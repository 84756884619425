/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { LayerType, webmapUtils, WMJSDimension } from '@opengeoweb/webmap';

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import {
  SetLayerDimensionsPayload,
  SetLayerStylePayload,
  UpdateLayerInfoPayload,
  createInterSections,
  getGeoJson,
  getLastEmptyFeatureIndex,
  moveFeature,
} from '@opengeoweb/webmap-react';
import {
  LayerState,
  Layer,
  LayerStatus,
  AddLayerPayload,
  SetLayerDimensionPayload,
  SetLayerEnabledPayload,
  SetLayerOpacityPayload,
  SetLayerNamePayload,
  SetLayerGeojsonPayload,
  DeleteLayerPayload,
  ErrorLayerPayload,
  SetLayersPayload,
  SetBaseLayersPayload,
  AddBaseLayerPayload,
  AddAvailableBaseLayerPayload,
  AddAvailableBaseLayersPayload,
  SetAvailableBaseLayersPayload,
  SetSelectedFeaturePayload,
  UpdateFeaturePayload,
  ExitFeatureDrawModePayload,
  ToggleFeatureModePayload,
  UpdateFeaturePropertiesPayload,
  DuplicateMapLayerPayload,
  ShowLayerInfoPayload,
  SetLayerGeojsonFromLayerPayload,
} from './types';
import { Dimension } from '../map/types';
import { checkValidLayersPayload } from '../map/utils';
import { produceDraftStateForAllLayersForDimensionWithinMap } from './utils';
import { SyncLayerPayloads } from '../../generic/types';
import { mapChangeDimension, setMapPreset } from '../map/actions';
import {
  setLayerActionSync,
  setTimeSync,
} from '../../generic/synchronizationActions/actions';

export const createLayer = ({
  id,
  opacity = 1,
  acceptanceTimeInMinutes = 60,
  enabled = true,
  layerType = 'mapLayer' as LayerType,
  status = 'default' as LayerStatus,
  useLatestReferenceTime = true,
  ...props
}: Layer): Layer => {
  const wmjsLayer = webmapUtils.getWMLayerById(id!);
  const dimensions =
    props.dimensions ||
    (wmjsLayer ? wmjsLayer.getDimensions() : []).map((dim: WMJSDimension) => ({
      name: dim.name,
      currentValue: dim.currentValue,
      units: dim.units,
    }));

  return {
    ...props,
    dimensions,
    id,
    opacity,
    acceptanceTimeInMinutes,
    enabled,
    layerType,
    status,
    useLatestReferenceTime,
  };
};

export const initialState: LayerState = {
  byId: {},
  allIds: [],
  availableBaseLayers: { byId: {}, allIds: [] },
};

export const slice = createSlice({
  initialState,
  name: 'layerReducer',
  reducers: {
    addLayer: (
      draft: Draft<LayerState>,
      action: PayloadAction<AddLayerPayload>,
    ) => {
      const { layer, layerId, mapId } = action.payload;
      if (!checkValidLayersPayload([layer], mapId)) {
        return;
      }

      if (!draft.byId[layerId]) {
        // TODO: (Sander de Snaijer, 2020-03-19) remove layerIds from the layer utils
        draft.byId[layerId] = createLayer({ ...layer, id: layerId, mapId });
        draft.allIds.push(layerId);
      }
    },
    duplicateMapLayer: (
      draft: Draft<LayerState>,
      action: PayloadAction<DuplicateMapLayerPayload>,
    ) => {
      const { oldLayerId, newLayerId, mapId } = action.payload;
      const oldLayer = draft.byId[oldLayerId];

      if (oldLayer && !draft.byId[newLayerId]) {
        draft.byId[newLayerId] = createLayer({
          ...draft.byId[oldLayerId],
          id: newLayerId,
          mapId,
        });
        draft.allIds.push(newLayerId);
      }
    },
    layerChangeDimension: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerDimensionPayload>,
    ) => {
      const { layerId: layerIdFromAction, dimension } = action.payload;
      const layerFromAction = draft.byId[layerIdFromAction];
      if (!layerFromAction) {
        return;
      }

      const { mapId } = layerFromAction;

      produceDraftStateForAllLayersForDimensionWithinMap(
        draft,
        dimension,
        mapId!,
        layerIdFromAction,
      );
    },
    layerChangeEnabled: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerEnabledPayload>,
    ) => {
      const { layerId, enabled } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].enabled = enabled;
      }
    },
    layerChangeOpacity: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerOpacityPayload>,
    ) => {
      const { layerId, opacity } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].opacity = opacity;
      }
    },
    layerChangeStyle: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerStylePayload>,
    ) => {
      const { layerId, style } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].style = style;
      }
    },
    layerChangeAcceptanceTime: (
      draft: Draft<LayerState>,
      action: PayloadAction<{
        layerId: string;
        acceptanceTime: number | undefined;
      }>,
    ) => {
      const { layerId, acceptanceTime } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].acceptanceTimeInMinutes = acceptanceTime;
      }
    },
    layerChangeName: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerNamePayload>,
    ) => {
      const { layerId, name } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].name = name;
      }
    },
    layerChangeGeojson: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerGeojsonPayload>,
    ) => {
      const { layerId, geojson } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].geojson = geojson;
      }
    },
    layerSetGeojsonFromLayer: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerGeojsonFromLayerPayload>,
    ) => {
      const { layerId, sourceLayerId } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].geojson = draft.byId[sourceLayerId]?.geojson;
      }
    },
    layerDelete: (
      draft: Draft<LayerState>,
      action: PayloadAction<DeleteLayerPayload>,
    ) => {
      const { layerId } = action.payload;
      if (draft.byId[layerId]) {
        draft.allIds = draft.allIds.filter((id) => id !== layerId);
        delete draft.byId[layerId];
      }
    },
    layerError: (
      draft: Draft<LayerState>,
      action: PayloadAction<ErrorLayerPayload>,
    ) => {
      const { layerId } = action.payload;
      if (draft.byId[layerId]) {
        draft.byId[layerId].status = LayerStatus.error;
      }
    },
    baseLayerDelete: (
      draft: Draft<LayerState>,
      action: PayloadAction<DeleteLayerPayload>,
    ) => {
      const { layerId } = action.payload;
      if (
        draft.byId[layerId].layerType !== LayerType.baseLayer &&
        draft.byId[layerId].layerType !== LayerType.overLayer
      ) {
        return;
      }

      if (draft.byId[layerId]) {
        draft.allIds = draft.allIds.filter((id) => id !== layerId);
        delete draft.byId[layerId];
      }
    },
    setLayers: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayersPayload>,
    ) => {
      const { layers, mapId } = action.payload;
      if (!checkValidLayersPayload(layers, mapId)) {
        return;
      }

      /* 
          All layer id's for the specified mapId should be removed. 
          This is done by filtering the allId array. Keeping only the layer id's which don't exist in the byId object
          At the same time the byId object is synchronized. 
        */
      draft.allIds = draft.allIds.filter((layerId) => {
        if (
          draft.byId[layerId] &&
          draft.byId[layerId].layerType !== LayerType.baseLayer &&
          draft.byId[layerId].layerType !== LayerType.overLayer &&
          draft.byId[layerId].mapId === mapId
        ) {
          delete draft.byId[layerId];
          return false;
        }
        return true;
      });

      /*
          Here we set the layers for the mapId from the action. byId and allIds is updated. 
        */
      layers.forEach((layer) => {
        draft.byId[layer.id!] = createLayer({ id: layer.id, mapId, ...layer });
        draft.allIds.push(layer.id!);
      });
    },
    setBaseLayers: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetBaseLayersPayload>,
    ) => {
      const { layers, mapId } = action.payload;
      const filtererdBaseLayers = layers.filter(
        (layer) =>
          layer.layerType === LayerType.baseLayer ||
          layer.layerType === LayerType.overLayer,
      );

      // filter for unique layerTypes
      const layerTypes: LayerType[] = [];
      filtererdBaseLayers.forEach((layer) => {
        if (
          layer.layerType !== LayerType.baseLayer &&
          layer.layerType !== LayerType.overLayer
        ) {
          return;
        }
        if (!layerTypes.includes(layer.layerType)) {
          layerTypes.push(layer.layerType);
        }
      });

      // remove current layers with same type as one of the passed layers
      draft.allIds = draft.allIds.filter((layerId) => {
        if (
          draft.byId[layerId] &&
          layerTypes.includes(draft.byId[layerId].layerType!) &&
          draft.byId[layerId].mapId === mapId
        ) {
          delete draft.byId[layerId];
          return false;
        }

        return true;
      });
      // set over and base layers
      filtererdBaseLayers.forEach((layer) => {
        draft.byId[layer.id!] = createLayer({
          id: layer.id,
          layerType: layer.layerType,
          mapId,
          ...layer,
        });
        draft.allIds.push(layer.id!);
      });
    },
    addBaseLayer: (
      draft: Draft<LayerState>,
      action: PayloadAction<AddBaseLayerPayload>,
    ) => {
      const { layer } = action.payload;
      if (
        layer.layerType !== LayerType.baseLayer &&
        layer.layerType !== LayerType.overLayer
      ) {
        return;
      }

      if (!draft.byId[layer.id!]) {
        draft.byId[layer.id!] = createLayer({
          id: layer.id,
          layerType: layer.layerType,
          ...layer,
        });
        draft.allIds.push(layer.id!);
      }
    },
    layerSetDimensions: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetLayerDimensionsPayload>,
    ) => {
      const { dimensions, layerId } = action.payload;

      if (draft.byId[layerId]) {
        draft.byId[layerId].dimensions = dimensions;
      }
    },
    addAvailableBaseLayer: (
      draft: Draft<LayerState>,
      action: PayloadAction<AddAvailableBaseLayerPayload>,
    ) => {
      const { layer } = action.payload;
      if (
        (layer.layerType !== LayerType.baseLayer &&
          layer.layerType !== LayerType.overLayer) ||
        !layer.mapId
      ) {
        return;
      }

      if (!draft.availableBaseLayers.byId[layer.id!]) {
        draft.availableBaseLayers.byId[layer.id!] = createLayer({
          id: layer.id,
          layerType: layer.layerType,
          ...layer,
        });
        draft.availableBaseLayers.allIds.push(layer.id!);
      }
    },
    addAvailableBaseLayers: (
      draft: Draft<LayerState>,
      action: PayloadAction<AddAvailableBaseLayersPayload>,
    ) => {
      const { layers } = action.payload;

      // add new available baselayers
      layers.forEach((layer) => {
        if (
          layer.layerType !== LayerType.baseLayer ||
          draft.availableBaseLayers.byId[layer.id!] ||
          !layer.mapId
        ) {
          return;
        }

        draft.availableBaseLayers.byId[layer.id!] = createLayer({
          id: layer.id,
          layerType: layer.layerType,
          ...layer,
        });
        draft.availableBaseLayers.allIds.push(layer.id!);
      });
    },
    // Overwrites all baselayer for a certain map
    setAvailableBaseLayers: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetAvailableBaseLayersPayload>,
    ) => {
      const { layers, mapId } = action.payload;

      // remove current baselayers for passed map
      draft.availableBaseLayers.allIds =
        draft.availableBaseLayers.allIds.filter((layerId) => {
          if (
            draft.availableBaseLayers.byId[layerId] &&
            draft.availableBaseLayers.byId[layerId].mapId === mapId
          ) {
            delete draft.availableBaseLayers.byId[layerId];
            return false;
          }

          return true;
        });

      // add new available baselayers
      layers.forEach((layer) => {
        if (layer.layerType !== LayerType.baseLayer || !layer.mapId) {
          return;
        }

        draft.availableBaseLayers.byId[layer.id!] = createLayer({
          ...layer,
        });
        draft.availableBaseLayers.allIds.push(layer.id!);
      });
    },
    setUseLatestReferenceTime: (
      draft: Draft<LayerState>,
      action: PayloadAction<{ id: string; useLatestReferenceTime: boolean }>,
    ) => {
      const { id, useLatestReferenceTime } = action.payload;
      const layer = draft.byId[id];
      if (!layer) {
        return;
      }
      layer.useLatestReferenceTime = useLatestReferenceTime;
    },
    onUpdateLayerInformation: (
      draft: Draft<LayerState>,
      action: PayloadAction<UpdateLayerInfoPayload>,
    ) => {
      const { layerStyle, layerDimensions } = action.payload;

      if (layerStyle && draft.byId[layerStyle.layerId]) {
        draft.byId[layerStyle.layerId].style = layerStyle?.style;
      }

      if (!layerDimensions) {
        return;
      }

      const layer = draft.byId[layerDimensions.layerId];

      const incomingDimensionNames = layerDimensions.dimensions.map(
        (dimension) => dimension.name,
      );
      /* Find all other layers with the same name and service, and update these at once (atomic update) */
      const layersToUpdate = Object.values(draft.byId).filter(
        (otherLayer) =>
          otherLayer.name === layer.name &&
          otherLayer.service === layer.service &&
          otherLayer.id,
      );

      layersToUpdate.forEach((layerToUpdate) => {
        const layerId = layerToUpdate.id!;
        const reduxLayer = draft.byId[layerId];

        reduxLayer.dimensions ??= [];

        // remove dimensions not in incoming list of dimensions
        reduxLayer.dimensions = reduxLayer.dimensions.filter((dimension) => {
          return incomingDimensionNames.includes(dimension.name);
        });

        const reduxDimensions = reduxLayer.dimensions;

        /* Now update or insert the dimension for each layer */
        layerDimensions.dimensions.forEach((newLayerDimension) => {
          /* Find the wmLayer */
          const wmLayer = webmapUtils.getWMLayerById(layerId);

          /* Find the wmDimension */
          const wmDimension = wmLayer?.getDimension(newLayerDimension.name!);

          /* This will set the new range of start/stop values for this dimension, making getClosestValue work properly */
          wmDimension?.reInitializeValues(newLayerDimension.values!);

          const existingDimension = reduxDimensions.find(
            (d) => d.name === newLayerDimension.name,
          );

          if (existingDimension) {
            if (
              existingDimension.name === 'reference_time' &&
              reduxLayer.useLatestReferenceTime &&
              newLayerDimension.maxValue
            ) {
              existingDimension.currentValue = newLayerDimension.maxValue;
            }
            /* If found update only minValue, maxValue and values */
            existingDimension.maxValue = newLayerDimension.maxValue;
            existingDimension.minValue = newLayerDimension.minValue;
            existingDimension.values = newLayerDimension.values;
            if (!existingDimension.units) {
              existingDimension.units = newLayerDimension.units;
            }
          } else if (layerId === layerDimensions.layerId) {
            /* Otherwise add a new one, but only for this layer. */
            reduxDimensions.push({ ...newLayerDimension });
          }
        });
      });
    },

    // feature layer actions
    orderLayerToFront: (
      draft: Draft<LayerState>,
      action: PayloadAction<{
        layerId: string;
      }>,
    ) => {
      const { layerId } = action.payload;
      const layerIdsWithoutNewId = draft.allIds.filter(
        (existingLayerId) => existingLayerId !== layerId,
      );

      if (!layerId || layerIdsWithoutNewId.length === draft.allIds.length) {
        return;
      }

      const newLayerIds = [layerId, ...layerIdsWithoutNewId];
      draft.allIds = newLayerIds;
    },

    // feature layer actions
    setSelectedFeature: (
      draft: Draft<LayerState>,
      action: PayloadAction<SetSelectedFeaturePayload>,
    ) => {
      const { layerId, selectedFeatureIndex } = action.payload;
      if (!draft.byId[layerId]) {
        return;
      }
      draft.byId[layerId].selectedFeatureIndex = selectedFeatureIndex;
    },
    updateFeature: (
      draft: Draft<LayerState>,
      action: PayloadAction<UpdateFeaturePayload>,
    ) => {
      const {
        layerId,
        geojson,
        shouldAllowMultipleShapes = false,
        reason = '',
        geoJSONIntersectionLayerId,
        geoJSONIntersectionBoundsLayerId,
        selectionType,
      } = action.payload;

      const currentLayer = draft.byId[layerId];
      if (!currentLayer) {
        return;
      }

      const updatedGeoJSON = getGeoJson(geojson, shouldAllowMultipleShapes);

      if (shouldAllowMultipleShapes) {
        const { geojson: currentGeoJSON, selectedFeatureIndex = 0 } =
          currentLayer;

        const newFeatureIndex = moveFeature(
          currentGeoJSON!,
          updatedGeoJSON,
          selectedFeatureIndex,
          reason,
          selectionType,
        );

        if (newFeatureIndex !== undefined) {
          currentLayer.selectedFeatureIndex = newFeatureIndex;
        }
      }

      currentLayer.geojson = updatedGeoJSON;

      if (geoJSONIntersectionBoundsLayerId && geoJSONIntersectionLayerId) {
        const intersectionLayer = draft.byId[geoJSONIntersectionLayerId];
        const intersectionBoundsLayer =
          draft.byId[geoJSONIntersectionBoundsLayerId];
        if (!intersectionLayer || !intersectionBoundsLayer) {
          return;
        }

        const bounds =
          intersectionBoundsLayer.geojson as GeoJSON.FeatureCollection;
        const geoJSONProperties =
          intersectionLayer.defaultGeoJSONProperties ||
          currentLayer.geojson?.features[0]?.properties;

        intersectionLayer.geojson = createInterSections(
          updatedGeoJSON,
          bounds,
          geoJSONProperties,
        );
      }
    },

    updateFeatureProperties: (
      draft: Draft<LayerState>,
      action: PayloadAction<UpdateFeaturePropertiesPayload>,
    ) => {
      const { layerId, properties, selectedFeatureIndex } = action.payload;
      const currentLayer = draft.byId[layerId];
      if (!currentLayer) {
        return;
      }
      if (currentLayer.geojson && currentLayer.geojson.features.length) {
        currentLayer.geojson.features.forEach((_, index) => {
          if (
            selectedFeatureIndex === undefined ||
            selectedFeatureIndex === index
          ) {
            Object.keys(properties!).forEach((key) => {
              draft.byId[layerId].geojson!.features[index].properties![key] =
                properties![key];
            });
          }
        });
      }
    },

    toggleFeatureMode: (
      draft: Draft<LayerState>,
      action: PayloadAction<ToggleFeatureModePayload>,
    ) => {
      const { layerId, isInEditMode, drawMode } = action.payload;
      if (!draft.byId[layerId]) {
        return;
      }
      if (isInEditMode !== undefined) {
        draft.byId[layerId].isInEditMode = isInEditMode;
      }
      if (drawMode !== undefined) {
        draft.byId[layerId].drawMode = drawMode;
      }
    },
    exitFeatureDrawMode: (
      draft: Draft<LayerState>,
      action: PayloadAction<ExitFeatureDrawModePayload>,
    ) => {
      const { layerId, shouldAllowMultipleShapes, reason } = action.payload;
      if (!draft.byId[layerId]) {
        return;
      }

      const exitMultipleShapes =
        shouldAllowMultipleShapes && reason === 'escaped';

      if (!shouldAllowMultipleShapes || exitMultipleShapes) {
        draft.byId[layerId].isInEditMode = false;
        draft.byId[layerId].drawMode = '';

        if (exitMultipleShapes) {
          const currentGeoJSON = draft.byId[layerId].geojson;

          const lastFeatureIndex =
            currentGeoJSON !== undefined &&
            getLastEmptyFeatureIndex(currentGeoJSON);

          if (currentGeoJSON && lastFeatureIndex !== undefined) {
            const newGeoJSON = {
              ...currentGeoJSON,
              features: (
                currentGeoJSON as GeoJSON.FeatureCollection
              ).features.filter(
                (_feature, index) => index !== lastFeatureIndex,
              ),
            };
            draft.byId[layerId].geojson = newGeoJSON;
            draft.byId[layerId].selectedFeatureIndex =
              newGeoJSON.features.length > 0
                ? newGeoJSON.features.length - 1
                : 0;
          }
        }
      }
    },

    // layer info actions
    showLayerInfo: (
      draft: Draft<LayerState>,
      action: PayloadAction<ShowLayerInfoPayload>,
    ) => {
      const { serviceUrl, layerName } = action.payload;
      draft.activeLayerInfo = {
        serviceUrl,
        layerName,
      };
    },
    hideLayerInfo: (draft: Draft<LayerState>) => {
      delete draft.activeLayerInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setTimeSync, (draft: Draft<LayerState>, action) => {
        const { targets: targetsFromAction, source } = action.payload;
        /* Because we want backwards compatibility with the previous code, we also need to listen to the original source action */
        const targets = [];
        if (source) {
          targets.push({
            targetId: source.payload.sourceId,
            value: source.payload.value,
          });
        }
        /* And then append the targets form the action */
        targets.push(...targetsFromAction);
        targets.forEach((payload) => {
          const { targetId, value } = payload;
          const dimension: Dimension = {
            name: 'time',
            currentValue: value,
          };
          produceDraftStateForAllLayersForDimensionWithinMap(
            draft,
            dimension,
            targetId,
            null!,
          );
        });
      })
      .addCase(setLayerActionSync, (draft: Draft<LayerState>, action) => {
        /*
         * This GENERIC_SYNC_SETLAYERACTIONS action is generated by the syncgroup listener.
         * It has multiple targets (Layers) in its payload.
         * These targets can be used as payloads in new Layer actions.
         * These actions are here handled via the layer reducer, as it is the same logic
         */
        const { targets, source } = action.payload;
        return targets.reduce(
          (prevState: LayerState, target: SyncLayerPayloads): LayerState => {
            const action = {
              payload: target,
              type: source && source.type,
            };
            /* Handle the Layer action with the same logic, using the same reducer */
            return layerReducer(prevState, action);
          },
          draft,
        );
      })
      .addCase(setMapPreset, (draft: Draft<LayerState>, action) => {
        const { mapId } = action.payload;
        const layers = draft.allIds
          .map((id) => draft.byId[id])
          .filter((layer) => layer && layer.mapId === mapId);

        layers.forEach((layer) => {
          delete draft.byId[layer.id!];
          const index = draft.allIds.indexOf(layer.id!);
          draft.allIds.splice(index, 1);
        });
      })
      .addCase(mapChangeDimension, (draft: Draft<LayerState>, action) => {
        const { mapId } = action.payload;
        produceDraftStateForAllLayersForDimensionWithinMap(
          draft,
          action.payload.dimension,
          mapId,
          null!,
        );
      });
  },
});

export const { reducer: layerReducer, actions: layerActions } = slice;

export type LayerActions =
  | ReturnType<typeof layerActions.layerSetDimensions>
  | ReturnType<typeof layerActions.layerChangeStyle>
  | ReturnType<typeof layerActions.addAvailableBaseLayer>
  | ReturnType<typeof layerActions.addAvailableBaseLayers>
  | ReturnType<typeof layerActions.addBaseLayer>
  | ReturnType<typeof layerActions.addLayer>
  | ReturnType<typeof layerActions.baseLayerDelete>
  | ReturnType<typeof layerActions.onUpdateLayerInformation>
  | ReturnType<typeof layerActions.layerChangeDimension>
  | ReturnType<typeof layerActions.layerChangeEnabled>
  | ReturnType<typeof layerActions.layerChangeGeojson>
  | ReturnType<typeof layerActions.layerChangeName>
  | ReturnType<typeof layerActions.layerChangeOpacity>
  | ReturnType<typeof layerActions.layerDelete>
  | ReturnType<typeof layerActions.layerError>
  | ReturnType<typeof layerActions.setBaseLayers>
  | ReturnType<typeof layerActions.setLayers>;

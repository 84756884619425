/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { LinearProgress, MenuItem, SelectChangeEvent } from '@mui/material';
import { TooltipSelect } from '@opengeoweb/shared';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { Parameter, SelectParameter } from '../../TimeSeries/types';
import { TimeSeriesModuleState } from '../../../store/types';
import { getService } from '../../../store/selectors';
import { getParameterListForCollectionId } from '../../../utils/edrUtils';

const ParameterNameSelect: React.FC<{
  rowIsEnabled: boolean;
  parameter: Parameter;
  patchParameter: (parameter: Partial<Parameter>) => void;
  t: TFunction;
}> = ({ rowIsEnabled, parameter, patchParameter, t }) => {
  const { serviceId } = parameter;

  const service = useSelector((store: TimeSeriesModuleState) =>
    getService(store, serviceId),
  );
  const [parameterList, setParameterList] = React.useState<SelectParameter[]>(
    [],
  );

  React.useEffect(() => {
    if (service) {
      getParameterListForCollectionId(service, parameter.collectionId)
        .then((params) => {
          setParameterList(params.parameters);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameter.collectionId, service?.url]);

  const isFetching = parameterList.length === 0;

  return (
    <TooltipSelect
      SelectDisplayProps={{ 'aria-label': 'timeseriesManager-parameterName' }}
      value={parameter.propertyName}
      tooltip={`Parameter: ${parameter.propertyName}`}
      isEnabled={rowIsEnabled}
      onChange={(event: SelectChangeEvent<unknown>): void => {
        const selectedParam = parameterList.find((parameter) => {
          return parameter.propertyName === event.target.value;
        });
        if (selectedParam) {
          const newParameter = {
            id: parameter.id,
            propertyName: selectedParam.propertyName,
          };
          patchParameter(newParameter);
        } else {
          console.warn(`Unable to find param${event.target.value}`);
        }
      }}
    >
      {isFetching && <LinearProgress data-testid="spinner" color="inherit" />}
      <MenuItem disabled>{t('timeseries-parameter')}</MenuItem>
      {parameterList.length === 0 && (
        <MenuItem value={parameter.propertyName}>
          {parameter.propertyName}
        </MenuItem>
      )}
      {parameterList.map((parameter: SelectParameter) => {
        return (
          <MenuItem
            key={`parametermenu${parameter.propertyName}`}
            value={parameter.propertyName}
          >
            {parameter.propertyName}
          </MenuItem>
        );
      })}
    </TooltipSelect>
  );
};

export default ParameterNameSelect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, MenuItem } from '@mui/material';
import {
  isEmpty,
  isInteger,
  isValidMax,
  isValidMin,
  ReactHookFormSelect,
  ReactHookFormNumberField,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';
import {
  ConfigurableFormFieldProps,
  FIRConfigAirmet,
  ProductConfig,
  WindUnit,
} from '../../../types';
import { styles } from '../ProductForm.styles';
import {
  getActiveFIRArea,
  getAllowedUnits,
  getFieldLabel,
  getMaxWindSpeedValue,
  getMinWindSpeedValue,
  triggerValidations,
} from '../utils';
import {
  translateKeyOutsideComponents,
  useSigmetAirmetTranslation,
} from '../../../utils/i18n';

export const DEFAULT_ROUNDING_SURFACE_WIND_DIRECTION = 10;

export const invalidSurfaceWindDirectionStepsMessage = (
  value = DEFAULT_ROUNDING_SURFACE_WIND_DIRECTION,
): string =>
  translateKeyOutsideComponents('invalid-surface-wind-direction-message', {
    value,
  });

export const validateSurfaceWindDirection = (
  value: string,
  productConfig: ProductConfig,
  selectedFIR: string,
): boolean | string => {
  if (isEmpty(value)) {
    return true;
  }

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wind_direction_rounding = DEFAULT_ROUNDING_SURFACE_WIND_DIRECTION,
  } = getActiveFIRArea(selectedFIR, productConfig) as FIRConfigAirmet;

  // Parse to integer to check for steps
  const intVisibility = parseInt(value, 10);
  return (
    intVisibility % wind_direction_rounding === 0 ||
    invalidSurfaceWindDirectionStepsMessage(wind_direction_rounding)
  );
};

const SurfaceWind: React.FC<ConfigurableFormFieldProps> = ({
  isDisabled,
  isReadOnly,
  onChange = (): void => null!,
  productConfig,
}: ConfigurableFormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { trigger, getValues } = useFormContext();
  const { isRequired } = useDraftFormHelpers();

  const getFIRValue = (): string => getValues('locationIndicatorATSR');

  // Get allowed speed units based on selected FIR - if no FIR selected, allow all units
  const allowedWindUnitsForFir = getAllowedUnits(
    getFIRValue(),
    productConfig,
    'surfacewind_unit',
    WindUnit,
  );

  const windLabel = getFieldLabel(
    t('surface-wind-label'),
    isReadOnly!,
    t('set'),
  );
  const speedLabel = getFieldLabel(
    t('surface-wind-speed-label'),
    isReadOnly!,
    t('set'),
  );

  return (
    <Grid container spacing={2}>
      <Grid container justifyContent="flex-end" size={4} />
      <Grid container justifyContent="flex-start" size={8}>
        <Grid sx={styles.surfaceWind} size={12}>
          <ReactHookFormNumberField
            name="windDirection"
            label={windLabel}
            helperText={t('surface-wind-helper-text')}
            rules={{
              min: {
                value: 10,
                message: t('surface-wind-rule-min-max-message'),
              },
              max: {
                value: 360,
                message: t('surface-wind-rule-min-max-message'),
              },
              validate: {
                isRequired,
                validateSurfaceWindDirection: (value): boolean | string =>
                  validateSurfaceWindDirection(
                    value,
                    productConfig,
                    getFIRValue(),
                  ),
              },
            }}
            size="small"
            disabled={isDisabled}
            isReadOnly={isReadOnly}
            data-testid="surfaceWind-windDirection"
          />
        </Grid>
        <Grid container size={12} sx={{ marginTop: -2 }} spacing={0}>
          <Grid size={5}>
            <ReactHookFormSelect
              name="windUnit"
              label={t('unit-label')}
              rules={{ validate: { isRequired } }}
              size="small"
              sx={styles.unit}
              disabled={isDisabled}
              isReadOnly={isReadOnly}
              defaultValue={'KT' as WindUnit}
              data-testid="surfaceWind-windUnit"
              onChange={(): void => {
                triggerValidations(['windSpeed'], getValues, trigger);
                onChange();
              }}
            >
              {Object.keys(allowedWindUnitsForFir).map((key) => (
                <MenuItem value={key} key={key}>
                  {allowedWindUnitsForFir[key]}
                </MenuItem>
              ))}
            </ReactHookFormSelect>
          </Grid>
          <Grid size={7}>
            <ReactHookFormNumberField
              name="windSpeed"
              label={speedLabel}
              rules={{
                validate: {
                  isRequired,
                  isInteger,
                  min: (value): boolean | string =>
                    // The min level depends on the unit
                    isValidMin(
                      value,
                      getMinWindSpeedValue(
                        getValues('windUnit'),
                        getFIRValue(),
                        productConfig,
                      ),
                    ) ||
                    t('surface-wind-minimum-wind-speed', {
                      windUnit:
                        WindUnit[
                          getValues('windUnit') as keyof typeof WindUnit
                        ],
                      minWindSpeedValue: getMinWindSpeedValue(
                        getValues('windUnit'),
                        getFIRValue(),
                        productConfig,
                      ),
                    }),

                  max: (value): boolean | string =>
                    // The max level depends on the unit
                    isValidMax(
                      value,
                      getMaxWindSpeedValue(
                        getValues('windUnit'),
                        getFIRValue(),
                        productConfig,
                      ),
                    ) ||
                    t('surface-wind-maximum-wind-speed', {
                      windUnit:
                        WindUnit[
                          getValues('windUnit') as keyof typeof WindUnit
                        ],
                      maxWindSpeedValue: getMaxWindSpeedValue(
                        getValues('windUnit'),
                        getFIRValue(),
                        productConfig,
                      ),
                    }),
                },
              }}
              size="small"
              disabled={isDisabled}
              isReadOnly={isReadOnly}
              data-testid="surfaceWind-windSpeed"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SurfaceWind;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomToggleButton, CustomTooltip } from '@opengeoweb/shared';
import { ServiceFilterChipsObject } from '../../../store/types';
import { useTimeseriesTranslation } from '../../../utils/i18n';

const style = {
  borderRadius: '20px!important',
  marginRight: '4px',
  whiteSpace: 'nowrap',
  height: '24px!important',
  padding: '5px 12px!important',
  textTransform: 'none!important',
  fontWeight: 'normal',
  marginBottom: '4px',
};

interface ServiceChipProps {
  all?: boolean;
  service?: ServiceFilterChipsObject;
  isSelected?: boolean;
  isDisabled?: boolean;
  toggleChip?: (service: string) => void;
}

const ServiceChip: React.FC<ServiceChipProps> = ({
  all = false,
  service,
  isSelected,
  isDisabled,
  toggleChip,
}: ServiceChipProps) => {
  const { t } = useTimeseriesTranslation();
  const chip = all ? 'all' : service && service.serviceUrl;

  return (
    <CustomTooltip
      title={service?.serviceUrl ? `URL: ${service.serviceUrl}` : ''}
    >
      <span>
        <CustomToggleButton
          variant="boxed"
          selected={Boolean(isSelected)}
          tabIndex={0}
          onClick={(): void => toggleChip!(chip!)}
          disabled={isDisabled}
          sx={style}
        >
          {service ? service.serviceName : t('timeseries-all')}
        </CustomToggleButton>
      </span>
    </CustomTooltip>
  );
};

export default ServiceChip;

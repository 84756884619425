/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ServiceFilterChipsObjectEntities } from '../../store/types';
import { SelectParameter } from '../TimeSeries/types';

export interface TimeseriesCollectionDetail {
  collectionId: string;
  serviceId: string;
  parameters: SelectParameter[];
  serviceName: string;
}

export const filterCollectionsParametersFromService = (
  collectionList: TimeseriesCollectionDetail[],
  searchString: string,
  serviceFilterChips: ServiceFilterChipsObjectEntities,
  allFilterChipsEnabled: boolean,
): TimeseriesCollectionDetail[] => {
  // If no search string and all filterchips are enabled, simply return the same list
  if (searchString === '' && allFilterChipsEnabled) {
    return collectionList;
  }
  const searchStringArray = searchString.split(' ');

  return collectionList.reduce(
    (
      filteredCollectionList: TimeseriesCollectionDetail[],
      collection: TimeseriesCollectionDetail,
    ): TimeseriesCollectionDetail[] => {
      if (!serviceFilterChips[collection.serviceId]?.enabled) {
        return filteredCollectionList;
      }
      const collectionContainsSearch = searchStringArray.every((search) => {
        const searchLowercase = search.toLowerCase();

        const isSearchTextInCollectionId = collection.collectionId
          ?.toLowerCase()
          .includes(searchLowercase);
        const isSearchTextInServiceId = collection.serviceId
          ?.toLowerCase()
          .includes(searchLowercase);
        const isSearchTextInServiceName = collection.serviceName
          ?.toLowerCase()
          .includes(searchLowercase);

        return (
          isSearchTextInCollectionId ||
          isSearchTextInServiceId ||
          isSearchTextInServiceName
        );
      });

      // Not found in collection - search in parameters
      const filteredParamters = collection.parameters.reduce(
        (
          filteredParameterList: SelectParameter[],
          parameter: SelectParameter,
        ): SelectParameter[] => {
          const parameterContainsSearch = searchStringArray.every((search) => {
            const searchLowercase = search.toLowerCase();

            const isSearchTextInParameterName = parameter.propertyName
              ?.toLowerCase()
              .includes(searchLowercase);

            return isSearchTextInParameterName;
          });

          if (parameterContainsSearch) {
            return filteredParameterList.concat(parameter);
          }
          return filteredParameterList;
        },
        [],
      );

      const collectionFilteredParams = {
        ...collection,
        parameters: filteredParamters,
      };
      if (filteredParamters.length > 0 || collectionContainsSearch) {
        return filteredCollectionList.concat(collectionFilteredParams);
      }

      return filteredCollectionList;
    },
    [],
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useState } from 'react';
import { Clock } from '@opengeoweb/theme';
import { TimeSliderMenu } from '../TimeSliderMenu/TimeSliderMenu';
import { defaultTimeSpan } from '../../TimeSlider/timeSliderUtils';
import { useTimesliderTranslation } from '../../../utils/i18n';
import { TimeSpanButtonCustomSelect } from './TimeSpanButtonCustomSelect';

export const marks = [
  { value: 60, text: 'timeslider-hour', count: 1 },
  { value: 3 * 60, text: 'timeslider-hour', count: 3 },
  { value: 6 * 60, text: 'timeslider-hour', count: 6 },
  { value: 12 * 60, text: 'timeslider-hour', count: 12 },
  { value: 24 * 60, text: 'timeslider-hour', count: 24 },
  { value: 2 * 24 * 60, text: 'timeslider-hour', count: 48 },
  { value: 7 * 24 * 60, text: 'timeslider-week', count: 1 },
  { value: 2 * 7 * 24 * 60, text: 'timeslider-weeks', count: 2 },
  { value: 30 * 24 * 60, text: 'timeslider-month', count: 1 },
  { value: 3 * 30 * 24 * 60, text: 'timeslider-months', count: 3 },
  { value: 365 * 24 * 60, text: 'timeslider-year', count: 1 },
].sort((a, b) => b.value - a.value);

interface TimeSpanButtonProps {
  timeSliderSpan: number;
  disabled?: boolean;
  onChangeSliderValue?: (markValue: number) => void;
  onToggleTimeSpan?: () => void;
  isTimeSpanAuto: boolean;
  isOpenByDefault?: boolean;
  layerHasTimeStep?: boolean;
}

export const TimeSpanButton = ({
  timeSliderSpan = defaultTimeSpan,
  disabled = false,
  onChangeSliderValue = (): void => {},
  onToggleTimeSpan,
  isTimeSpanAuto,
  isOpenByDefault = false,
  layerHasTimeStep = false,
}: TimeSpanButtonProps): React.ReactElement => {
  const { t } = useTimesliderTranslation();
  const translatedMarks = marks.map((mark) => ({
    ...mark,
    label: `${mark.count} ${t(mark.text)}`,
  }));
  const [open, setOpen] = useState(isOpenByDefault);

  return (
    <TimeSliderMenu
      icon={<Clock />}
      marks={translatedMarks}
      displayCurrentLength={true}
      title={t('timeslider-time-span')}
      customFieldHeader={t('timeslider-span')}
      value={timeSliderSpan}
      onChangeSliderValue={onChangeSliderValue}
      allOptionsDisabled={disabled}
      isAutoSelected={isTimeSpanAuto}
      isAutoDisabled={!layerHasTimeStep}
      handleAutoClick={onToggleTimeSpan}
      disbleUserSetDuration={false}
      open={open}
      setOpen={setOpen}
      customLengthSelector={
        <TimeSpanButtonCustomSelect
          timeSliderSpan={timeSliderSpan}
          setOpen={setOpen}
          onChangeSliderValue={onChangeSliderValue}
        />
      }
    />
  );
};

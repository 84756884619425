/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Typography } from '@mui/material';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { styles } from '../ProductForm.styles';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

interface IssuedAtProps {
  date: string;
  isReadOnly?: boolean;
}

const IssuedAt: React.FC<IssuedAtProps> = ({
  date,
  isReadOnly,
}: IssuedAtProps) => {
  const { t } = useSigmetAirmetTranslation();
  return (
    <ProductFormFieldLayout title={t('issued-at')} sx={{ marginTop: -2 }}>
      <Typography
        variant="body2"
        sx={{
          ...styles.body,
          fontSize: '1rem',
          color: isReadOnly
            ? 'geowebColors.typographyAndIcons.text'
            : 'geowebColors.buttons.primary.disabled.color',
        }}
      >
        {date}
      </Typography>
    </ProductFormFieldLayout>
  );
};

export default IssuedAt;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Action } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { LayerFoundation } from '@opengeoweb/webmap';
import { UpdateLayerInfoPayload } from '@opengeoweb/webmap-react';
import type { Dimension } from '../map/types';

import type { SyncGroupActionOrigin } from '../../generic/syncGroups/types';
import { uiTypes } from '../../ui';

export enum LayerStatus {
  default = 'default',
  error = 'error',
}

export enum LayerActionOrigin {
  layerManager = 'layerManager',
  wmsLoader = 'WMSLayerTreeConnect',
  ReactMapViewParseLayer = 'ReactMapViewParseLayer',
  updateLayerInformationListener = 'updateLayerInformationListener',
  toggleAutoUpdateListener = 'toggleAutoUpdateListener',
  unregisterMapListener = 'unregisterMapListener',
}

export interface FeatureLayer {
  // TODO: rename to geoJSON
  geojson?: FeatureCollection;
  defaultGeoJSONProperties?: GeoJSON.GeoJsonProperties;
  selectedFeatureIndex?: number;
  isInEditMode?: boolean;
  drawMode?: string;
}

/**
 * ReduxLayer is used to reflect the shape of the layer in the redux layers store.
 * It is a subset of Layer since some properties like the styles list now come from the service store.
 */
export interface ReduxLayer extends LayerFoundation, FeatureLayer {
  dimensions?: Dimension[];
  mapId?: string;
  status?: LayerStatus;
  useLatestReferenceTime?: boolean;
}

/**
 * Layer is used to define a layer with all its possible properties.
 */
export interface Layer extends ReduxLayer {
  headers?: Headers[];
}

export interface AvailableBaseLayersType {
  byId: Record<string, ReduxLayer>;
  allIds: string[];
}

export interface LayerState {
  byId: Record<string, ReduxLayer>;
  allIds: string[];
  availableBaseLayers: AvailableBaseLayersType;
  activeLayerInfo?: {
    layerName: string;
    serviceUrl: string;
  };
}

// actions
export interface LayerPayload {
  layerId: string;
  origin?: string;
}

export interface SetLayersPayload {
  mapId: string;
  layers: ReduxLayer[];
  origin?: string;
}

export interface SetBaseLayersPayload {
  mapId: string;
  layers: ReduxLayer[];
  origin?: LayerActionOrigin | SyncGroupActionOrigin.layerActions;
}

export interface SetLayerOpacityPayload extends LayerPayload {
  opacity: number; // between 0.0 and 1.0
  mapId?: string;
  origin?: LayerActionOrigin;
}

export interface SetLayerNamePayload extends LayerPayload {
  name: string;
  mapId?: string;
  origin?: LayerActionOrigin;
}

export interface SetLayerEnabledPayload extends LayerPayload {
  enabled: boolean;
  mapId?: string;
  origin?: LayerActionOrigin;
}

export interface SetLayerDimensionPayload extends LayerPayload {
  dimension: Dimension;
  service?: string;
  origin?: LayerActionOrigin;
  mapId?: string;
}

export interface SetLayerGeojsonPayload extends LayerPayload {
  geojson: FeatureCollection;
}

export interface SetLayerGeojsonFromLayerPayload extends LayerPayload {
  sourceLayerId: string;
}

export interface DeleteLayerPayload extends LayerPayload {
  mapId: string;
  layerIndex?: number;
  origin?: LayerActionOrigin | SyncGroupActionOrigin.delete;
}

export interface AddLayerPayload {
  mapId: string;
  layerId: string;
  layer: Layer;
  origin: string;
}

export interface DuplicateMapLayerPayload {
  mapId: string;
  oldLayerId: string;
  newLayerId: string;
  origin: string;
}

export interface AddBaseLayerPayload {
  mapId: string;
  layerId: string;
  layer: Layer;
  origin?: string;
}

export interface AddAvailableBaseLayerPayload {
  layer: Layer;
  origin?: string;
}

export interface AddAvailableBaseLayersPayload {
  layers: ReduxLayer[];
  origin?: string;
}

export interface SetAvailableBaseLayersPayload
  extends AddAvailableBaseLayersPayload {
  mapId: string;
}

export interface ErrorLayerPayload extends LayerPayload {
  error: string;
}

export interface UpdateLayerInfo extends Action {
  type: `layerReducer/onUpdateLayerInformation`;
  payload: UpdateLayerInfoPayload;
}

// feature layer actions
export interface SetSelectedFeaturePayload extends LayerPayload {
  selectedFeatureIndex?: number;
}

export interface UpdateFeaturePayload extends LayerPayload {
  // TODO: rename to geoJSON
  geojson: GeoJSON.FeatureCollection;
  shouldAllowMultipleShapes?: boolean;
  reason?: string;
  geoJSONIntersectionLayerId?: string;
  geoJSONIntersectionBoundsLayerId?: string;
  selectionType?: string;
}

export interface UpdateFeaturePropertiesPayload extends LayerPayload {
  properties: GeoJSON.GeoJsonProperties;
  selectedFeatureIndex?: number;
}

export interface ToggleFeatureModePayload extends LayerPayload {
  isInEditMode?: boolean;
  drawMode?: string;
}

export interface ExitFeatureDrawModePayload extends LayerPayload {
  reason: string;
  shouldAllowMultipleShapes?: boolean;
}

export interface ShowLayerInfoPayload {
  serviceUrl: string;
  layerName: string;
  mapId: string;
  source?: uiTypes.Source;
}

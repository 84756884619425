/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  FormControlLabel,
  Grid2 as Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ThemeTypes, useThemeContext } from '@opengeoweb/theme';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { SHARED_NAMESPACE } from '../../utils/i18n';

interface MenuItemThemeProps {
  enableSpecialThemes?: boolean;
}

const MenuItemTheme: React.FC<MenuItemThemeProps> = ({
  enableSpecialThemes,
}) => {
  const { namedTheme, setThemeName } = useThemeContext();
  const { t } = useTranslation(SHARED_NAMESPACE);
  return (
    <MenuItem divider>
      <Grid container>
        <Grid>{t('shared-choose-app-mode')}</Grid>
        <Grid>
          <RadioGroup
            onChange={(event, value) => {
              if (value && setThemeName) {
                setThemeName(value as ThemeTypes);
              }
            }}
          >
            <FormControlLabel
              value={ThemeTypes.LIGHT_THEME}
              control={
                <Radio checked={namedTheme?.name === ThemeTypes.LIGHT_THEME} />
              }
              label={t('shared-light')}
            />
            <FormControlLabel
              value={ThemeTypes.DARK_THEME}
              control={
                <Radio checked={namedTheme?.name === ThemeTypes.DARK_THEME} />
              }
              label={t('shared-dark')}
            />

            {enableSpecialThemes && (
              <FormControlLabel
                value={ThemeTypes.XMAS2024_THEME}
                control={
                  <Radio
                    checked={namedTheme?.name === ThemeTypes.XMAS2024_THEME}
                  />
                }
                label={`${t('shared-xmas')} 2024`}
              />
            )}
          </RadioGroup>
        </Grid>
      </Grid>
    </MenuItem>
  );
};

export default MenuItemTheme;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useEffect, useState, FC } from 'react';
import { Grid2 as Grid, Popper, Paper } from '@mui/material';
import { CustomIconButton } from '@opengeoweb/shared';
import { Options } from '@opengeoweb/theme';
import { OptionsMenu } from './OptionsMenu';
import { useTimesliderTranslation } from '../../../utils/i18n';

interface OptionsMenuButtonProps {
  speedBtn?: React.ReactNode;
  timeStepBtn?: React.ReactNode;
  timeSpanBtn?: React.ReactNode;
  animationLengthBtn?: React.ReactNode;
  isOpenByDefault?: boolean;
}

export const OptionsMenuButton: FC<OptionsMenuButtonProps> = ({
  speedBtn,
  timeStepBtn,
  timeSpanBtn,
  animationLengthBtn,
  isOpenByDefault = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const { t } = useTimesliderTranslation();

  useEffect(() => {
    if (anchorEl && isOpenByDefault) {
      setOpen(true);
    }
  }, [anchorEl, isOpenByDefault]);

  return (
    <div>
      <CustomIconButton
        variant="tool"
        onClick={(): void => {
          setOpen(!open);
        }}
        data-testid="optionsMenuButton"
        isSelected={open}
        aria-label="animation options"
        tooltipProps={{
          title: t('timeslider-animation-options'),
          placement: 'bottom',
        }}
        ref={(ref): void => {
          setAnchorEl(ref);
        }}
      >
        <Options />
      </CustomIconButton>
      <Popper
        sx={{ zIndex: 10 }}
        placement="top-start"
        open={open}
        data-testid="optionsMenuPopOver"
        anchorEl={anchorEl}
      >
        <Paper elevation={3} sx={{ position: 'relative', top: '-2rem' }}>
          <Grid
            sx={{
              padding: '10px',
            }}
            container
            spacing={1}
          >
            <OptionsMenu
              timeSpanBtn={timeSpanBtn}
              timeStepBtn={timeStepBtn}
              speedBtn={speedBtn}
              animationLengthBtn={animationLengthBtn}
            />
          </Grid>
        </Paper>
      </Popper>
    </div>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import {
  UseTranslationResponse,
  initReactI18next,
  useTranslation,
} from 'react-i18next';

import snackbarTranslations from '../locales/snackbar.json';

export const SNACKBAR_NAMESPACE = 'snackbar';
const WORKSPACE_NAMESPACE = 'workspace';
const WARN_NAMESPACE = 'warn';
const LAYER_SELECT_NAMESPACE = 'layerselect';
const AUTH_NAMESPACE = 'auth';

const defaultLanguage = 'en';

export const initSnackbarI18n = (): void => {
  void i18n.use(initReactI18next).init({
    lng: defaultLanguage,
    ns: [SNACKBAR_NAMESPACE],
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [SNACKBAR_NAMESPACE]: snackbarTranslations.en,
      },
      nl: {
        [SNACKBAR_NAMESPACE]: snackbarTranslations.nl,
      },
      fi: {
        [SNACKBAR_NAMESPACE]: snackbarTranslations.fi,
      },
      no: {
        [SNACKBAR_NAMESPACE]: snackbarTranslations.no,
      },
    },
  });
};

export const useSnackbarTranslation = (): UseTranslationResponse<
  typeof SNACKBAR_NAMESPACE,
  typeof i18n
> =>
  useTranslation(
    [
      SNACKBAR_NAMESPACE,
      WORKSPACE_NAMESPACE,
      WARN_NAMESPACE,
      LAYER_SELECT_NAMESPACE,
      AUTH_NAMESPACE,
    ],
    {
      nsMode: 'fallback',
    },
  );

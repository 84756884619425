/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Box,
  Grid2 as Grid,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import {
  AlertBanner,
  CustomAccordion,
  CustomIconButton,
  renderCounter,
  tooltipContainerStyles,
} from '@opengeoweb/shared';
import {
  CloudLoading,
  Delete,
  Visibility,
  VisibilityOff,
} from '@opengeoweb/theme';
import { mapTypes, layerTypes, layerUtils } from '@opengeoweb/store';
import type { serviceTypes } from '@opengeoweb/store';
import { LayerInfoButton } from '@opengeoweb/webmap-react';
import DimensionSelect from './DimensionSelect/DimensionSelect';
import RenderLayers from './RenderLayers/RenderLayers';
import OpacitySelect from './OpacitySelect/OpacitySelect';
import LayerManagerMenuButton from './Menubutton/MenuButton';
import RenderStyles from './RenderStyles/RenderStyles';
import { columnClasses, leftButtonsStyle } from '../../LayerManagerUtils';
import ActivateLayer, { AutoOptions } from './ActivateLayer/ActivateLayer';
import { AcceptanceTime } from './AcceptanceTime/AcceptanceTime';
import { MissingData } from './MissingData/MissingData';
import LoadDuration from './LoadDuration/LoadDuration';
import { useCoreTranslation } from '../../../../utils/i18n';

const LeftButtons: FC<{
  size?: string;
  layerEnableLayout?: React.ReactElement;
  layerMissingDataLayout?: React.ReactElement;
  layerActiveLayout?: React.ReactElement;
  disableActivateLayer?: boolean;
  isEnabled?: boolean;
  dragHandle?: React.ReactElement;
  layer?: layerTypes.Layer;
  onChangeEnable: (event: React.MouseEvent<HTMLElement>) => void;
  onChangeActive: (autoOption: AutoOptions) => void;
}> = ({
  size,
  dragHandle,
  layer,
  layerMissingDataLayout,
  layerEnableLayout,
  isEnabled,
  disableActivateLayer,
  layerActiveLayout,
  onChangeEnable,
  onChangeActive,
}) => {
  return (
    <Box sx={leftButtonsStyle}>
      {dragHandle}
      {layerMissingDataLayout || (
        <MissingData layerIsInsideAcceptanceTime="equal" />
      )}
      {layerEnableLayout || (
        <CustomIconButton
          shouldShowAsDisabled={!layer!.enabled}
          tooltipTitle={layer!.name!}
          onClick={onChangeEnable}
          data-testid={`enableButton${size}`}
        >
          {isEnabled ? <Visibility /> : <VisibilityOff />}
        </CustomIconButton>
      )}
      {!disableActivateLayer &&
        (layerActiveLayout || (
          <ActivateLayer
            onChange={onChangeActive}
            current={AutoOptions.BOTH}
            isEnabled={true}
          />
        ))}
    </Box>
  );
};

const Styles: FC<{
  style: string;
  onChangeStyle: (style: string) => void;
}> = ({ style, onChangeStyle }) => (
  <RenderStyles
    layerStyles={[]}
    currentLayerStyle={style}
    onChangeLayerStyle={onChangeStyle}
  />
);

const Opacity: FC<{
  opacity: number;
  onChangeOpacity: (opacity: number) => void;
}> = ({ opacity, onChangeOpacity }) => (
  <OpacitySelect
    currentOpacity={opacity}
    onLayerChangeOpacity={onChangeOpacity}
  />
);

const DeleteLayer: FC<{
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ onClick }) => {
  const { t } = useCoreTranslation();
  return (
    <CustomIconButton
      tooltipTitle={t('layermanager-layer-delete')}
      onClick={onClick}
      data-testid="deleteButton"
    >
      <Delete />
    </CustomIconButton>
  );
};

const ShowLayerInfo: FC<{
  onClick: () => void;
}> = ({ onClick }) => <LayerInfoButton onClick={onClick} />;

const Acceptance: FC<{
  acceptanceTime: number | undefined;
  onChangeAcceptanceTime: (acceptanceTime: number | undefined) => void;
}> = ({ acceptanceTime, onChangeAcceptanceTime }) => (
  <AcceptanceTime
    acceptanceTimeInMinutes={acceptanceTime}
    onChangeAcceptanceTime={onChangeAcceptanceTime}
  />
);

const RowItemCollapse: FC<{
  className: string;
  collapsedColumns?: Record<string, boolean>;
  children: React.ReactNode;
}> = ({ className, collapsedColumns, children }) => {
  const theme = useTheme();
  const hoverId = `${columnClasses.columnHeader}-${className}`;
  const hoverElement = document.getElementById(hoverId);

  hoverElement?.addEventListener('mouseenter', () => {
    const targetElements = document.getElementsByClassName(className);
    (Array.from(targetElements) as HTMLElement[]).forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.backgroundColor =
        theme.palette.geowebColors.buttons.icon.mouseOver.fill || 'inherit';
    });
  });

  hoverElement?.addEventListener('mouseleave', () => {
    const targetElements = document.getElementsByClassName(className);
    (Array.from(targetElements) as HTMLElement[]).forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.backgroundColor = 'inherit';
    });
  });

  return (
    <Grid
      className={className}
      style={{
        height: '32px',
        ...(collapsedColumns?.[className] && {
          width: '24px',
          flexGrow: 0,
          contentVisibility: 'hidden',
        }),
      }}
    >
      {children}
    </Grid>
  );
};

interface LayerRowUnstyledProps {
  layerId?: string;
  layer?: layerTypes.Layer;
  layerName?: string;
  mapId: string;
  onLayerRowClick?: (layerId?: string) => void;
  onLayerEnable?: (payload: { layerId: string; enabled: boolean }) => void;
  onLayerChangeName?: (payload: { layerId: string; name: string }) => void;
  onLayerChangeStyle?: (payload: { layerId: string; style: string }) => void;
  onLayerChangeOpacity?: (payload: {
    layerId: string;
    opacity: number;
  }) => void;
  onLayerChangeDimension?: (payload: {
    origin: string;
    layerId: string;
    dimension: mapTypes.Dimension;
  }) => void;
  onLayerDelete?: (payload: { mapId: string; layerId: string }) => void;
  onLayerDuplicate?: (payload: { mapId: string; layerId: string }) => void;
  onChangeAcceptanceTime?: (payload: {
    layerId: string;
    acceptanceTime: number | undefined;
  }) => void;
  layerEnableLayout?: React.ReactElement;
  layerServicesLayout?: React.ReactElement;
  layerStylesLayout?: React.ReactElement;
  layerOpacityLayout?: React.ReactElement;
  layerDimensionLayout?: React.ReactElement;
  layerAcceptanceTimeLayout?: React.ReactElement;
  layerMissingDataLayout?: React.ReactElement;
  layerDeleteLayout?: React.ReactElement;
  layerShowLayerInfoLayout?: React.ReactElement;
  layerMenuLayout?: React.ReactElement;
  layerActiveLayout?: React.ReactElement;
  layerLoadDurationLayout?: React.ReactElement;
  disableActivateLayer?: boolean;
  services?: serviceTypes.Services;
  isEnabled?: boolean;
  dragHandle?: React.ReactElement;
  isLayerMissing?: boolean;
  className?: string;
  collapsedColumns?: Record<string, boolean>;
}

const LayerRowUnstyled: FC<LayerRowUnstyledProps> = ({
  layerId,
  layer,
  layerName,
  services,
  mapId,
  onLayerRowClick = (): void => {},
  isEnabled = true,
  isLayerMissing = false,
  onLayerEnable,
  onLayerChangeName,
  onLayerChangeStyle,
  onLayerChangeOpacity,
  onLayerChangeDimension,
  onLayerDelete,
  onLayerDuplicate,
  onChangeAcceptanceTime,
  // layout
  layerEnableLayout,
  layerServicesLayout,
  layerStylesLayout,
  layerOpacityLayout,
  layerDimensionLayout,
  layerDeleteLayout,
  layerShowLayerInfoLayout,
  layerMenuLayout,
  layerActiveLayout,
  disableActivateLayer,
  dragHandle,
  layerAcceptanceTimeLayout,
  layerMissingDataLayout,
  layerLoadDurationLayout,
  className,
  collapsedColumns,
}: LayerRowUnstyledProps) => {
  const { t } = useCoreTranslation();
  const onClickRow = (): void => onLayerRowClick(layerId);

  const changeEnable = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    onLayerEnable!({ layerId: layerId!, enabled: !isEnabled });
  };

  const changeStyle = (style: string): void => {
    onLayerChangeStyle!({ layerId: layerId!, style });
  };

  const changeOpacity = (opacity: number): void => {
    onLayerChangeOpacity!({
      layerId: layer!.id!,
      opacity,
    });
  };

  const changeAcceptanceTime = (acceptanceTime: number | undefined): void => {
    onChangeAcceptanceTime?.({
      layerId: layerId!,
      acceptanceTime,
    });
  };

  const deleteLayer = (): void => onLayerDelete!({ mapId, layerId: layerId! });

  renderCounter.count(LayerRowUnstyled.name);

  return (
    // className needed for styled() utility
    <div className={className}>
      <Grid
        container
        data-testid={`layerRow-${layerId}`}
        className="layerRow"
        alignItems="center"
        role="listitem"
      >
        <Accordion
          LeftButtons={
            <LeftButtons
              size="-medium"
              dragHandle={dragHandle}
              layer={layer}
              layerMissingDataLayout={layerMissingDataLayout}
              layerEnableLayout={layerEnableLayout}
              isEnabled={isEnabled}
              disableActivateLayer={disableActivateLayer}
              layerActiveLayout={layerActiveLayout}
              onChangeEnable={changeEnable}
              onChangeActive={onClickRow}
            />
          }
          layerName={layerName}
          Styles={
            layerStylesLayout || (
              <Styles style={layer!.style!} onChangeStyle={changeStyle} />
            )
          }
          Opacity={
            layerOpacityLayout || (
              <Opacity
                opacity={layer!.opacity!}
                onChangeOpacity={changeOpacity}
              />
            )
          }
          Acceptance={
            layerAcceptanceTimeLayout || (
              <Acceptance
                acceptanceTime={layer!.acceptanceTimeInMinutes}
                onChangeAcceptanceTime={changeAcceptanceTime}
              />
            )
          }
          LoadDuration={
            layerLoadDurationLayout || (
              <LoadDuration layerId={layerId!} mapId={mapId} />
            )
          }
          ShowLayerInfo={
            layerShowLayerInfoLayout || (
              <ShowLayerInfo onClick={(): void => {}} />
            )
          }
          DeleteLayer={
            layerDeleteLayout || <DeleteLayer onClick={deleteLayer} />
          }
        />
        <Grid className={columnClasses.column1} alignItems="center">
          <LeftButtons
            dragHandle={dragHandle}
            layer={layer}
            layerMissingDataLayout={layerMissingDataLayout}
            layerEnableLayout={layerEnableLayout}
            isEnabled={isEnabled}
            disableActivateLayer={disableActivateLayer}
            layerActiveLayout={layerActiveLayout}
            onChangeEnable={changeEnable}
            onChangeActive={onClickRow}
          />
        </Grid>
        <RowItemCollapse
          className={columnClasses.column2}
          collapsedColumns={collapsedColumns}
        >
          {layerServicesLayout || (
            <RenderLayers
              layerName={layer!.name!}
              layers={
                (services &&
                  layer!.service &&
                  services[layer!.service] &&
                  services[layer!.service].layers) ||
                []
              }
              onChangeLayerName={(name): void => {
                onLayerChangeName!({ layerId: layer!.id!, name });
              }}
            />
          )}
        </RowItemCollapse>
        {isLayerMissing ? (
          <Grid className={columnClasses.column7}>
            <AlertBanner title={t('layermanager-layer-error')} isCompact />
          </Grid>
        ) : (
          <>
            <RowItemCollapse
              className={columnClasses.column3}
              collapsedColumns={collapsedColumns}
            >
              {layerStylesLayout || (
                <Styles style={layer!.style!} onChangeStyle={changeStyle} />
              )}
            </RowItemCollapse>
            <RowItemCollapse
              className={columnClasses.column4}
              collapsedColumns={collapsedColumns}
            >
              {layerOpacityLayout || (
                <Opacity
                  opacity={layer!.opacity!}
                  onChangeOpacity={changeOpacity}
                />
              )}
            </RowItemCollapse>
            <RowItemCollapse
              className={columnClasses.column5}
              collapsedColumns={collapsedColumns}
            >
              {layerDimensionLayout || (
                <DimensionSelect
                  layerDimensions={layerUtils.filterNonTimeDimensions(
                    layer!.dimensions!,
                  )}
                  onLayerChangeDimension={(
                    dimensionName: string,
                    dimensionValue: string,
                  ): void => {
                    const dimension: mapTypes.Dimension = {
                      name: dimensionName,
                      currentValue: dimensionValue,
                    };
                    onLayerChangeDimension!({
                      origin: 'layerrow',
                      layerId: layerId!,
                      dimension,
                    });
                  }}
                />
              )}
            </RowItemCollapse>
            <RowItemCollapse
              className={columnClasses.acceptanceTime}
              collapsedColumns={collapsedColumns}
            >
              {layerAcceptanceTimeLayout || (
                <Acceptance
                  acceptanceTime={layer!.acceptanceTimeInMinutes}
                  onChangeAcceptanceTime={changeAcceptanceTime}
                />
              )}
            </RowItemCollapse>
            <Grid
              display="flex"
              justifyContent="flex-end"
              className={columnClasses.loadDuration}
            >
              {layerLoadDurationLayout || (
                <LoadDuration layerId={layerId!} mapId={mapId} />
              )}
            </Grid>
          </>
        )}
        <Grid className={columnClasses.column6} alignItems="center">
          {layerShowLayerInfoLayout || (
            <ShowLayerInfo onClick={(): void => {}} />
          )}
          {layerDeleteLayout || <DeleteLayer onClick={deleteLayer} />}
          {layerMenuLayout || (
            <LayerManagerMenuButton
              mapId={mapId}
              layerId={layerId}
              onLayerDuplicate={onLayerDuplicate}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const LayerRow = styled(LayerRowUnstyled)<{ isEnabled?: boolean }>(({
  theme,
  isEnabled = true,
}) => {
  return {
    '& .MuiInputBase-root': {
      ...tooltipContainerStyles(theme, isEnabled),
    },
    '& .layerRow': {
      backgroundColor: isEnabled
        ? theme.palette.geowebColors.layerManager.tableRowDefaultCardContainer
            .fill
        : theme.palette.geowebColors.layerManager.tableRowDisabledCardContainer
            .fill,
      border: '1px solid',
      borderColor: isEnabled
        ? theme.palette.geowebColors.layerManager.tableRowDefaultCardContainer
            .borderColor
        : theme.palette.geowebColors.layerManager.tableRowDisabledCardContainer
            .borderColor,
      borderRadius: 1 * theme.shape.borderRadius,
      marginBottom: theme.spacing(0.25),
      minHeight: '32px',
    },
  };
});

export default LayerRow;

const Accordion: FC<{
  Styles: React.ReactNode;
  ShowLayerInfo: React.ReactNode;
  DeleteLayer: React.ReactNode;
  Acceptance: React.ReactNode;
  Opacity: React.ReactNode;
  layerName?: string;
  LeftButtons: React.ReactNode;
  LoadDuration: React.ReactNode;
}> = ({
  Styles,
  Acceptance,
  ShowLayerInfo,
  DeleteLayer,
  Opacity,
  layerName,
  LeftButtons,
  LoadDuration,
}) => {
  const { t } = useCoreTranslation();
  return (
    <CustomAccordion
      className="medium-layermanager"
      defaultExpanded={false}
      sx={{
        minHeight: 33,
        margin: 0,
        width: '100%',
        border: 'none',
        backgroundColor: 'inherit',
        '.MuiAccordionSummary-content': {
          margin: 0,
          padding: 0,
        },
      }}
      title={
        <>
          {LeftButtons}
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {layerName}
          </Typography>
        </>
      }
    >
      <Grid container sx={{ padding: '16px 32px' }} spacing={3}>
        <Grid container>
          <Grid>{t('layermanager-style-title')}</Grid>
          {Styles}
        </Grid>
        <Grid
          container
          sx={{
            '.MuiButtonBase-root': {
              justifyContent: 'flex-start',
            },
          }}
        >
          <Grid>{t('layermanager-opacity-title')}</Grid>
          {Opacity}
        </Grid>
        <Grid container>
          <Grid>{t('layermanager-acceptance-time')}</Grid>
          {Acceptance}
        </Grid>
        <Grid container flexDirection="column">
          <Grid>
            <CloudLoading />
          </Grid>
          {LoadDuration}
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          <Grid sx={{}}>{ShowLayerInfo}</Grid>
          <Grid sx={{}}>{DeleteLayer}</Grid>
        </Grid>
      </Grid>
    </CustomAccordion>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  ReactHookFormRadioGroup,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { RadioButtonAndLabel } from './RadioButtonAndLabel';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { FormFieldProps } from '../../../types';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

const Change: React.FC<FormFieldProps> = ({
  isDisabled,
  isReadOnly,
}: FormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { isRequired: isRequiredField } = useDraftFormHelpers();
  const { isShortTest } = useShortTestHelpers();
  const isRequired = (value: string): string | boolean =>
    isShortTest() || isRequiredField(value);
  const fieldsDisabled = isDisabled || isShortTest();

  return (
    <ProductFormFieldLayout
      title={t('change')}
      sx={{
        '.MuiButtonBase-root.MuiRadio-root': {
          padding: '6px',
          marginLeft: '3px',
        },
        '.MuiTypography-root.MuiTypography-subtitle1': {
          marginTop: 1,
        },
        marginTop: -1,
      }}
    >
      <ReactHookFormRadioGroup
        name="change"
        rules={{ validate: { isRequired } }}
        disabled={fieldsDisabled}
        isReadOnly={isReadOnly}
      >
        <RadioButtonAndLabel
          value="WKN"
          label={t('weakening')}
          disabled={fieldsDisabled}
          data-testid="change-WKN"
        />
        <RadioButtonAndLabel
          value="NC"
          label={t('no-change')}
          disabled={fieldsDisabled}
          data-testid="change-NC"
        />
        <RadioButtonAndLabel
          value="INTSF"
          label={t('intensifying')}
          disabled={fieldsDisabled}
          data-testid="change-INTSF"
        />
      </ReactHookFormRadioGroup>
    </ProductFormFieldLayout>
  );
};

export default Change;

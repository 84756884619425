/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, BoxProps, Stack } from '@mui/material';
import * as React from 'react';
import FilterList, { ListFilter } from './FilterList';

import SearchField from './SearchField';

export const DEFAULT_FILTER_HEIGHT = 85;

interface FilterProps extends BoxProps {
  filters: ListFilter[];
  onClickFilterChip?: (id: string, selected: boolean) => void;
  onSearch?: (value: string) => void;
  isAllSelected?: boolean;
  searchQuery?: string;
}

const Filter = React.forwardRef<HTMLElement, FilterProps>(
  (
    {
      filters,
      onClickFilterChip = (): void => {},
      onSearch = (): void => {},
      isAllSelected,
      searchQuery,
      ...props
    }: FilterProps,
    ref: React.Ref<HTMLElement>,
  ) => {
    return (
      <Box sx={{ marginBottom: '8px', ...props.sx }} ref={ref}>
        <Stack spacing={1}>
          <SearchField value={searchQuery} valueChanged={onSearch} />
          <FilterList
            filters={filters}
            onChipClick={onClickFilterChip}
            isAllSelected={isAllSelected}
          />
        </Stack>
      </Box>
    );
  },
);

export default Filter;

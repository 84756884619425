/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { connect } from 'react-redux';

import {
  layerActions,
  mapSelectors,
  layerTypes,
  CoreAppStore,
  serviceTypes,
} from '@opengeoweb/store';
import { LayerType, webmapUtils } from '@opengeoweb/webmap';
import WMSLayerTree from './WMSLayerTree';

export interface WMSLayerTreeConnectProps {
  service: serviceTypes.InitialService;
  addLayer?: typeof layerActions.addLayer;
  setBaseLayers?: typeof layerActions.setBaseLayers;
  addAvailableBaseLayer?: typeof layerActions.addAvailableBaseLayer;
  mapId: string;
  loadedLayers?: layerTypes.Layer[];
  layerType?: LayerType;
}

const connectRedux = connect(
  (store: CoreAppStore, props: WMSLayerTreeConnectProps) => ({
    loadedLayers: mapSelectors.getMapLayers(store, props.mapId),
  }),
  {
    addLayer: layerActions.addLayer,
    setBaseLayers: layerActions.setBaseLayers,
    addAvailableBaseLayer: layerActions.addAvailableBaseLayer,
  },
);

const ConnectedTree: React.FC<WMSLayerTreeConnectProps> = ({
  addLayer,
  setBaseLayers,
  addAvailableBaseLayer,
  service,
  mapId,
  loadedLayers,
  layerType = LayerType.mapLayer,
}: WMSLayerTreeConnectProps) => {
  const addMapLayer = (serviceURL: string, layerName: string): void => {
    const newWms = {
      service: serviceURL,
      name: layerName,
      id: webmapUtils.generateLayerId(),
      layerType: LayerType.mapLayer,
    };
    addLayer!({
      mapId,
      layerId: newWms.id,
      layer: newWms,
      origin: layerTypes.LayerActionOrigin.wmsLoader,
    });
  };

  const addBaseLayer = (serviceURL: string, layerName: string): void => {
    const newBaseLayer = {
      service: serviceURL,
      name: layerName,
      id: webmapUtils.generateLayerId(),
      layerType: LayerType.baseLayer,
      mapId,
    };
    addAvailableBaseLayer!({ layer: newBaseLayer });
    setBaseLayers!({
      mapId,
      layers: [newBaseLayer],
      origin: layerTypes.LayerActionOrigin.wmsLoader,
    });
  };

  return (
    <WMSLayerTree
      service={service}
      onClickLayer={(serviceURL, layerName): void => {
        if (layerType === LayerType.baseLayer) {
          addBaseLayer(serviceURL, layerName);
        } else {
          addMapLayer(serviceURL, layerName);
        }
      }}
      highlightedLayers={loadedLayers!}
    />
  );
};

const WMSLayerTreeConnect = connectRedux(ConnectedTree);

export default WMSLayerTreeConnect;

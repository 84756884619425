/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { selectorMemoizationOptions } from '@opengeoweb/store';
import { PublicWarningsState } from './types';
import { publicWarningsAdapter } from './reducer';
import { WarningModuleStore } from '../types';
import { initialFilters } from '../../components/FilterList/filter-utils';

export const getPublicWarningsStore = (
  store: WarningModuleStore,
): PublicWarningsState => {
  if (store && store.publicWarnings) {
    return store.publicWarnings;
  }
  return null!;
};

export const { selectAll: selectAllPublicWarnings } =
  publicWarningsAdapter.getSelectors((state: WarningModuleStore) => {
    return state?.publicWarnings?.warnings ?? { entities: {}, ids: [] };
  });

export const isPublicWarningsLoading = createSelector(
  getPublicWarningsStore,
  (store) => store?.isLoading || false,
);

export const getPublicWarningsError = createSelector(
  getPublicWarningsStore,
  (store) => store?.error,
);

export const getPublicWarningsLastUpdatedTime = createSelector(
  getPublicWarningsStore,
  (store) => store?.lastUpdatedTime,
);

const getWarningsFilterState = createSelector(
  getPublicWarningsStore,
  (store) => store?.filterState,
  selectorMemoizationOptions,
);

export const getWarningsFilterById = createSelector(
  getWarningsFilterState,
  (_store: WarningModuleStore, panelId?: string) => panelId,
  (filterState, panelId) =>
    panelId && filterState && filterState[panelId]
      ? filterState[panelId]
      : initialFilters,
  selectorMemoizationOptions,
);

export const getWarningsPresetById = createSelector(
  getWarningsFilterState,
  (_store: WarningModuleStore, panelId?: string) => panelId,
  (filterState, panelId) => {
    if (!panelId || !filterState || !filterState[panelId]) {
      return { filterState: null };
    }
    return {
      filterState: filterState[panelId],
    };
  },
  selectorMemoizationOptions,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PROJECTION } from '@opengeoweb/shared';

export const WMEmptyLayerName = 'empty_layer';
export const WMEmptyLayerTitle = 'empty layer';
export const WMDateOutSideRange = 'outside range';
export const WMDateTooEarlyString = 'date too early';
export const WMDateTooLateString = 'date too late';
export const WMDateUnit = 'ISO8601';

export const WMInvalidDateValues = new Set<string>([
  WMDateOutSideRange,
  WMDateTooEarlyString,
  WMDateTooLateString,
]);

export const WMSJSMAP_MINIMUM_MAP_WIDTH = 4;
export const WMSJSMAP_MINIMUM_MAP_HEIGHT = 4;

export const WMSVersion = {
  version100: '1.0.0',
  version111: '1.1.1',
  version130: '1.3.0',
};
export const WMProj4Defs = [
  [
    PROJECTION.EPSG_4326.value,
    '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees',
  ],
  [
    PROJECTION.EPSG_4269.value,
    '+title=NAD83 (long/lat) +proj=longlat +a=6378137.0 +b=6356752.31414036 +ellps=GRS80 +datum=NAD83 +units=degrees',
  ],
  [
    PROJECTION.EPSG_3575.value,
    '+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
  ],
  [
    PROJECTION.EPSG_3785.value,
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
  ],
  [
    PROJECTION.EPSG_3857.value,
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
  ],
  [
    PROJECTION.EPSG_3411.value,
    '+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +a=6378273 +b=6356889.449 +units=m +no_defs',
  ],
  [
    PROJECTION.EPSG_3412.value,
    '+proj=stere +lat_0=-90 +lat_ts=-70 +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=6378273 +b=6356889.449 +units=m +no_defs',
  ],
  [
    PROJECTION.EPSG_28992.value,
    '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +no_defs  <>',
  ],
  [
    PROJECTION.EPSG_32661.value,
    '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=0 +k=0.994 +x_0=2000000 +y_0=2000000 +ellps=WGS84 +datum=WGS84 +units=m +no_defs',
  ],
  [
    PROJECTION.EPSG_102100.value,
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
  ],
  [
    PROJECTION.EPSG_3067.value,
    '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
  ],
];

export const mapImageStoreLength = 1000;
export const bgImageStoreLength = 360;
export const legendImageStoreLength = 500;

export const EVENT_GETCAPABILITIES_START = 'onstartgetcapabilities';
export const EVENT_GETCAPABILITIES_READY = 'onreadygetcapabilities';

export const WMJSMAP_LONLAT_EPSGCODE = PROJECTION.EPSG_4326.value;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { dateUtils } from '@opengeoweb/shared';
import { SeverityStyles } from '../../types';
import { useCapTranslation } from '../../../utils/i18n';

const severityStyles: SeverityStyles = {
  Extreme: {
    border: 'solid 5px rgb(255, 9, 9)',
    background: 'rgba(255, 9, 9, 0.15)',
  },
  Severe: {
    border: 'solid 5px rgb(255, 165, 0)',
    background: 'rgba(255, 165, 0, 0.15)',
  },
  Moderate: {
    border: 'solid 5px rgb(248, 248, 0)',
    background: 'rgba(248, 248, 0, 0.15)',
  },
  Minor: {
    border: 'solid 5px rgb(106, 248, 108)',
    background: 'rgba(106, 248, 108, 0.15)',
  },
};
export interface WarningListRowCapProps {
  event: string;
  severity: string;
  expires: string;
}

export const WarningListRowCap: React.FC<WarningListRowCapProps> = ({
  event,
  severity,
  expires,
}) => {
  const { t } = useCapTranslation();
  return (
    <Grid
      container
      sx={{
        border: severityStyles[severity as keyof SeverityStyles].border,
        background: severityStyles[severity as keyof SeverityStyles].background,
        padding: '5px',
        marginTop: '20px',
        marginBottom: '20px',
        width: '100%',
      }}
    >
      <Grid size={{ xs: 4 }}>
        <Typography variant="overline">
          {t('warning-list-row-event')}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 'normal' }}
          data-testid="event"
        >
          {event ? `${event}` : ''}
        </Typography>
      </Grid>
      <Grid size={{ xs: 4 }}>
        <Typography variant="overline">
          {t('warning-list-row-severity')}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 'normal' }}
          data-testid="severity"
        >
          {severity ? `${severity}` : ''}
        </Typography>
      </Grid>
      <Grid size={{ xs: 4 }}>
        <Typography variant="overline">
          {t('warning-list-row-expires')}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 'normal' }}
          data-testid="expires"
        >
          {expires
            ? dateUtils.dateToString(
                dateUtils.createDate(expires),
                "dd MMM yyyy, HH:mm 'UTC'",
              )
            : t('warning-list-row-not-expired')}
        </Typography>
      </Grid>
    </Grid>
  );
};

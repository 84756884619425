/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  defaultFormOptions,
  ReactHookFormProvider,
} from '@opengeoweb/form-fields';
import { ConfirmationDialog } from '@opengeoweb/shared';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  WorkspacePresetAction,
  WorkspacePresetFromBE,
} from '../../store/workspace/types';
import {
  WorkspaceListError,
  WorkspacePresetFormValues,
} from '../../store/workspaceList/types';
import { useWorkspaceTranslation } from '../../utils/i18n';
import WorkspacePresetsForm from './WorkspacePresetsForm';

export interface WorkspacePresetsFormDialogProps {
  isOpen: boolean;
  action: WorkspacePresetAction;
  presetId: string;
  formValues: WorkspacePresetFormValues;
  onFormSubmit: (
    action: WorkspacePresetAction,
    presetId: string,
    data: WorkspacePresetFromBE,
  ) => void;
  onClose: () => void;
  isLoading: boolean;
  error?: WorkspaceListError;
  isWorkspaceSaved?: boolean;
}

const WorkspacePresetsFormDialog: React.FC<WorkspacePresetsFormDialogProps> = ({
  isOpen,
  action,
  presetId,
  formValues,
  onClose,
  onFormSubmit,
  isLoading = false,
  error,
  isWorkspaceSaved = false,
}: WorkspacePresetsFormDialogProps) => {
  const { handleSubmit: handleFormSubmit, reset } =
    useFormContext<WorkspacePresetFromBE>();
  const { t } = useWorkspaceTranslation();

  React.useEffect(() => {
    if (
      action !== WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS &&
      action !== WorkspacePresetAction.SAVE_SYSTEM_PRESET &&
      action !== WorkspacePresetAction.SAVE_AS &&
      action !== WorkspacePresetAction.SAVE
    ) {
      reset(formValues);
    }
  }, [formValues, reset, action]);

  const getConfirmLabel = (action: WorkspacePresetAction): string => {
    switch (action) {
      case WorkspacePresetAction.DELETE:
      case WorkspacePresetAction.DELETE_SYSTEM_PRESET: {
        return t('workspace-delete');
      }
      case WorkspacePresetAction.RESET: {
        return t('workspace-reset');
      }
      default: {
        return t('workspace-save');
      }
    }
  };

  const getDialogTitle = (action: WorkspacePresetAction): string => {
    switch (action) {
      case WorkspacePresetAction.DELETE: {
        return t('workspace-dialog-delete');
      }
      case WorkspacePresetAction.DELETE_SYSTEM_PRESET: {
        return t('workspace-dialog-delete-system');
      }
      case WorkspacePresetAction.DUPLICATE: {
        return t('workspace-duplicate');
      }
      case WorkspacePresetAction.EDIT: {
        return t('workspace-edit');
      }
      case WorkspacePresetAction.SAVE: {
        return t('workspace-admin-save-user-workspace');
      }
      case WorkspacePresetAction.SAVE_SYSTEM_PRESET: {
        return t('workspace-admin-save-workspace');
      }
      case WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS: {
        return t('workspace-admin-save-new-workspace');
      }
      case WorkspacePresetAction.RESET: {
        return t('workspace-reset-title');
      }
      default: {
        return t('workspace-save-as');
      }
    }
  };

  const confirmLabel = getConfirmLabel(action);

  const onSubmit = async (): Promise<void> => {
    await handleFormSubmit((newFormValues: WorkspacePresetFromBE): void => {
      const { title, abstract, ...otherFormValues } = newFormValues;
      const trimmedTitle = title?.trim();
      const trimmedAbstract = abstract?.trim();
      const parsedFormValues = {
        ...otherFormValues,
        title: trimmedTitle,
        abstract: trimmedAbstract,
      };

      onFormSubmit(action, presetId, parsedFormValues);
    })();
  };

  const cancelLabel = t('workspace-cancel');

  return (
    <ConfirmationDialog
      data-testid="workspace-preset-dialog"
      title={getDialogTitle(action)}
      open={isOpen}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      description=""
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      sx={{ width: '330px' }}
      content={
        <WorkspacePresetsForm
          formValues={formValues}
          error={error}
          onSubmit={onSubmit}
          action={action}
          isLoading={isLoading}
          hasSavedWorkspace={isWorkspaceSaved}
        />
      }
    />
  );
};

const WorkspacePresetsFormDialogWrapper: React.FC<
  WorkspacePresetsFormDialogProps
> = ({ ...props }: WorkspacePresetsFormDialogProps) => (
  <ReactHookFormProvider
    options={{
      ...defaultFormOptions,
      defaultValues: props.formValues,
    }}
  >
    <WorkspacePresetsFormDialog {...props} />
  </ReactHookFormProvider>
);

export default WorkspacePresetsFormDialogWrapper;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Card, Tab, Tabs, Box } from '@mui/material';
import React from 'react';
import { TafFromBackend } from '../../../types';
import { TabPanel } from '../../TabPanel';
import TafHeader from '../../TafHeader/TafHeader';
import { sortTafsOnLocation, muiTabA11yProps } from '../utils';

import TacContainer from './TacContainer';
import { useTafTranslation } from '../../../utils/i18n';

interface TacOverviewProps {
  upcomingTafs: TafFromBackend[];
  currentTafs: TafFromBackend[];
  expiredTafs: TafFromBackend[];
  hideTitle?: boolean;
  activeTaf?: TafFromBackend;
  isFormDisabled?: boolean;
}

const TacOverview: React.FC<TacOverviewProps> = ({
  upcomingTafs = [],
  currentTafs = [],
  expiredTafs = [],
  hideTitle = false,
  activeTaf = null!,
  isFormDisabled = false,
}: TacOverviewProps) => {
  const { t } = useTafTranslation();
  const [activeTab, setActiveTab] = React.useState(0);
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const tabs = [
    {
      title: t('top-tabs-upcoming'),
      tafList: upcomingTafs,
    },
    {
      title: t('top-tabs-current-expired'),
      tafList: sortTafsOnLocation([...currentTafs, ...expiredTafs]),
    },
  ];

  const activeLocation = activeTaf?.taf.location || null;

  const isActiveLocation = (location: string): boolean =>
    location === activeLocation;

  const scrollToPosition = React.useCallback((): void => {
    if (!scrollRef.current) {
      return;
    }
    const containerTop = scrollRef.current.getBoundingClientRect().top;
    const firstLocation = scrollRef.current.querySelector(
      `[data-location="${activeLocation}"]`,
    );
    const paddingTop = parseInt(
      getComputedStyle(scrollRef.current).paddingTop,
      10,
    );
    const firstLocationTop = firstLocation?.getBoundingClientRect().top!;
    const newScrollPosition =
      firstLocationTop -
      containerTop +
      scrollRef.current.scrollTop -
      paddingTop;

    scrollRef.current.scrollTo(newScrollPosition, newScrollPosition);
  }, [activeLocation]);

  const resetScrollPosition = (): void => scrollRef.current!.scrollTo(0, 0);

  const onChangeTab = (
    _event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setActiveTab(newValue);
    resetScrollPosition();
  };

  React.useEffect(() => {
    if (activeLocation) {
      scrollToPosition();
    }
  }, [activeLocation, scrollToPosition]);

  React.useEffect(() => {
    const SCROLL_DELAY = 1000;
    const timer = setTimeout(() => {
      scrollToPosition();
    }, SCROLL_DELAY);

    return (): void => {
      clearTimeout(timer);
    };
  }, [activeTab, scrollToPosition]);

  return (
    <Card
      variant="outlined"
      sx={{
        maxHeight: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          zIndex: 1,
        }}
      >
        {!hideTitle && <TafHeader title={t('tac-overview')} />}

        <Tabs value={activeTab} onChange={onChangeTab} aria-label="tabs">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.title}
              sx={{
                flexGrow: 1,
              }}
              label={tab.title}
              data-testid={`tab-${index}`}
              {...muiTabA11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>

      <Box
        ref={scrollRef}
        sx={{
          padding: { xs: '12px', sm: '6px', md: '16px', lg: '24px' },
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        {tabs.map((tab, index) => (
          <TabPanel key={tab.title} value={activeTab} index={index}>
            {tab.tafList.map(({ taf }) => (
              <TacContainer
                key={taf.uuid}
                taf={taf}
                isActive={isActiveLocation(taf.location)}
                isFormDisabled={isFormDisabled}
              />
            ))}
          </TabPanel>
        ))}
      </Box>
    </Card>
  );
};

export default TacOverview;

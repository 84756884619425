/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useState } from 'react';
import { Grid2 as Grid, SxProps, TextField } from '@mui/material';

interface GeoJSONTextFieldProps {
  geoJSON: GeoJSON.FeatureCollection;
  onChangeGeoJSON?: (geoJSON: GeoJSON.FeatureCollection) => void;
  title?: string;
  sx?: SxProps;
  maxRows?: number;
}

const GeoJSONTextField: React.FC<GeoJSONTextFieldProps> = ({
  onChangeGeoJSON,
  geoJSON,
  title = 'GeoJSON text',
  sx,
  maxRows = 18,
}: GeoJSONTextFieldProps) => {
  const [isValid, setValidity] = useState<boolean>(true);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      onChangeGeoJSON!(JSON.parse(event.target.value));
      setValidity(true);
    } catch (e) {
      onChangeGeoJSON!(null!);
      setValidity(false);
    }
  };
  return (
    <Grid size={{ xs: 12 }} sx={sx}>
      <TextField
        sx={{
          padding: 0,
          border: 'none',
          width: '100%',
          height: '100%',
          fontSize: '10px',
          '.MuiInputBase-root': {
            height: '100%',
            alignItems: 'baseline',
          },
        }}
        multiline
        error={!isValid}
        maxRows={maxRows}
        label={!isValid ? 'GeoJSON not valid' : title}
        variant="filled"
        onChange={onChange}
        value={JSON.stringify(geoJSON, null, 2)}
        disabled={!onChangeGeoJSON}
      />
    </Grid>
  );
};

export default GeoJSONTextField;

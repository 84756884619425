/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useSetupDialog,
  uiSelectors,
  uiTypes,
  loadingIndicatorActions,
  loadingIndicatorConstants,
  CoreAppStore,
} from '@opengeoweb/store';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { TimeSeriesService, useConfirmationDialog } from '@opengeoweb/shared';
import { Parameter, PlotPreset } from '../TimeSeries/types';
import { CustomSortableEvent, TimeSeriesManager } from './TimeSeriesManager';
import { getPlotState } from '../../store/selectors';
import { TimeSeriesSelectConnect } from '../TimeSeriesSelect/TimeSeriesSelectConnect';
import { timeSeriesActions } from '../../store';
import { ParameterInfoDialogConnect } from './ParameterInfo/ParameterInfoDialogConnect';
import {
  MoveParameterPayload,
  TimeSeriesActionOrigin,
  UpdateTitlePayload,
} from '../../store/types';
import { edrListener } from '../../utils/edrUtils';
import { useTimeseriesTranslation } from '../../utils/i18n';

export const DIALOG_TYPE_MANAGER = uiTypes.DialogTypes.TimeSeriesManager;

const origin = TimeSeriesActionOrigin.timeSeriesManager;

interface TimeSeriesManagerProps {
  preloadedTimeseriesServices?: TimeSeriesService[];
}
export const TimeSeriesManagerConnect: FC<TimeSeriesManagerProps> = ({
  preloadedTimeseriesServices,
}) => {
  const panelId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, uiTypes.DialogTypes.TimeSeriesManager),
  );
  const { setDialogOrder, dialogOrder, isDialogOpen, onCloseDialog } =
    useSetupDialog(DIALOG_TYPE_MANAGER);
  const { t } = useTimeseriesTranslation();
  const [selectPlotId, setSelectPlotId] = useState('');
  const dispatch = useDispatch();
  const confirmDialog = useConfirmationDialog();

  const plotState: PlotPreset | undefined = useSelector(getPlotState);

  React.useEffect(() => {
    const addServices = (timeSeriesServices: TimeSeriesService[]): void => {
      dispatch(timeSeriesActions.addServices({ timeSeriesServices }));
    };
    if (preloadedTimeseriesServices) {
      addServices(preloadedTimeseriesServices);
    }
  }, [preloadedTimeseriesServices, dispatch]);

  const showSnackbar = React.useCallback(
    (message: string) => {
      dispatch(
        snackbarActions.openSnackbar({
          type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
          message,
        }),
      );
    },
    [dispatch],
  );

  const deletePlotSnackbar = React.useCallback(
    (plotId: string) => {
      const message = `${t('timeseries-plot-removed', { id: plotId })}`;
      showSnackbar(message);
      dispatch(timeSeriesActions.deletePlot({ panelId, origin, plotId }));
    },
    [dispatch, panelId, showSnackbar, t],
  );

  const deletePlot = (id: string): void => {
    void confirmDialog({
      title: `${t('timeseries-remove')} ${t('timeseries-plot')}`,
      description: `${t('timeseries-remove-confirm', { id })}`,
      confirmLabel: `${t('timeseries-remove')}`,
      cancelLabel: `${t('timeseries-cancel')}`,
    }).then(() => {
      deletePlotSnackbar(id);
    });
  };

  const addPlot = (title: string): void => {
    dispatch(timeSeriesActions.addPlot({ panelId, origin, title }));
  };
  const deleteParameter = (parameterId: string): void => {
    dispatch(
      timeSeriesActions.deleteParameter({ panelId, origin, parameterId }),
    );
  };
  const togglePlot = (plotId: string): void => {
    dispatch(timeSeriesActions.togglePlot({ panelId, origin, plotId }));
  };
  const toggleParameter = (parameterId: string): void => {
    dispatch(
      timeSeriesActions.toggleParameter({ panelId, origin, parameterId }),
    );
  };
  const patchParameter = (parameter: Partial<Parameter>): void => {
    dispatch(timeSeriesActions.patchParameter({ panelId, origin, parameter }));
  };
  const movePlot = ({ oldIndex, newIndex }: CustomSortableEvent): void => {
    dispatch(
      timeSeriesActions.movePlot({ panelId, origin, oldIndex, newIndex }),
    );
  };
  const moveParameter = (payload: MoveParameterPayload): void => {
    dispatch(timeSeriesActions.moveParameter({ ...payload, panelId, origin }));
  };
  const updateTitle = (payload: UpdateTitlePayload): void => {
    dispatch(timeSeriesActions.updateTitle({ ...payload, panelId, origin }));
  };
  const addParameter = (parameter: Parameter): void => {
    dispatch(timeSeriesActions.addParameter({ panelId, origin, parameter }));
  };

  const edrObserver = useCallback(
    (param: unknown): void => {
      const startOrStopLoading = param as boolean;
      dispatch(
        loadingIndicatorActions.setEDRIsLoading({
          id: loadingIndicatorConstants.ANY_EDR_SERVICE,
          isEDRLoading: startOrStopLoading,
        }),
      );
    },
    [dispatch],
  );

  React.useEffect(() => {
    edrListener.addEventListener('edr', edrObserver);
    return (): void => {
      edrListener.removeEventListener('edr', edrObserver);
    };
  }, [edrObserver]);

  return (
    <>
      {plotState && (
        <TimeSeriesManager
          isOpen={isDialogOpen}
          onClose={onCloseDialog}
          onMouseDown={setDialogOrder}
          order={dialogOrder}
          plotState={plotState}
          addPlot={addPlot}
          deletePlot={deletePlot}
          togglePlot={togglePlot}
          addParameter={addParameter}
          deleteParameter={deleteParameter}
          toggleParameter={toggleParameter}
          setSelectPlotId={setSelectPlotId}
          selectPlotId={selectPlotId}
          patchParameter={patchParameter}
          movePlot={movePlot}
          moveParameter={moveParameter}
          updateTitle={updateTitle}
        />
      )}
      <TimeSeriesSelectConnect selectPlotId={selectPlotId} panelId={panelId} />
      <ParameterInfoDialogConnect />
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { CustomIconButton } from '@opengeoweb/shared';
import { useTimeseriesTranslation } from '../../../utils/i18n';
import { OverflowTooltip } from './OverflowTooltip';

export interface LocationListItemProps {
  location: string;
  handleChange: (newSelectedLocation: string) => void;
  selectedLocation: string;
}

export const LocationListItem: React.FC<LocationListItemProps> = ({
  location,
  handleChange,
  selectedLocation,
}) => {
  const theme = useTheme();
  const { t } = useTimeseriesTranslation();
  const mouseMoveRef = React.useRef<HTMLDivElement>(null);
  const textContainerRef = React.useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = React.useState(false);
  const disableONLYUntillImplementation = true;

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };
  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };
  const selectOnlyOne = (): void => {};

  const checkHover = (event: MouseEvent): void => {
    if (mouseMoveRef.current) {
      const mouseOver = mouseMoveRef.current.contains(event.target as Node);
      if (!isHovering && mouseOver) {
        handleMouseEnter();
      }
      if (isHovering && !mouseOver) {
        handleMouseLeave();
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('mousemove', checkHover, true);
    return (): void => {
      window.removeEventListener('mousemove', checkHover, true);
    };
  });

  const [textContainerWidth, setTextContainerWidth] = React.useState(0);

  React.useEffect(() => {
    if (textContainerRef.current) {
      setTextContainerWidth(textContainerRef.current.clientWidth);
    }
  }, [textContainerRef]);

  return (
    <OverflowTooltip text={location} textContainerWidth={textContainerWidth}>
      <div
        ref={mouseMoveRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
      >
        <ListItem
          data-testid="locationDropdownListItem"
          onClick={() => handleChange(location)}
          sx={{
            padding: '0px',
            paddingLeft: '0px',
            paddingRight: '50px',
            fontSize: '12px',
            backgroundColor: isHovering
              ? theme.palette.geowebColors.buttons.tool.mouseOver.fill
              : undefined,
          }}
        >
          <ListItemIcon sx={{ minWidth: '42px' }}>
            <Checkbox
              inputProps={{ 'aria-label': location }}
              color="secondary"
              checked={selectedLocation === location}
              sx={{
                paddingTop: '6px',
                paddingBottom: '7px',
                '&&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </ListItemIcon>

          <Typography
            ref={textContainerRef}
            variant="body2"
            sx={{
              fontSize: '16px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '75%',
            }}
          >
            {location}
          </Typography>

          {/* This feature is disabled untill the implementation continues */}
          {!disableONLYUntillImplementation ? (
            <CustomIconButton
              arial-label={`Only ${location}`}
              style={{ backgroundColor: 'transparent', width: 'auto' }}
              edge="end"
              onClick={(): void => selectOnlyOne()}
            >
              <Typography
                sx={{
                  color: 'geowebColors.buttons.primary.default.fill',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {t('timeseries-location-dropdown-only')}
              </Typography>
            </CustomIconButton>
          ) : null}
        </ListItem>
      </div>
    </OverflowTooltip>
  );
};

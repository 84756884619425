/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box, Grid2 as Grid } from '@mui/material';
import {
  FieldArrayWithId,
  FieldValues,
  SubmitHandler,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import Sortable from 'sortablejs';

import { useAuthenticationContext } from '@opengeoweb/authentication';
import {
  ReactHookFormHiddenInput,
  useDraftFormHelpers,
  HIDDEN_INPUT_HELPER_IS_DRAFT,
} from '@opengeoweb/form-fields';
import {
  SwitchButton,
  useConfirmationDialog,
  usePreventBrowserClose,
} from '@opengeoweb/shared';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import {
  BaseTaf,
  ChangeGroup,
  Taf,
  TafActions,
  TafFormData,
  TafFromBackend,
  TafFromFrontEnd,
} from '../../types';
import TafFormButtons from './TafFormButtons/TafFormButtons';
import {
  convertTafValuesToObject,
  getConfirmationDialogButtonLabel,
  getConfirmationDialogContent,
  getConfirmationDialogTitle,
  useDragOrder,
  SortableField,
  isTafAmendedCorrectedChanged,
  getFieldNames,
  emptyChangegroup,
  prepareTafValues,
  getIsFormDefaultDisabled,
  fromStatusToAction,
  clearTafFormData,
  getFormRowPosition,
} from './utils';
import DragHandle from './TafFormRow/DragHandle';
import { IssuesButton } from '../IssuesPane';
import { IssuesPanePosition } from '../IssuesPane/types';
import { useTafModuleContext } from '../TafModule/TafModuleProvider';
import { TafAvatar } from '../TafAvatar';
import TafFormRow from './TafFormRow/TafFormRow';
import { useTafTranslation } from '../../utils/i18n';

// prevents default functionality
const noop = (): void => {};

export interface TafFormProps {
  isDisabled?: boolean;
  setIsDisabled?: (isDisabled: boolean) => void;
  tafFromBackend: TafFromBackend;
  onFormAction?: (action: TafActions, formValues?: Taf) => Promise<boolean>;
  isIssuesPaneOpen?: boolean;
  setIsIssuesPaneOpen?: (isOpen: boolean, position: IssuesPanePosition) => void;
  onSwitchEditor?: (formValues: TafFromFrontEnd) => Promise<boolean>;
  fetchNewTafList?: () => Promise<TafFromBackend[]>;
  previousTaf?: Taf;
}

const TafForm: React.FC<TafFormProps> = React.memo(
  ({
    tafFromBackend,
    onFormAction = (): Promise<boolean> => null!,
    isIssuesPaneOpen = false,
    setIsIssuesPaneOpen = (): void => null!,
    isDisabled = false,
    setIsDisabled = (): void => null!,
    onSwitchEditor = (): Promise<boolean> => null!,
    fetchNewTafList = (): Promise<TafFromBackend[]> => null!,
    previousTaf = null!,
  }: TafFormProps) => {
    const { t } = useTafTranslation();
    const formRef = React.useRef<HTMLFormElement>(null);
    const { taf: originalTaf, editor } = tafFromBackend;
    const { auth } = useAuthenticationContext();
    const dispatch = useDispatch();
    const isEditor = editor === auth?.username || false;

    const isDefaultDisabled = getIsFormDefaultDisabled(originalTaf.status);

    const confirmDialog = useConfirmationDialog();
    const { toggleIsDraft, isRequired: isNotRequiredForAction } =
      useDraftFormHelpers();

    const {
      registerValidateForm,
      tafAction,
      setTafAction = (): void => {},
      onPreventCloseView = (): void => {},
      useFieldArrayMethods,
    } = useTafModuleContext();

    const { fields, insert, remove, move, replace } =
      useFieldArrayMethods as UseFieldArrayReturn<FieldValues, string>;

    const {
      handleSubmit,
      trigger,
      formState,
      getValues,
      setValue,
      resetField,
    } = useFormContext();

    const { onChangeOrder, onStartDrag, activeDraggingIndex } = useDragOrder(
      trigger,
      move,
    );
    const { errors, isDirty: isFormDirty } = formState;

    usePreventBrowserClose(isFormDirty && !isDisabled);

    // Needed to be able to show a confirmation dialog on closing parent view
    React.useEffect(() => {
      onPreventCloseView(isFormDirty);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormDirty]);

    const hiddenInputFields = [
      'baseTime',
      'issueDate',
      'uuid',
      'validDateStart',
      'validDateEnd',
      'status',
      'previousId',
      'previousValidDateStart',
      'previousValidDateEnd',
      HIDDEN_INPUT_HELPER_IS_DRAFT,
    ];

    const onSubmit = (data: TafFormData): void => {
      // eslint-disable-next-line no-console
      console.log('submit:', convertTafValuesToObject(data));
    };

    const showConfirmDialog = (action: TafActions): Promise<void> =>
      confirmDialog({
        title: getConfirmationDialogTitle(action, t),
        description: getConfirmationDialogContent(
          action,
          () =>
            isTafAmendedCorrectedChanged(
              // If already saved as draft amd/cor then send over the previous taf
              originalTaf.status === 'DRAFT_AMENDED' ||
                originalTaf.status === 'DRAFT_CORRECTED'
                ? previousTaf
                : originalTaf,
              convertTafValuesToObject(getValues() as TafFormData),
            ),
          t,
        ),
        confirmLabel: getConfirmationDialogButtonLabel(action, t),
      });

    const resetFormValues = (
      formData: TafFormData,
      keepDirty = false,
    ): void => {
      Object.keys(formData).forEach((key: string) => {
        if (!keepDirty) {
          resetField(key, {
            keepDirty,
            defaultValue: formData[key as keyof TafFormData],
          });
        }
        setValue(key, formData[key as keyof TafFormData], {
          shouldDirty: keepDirty,
        });

        replace(formData.changeGroups);
      });
    };

    const resetFormState = (
      resetDisabled: boolean,
      action: TafActions = undefined!,
      tafToReset = originalTaf,
    ): void => {
      const shouldAddEmptyChangGroup = !resetDisabled;

      const newTaf = prepareTafValues(
        tafToReset,
        action,
        shouldAddEmptyChangGroup,
      );
      resetFormValues(newTaf);
      setIsDisabled(resetDisabled);
      setTafAction(action);
    };

    const showSnackbar = React.useCallback(
      (message: string) => {
        dispatch(
          snackbarActions.openSnackbar({
            type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
            message,
          }),
        );
      },
      [dispatch],
    );

    const isLatestEditor = (
      latestEditor: string,
      userName: string,
    ): boolean => {
      return (
        latestEditor !== '' &&
        latestEditor !== null &&
        latestEditor !== userName
      );
    };

    const setIsEditor = async (checked: boolean): Promise<boolean> => {
      const username = checked ? auth?.username : '';

      // make sure to get the latest changes
      const latestList = await fetchNewTafList();
      const { editor: latestEditor } = latestList.find(
        (taf) => taf.taf.uuid === originalTaf.uuid,
      )!;

      if (username === latestEditor) {
        // do nothing, correct editor is already set
        return true;
      }

      if (!checked && isLatestEditor(latestEditor, auth?.username!)) {
        // do nothing, someone else is editor so user will already get set to viewer mode
        return true;
      }

      if (checked && isLatestEditor(latestEditor, auth?.username!)) {
        await confirmDialog({
          title: t('switch-title'),
          description: t('switch-description'),
          confirmLabel: t('switch-confirm'),
        });
      }

      const didSucceed = await onSwitchEditor({
        ...tafFromBackend,
        editor: username,
      });
      if (didSucceed) {
        showSnackbar(
          checked
            ? t('editor-on', { location: originalTaf.location })
            : t('editor-off', { location: originalTaf.location }),
        );
      }
      return didSucceed;
    };

    const onSwitchMode = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { checked } = event.target;
      if (!checked && isFormDirty) {
        void confirmDialog({
          title: t('confirm-dialog-title'),
          description: t('switch-description-viewer'),
          confirmLabel: t('switch-confirm-viewer'),
        }).then(async () => {
          const didSucceed = await setIsEditor(checked);
          if (didSucceed) {
            resetFormState(isDefaultDisabled, undefined);
          }
        });
      } else {
        void setIsEditor(checked);
      }
    };

    const onTafEditModeButtonPress = (action: TafActions): void => {
      switch (action) {
        case 'DRAFT':
        case 'DRAFT_AMEND':
        case 'DRAFT_CORRECT':
          toggleIsDraft(true);
          void handleSubmit((data: FieldValues) => {
            void onFormAction(
              action,
              convertTafValuesToObject(data as TafFormData),
            );
            resetFormValues(getValues() as TafFormData);
          })();
          break;
        case 'CLEAR':
          void showConfirmDialog(action).then(() => {
            const emptiedForm = clearTafFormData(getValues() as TafFormData);
            resetFormValues(emptiedForm, true);
            showSnackbar(t('clear-message'));
          });
          break;
        case 'AMEND':
        case 'CORRECT':
        case 'PUBLISH':
          toggleIsDraft(false);
          void handleSubmit(async (data) => {
            await showConfirmDialog(action);
            const didSucceed = await onFormAction(
              action,
              convertTafValuesToObject(data as TafFormData),
            );
            if (didSucceed) {
              resetFormState(
                true,
                null!,
                convertTafValuesToObject(data as TafFormData),
              );
            }
            return true;
          })().then(() => {
            const hasFormErrors = Object.keys(errors).length;
            const formNodeRef = formRef.current as HTMLElement;
            if (hasFormErrors && formNodeRef) {
              const inputsWithError =
                formNodeRef.querySelectorAll('.Mui-error');

              const firstFormGroupWithError = inputsWithError.length
                ? inputsWithError[inputsWithError.length - 1].closest(
                    '.formRow',
                  )
                : null;

              if (firstFormGroupWithError) {
                const { top, left } = getFormRowPosition(
                  firstFormGroupWithError,
                );
                const tafModuleNode = formNodeRef.closest('#tafmodule');
                const tafModuleNodeTop =
                  tafModuleNode?.getBoundingClientRect().top!;
                const newTop = top - tafModuleNodeTop || 0;

                setIsIssuesPaneOpen(true, { top: newTop, left });
              }
            }
          });

          break;
        default:
          void onFormAction(action);
          break;
      }
    };

    const onTafViewModeButtonPress = (action: TafActions): void => {
      switch (action) {
        case 'CANCEL':
          void showConfirmDialog(action).then(async () => {
            await onFormAction(
              action,
              convertTafValuesToObject(prepareTafValues(originalTaf, action)),
            );
          });
          break;
        default:
          resetFormState(false, action);
          break;
      }
    };

    const onRemoverow = (index: number): void => {
      void confirmDialog({
        title: t('options-delete'),
        description: t('confirm-delete-description'),
        confirmLabel: t('confirm-yes'),
      }).then(() => {
        remove(index);
        void trigger(); // trigger all fields validation
      });
    };

    // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/726 insert (react-hook-form) does not work well with focus (only last row will get focus), so added this dirty solution. This can be fixed in a better way in react-hook-form v7
    const focusFirstInput = (rowIndex: number): void => {
      const selector =
        rowIndex === -1
          ? `[name="baseForecast.wind"]`
          : `[name="changeGroups[${rowIndex}].probability"]`;

      const nextRowFirstInput: HTMLElement = document.querySelector(selector)!;
      if (nextRowFirstInput) {
        nextRowFirstInput.focus();
      }
    };

    // adding rows and validation don't work well combined, with always skipping validation of fields of rows above the one the inserted row. A setTimeout prevents this issue
    const setFocusAndValidate = (rowIndex: number): NodeJS.Timeout | number =>
      setTimeout(() => {
        focusFirstInput(rowIndex);
        void trigger(); // trigger all fields validation
      }, 0);

    const onAddRow = (rowIndex = -1, above = false): void => {
      const newRowIndex = above ? rowIndex : rowIndex + 1;
      insert(newRowIndex, emptyChangegroup, { shouldFocus: false });
      setFocusAndValidate(newRowIndex);
    };

    const onClearRow = (rowIndex = -1, isChangeGroup = false): void => {
      void confirmDialog({
        title: t('options-clear'),
        description: t('confirm-clear-description'),
        confirmLabel: t('confirm-yes'),
      }).then(() => {
        if (isChangeGroup) {
          remove(rowIndex);
          insert(rowIndex, emptyChangegroup, { shouldFocus: false });
        } else {
          const baseForecastFields = getFieldNames(false, -1);
          setValue(baseForecastFields.wind, '');
          setValue(baseForecastFields.visibility, '');
          setValue(baseForecastFields.weather1, '');
          setValue(baseForecastFields.weather2, '');
          setValue(baseForecastFields.weather3, '');
          setValue(baseForecastFields.cloud1, '');
          setValue(baseForecastFields.cloud2, '');
          setValue(baseForecastFields.cloud3, '');
          setValue(baseForecastFields.cloud4, '');
        }

        setFocusAndValidate(rowIndex);
      });
    };

    const [shouldValidate, setShouldValidate] = React.useState(false);

    React.useEffect(() => {
      setIsDisabled(!isEditor || isDefaultDisabled);
      // set validate flag to validate after the values have been set
      setShouldValidate(true);

      // register validate method to use in parent components
      const onValidateFormPromise = (): Promise<void | BaseTaf> =>
        new Promise((resolve, reject) => {
          toggleIsDraft(true);
          void handleSubmit((formData) => {
            resolve(convertTafValuesToObject(formData as TafFormData));
          })().then((formData): void => {
            if (formData === undefined) {
              reject();
            }
            resolve(formData);
          });
        });

      if (registerValidateForm) {
        registerValidateForm(onValidateFormPromise);
      }
      // set default taf action
      if (setTafAction) {
        setTafAction(fromStatusToAction(originalTaf.status));
      }

      return (): void => {
        resetFormValues({} as TafFormData);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      const newValues = prepareTafValues(
        originalTaf,
        undefined!,
        !isDefaultDisabled, // Only add empty changegroup rows if not yet published
      );
      // set new form values when taf values changed
      resetFormValues(newValues);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalTaf]);

    React.useEffect(() => {
      // update disabled state when editor changed
      setIsDisabled(!isEditor || isDefaultDisabled);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor]);

    React.useEffect(() => {
      if (shouldValidate && !isDisabled) {
        // trigger validation on all changegroup validity times after form is enabled since they could be outside the new validity period
        const { changeGroups = [] } = originalTaf;
        const validFields = changeGroups.map(
          (_field, index) => `changeGroups[${index}].valid`,
        );

        void trigger(validFields);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldValidate, isDisabled]);

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            position: 'absolute',
            top: { xs: '34px', sm: '-3px' },
            right: { xs: 'calc(50% -  70px)', sm: '8px' },
          }}
        >
          <SwitchButton checked={isEditor} onChange={onSwitchMode} />
          <Box sx={{ width: '16px', marginLeft: 1 }}>
            {editor && <TafAvatar editor={editor} />}
          </Box>
        </Box>
        <form
          data-testid="taf-form"
          onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
          ref={formRef}
        >
          {/* baseforecast  */}
          <TafFormRow
            disabled={isDisabled}
            onAddChangeGroupBelow={onAddRow}
            onClearRow={onClearRow}
            isNotRequiredForAction={isNotRequiredForAction}
          />
          {/* changegroups */}
          <ReactSortable
            tag="div"
            list={fields as SortableField[]}
            setList={noop} // we don't use this function because we order with our own methods
            animation={200}
            onSort={onChangeOrder}
            handle=".handle"
            direction="vertical"
            // hover props
            forceFallback={false}
            onStart={(event): void => {
              onStartDrag(event);
              if (Sortable.ghost) {
                Sortable.ghost.style.opacity = '1';
              }
            }}
          >
            {fields.map(
              (item: FieldArrayWithId<ChangeGroup>, index: number) => (
                <TafFormRow
                  field={item}
                  key={item.id}
                  index={index}
                  disabled={isDisabled}
                  onRemoveChangeGroup={onRemoverow}
                  onAddChangeGroupBelow={onAddRow}
                  onAddChangeGroupAbove={(): void => onAddRow(index, true)}
                  onClearRow={(): void => onClearRow(index, true)}
                  isChangeGroup
                  dragHandle={
                    <DragHandle
                      isDisabled={fields.length === 1 || isDisabled}
                      isSorting={activeDraggingIndex === index}
                      index={index}
                    />
                  }
                  isNotRequiredForAction={isNotRequiredForAction}
                />
              ),
            )}
          </ReactSortable>

          {!isDisabled && isEditor && (
            <Grid
              container
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginLeft: 1.5,
                marginTop: 1,
              }}
            >
              <Grid>
                <IssuesButton
                  errors={errors}
                  isIssuesPaneOpen={isIssuesPaneOpen}
                  setIsIssuesPaneOpen={setIsIssuesPaneOpen}
                />
              </Grid>
            </Grid>
          )}

          {isEditor && (
            <TafFormButtons
              isFormDisabled={isDisabled}
              tafAction={tafAction}
              canBe={tafFromBackend.canbe}
              onTafEditModeButtonPress={onTafEditModeButtonPress}
              onTafViewModeButtonPress={onTafViewModeButtonPress}
            />
          )}

          {hiddenInputFields.map((field) => (
            <ReactHookFormHiddenInput
              name={field}
              key={field}
              data-testid={field}
            />
          ))}
        </form>
      </>
    );
  },
  (prevProps, nextProps) => {
    // prevent re-render
    return (
      !nextProps.isDisabled &&
      prevProps.tafFromBackend !== nextProps.tafFromBackend &&
      // if editor changed it should rerender
      prevProps.tafFromBackend.editor === nextProps.tafFromBackend.editor
    );
  },
);

export default TafForm;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  uiActions,
  uiSelectors,
  layerTypes,
  uiTypes,
  mapSelectors,
  CoreAppStore,
  useSetupDialog,
  serviceTypes,
} from '@opengeoweb/store';

import {
  LayerSelectButtonConnect,
  LayerSelectConnect,
} from '@opengeoweb/layer-select';
import LayerManager from './LayerManager';

import { useFetchServices } from './useFetchServices';
import { sizeLarge } from './LayerManagerHeaderOptions';
import { returnCorrectSize } from './LayerManagerUtils';
import { LayerInfoDialogConnect } from '../LayerInfoConnect';
import { useCoreTranslation } from '../../utils/i18n';

export const getDialogType = (
  mapId: string,
  isMultiMap: boolean,
  isDocked: boolean,
): string => {
  if (isDocked) {
    return `${uiTypes.DialogTypes.DockedLayerManager}-${mapId}`;
  }
  if (isMultiMap) {
    return `${uiTypes.DialogTypes.LayerManager}-${mapId}`;
  }
  return uiTypes.DialogTypes.LayerManager;
};

interface LayerManagerConnectProps {
  mapId?: string;
  preloadedAvailableBaseLayers?: layerTypes.Layer[];
  preloadedMapServices?: serviceTypes.InitialService[];
  preloadedBaseServices?: serviceTypes.InitialService[];
  bounds?: string;
  title?: string;
  showMapIdInTitle?: boolean;
  isMultiMap?: boolean;
  isDocked?: boolean;
  source?: uiTypes.Source;
}

/**
 * Layer Managerconnected to the store displaying the layers forthe active map Id
 * Please note that in order to use this and open/close the dialog, every map on the screen that should be able to show
 * a dialog should have the <LayerManagerMapButtonConnect/> which is used to focus the correct map and open the dialog
 *
 * Expects the following props:
 * @param {serviceTypes.InitialService[]} preloadedMapServices preloadedMapServices: array of Service objects - contains an array of Service objects that are preloaded into in the WMS Loader for maplayers
 * @param {serviceTypes.InitialService[]} preloadedBaseServices preloadedBaseServices: array of Service objects - contains an array of Service objects that are preloaded into in the WMS Loader fpr baselayers
 * @param {Layer[]} preloadedAvailableBaseLayers preloadedAvailableBaseLayers: array of Layer objects - contains an array of Layer objects (consisting solely of type baseLayer) that are available to be shown as basemaps
 * ``` <LayerManagerConnect />```
 */
const LayerManagerConnect: React.FC<LayerManagerConnectProps> = ({
  preloadedAvailableBaseLayers,
  preloadedMapServices,
  preloadedBaseServices,
  bounds,
  title,
  showMapIdInTitle = false,
  mapId: initialMapId = null!,
  isMultiMap = false,
  isDocked = false,
  source = 'app',
}: LayerManagerConnectProps) => {
  const dispatch = useDispatch();
  const dialogType = getDialogType(initialMapId, isMultiMap, isDocked);

  const { t } = useCoreTranslation();
  const nonEmptyTitle = title ?? t('layermanager-title');

  const activeMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );

  // In case of a docked layer manager or multimap, use the map id that is passed
  // For floating layer managers, use the currently active, selected mapid
  const mapId = initialMapId || activeMapId;

  const dockedLayerManagerSize = useSelector((store: CoreAppStore) =>
    mapSelectors.getDockedLayerManagerSize(store, mapId),
  );

  const {
    dialogOrder,
    setDialogOrder,
    onCloseDialog,
    isDialogOpen,
    uiSource,
    uiIsLoading,
    uiError,
  } = useSetupDialog(dialogType, source);

  // Only fetch services for floating layer manager, not for docked!
  useFetchServices(dialogType, preloadedMapServices, isDocked);

  const onToggleDock = React.useCallback((): void => {
    onCloseDialog();
    if (isDocked) {
      // Close docked layer manager and open the floating layer manager
      dispatch(
        uiActions.setActiveMapIdForDialog({
          type: uiTypes.DialogTypes.LayerManager,
          mapId,
          setOpen: true,
          source,
        }),
      );
    } else {
      // Close the floating layer manager and open docked layer manager
      dispatch(
        uiActions.setToggleOpenDialog({
          type: `${uiTypes.DialogTypes.DockedLayerManager}-${mapId}`,
          setOpen: true,
        }),
      );
    }
  }, [dispatch, isDocked, mapId, onCloseDialog, source]);

  return React.useMemo(() => {
    const shownTitle = showMapIdInTitle
      ? `${nonEmptyTitle} ${mapId}`
      : nonEmptyTitle;
    return (
      <>
        <LayerManager
          mapId={mapId || initialMapId}
          preloadedAvailableBaseLayers={preloadedAvailableBaseLayers}
          preloadedBaseServices={preloadedBaseServices}
          bounds={bounds}
          isOpen={isDialogOpen}
          onClose={onCloseDialog}
          title={shownTitle}
          onMouseDown={setDialogOrder}
          order={dialogOrder}
          source={uiSource}
          size={
            isDocked ? returnCorrectSize(dockedLayerManagerSize) : sizeLarge
          }
          isLoading={uiIsLoading}
          error={uiError}
          onToggleDock={onToggleDock}
          headerSize="xs"
          isDockedLayerManager={isDocked}
          startPosition={
            isDocked ? { top: 60, right: 16 } : { top: 85, left: 50 }
          }
          addLayerComponent={
            <LayerSelectButtonConnect
              mapId={mapId}
              source={source}
              isMultiMap={isMultiMap}
            />
          }
        />

        {!isDocked && <LayerInfoDialogConnect />}
        {!isDocked && <LayerSelectConnect />}
      </>
    );
  }, [
    bounds,
    dialogOrder,
    dockedLayerManagerSize,
    initialMapId,
    isDialogOpen,
    isDocked,
    isMultiMap,
    mapId,
    onCloseDialog,
    onToggleDock,
    preloadedAvailableBaseLayers,
    preloadedBaseServices,
    setDialogOrder,
    showMapIdInTitle,
    nonEmptyTitle,
    uiError,
    uiIsLoading,
    uiSource,
    source,
  ]);
};

export default LayerManagerConnect;

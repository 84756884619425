/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box, Radio } from '@mui/material';

import { CustomTooltip } from '@opengeoweb/shared';
import { DrawMode } from '@opengeoweb/webmap-react';
import { getToolIcon } from '../utils';

const styles = {
  radioInput: {
    padding: 1,
    borderRadius: '5px',
    backgroundColor: 'geowebColors.buttons.tool.default.fill',
    color: 'geowebColors.buttons.tool.default.color',
    '&.Mui-checked': {
      backgroundColor: 'geowebColors.buttons.primary.default.fill',
      color: 'geowebColors.buttons.primary.default.color',
    },
  },
};

interface DrawToolsProps {
  activeTool: string;
  tools: DrawMode[];
  onChangeTool: (newToolMode: DrawMode) => void;
  isDisabled?: boolean;
}

const DrawTools: React.FC<DrawToolsProps> = ({
  activeTool,
  tools,
  onChangeTool,
  isDisabled = false,
}: DrawToolsProps) => {
  return (
    <Box
      data-testid="drawtools"
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      {tools.map((tool) => (
        <CustomTooltip title={tool.title} key={tool.drawModeId}>
          <Radio
            icon={getToolIcon(tool.selectionType)}
            value
            checkedIcon={getToolIcon(tool.selectionType)}
            checked={activeTool === tool.drawModeId}
            disabled={isDisabled}
            onChange={(): void => {
              onChangeTool(tool);
            }}
            data-testid={tool.drawModeId}
            sx={styles.radioInput}
          />
        </CustomTooltip>
      ))}
    </Box>
  );
};

export default DrawTools;

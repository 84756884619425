/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as uiSelectors from './selectors';
import { uiActions } from './reducer';
import type { uiTypes } from '.';
import { useAppSelector } from './selectors';

export interface SetupDialogReturnValue {
  setDialogOrder: () => void;
  dialogOrder: number;
  onCloseDialog: () => void;
  uiSource: uiTypes.Source;
  isDialogOpen: boolean;
  uiIsLoading: boolean;
  uiError: string;
}

export const useSetupDialog = (
  dialogType: uiTypes.DialogType,
  source: uiTypes.Source = 'app',
): SetupDialogReturnValue => {
  const dispatch = useDispatch();

  const onCloseDialog = useCallback((): void => {
    dispatch(
      uiActions.setToggleOpenDialog({
        type: dialogType,
        setOpen: false,
      }),
    );
  }, [dialogType, dispatch]);

  const registerDialog = useCallback((): void => {
    dispatch(
      uiActions.registerDialog({
        type: dialogType,
        setOpen: false,
        source,
      }),
    );
  }, [dialogType, dispatch, source]);

  const unregisterDialog = useCallback((): void => {
    dispatch(
      uiActions.unregisterDialog({
        type: dialogType,
      }),
    );
  }, [dialogType, dispatch]);

  // Register this dialog in the store
  useEffect(() => {
    registerDialog();
    return (): void => {
      unregisterDialog();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uiIsOrderedOnTop = useAppSelector((store) =>
    uiSelectors.getDialogIsOrderedOnTop(store, dialogType),
  );
  const setDialogOrder = useCallback((): void => {
    if (!uiIsOrderedOnTop) {
      dispatch(
        uiActions.orderDialog({
          type: dialogType,
        }),
      );
    }
  }, [dialogType, dispatch, uiIsOrderedOnTop]);

  const dialogOrder: number = useAppSelector((store) =>
    uiSelectors.getDialogOrder(store, dialogType),
  );

  const uiSource = useAppSelector((store) =>
    uiSelectors.getDialogSource(store, dialogType),
  );

  const isDialogOpen = useAppSelector((store) =>
    uiSelectors.getisDialogOpen(store, dialogType),
  );

  const uiIsLoading = useAppSelector((store) =>
    uiSelectors.getDialogIsLoading(store, dialogType),
  );

  const uiError = useAppSelector((store) =>
    uiSelectors.getDialogError(store, dialogType),
  );

  return {
    setDialogOrder,
    dialogOrder,
    onCloseDialog,
    uiSource,
    isDialogOpen,
    uiIsLoading,
    uiError,
  };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2025 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2025 - Finnish Meteorological Institute (FMI)
 * Copyright 2025 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Action, Reducer } from '@reduxjs/toolkit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReducerMap = Record<string, Reducer<any, Action<unknown>>>;

export const unifyReducerMaps = (reducerMaps: ReducerMap[]): ReducerMap => {
  const unifiedReducerMaps: ReducerMap = {};

  for (const reducersMap of reducerMaps) {
    for (const [key, reducer] of Object.entries(reducersMap)) {
      if (key in unifiedReducerMaps && unifiedReducerMaps[key] !== reducer) {
        throw new Error(
          `Reducer key ${key} is already defined with a different reducer`,
        );
      } else {
        unifiedReducerMaps[key] = reducer;
      }
    }
  }
  return unifiedReducerMaps;
};

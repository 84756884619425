/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { defaultGeoJSONStyleProperties } from '../geojsonShapes';

export const simplePolygonGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Polygon> =
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          stroke: '#8F8',
          'stroke-width': 4,
          'stroke-opacity': 1,
          fill: '#33ccFF',
          'fill-opacity': 0.5,
          country: 'Netherlands',
          details: [
            {
              event: 'Gale',
              sent: '2022-04-06T06:42:09+00:00',
              expires: '2022-04-06T14:00:00+00:00',
              area: '',
              web: '',
              feedAddress: 'https://alerts.fmi.fi/cap/feed/rss_en-GB.rss',
              identifier: '1.578.0.220406064209738.1298',
            },
          ],
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2.0167417739837967, 51.17995697758499],
              [4.257084968220221, 49.29134268590647],
              [8.635937575136854, 51.624654070318876],
              [8.228602448912055, 53.72352125601693],
              [6.191926817788037, 53.5423727705048],
              [2.0167417739837967, 51.17995697758499],
            ],
          ],
        },
      },
      {
        type: 'Feature',
        properties: {
          stroke: '#8F8',
          'stroke-width': 4,
          'stroke-opacity': 1,
          fill: '#33FFcc',
          'fill-opacity': 0.5,
          country: 'Finland',
          details: [
            {
              event: 'Gale',
              sent: '2022-04-06T06:42:09+00:00',
              expires: '2022-04-06T14:00:00+00:00',
              area: 'Helsinki',
              web: '',
              feedAddress: 'https://alerts.fmi.fi/cap/feed/rss_en-GB.rss',
              identifier: '2.49.0.1.578.0.220406064209738',
            },
          ],
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [26.253181784359604, 68.27806559514066],
              [20.855991361880957, 59.89443696044347],
              [29.308195231045634, 60.19950189496858],
              [33.78888161951847, 69.41694899886294],
              [28.493524978596028, 70.4304787447596],
              [26.253181784359604, 68.27806559514066],
            ],
          ],
        },
      },
      {
        type: 'Feature',
        properties: {
          stroke: '#8F8',
          'stroke-width': 4,
          'stroke-opacity': 1,
          fill: '#ffcc33',
          'fill-opacity': 0.5,
          country: 'Norway',
          warning: 'Wind',
          details: [
            {
              event: 'Gale',
              sent: '2022-04-06T06:42:09+00:00',
              expires: '2022-04-06T14:00:00+00:00',
              area: 'Andenes - Hekkingen',
              web: 'https://www.met.no/vaer-og-klima/Ekstremvaervarsler-og-andre-farevarsler',
              feedAddress: 'https://alerts.fmi.fi/cap/feed/rss_en-GB.rss',
              identifier: '2.49.0.1.578.0.220406064209738.1298',
            },
          ],
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [5.377256565338432, 62.16231256814888],
              [5.580924128450831, 58.111828399316614],
              [10.061610516923672, 58.751545357585904],
              [20.75415758032477, 69.91236644763461],
              [15.967969847183323, 68.83644729778501],
              [5.377256565338432, 62.16231256814888],
            ],
          ],
        },
      },
    ],
  };

export const simplePointsGeojson: GeoJSON.FeatureCollection<GeoJSON.Point> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: { name: 'First point' },
      geometry: {
        type: 'Point',
        coordinates: [3.1640625, 53.12040528310657],
      },
    },
    {
      type: 'Feature',
      properties: { name: 'Second point' },
      geometry: {
        type: 'Point',
        coordinates: [-2.4609375, 48.22467264956519],
      },
    },
    {
      type: 'Feature',
      properties: { name: 'Third point' },
      geometry: {
        type: 'Point',
        coordinates: [5.9765625, 48.922499263758255],
      },
    },
    {
      type: 'Feature',
      properties: { name: 'Fourth point' },
      geometry: {
        type: 'Point',
        coordinates: [1.40625, 48.922499263758255],
      },
    },
  ],
};

export const simpleSmallLineStringGeoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString> =
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          stroke: '#66F',
          'stroke-width': 5,
          'stroke-opacity': '1',
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [13.005656257245073, 53.618161003652624],
            [8.73272402958337, 48.28535054578368],
          ],
        },
      },
    ],
  };

export const simpleFlightRoutePointsGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point> =
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [9.988228, 53.630389],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [9.683522, 52.460214],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [8.570456, 50.033306],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [9.221964, 48.689878],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [11.078008, 49.4987],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [11.786086, 48.353783],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [12.0818, 49.142],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [10.958106, 50.979811],
        },
      },
      {
        type: 'Feature',
        properties: {
          fill: 'red',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'Point',
          coordinates: [13.500672, 52.362247],
        },
      },
    ],
  };

export const simpleLineStringGeoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString> =
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            [3.2958984375, 51.31001339554934],
            [6.30615234375, 52.696361078274485],
            [8.72314453125, 52.61639023304539],
          ],
        },
      },
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            [9.228515625, 48.922499263758255],
            [-4.21875, 47.338822694822],
            [-1.318359375, 54.521081495443596],
            [8.61328125, 55.3791104480105],
          ],
        },
      },
    ],
  };

export const simpleBoxGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        _type: 'box',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [3.6764699246101573, 63.564699956881675],
            [3.6764699246101573, 60.0554711506609],
            [15.801179052423734, 60.0554711506609],
            [15.801179052423734, 63.564699956881675],
            [3.6764699246101573, 63.564699956881675],
          ],
        ],
      },
    },
  ],
};

export const simpleBoxGeoJSONWrongOrder: GeoJSON.FeatureCollection<GeoJSON.Polygon> =
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          _type: 'box',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.6764699246101573, 60.0554711506609],
              [15.801179052423734, 60.0554711506609],
              [15.801179052423734, 63.564699956881675],
              [3.6764699246101573, 63.564699956881675],
              [3.6764699246101573, 60.0554711506609],
            ],
          ],
        },
      },
    ],
  };

export const simpleFlightRouteLineStringGeoJSON: GeoJSON.FeatureCollection<GeoJSON.LineString> =
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            [9.988228, 53.630389],
            [9.683522, 52.460214],
            [8.570456, 50.033306],
            [9.221964, 48.689878],
            [11.078008, 49.4987],
            [11.786086, 48.353783],
            [12.0818, 49.142],
            [10.958106, 50.979811],
            [13.500672, 52.362247],
          ],
        },
      },
    ],
  };

export const simpleMultiPolygon: GeoJSON.FeatureCollection<GeoJSON.MultiPolygon> =
  {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          stroke: '#8F8',
          'stroke-width': 4,
          'stroke-opacity': 1,
          fill: '#33ccFF',
          'fill-opacity': 0.5,
        },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [
            [
              [
                [4.365729269907419, 55.315807175634454],
                [3.299427839634416, 55.605958027800156],
                [2.368817, 55.764314],
                [4.331914, 59.332644],
                [4.365729269907419, 55.315807175634454],
              ],
            ],
            [
              [
                [6.500001602574285, 54.735051191917506],
                [5.526314849017847, 58.0000007017522],
                [18.500002, 55.000002],
                [6.500001602574285, 54.735051191917506],
              ],
            ],
          ],
        },
      },
    ],
  };

export const intersectionFeatureNL: GeoJSON.Feature<GeoJSON.Polygon> = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [5.0, 55.0],
        [4.331914, 55.332644],
        [3.368817, 55.764314],
        [2.761908, 54.379261],
        [3.15576, 52.913554],
        [2.000002, 51.500002],
        [3.370001, 51.369722],
        [3.370527, 51.36867],
        [3.362223, 51.320002],
        [3.36389, 51.313608],
        [3.373613, 51.309999],
        [3.952501, 51.214441],
        [4.397501, 51.452776],
        [5.078611, 51.391665],
        [5.848333, 51.139444],
        [5.651667, 50.824717],
        [6.011797, 50.757273],
        [5.934168, 51.036386],
        [6.222223, 51.361666],
        [5.94639, 51.811663],
        [6.405001, 51.830828],
        [7.053095, 52.237764],
        [7.031389, 52.268885],
        [7.063612, 52.346109],
        [7.065557, 52.385828],
        [7.133055, 52.888887],
        [7.14218, 52.898244],
        [7.191667, 53.3],
        [6.5, 53.666667],
        [6.500002, 55.000002],
        [5.0, 55.0],
      ],
    ],
  },
  properties: defaultGeoJSONStyleProperties,
};

export const intersectionFeatureBE: GeoJSON.Feature<GeoJSON.Polygon> = {
  type: 'Feature',
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [4.83163899984, 51.4375169997],
        [4.83971700004, 51.4211280003],
        [4.78471399965, 51.4327609998],
        [4.7811030004, 51.4302970004],
        [4.77944200025, 51.4175190002],
        [4.80665799987, 51.4100140003],
        [4.88973599992, 51.4160859998],
        [4.90944699968, 51.4091530002],
        [4.97808600027, 51.4238780003],
        [5.01693300026, 51.4755610004],
        [5.03137199972, 51.4852999999],
        [5.04139699977, 51.4866750004],
        [5.05027800013, 51.4747000003],
        [5.06139400013, 51.4719500003],
        [5.10058599984, 51.4399779999],
        [5.07663599975, 51.3988970003],
        [5.07864199963, 51.3916780001],
        [5.12780000027, 51.3497389997],
        [5.14000300004, 51.3208609998],
        [5.14945799989, 51.3171939998],
        [5.178908, 51.3166780003],
        [5.22806699979, 51.3122110002],
        [5.22523100031, 51.2688940001],
        [5.25029999985, 51.2633360001],
        [5.32971099986, 51.2621920004],
        [5.34724199982, 51.2694670004],
        [5.38752200024, 51.2677470002],
        [5.46779400039, 51.2827610001],
        [5.517222, 51.2958330004],
        [5.54250799964, 51.2769170004],
        [5.55780600005, 51.2639109996],
        [5.55666699968, 51.2558330003],
        [5.5572329997, 51.2313669998],
        [5.5591809996, 51.2244330002],
        [5.64942199993, 51.2016859998],
        [5.65555300027, 51.1964140004],
        [5.71055599976, 51.1822219997],
        [5.73553599969, 51.1916609998],
        [5.77696099985, 51.180258],
        [5.77942500016, 51.1694279997],
        [5.82806899958, 51.1669640003],
        [5.85500000045, 51.1510939996],
        [5.85601899978, 51.1473080004],
        [5.8472219997, 51.1352779996],
        [5.81695299958, 51.1225030004],
        [5.84056100009, 51.1058309996],
        [5.80641099997, 51.0958610003],
        [5.80280299996, 51.093339],
        [5.80222800042, 51.0616560003],
        [5.7752999997, 51.0597080004],
        [5.77140299985, 51.0475029997],
        [5.76332499965, 51.0394250004],
        [5.76303899995, 51.0352999999],
        [5.7747280003, 51.028594],
        [5.77696099985, 51.0258470001],
        [5.76418599978, 50.9899779999],
        [5.74802800019, 50.9819579997],
        [5.72276099962, 50.9652829996],
        [5.72390600026, 50.9577779998],
        [5.72831700043, 50.9561169996],
        [5.74418900032, 50.9577779998],
        [5.72974999996, 50.9141749999],
        [5.7150249999, 50.9105669999],
        [5.68406700039, 50.8831669999],
        [5.66555600021, 50.8769439998],
        [5.65331699968, 50.8783079997],
        [5.64776099977, 50.8677670001],
        [5.63750299976, 50.8502920001],
        [5.63945299976, 50.8430719999],
        [5.6472220001, 50.8324999998],
        [5.70001400015, 50.8069169999],
        [5.70052799957, 50.8033079999],
        [5.69806400015, 50.7999859996],
        [5.69694399977, 50.7897220002],
        [5.6933110004, 50.762514],
        [5.69308099969, 50.7591919997],
        [5.73719699984, 50.7594190003],
        [5.76195000009, 50.7763810001],
        [5.7747280003, 50.7816500002],
        [5.77862200001, 50.7794169998],
        [5.77999699956, 50.7758639996],
        [5.79747499977, 50.7694469996],
        [5.80417800041, 50.7647499997],
        [5.83888900033, 50.7616669996],
        [5.84720599986, 50.7669250002],
        [5.85471099971, 50.7591919997],
        [5.88307199997, 50.7664109999],
        [5.89250000037, 50.7544440002],
        [5.93251899955, 50.756956],
        [5.99888899983, 50.7538889998],
        [6.01163300026, 50.7566690003],
        [6.01611400006, 50.765264],
        [6.06666700028, 50.7166670001],
        [6.11666700018, 50.7333329997],
        [6.16666700008, 50.6666670002],
        [6.2500000002, 50.6499999996],
        [6.28333300042, 50.6166670003],
        [6.2500000002, 50.5999999997],
        [6.2500000002, 50.583333],
        [6.2000000003, 50.5499999998],
        [6.1986110001, 50.5194439999],
        [6.2500000002, 50.4833330002],
        [6.35, 50.4666669997],
        [6.37388899998, 50.4349999999],
        [6.38333300022, 50.4333330003],
        [6.40833300017, 50.3333329996],
        [6.3000000001, 50.316667],
        [6.29916700036, 50.303056],
        [6.23166700013, 50.2388889997],
        [6.1500000004, 50.1499999997],
        [6.13301100026, 50.1268060003],
        [6.11655799965, 50.0714689998],
        [6.12647199998, 50.0711830001],
        [6.11322499973, 50.0586359998],
        [6.13316099998, 50.0435579996],
        [6.14093299956, 49.9935890002],
        [6.16349999973, 49.9814360001],
        [6.15971699974, 49.9746389996],
        [6.17998600005, 49.9583389997],
        [6.18282499967, 49.9675310001],
        [6.18998899999, 49.9667970004],
        [6.21388899958, 49.955556],
        [6.22060299983, 49.9355170004],
        [6.22500000025, 49.9083329996],
        [6.23418300015, 49.897519],
        [6.30860799956, 49.8644609999],
        [6.32110000006, 49.8483030003],
        [6.32195800006, 49.8399940002],
        [6.34109700043, 49.8475000001],
        [6.36917200009, 49.8419420001],
        [6.4435970004, 49.8094560003],
        [6.50307200017, 49.8072219998],
        [6.51304200039, 49.8041860001],
        [6.5222080004, 49.8111170004],
        [6.52140600029, 49.7974810002],
        [6.50668100023, 49.7863670003],
        [6.51751099963, 49.7580609999],
        [6.49997799959, 49.7419610003],
        [6.5166500004, 49.7194439997],
        [6.49779999987, 49.7203029998],
        [6.50141100002, 49.7060940001],
        [6.46111099957, 49.6916670003],
        [6.42085300017, 49.6658169999],
        [6.42056400033, 49.6602579998],
        [6.43805600028, 49.6541670004],
        [6.42499999985, 49.638889],
        [6.42499999985, 49.6333330001],
        [6.37944399984, 49.5969440002],
        [6.3719220001, 49.5872059999],
        [6.3750139997, 49.5822220002],
        [6.36137800039, 49.5752889998],
        [6.37335300044, 49.549161],
        [6.36779699963, 49.5422280004],
        [6.36166700023, 49.4543310003],
        [6.2805329999, 49.4930689997],
        [6.21666699998, 49.5000000001],
        [6.15304999986, 49.5025220004],
        [6.15969699972, 49.4933560004],
        [6.12251400002, 49.4839029997],
        [6.11804199974, 49.469692],
        [6.09054199961, 49.4530749999],
        [6.06916899965, 49.4633329999],
        [6.04321399973, 49.4534189996],
        [5.9999999998, 49.4500000002],
        [5.95833300017, 49.4833330004],
        [5.8511029997, 49.5052720004],
        [5.80858100021, 49.5446939999],
        [5.75833299967, 49.5361109998],
        [5.48333300022, 49.5000000001],
        [5.4000000001, 49.6083330002],
        [5.31666699998, 49.6166669996],
        [5.3000000003, 49.6666670004],
        [5.17499999965, 49.6916670003],
        [5.06666699958, 49.7583329999],
        [5.0, 49.7833329998],
        [4.8500000003, 49.7833329998],
        [4.85833299967, 49.9166669999],
        [4.8000000004, 49.9500000001],
        [4.83888899963, 50.0472220004],
        [4.87222199985, 50.088889],
        [4.86749999973, 50.1152780002],
        [4.87022799962, 50.1267969999],
        [4.89647500042, 50.1402000001],
        [4.88140799988, 50.1486169998],
        [4.87752199964, 50.1560970003],
        [4.85856400043, 50.1539169996],
        [4.83766699993, 50.1585920002],
        [4.82454400001, 50.1676309998],
        [4.81676900029, 50.1657609999],
        [4.80753599989, 50.1536059996],
        [4.76428099987, 50.1377080003],
        [4.7499999996, 50.1180559997],
        [4.74027800003, 50.1083330001],
        [4.73317799996, 50.1093419998],
        [4.71540300045, 50.0977579998],
        [4.68333300002, 50.0],
        [4.48333300042, 49.9333330004],
        [4.18333300012, 49.9500000001],
        [4.12499999995, 50.0166669997],
        [4.14888899993, 50.0347220004],
        [4.1999999998, 50.0999999998],
        [4.11666699968, 50.1499999997],
        [4.16666699958, 50.2500000004],
        [4.17222200035, 50.2833329997],
        [4.01666699988, 50.3500000002],
        [3.9000000004, 50.316667],
        [3.79166700033, 50.3333329996],
        [3.7099999997, 50.3083329997],
        [3.6599999998, 50.3719440004],
        [3.61666699978, 50.4833330002],
        [3.40499999995, 50.4633329997],
        [3.27666700028, 50.5300000002],
        [3.22499999995, 50.6783330004],
        [3.11666699988, 50.7833329996],
        [2.8999999997, 50.7000000004],
        [2.81666699958, 50.7250000003],
        [2.71666699978, 50.8000000002],
        [2.63333299962, 50.8166669999],
        [2.5500000004, 51.0833329999],
        [1.9999999997, 51.1166670002],
        [1.9999999997, 51.4999999997],
        [3.0000000004, 51.4086179998],
        [3.10027800043, 51.3994440003],
        [3.36388899988, 51.3736109997],
        [3.38724200032, 51.3413280003],
        [3.38377200038, 51.3313580001],
        [3.36248900007, 51.3184579996],
        [3.37401700018, 51.3050059998],
        [3.36470599978, 51.3019560004],
        [3.36492800013, 51.2987639998],
        [3.3813890002, 51.2836109997],
        [3.42589999969, 51.2589609998],
        [3.43277200003, 51.2486970004],
        [3.45504400008, 51.2451440002],
        [3.49817799991, 51.2463390004],
        [3.53010600009, 51.2507780001],
        [3.52079199955, 51.2930780001],
        [3.52611400036, 51.2955750002],
        [3.54363099967, 51.2896110003],
        [3.56447200028, 51.2968220001],
        [3.58376100037, 51.2897499996],
        [3.58575599974, 51.3012609998],
        [3.59285000043, 51.3063919999],
        [3.6037139996, 51.3037579998],
        [3.64140600029, 51.2876689998],
        [3.65204699997, 51.2872530004],
        [3.65847799976, 51.2835080004],
        [3.69151399977, 51.2815669999],
        [3.71390800005, 51.2744940002],
        [3.73109999967, 51.2727249996],
        [3.75780600005, 51.2722750004],
        [3.76866900007, 51.2646470003],
        [3.77842500031, 51.2670030001],
        [3.79084200006, 51.2625669997],
        [3.79061899967, 51.2514719997],
        [3.79039700022, 51.2199890003],
        [3.80370000035, 51.2145779999],
        [3.84560600022, 51.2120829999],
        [3.86400799987, 51.2141639997],
        [3.87886100043, 51.2273390002],
        [3.88846099978, 51.2265610003],
        [3.88839700042, 51.217214],
        [3.88462800018, 51.2088920003],
        [3.91278600007, 51.209725],
        [3.92298299997, 51.220958],
        [3.9300779998, 51.2226220003],
        [3.93916899995, 51.2165219996],
        [3.94870299984, 51.215828],
        [3.97109700013, 51.2215139996],
        [4.10833300027, 51.2708330004],
        [4.25143300013, 51.3750060002],
        [4.33391100039, 51.3766670004],
        [4.34307800045, 51.3647500003],
        [4.3469750003, 51.3625169999],
        [4.41859199972, 51.3591360004],
        [4.43165799972, 51.3644639997],
        [4.42805599998, 51.3744440004],
        [4.3966499996, 51.4169469999],
        [4.40942499966, 51.4375169997],
        [4.40805000011, 51.440839],
        [4.39389999959, 51.4502919998],
        [4.46528900019, 51.4716639997],
        [4.4986109999, 51.4788890001],
        [4.51250000007, 51.4805559997],
        [4.54556100034, 51.4850140002],
        [4.54498900004, 51.4627830002],
        [4.53805599958, 51.4524690003],
        [4.53530599957, 51.4397500002],
        [4.62361099965, 51.4272219998],
        [4.67166899992, 51.4274890004],
        [4.67304400038, 51.4308109998],
        [4.66863300021, 51.440839],
        [4.68415800029, 51.4494329997],
        [4.69859699975, 51.4644440004],
        [4.76918600033, 51.502775],
        [4.80833299977, 51.4999999997],
        [4.8408059999, 51.4830670003],
        [4.84470299974, 51.4614079998],
        [4.83696700003, 51.4569389996],
        [4.83163899984, 51.4375169997],
      ],
    ],
  },
  properties: defaultGeoJSONStyleProperties,
};

export const simpleGeometryCollectionGeoJSON: GeoJSON.FeatureCollection<
  GeoJSON.GeometryCollection<GeoJSON.MultiPolygon>
> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        stroke: '#8F8',
        'stroke-width': 4,
        'stroke-opacity': 1,
        fill: '#33ccFF',
        'fill-opacity': 0.5,
        country: 'Finland',
        details: {
          identifier: '2.49.0.0.246.0.FI.240410113246.jma418B852H2nBY1ntp3',
          severity: 'Moderate',
          certainty: 'Possible',
          onset: '2024-04-11T12:00:00+03:00',
          expires: '2024-04-11T18:00:00+03:00',
          languages: [
            {
              language: 'fi-FI',
              event: 'Tuulivaroitus maa-alueille',
              senderName: 'Ilmatieteen laitos',
              headline: '',
              description:
                'Tuulivaroitus maa-alueille: Länsituuli on voimakasta iltapäivällä, tuulen nopeus puuskissa 20 m/s.',
              areaDesc: 'Kainuu, Pudasjärvi, Taivalkoski, Kuusamo',
            },
            {
              language: 'sv-SE',
              event: 'Vindvarning för landområden',
              senderName: 'Meteorologiska institutet',
              headline: '',
              description:
                'Vindvarning för landområden: På eftermiddagen är den västliga vinden kraftigt, i vindbyarna 20 m/s.',
              areaDesc: 'Kainuu, Pudasjärvi, Taivalkoski, Kuusamo',
            },
            {
              language: 'en-GB',
              event: 'Wind warning for land areas',
              senderName: 'Finnish Meteorological Institute',
              headline: '',
              description:
                'Wind warning for land areas: In the afternoon, strong winds from west may bring strong gusts of 20 m/s.',
              areaDesc: 'Kainuu, Pudasjärvi, Taivalkoski, Kuusamo',
            },
          ],
        },
      },
      geometry: {
        type: 'GeometryCollection',
        geometries: [
          {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [29.758392564346536, 65.49273540846954],
                  [29.71941427879389, 65.49638044528335],
                  [29.21459755302349, 65.51209391081191],
                  [29.170693731268035, 65.5197800533191],
                  [29.147217916080326, 65.50275296777002],
                  [28.633374093002843, 65.3721705402633],
                  [28.18723681698394, 65.25672404742585],
                  [28.14911332514444, 65.24984205608105],
                  [28.111118852458272, 65.24063653209379],
                  [27.693819807358636, 65.16109646665383],
                  [27.33039713116483, 65.09068209391778],
                  [27.332840574130387, 65.0387703089369],
                  [27.326646217967365, 64.97976270671202],
                  [27.157653690059732, 64.83986451042715],
                  [27.126612443366312, 64.82736609485796],
                  [26.916420458938006, 64.76188902343115],
                  [26.886428080039778, 64.75000916898261],
                  [26.467815769740625, 64.56827471246525],
                  [26.334511209056984, 64.50150678855421],
                  [26.305590843292137, 64.47468873954321],
                  [26.339316861749918, 64.44776636198924],
                  [26.504854495183764, 64.3399621504545],
                  [26.526789567510534, 64.3210451684454],
                  [26.64680093131525, 64.20565204411693],
                  [26.805502956254468, 64.05176843450018],
                  [26.822846233907782, 64.02833008717302],
                  [26.866302819035326, 64.02149244583438],
                  [27.25090982244751, 63.95456911474786],
                  [27.63394361227682, 63.88676185094127],
                  [28.01539289698782, 63.81807690948368],
                  [28.336001020870327, 63.75944634860591],
                  [28.36953495057645, 63.75291483074939],
                  [28.38146960707695, 63.780308294502746],
                  [28.453212390927888, 63.86320698957978],
                  [28.488122426266656, 63.87072168747777],
                  [29.061327279544, 63.87578318437369],
                  [29.67289940508833, 63.87894724382795],
                  [29.701281337390554, 63.87096916155944],
                  [29.95452767634896, 63.762659061713634],
                  [29.991551532456302, 63.74790651378042],
                  [30.022558687101863, 63.76119168003561],
                  [30.214883314310555, 63.81188283894816],
                  [30.24646259407193, 63.8240131577964],
                  [30.45743761263076, 63.98659320159419],
                  [30.485808880817984, 64.00962934474863],
                  [30.528497757656666, 64.05664110171924],
                  [30.54104187728157, 64.08719012709481],
                  [30.548808176296486, 64.12087568595071],
                  [30.519336467341862, 64.20177024842253],
                  [30.501794237261407, 64.23261663433796],
                  [30.4770997493347, 64.25733643782799],
                  [30.278855247194695, 64.32813156135076],
                  [30.249923179452413, 64.34139133102182],
                  [30.034923434023412, 64.50055888989162],
                  [30.006675740312176, 64.52232084337746],
                  [29.843095805108717, 64.6732364080745],
                  [29.698703502979736, 64.8052603050698],
                  [29.677920179868693, 64.82640388377037],
                  [29.638540921311844, 64.88072887102366],
                  [29.62541734471698, 64.90735364979494],
                  [29.60157369391645, 64.9718516701184],
                  [29.597607059999827, 64.9972352266183],
                  [29.669945543777658, 65.23518451106732],
                  [29.73413551222892, 65.44365355519507],
                  [29.743985637492894, 65.469616744196],
                  [29.758392564346536, 65.49273540846954],
                ],
              ],
            ],
          },
          {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [27.766520457815957, 65.8195160088791],
                  [27.73085604057836, 65.83947485866196],
                  [27.45820033758423, 65.9493211039968],
                  [27.430865666979813, 65.9554713265499],
                  [27.407432994387463, 65.93839022633652],
                  [27.17343408151436, 65.79130783497929],
                  [27.13700155265117, 65.7748045054381],
                  [27.043143659347365, 65.74146868282556],
                  [26.93212618070619, 65.72018457880594],
                  [26.770113943520442, 65.70598902727579],
                  [26.723301465692543, 65.70590768662042],
                  [26.387054877123173, 65.71366432114496],
                  [26.158116226051387, 65.7257708265435],
                  [26.154050492787476, 65.48723563080719],
                  [26.158292174759623, 65.41018287503542],
                  [26.226082500593677, 65.28786857013642],
                  [26.24918073602433, 65.26064963421615],
                  [26.311435369016323, 65.20611737168086],
                  [26.34746159341583, 65.19097379678617],
                  [26.47064990531538, 65.14852904702708],
                  [26.617354013993747, 65.10039082072402],
                  [26.658172378514152, 65.09683493161036],
                  [26.7850977917127, 65.09445840537802],
                  [27.33039713116483, 65.09068209391778],
                  [27.693819807358636, 65.16109646665383],
                  [28.111118852458272, 65.24063653209379],
                  [28.14911332514444, 65.24984205608105],
                  [28.141796473873793, 65.28201842856463],
                  [28.04237271730372, 65.42610665418935],
                  [27.942039909829926, 65.57026895303783],
                  [27.766520457815957, 65.8195160088791],
                ],
              ],
            ],
          },
          {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [29.170693731268035, 65.5197800533191],
                  [29.130087050229307, 65.54237017185336],
                  [28.793692264131735, 65.71359634271593],
                  [28.549640756625106, 65.83637062837917],
                  [28.52068592987659, 65.85585413976165],
                  [28.48883706877227, 65.85421373746401],
                  [28.124779448491477, 65.84111872142014],
                  [27.79622169654993, 65.82862408942168],
                  [27.766520457815957, 65.8195160088791],
                  [27.942039909829926, 65.57026895303783],
                  [28.04237271730372, 65.42610665418935],
                  [28.141796473873793, 65.28201842856463],
                  [28.14911332514444, 65.24984205608105],
                  [28.18723681698394, 65.25672404742585],
                  [28.633374093002843, 65.3721705402633],
                  [29.147217916080326, 65.50275296777002],
                  [29.170693731268035, 65.5197800533191],
                ],
              ],
            ],
          },
          {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [29.568191250285462, 66.43045265195359],
                  [29.527651742524338, 66.42954425078428],
                  [29.167149655693773, 66.45472209596944],
                  [28.72266047369712, 66.48468310371933],
                  [28.68788794031491, 66.4858456386077],
                  [28.686129727583417, 66.45685684583327],
                  [28.616947548792908, 66.21978395191063],
                  [28.57140958210226, 66.06194633146768],
                  [28.526330219060455, 65.90427238999632],
                  [28.52068592987659, 65.85585413976165],
                  [28.549640756625106, 65.83637062837917],
                  [28.793692264131735, 65.71359634271593],
                  [29.130087050229307, 65.54237017185336],
                  [29.170693731268035, 65.5197800533191],
                  [29.21459755302349, 65.51209391081191],
                  [29.71941427879389, 65.49638044528335],
                  [29.758392564346536, 65.49273540846954],
                  [29.803357612639658, 65.51348157207968],
                  [30.11053968124635, 65.65921283899507],
                  [30.124309492013527, 65.69970034115639],
                  [30.121904515243088, 65.75312552789165],
                  [30.108659610722114, 65.8017236758968],
                  [30.093512380951722, 65.84734580681476],
                  [30.05919479177051, 65.92010170197672],
                  [29.930537319625472, 66.1076962309863],
                  [29.91149731454561, 66.13245763550866],
                  [29.74544846833132, 66.28135761175152],
                  [29.5988415801629, 66.41164508252187],
                  [29.568191250285462, 66.43045265195359],
                ],
              ],
            ],
          },
        ],
      },
    },
  ],
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import {
  CoreAppStore,
  layerSelectors,
  mapActions,
  mapEnums,
  mapSelectors,
} from '@opengeoweb/store';
import {
  TimeSpanButton,
  getNewCenterOfFixedPointZoom,
  secondsPerPxFromCanvasWidth,
} from '@opengeoweb/timeslider';

export interface TimeSpanButtonConnectProps {
  mapId: string;
}

export const TimeSpanButtonConnect = ({
  mapId,
}: TimeSpanButtonConnectProps): React.ReactElement => {
  const dispatch = useDispatch();
  const store = useStore<CoreAppStore>();

  const timeSliderSpan = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderSpan(store, mapId),
  );
  const isAnimating = useSelector((store: CoreAppStore) =>
    mapSelectors.isAnimating(store, mapId),
  );
  const autoTimeStepLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getAutoTimeStepLayerId(store, mapId),
  );
  const timeStepFromLayer = useSelector((store: CoreAppStore) =>
    layerSelectors.getTimeStepForLayerId(store, autoTimeStepLayerId),
  );
  const isTimeSpanAuto = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimeSpanAuto(store, mapId),
  );

  const onToggleTimeSpanAuto = React.useCallback((): void => {
    dispatch(
      mapActions.toggleTimeSpanAuto({
        mapId,
        timeSpanAuto: !isTimeSpanAuto,
        origin: mapEnums.MapActionOrigin.map,
      }),
    );
  }, [dispatch, isTimeSpanAuto, mapId]);

  const onChangeTimeSliderSpan = (valueInMinutes: number): void => {
    if (isTimeSpanAuto) {
      onToggleTimeSpanAuto();
    }
    const spanInSeconds = valueInMinutes * 60;

    const timeSliderWidth = mapSelectors.getMapTimeSliderWidth(
      store.getState(),
      mapId,
    ) as number;

    const selectedTime = mapSelectors.getSelectedTime(store.getState(), mapId);

    const secondsPerPx = mapSelectors.getMapTimeSliderSecondsPerPx(
      store.getState(),
      mapId,
    ) as number;

    const centerTime = mapSelectors.getMapTimeSliderCenterTime(
      store.getState(),
      mapId,
    ) as number;

    const newSecondsPerPx = secondsPerPxFromCanvasWidth(
      timeSliderWidth,
      spanInSeconds,
    );

    const newCenterTime = getNewCenterOfFixedPointZoom(
      selectedTime,
      secondsPerPx,
      newSecondsPerPx!,
      centerTime,
    );

    if (
      spanInSeconds !== undefined &&
      newCenterTime !== undefined &&
      newSecondsPerPx !== undefined
    ) {
      dispatch(
        mapActions.setTimeSliderSecondsPerPx({
          mapId,
          timeSliderSecondsPerPx: newSecondsPerPx,
        }),
      );
      dispatch(
        mapActions.setTimeSliderSpan({
          mapId,
          timeSliderSpan: spanInSeconds!,
          origin: mapEnums.MapActionOrigin.map,
        }),
      );
      dispatch(
        mapActions.setTimeSliderCenterTime({
          mapId,
          timeSliderCenterTime: newCenterTime,
        }),
      );
    }
  };

  const onToggleTimeSpan = (): void => {
    if (isTimeSpanAuto) {
      return;
    }
    onToggleTimeSpanAuto();
  };

  return (
    <TimeSpanButton
      timeSliderSpan={timeSliderSpan! / 60}
      disabled={isAnimating}
      onChangeSliderValue={onChangeTimeSliderSpan}
      onToggleTimeSpan={onToggleTimeSpan}
      isTimeSpanAuto={Boolean(isTimeSpanAuto) && Boolean(timeStepFromLayer)}
      layerHasTimeStep={Boolean(timeStepFromLayer)!}
    />
  );
};

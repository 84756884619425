/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CustomFeature, SeverityStyles } from '../components/types';

export const addColors = (features: CustomFeature[]): CustomFeature[] => {
  const severityStyles: SeverityStyles = {
    Extreme: {
      stroke: 'rgb(255, 9, 9)',
      fill: 'rgba(255, 9, 9, 0.10)',
    },
    Severe: {
      stroke: 'rgb(255, 165, 0)',
      fill: 'rgba(255, 165, 0, 0.10)',
    },
    Moderate: {
      stroke: 'rgb(248, 248, 0)',
      fill: 'rgba(248, 248, 0, 0.10)',
    },
    Minor: {
      stroke: 'rgb(106, 248, 108)',
      fill: 'rgba(106, 248, 108, 0.10)',
    },
  };

  for (const feature of features) {
    const { severity } = feature.properties.details;
    feature.properties.stroke =
      severityStyles[severity as keyof SeverityStyles].stroke;
    feature.properties.fill =
      severityStyles[severity as keyof SeverityStyles].fill;
    feature.properties['stroke-opacity'] = 1;
    feature.properties['stroke-width'] = 2;
  }
  return features;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import {
  ReactHookFormRadioGroup,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { getAllFirAreas } from '../utils';
import { FIRConfigSigmet, ProductConfig, Sigmet } from '../../../types';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

interface CancelSigmetVolcanicMovementsProps {
  sigmet: Sigmet;
  productConfig: ProductConfig;
}

export const CancelSigmetVolcanicMovements: React.FC<
  CancelSigmetVolcanicMovementsProps
> = ({ sigmet, productConfig }: CancelSigmetVolcanicMovementsProps) => {
  const allFIRS = getAllFirAreas(productConfig);
  const { t } = useSigmetAirmetTranslation();

  const { isRequired } = useDraftFormHelpers();

  return (
    <>
      <Typography variant="body1">{t('vulcanic-ash-description')}:</Typography>
      <ReactHookFormRadioGroup
        name="vaSigmetMoveToFIR"
        rules={{ validate: { isRequired } }}
        row
        defaultValue={t('unknown').toUpperCase()}
      >
        {(
          allFIRS[sigmet.locationIndicatorATSR] as FIRConfigSigmet
        ).adjacent_firs.map((adjacentFIR) => {
          return (
            <FormControlLabel
              key={adjacentFIR}
              value={adjacentFIR}
              control={<Radio />}
              label={adjacentFIR}
            />
          );
        })}
        <FormControlLabel
          key="UNKNOWN"
          value="UNKNOWN"
          control={<Radio />}
          label={t('vulcanic-VA-SIGMET-text')}
        />
      </ReactHookFormRadioGroup>
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box, Grid2 as Grid, Skeleton } from '@mui/material';

import { AlertBanner } from '@opengeoweb/shared';
import { useApiContext, useApi } from '@opengeoweb/api';
import { BulletinType, Bulletin } from '../../types';
import { BulletinViewer } from './BulletinViewer';
import { ContentDialog } from '../ContentDialog';
import { BulletinHistoryList } from './BulletinHistoryList';
import { BulletinTabs } from './BulletinTabs';
import { SpaceWeatherApi } from '../../utils/api';
import { useSpaceweatherTranslation } from '../../utils/i18n';

interface BulletinHistoryDialogProps {
  open: boolean;
  toggleStatus: () => void;
}

const BulletinHistoryDialog: React.FC<BulletinHistoryDialogProps> = ({
  open,
  toggleStatus,
}: BulletinHistoryDialogProps) => {
  const [bulletinType, setBulletinType] = React.useState(
    BulletinType.technical,
  );
  const [selectedBulletin, setSelectedBulletin] = React.useState('');
  const { api } = useApiContext<SpaceWeatherApi>();
  const { t } = useSpaceweatherTranslation();
  const {
    isLoading,
    error,
    result: bulletinHistoryList,
    fetchApiData,
  } = useApi(api.getBulletinHistory);

  React.useEffect(() => {
    if (
      bulletinHistoryList &&
      bulletinHistoryList.length &&
      !selectedBulletin
    ) {
      setSelectedBulletin(bulletinHistoryList[0].bulletin_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulletinHistoryList]);

  React.useEffect(() => {
    if (open) {
      void fetchApiData!(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const selectedBulletinItem = React.useMemo(() => {
    if (!bulletinHistoryList?.length || !selectedBulletin) {
      return undefined;
    }

    return bulletinHistoryList.find(
      (bulletin: Bulletin) => bulletin.bulletin_id === selectedBulletin,
    );
  }, [bulletinHistoryList, selectedBulletin]);

  return (
    <ContentDialog
      open={open}
      toggleStatus={toggleStatus}
      title={t('bulletin-forecast-history-title')}
      data-testid="history-dialog"
      fullWidth
      disableEscapeKeyDown
    >
      <Grid container>
        <Grid size={2}>
          {bulletinHistoryList && (
            <BulletinHistoryList
              selectedBulletin={selectedBulletin}
              bulletinHistoryList={bulletinHistoryList}
              onSelectBulletin={setSelectedBulletin}
            />
          )}
          {isLoading && (
            <Skeleton
              data-testid="history-dialog-loading"
              variant="rectangular"
              height={558}
              width="90%"
            />
          )}
          {error && (
            <AlertBanner
              severity="error"
              dataTestId="history-dialog-error"
              title={error.message ? error.message : ''}
            />
          )}
        </Grid>
        <Grid size={10}>
          <Grid container direction="column" alignItems="stretch" spacing={0}>
            <BulletinTabs
              selected={bulletinType}
              onSetBulletinType={setBulletinType}
            />
            <Grid>
              <Box
                sx={{
                  padding: '10px 0px 0px 10px',
                }}
              >
                {selectedBulletinItem && (
                  <BulletinViewer
                    bulletinType={bulletinType}
                    smallContent={false}
                    bulletin={selectedBulletinItem}
                  />
                )}
              </Box>
              {isLoading && (
                <Skeleton variant="rectangular" height={500} width="100%" />
              )}
              {error && (
                <AlertBanner
                  severity="error"
                  title={error.message ? error.message : ''}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentDialog>
  );
};

export default BulletinHistoryDialog;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { CSSProperties, ReactNode } from 'react';
import { DragHandle as DragHandleIcon } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { Theme } from '@mui/material';
import { useCoreTranslation } from '../../../../../utils/i18n';

interface DragHandleProps {
  isDisabled?: boolean;
  hideTooltip?: boolean;
  isSorting?: boolean;
  icon?: ReactNode;
  tooltipTitle?: string;
}

const DragHandle: React.FC<DragHandleProps> = ({
  isDisabled = false,
  hideTooltip = true,
  isSorting = false,
  icon = <DragHandleIcon data-testid="dragHandleIcon" />,
  tooltipTitle,
}: DragHandleProps) => {
  const { t } = useCoreTranslation();
  const nonEmptyTooltipTitle = tooltipTitle ?? t('layermanager-layer-drag');
  const usedTooltipTitle = hideTooltip ? '' : nonEmptyTooltipTitle;
  return (
    <CustomIconButton
      tooltipTitle={usedTooltipTitle}
      data-testid="dragHandle"
      className="handle"
      tabIndex={-1}
      disableTouchRipple
      disabled={isDisabled}
      sx={{
        cursor: isSorting ? 'grabbing' : 'grab',
        '&.handle:hover': (theme: Theme): CSSProperties => ({
          ...theme.palette.geowebColors.layerManager.dragHandleHover,
        }),
        marginLeft: '3px',
        marginRight: '3px',
        marginTop: 'auto',
        marginBottom: 'auto',

        '&.MuiIconButton-root': {
          color: 'geowebColors.greys.accessible',
        },
      }}
    >
      {icon}
    </CustomIconButton>
  );
};

export default DragHandle;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  WorkspaceModuleProvider,
  WorkspaceDetail,
  WorkspaceTopBar,
  WorkspaceLookupFunctionType,
} from '@opengeoweb/workspace';
import React from 'react';
import { ApiModule, useApi, useApiContext } from '@opengeoweb/api';

import {
  InitialAppPreset,
  LayerManagerConnect,
  MultiMapDimensionSelectConnect,
  SyncGroupViewerConnect,
  filterMapPresets,
} from '@opengeoweb/core';

import { TimeSeriesManagerConnect } from '@opengeoweb/timeseries';
import { AppLayout, ErrorBoundary, ConfigType } from '@opengeoweb/shared';
import { useDispatch, useSelector } from 'react-redux';

import { LinearProgress } from '@mui/material';
import {
  ObjectManagerConnect,
  DrawingToolConnect,
  WarningsModuleProvider,
  PublicWarningsFormDialogConnect,
} from '@opengeoweb/warnings';
import { mapActions, mapSelectors } from '@opengeoweb/store';
import { componentsLookUp, ComponentsLookUpPayload } from './componentsLookUp';
import { AppApi } from '../../utils/api';

import BrowserIssueDialog from '../../components/BrowserIssueDialog';
import Header from './Header';
import { AppStore } from '../../store';

interface WorkspaceLayoutProps extends Omit<ApiModule, 'config'> {
  workspaceId?: string;
  config?: ConfigType;
}

export const WorkspaceLayout: React.FC<WorkspaceLayoutProps> = ({
  workspaceId,
  config,
  auth,
  onSetAuth,
}: WorkspaceLayoutProps) => {
  const dispatch = useDispatch();
  const defaultMapSettings = useSelector((store: AppStore) =>
    mapSelectors.getdefaultMapSettings(store),
  );
  const { api } = useApiContext<AppApi>();

  const { result: initialPresetsResult, isLoading: isInitialPresetsLoading } =
    useApi(
      api.getInitialPresets,
      config?.GW_INITIAL_PRESETS_FILENAME || 'initialPresets.json',
      {
        onSuccess(data: InitialAppPreset) {
          if (data.preset.defaultMapSettings) {
            dispatch(
              mapActions.setDefaultMapSettings({
                preset: data.preset.defaultMapSettings,
              }),
            );
          }
        },
      },
    );

  const onComponentsLookUp = (
    payload: ComponentsLookUpPayload,
  ): React.ReactElement => {
    return componentsLookUp(payload, config, auth, onSetAuth);
  };

  // // wait before the initial presets have been loaded before loading the app
  if (isInitialPresetsLoading) {
    return <LinearProgress color="secondary" />;
  }

  const { baseLayers, services, baseServices, timeSeriesServices } =
    filterMapPresets(initialPresetsResult);

  return (
    <WorkspaceModuleProvider
      auth={auth}
      onSetAuth={onSetAuth}
      config={{
        baseURL: config?.GW_PRESET_BACKEND_URL,
        appURL: config?.GW_APP_URL,
        authTokenURL: config?.GW_AUTH_TOKEN_URL,
        authClientId: config?.GW_AUTH_CLIENT_ID,
      }}
    >
      <AppLayout
        header={
          <Header
            config={config}
            workspaceTopBar={
              <WorkspaceTopBar
                workspaceId={workspaceId}
                appTitle={config?.GW_FEATURE_APP_TITLE}
              />
            }
          />
        }
      >
        <WorkspaceDetail
          initialWorkspaceId={config?.GW_INITIAL_WORKSPACE_PRESET}
          workspaceId={workspaceId}
          componentsLookUp={onComponentsLookUp as WorkspaceLookupFunctionType}
        >
          <BrowserIssueDialog />
          <ErrorBoundary>
            <SyncGroupViewerConnect />
            <TimeSeriesManagerConnect
              preloadedTimeseriesServices={timeSeriesServices}
            />
            <LayerManagerConnect
              bounds="parent"
              showMapIdInTitle
              preloadedAvailableBaseLayers={baseLayers}
              preloadedBaseServices={baseServices || services}
              preloadedMapServices={services}
            />
            <MultiMapDimensionSelectConnect />
            {config?.GW_DRAWINGS_BASE_URL !== undefined && (
              <WarningsModuleProvider
                auth={auth}
                onSetAuth={onSetAuth}
                config={{
                  baseURL: config?.GW_DRAWINGS_BASE_URL,
                  appURL: config?.GW_APP_URL,
                  authTokenURL: config?.GW_AUTH_TOKEN_URL,
                  authClientId: config?.GW_AUTH_CLIENT_ID,
                }}
              >
                <ObjectManagerConnect />
                <DrawingToolConnect showMapIdInTitle />
                <PublicWarningsFormDialogConnect
                  startPosition={{ top: 40, left: 60 }}
                  defaultMapPreset={defaultMapSettings}
                />
              </WarningsModuleProvider>
            )}
          </ErrorBoundary>
        </WorkspaceDetail>
      </AppLayout>
    </WorkspaceModuleProvider>
  );
};

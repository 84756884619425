/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { useDispatch } from 'react-redux';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { UserMenuRoles } from './UserMenuRoles';
import { useAuthenticationTranslation } from '../../utils/i18n';
import { Role } from '../ApiContext/types';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  GEOWEB_ROLE_USER,
} from '../ApiContext/utils';

export const UserMenuRolesConnect: React.FC<{
  // TODO: will be removed when we have some proper role logic
  temporaryOnChangeRoleForDemo?: (role: Role) => void;
  roles?: Role[];
  isAdmin?: boolean;
}> = ({
  temporaryOnChangeRoleForDemo = (): void => {},
  roles,
  isAdmin = false,
}): React.ReactElement => {
  const defaultRole = isAdmin
    ? GEOWEB_ROLE_PRESETS_ADMIN.name
    : GEOWEB_ROLE_USER.name;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [currentRole, setCurrentRole] = React.useState<string>(defaultRole);

  const dispatch = useDispatch();
  const { t } = useAuthenticationTranslation();

  const onFakeRequest = (): Promise<void> =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 300);
    });

  const onChangeRole = (role: Role): void => {
    setIsLoading(true);

    onFakeRequest()
      .then(() => {
        setCurrentRole(role.name);
        temporaryOnChangeRoleForDemo(role);
        const message: snackbarTypes.TranslatableMessage = {
          type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
          key: 'auth-role-message-assigned',
          params: {
            role: role.getTitle(t),
          },
        };
        dispatch(snackbarActions.openSnackbar(message));
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <UserMenuRoles
      onChangeRole={onChangeRole}
      roles={roles}
      currentRole={currentRole}
      isLoading={isLoading}
    />
  );
};

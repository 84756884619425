/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { MenuItem } from '@mui/material';
import { FieldValues, useFormContext } from 'react-hook-form';
import {
  ReactHookFormSelect,
  isEmpty,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { useWarningsTranslation } from '../../../utils/i18n';

export const probablityOptions = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10];
export const defaultProbability = 30;

export const ProbabilityField: React.FC<{
  isDisabled?: boolean;
  isReadOnly?: boolean;
}> = ({ isDisabled, isReadOnly }) => {
  const { t } = useWarningsTranslation();
  const { trigger, getValues } = useFormContext();
  const { isRequired } = useDraftFormHelpers();

  const isValidProbabilityForLevel = (
    value: number,
    fieldValues: FieldValues,
  ): string | boolean => {
    if (fieldValues['level'] === 'moderate' && value < 30) {
      return t('warning-probability-moderate-level-invalid');
    }
    return true;
  };

  const onChangeProbability = (): void => {
    void trigger('probability');

    // We don't want to trigger the missing value validation
    if (!isEmpty(getValues('level'))) {
      void trigger('level');
    }
  };

  return (
    <ReactHookFormSelect
      name="probability"
      label={t('warning-probability')}
      rules={{
        validate: {
          isRequired,
          isValidProbabilityForLevel,
        },
      }}
      disabled={isDisabled || isReadOnly}
      isReadOnly={isReadOnly}
      defaultValue={defaultProbability}
      onChange={onChangeProbability}
    >
      {probablityOptions.map((probability) => (
        <MenuItem value={probability} key={probability}>
          {probability} %
        </MenuItem>
      ))}
    </ReactHookFormSelect>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { ConfigType } from '@opengeoweb/shared';
import { CreateApiProps, Credentials, GeoWebJWT, Role } from './types';

const API_NAMESPACE = 'api';
const DEFAULT_TIMEOUT = 15000;

export const KEEP_ALIVE_POLLER_IN_SECONDS = 60; // Number of seconds between the checks if the token should be refreshed.

export const REFRESH_TOKEN_WHEN_PCT_EXPIRED = 75; // Refresh token when 75% expired. Set to (10 / 3600) * 100 = 0.2777778% to test with 10 second interval (assuming 1 hour token expiration).

const DEFAULT_TOKEN_EXPIRES_IN = 3600; // Number of seconds a token expires by default

export const MILLISECOND_TO_SECOND = 1 / 1000;

const ns = API_NAMESPACE;

export const GEOWEB_ROLE_PRESETS_ADMIN: Role = {
  name: 'ROLE_PRESET_ADMIN',
  getTitle: (t) => t('api-role-title-preset-admin', { ns }),
};

export const GEOWEB_ROLE_USER: Role = {
  name: 'ROLE_USER',
  getTitle: (t) => t('api-role-title-user', { ns }),
};

/**
 * Creates a Credentials object based on the axios response from the token service.
 *
 * It will calculate the expires_at attribute based on the expires_in property found in the JWT.
 *
 * @param tokenResponse Response of the tokenservice as axios response object.
 * @returns Credentials object.
 */
export const makeCredentialsFromTokenResponse = (
  tokenResponse: AxiosResponse,
  authConfig?: ConfigType,
): Credentials => {
  const token: GeoWebJWT = tokenResponse.data.body || tokenResponse.data;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { access_token, refresh_token, id_token, expires_in } = token;
  if (!access_token || !id_token) {
    // message is not user-facing
    throw new Error('Login failed');
  }
  const userInfoString = atob(
    // convert base64url specific characters before parsing as base64
    id_token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'),
  );
  const userInfo = JSON.parse(userInfoString);

  const tokenExpirationInSeconds: number =
    expires_in && expires_in > 0 ? expires_in : DEFAULT_TOKEN_EXPIRES_IN; // If not set or zero in the token, assume valid for 1 hour.
  const pctTokenExpirationInSeconds =
    tokenExpirationInSeconds * (REFRESH_TOKEN_WHEN_PCT_EXPIRED / 100);
  const epochTimeTokenExpirationInSeconds =
    pctTokenExpirationInSeconds + getCurrentTimeInSeconds();

  const isAuthCognito = userInfo.iss.includes('cognito');
  const roleClaimName = authConfig?.GW_AUTH_ROLE_CLAIM_NAME;
  const groups = userInfo[roleClaimName!];
  const newAuth = {
    username: isAuthCognito ? userInfo['cognito:username'] : userInfo.email,
    roles: groupsToRoles(groups, authConfig),
    token: access_token,
    refresh_token: refresh_token || '',
    expires_at: epochTimeTokenExpirationInSeconds,
    has_connection_issue: false,
  };
  return newAuth;
};

export const refreshAccessToken = ({
  auth,
  config: { authTokenURL, authClientId, appURL } = {},
  timeout = DEFAULT_TIMEOUT,
}: CreateApiProps): Promise<AxiosResponse> => {
  // Refresh token request with a new axios instance
  // without request interceptor
  const tokenAxiosInstance = axios.create({
    headers: {},
    timeout,
  });
  const refreshPayload = {
    refresh_token: auth!.refresh_token,
    redirect_uri: `${appURL}/code`,
    grant_type: 'refresh_token',
    client_id: authClientId!,
  };
  /* Send data in the "application/x-www-form-urlencoded" format.
    If only JSON is supported, use Axios' default content type ("application/x-www-form-urlencoded"). */
  const useDefaultContentType = authTokenURL!.includes('amazonaws.com');
  const data = useDefaultContentType
    ? refreshPayload
    : new URLSearchParams(refreshPayload);

  const axiosConfig = {
    headers: {
      'Content-Type': useDefaultContentType
        ? 'application/json'
        : 'application/x-www-form-urlencoded',
    },
  };
  return tokenAxiosInstance.post(authTokenURL!, data, axiosConfig);
};

export const refreshAccessTokenAndSetAuthContext = async ({
  auth,
  onSetAuth,
  config,
  timeout = DEFAULT_TIMEOUT,
  configURLS,
  onLogin,
}: CreateApiProps & { configURLS?: ConfigType }): Promise<void> => {
  try {
    const refreshedToken = await refreshAccessToken({
      auth,
      config,
      timeout,
    });
    const newAuth = makeCredentialsFromTokenResponse(
      refreshedToken,
      configURLS,
    );

    // Cognito does not send a new refresh token, but gitlab does. Set it here into the auth context.
    if (!newAuth.refresh_token || newAuth.refresh_token.length === 0) {
      newAuth.refresh_token = auth!.refresh_token;
    }

    // If the prop for the role config is not set, keep the roles of the current auth context.
    if (!configURLS) {
      newAuth.roles = auth!.roles;
    }

    onSetAuth!(newAuth);
  } catch (e: unknown) {
    const status = (e as AxiosError).response?.status;
    if (status === 400) {
      onLogin!(false);
    } else {
      onSetAuth!({ ...auth!, has_connection_issue: true });
    }
  }
};

export const createApiInstance = ({
  auth,
  config: { baseURL } = {},
  timeout = DEFAULT_TIMEOUT,
}: CreateApiProps): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {},
    timeout,
  });
  // Request interceptor for API calls done BEFORE the request is made.
  axiosInstance.interceptors.request.use(
    async (
      axiosConfig: InternalAxiosRequestConfig,
    ): Promise<InternalAxiosRequestConfig<unknown>> => {
      // Add the access token to the headers of the request.
      const newConfig = {
        ...axiosConfig,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth!.token}`,
          Accept: 'application/json',
          ...axiosConfig.headers, // use header settings from config parameters
        },
      };
      return newConfig as InternalAxiosRequestConfig;
    },
    async (error) => {
      await Promise.reject(error);
    },
  );

  // Response interceptor for API calls done AFTER the request is made.
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      // If request fails with 401, retry the request once.
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401 &&
        !originalRequest.inRetry
      ) {
        originalRequest.inRetry = true;
        // Update the headers of the original request with the token from the current auth context.
        if (originalRequest.headers?.Authorization) {
          originalRequest.headers.Authorization = `Bearer ${auth?.token}`;
        }
        return axiosInstance(originalRequest);
      }
      return Promise.reject(error);
    },
  );
  return axiosInstance;
};

export const createNonAuthApiInstance = ({
  config: { baseURL } = {},
  timeout = DEFAULT_TIMEOUT,
}: CreateApiProps): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL,
    headers: {},
    timeout,
  });

  // Request interceptor for API calls
  axiosInstance.interceptors.request.use(
    async (
      config: InternalAxiosRequestConfig,
    ): Promise<InternalAxiosRequestConfig<unknown>> => {
      const newConfig = {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...config.headers, // use header settings from config parameters
        },
      };
      return newConfig as InternalAxiosRequestConfig;
    },
    async (error) => {
      await Promise.reject(error);
    },
  );

  return axiosInstance;
};

export const fakeApiRequest = (signal?: AbortController): Promise<void> =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      resolve();
    }, 300);
    signal?.signal.addEventListener('abort', () => {
      clearTimeout(timer);
      reject(new Error('canceled'));
    });
  });

export const createFakeApiInstance = (): AxiosInstance =>
  ({
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars,
    get: (_url: string, _params?: unknown): Promise<void> => fakeApiRequest(),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars,
    put: (_url, _params?): Promise<void> => fakeApiRequest(),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars,
    post: (_url, _params?): Promise<void> => fakeApiRequest(),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    delete: (_url, _params?): Promise<void> => fakeApiRequest(),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
    patch: (_url, _params?, _signal?): Promise<void> =>
      fakeApiRequest(_signal as AbortController),
  }) as AxiosInstance;

export const getCurrentTimeInSeconds = (): number =>
  Date.now() * MILLISECOND_TO_SECOND;

export const groupsToRoles = (
  groups: string[] | undefined,
  authConfig?: ConfigType,
): Role[] => {
  if (!Array.isArray(groups)) {
    return [GEOWEB_ROLE_USER];
  }
  const requiredGroupForPresetsAdmin =
    authConfig?.GW_AUTH_ROLE_CLAIM_VALUE_PRESETS_ADMIN;
  const roles: Role[] = groups.reduce(
    (acc: Role[], group: string) => {
      if (
        requiredGroupForPresetsAdmin &&
        group === requiredGroupForPresetsAdmin
      ) {
        acc.push(GEOWEB_ROLE_PRESETS_ADMIN);
      }
      return acc;
    },
    [GEOWEB_ROLE_USER],
  );

  return roles;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Dialog } from '@mui/material';
import { CustomIconButton, ToolHeader } from '@opengeoweb/shared';
import { ListItem } from '@opengeoweb/theme';
import TacOverview from './TacOverview';
import { TafFromBackend } from '../../../types';
import { useTafTranslation } from '../../../utils/i18n';

interface TacOverviewMobileProps {
  upcomingTafs: TafFromBackend[];
  currentTafs: TafFromBackend[];
  expiredTafs: TafFromBackend[];
  activeTaf?: TafFromBackend;
  defaultOpen?: boolean;
}

const TacOverviewMobile: React.FC<TacOverviewMobileProps> = ({
  upcomingTafs,
  currentTafs,
  expiredTafs,
  activeTaf,
  defaultOpen = false,
}: TacOverviewMobileProps) => {
  const { t } = useTafTranslation();
  const [isOpen, toggleOpen] = React.useState(defaultOpen);
  const onClose = (): void => toggleOpen(false);

  return (
    <>
      <CustomIconButton
        isSelected={isOpen}
        onClick={(): void => toggleOpen(true)}
        data-testid="tac-overview-mobile-button"
        sx={{
          display: { xs: 'inline-flex', sm: 'none' },
          position: 'absolute',
          right: '4px',
          top: '42px',
        }}
        variant="tool"
      >
        <ListItem />
      </CustomIconButton>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{ sx: { margin: 2, width: '100%', height: '100%' } }}
        data-testid="tac-overview-mobile"
      >
        <ToolHeader
          title={t('tac-overview')}
          size="small"
          isDraggable={false}
          onClose={onClose}
        />
        <TacOverview
          upcomingTafs={upcomingTafs}
          currentTafs={currentTafs}
          expiredTafs={expiredTafs}
          activeTaf={activeTaf}
          hideTitle
        />
      </Dialog>
    </>
  );
};

export default TacOverviewMobile;

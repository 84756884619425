/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ThemeTypes } from '@opengeoweb/theme';

export interface ConfigType {
  // auth
  GW_AUTH_LOGIN_URL?: string;
  GW_AUTH_LOGOUT_URL?: string;
  GW_AUTH_TOKEN_URL?: string;
  GW_AUTH_CLIENT_ID?: string;
  GW_AUTH_ROLE_CLAIM_NAME?: string;
  GW_AUTH_ROLE_CLAIM_VALUE_PRESETS_ADMIN?: string;
  // app
  GW_BE_VERSION_BASE_URL?: string;
  GW_APP_URL?: string;
  GW_INITIAL_PRESETS_FILENAME?: string;
  GW_CAP_CONFIGURATION_FILENAME?: string;
  GW_TIMESERIES_CONFIGURATION_FILENAME?: string;
  GW_DEFAULT_THEME?: ThemeTypes;
  GW_INITIAL_WORKSPACE_PRESET?: string;
  // backend urls
  GW_SW_BASE_URL?: string;
  GW_SIGMET_BASE_URL?: string;
  GW_AIRMET_BASE_URL?: string;
  GW_TAF_BASE_URL?: string;
  GW_PRESET_BACKEND_URL?: string;
  GW_CAP_BASE_URL?: string;
  GW_DRAWINGS_BASE_URL?: string;
  GW_TECHNICAL_RELEASE_NOTES_URL?: string;
  GW_VERSION_PROGRESS_NOTES_URL?: string;
  GW_LOCATION_BASE_URL?: string;
  // app features
  GW_FEATURE_APP_TITLE?: string;
  GW_FEATURE_FORCE_AUTHENTICATION?: boolean;
  GW_FEATURE_MODULE_SPACE_WEATHER?: boolean;
  GW_FEATURE_MENU_FEEDBACK?: boolean;
  GW_FEATURE_MENU_INFO?: boolean;
  GW_FEATURE_MENU_VERSION?: boolean;
  GW_FEATURE_MENU_FE_VERSION?: boolean;
  GW_FEATURE_DISPLAY_SEARCH_ON_MAP?: boolean;
  GW_FEATURE_INITIALIZE_SENTRY?: boolean;
  GW_FEATURE_ENABLE_SPECIAL_THEMES?: boolean;
  // i18n
  GW_LANGUAGE?: string;
}

export interface TimeSeriesService {
  id: string;
  description: string;
  url: string;
  type: ServiceInterface;
  name: string;
  // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/4389 Set to mandatory once the BE presets have been adjusted
  scope?: SystemScope;
}

// Supported interfaces are EDR and OGC Features API
export type ServiceInterface = 'EDR' | 'OGC';

export type SystemScope = 'user' | 'system';

// eslint-disable-next-line no-redeclare
export const SystemScope = {
  user: 'user' as SystemScope,
  system: 'system' as SystemScope,
} as const;

// Presets types
export type PresetScope = SystemScope | 'shared';

// eslint-disable-next-line no-redeclare
export const PresetScope = {
  ...SystemScope,
  shared: 'shared' as PresetScope,
} as const;

// This is a copy of PlotPreset in libs/timeseries/src/lib/components/TimeSeries/types.ts
export interface PlotPreset {
  connectedMap?: string;
  mapId: string;
  plots: Plot[];
  parameters: PlotParameter[];
}

// This is a copy of Plot in libs/timeseries/src/lib/components/TimeSeries/types.ts
export interface Plot {
  title: string;
  plotId: string;
  enabled?: boolean;
  parameters?: PlotParameter[];
}

// This is a copy of Parameter in libs/timeseries/src/lib/components/TimeSeries/types.ts
export interface PlotParameter {
  id?: string;
  plotId: string;
  unit?: string;
  propertyName: string;
  plotType: PlotType | 'area';
  enabled?: boolean;
  color?: string;
  opacity?: number;
  serviceId: string;
  collectionId: string;
  instanceId?: string;
  instances?: EDRInstance[];
}

export type PlotType = 'bar' | 'line' | 'scatter';

// This is a copy of EDRInstance in libs/timeseries/src/lib/components/TimeSeries/types.ts
export interface EDRInstance {
  id: string;
  data_queries?: {
    position?: {
      link: {
        variables: {
          output_formats: string[];
        };
      };
    };
    locations?: {
      link: {
        variables: {
          output_formats: string[];
        };
      };
    };
    instances?: {
      link: string;
    };
  };
  extent?: {
    temporal?: {
      interval: string[][];
      crs: string;
      values: string[];
    };
    spatial: {
      crs: string;
      bbox: [number, number, number, number];
    };
  };
}

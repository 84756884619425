/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Slider, SliderProps } from '@mui/material';
import { useThemeContext } from '@opengeoweb/theme';

export const sliderHeaderStyle = {
  fontFamily: 'Roboto-Black, Roboto',
  fontSize: 12,
  color: 'geowebColors.typographyAndIcons.text',
  padding: '11px 0 11px 16px',
};

const sliderStyle = {
  '& span.selectedLabel': {
    fontWeight: 'bold',
  },
  '& .MuiSlider-thumb': {
    backgroundColor: 'geowebColors.customSlider.thumb',
    boxShadow: '0px 3px 5px 1px rgba(0,0,0,0.3)',
    '&.Mui-focusVisible, &:hover, &:active': {
      boxShadow: '0px 3px 5px 2px rgba(0,0,0,0.4)',
    },
  },
  '& .MuiSlider-mark': {
    backgroundColor: 'geowebColors.customSlider.mark',
  },
  '& .MuiSlider-markLabel': {
    fontFamily: 'Roboto-Black, Roboto',
    fontSize: 12,
    color: 'geowebColors.typographyAndIcons.text',
  },
  '& .MuiSlider-track': {
    color: 'geowebColors.customSlider.track',
    backgroundColor: 'geowebColors.customSlider.track',
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'geowebColors.customSlider.rail',
    opacity: 1,
  },
  '&.Mui-disabled': {
    '& .MuiSlider-thumb': {
      backgroundColor: 'geowebColors.customSlider.thumbDisabled',
    },
    '& .MuiSlider-mark': {
      backgroundColor: 'geowebColors.customSlider.markDisabled',
    },
    '& .MuiSlider-track': {
      backgroundColor: 'geowebColors.customSlider.trackDisabled',
      color: 'geowebColors.customSlider.trackDisabled',
    },
    '& .MuiSlider-rail': {
      backgroundColor: 'geowebColors.customSlider.railDisabled',
      opacity: 1,
    },
  },
};

interface CustomSliderProps extends SliderProps {
  shouldAutoFocus?: boolean;
}

export const CustomSlider: React.FC<CustomSliderProps> = ({
  value,
  orientation = 'vertical',
  shouldAutoFocus = false,
  ...props
}: CustomSliderProps) => {
  // Add custom class (for bold text) to label adjacent to thumb component.
  // Material UI doesn't support highlighing only adjacent label
  // when track is highlighted everywhere below selected value.
  const element = React.useRef<HTMLSpanElement>(null);
  const themeContext = useThemeContext();
  React.useEffect(() => {
    if (!element.current) {
      return;
    }
    const activeMarkLabelElements = element.current.getElementsByClassName(
      'MuiSlider-markLabelActive',
    );
    const [first, ...rest] = Array.from(activeMarkLabelElements).reverse();
    if (first) {
      first.classList.add('selectedLabel');
    }
    rest.forEach((element) => element.classList.remove('selectedLabel'));
  }, [value, themeContext.namedTheme]);

  // autofocus
  React.useEffect(() => {
    if (shouldAutoFocus && element.current) {
      const thumb: null | HTMLElement = element.current.querySelector(
        '.MuiSlider-thumb input',
      );
      thumb!.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Slider
      sx={sliderStyle}
      ref={element}
      value={value}
      orientation={orientation}
      role="slider"
      slotProps={{
        thumb: {
          role: 'button',
        },
        mark: {
          role: 'listitem',
        },
      }}
      {...props}
    />
  );
};

export default CustomSlider;

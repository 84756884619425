/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { connect } from 'react-redux';
import {
  CoreAppStore,
  mapActions,
  mapEnums,
  mapSelectors,
} from '@opengeoweb/store';
import { AutoUpdateButton } from '@opengeoweb/timeslider';

interface ConnectedAutoUpdateButtonProps {
  mapId: string;
  isAutoUpdating?: boolean;
  toggleAutoUpdate?: typeof mapActions.toggleAutoUpdate;
}

const connectRedux = connect(
  (store: CoreAppStore, props: ConnectedAutoUpdateButtonProps) => ({
    isAutoUpdating: mapSelectors.isAutoUpdating(store, props.mapId),
  }),
  {
    toggleAutoUpdate: mapActions.toggleAutoUpdate,
  },
);

const AutoUpdateButtonConnectComponent = connectRedux(
  ({
    mapId,
    isAutoUpdating,
    toggleAutoUpdate,
  }: ConnectedAutoUpdateButtonProps) => {
    const onClick = (): void => {
      toggleAutoUpdate!({
        mapId,
        shouldAutoUpdate: !isAutoUpdating,
        origin: mapEnums.MapActionOrigin.map,
      });
    };

    return (
      <AutoUpdateButton
        isAutoUpdating={isAutoUpdating}
        toggleAutoUpdate={onClick}
        disabled={false}
      />
    );
  },
);

export const AutoUpdateButtonConnect = connectRedux(
  AutoUpdateButtonConnectComponent,
);

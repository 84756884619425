/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { InitialProps, SupportedComponentTypes } from '@opengeoweb/core';
import { SupportedComponentTypes as SupportedTimeSeriesComponentTypes } from '@opengeoweb/timeseries';

import { EntityState } from '@reduxjs/toolkit';
import { WarningListComponentType } from '@opengeoweb/warnings';
import { PresetScope } from '@opengeoweb/shared';
import {
  FetchViewPresetsListParams,
  ViewPresetListItem,
} from '../viewPresetsList/types';
import { WorkspaceViewPresetComponentsLookupType } from '../workspace/types';

export type WorkspaceSupportedComponentTypes =
  | SupportedComponentTypes
  | SupportedTimeSeriesComponentTypes
  | WarningListComponentType
  | WorkspaceViewPresetComponentsLookupType;

// ViewPreset coming from BE
export interface ViewPreset {
  id?: string;
  title: string;
  abstract?: string;
  keywords: string;
  componentType: WorkspaceSupportedComponentTypes;
  initialProps?: InitialProps;
  scope?: PresetScope;
}

export enum ViewPresetErrorComponent {
  PRESET_DETAIL = 'PRESET_DETAIL',
  PRESET_LIST = 'PRESET_LIST',
}

export enum ViewPresetErrorType {
  AUTH = 'AUTH', // Errors related to authentication and authorization
  GENERIC = 'GENERIC',
}

export type ViewPresetError =
  | {
      component: ViewPresetErrorComponent;
      errorType: ViewPresetErrorType;
      message: string;
    }
  | undefined;

export interface ViewPresetsListFilter {
  label: string;
  id: string;
  type: 'scope' | 'keyword';
  isSelected?: boolean;
  isDisabled?: boolean;
}

export interface ViewPresetEntity {
  panelId: string; // can be a mapId or a viewId because 1 view can have multiple maps, but some views as sigmet don't have a map
  activeViewPresetId: string;
  hasChanges: boolean;
  isFetching: boolean;
  error: ViewPresetError;
  isViewPresetListDialogOpen: boolean;
  filters: ViewPresetsListFilter[];
  searchQuery: string;
  filterResults: EntityState<ViewPresetListItem>;
}

export interface ViewPresetFormValues {
  title?: string;
  abstract?: string;
  initialProps?: InitialProps;
}

export interface ViewPresetDialog {
  title: string; // dialog title
  action: PresetAction;
  viewPresetId: string;
  panelId: string;
  formValues: ViewPresetFormValues;
}

export interface ViewPresetState extends EntityState<ViewPresetEntity> {
  viewPresetDialog?: ViewPresetDialog;
}

export enum PresetAction {
  OPEN = 'Open',
  DELETE = 'Delete',
  SAVE = 'Save',
  SAVE_AS = 'Save as',
  SHARE = 'Share',
  EDIT = 'Edit',
  RESET = 'Reset',
}

// actions
interface ViewPresetAction {
  panelId: string;
}

// list api request actions
export interface FetchViewPresetsPayload extends ViewPresetAction {
  filterParams: FetchViewPresetsListParams;
}
export interface FetchedViewPresetsPayload extends ViewPresetAction {
  viewPresets: ViewPresetListItem[];
  filterParams: FetchViewPresetsListParams;
}

// detail api request actions
export interface FetchViewPresetPayload extends ViewPresetAction {
  viewPresetId: string;
}
export interface FetchedViewPresetPayload extends ViewPresetAction {
  viewPresetId: string;
  viewPreset: ViewPreset;
}
export interface SaveViewPresetPayload extends ViewPresetAction {
  viewPresetId: string;
  viewPreset: ViewPreset;
}
export interface SavedViewPresetPayload extends ViewPresetAction {
  viewPresetId: string;
}
export interface ErrorViewPresetPayload extends ViewPresetAction {
  viewPresetId?: string;
  error: ViewPresetError;
}

// map actions
export interface SetViewPresetHasChangePayload extends ViewPresetAction {
  hasChanges: boolean;
}

export interface SetActiveViewPresetIdPayload extends ViewPresetAction {
  viewPresetId: string;
}

export interface OnSuccesViewPresetActionPayload extends ViewPresetAction {
  viewPresetId: string;
  action: PresetAction;
  title: string;
  componentType?: string;
  scope?: PresetScope;
  abstract?: string;
}
export interface SelectViewPresetPayload extends ViewPresetAction {
  viewPresetId: string;
  shouldReset?: boolean;
}

export interface RegisterViewPresetPayload extends ViewPresetAction {
  viewPresetId: string;
}

export interface ToggleViewPresetListDialogPayload extends ViewPresetAction {
  isViewPresetListDialogOpen: boolean;
}

export interface ToggleSelectFilterChip extends ViewPresetAction {
  id: string;
  isSelected: boolean;
}

export interface SetFilterChipsPayload extends ViewPresetAction {
  filters: {
    id: string;
    isSelected: boolean;
  }[];
}

export type SetSelectAllFilterChip = ViewPresetAction;

export interface SearchWorkspaceFilter extends ViewPresetAction {
  searchQuery: string;
}

export type UnregisterViewPresetPayload = ViewPresetAction;

// dialog options
export interface OpenViewPresetDialogPayload {
  viewPresetDialog: ViewPresetDialog;
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormSelect,
  ReactHookFormTextField,
} from '@opengeoweb/form-fields';

import { TFunction } from 'i18next';
import { styles } from './EditFormFields.styles';
import { ThresholdValues, EventLevels, XrayClasses } from '../../../types';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

export const isValidPeakClass = (
  t: TFunction,
  value: string,
): boolean | string => {
  const regexPeakClass = /^[MX]\d+\.\d$/;
  return regexPeakClass.test(value) ? true : t('notification-peak-class-error');
};

interface XrayClassPeakClassProps {
  actionMode: string;
}

const XrayClassPeakClass: React.FC<XrayClassPeakClassProps> = ({
  actionMode,
}: XrayClassPeakClassProps) => {
  const { t } = useSpaceweatherTranslation();
  const { watch, setValue } = useFormContext();

  const category = watch('category');

  return (
    <Grid container size={12}>
      <Grid size={5}>
        <ReactHookFormSelect
          name="xrayclass"
          label={t('notification-xray-class')}
          sx={styles.inputField}
          inputProps={{
            SelectDisplayProps: {
              'data-testid': 'xrayclass-select',
            },
          }}
          rules={{ required: true }}
          onChange={(event: SelectChangeEvent<unknown>): void => {
            const index = XrayClasses.findIndex(
              (item) => item === event.target.value,
            );
            setValue(
              'neweventlevel',
              EventLevels[category as keyof typeof EventLevels][index],
              {
                shouldValidate: true,
              },
            );
            setValue(
              'threshold',
              ThresholdValues[category as keyof typeof ThresholdValues][index],
              {
                shouldValidate: true,
              },
            );
          }}
        >
          {Object.keys(XrayClasses).map((key) => (
            <MenuItem value={XrayClasses[parseInt(key, 10)]} key={key}>
              {XrayClasses[parseInt(key, 10)]}
            </MenuItem>
          ))}
        </ReactHookFormSelect>
      </Grid>
      {actionMode === 'Summarise' && (
        <Grid size={5}>
          <ReactHookFormTextField
            name="peakclass"
            sx={styles.inputField}
            inputProps={{ 'data-testid': 'peakclass-input' }}
            label={t('notification-peak-class')}
            upperCase
            rules={{
              required: true,
              validate: {
                isValidPeakClass: (value: string) => isValidPeakClass(t, value),
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default XrayClassPeakClass;

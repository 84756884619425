/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { isEqual } from 'lodash';
import React from 'react';
import Sortable from 'sortablejs';
import { HIDDEN_INPUT_HELPER_IS_DRAFT } from '@opengeoweb/form-fields';
import { useIsMounted, dateUtils } from '@opengeoweb/shared';

import { TFunction } from 'i18next';
import {
  formatValidToString,
  parseValidToObject,
} from './TafFormRow/validations/validField';
import {
  BaseForecast,
  BaseForecastFormData,
  ChangeGroup,
  ChangeGroupFormData,
  MessageType,
  TAC,
  Taf,
  TafActions,
  TafFormData,
  TafStatus,
  Weather,
} from '../../types';
import {
  formatVisibilityToString,
  parseVisibilityToObject,
} from './TafFormRow/validations/visibility';
import { parseWeatherToObject } from './TafFormRow/validations/weather';
import {
  formatWindToString,
  parseWindToObject,
} from './TafFormRow/validations/wind';
import {
  formatCloudsToString,
  parseCloudsToObject,
} from './TafFormRow/validations/clouds';
import { FieldNames } from './TafFormRow/types';
import { IssuesPanePosition } from '../IssuesPane/types';
import { DATE_FORMAT_ISSUE_TIME } from '../../utils/dateFormats';
import { useTafTranslation } from '../../utils/i18n';

export const emptyBaseForecast = {
  wind: '',
  visibility: '',
  weather: {
    weather1: '',
    weather2: '',
    weather3: '',
  },
  cloud: {
    cloud1: '',
    cloud2: '',
    cloud3: '',
    cloud4: '',
  },
};

export const MIN_NUMBER_CHANGE_GROUP = 5;

// formatters
export const formatIssueTime = (issueDate: string): string => {
  const issueDateAsString = dateUtils.dateToString(
    new Date(issueDate),
    DATE_FORMAT_ISSUE_TIME,
  );
  return issueDateAsString || 'Not issued';
};

type BaseForecastOrChangeGroupFormData<T extends BaseForecast | ChangeGroup> =
  T extends ChangeGroup ? ChangeGroupFormData : BaseForecastFormData;

export function formatFormValues<T extends BaseForecast | ChangeGroup>(
  row: T,
): BaseForecastOrChangeGroupFormData<T> {
  return Object.keys(row).reduce((list, key) => {
    switch (key) {
      case 'valid': {
        return {
          ...list,
          valid: formatValidToString(list[key]!),
        };
      }
      case 'wind': {
        return {
          ...list,
          wind: formatWindToString(list[key]!),
        };
      }
      case 'visibility': {
        // Assume if visibility field set - no cavOK
        return {
          ...list,
          visibility: formatVisibilityToString(list[key]!),
        };
      }
      case 'cavOK': {
        // Assume if cavOK field set to true - no visibility field, otherwise return the original list containing the visibility and remove cavOK
        const { cavOK, ...formData } = list;
        if (list[key]) {
          return {
            ...formData,
            visibility: formatVisibilityToString(list[key]!),
          } as unknown as T;
        }

        return formData as T;
      }
      case 'cloud': {
        return {
          ...list,
          cloud: formatCloudsToString(list[key]!),
        };
      }
      default: {
        return list;
      }
    }
  }, row) as unknown as BaseForecastOrChangeGroupFormData<T>;
}

type BaseForecastOrChangeGroup<
  T extends BaseForecastFormData | ChangeGroupFormData,
> = T extends ChangeGroupFormData ? ChangeGroup : BaseForecast;

// parsers
function reduceRowValues<T extends BaseForecastFormData | ChangeGroupFormData>(
  row: T,
  tafStart: string,
  tafEnd: string,
): BaseForecastOrChangeGroup<T> {
  return Object.keys(row).reduce((list, key) => {
    switch (key) {
      case 'valid': {
        return {
          ...list,
          valid: parseValidToObject(list[key], tafStart, tafEnd),
        };
      }
      case 'wind': {
        return {
          ...list,
          wind: parseWindToObject(list[key]!),
        };
      }
      case 'visibility': {
        return {
          ...list,
          ...parseVisibilityToObject(list[key]!),
        };
      }
      case 'weather': {
        return {
          ...list,
          weather: parseWeatherToObject(list[key] as Weather),
        };
      }
      case 'cloud': {
        return {
          ...list,
          cloud: parseCloudsToObject(list[key]!),
        };
      }
      // Trim string field
      case 'change':
      case 'probability': {
        return {
          ...list,
          [key]: String(list[key as keyof BaseForecast]).trim(),
        };
      }
      default: {
        return list;
      }
    }
  }, row) as unknown as BaseForecastOrChangeGroup<T>;
}

export function parseFormValues<
  T extends ChangeGroupFormData | BaseForecastFormData,
>(row: T, tafStart: string, tafEnd: string): BaseForecastOrChangeGroup<T> {
  const reducedRow = reduceRowValues(row, tafStart, tafEnd);
  return Object.keys(reducedRow).reduce((list, key) => {
    switch (key) {
      case 'cavOK': {
        // if cavOK is set - remove visibility from the list
        if (reducedRow.cavOK) {
          const { visibility, ...rest } = list;
          return rest as BaseForecastOrChangeGroup<T>;
        }
        return list;
      }
      case 'cloud': {
        // If cloud is undefined or empty, remove it from the list
        if (!reducedRow.cloud || Object.keys(reducedRow.cloud).length === 0) {
          const { cloud, ...rest } = list;
          return rest as BaseForecastOrChangeGroup<T>;
        }
        return list;
      }
      case 'weather': {
        // If empty, remove it from the list
        if (!reducedRow.weather!.weather1) {
          const { weather, ...rest } = list;
          return rest as BaseForecastOrChangeGroup<T>;
        }
        return list;
      }
      case 'wind': {
        // If empty, remove it from the list
        if (
          !reducedRow.wind!.direction &&
          isNaN(Number(reducedRow.wind!.direction))
        ) {
          const { wind, ...rest } = list;
          return rest as BaseForecastOrChangeGroup<T>;
        }
        return list;
      }
      case 'visibility': {
        // If empty, remove it from the list
        if (
          !reducedRow.visibility!.range &&
          isNaN(reducedRow.visibility!.range)
        ) {
          const { visibility, ...rest } = list;
          return rest as BaseForecastOrChangeGroup<T>;
        }
        return list;
      }
      case 'change':
      case 'probability':
        // If empty, remove it from the list
        if (!reducedRow[key as keyof BaseForecast]) {
          const { [key]: emptyKey, ...rest } = list as ChangeGroup;
          return rest as BaseForecastOrChangeGroup<T>;
        }
        return list;

      case 'valid': {
        // If empty, remove it from the list
        if (!reducedRow[key]) {
          const { [key]: emptyKey, ...rest } = list;
          return rest as BaseForecastOrChangeGroup<T>;
        }
        return list;
      }
      default: {
        return list;
      }
    }
  }, reducedRow) as BaseForecastOrChangeGroup<T>;
}

export const convertTafValuesToObject = (formData: TafFormData): Taf => {
  const { IS_DRAFT, ...productToPost } = formData;
  const tafStart = productToPost.validDateStart;
  const tafEnd = productToPost.validDateEnd;

  const parsedChangeGroups =
    productToPost.changeGroups &&
    productToPost.changeGroups
      // parse data for each changegroup row
      .map((row) => parseFormValues(row, tafStart, tafEnd) as ChangeGroup)
      // filter out empty changegroup rows
      .filter((value: ChangeGroup) => Object.keys(value).length);

  const parsedBaseForecast =
    productToPost.baseForecast &&
    parseFormValues(productToPost.baseForecast, tafStart, tafEnd);

  const parsedFormValues = {
    ...productToPost,
    baseForecast: parsedBaseForecast,
    changeGroups: parsedChangeGroups,
  };

  return Object.keys(parsedFormValues).reduce<Taf>((list: Taf, key: string) => {
    switch (key) {
      case 'changeGroups': {
        // remove changeGroups when empty
        if (
          !parsedFormValues.changeGroups ||
          !parsedFormValues.changeGroups.length
        ) {
          const { changeGroups, ...rest } = list;
          return rest;
        }
        return list;
      }
      case 'previousId':
      case 'previousValidDateStart':
      case 'previousValidDateEnd':
      case 'issueDate': {
        // remove when empty
        if (!parsedFormValues[key]) {
          const { [key]: emptyKey, ...rest } = list;
          return rest;
        }
        return list;
      }

      default: {
        return list;
      }
    }
  }, parsedFormValues);
};

// Ensure we have enough data to retrieve a TAC - we only need to check for wind and visibility/ cavok as those are the only ones required in all cases
export const shouldRetrieveTAC = (product: Taf): boolean => {
  if (
    product &&
    product.baseForecast &&
    product.baseForecast.wind &&
    'speed' in product.baseForecast.wind &&
    product.baseForecast.wind.speed !== '' &&
    ((product.baseForecast.visibility &&
      'range' in product.baseForecast.visibility &&
      product.baseForecast.visibility.range !== ('' as unknown)) ||
      product.baseForecast.cavOK)
  ) {
    return true;
  }
  return false;
};

export const getFieldNames = (
  isChangeGroup: boolean,
  index: number,
): FieldNames => {
  const namePrefix = !isChangeGroup ? 'baseForecast' : `changeGroups[${index}]`;
  return {
    ...(!isChangeGroup && {
      messageType: 'messageType',
      location: 'location',
      issueDate: 'issueDate',
    }),
    ...(isChangeGroup && {
      probability: `${namePrefix}.probability`,
      change: `${namePrefix}.change`,
    }),
    valid: `${namePrefix}.valid`,
    wind: `${namePrefix}.wind`,
    visibility: `${namePrefix}.visibility`,
    weather1: `${namePrefix}.weather.weather1`,
    weather2: `${namePrefix}.weather.weather2`,
    weather3: `${namePrefix}.weather.weather3`,
    cloud1: `${namePrefix}.cloud.cloud1`,
    cloud2: `${namePrefix}.cloud.cloud2`,
    cloud3: `${namePrefix}.cloud.cloud3`,
    cloud4: `${namePrefix}.cloud.cloud4`,
  };
};

export const tacHasError = (tac: TAC, t: TFunction): boolean => {
  return (
    tac === t('TAC-missing-data') ||
    tac === t('TAC-not-available') ||
    tac === t('TAC-retrieving-data') ||
    tac === t('TAC-failed')
  );
};

export const useTACGenerator = (
  taf: Taf,
  requestTAC: (
    postTaf: Taf,
    getJSON?: boolean,
    abortSignal?: AbortSignal,
  ) => Promise<{ data: TAC }>,
  getJSON = false,
): [TAC, (tafToPost: Taf) => void, (newTAC: TAC) => void] => {
  const { t } = useTafTranslation();
  const [TAC, setTAC] = React.useState<TAC>(
    !taf || taf.status === 'NEW'
      ? t('TAC-not-available')
      : t('TAC-retrieving-data'),
  );
  const { isMounted } = useIsMounted();

  const controller = React.useRef<AbortController>();

  const onSetTac = (newTAC: TAC): void => {
    if (controller.current) {
      controller.current.abort();
    }
    setTAC(newTAC);
  };

  const retrieveTAC = React.useCallback(
    (tafToPost: Taf): void => {
      if (shouldRetrieveTAC(tafToPost)) {
        if (controller.current) {
          controller.current.abort();
        }

        controller.current = new AbortController();
        setTAC(t('TAC-retrieving-data'));

        requestTAC(tafToPost, getJSON, controller.current!.signal)
          .then((result) => {
            if (isMounted.current) {
              setTAC(result.data);
            }
          })
          .catch((error) => {
            if (isMounted && error.message !== 'canceled') {
              setTAC(t('TAC-failed'));
            }
          });
      } else {
        setTAC(t('TAC-missing-data'));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, requestTAC, getJSON],
  );

  React.useEffect(() => {
    if (taf && taf.status !== 'NEW') {
      retrieveTAC(taf);
    }
  }, [retrieveTAC, taf]);

  return [TAC, retrieveTAC, onSetTac];
};

export interface SortableField {
  id: string;
  chosen: boolean; // more info https://github.com/SortableJS/Sortable#options
}

interface OldAndNewIndex {
  oldIndex: number | undefined;
  newIndex: number | undefined;
}

export const useDragOrder = (
  trigger: (name?: string | string[]) => Promise<boolean>,
  move: (oldIndex: number, newIndex: number) => void,
): {
  onChangeOrder: (event: Sortable.SortableEvent) => void;
  onStartDrag: (event: Sortable.SortableEvent) => void;
  activeDraggingIndex: number | boolean;
} => {
  const [activeDraggingIndex, setActiveDraggingIndex] = React.useState<
    number | boolean
  >(null!);

  const [lastDragOrder, onUpdatelastDragOrder] = React.useState<
    null[] | number[]
  >([null, null]);

  const onChangeOrder = ({ oldIndex, newIndex }: OldAndNewIndex): void => {
    if (oldIndex === undefined || newIndex === undefined) {
      return;
    }
    move(oldIndex, newIndex);
    onUpdatelastDragOrder([oldIndex, newIndex]);
    setActiveDraggingIndex(false);
  };

  const onStartDrag = ({ oldIndex }: OldAndNewIndex): void =>
    setActiveDraggingIndex(oldIndex!);

  // triggers validation AFTER render of new sorted elements
  React.useEffect(() => {
    const [oldIndex, newIndex] = lastDragOrder;
    if (oldIndex !== null && newIndex !== null) {
      // trigger validation of all fields
      void trigger();
    }
  }, [lastDragOrder, trigger]);

  return { onChangeOrder, activeDraggingIndex, onStartDrag };
};

// dialog utils
export const getConfirmationDialogTitle = (
  action: TafActions,
  t: TFunction,
): string => {
  if (action === 'CLEAR') {
    return t('confirm-clear-title');
  }
  if (action === 'PUBLISH') {
    return t('button-publish');
  }
  if (action === 'AMEND') {
    return t('confirm-amend-title');
  }
  if (action === 'CORRECT') {
    return t('confirm-correct-title');
  }
  if (action === 'CANCEL') {
    return t('confirm-cancel-title');
  }
  return t('confirm-cancel-title');
};

// Check if the amended/corrected TAF has been changed. Only compare the fields users are able to fill in
// (i.e. exclude baseforecast valid time as that gets updated when doing an amendment automatically outside of the users influence)
export const isTafAmendedCorrectedChanged = (
  originalTaf: Taf,
  currentTaf: Taf,
): boolean => {
  if (originalTaf === null || currentTaf === null) {
    return false;
  }
  const { baseForecast: originalBaseF, changeGroups: originalChG } =
    originalTaf;
  const { baseForecast: currentBaseF, changeGroups: currentChG } = currentTaf;

  const { valid: validOriginal, ...originalNoValid } = originalBaseF;
  const { valid: validCurrent, ...currentNoValid } = currentBaseF;

  return (
    !isEqual(originalNoValid, currentNoValid) ||
    !isEqual(originalChG, currentChG)
  );
};

export const getConfirmationDialogContent = (
  action: TafActions,
  isFormDirty: () => boolean,
  t: TFunction,
): string => {
  switch (action) {
    case 'CLEAR':
      return t('confirm-clear-content');
    case 'PUBLISH':
      return t('confirm-publish-content');
    case 'AMEND':
    case 'CORRECT':
      return isFormDirty()
        ? t('confirm-correct-amend-content')
        : t('confirm-correct-amend-content-no-changes');
    case 'CANCEL':
    default:
      return t('confirm-cancel-content');
  }
};

export const getConfirmationDialogButtonLabel = (
  action: TafActions,
  t: TFunction,
): string => {
  switch (action) {
    case 'CLEAR': {
      return t('button-clear');
    }
    case 'CANCEL':
      return t('button-cancel');
    default: {
      return t('button-publish');
    }
  }
};

export const emptyChangegroup = {
  valid: '',
  wind: '',
  visibility: '',
  weather: {
    weather1: '',
    weather2: '',
    weather3: '',
  },
  cloud: {
    cloud1: '',
    cloud2: '',
    cloud3: '',
    cloud4: '',
  },
  change: '',
  probability: '',
};

export const getTafStatusLabel = (
  t: TFunction,
  status: TafStatus,
  action?: TafActions,
  isFormDisabled?: boolean,
): string => {
  // See if we need to base status label on action if form is not disabled and action passed
  const baseOnAction =
    isFormDisabled !== undefined && !isFormDisabled && action;
  if (
    baseOnAction &&
    action === 'AMEND' &&
    (status === 'PUBLISHED' || status === 'AMENDED' || status === 'CORRECTED')
  ) {
    return t('status-new-amendment');
  }
  if (
    baseOnAction &&
    action === 'CORRECT' &&
    (status === 'PUBLISHED' || status === 'AMENDED' || status === 'CORRECTED')
  ) {
    return t('status-new-correction');
  }

  switch (status) {
    case 'DRAFT_AMENDED':
      return t('status-draft-amendment');
    case 'DRAFT_CORRECTED':
      return t('status-draft-correction');
    case 'AMENDED':
      return t('status-published-amendment');
    case 'CORRECTED':
      return t('status-published-correction');
    default:
      return t(`status-${status.toLocaleLowerCase()}`);
  }
};

export const getMessageType = (taf: Taf, tafAction: TafActions): string => {
  if (tafAction === 'CORRECT') {
    return 'COR';
  }
  if (tafAction === 'AMEND') {
    return 'AMD';
  }
  if (tafAction === 'CANCEL') {
    return 'CNL';
  }

  return taf.messageType;
};

// For corrections and amendment we need to add the previousValidStart and End for the TAC converter
export const getAmendedCorrectedTafTimes = (
  taf: Taf,
  tafAction: TafActions,
): Taf => {
  const previousValidDateStart =
    taf.previousValidDateStart && tafAction !== 'AMEND'
      ? taf.previousValidDateStart
      : taf.validDateStart;
  const previousValidDateEnd =
    taf.previousValidDateEnd && tafAction !== 'AMEND'
      ? taf.previousValidDateEnd
      : taf.validDateEnd;

  // For amendment change valid start time to current time
  // only do this if we're already within the validity period
  const now = dateUtils.utc();
  const validDateStart = dateUtils.utc(taf.validDateStart);

  if (
    (tafAction === 'AMEND' || tafAction === 'DRAFT_AMEND') &&
    now.getTime() >= validDateStart.getTime()
  ) {
    const now = dateUtils.dateToString(
      dateUtils.utc(),
      dateUtils.DATE_FORMAT_UTC,
    )!;

    const validObj = { start: now, end: taf.baseForecast.valid.end };
    return {
      ...taf,
      previousValidDateStart,
      previousValidDateEnd,
      validDateStart: now,
      baseForecast: { ...taf.baseForecast, valid: validObj },
    };
  }
  return {
    ...taf,
    previousValidDateStart,
    previousValidDateEnd,
  };
};

export const createChangeGroup = (
  changeGroup: ChangeGroupFormData,
): ChangeGroupFormData => ({
  ...emptyChangegroup,
  ...changeGroup,
  weather: {
    ...emptyChangegroup.weather,
    ...changeGroup.weather,
  },
  cloud: {
    ...emptyChangegroup.cloud,
    ...changeGroup.cloud,
  },
});

export const prepareTafValues = (
  _taf: Taf,
  tafAction: TafActions,
  addEmptyChangeGroups = false,
): TafFormData => {
  // If action is AMEND/CORRECT/DRAFT_AMEND/DRAFT_CORRECTION - add previous valid start and end
  // If action is AMEND/DRAFT_AMEND - update validity start time to current time
  const taf =
    tafAction === 'AMEND' ||
    tafAction === 'DRAFT_AMEND' ||
    tafAction === 'CORRECT' ||
    tafAction === 'DRAFT_CORRECT'
      ? getAmendedCorrectedTafTimes(_taf, tafAction)
      : _taf;

  const formattedBaseForecast = formatFormValues(taf.baseForecast);
  const baseForecast = {
    ...emptyBaseForecast,
    ...formattedBaseForecast,
    weather: {
      ...emptyBaseForecast.weather,
      ...formattedBaseForecast.weather,
    },
    cloud: {
      ...emptyBaseForecast.cloud,
      ...formattedBaseForecast.cloud,
    },
  };

  const formattedChangeGroups = taf.changeGroups?.map(formatFormValues) || [];
  const changeGroups = formattedChangeGroups.map(createChangeGroup);
  // If addEmptyChangeGroups is true - ensure there are at least MIN_NUMBER_CHANGE_GROUP changegroups
  if (addEmptyChangeGroups) {
    const changeGroupsToAdd = MIN_NUMBER_CHANGE_GROUP - changeGroups.length;
    if (changeGroupsToAdd > 0) {
      Array.from(Array(changeGroupsToAdd)).map(() =>
        changeGroups.push(emptyChangegroup),
      );
    }
  }

  return {
    issueDate: '',
    previousId: null!,
    previousValidDateStart: null!,
    previousValidDateEnd: null!,
    ...taf,
    messageType: getMessageType(taf, tafAction) as MessageType,
    baseForecast,
    changeGroups,
    // default draft true
    [HIDDEN_INPUT_HELPER_IS_DRAFT]: true,
  };
};

export const clearTafFormData = (tafFormValues: TafFormData): TafFormData => {
  // Remove all except for the validity times
  const baseForecast = {
    valid: tafFormValues.baseForecast.valid,
  };

  const emptyChangeGroups: ChangeGroupFormData[] = [];
  Array.from(Array(MIN_NUMBER_CHANGE_GROUP)).map(() =>
    emptyChangeGroups.push(emptyChangegroup),
  );

  return {
    ...tafFormValues,
    baseForecast: {
      ...emptyBaseForecast,
      ...baseForecast,
    },
    // Add empty change groups
    changeGroups: emptyChangeGroups,
  };
};

export const getIsFormDefaultDisabled = (status: TafStatus): boolean => {
  return !(
    status === 'NEW' ||
    status === 'DRAFT' ||
    status === 'DRAFT_AMENDED' ||
    status === 'DRAFT_CORRECTED'
  );
};

export const fromStatusToAction = (tafStatus: TafStatus): TafActions => {
  switch (tafStatus) {
    case 'DRAFT':
      return 'DRAFT';
    case 'DRAFT_AMENDED':
      return 'DRAFT_AMEND';
    case 'DRAFT_CORRECTED':
      return 'DRAFT_CORRECT';
    case 'PUBLISHED':
      return 'PUBLISH';
    case 'AMENDED':
      return 'AMEND';
    case 'CORRECTED':
      return 'CORRECT';
    case 'CANCELLED':
      return 'CANCEL';
    default:
      return 'NEW';
  }
};

export const getFormRowPosition = (formRow: Element): IssuesPanePosition => {
  const { top, left, height } = formRow.getBoundingClientRect();
  const newTop = top + height;
  return { top: newTop, left };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { DragHandle as DragHandleIcon } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';

export interface DragHandleProps {
  isSorting?: boolean;
  isDisabled?: boolean;
  index: number;
}

const DragHandle: React.FC<DragHandleProps> = ({
  isSorting = false,
  isDisabled = false,
  index,
}: DragHandleProps) => {
  return (
    <CustomIconButton
      className="handle"
      data-testid={`dragHandle-${index}`}
      disableRipple
      disabled={isDisabled}
      sx={{
        cursor: isSorting ? 'grabbing' : 'grab',
        '&.MuiButtonBase-root.MuiIconButton-root': {
          width: 50,
          height: 50,
          color: 'geowebColors.greys.accessible',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <DragHandleIcon />
    </CustomIconButton>
  );
};

export default DragHandle;

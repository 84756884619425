/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { SearchHighlight } from '@opengeoweb/shared';
import { layerTypes, serviceTypes } from '@opengeoweb/store';
import { LayerInfoButton, getDimensionsList } from '@opengeoweb/webmap-react';
import { LayerProps } from '@opengeoweb/webmap';
import {
  DeleteLayerParams,
  LayerAddRemoveButton,
} from './LayerAddRemoveButton';
import { LayerListDimensions } from './LayerListDimensions';
import {
  widthToColumns,
  widthToRowHeight,
} from '../LayerSelect/LayerSelectUtils';
import { useLayerSelectTranslation } from '../../utils/i18n';

interface AddLayerParams {
  serviceUrl: string;
  layerName: string;
}

interface LayerListRowProps {
  layer: LayerProps;
  service: serviceTypes.ReduxService;
  addLayer: ({ serviceUrl, layerName }: AddLayerParams) => void;
  deleteLayer: ({ layerId, layerIndex }: DeleteLayerParams) => void;
  mapLayers: layerTypes.ReduxLayer[];
  layerSelectWidth: number;
  searchFilter: string;
  hasActiveLayer: boolean;
  onToggleActiveLayer: () => void;
}

const layerInfoStyle = {
  fontSize: '10px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  lineHeight: 1,
};

export const LayerListRow: React.FC<LayerListRowProps> = ({
  layer,
  service,
  addLayer,
  deleteLayer,
  mapLayers,
  layerSelectWidth,
  searchFilter,
  hasActiveLayer = false,
  onToggleActiveLayer,
}) => {
  const { t } = useLayerSelectTranslation();
  const rowHeight = widthToRowHeight(layerSelectWidth);
  const columns = widthToColumns(layerSelectWidth);
  const dimensions = getDimensionsList(layer.dimensions!);

  return (
    <Grid
      data-testid="layerListLayerRow"
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: 'geowebColors.cards.cardContainer',
        padding: '8px',
        marginBottom: '4px',
        height: rowHeight,
        border: 'solid 1px',
        borderColor: 'geowebColors.cards.cardContainerBorder',
      }}
    >
      <Grid container sx={columns.column8}>
        <Grid size={{ xs: 12 }}>
          <Box
            sx={{
              fontSize: '12px',
              height: '14px',
              lineHeight: '14px',
              fontWeight: 500,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <SearchHighlight text={layer.title!} search={searchFilter} />
          </Box>
        </Grid>
        <Grid container direction="column" sx={columns.column1}>
          <Box
            sx={{
              fontSize: '12px',
              height: '18px',
              lineHeight: '18px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            <SearchHighlight text={service.name!} search={searchFilter} />
          </Box>
          <LayerInfoButton
            isActive={hasActiveLayer}
            onClick={onToggleActiveLayer}
          />
        </Grid>
        <Grid sx={columns.column2}>
          <Grid sx={columns.column3}>
            {layer.abstract && (
              <Typography data-testid="layerAbstract" sx={layerInfoStyle}>
                <SearchHighlight text={layer.abstract} search={searchFilter} />
              </Typography>
            )}
          </Grid>
          <Grid container sx={columns.column4}>
            <Grid sx={columns.column5}>
              <LayerListDimensions dimensions={dimensions} />
            </Grid>
            <Grid sx={columns.column6}>
              {layer.path.length > 0 && (
                <Typography sx={layerInfoStyle}>
                  {t('groups')}:{' '}
                  <SearchHighlight
                    text={layer.path.join(' / ')}
                    search={searchFilter}
                  />
                </Typography>
              )}
              {layer.keywords?.length! > 0 && (
                <Typography sx={layerInfoStyle}>
                  {t('keywords')}:{' '}
                  <SearchHighlight
                    text={layer
                      .keywords!.slice()
                      .sort((a, b) => {
                        return a.localeCompare(b);
                      })
                      .join(', ')}
                    search={searchFilter}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={columns.column9}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box sx={columns.column7}>
          <LayerAddRemoveButton
            layer={layer as unknown as layerTypes.ReduxLayer}
            serviceUrl={service.serviceUrl!}
            addLayer={addLayer}
            deleteLayer={deleteLayer}
            mapLayers={mapLayers}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

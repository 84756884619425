/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  CustomIconButton,
  CustomTooltip,
  FilterChip,
  FilterOption,
} from '@opengeoweb/shared';
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Menu,
  Switch,
  Typography,
} from '@mui/material';
import { Close, Options } from '@opengeoweb/theme';
import { useState } from 'react';
import { PickedFilterState, OptionKey, UpdateFilter } from './filter-hooks';
import { useWarningsTranslation } from '../../utils/i18n';
import { warningColors } from './filter-styles';
import { getLevelStyle, LevelColors } from '../PublicWarningsForm/LevelField';
import { getTranslationKey } from '../../utils/warningUtils';
import { getPhenomenaIcon } from '../PublicWarningsForm/PhenomenonField';
import { emptyFilterId, getSelectedOptions } from './filter-utils';

type IdType = keyof PickedFilterState;
export interface ExpandableFilterChipProps {
  id: IdType;
  options: PickedFilterState[IdType];
  updateFilter: UpdateFilter;
}

const ExpandableFilterChip: React.FC<ExpandableFilterChipProps> = ({
  id,
  options,
  updateFilter,
}: ExpandableFilterChipProps) => {
  const { t } = useWarningsTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { noSelectedOptions, allSelected } = getSelectedOptions(options);
  const isDisabled = Object.keys(options).length < 2;

  return (
    <>
      <CustomTooltip
        title={isDisabled ? t('filter-no-results') : t('filter-options')}
        placement="bottom"
      >
        <span style={{ margin: 0 }}>
          <FilterChip
            id={id}
            label={t(`filter-${id}`)}
            isSelected={!isDisabled && noSelectedOptions > 0}
            onClick={(ref: HTMLElement | null) => setAnchorEl(ref)}
            isDisabled={isDisabled}
            size="medium"
            actionIcon={
              <Grid
                container
                alignItems="center"
                style={{
                  marginRight: '8px',
                  minWidth: '36px',
                }}
              >
                <Options />
                <Typography variant="body2" sx={{ paddingLeft: 0.5 }}>
                  {!isDisabled && noSelectedOptions > 0 && noSelectedOptions}
                </Typography>
              </Grid>
            }
          />
        </span>
      </CustomTooltip>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        BackdropProps={{
          style: {
            opacity: 0,
            transition: 'none',
          },
        }}
        sx={{ padding: 3 }}
      >
        <DialogTitle
          id="dialog-title"
          sx={{
            zIndex: 1,
            padding: '8px 18px 16px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 500,
              paddingRight: 1,
            }}
            data-testid="customDialog-title"
            component="span"
          >
            {t(`filter-${id}`)}
          </Typography>
          <CustomIconButton
            aria-label={t('close-dialog-confirm')}
            onClick={() => setAnchorEl(null)}
            sx={{
              '&.MuiIconButton-root': {
                color: 'geowebColors.greys.accessible',
              },
            }}
          >
            <Close />
          </CustomIconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '0px 24px 16px 24px',
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            {t('filter-select-all')}
            <Switch
              inputProps={{
                'aria-label': t('filter-select-all'),
              }}
              checked={allSelected}
              onChange={() => updateFilter(id, 'ALL', !allSelected)}
              sx={{ marginRight: '-12px', marginTop: '-6px' }}
            />
          </Grid>
          <Box>
            {options[emptyFilterId as OptionKey<keyof PickedFilterState>] !==
              undefined && (
              <FilterOption
                key={`${id}-${emptyFilterId}`}
                optionId={emptyFilterId}
                checked={
                  options[emptyFilterId as OptionKey<keyof PickedFilterState>]
                }
                onClick={(checked, only) =>
                  updateFilter(
                    id,
                    emptyFilterId as OptionKey<keyof PickedFilterState>,
                    checked,
                    only,
                  )
                }
                labelStyle={{
                  fontSize: '16px',
                }}
                label={
                  <>
                    {t(
                      getTranslationKey(
                        emptyFilterId as OptionKey<keyof PickedFilterState>,
                        id,
                      ),
                    )}
                  </>
                }
              />
            )}
            {Object.keys(options).map((optionId) => {
              if (optionId === emptyFilterId) {
                return null;
              }
              const warningColor = warningColors[optionId as string];
              return (
                <FilterOption
                  key={`${id}-${optionId}`}
                  optionId={optionId}
                  checked={
                    options[optionId as OptionKey<keyof PickedFilterState>]
                  }
                  onClick={(checked, only) =>
                    updateFilter(
                      id,
                      optionId as OptionKey<keyof PickedFilterState>,
                      checked,
                      only,
                    )
                  }
                  labelStyle={{
                    ...getLevelStyle(warningColor),
                    width: LevelColors[
                      optionId as OptionKey<keyof PickedFilterState>
                    ]
                      ? 'fit-content'
                      : '100%',
                    fontSize: '16px',
                  }}
                  label={
                    <CustomTooltip
                      title={t(
                        getTranslationKey(
                          optionId as OptionKey<keyof PickedFilterState>,
                          id,
                        ),
                      )}
                      placement="bottom"
                    >
                      <Box
                        data-testid={`wrapper-${optionId}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          maxWidth: 200,
                        }}
                      >
                        {getPhenomenaIcon(optionId)}

                        <Typography
                          noWrap
                          variant="body2"
                          sx={{
                            fontSize: '16px',
                          }}
                        >
                          {t(
                            getTranslationKey(
                              optionId as OptionKey<keyof PickedFilterState>,
                              id,
                            ),
                          )}
                        </Typography>
                      </Box>
                    </CustomTooltip>
                  }
                />
              );
            })}
          </Box>
        </DialogContent>
      </Menu>
    </>
  );
};

export default ExpandableFilterChip;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { mapSelectors, serviceActions, serviceTypes } from '@opengeoweb/store';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { clearImageCacheForAllMaps } from '@opengeoweb/webmap';
import { ServiceOptionsDialog, ServicePopupInfo } from './ServiceOptionsDialog';
import { loadWMSService } from '../ServicePopup/utils';
import {
  layerSelectTypes,
  layerSelectActions,
  layerSelectSelectors,
  useAppSelector,
} from '../../store';

interface ServiceOptionsDialogConnectProps {
  mapId?: string;
}

const getService = (
  services: layerSelectTypes.ActiveServiceObjectEntities,
  serviceUrl: string,
): layerSelectTypes.ActiveServiceObject | null => {
  const serviceIds = Object.keys(services);
  const serviceIndex = serviceIds.findIndex(
    (serviceId) => services[serviceId]?.serviceUrl === serviceUrl,
  );
  if (serviceIndex < 0) {
    return null;
  }
  return services[serviceIds[serviceIndex]];
};

export const getServiceSuccesUpdateMessage = (
  serviceName: string,
): snackbarTypes.TranslatableMessage => ({
  type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
  key: 'service-successfully-updated',
  params: {
    serviceName,
  },
});

export const getServiceFailedUpdateMessage = (
  error: Error,
): snackbarTypes.TranslatableMessage => {
  const { message } = error;
  return {
    type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
    key: 'unable-to-update-service',
    params: {
      message,
    },
  };
};

export const ServiceOptionsDialogConnect: React.FC<
  ServiceOptionsDialogConnectProps
> = ({ mapId }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();

  const serviceSetLayers = React.useCallback(
    (payload: serviceTypes.SetLayersForServicePayload): void => {
      dispatch(
        serviceActions.serviceSetLayers({ ...payload, isUpdating: true }),
      );
    },
    [dispatch],
  );

  const setServicePopupInfo = React.useCallback(
    (popupInfo: ServicePopupInfo): void => {
      const payload: layerSelectTypes.ToggleServicePopupPayload = {
        ...popupInfo,
        variant: popupInfo.variant! || 'default',
      };
      dispatch(layerSelectActions.toggleServicePopup(payload));
    },
    [dispatch],
  );

  const showSnackbar = React.useCallback(
    (message: snackbarTypes.TranslatableMessage) => {
      dispatch(snackbarActions.openSnackbar(message));
    },
    [dispatch],
  );

  const services = useAppSelector((store) =>
    layerSelectSelectors.getActiveServices(store),
  );

  const selectedLayers = useAppSelector((store) =>
    mapSelectors.getMapLayersWithoutDimensionCurrentValue(store, mapId!),
  );

  const layerSelectRemoveService = React.useCallback(
    (serviceId: string, serviceUrl: string, serviceName: string) => {
      const message: snackbarTypes.TranslatableMessage = {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key: 'service-has-been-deleted',
        params: {
          serviceName,
        },
      };
      showSnackbar(message);
      dispatch(
        layerSelectActions.layerSelectRemoveService({
          serviceId,
          serviceUrl,
        }),
      );
    },
    [dispatch, showSnackbar],
  );

  const layerSelectReloadService = React.useCallback(
    async (serviceUrl: string) => {
      setIsLoading(true);
      try {
        const layersForService = await loadWMSService(serviceUrl, true);

        const service = getService(services, serviceUrl);

        serviceSetLayers({
          id: layersForService.id,
          name: (service && service.serviceName) || layersForService.name,
          serviceUrl,
          abstract: (service && service.abstract) || layersForService.abstract,
          layers: layersForService.layers,
          scope: (service && service.scope) || 'user',
          isUpdating: true,
        });
        clearImageCacheForAllMaps();
        const serviceTitle =
          (service && service.serviceName) || layersForService.name;
        showSnackbar(getServiceSuccesUpdateMessage(serviceTitle));
      } catch (error) {
        // TODO: Maarten Plieger 2022-11-02: Snackbars should not be used to inform the user that something went wrong.
        // https://gitlab.com/opengeoweb/opengeoweb/-/issues/2819
        showSnackbar(getServiceFailedUpdateMessage(error as Error));
      } finally {
        setIsLoading(false);
      }
    },
    [serviceSetLayers, services, showSnackbar],
  );

  return (
    <ServiceOptionsDialog
      selectedLayers={selectedLayers}
      services={services}
      layerSelectRemoveService={layerSelectRemoveService}
      layerSelectReloadService={layerSelectReloadService}
      setServicePopupInfo={setServicePopupInfo}
      isLoading={isLoading}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { AxiosError } from 'axios';

interface ResponseData {
  message?: string;
  WarningMessage?: string;
}

export const DEFAULT_ERROR_MESSAGE = 'An error has occurred';

// Guard function for detecting an AxiosError (typically the kind of error returned by the backend)
export const isAxiosError = (
  error: AxiosError | Error,
): error is AxiosError => {
  return (error as AxiosError).isAxiosError !== undefined;
};

export const isAxiosAuthError = (error: AxiosError | Error): boolean => {
  if (isAxiosError(error)) {
    return error.response?.status === 401 || error.response?.status === 403;
  }
  return false;
};

export const getAxiosErrorMessage = (error: AxiosError): string => {
  const data = error.response && (error.response.data as ResponseData);
  if (data && typeof data === 'string') {
    return data;
  }
  if (data && data.message && typeof data.message === 'string') {
    return data.message;
  }
  if (error.message) {
    return error.message;
  }

  return DEFAULT_ERROR_MESSAGE;
};

export const getErrorMessage = (error: Error | AxiosError): string => {
  if (isAxiosError(error)) {
    return getAxiosErrorMessage(error);
  }
  return error.message || DEFAULT_ERROR_MESSAGE;
};

export const getAxiosWarningMessage = (error: AxiosError): string => {
  const data = error.response && (error.response.data as ResponseData);
  if (data && data.WarningMessage && typeof data.WarningMessage === 'string') {
    return data.WarningMessage;
  }
  return '';
};

export const getWarningMessage = (error: AxiosError | Error): string => {
  if (isAxiosError(error)) {
    return getAxiosWarningMessage(error);
  }

  return '';
};

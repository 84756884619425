/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { DrawFIRLand } from '@opengeoweb/theme';

import { DRAWMODE } from '../MapDraw/MapDraw';
import {
  intersectionFeatureBE,
  intersectionFeatureNL,
} from '../MapDraw/storyComponents';
import { DrawMode, SelectionType } from './types';
import {
  MapDrawToolOptions,
  defaultBox,
  defaultDelete,
  defaultPoint,
  defaultPolygon,
  getIcon,
} from './useMapDrawTool';

export const getIntersectionToolIcon = (
  selectionType: SelectionType,
): React.ReactElement => {
  const defaultIcon = getIcon(selectionType);
  if (defaultIcon) {
    return defaultIcon;
  }
  if (selectionType === shapeButtonNL.selectionType) {
    return <DrawFIRLand />;
  }
  if (selectionType === shapeButtonBE.selectionType) {
    return <DrawFIRLand sx={{ transform: `scaleY(-1)` }} />;
  }
  return <DrawFIRLand />;
};

// custom buttons
const shapeButtonNL: DrawMode = {
  drawModeId: 'tool-fir',
  value: DRAWMODE.POLYGON,
  title: 'Custom FIR NL polygon',
  shape: intersectionFeatureNL,
  isSelectable: false,
  selectionType: 'fir-NL',
};

const shapeButtonBE: DrawMode = {
  drawModeId: 'fir-be',
  value: DRAWMODE.POLYGON,
  title: 'Custom FIR BE polygon',
  shape: intersectionFeatureBE,
  isSelectable: false,
  selectionType: 'fir-BE',
};
const geoJSONIntersectionBoundsStyle = {
  stroke: '#000000',
  'stroke-width': 1.5,
  'stroke-opacity': 1,
  fill: '#0075a9',
  'fill-opacity': 0.0,
};

const featurePropsIntersectionEnd = {
  stroke: '#000000',
  'stroke-width': 1.5,
  'stroke-opacity': 1,
  fill: '#6e1e91',
  'fill-opacity': 0.5,
};

const intersectionShapeNL: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      ...intersectionFeatureNL,
      properties: geoJSONIntersectionBoundsStyle,
    },
  ],
};
const intersectionShapeBE: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      ...intersectionFeatureBE,
      properties: geoJSONIntersectionBoundsStyle,
    },
  ],
};

export const exampleIntersections = [
  { title: 'NL', geojson: intersectionShapeNL },
  { title: 'BE', geojson: intersectionShapeBE },
];

export const exampleIntersectionOptions: MapDrawToolOptions = {
  defaultGeoJSONIntersectionBounds: intersectionShapeNL,
  defaultDrawModes: [
    defaultPoint,
    defaultPolygon,
    defaultBox,
    shapeButtonNL,
    shapeButtonBE,
    defaultDelete,
  ],
};

export const exampleIntersectionWithShapeOptions: MapDrawToolOptions = {
  defaultGeoJSON: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          ...featurePropsIntersectionEnd,
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [5.711880814773656, 55.12656218004421],
              [7.663910745234145, 55.970965630799725],
              [10.075241835802988, 52.35182632872327],
              [6.673542618750517, 51.77828698943017],
              [4.506215269489237, 53.442721496909385],
              [5.711880814773656, 55.12656218004421],
            ],
          ],
        },
      },
    ],
  },
  defaultGeoJSONIntersection: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          ...featurePropsIntersectionEnd,
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [4.506215269489237, 53.442721496909385],
              [6.515104876543458, 51.89996184648691],
              [7.053095, 52.237764],
              [7.031389, 52.268885],
              [7.063612, 52.346109],
              [7.065557, 52.385828],
              [7.133055, 52.888887],
              [7.14218, 52.898244],
              [7.191667, 53.3],
              [6.5, 53.666667],
              [6.500002, 55.000002],
              [5.621260209964854, 55.00000082834584],
              [4.506215269489237, 53.442721496909385],
            ],
          ],
        },
      },
    ],
  },
  defaultGeoJSONIntersectionBounds: intersectionShapeNL,
  defaultDrawModes: [
    {
      ...defaultPoint,
      shape: {
        ...defaultPoint.shape,
        properties: {
          ...(defaultPoint.shape as GeoJSON.Feature).properties,
          ...featurePropsIntersectionEnd,
        },
      } as GeoJSON.Feature,
    },
    {
      ...defaultPolygon,
      shape: {
        ...defaultPolygon.shape,
        properties: {
          ...(defaultPolygon.shape as GeoJSON.Feature).properties,
          ...featurePropsIntersectionEnd,
        },
      } as GeoJSON.Feature,
    },
    {
      ...defaultBox,
      shape: {
        ...defaultBox.shape,
        properties: {
          ...(defaultBox.shape as GeoJSON.Feature).properties,
          ...featurePropsIntersectionEnd,
        },
      } as GeoJSON.Feature,
    },
    {
      ...shapeButtonNL,
      shape: {
        ...shapeButtonNL.shape,
        properties: {
          ...(shapeButtonNL.shape as GeoJSON.Feature).properties,
          ...featurePropsIntersectionEnd,
        },
      } as GeoJSON.Feature,
    },
    {
      ...shapeButtonBE,
      shape: {
        ...shapeButtonBE.shape,
        properties: {
          ...(shapeButtonBE.shape as GeoJSON.Feature).properties,
          ...featurePropsIntersectionEnd,
        },
      } as GeoJSON.Feature,
    },
    defaultDelete,
  ],
  defaultGeoJSONIntersectionProperties: featurePropsIntersectionEnd,
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { syncGroupsActions } from './reducer';
import { SynchronizationGroupModuleState } from './types';
import * as synchronizationGroupsSelector from './selectors';
import { SYNCGROUPS_TYPE_SETBBOX, SYNCGROUPS_TYPE_SETTIME } from './constants';
import { setBboxSync, setTimeSync } from '../synchronizationActions/actions';
import { SetBboxPayload, SetTimePayload } from '../types';
import { createSyncGroupViewStateSelector } from './selector';

export const syncGroupsListener =
  createListenerMiddleware<SynchronizationGroupModuleState>();

syncGroupsListener.startListening({
  matcher: isAnyOf(
    syncGroupsActions.syncGroupAddTarget,
    syncGroupsActions.syncGroupLinkTarget,
  ),
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { groupId, linked, targetId: targetToUpdate } = payload;

    const group = synchronizationGroupsSelector.getSynchronizationGroup(
      listenerApi.getState(),
      groupId,
    );

    if (!linked && group) {
      switch (group.type) {
        case SYNCGROUPS_TYPE_SETTIME:
          if (!group.payloadByType[SYNCGROUPS_TYPE_SETTIME]) {
            return;
          }
          listenerApi.dispatch(
            setTimeSync(
              group.payloadByType[SYNCGROUPS_TYPE_SETTIME] as SetTimePayload,
              [
                {
                  targetId: targetToUpdate,
                  value: (
                    group.payloadByType[
                      SYNCGROUPS_TYPE_SETTIME
                    ] as SetTimePayload
                  ).value,
                },
              ],
              [groupId],
            ),
          );
          break;
        case SYNCGROUPS_TYPE_SETBBOX:
          if (!group.payloadByType[SYNCGROUPS_TYPE_SETBBOX]) {
            return;
          }
          listenerApi.dispatch(
            setBboxSync(
              group.payloadByType[SYNCGROUPS_TYPE_SETBBOX] as SetBboxPayload,
              [
                {
                  targetId: targetToUpdate,
                  bbox: (
                    group.payloadByType[
                      SYNCGROUPS_TYPE_SETBBOX
                    ] as SetBboxPayload
                  ).bbox,
                  srs: (
                    group.payloadByType[
                      SYNCGROUPS_TYPE_SETBBOX
                    ] as SetBboxPayload
                  ).srs,
                },
              ],
              [groupId],
            ),
          );
          break;
        default:
          break;
      }
    }
  },
});

syncGroupsListener.startListening({
  matcher: isAnyOf(
    syncGroupsActions.syncGroupAddGroup,
    syncGroupsActions.syncGroupRemoveGroup,
    syncGroupsActions.syncGroupAddSource,
    syncGroupsActions.syncGroupRemoveSource,
    syncGroupsActions.syncGroupAddTarget,
    syncGroupsActions.syncGroupRemoveTarget,
    syncGroupsActions.syncGroupLinkTarget,
  ),
  effect: async (_, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const viewState = createSyncGroupViewStateSelector(listenerApi.getState());
    listenerApi.dispatch(
      syncGroupsActions.syncGroupSetViewState({ viewState }),
    );
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  Grid2 as Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormRadioGroup,
  ReactHookFormSelect,
  ReactHookFormNumberField,
  isValidMax,
  isInteger,
  isValidMin,
  isEmpty,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';

import { TFunction } from 'i18next';
import { styles } from '../ProductForm.styles';
import {
  LevelUnits,
  ConfigurableFormFieldProps,
  ProductConfig,
} from '../../../types';
import {
  getActiveFIRArea,
  getAllowedUnits,
  getMaxLevelValue,
  getMinLevelValue,
  isLevelLower,
  triggerValidations,
} from '../utils';
import { RadioButtonAndLabel } from './RadioButtonAndLabel';

import ProductFormFieldLayout from './ProductFormFieldLayout';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import {
  translateKeyOutsideComponents,
  useSigmetAirmetTranslation,
} from '../../../utils/i18n';

export const DEFAULT_ROUNDING_LEVELS_FL = 5;
export const DEFAULT_ROUNDING_LEVELS_FT = 500;
export const DEFAULT_ROUNDING_LEVELS_M = 1;

export const getLevelInvalidStepsForFLUnitMessage = (
  value = DEFAULT_ROUNDING_LEVELS_FL,
): string =>
  translateKeyOutsideComponents(
    'levels-get-level-invalid-steps-for-fl-unit-message',
    { value },
  );

export const getLevelInvalidStepsForFTUnitMessage = (
  value = DEFAULT_ROUNDING_LEVELS_FT,
): string =>
  translateKeyOutsideComponents(
    'levels-get-level-invalid-steps-for-ft-unit-message',
    { value },
  );

export const getLevelInvalidStepsForMUnitMessage = (
  value = DEFAULT_ROUNDING_LEVELS_M,
): string =>
  translateKeyOutsideComponents(
    'levels-get-level-invalid-steps-for-m-unit-message',
    { value },
  );

export const invalidUnitMessage = translateKeyOutsideComponents(
  'levels-invalid-unit-message',
);

export const validateLevels = (
  value: string,
  unit: string,
  productConfig: ProductConfig,
  selectedFIR: string,
): boolean | string => {
  if (isEmpty(value)) {
    return true;
  }

  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    level_rounding_FL = DEFAULT_ROUNDING_LEVELS_FL,
    level_rounding_FT = DEFAULT_ROUNDING_LEVELS_FT,
    level_rounding_M = DEFAULT_ROUNDING_LEVELS_M,
    /* eslint-enable @typescript-eslint/naming-convention */
  } = getActiveFIRArea(selectedFIR, productConfig);

  // Parse to integer to check for steps
  const intLevel = parseInt(value, 10);
  if (LevelUnits[unit as keyof typeof LevelUnits] === LevelUnits.FT) {
    return (
      intLevel % level_rounding_FT === 0 ||
      getLevelInvalidStepsForFTUnitMessage(level_rounding_FT)
    );
  }
  if (LevelUnits[unit as keyof typeof LevelUnits] === LevelUnits.FL) {
    return (
      intLevel % level_rounding_FL === 0 ||
      getLevelInvalidStepsForFLUnitMessage(level_rounding_FL)
    );
  }
  if (LevelUnits[unit as keyof typeof LevelUnits] === LevelUnits.M) {
    return (
      intLevel % level_rounding_M === 0 ||
      getLevelInvalidStepsForMUnitMessage(level_rounding_M)
    );
  }
  return invalidUnitMessage;
};

export const validateLevelUnitCombinations = (
  levelUnit: string,
  lowerLevelUnit: string,
  t: TFunction,
): boolean | string => {
  // If different units and lower level not SFC, only FL is allowed as upper level
  if (
    lowerLevelUnit !== 'SFC' &&
    levelUnit !== lowerLevelUnit &&
    LevelUnits[levelUnit as keyof typeof LevelUnits] !== LevelUnits.FL
  ) {
    return t('levels-invalid-level-unit-combination-message');
  }

  return true;
};

const Levels: React.FC<ConfigurableFormFieldProps> = ({
  isDisabled,
  isReadOnly,
  onChange = (): void => {},
  productConfig,
}: ConfigurableFormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { watch, setValue, trigger, getValues, clearErrors, unregister } =
    useFormContext();
  const { isRequired: isRequiredField } = useDraftFormHelpers();
  const { isShortTest } = useShortTestHelpers();
  const isRequired = (value: string): string | boolean =>
    isShortTest() || isRequiredField(value);

  const level = watch('level.value');
  const levelInfoMode = watch('levelInfoMode');
  const isRadioactiveCloud = watch('phenomenon') === 'RDOACT_CLD';
  const fieldsDisabled = isDisabled || isShortTest() || isRadioactiveCloud;

  const getFIRValue = (): string => getValues('locationIndicatorATSR');

  // Get allowed level units based on selected FIR - if no FIR selected, allow all default units
  const allowedLevelUnitsForFir = getAllowedUnits(
    getFIRValue(),
    productConfig,
    'level_unit',
    LevelUnits,
  );

  const radioactiveCloudLevel = getMaxLevelValue(
    LevelUnits.FL,
    getFIRValue(),
    productConfig,
  );

  React.useEffect(() => {
    if (levelInfoMode !== 'BETW') {
      unregister('lowerLevel');
    }
    if (isRadioactiveCloud) {
      setValue('level.unit', 'FL');
      setValue('level.value', radioactiveCloudLevel);
      triggerValidations(['level.value'], getValues, trigger);
    }
  }, [
    getValues,
    isRadioactiveCloud,
    levelInfoMode,
    radioactiveCloudLevel,
    setValue,
    trigger,
    unregister,
  ]);

  return (
    <ProductFormFieldLayout
      title={t('levels')}
      sx={{
        ...styles.containerItem,
        '.MuiFormHelperText-root.Mui-error': {
          marginBottom: '-16px',
        },
      }}
    >
      <ReactHookFormRadioGroup
        name="levelInfoMode"
        rules={{ validate: { isRequired } }}
        disabled={fieldsDisabled}
        isReadOnly={isReadOnly}
        onChange={(): void => {
          // Reset default value for the level value to '' so when it rerenders it uses this empty default value
          setValue('level.unit', 'FL');
          setValue('level.value', null);

          // Clear out errors for the levels
          clearErrors(['level', 'lowerLevel']);
        }}
      >
        {/** AT */}
        <RadioButtonAndLabel
          value="AT"
          label={t('at')}
          checked={levelInfoMode === 'AT' || levelInfoMode === 'TOPS'}
          data-testid="levels-AT"
          disabled={fieldsDisabled}
        />
        {(levelInfoMode === 'AT' || levelInfoMode === 'TOPS') &&
        !isRadioactiveCloud ? (
          <Grid
            container
            spacing={2}
            justifyItems="flex-end"
            alignItems="flex-start"
          >
            <Grid
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={levelInfoMode === 'TOPS'}
                    onChange={(): void => {
                      setValue(
                        'levelInfoMode',
                        getValues('levelInfoMode') === 'AT' ? 'TOPS' : 'AT',
                        { shouldDirty: true },
                      );
                    }}
                    color="secondary"
                    disabled={isDisabled}
                  />
                }
                data-testid="levels-TOPS"
                label={t('levels-tops-label')}
              />
            </Grid>
            <Grid
              size={{
                xs: 4,
                md: 3,
              }}
            >
              <ReactHookFormSelect
                name="level.unit"
                label={t('unit-label')}
                rules={{ validate: { isRequired } }}
                sx={styles.unit}
                size="small"
                disabled={isDisabled}
                isReadOnly={isReadOnly}
                defaultValue={'FL' as LevelUnits}
                onChange={(): void => {
                  triggerValidations(['level.value'], getValues, trigger);
                  onChange();
                }}
              >
                {Object.keys(allowedLevelUnitsForFir).map((key) => (
                  <MenuItem value={key} key={key}>
                    {allowedLevelUnitsForFir[key]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid
              size={{
                xs: 8,
                md: 6,
              }}
            >
              <ReactHookFormNumberField
                name="level.value"
                data-testid="level.value"
                label={t('level')}
                rules={{
                  validate: {
                    isRequired,
                    isInteger,
                    max: (value): boolean | string =>
                      // The max level depends on the unit
                      isValidMax(
                        value,
                        getMaxLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      ) ||
                      t('levels-the-maximum-level', {
                        levelUnit:
                          LevelUnits[
                            getValues('level.unit') as keyof typeof LevelUnits
                          ],
                        maxLevelValue: getMaxLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      }),
                    min: (value): boolean | string =>
                      // The min level depends on the unit
                      isValidMin(
                        value,
                        getMinLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      ) ||
                      t('levels-the-minimum-level', {
                        levelUnit:
                          LevelUnits[
                            getValues('level.unit') as keyof typeof LevelUnits
                          ],
                        minLevelValue: getMinLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      }),
                    validateLevels: (value): boolean | string =>
                      // level step depends on the unit
                      validateLevels(
                        value,
                        getValues('level.unit'),
                        productConfig,
                        getFIRValue(),
                      ),
                  },
                }}
                size="small"
                disabled={isDisabled}
                isReadOnly={isReadOnly}
                autoFocus
              />
            </Grid>
          </Grid>
        ) : null}

        {/** BETWEEN */}
        <RadioButtonAndLabel
          value="BETW"
          label={t('between')}
          checked={levelInfoMode === 'BETW' || levelInfoMode === 'BETW_SFC'}
          data-testid="levels-BETW"
          disabled={fieldsDisabled}
        />
        {levelInfoMode === 'BETW' || levelInfoMode === 'BETW_SFC' ? (
          <>
            <Grid container spacing={2}>
              <Grid
                size={{
                  xs: 12,
                  md: 3,
                }}
              />
              <Grid
                size={{
                  xs: 4,
                  md: 3,
                }}
              >
                <ReactHookFormSelect
                  name="level.unit"
                  label={t('unit-label')}
                  rules={{
                    validate: {
                      isRequired,
                      validateLevelUnitCombinations: (
                        value,
                      ): boolean | string =>
                        // level step depends on the unit
                        validateLevelUnitCombinations(
                          value,
                          levelInfoMode === 'BETW_SFC'
                            ? 'SFC'
                            : getValues('lowerLevel.unit'),
                          t,
                        ) === true,
                    },
                  }}
                  size="small"
                  sx={styles.unit}
                  disabled={fieldsDisabled}
                  isReadOnly={isReadOnly}
                  defaultValue={'FL' as LevelUnits}
                  onChange={(): void => {
                    triggerValidations(
                      ['level.value', 'lowerLevel.value', 'lowerLevel.unit'],
                      getValues,
                      trigger,
                    );
                    onChange();
                  }}
                >
                  {Object.keys(allowedLevelUnitsForFir).map((key) => (
                    <MenuItem value={key} key={key}>
                      {allowedLevelUnitsForFir[key]}
                    </MenuItem>
                  ))}
                </ReactHookFormSelect>
              </Grid>
              <Grid
                size={{
                  xs: 8,
                  md: 6,
                }}
              >
                <ReactHookFormNumberField
                  value={level || ''}
                  name="level.value"
                  label={t('levels-upper-level-label')}
                  sx={styles.levelField}
                  size="small"
                  rules={{
                    validate: {
                      isRequired,
                      isInteger,
                      max: (value): boolean | string =>
                        // The max level depends on the unit
                        isValidMax(
                          value,
                          getMaxLevelValue(
                            getValues('level.unit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        ) ||
                        t('levels-the-maximum-level', {
                          levelUnit:
                            LevelUnits[
                              getValues('level.unit') as keyof typeof LevelUnits
                            ],
                          maxLevelValue: getMaxLevelValue(
                            getValues('level.unit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        }),

                      min: (value): boolean | string =>
                        // The min level depends on the unit
                        isValidMin(
                          value,
                          getMinLevelValue(
                            getValues('level.unit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        ) ||
                        t('levels-the-minimum-level', {
                          levelUnit:
                            LevelUnits[
                              getValues('level.unit') as keyof typeof LevelUnits
                            ],
                          minLevelValue: getMinLevelValue(
                            getValues('level.unit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        }),
                      validateLevels: (value): boolean | string =>
                        // level step depends on the unit
                        validateLevels(
                          value,
                          getValues('level.unit'),
                          productConfig,
                          getFIRValue(),
                        ),
                    },
                  }}
                  disabled={fieldsDisabled}
                  isReadOnly={isReadOnly}
                  onChange={(): void => {
                    triggerValidations(
                      ['lowerLevel.value'],
                      getValues,
                      trigger,
                    );
                  }}
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyItems="flex-end"
              alignItems="flex-start"
            >
              <Grid
                size={{
                  xs: 12,
                  md: 3,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={levelInfoMode === 'BETW_SFC'}
                      onChange={(): void => {
                        getValues('level.unit') && trigger('level.unit');
                        setValue(
                          'levelInfoMode',
                          getValues('levelInfoMode') === 'BETW'
                            ? 'BETW_SFC'
                            : 'BETW',
                          { shouldDirty: true },
                        );
                      }}
                      name="checkedF"
                      color="secondary"
                      disabled={fieldsDisabled}
                    />
                  }
                  data-testid="levels-SFC"
                  label="SFC"
                />
              </Grid>
              {levelInfoMode !== 'BETW_SFC' && (
                <>
                  <Grid
                    size={{
                      xs: 4,
                      md: 3,
                    }}
                  >
                    <ReactHookFormSelect
                      name="lowerLevel.unit"
                      label={t('unit-label')}
                      rules={{
                        validate: {
                          isRequired,
                          validateLevelUnitCombinations: (
                            value,
                          ): boolean | string =>
                            // level step depends on the unit
                            validateLevelUnitCombinations(
                              getValues('level.unit'),
                              value,
                              t,
                            ),
                        },
                      }}
                      size="small"
                      sx={styles.unit}
                      disabled={isDisabled}
                      isReadOnly={isReadOnly}
                      defaultValue={
                        getValues('level.unit') || ('FL' as LevelUnits)
                      }
                      onChange={(): void => {
                        triggerValidations(
                          ['lowerLevel.value', 'level.unit'],
                          getValues,
                          trigger,
                        );
                        onChange();
                      }}
                    >
                      {Object.keys(allowedLevelUnitsForFir).map((key) => (
                        <MenuItem value={key} key={key}>
                          {allowedLevelUnitsForFir[key]}
                        </MenuItem>
                      ))}
                    </ReactHookFormSelect>
                  </Grid>
                  <Grid
                    size={{
                      xs: 8,
                      md: 6,
                    }}
                  >
                    <ReactHookFormNumberField
                      name="lowerLevel.value"
                      label={t('levels-lower-level-label')}
                      rules={{
                        validate: {
                          isRequired,
                          isInteger,
                          max: (value): boolean | string =>
                            // The max level depends on the unit
                            isValidMax(
                              value,
                              getMaxLevelValue(
                                getValues('lowerLevel.unit'),
                                getFIRValue(),
                                productConfig,
                              ),
                            ) ||
                            t('levels-the-maximum-level', {
                              levelUnit:
                                LevelUnits[
                                  getValues(
                                    'lowerLevel.unit',
                                  ) as keyof typeof LevelUnits
                                ],
                              maxLevelValue: getMaxLevelValue(
                                getValues('lowerLevel.unit'),
                                getFIRValue(),
                                productConfig,
                              ),
                            }),
                          min: (value): boolean | string =>
                            // The min level depends on the unit
                            isValidMin(
                              value,
                              getMinLevelValue(
                                getValues('lowerLevel.unit'),
                                getFIRValue(),
                                productConfig,
                              ),
                            ) ||
                            t('levels-the-minimum-level', {
                              levelUnit:
                                LevelUnits[
                                  getValues(
                                    'lowerLevel.unit',
                                  ) as keyof typeof LevelUnits
                                ],
                              minLevelValue: getMinLevelValue(
                                getValues('lowerLevel.unit'),
                                getFIRValue(),
                                productConfig,
                              ),
                            }),
                          isLevelLower: (value): boolean | string =>
                            // The lower level needs to be smaller than upper level
                            isLevelLower(
                              value,
                              getValues('lowerLevel.unit'),
                              getValues('level.value'),
                              getValues('level.unit'),
                            ),

                          validateLevels: (value): boolean | string =>
                            // level step depends on the unit
                            validateLevels(
                              value,
                              getValues('lowerLevel.unit'),
                              productConfig,
                              getFIRValue(),
                            ),
                        },
                      }}
                      size="small"
                      disabled={isDisabled}
                      isReadOnly={isReadOnly}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        ) : null}

        {/** ABOVE */}
        <RadioButtonAndLabel
          value="ABV"
          label={t('above')}
          disabled={fieldsDisabled}
          checked={levelInfoMode === 'ABV' || levelInfoMode === 'TOPS_ABV'}
          data-testid="levels-ABV"
        />
        {levelInfoMode === 'ABV' || levelInfoMode === 'TOPS_ABV' ? (
          <Grid
            container
            spacing={2}
            justifyItems="flex-end"
            alignItems="flex-start"
          >
            <Grid
              size={{
                xs: 12,
                md: 3,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={levelInfoMode === 'TOPS_ABV'}
                    onChange={(): void => {
                      setValue(
                        'levelInfoMode',
                        getValues('levelInfoMode') === 'ABV'
                          ? 'TOPS_ABV'
                          : 'ABV',
                        { shouldDirty: true },
                      );
                    }}
                    disabled={isDisabled}
                  />
                }
                label={t('levels-tops-label')}
                data-testid="levels-TOPS_ABV"
              />
            </Grid>
            <Grid
              size={{
                xs: 4,
                md: 3,
              }}
            >
              <ReactHookFormSelect
                name="level.unit"
                label={t('unit-label')}
                rules={{ validate: { isRequired } }}
                size="small"
                sx={styles.unit}
                disabled={isDisabled}
                isReadOnly={isReadOnly}
                defaultValue={'FL' as LevelUnits}
                onChange={(): void => {
                  triggerValidations(['level.value'], getValues, trigger);
                  onChange();
                }}
              >
                {Object.keys(allowedLevelUnitsForFir).map((key) => (
                  <MenuItem value={key} key={key}>
                    {allowedLevelUnitsForFir[key]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid
              size={{
                xs: 8,
                md: 6,
              }}
            >
              <ReactHookFormNumberField
                name="level.value"
                label={t('level')}
                rules={{
                  validate: {
                    isRequired,
                    isInteger,
                    max: (value): boolean | string =>
                      // The max level depends on the unit
                      isValidMax(
                        value,
                        getMaxLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      ) ||
                      t('levels-the-maximum-level', {
                        levelUnit:
                          LevelUnits[
                            getValues('level.unit') as keyof typeof LevelUnits
                          ],
                        maxLevelValue: getMaxLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      }),
                    min: (value): boolean | string =>
                      // The min level depends on the unit
                      isValidMin(
                        value,
                        getMinLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      ) ||
                      t('levels-the-minimum-level', {
                        levelUnit:
                          LevelUnits[
                            getValues('level.unit') as keyof typeof LevelUnits
                          ],
                        minLevelValue: getMinLevelValue(
                          getValues('level.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      }),
                    validateLevels: (value): boolean | string =>
                      // level step depends on the unit
                      validateLevels(
                        value,
                        getValues('level.unit'),
                        productConfig,
                        getFIRValue(),
                      ),
                  },
                }}
                size="small"
                disabled={isDisabled}
                isReadOnly={isReadOnly}
                autoFocus
              />
            </Grid>
          </Grid>
        ) : null}
      </ReactHookFormRadioGroup>
    </ProductFormFieldLayout>
  );
};

export default Levels;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { AlertBanner } from '@opengeoweb/shared';
import { useApiContext, useApi } from '@opengeoweb/api';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { TimeSeriesConnect } from './TimeSeriesConnect';
import { TimeSeriesApi } from '../../utils/api';
import { TimeSeriesStoreType } from '../../store/types';
import { timeSeriesActions } from '../../store/reducer';
import { getUserAddedEDRServices } from '../../utils/localStorage';

interface Props {
  panelId: string;
  timeSeriesPreset: TimeSeriesStoreType;
  productConfigKey: string;
}

export const ERROR_TITLE =
  'Something went wrong with loading the configuration file';

export const TimeSeriesConnectWrapper: React.FC<Props> = ({
  panelId,
  timeSeriesPreset,
  productConfigKey,
}) => {
  const { api } = useApiContext<TimeSeriesApi>();

  const {
    result: timeSeriesPresetLocations,
    isLoading: isConfigLoading,
    error,
  } = useApi(api.getTimeSeriesConfiguration, productConfigKey);

  const dispatch = useDispatch();

  // The preset should only be set once the component is mounted or when it has changed.
  const timeSeriesPresetRef = React.useRef<TimeSeriesStoreType | undefined>();

  // Load services from localstorage before timeseriespresets are loaded as after that preset registering action can change ids accordingly
  React.useEffect(() => {
    const localStorageServices = getUserAddedEDRServices();
    dispatch(
      timeSeriesActions.loadUserAddedTimeSeriesServices(localStorageServices),
    );
  }, [dispatch, timeSeriesPreset, timeSeriesPresetRef]);

  React.useEffect(() => {
    // Check if the preset has changed
    if (!isEqual(timeSeriesPreset, timeSeriesPresetRef.current)) {
      timeSeriesPresetRef.current = timeSeriesPreset;
      timeSeriesPreset &&
        dispatch(timeSeriesActions.registerTimeSeriesPreset(timeSeriesPreset));
    }
  }, [dispatch, timeSeriesPreset, timeSeriesPresetRef]);

  if (isConfigLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress data-testid="loadingSpinner" />
      </Box>
    );
  }

  if (error) {
    return <AlertBanner title={ERROR_TITLE} info={error.message} />;
  }

  return (
    <TimeSeriesConnect
      timeSeriesPresetLocations={timeSeriesPresetLocations}
      panelId={panelId}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CustomIconButton } from '@opengeoweb/shared';
import { ChevronDown, ChevronUp } from '@opengeoweb/theme';
import React from 'react';
import { useTimesliderTranslation } from '../../../utils/i18n';

export interface HideSliderButtonProps {
  isVisible?: boolean;
  toggleTimeSliderIsVisible?: (isVisible: boolean) => void;
}

const HideSliderButtonComponent: React.FC<HideSliderButtonProps> = ({
  isVisible,
  toggleTimeSliderIsVisible,
}: HideSliderButtonProps) => {
  const { t } = useTimesliderTranslation();
  return (
    <CustomIconButton
      variant="tool"
      onClick={(): void => {
        toggleTimeSliderIsVisible!(!isVisible);
      }}
      data-testid="hideTimeSliderButton"
      tooltipTitle={isVisible ? t('timeslider-hide') : t('timeslider-show')}
    >
      {isVisible ? <ChevronDown /> : <ChevronUp />}
    </CustomIconButton>
  );
};

export const HideSliderButton = React.memo(HideSliderButtonComponent);

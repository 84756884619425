/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { getApi } from '@opengeoweb/api';
import { InitialProps } from '@opengeoweb/core';
import { snackbarTypes } from '@opengeoweb/snackbar';
import { API_NAME, PresetsApi } from '../../utils/api';
import {
  PresetAction,
  ViewPreset,
  WorkspaceSupportedComponentTypes,
} from './types';

export const getSnackbarMessage = (
  action: PresetAction,
  title: string,
): snackbarTypes.TranslatableMessage => {
  // Trim and add quotes
  const trimmedTitle = title.trim();
  switch (action) {
    case PresetAction.DELETE:
      return {
        key: 'workspace-viewpreset-success-delete',
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        params: { TITLE: trimmedTitle },
      };

    case PresetAction.SAVE_AS:
      return {
        key: 'workspace-viewpreset-success-save-as',
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        params: { TITLE: trimmedTitle },
      };
    case PresetAction.EDIT:
      return {
        key: 'workspace-viewpreset-success-edit',
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        params: { TITLE: trimmedTitle },
      };
    case PresetAction.RESET:
      return {
        key: 'workspace-viewpreset-success-reset',
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        params: { TITLE: trimmedTitle },
      };
    default:
      return {
        key: 'workspace-viewpreset-success-save',
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        params: { TITLE: trimmedTitle },
      };
  }
};

export const getPresetsApi = (): PresetsApi => getApi<PresetsApi>(API_NAME);

export const emptyViewPreset = (
  componentType: WorkspaceSupportedComponentTypes | undefined = 'Map',
): ViewPreset => {
  const actualComponentType = componentType || 'Map';
  const initialPropsByType: Record<
    WorkspaceSupportedComponentTypes,
    InitialProps | undefined
  > = {
    Map: {
      mapPreset: {},
    },
    TimeSeries: {
      plotPreset: {
        mapId: '',
        plots: [],
        parameters: [],
      },
      services: [],
    },
    ViewError: undefined,
    ViewLoading: undefined,
    MultiMap: undefined,
    ModelRunInterval: undefined,
    HarmonieTempAndPrecipPreset: undefined,
    TimeSlider: undefined,
    WarningList: undefined,
  };
  return {
    componentType: actualComponentType,
    keywords: '',
    scope: 'user' as const,
    title: '',
    initialProps: initialPropsByType[actualComponentType],
  };
};

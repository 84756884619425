/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { selectorMemoizationOptions } from '../../utils';
import { loadingIndicatorAdapter } from './reducer';
import { LoadingIndicatorModuleState, LoadingIndicatorType } from './types';
import { ANY_EDR_SERVICE } from './constants';

export const { selectById: selectLoadingIndicatorById } =
  loadingIndicatorAdapter.getSelectors((state: LoadingIndicatorModuleState) => {
    return state?.loadingIndicator ?? { entities: {}, ids: [] };
  });

export const isGetMapLoading = createSelector(
  selectLoadingIndicatorById,
  (loadingIndicator: LoadingIndicatorType | undefined) => {
    return loadingIndicator?.isGetMapLoading || false;
  },
  selectorMemoizationOptions,
);

export const isGetCapabilitiesLoading = createSelector(
  selectLoadingIndicatorById,
  (loadingIndicator: LoadingIndicatorType | undefined) => {
    return loadingIndicator?.isGetCapabilitiesLoading || false;
  },
  selectorMemoizationOptions,
);

const isAnyEdrTypeLoading = (
  loadingIndicator: LoadingIndicatorType | undefined,
): boolean => loadingIndicator?.isEDRLoading || false;

export const isEDRLoading = createSelector(
  selectLoadingIndicatorById,
  isAnyEdrTypeLoading,
  selectorMemoizationOptions,
);

const isAnyEDRLoading = createSelector(
  [
    (store: LoadingIndicatorModuleState): LoadingIndicatorType | undefined => {
      return selectLoadingIndicatorById(store, ANY_EDR_SERVICE);
    },
  ],
  isAnyEdrTypeLoading,
  selectorMemoizationOptions,
);

export const isLoading = createSelector(
  [isGetMapLoading, isGetCapabilitiesLoading, isEDRLoading, isAnyEDRLoading],
  (
    isGetMapLoading,
    isGetCapabilitiesLoading,
    isEDRRLoading,
    isAnyEDRLoading,
  ) => {
    return (
      isGetMapLoading ||
      isGetCapabilitiesLoading ||
      isEDRRLoading ||
      isAnyEDRLoading
    );
  },
  selectorMemoizationOptions,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { LayersAdd } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { useCoreTranslation } from '../../../utils/i18n';

const style = {
  width: 'auto !important',
  height: 'auto !important',
  padding: 0,
};

interface LayerSelectButtonProps {
  tooltipTitle?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export const LayerSelectButton: React.FC<LayerSelectButtonProps> = ({
  tooltipTitle,
  icon = <LayersAdd data-testid="layerSelectButtonConnectIcon" />,
  onClick = (): void => {},
}: LayerSelectButtonProps) => {
  const { t } = useCoreTranslation();
  return (
    <CustomIconButton
      onClick={onClick}
      sx={style}
      id="layerSelectButton"
      data-testid="layerSelectButton"
      tooltipTitle={tooltipTitle ?? t('layermanager-layer-select')}
    >
      {icon}
    </CustomIconButton>
  );
};

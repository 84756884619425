/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { ReactHookFormProvider } from '@opengeoweb/form-fields';
import {
  TafModuleProvider,
  TafModuleProviderProps,
} from '../TafModule/TafModuleProvider';
import { emptyBaseForecast, emptyChangegroup } from '../TafForm/utils';

const TafWrapper: React.FC<TafModuleProviderProps> = ({
  children,
  ...props
}: TafModuleProviderProps) => (
  <ReactHookFormProvider
    options={{
      mode: 'onTouched',
      reValidateMode: 'onChange',
      criteriaMode: 'firstError',
      defaultValues: {
        baseForecast: emptyBaseForecast,
        changeGroups: [
          emptyChangegroup,
          emptyChangegroup,
          emptyChangegroup,
          emptyChangegroup,
          emptyChangegroup,
        ],
        validDateStart: '',
        validDateEnd: '',
        baseTime: '',
        previousId: '',
        previousValidDateStart: '',
        previousValidDateEnd: '',
        location: '',
        messageType: '',
        uuid: '',
        issueDate: '',
        status: '',
        IS_DRAFT: false,
      },
    }}
  >
    <TafModuleProvider {...props}>{children}</TafModuleProvider>
  </ReactHookFormProvider>
);

export default TafWrapper;

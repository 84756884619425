/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware } from '@reduxjs/toolkit';

import { snackbarActions } from './reducer';
import * as snackbarSelectors from './selectors';
import { SnackbarModuleStore } from './types';

export const hideTime = 4000;

export const snackbarListener = createListenerMiddleware<SnackbarModuleStore>();

snackbarListener.startListening({
  actionCreator: snackbarActions.openSnackbar,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const currentSnackbarMessages =
      snackbarSelectors.getCurrentSnackbarMessages(listenerApi.getState());

    // If there are currently opened snackbars we need to close these first
    if (currentSnackbarMessages.length) {
      listenerApi.dispatch(snackbarActions.closeSnackbar());
    }
    // Generate a unique id
    const id = `snackbar${Date.now().toString()}`;
    listenerApi.dispatch(
      snackbarActions.triggerOpenSnackbarByListener({
        snackbarContent: { message: payload, id },
      }),
    );
    // If no new snackbar is triggered cancelling this listener, hide the snackbar after X seconds
    await listenerApi.delay(hideTime);
    const currentSnackbarMessagesOpen =
      snackbarSelectors.getCurrentSnackbarMessages(listenerApi.getState());
    if (currentSnackbarMessagesOpen.length) {
      listenerApi.dispatch(snackbarActions.closeSnackbar());
    }
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { SearchField } from '@opengeoweb/shared';
import {
  useAppSelector,
  layerSelectSelectors,
  layerSelectActions,
} from '../../store';

export const SearchFieldConnect: React.FC = () => {
  const dispatch = useDispatch();
  const searchFilter = useAppSelector((store) =>
    layerSelectSelectors.getSearchFilter(store),
  );

  const toggleServicePopup = (url: string): void => {
    dispatch(
      layerSelectActions.toggleServicePopup({
        isOpen: true,
        url,
        variant: 'save',
      }),
    );
  };

  const setSearchFilter = (filterText: string): void => {
    dispatch(layerSelectActions.setSearchFilter({ filterText }));
  };

  return (
    <SearchField
      setSearchFilter={setSearchFilter}
      searchFilter={searchFilter}
      onOpenServicePopup={toggleServicePopup}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Box,
  Button,
  CircularProgress,
  Grid2 as Grid,
  SxProps,
  styled,
} from '@mui/material';
import {
  ReactHookFormHiddenInput,
  ReactHookFormProvider,
  defaultFormOptions,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { breakpoints } from '@opengeoweb/theme';
import * as React from 'react';
import { AlertBanner, dateUtils } from '@opengeoweb/shared';
import { useFormContext } from 'react-hook-form';
import {
  Airmet,
  AirmetConfig,
  AirmetForm,
  AviationProduct,
  FormMode,
  isInstanceOfCancelAirmet,
  isInstanceOfCancelSigmet,
  Sigmet,
  SigmetConfig,
  SigmetForm,
} from '@opengeoweb/sigmet-airmet';

import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { MapPreset } from '@opengeoweb/store';
import { DescriptionField } from './DescriptionField';
import { AreaField } from './AreaField/AreaField';
import { Phenomenon } from './PhenomenonField';
import {
  PublicWarningDetail,
  WarningType,
} from '../../store/publicWarningForm/types';
import {
  BaseDrawingListItem,
  DrawingListItem,
} from '../../store/warningsDrawings/types';
import { LevelColors, LevelField, WarningLevel } from './LevelField';
import { ValidFrom } from './ValidFromField';
import { ValidUntil } from './ValidUntilField';
import { FormAction, FormError } from '../../store/publicWarningForm/reducer';
import { useFormDirty } from '../../utils/useFormDirty';
import { useWarningsTranslation } from '../../utils/i18n';
import { WarningsMapView } from '../WarningsMapView/WarningsMapView';
import { ProbabilityField } from './ProbabilityField';
import { useProductConfig, useTACApi } from '../../api/aviation-api/hooks';

export const useScrollTopOnError = (
  error: FormError,
): React.RefObject<HTMLDivElement> => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (error?.message && ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [error]);
  return ref;
};

export const getEmptyPublicWarning = (): PublicWarningDetail => {
  const currentTime = new Date();
  const validFromTime = dateUtils.add(currentTime, { minutes: 15 });
  const validUntilTime = dateUtils.add(currentTime, { hours: 6, minutes: 15 });

  return {
    id: '',
    phenomenon: '',
    validFrom: dateUtils.dateToString(validFromTime)!,
    validUntil: dateUtils.dateToString(validUntilTime)!,
    level: '',
    probability: 30,
    descriptionOriginal: '',
    descriptionTranslation: '',
    areas: [],
  };
};

export const getFormData = (
  publicWarningsDetails: PublicWarningDetail | undefined,
  object?: DrawingListItem,
): FormData => {
  const data = {
    IS_DRAFT: '',
    'translation-select': '',
    ...(publicWarningsDetails
      ? {
          ...getEmptyPublicWarning(),
          ...publicWarningsDetails,
        }
      : getEmptyPublicWarning()),
    object,
  };

  return data;
};

// tablet breakpoint - 2 padding * 2 for both sides
const containerQueryBreakpoint = `@container (min-width: ${
  breakpoints.tablet - 32
}px)`;
const spinnerSx: SxProps = {
  width: '16px!important',
  height: '16px!important',
  position: 'absolute',
  top: 0,
  bottom: 0,
  margin: 'auto',
  marginLeft: 1,
};

const ContainerWrapper = styled('div')(() => ({
  containerType: 'size',
  height: '100%',
}));

const ContainerFormWrapper = styled('div')<{ isReadOnly: boolean }>(
  ({ isReadOnly = false, theme }) => ({
    padding: theme.spacing(2),
    height: isReadOnly ? '100%' : `calc(100% - 206px)`,
    overflowY: 'auto',
    [containerQueryBreakpoint]: {
      height: isReadOnly ? '100%' : 'calc(100% - 88px)',
    },
  }),
);

const sm6Width =
  'calc(100% * 6 / var(--Grid-columns) - (var(--Grid-columns) - 6) * (var(--Grid-columnSpacing) / var(--Grid-columns)))';
const sm4Width =
  'calc(100% * 4 / var(--Grid-columns) - (var(--Grid-columns) - 4) * (var(--Grid-columnSpacing) / var(--Grid-columns)))';
const ContainerGrid = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '.MuiGrid2-grid-sm-6, .MuiGrid2-grid-sm-4': {
    width: '100%',
    minWidth: '100%',
  },
  [containerQueryBreakpoint]: {
    '.MuiGrid2-grid-sm-6': {
      width: sm6Width,
      minWidth: sm6Width,
    },
    '.MuiGrid2-grid-sm-4': {
      width: sm4Width,
      minWidth: sm4Width,
    },
  },
}));

interface FormData extends PublicWarningDetail {
  IS_DRAFT?: boolean | string;
  object?: BaseDrawingListItem;
  'translation-select'?: string;
}

export const prepareFormValues = (data: FormData): PublicWarningDetail => {
  // remove unneeded fields
  const {
    IS_DRAFT,
    object,
    'translation-select': translationSelect,
    ...productToPost
  } = data;
  // remove empty fields
  return Object.keys(productToPost).reduce<PublicWarningDetail>(
    (warningDetail: PublicWarningDetail, fieldName: string) => {
      const key = fieldName as keyof PublicWarningDetail;
      const fieldValue = productToPost[key];
      if (Array.isArray(fieldValue)) {
        if (!fieldValue.length || fieldValue[0].geoJSON === null) {
          return warningDetail;
        }
      }

      if (fieldValue) {
        return {
          ...warningDetail,
          [key]: fieldValue,
        };
      }

      return warningDetail;
    },
    {} as PublicWarningDetail,
  );
};

export interface FormProps {
  formAction?: FormAction;
  defaultMapPreset?: MapPreset;
  onSaveForm?: (formValues: PublicWarningDetail) => void;
  onPublishForm?: (formValues: PublicWarningDetail) => void;
  onClearForm?: () => void;
  object?: DrawingListItem;
  publicWarningDetails?: PublicWarningDetail;
  error?: FormError;
  isReadOnly?: boolean;
  onFormDirty?: (isFormDirty: boolean) => void;
  isSnapshot?: boolean;
  onAddObject?: () => void;
  selectedAviationProduct?: AviationProduct;
  warningType?: WarningType;
  mode?: FormMode;
}

interface FormErrors {
  title: string;
  errors: React.ReactElement[];
}

const getErrors = (errors: string): null | FormErrors =>
  errors
    ? errors?.split(/\r?\n/).reduce<FormErrors>(
        (allErrors, error, index) => {
          if (index === 0) {
            return {
              ...allErrors,
              title: error,
            };
          }
          const isEven = index % 2 === 0;

          return {
            ...allErrors,
            errors: allErrors.errors.concat(
              // eslint-disable-next-line react/no-array-index-key
              <p key={`error-${index}`} style={{ fontSize: 14 }}>
                {isEven ? error : <i>{error}</i>}
              </p>,
            ),
          };
        },
        { title: '', errors: [] },
      )
    : null;

export function warningLevelFeatureColors(
  level: WarningLevel | undefined,
  geoJSON: FeatureCollection<Geometry, GeoJsonProperties> | undefined, // Allow undefined
  isAviationWarning = false, // Determines if it's an aviation warning
): FeatureCollection<Geometry, GeoJsonProperties> {
  if (!geoJSON || !geoJSON.features) {
    // Return an empty FeatureCollection if geoJSON is invalid
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }

  return {
    ...geoJSON,
    features: geoJSON.features.map((feature) => {
      let colors;

      if (isAviationWarning) {
        const isStartGeometry = feature.properties?.isStartGeometry ?? false;
        colors = {
          color: isStartGeometry ? '#E27000' : '#800080', // Orange for start, purple for end
          borderColor: isStartGeometry ? '#FEAE02' : '#4B0082',
        };
      } else if (level) {
        // Public warning: use LevelColors if level is defined
        colors = LevelColors[level];
      } else {
        // Default to blue if level is undefined (public warning)
        colors = { color: '#0000FF', borderColor: '#0000A0' };
      }

      const defaultProperties = {
        fill: colors.color,
        stroke: colors.color,
        'fill-opacity': 0.2,
        'stroke-width': 2,
        'stroke-opacity': 1,
      };

      return {
        ...feature,
        properties: {
          ...defaultProperties,
          ...feature.properties,
          fill: colors.color || feature.properties?.fill,
          stroke: colors.color || feature.properties?.stroke,
        },
      };
    }),
  };
}

const Form: React.FC<FormProps> = ({
  defaultMapPreset,
  onSaveForm = (): void => {},
  onPublishForm = (): void => {},
  onClearForm = (): void => {},
  object,
  formAction = '',
  publicWarningDetails,
  error = { severity: 'error', message: '' },
  isReadOnly = false,
  onFormDirty = (): void => {},
  isSnapshot = false,
  onAddObject = (): void => {},
  selectedAviationProduct,
  warningType = 'public',
  mode,
}) => {
  const { fetchSigmetTAC, fetchAirmetTAC } = useTACApi();

  const initialPublicWarningArea =
    publicWarningDetails?.areas?.length &&
    publicWarningDetails?.areas[0]?.geoJSON;

  const [warningGeoJSONFeature, setWarningGeoJSONFeature] = React.useState(
    initialPublicWarningArea,
  );

  const { t } = useWarningsTranslation();
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields },
    getValues,
    setValue,
  } = useFormContext();
  const { toggleIsDraft, DraftFieldHelper } = useDraftFormHelpers();

  const isPublishing = formAction === 'publishing';
  const isSaving = formAction === 'saving';
  const areFieldsDisabled = isPublishing || isSaving;

  useFormDirty({
    isDirty: Object.keys(dirtyFields).length > 0,
    isLoading: isPublishing || isSaving,
    error: error?.message,
    onFormDirty,
    onSuccess: () => {
      // reset form dirty and update defaultvalues and formvalues after successfull saving
      reset(getValues());
    },
  });

  const onPressPublish = (): void => {
    if (!isPublishing) {
      toggleIsDraft(false);
      void handleSubmit(async (data) => {
        onPublishForm(prepareFormValues(data as PublicWarningDetail));
      })();
    }
  };

  const onPressSave = (): void => {
    if (!isSaving) {
      toggleIsDraft(true);
      void handleSubmit(async (data) => {
        onSaveForm(prepareFormValues(data as PublicWarningDetail));
      })();
    }
  };

  const onPressClear = (): void => {
    reset(getEmptyPublicWarning());
    setWarningGeoJSONFeature(undefined);
    onClearForm();
  };

  const publicWarningId = publicWarningDetails?.id;
  React.useEffect(() => {
    if (selectedAviationProduct) {
      return;
    }
    // sync redux with react-hook-form
    reset(getFormData(publicWarningDetails, object));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicWarningId]);

  const areas = publicWarningDetails?.areas;
  React.useEffect(() => {
    if (selectedAviationProduct) {
      return;
    }
    if (areas) {
      // sync redux with react-hook-form
      setValue('areas', areas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas, setValue]);

  React.useEffect(() => {
    if (selectedAviationProduct) {
      return;
    }
    // sync drawing on map with selected warning
    setWarningGeoJSONFeature(initialPublicWarningArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPublicWarningArea]);

  const aviationId = selectedAviationProduct?.uuid;
  React.useEffect(() => {
    if (!selectedAviationProduct) {
      return;
    }
    // sync redux with react-hook-form
    reset(selectedAviationProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aviationId, warningType]);

  const errors = getErrors(error.message);

  const level = publicWarningDetails?.level
    ? WarningLevel[publicWarningDetails.level as keyof typeof WarningLevel]
    : undefined;

  const geoJSONFeature = warningLevelFeatureColors(
    level,
    warningGeoJSONFeature as FeatureCollection<Geometry, GeoJsonProperties>,
  );

  const ref = useScrollTopOnError(error);
  const { data: aviationConfig } = useProductConfig(warningType);

  return (
    <ContainerWrapper>
      <ContainerFormWrapper
        isReadOnly={isReadOnly}
        ref={ref}
        data-testid="scroll-container"
      >
        {errors && (
          <Box sx={{ marginBottom: 2 }}>
            <AlertBanner
              title={errors.title}
              info={errors.errors as unknown as React.ReactElement}
              shouldClose
              severity={error?.severity}
            />
          </Box>
        )}
        {selectedAviationProduct ? (
          <Box
            sx={{
              maxWidth: { xs: '550px', sm: '750px' },
              marginLeft: '-80px',
            }}
          >
            {warningType === 'sigmet' ? (
              <SigmetForm
                showMap={false}
                mode={mode!}
                initialSigmet={
                  selectedAviationProduct !== undefined &&
                  !isInstanceOfCancelSigmet(selectedAviationProduct)
                    ? (selectedAviationProduct as Sigmet)
                    : null!
                }
                isCancelSigmet={
                  selectedAviationProduct !== undefined &&
                  isInstanceOfCancelSigmet(selectedAviationProduct)
                }
                initialCancelSigmet={
                  selectedAviationProduct !== undefined &&
                  isInstanceOfCancelSigmet(selectedAviationProduct)
                    ? selectedAviationProduct
                    : null!
                }
                productConfig={aviationConfig as SigmetConfig}
                product={selectedAviationProduct}
                getTAC={(product) => fetchSigmetTAC(product)}
              />
            ) : (
              <AirmetForm
                showMap={false}
                mode={mode!}
                isCancelAirmet={
                  selectedAviationProduct !== undefined &&
                  isInstanceOfCancelAirmet(selectedAviationProduct)
                }
                initialAirmet={
                  selectedAviationProduct !== undefined &&
                  !isInstanceOfCancelAirmet(selectedAviationProduct)
                    ? (selectedAviationProduct as Airmet)
                    : null!
                }
                initialCancelAirmet={
                  selectedAviationProduct !== undefined &&
                  isInstanceOfCancelAirmet(selectedAviationProduct)
                    ? selectedAviationProduct
                    : null!
                }
                productConfig={aviationConfig as AirmetConfig}
                product={selectedAviationProduct}
                getTAC={(product) => fetchAirmetTAC(product)}
              />
            )}
          </Box>
        ) : (
          <ContainerGrid>
            <Grid size={{ xs: 12 }} container rowSpacing={2} columnSpacing={2}>
              <Grid size={{ xs: 12 }}>
                <Phenomenon
                  isDisabled={areFieldsDisabled}
                  isReadOnly={isReadOnly}
                />
              </Grid>

              <Grid size={{ xs: 12 }}>
                <AreaField
                  onEditObject={(): void => {}}
                  onViewObject={(): void => {}}
                  onDeleteObject={(): void => {}}
                  onAddObject={onAddObject}
                  isDisabled={areFieldsDisabled || isReadOnly}
                />
              </Grid>

              <Grid size={{ xs: 12 }}>
                <WarningsMapView
                  geojsonFeature={geoJSONFeature}
                  defaultMapPreset={defaultMapPreset}
                  isSnapshot={isSnapshot}
                />
              </Grid>

              <Grid size={{ xs: 12, sm: 6 }}>
                <ValidFrom
                  isDisabled={areFieldsDisabled}
                  isReadOnly={isReadOnly}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ValidUntil
                  isDisabled={areFieldsDisabled}
                  isReadOnly={isReadOnly}
                />
              </Grid>

              <Grid size={{ xs: 12 }} container spacing={2}>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <LevelField
                    isDisabled={areFieldsDisabled}
                    isReadOnly={isReadOnly}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <ProbabilityField
                    isDisabled={areFieldsDisabled}
                    isReadOnly={isReadOnly}
                  />
                </Grid>
              </Grid>

              <Grid size={{ xs: 12 }} container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <DescriptionField
                    isDisabled={areFieldsDisabled}
                    isReadOnly={isReadOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ContainerGrid>
        )}
        <DraftFieldHelper />
        <ReactHookFormHiddenInput name="id" />
      </ContainerFormWrapper>
      {!isReadOnly && (
        <Box
          sx={{
            padding: '24px 10px 14px 10px',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: 'geowebColors.background.surfaceApp',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
            zIndex: 1,
            overflowX: 'hidden',
            border: 'none',
          }}
        >
          <ContainerGrid>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Button
                  variant="tertiary"
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                  disabled={areFieldsDisabled}
                  onClick={onPressClear}
                >
                  {t('warning-button-clear')}
                </Button>
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Button
                  variant="tertiary"
                  sx={{
                    width: '100%',
                    marginBottom: 1,
                  }}
                  disabled={isPublishing}
                  onClick={onPressSave}
                >
                  <span style={{ position: 'relative' }}>
                    {isSaving
                      ? t('warning-button-saving')
                      : t('warning-button-save')}
                    {isSaving && (
                      <CircularProgress
                        data-testid="spinner"
                        color="inherit"
                        sx={spinnerSx}
                      />
                    )}
                  </span>
                </Button>
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Button
                  variant="primary"
                  sx={{
                    width: '100%',
                  }}
                  disabled={isSaving}
                  onClick={onPressPublish}
                >
                  <span style={{ position: 'relative' }}>
                    {isPublishing
                      ? t('warning-button-publishing')
                      : t('warning-button-publish')}
                    {isPublishing && (
                      <CircularProgress
                        data-testid="spinner"
                        color="inherit"
                        sx={spinnerSx}
                      />
                    )}
                  </span>
                </Button>
              </Grid>
            </Grid>
          </ContainerGrid>
        </Box>
      )}
    </ContainerWrapper>
  );
};

export const PublicWarningsForm: React.FC<FormProps> = (props) => {
  const { object, publicWarningDetails, selectedAviationProduct } = props;

  const defaultValues =
    selectedAviationProduct || getFormData(publicWarningDetails, object);

  return (
    <ReactHookFormProvider
      options={{
        ...defaultFormOptions,
        mode: 'onSubmit',
        defaultValues,
      }}
    >
      <Form {...props} />
    </ReactHookFormProvider>
  );
};

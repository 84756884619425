/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { LinearProgress } from '@mui/material';
import { useApi, useApiContext } from '@opengeoweb/api';
import { AlertBanner } from '@opengeoweb/shared';
import { useAuthenticationContext } from '@opengeoweb/authentication';
import React from 'react';
import { ProductType } from '../../types';
import { SigmetAirmetApi } from '../../utils/api';
import MetInfoWrapper from './MetInfoWrapper';
import { useSigmetAirmetTranslation } from '../../utils/i18n';

interface ModuleWrapperProps {
  productType: ProductType;
}

const DownloadSigmetAirmetConfigWrapper: React.FC<ModuleWrapperProps> = ({
  productType,
}) => {
  const { t } = useSigmetAirmetTranslation();
  const { api } = useApiContext<SigmetAirmetApi>();
  const { isLoggedIn } = useAuthenticationContext();

  const getConfigurationRequest =
    api[
      productType === 'sigmet'
        ? 'getSigmetConfiguration'
        : 'getAirmetConfiguration'
    ];
  const {
    result: productConfig,
    isLoading: isConfigLoading,
    error,
  } = useApi(getConfigurationRequest);

  if (isConfigLoading) {
    return <LinearProgress data-testid="loadingSpinner" />;
  }
  if (error) {
    return (
      <AlertBanner
        title={t('error-title')}
        info={!isLoggedIn ? t('error-auth-login') : error.message}
      />
    );
  }
  return (
    <MetInfoWrapper productType={productType} productConfig={productConfig} />
  );
};

export default DownloadSigmetAirmetConfigWrapper;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Button, Grid2 as Grid } from '@mui/material';
import React from 'react';
import { TafActions, TafCanbe } from '../../../types';
import { useTafTranslation } from '../../../utils/i18n';

export const tafActionToDraftAction = (tafAction: TafActions): TafActions => {
  switch (tafAction) {
    case 'AMEND':
    case 'DRAFT_AMEND':
      return 'DRAFT_AMEND';
    case 'CORRECT':
    case 'DRAFT_CORRECT':
      return 'DRAFT_CORRECT';
    default:
      return 'DRAFT';
  }
};

const tafActionToPublishAction = (tafAction: TafActions): TafActions => {
  switch (tafAction) {
    case 'AMEND':
    case 'DRAFT_AMEND':
      return 'AMEND';
    case 'CORRECT':
    case 'DRAFT_CORRECT':
      return 'CORRECT';
    default:
      return 'PUBLISH';
  }
};

const buttonsStyle = {
  button: {
    minWidth: { xs: '56px', sm: '64px' },
    marginLeft: {
      xs: 0.5,
      sm: 1,
    },
    marginTop: 3,
    marginBottom: 3,
  },
};
interface ViewModeButtonsProps {
  canBe: TafCanbe[];
  onTafViewModeButtonPress: (action: TafActions) => void;
}

const ViewModeButtons: React.FC<ViewModeButtonsProps> = ({
  canBe,
  onTafViewModeButtonPress,
}: ViewModeButtonsProps) => {
  const { t } = useTafTranslation();
  return !canBe.includes('CANCELLED') &&
    !canBe.includes('CORRECTED') &&
    !canBe.includes('AMENDED') ? null : (
    <>
      {canBe.includes('CANCELLED') && (
        <Button
          data-testid="canceltaf"
          variant="tertiary"
          onClick={(): void => onTafViewModeButtonPress('CANCEL')}
        >
          {t('button-cancel')}
        </Button>
      )}
      {canBe.includes('CORRECTED') && (
        <Button
          data-testid="correcttaf"
          variant="tertiary"
          onClick={(): void => onTafViewModeButtonPress('CORRECT')}
        >
          {t('button-correct')}
        </Button>
      )}
      {canBe.includes('AMENDED') && (
        <Button
          data-testid="amendtaf"
          variant="tertiary"
          onClick={(): void => onTafViewModeButtonPress('AMEND')}
        >
          {t('button-amend')}
        </Button>
      )}
    </>
  );
};

interface TafFormButtonsProps {
  isFormDisabled?: boolean;
  tafAction?: TafActions;
  canBe?: TafCanbe[];
  onTafEditModeButtonPress: (action: TafActions) => void;
  onTafViewModeButtonPress: (action: TafActions) => void;
}

const TafFormButtons: React.FC<TafFormButtonsProps> = ({
  isFormDisabled = false,
  tafAction,
  canBe = [],
  onTafEditModeButtonPress,
  onTafViewModeButtonPress,
}: TafFormButtonsProps) => {
  const { t } = useTafTranslation();
  return (
    <Grid
      container
      columns={24}
      sx={[
        {
          justifyContent: 'flex-end',
        },
        ...(Array.isArray(buttonsStyle) ? buttonsStyle : [buttonsStyle]),
      ]}
    >
      <Grid
        container
        columns={24}
        size={{
          xs: 24,
          sm: 23,
        }}
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        {isFormDisabled ? (
          <ViewModeButtons
            canBe={canBe}
            onTafViewModeButtonPress={onTafViewModeButtonPress}
          />
        ) : (
          <>
            <Button
              data-testid="cleartaf"
              variant="tertiary"
              onClick={(): void => {
                // Simply discard the changes and close the dialog.
                onTafEditModeButtonPress('CLEAR');
              }}
            >
              {t('button-clear')}
            </Button>
            <Button
              data-testid="savedrafttaf"
              variant="tertiary"
              onClick={(): void => {
                /* Save the TAF as draft: 
        if action NEW or DRAFT - new status will be DRAFT -- default
        if action AMEND - new status will be DRAFT_AMENDED 
        if action CORRECT - new status will be DRAFT_CORRECTED
        if action DRAFT_AMEND - new status will be DRAFT_AMENDED
        if action DRAFT_CORRECT - new status will be DRAFT_CORRECTED
        */

                onTafEditModeButtonPress(tafActionToDraftAction(tafAction!));
              }}
            >
              {t('button-save')}
            </Button>
            <Button
              data-testid="publishtaf"
              variant="tertiary"
              onClick={(): void => {
                /* Publish the TAF: 
        if action NEW or DRAFT - new status will be PUBLISHED -- default
        if action AMEND - new status will be AMENDED 
        if action CORRECT - new status will be CORRECTED
        if action DRAFT_AMEND - new status will be AMENDED
        if action DRAFT_CORRECT - new status will be CORRECTED
        */

                onTafEditModeButtonPress(tafActionToPublishAction(tafAction!));
              }}
            >
              {t('button-publish')}
            </Button>
          </>
        )}
      </Grid>
      <Grid columns={24} size={{ sm: 1 }} />
    </Grid>
  );
};

export default TafFormButtons;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* eslint-disable no-param-reassign */
import { dateUtils } from '@opengeoweb/shared';
import { debugLogger, DebugType } from './WMJSTools';
import type WMJSMap from './WMJSMap';
import { AnimationStep } from './types';
import {
  getLayersImageUrlsForTime,
  prefetchImagesForAnimation,
} from './WMPrefetch';
import WMTimer from './WMTimer';

interface SimpleAnimationStep {
  name: string;
  value: string;
}

export default class WMJSAnimate {
  private _map: WMJSMap;

  private _divAnimationInfo: HTMLElement;

  private _callBack: { triggerEvent: (ev: string, val?: WMJSMap) => void };

  private isNextTimeCloseToWallClockTime = false;

  private animationTimer: WMTimer;

  private animateBusy: boolean;

  constructor(_map: WMJSMap) {
    _map.animationDelay = 100;
    this.animateBusy = false;
    this._callBack = _map.getListener();
    this._divAnimationInfo = document.createElement('div');
    _map.currentAnimationStep = 0;
    _map.animationList = undefined;
    _map.isAnimating = false;
    _map.setAnimationDelay = (delay): void => {
      if (delay < 1) {
        delay = 1;
      }
      _map.animationDelay = delay;
    };
    this.isNextTimeCloseToWallClockTime = false;

    this._divAnimationInfo.style.zIndex = '10000';
    this._divAnimationInfo.style.background = 'none';
    this._divAnimationInfo.style.position = 'absolute';
    this._divAnimationInfo.style.border = 'none';
    this._divAnimationInfo.style.margin = '0px';
    this._divAnimationInfo.style.padding = '0px';
    this._divAnimationInfo.style.lineHeight = '14px';
    this._divAnimationInfo.style.fontFamily =
      '"Courier New", "Lucida Console", Monospace';
    this._divAnimationInfo.style.fontSize = '10px';
    _map.getBaseElement().append(this._divAnimationInfo);
    this._map = _map;

    /* Bind */
    this.loopAnimation = this.loopAnimation.bind(this);
    this.stopAnimating = this.stopAnimating.bind(this);
    _map.stopAnimating = this.stopAnimating;
    this.animationTimer = new WMTimer();
  }

  isCurrentAnimationTimeCloseToWallClockTime(): boolean {
    if (this.isNextTimeCloseToWallClockTime) {
      this.isNextTimeCloseToWallClockTime = false;
      return true;
    }

    const currentAnimationStep = this._map.animationList![
      this._map.currentAnimationStep
    ] as SimpleAnimationStep;

    const nextAnimationStep = this._map.animationList![
      this._map.currentAnimationStep + 1 >= this._map.animationList!.length
        ? 0
        : this._map.currentAnimationStep + 1
    ] as SimpleAnimationStep;

    const wallClockTime = new Date();

    const currentAnimationTime = new Date(currentAnimationStep.value);
    const nextAnimationTime = new Date(nextAnimationStep.value);

    if (
      dateUtils.isBetween(
        wallClockTime,
        currentAnimationTime,
        nextAnimationTime,
      )
    ) {
      const timeDiff1 = dateUtils.differenceInMinutes(
        currentAnimationTime,
        wallClockTime,
      );

      const timeDiff2 = dateUtils.differenceInMinutes(
        nextAnimationTime,
        wallClockTime,
      );

      if (Math.abs(timeDiff1) < Math.abs(timeDiff2)) {
        // Wall clock time is closer to current animation step time than to the next animation step time
        this.isNextTimeCloseToWallClockTime = false;
        return true;
      }

      // Wall clock time is closer to next animation step time than to the current animation step time
      this.isNextTimeCloseToWallClockTime = true;
    }

    return false;
  }

  #animate(): void {
    if (this._map.isAnimating === false) {
      return;
    }
    if (this.animateBusy === true) {
      return;
    }

    const animationStep = this._map.animationList![
      this._map.currentAnimationStep
    ] as SimpleAnimationStep;
    if (!animationStep) {
      debugLogger(
        DebugType.Error,
        `No animation step for ${this._map.currentAnimationStep}`,
      );
      return;
    }
    this._map.setDimension(animationStep.name, animationStep.value, false);
    this._callBack.triggerEvent('ondimchange');
    this._callBack.triggerEvent('onnextanimationstep', this._map);
    this._map.addLayersToCanvasAndDisplayThem();
    this.animateBusy = false;
  }

  loopAnimation(): void {
    if (this._map.isAnimating === false) {
      return;
    }

    let { animationDelay } = this._map;
    const animationSteps = this._map.animationList as AnimationStep[];

    if (this._map.currentAnimationStep === 0) {
      animationDelay *= 3;
    } else if (this._map.currentAnimationStep === animationSteps.length - 1) {
      animationDelay *= 5;
    } else if (this.isCurrentAnimationTimeCloseToWallClockTime()) {
      animationDelay *= 5;
    }

    // set up timer for next animation step
    this.animationTimer.init(animationDelay, this.loopAnimation);

    prefetchImagesForAnimation(this._map);

    this.#animate();

    let nextAnimationStepIndex = this._map.currentAnimationStep + 1;
    if (nextAnimationStepIndex >= animationSteps.length) {
      nextAnimationStepIndex = 0;
    }

    // get urls for images in next animation step
    const layersImageUrls = getLayersImageUrlsForTime(
      this._map,
      animationSteps[nextAnimationStepIndex].value,
    );

    // if images for next animation step is ready or haven't started loading
    // change map animation step to next step
    let numberOfImagesReadyForNextAnimationStep = 0;
    layersImageUrls.forEach((request) => {
      const { url } = request;
      const image = this._map.getMapImageStore.getImageForSrc(url);

      /* Get a loaded image which has no error */
      if (image?.isLoadedWithoutErrors()) {
        numberOfImagesReadyForNextAnimationStep += 1;
        return;
      }
      /* Check if a similar image is available instead, then we can continue with the smoother animation */
      const alternativeImage = this._map.getAlternativeImage(
        url,
        this._map.getDrawBBOX(),
      );
      if (alternativeImage) {
        numberOfImagesReadyForNextAnimationStep += 1;
        return;
      }
      const imageHasntStartedLoading = !image?.isLoading();
      if (imageHasntStartedLoading) {
        /* No alternatives and current image is not loading, so lets continue the animation */
        numberOfImagesReadyForNextAnimationStep += 1;
      }
    });
    if (numberOfImagesReadyForNextAnimationStep >= layersImageUrls.length) {
      this._map.currentAnimationStep = nextAnimationStepIndex;
    }
  }

  stopAnimating(): void {
    if (this._map.isAnimating === false) {
      return;
    }
    this._map._animationList = undefined!;
    this._divAnimationInfo.style.display = 'none';
    this._map.isAnimating = false;
    this.animateBusy = false;
    this._callBack.triggerEvent('onstopanimation', this._map);
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useGeowebTranslation } from '../../i18n';

interface ErrorPageProps {
  location?: History;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ location }: ErrorPageProps) => {
  const { t } = useGeowebTranslation();
  const hasError = location?.state && location?.state.error;

  if (!hasError) {
    return (
      <Alert severity="info">
        <AlertTitle>{t('geoweb-alert-severity-info-message')}</AlertTitle>
        {t('geoweb-info-go-back')}{' '}
        <Link to="/login">{t('geoweb-go-back-to-login')}</Link>
      </Alert>
    );
  }

  const { message, stack } = location.state.error;

  return (
    <Alert severity="error">
      <AlertTitle>{t('geoweb-alert-severity-error-message')}</AlertTitle>
      {t('geoweb-info-go-back')}{' '}
      <Link to="/login">{t('geoweb-go-back-to-login')}</Link>
      <br />
      <br />
      <Typography variant="subtitle1">{message}</Typography>
      <code>{stack}</code>
    </Alert>
  );
};

export default ErrorPage;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { useApiContext } from '@opengeoweb/api';
import { AlertBanner, dateUtils, usePoller } from '@opengeoweb/shared';
import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import { useAuthenticationContext } from '@opengeoweb/authentication';
import { AxiosError } from 'axios';
import TafLayout from '../TafLayout/TafLayout';
import { TafWrapper } from '../TafWrapper';
import { TafModuleProviderProps } from './TafModuleProvider';
import { TafFromBackend } from '../../types';
import { TafApi } from '../../utils/api';
import { ResponsiveWrapper } from '../ResponsiveWrapper/ResponsiveWrapper';
import { useTafTranslation } from '../../utils/i18n';

export const getCurrentDate = (): string =>
  dateUtils.dateToString(dateUtils.utc(), dateUtils.DATE_FORMAT_HOURS)!;

const TafModule = (): React.ReactElement => {
  const { t } = useTafTranslation();
  const { api } = useApiContext<TafApi>();
  const [tafList, setTafList] = React.useState<TafFromBackend[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<{
    title: string;
    info?: string;
  } | null>(null);
  const [lastUpdateTime, setLastUpdateTime] = React.useState<string>();
  const { isLoggedIn } = useAuthenticationContext();
  const ERROR_AUTH = t('error-auth');
  const ERROR_AUTH_MESSAGE = t('error-auth-message');

  const fetchNewTafList = async (): Promise<TafFromBackend[]> => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const result = await api.getTafList();
      setTafList(result.data);
      setIsLoading(false);
      setLastUpdateTime(getCurrentDate());
      return result.data;
    } catch (error: unknown) {
      setErrorMessage({
        title: t('error-list'),
        info: (error as AxiosError).message,
      });
      setIsLoading(false);
      return [];
    }
  };

  const onUpdateTaf = async (): Promise<TafFromBackend[]> => {
    return fetchNewTafList();
  };

  React.useEffect(() => {
    if (!isLoggedIn) {
      setErrorMessage({
        title: ERROR_AUTH,
        info: ERROR_AUTH_MESSAGE,
      });
    } else {
      void fetchNewTafList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  usePoller([tafList, errorMessage], fetchNewTafList);

  return (
    <ResponsiveWrapper>
      <Box
        component="div"
        id="tafmodule"
        data-testid="tafmodule"
        sx={{
          height: '100%',
        }}
      >
        {isLoggedIn && isLoading && (
          <LinearProgress
            data-testid="loading-bar"
            color="secondary"
            sx={{ marginBottom: '-4px' }}
          />
        )}
        {errorMessage ? (
          <AlertBanner
            severity="error"
            title={errorMessage.title}
            info={errorMessage.info}
            data-testid="error-message"
            actionButtonProps={
              errorMessage.title !== ERROR_AUTH
                ? {
                    title: t('error-title-retry'),
                    onClick: fetchNewTafList,
                  }
                : undefined
            }
          />
        ) : null}
        <TafLayout
          tafList={tafList}
          onUpdateTaf={onUpdateTaf}
          lastUpdateTime={lastUpdateTime}
        />
      </Box>
    </ResponsiveWrapper>
  );
};

const Wrapper: React.FC<TafModuleProviderProps> = (
  props: TafModuleProviderProps,
) => (
  <TafWrapper {...props}>
    <TafModule />
  </TafWrapper>
);

export default Wrapper;

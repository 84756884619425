/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { TimeSeriesSelect } from '@opengeoweb/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  CoreAppStore,
  uiActions,
  uiSelectors,
  uiTypes,
} from '@opengeoweb/store';
import { CustomToggleButton, CustomTooltip } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import { getMapId } from '../../store/selectors';

export interface TimeSeriesSelectButtonConnectProps {
  isEnabled: boolean;
  onClick: () => void;
  t: TFunction;
  plotId: string;
  selectPlotId: string;
}

export const TimeSeriesSelectButtonConnect: React.FC<
  TimeSeriesSelectButtonConnectProps
> = ({ isEnabled, onClick, t, plotId, selectPlotId }) => {
  const dispatch = useDispatch();
  const currentActiveMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, uiTypes.DialogTypes.TimeSeriesSelect),
  );

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, uiTypes.DialogTypes.TimeSeriesSelect),
  );

  const mapId = useSelector(getMapId)!;

  const handleClick = (): void => {
    onClick();

    const openDialogForDifferentMap = currentActiveMapId !== mapId;
    const dialogIsNotOpenForCurrentMap =
      openDialogForDifferentMap || !isOpenInStore;

    if (dialogIsNotOpenForCurrentMap) {
      dispatch(
        uiActions.setActiveMapIdForDialog({
          type: uiTypes.DialogTypes.TimeSeriesSelect,
          mapId,
          setOpen: true,
          source: 'app',
        }),
      );
    }
  };
  const isSelectOpenForCurrentPlot = plotId === selectPlotId;

  return (
    <CustomTooltip title={t('timeseries-add-param-to-plot')}>
      <CustomToggleButton
        onClick={handleClick}
        data-testid="timeSeriesSelectButton"
        disabled={!isEnabled}
        variant="tool"
        selected={isOpenInStore && isSelectOpenForCurrentPlot}
        sx={{
          marginLeft: '4px',
          height: '24px!important',
          width: '24px',
          paddingLeft: '5px',
          svg: {
            marginLeft: '8px',
          },
        }}
      >
        <TimeSeriesSelect />
      </CustomToggleButton>
    </CustomTooltip>
  );
};

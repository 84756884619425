/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import WMImage, { getCurrentImageTime } from './WMImage';
import {
  DebugType,
  debugLogger,
  isDefined,
  getUriWithParam,
  enableConsoleDebugging,
} from './WMJSTools';
import WMImageStore, { WMImageEventType } from './WMImageStore';
import WMBBOX from './WMBBOX';
import { LinkedInfo, LinkedInfoContent, WMPosition } from './types';
import type IWMJSMap from './IWMJSMap';

export type SharedImageListType = Record<string, CanvasGeoLayer[]>;

const ANIMATIONFRAMEREQUEST_DONE = -1;
const MIN_FRACTION_OF_OVERLAP = 0.75;

interface CanvasBufferCallbacks {
  beforecanvasstartdraw?: (context: CanvasRenderingContext2D) => void;
  beforecanvasdisplay?: (context: CanvasRenderingContext2D) => void;
  canvasonerror?: (e: LinkedInfo[]) => void;
  aftercanvasdisplay?: (c: CanvasRenderingContext2D) => void;
}

export interface CanvasBBOX {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface CanvasGeoLayer {
  intersect: number;
  areaFractionClosest: number;
  areaFraction: number;
  bbox: CanvasBBOX;
  width: number;
  height: number;
  opacity: number;
  imageAge: number;
  imageSource: string;
}
/**
 * Function to find image url's which are similar to other images, without taking width, height and bbox into account
 * @param imageSource The source / url of the image to check
 * @returns A signature for this image, which is the same for images with different bbox and or width/height
 */
export const makeQueryStringWithoutGeoInfo = (imageSource: string): string => {
  if (imageSource.indexOf('GFI%3ATIME_ELEVATION') !== -1) {
    return getUriWithParam(imageSource, {
      BBOX: '',
      WIDTH: '',
      HEIGHT: '',
      time: '',
      elevation: '',
    });
  }
  return getUriWithParam(imageSource, {
    BBOX: '',
    WIDTH: '',
    HEIGHT: '',
  });
};

/**
 * Function which returns how much two bbox areas have the same area and do overlap.
 * See test in WMCanvasBuffer.spec.ts for details
 * @param boxA
 * @param boxB
 * @returns
 */
const getOverlappingAreaRatio = (
  boxA: CanvasBBOX,
  boxB: CanvasBBOX,
): [number, number] => {
  const area1 =
    Math.abs(boxA.left - boxA.right) * Math.abs(boxA.bottom - boxA.top);

  const area2 =
    Math.abs(boxB.left - boxB.right) * Math.abs(boxB.bottom - boxB.top);

  if (area1 === 0 || area2 === 0) {
    return [0, 0];
  }
  const areaFraction = area1 / area2;

  const intersectingArea =
    Math.max(
      0,
      Math.min(boxA.right, boxB.right) - Math.max(boxA.left, boxB.left),
    ) *
    Math.max(
      0,
      Math.min(boxA.top, boxB.top) - Math.max(boxA.bottom, boxB.bottom),
    );

  const intersectingFraction = intersectingArea / area1;

  return [areaFraction, intersectingFraction];
};

/**
 * Sort the imageslist
 * @param imagesList
 * @returns
 */

export const getSortedListOfImages = (
  imagesList: CanvasGeoLayer[],
  bbox: CanvasBBOX,
): void => {
  if (!imagesList?.length) {
    return;
  }
  const canvasBox = {
    left: bbox.left,
    right: bbox.right,
    top: bbox.top,
    bottom: bbox.bottom,
  };
  // Calculate and assign amount of overlap to each image in image list
  imagesList.forEach((image) => {
    const [areaFraction, intersect] = getOverlappingAreaRatio(
      image.bbox,
      canvasBox,
    );

    // 1.0 is perfect overlap, 0.8 and 1.2 are less suitable. Filter by having images with overlap closest to 1.0 on top.
    const areaFractionClosest = Math.abs(1 - Math.min(areaFraction, 1.2));
    // Note: directly assigning properties here for efficiency
    image.areaFraction = areaFraction; // eslint-disable-line no-param-reassign
    image.intersect = intersect; // eslint-disable-line no-param-reassign

    image.areaFractionClosest = areaFractionClosest; // eslint-disable-line no-param-reassign
  });

  // Sort the images by overlap
  imagesList.sort((a, b) => {
    if (a.areaFractionClosest > b.areaFractionClosest) {
      return 1;
    }
    if (a.areaFractionClosest < b.areaFractionClosest) {
      return -1;
    }
    if (a.intersect < b.intersect) {
      return 1;
    }
    if (a.intersect > b.intersect) {
      return -1;
    }

    return 0;
  });

  // Only keep 10 images as reference for speed.
  if (imagesList.length >= 10) {
    imagesList.length = 10; // eslint-disable-line no-param-reassign
  }
};

export default class WMCanvasBuffer {
  private canvas!: HTMLCanvasElement;
  private _ctx!: CanvasRenderingContext2D;
  private _imageStore!: WMImageStore;
  private _ready!: boolean;
  private _canvasLayers!: CanvasGeoLayer[];
  private _currentnewbbox!: WMBBOX;
  private _width!: number;
  private _height!: number;
  private _webMapJS: IWMJSMap;
  private _internalCallbacks!: CanvasBufferCallbacks;
  private _animationFrameRequest: number;
  private _timerHandle: NodeJS.Timeout;
  private _errorList!: LinkedInfo[];
  private _isBufferLoading: boolean;
  private _onLoadReadyFunction!: (param: unknown) => void;

  private sharedImagesList: SharedImageListType = {};

  constructor(
    webMapJS: IWMJSMap,
    w: number,
    h: number,
    internalCallbacks: CanvasBufferCallbacks,
  ) {
    this.canvas = document.createElement('canvas');
    this.canvas.width = w;
    this.canvas.height = h;
    this._ctx = this.canvas.getContext('2d')!;
    this._ctx.canvas.width = w;
    this._ctx.canvas.height = h;

    this._imageStore = webMapJS.getImageStore();
    this._ready = true;
    this._canvasLayers = [];
    this._currentnewbbox = undefined!;

    this._width = w;
    this._height = h;
    this._webMapJS = webMapJS;

    this._internalCallbacks = internalCallbacks;

    /* Bind methods */
    this._imageLoadComplete = this._imageLoadComplete.bind(this);
    this._finishedLoading = this._finishedLoading.bind(this);
    this._getPixelCoordFromGeoCoord =
      this._getPixelCoordFromGeoCoord.bind(this);
    this._drawCanvasGeoLayer = this._drawCanvasGeoLayer.bind(this);
    this._isImageStoreLoading = this._isImageStoreLoading.bind(this);
    this._display = this._display.bind(this);
    this._checkIfLoading = this._checkIfLoading.bind(this);
    this.getAlternativeImage = this.getAlternativeImage.bind(this);
    this.removeAlternativeImage = this.removeAlternativeImage.bind(this);
    this.addAlternativeImage = this.addAlternativeImage.bind(this);
    this.setBBOX = this.setBBOX.bind(this);
    this.destroy = this.destroy.bind(this);
    this.display = this.display.bind(this);
    this.resize = this.resize.bind(this);
    this.addLayerToCanvas = this.addLayerToCanvas.bind(this);
    this.getHTMLCanvasElement = this.getHTMLCanvasElement.bind(this);

    this._imageStore.addImageEventCallback(
      (image, id, imageEventType: WMImageEventType) => {
        if (imageEventType === WMImageEventType.Loaded) {
          this._imageLoadComplete(image);
        }
        if (imageEventType === WMImageEventType.Deleted) {
          this.removeAlternativeImage(image.getSrc());
        }
      },
      'WMCanvasBuffer',
    );
    this._errorList = [];
    this._animationFrameRequest = ANIMATIONFRAMEREQUEST_DONE;
    this._isBufferLoading = false;
    this._timerHandle = undefined!;
  }

  /**
   * Triggered by the WMImageStore when an image completes loading
   * @param image
   */
  private _imageLoadComplete(image: WMImage): void {
    if (!this._webMapJS) {
      return;
    }

    const loadingLayers = this._canvasLayers.filter((layer): boolean => {
      const image = this._imageStore.getImageForSrc(layer.imageSource);
      return !image || image.isLoaded() === false;
    });
    if (loadingLayers.length === 0) {
      this._finishedLoading();
    }

    this._webMapJS.getListener().triggerEvent('onimageload', undefined);
    this._webMapJS.getListener().triggerEvent('onimagebufferimageload', image);
    this.display();
    this._checkIfLoading();
  }

  private _drawCanvasGeoLayer(
    canvasLayer: CanvasGeoLayer,
    debug = false,
  ): void {
    const { bbox: imageBBox, opacity, imageSource } = canvasLayer;
    const image = this._imageStore.getImageForSrc(imageSource);
    if (!image || image.hasError()) {
      return;
    }
    this._ctx.globalAlpha = opacity;
    const imageElement = image.getElement();

    const mapBBox = this._currentnewbbox;
    const coord1 = this._getPixelCoordFromGeoCoord(
      { x: imageBBox.left, y: imageBBox.top },
      mapBBox,
    );
    const coord2 = this._getPixelCoordFromGeoCoord(
      { x: imageBBox.right, y: imageBBox.bottom },
      mapBBox,
    );

    const imageX = coord1.x;
    const imageY = coord1.y;
    const imageW = coord2.x - coord1.x;
    const imageH = coord2.y - coord1.y;

    try {
      this._ctx.drawImage(imageElement, imageX, imageY, imageW, imageH);
      if (debug) {
        this._ctx.lineWidth = 1;
        this._ctx.strokeStyle = '#0000ff';
        this._ctx.beginPath();
        this._ctx.rect(imageX, imageY, imageW, imageH);
        this._ctx.stroke();
      }
      if (enableConsoleDebugging) {
        /* Enable this to draw the edges of the image */
        this._ctx.lineWidth = 0.2;
        this._ctx.strokeStyle = '#0000ff';
        this._ctx.beginPath();
        this._ctx.rect(imageX, imageY, imageW, imageH);
        this._ctx.stroke();
      }
    } catch (e) {
      debugLogger(DebugType.Error, `image error: ${e}`);
    }
  }

  private _display(): void {
    if (!this._ready) {
      return;
    }
    this._ready = false;
    // Frame is displayed, so reset animationFrameRequest back to default.
    this._animationFrameRequest = ANIMATIONFRAMEREQUEST_DONE;
    this._ctx.globalAlpha = 1;
    this._ctx.fillStyle = 'white';
    this._ctx.beginPath();
    this._ctx.fillRect(0, 0, this._width, this._height);

    this._ctx.fill();
    this._webMapJS
      .getListener()
      .triggerEvent('beforecanvasstartdraw', this._ctx);
    if (
      this._internalCallbacks &&
      this._internalCallbacks.beforecanvasstartdraw
    ) {
      this._internalCallbacks.beforecanvasstartdraw(this._ctx);
    }
    /* Reset errors */
    this._errorList.length = 0;

    this._canvasLayers.forEach((layer) => {
      const imageToDisplay = this._imageStore.getImageForSrc(layer.imageSource);
      if (imageToDisplay?.isLoadedWithoutErrors()) {
        // Draw this image, it is loaded and OK
        this._drawCanvasGeoLayer(layer);
      } else {
        /**
         * Check if this image has an error.
         * If the error has occured some time ago, and the amount of retries is low, just retry to load the image.
         */
        if (
          imageToDisplay?.hasError() &&
          imageToDisplay.isLoading() === false &&
          imageToDisplay.getLastErrorMSecondsAgo() > 5000 &&
          imageToDisplay.getNumFailedAttempts() < 10
        ) {
          imageToDisplay.forceReload();
        }
        /* Find closest alternative and display instead image */
        const ims = this.getAlternativeImage(
          layer.imageSource,
          this._currentnewbbox,
        );
        if (ims.length > 0) {
          this._drawCanvasGeoLayer(ims[0]);
        }
      }
    });
    this._ctx.globalAlpha = 1;

    /* Display errors */
    if (this._errorList.length > 0) {
      this._webMapJS
        .getListener()
        .triggerEvent('canvasonerror', this._errorList);
      this._internalCallbacks?.canvasonerror?.(this._errorList);
    }
    this._webMapJS.getListener().triggerEvent('beforecanvasdisplay', this._ctx);

    this._internalCallbacks?.beforecanvasdisplay?.(this._ctx);

    this.canvas.style.display = 'inline-block';
    this._webMapJS.getListener().triggerEvent('aftercanvasdisplay', this._ctx);
    this._internalCallbacks?.aftercanvasdisplay?.(this._ctx);

    this._ready = true;
  }

  private _finishedLoading(): void {
    if (this._ready) {
      return;
    }
    this._ready = true;

    for (const layer of this._canvasLayers) {
      const image = this._imageStore.getImageForSrc(layer.imageSource);
      if (image && image.hasError()) {
        debugLogger(DebugType.Error, `image error: ${image.getSrc()}`);
      }
    }
    try {
      if (isDefined(this._onLoadReadyFunction)) {
        this._onLoadReadyFunction(this);
      }
    } catch (e) {
      debugLogger(
        DebugType.Error,
        `Exception in Divbuffer::finishedLoading: ${e}`,
      );
    }
  }

  private _checkIfLoading(): void {
    const isLoading = this._isImageStoreLoading();
    if (!isLoading && this._isBufferLoading) {
      this._isBufferLoading = false;
      this._webMapJS.getListener().triggerEvent('onloadready');
    } else if (isLoading && !this._isBufferLoading) {
      this._isBufferLoading = true;
      this._webMapJS.getListener().triggerEvent('onloadstart');
    }
  }

  private _isImageStoreLoading(): boolean {
    return this._imageStore.getNumImagesLoading() !== 0;
  }

  private _getPixelCoordFromGeoCoord(
    coordinates: WMPosition,
    b: WMBBOX,
  ): WMPosition {
    const x = (this._width * (coordinates.x - b.left)) / (b.right - b.left);
    const y = (this._height * (coordinates.y - b.top)) / (b.bottom - b.top);
    return { x, y };
  }

  /**
   * Made accessible for testing purposes
   * @returns
   */
  public getSharedImagesList(): SharedImageListType {
    return this.sharedImagesList;
  }

  /**
   * Function to find the an alternative available image for an image url that is not yet loaded
   * @param imageUrl The image source / url to find an alternative for
   * @param imageStore The imagestore where the other images are located
   * @param bbox The boundingbox to search this image for
   * @param filterUnsuitableAlternatives When true, images which have an overlap or ratio less then given threshold will be discarded as alternative
   * @returns A canvasLayer, containing the new image source with its geo properties
   */
  public getAlternativeImage(
    imageUrl: string,
    bbox: CanvasBBOX,
    filterUnsuitableAlternatives = false,
  ): CanvasGeoLayer[] {
    const sigImageUrl = makeQueryStringWithoutGeoInfo(imageUrl);
    if (!this.sharedImagesList[sigImageUrl]) {
      this.sharedImagesList[sigImageUrl] = [];
    }
    const imagesForUrl: CanvasGeoLayer[] = this.sharedImagesList[sigImageUrl];
    getSortedListOfImages(this.sharedImagesList[sigImageUrl], bbox);

    const foundAltImages = imagesForUrl.filter((altImage: CanvasGeoLayer) => {
      // Images which are more then MIN_FRACTION_OF_OVERLAP smaller then previous will be discarded
      if (
        filterUnsuitableAlternatives &&
        altImage?.areaFraction! < MIN_FRACTION_OF_OVERLAP
      ) {
        return false;
      }
      // Only use images which are already loaded
      const cachedImage = this._imageStore.getImageForSrc(altImage.imageSource);
      return cachedImage?.isLoadedWithoutErrors();
    });
    return foundAltImages;
  }

  /**
   * Removes the image from the alternative imageslist (in case imagestore cache gets cleaned), this used when the imagestore triggers a delete event for an image
   * @param imageUrl The url for the image to remove
   */
  public removeAlternativeImage(imageUrl: string): void {
    const sigImageUrl = makeQueryStringWithoutGeoInfo(imageUrl);
    const imagesForUrl: CanvasGeoLayer[] = this.sharedImagesList[sigImageUrl];
    if (!imagesForUrl || imagesForUrl.length === 0) {
      return;
    }
    const indexToRemove = imagesForUrl.findIndex(
      (image) => image.imageSource === imageUrl,
    );
    if (indexToRemove >= 0) {
      imagesForUrl.splice(indexToRemove, 1);
    }
    if (imagesForUrl.length === 0) {
      delete this.sharedImagesList[sigImageUrl];
    }
  }

  /**
   * Add an image to the sharedimages list
   * @param newLayer A canvaslayer object to add.
   */
  public addAlternativeImage(newLayer: CanvasGeoLayer): void {
    /* Get a signature for images which belong to each other */
    const sigImageUrl = makeQueryStringWithoutGeoInfo(newLayer.imageSource);

    /* Make an entry for these images in the sharedImagesList */
    this.sharedImagesList[sigImageUrl] ??= [];

    /* Get the images for this signature */
    const imagesForUrl: CanvasGeoLayer[] = this.sharedImagesList[sigImageUrl];
    const indexForExisting = imagesForUrl.findIndex(
      (image) => image.imageSource === newLayer.imageSource,
    );
    if (indexForExisting === -1) {
      imagesForUrl.push(newLayer);
    } else {
      imagesForUrl[indexForExisting] = newLayer;
    }
  }

  /**
   * Used to tell the canvas that the map is resized,
   * @param w
   * @param h
   * @returns
   */
  public resize(w: string, h: string): void {
    const width = parseInt(w, 10);
    const height = parseInt(h, 10);
    if (this._width === width && this._height === height) {
      return;
    }
    this._width = width;
    this._height = height;
    this.canvas.width = width;
    this.canvas.height = height;
    this._ctx.canvas.height = height;
    this._ctx.canvas.width = width;
  }

  /**
   * Reset when a new set of layers have been added
   */
  public initCanvasLayers(): void {
    this._canvasLayers.length = 0;
  }

  /**
   * Set's a layer inside the canvasbuffer with given index and imagesource.
   * The image is directly loaded, but if loading is not complete when drawing, an alternative image is search in the alternative images list
   * @param layerIndex The layer index, similar to z-Index, 0 drawn on the bottom, higher numbers on top
   * @param imageSource The url of the image, usually a GetMap url
   * @param linkedInfo A linkedinfo object to track properties for the image and layer, like errors
   * @param opacity Opacity of this layer
   * @param bbox The boundingbox for this layer
   * @returns Void
   */
  public addLayerToCanvas(
    layerIndex: number,
    imageSource: string,
    linkedInfo: LinkedInfoContent,
    opacity: number,
    bbox: WMBBOX,
  ): void {
    if (!isDefined(imageSource)) {
      debugLogger(DebugType.Warning, 'Image source is not set');
      return;
    }

    /* HTTP Headers for the image, necessary in case of certain authentication forms */
    const headers: Headers[] = linkedInfo?.layer?.headers ?? [];

    /* Make a new CanvasLayer */
    const newLayer: CanvasGeoLayer = {
      imageSource,
      imageAge: getCurrentImageTime(),
      opacity,
      bbox: {
        left: bbox.left,
        right: bbox.right,
        top: bbox.top,
        bottom: bbox.bottom,
      },
      width: this._width,
      height: this._height,
      areaFraction: 1,
      intersect: 1,
      areaFractionClosest: 1,
    };

    /* Build same layers array as map layers array */
    while (layerIndex >= this._canvasLayers.length) {
      this._canvasLayers.push(newLayer);
    }
    this._canvasLayers[layerIndex] = newLayer;

    /* Track this layer in the alternative images list */
    this.addAlternativeImage(newLayer);

    /* Get the image with this imagesource from the imagestore and load it */
    const image = this._imageStore.getImage(imageSource, {
      headers,
    });
    if (!image.isLoaded() && !image.isLoading()) {
      image.load();
    }
    this._checkIfLoading();
  }

  public display(): void {
    if (!this._webMapJS) {
      return;
    }
    // Check if the response from window.requestAnimationFrame is set. If this is the case, debounce the next display.
    if (this._animationFrameRequest !== ANIMATIONFRAMEREQUEST_DONE) {
      clearTimeout(this._timerHandle);
      this._timerHandle = setTimeout(() => {
        if (this._webMapJS) {
          this._display();
        }
      }, 100);
      return;
    }
    // Display the next frame based on window.requestAnimationFrame, e.g. when the screen is ready to display.
    this._animationFrameRequest = window.requestAnimationFrame(() => {
      if (this._webMapJS) {
        this._display();
      }
    });
  }

  setBBOX(newbbox: WMBBOX): void {
    this._currentnewbbox = newbbox;
    this.display();
  }

  /**
   * Used when the map is destroyed
   */
  destroy(): void {
    this.sharedImagesList = null!;
    clearTimeout(this._timerHandle);
    cancelAnimationFrame(this._animationFrameRequest);
    this._webMapJS = null!;
  }

  getHTMLCanvasElement(): HTMLCanvasElement {
    return this.canvas;
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  snackbarReducer,
  snackbarListener,
  SnackbarModuleStore,
} from '@opengeoweb/snackbar';
import {
  layerReducer,
  uiReducer,
  drawingToolReducer,
  layersListener,
  drawingToolListener,
  WebMapStateModuleState,
  UIModuleState,
  DrawtoolModuleStore,
} from '@opengeoweb/store';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import {
  publicWarningFormReducer,
  publicWarningFormListener,
} from './publicWarningForm';
import {
  publicWarningsReducer,
  publicWarningsListener,
} from './publicWarnings';
import { WarningModuleStore } from './types';
import {
  warningsDrawingsReducer,
  warningsDrawingsListener,
} from './warningsDrawings';

export const warningsReducersMap = {
  layers: layerReducer,
  ui: uiReducer,
  drawingtools: drawingToolReducer,
  drawings: warningsDrawingsReducer,
  publicWarningForm: publicWarningFormReducer,
  publicWarnings: publicWarningsReducer,
  snackbar: snackbarReducer,
};

export const warningsMiddlewares = [
  layersListener.middleware,
  drawingToolListener.middleware,
  warningsDrawingsListener.middleware,
  publicWarningsListener.middleware,
  publicWarningFormListener.middleware,
  snackbarListener.middleware,
];

const warningsRootReducer = combineReducers(warningsReducersMap);

export const createMockStore = (
  mockState?:
    | WebMapStateModuleState
    | DrawtoolModuleStore
    | UIModuleState
    | WarningModuleStore
    | SnackbarModuleStore,
): ToolkitStore =>
  configureStore({
    reducer: warningsRootReducer,
    preloadedState: mockState,
    middleware: warningsMiddlewares,
  });

/*  _____ NOTE --> We should use the middlwares like this down below, but as it will be more strict, it exposes errors from the code itself, which won´t be fixed in this MR ______
   middleware: (getDefaultMiddleware) =>
     getDefaultMiddleware().concat(warningsMiddlewares),
*/

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { syncGroupsActions, syncGroupsTypes } from '@opengeoweb/store';

import {
  WorkspaceAddViewPayload,
  WorkspaceDeleteViewPayload,
  WorkspaceState,
  WorkspaceSetPresetPayload,
  WorkspaceSetPreventCloseViewPayload,
  WorkspaceUpdateViewPayload,
  WorkspaceUpdateViewsPayload,
  WorkspaceErrorWorkspacePayload,
  WorkspaceFetchedWorkspacePayload,
  WorkspaceFetchWorkspacePayload,
  WorkspaceFetchWorkspaceViewPresetPayload,
  WorkspacePresetAction,
  WorkspaceSaveWorkspacePresetPayload,
  WorkspaceViewType,
  WorkspaceSetNewViewPayload,
  WorkspaceChangeToEmptyMapWorkspacePayload,
} from './types';
import { workspaceListActions } from '../workspaceList/reducer';
import { getViewType, makeListOfViewIds } from './utils';
import { viewPresetActions } from '../viewPresets/reducer';
import {
  PresetAction,
  SelectViewPresetPayload,
  SetActiveViewPresetIdPayload,
  ViewPreset,
} from '../viewPresets/types';
import { getEmptyMapWorkspace } from '../workspaceList/utils';

export const initialState: WorkspaceState = {
  id: '',
  title: '',
  views: {
    byId: {},
    allIds: [],
  },
  mosaicNode: undefined!,
  isLoading: false,
  error: undefined,
  hasChanges: false,
};

const workspacePresetChangeActions = [
  viewPresetActions.selectViewPreset,
  viewPresetActions.setActiveViewPresetId,
  syncGroupsActions.syncGroupRemoveGroup,
  syncGroupsActions.syncGroupAddGroup,
  syncGroupsActions.syncGroupToggleIsTimeScrollingEnabled,
  syncGroupsActions.updateLinkedMap,
];

const slice = createSlice({
  initialState,
  name: 'workspace',
  reducers: {
    addWorkspaceView: (
      draft,
      action: PayloadAction<WorkspaceAddViewPayload>,
    ) => {
      const newId = action.payload.id;
      draft.views.allIds.push(newId);
      draft.views.byId![newId] = {
        componentType: action.payload.componentType,
        initialProps: action.payload.initialProps,
      };
      draft.hasChanges = true;
      draft.viewType = getViewType(
        draft.views.allIds.length,
        action.payload.componentType,
      );
    },
    setNewWorkspaceView: (
      // eslint-disable-next-line no-unused-vars
      draft,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<WorkspaceSetNewViewPayload>,
    ) => {},
    deleteWorkspaceView: (
      draft,
      action: PayloadAction<WorkspaceDeleteViewPayload>,
    ) => {
      const { id } = action.payload;
      draft.views.allIds = draft.views.allIds.filter((viewId) => viewId !== id);
      delete draft.views.byId![id];
      draft.hasChanges = true;

      // Determine new viewType
      const firstViewId = draft.views.allIds[0];
      const viewComponentType =
        draft.views.byId![firstViewId] !== undefined
          ? draft.views.byId![firstViewId].componentType
          : '';
      const newViewType = getViewType(
        draft.views.allIds.length,
        viewComponentType,
      );
      if (newViewType !== undefined) {
        draft.viewType = newViewType;
      } else {
        delete draft.viewType;
      }
    },
    updateWorkspaceViews: (
      draft,
      action: PayloadAction<WorkspaceUpdateViewsPayload>,
    ) => {
      draft.mosaicNode = action.payload.mosaicNode;

      const mosaicViewIds: string[] = makeListOfViewIds(
        action.payload.mosaicNode,
      );

      /* Delete all view id's which are still in the redux state but not in the mosaic */
      draft.views.allIds.forEach((id) => {
        if (!mosaicViewIds.includes(id)) {
          draft.views.allIds = draft.views.allIds.filter(
            (viewId) => viewId !== id,
          );
          delete draft.views.byId![id];
        }
      });
      draft.hasChanges = true;

      // Determine new viewType
      const firstViewId = draft.views.allIds[0];
      const viewComponentType =
        draft.views.byId![firstViewId] !== undefined
          ? draft.views.byId![firstViewId].componentType
          : '';
      const newViewType = getViewType(
        draft.views.allIds.length,
        viewComponentType,
      );
      if (newViewType !== undefined) {
        draft.viewType = newViewType;
      } else {
        delete draft.viewType;
      }
    },

    updateWorkspaceView: (
      draft,
      action: PayloadAction<WorkspaceUpdateViewPayload>,
    ) => {
      const {
        payload: { mosaicNodeId, viewPreset },
      } = action;
      draft.views.byId![mosaicNodeId] = viewPreset;
    },

    changePreset: (draft, action: PayloadAction<WorkspaceSetPresetPayload>) => {
      const { workspacePreset } = action.payload;
      // reset state on change preset
      draft.views.allIds = [];
      draft.views.byId = {};
      draft.mosaicNode = '';
      draft.id = '';
      draft.title = workspacePreset.title;
      draft.abstract = workspacePreset.abstract;
      draft.scope = workspacePreset.scope;
      draft.syncGroups = [];
    },

    changeToEmptyMapWorkspace: (
      draft,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<WorkspaceChangeToEmptyMapWorkspacePayload>,
    ) => {
      return draft;
    },

    setPreset: (draft, action: PayloadAction<WorkspaceSetPresetPayload>) => {
      // do not change anything when the preset is already set
      if (draft.id === action.payload.workspacePreset.id) {
        return draft;
      }

      const newViews = cloneDeep(action.payload.workspacePreset.views);

      // check if any viewIds are duplicate within the selected preset
      const duplicateIdWithinPreset = newViews.allIds.filter(
        (item, index) => newViews.allIds.indexOf(item) !== index,
      );

      if (duplicateIdWithinPreset.length) {
        console.warn('Invalid preset: duplicate viewId found.');
        return initialState;
      }

      draft.views = newViews;
      if (action.payload.workspacePreset.syncGroups) {
        draft.syncGroups = cloneDeep(action.payload.workspacePreset.syncGroups);
      } else {
        draft.syncGroups = [];
      }
      draft.id = action.payload.workspacePreset.id;
      draft.title = action.payload.workspacePreset.title;
      draft.mosaicNode = action.payload.workspacePreset.mosaicNode;
      draft.minimumPaneSizePercentage =
        action.payload.workspacePreset.minimumPaneSizePercentage;
      draft.hasChanges = false;
      draft.scope = action.payload.workspacePreset.scope;
      draft.viewType = action.payload.workspacePreset.viewType;
      draft.isTimeScrollingEnabled =
        action.payload.workspacePreset.isTimeScrollingEnabled;

      return draft;
    },
    setPreventCloseView: (
      draft,
      action: PayloadAction<WorkspaceSetPreventCloseViewPayload>,
    ) => {
      const { viewId, shouldPreventClose } = action.payload;
      draft.views.byId![viewId] = {
        ...draft.views.byId![viewId],
        shouldPreventClose,
      };
    },

    // workspaces
    fetchWorkspace: (
      draft,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<WorkspaceFetchWorkspacePayload>,
    ) => {
      draft.isLoading = true;
      draft.error = undefined;
    },
    loadedWorkspace: (
      draft,
      action: PayloadAction<WorkspaceFetchedWorkspacePayload>,
    ) => {
      draft.isLoading = false;

      if (action.payload?.workspace) {
        const { workspace } = action.payload;
        draft.abstract = workspace?.abstract;
        draft.title = workspace?.title;
      }
    },
    errorWorkspace: (
      draft,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<WorkspaceErrorWorkspacePayload>,
    ) => {
      const { error } = action.payload;
      draft.isLoading = false;
      draft.error = error;
    },
    fetchWorkspaceViewPreset: (
      // eslint-disable-next-line no-unused-vars
      draft,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<WorkspaceFetchWorkspaceViewPresetPayload>,
    ) => {},
    saveWorkspacePreset: (
      draft,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<WorkspaceSaveWorkspacePresetPayload>,
    ) => {
      draft.isLoading = true;
      draft.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        workspaceListActions.onSuccessWorkspacePresetAction,
        (draft, action) => {
          if (
            action.payload.action === WorkspacePresetAction.SAVE_AS ||
            action.payload.action ===
              WorkspacePresetAction.SAVE_SYSTEM_PRESET ||
            action.payload.action ===
              WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS ||
            action.payload.action === WorkspacePresetAction.SAVE
          ) {
            draft.id = action.payload.workspaceId;
            draft.title = action.payload.title;
            draft.abstract = action.payload.abstract;
            draft.hasChanges = false;
            draft.scope = action.payload.scope || 'user';
          }
          if (action.payload.action === WorkspacePresetAction.EDIT) {
            draft.title = action.payload.title;
            draft.abstract = action.payload.abstract;
          }
          // If current selected workspace is deleted
          // Remove the id, rename to empty workspace and set it has changes
          if (
            (action.payload.action === WorkspacePresetAction.DELETE ||
              action.payload.action ===
                WorkspacePresetAction.DELETE_SYSTEM_PRESET) &&
            action.payload.workspaceId === draft.id
          ) {
            delete draft.id;
            draft.title = action.payload.emptyWorkspacePresetTitle;
            draft.abstract = getEmptyMapWorkspace().abstract;
            draft.scope = getEmptyMapWorkspace().scope;
            draft.hasChanges = true;
          }
        },
      )
      .addCase(viewPresetActions.fetchedViewPreset, (draft, action) => {
        const { panelId, viewPreset } = action.payload;
        const entity = draft.views.byId![panelId];

        if (entity) {
          Object.keys(viewPreset).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore  - For some reason the entity has a type of never, which prevents access with key
            entity[key as keyof WorkspaceViewType] =
              viewPreset[key as keyof ViewPreset]!;
          });
        }
      })
      .addCase(viewPresetActions.onSuccessViewPresetAction, (draft, action) => {
        const {
          panelId,
          title,
          action: presetAction,
          scope,
          abstract,
        } = action.payload;
        const entity = draft.views.byId![panelId];

        if (entity && presetAction !== PresetAction.DELETE) {
          entity.title = title;
          entity.scope = scope || 'user';
          entity.abstract = abstract;
        }
      });

    workspacePresetChangeActions.map((action) =>
      builder.addCase(action, (draft, payload) => {
        const isResetViewPreset =
          action === viewPresetActions.selectViewPreset &&
          (payload.payload as SelectViewPresetPayload)?.shouldReset;

        const isSyncGroupAction =
          action === syncGroupsActions.syncGroupAddGroup ||
          action === syncGroupsActions.syncGroupToggleIsTimeScrollingEnabled;

        const isUserOrigin =
          isSyncGroupAction &&
          (payload.payload as syncGroupsTypes.SyncGroupAddGroupPayload)
            ?.origin === 'user';

        const hasChanges =
          (!isResetViewPreset && !isSyncGroupAction) || isUserOrigin;
        draft.hasChanges = hasChanges;

        // reset title and scope when setting empty activeViewPresetId
        if (action.type === viewPresetActions.setActiveViewPresetId.type) {
          const { panelId, viewPresetId } =
            payload.payload as SetActiveViewPresetIdPayload;
          const entity = draft.views.byId![panelId];
          if (entity && viewPresetId === '') {
            entity.title = '';
            entity.scope = 'system';
            entity.abstract = '';
          }
        }
      }),
    );
  },
});

// eslint-disable-next-line prefer-destructuring
export const workspaceReducer: Reducer<WorkspaceState> = slice.reducer;
export const workspaceActions = { ...slice.actions };

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { LinearProgress, Typography } from '@mui/material';
import { AlertBanner } from '@opengeoweb/shared';
import { useDispatch } from 'react-redux';
import { useWorkspaceTranslation } from '../../utils/i18n';
import { workspaceActions } from '../../store/workspace/reducer';
import { WorkspaceViewPresetComponentsLookupType } from '../../store/workspace/types';

export interface WorkspaceComponentsLookUpPayload {
  componentType: string | WorkspaceViewPresetComponentsLookupType;
  viewPresetId: string;
  mosaicNodeId: string;
}

interface WorkspaceComponentsLookUpComponent {
  viewPresetId: string;
}

export const ViewLoading: React.FC<WorkspaceComponentsLookUpComponent> = ({
  viewPresetId,
}: WorkspaceComponentsLookUpComponent) => {
  const { t } = useWorkspaceTranslation();

  return (
    <>
      <LinearProgress
        data-testid="loading-bar"
        color="secondary"
        sx={{ position: 'absolute', width: '100%', top: 0 }}
      />
      <Typography sx={{ margin: 1 }}>
        {t('workspace-loading')} {viewPresetId}
      </Typography>
    </>
  );
};

interface WorkspaceComponentsLookUpComponentError
  extends WorkspaceComponentsLookUpComponent {
  mosaicNodeId: string;
}

export const ViewError: React.FC<WorkspaceComponentsLookUpComponentError> = ({
  viewPresetId,
  mosaicNodeId,
}: WorkspaceComponentsLookUpComponentError) => {
  const dispatch = useDispatch();
  const { t } = useWorkspaceTranslation();

  const fetchWorkspaceViewPreset = React.useCallback((): void => {
    dispatch(
      workspaceActions.fetchWorkspaceViewPreset({
        viewPresetId,
        mosaicNodeId,
      }),
    );
  }, [dispatch, viewPresetId, mosaicNodeId]);

  return (
    <AlertBanner
      title={t('workspace-viewpresets-fail-fetch', {
        VIEWPRESET_ID: viewPresetId,
      })}
      shouldClose
      actionButtonProps={{
        title: t('workspace-retry'),
        onClick: fetchWorkspaceViewPreset,
      }}
    />
  );
};

export const defaultComponentsLookUp = ({
  componentType,
  viewPresetId,
  mosaicNodeId,
}: WorkspaceComponentsLookUpPayload): React.ReactElement => {
  switch (componentType) {
    case 'ViewLoading':
      return <ViewLoading viewPresetId={viewPresetId} />;
    case 'ViewError':
      return (
        <ViewError viewPresetId={viewPresetId} mosaicNodeId={mosaicNodeId} />
      );

    default:
      return null!;
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
// Source: http://codes.wmo.int/49-2/_AerodromePresentOrForecastWeather
export enum WeatherPhenomena {
  // weather phenomena from http://codes.wmo.int/49-2/_AerodromePresentOrForecastWeather
  '+DS' = '+DS', // 'Heavy duststorm'
  '+DZ' = '+DZ', // 'Heavy precipitation of drizzle'
  '+DZPL' = '+DZPL', // 'Heavy precipitation of drizzle and ice pellets'
  '+DZPLRA' = '+DZPLRA', // 'Heavy precipitation of drizzle, ice pellets and rain'
  '+DZRA' = '+DZRA', // 'Heavy precipitation of drizzle and rain'
  '+DZRAPL' = '+DZRAPL', // 'Heavy precipitation of drizzle, rain and ice pellets'
  '+DZRASG' = '+DZRASG', // 'Heavy precipitation of drizzle, rain and snow grains'
  '+DZRASN' = '+DZRASN', // 'Heavy precipitation of drizzle, rain and snow'
  '+DZSG' = '+DZSG', // 'Heavy precipitation of drizzle and snow grains'
  '+DZSGRA' = '+DZSGRA', // 'Heavy precipitation of drizzle, snow grains and rain'
  '+DZSN' = '+DZSN', // 'Heavy precipitation of drizzle and snow'
  '+DZSNRA' = '+DZSNRA', // 'Heavy precipitation of drizzle, snow and rain'
  '+FZDZ' = '+FZDZ', // 'Heavy precipitation of freezing drizzle'
  '+FZDZRA' = '+FZDZRA', // 'Heavy precipitation of freezing drizzle and rain'
  '+FZRA' = '+FZRA', // 'Heavy precipitation of freezng rain'
  '+FZRADZ' = '+FZRADZ', // 'Heavy precipitation of freezing rain and drizzle'
  '+FZUP' = '+FZUP', // 'Heavy unidentified freezing precipitation'
  '+PL' = '+PL', // 'Heavy precipitation of ice pellets'
  '+PLDZ' = '+PLDZ', // 'Heavy precipitation of ice pellets and drizzle'
  '+PLDZRA' = '+PLDZRA', // 'Heavy precipitation of ice pellets, drizzle and rain'
  '+PLRA' = '+PLRA', // 'Heavy precipitation of ice pellets and rain'
  '+PLRADZ' = '+PLRADZ', // 'Heavy precipitation of ice pellets, rain and drizzle'
  '+PLRASN' = '+PLRASN', // 'Heavy precipitation of ice pellets, rain and snow'
  '+PLSG' = '+PLSG', // 'Heavy precipitation of ice pellets and snow grains'
  '+PLSGSN' = '+PLSGSN', // 'Heavy precipitation of ice pellets, snow grains and snow'
  '+PLSN' = '+PLSN', // 'Heavy precipitation of ice pellets and snow'
  '+PLSNRA' = '+PLSNRA', // 'Heavy precipitation of ice pellets, snow and rain'
  '+PLSNSG' = '+PLSNSG', // 'Heavy precipitation of ice pellets, snow and snow grains'
  '+RA' = '+RA', // 'Heavy precipitation of rain'
  '+RADZ' = '+RADZ', // 'Heavy precipitation of rain and drizzle'
  '+RADZPL' = '+RADZPL', // 'Heavy precipitation of rain, drizzle and ice pellets'
  '+RADZSG' = '+RADZSG', // 'Heavy precipitation of rain, drizzle and snow grains'
  '+RADZSN' = '+RADZSN', // 'Heavy precipitation of rain, drizzle and snow'
  '+RAPL' = '+RAPL', // 'Heavy precipitation of rain and ice pellets'
  '+RAPLDZ' = '+RAPLDZ', // 'Heavy precipitation of rain, ice pellets and drizzle'
  '+RAPLSN' = '+RAPLSN', // 'Heavy precipitation of rain, ice pellets and snow'
  '+RASG' = '+RASG', // 'Heavy precipitation of rain and snow grains'
  '+RASGDZ' = '+RASGDZ', // 'Heavy precipitation of rain, snow grains and drizzle'
  '+RASGSN' = '+RASGSN', // 'Heavy precipitation of rain, snow grains and snow'
  '+RASN' = '+RASN', // 'Heavy precipitation of rain and snow'
  '+RASNDZ' = '+RASNDZ', // 'Heavy precipitation of rain, snow and drizzle'
  '+RASNPL' = '+RASNPL', // 'Heavy precipitation of rain, snow and ice pellets'
  '+RASNSG' = '+RASNSG', // 'Heavy precipitation of rain, snow and snow grains'
  '+SG' = '+SG', // 'Heavy precipitation of snow grains'
  '+SGDZ' = '+SGDZ', // 'Heavy precipitation of snow grains and drizzle'
  '+SGDZRA' = '+SGDZRA', // 'Heavy precipitation of snow grains, drizzle and rain'
  '+SGPL' = '+SGPL', // 'Heavy precipitation of snow grains and ice pellets'
  '+SGPLSN' = '+SGPLSN', // 'Heavy precipitation of snow grains, ice pellets and snow'
  '+SGRA' = '+SGRA', // 'Heavy precipitation of snow grains and rain'
  '+SGRADZ' = '+SGRADZ', // 'Heavy precipitation of snow grains, rain and drizzle'
  '+SGRASN' = '+SGRASN', // 'Heavy precipitation of snow grains, rain and snow'
  '+SGSN' = '+SGSN', // 'Heavy precipitation of snow grains and snow'
  '+SGSNPL' = '+SGSNPL', // 'Heavy precipitation of snow grains, snow and ice pellets'
  '+SGSNRA' = '+SGSNRA', // 'Heavy precipitation of snow grains, snow and rain'
  '+SHGR' = '+SHGR', // 'Heavy showery precipitation of hail'
  '+SHGRRA' = '+SHGRRA', // 'Heavy showery precipitation of hail and rain'
  '+SHGRRASN' = '+SHGRRASN', // 'Heavy showery precipitation of hail, rain and snow'
  '+SHGRSN' = '+SHGRSN', // 'Heavy showery precipitation of hail and snow'
  '+SHGRSNRA' = '+SHGRSNRA', // 'Heavy showery precipitation of hail, snow and rain'
  '+SHGS' = '+SHGS', // 'Heavy showery precipitation of snow pellets/small hail'
  '+SHGSRA' = '+SHGSRA', // 'Heavy showery precipitation of snow pellets/small hail and rain'
  '+SHGSRASN' = '+SHGSRASN', // 'Heavy showery precipitation of snow pellets/small hail, rain and snow'
  '+SHGSSN' = '+SHGSSN', // 'Heavy showery precipitation of snow pellets/small hail and snow'
  '+SHGSSNRA' = '+SHGSSNRA', // 'Heavy showery precipitation of snow pellets/small hail, snow and rain'
  '+SHRA' = '+SHRA', // 'Heavy showery precipitation of rain'
  '+SHRAGR' = '+SHRAGR', // 'Heavy showery precipitation of rain and hail'
  '+SHRAGRSN' = '+SHRAGRSN', // 'Heavy showery precipitation of rain, hail and snow'
  '+SHRAGS' = '+SHRAGS', // 'Heavy showery precipitation of rain and snow pellets/small hail'
  '+SHRAGSSN' = '+SHRAGSSN', // 'Heavy showery precipitation of rain, snow pellets/small hail and snow'
  '+SHRASN' = '+SHRASN', // 'Heavy showery precipitation of rain and snow'
  '+SHRASNGR' = '+SHRASNGR', // 'Heavy showery precipitation of rain, snow and hail'
  '+SHRASNGS' = '+SHRASNGS', // 'Heavy showery precipitation of rain, snow and snow pellets/small hail'
  '+SHSN' = '+SHSN', // 'Heavy showery precipitation of snow'
  '+SHSNGR' = '+SHSNGR', // 'Heavy showery precipitation of snow and hail'
  '+SHSNGRRA' = '+SHSNGRRA', // 'Heavy showery precipitation of snow, hail and rain'
  '+SHSNGS' = '+SHSNGS', // 'Heavy showery precipitation of snow and snow pellets/small hail'
  '+SHSNGSRA' = '+SHSNGSRA', // 'Heavy showery precipitation of snow, snow pellets/small hail and rain'
  '+SHSNRA' = '+SHSNRA', // 'Heavy showery precipitation of snow and rain'
  '+SHSNRAGR' = '+SHSNRAGR', // 'Heavy showery precipitation of snow, rain and hail'
  '+SHSNRAGS' = '+SHSNRAGS', // 'Heavy showery precipitation of snow, rain and snow pellets/small hail'
  '+SHUP' = '+SHUP', // 'Heavy unidentified showery precipitation'
  '+SN' = '+SN', // 'Heavy precipitation of snow'
  '+SNDZ' = '+SNDZ', // 'Heavy precipitation of snow and drizzle'
  '+SNDZRA' = '+SNDZRA', // 'Heavy precipitation of snow, drizzle and rain'
  '+SNPL' = '+SNPL', // 'Heavy precipitation of snow and ice pellets'
  '+SNPLRA' = '+SNPLRA', // 'Heavy precipitation of snow, ice pellets and rain'
  '+SNPLSG' = '+SNPLSG', // 'Heavy precipitation of snow, ice pellets and snow grains'
  '+SNRA' = '+SNRA', // 'Heavy precipitation of snow and rain'
  '+SNRADZ' = '+SNRADZ', // 'Heavy precipitation of snow, rain and drizzle'
  '+SNRAPL' = '+SNRAPL', // 'Heavy precipitation of snow, rain and ice pellets'
  '+SNRASG' = '+SNRASG', // 'Heavy precipitation of snow, rain and snow grains'
  '+SNSG' = '+SNSG', // 'Heavy precipitation of snow and snow grains'
  '+SNSGPL' = '+SNSGPL', // 'Heavy precipitation of snow, snow grains and ice pellets'
  '+SNSGRA' = '+SNSGRA', // 'Heavy precipitation of snow, snow grains and rain'
  '+SS' = '+SS', // 'Heavy sandstorm'
  '+TSGR' = '+TSGR', // 'Thunderstorm with heavy precipitation of hail'
  '+TSGRRA' = '+TSGRRA', // 'Thunderstorm with heavy precipitation of hail and rain'
  '+TSGRRASN' = '+TSGRRASN', // 'Thunderstorm with heavy precipitation of hail, rain and snow'
  '+TSGRSN' = '+TSGRSN', // 'Thunderstorm with heavy precipitation of hail and snow'
  '+TSGRSNRA' = '+TSGRSNRA', // 'Thunderstorm with heavy precipitation of hail, snow and rain'
  '+TSGS' = '+TSGS', // 'Thunderstorm with heavy precipitation of snow pellets/small hail'
  '+TSGSRA' = '+TSGSRA', // 'Thunderstorm with heavy precipitation of snow pellets/small hail and rain'
  '+TSGSRASN' = '+TSGSRASN', // 'Thunderstorm with heavy precipitation of snow pellets/small hail, rain and snow'
  '+TSGSSN' = '+TSGSSN', // 'Thunderstorm with heavy precipitation of snow pellets/small hail and snow'
  '+TSGSSNRA' = '+TSGSSNRA', // 'Thunderstorm with heavy precipitation of snow pellets/small hail, snow and rain'
  '+TSRA' = '+TSRA', // 'Thunderstorm with heavy precipitation of rain'
  '+TSRAGR' = '+TSRAGR', // 'Thunderstorm with heavy precipitation of rain and hail'
  '+TSRAGRSN' = '+TSRAGRSN', // 'Thunderstorm with heavy precipitation of rain, hail and snow'
  '+TSRAGS' = '+TSRAGS', // 'Thunderstorm with heavy precipitation of rain and snow pellets/small hail'
  '+TSRAGSSN' = '+TSRAGSSN', // 'Thunderstorm with heavy precipitation of rain, snow pellets/small hail and snow'
  '+TSRASN' = '+TSRASN', // 'Thunderstorm with heavy precipitation of rain and snow'
  '+TSRASNGR' = '+TSRASNGR', // 'Thunderstorm with heavy precipitation of rain, snow and hail'
  '+TSRASNGS' = '+TSRASNGS', // 'Thunderstorm with heavy precipitation of rain, snow and snow pellets/small hail'
  '+TSSN' = '+TSSN', // 'Thunderstorm with heavy precipitation of snow'
  '+TSSNGR' = '+TSSNGR', // 'Thunderstorm with heavy precipitation of snow and hail'
  '+TSSNGRRA' = '+TSSNGRRA', // 'Thunderstorm with heavy precipitation of snow, hail and rain'
  '+TSSNGS' = '+TSSNGS', // 'Thunderstorm with heavy precipitation of snow and snow pellets/small hail'
  '+TSSNGSRA' = '+TSSNGSRA', // 'Thunderstorm with heavy precipitation of snow, snow pellets/small hail and rain'
  '+TSSNRA' = '+TSSNRA', // 'Thunderstorm with heavy precipitation of snow and rain'
  '+TSSNRAGR' = '+TSSNRAGR', // 'Thunderstorm with heavy precipitation of snow, rain and hail'
  '+TSSNRAGS' = '+TSSNRAGS', // 'Thunderstorm with heavy precipitation of snow, rain and snow pellets/small hail'
  '+TSUP' = '+TSUP', // 'Thunderstorm with heavy precipitation of unidentified precipitation'
  '+UP' = '+UP', // 'Heavy unidentified precipitation'
  '-DZ' = '-DZ', // 'Light precipitation of drizzle'
  '-DZPL' = '-DZPL', // 'Light precipitation of drizzle and ice pellets'
  '-DZPLRA' = '-DZPLRA', // 'Light precipitation of drizzle, ice pellets and rain'
  '-DZRA' = '-DZRA', // 'Light precipitation of drizzle and rain'
  '-DZRAPL' = '-DZRAPL', // 'Light precipitation of drizzle, rain and ice pellets'
  '-DZRASG' = '-DZRASG', // 'Light precipitation of drizzle, rain and snow grains'
  '-DZRASN' = '-DZRASN', // 'Light precipitation of drizzle, rain and snow'
  '-DZSG' = '-DZSG', // 'Light precipitation of drizzle and snow grains'
  '-DZSGRA' = '-DZSGRA', // 'Light precipitation of drizzle, snow grains and rain'
  '-DZSN' = '-DZSN', // 'Light precipitation of drizzle and snow'
  '-DZSNRA' = '-DZSNRA', // 'Light precipitation of drizzle, snow and rain'
  '-FZDZ' = '-FZDZ', // 'Light precipitation of freezing drizzle'
  '-FZDZRA' = '-FZDZRA', // 'Light precipitation of freezing drizzle and rain'
  '-FZRA' = '-FZRA', // 'Light precipitation of freezng rain'
  '-FZRADZ' = '-FZRADZ', // 'Light precipitation of freezing rain and drizzle'
  '-FZUP' = '-FZUP', // 'Light unidentified freezing precipitation'
  '-PL' = '-PL', // 'Light precipitation of ice pellets'
  '-PLDZ' = '-PLDZ', // 'Light precipitation of ice pellets and drizzle'
  '-PLDZRA' = '-PLDZRA', // 'Light precipitation of ice pellets, drizzle and rain'
  '-PLRA' = '-PLRA', // 'Light precipitation of ice pellets and rain'
  '-PLRADZ' = '-PLRADZ', // 'Light precipitation of ice pellets, rain and drizzle'
  '-PLRASN' = '-PLRASN', // 'Light precipitation of ice pellets, rain and snow'
  '-PLSG' = '-PLSG', // 'Light precipitation of ice pellets and snow grains'
  '-PLSGSN' = '-PLSGSN', // 'Light precipitation of ice pellets, snow grains and snow'
  '-PLSN' = '-PLSN', // 'Light precipitation of ice pellets and snow'
  '-PLSNRA' = '-PLSNRA', // 'Light precipitation of ice pellets, snow and rain'
  '-PLSNSG' = '-PLSNSG', // 'Light precipitation of ice pellets, snow and snow grains'
  '-RA' = '-RA', // 'Light precipitation of rain'
  '-RADZ' = '-RADZ', // 'Light precipitation of rain and drizzle'
  '-RADZPL' = '-RADZPL', // 'Light precipitation of rain, drizzle and ice pellets'
  '-RADZSG' = '-RADZSG', // 'Light precipitation of rain, drizzle and snow grains'
  '-RADZSN' = '-RADZSN', // 'Light precipitation of rain, drizzle and snow'
  '-RAPL' = '-RAPL', // 'Light precipitation of rain and ice pellets'
  '-RAPLDZ' = '-RAPLDZ', // 'Light precipitation of rain, ice pellets and drizzle'
  '-RAPLSN' = '-RAPLSN', // 'Light precipitation of rain, ice pellets and snow'
  '-RASG' = '-RASG', // 'Light precipitation of rain and snow grains'
  '-RASGDZ' = '-RASGDZ', // 'Light precipitation of rain, snow grains and drizzle'
  '-RASGSN' = '-RASGSN', // 'Light precipitation of rain, snow grains and snow'
  '-RASN' = '-RASN', // 'Light precipitation of rain and snow'
  '-RASNDZ' = '-RASNDZ', // 'Light precipitation of rain, snow and drizzle'
  '-RASNPL' = '-RASNPL', // 'Light precipitation of rain, snow and ice pellets'
  '-RASNSG' = '-RASNSG', // 'Light precipitation of rain, snow and snow grains'
  '-SG' = '-SG', // 'Light precipitation of snow grains'
  '-SGDZ' = '-SGDZ', // 'Light precipitation of snow grains and drizzle'
  '-SGDZRA' = '-SGDZRA', // 'Light precipitation of snow grains, drizzle and rain'
  '-SGPL' = '-SGPL', // 'Light precipitation of snow grains and ice pellets'
  '-SGPLSN' = '-SGPLSN', // 'Light precipitation of snow grains, ice pellets and snow'
  '-SGRA' = '-SGRA', // 'Light precipitation of snow grains and rain'
  '-SGRADZ' = '-SGRADZ', // 'Light precipitation of snow grains, rain and drizzle'
  '-SGRASN' = '-SGRASN', // 'Light precipitation of snow grains, rain and snow'
  '-SGSN' = '-SGSN', // 'Light precipitation of snow grains and snow'
  '-SGSNPL' = '-SGSNPL', // 'Light precipitation of snow grains, snow and ice pellets'
  '-SGSNRA' = '-SGSNRA', // 'Light precipitation of snow grains, snow and rain'
  '-SHGR' = '-SHGR', // 'Light showery precipitation of hail'
  '-SHGRRA' = '-SHGRRA', // 'Light showery precipitation of hail and rain'
  '-SHGRRASN' = '-SHGRRASN', // 'Light showery precipitation of hail, rain and snow'
  '-SHGRSN' = '-SHGRSN', // 'Light showery precipitation of hail and snow'
  '-SHGRSNRA' = '-SHGRSNRA', // 'Light showery precipitation of hail, snow and rain'
  '-SHGS' = '-SHGS', // 'Light showery precipitation of snow pellets/small hail'
  '-SHGSRA' = '-SHGSRA', // 'Light showery precipitation of snow pellets/small hail and rain'
  '-SHGSRASN' = '-SHGSRASN', // 'Light showery precipitation of snow pellets/small hail, rain and snow'
  '-SHGSSN' = '-SHGSSN', // 'Light showery precipitation of snow pellets/small hail and snow'
  '-SHGSSNRA' = '-SHGSSNRA', // 'Light showery precipitation of snow pellets/small hail, snow and rain'
  '-SHRA' = '-SHRA', // 'Light showery precipitation of rain'
  '-SHRAGR' = '-SHRAGR', // 'Light showery precipitation of rain and hail'
  '-SHRAGRSN' = '-SHRAGRSN', // 'Light showery precipitation of rain, hail and snow'
  '-SHRAGS' = '-SHRAGS', // 'Light showery precipitation of rain and snow pellets/small hail'
  '-SHRAGSSN' = '-SHRAGSSN', // 'Light showery precipitation of rain, snow pellets/small hail and snow'
  '-SHRASN' = '-SHRASN', // 'Light showery precipitation of rain and snow'
  '-SHRASNGR' = '-SHRASNGR', // 'Light showery precipitation of rain, snow and hail'
  '-SHRASNGS' = '-SHRASNGS', // 'Light showery precipitation of rain, snow and snow pellets/small hail'
  '-SHSN' = '-SHSN', // 'Light showery precipitation of snow'
  '-SHSNGR' = '-SHSNGR', // 'Light showery precipitation of snow and hail'
  '-SHSNGRRA' = '-SHSNGRRA', // 'Light showery precipitation of snow, hail and rain'
  '-SHSNGS' = '-SHSNGS', // 'Light showery precipitation of snow and snow pellets/small hail'
  '-SHSNGSRA' = '-SHSNGSRA', // 'Light showery precipitation of snow, snow pellets/small hail and rain'
  '-SHSNRA' = '-SHSNRA', // 'Light showery precipitation of snow and rain'
  '-SHSNRAGR' = '-SHSNRAGR', // 'Light showery precipitation of snow, rain and hail'
  '-SHSNRAGS' = '-SHSNRAGS', // 'Light showery precipitation of snow, rain and snow pellets/small hail'
  '-SHUP' = '-SHUP', // 'Light unidentified showery precipitation'
  '-SN' = '-SN', // 'Light precipitation of snow'
  '-SNDZ' = '-SNDZ', // 'Light precipitation of snow and drizzle'
  '-SNDZRA' = '-SNDZRA', // 'Light precipitation of snow, drizzle and rain'
  '-SNPL' = '-SNPL', // 'Light precipitation of snow and ice pellets'
  '-SNPLRA' = '-SNPLRA', // 'Light precipitation of snow, ice pellets and rain'
  '-SNPLSG' = '-SNPLSG', // 'Light precipitation of snow, ice pellets and snow grains'
  '-SNRA' = '-SNRA', // 'Light precipitation of snow and rain'
  '-SNRADZ' = '-SNRADZ', // 'Light precipitation of snow, rain and drizzle'
  '-SNRAPL' = '-SNRAPL', // 'Light precipitation of snow, rain and ice pellets'
  '-SNRASG' = '-SNRASG', // 'Light precipitation of snow, rain and snow grains'
  '-SNSG' = '-SNSG', // 'Light precipitation of snow and snow grains'
  '-SNSGPL' = '-SNSGPL', // 'Light precipitation of snow, snow grains and ice pellets'
  '-SNSGRA' = '-SNSGRA', // 'Light precipitation of snow, snow grains and rain'
  '-TSGR' = '-TSGR', // 'Thunderstorm with light precipitation of hail'
  '-TSGRRA' = '-TSGRRA', // 'Thunderstorm with light precipitation of hail and rain'
  '-TSGRRASN' = '-TSGRRASN', // 'Thunderstorm with light precipitation of hail, rain and snow'
  '-TSGRSN' = '-TSGRSN', // 'Thunderstorm with light precipitation of hail and snow'
  '-TSGRSNRA' = '-TSGRSNRA', // 'Thunderstorm with light precipitation of hail, snow and rain'
  '-TSGS' = '-TSGS', // 'Thunderstorm with light precipitation of snow pellets/small hail'
  '-TSGSRA' = '-TSGSRA', // 'Thunderstorm with light precipitation of snow pellets/small hail and rain'
  '-TSGSRASN' = '-TSGSRASN', // 'Thunderstorm with light precipitation of snow pellets/small hail, rain and snow'
  '-TSGSSN' = '-TSGSSN', // 'Thunderstorm with light precipitation of snow pellets/small hail and snow'
  '-TSGSSNRA' = '-TSGSSNRA', // 'Thunderstorm with light precipitation of snow pellets/small hail, snow and rain'
  '-TSRA' = '-TSRA', // 'Thunderstorm with light precipitation of rain'
  '-TSRAGR' = '-TSRAGR', // 'Thunderstorm with light precipitation of rain and hail'
  '-TSRAGRSN' = '-TSRAGRSN', // 'Thunderstorm with light precipitation of rain, hail and snow'
  '-TSRAGS' = '-TSRAGS', // 'Thunderstorm with light precipitation of rain and snow pellets/small hail'
  '-TSRAGSSN' = '-TSRAGSSN', // 'Thunderstorm with light precipitation of rain, snow pellets/small hail and snow'
  '-TSRASN' = '-TSRASN', // 'Thunderstorm with light precipitation of rain and snow'
  '-TSRASNGR' = '-TSRASNGR', // 'Thunderstorm with light precipitation of rain, snow and hail'
  '-TSRASNGS' = '-TSRASNGS', // 'Thunderstorm with light precipitation of rain, snow and snow pellets/small hail'
  '-TSSN' = '-TSSN', // 'Thunderstorm with light precipitation of snow'
  '-TSSNGR' = '-TSSNGR', // 'Thunderstorm with light precipitation of snow and hail'
  '-TSSNGRRA' = '-TSSNGRRA', // 'Thunderstorm with light precipitation of snow, hail and rain'
  '-TSSNGS' = '-TSSNGS', // 'Thunderstorm with light precipitation of snow and snow pellets/small hail'
  '-TSSNGSRA' = '-TSSNGSRA', // 'Thunderstorm with light precipitation of snow, snow pellets/small hail and rain'
  '-TSSNRA' = '-TSSNRA', // 'Thunderstorm with light precipitation of snow and rain'
  '-TSSNRAGR' = '-TSSNRAGR', // 'Thunderstorm with light precipitation of snow, rain and hail'
  '-TSSNRAGS' = '-TSSNRAGS', // 'Thunderstorm with light precipitation of snow, rain and snow pellets/small hail'
  '-TSUP' = '-TSUP', // 'Thunderstorm with light unidentified precipitation'
  '-UP' = '-UP', // 'Light unidentified precipitation'
  'BCFG' = 'BCFG', // 'Patches of fog'
  'BLDU' = 'BLDU', // 'Blowing dust'
  'BLSA' = 'BLSA', // 'Blowing sand'
  'BLSN' = 'BLSN', // 'Blowing snow'
  'BR' = 'BR', // 'Mist'
  'DRDU' = 'DRDU', // 'Low drifting dust'
  'DRSA' = 'DRSA', // 'Low drifting sand'
  'DRSN' = 'DRSN', // 'Low drifting snow'
  'DS' = 'DS', // 'Duststorm'
  'DU' = 'DU', // 'Dust'
  'DZ' = 'DZ', // 'Precipitation of drizzle'
  'DZPL' = 'DZPL', // 'Precipitation of drizzle and ice pellets'
  'DZPLRA' = 'DZPLRA', // 'Precipitation of drizzle, ice pellets and rain'
  'DZRA' = 'DZRA', // 'Precipitation of drizzle and rain'
  'DZRAPL' = 'DZRAPL', // 'Precipitation of drizzle, rain and ice pellets'
  'DZRASG' = 'DZRASG', // 'Precipitation of drizzle, rain and snow grains'
  'DZRASN' = 'DZRASN', // 'Precipitation of drizzle, rain and snow'
  'DZSG' = 'DZSG', // 'Precipitation of drizzle and snow grains'
  'DZSGRA' = 'DZSGRA', // 'Precipitation of drizzle, snow grains and rain'
  'DZSN' = 'DZSN', // 'Precipitation of drizzle and snow'
  'DZSNRA' = 'DZSNRA', // 'Precipitation of drizzle, snow and rain'
  'FC' = 'FC', // 'Funnel cloud(s) (tornado or water-spout)'
  'FG' = 'FG', // 'Fog'
  'FU' = 'FU', // 'Smoke'
  'FZDZ' = 'FZDZ', // 'Precipitation of freezing drizzle'
  'FZDZRA' = 'FZDZRA', // 'Precipitation of freezing drizzle and rain'
  'FZFG' = 'FZFG', // 'Freezing fog'
  'FZRA' = 'FZRA', // 'Precipitation of freezng rain'Precipitation of freezing rain'
  'FZRADZ' = 'FZRADZ', // 'Precipitation of freezing rain and drizzle'
  'FZUP' = 'FZUP', // 'Unidentified freezing precipitation'
  'HZ' = 'HZ', // 'Haze'
  'MIFG' = 'MIFG', // 'Shallow fog'
  'PL' = 'PL', // 'Precipitation of ice pellets'
  'PLDZ' = 'PLDZ', // 'Precipitation of ice pellets and drizzle'
  'PLDZRA' = 'PLDZRA', // 'Precipitation of ice pellets, drizzle and rain'
  'PLRA' = 'PLRA', // 'Precipitation of ice pellets and rain'
  'PLRADZ' = 'PLRADZ', // 'Precipitation of ice pellets, rain and drizzle'
  'PLRASN' = 'PLRASN', // 'Precipitation of ice pellets, rain and snow'
  'PLSG' = 'PLSG', // 'Precipitation of ice pellets and snow grains'
  'PLSGSN' = 'PLSGSN', // 'Precipitation of ice pellets, snow grains and snow'
  'PLSN' = 'PLSN', // 'Precipitation of ice pellets and snow'
  'PLSNRA' = 'PLSNRA', // 'Precipitation of ice pellets, snow and rain'
  'PLSNSG' = 'PLSNSG', // 'Precipitation of ice pellets, snow and snow grains'
  'PO' = 'PO', // 'Dust/sand whirls (dust devils)'
  'PRFG' = 'PRFG', // 'Partial fog (covering part of the aerodrome)'
  'RA' = 'RA', // 'Precipitation of rain'
  'RADZ' = 'RADZ', // 'Precipitation of rain and drizzle'
  'RADZPL' = 'RADZPL', // 'Precipitation of rain, drizzle and ice pellets'
  'RADZSG' = 'RADZSG', // 'Precipitation of rain, drizzle and snow grains'
  'RADZSN' = 'RADZSN', // 'Precipitation of rain, drizzle and snow'
  'RAPL' = 'RAPL', // 'Precipitation of rain and ice pellets'
  'RAPLDZ' = 'RAPLDZ', // 'Precipitation of rain, ice pellets and drizzle'
  'RAPLSN' = 'RAPLSN', // 'Precipitation of rain, ice pellets and snow'
  'RASG' = 'RASG', // 'Precipitation of rain and snow grains'
  'RASGDZ' = 'RASGDZ', // 'Precipitation of rain, snow grains and drizzle'
  'RASGSN' = 'RASGSN', // 'Precipitation of rain, snow grains and snow'
  'RASN' = 'RASN', // 'Precipitation of rain and snow'
  'RASNDZ' = 'RASNDZ', // 'Precipitation of rain, snow and drizzle'
  'RASNPL' = 'RASNPL', // 'Precipitation of rain, snow and ice pellets'
  'RASNSG' = 'RASNSG', // 'Precipitation of rain, snow and snow grains'
  'SA' = 'SA', // 'Sand'
  'SG' = 'SG', // 'Precipitation of snow grains'
  'SGDZ' = 'SGDZ', // 'Precipitation of snow grains and drizzle'
  'SGDZRA' = 'SGDZRA', // 'Precipitation of snow grains, drizzle and rain'
  'SGPL' = 'SGPL', // 'Precipitation of snow grains and ice pellets'
  'SGPLSN' = 'SGPLSN', // 'Precipitation of snow grains, ice pellets and snow'
  'SGRA' = 'SGRA', // 'Precipitation of snow grains and rain'
  'SGRADZ' = 'SGRADZ', // 'Precipitation of snow grains, rain and drizzle'
  'SGRASN' = 'SGRASN', // 'Precipitation of snow grains, rain and snow'
  'SGSN' = 'SGSN', // 'Precipitation of snow grains and snow'
  'SGSNPL' = 'SGSNPL', // 'Precipitation of snow grains, snow and ice pellets'
  'SGSNRA' = 'SGSNRA', // 'Precipitation of snow grains, snow and rain'
  'SHGR' = 'SHGR', // 'Showery precipitation of hail'
  'SHGRRA' = 'SHGRRA', // 'Showery precipitation of hail and rain'
  'SHGRRASN' = 'SHGRRASN', // 'Showery precipitation of hail, rain and snow'
  'SHGRSN' = 'SHGRSN', // 'Showery precipitation of hail and snow'
  'SHGRSNRA' = 'SHGRSNRA', // 'Showery precipitation of hail, snow and rain'
  'SHGS' = 'SHGS', // 'Showery precipitation of snow pellets/small hail'
  'SHGSRA' = 'SHGSRA', // 'Showery precipitation of snow pellets/small hail and rain'
  'SHGSRASN' = 'SHGSRASN', // 'Showery precipitation of snow pellets/small hail, rain and snow'
  'SHGSSN' = 'SHGSSN', // 'Showery precipitation of snow pellets/small hail and snow'
  'SHGSSNRA' = 'SHGSSNRA', // 'Showery precipitation of snow pellets/small hail, snow and rain'
  'SHRA' = 'SHRA', // 'Showery precipitation of rain'
  'SHRAGR' = 'SHRAGR', // 'Showery precipitation of rain and hail'
  'SHRAGRSN' = 'SHRAGRSN', // 'Showery precipitation of rain, hail and snow'
  'SHRAGS' = 'SHRAGS', // 'Showery precipitation of rain and snow pellets/small hail'
  'SHRAGSSN' = 'SHRAGSSN', // 'Showery precipitation of rain, snow pellets/small hail and snow'
  'SHRASN' = 'SHRASN', // 'Showery precipitation of rain and snow'
  'SHRASNGR' = 'SHRASNGR', // 'Showery precipitation of rain, snow and hail'
  'SHRASNGS' = 'SHRASNGS', // 'Showery precipitation of rain, snow and snow pellets/small hail'
  'SHSN' = 'SHSN', // 'Showery precipitation of snow'
  'SHSNGR' = 'SHSNGR', // 'Showery precipitation of snow and hail'
  'SHSNGRRA' = 'SHSNGRRA', // 'Showery precipitation of snow, hail and rain'
  'SHSNGS' = 'SHSNGS', // 'Showery precipitation of snow and snow pellets/small hail'
  'SHSNGSRA' = 'SHSNGSRA', // 'Showery precipitation of snow, snow pellets/small hail and rain'
  'SHSNRA' = 'SHSNRA', // 'Showery precipitation of snow and rain'
  'SHSNRAGR' = 'SHSNRAGR', // 'Showery precipitation of snow, rain and hail'
  'SHSNRAGS' = 'SHSNRAGS', // 'Showery precipitation of snow, rain and snow pellets/small hail'
  'SHUP' = 'SHUP', // 'Unidentified showery precipitation'Showery precipitation of unidentified precipitation'
  'SN' = 'SN', // 'Precipitation of snow'
  'SNDZ' = 'SNDZ', // 'Precipitation of snow and drizzle'
  'SNDZRA' = 'SNDZRA', // 'Precipitation of snow, drizzle and rain'
  'SNPL' = 'SNPL', // 'Precipitation of snow and ice pellets'
  'SNPLRA' = 'SNPLRA', // 'Precipitation of snow, ice pellets and rain'
  'SNPLSG' = 'SNPLSG', // 'Precipitation of snow, ice pellets and snow grains'
  'SNRA' = 'SNRA', // 'Precipitation of snow and rain'
  'SNRADZ' = 'SNRADZ', // 'Precipitation of snow, rain and drizzle'
  'SNRAPL' = 'SNRAPL', // 'Precipitation of snow, rain and ice pellets'
  'SNRASG' = 'SNRASG', // 'Precipitation of snow, rain and snow grains'
  'SNSG' = 'SNSG', // 'Precipitation of snow and snow grains'
  'SNSGPL' = 'SNSGPL', // 'Precipitation of snow, snow grains and ice pellets'
  'SNSGRA' = 'SNSGRA', // 'Precipitation of snow, snow grains and rain'
  'SQ' = 'SQ', // 'Squalls'
  'SS' = 'SS', // 'Sandstorm'
  'TS' = 'TS', // 'Thunderstorm'
  'TSGR' = 'TSGR', // 'Thunderstorm with precipitation of hail'
  'TSGRRA' = 'TSGRRA', // 'Thunderstorm with precipitation of hail and rain'
  'TSGRRASN' = 'TSGRRASN', // 'Thunderstorm with precipitation of hail, rain and snow'
  'TSGRSN' = 'TSGRSN', // 'Thunderstorm with precipitation of hail and snow'
  'TSGRSNRA' = 'TSGRSNRA', // 'Thunderstorm with precipitation of hail, snow and rain'
  'TSGS' = 'TSGS', // 'Thunderstorm with precipitation of snow pellets/small hail'
  'TSGSRA' = 'TSGSRA', // 'Thunderstorm with precipitation of snow pellets/small hail and rain'
  'TSGSRASN' = 'TSGSRASN', // 'Thunderstorm with precipitation of snow pellets/small hail, rain and snow'
  'TSGSSN' = 'TSGSSN', // 'Thunderstorm with precipitation of snow pellets/small hail and snow'
  'TSGSSNRA' = 'TSGSSNRA', // 'Thunderstorm with precipitation of snow pellets/small hail, snow and rain'
  'TSRA' = 'TSRA', // 'Thunderstorm with precipitation of rain'
  'TSRAGR' = 'TSRAGR', // 'Thunderstorm with precipitation of rain and hail'
  'TSRAGRSN' = 'TSRAGRSN', // 'Thunderstorm with precipitation of rain, hail and snow'
  'TSRAGS' = 'TSRAGS', // 'Thunderstorm with precipitation of rain and snow pellets/small hail'
  'TSRAGSSN' = 'TSRAGSSN', // 'Thunderstorm with precipitation of rain, snow pellets/small hail and snow'
  'TSRASN' = 'TSRASN', // 'Thunderstorm with precipitation of rain and snow'
  'TSRASNGR' = 'TSRASNGR', // 'Thunderstorm with precipitation of rain, snow and hail'
  'TSRASNGS' = 'TSRASNGS', // 'Thunderstorm with precipitation of rain, snow and snow pellets/small hail'
  'TSSN' = 'TSSN', // 'Thunderstorm with precipitation of snow'
  'TSSNGR' = 'TSSNGR', // 'Thunderstorm with precipitation of snow and hail'
  'TSSNGRRA' = 'TSSNGRRA', // 'Thunderstorm with precipitation of snow, hail and rain'
  'TSSNGS' = 'TSSNGS', // 'Thunderstorm with precipitation of snow and snow pellets/small hail'
  'TSSNGSRA' = 'TSSNGSRA', // 'Thunderstorm with precipitation of snow, snow pellets/small hail and rain'
  'TSSNRA' = 'TSSNRA', // 'Thunderstorm with precipitation of snow and rain'
  'TSSNRAGR' = 'TSSNRAGR', // 'Thunderstorm with precipitation of snow, rain and hail'
  'TSSNRAGS' = 'TSSNRAGS', // 'Thunderstorm with precipitation of snow, rain and snow pellets/small hail'
  'TSUP' = 'TSUP', // 'Thunderstorm with unidentified precipitation'
  'UP' = 'UP', // 'Unidentified precipitation'
  'VA' = 'VA', // 'Volcanic ash'
  'VCBLDU' = 'VCBLDU', // 'Blowing dust in the vicinity'
  'VCBLSA' = 'VCBLSA', // 'Blowing sand in the vicinity'
  'VCBLSN' = 'VCBLSN', // 'Blowing snow in the vicinity'
  'VCDS' = 'VCDS', // 'Duststorm in the vicinity'
  'VCFC' = 'VCFC', // 'Funnel cloud(s) (tornado or water-spout) in the vicinity'
  'VCFG' = 'VCFG', // 'Fog in the vicinity'
  'VCPO' = 'VCPO', // 'Dust/sand whirls (dust devils) in the vicinity'
  'VCSH' = 'VCSH', // 'Shower(s) in the vicinity'
  'VCSS' = 'VCSS', // 'Sandstorm in the vicinity'
  'VCTS' = 'VCTS', // 'Thunderstorm in the vicinity'
  'VCVA' = 'VCVA', // 'Volcanic ash in the vicinity'

  // Additional codes from https://codes.wmo.int/306/4678
  '+FC' = '+FC', //	Well-developed funnel cloud(s) (tornado or waterspout)
  '-DS' = '-DS', // Light duststorm
  '-SS' = '-SS', // Light sandstorm

  // Additional codes added o fmi-avi-msgconverter
  'FZRASN' = 'FZRASN', // Precipitation of freezing rain and snow
  'FZRAPL' = 'FZRAPL', // Pecipitation of freezing rain and ice pellets
  'FZRASG' = 'FZRASG', // Pecipitation of freezing rain and snow grains
  'FZDZSG' = 'FZDZSG', // Pecipitation of freezing drizzle and snow grains
  'FZDZSN' = 'FZDZSN', // Pecipitation of freezing drizzle and snow
  'FZDZPL' = 'FZDZPL', // Precipitation of freezing drizzle and ice pellets

  '-FZRASN' = '-FZRASN', // Lght precipitation of freezing rain and snow
  '-FZRAPL' = '-FZRAPL', // Lght precipitation of freezing rain and ice pellets
  '-FZRASG' = '-FZRASG', // Lght precipitation of freezing rain and snow grains
  '-FZDZSG' = '-FZDZSG', // Lght precipitation of freezing drizzle and snow grains
  '-FZDZSN' = '-FZDZSN', // Lght precipitation of freezing drizzle and snow
  '-FZDZPL' = '-FZDZPL', // Light precipitation of freezing drizzle and ice pellets

  '+FZRASN' = '+FZRASN', // Havy precipitation of freezing rain and snow
  '+FZRAPL' = '+FZRAPL', // Havy precipitation of freezing rain and ice pellets
  '+FZRASG' = '+FZRASG', // Havy precipitation of freezing rain and snow grains
  '+FZDZSG' = '+FZDZSG', // Havy precipitation of freezing drizzle and snow grains
  '+FZDZSN' = '+FZDZSN', // Havy precipitation of freezing drizzle and snow
  '+FZDZPL' = '+FZDZPL', // Heavy precipitation of freezing drizzle and ice pellets
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as React from 'react';
import {
  mapSelectors,
  mapTypes,
  CoreAppStore,
  layerTypes,
  defaultLayers,
  syncGroupsTypes,
  syncGroupsActions,
  syncConstants,
  layerActions,
  mapActions,
} from '@opengeoweb/store';
import {
  LegendConnect,
  MapViewConnect,
  TimeSliderConnect,
} from '@opengeoweb/core';

import { webmapUtils } from '@opengeoweb/webmap';
import { MapViewLayer, MapViewLayerProps } from '@opengeoweb/webmap-react';
import { srsAndBboxDefault } from './constants';

export interface MapViewGeoJsonProps {
  baseLayers?: layerTypes.Layer[];
  projection?: mapTypes.MapPreset['proj'];
  geoJSONLayers: MapViewLayerProps[];
}

const MapViewGeoJson: React.FC<MapViewGeoJsonProps> = ({
  baseLayers: initialBaseLayers,
  projection: initialProjection,
  geoJSONLayers = [],
}: MapViewGeoJsonProps) => {
  const dispatch = useDispatch();
  const mapWindowRef = React.useRef(null);

  const firstMap = useSelector(
    (store: CoreAppStore) => mapSelectors.getFirstMap(store),
    shallowEqual,
  );
  const firstMapId = firstMap && firstMap.id ? firstMap.id : '';

  const mapLayers = useSelector(
    (store: CoreAppStore) => mapSelectors.getMapLayers(store, firstMapId),
    shallowEqual,
  );
  const mapDimensions = useSelector(
    (store: CoreAppStore) => mapSelectors.getMapDimensions(store, firstMapId),
    shallowEqual,
  );

  const mapId = React.useRef(`sigmet-${webmapUtils.generateMapId()}`).current;
  const timesliderId = React.useRef(
    `sigmet-${webmapUtils.generateTimesliderId()}`,
  ).current;

  const syncGroupAddGroup = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupAddGroupPayload): void => {
      dispatch(syncGroupsActions.syncGroupAddGroup(payload));
    },
    [dispatch],
  );

  const syncGroupAddTarget = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupAddTargetPayload): void => {
      dispatch(syncGroupsActions.syncGroupAddTarget(payload));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (mapLayers && mapLayers.length) {
      // use maplayers, but change layerId and current mapId
      const layers = mapLayers.map((layer: layerTypes.Layer) => ({
        ...layer,
        id: webmapUtils.generateLayerId(),
        mapId,
      }));
      dispatch(
        layerActions.setLayers({
          mapId,
          layers,
        }),
      );
    }

    if (mapDimensions && mapDimensions.length) {
      dispatch(
        mapActions.mapChangeDimension({
          mapId,
          dimension: mapDimensions[0],
          origin: 'MapViewGeoJson',
        }),
      );
    }

    const baseLayers = initialBaseLayers || [
      { ...defaultLayers.overLayer, id: `countryborders-${mapId}` },
    ];

    dispatch(
      layerActions.setBaseLayers({
        mapId,
        layers: baseLayers,
      }),
    );

    const projection = initialProjection || srsAndBboxDefault;
    dispatch(
      mapActions.setBbox({
        mapId,
        bbox: projection.bbox,
        srs: projection.srs,
      }),
    );
    syncGroupAddGroup({
      groupId: 'Time_SigmetAirmet',
      title: 'Group 1 for sigmet/airmet map',
      type: syncConstants.SYNCGROUPS_TYPE_SETTIME,
    });

    syncGroupAddTarget({ groupId: 'Time_SigmetAirmet', targetId: mapId });
    syncGroupAddTarget({
      groupId: 'Time_SigmetAirmet',
      targetId: timesliderId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{ position: 'relative', height: '100%' }}
      data-testid="MapView"
      ref={mapWindowRef}
    >
      <LegendConnect showMapId mapId={mapId} />
      <MapViewConnect
        mapId={mapId}
        controls={{
          mapControlsPositionTop: 0,
          layerManagerAndLegend: true,
          zoomControls: true,
          dimensionSelect: true,
        }}
      >
        {geoJSONLayers.map((layer) => (
          <MapViewLayer key={layer.id} {...layer} />
        ))}
      </MapViewConnect>
      <div
        style={{
          position: 'absolute',
          left: '0',
          bottom: '0',
          zIndex: 1000,
          width: '100%',
        }}
      >
        <TimeSliderConnect
          sourceId={timesliderId}
          mapId={mapId}
          mapWindowRef={mapWindowRef}
        />
      </div>
    </div>
  );
};

export default MapViewGeoJson;

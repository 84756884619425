/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
/*
  Accepts a value and timeout and will debounce
  
  @param {string} value: value of element
  @param {timeout} number: timer 
  @return {value: string}  

  @example: 
   const debouncedName = useDebounce('testing', 2000)
*/
export const useDebounce = (value: string, timeout: number): string => {
  const [state, setState] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return (): void => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

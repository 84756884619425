/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Style } from '@opengeoweb/webmap';
import { TooltipSelect } from '@opengeoweb/shared';
import { useCoreTranslation } from '../../../../../utils/i18n';

export interface RenderStylesProps {
  layerStyles: Style[];
  currentLayerStyle: string;
  onChangeLayerStyle: (style: string) => void;
  isEnabled?: boolean;
  icon?: React.ElementType;
  tooltipPrefix?: string;
  skipLocalStyling?: boolean;
}

const RenderStyles: React.FC<RenderStylesProps> = ({
  layerStyles,
  currentLayerStyle,
  onChangeLayerStyle,
  isEnabled = true,
  icon,
  tooltipPrefix,
  skipLocalStyling,
}: RenderStylesProps) => {
  const { t } = useCoreTranslation();
  const styles: Style[] = [
    {
      title: 'default',
      name: 'default',
      abstract: '',
      legendURL: '',
    },
  ];
  if (layerStyles && layerStyles.length > 0) {
    styles.push(...layerStyles);
  }

  const nonEmptyTooltipPrefix =
    tooltipPrefix ?? `${t('layermanager-style-title')}: `;

  const layerStyleNames = layerStyles.map((style) => style.name!);

  const layerStyleExists = layerStyleNames.includes(currentLayerStyle);

  const currentStyle = layerStyleExists ? currentLayerStyle : styles[0].name;

  const selectStyle = (event: SelectChangeEvent<unknown>): void => {
    event.stopPropagation();
    onChangeLayerStyle(event.target.value as string);
  };

  const list = styles.map((style) => ({
    value: style.name,
  }));

  const currentIndex = styles.findIndex((style) => currentStyle === style.name);

  return (
    <TooltipSelect
      tooltip={`${nonEmptyTooltipPrefix}${styles[currentIndex]?.title}`}
      inputProps={{
        SelectDisplayProps: {
          'data-testid': 'selectStyle',
        },
      }}
      isEnabled={isEnabled}
      value={currentStyle}
      list={list}
      currentIndex={currentIndex}
      onChange={selectStyle}
      onChangeMouseWheel={(e): void => onChangeLayerStyle(e.value)}
      requiresCtrlToChange={true}
      IconComponent={icon}
      skipLocalStyling={skipLocalStyling}
    >
      <MenuItem disabled>{t('layermanager-style-title')}</MenuItem>
      {styles.map((styleFromlayer) => (
        <MenuItem key={styleFromlayer.name} value={styleFromlayer.name}>
          {styleFromlayer.title}
        </MenuItem>
      ))}
    </TooltipSelect>
  );
};

export default RenderStyles;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { FastForward } from '@opengeoweb/theme';
import { renderCounter } from '@opengeoweb/shared';
import { useState } from 'react';
import { TimeSliderMenu } from '../TimeSliderMenu/TimeSliderMenu';
import { useTimesliderTranslation } from '../../../utils/i18n';

const marks = [
  { value: 1, text: 'timeslider-min', count: 1 },
  { value: 2, text: 'timeslider-min', count: 2 },
  { value: 5, text: 'timeslider-min', count: 5 },
  { value: 10, text: 'timeslider-min', count: 10 },
  { value: 15, text: 'timeslider-min', count: 15 },
  { value: 30, text: 'timeslider-min', count: 30 },
  { value: 60, text: 'timeslider-hour', count: 1 },
  { value: 120, text: 'timeslider-hour', count: 2 },
  { value: 180, text: 'timeslider-hour', count: 3 },
  { value: 360, text: 'timeslider-hour', count: 6 },
  { value: 720, text: 'timeslider-hour', count: 12 },
  { value: 1440, text: 'timeslider-hour', count: 24 },
  { value: 2880, text: 'timeslider-hour', count: 48 },
  { value: 4320, text: 'timeslider-hour', count: 72 },
  { value: 10080, text: 'timeslider-week', count: 1 },
  { value: 20160, text: 'timeslider-weeks', count: 2 },
  { value: 43200, text: 'timeslider-month', count: 1 },
  { value: 87600, text: 'timeslider-months', count: 2 },
  { value: 262800, text: 'timeslider-months', count: 6 },
  { value: 525600, text: 'timeslider-year', count: 1 },
  { value: 5256000, text: 'timeslider-years', count: 10 },
].sort((a, b) => b.value - a.value);

export interface TimeStepButtonProps {
  timeStep?: number;
  disabled?: boolean;
  isTimestepAuto?: boolean;
  onChangeTimeStep: (step: number) => void;
  onToggleTimestepAuto?: () => void;
  isOpenByDefault?: boolean;
  timeStepFromLayer?: number;
}

export const TimeStepButton: React.FC<TimeStepButtonProps> = ({
  timeStep = marks[0].value,
  disabled = false,
  isTimestepAuto = false,
  onChangeTimeStep,
  onToggleTimestepAuto = (): void => {},
  isOpenByDefault = false,
  timeStepFromLayer,
}: TimeStepButtonProps) => {
  const { t } = useTimesliderTranslation();

  const translatedMarks = marks.map((mark) => ({
    ...mark,
    label: `${mark.count} ${t(mark.text)}`,
  }));

  const onMenuItemClick = (markValue: number): void => {
    if (isTimestepAuto) {
      onToggleTimestepAuto();
    }
    onChangeTimeStep(markValue);
  };

  const handleToggleTimeStep = (): void => {
    if (isTimestepAuto) {
      return;
    }
    onToggleTimestepAuto();
    timeStepFromLayer && onChangeTimeStep(timeStepFromLayer);
  };

  const layerHasTimeStep = Boolean(timeStepFromLayer);
  renderCounter.count(TimeStepButton.name);
  const [open, setOpen] = useState(isOpenByDefault);

  return (
    <TimeSliderMenu
      title={t('timeslider-time-step')}
      marks={translatedMarks}
      value={timeStep || translatedMarks[0].value}
      onChangeSliderValue={onMenuItemClick}
      allOptionsDisabled={disabled}
      isAutoSelected={isTimestepAuto}
      isAutoDisabled={!layerHasTimeStep}
      handleAutoClick={handleToggleTimeStep}
      icon={<FastForward data-testid="timeStepButton" />}
      displayCurrentLength
      open={open}
      setOpen={setOpen}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { mapTypes } from '@opengeoweb/store';
import { PlotPreset, TimeSeriesService } from '@opengeoweb/shared';
import {
  ConfigurableMapConnect,
  ConfigurableMapConnectProps,
} from '../ConfigurableMapConnect';
import {
  HarmonieTempAndPrecipPreset,
  InitialHarmTempAndPrecipProps,
} from '../MultiMapViewConnect/HarmoniePresets';
import {
  ModelRunIntervalProps,
  ModelRunInterval,
} from '../MultiMapViewConnect/ModelRunInterval';
import {
  MultiMapPreset,
  MultiMapViewConnect,
} from '../MultiMapViewConnect/MultiMapViewConnect';
import { TimeSliderConnect } from '../TimeSliderConnect';

interface InitialMultiMapProps {
  mapPreset: mapTypes.MapPreset[];
  syncGroupsIds: string[];
  shouldShowZoomControls?: boolean;
}

interface InitialSliderProps {
  sliderPreset: { mapId: string };
  syncGroupsIds: string[];
}

export interface InitialMapProps {
  mapPreset: mapTypes.MapPreset;
  syncGroupsIds: string[];
  mapControls?: React.ReactNode;
  shouldDisplayDrawControls?: boolean;
  displaySearchButtonInMap?: boolean;
}

export interface InitialTimeSeriesProps {
  plotPreset: PlotPreset;
  services: TimeSeriesService[];
}

export type InitialProps =
  | InitialMapProps
  | InitialMultiMapProps
  | InitialHarmTempAndPrecipProps
  | ModelRunIntervalProps
  | mapTypes.MapPresetInitialProps
  | InitialSliderProps
  | InitialTimeSeriesProps
  | Record<string, unknown>;

/**
 * The lookup table is for registering your own components with the workspace.
 * @param payload
 * @returns
 */

export type SupportedComponentTypes =
  | 'Map'
  | 'MultiMap'
  | 'ModelRunInterval'
  | 'HarmonieTempAndPrecipPreset'
  | 'TimeSlider';

export interface ComponentsLookUpPayload {
  componentType: SupportedComponentTypes;
  id: string;
  title?: string;
  initialProps: InitialProps;
}

export const componentsLookUp = ({
  componentType,
  initialProps,
  id,
}: ComponentsLookUpPayload): React.ReactElement => {
  switch (componentType) {
    case 'Map': {
      const {
        mapPreset,
        mapControls,
        shouldDisplayDrawControls,
        displaySearchButtonInMap,
      } = initialProps as InitialMapProps;
      return (
        <ConfigurableMapConnect
          {...(mapPreset as ConfigurableMapConnectProps)}
          id={id}
          data-testid="coreConfigurableMapConnect"
          displayGetFeatureInfoButtonInMap
          shouldDisplayDrawControls={shouldDisplayDrawControls}
          displaySearchButtonInMap={displaySearchButtonInMap}
          mapControls={mapControls}
        />
      );
    }
    case 'MultiMap': {
      const { mapPreset, shouldShowZoomControls, syncGroupsIds } =
        initialProps as InitialMultiMapProps;

      if (!mapPreset || mapPreset.length === 0) {
        return null!;
      }

      return (
        <MultiMapViewConnect
          rows={2}
          cols={2}
          showZoomControls={shouldShowZoomControls}
          maps={mapPreset.map((preset, index) => {
            const { layers, autoUpdateLayerId, autoTimeStepLayerId } = preset;
            return {
              id: `${id}_${index}`,
              syncGroupsIds,
              layers,
              autoUpdateLayerId,
              autoTimeStepLayerId,
              bbox: mapPreset[0]?.proj?.bbox,
              srs: mapPreset[0]?.proj?.srs,
              componentType: 'MultiMap',
            } as MultiMapPreset;
          })}
          data-testid="coreMultiMapViewConnect"
        />
      );
    }
    case 'HarmonieTempAndPrecipPreset': {
      const { layers } = initialProps as InitialHarmTempAndPrecipProps;
      return (
        <HarmonieTempAndPrecipPreset
          layers={layers || null}
          data-testid="coreHarmonieTempAndPrecipPreset"
        />
      );
    }
    case 'ModelRunInterval': {
      return (
        <ModelRunInterval
          {...(initialProps as ModelRunIntervalProps)}
          data-testid="coreModelRunInterval"
        />
      );
    }

    case 'TimeSlider': {
      const { sliderPreset } = initialProps as InitialSliderProps;
      return (
        <TimeSliderConnect
          {...sliderPreset}
          sourceId={id}
          data-testid="coreTimeSliderConnect"
        />
      );
    }

    default:
      return null!;
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  uiSelectors,
  uiTypes,
  CoreAppStore,
  drawingToolActions,
  useSetupDialog,
} from '@opengeoweb/store';
import {
  currentlySupportedDrawModes,
  DrawMode,
} from '@opengeoweb/webmap-react';
import { TFunction } from 'i18next';
import DrawingToolDialog from './DrawingToolDialog';
import { useCloseDrawDialog } from './useObjectDrawDialogAction';
import { DrawingToolFormConnect } from '../DrawingToolForm';
import { drawingDialogType } from '../../store/warningsDrawings/utils';
import { useWarningsTranslation } from '../../utils/i18n';

export const getDialogType = (mapId: string, isMultiMap: boolean): string => {
  if (isMultiMap) {
    return `${uiTypes.DialogTypes.DrawingTool}-${mapId}`;
  }
  return uiTypes.DialogTypes.DrawingTool;
};

export const translateDrawModes = (
  drawModes: DrawMode[],
  translateFn: TFunction,
): DrawMode[] =>
  drawModes.map((drawMode) => ({
    ...drawMode,
    title: translateFn(drawMode.title),
  }));

interface DrawingToolConnectProps {
  mapId?: string;
  bounds?: string;
  title?: string;
  showMapIdInTitle?: boolean;
  isMultiMap?: boolean;
  source?: uiTypes.Source;
}

const DrawingToolConnect: React.FC<DrawingToolConnectProps> = ({
  bounds,
  title,
  showMapIdInTitle = false,
  mapId: initialMapId = null!,
  isMultiMap = false,
  source = 'app',
}: DrawingToolConnectProps) => {
  const { t } = useWarningsTranslation();
  const dialogType = getDialogType(initialMapId, isMultiMap);
  const activeMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );
  // In case of a multimap, use the map id that is passed
  // For floating drawingtoolbox, use the currently active, selected mapid
  const mapId = initialMapId || activeMapId;

  const { closeDrawDialog } = useCloseDrawDialog({
    mapId,
    source,
  });
  const { dialogOrder, setDialogOrder, isDialogOpen, uiSource, uiIsLoading } =
    useSetupDialog(dialogType, source);

  const defaultTitle = title || t('drawing-tool-title');
  const shownTitle = showMapIdInTitle
    ? `${defaultTitle} ${mapId}`
    : defaultTitle;

  const onClose = (): void => {
    closeDrawDialog();
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    const defaultDrawModes = translateDrawModes(currentlySupportedDrawModes, t);

    dispatch(
      drawingToolActions.registerDrawTool({
        drawToolId: drawingDialogType,
        defaultDrawModes,
      }),
    );

    return (): void => {
      dispatch(
        drawingToolActions.unregisterDrawTool({
          drawToolId: drawingDialogType,
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DrawingToolDialog
      bounds={bounds}
      isOpen={isDialogOpen}
      onClose={onClose}
      title={shownTitle}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      source={uiSource}
      isLoading={uiIsLoading}
      headerSize="xs"
      startPosition={{ top: 134, left: 50 }}
    >
      <DrawingToolFormConnect />
    </DrawingToolDialog>
  );
};

export default DrawingToolConnect;

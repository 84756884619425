/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { VisibilityOff, Visibility } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { useDispatch } from 'react-redux';

import { layerActions, layerTypes } from '@opengeoweb/store';
import { useCoreTranslation } from '../../../../../utils/i18n';

interface EnableLayerProps {
  layerId: string;
  mapId: string;
  isEnabled?: boolean;
  layerName?: string;
  icon?: React.ReactNode;
  tooltipTitle?: string;
}

interface LayerChangeEnabledParams {
  layerId: string;
  enabled: boolean;
}

const EnableLayerConnect: React.FC<EnableLayerProps> = ({
  layerId,
  mapId,
  isEnabled = false,
  layerName = '',
  icon = isEnabled ? <Visibility /> : <VisibilityOff />,
  tooltipTitle,
}: EnableLayerProps) => {
  const dispatch = useDispatch();
  const { t } = useCoreTranslation();

  const layerChangeEnabled = React.useCallback(
    ({ layerId, enabled }: LayerChangeEnabledParams) =>
      dispatch(
        layerActions.layerChangeEnabled({
          layerId,
          enabled,
          mapId,
          origin: layerTypes.LayerActionOrigin.layerManager,
        }),
      ),
    [dispatch, mapId],
  );

  const normalTooltip = tooltipTitle ?? t('layermanager-layer-toggle');
  const miniTooltip = tooltipTitle ?? layerName;

  return (
    <>
      <CustomIconButton
        shouldShowAsDisabled={!isEnabled}
        tooltipTitle={normalTooltip}
        onClick={(event): void => {
          event.stopPropagation();
          layerChangeEnabled({
            layerId,
            enabled: !isEnabled,
          });
        }}
        className="enable-btn"
        data-testid="enableButton"
        sx={{ margin: 'auto 0px' }}
      >
        {icon}
      </CustomIconButton>

      <CustomIconButton
        shouldShowAsDisabled={!isEnabled}
        tooltipTitle={miniTooltip!}
        onClick={(event): void => {
          event.stopPropagation();
          layerChangeEnabled({
            layerId,
            enabled: !isEnabled,
          });
        }}
        className="enable-btn-mini"
        data-testid="enableButton"
        sx={{ margin: 'auto 0px' }}
      >
        {icon}
      </CustomIconButton>
    </>
  );
};

/**
 * Allows you to show/hide a layer on the map
 *
 * Expects the following props:
 * @param {string} layerId layerId: string - Id of the layer that is toggled
 * @example
 * ``` <EnableLayerConnect layerId={layerId} />```
 */
export default EnableLayerConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';

export const showPrompt = (event: Event): void => {
  event.preventDefault();
};

export function usePreventBrowserClose(shouldPreventClose: boolean): void {
  React.useEffect(() => {
    if (shouldPreventClose) {
      window.addEventListener('beforeunload', showPrompt);
    } else {
      window.removeEventListener('beforeunload', showPrompt);
    }
    return (): void => {
      window.removeEventListener('beforeunload', showPrompt);
    };
  }, [shouldPreventClose]);
}

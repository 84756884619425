/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { getLinkedMaps, selectSharedData } from './selectors';
import { PotentialData } from './syncGroups/types';
import { syncGroupsActions } from './syncGroups';
import { CoreAppStore } from '../types';

export const useUpdateSharedData = (
  data: PotentialData,
  panelId?: string,
): PotentialData => {
  const sharedData = useSelector((state: CoreAppStore) =>
    selectSharedData(state, panelId!),
  );
  const linkedMaps = useSelector((store) => getLinkedMaps(store, panelId!));
  const dispatch = useDispatch();

  useEffect(() => {
    const diff = { ...sharedData, ...data };
    if (panelId && linkedMaps?.length > 0 && !isEqual(diff, sharedData)) {
      dispatch(
        syncGroupsActions.addSharedData({
          panelId,
          data,
        }),
      );
    }
  }, [panelId, linkedMaps, data, sharedData, dispatch]);

  useEffect(() => {
    return (): void => {
      if (panelId) {
        dispatch(syncGroupsActions.deleteSharedData({ panelId }));
      }
    };
  }, [dispatch, panelId]);

  return sharedData;
};

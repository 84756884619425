/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { AlertBanner } from '@opengeoweb/shared';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGeowebTranslation } from '../../i18n';

const NotFound: React.FC = () => {
  const { t } = useGeowebTranslation();
  return (
    <AlertBanner
      severity="info"
      title={t('geoweb-404-error-message')}
      info={
        <p>
          {t('geoweb-info-go-back')}{' '}
          <Link to="/"> {t('geoweb-info-go-back-to-app')}</Link>
        </p>
      }
    />
  );
};

export default NotFound;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layers } from '@opengeoweb/theme';
import {
  uiActions,
  uiTypes,
  uiSelectors,
  CoreAppStore,
} from '@opengeoweb/store';
import { MapControlButton } from '@opengeoweb/webmap-react';
import { useCoreTranslation } from '../../utils/i18n';

interface LayerManagerMapButtonConnectProps {
  mapId: string;
  source?: uiTypes.Source;
  isMultiMap?: boolean;
}

const LayerManagerMapButtonConnect: React.FC<
  LayerManagerMapButtonConnectProps
> = ({
  mapId,
  source = 'app',
  isMultiMap = false,
}: LayerManagerMapButtonConnectProps) => {
  const { t } = useCoreTranslation();
  const dispatch = useDispatch();
  const dialogType = isMultiMap
    ? `${uiTypes.DialogTypes.LayerManager}-${mapId}`
    : uiTypes.DialogTypes.LayerManager;
  const currentActiveMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, dialogType),
  );

  const openLayerManagerDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: dialogType,
        mapId,
        setOpen: currentActiveMapId !== mapId ? true : !isOpenInStore,
        source,
      }),
    );
  }, [currentActiveMapId, dialogType, dispatch, isOpenInStore, mapId, source]);

  const isOpen = currentActiveMapId === mapId && isOpenInStore;

  return (
    <MapControlButton
      title={t('layermanager-title')}
      data-testid="layerManagerButton"
      onClick={openLayerManagerDialog}
      isActive={isOpen}
    >
      <Layers />
    </MapControlButton>
  );
};

export default LayerManagerMapButtonConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid, MenuItem, FormControl } from '@mui/material';
import {
  CustomIconButton,
  PROJECTION,
  TooltipSelect,
} from '@opengeoweb/shared';
import { Bbox } from '@opengeoweb/webmap';
import { WorldMapProjection } from '@opengeoweb/theme';
import { columnClasses, leftButtonsStyle } from '../LayerManagerUtils';
import { useCoreTranslation } from '../../../utils/i18n';

export interface ProjectionDetails {
  name: string;
  value: string;
  bbox: Bbox;
}

export const availableProjections: ProjectionDetails[] = [
  {
    ...PROJECTION.EPSG_3857,
    bbox: {
      left: -1342329.1465696818,
      bottom: 3920707.9160590963,
      right: 4520696.671030318,
      top: 11588870.593609098,
    },
  },
  {
    ...PROJECTION.EPSG_3575,
    bbox: {
      left: -6974291.292427451,
      bottom: -4143517.10430627,
      right: 7160410.554092634,
      top: 3403105.194804561,
    },
  },
  {
    ...PROJECTION.EPSG_3411,
    bbox: {
      left: -8586591.790921725,
      bottom: -6905418.5308475215,
      right: 11520691.230089426,
      top: 3830009.2177224844,
    },
  },
  {
    ...PROJECTION.EPSG_3412,
    bbox: {
      left: -23805428.64174738,
      bottom: -13538921.571034307,
      right: 24880766.607377376,
      top: 12454999.681458568,
    },
  },
  {
    ...PROJECTION.EPSG_32661,
    bbox: {
      left: -3078812.468202346,
      bottom: -5551735.866551964,
      right: 9359725.15673533,
      top: 1089291.7566655697,
    },
  },
  {
    ...PROJECTION.EPSG_54030,
    bbox: {
      left: -2020532.0269369134,
      bottom: 4102671.9257380506,
      right: 3149912.1784659545,
      top: 6863210.489656053,
    },
  },
  {
    ...PROJECTION.EPSG_28992,
    bbox: {
      left: -875225.6439854703,
      bottom: -36779.36485252716,
      right: 1356591.6745628114,
      top: 1154804.4779891823,
    },
  },
  {
    ...PROJECTION.EPSG_3067,
    bbox: {
      left: -1197402.0,
      bottom: 6439686.0,
      right: 2090705,
      top: 7975202,
    },
  },
];

export interface ProjectionSelectProps {
  currentProjection: string;
  onChangeProjection: (projection: ProjectionDetails) => void;
}

const ProjectionSelect: React.FC<ProjectionSelectProps> = ({
  currentProjection,
  onChangeProjection,
}: ProjectionSelectProps) => {
  const { t } = useCoreTranslation();
  const selectProjection = (newProj: string): void => {
    const newProjDetails = availableProjections.find(
      (proj) => proj.value === newProj,
    );
    onChangeProjection(newProjDetails!);
  };

  const index = availableProjections.findIndex(
    (projection) => projection.value === currentProjection,
  );
  const currentProjectionObject = availableProjections.find(
    (projection) => projection.value === currentProjection,
  );

  const title = t('projection-title');

  return (
    <Grid
      container
      className="setProjection-column"
      sx={{
        width: '100%',
        height: '36px',
      }}
    >
      <Grid sx={[{ marginTop: '-2px' }, leftButtonsStyle]}>
        <CustomIconButton
          sx={{ margin: 'auto 0px auto 32px' }}
          disableRipple
          disabled
        >
          <WorldMapProjection />
        </CustomIconButton>
      </Grid>
      <Grid className={columnClasses.baseLayer}>
        <FormControl style={{ width: '100%' }}>
          <TooltipSelect
            disableUnderline
            SelectDisplayProps={{ 'aria-label': 'projectionSelect' }}
            tooltip={`${title}: ${currentProjectionObject?.name}`}
            isEnabled
            style={{ maxWidth: '100%' }}
            value={currentProjection}
            list={availableProjections}
            currentIndex={index}
            onChange={(event): void => {
              event.stopPropagation();
              selectProjection(event.target.value as string);
            }}
            onChangeMouseWheel={(e): void => selectProjection(e.value)}
            requiresCtrlToChange={true}
          >
            <MenuItem disabled>{title}</MenuItem>
            {availableProjections.map((projection: ProjectionDetails) => (
              <MenuItem key={projection.name} value={projection.value}>
                {projection.name}
              </MenuItem>
            ))}
          </TooltipSelect>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ProjectionSelect;

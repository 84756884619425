/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { compact } from 'lodash';
import { dateUtils } from '@opengeoweb/shared';

import {
  defaultTimeSpan,
  defaultTimeStep,
  defaultAnimationDelayAtStart,
  defaultSecondsPerPx,
  roundWithTimeStep,
} from '@opengeoweb/timeslider';
import { produce } from 'immer';
import type { Bbox, MapPreset, WebMap, WebMapState } from './types';
import type { CoreAppStore } from '../../types';
import { layerSelectors } from '../layer';
import { uiSelectors } from '../../ui';
import { genericSelectors } from '../../generic';
import type {
  Dimension,
  Layer,
  ReduxLayer,
  WebMapAnimationList,
} from '../types';
import {
  dateFormat,
  findMapIdFromLayerId,
  generateAnimationList,
  getAnimationDuration,
  getSpeedFactor,
} from './utils';
import { selectorMemoizationOptions } from '../../utils';
import type { uiTypes } from '../../ui';
import { filterCurrentValueFromDimensions } from '../layer/utils';

const getMapStore = (store: CoreAppStore): WebMapState | undefined =>
  store && store.webmap;

/**
 * Gets the map state by mapId
 *
 * Example: getMapById(store, 'mapid_1')
 * @param {object} store store object from which the map state wll be extracted
 * @param {string} mapId Id of the map
 * @returns {object} object containing map state (isAnimating, bbox, baseLayers, layers etc.)
 */

export const getMapById = createSelector(
  [
    getMapStore,
    (getMapStore: CoreAppStore | undefined, mapId: string): string => mapId,
  ],
  (mapState: WebMapState | undefined, mapId: string): WebMap | undefined => {
    return mapState && mapState.byId[mapId]
      ? mapState && mapState.byId[mapId]
      : undefined;
  },
  selectorMemoizationOptions,
);

export const getAllMapsByIds = createSelector(
  getMapStore,
  (mapState: WebMapState | undefined): Record<string, WebMap> => {
    return mapState && mapState.byId ? mapState && mapState.byId : {};
  },
  selectorMemoizationOptions,
);

/**
 * Gets all mapIds
 *
 * Example: getAllMapIds(store)
 * @param {object} store store object from which the map state wll be extracted
 * @returns {array} array containing all map ids
 */
export const getAllMapIds = createSelector(
  getMapStore,
  (mapState): string[] => (mapState && mapState.allIds ? mapState.allIds : []),
  selectorMemoizationOptions,
);

/**
 * Gets the map state of the first map in the store
 *
 * Example: getFirstMap(store)
 * @param {object} store store object from which the map state wll be extracted
 * @returns {object} object containing map state (isAnimating, bbox, baseLayers, layers etc.)
 */
export const getFirstMap = createSelector(
  getAllMapIds,
  getAllMapsByIds,
  (allMapIds, mapsById) =>
    allMapIds[0] && mapsById[allMapIds[0]] ? mapsById[allMapIds[0]] : null,
  selectorMemoizationOptions,
);

/**
 * Gets the id of first map in store
 *
 * Example: getFirstMapId(store)
 * @param {object} store store: object from which the map state wll be extracted
 * @returns {string} returnType:string - map id
 */
export const getFirstMapId = createSelector(
  getFirstMap,
  (map) => (map ? map.id : ''),
  selectorMemoizationOptions,
);

/**
 * Determines if map is present
 *
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType:boolean - true if map is present
 */
export const getIsMapPresent = createSelector(
  getMapById,
  (map): boolean => !!map,
  selectorMemoizationOptions,
);

/**
 * Gets all layerIds for a map that aren't baselayers or overlayers
 *
 * Example: layerIds = getLayerIds(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array -  array containing all layerIds
 */
export const getLayerIds = createSelector(
  getMapById,
  (map) => map?.mapLayers ?? [],
  selectorMemoizationOptions,
);

/**
 * Gets all layer states for a map
 *
 * Example: layers = getMapLayers(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array -  array containing all layer states for the map
 */
export const getMapLayers = createSelector(
  getLayerIds,
  layerSelectors.getLayersById,
  (layerIdsInMap, allLayers) => {
    const layersWithUndefined = layerIdsInMap.map(
      (layerId) => allLayers?.[layerId],
    );
    const layersWithoutUndefined = compact(layersWithUndefined);
    return layersWithoutUndefined;
  },
  selectorMemoizationOptions,
);

/**
 * Gets all layer states for a map, but where Dimensions have no currentValue set
 *
 * Use this instead of getMapLayers to prevent unnecessary re-renders
 *
 * Example: layers = getMapLayers(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array -  array containing all layer states for the map
 */
export const getMapLayersWithoutDimensionCurrentValue = createSelector(
  getLayerIds,
  layerSelectors.getLayersById,
  (layerIdsInMap, allLayers) => {
    const layersWithUndefined = layerIdsInMap.map(
      (layerId) => allLayers?.[layerId],
    );
    const layersWithoutUndefined = compact(layersWithUndefined);
    const layersWithoutTimeDim = layersWithoutUndefined.map((layer) =>
      produce(layer, (draftLayer: ReduxLayer): ReduxLayer => {
        draftLayer.dimensions = filterCurrentValueFromDimensions(
          draftLayer.dimensions || [],
        );
        return draftLayer;
      }),
    );
    return layersWithoutTimeDim;
  },
  selectorMemoizationOptions,
);

/**
 * Gets an array of baselayers ids for a map
 *
 * Example: baseLayersId = getMapBaseLayersIds(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array -  array containing an array of baselayers ids
 */
export const getMapBaseLayersIds = createSelector(
  getMapById,
  (store) => (store && store.baseLayers ? store.baseLayers : []),
  selectorMemoizationOptions,
);

/**
 * Gets all baselayers for a map
 *
 * Example: baseLayers = getMapBaseLayers(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array -  array containing all baselayers for the map
 */
export const getMapBaseLayers = createSelector(
  getMapBaseLayersIds,
  layerSelectors.getLayersById,
  (layerIds, layers) => layerIds.map((layerId) => layers![layerId]),
  selectorMemoizationOptions,
);

/**
 * Gets and array of overLayers ids for a map
 *
 * Example: overLayersId = getMapOverLayersIds(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array -  array containing an array of overLayers ids
 */
export const getMapOverLayersIds = createSelector(
  getMapById,
  (store) => (store ? store.overLayers : []),
  selectorMemoizationOptions,
);

export const getMapFeatureLayers = createSelector(
  getMapById,
  layerSelectors.getFeatureLayers,
  (store, featureLayers) => {
    const featureLayersOnMap = store?.featureLayers || [];
    return featureLayers.filter(
      (layer) => featureLayersOnMap.indexOf(layer.id!) !== -1,
    );
  },
  selectorMemoizationOptions,
);

/**
 * Gets all overLayers for a map
 *
 * Example: overLayers = getMapOverLayers(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array -  array containing all overLayers for the map
 */
export const getMapOverLayers = createSelector(
  getMapOverLayersIds,
  layerSelectors.getLayersById,
  (layerIds, layers) => layerIds.map((layerId) => layers![layerId]),
  selectorMemoizationOptions,
);

/**
 * Gets map dimensions
 *
 * Example: mapDimensions = getMapDimensions(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {array} returnType: array - array containing map dimensions
 */
export const getMapDimensions = createSelector(
  getMapById,
  (webMap: WebMap | undefined) => (webMap ? webMap.dimensions : []),
  selectorMemoizationOptions,
);

/**
 * Gets the map dimension requested
 *
 * Example: mapDimensions = getMapDimension(store, 'mapid_1', 'elevation')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} dimensionName dimensionName: string - name of the dimension
 * @returns {object} returnType: object - object containing the map dimension details
 */
export const getMapDimension = createSelector(
  [
    getMapById,
    (_store, mapId): string => mapId,
    (_store, _mapId, dimensionName: string): string => dimensionName,
  ],
  (mapState, _mapId, dimensionName): Dimension | undefined => {
    if (mapState && mapState.dimensions) {
      return mapState.dimensions.find((dim) => dim.name === dimensionName);
    }
    return undefined;
  },
  selectorMemoizationOptions,
);

/**
 * Returns the current time in unix time for the given map
 *
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {object} returnType: unix time as number
 */
export const getSelectedTime = createSelector(
  (store: CoreAppStore, mapId: string) => getMapDimension(store, mapId, 'time'),
  (timeDimension): number => {
    const now = dateUtils.unix(new Date());
    if (!timeDimension || !timeDimension.currentValue) {
      return now;
    }

    const timeSliderTime = dateUtils.parseISO(timeDimension.currentValue);
    if (dateUtils.isValid(timeSliderTime)) {
      return dateUtils.unix(timeSliderTime);
    }
    return now;
  },
  selectorMemoizationOptions,
);

export const getDataLimitsFromLayers = createSelector(
  getMapLayers,
  (layers) =>
    layers.reduce(
      ([start, end], layer) => {
        const dimension = layer.dimensions?.find(
          (dimension) => dimension.name === 'time',
        );
        if (dimension?.minValue && dimension.maxValue) {
          const lastValue = dateUtils.unix(new Date(dimension.maxValue));
          const firstValue = dateUtils.unix(new Date(dimension.minValue));

          const newLast = Math.max(lastValue, end);
          const newFirst = Math.min(firstValue, start);

          return [newFirst, newLast];
        }
        return [start, end];
      },
      /* Using the maximum 32-bit value and 0 as starting points
       * bigger values like Number.MAX_VALUE or Number.MAX_SAFE_INTEGER
       * cause weird behaviour as timestamps break at 32bit limit (year 2038)
       */
      [2147483647, 0],
    ),
  selectorMemoizationOptions,
);

/**
 * Gets map srs
 *
 * Example: mapSrs = getSrs(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {string} returnType: string -  string containing srs
 */
export const getSrs = createSelector(
  getMapById,
  (store) => (store ? store.srs : ''),
  selectorMemoizationOptions,
);

/**
 * Gets map bounding box
 *
 * Example: mapBbox = getBbox(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {object} returnType: object -  boundingbox object {left: number, bottom: number, right:number, top: number}
 */
export const getBbox = createSelector(
  getMapById,
  (store) => (store ? store.bbox : ({} as Bbox)),
  selectorMemoizationOptions,
);

/**
 * Gets if map is animating
 *
 * Example: mapIsAnimating = isAnimating(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isAnimating = createSelector(getMapById, (store) =>
  store ? store.isAnimating : false,
);

/**
 * Gets if any linked map is animating
 *
 * Example: linkedMapIsAnimating = linkedMapAnimationInfo(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {object} returnType: object - object containing isAnimating boolean and id string
 */
export const linkedMapAnimationInfo = createSelector(
  (store: CoreAppStore, mapId: string) => {
    const animationInfo = {
      isAnimating: isAnimating(store, mapId),
      id: mapId,
    };
    genericSelectors
      .getSynchronizationGroupStore(store)
      .groups.allIds.forEach((groupId) => {
        if (
          genericSelectors.getSynchronizationGroupStore(store).groups.byId[
            groupId
          ].targets.byId[animationInfo.id] &&
          genericSelectors.getSynchronizationGroupStore(store).groups.byId[
            groupId
          ].targets.byId[animationInfo.id].linked
        ) {
          genericSelectors
            .getSynchronizationGroupStore(store)
            .groups.byId[groupId].targets.allIds.forEach((id) => {
              if (isAnimating(store, id) === true) {
                animationInfo.isAnimating = true;
                animationInfo.id = id;
              }
            });
        }
      });
    return animationInfo;
  },
  (animationInfo) => animationInfo,
  selectorMemoizationOptions,
);

/**
 * Gets start time of animation
 *
 * Example: endTimeOfAnimetion = getAnimationStartTime(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {string} returnType: string
 */
export const getAnimationStartTime = createSelector(
  getMapById,
  (store) =>
    store?.animationStartTime
      ? store.animationStartTime
      : dateUtils.dateToString(
          dateUtils.sub(dateUtils.utc(), { hours: 6 }),
          dateFormat,
        ),
  selectorMemoizationOptions,
);

/**
 * Gets end time of animation
 *
 * Example: endTimeOfAnimation = getAnimationEndTime(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {string} returnType: string
 */
export const getAnimationEndTime = createSelector(
  getMapById,
  (store) =>
    store?.animationEndTime
      ? store.animationEndTime
      : dateUtils.dateToString(
          dateUtils.sub(dateUtils.utc(), { minutes: 10 }),
          dateFormat,
        ),
  selectorMemoizationOptions,
);

/**
 * Returns map is auto updating
 *
 * Example: isAutoUpdating = isAutoUpdating(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isAutoUpdating = createSelector(
  getMapById,
  (store) => (store ? store.isAutoUpdating : false),
  selectorMemoizationOptions,
);

/**
 * Gets if endTime and duration of animationPayload are overriding maxValue and minValue of leading layer
 *
 * Example: shouldEndtimeOverride = shouldEndtimeOverride(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const shouldEndtimeOverride = createSelector(
  getMapById,
  (store) => (store ? store.shouldEndtimeOverride : false),
  selectorMemoizationOptions,
);

/**
 * Gets activeLayerId for map
 *
 * example: activeLayerId = getActiveLayerId(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 */
export const getActiveLayerId = createSelector(
  getMapById,
  (store) => {
    if (
      store?.autoUpdateLayerId &&
      store.autoUpdateLayerId === store.autoTimeStepLayerId
    ) {
      return store.autoUpdateLayerId;
    }
    return undefined;
  },
  selectorMemoizationOptions,
);

/**
 * Gets autoUpdateLayerId for map
 *
 * example: autoUpdateLayerId = getAutoUpdateLayerId(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 */
export const getAutoUpdateLayerId = createSelector(
  getMapById,
  (store) => store?.autoUpdateLayerId,
  selectorMemoizationOptions,
);

/**
 * Gets autoUpdateLayerId for map
 *
 * example: autoUpdateLayerId = getAutoUpdateLayerId(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 */
export const getAutoTimeStepLayerId = createSelector(
  getMapById,
  (store) => store?.autoTimeStepLayerId,
  selectorMemoizationOptions,
);

/**
 * Gets span of a time slider of a map
 *
 * Example: span = getSpan(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {Span} returnType: Span -  span as an enum
 */
export const getMapTimeSliderSpan = createSelector(
  getMapById,
  (store) => (store ? store.timeSliderSpan : defaultTimeSpan),
  selectorMemoizationOptions,
);

/**
 * Gets time step of a map
 *
 * Example: timeStep = getTimeStep(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {number} returnType: number - time step as a number
 */
export const getMapTimeStep = createSelector(
  getMapById,
  (store) => store?.timeStep ?? defaultTimeStep,
  selectorMemoizationOptions,
);

export const getMapTimeStepWithoutDefault = createSelector(
  getMapById,
  (store) => store?.timeStep,
  selectorMemoizationOptions,
);

/**
 * Returns the speed of animation
 *
 * Example: speed = getSpeed(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {number} returnType: number - speed as a number
 */
export const getMapAnimationDelay = createSelector(
  getMapById,
  (store) => (store ? store.animationDelay : defaultAnimationDelayAtStart),
  selectorMemoizationOptions,
);

/**
 * Returns the width of time slider
 *
 * Example: timeSliderWith = getMapTimeSliderWidth(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {number} returnType: number - center time as a unix timestamp
 */
export const getMapTimeSliderWidth = createSelector(
  getMapById,
  (store) => (store ? store.timeSliderWidth : 0),
  selectorMemoizationOptions,
);

/**
 * Returns the center time of time slider
 *
 * Example: centerTime = getCenterTime(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {number} returnType: number - center time as a unix timestamp
 */
export const getMapTimeSliderCenterTime = createSelector(
  getMapById,
  (store) =>
    store ? store.timeSliderCenterTime : dateUtils.unix(dateUtils.utc()),
  selectorMemoizationOptions,
);

/**
 * Returns the number of seconds per pixel on the time slider
 *
 * Example: secondsPerPx = getSecondsPerPx(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {number} returnType: number - the number of seconds per pixel
 */
export const getMapTimeSliderSecondsPerPx = createSelector(
  getMapById,
  (store) => (store ? store.timeSliderSecondsPerPx : defaultSecondsPerPx),
  selectorMemoizationOptions,
);

/**
 * Returns map is timestep auto
 *
 * Example: isTimestepAuto = isTimestepAuto(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isTimestepAuto = createSelector(
  getMapById,
  (store) => (store ? store.isTimestepAuto : false),
  selectorMemoizationOptions,
);

/**
 * Returns map is animation auto
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isAnimationLengthAuto = createSelector(
  getMapById,
  (store) => store?.isAnimationLengthAuto || false,
  selectorMemoizationOptions,
);

/**
 * Returns map is timespan auto
 *
 * Example: isTimeSpanAuto = isTimeSpanAuto(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isTimeSpanAuto = createSelector(
  getMapById,
  (store) => (store ? store.isTimeSpanAuto : false),
  selectorMemoizationOptions,
);

/**
 * Returns map is time slider hover
 *
 * Example: isTimeSliderHoverOn = isTimeSliderHoverOn(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isTimeSliderHoverOn = createSelector(
  getMapById,
  (store) => (store ? store.isTimeSliderHoverOn : false),
  selectorMemoizationOptions,
);

/**
 * Returns map if zoomcontrols are visible
 *
 * Example: isZoomControlsVisible = isZoomControlsVisible(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isZoomControlsVisible = createSelector(
  getMapById,
  (store) => (store ? store.shouldShowZoomControls : true),
  selectorMemoizationOptions,
);

/**
 * Returns map is time slider visible
 *
 * Example: isTimeSliderHoverOn = isTimeSliderVisible(store, 'mapid_1')
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const isTimeSliderVisible = createSelector(
  getMapById,
  (store) => (store ? store.isTimeSliderVisible : true),
  selectorMemoizationOptions,
);

/**
 * Returns is layer is active layer
 *
 * Example: isLayerActiveLayer = getIsLayerActiveLayer(store, 'mapid_1', 'layer_1)
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} mapId layerId: string - Id of the layer
 * @returns {boolean} returnType: boolean
 */
export const getIsLayerActiveLayer = createSelector(
  getActiveLayerId,
  (_store: CoreAppStore, _mapId: string, layerId: string) => layerId,
  (activeLayerId, layerId) => activeLayerId === layerId,
  selectorMemoizationOptions,
);

/**
 * Returns the mapId for given layerId
 *
 * Example const mapId = getMapIdFromLayerId(store 'layerId-A');
 * @param {object} store store: object - store object
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {string} returnType: string - the mapId, or null if not found
 */
export const getMapIdFromLayerId = createSelector(
  (store: CoreAppStore): WebMapState | undefined => store?.webmap,
  (_store: CoreAppStore, layerId?: string): string | undefined => layerId,
  findMapIdFromLayerId,
  selectorMemoizationOptions,
);

/**
 * Returns the layerId for given layerName
 *
 * Example const layerId = getLayerIdByLayerName(store, 'mapid-1', 'precipitation');
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {string} returnType: string - the layerId, or null if not found
 */
export const getLayerIdByLayerName = createSelector(
  getMapLayers,
  (_store: CoreAppStore, _mapId: string, layerName: string): string =>
    layerName,
  (layers: Layer[], layerName: string): string => {
    const layer: Layer = layers.find((layer) => layer.name === layerName)!;
    if (layer) {
      return layer.id!;
    }
    return null!;
  },
  selectorMemoizationOptions,
);

/**
 * Returns the layerIndex in the map for given layerId
 *
 * Example const layerIndex = getLayerIndexByLayerId(store, 'mapid-1', 'precipitation');
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {number} returnType: index number or -1 if not found
 */
export const getLayerIndexByLayerId = createSelector(
  getMapLayers,
  (
    _store: CoreAppStore,
    _mapId: string,
    layerId?: string,
  ): string | undefined => layerId,
  (layers: Layer[], layerId?: string): number => {
    return layers.findIndex((layer) => layer.id === layerId);
  },
  selectorMemoizationOptions,
);

/**
 * Returns the Layer in the map for given layerIndex
 *
 * Example const layer = getLayerByLayerIndex(store, 'mapid-1', 0);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @param {number} layerIndex layerId: number - Index of the layer in the map
 * @returns {object} returnType: layer, or null if not found
 */
export const getLayerByLayerIndex = createSelector(
  getMapLayers,
  (_store: CoreAppStore, _mapId: string, layerIndex: number): number =>
    layerIndex,
  (layers: Layer[], layerIndex: number): Layer => {
    if (layerIndex >= 0 && layerIndex < layers.length) {
      return layers[layerIndex];
    }
    return null!;
  },
  selectorMemoizationOptions,
);

/**
 * Returns name of all unique dimensions present in all maps
 *
 * Example getAllUniqueDimensions(store);
 * @param {object} store store: object - store object
 * @returns {array} returnType: array of dimension names
 */
export const getAllUniqueDimensions = createSelector(
  getAllMapIds,
  (store: CoreAppStore) => store,
  (mapIds: string[], store: CoreAppStore): string[] => {
    return mapIds.reduce<string[]>((list, mapId) => {
      const mapDimensions = getMapDimensions(store, mapId);
      const uniqueMapDims = mapDimensions!.reduce<string[]>(
        (array, dimension) => {
          if (
            dimension &&
            dimension.name &&
            array.indexOf(dimension.name) === -1
          ) {
            return array.concat(dimension.name);
          }
          return array;
        },
        list,
      );
      return uniqueMapDims;
    }, []);
  },
  selectorMemoizationOptions,
);

/**
 * Returns the mapPinLocation for the current map
 *
 * Example getPinLocation(store);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {object} returnType: latitude and longitude of pin
 */
export const getPinLocation = createSelector(
  getMapById,
  (store) => store?.mapPinLocation,
  selectorMemoizationOptions,
);

/**
 * Returns the disable map pin boolean for the current map
 *
 * Example getDisableMapPin(store);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const getDisableMapPin = createSelector(
  [getMapById],
  (map) => map?.disableMapPin,
  selectorMemoizationOptions,
);

/**
 * Returns the display map pin boolean for the current map
 *
 * Example getDisplayMapPin(store);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: boolean
 */
export const getDisplayMapPin = createSelector(
  getMapById,
  (store) => (store ? store.displayMapPin : false),
  selectorMemoizationOptions,
);

/**
 * Returns the legend id
 *
 * Example getLegendId(store, mapId);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {boolean} returnType: id or undefined
 */
export const getLegendId = createSelector(
  getMapById,
  (store) => (store ? store.legendId : undefined),
  selectorMemoizationOptions,
);

/**
 * Creates a MapPreset from mapId
 *
 * Example getMapPreset(store, mapId);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {MapPreset} returnType: MapPreset
 */
export const getMapPreset = createSelector(
  getMapLayers,
  getMapBaseLayers,
  getMapOverLayers,
  getBbox,
  getSrs,
  getActiveLayerId,
  getAutoTimeStepLayerId,
  getAutoUpdateLayerId,
  isAnimating,
  isAutoUpdating,
  isTimeSliderVisible,
  getDisplayMapPin,
  isZoomControlsVisible,
  getMapTimeStep,
  getMapAnimationDelay,
  getAnimationStartTime,
  getAnimationEndTime,
  getMapTimeSliderSpan,
  isTimestepAuto,
  isTimeSpanAuto,
  getLegendId,
  uiSelectors.getUiStore,
  (
    mapLayers: Layer[],
    baseLayers: Layer[],
    overLayers: Layer[],
    bbox: Bbox,
    srs: string,
    activeLayerId: string | undefined,
    autoUpdateLayerId: string | undefined,
    autoTimeStepLayerId: string | undefined,
    isAnimating: boolean,
    isAutoUpdating: boolean,
    isTimeSliderVisible: boolean | undefined,
    displayMapPin: boolean | undefined,
    isZoomControlsVisible: boolean | undefined,
    mapTimeStep: number,
    mapAnimationDelay: number | undefined,
    animationStartTime: string | undefined,
    animationEndTime: string | undefined,
    timeSliderSpan: number | undefined,
    isTimestepAuto: boolean | undefined,
    isTimeSpanAuto: boolean | undefined,
    legendId: string | undefined,
    uiStore: uiTypes.UIStoreType,
  ): MapPreset => {
    const allLayers = [...baseLayers, ...overLayers, ...mapLayers].map(
      ({ mapId, ...layer }) => {
        if (layer.dimensions !== undefined) {
          // Filter out (reference) time dimension as these should not be part of the preset for now
          const dimensions = layer.dimensions.filter(
            (dimension) =>
              dimension.name !== 'time' && dimension.name !== 'reference_time',
          );

          return {
            ...layer,
            dimensions,
          };
        }
        return layer;
      },
    );

    const animationPayload = {
      interval: mapTimeStep,
      speed: mapAnimationDelay ? getSpeedFactor(mapAnimationDelay) : 1,
      duration: getAnimationDuration(animationEndTime, animationStartTime),
    };

    const shouldShowLegend = legendId
      ? uiStore?.dialogs[legendId]?.isOpen
      : false;

    return {
      layers: allLayers,
      ...(activeLayerId !== undefined && { activeLayerId }),
      ...(autoTimeStepLayerId !== undefined && { autoTimeStepLayerId }),
      ...(autoUpdateLayerId !== undefined && { autoUpdateLayerId }),
      proj: {
        bbox,
        srs,
      },
      shouldAnimate: isAnimating,
      shouldAutoUpdate: isAutoUpdating,
      showTimeSlider: isTimeSliderVisible,
      displayMapPin,
      shouldShowZoomControls: isZoomControlsVisible,
      animationPayload,
      toggleTimestepAuto: isTimestepAuto,
      toggleTimeSpanAuto: isTimeSpanAuto,
      shouldShowLegend,
      timeSliderSpan,
    };
  },
  selectorMemoizationOptions,
);

/**
 * Gets all enabled layerIds for map
 *
 * Example: getMapLayerIdsEnabled = getLayerIdsEnabled(store, 'mapId_1')
 * @param {object} store store: object - store
 * @param {string} mapId mapId: string - Id of the map
 * @returns {string[]} returnType: string[] -  array of enabled layerIds
 */
export const getMapLayerIdsEnabled = createSelector(
  getLayerIds,
  layerSelectors.getLayersById,
  (mapLayerIds, layers) =>
    mapLayerIds.reduce<string[]>((list, layerId) => {
      if (layers![layerId] && layers![layerId].enabled) {
        return list.concat(layerId);
      }
      return list;
    }, []),
  selectorMemoizationOptions,
);

/**
 * Returns if a map dimension is used for any enabled layers on that map
 *
 * Example getIsEnabledLayersForMapDimension(store, mapId);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} dimensionName dimensionName: string - name of the dimension
 * @returns {Boolean} returnType: boolean
 */
export const getIsEnabledLayersForMapDimension = createSelector(
  getMapLayerIdsEnabled,
  (store: CoreAppStore, _mapId: string, dimensionName: string) =>
    layerSelectors.getDimensionLayerIds(store, dimensionName),
  (enabledMapLayerIds: string[], dimensionLayerIds) => {
    return dimensionLayerIds.some((layerId) =>
      enabledMapLayerIds.includes(layerId),
    );
  },
  selectorMemoizationOptions,
);

/**
 * Returns the dockedLayerManagerSize originally set in mapPresets
 *
 * Example getDockedLayerManagerSize(store);
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {DockedLayerManagerSize} returnType: string
 */
export const getDockedLayerManagerSize = createSelector(
  getMapById,
  (store) => (store ? store.dockedLayerManagerSize : 'sizeSmall'),
  selectorMemoizationOptions,
);

/**
 * Returns the animation list for given map id
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {animationList} returnType: WebMapAnimationList, list of timesteps to animate for this map.
 */

export const getAnimationList = createSelector(
  layerSelectors.getLayersById,
  getMapById,
  getAnimationStartTime,
  getAnimationEndTime,
  getMapTimeStep,
  (layers, mapStore, animationStart, animationEnd, animationInterval) => {
    // Animation is defined by one of the following:
    // - start, end and interval for a continuous animation loop
    // - timeList, for a custom timeList

    // Here the animation is defined by a timeList
    if (mapStore?.timeList?.length) {
      return mapStore.timeList;
    }

    const autoTimeStepLayerId = mapStore?.autoTimeStepLayerId;

    const timeDimension =
      autoTimeStepLayerId &&
      layers?.[autoTimeStepLayerId]?.dimensions?.find(
        (dimension) => dimension.name === 'time',
      );

    const { values } = timeDimension || {};

    const unixAnimationStart = roundWithTimeStep(
      Number(animationStart && dateUtils.unix(dateUtils.utc(animationStart))),
      animationInterval!,
      'ceil',
    );
    const unixAnimationEnd = roundWithTimeStep(
      Number(animationEnd && dateUtils.unix(dateUtils.utc(animationEnd))),
      animationInterval!,
      'floor',
    );

    const animationList = generateAnimationList(
      unixAnimationStart,
      unixAnimationEnd,
      values,
    );

    // If no animationList is generated, generate a default list using the interval from map
    if (!animationList.length) {
      const timeList: WebMapAnimationList = [];
      const intervalSeconds = animationInterval * 60;
      for (
        let j = unixAnimationStart;
        j <= unixAnimationEnd;
        j += intervalSeconds
      ) {
        timeList.push({
          name: 'time',
          value: dateUtils.fromUnix(j).toISOString(),
        });
      }
      return timeList;
    }
    return animationList;
  },
  selectorMemoizationOptions,
);

/**
 * @param {object} store store: object - store object
 * @param {string} mapId mapId: string - Id of the map
 * @returns {object} Returns min and max value of time dimension of autoTimeStep-layer. If no autoTimeStep-layer it returns default start/end time from store.
 */
export const getAnimationRange = createSelector(
  layerSelectors.getLayersById,
  getAutoTimeStepLayerId,
  getAnimationStartTime,
  getAnimationEndTime,
  isAnimationLengthAuto,
  (layers, autoTimeStepLayerId, startTime, endTime, isAnimationLengthAuto) => {
    const layerTimeDimension =
      autoTimeStepLayerId &&
      layers?.[autoTimeStepLayerId]?.dimensions?.find(
        (dimension) => dimension.name === 'time',
      );

    const { minValue, maxValue } = layerTimeDimension || {};
    const hasTimeDimension = minValue && maxValue;

    const animationStartTime =
      isAnimationLengthAuto && autoTimeStepLayerId && hasTimeDimension
        ? minValue
        : startTime;
    const animationEndTime =
      isAnimationLengthAuto && autoTimeStepLayerId && hasTimeDimension
        ? maxValue
        : endTime;

    return { animationStartTime, animationEndTime };
  },
  selectorMemoizationOptions,
);

/**
 * Gets intialViewMapPreset from webmap state
 *
 * @param {object} store store: object - Store object
 * @returns {MapPreset | undefined} returnType: MapPreset
 */
export const getdefaultMapSettings = createSelector(
  getMapStore,
  (state) => state && state.defaultMapSettings,
  selectorMemoizationOptions,
);

/**
 * Gets the layers of intialViewMapPreset from webmap state
 *
 * @param {object} store store: object - Store object
 * @returns {Layer[] | undefined} returnType: Layer[]
 */
export const getdefaultMapSettingsLayers = createSelector(
  getdefaultMapSettings,
  (state) => state && state.layers,
  selectorMemoizationOptions,
);

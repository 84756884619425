/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Grid2 as Grid,
  Typography,
  Card,
  CardContent,
  Skeleton,
} from '@mui/material';
import { AlertBanner, usePoller } from '@opengeoweb/shared';

import { useApiContext, useApi } from '@opengeoweb/api';

import { BulletinControls } from './BulletinControls';
import { BulletinViewer } from './BulletinViewer';
import BulletinHistoryDialog from './BulletinHistoryDialog';
import { BulletinTabs } from './BulletinTabs';
import { BulletinType } from '../../types';
import { SpaceWeatherApi } from '../../utils/api';
import { useSpaceweatherTranslation } from '../../utils/i18n';

const maxHeight = '22vh';

const styles = {
  BulletinHeader: {
    textAlign: 'center',
    fontWeight: 500,
  },
  card: { boxShadow: 'inset 0 0 2px 0 rgba(0, 0, 0, 0.5)' },
  cardContent: { height: maxHeight, minHeight: '175px' },
};

/**
 * Bulletin
 * View the latest bulletin from Met Office, and access the history list
 *
 * @example
 * ``` <Bulletin /> ```
 */
const BulletinComponent: React.FC = () => {
  const { api } = useApiContext<SpaceWeatherApi>();
  const { t } = useSpaceweatherTranslation();
  const [bulletinType, setBulletinType] = React.useState(
    BulletinType.technical,
  );
  const [bulletinHistoryOpen, setBulletinHistoryOpen] = React.useState(false);
  const toggleBulletinHistory = (): void => {
    setBulletinHistoryOpen(!bulletinHistoryOpen);
  };

  const { isLoading, error, result, fetchApiData } = useApi(api.getBulletin);

  usePoller([result, error], () => fetchApiData!(null), 300000);

  const hasNoAvailableBulletinsFromLasth24h =
    result !== null && !result.bulletin_id && error === null && !isLoading;

  return (
    <Grid container spacing={1} size={12}>
      <Grid size={12}>
        <Typography variant="subtitle1" sx={styles.BulletinHeader}>
          {t('bulletin-title')}
        </Typography>
      </Grid>
      <Grid size={12}>
        <Card elevation={0} variant="outlined" sx={styles.card}>
          <BulletinTabs
            selected={bulletinType}
            onSetBulletinType={setBulletinType}
          />
          <CardContent sx={styles.cardContent}>
            {hasNoAvailableBulletinsFromLasth24h && (
              <AlertBanner
                dataTestId="bulletin-no-avail-bulletins"
                severity="info"
                title={t('bulletin-not-available')}
                info={t('bulletin-historical')}
              />
            )}
            {result && result.bulletin_id && (
              <BulletinViewer bulletinType={bulletinType} bulletin={result} />
            )}
            {isLoading && (
              <Skeleton
                data-testid="bulletin-loading"
                variant="rectangular"
                height="100%"
                width="100%"
              />
            )}
            {error && (
              <AlertBanner
                dataTestId="bulletin-error"
                severity="error"
                title={error.message ? error.message : ''}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid size={12}>
        <BulletinControls onOpenBulletinHistory={toggleBulletinHistory} />
      </Grid>
      <BulletinHistoryDialog
        open={bulletinHistoryOpen}
        toggleStatus={toggleBulletinHistory}
      />
    </Grid>
  );
};

export default BulletinComponent;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import {
  FlagBritain,
  FlagNetherlands,
  FlagFinland,
  FlagNorway,
} from '@opengeoweb/theme';
import { useCoreTranslation } from '../../utils/i18n';

export const languages: {
  language: string;
  code: string;
  flag: React.ReactElement;
}[] = [
  { language: 'English', code: 'en', flag: <FlagBritain /> },
  { language: 'Dutch', code: 'nl', flag: <FlagNetherlands /> },
  { language: 'Finnish', code: 'fi', flag: <FlagFinland /> },
  { language: 'Norwegian', code: 'no', flag: <FlagNorway /> },
];

export const languageLocalStorageKey = 'language';

export const getLanguageFromLocalStorage = (): string | null => {
  return window.localStorage.getItem(languageLocalStorageKey);
};

export const saveLanguageToLocalStorage = (languageKey: string): void => {
  window.localStorage.setItem(languageLocalStorageKey, languageKey);
};

const LanguageSelect: React.FC<{
  onChangeLanguage: (currentLanguage: string) => void;
  isOpen?: boolean; // only used for snapshots
}> = ({ onChangeLanguage, isOpen: shouldRenderAsOpenForSnapshot = false }) => {
  const { t, i18n } = useCoreTranslation();
  const currentLanguage =
    languages.find((language) => language.code === i18n.language)?.code || '';

  const onSelectLanguage = (event: SelectChangeEvent): void => {
    const { value } = event.target;
    void i18n.changeLanguage(value);
    onChangeLanguage(value);
  };

  const label = t('language-select');

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={currentLanguage}
        sx={{
          '.MuiSelect-select': {
            display: 'flex',
            padding: '22px 32px 1px 12px',
          },
        }}
        onChange={onSelectLanguage}
        open={shouldRenderAsOpenForSnapshot || undefined}
        inputProps={{
          SelectDisplayProps: {
            'data-testid': 'select',
          },
        }}
      >
        <MenuItem
          sx={{ minHeight: '20px!important', opacity: '.67!important' }}
          disabled
        >
          <ListItemText
            sx={{
              margin: 0,
              '.MuiTypography-root': {
                fontSize: 12,
                color: 'geowebColors.typographyAndIcons.text',
              },
            }}
          >
            {label}
          </ListItemText>
        </MenuItem>
        {languages.map((language) => (
          <MenuItem value={language.code} key={language.code}>
            <ListItemText
              sx={{ margin: 0, '.MuiTypography-root': { fontSize: 12 } }}
            >
              {language.language}
            </ListItemText>
            <ListItemIcon
              sx={{
                minWidth: 36,
                '.MuiSvgIcon-root': {
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  margin: 'auto',
                  boxShadow: 2,
                },
              }}
            >
              {language.flag}
            </ListItemIcon>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  CoreAppStore,
  layerActions,
  layerSelectors,
  layerTypes,
  mapTypes,
} from '@opengeoweb/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface LayerChangeDimensionParams {
  dimension: mapTypes.Dimension;
  origin: layerTypes.LayerActionOrigin | undefined;
}

export const useLayerDimensions = (
  layerId: string,
  mapId: string,
): {
  layerDimensions: mapTypes.Dimension[];
  isLayerEnabled: boolean;
  layerChangeDimension: ({
    dimension,
    origin,
  }: LayerChangeDimensionParams) => void;
} => {
  const dispatch = useDispatch();

  const layerDimensions = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerNonTimeDimensions(store, layerId),
  );

  const isLayerEnabled = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerEnabled(store, layerId),
  );

  const layerChangeDimension = useCallback(
    ({ dimension, origin }: LayerChangeDimensionParams) =>
      dispatch(
        layerActions.layerChangeDimension({
          layerId,
          dimension,
          mapId,
          origin,
        }),
      ),
    [layerId, dispatch, mapId],
  );

  return { layerDimensions, isLayerEnabled, layerChangeDimension };
};

export const useReferenceTimeWithLatestOption = (
  layerId: string,
): {
  useLatestReferenceTime: boolean;
  setUseLatestReferenceTime: (useLatestReferenceTime: boolean) => void;
} => {
  const useLatestReferenceTime = useSelector((store: CoreAppStore) =>
    layerSelectors.getUseLatestReferenceTime(store, layerId),
  );

  const dispatch = useDispatch();
  const setUseLatestReferenceTime = (useLatestReferenceTime: boolean): void => {
    dispatch(
      layerActions.setUseLatestReferenceTime({
        id: layerId,
        useLatestReferenceTime,
      }),
    );
  };

  return { useLatestReferenceTime, setUseLatestReferenceTime };
};

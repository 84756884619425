/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import {
  ReactHookFormHiddenInput,
  isGeometryDirty,
} from '@opengeoweb/form-fields';
import { useApiContext } from '@opengeoweb/api';
import {
  DrawMode,
  MapViewLayerProps,
  addFeatureProperties,
  rewindGeometry,
  useMapDrawTool,
} from '@opengeoweb/webmap-react';
import {
  FormMode,
  StartOrEndDrawing,
  Airmet,
  CancelAirmet,
  ProductConfig,
  AviationProduct,
  AviationPhenomenon,
} from '../../../types';

import {
  Phenomenon,
  ObservationForecast,
  Change,
  Progress,
  Type,
  SelectFIR,
  ObservationForecastTime,
  ValidFrom,
  ValidUntil,
  StartGeometry,
  Levels,
  SurfaceVisibility,
  SurfaceWind,
  CloudLevels,
} from '../ProductFormFields';
import { useTAC } from '../ProductFormTac';
import {
  getDefaultPhenomena,
  getDrawTools,
  getFir,
  getProductIssueDate,
  triggerValidations,
  updateDrawToolsWithFir,
  useFormLayers,
} from '../utils';
import { SigmetAirmetApi } from '../../../utils/api';
import IssuedAt from '../ProductFormFields/IssuedAt';
import Tac from '../ProductFormFields/Tac';
import ProductFormLayout from '../ProductFormLayout';
import { airmetConfig as defaultAirmetConfig } from '../../../utils/config';
import {
  featurePropsFIR,
  featurePropsIntersectionStart,
  featurePropsStart,
} from '../../MapViewGeoJson/constants';
import { formStyles } from '../ProductForm.styles';

const useConditionalFields = (): {
  hasSurfaceVisiblity: boolean;
  hasSurfaceWind: boolean;
  hasLevels: boolean;
  hasCloudLevels: boolean;
} => {
  const { watch, unregister } = useFormContext();
  // phenomenon: SFC_VIS
  const phenomenon = watch('phenomenon');
  const hasSurfaceVisiblity = phenomenon === 'SFC_VIS';
  React.useEffect(() => {
    if (!hasSurfaceVisiblity) {
      unregister(['visibilityValue', 'visibilityCause']);
    }
  }, [hasSurfaceVisiblity, unregister]);

  // phenomenon: SFC_WIND
  const hasSurfaceWind = watch('phenomenon') === 'SFC_WIND';
  React.useEffect(() => {
    if (!hasSurfaceWind) {
      unregister(['windDirection', 'windUnit', 'windSpeed']);
    }
  }, [hasSurfaceWind, unregister]);

  // phenomenon: SFC_WIND, SFC_VIS, BKN_CLD, OVC_CLD
  const hasLevels =
    phenomenon !== 'SFC_WIND' &&
    phenomenon !== 'SFC_VIS' &&
    phenomenon !== 'BKN_CLD' &&
    phenomenon !== 'OVC_CLD';

  React.useEffect(() => {
    if (!hasLevels) {
      unregister(['levelInfoMode', 'level', 'lowerLevel']);
    }
  }, [hasLevels, unregister]);

  // phenomenon: BKN_CLD
  const hasCloudLevels = phenomenon === 'BKN_CLD' || phenomenon === 'OVC_CLD';
  React.useEffect(() => {
    if (!hasCloudLevels) {
      unregister(['cloudLevelInfoMode', 'cloudLevel', 'cloudLowerLevel']);
    }
  }, [hasCloudLevels, unregister]);

  return { hasSurfaceVisiblity, hasSurfaceWind, hasLevels, hasCloudLevels };
};

export interface AirmetFormProps {
  mode: FormMode;
  isCancelAirmet?: boolean;
  initialAirmet?: Airmet;
  initialCancelAirmet?: CancelAirmet;
  showMap?: boolean;
  productConfig?: ProductConfig;
  product?: AviationProduct;
  getTAC?: (product: AviationProduct) => Promise<{ data: string }>;
}

const AirmetForm: React.FC<AirmetFormProps> = ({
  mode,
  isCancelAirmet = false,
  initialAirmet = null!,
  initialCancelAirmet = null!,
  showMap = true,
  productConfig = defaultAirmetConfig,
  product,
  getTAC,
}: AirmetFormProps) => {
  const [phenomenaList, setPhenomenaList] = React.useState<
    AviationPhenomenon[]
  >(getDefaultPhenomena(productConfig).phenomena);

  const { api } = useApiContext<SigmetAirmetApi>();
  const firBorderAtStart = initialAirmet
    ? initialAirmet.locationIndicatorATSR
    : productConfig.active_firs[0];

  const {
    geoJSON: startGeoJSON,
    setGeoJSON,
    geoJSONIntersection: startGeoJSONIntersection,
    getLayer: getStartLayer,
    drawModes: startEditModes,
    activeTool: activeStartTool,
    changeActiveTool: changeActiveStartTool,
    deactivateTool: deactivateStartTool,
    setGeoJSONIntersectionBounds: setStartGeoJSONIntersectionBounds,
    setDrawModes: setStartEditModes,
  } = useMapDrawTool({
    defaultDrawModes: getDrawTools(
      StartOrEndDrawing.start,
      getFir(productConfig, firBorderAtStart),
    ),
    defaultGeoJSON:
      !isCancelAirmet && initialAirmet && initialAirmet.startGeometry
        ? addFeatureProperties(initialAirmet.startGeometry, featurePropsStart)
        : undefined,
    defaultGeoJSONIntersection:
      !isCancelAirmet && initialAirmet && initialAirmet.startGeometryIntersect
        ? addFeatureProperties(
            initialAirmet.startGeometryIntersect,
            featurePropsIntersectionStart,
          )
        : undefined,
    defaultGeoJSONIntersectionBounds: addFeatureProperties(
      getFir(productConfig, firBorderAtStart),
      featurePropsFIR,
    ),
  });

  const onUpdateStartgeoJSON = (
    updatedStartGeoJSON: GeoJSON.FeatureCollection,
    updateStartGeoJSONIntersection: GeoJSON.FeatureCollection,
  ): void => {
    setValue('startGeometry', updatedStartGeoJSON, {
      shouldValidate: true,
      shouldDirty: isGeometryDirty(watch('startGeometry'), updatedStartGeoJSON),
    });
    setValue('startGeometryIntersect', updateStartGeoJSONIntersection, {
      shouldValidate: true,
      shouldDirty: isGeometryDirty(
        watch('startGeometryIntersect'),
        updateStartGeoJSONIntersection,
      ),
    });
    onChangeForm();
  };

  const onChangeStartTool = (newTool: DrawMode): void => {
    changeActiveStartTool(newTool);

    if (!newTool.isSelectable) {
      deactivateStartTool();

      onUpdateStartgeoJSON(
        newTool.shape as GeoJSON.FeatureCollection,
        newTool.shape as GeoJSON.FeatureCollection,
      );
    }
  };

  const onExitDrawMode = (
    reason: string,
    newGeoJSON?: GeoJSON.FeatureCollection,
  ): void => {
    deactivateStartTool();

    const [newStartGeoJson, newStartIntersection] = setGeoJSON(newGeoJSON!);
    const newGeoStart = rewindGeometry(newStartGeoJson);
    const newGeoStartIntersect = rewindGeometry(newStartIntersection!);
    onUpdateStartgeoJSON(newGeoStart, newGeoStartIntersect!);
  };

  const layers: MapViewLayerProps[] = [
    getStartLayer('geoJSONIntersectionBounds', 'geojsonlayer-fir'),
    {
      ...getStartLayer('geoJSON', 'geojsonlayer-start'),
      exitDrawModeCallback: onExitDrawMode,
    },
    getStartLayer('geoJSONIntersection', 'geojsonlayer-intersection-start'),
  ];

  useFormLayers(layers);

  const {
    watch,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const getHasFormErrors = (): boolean => Object.keys(errors).length > 0;

  const [isDisabled, setIsDisabled] = React.useState(mode === 'view');
  const isReadOnly = mode === 'view';
  const helperText = isReadOnly ? '' : 'Optional';
  const [tac, updateTac] = useTAC(
    initialAirmet !== null ? initialAirmet : initialCancelAirmet,
    (getTAC || api.getAirmetTAC) as (
      product: AviationProduct,
    ) => Promise<{ data: string }>,
    getHasFormErrors,
  );

  const onChangeForm = (): void => {
    updateTac(watch);
  };
  React.useEffect(() => {
    updateTac(watch);
  }, [initialAirmet, updateTac, watch]);

  React.useEffect(() => {
    const isInDrawMode = activeStartTool !== '';
    if (mode === 'view') {
      setIsDisabled(true);
    } else {
      setIsDisabled(isInDrawMode);
    }
  }, [activeStartTool, mode]);

  const { hasSurfaceVisiblity, hasSurfaceWind, hasLevels, hasCloudLevels } =
    useConditionalFields();

  const onChangeFir = (
    newPhenomenaList: AviationPhenomenon[],
    event?: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newFIRValue = event?.target.value;
    const newFirGeoJSON = addFeatureProperties(
      getFir(productConfig, newFIRValue),
      featurePropsFIR,
    );
    // delete shape
    onChangeStartTool(startEditModes.find((mode) => mode.value === 'DELETE')!);
    // update instersection bounds
    setStartGeoJSONIntersectionBounds(newFirGeoJSON);
    // update tool buttons with new fir geoJSON
    setStartEditModes(updateDrawToolsWithFir(startEditModes, newFirGeoJSON));
    // update available phenomena for the FIR
    setPhenomenaList(newPhenomenaList);

    // trigger validation for airmet specific fields that depend on FIR config
    const fieldnames = [
      'windDirection',
      'windSpeed',
      'visibilityValue',
      'cloudLevel.value',
      'cloudLowerLevel.value',
    ];
    triggerValidations(fieldnames, getValues, trigger);
  };

  const renderForm = (): React.ReactElement => (
    <Grid container direction="column" spacing={2} sx={formStyles}>
      {/* Type */}
      <Type
        productType="airmet"
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={onChangeForm}
      />
      {/* Phenomenon */}
      {!isCancelAirmet && (
        <Phenomenon
          productType="airmet"
          productConfig={productConfig}
          phenomenaList={phenomenaList}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={onChangeForm}
        />
      )}
      {/* Surface Visibility */}
      {!isCancelAirmet && hasSurfaceVisiblity && (
        <SurfaceVisibility
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          onChange={onChangeForm}
          productConfig={productConfig}
        />
      )}
      {/* Surface Wind */}
      {!isCancelAirmet && hasSurfaceWind && (
        <SurfaceWind
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          onChange={onChangeForm}
          productConfig={productConfig}
        />
      )}
      {/* Observed/Forecast */}
      {!isCancelAirmet && (
        <ObservationForecast isDisabled={isDisabled} isReadOnly={isReadOnly} />
      )}
      {/* At */}
      {!isCancelAirmet && (
        <ObservationForecastTime
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          helperText={helperText}
          onChange={onChangeForm}
        />
      )}
      {/* Valid from */}
      <ValidFrom
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        onChange={onChangeForm}
        productConfig={productConfig}
      />
      {/* Valid until */}
      <ValidUntil
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        onChange={onChangeForm}
        productConfig={productConfig}
      />

      {/* Where */}
      <SelectFIR
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        productConfig={productConfig}
        onChange={(event, newPhenomenaList) => {
          onChangeFir(newPhenomenaList || [], event);
        }}
      />
      {/* Draw */}
      <StartGeometry
        isReadOnly={isReadOnly}
        geoJSON={startGeoJSON}
        geoJSONIntersection={startGeoJSONIntersection}
        tools={startEditModes}
        onChangeTool={onChangeStartTool}
        activeTool={activeStartTool}
        productConfig={productConfig}
      />
      {/* Levels */}
      {!isCancelAirmet && hasLevels && (
        <Levels
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={onChangeForm}
          productType="airmet"
          productConfig={productConfig}
        />
      )}
      {/* Cloud Levels */}
      {!isCancelAirmet && hasCloudLevels && (
        <CloudLevels
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={onChangeForm}
          productConfig={productConfig}
        />
      )}
      {/* Progress */}
      {!isCancelAirmet && (
        <Progress
          productType="airmet"
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={onChangeForm}
          productConfig={productConfig}
        />
      )}
      {/* Change */}
      {!isCancelAirmet && (
        <Change isDisabled={isDisabled} isReadOnly={isReadOnly} />
      )}
      {/* Issued at */}
      <IssuedAt
        isReadOnly={isReadOnly}
        date={getProductIssueDate(initialAirmet, initialCancelAirmet)}
      />

      <Tac tac={tac} product={product!}>
        {/* Hidden sequence number field needed for the TAC generation */}
        <ReactHookFormHiddenInput name="sequence" defaultValue="-1" />
        {
          /* Add hidden fields for TAC generation in case of cancel airmet */
          isCancelAirmet && (
            <>
              <ReactHookFormHiddenInput name="cancelsAirmetSequenceId" />
              <ReactHookFormHiddenInput name="validDateEndOfAirmetToCancel" />
              <ReactHookFormHiddenInput name="validDateStartOfAirmetToCancel" />
            </>
          )
        }
      </Tac>
    </Grid>
  );

  if (!showMap) {
    return renderForm();
  }

  return (
    <form
      onChange={onChangeForm}
      onSubmit={handleSubmit(() => null)}
      style={{ width: '100%' }}
    >
      <ProductFormLayout productConfig={productConfig} geoJSONLayers={layers}>
        {renderForm()}
      </ProductFormLayout>
    </form>
  );
};

export default AirmetForm;

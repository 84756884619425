/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CustomToggleButton } from '@opengeoweb/shared';
import { Add } from '@opengeoweb/theme';
import React from 'react';
import { ListItemText, Menu, MenuItem } from '@mui/material';
import { useWarningsTranslation } from '../../../utils/i18n';
import { WarningType } from '../../../store/publicWarningForm/types';

export const BUTTON_AIRMET = 'AIRMET';
export const BUTTON_SIGMET = 'SIGMET';

interface NewWarningButtonProps {
  onCreateWarning: (warningType?: WarningType) => void;
  isDefaultOpen?: boolean;
}

export const NewWarningButton: React.FC<NewWarningButtonProps> = ({
  onCreateWarning,
  isDefaultOpen = false,
}: NewWarningButtonProps) => {
  const { t } = useWarningsTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement, Event>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onCreateNewWarning = (
    warningType: WarningType,
    event: React.MouseEvent,
  ): void => {
    onCreateWarning(warningType);
    handleClose(event);
  };

  const buttonId = 'newWarningButton';
  React.useEffect(() => {
    if (isDefaultOpen) {
      setAnchorEl(document.getElementById(buttonId) as HTMLButtonElement);
    }
  }, [isDefaultOpen, buttonId]);

  return (
    <>
      <CustomToggleButton
        id={buttonId}
        onClick={handleClick}
        sx={{
          textTransform: 'initial!important',
          marginBottom: '8px',
          fontSize: '12px',
          height: '32px!important',
          whiteSpace: 'nowrap',
          minWidth: 'auto',
          paddingRight: '12px!important',
          ...(isOpen
            ? {
                '&.MuiToggleButton-root.primary': {
                  backgroundColor:
                    'geowebColors.buttons.primary.mouseOver.fill',
                },
              }
            : {}),
        }}
      >
        <Add />
        {t('warning-list-button_create')}
      </CustomToggleButton>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={(
          event: React.MouseEvent,
          reason: 'backdropClick' | 'escapeKeyDown' | 'tabKeyDown',
        ): void => {
          if (reason === 'tabKeyDown') {
            return;
          }
          handleClose(event);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          root: {
            slotProps: {
              backdrop: {
                style: {
                  opacity: 0,
                  transition: 'none',
                },
              },
            },
          },
        }}
        MenuListProps={{
          style: {
            padding: '0px',
          },
        }}
      >
        <MenuItem
          key="Public"
          onClick={(event: React.MouseEvent): void => {
            onCreateNewWarning('public', event);
          }}
        >
          <ListItemText>{t('public-warning-title').toUpperCase()}</ListItemText>
        </MenuItem>
        <MenuItem
          key="Airmet"
          onClick={(event: React.MouseEvent): void => {
            onCreateNewWarning('airmet', event);
          }}
        >
          <ListItemText>{BUTTON_AIRMET}</ListItemText>
        </MenuItem>
        <MenuItem
          key="Sigmet"
          onClick={(event: React.MouseEvent): void => {
            onCreateNewWarning('sigmet', event);
          }}
        >
          <ListItemText>{BUTTON_SIGMET}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

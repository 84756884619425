/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

// To be used with translations of days in formatted date
// enum DaysOfWeeki18n {
//   SUNDAY = 'timeslider-sunday',
//   MONDAY = 'timeslider-monday',
//   TUESDAY = 'timeslider-tuesday',
//   WEDNESDAY = 'timeslider-wednesday',
//   THURSDAY = 'timeslider-thursday',
//   FRIDAY = 'timeslider-friday',
//   SATURDAY = 'timeslider-saturday',
// }

// To be used with translations of months in formatted date
// enum MonthsOfTheYeari18n {
//   JANUARY = 'timeslider-january',
//   FEBRUARY = 'timeslider-february',
//   MARCH = 'timeslider-march',
//   APRIL = 'timeslider-april',
//   MAY = 'timeslider-may',
//   JUNE = 'timeslider-june',
//   JULY = 'timeslider-july',
//   AUGUST = 'timeslider-august',
//   SEPTEMBER = 'timeslider-september',
//   OCTOBER = 'timeslider-october',
//   NOVEMBER = 'timeslider-november',
//   DECEMBER = 'timeslider-december',
// }

enum DaysOfWeek {
  SUNDAY = 'Sun',
  MONDAY = 'Mon',
  TUESDAY = 'Tue',
  WEDNESDAY = 'Wed',
  THURSDAY = 'Thu',
  FRIDAY = 'Fri',
  SATURDAY = 'Sat',
}

enum MonthsOfTheYear {
  JANUARY = 'Jan',
  FEBRUARY = 'Feb',
  MARCH = 'Mar',
  APRIL = 'Apr',
  MAY = 'May',
  JUNE = 'Jun',
  JULY = 'Jul',
  AUGUST = 'Aug',
  SEPTEMBER = 'Sep',
  OCTOBER = 'Oct',
  NOVEMBER = 'Nov',
  DECEMBER = 'Dec',
}

export const dateToManualFormatString = (inDate: Date | undefined): string => {
  if (!inDate) {
    return 'Date not available';
  }

  const EEE = inDate && Object.values(DaysOfWeek)[inDate.getUTCDay()];
  const dd = `0${inDate?.getUTCDate()}`.slice(-2);
  const MMM = inDate && Object.values(MonthsOfTheYear)[inDate.getUTCMonth()];
  const HH = `0${inDate?.getUTCHours()}`.slice(-2);
  const mm = `0${inDate?.getUTCMinutes()}`.slice(-2);

  return `${EEE} ${dd} ${MMM} ${HH}:${mm} UTC`;
};

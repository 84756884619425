/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { range } from 'lodash';
import { dateUtils, defaultDelay } from '@opengeoweb/shared';
import type IWMJSMap from './IWMJSMap';
import type { AnimationStep } from './types';
import { getWMSRequests } from './WMJSMapHelpers';
import { debugLogger, DebugType } from './WMJSTools';

const MAX_IMAGES_TO_PREFETCH_DEFAULT = 25;
const BATCH_SIZE = 5;

export function prefetchImagesForAnimation(
  wmMap: IWMJSMap,
  maxImagesToPrefetch: number = MAX_IMAGES_TO_PREFETCH_DEFAULT,
): void {
  const animationSteps = wmMap.animationList as AnimationStep[];

  const countAnimationSteps = animationSteps.length;

  const animationStepsInOrder = range(0, countAnimationSteps).map((offset) => {
    // start prefecthing images for the next animation step first
    // when reaching end of animation, start from the beginning
    const indexOfNextAnimationStep =
      (wmMap.currentAnimationStep + offset) % countAnimationSteps;
    return animationSteps[indexOfNextAnimationStep];
  });

  prefetchLayerImagesInOrderWithUrls(
    wmMap,
    animationStepsInOrder,
    maxImagesToPrefetch,
  );
}

function prefetchLayerImagesInOrderWithUrls(
  wmMap: IWMJSMap,
  animationStepsInOrder: AnimationStep[],
  maxImagesToPrefetch: number,
): void {
  for (const animationStep of animationStepsInOrder) {
    // dont prefetch if already prefetching too many images
    const countImagesLoading = wmMap.getMapImageStore.getNumImagesLoading();
    if (countImagesLoading > maxImagesToPrefetch - 1) {
      break;
    }

    prefetchLayerImagesForTime(wmMap, animationStep.requests || []);
  }
}

async function processBatch(
  wmMap: IWMJSMap,
  timesToPrefetchInOrder: string[],
  maxImagesToPrefetch: number,
  batchSize: number,
  startIndex = 0,
): Promise<void> {
  const batch = timesToPrefetchInOrder.slice(
    startIndex,
    startIndex + batchSize,
  );

  for (const timeToPrefetch of batch) {
    const countImagesLoading = wmMap.getMapImageStore.getNumImagesLoading();
    if (countImagesLoading > maxImagesToPrefetch - 1) {
      return; // Stop processing if maximum prefetch count is reached
    }

    const layersImageUrls = getLayersImageUrlsForTime(wmMap, timeToPrefetch);
    prefetchLayerImagesForTime(wmMap, layersImageUrls);
  }

  const nextIndex = startIndex + batchSize;
  if (nextIndex < timesToPrefetchInOrder.length) {
    await new Promise((resolve) => {
      setTimeout(resolve, defaultDelay);
    });

    await processBatch(
      wmMap,
      timesToPrefetchInOrder,
      maxImagesToPrefetch,
      batchSize,
      nextIndex,
    );
  }
}

async function prefetchLayerImagesInOrder(
  wmMap: IWMJSMap,
  timesToPrefetchInOrder: string[],
  maxImagesToPrefetch: number,
  batchSize: number,
): Promise<void> {
  await processBatch(
    wmMap,
    timesToPrefetchInOrder,
    maxImagesToPrefetch,
    batchSize,
  );
}

interface PrefetchRequest {
  url: string;
  headers: Headers[];
}

export function getLayersImageUrlsForTime(
  wmMap: IWMJSMap,
  timeToPrefetch: string,
): PrefetchRequest[] {
  const layersImageUrls = getWMSRequests(wmMap, [
    {
      name: 'time',
      currentValue: timeToPrefetch,
    },
  ]);

  return layersImageUrls;
}

function prefetchLayerImagesForTime(
  wmMap: IWMJSMap,
  requests: PrefetchRequest[],
): void {
  requests.forEach((request) => {
    const image = wmMap.getMapImageStore.getImage(request.url, {
      headers: request.headers,
    });
    if (image.hasNotStartedLoading()) {
      image.load();
    } else if (image.isLoaded() && image.isStale()) {
      image.forceReload(true);
    }
  });
}

export function prefetchImagesForNonAnimation(
  wmMap: IWMJSMap,
  maxImagesToPrefetch: number = MAX_IMAGES_TO_PREFETCH_DEFAULT,
  batchSize: number = BATCH_SIZE,
): void {
  if (!wmMap.shouldPrefetch) {
    return;
  }
  const { timestepInMinutes } = wmMap;
  const timeDimension = wmMap.getDimension('time');
  if (!timeDimension || !timestepInMinutes) {
    return;
  }

  const currentTimeIsoString = timeDimension.getClosestValue(
    timeDimension.currentValue,
  );

  // Check if determining prefetching is necessary based on the following props:
  if (
    !wmMap.prefetchLayerImagesForTimeMemo.checkIfChanged({
      currentTimeIsoString,
      timestepInMinutes,
      srs: wmMap.getProjection().srs,
      left: wmMap.getProjection().bbox.left,
      top: wmMap.getProjection().bbox.top,
      maxImagesToPrefetch,
      batchSize,
    })
  ) {
    return;
  }

  const currentTime = dateUtils.parseISO(currentTimeIsoString);

  // Number of images to prefetch in each direction - current time
  const numTimesEachDirection = Math.floor((maxImagesToPrefetch - 1) / 2);

  const futureTimes = Array.from({ length: numTimesEachDirection }, (_, i) => {
    const newDate = dateUtils.add(currentTime, {
      minutes: timestepInMinutes * (i + 1),
    });
    return dateUtils.isValid(newDate) ? newDate.toISOString() : null;
  }).filter((date): date is string => date !== null);

  const pastTimes = Array.from({ length: numTimesEachDirection }, (_, i) => {
    const newDate = dateUtils.sub(currentTime, {
      minutes: timestepInMinutes * (i + 1),
    });
    return dateUtils.isValid(newDate) ? newDate.toISOString() : null;
  }).filter((date): date is string => date !== null);

  const timesToPrefetch = [currentTimeIsoString, ...futureTimes, ...pastTimes];

  // Sort times adjacent to current time
  timesToPrefetch.sort((a, b) => {
    const diffA = Math.abs(
      dateUtils.differenceInMinutes(currentTime, dateUtils.parseISO(a)),
    );
    const diffB = Math.abs(
      dateUtils.differenceInMinutes(currentTime, dateUtils.parseISO(b)),
    );
    return diffA - diffB;
  });

  prefetchLayerImagesInOrder(
    wmMap,
    timesToPrefetch,
    maxImagesToPrefetch,
    batchSize,
  ).catch(() => {
    debugLogger(DebugType.Error, `Error prefetching images`);
  });
}

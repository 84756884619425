/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Airmet,
  CancelAirmet,
  CancelSigmet,
  ProductStatus,
  Sigmet,
} from '@opengeoweb/api';

export interface Area {
  objectName: string;
  geoJSON: GeoJSON.FeatureCollection;
  areaId?: string;
  published_warning_uuid?: string;
}

export interface PublicWarningDetail {
  id?: string;
  phenomenon: string;
  areas?: Area[];
  validFrom: string;
  validUntil: string;
  level: string;
  probability: number;
  descriptionOriginal: string;
  descriptionTranslation: string;
  domain?: string;
  warningProposalSource?: string;
  authority?: string;
  type?: number;
  warning_handle_uuid?: string;
  proposal_id?: string;
  linked_to_id?: string;
  incident?: string;
}

export type AviationWarningDetails =
  | Airmet
  | CancelAirmet
  | Sigmet
  | CancelSigmet;

export enum PublicWarningStatus {
  DRAFT = 'DRAFT',
  DRAFT_PUBLISHED = 'DRAFT_PUBLISHED',
  PUBLISHED = 'PUBLISHED',
  TODO = 'TODO',
  EXPIRED = 'EXPIRED',
  IGNORED = 'IGNORED',
  WITHDRAWN = 'WITHDRAWN',
}

export type WarningType = 'public' | 'airmet' | 'sigmet';

interface BaseWarning {
  status?: PublicWarningStatus;
  productStatus?: ProductStatus;
  editor?: string;
  warningDetail: PublicWarningDetail;
  id?: string;
  lastUpdatedTime?: string;
}

export interface PublicWarning extends BaseWarning {
  type: 'public';
}
export interface AirmetWarning extends BaseWarning {
  type: 'airmet';
  sigmetAirmetDetails: Airmet | CancelAirmet;
}
export interface SigmetWarning extends BaseWarning {
  type: 'sigmet';
  sigmetAirmetDetails: Sigmet | CancelSigmet;
}

export type Warning = PublicWarning | AirmetWarning | SigmetWarning;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { isEqual } from 'lodash';

export class WMMemo {
  constructor() {
    this.checkIfChanged = this.checkIfChanged.bind(this);
    this.obj = {};
  }
  obj: object;

  checkIfChanged(obj: object): boolean {
    if (isEqual(obj, this.obj)) {
      return false;
    }
    Object.assign(this.obj, { ...obj });
    return true;
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useEffect, useState } from 'react';
import { TimeSeriesService, dateUtils } from '@opengeoweb/shared';
import { compact, groupBy, isEqual } from 'lodash';
import { Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  EDRInstance,
  ParameterWithData,
  PlotPreset,
  PlotWithData,
  Plot,
  isTimeSeriesLocation,
  PointerLocation,
  Parameter,
  TimeSeriesLocation,
  GeoJsonWithService,
} from './types';
import { TimeSeriesChart } from './TimeSeriesChart';
import { getOgcParameter } from './api';
import {
  fetchEdrAllInstances,
  findNearestPoint,
  getEdrParameter,
  getFeatureCenterPoint,
} from '../../utils/edrUtils';
import { timeSeriesActions } from '../../store';

interface TimeSeriesViewProps {
  plotPreset: PlotPreset;
  selectedLocation: PointerLocation;
  services: TimeSeriesService[];
  splitGeoJsonByCollection: GeoJsonWithService[];
  isLoading?: (startOrFinish: boolean) => void;
}

export const TimeSeriesView: React.FC<TimeSeriesViewProps> = React.memo(
  ({
    plotPreset,
    selectedLocation,
    services,
    splitGeoJsonByCollection,
    isLoading,
  }: TimeSeriesViewProps) => {
    const dispatch = useDispatch();
    const [hasSetInstanceId, setHasSetInstanceId] = useState(false);

    const plotsWithData = useGetPlotsWithData(
      plotPreset,
      selectedLocation,
      services,
      splitGeoJsonByCollection,
      isLoading,
    );

    useEffect(() => {
      if (!hasSetInstanceId && plotsWithData && plotsWithData.length > 0) {
        plotsWithData.forEach((plot) => {
          plot.parametersWithData?.forEach((parameter) => {
            const service = services.find(
              (service) => service.id === parameter.serviceId,
            );
            if (service && service.type === 'EDR') {
              fetchEdrAllInstances(service.url, parameter.collectionId)
                .then((refTimeList: EDRInstance[]) => {
                  const sortedRefTimeList = refTimeList.sort((a, b) => {
                    return (
                      dateUtils.parseCustomDateString(b.id).getTime() -
                      dateUtils.parseCustomDateString(a.id).getTime()
                    );
                  });
                  dispatch(
                    timeSeriesActions.patchParameter({
                      parameter: {
                        id: parameter.id,
                        instanceId: sortedRefTimeList[0].id,
                      },
                    }),
                  );
                })
                .catch(() => {});
            }
          });
        });
        setHasSetInstanceId(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSetInstanceId, plotsWithData]);

    return (
      <Card
        data-testid="TimeSeriesView"
        sx={{
          border: 'solid 1px',
          borderColor: 'geowebColors.cards.cardContainerBorder',
          boxShadow: 'none',
        }}
      >
        {plotsWithData && <TimeSeriesChart plotsWithData={plotsWithData} />}
      </Card>
    );
  },
  (previousProps, nextProps) => {
    const samePreset = previousProps.plotPreset === nextProps.plotPreset;
    const sameLocation = isEqual(
      previousProps.selectedLocation,
      nextProps.selectedLocation,
    );
    const sameGeoJson = isEqual(
      previousProps.splitGeoJsonByCollection,
      nextProps.splitGeoJsonByCollection,
    );
    return samePreset && sameLocation && sameGeoJson;
  },
);

export const useGetPlotsWithData = (
  plotsWithoutData: PlotPreset,
  selectedLocation: PointerLocation,
  services: TimeSeriesService[],
  splitGeoJsonByCollection: GeoJsonWithService[],
  isLoading?: (startOrFinish: boolean) => void,
): PlotWithData[] | undefined => {
  const [plotsWithData, setPlotsWithData] = React.useState<PlotWithData[]>();

  useEffect(() => {
    const setPlots = async (): Promise<void> => {
      const shownPlotsWithoutData =
        filterOutHiddenPlotsAndParameters(plotsWithoutData);

      if (!shownPlotsWithoutData) {
        setPlotsWithData(undefined);
      } else {
        const plotsWithData = await getPlotsWithData(
          shownPlotsWithoutData,
          selectedLocation,
          services,
          splitGeoJsonByCollection,
        );
        setPlotsWithData(plotsWithData);
      }
    };
    const interval = window.setInterval(() => {
      void setPlots();
    }, 60_000);

    isLoading && isLoading(true);
    void setPlots().finally(() => {
      isLoading && isLoading(false);
    });

    return (): void => {
      window.clearInterval(interval);
    };
  }, [
    isLoading,
    plotsWithoutData,
    selectedLocation,
    services,
    splitGeoJsonByCollection,
  ]);

  return plotsWithData;
};

const getPlotsWithData = async (
  plotsWithoutData: PlotPreset,
  selectedLocation: PointerLocation,
  services: TimeSeriesService[],
  splitGeoJsonByCollection: GeoJsonWithService[],
): Promise<PlotWithData[] | undefined> => {
  const parameters = await fetchParameters(
    plotsWithoutData,
    selectedLocation,
    services,
    splitGeoJsonByCollection,
  );
  const plotsWithData = getPlotsWithParameters(
    plotsWithoutData.plots,
    parameters,
  );
  return plotsWithData;
};

const filterOutHiddenPlotsAndParameters = (
  plotPreset: PlotPreset,
): PlotPreset | undefined => {
  const shownPlots = plotPreset.plots.filter((plot) => plot.enabled !== false);
  if (shownPlots.length === 0) {
    return undefined;
  }
  const hiddenPlotsIds = plotPreset.plots
    .filter((plot) => {
      return plot.enabled === false;
    })
    .map((plot) => plot.plotId);

  const shownParameters = plotPreset.parameters.filter((parameter) => {
    const parameterIsInEnabledPlot = !hiddenPlotsIds.includes(parameter.plotId);
    const parameterIsEnabled = parameter.enabled !== false;
    return parameterIsEnabled && parameterIsInEnabledPlot;
  });

  return {
    ...plotPreset,
    plots: shownPlots,
    parameters: shownParameters,
  };
};

function getPlotsWithParameters(
  plots: Plot[],
  parameters: ParameterWithData[],
): PlotWithData[] {
  const parametersByPlotId = groupBy(
    parameters,
    (parameter) => parameter.plotId,
  );

  const plotsWithParameters = plots
    .map((plot) => {
      return { ...plot, parametersWithData: parametersByPlotId[plot.plotId] };
    })
    .filter((plot) => plot.parametersWithData?.length);
  return plotsWithParameters;
}

export const fetchParameters = async (
  plotsWithoutData: PlotPreset,
  selectedLocation: PointerLocation,
  services: TimeSeriesService[],
  splitGeoJsonByCollection: GeoJsonWithService[],
): Promise<ParameterWithData[]> => {
  const serviceMap = services.reduce<Record<string, TimeSeriesService>>(
    (acc, service) => ({ ...acc, [service.id]: service }),
    {},
  );
  const geojsonMap = new Map(
    splitGeoJsonByCollection.map((gjs) => [
      `${gjs.serviceName}_${gjs.collectionName}`,
      gjs,
    ]),
  );

  const parameterRequests = plotsWithoutData.parameters.map(
    async (parameter) => {
      const service = serviceMap[parameter.serviceId];

      // If this parameter has a location, use it directly
      if (
        isTimeSeriesLocation(selectedLocation) &&
        selectedLocation.serviceId === parameter.serviceId &&
        selectedLocation.collectionId === parameter.collectionId
      ) {
        return getParameter(parameter, service, selectedLocation);
      }

      // Try a position query
      const position: PointerLocation = {
        lon: selectedLocation.lon,
        lat: selectedLocation.lat,
      };
      const result = await getParameter(parameter, service, position);
      if (result) {
        return result;
      }

      // As a final option look for a nearby location
      const nearbyLocation = await getNearbyLocation(
        parameter,
        service,
        selectedLocation,
        geojsonMap,
      );

      return nearbyLocation
        ? getParameter(parameter, service, nearbyLocation)
        : null;
    },
  );

  const results = await Promise.all(parameterRequests);
  return compact(results);
};

const getParameter = async (
  parameter: Parameter,
  service: TimeSeriesService,
  location: PointerLocation,
): Promise<ParameterWithData | null> => {
  return service.type === 'OGC'
    ? getOgcParameter(parameter, service.url, location.lon, location.lat)
    : getEdrParameter(parameter, service.url, location);
};

const getNearbyLocation = async (
  parameter: Parameter,
  service: TimeSeriesService,
  selectedLocation: PointerLocation,
  geojsonMap: Map<string, GeoJsonWithService>,
): Promise<TimeSeriesLocation | null> => {
  const collection = geojsonMap.get(
    `${service.name}_${parameter.collectionId}`,
  );
  if (!collection?.geoJson.features?.length) {
    return null;
  }
  const nearest = findNearestPoint(
    selectedLocation,
    collection.geoJson.features,
  );

  if (nearest) {
    const [nearestLon, nearestLat] = getFeatureCenterPoint(nearest);
    return {
      id: nearest.id as string,
      lon: nearestLon,
      lat: nearestLat,
      serviceId: parameter.serviceId,
      collectionId: parameter.collectionId,
    };
  }

  return null;
};

export const getUtcTime = (time: string): Date => {
  return dateUtils.utc(time);
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  layerActions,
  layerSelectors,
  serviceActions,
  layerTypes,
  CoreAppStore,
  serviceSelectors,
  serviceTypes,
} from '@opengeoweb/store';
import { queryWMSLayers, queryWMSServiceInfo } from '@opengeoweb/webmap';
import RenderLayers from './RenderLayers';

interface RenderLayersProps {
  layerId: string;
  mapId: string;
  tooltipPrefix?: string;
  skipLocalStyling?: boolean;
}

const RenderLayersConnect: React.FC<RenderLayersProps> = ({
  layerId,
  mapId,
  tooltipPrefix,
  skipLocalStyling,
}: RenderLayersProps) => {
  const dispatch = useDispatch();
  const layerName = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerName(store, layerId),
  );
  const layerService = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerService(store, layerId),
  );
  const isLayerEnabled = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerEnabled(store, layerId),
  );

  const service = useSelector((store: CoreAppStore) => {
    return serviceSelectors.getServiceByUrl(store, layerService);
  });

  const serviceSetLayers = React.useCallback(
    (payload: serviceTypes.SetLayersForServicePayload): void => {
      dispatch(serviceActions.serviceSetLayers(payload));
    },
    [dispatch],
  );

  const addService = async (serviceUrl: string): Promise<void> => {
    try {
      const layers = await queryWMSLayers(serviceUrl);
      const wmService = await queryWMSServiceInfo(serviceUrl);
      serviceSetLayers({
        id: wmService.id!,
        name: wmService.title,
        serviceUrl,
        layers,
        scope: 'user',
        abstract: wmService.abstract,
      });
    } catch (error) {
      console.warn(
        error instanceof Error ? error.message : 'addService failed',
      );
    }
  };

  if (!service) {
    void addService(layerService);
  }

  const layers = useSelector((store: CoreAppStore) => {
    return serviceSelectors.getLayersFromServiceSelector(store, layerService);
  });

  const layerChangeName = React.useCallback(
    ({ layerId, name }: layerTypes.SetLayerNamePayload) =>
      dispatch(
        layerActions.layerChangeName({
          layerId,
          name,
          mapId,
          origin: layerTypes.LayerActionOrigin.layerManager,
        }),
      ),
    [dispatch, mapId],
  );
  return (
    <RenderLayers
      layers={layers}
      layerName={layerName}
      onChangeLayerName={(name): void => {
        layerChangeName({ layerId, name });
      }}
      isEnabled={isLayerEnabled}
      tooltipPrefix={tooltipPrefix}
      skipLocalStyling={skipLocalStyling}
    />
  );
};

export default RenderLayersConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { WMLayer } from '@opengeoweb/webmap';
import { layerTypes } from '@opengeoweb/store';
import { AlertBanner, PROJECTION } from '@opengeoweb/shared';
import { MultiMapPreset, MultiMapViewConnect } from '../MultiMapViewConnect';
import { useHasSyncGroupWithLayerActions } from '../ModelRunInterval';

const useGetReferenceTimes = (
  inputLayer: WMLayer,
): { referenceTimes: string[]; error: Error | null } => {
  const [referenceTimes, setReferenceTimes] = React.useState<string[]>([]);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    const referenceTimes: string[] = [];
    const wmLayer = new WMLayer({ ...inputLayer });
    wmLayer
      .parseLayer()
      .then((layer: WMLayer) => {
        const refTimeDim = layer.getDimension('reference_time')!;
        /* Loop through all values of the dimension.
         A map cannot be used for iterating,  as the dimension values are not in an array (it is an API). */
        for (let j = 0; j < refTimeDim.size(); j += 1) {
          const referenceTimeValue = refTimeDim.getValueForIndex(j);
          referenceTimes.push(referenceTimeValue as string);
        }

        setReferenceTimes(referenceTimes);
      })
      .catch((error) => {
        setError(error as Error);
      });
  }, [inputLayer]);
  return { referenceTimes, error };
};

export interface InitialHarmTempAndPrecipProps {
  layers: {
    topRow: layerTypes.Layer;
    bottomRow: layerTypes.Layer;
    topRowSyncGroups?: string[];
    bottomRowSyncGroups?: string[];
  };
  multiLegend?: boolean;
}

export const HarmonieTempAndPrecipPreset: React.FC<
  InitialHarmTempAndPrecipProps
> = ({ layers, multiLegend = false }: InitialHarmTempAndPrecipProps) => {
  const displayLayerManagerAndLegendButtonInAllTopRows =
    !useHasSyncGroupWithLayerActions(layers && layers.topRowSyncGroups);
  const displayLayerManagerAndLegendButtonInAllBottomRows =
    !useHasSyncGroupWithLayerActions(layers && layers.bottomRowSyncGroups);
  /* Get all the reference times for this layer */
  const { referenceTimes, error } = useGetReferenceTimes(
    layers.topRow as WMLayer,
  );
  if (error) {
    return <AlertBanner title={error.message} />;
  }
  if (referenceTimes.length === 0) {
    return <div>Loading...</div>;
  }

  /* Helper to make a map layer prest */
  const makeMapPreset = (
    referenceTime: string,
    uniqueId: string,
    layer: layerTypes.Layer,
    syncGroupsIds: string[],
    col: number,
  ): MultiMapPreset => {
    return {
      id: uniqueId,
      title: `Harmonie run ${`${referenceTime
        .substring(8, 13)
        .replace('T', ' / ')}`}`,
      bbox: {
        left: -450651.2255879827,
        bottom: 6490531.093143953,
        right: 1428345.8183648037,
        top: 7438773.776232235,
      },
      srs: PROJECTION.EPSG_3857.value,
      componentType: 'MultiMap',
      layers: [
        {
          ...layer,
          id: uniqueId,
          dimensions: [
            {
              name: 'reference_time',
              currentValue: referenceTime,
            },
          ],
        },
      ],
      syncGroupsIds,
      displayLayerManagerAndLegendButtonInMap:
        col === 0 || displayLayerManagerAndLegendButtonInAllTopRows,
      displayDimensionSelectButtonInMap:
        col === 0 || displayLayerManagerAndLegendButtonInAllBottomRows,
    };
  };

  /* Make the preset */
  const nCols = 5;

  /* Add the harmonie air temperature layers with the different referencetimes */
  const mapsTemperature = Array.from({ length: nCols }, (_, index) => {
    const refTime = referenceTimes[referenceTimes.length - nCols + index];
    return makeMapPreset(
      refTime,
      `harm_air${index}`,
      layers.topRow,
      layers.topRowSyncGroups!,
      index,
    );
  });

  /* Add the harmonie precipitation layers with the different referencetimes */
  const mapsPrecipitation = Array.from({ length: nCols }, (_, index) => {
    const refTime = referenceTimes[referenceTimes.length - nCols + index];
    return makeMapPreset(
      refTime,
      `harm_precip${index}`,
      layers.bottomRow,
      layers.bottomRowSyncGroups!,
      index,
    );
  });
  const maps = mapsTemperature.concat(mapsPrecipitation);

  return (
    <MultiMapViewConnect
      rows={2}
      cols={5}
      maps={maps}
      multiLegend={multiLegend}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TimeSeriesService } from '@opengeoweb/shared';
import { produce } from 'immer';
import { Parameter, Plot } from '../TimeSeries/types';
import { TimeSeriesSelectList } from './TimeSeriesSelectList';
import { TimeSeriesModuleState } from '../../store/types';
import { TimeseriesCollectionDetail } from './utils';
import {
  getAllFilterChipsEnabled,
  getSearchFilter,
  getServiceFilterChips,
} from '../../store/selectors';
import { getCollectionListForTimeSeriesService } from '../../utils/edrUtils';

interface TimeSeriesSelectListConnectProps {
  selectPlot: Plot;
  handleAddOrRemoveClick: (
    parameter: Parameter,
    plotHasParameter: boolean,
  ) => void;
  services: TimeSeriesService[];
}

export const TimeSeriesSelectListConnect: FC<TimeSeriesSelectListConnectProps> =
  memo(
    ({
      selectPlot,
      handleAddOrRemoveClick,
      services,
    }: TimeSeriesSelectListConnectProps) => {
      const [collectionList, setCollectionList] = useState<
        TimeseriesCollectionDetail[]
      >([]);
      const [collectionListItemDetails, setCollectionListItemDetails] =
        useState<Record<string, boolean>>({});

      const filterChipsServices = useSelector((store: TimeSeriesModuleState) =>
        getServiceFilterChips(store),
      );
      const allFilterChipsEnabled = useSelector(
        (store: TimeSeriesModuleState) => getAllFilterChipsEnabled(store),
      );

      const searchFilter = useSelector((state: TimeSeriesModuleState) =>
        getSearchFilter(state),
      );

      useEffect(() => {
        services.forEach(async (service) => {
          void getCollectionListForTimeSeriesService(service).then(
            (newServiceCollectionList) => {
              // Update the state when each service returns, so the list grows gradually when services complete.
              setCollectionList(
                produce((draft: TimeseriesCollectionDetail[]) => {
                  newServiceCollectionList.forEach((newColl) => {
                    const { collectionId, serviceId } = newColl;
                    // Find the collection in the list by collection id and serviceId
                    const collIndex = draft.findIndex((draftCollection) => {
                      return (
                        draftCollection.collectionId === collectionId &&
                        draftCollection.serviceId === serviceId
                      );
                    });
                    // If not found push it to the list, otherwise assign collection to existing index
                    collIndex !== -1 &&
                    draft[collIndex].serviceId === newColl.serviceId
                      ? (draft[collIndex] = newColl)
                      : draft.push(newColl);
                  });
                }, collectionList),
              );
            },
          );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [services]);

      return (
        <TimeSeriesSelectList
          selectPlot={selectPlot}
          collectionList={collectionList}
          handleAddOrRemoveClick={handleAddOrRemoveClick}
          collectionListItemDetails={collectionListItemDetails}
          setCollectionListItemDetails={setCollectionListItemDetails}
          searchFilter={searchFilter}
          filterChipsServices={filterChipsServices}
          allFilterChipsEnabled={allFilterChipsEnabled}
        />
      );
    },
  );

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  isEmpty,
  isInteger,
  isValidMax,
  isValidMin,
  ReactHookFormHiddenInput,
  ReactHookFormSelect,
  ReactHookFormNumberField,
  ReactHookFormFormControl,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';
import {
  CloudLevelUnits,
  ConfigurableFormFieldProps,
  FIRConfigAirmet,
  ProductConfig,
} from '../../../types';
import { styles } from '../ProductForm.styles';
import {
  getAllowedUnits,
  getActiveFIRArea,
  getMaxCloudLevelValue,
  getMaxCloudLowerLevelValue,
  getMinCloudLevelValue,
  getMinCloudLowerLevelValue,
  isLevelLower,
  triggerValidations,
} from '../utils';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

export const DEFAULT_ROUNDING_CLOUD_LEVELS_FT = 100;
export const DEFAULT_ROUNDING_CLOUD_LEVELS_M_BELOW = 30;
export const DEFAULT_ROUNDING_CLOUD_LEVELS_M_ABOVE = 300;

export const getInvalidStepsForFeetUnitMessage = (
  value = DEFAULT_ROUNDING_CLOUD_LEVELS_FT,
): string => `A level must be rounded to the nearest ${value} ft`;

export const getInvalidStepsForMetersUnitBelow2970Message = (
  value = DEFAULT_ROUNDING_CLOUD_LEVELS_M_BELOW,
): string => `A level must be rounded to the nearest ${value} m`;

export const getInvalidStepsForMetersUnitAbove2970Message = (
  value = DEFAULT_ROUNDING_CLOUD_LEVELS_M_ABOVE,
): string => `A level must be rounded to the nearest ${value} m`;

export const invalidUnitMessage = 'Level unit not defined';

export const validateRoundedStep = (
  value: string,
  unit: string,
  productConfig: ProductConfig,
  selectedFIR: string,
): boolean | string => {
  if (isEmpty(value)) {
    return true;
  }
  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    cloud_level_rounding_ft = DEFAULT_ROUNDING_CLOUD_LEVELS_FT,
    cloud_level_rounding_m_below = DEFAULT_ROUNDING_CLOUD_LEVELS_M_BELOW,
    cloud_level_rounding_m_above = DEFAULT_ROUNDING_CLOUD_LEVELS_M_ABOVE,
    /* eslint-enable @typescript-eslint/naming-convention */
  } = getActiveFIRArea(selectedFIR, productConfig) as FIRConfigAirmet;

  if (
    CloudLevelUnits[unit as keyof typeof CloudLevelUnits] === CloudLevelUnits.FT
  ) {
    return parseInt(value, 10) % cloud_level_rounding_ft === 0
      ? true
      : getInvalidStepsForFeetUnitMessage(cloud_level_rounding_ft);
  }
  if (
    CloudLevelUnits[unit as keyof typeof CloudLevelUnits] === CloudLevelUnits.M
  ) {
    if (value <= '2970') {
      return parseInt(value, 10) % cloud_level_rounding_m_below === 0
        ? true
        : getInvalidStepsForMetersUnitBelow2970Message(
            cloud_level_rounding_m_below,
          );
    }
    return parseInt(value, 10) % cloud_level_rounding_m_above === 0
      ? true
      : getInvalidStepsForMetersUnitAbove2970Message(
          cloud_level_rounding_m_above,
        );
  }
  return invalidUnitMessage;
};

const CloudLevels: React.FC<ConfigurableFormFieldProps> = ({
  isDisabled,
  isReadOnly,
  onChange = (): void => {},
  productConfig,
}: ConfigurableFormFieldProps) => {
  const { watch, setValue, trigger, getValues, unregister } = useFormContext();
  const { isRequired } = useDraftFormHelpers();
  const { t } = useSigmetAirmetTranslation();

  const getFIRValue = (): string => getValues('locationIndicatorATSR');
  const cloudLevelInfoMode = watch('cloudLevelInfoMode');

  // Get allowed cloud level units based on selected FIR - if no FIR selected, allow all default units
  const allowedCloudLevelUnitsForFir = getAllowedUnits(
    getFIRValue(),
    productConfig,
    'cloud_level_unit',
    CloudLevelUnits,
  );

  React.useEffect(() => {
    if (
      cloudLevelInfoMode === 'BETW_SFC' ||
      cloudLevelInfoMode === 'BETW_SFC_ABV'
    ) {
      unregister('cloudLowerLevel');
    }
  }, [cloudLevelInfoMode, unregister]);

  return (
    <Grid container spacing={2} sx={styles.containerItem}>
      <Grid container justifyContent="flex-end" size={4}>
        <Typography variant="subtitle1" sx={styles.label}>
          {t('levels')}
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-start" size={8}>
        <Grid size={12}>
          <ReactHookFormHiddenInput
            name="cloudLevelInfoMode"
            defaultValue="BETW"
          />
          <Grid container size={12}>
            <Grid size={4}>
              {(!isReadOnly ||
                cloudLevelInfoMode === 'BETW_ABV' ||
                cloudLevelInfoMode === 'BETW_SFC_ABV') && (
                <ReactHookFormFormControl
                  disabled={isDisabled}
                  isReadOnly={isReadOnly}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          cloudLevelInfoMode === 'BETW_ABV' ||
                          cloudLevelInfoMode === 'BETW_SFC_ABV'
                        }
                        onChange={(): void => {
                          switch (getValues('cloudLevelInfoMode')) {
                            case 'BETW':
                              setValue('cloudLevelInfoMode', 'BETW_ABV', {
                                shouldDirty: true,
                              });
                              break;
                            case 'BETW_ABV':
                              setValue('cloudLevelInfoMode', 'BETW', {
                                shouldDirty: true,
                              });
                              break;
                            case 'BETW_SFC':
                              setValue('cloudLevelInfoMode', 'BETW_SFC_ABV', {
                                shouldDirty: true,
                              });
                              break;
                            case 'BETW_SFC_ABV':
                              setValue('cloudLevelInfoMode', 'BETW_SFC', {
                                shouldDirty: true,
                              });
                              break;
                            default:
                              break;
                          }
                        }}
                        name="checkedAbove"
                        color="secondary"
                        disabled={isDisabled}
                      />
                    }
                    data-testid="cloudLevels-Above"
                    label="Above"
                  />
                </ReactHookFormFormControl>
              )}
            </Grid>
            <Grid size={3}>
              <ReactHookFormSelect
                name="cloudLevel.unit"
                label="Unit"
                data-testid="cloudLevel-unit"
                rules={{ validate: { isRequired } }}
                size="small"
                sx={styles.unit}
                disabled={isDisabled}
                isReadOnly={isReadOnly}
                defaultValue={'FT' as CloudLevelUnits}
                onChange={(): void => {
                  // Unit change is reflected in the Lower Cloud Level
                  setValue(
                    'cloudLowerLevel.unit',
                    getValues('cloudLevel.unit'),
                  );
                  triggerValidations(
                    ['cloudLevel.value', 'cloudLowerLevel.value'],
                    getValues,
                    trigger,
                  );
                  onChange();
                }}
              >
                {Object.keys(allowedCloudLevelUnitsForFir).map((key) => (
                  <MenuItem value={key} key={key}>
                    {allowedCloudLevelUnitsForFir[key]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid size={5}>
              <ReactHookFormNumberField
                name="cloudLevel.value"
                label="Upper level"
                data-testid="cloudLevel-value"
                size="small"
                sx={styles.levelField}
                rules={{
                  validate: {
                    isRequired,
                    isInteger,
                    min: (value): boolean | string =>
                      // The min level depends on the unit
                      isValidMin(
                        value,
                        getMinCloudLevelValue(
                          getValues('cloudLevel.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      ) ||
                      `The minimum level in ${
                        CloudLevelUnits[
                          getValues(
                            'cloudLevel.unit',
                          ) as keyof typeof CloudLevelUnits
                        ]
                      } is ${getMinCloudLevelValue(
                        getValues('cloudLevel.unit'),
                        getFIRValue(),
                        productConfig,
                      )}`,
                    max: (value): boolean | string =>
                      // The max level depends on the unit
                      isValidMax(
                        value,
                        getMaxCloudLevelValue(
                          getValues('cloudLevel.unit'),
                          getFIRValue(),
                          productConfig,
                        ),
                      ) ||
                      `The maximum level in ${
                        CloudLevelUnits[
                          getValues(
                            'cloudLevel.unit',
                          ) as keyof typeof CloudLevelUnits
                        ]
                      } is ${getMaxCloudLevelValue(
                        getValues('cloudLevel.unit'),
                        getFIRValue(),
                        productConfig,
                      )}`,
                    step: (value): boolean | string =>
                      validateRoundedStep(
                        value,
                        getValues('cloudLevel.unit'),
                        productConfig,
                        getFIRValue(),
                      ),
                  },
                }}
                disabled={isDisabled}
                isReadOnly={isReadOnly}
                onChange={(): void => {
                  triggerValidations(
                    ['cloudLowerLevel.value'],
                    getValues,
                    trigger,
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container size={12}>
            <Grid size={4}>
              {(!isReadOnly ||
                cloudLevelInfoMode === 'BETW_SFC' ||
                cloudLevelInfoMode === 'BETW_SFC_ABV') && (
                <ReactHookFormFormControl
                  disabled={isDisabled}
                  isReadOnly={isReadOnly}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          cloudLevelInfoMode === 'BETW_SFC' ||
                          cloudLevelInfoMode === 'BETW_SFC_ABV'
                        }
                        onChange={(): void => {
                          switch (getValues('cloudLevelInfoMode')) {
                            case 'BETW':
                              setValue('cloudLevelInfoMode', 'BETW_SFC', {
                                shouldDirty: true,
                              });
                              break;
                            case 'BETW_ABV':
                              setValue('cloudLevelInfoMode', 'BETW_SFC_ABV', {
                                shouldDirty: true,
                              });
                              break;
                            case 'BETW_SFC':
                              setValue('cloudLevelInfoMode', 'BETW', {
                                shouldDirty: true,
                              });
                              break;
                            case 'BETW_SFC_ABV':
                              setValue('cloudLevelInfoMode', 'BETW_ABV', {
                                shouldDirty: true,
                              });
                              break;
                            default:
                              break;
                          }
                        }}
                        name="checkedSFC"
                        color="secondary"
                        disabled={isDisabled}
                      />
                    }
                    data-testid="cloudLevels-SFC"
                    label="SFC"
                  />
                </ReactHookFormFormControl>
              )}
            </Grid>
            {cloudLevelInfoMode !== 'BETW_SFC' &&
              cloudLevelInfoMode !== 'BETW_SFC_ABV' && (
                <>
                  <Grid size={3}>
                    <ReactHookFormSelect
                      name="cloudLowerLevel.unit"
                      label="Unit"
                      data-testid="cloudLowerLevel-unit"
                      rules={{ validate: { isRequired } }}
                      size="small"
                      sx={styles.unit}
                      disabled={isDisabled}
                      isReadOnly={isReadOnly}
                      defaultValue={
                        getValues('cloudLevel.unit')
                          ? getValues('cloudLevel.unit')
                          : ('FT' as CloudLevelUnits)
                      }
                      onChange={(): void => {
                        // Unit change is reflected in the Upper Cloud Level
                        setValue(
                          'cloudLevel.unit',
                          getValues('cloudLowerLevel.unit'),
                        );
                        triggerValidations(
                          ['cloudLowerLevel.value', 'cloudLevel.value'],
                          getValues,
                          trigger,
                        );
                        onChange();
                      }}
                    >
                      {Object.keys(allowedCloudLevelUnitsForFir).map((key) => (
                        <MenuItem value={key} key={key}>
                          {allowedCloudLevelUnitsForFir[key]}
                        </MenuItem>
                      ))}
                    </ReactHookFormSelect>
                  </Grid>
                  <Grid size={5}>
                    <ReactHookFormNumberField
                      name="cloudLowerLevel.value"
                      label="Lower level"
                      data-testid="cloudLowerLevel-value"
                      rules={{
                        validate: {
                          isRequired,
                          isInteger,
                          min: (value): boolean | string =>
                            // The min level depends on the unit
                            isValidMin(
                              value,
                              getMinCloudLowerLevelValue(
                                getValues('cloudLowerLevel.unit'),
                                getFIRValue(),
                                productConfig,
                              ),
                            ) ||
                            `The minimum level in ${
                              CloudLevelUnits[
                                getValues(
                                  'cloudLowerLevel.unit',
                                ) as keyof typeof CloudLevelUnits
                              ]
                            } is ${getMinCloudLowerLevelValue(
                              getValues('cloudLowerLevel.unit'),
                              getFIRValue(),
                              productConfig,
                            )}`,
                          max: (value): boolean | string =>
                            // The max level depends on the unit
                            isValidMax(
                              value,
                              getMaxCloudLowerLevelValue(
                                getValues('cloudLowerLevel.unit'),
                                getFIRValue(),
                                productConfig,
                              ),
                            ) ||
                            `The maximum level in ${
                              CloudLevelUnits[
                                getValues(
                                  'cloudLowerLevel.unit',
                                ) as keyof typeof CloudLevelUnits
                              ]
                            } is ${getMaxCloudLowerLevelValue(
                              getValues('cloudLowerLevel.unit'),
                              getFIRValue(),
                              productConfig,
                            )}`,
                          isLevelLower: (value): boolean | string =>
                            // The lower level needs to be smaller than upper level
                            isLevelLower(
                              value,
                              getValues('cloudLowerLevel.unit'),
                              getValues('cloudLevel.value'),
                              getValues('cloudLevel.unit'),
                            ),
                          step: (value): boolean | string =>
                            validateRoundedStep(
                              value,
                              getValues('cloudLowerLevel.unit'),
                              productConfig,
                              getFIRValue(),
                            ),
                        },
                      }}
                      size="small"
                      disabled={isDisabled}
                      isReadOnly={isReadOnly}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CloudLevels;

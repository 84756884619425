/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { MenuItem, Grid2 as Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ConfigType,
  UserMenu as SharedUserMenu,
  useUserMenu,
  getInitials,
  UserMenuItemTheme,
  UserMenuItemCheatSheet,
  UserMenuItemSentryRecording,
} from '@opengeoweb/shared';
import {
  useApiContext,
  useApi,
  GEOWEB_ROLE_PRESETS_ADMIN,
  Role,
} from '@opengeoweb/api';
import {
  useAuthenticationContext,
  UserMenuRolesConnect,
} from '@opengeoweb/authentication';
import { LanguageSelect, saveLanguageToLocalStorage } from '@opengeoweb/core';
import { ProjectDocInfo } from './ProjectDocInfo';
import ReleaseNotes from './ReleaseNotes';
import { AppApi } from '../utils/api';
import { useGeowebTranslation } from '../../i18n';

const styles = {
  username: {
    fontWeight: 500,
  },
  versionmenu: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  },
};

interface UserProps {
  userName: string;
  userRoles?: Role[];
  isAuthConfigured?: boolean;
  config?: ConfigType;
  recordButtonEnabled?: boolean;
  setRecordButtonEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  sentryInitialized?: boolean;
}

const UserMenu: React.FC<UserProps> = ({
  userName,
  userRoles,
  isAuthConfigured = false,
  config,
  recordButtonEnabled,
  setRecordButtonEnabled,
  sentryInitialized,
}: UserProps) => {
  const { t } = useGeowebTranslation();
  const { api } = useApiContext<AppApi>();
  const navigate = useNavigate();
  const { isOpen, onClose, onToggle } = useUserMenu();
  const { error, result } = useApi(api.getBeVersion, config);
  const [showReleaseNotes, setShowReleaseNotes] = React.useState(false);
  const [showFeReleaseNotes, setShowFeReleaseNotes] = React.useState(false);
  const { currentRole, setCurrentRole } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;
  const beVersionLoadingMsg = error
    ? t('geoweb-user-menu-loading-failed', { errorMessage: error.message })
    : t('geoweb-user-menu-loading');

  const feVersion =
    process.env.NX_PUBLIC_GEOWEB_COMMIT || process.env.NX_PUBLIC_GEOWEB_VERSION;

  // For KNMI, set GW_FEATURE_MENU_VERSION to true to display the link pointing towards beReleaseNotesPageLink. To display technical notes, also add GW_TECHNICAL_RELEASE_NOTES_URL with the appropriate link
  // For FMI and MetNo, set GW_FEATURE_MENU_FE_VERSION to true and if appropriate the correct link via GW_VERSION_PROGRESS_NOTES_URL (otherwise it will point to gitlab). To display technical notes, also add GW_TECHNICAL_RELEASE_NOTES_URL with the appropriate link

  const beReleaseNotesPageLink = `http://confluence.knmi.nl/display/GW/Release+notes`;

  const feVersionProgressNotesLink =
    config?.GW_VERSION_PROGRESS_NOTES_URL ||
    'https://gitlab.com/groups/opengeoweb/-/wikis/Version-Progress-Notes';

  let feTechnicalReleaseNotesLink;

  if (config?.GW_TECHNICAL_RELEASE_NOTES_URL) {
    feTechnicalReleaseNotesLink = config.GW_TECHNICAL_RELEASE_NOTES_URL;
  } else if (process.env.NX_PUBLIC_GEOWEB_COMMIT) {
    feTechnicalReleaseNotesLink = `https://gitlab.com/opengeoweb/opengeoweb/-/commits/${process.env.NX_PUBLIC_GEOWEB_COMMIT}`;
  } else {
    feTechnicalReleaseNotesLink = `https://gitlab.com/opengeoweb/opengeoweb/-/releases/v${process.env.NX_PUBLIC_GEOWEB_VERSION}`;
  }

  const handleLogout = (): void => {
    onClose();
    navigate('/logout');
  };

  const handleLogin = (): void => {
    onClose();
    navigate('/login');
  };

  const openReleaseNotes = (): void => {
    onClose();
    setShowReleaseNotes(true);
  };

  const openFeReleaseNotes = (): void => {
    onClose();
    setShowFeReleaseNotes(true);
  };

  const onOpenFeedback = (): void => {
    window.open('http://confluence.knmi.nl/display/GW/Feedback', '_blank');
  };

  const isGuestUser = userName === 'Guest';

  const loginLogoutObject = isGuestUser ? (
    <MenuItem onClick={handleLogin} data-testid="loginButton">
      {t('geoweb-user-menu-login')}
    </MenuItem>
  ) : (
    <MenuItem onClick={handleLogout} data-testid="logoutButton">
      {t('geoweb-user-menu-logout')}
    </MenuItem>
  );

  return (
    <>
      <SharedUserMenu
        isOpen={isOpen}
        onClose={onClose}
        onToggle={onToggle}
        initials={getInitials(userName)}
        isAdmin={isAdmin}
      >
        <MenuItem selected divider>
          <Grid container>
            <Grid sx={styles.username}>{userName}</Grid>
          </Grid>
        </MenuItem>

        {!isGuestUser && (
          <MenuItem divider>
            <UserMenuRolesConnect
              roles={userRoles}
              temporaryOnChangeRoleForDemo={setCurrentRole}
              isAdmin={isAdmin}
            />
          </MenuItem>
        )}

        <MenuItem divider sx={{ padding: '11px 16px' }}>
          <LanguageSelect onChangeLanguage={saveLanguageToLocalStorage} />
        </MenuItem>

        <UserMenuItemTheme
          enableSpecialThemes={config?.GW_FEATURE_ENABLE_SPECIAL_THEMES}
        />
        {config?.GW_FEATURE_MENU_VERSION && (
          <MenuItem
            data-testid="be-version-menu"
            onClick={openReleaseNotes}
            divider
            sx={styles.versionmenu}
          >
            {!result
              ? beVersionLoadingMsg
              : t('geoweb-user-menu-version-has-been-released', {
                  version: result.version,
                })}
          </MenuItem>
        )}
        {config?.GW_FEATURE_MENU_FE_VERSION && feVersion && (
          <MenuItem
            data-testid="fe-version-menu"
            onClick={openFeReleaseNotes}
            divider
            sx={styles.versionmenu}
          >
            {t('geoweb-user-menu-release-notes')}
            <br />
            {`v${feVersion}`}
          </MenuItem>
        )}
        <UserMenuItemCheatSheet />
        {sentryInitialized && (
          <UserMenuItemSentryRecording
            recordButtonEnabled={recordButtonEnabled}
            setRecordButtonEnabled={setRecordButtonEnabled}
          />
        )}

        {config?.GW_FEATURE_MENU_FEEDBACK && (
          <MenuItem
            data-testid="open-feedback"
            onClick={onOpenFeedback}
            divider
          >
            {t('geoweb-user-menu-feedback')}
          </MenuItem>
        )}
        {config?.GW_FEATURE_MENU_INFO && <ProjectDocInfo />}
        {isAuthConfigured ? loginLogoutObject : null}
      </SharedUserMenu>
      {config?.GW_FEATURE_MENU_VERSION && showReleaseNotes && (
        <ReleaseNotes
          version={!result ? beVersionLoadingMsg : result.version}
          config={config}
          handleClose={(): void => setShowReleaseNotes(false)}
          releasePage={feTechnicalReleaseNotesLink}
          versionProgressNotesPage={beReleaseNotesPageLink}
        />
      )}
      {config?.GW_FEATURE_MENU_FE_VERSION && showFeReleaseNotes && (
        <ReleaseNotes
          version={feVersion!}
          config={config}
          handleClose={(): void => setShowFeReleaseNotes(false)}
          releasePage={feTechnicalReleaseNotesLink}
          versionProgressNotesPage={feVersionProgressNotesLink}
        />
      )}
    </>
  );
};

export default UserMenu;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import Box from '@mui/material/Box';
import { AdverseWeatherTAC } from '../../../types';

interface AdverseWeatherTACProps {
  TAC: AdverseWeatherTAC;
  isActive: boolean;
}

export const AdverseWeatherTACMessage: React.FC<AdverseWeatherTACProps> = ({
  TAC,
  isActive,
}: AdverseWeatherTACProps) => {
  return (
    <Box
      data-testid="adverse-weather-tac"
      sx={{
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: 'geowebColors.captions.captionStatus.rgba',
      }}
    >
      {TAC.tac.map((line, index) => (
        <Box
          key={line.toString() + index.toString()}
          component="span"
          sx={{
            display: 'block',
          }}
        >
          {line.map((word, index) => (
            <Box
              key={word.text + index.toString()}
              component="span"
              sx={{
                display: 'inline',
                boxDecorationBreak: 'clone',
                WebkitBoxDecorationBreak: 'clone',
                ...(isActive && {
                  backgroundColor:
                    'geowebColors.functional.warningHighlight.rgba',
                }),
                ...(word.type === 'adverse' && {
                  color: 'geowebColors.captions.captionError.rgba',
                  fontWeight: 'bold',
                }),
              }}
            >
              {word.text === '=' ? `${word.text}` : ` ${word.text}`}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

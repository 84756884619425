/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CoreAppStore,
  layerActions,
  layerSelectors,
  layerTypes,
} from '@opengeoweb/store';

import OpacitySelect from './OpacitySelect';

interface OpacitySelectConnectProps {
  layerId: string;
  mapId: string;
  tooltipPrefix?: string;
}

const OpacitySelectConnect: React.FC<OpacitySelectConnectProps> = ({
  layerId,
  mapId,
  tooltipPrefix,
}: OpacitySelectConnectProps) => {
  const dispatch = useDispatch();
  const opacity = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerOpacity(store, layerId),
  );
  const isLayerEnabled = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerEnabled(store, layerId),
  );

  return (
    <OpacitySelect
      currentOpacity={opacity}
      onLayerChangeOpacity={(newOpacity: number): void => {
        dispatch(
          layerActions.layerChangeOpacity({
            layerId,
            opacity: newOpacity,
            mapId,
            origin: layerTypes.LayerActionOrigin.layerManager,
          }),
        );
      }}
      isEnabled={isLayerEnabled}
      tooltipPrefix={tooltipPrefix}
    />
  );
};

/**
 * Contains a select allowing you to change the opacity of a layer on the map
 *
 * Expects the following props:
 * @param {string} layerId layerId: string - Id of the layer
 * @example
 * ``` <OpacitySelectConnect layerId="layerid_1"/> ```
 */
export default OpacitySelectConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { dateUtils } from '@opengeoweb/shared';
import { Dimension, TimeInterval } from './types';

import {
  WMDateOutSideRange,
  WMDateTooEarlyString,
  WMDateTooLateString,
  WMDateUnit,
} from './WMConstants';
import {
  isDefined,
  debugLogger,
  DebugType,
  getFirstPartOfDimensionValueSet,
} from './WMJSTools';
import {
  calculateTimeIntervalFromList,
  DateInterval,
  isTimeOnIntervalStep,
  parseISO8601DateToDate,
  parseISO8601IntervalToDateInterval,
  ParseISOTimeRangeDuration,
} from './WMTime';
import { CustomDate } from './WMTimeTypes';

/**
 * Re-formats isoString to the string that is expected by WMJSDimension (for time).
 * @param dateIn The input date to re-format, usually in the format `YYYY-MM-DDThh:mm:ss.uuuZ`
 * @returns The formated input date in the format `YYYY-MM-DDThh:mm:ssZ`
 */
export const handleDateUtilsISOString = (dateIn: string): string => {
  /* Try to fix the timestrings generated by our dateUtils made with dateUtils.dateToIsoString() */
  if (!dateIn) {
    return null!;
  }

  if (dateIn.length > 20) {
    const fixedDate = dateIn.substring(0, 19);

    return `${fixedDate}Z`;
  }

  if (dateIn.length === 17) {
    const fixedDate = dateIn.substring(0, 16);

    return `${fixedDate}:00Z`;
  }
  return dateIn;
};

interface WMJSDimensionConfig {
  name?: string;
  units?: string;
  values?: string;
  currentValue?: string;
  defaultValue?: string;
  linked?: boolean;
  unitSymbol?: string;
  synced?: boolean;
}

/**
 * WMJSDimension Class
 * Keep all information for a single dimension, like time.
 * Author : MaartenPlieger (plieger at knmi.nl)
 * Copyright KNMI
 */
export default class WMJSDimension implements Dimension {
  currentValue!: string;

  defaultValue!: string;

  name: string = undefined!; // Name of the dimension, e.g. 'time'

  units: string = undefined!; // Units of the dimension, e.g. 'ISO8601'

  unitSymbol: string = undefined!; // Unit symbol, eg: hPa

  linked = true;

  synced = false;

  used?: boolean;

  values?: string; // Values of the dimension, according to values defined in WMS specification, e.g. 2011-01-01T00:00:00Z/2012-01-01T00:00:00Z/P1M or list of values.

  private timeRangeDuration?: string;

  private _initialized = false;

  private _timeRangeDurationDate: ParseISOTimeRangeDuration | null = null; // Used for timerange (start/stop/res)

  private _allDates: CustomDate[] = []; // Used for individual timevalues

  private _type: 'timestartstopres' | 'timevalues' | 'anyvalue' = null!;

  private _allValues: string[] = [];

  private dimMinValue!: string;

  private dimMaxValue!: string;

  private dimTimeInterval!: DateInterval;

  private calculatedTimeInterval!: DateInterval;

  constructor(config?: WMJSDimensionConfig) {
    this.setTimeValuesForReferenceTime =
      this.setTimeValuesForReferenceTime.bind(this);

    this.generateAllValues = this.generateAllValues.bind(this);
    this.reInitializeValues = this.reInitializeValues.bind(this);
    this.initialize = this.initialize.bind(this);
    this.getValue = this.getValue.bind(this);
    this.setValue = this.setValue.bind(this);
    this.getValues = this.getValues.bind(this);
    this.processCompressedRefTimeValues =
      this.processCompressedRefTimeValues.bind(this);
    this.createTimeStepsArray = this.createTimeStepsArray.bind(this);
    this.setClosestValue = this.setClosestValue.bind(this);
    this.addTimeRangeDurationToValue =
      this.addTimeRangeDurationToValue.bind(this);
    this.setTimeRangeDuration = this.setTimeRangeDuration.bind(this);
    this.getClosestValue = this.getClosestValue.bind(this);
    this.getValueForIndex = this.getValueForIndex.bind(this);
    this.get = this.get.bind(this);
    this.getFirstValue = this.getFirstValue.bind(this);
    this.getMiddleValue = this.getMiddleValue.bind(this);
    this.getValueForSpecialString = this.getValueForSpecialString.bind(this);
    this.getExactMatchingValue = this.getExactMatchingValue.bind(this);

    this.getLastValue = this.getLastValue.bind(this);
    this.getDimInterval = this.getDimInterval.bind(this);

    this.getIndexForValue = this.getIndexForValue.bind(this);
    this.size = this.size.bind(this);
    this.clone = this.clone.bind(this);

    if (isDefined(config)) {
      if (isDefined(config.name)) {
        this.name = config.name.toLowerCase();
      }
      if (isDefined(config.units)) {
        this.units = config.units;
      }
      if (isDefined(config.unitSymbol)) {
        this.unitSymbol = config.unitSymbol;
      }
      if (isDefined(config.values)) {
        this.values = config.values;
        if (!this.units) {
          // If unit was not set, check if this is a ISO8601 period.
          const lastItem = this.values.split('/').at(-1);
          if (lastItem?.startsWith('P') && lastItem?.indexOf('T') !== -1) {
            this.units = WMDateUnit;
          }
        }
      }
      if (isDefined(config.currentValue)) {
        this.currentValue = config.currentValue;
      }
      if (isDefined(config.defaultValue)) {
        this.defaultValue = config.defaultValue;
      }
      if (isDefined(config.linked)) {
        this.linked = config.linked;
      }
      if (isDefined(config.synced)) {
        this.synced = config.synced;
      }
    }
  }

  generateAllValues(): number | string[] {
    const vals: number | string[] = [];
    if (this.size() > 5000) {
      throw new Error(
        'Error: Dimension too large to query all possible values at once',
      );
    }

    for (let i = 0; i < this.size(); i += 1) {
      vals.push(this.getValueForIndex(i) as string);
    }

    return vals;
  }

  setTimeValuesForReferenceTime(
    referenceTimeValueToSet: string,
    referenceTimeDim: WMJSDimension,
  ): void {
    if (!this._initialized) {
      this.initialize();
    }
    if (!referenceTimeValueToSet) {
      this.reInitializeValues(this.values!);
      this.setClosestValue(null);
      debugLogger(DebugType.Warning, 'returning');
      return;
    }

    /*
     * Calculate the model run length,
     * this can be done by looking at the difference between
     * the last dim_reference_time and last dim_time
     */

    /* 1. Get the last value in the list of the referencetime */
    const lastRefTime = new Date(referenceTimeDim.getLastValue());

    /* 2. Get the original last value of the time dimension, we cannot use this.getLastValue(), as we are changing this. */
    const orgLastDimTime = new Date(this.values!.split('/')[1]);

    /* 3. Now calculate the run length */
    const runLengthAsHour = dateUtils.differenceInHours(
      orgLastDimTime,
      lastRefTime,
    );

    const newStartValueTime = new Date(referenceTimeValueToSet);

    const newEndValueTime = dateUtils.add(
      new Date(newStartValueTime.getTime()),
      {
        hours: runLengthAsHour,
      },
    );

    if (this._type === 'timestartstopres') {
      // Compose a new dateString interval and re-initialize the time dimension if the
      // calculated start and end values are valid for the time dimension interval
      const [start, , interval] = this.values!.split('/').map((s) => s.trim());
      const intervalStart = new Date(start);
      const dateInterval = parseISO8601IntervalToDateInterval(interval);
      if (
        isTimeOnIntervalStep(newStartValueTime, intervalStart, dateInterval) &&
        isTimeOnIntervalStep(newEndValueTime, intervalStart, dateInterval)
      ) {
        const newValue = `${newStartValueTime.toISOString()}/${newEndValueTime.toISOString()}/${interval}`;
        this.defaultValue = newEndValueTime.toISOString();
        this.reInitializeValues(newValue, true);
      }
    } else if (this._type === 'timevalues') {
      /* Filter all dates from the array which are lower than given start value */
      /* TODO: Maarten Plieger 2020-06-10: Make this also work for WMS times advertised as a comma separated list */
      const newValue = parseISO8601DateToDate(newStartValueTime.toISOString());
      const newArray = this._allDates.filter((x) => x >= newValue);
      let newValues = '';
      for (let j = 0; j < newArray.length; j += 1) {
        if (j > 0) {
          newValues += ',';
        }
        newValues += newArray[j].toISO8601();
      }
      this.reInitializeValues(newValues, true);
      this.setClosestValue();
    }
  }

  reInitializeValues(values: string, forceReferenceTimeValue = false): void {
    this._initialized = false;
    this.initialize(values, forceReferenceTimeValue);
  }

  initialize(
    forceothervalues: string | undefined = undefined,
    forceReferenceTimeValue = false,
  ): void {
    if (this._initialized === true) {
      return;
    }
    let ogcdimvalues = this.values;
    if (forceothervalues) {
      ogcdimvalues = forceothervalues;
    }
    if (!isDefined(ogcdimvalues)) {
      return;
    }
    this._allValues = [];
    this._initialized = true;

    if (this.units === 'ISO8601') {
      if (ogcdimvalues.indexOf('/') > 0 && ogcdimvalues.indexOf(',') === -1) {
        this._type = 'timestartstopres';
        this._timeRangeDurationDate = new ParseISOTimeRangeDuration(
          ogcdimvalues,
        );
        this.processCompressedRefTimeValues(ogcdimvalues);
      } else {
        this._type = 'timevalues';
        if (
          ogcdimvalues.indexOf('/') > 0 &&
          ogcdimvalues.indexOf(',') === -1 &&
          this.name === 'reference_time'
        ) {
          this.processCompressedRefTimeValues(ogcdimvalues);
        }
      }
    } else {
      this._type = 'anyvalue';
      this.linked = false;
    }

    if (this._type !== 'timestartstopres') {
      const values = ogcdimvalues.split(',');
      values.forEach((value) => {
        const valuesRangedNoTrim = value.split('/');
        if (valuesRangedNoTrim.length === 3) {
          const valuesRanged = valuesRangedNoTrim.map((value) => value.trim());
          /* Can be either time like '2021-03-17T06:00:00Z/2021-03-21T00:00:00Z/PT6H' or something else, like '0/24/2' */
          if (valuesRanged[2].charAt(0) === 'P') {
            const partTimeRanges = new ParseISOTimeRangeDuration(value);
            for (let i = 0; i < partTimeRanges.getTimeSteps(); i += 1) {
              const dataAtTimeStep = partTimeRanges.getDateAtTimeStep(i);
              try {
                this._allValues.push(dataAtTimeStep.toISO8601());
              } catch (e) {
                this._allValues.push(dataAtTimeStep.toString());
              }
            }
            this.processCompressedRefTimeValues(ogcdimvalues!);
          } else {
            const start = parseFloat(valuesRanged[0]);
            let stop = parseFloat(valuesRanged[1]);
            let res = parseFloat(valuesRanged[2]);
            stop += res;
            if (start > stop) {
              stop = start;
            }
            if (res <= 0) {
              res = 1;
            }
            for (let j2 = start; j2 < stop; j2 += res) {
              this._allValues.push(String(j2 as unknown));
            }
          }
        } else {
          this._allValues.push(String(value as unknown));
        }
      });

      if (this._type === 'timevalues') {
        this._allDates.length = 0;
        this._allValues.forEach((value) => {
          this._allDates.push(parseISO8601DateToDate(value));
        });

        const isRangeFormat = this._allValues.some((value) =>
          value.includes('/'),
        );

        if (!isRangeFormat && this._allValues.length > 1) {
          this.calculatedTimeInterval = calculateTimeIntervalFromList(
            this._allValues,
          );
        }
      }
    }

    /* If no default value is given, take the last / most recent one */
    if (!isDefined(this.defaultValue) || this.defaultValue === '') {
      this.defaultValue = this.getLastValue();
    }

    /* Check if the string 'current' is given, and find the closest value inside the dimension */
    if (this.defaultValue === 'current') {
      this.defaultValue = this.getClosestValue(
        dateUtils.dateToString(dateUtils.utc())!,
        true,
      );
    }

    /* If no currentvalue is set, set the default */
    if (!this.currentValue || this.currentValue.length === 0) {
      this.currentValue = this.defaultValue;
    }

    /* Check for out of range values and adjust accordingly to a valid range */
    const index = this.getIndexForValue(this.currentValue);
    if (index === -2) {
      this.currentValue = this.getLastValue(); // Date is past the latest (-2), so set it to the last value
    }
    if (index === -1) {
      this.currentValue = this.getFirstValue(); // Date is past the first (-1), so set it to the first value
    }

    /* In case of reference time, the time dimension range is changed, check if the current time still fits in that range */
    if (forceReferenceTimeValue) {
      if (this.getIndexForValue(this.currentValue, true) < 0) {
        this.currentValue = this.getClosestValue(this.currentValue);
      }
    }

    this.dimMinValue = this.getFirstValue();
    this.dimMaxValue = this.getLastValue();
    if (this._timeRangeDurationDate) {
      this.dimTimeInterval = this._timeRangeDurationDate.timeInterval;
    } else {
      this.dimTimeInterval = this.calculatedTimeInterval;
    }
  }

  /**
   * Returns the current value of this dimensions.
   * If a ISO8601 period is set, the getValue will return a timeRange using addTimeRangeDurationToValue instead of a single value
   */
  getValue(): string {
    if (!this._initialized) {
      this.initialize();
    }
    let value = this.defaultValue;
    if (isDefined(this.currentValue)) {
      value = this.currentValue;
    }
    value = this.addTimeRangeDurationToValue(value);
    return value;
  }

  processCompressedRefTimeValues(ogcdimvalues: string): void {
    if (this.name === 'reference_time') {
      // Split the format start/stop/res
      const splitValues = ogcdimvalues.split(',');
      const timeRanges = splitValues.filter(
        (value) => value.includes('/') && value.includes('P'),
      );
      let allValues: string[] = [];

      // Get all the ranges between start and stop time
      timeRanges.forEach((timeRange) => {
        const partTimeRanges = new ParseISOTimeRangeDuration(timeRange);
        const newValues = this.createTimeStepsArray(partTimeRanges);
        allValues = allValues.concat(newValues);
      });

      // Some ogcdimvalues deliver the interval for arbitrary timesteps which may stop the array
      // In that case, get the last date to set as dimMaxValue
      const lastTimeRangeString = splitValues[splitValues.length - 1];
      if (
        lastTimeRangeString.includes('/') &&
        lastTimeRangeString.includes('P')
      ) {
        const [, endDateString] = lastTimeRangeString.split('/');
        this.dimMaxValue = endDateString;
      }

      this.values = allValues.join(',');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  createTimeStepsArray(partTimeRanges: ParseISOTimeRangeDuration): string[] {
    return Array.from({ length: partTimeRanges.getTimeSteps() }, (_, i) => {
      const dataAtTimeStep = partTimeRanges.getDateAtTimeStep(i);
      if (dataAtTimeStep && !isNaN(dataAtTimeStep.getTime())) {
        const isoDateString = dataAtTimeStep.toISO8601();
        return isoDateString;
      }
      return null;
    }).filter((dateString): dateString is string => dateString !== null);
  }

  /**
   * Set current value of this dimension
   * @param value The new value for this dimension (string)
   * @param forceValue When set to false,check if the given value is outside range, and do nothing if this is the case
   */
  setValue(value: string, forceValue = true): void {
    if (!this._initialized) {
      this.initialize();
    }
    if (forceValue === false) {
      if (
        value === WMDateOutSideRange ||
        value === WMDateTooEarlyString ||
        value === WMDateTooLateString
      ) {
        return;
      }
    }
    this.currentValue = value;
  }

  /**
   * Returns values of the dimension, according to values defined in WMS specification, e.g. 2011-01-01T00:00:00Z/2012-01-01T00:00:00Z/P1M or list of values.
   * @returns
   */
  getValues(): string {
    this.initialize();
    return this.values!;
  }

  setClosestValue(
    newValue: string | null = null,
    evenWhenOutsideRange = true,
  ): void {
    const newClosestValue = newValue || this.getValue();
    this.currentValue = this.getClosestValue(
      newClosestValue,
      evenWhenOutsideRange,
    );
  }

  addTimeRangeDurationToValue(value: string): string {
    if (
      value === WMDateOutSideRange ||
      value === WMDateTooEarlyString ||
      value === WMDateTooLateString
    ) {
      return value;
    }
    if (this.timeRangeDuration && this.timeRangeDuration.length > 0) {
      const interval = parseISO8601IntervalToDateInterval(
        this.timeRangeDuration,
      );
      const value2date = parseISO8601DateToDate(value);
      value2date.add(interval);
      const value2 = value2date.toISO8601();
      return `${value}/${value2}`;
    }
    return value;
  }

  // If a ISO8601 period is given, the getValue will return a timeRange instead of a single value
  setTimeRangeDuration(duration: string): void {
    this.timeRangeDuration = duration;
    if (duration && duration.length > 0) {
      this.reInitializeValues(this.values!);
      const startDate = parseISO8601DateToDate(this.dimMinValue);
      const stopDate = this.dimMaxValue;
      const interval = parseISO8601IntervalToDateInterval(
        this.timeRangeDuration,
      );
      if (interval.minute !== 0) {
        startDate.setUTCSeconds(0);
      }
      if (interval.hour !== 0) {
        startDate.setUTCSeconds(0);
        startDate.setUTCMinutes(0);
      }
      if (interval.day !== 0) {
        startDate.setUTCSeconds(0);
        startDate.setUTCMinutes(0);
        startDate.setUTCHours(0);
      }
      if (interval.month !== 0) {
        startDate.setUTCSeconds(0);
        startDate.setUTCMinutes(0);
        startDate.setUTCHours(0);
        startDate.setUTCDate(1);
      }
      this.reInitializeValues(
        `${startDate.toISO8601()}/${stopDate}/${this.timeRangeDuration}`,
      );
    } else {
      this.reInitializeValues(this.values!);
    }
  }

  getClosestValueForTime(timeStamp: number): string {
    const timeToFind = `${new Date(timeStamp).toISOString().substring(0, 19)}Z`;
    return this.getClosestValue(timeToFind);
  }

  getValueForSpecialString(inputValue: string): string {
    // Check for special cases like: 'current', 'default, '', 'middle', 'earliest' and 'latest'
    switch (inputValue) {
      case 'current':
      case 'default':
      case '':
        return this.defaultValue;
      case 'middle':
        return this.getMiddleValue();
      case 'earliest':
      case WMDateTooEarlyString:
        return this.getFirstValue();
      case 'latest':
      case WMDateTooLateString:
        return this.getLastValue();
      default:
    }
    return inputValue;
  }

  getExactMatchingValue(inputValue: string): string {
    try {
      const index = this.getIndexForValue(inputValue);
      return this.getValueForIndex(index) as string;
    } catch (e: unknown) {
      if (typeof (e as Error).message === 'number') {
        if ((e as Error).message === '0') {
          return WMDateTooEarlyString;
        }
        return WMDateTooLateString;
      }
    }
    return WMDateOutSideRange;
  }

  getClosestValue(inputValue: string, evenWhenOutsideRange = false): string {
    this.initialize();
    if (!this._initialized || inputValue === undefined || inputValue === null) {
      return inputValue;
    }

    // Just make sure to return the first part if accidently start/stop/res was given.
    const firstPartValue = getFirstPartOfDimensionValueSet(inputValue);

    // Handle  'current', 'default, '', 'middle', 'earliest' and 'latest'
    const newValue = this.getValueForSpecialString(firstPartValue);

    // Get the exact value as present in the dimension values
    const matchingValue = this.getExactMatchingValue(newValue);

    // Handle  'current', 'default, '', 'middle', 'earliest' and 'latest'
    return evenWhenOutsideRange
      ? this.getValueForSpecialString(matchingValue)
      : matchingValue;
  }

  /**
   * Get dimension value for specified index
   */
  getValueForIndex(index: number): string | CustomDate {
    this.initialize();
    if (!this._initialized) {
      return this.currentValue;
    }
    if (index < 0) {
      if (index === -1) {
        return WMDateTooEarlyString;
      }
      if (index === -2) {
        return WMDateTooLateString;
      }
      return -1 as unknown as string;
    }
    if (this._type === 'timestartstopres') {
      try {
        return this._timeRangeDurationDate!.getDateAtTimeStep(
          index,
        ).toISO8601();
      } catch (e) {
        // nothing
      }
      return this._timeRangeDurationDate!.getDateAtTimeStep(index);
    }
    if (this._type === 'timevalues' || this._type === 'anyvalue') {
      return this._allValues[index];
    }
    return null!;
  }

  /**
   * Hint about the timestep size / time resolution of this dimension.
   * @returns The dimTimeInterval
   */
  getDimInterval(): TimeInterval | undefined {
    this.initialize();
    if (!this.dimTimeInterval) {
      return undefined;
    }
    const { year, month, day, hour, minute, second, isRegularInterval } =
      this.dimTimeInterval;
    return {
      year,
      month,
      day,
      hour,
      minute,
      second,
      isRegularInterval,
    };
  }

  /**
   * Shorthand functionf or getValueForIndex
   */
  get(index: number): string {
    return this.getValueForIndex(index) as string;
  }

  /**
   * Returns the first dimension value
   */
  getFirstValue(): string {
    return this.get(0);
  }

  getMiddleValue(): string {
    const middleIndex = Math.ceil(this.size() / 2) - 1;
    return this.getValueForIndex(
      middleIndex > 0 ? middleIndex : 0,
    ) as unknown as string;
  }
  /**
   * Returns the last dimension value
   */
  getLastValue(): string {
    return this.get(this.size() - 1);
  }

  /**
   * Get index value for specified value. Returns the index in the store for the given time value, either a date or a iso8601 string can be passes as input.
   * @param value Either a JS Date object or an ISO8601 String
   * @return The index of the value.  If outSideOfRangeFlag is false, a valid index will
   * always be returned. If outSideOfRangeFlag is true:
   * -1 if the value is not in the store, and is lower than available values,
   * -2 if the value is not in the store, and is higher than available values
   */
  getIndexForValue(value: string, outSideOfRangeFlag = true): number {
    this.initialize();
    if (typeof value === 'string') {
      if (value === 'current' && this.defaultValue !== 'current') {
        return this.getIndexForValue(this.defaultValue);
      }
    }
    if (this._type === 'timestartstopres') {
      try {
        if (typeof value === 'string') {
          return this._timeRangeDurationDate!.getTimeStepFromISODate(
            value,
            outSideOfRangeFlag,
          );
        }
        return this._timeRangeDurationDate!.getTimeStepFromDate(
          value,
          outSideOfRangeFlag,
        );
      } catch (e) {
        if (parseInt((e as Error).message, 10) === 0) {
          return -1;
        }
        return -2;
      }
    }
    if (this._type === 'timevalues') {
      try {
        const wantedTime = parseISO8601DateToDate(value).getTime();
        let minDistance: number = null!;
        let foundIndex = 0;
        let startTime: number = null!;
        let stopTime: number = null!;
        this._allValues.forEach((_, valueIndex) => {
          const currentTime = this._allDates[valueIndex].getTime();
          if (currentTime < startTime || startTime === null) {
            startTime = currentTime;
          }
          if (currentTime > stopTime || stopTime === null) {
            stopTime = currentTime;
          }
          const distance = Math.abs(currentTime - wantedTime);

          if (valueIndex === 0) {
            minDistance = distance;
          }
          if (minDistance > distance) {
            minDistance = distance;
            foundIndex = valueIndex;
          }
        });
        if (outSideOfRangeFlag) {
          if (startTime > wantedTime) {
            return -1;
          }
          if (stopTime < wantedTime) {
            return -2;
          }
        }
        return foundIndex;
      } catch (e) {
        debugLogger(DebugType.Error, `WMSJDimension::getIndexForValue,2: ${e}`);
        return -1;
      }
    }

    if (this._type === 'anyvalue') {
      for (let j = 0; j < this._allValues.length; j += 1) {
        if (this._allValues[j] === value) {
          return j;
        }
      }
    }

    return -1;
  }

  /**
   * Get number of values
   */
  size(): number {
    this.initialize();
    if (this._type === 'timestartstopres') {
      return this._timeRangeDurationDate!.getTimeSteps();
    }
    if (this._type === 'timevalues' || this._type === 'anyvalue') {
      return this._allValues.length;
    }
    return null!;
  }

  /**
   * Clone this dimension
   */
  clone(): WMJSDimension {
    const dim = new WMJSDimension();
    dim.name = this.name;
    dim.units = this.units;
    dim.unitSymbol = this.unitSymbol;
    dim.values = this.values;
    dim.initialize();
    dim.currentValue = this.currentValue;
    dim.defaultValue = this.defaultValue;
    dim.linked = this.linked;
    dim.synced = this.synced;
    return dim;
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { ConfigType } from '@opengeoweb/shared';
import { TFunction } from 'i18next';

const allConfigKeys = {
  GW_AUTH_LOGIN_URL: '',
  GW_AUTH_LOGOUT_URL: '',
  GW_AUTH_TOKEN_URL: '',
  GW_AUTH_CLIENT_ID: '',
  GW_AUTH_ROLE_CLAIM_NAME: '',
  GW_AUTH_ROLE_CLAIM_VALUE_PRESETS_ADMIN: '',
  GW_BE_VERSION_BASE_URL: '',
  GW_APP_URL: '',
  GW_INITIAL_PRESETS_FILENAME: '',
  GW_CAP_CONFIGURATION_FILENAME: '',
  GW_TIMESERIES_CONFIGURATION_FILENAME: '',
  GW_DEFAULT_THEME: '',
  GW_SW_BASE_URL: '',
  GW_SIGMET_BASE_URL: '',
  GW_AIRMET_BASE_URL: '',
  GW_TAF_BASE_URL: '',
  GW_PRESET_BACKEND_URL: '',
  GW_DRAWINGS_BASE_URL: '',
  GW_CAP_BASE_URL: '',
  GW_LOCATION_BASE_URL: '',
  GW_TECHNICAL_RELEASE_NOTES_URL: '',
  GW_VERSION_PROGRESS_NOTES_URL: '',
  GW_FEATURE_APP_TITLE: '',
  GW_FEATURE_FORCE_AUTHENTICATION: false,
  GW_FEATURE_MODULE_SPACE_WEATHER: false,
  GW_FEATURE_MENU_FEEDBACK: false,
  GW_FEATURE_MENU_INFO: false,
  GW_FEATURE_MENU_VERSION: false,
  GW_FEATURE_MENU_FE_VERSION: false,
  GW_FEATURE_DISPLAY_SEARCH_ON_MAP: false,
  GW_LANGUAGE: '',
  GW_INITIAL_WORKSPACE_PRESET: '',
  GW_FEATURE_INITIALIZE_SENTRY: false,
  GW_FEATURE_ENABLE_SPECIAL_THEMES: false,
};

const requiredConfigKeysForAuthentication: ConfigType = {
  GW_AUTH_CLIENT_ID: '',
  GW_AUTH_LOGIN_URL: '',
  GW_AUTH_LOGOUT_URL: '',
  GW_AUTH_TOKEN_URL: '',
  GW_APP_URL: '',
};

export enum ValidationType {
  missing = 'Missing keys',
  empty = 'Empty keys',
  nonExist = 'NonExisting keys',
}

export interface ValidationError {
  key: string;
  errorType: ValidationType;
}

const validateRequiredConfigKeys = (
  requiredKeys: ConfigType,
  config: ConfigType,
): ValidationError[] =>
  Object.keys(requiredKeys).reduce<ValidationError[]>((list, key) => {
    const value = config[key as keyof ConfigType];
    if (value === undefined) {
      return list.concat({ key, errorType: ValidationType.missing });
    }
    return list;
  }, []);
export const getNonExistingKeyWarning = (t: TFunction, key: string): string =>
  t('geoweb-non-existing-keys-warning', {
    key,
  });
const validateConfigKeys = (
  t: TFunction,
  keys: ConfigType,
): ValidationError[] => {
  return Object.keys(keys).reduce<ValidationError[]>((list, key) => {
    // check if exist
    if (allConfigKeys[key as keyof ConfigType] === undefined) {
      console.warn(getNonExistingKeyWarning(t, key));
    }
    const value = keys[key as keyof ConfigType];
    if (typeof value === 'string' && value === '') {
      return list.concat({ key, errorType: ValidationType.empty });
    }
    return list;
  }, []);
};

export const isValidConfig = (
  t: TFunction,
  configObject: ConfigType,
): boolean | ValidationError[] => {
  const errors: ValidationError[] = [];
  if (configObject.GW_FEATURE_FORCE_AUTHENTICATION === true) {
    const authErrors = [
      ...validateRequiredConfigKeys(
        requiredConfigKeysForAuthentication,
        configObject,
      ),
    ];
    authErrors.length && errors.push(...authErrors);
  }
  const mainErrors = [...validateConfigKeys(t, configObject)];
  mainErrors.length && errors.push(...mainErrors);

  return errors.length ? errors : true;
};

export const isValidConfigWithAuthentication = (
  configObject: ConfigType,
): boolean | ValidationError[] => {
  const errors = validateRequiredConfigKeys(
    requiredConfigKeysForAuthentication,
    configObject,
  );
  return errors.length ? errors : true;
};

export const sortErrors = (
  validationErrors: ValidationError[],
): {
  [ValidationType.missing]?: ValidationError[];
  [ValidationType.empty]?: ValidationError[];
  [ValidationType.nonExist]?: ValidationError[];
} => {
  const sortedErrors: {
    [ValidationType.missing]?: ValidationError[];
    [ValidationType.empty]?: ValidationError[];
    [ValidationType.nonExist]?: ValidationError[];
  } = {};

  validationErrors.forEach((error) => {
    const { errorType } = error; // Assuming this is the correct property, modify if needed
    if (!sortedErrors[errorType]) {
      sortedErrors[errorType] = [];
    }
    sortedErrors[errorType]!.push(error);
  });

  return sortedErrors;
};

export const fetchFile = async <FileContentType>(
  fileName: string,
): Promise<FileContentType> => {
  const res = await fetch(`./assets/${fileName}`, {
    credentials: 'include',
  });
  const mod = await res.json();
  return mod;
};

export const useConfig = <FileContentType>(
  t: TFunction,
  fileName: string,
): [FileContentType | null] => {
  const [configObject, setConfigObject] =
    React.useState<FileContentType | null>(null);

  React.useEffect(() => {
    fetchFile<FileContentType>(fileName)
      .then((result): void => {
        setConfigObject(result);
      })
      .catch(() => {
        setConfigObject({} as FileContentType);
        // eslint-disable-next-line no-console
        console.info(
          t('geoweb-unable-to-load-config', {
            fileName,
          }),
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  return [configObject];
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2025 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2025 - Finnish Meteorological Institute (FMI)
 * Copyright 2025 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { drawingToolReducer } from './drawingTool';
import { drawingToolListener } from './drawingTool/listener';
import {
  loadingIndicatorReducer,
  syncGroupsListener,
  syncGroupsReducer,
} from './generic';
import { genericListener } from './generic/listener';
import {
  mapReducer,
  serviceReducer,
  layerReducer,
  WebMapStateModuleState,
} from './map';
import { layersListener } from './map/layer/listeners';
import { mapListener } from './map/map/listener';
import { metronomeListener } from './map/map/metronomeListener';
import { serviceListener } from './map/service/listener';
import { routerReducer } from './router';
import { routerListener } from './router/listener';
import { uiReducer } from './ui';
import { SynchronizationGroupModuleState } from './generic/syncGroups/types';
import { LoadingIndicatorModuleState } from './generic/loadingIndicator/types';
import { UIModuleState } from './ui/types';
import { DrawtoolModuleStore } from './drawingTool/reducer';

export const storeReducerMap = {
  webmap: mapReducer,
  services: serviceReducer,
  layers: layerReducer,
  syncGroups: syncGroupsReducer,
  loadingIndicator: loadingIndicatorReducer,
  ui: uiReducer,
  drawingtools: drawingToolReducer,
  router: routerReducer,
};

export const storeMiddlewares = [
  mapListener.middleware,
  serviceListener.middleware,
  layersListener.middleware,
  metronomeListener.middleware,
  syncGroupsListener.middleware,
  genericListener.middleware,
  drawingToolListener.middleware,
  routerListener.middleware,
];

const storeRootReducer = combineReducers(storeReducerMap);

export const createMockStore = (
  mockState?:
    | WebMapStateModuleState
    | SynchronizationGroupModuleState
    | LoadingIndicatorModuleState
    | UIModuleState
    | DrawtoolModuleStore,
): ToolkitStore =>
  configureStore({
    reducer: storeRootReducer,
    preloadedState: mockState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(storeMiddlewares),
  });

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormSelect } from '@opengeoweb/form-fields';
import { StatusTag } from '@opengeoweb/shared';

import { styles } from './EditFormFields.styles';
import {
  EventCategory,
  EventCategoryDetail,
  EventCategoryDetails,
  EventLabels,
  NotificationLabel,
} from '../../../types';
import { getNewInternalStatusTagContent } from '../utils';
import { getStatusTagColorFromContent } from '../../Notifications/utils';
import { isCategoryDetailRequired } from './validations';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

interface CategoryLabelProps {
  statusTagText: string;
  setStatusTagText: React.Dispatch<React.SetStateAction<string>>;
}

const CategoryLabelStatusTag: React.FC<CategoryLabelProps> = ({
  statusTagText,
  setStatusTagText,
}: CategoryLabelProps) => {
  const { t } = useSpaceweatherTranslation();
  const { setValue, watch, trigger } = useFormContext();

  const category = watch('category');
  const categorydetail = watch('categorydetail');

  return (
    <>
      <Grid container size={12}>
        <Grid size={5}>
          <ReactHookFormSelect
            name="category"
            label={t('notification-category')}
            sx={styles.inputField}
            inputProps={{
              SelectDisplayProps: {
                'data-testid': 'category-select',
              },
            }}
            rules={{ required: true }}
            onChange={(event: SelectChangeEvent<unknown>): void => {
              const newCategory = event.target.value;
              const firstCategoryDetail =
                EventCategoryDetails[
                  newCategory as keyof typeof EventCategoryDetails
                ][0];
              const firstLabel =
                EventLabels[firstCategoryDetail as keyof typeof EventLabels][0];
              setValue('neweventlevel', '');
              setValue('threshold', '');
              setValue('categorydetail', firstCategoryDetail);
              setValue('label', firstLabel);
              setStatusTagText(
                getNewInternalStatusTagContent(
                  '',
                  firstLabel,
                  firstCategoryDetail,
                ),
              );
              void trigger('neweventstart');
            }}
          >
            {Object.keys(EventCategory).map((key: string) => (
              <MenuItem value={key} key={key}>
                {EventCategory[key as keyof typeof EventCategory]}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
        <Grid size={5}>
          <ReactHookFormSelect
            name="categorydetail"
            label={t('notification-category-detail')}
            sx={
              category !== 'XRAY_RADIO_BLACKOUT'
                ? styles.inputField
                : styles.hide
            }
            disabled={category === 'XRAY_RADIO_BLACKOUT'}
            inputProps={{
              SelectDisplayProps: {
                'data-testid': 'categorydetail-select',
              },
            }}
            onChange={(event: SelectChangeEvent<unknown>): void => {
              const newCategoryDetail = event.target.value as string;
              const firstLabel =
                EventLabels[newCategoryDetail as keyof typeof EventLabels][0];
              setValue('label', firstLabel);
              setStatusTagText(
                getNewInternalStatusTagContent(
                  '',
                  firstLabel,
                  newCategoryDetail,
                ),
              );
              void trigger('neweventstart');
            }}
            rules={{
              validate: {
                isRequired: (value: string): boolean | string =>
                  isCategoryDetailRequired(t, value, category),
              },
            }}
          >
            {EventCategoryDetails[
              category as keyof typeof EventCategoryDetails
            ].map((detail: string) => (
              <MenuItem value={detail} key={detail}>
                {
                  EventCategoryDetail[
                    detail as keyof typeof EventCategoryDetail
                  ]
                }
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
        <Grid data-testid="notification-tag" size={2}>
          <StatusTag
            content={statusTagText}
            color={getStatusTagColorFromContent(statusTagText)}
            sx={{ width: 'auto' }}
          />
        </Grid>
      </Grid>
      <Grid container size={12}>
        <Grid size={5}>
          <ReactHookFormSelect
            name="label"
            label={t('notification-label')}
            sx={styles.inputField}
            inputProps={{
              SelectDisplayProps: {
                'data-testid': 'label-select',
              },
            }}
            onChange={(event: SelectChangeEvent<unknown>): void => {
              setStatusTagText(
                event.target.value === 'WARNING' ? 'Warning' : 'Alert',
              );
              void trigger('neweventstart');
            }}
            rules={{ required: true }}
          >
            {EventLabels[categorydetail as keyof typeof EventLabels].map(
              (label: string) => (
                <MenuItem value={label} key={label}>
                  {categorydetail === 'GEOMAGNETIC_STORM'
                    ? 'Watch'
                    : NotificationLabel[
                        label as keyof typeof NotificationLabel
                      ]}
                </MenuItem>
              ),
            )}
          </ReactHookFormSelect>
        </Grid>
      </Grid>
    </>
  );
};

export default CategoryLabelStatusTag;

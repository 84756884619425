/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Typography,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  Box,
} from '@mui/material';
import { syncGroupsTypes } from '@opengeoweb/store';

const styles = {
  listItem: {
    width: '80%',
    borderRadius: '5px',
  },
  isActive: {
    color: 'geowebColors.typographyAndIcons.icon',
  },
  checkboxIsActive: {
    color: 'geowebColors.typographyAndIcons.iconLinkActive',
  },
  isDisabled: {
    color: 'geowebColors.typographyAndIcons.iconLinkDisabled',
  },
};

interface SyncGroupListItemProps {
  viewStateData: syncGroupsTypes.GroupsAndSources;
  groupId: string;
  selected: string[];
  sourcesById: syncGroupsTypes.SourceById[];
  handleToggle: (id: string) => void;
}

export const SyncGroupListItem: React.FC<SyncGroupListItemProps> = ({
  viewStateData,
  groupId,
  selected,
  sourcesById,
  handleToggle,
}: SyncGroupListItemProps) => {
  return (
    <Box sx={styles.listItem}>
      {sourcesById.map((source) => {
        const isChecked = selected.includes(source.id);
        // Check if checkbox is checked in another group
        const isNotCheckedElseWhere = viewStateData.groups.every((group) => {
          return !(group.id !== groupId && group.selected.includes(source.id));
        });
        return (
          <ListItemButton
            key={source.id}
            dense
            onClick={(): void => handleToggle(source.id)}
            sx={styles.listItem}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': source.name }}
                sx={
                  isNotCheckedElseWhere
                    ? styles.checkboxIsActive
                    : styles.isDisabled
                }
                color="default"
              />
            </ListItemIcon>
            <Typography
              sx={isNotCheckedElseWhere ? styles.isActive : styles.isDisabled}
            >
              {source.name}
            </Typography>
          </ListItemButton>
        );
      })}
    </Box>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useSelector } from 'react-redux';
import { CoreAppStore, mapSelectors } from '@opengeoweb/store';

import { TimeSliderClock } from '@opengeoweb/timeslider';
import { ControlButtonsConnect } from '../TimeSliderConnect/TimeSliderButtonsConnect/ControlButtonsConnect';
import { OptionsMenuConnect } from '../TimeSliderConnect/TimeSliderButtonsConnect/OptionsMenuConnect';

export const TimeSliderClockConnect: React.FC<{ mapId: string }> = ({
  mapId,
}) => {
  const selectedTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getSelectedTime(store, mapId),
  );
  const isTimeSliderVisible = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimeSliderVisible(store, mapId),
  );

  return (
    <TimeSliderClock
      hideButton={isTimeSliderVisible}
      controls={<ControlButtonsConnect mapId={mapId} sourceId={mapId} />}
      options={<OptionsMenuConnect mapId={mapId} />}
      time={selectedTime}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  AlertBanner,
  HeaderSize,
  ToolContainerDraggable,
  calculateStartSize,
  Position,
  DraggableSize,
} from '@opengeoweb/shared';
import { uiTypes } from '@opengeoweb/store';
import { Box, Button, LinearProgress } from '@mui/material';
import { DrawingListItem } from '../../store/warningsDrawings/types';
import { Objects } from './Objects';
import { useWarningsTranslation } from '../../utils/i18n';

const DEFAULT_OBJECT_MANAGER_SIZE = { width: 320, height: 542 };
const DEFAULT_OBJECT_MANAGER_POSITION = { top: 120, left: 50 };
const DEFAULT_OBJECT_MANAGER_MIN_SIZE = { width: 160, height: 300 };

export const ObjectManager: React.FC<{
  bounds?: string;
  title?: string;
  onClose: () => void;
  onMouseDown?: () => void;
  isOpen: boolean;
  order?: number;
  source?: uiTypes.Source;
  size?: DraggableSize;
  startPosition?: Position;
  headerSize?: HeaderSize;
  drawingListItems: DrawingListItem[];
  onClickDrawing?: (drawing: DrawingListItem) => void;
  onEditDrawing?: (drawingId: string) => void;
  onDeleteDrawing?: (drawingId: string) => void;
  onShareObject?: (object: DrawingListItem) => void;
  onCreateNewObject?: () => void;
  activeDrawingId?: string;
  error?: string;
  isLoading?: boolean;
}> = ({
  bounds,
  onClose,
  title = 'Object Manager',
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'app',
  size = DEFAULT_OBJECT_MANAGER_SIZE,
  startPosition = DEFAULT_OBJECT_MANAGER_POSITION,
  headerSize = 'xs',
  drawingListItems,
  onClickDrawing = (): void => {},
  onEditDrawing = (): void => {},
  onDeleteDrawing = (): void => {},
  onShareObject = (): void => {},
  onCreateNewObject = (): void => {},
  activeDrawingId = '',
  error,
  isLoading = false,
}) => {
  const { t } = useWarningsTranslation();
  const minSize = DEFAULT_OBJECT_MANAGER_MIN_SIZE;
  const startSizeCalc = calculateStartSize(minSize, size, startPosition);
  const [sizeInState, setSizeInState] =
    React.useState<DraggableSize>(startSizeCalc);

  return (
    <ToolContainerDraggable
      title={title}
      startSize={sizeInState}
      minWidth={minSize.width}
      minHeight={minSize.height}
      startPosition={startPosition}
      isOpen={isOpen}
      onClose={onClose}
      headerSize={headerSize}
      bounds={bounds}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
      onResizeStop={(_event, _direction, node): void => {
        const { offsetWidth: width, offsetHeight: height } = node;
        setSizeInState({ width, height });
      }}
      onDragEnd={(_position, dragSize): void => {
        if (dragSize !== sizeInState) {
          setSizeInState(dragSize as DraggableSize);
        }
      }}
      sx={{
        footer: {
          backgroundColor: 'inherit',
          boxShadow: 'none',
        },
      }}
    >
      {isLoading && (
        <LinearProgress
          data-testid="loading-bar"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 0 }}
        />
      )}
      {error && (
        <Box>
          <AlertBanner title={error} shouldClose />
        </Box>
      )}

      <Objects
        objects={drawingListItems}
        onClickObject={onClickDrawing}
        onClickEdit={onEditDrawing}
        onClickDelete={onDeleteDrawing}
        onClickShare={onShareObject}
        activeObject={activeDrawingId}
        isLoading={isLoading}
      />
      <Box
        sx={{
          padding: '24px 8px 16px 8px',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          backgroundColor: 'geowebColors.background.surfaceApp',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
      >
        <Button
          variant="tertiary"
          onClick={onCreateNewObject}
          sx={{ width: '100%', textTransform: 'none', fontSize: '12px' }}
        >
          {t('object-manager-add')}
        </Button>
      </Box>
    </ToolContainerDraggable>
  );
};

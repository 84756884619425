import { FeatureCollection, GeometryObject } from 'geojson';

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface Properties {
  timestep: Date[];
  propertyName: string;
  values: number[];
}

// properties provided by https://for.weather.fmibeta.com/sofp
export interface ObservationProperties {
  timestep: string[];
  observedPropertyName: string;
  result: string[];
}

export interface Feature {
  type: string;
  geometry: Geometry;
  properties: ObservationProperties;
}

export interface ParameterApiData {
  type: string;
  features: Feature[];
  timeStamp: string;
  numberReturned: number;
}

// A copy of this type exists in libs/shared/src/lib/types/types.ts
export interface PlotPreset {
  connectedMap?: string;
  mapId: string;
  plots: Plot[];
  parameters: Parameter[];
}

// A copy of this type exists in libs/shared/src/lib/types/types.ts
export interface Plot {
  title: string;
  plotId: string;
  enabled?: boolean;
  parameters?: Parameter[];
}

export interface PlotWithData extends Plot {
  parametersWithData: ParameterWithData[];
}

export type PlotType = 'bar' | 'line' | 'scatter';

// A copy of this type exists in libs/shared/src/lib/types/types.ts as PlotParameter
export interface Parameter {
  id?: string;
  plotId: string;
  unit?: string;
  propertyName: string;
  plotType: PlotType | 'area';
  enabled?: boolean;
  color?: string;
  opacity?: number;
  serviceId: string;
  collectionId: string;
  instanceId?: string;
  instances?: EDRInstance[];
}

export interface ParameterWithData extends Parameter {
  timestep: Date[];
  value: number[];
  unit: string;
}

export interface PointerLocation {
  lon: number;
  lat: number;
}

export interface TimeSeriesLocation extends PointerLocation {
  id?: string;
  serviceId?: string;
  collectionId?: string;
}

export const isTimeSeriesLocation = (
  obj: PointerLocation | TimeSeriesLocation,
): obj is TimeSeriesLocation => {
  return 'id' in obj && 'serviceId' in obj && 'collectionId' in obj;
};

export interface TimeSeriesPresetLocation {
  lat: number;
  lon: number;
  name: string;
}

export interface EDRAxes {
  t: {
    values: string[];
  };
  x: {
    values: number[];
  };
  y: {
    values: number[];
  };
}

export interface EDRDomain {
  axes: EDRAxes;
}

export interface EDRObservedProperty {
  id: string;
  label: EDRI18nString;
}

export type EDRI18nString = string | Record<string, string>;

export interface EDRParameter {
  id: string;
  observedProperty?: EDRObservedProperty;
  unit?: {
    label?: EDRI18nString;
    symbol?:
      | string
      | {
          type: string;
          value: string;
        };
  };
}

export type EDRParameters = Record<string, EDRParameter>;

export interface EDRRangeParameter {
  axisNames: string[];
  dataType: string;
  shape: number[];
  type: string;
  values: number[];
}

export type EDRRange = Record<string, EDRRangeParameter>;

export interface EDRPositionResponse {
  type: string;
  domain: EDRDomain;
  parameters: EDRParameters;
  ranges?: EDRRange;
}

export interface EDRCollection {
  parameter_names?: object;
  parameters?: object;
  data_queries?: {
    cube?: object;
    locations?: object;
    position?: object;
    instances?: object;
  };
  extent: object;
  id: string;
  title: string;
  description: string;
}

// A copy of this type exists in libs/shared/src/lib/types/types.ts
export interface EDRInstance {
  id: string;
  data_queries?: {
    position?: {
      link: {
        variables: {
          output_formats: string[];
        };
      };
    };
    locations?: {
      link: {
        variables: {
          output_formats: string[];
        };
      };
    };
    instances?: {
      link: string;
    };
  };
  extent?: {
    temporal?: {
      interval: string[][];
      crs: string;
      values: string[];
    };
    spatial: {
      crs: string;
      bbox: [number, number, number, number];
    };
  };
}

export interface ParameterApiDataRequest {
  urlDomain: string;
  lon: number;
  lat: number;
  parameterName: string;
}

export interface EdrCollection {
  id: string;
  description: string;
  parameters: SelectParameter[];
}

export type EdrCollections = Record<
  string,
  {
    id: string;
    title: string;
    description: string;
    parameters: SelectParameter[];
  }
>;

export interface SelectParameter {
  propertyName: string;
  unit: string;
  plotType: PlotType;
  serviceId: string;
  collectionId: string;
  color: string;
  instanceId?: string;
  instances?: EDRInstance[];
}

export interface ParameterListStyles {
  plot: React.CSSProperties;
  row: React.CSSProperties;
  rowText: React.CSSProperties;
}

export interface GeoJsonWithService {
  collectionName: string;
  serviceName: string;
  geoJson: FeatureCollection<GeometryObject>;
}

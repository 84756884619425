/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { DrawFIRLand, ArrowUp } from '@opengeoweb/theme';
import { DRAWMODE } from '../MapDraw/MapDraw';
import { defaultGeoJSONStyleProperties } from '../MapDraw/geojsonShapes';
import { intersectionFeatureNL } from '../MapDraw/storyComponents';
import { DrawMode, SelectionType } from './types';
import { MapDrawToolOptions, defaultModes, getIcon } from './useMapDrawTool';

export const opacityOptions = [
  '100',
  '90',
  '80',
  '70',
  '60',
  '50',
  '40',
  '30',
  '20',
  '10',
  '0',
];
export const fillOptions = [
  defaultGeoJSONStyleProperties!.fill,
  '#6e1e91',
  '#FF00FF',
  '#33ccFF',
  '#8F8',
];
export const strokeWidthOptions = [
  '10',
  '9',
  '8',
  '7',
  '6',
  '5',
  '4',
  '3',
  '2',
  '1',
];

export const getToolIcon = (
  selectionType: SelectionType,
): React.ReactElement => {
  const defaultIcon = getIcon(selectionType);
  if (defaultIcon) {
    return defaultIcon;
  }
  if (selectionType === shapeButtonNL.selectionType) {
    return <DrawFIRLand />;
  }
  if (selectionType === customLineButton.selectionType) {
    return <ArrowUp />;
  }
  return <div />;
};

// custom buttons
const shapeButtonNL: DrawMode = {
  drawModeId: 'tool-fir',
  value: DRAWMODE.POLYGON,
  title: 'Custom FIR NL polygon',
  shape: intersectionFeatureNL,
  isSelectable: false,
  selectionType: 'custom-fir',
};

const customLineButton: DrawMode = {
  drawModeId: 'tool-custom-line',
  value: DRAWMODE.LINESTRING,
  title: 'Custom LineString',
  shape: {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [
        [4.136829504703722, 50.944730810381465],
        [4.450134704406403, 53.81224530783831],
        [2.5703035061903217, 53.78911570560625],
        [5.252979278644519, 56.722490281934554],
        [8.758081200318252, 53.85846624679025],
        [6.290802752659646, 53.84691579421783],
        [6.623689527343743, 51.0556380592049],
        [4.136829504703722, 50.95706693142843],
      ],
    },
    properties: {
      ...defaultGeoJSONStyleProperties,
      selectionType: 'custom-line',
    },
  },
  isSelectable: false,
  selectionType: 'custom-line',
};

export const basicExampleDrawOptions: MapDrawToolOptions = {
  defaultDrawModes: [...defaultModes, shapeButtonNL, customLineButton],
  defaultGeoJSON: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          ...defaultGeoJSONStyleProperties,
          selectionType: 'box',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [9.451665078283622, 53.21804334226515],
              [4.734608638338736, 53.21804334226515],
              [4.734608638338736, 54.57650543915101],
              [9.451665078283622, 54.57650543915101],
              [9.451665078283622, 53.21804334226515],
            ],
          ],
        },
      },
    ],
  } as GeoJSON.FeatureCollection,
};

export const basicExampleMultipleShapeDrawOptions: MapDrawToolOptions = {
  shouldAllowMultipleShapes: true,
  // add multiple tools, and update all with custom color
  defaultDrawModes: [...defaultModes, shapeButtonNL, customLineButton].map(
    (mode) => ({
      ...mode,
      shape: {
        ...mode.shape,
        properties: {
          ...(mode.shape as GeoJSON.Feature).properties,
          stroke: '#8F8',
          'stroke-width': 4,
          'stroke-opacity': 1,
          fill: '#33ccFF',
          'fill-opacity': 0.5,
        },
      },
    }),
  ),
};

export const basicExampleMultipleShapeWithValuesDrawOptions: MapDrawToolOptions =
  {
    ...basicExampleMultipleShapeDrawOptions,
    defaultGeoJSON: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            fill: '#33ccFF',
            'fill-opacity': 0.7,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
            selectionType: 'box',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-2.1980359430346246, 57.20029287883823],
                [-2.1980359430346246, 55.903312387397165],
                [0.20402079718587424, 55.903312387397165],
                [0.20402079718587424, 57.20029287883823],
                [-2.1980359430346246, 57.20029287883823],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            fill: '#33ccFF',
            'fill-opacity': 0.7,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
            selectionType: 'poly',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [0.9181457740081845, 55.88511120390367],
                [2.378855953872001, 57.226659207134794],
                [3.612344550201447, 55.903312387397165],
                [0.9181457740081845, 55.88511120390367],
                [3.612344550201447, 55.903312387397165],
                [0.9181457740081845, 55.88511120390367],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            fill: '#33ccFF',
            'fill-opacity': 0.7,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
            selectionType: 'point',
          },
          geometry: {
            type: 'Point',
            coordinates: [5.446347331586017, 56.5975874650108],
          },
        },
        {
          type: 'Feature',
          properties: {
            fill: '#33ccFF',
            'fill-opacity': 0.7,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
            selectionType: 'linestring',
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              [6.955747850778628, 57.15630712609812],
              [9.666176740081486, 55.98511209901979],
              [6.7772166065730515, 55.96694931494649],
              [9.633716513862291, 57.253006702542514],
              [9.633716513862291, 57.253006702542514],
            ],
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-0.20986630818094482, 54.77174384201191],
                [-0.8779523081809448, 55.10438784201191],
                [-1.8410493081809447, 55.53605784201191],
                [-2.4479583081809433, 54.15100484201191],
                [-2.0541063081809448, 52.685297842011906],
                [-3.209864308180943, 51.27174584201191],
                [-1.8398653081809448, 51.14146584201191],
                [-1.8393393081809446, 51.14041384201191],
                [-1.8476433081809445, 51.09174584201191],
                [-1.8459763081809446, 51.08535184201191],
                [-1.8362533081809445, 51.081742842011906],
                [-1.2573653081809448, 50.98618484201191],
                [-0.812365308180945, 51.22451984201191],
                [-0.13125530818094439, 51.16340884201191],
                [0.6384666918190558, 50.911187842011906],
                [0.44180069181905546, 50.59646084201191],
                [0.8019306918190554, 50.529016842011906],
                [0.7243016918190552, 50.80812984201191],
                [1.0123566918190556, 51.13340984201191],
                [0.7365236918190547, 51.58340684201191],
                [1.1951346918190564, 51.602571842011905],
                [1.843228691819056, 52.00950784201191],
                [1.821522691819056, 52.040628842011905],
                [1.853745691819056, 52.11785284201191],
                [1.8556906918190572, 52.157571842011905],
                [1.9231886918190562, 52.660630842011905],
                [1.932313691819057, 52.669987842011906],
                [1.9818006918190565, 53.071743842011905],
                [1.290133691819056, 53.438410842011905],
                [1.2901356918190563, 54.77174584201191],
                [-0.20986630818094482, 54.77174384201191],
              ],
            ],
          },
          properties: {
            fill: '#33ccFF',
            'fill-opacity': 0.5,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
            selectionType: 'custom-fir',
          },
        },
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [4.136829504703722, 50.944730810381465],
              [4.450134704406403, 53.81224530783831],
              [2.5703035061903217, 53.78911570560625],
              [5.252979278644519, 56.722490281934554],
              [8.758081200318252, 53.85846624679025],
              [6.290802752659646, 53.84691579421783],
              [6.623689527343743, 51.0556380592049],
              [4.136829504703722, 50.95706693142843],
            ],
          },
          properties: {
            fill: '#33ccFF',
            'fill-opacity': 0.5,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
            selectionType: 'custom-line',
          },
        },
        {
          type: 'Feature',
          properties: {
            fill: '#6e1e91',
            'fill-opacity': 0.5,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
          },
          geometry: {
            type: 'Point',
            coordinates: [3.9694070386126015, 53.40680002840567],
          },
        },
        {
          type: 'Feature',
          properties: {
            fill: '#6e1e91',
            'fill-opacity': 0.5,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [3.790875794407024, 52.29939515934869],
                [5.5761882364628, 53.811222537395366],
                [6.095551855969935, 52.16022302393104],
                [3.790875794407024, 52.29939515934869],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            fill: '#6e1e91',
            'fill-opacity': 0.5,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [6.890827398340236, 53.73448815953251],
                [6.890827398340236, 52.18013147265132],
                [9.682406853191086, 52.18013147265132],
                [9.682406853191086, 53.73448815953251],
                [6.890827398340236, 53.73448815953251],
              ],
            ],
          },
        },
        {
          type: 'Feature',
          properties: {
            fill: '#6e1e91',
            'fill-opacity': 0.5,
            stroke: '#8F8',
            'stroke-width': 4,
            'stroke-opacity': 1,
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              [10.396531830013394, 53.71528264245669],
              [10.510142621780581, 52.03060087360206],
              [11.402798842808469, 52.408438794651005],
              [11.922162462315605, 53.290538521680475],
              [11.922162462315605, 53.30023909261376],
              [12.084463593411584, 54.10724495817889],
            ],
          },
        },
      ],
    },
  };

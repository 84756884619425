/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  MapView,
  MapViewLayer,
  MapViewLayerProps,
  defaultLayers,
  getFeatureExtent,
} from '@opengeoweb/webmap-react';
import {
  LayerType,
  WMBBOX,
  generateMapId,
  getGeoCoordFromLatLong,
  getWMJSMapById,
} from '@opengeoweb/webmap';

import { FeatureCollection } from 'geojson';
import { MapPreset } from '@opengeoweb/store';

export const zoomToFeature = (
  mapId: string,
  geojsonFeature: FeatureCollection | undefined,
): void => {
  const webmapjs = getWMJSMapById(mapId);
  if (!webmapjs || !geojsonFeature) {
    return;
  }
  const bboxGeoJSON = getFeatureExtent(geojsonFeature);
  const projectedCoordinateBoxBottomLeft = getGeoCoordFromLatLong(webmapjs, {
    x: bboxGeoJSON.left,
    y: bboxGeoJSON.bottom,
  });
  const projectedCoordinateBoxTopRight = getGeoCoordFromLatLong(webmapjs, {
    x: bboxGeoJSON.right,
    y: bboxGeoJSON.top,
  });
  if (projectedCoordinateBoxBottomLeft && projectedCoordinateBoxTopRight) {
    const transformedExtent = new WMBBOX({
      left: projectedCoordinateBoxBottomLeft.x,
      bottom: projectedCoordinateBoxBottomLeft.y,
      right: projectedCoordinateBoxTopRight.x,
      top: projectedCoordinateBoxTopRight.y,
    });
    webmapjs.zoomTo(transformedExtent);
    webmapjs.zoomOut(2);
    webmapjs.draw();
  }
};
interface WarningsMapViewProps {
  geojsonFeature: FeatureCollection | undefined;
  defaultMapPreset?: MapPreset;
  isSnapshot?: boolean;
}
export const WarningsMapView: React.FC<WarningsMapViewProps> = ({
  geojsonFeature,
  defaultMapPreset,
  isSnapshot = false,
}: WarningsMapViewProps) => {
  const defaultBbox = defaultMapPreset?.proj;
  const defaultBaseLayer =
    defaultMapPreset?.layers?.find(
      (layer) => layer.layerType === LayerType.baseLayer,
    ) || defaultLayers.baseLayerGrey;
  const defaultOverLayer =
    defaultMapPreset?.layers?.find(
      (layer) => layer.layerType === LayerType.overLayer,
    ) || defaultLayers.overLayer;

  const mapId = React.useRef<string>(
    `WarningsMapView_${generateMapId()}`,
  ).current;

  const warningLayer: MapViewLayerProps = {
    id: `${mapId}geojsonfeature`,
    geojson: geojsonFeature,
    layerType: LayerType.featureLayer,
  };

  if (!defaultBbox) {
    // Zoom to the geojson when the geojsonFeature is updated in an already mounted map component
    zoomToFeature(mapId, geojsonFeature);
  }

  return (
    <div style={{ height: '200px', width: '100%' }}>
      <MapView
        bbox={defaultBbox?.bbox}
        srs={defaultBbox?.srs}
        mapId={mapId}
        showScaleBar={false}
        onWMJSMount={(mapId) => {
          const webmapjs = getWMJSMapById(mapId);
          if (!webmapjs) {
            return;
          }
          webmapjs.hideMouseCursorProperties();
          if (!defaultBbox) {
            // Zoom to the geojson when the map is ready
            zoomToFeature(mapId, geojsonFeature);
          }
        }}
        holdShiftToScroll={true}
      >
        {!isSnapshot && (
          <MapViewLayer {...defaultBaseLayer} id={`${mapId}_baseLayer`} />
        )}
        {warningLayer.geojson && <MapViewLayer {...warningLayer} />}
        {!isSnapshot && (
          <MapViewLayer {...defaultOverLayer} id={`${mapId}_overlayer`} />
        )}
      </MapView>
    </div>
  );
};

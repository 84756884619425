/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Grid2 as Grid, Box, Grid2Props } from '@mui/material';
import { breakpoints } from '@opengeoweb/theme';
import { PublicWarningStatus } from '../../store/publicWarningForm/types';

export const WarningListColumnContent: React.FC<
  Grid2Props & {
    title?: string;
    status: PublicWarningStatus;
    children?: React.ReactNode;
    width?: number;
  }
> = ({ title, status, children, width = 76, ...props }) => {
  return (
    <Grid sx={{ ...props.sx, width }}>
      {title && (
        <Box
          sx={{
            overflow: 'hidden',
            fontWeight: 'normal',
            textOverflow: 'ellipsis',
            fontSize: 10,
            lineHeight: 1.6,
            height: 12,
            color:
              status === PublicWarningStatus.EXPIRED ||
              status === PublicWarningStatus.WITHDRAWN
                ? 'geowebColors.typographyAndIcons.inactiveText'
                : 'geowebColors.captions.captionStatus.rgba',
            display: 'block',
            [`@container publicWarningList (min-width: ${breakpoints.desktop}px)`]:
              {
                display: 'none',
              },
          }}
        >
          {title}
        </Box>
      )}
      <Box
        sx={{
          overflow: 'visible',
          fontSize: 12,
          lineHeight: 1.2,
          color:
            status === PublicWarningStatus.EXPIRED ||
            status === PublicWarningStatus.WITHDRAWN
              ? 'geowebColors.typographyAndIcons.inactiveText'
              : 'geowebColors.typographyAndIcons.text',
        }}
      >
        {children || '-'}
      </Box>
    </Grid>
  );
};

export default WarningListColumnContent;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Button } from '@mui/material';
import { Add } from '@opengeoweb/theme';
import { TFunction } from 'i18next';
import { PlotPreset } from '../TimeSeries/types';

interface AddPlotButtonProps {
  handleClick: (title: string) => void;
  plotState: PlotPreset;
  t: TFunction;
}

export const AddPlotButton: React.FC<AddPlotButtonProps> = ({
  handleClick,
  plotState,
  t,
}) => {
  const onClick = (): void => {
    const titleRegex = /\d+/;
    const plotIndex: number = plotState.plots.length
      ? +plotState.plots[plotState.plots.length - 1].title.match(titleRegex)!
      : 0;
    handleClick(`${t('timeseries-plot')} ${plotIndex + 1}`);
  };

  return (
    <Button
      onClick={onClick}
      data-testid="timeSeriesAddPlotButton"
      size="small"
      variant="tertiary"
      sx={{ border: 'none' }}
      startIcon={<Add />}
    >
      {t('timeseries-plot')}
    </Button>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { dateUtils } from '@opengeoweb/shared';
import { useWebmapReactTranslation } from '../../utils/i18n';

interface WarningListProps {
  selectedFeatureProperties: {
    identifier?: string;
    severity?: string;
    certainty?: string;
    onset?: string;
    expires?: string;
    languages?: Language[];
  };
  languageIndex?: number;
  parentSize?: number;
}

interface Language {
  areaDesc: string;
  language: string;
  event: string;
  senderName: string;
  headline: string;
  description: string;
}

export const MapWarningProperties: React.FC<WarningListProps> = ({
  selectedFeatureProperties,
  languageIndex,
  parentSize,
}) => {
  const language = languageIndex || 0;
  const newWidth = parentSize ? parentSize - 100 : 350;
  const { t } = useWebmapReactTranslation();
  return (
    <div
      style={{
        position: 'fixed',
        width: `${newWidth}px`,
        height: '1px',
        display: 'flex',
        justifyContent: 'center',
        left: '50px',
      }}
    >
      <div
        data-testid="map-warning-properties"
        style={{
          marginTop: '50px',
          width: '350px',
          height: '190px',
          padding: '0px 20px 20px',
          overflow: 'auto',
          fontSize: '11px',
          backgroundColor: '#CCCCCCC0',
        }}
      >
        <h1 data-testid="map-warning-properties-title">
          {selectedFeatureProperties.languages &&
            selectedFeatureProperties.languages[language].areaDesc}
        </h1>

        {selectedFeatureProperties.languages &&
          selectedFeatureProperties.languages[language].areaDesc && (
            <pre data-testid="map-warning-properties-row">
              {t('webmap-react-event')}{' '}
              {selectedFeatureProperties.languages &&
                selectedFeatureProperties.languages[language].event}
            </pre>
          )}
        {selectedFeatureProperties.onset && (
          <pre data-testid="map-warning-properties-row">
            {t('webmap-react-sent')}{' '}
            {dateUtils.dateToString(
              dateUtils.utc(selectedFeatureProperties.onset),
              dateUtils.DATE_FORMAT_NAME_OF_MONTH,
            )}{' '}
            {t('webmap-react-utc')}
          </pre>
        )}
        {selectedFeatureProperties.expires && (
          <pre data-testid="map-warning-properties-row">
            {t('webmap-react-expires')}{' '}
            {dateUtils.dateToString(
              dateUtils.utc(selectedFeatureProperties.expires),
              dateUtils.DATE_FORMAT_NAME_OF_MONTH,
            )}{' '}
            {t('webmap-react-utc')}
          </pre>
        )}
        {selectedFeatureProperties.languages &&
          selectedFeatureProperties.languages[language].senderName && (
            <pre data-testid="map-warning-properties-row">
              {t('webmap-react-sender')}{' '}
              {selectedFeatureProperties.languages &&
                selectedFeatureProperties.languages[language].senderName}
            </pre>
          )}
        {selectedFeatureProperties.identifier && (
          <pre data-testid="map-warning-properties-row">
            {t('webmap-react-identifier')}{' '}
            {selectedFeatureProperties.identifier}
          </pre>
        )}
      </div>
    </div>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { getFieldValue } from './utils';

// messages
export const invalidChangeMessage =
  'Invalid Change input, only BECMG, TEMPO or FM are allowed';

export const invalidChangeProbCombinationMessage =
  'A probability is only allowed in combination with TEMPO or an empty Change input';

// validations
export const validateChangeField = (value: string): boolean | string => {
  const trimmedValue = getFieldValue(value);
  const isValid =
    trimmedValue === '' ||
    trimmedValue === 'FM' ||
    trimmedValue === 'TEMPO' ||
    trimmedValue === 'BECMG';
  return isValid ? true : invalidChangeMessage;
};

export const validateChangeFieldInterField = (
  value: string,
  valueProb: string,
): boolean | string => {
  const trimmedProbValue = getFieldValue(valueProb);
  const trimmedValue = getFieldValue(value);

  if (
    trimmedProbValue !== '' &&
    trimmedValue !== '' &&
    trimmedValue !== 'TEMPO'
  ) {
    return invalidChangeProbCombinationMessage;
  }
  return true;
};

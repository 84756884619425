/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  uiTypes,
  uiActions,
  mapEnums,
  CoreAppStore,
  uiSelectors,
} from '@opengeoweb/store';
import { LegendButton } from '@opengeoweb/webmap-react';

interface LegendMapButtonProps {
  mapId: string;
  source?: uiTypes.Source;
  multiLegend?: boolean;
}

const LegendMapButtonConnect: React.FC<LegendMapButtonProps> = ({
  mapId,
  source = 'app',
  multiLegend = false,
}: LegendMapButtonProps) => {
  const dispatch = useDispatch();
  const legendType = multiLegend ? `legend-${mapId}` : `legend`;

  const currentActiveMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, legendType),
  );

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, legendType),
  );

  const openLegendDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: legendType,
        mapId,
        setOpen: currentActiveMapId !== mapId ? true : !isOpenInStore,
        source,
        origin: mapEnums.MapActionOrigin.map,
      }),
    );
  }, [currentActiveMapId, dispatch, isOpenInStore, mapId, source, legendType]);

  const isOpen = currentActiveMapId === mapId && isOpenInStore;

  return <LegendButton isOpen={isOpen} openLegendDialog={openLegendDialog} />;
};

export default LegendMapButtonConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useDispatch } from 'react-redux';

import { serviceActions, serviceTypes } from '@opengeoweb/store';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { ServicePopup } from './ServicePopup';
import {
  layerSelectSelectors,
  layerSelectTypes,
  useAppSelector,
} from '../../store';

interface ServicePopupDialogConnectType {
  isOpen: boolean;
  serviceId?: string;
  variant: layerSelectTypes.PopupVariant;
  url?: string;
  setServicePopupClosed: () => void;
}

// Extracted from ServicePopupDialog as the getActiveServices and getActiveServiceById selectors
// Result in many rerenders. By extracting we're able to only render this component and these selectors
// when the dialog is open
export const ServicePopupDialogConnect: React.FC<
  ServicePopupDialogConnectType
> = ({
  isOpen,
  serviceId = '',
  variant,
  url = '',
  setServicePopupClosed,
}: ServicePopupDialogConnectType) => {
  const dispatch = useDispatch();

  const services = useAppSelector((store) =>
    layerSelectSelectors.getActiveServices(store),
  );

  const activeService = useAppSelector((store) =>
    layerSelectSelectors.getActiveServiceById(store, serviceId!),
  );

  const serviceSetLayers = React.useCallback(
    (payload: serviceTypes.SetLayersForServicePayload): void => {
      dispatch(serviceActions.serviceSetLayers(payload));
    },
    [dispatch],
  );

  const showSnackbar = React.useCallback(
    (message: string) => {
      dispatch(
        snackbarActions.openSnackbar({
          type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
          message,
        }),
      );
    },
    [dispatch],
  );

  return (
    <ServicePopup
      servicePopupVariant={variant}
      isOpen={isOpen}
      closePopup={setServicePopupClosed}
      services={services}
      serviceSetLayers={serviceSetLayers}
      serviceId={serviceId}
      serviceUrl={url || activeService?.serviceUrl}
      serviceName={activeService?.serviceName}
      serviceAbstracts={activeService?.abstract}
      showSnackbar={showSnackbar}
    />
  );
};

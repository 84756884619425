/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createAction } from '@reduxjs/toolkit';
import { setBbox, setTime } from '../actions';
import type {
  SetBboxPayload,
  SetTimePayload,
  SyncLayerPayloads,
} from '../types';
import type {
  SetBboxSyncActionPayload,
  SetBboxSyncPayload,
  SetLayerActionsSyncPayload,
  SetTimeSyncActionPayload,
  SetTimeSyncPayload,
} from './types';

/**
 * These actions are fired by the generic/listener.ts, based on generic actions and the synchronizationGroup state.
 *
 * These actions should not be used by components directly. Components should only use the generic actions.
 */
export const setTimeSync = createAction(
  'GENERIC_SYNC_SETTIME',
  (
    setTimePayload: SetTimePayload | null,
    targets: SetTimeSyncPayload[],
    groups: string[],
  ): { payload: SetTimeSyncActionPayload } => {
    return {
      payload: {
        source: setTimePayload && setTime(setTimePayload),
        groups,
        targets,
      },
    };
  },
);

export const setBboxSync = createAction(
  'GENERIC_SYNC_SETBBOX',
  (
    payload: SetBboxPayload,
    targets: SetBboxSyncPayload[],
    groups: string[],
  ): { payload: SetBboxSyncActionPayload } => {
    return {
      payload: {
        source: setBbox(payload),
        groups,
        targets,
      },
    };
  },
);

export const setLayerActionSync = createAction(
  'GENERIC_SYNC_SETLAYERACTIONS',
  (
    payload: SyncLayerPayloads,
    targets: SyncLayerPayloads[],
    type: string,
  ): { payload: SetLayerActionsSyncPayload } => {
    return {
      payload: {
        source: {
          type,
          payload,
        },
        targets,
      },
    };
  },
);

export type GenericSyncActions =
  | ReturnType<typeof setTimeSync>
  | ReturnType<typeof setBboxSync>
  | ReturnType<typeof setLayerActionSync>;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { usePrevious } from '@opengeoweb/shared';
import { GraphItem } from './types';
import { TimeseriesParams, StreamResponse } from '../../types';
import {
  createCancelRequestId,
  cancelRequestById,
  SpaceWeatherApi,
} from '../../utils/api';

export interface GraphState {
  isLoading: boolean;
  data: GraphItem[];
  error: Error;
}

export type GraphRecord = Record<string, GraphState>;

export const createStateById = (
  chartConfig: GraphItem[],
  params: TimeseriesParams,
): GraphRecord =>
  chartConfig.reduce((list, chart) => {
    const data = {
      ...chart,
      series: [],
      params: chart.params.map((param) => ({
        ...param,
        ...params,
      })),
    };

    return {
      ...list,
      [chart.id]: {
        isLoading: true,
        data: [data],
        error: null,
      },
    };
  }, {});

export const stateAsArray = (newState: GraphRecord): GraphState[] =>
  Object.keys(newState).map((key) => newState[key]);

export const cancelRequests = (chartConfig: GraphItem[]): void => {
  const cancelRequestIds = chartConfig.reduce<string[]>(
    (list, item) =>
      list.concat(
        item.params.map((param) =>
          createCancelRequestId(param as TimeseriesParams),
        ),
      ),
    [],
  );
  cancelRequestIds.forEach((id) => cancelRequestById(id));
};

export const createStreamDict = (
  streams: StreamResponse[],
): Record<string, StreamResponse> =>
  streams.reduce(
    (list, item) => ({
      ...list,
      [item.stream]: item,
    }),
    {},
  );

export const useUserSleeping = (delay = 300000): [boolean, () => void] => {
  const [isSleeping, setIsSleeping] = React.useState(false);
  const autoInterval = React.useRef<NodeJS.Timeout | number | null>(null);
  const setUserToSleep = (): void => setIsSleeping(() => true);
  const clearTimer = (): void => {
    if (autoInterval.current) {
      clearTimeout(autoInterval.current as NodeJS.Timeout);
    }
  };
  const resetAutoInterval = (): void => {
    clearTimer();
    autoInterval.current = setTimeout(setUserToSleep, delay);
    if (isSleeping) {
      setIsSleeping(() => false);
    }
  };

  React.useEffect(() => {
    // mount
    resetAutoInterval();
    // unmount
    return (): void => {
      clearTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isSleeping, resetAutoInterval];
};

export const useStreams = (api: SpaceWeatherApi): [[], () => void] => {
  const [streams, setStreams] = React.useState<StreamResponse[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [streamsDict, setStreamsDict] = React.useState<any>(null);
  const prevStreams = usePrevious(streams);

  React.useEffect(() => {
    if (streams && prevStreams && prevStreams.length) {
      const streamsWithNewData = streams.filter(
        (stream, index) => stream.end !== prevStreams[index].end,
      );

      // store loaded streams
      if (streamsWithNewData.length) {
        const loadedStreams = createStreamDict(streamsWithNewData);
        setStreamsDict(() => loadedStreams);
      }
    }
  }, [streams, prevStreams]);

  const fetchStreams = async (): Promise<void> => {
    try {
      const { data } = await api.getStreams(null!, 'stream-auto-update');
      return setStreams(() => data);
    } catch (error) {
      return null!;
    }
  };

  React.useEffect(() => {
    return (): void => {
      cancelRequestById('stream-auto-update');
    };
  }, []);

  return [streamsDict, fetchStreams];
};

export const getGraphHeightInPx = (): number => {
  const graphHeightInPx = 0.1 * document.documentElement.clientHeight; // using 10% of the screen per graph to make it responsive
  if (graphHeightInPx < 90) {
    return 90; // minimum height of 90px
  }
  return graphHeightInPx;
};

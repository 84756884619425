/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { dateUtils } from '@opengeoweb/shared';
import { createSeries } from '../components/TimeSeries/createChartSeriesData';
import { GraphItem } from '../components/TimeSeries/types';

const now = dateUtils.utc();

export const solarWindSpeedDummyData = {
  parameter: 'proton_speed',
  stream: 'rtsw_wind',
  unit: 'km/s',
  data: [
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 24 }))!,
      value: 311,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 23 }))!,
      value: 313,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 22 }))!,
      value: 310,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 21 }))!,
      value: 303,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 20 }))!,
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 19 }))!,
      value: 324,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 18 }))!,
      value: 320,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 17 }))!,
      value: 326,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 3 }))!,
      value: 320,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 2 }))!,
      value: 303,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 1 }))!,
      value: 313,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 207,
      source: 'DSCOVR',
    },
  ],
};

export const solarWindSpeedDummyDataFixedDates = {
  parameter: 'proton_speed',
  stream: 'rtsw_wind',
  unit: 'km/s',
  data: [
    {
      timestamp: '2022-01-05T03:00:00Z',
      value: 311,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T04:00:00Z',
      value: 313,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T05:00:00Z',
      value: 310,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T06:00:00Z',
      value: 303,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T07:00:00Z',
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T08:00:00Z',
      value: 324,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T09:00:00Z',
      value: 320,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T10:00:00Z',
      value: 326,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T00:00:00Z',
      value: 320,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T01:00:00Z',
      value: 303,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T02:00:00Z',
      value: 313,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T03:00:00Z',
      value: 207,
      source: 'DSCOVR',
    },
  ],
};

export const solarWindPressureDummyData = {
  parameter: 'proton_pressure',
  stream: 'rtsw_wind',
  unit: 'nPa',
  data: [
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 16 }))!,
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 12 }))!,
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 8 }))!,
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 4 }))!,
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 3 }))!,
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 2 }))!,
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 1 }))!,
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 3,
      source: 'DSCOVR',
    },
  ],
};

export const solarWindPressureDummyDataFixedDates = {
  parameter: 'proton_pressure',
  stream: 'rtsw_wind',
  unit: 'nPa',
  data: [
    {
      timestamp: '2022-01-05T20:00:00Z',
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T21:00:00Z',
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T22:00:00Z',
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T23:00:00Z',
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T00:00:00Z',
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T01:00:00Z',
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T02:00:00Z',
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T03:00:00Z',
      value: 3,
      source: 'DSCOVR',
    },
  ],
};

export const solarWindDensityDummyData = {
  parameter: 'proton_density',
  stream: 'rtsw_wind',
  unit: '1/cm3',
  data: [
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 15 }))!,
      value: 8,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 14 }))!,
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 13 }))!,
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 12 }))!,
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 11 }))!,
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 10 }))!,
      value: 5,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 9 }))!,
      value: 4,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 8 }))!,
      value: 11,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 7 }))!,
      value: 11,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 6 }))!,
      value: 32,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 5 }))!,
      value: 100,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 4 }))!,
      value: 70,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 2 }))!,
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 1 }))!,
      value: 6,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 5,
      source: 'DSCOVR',
    },
  ],
};

export const solarWindDensityDummyDataFixedDates = {
  parameter: 'proton_density',
  stream: 'rtsw_wind',
  unit: '1/cm3',
  data: [
    {
      timestamp: '2022-01-05T12:00:00Z',
      value: 8,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T13:00:00Z',
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T14:00:00Z',
      value: 2,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T15:00:00Z',
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T16:00:00Z',
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T17:00:00Z',
      value: 5,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T18:00:00Z',
      value: 4,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T19:00:00Z',
      value: 11,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T20:00:00Z',
      value: 11,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T21:00:00Z',
      value: 32,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T22:00:00Z',
      value: 100,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T23:00:00Z',
      value: 70,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T01:00:00Z',
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T02:00:00Z',
      value: 6,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T03:00:00Z',
      value: 5,
      source: 'DSCOVR',
    },
  ],
};

export const kpIndexDummyData = {
  parameter: 'kp',
  stream: 'kp',
  unit: '-',
  data: [
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 15 }))!,
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 12 }))!,
      value: 0.66,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 9 }))!,
      value: 0.0,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 6 }))!,
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 3 }))!,
      value: 0.33,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 3,
      source: 'GFZ',
    },
  ],
};

export const kpIndexDummyDataFixedDates = {
  parameter: 'kp',
  stream: 'kp',
  unit: '-',
  data: [
    {
      timestamp: '2022-01-05T09:00:00Z',
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-05T12:00:00Z',
      value: 0.66,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-05T15:00:00Z',
      value: 0.0,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-05T18:00:00Z',
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-05T21:00:00Z',
      value: 0.33,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-06T00:00:00Z',
      value: 3,
      source: 'GFZ',
    },
  ],
};

export const kpIndexForecastDummyData = {
  parameter: 'kp_24',
  stream: 'kp',
  unit: '-',
  data: [
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 4,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.add(now, { hours: 3 }))!,
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.add(now, { hours: 9 }))!,
      value: 3,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.add(now, { hours: 12 }))!,
      value: 7,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.add(now, { hours: 15 }))!,
      value: 2,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.add(now, { hours: 18 }))!,
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.add(now, { hours: 21 }))!,
      value: 3,
      source: 'GFZ',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.add(now, { hours: 24 }))!,
      value: 3,
      source: 'GFZ',
    },
  ],
};

export const kpIndexForecastDummyDataFixedDates = {
  parameter: 'kp_24',
  stream: 'kp',
  unit: '-',
  data: [
    {
      timestamp: '2022-01-06T03:00:00Z',
      value: 4,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-06T06:00:00Z',
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-06T09:00:00Z',
      value: 3,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-06T12:00:00Z',
      value: 7,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-06T15:00:00Z',
      value: 2,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-06T18:00:00Z',
      value: 1,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-06T21:00:00Z',
      value: 3,
      source: 'GFZ',
    },
    {
      timestamp: '2022-01-07T00:00:00Z',
      value: 3,
      source: 'GFZ',
    },
  ],
};

export const xRayFluxDummyData = {
  parameter: 'solar_x_ray_flux_long',
  stream: 'xray',
  unit: 'W/m2',
  data: [
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 12 }))!,
      value: 2e-5,
      source: 'GOES-16',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 9 }))!,
      value: 4.5e-3,
      source: 'GOES-16',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 6 }))!,
      value: 5e-6,
      source: 'GOES-16',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 3 }))!,
      value: 4.3e-3,
      source: 'GOES-16',
    },
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 4.3e-3,
      source: 'GOES-16',
    },
  ],
};

export const xRayFluxDummyDataFixedDates = {
  parameter: 'solar_x_ray_flux_long',
  stream: 'xray',
  unit: 'W/m2',
  data: [
    {
      timestamp: '2022-01-05T09:00:00Z',
      value: 2e-5,
      source: 'GOES-16',
    },
    {
      timestamp: '2022-01-05T12:00:00Z',
      value: 4.5e-3,
      source: 'GOES-16',
    },
    {
      timestamp: '2022-01-05T20:00:00Z',
      value: 5e-6,
      source: 'GOES-16',
    },
    {
      timestamp: '2022-01-05T23:00:00Z',
      value: 4.3e-3,
      source: 'GOES-16',
    },
    {
      timestamp: '2022-01-06T03:00:00Z',
      value: 4.3e-3,
      source: 'GOES-16',
    },
  ],
};

export const interplanetaryMagneticFieldBt = {
  parameter: 'bt',
  stream: 'rtsw_mag',
  unit: 'nT',
  data: [
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 15 }))!,
      value: 15,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 12 }))!,
      value: 0,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 9 }))!,
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 6 }))!,
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 3 }))!,
      value: 18,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 8,
      source: 'DSCOVR',
    },
  ],
};

export const interplanetaryMagneticFieldBz = {
  parameter: 'bz_gsm',
  stream: 'rtsw_mag',
  unit: 'nT',
  data: [
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 15 }))!,
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 12 }))!,
      value: 6,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 9 }))!,
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 6 }))!,
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(dateUtils.sub(now, { hours: 3 }))!,
      value: 4,
      source: 'DSCOVR',
    },
    {
      timestamp: dateUtils.dateToString(now)!,
      value: 2,
      source: 'DSCOVR',
    },
  ],
};

export const xrayWithSeries = {
  id: '2-xray-logarithmicGraph',
  title: 'X-ray Solar Flux (W/m\u00B2)',
  graphType: 'LOG',
  params: [
    {
      stream: 'xray',
      parameter: 'solar_x_ray_flux_long',
    },
  ],
  columns: ['lineSolarXray'],
  threshold: [{ title: '', value: 5e-5 }],
  yMinValue: 1e-9,
  yMaxValue: 1e-2,
  tickValues: (value: number): string => {
    switch (value) {
      case 1e-9:
        return '10^-9';
      case 1e-8:
        return '10^-8';
      case 1e-7:
        return '10^-7';
      case 1e-6:
        return '10^-6';
      case 1e-5:
        return '10^-5';
      case 1e-4:
        return '10^-4';
      case 1e-3:
        return '10^-3';
      case 1e-2:
        return '10^-2';
      default:
        return '';
    }
  },
  series: createSeries([{ data: xRayFluxDummyData }]),
} as GraphItem;

export const xrayWithSeriesFixedDates = {
  ...xrayWithSeries,
  series: createSeries([{ data: xRayFluxDummyDataFixedDates }]),
};

export const windPressureWithSeries = {
  id: '5-wind-pressure-areaGraph',
  title: 'Solar Wind Pressure (nPa)',
  graphType: 'AREA',
  params: [
    {
      stream: 'rtsw_wind',
      parameter: 'proton_pressure',
    },
  ],
  columns: [['lineSolarWindPressure', 'areaSolarWindPressure']],
  yMinValue: 0,
  yMaxValue: 15,
  series: createSeries([{ data: solarWindPressureDummyData }]),
} as GraphItem;

export const windPressureWithSeriesFixedDates = {
  ...windPressureWithSeries,
  series: createSeries([{ data: solarWindPressureDummyDataFixedDates }]),
};

const barGraph = {
  id: '0-kp-barGraph',
  title: 'Kp Index',
  graphType: 'BAR',
  columns: ['kpIndex', 'kpIndexForecast'],
  yMinValue: 0,
  yMaxValue: 9,
  threshold: [
    { title: 'Met Office', value: 5 },
    { title: 'KNMI', value: 7 },
  ],
  params: [{ stream: 'kp', parameter: 'kp' }],
} as GraphItem;

export const barGraphWithSeries = {
  ...barGraph,
  series: createSeries([
    { data: kpIndexDummyData },
    { data: kpIndexForecastDummyData },
  ]),
};

export const barGraphWithSeriesFixedDates = {
  ...barGraph,
  series: createSeries([
    { data: kpIndexDummyDataFixedDates },
    { data: kpIndexForecastDummyDataFixedDates },
  ]),
};

const areaGraph = {
  id: '3-wind-areaGraph',
  title: 'Solar Wind Speed (km/s)',
  graphType: 'AREA',
  params: [{ stream: 'rtsw_wind', parameter: 'proton_speed' }],
  columns: [['lineSolarWindSpeed', 'areaSolarWindSpeed']],
  yMinValue: 200,
  yMaxValue: 800,
} as GraphItem;

export const areaGraphWithSeries = {
  ...areaGraph,
  series: createSeries([{ data: solarWindSpeedDummyData }]),
};

export const areaGraphWithSeriesFixedDates = {
  ...areaGraph,
  series: createSeries([{ data: solarWindSpeedDummyDataFixedDates }]),
};

const logGraph = {
  id: '4-wind-density-logarithmicGraph',
  title: 'Solar Wind Density (1/cm\u00B3)',
  graphType: 'LOG',
  params: [
    {
      stream: 'rtsw_wind',
      parameter: 'proton_density',
    },
  ],
  columns: ['lineSolarWindDensity'],
  threshold: [{ title: '', value: 10 }],
  yMinValue: 1,
  yMaxValue: 100,
  tickValues: (value: number): number => {
    switch (value) {
      case 1:
        return 1;
      case 2:
        return 10;
      case 3:
        return 100;
      default:
        return 1;
    }
  },
} as GraphItem;

export const logGraphWithSeries = {
  ...logGraph,
  series: createSeries([{ data: solarWindDensityDummyData }]),
};

export const windDensityWithSeries = {
  ...logGraph,
  tickValues: undefined,
  series: createSeries([{ data: solarWindDensityDummyData }]),
};

export const windDensityWithSeriesFixedDates = {
  ...logGraph,
  tickValues: undefined,
  series: createSeries([{ data: solarWindDensityDummyDataFixedDates }]),
};

export const logGraphWithSeriesFixedDates = {
  ...logGraph,
};

const bandGraph = {
  id: '1-rtsw-bandGraph',
  title: 'Interplanetary Magnetic Field Bt and Bz (nT)',
  graphType: 'BAND',
  params: [
    { stream: 'rtsw_mag', parameter: 'bt' },
    { stream: 'rtsw_mag', parameter: 'bz_gsm' },
  ],
  columns: ['magneticFieldBt', 'magneticFieldBz'],
  threshold: [{ title: 'example title', value: -20 }],
  yMinValue: -25,
  yMaxValue: 25,
} as GraphItem;

export const bandGraphWithSeries = {
  ...bandGraph,
  series: createSeries([
    { data: interplanetaryMagneticFieldBt },
    { data: interplanetaryMagneticFieldBz },
  ]),
};

export const interplanetaryMagneticFieldBtFixedDates = {
  parameter: 'bt',
  stream: 'rtsw_mag',
  unit: 'nT',
  data: [
    {
      timestamp: '2022-01-05T12:00:00Z',
      value: 15,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T15:00:00Z',
      value: 0,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T18:00:00Z',
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T21:00:00Z',
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T00:00:00Z',
      value: 18,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T03:00:00Z',
      value: 8,
      source: 'DSCOVR',
    },
  ],
};

export const interplanetaryMagneticFieldBzFixedDates = {
  parameter: 'bz_gsm',
  stream: 'rtsw_mag',
  unit: 'nT',
  data: [
    {
      timestamp: '2022-01-05T12:00:00Z',
      value: 1,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T15:00:00Z',
      value: 6,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T18:00:00Z',
      value: NaN,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-05T21:00:00Z',
      value: 3,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T00:00:00Z',
      value: 4,
      source: 'DSCOVR',
    },
    {
      timestamp: '2022-01-06T03:00:00Z',
      value: 2,
      source: 'DSCOVR',
    },
  ],
};

export const bandGraphWithSeriesFixedDates = {
  ...bandGraph,
  series: createSeries([
    { data: interplanetaryMagneticFieldBtFixedDates },
    { data: interplanetaryMagneticFieldBzFixedDates },
  ]),
};

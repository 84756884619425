/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Credentials, getConfig } from '@opengeoweb/authentication';
import { getHeaders, handleResponse } from '@opengeoweb/api';
import { PublicWarning } from '../../store/publicWarningForm/types';

const config = getConfig();

const publicWarningBaseUrl = config.GW_DRAWINGS_BASE_URL || '';

// API
export const getPublicWarningList = (
  auth: Credentials,
): Promise<PublicWarning[]> =>
  fetch(`${publicWarningBaseUrl}/warnings`, {
    headers: getHeaders(auth),
  })
    .then(handleResponse)
    .then((warnings) =>
      warnings.map((warning: Partial<PublicWarning>) => ({
        ...warning,
        type: 'public',
      })),
    );

export const updatePublicWarningEditor = (
  warningId: string,
  isEditor: boolean,
  auth: Credentials,
): Promise<object> =>
  fetch(
    `${publicWarningBaseUrl}/warnings/${warningId}?editor=${isEditor ? 'true' : 'false'}&force=true`,
    {
      headers: getHeaders(auth),
      method: 'POST',
    },
  ).then(handleResponse);

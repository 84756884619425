/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const BUTTON_WIDTH = 30;

const leftButtonsWidth = 2 * BUTTON_WIDTH + 10;
const rightButtonsWidth = 3 * BUTTON_WIDTH;

const nonFlexibleTitleAreaWidth = leftButtonsWidth + rightButtonsWidth;

const nonFlexibleRowAreaWidth = leftButtonsWidth + rightButtonsWidth;

const flexibleTitleAreaWidth = `(100% - ${nonFlexibleTitleAreaWidth}px)`;

const flexibleRowAreaWidth = `(100% - ${nonFlexibleRowAreaWidth}px)`;

export const RIGHT_ACTION_WIDTH = rightButtonsWidth;
export const LEFT_ACTION_WIDTH = leftButtonsWidth;

// Default styles when Time Series Manager width is larger than 600px
export const timeSeriesManagerStyle = {
  '.timeSeriesManagerContainer': {
    containerType: 'inline-size',
  },
  '.medium-timeseriesmanager': {
    display: 'none',
  },

  // Styles for header titles
  '.titleCollectionWidth': {
    width: `calc(${flexibleTitleAreaWidth} * 0.15)`,
    minWidth: '30px',
  },
  '.titleParameterWidth': {
    width: `calc(${flexibleTitleAreaWidth} * 0.2)`,
  },
  '.titleStyleWidth': {
    width: `calc(${flexibleTitleAreaWidth} * 0.35)`,
  },
  '.titleTimeWidth': {
    width: `calc(${flexibleTitleAreaWidth} * 0.25)`,
  },

  // Styles for elements inside plot rows
  '.rowCollectionWidth': {
    width: `calc(${flexibleRowAreaWidth} * 0.15)`,
  },
  '.rowParameterWidth': {
    width: `calc(${flexibleRowAreaWidth} * 0.2)`,
  },
  '.rowStyleWidth': {
    width: `calc(${flexibleRowAreaWidth} * 0.35)`,
  },
  '.rowTimeWidth': {
    width: `calc(${flexibleRowAreaWidth} * 0.29)`,
  },
  '.rightActionWidth': {
    width: rightButtonsWidth,
    justifyContent: 'flex-end',
  },

  // Styles when Time Series Manager width is less than 620px
  '@container (max-width: 620px)': {
    '.timeseriesmanager': {
      ' .titleParameterWidth, .titleStyleWidth, .titleTimeWidth, .rowParameterWidth, .rowStyleWidth, .rowTimeWidth, .rightActionWidth':
        {
          display: 'none',
        },
      '.rowCollectionWidth': {
        width: `calc(${flexibleRowAreaWidth} * 0.90)`,
      },
      '.medium-timeseriesmanager': {
        display: 'block',
      },
      '.plotRow': {
        display: 'none',
      },
      '.tooltipSelectWidth': {
        width: '100%',
      },
      '.titleCollectionWidth': {
        minWidth: '40px',
      },
    },
  },
};

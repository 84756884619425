/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as turf from '@turf/turf';
import { produce, WritableDraft } from 'immer';
import { booleanClockwise, rewind, polygonToLine } from '@turf/turf';
import {
  Polygon,
  Feature,
  LineString,
  FeatureCollection,
  GeoJsonProperties,
  Point,
} from 'geojson';
import { Bbox } from '@opengeoweb/webmap';
import {
  defaultGeoJSONStyleProperties,
  emptyGeoJSON,
} from '../MapDraw/geojsonShapes';
import {
  NEW_FEATURE_CREATED,
  NEW_LINESTRING_CREATED,
  NEW_POINT_CREATED,
} from '../MapDraw/mapDrawUtils';
import { DrawMode, SelectionType } from './types';

/**
 * Adds properties to the first geojson feature based on the given property object.
 * It only extends or changes the properties which are defined in styleConfig,
 * all other properties in the geojson are left unchanged.
 * @param geojson
 * @param featureProperties
 */
export const addFeatureProperties = (
  geojson: GeoJSON.FeatureCollection | undefined,
  featureProperties: GeoJSON.GeoJsonProperties,
  featureIndex = 0,
): GeoJSON.FeatureCollection => {
  if (!geojson) {
    return null!;
  }
  return produce(geojson, (draft) => {
    if (
      draft.features &&
      draft.features.length > 0 &&
      draft.features[featureIndex] !== undefined &&
      draft.features[featureIndex].properties
    ) {
      Object.keys(featureProperties!).forEach((key) => {
        draft.features[featureIndex].properties![key] = featureProperties![key];
      });
    }
  });
};

export const getGeoJSONPropertyValue = (
  property: string,
  properties: GeoJSON.GeoJsonProperties,
  polygonDrawMode: DrawMode | undefined,
  defaultProperties: GeoJSON.GeoJsonProperties = defaultGeoJSONStyleProperties,
): number | string => {
  // if a shape is set, extract the style from there
  if (properties![property] !== undefined) {
    return properties![property];
  }
  // if active polygon tool is preset, retreive style from there
  if (polygonDrawMode) {
    const polygonDrawModeProperty =
      polygonDrawMode.shape.type === 'Feature' &&
      polygonDrawMode.shape.properties![property];

    if (polygonDrawModeProperty !== undefined) {
      return polygonDrawModeProperty;
    }
  }
  // otherwise get values from defaultStyle
  return defaultProperties![property];
};

/**
 * moves geoJSON feature as new feature. Mutates newGeoJSON
 * @constructor
 * @param {GeoJSON.FeatureCollection} currentGeoJSON - current geoJSON
 * @param {GeoJSON.FeatureCollection} newGeoJSON - new geoJSON
 * @param {number} featureLayerIndex - feature layer index
 * @param {string} text - reason of change
 */
export const moveFeature = (
  currentGeoJSON: GeoJSON.FeatureCollection,
  newGeoJSON: GeoJSON.FeatureCollection,
  featureLayerIndex: number,
  reason: string,
  selectionType?: string,
): number | undefined => {
  const feature = newGeoJSON.features[featureLayerIndex];
  const currentFeature = currentGeoJSON.features[featureLayerIndex];

  if (feature) {
    const { geometry } = feature;

    if (
      geometry.type === 'Polygon' &&
      geometry.coordinates.length > 1 &&
      reason === NEW_FEATURE_CREATED
    ) {
      const lastCoordinate = geometry.coordinates.pop();
      const copyFeature: GeoJSON.Feature<GeoJSON.Polygon> = {
        ...feature,
        geometry: {
          ...geometry,
          coordinates: [lastCoordinate],
        },
        ...(selectionType && {
          properties: {
            ...feature.properties,
            selectionType,
          },
        }),
      } as GeoJSON.Feature<GeoJSON.Polygon>;

      newGeoJSON.features.push(copyFeature);

      return newGeoJSON.features.length - 1;
    }
    if (
      geometry.type === 'LineString' &&
      geometry.coordinates.length > 2 &&
      reason === NEW_LINESTRING_CREATED
    ) {
      const lastCoordinate = geometry.coordinates.pop() || [0, 0];
      const copyFeature: GeoJSON.Feature<GeoJSON.LineString> = {
        ...feature,
        geometry: {
          ...geometry,
          coordinates: [[...lastCoordinate], [...lastCoordinate]],
        },
        ...(selectionType && {
          properties: {
            ...feature.properties,
            selectionType,
          },
        }),
      } as GeoJSON.Feature<GeoJSON.LineString>;

      newGeoJSON.features.push(copyFeature);
      return newGeoJSON.features.length - 1;
    }
    if (
      geometry.type === 'Point' &&
      currentFeature &&
      currentFeature.geometry.type === 'Point' &&
      currentFeature.geometry.coordinates.length > 0 &&
      reason === NEW_POINT_CREATED
    ) {
      const copyFeature: GeoJSON.Feature<GeoJSON.Point> = {
        ...currentFeature,
        ...(selectionType && {
          properties: {
            ...feature.properties,
            selectionType,
          },
        }),
      } as GeoJSON.Feature<GeoJSON.Point>;

      newGeoJSON.features.push(copyFeature);

      return newGeoJSON.features.length - 1;
    }
  }

  return undefined;
};

/**
 * Returns the intersection of two point features. In case of a polygon, only the first feature is used.
 * @param a Feature A
 * @param b Feature B
 * @returns The intersection of the two features.
 */
export const intersectPointGeoJSONS = (
  a: GeoJSON.FeatureCollection<GeoJSON.Point>,
  b: GeoJSON.FeatureCollection,
  geoJSONProperties = {
    stroke: '#FF0000',
    'stroke-width': 10.0,
    'stroke-opacity': 1,
    fill: '#0000FF',
    'fill-opacity': 1.0,
  },
): GeoJSON.FeatureCollection => {
  const featureA = turf.feature(a.features[0].geometry);
  const featureB = turf.feature(b.features[0].geometry as Polygon);
  const options = { tolerance: 0.001, highQuality: true };
  const simplifiedB = turf.simplify(featureB, options);
  const isInside = turf.booleanPointInPolygon(
    a.features[0].geometry,
    simplifiedB,
  );

  return addFeatureProperties(
    {
      type: 'FeatureCollection',
      features: !isInside
        ? [
            {
              type: 'Feature',
              properties: {
                selectionType: 'point',
              },
              geometry: {
                type: 'Point',
                coordinates: [],
              },
            },
          ]
        : [featureA],
    },
    geoJSONProperties,
  );
};

/**
 * Returns the intersection of two (multi) polygon features. In case of a polygon, only the first feature is used.
 * @param a Feature A
 * @param b Feature B
 * @returns The intersection of the two features.
 */
export const intersectPolygonGeoJSONS = (
  a: GeoJSON.FeatureCollection,
  b: GeoJSON.FeatureCollection,
  geoJSONProperties = {
    stroke: '#FF0000',
    'stroke-width': 10.0,
    'stroke-opacity': 1,
    fill: '#0000FF',
    'fill-opacity': 1.0,
  },
): GeoJSON.FeatureCollection => {
  const featureA = turf.feature(a.features[0].geometry as Polygon);
  const featureB = turf.feature(b.features[0].geometry as Polygon);
  const options = { tolerance: 0.001, highQuality: true };
  const simplifiedA = turf.simplify(featureA, options);
  const simplifiedB = turf.simplify(featureB, options);
  const intersection = turf.intersect(
    turf.featureCollection([simplifiedA, simplifiedB]),
  );

  return addFeatureProperties(
    {
      type: 'FeatureCollection',
      features:
        intersection === null
          ? [
              {
                type: 'Feature',
                properties: {
                  selectionType: 'poly',
                },
                geometry: {
                  type: 'Polygon',
                  coordinates: [[]],
                },
              },
            ]
          : [intersection],
    },
    geoJSONProperties,
  );
};

export const isPointFeatureCollection = (
  geojson: GeoJSON.FeatureCollection,
): geojson is GeoJSON.FeatureCollection<Point> => {
  return geojson.features[0].geometry.type === 'Point';
};

/**
 * Adds the intersectionStart and intersectionEnd properties to the GeoJSONS structure
 * @param geoJSONs
 * @returns GeoJSONS extend with intersections
 */

export const createInterSections = (
  geojson: GeoJSON.FeatureCollection,
  otherGeoJSON: GeoJSON.FeatureCollection,
  geoJSONproperties: GeoJSON.GeoJsonProperties = {
    stroke: '#f24a00',
    'stroke-width': 1.5,
    'stroke-opacity': 1,
    fill: '#f24a00',
    'fill-opacity': 0.5,
  },
): GeoJSON.FeatureCollection => {
  const intersections = produce(geojson, () => {
    try {
      if (isPointFeatureCollection(geojson)) {
        return addFeatureProperties(
          intersectPointGeoJSONS(geojson, otherGeoJSON!),
          geoJSONproperties,
        );
      }
      return addFeatureProperties(
        intersectPolygonGeoJSONS(geojson, otherGeoJSON!),
        geoJSONproperties,
      );
    } catch (error) {
      return addFeatureProperties(geojson, geoJSONproperties);
    }
  });
  return intersections as unknown as GeoJSON.FeatureCollection;
};

export const getGeoJson = (
  geojson: GeoJSON.FeatureCollection,
  shouldAllowMultipleShapes: boolean,
): GeoJSON.FeatureCollection => {
  if (shouldAllowMultipleShapes || !geojson.features.length) {
    return geojson;
  }

  const { geometry } = geojson.features[0];
  if (geometry.type === 'Polygon' && geometry.coordinates.length > 1) {
    return produce(
      geojson,
      (draft: WritableDraft<FeatureCollection<Polygon>>) => {
        const draftFeature = draft.features[0];
        draftFeature.geometry.coordinates = [
          draftFeature.geometry.coordinates[1],
        ];
      },
    );
  }

  return geojson;
};

const hasFeatures = (geometry: GeoJSON.Geometry): boolean => {
  if (geometry.type === 'Point') {
    return geometry.coordinates.length > 0;
  }
  if (geometry.type === 'LineString' || geometry.type === 'Polygon') {
    return geometry.coordinates[0].length > 0;
  }

  return false;
};

export const getLastEmptyFeatureIndex = (
  geoJSONFeatureCollection: GeoJSON.FeatureCollection,
): number | undefined => {
  const totalFeatures = geoJSONFeatureCollection.features.length;
  if (totalFeatures > 0) {
    const lastFeatureIndex = totalFeatures - 1;
    const lastFeature = geoJSONFeatureCollection.features[lastFeatureIndex];

    if (!hasFeatures(lastFeature.geometry)) {
      return lastFeatureIndex;
    }
  }
  return undefined;
};

export const getFeatureCollection = (
  geoJSONFeature: GeoJSON.Feature | GeoJSON.FeatureCollection,
  shouldAllowMultipleShapes: boolean,
  geoJSONFeatureCollection: GeoJSON.FeatureCollection = emptyGeoJSON,
): GeoJSON.FeatureCollection => {
  if (geoJSONFeature.type === 'FeatureCollection') {
    return geoJSONFeature;
  }

  if (shouldAllowMultipleShapes) {
    const lastFeatureIndex = getLastEmptyFeatureIndex(geoJSONFeatureCollection);

    if (lastFeatureIndex !== undefined) {
      // replace last feature if it's an empty shape
      return {
        ...geoJSONFeatureCollection,
        features: geoJSONFeatureCollection.features.map((feature, index) =>
          index === lastFeatureIndex ? geoJSONFeature : feature,
        ),
      };
    }

    return {
      ...geoJSONFeatureCollection,
      features: geoJSONFeatureCollection.features.concat(geoJSONFeature),
    };
  }
  return { ...geoJSONFeatureCollection, features: [geoJSONFeature] };
};

// check if geoJSON has same selectionType in properties as passed selectionType
export const isGeoJSONFeatureCreatedByTool = (
  existingJSON: GeoJSON.FeatureCollection,
  selectionType: SelectionType,
  featureLayerIndex = 0,
): boolean => {
  if (!existingJSON.features.length) {
    return false;
  }
  const lastUsedTool =
    existingJSON.features[featureLayerIndex].properties?.selectionType;

  return lastUsedTool !== undefined && selectionType !== undefined
    ? lastUsedTool === selectionType
    : false;
};

export const rewindGeometry = (
  geoJSON: FeatureCollection,
): FeatureCollection => {
  return produce(geoJSON, (geoJSONDraft) => {
    if (
      geoJSONDraft &&
      geoJSONDraft.features.length > 0 &&
      geoJSONDraft.features[0].geometry.type === 'Polygon' &&
      geoJSONDraft.features[0].geometry.coordinates[0].length > 1
    ) {
      const { geometry } = geoJSON.features[0] as Feature<Polygon>;
      const lineString = polygonToLine(geometry!) as Feature<LineString>;
      if (booleanClockwise(lineString.geometry!.coordinates)) {
        // eslint-disable-next-line no-param-reassign
        geoJSONDraft.features[0].geometry = rewind(
          geoJSONDraft.features[0].geometry,
        ) as WritableDraft<Polygon>;
      }
    }
  });
};

const addGeoJSONPropertiesToFeature = (
  geoJSONFeature: GeoJSON.Feature,
  newProperties: GeoJsonProperties,
): GeoJSON.Feature => ({
  ...geoJSONFeature,
  properties: {
    ...geoJSONFeature.properties,
    ...newProperties,
  },
});

export const addGeoJSONProperties = (
  geoJSON: GeoJSON.Feature | GeoJSON.FeatureCollection,
  newProperties: GeoJsonProperties,
): GeoJSON.Feature | GeoJSON.FeatureCollection => {
  if (geoJSON.type === 'Feature') {
    return addGeoJSONPropertiesToFeature(geoJSON, newProperties);
  }
  return {
    ...geoJSON,
    features: geoJSON.features.map((feature) =>
      addGeoJSONPropertiesToFeature(feature, newProperties),
    ),
  };
};

export const addSelectionTypeToGeoJSON = (
  geoJSON: GeoJSON.Feature | GeoJSON.FeatureCollection,
  selectionType: SelectionType,
): GeoJSON.Feature | GeoJSON.FeatureCollection => {
  return addGeoJSONProperties(geoJSON, { selectionType });
};

export const getFeatureExtent = (
  geoJSON: GeoJSON.Feature | GeoJSON.FeatureCollection,
): Bbox => {
  const turfBbox = turf.bbox(geoJSON);
  return {
    left: turfBbox[0],
    bottom: turfBbox[1],
    right: turfBbox[2],
    top: turfBbox[3],
  };
};

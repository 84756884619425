/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Home, Add, Minus } from '@opengeoweb/theme';

import { useWebmapReactTranslation } from '../../utils/i18n';
import { MapControlButton } from './MapControlButton';

interface ZoomControlsProps {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
}

export const ZoomControls: React.FC<ZoomControlsProps> = ({
  onZoomIn,
  onZoomOut,
  onZoomReset,
}: ZoomControlsProps) => {
  const { t } = useWebmapReactTranslation();
  return (
    <>
      <MapControlButton
        title={t('webmap-react-zoom-reset')}
        data-testid="zoom-reset"
        onClick={onZoomReset}
      >
        <Home />
      </MapControlButton>
      <MapControlButton
        title={t('webmap-react-zoom-in')}
        data-testid="zoom-in"
        onClick={onZoomIn}
      >
        <Add />
      </MapControlButton>
      <MapControlButton
        title={t('webmap-react-zoom-out')}
        data-testid="zoom-out"
        onClick={onZoomOut}
      >
        <Minus />
      </MapControlButton>
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Button, Card, CardContent, Typography } from '@mui/material';
import { ToggleMenu, dateUtils } from '@opengeoweb/shared';
import { Edit, Visibility, Delete, Add } from '@opengeoweb/theme';
import React from 'react';
import {
  ReactHookFormHiddenInput,
  errorMessages,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';
import { TFunction } from 'i18next';
import { useWarningsTranslation } from '../../../utils/i18n';

export const formatDate = (lastUpdatedTime: string): string =>
  dateUtils.dateToString(
    dateUtils.isoStringToDate(lastUpdatedTime, false),
    `${dateUtils.DATE_FORMAT_DATEPICKER} 'UTC'`,
  )!;

const getObjectName = (
  geoJSON: GeoJSON.FeatureCollection,
  objectName: string,
  t: TFunction,
): string => {
  if (geoJSON) {
    return objectName || t('object-manager-object-selected');
  }
  return t('object-manager-no-object-selected');
};

export const AreaField: React.FC<{
  onEditObject: () => void;
  onViewObject: () => void;
  onDeleteObject: () => void;
  onAddObject: () => void;
  isDisabled?: boolean;
}> = ({
  onEditObject = (): void => {},
  onViewObject = (): void => {},
  onDeleteObject = (): void => {},
  onAddObject = (): void => {},
  isDisabled = false,
}) => {
  const { t } = useWarningsTranslation();
  const {
    getValues,
    formState: { errors },
  } = useFormContext();
  const { isRequired } = useDraftFormHelpers();
  const lastUpdatedTime = getValues('object.lastUpdatedTime');
  const geoJSON = getValues('areas[0].geoJSON');
  const objectName = getObjectName(
    geoJSON,
    getValues('areas[0].objectName'),
    t,
  );

  const hasErrors = errors?.areas !== undefined;

  return (
    <>
      <Typography
        variant="h3"
        color="text.secondary"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 'normal',
          lineHeight: 1.83,
          paddingLeft: 1.5,
          ...(hasErrors && {
            color: 'geowebColors.captions.captionError.color',
          }),
        }}
        {...(hasErrors && {
          'aria-errormessage': errorMessages.required,
        })}
      >
        {t('warning-area')}
      </Typography>
      <Card elevation={0} sx={{ ...(!geoJSON && { background: 'none' }) }}>
        <CardContent
          sx={{
            position: 'relative',
            padding: 1,
            '&:last-child': { paddingBottom: 1 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: 'text.primary',
              fontSize: '0.75rem',
              fontWeight: 'normal',
              lineHeight: 1.83,
            }}
            role="heading"
          >
            {objectName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {lastUpdatedTime ? formatDate(lastUpdatedTime) : ''}
          </Typography>

          {geoJSON ? (
            <ToggleMenu
              menuTitle={t('object-manager-options-menu')}
              tooltipTitle={t('object-manager-options-menu')}
              buttonSx={{
                position: 'absolute',
                right: 0,
                margin: 'auto',
                top: 0,
                bottom: 0,
                marginRight: '8px',
              }}
              menuPosition="bottom"
              isDisabled={true}
              menuItems={[
                {
                  text: t('object-manager-button-edit'),
                  action: onEditObject,
                  icon: <Edit />,
                },
                {
                  text: t('object-manager-button-view'),
                  action: onViewObject,
                  icon: <Visibility />,
                },
                {
                  text: t('object-manager-button-delete'),
                  action: onDeleteObject,
                  icon: <Delete />,
                },
              ]}
            />
          ) : null}
        </CardContent>
      </Card>
      <ReactHookFormHiddenInput
        rules={{
          validate: { isRequired },
        }}
        name="areas[0].geoJSON"
      />
      <Button
        sx={{ marginTop: 1 }}
        variant="tertiary"
        startIcon={<Add />}
        size="small"
        onClick={onAddObject}
        disabled={isDisabled}
      >
        {t('warning-button-add-object')}
      </Button>
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { ComponentsVariants, CSSObject } from '@mui/material';
import { ButtonStyle, ButtonVariant } from '../types';

export const BORDER_RADIUS = 3;

export const defaultButtonStyle = (button: ButtonStyle): CSSObject => ({
  // default styling
  color: button.default.color,
  backgroundColor: button.default.fill,
  textTransform: 'capitalize',
  borderRadius: BORDER_RADIUS,
  // hover styling
  '&:hover': {
    backgroundColor: button.mouseOver.fill,
    borderColor: button.mouseOver.borderColor,
    color: button.mouseOver.color,
  },
  // active styling
  '&.Mui-selected': {
    backgroundColor: button.active.fill,
    color: button.active.color,
    borderColor: button.active.borderColor,
    '&:hover': {
      backgroundColor: button.activeMouseOver.fill,
    },
  },
  // disabled styling
  '&.Mui-disabled': {
    backgroundColor: button.disabled.fill,
    color: button.disabled.color,
    borderColor: button.disabled.borderColor,
  },
  // focus styling
  '&.Mui-focusVisible': {
    outline: button.focus.outline,
    '&:not(.Mui-selected)': {
      borderColor: 'transparent',
      backgroundColor: button.mouseOver.fill,
    },
  },
});

export const buttonStyle = (button: ButtonStyle): CSSObject => ({
  ...defaultButtonStyle(button),
  padding: '12px 8px',
  height: '40px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: button.default.borderColor,
  borderRadius: BORDER_RADIUS,
  // size styling
  '&[class*="sizeSmall"]': {
    fontSize: '12px',
    height: '32px',
    lineHeight: '12px',
  },
  // icon styling
  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
    fontSize: '24px!important',
  },
});

export const iconButtonStyle = (button: ButtonStyle): CSSObject => ({
  ...defaultButtonStyle(button),
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: button.default.borderColor,
  // size styling
  '&[class*="sizeSmall"]': {
    width: 24,
    height: 24,
  },
  '&[class*="sizeMedium"]': {
    width: 32,
    height: 32,
  },
  '&[class*="sizeLarge"]': {
    width: 40,
    height: 40,
  },
});

type PartialButtonVariants = Partial<Record<ButtonVariant, ButtonStyle>>;

export const buttonVariants = (
  buttons: PartialButtonVariants,
  stylingFn = buttonStyle,
): ComponentsVariants['MuiButton'] => {
  return Object.keys(buttons).map((key) => {
    const variant = key as ButtonVariant;
    return {
      props: { variant: variant as ButtonVariant },
      style: stylingFn(buttons[variant]!),
    };
  });
};

export const variantsToClassName = (
  buttonVariants: ComponentsVariants['MuiButton'],
): CSSObject =>
  buttonVariants!.reduce(
    // @ts-expect-error Somehow typecheck thinks variant is not part of ButtonProps here
    (variants, { props: { variant }, style }) => ({
      ...variants,
      [`&.${variant}`]: style,
    }),
    {},
  );

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

export interface AvatarProps {
  children: React.ReactNode;
  size?: 'small' | 'large';
}

const styles = {
  small: {
    height: 16,
    width: 16,
    fontSize: '0.5rem',
  },
  large: {},
};

const Avatar: React.FC<AvatarProps> = ({
  children,
  size = 'large',
}: AvatarProps) => {
  return (
    <MuiAvatar
      data-testid="avatar"
      sx={{
        ...styles[size],
      }}
    >
      {children}
    </MuiAvatar>
  );
};

export default Avatar;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { SystemScope } from '@opengeoweb/shared';
import { LoadingIndicatorModuleState } from '../loadingIndicator/types';
import type { GenericActionPayload } from '../types';

import {
  SYNCGROUPS_TYPE_SETBBOX,
  SYNCGROUPS_TYPE_SETLAYERACTIONS,
  SYNCGROUPS_TYPE_SETTIME,
} from './constants';

export interface Group {
  id: string;
  selected: string[];
}

export interface SourceById {
  id: string;
  name: string;
}

export interface GroupsAndSources {
  groups: Group[];
  sourcesById: SourceById[];
}

export interface SyncGroupViewState {
  timeslider: GroupsAndSources;
  zoompane: GroupsAndSources;
  level: GroupsAndSources;
  [key: string]: GroupsAndSources;
}

export interface SynchronizationGroup {
  type: SyncType;
  title?: string;
  payloadByType: SynchronizationSourcePayLoadByType;
  targets: {
    byId: Record<string, { linked: boolean }>;
    allIds: string[];
  };
}

export interface PotentialData {
  features?: {
    id: string;
    originalId?: string;
    geoJSON: GeoJSON.FeatureCollection;
  }[];
  selectedFeatureId?: string;
  formFeatures?: { id: string; geoJSON: GeoJSON.FeatureCollection }[];
  hoverId?: string;
}
export interface LinkedState {
  links: Record<string, string[]>;
  sharedData: Record<string, PotentialData>;
}

export type SynchronizationSourcePayLoadByType = Record<
  string,
  GenericActionPayload
>;
export interface SynchronizationSource {
  types: SyncType[];
  payloadByType: SynchronizationSourcePayLoadByType;
}

export interface SynchronizationSources {
  byId: Record<string, SynchronizationSource>;
  allIds: string[];
}

export interface SynchronizationGroups {
  byId: Record<string, SynchronizationGroup>;
  allIds: string[];
}

export interface SynchronizationGroupState {
  sources: SynchronizationSources;
  groups: SynchronizationGroups;
  viewState: SyncGroupViewState;
  linkedState: LinkedState;
  isTimeScrollingEnabled: boolean;
}

export interface SynchronizationGroupModuleState {
  syncGroups?: SynchronizationGroupState;
  loadingIndicator?: LoadingIndicatorModuleState['loadingIndicator'];
}

export type SyncType =
  | typeof SYNCGROUPS_TYPE_SETBBOX
  | typeof SYNCGROUPS_TYPE_SETTIME
  | typeof SYNCGROUPS_TYPE_SETLAYERACTIONS;

export interface SyncGroupsAddSourcePayload {
  id: string;
  type: SyncType[];
  defaultPayload?: GenericActionPayload;
}

export interface SyncGroupRemoveSourcePayload {
  id: string;
}

export interface SyncGroupTarget {
  groupId: string;
  targetId: string;
  linked?: boolean;
  [key: string]: unknown;
}

export interface SyncGroupAddTargetPayload extends SyncGroupTarget {
  origin?: SystemScope;
}

export interface SyncGroupRemoveTargetPayload {
  groupId: string;
  targetId: string;
  origin?: SystemScope;
}

export interface SyncGroupLinkTargetPayload extends SyncGroupTarget {
  linked: boolean;
}

export interface SyncGroupAddGroupPayload {
  groupId: string;
  title: string /* Title for sync group */;
  type: SyncType /* SyncGroup type */;
  origin?: SystemScope;
}

export interface SyncGroupRemoveGroupPayload {
  groupId: string;
}

export interface SyncGroupSetViewStatePayload {
  viewState: SyncGroupViewState;
}

export interface SyncGroupToggleIsTimeScrollingEnabled {
  isTimeScrollingEnabled: boolean;
  origin?: SystemScope;
}

export const SyncGroupTypeList = [
  SYNCGROUPS_TYPE_SETBBOX,
  SYNCGROUPS_TYPE_SETTIME,
];

export enum SyncGroupActionOrigin {
  delete = 'ORIGIN_GENERIC_SYNCHRONIZATIONGROUP_UTILS_DELETEACTION',
  layerActions = 'ORIGIN_GENERIC_SYNCHRONIZATIONGROUP_UTILS_LAYERACTIONS',
  move = 'ORIGIN_GENERIC_SYNCHRONIZATIONGROUP_UTILS_MOVEACTION',
  add = 'ORIGIN_GENERIC_SYNCHRONIZATIONGROUP_UTILS_ADDACTION',
  autoLayerId = 'ORIGIN_GENERIC_SYNCHRONIZATIONGROUP_UTILS_AUTOLAYERIDACTION',
}

export interface SetLinkedMap {
  newLinks: Record<string, string[]>;
}

export interface UpdateLinkedMap {
  panelId: string;
  mapIds: string[];
}

export interface AddSharedData {
  panelId: string;
  data: PotentialData;
}

export interface DeleteSharedData {
  panelId: string;
}

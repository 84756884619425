/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';

import { SvgIconProps } from '@mui/material';
import { Equalizer, DimensionsElevation } from '@opengeoweb/theme';
import { WMJSDimension } from '@opengeoweb/webmap';
import { TFunction } from 'i18next';

export interface DimensionConfig {
  name: string;
  label: string;
  defaultUnit: string;
  reversed: boolean;
  iconType: string;
}

export const dimensionConfig = (t: TFunction): DimensionConfig[] => [
  {
    name: 'elevation',
    label: t('webmap-react-vertical-level'),
    defaultUnit: t('webmap-react-hpa'),
    reversed: true,
    iconType: 'IconLevels',
  },
  {
    name: 'ensemble_member',
    label: t('webmap-react-ensemble-member'),
    defaultUnit: t('webmap-react-member'),
    reversed: false,
    iconType: 'IconEnsemble',
  },
  {
    name: 'probability',
    label: t('webmap-react-probability'),
    defaultUnit: t('webmap-react-percent'),
    reversed: false,
    iconType: 'IconLevels',
  },
  {
    name: 'level',
    label: t('webmap-react-level'),
    defaultUnit: t('webmap-react-level'),
    reversed: false,
    iconType: 'IconLevels',
  },
];

export const getDimensionIcon = (iconType: string): React.FC<SvgIconProps> => {
  if (iconType === 'IconEnsemble') {
    return Equalizer;
  }
  return DimensionsElevation;
};

export interface Mark {
  value: number | string;
  label?: React.ReactNode;
}

export const marksByDimension = (t: TFunction, dim: WMJSDimension): Mark[] => {
  if (!dim || !dim.name) {
    return [];
  }
  const dimCnf = dimensionConfig(t).find((cnf) => cnf.name === dim.name);
  const defaultUnit = dimCnf ? dimCnf.defaultUnit : '';
  const marks: Mark[] = [];
  if (dim && dim.size) {
    for (let i = 0; i < dim.size(); i += 1) {
      marks.push({
        value:
          Number(dim.getValueForIndex(i)) ||
          (dim.getValueForIndex(i) as string),
        label: `${Number(dim.getValueForIndex(i)) || dim.getValueForIndex(i)} ${
          dim.unitSymbol ? dim.unitSymbol : defaultUnit
        }`,
      });
    }
  }
  return marks;
};

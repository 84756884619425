/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomToggleButton } from '@opengeoweb/shared';
import { layerTypes } from '@opengeoweb/store';
import { useLayerSelectTranslation } from '../../utils/i18n';

interface AddLayerParams {
  serviceUrl: string;
  layerName: string;
}

export interface DeleteLayerParams {
  layerId: string;
  layerIndex: number;
}
interface LayerAddRemoveButtonProps {
  layer: layerTypes.ReduxLayer;
  serviceUrl: string;
  addLayer: ({ serviceUrl, layerName }: AddLayerParams) => void;
  deleteLayer: ({ layerId, layerIndex }: DeleteLayerParams) => void;
  mapLayers: layerTypes.ReduxLayer[];
}

export const LayerAddRemoveButton: React.FC<LayerAddRemoveButtonProps> = ({
  layer,
  serviceUrl,
  addLayer,
  deleteLayer,
  mapLayers,
}: LayerAddRemoveButtonProps) => {
  const { t } = useLayerSelectTranslation();
  const foundLayerIndex = mapLayers.findIndex(
    (lr) => lr.name === layer.name && lr.service === serviceUrl,
  )!;
  const foundLayer = foundLayerIndex !== -1 && mapLayers[foundLayerIndex];

  return (
    <CustomToggleButton
      data-testid={`layerAddRemoveButton-${layer.name}`}
      onClick={(): void => {
        !foundLayer
          ? addLayer({ serviceUrl, layerName: layer.name! })
          : deleteLayer({
              layerId: foundLayer.id!,
              layerIndex: foundLayerIndex,
            });
      }}
      selected={!!foundLayer}
      variant="tool"
      fullWidth={true}
      sx={{ fontSize: '12px' }}
      className="layerAddRemoveButton"
    >
      {!foundLayer ? t('add') : t('remove')}
    </CustomToggleButton>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CoreAppStore,
  layerActions,
  layerSelectors,
  layerTypes,
  serviceSelectors,
} from '@opengeoweb/store';
import RenderStyles from './RenderStyles';

interface RenderStylesProps {
  layerId: string;
  mapId: string;
  icon?: React.ElementType;
  tooltipPrefix?: string;
  skipLocalStyling?: boolean;
}

const RenderStylesConnect: React.FC<RenderStylesProps> = ({
  layerId,
  mapId,
  icon,
  tooltipPrefix,
  skipLocalStyling,
}: RenderStylesProps) => {
  const dispatch = useDispatch();
  const layerService = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerService(store, layerId),
  );
  const layerName = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerName(store, layerId),
  );

  const layerStyles = useSelector((store: CoreAppStore) => {
    return serviceSelectors.getLayerStyles(store, layerService, layerName);
  });

  const currentLayerStyle = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerStyle(store, layerId),
  );
  const isLayerEnabled = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerEnabled(store, layerId),
  );

  const layerChangeStyle = React.useCallback(
    (style: string) =>
      dispatch(
        layerActions.layerChangeStyle({
          layerId,
          style,
          mapId,
          origin: layerTypes.LayerActionOrigin.layerManager,
        }),
      ),
    [layerId, dispatch, mapId],
  );

  return (
    <RenderStyles
      layerStyles={layerStyles}
      currentLayerStyle={currentLayerStyle}
      onChangeLayerStyle={layerChangeStyle}
      isEnabled={isLayerEnabled}
      icon={icon}
      tooltipPrefix={tooltipPrefix}
      skipLocalStyling={skipLocalStyling}
    />
  );
};

export default RenderStylesConnect;

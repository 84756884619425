/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import {
  CoreAppStore,
  getSingularDrawtoolDrawLayerId,
  layerActions,
  layerSelectors,
  uiActions,
  uiSelectors,
  uiTypes,
  useSetupDialog,
} from '@opengeoweb/store';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { emptyGeoJSON } from '@opengeoweb/webmap-react';

import { ObjectManager } from './ObjectManager';
import { DrawingListItem } from '../../store/warningsDrawings/types';
import { getWarningsApi } from '../../utils/api';
import { warningsDrawingsActions } from '../../store/warningsDrawings/reducer';
import { selectDrawByInstanceId } from '../../store/warningsDrawings/selectors';
import { useObjectDrawDialogAction } from '../DrawingTool/useObjectDrawDialogAction';
import { ConfirmDeleteDialog, getDeleteSucces } from './ConfirmDeleteDialog';
import {
  drawingDialogType,
  objectDialogType,
} from '../../store/warningsDrawings/utils';
import { publicWarningFormActions } from '../../store/publicWarningForm/reducer';
import { PublicWarningDetail } from '../../store/publicWarningForm/types';
import { useWarningsTranslation } from '../../utils/i18n';

export const ObjectManagerConnect: React.FC<{
  bounds?: string;
  source?: uiTypes.Source;
}> = ({ bounds, source = 'app' }) => {
  const { t } = useWarningsTranslation();
  const { dialogOrder, setDialogOrder, isDialogOpen, uiSource, uiError } =
    useSetupDialog(objectDialogType, source);
  const warningsApi = getWarningsApi();

  const dispatch = useDispatch();
  const mapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, objectDialogType),
  );
  const { closeObjectDialog, openDrawDialog } = useObjectDrawDialogAction({
    mapId,
    source,
  });
  const geoJSONLayerId = getSingularDrawtoolDrawLayerId(mapId);

  const currentLayer = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerByIdWithoutTimeDimension(store, geoJSONLayerId),
  );
  const currrentDrawing = useSelector((store: CoreAppStore) =>
    selectDrawByInstanceId(store, drawingDialogType),
  );
  const activeDrawingId = currrentDrawing?.id || '';

  const [drawings, setDrawings] = useState<DrawingListItem[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchDrawings = (): void => {
    dispatch(
      uiActions.setErrorDialog({
        error: '',
        type: objectDialogType,
      }),
    );
    setLoading(true);
    warningsApi
      .getDrawings({})
      .then((response) => {
        setLoading(false);
        setDrawings(response.data);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          uiActions.setErrorDialog({
            error: error.message,
            type: objectDialogType,
          }),
        );
      });
  };

  useEffect(() => {
    if (isDialogOpen) {
      fetchDrawings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const deleteGeoJSONLayer = (): void => {
    if (geoJSONLayerId) {
      dispatch(
        layerActions.layerChangeGeojson({
          layerId: geoJSONLayerId,
          geojson: emptyGeoJSON,
        }),
      );
    }
  };

  const openSnackbar = (message: string): void => {
    dispatch(
      snackbarActions.openSnackbar({
        type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
        message,
      }),
    );
  };

  const showGeoJSONLayer = (drawing: DrawingListItem): void => {
    if (!currentLayer) {
      dispatch(
        layerActions.addLayer({
          mapId,
          layer: { geojson: drawing.geoJSON },
          layerId: geoJSONLayerId!,
          origin: 'warnings-lib',
        }),
      );
    } else {
      dispatch(
        layerActions.layerChangeGeojson({
          layerId: geoJSONLayerId!,
          geojson: drawing.geoJSON,
        }),
      );
    }
  };

  const onClickDrawing = async (drawing: DrawingListItem): Promise<void> => {
    const isActive = drawing.id === currrentDrawing?.id;

    dispatch(
      warningsDrawingsActions.setDrawValues({
        objectName: drawing.objectName,
        drawingInstanceId: drawingDialogType,
        id: !isActive ? drawing.id : '',
      }),
    );

    if (isActive) {
      deleteGeoJSONLayer();
    } else {
      showGeoJSONLayer(drawing);
    }
  };

  const [confirmDialogOptions, setConfirmDialogOptions] = React.useState<
    | {
        objectId: string;
        objectName: string;
      }
    | undefined
  >(undefined);

  const openDrawTools = async (drawingId: string): Promise<void> => {
    dispatch(
      uiActions.setErrorDialog({
        error: '',
        type: objectDialogType,
      }),
    );
    setLoading(true);
    try {
      // if another geoJSON object is shown, remove that
      if (drawingId !== activeDrawingId) {
        // clear shape
        deleteGeoJSONLayer();
      }

      const { data } = await warningsApi.getDrawingDetails(drawingId);

      // update draw tool values
      dispatch(
        warningsDrawingsActions.setDrawValues({
          objectName: data.objectName,
          id: drawingId,
          drawingInstanceId: drawingDialogType,
        }),
      );

      setLoading(false);
      openDrawDialog(data.geoJSON);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        dispatch(
          uiActions.setErrorDialog({
            error: error.message,
            type: objectDialogType,
          }),
        );
      }
    }
  };

  const onEditDrawing = (drawingId: string): void => {
    void openDrawTools(drawingId);
  };

  const onClose = (): void => {
    closeObjectDialog();
  };

  const onShareObject = (object: DrawingListItem): void => {
    dispatch(
      publicWarningFormActions.openPublicWarningFormDialog({
        object,
        warning: {
          warningDetail: {
            areas: [{ geoJSON: object.geoJSON, objectName: object.objectName }],
          } as PublicWarningDetail,
          type: 'public',
        },
      }),
    );
  };

  const onCreateNewObject = (): void => {
    closeObjectDialog();
    openDrawDialog();
  };

  const onDeleteSuccess = (objectId: string, objectName: string): void => {
    // close confirm dialog
    setConfirmDialogOptions(undefined);
    // remove any geoJSON layer of deleted object if set
    if (activeDrawingId && activeDrawingId === objectId) {
      deleteGeoJSONLayer();
    }
    openSnackbar(getDeleteSucces(objectName, t));
    fetchDrawings();
  };

  const onDeleteDrawing = (objectId: string): void => {
    const currentObject = drawings.find((drawing) => drawing.id === objectId);
    const objectName = currentObject?.objectName!;

    setConfirmDialogOptions({ objectId, objectName });
  };

  return (
    <>
      <ObjectManager
        title={`${t('object-manager-title')} ${mapId}`}
        bounds={bounds}
        isOpen={isDialogOpen}
        onClose={onClose}
        onMouseDown={setDialogOrder}
        order={dialogOrder}
        source={uiSource}
        headerSize="xs"
        startPosition={{ top: 104, left: 50 }}
        drawingListItems={drawings}
        onClickDrawing={onClickDrawing}
        onEditDrawing={onEditDrawing}
        onDeleteDrawing={onDeleteDrawing}
        onShareObject={onShareObject}
        onCreateNewObject={onCreateNewObject}
        activeDrawingId={activeDrawingId}
        error={uiError}
        isLoading={loading}
      />
      {confirmDialogOptions && (
        <ConfirmDeleteDialog
          {...confirmDialogOptions}
          onDeleteSucces={onDeleteSuccess}
          onClose={(): void => setConfirmDialogOptions(undefined)}
        />
      )}
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box, BoxProps } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { DEFAULT_FILTER_HEIGHT } from '@opengeoweb/shared';
import { useWorkspaceTranslation } from '../../utils/i18n';
import {
  WorkspacePresetListItem,
  WorkspacePresetAction,
} from '../../store/workspace/types';
import WorkspaceSelectListRow, {
  WORKSPACE_SELECT_LIST_ROW_HEIGHT,
} from './WorkspaceSelectListRow';
import { getEmptyWorkspaceListItem } from '../../store/workspaceList/utils';

export interface WorkspaceSelectListProps extends BoxProps {
  workspacePresets: WorkspacePresetListItem[];
  currentSelectedId: string;
  onClickWorkspacePreset: (id: string) => void;
  onClickWorkspacePresetOption: (
    presetId: string,
    workspaceAction: WorkspacePresetAction,
    workspacePreset: WorkspacePresetListItem,
  ) => void;
  hideNewWorkspace?: boolean;
  isEditable?: boolean;
  isAdmin?: boolean;
  searchQuery?: string;
  newPreset?: WorkspacePresetListItem;
  testIdPrefix?: string; // Specifies if used for workspace or viewpreset, added to testids
}

const MIN_HEIGHT_SCROLLABLE_LIST = 88; // Slightly less than 2 rows
const LIST_PADDING_TOP = 8;
const DEFAULT_RESULTS_HEIGHT = 22;

// row height = height + margin
const rowHeight = WORKSPACE_SELECT_LIST_ROW_HEIGHT + 4;

const WorkspaceSelectList: React.FC<WorkspaceSelectListProps> = ({
  workspacePresets,
  currentSelectedId,
  onClickWorkspacePreset,
  onClickWorkspacePresetOption,
  hideNewWorkspace,
  isEditable,
  isAdmin,
  searchQuery,
  newPreset,
  testIdPrefix = 'workspace',
}: WorkspaceSelectListProps) => {
  const { t } = useWorkspaceTranslation();
  const newPresetTruthy = newPreset || getEmptyWorkspaceListItem(t);
  const items = React.useMemo(
    () =>
      !hideNewWorkspace
        ? [newPresetTruthy, ...workspacePresets]
        : workspacePresets,
    [hideNewWorkspace, newPresetTruthy, workspacePresets],
  );

  return (
    <Box
      data-testid={`${testIdPrefix}-selectList`}
      sx={{
        height: `calc(100% - ${DEFAULT_FILTER_HEIGHT}px)`,
        minHeight: MIN_HEIGHT_SCROLLABLE_LIST,
        overflow: 'hidden',
      }}
    >
      <Box sx={{ marginBottom: '4px', fontSize: '12px' }}>
        {workspacePresets.length} {t('workspace-results')}
      </Box>
      <AutoSizer>
        {({ height = 0, width = 0 }): React.ReactNode => (
          <Box
            sx={{
              paddingTop: `${LIST_PADDING_TOP}px`,
            }}
          >
            <List
              height={height - DEFAULT_RESULTS_HEIGHT}
              width={width}
              itemCount={items.length}
              itemSize={rowHeight}
            >
              {({ index, style }): React.ReactElement => {
                const preset = items[index];
                return (
                  <div style={style} key={preset.id || 'emptyPreset'}>
                    <WorkspaceSelectListRow
                      workspacePreset={preset}
                      onClickWorkspacePreset={onClickWorkspacePreset}
                      onClickWorkspacePresetOption={
                        onClickWorkspacePresetOption
                      }
                      isEditable={isEditable}
                      isAdmin={isAdmin}
                      searchQuery={searchQuery}
                      isSelected={
                        currentSelectedId === preset.id ||
                        (currentSelectedId === '' && preset.id === undefined)
                      }
                      testIdPrefix={testIdPrefix}
                    />
                  </div>
                );
              }}
            </List>
          </Box>
        )}
      </AutoSizer>
    </Box>
  );
};

export default WorkspaceSelectList;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Feature, GeoJsonProperties, GeometryObject } from 'geojson';
import { ListChildComponentProps } from 'react-window';
import { LocationListItem } from './LocationListItem';

export const renderRow =
  (
    features: Feature<GeometryObject, GeoJsonProperties>[],
    handleChange: (newSelectedLocation: string) => void,
    selectedLocation: string,
  ) =>
  (props: ListChildComponentProps): React.ReactElement => {
    const { index, style } = props;
    const feature = features[index];

    return (
      <div style={{ ...style }}>
        <LocationListItem
          key={feature.id}
          location={feature.properties?.name as string}
          handleChange={handleChange}
          selectedLocation={selectedLocation}
        />
      </div>
    );
  };

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Theme } from '@mui/material';
import { dragHandlePath } from '@opengeoweb/theme';
import { dateUtils } from '@opengeoweb/shared';
import {
  Scale,
  getFundamentalScale,
  getScaleFromSpan,
  timestampToPixelEdges,
} from '../../TimeSlider/timeSliderUtils';
import {
  getCustomRoundedStartAndEnd,
  getCustomTimesteps,
} from './drawTimeScale';

export const drawBackground = (
  context: CanvasRenderingContext2D,
  theme: Theme,
  visibleTimeStart: number,
  visibleTimeEnd: number,
  animationStartPx: number,
  animationEndPx: number,
  canvasWidth: number,
  height: number,
  secondsPerPx: number,
  timeSliderSpan: number,
  dataStartPx: number,
  dataEndPx: number,
  currentTimePx: number,
): void => {
  const ctx = context;
  const {
    timelineNightTime,
    ledgendObservedBackground,
    legendForecastBackground,
    legendNoDataBackground,
  } = theme.palette.geowebColors.timeSlider;

  // top spacing
  const y = 0;

  const scaleToUse = timeSliderSpan
    ? getScaleFromSpan(timeSliderSpan)
    : getFundamentalScale(secondsPerPx);

  drawDefaultBackground();
  drawObservedData();
  drawForecastData();
  drawNightOrAlternateTime();
  drawAnimationArea();
  drawDraggableIcons();

  function drawDefaultBackground(): void {
    ctx.fillStyle = legendNoDataBackground.rgba!;
    ctx.fillRect(0, y, canvasWidth, height);
  }

  function drawObservedData(): void {
    if (dataStartPx! < currentTimePx) {
      ctx.fillStyle = ledgendObservedBackground.fill!;
      ctx.fillRect(
        Math.max(dataStartPx!, y),
        y,
        Math.min(currentTimePx, dataEndPx!) - Math.max(dataStartPx!, y),
        height,
      );
    }
  }

  function drawForecastData(): void {
    if (dataEndPx! > currentTimePx) {
      ctx.fillStyle = legendForecastBackground.fill!;
      ctx.fillRect(
        Math.max(currentTimePx, dataStartPx!),
        y,
        Math.min(canvasWidth, dataEndPx!) -
          Math.max(currentTimePx, dataStartPx!),
        height,
      );
    }
  }

  function drawAnimationArea(): void {
    ctx.fillStyle =
      theme.palette.geowebColors.timeSlider.timelineSelectionBackground.rgba!;
    ctx.strokeStyle =
      theme.palette.geowebColors.timeSlider.timelineSelectionOutline.rgba!;
    ctx.lineWidth = 3;
    ctx.rect(
      animationStartPx,
      y,
      Math.max(animationEndPx - animationStartPx, y),
      height,
    );
    ctx.fill();
    ctx.stroke();
  }

  function drawDraggableIcons(): void {
    ctx.save();
    const path = new Path2D(dragHandlePath);
    ctx.translate(animationStartPx, y);
    ctx.fillStyle =
      theme.palette.geowebColors.timeSlider.timelineTimeScale.fill!;
    ctx.fill(path);
    ctx.restore();
    ctx.save();
    ctx.translate(animationEndPx - 24, y);
    ctx.fillStyle =
      theme.palette.geowebColors.timeSlider.timelineTimeScale.fill!;
    ctx.fill(path);
    ctx.restore();
  }

  function drawNightOrAlternateTime(): void {
    const colorChangeTimesteps = getColorChangeTimesteps(
      scaleToUse,
      visibleTimeStart,
      visibleTimeEnd,
    );
    colorChangeTimesteps.forEach((timestep, i, arr) => {
      if (i === 0) {
        return;
      }
      const [prevPx, currPx] = [arr[i - 1], timestep].map((timestep) =>
        timestampToPixelEdges(
          timestep,
          visibleTimeStart,
          visibleTimeEnd,
          canvasWidth,
        ),
      );
      if (!isColorIntervalEven(scaleToUse, timestep)) {
        ctx.fillStyle = timelineNightTime.rgba!;
        const TOP_OFFSET = 0;
        ctx.fillRect(prevPx, TOP_OFFSET, currPx - prevPx, height);
      }
    });
  }
};

const getColorChangeTimestepUnit = (scale: Scale): string => {
  switch (scale) {
    case Scale.Year:
      return 'months';
    case Scale.Month:
    case Scale.Months3:
      return 'weeks';
    case Scale.Weeks2:
    case Scale.Week:
      return 'days';
    default:
      return 'days';
  }
};

const isColorIntervalEven = (scale: Scale, timestep: number): boolean => {
  const time = dateUtils.fromUnix(timestep);

  switch (scale) {
    case Scale.Year:
      return dateUtils.getMonth(time!) % 2 === 0;
    case Scale.Month:
    case Scale.Months3:
      return dateUtils.getWeek(time!) % 2 === 0;
    case Scale.Week:
    case Scale.Weeks2:
      return dateUtils.getDayOfYear(time!) % 2 === 0;
    default: {
      return dateUtils.getDayOfYear(time!) % 2 === 0;
    }
  }
};

export function getColorChangeTimesteps(
  scale: Scale,
  visibleTimeStart: number,
  visibleTimeEnd: number,
): number[] {
  const [colorChangeStart, colorChangeEnd] = getCustomRoundedStartAndEnd(
    visibleTimeStart,
    visibleTimeEnd,
    scale,
  );
  const colorChangeUnit = getColorChangeTimestepUnit(scale);
  const colorChangeTimesteps = getCustomTimesteps(
    colorChangeStart,
    colorChangeEnd,
    colorChangeUnit,
  );
  return colorChangeTimesteps;
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  CreateApiProps,
  createApiInstance,
  createNonAuthApiInstance,
  getApi,
} from '@opengeoweb/api';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
  DrawingFromBE,
  DrawingFromFE,
  DrawingListItem,
  FetchDrawingParams,
} from '../store/warningsDrawings/types';
import { Warning } from '../store/publicWarningForm/types';

export const API_NAME = 'warnings';

export const getIdFromUrl = (url: string): string => {
  const splitString = url && url.split('/');
  return url && splitString![splitString.length - 2];
};

export interface WarningsApi {
  // drawings
  getDrawings: (
    params?: FetchDrawingParams,
  ) => Promise<{ data: DrawingListItem[] }>;
  getDrawingDetails: (drawingId: string) => Promise<{ data: DrawingFromBE }>;
  updateDrawing: (drawingId: string, data: DrawingFromFE) => Promise<void>;
  saveDrawingAs: (data: DrawingFromFE) => Promise<string>;
  deleteDrawing: (drawingId: string) => Promise<void>;
  getAreaKeywords: () => Promise<{ data: string[] }>;
  // warnings
  saveWarningAs: (data: Warning) => Promise<string>;
  updateWarning: (warningId: string, data: Warning) => Promise<void>;
  deleteWarning: (warningId: string) => Promise<void>;
}

export const getApiRoutes = (axiosInstance: AxiosInstance): WarningsApi => ({
  // drawings
  getDrawings: (params): Promise<{ data: DrawingListItem[] }> => {
    return axiosInstance.get('/drawings', { params });
  },
  getDrawingDetails: (drawingId: string): Promise<{ data: DrawingFromBE }> => {
    return axiosInstance.get(`/drawings/${drawingId}`);
  },
  updateDrawing: (drawingId: string, data: DrawingFromFE): Promise<void> => {
    return axiosInstance.post(`/drawings/${drawingId}/`, data);
  },
  saveDrawingAs: (data: DrawingFromFE): Promise<string> => {
    return axiosInstance
      .post(`/drawings/`, data)
      .then((response: AxiosResponse): string => {
        const headerLocation = response?.request.getResponseHeader('Location');
        return getIdFromUrl(headerLocation);
      });
  },
  deleteDrawing: (drawingId: string): Promise<void> => {
    return axiosInstance.post(`/drawings/${drawingId}?delete=true`);
  },
  getAreaKeywords: (): Promise<{ data: string[] }> => {
    return axiosInstance.get(`/area-keywords`);
  },
  saveWarningAs: (data: Warning): Promise<string> => {
    return axiosInstance
      .post(`/warnings/`, data)
      .then((response: AxiosResponse): string => {
        const headerLocation = response?.request.getResponseHeader('Location');
        return getIdFromUrl(headerLocation);
      });
  },
  updateWarning: (warningId: string, data: Warning): Promise<void> => {
    return axiosInstance.post(`/warnings/${warningId}/`, data);
  },
  deleteWarning: (warningId: string): Promise<void> => {
    return axiosInstance.post(`/warnings/${warningId}?delete=true`);
  },
});

export const getWarningsApi = (): WarningsApi => getApi<WarningsApi>(API_NAME);

export const createApi = (props: CreateApiProps): WarningsApi => {
  const authInstance = createApiInstance(props);
  const nonAuthInstance = createNonAuthApiInstance(props);
  return getApiRoutes(props.auth ? authInstance : nonAuthInstance);
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Grid2 as Grid,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  ReactHookFormHiddenInput,
  ReactHookFormSelect,
  ReactHookFormTextField,
  isValidGeoJsonCoordinates,
} from '@opengeoweb/form-fields';
import { AlertBanner, CustomIconButton } from '@opengeoweb/shared';
import { DrawMode, getIcon } from '@opengeoweb/webmap-react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { isEqual } from 'lodash';
import { useWarningsTranslation } from '../../utils/i18n';

export const opacityOptions = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];

export interface DrawingToolFormValues {
  objectName: string;
  geoJSON: GeoJSON.FeatureCollection;
  id: string;
}

export interface DrawingToolFormContentsProps {
  isLoading?: boolean;
  dialogError?: string;
  id?: string;
  drawModes: DrawMode[];
  activeDrawModeId: string;
  opacity: number;
  isInEditMode: boolean;
  isDirty: boolean;
  errors: FieldErrors<FieldValues>;
  onChangeDrawMode: (newMode: DrawMode) => void;
  onChangeOpacity: (event: SelectChangeEvent<unknown>) => void;
  onBlurName: (event: React.FocusEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

export const DrawingToolFormContentsNoMemo: React.FC<
  DrawingToolFormContentsProps
> = ({
  isLoading,
  dialogError,
  id,
  drawModes,
  onChangeDrawMode,
  activeDrawModeId,
  isInEditMode,
  isDirty,
  errors,
  onChangeOpacity,
  onBlurName,
  opacity,
  onSubmit,
}) => {
  const { t } = useWarningsTranslation();
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <>
      <Box
        sx={{
          height: 'calc( 100% - 72px )',
          overflow: 'auto',
        }}
      >
        {dialogError && (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <AlertBanner title={dialogError} shouldClose />
          </Box>
        )}
        <Grid
          data-testid="drawingToolForm"
          container
          direction="column"
          spacing={1}
          padding={2}
        >
          <Grid>
            <Typography variant="body2">
              {t('drawing-tool-form-tools')}
            </Typography>
          </Grid>
          <Grid container>
            <Grid>
              {(drawModes as DrawMode[]).map((mode) => (
                <CustomIconButton
                  key={mode.drawModeId}
                  variant="tool"
                  tooltipTitle={mode.title}
                  isSelected={
                    mode.isSelectable && activeDrawModeId === mode.drawModeId
                  }
                  onClick={(): void => onChangeDrawMode(mode)}
                  sx={{ marginRight: 1, marginBottom: 1 }}
                  data-testid={mode.drawModeId}
                  disabled={isLoading}
                  size="medium"
                >
                  {getIcon(mode.selectionType)}
                </CustomIconButton>
              ))}
              {isInEditMode && (
                <Typography
                  component="span"
                  sx={{
                    display: 'inline-block',
                    marginLeft: 2,
                    top: -1,
                    position: 'relative',
                    fontSize: 14,
                  }}
                >
                  {t('drawing-tool-form-exit-draw-mode')}
                </Typography>
              )}
              {!!errors.geoJSON && isDirty && (
                <FormHelperText error variant="filled" sx={{ marginLeft: 0 }}>
                  {errors.geoJSON.message as string}
                </FormHelperText>
              )}
              <ReactHookFormHiddenInput
                name="geoJSON"
                rules={{
                  validate: {
                    coordinatesNotEmpty: (value): boolean | string =>
                      isValidGeoJsonCoordinates(value!) ||
                      t('drawing-tool-form-drawing-required'),
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container size="grow">
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <ReactHookFormSelect
                name="opacity"
                label={t('drawing-tool-form-opacity')}
                rules={{}}
                disabled={isLoading}
                isReadOnly={false}
                data-testid="drawingToolForm-opacity"
                onChange={onChangeOpacity}
                autoFocus
                value={opacity * 100}
              >
                {opacityOptions.map((opacity) => (
                  <MenuItem value={opacity} key={opacity}>
                    {opacity} %
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
          </Grid>
          <Grid>
            <ReactHookFormTextField
              name="objectName"
              label={t('drawing-tool-form-object-name')}
              rules={{ required: true }}
              disabled={isLoading}
              isReadOnly={false}
              onBlur={onBlurName}
              onKeyDown={(event) => event.stopPropagation()}
            />
            <ReactHookFormHiddenInput name="id" />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          padding: '24px 10px 14px 10px',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          backgroundColor: 'geowebColors.background.surfaceApp',
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
          zIndex: 1,
        }}
      >
        <Button
          variant="primary"
          onClick={onSubmit}
          sx={{ width: '100%', textTransform: 'none', fontSize: '12px' }}
          data-testid="saveDrawingFormButton"
          disabled={isLoading || hasErrors}
        >
          {id !== ''
            ? t('drawing-tool-form-update')
            : t('drawing-tool-form-save')}
        </Button>
      </Box>
    </>
  );
};

export const DrawingToolFormContents = React.memo(
  DrawingToolFormContentsNoMemo,
  isEqual,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  Menu,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { CustomIconButton } from '@opengeoweb/shared';
import {
  CoreAppStore,
  mapSelectors,
  linkComponentTypesConstants,
  genericActions,
  genericSelectors,
} from '@opengeoweb/store';
import { LinkOff, Link } from '@opengeoweb/theme';
import { useSelector, useDispatch } from 'react-redux';
import * as workspaceSelectors from '../../../store/workspace/selectors';
import { AppStore } from '../../../store/types';
import { useWorkspaceTranslation } from '../../../utils/i18n';

interface SelectMenuProps {
  panelId: string;
}

const LinkMenuConnect: React.FC<SelectMenuProps> = ({ panelId }) => {
  const dispatch = useDispatch();
  const { t } = useWorkspaceTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const maps = useSelector((store: CoreAppStore) =>
    mapSelectors.getAllMapIds(store),
  );
  const viewsById = useSelector(workspaceSelectors.getViewsById);

  const selectedMapIds = useSelector((store: AppStore) =>
    genericSelectors.getLinkedMaps(store, panelId),
  );

  const viewComponentType = useSelector((store: AppStore) =>
    workspaceSelectors.getViewComponentType(store, panelId),
  );

  const handleLinkViews = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const handleMapSelect = (mapId: string): void => {
    const updatedMapIds = selectedMapIds.includes(mapId)
      ? selectedMapIds.filter((id) => id !== mapId)
      : [...selectedMapIds, mapId];
    dispatch(
      genericActions.updateLinkedMap({
        panelId,
        mapIds: updatedMapIds,
      }),
    );
  };

  return (
    <>
      {linkComponentTypesConstants.supportedComponentTypesForLinking.includes(
        viewComponentType,
      ) && (
        <CustomIconButton
          onClick={handleLinkViews}
          data-testid="icon-button"
          tooltipTitle={`Link ${viewComponentType}`}
          sx={{ marginLeft: 0.5, marginRight: 0.5 }}
        >
          {selectedMapIds.length > 0 ? (
            <Link
              data-testid="link-icon"
              style={{
                fill: anchorEl ? 'white' : undefined,
                backgroundColor: anchorEl ? '#186DFF' : undefined,
              }}
            />
          ) : (
            <LinkOff
              data-testid="link-off-icon"
              style={{
                fill: anchorEl ? 'white' : undefined,
                backgroundColor: anchorEl ? '#186DFF' : undefined,
              }}
            />
          )}
        </CustomIconButton>
      )}
      <Menu
        id="map-select-menu"
        data-testid="map-select-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
      >
        <Typography
          variant="h6"
          style={{ padding: '10px 15px 0', fontWeight: 'bold' }}
        >
          {t('workspace-link')} - {t('workspace-link-location-header')}
        </Typography>
        <List dense>
          {maps.map((mapId) => {
            const labelId = `checkbox-link-label-${mapId}`;

            return (
              <ListItem
                key={mapId}
                onClick={() => handleMapSelect(mapId)}
                disablePadding
                dense
                divider
              >
                <ListItemButton
                  role="checkbox"
                  dense
                  sx={{ margin: 0, padding: '0 16px' }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      checked={selectedMapIds.includes(mapId)}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={viewsById[mapId]?.title}
                    secondary={mapId}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </>
  );
};

export default LinkMenuConnect;

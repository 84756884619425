/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  ListItem,
  ListItemButton,
  Grid2 as Grid,
  Box,
  ListItemProps,
} from '@mui/material';
import {
  ToggleMenu,
  dateUtils,
  CustomTooltip,
  StatusTag,
  MenuItemType,
} from '@opengeoweb/shared';
import { Edit, Options, breakpoints } from '@opengeoweb/theme';
import { ProductStatus } from '@opengeoweb/api';
import {
  Warning,
  PublicWarningStatus,
} from '../../store/publicWarningForm/types';
import { useWarningsTranslation } from '../../utils/i18n';
import {
  getLevelStyle,
  levelOptionsDict,
} from '../PublicWarningsForm/LevelField';
import {
  allPhenomenaDict,
  getPhenomenaIcon,
} from '../PublicWarningsForm/PhenomenonField';
import WarningAvatar from '../PublicWarningsFormDialog/WarningsAvatar';
import useOverflowCheck, { getSortedStatus } from '../../utils/warningUtils';
import { WarningListColumnContent } from './WarningListColumnContent';
import WarningListItemMenu, {
  WarningListItemActions,
} from './WarningListItemMenu';
import {
  isCancelAirmet,
  isCancelSigmet,
  mapProductStatusToWarningStatus,
} from '../../utils/sig-airmet-util';

const renderLevelText = (levelString: string): React.ReactElement | null => {
  if (!levelString) {
    return null;
  }
  const firstLetter = levelString[0];
  const restLetters = levelString.slice(1, levelString.length);

  return (
    <Box>
      <Box component="span" sx={{ textTransform: 'uppercase' }}>
        {firstLetter}
      </Box>
      <Box className="level-suffix" component="span">
        {restLetters}
      </Box>
    </Box>
  );
};

const renderStatusIcon = (
  warning: Warning,
  status: PublicWarningStatus,
): React.ReactNode => {
  const getStatusTag = (
    content: string,
    color: string,
    icon: React.ReactNode = null,
  ): React.ReactNode => (
    <StatusTag
      content={content}
      color={color}
      sx={{
        width: 'inherit',
        height: '16px',
        marginRight: 4,
      }}
      icon={icon}
    />
  );

  if (
    warning.productStatus === ProductStatus.PUBLISHED &&
    warning.type !== 'public' &&
    (isCancelAirmet(warning.sigmetAirmetDetails) ||
      isCancelSigmet(warning.sigmetAirmetDetails))
  ) {
    return getStatusTag(
      `Cnls ${isCancelAirmet(warning.sigmetAirmetDetails) ? warning.sigmetAirmetDetails.cancelsAirmetSequenceId : warning.sigmetAirmetDetails.cancelsSigmetSequenceId}`,
      'red',
    );
  }

  if (
    warning.productStatus === ProductStatus.CANCELLED &&
    warning.type !== 'public'
  ) {
    return getStatusTag('Cnld', 'red');
  }

  switch (status) {
    case PublicWarningStatus.TODO:
      return null;
    case PublicWarningStatus.DRAFT:
      return (
        <Box
          sx={{
            height: '16px',
            padding: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'geowebColors.iconButtons.flat.default.color',
            marginRight: 2,
          }}
        >
          <Edit data-testid="EditPencilIcon" />
        </Box>
      );
    case PublicWarningStatus.DRAFT_PUBLISHED:
      return getStatusTag(
        'Pub',
        'green',
        <Edit
          sx={{
            width: 20,
            height: 20,
            marginTop: '3px',
          }}
        />,
      );
    case PublicWarningStatus.PUBLISHED:
      return getStatusTag('Pub', 'green');
    case PublicWarningStatus.WITHDRAWN:
      return getStatusTag('Wdn', 'red');
    case PublicWarningStatus.EXPIRED:
    default:
      return getStatusTag('Exp', 'grey');
  }
};

export interface WarningListItemProps
  extends ListItemProps,
    WarningListItemActions {
  warning: Warning;
  highlightedItem?: boolean;
  activeWarningId: string;
  onSelectWarning?: (warning: Warning) => void;
}

const WarningListItem: React.FC<WarningListItemProps> = ({
  warning,
  activeWarningId,
  highlightedItem,
  onSelectWarning = (): void => {},
  onEditWarning = (): void => {},
  onDeleteWarning = (): void => {},
  onDeleteReviewWarning = (): void => {},
  onExpireWarning = (): void => {},
  onWithdrawWarning = (): void => {},
  onActionAviationWarning = (): void => {},
  ...props
}) => {
  const { t } = useWarningsTranslation();

  const phenomenonDetails = allPhenomenaDict[warning.warningDetail.phenomenon];
  const objectNameText = warning.warningDetail.areas?.[0]?.objectName || '-';
  const objectHoverText =
    warning.type !== 'public'
      ? warning.sigmetAirmetDetails.firName
      : objectNameText;
  const { ref: objectNameRef } = useOverflowCheck(objectNameText);

  const phenomenonText = phenomenonDetails?.translationKey
    ? t(phenomenonDetails.translationKey)
    : '-';
  const { ref: phenomenonRef, isOverflowing: isPhenomenonOverflowing } =
    useOverflowCheck(phenomenonText);

  const levelDetails = levelOptionsDict[warning.warningDetail.level];

  const status =
    warning.status || mapProductStatusToWarningStatus(warning.productStatus);

  const levelTranslation = levelDetails?.translationKey;

  const defaultLevelText = React.useMemo(() => {
    if (!levelTranslation) {
      return '-';
    }
    return t(levelTranslation);
  }, [levelTranslation, t]);

  const backgroundColor = React.useMemo(() => {
    if (highlightedItem) {
      return 'geowebColors.cards.cardContainerMouseOver';
    }
    if (
      status === PublicWarningStatus.EXPIRED ||
      status === PublicWarningStatus.WITHDRAWN
    ) {
      return 'geowebColors.cards.cardContainerDisabled';
    }
    return 'geowebColors.cards.cardContainer';
  }, [highlightedItem, status]);

  return (
    <ListItem
      key={warning.id}
      sx={{
        marginBottom: 0.25,
        minHeight: '32px',
        padding: '0 0',
        backgroundColor,
        borderColor:
          status === PublicWarningStatus.EXPIRED ||
          status === PublicWarningStatus.WITHDRAWN
            ? 'geowebColors.cards.cardContainerDisabledBorder'
            : 'geowebColors.cards.cardContainerBorder',
        borderRadius: 1,
        borderWidth: activeWarningId === warning.id ? '1px' : '1px',
        borderStyle: 'solid',
      }}
      secondaryAction={
        <ToggleMenu
          menuTitle={t('warning-list-button-options')}
          tooltipTitle={t('warning-list-button-options')}
          menuPosition="bottom"
          aria-label={t('warning-list-button-options')}
          menuItems={
            WarningListItemMenu({
              warning,
              t,
              onEditWarning,
              onDeleteReviewWarning,
              onDeleteWarning,
              onExpireWarning,
              onWithdrawWarning,
              onActionAviationWarning,
            }) as unknown as MenuItemType[]
          }
          buttonIcon={<Options />}
        />
      }
      data-testid={`warning-${getSortedStatus(status)}`}
      {...props}
    >
      <ListItemButton
        sx={{
          height: '100%',
          padding: 1,
          '.warning-list': {
            justifyContent: 'left',
          },
          [`@container publicWarningList (min-width: ${breakpoints.desktop}px)`]:
            {
              '.warning-list': {
                justifyContent: 'space-between',
              },
            },
        }}
        onClick={() => onSelectWarning(warning)}
        aria-label={`warning item for ${warning.id}`}
        selected={activeWarningId === warning.id}
      >
        <Grid
          className="warning-list"
          container
          rowSpacing={0}
          columnSpacing={0}
          alignItems="center"
          size="grow"
        >
          <WarningListColumnContent
            title={t('warning-list-header-incident')}
            status={status}
          >
            {warning.productStatus !== 'DRAFT'
              ? warning.warningDetail.incident
              : ''}
          </WarningListColumnContent>
          <WarningListColumnContent
            title={`${t('warning-list-header-last-update')} UTC`}
            status={status}
            width={142}
          >
            {warning.lastUpdatedTime &&
              dateUtils.dateToString(
                dateUtils.utc(warning.lastUpdatedTime),
                dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH,
              )}
          </WarningListColumnContent>
          <WarningListColumnContent
            title={`${t('warning-list-header-start-time')} UTC`}
            status={status}
            width={142}
          >
            {warning.warningDetail.validFrom &&
              dateUtils.dateToString(
                dateUtils.utc(warning.warningDetail.validFrom),
                dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH,
              )}
          </WarningListColumnContent>
          <WarningListColumnContent
            title={`${t('warning-list-header-end-time')} UTC`}
            status={status}
            width={142}
          >
            {warning.warningDetail.validUntil &&
              dateUtils.dateToString(
                dateUtils.utc(warning.warningDetail.validUntil),
                dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH,
              )}
          </WarningListColumnContent>
          <WarningListColumnContent
            title={t('warning-list-header-domain')}
            status={status}
          >
            {warning.warningDetail.domain}
          </WarningListColumnContent>
          <WarningListColumnContent
            title={t('warning-list-header-phenomenon')}
            status={status}
            width={140}
            sx={{ position: 'relative' }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ flexGrow: 1 }}
            >
              <Box
                display="inline-block"
                component="div"
                ref={phenomenonRef}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: 90,
                  whiteSpace: 'nowrap',
                }}
              >
                {isPhenomenonOverflowing ? (
                  <CustomTooltip
                    title={phenomenonText}
                    placement="top"
                    sx={{ zIndex: 1000 }}
                  >
                    <span>{phenomenonText}</span>
                  </CustomTooltip>
                ) : (
                  <span>{phenomenonText}</span>
                )}
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  right: 16,
                  bottom: -6,
                }}
              >
                {getPhenomenaIcon(phenomenonDetails?.key!)}
              </Box>
            </Box>
          </WarningListColumnContent>

          <WarningListColumnContent
            title={t('warning-list-header-level')}
            status={status}
            sx={{
              [`@container publicWarningList (min-width: ${breakpoints.desktop}px)`]:
                {
                  width: 56,
                },
            }}
          >
            <Box
              component="span"
              sx={{
                ...getLevelStyle(levelDetails),
                fontWeight: 'bold',
                padding: 0,
                marginRight: 2,
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                [`@container publicWarningList (min-width: ${breakpoints.desktop}px)`]:
                  {
                    marginRight: 0,
                    height: '32px',
                    '.level-suffix': {
                      display: 'none',
                    },
                  },
              }}
            >
              {((): React.ReactNode => {
                if (warning.warningDetail?.level && !levelTranslation) {
                  // Show sigmet-airmet level
                  return warning.warningDetail.level;
                }
                return renderLevelText(defaultLevelText);
              })()}
            </Box>
          </WarningListColumnContent>

          <WarningListColumnContent title={t('areas')} status={status}>
            <Box
              display="inline-block"
              component="div"
              ref={objectNameRef}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 70,
                whiteSpace: 'nowrap',
              }}
            >
              <CustomTooltip
                title={objectHoverText}
                placement="top"
                sx={{ zIndex: 1000 }}
              >
                <span>{objectNameText}</span>
              </CustomTooltip>
            </Box>
          </WarningListColumnContent>

          <WarningListColumnContent
            title={t('warning-list-header-status')}
            status={status}
            width={96}
          >
            <Box sx={{ position: 'relative' }}>
              {renderStatusIcon(warning, status)}
              {warning.editor && (
                <Box
                  sx={{
                    width: '16px',
                    position: 'absolute',
                    top: '1px',
                    right: '4px',
                  }}
                >
                  <WarningAvatar editor={warning.editor} />
                </Box>
              )}
            </Box>
          </WarningListColumnContent>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
};

export default WarningListItem;

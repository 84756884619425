/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { AlertColor } from '@mui/material';
import { Success, Exclamation, Info } from '@opengeoweb/theme';

interface AlertIconProps {
  severity?: AlertColor;
  dataTestId?: string;
}

const AlertIcon: React.FC<AlertIconProps> = ({
  severity = 'error',
  dataTestId = 'alert-icon',
}) => {
  switch (severity) {
    case 'info':
      return (
        <Info
          sx={{ color: 'geowebColors.functional.notification' }}
          data-testid={dataTestId}
        />
      );
    case 'success':
      return (
        <Success
          sx={{ color: 'geowebColors.functional.success' }}
          data-testid={dataTestId}
        />
      );
    case 'warning':
      return (
        <Exclamation
          sx={{ color: 'geowebColors.functional.warning' }}
          data-testid={dataTestId}
        />
      );
    case 'error':
    default:
      return (
        <Exclamation
          sx={{ color: 'geowebColors.functional.error' }}
          data-testid={dataTestId}
        />
      );
  }
};

export default AlertIcon;

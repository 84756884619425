/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  CoreAppStore,
  layerSelectors,
  serviceSelectors,
  uiTypes,
  useSetupDialog,
} from '@opengeoweb/store';

import { LayerInfoDialog } from '@opengeoweb/webmap-react';
import { useSelector } from 'react-redux';

export const LayerInfoDialogConnect: React.FC = () => {
  const layerInfo = useSelector((store: CoreAppStore) =>
    layerSelectors.getActiveLayerInfo(store),
  );

  const layer = useSelector((store: CoreAppStore) =>
    serviceSelectors.getLayerFromService(
      store,
      layerInfo?.serviceUrl || '',
      layerInfo?.layerName!,
    ),
  );

  const currentService = useSelector((store: CoreAppStore) =>
    serviceSelectors.getServiceByUrl(store, layerInfo?.serviceUrl || ''),
  );

  const { dialogOrder, onCloseDialog, setDialogOrder, uiSource, isDialogOpen } =
    useSetupDialog(uiTypes.DialogTypes.LayerInfo);
  if (!layer) {
    return null;
  }
  return (
    <LayerInfoDialog
      isOpen={isDialogOpen}
      onClose={onCloseDialog}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      source={uiSource}
      layer={layer}
      serviceName={currentService?.name!}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { ChevronDown } from '@opengeoweb/theme';

export interface CustomAccordionProps extends Omit<AccordionProps, 'title'> {
  title?: string | React.ReactNode;
  title2?: string;
  expandedElevation?: number;
  collapsedElevation?: number;
  transitionDuration?: number;
  detailsSx?: SxProps<Theme>;
  CustomExpandIcon?: React.ElementType;
  amountOfItems?: number;
}

export const CustomAccordion: React.FC<CustomAccordionProps> = ({
  title,
  title2,
  defaultExpanded = true,
  disableGutters = true,
  expandedElevation = 3,
  collapsedElevation = 0,
  transitionDuration = 150,
  detailsSx,
  CustomExpandIcon,
  children,
  amountOfItems,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const theme = useTheme();
  const iconColor = theme.palette.geowebColors.buttons.icon.default.color;
  return (
    <Accordion
      {...props}
      defaultExpanded={defaultExpanded}
      elevation={expanded ? expandedElevation : collapsedElevation}
      onChange={(_event, expanded): void => setExpanded(expanded)}
      disableGutters={disableGutters}
      slotProps={{ transition: { timeout: transitionDuration } }}
    >
      <AccordionSummary
        component="div"
        expandIcon={
          CustomExpandIcon ? (
            <CustomExpandIcon style={{ fill: `${iconColor}` }} />
          ) : (
            <ChevronDown style={{ fill: `${iconColor}` }} />
          )
        }
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              {title}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '25px',
              }}
            >
              {title2}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '10px',
            }}
          >
            {amountOfItems}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={detailsSx}>{children}</AccordionDetails>
    </Accordion>
  );
};

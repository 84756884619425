/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useAuthenticationContext } from '@opengeoweb/authentication';
import { AviationProduct, FormMode } from '@opengeoweb/sigmet-airmet';
import { MapPreset, uiActions, uiTypes } from '@opengeoweb/store';
import { PublicWarningsForm } from './PublicWarningsForm';
import {
  getPublicWarningFormState,
  getPublicWarningFormError,
  getPublicWarningObject,
  getPublicWarning,
} from '../../store/publicWarningForm/selectors';
import { publicWarningFormActions } from '../../store/publicWarningForm/reducer';
import {
  PublicWarningDetail,
  PublicWarningStatus,
  WarningType,
} from '../../store/publicWarningForm/types';

export interface PublicWarningsFormConnectProps {
  editor?: string;
  selectedAviationProduct?: AviationProduct;
  warningType?: WarningType;
  mode?: FormMode;
  defaultMapPreset?: MapPreset;
  onFormSubmit?: () => void;
}

export const PublicWarningsFormConnect: React.FC<
  PublicWarningsFormConnectProps
> = ({
  editor,
  defaultMapPreset,
  selectedAviationProduct,
  warningType = 'public',
  mode,
  onFormSubmit,
}: PublicWarningsFormConnectProps) => {
  const publicWarning = useSelector(getPublicWarning);
  const publicWarningObject = useSelector(getPublicWarningObject);
  const publicWarningFormAction = useSelector(getPublicWarningFormState);
  const publicWarningFormError = useSelector(getPublicWarningFormError);

  const { auth } = useAuthenticationContext();
  const isEditor = editor === auth?.username || false;

  const dispatch = useDispatch();

  const publishForm = (newFormValues: PublicWarningDetail): void => {
    if (warningType === 'public') {
      dispatch(
        publicWarningFormActions.publishWarning({
          publicWarning: {
            warningDetail: newFormValues,
            status: PublicWarningStatus.PUBLISHED,
            type: warningType,
          },
          refetchWarnings: onFormSubmit,
        }),
      );
    }
  };

  const clearForm = (): void => {
    dispatch(publicWarningFormActions.removeAllAreas());
  };

  const saveForm = (newFormValues: PublicWarningDetail): void => {
    const status = newFormValues.linked_to_id
      ? PublicWarningStatus.DRAFT_PUBLISHED
      : PublicWarningStatus.DRAFT;

    if (warningType === 'public') {
      dispatch(
        publicWarningFormActions.saveWarning({
          publicWarning: {
            warningDetail: newFormValues,
            status,
            editor: auth?.username,
            type: warningType,
          },
          refetchWarnings: onFormSubmit,
        }),
      );
    }
  };

  const onFormDirty = (isFormDirty: boolean): void => {
    dispatch(
      publicWarningFormActions.setFormDirty({
        isFormDirty,
      }),
    );
  };

  const getIsReadOnly = (): boolean => {
    // public warning related
    if (warningType === 'public') {
      if (publicWarningFormAction === 'readonly') {
        return true;
      }
      if (publicWarning?.status === 'TODO') {
        return false;
      }
      // Readonly in case you are not creating a new warning and you are not set as the editor
      return !isEditor && publicWarning?.status !== undefined;
    }
    // aviation related
    if (selectedAviationProduct?.status === 'DRAFT') {
      return false;
    }
    if (publicWarningFormAction === 'edit') {
      return false;
    }
    if (mode === 'new') {
      return false;
    }
    return true;
  };

  const isReadOnly = getIsReadOnly();
  const formMode = publicWarningFormAction === 'edit' ? 'edit' : mode;

  const onAddObject = (): void => {
    dispatch(
      uiActions.setToggleOpenDialog({
        type: uiTypes.DialogTypes.AreaObjectLoader,
        setOpen: true,
      }),
    );
  };

  return (
    <PublicWarningsForm
      object={publicWarningObject}
      publicWarningDetails={publicWarning?.warningDetail}
      onPublishForm={publishForm}
      onSaveForm={saveForm}
      onClearForm={clearForm}
      formAction={publicWarningFormAction}
      isReadOnly={isReadOnly}
      error={publicWarningFormError}
      onFormDirty={onFormDirty}
      defaultMapPreset={defaultMapPreset}
      onAddObject={onAddObject}
      selectedAviationProduct={selectedAviationProduct}
      warningType={warningType}
      mode={formMode}
    />
  );
};

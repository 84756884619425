/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* eslint-disable max-classes-per-file */

import { dateUtils } from '@opengeoweb/shared';
import { debugLogger, DebugType, isDefined } from './WMJSTools';
import { CustomDate } from './WMTimeTypes';
import { TimeInterval } from './types';

/** ************************************************ */
/* Object to store a time duration / time interval */
/** ************************************************ */
export class DateInterval implements TimeInterval {
  public year: number;

  public month: number;

  public day: number;

  public hour: number;

  public minute: number;

  public second: number;

  public isRegularInterval: boolean;

  constructor(
    year: string,
    month: string,
    day: string,
    hour: string,
    minute: string,
    second: string,
  ) {
    this.year = parseInt(year, 10);
    this.month = parseInt(month, 10);
    this.day = parseInt(day, 10);
    this.hour = parseInt(hour, 10);
    this.minute = parseInt(minute, 10);
    this.second = parseInt(second, 10);
    this.isRegularInterval = false;
    if (this.month === 0 && this.year === 0) {
      this.isRegularInterval = true;
    }
    this.getTime = this.getTime.bind(this);
    this.toISO8601 = this.toISO8601.bind(this);
  }

  getTime(): number {
    let timeres = 0;
    /* Months and years are unequally distributed in time
       So get time is not possible */
    if (this.month !== 0) {
      throw new Error('month !== 0');
    }
    if (this.year !== 0) {
      throw new Error('year !== 0');
    }
    timeres += this.day * 60 * 60 * 24;
    timeres += this.hour * 60 * 60;
    timeres += this.minute * 60;
    timeres += this.second;
    timeres *= 1000;
    return timeres;
  }

  toISO8601(): string {
    let isoTime = 'P';
    if (this.year !== 0) {
      isoTime += `${this.year}Y`;
    }
    if (this.month !== 0) {
      isoTime += `${this.month}M`;
    }
    if (this.day !== 0) {
      isoTime += `${this.day}D`;
    }
    if (this.hour !== 0 && this.minute !== 0 && this.second !== 0) {
      isoTime += 'T';
    }
    if (this.hour !== 0) {
      isoTime += `${this.hour}H`;
    }
    if (this.minute !== 0) {
      isoTime += `${this.minute}M`;
    }
    if (this.second !== 0) {
      isoTime += `${this.second}S`;
    }
    return isoTime;
  }
}

export const isValidISOTime = (isotime: string): boolean => {
  const minimalFormat = 'ccyy-mm-ddThh:mmZ';
  if (!isotime || isotime.length < minimalFormat.length) {
    return false;
  }
  const [date, time] = isotime.split('T');
  if (date === undefined || time === undefined) {
    return false;
  }
  return true;
};

export const parseTimeValue = (value: string): number =>
  parseInt(
    value === undefined || value === null || !value.length ? '00' : value,
    10,
  );

export const getTimeValues = (isotime: string): number[] =>
  isotime.split(/\D+/).map(parseTimeValue);

/** ************************************************* */
/* Parses ISO8601 times to a Javascript Date Object */
/** ************************************************* */
export const parseISO8601DateToDate = (
  unvalidatedIsotime: string,
): CustomDate => {
  /*
  The following functions are added to the standard Date object:
    - add(dateInterval) adds a DateInterval time to this time
    - substract(dateInterval) substracts a DateInterval time to this time
    - toISO8601() returns the date object as iso8601 string
    - clone() creates a copy of this object
  */

  /** Timeformats according to:
   * https://portal.ogc.org/files/?artifact_id=14416 / 06-042_OpenGIS_Web_Map_Service_WMS_Implementation_Specification.pdf,
   * chapter D2.1 are supported
   */
  const makeISO8601StringFromFlexibleWMSDateString = (
    isoString: string,
  ): string => {
    // Datestring starting with a - token are not supported.
    if (!isoString || isoString.startsWith('-')) {
      return isoString;
    }
    const trimmed = isoString.trim();
    if (trimmed.length === 4) {
      return `${trimmed}-01-01T00:00:00Z`; // YYYY
    }
    if (trimmed.length === 7) {
      return `${trimmed}-01T00:00:00Z`; // YYYY-MM
    }
    if (trimmed.length === 10) {
      return `${trimmed}T00:00:00Z`; // YYYY-MM-DD
    }
    if (trimmed.length === 14) {
      return `${trimmed.slice(0, -1)}:00:00Z`; // YYYY-MM-DDTHHZ
    }
    return trimmed;
  };

  const isotime =
    makeISO8601StringFromFlexibleWMSDateString(unvalidatedIsotime);

  if (!isValidISOTime(isotime)) {
    debugLogger(DebugType.Warning, 'No date given to parseISO8601DateToDate');
    return undefined!;
  }

  const [year, month, day, hours, minutes, seconds] = getTimeValues(isotime);

  const date: CustomDate = new Date(
    Date.UTC(year, month - 1, day, hours, minutes, seconds),
  ) as CustomDate;

  date.add = (dateInterval): void => {
    if (!dateInterval) {
      return;
    }
    if (dateInterval.isRegularInterval === false) {
      if (dateInterval.year !== 0) {
        date.setUTCFullYear(date.getUTCFullYear() + dateInterval.year);
      }
      if (dateInterval.month !== 0) {
        date.setUTCMonth(date.getUTCMonth() + dateInterval.month);
      }
      if (dateInterval.day !== 0) {
        date.setUTCDate(date.getUTCDate() + dateInterval.day);
      }
      if (dateInterval.hour !== 0) {
        date.setUTCHours(date.getUTCHours() + dateInterval.hour);
      }
      if (dateInterval.minute !== 0) {
        date.setUTCMinutes(date.getUTCMinutes() + dateInterval.minute);
      }
      if (dateInterval.second !== 0) {
        date.setUTCSeconds(date.getUTCSeconds() + dateInterval.second);
      }
    } else {
      date.setTime(date.getTime() + dateInterval.getTime());
    }
  };

  date.substract = (dateInterval): void => {
    if (!dateInterval) {
      return;
    }
    if (dateInterval.isRegularInterval === false) {
      if (dateInterval.year !== 0) {
        date.setUTCFullYear(date.getUTCFullYear() - dateInterval.year);
      }
      if (dateInterval.month !== 0) {
        date.setUTCMonth(date.getUTCMonth() - dateInterval.month);
      }
      if (dateInterval.day !== 0) {
        date.setUTCDate(date.getUTCDate() - dateInterval.day);
      }
      if (dateInterval.hour !== 0) {
        date.setUTCHours(date.getUTCHours() - dateInterval.hour);
      }
      if (dateInterval.minute !== 0) {
        date.setUTCMinutes(date.getUTCMinutes() - dateInterval.minute);
      }
      if (dateInterval.second !== 0) {
        date.setUTCSeconds(date.getUTCSeconds() - dateInterval.second);
      }
    } else {
      date.setTime(date.getTime() - dateInterval.getTime());
    }
  };

  date.addMultipleTimes = (dateInterval, numberOfSteps: number): void => {
    if (!dateInterval) {
      return;
    }
    if (dateInterval.isRegularInterval === false) {
      if (dateInterval.year !== 0) {
        date.setUTCFullYear(
          date.getUTCFullYear() + dateInterval.year * numberOfSteps,
        );
      }
      if (dateInterval.month !== 0) {
        date.setUTCMonth(
          date.getUTCMonth() + dateInterval.month * numberOfSteps,
        );
      }
      if (dateInterval.day !== 0) {
        date.setUTCDate(date.getUTCDate() + dateInterval.day * numberOfSteps);
      }
      if (dateInterval.hour !== 0) {
        date.setUTCHours(
          date.getUTCHours() + dateInterval.hour * numberOfSteps,
        );
      }
      if (dateInterval.minute !== 0) {
        date.setUTCMinutes(
          date.getUTCMinutes() + dateInterval.minute * numberOfSteps,
        );
      }
      if (dateInterval.second !== 0) {
        date.setUTCSeconds(
          date.getUTCSeconds() + dateInterval.second * numberOfSteps,
        );
      }
    } else {
      date.setTime(date.getTime() + dateInterval.getTime() * numberOfSteps);
    }
  };

  date.substractMultipleTimes = (dateInterval, numberOfSteps: number): void => {
    if (!dateInterval) {
      return;
    }

    if (dateInterval.isRegularInterval === false) {
      if (dateInterval.year !== 0) {
        date.setUTCFullYear(
          date.getUTCFullYear() - dateInterval.year * numberOfSteps,
        );
      }
      if (dateInterval.month !== 0) {
        date.setUTCMonth(
          date.getUTCMonth() - dateInterval.month * numberOfSteps,
        );
      }
      if (dateInterval.day !== 0) {
        date.setUTCDate(date.getUTCDate() - dateInterval.day * numberOfSteps);
      }
      if (dateInterval.hour !== 0) {
        date.setUTCHours(
          date.getUTCHours() - dateInterval.hour * numberOfSteps,
        );
      }
      if (dateInterval.minute !== 0) {
        date.setUTCMinutes(
          date.getUTCMinutes() - dateInterval.minute * numberOfSteps,
        );
      }
      if (dateInterval.second !== 0) {
        date.setUTCSeconds(
          date.getUTCSeconds() - dateInterval.second * numberOfSteps,
        );
      }
    } else {
      date.setTime(date.getTime() - dateInterval.getTime() * numberOfSteps);
    }
  };

  date.toISO8601 = (): string => {
    function prf(input: number, width: number): string {
      // print decimal with fixed length (preceding zero's)
      let string = `${input}`;
      const len = width - string.length;
      let j;
      let zeros = '';
      for (j = 0; j < len; j += 1) {
        zeros += `0${zeros}`;
      }

      string = zeros + string;
      return string;
    }
    const iso = `${prf(date.getUTCFullYear(), 4)}-${prf(
      date.getUTCMonth() + 1,
      2,
    )}-${prf(date.getUTCDate(), 2)}T${prf(date.getUTCHours(), 2)}:${prf(
      date.getUTCMinutes(),
      2,
    )}:${prf(date.getUTCSeconds(), 2)}Z`;
    return iso;
  };

  date.clone = (): CustomDate => parseISO8601DateToDate(date.toISO8601());

  return date;
};

/** ****************************************************** */
/* Parses ISO8601 time duration to a DateInterval Object */
/** ****************************************************** */

export const parseISO8601IntervalToDateInterval = (
  _isotime: string,
): DateInterval => {
  if (!_isotime) {
    return undefined!;
  }
  const isotime = _isotime.trim();
  if (isotime.charAt(0) !== 'P') {
    return undefined!;
  }
  const splittedOnT = isotime.split('T');
  let years = '0';
  let months = '0';
  let days = '0';
  let hours = '0';
  let minutes = '0';
  let seconds = '0';
  const YYYYMMDDPart = splittedOnT[0].split('P')[1];

  // Parse the left part
  if (YYYYMMDDPart) {
    const yearIndex = YYYYMMDDPart.indexOf('Y');
    const monthIndex = YYYYMMDDPart.indexOf('M');
    const dayIndex = YYYYMMDDPart.indexOf('D');
    if (yearIndex !== -1) {
      years = YYYYMMDDPart.substring(0, yearIndex);
    }
    if (monthIndex !== -1) {
      months = YYYYMMDDPart.substring(yearIndex + 1, monthIndex);
    }
    if (dayIndex !== -1) {
      let start = yearIndex;
      if (monthIndex !== -1) {
        start = monthIndex;
      }
      days = YYYYMMDDPart.substring(start + 1, dayIndex);
    }
  }
  // parse the right part

  if (splittedOnT.length > 0) {
    if (isDefined(splittedOnT[1])) {
      const HHMMSSPart = splittedOnT[1];
      if (HHMMSSPart) {
        const hourIndex = HHMMSSPart.indexOf('H');
        const minuteIndex = HHMMSSPart.indexOf('M');
        const secondIndex = HHMMSSPart.indexOf('S');
        if (hourIndex !== -1) {
          hours = HHMMSSPart.substring(0, hourIndex);
        }
        if (minuteIndex !== -1) {
          minutes = HHMMSSPart.substring(hourIndex + 1, minuteIndex);
        }
        if (secondIndex !== -1) {
          let start = hourIndex;
          if (minuteIndex !== -1) {
            start = minuteIndex;
          }
          seconds = HHMMSSPart.substring(start + 1, secondIndex);
        }
      }
    }
  }

  // Assemble the dateInterval object
  const dateInterval = new DateInterval(
    years,
    months,
    days,
    hours,
    minutes,
    seconds,
  );
  return dateInterval;
};

export function isTimeOnIntervalStep(
  time: Date,
  intervalStart: Date,
  interval: DateInterval,
): boolean {
  if (!interval.isRegularInterval || interval.getTime() === 0) {
    return true;
  }
  return (time.getTime() - intervalStart.getTime()) % interval.getTime() === 0;
}

/** ******************************************************* */
/* Calculates the number of time steps with this interval */
/** ******************************************************* */
export function getNumberOfTimeSteps(
  starttime: CustomDate,
  stoptime: CustomDate,
  interval: DateInterval,
): number {
  if (!starttime || !stoptime || !interval) {
    return undefined!;
  }
  let steps = 0;
  if (interval.month !== 0 || interval.year !== 0) {
    // In case of unequally distributed time steps, where the number of days leties within a month:
    const testtime = starttime.clone();
    const timestopms = stoptime.getTime();
    while (testtime.getTime() < timestopms) {
      testtime.add(interval);
      steps += 1;
    }
    steps += 1;
    return steps;
  }
  // In case of equally distributed time steps

  steps =
    parseInt(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (stoptime.getTime() - starttime.getTime()) / interval.getTime() + 0.5,
      10,
    ) + 1;

  return steps;
}

// Takes "1999-01-01T00:00:00Z/2009-12-01T00:00:00Z/PT60S" as input
export class ParseISOTimeRangeDuration {
  private initialized = false;

  public startTime!: CustomDate;

  public stopTime!: CustomDate;

  public timeInterval!: DateInterval;

  public timeSteps!: number;

  constructor(isoTimeRangeDuration: string) {
    const times = isoTimeRangeDuration.split('/');
    // Add safety checks for undefined elements
    if (times[2] === undefined) {
      times[2] = 'PT1M';
    }

    if (times[1] === undefined) {
      // eslint-disable-next-line prefer-destructuring
      times[1] = times[0];
      times[2] = 'PT1M';
    }

    // Convert the dates
    this.startTime = parseISO8601DateToDate(times[0]);
    this.stopTime = parseISO8601DateToDate(times[1]);
    if (!this.startTime || !this.stopTime) {
      debugLogger(
        DebugType.Warning,
        `Unable to initialize ParseISOTimeRangeDuration with value [${isoTimeRangeDuration}]`,
      );
      return;
    }

    /* Try to get the time interval. If not available default to 1 minute */
    const timeIntervalString = times[2] || 'PT1M';

    this.timeInterval = parseISO8601IntervalToDateInterval(timeIntervalString);

    if (!this.timeInterval) {
      debugLogger(
        DebugType.Warning,
        `Unable to initialize timeInterval using parseISO8601IntervalToDateInterval with value [${timeIntervalString}]`,
      );
      return;
    }
    // Calculate the number if steps
    this.timeSteps = getNumberOfTimeSteps(
      this.startTime,
      this.stopTime,
      this.timeInterval,
    );

    this.getTimeSteps = this.getTimeSteps.bind(this);
    this.getDateAtTimeStep = this.getDateAtTimeStep.bind(this);
    this.getTimeStepFromISODate = this.getTimeStepFromISODate.bind(this);
    this.getTimeStepFromDate = this.getTimeStepFromDate.bind(this);
    this.getTimeStepFromDateWithinRange =
      this.getTimeStepFromDateWithinRange.bind(this);
    this.getTimeStepFromDateClipped =
      this.getTimeStepFromDateClipped.bind(this);

    /* Indicate that this object is properly constructed */
    this.initialized = true;
  }

  getTimeSteps(): number {
    return this.timeSteps;
  }

  getDateAtTimeStep(currentStep: number): CustomDate {
    if (this.initialized === false) {
      return null!;
    }
    if (!this.timeInterval) {
      debugLogger(
        DebugType.Warning,
        `getDateAtTimeStep fails because timeinterval is not available`,
      );
      return null!;
    }
    if (this.timeInterval.isRegularInterval === false) {
      const temptime = this.startTime.clone();
      temptime.addMultipleTimes(this.timeInterval, currentStep);

      return temptime;
    }
    const temptime = this.startTime.clone();
    let dateIntervalTime = this.timeInterval.getTime();
    dateIntervalTime *= currentStep;
    temptime.setTime(temptime.getTime() + dateIntervalTime);
    return temptime;
  }

  getTimeStepFromISODate(
    currentISODate: string,
    throwIfOutsideRange: boolean,
  ): number {
    let currentDate: CustomDate | null = null;
    try {
      currentDate = parseISO8601DateToDate(currentISODate);
    } catch (e) {
      throw new Error(`The date ${currentISODate} is not a valid date`);
    }
    return this.getTimeStepFromDate(currentDate, throwIfOutsideRange);
  }

  /* Calculates the time step at the given date  */
  getTimeStepFromDate(
    wantedDate: CustomDate,
    throwIfOutsideRange = false,
  ): number {
    const wantedTime = wantedDate.getTime();

    if (wantedTime < this.startTime.getTime()) {
      if (throwIfOutsideRange === true) {
        throw new Error('0');
      }
      return 0;
    }

    const myStopTime = this.stopTime.clone();
    myStopTime.add(this.timeInterval);

    if (wantedTime >= myStopTime.getTime()) {
      if (throwIfOutsideRange === true) {
        throw new Error(`${this.timeSteps - 1}`);
      }
      return this.timeSteps - 1;
    }
    if (wantedTime > this.stopTime.getTime()) {
      return this.timeSteps - 1;
    }
    let timeStep = 0;
    if (this.timeInterval.isRegularInterval === false) {
      const next = this.startTime.clone();
      for (
        let currentTimeStepIndex = 0;
        currentTimeStepIndex < this.timeSteps;
        currentTimeStepIndex += 1
      ) {
        const currentTime = next.getTime();
        next.add(this.timeInterval);

        const nextTime = next.getTime();
        if (
          dateUtils.isWithinInterval(wantedTime, {
            start: currentTime,
            end: nextTime,
          })
        ) {
          const timeInTheMiddle = (currentTime + nextTime) / 2;
          if (wantedTime > timeInTheMiddle) {
            return currentTimeStepIndex + 1;
          }
          return currentTimeStepIndex;
        }
      }
      throw new Error(`Date ${wantedDate.toISO8601()} not found!`);
    } else {
      timeStep =
        (wantedDate.getTime() - this.startTime.getTime()) /
        this.timeInterval.getTime();

      // round 4.5 down to 4 by using this hack
      timeStep = -Math.round(-timeStep);
      return timeStep;
    }
  }

  /* Returns the timestep at the currentDate, if currentdate is outside the range,
  a exception is thrown.  */
  getTimeStepFromDateWithinRange(currentDate: CustomDate): number {
    return this.getTimeStepFromDate(currentDate, true);
  }

  /* Returns the timestep at the currentDate, if currentdate is outside the range,
  a minimum or maximum step which lies within the time range is returned.  */
  getTimeStepFromDateClipped(currentDate: CustomDate): number {
    return this.getTimeStepFromDate(currentDate, false);
  }
}

export function calculateTimeIntervalFromList(
  dateStrings: string[],
): DateInterval {
  if (dateStrings.length < 2) {
    throw new Error('Need at least two dates to calculate an interval.');
  }

  const firstDate = parseISO8601DateToDate(dateStrings[0]);
  const secondDate = parseISO8601DateToDate(dateStrings[1]);

  const years = dateUtils.differenceInYears(secondDate, firstDate);
  const months = dateUtils.differenceInMonths(secondDate, firstDate) % 12;
  const days = dateUtils.differenceInDays(secondDate, firstDate) % 30;
  const hours = dateUtils.differenceInHours(secondDate, firstDate) % 24;
  const minutes = dateUtils.differenceInMinutes(secondDate, firstDate) % 60;
  const seconds = dateUtils.differenceInSeconds(secondDate, firstDate) % 60;

  return new DateInterval(
    years.toString(),
    months.toString(),
    days.toString(),
    hours.toString(),
    minutes.toString(),
    seconds.toString(),
  );
}

/* Example:
function init(){
  let isodate="1999-01-01T00:00:00Z/2009-12-01T00:00:00Z/PT60S";
  //Split on '/'
  let times=isodate.split('/');
  //Some safety checks
  if(times[1]==undefined){times[1]=times[0];times[2]='PT1M';}
  //Convert the dates
  starttime=parseISO8601DateToDate(times[0]);
  stoptime=parseISO8601DateToDate(times[1]);
  interval=parseISO8601IntervalToDateInterval(times[2]);
  //Calculate the number if steps
  steps=getNumberOfTimeSteps(starttime,stoptime,interval);
}
*/

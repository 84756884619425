/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Chip } from '@mui/material';
import { FieldErrors } from 'react-hook-form';
import { CustomTooltip } from '@opengeoweb/shared';
import { filterDuplicateErrors, getErrors, getIssuesLabel } from './utils';
import { IssuesPanePosition } from './types';
import { useTafTranslation } from '../../utils/i18n';

interface IssuesButtonProps {
  errors?: FieldErrors;
  isIssuesPaneOpen?: boolean;
  setIsIssuesPaneOpen?: (isOpen: boolean, position: IssuesPanePosition) => void;
}

const IssuesButton: React.FC<IssuesButtonProps> = ({
  errors = {},
  isIssuesPaneOpen = false,
  setIsIssuesPaneOpen = (): void => null!,
}: IssuesButtonProps) => {
  const { t } = useTafTranslation();
  const errorList = filterDuplicateErrors(getErrors(errors));

  const totalErrors = errorList.length;
  const errorStatus = totalErrors > 0 ? 'error' : 'success';
  const buttonColor = `geowebColors.functional.${errorStatus}`;

  const toggleIssuesPane = (newPosition: IssuesPanePosition): void =>
    setIsIssuesPaneOpen(!isIssuesPaneOpen, newPosition);

  const onClickButton = (event: React.MouseEvent<HTMLElement>): void => {
    const { left, top } = event.currentTarget.getBoundingClientRect();
    const parentOffset = document
      .getElementById('tafmodule')
      ?.getBoundingClientRect() || { top: 0, left: 0 };

    const newPosition = {
      left: left - parentOffset.left + 100, // button width margin
      top: top - parentOffset.top,
    };
    toggleIssuesPane(newPosition);
  };

  return (
    <CustomTooltip title={t('issues-button-title')}>
      <Chip
        data-testid="issuesButton"
        className={`status-${errorStatus}`}
        size="small"
        label={getIssuesLabel(totalErrors, t)}
        onClick={onClickButton}
        sx={{
          minWidth: '74px',
          color: '#ffffff',
          backgroundColor: buttonColor,
          '&:hover': {
            backgroundColor: buttonColor,
          },
          '&:focus': {
            backgroundColor: buttonColor,
          },
          fontSize: 12,
        }}
      />
    </CustomTooltip>
  );
};

export default IssuesButton;

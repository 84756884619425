/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Draft,
} from '@reduxjs/toolkit';
import {
  OpenSnackbarPayload,
  SnackbarItem,
  SnackbarState,
  TriggerOpenSnackbarByListenerPayload,
} from './types';

export const snackbarAdapter = createEntityAdapter<SnackbarItem>();

export const initialState: SnackbarState = snackbarAdapter.getInitialState();

const slice = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    // To open the snackbar call this action
    openSnackbar: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<SnackbarState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<OpenSnackbarPayload>,
    ) => {},
    // triggerOpenSnackbarByListener is triggered by the listener to open the snackbar after the current snackbars have all been closed
    // DO NOT CALL THIS ACTION DIRECTLY!
    triggerOpenSnackbarByListener: (
      draft: Draft<SnackbarState>,
      action: PayloadAction<TriggerOpenSnackbarByListenerPayload>,
    ) => {
      const { snackbarContent } = action.payload;
      // Ensure we have an id before proceeding
      if (snackbarContent.id === undefined) {
        return;
      }
      snackbarAdapter.setOne(draft, snackbarContent);
    },
    closeSnackbar: (draft: Draft<SnackbarState>) => {
      snackbarAdapter.removeAll(draft);
    },
  },
});

export const { reducer: snackbarReducer, actions: snackbarActions } = slice;

export type SnackbarActions =
  | ReturnType<typeof snackbarActions.openSnackbar>
  | ReturnType<typeof snackbarActions.triggerOpenSnackbarByListener>
  | ReturnType<typeof snackbarActions.closeSnackbar>;

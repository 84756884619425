/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { SxProps } from '@mui/material';
import { AutoUpdateActive, Both, FastForward, None } from '@opengeoweb/theme';
import { ToggleMenu } from '@opengeoweb/shared';
import { useCoreTranslation } from '../../../../../utils/i18n';

const getIconColor = (isActive: boolean, isEnabled: boolean): string => {
  if (isActive) {
    return 'common.white';
  }
  if (isEnabled) {
    return 'geowebColors.buttons.flat.default.color';
  }
  return 'geowebColors.buttons.flat.disabled.color';
};

export enum AutoOptions {
  BOTH = 'Auto both',
  NONE = 'Auto none',
  TIMESTEP = 'Auto-timestep',
  UPDATE = 'Auto-update',
}

interface ActivateLayerProps {
  onChange: (autoOption: AutoOptions) => void;
  isEnabled: boolean;
  current: AutoOptions;
}

const ActivateLayer: React.FC<ActivateLayerProps> = ({
  onChange,
  isEnabled,
  current,
}: ActivateLayerProps) => {
  const isActive = current !== AutoOptions.NONE;
  const { t } = useCoreTranslation();
  return (
    <ToggleMenu
      tooltipTitle={t('layermanager-layer-leading')}
      buttonSx={{
        margin: '4px 2px',
        '&.MuiButtonBase-root': {
          backgroundColor: isActive
            ? 'geowebColors.buttons.tool.active.fill'
            : 'inherit',
          '&:hover': {
            backgroundColor: isActive
              ? 'geowebColors.buttons.tool.activeMouseOver.fill'
              : 'geowebColors.buttons.tool.mouseOver.fill',
          },
          '&.Mui-focusVisible:not(.Mui-selected)': {
            backgroundColor: isActive
              ? 'geowebColors.buttons.tool.active.fill'
              : 'inherit',
          },
        },
      }}
      buttonIcon={<AutoUpdateIcon current={current} isEnabled={isEnabled} />}
      menuItems={[
        {
          text: t('layermanager-layer-leading-none'),
          action: (): void => {
            onChange(AutoOptions.NONE);
          },
          icon: <None />,
          isSelected: current === AutoOptions.NONE,
        },
        {
          text: t('layermanager-layer-leading-auto-timestep'),
          action: (): void => {
            onChange(AutoOptions.TIMESTEP);
          },
          icon: <FastForward />,
          isSelected: current === AutoOptions.TIMESTEP,
        },
        {
          text: t('layermanager-layer-leading-auto-update'),
          action: (): void => {
            onChange(AutoOptions.UPDATE);
          },
          icon: <AutoUpdateActive />,
          isSelected: current === AutoOptions.UPDATE,
        },
        {
          text: t('layermanager-layer-leading-both'),
          action: (): void => {
            onChange(AutoOptions.BOTH);
          },
          isSelected: current === AutoOptions.BOTH,
          icon: <Both />,
        },
      ]}
      menuTitle={t('layermanager-layer-leading')}
    />
  );
};

export default ActivateLayer;

const AutoUpdateIcon: React.FC<{
  current: AutoOptions;
  isEnabled: boolean;
}> = ({ current, isEnabled }) => {
  const isActive = current !== AutoOptions.NONE;
  const Icon: React.ReactElement = getIcon(isActive, isEnabled, current);

  return Icon;
};

function getIcon(
  isActive: boolean,
  isEnabled: boolean,
  current: AutoOptions,
): React.ReactElement {
  const style: SxProps = {
    color: getIconColor(isActive, isEnabled),
  };
  if (current === AutoOptions.BOTH) {
    return <Both sx={style} />;
  }
  if (current === AutoOptions.TIMESTEP) {
    return <FastForward sx={style} />;
  }
  if (current === AutoOptions.UPDATE) {
    return <AutoUpdateActive sx={style} />;
  }
  return <None sx={style} />;
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { styled } from '@mui/material';
import {
  CustomToggleButton,
  CustomToggleButtonProps,
} from '@opengeoweb/shared';
import React from 'react';

interface TimeSeriesButtonProps extends CustomToggleButtonProps {
  icon: React.ReactNode;
  shortText?: string;
  longText: string;
}

const TimeSeriesTopRowButtonUnstyled = React.forwardRef<
  HTMLButtonElement,
  TimeSeriesButtonProps
>(({ icon, shortText, longText, ...props }: TimeSeriesButtonProps, ref) => (
  <CustomToggleButton {...props} ref={ref} variant="tool">
    {icon}
    <span className="longText">{longText}</span>
    <span className="shortText">{shortText || longText}</span>
  </CustomToggleButton>
));

export const TimeSeriesTopRowButton = styled(TimeSeriesTopRowButtonUnstyled)({
  height: '10px !important',
  '@container time-series-container (max-width: 450px)': {
    width: '90px',
    '& .longText': {
      display: 'none',
    },
    '& svg': {
      display: 'none',
    },
  },
  '@container time-series-container (min-width: 450px)': {
    width: '130px',
    '& .shortText': {
      display: 'none',
    },
  },
  '& span': {
    fontSize: '9pt',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const defaultGeoJSONStyleProperties: GeoJSON.GeoJsonProperties = {
  fill: '#ff7800',
  'fill-opacity': 0.2,
  stroke: '#ff7800',
  'stroke-width': 2,
  'stroke-opacity': 1,
};

export const emptyGeoJSON: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
};

export const featurePoint: GeoJSON.Feature<GeoJSON.Point> = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Point',
    coordinates: [],
  },
};

export const featureMultiPoint: GeoJSON.Feature<GeoJSON.MultiPoint> = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'MultiPoint',
    coordinates: [[]],
  },
};

export const featurePolygon: GeoJSON.Feature<GeoJSON.Polygon> = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Polygon',
    coordinates: [[]],
  },
};

export const featureBox: GeoJSON.Feature<GeoJSON.Polygon> = {
  type: 'Feature',
  properties: { _type: 'box' },
  geometry: {
    type: 'Polygon',
    coordinates: [[]],
  },
};

export const lineString: GeoJSON.Feature<GeoJSON.LineString> = {
  type: 'Feature',
  properties: {
    stroke: '#66F',
    'stroke-width': 5,
    'stroke-opacity': '1',
  },
  geometry: {
    type: 'LineString',
    coordinates: [[]],
  },
};

export const lineStringCollection: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [lineString],
};

export interface GeoFeatureStyle {
  stroke?: string;
  fill?: string;
  'stroke-width'?: number;
  'stroke-opacity'?: number;
  'fill-opacity'?: number;
}

export interface Coordinate {
  x: number;
  y: number;
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  mapActions,
  mapSelectors,
  CoreAppStore,
  useSetupDialog,
} from '@opengeoweb/store';
import GetFeatureInfoDialog from './GetFeatureInfoDialog';

export const IS_GETFEATUREINFO_OPEN_BY_DEFAULT = false;

interface GetFeatureInfoConnectProps {
  showMapId?: boolean;
  mapId: string;
}

const GetFeatureInfoConnect: React.FC<GetFeatureInfoConnectProps> = ({
  showMapId = false,
  mapId,
}: GetFeatureInfoConnectProps) => {
  const dispatch = useDispatch();
  const getFeatureInfoType = `getfeatureinfo-${mapId}`;

  const mapPinLocation = useSelector((store: CoreAppStore) =>
    mapSelectors.getPinLocation(store, mapId),
  );

  const toggleMapPinIsVisible = React.useCallback(
    (displayMapPin: boolean): void => {
      dispatch(mapActions.toggleMapPinIsVisible({ mapId, displayMapPin }));
    },
    [dispatch, mapId],
  );

  const enableMapPin = (): void => {
    dispatch(
      mapActions.setDisableMapPin({
        mapId,
        disableMapPin: false,
      }),
    );
  };

  const mapLayers = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapLayers(store, mapId),
  );

  const { dialogOrder, isDialogOpen, uiSource, setDialogOrder, onCloseDialog } =
    useSetupDialog(getFeatureInfoType);

  React.useEffect(() => {
    toggleMapPinIsVisible(isDialogOpen);
    if (isDialogOpen) {
      enableMapPin();
    }
    /* Do not respond on changes of isMapPinVisible, to allow other components to open/close the mappin. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, toggleMapPinIsVisible]);

  return (
    <GetFeatureInfoDialog
      layers={mapLayers}
      isOpen={isDialogOpen}
      onClose={onCloseDialog}
      mapId={mapId}
      showMapId={showMapId}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      source={uiSource}
      mapPinLocation={mapPinLocation}
    />
  );
};

export default GetFeatureInfoConnect;

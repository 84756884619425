/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box, Card, Grid2 as Grid } from '@mui/material';
import {
  LastUpdateTime,
  dateUtils,
  useConfirmationDialog,
} from '@opengeoweb/shared';
import { useApiContext } from '@opengeoweb/api';
import { useDispatch } from 'react-redux';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { TFunction } from 'i18next';
import { TacOverview, TacOverviewMobile } from './TacOverview';
import { TopTabs } from './TopTabs';
import { TafFromBackend, TafFromFrontEnd, TimeSlot, Taf } from '../../types';
import {
  getNewTafLocationIndex,
  getPreviousTaf,
  sortTafTimeSlots,
} from './utils';
import { LocationTabs } from './LocationTabs';
import { TafPanel } from './TafPanel';
import { MODAL_DIALOG_ELEMENT, useIssuesPane } from '../IssuesPane/IssuesPane';
import IssuesPaneWrapper from '../IssuesPane/IssuesPaneWrapper';
import {
  fromActionToStatus,
  isWarning,
  TafError,
  usePostTafRequest,
} from './TafPanel/TafPanel';
import { useTafModuleContext } from '../TafModule/TafModuleProvider';
import { tafActionToDraftAction } from '../TafForm/TafFormButtons/TafFormButtons';
import { TafApi } from '../../utils/api';
import { formatBaseTime } from './TopTabs/TopTabs';
import { useTafTranslation } from '../../utils/i18n';

export const getTafMessageContent = ({
  location,
  validDateStart,
  messageType,
}: Taf): { location: string; formattedDate: string; messageType: string } => {
  const formattedDate = formatBaseTime(dateUtils.utc(validDateStart));
  return {
    location,
    formattedDate,
    messageType,
  };
};

export const getSnackbarMessage = (
  tafFromFrontEnd: TafFromFrontEnd,
  isAutoSaved: boolean,
  t: TFunction,
): string => {
  const { changeStatusTo, taf } = tafFromFrontEnd;
  if (isAutoSaved) {
    return t('snackbar-auto-save');
  }

  const content = getTafMessageContent(taf);

  switch (changeStatusTo) {
    case 'DRAFT':
      return t('snackbar-draft');
    case 'DRAFT_AMENDED':
      return t('snackbar-draft-amend');
    case 'DRAFT_CORRECTED':
      return t('snackbar-draft-correct');
    case 'PUBLISHED':
      return t('snackbar-publish', content);
    case 'CANCELLED':
      return t('snackbar-cancel', content);
    case 'CORRECTED':
      return t('snackbar-correct', content);
    case 'AMENDED':
      return t('snackbar-amend', content);
    default:
      return t('snackbar-save', content);
  }
};

interface TafLayoutProps {
  tafList: TafFromBackend[];
  onUpdateTaf: () => Promise<TafFromBackend[]>;
  lastUpdateTime?: string;
}

const TafLayout: React.FC<TafLayoutProps> = ({
  tafList = [],
  onUpdateTaf = (): Promise<TafFromBackend[]> => null!,
  lastUpdateTime,
}: TafLayoutProps) => {
  const { t } = useTafTranslation();
  const { api } = useApiContext<TafApi>();
  const dispatch = useDispatch();
  const confirmDialog = useConfirmationDialog();
  const {
    isIssuesPaneOpen,
    onToggleIssuesPane,
    onCloseIssuesPane,
    startPosition,
    onUserHasDragged,
  } = useIssuesPane();
  const {
    isLoading,
    doRequest: postTafRequest,
    error: postError,
  } = usePostTafRequest(api.postTaf);
  const { doRequest: patchTafRequest, error: patchError } = usePostTafRequest(
    api.patchTaf,
  );

  const { onValidateForm, tafAction } = useTafModuleContext();

  const [{ upcoming, current, expired }, updateSortedTafs] = React.useState(
    sortTafTimeSlots(tafList),
  );
  const [lastCurrentTaf] = current.slice(-1);

  const [timeSlot, setTimeSlot] = React.useState<TimeSlot>('UPCOMING');
  const [locations, setLocations] = React.useState<TafFromBackend[]>(upcoming);
  const [activeTafIndex, setActiveTafIndex] = React.useState<number>(0);
  const [isFormDisabled, setIsFormDisabled] = React.useState<boolean>(false);
  const [error, setError] = React.useState<TafError>();

  const showSnackbar = React.useCallback(
    (message: string) => {
      dispatch(
        snackbarActions.openSnackbar({
          type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
          message,
        }),
      );
    },
    [dispatch],
  );

  const postTaf = async (
    data: TafFromFrontEnd,
    isAutoSaved = false,
  ): Promise<void> => {
    try {
      setError(null!);
      await postTafRequest(data);
      showSnackbar(getSnackbarMessage(data, isAutoSaved, t));
      // callback
      void onUpdateTaf();
    } catch (error: unknown) {
      if (isWarning(error as TafError)) {
        void onUpdateTaf();
      }
      throw error;
    }
  };

  const patchTaf = async (data: TafFromFrontEnd): Promise<boolean> => {
    try {
      setError(null!);
      await patchTafRequest(data);

      // callback
      void onUpdateTaf();
      return true;
    } catch (error) {
      return false;
    }
  };

  const CONFIRM_DIALOG_OPTIONS = {
    title: t('confirm-dialog-title'),
    description: t('confirm-dialog-description'),
    confirmLabel: t('confirm-dialog-confirm-label'),
  };

  async function onChangeTab<T>(
    action: (param: T) => void,
    value: T,
    isFormDirty: boolean,
  ): Promise<void> {
    if (isFormDirty) {
      try {
        const taf = await onValidateForm!();
        await postTaf(
          {
            taf,
            changeStatusTo: fromActionToStatus(
              tafActionToDraftAction(tafAction!),
            ),
          } as TafFromFrontEnd,
          true,
        );
        action(value);
      } catch (error) {
        void confirmDialog(CONFIRM_DIALOG_OPTIONS).then(() => {
          action(value);
          setError(null!);
        });
      }
    } else {
      action(value);
      setError(null!);
    }
  }

  const onChangeLocationTab = (index: number, isFormDirty: boolean): void => {
    void onChangeTab(setActiveTafIndex, index, isFormDirty);
  };

  const onChangeTimeSlot = (
    newTimeSlot: TimeSlot,
    isFormDirty: boolean,
  ): void => {
    void onChangeTab(changeTimeSlot, newTimeSlot, isFormDirty);
  };

  const changeTimeSlot = (newTimeSlot: TimeSlot): void => {
    setTimeSlot(newTimeSlot);
    setLocations(newTimeSlot === 'ACTIVE' ? current : upcoming);
    setActiveTafIndex(0);
  };

  React.useEffect(() => {
    const newSortedTafs = sortTafTimeSlots(tafList);
    const updatedLocations =
      timeSlot === 'ACTIVE' ? newSortedTafs.current : newSortedTafs.upcoming;
    updateSortedTafs(sortTafTimeSlots(tafList));
    setLocations(updatedLocations);

    // update active index
    const previousActiveLocationUUID = locations[activeTafIndex]?.taf?.uuid;
    const newTafLocationIndex = getNewTafLocationIndex(
      updatedLocations,
      previousActiveLocationUUID,
    );
    setActiveTafIndex(newTafLocationIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tafList, setLocations]);

  React.useEffect(() => {
    setError(postError);

    const cancelErrorTimeout =
      postError && postError.type === 'CANCELLED'
        ? setTimeout(() => {
            setError(null!);
          }, 10000)
        : null;
    return (): void => {
      if (cancelErrorTimeout !== null) {
        clearTimeout(cancelErrorTimeout);
      }
    };
  }, [postError]);

  React.useEffect(() => {
    setError(patchError);
  }, [patchError]);

  if (!tafList || !tafList.length) {
    return null;
  }
  const activeTaf = locations[activeTafIndex];

  return (
    <>
      <Box
        id={MODAL_DIALOG_ELEMENT}
        component="div"
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          overflow: 'hidden',
          pointerEvents: 'none',
        }}
      />
      <Box sx={{ height: '100%', maxHeight: '100%' }}>
        <Box
          sx={{
            minWidth: 320,
            margin: 'auto',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid container spacing={2} sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <Grid size={{ xs: 12, sm: 9 }}>
              <TopTabs
                tafFromBackend={lastCurrentTaf}
                timeSlot={timeSlot}
                onChangeTimeSlot={onChangeTimeSlot}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              {lastUpdateTime && (
                <LastUpdateTime
                  lastUpdateTime={lastUpdateTime}
                  onPressRefresh={onUpdateTaf}
                  dataTestId="lastupdated-time"
                />
              )}
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                height: '100%',
                paddingLeft: 1,
                paddingRight: 1,
                paddingTop: 2,
                overflow: 'auto',
              }}
            >
              <Grid size={{ xs: 3, sm: 1 }}>
                <LocationTabs
                  tafList={locations}
                  activeIndex={activeTafIndex}
                  onChangeTab={onChangeLocationTab}
                  timeSlot={timeSlot}
                />
              </Grid>
              <Grid size="grow">
                <Card
                  variant="outlined"
                  sx={{
                    marginBottom: '12px',
                    position: 'relative',
                    minHeight: locations.length * 62 + 32,
                  }}
                >
                  <TacOverviewMobile
                    upcomingTafs={upcoming}
                    currentTafs={current}
                    expiredTafs={expired}
                    activeTaf={activeTaf}
                  />
                  {locations.map((tafFromBackend, index) => (
                    <TafPanel
                      key={tafFromBackend.taf.uuid}
                      activeTafIndex={activeTafIndex}
                      index={index}
                      tafFromBackend={tafFromBackend}
                      isIssuesPaneOpen={isIssuesPaneOpen}
                      setIsIssuesPaneOpen={onToggleIssuesPane}
                      isFormDisabled={isFormDisabled}
                      setIsFormDisabled={setIsFormDisabled}
                      postTaf={postTaf}
                      patchTaf={patchTaf}
                      isLoading={isLoading}
                      error={error}
                      fetchNewTafList={onUpdateTaf}
                      previousTaf={getPreviousTaf(tafList, tafFromBackend)}
                      tafAction={tafAction!}
                    />
                  ))}
                </Card>
              </Grid>
              <Grid
                size={{
                  xs: 0,
                  sm: 3,
                }}
                sx={{
                  height: { xs: 0, sm: '100%' },
                }}
              >
                <TacOverview
                  upcomingTafs={upcoming}
                  currentTafs={current}
                  expiredTafs={expired}
                  activeTaf={activeTaf}
                  isFormDisabled={isFormDisabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <IssuesPaneWrapper
        handleClose={onCloseIssuesPane}
        isOpen={isIssuesPaneOpen}
        startPosition={startPosition}
        onUserHasDragged={onUserHasDragged}
      />
    </>
  );
};

export default TafLayout;

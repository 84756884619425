/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { compact } from 'lodash';
import type {
  CoordinateReferenceSystem,
  Dimension,
  GeographicBoundingBox,
  LayerProps,
  Style,
  WMSLayerFromGetCapabilities,
} from './types';
import {
  WMDateOutSideRange,
  WMDateTooEarlyString,
  WMDateTooLateString,
} from './WMConstants';

import type WMLayer from './WMLayer';
import { getCorrectWMSDimName } from '../utils/utils';

/* debug helper */
export const enableConsoleDebugging = false;
export enum DebugType {
  Log,
  Warning,
  Error,
}
export const debugLogger = (
  type: DebugType,
  message: string,
  ...optionalParam: unknown[]
): void => {
  if (enableConsoleDebugging) {
    switch (type) {
      case DebugType.Log:
        // eslint-disable-next-line no-console
        console.log(`Log: ${message}`, optionalParam);
        break;
      case DebugType.Warning:
        // eslint-disable-next-line no-console
        console.warn(`Warning: ${message}`, optionalParam);
        break;
      case DebugType.Error:
        // eslint-disable-next-line no-console
        console.error(`Error: ${message}`, optionalParam);
        break;
      default:
        break;
    }
  }
};

/**
 * Checks if variable is defined or not
 * @param variable The variable to check
 * @returns true if variable is indeed defined, otherwise false.
 */
export const isDefined = <T>(variable?: T): variable is T => {
  if (variable === null) {
    return false;
  }
  if (typeof variable === 'undefined') {
    return false;
  }
  return true;
};

/**
 * Checks if a variable is null or not
 * @param variable The variable to check
 * @returns true if variable is indeed null, otherwise false.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const isNull = (variable: any): boolean => {
  if (variable === null) {
    return true;
  }
  return false;
};

/**
 * Converts a variable to an array. If the variable is not an array it will be pushed as the first entry in a new array. If the variable is already an array, nothing will  be done.
 * @param array The variable to convert
 * @returns Always an array
 */
export const toArray = (
  array: unknown | unknown[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => {
  if (array === null || typeof array === 'undefined') {
    return [];
  }
  if (array instanceof Array) {
    return array;
  }
  const newArray: unknown[] = [];
  newArray[0] = array!;
  return newArray;
};

/**
 * Function which checks wether URL contains a ? token. If not, it is assumed that this token was not provided by the user,
 * manually add the token later.
 * @param url The URL to check
 * @return the fixed URL
 */
export const WMJScheckURL = (url: string): string => {
  if (!isDefined(url)) {
    return '?';
  }
  const trimmedUrl = url.trim();
  if (trimmedUrl.indexOf('?') === -1) {
    return `${trimmedUrl}?`;
  }
  return trimmedUrl;
};

export const preventdefaultEvent = (event: Event): void => {
  if (event.preventDefault) {
    event.preventDefault();
  }
};

export const getMouseXCoordinate = (event: MouseEvent): number => {
  return (
    event.clientX +
    document.documentElement.scrollLeft +
    document.body.scrollLeft
  );
};

export const getMouseYCoordinate = (event: MouseEvent): number => {
  return (
    event.clientY + document.documentElement.scrollTop + document.body.scrollTop
  );
};

export const URLDecode = (encodedURL: string): string => {
  if (!isDefined(encodedURL)) {
    return '';
  }
  return encodedURL
    .replace('+', ' ')
    .replace('%2B', '+')
    .replace('%20', ' ')
    .replace('%5E', '^')
    .replace('%26', '&')
    .replace('%3F', '?')
    .replace('%3E', '>')
    .replace('%3C', '<')
    .replace('%5C', '\\')
    .replace('%2F', '/')
    .replace('%25', '%')
    .replace('%3A', ':')
    .replace('%27', "'")
    .replace('%24', '$');
};

// Encodes plain text to URL encoding
export const URLEncode = (plaintext: string): string => {
  if (!plaintext) {
    return plaintext;
  }
  if (plaintext === undefined) {
    return plaintext;
  }
  if (plaintext === '') {
    return plaintext;
  }
  const SAFECHARS =
    '0123456789' + // Numeric
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + // Alphabetic
    'abcdefghijklmnopqrstuvwxyz' +
    "%-_.!~*'()"; // RFC2396 Mark characters
  const HEX = '0123456789ABCDEF';

  const replacedText = plaintext
    .replace(/%/g, '%25')
    .replace(/\+/g, '%2B')
    .replace(/ /g, '%20')
    .replace(/\^/g, '%5E')
    .replace(/&/g, '%26')
    .replace(/\?/g, '%3F')
    .replace(/>/g, '%3E')
    .replace(/</g, '%3C')
    .replace(/\\/g, '%5C');

  let encoded = '';
  for (let i = 0; i < replacedText.length; i += 1) {
    const ch = replacedText.charAt(i);
    if (ch === ' ') {
      encoded += '%20'; // x-www-urlencoded, rather than %20
    } else if (SAFECHARS.indexOf(ch) !== -1) {
      encoded += ch;
    } else {
      const charCode = ch.charCodeAt(0);

      if (charCode > 255) {
        debugLogger(
          DebugType.Warning,
          `Unicode Character '${ch}' cannot be encoded using standard URL encoding.\n` +
            '(URL encoding only supports 8-bit characters.)\n' +
            'A space (+) will be substituted.',
        );
        encoded += '+';
      } else {
        encoded += '%';
        // eslint-disable-next-line no-bitwise
        encoded += HEX.charAt((charCode >> 4) & 0xf);
        // eslint-disable-next-line no-bitwise
        encoded += HEX.charAt(charCode & 0xf);
      }
    }
  }
  return encoded;
};

/* Returns all dimensions with its current values as URL */
export const getMapDimURL = (
  layer: WMLayer,
  dimensionOverride?: Dimension[],
): string => {
  let request = '';
  layer.dimensions.forEach((layerDimension) => {
    const overrideDim =
      dimensionOverride &&
      dimensionOverride.find((d) => {
        return d.name === layerDimension.name;
      });
    const dimension = layerDimension;

    const currentValue =
      (overrideDim &&
        dimension.getClosestValue(overrideDim.currentValue, true)) ||
      dimension.currentValue;
    request += `&${getCorrectWMSDimName(dimension.name)}`;
    request += `=${URLEncode(currentValue)}`;
    if (
      currentValue === WMDateOutSideRange ||
      currentValue === WMDateTooEarlyString ||
      currentValue === WMDateTooLateString
    ) {
      throw new Error(WMDateOutSideRange);
    }
  });
  return request;
};

/**
 * Parses url and then it it allows for setting / changing key value pairs in that URL (From https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter)
 * @param baseUrl
 * @param params
 * @returns
 */
export const getUriWithParam = (
  baseUrl: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>,
): string => {
  const Url = new URL(baseUrl);
  const urlParams: URLSearchParams = new URLSearchParams(Url.search);
  for (const key in params) {
    if (params[key] !== undefined) {
      urlParams.set(key, params[key]);
    }
  }
  Url.search = urlParams.toString();
  return Url.toString();
};

/**
 * Parses url and then it it allows for setting additional key value pairs in that URL (From https://stackoverflow.com/questions/5999118/how-can-i-add-or-update-a-query-string-parameter)
 * @param baseUrl
 * @param params
 * @returns
 */
export const getUriAddParam = (
  baseUrl: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Record<string, any>,
): string => {
  const Url = new URL(baseUrl);
  const urlParams: URLSearchParams = new URLSearchParams(Url.search);
  // Create search params all lowercase so we can compare to the params passed in
  const lowerCaseUrlParams = new URLSearchParams();
  for (const key of urlParams.keys()) {
    lowerCaseUrlParams.append(key.toLowerCase(), 'tempval');
  }
  Object.keys(params).forEach((key) => {
    if (
      params[key] !== undefined &&
      !lowerCaseUrlParams.has(key.toLowerCase())
    ) {
      urlParams.set(key, params[key]);
    }
  });
  Url.search = urlParams.toString();
  return Url.toString();
};

/**
 * Converts a JS date to a ISO8601 string
 * @param date
 * @returns ISO8601 string
 */
export const dateToISO8601 = (date: Date): string => {
  const prf = (input: number, width: number): string => {
    // print decimal with fixed length (preceding zero's)
    let string = `${input}`;
    const len = width - string.length;
    let j;
    let zeros = '';
    for (j = 0; j < len; j += 1) {
      zeros += `0${zeros}`;
    }
    string = zeros + string;
    return string;
  };
  const iso = `${prf(date.getUTCFullYear(), 4)}-${prf(
    date.getUTCMonth() + 1,
    2,
  )}-${prf(date.getUTCDate(), 2)}T${prf(date.getUTCHours(), 2)}:${prf(
    date.getUTCMinutes(),
    2,
  )}:${prf(date.getUTCSeconds(), 2)}Z`;
  return iso;
};

/**
 * Helper function to figure out the styles from the WMS layer object from the WMS GetCapabilities document
 * @param layerObjectFromWMS The layer object from the WMS GetCapabilities JSON document
 * @returns Style[] Array of style objects.
 */

export const addStylesForLayer = (
  layerObjectFromWMS: WMSLayerFromGetCapabilities,
): Style[] => {
  /* Get the Style object */
  if (!layerObjectFromWMS?.Style) {
    return [];
  }
  const layerStyles = toArray(layerObjectFromWMS.Style);

  /* Loop through the list of styles from the document, create a default object and try to fill in the object with the props from the WMS GetCapabilities document */
  return layerStyles.map((layerStyle) => {
    const style: Style = {
      title: 'default',
      name: 'default',
      legendURL: '',
      abstract: 'No abstract available',
    };

    try {
      style.title = layerStyle.Title.value;
    } catch (e) {
      /* Do nothing */
    }
    try {
      style.name = layerStyle.Name.value;
    } catch (e) {
      /* Do nothing */
    }
    try {
      style.legendURL = layerStyle.LegendURL.OnlineResource.attr['xlink:href'];
    } catch (e) {
      /* Do nothing */
    }
    try {
      style.abstract = layerStyle.Abstract.value;
    } catch (e) {
      /* Do nothing */
    }

    return style;
  });
};

export const addDimensionsForLayer = (
  layerObjectFromWMS: WMSLayerFromGetCapabilities,
): Dimension[] => {
  if (!layerObjectFromWMS?.Dimension) {
    return [];
  }
  const layerDimensions = toArray(layerObjectFromWMS.Dimension);
  const layerDimensionsExtents = toArray(layerObjectFromWMS.Extent);

  const dimensionList = layerDimensions.map((layerDimension) => {
    const dimensionName =
      layerDimension.attr && layerDimension.attr.name.toLowerCase();

    /* Find corresponding Dimension Extent if given */
    const dimExtentIndex = layerDimensionsExtents.findIndex(
      (layerDimensionsExtent) => {
        const extentName =
          layerDimensionsExtent.attr &&
          layerDimensionsExtent.attr.name &&
          layerDimensionsExtent.attr.name.toLowerCase();
        return dimensionName === extentName;
      },
    );
    const dimExtent =
      dimExtentIndex !== -1 ? layerDimensionsExtents[dimExtentIndex] : null;

    const dimensionValues =
      (dimExtent && dimExtent.value) || layerDimension.value || '';

    const dimensionCurrentValue =
      (dimExtent && dimExtent.attr && dimExtent.attr.default) ||
      layerDimension.attr.default ||
      '';

    return {
      name: dimensionName,
      units: layerDimension.attr.units,
      currentValue: dimensionCurrentValue.trim(),
      unitSymbol: layerDimension.attr.unitSymbol,
      values: dimensionValues.trim(),
    };
  });
  return dimensionList;
};

export const addBoundingBoxForLayer = (
  layerObjectFromWMS: WMSLayerFromGetCapabilities,
): GeographicBoundingBox | undefined => {
  if (!layerObjectFromWMS) {
    return undefined;
  }
  if (layerObjectFromWMS.EX_GeographicBoundingBox) {
    const layerBbox = layerObjectFromWMS.EX_GeographicBoundingBox;
    return {
      east: layerBbox.eastBoundLongitude?.value,
      west: layerBbox.westBoundLongitude?.value,
      north: layerBbox.northBoundLatitude?.value,
      south: layerBbox.southBoundLatitude?.value,
    };
  }

  if (layerObjectFromWMS.LatLonBoundingBox) {
    const layerBbox = layerObjectFromWMS.LatLonBoundingBox;
    return {
      east: layerBbox.attr.maxx,
      west: layerBbox.attr.minx,
      north: layerBbox.attr.maxy,
      south: layerBbox.attr.miny,
    };
  }

  return undefined;
};

/**
 * Sorts an array with objects by object key.
 *
 * @param array Array of objects
 * @param key The key of the object to use for sorting
 * @returns Sorted array.
 */
export function sortArrayOfObjectsByKey<ArrayOfObjects>(
  array: ArrayOfObjects[],
  key: string,
): ArrayOfObjects[] {
  const spreadArray: ArrayOfObjects[] = [...array];
  return spreadArray.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const x = (a as any)[key];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const y = (b as any)[key];
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
}

/**
 * Makes a LayerProps object from the WMSLayerFromGetCapabilities and nestedLayerPath
 * @param layer WMSLayerFromGetCapabilities
 * @param path The path of the layer in the WMS GetCapabilities tree (Array of strings)
 * @param isleaf Is this a group layer or an actual layer
 * @param nestedLayerPath The path of the layer in the WMS GetCapabilities, in this case array of LayerProps
 * @returns
 */
export const makeNodeLayerFromWMSGetCapabilityLayer = (
  layer: WMSLayerFromGetCapabilities,
  path: string[] = [],
  isleaf = false,
  nestedLayerPath: LayerProps[] = [],
): LayerProps => {
  const geographicBoundingBox: GeographicBoundingBox | undefined =
    nestedLayerPath.reverse().reduce((p, c) => {
      return p || c.geographicBoundingBox;
    }, addBoundingBoxForLayer(layer));

  const dimensions: Dimension[] = nestedLayerPath
    .reverse()
    .reduce((dims, props) => {
      const parentDims = (props.dimensions || []).filter(
        (dim) => !dims.some((d) => d.name === dim.name),
      );
      return [...parentDims, ...dims];
    }, addDimensionsForLayer(layer));

  const styles: Style[] = nestedLayerPath.reverse().reduce((p, c) => {
    if (c.styles && Array.isArray(c.styles) && c.styles.length) {
      return c.styles.concat(p);
    }
    return p;
  }, addStylesForLayer(layer));

  const queryable = layer?.attr
    ? parseInt(layer.attr.queryable, 10) === 1
    : false;

  const crs: CoordinateReferenceSystem[] =
    layer?.BoundingBox &&
    Array.isArray(layer.BoundingBox) &&
    layer.BoundingBox.length
      ? compact(
          layer.BoundingBox.map(
            (wmsBBOX): CoordinateReferenceSystem | undefined => {
              if (wmsBBOX.attr) {
                const crsName = wmsBBOX.attr.CRS || wmsBBOX.attr.SRS;
                return {
                  name: crsName,
                  bbox: {
                    left: parseFloat(wmsBBOX.attr.minx),
                    right: parseFloat(wmsBBOX.attr.maxx),
                    bottom: parseFloat(wmsBBOX.attr.miny),
                    top: parseFloat(wmsBBOX.attr.maxy),
                  },
                };
              }
              return undefined;
            },
          ),
        )
      : [];

  const layerName = layer?.Name ? layer.Name.value : null;
  const layerTitle = layer?.Title ? layer.Title.value : layerName;

  return {
    name: layerName,
    title: layerTitle || undefined,
    leaf: isleaf,
    path,
    keywords: toArray(layer?.KeywordList?.Keyword ?? [])
      .map((keyword) => keyword.value)
      .filter(Boolean),
    abstract: layer?.Abstract ? layer.Abstract.value : undefined,
    styles,
    dimensions,
    geographicBoundingBox,
    queryable,
    crs,
  };
};

export const getFirstPartOfDimensionValueSet = (inputValue: string): string => {
  return inputValue?.indexOf('/') === -1
    ? inputValue
    : inputValue.split('/')[0];
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  SystemScope,
  ServiceInterface,
  TimeSeriesService,
} from '@opengeoweb/shared';
import { EntityState } from '@reduxjs/toolkit';
import type { Parameter, PlotPreset } from '../components/TimeSeries/types';

export enum TimeSeriesActionOrigin {
  timeSeriesManager = 'timeSeriesManager',
  timeSeriesSelect = 'timeSeriesSelect',
}

export interface Plot {
  title: string;
  plotId: string;
}

export type PopupVariant = 'edit' | 'add' | 'save' | 'show';

export interface ServiceFilterChipsObject {
  serviceId: string;
  serviceName?: string;
  serviceUrl: string;
  type: ServiceInterface;
  enabled?: boolean;
  scope?: SystemScope;
  isLoading?: boolean;
}

export type ServiceFilterChipsType = EntityState<ServiceFilterChipsObject>;
export type ServiceFilterChipsObjectEntities = Record<
  string,
  ServiceFilterChipsObject
>;

export interface TimeseriesSelectFilters {
  searchFilter: string;
  serviceFilterChips: ServiceFilterChipsType;
  allServiceFilterChipsEnabled: boolean;
}

export interface TimeSeriesServicePopupObject {
  isOpen: boolean;
  variant: PopupVariant;
  url?: string;
  serviceId?: string;
}

export interface TimeseriesSelectStore {
  filters: TimeseriesSelectFilters;
  currentParameterInfo?: string | Parameter;
  servicePopup: TimeSeriesServicePopupObject;
}

export interface TimeSeriesStoreType {
  plotPreset?: PlotPreset;
  timeseriesSelect?: TimeseriesSelectStore;
  services?: TimeSeriesService[];
}

export interface TimeSeriesPreset {
  plotPreset: PlotPreset;
  services: TimeSeriesService[];
}

export interface TimeSeriesPayload {
  panelId?: string;
  origin?: string;
}

export interface ParameterManipulationPayload extends TimeSeriesPayload {
  parameter: Parameter;
}

export interface ParameterPatchPayload extends TimeSeriesPayload {
  parameter: Partial<Parameter>;
}

export interface ParameterActionPayload extends TimeSeriesPayload {
  parameterId: string;
}

export interface PlotActionPayload extends TimeSeriesPayload {
  plotId: string;
}

export interface AddPlotPayload extends TimeSeriesPayload {
  title: string;
}

export interface UpdateTitlePayload extends TimeSeriesPayload {
  plotId: string;
  title: string;
}

export interface TimeSeriesModuleState {
  timeSeries?: TimeSeriesStoreType;
}

export interface SetSearchFilterPayload {
  filterText: string;
}

export interface MovePlotPayload extends TimeSeriesPayload {
  oldIndex: number;
  newIndex: number;
}

export interface MoveParameterPayload extends TimeSeriesPayload {
  oldIndex: number;
  newIndex: number;
  plotId: string;
  toPlotId: string;
}

export interface SetCurrentParameterInfo {
  parameter: string | Parameter;
}

export interface SetCurrentFrozenParameterInfo {
  parameter: Parameter;
}
export interface SetTimeSeriesServicesPayload {
  timeSeriesServices: TimeSeriesService[];
}

export interface RemoveServiceFilterChipFromStorePayload {
  id: string;
}

export interface RemoveTimeSeriesServicePayload {
  id: string;
}

export interface UpsertTimeSeriesServicePayload extends TimeSeriesService {
  isUpdate: boolean;
}

export interface SetTimeSeriesServicePopupPayload {
  url?: string;
  variant: PopupVariant;
  serviceId?: string;
  isOpen: boolean;
}

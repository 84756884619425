/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { PresetScope } from '@opengeoweb/shared';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/types';
import {
  viewPresetActions,
  viewPresetSelectors,
} from '../../store/viewPresets';
import { PresetAction } from '../../store/viewPresets/types';
import * as workspaceSelectors from '../../store/workspace/selectors';
import ViewPresetsFormDialog from './ViewPresetsFormDialog';

const ViewPresetsFormDialogConnect: React.FC<{ scope?: PresetScope }> = ({
  scope = 'user',
}) => {
  const dispatch = useDispatch();
  const dialogOptions = useSelector((store: AppStore) =>
    viewPresetSelectors.getDialogOptions(store),
  );
  const panelId = dialogOptions?.panelId!;
  const viewComponentType = useSelector((store: AppStore) =>
    workspaceSelectors.getViewComponentType(store, panelId),
  );

  const onReset = React.useCallback(
    (viewPresetId: string) => {
      dispatch(
        viewPresetActions.selectViewPreset({
          panelId,
          viewPresetId,
          shouldReset: true,
        }),
      );
    },
    [dispatch, panelId],
  );

  const closeDialog = React.useCallback(() => {
    dispatch(viewPresetActions.closeViewPresetDialog());
  }, [dispatch]);

  const onClose = (): void => {
    closeDialog();
  };

  const onSuccess = (
    action: PresetAction,
    newViewPresetId: string,
    title: string,
    abstract: string,
  ): void => {
    dispatch(
      viewPresetActions.onSuccessViewPresetAction({
        panelId,
        viewPresetId: newViewPresetId,
        action,
        title,
        scope,
        abstract,
      }),
    );
  };

  const isOpen = dialogOptions !== undefined;

  if (!isOpen) {
    return null;
  }

  return (
    <ViewPresetsFormDialog
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
      viewComponentType={viewComponentType}
      {...dialogOptions}
      formValues={dialogOptions?.formValues}
      onReset={onReset}
    />
  );
};

export default ViewPresetsFormDialogConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createEntityAdapter,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { WorkspacePresetListItem } from '../workspace/types';
import {
  OpenWorkspaceActionDialogPayload,
  FetchedWorkspaceList,
  WorkspaceListState,
  ErrorWorkspaceList,
  OnSuccesWorkspacePresetActionPayload,
  ToggleListDialogPayload,
  ToggleSelectFilterChip,
  WorkspaceListFilter,
  FetchWorkspaceList,
  SearchWorkspaceFilter,
  SubmitFormWorkspaceActionDialogPayload,
  OnErrorWorkspacePresetActionPayload,
} from './types';

export const workspaceListFilterOptions: WorkspaceListFilter[] = [
  {
    label: 'shared-filter-my-presets',
    id: 'user',
    type: 'scope',
    isSelected: true,
    isDisabled: false,
  },
  {
    label: 'shared-filter-system-presets',
    id: 'system',
    type: 'scope',
    isSelected: true,
    isDisabled: false,
  },
];

export const workspaceListAdapter =
  createEntityAdapter<WorkspacePresetListItem>();

export const initialState: WorkspaceListState = {
  isFetching: false,
  error: undefined,
  isWorkspaceListDialogOpen: false,
  ...workspaceListAdapter.getInitialState(),
  searchQuery: '',
  workspaceListFilters: workspaceListFilterOptions,
};

const slice = createSlice({
  initialState,
  name: 'workspaceList',
  reducers: {
    fetchWorkspaceList: (
      draft: Draft<WorkspaceListState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<FetchWorkspaceList>,
    ) => {
      draft.isFetching = true;
      draft.error = undefined;
    },
    fetchedWorkspaceList: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<FetchedWorkspaceList>,
    ) => {
      const { workspaceList } = action.payload;
      workspaceListAdapter.setAll(draft, workspaceList);
      draft.isFetching = false;
    },
    errorWorkspaceList: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<ErrorWorkspaceList>,
    ) => {
      const { error } = action.payload;
      draft.isFetching = false;
      draft.error = error;
    },
    openWorkspaceActionDialogOptions: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<OpenWorkspaceActionDialogPayload>,
    ) => {
      draft.workspaceActionDialog = {
        ...action.payload,
        error: undefined,
        isFetching: false,
      };
    },
    onSuccessWorkspaceSaveDialog: (
      draft: Draft<WorkspaceListState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{ presetId: string }>,
    ) => {
      if (!draft.workspaceActionDialog) {
        return;
      }
      const { presetId } = action.payload;
      if (presetId) {
        draft.workspaceActionDialog.presetId = presetId;
      }
      draft.workspaceActionDialog!.hasSaved = true;
    },
    submitFormWorkspaceActionDialogOptions: (
      draft: Draft<WorkspaceListState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<SubmitFormWorkspaceActionDialogPayload>,
    ) => {
      if (!draft.workspaceActionDialog) {
        return;
      }
      draft.workspaceActionDialog!.error = undefined;
      draft.workspaceActionDialog!.isFetching = true;
    },
    closeWorkspaceActionDialogOptions: (draft: Draft<WorkspaceListState>) => {
      delete draft.workspaceActionDialog;
    },
    // action is intercepted in mapPresets/listener
    onSuccessWorkspacePresetAction: (
      draft: Draft<WorkspaceListState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<OnSuccesWorkspacePresetActionPayload>,
    ) => {
      delete draft.workspaceActionDialog;
    },
    onErrorWorkspacePresetAction: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<OnErrorWorkspacePresetActionPayload>,
    ) => {
      const { error } = action.payload;
      draft.workspaceActionDialog!.isFetching = false;
      draft.workspaceActionDialog!.error = error;
    },
    toggleWorkspaceDialog: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<ToggleListDialogPayload>,
    ) => {
      const { isWorkspaceListDialogOpen: isListDialogOpen } = action.payload;
      draft.isWorkspaceListDialogOpen = isListDialogOpen;
    },
    toggleSelectFilterChip: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<ToggleSelectFilterChip>,
    ) => {
      const { id, isSelected } = action.payload;
      const isAllSelected = draft.workspaceListFilters!.every(
        (filter) => filter.isSelected,
      );
      const index = draft.workspaceListFilters!.findIndex(
        (filter) => filter.id === id,
      );

      if (index !== -1) {
        if (isAllSelected) {
          draft.workspaceListFilters!.forEach((filter, index) => {
            draft.workspaceListFilters![index].isSelected =
              filter.id === id ? isSelected : false;
          });
        } else {
          draft.workspaceListFilters![index] = {
            ...draft.workspaceListFilters![index],
            isSelected,
          };

          const isAllUnSelected = draft.workspaceListFilters!.every(
            (filter) => !filter.isSelected,
          );
          if (isAllUnSelected) {
            draft.workspaceListFilters!.forEach((_, index) => {
              draft.workspaceListFilters![index].isSelected = true;
            });
          }
        }
      }
    },
    setSelectAllFilterChip: (draft: Draft<WorkspaceListState>) => {
      draft.workspaceListFilters = draft.workspaceListFilters!.map((filter) => {
        return { ...filter, isSelected: true };
      });
    },
    setFilterChips: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<ToggleSelectFilterChip[]>,
    ) => {
      const { payload } = action;

      draft.workspaceListFilters!.forEach(
        (filter: WorkspaceListFilter, index) => {
          const existingFilter = payload.find((f) => f.id === filter.id);
          const updatedFilter = {
            ...filter,
            isSelected: existingFilter
              ? existingFilter.isSelected
              : filter.isSelected,
          };
          draft.workspaceListFilters![index] = updatedFilter;
        },
      );
    },
    searchFilter: (
      draft: Draft<WorkspaceListState>,
      action: PayloadAction<SearchWorkspaceFilter>,
    ) => {
      const { searchQuery } = action.payload;
      draft.searchQuery = searchQuery;
    },
  },
});

export const { reducer: workspaceListReducer, actions: workspaceListActions } =
  slice;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import WMImageStore from './WMImageStore';
import {
  isDefined,
  URLEncode,
  getMapDimURL,
  debugLogger,
  DebugType,
} from './WMJSTools';
import { legendImageStoreLength } from './WMConstants';
import WMLayer from './WMLayer';

export const legendImageStore = new WMImageStore(legendImageStoreLength, {
  id: 'legendImageStore',
});

export const getLegendGraphicURLForLayer = (
  layer: WMLayer,
  width = 250,
  height = 250,
): string => {
  if (layer) {
    let legendURL = layer.legendGraphic;
    if (!legendURL) {
      return undefined!;
    }

    try {
      if (layer.legendIsDimensionDependent === true) {
        legendURL += `${getMapDimURL(layer)}&`;
      }
      if (layer.sldURL) {
        legendURL += `&SLD=${URLEncode(layer.sldURL)}`;
      }

      legendURL += '&transparent=true&';

      // append width and height parameters when not present
      if (!legendURL.includes('&width=') && !legendURL.includes('&height=')) {
        legendURL += `&width=${width}&height=${height}&`;
      }
    } catch (e) {
      return undefined!;
    }

    // Handle WMS extensions
    legendURL += layer.wmsextensions.url;

    return legendURL;
  }
  return undefined!;
};

export const getLegendImageStore = (): WMImageStore => {
  return legendImageStore;
};

export const drawLegend = (
  canvasContext: CanvasRenderingContext2D,
  width: number,
  height: number,
  layers: WMLayer[],
): void => {
  /* Draw legends */
  const ctx = canvasContext as CanvasRenderingContext2D;
  let legendPosX = 0;
  for (const layer of layers) {
    if (layer.enabled !== false) {
      const legendUrl = getLegendGraphicURLForLayer(layer);
      if (isDefined(legendUrl)) {
        const image = legendImageStore.getImage(legendUrl, {
          headers: layer.headers,
        });
        if (image.hasError() === false) {
          if (image.isLoaded() === false && image.isLoading() === false) {
            image.load();
          } else {
            const el = image.getElement();
            const legendW = Math.trunc(el.width) + 4;
            const legendH = Math.trunc(el.height) + 4;
            legendPosX += legendW + 4;
            const legendX = width - legendPosX + 2;
            const legendY = height - legendH - 2 - 13;

            ctx.beginPath();
            ctx.fillStyle = '#FFFFFF';
            ctx.lineWidth = 0.3;
            ctx.globalAlpha = 0.5;
            ctx.strokeStyle = '#000000';
            ctx.rect(legendX + 0.5, legendY + 0.5, legendW, legendH);
            ctx.fill();
            ctx.stroke();
            ctx.globalAlpha = 1.0;
            try {
              ctx.drawImage(el, legendX, legendY);
            } catch (e) {
              debugLogger(
                DebugType.Error,
                `Unable to draw legend image ${legendUrl}`,
              );
            }
          }
        }
      }
    }
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import type { FeatureCollection } from 'geojson';
import type { TileServerSettings } from '../utils';
import type IWMJSMap from './IWMJSMap';
import type { Dimension } from './types';
import type WMLayer from './WMLayer';

export enum LayerType {
  mapLayer = 'mapLayer',
  baseLayer = 'baseLayer',
  overLayer = 'overLayer',
  featureLayer = 'featureLayer',
}

export interface TiledLayerFoundation {
  tileServer?: TileServerSettings;
}

export interface LayerFoundation extends TiledLayerFoundation {
  acceptanceTimeInMinutes?: number;
  dimensions?: Dimension[];
  enabled?: boolean;
  format?: string;
  id?: string;
  layerType?: LayerType;
  name?: string;
  opacity?: number; // between 0.0 and 1.0
  service?: string;
  style?: string;
  title?: string;
  type?: string;
  geojson?: FeatureCollection;
}

export interface LayerOptions extends LayerFoundation {
  id: string;
  layerType: LayerType;
  service: string;
  getmapURL?: string;
  active?: boolean;
  getgraphinfoURL?: string;
  currentStyle?: string;
  sldURL?: string;
  keepOnTop?: boolean;
  transparent?: boolean;
  onReady?: (param: LayerOptions) => void;
  parentMap?: IWMJSMap;
  headers?: Headers[];
  failure?: (layer: WMLayer, message: string) => void;
  ReactWMJSLayerId?: string;
  onLayerError?: (
    layer: WMLayer,
    error: Error,
    webmapInstance: IWMJSMap,
  ) => void;
  onLayerReady?: (layer: WMLayer, webmap?: IWMJSMap) => void;
  geojson?: GeoJSON.FeatureCollection;
}

export interface WMSLayerDimInfo {
  name: string;
  units: string;
  unitSymbol: string;
  value: string;
  default: string;
  linkDimensionToMap: boolean;
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid } from '@mui/material';

import { OptionsMenuButton } from './OptionsMenuButton/OptionsMenuButton';
import { ControlButtons } from './ControlButtons/ControlButtons';

export interface TimeSliderButtonsProps {
  optionsMenuBtn?: React.ReactNode;
  playBtn?: React.ReactNode;
  backwardBtn?: React.ReactNode;
  forwardBtn?: React.ReactNode;
  nowBtn?: React.ReactNode;
  autoUpdateBtn?: React.ReactNode;
}

export const TimeSliderButtons: React.FC<TimeSliderButtonsProps> = ({
  optionsMenuBtn,
  playBtn,
  backwardBtn,
  forwardBtn,
  nowBtn,
  autoUpdateBtn,
}: TimeSliderButtonsProps) => {
  return (
    <div data-testid="timeSliderButtons">
      <Grid
        container
        spacing={0.5}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid>{optionsMenuBtn || <OptionsMenuButton />}</Grid>
        <ControlButtons
          backwardBtn={backwardBtn}
          forwardBtn={forwardBtn}
          playBtn={playBtn}
          nowBtn={nowBtn}
          autoUpdateBtn={autoUpdateBtn}
        />
      </Grid>
    </div>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Select,
  BaseSelectProps,
  SelectVariants,
  SxProps,
  Theme,
  styled,
} from '@mui/material';
import { CustomTooltip, useControlledTooltip } from '../CustomTooltip';

interface ListElement {
  value: string;
}

// For any additional styling, pass as part of additionalStyling and not sx, this doesn't work properly anymore with the styled component
type TooltipSelectProps = Omit<BaseSelectProps, 'sx'> & {
  tooltip: string;
  list?: ListElement[];
  currentIndex?: number;
  onChangeMouseWheel?: (element: ListElement) => void;
  requiresCtrlToChange?: boolean;
  isEnabled?: boolean;
  hasBackgroundColor?: boolean;
  variant?: SelectVariants;
  additionalStyling?: SxProps;
  skipLocalStyling?: boolean;
};

export const TooltipSelect: React.FC<TooltipSelectProps> = ({
  tooltip = '',
  list = [],
  currentIndex = -1,
  onChangeMouseWheel = (): void => {},
  requiresCtrlToChange = false,
  isEnabled,
  hasBackgroundColor,
  variant = 'standard',
  additionalStyling = {},
  skipLocalStyling = false,
  ...props
}: TooltipSelectProps) => {
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = useControlledTooltip();

  const onWheel = React.useCallback(
    (event: React.WheelEvent): void => {
      if (
        (requiresCtrlToChange && (event.ctrlKey || event.metaKey)) ||
        !requiresCtrlToChange
      ) {
        if (selectOpen) {
          return;
        }
        const direction = event.deltaY < 0 ? 1 : -1;
        const newIndex = currentIndex - direction;
        if (newIndex < 0 || newIndex >= list.length) {
          return;
        }
        onChangeMouseWheel(list[newIndex]);
      }
    },
    [selectOpen, currentIndex, list, onChangeMouseWheel, requiresCtrlToChange],
  );
  const onOpen = React.useCallback(() => {
    setSelectOpen(true);
    setTooltipOpen(false);
  }, [setTooltipOpen]);
  const onClose = React.useCallback(() => {
    setSelectOpen(false);
  }, []);
  const onMouseEnter = React.useCallback(() => {
    if (!selectOpen) {
      setTooltipOpen(true);
    }
  }, [selectOpen, setTooltipOpen]);
  const onMouseLeave = React.useCallback(() => {
    setTooltipOpen(false);
  }, [setTooltipOpen]);
  const onFocus = React.useCallback(() => {
    setTooltipOpen(true);
  }, [setTooltipOpen]);
  const onBlur = React.useCallback(() => {
    setTooltipOpen(false);
  }, [setTooltipOpen]);

  return (
    <CustomTooltip title={tooltip} placement="top" open={tooltipOpen}>
      <StyledSelect
        variant={variant}
        disableUnderline
        onOpen={onOpen}
        onClose={onClose}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        onWheel={onWheel}
        isEnabled={isEnabled}
        hasBackgroundColor={hasBackgroundColor}
        additionalStyling={additionalStyling}
        skipLocalStyling={skipLocalStyling}
        {...props}
      />
    </CustomTooltip>
  );
};

// Style the select
const BaseSelect = styled(Select, {
  shouldForwardProp: (prop) =>
    prop !== 'isEnabled' &&
    prop !== 'hasBackgroundColor' &&
    prop !== 'skipLocalStyling',
})<{
  isEnabled?: boolean;
  hasBackgroundColor?: boolean;
  skipLocalStyling: boolean;
}>(({ theme, isEnabled, hasBackgroundColor, skipLocalStyling }) => {
  return {
    ...(!skipLocalStyling &&
      tooltipContainerStyles(theme, !!isEnabled, hasBackgroundColor)),
  };
});

interface StyledSelectProps
  extends Omit<TooltipSelectProps, 'tooltip' | 'skipLocalStyling'> {
  skipLocalStyling: boolean;
}

// Style the menu pop-over
const StyledSelect = styled(
  React.forwardRef(
    ({ className, additionalStyling, ...props }: StyledSelectProps, ref) => {
      return (
        <BaseSelect
          {...props}
          MenuProps={{
            classes: { paper: className },
            slotProps: {
              root: {
                slotProps: {
                  backdrop: {
                    style: {
                      backgroundColor: 'transparent',
                    },
                  },
                },
              },
            },
          }}
          sx={additionalStyling}
          ref={ref}
        />
      );
    },
  ),
)(({ theme }) => ({
  '& ul': {
    backgroundColor: theme.palette.geowebColors.background.surface,
  },
  '& li': {
    fontSize: '16px',
    padding: '12px',
    '&:hover': {
      backgroundColor: theme.palette.geowebColors.buttons.tool.mouseOver.fill,
    },
    '&.Mui-selected': {
      color: theme.palette.geowebColors.buttons.tool.active.color,
      backgroundColor: theme.palette.geowebColors.buttons.tool.active.fill,
      boxShadow: `none`,
      '&:hover': {
        backgroundColor:
          theme.palette.geowebColors.buttons.tool.activeMouseOver.fill,
      },
      '&:focus': {
        backgroundColor:
          theme.palette.geowebColors.buttons.tool.activeMouseOver.fill,
      },
      '.MuiListItemIcon-root': {
        color: theme.palette.geowebColors.buttons.tool.active.color,
      },
    },
    '&:after': {
      background: 'none!important',
    },
    '&.Mui-disabled': {
      fontSize: '12px',
      opacity: 0.67,
      padding: '0px 12px',
      '&.MuiMenuItem-root': {
        minHeight: '30px!important',
      },
    },
  },
}));

export const tooltipContainerStyles = (
  theme: Theme,
  isEnabled: boolean,
  hasBackgroundColor = true,
): object => ({
  width: '100%',
  height: 32,
  fontWeight: 500,
  fontSize:
    theme.palette.geowebColors.layerManager.tableRowDefaultText.fontSize!,
  color: isEnabled
    ? theme.palette.geowebColors.layerManager.tableRowDefaultText.rgba
    : theme.palette.geowebColors.layerManager.tableRowDisabledText.rgba,

  '& .MuiOutlinedInput-input': {
    padding: '6px 24px 6px 8px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent!important',
  },
  '&:hover': {
    backgroundColor: theme.palette.geowebColors.buttons.tool.mouseOver.fill,
    borderRadius: 0,
  },
  '&.Mui-focused': {
    outline: theme.palette.geowebColors.buttons.tool.focus.outline!,
    outlineOffset: '-1px',
    borderRadius: 0,
  },
  '& .MuiSvgIcon-root': {
    transition: 'none',
    fill: isEnabled
      ? theme.palette.geowebColors.buttons.flat.default.color!
      : theme.palette.geowebColors.buttons.flat.disabled.color!,
  },
  '&>div': {
    paddingLeft: '8px!important',
  },
  ...theme.palette.geowebColors.tooltips.tooltipSelect.select,
  ...(hasBackgroundColor && {
    '&:hover': {
      backgroundColor: theme.palette.geowebColors.buttons.tool.mouseOver.fill,
      borderRadius: 0,
    },
    '&.Mui-focused .MuiSelect-select': {
      backgroundColor: theme.palette.geowebColors.buttons.tool.mouseOver.fill,
      borderRadius: 0,
    },
    '&.Mui-focused': {
      outline: theme.palette.geowebColors.buttons.tool.focus.outline!,
      outlineOffset: '-1px',
      borderRadius: 0,
      backgroundColor: theme.palette.geowebColors.buttons.tool.mouseOver.fill,
    },
    '&:hover&.MuiButton-root': {
      backgroundColor: theme.palette.geowebColors.buttons.tool.mouseOver.fill,
    },
    '&:focus&.MuiButton-root': {
      border: theme.palette.geowebColors.buttons.tool.focus.outline!,
      backgroundColor: theme.palette.geowebColors.buttons.tool.mouseOver.fill,
      transition: 'border',
    },
  }),
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { TimeSeriesService } from '@opengeoweb/shared';

export const getUserAddedEDRServices = (): TimeSeriesService[] => {
  try {
    const storedString = window.localStorage.getItem('userAddedEDRServices');
    return (JSON.parse(storedString!) as TimeSeriesService[]) || [];
  } catch (e) {
    console.error('Unable to read services from localStorage: ', e);
  }
  return [];
};

export const setUserAddedEDRServices = (
  services: TimeSeriesService[],
): void => {
  try {
    window.localStorage.setItem(
      'userAddedEDRServices',
      JSON.stringify(services),
    );
  } catch (e) {
    console.error('Unable to write services to localStorage: ', e);
  }
};

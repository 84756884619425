/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { AlertBanner } from '@opengeoweb/shared';
import { ThemeWrapper } from '@opengeoweb/theme';
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useAuthenticationContext } from '../AuthenticationContext';
import { AUTH_NAMESPACE, useAuthenticationTranslation } from '../../utils/i18n';

const OAuth2Logout: React.FC = () => {
  const { onSetAuth, authConfig, isLoggedIn, onLogin, sessionStorageProvider } =
    useAuthenticationContext();
  const [showAlert, setShowAlert] = React.useState(true);
  const { t } = useAuthenticationTranslation();

  React.useEffect(() => {
    if (isLoggedIn) {
      setShowAlert(false);
      onSetAuth(null!);
      onLogin(false);
      sessionStorageProvider.removeHasAuthenticated();
      window.location.assign(authConfig.GW_AUTH_LOGOUT_URL);
    }
  }, [
    isLoggedIn,
    onSetAuth,
    onLogin,
    sessionStorageProvider,
    authConfig.GW_AUTH_LOGOUT_URL,
  ]);

  return showAlert ? (
    <ThemeWrapper>
      <AlertBanner
        severity="info"
        title={t('auth-logout-you-are-logged-out')}
        info={
          <p>
            <Trans
              i18nKey="auth-logout-go-back-to-home-page"
              ns={AUTH_NAMESPACE}
            >
              Go back to the <Link to="/">Home Page</Link>
            </Trans>
          </p>
        }
      />
    </ThemeWrapper>
  ) : null;
};

export default OAuth2Logout;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Breakpoint } from '@mui/material';

import {
  AlertBanner,
  useConfirmationDialog,
  dateUtils,
} from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import { ContentDialog } from '../ContentDialog';
import LifeCycleEdit from './LifeCycleEdit';
import LifeCycleDisplay from './LifeCycleDisplay';
import { EventCategory, EventCategoryDetail, SWEvent } from '../../types';
import { useSpaceweatherTranslation } from '../../utils/i18n';

export const getDialogTitle = (
  t: TFunction,
  dialogMode: string,
  event: SWEvent,
): string => {
  const titleTime =
    event &&
    event.lifecycles!.internalprovider &&
    event.lifecycles!.internalprovider.draft !== true
      ? dateUtils.dateToString(
          dateUtils.utc(
            event.lifecycles!.externalprovider
              ? event.lifecycles!.externalprovider.firstissuetime
              : event.lifecycles!.internalprovider.firstissuetime,
          ),
          `${dateUtils.DATE_FORMAT_DATEPICKER}' UTC'`,
        )
      : 'Draft';

  if (dialogMode !== 'new') {
    if (event.categorydetail) {
      return `${
        EventCategoryDetail[
          event.categorydetail as keyof typeof EventCategoryDetail
        ]
      }: ${titleTime}`;
    }
    return `${
      EventCategory[event.category as keyof typeof EventCategory]
    }: ${titleTime}`;
  }
  return t('notification-title-new');
};

export interface LifeCycleDialogProps {
  open: boolean;
  toggleStatus: () => void;
  dialogMode?: string;
  event?: SWEvent;
}

const LifeCycleDialog: React.FC<LifeCycleDialogProps> = ({
  open,
  toggleStatus,
  dialogMode = 'new',
  event = null!,
}: LifeCycleDialogProps) => {
  const { t } = useSpaceweatherTranslation();
  const confirmDialog = useConfirmationDialog();
  const [dialogSize, setDialogSize] = React.useState<Breakpoint>('lg');
  const [isFormChanged, setFormIsChanged] = React.useState(false);
  const [errorStoreMessage, setErrorStoreMessage] = React.useState('');
  const [errorRetrievePopulate, setErrorRetrievePopulate] = React.useState('');

  const onChangeForm = (hasChanged: boolean): void => {
    setFormIsChanged(hasChanged);
  };

  React.useEffect(() => {
    if (dialogMode === 'new' || (event && event.originator === 'KNMI')) {
      setDialogSize('sm');
    } else {
      setDialogSize('lg');
    }
  }, [dialogMode, event]);

  const onToggleDialog = (formSaved = false): void => {
    if (isFormChanged && !formSaved) {
      void confirmDialog({
        title: t('close-dialog-title'),
        description: t('close-dialog-description'),
        confirmLabel: t('close-dialog-confirm'),
        cancelLabel: t('dialog-cancel'),
      }).then(() => {
        toggleStatus();
      });
    } else {
      toggleStatus();
    }
  };

  return (
    <ContentDialog
      open={open}
      toggleStatus={onToggleDialog}
      data-testid="lifecycle-dialog"
      title={getDialogTitle(t, dialogMode, event)}
      maxWidth={dialogSize}
      fullWidth
      disableEscapeKeyDown
      onClose={onToggleDialog}
      alertBanner={
        errorStoreMessage || errorRetrievePopulate ? (
          <AlertBanner
            severity="error"
            title={
              errorStoreMessage
                ? t('dialog-save-error')
                : t('dialog-template-error')
            }
            info={errorStoreMessage || errorRetrievePopulate}
          />
        ) : null
      }
    >
      {dialogMode === 'new' && (
        <LifeCycleEdit
          toggleDialogOpen={onToggleDialog}
          statusTagContent="Alert"
          baseNotificationType="new"
          onFormChange={onChangeForm}
          setErrorStoreMessage={setErrorStoreMessage}
          setErrorRetrievePopulate={setErrorRetrievePopulate}
        />
      )}
      {dialogMode !== 'new' && (
        <LifeCycleDisplay
          eventId={dialogMode}
          toggleDialogOpen={onToggleDialog}
          setErrorStoreMessage={setErrorStoreMessage}
          setErrorRetrievePopulate={setErrorRetrievePopulate}
          onFormChange={onChangeForm}
        />
      )}
    </ContentDialog>
  );
};

export default LifeCycleDialog;

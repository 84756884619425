/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { viewPresetActions } from '../viewPresets';
import { ViewPresetListItem, ViewPresetListState } from './types';

export const viewPresetsListAdapter = createEntityAdapter<ViewPresetListItem>();

export const initialState: ViewPresetListState =
  viewPresetsListAdapter.getInitialState();

const slice = createSlice({
  initialState,
  name: 'viewPresetsList',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(viewPresetActions.fetchedViewPresets, (draft, action) => {
      const { viewPresets, filterParams } = action.payload;
      // If there are filter params applied, don't do anything, these results are put in the specific panel filterResults
      if (Object.keys(filterParams).length !== 0) {
        return;
      }
      if (!viewPresets || !viewPresets.length) {
        viewPresetsListAdapter.removeAll(draft);
        return;
      }
      viewPresetsListAdapter.setAll(draft, action.payload.viewPresets);
    });
  },
});

export const {
  reducer: viewPresetsListReducer,
  actions: viewPresetsListActions,
} = slice;

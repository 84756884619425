/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box, Popper, useTheme, Typography, Fade } from '@mui/material';
import {
  CustomSlider,
  sliderHeaderStyle,
  CustomIconButton,
} from '@opengeoweb/shared';
import { isArray } from 'lodash';
import { useCoreTranslation } from '../../../../../utils/i18n';

interface OpacitySelectProps {
  currentOpacity: number;
  onLayerChangeOpacity: (opacity: number) => void;
  isEnabled?: boolean;
  tooltipPrefix?: string;
}

const marks = [
  {
    value: 0,
    label: '0 %',
  },
  {
    value: 0.1,
  },
  {
    value: 0.2,
  },
  {
    value: 0.3,
  },
  {
    value: 0.4,
  },
  {
    value: 0.5,
    label: '50 %',
  },
  {
    value: 0.6,
  },
  {
    value: 0.7,
  },
  {
    value: 0.8,
  },
  {
    value: 0.9,
  },
  {
    value: 1,
    label: '100 %',
  },
];

const OpacitySelect: React.FC<OpacitySelectProps> = ({
  currentOpacity,
  onLayerChangeOpacity,
  isEnabled = true,
  tooltipPrefix,
}: OpacitySelectProps) => {
  const { t } = useCoreTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const closeSliderPopper = (): void => setAnchorEl(null);

  const currentOpacityText = `${Math.round(currentOpacity * 100)}  %`;
  const nonEmptyTooltipPrefix =
    tooltipPrefix ?? t('layermanager-opacity-tooltip-prefix');

  const onWheel = React.useCallback(
    (event: React.WheelEvent): void => {
      if (event.ctrlKey || event.metaKey) {
        const multiplier = event.altKey ? 10 : 1;
        const direction =
          event.deltaY > 0 ? 0.01 * multiplier : -0.01 * multiplier;
        const newValue = Math.min(Math.max(currentOpacity - direction, 0), 1);

        // Kind of "one frame debouncer", meant to dampen too fast slider movement
        window.requestAnimationFrame(() => {
          onLayerChangeOpacity(newValue);
        });
      }
    },
    [currentOpacity, onLayerChangeOpacity],
  );

  const onKeyDownSlider = React.useCallback(
    (event: React.KeyboardEvent): void => {
      if (event.key === 'Tab' || event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();
        anchorEl?.focus();
        closeSliderPopper();
      }
    },
    [anchorEl],
  );

  return (
    <Box
      onWheel={onWheel}
      data-testid="scrollOpacity"
      sx={{
        width: '100%',
        height: '32px',
        minWidth: '49px',
      }}
    >
      <CustomIconButton
        tooltipTitle={`${nonEmptyTooltipPrefix}${currentOpacityText}`}
        onClick={handleClick}
        data-testid="selectOpacity"
        disableRipple
        disableFocusRipple
        sx={{
          '&.MuiIconButton-root': {
            justifyContent: 'normal',
            width: '100%!important',
            height: '100%!important',
            borderRadius: '0',
            color: isEnabled
              ? 'geowebColors.layerManager.tableRowDefaultText.rgba'
              : 'geowebColors.layerManager.tableRowDisabledText.rgba',
            ...(isOpen
              ? {
                  outline: (theme): string | number =>
                    theme.palette.geowebColors.buttons.tool.focus.outline!,
                  outlineOffset: '-1px',
                  backgroundColor: 'geowebColors.buttons.tool.mouseOver.fill',
                }
              : {}),
            '&.Mui-focusVisible': {
              outlineOffset: '-1px',
            },
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: (theme): number | string =>
              theme.palette.geowebColors.layerManager.tableRowDefaultText
                .fontSize!,
          }}
        >
          {currentOpacityText}
        </Typography>
      </CustomIconButton>
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        sx={{ zIndex: 1000 }}
        placement="bottom"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                padding: '0 0 60px 0',
                backgroundColor: 'geowebColors.background.surface',
                width: '82px',
                height: '248px',
                boxSizing: 'initial',
                boxShadow: 8,
                ...theme.palette.geowebColors.layerManager.opacitySelect.popper,
              }}
            >
              <Box sx={sliderHeaderStyle}>
                {t('layermanager-opacity-title')}
              </Box>
              <CustomSlider
                data-testid="opacitySlider"
                orientation="vertical"
                value={currentOpacity}
                step={0.01}
                max={1}
                min={0}
                marks={marks}
                onChange={(event, newValue: number | number[]): void => {
                  event.stopPropagation();
                  onLayerChangeOpacity(
                    isArray(newValue) ? newValue[0] : newValue,
                  );
                }}
                onKeyDown={onKeyDownSlider}
                onBlur={(event): void => {
                  if (event.relatedTarget !== anchorEl) {
                    closeSliderPopper();
                  }
                }}
                shouldAutoFocus
              />
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default OpacitySelect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { CSSProperties, FC } from 'react';
import { Box, Collapse, Grid2 as Grid, Input, Theme } from '@mui/material';
import { CustomIconButton, CustomTooltip } from '@opengeoweb/shared';
import {
  ChevronDown,
  ChevronUp,
  Delete,
  DragHandle,
  Visibility,
  VisibilityOff,
} from '@opengeoweb/theme';
import { ClickAwayListener } from '@mui/base';
import { TFunction } from 'i18next';
import { Parameter, Plot } from '../TimeSeries/types';
import { BUTTON_WIDTH, LEFT_ACTION_WIDTH } from './TimeSeriesManagerUtils';
import { TimeSeriesSelectButtonConnect } from '../TimeSeriesSelect/TimeSeriesSelectButtonConnect';
import { ParameterList } from './ParameterList';
import { MoveParameterPayload, UpdateTitlePayload } from '../../store/types';

export const PlotRows: FC<{
  plot: Plot;
  rowIndex: number;
  showTooltip: boolean;
  deletePlot: (id: string) => void;
  deleteParameter: (id: string) => void;
  togglePlot: (id: string) => void;
  selectPlotId: string;
  setSelectPlotId: (id: string) => void;
  toggleParameter: (id: string) => void;
  patchParameter: (parameter: Partial<Parameter>) => void;
  updateTitle: (payload: UpdateTitlePayload) => void;
  addParameter: (parameter: Parameter) => void;
  moveParameter: (event: MoveParameterPayload) => void;
  t: TFunction;
}> = ({
  plot,
  rowIndex,
  showTooltip,
  deletePlot,
  togglePlot,
  deleteParameter,
  selectPlotId,
  setSelectPlotId,
  toggleParameter,
  patchParameter,
  updateTitle,
  addParameter,
  moveParameter,
  t,
}) => {
  const [openParameterList, setOpenParameterList] = React.useState(true);
  const [editTitle, setEditTitle] = React.useState(false);
  const [plotTitleLocal, setPlotTitleLocal] = React.useState(plot.title);
  const plotIsEnabled = plot.enabled !== false;
  const styles = getStyles(plotIsEnabled);

  const plotTitleClickAway = (): void => {
    if (editTitle) {
      if (plotTitleLocal !== '' && plotTitleLocal !== plot.title) {
        updateTitle({ plotId: plot.plotId, title: plotTitleLocal });
      }
      setEditTitle(false);
    }
  };

  return (
    <Box sx={styles.plot} key={plot.plotId}>
      <Grid sx={styles.row} container>
        <Grid container sx={{ width: LEFT_ACTION_WIDTH }}>
          <Grid sx={{ width: BUTTON_WIDTH }}>
            <CustomIconButton
              shouldShowAsDisabled={!plotIsEnabled}
              data-testid={`dragHandleButton_${rowIndex}`}
              className="handle"
              tooltipTitle={showTooltip ? t('timeseries-drag') : ''}
              tabIndex={-1}
              disableTouchRipple
              sx={{
                '&.handle:hover': (theme: Theme): CSSProperties => ({
                  ...theme.palette.geowebColors.layerManager.dragHandleHover,
                }),
                cursor: 'grab',
                paddingLeft: '12px',
              }}
            >
              <DragHandle />
            </CustomIconButton>
          </Grid>
          <Grid sx={{ width: BUTTON_WIDTH }}>
            <CustomIconButton
              onClick={(): void => {
                togglePlot(plot.plotId);
              }}
              tooltipTitle={`${
                plotIsEnabled ? t('timeseries-hide') : t('timeseries-show')
              } ${plot.title}`}
              shouldShowAsDisabled={!plotIsEnabled}
              data-testid="enableButton"
            >
              {plotIsEnabled ? <Visibility /> : <VisibilityOff />}
            </CustomIconButton>
          </Grid>
        </Grid>
        <Grid className="titleCollectionWidth">
          <TimeSeriesSelectButtonConnect
            isEnabled={plotIsEnabled}
            onClick={(): void => setSelectPlotId(plot.plotId)}
            plotId={plot.plotId}
            selectPlotId={selectPlotId}
            t={t}
          />
        </Grid>
        <Grid className="titleParamterWidth">
          <ClickAwayListener onClickAway={plotTitleClickAway}>
            <Box onClick={(): void => setEditTitle(true)}>
              {editTitle ? (
                <Input
                  aria-label={t('timeseries-edit-title')}
                  inputProps={{
                    'aria-label': `${t('timeseries-edit-title')} ${plot.title}`,
                  }}
                  sx={{
                    ...styles.rowText,
                    backgroundColor: 'geowebColors.textInputField.default.rgba',
                  }}
                  onFocus={(event): void => event.target.select()}
                  onChange={(elem): void =>
                    setPlotTitleLocal(elem.target.value)
                  }
                  onKeyDown={(e): boolean | void =>
                    e.key === 'Enter' && plotTitleClickAway()
                  }
                  defaultValue={plot.title}
                />
              ) : (
                <CustomTooltip
                  title={t('timeseries-edit-title')}
                  placement="top"
                >
                  <Box
                    aria-label={`${plot.title}, ${t('timeseries-click-to-edit')}`}
                    sx={{
                      ...styles.rowText,
                      minWidth: '100%',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {plot.title}
                  </Box>
                </CustomTooltip>
              )}
            </Box>
          </ClickAwayListener>
        </Grid>
        <Grid sx={{ flexGrow: 1 }} />
        <Grid sx={{ width: BUTTON_WIDTH }}>
          <CustomIconButton
            tooltipTitle={`${t('timeseries-remove')} ${plot.title}`}
            onClick={(): void => {
              deletePlot(plot.plotId);
            }}
            shouldShowAsDisabled={!plotIsEnabled}
            data-testid="deleteButton"
          >
            <Delete />
          </CustomIconButton>
        </Grid>
        <Grid sx={{ width: BUTTON_WIDTH * 1.4 }} />
        <Grid sx={{ width: BUTTON_WIDTH }}>
          <CustomIconButton
            tooltipTitle={t('timeseries-open-param-list')}
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation();
              setOpenParameterList(!openParameterList);
            }}
            data-testid="openParameterListButton"
          >
            {openParameterList ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </Grid>
      </Grid>
      <Collapse
        sx={styles.parameter}
        in={openParameterList}
        timeout="auto"
        unmountOnExit
      >
        <ParameterList
          plot={plot}
          plotIsEnabled={plotIsEnabled}
          deleteParameter={deleteParameter}
          toggleParameter={toggleParameter}
          patchParameter={patchParameter}
          addParameter={addParameter}
          moveParameter={moveParameter}
          togglePlot={togglePlot}
          t={t}
        />
      </Collapse>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStyles = (isEnabled: boolean) => ({
  plot: {
    boxShadow: 3,
    borderRadius: 0.75,
    backgroundColor: 'geowebColors.background.surface',
    '&.sortable-ghost': {
      opacity: 0.5,
    },
  },
  row: {
    backgroundColor: isEnabled
      ? 'geowebColors.layerManager.tableRowDefaultCardContainer.fill'
      : 'geowebColors.layerManager.tableRowDisabledCardContainer.fill',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'geowebColors.cards.cardContainerBorder',
    borderRadius: 1,
    marginBottom: 0.25,
    height: 34,
    alignItems: 'center',
  },
  rowText: {
    fontSize: 14,
    fontWeight: 500,
    color: isEnabled
      ? 'geowebColors.layerManager.tableRowDefaultText.color'
      : 'geowebColors.layerManager.tableRowDisabledText.color',
  },
  parameter: {
    marginBottom: 0.25,
  },
});

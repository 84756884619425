/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  CoreAppStore,
  layerActions,
  mapSelectors,
  loadingIndicatorActions,
  genericSelectors,
  genericActions,
  syncGroupsActions,
  uiSelectors,
  mapActions,
} from '@opengeoweb/store';
import { Box, Stack } from '@mui/material';
import { LockLocked, LockUnlocked } from '@opengeoweb/theme';
import { FeatureCollection, GeometryObject } from 'geojson';
import { MapLocation, registerDrawFunction } from '@opengeoweb/webmap-react';
import { webmapUtils } from '@opengeoweb/webmap';
import { CustomTooltip } from '@opengeoweb/shared';
import { useTimeseriesTranslation } from '../../utils/i18n';
import {
  GeoJsonWithService,
  Parameter,
  PlotPreset,
  PointerLocation,
  TimeSeriesLocation,
  TimeSeriesPresetLocation,
} from './types';
import { TimeSeriesView } from './TimeSeriesView';
import { LocationSelect } from './LocationSelect';
import { getPlotState, getServices } from '../../store/selectors';
import {
  circleDrawFunction,
  createGeojson,
  createGeoJsonArrays,
  drawFunctionShowHoveredInfo,
} from './utils';
import { getLocations } from '../../utils/edrUtils';
import { TimeSeriesTopRowButton } from './TimeSeriesTopRowButton';
import { TimeSeriesModuleState } from '../../store/types';
import TimeSeriesManagerMapButtonConnect from '../TimeSeriesManager/TimeSeriesManagerMapButtonConnect';

interface TimeSeriesConnectProps {
  panelId: string;
  timeSeriesPresetLocations?: TimeSeriesPresetLocation[];
}

export const getUniqueServiceAndCollectionIds = (
  parameters: Parameter[],
): { serviceId: string; collectionId: string }[] => {
  return parameters.length
    ? parameters
        .map(({ serviceId, collectionId }) => ({ serviceId, collectionId }))
        // Remove duplicates
        .filter(
          (current, index, array) =>
            index ===
            array.findIndex(
              (ids) =>
                ids.serviceId === current.serviceId &&
                ids.collectionId === current.collectionId,
            ),
        )
    : [];
};

export const TimeSeriesConnect: React.FC<TimeSeriesConnectProps> = ({
  timeSeriesPresetLocations,
  panelId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTimeseriesTranslation();

  const [splitGeoJson, setSplitGeoJson] = useState<GeoJsonWithService[]>();
  const [geojsonLayerId, setGeojsonLayerId] = useState<string | undefined>();
  const [geojson, setGeojson] = useState<FeatureCollection<GeometryObject>>();
  const [selectedLocation, setSelectedLocation] = React.useState('');

  const circleDrawFunctionId = useRef(registerDrawFunction(circleDrawFunction));

  const hoverDrawFunctionId = useRef(
    registerDrawFunction(drawFunctionShowHoveredInfo),
  );

  const services = useSelector((state: TimeSeriesModuleState) =>
    getServices(state),
  );
  const plotPresetState: PlotPreset | undefined = useSelector(getPlotState);
  const selectedMapIds = useSelector((state: CoreAppStore) =>
    genericSelectors.getLinkedMaps(state, panelId),
  );

  const activeMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getActiveWindowId(store),
  );

  const selectedFeature = useSelector((state: CoreAppStore) =>
    genericSelectors.getSelectedFeature(state.syncGroups!, panelId),
  );

  const [timeSeriesLocation, setTimeSeriesLocation] = useState<
    PointerLocation | undefined
  >();

  const allMaps = useSelector((store: CoreAppStore) =>
    mapSelectors.getAllMapsByIds(store),
  );

  const [disableMapPin, setDisableMapPin] = useState<boolean>(false);

  const isLoading = useCallback(
    (startOrFinish: boolean): void => {
      dispatch(
        loadingIndicatorActions.setEDRIsLoading({
          id: panelId,
          isEDRLoading: startOrFinish,
        }),
      );
    },
    [dispatch, panelId],
  );

  const memoizedSelectedFeature = React.useMemo(() => {
    if (!selectedFeature) {
      return null;
    }

    return {
      lat: selectedFeature.lat,
      lon: selectedFeature.lon,
      id: selectedFeature.id,
      serviceId: selectedFeature.serviceId,
      collectionId: selectedFeature.collectionId,
      name: selectedFeature.name,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature?.lat, selectedFeature?.lon]);

  useEffect(() => {
    if (!memoizedSelectedFeature && activeMapId) {
      const activeMapCoordinates = allMaps[activeMapId]?.mapPinLocation;
      if (
        activeMapCoordinates?.lat !== undefined &&
        activeMapCoordinates?.lon !== undefined
      ) {
        setTimeSeriesLocation(activeMapCoordinates);
        setSelectedLocation('Location');
      }
      return;
    }

    if (memoizedSelectedFeature) {
      const validLocation: TimeSeriesLocation = {
        lat: memoizedSelectedFeature.lat,
        lon: memoizedSelectedFeature.lon,
        id: memoizedSelectedFeature.id,
        serviceId: memoizedSelectedFeature.serviceId,
        collectionId: memoizedSelectedFeature.collectionId,
      };

      setTimeSeriesLocation(validLocation);
      setSelectedLocation(selectedFeature?.name || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeMapId,
    selectedFeature,
    selectedMapIds,
    setTimeSeriesLocation,
    setSelectedLocation,
    memoizedSelectedFeature,
    allMaps,
  ]);

  useEffect(() => {
    if (!geojsonLayerId) {
      const newLayerId = webmapUtils.generateLayerId();
      setGeojsonLayerId(newLayerId);
    }
  }, [geojsonLayerId]);

  const drawGeojson = useCallback(
    (geojson: FeatureCollection<GeometryObject>): void => {
      setGeojson(geojson);
      dispatch(
        layerActions.layerChangeGeojson({
          geojson,
          layerId: geojsonLayerId!,
        }),
      );
    },
    [dispatch, geojsonLayerId],
  );

  function consolidateFeatures(
    features: {
      id: string;
      geoJSON: FeatureCollection<GeometryObject>;
    }[],
  ): FeatureCollection<GeometryObject> {
    const fuseFeatures = features.flatMap(
      (feature) => feature.geoJSON.features,
    );

    return {
      type: 'FeatureCollection',
      features: fuseFeatures,
    };
  }

  useEffect(
    () => (): void => {
      if (panelId) {
        dispatch(syncGroupsActions.deleteSharedData({ panelId }));
      }
    },
    [dispatch, panelId],
  );

  useEffect(() => {
    if (selectedMapIds.length > 0) {
      if (
        services &&
        services[0]?.type === 'OGC' &&
        timeSeriesPresetLocations
      ) {
        const geojson = createGeojson(
          timeSeriesPresetLocations,
          circleDrawFunctionId.current,
          hoverDrawFunctionId.current,
        );

        const { combinedGeoJson, splitGeoJsonByCollection } =
          createGeoJsonArrays([geojson], services, 'isOgc');

        drawGeojson(combinedGeoJson);
        setSplitGeoJson(splitGeoJsonByCollection);

        const consolidatedGeoJSON = consolidateFeatures([
          {
            id: 'combined-layer',
            geoJSON: combinedGeoJson,
          },
        ]);

        dispatch(
          genericActions.addSharedData({
            panelId,
            data: {
              features: [
                {
                  id: geojsonLayerId!,
                  geoJSON: consolidatedGeoJSON,
                },
              ],
            },
          }),
        );
      } else if (services) {
        const serviceAndCollectionIds = getUniqueServiceAndCollectionIds(
          plotPresetState?.parameters || [],
        );

        const locationPromises = serviceAndCollectionIds.flatMap(
          ({ serviceId, collectionId }) => {
            const service = services.find(
              (service) => service.id === serviceId,
            );
            if (!service) {
              return [];
            }
            return getLocations(
              service,
              collectionId,
              circleDrawFunctionId.current,
              hoverDrawFunctionId.current,
            ).catch(() => undefined);
          },
        );

        void Promise.all(locationPromises)
          .then((locations) => {
            const { combinedGeoJson, splitGeoJsonByCollection } =
              createGeoJsonArrays(locations, services);

            if (combinedGeoJson.features.length === 0) {
              setSelectedLocation('');
            }
            drawGeojson(combinedGeoJson);
            setSplitGeoJson(splitGeoJsonByCollection);

            const consolidatedGeoJSON = consolidateFeatures([
              {
                id: 'combined-layer',
                geoJSON: combinedGeoJson,
              },
            ]);

            dispatch(
              genericActions.addSharedData({
                panelId,
                data: {
                  features: [
                    {
                      id: geojsonLayerId!,
                      geoJSON: consolidatedGeoJSON,
                    },
                  ],
                },
              }),
            );
          })
          .finally(() => {
            isLoading(false);
          });
      }
    } else if (geojsonLayerId) {
      selectedMapIds.forEach((mapId) => {
        dispatch(
          layerActions.layerDelete({
            mapId,
            layerId: geojsonLayerId,
          }),
        );
      });
    }
  }, [
    geojsonLayerId,
    selectedMapIds,
    dispatch,
    services,
    plotPresetState,
    timeSeriesPresetLocations,
    isLoading,
    panelId,
    drawGeojson,
  ]);

  /* Make sure the mappin is visible when linking new maps */
  useEffect(() => {
    selectedMapIds.forEach((mapId) => {
      dispatch(
        mapActions.toggleMapPinIsVisible({
          mapId,
          displayMapPin: true,
        }),
      );
    });
  }, [dispatch, selectedMapIds]);

  const toggleDisableMapPin = (): void => {
    const newDisableMapPinState = !disableMapPin;
    setDisableMapPin(newDisableMapPinState);

    selectedMapIds.forEach((mapId) => {
      dispatch(
        mapActions.setDisableMapPin({
          mapId,
          disableMapPin: newDisableMapPinState,
        }),
      );
    });
  };

  const setMapPinLocation = (location: MapLocation): void => {
    selectedMapIds.forEach((mapId) => {
      dispatch(
        mapActions.setMapPinLocation({
          mapId,
          mapPinLocation: location,
        }),
      );
    });
  };

  const handleFeatureSelect = (panelId: string, featureId: string): void => {
    dispatch(
      genericActions.addSharedData({
        panelId,
        data: { selectedFeatureId: featureId || '' },
      }),
    );
  };

  const setSelectedFeatureIndex = (selectedFeatureIndex: number): void => {
    dispatch(
      layerActions.setSelectedFeature({
        layerId: geojsonLayerId!,
        selectedFeatureIndex,
      }),
    );
  };

  return (
    <Stack
      sx={{
        padding: '20px',
        width: '100%',
        height: '100%',
        backgroundColor: 'geowebColors.background.surfaceApp',
        containerType: 'inline-size',
        containerName: 'time-series-container',
      }}
      data-testid="TimeSeriesConnect"
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          maxWidth: '400px',
          marginBottom: '4px',
          marginLeft: '10px',
        }}
      >
        <TimeSeriesManagerMapButtonConnect viewId={panelId} />

        {geojson && (
          <LocationSelect
            splitGeoJson={splitGeoJson!}
            selectedLocation={selectedLocation}
            disableMapPin={disableMapPin!}
            geojson={geojson}
            panelId={panelId}
            selectedFeature={selectedFeature!}
            setMapPinLocation={setMapPinLocation}
            setSelectedFeatureIndex={setSelectedFeatureIndex}
            setSelectedLocation={setSelectedLocation}
            setTimeSeriesLocation={setTimeSeriesLocation}
            featureSelect={handleFeatureSelect}
            t={t}
          />
        )}

        <CustomTooltip
          title={
            disableMapPin
              ? t('timeseries-unlock-location')
              : t('timeseries-lock-location')
          }
        >
          <TimeSeriesTopRowButton
            onClick={(): void => toggleDisableMapPin()}
            selected={disableMapPin}
            data-testid="toggleLockLocationButton"
            icon={disableMapPin ? <LockLocked /> : <LockUnlocked />}
            longText={
              disableMapPin
                ? t('timeseries-unlock-location')
                : t('timeseries-lock-location')
            }
            shortText={
              disableMapPin ? t('timeseries-unlock') : t('timeseries-lock')
            }
          />
        </CustomTooltip>
      </Stack>
      {timeSeriesLocation && plotPresetState && (
        <Box sx={{ overflow: 'auto' }}>
          {services && (
            <TimeSeriesView
              plotPreset={plotPresetState}
              selectedLocation={timeSeriesLocation}
              services={services}
              splitGeoJsonByCollection={splitGeoJson || []}
              isLoading={isLoading}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};

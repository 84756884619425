/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Backdrop } from '@mui/material';
import React from 'react';

import { BackdropStyle, BackdropElement } from '@opengeoweb/theme';
import { WorkspaceSelectListConnect } from '../WorkspaceSelectList/WorkspaceSelectListConnect';
import { useWorkspaceTranslation } from '../../utils/i18n';
import { ResizeMenu } from '../ResizeMenu';

export interface WorkspaceMenuProps {
  hideBackdrop?: boolean;
  closeMenu?: () => void;
}

export const WorkspaceMenu: React.FC<WorkspaceMenuProps> = ({
  hideBackdrop = false,
  closeMenu,
}: WorkspaceMenuProps) => {
  const { t } = useWorkspaceTranslation();
  const toolContainer = (
    <ResizeMenu title={t('workspace-menu')} onCloseMenu={closeMenu}>
      <WorkspaceSelectListConnect />
    </ResizeMenu>
  );

  return hideBackdrop ? ( // Enable hiding backdrop from snapshot tests
    toolContainer
  ) : (
    <Backdrop
      data-testid="workspaceMenuBackdrop"
      open
      sx={{
        '&.MuiBackdrop-root': {
          top: '40px',
        },
        ...BackdropStyle(),
      }}
      onClick={closeMenu}
    >
      {BackdropElement()}
      {toolContainer}
    </Backdrop>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { uiActions, uiTypes } from '@opengeoweb/store';
import { AnyAction } from '@reduxjs/toolkit';
import {
  getLeaves,
  createBalancedTreeFromLeaves,
  MosaicNode,
} from 'react-mosaic-component';

export const getUniqueId = (existingIds: string[], count = 1): string => {
  const newId = `${count}_screen`;
  if (existingIds.indexOf(newId) !== -1) {
    return getUniqueId(existingIds, count + 1);
  }
  return newId;
};

export const createBalancedMosaicNode = (
  currentNode: MosaicNode<string>,
): MosaicNode<string> => {
  const leaves = getLeaves(currentNode);
  return createBalancedTreeFromLeaves(leaves)!;
};

export const closeTimeSeriesDialogsWhenChangingPreset = (
  componentTypes: string[] | undefined,
  dispatch: (action: AnyAction) => void,
): void => {
  if (componentTypes) {
    if (
      componentTypes.length > 0 &&
      !componentTypes.some((componentType) =>
        componentType.toLowerCase().includes('viewloading'),
      ) &&
      !componentTypes.some((componentType) =>
        componentType.toLowerCase().includes('timeseries'),
      )
    ) {
      dispatch(
        uiActions.setToggleOpenDialog({
          setOpen: false,
          type: uiTypes.DialogTypes.TimeSeriesManager,
        }),
      );
      dispatch(
        uiActions.setToggleOpenDialog({
          setOpen: false,
          type: uiTypes.DialogTypes.TimeSeriesSelect,
        }),
      );
      dispatch(
        uiActions.setToggleOpenDialog({
          setOpen: false,
          type: uiTypes.DialogTypes.TimeseriesInfo,
        }),
      );
    }
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { TextField, InputAdornment } from '@mui/material';
import * as React from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SHARED_NAMESPACE } from '../../utils/i18n';
import { SearchFieldButton, isURL } from './SearchFieldButtonContainer';

interface SearchFieldProps {
  searchFilter: string;
  label?: string;
  setSearchFilter: (filterText: string) => void;
  onOpenServicePopup: (url: string) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  searchFilter,
  label,
  setSearchFilter,
  onOpenServicePopup,
}: SearchFieldProps) => {
  const [localSearchString, setLocalSearchString] = React.useState('');
  const [urlIsValid, setUrlIsValid] = React.useState(false);
  const { t } = useTranslation(SHARED_NAMESPACE);
  const translatedLabel = label || t('shared-search-label');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearchFilterDebounced = React.useCallback(
    debounce(setSearchFilter, 300),
    [],
  );

  const handleChange = (text: string): void => {
    setLocalSearchString(text);
    if (!isURL(text)) {
      setSearchFilterDebounced(text);
    } else if (searchFilter) {
      setSearchFilterDebounced('');
    }
  };

  React.useEffect(() => {
    if (isURL(localSearchString)) {
      setUrlIsValid(true);
    }
  }, [localSearchString]);

  React.useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent): void => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (urlIsValid) {
          onOpenServicePopup(localSearchString);
          setLocalSearchString('');
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return (): void => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [urlIsValid, localSearchString, onOpenServicePopup]);

  // mirror store unless typing a URL
  React.useEffect(() => {
    if (isURL(localSearchString)) {
      return;
    }
    setLocalSearchString(searchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  return (
    <TextField
      variant="filled"
      label={translatedLabel}
      value={localSearchString}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        handleChange(e.target.value);
      }}
      autoFocus
      fullWidth
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchFieldButton
                setPopupIsOpen={(): void => {
                  onOpenServicePopup(localSearchString);
                  setLocalSearchString('');
                }}
                localSearchString={localSearchString}
                onClickClear={(): void => {
                  setLocalSearchString('');
                  setSearchFilterDebounced('');
                  setUrlIsValid(false);
                }}
              />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

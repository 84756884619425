/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { debounce, DebouncedFunc, throttle } from 'lodash';

import WMBBOX from './WMBBOX';
import type WMJSMap from './WMJSMap';

const SCALE_DELAY = 10;
const ZOOMED_CALLBACK_DELAY = 500;
const THROTTLE_OPTIONS = {
  trailing: false,
};
const THROTTLE_WAIT_TIME = 300;

export default class WMFlyToBBox {
  private wmMap: WMJSMap;
  private flyZoomToBBOXTimerStart = 1;
  private flyZoomToBBOXTimerSteps = 5;
  private flyZoomToBBOXTimerLoop?: number;
  private flyZoomToBBOXDebounced: DebouncedFunc<() => void>;
  private flyZoomToBBOXScaler = 0;
  private flyZoomToBBOXCurrent = new WMBBOX();
  public flyZoomToBBOXFly = new WMBBOX();
  private flyZoomToBBOXNew = new WMBBOX();
  private flyZoomToBBOXContinueNew = new WMBBOX();
  private flyZoomToBBOXTimerFuncBusy = 0;
  private flyZoomToBBOXTimerFuncBusyAndContinue = 0;

  constructor(wmMap: WMJSMap) {
    this.wmMap = wmMap;

    this.flyZoomToBBOXTimerFuncReadyDebounced = debounce(
      this.flyZoomToBBOXTimerFuncReadyDebounced,
      ZOOMED_CALLBACK_DELAY,
    );

    this.flyZoomToBBOXDebounced = debounce(
      this.flyZoomToBBOXTimerFunc,
      SCALE_DELAY,
    );
  }

  flyZoomToBBOXTimerFuncReadyDebounced = (): void => {
    this.wmMap.setBBOX(this.flyZoomToBBOXFly);
    this.wmMap._display();
    this.wmMap.draw('flyZoomToBBOXTimerFunc');
  };

  flyZoomToBBOXTimerFunc = (): void => {
    this.flyZoomToBBOXScaler =
      this.flyZoomToBBOXTimerLoop! / this.flyZoomToBBOXTimerSteps;
    const z1 = 1 - this.flyZoomToBBOXScaler;
    this.flyZoomToBBOXFly.left =
      this.flyZoomToBBOXCurrent.left * z1 +
      this.flyZoomToBBOXNew.left * this.flyZoomToBBOXScaler;
    this.flyZoomToBBOXFly.bottom =
      this.flyZoomToBBOXCurrent.bottom * z1 +
      this.flyZoomToBBOXNew.bottom * this.flyZoomToBBOXScaler;
    this.flyZoomToBBOXFly.right =
      this.flyZoomToBBOXCurrent.right * z1 +
      this.flyZoomToBBOXNew.right * this.flyZoomToBBOXScaler;
    this.flyZoomToBBOXFly.top =
      this.flyZoomToBBOXCurrent.top * z1 +
      this.flyZoomToBBOXNew.top * this.flyZoomToBBOXScaler;
    this.wmMap._updateBoundingBox(this.flyZoomToBBOXFly, false);

    this.flyZoomToBBOXTimerLoop! += 1;

    if (this.flyZoomToBBOXTimerLoop! > this.flyZoomToBBOXTimerSteps) {
      this.flyZoomToBBOXTimerLoop = this.flyZoomToBBOXTimerStart;
      this.wmMap._updateBBOX.setBBOX(this.flyZoomToBBOXFly);
      this.wmMap._drawnBBOX.setBBOX(this.flyZoomToBBOXFly);
      this.flyZoomToBBOXTimerFuncReadyDebounced();

      if (this.flyZoomToBBOXTimerFuncBusyAndContinue === 0) {
        this.flyZoomToBBOXTimerFuncBusyAndContinue = 0;
        this.flyZoomToBBOXTimerFuncBusy = 0;
        this.wmMap.draw('flyZoomToBBOXTimerFunc');
      } else {
        this.flyZoomToBBOXTimerFuncBusyAndContinue = 0;
        this.flyZoomToBBOXTimerFuncBusy = 0;
        this.flyZoomToBBOXStartZoom(
          this.wmMap._updateBBOX,
          this.flyZoomToBBOXContinueNew,
        );
      }
      return;
    }
    this.flyZoomToBBOXDebounced();
  };

  resetBBoxTimer = (): void => {
    this.flyZoomToBBOXDebounced.cancel();
    this.flyZoomToBBOXDebounced = debounce(
      this.flyZoomToBBOXTimerFunc,
      SCALE_DELAY,
    );
  };

  flyZoomToBBOXStop = (): void => {
    this.wmMap.setBBOX(this.flyZoomToBBOXFly);
    this.flyZoomToBBOXTimerFuncBusyAndContinue = 0;
    this.flyZoomToBBOXTimerFuncBusy = 0;
    this.resetBBoxTimer();
  };

  flyZoomToBBOXStartZoom = (currentbox: WMBBOX, newbox: WMBBOX): void => {
    if (this.flyZoomToBBOXTimerFuncBusy === 1) {
      this.flyZoomToBBOXContinueNew.copy(newbox);
      this.flyZoomToBBOXTimerFuncBusyAndContinue = 1;
      return;
    }
    this.flyZoomToBBOXCurrent.copy(currentbox);
    this.flyZoomToBBOXNew.copy(newbox);
    this.flyZoomToBBOXTimerLoop = this.flyZoomToBBOXTimerStart;
    this.flyZoomToBBOXTimerFuncBusyAndContinue = 0;
    if (this.flyZoomToBBOXTimerFuncBusy === 0) {
      this.flyZoomToBBOXTimerFuncBusy = 1;
      this.flyZoomToBBOXTimerFunc();
    }
  };

  startZoomThrottled = throttle(
    (currentbox: WMBBOX, newbox: WMBBOX) => {
      this.flyZoomToBBOXStartZoom(currentbox, newbox);
    },
    THROTTLE_WAIT_TIME,
    THROTTLE_OPTIONS,
  );

  flyZoomToBBOXStartZoomThrottled = (
    currentbox: WMBBOX,
    newbox: WMBBOX,
  ): void => {
    this.startZoomThrottled(currentbox, newbox);
  };
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import type IWMJSMap from './IWMJSMap';
import {
  isDefined,
  WMJScheckURL,
  URLDecode,
  URLEncode,
  toArray,
  debugLogger,
  DebugType,
  getUriWithParam,
} from './WMJSTools';
import {
  parseISO8601DateToDate,
  DateInterval,
  parseISO8601IntervalToDateInterval,
  ParseISOTimeRangeDuration,
} from './WMTime';
import WMImage from './WMImage';
import { getLegendGraphicURLForLayer, legendImageStore } from './WMLegend';
import { WMImageEventType } from './WMImageStore';
import WMListener from './WMListener';
import WMJSMap from './WMJSMap';
import WMProjection from './WMProjection';
import { LayerType } from './WMLayerTypes';
import { WMXMLStringToJson } from './WMXMLParser';

export {
  default as WMJSDimension,
  handleDateUtilsISOString,
} from './WMJSDimension';

export { default as WMLayer } from './WMLayer';
export type { LayerOptions, LayerFoundation } from './WMLayerTypes';
export { LayerType };
export { default as WMBBOX } from './WMBBOX';
export type { Bbox } from './WMBBOX';
export type { WMProjectionWH } from './WMProjection';
export { WMProjection };
export {
  IWMJSMap,
  WMJSMap,
  DateInterval,
  parseISO8601IntervalToDateInterval,
  ParseISOTimeRangeDuration,
  isDefined,
  WMJScheckURL,
  URLEncode,
  URLDecode,
  parseISO8601DateToDate,
  toArray,
  getLegendGraphicURLForLayer,
  legendImageStore,
  WMImage,
  debugLogger,
  DebugType,
  WMImageEventType,
  getUriWithParam,
  WMListener,
  WMXMLStringToJson,
};

export * from './types';
export * from './WMTimeTypes';
export * from './WMConstants';
export * from './WMJSMapHelpers';
export * from './WMCanvasDrawFunctions';

const wmServiceListener = new WMListener();
export { wmServiceListener };

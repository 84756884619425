/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { useSelector } from 'react-redux';
import {
  CoreAppStore,
  layerSelectors,
  serviceSelectors,
} from '@opengeoweb/store';

import { renderCounter } from '@opengeoweb/shared';
import LayerRow from './LayerRow';
import DeleteLayerConnect from './DeleteLayer/DeleteLayerConnect';
import EnableLayerConnect from './EnableLayer/EnableLayerConnect';
import OpacityLayerConnect from './OpacitySelect/OpacitySelectConnect';
import DimensionSelectConnect from './DimensionSelect/DimensionSelectConnect';
import RenderStylesConnect from './RenderStyles/RenderStylesConnect';
import RenderLayersConnect from './RenderLayers/RenderLayersConnect';
import LayerManagerMenuButtonConnect from './Menubutton/MenuButtonConnect';
import ActivateLayerConnect from './ActivateLayer/ActivateLayerConnect';
import { LayerManagerCustomSettings } from '../../LayerManagerUtils';
import { AcceptanceTimeConnect } from './AcceptanceTime/AcceptanceTimeConnect';
import { MissingDataConnect } from './MissingData/MissingDataConnect';
import CustomDimensionSelectConnect from './CustomDimensionSelect/CustomDimensionSelectConnect';
import { LayerInfoButtonConnect } from '../../../LayerInfoConnect';

interface LayerRowConnectProps {
  layerId: string;
  mapId: string;
  dragHandle: React.ReactElement;
  settings?: LayerManagerCustomSettings['content'];
  collapsedColumns?: Record<string, boolean>;
}

// Whenever a new layer is added index is updated in the dragHandle, which modifies the testing text. Below we ignore this text, as it serves no other purpose.
const areEqual = (
  prevProps: LayerRowConnectProps,
  nextProps: LayerRowConnectProps,
): boolean => {
  if (
    prevProps.layerId === nextProps.layerId &&
    prevProps.mapId === nextProps.mapId &&
    prevProps.settings === nextProps.settings &&
    prevProps.dragHandle.props.isDisabled ===
      nextProps.dragHandle.props.isDisabled &&
    prevProps.dragHandle.props.toolTipTitle ===
      nextProps.dragHandle.props.toolTipTitle &&
    prevProps.collapsedColumns === nextProps.collapsedColumns
  ) {
    return true;
  }
  return false;
};

const LayerRowConnect: React.FC<LayerRowConnectProps> = React.memo(
  ({
    layerId,
    mapId,
    dragHandle,
    settings,
    collapsedColumns,
  }: LayerRowConnectProps) => {
    const isEnabled = useSelector((store: CoreAppStore) =>
      layerSelectors.getLayerEnabled(store, layerId),
    );
    const layerName = useSelector((store: CoreAppStore) =>
      layerSelectors.getLayerName(store, layerId),
    );
    const layerServiceUrl = useSelector((store: CoreAppStore) =>
      layerSelectors.getLayerService(store, layerId),
    );
    const serviceLayer = useSelector((store: CoreAppStore) =>
      serviceSelectors.getLayerFromService(store, layerServiceUrl, layerName),
    );

    const currentlySelectedLayer = useSelector((store: CoreAppStore) => {
      return layerSelectors.getLayerByIdWithoutTimeDimension(store, layerId);
    });

    const layerWithAllData = useSelector((store: CoreAppStore) => {
      return serviceSelectors.getLayerFromService(
        store,
        currentlySelectedLayer?.service!,
        currentlySelectedLayer?.name!,
      );
    });

    const currentService = useSelector((store: CoreAppStore) => {
      return serviceSelectors.getServiceByUrl(store, layerServiceUrl);
    });

    const fullLayerName = serviceLayer?.title;
    renderCounter.count(LayerRowConnect.name);
    return (
      <LayerRow
        isEnabled={isEnabled}
        mapId={mapId}
        layerId={layerId}
        isLayerMissing={!serviceLayer}
        layerName={fullLayerName || layerName}
        layerEnableLayout={
          <EnableLayerConnect
            layerId={layerId}
            mapId={mapId}
            isEnabled={isEnabled}
            layerName={fullLayerName || layerName}
            icon={
              isEnabled
                ? settings?.enableLayer?.enabledIcon
                : settings?.enableLayer?.disabledIcon
            }
            tooltipTitle={
              isEnabled
                ? settings?.enableLayer?.enabledTooltipTitle
                : settings?.enableLayer?.disabledTooltipTitle
            }
          />
        }
        layerOpacityLayout={
          <OpacityLayerConnect
            layerId={layerId}
            mapId={mapId}
            tooltipPrefix={settings?.opacity?.tooltipPrefix}
          />
        }
        layerServicesLayout={
          settings?.renderLayer?.Element ? (
            <settings.renderLayer.Element layerId={layerId} mapId={mapId} />
          ) : (
            <RenderLayersConnect
              layerId={layerId}
              mapId={mapId}
              tooltipPrefix={settings?.renderLayer?.tooltipPrefix}
              skipLocalStyling={true}
            />
          )
        }
        layerDimensionLayout={
          settings?.dimensions ? (
            <CustomDimensionSelectConnect
              layerId={layerId}
              mapId={mapId}
              {...settings?.dimensions}
            />
          ) : (
            <DimensionSelectConnect
              layerId={layerId}
              mapId={mapId}
              skipLocalStyling={true}
            />
          )
        }
        layerStylesLayout={
          <RenderStylesConnect
            layerId={layerId}
            mapId={mapId}
            skipLocalStyling={true}
            {...settings?.layerStyle}
          />
        }
        layerAcceptanceTimeLayout={
          <AcceptanceTimeConnect
            layerId={layerId}
            skipLocalStyling={true}
            {...settings?.acceptanceTime}
          />
        }
        layerMissingDataLayout={
          <MissingDataConnect mapId={mapId} layerId={layerId} />
        }
        layerDeleteLayout={
          <DeleteLayerConnect
            mapId={mapId}
            layerId={layerId}
            {...settings?.deleteLayer}
          />
        }
        layerShowLayerInfoLayout={
          <LayerInfoButtonConnect
            isLayerMissing={!serviceLayer}
            mapId={mapId}
            serviceUrl={currentService?.serviceUrl!}
            layerName={layerWithAllData?.name || ''}
          />
        }
        layerMenuLayout={
          <LayerManagerMenuButtonConnect
            mapId={mapId}
            layerId={layerId}
            {...settings?.menu}
          />
        }
        layerActiveLayout={
          settings?.activateLayer?.isDisabled ? undefined : (
            <ActivateLayerConnect mapId={mapId} layerId={layerId} />
          )
        }
        disableActivateLayer={settings?.activateLayer?.isDisabled}
        dragHandle={dragHandle}
        collapsedColumns={collapsedColumns}
      />
    );
  },
  areEqual,
);

export default LayerRowConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { ApiModule, ApiProvider, ApiUrls } from '@opengeoweb/api';
import { ErrorBoundary } from '@opengeoweb/shared';
import { createApi } from '../../utils/api';
import { DownloadSigmetAirmetConfigWrapper } from '../MetInfoWrapper';
import { ProductType } from '../../types';

export interface ApiModuleProps extends ApiModule {
  config: ApiUrls;
  productType: ProductType;
}

export const AviationProductApiWrapper: React.FC<ApiModuleProps> = ({
  config,
  auth,
  onSetAuth,
  productType,
}: ApiModuleProps) => {
  return config ? (
    <ApiProvider
      config={{
        baseURL: config.baseURL,
        appURL: config.appURL,
        authTokenURL: config.authTokenURL,
        authClientId: config.authClientId,
      }}
      auth={auth}
      onSetAuth={onSetAuth}
      createApi={createApi}
    >
      <ErrorBoundary>
        <DownloadSigmetAirmetConfigWrapper productType={productType} />
      </ErrorBoundary>
    </ApiProvider>
  ) : null;
};

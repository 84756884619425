/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Tabs, Tab, SxProps } from '@mui/material';
import {
  ReactHookFormTextField,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useWarningsTranslation } from '../../../utils/i18n';

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const tabStyle: SxProps = {
  width: '50%',
  fontSize: '0.875',
  justifyContent: 'flex-end',
  color: 'geowebColors.typographyAndIcons.text',
  maxWidth: 'initial',
  '&.Mui-selected.Mui-disabled': {
    color: 'text.disabled',
  },
};

const CustomTabPanel: React.FC<{
  children: React.ReactNode;
  value: number;
  index: number;
}> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`tab-${index}`}
      style={{ position: 'relative' }}
      {...other}
    >
      {children}
    </div>
  );
};

export const DescriptionField: React.FC<{
  isDisabled?: boolean;
  isReadOnly?: boolean;
}> = ({ isDisabled = false, isReadOnly = false }) => {
  const { t } = useWarningsTranslation();
  const { isRequired } = useDraftFormHelpers();
  const { formState } = useFormContext();
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setActiveTab(newValue);
  };

  const lastSubmitCount = useRef(0);

  // next code shows the tab for the textarea which is in the invalid state
  useEffect(() => {
    if (formState.submitCount === lastSubmitCount.current) {
      return;
    }
    lastSubmitCount.current = formState.submitCount;

    if ('descriptionOriginal' in formState.errors) {
      setActiveTab(0);
    } else if ('descriptionTranslation' in formState.errors) {
      setActiveTab(1);
    }
  }, [formState.errors, formState.submitCount]);

  const focusForwards = (fieldName: string): void => {
    if (fieldName === 'descriptionOriginal') {
      setActiveTab(1);
    }
  };

  const focusBackwards = (fieldName: string): void => {
    if (fieldName === 'descriptionTranslation') {
      setActiveTab(0);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    const { name } = target;
    event.stopPropagation();

    if (event.code === 'Tab' && event.shiftKey) {
      focusBackwards(name);
    } else if (event.code === 'Tab') {
      focusForwards(name);
    }
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="description tabs"
        {...(isDisabled && {
          TabIndicatorProps: {
            style: { display: 'none' },
            'aria-disabled': true,
          },
        })}
      >
        <Tab
          sx={tabStyle}
          label={t('warning-description-original')}
          disabled={isDisabled}
          tabIndex={-1}
          {...a11yProps(0)}
        />

        <Tab
          sx={tabStyle}
          label={t('warning-description-translation')}
          disabled={isDisabled}
          tabIndex={-1}
          {...a11yProps(1)}
        />
      </Tabs>

      <CustomTabPanel value={activeTab} index={0}>
        <ReactHookFormTextField
          rules={{
            validate: {
              isRequired,
            },
          }}
          minRows={4}
          name="descriptionOriginal"
          multiline
          disabled={isDisabled}
          isReadOnly={isReadOnly}
          onKeyDown={onKeyDown}
        />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <ReactHookFormTextField
          rules={{
            validate: {
              isRequired,
            },
          }}
          minRows={4}
          name="descriptionTranslation"
          multiline
          disabled={isDisabled}
          isReadOnly={isReadOnly}
          onKeyDown={onKeyDown}
        />
      </CustomTabPanel>
    </>
  );
};

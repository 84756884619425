/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import { Close } from '@opengeoweb/theme';
import { ConfigType, CustomIconButton } from '@opengeoweb/shared';
import { useGeowebTranslation } from '../../i18n';

const styles = {
  paper: {
    position: 'absolute',
    top: '60px',
    right: '0',
    marginTop: '12px',
    marginRight: '24px',
    boxShadow:
      '0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)',
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  close: {
    marginTop: '8px',
    marginRight: '8px',
    alignSelf: 'flex-end',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px 32px 24px',
  },
  message: {
    marginTop: '16px',
    marginBottom: '16px',
    lineHeight: 1.75,
  },
  versionmenu: {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    overflowWwrap: `break-word`,
  },
  button: {
    textTransform: 'none',
  },
};

interface ReleaseNotesProps {
  version: string;
  config?: ConfigType;
  releasePage: string;
  versionProgressNotesPage: string;
  handleClose: () => void;
}

const ReleaseNotes: React.FC<ReleaseNotesProps> = ({
  version,
  config,
  releasePage,
  versionProgressNotesPage,
  handleClose,
}: ReleaseNotesProps) => {
  const { t } = useGeowebTranslation();

  return (
    <Paper sx={styles.paper} data-testid="releaseNotes">
      <CustomIconButton
        data-testid="closeReleaseNotes"
        aria-label="close"
        onClick={(): void => handleClose()}
        sx={styles.close}
      >
        <Close />
      </CustomIconButton>
      <Box sx={styles.content}>
        <Typography variant="h5" sx={styles.versionmenu}>
          {t('geoweb-release-notes-version', { version })}
        </Typography>
        <Typography variant="caption" sx={styles.message}>
          {t('geoweb-release-notes-improvements')}
        </Typography>
        <Button
          variant="tertiary"
          data-testid="openReleasePage"
          onClick={(): void => {
            window.open(`${versionProgressNotesPage}`, '_blank');
          }}
          sx={styles.button}
        >
          {t('geoweb-progress-notes-view-full')}
        </Button>
        {config?.GW_TECHNICAL_RELEASE_NOTES_URL && (
          <>
            <hr />
            <Button
              variant="tertiary"
              data-testid="openReleasePage"
              onClick={(): void => {
                window.open(`${releasePage}`, '_blank');
              }}
              sx={styles.button}
            >
              {t('geoweb-release-notes-view-full')}
            </Button>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default ReleaseNotes;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const fakeMaxAgeResponsesByLayer: Record<string, number> = {
  'observation:wis2_air_temperature': 600,
  'fmi:observation:lightning': 300,
  'fmi:observation:temperature': 3600,
  'fmi:observation:dewpoint': 3600,
  'fmi:observation:temperature_foreign': 3600,
  'fmi:observation:dewpoint_foreign': 3600,
  'fmi:observation:cloudceiling': 3600,
  'fmi:observation:cloudheight': 3600,
  'fmi:observation:cloudheight_foreign': 3600,
  'fmi:observation:cloudceiling_foreign': 3600,
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { MenuItem, SelectChangeEvent, Theme, useTheme } from '@mui/material';
import { TooltipSelect } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import { COLOR_MAP, COLOR_NAME_TO_HEX_MAP } from '../../../constants';
import { Parameter } from '../../TimeSeries/types';

function getTextColor(bgColor?: string): string {
  if (!bgColor) {
    return '#fff';
  }
  return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2
    ? '#000'
    : '#fff';
}

const ParameterColorSelect: React.FC<{
  useEntireWidth?: boolean;
  rowIsEnabled: boolean;
  parameter: Parameter;
  patchParameter: (parameter: Partial<Parameter>) => void;
  t: TFunction;
}> = ({ useEntireWidth, rowIsEnabled, parameter, patchParameter, t }) => {
  const theme = useTheme();
  const parameterColor: string =
    parameter.color ??
    COLOR_MAP[parameter.propertyName as keyof typeof COLOR_MAP] ??
    '#000';
  const textColor = getTextColor(
    COLOR_NAME_TO_HEX_MAP[parameterColor as keyof typeof COLOR_NAME_TO_HEX_MAP],
  );

  return (
    <TooltipSelect
      SelectDisplayProps={{ 'aria-label': 'timeseriesManager-color' }}
      data-testid="parameterColorSelect"
      className="tooltipSelectWidth"
      hasBackgroundColor={false}
      value={parameterColor}
      tooltip={t('timeseries-choose-color')}
      isEnabled={rowIsEnabled}
      additionalStyling={{
        '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
          backgroundColor:
            COLOR_NAME_TO_HEX_MAP[
              parameterColor as keyof typeof COLOR_NAME_TO_HEX_MAP
            ],
          color: textColor,
          backgroundClip: useEntireWidth ? 'unset' : 'content-box',
          width: useEntireWidth ? '100%' : '24px',
          height: '32px',
          textAlign: 'center',
          paddingTop: '0px',
          paddingBottom: '0px',
        },
        '&.Mui-focused': {
          backgroundColor:
            theme.palette.geowebColors.buttons.tool.mouseOver.fill,
        },
      }}
      onChange={(event: SelectChangeEvent<unknown>): void => {
        const target = event.target as HTMLInputElement;
        patchParameter({ id: parameter.id, color: target.value });
      }}
    >
      <MenuItem disabled>{t('timeseries-color')}</MenuItem>
      {Object.keys(COLOR_NAME_TO_HEX_MAP).map((name) => {
        const textColor = getTextColor(
          COLOR_NAME_TO_HEX_MAP[name as keyof typeof COLOR_NAME_TO_HEX_MAP],
        );
        return (
          <MenuItem
            key={name}
            value={name}
            sx={{
              width: useEntireWidth ? '100%' : '100px',
              '&.MuiMenuItem-root ': {
                color: (theme: Theme): string =>
                  `${theme.palette.geowebColors.background.surface}`,
                background: `${
                  COLOR_NAME_TO_HEX_MAP[
                    name as keyof typeof COLOR_NAME_TO_HEX_MAP
                  ]
                }`,
                backgroundClip: 'content-box',
                padding: '12px 8px',
                boxShadow: 'inset -8px -12px, inset 8px 12px',
                '&>div': {
                  paddingLeft: '4px',
                },
                '&:hover': {
                  boxShadow: (theme: Theme): string =>
                    `inset -8px -12px ${theme.palette.geowebColors.cards.cardContainerMouseOver}, inset 8px 12px ${theme.palette.geowebColors.cards.cardContainerMouseOver}`,
                  backgroundColor: `${
                    COLOR_NAME_TO_HEX_MAP[
                      name as keyof typeof COLOR_NAME_TO_HEX_MAP
                    ]
                  } !important`,
                  backgroundClip: 'content-box',
                },
                '&.Mui-selected': {
                  backgroundColor: `${
                    COLOR_NAME_TO_HEX_MAP[
                      name as keyof typeof COLOR_NAME_TO_HEX_MAP
                    ]
                  } !important`,
                },
              },
            }}
          >
            <div
              style={{
                paddingTop: '8px',
                color: `${textColor}`,
                fontSize: '14px',
              }}
            >
              {name}
            </div>
          </MenuItem>
        );
      })}
    </TooltipSelect>
  );
};

export default ParameterColorSelect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { uiTypes, uiSelectors, useSetupDialog } from '@opengeoweb/store';
import { LayerSelect } from './LayerSelect';
import { KeywordFilterResultsConnect } from '../KeywordFilterResults/KeywordFilterResultsConnect';
import { ServicePopupConnect } from '../ServicePopup/ServicePopupConnect';
import { useAppSelector } from '../../store';

interface LayerSelectConnectProps {
  mapId?: string;
  bounds?: string;
  showTitle?: boolean;
  isMultiMap?: boolean;
}

/**
 * LayerSelectConnect is a component that is opened from LayerManager
 * It's used to add Services and Layers to the application
 * It doesn't expect any props, its subcomponents only read and write to redux store
 *
 * ``` <LayerSelectConnect />```
 */
export const LayerSelectConnect: React.FC<LayerSelectConnectProps> = ({
  mapId: initialMapId = null!,
  bounds,
  showTitle = false,
  isMultiMap = false,
}: LayerSelectConnectProps) => {
  const dialogType = isMultiMap
    ? `${uiTypes.DialogTypes.LayerSelect}-${initialMapId}`
    : uiTypes.DialogTypes.LayerSelect;

  const mapId = useAppSelector((store) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );

  const { dialogOrder, isDialogOpen, onCloseDialog, setDialogOrder, uiSource } =
    useSetupDialog(dialogType);

  return (
    <>
      <LayerSelect
        mapId={mapId || initialMapId}
        bounds={bounds}
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        showTitle={showTitle}
        onMouseDown={setDialogOrder}
        order={dialogOrder}
        source={uiSource}
        isMultiMap={isMultiMap}
      />
      <KeywordFilterResultsConnect
        mapId={mapId || initialMapId}
        isMultiMap={isMultiMap}
      />
      <ServicePopupConnect />
    </>
  );
};

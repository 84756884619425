/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomIconButton } from '@opengeoweb/shared';
import { Play, Pause } from '@opengeoweb/theme';
import { useTimesliderTranslation } from '../../../utils/i18n';

export interface PlayButtonProps {
  isAnimating?: boolean;
  isDisabled?: boolean;
  onTogglePlayButton?: () => void;
}

export const PlayButton: React.FC<PlayButtonProps> = ({
  isAnimating,
  isDisabled,
  onTogglePlayButton = (): void => {},
}: PlayButtonProps) => {
  const onTogglePlay = (): void => {
    onTogglePlayButton();
  };
  const { t } = useTimesliderTranslation();

  return (
    <CustomIconButton
      variant="tool"
      tooltipTitle={isAnimating ? t('timeslider-pause') : t('timeslider-play')}
      onClick={onTogglePlay}
      disabled={isDisabled}
      data-testid="playButton"
      isSelected={isAnimating}
    >
      {isAnimating ? (
        <Pause data-testid="pause-svg-path" />
      ) : (
        <Play data-testid="play-svg-path" />
      )}
    </CustomIconButton>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { createApiInstance, CreateApiProps } from '@opengeoweb/api';
import { AxiosInstance } from 'axios';
import { TAC, Taf, TafFromBackend, TafFromFrontEnd } from '../types';

export interface TafApi {
  getTafList: () => Promise<{ data: TafFromBackend[] }>;
  postTaf: (formData: TafFromFrontEnd) => Promise<void>;
  getTAC: (
    tafData: Taf,
    getJSON?: boolean,
    signal?: AbortSignal,
  ) => Promise<{ data: TAC }>;
  patchTaf: (formData: TafFromFrontEnd) => Promise<void>;
}

const getApiRoutes = (axiosInstance: AxiosInstance): TafApi => ({
  getTafList: (): Promise<{ data: TafFromBackend[] }> => {
    return axiosInstance.get('/taflist');
  },
  postTaf: (formData: TafFromFrontEnd): Promise<void> => {
    return axiosInstance.post('/taf', { ...formData });
  },
  getTAC: (
    tafData: Taf,
    getJSON?: boolean,
    signal?: AbortSignal,
  ): Promise<{ data: TAC }> =>
    axiosInstance.post('/taf2tac', tafData, {
      ...(signal ? { signal } : {}),
      headers: {
        Accept: getJSON ? 'application/json' : 'text/plain',
      },
    }),
  patchTaf: (formData: TafFromFrontEnd): Promise<void> => {
    return axiosInstance.patch('/taf?force=true', { ...formData });
  },
});

export const createApi = (props: CreateApiProps): TafApi => {
  const axiosInstance = createApiInstance(props);
  return getApiRoutes(axiosInstance);
};

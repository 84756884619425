/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { InputAdornment, SxProps, Theme } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import {
  DateTimePicker,
  DateTimePickerProps,
  DateTimePickerSlotProps,
} from '@mui/x-date-pickers';
import { dateUtils } from '@opengeoweb/shared';
import { CalendarToday } from '@opengeoweb/theme';
import ReactHookFormFormControl from './ReactHookFormFormControl';
import { ReactHookFormInput } from './types';
import { getErrors } from './formUtils';
import { defaultProps } from './utils';

const OpenPicker = (): React.ReactElement => <CalendarToday />;

const getTimezoneOffset = (value: Date): number =>
  value.getTimezoneOffset() * 60000;

const makeLocalAppearUTC = (value: Date): Date =>
  new Date(value.getTime() + getTimezoneOffset(value));

const localToUTC = (dateTime: Date): Date =>
  new Date(dateTime.getTime() - getTimezoneOffset(dateTime));

/**
 * Should return an isostring in the form "YYYY-MM-DDThh:mmZ";
 * The milli seconds section should not be included, strictly YYYY-MM-DDThh:mm:ssZ is important
 * @param value input Date
 * @returns iso string in YYYY-MM-DDThh:mm format.
 */
export const getFormattedValueForDatePicker = (value: Date): string | null => {
  return (
    dateUtils.dateToString(value, "yyyy-MM-dd'T'HH:mm:00'Z'", true) ?? null
  );
};

export const getDateValue = (value: string): Date | null => {
  if (value) {
    const dateValue =
      dateUtils.stringToDate(value, 'yyyy/MM/dd HH:mm', false) ??
      dateUtils.isoStringToDate(value, false) ??
      null;
    if (dateValue) {
      return makeLocalAppearUTC(dateUtils.setSeconds(dateValue, 0));
    }
    return null;
  }
  return null;
};

type ReactHookKeyboardDateTimePickerProps = DateTimePickerProps<Date> &
  DateTimePickerSlotProps<Date, false> &
  ReactHookFormInput<{
    defaultNullValue?: string | null;
    value?: string;
    onChange?: (value: Date | string | null) => void;
    format?: string;
    sx?: SxProps<Theme>;
  }> & { 'data-testid'?: string; shouldHideUTC?: boolean };

const ReactHookKeyboardDateTimePicker: React.FC<
  ReactHookKeyboardDateTimePickerProps
> = ({
  name,
  rules,
  disabled,
  label = 'Select date and time',
  format = dateUtils.DATE_FORMAT_DATEPICKER,
  openTo = 'hours',
  defaultNullValue = null,
  helperText = '',
  onChange = (): void => {},
  className,
  sx,
  isReadOnly,
  inputAdornment,
  shouldHideUTC,
  disablePast,
  disableFuture,
  ...otherProps
}: ReactHookKeyboardDateTimePickerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange: onChangeField, value, ref },
    formState: { errors: formErrors },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultNullValue,
    ...defaultProps,
  });

  const errors = getErrors(name, formErrors);

  // Ensure value is a Date object

  const dateValue = getDateValue(value);
  const now = makeLocalAppearUTC(dateUtils.utc());
  const minDateTime = disablePast ? now : null;
  const maxDateTime = disableFuture ? now : null;

  return (
    <ReactHookFormFormControl
      disabled={disabled}
      errors={errors}
      className={className}
      sx={sx}
      isReadOnly={isReadOnly}
    >
      <DateTimePicker
        desktopModeMediaQuery="@media (min-width: 720px)"
        ampm={false}
        format={format}
        label={label}
        value={dateValue}
        openTo={openTo}
        disabled={disabled}
        onChange={(value: Date | null): void => {
          if (!value) {
            onChange(null);
            onChangeField(null);
            return;
          }
          const formattedDate = getFormattedValueForDatePicker(
            localToUTC(value),
          );
          onChangeField(formattedDate);
          onChange(formattedDate);
        }}
        inputRef={ref}
        slotProps={{
          textField: {
            variant: 'filled',
            helperText,
            error: !!errors,
            name,
            placeholder: disabled ? '' : format.toLowerCase(),
            InputProps: {
              ...(!shouldHideUTC && {
                endAdornment: (
                  <InputAdornment style={{ paddingTop: '16px' }} position="end">
                    UTC
                  </InputAdornment>
                ),
              }),
            },

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            'data-testid': otherProps['data-testid'],
            ...(isReadOnly && {
              readOnly: true,
            }),
          },
          inputAdornment: {
            ...(inputAdornment || { position: 'start' }),
          },
          openPickerButton: {
            size: 'small',
            sx: { padding: 0 },
          },
        }}
        slots={{
          openPickerIcon: OpenPicker,
        }}
        timeSteps={{ minutes: 1 }}
        minDateTime={minDateTime!}
        maxDateTime={maxDateTime!}
        {...otherProps}
      />
    </ReactHookFormFormControl>
  );
};
export default ReactHookKeyboardDateTimePicker;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, Grid2 as Grid, Theme } from '@mui/material';
import {
  CloudLoading,
  ColumnCollapse,
  ColumnCollapsed,
} from '@opengeoweb/theme';
import * as React from 'react';
import { uiTypes } from '@opengeoweb/store';
import { CustomTooltip } from '@opengeoweb/shared';
import {
  columnClasses,
  LayerManagerCustomSettings,
  leftButtonsStyle,
} from '../LayerManagerUtils';
import { LayerSelectButton } from '../LayerSelect/LayerSelectButton';
import { useCoreTranslation } from '../../../utils/i18n';

interface RowItemProps {
  className: string;
  title: string;
  onToggleCollapsed: (name: string) => void;
  collapsedColumns?: Record<string, boolean>;
}

const RowItemCollapse: React.FC<RowItemProps> = ({
  className,
  title,
  collapsedColumns,
  onToggleCollapsed = (): void => {},
}: RowItemProps) => {
  const { t } = useCoreTranslation();
  const hoverId = `${columnClasses.columnHeader}-${className}`;

  return (
    <Box
      id={hoverId}
      style={{
        ...(collapsedColumns?.[className] && {
          width: '24px',
          flexGrow: 0,
        }),
      }}
      className={`${columnClasses.columnHeader} ${className}`}
    >
      <CustomTooltip
        title={
          collapsedColumns?.[className]
            ? t('column-expand', { title })
            : t('column-collapse')
        }
        key={collapsedColumns?.[className] ? 'Expand' : 'Collapse'}
      >
        <Grid
          className={`${columnClasses.columnHeader} ${className}`}
          style={{
            ...(collapsedColumns?.[className] && {
              width: '24px',
              flexGrow: 0,
            }),
          }}
          onClick={() => {
            onToggleCollapsed(className);
            document
              .getElementById(hoverId)
              ?.dispatchEvent(new Event('mouseleave'));
          }}
        >
          {collapsedColumns?.[className] ? (
            <ColumnCollapsed />
          ) : (
            <>
              <Box sx={styles.text}>{title}</Box>
              <ColumnCollapse className="column-hover-icon" />
            </>
          )}
        </Grid>
      </CustomTooltip>
    </Box>
  );
};

interface DescriptionRowProps extends React.ComponentProps<typeof Grid> {
  source?: uiTypes.Source;
  settings?: LayerManagerCustomSettings['header'];
  addLayerComponent?: React.ReactElement;
  onToggleCollapsed?: (name: string) => void;
  collapsedColumns?: Record<string, boolean>;
}

const styles = {
  text: (theme: Theme): React.CSSProperties => ({
    paddingLeft: '10px',
    fontSize: '12px',
    fontFamily: 'Roboto',
    opacity: 0.67,
    ...theme.palette.geowebColors.layerManager.headerRowText,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

const DescriptionRow: React.FC<DescriptionRowProps> = ({
  settings,
  addLayerComponent,
  onToggleCollapsed = (): void => {},
  collapsedColumns,
  ...gridProps
}: DescriptionRowProps) => {
  const { t } = useCoreTranslation();

  return (
    <Grid
      container
      data-testid="descriptionRow"
      className="descriptionRow"
      alignItems="center"
      {...gridProps}
    >
      <Grid
        className={columnClasses.column1}
        sx={{
          ...leftButtonsStyle,
          justifyContent: 'center',
          paddingRight: '6px',
        }}
      >
        {addLayerComponent || (
          <LayerSelectButton
            tooltipTitle={settings?.addLayer?.tooltipTitle}
            icon={settings?.addLayer?.icon}
          />
        )}
      </Grid>
      <Grid className="medium-layermanager columnLayerName">
        <Box sx={styles.text}>
          {settings?.layerName?.title ?? t('layermanager-layer')}
        </Box>
      </Grid>
      <RowItemCollapse
        className={columnClasses.column2}
        title={settings?.layerName?.title ?? t('layermanager-layer')}
        onToggleCollapsed={onToggleCollapsed}
        collapsedColumns={collapsedColumns}
      />
      <RowItemCollapse
        className={columnClasses.column3}
        title={settings?.layerStyle?.title ?? t('layermanager-style-title')}
        onToggleCollapsed={onToggleCollapsed}
        collapsedColumns={collapsedColumns}
      />
      <RowItemCollapse
        className={columnClasses.column4}
        title={settings?.opacity?.title ?? t('layermanager-opacity-title')}
        onToggleCollapsed={onToggleCollapsed}
        collapsedColumns={collapsedColumns}
      />
      <RowItemCollapse
        className={columnClasses.column5}
        title={
          settings?.dimensions?.title ?? t('layermanager-dimensions-title')
        }
        onToggleCollapsed={onToggleCollapsed}
        collapsedColumns={collapsedColumns}
      />
      <RowItemCollapse
        className={columnClasses.acceptanceTime}
        title={
          settings?.acceptanceTime?.title ?? t('layermanager-acceptance-time')
        }
        onToggleCollapsed={onToggleCollapsed}
        collapsedColumns={collapsedColumns}
      />
      <Grid className={columnClasses.loadDuration}>
        <Box
          sx={[styles.text, { display: 'flex', justifyContent: 'flex-end' }]}
        >
          <CloudLoading />
        </Box>
      </Grid>
      <Grid className={columnClasses.column6} />
    </Grid>
  );
};

export default DescriptionRow;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Info } from '@opengeoweb/theme';
import type { CoreAppStore } from '@opengeoweb/store';
import { uiTypes, uiSelectors, uiActions, mapEnums } from '@opengeoweb/store';
import { MapControlButton } from '@opengeoweb/webmap-react';
import { useCoreTranslation } from '../../utils/i18n';

interface GetFeatureInfoButtonProps {
  mapId: string;
  source?: uiTypes.Source;
}

const GetFeatureInfoButtonConnect: React.FC<GetFeatureInfoButtonProps> = ({
  mapId,
  source = 'app',
}: GetFeatureInfoButtonProps) => {
  const { t } = useCoreTranslation();
  const dispatch = useDispatch();

  const gfiType = `getfeatureinfo-${mapId}`;

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, gfiType),
  );

  const openGfiDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: gfiType,
        mapId,
        setOpen: !isOpenInStore,
        source,
        origin: mapEnums.MapActionOrigin.map,
      }),
    );
  }, [mapId, dispatch, isOpenInStore, source, gfiType]);

  return (
    <MapControlButton
      title={t('feature_info_location_info')}
      data-testid="open-getfeatureinfo"
      onClick={openGfiDialog}
      isActive={isOpenInStore}
    >
      <Info />
    </MapControlButton>
  );
};

export default GetFeatureInfoButtonConnect;

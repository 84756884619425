/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { FieldError, FieldErrors } from 'react-hook-form';
import { TFunction } from 'i18next';
import { CustomErrorField } from './types';

export const getCustomError = (
  error: FieldError,
  isChangeGroup: boolean,
  rowIndex: number,
): CustomErrorField => {
  return {
    ...error,
    isChangeGroup,
    rowIndex,
  };
};

export const getIssuesLabel = (sumOfErrors: number, t: TFunction): string => {
  switch (sumOfErrors) {
    case 0:
      return t('issues-button-0');
    case 1:
      return t('issues-button-1');
    default:
      return t('issues-button-multiple', { amount: sumOfErrors });
  }
};

export const getErrorValues = (
  fieldErrors: FieldErrors,
  isChangeGroup = false,
  rowIndex = -1,
): FieldError[] => {
  if (!fieldErrors) {
    return [];
  }
  return Object.keys(fieldErrors).reduce(
    (list: FieldError[], key: keyof FieldErrors) => {
      const error = { ...fieldErrors[key] } as FieldError | FieldErrors;

      if (error.ref) {
        return list.concat(
          getCustomError(error as FieldError, isChangeGroup, rowIndex),
        );
      }

      return list.concat(
        getErrorValues(error as FieldErrors, isChangeGroup, rowIndex),
      );
    },
    [],
  );
};

export const filterDuplicateErrors = (
  errors: CustomErrorField[],
): CustomErrorField[] =>
  errors.reduce((list: CustomErrorField[], error) => {
    // check on type validateRequiredSub and validateRequiredMainOptional since these are giving 'duplicates'
    if (error.type === 'validateRequiredSub') {
      const exist = list.find(
        (e) =>
          e.type === 'validateRequiredSub' && e.rowIndex === error.rowIndex,
      );
      return exist ? list : list.concat(error);
    }
    if (error.type === 'validateRequiredMainOptional') {
      const exist = list.find(
        (e) =>
          e.type === 'validateRequiredMainOptional' &&
          e.rowIndex === error.rowIndex,
      );
      return exist ? list : list.concat(error);
    }
    return list.concat(error);
  }, []);

export const getErrors = (errors: FieldErrors): CustomErrorField[] => {
  const errorList = Object.keys(errors).reduce<FieldError[]>((list, key) => {
    const errorValue = errors[key] as FieldErrors;
    // changeGroups
    if (Array.isArray(errorValue)) {
      const errorValues = errorValue.reduce(
        (changeGroupErrors, changeGroupError, index) =>
          changeGroupErrors.concat(
            getErrorValues(changeGroupError, true, index),
          ),
        [],
      ) as FieldError[];
      return list.concat(...errorValues);
    }
    // baseForecast
    return list.concat(getErrorValues(errorValue, false, -1));
  }, []);
  return errorList as CustomErrorField[];
};

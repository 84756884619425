/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box } from '@mui/material';
import { Edit, Clock, Success, Cancel } from '@opengeoweb/theme';
import { CustomTooltip } from '@opengeoweb/shared';
import { TafStatus, TimeSlot } from '../../types';
import { getTafStatusLabel } from '../TafForm/utils';
import { useTafTranslation } from '../../utils/i18n';

const IconWithTooltip: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => (
  <CustomTooltip
    title={title}
    placement="right"
    data-testid="statusIcon-tooltip"
  >
    <Box component="span">{children}</Box>
  </CustomTooltip>
);

interface StatusIconProps {
  status: TafStatus;
  timeSlot: TimeSlot;
}

const StatusIcon: React.FC<StatusIconProps> = ({
  status,
  timeSlot,
}: StatusIconProps) => {
  const { t } = useTafTranslation();
  switch (status) {
    case 'DRAFT':
    case 'DRAFT_AMENDED':
    case 'DRAFT_CORRECTED':
      return (
        <IconWithTooltip title={getTafStatusLabel(t, status)}>
          <Edit
            data-testid="status-draft"
            sx={{ color: 'geowebColors.buttons.tertiary.default.color' }}
          />
        </IconWithTooltip>
      );
    case 'PUBLISHED':
    case 'CORRECTED':
    case 'AMENDED':
      return (
        <IconWithTooltip title={getTafStatusLabel(t, status)}>
          {timeSlot === 'ACTIVE' ? (
            <Success
              data-testid="status-active"
              sx={{ color: 'geowebColors.functional.success' }}
            />
          ) : (
            <Clock
              data-testid="status-upcoming"
              sx={{ color: 'geowebColors.functional.success' }}
            />
          )}
        </IconWithTooltip>
      );
    case 'CANCELLED':
      return (
        <IconWithTooltip title={getTafStatusLabel(t, status)}>
          <Cancel
            data-testid="status-cancelled"
            sx={{ color: 'geowebColors.captions.captionError.rgba' }}
          />
        </IconWithTooltip>
      );
    case 'EXPIRED':
      return (
        <IconWithTooltip title={getTafStatusLabel(t, status)}>
          <Clock
            data-testid="status-expired"
            sx={{
              transform: 'scaleX(-1)',
              color: 'geowebColors.buttons.tertiary.disabled.color',
            }}
          />
        </IconWithTooltip>
      );
    case 'NEW':
    default:
      return null;
  }
};

export default StatusIcon;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { Box } from '@mui/material';
import { IWMJSMap, webmapUtils } from '@opengeoweb/webmap';

import { useTranslation } from 'react-i18next';
import { MapControls, ZoomControls } from '../MapControls';
import { ReactMapView, ReactMapViewProps } from '../ReactMapView';
import { MapTime } from '../MapTime';
import { ProfileAxis } from '../Axis/ProfileAxis';

export interface MapViewProps extends ReactMapViewProps {
  controls?: {
    zoomControls?: boolean;
  };
  isTimeScrollingEnabled?: boolean;
}

export const MapView: React.FC<MapViewProps> = ({
  children,
  controls = {
    zoomControls: true,
  },
  displayTimeInMap = true,
  onWMJSMount,
  isTimeScrollingEnabled,
  // rest props
  ...props
}: MapViewProps) => {
  const { dimensions, mapId: mapIdProp } = props;
  const { t } = useTranslation();

  const wmjsMapRef = React.useRef<IWMJSMap | null>(null);
  const mapId = React.useRef<string>(mapIdProp).current;

  if (mapIdProp !== mapId) {
    console.warn(
      `Warning, MapId ${mapId} has been updated to ${mapIdProp} on an already mounted component. Keeping ${mapId}`,
    );
  }

  const { ref, height, width } = useResizeDetector();

  React.useEffect(() => {
    if (wmjsMapRef.current && !!width && !!height) {
      const { height: mapHeight, width: mapWidth } =
        wmjsMapRef.current.getSize();
      if (width !== mapWidth || height !== mapHeight) {
        wmjsMapRef.current.setSize(Math.ceil(width), Math.ceil(height));
      }
    }
  }, [width, height]);

  const reactMapViewProps = {
    ...(props as ReactMapViewProps),
    mapId,
    isTimeScrollingEnabled,
  };

  return (
    <Box
      ref={ref}
      sx={{
        display: 'grid',
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {controls && controls.zoomControls && (
        <MapControls style={{ top: 0, position: 'absolute' }}>
          <ProfileAxis mapId={mapId} />
          <ZoomControls
            onZoomIn={(): void => {
              webmapUtils.getWMJSMapById(mapId)?.zoomIn(undefined!);
            }}
            onZoomOut={(): void => {
              webmapUtils.getWMJSMapById(mapId)?.zoomOut();
            }}
            onZoomReset={(): void => {
              const wmjsMap = webmapUtils.getWMJSMapById(mapId);
              if (!wmjsMap) {
                return;
              }
              wmjsMap.zoomToLayer(wmjsMap.getActiveLayer());
            }}
          />
        </MapControls>
      )}
      <Box
        sx={{
          gridColumnStart: 1,
          gridRowStart: 1,
        }}
      >
        <ReactMapView
          {...reactMapViewProps}
          showLegend={false}
          displayTimeInMap={false}
          shouldDisablePrefetching={props.shouldDisablePrefetching}
          onWMJSMount={(id): void => {
            const webMapJS = webmapUtils.getWMJSMapById(id);
            if (!webMapJS) {
              return;
            }
            wmjsMapRef.current = webMapJS;
            if (onWMJSMount) {
              onWMJSMount(id);
            }
          }}
          t={t}
        >
          {children}
        </ReactMapView>
      </Box>
      {displayTimeInMap && dimensions && <MapTime dimensions={dimensions} />}
    </Box>
  );
};

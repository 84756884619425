/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box } from '@mui/material';
import React from 'react';
import { PresetWorkspace } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { WorkspaceMenu } from './WorkspaceMenu';
import { useWorkspaceTranslation } from '../../utils/i18n';

interface WorkspaceMenuButtonProps {
  showMenu?: boolean;
  onToggleDialog?: (shouldOpen: boolean) => void;
}

export const WorkspaceMenuButton: React.FC<WorkspaceMenuButtonProps> = ({
  showMenu = false,
  onToggleDialog = (): void => {},
}) => {
  const onCloseMenu = (): void => {
    onToggleDialog(false);
  };
  const { t } = useWorkspaceTranslation();
  const tooltipTitle = t('workspace-menu');

  return (
    <div>
      <Box
        sx={{
          padding: '4px 8px',
          flexGrow: 1,
        }}
      >
        <CustomIconButton
          onClick={(): void => {
            onToggleDialog(!showMenu);
          }}
          data-testid="workspaceMenuButton"
          isSelected={showMenu}
          aria-label="workspace menu"
          variant="tool"
          tooltipTitle={tooltipTitle}
          sx={{
            '&&': {
              backgroundColor: !showMenu ? 'transparent' : undefined,
              '&:hover': {
                backgroundColor: 'geowebColors.workspace.tabButtonHover',
              },
            },
          }}
        >
          <PresetWorkspace />
        </CustomIconButton>
      </Box>
      {showMenu && <WorkspaceMenu closeMenu={onCloseMenu} />}
    </div>
  );
};

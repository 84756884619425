/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Theme } from '@mui/material';
import {
  pixelToTimestamp,
  timestampToPixel,
} from '../../TimeSlider/timeSliderUtils';
import { drawTimeScale } from './drawTimeScale';
import { drawNeedle } from './drawNeedle';
import { drawCurrentTime } from './drawCurrentTime';
import { drawBackground } from './drawBackground';

export const drawTimeSliderLegend = (
  context: CanvasRenderingContext2D,
  theme: Theme,
  canvasWidth: number,
  height: number,
  centerTime: number,
  secondsPerPx: number,
  timeSliderSpan: number,
  selectedTimeUnix: number,
  currentTimeUnix: number,
  animationStartTime: number,
  animationEndTime: number,
  dataStartTime: number,
  dataEndTime: number,
): void => {
  const ctx = context;
  const [visibleTimeStart, visibleTimeEnd] = [0, canvasWidth].map((px) =>
    pixelToTimestamp(px, centerTime, canvasWidth, secondsPerPx),
  );

  const [
    animationStartPx,
    animationEndPx,
    dataStartPx,
    dataEndPx,
    currentTimePx,
  ] = [
    animationStartTime,
    animationEndTime,
    dataStartTime,
    dataEndTime,
    currentTimeUnix,
  ].map((time) => {
    return timestampToPixel(time, centerTime, canvasWidth, secondsPerPx);
  });
  ctx.clearRect(0, 0, canvasWidth, height);
  drawBackground(
    ctx,
    theme,
    visibleTimeStart,
    visibleTimeEnd,
    animationStartPx,
    animationEndPx,
    canvasWidth,
    height,
    secondsPerPx,
    timeSliderSpan,
    dataStartPx,
    dataEndPx,
    currentTimePx,
  );
  drawTimeScale(
    ctx,
    theme,
    visibleTimeStart,
    visibleTimeEnd,
    canvasWidth,
    height,
    secondsPerPx,
  );
  drawCurrentTime(
    ctx,
    theme,
    canvasWidth,
    height,
    visibleTimeStart,
    visibleTimeEnd,
    currentTimeUnix,
  );
  drawNeedle(
    ctx,
    theme,
    canvasWidth,
    height,
    visibleTimeStart,
    visibleTimeEnd,
    selectedTimeUnix,
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { timestampToPixel } from '../TimeSlider/timeSliderUtils';

export const onMouseMove = (
  x: number,
  width: number,
  selectedTime: number,
  centerTime: number,
  secondsPerPx: number,
  mouseDownInTimeBox: boolean,
  isTimeBoxArea: (location: number, timePx: number) => boolean,
  setCursorStyle: (style: string) => void,
): void => {
  if (!selectedTime || mouseDownInTimeBox) {
    return;
  }
  const selectedTimePx = timestampToPixel(
    selectedTime,
    centerTime,
    width,
    secondsPerPx,
  );
  if (isTimeBoxArea(x, selectedTimePx)) {
    setCursorStyle('grab');
  } else {
    setCursorStyle('auto');
  }
};

export const onMouseDown = (
  x: number,
  width: number,
  selectedTime: number,
  centerTime: number,
  secondsPerPx: number,
  isTimeBoxArea: (location: number, timePx: number) => boolean,
  setCursorStyle: (style: string) => void,
  setMouseDownInTimeBox: (dragging: boolean) => void,
): void => {
  if (!selectedTime) {
    return;
  }
  const selectedTimePx = timestampToPixel(
    selectedTime,
    centerTime,
    width,
    secondsPerPx,
  );
  if (isTimeBoxArea(x, selectedTimePx)) {
    setCursorStyle('grabbing');
  }
  setMouseDownInTimeBox(true);
};

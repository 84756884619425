/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import {
  UseTranslationResponse,
  initReactI18next,
  useTranslation,
} from 'react-i18next';
import { SHARED_NAMESPACE, sharedTranslations } from '@opengeoweb/shared';
import {
  WEBMAP_REACT_NAMESPACE,
  webmapReactTranslations,
} from '@opengeoweb/webmap-react';
import { CORE_NAMESPACE, coreTranslations } from '@opengeoweb/core';
import {
  FORM_FIELDS_NAMESPACE,
  formFieldsTranslations,
} from '@opengeoweb/form-fields';
import { WEBMAP_NAMESPACE, webmapTranslations } from '@opengeoweb/webmap';
import { SA_NAMESPACE, saTranslations } from '@opengeoweb/sigmet-airmet';
import warningsTranslations from '../../../locales/warnings.json';

export const WARN_NAMESPACE = 'warn';
const defaultLanguage = 'en';

export const initWarnI18n = (): void => {
  void i18n.use(initReactI18next).init({
    lng: defaultLanguage,
    ns: WARN_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [WARN_NAMESPACE]: warningsTranslations.en,
        [SHARED_NAMESPACE]: sharedTranslations.en,
        [WEBMAP_NAMESPACE]: webmapTranslations.en,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.en,
        [CORE_NAMESPACE]: coreTranslations.en,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.en,
        [SA_NAMESPACE]: saTranslations.en,
      },
      nl: {
        [WARN_NAMESPACE]: warningsTranslations.nl,
        [SHARED_NAMESPACE]: sharedTranslations.nl,
        [WEBMAP_NAMESPACE]: webmapTranslations.nl,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.nl,
        [CORE_NAMESPACE]: coreTranslations.nl,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.nl,
        [SA_NAMESPACE]: saTranslations.nl,
      },
      fi: {
        [WARN_NAMESPACE]: warningsTranslations.fi,
        [SHARED_NAMESPACE]: sharedTranslations.fi,
        [WEBMAP_NAMESPACE]: webmapTranslations.fi,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.fi,
        [CORE_NAMESPACE]: coreTranslations.fi,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.fi,
        [SA_NAMESPACE]: saTranslations.fi,
      },
      no: {
        [WARN_NAMESPACE]: warningsTranslations.no,
        [SHARED_NAMESPACE]: sharedTranslations.no,
        [WEBMAP_NAMESPACE]: webmapTranslations.no,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.no,
        [CORE_NAMESPACE]: coreTranslations.no,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.no,
        [SA_NAMESPACE]: saTranslations.no,
      },
    },
  });
};

const ns = [WARN_NAMESPACE, FORM_FIELDS_NAMESPACE, SHARED_NAMESPACE];

export const translateKeyOutsideComponents = (
  key: string,
  params: Record<string, string | number> | undefined = undefined,
): string => i18n.t(key, { ns, ...params });

export const useWarningsTranslation = (): UseTranslationResponse<
  typeof WARN_NAMESPACE,
  typeof i18n
> => useTranslation(ns);

export { i18n };

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { Navigate } from 'react-router-dom';
import { useIsMounted } from '@opengeoweb/shared';
import { useAuthenticationContext } from '../AuthenticationContext';
import { makeCredentialsFromTokenResponse } from '../ApiContext/utils';

export const axiosInstance = axios.create({
  headers: {},
});

export const HandleOAuth2Code: React.FC = () => {
  const { onSetAuth, authConfig, isLoggedIn, onLogin, sessionStorageProvider } =
    useAuthenticationContext();
  const [error, setError] = React.useState<{ message: string; stack: string }>(
    null!,
  );
  const { isMounted } = useIsMounted();
  const loginInProgress = React.useRef(false);

  const authenticate = React.useCallback(async () => {
    const { code, state } = Object.fromEntries(
      new URLSearchParams(window.location.search),
    );
    const isStateRequired = authConfig.GW_AUTH_LOGIN_URL.includes('&state=');
    const isPkceRequired =
      authConfig.GW_AUTH_LOGIN_URL.includes('&code_challenge=');

    if (isStateRequired) {
      if (!state || state !== sessionStorageProvider.getOauthState()) {
        setError(() => ({
          message: 'Login failed',
          stack: !state ? 'Missing state param' : 'State param does not match',
        }));
        return;
      }
    }
    if (isPkceRequired) {
      if (!code || !sessionStorageProvider.getOauthCodeVerifier()) {
        setError(() => ({
          message: 'Login failed',
          stack: !code ? 'Missing code param' : 'Missing code verifier',
        }));
        return;
      }
    }
    const payload = {
      client_id: authConfig.GW_AUTH_CLIENT_ID,
      code: code as string,
      redirect_uri: `${authConfig.GW_APP_URL}/code`,
      grant_type: 'authorization_code',
      ...(isPkceRequired && {
        code_verifier: sessionStorageProvider.getOauthCodeVerifier(),
      }),
    };

    /* Send data in the "application/x-www-form-urlencoded" format.
      If only JSON is supported, use Axios' default content type ("application/x-www-form-urlencoded"). */
    const useDefaultContentType =
      authConfig.GW_AUTH_TOKEN_URL.includes('amazonaws.com');
    const data = useDefaultContentType ? payload : new URLSearchParams(payload);

    const config: AxiosRequestConfig = {
      headers: {
        'content-type': useDefaultContentType
          ? 'application/json'
          : 'application/x-www-form-urlencoded',
      },
    };

    try {
      const res = await axiosInstance.post(
        authConfig.GW_AUTH_TOKEN_URL,
        data,
        config,
      );
      const newAuth = makeCredentialsFromTokenResponse(res, authConfig);
      if (isMounted.current) {
        onSetAuth(newAuth);
        onLogin(true);
        sessionStorageProvider.setHasAuthenticated('true');
        if (sessionStorageProvider) {
          sessionStorageProvider.removeOauthState();
          sessionStorageProvider.removeOauthCodeVerifier();
          sessionStorageProvider.removeOauthCodeChallenge();
        }
      }
    } catch (error: unknown) {
      if (isMounted.current) {
        if (error instanceof Error) {
          setError(() => ({
            message: error.message,
            stack: error.stack || 'Error in HandleOAuth2Code',
          }));
        }
        if (sessionStorageProvider) {
          sessionStorageProvider.removeOauthState();
          sessionStorageProvider.removeOauthCodeVerifier();
          sessionStorageProvider.removeOauthCodeChallenge();
          sessionStorageProvider.removeHasAuthenticated();
        }
      }
    }
  }, [authConfig, isMounted, onLogin, onSetAuth, sessionStorageProvider]);

  React.useEffect(() => {
    const authenticateWithLock = async (): Promise<void> => {
      if (!isLoggedIn && !loginInProgress.current) {
        loginInProgress.current = true;
        await authenticate();
        loginInProgress.current = false;
      }
    };

    authenticateWithLock().catch(() => {
      loginInProgress.current = false;
    });
  }, [authenticate, isLoggedIn]);

  return (
    <>
      {error ? (
        <Navigate
          to="/error"
          state={{
            error: {
              message: error.message,
              stack: error.stack,
            },
          }}
          replace
        />
      ) : null}
      {isLoggedIn ? (
        <Navigate to={sessionStorageProvider.getCallbackUrl()} replace />
      ) : null}
    </>
  );
};

export default HandleOAuth2Code;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { ApiProvider } from '@opengeoweb/api';
import { produce } from 'immer';
import { TimeSeriesService } from '@opengeoweb/shared';
import { TimeSeriesConnectWrapper } from '../TimeSeries/TimeSeriesConnectWrapper';
import { PlotPreset, TimeSeriesPresetLocation } from '../TimeSeries/types';
import { createApi } from '../../utils/api';

interface InitialTimeseriesProps {
  plotPreset: PlotPreset;
  services: TimeSeriesService[];
  predefinedLocations?: TimeSeriesPresetLocation[];
}

export type SupportedComponentTypes = 'TimeSeries';

export type InitialProps = InitialTimeseriesProps;

export interface ComponentsLookUpPayload {
  componentType: SupportedComponentTypes;
  id: string;
  title?: string;
  initialProps: InitialProps;
  productConfigKey?: string;
  connectedMap?: string;
}

interface TimeSeriesServiceOld {
  id: string;
  interface: string;
  serviceUrl: string;
}

/**
 * The lookup table is for registering your own components with the workspace.
 * @param payload
 * @returns
 */
export const componentsLookUp = ({
  id: panelId,
  componentType,
  initialProps,
  productConfigKey,
  connectedMap,
}: ComponentsLookUpPayload): React.ReactElement => {
  switch (componentType) {
    case 'TimeSeries': {
      /**
       * Get a services property from intial props
       * @param initialProps
       * @returns
       */
      const getServicesFromProps = (
        initialProps: InitialTimeseriesProps,
      ): TimeSeriesService[] => {
        // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/4389 Remove when implemented
        const { services } = initialProps;
        if (!services || !services.length) {
          const oldService = initialProps.plotPreset as unknown as {
            services: TimeSeriesServiceOld[];
          };
          return oldService.services.map((elem) => {
            // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/4389 Remove when implemented
            // Remove collections/{collecionId} from service "https://opendata.fmi.fi/edr/collections/ecmwf"
            const hasCollections = elem.serviceUrl.indexOf('collections');
            const url =
              hasCollections === -1
                ? elem.serviceUrl
                : elem.serviceUrl.substring(0, hasCollections - 1);
            return {
              url,
              description: 'something',
              // Object needs all the properties for casting to work properly
              name: elem.id,
              id: elem.id,
              type: elem.interface,
              // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/4389 Take from service as defined in the preset
              // Set scope in BE presets
              scope: 'system',
            } as TimeSeriesService;
          });
        }
        return services;
      };

      /**
       * Get a plotpreset from initialprops.
       * @param initialProps
       * @returns
       */
      const getPlotPresetFromProps = (
        initialProps: InitialTimeseriesProps,
      ): PlotPreset => {
        const { plotPreset } = initialProps;
        return produce(plotPreset, (draft) => {
          draft.connectedMap = connectedMap;
          draft.parameters.forEach((draftParameter) => {
            // Some presets do not return a collectionId, to ensure backwards compatibility set it to ecmwf
            if (!draftParameter.collectionId) {
              console.warn(
                'NOTE: No collectionId propery given, falling back to ecmwf',
              );
              draftParameter.collectionId = 'ecmwf';
            }
          });
        });
      };
      return (
        <ApiProvider createApi={createApi} data-testid="timeseriesPlot">
          <TimeSeriesConnectWrapper
            panelId={panelId}
            timeSeriesPreset={{
              plotPreset: getPlotPresetFromProps(initialProps),
              services: getServicesFromProps(initialProps),
            }}
            productConfigKey={productConfigKey!}
          />
        </ApiProvider>
      );
    }
    default:
      return null!;
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React, { useRef } from 'react';
import {
  AuthenticationConfig,
  AuthenticationContextProps,
  AuthenticationDefaultStateProps,
} from './types';
import { getSessionStorageProvider } from '../../utils/session';
import { Credentials, Role } from '../ApiContext/types';
import {
  GEOWEB_ROLE_USER,
  getCurrentTimeInSeconds,
  KEEP_ALIVE_POLLER_IN_SECONDS,
  MILLISECOND_TO_SECOND,
  refreshAccessTokenAndSetAuthContext,
} from '../ApiContext/utils';

const authConfigKeys = {
  GW_AUTH_LOGIN_URL: null!,
  GW_AUTH_LOGOUT_URL: null!,
  GW_AUTH_TOKEN_URL: null!,
  GW_APP_URL: null!,
  GW_BE_VERSION_BASE_URL: null!,
  GW_AUTH_CLIENT_ID: null!,
  GW_AUTH_ROLE_CLAIM_NAME: null!,
  GW_AUTH_ROLE_CLAIM_VALUE_PRESETS_ADMIN: null!,
};

export const getRandomString = (): string => {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  const randomString = Array.from(array, (dec) => dec.toString(16)).join('');

  return randomString;
};

export const getCodeChallenge = async (
  codeVerifier: string,
): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await crypto.subtle.digest('SHA-256', data);
  return btoa(
    String.fromCharCode.apply(
      null,
      new Uint8Array(digest) as unknown as number[],
    ),
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export const replaceTemplateKeys = (
  url: string,
  clientId: string,
  appUrl: string,
  oauthState?: string,
  codeChallenge?: string,
): string => {
  return url
    .replace('{client_id}', clientId)
    .replace('{app_url}', appUrl)
    .replace('{state}', oauthState!)
    .replace('{code_challenge}', codeChallenge!);
};

export const getAuthConfig = (
  _configUrls: AuthenticationConfig,
): AuthenticationConfig => {
  const {
    GW_AUTH_LOGIN_URL,
    GW_AUTH_LOGOUT_URL,
    GW_AUTH_TOKEN_URL,
    GW_APP_URL,
    GW_BE_VERSION_BASE_URL,
    GW_AUTH_CLIENT_ID,
    GW_AUTH_ROLE_CLAIM_NAME,
    GW_AUTH_ROLE_CLAIM_VALUE_PRESETS_ADMIN,
  } = _configUrls;
  const sessionStorageProvider = getSessionStorageProvider();

  const loginUrl = replaceTemplateKeys(
    GW_AUTH_LOGIN_URL,
    GW_AUTH_CLIENT_ID,
    GW_APP_URL,
    sessionStorageProvider.getOauthState(),
    sessionStorageProvider.getOauthCodeChallenge(),
  );
  const logOutUrl = replaceTemplateKeys(
    GW_AUTH_LOGOUT_URL,
    GW_AUTH_CLIENT_ID,
    GW_APP_URL,
  );

  return {
    GW_AUTH_LOGIN_URL: loginUrl,
    GW_AUTH_LOGOUT_URL: logOutUrl,
    GW_AUTH_TOKEN_URL,
    GW_APP_URL,
    GW_BE_VERSION_BASE_URL,
    GW_AUTH_CLIENT_ID,
    GW_AUTH_ROLE_CLAIM_NAME,
    GW_AUTH_ROLE_CLAIM_VALUE_PRESETS_ADMIN,
  };
};

export const AuthenticationContext: React.Context<AuthenticationContextProps> =
  React.createContext<AuthenticationContextProps>({
    isLoggedIn: null!,
    onLogin: null!,
    auth: null!,
    onSetAuth: null!,
    authConfig: null!,
    sessionStorageProvider: null!,
    currentRole: null!,
  });

export const useAuthenticationDefaultProps =
  (): AuthenticationDefaultStateProps => {
    const [isLoggedIn, onLogin] = React.useState(false);
    const [hasConnectionIssue, setHasConnectionIssue] = React.useState(false);

    const emptyCredentials = {
      username: '',
      roles: [],
      token: '',
      refresh_token: '',
      expires_at: 0,
      has_connection_issue: false,
    };
    const auth = React.useRef<Credentials>({ ...emptyCredentials }).current;

    const onSetAuth = (newAuth: Credentials | null): void => {
      if (newAuth) {
        Object.assign(auth, newAuth);
        if (
          newAuth.has_connection_issue !== undefined &&
          hasConnectionIssue !== auth.has_connection_issue
        ) {
          /*
           * The hasconnection issue should trigger a render.
           * This is needed to make the connection banner show that there is an issue.
           */
          setHasConnectionIssue(auth.has_connection_issue === true);
        }
      } else {
        Object.assign(auth, emptyCredentials);
      }
    };
    const sessionStorageProvider = getSessionStorageProvider();
    const [currentRole, setCurrentRole] = React.useState<Role>(
      (auth.roles && auth.roles[0]) || GEOWEB_ROLE_USER,
    );

    return {
      isLoggedIn,
      onLogin,
      auth: isLoggedIn ? auth : null,
      onSetAuth,
      sessionStorageProvider,
      currentRole,
      setCurrentRole,
    };
  };

interface AuthenticationProviderProps {
  children: React.ReactNode;
  value?: AuthenticationDefaultStateProps;
  configURLS?: AuthenticationConfig;
}

export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({
  children,
  value,
  configURLS = authConfigKeys,
}: AuthenticationProviderProps) => {
  const defaultValues = useAuthenticationDefaultProps();
  const {
    isLoggedIn,
    onLogin,
    auth,
    onSetAuth,
    sessionStorageProvider,
    currentRole,
    setCurrentRole,
  } = value || defaultValues;
  const authConfig = configURLS;

  // Checks the token expiration time regularly and renews it before it expires.
  const interval = useRef<ReturnType<typeof setInterval>>();
  React.useEffect(() => {
    interval.current = setInterval(async () => {
      if (auth) {
        const currentTime = getCurrentTimeInSeconds();
        const timeInSecondsLeftBeforeExpiration = auth.expires_at
          ? auth.expires_at - currentTime
          : 0;
        if (timeInSecondsLeftBeforeExpiration < 0) {
          await refreshAccessTokenAndSetAuthContext({
            auth,
            onSetAuth,
            config: {
              baseURL: '',
              authTokenURL: configURLS.GW_AUTH_TOKEN_URL,
              appURL: configURLS.GW_APP_URL,
              authClientId: configURLS.GW_AUTH_CLIENT_ID,
            },
            configURLS,
            onLogin,
          });
        }
      }
    }, KEEP_ALIVE_POLLER_IN_SECONDS / MILLISECOND_TO_SECOND);

    return (): void => {
      clearInterval(interval.current!);
    };
  }, [
    auth,
    configURLS.GW_APP_URL,
    configURLS.GW_AUTH_CLIENT_ID,
    configURLS.GW_AUTH_TOKEN_URL,
    onSetAuth,
    configURLS,
    onLogin,
  ]);

  const contextValue = React.useMemo(
    () => ({
      isLoggedIn,
      onLogin,
      auth,
      onSetAuth,
      authConfig,
      sessionStorageProvider,
      currentRole,
      setCurrentRole,
    }),
    [
      isLoggedIn,
      onLogin,
      auth,
      onSetAuth,
      authConfig,
      sessionStorageProvider,
      currentRole,
      setCurrentRole,
    ],
  );

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = (): AuthenticationContextProps =>
  React.useContext(AuthenticationContext);

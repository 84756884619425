/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Backdrop, Grid2 as Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SHARED_NAMESPACE } from '../../utils/i18n';
import { ToolContainerDraggable } from '../ToolContainer';
import CheatSheet from './CheatSheet/CheatSheet';

const UserMenuItemCheatSheet: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const onClose = (): void => {
    setOpen(false);
  };
  const onOpen = (): void => {
    setOpen(true);
  };
  const { t } = useTranslation(SHARED_NAMESPACE);

  return (
    <>
      <MenuItem data-testid="cheatsheet-btn" onClick={onOpen} divider>
        <Grid container>
          <Grid>{t('shared-cheat-sheet')}</Grid>
        </Grid>
      </MenuItem>
      <Backdrop
        data-testid="cheatsheet-content"
        open={open}
        sx={{
          zIndex: 1,
        }}
      >
        <ToolContainerDraggable
          startSize={{ width: 700 }}
          minHeight={300}
          minWidth={500}
          title={t('shared-cheat-sheet')}
          isOpen={true}
          onClose={onClose}
          bounds="parent"
        >
          <CheatSheet />
        </ToolContainerDraggable>
      </Backdrop>
    </>
  );
};

export default UserMenuItemCheatSheet;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createAction } from '@reduxjs/toolkit';
import { SetMapDimensionPayload } from '@opengeoweb/webmap-react';
import type { SetMapPresetPayload } from './types';

/**
 * These actions causes circular dependency if they are defined in the reducer.ts file.
 * Therefor they are instead added to this file
 * https://redux-toolkit.js.org/usage/usage-guide#exporting-and-using-slices
 */

export const mapChangeDimension = createAction<SetMapDimensionPayload>(
  'MAP_CHANGE_DIMENSION',
);

export const setMapPreset = createAction<SetMapPresetPayload>('SET_MAP_PRESET');

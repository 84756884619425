/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid, InputLabel } from '@mui/material';
import { Cached } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { useCoreTranslation } from '../../../utils/i18n';

interface WMSLayerTreeHeaderProps {
  serviceName: string;
  onClickReload?: () => void;
}

const WMSLayerTreeHeader: React.FC<WMSLayerTreeHeaderProps> = ({
  serviceName,
  onClickReload,
}: WMSLayerTreeHeaderProps) => {
  const { t } = useCoreTranslation();
  return (
    <Grid container direction="row" spacing={1} justifyContent="space-between">
      <Grid>
        <InputLabel shrink htmlFor="select-multiple-native">
          {t('add-layer-popup-available-layers', { serviceName })}
        </InputLabel>
      </Grid>
      {onClickReload && (
        <Grid>
          <CustomIconButton
            tooltipTitle={t('add-layer-popup-reload-layers')}
            data-testid="reloadLayers"
            onClick={onClickReload}
          >
            <Cached />
          </CustomIconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default WMSLayerTreeHeader;

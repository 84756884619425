/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { DrawState } from './types';
import { drawAdapter } from './reducer';
import { WarningModuleStore } from '../types';

export const getDrawStore = (store: WarningModuleStore): DrawState => {
  if (store && store.drawings) {
    return store.drawings;
  }
  return null!;
};

export const { selectById: selectDrawByInstanceId } = drawAdapter.getSelectors(
  (state: WarningModuleStore) => {
    return state?.drawings ?? { entities: {}, ids: [] };
  },
);

export const getWarningFormDirty = createSelector(
  selectDrawByInstanceId,
  (drawing) => drawing?.isFormDirty || false,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { TooltipSelect } from '@opengeoweb/shared';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { TFunction } from 'i18next';
import { Parameter } from '../../TimeSeries/types';

const ParameterOpacitySelect: React.FC<{
  rowIsEnabled: boolean;
  parameter: Parameter;
  patchParameter: (parameter: Partial<Parameter>) => void;
  t: TFunction;
}> = ({ rowIsEnabled, parameter, patchParameter, t }) => {
  const opacityValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  return (
    <TooltipSelect
      SelectDisplayProps={{ 'aria-label': 'timeseriesManager-opacity' }}
      data-testid="parameterOpacitySelect"
      value={parameter.opacity}
      tooltip={t('timeseries-choose-opacity')}
      isEnabled={rowIsEnabled}
      onChange={(event: SelectChangeEvent<unknown>): void => {
        patchParameter({
          id: parameter.id,
          opacity: event.target.value as unknown as number,
        });
      }}
    >
      <MenuItem disabled>{t('timeseries-opacity')}</MenuItem>
      {opacityValues.map((value) => (
        <MenuItem value={value} key={value}>
          {value}%
        </MenuItem>
      ))}
    </TooltipSelect>
  );
};

export default ParameterOpacitySelect;

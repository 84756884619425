/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

import {
  DialogType,
  SetActiveMapIdForDialogPayload,
  SetActiveWindowIdPayload,
  Source,
  UIOrderDialogPayload,
  UIRegisterDialogPayload,
  UISetErrorPayload,
  UIStoreType,
  UIToggleDialogPayload,
  UIToggleIsLoadingPayload,
  UIType,
  UIUnRegisterDialogPayload,
} from './types';

interface CreateUIDialogElementProps {
  activeMapId: string;
  type: DialogType;
  setOpen: boolean;
  source?: Source;
  sourcePanelId?: string;
}

export const moveToTop = <Type>(list: Type[], element: Type): Type[] => {
  const filteredList = list.filter((item) => item !== element);
  const newOrder = [element, ...filteredList];
  return newOrder;
};

export const createUIDialogElement = ({
  activeMapId,
  type,
  setOpen,
  source = 'app',
  sourcePanelId,
}: CreateUIDialogElementProps): UIType => ({
  activeMapId,
  isOpen: setOpen,
  type,
  source,
  isLoading: false,
  error: undefined,
  sourcePanelId,
});

export const initialState: UIStoreType = {
  order: [],
  dialogs: {},
  activeWindowId: undefined,
};

export const slice = createSlice({
  initialState,
  name: 'uiReducer',
  reducers: {
    registerDialog: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<UIRegisterDialogPayload>,
    ) => {
      const { type } = action.payload;
      const activeMapId = action.payload.mapId ? action.payload.mapId : '';
      const setOpen = action.payload.setOpen ? action.payload.setOpen : false;
      const source = action.payload.source ? action.payload.source : 'app';
      if (!Object.keys(draft.dialogs).includes(type)) {
        draft.dialogs[type] = createUIDialogElement({
          activeMapId,
          type,
          setOpen,
          source,
        });
      }
      // register dialog to order
      if (!draft.order.includes(type)) {
        draft.order.push(type);
      }
    },
    unregisterDialog: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<UIUnRegisterDialogPayload>,
    ) => {
      const { type } = action.payload;
      if (draft.dialogs[type]) {
        delete draft.dialogs[type];
        draft.order = draft.order.filter((item) => item !== type);
      }
    },
    setActiveMapIdForDialog: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<SetActiveMapIdForDialogPayload>,
    ) => {
      const { type, mapId, source } = action.payload;
      if (!draft.dialogs[type]) {
        return;
      }
      const isOpen =
        action.payload.setOpen !== undefined
          ? action.payload.setOpen
          : draft.dialogs[type]!.isOpen;
      draft.dialogs[type]!.activeMapId = mapId;
      draft.dialogs[type]!.isOpen = isOpen;
      draft.dialogs[type]!.source = source;
      if (isOpen) {
        draft.order = moveToTop(draft.order, type);
      }
    },
    setToggleOpenDialog: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<UIToggleDialogPayload>,
    ) => {
      const { type, setOpen, sourcePanelId } = action.payload;
      if (!draft.dialogs[type]) {
        return;
      }
      draft.dialogs[type]!.isOpen = setOpen;
      if (sourcePanelId) {
        draft.dialogs[type]!.sourcePanelId = sourcePanelId;
      }
      if (setOpen) {
        draft.order = moveToTop(draft.order, type);
      }
    },
    orderDialog: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<UIOrderDialogPayload>,
    ) => {
      const { type } = action.payload;
      draft.order = moveToTop(draft.order, type);
    },
    setActiveWindowId: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<SetActiveWindowIdPayload>,
    ) => {
      const { activeWindowId } = action.payload;
      draft.activeWindowId = activeWindowId;
    },
    toggleIsLoadingDialog: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<UIToggleIsLoadingPayload>,
    ) => {
      const { isLoading, type } = action.payload;
      draft.dialogs[type]!.isLoading = isLoading;
    },
    setErrorDialog: (
      draft: Draft<UIStoreType>,
      action: PayloadAction<UISetErrorPayload>,
    ) => {
      const { error, type } = action.payload;
      draft.dialogs[type]!.error = error;
    },
  },
});

export const { reducer: uiReducer, actions: uiActions } = slice;

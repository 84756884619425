/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { dateUtils } from '@opengeoweb/shared';
import {
  SWNotification,
  SWEvent,
  EventCategory,
  NotificationLabel,
  ThresholdUnits,
} from '../../types';

export const formatDate = (date: string): string | undefined =>
  dateUtils.dateToString(
    dateUtils.createDate(date),
    dateUtils.DATE_FORMAT_DATEPICKER,
  );

export const getLatestNotification = (event: SWEvent): SWNotification => {
  // get latest internal notification if there, otherwise latest external notification
  if (
    !event ||
    !event.lifecycles ||
    (!event.lifecycles.internalprovider && !event.lifecycles.externalprovider)
  ) {
    return {} as SWNotification;
  }
  return event.lifecycles.internalprovider &&
    event.lifecycles.internalprovider.notifications &&
    event.lifecycles.internalprovider.notifications.length
    ? event.lifecycles.internalprovider.notifications[
        event.lifecycles.internalprovider.notifications.length - 1
      ]
    : event.lifecycles.externalprovider!.notifications![
        event.lifecycles.externalprovider!.notifications!.length - 1
      ];
};

export const constructBaseNotification = (
  event: SWEvent | null,
  mode = 'new',
): SWNotification => {
  switch (mode) {
    case 'draft': {
      const draftNotification =
        event!.lifecycles!.internalprovider!.notifications!.find(
          (notification) => notification.draft,
        );
      const { neweventstart } = draftNotification!;

      const neweventend =
        draftNotification!.neweventend &&
        draftNotification!.neweventend !== null &&
        draftNotification!.neweventend !== ''
          ? draftNotification!.neweventend
          : undefined;
      return {
        originator: 'KNMI',
        ...draftNotification!,
        eventid: event!.eventid,
        neweventstart,
        ...(neweventend && { neweventend }),
        // For backwards compatibility, if title is null or not set, replace with ''
        title: draftNotification!.title || '',
        neweventlevel:
          draftNotification!.neweventlevel !== null
            ? draftNotification!.neweventlevel
            : '',
      };
    }
    case 'internalprovider': {
      const lifeCycle = event!.lifecycles!.internalprovider!;
      const externalNotifications =
        event!.lifecycles!.externalprovider &&
        event!.lifecycles!.externalprovider.notifications
          ? event!.lifecycles!.externalprovider.notifications
          : [];
      const lastExternalNotification =
        externalNotifications.length > 0
          ? externalNotifications[externalNotifications.length - 1]
          : {};

      const neweventstart = lifeCycle.eventstart;
      const neweventend =
        lifeCycle.eventend &&
        lifeCycle.eventend !== null &&
        lifeCycle.eventend !== ''
          ? lifeCycle.eventend
          : undefined;

      return {
        ...(lifeCycle.eventid !== null && { eventid: lifeCycle.eventid }),
        ...getLatestNotification(event!),
        neweventstart,
        ...(neweventend && { neweventend }),
        // Overwrite any fields from the incoming message if it exists
        ...lastExternalNotification,
        eventid: event!.eventid,
        category: event!.category,
        categorydetail: event!.categorydetail,
        label: lifeCycle.label,
        changestateto: '',
        draft: false,
        // Message and title will be autopopulated from the template so should be set to empty
        message: '',
        title: '',
        originator: 'KNMI',
      };
    }
    case 'externalprovider': {
      const lifeCycle = event!.lifecycles!.externalprovider;
      const neweventstart = lifeCycle!.eventstart;
      const neweventend =
        lifeCycle!.eventend &&
        lifeCycle!.eventend !== null &&
        lifeCycle!.eventend !== ''
          ? lifeCycle!.eventend
          : undefined;

      return {
        ...(lifeCycle!.eventid !== null && { eventid: lifeCycle!.eventid }),
        ...getLatestNotification(event!),
        eventid: event!.eventid,
        category: event!.category,
        categorydetail: event!.categorydetail,
        label: lifeCycle!.label,
        changestateto: '',
        draft: false,
        neweventstart,
        ...(neweventend && { neweventend }),
        // Message and title will be autopopulated from the template so should be set to empty
        message: '',
        title: '',
        originator: 'KNMI',
      };
    }
    case 'new':
    default: {
      // Defaults for the date
      const now = dateUtils.dateToString(
        dateUtils.sub(dateUtils.utc(), { minutes: 5 }),
      )!;

      return {
        category: Object.keys(EventCategory)[0],
        categorydetail: '',
        label: Object.keys(NotificationLabel)[0],
        draft: false,
        neweventstart: now,
        changestateto: 'issued',
        message: '',
        title: '',
        originator: 'KNMI',
        thresholdunit:
          ThresholdUnits[
            Object.keys(EventCategory)[0] as keyof typeof ThresholdUnits
          ],
      };
    }
  }
};

export const constructOutgoingNotification = (
  _notification: SWNotification,
  newState: string,
  draft = false,
): SWNotification => {
  // strip empty values
  const notification = Object.keys(_notification).reduce((list, key) => {
    if (
      _notification[key as keyof SWNotification] !== null &&
      _notification[key as keyof SWNotification] !== ''
    ) {
      return {
        ...list,
        [key]: _notification[key as keyof SWNotification],
      };
    }
    return list;
  }, {}) as SWNotification;

  const changestateto =
    newState === 'Alert' || newState === 'Warning' || newState === 'Watch'
      ? 'issued'
      : 'ended';
  const neweventend = notification.neweventend
    ? dateUtils.dateToString(dateUtils.utc(notification.neweventend))
    : undefined;

  return {
    ...notification,
    neweventstart: dateUtils.dateToString(
      dateUtils.utc(notification.neweventstart),
    )!,
    ...(neweventend && { neweventend }),
    draft,
    changestateto,
  };
};

export interface ConstructRepopulateNotificationType extends SWNotification {
  IS_POPULATE_REQUEST: boolean;
}

export const constructRepopulateNotification = (
  _notification: ConstructRepopulateNotificationType,
  actionMode = '',
): SWNotification => {
  const { IS_POPULATE_REQUEST, ...notificationToPost } = _notification;
  // strip empty values
  const notification = Object.keys(notificationToPost).reduce((list, key) => {
    if (
      notificationToPost[key as keyof SWNotification] !== null &&
      notificationToPost[key as keyof SWNotification] !== ''
    ) {
      return {
        ...list,
        [key]: notificationToPost[key as keyof SWNotification],
      };
    }
    return list;
  }, {}) as SWNotification;

  const neweventend = notification.neweventend
    ? dateUtils.dateToString(dateUtils.utc(notification.neweventend))
    : undefined;

  const changestateto =
    actionMode === 'Summarise' || actionMode === 'Cancel' ? 'ended' : '';

  return {
    ...notification,
    neweventstart: dateUtils.dateToString(
      dateUtils.utc(notification.neweventstart),
    )!,
    ...(neweventend && { neweventend }),
    changestateto,
  };
};

export const getNewInternalStatusTagContent = (
  actionMode: string,
  label: string,
  categorydetail = '',
): string => {
  if (actionMode === 'Summarise') {
    return 'Summary';
  }
  if (actionMode === 'Cancel') {
    return 'Cancelled';
  }
  if (label === 'WARNING') {
    if (categorydetail === 'GEOMAGNETIC_STORM') {
      return 'Watch';
    }
    return 'Warning';
  }
  return 'Alert';
};

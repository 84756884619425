/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputAdornment,
  LinearProgress,
} from '@mui/material';

import { Close, Copy } from '@opengeoweb/theme';
import {
  defaultFormOptions,
  ReactHookFormProvider,
  ReactHookFormTextField,
} from '@opengeoweb/form-fields';
import {
  useFormContext,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import { CustomIconButton } from '@opengeoweb/shared';

import { serviceTypes } from '@opengeoweb/store';
import { TFunction } from 'i18next';
import {
  loadWMSService,
  validateServiceName,
  validateServiceUrl,
  ValidationResult,
} from './utils';
import { layerSelectTypes } from '../../store';
import { useLayerSelectTranslation } from '../../utils/i18n';

const style = {
  textField: {
    width: '100%',
    marginBottom: 2,
  },
};

export interface ServicePopupProps {
  servicePopupVariant: layerSelectTypes.PopupVariant;
  hideBackdrop?: boolean;
  isOpen?: boolean;
  closePopup?: () => void;
  shouldDisableAutoFocus?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  serviceId?: string;
  serviceUrl?: string;
  serviceName?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  serviceAbstracts?: string;
  services?: layerSelectTypes.ActiveServiceObjectEntities;
  serviceSetLayers: (payload: serviceTypes.SetLayersForServicePayload) => void;
  showSnackbar?: (message: string) => void;
}

export const getSuccesMessage = (serviceName: string, t: TFunction): string =>
  t('get-success-message', { serviceName });

const getTitleForVariant = (
  t: TFunction,
  variant: layerSelectTypes.PopupVariant,
): string => {
  switch (variant) {
    case 'add':
      return t('add-a-new-service');
    case 'edit':
      return t('edit-service-title');
    case 'save':
      return t('save-service-title');
    default:
      return 'Service';
  }
};

interface InitialValidationsTriggerProps {
  trigger: UseFormTrigger<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  onValidField: (value: string) => void;
}

enum FormFields {
  serviceUrl = 'serviceUrl',
  serviceName = 'serviceName',
  serviceAbstracts = 'serviceAbstracts',
}
type FormValues = Record<FormFields, string>;

const InitialValidationsTrigger: React.FC<InitialValidationsTriggerProps> = ({
  trigger,
  getValues,
  onValidField,
}: InitialValidationsTriggerProps) => {
  React.useEffect(() => {
    const value = getValues(FormFields.serviceUrl);
    if (value) {
      void trigger(FormFields.serviceUrl).then(
        (isValid) => isValid && onValidField(value),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

const ServicePopupUnwrapped: React.FC<ServicePopupProps> = ({
  servicePopupVariant,
  hideBackdrop = false,
  isOpen,
  closePopup,
  shouldDisableAutoFocus,
  services,
  serviceSetLayers,
  serviceUrl,
  serviceName,
  showSnackbar = (): void => {},
}: ServicePopupProps) => {
  const { t } = useLayerSelectTranslation();
  const {
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    watch,
    trigger,
    getValues,
    setValue,
  } = useFormContext<FormValues>();

  const [isLoading, setIsLoading] = React.useState(false);
  const [shouldForceReload, setShouldForceReload] = React.useState(false);

  const fetchServiceData = async (newServiceUrl: string): Promise<void> => {
    try {
      setIsLoading(true);

      const service = await loadWMSService(newServiceUrl, true);

      setValue(FormFields.serviceName, service.name);
      setValue(FormFields.serviceAbstracts, service.abstract || '');
      setIsLoading(false);
      setShouldForceReload(false);
    } catch (error) {
      setIsLoading(false);
      setShouldForceReload(true);
      setError(FormFields.serviceUrl, {
        message: t('validations-request-failed'),
        type: 'SYSTEM',
      });
    }
  };

  const addNewService = async (formValues: FormValues): Promise<void> => {
    clearErrors(FormFields.serviceUrl);
    setIsLoading(true);
    try {
      const service = await loadWMSService(
        formValues.serviceUrl,
        shouldForceReload,
      );

      serviceSetLayers({
        id: service.id,
        name: formValues.serviceName,
        serviceUrl: formValues.serviceUrl,
        abstract: formValues.serviceAbstracts,
        layers: service.layers,
        scope: 'user',
      });
      setIsLoading(false);
      showSnackbar(getSuccesMessage(formValues.serviceName, t));
      closePopup!();
    } catch (error) {
      setIsLoading(false);
      setError(FormFields.serviceUrl, {
        message: t('validations-request-failed'),
        type: 'SYSTEM',
      });
    }
  };

  const onValidateServiceUrl = (value: string): ValidationResult =>
    validateServiceUrl(t, value, services!);

  const onFocusServiceUrl = (): void => {
    const hasValue = getValues(FormFields.serviceUrl);
    if (!errors[FormFields.serviceUrl] && hasValue) {
      void trigger(FormFields.serviceUrl);
    }
  };

  const onBlurServiceUrl = (): void => {
    void trigger(FormFields.serviceUrl).then(() => {
      const hasValue = getValues(FormFields.serviceUrl);
      if (!errors[FormFields.serviceUrl] && hasValue) {
        void fetchServiceData(getValues(FormFields.serviceUrl));
      }
    });
  };

  const onKeyDownServiceUrl = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      onBlurServiceUrl();
    }
  };

  const onValidateServiceName = (value: string): ValidationResult =>
    validateServiceName(t, value, services!, serviceName);

  const getFormAreRequiredFieldsEmpty = (): boolean => {
    const serviceNameField = watch(FormFields.serviceName);
    const serviceUrlField = watch(FormFields.serviceUrl);

    return (
      serviceNameField === '' ||
      serviceNameField === undefined ||
      serviceUrlField === '' ||
      serviceUrlField === undefined
    );
  };

  const getFormHasformErrors = (): boolean => {
    const totalErrors = Object.keys(errors).length;
    if (totalErrors > 0) {
      // prevent SYSTEM error types from blocking the save button
      if (totalErrors === 1 && errors.serviceUrl) {
        return (
          errors.serviceUrl.type !== undefined &&
          errors.serviceUrl.type !== 'SYSTEM'
        );
      }
      return true;
    }
    return false;
  };

  const isSubmitDisabled =
    getFormHasformErrors() || getFormAreRequiredFieldsEmpty();

  const isShowPopup = servicePopupVariant === 'show';
  const isServiceUrlDisabled = isShowPopup || servicePopupVariant === 'edit';

  return (
    <Dialog
      open={isOpen!}
      hideBackdrop={hideBackdrop}
      fullWidth
      maxWidth="xs"
      data-testid="servicePopup"
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: `geowebColors.cards.cardContainer`,
          maxWidth: 458,
        },
      }}
    >
      <form
        onSubmit={handleSubmit((formValues) =>
          addNewService(formValues as FormValues),
        )}
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            padding: '16px 16px 24px 16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ' span': {
                fontSize: '1rem',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '1.38',
                letterSpacing: '0.12px',
              },
            }}
          >
            <Typography variant="h5" component="span">
              {getTitleForVariant(t, servicePopupVariant)}
            </Typography>
            <CustomIconButton
              aria-label="close"
              onClick={closePopup}
              data-testid="closePopupButtonCross"
              sx={{
                marginTop: 'auto',
                marginBottom: 'auto',
                '&.MuiButtonBase-root': {
                  color: 'geowebColors.greys.accessible',
                },
              }}
            >
              <Close />
            </CustomIconButton>
          </Box>
          {isLoading && (
            <Box sx={{ position: 'relative' }}>
              <LinearProgress
                data-testid="loadingIndicator"
                color="secondary"
                sx={{ position: 'absolute', width: '100%', top: 8 }}
              />
            </Box>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '8px 16px',
          }}
        >
          <ReactHookFormTextField
            name={FormFields.serviceUrl}
            label={t('service-url-label')}
            onFocus={onFocusServiceUrl}
            autoFocus={!shouldDisableAutoFocus}
            onBlur={onBlurServiceUrl}
            onKeyDown={onKeyDownServiceUrl}
            sx={style.textField}
            rules={{
              required: true,
              validate: {
                ...(!isServiceUrlDisabled && {
                  onValidateServiceUrl,
                }),
              },
            }}
            disabled={isServiceUrlDisabled || isLoading}
            isReadOnly={isShowPopup}
            inputSlotProps={{
              disableUnderline: isShowPopup,
              endAdornment: isShowPopup && (
                <InputAdornment position="end">
                  <CustomIconButton
                    aria-label="copy url"
                    onClick={(): void => {
                      void navigator.clipboard.writeText(serviceUrl!);
                      showSnackbar(t('copy-url-message'));
                    }}
                    sx={{ marginTop: 0.5 }}
                  >
                    <Copy />
                  </CustomIconButton>
                </InputAdornment>
              ),
            }}
            defaultValue={serviceUrl}
          />

          <ReactHookFormTextField
            name={FormFields.serviceName}
            label={t('service-name-label')}
            rules={{
              required: true,
              validate: {
                onValidateServiceName,
              },
            }}
            disabled={isShowPopup || isLoading}
            isReadOnly={isShowPopup}
            inputSlotProps={{
              disableUnderline: isShowPopup,
            }}
            sx={style.textField}
            defaultValue={serviceName}
          />

          <ReactHookFormTextField
            multiline
            rows={4}
            name={FormFields.serviceAbstracts}
            label={t('service-abstracts-label')}
            disabled={isShowPopup || isLoading}
            isReadOnly={isShowPopup}
            inputSlotProps={{
              disableUnderline: isShowPopup,
            }}
            helperText={!isShowPopup ? t('service-popup-helper-text') : ''}
            sx={style.textField}
            defaultValue=""
          />

          {!isServiceUrlDisabled && (
            <InitialValidationsTrigger
              trigger={trigger}
              getValues={getValues}
              onValidField={fetchServiceData}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
          }}
        >
          {isShowPopup ? (
            <Button
              onClick={(): void => closePopup!()}
              variant="tertiary"
              sx={{ width: '117px' }}
            >
              {t('close')}
            </Button>
          ) : (
            <>
              <Button
                onClick={closePopup}
                data-testid="closePopupButtonCancel"
                variant="tertiary"
                sx={{ width: '117px' }}
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitDisabled || isLoading}
                data-testid="saveServiceButton"
                sx={{ width: '117px', marginLeft: '16px!important' }}
              >
                {t('save')}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const ServicePopup: React.FC<ServicePopupProps> = ({
  ...props
}: ServicePopupProps) => (
  <ReactHookFormProvider
    options={{
      ...defaultFormOptions,
      defaultValues: {
        serviceUrl: props.serviceUrl,
        serviceName: props.serviceName,
        serviceAbstracts: props.serviceAbstracts,
      },
    }}
  >
    <ServicePopupUnwrapped {...props} />
  </ReactHookFormProvider>
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PROJECTION } from '@opengeoweb/shared';
import {
  defaultGeoJSONStyleProperties,
  defaultIntersectionStyleProperties,
} from '@opengeoweb/webmap-react';

export const srsAndBboxDefault = {
  bbox: {
    left: -911501,
    right: 3738819,
    top: 11688874,
    bottom: 5930186,
  },
  srs: PROJECTION.EPSG_3857.value,
};

export const simplePolygonGeoJSON: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        stroke: '#f5a623',
        'stroke-width': 5,
        'stroke-opacity': 1,
        fill: '#f5a623',
        'fill-opacity': 0.25,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.21875, 54.16243396806779],
            [-0.3515625, 45.583289756006316],
            [14.0625, 49.38237278700955],
            [11.953125, 58.07787626787517],
            [1.40625, 57.70414723434193],
            [-4.21875, 54.16243396806779],
          ],
        ],
      },
    },
  ],
};

export const simplePolygonGeoJSONHalfOfNL: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [4.921875, 51.767839887322154],
            [4.7900390625, 49.97242235423708],
            [7.679443359375, 49.79544988802771],
            [7.888183593749999, 51.74743863117572],
            [5.86669921875, 51.984880139916626],
            [4.921875, 51.767839887322154],
          ],
        ],
      },
    },
  ],
};

export const featurePropsStart = defaultGeoJSONStyleProperties;

export const featurePropsEnd = {
  stroke: '#6e1e91',
  'stroke-width': 1.5,
  'stroke-opacity': 1,
  fill: '#6e1e91',
  'fill-opacity': 0.25,
};

export const featurePropsIntersectionStart = defaultIntersectionStyleProperties;

export const featurePropsIntersectionEnd = {
  ...featurePropsEnd,
  'fill-opacity': 0.5,
};

export const featurePropsFIR = {
  stroke: '#0075a9',
  'stroke-width': 1.5,
  'stroke-opacity': 1,
  fill: '#0075a9',
  'fill-opacity': 0.0,
};

export const featurePropsUnStyled = {
  stroke: '#FF0000',
  'stroke-width': 10.0,
  'stroke-opacity': 1,
  fill: '#0000FF',
  'fill-opacity': 1.0,
};

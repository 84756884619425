/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

// custom added properties
export interface CSSProperties extends React.CSSProperties {
  rgba?: string;
  opacity?: number;
}

export interface NamedTheme {
  name: ThemeTypes;
  theme: Theme;
}

export enum ThemeTypes {
  DARK_THEME = 'darkTheme',
  LIGHT_THEME = 'lightTheme',
  XMAS2024_THEME = 'xmas2024Theme',
}

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'flat'
  | 'tool'
  | 'boxed'
  | 'icon';

export interface ButtonStyle {
  default: CSSProperties;
  mouseOver: CSSProperties;
  active: CSSProperties;
  activeMouseOver: CSSProperties;
  disabled: CSSProperties;
  focus: CSSProperties;
}

// see zeplin for naming convention https://app.zeplin.io/project/5ecf84a3c6ae1047a368f393/screen/609bb6a5287bd1abe0de39a7
export interface GeowebColorPalette {
  background: {
    surface: CSSProperties['color'];
    surfaceApp: CSSProperties['color'];
    surfaceBrowser: CSSProperties['color'];
  };
  brand: {
    brand: CSSProperties['color'];
  };
  buttons: {
    primary: ButtonStyle;
    secondary: ButtonStyle;
    tertiary: ButtonStyle;
    flat: ButtonStyle;
    tool: ButtonStyle;
    boxed: ButtonStyle;
    icon: ButtonStyle;
  };
  iconButtons: {
    flat: ButtonStyle;
    tool: ButtonStyle;
    boxed: ButtonStyle;
  };
  typographyAndIcons: {
    text: CSSProperties['color'];
    icon: CSSProperties['color'];
    buttonIcon: CSSProperties['color'];
    iconLinkActive: CSSProperties['color'];
    iconLinkDisabled: CSSProperties['color'];
    iconWarning: CSSProperties['color'];
    textLinkActive: CSSProperties['color'];
    buttonIconTertiaryFlat: CSSProperties['color'];
    inactiveText: CSSProperties['color'];
  };
  textInputField: {
    default: CSSProperties;
    active: CSSProperties;
    disabled: CSSProperties;
    label: CSSProperties;
    mouseOver: CSSProperties;
    error: CSSProperties;
    errorMouseOver: CSSProperties;
  };
  menuItem: {
    mouseOver: CSSProperties['color'];
    selected: CSSProperties['color'];
    selectedMouseOver: CSSProperties['color'];
  };
  tab: {
    mouseOver: CSSProperties;
  };
  greys: {
    accessible: CSSProperties['color'];
    accessibleLight: CSSProperties['color'];
  };
  cards: {
    cardContainer: CSSProperties['color'];
    cardContainerBorder: CSSProperties['color'];
    cardContainerMouseOver: CSSProperties['color'];
    cardContainerDisabled: CSSProperties['color'];
    cardContainerDisabledBorder: CSSProperties['color'];
    cardContainerDisabledMouseOver: CSSProperties['color'];
  };
  backdrops: {
    black: CSSProperties;
    white: CSSProperties;
  };
  workspace: {
    activeWindow: CSSProperties['color'];
    inactiveWindow: CSSProperties['color'];
    tabButtonHover: CSSProperties['color'];
  };
  timeSlider: {
    // player
    playerNeedleTimeTop: CSSProperties;
    playerNeedleTime: CSSProperties;
    playerNeedlePlayerTop: CSSProperties;
    playerNeedlePlayer: CSSProperties;
    playerTimeMarkers: CSSProperties;
    playerTimeText: CSSProperties;
    // timeline
    timelineIndicator: CSSProperties;
    timelineText: CSSProperties;
    timelineTimeScale: CSSProperties;
    timelineNightTime: CSSProperties;
    timelineTimelineSurface: CSSProperties;
    timelineMonthChangeDash: CSSProperties;
    timelineSelectionBackground: CSSProperties;
    timelineSelectionOutline: CSSProperties;
    // time scale
    timeScalePointer: CSSProperties;
    timeScaleHorizontalScale: CSSProperties;
    timeScaleTimeIndicators: CSSProperties;
    timeScaleTimeIndicatorsActive: CSSProperties;
    timeScaleShadowButtonScale: CSSProperties;
    // legend
    ledgendObservedBackground: CSSProperties;
    legendForecastBackground: CSSProperties;
    legendNoDataBackground: CSSProperties;
  };
  timeSliderLite: {
    selected: CSSProperties;
    needle: CSSProperties;
    needleDefault: CSSProperties;
    needleShadow: CSSProperties;
    needleLabel: CSSProperties;
    timeline: CSSProperties;
    timelineBackground: CSSProperties;
    timelineBackgroundAlternative: CSSProperties;
    timelineBackgroundDisabled: CSSProperties;
    timelineBackgroundDisabledAlternative: CSSProperties;
    timelineBackgroundObserved: CSSProperties;
    timelineBackgroundObservedAlternative: CSSProperties;
  };
  customSlider: {
    rail: CSSProperties['color'];
    railDisabled: CSSProperties['color'];
    track: CSSProperties['color'];
    trackDisabled: CSSProperties['color'];
    thumb: CSSProperties['color'];
    thumbDisabled: CSSProperties['color'];
    mark: CSSProperties['color'];
    markDisabled: CSSProperties['color'];
  };
  customSwitch: {
    thumb: CSSProperties['color'];
    thumbDisabled: CSSProperties['color'];
    track: CSSProperties['color'];
    trackActive: CSSProperties['color'];
  };
  syncGroups: {
    drawerOpen: CSSProperties;
  };
  logo?: {
    image: string;
  };
  layerManager: {
    headerRowText: CSSProperties;
    tableRowDefaultText: CSSProperties;
    tableRowDefaultCardContainer: CSSProperties;
    tableRowDisabledText: CSSProperties;
    tableRowDisabledCardContainer: CSSProperties;
    dragHandleHover: CSSProperties;
    root: SxProps<Theme>;
    opacitySelect: {
      root: SxProps<Theme>;
      button: SxProps<Theme>;
      popper: SxProps<Theme>;
    };
    baseLayerRow: {
      root: SxProps<Theme>;
    };
  };
  tooltips: {
    tooltipContainer: CSSProperties;
    tooltipText: CSSProperties;
    tooltipSelect: {
      select: SxProps<Theme>;
      menu: SxProps<Theme>;
    };
  };
  captions: {
    captionStatus: CSSProperties;
    captionInformation: CSSProperties;
    captionError: CSSProperties;
    captionErrorBackground: CSSProperties;
    captionDisabled: CSSProperties;
  };
  functional: {
    success: CSSProperties['color'];
    successOutline: CSSProperties;
    error: CSSProperties['color'];
    errorOutline: CSSProperties;
    warning: CSSProperties['color'];
    warningOutline: CSSProperties;
    warningHighlight: CSSProperties;
    notification: CSSProperties['color'];
    notificationOutline: CSSProperties;
    summary: CSSProperties['color'];
    summaryOutline: CSSProperties;
    disabled: CSSProperties['color'];
    disabledOutline: CSSProperties;
  };
  snackbar: {
    background: CSSProperties['color'];
    text: CSSProperties['color'];
    action: CSSProperties['color'];
    actionHover: CSSProperties;
  };
  festoonOverlay?: CSSProperties;
  festoonContainer?: CSSProperties;
  festoonBackdrop?: CSSProperties;
  chip: {
    highlightDefault: CSSProperties;
    highlightDefaultMouseOver: CSSProperties;
    highlightActiveDefault: CSSProperties;
    highlightActiveMouseOver: CSSProperties;
  };
}

export type Elevations = Record<string, string>;

declare module '@mui/material/styles' {
  interface Palette {
    geowebColors: GeowebColorPalette;
  }
  interface PaletteOptions {
    geowebColors: GeowebColorPalette;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    flat: true;
    tool: true;
    boxed: true;
    icon: true;
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  CoreAppStore,
  uiActions,
  uiTypes,
  drawingToolSelectors,
  layerActions,
  drawingToolActions,
  layerSelectors,
  getSingularDrawtoolDrawLayerId,
  uiSelectors,
} from '@opengeoweb/store';
import { defaultPolygon, emptyGeoJSON } from '@opengeoweb/webmap-react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationOptions, useConfirmationDialog } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import { warningsDrawingsActions } from '../../store/warningsDrawings/reducer';

import {
  drawingDialogType,
  objectDialogType,
} from '../../store/warningsDrawings/utils';
import { getWarningFormDirty } from '../../store/warningsDrawings/selectors';
import { useWarningsTranslation } from '../../utils/i18n';

export const confirmationDialogOptions = (
  t: TFunction,
): ConfirmationOptions => {
  return {
    cancelLabel: t('close-dialog-cancel'),
    title: t('close-draw-dialog-title'),
    description: t('close-dialog-description'),
    confirmLabel: t('close-dialog-confirm'),
    catchOnCancel: true,
  };
};

export const useCloseDrawDialog = ({
  mapId,
  source,
}: {
  mapId: string;
  source?: uiTypes.Source;
}): { closeDrawDialog: (skipConfirmationDialog?: boolean) => void } => {
  const { t } = useWarningsTranslation();
  const dispatch = useDispatch();
  const drawLayerId = getSingularDrawtoolDrawLayerId(mapId);

  const drawDialogActiveDrawModeId = useSelector((store: CoreAppStore) =>
    drawingToolSelectors.getActiveDrawModeId(store, drawingDialogType),
  );

  const featureLayerContainsGeoJSON = useSelector((store: CoreAppStore) =>
    layerSelectors.getHasFeatureLayerGeoJSON(store, drawLayerId),
  );
  const dialogError = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogError(store, drawingDialogType),
  );

  const isFormDirty = useSelector((store: CoreAppStore) =>
    getWarningFormDirty(store, drawingDialogType),
  );
  const confirmDialog = useConfirmationDialog();

  const closeDrawDialog = async (
    skipConfirmationDialog = false,
  ): Promise<void> => {
    if (isFormDirty && !skipConfirmationDialog) {
      const mayProceed = await confirmDialog(confirmationDialogOptions(t))
        .then(() => true)
        .catch(() => false);

      if (!mayProceed) {
        return;
      }
    }

    if (drawDialogActiveDrawModeId) {
      // disable the edit layer
      dispatch(
        layerActions.toggleFeatureMode({
          layerId: drawLayerId,
          isInEditMode: false,
          drawMode: '',
        }),
      );
      // reset active draw tool
      dispatch(
        drawingToolActions.changeDrawToolMode({
          drawToolId: drawingDialogType,
          drawModeId: drawDialogActiveDrawModeId,
        }),
      );
    }
    // close draw dialog
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: drawingDialogType,
        mapId,
        setOpen: false,
        source,
      }),
    );

    // reset all drawings fields
    dispatch(
      warningsDrawingsActions.setDrawValues({
        drawingInstanceId: drawingDialogType,
        objectName: '',
        id: '',
      }),
    );

    // reset error
    if (dialogError) {
      dispatch(
        uiActions.setErrorDialog({
          type: drawingDialogType,
          error: '',
        }),
      );
    }

    // clear existing geoJSON
    if (featureLayerContainsGeoJSON) {
      dispatch(
        layerActions.layerChangeGeojson({
          layerId: drawLayerId,
          geojson: emptyGeoJSON,
        }),
      );
    }
  };

  return { closeDrawDialog };
};

export const useCloseObjectDialog = ({
  mapId,
  source,
}: {
  mapId: string;
  source?: uiTypes.Source;
}): { closeObjectDialog: (shouldReset?: boolean) => void } => {
  const dispatch = useDispatch();
  const drawLayerId = getSingularDrawtoolDrawLayerId(mapId);

  const featureLayerContainsGeoJSON = useSelector((store: CoreAppStore) =>
    layerSelectors.getHasFeatureLayerGeoJSON(store, drawLayerId),
  );

  const closeObjectDialog = (shouldReset = true): void => {
    // reset active object
    if (shouldReset) {
      dispatch(
        warningsDrawingsActions.setDrawValues({
          drawingInstanceId: drawingDialogType,
          objectName: '',
          id: '',
        }),
      );
    }

    // close object dialog
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: objectDialogType,
        mapId,
        setOpen: false,
        source,
      }),
    );

    if (featureLayerContainsGeoJSON && shouldReset) {
      dispatch(
        layerActions.layerChangeGeojson({
          layerId: drawLayerId,
          geojson: emptyGeoJSON,
        }),
      );
    }
  };

  return { closeObjectDialog };
};

const useDialogOptions = ({
  dialogType,
}: {
  dialogType: uiTypes.DialogTypes;
}): {
  isDialogOpen: boolean;
  dialogMapId: string;
  dialogLayerId: string;
  hasGeoJSON: boolean;
} => {
  const isDialogOpen = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, dialogType),
  );
  const dialogMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );
  const dialogLayerId = getSingularDrawtoolDrawLayerId(dialogMapId);

  const hasGeoJSON = useSelector((store: CoreAppStore) =>
    layerSelectors.getHasFeatureLayerGeoJSON(store, dialogLayerId),
  );

  return { isDialogOpen, dialogMapId, hasGeoJSON, dialogLayerId };
};

export const useObjectDrawDialogAction = ({
  mapId,
  source,
}: {
  mapId: string;
  source?: uiTypes.Source;
}): {
  openDrawDialog: (geoJSON?: GeoJSON.FeatureCollection) => void;
  closeDrawDialog: () => void;
  openObjectDialog: () => void;
  closeObjectDialog: (shouldReset?: boolean) => void;
} => {
  const { t } = useWarningsTranslation();
  const dispatch = useDispatch();
  const confirmDialog = useConfirmationDialog();

  const currentDrawLayerId = getSingularDrawtoolDrawLayerId(mapId);
  // draw selectors
  const {
    isDialogOpen: isDrawDialogOpen,
    hasGeoJSON: drawDialogLayerHasGeoJSON,
    dialogLayerId: drawDialogLayerId,
  } = useDialogOptions({ dialogType: drawingDialogType });

  const drawDialogActiveDrawModeId = useSelector((store: CoreAppStore) =>
    drawingToolSelectors.getActiveDrawModeId(store, drawingDialogType),
  );

  const isFormDirty = useSelector((store: CoreAppStore) =>
    getWarningFormDirty(store, drawingDialogType),
  );

  // object selectors
  const {
    isDialogOpen: isObjectDialogOpen,
    hasGeoJSON: objectDialogLayerHasGeoJSON,
    dialogLayerId: objectDialogLayerId,
  } = useDialogOptions({ dialogType: objectDialogType });

  const clearGeoJSON = (layerId: string): void => {
    dispatch(
      layerActions.layerChangeGeojson({
        layerId,
        geojson: emptyGeoJSON,
      }),
    );
  };

  const openObjectDialog = async (): Promise<void> => {
    if (isDrawDialogOpen) {
      if (isFormDirty) {
        const mayProceed = await confirmDialog(confirmationDialogOptions(t))
          .then(() => true)
          .catch(() => false);

        if (!mayProceed) {
          return;
        }
      }

      if (drawDialogLayerHasGeoJSON) {
        clearGeoJSON(drawDialogLayerId);
      }
      closeDrawDialog(true);
    }

    // open object dialog
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: objectDialogType,
        mapId,
        setOpen: true,
        source,
      }),
    );

    // move shape to screen
    if (isObjectDialogOpen && objectDialogLayerHasGeoJSON) {
      // update new shape
      dispatch(
        layerActions.layerSetGeojsonFromLayer({
          layerId: currentDrawLayerId,
          sourceLayerId: objectDialogLayerId,
        }),
      );
      // remove current shape
      clearGeoJSON(objectDialogLayerId);
    }
  };

  const openDrawDialog = (newGeoJSON?: GeoJSON.FeatureCollection): void => {
    if (isObjectDialogOpen) {
      if (objectDialogLayerHasGeoJSON) {
        clearGeoJSON(objectDialogLayerId);
      }
      const shouldResetShape = newGeoJSON === undefined;
      closeObjectDialog(shouldResetShape);
    }

    // open draw dialog
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: drawingDialogType,
        mapId,
        setOpen: true,
        source,
      }),
    );

    // activate draw tool
    dispatch(
      drawingToolActions.updateGeoJSONLayerId({
        drawToolId: drawingDialogType,
        geoJSONLayerId: currentDrawLayerId,
      }),
    );

    // activate default draw mode
    if (!drawDialogActiveDrawModeId && !isDrawDialogOpen) {
      dispatch(
        drawingToolActions.changeDrawToolMode({
          drawToolId: drawingDialogType,
          drawModeId: defaultPolygon.drawModeId,
        }),
      );
    }

    // move shape to screen
    if (isDrawDialogOpen && drawDialogLayerHasGeoJSON) {
      // update new shape
      dispatch(
        layerActions.layerSetGeojsonFromLayer({
          layerId: currentDrawLayerId,
          sourceLayerId: drawDialogLayerId,
        }),
      );
      // remove current shape
      clearGeoJSON(drawDialogLayerId);
    }

    if (drawDialogActiveDrawModeId) {
      // activate current
      dispatch(
        layerActions.toggleFeatureMode({
          layerId: currentDrawLayerId,
          isInEditMode: true,
          drawMode: drawDialogActiveDrawModeId,
        }),
      );

      // deactivate previous
      dispatch(
        layerActions.toggleFeatureMode({
          layerId: drawDialogLayerId,
          isInEditMode: false,
          drawMode: '',
        }),
      );
    }

    if (newGeoJSON) {
      dispatch(
        layerActions.layerChangeGeojson({
          layerId: currentDrawLayerId,
          geojson: newGeoJSON,
        }),
      );
    }
  };

  const { closeDrawDialog } = useCloseDrawDialog({
    mapId,
    source,
  });

  const { closeObjectDialog } = useCloseObjectDialog({ mapId, source });

  return {
    closeDrawDialog,
    closeObjectDialog,
    openObjectDialog,
    openDrawDialog,
  };
};

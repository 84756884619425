/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CustomToggleButton, dateUtils } from '@opengeoweb/shared';
import { VariableSizeList as List } from 'react-window';
import { Stack, ListItem, Paper, Typography, Box, styled } from '@mui/material';
import React, { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { TFunction } from 'i18next';
import { DrawingListItem } from '../../store/warningsDrawings/types';
import { useWarningsTranslation } from '../../utils/i18n';
import { Area } from '../../store/publicWarningForm/types';

const OBJECT_ITEM_SIZE = 44;
const OBJECT_ITEM_SIZE_SMALL_WIDTH = 72;
const HEADER_ITEM_SIZE = 28;
const BREAKPOINT = 320;
const ERROR_HEIGHT = 48;

interface ObjectAreaList {
  title: string;
  items: DrawingListItem[];
}

export const useGetFilterHeight = (
  filterRef?: React.MutableRefObject<HTMLElement>,
): number => {
  const [filterHeight, setFilterHeight] = React.useState(0);

  React.useEffect(() => {
    let resizeObserver: ResizeObserver;
    if (filterRef) {
      resizeObserver = new ResizeObserver((entry) => {
        const filterHeight = entry[0].target.getBoundingClientRect().height;
        setFilterHeight(filterHeight);
      });

      resizeObserver.observe(filterRef.current);
    }
    return (): void => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [filterRef]);

  return filterHeight;
};

const isArea = (item: DrawingListItem): boolean =>
  !item.keywords?.includes('Objects');

export const getListInclHeaders = (
  items: DrawingListItem[],
  translateFn: TFunction,
): { objects: ObjectAreaList; areas: ObjectAreaList } => {
  const { objects, areas } = items.reduce<{
    areas: DrawingListItem[];
    objects: DrawingListItem[];
  }>(
    ({ objects, areas }, item) => {
      const isObject = !isArea(item);
      if (isObject) {
        return { areas, objects: objects.concat(item) };
      }
      return { areas: areas.concat(item), objects };
    },
    {
      objects: [],
      areas: [],
    },
  );

  return {
    objects: {
      title: `${objects.length} ${translateFn('objects')}`,
      items: objects,
    },
    areas: {
      title: `${areas.length} ${translateFn('areas')}`,
      items: areas,
    },
  };
};

export const getDisplayDate = (date: string): string =>
  `${dateUtils.dateToString(
    dateUtils.utc(date),
    dateUtils.DATE_FORMAT_NAME_OF_DAY,
  )} UTC`;

const ListItemAction: React.FC<{
  listItem: DrawingListItem;
  selectedAreas: Area[];
  onAddArea: (drawingListItem: DrawingListItem) => void;
  onRemoveArea: (drawingListItem: DrawingListItem) => void;
  isDisabled: boolean;
  t: TFunction;
}> = ({ listItem, selectedAreas, onAddArea, onRemoveArea, isDisabled, t }) => {
  const isAreaSelected = !!selectedAreas.find(
    (area) => area.objectName === listItem.objectName,
  );
  const label = t(!isAreaSelected ? 'add-button' : 'remove-button');

  const onClick = (): void => {
    isAreaSelected ? onRemoveArea(listItem) : onAddArea(listItem);
  };

  return (
    <CustomToggleButton
      sx={{
        fontSize: '12px',
        height: '22px!important',
        minWidth: '60px',
      }}
      variant="tool"
      onClick={onClick}
      selected={isAreaSelected}
      disabled={isDisabled}
    >
      {label}
    </CustomToggleButton>
  );
};

const ContainerDiv = styled('div')(() => ({
  '& .MuiListItem-root': {
    paddingBottom: '2px',
  },
  '& .MuiPaper-root': {
    height: '100%',
    display: 'grid',
  },
  '& .MuiListItemSecondaryAction-root': {
    right: '12px',
  },
  '& .MuiStack-root': {
    paddingRight: '68px',
    width: '100%',
    overflowWrap: 'anywhere',
    overflow: 'hidden',
    justifyContent: 'center',
    paddingLeft: '4px',
  },
}));

export const AreasObjects: FC<{
  drawingListItems: DrawingListItem[];
  isLoading?: boolean;
  hasError?: boolean;
  filterRef?: React.MutableRefObject<HTMLElement>;
  onAddArea?: (drawingListItem: DrawingListItem) => void;
  onRemoveArea?: (drawingListItem: DrawingListItem) => void;
  selectedAreas?: Area[];
  isChangeAreaDisabled?: boolean;
}> = ({
  drawingListItems,
  isLoading = false,
  hasError = false,
  filterRef,
  onAddArea = (): void => {},
  onRemoveArea = (): void => {},
  selectedAreas = [],
  isChangeAreaDisabled = true,
}) => {
  const { t } = useWarningsTranslation();
  const listRef = React.useRef<List<DrawingListItem>>(null);
  const { objects, areas } = getListInclHeaders(drawingListItems, t);
  const filterHeight = useGetFilterHeight(filterRef);

  React.useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [drawingListItems]);

  const items = [objects.title, ...objects.items, areas.title, ...areas.items];
  const getItemSize = (width: number, index: number): number => {
    const item = items[index];
    if (typeof item !== 'string') {
      return width < BREAKPOINT
        ? OBJECT_ITEM_SIZE_SMALL_WIDTH
        : OBJECT_ITEM_SIZE;
    }
    return HEADER_ITEM_SIZE;
  };

  const totalContainerHeight = hasError
    ? filterHeight + ERROR_HEIGHT
    : filterHeight;

  return (
    <Box sx={{ height: `calc(100% - ${totalContainerHeight}px)` }}>
      <AutoSizer
        onResize={(): void => {
          if (listRef.current !== null) {
            listRef.current.resetAfterIndex(0, true);
          }
        }}
      >
        {({ height = 0, width = 0 }): React.ReactNode => (
          <Box sx={{ width }}>
            {!isLoading && drawingListItems.length === 0 ? (
              <Typography
                sx={{ paddingLeft: '16px', paddingTop: '8px' }}
                variant="body1"
              >
                {t('area-object-loader-no-areas-objects')}
              </Typography>
            ) : (
              <List
                height={height}
                width={width}
                itemCount={items.length}
                itemSize={(index): number => getItemSize(width, index)}
                ref={listRef}
              >
                {({ index, style }): React.ReactElement => {
                  const listItem = items[index];
                  if (typeof listItem === 'string') {
                    return (
                      <Typography
                        style={style}
                        key={index}
                        sx={{
                          paddingLeft: 2.5,
                          paddingBottom: 0.5,
                          paddingTop: 1,
                          opacity: 0.67,
                          lineHeight: '16px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                        variant="caption"
                      >
                        {listItem}
                      </Typography>
                    );
                  }

                  return (
                    <ContainerDiv>
                      <ListItem
                        style={style}
                        disablePadding
                        key={listItem.id}
                        secondaryAction={
                          <ListItemAction
                            listItem={listItem}
                            selectedAreas={selectedAreas}
                            onAddArea={onAddArea}
                            onRemoveArea={onRemoveArea}
                            t={t}
                            isDisabled={isChangeAreaDisabled}
                          />
                        }
                      >
                        <Paper
                          elevation={0}
                          sx={{
                            width: '100%',
                            margin: 0.25,
                            backgroundColor: 'geowebColors.background.surface',
                            border: 'solid 1px',
                            borderColor:
                              'geowebColors.cards.cardContainerBorder',
                          }}
                        >
                          <Stack>
                            <Typography
                              variant="caption"
                              sx={{
                                lineHeight: '16px',
                                maxHeight: '32px',
                                overflow: 'hidden',
                              }}
                            >
                              {listItem.objectName}
                            </Typography>
                            {!isArea(listItem) && (
                              <Typography
                                variant="caption"
                                sx={{
                                  opacity: 0.67,
                                  lineHeight: '16px',
                                  maxHeight: '32px',
                                  overflow: 'hidden',
                                }}
                              >
                                {getDisplayDate(listItem.lastUpdatedTime)}
                              </Typography>
                            )}
                          </Stack>
                        </Paper>
                      </ListItem>
                    </ContainerDiv>
                  );
                }}
              </List>
            )}
          </Box>
        )}
      </AutoSizer>
    </Box>
  );
};

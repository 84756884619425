/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import {
  ArrowDropDownDown,
  Copy,
  Delete,
  Visibility,
  VisibilityOff,
} from '@opengeoweb/theme';
import { CustomAccordion, CustomIconButton } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import ParameterTypeSelect from './TimeSeriesManagerParameterComponents/ParameterTypeSelect';
import ParameterColorSelect from './TimeSeriesManagerParameterComponents/ParameterColorSelect';
import ParameterOpacitySelect from './TimeSeriesManagerParameterComponents/ParameterOpacitySelect';
import { BUTTON_WIDTH } from './TimeSeriesManagerUtils';
import { Parameter, ParameterListStyles, Plot } from '../TimeSeries/types';
import ParameterNameSelect from './TimeSeriesManagerParameterComponents/ParameterNameSelect';
import { ParameterInfoButtonConnect } from './ParameterInfo/ParameterInfoButtonConnect';
import { ReferenceTimeSelect } from './TimeSeriesManagerParameterComponents/ParameterReferenceTimeSelect';

const TimeSeriesManagerRowAccordion: React.FC<{
  plot: Plot;
  plotIsEnabled: boolean;
  rowIsEnabled: boolean;
  parameter: Parameter;
  styles: ParameterListStyles;
  addParameter: (parameter: Parameter) => void;
  patchParameter: (parameter: Partial<Parameter>) => void;
  deleteParameter: (id: string) => void;
  togglePlot: (id: string) => void;
  t: TFunction;
}> = ({
  plot,
  plotIsEnabled,
  rowIsEnabled,
  parameter,
  styles,
  addParameter,
  patchParameter,
  deleteParameter,
  togglePlot,
  t,
}) => {
  return (
    <CustomAccordion
      data-testid="timeSeriesManagerRowAccordion"
      CustomExpandIcon={ArrowDropDownDown}
      defaultExpanded={false}
      sx={{
        width: '100%',
        marginLeft: '4px',
        marginRight: '4px',
      }}
      title={
        <Grid
          container
          spacing={0}
          sx={{
            alignItems: 'flex-start',
          }}
        >
          <Grid sx={{ width: BUTTON_WIDTH }}>
            <CustomIconButton
              onClick={(event): void => {
                event.stopPropagation();
                togglePlot(plot.plotId);
              }}
              tooltipTitle={`${plotIsEnabled ? t('timeseries-hide') : t('timeseries-show')} ${plot.title}`}
              shouldShowAsDisabled={!plotIsEnabled}
              data-testid="accordionEnableButton"
            >
              {plotIsEnabled ? <Visibility /> : <VisibilityOff />}
            </CustomIconButton>
          </Grid>
          <Grid>
            <Grid sx={[styles.rowText]} title={parameter.collectionId}>
              {parameter.collectionId}
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        sx={{ padding: '16px 10px' }}
        spacing={2}
        direction="column"
      >
        <Grid> {t('timeseries-collection')}</Grid>
        <Grid sx={[styles.rowText]} title={parameter.collectionId}>
          {parameter.collectionId}
        </Grid>

        <Grid> {t('timeseries-parameter')}</Grid>
        <Grid>
          <ParameterNameSelect
            rowIsEnabled={rowIsEnabled}
            parameter={parameter}
            patchParameter={patchParameter}
            t={t}
          />
        </Grid>

        <Grid> {t('timeseries-style')}</Grid>
        <Grid>
          <ParameterTypeSelect
            rowIsEnabled={rowIsEnabled}
            parameter={parameter}
            patchParameter={patchParameter}
            t={t}
          />
        </Grid>

        <Grid> {t('timeseries-color')}</Grid>
        <Grid sx={{ width: '100%' }}>
          <ParameterColorSelect
            useEntireWidth={true}
            rowIsEnabled={rowIsEnabled}
            parameter={parameter}
            patchParameter={patchParameter}
            t={t}
          />
        </Grid>

        <Grid> {t('timeseries-opacity')}</Grid>
        <Grid>
          <ParameterOpacitySelect
            rowIsEnabled={rowIsEnabled}
            parameter={parameter}
            patchParameter={patchParameter}
            t={t}
          />
        </Grid>

        <Grid> {t('timeseries-reference-time')}</Grid>
        <Grid>
          <ReferenceTimeSelect
            rowIsEnabled={rowIsEnabled}
            parameter={parameter}
            patchParameter={patchParameter}
            t={t}
          />
        </Grid>

        <Grid>
          <Grid
            container
            sx={{
              justifyContent: 'space-evenly',
            }}
          >
            <Grid>
              <ParameterInfoButtonConnect parameter={parameter} />
            </Grid>
            <Grid>
              <CustomIconButton
                tooltipTitle={`Duplicate ${parameter.propertyName}`}
                shouldShowAsDisabled={!plotIsEnabled}
                data-testid="accordionDuplicateButton"
                onClick={(): void => {
                  addParameter(parameter);
                }}
              >
                <Copy />
              </CustomIconButton>
            </Grid>
            <Grid>
              <CustomIconButton
                tooltipTitle={`${t('timeseries-remove')} ${parameter.propertyName}`}
                onClick={(): void => {
                  deleteParameter(parameter.id!);
                }}
                shouldShowAsDisabled={!plotIsEnabled}
                data-testid="accordionDeleteButton"
              >
                <Delete />
              </CustomIconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomAccordion>
  );
};

export default TimeSeriesManagerRowAccordion;

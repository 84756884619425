/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, Switch, Tooltip, Typography } from '@mui/material';
import { CustomIconButton, ToolContainerDraggable } from '@opengeoweb/shared';
import { syncGroupsSelector, syncGroupsTypes } from '@opengeoweb/store';
import { Info } from '@opengeoweb/theme';
import * as React from 'react';
import { useCoreTranslation } from '../../utils/i18n';
import { SyncGroupList } from './SyncGroupList';

interface SyncGroupViewerProps {
  onClose: () => void;
  isOpen: boolean;
  syncGroupViewState: syncGroupsTypes.SyncGroupViewState;
  syncGroupAddTarget: (
    payload: syncGroupsTypes.SyncGroupAddTargetPayload,
  ) => void;
  syncGroupRemoveTarget: (
    payload: syncGroupsTypes.SyncGroupRemoveTargetPayload,
  ) => void;
  syncGroupAddGroup: (
    payload: syncGroupsTypes.SyncGroupAddGroupPayload,
  ) => void;
  syncGroupRemoveGroup: (
    payload: syncGroupsTypes.SyncGroupRemoveGroupPayload,
  ) => void;
  onMouseDown?: () => void;
  order?: number;
  isTimeScrollingEnabled?: boolean;
  toggleTimeScroll: (
    payload: syncGroupsTypes.SyncGroupToggleIsTimeScrollingEnabled,
  ) => void;
}

export const SyncGroupViewer: React.FC<SyncGroupViewerProps> = ({
  onClose,
  isOpen,
  syncGroupViewState,
  syncGroupAddTarget,
  syncGroupRemoveTarget,
  syncGroupAddGroup,
  syncGroupRemoveGroup,
  onMouseDown,
  order = 0,
  isTimeScrollingEnabled,
  toggleTimeScroll,
}: SyncGroupViewerProps) => {
  const { t } = useCoreTranslation();

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      const target = event.target as Element;
      if (
        target.tagName !== 'INPUT' &&
        target.tagName !== 'TEXTAREA' &&
        event.key === 's'
      ) {
        toggleTimeScroll({ isTimeScrollingEnabled: !isTimeScrollingEnabled });
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTimeScrollingEnabled, toggleTimeScroll]);

  const handleToggle = (
    groupCategory: string,
    groupId: string,
    sourceId: string,
  ): void => {
    const { selected } = syncGroupViewState[groupCategory].groups.find(
      (groupObject) => groupObject.id === groupId,
    ) as syncGroupsTypes.Group;
    const isChecked = selected.includes(sourceId);
    if (isChecked) {
      syncGroupRemoveTarget({
        groupId: String(groupId),
        targetId: String(sourceId),
        origin: 'user',
      });
    } else {
      const { groups } = syncGroupViewState[groupCategory];
      for (const group of groups) {
        if (group.id !== groupId && group.selected.includes(sourceId)) {
          syncGroupRemoveTarget({
            groupId: String(group.id),
            targetId: String(sourceId),
            origin: 'user',
          });
        }
      }
      syncGroupAddTarget({
        groupId: String(groupId),
        targetId: String(sourceId),
        origin: 'user',
      });
    }
  };

  const addNewGroup = (groupCategory: string, groupId: string): void => {
    syncGroupsSelector.groupTypes.forEach(({ title, syncType, groupType }) => {
      if (groupCategory === groupType) {
        syncGroupAddGroup({
          groupId: String(groupId + Math.floor(Math.random() * 1000)),
          title,
          type: syncType,
          origin: 'user',
        });
      }
    });
  };

  const removeGroup = (groupCategory: string, groupId: string): void => {
    syncGroupsSelector.groupTypes.forEach(({ groupType }) => {
      if (groupCategory === groupType) {
        syncGroupRemoveGroup({ groupId: String(groupId) });
      }
    });
  };

  if (!syncGroupViewState) {
    return null;
  }

  return (
    <ToolContainerDraggable
      title={t('syncgroups-title')}
      startSize={{ width: 349, height: 800 }}
      isOpen={isOpen}
      onClose={onClose}
      data-testid="syncGroupViewer"
      onMouseDown={onMouseDown}
      order={order}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography sx={{ fontSize: 12 }}>
          {t('workspace-time-scroll-off')}
        </Typography>
        <Switch
          inputProps={{
            'aria-label': 'workspace time scroll toggle',
          }}
          checked={isTimeScrollingEnabled}
          onChange={() =>
            toggleTimeScroll({
              isTimeScrollingEnabled: !isTimeScrollingEnabled,
              origin: 'user',
            })
          }
        />
        <Typography sx={{ fontSize: 12 }}>
          {t('workspace-time-scroll-on')}
        </Typography>
        <Tooltip
          title={t('workspace-time-scroll-info')}
          placement="bottom-start"
        >
          <CustomIconButton variant="flat" aria-label="time scroll info">
            <Info />
          </CustomIconButton>
        </Tooltip>
      </Box>

      <div>
        <SyncGroupList
          viewStateData={syncGroupViewState.zoompane}
          title={t('syncgroups-zoom')}
          handleToggle={(groupId, sourceId): void =>
            handleToggle('zoompane', groupId, sourceId!)
          }
          addNewGroup={(groupId): void => addNewGroup('zoompane', groupId)}
          removeGroup={(groupId): void => removeGroup('zoompane', groupId)}
        />
        <SyncGroupList
          viewStateData={syncGroupViewState.timeslider}
          title={t('syncgroups-timeslider')}
          handleToggle={(groupId, sourceId): void =>
            handleToggle('timeslider', groupId, sourceId!)
          }
          addNewGroup={(groupId): void => addNewGroup('timeslider', groupId)}
          removeGroup={(groupId): void => removeGroup('timeslider', groupId)}
        />
        <SyncGroupList
          viewStateData={syncGroupViewState.level}
          title={t('syncgroups-level')}
          handleToggle={(groupId, sourceId): void =>
            handleToggle('level', groupId, sourceId!)
          }
          addNewGroup={(groupId): void => addNewGroup('level', groupId)}
          removeGroup={(groupId): void => removeGroup('level', groupId)}
        />
      </div>
    </ToolContainerDraggable>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { createTheme } from '../createTheme';
import {
  colors as colorsLight,
  elevations as elevationsLight,
} from './light/lightTheme';
import {
  colors as colorsDark,
  elevations as elevationsDark,
} from './dark/darkTheme';
import {
  colors as colorsXmas2024,
  elevations as elevationsXmas2024,
} from './xmas2024/xmas2024Theme';
import { NamedTheme, ThemeTypes } from '../../types';

export const getThemeByName = (themeName: ThemeTypes): NamedTheme => {
  switch (themeName) {
    case ThemeTypes.DARK_THEME:
      return namedDarkTheme;
    case ThemeTypes.LIGHT_THEME:
      return namedLightTheme;
    case ThemeTypes.XMAS2024_THEME:
      return namedXmas2024Theme;
    default:
      return namedLightTheme;
  }
};

export const lightTheme = createTheme({
  paletteType: 'light',
  geowebColors: colorsLight,
  elevations: elevationsLight,
});

export const darkTheme = createTheme({
  paletteType: 'dark',
  geowebColors: colorsDark,
  elevations: elevationsDark,
});

export const xmas2024Theme = createTheme({
  paletteType: 'dark',
  geowebColors: colorsXmas2024,
  elevations: elevationsXmas2024,
});

export const namedLightTheme = {
  name: ThemeTypes.LIGHT_THEME,
  theme: lightTheme,
};

export const namedDarkTheme = {
  name: ThemeTypes.DARK_THEME,
  theme: darkTheme,
};

export const namedXmas2024Theme = {
  name: ThemeTypes.XMAS2024_THEME,
  theme: xmas2024Theme,
};

export { colorsLight, elevationsLight };

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { useApiContext, useApi } from '@opengeoweb/api';
import { usePoller } from '@opengeoweb/shared';
import { SWEvent } from '../../types';
import { SpaceWeatherApi } from '../../utils/api';

const NotificationTriggerContext =
  React.createContext<NotificationContextState>({
    notificationTriggers: null,
    error: null,
    onFetchNewNotificationTriggerData: () => {
      // do nothing
    },
  });

interface NotificationContextState {
  notificationTriggers: SWEvent[] | null;
  error: Error | null;
  onFetchNewNotificationTriggerData: () => void;
}

interface NotificationContextProps {
  children: React.ReactNode;
}

export const NotificationTriggerProvider: React.FC<
  NotificationContextProps
> = ({ children }: NotificationContextProps) => {
  const { api } = useApiContext<SpaceWeatherApi>();

  const {
    error,
    result: notificationTriggers,
    fetchApiData,
  } = useApi(api.getNewNotifications);

  const interval = usePoller(
    [notificationTriggers, error],
    () => fetchApiData!(null),
    60000,
  );

  const onFetchNewNotificationTriggerData = React.useCallback((): void => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    void fetchApiData!(null);
  }, [interval, fetchApiData]);

  const contextValue = React.useMemo(
    () => ({
      notificationTriggers,
      error,
      onFetchNewNotificationTriggerData,
    }),
    [notificationTriggers, error, onFetchNewNotificationTriggerData],
  );

  return (
    <NotificationTriggerContext.Provider value={contextValue}>
      {children}
    </NotificationTriggerContext.Provider>
  );
};

export const useNotificationTriggerContext =
  (): Partial<NotificationContextState> => {
    const context = React.useContext(NotificationTriggerContext);
    return context;
  };

export default NotificationTriggerContext;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Paper,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import {
  invalidateWMSGetCapabilities,
  LayerTree,
  queryWMSLayersTree,
} from '@opengeoweb/webmap';
import _ from 'lodash';
import { layerTypes, serviceTypes } from '@opengeoweb/store';
import WMSLayerTreeHeader from './WMSLayerTreeHeader';
import { useCoreTranslation } from '../../../utils/i18n';

interface WMSLayerTreeProps {
  service: serviceTypes.InitialService;
  onClickLayer: (serviceURL: string, layerName: string) => void;
  highlightedLayers: layerTypes.Layer[];
}

const WMSLayerTree: React.FC<WMSLayerTreeProps> = ({
  service,
  onClickLayer,
  highlightedLayers,
}: WMSLayerTreeProps) => {
  const { t } = useCoreTranslation();
  const initialTree: LayerTree = {
    name: 'loading wms getcapabilities',
    leaf: true,
    path: [],
    title: '',
    children: [],
  };

  const [processedOK, setProcessedOK] = React.useState<string[]>([]);
  const handleInternalSelect = (layerName: string): void => {
    onClickLayer(service.url, layerName);
    setProcessedOK([...processedOK, layerName]);
  };

  const [layerTree, setLayerTree] = React.useState(initialTree);
  const [isLoading, setIsLoading] = React.useState(false);
  const [forceReload, setForceReload] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const makeServiceQuery = async (): Promise<void> => {
      if (forceReload) {
        await invalidateWMSGetCapabilities(service.url);
      }

      queryWMSLayersTree(service.url)
        .then((layerTreeFromPromise) => {
          if (isMounted) {
            setLayerTree(layerTreeFromPromise);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (isMounted) {
            setLayerTree(error.toString());
            setIsLoading(false);
          }
        });
    };
    void makeServiceQuery();

    return (): void => {
      isMounted = false;
      setForceReload(false);
    };
  }, [service, forceReload]);

  const paperStyle = { height: 200, overflow: 'auto' };

  if ((!layerTree.children || !layerTree.children.length) && !isLoading) {
    return (
      <>
        <WMSLayerTreeHeader serviceName={service.name} />
        <Paper data-testid="message" style={paperStyle}>
          <Box p={2} display="flex">
            <Typography variant="body2">{t('wms-loader-no-data')}</Typography>
          </Box>
        </Paper>
      </>
    );
  }

  // Base case where there exists at least one layer
  // TODO: find a better way of doing this, without using prototype
  const flatten = (
    children: LayerTree[],
    parent: string | null[],
  ): LayerTree[] =>
    Array.prototype.concat.apply(
      (children || []).map((treeElem: LayerTree) => ({
        ...treeElem,
        parent: parent || null,
      })),
      (children || []).map((treeElem: LayerTree) =>
        flatten(treeElem.children || [], treeElem.title!),
      ),
    );
  const flattenedLayerTree = flatten(layerTree.children, []);

  const layerMap = _.groupBy(flattenedLayerTree, 'parent');

  // Check if a layer of the same name and service is in an array
  const layerExists = (
    targetLayer: LayerTree,
    layers: layerTypes.Layer[],
  ): boolean => {
    return (
      layers.filter(
        (layerInMap) =>
          layerInMap.name === targetLayer.name &&
          layerInMap.service === service.url,
      ).length > 0
    );
  };

  const categories = Object.keys(layerMap);

  return (
    <>
      <WMSLayerTreeHeader
        serviceName={service.name}
        onClickReload={(): void => setForceReload(true)}
      />
      <Paper style={paperStyle}>
        {isLoading ? (
          <Box p={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <List
            data-testid="layer-list"
            dense
            style={{ backgroundColor: 'inherit' }}
          >
            {categories.map((category) => {
              return (
                <React.Fragment key={category}>
                  {category === '' ? (
                    '' // Do not render a subheader if category is empty
                  ) : (
                    <ListSubheader key={category}>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          width: '100%',
                          fontWeight: 'bold',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {category}
                      </div>
                    </ListSubheader>
                  )}
                  {layerMap[category].map((layer: LayerTree) => {
                    return layer.leaf ? (
                      <ListItemButton
                        data-testid="selectableLayer"
                        key={layer.name}
                        selected={
                          processedOK.indexOf(layer.name!) !== -1 ||
                          layerExists(layer, highlightedLayers)
                        }
                        onClick={(): void => handleInternalSelect(layer.name!)}
                      >
                        <ListItemText id={layer.name!} primary={layer.title} />
                      </ListItemButton>
                    ) : null; // Do not list subcategories as layers
                  })}
                </React.Fragment>
              );
            })}
          </List>
        )}
      </Paper>
    </>
  );
};

export default WMSLayerTree;

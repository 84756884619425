/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputAdornment,
  LinearProgress,
  MenuItem,
} from '@mui/material';

import { Close, Copy } from '@opengeoweb/theme';
import {
  defaultFormOptions,
  ReactHookFormProvider,
  ReactHookFormSelect,
  ReactHookFormTextField,
} from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';
import {
  CustomIconButton,
  ServiceInterface,
  TimeSeriesService,
} from '@opengeoweb/shared';

import { useTranslation } from 'react-i18next';
import {
  loadEDRService,
  validateServiceName,
  validateServiceUrl,
  ValidationResult,
} from './utils';
import { TimeSeriesTypes } from '../../../store';
import { TIMESERIES_NAMESPACE } from '../../../utils/i18n';

const style = {
  textField: {
    width: '100%',
    marginBottom: 2,
  },
};

export interface ServicePopupProps {
  servicePopupVariant: TimeSeriesTypes.PopupVariant;
  hideBackdrop?: boolean;
  isOpen?: boolean;
  closePopup?: () => void;
  shouldDisableAutoFocus?: boolean;
  serviceUrl?: string;
  serviceName?: string;
  serviceDescription?: string;
  serviceType?: ServiceInterface;
  services?: TimeSeriesService[];
  serviceSetService: (payload: TimeSeriesService) => void;
  showSnackbar?: (message: string) => void;
}

const getTranslationIdForVariant = (
  variant: TimeSeriesTypes.PopupVariant,
): string => {
  switch (variant) {
    case 'add':
      return 'timeseries-add-service';
    case 'edit':
      return 'timeseries-edit-service';
    case 'save':
      return 'timeseries-save-service';
    default:
      return 'timeseries-service';
  }
};

const fields = {
  serviceUrl: {
    name: 'serviceUrl',
    labelTranslationId: 'timeseries-service-refers-to-url',
  },
  serviceName: {
    name: 'serviceName',
    labelTranslationId: 'timeseries-service-name',
  },
  serviceDescription: {
    name: 'serviceDescription',
    labelTranslationId: 'timeseries-description',
  },
  serviceType: {
    name: 'serviceType',
    labelTranslationId: 'timeseries-type',
  },
};

interface InitialValidationsTriggerProps {
  trigger: (name?: string | string[]) => Promise<boolean>;
  getValues: (name?: string | string[]) => string;
  onValidField: (value: string) => void;
}

const InitialValidationsTrigger: React.FC<InitialValidationsTriggerProps> = ({
  trigger,
  getValues,
  onValidField,
}: InitialValidationsTriggerProps) => {
  React.useEffect(() => {
    const value = getValues(fields.serviceUrl.name);
    if (value) {
      void trigger(fields.serviceUrl.name).then(
        (isValid) => isValid && onValidField(value),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};
interface FormValues {
  serviceUrl: string;
  serviceName: string;
  serviceDescription: string;
  serviceType: ServiceInterface;
}

const ServicePopupUnwrapped: React.FC<ServicePopupProps> = ({
  servicePopupVariant,
  hideBackdrop = false,
  isOpen,
  closePopup,
  shouldDisableAutoFocus,
  services,
  serviceSetService,
  serviceUrl,
  serviceName,
  serviceDescription,
  serviceType,
  showSnackbar = (): void => {},
}: ServicePopupProps) => {
  const {
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    watch,
    trigger,
    getValues,
    setValue,
  } = useFormContext();
  const { t } = useTranslation(TIMESERIES_NAMESPACE);

  const [isLoading, setIsLoading] = React.useState(false);

  const fetchServiceData = async (newServiceUrl: string): Promise<void> => {
    try {
      setIsLoading(true);
      const service = await loadEDRService(newServiceUrl, services || []);

      const currentValues = getValues();

      if (!currentValues[fields.serviceName.name]) {
        setValue(fields.serviceName.name, service.name);
      }
      if (!currentValues[fields.serviceDescription.name]) {
        setValue(fields.serviceDescription.name, service.description);
      }
      if (!currentValues[fields.serviceType.name]) {
        setValue(fields.serviceType.name, service.type);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(fields.serviceUrl.name, {
        message: t('timeseries-validations-request-failed'),
        type: 'SYSTEM',
      });
    }
  };

  const addNewService = async (formValues: FormValues): Promise<void> => {
    clearErrors(fields.serviceUrl.name);
    setIsLoading(true);
    try {
      const service = await loadEDRService(
        formValues.serviceUrl,
        services || [],
      );

      serviceSetService({
        id: service.id,
        name: formValues.serviceName,
        url: formValues.serviceUrl,
        description: formValues.serviceDescription,
        type: formValues.serviceType,
        scope: 'user',
      });
      setIsLoading(false);
      showSnackbar(
        t('timeseries-service-saved-successfully', {
          service: formValues.serviceName,
        }),
      );
      closePopup!();
    } catch (error) {
      setIsLoading(false);
      setError(fields.serviceUrl.name, {
        message: t('timeseries-validations-request-failed'),
        type: 'SYSTEM',
      });
    }
  };

  const onValidateServiceUrl = (value: string): ValidationResult => {
    const res = validateServiceUrl(value, services!);
    if (typeof res === 'string') {
      return t(res);
    }
    return res;
  };

  const onFocusServiceUrl = (): void => {
    const hasValue = getValues(fields.serviceUrl.name);
    if (!errors[fields.serviceUrl.name] && hasValue) {
      void trigger(fields.serviceUrl.name);
    }
  };

  const onBlurServiceUrl = (): void => {
    void trigger(fields.serviceUrl.name).then(() => {
      const hasValue = getValues(fields.serviceUrl.name);
      if (!errors[fields.serviceUrl.name] && hasValue) {
        void fetchServiceData(getValues(fields.serviceUrl.name));
      }
    });
  };

  const onKeyDownServiceUrl = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      onBlurServiceUrl();
    }
  };

  const onValidateServiceName = (value: string): ValidationResult => {
    const res = validateServiceName(value, services!, serviceName);
    if (typeof res === 'string') {
      return t(res);
    }
    return res;
  };

  const getFormAreRequiredFieldsEmpty = (): boolean => {
    const serviceNameField = watch(fields.serviceName.name);
    const serviceUrlField = watch(fields.serviceUrl.name);

    return (
      serviceNameField === '' ||
      serviceNameField === undefined ||
      serviceUrlField === '' ||
      serviceUrlField === undefined
    );
  };

  const getFormHasformErrors = (): boolean => {
    const totalErrors = Object.keys(errors).length;
    if (totalErrors > 0) {
      // prevent SYSTEM error types from blocking the save button
      if (totalErrors === 1 && errors.serviceUrl) {
        return (
          errors.serviceUrl.type !== undefined &&
          errors.serviceUrl.type !== 'SYSTEM'
        );
      }
      return true;
    }
    return false;
  };

  const isSubmitDisabled =
    getFormHasformErrors() || getFormAreRequiredFieldsEmpty();

  const isShowPopup = servicePopupVariant === 'show';
  const isServiceUrlDisabled = isShowPopup || servicePopupVariant === 'edit';

  return (
    <Dialog
      open={isOpen!}
      hideBackdrop={hideBackdrop}
      fullWidth
      maxWidth="xs"
      data-testid="servicePopup"
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: `geowebColors.cards.cardContainer`,
          maxWidth: 458,
        },
      }}
    >
      <form
        onSubmit={handleSubmit((formValues) =>
          addNewService(formValues as FormValues),
        )}
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            padding: '16px 16px 24px 16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ' span': {
                fontSize: '1rem',
                fontWeight: 500,
                fontStyle: 'normal',
                lineHeight: '1.38',
                letterSpacing: '0.12px',
              },
            }}
          >
            <Typography variant="h5" component="span">
              {t(getTranslationIdForVariant(servicePopupVariant))}
            </Typography>
            <CustomIconButton
              aria-label={t('timeseries-close')}
              onClick={closePopup}
              data-testid="closePopupButtonCross"
              sx={{
                marginTop: 'auto',
                marginBottom: 'auto',
                '&.MuiButtonBase-root': {
                  color: 'geowebColors.greys.accessible',
                },
              }}
            >
              <Close />
            </CustomIconButton>
          </Box>
          {isLoading && (
            <Box sx={{ position: 'relative' }}>
              <LinearProgress
                data-testid="loadingIndicator"
                color="secondary"
                sx={{ position: 'absolute', width: '100%', top: 8 }}
              />
            </Box>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: '8px 16px',
          }}
        >
          <ReactHookFormTextField
            name={fields.serviceUrl.name}
            label={t(fields.serviceUrl.labelTranslationId)}
            onFocus={onFocusServiceUrl}
            autoFocus={!shouldDisableAutoFocus}
            onBlur={onBlurServiceUrl}
            onKeyDown={onKeyDownServiceUrl}
            sx={style.textField}
            rules={{
              required: true,
              validate: {
                ...(!isServiceUrlDisabled && {
                  onValidateServiceUrl,
                }),
              },
            }}
            disabled={isServiceUrlDisabled || isLoading}
            isReadOnly={isShowPopup}
            inputSlotProps={{
              disableUnderline: isShowPopup,
              endAdornment: isShowPopup && (
                <InputAdornment position="end">
                  <CustomIconButton
                    aria-label={t('timeseries-service-url-copy')}
                    onClick={(): void => {
                      void navigator.clipboard.writeText(serviceUrl!);
                      showSnackbar(t('timeseries-service-url-copied'));
                    }}
                    sx={{ marginTop: 0.5 }}
                  >
                    <Copy />
                  </CustomIconButton>
                </InputAdornment>
              ),
            }}
            defaultValue={serviceUrl}
          />

          <ReactHookFormTextField
            name={fields.serviceName.name}
            label={t(fields.serviceName.labelTranslationId)}
            rules={{
              required: true,
              validate: {
                onValidateServiceName,
              },
            }}
            disabled={isShowPopup || isLoading}
            isReadOnly={isShowPopup}
            inputSlotProps={{ disableUnderline: isShowPopup }}
            sx={style.textField}
            defaultValue={serviceName}
          />

          <ReactHookFormTextField
            multiline
            rows={4}
            name={fields.serviceDescription.name}
            label={t(fields.serviceDescription.labelTranslationId)}
            disabled={isShowPopup || isLoading}
            isReadOnly={isShowPopup}
            inputSlotProps={{ disableUnderline: isShowPopup }}
            helperText={
              !isShowPopup ? t('timeseries-description-helper-text') : ''
            }
            sx={style.textField}
            defaultValue={serviceDescription || ''}
          />

          <ReactHookFormSelect
            name={fields.serviceType.name}
            label={t(fields.serviceType.labelTranslationId)}
            disabled
            isReadOnly={isShowPopup}
            rules={{
              required: true,
            }}
            defaultValue={serviceType || 'EDR'}
          >
            <MenuItem value="EDR">EDR</MenuItem>
            <MenuItem value="OGC">OGC</MenuItem>
          </ReactHookFormSelect>

          {!isServiceUrlDisabled && (
            <InitialValidationsTrigger
              trigger={trigger}
              getValues={getValues}
              onValidField={fetchServiceData}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: 2,
          }}
        >
          {isShowPopup ? (
            <Button
              onClick={(): void => closePopup!()}
              variant="tertiary"
              sx={{ width: '117px' }}
            >
              {t('timeseries-close')}
            </Button>
          ) : (
            <>
              <Button
                onClick={closePopup}
                data-testid="closePopupButtonCancel"
                variant="tertiary"
                sx={{ width: '117px' }}
              >
                {t('timeseries-cancel')}
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitDisabled || isLoading}
                data-testid="saveServiceButton"
                sx={{ width: '117px', marginLeft: '16px!important' }}
              >
                {t('timeseries-save')}
              </Button>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const ServicePopup: React.FC<ServicePopupProps> = (
  props: ServicePopupProps,
) => {
  const { serviceUrl, serviceName, serviceDescription, serviceType } = props;
  return (
    <ReactHookFormProvider
      options={{
        ...defaultFormOptions,
        defaultValues: {
          serviceUrl,
          serviceName,
          serviceDescription,
          serviceType,
        },
      }}
    >
      <ServicePopupUnwrapped {...props} />
    </ReactHookFormProvider>
  );
};

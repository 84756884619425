/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ConfigType } from '@opengeoweb/shared';

export interface SessionStorageProvider {
  setConfig: (value: ConfigType) => void;
  getConfig: () => ConfigType;
  setOauthState: (value: string) => void;
  getOauthState: () => string;
  removeOauthState: () => void;
  setOauthCodeVerifier: (value: string) => void;
  getOauthCodeVerifier: () => string;
  removeOauthCodeVerifier: () => void;
  setOauthCodeChallenge: (value: string) => void;
  getOauthCodeChallenge: () => string;
  removeOauthCodeChallenge: () => void;
  setHasAuthenticated: (value: string) => void;
  getHasAuthenticated: () => string;
  removeHasAuthenticated: () => void;
  getCallbackUrl: () => string;
  setCallbackUrl: (value: string) => void;
}

export enum SessionStorageKey {
  CONFIG = 'config',
  OAUTH_STATE = 'oauth_state',
  OAUTH_CODE_VERIFIER = 'code_verifier',
  OAUTH_CODE_CHALLENGE = 'code_challenge',
  HAS_AUTHENTICATED = 'has_authenticated',
  CALLBACK_URL = 'callback_url',
}

export const getSessionStorageProvider = (): SessionStorageProvider => {
  const { sessionStorage } = window;

  return {
    setConfig: (value: ConfigType): void =>
      sessionStorage.setItem(SessionStorageKey.CONFIG, JSON.stringify(value)),
    getConfig: (): ConfigType =>
      JSON.parse(sessionStorage.getItem(SessionStorageKey.CONFIG) || '{}'),
    setOauthState: (value: string): void =>
      sessionStorage.setItem(SessionStorageKey.OAUTH_STATE, value),
    getOauthState: (): string =>
      sessionStorage.getItem(SessionStorageKey.OAUTH_STATE)!,
    removeOauthState: (): void =>
      sessionStorage.removeItem(SessionStorageKey.OAUTH_STATE),
    setOauthCodeVerifier: (value: string): void =>
      sessionStorage.setItem(SessionStorageKey.OAUTH_CODE_VERIFIER, value),
    getOauthCodeVerifier: (): string =>
      sessionStorage.getItem(SessionStorageKey.OAUTH_CODE_VERIFIER)!,
    removeOauthCodeVerifier: (): void =>
      sessionStorage.removeItem(SessionStorageKey.OAUTH_CODE_VERIFIER),
    setOauthCodeChallenge: (value: string): void =>
      sessionStorage.setItem(SessionStorageKey.OAUTH_CODE_CHALLENGE, value),
    getOauthCodeChallenge: (): string =>
      sessionStorage.getItem(SessionStorageKey.OAUTH_CODE_CHALLENGE)!,
    removeOauthCodeChallenge: (): void =>
      sessionStorage.removeItem(SessionStorageKey.OAUTH_CODE_CHALLENGE),
    setHasAuthenticated: (value: string): void =>
      sessionStorage.setItem(SessionStorageKey.HAS_AUTHENTICATED, value),
    getHasAuthenticated: (): string =>
      sessionStorage.getItem(SessionStorageKey.HAS_AUTHENTICATED)!,
    removeHasAuthenticated: (): void =>
      sessionStorage.removeItem(SessionStorageKey.HAS_AUTHENTICATED),
    getCallbackUrl: (): string =>
      sessionStorage.getItem(SessionStorageKey.CALLBACK_URL)!,
    setCallbackUrl: (value: string): void =>
      sessionStorage.setItem(SessionStorageKey.CALLBACK_URL, value),
  };
};

export const getConfig = (): ConfigType =>
  getSessionStorageProvider().getConfig();

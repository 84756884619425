/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* Type constants */
export const SYNCGROUPS_TYPE_SETTIME = 'SYNCGROUPS_TYPE_SETTIME';
export const SYNCGROUPS_TYPE_SETBBOX = 'SYNCGROUPS_TYPE_SETBBOX';
export const SYNCGROUPS_TYPE_SETLAYERACTIONS =
  'SYNCGROUPS_TYPE_SETLAYERACTIONS';
export const SYNCGROUPS_SET_VIEW_STATE = 'SYNCGROUPS_SET_VIEW_STATE';

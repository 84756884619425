/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { TimeSeriesService } from '@opengeoweb/shared';
import { validateServiceUrl as validateEdrServiceUrl } from '../../../utils/edrUtils';

export type ValidationResult = string | boolean;

export const validateServiceName = (
  value: string,
  services: TimeSeriesService[],
  ownServiceName = '',
): ValidationResult => {
  const serviceName = value?.toLowerCase();
  const foundName = services.find(
    (service) =>
      (service.name ? service.name.toLowerCase() : service.id.toLowerCase()) ===
        serviceName && serviceName !== ownServiceName.toLocaleLowerCase(),
  );
  return foundName === undefined
    ? true
    : 'timeseries-validations-name-existing';
};

const isValidUrl = (url: string): boolean => {
  if (url === '' || url === null || typeof url === 'undefined') {
    return false;
  }
  const matcher = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|[0-9a-zA-Z]+[:?\d]*)\S*$/;
  if (!matcher.test(url)) {
    return false;
  }
  return true;
};

export const validateServiceUrl = (
  value: string,
  services: TimeSeriesService[],
): ValidationResult => {
  const serviceUrl = value;
  // validate valid url
  const isServiceUrlValid = isValidUrl(serviceUrl);
  if (!isServiceUrlValid) {
    return 'timeseries-validations-service-valid-url';
  }
  // check url existing
  const foundUrl = services.find((service) => service.url === serviceUrl);
  if (foundUrl) {
    return 'timeseries-validations-service-existing';
  }

  return true;
};

export const loadEDRService = async (
  serviceUrl: string,
  services: TimeSeriesService[],
): Promise<TimeSeriesService> => {
  const service = services.find((el) => el.url === serviceUrl);

  if (service) {
    return service;
  }

  const validation = await validateEdrServiceUrl(serviceUrl);

  if (validation) {
    return {
      id: '',
      name: validation.title || '',
      url: serviceUrl,
      description: validation.description || '',
      type: 'EDR',
      scope: 'user',
    };
  }

  throw new Error();
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  AlertBanner,
  ConfirmationDialog,
  getAxiosErrorMessage,
} from '@opengeoweb/shared';
import { Box, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { AxiosError } from 'axios';
import { getWarningsApi } from '../../utils/api';
import { useWarningsTranslation } from '../../utils/i18n';

export const getDeleteDescription = (
  objectName: string,
  t: TFunction,
): string => `${t('delete-dialog-description')} "${objectName}"?`;

export const getDeleteSucces = (objectName: string, t: TFunction): string =>
  `Object "${objectName}" ${t('delete-succes')}`;

export const ConfirmDeleteDialog: React.FC<{
  objectId: string;
  objectName: string;
  onDeleteSucces: (objectId: string, objectName: string) => void;
  onClose: () => void;
}> = ({
  objectId,
  objectName,
  onDeleteSucces = (): void => {},
  onClose = (): void => {},
}) => {
  const { t } = useWarningsTranslation();
  const warningsApi = getWarningsApi();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<undefined | string>(undefined);
  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await warningsApi.deleteDrawing(objectId);
      onDeleteSucces(objectId, objectName);
      setIsLoading(false);
    } catch (error) {
      setError(getAxiosErrorMessage(error as AxiosError));
      setIsLoading(false);
    }
  };

  const description = getDeleteDescription(objectName, t);

  return (
    <ConfirmationDialog
      title={t('delete-dialog-title')}
      open
      confirmLabel={t('delete-dialog-confirm')}
      cancelLabel={t('delete-dialog-cancel')}
      description={!error ? description : ''}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      sx={{ width: 330 }}
      {...(error && {
        content: (
          <Box sx={{ marginBottom: 0 }}>
            <AlertBanner title={error!} />
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {description}
            </Typography>
          </Box>
        ),
      })}
    />
  );
};

export default ConfirmDeleteDialog;

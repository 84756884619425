/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Provider } from 'react-redux';
import { AnyAction, Store } from '@reduxjs/toolkit';
import { MemoryRouter, Routes } from 'react-router-dom';
import { ApiProvider } from '@opengeoweb/api';
import {
  ThemeProviderProps,
  ThemeTypes,
  ThemeWrapper,
} from '@opengeoweb/theme';
import { SnackbarWrapperConnect } from '@opengeoweb/snackbar';
import { ConfirmationServiceProvider } from '@opengeoweb/shared';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AppApi, createFakeApi } from '../../utils/api';
import i18n, { initGeowebI18n } from '../../../i18n';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

const ThemeWrapperWithModules: React.FC<ThemeProviderProps> = ({
  theme,
  children,
  defaultThemeName,
}: ThemeProviderProps) => (
  <ThemeWrapper theme={theme} defaultThemeName={defaultThemeName}>
    {children}
  </ThemeWrapper>
);

export const AppWrapper: React.FC<{
  children: React.ReactNode;
  store: Store<unknown, AnyAction>;
  defaultThemeName?: ThemeTypes;
}> = ({ children, store, defaultThemeName }) => (
  <Provider store={store}>
    <ThemeWrapperWithModules defaultThemeName={defaultThemeName}>
      <SnackbarWrapperConnect>
        <ConfirmationServiceProvider>
          {children as React.ReactElement}
        </ConfirmationServiceProvider>
      </SnackbarWrapperConnect>
    </ThemeWrapperWithModules>
  </Provider>
);

interface TestWrapperProps {
  store: Store<unknown, AnyAction>;
  children: React.ReactNode;
  createApi?: () => AppApi;
}

export const TestWrapper: React.FC<TestWrapperProps> = ({
  store,
  children,
  createApi = createFakeApi,
}: TestWrapperProps) => {
  initGeowebI18n();
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <ApiProvider createApi={createApi}>
          <AppWrapper store={store}>{children}</AppWrapper>
        </ApiProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export const TestWrapperWithRouter: React.FC<TestWrapperProps> = ({
  store,
  children,
  createApi = createFakeApi,
}: TestWrapperProps) => {
  return (
    <TestWrapper store={store} createApi={createApi}>
      <MemoryRouter>
        <Routes>{children}</Routes>
      </MemoryRouter>
    </TestWrapper>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { mapTypes } from '@opengeoweb/store';
import { Dimension } from '@opengeoweb/webmap';
import DimensionSelect from '../DimensionSelect/DimensionSelect';
import { DimensionSelectProps } from '../DimensionSelect/DimensionSelectConnect';
import { LayerManagerCustomSettings } from '../../../LayerManagerUtils';
import {
  useLayerDimensions,
  useReferenceTimeWithLatestOption,
} from '../DimensionSelect/dimensionSelectHooks';

type Content = NonNullable<LayerManagerCustomSettings['content']>;
type Dimensions = NonNullable<Content['dimensions']>;
type DimensionsSettingsProps = Omit<Dimensions, 'nameMappings'>;

type CustomDimensionSelectProps = DimensionSelectProps &
  DimensionsSettingsProps;

const CustomDimensionSelectConnect: React.FC<CustomDimensionSelectProps> = ({
  layerId,
  mapId,
  dimensionsToShow,
  ...props
}: CustomDimensionSelectProps) => {
  const { layerDimensions, isLayerEnabled, layerChangeDimension } =
    useLayerDimensions(layerId, mapId);
  const { useLatestReferenceTime, setUseLatestReferenceTime } =
    useReferenceTimeWithLatestOption(layerId);

  const filteredDimensions = dimensionsToShow
    ? (dimensionsToShow
        .map(
          (
            dimensionName, // Use dimensionToShow order
          ) =>
            layerDimensions.find(
              (dimension: Dimension) => dimension.name === dimensionName,
            ),
        )
        .filter(Boolean) as mapTypes.Dimension[])
    : layerDimensions;

  return (
    <DimensionSelect
      layerDimensions={filteredDimensions}
      onLayerChangeDimension={(
        dimensionName: string,
        dimensionValue: string,
        origin,
      ): void => {
        const dimension: mapTypes.Dimension = {
          name: dimensionName,
          currentValue: dimensionValue,
        };
        layerChangeDimension({
          dimension,
          origin,
        });
      }}
      isEnabled={isLayerEnabled}
      useLatestReferenceTime={useLatestReferenceTime}
      setUseLatestReferenceTime={setUseLatestReferenceTime}
      {...props}
    />
  );
};

export default CustomDimensionSelectConnect;

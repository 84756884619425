/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useApiContext,
  useAuthenticationContext,
} from '@opengeoweb/api';
import {
  defaultFormOptions,
  ReactHookFormProvider,
} from '@opengeoweb/form-fields';
import {
  ConfirmationDialog,
  getAxiosErrorMessage,
  isAxiosError,
} from '@opengeoweb/shared';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ViewPresetsForm from './ViewPresetsForm';
import { PresetsApi } from '../../utils/api';
import {
  PresetAction,
  ViewPreset,
  ViewPresetFormValues,
  WorkspaceSupportedComponentTypes,
} from '../../store/viewPresets/types';
import { useWorkspaceTranslation } from '../../utils/i18n';

export interface ViewPresetsFormDialogProps {
  isOpen: boolean;
  title: string;
  action: PresetAction;
  viewPresetId: string;
  viewComponentType: WorkspaceSupportedComponentTypes;
  formValues: ViewPresetFormValues;
  onSuccess: (
    action: PresetAction,
    presetId: string,
    title: string,
    abstract: string,
  ) => void;
  onClose: () => void;
  onReset: (viewPresetId: string) => void;
}

const ViewPresetsFormDialog: React.FC<ViewPresetsFormDialogProps> = ({
  isOpen,
  action,
  viewPresetId,
  viewComponentType,
  formValues,
  onSuccess,
  onClose,
  title,
  onReset,
}: ViewPresetsFormDialogProps) => {
  const { handleSubmit: handleFormSubmit, reset } =
    useFormContext<ViewPreset>();
  const { t } = useWorkspaceTranslation();
  const { api } = useApiContext<PresetsApi>();
  const { currentRole } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;
  const [error, setError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isOpen) {
      setError(null!);
    }
  }, [isOpen]);

  React.useEffect(() => {
    reset(formValues);
  }, [formValues, reset]);

  const getConfirmLabel = (action: PresetAction): string => {
    switch (action) {
      case PresetAction.DELETE: {
        return t('workspace-delete');
      }
      case PresetAction.SAVE_AS: {
        return t('workspace-save');
      }
      case PresetAction.RESET: {
        return t('workspace-reset');
      }
      default:
        return t('workspace-save');
    }
  };

  const confirmLabel = getConfirmLabel(action);

  const onFormSubmit = async (formValues: ViewPreset): Promise<void> => {
    const scope = isAdmin ? 'system' : formValues.scope;

    const handleSuccess = (id: string): void => {
      setIsLoading(false);
      onClose();
      onSuccess(action, id, newFormValues.title, newFormValues.abstract!);
    };

    const newFormValues = {
      ...formValues,
      scope,
    };
    try {
      setError(null!);
      setIsLoading(true);

      switch (action) {
        case PresetAction.SAVE_AS: {
          const newId = await api.saveViewPresetAs(newFormValues);
          handleSuccess(newId);
          break;
        }
        case PresetAction.DELETE: {
          await api.deleteViewPreset(viewPresetId);
          handleSuccess(viewPresetId);
          break;
        }
        case PresetAction.EDIT: {
          // Retrieve stored preset so we ensure only to update the title
          const { data: originalViewPreset } =
            await api.getViewPreset(viewPresetId);
          const viewPresetEdited: ViewPreset = {
            ...originalViewPreset,
            title: newFormValues.title,
            abstract: newFormValues.abstract,
            scope,
          };
          await api.saveViewPreset(viewPresetId, viewPresetEdited);
          handleSuccess(viewPresetId);

          break;
        }
        case PresetAction.RESET: {
          onReset(viewPresetId);
          handleSuccess(viewPresetId);
          break;
        }
        default: {
          break;
        }
      }
    } catch (error) {
      setIsLoading(false);
      const submitError = error as Error;
      const message = isAxiosError(submitError)
        ? getAxiosErrorMessage(submitError)
        : submitError.message;
      setError(message);
    }
  };

  const onSubmit = async (): Promise<void> => {
    await handleFormSubmit(async (newFormValues: ViewPreset): Promise<void> => {
      const { title, ...otherFormValues } = newFormValues;
      const trimmedTitle = title?.trim();

      const parsedFormValues = {
        ...otherFormValues,
        title: trimmedTitle,
      };

      await onFormSubmit(parsedFormValues);
    })();
  };

  return (
    <ConfirmationDialog
      data-testid="map-preset-dialog"
      title={title}
      open={isOpen}
      confirmLabel={confirmLabel}
      cancelLabel={t('workspace-cancel')}
      description=""
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      sx={{ width: '330px' }}
      content={
        <ViewPresetsForm
          viewComponentType={viewComponentType}
          formValues={formValues}
          error={error}
          onSubmit={onSubmit}
          action={action}
        />
      }
    />
  );
};

const ViewPresetsFormDialogWrapper: React.FC<ViewPresetsFormDialogProps> = ({
  ...props
}: ViewPresetsFormDialogProps) => {
  return (
    <ReactHookFormProvider
      options={{
        ...defaultFormOptions,
      }}
    >
      <ViewPresetsFormDialog {...props} />
    </ReactHookFormProvider>
  );
};

export default ViewPresetsFormDialogWrapper;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';

import { CoreAppStore, mapSelectors } from '@opengeoweb/store';

import MultiMapSingleDimensionSelectConnect from './MultiMapSingleDimensionSelectConnect';

const MultiMapMultiDimensionSelectConnect: React.FC = () => {
  const allDimensions = useSelector((store: CoreAppStore) =>
    mapSelectors.getAllUniqueDimensions(store),
  );
  const allDimensionsNoTime = allDimensions.filter(
    (dimension) => !dimension.includes('time'),
  );
  return (
    <>
      {allDimensionsNoTime.map((dimName, index) => (
        <MultiMapSingleDimensionSelectConnect
          key={`v-select-${dimName}`}
          dimensionName={dimName}
          index={index}
        />
      ))}
    </>
  );
};

/**
 * Component used to control values for all dimension other than time and ref.time
 *
 * @example
 * ``` <MultiMapMultiDimensionSelectConnect  /> ```
 */
export default MultiMapMultiDimensionSelectConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useEffect, useState } from 'react';
import { uiTypes, useSetupDialog } from '@opengeoweb/store';
import { useSelector } from 'react-redux';
import { TimeSeriesModuleState } from '../../../store/types';
import { timeSeriesSelectors } from '../../../store';
import {
  ParameterInfoDialog,
  InstanceInformation,
} from './ParameterInfoDialog';
import { fetchEdrAllInstances } from '../../../utils/edrUtils';

export const ParameterInfoDialogConnect: React.FC = () => {
  const [temporalInformation, setTemporalInformation] =
    useState<InstanceInformation>({
      isLoading: false,
      instances: [],
    });

  const currentParameterInfoDisplayed = useSelector(
    timeSeriesSelectors.getParameterInfoParameter,
  );

  const relatedService = useSelector((store: TimeSeriesModuleState) =>
    timeSeriesSelectors.getService(
      store,
      currentParameterInfoDisplayed?.serviceId || '',
    ),
  );

  useEffect(() => {
    if (!relatedService || !currentParameterInfoDisplayed) {
      return;
    }

    setTemporalInformation({ isLoading: true });

    void fetchEdrAllInstances(
      relatedService.url,
      currentParameterInfoDisplayed.collectionId,
    ).then((instances) => {
      setTemporalInformation({
        isLoading: false,
        instances,
      });
    });
  }, [relatedService, currentParameterInfoDisplayed]);

  const { dialogOrder, onCloseDialog, setDialogOrder, uiSource, isDialogOpen } =
    useSetupDialog(uiTypes.DialogTypes.TimeseriesInfo);
  if (!currentParameterInfoDisplayed) {
    return null;
  }
  return (
    <ParameterInfoDialog
      isOpen={isDialogOpen}
      instanceInformation={temporalInformation}
      onClose={onCloseDialog}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      source={uiSource}
      currentParameterInfo={currentParameterInfoDisplayed}
      relatedService={relatedService}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* eslint-disable no-param-reassign */
import { Theme } from '@mui/material';

import { dateUtils } from '@opengeoweb/shared';
import { timeBoxGeom, timestampToPixel } from '../TimeSlider/timeSliderUtils';

export const secondsInYear = 365 * 24 * 3600;

const drawRoundedRectangle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  cornerRadius: number,
  theme: Theme,
): void => {
  ctx.save();
  ctx.translate(x, y);

  const { playerTimeMarkers } = theme.palette.geowebColors.timeSlider;

  ctx.beginPath();
  ctx.strokeStyle = playerTimeMarkers.fill!;
  ctx.fillStyle = playerTimeMarkers.fill!;
  ctx.lineWidth = timeBoxGeom.lineWidth;
  const halfLineWidth = timeBoxGeom.lineWidth / 2;

  // Top left corner
  ctx.moveTo(halfLineWidth, height / 2);
  ctx.arcTo(
    halfLineWidth,
    halfLineWidth,
    width / 2,
    halfLineWidth,
    cornerRadius,
  );

  // Top right corner
  ctx.arcTo(
    width - halfLineWidth,
    halfLineWidth,
    width - halfLineWidth,
    height / 2,
    cornerRadius,
  );

  // Bottom right corner
  ctx.arcTo(
    width - halfLineWidth,
    height - halfLineWidth,
    width / 2,
    height - halfLineWidth,
    cornerRadius,
  );
  // Bottom left corner
  ctx.arcTo(
    halfLineWidth,
    height - halfLineWidth,
    halfLineWidth,
    height / 2,
    cornerRadius,
  );

  ctx.lineTo(halfLineWidth, height / 2);
  ctx.fill();
  ctx.stroke();

  ctx.restore();
};

const drawAutoUpdateIcon = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  theme: Theme,
  isAutoUpdating: boolean,
): void => {
  ctx.save();
  ctx.translate(x, y);
  const { playerTimeText } = theme.palette.geowebColors.timeSlider;

  ctx.fillStyle = isAutoUpdating ? '#7BACFF' : '#B4B4B4';

  const path = new Path2D(
    'M12.385 3.295l5 3-5 3V7.723l-.085.014c-3.08.548-5.418 3.24-5.418 6.479 0 3.634 2.945 6.58 6.577 6.58 2.914 0 5.385-1.896 6.248-4.523l2.815-1.626c-.225 4.814-4.197 8.648-9.063 8.648-5.012 0-9.074-4.065-9.074-9.08 0-4.65 3.495-8.484 8-9.015V3.295z',
  );

  ctx.translate(7, -8);
  ctx.rotate(13.295);
  ctx.fill(path);
  ctx.restore();

  const text = 'Auto';

  ctx.save();
  ctx.translate(x, y);

  ctx.fillStyle = isAutoUpdating ? '#7BACFF' : '#B4B4B4'; // ON

  ctx.font = `7px ${playerTimeText.fontFamily}`;
  ctx.fillText(text, 5, 14);
  ctx.restore();
};
const drawTextAnnotation = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  text: string,
  theme: Theme,
): void => {
  const { playerTimeText } = theme.palette.geowebColors.timeSlider;
  ctx.save();
  ctx.translate(x, y);

  ctx.fillStyle = playerTimeText.color!;
  ctx.font = `${playerTimeText.fontSize}px ${playerTimeText.fontFamily}`;
  const textMetrics = ctx.measureText(text);

  const actualTextWidth =
    textMetrics.actualBoundingBoxRight - textMetrics.actualBoundingBoxLeft;
  const actualTextHeight = ctx.measureText('O').width;
  ctx.fillText(text, -actualTextWidth / 2, actualTextHeight / 2);

  ctx.restore();
};

const drawNewCurrentTimeBox = (
  ctx: CanvasRenderingContext2D,
  selectedTime: number,
  selectedPx: number,
  span: number,
  theme: Theme,
  isAutoUpdating: boolean,
): void => {
  const { smallWidth, largeWidth, height, iconWidth, calendarWidth } =
    timeBoxGeom;
  const boxWidth = span >= secondsInYear ? largeWidth : smallWidth;
  const needleX = selectedPx - boxWidth / 2;
  const needleY = timeBoxGeom.lineWidth;

  drawRoundedRectangle(
    ctx,
    needleX - iconWidth / 2 - calendarWidth / 2,
    needleY,
    boxWidth + iconWidth + calendarWidth,
    height,
    timeBoxGeom.cornerRadius,
    theme,
  );

  const timeFormat =
    span >= secondsInYear
      ? `y ${dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH}`
      : dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH;

  const timeText =
    dateUtils.dateToString(dateUtils.fromUnix(selectedTime), timeFormat) || '';

  drawTextAnnotation(
    ctx,
    needleX + boxWidth / 2 - iconWidth / 2 + calendarWidth / 2,
    needleY + height / 2,
    timeText,
    theme,
  );

  drawAutoUpdateIcon(
    ctx,
    needleX + boxWidth + calendarWidth - iconWidth / 2 - calendarWidth / 2,
    needleY,
    theme,
    isAutoUpdating,
  );
};

export const renderTimeSliderCurrentTimeBox = (
  ctx: CanvasRenderingContext2D,
  theme: Theme,
  width: number,
  height: number,
  centerTime: number,
  selected: number,
  secondsPerPx: number,
  span: number,
  isAutoUpdating: boolean,
): void => {
  // map unix timestamps to pixel values
  const [selectedPx] = [selected].map((t) =>
    timestampToPixel(t!, centerTime, width, secondsPerPx),
  );
  ctx.clearRect(0, 0, width, height);

  drawNewCurrentTimeBox(ctx, selected, selectedPx, span, theme, isAutoUpdating);
};

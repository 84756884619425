/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import axios from 'axios';
import { dateUtils } from '@opengeoweb/shared';

import {
  Parameter,
  ParameterApiData,
  ParameterApiDataRequest,
  ParameterWithData,
} from './types';

export const fetchParameterApiData = async (
  request: ParameterApiDataRequest,
): Promise<ParameterApiData | null> => {
  const url = `${request.urlDomain}&lonlat=${request.lon},${request.lat}&observedPropertyName=${request.parameterName}`;
  try {
    const result = await axios.get<ParameterApiData>(url);
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getOgcParameter = async (
  presetParameter: Parameter,
  url: string,
  longitude: number,
  latitude: number,
): Promise<ParameterWithData | null> => {
  const apiData: ParameterApiData | null = await fetchParameterApiData({
    urlDomain: url,
    lon: longitude,
    lat: latitude,
    parameterName: presetParameter.propertyName,
  });

  if (apiData === null || apiData.features.length === 0) {
    return null;
  }
  const feature = apiData.features[0];
  const timeValue = feature.properties.timestep.map((time, index) => {
    return {
      time: dateUtils.utc(time),
      value: Number(feature.properties.result[index]),
    };
  });

  return {
    ...presetParameter,
    timestep: timeValue.map((elem) => elem.time),
    value: timeValue.map((elem) => elem.value),
  } as ParameterWithData;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box, Grid2 as Grid, LinearProgress } from '@mui/material';
import { AlertBanner, LastUpdateTime } from '@opengeoweb/shared';
import { breakpoints } from '@opengeoweb/theme';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { groupBy } from 'lodash';
import {
  Warning,
  PublicWarningStatus,
} from '../../store/publicWarningForm/types';
import { useWarningsTranslation } from '../../utils/i18n';
import FilterList from '../FilterList/FilterList';
import WarningListItemSeperator from './WarningListItemSeperator';
import WarningListItem from './WarningListItem';
import { useGetFilterHeight } from '../AreaObjectLoader/AreasObjects';
import { mapProductStatusToWarningStatus } from '../../utils/sig-airmet-util';
import { FilterHookState } from '../FilterList/filter-hooks';
import { WarningListItemActions } from './WarningListItemMenu';
import { NewWarningButton } from './NewWarningButton/NewWarningButton';
import WarningListHeaderContent from './WarningListHeaderContent';

interface SortedWarnings {
  draft: Warning[];
  published: Warning[];
  todo: Warning[];
  expired: Warning[];
}

type SortKeyType = 'lastUpdatedTime' | 'validFrom' | 'validUntil';
interface SortState {
  key: SortKeyType;
  direction: 'asc' | 'desc';
}

const groupWarningsOnStatus = (
  warnings: Warning[],
  sortState: SortState,
): SortedWarnings => {
  const { key, direction } = sortState;
  const sortedWarnings = warnings.sort((a, b) => {
    const dateStringA =
      key === 'lastUpdatedTime' ? a[key] : a.warningDetail[key];
    const dateStringB =
      key === 'lastUpdatedTime' ? b[key] : b.warningDetail[key];

    return dateStringB?.localeCompare(dateStringA || '') || 0;
  });
  if (direction === 'asc') {
    sortedWarnings.reverse();
  }
  const groupedWarnings = groupBy(sortedWarnings, (warning) => {
    const status =
      warning.status || mapProductStatusToWarningStatus(warning.productStatus);
    switch (status) {
      case PublicWarningStatus.DRAFT:
      case PublicWarningStatus.DRAFT_PUBLISHED:
        return 'draft';
      case PublicWarningStatus.PUBLISHED:
        return 'published';
      case PublicWarningStatus.TODO:
        return 'todo';
      case PublicWarningStatus.EXPIRED:
      case PublicWarningStatus.WITHDRAWN:
        return 'expired';
      default:
        return 'ignore';
    }
  });
  return {
    draft: [],
    published: [],
    todo: [],
    expired: [],
    ...groupedWarnings,
  };
};

export interface PublicWarningListProps extends WarningListItemActions {
  warnings: Warning[];
  onSelectWarning?: (warning: Warning) => void;
  isLoading?: boolean;
  error?: Error;
  selectedWarningId?: string;
  lastUpdatedTime?: string;
  onCreateWarning?: () => void;
  onRefetchWarnings?: () => void;
  filterHookState: FilterHookState;
  hoveredFeatureId?: string;
  expandedSections: Record<string, boolean>;
  setExpandedSections: (expandedSections: Record<string, boolean>) => void;
  onActionAviationWarning?: () => void;
}

interface WarningListElementHeader {
  status: PublicWarningStatus;
  totalWarnings: number;
}

export const PublicWarningList: React.FC<PublicWarningListProps> = ({
  warnings = [],
  filterHookState,
  isLoading = false,
  error,
  selectedWarningId = '',
  lastUpdatedTime,
  onSelectWarning = (): void => {},
  onEditWarning = (): void => {},
  onCreateWarning = (): void => {},
  onDeleteWarning = (): void => {},
  onRefetchWarnings = (): void => {},
  onDeleteReviewWarning = (): void => {},
  onExpireWarning = (): void => {},
  onWithdrawWarning = (): void => {},
  onActionAviationWarning = (): void => {},
  hoveredFeatureId,
  expandedSections,
  setExpandedSections,
}) => {
  const { updateFilter, allSelected, filterState } = filterHookState;
  const { t } = useWarningsTranslation();
  const [sortState, setSortState] = React.useState<SortState>({
    key: 'lastUpdatedTime',
    direction: 'desc',
  });

  const onClickAll = (isChecked: boolean): void => {
    updateFilter('ALL', 'ALL', !isChecked);
  };
  const sortedWarnings = React.useMemo(
    () => groupWarningsOnStatus(warnings, sortState),
    [warnings, sortState],
  );

  const toggleSortDirection = (key: SortKeyType): void => {
    setSortState((prevState) => ({
      key,
      direction:
        prevState.key === key && prevState.direction === 'desc'
          ? 'asc'
          : 'desc',
    }));
  };

  const toggleExpand = (
    shouldToggle: boolean,
    status: PublicWarningStatus,
  ): void => {
    setExpandedSections({
      ...expandedSections,
      [status]: shouldToggle,
    });
    resetListIndex();
  };

  const items: (Warning | WarningListElementHeader)[] = React.useMemo(
    () => [
      {
        status: PublicWarningStatus.TODO,
        totalWarnings: sortedWarnings.todo.length,
      },
      ...(expandedSections.TODO ? sortedWarnings.todo : []),
      {
        status: PublicWarningStatus.DRAFT,
        totalWarnings: sortedWarnings.draft.length,
      },
      ...(expandedSections.DRAFT ? sortedWarnings.draft : []),
      {
        status: PublicWarningStatus.PUBLISHED,
        totalWarnings: sortedWarnings.published.length,
      },
      ...(expandedSections.PUBLISHED ? sortedWarnings.published : []),
      {
        status: PublicWarningStatus.EXPIRED,
        totalWarnings: sortedWarnings.expired.length,
      },
      ...(expandedSections.EXPIRED ? sortedWarnings.expired : []),
    ],
    [sortedWarnings, expandedSections],
  );

  const getItemSize = (index: number, width: number): number => {
    const item = items[index];
    if ((item as WarningListElementHeader).totalWarnings !== undefined) {
      return 36;
    }
    if (width < 400) {
      return 201;
    }
    if (width < 500) {
      return 101;
    }
    if (width < breakpoints.tablet) {
      return 81;
    }
    if (width < breakpoints.desktop) {
      return 71;
    }

    return 34;
  };

  const filterRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<VariableSizeList>(null);

  const resetListIndex = (): void => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  };

  React.useEffect(() => {
    if (listRef.current) {
      resetListIndex();
    }
  }, [warnings]);

  const filterHeight = useGetFilterHeight(
    filterRef as React.MutableRefObject<HTMLElement>,
  );

  React.useEffect(() => {
    if (hoveredFeatureId) {
      const index = items.findIndex(
        (item) => (item as Warning).id === hoveredFeatureId,
      );
      listRef.current?.scrollToItem(index, 'smart');
    }
  }, [hoveredFeatureId, items]);

  return (
    <>
      {isLoading && (
        <LinearProgress
          data-testid="loading-bar"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 0 }}
        />
      )}
      <Box
        sx={{
          padding: 3,
          height: '100%',
          overflowY: 'hidden',
          containerName: 'publicWarningList',
          containerType: 'inline-size',
        }}
        data-testid="publicWarningList"
      >
        <Grid
          container
          justifyItems="flex-end"
          ref={filterRef}
          sx={{
            [`@container publicWarningList (max-width: ${breakpoints.tablet}px)`]:
              {
                ' .MuiGrid2-grid-sm-12': {
                  maxWidth: '100%',
                  flexBasis: '100%',
                },
                ' .warning-filter': {
                  order: 2,
                },
                ' .create-warning': {
                  order: 1,
                },
              },
          }}
        >
          <Grid
            className="warning-filter"
            order={{ xs: 2, md: 1 }}
            size={{
              xs: 12,
              sm: 12,
              md: 8,
            }}
          >
            <FilterList
              isAllSelected={allSelected}
              onClickAll={onClickAll}
              filterState={filterState}
              updateFilter={updateFilter}
            />
          </Grid>
          <Grid
            className="create-warning"
            order={{ xs: 1, md: 2 }}
            sx={{
              display: 'flex',
            }}
            justifyContent="flex-end"
            alignItems="self-start"
            size={{
              xs: 12,
              sm: 12,
              md: 4,
            }}
          >
            {lastUpdatedTime && (
              <Box sx={{ float: 'left', minWidth: 140, paddingTop: 0.5 }}>
                <LastUpdateTime
                  onPressRefresh={onRefetchWarnings}
                  lastUpdateTime={lastUpdatedTime}
                />
              </Box>
            )}
            <NewWarningButton onCreateWarning={onCreateWarning} />
          </Grid>
        </Grid>
        {error && <AlertBanner title={error.message} shouldClose />}

        {!error && (
          <Grid
            container
            rowSpacing={0}
            columnSpacing={0}
            alignItems="center"
            justifyContent="space-between"
            size="grow"
            sx={{
              maxWidth: '100%',
              display: 'none',
              marginTop: '4px',
              [`@container publicWarningList (min-width: ${breakpoints.desktop}px)`]:
                {
                  display: 'flex',
                },
            }}
          >
            <WarningListHeaderContent
              title={t('warning-list-header-incidents')}
              sx={{ paddingLeft: 2 }}
            />
            <WarningListHeaderContent
              title={`${t('warning-list-header-last-update')} UTC`}
              width={142}
              onClick={() => toggleSortDirection('lastUpdatedTime')}
              isSorted={sortState.key === 'lastUpdatedTime'}
              sortDirection={sortState.direction}
              sortable
            />
            <WarningListHeaderContent
              title={`${t('warning-list-header-start-time')} UTC`}
              width={142}
              onClick={() => toggleSortDirection('validFrom')}
              isSorted={sortState.key === 'validFrom'}
              sortDirection={sortState.direction}
              sortable
            />
            <WarningListHeaderContent
              title={`${t('warning-list-header-end-time')} UTC`}
              width={142}
              onClick={() => toggleSortDirection('validUntil')}
              isSorted={sortState.key === 'validUntil'}
              sortDirection={sortState.direction}
              sortable
            />

            <WarningListHeaderContent
              title={t('warning-list-header-domains')}
            />
            <WarningListHeaderContent
              title={t('warning-list-header-phenomena')}
              width={140}
            />
            <WarningListHeaderContent
              title={t('warning-list-header-levels')}
              sx={{
                [`@container publicWarningList (min-width: ${breakpoints.desktop}px)`]:
                  {
                    width: 56,
                  },
              }}
            />
            <WarningListHeaderContent title={t('areas')} width={100} />
            <WarningListHeaderContent
              title={t('warning-list-header-status')}
              width={76}
              sx={{ marginRight: '48px' }}
            />
          </Grid>
        )}
        {sortedWarnings && !error && (
          <Box
            data-testid="warnings-list"
            sx={{
              height: `calc(100% - ${filterHeight}px)`,
              minHeight: '206px',
            }}
          >
            <AutoSizer onResize={resetListIndex}>
              {({ height = 0, width = 0 }): React.ReactNode => (
                <VariableSizeList
                  height={height}
                  width={width}
                  itemCount={items.length}
                  itemSize={(index: number) => getItemSize(index, width)}
                  ref={listRef}
                >
                  {({ index, style }): React.ReactElement => {
                    const warning = items[index];
                    const key = `warning-${warning.status}-${index}`;
                    if (
                      (warning as WarningListElementHeader).totalWarnings !==
                      undefined
                    ) {
                      const header = warning as WarningListElementHeader;
                      return (
                        <WarningListItemSeperator
                          key={key}
                          status={header.status}
                          totalWarnings={header.totalWarnings}
                          style={style}
                          isExpanded={expandedSections[warning.status!]}
                          onClick={() => {
                            toggleExpand(
                              !expandedSections[header.status],
                              header.status,
                            );
                          }}
                        />
                      );
                    }

                    const publicWarning = warning as Warning;
                    const highlightedItem =
                      hoveredFeatureId === publicWarning.id;

                    return (
                      <WarningListItem
                        key={key}
                        style={style}
                        warning={publicWarning}
                        highlightedItem={highlightedItem}
                        onSelectWarning={onSelectWarning}
                        onEditWarning={onEditWarning}
                        onDeleteWarning={onDeleteWarning}
                        onDeleteReviewWarning={onDeleteReviewWarning}
                        activeWarningId={selectedWarningId}
                        onExpireWarning={onExpireWarning}
                        onWithdrawWarning={onWithdrawWarning}
                        onActionAviationWarning={onActionAviationWarning}
                      />
                    );
                  }}
                </VariableSizeList>
              )}
            </AutoSizer>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PublicWarningList;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export default `<?xml version="1.0" encoding="UTF-8"?>
<WMS_Capabilities xmlns="http://www.opengis.net/wms" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.3.0" updateSequence="0" xsi:schemaLocation="http://www.opengis.net/wms http://schemas.opengis.net/wms/1.3.0/capabilities_1_3_0.xsd">
   <Service>
      <Name>WMS</Name>
      <Title>RADAR</Title>
      <Abstract>This service demonstrates how the ADAGUC server can be used to create OGC services.</Abstract>
      <KeywordList>
         <Keyword>view</Keyword>
         <Keyword>infoMapAccessService</Keyword>
         <Keyword>ADAGUCServer version 2.5.8, of Apr 13 2021 07:55:43</Keyword>
      </KeywordList>
      <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
      <ContactInformation />
      <Fees>no conditions apply</Fees>
      <AccessConstraints>None</AccessConstraints>
      <MaxWidth>8192</MaxWidth>
      <MaxHeight>8192</MaxHeight>
   </Service>
   <Capability>
      <Request>
         <GetCapabilities>
            <Format>text/xml</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetCapabilities>
         <GetMap>
            <Format>image/png</Format>
            <Format>image/png;mode=8bit</Format>
            <Format>image/png;mode=8bit_noalpha</Format>
            <Format>image/png;mode=24bit</Format>
            <Format>image/png;mode=32bit</Format>
            <Format>image/gif</Format>
            <Format>image/jpeg</Format>
            <!--<Format>image/webp</Format>-->
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetMap>
         <GetFeatureInfo>
            <Format>image/png</Format>
            <Format>text/plain</Format>
            <Format>text/html</Format>
            <Format>text/xml</Format>
            <Format>application/json</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetFeatureInfo>
      </Request>
      <Exception>
         <Format>XML</Format>
         <Format>INIMAGE</Format>
         <Format>BLANK</Format>
      </Exception>
      <UserDefinedSymbolization SupportSLD="1" />
      <Layer>
         <Title>WMS of  RADAR</Title>
         <CRS>EPSG:3411</CRS>
         <CRS>EPSG:3412</CRS>
         <CRS>EPSG:3575</CRS>
         <CRS>EPSG:3857</CRS>
         <CRS>EPSG:4258</CRS>
         <CRS>EPSG:4326</CRS>
         <CRS>CRS:84</CRS>
         <CRS>EPSG:25831</CRS>
         <CRS>EPSG:25832</CRS>
         <CRS>EPSG:28992</CRS>
         <CRS>EPSG:7399</CRS>
         <CRS>EPSG:50001</CRS>
         <CRS>EPSG:54030</CRS>
         <CRS>EPSG:32661</CRS>
         <CRS>EPSG:40000</CRS>
         <CRS>EPSG:900913</CRS>
         <CRS>PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0</CRS>
         <BoundingBox CRS="EPSG:3411" minx="2682754.743620" miny="-3245034.014141" maxx="3759536.917562" maxy="-2168251.989038" />
         <BoundingBox CRS="EPSG:3412" minx="0.000000" miny="32318824.826266" maxx="7413041.166015" maxy="40075258.815074" />
         <BoundingBox CRS="EPSG:3575" minx="-770622.801471" miny="-4485814.811314" maxx="56845.766135" maxy="-3684039.443620" />
         <BoundingBox CRS="EPSG:3857" minx="0.000000" miny="6257115.219364" maxx="1208534.698398" maxy="7553161.958695" />
         <BoundingBox CRS="EPSG:4258" minx="48.895303" miny="0.000000" maxx="55.973600" maxy="10.856452" />
         <BoundingBox CRS="EPSG:4326" minx="48.895303" miny="0.000000" maxx="55.973600" maxy="10.856452" />
         <BoundingBox CRS="CRS:84" minx="48.895303" miny="0.000000" maxx="55.973600" maxy="10.856452" />
         <BoundingBox CRS="EPSG:25831" minx="282182.345905" miny="5433247.394267" maxx="997135.658653" maxy="6207204.592736" />
         <BoundingBox CRS="EPSG:25832" minx="-153083.019482" miny="5415817.312927" maxx="617595.626092" maxy="6239769.309937" />
         <BoundingBox CRS="EPSG:28992" minx="-236275.338083" miny="106727.731651" maxx="501527.918656" maxy="900797.079725" />
         <BoundingBox CRS="EPSG:7399" minx="0.000000" miny="5757301.056717" maxx="763611.971696" maxy="6483919.801602" />
         <BoundingBox CRS="EPSG:50001" minx="-2000000.000000" miny="-2000000.000000" maxx="10000000.000000" maxy="8500000.000000" />
         <BoundingBox CRS="EPSG:54030" minx="0.000000" miny="5211855.054125" maxx="853649.695106" maxy="5936394.291427" />
         <BoundingBox CRS="EPSG:32661" minx="2000000.000000" miny="-2703305.597319" maxx="2745713.040381" maxy="-1888346.216710" />
         <BoundingBox CRS="EPSG:40000" minx="0.000000" miny="-4731695.771951" maxx="750214.326339" maxy="-3911817.119426" />
         <BoundingBox CRS="EPSG:900913" minx="0.000000" miny="6257115.219364" maxx="1208534.698398" maxy="7553161.958695" />
         <BoundingBox CRS="PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0" minx="0.000000" miny="-3649.999338" maxx="700.002420" maxy="-4415.002986" />
         <Style>
            <Name>precip-gray/nearest</Name>
            <Title>precip-gray/nearest</Title>
            <LegendURL width="300" height="400">
                <Format>image/png</Format>
                <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-gray/nearest" />
            </LegendURL>
          </Style>
          <Style>
            <Name>precip-blue/nearest</Name>
            <Title>precip-blue/nearest</Title>
            <LegendURL width="300" height="400">
                <Format>image/png</Format>
                <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-blue/nearest" />
            </LegendURL>
          </Style>
          <Dimension name="time" units="ISO8601" default="2021-05-14T07:35:00Z" multipleValues="1" nearestValue="0" current="1">2021-03-31T09:25:00Z/2021-05-14T07:35:00Z/PT5M</Dimension>
         <Layer queryable="1" opaque="1" cascaded="0">
            <Name>RAD_NL25_PCP_CM-inherited</Name>
            <Title>Precipitation Radar NL</Title>
            <EX_GeographicBoundingBox>
               <westBoundLongitude>0.000000</westBoundLongitude>
               <eastBoundLongitude>10.856452</eastBoundLongitude>
               https://stackoverflow.com/questions/62249737/how-to-read-xml-filetoolsbox-xml-in-react-typescript
               <southBoundLatitude>48.895303</southBoundLatitude>
               <northBoundLatitude>55.973600</northBoundLatitude>
            </EX_GeographicBoundingBox>
            <BoundingBox CRS="EPSG:3411" minx="2682754.743620" miny="-3245034.014141" maxx="3759536.917562" maxy="-2168251.989038" />
            <BoundingBox CRS="EPSG:3412" minx="0.000000" miny="32318824.826266" maxx="7413041.166015" maxy="40075258.815074" />
            <BoundingBox CRS="EPSG:3575" minx="-770622.801471" miny="-4485814.811314" maxx="56845.766135" maxy="-3684039.443620" />
            <BoundingBox CRS="EPSG:3857" minx="0.000000" miny="6257115.219364" maxx="1208534.698398" maxy="7553161.958695" />
            <BoundingBox CRS="EPSG:4258" minx="48.895303" miny="0.000000" maxx="55.973600" maxy="10.856452" />
            <BoundingBox CRS="EPSG:4326" minx="48.895303" miny="0.000000" maxx="55.973600" maxy="10.856452" />
            <BoundingBox CRS="CRS:84" minx="48.895303" miny="0.000000" maxx="55.973600" maxy="10.856452" />
            <BoundingBox CRS="EPSG:25831" minx="282182.345905" miny="5433247.394267" maxx="997135.658653" maxy="6207204.592736" />
            <BoundingBox CRS="EPSG:25832" minx="-153083.019482" miny="5415817.312927" maxx="617595.626092" maxy="6239769.309937" />
            <BoundingBox CRS="EPSG:28992" minx="-236275.338083" miny="106727.731651" maxx="501527.918656" maxy="900797.079725" />
            <BoundingBox CRS="EPSG:7399" minx="0.000000" miny="5757301.056717" maxx="763611.971696" maxy="6483919.801602" />
            <BoundingBox CRS="EPSG:50001" minx="-2000000.000000" miny="-2000000.000000" maxx="10000000.000000" maxy="8500000.000000" />
            <BoundingBox CRS="EPSG:54030" minx="0.000000" miny="5211855.054125" maxx="853649.695106" maxy="5936394.291427" />
            <BoundingBox CRS="EPSG:32661" minx="2000000.000000" miny="-2703305.597319" maxx="2745713.040381" maxy="-1888346.216710" />
            <BoundingBox CRS="EPSG:40000" minx="0.000000" miny="-4731695.771951" maxx="750214.326339" maxy="-3911817.119426" />
            <BoundingBox CRS="EPSG:900913" minx="0.000000" miny="6257115.219364" maxx="1208534.698398" maxy="7553161.958695" />
            <BoundingBox CRS="PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0" minx="0.000000" miny="-3649.999338" maxx="700.002420" maxy="-4415.002986" />
            
            <Style>
               <Name>radar/nearest</Name>
               <Title>radar/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=radar/nearest" />
               </LegendURL>
            </Style>
            <Style>
               <Name>precip-rainbow/nearest</Name>
               <Title>precip-rainbow/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-rainbow/nearest" />
               </LegendURL>
            </Style>
         </Layer>
      </Layer>
   </Capability>
</WMS_Capabilities>`;

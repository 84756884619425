/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Button, Grid2 as Grid } from '@mui/material';
import { Edit } from '@opengeoweb/theme';
import { useSpaceweatherTranslation } from '../../utils/i18n';

const styles = {
  optionButton: {
    marginRight: '10px',
  },
};

interface LifeCycleActionsProps {
  actions: string[];
  onAction: (actionType: string) => void;
}

const LifeCycleActions: React.FC<LifeCycleActionsProps> = ({
  actions,
  onAction,
}: LifeCycleActionsProps) => {
  const { t } = useSpaceweatherTranslation();
  return (
    <Grid
      container
      size={12}
      alignItems="center"
      justifyContent="flex-end"
      data-testid="lifecycle-actions-displaybuttons"
    >
      <Grid>
        {(actions.includes('updated') || actions.includes('extended')) && (
          <Button
            data-testid="updateextend"
            color="secondary"
            onClick={(): void => onAction('Updateextend')}
            sx={styles.optionButton}
            startIcon={<Edit />}
          >
            {t('notification-update-extend')}
          </Button>
        )}
      </Grid>
      <Grid>
        {actions.includes('summarised') && (
          <Button
            data-testid="summarise"
            variant="contained"
            color="secondary"
            onClick={(): void => onAction('Summarise')}
            sx={styles.optionButton}
          >
            {t('notification-summarise')}
          </Button>
        )}
      </Grid>
      <Grid>
        {actions.includes('cancelled') && (
          <Button
            data-testid="cancel"
            variant="contained"
            color="secondary"
            onClick={(): void => onAction('Cancel')}
            sx={styles.optionButton}
          >
            {t('notification-cancel')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default LifeCycleActions;

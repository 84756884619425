/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  CoreAppStore,
  layerSelectors,
  uiTypes,
  useSetupDialog,
} from '@opengeoweb/store';
import { useSelector } from 'react-redux';
import { SearchDialog } from './SearchDialog';
import {
  myLocationDrawFunction,
  selectedLocationDrawFunction,
  useDrawOnMap,
} from './utils';
import { MY_LOCATION } from './MyMapLocation';

export interface SearchDialogConnectProps {
  mapId?: string;
}

export const SearchDialogConnect: React.FC<SearchDialogConnectProps> = ({
  mapId = '',
}: SearchDialogConnectProps) => {
  const dialogType = `${uiTypes.DialogTypes.Search}-${mapId}`;
  const { isDialogOpen } = useSetupDialog(dialogType);
  const [updateMapPin] = useDrawOnMap(mapId, selectedLocationDrawFunction);
  const [updateMyLocation] = useDrawOnMap(mapId, myLocationDrawFunction);

  const mapLayers = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayersByMapId(store, mapId),
  );

  const myLocationLayer = mapLayers.find((layer) => {
    if (layer.geojson?.features[0]?.properties?.name) {
      if (layer.geojson.features[0].properties.name === MY_LOCATION) {
        return true;
      }
    }
    return false;
  });
  const isMyLocationSelected = !!myLocationLayer;

  return (
    <SearchDialog
      isOpen={isDialogOpen}
      mapId={mapId}
      onSelectLocation={updateMapPin}
      onUpdateMyLocation={updateMyLocation}
      isMyLocationSelected={isMyLocationSelected}
    />
  );
};

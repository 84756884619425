/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware, TaskAbortError } from '@reduxjs/toolkit';
import { uiActions } from '@opengeoweb/store';
import { getAxiosErrorMessage, isAxiosError } from '@opengeoweb/shared';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { warningsDrawingsActions } from './reducer';
import { drawingDialogType } from './utils';
import { getWarningsApi } from '../../utils/api';
import { WarningModuleStore } from '../types';

const getSnackbarMessage = (
  objectName: string,
  id: string,
): snackbarTypes.TranslatableMessage => ({
  type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
  key: id ? 'update-succes' : 'save-succes',
  params: { objectName },
});

export const warningsDrawingsListener =
  createListenerMiddleware<WarningModuleStore>();

warningsDrawingsListener.startListening({
  actionCreator: warningsDrawingsActions.submitDrawValues,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { id, ...formValues } = payload;

    try {
      listenerApi.dispatch(
        uiActions.toggleIsLoadingDialog({
          isLoading: true,
          type: drawingDialogType,
        }),
      );
      const warningsApi = getWarningsApi();
      if (!id) {
        const newID = await warningsApi.saveDrawingAs(formValues);
        listenerApi.dispatch(
          warningsDrawingsActions.setDrawValues({
            drawingInstanceId: drawingDialogType,
            id: newID,
            objectName: formValues.objectName,
          }),
        );
      } else {
        await warningsApi.updateDrawing(id, formValues);
      }

      listenerApi.dispatch(
        uiActions.setErrorDialog({
          type: drawingDialogType,
          error: '',
        }),
      );
      listenerApi.dispatch(
        snackbarActions.openSnackbar(
          getSnackbarMessage(formValues.objectName, id!),
        ),
      );
      listenerApi.dispatch(
        uiActions.toggleIsLoadingDialog({
          isLoading: false,
          type: drawingDialogType,
        }),
      );
    } catch (error) {
      // Don't do anything if the cancelActiveListeners was invoked (which throws a TaskAbortError)
      const taskError = error as TaskAbortError;

      if (taskError?.code !== 'listener-cancelled') {
        listenerApi.dispatch(
          uiActions.toggleIsLoadingDialog({
            isLoading: false,
            type: drawingDialogType,
          }),
        );

        const errorMessage = isAxiosError(taskError)
          ? getAxiosErrorMessage(taskError)
          : taskError.message;
        listenerApi.dispatch(
          uiActions.setErrorDialog({
            type: drawingDialogType,
            error: errorMessage,
          }),
        );
      }
    }
  },
});

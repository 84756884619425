/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WMJSONDocument = any;

export const WMXMLStringToXMLDocument = (xmlString: string): XMLDocument => {
  const xmlData: XMLDocument = new DOMParser().parseFromString(
    xmlString as string,
    'text/xml',
  );
  if (xmlData.documentElement.nodeName === 'parsererror') {
    throw new TypeError(`Error while parsing: ${xmlData}`);
  }
  return xmlData;
};

export const WMXMLtoJSON = (xmlDocument: XMLDocument): WMJSONDocument => {
  // eslint-disable-next-line no-shadow
  const traverse = (
    data: XMLDocument,
    path: string,
    json: WMJSONDocument,
  ): void => {
    if (data.children && data.children.length) {
      for (const child of data.children) {
        const newPath = `${path}->${child.nodeName}`;
        let newJson: WMJSONDocument = null;
        const { nodeName } = child;
        if (json[nodeName]) {
          newJson = { attr: {} };

          // eslint-disable-next-line no-empty
          if (json[nodeName] instanceof Array) {
          } else {
            const obj = { ...json[nodeName] };
            // eslint-disable-next-line no-param-reassign
            json[nodeName] = [];
            json[nodeName].push(obj);
          }
          json[nodeName].push(newJson);
        } else {
          // eslint-disable-next-line no-param-reassign
          json[nodeName] = { attr: {} };
          newJson = json[nodeName];
        }
        // Value
        if (child.childNodes && child.childNodes.length > 0) {
          if (child.childNodes[0].nodeValue) {
            const value = child.childNodes[0].nodeValue.trim();
            if (value !== '\n' && value.length > 0) {
              newJson.value = value;
            }
          }
        }
        // Attributes
        if (child.attributes && child.attributes.length > 0) {
          for (const childAttribute of child.attributes) {
            newJson.attr[childAttribute.name] = childAttribute.value;
          }
        }

        traverse(child as unknown as XMLDocument, newPath, newJson);
      }
    }
  };
  const json = {};
  traverse(xmlDocument, '', json);
  return json;
};

export const WMXMLStringToJson = (xmlString: string): WMJSONDocument =>
  WMXMLtoJSON(WMXMLStringToXMLDocument(xmlString));

const WMXMLParser = (
  url: string,
  headers: { name: string; value: string }[],
): Promise<Record<string, unknown>> => {
  return new Promise((resolve, reject) => {
    const fetchHeaders = new Headers();
    if (headers && headers.length > 0) {
      for (const header of headers) {
        fetchHeaders.append(header.name, header.value);
      }
    }
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: fetchHeaders,
    })
      .then((response) => {
        return response.text();
      })
      .catch((e) => {
        return reject(e);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        try {
          const result = WMXMLStringToJson(data);
          return resolve(result);
        } catch (e) {
          return reject(e);
        }
      })
      .catch((e) => {
        return reject(e);
      });
  });
};
export default WMXMLParser;

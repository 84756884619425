/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Menu, MenuItem, Box, Typography, CardHeader } from '@mui/material';
import { CustomIconButton, CustomTooltip } from '@opengeoweb/shared';
import { useState } from 'react';
import { throttle } from 'lodash';
import { Edit } from '@opengeoweb/theme';
import { minutesToDescribedDuration } from '../../TimeSlider/timeSliderUtils';
import { useTimesliderTranslation } from '../../../utils/i18n';

const THROTTLE_WAIT_TIME = 300; // [ms]
const THROTTLE_OPTIONS = {
  trailing: false, // Invoke function when called, but not more than once every <THROTTLE_WAIT_TIME>.
};
export interface Mark {
  label?: string;
  value: number;
  text: string;
}

export interface TimeSliderMenuProps {
  marks: Mark[];
  value: number;
  title: string;
  icon: React.ReactElement;
  allOptionsDisabled?: boolean;
  isAutoDisabled?: boolean;
  handleAutoClick?: () => void;
  isAutoSelected?: boolean;
  onChangeSliderValue?: (markValue: number) => void;
  setOpen: (display: boolean) => void;
  open: boolean;
  displayCurrentLength?: boolean;
  disbleUserSetDuration?: boolean;
  customLengthSelector?: React.ReactNode;
  customFieldHeader?: string;
}

export const TimeSliderMenu = ({
  marks,
  value,
  allOptionsDisabled,
  isAutoDisabled,
  handleAutoClick,
  isAutoSelected,
  title,
  icon,
  onChangeSliderValue = (): void => {},
  displayCurrentLength,
  disbleUserSetDuration = true,
  customLengthSelector,
  setOpen = (): void => {},
  customFieldHeader = 'timeslider-length',
  open,
}: TimeSliderMenuProps): React.ReactElement => {
  const currentMarkIndex = marks.findIndex((mark) => mark.value === value);
  const currentMark = marks[currentMarkIndex];
  const [anchorRef, setAnchorRef] = React.useState<null | HTMLElement>(null);
  const { t } = useTimesliderTranslation();
  const [displayLengthInputField, setdisplayLengthInputField] = useState(false);

  React.useEffect(() => {
    if (!open && displayLengthInputField) {
      setdisplayLengthInputField(false);
    }
  }, [displayLengthInputField, open]);

  const buttonText = (
    value: number,
    displayCurrentLength: boolean | undefined,
    currentMark: Mark,
  ): string => {
    if (displayCurrentLength) {
      return minutesToDescribedDuration(value);
    }
    if (currentMark) {
      const parts = t(currentMark.label!).split(' ');
      if (parts.length > 1) {
        return parts[0] + parts.slice(1).join(' ');
      }
      return parts.join('');
    }
    return '';
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledScroll = React.useCallback(
    throttle(
      (newValue) => {
        onChangeSliderValue(newValue);
      },
      THROTTLE_WAIT_TIME,
      THROTTLE_OPTIONS,
    ),
    [],
  );

  const onWheel = React.useCallback(
    (event: React.WheelEvent): void => {
      const direction = event.deltaY < 0 ? 1 : -1;
      const newValue =
        marks[
          Math.min(Math.max(currentMarkIndex - direction, 0), marks.length - 1)
        ];
      throttledScroll(newValue.value);
    },
    [currentMarkIndex, marks, throttledScroll],
  );

  return (
    <Box
      onKeyDown={(event): void => {
        event.stopPropagation();
      }}
    >
      <CustomIconButton
        variant="tool"
        tooltipTitle={title}
        onClick={(): void => {
          setOpen(true);
        }}
        ref={setAnchorRef}
        isSelected
        onWheel={onWheel}
        sx={{ padding: '11px!important', width: '100%!important' }}
      >
        {icon}
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 400,
            textTransform: 'none',
          }}
        >
          {buttonText(value, displayCurrentLength, currentMark)}
        </Typography>
      </CustomIconButton>
      {anchorRef && (
        <Menu
          anchorEl={anchorRef}
          open={open}
          onClose={() => setOpen(false)}
          BackdropProps={{
            sx: { backgroundColor: 'transparent' },
          }}
          anchorOrigin={{
            vertical: -15,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <MenuItem
            disabled
            sx={{
              fontSize: '12px',
              opacity: 0.67,
              padding: '0px 12px',
              '&.MuiMenuItem-root': {
                minHeight: '30px!important',
                minWidth: 150,
              },
            }}
          >
            {title}
          </MenuItem>

          {marks.map((mark) => {
            const isSelected =
              currentMark?.value === mark.value && !isAutoSelected;
            return (
              <MenuItem
                onClick={(): void => {
                  if (!isSelected) {
                    onChangeSliderValue(mark.value);
                  }
                  setOpen(false);
                }}
                key={mark.value}
                selected={isSelected && !displayLengthInputField}
                disabled={allOptionsDisabled}
              >
                {mark.label || mark.text}
              </MenuItem>
            );
          })}
          {handleAutoClick && (
            <MenuItem
              onClick={(): void => {
                handleAutoClick();
                setOpen(false);
              }}
              selected={isAutoSelected}
              disabled={allOptionsDisabled || isAutoDisabled}
            >
              {t('timeslider-auto')}
            </MenuItem>
          )}
          {displayCurrentLength && (
            <MenuItem
              onClick={() => setdisplayLengthInputField(true)}
              disabled={disbleUserSetDuration}
              sx={{ maxWidth: '175px' }}
            >
              {displayLengthInputField && customLengthSelector}
              {!displayLengthInputField && (
                <Box
                  sx={{
                    flexDirection: 'column',
                    display: 'flex',
                    flexGrow: 1,
                  }}
                >
                  <CardHeader
                    title={t(customFieldHeader)}
                    sx={{
                      height: 2,
                      padding: 0,
                      color: 'geowebColors.greys.accessible',
                    }}
                    titleTypographyProps={{
                      fontSize: 12,
                    }}
                  />
                  <Box
                    sx={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexGrow: 1,
                      paddingTop: 0.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        textTransform: 'none',
                        lineHeight: 2,
                      }}
                    >
                      {minutesToDescribedDuration(value)}
                    </Typography>
                    <CustomTooltip title={t('timeslider-edit')}>
                      <span>
                        <CustomIconButton
                          aria-label="edit animation option"
                          data-testid="editAnimationOption"
                        >
                          <Edit />
                        </CustomIconButton>
                      </span>
                    </CustomTooltip>
                  </Box>
                </Box>
              )}
            </MenuItem>
          )}
        </Menu>
      )}
    </Box>
  );
};

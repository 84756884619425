/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import * as actions from './actions';
import { SynchronizationGroupModuleState } from './syncGroups/types';
import * as synchronizationGroupsSelector from './syncGroups/selectors';
import {
  SYNCGROUPS_TYPE_SETBBOX,
  SYNCGROUPS_TYPE_SETLAYERACTIONS,
  SYNCGROUPS_TYPE_SETTIME,
} from './syncGroups/constants';
import {
  setBboxSync,
  setLayerActionSync,
  setTimeSync,
} from './synchronizationActions/actions';
import {
  SetBboxSyncPayload,
  SetTimeSyncPayload,
} from './synchronizationActions/types';
import { layerActions } from '../map/layer/reducer';
import { AddLayerPayload, LayerActionOrigin } from '../map/layer/types';
import {
  getAddLayerActionsTargets,
  getLayerActionsTargets,
  getLayerDeleteActionsTargets,
  getLayerMoveActionsTargets,
  getMapBaseLayerActionsTargets,
  getSetAutoLayerIdActionsTargets,
} from './syncGroups/utils';
import { layerSelectors, mapActions } from '../map';

const setTimeValidatorRexexp =
  /^(19|20)\d\d-(0[1-9]|1[012])-([012]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)Z$/;

export const genericListener =
  createListenerMiddleware<SynchronizationGroupModuleState>();

genericListener.startListening({
  actionCreator: actions.setTime,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { value, origin } = payload;
    /* Test if the value is according to the expected time format */
    if (!setTimeValidatorRexexp.test(value)) {
      console.error(
        `setTime value ${value} does not conform to format [YYYY-MM-DDThh:mm:ssZ]. It was triggered by ${origin}`,
      );
    } else {
      const targets = synchronizationGroupsSelector.getTargets(
        listenerApi.getState(),
        payload,
        SYNCGROUPS_TYPE_SETTIME,
      ) as SetTimeSyncPayload[];
      const groups = synchronizationGroupsSelector.getTargetGroups(
        listenerApi.getState(),
        payload,
        SYNCGROUPS_TYPE_SETTIME,
      );

      listenerApi.dispatch(setTimeSync(payload, targets, groups));
    }
  },
});

genericListener.startListening({
  actionCreator: actions.setBbox,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const targets = synchronizationGroupsSelector.getTargets(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETBBOX,
    ) as SetBboxSyncPayload[];
    const groups = synchronizationGroupsSelector.getTargetGroups(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETBBOX,
    );

    listenerApi.dispatch(setBboxSync(payload, targets, groups));
  },
});

genericListener.startListening({
  matcher: isAnyOf(
    layerActions.layerChangeName,
    layerActions.layerChangeEnabled,
    layerActions.layerChangeOpacity,
    layerActions.layerChangeDimension,
    layerActions.layerChangeStyle,
  ),
  effect: async ({ payload, type }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    /* Should not listen to actions from itself */
    if (
      payload &&
      payload.origin === LayerActionOrigin.ReactMapViewParseLayer
    ) {
      return;
    }
    const targets = getLayerActionsTargets(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETLAYERACTIONS,
    );
    if (targets && targets.length > 0) {
      listenerApi.dispatch(setLayerActionSync(payload, targets, type));
    }
  },
});

genericListener.startListening({
  actionCreator: layerActions.addLayer,
  effect: async ({ payload, type }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    /* Should not listen to actions from itself */
    if (
      payload &&
      payload.origin === LayerActionOrigin.ReactMapViewParseLayer
    ) {
      return;
    }
    const targets = getAddLayerActionsTargets(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETLAYERACTIONS,
    );
    if (targets && targets.length > 0) {
      listenerApi.dispatch(setLayerActionSync(payload, targets, type));
    }
  },
});

genericListener.startListening({
  actionCreator: layerActions.duplicateMapLayer,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const sourceLayer = layerSelectors.getLayerById(
      listenerApi.getState(),
      payload.oldLayerId,
    );
    const newPayload = {
      mapId: payload.mapId,
      layer: sourceLayer,
      origin: payload.origin,
    };
    const targets = getAddLayerActionsTargets(
      listenerApi.getState(),
      newPayload as AddLayerPayload,
      SYNCGROUPS_TYPE_SETLAYERACTIONS,
    );
    if (targets && targets.length > 0) {
      listenerApi.dispatch(
        setLayerActionSync(newPayload, targets, layerActions.addLayer.type),
      );
    }
  },
});

genericListener.startListening({
  actionCreator: mapActions.layerMoveLayer,
  effect: async ({ payload, type }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    /* Should not listen to actions from itself */
    if (
      payload &&
      payload.origin === LayerActionOrigin.ReactMapViewParseLayer
    ) {
      return;
    }

    const targets = getLayerMoveActionsTargets(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETLAYERACTIONS,
    );
    if (targets && targets.length > 0) {
      listenerApi.dispatch(setLayerActionSync(payload, targets, type));
    }
  },
});

genericListener.startListening({
  matcher: isAnyOf(
    mapActions.setAutoLayerId,
    mapActions.setAutoUpdateLayerId,
    mapActions.setAutoTimestepLayerId,
  ),
  effect: async ({ payload, type }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const targets = getSetAutoLayerIdActionsTargets(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETLAYERACTIONS,
    );
    if (targets && targets.length > 0) {
      listenerApi.dispatch(setLayerActionSync(payload, targets, type));
    }
  },
});

genericListener.startListening({
  actionCreator: layerActions.setBaseLayers,
  effect: async ({ payload, type }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    /* Should not listen to actions from itself */
    if (
      payload &&
      payload.origin === LayerActionOrigin.ReactMapViewParseLayer
    ) {
      return;
    }
    const targets = getMapBaseLayerActionsTargets(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETLAYERACTIONS,
    );
    if (targets && targets.length > 0) {
      listenerApi.dispatch(setLayerActionSync(payload, targets, type));
    }
  },
});

genericListener.startListening({
  actionCreator: layerActions.layerDelete,
  effect: async ({ payload, type }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    /* Should not listen to actions from itself */
    if (
      payload &&
      payload.origin === LayerActionOrigin.ReactMapViewParseLayer
    ) {
      return;
    }
    const targets = getLayerDeleteActionsTargets(
      listenerApi.getState(),
      payload,
      SYNCGROUPS_TYPE_SETLAYERACTIONS,
    );
    if (targets && targets.length > 0) {
      listenerApi.dispatch(setLayerActionSync(payload, targets, type));
    }
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useAuthenticationContext } from '@opengeoweb/authentication';
import {
  dateUtils,
  SystemScope,
  useConfirmationDialog,
} from '@opengeoweb/shared';
import { ProductAction, ProductConfig } from '@opengeoweb/sigmet-airmet';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { uiActions, useUpdateSharedData } from '@opengeoweb/store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWarnings } from '../../api/api-utils';
import { useProductConfig } from '../../api/aviation-api/hooks';
import { useMutatePublicWarningEditor } from '../../api/warning-api/hooks';
import {
  FormAction,
  publicWarningFormActions,
} from '../../store/publicWarningForm/reducer';
import {
  getSelectedPublicIsFormDirty,
  getSelectedWarningId,
  getWarningType,
} from '../../store/publicWarningForm/selectors';
import {
  AirmetWarning,
  PublicWarningStatus,
  SigmetWarning,
  Warning,
  WarningType,
} from '../../store/publicWarningForm/types';
import { publicWarningDialogType } from '../../store/publicWarningForm/utils';
import { publicWarningActions } from '../../store/publicWarnings/reducer';
import { getWarningsFilterById } from '../../store/publicWarnings/selectors';
import { WarningModuleStore } from '../../store/types';
import { getWarningsApi } from '../../utils/api';
import { useWarningsTranslation } from '../../utils/i18n';
import {
  duplicateAviationProduct,
  renewAviationProduct,
} from '../../utils/sig-airmet-util';
import {
  PickedFilterState,
  useWarningFilters,
} from '../FilterList/filter-hooks';
import { warningLevelFeatureColors } from '../PublicWarningsForm';
import { WarningLevel } from '../PublicWarningsForm/LevelField';
import { warningFormConfirmationOptions } from '../PublicWarningsFormDialog/PublicWarningsFormDialogConnect';
import {
  ConfirmDeleteWarningDialog,
  ConfirmDialogProps,
} from './ConfirmWarningDialog';
import { PublicWarningList } from './PublicWarningList';

export interface PublicWarningListConnectProps {
  panelId: string;
  presetFilterState?: PickedFilterState;
}

export const PublicWarningListConnect: React.FC<
  PublicWarningListConnectProps
> = ({ panelId, presetFilterState }) => {
  const updatePublicWarningEditor = useMutatePublicWarningEditor();
  const { t } = useWarningsTranslation();
  const dispatch = useDispatch();
  const confirmDialog = useConfirmationDialog();
  const { auth } = useAuthenticationContext();

  const {
    combinedWarnings,
    refetchWarnings,
    isFetching,
    error,
    dataUpdatedAt,
  } = useWarnings();

  const lastUpdateTime = dateUtils.dateToString(
    new Date(dataUpdatedAt),
    dateUtils.DATE_FORMAT_HOURS,
  );

  const [confirmDialogOptions, setConfirmDialogOptions] = React.useState<
    ConfirmDialogProps | undefined
  >(undefined);

  const selectedWarningId = useSelector(getSelectedWarningId);

  const savedFilterState = useSelector((store: WarningModuleStore) =>
    getWarningsFilterById(store, panelId),
  );

  const isFormDirty = useSelector(getSelectedPublicIsFormDirty);

  const warningsApi = getWarningsApi();

  const openPublicWarningDialog = async (
    formAction?: FormAction,
    warning?: Warning,
    warningType?: WarningType,
  ): Promise<void> => {
    if (isFormDirty) {
      const mayProceed = await confirmDialog(warningFormConfirmationOptions(t))
        .then(() => true)
        .catch(() => false);

      if (!mayProceed) {
        return;
      }
    }

    dispatch(
      publicWarningFormActions.openPublicWarningFormDialog({
        ...(warning && {
          warning,
        }),
        formState: formAction,
        warningType,
        panelId,
      }),
    );
  };

  const selectWarning = (publicWarning?: Warning): void => {
    void openPublicWarningDialog('readonly', publicWarning);
  };
  const createWarning = (warningType?: WarningType): void => {
    void openPublicWarningDialog('', undefined, warningType);
  };

  const editWarning = async (publicWarning: Warning): Promise<void> => {
    if (publicWarning.id && auth?.username && !publicWarning.editor) {
      refetchWarnings();
      updatePublicWarningEditor.mutate({
        id: publicWarning.id,
        isEditor: true,
      });

      await openPublicWarningDialog('', {
        ...publicWarning,
        editor: auth?.username,
      });
    } else {
      void openPublicWarningDialog('', publicWarning);
    }
  };

  const expireWarning = async (warning: Warning): Promise<void> => {
    const expiredWarning: Warning = {
      ...warning,
      status: PublicWarningStatus.EXPIRED,
    };

    setConfirmDialogOptions({
      confirmLabel: t('warning-dialog-expire-confirm'),
      title: t('warning-dialog-expire-title'),
      description: t('warning-dialog-expire-description'),
      request: () =>
        warningsApi.updateWarning(expiredWarning.id!, expiredWarning),
      callback: () =>
        onRemoveSuccess(expiredWarning.id!, t('warning-dialog-expire-succes')),
    });
  };

  const withdrawWarning = async (warning: Warning): Promise<void> => {
    const withdrawWarning: Warning = {
      ...warning,
      status: PublicWarningStatus.WITHDRAWN,
    };

    setConfirmDialogOptions({
      confirmLabel: t('warning-dialog-withdraw-confirm'),
      title: t('warning-dialog-withdraw-title'),
      description: t('warning-dialog-withdraw-description'),
      request: () =>
        warningsApi.updateWarning(withdrawWarning.id!, withdrawWarning),
      callback: () =>
        onRemoveSuccess(
          withdrawWarning.id!,
          t('warning-dialog-withdraw-succes'),
        ),
    });
  };

  const warningType = useSelector(getWarningType);

  const { data: aviationConfig } = useProductConfig(warningType, false);

  const handleAviationProductClick = (
    warning?: SigmetWarning | AirmetWarning,
    actionType?: ProductAction,
  ): void => {
    if (!warning) {
      return;
    }

    const type = warning.warningDetail.level === 'SIG' ? 'sigmet' : 'airmet';

    let updatedWarning: SigmetWarning | AirmetWarning | undefined;

    switch (actionType) {
      case 'RENEW':
        updatedWarning = renewAviationProduct(
          warning,
          aviationConfig as ProductConfig,
        );
        break;
      case 'DUPLICATE':
        updatedWarning = duplicateAviationProduct(warning);
        break;
      default:
        break;
    }

    void openPublicWarningDialog('edit', updatedWarning, type);
  };

  const openSnackbar = (message: string): void => {
    dispatch(
      snackbarActions.openSnackbar({
        type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
        message,
      }),
    );
  };

  const deleteWarning = (warningId: string, warningEditor?: string): void => {
    const isLocked =
      (warningEditor && warningEditor !== auth?.username) || false;

    setConfirmDialogOptions({
      confirmLabel: t('warning-dialog-delete-confirm'),
      title: t('warning-dialog-delete-title'),
      description: t('warning-dialog-delete-description'),
      request: () => warningsApi.deleteWarning(warningId),
      callback: () =>
        onRemoveSuccess(warningId, t('warning-dialog-delete-succes')),
      isLocked,
    });
  };

  const deleteReviewWarning = (warning: Warning): void => {
    const ignoredWarning: Warning = {
      ...warning,
      status: PublicWarningStatus.IGNORED,
    };

    setConfirmDialogOptions({
      confirmLabel: t('warning-dialog-delete-confirm'),
      title: t('warning-dialog-delete-title'),
      description: t('warning-dialog-delete-description'),
      request: () =>
        warningsApi.updateWarning(ignoredWarning.id!, ignoredWarning),
      callback: () =>
        onRemoveSuccess(ignoredWarning.id!, t('warning-dialog-delete-succes')),
    });
  };

  const onRemoveSuccess = (
    warningId: string,
    snackbarMessage: string,
  ): void => {
    // close confirm dialog
    setConfirmDialogOptions(undefined);
    // Close warning dialog if needed
    if (selectedWarningId === warningId) {
      dispatch(
        uiActions.setToggleOpenDialog({
          setOpen: false,
          type: publicWarningDialogType,
        }),
      );
    }

    openSnackbar(snackbarMessage);
    refetchWarnings();
  };

  const onUpdateFilters = React.useCallback(
    (filterState: PickedFilterState, origin: SystemScope): void => {
      dispatch(
        publicWarningActions.setWarningFilters({
          filterState,
          panelId,
          origin,
        }),
      );
    },
    [dispatch, panelId],
  );

  const filterHookState = useWarningFilters(
    combinedWarnings,
    onUpdateFilters,
    savedFilterState,
    presetFilterState,
  );

  const filteredWarnings = React.useMemo(
    () => combinedWarnings.filter(filterHookState.byFilterState),
    [combinedWarnings, filterHookState.byFilterState],
  );

  const [expandedSections, setExpandedSections] = React.useState<
    Record<string, boolean>
  >({
    TODO: true,
    DRAFT: true,
    PUBLISHED: true,
    EXPIRED: false,
  });

  const filteredWarningsByExpandedSections = React.useMemo(
    () =>
      filteredWarnings.filter(
        (warning) =>
          (warning.status && expandedSections[warning.status]) ||
          (warning.productStatus && expandedSections[warning.productStatus]),
      ),
    [filteredWarnings, expandedSections],
  );

  const allFeatures = React.useMemo(
    () =>
      filteredWarningsByExpandedSections
        .flatMap(
          (warning) =>
            (warning?.id &&
              (warning?.warningDetail && warning.warningDetail.areas
                ? warning.warningDetail.areas
                    .filter((area) => area.geoJSON)
                    .map((area) => {
                      const isAviationWarning =
                        warning.warningDetail.level === 'SIG' ||
                        warning.warningDetail.level === 'AIR';

                      const styledGeoJSON = warningLevelFeatureColors(
                        warning.warningDetail.level as WarningLevel,
                        area.geoJSON,
                        isAviationWarning,
                      );

                      return {
                        id: warning.id + area.areaId!,
                        originalId: warning.id,
                        geoJSON: styledGeoJSON,
                      };
                    })
                : [])) ||
            [],
        )
        .filter((feature) => feature.id),
    [filteredWarningsByExpandedSections],
  );

  const { hoverId: hoveredFeatureId } = useUpdateSharedData(
    { features: allFeatures },
    panelId,
  );

  return (
    <>
      <PublicWarningList
        warnings={filteredWarnings}
        expandedSections={expandedSections}
        setExpandedSections={setExpandedSections}
        isLoading={isFetching}
        error={error || undefined}
        onSelectWarning={selectWarning}
        onCreateWarning={createWarning}
        onEditWarning={editWarning}
        onDeleteWarning={deleteWarning}
        onDeleteReviewWarning={deleteReviewWarning}
        onExpireWarning={expireWarning}
        onWithdrawWarning={withdrawWarning}
        selectedWarningId={selectedWarningId}
        lastUpdatedTime={lastUpdateTime}
        onRefetchWarnings={refetchWarnings}
        filterHookState={filterHookState}
        hoveredFeatureId={hoveredFeatureId}
        onActionAviationWarning={handleAviationProductClick}
      />
      {confirmDialogOptions && (
        <ConfirmDeleteWarningDialog
          onClose={(): void => setConfirmDialogOptions(undefined)}
          title={confirmDialogOptions.title}
          description={confirmDialogOptions.description}
          request={confirmDialogOptions.request}
          confirmLabel={confirmDialogOptions.confirmLabel}
          callback={confirmDialogOptions.callback}
          isLocked={confirmDialogOptions.isLocked}
        />
      )}
    </>
  );
};

export default PublicWarningListConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  LayerType,
  Style,
  WMLayer,
  webmapTestSettings,
} from '@opengeoweb/webmap';
import { serviceTypes } from './service';

export const {
  WmLayerWithoutTimeDimension,
  WmMultiDimensionLayer,
  layerWithoutTimeDimension,
} = webmapTestSettings;

export const styleListForRADNLOPERR25PCPRRL3KNMILayer: Style[] = [
  {
    title: 'knmiradar/nearest',
    name: 'knmiradar/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=knmiradar/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip/nearest',
    name: 'precip/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-transparent/nearest',
    name: 'precip-transparent/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-transparent/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-gray/nearest',
    name: 'precip-gray/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-gray/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-rainbow/nearest',
    name: 'precip-rainbow/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-rainbow/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-blue/nearest',
    name: 'precip-blue/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-blue/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-blue-transparent/nearest',
    name: 'precip-blue-transparent/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-blue-transparent/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'pseudoradar/nearest',
    name: 'pseudoradar/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=pseudoradar/nearest',
    abstract: 'No abstract available',
  },
];

export const defaultReduxServices: serviceTypes.Services = {
  serviceid_1: {
    name: 'testservice',
    serviceUrl: 'https://testservice',
    layers: [
      {
        name: 'RAD_NL25_PCP_CM',
        title: 'RADAR NL COLOR',
        leaf: true,
        path: ['BasisLayer'],
        keywords: ['keyword'],
        abstract: 'RADAR NL COLOR abstract',
        dimensions: [
          {
            name: 'reference_time',
            values: '2022-10-05,2022-10-06',
            units: 'ISO8601',
            currentValue: '2022-10-05',
          },
          {
            name: 'TIME',
            values: '2022-10-04T00:00:00Z/PT1H',
            units: 'ISO8601',
            currentValue: '2022-10-04T00:00:00Z',
          },
          {
            name: 'modellevel',
            values: '1,2,3,4,5',
            units: '-',
            currentValue: '1',
          },
          {
            name: 'Elevation',
            values: '1000,500,100',
            units: 'hPa',
            currentValue: '1000',
          },
          {
            name: 'member',
            values: '1,2,3',
            units: '-',
            currentValue: '1',
          },
        ],
      },
      {
        name: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
        title: 'RADAR NL KNMI',
        leaf: true,
        path: ['testLayer'],
        keywords: ['testword'],
        abstract: 'RADAR NL KNMI abstract',
        styles: styleListForRADNLOPERR25PCPRRL3KNMILayer,
      },
      {
        name: 'MULTI_DIMENSION_LAYER',
        title: 'Multi Dimension layerTypes.Layer',
        leaf: true,
        path: [],
        keywords: [],
      },
    ],
  },
};

export const WmMultiDimensionLayer2 = new WMLayer({
  service: 'https://testservicedimensions',
  id: 'multiDimensionLayerMock2',
  name: 'netcdf_5dims',
  layerType: LayerType.mapLayer,
  dimensions: [
    {
      name: 'member',
      units: 'member number',
      currentValue: 'member1',
      values: 'member1,member2,member3,member4',
    },
    {
      name: 'elevation',
      units: 'meters',
      currentValue: '9000',
      values: '1000,5000,9000',
    },
    {
      name: 'time',
      units: 'ISO8601',
      currentValue: '2017-01-01T00:25:00Z',
    },
  ],
});

export const WmMultiDimensionServices: serviceTypes.Services = {
  serviceid_1: {
    name: 'testservicedimensions',
    serviceUrl: 'https://testservicedimensions',
    layers: [
      {
        name: 'netcdf_5dims',
        title: 'netcdf_5dims',
        leaf: true,
        path: [],
      },
    ],
  },
  serviceid_2: {
    name: 'testservice',
    serviceUrl: 'https://testservice',
    layers: [
      {
        name: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
        title: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
        leaf: true,
        path: [],
      },
      {
        name: 'LAYER_WITHOUT_TIME',
        title: 'LAYER_WITHOUT_TIME',
        leaf: true,
        path: [],
      },
    ],
  },
};

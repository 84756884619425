/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { debugLogger, DebugType, isDefined } from './WMJSTools';
import WMDrawMarker from './WMDrawMarker';
import WMBBOX, { Bbox } from './WMBBOX';
import type IWMJSMap from './IWMJSMap';
import {
  getGeoCoordFromPixelCoord,
  getPixelCoordFromGeoCoord,
  getPixelCoordFromLatLong,
} from './WMJSMapHelpers';

class MapPin {
  private _map: IWMJSMap = null!;

  private x: number = null!;

  private y: number = null!;

  private exactX: number = null!;

  private exactY: number = null!;

  private geoPosX: number = null!;

  private geoPosY: number = null!;

  public displayMapPin = true;

  public disableMapPin = false;

  constructor(_map: IWMJSMap) {
    this._map = _map;
  }

  public redrawMap = (): void => {
    this._map.draw();
  };

  public drawMarker(ctx: CanvasRenderingContext2D): void {
    if (isDefined(this.x) && isDefined(this.y)) {
      WMDrawMarker(
        ctx,
        this.x,
        this.y,
        this.disableMapPin ? '#808080' : '#051039',
      );
    }
  }

  public repositionMapPin = (_bbox: WMBBOX): void => {
    if (!_bbox) {
      return;
    }
    const newpos = getPixelCoordFromGeoCoord(
      this._map,
      { x: this.geoPosX, y: this.geoPosY },
      _bbox,
    );
    this.setMapPin(newpos.x, newpos.y, _bbox);
  };

  public setMapPin = (_x: number, _y: number, _bbox?: Bbox): void => {
    const x = _x as unknown as string;
    const y = _y as unknown as string;

    if (!x || !y) {
      return;
    }
    if (this.disableMapPin && !_bbox) {
      return; // we still want to update the pin position when the bbox changes.
    }
    this.x = parseInt(x, 10);
    this.y = parseInt(y, 10);
    this.exactX = parseFloat(x);
    this.exactY = parseFloat(y);

    const { width: mapWidth, height: mapHeight } = this._map.getSize();
    const geopos = getGeoCoordFromPixelCoord(
      { x: this.exactX, y: this.exactY },
      _bbox || this._map.getDrawBBOX(),
      mapWidth,
      mapHeight,
    );

    this.geoPosX = geopos.x;
    this.geoPosY = geopos.y;
    this.redrawMap();
  };

  public positionMapPinByLatLon = (coord: { x: number; y: number }): void => {
    debugLogger(
      DebugType.Log,
      `positionMapPinByLatLon at ${coord.x},${coord.y}`,
    );
    const newpos = getPixelCoordFromLatLong(this._map, coord);
    this.setMapPin(newpos.x, newpos.y);
  };

  public setMapPinDisabled = (): void => {
    this.disableMapPin = true;
    this.redrawMap();
  };

  public setMapPinEnabled = (): void => {
    this.disableMapPin = false;
    this.redrawMap();
  };

  public showMapPin = (): void => {
    this.displayMapPin = true;
    this.redrawMap();
  };

  public hideMapPin = (): void => {
    this.displayMapPin = false;
    this.redrawMap();
  };

  public getXY = (): { x: number; y: number } => {
    return { x: this.x, y: this.y };
  };
}

export default MapPin;

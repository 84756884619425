/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormDateTime,
  isXHoursAfter,
  isXHoursBefore,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { dateUtils } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import { WARN_NAMESPACE, useWarningsTranslation } from '../../../utils/i18n';

export const VALID_FROM_MAX_HOURS_AFTER_CURRENT = 168;

export const isValueBeforeCurrentTime = (
  value: string,
  t: TFunction,
): boolean | string => {
  return (
    isXHoursBefore(value, dateUtils.getCurrentTimeAsString(), 0) ||
    t('warning-valid-from-error-before-current')
  );
};

export const isMaxHoursAfterCurrentTime = (
  value: string,
  t: TFunction,
): boolean | string =>
  isXHoursAfter(
    value,
    dateUtils.getCurrentTimeAsString(),
    VALID_FROM_MAX_HOURS_AFTER_CURRENT,
  ) ||
  t('warning-valid-from-error-max-hours', {
    ns: WARN_NAMESPACE,
    MAX_HOURS: VALID_FROM_MAX_HOURS_AFTER_CURRENT,
  });

export const ValidFrom: React.FC<{
  isDisabled?: boolean;
  isReadOnly?: boolean;
}> = ({ isDisabled, isReadOnly }) => {
  const { t } = useWarningsTranslation();
  const { isRequired } = useDraftFormHelpers();
  const { trigger } = useFormContext();
  const onChangeValidFrom = (): void => {
    void trigger(['validFrom', 'validUntil']);
  };

  return (
    <ReactHookFormDateTime
      name="validFrom"
      rules={{
        validate: {
          isRequired,
          isValidDate: dateUtils.isValidIsoDateString,
          // The valid from time cannot be before the current time
          isValueBeforeCurrentTime: (value: string): boolean | string =>
            isValueBeforeCurrentTime(value, t),
          // Valid from can be no more than X hours after current time
          isMaxHoursAfterCurrentTime: (value: string): boolean | string =>
            isMaxHoursAfterCurrentTime(value, t),
        },
      }}
      label={t('warning-valid-from')}
      disablePast
      disabled={isDisabled || isReadOnly}
      isReadOnly={isReadOnly}
      onChange={onChangeValidFrom}
    />
  );
};

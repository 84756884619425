/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Button, SxProps } from '@mui/material';
import { Clear, Search } from '@opengeoweb/theme';
import { useSharedTranslation } from '../../utils/i18n';
import { CustomIconButton } from '../CustomIconButton';

export const isURL = (string: string): boolean => /^https?:\/\//.test(string);

const ClearButton: React.FC<{
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}> = ({ onClick }) => {
  const { t } = useSharedTranslation();
  return (
    <CustomIconButton
      tooltipTitle={t('shared-clear')}
      sx={buttonStyle}
      onClick={onClick}
    >
      <Clear />
    </CustomIconButton>
  );
};

const buttonStyle: SxProps = {
  '&.MuiButtonBase-root.MuiIconButton-root': {
    width: 48,
    height: 48,
    marginRight: '-12px',
  },
};

interface FieldButtonProps {
  localSearchString: string;
  onClickClear: () => void;
  setPopupIsOpen: (value: boolean) => void;
}

export const SearchFieldButton: React.FC<FieldButtonProps> = ({
  localSearchString,
  onClickClear,
  setPopupIsOpen,
}: FieldButtonProps) => {
  const { t } = useSharedTranslation();
  if (isURL(localSearchString)) {
    return (
      <>
        <div style={{ marginRight: '15px' }}>
          <ClearButton onClick={(): void => onClickClear()} />
        </div>
        <Button variant="tertiary" onClick={(): void => setPopupIsOpen(true)}>
          {t('shared-save')}
        </Button>
      </>
    );
  }
  if (localSearchString) {
    return <ClearButton onClick={(): void => onClickClear()} />;
  }

  return (
    <CustomIconButton tooltipTitle={t('shared-search')} sx={buttonStyle}>
      <Search />
    </CustomIconButton>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/**
 * Makes hashed georeferenced url without width, height and bbox
 * @param url
 * @returns
 */
export const makeHashUrlForGeoReferencedImage = (
  url: string,
): string | undefined => {
  try {
    const urlAsURL = new URL(url);
    const urlParams: URLSearchParams = new URLSearchParams(urlAsURL.search);
    urlParams.delete('BBOX');
    urlParams.delete('WIDTH');
    urlParams.delete('HEIGHT');
    return `${urlAsURL.origin}${urlAsURL.pathname}?${urlParams.toString()}`;
  } catch (e) {
    return undefined;
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Grid2 as Grid,
  List,
  Card,
  CardContent,
  Skeleton,
  useTheme,
} from '@mui/material';
import { AlertBanner } from '@opengeoweb/shared';

import NotificationRow from './NotificationRow';
import NotificationTabs from './NotificationTabs';
import { SWEvent } from '../../types';

const maxHeight = '33vh';

interface NotificationListProps {
  tabValue: string;
  newNotifications: SWEvent[];
  eventList: SWEvent[] | null;
  isLoading: boolean;
  error: Error | null;
  onChangeTabValue: (value: string) => void;
  handleNotificatioNRowClick: (event: SWEvent) => void;
}

const NotificationList: React.FC<NotificationListProps> = ({
  tabValue,
  newNotifications,
  eventList,
  isLoading,
  error,
  onChangeTabValue,
  handleNotificatioNRowClick,
}: NotificationListProps) => {
  const theme = useTheme();

  const handleChange = (newValue: string): void => {
    onChangeTabValue(newValue);
  };

  return (
    <Grid container spacing={0}>
      <Grid size={12}>
        <NotificationTabs
          activeTab={tabValue}
          newNotifications={newNotifications}
          onChange={handleChange}
        />
      </Grid>
      <Grid size={12}>
        <Card
          elevation={0}
          variant="outlined"
          sx={{
            boxShadow: 'inset 0 0 2px 0 rgba(0, 0, 0, 0.5)',
            backgroundColor: theme.palette.geowebColors.background.surfaceApp,
            paddingBottom: '2px',
          }}
        >
          <CardContent
            sx={{
              height: maxHeight,
              minHeight: '306px',
              padding: '4px 8px',
              overflowY: 'scroll',
            }}
          >
            {eventList && (
              <List
                sx={{
                  padding: 0,
                }}
              >
                {eventList.map((event) => {
                  return (
                    <NotificationRow
                      event={event}
                      onNotificationRowClick={(_event): void => {
                        handleNotificatioNRowClick(_event);
                      }}
                      key={event.eventid}
                    />
                  );
                })}
              </List>
            )}
            {isLoading && (
              <Skeleton
                data-testid="notificationList-skeleton"
                variant="rectangular"
                height="100%"
                width="100%"
              />
            )}
            {error && (
              <AlertBanner
                severity="error"
                dataTestId="notificationList-alert"
                title={error.message ? error.message : ''}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NotificationList;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { produce } from 'immer';
import {
  LayerType,
  TimeInterval,
  parseISO8601IntervalToDateInterval,
} from '@opengeoweb/webmap';
import { isEmpty } from 'lodash';
import { dateUtils } from '@opengeoweb/shared';
import type { CoreAppStore } from '../../types';
import { selectorMemoizationOptions } from '../../utils';
import type { Dimension, ReduxLayer } from '../types';
import { LayerState, Layer, LayerStatus } from './types';
import { filterNonTimeDimensions } from './utils';
import {
  getTimeStepFromDataInterval,
  parseTimeDimToISO8601Interval,
} from '../map/utils';

const layerStore = (store: CoreAppStore): LayerState | undefined =>
  store?.layers;

/**
 * Gets a layer from the layers part of the store by its Id
 *
 * Example: layer = getLayerById(store, 'layerId')
 * @param {object} store object from which the layer state will be extracted
 * @param {string} layerId Id of the layer
 * @returns {object} object containing layer information (service, name, style, enabled etc.)
 */
export const getLayerById = createSelector(
  [layerStore, (layerStore, layerId): string => layerId],
  (layerState, layerId): ReduxLayer | undefined => {
    return layerState && layerState.byId && layerState.byId[layerId];
  },
  selectorMemoizationOptions,
);

/**
 * Determines if layer is present
 *
 * @param {object} store store: object - store object
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {boolean} returnType:boolean - true if layer is present
 */
export const getIsLayerPresent = createSelector(
  getLayerById,
  (layer): boolean => !!layer,
  selectorMemoizationOptions,
);

/**
 * Gets a layer from the layers part of the store by its Id, but without the time dimension. (All other dimensions are still included)
 *
 * Example: layer = getLayerByIdWithoutTimeDimension(store, 'layerId')
 * @param {object} store object from which the layer state will be extracted
 * @param {string} layerId Id of the layer
 * @returns {object} object containing layer information (service, name, style, enabled etc.)
 */
export const getLayerByIdWithoutTimeDimension = createSelector(
  [layerStore, (layerStore, layerId): string => layerId],
  (layerState, layerId): ReduxLayer | undefined => {
    const layer = layerState && layerState.byId && layerState.byId[layerId];
    return layer
      ? produce(layer, (draftLayer: ReduxLayer): ReduxLayer => {
          draftLayer.dimensions = filterNonTimeDimensions(
            draftLayer.dimensions || [],
          );
          return draftLayer;
        })
      : undefined;
  },
  selectorMemoizationOptions,
);

/**
 * Retrieves all layers indexed by layerId
 *
 * Example: layers = getLayersById(store)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @returns {object} returnType: object - an object of all layers containing layer information (service, name, style, enabled etc.) indexed by layerId
 */
export const getLayersById = createSelector(
  layerStore,
  (store) => store?.byId ?? null,
  selectorMemoizationOptions,
);

/**
 * Retrieves all layerIds
 *
 * Example: layerIds = getLayersIds(store)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @returns {array} returnType: array - an array of all layerIds
 */
export const getLayersIds = createSelector(
  layerStore,
  (store) => (store ? store.allIds : []),
  selectorMemoizationOptions,
);

/**
 * Retrieves all layers (including base layers and overlayers)
 *
 * Example: layers = getAllLayers(store)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @returns {array} returnType: array - an array of all layers containing layer information (service, name, style, enabled etc.)
 */
export const getAllLayers = createSelector(
  getLayersIds,
  getLayersById,
  (ids, layers) => ids.map((layerId) => layers![layerId]),
  selectorMemoizationOptions,
);

/**
 * Retrieves all layers (including base layers and overlayers) for the given mapId
 *
 * Example: layers = getLayersByMapId(store, mapId)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} mapId mapId: string - mapId
 * @returns {array} returnType: ReduxLayer[] - an array of all layers containing layer information (service, name, style, enabled etc.)
 */
export const getLayersByMapId = createSelector(
  getAllLayers,
  (_store: CoreAppStore, _mapId: string): string => _mapId,
  (layers: ReduxLayer[], mapId: string): ReduxLayer[] => {
    return layers.filter((layer) => layer.mapId === mapId);
  },
  selectorMemoizationOptions,
);

/**
 * Retrieves all layers that aren't base layers and aren't overlayers
 *
 * Example: layers = getLayers(store)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @returns {array} returnType: array - an array of all non-baselayers containing layer information (service, name, style, enabled etc.)
 */
export const getLayers = createSelector(
  getAllLayers,
  (layers) =>
    layers.filter(
      (layer) =>
        layer.layerType !== LayerType.baseLayer &&
        layer.layerType !== LayerType.overLayer,
    ),
  selectorMemoizationOptions,
);

/**
 * Retrieves all baselayers
 *
 * Example: layers = getBaseLayers(store)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @returns {array} returnType: array - an array of all baselayers containing layer information (service, name, style, enabled etc.)
 */
export const getBaseLayers = createSelector(
  getAllLayers,
  (layers) => layers.filter((layer) => layer.layerType === LayerType.baseLayer),
  selectorMemoizationOptions,
);

/**
 * Retrieves all overLayers
 *
 * Example: layers = getOverLayers(store)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @returns {array} returnType: array - an array of all overLayers containing layer information (service, name, style, enabled etc.)
 */
export const getOverLayers = createSelector(
  getAllLayers,
  (layers) => layers.filter((layer) => layer.layerType === LayerType.overLayer),
  selectorMemoizationOptions,
);

/**
 * Retrieves all featureLayers
 *
 * Example: layers = getFeatureLayers(store)
 * @param {object} store store: object - object from which the layers state will be extracted
 * @returns {array} returnType: array - an array of all featureLayers
 */
export const getFeatureLayers = createSelector(
  getAllLayers,
  (layers) =>
    layers.filter((layer) => layer.layerType === LayerType.featureLayer),
  selectorMemoizationOptions,
);

/**
 * Gets dimensions of the passed layer
 *
 * Example: layerDimensions = getLayerDimensions(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {array} returnType: Dimension[] - array containing layer dimensions
 */
export const getLayerDimensions = createSelector(
  getLayerById,
  (layer) => layer?.dimensions ?? [],
  selectorMemoizationOptions,
);

/**
 * Gets dimensions of the passed layer, excluding the time dimension
 *
 * Example: layerNonTimeDimensions = getLayerNonTimeDimensions(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {array} returnType: Dimension[] - array containing layer dimensions, excluding the time dimension
 */
export const getLayerNonTimeDimensions = createSelector(
  getLayerDimensions,
  (dimensions) => filterNonTimeDimensions(dimensions),
  selectorMemoizationOptions,
);

/**
 * Gets time dimension of the passed layer
 *
 * Example: timeDimension = getLayerTimeDimension(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {object} returnType: object - object of layer time dimension
 */
export const getLayerTimeDimension = createSelector(
  getLayerDimensions,
  (dimensions) => dimensions?.find((dimension) => dimension.name === 'time'),
  selectorMemoizationOptions,
);

/**
 * Returns a boolean indicating whether the layer has a time dimension
 *
 * Example: layerHasTimeDimension = getLayerHasTimeDimension(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {boolean} returnType: boolean - boolean indicating whether the layer has a time dimension
 */
export const getLayerHasTimeDimension = createSelector(
  getLayerTimeDimension,
  (timeDimension) => !!timeDimension,
  selectorMemoizationOptions,
);

export const getLayerCurrentTime = createSelector(
  getLayerTimeDimension,
  (dimension) => dimension?.currentValue,
  selectorMemoizationOptions,
);

/**
 * Gets specified dimension of the passed layer
 *
 * Example: dimension = getLayerDimension(store, 'layerId_1', 'elevation')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @param {string} dimensionName dimensionName: string - name of dimension you want to retrieve the dimension data for
 * @returns {object} returnType: object - object of layer dimension
 */
export const getLayerDimension = createSelector(
  getLayerDimensions,
  (_store: CoreAppStore, _layerId: string, dimensionName: string): string =>
    dimensionName,
  (dimensions, dimensionName): Dimension =>
    dimensions?.find((dimension) => dimension.name === dimensionName)!,
  selectorMemoizationOptions,
);

/**
 * Gets opacity of the passed layer
 *
 * Example: layerOpacity = getLayerOpacity(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {number} returnType: number - opacity as a number (between 0 and 1)
 */
export const getLayerOpacity = createSelector(
  getLayerById,
  (layer) => (layer && layer.opacity ? layer.opacity : 0),
  selectorMemoizationOptions,
);

/**
 * Gets whether a layer is enabled or disabled
 *
 * Example: isLayerEnabled = getLayerEnabled(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {boolean} returnType: boolean -  true if enabled, false if disabled
 */
export const getLayerEnabled = createSelector(
  getLayerById,
  (layer) => Boolean(layer?.enabled),
  selectorMemoizationOptions,
);

/**
 * Gets layer name
 *
 * Example: layerName = getLayerName(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {string} returnType: string -  layer name
 */
export const getLayerName = createSelector(
  getLayerById,
  (layer) => layer?.name ?? '',
  selectorMemoizationOptions,
);

/**
 * Gets layer service
 *
 * Example: layerService = getLayerService(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {string} returnType: string -  layer service
 */
export const getLayerService = createSelector(
  getLayerById,
  (layer) => layer?.service ?? '',
  selectorMemoizationOptions,
);

/**
 * Gets selected style of the passed layer
 *
 * Example: layerStyle = getLayerStyle(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {string} returnType: string - style that is currently selected
 */
export const getLayerStyle = createSelector(
  getLayerById,
  (layer) => (layer && layer.style ? layer.style : ''),
  selectorMemoizationOptions,
);

/**
 * Gets layer status
 *
 * Example: layerService = getLayerStatus(store, 'layerId_1')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} layerId layerId: string - Id of the layer
 * @returns {string} returnType: LayerStatus
 */
export const getLayerStatus = createSelector(
  getLayerById,
  (layer) => (layer && layer.status ? layer.status : LayerStatus.default),
  selectorMemoizationOptions,
);

/**
 * Gets all available base layers for a map
 *
 * Example: availableBaseLayers = getAvailableBaseLayersForMap(store)
 * @param {object} store store: object - object from which the layer state will be extracted
 * @param {string} mapId mapId: string - Id of the map we want to retrieve the available baselayers for
 * @returns {array} returnType: array - array containing all available base layers
 */
export const getAvailableBaseLayersForMap = createSelector(
  layerStore,
  (_store: CoreAppStore, _mapId: string): string => _mapId,
  (layerStore, mapId: string): Layer[] => {
    if (layerStore && layerStore.availableBaseLayers) {
      const { availableBaseLayers } = layerStore;
      return availableBaseLayers.allIds.reduce(
        (list: ReduxLayer[], layerId) => {
          if (availableBaseLayers.byId[layerId].mapId === mapId) {
            return list.concat(availableBaseLayers.byId[layerId]);
          }
          return list;
        },
        [],
      );
    }
    return [];
  },
  selectorMemoizationOptions,
);

/**
 * Returns the selected geojson feature for the given layer
 *
 * Example const selectedFeature = getSelectedFeature(store, 'layerId1')
 * @param {object} store store: object - store object
 * @param {string} mapId layerId: string - Id of the layer
 * @returns {number} selectedFeatureIndex: the index of the selected geojson feature
 */
export const getSelectedFeatureIndex = createSelector(
  getLayerById,
  (layer) => layer?.selectedFeatureIndex,
  selectorMemoizationOptions,
);

/**
 * Returns the layer is in edit mode
 *
 * Example const isLayerInEditMode = getIsLayerInEditMode(store, 'layerId1')
 * @param {object} store store: object - store object
 * @param {string} mapId layerId: string - Id of the layer
 * @returns {number} isLayerInEditMode: boolean - isInEditMode
 */
export const getIsLayerInEditMode = createSelector(
  getLayerById,
  (layer) => layer?.isInEditMode || false,
  selectorMemoizationOptions,
);

export const getFeatureLayerGeoJSON = createSelector(
  getLayerById,
  (layer) => layer?.geojson || undefined,
  selectorMemoizationOptions,
);

export const getHasFeatureLayerGeoJSON = createSelector(
  getFeatureLayerGeoJSON,
  (geojson) => !!geojson,
  selectorMemoizationOptions,
);

export const getFeatureLayerGeoJSONProperties = createSelector(
  getLayerById,
  getSelectedFeatureIndex,
  (layer, selecteFeatureIndex = 0) =>
    layer?.geojson?.features[selecteFeatureIndex]?.properties || {},
  selectorMemoizationOptions,
);

/**
 * Gets layerIds that contain passed dimension
 *
 * Example: dimension = getDimensionLayerIds(store, 'elevation')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string} dimensionName dimensionName: string - name of dimension you want to retrieve the dimension data for
 * @returns {string[]} returnType: string[] - layerIds
 */
export const getDimensionLayerIds = createSelector(
  getLayersIds,
  getLayersById,
  (_store: CoreAppStore, dimensionName: string): string => dimensionName,
  (
    layerIds: string[],
    layers: Record<string, Layer> | null,
    dimensionName: string,
  ): string[] => {
    return layerIds.reduce<string[]>((list, layerId) => {
      const layer = layers![layerId];
      const dimensions = layer?.dimensions ? layer.dimensions : [];
      if (dimensions.find((dimension) => dimension.name === dimensionName)) {
        return list.concat(layerId);
      }
      return list;
    }, []);
  },
  selectorMemoizationOptions,
);

export const getAcceptanceTimeInMinutes = createSelector(
  getLayerById,
  (layer) => {
    return layer?.acceptanceTimeInMinutes;
  },
  selectorMemoizationOptions,
);

export const getLayerIsInsideAcceptanceTime = createSelector(
  getAcceptanceTimeInMinutes,
  getLayerCurrentTime,
  (
    state: CoreAppStore,
    _layerId: string | undefined,
    mapId: string,
  ): string | undefined =>
    state.webmap?.byId[mapId]?.dimensions?.find(
      (dimension) => dimension.name === 'time',
    )?.currentValue,

  (acceptanceTimeInMinutes, layerCurrentTime, mapCurrentTime) => {
    if (!mapCurrentTime || !layerCurrentTime) {
      return 'equal';
    }

    const mapTime = new Date(mapCurrentTime).getTime();
    const layerTime = new Date(layerCurrentTime).getTime();
    if (mapTime === layerTime) {
      return 'equal';
    }

    if (acceptanceTimeInMinutes !== undefined) {
      const millisecondsBetween = mapTime - layerTime;
      const acceptanceTimeInMilliseconds = acceptanceTimeInMinutes * 60000;
      if (Math.abs(millisecondsBetween) > acceptanceTimeInMilliseconds) {
        return 'outside';
      }
    }

    return 'inside';
  },
  selectorMemoizationOptions,
);

export const getUseLatestReferenceTime = createSelector(
  getLayerById,
  (layer) => Boolean(layer?.useLatestReferenceTime),
  selectorMemoizationOptions,
);

/**
 * Returns the timestep for this layerId in seconds, or undefined if it has no time dimension or timeinterval.
 */
export const getTimeStepForLayerId = createSelector(
  getLayerTimeDimension,
  (timeDimension): number | undefined => {
    if (!timeDimension) {
      return undefined;
    }

    const {
      values,
      currentValue,
      timeInterval = {} as TimeInterval,
    } = timeDimension;

    if (!values || !currentValue) {
      return isEmpty(timeInterval)
        ? undefined
        : getTimeStepFromDataInterval(timeInterval);
    }

    const iso8601Intervals = parseTimeDimToISO8601Interval(values);

    for (const { startTime, endTime, duration } of iso8601Intervals) {
      if (
        dateUtils.isWithinInterval(currentValue, {
          start: startTime,
          end: endTime,
        })
      ) {
        const parsedInterval = parseISO8601IntervalToDateInterval(duration);
        return getTimeStepFromDataInterval(parsedInterval);
      }
    }

    return getTimeStepFromDataInterval(timeInterval);
  },
  selectorMemoizationOptions,
);

export const getActiveLayerInfo = createSelector(
  layerStore,
  (store) => store?.activeLayerInfo,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { mapSelectors, syncGroupsSelectors } from '@opengeoweb/store';
import {
  TimeSeriesModuleState,
  timeSeriesSelectors,
} from '@opengeoweb/timeseries';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, LinearProgress } from '@mui/material';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import { AlertBanner, Filter } from '@opengeoweb/shared';

import { InitialProps } from '@opengeoweb/core';
import {
  publicWarningsSelectors,
  WarningModuleStore,
} from '@opengeoweb/warnings';
import { AppStore } from '../../store/types';

import {
  viewPresetActions,
  viewPresetSelectors,
} from '../../store/viewPresets';
import {
  PresetAction,
  ViewPresetDialog,
  ViewPresetErrorType,
  ViewPresetsListFilter,
} from '../../store/viewPresets/types';
import { viewPresetsListSelectors } from '../../store/viewPresetsList';
import * as workspaceSelectors from '../../store/workspace/selectors';
import {
  ViewPresetListItem,
  WorkspacePresetAction,
  WorkspacePresetListItem,
} from '../../store/workspace/types';
import {
  constructFilterParams,
  getEmptyWorkspaceListItem,
} from '../../store/workspaceList/utils';
import { useWorkspaceTranslation } from '../../utils/i18n';
import WorkspaceSelectList from '../WorkspaceSelectList/WorkspaceSelectList';

interface ViewPresetMenuListConnectProps {
  panelId: string;
}

export const ViewPresetMenuListConnect: React.FC<
  ViewPresetMenuListConnectProps
> = ({ panelId }: ViewPresetMenuListConnectProps) => {
  const { t } = useWorkspaceTranslation();
  const dispatch = useDispatch();
  const { currentRole, isLoggedIn } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;
  const scope = !isAdmin ? 'user' : 'system';

  const viewPresets: ViewPresetListItem[] = useSelector((store: AppStore) =>
    viewPresetsListSelectors.getFilteredViewPresetListForView(store, panelId),
  );
  const activeViewPresetId = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetActiveId(store, panelId),
  );

  const viewComponentType = useSelector((store: AppStore) =>
    workspaceSelectors.getViewComponentType(store, panelId),
  );
  const currentMapPreset = useSelector((store: AppStore) =>
    mapSelectors.getMapPreset(store, panelId),
  );
  const currentTimeSeriesPreset = useSelector((store: TimeSeriesModuleState) =>
    timeSeriesSelectors.getTimeSeriesPreset(store),
  );
  const currentWarningsPreset = useSelector((store: WarningModuleStore) =>
    publicWarningsSelectors.getWarningsPresetById(store, panelId),
  );

  const viewPresetListFilters = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetListFiltersForView(store, panelId, scope),
  );

  const viewPresetListSearchQuery = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetListSearchQueryForView(store, panelId),
  );

  const relatedSyncGroupIds = useSelector((store: AppStore) =>
    syncGroupsSelectors.getAllTargetGroupsForSource(store, {
      sourceId: panelId,
    }),
  );
  const error = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetsError(store, panelId),
  );
  const isViewPresetsListFetching = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetsIsFetching(store, panelId),
  );

  const openViewPresetsDialog = React.useCallback(
    (viewPresetDialogOptions: ViewPresetDialog) => {
      dispatch(
        viewPresetActions.openViewPresetDialog({
          viewPresetDialog: viewPresetDialogOptions,
        }),
      );
    },
    [dispatch],
  );

  const selectViewPreset = React.useCallback(
    (viewPresetId: string) => {
      dispatch(
        viewPresetActions.selectViewPreset({
          panelId,
          viewPresetId,
        }),
      );
    },
    [dispatch, panelId],
  );

  const fetchViewPresets = React.useCallback(() => {
    const filterParams = constructFilterParams(
      viewPresetListFilters,
      viewPresetListSearchQuery,
    );

    dispatch(viewPresetActions.fetchViewPresets({ panelId, filterParams }));
  }, [dispatch, panelId, viewPresetListFilters, viewPresetListSearchQuery]);

  const constructInitialProps = (): InitialProps | undefined => {
    switch (viewComponentType) {
      case 'WarningList':
        return currentWarningsPreset;
      case 'TimeSeries':
        return currentTimeSeriesPreset;
      case 'Map':
        return {
          mapPreset: currentMapPreset,
          syncGroupsIds: relatedSyncGroupIds,
        };
      default:
        return undefined;
    }
  };

  const onClickViewPresetOption = (
    presetId: string,
    workspaceAction: WorkspacePresetAction,
    preset: WorkspacePresetListItem,
  ): void => {
    switch (workspaceAction) {
      case WorkspacePresetAction.DUPLICATE: {
        openViewPresetsDialog({
          title: t('workspace-mappreset-save-as'),
          action: PresetAction.SAVE_AS,
          viewPresetId: presetId,
          panelId,
          formValues: {
            title: preset.title,
            initialProps: constructInitialProps(),
            abstract: preset.abstract,
          },
        });
        break;
      }
      case WorkspacePresetAction.EDIT: {
        const presetTitle = preset?.title || '';
        openViewPresetsDialog({
          title: t('workspace-mappreset-dialog-title-edit'),
          action: PresetAction.EDIT,
          panelId,
          viewPresetId: presetId!,
          formValues: {
            title: presetTitle,
            abstract: preset.abstract,
          },
        });
        break;
      }
      case WorkspacePresetAction.DELETE_SYSTEM_PRESET:
      case WorkspacePresetAction.DELETE: {
        openViewPresetsDialog({
          title: t('workspace-mappreset-dialog-title-delete'),
          action: PresetAction.DELETE,
          viewPresetId: presetId,
          panelId,
          formValues: { title: preset.title },
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  const onSearchFilter = React.useCallback(
    (searchQuery: string) => {
      dispatch(
        viewPresetActions.searchFilter({
          searchQuery,
          panelId,
        }),
      );
    },
    [dispatch, panelId],
  );

  const toggleSelectFilterChip = React.useCallback(
    (id: string, isSelected: boolean) => {
      dispatch(
        viewPresetActions.toggleSelectFilterChip({ id, isSelected, panelId }),
      );
    },
    [dispatch, panelId],
  );

  const setSelectAllFilterChip = React.useCallback(() => {
    dispatch(viewPresetActions.setSelectAllFilterChip({ panelId }));
  }, [dispatch, panelId]);

  const onClickFilterChip = (id: string, shouldSelect: boolean): void => {
    if (id === 'all') {
      if (shouldSelect) {
        setSelectAllFilterChip();
      }
    } else {
      toggleSelectFilterChip(id, shouldSelect);
    }
  };

  const isAllSelectedForFilters = viewPresetListFilters.every(
    (filter: ViewPresetsListFilter) => filter.isSelected,
  );

  const shouldHideNewWorkspace =
    !isAllSelectedForFilters || viewPresetListSearchQuery.length > 0;

  return (
    <div data-testid="viewpreset-selectlist-connect" style={{ height: '100%' }}>
      {isViewPresetsListFetching && (
        <LinearProgress
          data-testid="loading-bar"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 0 }}
        />
      )}
      {!isViewPresetsListFetching && error && (
        <AlertBanner
          title={t('workspace-viewpresets-error')}
          info={error.message}
          shouldClose
          {...(error.errorType === ViewPresetErrorType.GENERIC && {
            actionButtonProps: {
              title: t('workspace-retry'),
              onClick: fetchViewPresets,
            },
          })}
        />
      )}
      <Box sx={{ padding: 1, height: '100%' }}>
        {!error && (
          <Filter
            filters={viewPresetListFilters}
            onClickFilterChip={onClickFilterChip}
            isAllSelected={isAllSelectedForFilters}
            searchQuery={viewPresetListSearchQuery}
            onSearch={onSearchFilter}
          />
        )}

        <WorkspaceSelectList
          workspacePresets={viewPresets as WorkspacePresetListItem[]}
          currentSelectedId={activeViewPresetId || undefined!}
          onClickWorkspacePreset={selectViewPreset}
          onClickWorkspacePresetOption={onClickViewPresetOption}
          newPreset={{
            ...getEmptyWorkspaceListItem(t),
            title: t('workspace-mappreset-new'),
          }}
          hideNewWorkspace={shouldHideNewWorkspace}
          searchQuery={viewPresetListSearchQuery}
          testIdPrefix="viewpreset"
          isAdmin={isAdmin}
          isEditable={isLoggedIn}
        />
      </Box>
    </div>
  );
};

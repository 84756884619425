/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { throttle } from 'lodash';
import React from 'react';
import { DraggablePosition } from '../../components/DraggableResizable/types';

export const adjustPosition = (
  bbox: DOMRect,
  containingView: DOMRect,
  position: DraggablePosition,
  setPosition: (position: DraggablePosition) => void,
): void => {
  const isHorizontallyLarger = bbox.width > containingView.width;
  const isVerticallyLarger = bbox.height > containingView.height;

  // Check corner cases first
  // bottom left
  if (bbox.x < containingView.x && bbox.bottom >= containingView.bottom) {
    const diffX = containingView.x - bbox.x;
    const diffY = containingView.bottom - bbox.bottom;
    setPosition({ x: position.x + diffX, y: position.y + diffY });
  } else if (
    // bottom right
    bbox.right >= containingView.right &&
    bbox.bottom >= containingView.bottom
  ) {
    const diffX = containingView.right - bbox.right;
    const diffY = containingView.bottom - bbox.bottom;
    setPosition({ x: position.x + diffX, y: position.y + diffY });
  } else if (bbox.x < containingView.x || isHorizontallyLarger) {
    // left screen
    const diffX = containingView.x - bbox.x;
    setPosition({ ...position, x: position.x + diffX });
  } else if (bbox.right >= containingView.right) {
    // right screen
    const diffX = containingView.right - bbox.right;
    setPosition({ ...position, x: position.x + diffX });
  } else if (bbox.y < containingView.y || isVerticallyLarger) {
    // top screen
    const diffY = containingView.y - bbox.y;
    setPosition({ ...position, y: position.y + diffY });
  } else if (bbox.bottom >= containingView.bottom) {
    // bottom screen
    const diffY = containingView.bottom - bbox.bottom;
    setPosition({ ...position, y: position.y + diffY });
  }
};

export const useMakeSureContainerStaysInsideWindow = (
  nodeRef: React.RefObject<HTMLDivElement>,
  position: DraggablePosition,
  setPosition: (position: DraggablePosition) => void,
): void => {
  const onResizeWindow = React.useCallback((): void => {
    if (!nodeRef.current) {
      return;
    }
    const bbox = (
      nodeRef.current.firstChild as Element
    ).getBoundingClientRect();

    const containingView = (
      nodeRef.current.parentElement as Element
    ).getBoundingClientRect();

    adjustPosition(bbox, containingView, position, setPosition);
  }, [nodeRef, position, setPosition]);

  React.useEffect(() => {
    const throttledResize = throttle(onResizeWindow, 100);
    window.addEventListener('resize', throttledResize);
    return (): void => {
      window.removeEventListener('resize', throttledResize);
    };
  }, [onResizeWindow]);
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { MenuItem } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormSelect } from '@opengeoweb/form-fields';

import ProductFormFieldLayout from './ProductFormFieldLayout';
import { FormFieldProps, AirmetType, SigmetType } from '../../../types';
import { getFieldLabel, triggerValidations } from '../utils';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

const Type: React.FC<FormFieldProps> = ({
  productType,
  isReadOnly,
  isDisabled,
  onChange = (): void => {},
}: FormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { getValues, trigger } = useFormContext();
  const label = getFieldLabel(t('type-title'), isReadOnly!);
  const { onTypeChange } = useShortTestHelpers();
  const messageType = productType === 'airmet' ? AirmetType : SigmetType;

  return (
    <ProductFormFieldLayout title={t('type-title')}>
      <ReactHookFormSelect
        name="type"
        label={label}
        disabled={isDisabled}
        isReadOnly={isReadOnly}
        size="small"
        data-testid="type"
        defaultValue="NORMAL"
        onChange={(): Promise<boolean> | void => {
          onTypeChange();
          triggerValidations(
            ['validDateStart', 'validDateEnd'],
            getValues,
            trigger,
          );
          onChange();
        }}
        autoFocus
      >
        {Object.keys(messageType).map((key) => (
          <MenuItem value={key} key={key}>
            {messageType[key as keyof typeof messageType]}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
    </ProductFormFieldLayout>
  );
};

export default Type;

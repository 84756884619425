/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Grid2 as Grid, MenuItem } from '@mui/material';
import {
  ReactHookFormSelect,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import {
  CoastalEvent,
  Fog,
  FreezingPrecipitation,
  Haze,
  // FunnelCloud,
  Lightning,
  ModerateAircraftIcing,
  ModerateTurbulence,
  RadioactiveMaterials,
  Rain,
  SandStorm,
  SevereAircraftIcing,
  SevereTurbulence,
  Snow,
  TemperatureHigh,
  TemperatureLow,
  Thunderstorm,
  VulcanicEruption,
  Wind,
} from '@opengeoweb/theme';
import { useWarningsTranslation } from '../../../utils/i18n';
import { FilterTranslation } from '../../../store/publicWarnings/types';
import { emptyFilterId } from '../../FilterList/filter-utils';

export const getWarningPhenomenaIcon = (
  key: string,
): React.ReactElement | undefined => {
  switch (key) {
    case 'wind':
      return <Wind />;
    case 'fog':
      return <Fog />;
    case 'thunderstorm':
      return <Lightning />;
    case 'snowIce':
      return <Snow />;
    case 'rain':
      return <Rain />;
    case 'highTemp':
      return <TemperatureHigh />;
    case 'lowTemp':
      return <TemperatureLow />;
    case 'coastalEvent':
      return <CoastalEvent />;
    default:
      return undefined;
  }
};

export const getAviationPhenomenaIcon = (
  key: string,
): React.ReactElement | undefined => {
  switch (key) {
    case 'RDOACT_CLD':
      return <RadioactiveMaterials />;
    case 'HVY_SS':
      return <SandStorm />;
    case 'SEV_TURB':
      return <SevereTurbulence />;
    case 'MOD_TURB':
      return <ModerateTurbulence />;
    case 'SEV_ICE':
      return <SevereAircraftIcing />;
    case 'MOD_ICE':
      return <ModerateAircraftIcing />;
    case 'SEV_ICE_FRZ_RN':
      return <FreezingPrecipitation />;
    case 'HVY_DS':
      return <Haze />;
    case 'VA_CLD':
      return <VulcanicEruption />;
    case 'EMBD_TS':
    case 'EMBD_TSGR':
    case 'FRQ_TS':
    case 'FRQ_TSGR':
    case 'OBSC_TS':
    case 'OBSC_TSGR':
    case 'SQL_TSGR':
    case 'ISOL_TS':
    case 'ISOL_TSGR':
    case 'OCNL_TS':
    case 'OCNL_TSGR':
      return <Thunderstorm />;
    default:
      return undefined;
  }
};

export const warningPhenomena: FilterTranslation[] = [
  {
    translationKey: 'warning-unspecified',
    key: emptyFilterId,
    isAviation: false,
  },
  {
    translationKey: 'warning-phenomenon-wind',
    key: 'wind',
    isAviation: false,
  },
  {
    translationKey: 'warning-phenomenon-fog',
    key: 'fog',
    isAviation: false,
  },
  {
    translationKey: 'warning-phenomenon-thunderstorm',
    key: 'thunderstorm',
    isAviation: false,
  },
  {
    translationKey: 'warning-phenomenon-snow-ice',
    key: 'snowIce',
    isAviation: false,
  },
  {
    translationKey: 'warning-phenomenon-rain',
    key: 'rain',
    isAviation: false,
  },
  {
    translationKey: 'warning-phenomenon-high-temp',
    key: 'highTemp',
    isAviation: false,
  },
  // {
  //   title: 'warning-phenomenon-funnel-cloud',
  //   key: 'funnelCloud',
  // },
  {
    translationKey: 'warning-phenomenon-low-temp',
    key: 'lowTemp',
    isAviation: false,
  },
  {
    translationKey: 'warning-phenomenon-coastal-event',
    key: 'coastalEvent',
    isAviation: false,
  },
  {
    translationKey: 'aviation-phenomenon-radioactive-cloud',
    key: 'RDOACT_CLD',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-heavy-sandstorm',
    key: 'HVY_SS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-severe-turbulence',
    key: 'SEV_TURB',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-moderate-turbulence',
    key: 'MOD_TURB',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-severe-ice',
    key: 'SEV_ICE',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-moderate-ice',
    key: 'MOD_ICE',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-severe-ice-freezing-rain',
    key: 'SEV_ICE_FRZ_RN',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-heavy-dust-storm',
    key: 'HVY_DS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-volcanic-cloud',
    key: 'VA_CLD',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-embedded-thunderstorm',
    key: 'EMBD_TS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-embedded-thunderstorm-with-hail',
    key: 'EMBD_TSGR',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-obscured-thunderstorm-with-hail',
    key: 'OBSC_TSGR',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-obscured-thunderstorm',
    key: 'OBSC_TS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-squall-thunderstorm-with-hail',
    key: 'SQL_TSGR',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-isolated-thunderstorm',
    key: 'ISOL_TS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-isolated-thunderstorm-with-hail',
    key: 'ISOL_TSGR',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-occasional-thunderstorm',
    key: 'OCNL_TS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-occasional-thunderstorm-with-hail',
    key: 'OCNL_TSGR',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-frequent-thunderstorm',
    key: 'FRQ_TS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-frequent-thunderstorm-with-hail',
    key: 'FRQ_TSGR',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-severe-mountain-waves',
    key: 'SEV_MTW',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-surface-visibility',
    key: 'SFC_VIS',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-surface-wind',
    key: 'SFC_WIND',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-overcast-cloud',
    key: 'OVC_CLD',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-broken-cloud',
    key: 'BKN_CLD',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-isolated-cumulonimbus-cloud',
    key: 'ISOL_CB',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-occasional-cumulonimbus-cloud',
    key: 'OCNL_CB',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-frequent-cumulonimbus-cloud',
    key: 'FRQ_CB',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-isolated-towering-cumulus-cloud',
    key: 'ISOL_TCU',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-occasional-towering-cumulus-cloud',
    key: 'OCNL_TCU',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-frequent-towering-cumulus-cloud',
    key: 'FRQ_TCU',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-moderate-mountain-wave',
    key: 'MOD_MTW',
    isAviation: true,
  },
  {
    translationKey: 'aviation-phenomenon-mountains-obscured',
    key: 'MT_OBSC',
    isAviation: true,
  },
];

export const allPhenomenaDict: Record<string, FilterTranslation> =
  warningPhenomena.reduce(
    (list, item) => ({
      ...list,
      [item.key]: item,
    }),
    {},
  );

export const getPhenomenaIcon = (
  key: string,
): React.ReactElement | undefined => {
  return getWarningPhenomenaIcon(key) || getAviationPhenomenaIcon(key);
};
export const Phenomenon: React.FC<{
  isDisabled?: boolean;
  isReadOnly?: boolean;
}> = ({ isDisabled, isReadOnly }) => {
  const { t } = useWarningsTranslation();
  const { isRequired } = useDraftFormHelpers();

  // These phenomena are sent to Public Warning Form
  const warningFormPhenomena = warningPhenomena.filter(
    (phenomenon) => !phenomenon.isAviation && phenomenon.key !== emptyFilterId,
  );

  return (
    <ReactHookFormSelect
      name="phenomenon"
      label={t('warning-phenomenon')}
      rules={{ validate: { isRequired } }}
      disabled={isDisabled || isReadOnly}
      isReadOnly={isReadOnly}
    >
      {warningFormPhenomena.map((phenomenon) => (
        <MenuItem key={phenomenon.key} value={phenomenon.key}>
          <Grid container justifyContent="space-between">
            <Grid>{t(phenomenon.translationKey)}</Grid>
            <Grid sx={{ minWidth: 56 }}>
              {getPhenomenaIcon(phenomenon.key)}
            </Grid>
          </Grid>
        </MenuItem>
      ))}
    </ReactHookFormSelect>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, FormControl, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import {
  getLargestTimeUnit,
  TimeInMinutes,
} from '../../TimeSlider/timeSliderUtils';
import { useTimesliderTranslation } from '../../../utils/i18n';

export interface TimeSpanButtonCustomSelectProps {
  timeSliderSpan: number;
  setOpen: (display: boolean) => void;
  onChangeSliderValue: (value: number) => void;
}

export const TimeSpanButtonCustomSelect: React.FC<
  TimeSpanButtonCustomSelectProps
> = ({
  timeSliderSpan,
  setOpen,
  onChangeSliderValue,
}: TimeSpanButtonCustomSelectProps) => {
  const { t } = useTimesliderTranslation();
  const [selectedTimeValue, setSelectedTimeValue] = React.useState(
    timeSliderSpan / getLargestTimeUnit(timeSliderSpan),
  );
  const [selectedTimeResolution, setSelectedTimeResolution] = React.useState(
    getLargestTimeUnit(timeSliderSpan).toString(),
  );
  const [isANumber, setisANumber] = React.useState(true);

  return (
    <Box>
      <FormControl
        variant="standard"
        size="small"
        sx={{
          flexDirection: 'row',
          display: 'flex',
        }}
        onKeyDown={(event): void => {
          event.stopPropagation();
        }}
      >
        <TextField
          label={t('timeslider-span')}
          size="small"
          variant="standard"
          value={Math.round(selectedTimeValue * 100) / 100}
          autoFocus
          onChange={(e) => {
            if (!isNaN(+e.target.value)) {
              setisANumber(true);
              setSelectedTimeValue(Number(e.target.value));
            } else {
              setisANumber(false);
            }
          }}
          color={isANumber ? 'primary' : 'warning'}
          onBlur={() => {
            onChangeSliderValue(
              selectedTimeValue * Number(selectedTimeResolution),
            );
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Escape') {
              onChangeSliderValue(
                selectedTimeValue * Number(selectedTimeResolution),
              );
              setOpen(false);
            }
          }}
          slotProps={{
            htmlInput: {
              maxLength: 4,
              'aria-label': 'custom-input',
            },
          }}
        />
        <Box sx={{ alignContent: 'end' }}>
          <Select
            value={selectedTimeResolution}
            defaultValue="h"
            onChange={(e) => {
              setSelectedTimeResolution(e.target.value);
              onChangeSliderValue(selectedTimeValue * Number(e.target.value));
              setOpen(false);
            }}
            aria-label="time-resolution-selector"
            size="small"
            tabIndex={-1}
          >
            <MenuItem value={TimeInMinutes.YEAR}>{t('years')}</MenuItem>
            <MenuItem value={TimeInMinutes.MONTH}>{t('months')}</MenuItem>
            <MenuItem value={TimeInMinutes.WEEK}>{t('weeks')}</MenuItem>
            <MenuItem value={TimeInMinutes.DAY}>{t('days')}</MenuItem>
            <MenuItem value={TimeInMinutes.HOUR}>{t('hours')}</MenuItem>
            <MenuItem value={TimeInMinutes.MINUTE}>{t('minutes')}</MenuItem>
          </Select>
        </Box>
      </FormControl>
    </Box>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Grid2 as Grid,
  Typography,
  Card,
  CardContent,
  Button,
  ListItemButton,
} from '@mui/material';
import { Edit, Notifications } from '@opengeoweb/theme';
import { StatusTag, dateUtils } from '@opengeoweb/shared';
import { getStatusTagColorFromContent } from './utils';
import { EventCategory, SWEvent, SWEventLifeCycle } from '../../types';
import { useSpaceweatherTranslation } from '../../utils/i18n';

const listRowStyles = {
  padding: '2px',
  width: '100%',
  '.notificationListRowContainer': {
    containerType: 'inline-size',
    containerName: 'notificationListRow',
    width: '100%',
  },
  '.rowContainer': {
    padding: '14px',
    '&:last-child': {
      paddingBottom: '14px',
    },
  },
  '.col-1': {
    flexBasis: '50%',
  },
  '.col-2': {
    flexBasis: '30%',
  },
  '.col-3': {
    flexBasis: '20%',
  },
  '.col-4': {
    flexBasis: '50%',
  },
  '.col-4-notificationIcon': {
    display: 'none',
  },
  '.col-5': {
    flexBasis: '30%',
  },
  '@container notificationListRow (width > 500px)': {
    '.notificationListRowContainer': {
      '.rowContainer': {},
      '.col-1': {
        flexBasis: '40%',
      },
      '.col-2': {
        flexBasis: '15%',
      },
      '.col-3': {
        display: 'none',
      },
      '.col-4': {
        flexBasis: '30%',
        justifyContent: 'center',
      },
      '.col-4-notificationIcon': {
        display: 'block',
      },
      '.col-5': {
        flexBasis: '15%',
      },
    },
  },
};

const getStatusTagContent = (
  lifeCycle:
    | SWEventLifeCycle
    | {
        firstissuetime?: string;
        lastissuetime?: string;
        state?: string;
        label?: string;
        eventlevel?: string;
      },
  categorydetail: string,
): string => {
  if (lifeCycle.label === 'WARNING') {
    if (lifeCycle.state === 'issued') {
      return categorydetail === 'GEOMAGNETIC_STORM' ? 'Watch' : 'Warning';
    }
    return lifeCycle.state === 'expired' ? 'Expired' : 'Cancelled';
  }

  return lifeCycle.state === 'issued' ? 'Alert' : 'Summary';
};

interface NotificationRowProps {
  event: SWEvent;
  onNotificationRowClick: (event: SWEvent) => void;
}

const NotificationRow: React.FC<NotificationRowProps> = ({
  event,
  onNotificationRowClick,
}: NotificationRowProps) => {
  const { t } = useSpaceweatherTranslation();
  const externalProviderLifeCycle =
    event.originator !== 'KNMI'
      ? event.lifecycles!.externalprovider
      : {
          firstissuetime: '',
          lastissuetime: '',
          state: '',
          label: '',
          eventlevel: '',
        };

  const internalProviderLifeCycle =
    event.lifecycles!.internalprovider !== undefined
      ? event.lifecycles!.internalprovider
      : {
          lastissuetime: '',
          state: '',
          draft: false,
          label: '',
          eventlevel: '',
        };

  const internalTagContent = getStatusTagContent(
    internalProviderLifeCycle,
    event.categorydetail,
  );
  const externalTagContent = getStatusTagContent(
    externalProviderLifeCycle!,
    event.categorydetail,
  );

  const eventlevel =
    event.originator !== 'KNMI'
      ? externalProviderLifeCycle!.eventlevel
      : internalProviderLifeCycle.eventlevel;

  return (
    <ListItemButton
      onClick={(): void => {
        onNotificationRowClick(event);
      }}
      key={event.eventid}
      sx={listRowStyles}
      data-testid="notificationRow-listitem"
    >
      <Card
        elevation={0}
        variant="outlined"
        className="notificationListRowContainer"
      >
        <CardContent className="rowContainer">
          <Grid container justifyContent="left" alignItems="center">
            <Grid className="col-1">
              <Typography variant="body2">
                {`${eventlevel ? `${eventlevel} ` : ''}${
                  EventCategory[event.category as keyof typeof EventCategory]
                }`}
                {event.originator !== 'KNMI'
                  ? dateUtils.dateToString(
                      dateUtils.utc(externalProviderLifeCycle!.lastissuetime!),
                      `: ${dateUtils.DATE_FORMAT_DATEPICKER}`,
                    )
                  : ''}
              </Typography>
            </Grid>
            <Grid
              className="col-2"
              container
              justifyContent="right"
              data-testid="notificationRow-externalStatusTag"
            >
              {event.originator !== 'KNMI' && (
                <StatusTag
                  content={externalTagContent}
                  color={getStatusTagColorFromContent(externalTagContent)}
                  sx={{ width: '68px' }}
                />
              )}
            </Grid>
            <Grid className="col-3" container justifyContent="right">
              {event.notacknowledged === true && (
                <Notifications
                  color="warning"
                  data-testid="newNotificationRowIcon-small"
                />
              )}
            </Grid>

            <Grid className="col-4" container>
              {event.notacknowledged === true && (
                <Notifications
                  color="warning"
                  data-testid="newNotificationRowIcon-large"
                  className="col-4-notificationIcon"
                />
              )}
              {event.notacknowledged !== true &&
                internalProviderLifeCycle.draft !== true &&
                internalProviderLifeCycle.lastissuetime && (
                  <Typography
                    variant="body2"
                    data-testid="notificationRow-internalLastIssueTime"
                  >
                    {dateUtils.dateToString(
                      dateUtils.utc(internalProviderLifeCycle.lastissuetime),
                      `${dateUtils.DATE_FORMAT_DATEPICKER}`,
                    )}
                  </Typography>
                )}
            </Grid>
            <Grid
              className="col-5"
              container
              justifyContent="right"
              data-testid="notificationRow-internalStatusTagDraft"
            >
              {event.notacknowledged !== true &&
                internalProviderLifeCycle.draft !== true &&
                internalProviderLifeCycle.lastissuetime && (
                  <StatusTag
                    content={internalTagContent}
                    color={getStatusTagColorFromContent(internalTagContent)}
                    sx={{ width: '68px' }}
                  />
                )}
              {internalProviderLifeCycle.draft !== undefined &&
                internalProviderLifeCycle.draft === true && (
                  <Button
                    color="secondary"
                    size="small"
                    data-testid="notificationRow-draft"
                    startIcon={<Edit color="secondary" />}
                    sx={{ height: '20px' }}
                  >
                    {t('notification-edit')}
                  </Button>
                )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ListItemButton>
  );
};

export default NotificationRow;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Filter, ListFilter } from '@opengeoweb/shared';
import React from 'react';

export interface AreaObjectFilterProps {
  filters: ListFilter[];
  onClickFilterChip?: (id: string, selected: boolean) => void;
  searchQuery: string;
  onSearch?: (searchQuery: string) => void;
}

export const AreaObjectFilter = React.forwardRef<
  HTMLElement,
  AreaObjectFilterProps
>(
  (
    {
      filters = [],
      onClickFilterChip,
      searchQuery = '',
      onSearch,
    }: AreaObjectFilterProps,
    ref: React.Ref<HTMLElement>,
  ) => {
    const isAllSelected = !filters.some((filter) => filter.isSelected);

    return (
      <Filter
        ref={ref}
        filters={filters}
        onSearch={onSearch}
        onClickFilterChip={onClickFilterChip}
        isAllSelected={isAllSelected}
        searchQuery={searchQuery}
        sx={{
          '.filter-list': {
            display: 'block',
            '.MuiChip-root': {
              margin: 0,
              marginRight: 0.5,
              marginBottom: 0.5,
            },
          },
        }}
      />
    );
  },
);

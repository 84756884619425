/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CoreAppStore,
  genericActions,
  mapActions,
  mapSelectors,
} from '@opengeoweb/store';
import { handleDateUtilsISOString } from '@opengeoweb/webmap';
import { NowButton, handleSetNowEvent } from '@opengeoweb/timeslider';
import { dateUtils } from '@opengeoweb/shared';

export const defaultAnimationDuration = 6;

export interface NowButtonConnectProps {
  sourceId: string;
  mapId: string;
  isDisabled?: boolean;
}

export const NowButtonConnect: React.FC<NowButtonConnectProps> = ({
  sourceId,
  mapId,
  isDisabled,
}: NowButtonConnectProps) => {
  const dispatch = useDispatch();
  const currentTime = dateUtils.unix(dateUtils.utc());
  const timeStep = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeStep(store, mapId),
  );
  const [dataStartTime, dataEndTime] = useSelector((store: CoreAppStore) =>
    mapSelectors.getDataLimitsFromLayers(store, mapId),
  );

  const isAnimating = useSelector((store: CoreAppStore) =>
    mapSelectors.isAnimating(store, mapId),
  );

  const onSetNewDate = (newDate: string): void => {
    if (isAnimating) {
      dispatch(mapActions.mapStopAnimation({ mapId }));
    }
    dispatch(
      genericActions.setTime({
        sourceId,
        origin: 'NowButtonConnect, 140',
        value: handleDateUtilsISOString(newDate),
      }),
    );
  };

  const onSetCenterTime = (newTime: number): void => {
    dispatch(
      mapActions.setTimeSliderCenterTime({
        mapId,
        timeSliderCenterTime: newTime,
      }),
    );
  };

  return (
    <NowButton
      disabled={isDisabled}
      onSetNow={(): void =>
        handleSetNowEvent(
          timeStep!,
          dataStartTime,
          dataEndTime,
          currentTime,
          onSetNewDate,
          onSetCenterTime,
        )
      }
    />
  );
};

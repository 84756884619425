/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  CollapseLarge,
  CollapseMedium,
  CollapseSmall,
  CollapseWindow,
  ExpandWindow,
} from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { useCoreTranslation } from '../../utils/i18n';

export interface Size {
  width: number;
  height: number;
}

export interface ToolbarButtonSettings {
  small?: {
    icon?: React.ReactNode;
    isDisabled?: boolean;
  };
  medium?: {
    icon?: React.ReactNode;
    isDisabled?: boolean;
  };
  large?: {
    icon?: React.ReactNode;
    isDisabled?: boolean;
  };
  dock?: {
    isDisabled?: boolean;
  };
  divider?: {
    element: React.ReactNode;
  };
}

interface HeaderOptionsProps {
  isDockedLayerManager: boolean;
  shortcutsEnabled: boolean;
  onClickDockButton: () => void;
  onChangeSize: (size: Size) => void;
  buttonSettings?: ToolbarButtonSettings;
}

export const sizeSmall = {
  width: 100,
  height: 300,
};

export const sizeMedium = {
  width: 360,
  height: 300,
};

export const sizeLarge = {
  width: 720,
  height: 300,
};

export const sizeExtraLarge = {
  width: 720,
  height: 400,
};

const HeaderOptions: React.FC<HeaderOptionsProps> = ({
  isDockedLayerManager,
  shortcutsEnabled,
  onClickDockButton,
  onChangeSize,
  buttonSettings,
}: HeaderOptionsProps) => {
  const { t } = useCoreTranslation();
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (shortcutsEnabled) {
        if (event.ctrlKey && event.altKey && event.code === 'KeyS') {
          onChangeSize(sizeSmall);
        } else if (
          event.key === 'µ' ||
          (event.ctrlKey && event.altKey && event.code === 'KeyM')
        ) {
          onChangeSize(sizeMedium);
        } else if (event.ctrlKey && event.altKey && event.code === 'KeyL') {
          onChangeSize(sizeLarge);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onChangeSize, shortcutsEnabled]);

  return (
    <>
      {!buttonSettings?.small?.isDisabled && (
        <CustomIconButton
          tooltipTitle={t('layermanager-header-button-small')}
          data-testid="collapseSmall-btn"
          onClick={(): void => {
            onChangeSize(sizeSmall);
          }}
          className="collapseSmall-btn"
        >
          {buttonSettings?.small?.icon ?? <CollapseSmall />}
        </CustomIconButton>
      )}
      {!buttonSettings?.medium?.isDisabled && (
        <CustomIconButton
          tooltipTitle={t('layermanager-header-button-medium')}
          data-testid="collapseMedium-btn"
          onClick={(): void => {
            onChangeSize(sizeMedium);
          }}
          className="collapseMedium-btn"
        >
          {buttonSettings?.medium?.icon ?? <CollapseMedium />}
        </CustomIconButton>
      )}
      {!buttonSettings?.large?.isDisabled && (
        <CustomIconButton
          tooltipTitle={t('layermanager-header-button-large')}
          data-testid="collapseLarge-btn"
          onClick={(): void => {
            onChangeSize(sizeLarge);
          }}
          className="collapseLarge-btn"
        >
          {buttonSettings?.large?.icon ?? <CollapseLarge />}
        </CustomIconButton>
      )}

      {!buttonSettings?.dock?.isDisabled && (
        <CustomIconButton
          tooltipTitle={
            isDockedLayerManager
              ? t('layermanager-header-button-undock')
              : t('layermanager-header-button-dock')
          }
          data-testid="dockedBtn"
          disableRipple
          onClick={(): void => onClickDockButton()}
        >
          {isDockedLayerManager ? (
            <CollapseWindow data-testid="dockedLayerManager-collapse" />
          ) : (
            <ExpandWindow data-testid="dockedLayerManager-uncollapse" />
          )}
        </CustomIconButton>
      )}
      {buttonSettings?.divider?.element}
    </>
  );
};

export default HeaderOptions;

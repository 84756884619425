/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Draft } from '@reduxjs/toolkit';
import { WMJSDimension, webmapUtils } from '@opengeoweb/webmap';
import type { Dimension, ReduxLayer } from '../types';
import type { LayerState } from './types';

/**
 * Sets a dimension value for a layer in the state. If the dimension does not exist it is added to the layer.
 * @param draft The draft layerstate
 * @param layerId The layerId
 * @param dimensionValueToUpdate The dimension with values to update in the layer.
 */
const produceLayerDimensionDraftState = (
  draft: Draft<LayerState>,
  layerId: string,
  dimensionValueToUpdate: Dimension,
): void => {
  const layer = draft.byId[layerId];
  if (!layer.dimensions) {
    layer.dimensions = [];
  }
  const existingLayerDimIndex = layer.dimensions.findIndex(
    (layerDim) => layerDim.name === dimensionValueToUpdate.name,
  );
  if (existingLayerDimIndex < 0) {
    layer.dimensions.push({ ...dimensionValueToUpdate });
  } else {
    const layerDimension = layer.dimensions[existingLayerDimIndex];
    if (dimensionValueToUpdate.currentValue) {
      layerDimension.currentValue = dimensionValueToUpdate.currentValue;
    }
    if (dimensionValueToUpdate.synced !== undefined) {
      layerDimension.synced = dimensionValueToUpdate.synced;
    }
    if (dimensionValueToUpdate.validSyncSelection !== undefined) {
      layerDimension.validSyncSelection =
        dimensionValueToUpdate.validSyncSelection;
    }
  }
  updateMinMaxValueWhenReferenceTimeChanges(layer, dimensionValueToUpdate);
};

export const isActionLayerSynced = (
  state: LayerState,
  layerIdFromAction: string,
  dimension: Dimension,
): boolean => {
  if (dimension.synced !== undefined) {
    return dimension.synced;
  }
  const actionLayer = state.byId[layerIdFromAction];
  if (!actionLayer) {
    return false;
  }
  const layerDimensionIndex = actionLayer.dimensions!.findIndex(
    (layerDim) => layerDim.name === dimension.name,
  );
  if (layerDimensionIndex < 0) {
    return false;
  }
  return actionLayer.dimensions![layerDimensionIndex].synced === true;
};

export const produceDimensionActionForMapLayer = (
  layerDim: Dimension,
  wmjsDimension: WMJSDimension,
  newDimension: Dimension,
): Dimension => {
  // If new value does not occur in the wmjsdimension - do not update the value but set the validSyncSelection to false
  if (wmjsDimension.getIndexForValue(newDimension.currentValue) === -1) {
    return {
      ...layerDim,
      name: newDimension.name,
      units: newDimension.units,
      currentValue: layerDim.currentValue,
      validSyncSelection: false,
    };
  }
  // Otherwise, update to new value and set validSyncSelection to true
  return {
    ...layerDim,
    name: newDimension.name,
    units: newDimension.units,
    currentValue: newDimension.currentValue,
    validSyncSelection: true,
  };
};

/**
 * For the given dimension, it creates a new draft state for all other (linked or synced) layers inside the map.
 * @param draft
 * @param dimension
 * @param mapId
 */
export const produceDraftStateForAllLayersForDimensionWithinMap = (
  draft: Draft<LayerState>,
  dimension: Dimension,
  mapId: string,
  layerIdFromAction: string /* Layer ID or null if not known */,
): void => {
  if (!mapId) {
    return;
  }

  const actionLayerSynced = isActionLayerSynced(
    draft,
    layerIdFromAction,
    dimension,
  );
  draft.allIds.forEach((layerId) => {
    const layer = draft.byId[layerId];

    if (!layer || layer.mapId !== mapId) {
      return;
    }
    const wmjsDimension = webmapUtils.getWMJSDimensionForLayerAndDimension(
      layerId,
      dimension.name,
    );
    if (wmjsDimension?.linked && wmjsDimension.name === 'reference_time') {
      wmjsDimension.linked = false;
    }
    if (layerIdFromAction === layerId || wmjsDimension?.linked) {
      const evenWhenOutsideRange = true;
      const closestValue = wmjsDimension
        ? wmjsDimension.getClosestValue(
            dimension.currentValue,
            evenWhenOutsideRange,
          )
        : dimension.currentValue;

      const synced =
        dimension.synced !== undefined ? { synced: dimension.synced } : {};
      const dimensionValueToUpdate = {
        name: dimension.name,
        units: dimension.units,
        currentValue: closestValue,
        ...synced,
        validSyncSelection: true,
      };
      /* Set the dimension value for this layer */
      produceLayerDimensionDraftState(draft, layerId, dimensionValueToUpdate);
    } else if (actionLayerSynced) {
      // Check to see if we need to update layer based on being synced
      const layerDimensionIndex = layer.dimensions!.findIndex(
        (layerDim) => layerDim.name === dimension.name,
      );
      if (layerDimensionIndex !== -1) {
        const layerDim = layer.dimensions![layerDimensionIndex];
        if (layerDim.synced === true) {
          const dimensionValueToUpdate = produceDimensionActionForMapLayer(
            layerDim,
            wmjsDimension!,
            dimension,
          );
          /* Set the dimension value for this layer */
          produceLayerDimensionDraftState(
            draft,
            layerId,
            dimensionValueToUpdate,
          );
        }
      }
    }
  });
};

/**
 * Returns the list of dimensions without the time dimension
 * @param dimensions Dimension[]
 */
export const filterNonTimeDimensions = (
  dimensions: Dimension[],
): Dimension[] => {
  return dimensions?.filter((dim) => dim.name !== 'time') ?? [];
};

/**
 * Returns the list of dimensions without the time dimension
 * @param dimensions Dimension[]
 */
export const filterCurrentValueFromDimensions = (
  dimensions: Dimension[],
): Dimension[] => {
  return (
    dimensions?.map((dim: Dimension): Dimension => {
      // eslint-disable-next-line no-param-reassign
      dim.currentValue = '';
      return dim;
    }) ?? []
  );
};

/**
 * The timeslider range is depending on values set via reference_time dimension.
 * This code will update minValue and maxValue accordingly based on the value of the reference_time.
 *
 * @param draft
 * @param layerId
 * @param dimensionValueToUpdate
 * @returns
 */
export const updateMinMaxValueWhenReferenceTimeChanges = (
  draftLayer: Draft<ReduxLayer>,
  dimensionValueToUpdate: Dimension,
): void => {
  if (
    !draftLayer?.id ||
    !draftLayer?.dimensions ||
    dimensionValueToUpdate.name !== 'reference_time'
  ) {
    return;
  }

  // If dimension reference_time is updated, we might have to update the time minValue and maxValue as well.
  const wmLayer = webmapUtils.getWMLayerById(draftLayer.id);
  if (wmLayer && wmLayer.dimensions.length >= 2) {
    const referenceTimeDimension = wmLayer.getDimension('reference_time');
    const timeDimension = wmLayer.getDimension('time');
    const reduxTimeDim = draftLayer.dimensions.find((d) => d.name === 'time');
    if (reduxTimeDim && timeDimension && referenceTimeDimension) {
      // When the reference time changes, a new set of timevalues needs to be set in the layer.
      // From the new timevalues the min.max can be derived.
      timeDimension?.setTimeValuesForReferenceTime(
        dimensionValueToUpdate.currentValue,
        referenceTimeDimension,
      );

      reduxTimeDim.minValue = timeDimension.getFirstValue();
      reduxTimeDim.maxValue = timeDimension.getLastValue();
    }
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormTextField,
  ReactHookFormNumberField,
  isLatitude,
  isLongitude,
  isNonOrBothCoordinates,
} from '@opengeoweb/form-fields';
import { styles } from '../ProductForm.styles';
import { FormFieldProps } from '../../../types';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

interface VolcanicFieldsProps extends FormFieldProps {
  helperText: string;
}

const VolcanicFields: React.FC<VolcanicFieldsProps> = ({
  isDisabled,
  isReadOnly,
  helperText,
}: VolcanicFieldsProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { watch, trigger, getValues } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid
        container
        justifyContent="flex-start"
        offset={4}
        size={8}
        spacing={0}
        sx={{
          '.MuiFormHelperText-root': {
            marginTop: '2px',
          },
        }}
      >
        {(!isReadOnly || !!watch('vaSigmetVolcanoName')) && (
          <Grid sx={styles.volcanicField} size={12}>
            <ReactHookFormTextField
              name="vaSigmetVolcanoName"
              label={t('volcano-name')}
              rules={{ required: false }}
              disabled={isDisabled}
              helperText={helperText}
              upperCase={true}
              isReadOnly={isReadOnly}
              size="small"
            />
          </Grid>
        )}
        {(!isReadOnly || !!watch('vaSigmetVolcanoCoordinates.latitude')) && (
          <Grid size={6}>
            <ReactHookFormNumberField
              name="vaSigmetVolcanoCoordinates.latitude"
              data-testid="vaSigmetVolcanoCoordinates.latitude"
              label={t('latitude')}
              inputMode="decimal"
              rules={{
                required: true,
                validate: {
                  isLatitude,
                  isNonOrBothCoordinates: (value): boolean | string =>
                    isNonOrBothCoordinates(
                      value,
                      getValues('vaSigmetVolcanoCoordinates.longitude'),
                    ),
                },
              }}
              size="small"
              sx={{ ...styles.latitude }}
              disabled={isDisabled}
              onChange={(): Promise<boolean> =>
                trigger('vaSigmetVolcanoCoordinates.longitude')
              }
              isReadOnly={isReadOnly}
            />
          </Grid>
        )}
        {(!isReadOnly || !!watch('vaSigmetVolcanoCoordinates.longitude')) && (
          <Grid size={6}>
            <ReactHookFormNumberField
              name="vaSigmetVolcanoCoordinates.longitude"
              data-testid="vaSigmetVolcanoCoordinates.longitude"
              label={t('longitude')}
              inputMode="decimal"
              size="small"
              sx={{ ...styles.latitude }}
              rules={{
                required: true,
                validate: {
                  isLongitude,
                  isNonOrBothCoordinates: (value): boolean | string =>
                    isNonOrBothCoordinates(
                      value,
                      getValues('vaSigmetVolcanoCoordinates.latitude'),
                    ),
                },
              }}
              disabled={isDisabled}
              onChange={(): Promise<boolean> =>
                trigger('vaSigmetVolcanoCoordinates.latitude')
              }
              isReadOnly={isReadOnly}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default VolcanicFields;

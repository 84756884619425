/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { InputLabel, RadioGroup, RadioGroupProps } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import ReactHookFormFormControl from './ReactHookFormFormControl';
import { getErrors } from './formUtils';
import { ReactHookFormInput } from './types';
import { defaultProps } from './utils';

type ReactHookFormRadioGroupProps = Partial<RadioGroupProps> &
  ReactHookFormInput<{
    disabled?: boolean;
    children?: React.ReactNode;
  }>;

const ReactHookFormRadioGroup: React.FC<ReactHookFormRadioGroupProps> = ({
  name,
  label,
  defaultValue = '',
  children,
  rules,
  disabled = false,
  onChange = (): void => {},
  isReadOnly,
  ...otherProps
}: ReactHookFormRadioGroupProps) => {
  const labelId = `${name}-label`;

  const { control } = useFormContext();
  const {
    field: { onChange: onChangeField, value, ref },
    formState: { errors: formErrors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    ...defaultProps,
  });

  const errors = getErrors(name!, formErrors);

  React.useEffect(() => {
    if (errors) {
      const firstError = Object.keys(formErrors)[0];
      if (firstError === name) {
        // if first error in form is same, focus on that input
        const inputNode = document.querySelector(
          `[name="${name}"]`,
        ) as HTMLInputElement;
        if (inputNode !== null) {
          inputNode.focus();
        }
      }
    }
  }, [errors, formErrors, name]);

  return (
    <ReactHookFormFormControl
      disabled={disabled}
      errors={errors}
      isReadOnly={isReadOnly}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <RadioGroup
        value={value}
        onChange={(changeEvent): void => {
          onChangeField(changeEvent.target.value);
          onChange(changeEvent, changeEvent.target.value);
        }}
        ref={ref}
        name={name}
        {...otherProps}
      >
        {children}
      </RadioGroup>
    </ReactHookFormFormControl>
  );
};
export default ReactHookFormRadioGroup;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { ElementType, FC } from 'react';
import { MenuItem } from '@mui/material';
import { TooltipSelect } from '@opengeoweb/shared';
import { DimensionNameMappings } from '../../../LayerManagerUtils';
import { useCoreTranslation } from '../../../../../utils/i18n';

const DimensionSelectName: FC<{
  dimensionNames: string[];
  selectedDimensionName: string;
  setSelectedDimensionName: (dimension: string) => void;
  icon?: ElementType;
  nameMappings?: DimensionNameMappings;
  tooltip: string;
  isEnabled: boolean;
  skipLocalStyling?: boolean;
}> = ({
  selectedDimensionName,
  setSelectedDimensionName,
  icon,
  nameMappings,
  tooltip,
  isEnabled,
  dimensionNames,
  skipLocalStyling,
}) => {
  const { t } = useCoreTranslation();

  return (
    <TooltipSelect
      disableUnderline
      tooltip={tooltip}
      SelectDisplayProps={{ 'aria-label': 'dimensionSelectName' }}
      isEnabled={isEnabled}
      value={selectedDimensionName}
      list={dimensionNames.map((name) => ({ value: name }))}
      currentIndex={dimensionNames.findIndex(
        (name) => name === selectedDimensionName,
      )}
      onChange={(event): void => {
        event.stopPropagation();
        setSelectedDimensionName(event.target.value as string);
      }}
      onChangeMouseWheel={(event): void => {
        setSelectedDimensionName(event.value);
      }}
      style={{
        maxWidth: '50%',
      }}
      IconComponent={icon}
      skipLocalStyling={skipLocalStyling}
    >
      <MenuItem disabled>{t('layermanager-dimension-title')}</MenuItem>
      {dimensionNames.map((name) => (
        <MenuItem key={name} value={name}>
          {nameMappings?.[name] || name}
        </MenuItem>
      ))}
    </TooltipSelect>
  );
};

export default DimensionSelectName;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const formStyles = {
  '.MuiSelect-select.MuiInputBase-input.MuiFilledInput-input': {
    paddingTop: '17px',
    paddingBottom: '0px',
  },
  '.MuiInputBase-input.MuiFilledInput-input': {
    paddingTop: '15px',
    paddingBottom: '2px',
  },
};

export const styles = {
  label: {
    fontSize: '0.875rem',
    marginTop: 2,
    textAlign: 'right',
  },
  body: {
    paddingTop: '17px',
  },
  containerItem: {
    marginTop: -1,
    ' .MuiTypography-subtitle1': {
      marginTop: 1,
    },
    '+ .MuiGrid-item': {
      paddingTop: 0,
    },
    '.MuiButtonBase-root.MuiRadio-root': {
      padding: '6px',
      marginLeft: '3px',
    },
    '.MuiFormControl-root.MuiTextField-root': {
      marginBottom: 0,
    },
    '.MuiGrid-root': {
      alignItems: 'flex-start',
    },
  },
  volcanicField: {
    marginBottom: '14px',
    '.MuiFormHelperText-root': {
      marginTop: '2px',
    },
  },
  surfaceVisibility: {
    marginBottom: 2,
  },
  surfaceWind: {
    marginBottom: 2,
    ' .MuiFormHelperText-root': {
      lineHeight: '1.33em',
    },
  },
  levelField: {
    marginBottom: 2,
  },
  movement: {
    marginBottom: 2,
  },
  latitude: {
    width: '97%',
    '.MuiFormControl-root.MuiTextField-root': {
      margin: 0,
    },
    '.MuiFormHelperText-root': {
      marginTop: '3px',
      lineHeight: '1.33em',
    },
  },
  unit: {
    width: '95%',
  },
  quitDrawModeMessage: { opacity: '1' },
  drawSection: {
    ' .MuiTypography-subtitle1': {
      marginTop: 1,
    },
    '.MuiFormHelperText-root': {
      marginTop: '-21px',
      lineHeight: '1.33em',
    },
    '.MuiFormHelperText-root.Mui-error': {
      marginTop: '-21px',
      marginBottom: '-10px',
      lineHeight: '1.33em',
    },
  },
  drawSectionProgress: {
    ' .MuiTypography-subtitle1': {
      marginTop: 1,
    },
    '.MuiFormHelperText-root': {
      marginTop: 0,
    },
  },
  helperText: {
    ' .MuiFormHelperText-root': {
      top: 'auto',
      position: 'relative',
      lineHeight: '1.33em',
      '&.Mui-error': {
        top: 'auto',
        position: 'relative',
      },
    },
    '.MuiFormLabel-root.MuiInputLabel-root': {
      top: -4,
      lineHeight: '1.33em',
    },
    '.MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em',
    },
    '.MuiButtonBase-root.MuiIconButton-root': {
      padding: '2px',
      marginLeft: '-4px',
    },
  },
};

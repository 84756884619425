/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { CustomIconButton } from '@opengeoweb/shared';
import { Export } from '@opengeoweb/theme';
import { useTafTranslation } from '../../../utils/i18n';

interface ExportButtonProps {
  onClick: () => void;
  isHidden: boolean;
}

const ExportButton: React.FC<ExportButtonProps> = ({
  onClick,
  isHidden,
}: ExportButtonProps) => {
  const { t } = useTafTranslation();
  return isHidden ? null : (
    <CustomIconButton
      onClick={onClick}
      data-testid="export-tac-button"
      variant="tool"
      tooltipProps={{ title: t('taf-import-title'), placement: 'left' }}
    >
      <Export />
    </CustomIconButton>
  );
};

export default ExportButton;

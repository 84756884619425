/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  CoordinateReferenceSystem,
  Dimension,
  LayerFoundation,
  LayerOptions,
  LayerType,
  Style,
  WMLayer,
} from '../components';
import { generateLayerId } from '../utils';

export const defaultReduxLayerRadarColor: LayerOptions = {
  service: 'https://testservice',
  name: 'RAD_NL25_PCP_CM',
  title: 'RAD_NL25_PCP_CM',
  format: 'image/png',
  style: 'rainbow/nearest',
  enabled: true,
  layerType: LayerType.mapLayer,
  dimensions: [],
  id: generateLayerId(),
};

export const WmdefaultReduxLayerRadarKNMI = new WMLayer({
  service: 'https://testservice',
  name: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
  title: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
  format: 'image/png',
  style: 'knmiradar/nearest',
  enabled: true,
  layerType: LayerType.mapLayer,
  dimensions: [
    {
      name: 'time',
      units: 'ISO8601',
      currentValue: '2020-03-13T13:30:00Z',
    },
  ],
  id: 'layerid_3',
});

export const makeGeoservicesRadarLayer = (
  dimensions?: Dimension[],
): WMLayer => {
  return new WMLayer({
    service: 'https://geoservices.knmi.nl/wms?dataset=RADAR&',
    name: 'RAD_NL25_PCP_CM',
    format: 'image/png',
    enabled: true,
    style: 'radar/nearest',
    id: generateLayerId(),
    layerType: LayerType.mapLayer,
    title: 'RAD_NL25_PCP_CM',
    dimensions: dimensions ?? [
      {
        name: 'time',
        units: 'ISO8601',
        currentValue: '2021-04-15T00:00:00Z',
      },
    ],
  });
};

export const WmMultiDimensionLayer = new WMLayer({
  service: 'https://testservice',
  id: 'multiDimensionLayerMock',
  name: 'MULTI_DIMENSION_LAYER',
  title: 'MULTI_DIMENSION_LAYER',
  layerType: LayerType.mapLayer,
  enabled: true,
  dimensions: [
    {
      name: 'flight level',
      units: 'hft',
      currentValue: '625',
      values: '25,325,625',
    },
    {
      name: 'elevation',
      units: 'meters',
      currentValue: '9000',
      values: '1000,5000,9000',
    },
    {
      name: 'time',
      units: 'ISO8601',
      currentValue: '2020-03-13T14:40:00Z',
      values: '2020-03-13T10:40:00Z/2020-03-13T14:40:00Z/PT5M',
    },
  ],
});

export const layerWithoutTimeDimension: LayerOptions = {
  service: 'https://testservice',
  id: 'layerWithoutTime',
  name: 'LAYER_WITHOUT_TIME',
  layerType: LayerType.mapLayer,
  dimensions: [
    {
      name: 'flight level',
      units: 'hft',
      currentValue: '625',
      values: '25,325,625',
    },
  ],
};

export const WmLayerWithoutTimeDimension = new WMLayer(
  layerWithoutTimeDimension,
);

export const crsListForRADNL25PCPCMLayer: CoordinateReferenceSystem[] = [
  {
    name: 'EPSG:3411',
    bbox: {
      left: 2682754.74362,
      right: 3759536.917562,
      bottom: -3245034.014141,
      top: -2168251.989038,
    },
  },
  {
    name: 'EPSG:3412',
    bbox: {
      left: 0,
      right: 7413041.166015,
      bottom: 32318824.826266,
      top: 40075258.815074,
    },
  },
  {
    name: 'EPSG:3575',
    bbox: {
      left: -770622.801471,
      right: 56845.766135,
      bottom: -4485814.811314,
      top: -3684039.44362,
    },
  },
  {
    name: 'EPSG:3857',
    bbox: {
      left: 0,
      right: 1208534.698398,
      bottom: 6257115.219364,
      top: 7553161.958695,
    },
  },
  {
    name: 'EPSG:4258',
    bbox: {
      left: 0,
      right: 10.856452,
      bottom: 48.895303,
      top: 55.9736,
    },
  },
  {
    name: 'EPSG:4326',
    bbox: {
      left: 0,
      right: 10.856452,
      bottom: 48.895303,
      top: 55.9736,
    },
  },
  {
    name: 'CRS:84',
    bbox: {
      left: 0,
      right: 10.856452,
      bottom: 48.895303,
      top: 55.9736,
    },
  },
  {
    name: 'EPSG:25831',
    bbox: {
      left: 282182.345905,
      right: 997135.658653,
      bottom: 5433247.394267,
      top: 6207204.592736,
    },
  },
  {
    name: 'EPSG:25832',
    bbox: {
      left: -153083.019482,
      right: 617595.626092,
      bottom: 5415817.312927,
      top: 6239769.309937,
    },
  },
  {
    name: 'EPSG:28992',
    bbox: {
      left: -236275.338083,
      right: 501527.918656,
      bottom: 106727.731651,
      top: 900797.079725,
    },
  },
  {
    name: 'EPSG:7399',
    bbox: {
      left: 0,
      right: 763611.971696,
      bottom: 5757301.056717,
      top: 6483919.801602,
    },
  },
  {
    name: 'EPSG:50001',
    bbox: {
      left: -2000000,
      right: 10000000,
      bottom: -2000000,
      top: 8500000,
    },
  },
  {
    name: 'EPSG:54030',
    bbox: {
      left: 0,
      right: 853649.695106,
      bottom: 5211855.054125,
      top: 5936394.291427,
    },
  },
  {
    name: 'EPSG:32661',
    bbox: {
      left: 2000000,
      right: 2745713.040381,
      bottom: -2703305.597319,
      top: -1888346.21671,
    },
  },
  {
    name: 'EPSG:40000',
    bbox: {
      left: 0,
      right: 750214.326339,
      bottom: -4731695.771951,
      top: -3911817.119426,
    },
  },
  {
    name: 'EPSG:900913',
    bbox: {
      left: 0,
      right: 1208534.698398,
      bottom: 6257115.219364,
      top: 7553161.958695,
    },
  },
  {
    name: 'PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0',
    bbox: {
      left: 0,
      right: 700.00242,
      bottom: -3649.999338,
      top: -4415.002986,
    },
  },
];

export const styleListForRADNL25PCPCMLayer: Style[] = [
  {
    title: 'radar/nearest',
    name: 'radar/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=radar/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-rainbow/nearest',
    name: 'precip-rainbow/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-rainbow/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-gray/nearest',
    name: 'precip-gray/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-gray/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-blue/nearest',
    name: 'precip-blue/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-blue/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-blue-transparent/nearest',
    name: 'precip-blue-transparent/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-blue-transparent/nearest',
    abstract: 'No abstract available',
  },
  {
    title: 'precip-with-range/nearest',
    name: 'precip-with-range/nearest',
    legendURL:
      'https://geoservices.knmi.nl/wms?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-with-range/nearest',
    abstract: 'No abstract available',
  },
];

export const multiDimensionLayer3: LayerFoundation = {
  service: 'https://testservicedimensions',
  id: 'multiDimensionLayerMock2',
  name: 'netcdf_5dims',
  layerType: LayerType.mapLayer,
  dimensions: [
    {
      name: 'member',
      units: 'member number',
      currentValue: 'member1',
      values: 'member1,member2,member3,member4',
    },
    {
      name: 'elevation',
      units: 'meters',
      currentValue: '9000',
      values: '1000,5000,9000',
    },
    {
      name: 'time',
      units: 'ISO8601',
      currentValue: '2017-01-01T00:25:00Z',
      timeInterval: {
        year: 0,
        month: 0,
        day: 0,
        hour: 0,
        minute: 5,
        second: 0,
        isRegularInterval: true,
      },
    },
  ],
};

export const defaultReduxLayerRadarKNMIWithStyles: LayerFoundation & {
  styles: Style[];
} = {
  service: 'https://testservice',
  name: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
  title: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
  format: 'image/png',
  style: 'knmiradar/nearest',
  enabled: true,
  layerType: LayerType.mapLayer,
  dimensions: [
    {
      name: 'time',
      units: 'ISO8601',
      currentValue: '2020-03-13T13:30:00Z',
    },
  ],
  styles: [
    {
      title: 'knmiradar/nearest',
      name: 'knmiradar/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=knmiradar/nearest',
      abstract: 'No abstract available',
    },
    {
      title: 'precip/nearest',
      name: 'precip/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip/nearest',
      abstract: 'No abstract available',
    },
    {
      title: 'precip-transparent/nearest',
      name: 'precip-transparent/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-transparent/nearest',
      abstract: 'No abstract available',
    },
    {
      title: 'precip-gray/nearest',
      name: 'precip-gray/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-gray/nearest',
      abstract: 'No abstract available',
    },
    {
      title: 'precip-rainbow/nearest',
      name: 'precip-rainbow/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-rainbow/nearest',
      abstract: 'No abstract available',
    },
    {
      title: 'precip-blue/nearest',
      name: 'precip-blue/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-blue/nearest',
      abstract: 'No abstract available',
    },
    {
      title: 'precip-blue-transparent/nearest',
      name: 'precip-blue-transparent/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=precip-blue-transparent/nearest',
      abstract: 'No abstract available',
    },
    {
      title: 'pseudoradar/nearest',
      name: 'pseudoradar/nearest',
      legendURL:
        'https://geoservices.knmi.nl/wms?dataset=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RADNL_OPER_R___25PCPRR_L3_KNMI&format=image/png&STYLE=pseudoradar/nearest',
      abstract: 'No abstract available',
    },
  ],
  id: 'layerid_2',
};

export const defaultReduxLayerRadarKNMI =
  defaultReduxLayerRadarKNMIWithStyles as LayerOptions;

export const multiDimensionLayer = {
  service: 'https://testservice',
  id: 'multiDimensionLayerMock',
  name: 'MULTI_DIMENSION_LAYER',
  title: 'MULTI_DIMENSION_LAYER',
  layerType: LayerType.mapLayer,
  enabled: true,
  dimensions: [
    {
      name: 'flight level',
      units: 'hft',
      currentValue: '625',
      values: '25,325,625',
    },
    {
      name: 'elevation',
      units: 'meters',
      currentValue: '9000',
      values: '1000,5000,9000',
    },
    {
      name: 'time',
      units: 'ISO8601',
      currentValue: '2020-03-13T14:40:00Z',
    },
  ],
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  snackbarReducer,
  snackbarListener,
  SnackbarModuleStore,
} from '@opengeoweb/snackbar';
import {
  mapReducer,
  layerReducer,
  syncGroupsReducer,
  loadingIndicatorReducer,
  uiReducer,
  mapListener,
  layersListener,
  syncGroupsListener,
  WebMapStateModuleState,
  serviceReducer,
  serviceListener,
  SynchronizationGroupModuleState,
  UIModuleState,
} from '@opengeoweb/store';
import { timeSeriesReducer } from '@opengeoweb/timeseries';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { viewPresetsReducer } from './viewPresets';
import { viewPresetsListener } from './viewPresets/listener';
import { viewPresetsListReducer } from './viewPresetsList';
import { workspaceReducer } from './workspace';
import { workspaceListener } from './workspace/listener';
import { workspaceListReducer } from './workspaceList';
import { workspaceListListener } from './workspaceList/listener';
import { WorkspaceModuleStore } from './types';

export const workspaceReducersMap = {
  webmap: mapReducer,
  services: serviceReducer,
  layers: layerReducer,
  syncGroups: syncGroupsReducer,
  loadingIndicator: loadingIndicatorReducer,
  ui: uiReducer,
  workspace: workspaceReducer,
  workspaceList: workspaceListReducer,
  viewPresetsList: viewPresetsListReducer,
  viewPresets: viewPresetsReducer,
  timeSeries: timeSeriesReducer,
  snackbar: snackbarReducer,
};

export const workspaceMiddlewares = [
  mapListener.middleware,
  serviceListener.middleware,
  layersListener.middleware,
  syncGroupsListener.middleware,
  workspaceListener.middleware,
  workspaceListListener.middleware,
  viewPresetsListener.middleware,
  snackbarListener.middleware,
];

const workspaceRootReducer = combineReducers(workspaceReducersMap);

export const createMockStore = (
  mockState?:
    | WebMapStateModuleState
    | WorkspaceModuleStore
    | UIModuleState
    | SynchronizationGroupModuleState
    | SnackbarModuleStore,
): ToolkitStore =>
  configureStore({
    reducer: workspaceRootReducer,
    preloadedState: mockState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(workspaceMiddlewares),
  });

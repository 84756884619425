/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import { layerTypes, serviceTypes } from '@opengeoweb/store';
import { LayerListRow } from './LayerListRow';
import { widthToRowHeight } from '../LayerSelect/LayerSelectUtils';
import { LayerWithServiceName } from '../../store/types';
import { useLayerSelectTranslation } from '../../utils/i18n';
import { DeleteLayerParams } from './LayerAddRemoveButton';

interface AddLayerParams {
  serviceUrl: string;
  layerName: string;
}

interface ComponentProps {
  // eslint-disable-next-line react/no-unused-prop-types
  index: number;
  // eslint-disable-next-line react/no-unused-prop-types
  style: React.CSSProperties;
}

export interface LayerListProps {
  services: serviceTypes.Services;
  filteredLayers: LayerWithServiceName[];
  layerSelectHeight: number;
  serviceListHeight: number;
  layerSelectWidth: number;
  addLayer: ({ serviceUrl, layerName }: AddLayerParams) => void;
  deleteLayer: ({ layerId }: DeleteLayerParams) => void;
  mapLayers: layerTypes.ReduxLayer[];
  searchFilter: string;
  isLayerInfoVisibleInStore: boolean;
  onToggleActiveLayer: ({
    serviceUrl,
    layerName,
    isActiveLayer,
  }: AddLayerParams & { isActiveLayer: boolean }) => void;
  currentLayerInfo?: AddLayerParams;
}

export const LayerList: React.FC<LayerListProps> = ({
  services,
  filteredLayers,
  layerSelectHeight,
  serviceListHeight,
  layerSelectWidth,
  addLayer,
  deleteLayer,
  mapLayers,
  searchFilter,
  isLayerInfoVisibleInStore,
  onToggleActiveLayer,
  currentLayerInfo,
}: LayerListProps) => {
  const { t } = useLayerSelectTranslation();
  const rowMargin = 4;
  const rowHeight = widthToRowHeight(layerSelectWidth);
  const layerSelectFilterHeight = 155 + serviceListHeight;
  const minHeight = 320;
  return (
    <Box data-testid="layerList">
      <Box sx={{ marginBottom: '4px', fontSize: '12px' }}>
        {filteredLayers.length} {t('layer-list-results')}
      </Box>

      <List
        height={
          layerSelectHeight - layerSelectFilterHeight < minHeight
            ? minHeight
            : layerSelectHeight - layerSelectFilterHeight
        }
        itemCount={filteredLayers.length}
        itemSize={rowHeight + rowMargin}
        width="100%"
      >
        {({ index, style }: ComponentProps): React.ReactElement => {
          const filter = filteredLayers[index];
          const service = services[filter.serviceName];
          const isActiveLayer =
            isLayerInfoVisibleInStore &&
            currentLayerInfo?.serviceUrl === service.serviceUrl &&
            currentLayerInfo?.layerName === filter.name;

          return (
            <div style={style} key={filter.name}>
              {service && (
                <LayerListRow
                  layer={filter}
                  service={service}
                  addLayer={addLayer}
                  deleteLayer={deleteLayer}
                  mapLayers={mapLayers}
                  layerSelectWidth={layerSelectWidth}
                  searchFilter={searchFilter}
                  hasActiveLayer={isActiveLayer}
                  onToggleActiveLayer={() =>
                    onToggleActiveLayer({
                      serviceUrl: service?.serviceUrl!,
                      layerName: filter.name!,
                      isActiveLayer,
                    })
                  }
                />
              )}
            </div>
          );
        }}
      </List>
    </Box>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid2 as Grid,
  CircularProgress,
  Backdrop,
  TooltipProps,
} from '@mui/material';

import { CustomIconButton, CustomTooltip } from '@opengeoweb/shared';
import { Add, Delete, Edit, Visibility, Cached } from '@opengeoweb/theme';

import { layerTypes } from '@opengeoweb/store';
import { TFunction } from 'i18next';
import { sortByService } from '../LayerSelect/LayerSelectUtils';
import { isUserAddedService, layerSelectTypes } from '../../store';
import { useLayerSelectTranslation } from '../../utils/i18n';

interface ServiceOptionsDialogProps {
  services: layerSelectTypes.ActiveServiceObjectEntities;
  layerSelectRemoveService?: (
    serviceId: string,
    serviceUrl: string,
    serviceName: string,
  ) => void;
  layerSelectReloadService?: (serviceUrl: string) => void;
  selectedLayers: layerTypes.ReduxLayer[];
  setServicePopupInfo?: (popupInfo: ServicePopupInfo) => void;
  isLoading?: boolean;
}

const styles = {
  servicesContainer: {
    width: '360px',
    maxHeight: '412px',
    top: '140px',
    backgroundColor: 'geowebColors.background.surface',
    boxShadow: 6,
    overflow: 'auto',
  },
  header: {
    position: 'sticky',
    top: 0,
    fontSize: 'default',
    padding: '12px',
    backgroundColor: 'geowebColors.background.surface',
    zIndex: 100,
  },
  loading: {
    position: 'absolute',
    zIndex: 101,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'geowebColors.background.surface',
    zIndex: 100,
  },
  button: {
    margin: '16px',
    width: '80%',
    fontSize: '12px',
    textTransform: 'none',
  },
};

const stopTabPropagation = (event: React.KeyboardEvent): void => {
  if (event.key === 'Tab') {
    event.stopPropagation();
  }
};

export interface ServicePopupInfo {
  isOpen: boolean;
  serviceId?: string;
  serviceUrl?: string;
  variant: layerSelectTypes.PopupVariant;
}
export const ServiceOptionsDialog: React.FC<ServiceOptionsDialogProps> = ({
  services,
  layerSelectRemoveService,
  layerSelectReloadService,
  selectedLayers,
  setServicePopupInfo,
  isLoading = false,
}: ServiceOptionsDialogProps) => {
  const { t } = useLayerSelectTranslation();
  const openAddService = (): void => {
    setServicePopupInfo!({ isOpen: true, variant: 'add' });
  };
  const dialogRef = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    dialogRef.current?.focus();
  }, []);

  return (
    <Box
      sx={styles.servicesContainer}
      data-testid="ServiceDialog"
      onKeyDown={(event): void => {
        if (event.key !== 'Escape') {
          event.stopPropagation();
        }
      }}
      tabIndex={-1}
      ref={dialogRef}
    >
      <Box sx={styles.header}>{t('services')}</Box>
      <Backdrop
        sx={{ ...styles.loading, backgroundSize: 'cover' }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      <Rows
        services={services}
        layerSelectRemoveService={layerSelectRemoveService!}
        layerSelectReloadService={layerSelectReloadService!}
        setServicePopupInfo={setServicePopupInfo!}
        selectedLayers={selectedLayers}
      />
      <Box sx={styles.footer}>
        <Button
          onClick={openAddService}
          onKeyDown={stopTabPropagation}
          data-testid="openAddServiceButton"
          sx={styles.button}
          variant="tertiary"
          startIcon={<Add />}
        >
          {t('add-a-new-service')}
        </Button>
      </Box>
    </Box>
  );
};

const deleteServiceTitle = (t: TFunction, hasActiveLayers: boolean): string => {
  return hasActiveLayers
    ? t('service-has-active-layers-error')
    : t('delete-service');
};

const tooltipPlacement = (): TooltipProps['placement'] => {
  return 'left';
};

const tooltipPopperProps = {
  disablePortal: true,
  sx: {
    '& .MuiTooltip-tooltip': {
      width: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};

const Rows: React.FC<{
  services: layerSelectTypes.ActiveServiceObjectEntities;
  layerSelectRemoveService: (
    serviceId: string,
    serviceUrl: string,
    serviceName: string,
  ) => void;
  layerSelectReloadService: (serviceUrl: string) => void;
  setServicePopupInfo: (popupInfo: ServicePopupInfo) => void;
  selectedLayers: layerTypes.ReduxLayer[];
}> = ({
  services,
  layerSelectReloadService,
  layerSelectRemoveService,
  setServicePopupInfo,
  selectedLayers,
}) => {
  const { t } = useLayerSelectTranslation();
  const checkIfServiceHasActiveLayers = (serviceUrl: string): boolean => {
    const foundService = selectedLayers.find(
      (object) => object.service === serviceUrl,
    );
    if (foundService) {
      return true;
    }
    return false;
  };

  const sortedServices = sortByService(services);

  return (
    <List disablePadding>
      {Object.keys(sortedServices).map((serviceId) => {
        const { serviceUrl, serviceName, scope } = services[serviceId];
        const serviceHasActiveLayers = checkIfServiceHasActiveLayers(
          serviceUrl!,
        );
        const userAddedService = isUserAddedService(scope!);
        return (
          <ListItem
            key={serviceUrl}
            secondaryAction={
              <Box
                sx={{
                  marginRight: '0px',
                  width: 96,
                  button: { marginLeft: 1 },
                }}
              >
                <Grid
                  container
                  justifyContent="left"
                  sx={{
                    marginRight: '0px',
                    width: 96,
                    button: { marginLeft: 1 },
                  }}
                >
                  <CustomTooltip
                    data-testid="updateServiceTooltip"
                    title={t('update-service-title')}
                    placement={tooltipPlacement()}
                    PopperProps={tooltipPopperProps}
                  >
                    <CustomIconButton
                      data-testid="updateServiceButton"
                      aria-label="Update layers from WMS service"
                      onKeyDown={stopTabPropagation}
                      onClick={(): void => {
                        layerSelectReloadService(serviceUrl!);
                      }}
                    >
                      <Cached />
                    </CustomIconButton>
                  </CustomTooltip>
                  {userAddedService ? (
                    <CustomTooltip
                      title={t('edit-service-title')}
                      placement={tooltipPlacement()}
                      PopperProps={tooltipPopperProps}
                    >
                      <span>
                        <CustomIconButton
                          aria-label="edit service"
                          data-testid="openEditServiceButton"
                          onKeyDown={stopTabPropagation}
                          onClick={(): void => {
                            setServicePopupInfo({
                              isOpen: true,
                              serviceId,
                              serviceUrl,
                              variant: 'edit',
                            });
                          }}
                        >
                          <Edit />
                        </CustomIconButton>
                      </span>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip
                      title={t('show-service-title')}
                      placement={tooltipPlacement()}
                      PopperProps={tooltipPopperProps}
                    >
                      <span>
                        <CustomIconButton
                          aria-label="show service"
                          data-testid="openShowServiceButton"
                          onKeyDown={stopTabPropagation}
                          onClick={(): void => {
                            setServicePopupInfo({
                              isOpen: true,
                              serviceId,
                              serviceUrl,
                              variant: 'show',
                            });
                          }}
                        >
                          <Visibility />
                        </CustomIconButton>
                      </span>
                    </CustomTooltip>
                  )}
                  {userAddedService && (
                    <Box>
                      <CustomTooltip
                        title={deleteServiceTitle(t, serviceHasActiveLayers)}
                        PopperProps={tooltipPopperProps}
                        placement={tooltipPlacement()}
                        data-testid="removeServiceTooltip"
                      >
                        <span>
                          <CustomIconButton
                            data-testid="removeServiceButton"
                            aria-label="delete service"
                            disabled={serviceHasActiveLayers}
                            onKeyDown={stopTabPropagation}
                            onClick={(): void =>
                              layerSelectRemoveService(
                                serviceId,
                                serviceUrl!,
                                serviceName!,
                              )
                            }
                          >
                            <Delete />
                          </CustomIconButton>
                        </span>
                      </CustomTooltip>
                    </Box>
                  )}
                </Grid>
              </Box>
            }
          >
            <ListItemText
              style={{
                fontSize: '16px',
              }}
            >
              <Typography
                noWrap
                style={{
                  textOverflow: 'ellipsis',
                  paddingRight: '70px',
                }}
              >
                {serviceName}
              </Typography>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { HideSliderButton } from '../TimeSliderButtons/HideSliderButton/HideSliderButton';
import {
  useMoveTimeWithKeyboard,
  useToggleTimesliderVisibility,
} from './timeSliderEffects';
import { TIME_SLIDER_LEGEND_HEIGHT } from '../TimeSliderLegend/timeSliderLegendUtils';

export interface TimeSliderProps {
  buttons: React.ReactNode;
  timeBox: React.ReactNode;
  legend: React.ReactNode;
  mapIsActive?: boolean;
  onToggleTimeSlider?: (isVisible: boolean) => void;
  isVisible?: boolean;
  timeStep: number;
  dataStartTime: number;
  dataEndTime: number;
  selectedTime: number;
  onSetNewDate: (newDate: string) => void;
  onSetCenterTime: (newTime: number) => void;
}
export const TimeSlider: React.FC<TimeSliderProps> = ({
  buttons,
  timeBox,
  legend,
  mapIsActive = true,
  isVisible = true,
  timeStep,
  dataStartTime,
  dataEndTime,
  selectedTime,
  onSetNewDate,
  onSetCenterTime,
  onToggleTimeSlider = (): void => {},
}) => {
  // set up visibility toggle
  useToggleTimesliderVisibility(mapIsActive, isVisible, onToggleTimeSlider);

  // set up movement with keyboard shortcuts
  useMoveTimeWithKeyboard(
    mapIsActive,
    timeStep,
    dataStartTime,
    dataEndTime,
    onSetNewDate,
    onSetCenterTime,
    selectedTime,
  );

  // timeBox, buttons and legend are created in TimeSliderConnect.tsx
  // these components will always be created as TimeSlider never runs in isolation from the store
  return isVisible ? (
    <Grid
      container
      sx={{
        display: 'grid',
        gridTemplateColumns: '175px 1fr 40px',
        gridTemplateRows: TIME_SLIDER_LEGEND_HEIGHT,
        gridTemplateAreas: `". timebox ."
                            "buttons timelegend toggle"`,
        paddingBottom: 1,
        alignItems: 'center',
        justifyContent: 'center',
        visibility: isVisible ? 'visible' : 'hidden',
      }}
      data-testid="timeSlider"
    >
      <Grid
        sx={{
          gridArea: 'timebox',
          zIndex: 99,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {timeBox}
      </Grid>
      <Grid sx={{ gridArea: 'buttons' }}>{buttons}</Grid>
      <Grid sx={{ gridArea: 'timelegend' }}>{legend}</Grid>
      <Grid sx={{ gridArea: 'toggle', paddingLeft: 1 }}>
        <HideSliderButton
          toggleTimeSliderIsVisible={onToggleTimeSlider}
          isVisible={isVisible}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      sx={{
        paddingRight: 1,
        paddingBottom: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <HideSliderButton
        toggleTimeSliderIsVisible={onToggleTimeSlider}
        isVisible={isVisible}
      />
    </Grid>
  );
};

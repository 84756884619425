/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Source, ToolContainerDraggable } from '@opengeoweb/shared';
import { Grid2 as Grid } from '@mui/material';
import { DimensionConfig, dimensionConfig } from './utils';
import { useWebmapReactTranslation } from '../../utils/i18n';

export interface DimensionSelectProps {
  isOpen: boolean;
  order?: number;
  onMouseDown?: () => void;
  onClose?: () => void;
  source?: Source;
  dimensionName: string;
  index?: number;
  children?: React.ReactNode;
}

const DimensionSelectDialog: React.FC<DimensionSelectProps> = ({
  isOpen,
  order = 0,
  onMouseDown,
  onClose,
  source = 'app',
  dimensionName,
  index = 0,
  children,
}) => {
  const { t } = useWebmapReactTranslation();
  const dimConfig: DimensionConfig = dimensionConfig(t).find(
    (cnf) => cnf.name === dimensionName,
  )!;
  const title = dimConfig ? dimConfig.label : dimensionName;
  const topPos = index * 32 + 164; // index * btn height + offset top of window

  return (
    <ToolContainerDraggable
      startPosition={{ left: 50, top: topPos }}
      minWidth={180}
      title={title}
      isOpen={isOpen}
      data-testid={`multidimensionContent-${title}`}
      onClose={onClose}
      bounds="parent"
      onMouseDown={onMouseDown}
      order={order}
      source={source}
    >
      <div
        style={{
          padding: 4,
        }}
      >
        <Grid container direction="row">
          {children}
        </Grid>
      </div>
    </ToolContainerDraggable>
  );
};
export default DimensionSelectDialog;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useState } from 'react';
import { Animation } from '@opengeoweb/theme';
import { useTimesliderTranslation } from '../../../utils/i18n';
import { TimeSliderMenu } from '../TimeSliderMenu/TimeSliderMenu';
import { AnimationLength } from '../../TimeSlider/timeSliderUtils';

const marks = [
  { value: AnimationLength.Minutes15, text: 'timeslider-min', count: 15 },
  { value: AnimationLength.Minutes30, text: 'timeslider-min', count: 30 },
  { value: AnimationLength.Hours1, text: 'timeslider-hour', count: 1 },
  { value: AnimationLength.Hours2, text: 'timeslider-hour', count: 2 },
  { value: AnimationLength.Hours3, text: 'timeslider-hour', count: 3 },
  { value: AnimationLength.Hours6, text: 'timeslider-hour', count: 6 },
  { value: AnimationLength.Hours12, text: 'timeslider-hour', count: 12 },
  { value: AnimationLength.Hours24, text: 'timeslider-hour', count: 24 },
].sort((a, b) => b.value - a.value);

interface AnimationLengthButtonProps {
  disabled?: boolean;
  animationLength?: AnimationLength;
  isAuto?: boolean;
  layerHasTimeStep?: boolean;
  onChangeAnimationLength?: (length: number) => void;
  toggleAnimationLengthAuto?: () => void;
}

export const AnimationLengthButton = ({
  disabled = false,
  animationLength = AnimationLength.Hours1,
  isAuto = false,
  layerHasTimeStep,
  onChangeAnimationLength,
  toggleAnimationLengthAuto,
}: AnimationLengthButtonProps): React.ReactElement => {
  const { t } = useTimesliderTranslation();

  const translatedMarks = marks.map((mark) => ({
    ...mark,
    label: `${mark.count} ${t(mark.text)}`,
  }));

  const onChangeSliderValue = (newSliderValue: number): void => {
    const newLength = newSliderValue ? Math.round(newSliderValue) : 0;
    onChangeAnimationLength && onChangeAnimationLength(newLength);
  };
  const [open, setOpen] = useState(false);

  return (
    <TimeSliderMenu
      onChangeSliderValue={onChangeSliderValue}
      icon={<Animation data-testid="animationLengthButton" />}
      marks={translatedMarks}
      title={t('timeslider-animation')}
      isAutoSelected={isAuto}
      isAutoDisabled={!layerHasTimeStep}
      handleAutoClick={toggleAnimationLengthAuto}
      allOptionsDisabled={disabled}
      value={animationLength}
      displayCurrentLength
      open={open}
      setOpen={setOpen}
    />
  );
};

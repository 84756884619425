/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Resizable, ToolContainer } from '@opengeoweb/shared';

export const ResizeMenu: React.FC<{
  onCloseMenu?: () => void;
  title: string;
  children: React.ReactNode;
}> = ({ onCloseMenu, title, children }) => {
  return (
    <Resizable
      defaultSize={{
        width: 380,
        height: '90%',
      }}
      maxWidth="100%"
      minHeight={110}
      minWidth={280}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      enable={{
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
    >
      <ToolContainer
        title={title}
        onClose={onCloseMenu}
        style={{ width: '100%', height: '100%' }}
        onClick={(event): void => {
          event.stopPropagation();
        }}
      >
        {children}
      </ToolContainer>
    </Resizable>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ReactHookFormDateTime } from '@opengeoweb/form-fields';

import { dateUtils } from '@opengeoweb/shared';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { styles } from '../ProductForm.styles';
import { FormFieldProps } from '../../../types';
import { getFieldLabel } from '../utils';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

interface ObservationForecastTimeProps extends FormFieldProps {
  helperText: string;
}

const goZeroSeconds = (val: string): string => {
  return dateUtils.dateToString(
    dateUtils.set(dateUtils.utc(val), { seconds: 0, milliseconds: 0 }),
  )!;
};

const ObservationForecastTime: React.FC<ObservationForecastTimeProps> = ({
  isDisabled,
  isReadOnly,
  helperText,
  onChange = (): void => {},
}: ObservationForecastTimeProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { watch } = useFormContext();
  const label = getFieldLabel(t('date-and-time'), isReadOnly!);

  const { isShortTest } = useShortTestHelpers();
  const fieldsDisabled = isDisabled || isShortTest();

  const forecastValidationMessage = t('surface-visibility-between-time');

  return !isReadOnly || watch('observationOrForecastTime') !== null ? (
    <ProductFormFieldLayout title={t('at')}>
      <ReactHookFormDateTime
        disablePast={watch('isObservationOrForecast') === 'FCST'}
        disableFuture={watch('isObservationOrForecast') === 'OBS'}
        name="observationOrForecastTime"
        label={label}
        rules={{
          validate: {
            isValidDate: dateUtils.isValidIsoDateString,

            isBefore: (val): boolean | string =>
              watch('isObservationOrForecast') === 'OBS' && val
                ? dateUtils.isBefore(val, dateUtils.getNowUtc()) ||
                  t('observation-forecast-error')
                : true,

            isBetween: (val): boolean | string =>
              watch('isObservationOrForecast') === 'FCST' && val
                ? dateUtils.isBetween(
                    dateUtils.isoStringToDate(val)!,
                    dateUtils.isoStringToDate(
                      goZeroSeconds(watch('validDateStart')),
                    )!,
                    dateUtils.isoStringToDate(
                      goZeroSeconds(watch('validDateEnd')),
                    )!,
                    '[]',
                  ) || forecastValidationMessage
                : true,
          },
        }}
        disabled={fieldsDisabled}
        isReadOnly={isReadOnly}
        helperText={helperText}
        onChange={(): void => onChange()}
        sx={styles.helperText}
        data-testid="obs-fcst-time"
      />
    </ProductFormFieldLayout>
  ) : null;
};

export default ObservationForecastTime;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  uiActions,
  uiTypes,
  uiSelectors,
  CoreAppStore,
} from '@opengeoweb/store';
import { ParameterInfoButton } from './ParameterInfoButton';
import { Parameter } from '../../TimeSeries/types';
import { timeSeriesActions, timeSeriesSelectors } from '../../../store';
import { TimeSeriesModuleState } from '../../../store/types';

interface ParameterInfoButtonProps {
  parameter: Parameter;
  source?: uiTypes.Source;
}

export const ParameterInfoButtonConnect: React.FC<ParameterInfoButtonProps> = ({
  parameter,
  source = 'app',
}: ParameterInfoButtonProps) => {
  const dispatch = useDispatch();

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, uiTypes.DialogTypes.TimeseriesInfo),
  );

  const currentParameterDisplayed = useSelector(
    (store: TimeSeriesModuleState) =>
      timeSeriesSelectors.getParameterInfoParameter(store),
  );

  const isActive = parameter.id
    ? parameter.id === currentParameterDisplayed?.id
    : parameter.propertyName === currentParameterDisplayed?.propertyName &&
      parameter.serviceId === currentParameterDisplayed?.serviceId &&
      parameter.collectionId === currentParameterDisplayed?.collectionId;

  const toggleInfoDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: uiTypes.DialogTypes.TimeseriesInfo,
        mapId: '',
        setOpen: !isOpenInStore,
        source,
      }),
    );
  }, [dispatch, isOpenInStore, source]);

  const setCurrentParameterInfoDisplayed = React.useCallback((): void => {
    dispatch(
      timeSeriesActions.setCurrentParameterInfoDisplayed({
        parameter: parameter.id || parameter,
      }),
    );

    dispatch(
      uiActions.orderDialog({
        type: uiTypes.DialogTypes.TimeseriesInfo,
      }),
    );
  }, [dispatch, parameter]);

  const onClick = useCallback((): void => {
    if (isActive) {
      toggleInfoDialog();
    }
    if (!isActive && isOpenInStore) {
      setCurrentParameterInfoDisplayed();
    }
    if (!isActive && !isOpenInStore) {
      setCurrentParameterInfoDisplayed();
      toggleInfoDialog();
    }
  }, [
    isActive,
    toggleInfoDialog,
    isOpenInStore,
    setCurrentParameterInfoDisplayed,
  ]);

  return (
    <ParameterInfoButton
      isActive={isActive}
      isOpenInStore={isOpenInStore}
      onClick={onClick}
    />
  );
};

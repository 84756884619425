/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { dateUtils } from '@opengeoweb/shared';
import { timestampToPixel } from '../TimeSlider/timeSliderUtils';

/**
 * @param x The pin location
 * @param selectedTimePx The selected px
 * @param width The default width of a hoverable icon (usually 24px)
 * @returns
 */
export const isSelectedTimeIconArea = (
  x: number,
  selectedTimePx: number,
  width: number,
): boolean => {
  return x >= selectedTimePx && x <= selectedTimePx + width / 2;
};
/**
 * @param x The pin location
 * @param selectedTimePx The selected px
 * @param width The default width of a hoverable icon (usually 24px)
 * @returns
 */
export const isRightAnimationIconArea = (
  x: number,
  rightMarkerPx: number,
  width: number,
): boolean => {
  return x >= rightMarkerPx - width && x <= rightMarkerPx;
};

export const isInsideAnimationArea = (
  x: number,
  leftMarkerPx: number,
  rightMarkerPx: number,
): boolean => {
  const isInsideOnLeftSide = x >= leftMarkerPx;
  const isInsideOnRightSide = x <= rightMarkerPx;
  return isInsideOnLeftSide && isInsideOnRightSide;
};
/**
 * @param x The pin location
 * @param selectedTimePx The selected px
 * @param width The default width of a hoverable icon (usually 24px)
 * @returns
 */
export const isLeftAnimationIconArea = (
  x: number,
  leftMarkerPx: number,
  width: number,
): boolean => {
  return x >= leftMarkerPx && x <= leftMarkerPx + width;
};

export function convertStringTimeToUnix(
  time: string | undefined,
): number | undefined {
  return time ? dateUtils.unix(new Date(time)) : undefined;
}

export const timeValues = [
  3600, 10800, 21600, 43200, 86400, 172800, 604800, 1209600, 2592000, 7776000,
  31536000,
];

export const TIME_SLIDER_LEGEND_HEIGHT = 24;
export const DRAG_AREA_WIDTH = 24;

export type ShowTooltip =
  | 'animationStart'
  | 'animationEnd'
  | 'mousePointer'
  | undefined;

export const isDraggingStartWithinBounds = (
  endPos: number,
  mousePosition: number,
): boolean => {
  const startAnimationMaxPosition = endPos - DRAG_AREA_WIDTH * 2;
  return mousePosition >= startAnimationMaxPosition || mousePosition <= 0;
};

export const isDraggingEndWithinBounds = (
  startPos: number,
  mousePosition: number,
  canvasWidth: number,
): boolean => {
  const endAnimationMinimumPosition = startPos + DRAG_AREA_WIDTH * 2;
  return (
    endAnimationMinimumPosition >= mousePosition || mousePosition >= canvasWidth
  );
};

export const getTimeSliderLegendId = (mapId: string): string => {
  return `timeSliderLegend_${mapId}`;
};

export const getTimeSliderLegendElement = (mapId: string): Element | null => {
  return document.querySelector(`.timeSliderLegend_${mapId}`);
};

export const getPixelsBetweenViewportAndTimesliderOnLeft = (
  mapId: string,
): number | undefined => {
  const timeSliderLegendElement = document.querySelector(
    `.timeSliderLegend_${mapId}`,
  );
  return timeSliderLegendElement?.getBoundingClientRect()['left'];
};

export function getPositions(
  width: number,
  unfilteredSelectedTime: number | undefined,
  centerTime: number,
  secondsPerPx: number,
  start: number,
  end: number,
): {
  startAnimationPosition: number;
  endAnimationPosition: number;
  selectedTimePosition: number;
} {
  const selectedTimePosition = timestampToPixel(
    unfilteredSelectedTime!,
    centerTime,
    width,
    secondsPerPx,
  );
  const endAnimationPosition = timestampToPixel(
    end,
    centerTime,
    width,
    secondsPerPx,
  );
  const startAnimationPosition = timestampToPixel(
    start,
    centerTime,
    width,
    secondsPerPx,
  );
  return {
    startAnimationPosition,
    endAnimationPosition,
    selectedTimePosition,
  };
}

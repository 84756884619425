/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import * as turf from '@turf/turf';
import type { CoreAppStore } from '../types';
import { SYNCGROUPS_TYPE_SETTIME } from './syncGroups/constants';
import type {
  LinkedState,
  SynchronizationGroupState,
  SynchronizationSource,
} from './syncGroups/types';
import type { SetTimePayload } from './types';
import { selectorMemoizationOptions } from '../utils';

const synchronizationGroupStore = (
  store: CoreAppStore,
): SynchronizationGroupState =>
  store && store.syncGroups ? store.syncGroups : null!;

export const getSynchronizationGroupStore = createSelector(
  synchronizationGroupStore,
  (store) => store,
  selectorMemoizationOptions,
);

/**
 * Returns the synchronization source by id
 * @param store The app  store
 * @param id The source id to find
 */
const getSyncSourceBySourceId = (
  state: SynchronizationGroupState,
  id: string,
): SynchronizationSource => {
  if (state && state.sources) {
    const { sources } = state;
    if (sources.byId[id]) {
      return sources.byId[id];
    }
  }
  return null!;
};

export const getTime = createSelector(
  getSyncSourceBySourceId,
  (store) =>
    store && store.payloadByType && store.payloadByType[SYNCGROUPS_TYPE_SETTIME]
      ? (store.payloadByType[SYNCGROUPS_TYPE_SETTIME] as SetTimePayload).value
      : null,
  selectorMemoizationOptions,
);

const selectLinkedState = createSelector(
  synchronizationGroupStore,
  (syncState) => {
    return syncState?.linkedState || { links: {}, sharedData: {} };
  },
);

export const getLinkedMaps = createSelector(
  [selectLinkedState, (store, panelId: string): string => panelId],
  (linkedState, panelId): string[] => linkedState.links[panelId] || [],
  selectorMemoizationOptions,
);

export const getLinkedFeatureProviders = (
  linkedState: LinkedState,
  mapId: string,
): string[] =>
  Object.keys(linkedState.links).filter((panelId) =>
    linkedState.links[panelId].find((id) => id === mapId),
  );

export const selectSharedData = createSelector(
  [selectLinkedState, (store, panelId: string): string => panelId],
  (linkedState, panelId) => {
    const sharedDataForPanel = linkedState.sharedData[panelId];

    return sharedDataForPanel || {};
  },
);

export const selectLinkedPanelId = createSelector(
  [selectLinkedState, (_, mapViewId: string): string => mapViewId],
  (linkedState, mapViewId): string | undefined => {
    if (!linkedState || !linkedState.links) {
      return undefined;
    }

    // Find the panelId where mapView(s) is linked
    const panelId = Object.keys(linkedState.links).find((panelId) =>
      linkedState.links[panelId]?.includes(mapViewId),
    );

    return panelId;
  },
);

export const selectMapViewsForPanelId = createSelector(
  [
    (linkedState: LinkedState, panelId: string): string[] =>
      linkedState.links[panelId],
  ],
  (mapViews): string[] | undefined => {
    if (!mapViews) {
      return undefined;
    }
    return mapViews;
  },
);

export const selectLinkedFeatures = createSelector(
  [(linkedState): LinkedState => linkedState, getLinkedFeatureProviders],
  (linkedState, featureProviders) => {
    const features = featureProviders
      .flatMap(
        (providerId) => linkedState.sharedData[providerId]?.features || [],
      )
      .filter((f) => f !== undefined);
    return features;
  },
);

export const selectLinkedFormFeatures = createSelector(
  [(linkedState): LinkedState => linkedState, getLinkedFeatureProviders],
  (linkedState, featureProviders) => {
    const features = featureProviders
      .flatMap(
        (providerId) => linkedState.sharedData[providerId]?.formFeatures || [],
      )
      .filter((f) => f !== undefined);
    return features;
  },
);

export const getSelectedFeature = createSelector(
  [
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    (state: SynchronizationGroupState, mapId: string) => {
      const sharedData = state.linkedState?.sharedData[mapId];
      const { features = [], selectedFeatureId } = sharedData || {};
      return { features, selectedFeatureId };
    },
  ],
  ({ features, selectedFeatureId }) => {
    const selectedFeature = features[0]?.geoJSON.features.find(
      (feature) => feature.id === selectedFeatureId,
    );

    if (selectedFeature && selectedFeature.geometry.type === 'Point') {
      const coordinates = selectedFeature.geometry.coordinates as [
        number,
        number,
      ];
      return {
        lat: coordinates[1],
        lon: coordinates[0],
        id: selectedFeature.id as string,
        serviceId: selectedFeature.properties?.serviceId,
        collectionId: selectedFeature.properties?.collectionId,
        name: selectedFeature.properties?.name,
      };
    }

    if (selectedFeature) {
      const center = turf.center(selectedFeature);

      return {
        lat: center.geometry.coordinates[1],
        lon: center.geometry.coordinates[0],
        id: selectedFeature.id as string,
        serviceId: selectedFeature.properties?.serviceId,
        collectionId: selectedFeature.properties?.collectionId,
        name: selectedFeature.properties?.name,
      };
    }

    return null;
  },
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { TimeSeriesService } from '@opengeoweb/shared';
import { chain } from 'lodash';
import { Parameter, Plot, PlotPreset } from '../components/TimeSeries/types';
import {
  ServiceFilterChipsObjectEntities,
  ServiceFilterChipsType,
  TimeSeriesModuleState,
  TimeSeriesPreset,
  TimeSeriesServicePopupObject,
  TimeSeriesStoreType,
} from './types';
import {
  timeseriesSelectServiceFilterChipsAdapter,
  initialState,
} from './reducer';
import { getServiceById } from '../utils/edrUtils';

export const getPlotState = (
  state: TimeSeriesModuleState,
): PlotPreset | undefined => {
  return state.timeSeries?.plotPreset;
};

export const getTimeSeriesState = (
  state: TimeSeriesModuleState,
): TimeSeriesStoreType | undefined => {
  return state.timeSeries;
};

export const getMapId = (state: TimeSeriesModuleState): string | undefined =>
  state.timeSeries?.plotPreset?.mapId;

export const getPlotWithParameters = createSelector(
  getPlotState,
  (_: TimeSeriesModuleState, selectPlotId: string) => selectPlotId,
  (plotPreset, selectPlotId: string): Plot | undefined => {
    if (!plotPreset || !selectPlotId) {
      return undefined;
    }

    const plot = plotPreset.plots.find((plot) => plot.plotId === selectPlotId);
    if (!plot) {
      return undefined;
    }

    const parameters = plotPreset.parameters.filter(
      (parameter) => parameter.plotId === selectPlotId,
    );

    return { ...plot, parameters };
  },
);

export const getService = createSelector(
  getTimeSeriesState,
  (_: TimeSeriesModuleState, serviceId: string | undefined) => serviceId,
  (
    timeSeriesState: TimeSeriesStoreType | undefined,
    serviceId: string | undefined,
  ): TimeSeriesService | undefined => {
    return timeSeriesState?.services
      ? getServiceById(timeSeriesState.services, serviceId)
      : undefined;
  },
);

export const getServices = createSelector(
  getTimeSeriesState,
  (
    timeSeriesState: TimeSeriesStoreType | undefined,
  ): TimeSeriesService[] | undefined => {
    return timeSeriesState?.services;
  },
);

/**
 * Returns search filter string
 *
 * Example getSearchFilter(store);
 * @param {object} store store: object - store object
 * @returns {array} returnType: string
 */
export const getSearchFilter = createSelector(
  getTimeSeriesState,
  (store): string => store?.timeseriesSelect?.filters?.searchFilter || '',
);

/**
 * Returns all currently used parameters
 *
 * Example getParametersInUse(store);
 * @param {object} store store: object - store object
 * @returns {Parameter[]} returnType: Parameter[]
 */
export const getParametersInUse = createSelector(
  getTimeSeriesState,
  (store): Parameter[] => store?.plotPreset?.parameters || [],
);

/**
 * Returns active services array
 *
 * Example getActiveServices(store);
 * @param {object} store store: object - store object
 * @returns {ActiveServiceObjectEntities} returnType: ActiveServiceObjectEntitiesobject of active services
 */
// cast to usable type - selectEntities returns Dictionary<ActiveServiceObject> which is not usable inside of the code
export const getServiceFilterChips = (
  store: TimeSeriesModuleState,
): ServiceFilterChipsObjectEntities =>
  getServiceFilterChipsDictionary(store) as ServiceFilterChipsObjectEntities;

export const {
  selectEntities: getServiceFilterChipsDictionary,
  selectById: getServiceFilterChipsById,
} = timeseriesSelectServiceFilterChipsAdapter.getSelectors(
  (store: TimeSeriesModuleState): ServiceFilterChipsType => {
    return (
      store?.timeSeries?.timeseriesSelect?.filters?.serviceFilterChips || {
        entities: {},
        ids: [],
      }
    );
  },
);

/**
 * Returns whether all service filter chips are enabled
 *
 * Example getAllFilterChipsEnabled(store);
 * @param {object} store store: object - store object
 * @returns {boolean} returnType: boolean
 */
export const getAllFilterChipsEnabled = createSelector(
  getTimeSeriesState,
  (store): boolean =>
    store?.timeseriesSelect?.filters?.allServiceFilterChipsEnabled || false,
);
/**
 * Returns current parameter id displayed in the info dialog
 *
 * Example getCurrentParameterInfoDisplayed(store);
 * @param {object} store store: object - store object
 * @returns {string} returnType: string
 */
export const getCurrentParameterInfoDisplayed = createSelector(
  getTimeSeriesState,
  (store): string =>
    typeof store?.timeseriesSelect?.currentParameterInfo === 'string'
      ? store.timeseriesSelect.currentParameterInfo
      : '',
);

/**
 * Returns parameter for the parameterInfo window
 *
 * Example getParameterInfoParameter(store);
 * @param {object} store store: object - store object
 * @returns {Parameter} returnType: Parameter
 */
export const getParameterInfoParameter = createSelector(
  getTimeSeriesState,
  (store: TimeSeriesStoreType | undefined): Parameter | undefined => {
    if (typeof store?.timeseriesSelect?.currentParameterInfo === 'string') {
      if (store.plotPreset) {
        return store.plotPreset.parameters?.find(
          (parameter) =>
            parameter.id === store!.timeseriesSelect!.currentParameterInfo,
        );
      }
      return undefined;
    }
    return store?.timeseriesSelect?.currentParameterInfo;
  },
);

/**
 * Returns parameter by id
 *
 * Example getParameterById(store);
 * @param {object} store store: object - store object
 * @param {string} parameterId parameterId
 * @returns {Parameter} returnType: Parameter
 */
export const getParameterById = createSelector(
  getTimeSeriesState,
  (store: TimeSeriesModuleState, parameterId: string) => parameterId,
  (
    store: TimeSeriesStoreType | undefined,
    parameterId: string,
  ): Parameter | undefined => {
    if (store?.plotPreset) {
      return store.plotPreset.parameters?.find(
        (parameter) => parameter.id === parameterId,
      );
    }
    return undefined;
  },
);

export const getServicePopupDetails = createSelector(
  getTimeSeriesState,
  (store): TimeSeriesServicePopupObject =>
    store?.timeseriesSelect?.servicePopup ||
    initialState.timeseriesSelect!.servicePopup,
);

export const getTimeSeriesPreset = createSelector(
  getPlotState,
  getServices,
  (plotState, services): TimeSeriesPreset | undefined => {
    if (!plotState || !services) {
      return undefined;
    }
    const presetParameters = plotState.parameters.map((parameter) => {
      const presetParam = { ...parameter };
      delete presetParam.instanceId;
      return presetParam;
    });
    const usedServicesIds = chain(presetParameters)
      .keyBy((param) => param.serviceId)
      .mapValues(() => true)
      .value();
    const usedServices = services.filter(
      (service) => usedServicesIds[service.id],
    );
    return {
      plotPreset: {
        ...plotState,
        parameters: presetParameters,
      },
      services: usedServices,
    };
  },
);

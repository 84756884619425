/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Link, Typography } from '@mui/material';
import { CustomDialog } from '@opengeoweb/shared';
import { Trans } from 'react-i18next';
import image from '../../assets/firefox_container_queries.png';
import { useGeowebTranslation } from '../../i18n';

interface MessageProps {
  isFirefox?: boolean;
}

const Message: React.FC<MessageProps> = ({
  isFirefox = false,
}: MessageProps) => {
  const { t } = useGeowebTranslation();
  return (
    <>
      <Typography>{t('geoweb-browser-not-compatible')}</Typography>
      <br />
      {isFirefox ? (
        <Typography data-testid="firefoxMessage">
          {t('geoweb-enable-container-queries')}
          <br />
          <br />
          {t('geoweb-enable-container-queries-1')}
          <br />
          {t('geoweb-enable-container-queries-2')}
          <br />
          {t('geoweb-enable-container-queries-3')}
          <br />
          {t('geoweb-enable-container-queries-4')}
          <br />
          <img
            alt="Firefox container queries configuration"
            src={image}
            width="528"
          />
          <br />
          {t('geoweb-enable-container-queries-5')}
        </Typography>
      ) : (
        <Typography>
          <Trans i18nKey="geoweb-upgrade-to-latest-version">
            {t('geoweb-upgrade-to-latest-version', { latestVersion: '' })}
            <Link href="https://www.google.com/chrome/" target="_blank">
              Chrome
            </Link>
            ,{' '}
            <Link
              href="https://support.apple.com/downloads/safari"
              target="_blank"
            >
              Safari
            </Link>{' '}
            {t('geoweb-upgrade-to-latest-or')}
            <Link href="https://www.microsoft.com/edge" target="_blank">
              Microsoft Edge
            </Link>
            .
          </Trans>
        </Typography>
      )}
    </>
  );
};

const BrowserIssueDialog: React.FC = () => {
  const { t } = useGeowebTranslation();
  const hasContainerQueriesSupport =
    'container' in document.documentElement.style;
  const isFirefox =
    window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const [open, setOpen] = React.useState<boolean>(!hasContainerQueriesSupport);

  if (hasContainerQueriesSupport) {
    return null;
  }

  return (
    <CustomDialog
      title={t('geoweb-browser-issue')}
      open={open}
      onClose={(): void => {
        setOpen(false);
      }}
    >
      <Message isFirefox={isFirefox} />
    </CustomDialog>
  );
};

export default BrowserIssueDialog;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Box } from '@mui/system';
import { usePoller } from '@opengeoweb/shared';
import React from 'react';

import {
  AirmetFromBackend,
  ProductConfig,
  ProductFromBackend,
  ProductType,
  SigmetFromBackend,
} from '../../types';
import { ProductFormDialog } from '../ProductForms/ProductFormDialog';
import { ProductList } from '../ProductList';
import { renewAirmet, renewSigmet, useProductApi } from './utils';

export interface MetInfoWrapperProps {
  productType: ProductType;
  productConfig: ProductConfig;
}

/**
 * Shows the list of Sigmets or Airmets and allows to create, edit and view Sigmets/Airmets.
 * Needs to be wrapped in the ApiProvider.
 * Pass in either 'sigmet' or 'airmet' as prop to determine the mode
 * @param {string} productType productType: string - either 'sigmet' or 'airmet'. Determines mode in which wrapper is loaded
 *
 * @example
 * ```<ApiProvider baseURL="http://test.com" appURL="http://app.com" auth={auth} onSetAuth={onSetAuth} createApi={api.createApi} authTokenURL="url/tokenrefresh"> <MetInfoWrapper productType="sigmet"/> </ApiProvider>```
 */

const MetInfoWrapper: React.FC<MetInfoWrapperProps> = ({
  productType,
  productConfig,
}: MetInfoWrapperProps) => {
  const {
    isLoading: isLoadingList,
    error: errorList,
    result: productList,
    fetchNewData: fetchNewProductList,
  } = useProductApi(productType);

  const [isFormDialogOpen, setIsFormDialogOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<
    ProductFromBackend | undefined
  >();

  usePoller([productList, errorList], fetchNewProductList, 60000);

  React.useEffect(() => {
    if (selectedProduct && !isFormDialogOpen) {
      toggleDialogOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const toggleDialogOpen = (shouldRefreshList = true): void => {
    // Refresh list on closing dialog
    if (isFormDialogOpen === true && shouldRefreshList) {
      void fetchNewProductList();
    }
    setIsFormDialogOpen(!isFormDialogOpen);
  };

  const handleProductRowClick = (product: ProductFromBackend): void => {
    if (selectedProduct === product) {
      toggleDialogOpen();
    } else {
      setSelectedProduct(product);
    }
  };

  const handleNewProductClick = (): void => {
    setSelectedProduct(undefined);
    toggleDialogOpen();
  };

  const handleRenewProductClick = (oldProduct: ProductFromBackend): void => {
    if (productType === 'sigmet') {
      setSelectedProduct(
        renewSigmet(oldProduct as SigmetFromBackend, productConfig),
      );
    } else {
      setSelectedProduct(
        renewAirmet(oldProduct as AirmetFromBackend, productConfig),
      );
    }
  };

  return (
    <Box
      data-testid="sigmetAirmetComponent"
      sx={{ padding: '24px', height: '100%', overflowY: 'auto' }}
    >
      <ProductList
        data-testid="sigmetAirmetList"
        productList={productList}
        productType={productType}
        productConfig={productConfig}
        isLoading={isLoadingList}
        error={errorList}
        onClickProductRow={handleProductRowClick}
        onClickNewProduct={handleNewProductClick}
      />
      <ProductFormDialog
        productType={productType}
        productConfig={productConfig!}
        isOpen={isFormDialogOpen}
        toggleDialogStatus={toggleDialogOpen}
        handleRenewProductClick={handleRenewProductClick}
        productListItem={selectedProduct}
      />
    </Box>
  );
};

export default MetInfoWrapper;

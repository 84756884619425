/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
export const DATE_FORMAT_DATEPICKER = 'dd/MM/yyyy HH:mm';
export const DATE_FORMAT_UTC = "yyyy-MM-dd'T'HH:mm:ss'Z'";
export const DATE_FORMAT_YEAR = 'yyyy-MM-dd';
export const DATE_FORMAT_HOURS = 'HH:mm';
export const DATE_FORMAT_NAME_OF_DAY_MONTH = 'eee dd MMM HH:mm';
export const DATE_FORMAT_NAME_OF_MONTH = 'dd MMM yyyy, HH:mm';
export const DATE_FORMAT_NAME_OF_DAY = 'eee dd/MM/yyyy HH:mm';

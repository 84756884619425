/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  ApiModuleProps,
  AviationProductApiWrapper,
} from './AviationProductApiWrapper';

type SigmetComponentType = 'SigmetModule';

export interface SigmetComponentsLookUpPayload {
  componentType: SigmetComponentType;
  id: string;
  title?: string;
  initialProps: Record<string, never>;
}

export const SigmetComponentsLookUp = (
  payload: SigmetComponentsLookUpPayload,
  { config, auth, onSetAuth }: ApiModuleProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ConnectedModuleWrapper: any = null,
): React.ReactElement => {
  const { componentType } = payload;
  switch (componentType) {
    case 'SigmetModule':
      if (ConnectedModuleWrapper) {
        return (
          <ConnectedModuleWrapper
            component={AviationProductApiWrapper}
            payload={payload}
            config={config}
            auth={auth}
            onSetAuth={onSetAuth}
            productType="sigmet"
          />
        );
      }
      return (
        <AviationProductApiWrapper
          config={config}
          auth={auth}
          onSetAuth={onSetAuth}
          productType="sigmet"
        />
      );

    default:
      return null as unknown as React.ReactElement;
  }
};

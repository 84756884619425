/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Snackbar, SnackbarProps } from '@mui/material';
import { Close } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import { useSnackbarTranslation } from '../../utils/i18n';
import { snackbarTypes } from '../../store';
import { SnackbarMessage } from '../../store/types';

interface SnackBarWrapperProps extends SnackbarProps {
  isOpen: boolean;
  id: string;
  snackbarMessage?: snackbarTypes.SnackbarMessage;
  onCloseSnackbar: () => void;
}

const getMessageString = (
  t: TFunction,
  snackbarMessage?: SnackbarMessage,
): string => {
  if (!snackbarMessage) {
    return '';
  }
  return snackbarMessage.type ===
    snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE
    ? t(snackbarMessage.key, snackbarMessage.params)
    : snackbarMessage.message;
};

export const SnackbarWrapper: React.FC<SnackBarWrapperProps> = ({
  children,
  isOpen,
  onCloseSnackbar,
  id,
  snackbarMessage,
  ...props
}: SnackBarWrapperProps) => {
  const { t } = useSnackbarTranslation();
  const messageString = getMessageString(t, snackbarMessage);
  return (
    <>
      {children}
      <Snackbar
        message={messageString}
        data-testid="snackbarComponent"
        open={isOpen}
        onClose={onCloseSnackbar}
        autoHideDuration={null}
        key={id}
        ClickAwayListenerProps={{ mouseEvent: false }}
        action={
          <CustomIconButton
            aria-label={t('snackbar-close')}
            data-testid="snackbarCloseButton"
            onClick={onCloseSnackbar}
            size="large"
            sx={{
              '&.MuiButtonBase-root': {
                borderRadius: 20,
                color: 'geowebColors.snackbar.action',
                '&:hover': {
                  color: 'geowebColors.snackbar.action',
                  backgroundColor: 'geowebColors.snackbar.actionHover.rgba',
                },
              },
            }}
          >
            <Close />
          </CustomIconButton>
        }
        {...props}
      />
    </>
  );
};

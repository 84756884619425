/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { dateUtils } from '@opengeoweb/shared';

import { Bulletin, BulletinType } from '../../types';
import { useSpaceweatherTranslation } from '../../utils/i18n';

const styles = {
  bulletinContentSmall: {
    height: '100%',
    overflowY: 'scroll',
  },
  bulletinContentLarge: {
    height: '500px',
    overflowY: 'scroll',
  },
};

export interface BulletinViewerProps {
  bulletinType: BulletinType;
  smallContent?: boolean;
  bulletin: Bulletin;
}

export const BulletinViewer: React.FC<BulletinViewerProps> = ({
  smallContent = true,
  bulletinType,
  bulletin,
}: BulletinViewerProps) => {
  const { t } = useSpaceweatherTranslation();
  let bulletinContent = '';
  if (
    bulletin.message &&
    bulletin.message.overview &&
    bulletin.message.overview.timestamp &&
    bulletin.message.overview.content &&
    bulletin.message.overview.simplified_content
  ) {
    bulletinContent = `<p style="text-align: right; margin: 0px"><b>${t('bulletin-published')} </b>${dateUtils.dateToString(
      dateUtils.utc(bulletin.message.overview.timestamp),
      `${dateUtils.DATE_FORMAT_DATEPICKER}' UTC'`,
    )}</p>`;

    bulletinContent +=
      bulletinType === BulletinType.technical
        ? bulletin.message.overview.content
        : bulletin.message.overview.simplified_content;
  }

  return (
    <Box
      sx={
        smallContent ? styles.bulletinContentSmall : styles.bulletinContentLarge
      }
      data-testid="bulletinviewer"
    >
      {bulletin && (
        <Typography
          data-testid="bulletinContent"
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: bulletinContent,
          }}
        />
      )}
    </Box>
  );
};

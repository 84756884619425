/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { ApiProvider, CreateApiProps } from '@opengeoweb/api';
import {
  getCurrentUrlLocation,
  getSessionStorageProvider,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import { ConfigType } from '@opengeoweb/shared';
import { useParams } from 'react-router-dom';
import { createApi as createRealApi, AppApi } from '../utils/api';
import { WorkspaceLayout } from './workspace/WorkspaceLayout';

interface WorkspaceProps {
  config?: ConfigType;
  createApi?: (props: CreateApiProps) => AppApi;
}

const Workspace: React.FC<WorkspaceProps> = ({
  config,
  createApi = createRealApi,
}: WorkspaceProps) => {
  const { auth, onSetAuth, isLoggedIn } = useAuthenticationContext();
  const params = useParams();

  const sessionStorageProvider = getSessionStorageProvider();
  const hasAuthenticated =
    sessionStorageProvider.getHasAuthenticated() === 'true';

  if (
    (config && !auth && !isLoggedIn && hasAuthenticated) ||
    (config?.GW_FEATURE_FORCE_AUTHENTICATION && !hasAuthenticated)
  ) {
    const callbackUrl = getCurrentUrlLocation(
      window.location.href,
      config.GW_APP_URL!,
    );
    sessionStorageProvider.setCallbackUrl(callbackUrl);

    window.location.assign('/login');
  }

  return (
    <ApiProvider
      auth={auth!}
      onSetAuth={onSetAuth}
      createApi={createApi}
      config={{
        appURL: config && config.GW_APP_URL,
        authTokenURL: config && config.GW_AUTH_TOKEN_URL,
        authClientId: config && config.GW_AUTH_CLIENT_ID,
      }}
    >
      <WorkspaceLayout
        workspaceId={params.workspaceId}
        config={config}
        auth={auth!}
        onSetAuth={onSetAuth}
      />
    </ApiProvider>
  );
};

export default Workspace;

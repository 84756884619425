/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import Typography from '@mui/material/Typography';
import { Dimension } from '@opengeoweb/webmap';
import { textStyleWithMargin } from './LayerInfoText';
import { LegendLayout } from '../Legend';
import { useWebmapReactTranslation } from '../../utils/i18n';

export interface LayerInfoLegendProps {
  title: string;
  name: string;
  dimensions: Dimension[];
  legendURL: string;
}

export const LayerInfoLegend: React.FC<LayerInfoLegendProps> = ({
  title,
  name,
  dimensions,
  legendURL,
}: LayerInfoLegendProps) => {
  const { t } = useWebmapReactTranslation();
  return (
    <>
      <Typography variant="subtitle2" sx={textStyleWithMargin}>
        {t('webmap-react-legend')}
      </Typography>
      <LegendLayout title={title} name={name} dimensions={dimensions}>
        {legendURL ? <img alt={title} src={legendURL} /> : '-'}
      </LegendLayout>
    </>
  );
};

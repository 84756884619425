/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { FC } from 'react';
import { Stack } from '@mui/material';
import { CustomTooltip, FilterChip } from '@opengeoweb/shared';
import { useWarningsTranslation } from '../../utils/i18n';
import ExpandableFilterChip from './ExpandableFilterChip';
import { FilterKeyType, PickedFilterState, UpdateFilter } from './filter-hooks';

const filterOrder: FilterKeyType[] = [
  'incident',
  'source',
  'domain',
  'phenomenon',
  'level',
];

interface FilterListProps {
  onClickAll?: (isChecked: boolean) => void;
  isAllSelected?: boolean;
  filterState: PickedFilterState;
  updateFilter: UpdateFilter;
}

const FilterList: FC<FilterListProps> = ({
  onClickAll = (): void => {},
  isAllSelected = true,
  filterState,
  updateFilter,
}: FilterListProps) => {
  const { t } = useWarningsTranslation();

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: 'block',
        '.MuiChip-root': {
          margin: 0,
          marginRight: 1,
          marginBottom: 1,
        },
      }}
    >
      <CustomTooltip
        title={isAllSelected ? t('filter-hide-all') : t('filter-show-all')}
        placement="bottom"
      >
        <span style={{ margin: 0 }}>
          <FilterChip
            id="all"
            label={t('filter-all')}
            isSelected={isAllSelected}
            handleClick={(): void => onClickAll(isAllSelected)}
            size="medium"
          />
        </span>
      </CustomTooltip>
      {filterOrder.map((filterId: FilterKeyType) => {
        return (
          <ExpandableFilterChip
            key={filterId}
            id={filterId}
            options={filterState[filterId]}
            updateFilter={updateFilter}
          />
        );
      })}
    </Stack>
  );
};

export default FilterList;

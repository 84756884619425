/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

export interface RadioButtonAndLabelProps {
  value: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
}

// A version of a radio button with label where the label and surrounding white space is not clickable
export const RadioButtonAndLabel: React.FC<RadioButtonAndLabelProps> = (
  props: RadioButtonAndLabelProps,
) => {
  const { value, label, disabled = true, ...restProps } = props;

  return (
    <FormControlLabel
      style={{ pointerEvents: 'none' }}
      value={value}
      control={<Radio style={{ pointerEvents: 'auto' }} />}
      label={label}
      disabled={disabled}
      {...restProps}
    />
  );
};

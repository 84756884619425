/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PROJECTION } from '@opengeoweb/shared';
import WMBBOX, { Bbox } from './WMBBOX';
/**
 * WMProjection Class
 * Keep all projection information, by a bbox and an srs value.
 */
export default class WMProjection {
  public bbox: WMBBOX;

  public srs: string;

  constructor() {
    this.bbox = new WMBBOX();
    this.srs = PROJECTION.EPSG_4326.value;
  }
}

export interface WMProjectionWH {
  bbox: Bbox;
  srs: string;
  width: number;
  height: number;
}

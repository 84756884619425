/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, Typography } from '@mui/material';
import {
  ReactHookFormHiddenInput,
  ReactHookFormTextField,
} from '@opengeoweb/form-fields';
import { AlertBanner } from '@opengeoweb/shared';
import React from 'react';

import { Exclamation } from '@opengeoweb/theme';
import {
  PresetAction,
  ViewPresetFormValues,
  WorkspaceSupportedComponentTypes,
} from '../../store/viewPresets/types';
import { emptyViewPreset } from '../../store/viewPresets/utils';
import { useWorkspaceTranslation } from '../../utils/i18n';

const validateTitle = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  // TODO: use translation from form-fields https://gitlab.com/opengeoweb/opengeoweb/-/issues/4240
  return !value.trim().length ? 'This field is required' : true;
};

const marginBottom = 2;

export interface ViewPresetsFormProps {
  viewComponentType: WorkspaceSupportedComponentTypes;
  error?: string;
  onSubmit?: () => void;
  action: PresetAction;
  formValues: ViewPresetFormValues;
}

const ViewPresetsForm: React.FC<ViewPresetsFormProps> = ({
  viewComponentType,
  error,
  onSubmit,
  action,
  formValues,
}: ViewPresetsFormProps) => {
  const fieldName = 'title';
  const { t } = useWorkspaceTranslation();

  const { title, ...otherFormValues } = {
    ...emptyViewPreset(viewComponentType),
    ...formValues,
  };
  const hiddenFields = Object.keys(otherFormValues).map((name) => ({
    name,
    value: otherFormValues[name as keyof typeof otherFormValues],
  }));

  React.useEffect(() => {
    // autoFocus
    const field = document.querySelector(
      `[name="${fieldName}"]`,
    ) as HTMLInputElement;
    field?.focus();
  }, []);

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit!();
  };

  return (
    <>
      {Boolean(error) && (
        <Box sx={{ mb: marginBottom }}>
          <AlertBanner title={error!} />
        </Box>
      )}
      {(action === PresetAction.SAVE_AS || action === PresetAction.EDIT) && (
        <form data-testid="map-preset-form" onSubmit={onSubmitForm}>
          <ReactHookFormTextField
            name="title"
            label={t('workspace-mappreset-dialog-title')}
            rules={{
              required: true,
              validate: {
                validateTitle,
              },
            }}
            defaultValue={title}
            sx={{ mb: marginBottom }}
          />
          <ReactHookFormTextField
            name="abstract"
            label={t('workspace-dialog-abstract')}
            rules={{
              required: false,
            }}
            multiline
            rows={3}
          />
          {hiddenFields.map(({ name, value }) => (
            <ReactHookFormHiddenInput
              key={name}
              name={name}
              defaultValue={value}
              data-testid={name}
            />
          ))}
        </form>
      )}
      {action === PresetAction.DELETE && (
        <>
          <Typography variant="body1">
            {t('workspace-mappreset-delete-message', { TITLE: title })}
          </Typography>
          <ReactHookFormHiddenInput
            key="title"
            name="title"
            defaultValue={title}
          />
        </>
      )}
      {action === PresetAction.RESET && (
        <Box>
          <Typography variant="body1">
            <Exclamation
              sx={{
                top: '6px',
                position: 'relative',
                color: 'geowebColors.functional.warning',
              }}
            />
            {t('workspace-dialog-confirm-reset-view-message')}
          </Typography>
          <ReactHookFormHiddenInput
            key="title"
            name="title"
            defaultValue={title}
          />
        </Box>
      )}
    </>
  );
};

export default ViewPresetsForm;

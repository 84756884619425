/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, LinearProgress } from '@mui/material';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import { AlertBanner, Filter } from '@opengeoweb/shared';
import { routerActions } from '@opengeoweb/store';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/types';
import { workspaceActions } from '../../store/workspace/reducer';
import * as workspaceSelectors from '../../store/workspace/selectors';
import { WorkspacePresetAction } from '../../store/workspace/types';
import { workspaceListSelectors } from '../../store/workspaceList';
import { workspaceListActions } from '../../store/workspaceList/reducer';
import {
  WorkspaceActionDialogType,
  WorkspaceListErrorCategory,
  WorkspaceListErrorType,
  WorkspaceListFilter,
} from '../../store/workspaceList/types';
import {
  constructFilterParams,
  getEmptyMapWorkspace,
} from '../../store/workspaceList/utils';
import { useWorkspaceTranslation } from '../../utils/i18n';
import { getWorkspaceRouteUrl } from '../../utils/routes';
import WorkspaceSelectList from './WorkspaceSelectList';

export const WorkspaceSelectListConnect: React.FC = () => {
  const { currentRole } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;
  const scope = !isAdmin ? 'user' : 'system';

  const currentView = useSelector((store: AppStore) =>
    workspaceSelectors.getWorkspaceState(store),
  );

  const workspacePresets = useSelector((store: AppStore) =>
    workspaceListSelectors.getWorkspaceList(store),
  );

  const workspaceListFilters = useSelector((store: AppStore) =>
    workspaceListSelectors.getWorkspaceListFilters(store, scope),
  );

  const workspaceSearchQuery = useSelector((store: AppStore) =>
    workspaceListSelectors.getWorkspaceListSearchQuery(store),
  );

  const isFetching = useSelector((store: AppStore) =>
    workspaceListSelectors.getIsWorkspaceListFetching(store),
  );

  const error = useSelector((store: AppStore) =>
    workspaceListSelectors.getWorkspaceListError(store),
  );

  const { isLoggedIn } = useAuthenticationContext();

  const dispatch = useDispatch();

  const { t } = useWorkspaceTranslation();

  const fetchWorkspaceList = React.useCallback((): void => {
    const filterParams = constructFilterParams(
      workspaceListFilters,
      workspaceSearchQuery,
    );

    dispatch(workspaceListActions.fetchWorkspaceList(filterParams));
  }, [dispatch, workspaceListFilters, workspaceSearchQuery]);

  const fetchWorkspace = React.useCallback(
    (workspaceId: string) => {
      dispatch(workspaceActions.fetchWorkspace({ workspaceId }));
    },
    [dispatch],
  );

  const setWorkspaceActionDialogOptions = React.useCallback(
    (dialogOptions: WorkspaceActionDialogType) => {
      dispatch(
        workspaceListActions.openWorkspaceActionDialogOptions(dialogOptions),
      );
    },
    [dispatch],
  );

  const toggleSelectFilterChip = React.useCallback(
    (id: string, isSelected: boolean) => {
      dispatch(workspaceListActions.toggleSelectFilterChip({ id, isSelected }));
    },
    [dispatch],
  );

  const setSelectAllFilterChip = React.useCallback(() => {
    dispatch(workspaceListActions.setSelectAllFilterChip());
  }, [dispatch]);

  const searchFilter = React.useCallback(
    (searchQuery: string) => {
      dispatch(workspaceListActions.searchFilter({ searchQuery }));
    },
    [dispatch],
  );

  // If we're loading the `New workspace` preset as defined in the FE, use this instead of retrieving from BE
  const onClickNewPreset = React.useCallback((): void => {
    dispatch(routerActions.navigateToUrl({ url: getWorkspaceRouteUrl() }));
    dispatch(
      workspaceListActions.toggleWorkspaceDialog({
        isWorkspaceListDialogOpen: false,
      }),
    );
    dispatch(
      workspaceActions.changeToEmptyMapWorkspace({
        newMapPresetText: t('workspace-mappreset-new'),
        newWorkspaceText: t('workspace-new'),
      }),
    );
  }, [dispatch, t]);

  const onClickWorkspacePreset = (id: string): void => {
    if (id === getEmptyMapWorkspace(t).id) {
      onClickNewPreset();
    } else if (!isFetching) {
      fetchWorkspace(id);
    }
  };

  const onClickFilterChip = (id: string, shouldSelect: boolean): void => {
    if (id === 'all') {
      if (shouldSelect) {
        setSelectAllFilterChip();
      }
    } else {
      toggleSelectFilterChip(id, shouldSelect);
    }
  };

  const onClickWorkspacePresetOption = (
    presetId: string,
    workspaceAction: WorkspacePresetAction,
  ): void => {
    // If affectedPreset is the FE defined `New workspace` - use the definition from FE instead of BE
    const affectedPreset =
      presetId === getEmptyMapWorkspace(t).id
        ? getEmptyMapWorkspace(t)
        : workspacePresets.find((preset) => preset.id === presetId);
    const presetTitle = affectedPreset?.title;
    switch (workspaceAction) {
      case WorkspacePresetAction.DUPLICATE:
      case WorkspacePresetAction.EDIT: {
        setWorkspaceActionDialogOptions({
          action: workspaceAction,
          presetId,
          formValues: {
            title: presetTitle,
            abstract: affectedPreset!.abstract,
            scope,
          },
        });
        break;
      }
      // case WorkspacePresetAction.SAVE: {
      // break;
      // }
      case WorkspacePresetAction.DELETE: {
        setWorkspaceActionDialogOptions({
          action: workspaceAction,
          presetId,
          formValues: { title: presetTitle, scope },
        });
        break;
      }
      case WorkspacePresetAction.DELETE_SYSTEM_PRESET: {
        setWorkspaceActionDialogOptions({
          action: workspaceAction,
          presetId,
          formValues: { title: presetTitle, scope },
        });
        break;
      }
      case WorkspacePresetAction.SHARE: {
        // TODO
        break;
      }
      default: {
        break;
      }
    }
  };

  const isAllSelected = workspaceListFilters.every(
    (filter: WorkspaceListFilter) => filter.isSelected,
  );

  const shouldHideNewWorkspace =
    !isAllSelected || workspaceSearchQuery.length > 0;

  return (
    <Box
      data-testid="workspace-selectlist-connect"
      style={{ height: '100%' }}
      tabIndex={0}
    >
      {isFetching && (
        <LinearProgress
          data-testid="loading-bar"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 0, left: 0 }}
        />
      )}
      {error && (
        <div style={{ whiteSpace: 'normal' }}>
          <AlertBanner
            title={t('workspace-error-fetch')}
            info={
              error.type === WorkspaceListErrorType.TRANSLATABLE_ERROR
                ? t(error.key, error.params)
                : error.message
            }
            shouldClose
            {...(error.category === WorkspaceListErrorCategory.GENERIC && {
              actionButtonProps: {
                title: t('workspace-retry'),
                onClick: (): void => fetchWorkspaceList(),
              },
            })}
          />
        </div>
      )}
      <Box sx={{ padding: 1, height: '100%' }}>
        {!error && (
          <Filter
            filters={workspaceListFilters}
            onClickFilterChip={onClickFilterChip}
            isAllSelected={isAllSelected}
            searchQuery={workspaceSearchQuery}
            onSearch={searchFilter}
          />
        )}
        <WorkspaceSelectList
          workspacePresets={workspacePresets}
          currentSelectedId={currentView.id || ''}
          onClickWorkspacePreset={onClickWorkspacePreset}
          onClickWorkspacePresetOption={onClickWorkspacePresetOption}
          hideNewWorkspace={shouldHideNewWorkspace}
          isEditable={isLoggedIn}
          isAdmin={isAdmin}
          searchQuery={workspaceSearchQuery}
        />
      </Box>
    </Box>
  );
};

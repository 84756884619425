/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box, Card, Grid2 as Grid, LinearProgress } from '@mui/material';
import { useApiContext } from '@opengeoweb/api';
import { dateUtils, usePoller } from '@opengeoweb/shared';
import {
  getBeginTime,
  getEndTime,
  endTime,
  beginTime,
} from '../../utils/defaultTimeRange';

import { SWErrors, TimeseriesParams } from '../../types';
import { GraphItem } from './types';
import { TimeSeriesHeader } from './TimeSeriesHeader';
import {
  createStateById,
  stateAsArray,
  cancelRequests,
  useUserSleeping,
  useStreams,
  GraphRecord,
  GraphState,
  getGraphHeightInPx,
} from './TimeSeries.utils';
import { createSeries } from './createChartSeriesData';
import { SpaceWeatherApi } from '../../utils/api';
import { Graphs } from './Graphs';
import {
  CHART_TOP_MARGIN,
  CHART_BOTTOM_MARGIN,
  PLOT_TOP_MARGIN,
  config,
  CHART_LEFT_MARGIN,
  CHART_RIGHT_MARGIN,
} from './utils';
import { GraphErrors } from './GraphErrors';

// component
const TimeSeries: React.FC = () => {
  const { api } = useApiContext<SpaceWeatherApi>();
  const [isUserSleeping, resetUserIsSleeping] = useUserSleeping();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const time_start = dateUtils.dateToString(beginTime)!;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const time_stop = dateUtils.dateToString(endTime)!;

  /* Ref, state and update function for results per chart */
  const [chartRerenderIndicator, triggerRerender] = React.useState<string>();
  const newChart = React.useRef<GraphRecord>({}).current;
  const setNewChart = (
    chartId: string,
    graphState: GraphState | null,
    isLoading: boolean,
  ): void => {
    if (graphState) {
      newChart[chartId] = graphState;
    }
    if (newChart[chartId]) {
      newChart[chartId].isLoading = isLoading;
    }
    /* Comma separated string with loading state for each chart, when changes a re-render is triggered */
    triggerRerender(
      Object.keys(newChart)
        .map(
          (chartId: string): string =>
            `${chartId}=${newChart[chartId].isLoading}`,
        )
        .join(','),
    );
  };

  // chart state
  const [loadedCharts, setLoadedChart] = React.useState(
    createStateById(config, { time_start, time_stop }),
  );
  const [loadedStreams, fetchStreams] = useStreams(api);
  // holds every loaded graph as keys
  const [parsedCharts, setParsedCharts] = React.useState(
    stateAsArray(createStateById(config, { time_start, time_stop })),
  );

  const onUserAction = (): void => {
    resetUserIsSleeping();
  };

  // fetch all charts
  const fetchChart = (
    chart: GraphItem,
    params: TimeseriesParams[],
  ): Promise<void> => {
    setNewChart(chart.id, null, true);
    return api
      .getTimeSeriesMultiple(params)
      .then((responses) => {
        // Filter out any errors
        const filteredResponses = responses.reduce(
          (obj, response) => {
            if (!response.data) {
              if (response.message !== SWErrors.USER_CANCELLED) {
                obj.errors.push(response);
              }
              return obj;
            }
            obj.success.push(response);
            return obj;
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { errors: [] as any[], success: [] as any[] },
        );

        if (filteredResponses.success.length > 0) {
          const newLoadedChart = {
            ...chart,
            params: [...params],
            series: createSeries(filteredResponses.success),
          };

          // // when first time chart is loaded, trigger resize to prevent alignment issues
          // if (!loadedCharts[chart.id].data[0].series?.length) {
          //   window.dispatchEvent(new Event('resize'));
          // }

          setNewChart(
            chart.id,
            {
              data: [newLoadedChart],
              isLoading: false,
              error:
                filteredResponses.errors.length > 0
                  ? filteredResponses.errors[0]
                  : null,
            },
            false,
          );
        } else if (filteredResponses.errors.length > 0) {
          throw new Error(filteredResponses.errors[0]);
        }
      })
      .catch((error) => {
        if (error.message !== SWErrors.USER_CANCELLED) {
          setNewChart(
            chart.id,
            {
              data: [{ ...chart }],
              isLoading: false,
              error,
            },
            false,
          );
        }
      });
  };

  const refetchChartsWithData = (timeStart: string, timeStop: string): void => {
    cancelRequests(config);
    const chartsWithData = parsedCharts.reduce<GraphItem[]>(
      (list: GraphItem[], item: GraphState) => {
        if (item.data && item.data.length) {
          return list.concat(item.data[0]);
        }
        return list;
      },
      [],
    );

    chartsWithData.forEach((chart: GraphItem) =>
      fetchChart(
        chart,
        chart.params.map((param) => ({
          ...param,
          time_start: timeStart,
          time_stop: timeStop,
        })),
      ),
    );
  };

  // eslint-disable-next-line consistent-return
  const autoUpdate = async (): Promise<void> => {
    // only autoUpdate if user is sleeping
    if (!isUserSleeping) {
      return null!;
    }
    try {
      fetchStreams();
    } catch (error) {
      return null!;
    }
  };

  // cancel requests on unmount
  React.useEffect(() => {
    return (): void => {
      cancelRequests(config);
    };
  }, []);

  // fetch charts on load
  React.useEffect(() => {
    // reset loading and error
    const newState = Object.keys(loadedCharts).reduce(
      (list, key) => ({
        ...list,
        [key]: {
          ...loadedCharts[key],
          isLoading: true,
          error: null,
        },
      }),
      {},
    );
    setLoadedChart(newState);
    // update parsed charts
    const chartsAsArray = stateAsArray(newState);
    setParsedCharts(chartsAsArray);
    // refetch data
    refetchChartsWithData(time_start, time_stop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sync state update
  React.useEffect(() => {
    if (newChart) {
      const newState = {
        ...loadedCharts,
        ...newChart,
      };
      // stores the new chart
      setLoadedChart(newState);
      // store state as array as well
      const chartsAsArray = stateAsArray(newState);
      setParsedCharts(chartsAsArray);
    }
    // whenever a new chart has been added async, update and parse the data of that
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChart, chartRerenderIndicator]);

  usePoller([parsedCharts, loadedCharts], autoUpdate, 60000);

  React.useEffect(() => {
    if (isUserSleeping) {
      // automatically jump to current date when fell asleep
      const newTimeStart = dateUtils.dateToString(getBeginTime())!;
      const newTimeEnd = dateUtils.dateToString(getEndTime())!;
      refetchChartsWithData(newTimeStart, newTimeEnd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSleeping]);

  React.useEffect(() => {
    if (loadedStreams) {
      // find and update charts with new data
      parsedCharts.forEach((chart: GraphState) => {
        const chartData = chart.data[0];
        const { stream } = chartData.params[0];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newStream = (loadedStreams as any)[stream!];
        // stream has newer data, update the chart
        if (newStream) {
          const params = chartData.params.map((param) => ({
            ...param,
            time_stop: newStream.end,
          }));
          void fetchChart({ ...chartData, end: newStream.end }, params);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedStreams]);

  const isLoading = parsedCharts.filter((chart) => chart.isLoading).length > 0;

  const graphHeight = getGraphHeightInPx();
  const chartHeight =
    CHART_TOP_MARGIN +
    (PLOT_TOP_MARGIN + graphHeight) * config.length +
    CHART_BOTTOM_MARGIN;

  return (
    <Grid
      container
      sx={{
        backgroundColor: 'geowebColors.background.surfaceApp',
      }}
    >
      <Grid size={12}>
        <TimeSeriesHeader />
      </Grid>
      <Grid
        sx={{ paddingTop: '0px!important', paddingBottom: '4px' }}
        size={12}
      >
        {isLoading ? (
          <LinearProgress
            data-testid="timeline-loadingbar"
            color="secondary"
            sx={{
              marginLeft: `${CHART_LEFT_MARGIN}px`,
              marginRight: `${CHART_RIGHT_MARGIN}px`,
            }}
          />
        ) : (
          <Box sx={{ height: 4 }} />
        )}
      </Grid>
      <Grid sx={{ height: chartHeight }} size={12}>
        <Card
          sx={{
            height: chartHeight,
            border: 'solid 1px',
            borderColor: 'geowebColors.cards.cardContainerBorder',
            boxShadow: 'none',
          }}
        >
          <Graphs
            data={parsedCharts.flatMap((chart) => chart.data)}
            beginTime={beginTime}
            endTime={endTime}
            graphHeight={graphHeight}
            chartHeight={chartHeight}
            onUserAction={onUserAction}
          />
          <GraphErrors
            data={parsedCharts}
            graphHeight={graphHeight}
            chartHeight={chartHeight}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TimeSeries;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  getWMSServiceId,
  invalidateWMSGetCapabilities,
  queryWMSLayers,
  queryWMSServiceInfo,
} from '@opengeoweb/webmap';
import { serviceTypes } from '@opengeoweb/store';
import { TFunction } from 'i18next';
import { layerSelectTypes } from '../../store';

export type ValidationResult = string | boolean;

export const validateServiceName = (
  t: TFunction,
  value: string,
  services: layerSelectTypes.ActiveServiceObjectEntities,
  ownServiceName = '',
): ValidationResult => {
  const serviceName = value?.toLowerCase();
  const foundName = Object.keys(services).find(
    (serviceId) =>
      services[serviceId]?.serviceName!.toLowerCase() === serviceName &&
      serviceName !== ownServiceName.toLocaleLowerCase(),
  );
  return foundName === undefined ? true : t('validations-name-existing');
};

const isValidUrl = (url: string): boolean => {
  if (url === '' || url === null || typeof url === 'undefined') {
    return false;
  }
  const matcher = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|[0-9a-zA-Z]+[:?\d]*)\S*$/;
  if (!matcher.test(url)) {
    return false;
  }
  return true;
};

export const validateServiceUrl = (
  t: TFunction,
  value: string,
  services: layerSelectTypes.ActiveServiceObjectEntities,
): ValidationResult => {
  const serviceUrl = value;
  // validate valid url
  const isServiceUrlValid = isValidUrl(serviceUrl);
  if (!isServiceUrlValid) {
    return t('validations-service-valid-url');
  }
  // check url existing
  const foundUrl = Object.keys(services).find(
    (serviceId) => services[serviceId]?.serviceUrl === serviceUrl,
  );
  if (foundUrl) {
    return t('validations-service-existing');
  }

  return true;
};

export const loadWMSService = async (
  serviceUrl: string,
  forceReload = false,
): Promise<serviceTypes.SetLayersForServicePayload> => {
  if (forceReload) {
    await invalidateWMSGetCapabilities(serviceUrl);
  }

  const layers = await queryWMSLayers(serviceUrl);
  const service = await queryWMSServiceInfo(serviceUrl);

  return {
    id: service.id || getWMSServiceId(serviceUrl),
    name: service.title,
    serviceUrl,
    abstract: service.abstract,
    layers,
    scope: 'user',
  };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  WMLayer,
  WMJSDimension,
  webmapUtils,
  Dimension,
} from '@opengeoweb/webmap';
import { UpdateLayerInfoPayload } from './types';

export const getCurrentDimensionValue = (
  name: string,
  dimensions: WMJSDimension[] = [],
): string => {
  const currentDimension = dimensions.find((dim) => dim.name === name);
  return currentDimension?.currentValue!;
};

export const getLayerUpdateInfo = (
  wmLayer: WMLayer,
  mapId: string,
): UpdateLayerInfoPayload => {
  const origin = 'ReactMapViewParseLayer';
  const map = webmapUtils.getWMJSMapById(mapId);

  const mapDimensions = map?.mapdimensions.map((mapdim): Dimension => {
    return {
      units: mapdim.units,
      currentValue: mapdim.currentValue,
      name: mapdim.name,
    };
  });

  const layerDimensions = wmLayer.dimensions.map((dim: WMJSDimension) => {
    const { name, units, synced } = dim;
    return {
      name,
      units,
      currentValue: dim.getValue(),
      minValue: dim.getFirstValue(),
      maxValue: dim.getLastValue(),
      timeInterval: dim.getDimInterval(),
      values: dim.getValues(),
      synced,
    };
  });
  /* Update list of layers for service */
  const updateObject: UpdateLayerInfoPayload = {
    origin,
    layerStyle: {
      origin,
      layerId: wmLayer.ReactWMJSLayerId!,
      style: wmLayer.currentStyle!,
    },
    mapDimensions: {
      mapId,
      origin,
      dimensions: mapDimensions ?? [],
    },
    layerDimensions: {
      origin,
      layerId: wmLayer.id,
      dimensions: layerDimensions,
    },
  };
  return updateObject;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useState } from 'react';
import { Exclamation, MyLocation } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { useCoreTranslation } from '../../utils/i18n';
import { LocationDetail } from './types';

export interface MyMapLocationProps {
  mapId?: string;
  isSelected?: boolean;
  onUpdateMyLocation?: (location?: LocationDetail) => void;
}

export const MY_LOCATION = 'My location';

const locationOptions = {
  enableHighAccuracy: false,
  timeout: 10000,
  maximumAge: 0,
};

export const MyMapLocation: React.FC<MyMapLocationProps> = ({
  mapId = '',
  isSelected = false,
  onUpdateMyLocation = (): void => {},
}: MyMapLocationProps) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);

  const { t } = useCoreTranslation();

  const handleLocationClick = (): void => {
    if (mapId && isSelected) {
      onUpdateMyLocation(undefined);
    } else if (navigator.geolocation && mapId && !isSelected) {
      setIsLoading(true);
      setHasFailed(false);
      navigator.geolocation.getCurrentPosition(
        (success) => {
          setIsLoading(false);
          const {
            coords: { latitude, longitude },
          } = success;
          const location: LocationDetail = {
            lat: latitude,
            lon: longitude,
            name: MY_LOCATION,
            source: 'MyMapLocation',
            id: MY_LOCATION,
          };
          onUpdateMyLocation(location);
        },
        () => {
          setIsLoading(false);
          setHasFailed(true);
          onUpdateMyLocation(undefined);
        },
        locationOptions,
      );
    }
  };

  const chooseIcon = (): React.ReactElement => {
    return hasFailed ? (
      <Exclamation
        style={{ color: theme.palette.geowebColors.functional.error }}
        aria-label="location error"
      />
    ) : (
      <MyLocation aria-label="location default" />
    );
  };

  return (
    <Box sx={{ height: 40, padding: 1 }}>
      {isLoading ? (
        <CircularProgress
          size={24}
          aria-label="Location loading"
          color="secondary"
        />
      ) : (
        <CustomIconButton
          tooltipTitle={t('search-my-location')}
          aria-label={t('search-my-location')}
          onClick={handleLocationClick}
          isSelected={isSelected}
          variant="tool"
          size="small"
        >
          {chooseIcon()}
        </CustomIconButton>
      )}
    </Box>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { UserAddedServices, NoIdService } from './types';

export const getUserAddedServices = (): UserAddedServices => {
  try {
    const storedString = window.localStorage.getItem('userAddedServices');
    const parsedData = JSON.parse(storedString!);

    if (parsedData && typeof parsedData === 'object') {
      return Object.entries(parsedData).reduce(
        (userServices, [serviceUrl, service]) => ({
          ...userServices,
          ...(isNoIdService(service)
            ? { [serviceUrl]: { ...service, scope: 'user' } }
            : {}),
        }),
        {} as UserAddedServices,
      );
    }
  } catch (e) {
    console.error('Unable to read services from localStorage: ', e);
  }
  return {};
};

export const setUserAddedServices = (services: UserAddedServices): void => {
  try {
    window.localStorage.setItem('userAddedServices', JSON.stringify(services));
  } catch (e) {
    console.error('Unable to write services to localStorage: ', e);
  }
};

const isNoIdService = (param: unknown): param is NoIdService => {
  const serviceParam = param as NoIdService;
  return !!(serviceParam?.name && serviceParam?.url);
};

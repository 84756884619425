/* eslint-disable i18next/no-literal-string */
/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Box, Grid2 as Grid, List, ListItem } from '@mui/material';
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  KeyCommand,
  KeyOption,
} from '@opengeoweb/theme';
import { useTranslation } from 'react-i18next';
import ShortcutSegment from './ShortcutSegment';
import { SHARED_NAMESPACE } from '../../../utils/i18n';

interface ShortCut {
  titleKey: string;
  description: React.ReactNode;
  descriptionMac?: React.ReactNode;
}

type Platform = 'windows' | 'mac';

const ShortcutList: React.FC<{ platform?: Platform }> = ({
  platform = 'windows',
}) => {
  const { t } = useTranslation(SHARED_NAMESPACE);

  const shortcuts: ShortCut[] = [
    {
      titleKey: 'shared-choose-selected-time',
      description: (
        <ShortcutSegment>{t('shared-shortcut-left-click')}</ShortcutSegment>
      ),
    },
    {
      titleKey: 'shared-move-selected-time',
      description: (
        <ShortcutSegment>
          {t('shared-shortcut-mouse-scroll-timeslider')}
        </ShortcutSegment>
      ),
    },
    {
      titleKey: 'shared-move-one-step',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowLeft />
          </ShortcutSegment>
          <ShortcutSegment type="divider">
            {t('shared-shortcut-or')}
          </ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowRight />
          </ShortcutSegment>
        </>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">
            <KeyOption />
          </ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowLeft />
          </ShortcutSegment>
          <ShortcutSegment type="divider">
            {t('shared-shortcut-or')}
          </ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowRight />
          </ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-toggle-timeslider',
      description: <ShortcutSegment type="icon">T</ShortcutSegment>,
    },
    {
      titleKey: 'shared-timeslider-scale',
      description: (
        <ShortcutSegment>{t('shared-shortcut-hold-click')}</ShortcutSegment>
      ),
    },
    {
      titleKey: 'shared-expand-timeslider-scale',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment>{t('shared-shortcut-scroll')}</ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-mouseover-selected-time',
      description: (
        <ShortcutSegment>{t('shared-shortcut-hover')}</ShortcutSegment>
      ),
    },
    {
      titleKey: 'shared-mouseover-on-off',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">Alt</ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">h</ShortcutSegment>
        </>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">
            <KeyOption />
          </ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">h</ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-change-timestep-or-speed',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowUp />
          </ShortcutSegment>
          <ShortcutSegment type="divider">
            {t('shared-shortcut-or')}
          </ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowDown />
          </ShortcutSegment>
          <ShortcutSegment>
            ({t('shared-shortcut-when-active')})
          </ShortcutSegment>
        </>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">
            <KeyCommand />
          </ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowUp />
          </ShortcutSegment>
          <ShortcutSegment type="divider">
            {t('shared-shortcut-or')}
          </ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowDown />
          </ShortcutSegment>
          <ShortcutSegment>
            ({t('shared-shortcut-when-active')})
          </ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-return-to-current-time',
      description: (
        <ShortcutSegment type="icon">
          {t('shared-shortcut-home')}
        </ShortcutSegment>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">fn</ShortcutSegment>
          <ShortcutSegment type="connect">+</ShortcutSegment>
          <ShortcutSegment type="icon">
            <ArrowLeft />
          </ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-change-layer-values-scrolling',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment>
            {' '}
            {t('shared-shortcut-mouse-scroll')}
          </ShortcutSegment>
        </>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">
            <KeyCommand />
          </ShortcutSegment>
          <ShortcutSegment>
            {' '}
            {t('shared-shortcut-mouse-scroll')}
          </ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-change-layermanager-small',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">Alt</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">s</ShortcutSegment>
        </>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">
            <KeyOption />
          </ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">s</ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-change-layermanager-medium',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">Alt</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">m</ShortcutSegment>
        </>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">
            <KeyOption />
          </ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">m</ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-change-layermanager-large',
      description: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">Alt</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">l</ShortcutSegment>
        </>
      ),
      descriptionMac: (
        <>
          <ShortcutSegment type="icon">Ctrl</ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">
            <KeyOption />
          </ShortcutSegment>
          <ShortcutSegment> + </ShortcutSegment>
          <ShortcutSegment type="icon">l</ShortcutSegment>
        </>
      ),
    },
    {
      titleKey: 'shared-shortcut-toggle-workspace-time-scroll',
      description: <ShortcutSegment type="icon">S</ShortcutSegment>,
      descriptionMac: <ShortcutSegment type="icon">S</ShortcutSegment>,
    },
  ];

  return (
    <List data-testid={`list-${platform}`}>
      {shortcuts.map((shortcut, index) => (
        <ListItem
          sx={{ minHeight: 56 }}
          divider={index !== shortcuts.length - 1}
          key={shortcut.titleKey}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            size="grow"
          >
            <Grid
              sx={{
                fontSize: 12,
                fontWeight: 300,
                letterSpacing: 0.4,
                opacity: 0.67,
              }}
            >
              {t(shortcut.titleKey)}
            </Grid>
            <Grid>
              <Box sx={{ alignItems: 'center' }}>
                {platform === 'mac' && shortcut.descriptionMac
                  ? shortcut.descriptionMac
                  : shortcut.description}
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

export default ShortcutList;

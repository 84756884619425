/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { TimeSeriesService } from '@opengeoweb/shared';
import { ServicePopup } from './ServicePopup';
import {
  timeSeriesActions,
  timeSeriesSelectors,
  TimeSeriesTypes,
} from '../../../store';
import { TimeSeriesModuleState } from '../../../store/types';

interface ServicePopupDialogConnectType {
  isOpen: boolean;
  serviceId?: string;
  variant: TimeSeriesTypes.PopupVariant;
  url?: string;
  setServicePopupClosed: () => void;
}

// Extracted from ServicePopupConnect as the getActiveServices and getActiveServiceById selectors
// Result in many rerenders. By extracting we're able to only render this component and these selectors
// when the dialog is open
export const ServicePopupDialogConnect: React.FC<
  ServicePopupDialogConnectType
> = ({
  isOpen,
  serviceId = '',
  variant,
  url = '',
  setServicePopupClosed,
}: ServicePopupDialogConnectType) => {
  const dispatch = useDispatch();

  const services = useSelector((store: TimeSeriesModuleState) =>
    timeSeriesSelectors.getServices(store),
  );

  const activeService = useSelector((store: TimeSeriesModuleState) =>
    timeSeriesSelectors.getService(store, serviceId!),
  );

  const popupState = useSelector((store: TimeSeriesModuleState) =>
    timeSeriesSelectors.getServicePopupDetails(store),
  );

  const serviceSetService = React.useCallback(
    (payload: TimeSeriesService): void => {
      dispatch(
        timeSeriesActions.upsertService({
          ...payload,
          isUpdate: popupState.variant === 'edit',
        }),
      );
    },
    [dispatch, popupState],
  );

  const showSnackbar = React.useCallback(
    (message: string) => {
      dispatch(
        snackbarActions.openSnackbar({
          type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
          message,
        }),
      );
    },
    [dispatch],
  );

  return (
    <ServicePopup
      servicePopupVariant={variant}
      isOpen={isOpen}
      closePopup={setServicePopupClosed}
      services={services}
      serviceSetService={serviceSetService}
      serviceUrl={url || activeService?.url}
      serviceName={activeService?.name}
      serviceDescription={activeService?.description}
      showSnackbar={showSnackbar}
    />
  );
};

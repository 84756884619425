/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchField } from '@opengeoweb/shared';
import { timeSeriesActions } from '../../store';
import { getSearchFilter } from '../../store/selectors';
import { TimeSeriesModuleState } from '../../store/types';
import { useTimeseriesTranslation } from '../../utils/i18n';

const SearchFieldConnect: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTimeseriesTranslation();

  const searchFilter = useSelector((state: TimeSeriesModuleState) =>
    getSearchFilter(state),
  );

  const toggleServicePopup = (url: string): void => {
    dispatch(
      timeSeriesActions.setServicePopup({
        isOpen: true,
        url,
        variant: 'save',
      }),
    );
  };

  const setSearchFilter = (filterText: string): void => {
    dispatch(timeSeriesActions.setSearchFilter({ filterText }));
  };

  return (
    <SearchField
      setSearchFilter={setSearchFilter}
      searchFilter={searchFilter}
      label={t('timeseries-search-ts-or-enter-url')}
      onOpenServicePopup={toggleServicePopup}
    />
  );
};

export default SearchFieldConnect;

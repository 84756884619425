/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box, Card, CircularProgress } from '@mui/material';
import { AlertBanner } from '@opengeoweb/shared';
import { WarningListRowCapConnect } from './WarningListRowCap/WarningListRowCapConnect';
import { CapPresets } from '../types';
import { useCapTranslation } from '../../utils/i18n';

interface WarningListCapProps {
  capWarningPresets?: CapPresets;
  alertIds: string[];
}

export const WarningListCap: React.FC<WarningListCapProps> = ({
  capWarningPresets,
  alertIds,
}) => {
  const { t } = useCapTranslation();

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <Card
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'auto',
          height: '95%',
          width: '100%',
          padding: '10px',
          margin: '20px',
          marginBottom: '10px',
          position: 'relative',
        }}
        data-testid="warningList"
      >
        {alertIds.length > 0 && (
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AlertBanner title={t('warning-list-cap-alert-banner-title')} />
          </Box>
        )}
        {alertIds.length === 0 ? (
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '20%',
              zIndex: '100',
            }}
          >
            <CircularProgress />
          </Box>
        ) : null}
        {alertIds.map((id) => (
          <WarningListRowCapConnect
            key={id}
            alertId={id}
            capWarningPresets={capWarningPresets!}
          />
        ))}
      </Card>
    </Box>
  );
};

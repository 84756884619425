/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PresetScope } from '@opengeoweb/shared';
import { syncGroupsTypes } from '@opengeoweb/store';
import { MosaicNode } from 'react-mosaic-component';

export type InitialPropsWorkspaceViewType = Record<string, unknown>; // empty

export type WorkspaceViewPresetComponentsLookupType =
  | 'ViewError'
  | 'ViewLoading';

export interface WorkspaceViewType {
  id?: string;
  title?: string; // If not provided, id will be used instead
  abstract?: string;
  componentType: string | WorkspaceViewPresetComponentsLookupType;
  initialProps?: InitialPropsWorkspaceViewType;
  activeLayerId?: string;
  shouldPreventClose?: boolean;
  scope?: PresetScope;
  keywords?: string;
}

export interface WorkspaceViews {
  byId: Record<string, WorkspaceViewType>;
  allIds: string[];
}

export interface WorkspaceViewsFromBE {
  mosaicNodeId: string;
  viewPresetId: string;
}

export type ViewType = 'singleWindow' | 'multiWindow' | 'tiledWindow';

export interface WorkspaceSyncGroup {
  id: string;
  type: syncGroupsTypes.SyncType;
  title?: string;
}

export interface WorkspacePreset {
  id?: string;
  title: string;
  syncGroups?: WorkspaceSyncGroup[];
  isTimeScrollingEnabled?: boolean;
  views: WorkspaceViews;
  viewType?: ViewType;
  mosaicNode: MosaicNode<string>;
  minimumPaneSizePercentage?: number;
  scope?: PresetScope;
  abstract?: string;
  linking?: Record<string, string[]>;
}

// should be extracted from storing preset
export interface WorkspaceState extends WorkspacePreset {
  isLoading?: boolean;
  error?: WorkspaceError;
  hasChanges?: boolean;
}

export interface WorkspacePresetFromBE {
  id?: string;
  title: string;
  syncGroups?: WorkspaceSyncGroup[];
  linking?: Record<string, string[]>;
  views: WorkspaceViewsFromBE[];
  viewType?: ViewType;
  mosaicNode: MosaicNode<string>;
  scope?: PresetScope;
  abstract?: string;
}

export interface WorkspacePresetListItem {
  id: string;
  date?: string;
  title: string;
  scope?: PresetScope;
  viewType: ViewType;
  abstract: string;
}

export enum WorkspacePresetAction {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  SAVE = 'SAVE',
  SAVE_AS = 'SAVE_AS',
  DUPLICATE = 'DUPLICATE',
  SAVE_SYSTEM_PRESET = 'SAVE_SYSTEM_PRESET',
  SAVE_SYSTEM_PRESET_AS = 'SAVE_SYSTEM_PRESET_AS',
  RESET = 'RESET',
  DELETE_SYSTEM_PRESET = 'DELETE_SYSTEM_PRESET',
  SHARE = 'SHARE',
}

// actions
export interface WorkspaceAddViewPayload {
  id: string;
  componentType: string;
  initialProps: InitialPropsWorkspaceViewType;
}

export interface WorkspaceSetNewViewPayload {
  mosaicNodeId: string;
  newId: string;
  componentType: string;
}

export interface WorkspaceDeleteViewPayload {
  id: string;
}

export interface WorkspaceUpdateViewsPayload {
  mosaicNode: MosaicNode<string>;
}

export interface WorkspaceUpdateViewPayload {
  mosaicNodeId: string;
  viewPreset: WorkspaceViewType;
}

export interface WorkspaceChangeToEmptyMapWorkspacePayload {
  newMapPresetText: string;
  newWorkspaceText: string;
}

export interface WorkspaceSetPresetPayload {
  workspacePreset: WorkspacePreset;
}

export interface WorkspaceSetPreventCloseViewPayload {
  viewId: string;
  shouldPreventClose: boolean;
}

// base WorkspaceComponentLookupPayload type. This can be extended in libraries to make it more specific
export interface WorkspaceComponentLookupPayload {
  componentType: string;
  id: string;
  title?: string;
  initialProps: unknown;
  connectedMap?: string;
}

export type WorkspaceLookupFunctionType = (
  payload: WorkspaceComponentLookupPayload,
) => React.ReactElement;

export interface ViewPresetListItem {
  id: string;
  title: string;
  date: string;
  scope: PresetScope;
}

// Workspaces
export interface WorkspaceFetchWorkspacePayload {
  workspaceId: string;
}
export interface WorkspaceFetchedWorkspacePayload {
  workspaceId: string;
  workspace: WorkspacePresetFromBE;
}
export interface WorkspaceFetchWorkspaceViewPresetPayload {
  viewPresetId: string;
  mosaicNodeId: string;
}

export interface WorkspaceSaveWorkspacePresetPayload {
  workspace: WorkspacePresetFromBE;
}

export enum WorkspaceErrorType {
  NOT_FOUND = 'NOT_FOUND',
  GENERIC = 'GENERIC',
  SAVE = 'SAVE',
}

export type WorkspaceError =
  | { type: WorkspaceErrorType; message: string }
  | undefined;

export interface WorkspaceErrorWorkspacePayload {
  workspaceId: string;
  error: WorkspaceError;
}

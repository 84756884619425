/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { FeatureEvent } from '@opengeoweb/webmap-react';
import { CustomFeature } from '../components/types';

export const highlightGeoJson = (
  currentFeatures: Record<string, CustomFeature[]>,
  featureResults: FeatureEvent,
): Record<string, CustomFeature[]> =>
  Object.entries(currentFeatures).reduce(
    (byId, [alertId, features]) => {
      const isSelected =
        alertId === featureResults?.feature.properties!.details.identifier;
      return {
        ...byId,
        [alertId]: features.map((feature) => {
          const recoloredFeature = { ...feature };
          recoloredFeature.properties.fill = isSelected
            ? feature.properties.fill!.replace('0.10', '0.25')
            : (recoloredFeature.properties.fill =
                feature.properties.fill!.replace('0.25', '0.10'));
          recoloredFeature.properties['stroke-width'] = isSelected ? 3 : 2;
          return recoloredFeature;
        }),
      };
    },
    {} as Record<string, CustomFeature[]>,
  );

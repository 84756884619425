/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CustomAccordion } from '@opengeoweb/shared';
import * as React from 'react';
import { Box, Grid2 as Grid } from '@mui/material';
import { FixedSizeList } from 'react-window';
import { LocationDropdownHeader } from './LocationDropdownHeader';
import { GeoJsonWithService } from '../types';
import { renderRow } from './LocationDropdownRenderRow';

interface LocationDropdownProps {
  splitGeoJson?: GeoJsonWithService[];
  handleChange: (newSelectedLocation: string) => void;
  selectedLocation: string;
}

export const LocationDropdown: React.FC<LocationDropdownProps> = ({
  splitGeoJson,
  handleChange,
  selectedLocation,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [filteredResults, setFilteredResults] = React.useState(splitGeoJson);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const term = event?.target.value.toLowerCase();
    setSearchTerm(term);

    const newFilteredResults = splitGeoJson?.map((dataset) => {
      const filteredFeatures = dataset.geoJson.features.filter((feature) => {
        return feature.properties?.name.toLowerCase().includes(term);
      });

      return {
        ...dataset,
        geoJson: {
          ...dataset.geoJson,
          features: filteredFeatures,
        },
      };
    });
    setFilteredResults(newFilteredResults);
  };

  return (
    <Box
      data-testid="locationDropdownContainer"
      sx={{
        width: '350px',
        height: '600px',
        overflowY: 'auto',
        backgroundColor: 'geowebColors.background.surface',
      }}
    >
      <Grid container>
        <Grid
          size={{ xs: 12 }}
          container
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'white',
          }}
        >
          <LocationDropdownHeader
            searchTerm={searchTerm}
            handleSearch={handleSearch}
          />
        </Grid>

        {filteredResults?.map((object) => {
          const { serviceName, geoJson, collectionName } = object;

          return (
            <Grid
              data-testid="serviceDropdown"
              key={`${collectionName}-${serviceName}`}
              container
              sx={{ marginBottom: '5px', marginTop: '5px' }}
            >
              <CustomAccordion
                data-testid="locationDropdownCollectionAccordion"
                title={collectionName}
                title2={serviceName}
                style={{ width: '92%' }}
                amountOfItems={geoJson.features.length || 0}
                defaultExpanded={false}
              >
                <FixedSizeList
                  height={350}
                  width={300}
                  itemSize={40}
                  itemCount={geoJson.features.length}
                >
                  {renderRow(geoJson.features, handleChange, selectedLocation)}
                </FixedSizeList>
              </CustomAccordion>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Grid2 as Grid, ListItem, Skeleton } from '@mui/material';
import { SubdirectoryArrowRight } from '@opengeoweb/theme';

import { AlertBanner } from '@opengeoweb/shared';
import { useApiContext, useApi } from '@opengeoweb/api';
import LifeCycleColumn from './LifeCycleColumn';
import LifeCycleActions from './LifeCycleActions';
import LifeCycleEdit from './LifeCycleEdit';
import { getStatusTagContent } from '../Notifications/utils';
import { getNewInternalStatusTagContent } from './utils';
import { SpaceWeatherApi } from '../../utils/api';

export const getActionModeForDraft = (
  actionMode: string,
  label: string,
  changestateto: string,
): string => {
  if (actionMode !== 'none') {
    return actionMode;
  }
  if (changestateto === 'ended') {
    if (label === 'WARNING') {
      return 'Cancel';
    }
    if (label === 'ALERT') {
      return 'Summarise';
    }
  }

  return actionMode;
};

interface LifeCycleDisplayProps {
  eventId: string;
  toggleDialogOpen: () => void;
  onFormChange?: (hasChanged: boolean) => void;
  setErrorStoreMessage?: (value: string) => void;
  setErrorRetrievePopulate?: (value: string) => void;
}

const LifeCycleDisplay: React.FC<LifeCycleDisplayProps> = ({
  eventId,
  toggleDialogOpen,
  onFormChange,
  setErrorStoreMessage = (): void => {},
  setErrorRetrievePopulate = (): void => {},
}: LifeCycleDisplayProps) => {
  const { api } = useApiContext<SpaceWeatherApi>();

  const { isLoading, error, result } = useApi(api.getEvent, eventId);

  const [actionMode, setActionMode] = React.useState('none');

  const onAction = (actionType: string): void => {
    setActionMode(actionType);
  };

  const draft =
    result?.lifecycles?.internalprovider?.draft === true
      ? result.lifecycles.internalprovider.notifications![
          result.lifecycles.internalprovider.notifications!.length - 1
        ]
      : false;

  const isInternalProviderLifeCycle =
    result?.lifecycles?.internalprovider !== undefined &&
    result?.lifecycles?.internalprovider.eventid;

  return (
    <>
      {result && (
        <Grid container data-testid="display-lifecycle">
          {result.lifecycles!.externalprovider &&
            result.lifecycles!.externalprovider.firstissuetime && (
              <Grid data-testid="display-lifecycle-externalcolumn" size={6}>
                <LifeCycleColumn
                  lifeCycle={result.lifecycles!.externalprovider}
                  categoryDetail={result.categorydetail}
                  type="external"
                />
              </Grid>
            )}
          <Grid
            data-testid="display-lifecycle-internalcolumn"
            size={result.lifecycles!.externalprovider ? 6 : 12}
          >
            {isInternalProviderLifeCycle && (
              <LifeCycleColumn
                lifeCycle={result.lifecycles!.internalprovider!}
                categoryDetail={result.categorydetail}
                type="internal"
              />
            )}
            <ListItem>
              {
                // internalprovider notification exists already - no action chosen - display buttons
                isInternalProviderLifeCycle &&
                  actionMode === 'none' &&
                  !draft && (
                    <LifeCycleActions
                      actions={result.lifecycles!.internalprovider!.canbe!}
                      onAction={onAction}
                    />
                  )
              }
              {
                // No internalprovider notification issued yet - incoming externalprovider notification exists - default in data from last external provider notification
                result !== undefined &&
                  !draft &&
                  !isInternalProviderLifeCycle &&
                  actionMode === 'none' && (
                    <LifeCycleEdit
                      toggleDialogOpen={toggleDialogOpen}
                      statusTagContent={getStatusTagContent(
                        result.lifecycles!.externalprovider!.label,
                        result.categorydetail,
                      )}
                      eventTypeDisabled
                      baseNotificationData={result}
                      baseNotificationType="externalprovider"
                      onFormChange={onFormChange}
                      setErrorStoreMessage={setErrorStoreMessage}
                      setErrorRetrievePopulate={setErrorRetrievePopulate}
                    />
                  )
              }

              {
                // internalprovider notification exists already (no draft) - we are performing an action after pressing one of the buttons -
                // base category, type and message off of existing (inernal) event. Any other fields are based off of last external notification if present
                isInternalProviderLifeCycle &&
                  !draft &&
                  actionMode !== 'none' && (
                    <Grid
                      container
                      spacing={1}
                      data-testid="lifecycle-display-internaleditaction"
                    >
                      <Grid size={12}>
                        <SubdirectoryArrowRight />
                      </Grid>
                      <Grid size={12}>
                        <LifeCycleEdit
                          toggleDialogOpen={toggleDialogOpen}
                          statusTagContent={getNewInternalStatusTagContent(
                            actionMode,
                            result.lifecycles!.internalprovider!.label,
                          )}
                          eventTypeDisabled
                          baseNotificationData={result}
                          baseNotificationType="internalprovider"
                          onFormChange={onFormChange}
                          actionMode={actionMode}
                          setErrorStoreMessage={setErrorStoreMessage}
                          setErrorRetrievePopulate={setErrorRetrievePopulate}
                        />
                      </Grid>
                    </Grid>
                  )
              }

              {
                // internalprovider draft notification exists - base category, type and start/end time off event filled in from draft

                isInternalProviderLifeCycle && draft && (
                  <Grid
                    container
                    spacing={1}
                    data-testid="lifecycle-display-internaldraftedit"
                  >
                    {result.lifecycles!.internalprovider!.notifications!
                      .length > 1 && (
                      <Grid size={12}>
                        <SubdirectoryArrowRight />
                      </Grid>
                    )}
                    <Grid size={12}>
                      <LifeCycleEdit
                        toggleDialogOpen={toggleDialogOpen}
                        baseNotificationData={result}
                        baseNotificationType="draft"
                        statusTagContent={getStatusTagContent(
                          draft.label,
                          result.categorydetail,
                          draft.changestateto,
                        )}
                        eventTypeDisabled
                        onFormChange={onFormChange}
                        actionMode={getActionModeForDraft(
                          actionMode,
                          draft.label,
                          draft.changestateto,
                        )}
                        setErrorStoreMessage={setErrorStoreMessage}
                        setErrorRetrievePopulate={setErrorRetrievePopulate}
                      />
                    </Grid>
                  </Grid>
                )
              }
            </ListItem>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <Skeleton
          data-testid="display-lifecycle-loading"
          variant="rectangular"
          height={500}
          width="100%"
        />
      )}
      {error && (
        <AlertBanner
          severity="error"
          dataTestId="display-lifecycle-error"
          title={error.message ? error.message : ''}
        />
      )}
    </>
  );
};

export default LifeCycleDisplay;

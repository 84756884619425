/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { ApiModule, ApiProvider, ApiUrls } from '@opengeoweb/api';
import { ErrorBoundary } from '@opengeoweb/shared';
import { createApi } from '../../utils/api';
import {
  PublicWarningListConnect,
  PublicWarningListConnectProps,
} from '../PublicWarningList';

const warningListViewPreset = {
  title: 'Warning List',
  id: 'warninglist',
  componentType: 'WarningList',
  initialProps: {},
};

export type WarningListComponentType = 'WarningList';

export interface ComponentsLookUpPayload {
  componentType: WarningListComponentType;
  id: string;
  title?: string;
  initialProps: Record<string, never>;
}

// base type for connected modules
export interface ApiModuleProps extends ApiModule {
  config: ApiUrls;
}
interface Props extends ApiModuleProps, PublicWarningListConnectProps {}

export const PublicWarningApiWrapper: React.FC<Props> = ({
  config,
  auth,
  onSetAuth,
  panelId,
  presetFilterState,
}) => {
  return config ? (
    <ApiProvider
      config={{
        baseURL: config.baseURL,
        appURL: config.baseURL,
        authTokenURL: config.authTokenURL,
        authClientId: config.authClientId,
      }}
      auth={auth}
      onSetAuth={onSetAuth}
      createApi={createApi}
    >
      <ErrorBoundary>
        <PublicWarningListConnect
          panelId={panelId}
          presetFilterState={presetFilterState}
        />
      </ErrorBoundary>
    </ApiProvider>
  ) : null;
};

export const componentsLookUp = (
  payload: ComponentsLookUpPayload,
  apiProps?: ApiModuleProps,
): React.ReactElement => {
  const { id, componentType, initialProps } = payload;
  switch (componentType) {
    case warningListViewPreset.componentType:
      if (apiProps && apiProps.config) {
        return (
          <PublicWarningApiWrapper
            {...apiProps}
            panelId={id}
            data-testid="publicWarningModule"
            presetFilterState={initialProps.filterState}
          />
        );
      }
      return (
        <PublicWarningListConnect
          panelId={id}
          data-testid="publicWarningList"
          presetFilterState={initialProps.filterState}
        />
      );
    default:
      return null!;
  }
};

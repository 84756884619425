/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { LayerType, webmapUtils } from '@opengeoweb/webmap';
import { layerTypes } from '../layer';

export const filterLayers = (
  layers: layerTypes.Layer[] = [],
  layerParser = (layer: layerTypes.Layer): layerTypes.Layer => layer,
): FilteredLayerList =>
  layers.reduce((layers, layer) => {
    const { mapLayers, baseLayers, overLayers } = layers;
    const parsedLayer = layerParser(layer);
    if (layer.layerType === LayerType.baseLayer) {
      return {
        mapLayers,
        baseLayers: baseLayers.concat(parsedLayer),
        overLayers,
      };
    }

    if (layer.layerType === LayerType.mapLayer) {
      return {
        mapLayers: mapLayers.concat(parsedLayer),
        baseLayers,
        overLayers,
      };
    }

    if (layer.layerType === LayerType.overLayer) {
      return {
        mapLayers,
        baseLayers,
        overLayers: overLayers!.concat(parsedLayer),
      };
    }

    return layers;
  }, createEmtpyFilteredList([]));
export const createEmtpyFilteredList = (
  existingList: [],
): FilteredLayerList => ({
  mapLayers: existingList,
  baseLayers: existingList,
  overLayers: existingList,
});
export interface FilteredLayerList {
  mapLayers: layerTypes.Layer[];
  baseLayers: layerTypes.Layer[];
  overLayers?: layerTypes.Layer[];
}

export const parseBoolean = (value: string | boolean): boolean =>
  typeof value === 'boolean' ? value : value === 'true';

export const parseLayer = (layer: layerTypes.Layer): layerTypes.Layer => ({
  ...layer,
  ...(!layer.id && { id: webmapUtils.generateLayerId() }),
  ...(layer.enabled && { enabled: parseBoolean(layer.enabled) }),
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { dateUtils } from '@opengeoweb/shared';
import { CoreAppStore } from '@opengeoweb/store';
import { createSelector } from '@reduxjs/toolkit';
import { alertAdapter, feedAdapter } from './reducer';
import { CapAlert, CapModule, CapStore, CapFeed } from './types';

export const getCapStore = (store: CapModule): CapStore => {
  if (store && store.cap) {
    return store.cap;
  }
  return null!;
};

export const { selectById: getCapFeedById, selectAll: getCapFeeds } =
  feedAdapter.getSelectors((state: CapModule) => state.cap!.feeds);

export const {
  selectById: getAlertById,
  selectAll: getAlerts,
  selectIds: getAlertIds,
} = alertAdapter.getSelectors((state: CapModule) => state.cap!.alerts);

export const getAlertsInEffect = createSelector(
  getAlerts,
  (_state: CoreAppStore, selectedTime: string): string => selectedTime,
  (alerts: CapAlert[], selectedTime: string) => {
    return alerts.filter((alert) =>
      dateUtils.isBetween(
        dateUtils.createDate(selectedTime),
        dateUtils.createDate(alert.onset),
        dateUtils.createDate(alert.expires),
        '[)',
      ),
    );
  },
);

export const getLastUpdatedTimes = createSelector(
  getCapFeeds,
  (feeds: CapFeed[]) => {
    return feeds.map((feed) => feed.lastUpdated);
  },
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormDateTime,
  ReactHookFormNumberField,
} from '@opengeoweb/form-fields';

import { dateUtils } from '@opengeoweb/shared';
import { styles } from './EditFormFields.styles';
import { isInPastOrFutureAllowed, isRecentPast } from './validations';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

const SuddenImpulse: React.FC = () => {
  const { t } = useSpaceweatherTranslation();
  const { watch } = useFormContext();

  const label = watch('label');

  return label === 'ALERT' ? (
    <Grid container size={12}>
      <Grid size={5}>
        <ReactHookFormDateTime
          name="impulsetime"
          label={t('notification-impulse-time')}
          data-testid="impulsetime-picker"
          sx={styles.inputField}
          disableFuture
          rules={{
            required: true,
            validate: {
              isValidDate: dateUtils.isValidIsoDateString,
              isInPastOrFutureAllowed: (value: string) =>
                isInPastOrFutureAllowed(t, value),
              isRecentPast: (value: string) => isRecentPast(t, value),
            },
          }}
          shouldHideUTC
        />
      </Grid>
      <Grid size={5}>
        <ReactHookFormNumberField
          name="magnetometerdeflection"
          inputMode="decimal"
          sx={styles.inputField}
          slotProps={{
            htmlInput: { 'data-testid': 'magnetometerdeflection-input' },
          }}
          label={t('notification-magnetometer')}
          rules={{
            required: true,
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container>
        <Grid size={11}>
          <ReactHookFormDateTime
            name="shocktime"
            label={t('notification-shock-time')}
            data-testid="shocktime-picker"
            sx={styles.inputField}
            disableFuture
            rules={{
              required: true,
              validate: {
                isValidDate: dateUtils.isValidIsoDateString,
                isInPastOrFutureAllowed: (value: string) =>
                  isInPastOrFutureAllowed(t, value),
                isRecentPast: (value: string) => isRecentPast(t, value),
              },
            }}
            shouldHideUTC
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={5}>
          <ReactHookFormNumberField
            name="observedpolaritybz"
            inputMode="decimal"
            sx={styles.inputField}
            slotProps={{
              htmlInput: { 'data-testid': 'observedpolaritybz-input' },
            }}
            label={t('notification-polarity')}
            rules={{
              required: true,
              min: {
                value: -1000,
                message: t('notification-polarity-error'),
              },
              max: {
                value: 1000,
                message: t('notification-polarity-error'),
              },
            }}
          />
        </Grid>
        <Grid size={6}>
          <ReactHookFormNumberField
            name="observedsolarwind"
            inputMode="decimal"
            sx={styles.inputFieldLongLabel}
            slotProps={{
              htmlInput: { 'data-testid': 'observedsolarwind-input' },
            }}
            label={t('notification-solar-wind')}
            rules={{
              required: true,
              min: {
                value: 100,
                message: t('notification-solar-wind-error'),
              },
              max: {
                value: 10000,
                message: t('notification-solar-wind-error'),
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SuddenImpulse;

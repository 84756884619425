/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { Link, LinkOff } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { Dimension, webmapUtils } from '@opengeoweb/webmap';
import { useWebmapReactTranslation } from '../../utils/i18n';
import { DimensionConfig, dimensionConfig, marksByDimension } from './utils';
import DimensionSelectSlider from './DimensionSelectSlider';

const Icon: React.FC<{ isLayerDimensionSynced: boolean }> = ({
  isLayerDimensionSynced,
}) =>
  isLayerDimensionSynced ? (
    <Link data-testid="syncIcon" />
  ) : (
    <LinkOff data-testid="syncDisIcon" />
  );

export interface MapDimensionSelectProps {
  layerId: string;
  dimensionName: string;
  handleDimensionValueChanged: (
    layerId: string,
    dimensionName: string,
    dimensionValue: string,
  ) => void;
  handleSyncChanged: (
    layerId: string,
    dimensionName: string,
    dimensionValue: string,
    synced: boolean,
  ) => void;
  layerDimension: Dimension;
}

const MapDimensionSelect: React.FC<MapDimensionSelectProps> = ({
  layerId,
  dimensionName,
  handleDimensionValueChanged,
  handleSyncChanged,
  layerDimension,
}: MapDimensionSelectProps) => {
  const { t } = useWebmapReactTranslation();
  const wmLayer = webmapUtils.getWMLayerById(layerId);
  const dimConfig: DimensionConfig = dimensionConfig(t).find(
    (cnf) => cnf.name === dimensionName,
  )!;

  if (!layerDimension || !wmLayer) {
    return null;
  }
  const wmsDimension = wmLayer.getDimension(dimensionName);

  if (!wmsDimension) {
    return null;
  }
  const layerTitle = wmLayer.title!;
  const marks = marksByDimension(t, wmsDimension);
  const isLayerDimensionSynced =
    layerDimension.synced !== undefined && layerDimension.synced === true;

  const tooltipTitle = isLayerDimensionSynced
    ? t('webmap-react-click-disconnect')
    : t('webmap-react-click-connect');

  return (
    <Grid key={layerId} size={{ xs: 'auto' }}>
      <CustomIconButton
        tooltipTitle={tooltipTitle}
        onClick={(): void =>
          handleSyncChanged(
            layerId,
            dimensionName,
            layerDimension.currentValue,
            !layerDimension.synced,
          )
        }
        sx={{ margin: '5px' }}
        data-testid="syncButton"
      >
        <Icon isLayerDimensionSynced={isLayerDimensionSynced} />
      </CustomIconButton>

      <DimensionSelectSlider
        marks={marks}
        layerTitle={layerTitle}
        reverse={dimConfig ? dimConfig.reversed : false}
        managedValue={
          Number(layerDimension.currentValue) || layerDimension.currentValue
        }
        onChangeDimensionValue={(value): void => {
          handleDimensionValueChanged(layerId, dimensionName, value);
        }}
        isDisabled={false}
        validSelection={
          layerDimension.synced === false ||
          !(
            layerDimension.validSyncSelection !== undefined &&
            layerDimension.validSyncSelection === false
          )
        }
      />
    </Grid>
  );
};

export default MapDimensionSelect;

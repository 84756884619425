/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CoreAppStore,
  mapSelectors,
  uiActions,
  uiSelectors,
  uiTypes,
} from '@opengeoweb/store';

import { DimensionSelectButton } from '@opengeoweb/webmap-react';
import { getDimensionType } from './MultiMapSingleDimensionSelectConnect';

interface DimensionSelectMapButtonProps {
  mapId: string;
  dimension: string;
  source?: uiTypes.Source;
}

const DimensionSelectMapButtonConnect: React.FC<
  DimensionSelectMapButtonProps
> = ({ mapId, dimension, source = 'app' }: DimensionSelectMapButtonProps) => {
  const dispatch = useDispatch();
  const uiDialogType = getDimensionType(dimension) as uiTypes.DialogType;

  const currentActiveMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, uiDialogType),
  );

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, uiDialogType),
  );

  // Only show button if enabled layer for map contains dimension
  const mapContainsDimension = useSelector((store: CoreAppStore) =>
    mapSelectors.getIsEnabledLayersForMapDimension(store, mapId, dimension),
  );

  const openMultiDimensionDialog = React.useCallback((): void => {
    const setOpen = currentActiveMapId !== mapId ? true : !isOpenInStore;
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: uiDialogType,
        mapId,
        setOpen,
        source,
      }),
    );
  }, [
    currentActiveMapId,
    dispatch,
    isOpenInStore,
    uiDialogType,
    mapId,
    source,
  ]);

  if (!mapContainsDimension) {
    return null;
  }

  const isOpen = currentActiveMapId === mapId && isOpenInStore;

  return (
    <DimensionSelectButton
      dimension={dimension}
      onClickDimensionButton={openMultiDimensionDialog}
      isActive={isOpen}
    />
  );
};

export default DimensionSelectMapButtonConnect;

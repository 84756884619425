/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import {
  FetchInitialServicesPayload,
  MapStoreRemoveServicePayload,
  ServiceState,
  SetLayersForServicePayload,
} from './types';
import { layerActions } from '../layer/reducer';

export const initialState: ServiceState = {
  byId: {},
  allIds: [],
};

const slice = createSlice({
  initialState,
  name: 'serviceReducer',
  reducers: {
    serviceSetLayers: (
      draft: Draft<ServiceState>,
      action: PayloadAction<SetLayersForServicePayload>,
    ) => {
      const { id } = action.payload;
      draft.byId[id] = { ...action.payload };
      if (!draft.allIds.includes(id)) {
        draft.allIds.push(id);
      }
    },
    mapStoreRemoveService: (
      draft: Draft<ServiceState>,
      action: PayloadAction<MapStoreRemoveServicePayload>,
    ) => {
      const foundServiceIndex = draft.allIds.findIndex((id) => {
        return draft.byId[id].serviceUrl === action.payload.serviceUrl;
      });
      if (foundServiceIndex !== -1) {
        const serviceId = draft.allIds[foundServiceIndex];
        draft.allIds = draft.allIds.filter((id) => id !== serviceId);
        delete draft.byId[serviceId];
      }
    },
    fetchInitialServices: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<ServiceState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<FetchInitialServicesPayload>,
    ) => {
      // This block is intentionally left empty so no code should be put in here
    },
  },
});

export const { reducer: serviceReducer, actions: serviceActions } = slice;

export type ServiceActions =
  | ReturnType<typeof serviceActions.mapStoreRemoveService>
  | ReturnType<typeof serviceActions.serviceSetLayers>
  | ReturnType<typeof serviceActions.fetchInitialServices>
  | ReturnType<typeof layerActions.onUpdateLayerInformation>;

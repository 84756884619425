/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  DialogProps,
  Typography,
  Dialog,
  DialogContent,
  Button,
  Grid2 as Grid,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { ChevronLeft } from '@opengeoweb/theme';
import { useSigmetAirmetTranslation } from '../../utils/i18n';

interface ContentDialogProps extends DialogProps {
  open: boolean;
  toggleDialogStatus: () => void;
  title: string;
  children?: React.ReactNode;
  options?: React.ReactNode;
  alertBanner?: React.ReactNode;
  onClose?: () => void;
}

const ContentDialog: React.FC<ContentDialogProps> = ({
  open,
  toggleDialogStatus,
  title,
  children,
  options,
  onClose,
  alertBanner = null,
  ...other
}: ContentDialogProps) => {
  const { t } = useSigmetAirmetTranslation();
  const theme = useTheme();

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    } else {
      toggleDialogStatus();
    }
  };

  return (
    <Dialog
      disableEnforceFocus
      data-testid="contentDialog"
      open={open}
      onClose={handleClose}
      maxWidth={other.maxWidth ? other.maxWidth : 'lg'}
      {...other}
      PaperProps={{
        sx: {
          height: '100%',
        },
      }}
      sx={{
        '> div > .MuiPaper-root': {
          minWidth: 300,
        },
      }}
    >
      <DialogTitle
        sx={{
          boxShadow: 1,
          zIndex: 1,
          padding: 0,
          height: 48,
          display: 'flex',
          paddingLeft: 2,
          paddingRight: 2,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: 3,
            paddingRight: 3,
          },
        }}
        component="div"
      >
        <Grid container alignItems="center" size="grow">
          <Grid size={4}>
            <Button
              onClick={handleClose}
              variant="flat"
              startIcon={<ChevronLeft />}
              data-testid="contentdialog-close"
              sx={{
                [theme.breakpoints.down('md')]: {
                  textIndent: -999999,
                  width: 24,
                  minWidth: 24,
                  height: 24,
                  ' .MuiSvgIcon-root': {
                    marginRight: 0,
                  },
                },
              }}
            >
              {t('back')}
            </Button>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
            size={4}
          >
            <Typography
              data-testid="dialogTitle"
              variant="h6"
              sx={{
                fontSize: '1.0rem',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Typography>
          </Grid>

          <Grid size={4}>{options}</Grid>
        </Grid>
      </DialogTitle>
      {alertBanner !== null && alertBanner}
      <DialogContent
        sx={{
          display: 'flex',
          padding: 0,
          minHeight: 200,
          height: '100%',
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ContentDialog;

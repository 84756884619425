/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PayloadAction, createListenerMiddleware } from '@reduxjs/toolkit';

import { routerActions } from '@opengeoweb/store';
import {
  getAxiosErrorMessage,
  isAxiosAuthError,
  isAxiosError,
} from '@opengeoweb/shared';
import { snackbarActions } from '@opengeoweb/snackbar';
import { AxiosError } from 'axios';
import { workspaceListActions } from './reducer';
import {
  FetchWorkspaceParams,
  OnSuccesWorkspacePresetActionPayload,
  SubmitFormWorkspaceActionDialogPayload,
  WorkspaceListError,
  WorkspaceListErrorCategory,
  WorkspaceDialogView,
  WorkspaceListErrorType,
} from './types';
import { getWorkspaceApi } from '../workspace/utils';
import * as workspaceSelectors from '../workspace/selectors';
import {
  WorkspacePresetAction,
  WorkspacePresetFromBE,
} from '../workspace/types';
import {
  constructFilterParams,
  getEmptyMapWorkspace,
  getSnackbarMessage,
} from './utils';
import { getWorkspaceRouteUrl } from '../../utils/routes';
import { workspaceListSelectors } from '.';
import { getViewPresetToSendToBackend } from '../workspace/selectors';
import { SaveViewPresetPayload } from '../viewPresets/types';
import { WorkspaceModuleStore } from '../types';
import {
  saveViewPresetAsEffect,
  saveViewPresetEffect,
} from '../viewPresets/listener';
import { workspaceActions } from '../workspace/reducer';

export const emptyWorkspaceBEData: Omit<WorkspacePresetFromBE, 'title'> = {
  ...getEmptyMapWorkspace(),
  views: [{ mosaicNodeId: '1_screen', viewPresetId: 'emptyMap' }],
};

export const workspaceListListener =
  createListenerMiddleware<WorkspaceModuleStore>();

workspaceListListener.startListening({
  actionCreator: workspaceListActions.fetchWorkspaceList,
  effect: async (
    { payload: workspaceFilterParams }: PayloadAction<FetchWorkspaceParams>,
    listenerApi,
  ) => {
    listenerApi.cancelActiveListeners();

    try {
      const workspaceApi = getWorkspaceApi();
      const { data } = await workspaceApi.getWorkspacePresets(
        workspaceFilterParams,
      );

      if (listenerApi.signal.aborted) {
        return;
      }
      listenerApi.dispatch(
        workspaceListActions.fetchedWorkspaceList({ workspaceList: data }),
      );
    } catch (error) {
      const isAuthError = isAxiosAuthError(error as Error);
      const workspaceListError: WorkspaceListError = isAuthError
        ? {
            type: WorkspaceListErrorType.VERBATIM_ERROR,
            message: getAxiosErrorMessage(error as AxiosError),
            category: WorkspaceListErrorCategory.AUTH,
          }
        : {
            type: WorkspaceListErrorType.TRANSLATABLE_ERROR,
            key: 'workspace-list-error',
            category: WorkspaceListErrorCategory.GENERIC,
          };
      listenerApi.dispatch(
        workspaceListActions.errorWorkspaceList({
          error: workspaceListError,
        }),
      );
    }
  },
});

workspaceListListener.startListening({
  actionCreator: workspaceListActions.onSuccessWorkspacePresetAction,
  effect: async (
    { payload }: PayloadAction<OnSuccesWorkspacePresetActionPayload>,
    listenerApi,
  ) => {
    listenerApi.cancelActiveListeners();

    const { action, workspaceId, title } = payload;
    listenerApi.dispatch(
      snackbarActions.openSnackbar(getSnackbarMessage(action, title)),
    );
    // if active workspace has been deleted, empty out url
    if (
      (action === WorkspacePresetAction.DELETE ||
        action === WorkspacePresetAction.DELETE_SYSTEM_PRESET) &&
      payload.currentActiveWorkspaceId &&
      workspaceId === payload.currentActiveWorkspaceId
    ) {
      listenerApi.dispatch(
        routerActions.navigateToUrl({ url: getWorkspaceRouteUrl() }),
      );
    }

    // For Save as, update url to include the new id
    if (action === WorkspacePresetAction.SAVE_AS) {
      listenerApi.dispatch(
        routerActions.navigateToUrl({
          url: getWorkspaceRouteUrl(workspaceId),
        }),
      );
    }

    // Retrieve new list with current filter
    const workspaceFilters = workspaceListSelectors.getWorkspaceListFilters(
      listenerApi.getState(),
    );

    const searchQuery = workspaceListSelectors.getWorkspaceListSearchQuery(
      listenerApi.getState(),
    );

    listenerApi.dispatch(
      workspaceListActions.fetchWorkspaceList(
        constructFilterParams(workspaceFilters, searchQuery),
      ),
    );
  },
});

workspaceListListener.startListening({
  actionCreator: workspaceListActions.submitFormWorkspaceActionDialogOptions,
  effect: async (
    { payload }: PayloadAction<SubmitFormWorkspaceActionDialogPayload>,
    listenerApi,
  ) => {
    listenerApi.cancelActiveListeners();
    const workspaceApi = getWorkspaceApi();

    try {
      const {
        presetId,
        presetAction,
        data,
        emptyMapPresetTitle,
        emptyWorkspacePresetTitle,
      } = payload;
      const scope = data.scope || 'user';

      // duplicate
      if (presetAction === WorkspacePresetAction.DUPLICATE) {
        const isNewWorkspace = presetId === getEmptyMapWorkspace().id;
        const { data: presetToDuplicate } = isNewWorkspace
          ? { data: emptyWorkspaceBEData }
          : await workspaceApi.getWorkspacePreset(presetId);

        const fullPreset = { ...presetToDuplicate, ...data };

        const newId = await workspaceApi.saveWorkspacePresetAs(fullPreset);

        listenerApi.dispatch(
          workspaceListActions.onSuccessWorkspacePresetAction({
            workspaceId: newId,
            action: presetAction,
            title: data.title,
            scope,
          }),
        );
      }

      // delete
      if (
        presetAction === WorkspacePresetAction.DELETE ||
        presetAction === WorkspacePresetAction.DELETE_SYSTEM_PRESET
      ) {
        await workspaceApi.deleteWorkspacePreset(presetId);
        const selectedWorkspace = workspaceSelectors.getWorkspaceState(
          listenerApi.getState(),
        );

        listenerApi.dispatch(
          workspaceListActions.onSuccessWorkspacePresetAction({
            workspaceId: presetId,
            action: presetAction,
            title: data.title,
            currentActiveWorkspaceId: selectedWorkspace.id,
            scope,
            emptyWorkspacePresetTitle,
          }),
        );
      }

      // edit
      if (presetAction === WorkspacePresetAction.EDIT) {
        // Retrieve original workspace data
        const { data: originalData } =
          await workspaceApi.getWorkspacePreset(presetId);

        const workspaceDataNewTitle = {
          ...originalData,
          title: data.title,
          abstract: data.abstract,
        };
        await workspaceApi.saveWorkspacePreset(presetId, workspaceDataNewTitle);

        listenerApi.dispatch(
          workspaceListActions.onSuccessWorkspacePresetAction({
            workspaceId: presetId,
            action: presetAction,
            title: data.title,
            abstract: data.abstract,
            scope,
          }),
        );
      }

      // save (as) user/system presets
      const isSaveWorkspaceAction =
        presetAction === WorkspacePresetAction.SAVE_SYSTEM_PRESET ||
        presetAction === WorkspacePresetAction.SAVE;

      const isSaveAsWorkspaceAction =
        presetAction === WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS ||
        presetAction === WorkspacePresetAction.SAVE_AS;

      if (isSaveWorkspaceAction || isSaveAsWorkspaceAction) {
        const hasWorkspaceAlreadyBeenSaved =
          workspaceListSelectors.getWorkspaceDialogIsSaved(
            listenerApi.getState(),
          );

        let workspaceId = presetId;

        if (!hasWorkspaceAlreadyBeenSaved && isSaveAsWorkspaceAction) {
          workspaceId = await workspaceApi.saveWorkspacePresetAs(data);
        }
        if (isSaveWorkspaceAction) {
          await workspaceApi.saveWorkspacePreset(workspaceId, data);
        }

        // lock the workspace
        listenerApi.dispatch(
          workspaceListActions.onSuccessWorkspaceSaveDialog({
            presetId: workspaceId,
          }),
        );
        const viewPresetResults = await Promise.all(
          (data.views as WorkspaceDialogView[]).map(async (view) => {
            const viewPresetEntity = {
              ...getViewPresetToSendToBackend(
                listenerApi.getState(),
                view.mosaicNodeId,
              ),
              title: view.title,
              ...(view.abstract && {
                abstract: view.abstract,
              }),
            };

            const shouldUpdateView = view.hasChanges && view.isSelected;
            const canUpdateView =
              scope === 'system' || scope === viewPresetEntity.scope;

            // save existing views
            if (
              shouldUpdateView &&
              view.viewPresetId !== 'emptyMap' &&
              canUpdateView
            ) {
              const payload = {
                panelId: view.mosaicNodeId,
                viewPresetId: view.viewPresetId,
                viewPreset: viewPresetEntity,
              };

              return saveViewPresetEffect(
                { payload } as PayloadAction<SaveViewPresetPayload>,
                listenerApi,
              );
            }
            // save new views
            if (shouldUpdateView) {
              const payload = {
                panelId: view.mosaicNodeId,
                viewPresetId: '',
                viewPreset: {
                  ...viewPresetEntity,
                  // update scope
                  scope,
                },
              };

              return saveViewPresetAsEffect(
                { payload } as PayloadAction<SaveViewPresetPayload>,
                listenerApi,
              );
            }
            return viewPresetEntity;
          }),
        );

        const hasError = viewPresetResults.find(
          (view) => (view as Error).message,
        );

        if (hasError) {
          // maybe we can use another action, but this resets fetching of the form
          listenerApi.dispatch(
            workspaceListActions.onErrorWorkspacePresetAction({
              error: undefined,
            }),
          );
          return;
        }

        const workspaceData = workspaceSelectors.getWorkspaceData(
          listenerApi.getState(),
        );
        const newWorkspace: WorkspacePresetFromBE = {
          ...workspaceData,
          id: workspaceId,
          title: data.title,
          abstract: data.abstract,
          scope,
        };

        await workspaceApi.saveWorkspacePreset(workspaceId!, newWorkspace);

        listenerApi.dispatch(
          workspaceListActions.onSuccessWorkspacePresetAction({
            action: presetAction,
            workspaceId,
            title: newWorkspace.title,
            abstract: newWorkspace.abstract,
            scope,
          }),
        );

        listenerApi.dispatch(
          routerActions.navigateToUrl({
            url: getWorkspaceRouteUrl(workspaceId),
          }),
        );
      }

      // Reset
      if (presetAction === WorkspacePresetAction.RESET) {
        // In case we're resetting the `New workspace` preset
        if (presetId === getEmptyMapWorkspace().id) {
          listenerApi.dispatch(
            workspaceActions.changeToEmptyMapWorkspace({
              newMapPresetText: emptyMapPresetTitle,
              newWorkspaceText: emptyWorkspacePresetTitle,
            }),
          );
        } else {
          listenerApi.dispatch(
            workspaceActions.fetchWorkspace({ workspaceId: presetId }),
          );
        }
        // workspace success handling
        listenerApi.dispatch(
          workspaceListActions.onSuccessWorkspacePresetAction({
            action: WorkspacePresetAction.RESET,
            workspaceId: presetId!,
            title: data.title,
            abstract: data.abstract,
            scope,
          }),
        );
      }
    } catch (error) {
      const message = isAxiosError(error as Error)
        ? getAxiosErrorMessage(error as AxiosError)
        : (error as Error).message;

      const category = isAxiosAuthError(error as AxiosError)
        ? WorkspaceListErrorCategory.AUTH
        : WorkspaceListErrorCategory.GENERIC;
      listenerApi.dispatch(
        workspaceListActions.onErrorWorkspacePresetAction({
          error: {
            type: WorkspaceListErrorType.VERBATIM_ERROR,
            category,
            message,
          },
        }),
      );
    }
  },
});

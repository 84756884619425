/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { dateUtils } from '@opengeoweb/shared';
import { mapTypes } from '@opengeoweb/store';
import { TimeBounds } from '@opengeoweb/timeslider';

/**
 * Returns time bounds from the given dimension. If no time dimension given, current time is returned as default
 */
export const getTimeBounds = (dimensions: mapTypes.Dimension[]): TimeBounds => {
  const defaultStartEnd = dateUtils.unix(
    dateUtils.set(dateUtils.utc(), { seconds: 0, milliseconds: 0 }),
  );
  const timeDimension = dimensions?.find((dim) => dim.name === 'time');

  const startTime =
    timeDimension && timeDimension.minValue
      ? dateUtils.unix(dateUtils.utc(timeDimension.minValue))
      : defaultStartEnd;
  const endTime =
    timeDimension && timeDimension.maxValue
      ? dateUtils.unix(dateUtils.utc(timeDimension.maxValue))
      : defaultStartEnd;

  return {
    startTime,
    endTime,
  };
};

export const calculateNewAnimationEndTime = (
  newTimeInMilliseconds: number,
  dataStartTimeInMilliseconds: number,
  dataEndTimeInMilliseconds: number,
  animationDuration: number,
): number => {
  if (newTimeInMilliseconds <= dataStartTimeInMilliseconds) {
    return dataStartTimeInMilliseconds + animationDuration;
  }
  if (newTimeInMilliseconds > dataEndTimeInMilliseconds) {
    return dataEndTimeInMilliseconds;
  }
  return newTimeInMilliseconds;
};

export const calculateNewAnimationTimeBounds = (
  newTime: number,
  animationStartTime: string,
  animationEndTime: string,
  dataStartTime: number,
  dataEndTime: number,
): { newAnimationStartTime: number; newAnimationEndTime: number } => {
  const dateToMillisecond = (date: string | number): number =>
    new Date(date).getTime();

  const animationDuration =
    dateToMillisecond(animationEndTime) - dateToMillisecond(animationStartTime);

  const newAnimationEndTime = calculateNewAnimationEndTime(
    newTime * 1000,
    dataStartTime * 1000,
    dataEndTime * 1000,
    animationDuration,
  );

  const newAnimationStartTime = newAnimationEndTime - animationDuration;

  return { newAnimationStartTime, newAnimationEndTime };
};

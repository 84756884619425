/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  CssBaseline,
  GlobalStyles,
  Theme as MUITheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { getThemeByName } from '../../utils/themes';
import { NamedTheme, ThemeTypes } from '../../types';

interface ThemeContextProps {
  setThemeName?: (themeName: ThemeTypes) => void;
  namedTheme?: NamedTheme;
}

export const ThemeContext = React.createContext<Partial<ThemeContextProps>>({
  setThemeName: undefined,
  namedTheme: undefined,
});

export interface ThemeProviderProps {
  children: React.ReactNode;
  theme?: MUITheme;
  defaultThemeName?: ThemeTypes;
  disableCssBaseline?: boolean;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme,
  defaultThemeName,
  disableCssBaseline = false,
}: ThemeProviderProps) => {
  const [configuredTheme, setConfiguredTheme] = React.useState<NamedTheme>(
    getThemeByName(defaultThemeName || ThemeTypes.LIGHT_THEME),
  );
  const currentTheme = React.useMemo(
    () => theme || configuredTheme.theme,
    [configuredTheme, theme],
  );
  const setThemeName = React.useCallback((themeName: ThemeTypes): void => {
    setConfiguredTheme(getThemeByName(themeName));
  }, []);

  const contextValue = React.useMemo(() => {
    return { setThemeName, namedTheme: configuredTheme };
  }, [configuredTheme, setThemeName]);

  return (
    <ThemeContext.Provider value={contextValue}>
      <MUIThemeProvider theme={currentTheme}>
        {!disableCssBaseline && <CssBaseline />}
        <GlobalStyles
          styles={{
            '@keyframes mui-auto-fill': { from: { display: 'block' } },
            '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
          }}
        />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = (): Partial<ThemeContextProps> =>
  React.useContext<ThemeContextProps>(ThemeContext);

// Wrapper for storybook stories. Will include later an additional wrapper after upgrade to MUI5
export const ThemeWrapper: React.FC<ThemeProviderProps> = ({
  children,
  theme,
  defaultThemeName,
  disableCssBaseline = false,
}: ThemeProviderProps) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider
      theme={theme}
      defaultThemeName={defaultThemeName}
      disableCssBaseline={disableCssBaseline}
    >
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

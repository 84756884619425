/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import ReactECharts from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import {
  EChartsDarkTheme,
  EChartsLightTheme,
  useThemeContext,
} from '@opengeoweb/theme';
import { dateUtils } from '@opengeoweb/shared';
import { GraphItem } from './types';
import {
  DATA_ZOOM_LABEL_WIDTH,
  config,
  dateFormat,
  getCurrentTimeMarkers,
  getGrid,
  getSeries,
  getTooltip,
  getXAxis,
  getYAxis,
} from './utils';

const getOption = (
  data: GraphItem[],
  beginTime: Date,
  endTime: Date,
  graphHeight: number,
): EChartsOption => {
  const tooltip = getTooltip();
  const grid = getGrid(config, graphHeight);
  const xAxis = getXAxis(config, beginTime, endTime);
  const yAxis = getYAxis(data);
  const series = getSeries(data);
  const currentTimeMarkers = getCurrentTimeMarkers(config);

  const option: EChartsOption = {
    animation: false,
    useUTC: true,
    tooltip,
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all',
        },
      ],
    },
    toolbox: {
      show: true,
      feature: {
        restore: { show: true },
      },
      itemSize: 12,
    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        left: DATA_ZOOM_LABEL_WIDTH,
        right: DATA_ZOOM_LABEL_WIDTH,
        xAxisIndex: data.map((_, index) => index),
        labelFormatter: (_, date): string => {
          return dateUtils.dateToString(dateUtils.utc(date), dateFormat)!;
        },
        startValue: dateUtils.add(beginTime, { days: 4 }),
        endValue: dateUtils.sub(endTime, { days: 1 }),
      },
    ],
    grid,
    xAxis,
    yAxis,
    series: [...series, ...currentTimeMarkers],
  };

  return option;
};

interface GraphsProps {
  data: GraphItem[];
  beginTime: Date;
  endTime: Date;
  graphHeight: number;
  chartHeight: number;
  onUserAction: () => void;
}

export const Graphs: React.FC<GraphsProps> = ({
  data,
  beginTime,
  endTime,
  graphHeight,
  chartHeight,
  onUserAction,
}: GraphsProps) => {
  const { namedTheme } = useThemeContext();
  const isDark = namedTheme?.theme.palette.mode === 'dark';
  const option = getOption(data, beginTime, endTime, graphHeight);
  const height = `${chartHeight}px`;

  return (
    <ReactECharts
      theme={isDark ? EChartsDarkTheme : EChartsLightTheme}
      option={option}
      notMerge={true}
      style={{ height }}
      onEvents={{
        dataZoom: onUserAction,
        mouseMove: onUserAction,
      }}
    />
  );
};

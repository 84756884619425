/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Box, Link } from '@mui/material';
import * as React from 'react';
import { AlertBanner } from '../AlertBanner';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
  handlePromiseRejections?: boolean;
  displayOnTop?: boolean;
}

interface ErrorBoundaryState {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

// TODO: (Sander de Snaijer 2020-07-13) this error wrappper should get more (non-breaking?) logic when we have decided how to handle errors.
/**
 * ErrorBoundary
 * Wrap this component around parts of your application. It will catch any uncaught errors and display an alert, and allows you to continue using the rest of your application.
 *
 * @example
 * ``` <ErrorBoundary><ExampleComponent /></ErrorBoundary> ```
 */
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static defaultProps = {
    onError: (): void => {},
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidMount(): void {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.handlePromiseRejections) {
      window.addEventListener(
        'unhandledrejection',
        this.handlePromiseRejection,
      );
    }
  }

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { onError } = this.props;
    this.setState({ error, errorInfo });
    onError!(error, errorInfo);
  }

  componentWillUnmount(): void {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.handlePromiseRejections) {
      window.removeEventListener(
        'unhandledrejection',
        this.handlePromiseRejection,
      );
    }
  }

  handlePromiseRejection = (event: PromiseRejectionEvent): void => {
    const error =
      event.reason instanceof Error
        ? event.reason
        : new Error(String(event.reason));
    const errorInfo = {
      componentStack:
        'Canvas contains images without CORS headers of: Access-Control-Allow-Origin: *. Recording canvas cannot be done.',
    };
    this.setState({ error, errorInfo });
    event.preventDefault();
  };

  public render(): React.ReactNode {
    const { error, errorInfo } = this.state;
    const reloadPage = 'Reload page';
    const { children, displayOnTop } = this.props;

    const normalStyles = {
      whiteSpace: 'pre-wrap',
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
    };

    const displayOnTopStyles = {
      position: 'fixed',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      whiteSpace: 'pre-wrap',
      width: '50%',
      height: '50%',
      overflowY: 'scroll',
    };

    if (error) {
      return (
        <Box sx={displayOnTop ? displayOnTopStyles : normalStyles}>
          <AlertBanner
            severity="error"
            title="Woops, an error has occurred. Please report the error shown below and reload the page if needed."
            info={
              <div>
                <br />
                {error.toString()}
                <br />
                {errorInfo && errorInfo.componentStack}
                <br />
                <br />
                <Link
                  underline="always"
                  style={{ cursor: 'pointer' }}
                  onClick={(): void => window.location.reload()}
                >
                  {reloadPage}
                </Link>
              </div>
            }
          />
        </Box>
      );
    }

    return children;
  }
}
export default ErrorBoundary;

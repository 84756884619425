/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2025 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2025 - Finnish Meteorological Institute (FMI)
 * Copyright 2025 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useMemo } from 'react';
import { concat } from 'lodash';
import {
  transformAirmetToWarningFormat,
  transformSigmetToWarningFormat,
} from '../utils/sig-airmet-util';
import { useAirmetList, useSigmetList } from './aviation-api/hooks';
import { usePublicWarnings } from './warning-api/hooks';
import { Warning } from '../store/publicWarningForm/types';

interface WarningsResponse {
  combinedWarnings: Warning[];
  refetchWarnings: () => void;
  isFetching: boolean;
  error?: Error;
  dataUpdatedAt: number;
}

export const useWarnings = (disabled?: boolean): WarningsResponse => {
  const {
    data: publicWarnings,
    refetch: refetchPublicWarnings,
    isFetching: isFetchingPublicWarnings,
    error: publicWarningsError,
    dataUpdatedAt,
  } = usePublicWarnings(disabled);
  const {
    data: airmetWarnings,
    refetch: refetchAirmet,
    isFetching: isFetchingAirmet,
  } = useAirmetList(disabled);
  const {
    data: sigmetWarnings,
    refetch: refetchSigmet,
    isFetching: isFetchingSigmet,
  } = useSigmetList(disabled);

  const combinedWarnings = useMemo(() => {
    const transformedSigmets =
      sigmetWarnings?.map(transformSigmetToWarningFormat) || [];
    const transformedAirmets =
      airmetWarnings?.map(transformAirmetToWarningFormat) || [];
    return concat<Warning>(
      publicWarnings || [],
      transformedSigmets,
      transformedAirmets,
    );
  }, [publicWarnings, sigmetWarnings, airmetWarnings]);

  const refetchWarnings = (): void => {
    void refetchPublicWarnings();
    void refetchAirmet();
    void refetchSigmet();
  };

  const isFetching =
    isFetchingPublicWarnings || isFetchingAirmet || isFetchingSigmet;

  return {
    combinedWarnings,
    refetchWarnings,
    isFetching,
    error: publicWarningsError || undefined,
    dataUpdatedAt,
  };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PresetScope } from '@opengeoweb/shared';
import { selectorMemoizationOptions } from '@opengeoweb/store';
import { createSelector } from '@reduxjs/toolkit';
import { AppStore } from '../types';
import { viewPresetSelectors } from '../viewPresets';
import { ViewPresetState } from '../viewPresets/types';
import { getWorkspaceState } from '../workspace/selectors';
import { WorkspaceState } from '../workspace/types';
import { workspaceListAdapter } from './reducer';
import {
  WorkspaceDialogView,
  WorkspaceListFilter,
  WorkspaceListState,
} from './types';

export const getWorkspaceListStore = (store: AppStore): WorkspaceListState => {
  if (store && store.workspaceList) {
    return store.workspaceList;
  }
  return null!;
};

const selectors = workspaceListAdapter.getSelectors((state: AppStore) => {
  return state?.workspaceList ?? { entities: {}, ids: [] };
});

export const { selectAll: getWorkspaceList } = selectors;

export const getIsWorkspaceListFetching = createSelector(
  getWorkspaceListStore,
  (store: AppStore) => store,
  (state: WorkspaceListState) => {
    return state?.isFetching || false;
  },
  selectorMemoizationOptions,
);

export const getWorkspaceDialogIsSaved = createSelector(
  getWorkspaceListStore,
  (store: AppStore) => store,
  (state: WorkspaceListState) => {
    return state?.workspaceActionDialog?.hasSaved || false;
  },
  selectorMemoizationOptions,
);

export const getWorkspaceListError = createSelector(
  getWorkspaceListStore,
  (store: AppStore) => store,
  (state: WorkspaceListState) => {
    return state?.error || undefined;
  },
  selectorMemoizationOptions,
);

export const getWorkspaceActionDialogDetails = createSelector(
  getWorkspaceListStore,
  getWorkspaceState,
  viewPresetSelectors.getViewPresetsStore,
  (
    workspaceListState: WorkspaceListState,
    workspaceState: WorkspaceState,
    viewpresetState: ViewPresetState,
  ) => {
    const dialog = workspaceListState?.workspaceActionDialog || false;
    if (dialog) {
      const views = (dialog.formValues.views as WorkspaceDialogView[])?.map(
        (view) => {
          const workspaceView = workspaceState.views.byId![view.mosaicNodeId];
          const viewComplete = viewpresetState.entities[view.mosaicNodeId];

          return {
            mosaicNodeId: view.mosaicNodeId,
            viewPresetId: viewComplete?.activeViewPresetId || view.viewPresetId,
            isSelected: view.isSelected,
            title: view.title || workspaceView.title,
            error: viewComplete?.error,
            isFetching: viewComplete?.isFetching,
            hasChanges: viewComplete?.hasChanges,
            scope: view.scope,
          };
        },
      );
      return {
        ...dialog,
        formValues: { ...dialog.formValues, ...(views && { views }) },
      };
    }
    return dialog;
  },
  selectorMemoizationOptions,
);

export const getIsWorkspaceListDialogOpen = createSelector(
  getWorkspaceListStore,
  (store: AppStore) => store,
  (state: WorkspaceListState) => {
    return state?.isWorkspaceListDialogOpen || false;
  },
  selectorMemoizationOptions,
);

export const getWorkspaceListFilters = createSelector(
  (store: AppStore) => getWorkspaceListStore(store),
  (_store: AppStore, scope?: PresetScope) => scope,
  (state: WorkspaceListState, scope?: PresetScope) => {
    const filters = state?.workspaceListFilters || [];
    // no filters for system presets for now
    if (scope === 'system') {
      return filters.reduce<WorkspaceListFilter[]>((list, filter) => {
        if (filter.id === 'system') {
          return list.concat({ ...filter, isDisabled: true });
        }
        return list;
      }, []);
    }
    return filters;
  },
  selectorMemoizationOptions,
);

export const getWorkspaceListSearchQuery = createSelector(
  getWorkspaceListStore,
  (store: AppStore) => store,
  (state: WorkspaceListState) => {
    return state?.searchQuery || '';
  },
  selectorMemoizationOptions,
);

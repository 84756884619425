/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  componentsLookUp as coreComponentsLookUp,
  ComponentsLookUpPayload as CoreComponentsLookUpPayload,
} from '@opengeoweb/core';
import {
  componentsLookUp as timeseriesComponentsLookUp,
  ComponentsLookUpPayload as TimeseriesComponentsLookUpPayload,
} from '@opengeoweb/timeseries';

import {
  ComponentsLookUp as capComponentsLookup,
  ComponentsLookUpPayload as CapComponentsLookUpPayload,
} from '@opengeoweb/cap';
import {
  componentsLookUp as tafComponentsLookUp,
  ComponentsLookUpPayload as TafComponentsLookUpPayload,
} from '@opengeoweb/taf';
import { ConfigType } from '@opengeoweb/shared';
import {
  SigmetComponentsLookUp,
  AirmetComponentsLookUp,
  SigmetComponentsLookUpPayload,
  AirmetComponentsLookUpPayload,
} from '@opengeoweb/sigmet-airmet';
import { ComponentsLookUpWrapper } from '@opengeoweb/workspace';
import {
  ObjectManagerMapButtonConnect,
  DrawingToolMapButtonConnect,
  componentsLookUp as warningListComponentsLookUp,
  ComponentsLookUpPayload as WarningListComponentsLookUpPayLoad,
} from '@opengeoweb/warnings';
import {
  componentsLookUp as spaceweatherComponentsLookUp,
  ComponentsLookUpPayload as SpaceweatherComponentsLookUpPayload,
} from '@opengeoweb/spaceweather';
import { Credentials } from '@opengeoweb/api';

export type ComponentsLookUpPayload =
  | CoreComponentsLookUpPayload
  | TimeseriesComponentsLookUpPayload
  | TafComponentsLookUpPayload
  | CapComponentsLookUpPayload
  | SigmetComponentsLookUpPayload
  | AirmetComponentsLookUpPayload
  | WarningListComponentsLookUpPayLoad
  | SpaceweatherComponentsLookUpPayload;

/**
 * The lookup table is for registering your own components with the workspace.
 * @param payload
 * @returns
 */
export const componentsLookUp = (
  payload: ComponentsLookUpPayload,
  config?: ConfigType,
  auth?: Credentials,
  onSetAuth?: (credentias: Credentials) => void,
): React.ReactElement => {
  const coreComponent = coreComponentsLookUp({
    ...payload,
    initialProps: {
      ...payload.initialProps,
      mapControls: config?.GW_DRAWINGS_BASE_URL !== undefined && (
        <>
          <DrawingToolMapButtonConnect mapId={payload.id} />
          <ObjectManagerMapButtonConnect mapId={payload.id} />
        </>
      ),
      shouldDisplayDrawControls: config?.GW_DRAWINGS_BASE_URL !== undefined,
      displaySearchButtonInMap: config?.GW_FEATURE_DISPLAY_SEARCH_ON_MAP,
    },
  } as CoreComponentsLookUpPayload);

  const timeseriesComponent = timeseriesComponentsLookUp({
    ...(payload as TimeseriesComponentsLookUpPayload),
    productConfigKey:
      config?.GW_TIMESERIES_CONFIGURATION_FILENAME ||
      'timeSeriesPresetLocations.json',
  });

  const tafComponent = tafComponentsLookUp(
    payload as TafComponentsLookUpPayload,
    {
      config: {
        baseURL: config?.GW_TAF_BASE_URL,
        appURL: config?.GW_APP_URL,
        authTokenURL: config?.GW_AUTH_TOKEN_URL,
        authClientId: config?.GW_AUTH_CLIENT_ID,
      },
      auth,
      onSetAuth,
    },
    ComponentsLookUpWrapper,
  );

  const capComponent = capComponentsLookup({
    ...(payload as CapComponentsLookUpPayload),
    productConfigKey:
      config?.GW_CAP_CONFIGURATION_FILENAME || 'capWarningPresets.json',
    config: {
      baseURL: config?.GW_CAP_BASE_URL,
    },
  });

  const sigmetComponent = SigmetComponentsLookUp(
    payload as SigmetComponentsLookUpPayload,
    {
      config: {
        baseURL: config?.GW_SIGMET_BASE_URL,
        appURL: config?.GW_APP_URL,
        authTokenURL: config?.GW_AUTH_TOKEN_URL,
        authClientId: config?.GW_AUTH_CLIENT_ID,
      },
      auth,
      onSetAuth,
      productType: 'sigmet',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ComponentsLookUpWrapper as any,
  );

  const airmetComponent = AirmetComponentsLookUp(
    payload as AirmetComponentsLookUpPayload,
    {
      config: {
        baseURL: config?.GW_AIRMET_BASE_URL,
        appURL: config?.GW_APP_URL,
        authTokenURL: config?.GW_AUTH_TOKEN_URL,
        authClientId: config?.GW_AUTH_CLIENT_ID,
      },
      auth,
      onSetAuth,
      productType: 'airmet',
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ComponentsLookUpWrapper as any,
  );

  const warningListComponent = warningListComponentsLookUp(
    payload as WarningListComponentsLookUpPayLoad,
    {
      config: {
        baseURL: config?.GW_DRAWINGS_BASE_URL,
        appURL: config?.GW_APP_URL,
        authTokenURL: config?.GW_AUTH_TOKEN_URL,
        authClientId: config?.GW_AUTH_CLIENT_ID,
      },
      auth,
      onSetAuth,
    },
  );

  const spaceweatherComponent = spaceweatherComponentsLookUp(
    payload as SpaceweatherComponentsLookUpPayload,
    {
      config: {
        baseURL: config?.GW_SW_BASE_URL,
        appURL: config?.GW_APP_URL,
        authTokenURL: config?.GW_AUTH_TOKEN_URL,
        authClientId: config?.GW_AUTH_CLIENT_ID,
      },
      auth,
      onSetAuth,
    },
  );

  return (
    coreComponent ||
    timeseriesComponent ||
    tafComponent ||
    capComponent ||
    sigmetComponent ||
    airmetComponent ||
    warningListComponent ||
    spaceweatherComponent
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { ApiModule, ApiProvider, ApiUrls } from '@opengeoweb/api';
import { ErrorBoundary } from '@opengeoweb/shared';
import { NotificationTrigger } from '../NotificationTrigger/NotificationTrigger';
import { NotificationTriggerProvider } from '../NotificationTrigger/NotificationTriggerContext';
import { createApi } from '../../utils/api';
import { Layout } from '../Layout';
import { Bulletin } from '../Bulletin';
import { TimeSeries } from '../TimeSeries';
import Notifications from '../Notifications/Notifications';

const spaceweatherViewPreset = {
  title: 'Spaceweather module',
  id: 'spaceweather',
  componentType: 'SpaceweatherModule',
  initialProps: {},
};

type SpaceweatherComponentType = 'SpaceweatherModule';

export interface ComponentsLookUpPayload {
  componentType: SpaceweatherComponentType;
  id: string;
  title?: string;
  initialProps: Record<string, never>;
}

// base type for connected modules
export interface ApiModuleProps extends ApiModule {
  config: ApiUrls;
}

export const SpaceweatherApiWrapper: React.FC<ApiModuleProps> = ({
  config,
  auth,
  onSetAuth,
}: ApiModuleProps) => {
  return config ? (
    <ApiProvider
      config={{
        baseURL: config.baseURL,
        appURL: config.baseURL,
        authTokenURL: config.authTokenURL,
        authClientId: config.authClientId,
      }}
      auth={auth!}
      onSetAuth={onSetAuth}
      createApi={createApi}
    >
      <SpaceweatherWrapper />
    </ApiProvider>
  ) : null;
};

export const SpaceweatherWrapper: React.FC = () => (
  <NotificationTriggerProvider>
    <NotificationTrigger />
    <ErrorBoundary>
      <Layout
        leftTop={<Bulletin />}
        leftBottom={<Notifications />}
        right={<TimeSeries />}
      />
    </ErrorBoundary>
  </NotificationTriggerProvider>
);

export const componentsLookUp = (
  payload: ComponentsLookUpPayload,
  { config, auth, onSetAuth }: ApiModuleProps,
  // TODO: find a good way to type this module without the apps complaining
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ConnectedModuleWrapper: any = null,
): React.ReactElement => {
  const { componentType } = payload;
  switch (componentType) {
    case spaceweatherViewPreset.componentType:
      if (ConnectedModuleWrapper) {
        return (
          <ConnectedModuleWrapper
            component={SpaceweatherApiWrapper}
            payload={payload}
            data-testid="spaceweathermodule"
            config={config}
            auth={auth}
            onSetAuth={onSetAuth}
          />
        );
      }
      return (
        <SpaceweatherApiWrapper
          data-testid="spaceweathermodule"
          config={config}
          auth={auth}
          onSetAuth={onSetAuth}
        />
      );
    default:
      return null!;
  }
};

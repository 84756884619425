/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { uiActions } from '@opengeoweb/store';
import { AlertColor } from '@mui/material';
import { snackbarTypes } from '@opengeoweb/snackbar';
import { DrawingListItem } from '../warningsDrawings/types';
import { publicWarningDialogType } from './utils';
import { Area, PublicWarning, Warning, WarningType } from './types';

export type FormAction = '' | 'saving' | 'publishing' | 'readonly' | 'edit';
export interface FormError {
  severity?: AlertColor;
  message: string;
}

export interface WarningFormState {
  // form data
  object?: DrawingListItem;
  warning?: Warning;
  // form state
  formState?: FormAction;
  error?: FormError;
  selectedWarningId?: string;
  isFormDirty?: boolean;
  warningType?: WarningType;
  panelId?: string;
}
export const TAKEOVER_MESSAGE = 'You are no longer the editor of this warning';

export const initialState: WarningFormState = {};

const slice = createSlice({
  initialState,
  name: 'publicWarningForm',
  reducers: {
    setFormValues: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        object?: DrawingListItem;
        warning?: Warning;
        formState?: FormAction;
      }>,
    ) => {
      const { object, warning, formState } = action.payload;

      if (object) {
        draft.object = object;
      }

      if (warning) {
        draft.warning = warning;
      }

      if (formState !== undefined) {
        draft.formState = formState;
      }
    },

    addArea: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        area: Area;
      }>,
    ) => {
      const { area } = action.payload;
      // only support 1 area (for now)
      if (draft.warning) {
        const areaWithPreservedUUID = {
          ...draft.warning.warningDetail.areas?.at(0),
          ...area,
        };

        draft.warning.warningDetail.areas = [areaWithPreservedUUID];
      } else {
        const newWarning = {
          warningDetail: { areas: [area] },
        } as Warning;
        draft.warning = newWarning;
      }
    },

    removeArea: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        area: Area;
      }>,
    ) => {
      const { area } = action.payload;

      if (draft.warning) {
        const removeAreas = draft.warning.warningDetail.areas?.filter(
          (existingArea) => existingArea.objectName !== area.objectName,
        );
        draft.warning.warningDetail.areas = removeAreas;
      }
    },

    removeAllAreas: (draft: Draft<WarningFormState>) => {
      if (draft.warning) {
        draft.warning.warningDetail.areas = [];
      }
    },

    openPublicWarningFormDialog: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        object?: DrawingListItem;
        formState?: FormAction;
        warning?: Warning;
        warningType?: WarningType;
        panelId?: string;
      }>,
    ) => {
      const {
        object,
        warning,
        formState = '',
        warningType = 'public',
        panelId,
      } = action.payload;

      draft.object = object;
      draft.warning = warning;
      draft.selectedWarningId = warning?.id;
      draft.formState = formState;
      draft.warningType = warning?.type || warningType;
      draft.panelId = panelId;
    },

    // publish warning
    publishWarning: (
      draft: Draft<WarningFormState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        publicWarning: PublicWarning;
        refetchWarnings?: () => void;
      }>,
    ) => {
      draft.formState = 'publishing';
      delete draft.error;
    },
    publishWarningSuccess: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        publicWarning: PublicWarning;
        message: snackbarTypes.SnackbarMessage;
      }>,
    ) => {
      const { publicWarning } = action.payload;
      draft.warning = publicWarning;
      // If form has been published, set form to readonly
      draft.formState = 'readonly';
    },
    // save warning
    saveWarning: (
      draft: Draft<WarningFormState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        publicWarning: PublicWarning;
        refetchWarnings?: () => void;
      }>,
    ) => {
      draft.formState = 'saving';
      delete draft.error;
      draft.warning = action.payload.publicWarning;
    },
    saveWarningSuccess: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        publicWarning: Warning;
        message: snackbarTypes.SnackbarMessage;
      }>,
    ) => {
      const { publicWarning } = action.payload;
      draft.warning = publicWarning;
      if (publicWarning?.warningDetail?.id) {
        draft.selectedWarningId = publicWarning.warningDetail.id;
      }
      draft.formState = '';
    },
    setFormError: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<FormError>,
    ) => {
      const { message, severity = 'error' } = action.payload;
      draft.formState = '';
      draft.error = {
        severity,
        message,
      };
    },
    removeFormError: (draft: Draft<WarningFormState>) => {
      delete draft.error;
    },
    setFormDirty: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        isFormDirty: boolean;
      }>,
    ) => {
      const { isFormDirty } = action.payload;
      draft.isFormDirty = isFormDirty;
    },
    setEditMode: (
      draft: Draft<WarningFormState>,
      action: PayloadAction<{
        warningId: string;
      }>,
    ) => {
      const { warningId } = action.payload;
      draft.selectedWarningId = warningId;
      draft.formState = 'edit';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      uiActions.setToggleOpenDialog,
      (draft: Draft<WarningFormState>, action) => {
        const { type, setOpen } = action.payload;
        if (type === publicWarningDialogType && setOpen === false) {
          delete draft.object;
          delete draft.warning;
          delete draft.formState;
          delete draft.error;
          delete draft.selectedWarningId;
          delete draft.isFormDirty;
          delete draft.warningType;
        }
      },
    );
  },
});

export const {
  reducer: publicWarningFormReducer,
  actions: publicWarningFormActions,
} = slice;

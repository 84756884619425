/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { RefObject } from 'react';
import { Warning, PublicWarningStatus } from '../store/publicWarningForm/types';
import {
  PickedFilterState,
  OptionKey,
} from '../components/FilterList/filter-hooks';
import { warningPhenomena } from '../components/PublicWarningsForm/PhenomenonField';
import { emptyFilterId } from '../components/FilterList/filter-utils';

interface OverflowCheckResult {
  ref: RefObject<HTMLDivElement>;
  isOverflowing: boolean;
}

export const getSortedStatus = (
  status: PublicWarningStatus,
): PublicWarningStatus => {
  switch (status) {
    case 'DRAFT_PUBLISHED': {
      return PublicWarningStatus.DRAFT;
    }
    default: {
      return status;
    }
  }
};

export const getWarningWithoutIdStatusEditor = (warning: Warning): Warning => {
  const { id, warningDetail, status, editor, ...fullWarning } = warning;

  const {
    id: warningDetailId,
    linked_to_id: linkedToId,
    proposal_id: proposalId,
    warning_handle_uuid: warningHandleId,
    ...cleanWarningDetail
  } = warningDetail;

  const areas = warningDetail.areas?.map(
    ({ published_warning_uuid, ...area }) => area,
  );

  return {
    ...fullWarning,
    warningDetail: { ...cleanWarningDetail, areas },
  };
};

export const getWarningWithLinkedToId = (warning: Warning): Warning => {
  const { id, warningDetail } = warning;
  const { id: warningDetailId, ...cleanWarningDetail } = warningDetail;

  return {
    ...warning,
    warningDetail: { ...cleanWarningDetail, linked_to_id: id },
    status: PublicWarningStatus.DRAFT_PUBLISHED,
  };
};

export const getWarningWithProposalId = (warning: Warning): Warning => {
  const { id, warningDetail, ...fullWarning } = warning;
  const { id: warningDetailId, ...cleanWarningDetail } = warningDetail;

  return {
    ...fullWarning,
    warningDetail: { ...cleanWarningDetail, proposal_id: id },
  };
};

export default function useOverflowCheck(text: string): OverflowCheckResult {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState<boolean>(false);

  React.useEffect(() => {
    const checkOverflow = (): void => {
      if (ref.current) {
        setIsOverflowing(ref.current.scrollWidth > ref.current.clientWidth);
      }
    };

    checkOverflow();
  }, [text]);

  return { ref, isOverflowing };
}

export const getTranslationKey = (
  optionId: OptionKey<keyof PickedFilterState>,
  id: string,
): string => {
  if (id === 'source' || id === 'incident') {
    if (optionId === emptyFilterId) {
      return `warning-unspecified`;
    }
    return optionId;
  }
  const phenomena = warningPhenomena.find(
    (phenomenon) => phenomenon.key === optionId,
  );
  return phenomena
    ? phenomena.translationKey
    : `warning-${id}-${optionId as string}`;
};

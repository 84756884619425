/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box, List, Typography } from '@mui/material';

import { CustomIconButton } from '@opengeoweb/shared';
import { Add, Delete } from '@opengeoweb/theme';
import { syncGroupsTypes } from '@opengeoweb/store';
import { SyncGroupListItem } from './SyncGroupListItem';
import { useCoreTranslation } from '../../utils/i18n';

interface SyncGroupProps {
  viewStateData: syncGroupsTypes.GroupsAndSources;
  title: string;
  handleToggle: (arg1: string, arg2?: string) => void;
  addNewGroup: (number: string) => void;
  removeGroup: (number: string) => void;
}

export const SyncGroupList: React.FC<SyncGroupProps> = ({
  viewStateData,
  title,
  handleToggle,
  addNewGroup,
  removeGroup,
}: SyncGroupProps) => {
  const { t } = useCoreTranslation();
  return (
    <List disablePadding={true}>
      {viewStateData.groups.map((groupObject, index) => {
        return (
          <Box
            key={groupObject.id}
            sx={{
              ...(index !== 0 && {
                backgroundColor: 'geowebColors.syncGroups.drawerOpen.fill',
                boxShadow: 'inset 0px 7px 7px -7px, inset 0px -7px 7px -7px',
                color: '#000000',
              }),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'geowebColors.typographyAndIcons.text',
                paddingLeft: '15px',
                paddingTop: '10px',
              }}
            >
              <Typography>{title}</Typography>
              <CustomIconButton
                tooltipTitle={
                  index === 0
                    ? t('syncgroups-add-group')
                    : t('syncgroups-remove-group')
                }
                style={{ right: '10px' }}
                data-testid="testAddButton"
                onClick={
                  index === 0
                    ? (): void => addNewGroup(groupObject.id)
                    : (): void => removeGroup(groupObject.id)
                }
              >
                {index === 0 ? <Add /> : <Delete />}
              </CustomIconButton>
            </Box>
            <SyncGroupListItem
              viewStateData={viewStateData}
              groupId={groupObject.id}
              selected={groupObject.selected}
              sourcesById={viewStateData.sourcesById}
              handleToggle={(sourceId): void =>
                handleToggle(groupObject.id, sourceId)
              }
            />
          </Box>
        );
      })}
    </List>
  );
};

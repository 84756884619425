/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Grid2,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  CustomIconButton,
  CustomTooltip,
  PresetScope,
  SearchHighlight,
  ToggleMenu,
} from '@opengeoweb/shared';
import {
  Delete,
  Preset,
  PresetTiles,
  PresetWorkspace,
} from '@opengeoweb/theme';
import React from 'react';
import {
  WorkspacePresetAction,
  WorkspacePresetListItem,
} from '../../store/workspace/types';
import { useWorkspaceTranslation } from '../../utils/i18n';

export const WORKSPACE_SELECT_LIST_ROW_HEIGHT = 46;

export const getPresetIcon = (
  preset: WorkspacePresetListItem,
): React.ReactElement => {
  switch (preset.viewType) {
    case 'tiledWindow':
      return <PresetTiles data-testid="tiledWindowIcon" />;
    case 'multiWindow':
      return <PresetWorkspace data-testid="multiWindowIcon" />;
    case 'singleWindow':
    default:
      return <Preset data-testid="singleWindowIcon" />;
  }
};

interface WorkspaceSelectListRowProps {
  workspacePreset: WorkspacePresetListItem;
  onClickWorkspacePreset: (id: string) => void;
  onClickWorkspacePresetOption: (
    presetId: string,
    workspaceAction: WorkspacePresetAction,
    workspacePreset: WorkspacePresetListItem,
  ) => void;
  isOptionsMenuOpen?: boolean;
  isEditable?: boolean;
  isAdmin?: boolean;
  searchQuery?: string;
  isSelected?: boolean;
  testIdPrefix?: string; // Specifies if used for workspace or viewpreset, added to testids
}

const WorkspaceSelectListRow: React.FC<WorkspaceSelectListRowProps> = ({
  workspacePreset,
  isSelected,
  onClickWorkspacePreset,
  onClickWorkspacePresetOption,
  isOptionsMenuOpen = false,
  isEditable = false,
  isAdmin = false,
  searchQuery = '',
  testIdPrefix = 'workspace',
}) => {
  const { t } = useWorkspaceTranslation();

  const onDuplicate = (): void => {
    onClickWorkspacePresetOption(
      workspacePreset.id,
      WorkspacePresetAction.DUPLICATE,
      workspacePreset,
    );
  };

  const onEdit = (): void => {
    onClickWorkspacePresetOption(
      workspacePreset.id,
      WorkspacePresetAction.EDIT,
      workspacePreset,
    );
  };

  const onShare = (): void => {
    onClickWorkspacePresetOption(
      workspacePreset.id,
      WorkspacePresetAction.SHARE,
      workspacePreset,
    );
  };

  const menuItems: [
    { text: string; action: () => void; isDisabled?: boolean },
  ] = [{ text: t('workspace-duplicate'), action: onDuplicate }];

  (workspacePreset.scope === PresetScope.user || isAdmin) &&
    menuItems.push({
      text: t('workspace-edit'),
      action: onEdit,
    });
  workspacePreset.scope === PresetScope.user &&
    menuItems.push({
      text: t('workspace-share'),
      action: onShare,
      isDisabled: true,
    });
  workspacePreset.scope === PresetScope.shared &&
    menuItems.push({
      text: t('workspace-stop-share'),
      action: onShare,
      isDisabled: true,
    });

  return (
    <ListItem
      disablePadding
      sx={{
        marginBottom: 0.5,
        backgroundColor: 'geowebColors.cards.cardContainer',
        ' .MuiListItemText-primary': {
          fontSize: '12px',
          fontWeight: 500,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
        ' .MuiListItemText-secondary': {
          fontSize: '10px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          lineHeight: '1.2',
          letterSpacing: '0.18px',
          whiteSpace: 'nowrap',
          color: 'geowebColors.typographyAndIcons.text',
        },
      }}
    >
      <ListItemButton
        data-testid={`${testIdPrefix}-selectListRow`}
        selected={isSelected}
        onClick={(): void => {
          onClickWorkspacePreset(workspacePreset.id);
        }}
        sx={{
          padding: 1,
          height: `${WORKSPACE_SELECT_LIST_ROW_HEIGHT}px`,
        }}
        role="listitem"
        aria-label={testIdPrefix}
      >
        <ListItemIcon>{getPresetIcon(workspacePreset)}</ListItemIcon>
        <CustomTooltip title={workspacePreset.title}>
          <ListItemText
            primary={
              <SearchHighlight
                text={workspacePreset?.title}
                search={searchQuery}
              />
            }
            secondary={
              <SearchHighlight
                text={workspacePreset?.abstract}
                search={searchQuery}
              />
            }
            sx={{ margin: 0 }}
          />
        </CustomTooltip>
        {isEditable && (
          <Grid2 sx={{ minWidth: 56, marginLeft: 2 }}>
            <Grid2>
              <ToggleMenu
                buttonTestId={`${testIdPrefix}-listOptionsButton`}
                menuPosition="right"
                menuItems={menuItems}
                menuTitle={t('workspace-options')}
                isDefaultOpen={isOptionsMenuOpen}
              />
              {(workspacePreset.scope === 'user' || isAdmin) &&
                workspacePreset.id && (
                  <CustomIconButton
                    tooltipTitle={t('workspace-delete-tooltip')}
                    onClick={(event: React.MouseEvent): void => {
                      event.stopPropagation();
                      onClickWorkspacePresetOption(
                        workspacePreset.id,
                        isAdmin
                          ? WorkspacePresetAction.DELETE_SYSTEM_PRESET
                          : WorkspacePresetAction.DELETE,
                        workspacePreset,
                      );
                    }}
                    variant="tool"
                    sx={{
                      marginLeft: 1,
                    }}
                    data-testid={`${testIdPrefix}-listDeleteButton`}
                  >
                    <Delete />
                  </CustomIconButton>
                )}
            </Grid2>
          </Grid2>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default WorkspaceSelectListRow;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  Copy,
  Edit,
  Expire,
  Remove,
  Delete,
  Success,
  Update,
} from '@opengeoweb/theme';
import { MenuItemType, dateUtils } from '@opengeoweb/shared';
import {
  AviationProduct,
  isInstanceOfCancelAirmet,
  isInstanceOfCancelSigmet,
  ProductAction,
} from '@opengeoweb/sigmet-airmet';
import { ProductStatus } from '@opengeoweb/api';
import { TFunction } from 'i18next';
import {
  Warning,
  PublicWarningStatus,
} from '../../store/publicWarningForm/types';
import {
  getWarningWithoutIdStatusEditor,
  getWarningWithLinkedToId,
  getWarningWithProposalId,
} from '../../utils/warningUtils';

export interface WarningListItemActions {
  onEditWarning?: (warning: Warning) => void;
  onDeleteWarning?: (warningId: string, warningEditor?: string) => void;
  onDeleteReviewWarning?: (warning: Warning) => void;
  onExpireWarning?: (warning: Warning) => void;
  onWithdrawWarning?: (warning: Warning) => void;
  onActionAviationWarning?: (
    warning: Warning,
    actionType?: ProductAction,
  ) => void;
}
interface WarningListItemMenuProps extends WarningListItemActions {
  warning: Warning;
  t: TFunction;
}

const WarningListItemMenu = ({
  warning,
  onEditWarning = (): void => {},
  onDeleteWarning = (): void => {},
  onDeleteReviewWarning = (): void => {},
  onExpireWarning = (): void => {},
  onWithdrawWarning = (): void => {},
  onActionAviationWarning = (): void => {},
  t,
}: WarningListItemMenuProps): MenuItemType[] => {
  const duplicate = {
    text: t('warning-list-button-duplicate'),
    action: (): void => onEditWarning(getWarningWithoutIdStatusEditor(warning)),
    icon: <Copy />,
  };

  const aviationDuplicate = {
    text: t('warning-list-button-duplicate'),
    action: (): void =>
      onActionAviationWarning(warning, ProductAction.DUPLICATE),
    icon: <Copy />,
  };

  // disable Expire option if warning is not active
  const shouldDisableExpire = !dateUtils.isBetween(
    dateUtils.getNowUtc(),
    dateUtils.createDate(warning.warningDetail.validFrom)!,
    dateUtils.createDate(warning.warningDetail.validUntil)!,
    '[]',
  );

  switch (warning.status) {
    case PublicWarningStatus.PUBLISHED:
      return [
        {
          text: t('warning-list-button-update'),
          action: () => onEditWarning(getWarningWithLinkedToId(warning)),
          icon: <Edit />,
        },
        duplicate,
        {
          text: t('warning-list-button-expire'),
          action: () => onExpireWarning(warning),
          icon: <Expire />,
          isDisabled: shouldDisableExpire,
        },
        {
          text: t('warning-list-button-withdraw'),
          action: () => onWithdrawWarning(warning),
          icon: <Remove />,
          isDisabled: false,
        },
      ];
    case PublicWarningStatus.EXPIRED:
    case PublicWarningStatus.WITHDRAWN:
      return [duplicate];
    case PublicWarningStatus.DRAFT:
    case PublicWarningStatus.DRAFT_PUBLISHED:
      return [
        {
          text: t('object-manager-button-edit'),
          action: () => onEditWarning(warning),
          icon: <Edit />,
        },
        duplicate,
        {
          text: t('object-manager-button-delete'),
          action: () => onDeleteWarning(warning.id!, warning.editor),
          icon: <Delete />,
        },
      ];
    case PublicWarningStatus.TODO:
      return [
        {
          text: t('warning-list-button-use'),
          action: () => onEditWarning(getWarningWithProposalId(warning)),
          icon: <Success />,
        },
        {
          text: t('object-manager-button-delete'),
          action: () => onDeleteReviewWarning(warning),
          icon: <Delete />,
        },
      ];
    default:
      switch (warning.productStatus) {
        case ProductStatus.DRAFT:
          return [aviationDuplicate];
        case ProductStatus.PUBLISHED:
          if (
            warning.type !== 'public' &&
            (isInstanceOfCancelSigmet(
              warning.sigmetAirmetDetails as AviationProduct,
            ) ||
              isInstanceOfCancelAirmet(
                warning.sigmetAirmetDetails as AviationProduct,
              ))
          ) {
            return [];
          }
          return [
            {
              text: t('warning-list-button-renew'),
              action: (): void => {
                if (warning) {
                  onActionAviationWarning(warning);
                }
              },
              icon: <Update />,
            },
            aviationDuplicate,
          ];
        case ProductStatus.CANCELLED:
          return [];
        case ProductStatus.EXPIRED:
          if (
            warning.type !== 'public' &&
            (isInstanceOfCancelSigmet(
              warning.sigmetAirmetDetails as AviationProduct,
            ) ||
              isInstanceOfCancelAirmet(
                warning.sigmetAirmetDetails as AviationProduct,
              ))
          ) {
            return [];
          }
          return [
            {
              text: t('warning-list-button-renew'),
              action: (): void => {
                if (warning) {
                  onActionAviationWarning(warning, ProductAction.RENEW);
                }
              },
              icon: <Update />,
            },
            aviationDuplicate,
          ];
        default:
          return [];
      }
  }
};

export default WarningListItemMenu;

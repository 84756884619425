/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { ControlButtons } from '@opengeoweb/timeslider';
import { BackwardForwardStepButtonConnect } from './BackwardForwardStepButtonConnect';
import { PlayButtonConnect } from './PlayButtonConnect';
import { AutoUpdateButtonConnect } from './AutoUpdateButtonConnect';
import { NowButtonConnect } from './NowButtonConnect';

export const ControlButtonsConnect: React.FC<{
  sourceId: string;
  mapId: string;
}> = ({ sourceId, mapId }) => {
  return (
    <ControlButtons
      nowBtn={<NowButtonConnect mapId={mapId} sourceId={sourceId} />}
      autoUpdateBtn={<AutoUpdateButtonConnect mapId={mapId} />}
      playBtn={<PlayButtonConnect mapId={mapId} />}
      backwardBtn={
        <BackwardForwardStepButtonConnect mapId={mapId} isForwardStep={false} />
      }
      forwardBtn={
        <BackwardForwardStepButtonConnect mapId={mapId} isForwardStep={true} />
      }
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItemButton,
  ListItemText,
  Theme,
  SxProps,
  Collapse,
} from '@mui/material';
import {
  workspaceActions,
  selectors as workspaceSelectors,
  WorkspaceModuleStore,
  createBalancedMosaicNode,
} from '@opengeoweb/workspace';
import { uiActions, uiTypes } from '@opengeoweb/store';
import { ConfigType } from '@opengeoweb/shared';
import { ChevronDown, ChevronUp } from '@opengeoweb/theme';

const styles = {
  paper: {
    '& .MuiDrawer-paper': {
      top: '40px',
    },
  },
  drawer: {
    width: '250px',
    height: 'calc(100% -  40px)',
    overflowY: 'scroll',
  },
  nested: (theme: Theme): SxProps<Theme> => ({
    paddingLeft: theme.spacing(4),
  }),
  listItemPrimary: {
    '& .MuiListItemText-primary': {
      fontWeight: '500',
    },
  },
};
export const PUBLIC_WARNINGS = 'Warnings';
export const publicWarningDialogType = uiTypes.DialogTypes.PublicWarnings;
export const WARNINGS_WORKSPACE_ID = 'screenConfigWarnings';
export const SPACEWEATHER_WORKSPACE_ID = 'spaceweather';

export interface MenuProps {
  config?: ConfigType;
}

const Menu: React.FC<MenuProps> = ({ config }: MenuProps) => {
  const dispatch = useDispatch();

  const mosaicNode = useSelector((store: WorkspaceModuleStore) =>
    workspaceSelectors.getMosaicNode(store),
  );

  const autoArrange = (): void => {
    dispatch(
      workspaceActions.updateWorkspaceViews({
        mosaicNode: createBalancedMosaicNode(mosaicNode),
      }),
    );
  };

  const openSyncgroupsDialog = React.useCallback(() => {
    dispatch(
      uiActions.setToggleOpenDialog({
        type: uiTypes.DialogTypes.SyncGroups,
        setOpen: true,
      }),
    );
  }, [dispatch]);

  const fetchWorkspace = React.useCallback(
    (workspaceId: string) => {
      dispatch(workspaceActions.fetchWorkspace({ workspaceId }));
    },
    [dispatch],
  );

  // temporary hardcoded aviation products in waiting of workspace filters
  const showAviationMenu = config?.GW_PRESET_BACKEND_URL !== undefined;
  const [openAviation, setOpenAviation] = React.useState(true);
  const aviationProducts = [
    { id: 'screenConfigTaf', title: 'TAF' },
    { id: 'screenConfigSigmet', title: 'SIGMET' },
    { id: 'screenConfigAirmet', title: 'AIRMET' },
  ];

  return (
    <List data-testid="drawer">
      {/* Auto arrange windows */}
      <ListItemButton
        onClick={(): void => {
          autoArrange();
        }}
      >
        <ListItemText
          sx={styles.listItemPrimary}
          primary="Auto Arrange Windows"
        />
      </ListItemButton>
      {/* Syn Groups */}
      <ListItemButton
        data-testid="syncGroupsButton"
        onClick={(): void => {
          openSyncgroupsDialog();
        }}
      >
        <ListItemText sx={styles.listItemPrimary} primary="Sync Groups" />
      </ListItemButton>
      {
        /* Space Weather */
        config?.GW_FEATURE_MODULE_SPACE_WEATHER && (
          <ListItemButton
            key={SPACEWEATHER_WORKSPACE_ID}
            onClick={(): void => {
              fetchWorkspace(SPACEWEATHER_WORKSPACE_ID);
            }}
          >
            <ListItemText
              sx={styles.listItemPrimary}
              primary="Space Weather Forecast"
            />
          </ListItemButton>
        )
      }

      {showAviationMenu && (
        <>
          <ListItemButton
            onClick={(event: React.MouseEvent): void => {
              event.stopPropagation();
              setOpenAviation(!openAviation);
            }}
          >
            <ListItemText sx={styles.listItemPrimary} primary="Aviation" />
            {openAviation ? <ChevronUp /> : <ChevronDown />}
          </ListItemButton>
          <Collapse in={openAviation} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {aviationProducts.map((aviationProduct) => (
                <ListItemButton
                  key={aviationProduct.id}
                  dense
                  sx={styles.nested as SxProps<Theme>}
                  onClick={(): void => {
                    fetchWorkspace(aviationProduct.id);
                  }}
                >
                  <ListItemText primary={aviationProduct.title} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </>
      )}

      {/** Public warnings */}
      {config?.GW_DRAWINGS_BASE_URL !== undefined &&
        config?.GW_PRESET_BACKEND_URL !== undefined && (
          <ListItemButton
            onClick={(): void => {
              fetchWorkspace(WARNINGS_WORKSPACE_ID);
            }}
          >
            <ListItemText
              sx={styles.listItemPrimary}
              primary={PUBLIC_WARNINGS}
            />
          </ListItemButton>
        )}
    </List>
  );
};

export default Menu;

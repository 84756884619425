/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

// eslint-disable-next-line @typescript-eslint/naming-convention
let _generatedServiceIds = 0;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const _generateServiceId = (): string => {
  _generatedServiceIds += 1;
  return `serviceid_${_generatedServiceIds}`;
};
// eslint-disable-next-line @typescript-eslint/naming-convention
export const _serviceIdLookupMap = new Map<string, string>();

/**
 * Returns the id corresponding to a WMS service url. It ensures that the id is unique across services.
 * The id can be used as a key in the redux store.
 *
 * @param {string} serviceUrl Link to a WMS service
 * @returns {string} Returns the corresponding id
 */

export const getWMSServiceId = (serviceUrl: string): string => {
  const foundId = _serviceIdLookupMap.get(serviceUrl);
  if (foundId) {
    return foundId;
  }
  const newId = _generateServiceId();
  _serviceIdLookupMap.set(serviceUrl, newId);
  return newId;
};

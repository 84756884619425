/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { snackbarReducer, snackbarListener } from '@opengeoweb/snackbar';
import {
  mapReducer,
  serviceReducer,
  layerReducer,
  uiReducer,
  mapListener,
  serviceListener,
  layersListener,
  WebMapStateModuleState,
  UIModuleState,
} from '@opengeoweb/store';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { layerSelectListener } from './listeners';
import { layerSelectReducer } from './reducer';
import { LayerSelectModuleState } from './types';

export const layerSelectReducersMap = {
  webmap: mapReducer,
  services: serviceReducer,
  layers: layerReducer,
  layerSelect: layerSelectReducer,
  ui: uiReducer,
  snackbar: snackbarReducer,
};

export const layerSelectMiddlewares = [
  mapListener.middleware,
  serviceListener.middleware,
  layersListener.middleware,
  layerSelectListener.middleware,
  snackbarListener.middleware,
];

const layerSelectRootReducer = combineReducers(layerSelectReducersMap);

export const createMockStore = (
  mockState?: WebMapStateModuleState | LayerSelectModuleState | UIModuleState,
): ToolkitStore =>
  configureStore({
    reducer: layerSelectRootReducer,
    preloadedState: mockState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(layerSelectMiddlewares),
  });

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  layerActions,
  mapSelectors,
  layerTypes,
  uiTypes,
  CoreAppStore,
} from '@opengeoweb/store';

import {
  DimensionConfig,
  dimensionConfig,
  DimensionSelectDialog,
  WEBMAP_REACT_NAMESPACE,
} from '@opengeoweb/webmap-react';
import { useTranslation } from 'react-i18next';
import DimensionSelectSliderConnect from './DimensionSelectSliderConnect';

interface DimensionSelectDialogConnectProps {
  mapId: string;
  dimensionName: string;
  isOpen: boolean;
  order?: number;
  onMouseDown?: () => void;
  onCloseDialog?: () => void;
  index?: number;
  source?: uiTypes.Source;
}

const DimensionSelectDialogConnect: React.FC<
  DimensionSelectDialogConnectProps
> = ({
  mapId,
  dimensionName,
  isOpen,
  order = 0,
  onMouseDown,
  onCloseDialog,
  index = 0,
  source = 'app',
}: DimensionSelectDialogConnectProps) => {
  const dispatch = useDispatch();
  // dimensions come from webmap-react
  const { t } = useTranslation(WEBMAP_REACT_NAMESPACE);

  const mapContainsDimension = useSelector((store: CoreAppStore) =>
    mapSelectors.getIsEnabledLayersForMapDimension(store, mapId, dimensionName),
  );

  const layerIds = useSelector((store: CoreAppStore) =>
    mapSelectors.getLayerIds(store, mapId),
  );

  const handleDimensionValueChanged = React.useCallback(
    (layerId: string, dimensionName: string, newValue: string): void => {
      dispatch(
        layerActions.layerChangeDimension({
          origin: layerTypes.LayerActionOrigin.layerManager,
          layerId,
          dimension: {
            name: dimensionName,
            currentValue: newValue,
          },
          mapId,
        }),
      );
    },
    [dispatch, mapId],
  );

  const handleSyncChanged = React.useCallback(
    (
      layerId: string,
      dimensionName: string,
      dimensionValue: string,
      synced: boolean,
    ): void => {
      dispatch(
        layerActions.layerChangeDimension({
          origin: layerTypes.LayerActionOrigin.layerManager,
          layerId,
          dimension: {
            name: dimensionName,
            currentValue: dimensionValue,
            synced,
          },
        }),
      );
    },
    [dispatch],
  );

  if (!mapContainsDimension || !layerIds || layerIds.length < 1) {
    return null;
  }

  const dimConfig: DimensionConfig = dimensionConfig(t).find(
    (cnf) => cnf.name === dimensionName,
  )!;
  const title = dimConfig ? dimConfig.label : dimensionName;

  return (
    <DimensionSelectDialog
      dimensionName={dimensionName}
      isOpen={isOpen}
      data-testid={`multidimensionContent-${title}`}
      onClose={onCloseDialog}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
      index={index}
    >
      {layerIds.map((layerId) => (
        <DimensionSelectSliderConnect
          key={layerId}
          handleDimensionValueChanged={handleDimensionValueChanged}
          handleSyncChanged={handleSyncChanged}
          layerId={layerId}
          dimensionName={dimensionName}
          mapId={mapId}
        />
      ))}
    </DimensionSelectDialog>
  );
};
export default DimensionSelectDialogConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';

interface OverflowTooltipProps {
  text: string;
  textContainerWidth: number;
  children?: ReactNode;
}

export const OverflowTooltip: React.FC<OverflowTooltipProps> = ({
  text,
  textContainerWidth,
  children,
}) => {
  const textMeasureRef = React.useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const handleMouseEnter = (): void => {
    const overflowMargin = 50;
    const textWidth = textMeasureRef.current?.scrollWidth;
    if (textWidth) {
      setIsOverflowing(textWidth > textContainerWidth - overflowMargin);
    }
  };

  const handleMouseLeave = (): void => {
    setIsOverflowing(false);
  };

  return (
    <>
      <Tooltip
        data-testid="overflowTooltip"
        title={isOverflowing ? text : ''}
        placement="top"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box sx={{ maxWidth: '100%' }}>{children}</Box>
      </Tooltip>

      <div
        data-testid="measurementDiv"
        ref={textMeasureRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
          width: 'auto',
        }}
      >
        {text}
      </div>
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

// eslint-disable-next-line max-classes-per-file
import { debugLogger, DebugType } from './WMJSTools';

interface CallBack {
  name: string;
  finished: number;
  keepOnCall: boolean;
  functionpointer: (param?: unknown, that?: unknown) => void;
}

export default class WMListener {
  private registeredEvents: Map<string, CallBack[]>;

  private _suspendedEvents: Map<string, boolean>;

  private _suspendAllEvents;

  constructor() {
    this.addEventListener = this.addEventListener.bind(this);
    this.removeEventListener = this.removeEventListener.bind(this);
    this.removeEventListeners = this.removeEventListeners.bind(this);
    this.triggerEvent = this.triggerEvent.bind(this);
    this.destroy = this.destroy.bind(this);
    this.suspendEvent = this.suspendEvent.bind(this);
    this.resumeEvent = this.resumeEvent.bind(this);
    this.suspendEvents = this.suspendEvents.bind(this);
    this.resumeEvents = this.resumeEvents.bind(this);

    // Deprecated routines:
    this.removeEvents = this.removeEvents.bind(this);

    this._suspendAllEvents = false;
    this.registeredEvents = new Map<string, CallBack[]>();
    this._suspendedEvents = new Map<string, boolean>();
  }

  /**
   * Remove events by name and function.
   * @param name Event name
   * @param f  Function pointer to check
   */
  public removeEventListener(
    name: string,
    f: (param?: unknown) => unknown,
  ): number {
    return this.removeEvents(name, f);
  }

  /**
   * Remove all events by name
   * @param name Event name
   */
  public removeEventListeners(name: string): number {
    return this.removeEvents(name);
  }

  /**
   * Add multiple functions which will be called after the event with the same name is triggered
   * @param name: The name of the event to register
   * @param function: The function to trigger
   * @param keepOnCall: When false, this event name and function combination is removed once triggered.
   *                    When true Otherwise keep the event and allow it to be triggered multiple times
   * @return: true if the event is registerd, false if it already was registered
   */
  public addEventListener(
    name: string,
    functionpointer: (param: unknown) => void,
    keepOnCall = true,
  ): boolean {
    const events = this.registeredEvents.get(name);

    // This event name has no function callbacks associated, just add it.
    if (!events || events?.length === 0) {
      this.registeredEvents.set(name, [
        { name, functionpointer, keepOnCall, finished: 0 },
      ]);
      return true;
    }

    // Check if this function callback was already associated. If so, do nothing.
    if (
      events?.findIndex((c) => c.functionpointer === functionpointer) !== -1
    ) {
      return false;
    }
    events.push({ name, functionpointer, keepOnCall, finished: 0 });
    return true;
  }

  /**
   * Destroys this listener, deregistering all events
   */
  public destroy(): void {
    this.registeredEvents.clear();
  }

  /**
   * Suspend a specific event by name, triggers will not be called
   * @param name
   */
  public suspendEvent(name: string): void {
    this._suspendedEvents.set(name, true);
  }

  /**
   * Resume a specific event by name, resuming normal operation
   * @param name
   */
  public resumeEvent(name: string): void {
    this._suspendedEvents.set(name, false);
  }

  /**
   * This listener will not listen to any triggers
   */
  public suspendEvents(): void {
    this._suspendAllEvents = true;
  }

  /**
   * Resume event listening
   */
  public resumeEvents(): void {
    this._suspendAllEvents = false;
  }

  /**
   * Trigger an event with custom parameters
   * @param name The name of the event to trigger
   * @param param The parameters to provide to the associated function
   * @returns The results of the function.
   */
  public triggerEvent(name: string, param?: unknown): unknown[] {
    if (
      this._suspendAllEvents === true ||
      this._suspendedEvents.get(name) === true
    ) {
      return [];
    }
    const returnList: unknown[] = [];
    const events = this.registeredEvents.get(name);
    if (events && events.length > 0) {
      for (const myEvent of events) {
        if (myEvent.finished === 0) {
          // Mark the following as finished: it needs to be removed
          if (myEvent.keepOnCall === false) {
            myEvent.finished = 1;
          }
          try {
            returnList.push(myEvent.functionpointer(param));
          } catch (e) {
            debugLogger(
              DebugType.Error,
              `Error for event [${name}]: `,
              param,
              e,
            );
          }
        }
      }
    }
    // Now remove all finished events
    this.removeFinishedEvents();
    return returnList;
  }

  private removeFinishedEvents(): void {
    this.registeredEvents.forEach((callbacks, name) => {
      this.registeredEvents.set(
        name,
        callbacks.filter((c) => !c.finished),
      );
    });
  }

  public removeEvents(name: string, f?: (param?: unknown) => unknown): number {
    let numberOfRemovedEvents = 0;
    const events = this.registeredEvents.get(name);
    if (events && events.length > 0) {
      for (const myEvent of events) {
        if (myEvent.finished === 0) {
          if (!f) {
            myEvent.finished = 1;
            numberOfRemovedEvents += 1;
          } else if (myEvent.functionpointer === f) {
            myEvent.finished = 1;
            numberOfRemovedEvents += 1;
          }
        }
      }
    }
    this.removeFinishedEvents();
    return numberOfRemovedEvents;
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { AlertBanner } from '@opengeoweb/shared';
import { useNotificationTriggerContext } from './NotificationTriggerContext';
import { useSpaceweatherTranslation } from '../../utils/i18n';

export const NotificationTrigger: React.FC = () => {
  const { notificationTriggers, error } = useNotificationTriggerContext();
  const { t } = useSpaceweatherTranslation();
  if (error) {
    return (
      <AlertBanner
        dataTestId="notificationtrigger-error"
        severity="error"
        title={`${t('notification-trigger-error')} ${error.message}`}
      />
    );
  }
  return notificationTriggers && notificationTriggers.length > 0 ? (
    <AlertBanner
      dataTestId="notificationtrigger-alert"
      severity="warning"
      title={`${notificationTriggers.length === 1 ? t('notification-trigger-single') : t('notification-trigger-multiple', { number: notificationTriggers.length })}`}
    />
  ) : null;
};

export default NotificationTrigger;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { dateUtils, PROJECTION } from '@opengeoweb/shared';

import {
  IWMJSMap,
  WMJSMap,
  WMLayer,
  WMBBOX,
  webmapUtils,
  tilesettings,
  getWMLayerById,
  registerWMLayer,
  LayerType,
  TileServerSettings,
} from '@opengeoweb/webmap';
import { debounce, isEqual } from 'lodash';

import { MapLocation, ReactMapViewProps } from './types';
import {
  addWMLayerPropsBasedOnChildProps,
  getFeatureLayers,
  makeLayerPropListFromChildren,
  orderLayers,
  removeWMLayerFromMap,
  setWMLayerPropsBasedOnChildProps,
} from './utils';
import { MapDrawContainer } from '../MapDraw';
import type { MapViewLayerProps } from '../MapView';
import { getLayerUpdateInfo } from './ReactMapViewParseLayer';

const getDisplayText = (currentAdagucTime: string): string => {
  const timeFormat = 'eee dd MMM yyyy HH:mm [UTC]';
  const adagucTime = dateUtils.utc(currentAdagucTime);
  const adagucTimeFormatted =
    dateUtils.dateToString(adagucTime, timeFormat) || '';
  return adagucTimeFormatted;
};

export const ORIGIN_REACTMAPVIEW_ONMAPCHANGEDIMENSION =
  'ORIGIN_REACTMAPVIEW_ONMAPCHANGEDIMENSION';

export const ORIGIN_REACTMAPVIEW_ONUPDATELAYERINFO =
  'ORIGIN_REACTMAPVIEW_ONUPDATELAYERINFO';

export const MINUTE_TO_MILLISECOND = 60000;

interface ReactMapViewState {
  adagucInitialised: boolean;
}

interface AdagucObjectProp {
  initialized: boolean;
  baseLayers: WMLayer[];
  oldbbox: WMBBOX;
  currentWidth: number;
  currentHeight: number;
  currentChildren: MapViewLayerProps[];
}
export class ReactMapView extends React.Component<
  ReactMapViewProps,
  ReactMapViewState
> {
  adaguc: AdagucObjectProp = {
    initialized: false,
    baseLayers: [],
    oldbbox: undefined!,
    currentWidth: -1,
    currentHeight: -1,
    currentChildren: [],
  };

  adagucContainerRef;

  adagucWebMapJSRef;

  tileServerSettings: TileServerSettings;
  refetchTimer: NodeJS.Timeout | number | null = null!;
  lastRefetched: number | null = null;

  static defaultProps = {
    srs: PROJECTION.EPSG_3857.value,
    bbox: {
      left: -2324980.5498391856,
      bottom: 5890854.775012179,
      right: 6393377.702660825,
      top: 11652109.058827976,
    },
    shouldAutoFetch: true,
    displayMapPin: false,
    disableMapPin: false,
    tileServerSettings: tilesettings,
    onWMJSMount: (): void => {},
    onWMJSUnMount: (): void => {},
    onMapChangeDimension: (): void => {
      /* nothing */
    },
    onUpdateLayerInformation: (): void => {
      /* nothing */
    },
    onMapZoomEnd: (): void => {
      /* nothing */
    },
  };

  constructor(props: ReactMapViewProps) {
    super(props);
    this.state = {
      adagucInitialised: false,
    };
    this.tileServerSettings =
      props.tileServerSettings || ReactMapView.defaultProps.tileServerSettings;
    this.resize = this.resize.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.drawDebounced = debounce(this.drawDebounced, 600);
    this.updateWMJSMapProps = this.updateWMJSMapProps.bind(this);
    this.mountWMJSMap = this.mountWMJSMap.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.onAfterSetBBoxListener = this.onAfterSetBBoxListener.bind(this);
    this.onUpdateBBoxListener = this.onUpdateBBoxListener.bind(this);
    this.onDimChangeListener = this.onDimChangeListener.bind(this);
    this.adagucContainerRef = React.createRef<HTMLDivElement>();
    this.adagucWebMapJSRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    const { shouldAutoFetch, onWMJSMount, mapId } = this.props;

    /* If this components re-mounts, make sure that the initialized state is set back to false */
    this.setState({ adagucInitialised: false });

    window.addEventListener('resize', this.handleWindowResize);

    this.mountWMJSMap();

    if (shouldAutoFetch) {
      this.onStartRefetchTimer();
      document.addEventListener(
        'visibilitychange',
        this.handleVisibilityChange,
      );
    }
    onWMJSMount!(mapId);
  }

  componentDidUpdate(prevProps: ReactMapViewProps): void {
    this.updateWMJSMapProps(prevProps, this.props);
  }

  componentWillUnmount(): void {
    const { mapId, onWMJSUnMount } = this.props;
    window.removeEventListener('resize', this.handleWindowResize);

    this.clearRefetchTimer();
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
    );

    webmapUtils.unRegisterWMJSMap(mapId);

    /* Reset adaguc properties */
    this.adaguc.initialized = false;
    this.adaguc.currentHeight = -1;
    this.adaguc.currentWidth = -1;
    this.adaguc.baseLayers = [];
    this.adaguc.oldbbox = new WMBBOX();
    this.adaguc.currentChildren = [];
    onWMJSUnMount && onWMJSUnMount(mapId);
  }

  handleWindowResize(): void {
    this.resize();
  }

  onDimChangeListener(): void {
    const { mapId, onMapChangeDimension } = this.props;
    const wmjsMap = webmapUtils.getWMJSMapById(mapId);
    if (wmjsMap) {
      const timeDimension = wmjsMap.getDimension('time');
      if (timeDimension) {
        onMapChangeDimension!({
          mapId,
          origin: ORIGIN_REACTMAPVIEW_ONMAPCHANGEDIMENSION,
          dimension: {
            name: 'time',
            currentValue: timeDimension.currentValue,
          },
        });
      }
    }
  }
  onAfterSetBBoxListener(): void {
    /* Update the map after 100 ms */
    window.setTimeout(() => {
      const { bbox: MapViewBBOX, mapId, onMapZoomEnd } = this.props;
      const wmjsMap = webmapUtils.getWMJSMapById(mapId);
      if (!wmjsMap) {
        return;
      }
      const projectionInfo = wmjsMap.getProjection();
      /* Trigger onMapZoomEnd callback */
      const mapBBOX = projectionInfo.bbox;
      if (
        MapViewBBOX!.left !== mapBBOX.left ||
        MapViewBBOX!.right !== mapBBOX.right ||
        MapViewBBOX!.bottom !== mapBBOX.bottom ||
        MapViewBBOX!.top !== mapBBOX.top
      ) {
        onMapZoomEnd!({
          mapId,
          bbox: {
            left: projectionInfo.bbox.left,
            bottom: projectionInfo.bbox.bottom,
            right: projectionInfo.bbox.right,
            top: projectionInfo.bbox.top,
          },
          srs: projectionInfo.srs,
        });
      }
    }, 100);
  }

  onUpdateBBoxListener(newBbox: WMBBOX): void {
    const oldbbox =
      this.adaguc.oldbbox || new WMBBOX(ReactMapView.defaultProps.bbox);
    if (
      oldbbox.left !== newBbox.left ||
      oldbbox.right !== newBbox.right ||
      oldbbox.top !== newBbox.top ||
      oldbbox.bottom !== newBbox.bottom
    ) {
      oldbbox.left = newBbox.left;
      oldbbox.right = newBbox.right;
      oldbbox.top = newBbox.top;
      oldbbox.bottom = newBbox.bottom;
      this.adaguc.oldbbox = new WMBBOX(oldbbox);
    }
  }

  handleVisibilityChange = (): void => {
    const { shouldAutoFetch } = this.props;
    const autoFetchInterval = Number(shouldAutoFetch); // Default true -> 1 minute
    const autoFetchIntervalMilliSeconds =
      Math.abs(autoFetchInterval) * MINUTE_TO_MILLISECOND;

    if (document.visibilityState === 'visible') {
      // Check if the last refetch time is not set or if the time since the last refetch exceeds the interval
      if (
        !this.lastRefetched ||
        new Date().getTime() - this.lastRefetched >
          autoFetchIntervalMilliSeconds
      ) {
        // Refetch immediately and restart the timer
        this.onStartRefetchTimer(true);
      }
    }
  };

  /**
   * Start the refetch timer
   * @param force - Force a fetch and wmLayer parse immediately
   */
  onStartRefetchTimer = (force?: boolean): void => {
    const { shouldAutoFetch } = this.props;

    this.clearRefetchTimer();

    const autoFetchInterval = Number(shouldAutoFetch); // Default true -> 1 minute

    const refetch = (): void => {
      const isVisible = document.visibilityState === 'visible';
      if (isVisible) {
        this.lastRefetched = new Date().getTime();

        const { mapId } = this.props;
        const wmjsMap = webmapUtils.getWMJSMapById(mapId);
        const layers = wmjsMap?.getLayers();
        layers?.forEach(async (layer: WMLayer) => {
          if (layer.enabled !== false) {
            try {
              await layer.parseLayer();
              const { props } = this;
              const { mapId, onUpdateLayerInformation } = props;
              const layerInfo = getLayerUpdateInfo(layer, mapId);
              onUpdateLayerInformation && onUpdateLayerInformation(layerInfo);
            } catch (e) {
              console.error(e);
            }
          }
        });
      }
    };

    if (autoFetchInterval > 0) {
      this.refetchTimer = setInterval(
        refetch,
        Math.abs(autoFetchInterval) * MINUTE_TO_MILLISECOND,
      );
    }

    if (force) {
      refetch();
    }
  };

  clearRefetchTimer = (): void => {
    if (this.refetchTimer) {
      clearInterval(this.refetchTimer as NodeJS.Timeout);
    }
  };

  updateWMJSMapProps(
    prevProps: ReactMapViewProps,
    props: ReactMapViewProps,
  ): void {
    if (!props) {
      return;
    }

    const { mapId, children, isTimeScrollingEnabled } = props;

    if (isTimeScrollingEnabled) {
      const wmjsMap = webmapUtils.getWMJSMapById(mapId);
      wmjsMap?.detachWheelEvent();
    } else {
      const wmjsMap = webmapUtils.getWMJSMapById(mapId);
      wmjsMap?.attachWheelEvent();
    }

    if (prevProps && prevProps.mapId !== props.mapId) {
      console.warn('MapId has changed on an already mounted map!');
      const prevWMJSMap = webmapUtils.getWMJSMapById(prevProps.mapId);
      if (prevWMJSMap) {
        webmapUtils.registerWMJSMap(prevWMJSMap, mapId);
      }
    }

    const wmjsMap = webmapUtils.getWMJSMapById(mapId);
    if (!wmjsMap) {
      console.warn(`No wmjsMap ${mapId}`);
      return;
    }

    let needsRedraw = false;

    /* Check map props */
    if (!prevProps || prevProps.showLegend !== props.showLegend) {
      wmjsMap.displayLegendInMap(props.showLegend !== false);
    }
    if (!prevProps || prevProps.showScaleBar !== props.showScaleBar) {
      wmjsMap.displayScaleBarInMap(props.showScaleBar !== false);
      needsRedraw = true;
    }
    if (!prevProps || prevProps.timestep !== props.timestep) {
      wmjsMap.timestepInMinutes = props.timestep;
    }
    if (!prevProps || prevProps.holdShiftToScroll !== props.holdShiftToScroll) {
      wmjsMap.holdShiftToScroll = !!props.holdShiftToScroll;
    }

    /* Check map dimensions */
    if (!prevProps || prevProps.dimensions !== props.dimensions) {
      if (props.dimensions) {
        for (const propDimension of props.dimensions) {
          const mapDim = wmjsMap.getDimension(propDimension.name!);
          if (
            (mapDim && mapDim.currentValue !== propDimension.currentValue) ||
            !mapDim
          ) {
            wmjsMap.setDimension(
              propDimension.name!,
              propDimension.currentValue,
              false,
              false,
            );
          }
          if (
            props.displayTimeInMap &&
            propDimension.name === 'time' &&
            propDimension.currentValue !== undefined
          ) {
            needsRedraw = true;
            const displayText = getDisplayText(propDimension.currentValue);
            wmjsMap.setTimeOffset(displayText);
          }
        }
      }
    }

    /* Check if srs and BBOX is updated */
    if (!prevProps || prevProps.bbox !== props.bbox) {
      if (props.bbox!.left !== undefined) {
        const projectionInfo = wmjsMap.getProjection();
        const mapBBOX = projectionInfo.bbox;
        if (
          props.bbox!.left !== mapBBOX.left ||
          props.bbox!.right !== mapBBOX.right ||
          props.bbox!.bottom !== mapBBOX.bottom ||
          props.bbox!.top !== mapBBOX.top
        ) {
          wmjsMap.suspendEvent('onupdatebbox');
          wmjsMap.setProjection(props.srs!, new WMBBOX(props.bbox));
          wmjsMap.resumeEvent('onupdatebbox');
          wmjsMap.draw();
        }
      }
    }

    /* Check if srs */
    if (!prevProps || prevProps.srs !== props.srs) {
      if (props.bbox!.left !== undefined) {
        wmjsMap.suspendEvent('onupdatebbox');
        wmjsMap.setProjection(props.srs!, new WMBBOX(props.bbox));
        wmjsMap.resumeEvent('onupdatebbox');
        wmjsMap.draw();
      }
    }

    /* Check display/hide map cursor */
    if (!prevProps || prevProps.displayMapPin !== props.displayMapPin) {
      if (props.displayMapPin === true) {
        wmjsMap.getMapPin().showMapPin();
      } else if (props.displayMapPin === false) {
        wmjsMap.getMapPin().hideMapPin();
      }
    }
    /* Set map cursor location */
    if (!prevProps || prevProps.mapPinLocation !== props.mapPinLocation) {
      if (props.mapPinLocation) {
        wmjsMap.getMapPin().positionMapPinByLatLon({
          x: props.mapPinLocation.lon,
          y: props.mapPinLocation.lat,
        });
        wmjsMap.draw();
      }
    }
    /* Set disable map pin */
    if (!prevProps || prevProps.disableMapPin !== props.disableMapPin) {
      if (props.disableMapPin === true) {
        wmjsMap.getMapPin().setMapPinDisabled();
      } else if (props.disableMapPin === false) {
        wmjsMap.getMapPin().setMapPinEnabled();
      }
      wmjsMap.draw();
    }

    /* Change the animation delay */
    if (!prevProps || prevProps.animationDelay !== props.animationDelay) {
      if (props.animationDelay) {
        if (typeof wmjsMap.setAnimationDelay === 'function') {
          wmjsMap.setAnimationDelay(props.animationDelay);
        }
      }
    }

    /* Check if layer metadata should be shown */
    if (!prevProps || prevProps.showLayerInfo !== props.showLayerInfo) {
      if (props.showLayerInfo) {
        wmjsMap.showLayerInfo = props.showLayerInfo;
      }
    }

    const myChildren = makeLayerPropListFromChildren(children, mapId);

    // Only enter this loop if there are changes.
    if (!isEqual(myChildren, this.adaguc.currentChildren)) {
      this.adaguc.currentChildren = myChildren;

      // ReactWMJSLayer Layer Childs: Detect all ReactLayers connected to WMJSLayers, remove WMJSLayer if there is no ReactLayer
      const wmjsLayers = wmjsMap.getLayers();
      const { onUpdateLayerInformation, mapId } = props;
      wmjsLayers.forEach((wmLayer): void => {
        const isWMLayerInReactMapViewChildren = myChildren.find(
          (child) => child.id === wmLayer.ReactWMJSLayerId,
        );
        if (!isWMLayerInReactMapViewChildren) {
          removeWMLayerFromMap(wmLayer, mapId, onUpdateLayerInformation);
        }
      });

      // ReactWMJSLayer BaseLayer Childs: For the baseLayers, detect all ReactLayers connected to WMJSLayers, remove WMJSLayer if there is no ReactLayer
      const webmapJSBaselayers = wmjsMap.getBaseLayers();
      for (let l = 0; l < webmapJSBaselayers.length; l += 1) {
        let wmjsBaseLayers = wmjsMap.getBaseLayers();
        if (
          myChildren.filter(
            (child) =>
              child.id === wmjsBaseLayers[l].ReactWMJSLayerId && child.name,
          ).length === 0
        ) {
          /*  TODO (Maarten Plieger, 2020-03-19):The remove property for the baselayer is not working yet */
          wmjsBaseLayers.splice(l, 1);
          wmjsMap.setBaseLayers(wmjsBaseLayers);
          wmjsBaseLayers = wmjsMap.getBaseLayers();
        }
      }

      // Loop through all React layers and update WMJSLayer properties where needed
      myChildren
        .filter((child) => child.name)
        .forEach((child) => {
          const wmLayer = getWMLayerById(child.id);
          const layerIsInThisMap = wmjsMap.hasLayer(wmLayer);

          if (!layerIsInThisMap) {
            // Add a new layer, it is not in the this map
            addWMLayerPropsBasedOnChildProps(
              child,
              mapId,
              props,
              onUpdateLayerInformation,
            );

            needsRedraw = true;
          } else {
            // Handle existing layer
            if (child.name !== undefined && wmLayer.name !== child.name) {
              // Handle layer name change
              wmLayer
                .setName(child.name)
                .then(async () => {
                  await wmLayer.parseLayer();
                  const layerInfo = getLayerUpdateInfo(wmLayer, mapId);
                  setWMLayerPropsBasedOnChildProps(child, wmLayer, props);
                  onUpdateLayerInformation &&
                    onUpdateLayerInformation(layerInfo);
                  wmjsMap.draw();
                })
                .catch((e) => {
                  child.onLayerError && child.onLayerError(wmLayer, e);
                });
              needsRedraw = true;
            }
            if (
              setWMLayerPropsBasedOnChildProps(child, wmLayer, props) === true
            ) {
              needsRedraw = true;
            }
          }
        });
      // Check if order of layers has changed
      if (orderLayers(wmjsMap, myChildren)) {
        needsRedraw = true;
      }
      if (needsRedraw) {
        wmjsMap.draw();
      }
    }
  }

  drawDebounced(): void {
    const { mapId } = this.props;
    const wmjsMap = webmapUtils.getWMJSMapById(mapId);
    if (!wmjsMap) {
      return;
    }
    wmjsMap.getListener().suspendEvents();
    wmjsMap.draw();
    wmjsMap.getListener().resumeEvents();
  }

  mountWMJSMap(): IWMJSMap | null {
    const {
      mapId,
      listeners,
      srs,
      bbox,
      onMapPinChangeLocation,
      shouldDisablePrefetching,
    } = this.props;
    /* Check if we have something to mount IWMJSMap on */
    if (this.adagucWebMapJSRef.current === null) {
      console.warn('No this.adagucWebMapJSRef.current  yet ');
      return null;
    }

    /* Check if webmapjs was not already initialized */
    const existingWMJSMap = webmapUtils.getWMJSMapById(mapId);
    if (existingWMJSMap) {
      console.warn(`Somehow ${mapId} already exists`);
      webmapUtils.unRegisterWMJSMap(mapId);
    }

    const wmjsMap = new WMJSMap(this.adagucWebMapJSRef.current);
    wmjsMap.shouldPrefetch = !shouldDisablePrefetching;
    // pass translation function
    const { t } = this.props;
    wmjsMap.t = t;
    this.adaguc.currentChildren = [];
    this.adaguc.baseLayers = [];
    webmapUtils.registerWMJSMap(wmjsMap, mapId);
    this.adaguc.initialized = true;

    /* Enable to show actual layer properties in the map */
    wmjsMap.showLayerInfo = false;

    wmjsMap.removeAllLayers();
    if (srs) {
      wmjsMap.setProjection(srs!, new WMBBOX(bbox));
    }

    wmjsMap.setWMTileRendererTileSettings(
      this.tileServerSettings || tilesettings,
    );

    if (listeners) {
      listeners.forEach((listener) => {
        wmjsMap.addListener(
          listener.name!,
          (data: string) => {
            listener.callbackfunction(wmjsMap, data as string);
          },
          listener.keep,
        );
      });
    }

    wmjsMap.addListener(
      'ondimchange',
      () => {
        this.onDimChangeListener();
      },
      true,
    );

    wmjsMap.addListener('onmapready', () => {
      this.setState({ adagucInitialised: true });
    });

    wmjsMap.addListener(
      'aftersetbbox',
      () => {
        this.onAfterSetBBoxListener();
      },
      true,
    );

    wmjsMap.addListener(
      'onupdatebbox',
      (newBbox: WMBBOX) => {
        this.onUpdateBBoxListener(newBbox as WMBBOX);
      },
      true,
    );

    wmjsMap.addListener(
      'onsetmappin',
      (mapPinLatLonCoordinate: MapLocation): void => {
        // only change location when mapPin is visible and enabled
        if (
          onMapPinChangeLocation &&
          // eslint-disable-next-line react/destructuring-assignment
          this.props.displayMapPin &&
          // eslint-disable-next-line react/destructuring-assignment
          !this.props.disableMapPin
        ) {
          onMapPinChangeLocation({
            mapPinLocation: {
              lat: mapPinLatLonCoordinate.lat,
              lon: mapPinLatLonCoordinate.lon,
              projectionX: mapPinLatLonCoordinate.projectionX,
              projectionY: mapPinLatLonCoordinate.projectionY,
              screenOffsetX: mapPinLatLonCoordinate.screenOffsetX,
              screenOffsetY: mapPinLatLonCoordinate.screenOffsetY,
              srs: mapPinLatLonCoordinate.srs,
            },
            mapId,
          });
        }
      },
      true,
    );
    this.resize();
    wmjsMap.draw();
    this.updateWMJSMapProps(null!, this.props);
    return wmjsMap;
  }

  resize(): void {
    const element = this.adagucContainerRef.current as HTMLDivElement;
    if (element) {
      const newWidth = element.clientWidth;
      const newHeight = element.clientHeight;
      if (
        this.adaguc.currentWidth !== newWidth ||
        this.adaguc.currentHeight !== newHeight
      ) {
        this.adaguc.currentWidth = newWidth;
        this.adaguc.currentHeight = newHeight;
        const { mapId } = this.props;
        const wmjsMap = webmapUtils.getWMJSMapById(mapId);
        if (!wmjsMap) {
          return;
        }
        wmjsMap.setSize(newWidth, newHeight);
      }
    }
  }

  render(): React.ReactElement {
    const { passiveMap, children, onClick, mapId, linkedFeatures } = this.props;
    const { adagucInitialised } = this.state;
    const featureLayers = getFeatureLayers(children);

    featureLayers.forEach((featureLayer) => {
      const wmFeatureLayer = getWMLayerById(featureLayer.id);
      if (!wmFeatureLayer) {
        registerWMLayer(
          new WMLayer({
            layerType: LayerType.featureLayer,
            service: '',
            ...featureLayer,
          }),
          featureLayer.id,
        );
      } else if (featureLayer.geojson) {
        wmFeatureLayer.geojson = featureLayer.geojson;
      }
    });

    return (
      <div
        className="MapView"
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
          display: 'block',
          overflow: 'hidden',
        }}
      >
        <div
          ref={this.adagucContainerRef}
          style={{
            minWidth: 'inherit',
            minHeight: 'inherit',
            width: 'inherit',
            height: 'inherit',
            overflow: 'hidden',
            display: 'block',
            border: 'none',
          }}
        >
          <div
            className="MapViewComponent"
            style={{
              position: 'absolute',
              overflow: 'hidden',
              display: 'block',
              padding: '0',
              margin: '0',
              zIndex: 10,
            }}
          >
            <div
              role="application"
              aria-label="map"
              ref={this.adagucWebMapJSRef}
            />
          </div>
          {/* MapViewLayers */}
          <div
            style={{
              position: 'absolute',
              overflow: 'hidden',
              display: 'block',
              padding: '0',
              margin: '0',
              zIndex: 100,
            }}
          >
            <div>{children}</div>
            {adagucInitialised && featureLayers && featureLayers.length ? (
              <MapDrawContainer
                featureLayers={featureLayers}
                linkedFeatures={linkedFeatures}
                mapId={mapId}
              />
            ) : null}
          </div>
          {passiveMap && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label, jsx-a11y/interactive-supports-focus
            <div
              style={{
                position: 'absolute',
                overflow: 'hidden',
                display: 'block',
                padding: '0',
                margin: '0',
                zIndex: 100,
                width: '100%',
                height: '100%',
              }}
              onClick={onClick}
              role="button"
            />
          )}
        </div>
      </div>
    );
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import { usePrevious } from '@opengeoweb/shared';
import { AppStore } from '../../store/types';
import { workspaceListActions } from '../../store/workspaceList/reducer';
import { workspaceListSelectors } from '../../store/workspaceList';
import { WorkspaceMenuButton } from './WorkspaceMenuButton';
import { FetchWorkspaceParams } from '../../store/workspaceList/types';
import { constructFilterParams } from '../../store/workspaceList/utils';

const FetchWorkspacePresets: React.FC = () => {
  const dispatch = useDispatch();
  const { currentRole } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;
  const scope = !isAdmin ? 'user' : 'system';

  const lastAdmin = usePrevious(isAdmin);
  const workspaceListFilters = useSelector((store: AppStore) =>
    workspaceListSelectors.getWorkspaceListFilters(store, scope),
  );
  const workspaceSearchQuery = useSelector(
    workspaceListSelectors.getWorkspaceListSearchQuery,
  );

  const fetchWorkspacePresets = React.useCallback(
    (params: FetchWorkspaceParams) => {
      dispatch(workspaceListActions.fetchWorkspaceList(params));
    },
    [dispatch],
  );

  React.useEffect(() => {
    const filterParams = constructFilterParams(
      workspaceListFilters,
      workspaceSearchQuery,
    );

    fetchWorkspacePresets(filterParams);
  }, [fetchWorkspacePresets, workspaceListFilters, workspaceSearchQuery]);

  React.useEffect(() => {
    if (isAdmin !== lastAdmin && lastAdmin !== undefined) {
      // reset list filter when user changes role
      const filters = [
        {
          id: 'system',
          isSelected: true,
        },
        {
          id: 'user',
          isSelected: !isAdmin,
        },
      ];
      dispatch(workspaceListActions.setFilterChips(filters));
    }
  }, [dispatch, isAdmin, lastAdmin]);
  return null;
};

export const WorkspaceMenuButtonConnect: React.FC = () => {
  const dispatch = useDispatch();

  const setListDialogOpen = React.useCallback(
    (isListDialogOpen: boolean): void => {
      dispatch(
        workspaceListActions.toggleWorkspaceDialog({
          isWorkspaceListDialogOpen: isListDialogOpen,
        }),
      );
    },
    [dispatch],
  );

  const isWorkspaceListDialogOpen = useSelector((store: AppStore) =>
    workspaceListSelectors.getIsWorkspaceListDialogOpen(store),
  );

  return (
    <>
      <FetchWorkspacePresets />
      <WorkspaceMenuButton
        showMenu={isWorkspaceListDialogOpen}
        onToggleDialog={setListDialogOpen}
      />
    </>
  );
};

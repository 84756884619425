/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { createApiInstance, CreateApiProps } from '@opengeoweb/api';
import { AxiosInstance } from 'axios';
import {
  Airmet,
  AirmetFromBackend,
  AirmetFromFrontend,
  ProductConfig,
  Sigmet,
  SigmetFromBackend,
  SigmetFromFrontend,
} from '../types';

export interface SigmetAirmetApi {
  // sigmet
  getSigmetConfiguration: () => Promise<{ data: ProductConfig }>;
  getSigmetList: () => Promise<{ data: SigmetFromBackend[] }>;
  postSigmet: (formData: SigmetFromFrontend) => Promise<void>;
  getSigmetTAC: (sigmetData: Sigmet) => Promise<{ data: string }>;
  // airmet
  getAirmetConfiguration: () => Promise<{ data: ProductConfig }>;
  getAirmetList: () => Promise<{ data: AirmetFromBackend[] }>;
  postAirmet: (formData: AirmetFromFrontend) => Promise<void>;
  getAirmetTAC: (sigmetData: Airmet) => Promise<{ data: string }>;
}

const getApiRoutes = (axiosInstance: AxiosInstance): SigmetAirmetApi => ({
  getSigmetConfiguration: (): Promise<{ data: ProductConfig }> => {
    return axiosInstance.get('/sigmet-config');
  },

  getSigmetList: (): Promise<{ data: SigmetFromBackend[] }> => {
    return axiosInstance.get('/sigmetlist');
  },
  postSigmet: (formData: SigmetFromFrontend): Promise<void> => {
    return axiosInstance.post('/sigmet', { ...formData });
  },
  getSigmetTAC: (sigmetData: Sigmet): Promise<{ data: string }> => {
    return axiosInstance.post('/sigmet2tac', { ...sigmetData });
  },

  getAirmetConfiguration: (): Promise<{ data: ProductConfig }> => {
    return axiosInstance.get('/airmet-config');
  },
  getAirmetList: (): Promise<{ data: AirmetFromBackend[] }> => {
    return axiosInstance.get('/airmetlist');
  },
  postAirmet: (formData: AirmetFromFrontend): Promise<void> => {
    return axiosInstance.post('/airmet', { ...formData });
  },
  getAirmetTAC: (sigmetData: Airmet): Promise<{ data: string }> => {
    return axiosInstance.post('/airmet2tac', { ...sigmetData });
  },
});

export const createApi = (props: CreateApiProps): SigmetAirmetApi => {
  const axiosInstance = createApiInstance(props);
  return getApiRoutes(axiosInstance);
};

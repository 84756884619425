/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Alert, AlertColor, AlertTitle, Box, Button } from '@mui/material';
import { ChevronRight } from '@opengeoweb/theme';
import { useTranslation } from 'react-i18next';
import { SHARED_NAMESPACE } from '../../utils/i18n';
import AlertIcon from '../AlertIcon/AlertIcon';

interface AlertBannerProps {
  title: string;
  severity?: AlertColor;
  info?: string | React.ReactElement;
  dataTestId?: string;
  isCompact?: boolean;
  shouldClose?: boolean;
  actionButtonProps?: {
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    title: string;
  };
}

const AlertBanner: React.FC<AlertBannerProps> = ({
  title,
  severity = 'error',
  info = '',
  dataTestId = 'alert-banner',
  isCompact = false,
  shouldClose = false,
  actionButtonProps = undefined,
}: AlertBannerProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const { t } = useTranslation(SHARED_NAMESPACE);

  if (!isOpen) {
    return null;
  }

  return (
    <Alert
      data-testid={dataTestId}
      severity={severity}
      icon={<AlertIcon severity={severity} />}
      action={
        shouldClose ? (
          <Button
            variant="flat"
            onClick={(): void => setIsOpen(false)}
            sx={{ backgroundColor: 'transparent!important' }}
          >
            {t('shared-close-caps')}
          </Button>
        ) : undefined
      }
      sx={
        isCompact
          ? {
              height: '32px',
              boxSizing: 'border-box',
              '& .MuiAlert-message': {
                padding: '0px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '& .MuiTypography-root': {
                  display: 'inline',
                },
              },
              '& .MuiAlert-icon': {
                marginTop: '-3px',
                padding: '0px',
              },
            }
          : {
              padding: '3px 16px',
              '& .MuiAlert-message': {
                padding: '8px 0',
              },
              '& .MuiAlert-icon': {
                padding: '8px 0',
              },
              '& .MuiAlert-action': {
                padding: 0,
              },
            }
      }
    >
      <AlertTitle
        sx={{
          fontSize: '14px',
          fontWeight: 'normal',
          marginTop: '0px!important',
          paddingTop: '0px!important',
          marginBottom: '0px!important',
          lineHeight: isCompact ? '18px' : '24px',
        }}
      >
        {title}
      </AlertTitle>
      {info && (
        <Box
          sx={{
            fontSize: '16px',
            marginTop: 1,
            wordBreak: 'break-word',
            lineHeight: '22px',
          }}
        >
          {info}
        </Box>
      )}
      {actionButtonProps && (
        <Button
          variant="flat"
          onClick={(event): void => {
            setIsOpen(false);
            actionButtonProps.onClick(event);
          }}
          sx={{
            backgroundColor: 'transparent!important',
          }}
          startIcon={<ChevronRight />}
        >
          {actionButtonProps.title.toUpperCase()}
        </Button>
      )}
    </Alert>
  );
};

export default AlertBanner;

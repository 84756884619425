/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { ToggleMenu } from '@opengeoweb/shared';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { TimeSeriesModuleState } from '../../store/types';
import { timeSeriesActions, timeSeriesSelectors } from '../../store';
import { ServiceOptionsDialog } from './ServiceOptionsDialog';

export const ServiceOptionsButtonConnect: React.FC = () => {
  const dispatch = useDispatch();

  const services = useSelector((store: TimeSeriesModuleState) =>
    timeSeriesSelectors.getServices(store),
  );

  const parametersInUse = useSelector((store: TimeSeriesModuleState) =>
    timeSeriesSelectors.getParametersInUse(store),
  );

  const openNewServicePopup = React.useCallback(() => {
    dispatch(
      timeSeriesActions.setServicePopup({
        isOpen: true,
        variant: 'add',
      }),
    );
  }, [dispatch]);

  const openServicePopup = React.useCallback(
    (variant: 'show' | 'edit', serviceId: string, url: string) => {
      dispatch(
        timeSeriesActions.setServicePopup({
          isOpen: true,
          variant,
          serviceId,
          url,
        }),
      );
    },
    [dispatch],
  );

  const removeService = React.useCallback(
    (id: string, message?: string) => {
      dispatch(
        timeSeriesActions.removeService({
          id,
        }),
      );
      if (message) {
        dispatch(
          snackbarActions.openSnackbar({
            type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
            message,
          }),
        );
      }
    },
    [dispatch],
  );

  return (
    <ToggleMenu menuPosition="bottom">
      <ServiceOptionsDialog
        services={services}
        parametersInUse={parametersInUse}
        openNewServicePopup={openNewServicePopup}
        openServicePopup={openServicePopup}
        removeService={removeService}
      />
    </ToggleMenu>
  );
};

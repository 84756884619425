/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useApi, useApiContext } from '@opengeoweb/api';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { AlertBanner } from '@opengeoweb/shared';
import { WarningListCapConnect } from './WarningListCapConnect';
import { CapApi } from '../../utils/api';
import { useCapTranslation } from '../../utils/i18n';

interface WarningListCapConnectWrapperProps {
  productConfigKey: string;
}

export const WarningListCapConnectWrapper: React.FC<
  WarningListCapConnectWrapperProps
> = ({ productConfigKey }) => {
  const { api } = useApiContext<CapApi>();
  const { t } = useCapTranslation();
  const {
    result: productConfig,
    isLoading: isConfigLoading,
    error,
  } = useApi(api.getCapConfiguration, productConfigKey);

  if (isConfigLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress data-testid="loadingSpinner" />
      </Box>
    );
  }
  if (error) {
    return (
      <AlertBanner
        title={t('warning-list-cap-error-title')}
        info={error.message}
      />
    );
  }

  return <WarningListCapConnect capWarningPresets={productConfig} />;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box } from '@mui/system';
import { AlertBanner } from '@opengeoweb/shared';
import { CHART_LEFT_MARGIN, PLOT_TOP_MARGIN } from './utils';
import { GraphState } from './TimeSeries.utils';
import { useSpaceweatherTranslation } from '../../utils/i18n';

interface GraphErrorsProps {
  data: GraphState[];
  graphHeight: number;
  chartHeight: number;
}

export const GraphErrors: React.FC<GraphErrorsProps> = ({
  data,
  graphHeight,
  chartHeight,
}: GraphErrorsProps) => {
  const { t } = useSpaceweatherTranslation();
  return (
    <>
      {data.map((chart, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={`chart-wrapper-${index}`}
          sx={{
            left: CHART_LEFT_MARGIN,
            paddingLeft: 2,
            width: '50%',
            position: 'relative',
            height: graphHeight + PLOT_TOP_MARGIN,
            top: -chartHeight + 8,
            opacity: chart.error ? 1 : 0,
            pointerEvents: 'none',
          }}
        >
          {chart.error ? (
            <Box sx={{ paddingTop: 2 }}>
              <AlertBanner
                severity="error"
                title={
                  chart.error.message ? chart.error.message : t('graph-error')
                }
              />
            </Box>
          ) : null}
        </Box>
      ))}
    </>
  );
};

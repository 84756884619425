/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

// eslint-disable-next-line no-restricted-imports
import {
  parse,
  parseISO,
  format,
  isEqual,
  isBefore as dateFnsIsBefore,
  isAfter as dateFnsIsAfter,
  differenceInYears as dateFnsDifferenceInYears,
  differenceInMonths as dateFnsDifferenceInMonths,
  differenceInDays as dateFnsDifferenceInDays,
  differenceInMinutes as dateFnsDifferenceInMinutes,
  differenceInHours as dateFnsDifferenceInHours,
  differenceInSeconds as dateFnsDifferenceInSeconds,
  roundToNearestMinutes as dateFnsRoundToNearestMinutes,
  add as dateFnsAdd,
  sub as dateFnsSub,
  isValid as dateFnsIsValid,
  set as dateFnsSet,
  getUnixTime,
  Duration,
  fromUnixTime,
  startOfDay,
  isWithinInterval,
  getDayOfYear,
  getDay,
  getMonth,
  getYear,
  getWeek,
  getHours as dateFnsGetHours,
  addMinutes,
  addHours,
  NearestMinutes,
  RoundToNearestMinutesOptions,
  setSeconds as datefnsSetSeconds,
} from 'date-fns';
import { DATE_FORMAT_UTC } from './dateFormats';

export interface DateValues {
  year?: number;
  month?: number;
  date?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

/**
 * Parsing date strings with geoweb dateUtils:
 *
 * An ISO String *with timezone* can safely be parsed directly to a date:
 *
 * date = new Date('2022-10-10T15:30:00Z')
 * date = new Date('2022-10-10T15:30:00+00:00')
 * date = new Date('2022-10-10T15:30:00+02:00')
 *
 * or using the dateUtils isoStringToDate function *with utc=false*
 *
 * date = isoStringToDate('2022-10-10T15:30:00Z', false)
 *
 * An ISO String *without a timezone* can be parsed with isoStringToDate
 *
 * date = isoStringToDate('2022-10-10T15:30:00')
 *
 * *Do not parse ISO without timezone using `new Date()` unless you correct for the local timezone*
 *
 * To parse an arbitrary date string with timezone use:
 *
 * date = stringToDate('2022.11.10, 11:15 +01:00', 'yyyy.MM.dd, HH:mm xxx', false)
 *
 * To parse an arbitrary date string without timezone use:
 *
 * date = stringToDate('2022.11.10, 11:15', 'yyyy.MM.dd, HH:mm')
 *
 *
 * To safely convert dates to utc strings use:
 *
 * date.toISOString()
 *
 * To get parts of UTC time use
 *
 * date.getUTCHours()
 * date.getUTCMins()
 *
 * To parse an arbitrary date string to a UTC string use:
 *
 * dateToString(new Date('2022-10-10T15:30:00Z'), 'HH:ss dd MMM yyyy')
 *
 */

/**
 * Parse an ISO string to a date
 *
 * Expects the following props:
 * @param {string} input - string containing date
 *
 * @returns {Date} Date
 * @example
 * ``` isoStringToDate("2022-5-10T20:32:00+00:00") ```
 * ``` isoStringToDate("2022-5-10T20:32:00:00Z", false) ```
 */
export const isoStringToDate = (
  input: string,
  utc = true,
): Date | undefined => {
  if (!input) {
    return undefined;
  }
  const parsed = parseISO(input);
  if (!isValid(parsed)) {
    return undefined;
  }
  if (utc) {
    return setUTCDate(parsed);
  }
  return parsed;
};

export const isValidIsoDateString = (input: string): boolean => {
  if (!input) {
    return true;
  }
  return isoStringToDate(input) !== undefined;
};

/**
 * Parse a string to a date
 *
 * ***NOTE: String will be parsed in local time if utc is set to false***
 *
 * Expects the following props:
 * @param {string} input - string containing date
 * @param {string} strFormat - format of the string, see: https://date-fns.org/v2.29.3/docs/parse
 * @param {boolean} utc [ default = true ] - boolean indicating whether to parse as UTC
 * @param {Date} referenceDate [ default = new Date() ] - defines values missing from the parsed dateString
 *
 * @returns {Date} Date
 * @example
 * ``` stringToDate("2022 5 10 20:32", 'yyyy M d HH:mm')) ```
 */
export const stringToDate = (
  input: string | undefined,
  strFormat: string,
  utc = true,
  referenceDate: Date | number = new Date(),
): Date | undefined => {
  if (!input) {
    return undefined;
  }
  const parsed = parse(input, strFormat, referenceDate);
  if (!isValid(parsed)) {
    return undefined;
  }
  if (utc) {
    return setUTCDate(parsed);
  }
  return parsed;
};

export const dateToIsoString = (
  input: Date | undefined,
): string | undefined => {
  if (!input || !isValid(input)) {
    return undefined;
  }
  return input.toISOString();
};

/**
 * Use this if you initialise a date without a timezone to set it to utc correctly
 * eg.
 *   new Date(2022, 10, 12, 15, 30)
 * should be:
 *   setUTCDate(new Date(2022, 10, 12, 15, 30))
 *
 * Expects the following props:
 * @param {Date} inDate - date to be set to UTC
 *
 * @returns {Date} - Date in UTC
 * @example
 * ``` setUTCDate(new Date(2022, 10, 12, 15, 30)) ```
 */
export const setUTCDate = (inDate: Date | undefined): Date | undefined => {
  if (!inDate || !isValid(inDate)) {
    return undefined;
  }
  const utcDate = new Date(inDate);
  utcDate.setUTCFullYear(
    inDate.getFullYear(),
    inDate.getMonth(),
    inDate.getDate(),
  );
  utcDate.setUTCHours(
    inDate.getHours(),
    inDate.getMinutes(),
    inDate.getSeconds(),
    inDate.getMilliseconds(),
  );
  return utcDate;
};

/**
 * Format a date to a string
 *
 * Expects the following props:
 * @param {Date} inDate - date to be formatted
 * @param {string} strFormat - [default: "yyyy-MM-dd'T'HH:mm:ss'Z'"] format of string
 * @param {boolean} utc - [default: true] true to return in utc otherwise return in local timezone
 *
 * @returns {string} - formatted date string
 * @example
 * ``` dateToString(new Date(2022, 10, 12, 15, 30), 'dd MMM yyyy') ```
 */
export const dateToString = (
  inDate: Date | undefined,
  strFormat = DATE_FORMAT_UTC,
  utc = true,
): string | undefined => {
  if (!inDate || !isValid(inDate)) {
    return undefined;
  }
  const formattedDate = utc
    ? format(
        new Date(
          inDate.getUTCFullYear(),
          inDate.getUTCMonth(),
          inDate.getUTCDate(),
          inDate.getUTCHours(),
          inDate.getUTCMinutes(),
          inDate.getUTCSeconds(),
        ),
        strFormat,
      )
    : format(inDate, strFormat);

  return formattedDate;
};

/**
 * Find out if a date is between two other dates.
 *
 * Inclusivity parameter matches that used in momentjs where '[' indicates inclusion and '(' indicates exclusion.
 *
 * Expects the following props:
 * @param {Date} date - date to be tested
 * @param {Date} from - first date
 * @param {Date} to - last date
 * @param {string} inclusivity - [default: '()'] Must be one of : '()', '[]', '(]', '[)'
 *
 * @returns {boolen} - true if date is between, otherwise false
 *
 */
export const isBetween = (
  date: Date,
  from: Date,
  to: Date,
  inclusivity = '()',
): boolean => {
  if (!['()', '[]', '(]', '[)'].includes(inclusivity)) {
    throw new Error('Inclusivity parameter must be one of (), [], (], [)');
  }

  const isBeforeEqual = inclusivity[0] === '[';
  const isAfterEqual = inclusivity[1] === ']';

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
};

/**
 * Creates a new Date object with the specified date and time, or the current date and time if no arguments are provided.
 *
 * @param yearOrIsoString The year for the new Date object, or an ISO-formatted string representing the date and time.
 * @param month The month for the new Date object (0-11).
 * @param day The day for the new Date object (1-31).
 * @param hours The hour for the new Date object (0-23).
 * @param minutes The minute for the new Date object (0-59).
 * @param seconds The second for the new Date object (0-59).
 * @param milliseconds The millisecond for the new Date object (0-999).
 * @returns A new Date object with the specified date and time, or the current date and time if no arguments are provided.
 *
 * @example
 * createDate(2022, 11, 13);
 * // => 2022-12-13T00:00:00.000Z
 *
 * @example
 * createDate('2022-12-13T00:00:00.000Z');
 * // => 2022-12-13T00:00:00.000Z
 */

export const createDate = (
  yearOrIsoString?: number | string,
  month?: number,
  day?: number,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0,
): Date => {
  if (yearOrIsoString === undefined) {
    return new Date();
  }

  if (typeof yearOrIsoString === 'string') {
    return new Date(yearOrIsoString);
  }

  return new Date(
    yearOrIsoString,
    month!,
    day!,
    hours,
    minutes,
    seconds,
    milliseconds,
  );
};

/**
 *
 * @param date
 * @param duration
 * @returns {Date} the new Date
 */
export const add = (date: Date | number, duration: Duration): Date => {
  return dateFnsAdd(date, duration);
};

/**
 *
 * @param date
 * @param duration
 * @returns {Date} the new Date
 */
export const sub = (date: Date | number, duration: Duration): Date => {
  return dateFnsSub(date, duration);
};

/**
 * Finds the number of years between to dates
 *
 * @param dateLeft - the later date
 * @param dateRight - the earlier date
 * @returns {number} the number of minutes
 */

export const differenceInYears = (
  dateLeft: Date | number,
  dateRight: Date | number,
): number => {
  return dateFnsDifferenceInYears(dateLeft, dateRight);
};

/**
 * Finds the number of months between to dates
 *
 * @param dateLeft - the later date
 * @param dateRight - the earlier date
 * @returns {number} the number of minutes
 */

export const differenceInMonths = (
  dateLeft: Date | number | string,
  dateRight: Date | number | string,
): number => {
  return dateFnsDifferenceInMonths(dateLeft, dateRight);
};

/**
 * Finds the number of days between to dates
 *
 * @param dateLeft - the later date
 * @param dateRight - the earlier date
 * @returns {number} the number of minutes
 */

export const differenceInDays = (
  dateLeft: Date | number | string,
  dateRight: Date | number | string,
): number => {
  return dateFnsDifferenceInDays(dateLeft, dateRight);
};

/**
 * Finds the number of minuts between to dates
 *
 * @param dateLeft - the later date
 * @param dateRight - the earlier date
 * @returns {number} the number of minutes
 */
export const differenceInMinutes = (
  dateLeft: Date | number,
  dateRight: Date | number,
): number => {
  return dateFnsDifferenceInMinutes(dateLeft, dateRight);
};

/**
 * Finds the number of hours between to dates
 *
 * @param dateLeft - the later date
 * @param dateRight - the earlier date
 * @returns {number} the number of hours
 */
export const differenceInHours = (
  dateLeft: Date | number,
  dateRight: Date | number,
  roundingMethod?: 'ceil' | 'floor' | 'round' | 'trunc',
): number => {
  return roundingMethod
    ? dateFnsDifferenceInHours(dateLeft, dateRight, { roundingMethod })
    : dateFnsDifferenceInHours(dateLeft, dateRight);
};

/**
 * Finds the number of seconds between to dates
 *
 * @param dateLeft - the later date
 * @param dateRight - the earlier date
 * @returns {number} the number of minutes
 */
export const differenceInSeconds = (
  dateLeft: Date | number,
  dateRight: Date | number,
): number => {
  return dateFnsDifferenceInSeconds(dateLeft, dateRight);
};

/**
 *
 * @param date - the date to be rounded
 * @param options - the options for rounding
 * @returns the new date
 */
export const roundToNearestMinutes = (
  date: Date | number,
  options: { nearestTo: NearestMinutes },
): Date => {
  return dateFnsRoundToNearestMinutes(
    date,
    options as RoundToNearestMinutesOptions,
  );
};

/**
 *
 * @param date - the date that should be before the other one to return true
 * @param dateToCompare -the date to compare with
 *
 * @returns the first date is before the second date
 */
export const isBefore = (
  date: Date | number,
  dateToCompare: Date | number,
): boolean => {
  const before = dateFnsIsBefore(date, dateToCompare);
  return before;
};

/**
 *
 * @param date - the date that should be after the other one to return true
 * @param dateToCompare -the date to compare with
 *
 * @returns the first date is after the second date
 */
export const isAfter = (
  date: Date | number,
  dateToCompare: Date | number,
): boolean => {
  return dateFnsIsAfter(date, dateToCompare);
};

/**
 * Gracefully parsing string to a date, setting tz to UTC even if it's missing or input is invalid
 * Note: use getUtcFromString() to convert a string to a date with stricter returns
 * @param dateStr - string containing date
 * @returns {Date} - Date in UTC, or current date when something invalid is passed.
 */
export const utc = (dateStr?: string | undefined): Date => {
  if (!dateStr) {
    return createDate();
  }

  if (hasOffset(dateStr)) {
    return isoStringToDate(dateStr, false) || createDate();
  }

  return isoStringToDate(dateStr) || createDate();
};

/**
 * Parsing string to a date. Stricter check, will return undefined when dateString is invalid.
 * @param dateStr - string containing date
 * @returns {Date} - Date in UTC, undefined when not valid
 */
export const getUtcFromString = (
  dateStr: string | undefined | null,
): Date | undefined => {
  if (!dateStr || typeof dateStr !== 'string') {
    return undefined;
  }

  if (hasOffset(dateStr)) {
    return isoStringToDate(dateStr, false);
  }

  return isoStringToDate(dateStr);
};

const hasOffset = (dateStr: string): boolean => {
  return !!getOffsetStr(dateStr);
};

const getOffsetStr = (dateStr: string): string | null => {
  const offsetMatcher = /T.*([+-]\d{2}(:?\d{2})?|Z)$/;

  const groups = dateStr.match(offsetMatcher);
  return groups ? groups[1] : null;
};

/**
 * Check if a date object is valid
 * @param {Date | number} date - date to be checked
 * @returns {boolean} - True if valid
 */
export const isValid = (date: Date | number): boolean => {
  return dateFnsIsValid(date);
};

/**
 * Get the seconds timestamp of the given date
 * @param {Date} date - the given date
 * @returns the timestamp
 */
export const unix = (date: Date | number): number => {
  return getUnixTime(date);
};

/**
 * Return the Date object for a given timestamp
 * @param {number} unixTime - timestamp
 * @returns the timestamp
 */
export const fromUnix = (unixTime: number): Date => {
  return fromUnixTime(unixTime);
};

export const parseCustomDateString = (dateStr: string): Date => {
  const customFormatRegex = /^\d{12}$/;
  const customFormatWithTRegex = /^\d{8}T\d{6}$/;

  if (customFormatRegex.test(dateStr)) {
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1;
    const day = parseInt(dateStr.substring(6, 8), 10);
    const hour = parseInt(dateStr.substring(8, 10), 10);
    const minute = parseInt(dateStr.substring(10, 12), 10);
    return new Date(Date.UTC(year, month, day, hour, minute));
  }

  if (customFormatWithTRegex.test(dateStr)) {
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1;
    const day = parseInt(dateStr.substring(6, 8), 10);
    const hour = parseInt(dateStr.substring(9, 11), 10);
    const minute = parseInt(dateStr.substring(11, 13), 10);
    return new Date(Date.UTC(year, month, day, hour, minute));
  }

  return new Date(dateStr);
};

/**
 * Returns Date in UTC
 * @returns {Date} - Date in UTC
 */
export const getNowUtc = (): Date => {
  return createDate();
};

/**
 * Returns current time as string
 * @param {string} strFormat - [default: "yyyy-MM-dd'T'HH:mm:ss'Z'"] format of string
 * @param {boolean} utc - [default: true] true to return in utc otherwise return in local timezone
 *
 * @returns {string} - formatted date string
 */
export const getCurrentTimeAsString = (
  strFormat = DATE_FORMAT_UTC,
  utc = true,
): string => {
  return dateToString(getNowUtc(), strFormat, utc)!;
};

/**
 * Set date values to a given date (by default it is assumed you are passing the new values in UTC).
 * Note that months start at 0!
 * @param date - the dater to be changed
 * @param values - an object with options
 * @param useLocalTimeZone - boolean true if you are passing the values in local timezone
 * @returns the new date with options set
 */
export const set = (
  date: Date,
  values: DateValues,
  useLocalTimeZone = false,
): Date => {
  if (useLocalTimeZone) {
    return dateFnsSet(date, values);
  }
  const newDate = new Date(date);
  if (values.year != null) {
    newDate.setUTCFullYear(values.year);
  }

  if (values.month != null) {
    newDate.setUTCMonth(values.month);
  }

  if (values.date != null) {
    newDate.setUTCDate(values.date);
  }

  if (values.hours != null) {
    newDate.setUTCHours(values.hours);
  }

  if (values.minutes != null) {
    newDate.setUTCMinutes(values.minutes);
  }

  if (values.seconds != null) {
    newDate.setUTCSeconds(values.seconds);
  }

  if (values.milliseconds != null) {
    newDate.setUTCMilliseconds(values.milliseconds);
  }

  return newDate;
};

/**
 * Get hours of a passed date in UTC (or local timezone if passed false)
 * @param date - the date of which to extract hours
 * @param utc - boolean true - whether to get UTC hours
 * @returns the hour number
 */
export const getHours = (date: Date, utc = true): number => {
  if (utc) {
    return date.getUTCHours();
  }

  return dateFnsGetHours(date);
};

/**
 * Return the start of hour for the given date. The result will be in UTC.
 * @param date - the date of which to retrieve the start of the hour
 * @returns the new date
 */
export const startOfHourUTC = (date: Date): Date => {
  const hourNum = date.getUTCHours();
  return set(new Date(date), {
    hours: hourNum,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
};

/**
 * Return the start of a day for the given date. The result will be in UTC.
 * @param date - the date of which to retrieve the start of day
 * @returns the new date
 */
export const startOfDayUTC = (date: Date): Date => {
  const dateNum = date.getUTCDate();
  return set(new Date(date), {
    date: dateNum,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
};

/**
 * Return the start of a week for the given date. The result will be in UTC.
 * @param date - the date of which to retrieve the start of the week
 * @returns the new date
 */
export const startOfWeekUTC = (date: Date): Date => {
  const dateNum = date.getUTCDate();
  const dayNum = date.getUTCDay();
  const dayDifference = (dayNum < 1 ? 7 : 0) + dayNum - 1;
  return set(new Date(date), {
    date: dateNum - dayDifference,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
};

/**
 * Return the start of a month for the given date. The result will be in UTC.
 * @param date - the date of which to retrieve the start of the month
 * @returns the new date
 */
export const startOfMonthUTC = (date: Date): Date => {
  return set(new Date(date), {
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
};

/**
 * Return the start of a year for the given date. The result will be in UTC.
 * @param date - the date of which to retrieve the start of the year
 * @returns the new date
 */
export const startOfYearUTC = (date: Date): Date => {
  const yearNum = date.getUTCFullYear();
  return set(new Date(date), {
    year: yearNum,
    month: 0,
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
};

// TODO - IF TODAY time is going to be used, we need to edit this to handle the time.
//      - now.getTimezoneOffset();   by subtracting the timezone offset, this can be used to convert the time to similar to UTC, because startOfDay retuns values in local time with offset
/**
 * Parse custom time format of NOW+current time | current date in UTC. The result will be in UTC.
 * @param timeValue - string value
 * @returns Time in UTC format
 */
export const convertNOWFormatToUTC = (timeValue: string): string => {
  const now = new Date();
  const today = startOfDay(now);

  const regex = /(NOW|TODAY)([+-])PT(\d+)H(\d+)M/;
  const matches = timeValue.match(regex);

  if (matches) {
    const base = matches[1] === 'NOW' ? now : today;
    const sign = matches[2] === '+' ? 1 : -1;
    const hours = parseInt(matches[3], 10);
    const minutes = parseInt(matches[4], 10);

    return addMinutes(
      addHours(base, sign * hours),
      sign * minutes,
    ).toISOString();
  }
  throw new Error('Invalid endTime format');
};

/**
 * Parse custom time format of NOW+-hours and minutes || TODAY+-hours and minutes into UTC. The result will be in UTC.
 * @param timeValue - string value
 * @returns Time in UTC format
 */
export const convertNOWandTODAYFormatsToUTC = (timeValue: string): string => {
  const now = new Date();
  const today = startOfDayUTC(now);
  const regex = /(NOW|TODAY)([+-])PT(\d+)H(\d+)M/;
  const matches = timeValue.match(regex);

  if (matches) {
    const base = matches[1] === 'NOW' ? now : today;
    const sign = matches[2] === '+' ? 1 : -1;
    const hours = parseInt(matches[3], 10);
    const minutes = parseInt(matches[4], 10);

    return addMinutes(
      addHours(base, sign * hours),
      sign * minutes,
    ).toISOString();
  }
  throw new Error('Invalid endTime format');
};

/**
 * @name setSeconds
 * @category Second Helpers
 * @summary Set the seconds to the given date.
 *
 * @description
 * Set the seconds to the given date.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments. Allows to use extensions like [`UTCDate`](https://github.com/date-fns/utc).
 *
 * @param date - The date to be changed
 * @param seconds - The seconds of the new date
 *
 * @returns The new date with the seconds set
 *
 * @example
 * // Set 45 seconds to 1 September 2014 11:30:40:
 * const result = setSeconds(new Date(2014, 8, 1, 11, 30, 40), 45)
 * //=> Mon Sep 01 2014 11:30:45
 */
export const setSeconds = (date: Date, seconds: number): Date => {
  return datefnsSetSeconds(date, seconds);
};

/**
 * Compares two dates if values are the same
 * @param {Date} date - first date
 * @param {Date} date - second date
 * @returns {boolean} - True if dates are equal
 */
export {
  isEqual,
  startOfDay,
  parseISO,
  isWithinInterval,
  getDayOfYear,
  getDay,
  getMonth,
  getYear,
  getWeek,
};

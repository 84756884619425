/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid, useTheme } from '@mui/material';
import { layerTypes, serviceTypes } from '@opengeoweb/store';
import { LayerType } from '@opengeoweb/webmap';
import BaseLayersConnect from './BaseLayers/BaseLayersConnect';

import {
  LayerManagerCustomSettings,
  columnClasses,
  leftButtonsStyle,
} from '../LayerManagerUtils';
import AddLayersButton from '../AddLayersButton/AddLayersButton';
import WMSLayerTreeConnect from '../../WMSLoader/WMSLayerTree/WMSLayerTreeConnect';
import {
  availableDefaultBaseLayers,
  preloadedDefaultBaseServices,
} from '../../../utils/defaultConfigurations';

const onRenderTree: React.FC<{
  service: serviceTypes.InitialService;
  mapId: string;
  layerType: LayerType;
}> = ({ service, mapId, layerType }) => (
  <WMSLayerTreeConnect mapId={mapId} service={service} layerType={layerType} />
);

interface BaseLayerRowProps extends React.ComponentProps<typeof Grid> {
  mapId: string;
  preloadedAvailableBaseLayers?: layerTypes.Layer[];
  preloadedServices?: serviceTypes.InitialService[];
  tooltip?: string;
  settings?: LayerManagerCustomSettings['footer'];
}

const BaseLayerRow: React.FC<BaseLayerRowProps> = ({
  mapId,
  preloadedAvailableBaseLayers = availableDefaultBaseLayers,
  preloadedServices = preloadedDefaultBaseServices,
  tooltip = '',
  settings,
  ...gridProps
}: BaseLayerRowProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      data-testid="baseLayerRow"
      className="addLayer-column"
      sx={{
        width: '100%',
        height: '36px',
        ...theme.palette.geowebColors.layerManager.baseLayerRow.root,
      }}
      {...gridProps}
    >
      <Grid sx={[{ marginTop: '-2px' }, leftButtonsStyle]}>
        {!settings?.baseLayerRow?.addLayersButton?.isDisabled && (
          <AddLayersButton
            preloadedServices={preloadedServices}
            tooltip={tooltip}
            onRenderTree={(service): React.ReactNode =>
              onRenderTree({
                mapId,
                service,
                layerType: LayerType.baseLayer,
              })
            }
          />
        )}
        {settings?.baseLayerRow?.title}
      </Grid>
      <Grid className={columnClasses.baseLayer}>
        <BaseLayersConnect
          mapId={mapId}
          preloadedAvailableBaseLayers={preloadedAvailableBaseLayers}
          tooltipPrefix={settings?.baseLayerDropdown?.tooltipPrefix}
          icon={settings?.baseLayerDropdown?.icon}
        />
      </Grid>
    </Grid>
  );
};

export default BaseLayerRow;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Shadows } from '@mui/material/styles/shadows';

import { CSSProperties, Elevations, GeowebColorPalette } from '../types';

export const hex2rgba = (hex: string, alpha = 1): string => {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const parseColors = (colors: GeowebColorPalette): GeowebColorPalette =>
  Object.keys(colors).reduce((segmentList, key) => {
    const segment = colors[key as keyof GeowebColorPalette];
    const parsedSegment = Object.keys(segment!).reduce((list, name) => {
      const value: CSSProperties = segment![name as keyof typeof segment];
      const color = value.fill || value.color;
      // converts hex to rgba value if color and opacity property has been set
      if (value && color && (value.opacity || value.opacity === 0)) {
        return {
          ...list,
          [name]: {
            ...value,
            rgba: hex2rgba(color, value.opacity),
          },
        };
      }

      return {
        ...list,
        [name]: value,
      };
    }, {});
    return {
      ...segmentList,
      [key as keyof GeowebColorPalette]: parsedSegment,
    };
  }, {} as GeowebColorPalette) as unknown as GeowebColorPalette;

export const createShadows = (elevations: Elevations): Shadows => {
  const SHADOW_LIST_SIZE = 25;
  const elevationList = Object.keys(elevations).map(
    (elevationName) => elevations[elevationName],
  );
  return Array(SHADOW_LIST_SIZE)
    .fill('none')
    .map((value, index) => {
      if (index > 0) {
        const elevationIndex = index - 1;
        if (elevationList[elevationIndex]) {
          return elevationList[elevationIndex];
        }
      }
      return value;
    }) as Shadows;
};

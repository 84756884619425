/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useApi, useApiContext } from '@opengeoweb/api';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { AlertBanner } from '@opengeoweb/shared';
import React from 'react';
import { InitialMapProps } from '@opengeoweb/core';
import { CapApi } from '../../utils/api';
import { MapViewCapConnect } from './MapViewCapConnect';
import { useCapTranslation } from '../../utils/i18n';

interface MapViewCapConnectWrapperProps {
  id?: string;
  title?: string;
  bbox?: { left: number; bottom: number; right: number; top: number };
  srs?: string;
  productConfigKey?: string;
  layers?: InitialMapProps['mapPreset']['layers'];
}

export const MapViewCapConnectWrapper: React.FC<
  MapViewCapConnectWrapperProps
> = ({ id, title, bbox, srs, productConfigKey, layers }) => {
  const { api } = useApiContext<CapApi>();
  const { t } = useCapTranslation();

  const {
    result: capWarningPresets,
    isLoading: isConfigLoading,
    error,
  } = useApi(api.getCapConfiguration, productConfigKey);

  if (isConfigLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress data-testid="loadingSpinner" />
      </Box>
    );
  }
  if (error) {
    return (
      <AlertBanner title={t('map-view-cap-error-title')} info={error.message} />
    );
  }

  return (
    <MapViewCapConnect
      capWarningPresets={capWarningPresets}
      id={id}
      title={title}
      bbox={bbox}
      srs={srs}
      layers={layers}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { FieldValues, UseFormGetValues, UseFormTrigger } from 'react-hook-form';
import { ToggleMenu, useIsMounted, dateUtils } from '@opengeoweb/shared';
import {
  CoreAppStore,
  layerTypes,
  mapTypes,
  uiSelectors,
  useUpdateSharedData,
} from '@opengeoweb/store';
import { DrawFIRLand } from '@opengeoweb/theme';
import { LayerType } from '@opengeoweb/webmap';
import { hasValidGeometry } from '@opengeoweb/form-fields';
import {
  DRAWMODE,
  DrawMode,
  MapViewLayerProps,
  addGeoJSONProperties,
  addSelectionTypeToGeoJSON,
  defaultBox,
  defaultDelete,
  defaultPoint,
  defaultPolygon,
  getIcon,
} from '@opengeoweb/webmap-react';
import {
  LevelUnits,
  ATSRLocation,
  ICAOLocation,
  ProductStatus,
  CancelSigmet,
  Sigmet,
  ProductStatusDescription,
  isInstanceOfCancelSigmet,
  ProductCanbe,
  SigmetFromBackend,
  SigmetFromFrontend,
  ProductAction,
  Airmet,
  CancelAirmet,
  ProductConfig,
  Firareas,
  FIRConfigSigmet,
  AirmetFromBackend,
  AirmetFromFrontend,
  ProductType,
  isInstanceOfCancelSigmetOrAirmet,
  AviationProduct,
  FIRLocationGeoJson,
  AirmetConfig,
  FIRConfigAirmet,
  StartOrEndDrawing,
  CloudLevelMinMaxType,
  SurfaceWindMinMaxType,
  MovementMinMaxType,
  LevelMinMaxType,
  AviationPhenomenon,
} from '../../types';
import {
  featurePropsEnd,
  featurePropsStart,
} from '../MapViewGeoJson/constants';
import { translateKeyOutsideComponents } from '../../utils/i18n';

export const getDefaultPhenomena = (
  productConfig: ProductConfig,
  currentFir?: string,
): {
  phenomena: AviationPhenomenon[];
} => {
  const defaultActiveFIR = currentFir || productConfig.active_firs[0]; // first FIR in active_firs is the default
  const currentFirArea = productConfig.fir_areas[defaultActiveFIR];
  const firPhenomena = currentFirArea.phenomenon || [];

  return {
    phenomena: firPhenomena,
  };
};

export const getHoursBeforeValidity = (
  phenomenon: string,
  fir: string,
  config: ProductConfig,
): number => {
  if (!fir) {
    return 4;
  }
  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    hours_before_validity,
    va_hours_before_validity,
    tc_hours_before_validity,
  } = config.fir_areas[fir] as FIRConfigSigmet;

  switch (phenomenon) {
    // Tropical Cyclone
    case 'TC':
      return tc_hours_before_validity;
    // Volcanic ash cloud
    case 'VA_CLD':
      return va_hours_before_validity;
    default:
      return hours_before_validity;
  }
};

export const getMaxHoursOfValidity = (
  phenomenon: string,
  fir: string,
  config: ProductConfig,
): number => {
  if (!fir) {
    return 4;
  }
  const FIR = getFirArea(fir, config);
  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    max_hours_of_validity,
    va_max_hours_of_validity,
    tc_max_hours_of_validity,
  } = config.fir_areas[FIR] as FIRConfigSigmet;

  switch (phenomenon) {
    case 'TC': // Tropical Cyclone
      return tc_max_hours_of_validity ?? 6;
    case 'VA_CLD': // Volcanic ash cloud
      return va_max_hours_of_validity ?? 6;
    default:
      return max_hours_of_validity ?? 4;
  }
};

export const getValidFromDelayTimeMinutesFromConfig = (
  config: ProductConfig,
): number => {
  return config.valid_from_delay_minutes;
};

export const getDefaultValidityMinutesFromConfig = (
  config: ProductConfig,
): number => {
  return config.default_validity_minutes;
};

export const getOmitChangeFromConfig = (config: ProductConfig): boolean => {
  return config.omit_change_va_and_rdoact_cloud === true;
};

export const getFirArea = (
  selectedFIR: string,
  productConfig: ProductConfig,
): string => {
  // If no FIR selected or FIR doesn't exist in list, select first FIR
  return selectedFIR && productConfig.fir_areas[selectedFIR] !== undefined
    ? selectedFIR
    : productConfig.active_firs[0];
};

export const getActiveFIRArea = (
  selectedFIR: string,
  productConfig: ProductConfig,
): FIRConfigSigmet | FIRConfigAirmet => {
  const FIR = getFirArea(selectedFIR, productConfig);
  return productConfig.fir_areas[FIR];
};

export const getMaxPolygonPoints = (
  selectedFIR: string,
  productConfig: ProductConfig,
): number => {
  const { max_polygon_points } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigSigmet | FIRConfigAirmet;

  return max_polygon_points ?? 7; // Fallback to 7 if max_polygon_points is not defined
};

export const hasMaxFeaturePoints = (
  geojson: GeoJSON.FeatureCollection,
  maxPoints: number,
): boolean => {
  const hasGeometry = hasValidGeometry(geojson);
  if (!hasGeometry) {
    return false; // No drawing, so no need to display the error
  }

  const [feature] = geojson.features;
  const { coordinates, type } = feature.geometry as GeoJSON.Polygon;

  if (type === 'Polygon' && coordinates[0].length > maxPoints) {
    return true;
  }

  return false;
};

export const DEFAULT_MINIMUM_SURFACE_LEVEL = 0;

export const getMinCloudLevelValue = (
  unit: string,
  selectedFIR: string,
  productConfig: AirmetConfig,
): number => {
  // Use first FIR in list if no FIR selected
  const { cloud_level_min } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return min value for selected unit - if not present in list, return 0 as we cannot go below the surface
  return cloud_level_min !== undefined &&
    cloud_level_min![unit as keyof CloudLevelMinMaxType]
    ? cloud_level_min![unit as keyof CloudLevelMinMaxType]!
    : DEFAULT_MINIMUM_SURFACE_LEVEL;
};

export const getMinCloudLowerLevelValue = (
  unit: string,
  selectedFIR: string,
  productConfig: AirmetConfig,
): number => {
  // Use first FIR in list if no FIR selected
  const { cloud_lower_level_min } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return min value for selected unit - if not present in list, return 0 as we cannot go below the surface
  return cloud_lower_level_min !== undefined &&
    cloud_lower_level_min![unit as keyof CloudLevelMinMaxType]
    ? cloud_lower_level_min![unit as keyof CloudLevelMinMaxType]!
    : DEFAULT_MINIMUM_SURFACE_LEVEL;
};

export const getMaxCloudLevelValue = (
  unit: string,
  selectedFIR: string,
  productConfig: AirmetConfig,
): number | null => {
  // Use first FIR in list if no FIR selected
  const { cloud_level_max } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return max value for selected unit - if not present in list, return null
  return cloud_level_max !== undefined &&
    cloud_level_max![unit as keyof CloudLevelMinMaxType]
    ? cloud_level_max![unit as keyof CloudLevelMinMaxType]!
    : null;
};

export const getMaxCloudLowerLevelValue = (
  unit: string,
  selectedFIR: string,
  productConfig: AirmetConfig,
): number | null => {
  // Use first FIR in list if no FIR selected
  const { cloud_lower_level_max } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return max value for selected unit - if not present in list, return null
  return cloud_lower_level_max !== undefined &&
    cloud_lower_level_max![unit as keyof CloudLevelMinMaxType]
    ? cloud_lower_level_max![unit as keyof CloudLevelMinMaxType]!
    : null;
};

// Retrieve max value from config - if not set, there is no max value for this unit so we return null
export const getMaxLevelValue = (
  unit: string,
  selectedFIR: string,
  productConfig: ProductConfig,
): number | null => {
  // Use first FIR in list if no FIR selected
  const { level_max } = getActiveFIRArea(selectedFIR, productConfig);
  // Return max value for selected unit - if not present in list, return null
  return level_max !== undefined && level_max![unit as keyof LevelMinMaxType]
    ? level_max![unit as keyof LevelMinMaxType]!
    : null;
};

// Retrieve min value from config - if not set, return zero
export const getMinLevelValue = (
  unit: string,
  selectedFIR: string,
  productConfig: ProductConfig,
): number => {
  // Use first FIR in list if no FIR selected
  const { level_min } = getActiveFIRArea(selectedFIR, productConfig);

  // Return min value for selected unit - if not present in list, return 0 as we cannot go below the surface
  return level_min !== undefined &&
    level_min![unit as keyof CloudLevelMinMaxType]
    ? level_min![unit as keyof CloudLevelMinMaxType]!
    : DEFAULT_MINIMUM_SURFACE_LEVEL;
};

export const getMaxWindSpeedValue = (
  unit: string,
  selectedFIR: string,
  productConfig: AirmetConfig,
): number | null => {
  // Use first FIR in list if no FIR selected
  const { wind_speed_max } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return min value for selected unit - if not present in list, return null
  return wind_speed_max !== undefined &&
    wind_speed_max![unit as keyof SurfaceWindMinMaxType]
    ? wind_speed_max![unit as keyof SurfaceWindMinMaxType]!
    : null;
};

export const DEFAULT_MIN_WIND_SPEED = 0;
export const getMinWindSpeedValue = (
  unit: string,
  selectedFIR: string,
  productConfig: AirmetConfig,
): number => {
  // Use first FIR in list if no FIR selected
  const { wind_speed_min } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return min value for selected unit - if not present in list, return 0 as we have negative wind speed
  return wind_speed_min !== undefined &&
    wind_speed_min![unit as keyof SurfaceWindMinMaxType]
    ? wind_speed_min![unit as keyof SurfaceWindMinMaxType]!
    : DEFAULT_MIN_WIND_SPEED;
};

export const getMaxVisibilityValue = (
  selectedFIR: string,
  productConfig: AirmetConfig,
): number | null => {
  // Use first FIR in list if no FIR selected
  const { visibility_max } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return max value for selected unit - if not present in list, return null
  return visibility_max || null;
};

export const DEFAULT_MIN_VISIBILITY = 0;
export const getMinVisibilityValue = (
  selectedFIR: string,
  productConfig: AirmetConfig,
): number => {
  // Use first FIR in list if no FIR selected
  const { visibility_min } = getActiveFIRArea(
    selectedFIR,
    productConfig,
  ) as FIRConfigAirmet;

  // Return min value for selected unit - if not present in list, return 0 as we have negative wind speed
  return visibility_min || DEFAULT_MIN_VISIBILITY;
};

export const getAllFirAreas = (productConfig: ProductConfig): Firareas => {
  return productConfig.fir_areas;
};

export const getFirOptions = (
  productConfig: ProductConfig,
): { ATSR: ATSRLocation; FIR: ICAOLocation }[] => {
  const firAreas = getAllFirAreas(productConfig);
  const optionsFIR = Object.keys(firAreas).map((fir) => ({
    ATSR: firAreas[fir].location_indicator_atsr,
    FIR: firAreas[fir].fir_name,
  }));
  return optionsFIR;
};

export const getLevelInFeet = (level: number, unit: string): number => {
  if (LevelUnits[unit as keyof typeof LevelUnits] === 'm') {
    return level / 0.3048;
  }
  if (LevelUnits[unit as keyof typeof LevelUnits] === 'FL') {
    return level * 100;
  }
  return level;
};

export const isLevelLower = (
  ownValue: number | string,
  ownUnit: LevelUnits,
  otherValue: number,
  otherUnit: LevelUnits,
): boolean => {
  if (typeof ownValue === 'string' && !ownValue.length) {
    return true;
  }
  if (ownUnit === otherUnit && typeof ownValue === 'number') {
    return ownValue < otherValue;
  }

  // not same units - convert both to feet and compare
  const ownValueFeet = getLevelInFeet(ownValue as number, ownUnit);
  const otherValueFeet = getLevelInFeet(otherValue, otherUnit);

  return ownValueFeet < otherValueFeet;
};

export const getPhenomenon = (
  selectedFIR: string,
  phenomenon: string,
  productConfig: ProductConfig,
): string | undefined => {
  const FIR = getFirArea(selectedFIR, productConfig);
  const firArea = productConfig.fir_areas[FIR];
  if (!firArea) {
    return undefined;
  }

  const firPhenomenon = firArea.phenomenon?.find((p) => p.code === phenomenon);

  if (!firPhenomenon) {
    return undefined;
  }
  return firPhenomenon.description;
};

export const getDialogtitle = (
  product: AviationProduct,
  productType: ProductType,
  productConfig: ProductConfig,
): string => {
  const productName = productType.toUpperCase();

  if (product === null) {
    return `New ${productName}`;
  }
  if (!isInstanceOfCancelSigmetOrAirmet(product)) {
    const phenomenon = getPhenomenon(
      product.firName,
      product.phenomenon,
      productConfig,
    );
    return `${productName} ${phenomenon || ''} - ${
      product.status === 'DRAFT'
        ? translateKeyOutsideComponents('saved-as-draft')
        : ProductStatusDescription[product.status]
    }`;
  }
  if (isInstanceOfCancelSigmet(product)) {
    return `${productName} ${translateKeyOutsideComponents('cancels')} ${product.cancelsSigmetSequenceId}`;
  }
  return `${productName} ${translateKeyOutsideComponents('cancels')} ${product.cancelsAirmetSequenceId}`;
};

export const getProductFormMode = (
  canBeOptions: ProductCanbe[] | undefined,
  productListItem: SigmetFromBackend | AirmetFromBackend | null,
): string => {
  if (!productListItem) {
    return 'new';
  }

  if (!Array.isArray(canBeOptions)) {
    return 'view';
  }

  return canBeOptions.includes('PUBLISHED') ? 'edit' : 'view';
};

const buttonStyle = {
  minWidth: 96,
};

interface ProductFormLifecycleButtonsProps {
  canBe: string[];
  productType: ProductType;
  onButtonPress: (newStatus: ProductAction) => void;
  areActionsDisabled?: boolean;
}
export const getProductFormLifecycleButtons: React.FC<
  ProductFormLifecycleButtonsProps
> = ({
  canBe,
  onButtonPress,
  productType,
  areActionsDisabled = false,
}: ProductFormLifecycleButtonsProps) => {
  const hasDiscardButton = canBe.includes('DISCARDED');
  const onDiscard = (): void => onButtonPress(ProductAction.DISCARD);

  const hasSaveButton = canBe.includes('DRAFTED');
  const onSave = (): void => onButtonPress(ProductAction.SAVE);

  const hasRenewButton = canBe.includes('RENEWED');
  const onRenew = (): void => onButtonPress(ProductAction.RENEW);

  const hasCancelButton = canBe.includes('CANCELLED');
  const onCancel = (): void => onButtonPress(ProductAction.CANCEL);

  const hasPublishButton = canBe.includes('PUBLISHED');
  const onPublish = (): void => onButtonPress(ProductAction.PUBLISH);

  const mobileMenuItems = [
    ...(hasDiscardButton
      ? [{ text: translateKeyOutsideComponents('discard'), action: onDiscard }]
      : []),
    ...(hasSaveButton
      ? [
          {
            text: translateKeyOutsideComponents('save'),
            action: onSave,
            isDisabled: areActionsDisabled,
          },
        ]
      : []),
    ...(hasRenewButton
      ? [
          {
            text: translateKeyOutsideComponents('renew', {
              product: productType,
            }),
            action: onRenew,
            isDisabled: areActionsDisabled,
          },
        ]
      : []),
    ...(hasCancelButton
      ? [
          {
            text: `${translateKeyOutsideComponents('cancel')} ${productType}`,
            action: onCancel,
          },
        ]
      : []),
    ...(hasPublishButton
      ? [
          {
            text: translateKeyOutsideComponents('publish'),
            action: onPublish,
            isDisabled: areActionsDisabled,
          },
        ]
      : []),
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        'button:not(:first-of-type)': { marginLeft: 1 },
      }}
    >
      <Box display={{ xs: 'none', lg: 'block' }}>
        {hasRenewButton && (
          <Button
            variant="flat"
            onClick={onRenew}
            sx={buttonStyle}
            data-testid="productform-dialog-renew"
          >
            {translateKeyOutsideComponents('renew', {
              product: productType,
            })}
          </Button>
        )}
        {hasDiscardButton && (
          <Button
            variant="flat"
            onClick={onDiscard}
            sx={buttonStyle}
            data-testid="productform-dialog-discard"
          >
            {translateKeyOutsideComponents('discard')}
          </Button>
        )}
        {hasSaveButton && (
          <Button
            variant="tertiary"
            onClick={onSave}
            sx={buttonStyle}
            data-testid="productform-dialog-draft"
            disabled={areActionsDisabled}
          >
            {translateKeyOutsideComponents('save')}
          </Button>
        )}
        {hasCancelButton && (
          <Button
            variant="tertiary"
            onClick={onCancel}
            sx={buttonStyle}
            data-testid="productform-dialog-cancel"
          >
            {`${translateKeyOutsideComponents('cancel')} ${productType}`}
          </Button>
        )}
        {hasPublishButton && (
          <Button
            variant="primary"
            onClick={onPublish}
            sx={buttonStyle}
            data-testid="productform-dialog-publish"
            disabled={areActionsDisabled}
          >
            {translateKeyOutsideComponents('publish')}
          </Button>
        )}
      </Box>
      <Box display={{ lg: 'none' }}>
        {mobileMenuItems.length > 0 && (
          <ToggleMenu menuItems={mobileMenuItems} />
        )}
      </Box>
    </Box>
  );
};

export const hasValue = (value: number | string): boolean =>
  value !== undefined &&
  value !== null &&
  value !== 'null' &&
  value.toString() !== 'NaN' &&
  value !== '';

export const prepareFormValues = <T extends AviationProduct>(product: T): T =>
  Object.keys(product).reduce((list, key) => {
    const value = product[
      key as keyof AviationProduct
    ] as unknown as GeoJSON.FeatureCollection;
    // remove hidden form values
    if (key === 'IS_DRAFT') {
      return list;
    }

    // remove geometry if empty coordinates
    if (
      key === 'endGeometry' ||
      key === 'endGeometryIntersect' ||
      key === 'startGeometry' ||
      key === 'startGeometryIntersect'
    ) {
      if (
        value?.features.length > 0 &&
        value?.features[0].geometry.type === 'Polygon' &&
        value?.features[0].geometry.coordinates[0].length
      ) {
        return { ...list, [key]: value };
      }
      if (
        value?.features.length > 0 &&
        value?.features[0].geometry.type === 'Point' &&
        value?.features[0].geometry.coordinates.length
      ) {
        return { ...list, [key]: value };
      }

      return list;
    }

    // check if nested object has value
    if (typeof value === 'object' && value !== null && value !== undefined) {
      const hasNaNValue = Object.keys(value).reduce(
        (total: boolean, valKey: string) => {
          const keyValue = value[valKey as keyof GeoJSON.FeatureCollection];
          return !hasValue(String(keyValue)) || total;
        },
        false,
      );

      return {
        ...list,
        ...(!hasNaNValue && {
          [key]: value,
        }),
      };
    }

    return {
      ...list,
      ...(hasValue(value) && {
        [key]: value,
      }),
    };
  }, {} as T);

export const createSigmetPostParameter = (
  sigmetToPost: Sigmet | CancelSigmet,
  newStatus: ProductStatus,
  previousUuid: string,
): SigmetFromFrontend => {
  const sigmetIncludingUuid =
    previousUuid !== null
      ? { ...sigmetToPost, uuid: previousUuid }
      : sigmetToPost;

  // Do not send empty numbered fields to the BE to prevent errors
  const sigmet = prepareFormValues(sigmetIncludingUuid);

  return {
    changeStatusTo: newStatus,
    sigmet,
  };
};

export const createAirmetPostParameter = (
  airmetToPost: Airmet | CancelAirmet,
  newStatus: ProductStatus,
  previousUuid: string,
): AirmetFromFrontend => {
  const airmetIncludingUuid =
    previousUuid !== null
      ? { ...airmetToPost, uuid: previousUuid }
      : airmetToPost;

  // Do not send empty numbered fields to the BE to prevent errors
  const airmet = prepareFormValues(airmetIncludingUuid);

  return {
    changeStatusTo: newStatus,
    airmet,
  };
};

export const useDelayedFormValues = (
  getValues: () => void,
  register: () => void,
  delay = 1000,
): [unknown, () => void] => {
  const { isMounted } = useIsMounted();
  const formValueTimer = React.useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );
  const [formValues, setFormValues] = React.useState(getValues());

  React.useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    setFormValues(getValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, getValues]);

  const updateFormValueTimer = (): void => {
    if (!isMounted.current) {
      return;
    }
    setFormValues(getValues());
  };

  const onChangeForm = (): void => {
    clearTimeout(formValueTimer.current!);
    formValueTimer.current = setTimeout(updateFormValueTimer, delay);
  };

  return [formValues, onChangeForm];
};

export const getConfirmationDialogTitle = (
  action: ProductAction,
  productType: ProductType,
): string => {
  const product = productType.toUpperCase();
  if (action === ProductAction.CLOSE) {
    return `${translateKeyOutsideComponents('close')} ${product}`;
  }
  if (action === ProductAction.DISCARD) {
    return `${translateKeyOutsideComponents('discard-non-capitalized')} ${product}`;
  }
  if (action === ProductAction.PUBLISH) {
    return translateKeyOutsideComponents('publish');
  }
  return `${translateKeyOutsideComponents('cancel')} ${product}`;
};

export const getConfirmationDialogContent = (
  action: ProductAction,
  productType: ProductType,
): string => {
  const product = productType.toUpperCase();
  if (action === ProductAction.DISCARD) {
    return translateKeyOutsideComponents('are-you-sure-you-want-to-discard', {
      product,
    });
  }
  if (action === ProductAction.CLOSE) {
    return translateKeyOutsideComponents('save-changes-made');
  }
  if (action === ProductAction.PUBLISH) {
    return translateKeyOutsideComponents('publish-this-product', { product });
  }
  return translateKeyOutsideComponents('cancel-this-product', { product });
};

export const getConfirmationDialogButtonLabel = (
  action: ProductAction,
  productType: ProductType,
): string => {
  const product = productType.toUpperCase();
  switch (action) {
    case ProductAction.DISCARD:
      return `${translateKeyOutsideComponents('discard-product', { product })}`;
    case ProductAction.PUBLISH:
      return translateKeyOutsideComponents('publish');
    case ProductAction.CANCEL:
      return translateKeyOutsideComponents('yes-cancel', { product });
    default:
      return translateKeyOutsideComponents('save-and-close');
  }
};

export const getConfirmationDialogCancelLabel = (
  action: ProductAction,
): string => {
  switch (action) {
    case ProductAction.CLOSE:
      return translateKeyOutsideComponents('discard-and-close');
    default:
      return undefined!;
  }
};

export const getProductIssueDate = (
  initialProduct: Sigmet | Airmet,
  initialCancelProduct: CancelSigmet | CancelAirmet,
): string => {
  if (
    initialProduct !== null &&
    (initialProduct.status === 'PUBLISHED' ||
      initialProduct.status === 'EXPIRED') &&
    initialProduct.issueDate
  ) {
    return `${dateUtils.dateToString(
      dateUtils.utc(initialProduct.issueDate),
      dateUtils.DATE_FORMAT_DATEPICKER,
    )} UTC`;
  }

  if (initialCancelProduct !== null && initialCancelProduct.issueDate) {
    return `${dateUtils.dateToString(
      dateUtils.utc(initialCancelProduct.issueDate),
      dateUtils.DATE_FORMAT_DATEPICKER,
    )} UTC`;
  }
  return translateKeyOutsideComponents('not-published');
};

// Provide a cross section of the units from the config and the units as allowed by the type definition
// Units from the config which are not in the type definition should be ignored
export const extractUnitsToShow = (
  configUnits: string[],
  unitType: Record<string, string>,
): Record<string, string> => {
  return configUnits.reduce((list, unit) => {
    if (unitType[unit]) {
      return {
        ...list,
        [unit]: unitType[unit],
      };
    }
    return list;
  }, {});
};

export const getAllowedUnits = (
  selectedFIR: string,
  productConfig: ProductConfig,
  config_type: string,
  unitType: Record<string, string>,
): Record<string, string> => {
  // For selected FIR, find the corresponding unit_type. If not specified in the config, return as defined in type
  if (selectedFIR !== undefined && productConfig.fir_areas[selectedFIR]) {
    const firAllowedUnits = productConfig.fir_areas[selectedFIR].units;
    const allowedMovementUnits = firAllowedUnits.find(
      (unitConfig) => unitConfig.unit_type === config_type,
    );

    return allowedMovementUnits
      ? extractUnitsToShow(allowedMovementUnits.allowed_units, unitType)
      : unitType;
  }

  // If no FIR selected or FIR not in options - return all units as defined in the type
  return unitType;
};

/**
 * Function to translate the geojson data to a GeoJSON.FeatureCollection
 */
export const getFir = (
  productConfig: ProductConfig,
  firName?: string,
): FIRLocationGeoJson => {
  const currentFIRName = firName || productConfig.active_firs[0];
  const firLocation = productConfig.fir_areas[currentFIRName]?.fir_location;

  // When switching FIRs, the selectionType is not set, so we need to add it here to use 'Entire FIR' button
  const updatedFirLocationWithSelectionType = addSelectionTypeToGeoJSON(
    firLocation,
    firSelectionType,
  ) as FIRLocationGeoJson;

  return updatedFirLocationWithSelectionType;
};

/**
 * Function to check if the given geojson object has 'fir' selectionType property
 */
export const isFir = (geojson: GeoJSON.FeatureCollection): boolean => {
  if (!geojson || !geojson.features || geojson.features.length === 0) {
    return false;
  }
  const selectionType = geojson.features[0]?.properties?.['selectionType'];
  return (
    geojson &&
    geojson.features &&
    geojson.features.length > 0 &&
    selectionType === firSelectionType
  );
};

export const getFieldLabel = (
  title: string,
  isDisabled: boolean,
  action = translateKeyOutsideComponents('select'),
): string => (isDisabled ? title : `${action} ${title.toLowerCase()}`);

export const getBaseLayers = (
  productConfig: ProductConfig,
): layerTypes.Layer[] =>
  productConfig.mapPreset?.layers?.filter(
    (layer) =>
      layer.layerType === LayerType.baseLayer ||
      layer.layerType === LayerType.overLayer,
  ) || [];

export const getMaxMovementSpeedValue = (
  unit: string,
  selectedFIR: string,
  productConfig: ProductConfig,
): number | null => {
  const { movement_max } = getActiveFIRArea(selectedFIR, productConfig);

  // Return max value for selected unit - if not present in list, return null
  return movement_max !== undefined &&
    movement_max![unit as keyof MovementMinMaxType]
    ? movement_max![unit as keyof MovementMinMaxType]!
    : null;
};

export const DEFAULT_MIN_MOVEMENT_SPEED = 0;

export const getMinMovementSpeedValue = (
  unit: string,
  selectedFIR: string,
  productConfig: ProductConfig,
): number => {
  const { movement_min } = getActiveFIRArea(selectedFIR, productConfig);

  // Return min value for selected unit - if not present in list, return DEFAULT_MIN_MOVEMENT_SPEED (zero, no negative speeds)
  return movement_min !== undefined &&
    movement_min![unit as keyof MovementMinMaxType]
    ? movement_min![unit as keyof MovementMinMaxType]!
    : DEFAULT_MIN_MOVEMENT_SPEED;
};

/** Trigger form validations for given list of field names if field has a value */
export const triggerValidations = (
  fieldNames: string[],
  getValues: UseFormGetValues<FieldValues>,
  trigger: UseFormTrigger<FieldValues>,
): void => {
  fieldNames.forEach((field) => {
    const value = getValues(field);
    if (value || value === 0) {
      void trigger(field);
    }
  });
};

export const getProjection = (
  productConfig: ProductConfig,
): mapTypes.MapPreset['proj'] => productConfig.mapPreset?.proj || undefined;

export const firSelectionType = 'fir';

export const getDrawTools = (
  type: StartOrEndDrawing,
  firShape: GeoJSON.FeatureCollection,
): DrawMode[] => {
  const defaultStyleProperties =
    type === StartOrEndDrawing.start ? featurePropsStart : featurePropsEnd;

  return [
    {
      ...defaultPoint,
      title: translateKeyOutsideComponents('drop-a-point-as', { type }),
      shape: addGeoJSONProperties(
        defaultPoint.shape as GeoJSON.Feature,
        defaultStyleProperties,
      ),
    },
    {
      ...defaultBox,
      title: translateKeyOutsideComponents('draw-a-box-as', { type }),
      shape: addGeoJSONProperties(
        defaultBox.shape as GeoJSON.Feature,
        defaultStyleProperties,
      ),
    },
    {
      ...defaultPolygon,
      title: translateKeyOutsideComponents('draw-a-shape-as', { type }),
      shape: addGeoJSONProperties(
        defaultPolygon.shape as GeoJSON.Feature,
        defaultStyleProperties,
      ),
    },
    {
      drawModeId: 'drawtools-fir',
      title: translateKeyOutsideComponents('set-a-fir-as', { type }),
      isSelectable: false,
      value: DRAWMODE.POLYGON,
      // add selectionType here to use later for `onUpdateStartgeoJSON` in Sigmet/Airmet-Form when updating form values directly
      shape: addSelectionTypeToGeoJSON(
        addGeoJSONProperties(firShape, { ...defaultStyleProperties }),
        firSelectionType,
      ),
      selectionType: firSelectionType,
    },
    {
      ...defaultDelete,
      title: translateKeyOutsideComponents('remove-geometry'),
    },
  ];
};

export const updateDrawToolsWithFir = (
  drawTools: DrawMode[],
  newFirShape: GeoJSON.FeatureCollection,
): DrawMode[] => {
  return drawTools.map((mode) => {
    if (mode.selectionType === firSelectionType) {
      return {
        ...mode,
        shape: newFirShape,
      };
    }
    return mode;
  });
};

export const getToolIcon = (selectionType: string): React.ReactElement => {
  const defaultIcon = getIcon(selectionType);
  if (defaultIcon) {
    return defaultIcon;
  }

  if (selectionType === firSelectionType) {
    return <DrawFIRLand />;
  }
  return <DrawFIRLand />;
};

export const useFormLayers = (layers: MapViewLayerProps[]): void => {
  const formLayers = layers
    .map((layer: MapViewLayerProps) => ({
      id: layer.id,
      geoJSON: layer.geojson,
    }))
    .filter(
      (
        layer,
      ): layer is {
        id: string;
        geoJSON: GeoJSON.FeatureCollection;
      } => layer.geoJSON !== undefined,
    );

  const sourcePanelId = useSelector((store: CoreAppStore) =>
    uiSelectors.getSourcePanelId(store, 'publicWarnings'),
  );

  useUpdateSharedData(
    {
      formFeatures: formLayers,
    },
    sourcePanelId,
  );
};

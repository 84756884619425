/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import {
  selectorMemoizationOptions,
  serviceSelectors,
} from '@opengeoweb/store';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { countBy, partition } from 'lodash';
import {
  LayerSelectStoreType,
  ActiveServiceType,
  Filters,
  ActiveServiceObjectEntities,
  ServicePopupObject,
  LayerSelectModuleState,
  RootState,
  Filter,
  LayerWithServiceName,
} from './types';
import {
  initialServicePopupState,
  layerSelectActiveServicesAdapter,
  layerSelectFilterAdapter,
} from './reducer';
import { filterLayersFromService } from './utils';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const layerSelectStore = (
  store: LayerSelectModuleState,
): LayerSelectStoreType =>
  store && store.layerSelect ? store.layerSelect : null!;

/**
 * Returns search filter string
 *
 * Example getSearchFilter(store);
 * @param {object} store store: object - store object
 * @returns {array} returnType: string
 */
export const getSearchFilter = createSelector(
  layerSelectStore,
  (store): string => store?.filters?.searchFilter || '',
);

/**
 * Returns active services array
 *
 * Example getActiveServices(store);
 * @param {object} store store: object - store object
 * @returns {ActiveServiceObjectEntities} returnType: ActiveServiceObjectEntitiesobject of active services
 */
// cast to usable type - selectEntities returns Dictionary<ActiveServiceObject> which is not usable inside of the code
export const getActiveServices = (
  store: LayerSelectModuleState,
): ActiveServiceObjectEntities =>
  getActiveServicesDictionary(store) as ActiveServiceObjectEntities;

export const {
  selectEntities: getActiveServicesDictionary,
  selectById: getActiveServiceById,
  selectAll: getAllActiveServices,
} = layerSelectActiveServicesAdapter.getSelectors(
  (store: LayerSelectModuleState): ActiveServiceType =>
    store?.layerSelect?.filters?.activeServices || { entities: {}, ids: [] },
);

/**
 * Returns all ids of enabled services
 *
 * Example getEnabledServiceIds(store);
 * @param {object} store store: object - store object
 * @returns {array} returnType: array of service ids that are enabled
 */
export const getEnabledServiceIds = createSelector(
  getAllActiveServices,
  (services): string[] => {
    return services
      .filter((service) => service.enabled)
      .map((service) => service.serviceId!);
  },
  selectorMemoizationOptions,
);

/**
 * Returns ids of all keywords
 *
 * Example getAllKeywordIds(store);
 * @param {object} store store: object - store object
 * @returns {array} returnType: array of all keyword ids
 */
// cast to usable type - selectIds returns EntityId[] which is not usable inside of the code
export const getAllFilterIds = (store: LayerSelectModuleState): string[] =>
  getAllFilterIdsEntity(store) as string[];

export const {
  selectAll: getAllFilters,
  selectIds: getAllFilterIdsEntity,
  selectById: getFilterById,
} = layerSelectFilterAdapter.getSelectors(
  (store: LayerSelectModuleState): Filters =>
    store?.layerSelect?.filters?.filters || { entities: {}, ids: [] },
);

/**
 * Returns all ids of checked keywords
 *
 * Example getCheckedKeywordIds(store);
 * @param {object} store store: object - store object
 * @returns {array} returnType: array of keyword ids that are checked
 */
export const getCheckedFilterIds = createSelector(
  getAllFilters,
  (filters): string[] => {
    return filters
      .filter((filter) => filter?.checked)
      .map((filter) => filter.id);
  },
  selectorMemoizationOptions,
);

export interface FiltersGrouped {
  groups: Filter[];
  keywords: Filter[];
}

export const getFilters = createSelector(
  getAllActiveServices,
  getAllFilters,
  (services, filters): FiltersGrouped => {
    const enabledServices = services.filter((service) => service.enabled);
    const keywords = enabledServices.flatMap((service) => service.keywords);
    const groups = enabledServices.flatMap((service) => service.groups);
    const countEachKeyword = countBy(keywords);
    const countEachGroup = countBy(groups);

    const filtersWithCount = filters.map((filter): Filter => {
      if (filter.type === 'groups') {
        return { ...filter, amountVisible: countEachGroup[filter.name] ?? 0 };
      }
      return { ...filter, amountVisible: countEachKeyword[filter.name] ?? 0 };
    });

    const [groupsWithCount, keywordsWithCount] = partition(
      filtersWithCount,
      (filter) => filter.type === 'groups',
    );

    const groupsSorted = [...groupsWithCount].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    const keywordsSorted = [...keywordsWithCount].sort((a, b) =>
      a.name.localeCompare(b.name),
    );

    return { groups: groupsSorted, keywords: keywordsSorted };
  },
);

/**
 * Returns if all keywords are checked
 *
 * Example isAllKeywordsChecked(store);
 * @param {object} store store: object - store object
 * @returns {array} returnType: boolean if all keywords are checked
 */
export const isAllFiltersChecked = createSelector(
  layerSelectStore,
  (store): boolean => {
    return store?.filters?.filters?.ids.every((filterId) => {
      return store?.filters?.filters?.entities[filterId]?.checked;
    });
  },
);

/**
 * Returns a filtered active layer object based on the given filter settings
 * Example: layerSelectSelectors.getFilteredLayers(store, filteredSettings);
 * @param {object} store store: object - object from which the service state will be extracted
 * @returns {array} returnType: LayerWithServiceName - an array of all filtered activelayers
 */
export const getFilteredLayers = createSelector(
  [
    getEnabledServiceIds,
    serviceSelectors.getServices,
    getCheckedFilterIds,
    isAllFiltersChecked,
    getSearchFilter,
  ],
  (
    enabledServiceIds,
    servicesStore,
    checkedFilterIds,
    allFiltersActive,
    searchFilter,
  ): LayerWithServiceName[] => {
    return enabledServiceIds.reduce<LayerWithServiceName[]>(
      (layerList, serviceId) => {
        return layerList.concat(
          filterLayersFromService(
            serviceId,
            servicesStore,
            checkedFilterIds,
            allFiltersActive,
            searchFilter,
          ).map((layer) => ({ ...layer, serviceName: serviceId })),
        );
      },
      [],
    );
  },
  selectorMemoizationOptions,
);

/**
 * Returns service popup details
 *
 * Example getServicePopupDetails(store, 'server-id');
 * @param {object} store store: object - store object
 * @returns {object} returnType: object of active service
 */
export const getServicePopupDetails = (
  store: LayerSelectModuleState,
): ServicePopupObject => {
  return store?.layerSelect?.servicePopup || initialServicePopupState;
};

/**
 * Returns service with active filtered layers from seaerch
 *
 * Example getFilteredActiveServices(store);
 * @param {object} store store: object - store object
 * @returns {object} returnType: object of active service
 */
export const getFilteredActiveServices = createSelector(
  [getFilteredLayers, getActiveServices, getSearchFilter],
  (layers, activeServices, searchResult) => {
    const servicesResult = searchResult.length
      ? layers.reduce<string[]>(
          (layers, { serviceName }) =>
            layers.includes(serviceName) ? layers : layers.concat(serviceName),
          [],
        )
      : [];

    const updatedServices = Object.fromEntries(
      Object.entries(activeServices).map(([key, service]) => {
        return [
          key,
          {
            ...service,
            isInSearch: servicesResult.includes(service.serviceId!),
          },
        ];
      }),
    );

    return updatedServices;
  },
  selectorMemoizationOptions,
);

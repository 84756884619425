/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomToggleButton, CustomTooltip } from '@opengeoweb/shared';
import { useTheme } from '@mui/material';
import { layerSelectTypes } from '../../store';
import { useLayerSelectTranslation } from '../../utils/i18n';

const style = {
  borderRadius: '20px!important',
  marginRight: '4px',
  whiteSpace: 'nowrap',
  height: '24px!important',
  padding: '5px 12px!important',
  textTransform: 'none!important',
  fontWeight: 'normal',
  marginBottom: '4px',
};

interface ServiceChipProps {
  all?: boolean;
  service?: layerSelectTypes.ActiveServiceObject;
  isSelected?: boolean;
  isDisabled?: boolean;
  toggleChip?: (service: string) => void;
  isInSearch?: boolean;
}

export const ServiceChip: React.FC<ServiceChipProps> = ({
  all = false,
  service,
  isSelected,
  isDisabled,
  toggleChip,
  isInSearch,
}: ServiceChipProps) => {
  const { t } = useLayerSelectTranslation();
  const {
    palette: { geowebColors },
  } = useTheme();
  const chip = all ? 'all' : service && service.serviceUrl;

  return (
    <CustomTooltip
      title={service?.abstract ? `Abstract: ${service.abstract}` : ''}
    >
      <span>
        <CustomToggleButton
          variant="boxed"
          selected={Boolean(isSelected)}
          tabIndex={0}
          data-testid={all ? 'serviceChipAll' : 'serviceChip'}
          onClick={(): void => toggleChip!(chip!)}
          onKeyPress={(): void => toggleChip!(chip!)}
          disabled={isDisabled}
          sx={{
            ...style,
            ...(isInSearch && {
              backgroundColor: `${geowebColors.chip.highlightDefault.rgba}!important`,
              '&:hover': {
                backgroundColor: `${geowebColors.chip.highlightDefaultMouseOver.rgba}!important`,
              },
              ...(isSelected && {
                backgroundColor: `${geowebColors.chip.highlightActiveDefault.rgba}!important`,
                '&:hover': {
                  backgroundColor: `${geowebColors.chip.highlightActiveMouseOver.rgba}!important`,
                },
              }),
            }),
          }}
        >
          {service ? service.serviceName : t('all')}
        </CustomToggleButton>
      </span>
    </CustomTooltip>
  );
};

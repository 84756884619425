/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { produce } from 'immer';
import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

import { LayerType, getWMJSMapById, webmapUtils } from '@opengeoweb/webmap';
import {
  MapPinLocationPayload,
  SetBboxPayload,
  SetStepBackWardOrForward,
  UpdateAllMapDimensionsPayload,
  UpdateLayerInfoPayload,
} from '@opengeoweb/webmap-react';
import { defaultAnimationDelayAtStart } from '@opengeoweb/timeslider';
import type {
  WebMapState,
  SetAutoLayerIdPayload,
  SetMapAnimationStartPayload,
  SetMapAnimationStopPayload,
  SetTimeSliderSpanPayload,
  SetTimeStepPayload,
  SetAnimationStartTimePayload,
  SetAnimationEndTimePayload,
  SetAnimationDelayPayload,
  MoveLayerPayload,
  SetTimeSliderWidthPayload,
  SetTimeSliderCenterTimePayload,
  SetEndTimeOverriding,
  ToggleAutoUpdatePayload,
  ToggleTimestepAutoPayload,
  ToggleTimeSpanAutoPayload,
  ToggleTimeSliderHoverPayload,
  DisableMapPinPayload,
  SetTimeSliderSecondsPerPxPayload,
  ToggleTimeSliderIsVisiblePayload,
  SetDockedLayerManagerSize,
  ToggleAnimationLengthAutoPayload,
  SetDefaultMapSettingsPayload,
} from './types';

import {
  Dimension,
  Layer,
  ToggleMapPinIsVisiblePayload,
  ToggleZoomControlsPayload,
  ReduxLayer,
} from '../types';

import {
  produceDraftStateSetWebMapDimension,
  produceDraftStateSetMapDimensionFromLayerChangeDimension,
  createMap,
  moveArrayElements,
} from './utils';

import { SyncLayerPayloads } from '../../generic/types';
import {
  setBboxSync,
  setLayerActionSync,
  setTimeSync,
} from '../../generic/synchronizationActions/actions';
import { mapChangeDimension, setMapPreset } from './actions';
import { layerActions } from '../layer/reducer';
import { uiActions } from '../../ui/reducer';

/**
 * Checks if the layer id is already taken in one of the maps.
 * @param state The WebMapState
 * @param layerIdToCheck The layerId to check
 * @returns true if the layerId is already taken somewhere else.
 */
export const checkIfMapLayerIdIsAlreadyTaken = (
  state: WebMapState,
  layerId: string,
): boolean => {
  return state.allIds.some((mapId: string) => {
    const map = state.byId[mapId];
    const doesLayeridExist = (layers: string[]): boolean =>
      layers && layers.some((id: string): boolean => id === layerId);
    return (
      doesLayeridExist(map.mapLayers) ||
      doesLayeridExist(map.overLayers) ||
      doesLayeridExist(map.baseLayers) ||
      doesLayeridExist(map.featureLayers)
    );
  });
};

/**
 * Addes id's to the layer object. All layers need to have an id for referring.
 * @param layers
 */
const createLayersWithIds = (state: WebMapState, layers: Layer[]): Layer[] => {
  if (!layers) {
    return layers;
  }
  return produce(layers, (draft) => {
    for (const layer of draft) {
      if (!layer.id) {
        layer.id = webmapUtils.generateLayerId();
      } else if (checkIfMapLayerIdIsAlreadyTaken(state, layer.id)) {
        console.warn(
          `Warning: Layer id ${layer.id} was already taken: Generating new one.`,
        );
        layer.id = webmapUtils.generateLayerId();
      }
    }
  });
};

export const initialState: WebMapState = {
  byId: {},
  allIds: [],
};

export const slice = createSlice({
  initialState,
  name: 'mapReducer',
  reducers: {
    registerMap: (
      draft: Draft<WebMapState>,
      action: PayloadAction<{ mapId: string }>,
    ) => {
      const { mapId } = action.payload;
      if (!draft.allIds.includes(mapId)) {
        draft.byId[mapId] = createMap({ id: mapId });
        draft.allIds.push(mapId);
      }
    },
    unregisterMap: (
      draft: Draft<WebMapState>,
      action: PayloadAction<{ mapId: string }>,
    ) => {
      const { mapId } = action.payload;
      const mapIndex = draft.allIds.indexOf(mapId);
      if (mapIndex !== -1) {
        delete draft.byId[mapId];
        draft.allIds.splice(mapIndex, 1);
      }
    },
    setStepBackwardOrForward: (
      // eslint-disable-next-line no-unused-vars
      _draft: Draft<WebMapState>,
      // eslint-disable-next-line no-unused-vars
      _action: PayloadAction<SetStepBackWardOrForward>,
    ) => {},
    setBbox: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetBboxPayload>,
    ) => {
      const { mapId, bbox } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      const srs = action.payload.srs || null;
      draft.byId[mapId].bbox = bbox;
      if (srs) {
        draft.byId[mapId].srs = srs;
      }
    },
    mapUpdateAllMapDimensions: (
      draft: Draft<WebMapState>,
      action: PayloadAction<UpdateAllMapDimensionsPayload>,
    ) => {
      const { mapId, dimensions } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      dimensions.forEach((dimension) =>
        produceDraftStateSetWebMapDimension(draft, mapId, dimension, true),
      );
    },
    mapStartAnimation: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetMapAnimationStartPayload>,
    ) => {
      const { mapId, timeList, start, end } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isAnimating = true;

      const wmMap = getWMJSMapById(mapId);
      if (wmMap) {
        wmMap.shouldPrefetch = false;
      }
      // Animation is defined by one of the following:
      // - start, end and interval for a continuous animation loop with constant interval
      // - timeList, for a custom timeList
      if (start && end) {
        draft.byId[mapId].animationStartTime = start;
        draft.byId[mapId].animationEndTime = end;
        draft.byId[mapId].timeList = undefined;
      }
      if (timeList && timeList.length >= 0) {
        draft.byId[mapId].animationStartTime = undefined;
        draft.byId[mapId].animationEndTime = undefined;
        draft.byId[mapId].timeList = timeList;
      }

      if (
        action.payload.interval &&
        action.payload.interval !== draft.byId[mapId].timeStep
      ) {
        draft.byId[mapId].timeStep = action.payload.interval;
        draft.byId[mapId].isTimestepAuto = false;
      }
    },
    mapStopAnimation: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetMapAnimationStopPayload>,
    ) => {
      const { mapId } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isAnimating = false;
      const wmMap = getWMJSMapById(mapId);
      if (wmMap) {
        wmMap.shouldPrefetch = true;
      }
    },
    setTimeSliderSpan: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetTimeSliderSpanPayload>,
    ) => {
      const { mapId, timeSliderSpan } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].timeSliderSpan = timeSliderSpan;
    },
    setTimeStep: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetTimeStepPayload>,
    ) => {
      const { mapId, timeStep } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].timeStep = timeStep;
    },
    setAnimationStartTime: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetAnimationStartTimePayload>,
    ) => {
      const { mapId, animationStartTime } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].animationStartTime = animationStartTime;
      draft.byId[mapId].isAnimationLengthAuto = false;
    },
    setAnimationEndTime: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetAnimationEndTimePayload>,
    ) => {
      const { mapId, animationEndTime } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].animationEndTime = animationEndTime;
      draft.byId[mapId].isAnimationLengthAuto = false;
    },
    setAnimationDelay: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetAnimationDelayPayload>,
    ) => {
      const { mapId, animationDelay } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].animationDelay = animationDelay;
    },
    layerMoveLayer: (
      draft: Draft<WebMapState>,
      action: PayloadAction<MoveLayerPayload>,
    ) => {
      const { oldIndex, newIndex, mapId } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }

      draft.byId[mapId].mapLayers = moveArrayElements(
        draft.byId[mapId].mapLayers,
        oldIndex,
        newIndex,
      );
    },
    setAutoLayerId: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetAutoLayerIdPayload>,
    ) => {
      const { mapId, layerId, autoTimeStepLayerId, autoUpdateLayerId } =
        action.payload;
      const map = draft.byId[mapId];
      if (!map) {
        return;
      }
      if (autoUpdateLayerId || autoTimeStepLayerId) {
        map.autoUpdateLayerId = autoUpdateLayerId;
        map.autoTimeStepLayerId = autoTimeStepLayerId;
      } else if (layerId) {
        map.autoUpdateLayerId = layerId;
        map.autoTimeStepLayerId = layerId;
      } else {
        map.autoTimeStepLayerId = undefined;
        map.autoUpdateLayerId = undefined;
      }
    },
    setAutoTimestepLayerId: (
      draft: Draft<WebMapState>,
      action: PayloadAction<{
        mapId: string;
        autoTimestepLayerId: string | undefined;
      }>,
    ) => {
      const { mapId, autoTimestepLayerId } = action.payload;
      const map = draft.byId[mapId];
      if (!map) {
        return;
      }
      map.autoTimeStepLayerId = autoTimestepLayerId;
    },
    setAutoUpdateLayerId: (
      draft: Draft<WebMapState>,
      action: PayloadAction<{
        mapId: string;
        autoUpdateLayerId: string | undefined;
      }>,
    ) => {
      const { mapId, autoUpdateLayerId } = action.payload;
      const map = draft.byId[mapId];
      if (!map) {
        return;
      }
      map.autoUpdateLayerId = autoUpdateLayerId;
    },
    setTimeSliderWidth: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetTimeSliderWidthPayload>,
    ) => {
      const { mapId, timeSliderWidth } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].timeSliderWidth = timeSliderWidth;
    },
    setTimeSliderCenterTime: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetTimeSliderCenterTimePayload>,
    ) => {
      const { mapId, timeSliderCenterTime } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].timeSliderCenterTime = timeSliderCenterTime;
    },
    setTimeSliderSecondsPerPx: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetTimeSliderSecondsPerPxPayload>,
    ) => {
      const { mapId, timeSliderSecondsPerPx } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }

      draft.byId[mapId].timeSliderSecondsPerPx = timeSliderSecondsPerPx;
    },
    toggleAutoUpdate: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleAutoUpdatePayload>,
    ) => {
      const { mapId, shouldAutoUpdate } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isAutoUpdating = shouldAutoUpdate;
    },
    setEndTimeOverriding: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetEndTimeOverriding>,
    ) => {
      const { mapId, shouldEndtimeOverride } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].shouldEndtimeOverride = shouldEndtimeOverride;
    },
    toggleTimestepAuto: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleTimestepAutoPayload>,
    ) => {
      const { mapId, timestepAuto } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isTimestepAuto = timestepAuto;
    },
    toggleTimeSpanAuto: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleTimeSpanAutoPayload>,
    ) => {
      const { mapId, timeSpanAuto } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isTimeSpanAuto = timeSpanAuto;
    },
    toggleAnimationLengthAuto: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleAnimationLengthAutoPayload>,
    ) => {
      const { mapId, autoAnimationLength } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isAnimationLengthAuto = autoAnimationLength;
    },
    toggleTimeSliderHover: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleTimeSliderHoverPayload>,
    ) => {
      const { mapId, isTimeSliderHoverOn } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isTimeSliderHoverOn = isTimeSliderHoverOn;
    },
    toggleTimeSliderIsVisible: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleTimeSliderIsVisiblePayload>,
    ) => {
      const { mapId, isTimeSliderVisible } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].isTimeSliderVisible = isTimeSliderVisible;
    },
    toggleZoomControls: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleZoomControlsPayload>,
    ) => {
      const { mapId, shouldShowZoomControls } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].shouldShowZoomControls = shouldShowZoomControls;
    },
    setMapPinLocation: (
      draft: Draft<WebMapState>,
      action: PayloadAction<MapPinLocationPayload>,
    ) => {
      const { mapId, mapPinLocation } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].mapPinLocation = mapPinLocation;
    },
    setDisableMapPin: (
      draft: Draft<WebMapState>,
      action: PayloadAction<DisableMapPinPayload>,
    ) => {
      const { mapId, disableMapPin } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].disableMapPin = disableMapPin;
    },
    toggleMapPinIsVisible: (
      draft: Draft<WebMapState>,
      action: PayloadAction<ToggleMapPinIsVisiblePayload>,
    ) => {
      const { mapId, displayMapPin } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].displayMapPin = displayMapPin;
    },
    setDockedLayerManagerSize: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetDockedLayerManagerSize>,
    ) => {
      const { mapId, dockedLayerManagerSize } = action.payload;
      if (!draft.byId[mapId]) {
        return;
      }
      draft.byId[mapId].dockedLayerManagerSize = dockedLayerManagerSize;
    },
    setMapPresetError: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<WebMapState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{ mapId: string; error: string }>,
    ) => {},
    setDefaultMapSettings: (
      draft: Draft<WebMapState>,
      action: PayloadAction<SetDefaultMapSettingsPayload>,
    ) => {
      draft.defaultMapSettings = action.payload.preset;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(layerActions.addLayer, (draft, action) => {
        const { layerId, mapId, layer } = action.payload;
        if (!draft.byId[mapId]) {
          return;
        }

        if (checkIfMapLayerIdIsAlreadyTaken(draft, layerId)) {
          console.warn(`Warning: Layer id ${layerId} was already taken`);
          return;
        }
        if (!draft.byId[mapId].mapLayers.length) {
          draft.byId[mapId].autoUpdateLayerId = layerId;
          draft.byId[mapId].autoTimeStepLayerId = layerId;
        }

        const isFeatureLayer =
          layer && layer.layerType === LayerType.featureLayer;

        if (isFeatureLayer) {
          draft.byId[mapId].featureLayers.unshift(layerId);
        } else {
          draft.byId[mapId].mapLayers.unshift(layerId);
        }
      })
      .addCase(layerActions.duplicateMapLayer, (draft, action) => {
        const { newLayerId, mapId } = action.payload;
        if (!draft.byId[mapId]) {
          return;
        }
        if (checkIfMapLayerIdIsAlreadyTaken(draft, newLayerId)) {
          console.warn(`Warning: Layer id ${newLayerId} was already taken`);
          return;
        }
        draft.byId[mapId].mapLayers.unshift(newLayerId);
      })
      .addCase(layerActions.addBaseLayer, (draft, action) => {
        const { layer, layerId, mapId } = action.payload;
        if (!draft.byId[mapId]) {
          return;
        }
        if (checkIfMapLayerIdIsAlreadyTaken(draft, layerId)) {
          console.warn(`Warning: Layer id ${layerId} was already taken`);
          return;
        }
        if (layer.layerType === LayerType.baseLayer) {
          draft.byId[mapId].baseLayers.unshift(layerId);
          return;
        }
        if (layer.layerType === LayerType.overLayer) {
          draft.byId[mapId].overLayers.unshift(layerId);
        }
      })
      .addCase(layerActions.setLayers, (draft, action) => {
        const { mapId } = action.payload;
        const layersWithIds = createLayersWithIds(draft, action.payload.layers);
        const layerIds = layersWithIds.map(({ id }) => id!);
        const [activeLayerId] = layerIds;

        if (draft.byId[mapId]) {
          draft.byId[mapId].mapLayers = layerIds;
          draft.byId[mapId].autoUpdateLayerId = activeLayerId;
          draft.byId[mapId].autoTimeStepLayerId = activeLayerId;
          const webmapInstance = webmapUtils.getWMJSMapById(mapId);
          if (webmapInstance) {
            const mapDims = webmapInstance.getDimensionList();
            if (!draft.byId[mapId].dimensions) {
              draft.byId[mapId].dimensions = [];
            }
            const draftDims = draft.byId[mapId].dimensions;
            if (mapDims && draftDims) {
              mapDims.forEach((dim) => {
                const draftMapDimIndex = draftDims.findIndex(
                  (draftDim) => draftDim.name === dim.name,
                );
                if (draftMapDimIndex !== -1) {
                  draftDims[draftMapDimIndex].name = dim.name;
                  draftDims[draftMapDimIndex].units = dim.units;
                  draftDims[draftMapDimIndex].currentValue = dim.currentValue;
                } else {
                  draftDims.push({
                    name: dim.name,
                    units: dim.units,
                    currentValue: dim.currentValue,
                  });
                }
              });
            }
          }
        }
      })
      .addCase(layerActions.setBaseLayers, (draft, action) => {
        const { layers, mapId } = action.payload;
        // Split into base and overlayers
        const baseLayers: ReduxLayer[] = [];
        const overLayers: ReduxLayer[] = [];
        layers.forEach((layer) => {
          if (layer.layerType === LayerType.overLayer) {
            overLayers.push(layer);
          } else if (layer.layerType === LayerType.baseLayer) {
            baseLayers.push(layer);
          }
        });

        const baseLayersWithIds = createLayersWithIds(draft, baseLayers);
        const overLayersWithIds = createLayersWithIds(draft, overLayers);

        const baseLayerIds = baseLayersWithIds.map(({ id }) => id!);
        const overLayerIds = overLayersWithIds.map(({ id }) => id!);

        if (draft.byId[mapId]) {
          if (baseLayerIds.length !== 0) {
            draft.byId[mapId].baseLayers = baseLayerIds;
          }
          if (overLayerIds.length !== 0) {
            draft.byId[mapId].overLayers = overLayerIds;
          }
        }
      })
      .addCase(layerActions.layerDelete, (draft, action) => {
        const { mapId, layerId } = action.payload;
        const map = draft.byId[mapId];
        if (!map) {
          return;
        }
        map.mapLayers = map.mapLayers.filter((id) => id !== layerId);

        // if auto layer is deleted, change auto layer ids
        if (!map.mapLayers.length) {
          map.autoUpdateLayerId = undefined;
          map.autoTimeStepLayerId = undefined;
          return;
        }
        const [activeLayerId] = map.mapLayers;
        if (map.autoUpdateLayerId === layerId) {
          map.autoUpdateLayerId = activeLayerId;
        }
        if (map.autoTimeStepLayerId === layerId) {
          map.autoTimeStepLayerId = activeLayerId;
        }
        map.featureLayers = map.featureLayers.filter((id) => id !== layerId);
      })
      .addCase(layerActions.baseLayerDelete, (draft, action) => {
        const { mapId, layerId } = action.payload;
        if (!draft.byId[mapId]) {
          return;
        }
        draft.byId[mapId].baseLayers = draft.byId[mapId].baseLayers.filter(
          (id) => id !== layerId,
        );
        draft.byId[mapId].overLayers = draft.byId[mapId].overLayers.filter(
          (id) => id !== layerId,
        );
      })
      .addCase(layerActions.layerChangeDimension, (draft, action) => {
        const { layerId, dimension } = action.payload;
        produceDraftStateSetMapDimensionFromLayerChangeDimension(
          draft,
          layerId,
          dimension,
        );
      })
      .addCase(setTimeSync, (draft, action) => {
        const { targets: targetsFromAction, source } = action.payload;
        /* Because we want backwards compatibility with the previous code, we also need to listen to the original source action */
        const targets = [];
        if (source) {
          targets.push({
            targetId: source.payload.sourceId,
            value: source.payload.value,
          });
        }
        /* And then append the targets form the action */
        targets.push(...targetsFromAction);
        targets.forEach((target) => {
          const { targetId, value } = target;
          if (draft.byId[targetId]) {
            const dimension: Dimension = {
              name: 'time',
              currentValue: value,
            };
            produceDraftStateSetWebMapDimension(
              draft,
              targetId,
              dimension,
              true,
            );
            produceDraftStateSetMapDimensionFromLayerChangeDimension(
              draft,
              targetId,
              dimension,
            );
          }
        });
      })
      .addCase(setBboxSync, (draft, action) => {
        const { targets: targetsFromAction, source } = action.payload;
        /* Because we want backwards compatibility with the previous code, we also need to listen to the original source action */
        const targets = [];
        if (source) {
          targets.push({
            targetId: source.payload.sourceId,
            bbox: source.payload.bbox,
            srs: source.payload.srs,
          });
        }
        /* And then append the targets form the action */
        targets.push(...targetsFromAction);
        targets.forEach((payload) => {
          const { targetId, bbox, srs } = payload;
          if (draft.byId[targetId]) {
            if (bbox) {
              draft.byId[targetId].bbox = bbox;
            }
            if (srs) {
              draft.byId[targetId].srs = srs;
            }
          }
        });
      })
      .addCase(setLayerActionSync, (draft, action) => {
        /*
         * This GENERIC_SYNC_SETLAYERACTIONS action is generated by the syncgroup listener.
         * It has multiple targets (Layers) in its payload.
         * These targets can be used as payloads in new Layer actions.
         * These actions are here handled via the layer reducer, as it is the same logic
         */
        const { targets, source } = action.payload;

        return targets.reduce(
          (prevState: WebMapState, target: SyncLayerPayloads): WebMapState => {
            const action = {
              payload: target,
              type: source && source.type,
            };
            /* Handle the Layer action with the same logic, using the same reducer */
            return mapReducer(prevState, action);
          },
          draft,
        );
      })
      .addCase(
        layerActions.onUpdateLayerInformation,
        (draft, action: PayloadAction<UpdateLayerInfoPayload>) => {
          const { mapDimensions } = action.payload;
          if (!mapDimensions) {
            return draft;
          }

          const { dimensions, mapId } = mapDimensions;
          dimensions.forEach((dimension) =>
            produceDraftStateSetWebMapDimension(draft, mapId, dimension, false),
          );
          return draft;
        },
      )
      .addCase(mapChangeDimension, (draft, action) => {
        const { mapId, dimension: dimensionFromAction } = action.payload;
        produceDraftStateSetWebMapDimension(
          draft,
          mapId,
          dimensionFromAction,
          true,
        );
      })
      .addCase(setMapPreset, (draft, action) => {
        const { mapId } = action.payload;
        if (!draft.byId[mapId]) {
          return;
        }
        // reset preset state (currently setMapPreset listener is firing actions and these are setting values)
        draft.byId[mapId].baseLayers = [];
        draft.byId[mapId].mapLayers = [];
        draft.byId[mapId].overLayers = [];
        draft.byId[mapId].isAutoUpdating = false;
        draft.byId[mapId].isAnimating = false;
        draft.byId[mapId].isTimeSliderVisible = true;
        draft.byId[mapId].isTimestepAuto = true;
        draft.byId[mapId].shouldShowZoomControls = true;
        draft.byId[mapId].timeStep = undefined;
        draft.byId[mapId].animationDelay = defaultAnimationDelayAtStart;
        draft.byId[mapId].dockedLayerManagerSize = '';
      })
      .addCase(uiActions.registerDialog, (draft, action) => {
        const { mapId, type } = action.payload;
        if (!draft.byId[mapId!]) {
          return;
        }
        draft.byId[mapId!].legendId = type;
      });
  },
});

export const mapActions = {
  ...slice.actions,
  setMapPreset,
  mapChangeDimension,
};

export const { reducer: mapReducer } = slice;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { dateUtils, defaultDelay, PROJECTION } from '@opengeoweb/shared';
import {
  TimeInterval,
  parseISO8601IntervalToDateInterval,
  webmapUtils,
} from '@opengeoweb/webmap';
import {
  defaultTimeSpan,
  defaultTimeStep,
  defaultAnimationDelayAtStart,
  defaultSecondsPerPx,
  SpeedFactorType,
} from '@opengeoweb/timeslider';
import { Bbox, Dimension } from './types';
import type {
  WebMapState,
  WebMap,
  ISO8601Interval,
  WebMapAnimationList,
} from '../types';
import type { Layer } from '../layer/types';

export const dateFormat = dateUtils.DATE_FORMAT_UTC;

export interface CreateMapProps {
  id: string;
  isAnimating?: boolean;
  animationStartTime?: string;
  animationEndTime?: string;
  isAutoUpdating?: boolean;
  shouldEndtimeOverride?: boolean;
  srs?: string;
  bbox?: Bbox;
  mapLayers?: string[];
  baseLayers?: string[];
  overLayers?: string[];
  featureLayers?: string[];
  dimensions?: Dimension[];
  autoTimeStepLayerId?: string;
  autoUpdateLayerId?: string;
  timeSliderSpan?: number;
  timeStep?: number;
  animationDelay?: number;
  timeSliderWidth?: number;
  timeSliderCenterTime?: number;
  timeSliderSecondsPerPx?: number;
  isTimestepAuto?: boolean;
  isTimeSpanAuto?: boolean;
  isTimeSliderHoverOn?: boolean;
  isTimeSliderVisible?: boolean;
  displayMapPin?: boolean;
  disableMapPin?: boolean;
  shouldShowZoomControls?: boolean;
}

export const createMap = ({
  id,
  isAnimating = false,
  animationStartTime = dateUtils.dateToString(
    dateUtils.sub(dateUtils.getNowUtc(), {
      hours: 5,
    }),
    dateFormat,
  )!,
  animationEndTime = dateUtils.dateToString(
    dateUtils.sub(dateUtils.getNowUtc(), {
      minutes: 10,
    }),
    dateFormat,
  )!,
  isAutoUpdating = false,
  shouldEndtimeOverride = false,
  bbox = {
    left: -19000000,
    bottom: -19000000,
    right: 19000000,
    top: 19000000,
  },
  srs = PROJECTION.EPSG_3857.value,
  baseLayers = [],
  overLayers = [],
  mapLayers = [],
  featureLayers = [],
  dimensions = [],
  autoUpdateLayerId,
  autoTimeStepLayerId,
  timeSliderSpan = defaultTimeSpan,
  timeStep = defaultTimeStep,
  animationDelay = defaultAnimationDelayAtStart,
  timeSliderWidth = 440,
  timeSliderCenterTime = dateUtils.unix(dateUtils.utc()),
  timeSliderSecondsPerPx = defaultSecondsPerPx,
  isTimestepAuto = true,
  isTimeSpanAuto = false,
  isTimeSliderHoverOn = false,
  isTimeSliderVisible = true,
  displayMapPin = false,
  disableMapPin = false,
  shouldShowZoomControls = true,
}: CreateMapProps): WebMap => ({
  id,
  isAnimating,
  animationStartTime,
  animationEndTime,
  isAutoUpdating,
  shouldEndtimeOverride,
  srs,
  bbox,
  baseLayers,
  overLayers,
  mapLayers,
  featureLayers,
  dimensions,
  autoUpdateLayerId,
  autoTimeStepLayerId,
  timeSliderSpan,
  timeStep,
  animationDelay,
  timeSliderWidth,
  timeSliderCenterTime,
  timeSliderSecondsPerPx,
  isTimestepAuto,
  isTimeSpanAuto,
  isTimeSliderHoverOn,
  isTimeSliderVisible,
  displayMapPin,
  disableMapPin,
  shouldShowZoomControls,
});

export const checkValidLayersPayload = (
  layers: Layer[],
  mapId: string,
): boolean => {
  /* Check for duplicate ids */
  const layerIds: Record<string, boolean> = {};
  for (const layer of layers) {
    if (layer.id) {
      /* Check if layer is already added to a different map */
      if (layer.mapId && mapId && layer.mapId !== mapId) {
        return false;
      }
      /* Check duplicate */
      if (!layerIds[layer.id!]) {
        layerIds[layer.id!] = true;
      } else {
        return false;
      }
    }
  }
  return true;
};

/**
 * This will get the map from the map draftstate.
 * If the mapId is not found, it registers one and returns it.
 * @param mapId The mapID
 * @param draft Draft map state
 */
export const getDraftMapById = (mapId: string, draft: WebMapState): WebMap => {
  const map = draft.byId[mapId];
  if (map) {
    return map;
  }
  if (!draft.allIds.includes(mapId)) {
    draft.byId[mapId] = createMap({ id: mapId } as WebMap);
    draft.allIds.push(mapId);
  }
  return draft.byId[mapId];
};

/**
 * Sets the map dimension in the state.
 * It will add dimensions to the map if they are missing.
 * If will update the existing dimensions if overwriteCurrentValue is set to true
 * @param draft The map draft state
 * @param mapId The mapId to update the dimensions for
 * @param dimensionFromAction  The dimension from the action
 * @param overwriteCurrentValue True to overwrite existing value. False to add a new dimension if one is not there yet.
 */
export const produceDraftStateSetWebMapDimension = (
  draft: WebMapState,
  mapId: string,
  dimensionFromAction: Dimension,
  overwriteCurrentValue: boolean,
): void => {
  const map = getDraftMapById(mapId, draft);
  if (dimensionFromAction) {
    if (!map.dimensions) {
      map.dimensions = [];
    }
    const { dimensions } = map;
    const mapDim = dimensions.find(
      (dim) => dim.name === dimensionFromAction.name,
    );
    if (mapDim) {
      if (overwriteCurrentValue) {
        mapDim.currentValue = dimensionFromAction.currentValue;
      }
    } else {
      dimensions.push({
        name: dimensionFromAction.name,
        currentValue: dimensionFromAction.currentValue,
      });
    }
  }
};

/**
 * Find the mapId belonging to a layerId
 * @param draft The WebMapState containing the state of all maps.
 * @param layerId The layer Id to find in the maps
 */
export const findMapIdFromLayerId = (
  mapState?: WebMapState,
  layerId?: string,
): string | null => {
  if (!mapState || !layerId) {
    return null;
  }
  const result = mapState.allIds.find((mapId) => {
    return mapState.byId[mapId].mapLayers.find(
      (layerIdFromMap) => layerIdFromMap === layerId,
    );
  });
  return result === undefined ? null : result;
};

/*
    When a layer dimension is changed, it can affect the map dimension if the layer dimension is linked with the map.
    We need to find out from the layerId to which map it is coupled, and then adjust the map dimension
  */

export const produceDraftStateSetMapDimensionFromLayerChangeDimension = (
  draft: WebMapState,
  layerId: string,
  dimension: Dimension,
): void => {
  const wmjsDimension = webmapUtils.getWMJSDimensionForLayerAndDimension(
    layerId,
    dimension.name!,
  );
  if (!wmjsDimension) {
    return;
  }
  /* If the layer dimension is not linked with the map, we should not update the map dimension */
  if (!wmjsDimension.linked) {
    return;
  }
  const mapId = findMapIdFromLayerId(draft, layerId);
  if (!mapId) {
    return;
  }

  produceDraftStateSetWebMapDimension(draft, mapId, dimension, true);
};

/**
 * Returns array with new order of swapped elements
 * @param array Array with ids
 * @param oldIndex Old index of element in array
 * @param newIndex New index of element in array
 */
export function moveArrayElements<T>(
  array: T[],
  oldIndex: number,
  newIndex: number,
): T[] {
  const newArray = [...array];
  const indexNew = Math.min(newIndex, newArray.length - 1);
  newArray.splice(indexNew, 0, newArray.splice(oldIndex, 1)[0]);
  return newArray;
}

export const getTimeStepFromDataInterval = (
  timeInterval: TimeInterval,
): number => {
  switch (timeInterval.isRegularInterval) {
    case false:
      switch (timeInterval.year) {
        case 0: // month
          return 30 * 24 * 60;
        default:
          return timeInterval.year * 365 * 24 * 60;
      }
    case true:
      if (timeInterval.day !== 0 && !isNaN(timeInterval.day)) {
        return timeInterval.day * 24 * 60;
      }
      if (timeInterval.hour !== 0 && !isNaN(timeInterval.hour)) {
        return timeInterval.hour * 60;
      }
      if (timeInterval.minute !== 0 && !isNaN(timeInterval.minute)) {
        return timeInterval.minute;
      }
      if (timeInterval.second !== 0 && !isNaN(timeInterval.second)) {
        return timeInterval.second / 60.0;
      }
      return defaultTimeStep;
    default:
      return defaultTimeStep;
  }
};

export const getSpeedFactor = (speedDelay: number): SpeedFactorType => {
  return (defaultDelay / speedDelay) as SpeedFactorType;
};

export const getAnimationDuration = (
  animationEndTime: string | undefined,
  animationStartTime: string | undefined,
): number =>
  animationEndTime && animationStartTime
    ? dateUtils.differenceInMinutes(
        new Date(animationEndTime),
        new Date(animationStartTime),
      )
    : 0;

/**
 * @param animationStart
 * @param animationEnd
 * @param iso8601Intervals
 * @returns WebMapAnimationList with time points
 */
export const generateAnimationList = (
  unixAnimationStart: number,
  unixAnimationEnd: number,
  timeValues: string | undefined,
): WebMapAnimationList => {
  if (!unixAnimationStart || !unixAnimationEnd || !timeValues) {
    return [];
  }
  const iso8601Intervals: ISO8601Interval[] =
    parseTimeDimToISO8601Interval(timeValues);

  const animationList: WebMapAnimationList = [];

  // If there are no ISO8601 intervals in the time dimension we treat it as a list of time values
  if (!iso8601Intervals.length) {
    timeValues.split(',').forEach((timeValue) => {
      if (
        unixAnimationStart <= dateUtils.unix(dateUtils.utc(timeValue)) &&
        dateUtils.unix(dateUtils.utc(timeValue)) <= unixAnimationEnd
      ) {
        animationList.push({
          name: 'time',
          value: timeValue,
        });
      }
    });
    return animationList;
  }

  // Initialize aggregate start time for animation points in case of multiple intervals
  let currentAnimationTime = unixAnimationStart;

  // Iterate over each iso8601 interval and generate animation points
  iso8601Intervals.forEach((timeInterval) => {
    if (!timeInterval.duration) {
      return;
    }

    // Convert interval start and end times to Unix time
    const unixIntervalStart = dateUtils.unix(
      dateUtils.utc(timeInterval.startTime),
    );
    const unixIntervalEnd = dateUtils.unix(dateUtils.utc(timeInterval.endTime));

    // Parse the duration and calculate interval step in seconds
    const interval = parseISO8601IntervalToDateInterval(timeInterval.duration);
    const intervalSeconds = getTimeStepFromDataInterval(interval) * 60;

    // Generate animation points for the current interval
    for (
      let i = currentAnimationTime;
      i <= unixAnimationEnd;
      i += intervalSeconds
    ) {
      if (i >= unixIntervalStart && i <= unixIntervalEnd) {
        animationList.push({
          name: 'time',
          value: dateUtils.fromUnix(i).toISOString(),
        });
      }
      // If the next step exceeds the current interval, break to switch to next interval
      if (i + intervalSeconds > unixIntervalEnd) {
        break;
      }
    }

    // Update the start time for the next interval
    currentAnimationTime = unixIntervalStart + intervalSeconds;
  });
  return animationList;
};

/**
 * Parses the time dimension values string to an array of ISO8601 intervals
 * @param timeInterval
 * @returns An array of ISO8601 intervals
 */

export const parseTimeDimToISO8601Interval = (
  timeInterval: string | undefined,
): ISO8601Interval[] => {
  if (!timeInterval || !timeInterval.includes('/')) {
    return [];
  }

  const intervalList = timeInterval.split(',').map((interval: string) => {
    const [startTime, endTime, duration] = interval.split('/');
    return {
      startTime: startTime?.trim(),
      endTime: endTime?.trim(),
      duration: duration?.trim(),
    };
  });

  // Filter out intervals with missing start, end or duration
  return intervalList.filter(
    (interval) => interval.startTime && interval.endTime && interval.duration,
  );
};

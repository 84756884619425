/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Grid2 as Grid, Typography, List, ListItem } from '@mui/material';
import { SubdirectoryArrowRight } from '@opengeoweb/theme';

import { StatusTag } from '@opengeoweb/shared';
import { EventCategoryDetail, SWEventLifeCycle } from '../../types';
import {
  getStatusTagColorFromContent,
  getStatusTagContent,
} from '../Notifications/utils';
import { formatDate } from './utils';
import { useSpaceweatherTranslation } from '../../utils/i18n';

const styles = {
  notificationHeader: { marginTop: '15px', marginBottom: '10px' },
  messageHeader: { fontWeight: 'bold', paddingTop: '10px' },
};

export interface LifeCycleColumnProps {
  lifeCycle: SWEventLifeCycle;
  categoryDetail: string;
  type: string;
}

export const formatDateWithUTC = (value: string): string | null | undefined =>
  value ? formatDate(value)?.concat(' UTC') : null;

const LifeCycleColumn: React.FC<LifeCycleColumnProps> = ({
  lifeCycle,
  categoryDetail,
  type,
}: LifeCycleColumnProps) => {
  const { t } = useSpaceweatherTranslation();
  return (
    <List data-testid="lifecycle-column">
      <ListItem>
        <Grid container spacing={1} size={12}>
          <Grid size={6}>
            <Typography variant="overline" data-testid="lifecycle-column-owner">
              {t('notification-originator')}
            </Typography>
            <Typography variant="body1">{lifeCycle.owner}</Typography>
          </Grid>
          {categoryDetail && (
            <Grid size={6}>
              <Typography variant="overline">
                {t('notification-category-detail')}
              </Typography>
              <Typography
                variant="body1"
                data-testid="lifecycle-column-categorydetail"
              >
                {
                  EventCategoryDetail[
                    categoryDetail as keyof typeof EventCategoryDetail
                  ]
                }
              </Typography>
            </Grid>
          )}
        </Grid>
      </ListItem>
      {lifeCycle.notifications &&
        lifeCycle.notifications
          .filter(
            (notification) =>
              // Remove all drafts, they should not be displayed
              !notification.draft,
          )
          .map((notification, key) => {
            const eventStartFormat = formatDateWithUTC(
              notification.neweventstart,
            );
            const eventEndFormat =
              notification.neweventend && notification.neweventend !== ''
                ? formatDateWithUTC(notification.neweventend)
                : null;

            const statusTagContent = getStatusTagContent(
              notification.label,
              categoryDetail,
              notification.changestateto,
            );
            return (
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={`${notification.notificationid}-${notification.issuetime}-${key}`}
                data-testid={`${notification.notificationid}-${notification.issuetime}`}
              >
                <Grid container spacing={1}>
                  <Grid sx={styles.notificationHeader} size={12}>
                    {key !== 0 && <SubdirectoryArrowRight />}
                    <Grid container data-testid="lifecycle-column-notification">
                      <Grid size={10}>
                        <Typography variant="overline">
                          {t('notification-issue-time')}
                        </Typography>
                        <Typography
                          variant="body1"
                          data-testid="lifecycle-issuetime"
                        >
                          {formatDateWithUTC(notification.issuetime!)}
                        </Typography>
                      </Grid>
                      <Grid size={2}>
                        <StatusTag
                          content={statusTagContent}
                          color={getStatusTagColorFromContent(statusTagContent)}
                          sx={{ width: 'auto' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {notification.datasource && (
                    <Grid size={12}>
                      <Typography variant="overline">
                        {t('notification-source')}
                      </Typography>
                      <Typography
                        variant="body1"
                        data-testid="lifecycle-column-datasource"
                      >
                        {notification.datasource}
                      </Typography>
                    </Grid>
                  )}
                  <Grid size={12}>
                    <Grid container>
                      {notification.neweventlevel && (
                        <Grid size={2}>
                          <Typography variant="overline">
                            {t('notification-event-level')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-neweventlevel"
                          >
                            {notification.neweventlevel}
                          </Typography>
                        </Grid>
                      )}
                      {notification.threshold && (
                        <Grid size={3}>
                          <Typography variant="overline">
                            {t('notification-threshold')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-threshold"
                          >
                            {notification.threshold}
                          </Typography>
                        </Grid>
                      )}
                      {notification.thresholdunit && (
                        <Grid size={3}>
                          <Typography variant="overline">
                            {t('notification-threshold-unit')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-thresholdunit"
                          >
                            {notification.thresholdunit}
                          </Typography>
                        </Grid>
                      )}
                      {notification.initialgscale && (
                        <Grid size={3}>
                          <Typography variant="overline">
                            {t('notification-g-scale')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-initialgscale"
                          >
                            {notification.initialgscale}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {notification.xrayclass && (
                    <Grid size={12}>
                      <Grid container>
                        <Grid size={2}>
                          <Typography variant="overline">
                            {t('notification-xray-class')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-xrayclass"
                          >
                            {notification.xrayclass}
                          </Typography>
                        </Grid>
                        {notification.peakclass && (
                          <Grid size={2}>
                            <Typography variant="overline">
                              {t('notification-peak-class')}
                            </Typography>
                            <Typography
                              variant="body1"
                              data-testid="lifecycle-column-peakclass"
                            >
                              {notification.peakclass}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {notification.peakflux && (
                    <Grid size={12}>
                      <Grid container>
                        <Grid size={5}>
                          <Typography variant="overline">
                            {t('notification-peak-flux')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-peakflux"
                          >
                            {notification.peakflux}
                          </Typography>
                        </Grid>
                        {notification.peakfluxtime && (
                          <Grid size={5}>
                            <Typography variant="overline">
                              {t('notification-peak-flux-time')}
                            </Typography>
                            <Typography
                              variant="body1"
                              data-testid="lifecycle-column-peakfluxtime"
                            >
                              {formatDateWithUTC(notification.peakfluxtime)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {notification.impulsetime && (
                    <Grid size={12}>
                      <Grid container>
                        <Grid size={5}>
                          <Typography variant="overline">
                            {t('notification-impulse-time')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-impulsetime"
                          >
                            {formatDateWithUTC(notification.impulsetime)}
                          </Typography>
                        </Grid>
                        {notification.magnetometerdeflection && (
                          <Grid size={3}>
                            <Typography variant="overline">
                              {t('notification-magnetometer')}
                            </Typography>
                            <Typography
                              variant="body1"
                              data-testid="lifecycle-column-magnetometerdeflection"
                            >
                              {notification.magnetometerdeflection}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {notification.shocktime && (
                    <Grid size={12}>
                      <Grid container>
                        <Grid size={5}>
                          <Typography variant="overline">
                            {t('notification-shock-time')}
                          </Typography>
                          <Typography
                            variant="body1"
                            data-testid="lifecycle-column-shocktime"
                          >
                            {formatDateWithUTC(notification.shocktime)}
                          </Typography>
                        </Grid>
                        {notification.observedpolaritybz && (
                          <Grid size={3}>
                            <Typography variant="overline">
                              {t('notification-polarity')}
                            </Typography>
                            <Typography
                              variant="body1"
                              data-testid="lifecycle-column-observedpolaritybz"
                            >
                              {notification.observedpolaritybz}
                            </Typography>
                          </Grid>
                        )}
                        {notification.observedsolarwind && (
                          <Grid size={4}>
                            <Typography variant="overline">
                              {t('notification-solar-wind')}
                            </Typography>
                            <Typography
                              variant="body1"
                              data-testid="lifecycle-column-observedsolarwind"
                            >
                              {notification.observedsolarwind}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid size={12}>
                    <Grid container>
                      <Grid size={5}>
                        <Typography variant="overline">
                          {t('notification-start')}
                        </Typography>
                        <Typography data-testid="lifecycle-column-eventstart">
                          {eventStartFormat}
                        </Typography>
                      </Grid>
                      {eventEndFormat && (
                        <Grid size={5}>
                          <Typography variant="overline">
                            {t('notification-end')}
                          </Typography>
                          <Typography data-testid="lifecycle-column-eventend">
                            {eventEndFormat}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid size={12}>
                    {type === 'external' && notification.message && (
                      <>
                        <Typography variant="body2" sx={styles.messageHeader}>
                          {t('notification-potential-impact')}
                        </Typography>
                        <Typography
                          variant="body2"
                          data-testid="lifecycle-column-message-impact"
                          paragraph
                        >
                          {notification.message}
                        </Typography>
                      </>
                    )}
                    {type === 'internal' && (
                      <>
                        {notification.title && (
                          <Typography
                            variant="body2"
                            sx={styles.messageHeader}
                            data-testid="lifecycle-column-title"
                          >
                            {notification.title}
                          </Typography>
                        )}
                        <Typography
                          variant="body2"
                          data-testid="lifecycle-column-message"
                        >
                          {notification.message}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
    </List>
  );
};

export default LifeCycleColumn;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { WMLayer, LayerType } from '@opengeoweb/webmap';
import { dateUtils, PROJECTION } from '@opengeoweb/shared';

import { range } from 'lodash';
import { useSelector } from 'react-redux';
import {
  CoreAppStore,
  layerTypes,
  syncGroupsSelectors,
} from '@opengeoweb/store';
import { publicLayers } from '@opengeoweb/webmap-react';
import { MultiMapPreset, MultiMapViewConnect } from '../MultiMapViewConnect';

const useGetLatestReferenceTime = (inputLayer: WMLayer): string => {
  const [latestReferenceTime, setLatestReferenceTime] =
    React.useState<string>('');
  React.useEffect(() => {
    const wmLayer = new WMLayer({ ...inputLayer });
    wmLayer
      .parseLayer()
      .then((layer: WMLayer) => {
        const refTimeDim = layer.getDimension('reference_time')!;
        const lastRefTime = refTimeDim.getLastValue();
        setLatestReferenceTime(lastRefTime);
      })
      .catch(console.error);
  }, [inputLayer]);
  return latestReferenceTime;
};

/**
 * Custom hook to find out if the list of syncgroup id's contains a group which is of type SYNCGROUPS_TYPE_SETLAYERACTIONS
 * @param syncGroupsIds A string array of syncgroup ids
 * @returns True if indeed a group is included which is of type SYNCGROUPS_TYPE_SETLAYERACTIONS
 */
export const useHasSyncGroupWithLayerActions = (
  syncGroupsIds: string[] | undefined,
): boolean => {
  /* Get Syncgroupstate */
  const syncGroupState = useSelector((store: CoreAppStore) =>
    syncGroupsSelectors.getSynchronizationGroupState(store),
  );
  if (!syncGroupState) {
    return false;
  }
  /* Return all syncgroups configured for this component of type SYNCGROUPS_TYPE_SETLAYERACTIONS */
  const syncGroupsWithLayerActions = syncGroupState.groups.allIds.filter(
    (syncGroupId) =>
      syncGroupsIds &&
      syncGroupsIds.includes(syncGroupId) &&
      syncGroupState.groups.byId[syncGroupId].type ===
        'SYNCGROUPS_TYPE_SETLAYERACTIONS',
  );

  /* Figure out if there is a syncgroup which controls layeractions.  */
  const displayLayerManagerAndLegendButtonInAllMaps = syncGroupsIds
    ? syncGroupsWithLayerActions.length === 1
    : false;

  return displayLayerManagerAndLegendButtonInAllMaps;
};

/* Helper to make a map layer preset */
export const makeMapPreset = (
  timeIncrement: number,
  uniqueId: string,
  referenceTime: string,
  layers: layerTypes.Layer[],
  syncGroupsIds: string[],
  displayLayerManagerAndLegendButtonInMap: boolean,
  displayDimensionSelectButtonInMap: boolean,
): MultiMapPreset => {
  const mapTime =
    dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(referenceTime), { hours: timeIncrement }),
    ) || dateUtils.getCurrentTimeAsString();
  return {
    id: uniqueId,
    syncGroupsIds,
    title: `FC +${timeIncrement}`,
    displayTimeInMap: true,
    bbox: {
      left: -638936.0386164065,
      bottom: 6068439.661512798,
      right: 1613044.3568348314,
      top: 7876599.103115981,
    },
    srs: PROJECTION.EPSG_3857.value,
    componentType: 'MultiMap',
    layers: layers.map((layer, index) => ({
      ...layer,
      id: `${uniqueId}-${index}`,
      /* Set time dimensions if layer type is not overLayer */
      dimensions:
        layer.layerType !== LayerType.overLayer
          ? [
              {
                name: 'reference_time',
                currentValue: referenceTime,
              },
              {
                name: 'time',
                currentValue: mapTime,
              },
            ]
          : [],
    })),
    displayLayerManagerAndLegendButtonInMap,
    displayDimensionSelectButtonInMap,
  };
};

export interface ModelRunIntervalProps {
  layers?: layerTypes.Layer[];
  syncGroupsIds: string[];
  interval?: number;
  startTimeIncrement?: number;
  multiLegend?: boolean;
}

export const ModelRunInterval: React.FC<ModelRunIntervalProps> = ({
  layers = [
    publicLayers.harmoniePressure,
    publicLayers.harmonieWindFlags,
    publicLayers.harmoniePrecipitation,
  ],
  syncGroupsIds,
  interval = 3,
  startTimeIncrement = 3,
  multiLegend = false,
}: ModelRunIntervalProps) => {
  const displayLayerManagerAndLegendButtonInAllMaps =
    !useHasSyncGroupWithLayerActions(syncGroupsIds);
  /* Get latest reference time for layer that is not an overLayer */
  const latestReferenceTime = useGetLatestReferenceTime(
    layers.find((layer) => layer.layerType !== LayerType.overLayer) as WMLayer,
  );
  if (latestReferenceTime === '') {
    return <div>Loading...</div>;
  }
  /* Calculate the last time increment based on 15 maps */
  const lastTimeIncrement = startTimeIncrement + 15 * interval;
  /* Make the preset */
  const timeIncrements = [
    ...range(startTimeIncrement, lastTimeIncrement, interval),
  ];

  /* Add the layers with the different map times */

  const maps = timeIncrements.map((increment, index) => {
    return makeMapPreset(
      increment,
      `harm_precip${increment}`,
      latestReferenceTime,
      layers,
      syncGroupsIds,
      index === 0 || displayLayerManagerAndLegendButtonInAllMaps,
      index === 0 || displayLayerManagerAndLegendButtonInAllMaps,
    );
  });
  return (
    <MultiMapViewConnect
      rows={3}
      cols={5}
      maps={maps}
      syncTime={false}
      showTimeSlider={false}
      multiLegend={multiLegend}
      showClock={false}
    />
  );
};

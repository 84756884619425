/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';

import { useFormContext } from 'react-hook-form';
import { errorMessages, isEmpty } from '../../components';

// hidden input helpers, should not be part of send formdata
export const HIDDEN_INPUT_HELPER_IS_DRAFT = 'IS_DRAFT';

export const useDraftFormHelpers = (
  isDefaultDraft = false,
): {
  isRequired: (value: string) => boolean | string;
  toggleIsDraft: (isDraft: boolean) => void;
  isDraft: () => boolean;
  DraftFieldHelper: () => React.ReactElement;
} => {
  const { getValues, setValue, register } = useFormContext();

  React.useEffect(() => {
    setValue(HIDDEN_INPUT_HELPER_IS_DRAFT, isDefaultDraft, {
      shouldDirty: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDraft = (): boolean =>
    getValues(HIDDEN_INPUT_HELPER_IS_DRAFT) === true;

  const isRequired = (value: string): string | boolean => {
    if (!isDraft() && isEmpty(value)) {
      return errorMessages.required;
    }
    return true;
  };

  const toggleIsDraft = (isDraft: boolean): void =>
    setValue(HIDDEN_INPUT_HELPER_IS_DRAFT, isDraft, { shouldDirty: false });

  const DraftFieldHelper = (): React.ReactElement => (
    <input {...register(HIDDEN_INPUT_HELPER_IS_DRAFT)} type="hidden" />
  );

  return { isRequired, toggleIsDraft, isDraft, DraftFieldHelper };
};

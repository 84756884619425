/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

export const bgColors = {
  green: 'geowebColors.functional.successOutline.fill',
  grey: 'geowebColors.functional.disabledOutline.fill',
  purple: 'geowebColors.functional.summaryOutline.fill',
  red: 'geowebColors.functional.errorOutline.fill',
  yellow: 'geowebColors.functional.warningOutline.fill',
  blue: 'geowebColors.functional.notificationOutline.fill',
};

const borderColors = {
  green: 'geowebColors.functional.successOutline.borderColor',
  grey: 'geowebColors.functional.disabledOutline.borderColor',
  purple: 'geowebColors.functional.summaryOutline.borderColor',
  red: 'geowebColors.functional.errorOutline.borderColor',
  yellow: 'geowebColors.functional.warningOutline.borderColor',
  blue: 'geowebColors.functional.notificationOutline.borderColor',
};

const tagStyle = (color: string): Record<string, unknown> => {
  // Set color to grey for summary
  const backgroundColor = { ...bgColors }[color];
  const borderColor = { ...borderColors }[color];

  return {
    backgroundColor,
    m: 0,
    p: 0,
    border: '1px solid',
    borderColor,
    borderRadius: '4px',
    width: '120px',
    height: '18px',
    lineHeight: '16px',
    color: 'geowebColors.captions.captionStatus.rgba',
  };
};

interface StatusTagProps extends BoxProps {
  content: string;
  color: string;
  icon?: React.ReactNode;
}

const StatusTag: React.FC<StatusTagProps> = ({
  content,
  color,
  icon,
  ...props
}: StatusTagProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      textAlign="center"
      data-testid="statusTag"
      className="statusTag"
      position="relative"
      sx={{ ...tagStyle(color), ...props.sx }}
    >
      <Box
        sx={{
          position: 'absolute',
          color: 'geowebColors.iconButtons.flat.default.color',
        }}
      >
        {icon}
      </Box>
      <Typography sx={{ width: '100%' }} variant="caption">
        {content}
      </Typography>
    </Box>
  );
};

export default StatusTag;

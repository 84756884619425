/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { useRef } from 'react';

export const pollingIntervalTimeout = 300000;

export const usePoller = (
  deps: unknown[],
  callback: () => void,
  pollingInterval = pollingIntervalTimeout,
): React.RefObject<ReturnType<typeof setTimeout>> => {
  const interval = useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    if (deps.filter((dep) => dep !== null).length > 0) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      interval.current = setInterval(() => {
        callback();
      }, pollingInterval);
    }
    return (): void => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps]);

  return interval;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Theme } from '@mui/material';
import { dateUtils } from '@opengeoweb/shared';
import {
  Scale,
  timestampToPixelEdges,
  getFundamentalScale,
} from '../../TimeSlider/timeSliderUtils';

type ScaleToUnitSecondsType = Partial<{ [key in Scale]: number }>;

const scaleToUnitSeconds: ScaleToUnitSecondsType = {
  [Scale.Minutes5]: 5 * 60,
  [Scale.Hour]: 60 * 60,
  [Scale.Hours3]: 3 * 60 * 60,
  [Scale.Hours6]: 6 * 60 * 60,
  [Scale.Day]: 24 * 60 * 60,
  [Scale.Week]: 7 * 24 * 60 * 60,
  [Scale.Weeks2]: 14 * 24 * 60 * 60,
};

const TICK_MARK_HEIGHT_PRIMARY = 40;
const TICK_MARK_HEIGHT_SECONDARY = 10;
const TICK_MARK_HEIGHT_TERTIARY = 5;

const getStepPxLineHeight = (scale: Scale, timestep: number): number => {
  const scaleUnitSeconds = scaleToUnitSeconds[scale];
  const rem = timestep % scaleUnitSeconds!;
  switch (scale) {
    case Scale.Hour: {
      if (rem === 0) {
        return TICK_MARK_HEIGHT_PRIMARY;
      }
      // 15 min subdivisions
      if (rem % (15 * 60) === 0) {
        return TICK_MARK_HEIGHT_SECONDARY;
      }
      return TICK_MARK_HEIGHT_TERTIARY;
    }
    case Scale.Hours3: {
      if (rem === 0) {
        return TICK_MARK_HEIGHT_PRIMARY;
      }
      return TICK_MARK_HEIGHT_TERTIARY;
    }
    case Scale.Hours6: {
      if (rem === 0) {
        return TICK_MARK_HEIGHT_PRIMARY;
      }
      // 3 hour subdivisions
      if (rem % (3 * 60 * 60) === 0) {
        return TICK_MARK_HEIGHT_SECONDARY;
      }
      return TICK_MARK_HEIGHT_TERTIARY;
    }
    case Scale.Day: {
      if (rem === 0) {
        return TICK_MARK_HEIGHT_PRIMARY;
      }
      // 6 hour subdivisions
      if (rem % (6 * 60 * 60) === 0) {
        return TICK_MARK_HEIGHT_SECONDARY;
      }
      return TICK_MARK_HEIGHT_TERTIARY;
    }
    case Scale.Week:
    case Scale.Weeks2: {
      const weekday = dateUtils.getDay(dateUtils.fromUnix(timestep));
      // dateUtils days range from 0 (Sunday) to 6 (Saturday)
      if (weekday === 1) {
        return TICK_MARK_HEIGHT_PRIMARY;
      }
      return TICK_MARK_HEIGHT_TERTIARY;
    }
    case Scale.Year: {
      const month = dateUtils.getMonth(dateUtils.fromUnix(timestep));
      // months are zero indexed
      if (month === 0) {
        return TICK_MARK_HEIGHT_PRIMARY;
      }
      if (month % 3 === 0) {
        return TICK_MARK_HEIGHT_SECONDARY;
      }
      return TICK_MARK_HEIGHT_TERTIARY;
    }
    case Scale.Minutes5: {
      // add primary line on day change
      if (timestep % (60 * 60 * 24) === 0) {
        return TICK_MARK_HEIGHT_PRIMARY;
      }
      return TICK_MARK_HEIGHT_TERTIARY;
    }
    default:
      // Scale.Month
      return TICK_MARK_HEIGHT_TERTIARY;
  }
};

const drawStepPxLine = (
  ctx: CanvasRenderingContext2D,
  theme: Theme,
  stepPx: number,
  height: number,
  fundamentalScale: Scale,
  timestep: number,
): void => {
  const ctx2 = ctx;
  ctx2.strokeStyle =
    theme.palette.geowebColors.timeSlider.timelineTimeScale.fill!;
  ctx2.lineWidth = 1;
  const stepPxLineHeight = getStepPxLineHeight(fundamentalScale, timestep);

  ctx2.beginPath();
  ctx2.moveTo(stepPx, height - stepPxLineHeight);
  ctx2.lineTo(stepPx, height);
  ctx2.stroke();
};

const drawTimeText = (
  ctx: CanvasRenderingContext2D,
  theme: Theme,
  stepPx: number,
  height: number,
  timeText: string,
  scale: Scale,
): void => {
  const ctx2 = ctx;
  const { fontSize, fontFamily, color } =
    theme.palette.geowebColors.timeSlider.timelineText;
  ctx2.fillStyle = color!;
  // time text has smaller font than other timeline text
  ctx2.font = `${fontSize}px ${fontFamily}`;
  ctx2.textAlign = 'left';
  if (
    scale === Scale.Year ||
    scale === Scale.Months3 ||
    scale === Scale.Month ||
    scale === Scale.Weeks2 ||
    scale === Scale.Week ||
    scale === Scale.Day
  ) {
    ctx2.fillText(timeText, stepPx, height - 12);
  } else {
    ctx2.fillText(timeText, stepPx - 15, height - 12);
  }
};

const drawYearScaleText = (
  ctx: CanvasRenderingContext2D,
  theme: Theme,
  newDate: string,
  stepPx: number,
  height: number,
): void => {
  const ctx2 = ctx;
  const { fontSize, fontFamily, color } =
    theme.palette.geowebColors.timeSlider.timelineText;
  ctx2.fillStyle = color!;
  ctx2.font = `${fontSize}px ${fontFamily}`;
  ctx2.textAlign = 'left';
  ctx2.fillText(newDate, stepPx + 2, height - 10);
};

const drawLeftSideDateText = (
  ctx: CanvasRenderingContext2D,
  theme: Theme,
  newDate: string,
  height: number,
): void => {
  const ctx2 = ctx;
  const { fontSize, fontFamily, color } =
    theme.palette.geowebColors.timeSlider.timelineText;
  ctx2.fillStyle = color!;
  ctx2.font = `${fontSize}px ${fontFamily}`;
  ctx2.textAlign = 'left';
  ctx2.fillText(newDate, 2, height - 30);
};

const getTimeFormat = (scale: number): string => {
  switch (scale) {
    case Scale.Year:
      return 'y';
    case Scale.Month:
    case Scale.Months3:
      return 'MMM';
    case Scale.Week:
    case Scale.Weeks2:
      return 'dd MMM';
    case Scale.Day:
      return 'EEE dd';
    default:
      return dateUtils.DATE_FORMAT_HOURS;
  }
};

const drawDateChangeLine = (
  ctx: CanvasRenderingContext2D,
  theme: Theme,
  stepPx: number,
  height: number,
): void => {
  const ctx2 = ctx;
  ctx2.strokeStyle =
    theme.palette.geowebColors.timeSlider.timelineTimeScale.fill!;
  ctx2.lineWidth = 1;
  ctx2.beginPath();
  ctx2.moveTo(stepPx, height - 40);
  ctx2.lineTo(stepPx, height);
  ctx2.stroke();
};

const drawDashedMonthChangeLine = (
  ctx: CanvasRenderingContext2D,
  theme: Theme,
  stepPx: number,
  height: number,
): void => {
  const ctx2 = ctx;
  ctx2.strokeStyle =
    theme.palette.geowebColors.timeSlider.timelineMonthChangeDash.rgba!;
  ctx2.lineWidth = 3;
  ctx2.setLineDash([5, 5]);
  ctx2.beginPath();
  ctx2.moveTo(stepPx, 0);
  ctx2.lineTo(stepPx, height);
  ctx2.stroke();
  ctx2.setLineDash([]);
};

export const roundByUnitOfTime = (
  timeUnix: number,
  unit: string,
  roundUp = false,
): number => {
  if (unit === 'hour') {
    const timeStartOfPassedUnit = dateUtils.startOfHourUTC(
      dateUtils.fromUnix(timeUnix),
    );
    if (roundUp) {
      return dateUtils.unix(dateUtils.add(timeStartOfPassedUnit, { hours: 1 }));
    }
    return dateUtils.unix(timeStartOfPassedUnit);
  }

  if (unit === 'day') {
    const timeStartOfPassedUnit = dateUtils.startOfDayUTC(
      dateUtils.fromUnix(timeUnix),
    );
    if (roundUp) {
      return dateUtils.unix(dateUtils.add(timeStartOfPassedUnit, { days: 1 }));
    }
    return dateUtils.unix(timeStartOfPassedUnit);
  }

  if (unit === 'week') {
    const timeStartOfPassedUnit = dateUtils.startOfWeekUTC(
      dateUtils.fromUnix(timeUnix),
    );
    if (roundUp) {
      return dateUtils.unix(dateUtils.add(timeStartOfPassedUnit, { weeks: 1 }));
    }
    return dateUtils.unix(timeStartOfPassedUnit);
  }

  if (unit === 'month') {
    const timeStartOfPassedUnit = dateUtils.startOfMonthUTC(
      dateUtils.fromUnix(timeUnix),
    );
    if (roundUp) {
      return dateUtils.unix(
        dateUtils.add(timeStartOfPassedUnit, { months: 1 }),
      );
    }
    return dateUtils.unix(timeStartOfPassedUnit);
  }

  // Default unit === 'year'
  const timeStartOfPassedUnit = dateUtils.startOfYearUTC(
    dateUtils.fromUnix(timeUnix),
  );
  if (roundUp) {
    return dateUtils.unix(dateUtils.add(timeStartOfPassedUnit, { years: 1 }));
  }
  return dateUtils.unix(timeStartOfPassedUnit);
};

const roundToNearestMultiple = (
  timeUnix: number,
  scale: Scale,
  roundUp = false,
): number => {
  const stepSeconds = scaleToUnitSeconds[scale];
  const roundFn = roundUp ? Math.ceil : Math.floor;
  return roundFn(timeUnix / stepSeconds!) * stepSeconds!;
};

/**
 * In this script starting and ending times of the time slider are rounded.
 * Here it is defined what is a time unit to a start of which a rounding is
 * done for each scale.
 * @returns an object where there are rounding units of each scale
 */

const scaleToRoundableUnit = {
  [Scale.Hour]: 'hour',
  [Scale.Day]: 'day',
  [Scale.Week]: 'week',
  [Scale.Weeks2]: 'week',
  [Scale.Month]: 'week',
  [Scale.Months3]: 'week',
  [Scale.Year]: 'year',
};

export const getRoundedStartAndEnd = (
  visibleTimeStart: number,
  visibleTimeEnd: number,
  scale: Scale,
): [number, number] => {
  if (
    scale === Scale.Minutes5 ||
    scale === Scale.Hours3 ||
    scale === Scale.Hours6
  ) {
    return [
      roundToNearestMultiple(visibleTimeStart, scale, false),
      roundToNearestMultiple(visibleTimeEnd, scale, true),
    ];
  }
  const unit = scaleToRoundableUnit[scale];
  return [
    roundByUnitOfTime(visibleTimeStart, unit, false),
    roundByUnitOfTime(visibleTimeEnd, unit, true),
  ];
};

const getCustomRoundedStartAndEndScale = (scale: Scale): string => {
  switch (scale) {
    case Scale.Year:
      return 'month';
    case Scale.Month:
    case Scale.Months3:
      return 'week';
    case Scale.Week:
    case Scale.Weeks2:
      return 'day';
    default:
      return 'day';
  }
};

export const getCustomRoundedStartAndEnd = (
  visibleTimeStart: number,
  visibleTimeEnd: number,
  unit: Scale,
): [number, number] => {
  const scale = getCustomRoundedStartAndEndScale(unit);
  return [
    roundByUnitOfTime(visibleTimeStart, scale, false),
    roundByUnitOfTime(visibleTimeEnd, scale, true),
  ];
};

const incrementTimestep = (
  timeAtTimeStep: number,
  fundamentalScale: Scale,
): number => {
  switch (fundamentalScale) {
    case Scale.Minutes5:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { minutes: 1 }),
      );
    case Scale.Hour:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { minutes: 5 }),
      );
    case Scale.Hours3:
    case Scale.Hours6:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { hours: 1 }),
      );
    case Scale.Day:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { hours: 3 }),
      );
    case Scale.Week:
    case Scale.Weeks2:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { days: 1 }),
      );
    case Scale.Month:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { weeks: 1 }),
      );
    case Scale.Year:
    case Scale.Months3:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { months: 1 }),
      );
    default:
      return dateUtils.unix(
        dateUtils.add(dateUtils.fromUnix(timeAtTimeStep), { months: 1 }),
      );
  }
};

const getTimesteps = (
  roundedStart: number,
  roundedEnd: number,
  fundamentalScaleType: Scale,
): number[] => {
  const timesteps: number[] = [];
  let timestep = roundedStart;
  while (timestep <= roundedEnd) {
    timesteps.push(timestep);
    timestep = incrementTimestep(timestep, fundamentalScaleType);
  }
  return timesteps;
};

export const getCustomTimesteps = (
  roundedStart: number,
  roundedEnd: number,
  increment: string,
): number[] => {
  const timesteps: number[] = [];
  let timestep = roundedStart;
  while (timestep <= roundedEnd) {
    timesteps.push(timestep);
    timestep = dateUtils.unix(
      dateUtils.add(dateUtils.fromUnix(timestep), { [increment]: 1 }),
    );
  }
  return timesteps;
};

const getLeftSideDateText = (
  timestamp: number,
  fundamentalScale: Scale,
): string => {
  const time = dateUtils.fromUnix(timestamp);
  switch (fundamentalScale) {
    case Scale.Minutes5:
    case Scale.Hour:
    case Scale.Hours3:
    case Scale.Hours6:
      return dateUtils.dateToString(time, 'y MMM EEE dd')!;
    case Scale.Day:
    case Scale.Week:
    case Scale.Weeks2:
      return dateUtils.dateToString(time, 'y MMM')!;
    case Scale.Month:
    case Scale.Months3:
      return dateUtils.dateToString(time, 'y')!;
    case Scale.Year:
      return dateUtils.dateToString(time, 'y')!;
    default:
      return '';
  }
};

export const drawTimeScale = (
  context: CanvasRenderingContext2D,
  theme: Theme,
  visibleTimeStart: number,
  visibleTimeEnd: number,
  canvasWidth: number,
  height: number,
  secondsPerPx: number,
): void => {
  const fundamentalScale = getFundamentalScale(secondsPerPx);

  const [roundedStart, roundedEnd] = getRoundedStartAndEnd(
    visibleTimeStart,
    visibleTimeEnd,
    fundamentalScale,
  );

  // draw regular tickmarks
  const ctx = context;
  getTimesteps(roundedStart, roundedEnd, fundamentalScale).forEach(
    (timestep) => {
      const stepPx = timestampToPixelEdges(
        timestep,
        visibleTimeStart,
        visibleTimeEnd,
        canvasWidth,
      );
      // Move 1 pixel line by 0.5 pixels to prevent it from being shown as blurry: https://usefulangle.com/post/17/html5-canvas-drawing-1px-crisp-straight-lines
      drawStepPxLine(
        ctx,
        theme,
        Math.floor(stepPx) + 0.5,
        height,
        fundamentalScale,
        timestep,
      );

      // Draw the time step time as text
      const timeFormat = getTimeFormat(fundamentalScale);

      const timeText = dateUtils.dateToString(
        dateUtils.fromUnix(timestep),
        timeFormat,
      );

      if (
        ([
          Scale.Minutes5,
          Scale.Hour,
          Scale.Hours3,
          Scale.Hours6,
          Scale.Day,
        ].includes(fundamentalScale) &&
          // eslint-disable-next-line @typescript-eslint/no-confusing-non-null-assertion
          timestep % scaleToUnitSeconds[fundamentalScale]! === 0) ||
        (fundamentalScale === Scale.Week &&
          dateUtils.getDay(dateUtils.fromUnix(timestep)) === 1)
      ) {
        drawTimeText(ctx, theme, stepPx, height, timeText!, fundamentalScale);
      }

      if (
        fundamentalScale === Scale.Year &&
        dateUtils.getMonth(dateUtils.fromUnix(timestep)) === 0
      ) {
        drawYearScaleText(ctx, theme, timeText!, stepPx, height);
      }
    },
  );

  // draw month overlay lines
  if (
    fundamentalScale === Scale.Week ||
    fundamentalScale === Scale.Weeks2 ||
    fundamentalScale === Scale.Month ||
    fundamentalScale === Scale.Months3
  ) {
    const [roundedStart, roundedEnd] = getCustomRoundedStartAndEnd(
      visibleTimeStart,
      visibleTimeEnd,
      Scale.Year,
    );
    const monthTimesteps = getCustomTimesteps(
      roundedStart,
      roundedEnd,
      'months',
    );
    monthTimesteps.forEach((timestep) => {
      const stepPx = timestampToPixelEdges(
        timestep,
        visibleTimeStart,
        visibleTimeEnd,
        canvasWidth,
      );
      if (
        fundamentalScale === Scale.Month ||
        fundamentalScale === Scale.Months3
      ) {
        const month = dateUtils.dateToString(
          dateUtils.fromUnix(timestep),
          'MMM',
        );

        // Move 1 pixel line by 0.5 pixels to prevent it from being shown as blurry: https://usefulangle.com/post/17/html5-canvas-drawing-1px-crisp-straight-lines
        drawDateChangeLine(ctx, theme, Math.floor(stepPx) + 0.5, height);
        drawTimeText(ctx, theme, stepPx, height, month!, fundamentalScale);
        return;
      }
      // adding 0.5 to make line not so blurred
      drawDashedMonthChangeLine(ctx, theme, Math.floor(stepPx) + 0.5, height);
    });
  }
  // draw date change texts
  if (fundamentalScale === Scale.Year) {
    return;
  }

  const dateText = getLeftSideDateText(visibleTimeStart, fundamentalScale);

  drawLeftSideDateText(ctx, theme, dateText, height);
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { DraggablePosition, Position, Size } from '../DraggableResizable/types';

export const getNewPositionSize = (
  element: Element,
  boundsNode: Element,
  isRightAligned: boolean,
  position: DraggablePosition,
  newSize?: Size,
): { position: Position; size: Size } => {
  const elementBbox = element.getBoundingClientRect();
  const parentBbox = boundsNode.getBoundingClientRect();

  const elementStyle = getComputedStyle(element);
  const initialX = parseFloat(
    isRightAligned ? elementStyle.right : elementStyle.left,
  );
  const initialY = parseFloat(elementStyle.top); // only use style.top since we don't support bottom alignment (yet?)

  const elementBboxHeight =
    newSize !== undefined && typeof newSize.height === 'number'
      ? newSize.height
      : elementBbox.height;
  const elementBboxWidth =
    newSize !== undefined && typeof newSize.width === 'number'
      ? newSize.width
      : elementBbox.width;

  const isElementLarger = elementBboxHeight >= parentBbox.height;
  const isElementWider = elementBboxWidth >= parentBbox.width;
  const newHeight = isElementLarger ? parentBbox.height : elementBboxHeight;
  const newWidth = isElementWider ? parentBbox.width : elementBboxWidth;

  let newX = position.x;
  let newY = position.y;
  // horizontal constrains
  if (isElementWider) {
    newX = isRightAligned ? initialX : -initialX;
  }

  // vertical constrains
  if (isElementLarger) {
    newY = -initialY;
  }

  // top aligned
  const newTop = newY + initialY + newHeight;
  if (newTop > parentBbox.height) {
    newY = parentBbox.height - newTop + position.y;
  }

  // right aligned
  if (isRightAligned) {
    const newLeft =
      parentBbox.x + parentBbox.width - initialX + newX - newWidth;
    if (newLeft < parentBbox.x) {
      newX = parentBbox.x - newLeft + position.x;
    }
  } else {
    // left aligned
    const newRight = newX + initialX + newWidth;
    if (newRight > parentBbox.width) {
      newX = parentBbox.width - newRight + position.x;
    }
  }

  return {
    position: { left: newX, top: newY },
    size: { width: newWidth, height: newHeight },
  };
};

export const useHandleSizeFromOutsideComponent = ({
  nodeRef,
  bounds,
  position,
  isRightAligned,
  isOpen,
  startSize,
  onChangeSize,
}: {
  nodeRef: React.RefObject<HTMLDivElement>;
  position: DraggablePosition;
  bounds: string;
  isRightAligned: boolean;
  isOpen: boolean;
  startSize: Size;
  onChangeSize: (size: Size) => void;
}): void => {
  const updateDomDirectly = React.useCallback(
    (position: Position, size: Size): void => {
      if (nodeRef.current) {
        const element = nodeRef.current as HTMLDivElement;
        const firstChild = element.firstChild as HTMLDivElement;
        element.style.transform = `translate(${position.left}px, ${position.top}px)`;
        firstChild.style.width = `${size.width}px`;
        firstChild.style.height = `${size.height}px`;
      }
    },
    [nodeRef],
  );

  const getBoundsNode = React.useCallback((): HTMLDivElement => {
    return (
      bounds === 'parent'
        ? nodeRef.current?.parentNode
        : document.querySelector(bounds as keyof HTMLElementTagNameMap)
    ) as HTMLDivElement;
  }, [bounds, nodeRef]);

  // store the position as ref so we can access it in another ref
  const refPosition = React.useRef(position);
  refPosition.current = position;
  const lastSize = React.useRef(startSize);

  // handle size from outside component
  React.useEffect(() => {
    const boundsNode = getBoundsNode();
    if (
      boundsNode &&
      lastSize.current.width &&
      lastSize.current.height &&
      (lastSize.current.width !== startSize.width ||
        lastSize.current.height !== startSize.height) &&
      typeof startSize.height === 'number' &&
      typeof startSize.width === 'number' &&
      nodeRef.current
    ) {
      const { position: newPosition, size: newSize } = getNewPositionSize(
        nodeRef.current!,
        boundsNode,
        isRightAligned,
        refPosition.current,
        startSize,
      );

      if (
        lastSize.current.width !== newSize.width ||
        lastSize.current.height !== newSize.height
      ) {
        updateDomDirectly(newPosition, newSize);
        onChangeSize(newSize);
        lastSize.current = newSize;
      }
    }
  }, [
    startSize,
    isRightAligned,
    lastSize,
    getBoundsNode,
    nodeRef,
    updateDomDirectly,
    onChangeSize,
  ]);

  // observe parent div dimensions
  const resizeObserverRef = React.useRef(
    new ResizeObserver((entry) => {
      const boundsTarget = entry[0].target;
      const element = nodeRef.current as Element;
      const boundsBbox = boundsTarget.getBoundingClientRect();

      if (
        (boundsBbox.height === lastSize.current.height &&
          boundsBbox.width === lastSize.current.width) ||
        !boundsBbox.height ||
        !element ||
        !boundsBbox
      ) {
        return;
      }

      const { position: newPosition, size: newSize } = getNewPositionSize(
        element,
        boundsTarget,
        isRightAligned,
        refPosition.current,
      );

      updateDomDirectly(newPosition, newSize);
    }),
  );

  React.useLayoutEffect(() => {
    const boundsNode = getBoundsNode();

    if (!boundsNode || !isOpen) {
      return (): void => {};
    }
    // store current position of the boundsNode
    const { height, width } = boundsNode.getBoundingClientRect();
    lastSize.current = { width, height };

    const observer = resizeObserverRef.current;
    observer.observe(boundsNode);

    return (): void => {
      observer.disconnect();
    };
  }, [isOpen, getBoundsNode]);
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomIconButton, renderCounter } from '@opengeoweb/shared';
import { StepBackward, StepForward } from '@opengeoweb/theme';
import { useTimesliderTranslation } from '../../../utils/i18n';

export interface BackwardForwardStepButtonProps {
  isForwardStep?: boolean;
  isDisabled?: boolean;
  onClickBFButton?: () => void;
}

export const BackwardForwardStepButton: React.FC<
  BackwardForwardStepButtonProps
> = ({
  isForwardStep,
  isDisabled,
  onClickBFButton = (): void => {},
}: BackwardForwardStepButtonProps) => {
  // Added renderCounter for testing and performance purposes
  renderCounter.count(BackwardForwardStepButton.name);
  const { t } = useTimesliderTranslation();
  return (
    <CustomIconButton
      variant="tool"
      data-testid={isForwardStep ? 'stepForwardButton' : 'stepBackwardButton'}
      disabled={isDisabled}
      aria-label={isForwardStep ? 'forward' : 'backward'}
      onClick={onClickBFButton}
      tooltipTitle={
        isForwardStep ? t('timeslider-forward') : t('timeslider-backward')
      }
    >
      {isForwardStep ? (
        <StepForward data-testid="step-forward-svg-path" />
      ) : (
        <StepBackward data-testid="step-backward-svg-path" />
      )}
    </CustomIconButton>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Grid2 as Grid, Switch } from '@mui/material';
import { SearchBar } from './SearchBar';
import { useTimeseriesTranslation } from '../../../utils/i18n';

interface LocationDropdownHeaderProps {
  searchTerm: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const LocationDropdownHeader: React.FC<LocationDropdownHeaderProps> = ({
  searchTerm,
  handleSearch,
}) => {
  const { t } = useTimeseriesTranslation();
  const selectAll = t('timeseries-locataion-dropdown-select-all');
  const disableSelectAllUntillImplementation = true;

  return (
    <Grid
      container
      sx={{
        padding: '7px',
        backgroundColor: 'geowebColors.background.surface',
        width: '100%',
      }}
      spacing={1}
    >
      <Grid container sx={{ width: '100%' }}>
        <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
      </Grid>

      {/* This feature is disabled untill the implementation continues */}
      {!disableSelectAllUntillImplementation && (
        <Grid container>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            size={{ xs: 6 }}
            sx={{ paddingLeft: '20px' }}
          >
            {selectAll}
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            size={{ xs: 6 }}
            sx={{ paddingRight: '20px' }}
          >
            <Switch />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { ElementType, FC } from 'react';
import { MenuItem } from '@mui/material';
import { TooltipSelect } from '@opengeoweb/shared';
import { layerTypes, mapTypes } from '@opengeoweb/store';
import { DimensionValueMappings } from '../../../LayerManagerUtils';
import { useCoreTranslation } from '../../../../../utils/i18n';

const DimensionSelectValue: FC<{
  selectedDimension?: mapTypes.Dimension;
  icon?: ElementType;
  tooltip: string;
  isEnabled: boolean;
  onLayerChangeDimension: (
    dimensionName: string,
    dimensionValue: string,
    origin?: layerTypes.LayerActionOrigin.layerManager,
  ) => void;
  valueMappings?: DimensionValueMappings;
  hideLatestDuplicate?: boolean;
  useLatestReferenceTime?: boolean;
  skipLocalStyling?: boolean;
  setUseLatestReferenceTime?: (useLatestReferenceTime: boolean) => void;
}> = ({
  isEnabled,
  tooltip,
  selectedDimension,
  icon,
  onLayerChangeDimension,
  valueMappings,
  hideLatestDuplicate,
  setUseLatestReferenceTime,
  useLatestReferenceTime,
  skipLocalStyling,
}) => {
  const { t } = useCoreTranslation();
  const dimensionValuesRaw = selectedDimension?.values
    ?.split(',')
    .sort()
    .reverse()
    .slice(0, 100);

  if (!selectedDimension?.name || !dimensionValuesRaw?.length) {
    return null;
  }

  const isReferenceTimeDimensionAndUseLatestIsAvailable =
    selectedDimension.name === 'reference_time' &&
    useLatestReferenceTime !== undefined;

  const latestValue = dimensionValuesRaw[0];

  const setDimensionValue = (dimensionValue: string): void => {
    if (isReferenceTimeDimensionAndUseLatestIsAvailable) {
      if (dimensionValue === 'Latest') {
        setUseLatestReferenceTime?.(true);
        onLayerChangeDimension(
          selectedDimension.name!,
          dimensionValuesRaw[0],
          layerTypes.LayerActionOrigin.layerManager,
        );
        return;
      }
      setUseLatestReferenceTime?.(false);
    }
    onLayerChangeDimension(
      selectedDimension.name!,
      dimensionValue,
      layerTypes.LayerActionOrigin.layerManager,
    );
  };

  const dimensionValues = isReferenceTimeDimensionAndUseLatestIsAvailable
    ? ['Latest', ...dimensionValuesRaw.slice(hideLatestDuplicate ? 1 : 0)]
    : dimensionValuesRaw.slice().reverse();
  const isLatest =
    isReferenceTimeDimensionAndUseLatestIsAvailable &&
    useLatestReferenceTime &&
    selectedDimension.maxValue === selectedDimension.currentValue;

  return (
    <TooltipSelect
      data-testid="dimensionSelectValue"
      disableUnderline
      SelectDisplayProps={{ 'aria-label': 'dimensionSelectValue' }}
      tooltip={tooltip}
      isEnabled={isEnabled}
      value={isLatest ? 'Latest' : selectedDimension.currentValue}
      list={dimensionValues.map((dimensionValue) => ({
        value: dimensionValue,
      }))}
      currentIndex={
        isLatest
          ? 0
          : dimensionValues.findIndex((dimensionValue) => {
              return dimensionValue === selectedDimension.currentValue;
            })
      }
      onChange={(event): void => {
        event.stopPropagation();
        setDimensionValue(event.target.value as string);
      }}
      onChangeMouseWheel={(event): void => {
        setDimensionValue(event.value);
      }}
      style={{
        maxWidth: '50%',
      }}
      IconComponent={icon}
      skipLocalStyling={skipLocalStyling}
    >
      <MenuItem disabled>
        {t('layermanager-dimensions-total-values', {
          total_dimensions: dimensionValues.length,
        })}
      </MenuItem>
      {dimensionValues.map((dimensionValue) => (
        <MenuItem key={dimensionValue} value={dimensionValue}>
          {valueMappings?.[selectedDimension.name!]?.(
            dimensionValue,
            isReferenceTimeDimensionAndUseLatestIsAvailable
              ? latestValue
              : undefined,
          ) || (
            <span style={{ whiteSpace: 'nowrap' }}>
              {dimensionValue}
              {dimensionValue !== 'Latest' && <i> {selectedDimension.units}</i>}
            </span>
          )}
        </MenuItem>
      ))}
    </TooltipSelect>
  );
};

export default DimensionSelectValue;

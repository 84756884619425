/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Typography } from '@mui/material';

const segmentStyle = {
  margin: '0 2px',
  verticalAlign: 'middle',
  fontSize: 14,
  fontWeight: 500,
  letterSpacing: 0.25,
  justifyContent: 'center',
};

const styles = {
  icon: {
    ...segmentStyle,
    backgroundColor: 'geowebColors.textInputField.default.rgba',
    width: 40,
    height: 40,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 1,
  },
  connect: {
    ...segmentStyle,
    fontSize: 18,
  },
  divider: {
    ...segmentStyle,
    margin: '0 6px',
    fontSize: 18,
  },
};

interface ShortCutSegmentProps {
  type?: 'text' | 'icon' | 'divider' | 'connect';
  children?: React.ReactNode;
}
const ShortCutSegment: React.FC<ShortCutSegmentProps> = ({
  type = 'text',
  children,
}: ShortCutSegmentProps) => {
  if (type === 'divider') {
    return (
      <Typography
        sx={styles.divider}
        data-testid="segment-divider"
        component="span"
      >
        {children}
      </Typography>
    );
  }
  if (type === 'icon') {
    return (
      <Typography sx={styles.icon} data-testid="segment-icon" component="span">
        {children}
      </Typography>
    );
  }

  if (type === 'connect') {
    return (
      <Typography
        sx={styles.connect}
        data-testid="segment-connect"
        component="span"
      >
        {children}
      </Typography>
    );
  }

  return (
    <Typography sx={segmentStyle} data-testid="segment-text" component="span">
      {children}
    </Typography>
  );
};

export default ShortCutSegment;

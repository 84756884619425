/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { EntityState } from '@reduxjs/toolkit';

export interface SnackbarItem {
  message: SnackbarMessage;
  id: string;
}

export type SnackbarState = EntityState<SnackbarItem>;

export interface SnackbarModuleStore {
  snackbar?: SnackbarState;
}

export enum SnackbarMessageType {
  TRANSLATABLE_MESSAGE = 'TRANSLATABLE_MESSAGE',
  VERBATIM_MESSAGE = 'VERBATIM_MESSAGE',
}
export interface TranslatableMessage {
  type: SnackbarMessageType.TRANSLATABLE_MESSAGE;
  key: string;
  params?: Record<string, string | number> | undefined;
}

export interface VerbatimMessage {
  type: SnackbarMessageType.VERBATIM_MESSAGE;
  message: string;
}

export type SnackbarMessage = TranslatableMessage | VerbatimMessage;

// actions
export type OpenSnackbarPayload = SnackbarMessage;
export interface TriggerOpenSnackbarByListenerPayload {
  snackbarContent: SnackbarItem;
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import {
  getUserAddedServices,
  layerActions,
  layerSelectors,
  serviceActions,
  serviceSelectors,
  setUserAddedServices,
  uiActions,
  uiSelectors,
  uiTypes,
} from '@opengeoweb/store';
import { RootState } from './types';
import { layerSelectActions } from './reducer';
import { getActiveServices, getFilteredLayers } from './selectors';
import { isUserAddedService } from './utils';

export const layerSelectListener = createListenerMiddleware<RootState>();

layerSelectListener.startListening({
  actionCreator: serviceActions.mapStoreRemoveService,
  effect: async (action) => {
    const { serviceUrl } = action.payload;
    const localStorageServices = getUserAddedServices();
    delete localStorageServices[serviceUrl];

    setUserAddedServices(localStorageServices);
  },
});
layerSelectListener.startListening({
  actionCreator: layerSelectActions.layerSelectRemoveService,
  effect: async (action, listenerApi) => {
    const removedService = action.payload;
    const { serviceId, serviceUrl } = removedService;
    const services = getActiveServices(listenerApi.getState());

    const servicesEnabled = Object.values(services).find(
      (service) => service?.enabled,
    );

    if (servicesEnabled === undefined) {
      Object.entries(services).map(([entryServiceId]) => {
        return listenerApi.dispatch(
          layerSelectActions.enableActiveService({
            serviceId: entryServiceId,
          }),
        );
      });
    }

    listenerApi.dispatch(
      serviceActions.mapStoreRemoveService({
        id: serviceId,
        serviceUrl: serviceUrl!,
      }),
    );
  },
});

layerSelectListener.startListening({
  actionCreator: serviceActions.serviceSetLayers,
  effect: async (action) => {
    const { scope, name, serviceUrl, abstract } = action.payload;
    if (isUserAddedService(scope)) {
      const localStorageServices = getUserAddedServices();

      setUserAddedServices({
        ...localStorageServices,
        [serviceUrl]: {
          name,
          url: serviceUrl,
          abstract,
        },
      });
    }
  },
});

layerSelectListener.startListening({
  matcher: isAnyOf(
    uiActions.setToggleOpenDialog,
    uiActions.setActiveMapIdForDialog,
  ),
  effect: async (action, listenerApi) => {
    const { payload } = action;
    if (!payload.setOpen && payload.type === uiTypes.DialogTypes.LayerSelect) {
      listenerApi.dispatch(layerActions.hideLayerInfo());
    }
  },
});

layerSelectListener.startListening({
  matcher: isAnyOf(
    layerSelectActions.disableActiveService,
    layerSelectActions.onlyThisServiceEnabled,
    layerSelectActions.toggleFilter,
    layerSelectActions.setSearchFilter,
  ),
  effect: async (action, listenerApi) => {
    if (
      uiSelectors.getisDialogOpen(
        listenerApi.getState(),
        uiTypes.DialogTypes.LayerInfo,
      )
    ) {
      const filteredLayers = getFilteredLayers(listenerApi.getState());
      const dialogInfo = layerSelectors.getActiveLayerInfo(
        listenerApi.getState(),
      );
      const layerWithData =
        dialogInfo &&
        serviceSelectors.getLayerFromService(
          listenerApi.getState(),
          dialogInfo.serviceUrl,
          dialogInfo.layerName,
        );

      if (
        !filteredLayers
          .map((layer) => layer.title)
          .includes(layerWithData?.title || '')
      ) {
        listenerApi.dispatch(layerActions.hideLayerInfo());
      }
    }
  },
});

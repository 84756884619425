/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
// based on https://morioh.com/p/a3b56ff851cc
import * as React from 'react';
import { useIsMounted } from '../../hooks';
import {
  ConfirmationAction,
  ConfirmationDialog,
  ConfirmationOptions,
} from './ConfirmationDialog';

type ConfirmationContext = (options: ConfirmationOptions) => Promise<void>;

const ConfirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject);

export const useConfirmationDialog = (): ConfirmationContext =>
  React.useContext(ConfirmationServiceContext);

export const useDialogActions = (
  awaitingPromiseRef: React.MutableRefObject<{
    resolve: () => void;
    reject: (reason: ConfirmationAction) => void;
  }>,
  defaultOptions: ConfirmationOptions = null!,
): {
  openConfirmation: (options: ConfirmationOptions) => Promise<void>;
  confirmationState: ConfirmationOptions;
  handleSubmit: () => void;
  handleClose: (reason: ConfirmationAction) => void;
} => {
  const { isMounted } = useIsMounted();
  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationOptions | null>(defaultOptions);

  const openConfirmation = (options: ConfirmationOptions): Promise<void> => {
    setConfirmationState(options);

    return new Promise<void>((resolve, reject) => {
      // since this is a React.ref it's safe to reassign it, although eslint says no
      // eslint-disable-next-line no-param-reassign
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  // clears state with timeout delay so optional values provided by the prop 'content' of ConfirmationDialog can be read
  const clearState = (): ReturnType<typeof setTimeout> =>
    setTimeout((): void => {
      if (isMounted.current) {
        setConfirmationState(null);
      }
    }, 0);

  const handleClose = (reason: ConfirmationAction): void => {
    if (
      confirmationState &&
      confirmationState.catchOnCancel &&
      awaitingPromiseRef.current
    ) {
      awaitingPromiseRef.current.reject(reason);
    }
    clearState();
  };

  const handleSubmit = (): void => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    clearState();
  };

  return {
    openConfirmation,
    confirmationState: confirmationState!,
    handleSubmit,
    handleClose,
  };
};

interface ConfirmationServiceProviderProps {
  children: React.ReactNode;
}
export const ConfirmationServiceProvider: React.FC<
  ConfirmationServiceProviderProps
> = ({ children }: ConfirmationServiceProviderProps) => {
  const awaitingPromiseRef: React.MutableRefObject<{
    resolve: () => void;
    reject: (reason: ConfirmationAction) => void;
  }> = React.useRef<{
    resolve: () => void;
    reject: (reason: ConfirmationAction) => void;
  }>({
    resolve: (): Promise<void> => Promise.resolve(),
    reject: (): Promise<void> => Promise.reject(),
  });

  const { openConfirmation, confirmationState, handleSubmit, handleClose } =
    useDialogActions(awaitingPromiseRef);

  return (
    <ConfirmationServiceContext.Provider value={openConfirmation}>
      {children}
      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </ConfirmationServiceContext.Provider>
  );
};

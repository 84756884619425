/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { webmapUtils } from '@opengeoweb/webmap';
import * as React from 'react';

export const isDialogOpen = (mapRef: HTMLElement | null): boolean => {
  const backdrops = Array.from(document.querySelectorAll('.MuiBackdrop-root'));
  return (
    backdrops.find((backdropNode) =>
      backdropNode.parentElement?.contains(mapRef),
    ) !== undefined
  );
};

export const isMapKeyboardControlsAllowed = (
  target: Element,
  mapRef: HTMLElement | null,
): boolean => {
  if (isDialogOpen(mapRef)) {
    return false;
  }
  if (target.tagName !== 'BODY') {
    if (target.tagName === 'BUTTON') {
      // return true for element is active but not focus visible to the user
      return !target.classList.contains('Mui-focusVisible');
    }
    return false;
  }
  return true;
};

export const useKeyboardZoomAndPan = (
  mapIsActive: boolean,
  mapId: string,
  mapRef: HTMLElement | null,
): void => {
  React.useEffect(() => {
    const keysPressedDown: Record<string, boolean> = {};
    const handleKeyPress = (event: KeyboardEvent): void => {
      const target = event.target as Element;
      const map = webmapUtils.getWMJSMapById(mapId);

      const { key, type } = event;
      keysPressedDown[key] = type === 'keydown';
      if (map && mapIsActive && isMapKeyboardControlsAllowed(target, mapRef)) {
        if (keysPressedDown['+']) {
          map.zoomIn(1);
        } else if (keysPressedDown['-']) {
          map.zoomOut();
        }

        const arrowRight = keysPressedDown['ArrowRight'];
        const arrowLeft = keysPressedDown['ArrowLeft'];
        const arrowUp = keysPressedDown['ArrowUp'];
        const arrowDown = keysPressedDown['ArrowDown'];
        const control = keysPressedDown['Control'];

        if (!control && (arrowRight || arrowLeft || arrowUp || arrowDown)) {
          let x = 0;
          let y = 0;
          if (arrowRight) {
            x -= PAN_STEP_PERCENTAGE;
          }
          if (arrowLeft) {
            x += PAN_STEP_PERCENTAGE;
          }
          if (arrowUp) {
            y -= PAN_STEP_PERCENTAGE;
          }
          if (arrowDown) {
            y += PAN_STEP_PERCENTAGE;
          }
          map.mapPanPercentage(x, y);
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('keyup', handleKeyPress);
    return (): void => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [mapIsActive, mapId, mapRef]);
};

const PAN_STEP_PERCENTAGE = 0.02;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Draft } from 'immer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateToUrlPayload } from './types';

export const initialState = {};

const slice = createSlice({
  initialState,
  name: 'router',
  reducers: {
    navigateToUrl: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<unknown>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<NavigateToUrlPayload>,
    ) => {},
  },
});

export const { reducer: routerReducer, actions: routerActions } = slice;

export type RouterActions = ReturnType<typeof routerActions.navigateToUrl>;

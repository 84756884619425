/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { QueryClient } from '@tanstack/query-core';

/**
 * This is the default TanStack QueryClient used for the query functions.
 * It can be used in react by a provider to provide the query client to the hook functions.
 * This ensures that TanStack keeps the same store for both the hooks and the query functions.
 */
export const wmsQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000, // Keep a cache of 10 seconds. In combination with the GeoWeb timer, this results in updates with max 70 seconds apart
      refetchInterval: 0, // Refetch is handled by GeoWeb, depending on if GeoWeb is visible in the browser.
      networkMode: 'always',
    },
  },
});

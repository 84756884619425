/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, MenuItem, FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Polygon } from 'geojson';
import {
  isValidGeoJsonCoordinates,
  isMaximumOneDrawing,
  hasIntersectionWithFIR,
  isInteger,
  ReactHookFormSelect,
  ReactHookFormRadioGroup,
  ReactHookFormNumberField,
  ReactHookFormHiddenInput,
  isValidMax,
  isValidMin,
  isEmpty,
  hasMulitpleIntersections,
  useDraftFormHelpers,
  countIntersectionPoints,
} from '@opengeoweb/form-fields';

import { DrawMode } from '@opengeoweb/webmap-react';
import DrawTools from './DrawTools';
import {
  Direction,
  MovementUnit,
  ConfigurableFormFieldProps,
  ProductConfig,
} from '../../../types';
import { styles } from '../ProductForm.styles';
import { RadioButtonAndLabel } from './RadioButtonAndLabel';
import {
  getActiveFIRArea,
  getAllowedUnits,
  getMaxMovementSpeedValue,
  getMinMovementSpeedValue,
  isFir,
  triggerValidations,
  hasMaxFeaturePoints,
  getMaxPolygonPoints,
} from '../utils';

import ProductFormFieldLayout from './ProductFormFieldLayout';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import {
  translateKeyOutsideComponents,
  useSigmetAirmetTranslation,
} from '../../../utils/i18n';

export const DEFAULT_ROUNDING_MOVEMENT_KT = 5;
export const DEFAULT_ROUNDING_MOVEMENT_KMH = 10;

export const getMovementStepValKTMessage = (
  value = DEFAULT_ROUNDING_MOVEMENT_KT,
): string =>
  translateKeyOutsideComponents('progress-get-movement-step-valktmessage', {
    value,
  });
export const getMovementStepValKMHMessage = (
  value = DEFAULT_ROUNDING_MOVEMENT_KMH,
): string =>
  translateKeyOutsideComponents('progress-get-movement-step-valkmhmessage', {
    value,
  });

export const validateMovementSteps = (
  value: string,
  unit: string,
  productConfig: ProductConfig,
  selectedFIR: string,
): boolean | string => {
  if (isEmpty(value)) {
    return true;
  }

  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    movement_rounding_kt = DEFAULT_ROUNDING_MOVEMENT_KT,
    movement_rounding_kmh = DEFAULT_ROUNDING_MOVEMENT_KMH,
    /* eslint-enable @typescript-eslint/naming-convention */
  } = getActiveFIRArea(selectedFIR, productConfig);

  // Parse to integer to check for steps
  const intLevel = parseInt(value, 10);

  if (MovementUnit[unit as keyof typeof MovementUnit] === MovementUnit.KT) {
    return (
      intLevel % movement_rounding_kt === 0 ||
      getMovementStepValKTMessage(movement_rounding_kt)
    );
  }
  if (MovementUnit[unit as keyof typeof MovementUnit] === MovementUnit.KMH) {
    return (
      intLevel % movement_rounding_kmh === 0 ||
      getMovementStepValKMHMessage(movement_rounding_kmh)
    );
  }
  return translateKeyOutsideComponents('invalid-unit');
};

interface ProgressProps extends ConfigurableFormFieldProps {
  geoJSON?: GeoJSON.FeatureCollection;
  geoJSONIntersection?: GeoJSON.FeatureCollection;
  activeTool?: string;
  tools?: DrawMode[];
  onChangeTool?: (newToolMode: DrawMode) => void;
}

const Progress: React.FC<ProgressProps> = ({
  productType,
  productConfig,
  isDisabled = false,
  isReadOnly = false,
  onChange = (): void => {},
  tools = [],
  activeTool,
  onChangeTool = (): void => {},
  geoJSON,
  geoJSONIntersection,
}: ProgressProps) => {
  const { t } = useSigmetAirmetTranslation();
  const {
    watch,
    getValues,
    trigger,
    formState: { errors },
    unregister,
  } = useFormContext();
  const { isRequired: isRequiredField, isDraft } = useDraftFormHelpers();
  const { isShortTest } = useShortTestHelpers();
  const isRequired = (value: string): string | boolean =>
    isShortTest() || isRequiredField(value);

  const movementType = watch('movementType');
  const getFIRValue = (): string => getValues('locationIndicatorATSR');

  // Get allowed movement units based on selected FIR - if no FIR selected, allow all units
  const allowedMovementUnitsForFir = getAllowedUnits(
    getFIRValue(),
    productConfig,
    'movement_unit',
    MovementUnit,
  );

  React.useEffect(() => {
    if (movementType !== 'MOVEMENT') {
      unregister('movementDirection');
      unregister('movementSpeed');
      unregister('movementUnit');
    }

    if (movementType !== 'FORECAST_POSITION') {
      unregister('endGeometry');
      unregister('endGeometryIntersect');
    }
  }, [movementType, unregister]);

  const isSigmet = productType === 'sigmet';
  const isRadioactiveCloud = watch('phenomenon') === 'RDOACT_CLD';
  const isVolcanicCloud = watch('phenomenon') === 'VA_CLD';
  const isShortTestOrRdoact = isShortTest() || isRadioactiveCloud;
  const fieldsDisabled = isDisabled || isShortTestOrRdoact;

  // Max points the FIR allows for a polygon
  const maxPoints = getMaxPolygonPoints(getFIRValue(), productConfig);

  // Intersections with FIR
  const intersectionPointCount = countIntersectionPoints(
    geoJSON! as GeoJSON.FeatureCollection<Polygon>,
    geoJSONIntersection! as GeoJSON.FeatureCollection<Polygon>,
  );
  const drawnGeometry = geoJSON?.features[0]?.geometry as GeoJSON.Polygon;
  const interceptionGeometry = geoJSONIntersection?.features[0]
    ?.geometry as GeoJSON.Polygon;

  // Total points in the user drawn polygon
  const totalDrawnPoints =
    drawnGeometry && drawnGeometry.coordinates[0]
      ? drawnGeometry.coordinates[0].length
      : 0;

  // Total points in the intersection polygon
  const intersectingPolygonPointsTotal =
    interceptionGeometry && interceptionGeometry.coordinates[0]
      ? interceptionGeometry.coordinates[0].length
      : 0;

  const showMaxFeaturePointsWarning =
    intersectionPointCount > 7 &&
    hasMaxFeaturePoints(geoJSONIntersection!, maxPoints) &&
    !activeTool &&
    !errors.startGeometry &&
    !isFir(geoJSON!);

  const showMoreThan6PointsWarning =
    totalDrawnPoints > 7 &&
    intersectionPointCount < 1 &&
    !activeTool &&
    !errors.startGeometry &&
    !isFir(geoJSON!);

  const showIntersectionAbove6PointsWarning =
    intersectingPolygonPointsTotal > 7 &&
    intersectionPointCount > 1 &&
    maxPoints > 7 &&
    !activeTool &&
    !errors.startGeometry &&
    !isFir(geoJSON!);

  return (
    <ProductFormFieldLayout title={t('progress')} sx={styles.containerItem}>
      <ReactHookFormRadioGroup
        name="movementType"
        data-testid="movementType-group"
        rules={{ validate: { isRequired } }}
        isReadOnly={isReadOnly}
        onChange={(): void => {
          const deleteTool = tools?.find((tool) => tool.value === 'DELETE');
          if (deleteTool) {
            onChangeTool(deleteTool);
          }
        }}
      >
        {isVolcanicCloud && (
          <RadioButtonAndLabel
            value="NO_VA_EXP"
            label={t('progress-radio-button-label-no-va-exp')}
            disabled={fieldsDisabled}
            data-testid="movementType-NO_VA_EXP"
          />
        )}
        <>
          <RadioButtonAndLabel
            value="STATIONARY"
            label={t('progress-radio-button-label-stationary')}
            disabled={fieldsDisabled}
            data-testid="movementType-STATIONARY"
          />
          <RadioButtonAndLabel
            value="MOVEMENT"
            label={t('progress-radio-button-label-movement')}
            disabled={fieldsDisabled}
            data-testid="movementType-MOVEMENT"
          />
          {isSigmet && isShortTestOrRdoact && (
            <RadioButtonAndLabel
              value="FORECAST_POSITION"
              label={t('progress-radio-button-label-end-potision')}
              disabled={fieldsDisabled}
              data-testid="movementType-FORECAST_POSITION"
            />
          )}
        </>
        {movementType === 'MOVEMENT' && !isShortTestOrRdoact && (
          <>
            <Grid>
              <ReactHookFormSelect
                name="movementDirection"
                label={t('progress-movement-direction-label')}
                rules={{ validate: { isRequired } }}
                size="small"
                sx={styles.movement}
                disabled={isDisabled}
                isReadOnly={isReadOnly}
                data-testid="movement-movementDirection"
                onChange={(event): void => {
                  event.stopPropagation();
                  onChange();
                }}
                autoFocus
              >
                {Object.keys(Direction).map((key) => (
                  <MenuItem value={key} key={key}>
                    {Direction[key as keyof typeof Direction]}
                  </MenuItem>
                ))}
              </ReactHookFormSelect>
            </Grid>
            <Grid container>
              <Grid size={5}>
                <ReactHookFormSelect
                  name="movementUnit"
                  label={t('progress-movement-unit-label')}
                  rules={{ validate: { isRequired } }}
                  size="small"
                  sx={styles.unit}
                  disabled={isDisabled}
                  isReadOnly={isReadOnly}
                  defaultValue={'KT' as MovementUnit}
                  data-testid="movement-movementUnit"
                  onChange={(event): void => {
                    event.stopPropagation();
                    triggerValidations(['movementSpeed'], getValues, trigger);
                    onChange();
                  }}
                >
                  {Object.keys(allowedMovementUnitsForFir).map((key) => (
                    <MenuItem value={key} key={key}>
                      {MovementUnit[key as keyof typeof MovementUnit]}
                    </MenuItem>
                  ))}
                </ReactHookFormSelect>
              </Grid>
              <Grid size={7}>
                <ReactHookFormNumberField
                  name="movementSpeed"
                  label={t('progress-movement-speed-label')}
                  rules={{
                    validate: {
                      isRequired,
                      isInteger,
                      min: (value): boolean | string =>
                        // The max level depends on the unit
                        isValidMin(
                          value,
                          getMinMovementSpeedValue(
                            getValues('movementUnit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        ) ||
                        t('progress-the-minimum-level-in-movement', {
                          movementUnitType:
                            MovementUnit[
                              getValues(
                                'movementUnit',
                              ) as keyof typeof MovementUnit
                            ],
                          movementUnit: getMinMovementSpeedValue(
                            getValues('movementUnit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        }),

                      max: (value): boolean | string =>
                        // The max level depends on the unit
                        isValidMax(
                          value,
                          getMaxMovementSpeedValue(
                            getValues('movementUnit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        ) ||
                        t('progress-the-maxium-level-in-movement', {
                          movementUnitType:
                            MovementUnit[
                              getValues(
                                'movementUnit',
                              ) as keyof typeof MovementUnit
                            ],
                          movementUnit: getMaxMovementSpeedValue(
                            getValues('movementUnit'),
                            getFIRValue(),
                            productConfig,
                          ),
                        }),

                      validateMovementSteps: (value): boolean | string =>
                        // movement step depends on the unit
                        validateMovementSteps(
                          value,
                          getValues('movementUnit'),
                          productConfig,
                          getFIRValue(),
                        ),
                    },
                  }}
                  size="small"
                  disabled={isDisabled}
                  isReadOnly={isReadOnly}
                  data-testid="movement-movementSpeed"
                  onChange={(event): void => event && event.stopPropagation()}
                />
              </Grid>
            </Grid>
          </>
        )}
        {isSigmet && !isShortTestOrRdoact && (
          <RadioButtonAndLabel
            value="FORECAST_POSITION"
            label={t('progress-radio-button-label-end-potision')}
            disabled={isDisabled}
            data-testid="movementType-FORECAST_POSITION"
          />
        )}
      </ReactHookFormRadioGroup>
      {isSigmet &&
        !isShortTestOrRdoact &&
        movementType === 'FORECAST_POSITION' && (
          <Grid
            data-testid="endGeometry"
            sx={styles.drawSectionProgress}
            size={12}
          >
            {!isReadOnly && (
              <>
                <DrawTools
                  data-testid="endGeometry-drawTools"
                  tools={tools}
                  activeTool={activeTool!}
                  onChangeTool={onChangeTool}
                />
                {activeTool && (
                  <FormHelperText
                    variant="filled"
                    sx={styles.quitDrawModeMessage}
                  >
                    {t('geometry-exit-draw-mode-message')}
                  </FormHelperText>
                )}
                {!!errors.endGeometry && (
                  <FormHelperText error variant="filled">
                    {errors.endGeometry.message as string}
                  </FormHelperText>
                )}
                {
                  /* non-blocking warnings */
                  showMaxFeaturePointsWarning && (
                    <FormHelperText variant="filled">
                      {t('geometry-max-feature-points-message')}
                    </FormHelperText>
                  )
                }
                {
                  /* non-blocking warnings */
                  showMoreThan6PointsWarning && (
                    <FormHelperText variant="filled">
                      {t('progress-more-than-6-points-message', {
                        totalDrawnPoints,
                      })}
                    </FormHelperText>
                  )
                }
                {
                  /* non-blocking warnings */
                  showIntersectionAbove6PointsWarning && (
                    <FormHelperText variant="filled">
                      {t('progress-intersection-above-6-points-message', {
                        intersectingPolygonPointsTotal,
                      })}
                    </FormHelperText>
                  )
                }
                {hasMulitpleIntersections(geoJSONIntersection!) &&
                  !activeTool &&
                  !errors.endGeometry &&
                  !isFir(geoJSON!) && (
                    <FormHelperText variant="filled">
                      {t('geometry-multi-intersections-message')}
                    </FormHelperText>
                  )}
              </>
            )}
            <ReactHookFormHiddenInput
              name="endGeometry"
              rules={{
                validate: {
                  maximumOneDrawing: (
                    value: GeoJSON.FeatureCollection,
                  ): boolean | string =>
                    isMaximumOneDrawing(value) ||
                    t('progress-only-one-position-drawing'),
                  coordinatesNotEmpty: (
                    value: GeoJSON.FeatureCollection,
                  ): boolean | string =>
                    getValues('movementType') === 'FORECAST_POSITION' &&
                    !isDraft()
                      ? isValidGeoJsonCoordinates(value) ||
                        t('progress-coordinate-empty-message')
                      : true,
                  intersectWithFIR: (
                    value: GeoJSON.FeatureCollection,
                  ): boolean | string =>
                    hasIntersectionWithFIR(
                      value,
                      getValues('endGeometryIntersect'),
                    ) || t('progress-no-intersection-message'),
                  hasMaxFeaturePoints: (
                    value: GeoJSON.FeatureCollection<Polygon>,
                  ): boolean | string =>
                    isFir(value)
                      ? true
                      : !hasMaxFeaturePoints(value, maxPoints) ||
                        t('progress-maximumPointsMessage', {
                          maxPoints: maxPoints - 1,
                        }),
                },
              }}
            />
            <ReactHookFormHiddenInput name="endGeometryIntersect" />
          </Grid>
        )}
    </ProductFormFieldLayout>
  );
};

export default Progress;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { dateUtils } from '@opengeoweb/shared';
import {
  handleSetNowEvent,
  setNextTimeStep,
  setPreviousTimeStep,
} from './changeTimeFunctions';

export const useToggleTimesliderVisibility = (
  mapIsActive: boolean,
  isVisible: boolean,
  onToggleTimeSlider: (isVisible: boolean) => void,
): void => {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      const target = event.target as Element;
      if (
        target.tagName !== 'INPUT' &&
        target.tagName !== 'TEXTAREA' &&
        event.key === 't' &&
        mapIsActive
      ) {
        onToggleTimeSlider(!isVisible);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mapIsActive, isVisible, onToggleTimeSlider]);
};

export const useMoveTimeWithKeyboard = (
  mapIsActive: boolean,
  timeStep: number,
  dataStartTime: number,
  dataEndTime: number,
  onSetNewDate: (newDate: string) => void,
  onSetCenterTime: (newTime: number) => void,
  selectedTime: number,
): void => {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Home' && mapIsActive) {
        handleSetNowEvent(
          timeStep,
          dataStartTime,
          dataEndTime,
          dateUtils.unix(dateUtils.utc()),
          onSetNewDate,
          onSetCenterTime,
        );
      }
      if (event.ctrlKey && dataStartTime && dataEndTime && mapIsActive) {
        switch (event.code) {
          case 'ArrowLeft':
            setPreviousTimeStep(
              timeStep,
              selectedTime,
              dataStartTime,
              onSetNewDate,
            );
            break;
          case 'ArrowRight':
            setNextTimeStep(timeStep, selectedTime, dataEndTime, onSetNewDate);
            break;
          default:
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    dataEndTime,
    dataStartTime,
    mapIsActive,
    onSetCenterTime,
    onSetNewDate,
    selectedTime,
    timeStep,
  ]);
};

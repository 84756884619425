/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom';
import {
  getLanguageFromLocalStorage,
  RouterWrapperConnect,
} from '@opengeoweb/core';

import './app.css';

import { AlertBanner, ConfigType } from '@opengeoweb/shared';
import {
  AuthenticationProvider,
  getAuthConfig,
  AuthenticationConfig,
  Login,
  Logout,
  Code,
  RequireAuth,
} from '@opengeoweb/authentication';
import { workspaceRoutes } from '@opengeoweb/workspace';

import { ThemeWrapper } from '@opengeoweb/theme';

import { isArray } from 'lodash';
import { CreateApiProps } from '@opengeoweb/api';
import ErrorPage from './pages/Error';
import NotFound from './pages/404';
import Workspace from './pages/Workspace';
import {
  isValidConfig,
  isValidConfigWithAuthentication,
  sortErrors,
  ValidationError,
} from './utils/loadConfig';
import { store } from './store';
import { AppApi, createApi as createRealApi } from './utils/api';
import Home from './pages/Home';
import { AppWrapper } from './components/Providers';
import { useGeowebTranslation } from '../i18n';

interface RoutesProps {
  forceAuth?: boolean;
  config?: ConfigType;
  createApi?: (props: CreateApiProps) => AppApi;
}
// ---> Sentry related code
const SentryMonitorReactRoutes =
  Sentry.withSentryReactRouterV6Routing(ReactRoutes);
// Sentry related code <--

const Routes: React.FC<RoutesProps> = ({
  forceAuth = false,
  config,
  createApi = createRealApi,
}: RoutesProps) => (
  <BrowserRouter>
    <RouterWrapperConnect>
      <SentryMonitorReactRoutes>
        <Route path={workspaceRoutes.root}>
          <Route
            index
            element={
              forceAuth ? (
                <RequireAuth>
                  <Home config={config} createApi={createApi} />
                </RequireAuth>
              ) : (
                <Home config={config} createApi={createApi} />
              )
            }
          />

          <Route
            path={workspaceRoutes.workspace}
            element={<Workspace config={config} createApi={createApi} />}
          />

          <Route
            path={workspaceRoutes.workspaceDetail}
            element={<Workspace config={config} createApi={createApi} />}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/code" element={<Code />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
      </SentryMonitorReactRoutes>
    </RouterWrapperConnect>
  </BrowserRouter>
);

interface AppProps {
  createApi?: (props: CreateApiProps) => AppApi;
  configObject?: ConfigType;
}

const useHandleInitialLanguage = ({
  configLanguage,
}: {
  configLanguage: string | undefined;
}): void => {
  const { i18n } = useGeowebTranslation();
  const isLanguageIntialised = React.useRef(false);

  if (!isLanguageIntialised.current) {
    const currentLanguage = i18n.language;
    const localStorageLanguage = getLanguageFromLocalStorage();

    // give priority to load language over local storage than config
    const newLanguage = localStorageLanguage || configLanguage;
    if (newLanguage && newLanguage !== currentLanguage) {
      i18n.changeLanguage(newLanguage).catch(() => {
        console.warn('Could not change language to:', newLanguage);
      });
    }
    isLanguageIntialised.current = true;
  }
};

const App: React.FC<AppProps> = ({
  createApi = createRealApi,
  configObject,
}: AppProps) => {
  const { t } = useGeowebTranslation();
  useHandleInitialLanguage({ configLanguage: configObject?.GW_LANGUAGE });

  // if config is null, it has not yet loaded.
  if (!configObject) {
    return null;
  }

  // validates config.json. If there are any empty or non existing keys, it will show an error
  const validateRequiredFields = isValidConfig(t, configObject);
  if (validateRequiredFields !== true && isArray(validateRequiredFields)) {
    const errors = sortErrors(validateRequiredFields);

    return (
      <ThemeWrapper>
        <AlertBanner
          severity="error"
          title={t('geoweb-alert-banner-config-not-valid')}
          info={
            <>
              {Object.keys(errors).map((key) => (
                <p key={key}>
                  <strong>{key}: </strong>
                  {errors[key as keyof typeof errors]!.map(
                    (error: ValidationError) => error.key,
                  ).join(', ')}
                </p>
              ))}
            </>
          }
        />
      </ThemeWrapper>
    );
  }

  // validates config.json for valid auth keys. If it's not valid, it will show the default application without requiring authentication
  if (isValidConfigWithAuthentication(configObject) !== true) {
    return (
      <AppWrapper
        store={store}
        defaultThemeName={configObject?.GW_DEFAULT_THEME}
      >
        <Routes config={configObject} createApi={createApi} />
      </AppWrapper>
    );
  }
  // renders the validated homepage
  return (
    <AuthenticationProvider
      configURLS={getAuthConfig(configObject as AuthenticationConfig)}
    >
      <AppWrapper
        store={store}
        defaultThemeName={configObject?.GW_DEFAULT_THEME}
      >
        <Routes
          config={configObject}
          forceAuth={configObject?.GW_FEATURE_FORCE_AUTHENTICATION}
          createApi={createApi}
        />
      </AppWrapper>
    </AuthenticationProvider>
  );
};

export default Sentry.withProfiler(App);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* Date object, eg ISO8601 `2020-09-18T12:00:00Z` */
export type DateType = string;

// International Civil Aviation Organization (ICAO) locations
export type ICAOLocation = string; // For example 'AMSTERDAM FIR';

// Meteorological Watch Office (MWO) locations
export type MWOLocation = string; // For example 'EHDB'; Use getMWO() from utils to get the current MWO

/* Air Traffic Services Unit locations */
export type ATSULocation = string; // For example 'EHAA';

/*  Air Traffic Services Region locations */
export type ATSRLocation = string; // For example 'EHAA';

/**
 * ProductStatus is returned by the backend. The backend fills in  this property.
 * For the backend, it should only need to store 'DRAFT' | 'PUBLISHED' | 'CANCELLED' in its DB.
 * The backend should derive and return the status 'EXPIRED' from the product properties.
 *
 * Note: ProductStatus from the backend should be refreshed regularly to display status updates. */
export enum ProductStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  RENEWED = 'RENEWED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  DISCARDED = 'DISCARDED',
}

/**
 * ProductSequence is a sequence label with a counter of the SIGMETs/AIRMETs issued on a day (starting at with 1 01:00),
 * max 3 characters long; first character should be a capital letter,
 * others are numbers: A01-A99, 01-99 or 1-99 */
export type ProductSequenceId = string;

/**
 *  ProductCanbe lifecycle is determined by the backend. E.g. the backend fills in these properties:
 * 'DRAFTED': product can be saved as draft
 * 'DISCARDED': This product can be thrown away
 * 'PUBLISHED': product can be PUBLISHED
 * 'CANCELLED': product can be CANCELLED */
export type ProductCanbe =
  | 'DRAFTED'
  | 'DISCARDED'
  | 'PUBLISHED'
  | 'RENEWED'
  | 'CANCELLED';

export type ObservationOrForcast = 'OBS' | 'FCST';

export enum LevelUnits {
  'FT' = 'ft',
  'M' = 'm',
  'FL' = 'FL',
}

export enum CloudLevelUnits {
  'FT' = 'ft',
  'M' = 'm',
}

/** Change type of the Product, can be:
 * INTSF:Intensifying
 * WKN: Weakening
 * NC: No change */
export type Change = 'INTSF' | 'WKN' | 'NC';

export type LevelInfoMode =
  | 'AT'
  | 'BETW'
  | 'BETW_SFC'
  | 'TOPS'
  | 'TOPS_ABV'
  | 'TOPS_BLW'
  | 'ABV';

/**
 * The possible phenomena for the sigmet
 */
export enum SigmetPhenomena {
  'EMBD_TS' = 'Embedded thunderstorm(s)',
  'EMBD_TSGR' = 'Embedded thunderstorm(s) with hail',
  'FRQ_TS' = 'Frequent thunderstorm(s)',
  'FRQ_TSGR' = 'Frequent thunderstorm(s) with hail',
  'HVY_DS' = 'Heavy duststorm',
  'HVY_SS' = 'Heavy sandstorm',
  'OBSC_TS' = 'Obscured thunderstorm(s)',
  'OBSC_TSGR' = 'Obscured thunderstorm(s) with hail',
  'RDOACT_CLD' = 'Radioactive cloud',
  'SEV_ICE' = 'Severe icing',
  'SEV_ICE_FRZ_RN' = 'Severe icing due to freezing rain',
  'SEV_MTW' = 'Severe mountain wave',
  'SEV_TURB' = 'Severe turbulence',
  'SQL_TS' = 'Squall line thunderstorm(s)',
  'SQL_TSGR' = 'Squall line thunderstorm(s) with hail',
  // 'TC' = 'Tropical cyclone',     // Don't show this option in the list because it will be not used by KNMI.
  'VA_CLD' = 'Volcanic ash cloud',
}

/**
 * The possible phenomena for the airmet
 */
export enum AirmetPhenomena {
  'SFC_VIS' = 'Surface visibility',
  'SFC_WIND' = 'Surface wind',
  'OVC_CLD' = 'Overcast cloud',
  'BKN_CLD' = 'Broken cloud',
  'ISOL_CB' = 'Isolated cumulonimbus cloud',
  'OCNL_CB' = 'Occasional cumulonimbus cloud',
  'FRQ_CB' = 'Frequent cumulonimbus cloud',
  'ISOL_TS' = 'Isolated thunderstorm(s)',
  'OCNL_TS' = 'Occasional thunderstorm(s)',
  'ISOL_TSGR' = 'Isolated thunderstorm(s) with hail',
  'OCNL_TSGR' = 'Occasional thunderstorm(s) with hail',
  'ISOL_TCU' = 'Isolated towering cumulus cloud',
  'OCNL_TCU' = 'Occasional towering cumulus cloud',
  'FRQ_TCU' = 'Frequent towering cumulus cloud',
  'MOD_ICE' = 'Moderate icing',
  'MOD_MTW' = 'Moderate mountain wave',
  'MOD_TURB' = 'Moderate turbulence',
  'MT_OBSC' = 'Mountains obscured',
}

/* STATIONARY: not moving
 * MOVEMENT: moving, specified by speed/direction, (If not STATIONARY and no end geometry is given)
 * FORECAST_POSITION: moving, specified by end geometry
 * NO_VA_EXP: no volcanic ash expected, option only available for phenomenon  VA_CLD */
export type SigmetMovementType =
  | 'STATIONARY'
  | 'MOVEMENT'
  | 'FORECAST_POSITION'
  | 'NO_VA_EXP';

/* STATIONARY: not moving
 * MOVEMENT: moving, specified by speed/direction */
export type AirmetMovementType = 'STATIONARY' | 'MOVEMENT';

/* Speed unit, e.g. 'KT', 'KMH' */
export enum MovementUnit {
  'KT' = 'kt',
  'KMH' = 'kmh',
}
// can these be unified as SpeedUnit?
export enum WindUnit {
  'KT' = 'kt',
  'MPS' = 'mps',
}

export enum Direction {
  'N' = 'N',
  'NNE' = 'NNE',
  'NE' = 'NE',
  'ENE' = 'ENE',
  'E' = 'E',
  'ESE' = 'ESE',
  'SE' = 'SE',
  'SSE' = 'SSE',
  'S' = 'S',
  'SSW' = 'SSW',
  'SW' = 'SW',
  'WSW' = 'WSW',
  'W' = 'W',
  'WNW' = 'WNW',
  'NW' = 'NW',
  'NNW' = 'NNW',
}

export type VisibilityUnit = 'm';

export enum VisibilityCause {
  'DZ' = 'Drizzle',
  'DU' = 'Dust',
  'PO' = 'Dust or sand devil',
  'DS' = 'Dust storm',
  'FG' = 'Fog',
  'FC' = 'Funnel clouds',
  'GR' = 'Hail',
  'HZ' = 'Haze',
  'PL' = 'Ice pellets',
  'BR' = 'Mist',
  'RA' = 'Rain',
  'SA' = 'Sand',
  'SS' = 'Sand storm',
  'FU' = 'Smoke',
  'SN' = 'Snow',
  'SG' = 'Snow grains',
  'GS' = 'Soft hail',
  'SQ' = 'Squalls',
  'VA' = 'Volcanic Ash',
}

export type CloudLevelInfoMode =
  | 'BETW'
  | 'BETW_SFC'
  | 'BETW_SFC_ABV'
  | 'BETW_ABV';

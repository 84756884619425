/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const radarGetCapabilities = {
  WMS_Capabilities: {
    attr: {
      xmlns: 'http://www.opengis.net/wms',
      'xmlns:xlink': 'http://www.w3.org/1999/xlink',
      'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      version: '1.3.0',
      updateSequence: '0',
      'xsi:schemaLocation':
        'http://www.opengis.net/wms http://schemas.opengis.net/wms/1.3.0/capabilities_1_3_0.xsd',
    },
    Service: {
      attr: {},
      Name: { attr: {}, value: 'WMS' },
      Title: { attr: {}, value: 'RADAR' },
      Abstract: {
        attr: {},
        value:
          'This service demonstrates how the ADAGUC server can be used to create OGC services.',
      },
      KeywordList: {
        attr: {},
        Keyword: [
          { attr: {}, value: 'view' },
          { attr: {}, value: 'infoMapAccessService' },
          {
            attr: {},
            value: 'ADAGUCServer version 2.5.15, of Oct 14 2021 16:05:05',
          },
        ],
      },
      OnlineResource: {
        attr: {
          'xlink:type': 'simple',
          'xlink:href':
            'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&',
        },
      },
      ContactInformation: { attr: {} },
      Fees: { attr: {}, value: 'no conditions apply' },
      AccessConstraints: { attr: {}, value: 'None' },
      MaxWidth: { attr: {}, value: '8192' },
      MaxHeight: { attr: {}, value: '8192' },
    },
    Capability: {
      attr: {},
      Request: {
        attr: {},
        GetCapabilities: {
          attr: {},
          Format: { attr: {}, value: 'text/xml' },
          DCPType: {
            attr: {},
            HTTP: {
              attr: {},
              Get: {
                attr: {},
                OnlineResource: {
                  attr: {
                    'xlink:type': 'simple',
                    'xlink:href':
                      'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&',
                  },
                },
              },
            },
          },
        },
        GetMap: {
          attr: {},
          Format: [
            { attr: {}, value: 'image/png' },
            { attr: {}, value: 'image/png;mode=8bit' },
            { attr: {}, value: 'image/png;mode=8bit_noalpha' },
            { attr: {}, value: 'image/png;mode=24bit' },
            { attr: {}, value: 'image/png;mode=32bit' },
            { attr: {}, value: 'image/gif' },
            { attr: {}, value: 'image/jpeg' },
          ],
          DCPType: {
            attr: {},
            HTTP: {
              attr: {},
              Get: {
                attr: {},
                OnlineResource: {
                  attr: {
                    'xlink:type': 'simple',
                    'xlink:href':
                      'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&',
                  },
                },
              },
            },
          },
        },
        GetFeatureInfo: {
          attr: {},
          Format: [
            { attr: {}, value: 'image/png' },
            { attr: {}, value: 'text/plain' },
            { attr: {}, value: 'text/html' },
            { attr: {}, value: 'text/xml' },
            { attr: {}, value: 'application/json' },
          ],
          DCPType: {
            attr: {},
            HTTP: {
              attr: {},
              Get: {
                attr: {},
                OnlineResource: {
                  attr: {
                    'xlink:type': 'simple',
                    'xlink:href':
                      'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&',
                  },
                },
              },
            },
          },
        },
      },
      Exception: {
        attr: {},
        Format: [
          { attr: {}, value: 'XML' },
          { attr: {}, value: 'INIMAGE' },
          { attr: {}, value: 'BLANK' },
        ],
      },
      UserDefinedSymbolization: { attr: { SupportSLD: '1' } },
      Layer: {
        attr: {},
        Title: { attr: {}, value: 'WMS of  RADAR' },
        CRS: [
          { attr: {}, value: 'EPSG:3411' },
          { attr: {}, value: 'EPSG:3412' },
          { attr: {}, value: 'EPSG:3575' },
          { attr: {}, value: 'EPSG:3857' },
          { attr: {}, value: 'EPSG:4258' },
          { attr: {}, value: 'EPSG:4326' },
          { attr: {}, value: 'CRS:84' },
          { attr: {}, value: 'EPSG:25831' },
          { attr: {}, value: 'EPSG:25832' },
          { attr: {}, value: 'EPSG:28992' },
          { attr: {}, value: 'EPSG:7399' },
          { attr: {}, value: 'EPSG:50001' },
          { attr: {}, value: 'EPSG:54030' },
          { attr: {}, value: 'EPSG:32661' },
          { attr: {}, value: 'EPSG:40000' },
          { attr: {}, value: 'EPSG:900913' },
          {
            attr: {},
            value:
              'PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0',
          },
        ],
        BoundingBox: [
          {
            attr: {
              CRS: 'EPSG:3411',
              minx: '2682754.743620',
              miny: '-3245034.014141',
              maxx: '3759536.917562',
              maxy: '-2168251.989038',
            },
          },
          {
            attr: {
              CRS: 'EPSG:3412',
              minx: '0.000000',
              miny: '32318824.826266',
              maxx: '7413041.166015',
              maxy: '40075258.815074',
            },
          },
          {
            attr: {
              CRS: 'EPSG:3575',
              minx: '-770622.801471',
              miny: '-4485814.811314',
              maxx: '56845.766135',
              maxy: '-3684039.443620',
            },
          },
          {
            attr: {
              CRS: 'EPSG:3857',
              minx: '0.000000',
              miny: '6257115.219364',
              maxx: '1208534.698398',
              maxy: '7553161.958695',
            },
          },
          {
            attr: {
              CRS: 'EPSG:4258',
              minx: '48.895303',
              miny: '0.000000',
              maxx: '55.973600',
              maxy: '10.856452',
            },
          },
          {
            attr: {
              CRS: 'EPSG:4326',
              minx: '48.895303',
              miny: '0.000000',
              maxx: '55.973600',
              maxy: '10.856452',
            },
          },
          {
            attr: {
              CRS: 'CRS:84',
              minx: '48.895303',
              miny: '0.000000',
              maxx: '55.973600',
              maxy: '10.856452',
            },
          },
          {
            attr: {
              CRS: 'EPSG:25831',
              minx: '282182.345905',
              miny: '5433247.394267',
              maxx: '997135.658653',
              maxy: '6207204.592736',
            },
          },
          {
            attr: {
              CRS: 'EPSG:25832',
              minx: '-153083.019482',
              miny: '5415817.312927',
              maxx: '617595.626092',
              maxy: '6239769.309937',
            },
          },
          {
            attr: {
              CRS: 'EPSG:28992',
              minx: '-236275.338083',
              miny: '106727.731651',
              maxx: '501527.918656',
              maxy: '900797.079725',
            },
          },
          {
            attr: {
              CRS: 'EPSG:7399',
              minx: '0.000000',
              miny: '5757301.056717',
              maxx: '763611.971696',
              maxy: '6483919.801602',
            },
          },
          {
            attr: {
              CRS: 'EPSG:50001',
              minx: '-2000000.000000',
              miny: '-2000000.000000',
              maxx: '10000000.000000',
              maxy: '8500000.000000',
            },
          },
          {
            attr: {
              CRS: 'EPSG:54030',
              minx: '0.000000',
              miny: '5211855.054125',
              maxx: '853649.695106',
              maxy: '5936394.291427',
            },
          },
          {
            attr: {
              CRS: 'EPSG:32661',
              minx: '2000000.000000',
              miny: '-2703305.597319',
              maxx: '2745713.040381',
              maxy: '-1888346.216710',
            },
          },
          {
            attr: {
              CRS: 'EPSG:40000',
              minx: '0.000000',
              miny: '-4731695.771951',
              maxx: '750214.326339',
              maxy: '-3911817.119426',
            },
          },
          {
            attr: {
              CRS: 'EPSG:900913',
              minx: '0.000000',
              miny: '6257115.219364',
              maxx: '1208534.698398',
              maxy: '7553161.958695',
            },
          },
          {
            attr: {
              CRS: 'PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0',
              minx: '0.000000',
              miny: '-3649.999338',
              maxx: '700.002420',
              maxy: '-4415.002986',
            },
          },
        ],
        Layer: [
          {
            attr: { queryable: '1', opaque: '1', cascaded: '0' },
            Name: { attr: {}, value: 'RAD_NL25_PCP_CM' },
            Title: { attr: {}, value: 'Precipitation Radar NL' },
            EX_GeographicBoundingBox: {
              attr: {},
              westBoundLongitude: { attr: {}, value: '0.000000' },
              eastBoundLongitude: { attr: {}, value: '10.856452' },
              southBoundLatitude: { attr: {}, value: '48.895303' },
              northBoundLatitude: { attr: {}, value: '55.973600' },
            },
            BoundingBox: [
              {
                attr: {
                  CRS: 'EPSG:3411',
                  minx: '2682754.743620',
                  miny: '-3245034.014141',
                  maxx: '3759536.917562',
                  maxy: '-2168251.989038',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:3412',
                  minx: '0.000000',
                  miny: '32318824.826266',
                  maxx: '7413041.166015',
                  maxy: '40075258.815074',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:3575',
                  minx: '-770622.801471',
                  miny: '-4485814.811314',
                  maxx: '56845.766135',
                  maxy: '-3684039.443620',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:3857',
                  minx: '0.000000',
                  miny: '6257115.219364',
                  maxx: '1208534.698398',
                  maxy: '7553161.958695',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:4258',
                  minx: '48.895303',
                  miny: '0.000000',
                  maxx: '55.973600',
                  maxy: '10.856452',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:4326',
                  minx: '48.895303',
                  miny: '0.000000',
                  maxx: '55.973600',
                  maxy: '10.856452',
                },
              },
              {
                attr: {
                  CRS: 'CRS:84',
                  minx: '48.895303',
                  miny: '0.000000',
                  maxx: '55.973600',
                  maxy: '10.856452',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:25831',
                  minx: '282182.345905',
                  miny: '5433247.394267',
                  maxx: '997135.658653',
                  maxy: '6207204.592736',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:25832',
                  minx: '-153083.019482',
                  miny: '5415817.312927',
                  maxx: '617595.626092',
                  maxy: '6239769.309937',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:28992',
                  minx: '-236275.338083',
                  miny: '106727.731651',
                  maxx: '501527.918656',
                  maxy: '900797.079725',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:7399',
                  minx: '0.000000',
                  miny: '5757301.056717',
                  maxx: '763611.971696',
                  maxy: '6483919.801602',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:50001',
                  minx: '-2000000.000000',
                  miny: '-2000000.000000',
                  maxx: '10000000.000000',
                  maxy: '8500000.000000',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:54030',
                  minx: '0.000000',
                  miny: '5211855.054125',
                  maxx: '853649.695106',
                  maxy: '5936394.291427',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:32661',
                  minx: '2000000.000000',
                  miny: '-2703305.597319',
                  maxx: '2745713.040381',
                  maxy: '-1888346.216710',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:40000',
                  minx: '0.000000',
                  miny: '-4731695.771951',
                  maxx: '750214.326339',
                  maxy: '-3911817.119426',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:900913',
                  minx: '0.000000',
                  miny: '6257115.219364',
                  maxx: '1208534.698398',
                  maxy: '7553161.958695',
                },
              },
              {
                attr: {
                  CRS: 'PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0',
                  minx: '0.000000',
                  miny: '-3649.999338',
                  maxx: '700.002420',
                  maxy: '-4415.002986',
                },
              },
            ],
            Dimension: {
              attr: {
                name: 'time',
                units: 'ISO8601',
                default: '2021-11-15T09:55:00Z',
                multipleValues: '1',
                nearestValue: '0',
                current: '1',
              },
              value: '2021-03-31T09:25:00Z/2021-11-15T09:55:00Z/PT5M',
            },
            Style: [
              {
                attr: {},
                Name: { attr: {}, value: 'radar/nearest' },
                Title: { attr: {}, value: 'radar/nearest' },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=radar/nearest',
                    },
                  },
                },
              },
              {
                attr: {},
                Name: { attr: {}, value: 'precip-rainbow/nearest' },
                Title: { attr: {}, value: 'precip-rainbow/nearest' },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-rainbow/nearest',
                    },
                  },
                },
              },
              {
                attr: {},
                Name: { attr: {}, value: 'precip-gray/nearest' },
                Title: { attr: {}, value: 'precip-gray/nearest' },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-gray/nearest',
                    },
                  },
                },
              },
              {
                attr: {},
                Name: { attr: {}, value: 'precip-blue/nearest' },
                Title: { attr: {}, value: 'precip-blue/nearest' },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-blue/nearest',
                    },
                  },
                },
              },
              {
                attr: {},
                Name: {
                  attr: {},
                  value: 'precip-blue-transparent/nearest',
                },
                Title: {
                  attr: {},
                  value: 'precip-blue-transparent/nearest',
                },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-blue-transparent/nearest',
                    },
                  },
                },
              },
              {
                attr: {},
                Name: { attr: {}, value: 'precip-with-range/nearest' },
                Title: { attr: {}, value: 'precip-with-range/nearest' },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=RAD_NL25_PCP_CM&format=image/png&STYLE=precip-with-range/nearest',
                    },
                  },
                },
              },
            ],
          },
          {
            attr: { queryable: '1', opaque: '1', cascaded: '0' },
            Name: { attr: {}, value: 'Reflectivity' },
            Title: { attr: {}, value: 'Reflectivity' },
            EX_GeographicBoundingBox: {
              attr: {},
              westBoundLongitude: { attr: {}, value: '0.000000' },
              eastBoundLongitude: { attr: {}, value: '10.856452' },
              southBoundLatitude: { attr: {}, value: '48.895303' },
              northBoundLatitude: { attr: {}, value: '55.973600' },
            },
            BoundingBox: [
              {
                attr: {
                  CRS: 'EPSG:3411',
                  minx: '2682754.743620',
                  miny: '-3245034.014141',
                  maxx: '3759536.917562',
                  maxy: '-2168251.989038',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:3412',
                  minx: '0.000000',
                  miny: '32318824.826266',
                  maxx: '7413041.166015',
                  maxy: '40075258.815074',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:3575',
                  minx: '-770622.801471',
                  miny: '-4485814.811314',
                  maxx: '56845.766135',
                  maxy: '-3684039.443620',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:3857',
                  minx: '0.000000',
                  miny: '6257115.219364',
                  maxx: '1208534.698398',
                  maxy: '7553161.958695',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:4258',
                  minx: '48.895303',
                  miny: '0.000000',
                  maxx: '55.973600',
                  maxy: '10.856452',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:4326',
                  minx: '48.895303',
                  miny: '0.000000',
                  maxx: '55.973600',
                  maxy: '10.856452',
                },
              },
              {
                attr: {
                  CRS: 'CRS:84',
                  minx: '48.895303',
                  miny: '0.000000',
                  maxx: '55.973600',
                  maxy: '10.856452',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:25831',
                  minx: '282182.345905',
                  miny: '5433247.394267',
                  maxx: '997135.658653',
                  maxy: '6207204.592736',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:25832',
                  minx: '-153083.019482',
                  miny: '5415817.312927',
                  maxx: '617595.626092',
                  maxy: '6239769.309937',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:28992',
                  minx: '-236275.338083',
                  miny: '106727.731651',
                  maxx: '501527.918656',
                  maxy: '900797.079725',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:7399',
                  minx: '0.000000',
                  miny: '5757301.056717',
                  maxx: '763611.971696',
                  maxy: '6483919.801602',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:50001',
                  minx: '-2000000.000000',
                  miny: '-2000000.000000',
                  maxx: '10000000.000000',
                  maxy: '8500000.000000',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:54030',
                  minx: '0.000000',
                  miny: '5211855.054125',
                  maxx: '853649.695106',
                  maxy: '5936394.291427',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:32661',
                  minx: '2000000.000000',
                  miny: '-2703305.597319',
                  maxx: '2745713.040381',
                  maxy: '-1888346.216710',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:40000',
                  minx: '0.000000',
                  miny: '-4731695.771951',
                  maxx: '750214.326339',
                  maxy: '-3911817.119426',
                },
              },
              {
                attr: {
                  CRS: 'EPSG:900913',
                  minx: '0.000000',
                  miny: '6257115.219364',
                  maxx: '1208534.698398',
                  maxy: '7553161.958695',
                },
              },
              {
                attr: {
                  CRS: 'PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0',
                  minx: '0.000000',
                  miny: '-3649.999338',
                  maxx: '700.002420',
                  maxy: '-4415.002986',
                },
              },
            ],
            Dimension: {
              attr: {
                name: 'time',
                units: 'ISO8601',
                default: '2021-11-15T09:55:00Z',
                multipleValues: '1',
                nearestValue: '0',
                current: '1',
              },
              value: '2021-10-25T11:45:00Z/2021-11-15T09:55:00Z/PT5M',
            },
            Style: [
              {
                attr: {},
                Name: { attr: {}, value: 'radarReflectivity/nearest' },
                Title: { attr: {}, value: 'radarReflectivity/nearest' },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=Reflectivity&format=image/png&STYLE=radarReflectivity/nearest',
                    },
                  },
                },
              },
              {
                attr: {},
                Name: {
                  attr: {},
                  value: 'radarReflectivity_transparant/nearest',
                },
                Title: {
                  attr: {},
                  value: 'radarReflectivity_transparant/nearest',
                },
                LegendURL: {
                  attr: { width: '300', height: '400' },
                  Format: { attr: {}, value: 'image/png' },
                  OnlineResource: {
                    attr: {
                      'xlink:type': 'simple',
                      'xlink:href':
                        'https://geoservices.knmi.nl/adaguc-server?DATASET=RADAR&SERVICE=WMS&&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=Reflectivity&format=image/png&STYLE=radarReflectivity_transparant/nearest',
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    },
  },
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { snackbarReducer } from './reducer';
import { snackbarListener } from './listener';
import { SnackbarModuleStore } from './types';

export const snackbarReducersMap = {
  snackbar: snackbarReducer,
};

export const snackbarMiddlewares = [snackbarListener.middleware];

const snackbarRootReuducer = combineReducers(snackbarReducersMap);

export const createMockStore = (
  mockState?: SnackbarModuleStore,
): ToolkitStore =>
  configureStore({
    reducer: snackbarRootReuducer,
    preloadedState: mockState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(snackbarListener.middleware),
  });

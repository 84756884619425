/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC, useEffect, useRef, useState } from 'react';
import { useMakeSureContainerStaysInsideWindow } from '@opengeoweb/shared';
import Draggable from 'react-draggable';
import { NumberSize, Resizable } from 're-resizable';
import { Resize } from '@opengeoweb/theme';
import { ClockContainer } from './ClockContainer';
import { PopperMenu } from './PopperMenu';

const MIN_WIDTH = 170;
const MIN_HEIGHT = 32;
const MIN_FONT_SIZE = 14; // bigger font size will cause render delay in LCP
const FONT_SIZE_WIDTH_RATIO = MIN_FONT_SIZE / MIN_WIDTH;
const BUTTON_WIDTH = 40;
const POPPER_WIDTH = 356;
const POPPER_LENGTH = 240;

export type PopperPlacement = 'right' | 'left' | 'top' | 'bottom';

export const TimeSliderClock: FC<{
  time: number;
  options?: React.ReactElement;
  controls?: React.ReactElement;
  hideButton?: boolean;
  isPopperOpenByDefault?: boolean;
}> = ({
  time,
  options,
  controls,
  hideButton = true,
  isPopperOpenByDefault = false,
}) => {
  const clockElement = useRef<HTMLDivElement | null>(null);
  const [buttonElement, setButtonElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [popperPlacement, setPopperPlacement] =
    useState<PopperPlacement>('left');

  const [position, setPosition] = useState({ x: 0, y: 0 });

  useMakeSureContainerStaysInsideWindow(clockElement, position, setPosition);

  const [size, setSize] = useState<NumberSize>({
    width: MIN_WIDTH,
    height: MIN_HEIGHT,
  });

  const [fontSize, setFontSize] = useState(MIN_FONT_SIZE);

  useEffect(() => {
    if (hideButton) {
      setIsPopperOpen(false);
    }
    if (size.width === MIN_WIDTH && hideButton) {
      return;
    }
    const diff = hideButton ? -BUTTON_WIDTH : BUTTON_WIDTH;
    const width = size.width + diff;
    setSize({ ...size, width });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideButton]);

  useEffect(() => {
    if (buttonElement && isPopperOpenByDefault) {
      setIsPopperOpen(true);
    }
  }, [buttonElement, isPopperOpenByDefault]);

  return (
    <>
      <PopperMenu
        popperPlacement={popperPlacement}
        isPopperOpen={isPopperOpen}
        buttonElement={buttonElement}
        options={options}
        controls={controls}
      />
      <Draggable
        cancel=".MuiButtonBase-root"
        nodeRef={clockElement}
        bounds="parent"
        position={position}
        onStop={(_event, position): void => {
          setPosition(position);
          setPopperPlacement(getPopperplacement(clockElement.current));
        }}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 1001,
            top: '10px',
            right: '10px',
          }}
          ref={(ref): void => {
            clockElement.current = ref;
            setPopperPlacement(getPopperplacement(ref));
          }}
        >
          <Resizable
            onResizeStart={(event): void => {
              event.stopPropagation();
            }}
            onResize={(_event, _direction, _ref, delta): void => {
              const width = size.width + delta.width;
              const adjustedWidth = hideButton ? width : width - BUTTON_WIDTH;
              const fontSize = FONT_SIZE_WIDTH_RATIO * adjustedWidth;
              setFontSize(fontSize);
            }}
            onResizeStop={(_event, _direction, _ref, delta): void => {
              const width = size.width + delta.width;
              const height = size.height + delta.height;
              setSize({
                width,
                height,
              });

              setPopperPlacement(getPopperplacement(clockElement.current));
            }}
            lockAspectRatio
            minHeight={MIN_HEIGHT}
            minWidth={hideButton ? MIN_WIDTH : MIN_WIDTH + BUTTON_WIDTH}
            size={size}
            handleComponent={{
              bottomLeft: (
                <Resize
                  isRightAligned
                  style={{
                    fill: '#FFFFFF',
                  }}
                />
              ),
            }}
            enable={resizeHandlePlacement}
          >
            <ClockContainer
              hideButton={hideButton}
              onButtonClick={(): void => {
                if (!isPopperOpen) {
                  setPopperPlacement(getPopperplacement(clockElement.current));
                }
                setIsPopperOpen(!isPopperOpen);
              }}
              isPopperOpen={isPopperOpen}
              fontSize={fontSize}
              time={time}
              setButtonElement={setButtonElement}
              popperPlacement={popperPlacement}
            />
          </Resizable>
        </div>
      </Draggable>
    </>
  );
};

const getPopperplacement = (
  clockElement: HTMLDivElement | null,
): PopperPlacement => {
  const clock = clockElement?.getBoundingClientRect();
  const mapWindow = clockElement?.parentElement?.getBoundingClientRect();
  if (clock && mapWindow) {
    const popperIsOutsideWindowOnLeft =
      mapWindow.left > clock.left - POPPER_WIDTH;
    const popperIsOutsideWindowOnBottom =
      clock.bottom + POPPER_LENGTH > mapWindow.bottom;
    const popperIsOutsideWindowOnRight =
      clock.right + POPPER_WIDTH > mapWindow.right;

    if (
      popperIsOutsideWindowOnLeft &&
      popperIsOutsideWindowOnBottom &&
      popperIsOutsideWindowOnRight
    ) {
      return 'top';
    }
    if (popperIsOutsideWindowOnLeft && popperIsOutsideWindowOnBottom) {
      return 'right';
    }
    if (popperIsOutsideWindowOnLeft) {
      return 'bottom';
    }
  }
  return 'left';
};

const resizeHandlePlacement = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: true,
  topLeft: false,
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Box, Grid2 as Grid } from '@mui/material';
import { getDeepProperty } from '@opengeoweb/form-fields';
import React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { formatIssueTime, getFieldNames } from '../utils';
import TafFormTextField from './TafFormTextField';
import { validateWindField } from './validations/wind';
import {
  validateChangeField,
  validateChangeFieldInterField,
} from './validations/change';
import { validateProbabilityField } from './validations/probability';

import {
  validateSecondWeatherField,
  validateThirdWeatherField,
  validateWeatherField,
} from './validations/weather';
import {
  validateValidField,
  validateValidChangeGroupInterField,
  validatePreviousChangeGroup,
  validateOverlapWithFM,
} from './validations/validField';
import { validateVisibilityField } from './validations/visibility';
import {
  validateFirstCloud,
  validateSecondCloud,
  validateThirdCloud,
  validateFourthCloud,
} from './validations/clouds';
import { FieldNames, TafFormRowProps } from './types';
import TafFormOptionsButton from '../TafFormButtons/TafFormOptionsButton';

const getDefaultValue = (fieldName: string, data: FieldValues): string => {
  if (!data) {
    return '';
  }
  return String(getDeepProperty(fieldName.split('.'), data) || '');
};

const getRequiredCrossFields = (
  fieldNames: FieldNames,
): [string[], string[], string[]] => [
  [fieldNames.valid], // main fields
  [fieldNames.change!, fieldNames.probability!], // main optional fields
  [
    fieldNames.wind,
    fieldNames.visibility,
    fieldNames.weather1,
    fieldNames.cloud1,
  ], // sub fields
];

const TafFormRow = React.forwardRef<HTMLDivElement, TafFormRowProps>(
  (
    {
      isNotRequiredForAction,
      isChangeGroup = false,
      disabled = false,
      index = -1,
      onRemoveChangeGroup,
      field = null!,
      dragHandle = null!,
      onAddChangeGroupBelow = (): void => {},
      onAddChangeGroupAbove = (): void => {},
      onClearRow = (): void => {},
      ...props
    }: TafFormRowProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const namePrefix = !isChangeGroup
      ? 'baseForecast'
      : `changeGroups[${index}]`;
    const fieldNames = getFieldNames(isChangeGroup, index);
    const { getValues, trigger } = useFormContext();

    // cross-field required validation
    const [mainFields, mainOptionalFields, subFields] =
      getRequiredCrossFields(fieldNames);
    const hasFieldsValue = (fields: string[]): boolean =>
      !!fields.map(getValues).find((field) => !!field);

    // Used to validate the required field for change groups: Valid
    const validateRequiredMain = (value: string): boolean | string => {
      return isNotRequiredForAction(value) !== true &&
        isChangeGroup &&
        !value.length &&
        (hasFieldsValue(subFields) ||
          hasFieldsValue(mainFields) ||
          hasFieldsValue(mainOptionalFields))
        ? 'Valid is required'
        : true;
    };

    // Used to ensure either probability or change is entered, or both
    const validateRequiredMainOptional = (value: string): boolean | string => {
      return isNotRequiredForAction(value) !== true &&
        isChangeGroup &&
        (hasFieldsValue(mainFields) || hasFieldsValue(subFields)) &&
        !hasFieldsValue(mainOptionalFields)
        ? 'Probability or change is required'
        : true;
    };

    // Used to ensure one wind, visibility, weather or cloud is entered when start editing a changegroup and all of these are empty
    const validateRequiredSub = (value: string): boolean | string => {
      return isNotRequiredForAction(value) !== true &&
        isChangeGroup &&
        (hasFieldsValue(mainFields) || hasFieldsValue(mainOptionalFields)) &&
        !hasFieldsValue(subFields)
        ? 'Wind, visibility, weather or cloud is required'
        : true;
    };

    // Used to validate the combination of change group and wind, visibility, weather and/or cloud data entered
    const validateRequiredMainFields = (
      value: string,
      fieldName: string,
    ): string | boolean => {
      const changeField = getValues(`${namePrefix}.change`);
      const visibilityField = getValues(`${namePrefix}.visibility`);
      const isFM = changeField && changeField.trim() === 'FM';
      const isCAVOK = visibilityField && visibilityField.trim() === 'CAVOK';

      if (isNotRequiredForAction(value) !== true && !value.length) {
        if ((isChangeGroup && isFM) || !isChangeGroup) {
          // exception for CavOK
          if (isCAVOK && (fieldName === 'Clouds' || fieldName === 'Weather')) {
            return true;
          }
          // exception for visibility > 5000
          if (
            visibilityField &&
            parseInt(visibilityField.trim(), 10) >= 5000 &&
            fieldName === 'Weather'
          ) {
            return true;
          }
          return `${fieldName} is required`;
        }
      }

      return true;
    };

    const validatePreviousRow = React.useCallback(() => {
      if (isChangeGroup && index > 0) {
        const { validDateEnd, validDateStart, changeGroups } = getValues();
        const currentChangeGroup = changeGroups[index];
        const previousChangeGroup = changeGroups[index - 1];

        const isvalidPrevious =
          validateValidField(
            currentChangeGroup.valid,
            getValues('validDateStart'),
            getValues('validDateEnd'),
          ) === true;
        const isvalidNext =
          validateValidField(
            previousChangeGroup.valid,
            getValues('validDateStart'),
            getValues('validDateEnd'),
          ) === true;

        if (isvalidPrevious !== true || isvalidNext !== true) {
          return true;
        }

        return validatePreviousChangeGroup(
          currentChangeGroup,
          previousChangeGroup,
          validDateStart,
          validDateEnd,
        );
      }
      return true;
    }, [index, isChangeGroup, getValues]);

    const getFieldFromRow = (fieldname: string, rowIndex: number): string[] => {
      const indexedFieldName = `changeGroups[${rowIndex}].${fieldname}`;
      const rowValue = getValues(indexedFieldName);
      return rowValue ? [indexedFieldName] : [];
    };

    const getValidFieldFromNextRow = (): string[] =>
      getFieldFromRow('valid', index + 1);

    const validateNextRows = React.useCallback(() => {
      if (isChangeGroup) {
        const { validDateEnd, validDateStart, changeGroups } = getValues();
        const currentChangeGroup = changeGroups[index];
        const nextChangeGroups = changeGroups.slice(index + 1);
        return validateOverlapWithFM(
          currentChangeGroup,
          nextChangeGroups,
          validDateStart,
          validDateEnd,
        );
      }
      return true;
    }, [index, isChangeGroup, getValues]);

    const getPreviousValidFields = (rowIndex: number): string[] => {
      const { changeGroups } = getValues() as FieldValues;
      const previousChangeGroups = changeGroups.slice(0, rowIndex);
      const validFields = previousChangeGroups.map(
        (_element: HTMLElement, index: number) =>
          getFieldFromRow('valid', index)[0],
      );
      return validFields;
    };

    const addChangeGroupBelow = React.useCallback(() => {
      onAddChangeGroupBelow(index);
    }, [onAddChangeGroupBelow, index]);

    const addChangeGroupAbove = React.useCallback(() => {
      onAddChangeGroupAbove(index);
    }, [onAddChangeGroupAbove, index]);

    const clearRow = React.useCallback(() => {
      onClearRow(index);
    }, [onClearRow, index]);

    return (
      <Grid
        data-testid={`row-${namePrefix}`}
        container
        ref={ref}
        {...props}
        className="formRow"
        sx={[
          {
            justifyContent: 'flex-start',
            marginTop: '8px',
            backgroundColor: 'geowebColors.cards.cardContainer',
            position: 'relative',
            paddingLeft: { xs: '2px', lg: '10px' },
            paddingRight: { xs: '2px', lg: '10px' },

            '&.sortable-chosen': {
              boxShadow: 1,
            },

            '&.sortable-ghost': {
              opacity: 0.5,
            },
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
      >
        <Grid
          container
          size="grow"
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          {!isChangeGroup && (
            <>
              <TafFormTextField
                label="Type"
                name={fieldNames.messageType!}
                disabled
              />
              <TafFormTextField label="Location" name="location" disabled />
              <TafFormTextField
                formatter={formatIssueTime}
                label="Issue time"
                name="issueDate"
                disabled
                shouldPreventUppercase
              />
            </>
          )}
          {isChangeGroup && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '2px',
                  minWidth: 60,
                }}
              >
                {!disabled && dragHandle}
              </Box>
              <TafFormTextField
                label="Prob"
                name={fieldNames.probability!}
                disabled={disabled}
                defaultValue={getDefaultValue('probability', field)}
                rules={{
                  validate: {
                    validateRequiredMainOptional,
                    validateProbabilityField,
                  },
                }}
                autoComplete={[
                  { key: '3', value: 'PROB30' },
                  { key: '4', value: 'PROB40' },
                ]}
                onBlur={(): void => {
                  if (isChangeGroup) {
                    const nextValidFieldName = getValidFieldFromNextRow();
                    void trigger([
                      ...subFields,
                      ...mainOptionalFields,
                      ...mainFields,
                      ...nextValidFieldName,
                    ]);
                  }
                }}
              />
              <TafFormTextField
                label="Change"
                name={fieldNames.change!}
                disabled={disabled}
                defaultValue={getDefaultValue('change', field)}
                rules={{
                  validate: {
                    validateRequiredMainOptional,
                    validateChangeField,
                    validateChangeFieldInterField: (value): boolean | string =>
                      validateChangeFieldInterField(
                        value,
                        getValues(fieldNames.probability!),
                      ),
                  },
                }}
                autoComplete={[
                  { key: 'T', value: 'TEMPO' },
                  { key: 'B', value: 'BECMG' },
                  { key: 'F', value: 'FM' },
                ]}
                onBlur={(): void => {
                  if (isChangeGroup) {
                    const nextValidFieldName = getValidFieldFromNextRow();
                    const previousValidFieldNames =
                      getPreviousValidFields(index);
                    void trigger([
                      ...subFields,
                      ...mainOptionalFields,
                      ...mainFields,
                      ...nextValidFieldName,
                      ...previousValidFieldNames,
                    ]);
                  }
                }}
              />
            </>
          )}
          <TafFormTextField
            label="Valid"
            name={fieldNames.valid}
            // always disabled for baseforecast row
            disabled={!isChangeGroup ? true : disabled}
            defaultValue={getDefaultValue('valid', field)}
            rules={{
              validate: {
                validateRequiredMain,
                validateValidField: (value: string): boolean | string =>
                  isChangeGroup
                    ? validateValidField(
                        value,
                        getValues('validDateStart'),
                        getValues('validDateEnd'),
                      )
                    : true,
                validateValidChangeGroupInterField: (
                  value,
                ): boolean | string =>
                  isChangeGroup
                    ? validateValidChangeGroupInterField(
                        value,
                        getValues(fieldNames.change!),
                        getValues(fieldNames.probability!),
                        getValues('validDateStart'),
                        getValues('validDateEnd'),
                      )
                    : true,
                validatePreviousRow,
                validateNextRows,
              },
            }}
            onBlur={(): void => {
              if (isChangeGroup) {
                const nextValidFieldName = getValidFieldFromNextRow();
                const previousValidFieldNames = getPreviousValidFields(index);
                void trigger([
                  ...subFields,
                  ...mainOptionalFields,
                  ...mainFields,
                  ...nextValidFieldName,
                  ...previousValidFieldNames,
                ]);
              }
            }}
          />

          <TafFormTextField
            label="Wind"
            name={fieldNames.wind}
            disabled={disabled}
            defaultValue={getDefaultValue('wind', field)}
            rules={{
              validate: {
                validateRequiredMainFields: (value): boolean | string =>
                  validateRequiredMainFields(value, 'Wind'),
                validateRequiredSub,
                validateWindField: (value): boolean | string =>
                  validateWindField(
                    value,
                    getValues(fieldNames.weather1),
                    getValues(fieldNames.weather2),
                    getValues(fieldNames.weather3),
                  ),
              },
            }}
            onBlur={(): void => {
              if (isChangeGroup) {
                void trigger([...subFields, ...mainFields]);
              }
            }}
            autoFocus={!isChangeGroup}
          />
          <TafFormTextField
            label="Visibility"
            name={fieldNames.visibility}
            disabled={disabled}
            defaultValue={getDefaultValue('visibility', field)}
            rules={{
              validate: {
                validateRequiredMainFields: (value): boolean | string =>
                  validateRequiredMainFields(value, 'Visibility'),
                validateRequiredSub,
                validateVisibilityField,
              },
            }}
            autoComplete={[{ key: 'C', value: 'CAVOK' }]}
            onBlur={(): void => {
              const fields = isChangeGroup
                ? [...subFields, ...mainFields]
                : subFields;
              void trigger([
                ...fields,
                fieldNames.weather1,
                fieldNames.weather2,
                fieldNames.weather3,
              ]);
            }}
          />
          <TafFormTextField
            label="Weather"
            name={fieldNames.weather1}
            disabled={disabled}
            defaultValue={getDefaultValue('weather.weather1', field)}
            rules={{
              validate: {
                validateRequiredMainFields: (value): boolean | string =>
                  validateRequiredMainFields(value, 'Weather'),
                validateRequiredSub,
                validateWeatherField: (value): boolean | string =>
                  validateWeatherField(
                    value,
                    getValues(fieldNames.visibility),
                    !!isChangeGroup,
                  ),
              },
            }}
            onBlur={(): Promise<boolean> | void => {
              const fields = isChangeGroup ? [...subFields, ...mainFields] : [];
              return trigger([
                ...fields,
                fieldNames.weather2,
                fieldNames.weather3,
                fieldNames.wind,
                fieldNames.cloud1,
              ]);
            }}
          />
          <TafFormTextField
            label="Weather"
            name={fieldNames.weather2}
            disabled={disabled}
            defaultValue={getDefaultValue('weather.weather2', field)}
            rules={{
              validate: {
                validateSecondWeatherField: (value: string): boolean | string =>
                  validateSecondWeatherField(
                    getValues(fieldNames.weather1),
                    value,
                    getValues(fieldNames.visibility),
                  ),
              },
            }}
            onBlur={(): Promise<boolean> | void => {
              const fields = isChangeGroup ? [...subFields, ...mainFields] : [];
              return trigger([
                ...fields,
                fieldNames.weather3,
                fieldNames.wind,
                fieldNames.cloud1,
              ]);
            }}
          />
          <TafFormTextField
            label="Weather"
            name={fieldNames.weather3}
            disabled={disabled}
            defaultValue={getDefaultValue('weather.weather3', field)}
            rules={{
              validate: {
                validateThirdWeatherField: (value: string): boolean | string =>
                  validateThirdWeatherField(
                    getValues(fieldNames.weather1),
                    getValues(fieldNames.weather2),
                    value,
                    getValues(fieldNames.visibility),
                  ),
              },
            }}
            onBlur={(): Promise<boolean> | void => {
              return trigger([fieldNames.wind, fieldNames.cloud1]);
            }}
          />
          <TafFormTextField
            label="Cloud"
            name={fieldNames.cloud1}
            disabled={disabled}
            defaultValue={getDefaultValue('cloud.cloud1', field)}
            rules={{
              validate: {
                validateRequiredMainFields: (value): boolean | string =>
                  validateRequiredMainFields(value, 'Clouds'),
                validateRequiredSub,
                validateFirstCloud: (value): boolean | string =>
                  validateFirstCloud(
                    value,
                    getValues(fieldNames.visibility),
                    getValues(fieldNames.weather1),
                    getValues(fieldNames.weather2),
                    getValues(fieldNames.weather3),
                  ),
              },
            }}
            onBlur={(): Promise<boolean> | void => {
              const fields = isChangeGroup ? [...subFields, ...mainFields] : [];
              return trigger([
                ...fields,
                fieldNames.cloud2,
                fieldNames.cloud3,
                fieldNames.cloud4,
              ]);
            }}
          />
          <TafFormTextField
            label="Cloud"
            name={fieldNames.cloud2}
            disabled={disabled}
            defaultValue={getDefaultValue('cloud.cloud2', field)}
            rules={{
              validate: {
                validateSecondCloud: (value: string): boolean | string =>
                  validateSecondCloud(
                    getValues(fieldNames.cloud1),
                    value,
                    getValues(fieldNames.visibility),
                  ),
              },
            }}
            onBlur={(): Promise<boolean> | void => {
              return trigger([fieldNames.cloud3, fieldNames.cloud4]);
            }}
          />
          <TafFormTextField
            label="Cloud"
            name={fieldNames.cloud3}
            disabled={disabled}
            defaultValue={getDefaultValue('cloud.cloud3', field)}
            rules={{
              validate: {
                validateThirdCloud: (value: string): boolean | string =>
                  validateThirdCloud(
                    getValues(fieldNames.cloud1),
                    getValues(fieldNames.cloud2),
                    value,
                    getValues(fieldNames.visibility),
                  ),
              },
            }}
            onBlur={(): Promise<boolean> | void => {
              return trigger(fieldNames.cloud4);
            }}
          />
          <TafFormTextField
            label="Cloud"
            name={fieldNames.cloud4}
            disabled={disabled}
            defaultValue={getDefaultValue('cloud.cloud4', field)}
            rules={{
              validate: {
                validateFourthCloud: (value: string): boolean | string =>
                  validateFourthCloud(
                    getValues(fieldNames.cloud1),
                    getValues(fieldNames.cloud2),
                    getValues(fieldNames.cloud3),
                    value,
                    getValues(fieldNames.visibility),
                  ),
              },
            }}
          />
        </Grid>
        {!disabled && (
          <Grid
            container
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              width: '3.5%',
              marginBottom: '14px',
              minWidth: 28,
            }}
          >
            <TafFormOptionsButton
              isChangeGroup={isChangeGroup}
              addChangeGroupBelow={addChangeGroupBelow}
              addChangeGroupAbove={addChangeGroupAbove}
              removeChangeGroup={(): void => onRemoveChangeGroup?.(index)}
              clearRow={clearRow}
              index={index}
            />
          </Grid>
        )}
      </Grid>
    );
  },
);

export default TafFormRow;

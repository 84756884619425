/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box, Slider, styled, Typography } from '@mui/material';
import { CustomTooltip } from '@opengeoweb/shared';
import { useState } from 'react';
import { Mark } from './utils';

const SmallFontSlider = styled(Slider)(() => ({
  '& .MuiSlider-markLabel': {
    fontSize: '80%',
  },
}));

interface DimensionSelectSliderProps {
  marks: Mark[];
  layerTitle: string;
  reverse?: boolean;
  isDisabled?: boolean;
  managedValue?: number | string;
  validSelection?: boolean;
  alertColor?: string;
  onChangeDimensionValue: (dimensionValue: string) => void;
}

const DimensionSelectSlider: React.FC<DimensionSelectSliderProps> = ({
  marks,
  layerTitle,
  reverse = false,
  isDisabled = false,
  managedValue = null!,
  validSelection = true,
  alertColor = 'red',
  onChangeDimensionValue,
}: DimensionSelectSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!marks || !marks.length) {
    return null;
  }
  const sortedMarks = [...marks].sort((a, b) =>
    Number(
      typeof a.value === 'number' &&
        typeof b.value === 'number' &&
        a.value - b.value,
    ),
  );
  const adaptedMarks = reverse ? sortedMarks.reverse() : sortedMarks;

  const values = adaptedMarks.map((m) => m.value);
  // using index as values in slider to get constant spacing between marks
  const indexLabelMarks = adaptedMarks.map((m, index) => {
    return { value: index, label: m.label };
  });

  const sliderValue =
    managedValue !== null
      ? values.findIndex((v) => v === managedValue)
      : currentIndex;

  const upDownNavigation = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.metaKey ||
      event.ctrlKey
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Box
        data-testid="slider-dimensionSelect"
        sx={{
          height: '320px',
          margin: '10px',
          marginLeft: '-5px',
          minWidth: 120,
          fontSize: '90%',
        }}
      >
        <Box
          component="span"
          sx={{
            m: 1,
          }}
        >
          <SmallFontSlider
            data-testid="verticalSlider"
            orientation="vertical"
            aria-labelledby="vertical-slider"
            step={null}
            min={0}
            max={indexLabelMarks.length - 1}
            marks={indexLabelMarks}
            style={!validSelection ? { color: alertColor } : {}}
            disabled={isDisabled}
            value={sliderValue}
            onKeyDown={upDownNavigation}
            onChange={(e, val: number | number[]): void => {
              if (typeof val === 'number') {
                onChangeDimensionValue(values[val].toString());
                if (!managedValue) {
                  setCurrentIndex(val);
                }
              }
            }}
          />
        </Box>
      </Box>
      <CustomTooltip title={layerTitle}>
        <Typography
          variant="body2"
          sx={{
            paddingLeft: '10px',
            maxWidth: 130,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {layerTitle}
        </Typography>
      </CustomTooltip>
    </>
  );
};

export default DimensionSelectSlider;

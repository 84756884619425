/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

type TimerFunction = (timerId: string, tickId: number) => void;

interface Timer {
  timerFunc: TimerFunction | null;
  hertz: number;
  timerId: string;
  isPaused: boolean;
}

/**
 * This class should NEVER be initialized with new Metronome(). Use the
 * exported variable. To reset the metronome use unregisterAllTimers()
 * to remove all existing timers followed by init() to initialize the
 * ticks.
 */
class Metronome {
  tickId: number;
  timers: Timer[];
  interval: NodeJS.Timeout | null;
  handleTimerTicks: ((timerIds: string[]) => void) | null;

  constructor() {
    this.tickId = 0;
    this.step = this.step.bind(this);
    this.isPaused = this.isPaused.bind(this);
    this.continueTimer = this.continueTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.toggleTimer = this.toggleTimer.bind(this);
    this.init = this.init.bind(this);
    this.unregisterAllTimers = this.unregisterAllTimers.bind(this);
    this.setSpeed = this.setSpeed.bind(this);

    this.interval = null;
    this.init();
    this.handleTimerTicks = null;

    this.timers = [];
  }

  init(): void {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(this.step, 10);
  }

  unregisterAllTimers(): void {
    this.timers.length = 0;
  }

  step(): void {
    this.tickId += 1;
    const triggeredTimerIds: string[] = [];
    this.timers.forEach((timer) => {
      // Delay in x10 ms
      const delay = 100 / timer.hertz;
      // Get nearest multiple of delay
      const nearestDelay =
        delay * Math.floor((this.tickId + delay / 2) / delay);
      const distanceToDelay = this.tickId - nearestDelay;
      if (Math.abs(distanceToDelay) < 0.5 || distanceToDelay === -0.5) {
        if (!timer.isPaused) {
          timer.timerFunc && timer.timerFunc(timer.timerId, this.tickId);
          triggeredTimerIds.push(timer.timerId);
        }
      }
    });
    // Trigger the handleTimerTicks function with all collected timerIds
    if (triggeredTimerIds.length > 0) {
      this.handleTimerTicks && this.handleTimerTicks(triggeredTimerIds);
    }
  }

  /**
   * Multiple timers with varying frequencies can be made at the same
   * time however each timerId must be unique. If you attempt to create
   * two timers with the same timerId, the second will silently be ignored.
   *
   * @param timerFunc The function to be executed at the given hertz frequency
   * @param hertz Frequency of the timer given in hertz
   * @param timerId Id of the timer
   * @returns Timer or nothing if no timer was created
   */
  register(
    timerFunc: TimerFunction | null,
    hertz: number,
    timerId: string,
  ): Timer | undefined {
    const existingTimer = this.timers.find(
      (timer) => timer.timerId === timerId,
    );
    if (existingTimer) {
      existingTimer.hertz = hertz;
      existingTimer.timerFunc = timerFunc;
      return existingTimer;
    }
    this.timers.push({
      timerFunc,
      hertz,
      timerId,
      isPaused: false,
    });
    return this.timers.at(-1);
  }

  /**
   * Unregistering a timer completely removes it, if you want to pause a
   * timer, then use pauseTimer()
   *
   * @param timerId Id of the timer
   */
  unregister(timerId: string): void {
    const indexToRemove = this.timers.findIndex(
      (timer) => timer.timerId === timerId,
    );
    if (indexToRemove > -1) {
      this.timers.splice(indexToRemove, 1);
    }
  }

  /**
   * Checks if a timer is paused
   *
   * @param timerId Id of the timer
   * @returns true if the timer is paused, false if it is running
   */
  isPaused(timerId: string): boolean {
    for (const timer of this.timers) {
      if (timer.timerId === timerId && timer.isPaused) {
        return true;
      }
    }
    return false;
  }

  /**
   * Continues running a timer
   *
   * @param timerId Id of the timer
   */
  continueTimer(timerId: string): void {
    for (const timer of this.timers) {
      if (timer.timerId === timerId) {
        timer.isPaused = false;
        break;
      }
    }
  }

  /**
   * Pauses a timer
   *
   * @param timerId Id of the timer
   */
  pauseTimer(timerId: string): void {
    for (const timer of this.timers) {
      if (timer.timerId === timerId) {
        timer.isPaused = true;
        break;
      }
    }
  }

  /**
   * Toggles a timer to be paused or running. Be careful when asking redux
   * to toggle a timer, it might toggle twice and therefore do nothing
   *
   * @param timerId Id of the timer
   */
  toggleTimer(timerId: string): void {
    for (const timer of this.timers) {
      if (timer.timerId === timerId) {
        timer.isPaused = !timer.isPaused;
        break;
      }
    }
  }

  /**
   * Sets the speed of a timer to a new frequency given in hertz
   *
   * @param timerId Id of the timer
   * @param hertz Frequency of the timer given in hertz
   */
  setSpeed(timerId: string, hertz: number): void {
    for (const timer of this.timers) {
      if (timer.timerId === timerId) {
        timer.hertz = hertz;
        break;
      }
    }
  }
}

const metronome = new Metronome();
export { metronome };

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  EChartsOption,
  TooltipComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
  GridComponentOption,
  LineSeriesOption,
  BarSeriesOption,
  ScatterSeriesOption,
} from 'echarts';
import { groupBy, sortBy, uniqBy } from 'lodash';
import { dateUtils } from '@opengeoweb/shared';
import { COLOR_MAP, COLOR_NAME_TO_HEX_MAP } from '../../constants';
import { ParameterWithData, Plot, PlotWithData } from './types';

export const dateFormatter = (date: string): string => {
  return dateUtils.dateToString(dateUtils.utc(date), 'eee HH:mm')!;
};

export const getTooltipTimeLabel = (date: string): string => {
  return dateUtils.dateToString(
    dateUtils.utc(date),
    dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH,
  )!;
};

interface ParamIntervalType {
  unit?: string;
  propertyName?: string;
  presentation?: string;
  interval: number;
  minNumberOfIntervals?: number;
  maxNumberOfIntervals?: number;
}

export const paramIntervals: ParamIntervalType[] = [
  {
    propertyName: 'Temp',
    interval: 2,
    maxNumberOfIntervals: 6,
    minNumberOfIntervals: 2,
  },
  {
    propertyName: 'Press',
    interval: 5,
    maxNumberOfIntervals: 6,
    minNumberOfIntervals: 2,
  },
  {
    propertyName: 'Humid',
    interval: 10,
    maxNumberOfIntervals: 6,
    minNumberOfIntervals: 2,
  },
];

// Expands the given initial interval up until the number of values on range gets less than a predefined maximum number.
const expandedInterval = (
  min: number,
  max: number,
  initialInterval: number,
  maxNumberOfIntervals: number,
): number => {
  let numberOfIntervals = (max - min) / initialInterval;
  let interval = initialInterval;
  while (numberOfIntervals > maxNumberOfIntervals) {
    interval *= 2;
    numberOfIntervals = (max - min) / interval;
  }

  return interval;
};

// Shrinks the given initial interval down until the number of values on range gets greater than a predefined minimum number.
const shrunkInterval = (
  min: number,
  max: number,
  initialInterval: number,
  minNumberOfIntervals: number,
): number => {
  let numberOfIntervals = (max - min) / initialInterval;
  let interval = initialInterval;
  while (numberOfIntervals < minNumberOfIntervals) {
    interval /= 2;
    numberOfIntervals = (max - min) / interval;
  }

  return interval;
};

interface CustomIntervalType {
  interval: number | undefined;
  padding: number;
}

const customizedInterval = (
  min: number,
  max: number,
  propertyNames: string[],
): CustomIntervalType => {
  const foundInterval = paramIntervals.find((pres) =>
    propertyNames.find(
      (name) =>
        pres.propertyName &&
        name.toLowerCase().includes(pres.propertyName.toLowerCase()),
    ),
  )?.interval;

  if (foundInterval) {
    // Padding applied only if percentage value is not supposed to exceed 100 or get below 0 (like humidity percentage)

    const isHumidityNameFound =
      paramIntervals
        .find((pres) =>
          propertyNames.find(
            (name) =>
              pres.propertyName &&
              name.toLowerCase().includes(pres.propertyName.toLowerCase()),
          ),
        )
        ?.propertyName!.toLowerCase() === 'humid';

    const padding = isHumidityNameFound && (max >= 100 || min <= 0) ? 0 : 1;

    const maxNumberOfIntervals = paramIntervals.find((pres) =>
      propertyNames.find(
        (name) =>
          pres.propertyName &&
          name.toLowerCase().includes(pres.propertyName.toLowerCase()),
      ),
    )?.maxNumberOfIntervals;

    if (maxNumberOfIntervals) {
      const newInterval = expandedInterval(
        min,
        max,
        foundInterval,
        maxNumberOfIntervals,
      );

      if (newInterval !== foundInterval) {
        return { interval: newInterval, padding } as CustomIntervalType;
      }
    }

    const minNumberOfIntervals = paramIntervals.find((pres) =>
      propertyNames.find(
        (name) =>
          pres.propertyName &&
          name.toLowerCase().includes(pres.propertyName.toLowerCase()),
      ),
    )?.minNumberOfIntervals;

    if (minNumberOfIntervals) {
      const newInterval = shrunkInterval(
        min,
        max,
        foundInterval,
        minNumberOfIntervals,
      );

      if (newInterval !== foundInterval) {
        return { interval: newInterval, padding } as CustomIntervalType;
      }
    }

    return { interval: foundInterval, padding } as CustomIntervalType;
  }

  return { interval: undefined, padding: 0 } as CustomIntervalType;
};

export const getYAxis = (plotCharts: PlotChart[]): YAXisComponentOption[] => {
  const yAxis = plotCharts.flatMap(
    (plotChart, plotIndex): YAXisComponentOption[] => {
      return Object.entries(plotChart.parametersByUnit).flatMap(
        ([unit, parameters], unitIndex): YAXisComponentOption => {
          const allValues = parameters
            .flatMap((parameter) => parameter.value)
            .filter((value) => value !== null && !isNaN(value));

          const propertyNames = parameters.flatMap((parameter) =>
            parameter.propertyName.toLowerCase(),
          );

          let min = Math.min(...allValues);
          let max = Math.max(...allValues);
          const { interval: customInterval, padding } = customizedInterval(
            min,
            max,
            propertyNames,
          );

          if (customInterval) {
            // Use custom value range
            min = customInterval * Math.floor(min / customInterval);
            max = customInterval * (Math.floor(max / customInterval) + padding);
          } else {
            // Use default value range
            min = Math.floor(min);
            max = Math.ceil(max);
          }

          const position = unitIndex % 2 === 0 ? 'left' : 'right';
          const offset = Math.floor(unitIndex / 2) * Y_AXIS_OFFSET;
          const name = unitIndex === 0 ? plotChart.title : undefined;

          return {
            type: 'value',
            position,
            name,
            axisTick: { show: true },
            axisLabel: {
              formatter: `{value} ${unit}`,
            },
            nameTextStyle: {
              align: 'left',
            },
            max,
            min,
            interval: customInterval,
            gridIndex: plotIndex,
            axisLine: {
              show: true,
            },
            offset,
          };
        },
      );
    },
  );

  return yAxis;
};

export const getXAxis = (
  plotCharts: PlotChart[],
  minDate: Date,
  maxDate: Date,
): XAXisComponentOption[] => {
  return plotCharts.map((_plotChart, index): XAXisComponentOption => {
    if (index === plotCharts.length - 1) {
      return {
        min: minDate.toISOString().split('T')[0],
        max: maxDate.toISOString().split('T')[0],
        type: 'category',
        gridIndex: index,
        axisLabel: {
          formatter: dateFormatter,
        },
        axisLine: {
          onZero: false,
        },
      };
    }
    return {
      min: minDate.toISOString().split('T')[0],
      max: maxDate.toISOString().split('T')[0],
      gridIndex: index,
      type: 'category',
      show: false,
    };
  });
};

interface TimeSeriesParam {
  data: [string, number];
  seriesName: string;
}

const isTimeSeriesParam = (param: unknown): param is TimeSeriesParam => {
  if (!param || typeof param !== 'object') {
    return false;
  }
  const obj = param as { data?: unknown; seriesName?: unknown };
  return (
    typeof obj.seriesName === 'string' &&
    Array.isArray(obj.data) &&
    obj.data.length === 2 &&
    typeof obj.data[0] === 'string' &&
    typeof obj.data[1] === 'number' &&
    !isNaN(obj.data[1])
  );
};

export const getParameterDisplayName = (
  parameter: ParameterWithData,
  parameters: ParameterWithData[],
): string => {
  const hasDuplicate = parameters.some(
    (p) =>
      p.propertyName === parameter.propertyName &&
      p.collectionId !== parameter.collectionId,
  );
  return hasDuplicate
    ? `${parameter.propertyName} - ${parameter.collectionId}`
    : parameter.propertyName;
};

export const getOption = (plotsWithData: PlotWithData[]): EChartsOption => {
  const newTimeSpan = CalculateTimeSpan(plotsWithData!);

  const minDate = newTimeSpan.left;
  const maxDate = newTimeSpan.right;

  const plotCharts = getPlotCharts(plotsWithData);
  const xAxis = getXAxis(plotCharts, minDate, maxDate);
  const yAxis = getYAxis(plotCharts);
  const series = getSeries(plotCharts);
  const grid = getGrid(plotCharts);

  const tooltip: TooltipComponentOption = {
    trigger: 'axis',
    confine: true,
    extraCssText: 'white-space:pre-wrap;',
    formatter: (newParams) => {
      const params = Array.isArray(newParams) ? newParams : [newParams];
      const { timestamp, value } = params.reduce<{
        timestamp: string;
        value: string;
      }>(
        (acc, param) => {
          if (isTimeSeriesParam(param)) {
            const timestamp = param.data[0];
            const value = `${acc.value}<br />${param.seriesName}: ${param.data[1]}`;
            return { timestamp, value };
          }
          return acc;
        },
        { timestamp: '', value: '' },
      );
      const timeLabel = getTooltipTimeLabel(timestamp);
      return `<b>${timeLabel}</b>${value}`;
    },
  };
  const parameters = plotsWithData.flatMap((plot) => {
    return plot.parametersWithData;
  });
  const parameterNames = parameters.map((parameter) =>
    getParameterDisplayName(parameter, parameters),
  );

  const dataZoomLabelWidth = 70;
  const option: EChartsOption = {
    legend: {
      data: parameterNames,
    },
    tooltip,
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all',
        },
      ],
    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        left: dataZoomLabelWidth,
        right: dataZoomLabelWidth,
        xAxisIndex: parameters.map((_, index) => index),
        labelFormatter: (_, date): string => {
          return dateFormatter(date);
        },
      },
    ],
    media: [
      {
        query: {
          maxWidth: 600,
        },
        option: {
          grid: {
            top: '12%',
          },
          legend: {
            orient: 'horizontal',
            left: 'center',
          },
        },
      },
      {
        query: {
          maxWidth: 500,
        },
        option: {
          legend: {
            orient: 'horizontal',
            right: '25%',
            left: '25%',
            formatter: (name: string): string => {
              if (name.length > 10) {
                return `${name.slice(0, 10)}...`;
              }
              return name;
            },
          },
        },
      },
      {
        query: {
          maxWidth: 400,
        },
        option: {
          legend: {
            orient: 'horizontal',
            right: '25%',
            left: '30%',
            formatter: (name: string): string => {
              if (name.length > 10) {
                return `${name.slice(0, 10)}...`;
              }
              return name;
            },
          },
        },
      },
      {
        query: {
          maxWidth: 300,
        },
        option: {
          legend: {
            orient: 'horizontal',
            right: '25%',
            left: '45%',
            formatter: (name: string): string => {
              if (name.length > 10) {
                return `${name.slice(0, 10)}...`;
              }
              return name;
            },
          },
        },
      },
      {
        query: {
          minWidth: 301,
        },
        option: {
          legend: {
            formatter: (name: string): string => {
              return name;
            },
          },
        },
      },
    ],
    grid,
    xAxis,
    yAxis,
    series: series.map((s) => ({
      ...s,
      connectNulls: true, // For plots with varying timesteps
    })),
  };
  return option;
};

export const getEChartsSeriesDataByTimestep = (
  timesteps: Date[],
  parameter: ParameterWithData,
): (number | string)[][] => {
  const data = timesteps.map((timestep) => {
    const index = parameter.timestep.findIndex((parameterTimestep) => {
      return parameterTimestep.getTime() === timestep.getTime();
    });
    const value = index === -1 ? NaN : parameter.value[index];
    return [timestep.toISOString(), value];
  });
  return data;
};

export const getSeries = (
  plotCharts: PlotChart[],
): (LineSeriesOption | BarSeriesOption | ScatterSeriesOption)[] => {
  const allTimesteps = plotCharts.flatMap((plotChart) =>
    Object.values(plotChart.parametersByUnit).flatMap((parameters) =>
      parameters.flatMap((parameter) => parameter.timestep),
    ),
  );

  const uniqueTimesteps = sortBy(
    uniqBy(allTimesteps, (timestep) => timestep.getTime()),
    (timestep: Date) => timestep,
    'desc',
  );
  const series = plotCharts.flatMap((plotChart, plotIndex) => {
    return Object.entries(plotChart.parametersByUnit).flatMap(
      ([, parameters], unitIndex) => {
        return parameters.flatMap(
          (
            parameter,
          ): LineSeriesOption | BarSeriesOption | ScatterSeriesOption => {
            const color =
              COLOR_NAME_TO_HEX_MAP[
                parameter.color as keyof typeof COLOR_NAME_TO_HEX_MAP
              ] ??
              COLOR_NAME_TO_HEX_MAP[
                COLOR_MAP[parameter.propertyName as keyof typeof COLOR_MAP]
              ];
            const type =
              parameter.plotType === 'area' ? 'line' : parameter.plotType;
            return {
              data: getEChartsSeriesDataByTimestep(uniqueTimesteps, parameter),
              name: getParameterDisplayName(parameter, parameters),
              type,
              xAxisIndex: plotIndex,
              yAxisIndex: plotChart.countPreviousUnits + unitIndex,
              lineStyle: { color, opacity: parameter.opacity! / 100 },
              itemStyle: { color, opacity: parameter.opacity! / 100 },
              ...(parameter.plotType === 'area' && { areaStyle: {} }),
              ...(parameter.plotType === 'scatter' && { symbolSize: 6 }),
            };
          },
        );
      },
    );
  });

  return series;
};

export const getGrid = (plotCharts: PlotChart[]): GridComponentOption[] => {
  const maxUniqueUnitPerPlot = plotCharts.reduce(
    (maxUniqueUnitPerPlot, plotChart) => {
      const countUniqueUnitsPerPlot = Object.keys(
        plotChart.parametersByUnit,
      ).length;
      if (countUniqueUnitsPerPlot > maxUniqueUnitPerPlot) {
        return countUniqueUnitsPerPlot;
      }
      return maxUniqueUnitPerPlot;
    },
    0,
  );

  const grid = plotCharts.map((_, index): GridComponentOption => {
    const top = PLOT_TOP_MARGIN + PLOT_HEIGHT * index;

    const xMarginValue = maxUniqueUnitPerPlot > 0 ? 60 : 30;

    return {
      left: `${xMarginValue}px`,
      right: `${xMarginValue}px`,
      top: `${top}px`,
      height: '100px',
    };
  });
  return grid;
};

export const Y_AXIS_OFFSET = 80;
export const PLOT_HEIGHT = 150;
const PLOT_TOP_MARGIN = 70;

export interface PlotChart extends Plot {
  parametersByUnit: Record<string, ParameterWithData[]>;
  countPreviousUnits: number;
}

function getPlotCharts(plotsWithData: PlotWithData[]): PlotChart[] {
  let countUnits = 0;
  const plotCharts = plotsWithData.map((plot): PlotChart => {
    const countPreviousUnits = countUnits;
    const parametersByUnit = groupBy(
      plot.parametersWithData,
      (parameter) => parameter.unit,
    );
    const units = Object.keys(parametersByUnit);
    countUnits += units.length;
    return { ...plot, parametersByUnit, countPreviousUnits };
  });

  return plotCharts;
}

type MinMaxValueType = Date | undefined;

/**
 * This function iterates over all added plots and paramters, finds the min and max values from all datasets to determine how to set the timespan
 * @param PlotsWithData Plots that contain fetched parameter data
 * @returns left and right values for the timespan
 */
export const CalculateTimeSpan = (
  plotsWithData: PlotWithData[],
): Record<string, Date> => {
  const now = new Date();

  const containsObservation = { value: false };
  const containsForecast = { value: false };

  // Loop over all plots and find min and max values for each dataset
  plotsWithData.forEach((plot) => {
    const maxValue: { value: MinMaxValueType } = { value: undefined };
    const minValue: { value: MinMaxValueType } = { value: undefined };

    // Find the min and max values for all plots
    plot.parametersWithData.forEach((parameter) => {
      const firstTimestep = parameter.timestep[0];
      const lastTimestep = parameter.timestep[parameter.timestep.length - 1];

      if (maxValue.value === undefined || lastTimestep > maxValue.value) {
        maxValue.value = lastTimestep;
      }
      if (minValue.value === undefined || firstTimestep < minValue.value) {
        minValue.value = firstTimestep;
      }
    });

    if (maxValue.value && maxValue.value <= now) {
      containsObservation.value = true;
    } else if (minValue.value && minValue.value >= now) {
      containsForecast.value = true;
    } else {
      containsForecast.value = true;
      containsObservation.value = true;
    }
  });

  const tenDaysToFuture = dateUtils.add(now, { days: 10 });
  const oneDayToPast = dateUtils.sub(now, { days: 1 });

  const newTimeSpan = { left: now, right: now };

  if (containsObservation.value && containsForecast.value) {
    newTimeSpan.left = oneDayToPast;
    newTimeSpan.right = tenDaysToFuture;
  } else if (containsObservation.value) {
    newTimeSpan.left = oneDayToPast;
    newTimeSpan.right = now;
  } else if (containsForecast.value) {
    newTimeSpan.left = now;
    newTimeSpan.right = tenDaysToFuture;
  }

  return newTimeSpan;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CSSProperties, Elevations, GeowebColorPalette } from '../../../types';

const focusButtonStyle: CSSProperties = {
  outline: 'solid 2px #419cfe',
};

// https://app.zeplin.io/project/5ecf84a3c6ae1047a368f393/screen/5ecf84d920b99428d7f98555
export const colors: GeowebColorPalette = {
  background: {
    surface: '#FFFFFF',
    surfaceApp: '#F5F5F5',
    surfaceBrowser: '#CFCFCF',
  },
  brand: {
    brand: '#FFFFFF',
  },
  buttons: {
    primary: {
      default: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: '#71A6FF',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {},
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    secondary: {
      default: {
        fill: '#374061',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {},
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    tertiary: {
      default: {
        fill: 'transparent',
        color: '#374061',
        borderColor: '#374061',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: '#374061',
      },
      active: {
        fill: 'transparent',
        color: '#0057EC',
        borderColor: '#0057EC',
      },
      activeMouseOver: {
        fill: '#D8E4F9',
        color: '#0057EC',
        borderColor: '#0057EC',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    flat: {
      default: {
        fill: 'transparent',
        color: '#374061',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: 'transparent',
      },
      active: {
        fill: 'transparent',
        color: '#0057EC',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#D8E4F9',
        color: '#0057EC',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    tool: {
      default: {
        fill: '#ECEDEE',
        color: '#374061',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: 'transparent',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    boxed: {
      default: {
        fill: 'transparent',
        color: '#374061',
        borderColor: '#374061',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: '#374061',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    icon: {
      default: {
        fill: 'transparent',
        color: '#374061',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: 'transparent',
      },
      active: {
        fill: 'transparent',
        color: '#186DFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#D8E4F9',
        color: '#186DFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
  },
  iconButtons: {
    flat: {
      default: {
        fill: 'transparent',
        color: '#374061',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: 'transparent',
      },
      active: {
        fill: 'transparent',
        color: '#0057EC',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#D8E4F9',
        color: '#186DFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    tool: {
      default: {
        fill: '#ECEDEE',
        color: '#374061',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: 'transparent',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    boxed: {
      default: {
        fill: 'transparent',
        color: '#374061',
        borderColor: '#374061',
      },
      mouseOver: {
        fill: '#D8E4F9',
        color: '#374061',
        borderColor: '#374061',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#626262',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
  },
  typographyAndIcons: {
    text: '#051039',
    icon: '#051039',
    buttonIcon: '#FFFFFF',
    iconLinkActive: '#186DFF',
    iconLinkDisabled: '#CCCCCC',
    textLinkActive: '#186DFF',
    buttonIconTertiaryFlat: '#575F7A',
    inactiveText: '#626262',
    iconWarning: '#ffa800',
  },
  textInputField: {
    default: {
      fill: '#e9edf5',
      opacity: 1,
    },
    mouseOver: {
      fill: '#e0e8f5',
      opacity: 1,
    },
    active: {
      fill: '#e9edf5',
      opacity: 1,
    },
    disabled: {
      fill: '#000000',
      opacity: 0,
    },
    label: {
      fill: '#051039',
      opacity: 0.67,
    },
    error: {
      fill: '#FEEDEE',
      opacity: 1,
    },
    errorMouseOver: {
      fill: '#ffc5c5',
      opacity: 1,
    },
  },
  menuItem: {
    mouseOver: '#e0e8f5',
    selected: '#186DFF',
    selectedMouseOver: '#1047b0',
  },
  tab: {
    mouseOver: {
      fill: '#051039',
      opacity: 0.07,
    },
  },
  greys: {
    accessible: '#626262',
    accessibleLight: '#C5C5C5',
  },
  cards: {
    cardContainer: '#FFFFFF',
    cardContainerBorder: '#EEEEEE',
    cardContainerMouseOver: '#EFF5FF',
    cardContainerDisabled: '#EBEBEB',
    cardContainerDisabledBorder: '#E3E2E2',
    cardContainerDisabledMouseOver: '#EFF5FF',
  },
  backdrops: {
    black: {
      fill: '#000000',
      opacity: 0.5,
    },
    white: {
      fill: '#FFFFFF',
      opacity: 0.24,
    },
  },
  workspace: {
    activeWindow: '#FFFFFF',
    inactiveWindow: '#E8E5E5',
    tabButtonHover: 'rgba(123, 172, 255, 0.12)',
  },
  timeSlider: {
    // player
    playerNeedleTimeTop: {
      fill: '#E3004F',
      opacity: 100,
    },
    playerNeedleTime: {
      fill: '#FFD523',
      opacity: 0.75,
    },
    playerNeedlePlayerTop: {
      fill: '#051039',
      opacity: 100,
    },
    playerNeedlePlayer: {
      fill: '#67F0FF',
      opacity: 0.75,
    },
    playerTimeMarkers: {
      fill: '#051039',
    },
    playerTimeText: {
      color: '#FFFFFF',
      fontFamily: 'Roboto',
      fontSize: 14,
    },
    // timeline
    timelineIndicator: {
      fill: '#767676',
    },
    timelineText: {
      fontSize: 12,
      color: '#FFFFFF',
      fontFamily: 'Roboto',
    },
    timelineTimeScale: {
      fill: '#FFFFFF',
    },
    timelineNightTime: {
      fill: '#051038',
      opacity: 0.67,
    },
    timelineTimelineSurface: {
      fill: '#FFFFFF',
    },
    timelineMonthChangeDash: {
      fill: '#767676',
      opacity: 0.5,
    },
    timelineSelectionBackground: {
      fill: '#FFFFFF',
      opacity: 0.15,
    },
    timelineSelectionOutline: {
      fill: '#F0FF00',
      opacity: 0.4,
    },
    // time scale
    timeScalePointer: {
      fill: '#F8F9F9',
    },
    timeScaleHorizontalScale: {
      fill: '#070808',
      opacity: 0.2,
    },
    timeScaleTimeIndicators: {
      fill: '#767676',
    },
    timeScaleTimeIndicatorsActive: {
      fill: '#051039',
    },
    timeScaleShadowButtonScale: {
      filter:
        'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.28)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.40))',
    },
    ledgendObservedBackground: {
      fill: '#00649F',
    },
    legendForecastBackground: {
      fill: '#9900B1',
    },
    legendNoDataBackground: {
      fill: '#575F7A',
      opacity: 0.95,
    },
  },
  timeSliderLite: {
    selected: {
      backgroundColor: '#EEF4FB',
    },
    needle: {
      stroke: '#303193',
    },
    needleDefault: {
      stroke: '#3BA587',
    },
    needleShadow: {
      stroke: '#E7F0FA',
    },
    needleLabel: {
      backgroundColor: '#212121',
      borderColor: '#9e9e9e',
      color: '#FFFFFF',
    },
    timeline: {
      fill: '#FFFFFF',
      stroke: '#FFFFFF',
    },
    timelineBackground: {
      fill: '#2959E0',
      color: '#FFFFFF',
    },
    timelineBackgroundAlternative: {
      fill: '#4871E5',
      color: '#FFFFFF',
    },
    timelineBackgroundDisabled: {
      fill: '#767E93',
      color: '#FFFFFF',
    },
    timelineBackgroundDisabledAlternative: {
      fill: '#8A91A3',
      color: '#FFFFFF',
    },
    timelineBackgroundObserved: {
      fill: '#8A91A3',
      color: '#FFFFFF',
    },
    timelineBackgroundObservedAlternative: {
      fill: '#767E93',
      color: '#FFFFFF',
    },
  },
  customSlider: {
    rail: '#488BFD',
    railDisabled: '#CCCCCC',
    track: '#186DFF',
    trackDisabled: '#CCCCCC',
    thumb: '#F8F9F9',
    thumbDisabled: '#CCCCCC',
    mark: '#FFFFFF',
    markDisabled: '#FFFFFF',
  },
  customSwitch: {
    thumb: '#F8F9F9',
    thumbDisabled: '#F5F5F5',
    track: '#707070',
    trackActive: '#186DFF',
  },
  syncGroups: {
    drawerOpen: {
      fill: '#E8E5E5',
    },
  },
  layerManager: {
    headerRowText: {
      color: '#051039',
      opacity: 0.67,
      fontSize: 12,
    },
    tableRowDefaultText: {
      color: '#051039',
      opacity: 0.87,
      fontSize: 12,
    },
    tableRowDefaultCardContainer: {
      fill: '#FFFFFF',
      border: '1px solid #EEEEEE',
      borderColor: '#EEEEEE',
    },
    tableRowDisabledText: {
      color: '#767676',
      opacity: 0.87,
      fontSize: 12,
    },
    tableRowDisabledCardContainer: {
      fill: '#EBEBEB',
      border: '1px solid #E3E2E2',
      borderColor: '#E3E2E2',
    },
    dragHandleHover: {
      backgroundColor: 'transparent',
    },
    root: {},
    opacitySelect: { root: {}, button: {}, popper: {} },
    baseLayerRow: { root: {} },
  },
  tooltips: {
    tooltipContainer: {
      fill: '#232323',
      opacity: 1,
    },
    tooltipText: {
      color: '#FFFFFF',
    },
    tooltipSelect: { select: {}, menu: {} },
  },
  captions: {
    captionStatus: { color: '#051039', opacity: 0.67 },
    captionInformation: { color: '#1100FF', opacity: 1 },
    captionError: { color: '#C00000', opacity: 1 },
    captionErrorBackground: { color: '#C00000', opacity: 1 },
    captionDisabled: { color: '#707070', opacity: 1 },
  },
  functional: {
    success: '#72BB23',
    successOutline: {
      fill: '#FCFFEB',
      border: '1px solid #72BB23',
      borderColor: '#72BB23',
    },
    error: '#C00000',
    errorOutline: {
      fill: '#F9E8EA',
      border: '1px solid #C00000',
      borderColor: '#C00000',
    },
    warning: '#FFA800',
    warningOutline: {
      fill: '#FFF8EB',
      border: '1px solid #FFA800',
      borderColor: '#FFA800',
    },
    warningHighlight: { fill: '#FFA800', opacity: 0.2 },
    notification: '#0062E6',
    notificationOutline: {
      fill: '#DFF2FD',
      border: '1px solid #0062E6',
      borderColor: '#0062E6',
    },
    summary: '#9595FF',
    summaryOutline: {
      fill: '#F8F9F9',
      border: '1px solid #8383F2',
      borderColor: '#8383F2',
    },
    disabled: '#9B9FB0',
    disabledOutline: {
      fill: '#F8F9F9',
      border: '1p solid #9B9FB0',
      borderColor: '#9B9FB0',
    },
  },
  snackbar: {
    background: '#051039',
    text: '#FFFFFF',
    action: '#98e1f1',
    actionHover: { fill: '#7BACFF', opacity: 0.2 },
  },
  chip: {
    highlightDefault: {
      fill: '#FFA800',
      opacity: 0.15,
      borderColor: '#374061',
    },
    highlightDefaultMouseOver: {
      fill: '#DEDBD4',
      opacity: 1,
      borderColor: '#374061',
    },
    highlightActiveDefault: {
      fill: '#566F94',
      opacity: 1,
      borderColor: 'transparent',
    },
    highlightActiveMouseOver: {
      fill: '#345596',
      opacity: 1,
      borderColor: 'transparent',
    },
  },
};

// https://app.zeplin.io/project/5ecf84a3c6ae1047a368f393/screen/609bb6a5287bd1abe0de39a7
export const elevations: Elevations = {
  elevation_01:
    '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)',
  elevation_02:
    '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
  elevation_03:
    '0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 3px 0 rgba(0, 0, 0, 0.14)',
  elevation_04:
    '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
  elevation_06:
    '0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)',
  elevation_08:
    '0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
  elevation_09:
    '0 5px 6px 0 rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14)',
  elevation_12:
    '0 7px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14)',
  elevation_16:
    '0 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14)',
  elevation_24:
    '0 11px 15px 0 rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { uiTypes, useSetupDialog } from '@opengeoweb/store';
import { KeywordFilterResults } from './KeywordFilterResults';
import { layerSelectSelectors, useAppSelector } from '../../store';

interface KeywordFilterResultsConnectProps {
  mapId?: string;
  bounds?: string;
  isMultiMap?: boolean;
}

export const KeywordFilterResultsConnect: React.FC<
  KeywordFilterResultsConnectProps
> = ({ bounds, mapId, isMultiMap }: KeywordFilterResultsConnectProps) => {
  const dialogType = isMultiMap
    ? `${uiTypes.DialogTypes.KeywordFilter}-${mapId}`
    : uiTypes.DialogTypes.KeywordFilter;

  const { dialogOrder, onCloseDialog, setDialogOrder, isDialogOpen, uiSource } =
    useSetupDialog(dialogType);

  const filters = useAppSelector(layerSelectSelectors.getFilters);

  return (
    <KeywordFilterResults
      bounds={bounds}
      isOpen={isDialogOpen}
      onClose={onCloseDialog}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      source={uiSource}
      filters={filters}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { FieldErrors } from 'react-hook-form';

export function getDeepProperty<I>(p: string[], o: I): I | null {
  return p.reduce<unknown>((xs, x) => {
    if (xs && typeof xs === 'object' && x in xs) {
      return (xs as Record<string, unknown>)[x];
    }
    return null;
  }, o) as I | null;
}

export const getErrors = (
  name: string,
  errors: FieldErrors,
): FieldErrors | undefined => {
  const nameAsArray = name.split('.');
  return getDeepProperty(nameAsArray, errors) || undefined;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { TooltipSelect } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import { Parameter, PlotType } from '../../TimeSeries/types';

const ParameterTypeSelect: React.FC<{
  rowIsEnabled: boolean;
  parameter: Parameter;
  patchParameter: (parameter: Partial<Parameter>) => void;
  t: TFunction;
}> = ({ rowIsEnabled, parameter, patchParameter, t }) => {
  return (
    <TooltipSelect
      SelectDisplayProps={{ 'aria-label': 'timeseriesManager-type' }}
      data-testid="parameterTypeSelect"
      value={parameter.plotType}
      tooltip={t('timeseries-choose-type')}
      isEnabled={rowIsEnabled}
      onChange={(event: SelectChangeEvent<unknown>): void => {
        patchParameter({
          id: parameter.id,
          plotType: event.target.value as PlotType,
        });
      }}
    >
      <MenuItem disabled>{t('timeseries-type')}</MenuItem>
      <MenuItem value="line">{t('timeseries-line')}</MenuItem>
      <MenuItem value="bar">{t('timeseries-bar')}</MenuItem>
      <MenuItem value="area">{t('timeseries-area')}</MenuItem>
      <MenuItem value="scatter">{t('timeseries-scatter')}</MenuItem>
    </TooltipSelect>
  );
};

export default ParameterTypeSelect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import ReactHookFormFormControl from './ReactHookFormFormControl';
import { ReactHookFormInput } from './types';
import { getErrors } from './formUtils';
import { defaultProps } from './utils';

type InputMode = 'numeric' | 'decimal';

export const convertNumericInputValue = (
  value: string,
  inputMode: InputMode,
): number | null => {
  if (value !== '' && !isNaN(parseFloat(value))) {
    return inputMode === 'numeric' ? parseInt(value, 10) : parseFloat(value);
  }

  return null;
};

export const getAllowedKeys = (inputMode: InputMode = 'numeric'): string[] => {
  const allowedKeys = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '-',
    'Backspace',
    'ArrowRight',
    'ArrowLeft',
    'Tab',
    'Home',
    'End',
    'PageDown',
    'PageUp',
    'Delete',
    ...(inputMode === 'decimal' ? ['.'] : []),
  ];
  return allowedKeys;
};

type ReactHookFormNumberFieldProps = Partial<TextFieldProps> &
  ReactHookFormInput<{
    inputMode?: InputMode;
  }>;

const ReactHookFormNumberField: React.FC<ReactHookFormNumberFieldProps> = ({
  name,
  label,
  defaultValue = null,
  rules,
  disabled = false,
  inputMode = 'numeric',
  helperText = '',
  className,
  size = 'medium',
  sx,
  onChange = (): void => {},
  isReadOnly,
  ...otherProps
}: ReactHookFormNumberFieldProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange: onChangeField, value, ref },
    formState: { errors: formErrors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    ...defaultProps,
  });

  const errors = getErrors(name!, formErrors);

  const allowedKeyes = getAllowedKeys(inputMode);

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    const clipboardText = event.clipboardData.getData('text/plain');

    const inputElement = event.target as HTMLInputElement;

    const before = inputElement.value.slice(0, inputElement.selectionStart!);
    const after = inputElement.value.slice(inputElement.selectionEnd!);

    const newValue = before + clipboardText + after;

    // eslint-disable-next-line no-useless-escape
    if (inputMode === 'numeric' && !newValue.match(/^\-?[0-9]+$/)) {
      event.preventDefault();
    }

    // eslint-disable-next-line no-useless-escape
    if (inputMode === 'decimal' && !newValue.match(/^\-?[0-9]*(\.[0-9]+)?$/)) {
      event.preventDefault();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const isMac = window.navigator.userAgent.includes(' Mac ');

    if ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) {
      if (['c', 'v', 'x'].includes(event.key.toLowerCase())) {
        return;
      }
    }

    if (
      !allowedKeyes.includes(event.key) ||
      // only allow one seperator
      (event.key === '.' &&
        (event.target as HTMLInputElement).value.includes('.')) ||
      // only allow one -
      (event.key === '-' &&
        (event.target as HTMLInputElement).value.includes('-'))
    ) {
      event.preventDefault();
    }
  };

  const [displayValue, setDisplayValue] = React.useState(
    value === null || isNaN(value) ? '' : value,
  );

  return (
    <ReactHookFormFormControl
      className={className}
      sx={sx}
      disabled={disabled}
      errors={errors}
      isReadOnly={isReadOnly}
    >
      <TextField
        label={label}
        error={!!errors}
        helperText={helperText}
        inputMode={inputMode}
        value={displayValue}
        variant="filled"
        type="text"
        name={name}
        size={size}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
          const { value } = evt.target;
          setDisplayValue(value);
          onChangeField(convertNumericInputValue(value, inputMode));
          onChange(null!);
        }}
        onKeyDown={onKeyDown}
        disabled={disabled}
        inputRef={ref}
        {...otherProps}
        slotProps={{
          ...otherProps.slotProps,
          input: {
            ...(isReadOnly && {
              readOnly: true,
            }),
            onPaste,
          },

          htmlInput: { ...otherProps.slotProps?.htmlInput, inputMode },
        }}
      />
    </ReactHookFormFormControl>
  );
};
export default ReactHookFormNumberField;

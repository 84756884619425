/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';

import {
  DrawRegion,
  DrawPolygon,
  Edit,
  Location,
  Delete,
} from '@opengeoweb/theme';

import {
  defaultGeoJSONStyleProperties,
  emptyGeoJSON,
  featurePoint,
  featurePolygon,
  lineString,
} from '../MapDraw/geojsonShapes';
import { DrawMode, DrawModeValue, SelectionType } from './types';
import { MapViewLayerProps } from '../MapView';
import { DRAWMODE } from '../MapDraw/MapDraw';

import {
  isGeoJSONFeatureCreatedByTool,
  moveFeature,
  createInterSections,
  getFeatureCollection,
  getGeoJson,
  addFeatureProperties,
  getLastEmptyFeatureIndex,
  addSelectionTypeToGeoJSON,
  addGeoJSONProperties,
} from './utils';
import { WEBMAP_REACT_NAMESPACE } from '../../utils/i18n';

export const defaultIntersectionStyleProperties: GeoJSON.GeoJsonProperties = {
  ...defaultGeoJSONStyleProperties,
  'fill-opacity': 0.5,
};

export const emptyLineString: GeoJSON.Feature = {
  ...lineString,
  properties: defaultGeoJSONStyleProperties,
};
export const emptyPoint: GeoJSON.Feature = {
  ...featurePoint,
  properties: defaultGeoJSONStyleProperties,
};
export const emptyPolygon: GeoJSON.Feature = {
  ...featurePolygon,
  properties: defaultGeoJSONStyleProperties,
};
export const emptyBox: GeoJSON.Feature = {
  ...featurePolygon,
  properties: defaultGeoJSONStyleProperties,
};

// TODO: improve this: without a feature, the custom shape does not work on first click
export const emptyIntersectionShape: GeoJSON.FeatureCollection = {
  ...emptyGeoJSON,
  features: [featurePolygon],
};

type DrawLayerType =
  | 'geoJSON'
  | 'geoJSONIntersection'
  | 'geoJSONIntersectionBounds';

export interface MapDrawToolProps {
  geoJSON: GeoJSON.FeatureCollection; // user selection
  geoJSONIntersection?: GeoJSON.FeatureCollection; // result of selection and geoJSONIntersectionBounds
  geoJSONIntersectionBounds?: GeoJSON.FeatureCollection; // static intersection shape
  setGeoJSON: (
    geojson: GeoJSON.Feature | GeoJSON.FeatureCollection,
    reason?: string,
  ) => GeoJSON.FeatureCollection[];
  setGeoJSONIntersectionBounds: (geojson: GeoJSON.FeatureCollection) => void;
  drawModes: DrawMode[];
  changeDrawMode: (mode: DrawModeValue) => void;
  setDrawModes: (drawModes: DrawMode[]) => void;
  isInEditMode: boolean;
  setEditMode: (shouldEnable: boolean) => void;
  featureLayerIndex: number;
  setFeatureLayerIndex: (newIndex: number) => void;
  activeTool: string;
  changeActiveTool: (newMode: DrawMode) => void;
  setActiveTool: (newToolId: string) => void;
  layers: MapViewLayerProps[];
  getLayer: (layerType: DrawLayerType, layerId: string) => MapViewLayerProps;
  deactivateTool: () => void;
  changeProperties: (geoJSONProperties: GeoJSON.GeoJsonProperties) => void;
  getProperties: () => GeoJSON.GeoJsonProperties;
}

export interface MapDrawToolOptions {
  shouldAllowMultipleShapes?: boolean;
  defaultDrawModes?: DrawMode[];
  defaultGeoJSON?: GeoJSON.FeatureCollection;
  defaultGeoJSONIntersection?: GeoJSON.FeatureCollection;
  defaultGeoJSONIntersectionBounds?: GeoJSON.FeatureCollection;
  defaultGeoJSONIntersectionProperties?: GeoJSON.GeoJsonProperties;
  geoJSONLayerId?: string;
  geoJSONIntersectionLayerId?: string;
  geoJSONIntersectionBoundsLayerId?: string;
}

export const getIcon = (
  selectionType: SelectionType,
): React.ReactElement | null => {
  switch (selectionType) {
    case 'point':
      return <Location />;
    case 'poly':
      return <DrawPolygon />;
    case 'box':
      return <DrawRegion />;
    case 'linestring':
      return <Edit />;
    case 'delete':
      return <Delete />;
    default:
      return null;
  }
};

export const defaultPoint: DrawMode = {
  drawModeId: 'drawtools-point',
  value: DRAWMODE.POINT,
  title: `${WEBMAP_REACT_NAMESPACE}:webmap-react-point`,
  shape: emptyPoint,
  isSelectable: true,
  selectionType: 'point',
};
export const defaultPolygon: DrawMode = {
  drawModeId: 'drawtools-polygon',
  value: DRAWMODE.POLYGON,
  title: `${WEBMAP_REACT_NAMESPACE}:webmap-react-polygon`,
  shape: emptyPolygon,
  isSelectable: true,
  selectionType: 'poly',
};
export const defaultBox: DrawMode = {
  drawModeId: 'drawtools-box',
  value: DRAWMODE.BOX,
  title: `${WEBMAP_REACT_NAMESPACE}:webmap-react-box`,
  shape: emptyBox,
  isSelectable: true,
  selectionType: 'box',
};
export const defaultLineString: DrawMode = {
  drawModeId: 'drawtools-linestring',
  value: DRAWMODE.LINESTRING,
  title: `${WEBMAP_REACT_NAMESPACE}:webmap-react-linestring`,
  shape: emptyLineString,
  isSelectable: true,
  selectionType: 'linestring',
};
export const defaultDelete: DrawMode = {
  drawModeId: 'drawtools-delete',
  value: 'DELETE' as const,
  title: `${WEBMAP_REACT_NAMESPACE}:webmap-react-delete`,
  shape: emptyGeoJSON,
  isSelectable: false,
  selectionType: 'delete',
};

export const defaultModes: DrawMode[] = [
  defaultPoint,
  defaultPolygon,
  defaultBox,
  defaultLineString,
  defaultDelete,
];

export const currentlySupportedDrawModes = [defaultPolygon, defaultDelete];

export const useMapDrawTool = ({
  defaultDrawModes = defaultModes,
  shouldAllowMultipleShapes = false,
  defaultGeoJSON = emptyGeoJSON,
  defaultGeoJSONIntersection = emptyIntersectionShape,
  defaultGeoJSONIntersectionBounds,
  defaultGeoJSONIntersectionProperties = defaultIntersectionStyleProperties,
  geoJSONLayerId = 'draw-layer',
  geoJSONIntersectionLayerId = 'intersection-layer',
  geoJSONIntersectionBoundsLayerId = 'static-layer',
}: MapDrawToolOptions): MapDrawToolProps => {
  // geoJSON feature collections
  const [geoJSON, setGeoJSON] =
    React.useState<GeoJSON.FeatureCollection>(defaultGeoJSON);
  const [geoJSONIntersection, setGeoJSONIntersection] =
    React.useState<GeoJSON.FeatureCollection>(defaultGeoJSONIntersection);
  const [geoJSONIntersectionBounds, setGeoJSONIntersectionBounds] =
    React.useState<GeoJSON.FeatureCollection | undefined>(
      defaultGeoJSONIntersectionBounds,
    );
  // state
  const [drawModes, setDrawModes] = React.useState(defaultDrawModes);
  const [activeTool, setActiveTool] = React.useState<string>('');
  const [drawMode, setDrawMode] = React.useState<DrawModeValue>('');
  const [isInEditMode, setEditMode] = React.useState<boolean>(false);
  const [featureLayerIndex, setFeatureLayerIndex] = React.useState<number>(0);

  const changeProperties = (
    styleProperties: GeoJSON.GeoJsonProperties,
  ): void => {
    // update all modes with new properties
    const newModes: DrawMode[] = drawModes.map((mode) => {
      return {
        ...mode,
        shape: addGeoJSONProperties(mode.shape, styleProperties),
      };
    });
    setDrawModes(newModes);
    // update current geoJSON with new properties
    const updateGeoJSON = addFeatureProperties(
      geoJSON,
      styleProperties,
      featureLayerIndex,
    );

    setGeoJSON(updateGeoJSON);
  };

  const getProperties = (): GeoJSON.GeoJsonProperties => {
    if (!geoJSON || !geoJSON.features.length) {
      return {};
    }

    return geoJSON.features[featureLayerIndex].properties;
  };

  const changeActiveTool = (newMode: DrawMode): void => {
    const shouldDeleteShape = newMode.value === 'DELETE';
    // reset if same tool is selected
    if (newMode.drawModeId === activeTool || shouldDeleteShape) {
      reset(shouldDeleteShape);
      return;
    }

    setActiveTool(newMode.isSelectable ? newMode.drawModeId : '');

    // updates shape
    const isNewSelectedTool = !isGeoJSONFeatureCreatedByTool(
      geoJSON,
      newMode.selectionType,
      featureLayerIndex,
    );
    const shouldUpdateShape =
      !geoJSON.features.length || isNewSelectedTool || !newMode.isSelectable;

    if (shouldUpdateShape) {
      const updatedGeoJSON = changeGeoJSON(
        addSelectionTypeToGeoJSON(newMode.shape, newMode.selectionType),
      )[0];
      setFeatureLayerIndex(updatedGeoJSON.features.length - 1);
    }

    // handle modes and update feature layer index
    setDrawMode(newMode.value);
    setEditMode(!!newMode.value && newMode.isSelectable);
  };

  const changeGeoJSON = (
    updatedGeoJSON: GeoJSON.FeatureCollection | GeoJSON.Feature,
    reason = '',
  ): GeoJSON.FeatureCollection[] => {
    const geoJSONFeatureCollection = getFeatureCollection(
      updatedGeoJSON,
      shouldAllowMultipleShapes,
      geoJSON,
    );
    const newGeoJSON = getGeoJson(
      geoJSONFeatureCollection,
      shouldAllowMultipleShapes,
    );

    if (shouldAllowMultipleShapes) {
      const activeDrawTool = drawModes.find(
        (mode) => mode.drawModeId === activeTool,
      );
      const selectionType = activeDrawTool?.selectionType;

      const newFeatureIndex = moveFeature(
        geoJSON,
        newGeoJSON,
        featureLayerIndex,
        reason,
        selectionType,
      );

      if (newFeatureIndex !== undefined) {
        setFeatureLayerIndex(newFeatureIndex);
      }
    }

    setGeoJSON(newGeoJSON);

    if (geoJSONIntersectionBounds) {
      const newIntersection = createInterSections(
        newGeoJSON,
        geoJSONIntersectionBounds,
        defaultGeoJSONIntersectionProperties,
      );
      setGeoJSONIntersection(newIntersection);
      return [newGeoJSON, newIntersection];
    }
    return [newGeoJSON];
  };

  const onSetGeoJSONIntersectionBounds = (
    newGeoJSON: GeoJSON.FeatureCollection,
  ): void => {
    setGeoJSONIntersectionBounds(newGeoJSON);
    // reset all other geoJSONs
    setGeoJSONIntersection(emptyIntersectionShape);
    setGeoJSON(emptyGeoJSON);
  };

  const onSetDrawMode = (drawMode: DrawModeValue): void => {
    setDrawMode(drawMode);
    const newActiveTool = drawModes.find((mode) => mode.value === drawMode);
    if (newActiveTool) {
      setActiveTool(newActiveTool.drawModeId);
    }
  };

  const removeLastEmptyShape = (): void => {
    const lastFeatureIndex = getLastEmptyFeatureIndex(geoJSON);
    if (lastFeatureIndex !== undefined) {
      const newGeoJSON = {
        ...geoJSON,
        features: geoJSON.features.filter(
          (_feature, index) => index !== lastFeatureIndex,
        ),
      };
      setGeoJSON(newGeoJSON);
      setFeatureLayerIndex(
        newGeoJSON.features.length > 0 ? newGeoJSON.features.length - 1 : 0,
      );
    }
  };

  const deactivateTool = (): void => {
    setEditMode(false);
    setActiveTool('');
    setDrawMode('');
    removeLastEmptyShape();
  };

  const reset = (shouldClearState = false): void => {
    deactivateTool();

    if (shouldClearState) {
      setGeoJSONIntersection(emptyIntersectionShape);
      setGeoJSON(emptyGeoJSON);
      setFeatureLayerIndex(0);
    }
  };

  const getLayer = (
    layerType: DrawLayerType,
    layerId: string,
  ): MapViewLayerProps => {
    // geoJSON
    if (layerType === 'geoJSON') {
      return {
        id: layerId,
        geojson: geoJSON,
        isInEditMode,
        drawMode,
        updateGeojson: changeGeoJSON,
        selectedFeatureIndex: featureLayerIndex,
      };
    }

    // intersections
    return {
      id: layerId,
      geojson:
        layerType === 'geoJSONIntersection'
          ? geoJSONIntersection
          : geoJSONIntersectionBounds,
      isInEditMode: false,
    };
  };

  const layers: MapViewLayerProps[] = [
    ...(geoJSONIntersectionBounds
      ? [
          getLayer(
            'geoJSONIntersectionBounds',
            geoJSONIntersectionBoundsLayerId,
          ),
          getLayer('geoJSONIntersection', geoJSONIntersectionLayerId),
        ]
      : []),
    getLayer('geoJSON', geoJSONLayerId),
  ];

  return {
    geoJSON,
    geoJSONIntersection,
    geoJSONIntersectionBounds,
    setGeoJSON: changeGeoJSON,
    setGeoJSONIntersectionBounds: onSetGeoJSONIntersectionBounds,
    drawModes,
    isInEditMode,
    changeDrawMode: onSetDrawMode,
    setEditMode,
    featureLayerIndex,
    setFeatureLayerIndex,
    activeTool,
    changeActiveTool,
    setActiveTool,
    layers,
    getLayer,
    deactivateTool,
    setDrawModes,
    changeProperties,
    getProperties,
  };
};

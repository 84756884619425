/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const getInitials = (name: string): string => {
  if (!name) {
    return '';
  }
  const nameWithoutEmail = name.split('@')[0];
  const array = nameWithoutEmail.split('.');

  switch (array.length) {
    case 1:
      return array[0].charAt(0).toUpperCase();
    default:
      return (
        array[0].charAt(0).toUpperCase() +
        array[array.length - 1].charAt(0).toUpperCase()
      );
  }
};

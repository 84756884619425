/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  uiTypes,
  mapSelectors,
  CoreAppStore,
  uiSelectors,
  useSetupDialog,
} from '@opengeoweb/store';
import { LegendDialog } from '@opengeoweb/webmap-react';

interface LegendConnectProps {
  showMapId?: boolean;
  source?: uiTypes.Source;
  mapId?: string;
  multiLegend?: boolean;
}

const LegendConnectComponent: React.FC<LegendConnectProps> = ({
  showMapId = false,
  source = 'app',
  mapId: initialMapId = null!,
  multiLegend = false,
}: LegendConnectProps) => {
  const legendType = multiLegend ? `legend-${initialMapId}` : `legend`;

  const mapIdStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, legendType),
  );

  const [mapId, setMapId] = React.useState(initialMapId);

  React.useEffect(() => {
    setMapId(mapIdStore);
  }, [mapIdStore]);

  const mapLayers = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapLayers(store, mapId),
  );

  const { dialogOrder, isDialogOpen, uiSource, setDialogOrder, onCloseDialog } =
    useSetupDialog(legendType, source);

  return (
    <LegendDialog
      layers={mapLayers}
      isOpen={isDialogOpen}
      onClose={onCloseDialog}
      mapId={mapId}
      showMapId={showMapId}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      source={uiSource}
    />
  );
};

/**
 * Legend component connected to the store displaying a legend for every layer shown on the active map Id
 * Please note that in order to use this and open/close the dialog, every map on the screen that should be able to show
 * a dialog should have the <LegendMapButtonConnect/> which is used to focus the correct map and open the dialog
 *
 * Expects the following props:
 * @param {boolean} showMapId (optional) showMapId: boolean - show the id of the map in the dialog title of which the legend is shown in te dialog
 * @param {uiTypes.Source} source (optional) source: uiTypes.Source - default is 'app', 'module' makes sure it will be shown on top of the module

 * ``` <LegendConnect legendDialogId={legendDialogId} />```
 */
const LegendConnect: React.FC<LegendConnectProps> = ({
  showMapId = false,
  ...props
}: LegendConnectProps) => (
  <LegendConnectComponent showMapId={showMapId} {...props} />
);

export default LegendConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { TimeSeriesModuleState } from './types';
import { setUserAddedEDRServices } from '../utils/localStorage';
import { timeSeriesActions } from '.';

export const timeseriesListener =
  createListenerMiddleware<TimeSeriesModuleState>();

timeseriesListener.startListening({
  matcher: isAnyOf(
    timeSeriesActions.loadUserAddedTimeSeriesServices,
    timeSeriesActions.upsertService,
    timeSeriesActions.removeService,
    timeSeriesActions.registerTimeSeriesPreset,
  ),
  effect: async (action, listenerApi) => {
    setUserAddedEDRServices(listenerApi.getState().timeSeries!.services || []);
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  useSetupDialog,
  uiTypes,
  CoreAppStore,
  MapPreset,
} from '@opengeoweb/store';
import { useAuthenticationContext } from '@opengeoweb/authentication';
import {
  ConfirmationOptions,
  Position,
  useConfirmationDialog,
  usePrevious,
} from '@opengeoweb/shared';
import {
  FormMode,
  getProductFormMode,
  ProductCanbe,
  ProductFromBackend,
  AviationProduct,
} from '@opengeoweb/sigmet-airmet';
import { useDispatch, useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { PublicWarningsFormDialog } from './PublicWarningsFormDialog';
import { publicWarningDialogType } from '../../store/publicWarningForm/utils';
import { PublicWarningsFormConnect } from '../PublicWarningsForm';
import {
  getPublicWarning,
  getPublicWarningFormState,
  getSelectedPublicIsFormDirty,
  getSelectedWarningId,
  getWarningType,
} from '../../store/publicWarningForm/selectors';
import {
  publicWarningFormActions,
  TAKEOVER_MESSAGE,
} from '../../store/publicWarningForm/reducer';
import { useWarningsTranslation } from '../../utils/i18n';
import { AreaObjectLoaderConnect } from '../AreaObjectLoader/AreaObjectLoaderConnect';

import {
  flattenPublicWarning,
  getEmptyAirmetWarning,
  getEmptySigmetWarning,
  transformAirmetToProductFormat,
  transformSigmetToProductFormat,
} from '../../utils/sig-airmet-util';
import {
  useAirmetList,
  useProductConfig,
  useSigmetList,
} from '../../api/aviation-api/hooks';
import {
  AviationWarningDetails,
  Warning,
} from '../../store/publicWarningForm/types';
import { useMutatePublicWarningEditor } from '../../api/warning-api/hooks';
import { getPublicWarningList } from '../../api/warning-api/api';
import { useWarnings } from '../../api/api-utils';

export const warningFormConfirmationOptions = (
  t: TFunction,
): ConfirmationOptions => {
  return {
    cancelLabel: t('close-dialog-cancel'),
    title: t('close-warning-dialog-title'),
    description: t('close-dialog-description'),
    confirmLabel: t('close-dialog-confirm'),
    catchOnCancel: true,
  };
};

export const isAlreadyEdited = (
  publicWarningEditor: string | undefined,
  username: string | undefined,
): boolean => {
  return (
    publicWarningEditor !== undefined &&
    publicWarningEditor !== '' &&
    publicWarningEditor !== null &&
    publicWarningEditor !== username
  );
};

export const PublicWarningsFormDialogConnect: React.FC<{
  bounds?: string;
  source?: uiTypes.Source;
  startPosition?: Position;
  defaultMapPreset?: MapPreset;
}> = ({ bounds, source = 'app', startPosition, defaultMapPreset }) => {
  const {
    dialogOrder,
    setDialogOrder,
    isDialogOpen,
    uiSource,
    onCloseDialog,
    uiIsLoading,
  } = useSetupDialog(publicWarningDialogType, source);
  const { t } = useWarningsTranslation();
  const dispatch = useDispatch();
  const confirmDialog = useConfirmationDialog();

  const formAction = useSelector((store: CoreAppStore) =>
    getPublicWarningFormState(store),
  );

  const isFormDirty = useSelector((store: CoreAppStore) =>
    getSelectedPublicIsFormDirty(store),
  );

  const publicWarningState = useSelector(getPublicWarning);
  const { status, editor } = publicWarningState || {};

  const { combinedWarnings, refetchWarnings, isFetching } = useWarnings(true);

  const [hasFetched, setHasFetched] = React.useState(isFetching);

  React.useEffect(() => {
    if (isFetching) {
      setHasFetched(true);
    }
  }, [isFetching]);

  const selectedWarningId = useSelector(getSelectedWarningId);

  const selectedWarningObject = useSelector(getPublicWarning);

  const warningType = useSelector(getWarningType);

  const selectedWarning = combinedWarnings?.find(
    (w) => w.id === selectedWarningId,
  );

  const { auth } = useAuthenticationContext();
  const isEditor = selectedWarning?.editor === auth?.username || false;

  const shouldGiveEditorWarning = React.useCallback(
    (newEditor?: string, oldEditor?: string): void => {
      const oldEditorIsYou = oldEditor && oldEditor === auth?.username;
      if (oldEditorIsYou && newEditor !== oldEditor) {
        dispatch(
          publicWarningFormActions.setFormError({
            severity: 'warning',
            message: TAKEOVER_MESSAGE,
          }),
        );
      }
    },
    [dispatch, auth?.username],
  );

  const previousSelectedWarning = usePrevious(selectedWarning);
  const refetchCurrentEditor = async (): Promise<string> => {
    const newWarnings = await getPublicWarningList(auth!);
    const newSelectedWarning = newWarnings?.find(
      (w) => w.id === selectedWarningId,
    );
    return newSelectedWarning?.editor || '';
  };

  React.useEffect(() => {
    if (editor !== selectedWarning?.editor && status !== 'TODO') {
      void setIsEditor(editor !== auth?.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  React.useEffect(() => {
    if (selectedWarning?.id !== previousSelectedWarning?.id) {
      dispatch(publicWarningFormActions.removeFormError());
    }
  }, [selectedWarning?.id, previousSelectedWarning?.id, dispatch]);

  React.useEffect(() => {
    // Only give editor warning when the change in selectedWarning is from a refetch
    if (hasFetched) {
      setHasFetched(false);
      shouldGiveEditorWarning(
        selectedWarning?.editor,
        previousSelectedWarning?.editor,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarning?.editor]);

  const updatePublicWarningEditor = useMutatePublicWarningEditor();

  const setIsEditor = async (isReadOnly: boolean): Promise<boolean> => {
    const username = auth?.username || '';

    // make sure to get the latest changes if you are editing an existing warning
    const latestEditor = await refetchCurrentEditor();

    if (
      !selectedWarningId ||
      (!isReadOnly && username === latestEditor) ||
      (isReadOnly && latestEditor !== username)
    ) {
      // currently editing a new warning or
      // correct editor is already set or
      // someone else is editor so only have to set to readonly mode
      // No need for a BE call
      dispatch(
        publicWarningFormActions.setFormValues({
          formState: isReadOnly ? 'readonly' : '',
        }),
      );
      // If the editor was changed in a different window, refetch the warnings
      if (latestEditor !== selectedWarning?.editor) {
        void refetchWarnings();
      }
      return true;
    }

    // We are taking over from another user, first confirm this is what they want
    if (!isReadOnly && isAlreadyEdited(latestEditor, username)) {
      await confirmDialog({
        title: t('warning-edit-mode-title'),
        description: t('warning-edit-mode-description'),
        confirmLabel: t('warning-edit-mode-confirm'),
        cancelLabel: t('warning-dialog-cancel'),
      });
    }
    dispatch(
      publicWarningFormActions.setFormValues({
        formState: isReadOnly ? 'readonly' : '',
      }),
    );
    updatePublicWarningEditor.mutate({
      id: selectedWarningId,
      isEditor: !isReadOnly,
    });
    return true;
  };

  const onChangeFormMode = (isReadOnly: boolean): void => {
    if (isReadOnly && isFormDirty) {
      void confirmDialog({
        title: t('warning-view-mode-title'),
        description: t('warning-view-mode-description'),
        confirmLabel: t('warning-view-mode-confirm'),
        cancelLabel: t('warning-dialog-cancel'),
      }).then(async () => {
        await setIsEditor(isReadOnly);
      });
    } else {
      void setIsEditor(isReadOnly);
    }
  };

  const closeDialog = async (): Promise<void> => {
    if (isFormDirty) {
      const mayProceed = await confirmDialog(warningFormConfirmationOptions(t))
        .then(() => true)
        .catch(() => false);

      if (!mayProceed) {
        return;
      }
    }
    onCloseDialog();
  };

  const { data: airmetList } = useAirmetList(true);
  const { data: sigmetList } = useSigmetList(true);

  const selectedSigmet = sigmetList?.find(
    (sigmet) => sigmet.uuid === selectedWarningId,
  );

  const selectedAirmet = airmetList?.find(
    (airmet) => airmet.uuid === selectedWarningId,
  );

  const updatedWarningType = selectedSigmet
    ? 'sigmet'
    : (selectedAirmet && 'airmet') || warningType;

  const { data: aviationConfig } = useProductConfig(
    updatedWarningType,
    !isDialogOpen,
  );

  const selectedSigmetProduct =
    selectedSigmet && aviationConfig
      ? transformSigmetToProductFormat(selectedSigmet)
      : getEmptySigmetWarning();

  const selectedAirmetProduct =
    selectedAirmet && aviationConfig
      ? transformAirmetToProductFormat(selectedAirmet)
      : getEmptyAirmetWarning();

  const selectedAviationProduct: Partial<AviationWarningDetails> | undefined =
    React.useMemo(() => {
      if (
        ['sigmet', 'airmet'].includes(selectedWarningObject?.type || '') &&
        aviationConfig
      ) {
        return flattenPublicWarning(selectedWarningObject as Warning);
      }

      if (updatedWarningType === 'sigmet') {
        return selectedSigmetProduct as Partial<AviationWarningDetails>;
      }

      if (updatedWarningType === 'airmet') {
        return selectedAirmetProduct as Partial<AviationWarningDetails>;
      }

      return undefined;
    }, [
      selectedWarningObject,
      updatedWarningType,
      selectedSigmetProduct,
      selectedAirmetProduct,
      aviationConfig,
    ]);

  const productCanBe =
    updatedWarningType === 'public'
      ? (['DRAFTED', 'DISCARDED', 'PUBLISHED'] as ProductCanbe[])
      : selectedSigmet?.canbe || selectedAirmet?.canbe;

  const mode = getProductFormMode(
    productCanBe,
    !selectedWarningId
      ? null
      : (selectedAviationProduct as unknown as ProductFromBackend),
  ) as FormMode;

  // Readonly also in case you are not creating a new warning and you are not set as the editor
  const isReadOnly =
    formAction === 'readonly' || (!isEditor && status !== undefined);

  // Only show toggle if in draft status
  const shouldHideFormToggleMode =
    status !== 'DRAFT' && status !== 'DRAFT_PUBLISHED';

  return (
    <>
      <PublicWarningsFormDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        order={dialogOrder}
        onMouseDown={setDialogOrder}
        source={uiSource}
        bounds={bounds}
        startPosition={startPosition}
        isLoading={uiIsLoading}
        isReadOnly={isReadOnly}
        onChangeFormMode={onChangeFormMode}
        shouldHideFormMode={shouldHideFormToggleMode}
        publicWarningEditor={selectedWarning?.editor || undefined}
        selectedAviationProduct={
          selectedAviationProduct as AviationProduct | undefined
        }
        warningType={updatedWarningType}
      >
        <PublicWarningsFormConnect
          editor={selectedWarning?.editor || undefined}
          defaultMapPreset={defaultMapPreset}
          selectedAviationProduct={
            selectedAviationProduct as AviationProduct | undefined
          }
          warningType={updatedWarningType}
          mode={mode}
          onFormSubmit={() => refetchWarnings()}
        />
      </PublicWarningsFormDialog>
      <AreaObjectLoaderConnect />
    </>
  );
};

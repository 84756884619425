/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { COLOR_MAP } from '../../constants';
import { SelectParameter } from '../TimeSeries/types';

export const ogcParameters: SelectParameter[] = [
  {
    propertyName: 'air_pressure_at_sea_level',
    unit: 'hPa',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.air_pressure_at_sea_level,
  },
  {
    propertyName: 'air_temperature__at_2m',
    unit: 'C',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.air_temperature__at_2m,
  },
  {
    propertyName: 'air_temperature__max_at_2m',
    unit: 'C',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.air_temperature__max_at_2m,
  },
  {
    propertyName: 'air_temperature__min_at_2m',
    unit: 'C',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.air_temperature__min_at_2m,
  },
  {
    propertyName: 'cloud_area_fraction',
    unit: '0to1',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.cloud_area_fraction,
  },
  {
    propertyName: 'cloud_base_altitude',
    unit: 'm',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.cloud_base_altitude,
  },
  {
    propertyName: 'cloud_top_altitude',
    unit: 'm',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.cloud_top_altitude,
  },
  {
    propertyName: 'dew_point_temperature__at_2m',
    unit: 'C',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.dew_point_temperature__at_2m,
  },
  {
    propertyName: 'precipitation_flux',
    unit: 'mm/hr',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.precipitation_flux,
  },
  {
    propertyName: 'graupel_flux',
    unit: 'mm/hr',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.graupel_flux,
  },
  {
    propertyName: 'snowfall_flux',
    unit: 'mm/hr',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.snowfall_flux,
  },
  {
    propertyName: 'high_type_cloud_area_fraction',
    unit: '0to1',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.high_type_cloud_area_fraction,
  },
  {
    propertyName: 'medium_type_cloud_area_fraction',
    unit: '0to1',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.medium_type_cloud_area_fraction,
  },
  {
    propertyName: 'low_type_cloud_area_fraction',
    unit: '0to1',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.low_type_cloud_area_fraction,
  },
  {
    propertyName: 'relative_humidity__at_2m',
    unit: '%',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.relative_humidity__at_2m,
  },
  {
    propertyName: 'wind_at_10m',
    unit: 'm/s',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.wind_at_10m,
  },
  {
    propertyName: 'wind_speed_of_gust__at_10m',
    unit: 'm/s',
    plotType: 'line',
    serviceId: 'knmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.wind_speed_of_gust__at_10m,
  },
  {
    propertyName: 'Pressure',
    unit: 'hPa',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.Pressure,
  },
  {
    propertyName: 'GeopHeight',
    unit: 'm2 s-2',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.GeopHeight,
  },
  {
    propertyName: 'Temperature',
    unit: 'C',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.Temperature,
  },
  {
    propertyName: 'DewPoint',
    unit: 'C',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.DewPoint,
  },
  {
    propertyName: 'Humidity',
    unit: '%',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.Humidity,
  },
  {
    propertyName: 'WindDirection',
    unit: 'Deg',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.WindDirection,
  },
  {
    propertyName: 'WindSpeedMS',
    unit: 'm s-1',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.WindSpeedMS,
  },
  {
    propertyName: 'PrecipitationAmount',
    unit: 'mm',
    plotType: 'bar',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.PrecipitationAmount,
  },
  {
    propertyName: 'TotalCloudCover',
    unit: '0to1',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.TotalCloudCover,
  },
  {
    propertyName: 'MiddleAndLowCloudCover',
    unit: '%',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.MiddleAndLowCloudCover,
  },
  {
    propertyName: 'LandSeaMask',
    unit: '0to1',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.LandSeaMask,
  },
  {
    propertyName: 'WeatherSymbol3',
    unit: 'No Unit',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.WeatherSymbol3,
  },
  {
    propertyName: 'Precipitation1h',
    unit: 'mm',
    plotType: 'bar',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.Precipitation1h,
  },
  {
    propertyName: 'WindGust',
    unit: 'm s-1',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.WindGust,
  },
  {
    propertyName: 'PrecipitationForm2',
    unit: 'JustAnumber',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.PrecipitationForm2,
  },
  {
    propertyName: 'WindGust2',
    unit: 'm s-1',
    plotType: 'line',
    serviceId: 'fmi',
    collectionId: 'collectionOGC',
    color: COLOR_MAP.WindGust2,
  },
];

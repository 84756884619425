/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { MenuItem } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormSelect } from '@opengeoweb/form-fields';

import { AviationPhenomenon, ConfigurableFormFieldProps } from '../../../types';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { getFieldLabel, triggerValidations } from '../utils';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

const Phenomenon: React.FC<
  ConfigurableFormFieldProps & { phenomenaList: AviationPhenomenon[] }
> = ({
  isDisabled,
  isReadOnly,
  onChange = (): void => {},
  phenomenaList,
}: ConfigurableFormFieldProps & { phenomenaList: AviationPhenomenon[] }) => {
  const { t } = useSigmetAirmetTranslation();
  const { getValues, setValue, trigger } = useFormContext();
  const { isShortTest } = useShortTestHelpers();
  const label = getFieldLabel(t('phenomenon'), isReadOnly!);
  const fieldsDisabled = isDisabled || isShortTest();

  const handleSinglePhenomenaSelection = (): void => {
    const defaultPhenomenon = getValues('phenomenon');
    const isValidPhenomenon = phenomenaList.some(
      (p) => p.code === defaultPhenomenon,
    );

    // Only auto-select if there is exactly one phenomenon available
    if (!isValidPhenomenon && phenomenaList.length === 1) {
      setValue('phenomenon', phenomenaList[0]?.code || '');
    }
  };

  if (phenomenaList.length === 1) {
    handleSinglePhenomenaSelection();
  }

  return (
    <ProductFormFieldLayout
      title={t('phenomenon-title')}
      sx={{
        '.MuiFormHelperText-root.Mui-error': {
          marginBottom: '-22px',
          marginTop: '2px',
        },
      }}
    >
      <ReactHookFormSelect
        name="phenomenon"
        label={label}
        rules={{ required: true }}
        disabled={fieldsDisabled}
        isReadOnly={isReadOnly}
        size="small"
        data-testid="phenomenon"
        onChange={(): Promise<boolean> | void => {
          if (getValues('movementType') === 'NO_VA_EXP') {
            setValue('movementType', null);
          } else if (getValues('phenomenon') === 'RDOACT_CLD') {
            // By definition, phenomenon 'RDOACT_CLD'shall not ever move but stay stationary
            setValue('movementType', 'STATIONARY');

            // Also by definition, phenomenon 'RDOACT_CLD' is strictly defined to always span from surface to upper level
            // with no lower explicit level values defined, hence level mode shall be set to 'BETW_SFC' instead of 'BETW'.
            setValue('levelInfoMode', 'BETW_SFC');
          }

          triggerValidations(
            ['validDateStart', 'validDateEnd'],
            getValues,
            trigger,
          );
          onChange();
        }}
      >
        {phenomenaList.map((phenomenon) => (
          <MenuItem value={phenomenon.code} key={phenomenon.code}>
            {phenomenon.description}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
    </ProductFormFieldLayout>
  );
};

export default Phenomenon;

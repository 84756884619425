/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import {
  UseTranslationResponse,
  initReactI18next,
  useTranslation,
} from 'react-i18next';
import { CORE_NAMESPACE, coreTranslations } from '@opengeoweb/core';
import {
  TIMESLIDER_NAMESPACE,
  timesliderTranslations,
} from '@opengeoweb/timeslider';
import {
  WEBMAP_REACT_NAMESPACE,
  webmapReactTranslations,
} from '@opengeoweb/webmap-react';
import capTrans from '../../../locales/cap.json';

export const CAP_NAMESPACE = 'cap';

export const initCapI18n = (): typeof i18n => {
  void i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    ns: CAP_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [CAP_NAMESPACE]: capTrans.en,
        [CORE_NAMESPACE]: coreTranslations.en,
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.en,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.en,
      },
      fi: {
        [CAP_NAMESPACE]: capTrans.fi,
        [CORE_NAMESPACE]: coreTranslations.fi,
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.fi,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.fi,
      },
      nl: {
        [CAP_NAMESPACE]: capTrans.nl,
        [CORE_NAMESPACE]: coreTranslations.nl,
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.nl,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.nl,
      },
    },
  });
  return i18n;
};

export const useCapTranslation = (): UseTranslationResponse<
  typeof CAP_NAMESPACE,
  typeof i18n
> => useTranslation(CAP_NAMESPACE);

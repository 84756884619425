/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { debounce, InputAdornment, TextField } from '@mui/material';
import { SearchFieldButton } from '@opengeoweb/shared';
import { useTimeseriesTranslation } from '../../../utils/i18n';

interface SearchBarProps {
  searchTerm: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  handleSearch,
}) => {
  const { t } = useTimeseriesTranslation();
  const [localValue, setLocalValue] = React.useState(searchTerm);

  const handleSearchDebounced = React.useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        handleSearch(event);
      }, 500),
    [handleSearch],
  );

  const resetEvent = {
    target: { value: '' },
  } as unknown as React.ChangeEvent<HTMLInputElement>;

  return (
    <TextField
      data-testid="locationDropdownSearchBar"
      variant="filled"
      label={t('timeseries-location-dropdown-search')}
      value={localValue}
      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
        handleSearchDebounced(event);
        setLocalValue(event.target.value);
      }}
      autoFocus
      fullWidth
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <SearchFieldButton
                setPopupIsOpen={(): void => {}}
                localSearchString={localValue}
                onClickClear={(): void => {
                  setLocalValue('');
                  handleSearchDebounced(resetEvent);
                }}
              />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

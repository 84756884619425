/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ClickAwayListener, MenuList, Paper } from '@mui/material';
import React from 'react';
import { Admin } from '@opengeoweb/theme';
import { Avatar } from '../Avatar';
import { CustomIconButton } from '../CustomIconButton';

const styles = {
  container: {
    position: 'absolute',
    top: 38,
    right: 20,
    width: 256,
    borderRadius: 0,
    boxShadow: 5,
    backgroundColor: 'geowebColors.background.surface',
  },
  userMenu: {
    padding: 0,
    '& > li': {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 2,
      paddingRight: 2,
    },
  },
};

interface UserMenuProps {
  isOpen?: boolean;
  onToggle?: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
  initials?: string;
  isAdmin?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = ({
  isOpen = false,
  onToggle = (): void => {},
  onClose = (): void => {},
  children,
  initials,
  isAdmin = false,
}: UserMenuProps) => {
  return (
    <>
      <CustomIconButton
        data-testid="userButton"
        onClick={onToggle}
        sx={{
          '&.MuiButtonBase-root': {
            background: 'transparent',
            '&:hover': {
              border: 'none',
              background: 'transparent',
            },
            ...(isAdmin && {
              svg: {
                width: 32,
                height: 32,
              },
            }),
          },
        }}
        size="medium"
      >
        {!isAdmin ? (
          <Avatar>{initials}</Avatar>
        ) : (
          <>
            <Admin />
            {initials && (
              <div
                style={{
                  position: 'absolute',
                  fontSize: '14px',
                  color: '#FFF',
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                }}
              >
                {initials}
              </div>
            )}
          </>
        )}
      </CustomIconButton>

      {isOpen && (
        <Paper sx={styles.container} data-testid="userMenu">
          <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
            <MenuList sx={styles.userMenu} variant="menu">
              {children}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      )}
    </>
  );
};

export default UserMenu;

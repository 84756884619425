/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box } from '@mui/material';
import { AllChipConnect, ServiceChipConnect } from './ServiceChipConnect';
import { layerSelectTypes } from '../../store';

interface ServiceListProps {
  activeServicesById: layerSelectTypes.ActiveServiceObjectEntities;
  setHeight: (height: number) => void;
}

export const ServiceList: React.FC<ServiceListProps> = ({
  activeServicesById,
  setHeight,
}: ServiceListProps) => {
  const ref = React.useRef<HTMLElement>(null);
  const [serviceListHeight, setServiceListHeight] = React.useState(0);
  const clientHeight =
    ref && ref.current && ref.current.clientHeight && ref.current.clientHeight;

  React.useEffect(() => {
    if (ref && ref.current && ref.current.clientHeight) {
      setServiceListHeight(ref.current.clientHeight);
    }
  }, [activeServicesById, clientHeight]);

  React.useEffect(() => {
    setHeight(serviceListHeight);
  }, [serviceListHeight, setHeight]);

  const activeServicesIds = Object.keys(activeServicesById);
  const activeServices = Object.values(activeServicesById);

  const isAllSelected = activeServices.every(
    (activeService) => activeService.enabled,
  );

  return (
    <Box
      sx={{
        marginTop: '8px',
        width: '100%',
        overflow: 'hidden',
      }}
      data-testid="serviceList"
    >
      <Box
        sx={{
          width: '100%',
          display: 'block',
          alignItems: 'center',
          float: 'left',
          paddingLeft: '48px',
          paddingRight: '40px',
          marginBottom: '8px',
          fontSize: '0.875rem',
        }}
        ref={ref}
      >
        {activeServicesIds?.length && (
          <AllChipConnect isAllSelected={isAllSelected} />
        )}
        {activeServices.map((service) => {
          return (
            <ServiceChipConnect
              key={service.serviceUrl}
              service={service}
              isAllSelected={isAllSelected}
            />
          );
        })}
      </Box>
    </Box>
  );
};

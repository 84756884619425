/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import { queryWMSLayers } from '@opengeoweb/webmap';
import { serviceActions } from './reducer';
import {
  FetchInitialServicesPayload,
  SetLayersForServicePayload,
  WebMapStateModuleState,
} from '../types';

export const serviceListener =
  createListenerMiddleware<WebMapStateModuleState>();

export const fetchService = async (
  service: SetLayersForServicePayload,
  listenerApi: ListenerEffectAPI<
    WebMapStateModuleState,
    ThunkDispatch<WebMapStateModuleState, unknown, AnyAction>,
    unknown
  >,
): Promise<void> => {
  try {
    const layers = await queryWMSLayers(service.serviceUrl);

    listenerApi.dispatch(
      serviceActions.serviceSetLayers({
        ...service,
        layers,
      }),
    );
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.warn(error.message);
    }
  }
};

export const fetchInitialServices = async (
  payload: FetchInitialServicesPayload,
  listenerApi: ListenerEffectAPI<
    WebMapStateModuleState,
    ThunkDispatch<WebMapStateModuleState, unknown, AnyAction>,
    unknown
  >,
): Promise<void> => {
  if (!payload || !payload.services) {
    return;
  }
  try {
    await Promise.all(
      payload.services.map(({ url, ...service }) =>
        fetchService(
          {
            ...service,
            serviceUrl: url,
          } as SetLayersForServicePayload,
          listenerApi,
        ),
      ),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }
};

serviceListener.startListening({
  actionCreator: serviceActions.fetchInitialServices,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    await fetchInitialServices(payload, listenerApi);
  },
});

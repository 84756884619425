/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { produce } from 'immer';
import { createSelector } from '@reduxjs/toolkit';
import { CoreAppStore } from '../../types';
import {
  SyncGroupViewState,
  SynchronizationGroups,
  SynchronizationSources,
  SyncType,
} from './types';

const emptyState: SyncGroupViewState = {
  timeslider: {
    groups: [],
    sourcesById: [],
  },
  zoompane: {
    groups: [],
    sourcesById: [],
  },
  level: {
    groups: [],
    sourcesById: [],
  },
};

export const groupTypes = [
  {
    title: 'Timeslider',
    syncType: 'SYNCGROUPS_TYPE_SETTIME' as SyncType,
    groupType: 'timeslider',
  },
  {
    title: 'Zoompane',
    syncType: 'SYNCGROUPS_TYPE_SETBBOX' as SyncType,
    groupType: 'zoompane',
  },
];

/*
    _____ Creates viewState  _____
      - Runs Each time viewState is created:
      - Fill arrays inside initialState with objects containing new values from Redux
*/
export const createSyncGroupViewState = (
  groups: SynchronizationGroups,
  sources: SynchronizationSources,
): SyncGroupViewState => {
  const initialState = produce(emptyState, (draft) => {
    /*
      _____ 1. Fill groups _____
        1. Goes through all groups by ID
        2. Collects original timeslider or zoompane values into an array
        3. Sorts and adds selected sources to ViewState based on ID type
    */
    Object.keys(groups.byId).forEach((id) => {
      const groupType = groupTypes.find(
        (object) => object.syncType === groups.byId[id].type,
      )?.groupType;
      if (groupType) {
        draft[groupType].groups.push({
          id,
          selected: groups.byId[id].targets.allIds,
        });
      }
    });
    /*
      _____ 2. Fill sourcesById _____
        1. Loop though sources and add source to each group type
    */
    Object.keys(sources.byId).forEach((id) => {
      groupTypes.forEach(({ syncType, groupType }) => {
        if (sources.byId[id].types.includes(syncType)) {
          draft[groupType].sourcesById.push({
            id,
            name: id,
          });
        }
      });
    });
  });
  return initialState;
};

const groups = (store: CoreAppStore): SynchronizationGroups =>
  store.syncGroups!.groups;
const sources = (store: CoreAppStore): SynchronizationSources =>
  store.syncGroups!.sources;
export const createSyncGroupViewStateSelector = createSelector(
  groups,
  sources,
  createSyncGroupViewState,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { MosaicNode, Mosaic } from 'react-mosaic-component';
import { Box, Typography } from '@mui/material';

import 'react-mosaic-component/react-mosaic-component.css';
import './WorkspaceView.css';
import { WebAssetOff } from '@opengeoweb/theme';
import { isString } from 'lodash';
import { useWorkspaceTranslation } from '../../utils/i18n';
import {
  WorkspacePreset,
  WorkspaceLookupFunctionType,
} from '../../store/workspace/types';

import WorkspaceTileConnect from './WorkspaceTileConnect';

export interface WorkspaceViewProps {
  screenConfig: WorkspacePreset;
  componentsLookUp: WorkspaceLookupFunctionType;
  updateViews: (currentNode: MosaicNode<string>) => void;
  activeWindowId: string;
  setActiveWindowId: (activeWindowId: string) => void;
  isWorkspaceLoading?: boolean;
}

const WorkspaceView: React.FC<WorkspaceViewProps> = ({
  screenConfig,
  componentsLookUp,
  updateViews,
  activeWindowId,
  setActiveWindowId,
  isWorkspaceLoading = false,
}) => {
  const { t } = useWorkspaceTranslation();
  const EmptyView = !isWorkspaceLoading && (
    <Box
      sx={{
        backgroundColor: 'geowebColors.background.surface',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <WebAssetOff sx={{ fontSize: '150px', margin: '20px' }} />
      <Typography sx={{ color: 'geowebColors.typographyAndIcons.text' }}>
        {t('geoweb-welcome')}
      </Typography>
    </Box>
  );

  useControlActiveWindowId(screenConfig, activeWindowId, setActiveWindowId);

  const getNewViewId = (element: HTMLDivElement, viewId: string): string => {
    if (element instanceof Element) {
      // Check if a windows close button was clicked
      const target = element.closest('#close-window-button');
      if (target) {
        const viewIds = screenConfig.views.allIds;
        const newViewId = viewIds.length === 0 ? undefined : activeWindowId;
        return newViewId!;
      }
    }
    return viewId;
  };

  const handleClickOnWindow = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    viewId: string,
  ): void => {
    const { target } = event;
    const newViewId = getNewViewId(target as HTMLDivElement, viewId);
    if (newViewId !== activeWindowId) {
      setActiveWindowId(newViewId);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        '.geoweb-mosaic .mosaic-window-title': {
          fontWeight: 'bold',
          fontSize: '12px',
          userSelect: 'none',
        },
        '.geoweb-mosaic .mosaic-window-body': {
          backgroundColor: 'geowebColors.background.surfaceApp',
        },
      }}
    >
      <Mosaic<string>
        className="geoweb-mosaic"
        zeroStateView={EmptyView || undefined}
        initialValue={screenConfig.mosaicNode}
        renderTile={(viewId, path): React.ReactElement => {
          const windowIsActive = viewId === activeWindowId;
          return (
            <Box
              onClick={(event): void => handleClickOnWindow(event, viewId)}
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              sx={(theme) => ({
                '> *': { height: '100%', width: '100%' },
                '.mosaic-window .mosaic-window-toolbar': {
                  backgroundColor: windowIsActive
                    ? theme.palette.geowebColors.workspace.activeWindow
                    : theme.palette.geowebColors.workspace.inactiveWindow,
                },
                '.mosaic-window-toolbar .MuiSvgIcon-root': {
                  fill: windowIsActive
                    ? theme.palette.geowebColors.buttons.flat.default.color
                    : theme.palette.geowebColors.buttons.flat.disabled.color,
                },
                '#close-window-button .MuiSvgIcon-root': {
                  fill: theme.palette.geowebColors.buttons.flat.disabled.color,
                },
                '.mosaic-window-toolbar .mosaic-window-title': {
                  color: windowIsActive
                    ? theme.palette.geowebColors.typographyAndIcons.text
                    : theme.palette.geowebColors.typographyAndIcons
                        .inactiveText,
                },
              })}
            >
              <WorkspaceTileConnect
                mosaicNodeId={viewId}
                path={path}
                componentsLookUp={componentsLookUp}
              />
            </Box>
          );
        }}
        onRelease={(node: MosaicNode<string> | null): void =>
          updateViews(node!)
        }
        resize={{
          minimumPaneSizePercentage:
            screenConfig.minimumPaneSizePercentage || 20,
        }}
      />
    </Box>
  );
};

export default WorkspaceView;

export const useControlActiveWindowId = (
  screenConfig: WorkspacePreset,
  activeWindowId: string | undefined,
  setActiveWindowId: (activeWindowId: string) => void,
): void => {
  // When screenConfig.id changes it means a new preset was chosen.
  React.useEffect(() => {
    if (screenConfig.id !== '' && screenConfig.mosaicNode) {
      let { mosaicNode } = screenConfig;
      while (!isString(mosaicNode)) {
        mosaicNode = mosaicNode.first;
      }
      setActiveWindowId(mosaicNode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenConfig.id]);

  // If active window is no longer in screenConfig then set active window to first window
  React.useEffect(() => {
    const viewIds = screenConfig.views.allIds;
    if (
      activeWindowId !== undefined &&
      viewIds &&
      viewIds.length > 0 &&
      !viewIds.includes(activeWindowId)
    ) {
      setActiveWindowId(viewIds[0]);
    }
  }, [
    activeWindowId,
    screenConfig.views,
    screenConfig.views.allIds,
    setActiveWindowId,
  ]);
};

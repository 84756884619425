/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PROJECTION } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import type WMLayer from './WMLayer';
import {
  isDefined,
  URLEncode,
  getMapDimURL,
  dateToISO8601,
  WMJScheckURL,
  debugLogger,
  DebugType,
} from './WMJSTools';
import { proj4 } from './WMJSExternalDependencies';
import {
  WMJSMAP_LONLAT_EPSGCODE,
  WMProj4Defs,
  WMSVersion,
} from './WMConstants';
import type { Dimension, LinkedInfo, WMPosition } from './types';
import type IWMJSMap from './IWMJSMap';
import { WMProjectionWH } from './WMProjection';
import { Bbox } from './WMBBOX';
import { translateKeyOutsideComponents } from '../utils/i18n';

export const getBBOXandProjString = (
  layer: WMLayer,
  projection: WMProjectionWH,
): string => {
  let request = '';
  const { srs, bbox } = projection;

  if (
    layer.version === WMSVersion.version100 ||
    layer.version === WMSVersion.version111
  ) {
    request += `SRS=${URLEncode(srs)}&`;
    request += `BBOX=${bbox.left},${bbox.bottom},${bbox.right},${bbox.top}&`;
  }
  if (layer.version === WMSVersion.version130) {
    request += `CRS=${URLEncode(srs)}&`;

    if (
      srs === PROJECTION.EPSG_4326.value &&
      layer.wms130bboxcompatibilitymode === false
    ) {
      request += `BBOX=${bbox.bottom},${bbox.left},${bbox.top},${bbox.right}&`;
    } else {
      request += `BBOX=${bbox.left},${bbox.bottom},${bbox.right},${bbox.top}&`;
    }
  }
  return request;
};

// Build a valid WMS request for a certain layer
export const buildWMSGetMapRequest = (
  layer: WMLayer,
  projection: WMProjectionWH,
  dimensionOverride?: Dimension[],
):
  | {
      url: string;
      headers: Headers[];
    }
  | undefined => {
  if (!isDefined(layer.name) || layer.name.length < 1) {
    return undefined;
  }
  const { srs, bbox, width, height } = projection;
  const mapBbox = bbox;

  // eslint-disable-next-line no-param-reassign
  layer.format ??= 'image/png';

  // GetFeatureInfo timeseries in the mapview
  if (srs === 'GFI:TIME_ELEVATION') {
    const x = 707;
    const y = 557;
    const bbox = '29109.947643979103,6500000,1190890.052356021,7200000';
    const srs = 'GFI:TIME_ELEVATION';

    let request = layer.getmapURL;
    request += `&SERVICE=WMS&REQUEST=GetFeatureInfo&VERSION=${layer.version}`;

    request += `&LAYERS=${URLEncode(layer.name)}`;

    const baseLayers = layer.name.split(',');
    request += `&QUERY_LAYERS=${URLEncode(baseLayers[baseLayers.length - 1])}`;
    request += `&BBOX=${bbox}`;
    if (
      layer.version === WMSVersion.version100 ||
      layer.version === WMSVersion.version111
    ) {
      request += `&SRS=${URLEncode(srs)}&`;
    }
    if (layer.version === WMSVersion.version130) {
      request += `&CRS=${URLEncode(srs)}&`;
    }
    request += `WIDTH=${width}`;
    request += `&HEIGHT=${height}`;
    if (
      layer.version === WMSVersion.version100 ||
      layer.version === WMSVersion.version111
    ) {
      request += `&X=${x}`;
      request += `&Y=${y}`;
    }
    if (layer.version === WMSVersion.version130) {
      request += `&I=${x}`;
      request += `&J=${y}`;
    }

    if (layer.sldURL) {
      request += `&SLD=${URLEncode(layer.sldURL)}`;
    }

    request += '&FORMAT=image/gif';
    request += '&INFO_FORMAT=image/png';
    request += '&STYLES=';
    request += '&TRANSPARENT=TRUE';

    const startDate = dateToISO8601(new Date(mapBbox.left));
    const stopDate = dateToISO8601(new Date(mapBbox.right));

    request += `&time=${startDate}/${stopDate}`;
    request += `&elevation=${mapBbox.bottom}/${mapBbox.top}`;

    return { url: request, headers: layer.headers };
  }

  let request = WMJScheckURL(layer.getmapURL);
  request += '&SERVICE=WMS&';
  request += `VERSION=${layer.version}&`;
  request += 'REQUEST=GetMap&';
  request += `LAYERS=${URLEncode(layer.name)}&`;
  request += `WIDTH=${width}&`;
  request += `HEIGHT=${height}&`;

  request += getBBOXandProjString(layer, projection);

  if (layer.sldURL) {
    request += `SLD=${URLEncode(layer.sldURL)}&`;
  }
  request += `STYLES=${URLEncode(layer.currentStyle!)}&`;
  request += `FORMAT=${layer.format}&`;
  if (layer.transparent === true) {
    request += 'TRANSPARENT=TRUE&';
  } else {
    request += 'TRANSPARENT=FALSE&';
  }
  // Handle dimensions
  try {
    request += getMapDimURL(layer, dimensionOverride);
  } catch (e) {
    return undefined;
  }
  // Handle WMS extensions
  request += layer.wmsextensions.url;
  return { url: request, headers: layer.headers };
};

export const isProjectionSupported = (
  srs: string,
  proj4Defintions = WMProj4Defs,
): boolean =>
  proj4Defintions.find((proj4Def) => proj4Def[0] === srs) !== undefined;

export const getWMSRequests = (
  map: IWMJSMap,
  dimensionOverride?: Dimension[],
): {
  url: string;
  headers: Headers[];
}[] => {
  const requests: {
    url: string;
    headers: Headers[];
  }[] = [];

  const screenProj: WMProjectionWH = {
    width: map.getSize().width,
    height: map.getSize().height,
    bbox: map.getBBOX(),
    srs: map?.getProjection().srs,
  };

  map.getLayers(false).forEach((layer) => {
    if (layer.service && layer.enabled) {
      const request = buildWMSGetMapRequest(
        layer,
        screenProj,
        dimensionOverride,
      );
      if (request?.url) {
        requests.push(request);
      }
    }
  });
  return requests;
};

export const getLayerIndex = (layer: WMLayer, layers: WMLayer[]): number => {
  return layer ? layers.findIndex((layerToFind) => layerToFind === layer) : -1;
};

/* Returns all dimensions with its current values as object */
export const buildMapLayerDims = (map: IWMJSMap): void => {
  map.mapdimensions.forEach((mapDim) => {
    map.getLayers().forEach((layer) => {
      layer.dimensions.forEach((layerDim) => {
        if (layerDim.linked === true && layerDim.name === mapDim.name) {
          if (
            mapDim.currentValue === 'current' ||
            mapDim.currentValue === 'default' ||
            mapDim.currentValue === '' ||
            mapDim.currentValue === 'earliest' ||
            mapDim.currentValue === 'middle' ||
            mapDim.currentValue === 'latest'
          ) {
            // eslint-disable-next-line no-param-reassign
            mapDim.currentValue = layerDim.getClosestValue(mapDim.currentValue);
          }
          layerDim.setClosestValue(mapDim.currentValue);
        }
      });
    });
  });
};

/**
 *  Makes a valid getfeatureinfoURL for each layer
 * @param layer
 * @param x
 * @param y
 * @param format
 * @returns
 */
export const getWMSGetFeatureInfoRequestURL = (
  layer: WMLayer,
  x: number,
  y: number,
  format = 'text/html',
): string => {
  /* For invalid layers we cannot build an url */
  if (!layer.name) {
    return '';
  }
  const map = layer.parentMap;
  if (!map) {
    return '';
  }
  const { width, height, bbox, srs } = map.getProjection();
  let request = WMJScheckURL(layer.service);
  request += `&SERVICE=WMS&REQUEST=GetFeatureInfo&VERSION=${layer.version}`;

  request += `&LAYERS=${URLEncode(layer.name)}`;

  request += `&QUERY_LAYERS=${URLEncode(layer.name)}`;
  request += `&${getBBOXandProjString(layer, { bbox, srs, width, height })}`;
  request += `WIDTH=${width}`;
  request += `&HEIGHT=${height}`;
  if (
    layer.version === WMSVersion.version100 ||
    layer.version === WMSVersion.version111
  ) {
    request += `&X=${x}`;
    request += `&Y=${y}`;
  }
  if (layer.version === WMSVersion.version130) {
    request += `&I=${x}`;
    request += `&J=${y}`;
  }
  request += '&FORMAT=image/gif';
  request += `&INFO_FORMAT=${format}`;
  request += '&STYLES=';
  try {
    request += `&${getMapDimURL(layer)}`;
  } catch (e) {
    return undefined!;
  }

  return request;
};

export const getGeoCoordFromPixelCoord = (
  coordinates: WMPosition,
  bbox: Bbox,
  width: number,
  height: number,
): WMPosition => {
  const lon = (coordinates.x / width) * (bbox.right - bbox.left) + bbox.left;
  const lat = (coordinates.y / height) * (bbox.bottom - bbox.top) + bbox.top;
  return { x: lon, y: lat };
};

export const getPixelCoordFromLatLong = (
  map: IWMJSMap,
  coordinates: { x: number; y: number },
): {
  x: number;
  y: number;
} => {
  const srs = map?.getProjection()?.srs;
  if (!srs || srs === 'GFI:TIME_ELEVATION') {
    return coordinates;
  }

  return getPixelCoordFromGeoCoord(
    map,
    getGeoCoordFromLatLong(map, coordinates),
  );
};

export const getGeoCoordFromLatLong = (
  map: IWMJSMap,
  coordinates: { x: number; y: number },
): {
  x: number;
  y: number;
} => {
  const srs = map?.getProjection()?.srs;
  if (!srs || srs === 'GFI:TIME_ELEVATION') {
    return coordinates;
  }

  try {
    const p: { x: number; y: number } = { x: undefined!, y: undefined! };
    p.x = parseFloat(coordinates.x as unknown as string);
    p.y = parseFloat(coordinates.y as unknown as string);

    const result = proj4(WMJSMAP_LONLAT_EPSGCODE, srs, [p.x, p.y]);
    return { x: result[0], y: result[1] };
  } catch (e) {
    debugLogger(
      DebugType.Error,
      `WebMapJS: error in getGeoCoordFromLatLong ${e}`,
    );
    return undefined!;
  }
};

export const getLatLongFromPixelCoord = (
  map: IWMJSMap,
  coordinates: { x: number; y: number },
): {
  x: number;
  y: number;
} => {
  if (!map) {
    return coordinates;
  }
  const { width, height, srs } = map.getProjection();
  const bbox = map.getDrawBBOX();
  if (!srs || srs === 'GFI:TIME_ELEVATION') {
    return coordinates;
  }
  try {
    const p: { x: number; y: number } = { x: undefined!, y: undefined! };
    p.x = (coordinates.x / width) * (bbox.right - bbox.left) + bbox.left;
    p.y = (coordinates.y / height) * (bbox.bottom - bbox.top) + bbox.top;
    const result = proj4(srs, WMJSMAP_LONLAT_EPSGCODE, [p.x, p.y]);
    return { x: result[0], y: result[1] };
  } catch (e) {
    return undefined!;
  }
};

export const getPixelCoordFromGeoCoord = (
  map: IWMJSMap,
  coordinates: { x: number; y: number },
  _bbox?: Bbox,
  _width?: number,
  _height?: number,
): {
  x: number;
  y: number;
} => {
  if (!map) {
    return coordinates;
  }
  const { width, height } = map.getProjection();
  let w = width;
  let h = height;
  let b = map.getDrawBBOX() || _bbox;
  if (isDefined(_width)) {
    w = _width;
  }
  if (isDefined(_height)) {
    h = _height;
  }
  if (isDefined(_bbox)) {
    b = _bbox;
  }

  const x = (w * (coordinates.x - b.left)) / (b.right - b.left);
  const y = (h * (coordinates.y - b.top)) / (b.bottom - b.top);
  return { x, y };
};

export const getErrorsToDisplay = (
  canvasErrors: LinkedInfo[],
  getLayerByServiceAndName: (
    layerservice: string,
    layername: string,
  ) => WMLayer,
  t: TFunction | undefined,
): string[] => {
  return canvasErrors.reduce<string[]>((list, error): string[] => {
    if (error.linkedInfo && error.linkedInfo.layer) {
      const layer = getLayerByServiceAndName(
        error.linkedInfo.layer.service,
        error.linkedInfo.layer.name!,
      );
      // Only show if layer is present and enabled
      if (layer && layer.enabled) {
        const message = error.linkedInfo.message
          ? `, ${error.linkedInfo.message}`
          : '';

        return list.concat(
          translateKeyOutsideComponents(t, 'layer-failed-to-load-message', {
            title: error.linkedInfo.layer.title || '',
            message,
          }),
        );
      }
    } else {
      return list.concat(
        translateKeyOutsideComponents(t, 'layer-failed-to-load'),
      );
    }
    return list;
  }, []);
};

export const detectLeftButton = (evt?: MouseEvent): boolean => {
  if (evt?.buttons) {
    return evt.buttons === 1;
  }
  return evt ? evt.button === 0 : false;
};

export const detectRightButton = (evt?: MouseEvent): boolean => {
  if (evt?.buttons) {
    return evt?.buttons === 2;
  }
  return evt ? evt.button === 2 : false;
};

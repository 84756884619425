/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';

import { DrawPolygon } from '@opengeoweb/theme';
import { uiTypes, uiSelectors, CoreAppStore } from '@opengeoweb/store';
import { MapControlButton } from '@opengeoweb/webmap-react';
import { useObjectDrawDialogAction } from './useObjectDrawDialogAction';
import { drawingDialogType } from '../../store/warningsDrawings/utils';
import { useWarningsTranslation } from '../../utils/i18n';

interface DrawingToolMapButtonConnectProps {
  mapId: string;
  source?: uiTypes.Source;
}

const DrawingToolMapButtonConnect: React.FC<
  DrawingToolMapButtonConnectProps
> = ({
  mapId,
  source = 'app',
}: // TODO: re-enable again when we support multi maps
// isMultiMap = false,
DrawingToolMapButtonConnectProps) => {
  const { t } = useWarningsTranslation();
  // const drawingDialogType = isMultiMap
  //   ? `${uiTypes.DialogTypes.DrawingTool}-${mapId}`
  //   : uiTypes.DialogTypes.DrawingTool;
  const drawDialogMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, drawingDialogType),
  );

  const isDrawDialogOpen = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, drawingDialogType),
  );

  const { openDrawDialog, closeDrawDialog } = useObjectDrawDialogAction({
    mapId,
    source,
  });

  const onClick = (): void => {
    const shouldOpen = drawDialogMapId !== mapId ? true : !isDrawDialogOpen;

    shouldOpen ? openDrawDialog() : closeDrawDialog();
  };
  const isOpen = drawDialogMapId === mapId && isDrawDialogOpen;

  return (
    <MapControlButton
      title={t('drawing-tool-title')}
      data-testid="drawingToolButton"
      onClick={onClick}
      isActive={isOpen}
    >
      <DrawPolygon />
    </MapControlButton>
  );
};

export default DrawingToolMapButtonConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { AxiosInstance } from 'axios';
import { createFakeApiInstance } from '@opengeoweb/api';
import { WarningsApi } from './api';
import {
  DrawingFromBE,
  DrawingFromFE,
  DrawingListItem,
  FetchDrawingParams,
} from '../store/warningsDrawings/types';

import { Warning } from '../store/publicWarningForm/types';
import {
  drawingList,
  warningList,
  drawingsListFullContent,
  areaKeywordList,
} from './fakeApi/index';

export const ERROR_NOT_FOUND = 'Request failed with status code 400';

export const MOCK_USERNAME = 'user.name';

export const extractDrawingDetailsFromJSON = (
  drawingId: string,
): DrawingFromBE => {
  return drawingsListFullContent.find((element) => element.id === drawingId)!;
};

// to copy
const getCleanSearchQuery = (searchQuery: string): string =>
  searchQuery.trim().split(/\s+/).join();

const getApiRoutes = (axiosInstance: AxiosInstance): WarningsApi => ({
  // drawings
  getDrawings: (
    params?: FetchDrawingParams,
  ): Promise<{ data: DrawingListItem[] }> => {
    return axiosInstance.get('/drawings', { params }).then(() => {
      const result = drawingList.filter((element) => {
        if (params?.search || params?.keywords) {
          const searchQuery = getCleanSearchQuery(params.search || '').split(
            ',',
          );
          const keyWordsQuery = params.keywords?.split(',') || [];
          const hasResult = [...searchQuery, ...keyWordsQuery].every(
            (query) => {
              return (
                element.objectName
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                element.keywords?.toLowerCase().includes(query.toLowerCase())
              );
            },
          );
          if (hasResult) {
            return element;
          }
        } else {
          return element;
        }
        return null;
      });

      return {
        data: result,
      };
    });
  },
  getDrawingDetails: (drawingId: string): Promise<{ data: DrawingFromBE }> => {
    return axiosInstance.get(`/drawings/${drawingId}`).then(() => {
      if (!extractDrawingDetailsFromJSON(drawingId)) {
        throw new Error(ERROR_NOT_FOUND);
      }
      return {
        data: extractDrawingDetailsFromJSON(drawingId),
      };
    });
  },
  updateDrawing: (drawingId: string, data: DrawingFromFE): Promise<void> => {
    return axiosInstance.post(`/drawings/${drawingId}/`, data);
  },
  saveDrawingAs: (data: DrawingFromFE): Promise<string> => {
    return axiosInstance.post(`/drawings/`, data).then(() => 'new-drawing-id');
  },
  deleteDrawing: (drawingId: string): Promise<void> => {
    drawingList.splice(
      drawingList.findIndex((drawing) => drawing.id === drawingId),
      1,
    );
    return axiosInstance.post(`/drawings/${drawingId}?delete=true`);
  },
  getAreaKeywords: (): Promise<{ data: string[] }> => {
    return axiosInstance.get('/area-keywords').then(() => {
      return {
        data: areaKeywordList,
      };
    });
  },
  saveWarningAs: (data: Warning): Promise<string> => {
    return axiosInstance.post(`/warnings/`, data).then(() => 'new-warning-id');
  },
  updateWarning: (warningId: string, data: Warning): Promise<void> => {
    return axiosInstance.post(`/warnings/${warningId}/`, data);
  },
  deleteWarning: (warningId: string): Promise<void> => {
    warningList.splice(
      warningList.findIndex((warning) => warning.id === warningId),
      1,
    );
    return axiosInstance.post(`/warnings/${warningId}?delete=true`);
  },
});

// used in storybook and tests
export const createApi = (): WarningsApi => {
  const instance = createFakeApiInstance();
  return getApiRoutes(instance);
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomToggleButton, CustomTooltip } from '@opengeoweb/shared';
import { Menu, ListSubheader, Typography, MenuItem, Box } from '@mui/material';
import { useWorkspaceTranslation } from '../../utils/i18n';

interface Option {
  title: string;
  callback: (() => void) | undefined;
}

export interface WorkspaceOptionsProps {
  title: string;
  activeTab?: boolean;
  isDefaultOpen?: boolean;
  panelId?: string;
  id?: string;
  handleSave?: () => void;
  handleSaveAs?: () => void;
  handleEdit?: () => void;
  handleDelete?: () => void;
  handleReset?: () => void;
  hasChanges?: boolean;
  isDisabled?: boolean;
  isAdmin?: boolean;
}

export const workspacePresetOptionId = 'workspace-options-toolbutton';
export const viewPresetOptionId = 'viewpreset-options-toolbutton';

export const WorkspaceOptionsNoMemo: React.FC<WorkspaceOptionsProps> = ({
  title,
  activeTab,
  isDefaultOpen = false,
  handleSave,
  handleSaveAs,
  handleEdit,
  handleDelete,
  handleReset,
  hasChanges = false,
  isDisabled = false,
  panelId,
  id = workspacePresetOptionId,
  isAdmin = false,
}: WorkspaceOptionsProps) => {
  const { t } = useWorkspaceTranslation();

  const [anchorEl, setAnchorEl] = React.useState<
    HTMLInputElement | Element | null
  >(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  React.useEffect(() => {
    if (isDefaultOpen) {
      setAnchorEl(document.getElementById(id) as HTMLInputElement);
    }
  }, [isDefaultOpen, id]);

  const isViewPresetsOptions = id === viewPresetOptionId;

  const saveAsTitle =
    isAdmin && !isViewPresetsOptions
      ? t('workspace-admin-save-new-workspace')
      : t('workspace-save-as');

  const isAdminWorkspaceOptions = isAdmin && id === workspacePresetOptionId;

  const options: Option[] = [
    {
      title: t('workspace-save'),
      callback: handleSave,
    },
    {
      title: saveAsTitle,
      callback: handleSaveAs,
    },
    ...(!isAdminWorkspaceOptions
      ? [{ title: t('workspace-edit'), callback: handleEdit }]
      : []),

    {
      title: t('workspace-reset'),
      callback: handleReset,
    },
    {
      title: t('workspace-delete'),
      callback: handleDelete,
    },
  ];

  const renderButton = (): React.ReactElement => (
    <CustomToggleButton
      variant="tool"
      selected={isOpen}
      onClick={handleClick}
      id={id}
      data-testid={id}
      disableRipple
      disabled={isDisabled}
      sx={{
        fontSize: '12px',
        '&&': {
          color:
            activeTab && !isOpen
              ? 'geowebColors.buttons.icon.active.color'
              : undefined,
          backgroundColor: !isOpen ? 'transparent' : undefined,
          '&:hover': {
            color: activeTab
              ? 'geowebColors.buttons.icon.active.color'
              : 'inherit',
            backgroundColor: 'geowebColors.workspace.tabButtonHover',
          },
          paddingRight: '4px !important',
          display: 'block',
          width: '100%',
          height: 20,
          fontStyle: hasChanges ? 'italic' : undefined,
          fontWeight: 500,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          lineHeight: '1px',
          textAlign: 'left',
          textTransform: 'none',
          '&.MuiButtonBase-root': {
            paddingLeft: 1,
            paddingTop: 1,
            paddingBottom: 1,
            border: 'none',
          },
        },
      }}
      role="button"
    >
      {title}
      {panelId && (
        <Box
          component="span"
          sx={{
            fontStyle: 'normal',
            marginLeft: 1,
            color: 'geowebColors.captions.captionStatus.rgba',
          }}
        >
          {panelId}
        </Box>
      )}
      <Menu
        BackdropProps={{
          style: {
            opacity: 0,
            transition: 'none',
          },
        }}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 21,
          horizontal: 6,
        }}
        sx={{
          '& .MuiMenu-list': {
            padding: 0,
          },
          '& .MuiMenuItem-root': {
            fontSize: 16,
          },
        }}
      >
        <ListSubheader
          sx={{
            paddingTop: '8px',
          }}
        >
          <Typography
            sx={{
              letterSpacing: '0.4px',
              fontSize: 12,
            }}
          >
            {t('workspace-options')}
          </Typography>
        </ListSubheader>
        {options.map(({ title, callback }) => (
          <MenuItem key={title} onClick={callback} disabled={!callback}>
            {title}
          </MenuItem>
        ))}
      </Menu>
    </CustomToggleButton>
  );

  return isDisabled ? (
    renderButton()
  ) : (
    <CustomTooltip title={!isOpen && title} TransitionProps={{ exit: false }}>
      {renderButton()}
    </CustomTooltip>
  );
};

export const WorkspaceOptions = React.memo(WorkspaceOptionsNoMemo);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Position, Source, ToolContainerDraggable } from '@opengeoweb/shared';
import Box from '@mui/material/Box';
import { LayerProps } from '@opengeoweb/webmap';
import { useWebmapReactTranslation } from '../../utils/i18n';
import { LayerInfoText } from './LayerInfoText';
import { LayerInfoList } from './LayerInfoList';
import { LayerInfoLegend } from './LayerInfoLegend';
import {
  getDimensionsList,
  getLayerBbox,
  getLayerStyles,
} from './LayerInfoUtils';

export interface LayerInfoDialogProps {
  layer: LayerProps;
  serviceName: string;
  onClose: () => void;
  isOpen: boolean;
  onMouseDown?: () => void;
  order?: number;
  source?: Source;
  dialogHeight?: number;
  startPosition?: Position;
}
export const LayerInfoDialog: React.FC<LayerInfoDialogProps> = ({
  onClose,
  layer,
  serviceName,
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'app',
  dialogHeight,
  startPosition = { top: 150, left: 900 },
}: LayerInfoDialogProps) => {
  const styles = getLayerStyles(layer);
  const dimensions = getDimensionsList(layer.dimensions!);
  const { t } = useWebmapReactTranslation();
  const bbox = getLayerBbox(t, layer);

  return (
    <ToolContainerDraggable
      onClose={onClose}
      startSize={{ width: 288, height: dialogHeight || 650 }}
      startPosition={startPosition}
      title={t('webmap-react-layer-info')}
      isOpen={isOpen}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
    >
      <Box sx={{ padding: 2 }}>
        <LayerInfoText label={t('webmap-react-title')} value={layer.title!} />
        <LayerInfoText label={t('webmap-react-name')} value={layer.name!} />
        <LayerInfoText label={t('webmap-react-service')} value={serviceName} />
        <LayerInfoText
          label={t('webmap-react-abstract')}
          value={layer.abstract || '-'}
        />
        <LayerInfoList label={t('webmap-react-dimensions')} list={dimensions} />
        <LayerInfoText label={t('webmap-react-styles')} value={styles} />
        <LayerInfoText label={t('webmap-react-bounding-box')} value={bbox} />
        <LayerInfoText
          label={t('webmap-react-groups')}
          value={layer.path?.join('/') || '-'}
        />
        <LayerInfoText
          label={t('webmap-react-keywords')}
          value={layer.keywords?.join(', ') || '-'}
        />
        <LayerInfoLegend
          title={layer.title!}
          name={layer.name!}
          dimensions={layer.dimensions!}
          legendURL={
            layer.styles?.length ? layer.styles[0].legendURL : undefined!
          }
        />
      </Box>
    </ToolContainerDraggable>
  );
};

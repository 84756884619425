/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { EPSGCode } from '@opengeoweb/shared';

export interface TileSettings {
  home: string;
  minLevel: number;
  maxLevel: number;
  tileServerType: string;
  copyRight: string;
  origX?: number;
  origY?: number;
  resolution?: number;
  title?: string;
  tileSize?: number;
  tileServerFormat?: string;
  tms?: boolean;
}

export type TileSettingPerProjection = {
  [key in EPSGCode]?: TileSettings;
};

export interface Title {
  title?: string;
}

export interface TileServerDefinition extends Title, TileSettingPerProjection {}

export type TileServerMap = Map<string, TileServerDefinition>;

export type TileServerSettings = Record<string, TileServerDefinition>;

export const tilesettings: TileServerSettings = {
  WorldMap_Light_Grey_Canvas: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap_Light_Grey_Canvas/EPSG3857/',
      minLevel: 0,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
  },
  OpenStreetMap_NL: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/OpenStreetMap_NL/EPSG3857/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | Ingmapping',
    },
    'EPSG:28992': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/OpenStreets_NL/EPSG28992/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
  },
  OpenStreets_NL: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/OpenStreets_NL/EPSG3857/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | Ingmapping',
    },
    'EPSG:28992': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/OpenStreets_NL/EPSG28992/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
  },
  Positron_NL: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/Positron_NL/EPSG3857/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | OpenMapTiles | Ingmapping',
    },
  },
  Positron_NL_NoLabels: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/Positron_NL_NoLabels/EPSG3857/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | OpenMapTiles | Ingmapping',
    },
  },
  Klokantech_Basic_NL: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/Klokantech_Basic_NL/EPSG3857/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | OpenMapTiles | Ingmapping',
    },
  },
  Klokantech_Basic_NL_NoLabels: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/Klokantech_Basic_NL_NoLabels/EPSG3857/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | OpenMapTiles | Ingmapping',
    },
  },
  OSM_Blossom_NL: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/OSM_Blossom_NL/EPSG3857/',
      minLevel: 0,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | Ingmapping',
    },
  },
  WorldMap: {
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG3857/',
      minLevel: 0,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
    /* TODO
     'EPSG:4326': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG4326/',
      minLevel: 0,
      maxLevel: 9,
      origX: -180,
      origY: 90,
      resolution: 1.422222,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    }, */
    'EPSG:3411': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG3411/',
      minLevel: 3,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
    'EPSG:28992': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG28992/',
      minLevel: 5,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
    'EPSG:3412': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG3412/',
      minLevel: 3,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
    'EPSG:32661': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG32661/',
      minLevel: 4,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
    'EPSG:54030': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG54030/',
      minLevel: 3,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
    'EPSG:3575': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/WorldMap/EPSG3575/',
      minLevel: 5,
      maxLevel: 9,
      tileServerType: 'osm',
      copyRight: 'Natural Earth II | Ingmapping',
    },
  },
  OSM_Antarctica: {
    'EPSG:3412': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/OSM_Antarctica/EPSG3412/',
      minLevel: 1,
      maxLevel: 7,
      origX: -3000000,
      origY: 3000000,
      resolution: 23437.5,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors | Ingmapping',
    },
  },
  arcGisCanvas: {
    title: 'ArcGIS canvas map',
    'EPSG:3857': {
      home: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/',
      minLevel: 1,
      maxLevel: 16,
      tileServerType: 'arcgisonline',
      copyRight: 'Basemap copyright: 2013 ESRI, DeLorme, NAVTEQ',
    },
    'EPSG:28992': {
      home: 'https://services.arcgisonline.com/arcgis/rest/services/Basiskaarten/Canvas/MapServer/tile/',
      minLevel: 1,
      maxLevel: 12,
      origX: -285401.92,
      origY: 903401.92,
      resolution: 3440.64,
      tileServerType: 'arcgisonline',
      copyRight:
        'Basiskaart bronnen: ESRI Nederland, ESRI, Kadaster, CBS en Rijkswaterstaat',
    },
    'EPSG:3067': {
      home: '',
      minLevel: -1,
      maxLevel: -1,
      tileServerType: 'arcgisonline',
      copyRight: '',
    },
  },
  arcGisTopo: {
    title: 'ArcGIS topo map',
    'EPSG:3857': {
      home: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/',
      minLevel: 1,
      maxLevel: 19,
      tileServerType: 'arcgisonline',
      copyRight:
        'Basemap sources: ESRI, DeLorme, NAVTEQ, TomTom, Intermap, increment P Corp., GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey, ESRI Japan, METI, ESRI China (Hong Kong), and the GIS User Community',
    },
    'EPSG:28992': {
      home: 'https://services.arcgisonline.com/arcgis/rest/services/Basiskaarten/Topo/MapServer/tile/',
      minLevel: 1,
      maxLevel: 12,
      origX: -285401.92,
      origY: 903401.92,
      resolution: 3440.64,
      tileServerType: 'arcgisonline',
      copyRight:
        'Basiskaart bronnen: ESRI Nederland, ESRI, Kadaster, CBS, Min VROM, Rijkswaterstaat en gemeenten: Rotterdam, Breda, Tilburg',
    },
  },
  arcGisOceanBaseMap: {
    'EPSG:3857': {
      title: 'ArcGIS ocean map',
      home: 'https://services.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/',
      minLevel: 1,
      maxLevel: 19,
      tileServerType: 'arcgisonline',
      copyRight:
        'Basemap sources: ESRI, GEBCO, NOAA, National Geographic, DeLorme, NAVTEQ, Geonames.org, and other contributors',
    },
  },
  arcGisSat: {
    title: 'ArcGIS satellite map',
    'EPSG:4326': {
      home: 'https://services.arcgisonline.com/ArcGIS/rest/services/ESRI_Imagery_World_2D/MapServer/tile/',
      minLevel: 1,
      maxLevel: 15,
      tileServerType: 'arcgisonline',
      origX: -180,
      origY: 90,
      resolution: 0.3515625,
      tileSize: 512,
      copyRight: 'ESRI',
    },
    'EPSG:3857': {
      home: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/',
      minLevel: 1,
      maxLevel: 18,
      tileServerType: 'arcgisonline',
      copyRight: 'ESRI',
    },
  },
  OpenStreetMap_Service: {
    title: 'OpenStreetMap Service',
    'EPSG:3857': {
      home: 'https://b.tile.openstreetmap.org/',
      minLevel: 1,
      maxLevel: 16,
      tileServerType: 'osm',
      copyRight: 'OpenStreetMap - contributors',
    },
    'EPSG:28992': {
      home: 'https://services.arcgisonline.com/ArcGIS/rest/services/Basiskaarten/PDOK_BRT/MapServer/tile/',
      minLevel: 1,
      maxLevel: 12,
      origX: -285401.92,
      origY: 903401.92,
      resolution: 3440.64,
      tileServerType: 'arcgisonline',
      copyRight: 'Basiskaart bronnen: PDOK, Kadaster, OpenStreetMap',
    },
  },
  NaturalEarth2: {
    'EPSG:3411': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG3411/',
      minLevel: 1,
      maxLevel: 6,
      origX: -12400000,
      origY: 12400000,
      resolution: 96875,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:3412': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG3412/',
      minLevel: 1,
      maxLevel: 6,
      origX: -12400000,
      origY: 12400000,
      resolution: 96875,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:3575': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG3575/',
      minLevel: 1,
      maxLevel: 6,
      origX: -13000000,
      origY: 13000000,
      resolution: 101562.5,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:3857': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG3857/',
      minLevel: 1,
      maxLevel: 7,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:4258': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG4326/',
      minLevel: 1,
      maxLevel: 6,
      origX: -180,
      origY: 90,
      resolution: 0.703125,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:4326': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG4326/',
      minLevel: 1,
      maxLevel: 6,
      origX: -180,
      origY: 90,
      resolution: 0.703125,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:28992': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG28992/',
      minLevel: 1,
      maxLevel: 5,
      origX: -2999000,
      origY: 2995500,
      resolution: 23437,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:32661': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG32661/',
      minLevel: 1,
      maxLevel: 7,
      origX: -5000000,
      origY: 4000000,
      resolution: 58593.75,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
    'EPSG:54030': {
      home: 'https://geoweb-maps-assets.pmc.knmi.cloud/NaturalEarth2/EPSG54030/',
      minLevel: 1,
      maxLevel: 7,
      origX: -17000000,
      origY: 8625830,
      resolution: 132812.5,
      tileServerType: 'wmst',
      copyRight: 'NPS - Natural Earth II',
    },
  },
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CoreAppStore,
  layerSelectors,
  mapActions,
  mapEnums,
  mapSelectors,
} from '@opengeoweb/store';
import { renderCounter } from '@opengeoweb/shared';
import { TimeStepButton } from '@opengeoweb/timeslider';

interface ConnectedTimeStepProps {
  mapId: string;
}

export const TimeStepButtonConnect: React.FC<ConnectedTimeStepProps> = ({
  mapId,
}: ConnectedTimeStepProps) => {
  const dispatch = useDispatch();

  const timeStep = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeStep(store, mapId),
  );

  const isAnimating = useSelector((store: CoreAppStore) =>
    mapSelectors.isAnimating(store, mapId),
  );
  const isTimestepAuto = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimestepAuto(store, mapId),
  );

  const autoTimeStepLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getAutoTimeStepLayerId(store, mapId),
  );
  const timeStepFromLayer = useSelector((store: CoreAppStore) =>
    layerSelectors.getTimeStepForLayerId(store, autoTimeStepLayerId),
  );

  const onToggleTimestepAuto = React.useCallback((): void => {
    dispatch(
      mapActions.toggleTimestepAuto({
        mapId,
        timestepAuto: !isTimestepAuto,
        origin: mapEnums.MapActionOrigin.map,
      }),
    );
  }, [dispatch, isTimestepAuto, mapId]);

  const activeTimeStep =
    isTimestepAuto && timeStepFromLayer ? timeStepFromLayer : timeStep!;

  const onSetTimeStep = (timeStep: number): void => {
    dispatch(
      mapActions.setTimeStep({
        mapId,
        timeStep,
      }),
    );
  };
  renderCounter.count(TimeStepButtonConnect.name);
  return (
    <TimeStepButton
      timeStep={activeTimeStep}
      onChangeTimeStep={onSetTimeStep}
      disabled={isAnimating}
      onToggleTimestepAuto={onToggleTimestepAuto}
      isTimestepAuto={isTimestepAuto && Boolean(timeStepFromLayer)}
      timeStepFromLayer={timeStepFromLayer!}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React, { useEffect } from 'react';
import { Menu } from '@mui/material';
import { FeatureCollection, GeometryObject } from 'geojson';
import { Location } from '@opengeoweb/theme';
import { CustomTooltip } from '@opengeoweb/shared';
import { MapLocation } from '@opengeoweb/webmap-react';
import { TFunction } from 'i18next';
import {
  GeoJsonWithService,
  PointerLocation,
  TimeSeriesLocation,
} from './types';
import { TimeSeriesTopRowButton } from './TimeSeriesTopRowButton';
import { LocationDropdown } from './LocationDropdown/LocationDropdown';
import { getFeatureCenterPoint } from '../../utils/edrUtils';

export const LocationSelect: React.FC<{
  splitGeoJson: GeoJsonWithService[];
  selectedLocation: string;
  disableMapPin: boolean;
  geojson: FeatureCollection<GeometryObject>;
  panelId: string;
  selectedFeature: MapLocation;
  setMapPinLocation: (location: PointerLocation) => void;
  setSelectedLocation: (location: string) => void;
  setTimeSeriesLocation: (location: MapLocation) => void;
  setSelectedFeatureIndex: (selectedFeatureIndex: number) => void;
  featureSelect: (mapId: string, featureId: string) => void;
  t: TFunction;
}> = ({
  splitGeoJson,
  selectedLocation,
  disableMapPin,
  geojson,
  panelId,
  selectedFeature,
  setMapPinLocation,
  setSelectedLocation,
  setTimeSeriesLocation,
  setSelectedFeatureIndex,
  featureSelect,
  t,
}) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);

  useEffect(() => {
    if (!selectedFeature?.id || !geojson.features?.length) {
      return;
    }

    const feature = geojson.features.find(
      (feature) => feature.id?.toString() === selectedFeature.id,
    );

    if (feature) {
      const newLocation = feature.properties?.name || '';
      if (selectedLocation !== newLocation) {
        setSelectedLocation(newLocation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature?.id, geojson.features, setSelectedLocation]);

  const handleChange = (newSelectedLocation: string): void => {
    if (!geojson.features?.length) {
      return;
    }

    setSelectedLocation(newSelectedLocation);

    const selectedFeatureIndex = geojson.features.findIndex(
      (feature) => feature.properties!.name === newSelectedLocation,
    );
    setSelectedFeatureIndex(selectedFeatureIndex);

    const feature = geojson.features[selectedFeatureIndex];
    const [lon, lat] = getFeatureCenterPoint(feature);
    const location: TimeSeriesLocation = {
      lat,
      lon,
      id: feature.id as string,
      serviceId: feature.properties?.serviceId,
      collectionId: feature.properties?.collectionId,
    };
    featureSelect(panelId, feature.id as string);
    setTimeSeriesLocation(location);
    setMapPinLocation(location);
  };

  return (
    <>
      <CustomTooltip
        title={selectedLocation || t('timeseries-select-location')}
      >
        <span>
          <TimeSeriesTopRowButton
            data-testid="locationSelectButton"
            onClick={(): void => setOpen(!open)}
            ref={ref}
            disabled={disableMapPin}
            selected={open}
            icon={<Location />}
            longText={selectedLocation || t('timeseries-location')}
            aria-label={t('timeseries-location')}
          />
        </span>
      </CustomTooltip>
      <Menu
        open={open}
        onClose={(): void => setOpen(false)}
        anchorEl={ref.current}
        sx={{
          maxHeight: 'max(800px, 50%)',
          ' .MuiMenu-paper': {
            transform: 'translate(-50px) !important',
          },
        }}
      >
        <LocationDropdown
          splitGeoJson={splitGeoJson}
          handleChange={handleChange}
          selectedLocation={selectedLocation}
        />
      </Menu>
    </>
  );
};

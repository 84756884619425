/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { SnackbarProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarWrapper } from './SnackbarWrapper';
import { snackbarActions, snackbarTypes, snackbarSelectors } from '../../store';

export const SnackbarWrapperConnect: React.FC<SnackbarProps> = (
  props: SnackbarProps,
) => {
  const dispatch = useDispatch();

  const onCloseSnackbar = (): void => {
    dispatch(snackbarActions.closeSnackbar());
  };

  const currentSnackbarMessage = useSelector(
    (store: snackbarTypes.SnackbarModuleStore) =>
      snackbarSelectors.getFirstSnackbarMessage(store),
  );

  return (
    <SnackbarWrapper
      isOpen={!!currentSnackbarMessage}
      onCloseSnackbar={onCloseSnackbar}
      snackbarMessage={currentSnackbarMessage?.message}
      id={currentSnackbarMessage ? currentSnackbarMessage.id : ''}
      {...props}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Theme } from '@mui/material';
import { timestampToPixelEdges } from '../../TimeSlider/timeSliderUtils';

export const drawNeedle = (
  context: CanvasRenderingContext2D,
  theme: Theme,
  width: number,
  height: number,
  startTime: number,
  endTime: number,
  selectedTime: number,
): void => {
  if (!selectedTime) {
    return;
  }
  const ctx = context;
  const { playerNeedlePlayer, playerNeedlePlayerTop, playerTimeMarkers } =
    theme.palette.geowebColors.timeSlider;
  const selectedPx = timestampToPixelEdges(
    selectedTime,
    startTime,
    endTime,
    width,
  );

  // Draw a high vertical line (needle) indicating the current or selected time
  ctx.beginPath();
  // Move 1 pixel line by 0.5 pixels to prevent it from being shown as blurry: https://usefulangle.com/post/17/html5-canvas-drawing-1px-crisp-straight-lines
  ctx.moveTo(Math.floor(selectedPx) + 0.5, height - 50);
  ctx.lineTo(Math.floor(selectedPx) + 0.5, height);
  ctx.lineWidth = 5;
  ctx.strokeStyle = playerNeedlePlayer.rgba!;
  ctx.stroke();
  ctx.lineWidth = 1;
  ctx.strokeStyle = playerNeedlePlayerTop.rgba!;
  ctx.stroke();

  // Triange connecting timebox.
  ctx.beginPath();
  ctx.moveTo(Math.floor(selectedPx) + 5, 0);
  ctx.lineTo(Math.floor(selectedPx) + 0.5, 5);
  ctx.lineTo(Math.floor(selectedPx) - 5, 0);
  ctx.closePath();
  ctx.fillStyle = playerTimeMarkers.fill!;
  ctx.fill();
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useSelector } from 'react-redux';

import { Polygons } from '@opengeoweb/theme';
import { uiTypes, uiSelectors, CoreAppStore } from '@opengeoweb/store';
import { MapControlButton } from '@opengeoweb/webmap-react';
import { useObjectDrawDialogAction } from '../DrawingTool/useObjectDrawDialogAction';
import { objectDialogType } from '../../store/warningsDrawings/utils';

export const ObjectManagerMapButtonConnect: React.FC<{
  mapId: string;
  source?: uiTypes.Source;
}> = ({ mapId, source = 'app' }) => {
  const objectDialogMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, objectDialogType),
  );
  const isObjectDialogOpen = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, objectDialogType),
  );

  const { openObjectDialog, closeObjectDialog } = useObjectDrawDialogAction({
    mapId,
    source,
  });

  const onClick = (): void => {
    const shouldOpen = objectDialogMapId !== mapId ? true : !isObjectDialogOpen;

    shouldOpen ? openObjectDialog() : closeObjectDialog();
  };

  const isOpen = objectDialogMapId === mapId && isObjectDialogOpen;

  return (
    <MapControlButton
      aria-label={`Object Manager Button for ${mapId}`}
      title="Object Manager"
      onClick={onClick}
      isActive={isOpen}
    >
      <Polygons />
    </MapControlButton>
  );
};

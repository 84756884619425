/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import {
  initReactI18next,
  useTranslation,
  UseTranslationResponse,
} from 'react-i18next';
import sharedTranslations from '../../../locales/shared.json';

export const SHARED_NAMESPACE = 'shared';

export const initSharedI18n = (): void => {
  void i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    ns: SHARED_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [SHARED_NAMESPACE]: sharedTranslations.en,
      },
      fi: {
        [SHARED_NAMESPACE]: sharedTranslations.fi,
      },
      no: {
        [SHARED_NAMESPACE]: sharedTranslations.no,
      },
      nl: {
        [SHARED_NAMESPACE]: sharedTranslations.nl,
      },
    },
  });
};

export const translateKeyOutsideComponents = (
  key: string,
  params: Record<string, string | number> | undefined = undefined,
): string => i18n.t(key, { ns: SHARED_NAMESPACE, ...params });

export const useSharedTranslation = (): UseTranslationResponse<
  typeof SHARED_NAMESPACE,
  typeof i18n
> => useTranslation([SHARED_NAMESPACE]);

export { i18n };

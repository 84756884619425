/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Switch, SwitchProps, FormLabel } from '@mui/material';

import * as React from 'react';
import { CustomTooltip } from '../CustomTooltip';

const styles = {
  label: {
    color: 'geowebColors.buttons.tertiary.default.color',
    opacity: 1,
    fontSize: '12px',
    fontWeight: 500,
  },
};

const SwitchButton: React.FC<
  SwitchProps & {
    activeLabel?: string;
    inActiveLabel?: string;
    tooltip?: string;
  }
> = ({
  activeLabel = 'Editor',
  inActiveLabel = 'Viewer',
  tooltip = 'Switch mode',
  ...props
}) => {
  return (
    <CustomTooltip title={tooltip}>
      <span role="button">
        <FormLabel sx={styles.label}>{inActiveLabel}</FormLabel>
        <Switch data-testid="switchMode" {...props} />
        <FormLabel sx={styles.label}>{activeLabel}</FormLabel>
      </span>
    </CustomTooltip>
  );
};

export default SwitchButton;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid2 as Grid } from '@mui/material';
import { textStyle, textStyleWithMargin } from './LayerInfoText';

export interface ListItem {
  label: string;
  value: string;
  icon: React.ReactElement;
}

export interface LayerInfoListProps {
  label: string;
  list: ListItem[];
}

const valueStyle = {
  ...textStyle,
  overflowWrap: 'break-word',
};

export const LayerInfoList: React.FC<LayerInfoListProps> = ({
  label,
  list,
}: LayerInfoListProps) => {
  return (
    <>
      <Typography variant="subtitle2" sx={textStyle}>
        {label}
      </Typography>
      {list.length === 0 ? (
        <Typography variant="body2" sx={textStyleWithMargin}>
          -
        </Typography>
      ) : (
        <Grid container direction="column">
          {list.map((item) => (
            <div key={item.label}>
              <Grid container direction="row">
                {item.icon}
                <Grid sx={{ marginLeft: '10px' }}>
                  <Typography variant="body2" sx={textStyle}>
                    {item.label}:
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ marginBottom: 1, paddingLeft: '34px' }}
              >
                <Typography variant="body2" sx={valueStyle}>
                  {item.value}
                </Typography>
              </Grid>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
};

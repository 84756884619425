/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  AlertBanner,
  ConfirmationDialog,
  getAxiosErrorMessage,
} from '@opengeoweb/shared';
import { Box, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useWarningsTranslation } from '../../utils/i18n';

export interface ConfirmDialogProps {
  title: string;
  description: string;
  request: () => Promise<void>;
  confirmLabel: string;
  callback: (data?: unknown) => void;
  isLocked?: boolean;
}

export const ConfirmDeleteWarningDialog: React.FC<
  {
    onClose: () => void;
  } & ConfirmDialogProps
> = ({
  onClose = (): void => {},
  title,
  description,
  request,
  confirmLabel,
  callback,
  isLocked,
}) => {
  const { t } = useWarningsTranslation();
  const defaultError = isLocked
    ? t('warning-dialog-delete-islocked')
    : undefined;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<undefined | string>(defaultError);
  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await request();
      callback();
      setIsLoading(false);
    } catch (error) {
      setError(getAxiosErrorMessage(error as AxiosError));
      setIsLoading(false);
    }
  };

  return (
    <ConfirmationDialog
      title={title}
      open
      confirmLabel={confirmLabel}
      cancelLabel={t('warning-dialog-cancel')}
      description={!error ? description : ''}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      sx={{ width: 330 }}
      {...(error && {
        content: (
          <Box sx={{ marginBottom: 0 }}>
            <AlertBanner
              title={error}
              severity={isLocked ? 'warning' : 'error'}
            />
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              {description}
            </Typography>
          </Box>
        ),
      })}
    />
  );
};

export default ConfirmDeleteWarningDialog;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { mapTypes, layerTypes } from '@opengeoweb/store';
import { ElementType, FC, useState } from 'react';
import { TFunction } from 'i18next';
import { useCoreTranslation } from '../../../../../utils/i18n';
import type {
  DimensionNameMappings,
  DimensionValueMappings,
} from '../../../LayerManagerUtils';
import DimensionSelectName from './DimensionSelectName';
import DimensionSelectValue from './DimensionSelectValue';

export interface SingleValueComponentProps extends mapTypes.Dimension {
  position: 'dimension' | 'value';
  nameMappings?: DimensionNameMappings;
  valueMappings?: DimensionValueMappings;
}

export type DimensionTooltipGenerator = (
  t: TFunction,
  selectedDimension: mapTypes.Dimension,
  isValue?: boolean,
) => string;

const defaultDimensionNameTooltip: DimensionTooltipGenerator = (
  t,
  selectedDimension,
) => `${t('layermanager-dimensions-title')}: ${selectedDimension.name}`;

const defaultDimensionValueTooltip: DimensionTooltipGenerator = (
  t,
  selectedDimension,
) =>
  `${t('layermanager-dimensions-title')}: ${selectedDimension.name} ${selectedDimension.currentValue} ${selectedDimension.units}`;

export interface DimensionSelectProps {
  layerDimensions?: mapTypes.Dimension[];
  onLayerChangeDimension: (
    dimensionName: string,
    dimensionValue: string,
    origin?: layerTypes.LayerActionOrigin.layerManager,
  ) => void;
  isEnabled?: boolean;
  icon?: ElementType;
  nameMappings?: DimensionNameMappings;
  valueMappings?: DimensionValueMappings;
  tooltipGenerator?: DimensionTooltipGenerator;
  useLatestReferenceTime?: boolean;
  setUseLatestReferenceTime?: (useLatestReferenceTime: boolean) => void;
  SingleValueComponent?: React.FC<SingleValueComponentProps>;
  hideLatestDuplicate?: boolean;
  skipLocalStyling?: boolean;
}

const DimensionSelect: FC<DimensionSelectProps> = ({
  onLayerChangeDimension,
  layerDimensions = [],
  isEnabled = false,
  icon,
  nameMappings,
  valueMappings,
  tooltipGenerator,
  setUseLatestReferenceTime,
  useLatestReferenceTime,
  SingleValueComponent,
  hideLatestDuplicate,
  skipLocalStyling,
}: DimensionSelectProps) => {
  const { t } = useCoreTranslation();

  const [selectedDimensionName, setSelectedDimensionName] = useState(
    layerDimensions?.[0]?.name!,
  );
  const selectedDimension = layerDimensions.find(
    (dimension) => dimension.name === selectedDimensionName,
  );

  const dimensionNames = layerDimensions.map((dimension) => dimension.name!);
  const selectedDimensionDoesntExist = !dimensionNames.includes(
    selectedDimensionName,
  );
  React.useEffect(() => {
    if (selectedDimensionDoesntExist) {
      setSelectedDimensionName(layerDimensions?.[0]?.name ?? '');
    }
  }, [layerDimensions, selectedDimensionDoesntExist]);

  if (selectedDimensionDoesntExist) {
    return null;
  }

  const collapseDimensionDropdown =
    SingleValueComponent && layerDimensions.length === 1;
  const collapseValueDropdown =
    SingleValueComponent && selectedDimension?.values?.length === 1;

  const defaultNameTooltip =
    defaultDimensionNameTooltip(t, selectedDimension!) ?? '';
  const defaultValueTooltip =
    defaultDimensionValueTooltip(t, selectedDimension!) ?? '';
  const nameTooltip =
    tooltipGenerator?.(t, selectedDimension!) ?? defaultNameTooltip;
  const valueTooltip =
    tooltipGenerator?.(t, selectedDimension!, true) ?? defaultValueTooltip;

  return (
    <>
      {collapseDimensionDropdown ? (
        <SingleValueComponent
          {...layerDimensions[0]}
          nameMappings={nameMappings}
          valueMappings={valueMappings}
          position="dimension"
        />
      ) : (
        <DimensionSelectName
          selectedDimensionName={selectedDimensionName}
          setSelectedDimensionName={setSelectedDimensionName}
          dimensionNames={dimensionNames}
          tooltip={nameTooltip}
          isEnabled={isEnabled}
          icon={icon}
          nameMappings={nameMappings}
          skipLocalStyling={skipLocalStyling}
        />
      )}

      {collapseValueDropdown ? (
        <SingleValueComponent
          {...layerDimensions[0]}
          nameMappings={nameMappings}
          valueMappings={valueMappings}
          position="value"
        />
      ) : (
        <DimensionSelectValue
          selectedDimension={selectedDimension}
          onLayerChangeDimension={onLayerChangeDimension}
          tooltip={valueTooltip}
          isEnabled={isEnabled}
          hideLatestDuplicate={hideLatestDuplicate}
          icon={icon}
          valueMappings={valueMappings}
          setUseLatestReferenceTime={setUseLatestReferenceTime}
          useLatestReferenceTime={useLatestReferenceTime}
          skipLocalStyling={skipLocalStyling}
        />
      )}
    </>
  );
};

export default DimensionSelect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/**
 * The removeInPlace() method modifies an array in place, by removing elements that pass the test implemented by the provided function.
 *
 * NOTE: This should only be used on draft properties as provided by the immer produce library, as modifying elements in place is bad practice on non draft variables.
 *
 * @param inDraftArray The draft array to filter, will be modified in place.
 * @param condition Function to check each element in the array
 */
export const removeInPlace = <T>(
  inDraftArray: T[],
  condition: (c: T) => boolean,
): void => {
  let j = 0;

  inDraftArray.forEach((e, i) => {
    if (!condition(e)) {
      if (i !== j) {
        // eslint-disable-next-line no-param-reassign
        inDraftArray[j] = e;
      }
      j += 1;
    }
  });

  // eslint-disable-next-line no-param-reassign
  inDraftArray.length = j;
};

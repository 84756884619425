/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { useFormContext } from 'react-hook-form';

export const useShortTestHelpers = (): {
  isShortTest: () => boolean;
  onTypeChange: () => void;
} => {
  const { getValues, setValue, clearErrors } = useFormContext();

  const unusedFields = [
    'isObservationOrForecast',
    'change',
    'startGeometry',
    'startGeometryIntersect',
    'endGeometry',
    'endGeometryIntersect',
    'levelInfoMode',
    'level',
    'lowerLevel',
    'movementType',
    'movementDirection',
    'movementUnit',
    'movementSpeed',
    'change',
    'vaSigmetVolcanoName',
    'vaSigmetVolcanoCoordinates.latitude',
    'vaSigmetVolcanoCoordinates.longitude',
  ];

  const isShortTest = (): boolean => {
    const type = getValues('type');
    return type === 'SHORT_TEST' || type === 'SHORT_VA_TEST';
  };

  const onTypeChange = (): void => {
    if (getValues('type') === 'SHORT_TEST') {
      setValue('phenomenon', 'EMBD_TS');
    } else if (getValues('type') === 'SHORT_VA_TEST') {
      setValue('phenomenon', 'VA_CLD');
    }
    if (isShortTest()) {
      unusedFields.forEach((field) => {
        setValue(field, null);
      });
      clearErrors(unusedFields);
    }
  };

  return {
    isShortTest,
    onTypeChange,
  };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSetupDialog, uiTypes } from '@opengeoweb/store';
import { TimeSeriesSelect } from './TimeSeriesSelect';
import { Parameter, Plot } from '../TimeSeries/types';
import { getPlotWithParameters, getServices } from '../../store/selectors';
import { timeSeriesActions } from '../../store';
import {
  TimeSeriesActionOrigin,
  TimeSeriesModuleState,
} from '../../store/types';

const DIALOG_TYPE = uiTypes.DialogTypes.TimeSeriesSelect;
const origin = TimeSeriesActionOrigin.timeSeriesSelect;

interface TimeSeriesSelectConnectProps {
  selectPlotId: string;
  panelId: string;
}

export const TimeSeriesSelectConnect: React.FC<
  TimeSeriesSelectConnectProps
> = ({ selectPlotId, panelId }) => {
  const { setDialogOrder, dialogOrder, isDialogOpen, onCloseDialog } =
    useSetupDialog(DIALOG_TYPE);

  const dispatch = useDispatch();

  const plot: Plot | undefined = useSelector((state: TimeSeriesModuleState) =>
    getPlotWithParameters(state, selectPlotId),
  );

  // If select dialog for a plot is open but the plot is deleted, close the dialog
  React.useEffect(() => {
    if (isDialogOpen && !plot) {
      onCloseDialog();
    }
  }, [plot, isDialogOpen, onCloseDialog]);

  const handleAddOrRemoveClick = React.useCallback(
    (parameter: Parameter, plotHasParameter: boolean): void => {
      if (plotHasParameter) {
        dispatch(
          timeSeriesActions.deleteParameter({
            panelId,
            origin,
            parameterId: parameter.id!,
          }),
        );
      } else {
        dispatch(
          timeSeriesActions.addParameter({ panelId, origin, parameter }),
        );
      }
    },
    [dispatch, panelId],
  );

  const services = useSelector((state: TimeSeriesModuleState) =>
    getServices(state),
  );

  return plot && services && services.length > 0 ? (
    <TimeSeriesSelect
      isOpen={isDialogOpen}
      onClose={onCloseDialog}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      selectPlot={plot}
      handleAddOrRemoveClick={handleAddOrRemoveClick}
      services={services}
    />
  ) : null;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { makeHashUrlForGeoReferencedImage } from './makeHashUrlForGeoReferencedImage';

import WMImage, { WMGeoReference, WMImageOptions } from './WMImage';
import { debugLogger, DebugType } from './WMJSTools';

export enum WMImageEventType {
  Loaded,
  Deleted,
}

const WMIMAGESTORE_MAX_ALTIMAGES_TO_KEEP = 4;

export default class WMImageStore {
  imagesbysrc: Map<string, WMImage>;

  imageLife: number;

  _imageLifeCounter: number;

  _loadEventCallbackList: {
    id: string;
    callback: (
      image: WMImage,
      id: string,
      imageEventType: WMImageEventType,
    ) => void;
  }[];

  _maxNumberOfImages: number;

  _options: { id: string };

  emptyImage: WMImage;
  imagesbyGeoRef: Map<string, WMImage[]>;

  /**
   * Constructs a new WMImageStore with given amount of image cache.
   * @param {*} maxNumberOfImages
   * @param {*} options
   */
  constructor(maxNumberOfImages: number, options: { id: string }) {
    this.imagesbysrc = new Map<string, WMImage>();

    this.imagesbyGeoRef = new Map<string, WMImage[]>();

    this.imageLife = 0;
    this._imageLifeCounter = 0;
    this._loadEventCallbackList = []; // Array of callbacks, as multiple instances can register listeners
    this._maxNumberOfImages = maxNumberOfImages;
    this._options = options;
    this.emptyImage = new WMImage(null!, null!);
    this.imageLoadEventCallback = this.imageLoadEventCallback.bind(this);
    this.getImageForSrc = this.getImageForSrc.bind(this);
    this.clear = this.clear.bind(this);
    this.addImageEventCallback = this.addImageEventCallback.bind(this);
    this.removeAllEventCallbacks = this.removeAllEventCallbacks.bind(this);
    this.getNumImagesLoading = this.getNumImagesLoading.bind(this);
    this.getImage = this.getImage.bind(this);
    this.load = this.load.bind(this);
  }

  load(imageUrl: string): Promise<WMImage> {
    return this.getImage(imageUrl).loadAwait() as Promise<WMImage>;
  }

  imageLoadEventCallback(
    _img: WMImage,
    imageEventType: WMImageEventType,
  ): void {
    for (const eventCallback of this._loadEventCallbackList) {
      eventCallback.callback(_img, eventCallback.id, imageEventType);
    }
  }

  /**
   * Check if we have similar images with the same source in the pipeline
   */
  getImageForSrc(src: string): WMImage | undefined {
    return this.imagesbysrc.get(src);
  }

  clear(): void {
    this.imagesbysrc.forEach((wmImage) => {
      wmImage.clear();
    });
    this.imagesbysrc.clear();
  }

  addImageEventCallback(
    callback: (
      image: WMImage,
      id: string,
      imageEventType: WMImageEventType,
    ) => void,
    id: string,
  ): void {
    if (!id) {
      debugLogger(DebugType.Error, 'addImageEventCallback: id not provided');
      return;
    }
    if (!callback) {
      debugLogger(
        DebugType.Error,
        'addImageEventCallback: callback not provided',
      );
      return;
    }
    this._loadEventCallbackList.push({
      id,
      callback,
    });
  }

  removeAllEventCallbacks(): void {
    this._loadEventCallbackList.length = 0;
  }

  removeEventCallback(id: string): void {
    for (let j = this._loadEventCallbackList.length - 1; j >= 0; j -= 1) {
      if (this._loadEventCallbackList[j].id === id) {
        this._loadEventCallbackList.splice(j, 1);
      }
    }
  }

  getNumImagesLoading(): number {
    let numLoading = 0;
    this.imagesbysrc.forEach((value: WMImage): void => {
      if (value._isLoading) {
        numLoading += 1;
      }
    });
    return numLoading;
  }

  /**
   * getAltGeoReferencedImage will return an ready and loaded image for similar query parameters as requested.
   * This image can be used to display something during the period when the requested image one is still loading.
   * The alternative image will have the same query parameters but can have a different extent and resolution.
   * @param srcToLoad The url of the image for which we want to find an alternative
   * @param _extent Extent of the requested image
   * @param _resolution Resolution of the request image
   * @returns A WMImage which is ready to display. Can have a different extent and resolution then what was asked in srcToLoad
   */
  getAltGeoReferencedImage(
    srcToLoad: string,
    // eslint-disable-next-line no-unused-vars
    _extent: number[],
    // eslint-disable-next-line no-unused-vars
    _resolution: number,
  ): WMImage | false {
    const hashUrl = makeHashUrlForGeoReferencedImage(srcToLoad);
    if (!hashUrl) {
      return false;
    }

    // Sort the imagesbyGeoRef array
    const imagesbyGeoRefArray = this.imagesbyGeoRef.get(hashUrl);
    if (!imagesbyGeoRefArray || imagesbyGeoRefArray.length === 0) {
      return false;
    }
    // Clean the set, only keep the most recent images
    const imagesbyGeoRefArraySortByImageLife = imagesbyGeoRefArray
      .sort((a, b) => {
        if (a.imageLife > b.imageLife) {
          return -1;
        }
        if (a.imageLife < b.imageLife) {
          return 1;
        }
        return 0;
      })
      .slice(0, WMIMAGESTORE_MAX_ALTIMAGES_TO_KEEP);
    // Set this array back in the array
    this.imagesbyGeoRef.set(hashUrl, imagesbyGeoRefArraySortByImageLife);

    // Now filter out the loaded images, which can be used as alternative
    const sortByImageLifeFilterdLoaded =
      imagesbyGeoRefArraySortByImageLife.filter((image) =>
        image.isLoadedWithoutErrors(),
      );

    // Just return most recent available image: TODO: Possibly make use of extent and resolution to find a better one.
    return sortByImageLifeFilterdLoaded.length > 0
      ? sortByImageLifeFilterdLoaded[0]
      : false;
  }

  getGeoReferencedImage(src: string, geoReference: WMGeoReference): WMImage {
    const image = this.getImage(src) as WMImage;

    if (image) {
      // Make a hashUrl without BBOX
      if (!image.hashUrl) {
        const hashUrl = makeHashUrlForGeoReferencedImage(image.srcToLoad);
        if (!hashUrl) {
          return null!;
        }
        image.hashUrl = hashUrl;

        if (image.hashUrl && !this.imagesbyGeoRef.has(image.hashUrl)) {
          this.imagesbyGeoRef.set(image.hashUrl, []);
        }
        image.hashUrl && this.imagesbyGeoRef.get(image.hashUrl)?.push(image);
      }
      image.setGeoReference(geoReference);
    }
    return image;
  }

  /**
   * Get an WMImage object for given URL
   * @param {*} src The url for the image
   * @returns WMImage object
   */
  getImage(src: string, loadOptions?: WMImageOptions): WMImage {
    if (!src) {
      return null!;
    }
    // Check if we have an image in the pipeline
    const image = this.imagesbysrc.get(src);
    if (image !== undefined) {
      this._imageLifeCounter += 1;
      image.imageLife = this._imageLifeCounter;
      return image;
    }

    // Create  image
    if (this.imagesbysrc.size < this._maxNumberOfImages) {
      const newImage = new WMImage(src, (img: WMImage) => {
        this.imageLoadEventCallback(img, WMImageEventType.Loaded);
      });
      newImage.setSource(src, loadOptions!);
      this.imagesbysrc.set(src, newImage);
      this._imageLifeCounter += 1;
      newImage.imageLife = this._imageLifeCounter;
      return newImage;
    }

    // We have to reuse an image
    const imagesAsList = Array.from(this.imagesbysrc.values()).filter(
      (imageBySrc: WMImage) => {
        return !imageBySrc || !imageBySrc.isLoading() || imageBySrc.hasError();
      },
    );
    imagesAsList.sort((a, b) => {
      if (a.imageLife < b.imageLife) {
        return -1;
      }
      if (a.imageLife > b.imageLife) {
        return 1;
      }
      return 0;
    });
    const imageIdByUrl =
      imagesAsList?.length > 0 ? imagesAsList[0].srcToLoad : '';

    if (imageIdByUrl === '') {
      console.error('IMAGESTORE TO LARGE TO LOAD');
    } else {
      const hashUrl = makeHashUrlForGeoReferencedImage(imageIdByUrl);
      hashUrl && this.imagesbyGeoRef.delete(hashUrl);

      const reuseImage = this.imagesbysrc.get(imageIdByUrl);
      if (reuseImage) {
        this.imagesbysrc.delete(imageIdByUrl);
        this.imageLoadEventCallback(reuseImage, WMImageEventType.Deleted);
        reuseImage.clear();
      }
    }

    // Force return of new image
    const newImage = new WMImage(src, (img: WMImage) => {
      this.imageLoadEventCallback(img, WMImageEventType.Loaded);
    });

    newImage.setSource(src, loadOptions!);
    this.imagesbysrc.set(src, newImage);
    this._imageLifeCounter += 1;
    newImage.imageLife = this._imageLifeCounter;
    return newImage;
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Button,
  Box,
  CircularProgress,
  ModalProps,
  SxProps,
  Theme,
} from '@mui/material';
import { CustomDialog, DialogAction } from '../CustomDialog';
import { useSharedTranslation } from '../../utils/i18n';

const confirmStyle = {
  width: { xs: '100%', sm: 'auto' },
  minWidth: { xs: '100%', sm: '150px' },
  marginLeft: { xs: '0px!important', sm: '8px!important' },
  marginTop: { xs: 2, sm: 0 },
};

export interface ConfirmationOptions {
  title?: string;
  description?: string;
  catchOnCancel?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  content?: React.ReactNode;
}

export type ConfirmationAction = 'CANCELLED' | DialogAction;

export interface ConfirmationDialogProps extends ConfirmationOptions {
  disableAutoFocus?: boolean;
  onSubmit: () => void;
  onClose: (reason: ConfirmationAction) => void;
  isLoading?: boolean;
  open: ModalProps['open'];
  sx?: SxProps<Theme>;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  description,
  confirmLabel,
  cancelLabel,
  onSubmit,
  onClose,
  content = null,
  disableAutoFocus = false,
  isLoading = false,
  ...other
}: ConfirmationDialogProps) => {
  const { catchOnCancel, ...rest } = other; // remove unwanted props
  const { t } = useSharedTranslation();

  return (
    <CustomDialog
      data-testid="confirmationDialog"
      open={open}
      title={title}
      description={description ?? t('shared-confirmation-description')}
      onClose={onClose}
      actions={
        <>
          <Button
            data-testid="confirmationDialog-cancel"
            variant="tertiary"
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            sx={(theme) => ({
              width: {
                xs: '100%',
                sm: '150px',
              },
              '&:focus': {
                outline:
                  theme.palette.geowebColors.buttons.primary.focus.outline,
                borderColor: 'transparent',
              },
            })}
            onClick={(): void => onClose('CANCELLED')}
          >
            {cancelLabel ?? t('shared-confirmation-cancel')}
          </Button>
          {isLoading ? (
            <Box
              data-testid="confirm-dialog-spinner"
              sx={{
                ...confirmStyle,
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={40} color="secondary" />
            </Box>
          ) : (
            <Button
              data-testid="confirmationDialog-confirm"
              variant="primary"
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              sx={(theme) => ({
                ...confirmStyle,
                '&:focus': {
                  outline:
                    theme.palette.geowebColors.buttons.primary.focus.outline,
                  borderColor: 'transparent',
                },
              })}
              onClick={onSubmit}
              autoFocus={!disableAutoFocus}
            >
              {confirmLabel ?? t('shared-confirmation-submit')}
            </Button>
          )}
        </>
      }
      {...rest}
    >
      {content}
    </CustomDialog>
  );
};

export default ConfirmationDialog;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  AlertBanner,
  HeaderSize,
  ToolContainerDraggable,
  calculateStartSize,
  Position,
  DraggableSize,
} from '@opengeoweb/shared';
import { uiTypes } from '@opengeoweb/store';
import { Box, LinearProgress } from '@mui/material';
import { DrawingListItem } from '../../store/warningsDrawings/types';
import { useWarningsTranslation } from '../../utils/i18n';
import { AreasObjects } from './AreasObjects';
import { AreaObjectFilter, AreaObjectFilterProps } from './AreaObjectFilter';
import { Area } from '../../store/publicWarningForm/types';

const DEFAULT_LOADER_SIZE = { width: 320, height: 542 };
const DEFAULT_LOADER_POSITION = { top: 120, left: 100 };
const DEFAULT_LOADER_MIN_SIZE = { width: 160, height: 300 };

export const areaErrorType = 'area';

export const AreaObjectLoader: React.FC<
  {
    bounds?: string;
    title?: string;
    onClose: () => void;
    onMouseDown?: () => void;
    isOpen: boolean;
    order?: number;
    source?: uiTypes.Source;
    size?: DraggableSize;
    startPosition?: Position;
    headerSize?: HeaderSize;
    drawingListItems: DrawingListItem[];
    error?: uiTypes.UISetErrorPayload;
    isLoading?: boolean;
    onAddArea?: (drawingListItem: DrawingListItem) => void;
    onRemoveArea?: (drawingListItem: DrawingListItem) => void;
    selectedAreas?: Area[];
    isChangeAreaDisabled?: boolean;
    onRetryRequest?: (errorType: string) => void;
  } & AreaObjectFilterProps
> = ({
  bounds,
  onClose,
  title,
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'app',
  size = DEFAULT_LOADER_SIZE,
  startPosition = DEFAULT_LOADER_POSITION,
  headerSize = 'xs',
  drawingListItems,
  error,
  isLoading = false,
  filters,
  onClickFilterChip,
  searchQuery,
  onSearch,
  selectedAreas,
  onAddArea = (): void => {},
  onRemoveArea = (): void => {},
  onRetryRequest = (): void => {},
  isChangeAreaDisabled = true,
}) => {
  const { t } = useWarningsTranslation();
  const minSize = DEFAULT_LOADER_MIN_SIZE;
  const startSizeCalc = calculateStartSize(minSize, size, startPosition);
  const [sizeInState, setSizeInState] =
    React.useState<DraggableSize>(startSizeCalc);

  const areaFilterRef = React.useRef(null!);
  return (
    <ToolContainerDraggable
      title={title || t('area-object-loader-title')}
      startSize={sizeInState}
      minWidth={minSize.width}
      minHeight={minSize.height}
      startPosition={startPosition}
      isOpen={isOpen}
      onClose={onClose}
      headerSize={headerSize}
      bounds={bounds}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
      onResizeStop={(_event, _direction, node): void => {
        const { offsetWidth: width, offsetHeight: height } = node;
        setSizeInState({ width, height });
      }}
      onDragEnd={(_position, dragSize): void => {
        if (dragSize !== sizeInState) {
          setSizeInState(dragSize as DraggableSize);
        }
      }}
      sx={{
        footer: {
          backgroundColor: 'inherit',
          boxShadow: 'none',
        },
      }}
    >
      {isLoading && (
        <LinearProgress
          data-testid="loading-bar"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 0 }}
        />
      )}
      <Box sx={{ padding: 1, height: '100%' }}>
        {error && (
          <AlertBanner
            title={error.error}
            {...(error.type === areaErrorType && {
              actionButtonProps: {
                title: t('error-title-retry'),
                onClick: (): void => onRetryRequest(error.type),
              },
            })}
          />
        )}
        <AreaObjectFilter
          onClickFilterChip={onClickFilterChip}
          searchQuery={searchQuery}
          onSearch={onSearch}
          filters={filters}
          ref={areaFilterRef}
        />
        <AreasObjects
          drawingListItems={drawingListItems}
          isLoading={isLoading}
          hasError={!!error}
          filterRef={areaFilterRef}
          onAddArea={onAddArea}
          onRemoveArea={onRemoveArea}
          selectedAreas={selectedAreas}
          isChangeAreaDisabled={isChangeAreaDisabled}
        />
      </Box>
    </ToolContainerDraggable>
  );
};

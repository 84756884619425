/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Grid2 as Grid } from '@mui/material';

import { ReactHookFormHiddenInput } from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';

import {
  CategoryLabelStatusTag,
  StartTimeEndTime,
  PeakFlux,
  XrayClassPeakClass,
  Threshold,
  EventLevel,
  SuddenImpulse,
  DataSourceStatusTag,
  GScaleStatusTag,
} from './EditFormFields';

const useConditionalFields = (
  actionMode: string,
): {
  hasGScale: boolean;
  hasDataSource: boolean;
  hasSuddenImpulse: boolean;
  hasEventLevel: boolean;
  hasThreshold: boolean;
  hasThresholdUnit: boolean;
  hasXrayClassPeakClass: boolean;
  hasPeakFlux: boolean;
} => {
  const { watch, unregister } = useFormContext();
  const category = watch('category');
  const categorydetail = watch('categorydetail');
  const label = watch('label');

  // GScale
  const hasGScale = categorydetail === 'GEOMAGNETIC_STORM';
  React.useEffect(() => {
    if (!hasGScale) {
      unregister(['initialgscale']);
    }
  }, [hasGScale, unregister]);

  // DataSource
  const hasDataSource = categorydetail !== 'GEOMAGNETIC_STORM';
  React.useEffect(() => {
    if (!hasDataSource) {
      unregister(['datasource']);
    }
  }, [hasDataSource, unregister]);

  // SuddenImpulse
  const hasSuddenImpulse = categorydetail === 'GEOMAGNETIC_SUDDEN_IMPULSE';
  const isAlert = label === 'ALERT';
  React.useEffect(() => {
    if (!hasSuddenImpulse || (hasSuddenImpulse && !isAlert)) {
      unregister(['impulsetime', 'magnetometerdeflection']);
    }
    if (!hasSuddenImpulse || (hasSuddenImpulse && isAlert)) {
      unregister(['shocktime', 'observedpolaritybz', 'observedsolarwind']);
    }
  }, [hasSuddenImpulse, isAlert, unregister]);

  // EventLevel
  const hasEventLevel =
    categorydetail !== 'GEOMAGNETIC_STORM' &&
    categorydetail !== 'GEOMAGNETIC_SUDDEN_IMPULSE' &&
    categorydetail !== 'PROTON_FLUX_100' &&
    category !== 'ELECTRON_FLUX';

  React.useEffect(() => {
    if (!hasEventLevel) {
      unregister(['neweventlevel']);
    }
  }, [hasEventLevel, unregister]);

  // Threshold
  const hasThreshold =
    categorydetail !== 'GEOMAGNETIC_STORM' &&
    categorydetail !== 'GEOMAGNETIC_SUDDEN_IMPULSE';
  const hasThresholdUnit = hasThreshold && category !== 'GEOMAGNETIC';
  React.useEffect(() => {
    if (!hasThreshold) {
      unregister(['threshold']);
    }
    if (!hasThresholdUnit) {
      unregister(['thresholdunit']);
    }
  }, [hasThreshold, hasThresholdUnit, unregister]);

  // XrayClassPeakClass
  const hasXrayClassPeakClass = category === 'XRAY_RADIO_BLACKOUT';
  const hasPeakClass = hasXrayClassPeakClass && actionMode === 'Summarise';
  React.useEffect(() => {
    if (!hasXrayClassPeakClass) {
      unregister(['xrayclass']);
    }
    if (!hasPeakClass) {
      unregister(['peakclass']);
    }
  }, [hasXrayClassPeakClass, hasPeakClass, unregister]);

  // PeakFlux
  const hasPeakFlux = actionMode === 'Summarise';
  React.useEffect(() => {
    if (!hasPeakFlux) {
      unregister(['peakflux', 'peakfluxtime']);
    }
  }, [hasPeakFlux, unregister]);

  // EventEnd
  const hasEventEnd = actionMode === 'Summarise' || label === 'WARNING';
  React.useEffect(() => {
    if (!hasEventEnd) {
      unregister(['neweventend']);
    }
  }, [hasEventEnd, unregister]);

  return {
    hasGScale,
    hasDataSource,
    hasSuddenImpulse,
    hasEventLevel,
    hasThreshold,
    hasThresholdUnit,
    hasXrayClassPeakClass,
    hasPeakFlux,
  };
};

interface LifeCycleEditOptionsProps {
  statusTagContent: string;
  eventTypeDisabled?: boolean;
  actionMode?: string;
}

const LifeCycleEditOptions: React.FC<LifeCycleEditOptionsProps> = ({
  statusTagContent,
  eventTypeDisabled = false,
  actionMode = 'none',
}: LifeCycleEditOptionsProps) => {
  const [statusTagText, setStatusTagText] = React.useState(statusTagContent);

  const {
    hasGScale,
    hasDataSource,
    hasSuddenImpulse,
    hasEventLevel,
    hasThreshold,
    hasThresholdUnit,
    hasXrayClassPeakClass,
    hasPeakFlux,
  } = useConditionalFields(actionMode);
  return (
    <Grid container alignItems="center">
      {eventTypeDisabled ? (
        <>
          <ReactHookFormHiddenInput name="category" data-testid="category" />
          <ReactHookFormHiddenInput
            name="categorydetail"
            data-testid="categorydetail"
          />
          <ReactHookFormHiddenInput name="label" data-testid="label" />
        </>
      ) : (
        <CategoryLabelStatusTag
          statusTagText={statusTagText}
          setStatusTagText={setStatusTagText}
        />
      )}
      {hasGScale && (
        <GScaleStatusTag
          statusTagText={statusTagText}
          actionMode={actionMode}
          eventTypeDisabled={eventTypeDisabled}
        />
      )}
      {hasDataSource && (
        <DataSourceStatusTag
          statusTagText={statusTagText}
          eventTypeDisabled={eventTypeDisabled}
        />
      )}
      {hasSuddenImpulse && <SuddenImpulse />}
      {hasEventLevel && <EventLevel />}
      {hasThreshold && (
        <Threshold
          hasEventLevel={hasEventLevel}
          hasThresholdUnit={hasThresholdUnit}
        />
      )}
      {hasXrayClassPeakClass && <XrayClassPeakClass actionMode={actionMode} />}
      {hasPeakFlux && <PeakFlux />}
      <StartTimeEndTime actionMode={actionMode} />
    </Grid>
  );
};

export default LifeCycleEditOptions;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid, List } from '@mui/material';
import { CustomAccordion, ToolContainerDraggable } from '@opengeoweb/shared';
import { uiTypes } from '@opengeoweb/store';
import { KeywordFilterResultsListItemConnect } from './KeywordFilterResultsListItemConnect';
import { KeywordFilterSelectAllSwitchConnect } from './KeywordFilterSelectAllSwitchConnect';
import { FiltersGrouped } from '../../store/selectors';
import { useLayerSelectTranslation } from '../../utils/i18n';

const listStyle = { padding: '0px' };

const accordionDetailsStyle = {
  margin: '0px',
  padding: '0 12px',
};

export interface KeywordFilterResultsProps {
  bounds?: string;
  onClose?: () => void;
  onMouseDown?: () => void;
  isOpen: boolean;
  order?: number;
  source?: uiTypes.Source;
  filters: FiltersGrouped;
}

export const KeywordFilterResults: React.FC<KeywordFilterResultsProps> = ({
  filters,
  bounds,
  onClose = (): void => {},
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'module',
}: KeywordFilterResultsProps) => {
  const { t } = useLayerSelectTranslation();

  return (
    <ToolContainerDraggable
      title={t('groups-and-keywords-dialog-title')}
      data-testid="keywordFilterResults"
      startSize={{ width: 349, height: 600 }}
      minWidth={312}
      minHeight={192}
      isOpen={isOpen}
      onClose={onClose}
      bounds={bounds}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
    >
      <Grid
        container
        size={{ xs: 12 }}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: 'background.paper',
          position: 'sticky',
          top: 0,
          zIndex: 100,
        }}
      >
        <Grid
          container
          size={{ xs: 6 }}
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            padding: '15px 15px 0 24px',
          }}
        >
          {t('select-all')}
        </Grid>
        <Grid
          container
          size={{ xs: 6 }}
          justifyContent="flex-end"
          alignContent="center"
          sx={{
            padding: '15px 15px 0 15px',
          }}
        >
          <KeywordFilterSelectAllSwitchConnect />
        </Grid>
      </Grid>
      <CustomAccordion title={t('groups')} detailsSx={accordionDetailsStyle}>
        <List dense={true} style={listStyle}>
          {filters.groups.map((group) => (
            <KeywordFilterResultsListItemConnect
              key={group.id}
              filter={group}
            />
          ))}
        </List>
      </CustomAccordion>
      <CustomAccordion title={t('keywords')} detailsSx={accordionDetailsStyle}>
        <List dense={true} style={listStyle}>
          {filters.keywords.map((keyword) => {
            return (
              <KeywordFilterResultsListItemConnect
                key={keyword.id}
                filter={keyword}
              />
            );
          })}
        </List>
      </CustomAccordion>
    </ToolContainerDraggable>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { CustomIconButton } from '@opengeoweb/shared';
import { Filter } from '@opengeoweb/theme';
import { uiActions, uiSelectors, uiTypes } from '@opengeoweb/store';
import { useAppSelector } from '../../store';

interface KeywordFilterButtonConnectProps {
  mapId: string;
  source?: uiTypes.Source;
  isMultiMap?: boolean;
}

export const KeywordFilterButtonConnect: React.FC<
  KeywordFilterButtonConnectProps
> = ({
  mapId,
  source = 'app',
  isMultiMap,
}: KeywordFilterButtonConnectProps) => {
  const dispatch = useDispatch();
  const dialogType = isMultiMap
    ? `${uiTypes.DialogTypes.KeywordFilter}-${mapId}`
    : uiTypes.DialogTypes.KeywordFilter;

  const currentActiveMapId = useAppSelector((store) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );

  const isOpenInStore = useAppSelector((store) =>
    uiSelectors.getisDialogOpen(store, dialogType),
  );

  const openFilterResultsDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: dialogType,
        mapId,
        setOpen: currentActiveMapId !== mapId ? true : !isOpenInStore,
        source,
      }),
    );
  }, [currentActiveMapId, dispatch, isOpenInStore, mapId, source, dialogType]);

  return (
    <CustomIconButton
      variant="tool"
      onClick={openFilterResultsDialog}
      isSelected={isOpenInStore}
      id="keywordFilterButton"
      data-testid="keywordFilterButton"
    >
      <Filter />
    </CustomIconButton>
  );
};

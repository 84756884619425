/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import {
  ReactHookFormDateTime,
  ReactHookFormNumberField,
} from '@opengeoweb/form-fields';

import { dateUtils } from '@opengeoweb/shared';
import { styles } from './EditFormFields.styles';
import { isInPastOrFutureAllowed, isRecentPast } from './validations';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

const PeakFlux: React.FC = () => {
  const { t } = useSpaceweatherTranslation();
  return (
    <Grid container size={12}>
      <Grid size={5}>
        <ReactHookFormNumberField
          name="peakflux"
          inputMode="decimal"
          sx={styles.inputField}
          slotProps={{ htmlInput: { 'data-testid': 'peakflux-input' } }}
          label={t('notification-peak-flux')}
          rules={{
            required: true,
            min: {
              value: 0,
              message: t('notification-peak-flux-error'),
            },
          }}
        />
      </Grid>
      <Grid size={5}>
        <ReactHookFormDateTime
          name="peakfluxtime"
          label={t('notification-peak-flux-time')}
          data-testid="peakfluxtime-picker"
          sx={styles.inputField}
          disableFuture
          rules={{
            required: true,
            validate: {
              isValidDate: dateUtils.isValidIsoDateString,
              isInPastOrFutureAllowed: (value: string) =>
                isInPastOrFutureAllowed(t, value),
              isRecentPast: (value: string) => isRecentPast(t, value),
            },
          }}
          shouldHideUTC
        />
      </Grid>
    </Grid>
  );
};

export default PeakFlux;

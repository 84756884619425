/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Add } from '@opengeoweb/theme';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Grid2 as Grid,
  Box,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import { isEqual } from 'lodash';
import { CustomIconButton } from '@opengeoweb/shared';
import { LayerType, queryWMSLayersTree } from '@opengeoweb/webmap';
import { serviceTypes } from '@opengeoweb/store';
import WMSServerList from '../../WMSLoader/WMSServerList/WMSServerList';
import WMSLayerTreeConnect from '../../WMSLoader/WMSLayerTree/WMSLayerTreeConnect';

import { preloadedDefaultMapServices } from '../../../utils/defaultConfigurations';
import { useCoreTranslation } from '../../../utils/i18n';

interface AddLayerPopupProps {
  onRenderTree?: (service: serviceTypes.InitialService) => React.ReactNode;
  preloadedServices?: serviceTypes.InitialService[];
  open?: boolean;
  handleClose?: () => void;
  layerType?: LayerType;
}

const validateServiceUrl = (url: string): boolean => {
  if (url === '' || url === null || typeof url === 'undefined') {
    return false;
  }
  const matcher = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|[0-9a-zA-Z]+[:?\d]*)\S*$/;
  if (!matcher.test(url)) {
    return false;
  }
  return true;
};

const AddLayersPopup: React.FC<AddLayerPopupProps> = ({
  onRenderTree,
  preloadedServices = preloadedDefaultMapServices,
  open = false,
  handleClose,
  layerType = LayerType.mapLayer,
}: AddLayerPopupProps) => {
  const { t } = useCoreTranslation();
  const [serviceURL, setServiceURL] = React.useState('');
  const [services, setServices] = React.useState(preloadedServices!);
  const [activeService, setActiveService] = React.useState(
    preloadedServices![0],
  );
  const [urlError, setUrlError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleChangeService = (service: serviceTypes.InitialService): void => {
    setActiveService(service);
  };

  const handleAddToList = (newServiceName: string): void => {
    setServices([
      {
        name: newServiceName,
        url: serviceURL,
        id: serviceURL,
      },
      ...services,
    ]);
    setServiceURL('');
  };

  const getServiceIndex = (url: string): number =>
    services.findIndex((serviceInList) => serviceInList.url === url);

  const handleAddServiceURL = (): void => {
    setLoading(true);
    const serviceIndex = getServiceIndex(serviceURL);
    // Check if service already exists in the list
    if (serviceIndex !== -1) {
      setActiveService(services[serviceIndex]);
      setServiceURL('');
      setUrlError('');
      setLoading(false);
      // Check if valid URL was passed
    } else if (validateServiceUrl(serviceURL)) {
      // Check if service is WMS server
      queryWMSLayersTree(serviceURL)
        .then((layerTreeFromPromise) => {
          // Shorten URL as a naming backup
          const shortenedURL = serviceURL.substring(
            serviceURL.indexOf('//') + 2,
            serviceURL.indexOf('?'),
          );
          handleAddToList(
            layerTreeFromPromise.title ||
              layerTreeFromPromise.name ||
              shortenedURL,
          );
          setUrlError('');
          setLoading(false);
        })
        .catch(() => {
          setUrlError(t('wms-loader-error-valid-service'));
          setLoading(false);
        });
    } else {
      setUrlError(t('wms-loader-error-valid-url'));
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isEqual(preloadedServices, services)) {
      setServices(preloadedServices!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadedServices]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      data-testid="addLayersPopup"
    >
      <DialogTitle id="form-dialog-title">
        {t('add-layer-popup-title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('wms-loader-description')}</DialogContentText>
        <Box m={2}>
          <Grid container direction="row" alignItems="center">
            <Grid size={12}>
              <TextField
                margin="dense"
                id="serviceurl"
                label={t('add-layer-popup-service-url')}
                value={serviceURL}
                type="url"
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ): void => {
                  const url = event.target.value.trim();
                  setServiceURL(url);
                  // No error message until new check
                  setUrlError('');
                }}
                onKeyPress={(
                  event: React.KeyboardEvent<HTMLInputElement>,
                ): void => {
                  if (event.key === 'Enter') {
                    handleAddServiceURL();
                  }
                }}
                fullWidth
                disabled={loading}
                error={urlError !== ''}
                helperText={urlError}
                variant="filled"
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {loading ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              zIndex: 1,
                            }}
                          />
                        ) : (
                          <CustomIconButton
                            data-testid="add-service"
                            onClick={handleAddServiceURL}
                            disabled={loading}
                            size="large"
                          >
                            <Add />
                          </CustomIconButton>
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box m={2}>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid size={4}>
              <WMSServerList
                availableServices={services!}
                service={activeService}
                handleChangeService={handleChangeService}
              />
            </Grid>
            <Grid size={8}>
              {onRenderTree ? (
                onRenderTree(activeService)
              ) : (
                <WMSLayerTreeConnect
                  mapId=""
                  service={activeService}
                  layerType={layerType}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('actions-close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLayersPopup;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { webmapUtils } from '@opengeoweb/webmap';
import { CoreAppStore } from '../../types';
import { mapSelectors } from '../../map';
import {
  AddLayerPayload,
  DeleteLayerPayload,
  MoveLayerPayload,
  SetAutoLayerIdPayload,
  SetBaseLayersPayload,
} from '../../map/types';
import { SyncLayerPayloads, LayerActionPayloadsWithLayerIds } from '../types';
import { syncGroupStore } from './selectors';
import { SyncGroupActionOrigin, SyncGroupTarget, SyncType } from './types';

interface FoundTargets {
  payload: SyncLayerPayloads;
  targetId: string;
  layerId: string;
}

/**
 * Tries to find the layerId's in the other map based on the payload of the action. It works for all layer actions.
 * @param state
 * @param mapId
 * @param targetMapId
 * @param payload
 * @returns
 */
export const getTargetLayerIdFromPayload = (
  state: CoreAppStore,
  mapId: string,
  targetMapId: string,
  payload: SyncLayerPayloads,
): string => {
  if (!payload) {
    return null!;
  }

  /* Try to find the layer for the DeleteLayerPayload, it uses layerIndex to reference the layer */
  if ('layerIndex' in (payload as DeleteLayerPayload)) {
    const tempLayerIndex = (payload as DeleteLayerPayload).layerIndex;
    if (tempLayerIndex !== undefined) {
      const targetLayer = mapSelectors.getLayerByLayerIndex(
        state,
        targetMapId,
        tempLayerIndex,
      );
      return !targetLayer ? null! : targetLayer.id!;
    }
  }

  /* Try to find the layer for the LayerActionsWithLayerIds, it uses layerId to reference the layer */
  if ('layerId' in (payload as LayerActionPayloadsWithLayerIds)) {
    const targetLayer = mapSelectors.getLayerByLayerIndex(
      state,
      targetMapId,
      mapSelectors.getLayerIndexByLayerId(
        state,
        mapId,
        (payload as LayerActionPayloadsWithLayerIds).layerId,
      ),
    );
    return !targetLayer ? null! : targetLayer.id!;
  }
  /* This payload probably does not reference to a layerId */
  return null!;
};

/**
 * Local helper function used by getLayerDeleteActionsTargets, getLayerActionsTargets, getAddLayerActionsTargets, getTargetLayerIdFromPayload to find the targets
 * @param state
 * @param payload
 * @param actionType
 * @param sourceMapId
 * @returns The found targets
 */
export const findTargets = (
  state: CoreAppStore,
  payload: SyncLayerPayloads,
  actionType: SyncType,
  sourceMapId: string,
): FoundTargets[] => {
  const actionPayloads: FoundTargets[] = [];
  const targetsInActionPayload = {} as SyncGroupTarget;
  const syncGroups = syncGroupStore(state);

  if (syncGroups && payload) {
    syncGroups.groups.allIds.forEach((id) => {
      const syncronizationGroup = syncGroups.groups.byId[id];
      if (actionType === syncronizationGroup.type) {
        /* Check if the source is in the target list of the synchonizationGroup */
        const source = syncronizationGroup.targets.byId[sourceMapId];
        /* If the source is part of the target list, and is linked, continue syncing the other targets */
        if (source && source.linked) {
          syncronizationGroup.targets.allIds.forEach((targetId) => {
            const target = syncronizationGroup.targets.byId[targetId];
            if (
              targetId !== sourceMapId &&
              target.linked &&
              !targetsInActionPayload[targetId]
            ) {
              /* Remember that we have already added this target in the action payloads, prevents adding it twice */
              targetsInActionPayload[targetId] = true;
              const otherLayerId = getTargetLayerIdFromPayload(
                state,
                sourceMapId,
                targetId,
                payload as SyncLayerPayloads,
              );

              actionPayloads.push({
                payload,
                targetId,
                layerId: otherLayerId,
              });
            }
          });
        }
      }
    });
  }
  return actionPayloads;
};

/**
 * These targets are found for layer actions that work with a layerId like: SetLayerName, SetLayerEnabled, SetLayerOpacity, SetLayerDimension and SetLayerStyle
 * @param state
 * @param payload
 * @param actionType
 * @returns
 */
export const getLayerActionsTargets = (
  state: CoreAppStore,
  payload: LayerActionPayloadsWithLayerIds,
  actionType: SyncType,
): LayerActionPayloadsWithLayerIds[] => {
  const mapId = mapSelectors.getMapIdFromLayerId(state, payload.layerId);
  if (!mapId) {
    return [];
  }
  const foundTargets = findTargets(state, payload, actionType, mapId);

  return foundTargets.map((target): LayerActionPayloadsWithLayerIds => {
    const { payload } = target;
    return {
      ...(payload as LayerActionPayloadsWithLayerIds),
      layerId: target.layerId,
      origin: SyncGroupActionOrigin.layerActions,
      mapId: payload.mapId,
    } as LayerActionPayloadsWithLayerIds;
  });
};

/**
 * These targets are found for the layer/map action AddLayer. AddLayer does not have a layerId (because it's new)
 * @param state
 * @param payload
 * @param actionType
 * @returns
 */
export const getAddLayerActionsTargets = (
  state: CoreAppStore,
  payload: AddLayerPayload,
  actionType: SyncType,
): AddLayerPayload[] => {
  const foundTargets = findTargets(state, payload, actionType, payload.mapId);

  return foundTargets.map(({ payload, targetId }): AddLayerPayload => {
    const { layer } = payload as AddLayerPayload;
    const { id, ...layerWithoutId } = layer;

    const layerId = webmapUtils.generateLayerId();
    return {
      ...payload,
      layer: layerWithoutId,
      layerId,
      mapId: targetId,
      origin: SyncGroupActionOrigin.add,
    };
  });
};

/**
 * These targets are found for the layer/map action DeleteLayer. The layer is already gone, so there is no layerId anymore. Using layerIndex instead.
 * @param state
 * @param payload
 * @param actionType
 * @returns
 */
export const getLayerDeleteActionsTargets = (
  state: CoreAppStore,
  payload: DeleteLayerPayload,
  actionType: SyncType,
): DeleteLayerPayload[] => {
  const foundTargets = findTargets(state, payload, actionType, payload.mapId);

  return foundTargets.map((target): DeleteLayerPayload => {
    return {
      ...(target.payload as DeleteLayerPayload),
      mapId: target.targetId,
      layerId: target.layerId,
      origin: SyncGroupActionOrigin.delete,
    };
  });
};

/**
 * These targets are found for the layer/map action MoveLayer. Here layers are referenced by newIndex and oldIndex.
 * @param state
 * @param payload
 * @param actionType
 * @returns
 */
export const getLayerMoveActionsTargets = (
  state: CoreAppStore,
  payload: MoveLayerPayload,
  actionType: SyncType,
): MoveLayerPayload[] => {
  const foundTargets = findTargets(state, payload, actionType, payload.mapId);

  return foundTargets.map((target): MoveLayerPayload => {
    return {
      ...(target.payload as MoveLayerPayload),
      mapId: target.targetId,
      origin: SyncGroupActionOrigin.move,
    };
  });
};

/**
 * These targets are found for the map action SetAutoLayerId. It needs both a target mapId and layerId
 * @param state
 * @param payload
 * @param actionType
 * @returns
 */
export const getSetAutoLayerIdActionsTargets = (
  state: CoreAppStore,
  payload: SetAutoLayerIdPayload,
  actionType: SyncType,
): LayerActionPayloadsWithLayerIds[] => {
  const payloadWithLayerId = {
    ...payload,
    layerId:
      payload.autoUpdateLayerId ||
      payload.autoTimeStepLayerId ||
      payload.layerId,
  };
  const foundTargets = findTargets(
    state,
    payloadWithLayerId,
    actionType,
    payload.mapId,
  );

  return foundTargets.map((target): LayerActionPayloadsWithLayerIds => {
    return {
      mapId: target.targetId,
      layerId: target.layerId,
      origin: SyncGroupActionOrigin.autoLayerId,
    };
  });
};

/**
 * These targets are found for baselayer actions that work with a mapId like: setBaseLayers
 * @param state
 * @param payload
 * @param actionType
 * @returns
 */
export const getMapBaseLayerActionsTargets = (
  state: CoreAppStore,
  payload: SetBaseLayersPayload,
  actionType: SyncType,
): SetBaseLayersPayload[] => {
  const foundTargets = findTargets(state, payload, actionType, payload.mapId);

  return foundTargets.map((target): SetBaseLayersPayload => {
    return {
      layers: payload.layers.map((layer) => {
        return { ...layer, id: webmapUtils.generateLayerId() };
      }),
      mapId: target.targetId,
      origin: SyncGroupActionOrigin.layerActions,
    };
  });
};

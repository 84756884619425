/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { useDispatch } from 'react-redux';
import { WorkspaceComponentLookupPayload } from '../../store/workspace/types';
import { workspaceActions } from '../../store/workspace/reducer';

interface ModuleMethods {
  onPreventCloseView?: (shouldPreventClose: boolean) => void;
}

interface ComponentsLookUpWrapperProps {
  component: React.FC<ModuleMethods> | React.FC;
  payload: WorkspaceComponentLookupPayload;
  [otherProps: string]: unknown;
}

// takes a component and connects module methods with workspace store
export const ComponentsLookUpWrapper: React.FC<
  ComponentsLookUpWrapperProps
> = ({
  component,
  payload,
  ...props
}: ComponentsLookUpWrapperProps): React.ReactElement => {
  const RenderComponent = component;
  const dispatch = useDispatch();
  const viewId = payload.id;

  const togglePreventClose = React.useCallback(
    (shouldPreventClose: boolean): void => {
      dispatch(
        workspaceActions.setPreventCloseView({
          viewId,
          shouldPreventClose,
        }),
      );
    },
    [dispatch, viewId],
  );

  return <RenderComponent onPreventCloseView={togglePreventClose} {...props} />;
};

export default ComponentsLookUpWrapper;

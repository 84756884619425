/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { LayerType } from '@opengeoweb/webmap';

export const baseLayer = {
  name: 'arcGisSat',
  title: 'arcGisSat',
  type: 'twms',
  layerType: LayerType.baseLayer,
  enabled: true,
  id: 'base-layer-0',
};

export const baseLayerGrey = {
  id: 'base-layer-1',
  name: 'WorldMap_Light_Grey_Canvas',
  type: 'twms',
  layerType: LayerType.baseLayer,
};

export const baseLayerOpenStreetMapNL = {
  id: 'base-layer-2',
  name: 'OpenStreetMap_NL',
  type: 'twms',
  layerType: LayerType.baseLayer,
  enabled: true,
};

export const baseLayerArcGisCanvas = {
  id: 'base-layer-3',
  name: 'arcGisCanvas',
  type: 'twms',
  layerType: LayerType.baseLayer,
  enabled: true,
};

export const baseLayerWorldMap = {
  id: 'base-layer-4',
  name: 'WorldMap',
  type: 'twms',
  layerType: LayerType.baseLayer,
  enabled: true,
};

export const baseLayerArcGisSat = {
  name: 'arcGisSat',
  title: 'arcGisSat',
  type: 'twms',
  layerType: LayerType.baseLayer,
  enabled: true,
  id: 'base-layer-5',
};

export const overLayer = {
  service: 'https://geoservices.knmi.nl/wms?DATASET=baselayers&',
  name: 'countryborders',
  format: 'image/png',
  layerType: LayerType.overLayer,
  enabled: true,
  id: 'over-layer-1',
};

export const overLayer2 = {
  service: 'https://geoservices.knmi.nl/wms?DATASET=baselayers&',
  name: 'countryborders',
  format: 'image/png',
  layerType: LayerType.overLayer,
  enabled: true,
  id: 'over-layer-2',
};

export const radarLayer = {
  service: 'https://geoservices.knmi.nl/wms?dataset=RADAR&',
  name: 'RAD_NL25_PCP_CM',
  format: 'image/png',
  enabled: true,
  style: 'knmiradar/nearest',
  id: 'layer-1',
  layerType: LayerType.mapLayer,
};

export const simplePolygonGeoJSON: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        stroke: '#8F8',
        'stroke-width': 4,
        'stroke-opacity': 1,
        fill: '#33ccFF',
        'fill-opacity': 0.5,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-4.21875, 54.16243396806779],
            [-0.3515625, 45.583289756006316],
            [14.0625, 49.38237278700955],
            [11.953125, 58.07787626787517],
            [1.40625, 57.70414723434193],
            [-4.21875, 54.16243396806779],
          ],
        ],
      },
    },
  ],
};

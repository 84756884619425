/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormSelect,
  ReactHookFormTextField,
  ReactHookFormNumberField,
} from '@opengeoweb/form-fields';

import { styles } from './EditFormFields.styles';
import {
  ThresholdValues,
  EventLevels,
  XrayClasses,
  ThresholdUnits,
} from '../../../types';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

interface ThresholdProps {
  hasEventLevel: boolean;
  hasThresholdUnit: boolean;
}

const Threshold: React.FC<ThresholdProps> = ({
  hasEventLevel,
  hasThresholdUnit,
}: ThresholdProps) => {
  const { t } = useSpaceweatherTranslation();
  const { watch, setValue } = useFormContext();

  const category = watch('category');
  const categorydetail = watch('categorydetail');

  React.useEffect(() => {
    const unit =
      category === 'ELECTRON_FLUX'
        ? ThresholdUnits[categorydetail as keyof typeof ThresholdUnits]
        : ThresholdUnits[category as keyof typeof ThresholdUnits];
    if (hasThresholdUnit) {
      setValue('thresholdunit', unit);
    }
  }, [category, categorydetail, setValue, hasThresholdUnit]);

  return (
    <Grid container size={12}>
      <Grid size={5}>
        {category === 'XRAY_RADIO_BLACKOUT' ||
        category === 'PROTON_FLUX' ||
        categorydetail === 'KP_INDEX' ? (
          <ReactHookFormSelect
            name="threshold"
            label={t('notification-threshold')}
            sx={styles.inputField}
            inputProps={{
              SelectDisplayProps: {
                'data-testid': 'threshold-select',
              },
            }}
            rules={{ required: true }}
            onChange={(event: SelectChangeEvent<unknown>): void => {
              const index = ThresholdValues[
                category as keyof typeof ThresholdValues
              ].findIndex((value: unknown) => value === event.target.value);
              if (hasEventLevel) {
                setValue(
                  'neweventlevel',
                  EventLevels[category as keyof typeof EventLevels][index],
                  {
                    shouldValidate: true,
                  },
                );
              }
              if (category === 'XRAY_RADIO_BLACKOUT') {
                setValue('xrayclass', XrayClasses[index], {
                  shouldValidate: true,
                });
              }
            }}
          >
            {ThresholdValues[category as keyof typeof ThresholdValues].map(
              (value) => (
                <MenuItem value={value} key={value}>
                  {value}
                </MenuItem>
              ),
            )}
          </ReactHookFormSelect>
        ) : (
          <ReactHookFormNumberField
            name="threshold"
            inputMode="decimal"
            sx={styles.inputField}
            slotProps={{ htmlInput: { 'data-testid': 'threshold-input' } }}
            label={t('notification-threshold')}
            rules={{
              required: true,
            }}
          />
        )}
      </Grid>
      {category !== 'GEOMAGNETIC' && (
        <Grid size={5}>
          <ReactHookFormTextField
            name="thresholdunit"
            slotProps={{ htmlInput: { 'data-testid': 'thresholdunit-input' } }}
            label={t('notification-threshold-unit')}
            sx={styles.inputField}
            disabled
            rules={{
              required: true,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Threshold;

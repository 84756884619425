/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { shallowEqual, useDispatch, useSelector, useStore } from 'react-redux';
import { mapSelectors, syncGroupsSelectors } from '@opengeoweb/store';
import { CustomIconButton, renderCounter } from '@opengeoweb/shared';
import { Preset } from '@opengeoweb/theme';
import { SxProps, Theme, useTheme } from '@mui/material';

import { InitialProps } from '@opengeoweb/core';
import {
  TimeSeriesModuleState,
  timeSeriesSelectors,
} from '@opengeoweb/timeseries';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import {
  publicWarningsSelectors,
  WarningModuleStore,
} from '@opengeoweb/warnings';
import {
  viewPresetOptionId,
  WorkspaceOptions,
} from '../WorkspaceOptions/WorkspaceOptions';
import {
  viewPresetActions,
  viewPresetSelectors,
} from '../../store/viewPresets';
import * as workspaceSelectors from '../../store/workspace/selectors';
import { AppStore } from '../../store/types';

import { PresetAction, ViewPresetDialog } from '../../store/viewPresets/types';
import { emptyViewPreset } from '../../store/viewPresets/utils';
import { LoadingIndicatorConnect } from '../WorkspaceView/LoadingIndicator/LoadingIndicatorConnect';
import { useWorkspaceTranslation } from '../../utils/i18n';
import { getPresetTitle } from '../../utils/utils';

export interface ToggleViewPresetDialogProps {
  panelId: string;
  isViewPresetDialogOpen: boolean;
}

interface WorkspaceViewTitleConnectProps {
  panelId: string;
  isViewPresetDialogOpen: boolean;
}

const customIconStyles = (
  theme: Theme,
  isViewPresetDialogOpen: boolean,
): SxProps<Theme> => {
  return {
    marginLeft: -1,
    marginRight: 1,
    '&&': {
      backgroundColor: !isViewPresetDialogOpen ? 'transparent' : undefined,
      '&:hover': {
        backgroundColor: 'geowebColors.workspace.tabButtonHover',
      },
      '.MuiSvgIcon-root > path': {
        fill: isViewPresetDialogOpen
          ? theme.palette.geowebColors.iconButtons.tool.active.color
          : undefined,
      },
    },
  };
};

const WorkspaceViewTitleConnectNoMemo: React.FC<
  WorkspaceViewTitleConnectProps
> = ({ panelId, isViewPresetDialogOpen }: WorkspaceViewTitleConnectProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const store = useStore<AppStore>();
  const { t } = useWorkspaceTranslation();
  const { currentRole, isLoggedIn } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;

  const workspaceView = useSelector((store: AppStore) => {
    const { title, scope, ...restProps } =
      workspaceSelectors.getViewById(store, panelId) ?? {};
    return { title, scope, ...restProps };
  }, shallowEqual);

  const viewComponentType = useSelector((store: AppStore) =>
    workspaceSelectors.getViewComponentType(store, panelId),
  );
  const hasViewPresetChanges = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetHasChanges(store, panelId),
  );

  const activeViewPresetId = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetActiveId(store, panelId),
  );
  const relatedSyncGroupIds = useSelector((store: AppStore) =>
    syncGroupsSelectors.getAllTargetGroupsForSource(store, panelId),
  );

  const presetAbstract = workspaceView?.abstract;

  const isNewActive = activeViewPresetId === '';

  const title = workspaceView.title || t('workspace-mappreset-new');
  const viewScope = workspaceView.scope;

  const fullTitle = getPresetTitle(
    title,
    hasViewPresetChanges!,
    isNewActive,
    t,
  );

  const openViewPresetsDialog = React.useCallback(
    (viewPresetDialogOptions: ViewPresetDialog) => {
      dispatch(
        viewPresetActions.openViewPresetDialog({
          viewPresetDialog: viewPresetDialogOptions,
        }),
      );
    },
    [dispatch],
  );
  const currentWarningsPreset = useSelector((store: WarningModuleStore) =>
    publicWarningsSelectors.getWarningsPresetById(store, panelId),
  );

  const constructInitialProps = React.useCallback(():
    | InitialProps
    | undefined => {
    switch (viewComponentType) {
      case 'WarningList':
        return currentWarningsPreset;
      case 'TimeSeries':
        return timeSeriesSelectors.getTimeSeriesPreset(
          store.getState() as TimeSeriesModuleState,
        );
      case 'Map':
        return {
          mapPreset: mapSelectors.getMapPreset(store.getState(), panelId),
          syncGroupsIds: relatedSyncGroupIds,
        };
      default:
        return undefined;
    }
  }, [
    currentWarningsPreset,
    panelId,
    relatedSyncGroupIds,
    store,
    viewComponentType,
  ]);

  const onSave = React.useCallback((): void => {
    dispatch(
      viewPresetActions.saveViewPreset({
        panelId,
        viewPresetId: activeViewPresetId!,
        viewPreset: {
          ...emptyViewPreset(viewComponentType),
          title,
          initialProps: constructInitialProps(),
        },
      }),
    );
  }, [
    activeViewPresetId,
    constructInitialProps,
    dispatch,
    panelId,
    title,
    viewComponentType,
  ]);

  const onDelete = React.useCallback((): void => {
    openViewPresetsDialog({
      title: t('workspace-mappreset-dialog-title-delete'),
      action: PresetAction.DELETE,
      viewPresetId: activeViewPresetId!,
      panelId,
      formValues: { title, abstract: presetAbstract },
    });
  }, [
    activeViewPresetId,
    openViewPresetsDialog,
    panelId,
    title,
    presetAbstract,
    t,
  ]);

  const onSaveAs = React.useCallback((): void => {
    const formTitle = activeViewPresetId ? title : '';
    openViewPresetsDialog({
      title: t('workspace-mappreset-save-as'),
      action: PresetAction.SAVE_AS,
      viewPresetId: activeViewPresetId!,
      panelId,
      formValues: {
        title: formTitle,
        initialProps: constructInitialProps(),
        abstract: presetAbstract,
      },
    });
  }, [
    activeViewPresetId,
    constructInitialProps,
    openViewPresetsDialog,
    panelId,
    t,
    title,
    presetAbstract,
  ]);

  const onEdit = React.useCallback((): void => {
    const formTitle = activeViewPresetId ? title : '';

    openViewPresetsDialog({
      title: t('workspace-mappreset-dialog-title-edit'),
      action: PresetAction.EDIT,
      panelId,
      viewPresetId: activeViewPresetId!,
      formValues: {
        title: formTitle,
        initialProps: constructInitialProps(),
        abstract: presetAbstract,
      },
    });
  }, [
    activeViewPresetId,
    constructInitialProps,
    openViewPresetsDialog,
    panelId,
    t,
    title,
    presetAbstract,
  ]);

  const onReset = React.useCallback((): void => {
    openViewPresetsDialog({
      title: t('workspace-reset-title'),
      action: PresetAction.RESET,
      viewPresetId: activeViewPresetId!,
      panelId,
      formValues: { title },
    });
  }, [activeViewPresetId, openViewPresetsDialog, panelId, t, title]);

  const toggleViewPresetDialog = React.useCallback(
    ({
      panelId,
      isViewPresetDialogOpen,
    }: ToggleViewPresetDialogProps): void => {
      dispatch(
        viewPresetActions.toggleViewPresetListDialog({
          panelId,
          isViewPresetListDialogOpen: isViewPresetDialogOpen,
        }),
      );
    },
    [dispatch],
  );

  const onClickOpenViewPreset = React.useCallback((): void => {
    toggleViewPresetDialog({
      panelId,
      isViewPresetDialogOpen: !isViewPresetDialogOpen,
    });
  }, [isViewPresetDialogOpen, panelId, toggleViewPresetDialog]);

  const style: SxProps<Theme> = React.useMemo(
    () => customIconStyles(theme, isViewPresetDialogOpen),
    [isViewPresetDialogOpen, theme],
  );

  const presetIcon = React.useMemo(() => <Preset />, []);

  renderCounter.count(WorkspaceViewTitleConnectNoMemo.name);

  const currentViewScope = viewScope || 'system';
  const isEnabled =
    (currentViewScope === 'user' ||
      (isAdmin && currentViewScope === 'system')) &&
    activeViewPresetId !== '' &&
    isLoggedIn;

  return (
    <>
      <CustomIconButton
        data-testid="open-viewpresets"
        sx={style}
        variant="tool"
        onClick={onClickOpenViewPreset}
        tooltipTitle={t('workspace-viewpreset-menu')}
        isSelected={isViewPresetDialogOpen}
      >
        {presetIcon}
      </CustomIconButton>
      <WorkspaceOptions
        panelId={panelId}
        title={fullTitle}
        hasChanges={hasViewPresetChanges || isNewActive}
        handleSave={isEnabled ? onSave : undefined}
        handleDelete={isEnabled ? onDelete : undefined}
        handleSaveAs={isLoggedIn ? onSaveAs : undefined}
        handleEdit={isEnabled ? onEdit : undefined}
        handleReset={onReset}
        id={viewPresetOptionId}
        isAdmin={isAdmin}
      />
      <LoadingIndicatorConnect panelId={panelId} />
    </>
  );
};

export const WorkspaceViewTitleConnect = React.memo(
  WorkspaceViewTitleConnectNoMemo,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  genericActions,
  mapActions,
  mapSelectors,
  CoreAppStore,
} from '@opengeoweb/store';

import { TimeSliderCurrentTimeBox } from '@opengeoweb/timeslider';

interface TimeSliderCurrentTimeBoxConnectComponentProps {
  mapId: string;
  timeStep?: number;
  centerTime?: number;
  secondsPerPx?: number;
  span?: number;
  isAutoUpdating?: boolean;
  setCenterTime?: typeof mapActions.setTimeSliderCenterTime;
  unfilteredSelectedTime: number;
  setUnfilteredSelectedTime: (unfilteredSelectedTime: number) => void;
  onCalendarSelect: (newTime: number) => void;
}

const connectRedux = connect(
  (
    store: CoreAppStore,
    props: TimeSliderCurrentTimeBoxConnectComponentProps,
  ) => ({
    centerTime: mapSelectors.getMapTimeSliderCenterTime(store, props.mapId),
    secondsPerPx: mapSelectors.getMapTimeSliderSecondsPerPx(store, props.mapId),
    span: mapSelectors.getMapTimeSliderSpan(store, props.mapId),
    timeStep: mapSelectors.getMapTimeStep(store, props.mapId),
    isAnimating: mapSelectors.isAnimating(store, props.mapId),
    isAutoUpdating: mapSelectors.isAutoUpdating(store, props.mapId),
  }),
  {
    stopMapAnimation: mapActions.mapStopAnimation,
    setTime: genericActions.setTime,
    setCenterTime: mapActions.setTimeSliderCenterTime,
  },
);

const TimeSliderCurrentTimeBoxConnectComponent: React.FC<
  TimeSliderCurrentTimeBoxConnectComponentProps
> = ({
  mapId,
  centerTime,
  secondsPerPx,
  span,
  timeStep,
  isAutoUpdating,
  setCenterTime,
  unfilteredSelectedTime,
  setUnfilteredSelectedTime,
  onCalendarSelect,
}: TimeSliderCurrentTimeBoxConnectComponentProps) => {
  const [dataStartTime, dataEndTime] = useSelector((store: CoreAppStore) =>
    mapSelectors.getDataLimitsFromLayers(store, mapId),
  );

  return (
    <TimeSliderCurrentTimeBox
      centerTime={centerTime!}
      secondsPerPx={secondsPerPx!}
      span={span}
      dataStartTime={dataStartTime}
      dataEndTime={dataEndTime}
      timeStep={timeStep}
      isAutoUpdating={isAutoUpdating}
      unfilteredSelectedTime={unfilteredSelectedTime}
      setUnfilteredSelectedTime={setUnfilteredSelectedTime}
      onCalendarSelect={onCalendarSelect}
      onSetCenterTime={(newTime: number): void => {
        setCenterTime!({ mapId, timeSliderCenterTime: newTime });
      }}
    />
  );
};

export const TimeSliderCurrentTimeBoxConnect = connectRedux(
  TimeSliderCurrentTimeBoxConnectComponent,
);

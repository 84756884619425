/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import {
  initReactI18next,
  useTranslation,
  UseTranslationResponse,
} from 'react-i18next';
import {
  WORKSPACE_NAMESPACE,
  workspaceTranslations,
} from '@opengeoweb/workspace';
import { CAP_NAMESPACE, capTranslations } from '@opengeoweb/cap';
import { WARN_NAMESPACE, warningsTranslations } from '@opengeoweb/warnings';
import { THEME_NAMESPACE, themeTranslations } from '@opengeoweb/theme';
import {
  WEBMAP_REACT_NAMESPACE,
  webmapReactTranslations,
  WEBMAP_NAMESPACE,
  webmapTranslations,
} from '@opengeoweb/webmap-react';
import {
  TIMESERIES_NAMESPACE,
  timeseriesTranslations,
} from '@opengeoweb/timeseries';
import { SA_NAMESPACE, saTranslations } from '@opengeoweb/sigmet-airmet';
import { AUTH_NAMESPACE, authTranslations } from '@opengeoweb/authentication';
import { CORE_NAMESPACE, coreTranslations } from '@opengeoweb/core';
import {
  SPACEWEATHER_NAMESPACE,
  swTranslations,
} from '@opengeoweb/spaceweather';
import {
  LAYER_SELECT_NAMESPACE,
  layerSelectTranslations,
} from '@opengeoweb/layer-select';
import { SHARED_NAMESPACE, sharedTranslations } from '@opengeoweb/shared';
import { TAF_NAMESPACE, tafTranslations } from '@opengeoweb/taf';
import {
  TIMESLIDER_NAMESPACE,
  timesliderTranslations,
} from '@opengeoweb/timeslider';
import { SNACKBAR_NAMESPACE, snackbarTranslations } from '@opengeoweb/snackbar';
import {
  FORM_FIELDS_NAMESPACE,
  formFieldsTranslations,
} from '@opengeoweb/form-fields';
import { API_NAMESPACE, apiTranslations } from '@opengeoweb/api';
import geowebTranslations from './app/locales/geoweb.json';

export const GEOWEB_NAMESPACE = 'geoweb';

export const initGeowebI18n = (): void => {
  void i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    defaultNS: GEOWEB_NAMESPACE,
    resources: {
      en: {
        [WORKSPACE_NAMESPACE]: workspaceTranslations.en,
        [CAP_NAMESPACE]: capTranslations.en,
        [WARN_NAMESPACE]: warningsTranslations.en,
        [THEME_NAMESPACE]: themeTranslations.en,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.en,
        [WEBMAP_NAMESPACE]: webmapTranslations.en,
        [TIMESERIES_NAMESPACE]: timeseriesTranslations.en,
        [SA_NAMESPACE]: saTranslations.en,
        [AUTH_NAMESPACE]: authTranslations.en,
        [CORE_NAMESPACE]: coreTranslations.en,
        [SPACEWEATHER_NAMESPACE]: swTranslations.en,
        [LAYER_SELECT_NAMESPACE]: layerSelectTranslations.en,
        [SHARED_NAMESPACE]: sharedTranslations.en,
        [TAF_NAMESPACE]: tafTranslations.en,
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.en,
        [SNACKBAR_NAMESPACE]: snackbarTranslations.en,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.en,
        [API_NAMESPACE]: apiTranslations.en,
        [GEOWEB_NAMESPACE]: geowebTranslations.en,
      },
      fi: {
        [WORKSPACE_NAMESPACE]: workspaceTranslations.fi,
        [CORE_NAMESPACE]: coreTranslations.fi,
        [CAP_NAMESPACE]: capTranslations.fi,
        [WARN_NAMESPACE]: warningsTranslations.fi,
        [THEME_NAMESPACE]: themeTranslations.fi,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.fi,
        [WEBMAP_NAMESPACE]: webmapTranslations.fi,
        [TIMESERIES_NAMESPACE]: timeseriesTranslations.fi,
        [SA_NAMESPACE]: saTranslations.fi,
        [AUTH_NAMESPACE]: authTranslations.fi,
        [SPACEWEATHER_NAMESPACE]: swTranslations.fi,
        [LAYER_SELECT_NAMESPACE]: layerSelectTranslations.fi,
        [SHARED_NAMESPACE]: sharedTranslations.fi,
        [TAF_NAMESPACE]: tafTranslations.fi,
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.fi,
        [SNACKBAR_NAMESPACE]: snackbarTranslations.fi,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.fi,
        [API_NAMESPACE]: apiTranslations.fi,
        [GEOWEB_NAMESPACE]: geowebTranslations.fi,
      },
      no: {
        [WORKSPACE_NAMESPACE]: workspaceTranslations.no,
        [WARN_NAMESPACE]: warningsTranslations.no,
        [THEME_NAMESPACE]: themeTranslations.no,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.no,
        [WEBMAP_NAMESPACE]: webmapTranslations.no,
        [TIMESERIES_NAMESPACE]: timeseriesTranslations.no,
        [SA_NAMESPACE]: saTranslations.no,
        [AUTH_NAMESPACE]: authTranslations.no,
        [CORE_NAMESPACE]: coreTranslations.no,
        [SPACEWEATHER_NAMESPACE]: swTranslations.no,
        [LAYER_SELECT_NAMESPACE]: layerSelectTranslations.no,
        [SHARED_NAMESPACE]: sharedTranslations.no,
        [TAF_NAMESPACE]: tafTranslations.no,
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.no,
        [SNACKBAR_NAMESPACE]: snackbarTranslations.no,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.no,
        [API_NAMESPACE]: apiTranslations.no,
        [GEOWEB_NAMESPACE]: geowebTranslations.no,
      },
      nl: {
        [WORKSPACE_NAMESPACE]: workspaceTranslations.nl,
        [WARN_NAMESPACE]: warningsTranslations.nl,
        [THEME_NAMESPACE]: themeTranslations.nl,
        [WEBMAP_REACT_NAMESPACE]: webmapReactTranslations.nl,
        [WEBMAP_NAMESPACE]: webmapTranslations.nl,
        [TIMESERIES_NAMESPACE]: timeseriesTranslations.nl,
        [SA_NAMESPACE]: saTranslations.nl,
        [AUTH_NAMESPACE]: authTranslations.nl,
        [CORE_NAMESPACE]: coreTranslations.nl,
        [SPACEWEATHER_NAMESPACE]: swTranslations.nl,
        [LAYER_SELECT_NAMESPACE]: layerSelectTranslations.nl,
        [SHARED_NAMESPACE]: sharedTranslations.nl,
        [TAF_NAMESPACE]: tafTranslations.nl,
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.nl,
        [SNACKBAR_NAMESPACE]: snackbarTranslations.nl,
        [CAP_NAMESPACE]: capTranslations.nl,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.nl,
        [API_NAMESPACE]: apiTranslations.nl,
        [GEOWEB_NAMESPACE]: geowebTranslations.nl,
      },
    }, // load non-library specific translations here
    interpolation: {
      escapeValue: false,
    },
  });
};
export const useGeowebTranslation = (): UseTranslationResponse<
  typeof GEOWEB_NAMESPACE,
  typeof i18n
> => useTranslation(GEOWEB_NAMESPACE);

export const translateKeyOutsideComponents = (
  key: string,
  params: Record<string, string | number> | undefined = undefined,
): string =>
  i18n.t(key, {
    ns: [
      AUTH_NAMESPACE,
      FORM_FIELDS_NAMESPACE,
      GEOWEB_NAMESPACE,
      SPACEWEATHER_NAMESPACE,
      WORKSPACE_NAMESPACE,
    ],
    ...params,
  });

export default i18n;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC } from 'react';
import { Box, Grid2 as Grid } from '@mui/material';
import { groupBy } from 'lodash';
import { Position, ToolContainerDraggable } from '@opengeoweb/shared';
import { ReactSortable, SortableEvent } from 'react-sortablejs';
import { useTimeseriesTranslation } from '../../utils/i18n';
import { Parameter, Plot, PlotPreset } from '../TimeSeries/types';
import { PlotRows } from './PlotRows';
import {
  RIGHT_ACTION_WIDTH,
  LEFT_ACTION_WIDTH,
  timeSeriesManagerStyle,
} from './TimeSeriesManagerUtils';
import { AddPlotButton } from '../TimeSeriesAddPlot/TimeSeriesAddPlot';
import { MoveParameterPayload, UpdateTitlePayload } from '../../store/types';

export const styles = {
  headerText: {
    fontSize: 12,
    opacity: 0.67,
  },
};

export interface CustomSortableEvent extends SortableEvent {
  oldIndex: number;
  newIndex: number;
}

export const TimeSeriesManager: FC<{
  onClose: () => void;
  onMouseDown: () => void;
  isOpen: boolean;
  order: number;
  plotState: PlotPreset;
  addPlot: (title: string) => void;
  deletePlot: (id: string) => void;
  togglePlot: (id: string) => void;
  addParameter: (parameter: Parameter) => void;
  deleteParameter: (id: string) => void;
  toggleParameter: (id: string) => void;
  selectPlotId: string;
  setSelectPlotId: (id: string) => void;
  startPosition?: Position;
  patchParameter: (parameter: Partial<Parameter>) => void;
  movePlot: (event: CustomSortableEvent) => void;
  moveParameter: (event: MoveParameterPayload) => void;
  updateTitle: (payload: UpdateTitlePayload) => void;
  startSize?: { width: number; height: number };
}> = ({
  onClose,
  isOpen,
  onMouseDown,
  order,
  plotState,
  addPlot,
  deletePlot,
  togglePlot,
  addParameter,
  deleteParameter,
  toggleParameter,
  selectPlotId,
  setSelectPlotId,
  startPosition = { top: 85, left: 50 },
  patchParameter,
  movePlot,
  moveParameter,
  updateTitle,
  startSize = { width: 720, height: 300 },
}) => {
  const [isSorting, setIsSorting] = React.useState(false);
  const { t } = useTimeseriesTranslation();
  const parametersGroupedByPlot = groupBy(
    plotState.parameters,
    (parameter) => parameter.plotId,
  );

  const plotHierarchy: Plot[] = plotState.plots.map((plot) => {
    const plotParameters = parametersGroupedByPlot[plot.plotId];
    return { ...plot, parameters: plotParameters };
  });

  const onSortEnd = React.useCallback(
    (event: SortableEvent): void => {
      const { oldIndex, newIndex } = event;
      if (oldIndex !== undefined && newIndex !== undefined) {
        movePlot({ oldIndex, newIndex } as CustomSortableEvent);
      }
    },
    [movePlot],
  );

  const onStart = React.useCallback(() => {
    setIsSorting(true);
  }, []);

  const onEnd = React.useCallback(() => {
    setIsSorting(false);
  }, []);

  const showTooltip = !isSorting;

  return (
    <ToolContainerDraggable
      title="Time Series Manager"
      startSize={startSize}
      minWidth={320}
      minHeight={126}
      startPosition={startPosition}
      isOpen={isOpen}
      onMouseDown={onMouseDown}
      order={order}
      onClose={onClose}
      className="timeseriesmanager"
      sx={{ ...timeSeriesManagerStyle }}
    >
      <Box sx={{ padding: '0 6px' }} className="timeSeriesManagerContainer">
        <Grid
          container
          sx={{
            alignItems: 'center',
            height: 32,
            margin: '8px 0 8px 0',
          }}
        >
          <Grid sx={{ width: LEFT_ACTION_WIDTH }}>
            <AddPlotButton handleClick={addPlot} plotState={plotState} t={t} />
          </Grid>
          <Grid sx={[styles.headerText]} className="titleCollectionWidth">
            {t('timeseries-collection')}
          </Grid>
          <Grid sx={[styles.headerText]} className="titleParameterWidth">
            {t('timeseries-parameter')}
          </Grid>
          <Grid sx={[styles.headerText]} className="titleStyleWidth">
            {t('timeseries-style')}
          </Grid>
          <Grid sx={[styles.headerText]} className="titleTimeWidth">
            {t('timeseries-ref-time')}
          </Grid>
          <Grid sx={{ width: RIGHT_ACTION_WIDTH }} />
        </Grid>
        <ReactSortable
          onSort={onSortEnd}
          list={plotHierarchy.map((plot) => ({ id: plot.plotId }))}
          setList={(): void => {}}
          animation={200}
          swapThreshold={0.3} // Nested sortable gets jumpy without this. Cant be lower because cypress will fail.
          onStart={onStart}
          onEnd={onEnd}
          direction="vertical"
          group={{ name: 'plot', pull: true, put: ['plot'] }}
        >
          {plotHierarchy.map((plot, i) => (
            <PlotRows
              rowIndex={i}
              showTooltip={showTooltip}
              key={plot.plotId}
              plot={plot}
              deletePlot={deletePlot}
              togglePlot={togglePlot}
              addParameter={addParameter}
              deleteParameter={deleteParameter}
              toggleParameter={toggleParameter}
              selectPlotId={selectPlotId}
              setSelectPlotId={setSelectPlotId}
              patchParameter={patchParameter}
              moveParameter={moveParameter}
              updateTitle={updateTitle}
              t={t}
            />
          ))}
        </ReactSortable>
      </Box>
    </ToolContainerDraggable>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n, { TFunction } from 'i18next';
import webmapTranslations from '../../../locales/webmap.json';

export const WEBMAP_NAMESPACE = 'webmap';

// do not use `initReactI18next` as this is not a react library
export const initWebmapTestI18n = (): void => {
  void i18n.init({
    lng: 'en',
    ns: WEBMAP_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [WEBMAP_NAMESPACE]: webmapTranslations.en,
      },
      fi: {
        [WEBMAP_NAMESPACE]: webmapTranslations.fi,
      },
      no: {
        [WEBMAP_NAMESPACE]: webmapTranslations.no,
      },
      nl: {
        [WEBMAP_NAMESPACE]: webmapTranslations.nl,
      },
    },
  });
};

export { i18n };

export const translateKeyOutsideComponents = (
  t: TFunction | undefined,
  key: string,
  params: Record<string, string | number> | undefined = undefined,
): string => (t ? t(key, { ns: WEBMAP_NAMESPACE, ...params }) : key);

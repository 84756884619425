/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { WMImage, webmapUtils } from '@opengeoweb/webmap';

const MILLISECONDS_PER_SECOND = 1e3;
export const MAX_DURATION_IN_SECONDS = 60;
export const MIN_DURATION_IN_SECONDS = 0.1;
export const DECIMALS_IN_LABEL = 1;
export const DECIMALS_IN_TOOLTIP = 3;

const LoadDuration: React.FC<{ mapId: string; layerId: string }> = ({
  mapId,
  layerId,
}) => {
  const loadDurationLabel = useLoadDurationLabel(mapId, layerId);
  const [tooltipText, setTooltipText] = React.useState<string | undefined>();

  return (
    <Tooltip
      placement="top"
      title={tooltipText}
      open={tooltipText !== undefined}
      onClose={() => setTooltipText(undefined)}
      onOpen={() => {
        const duration = getImageLoadDuration(layerId);
        if (duration) {
          setTooltipText(getDurationTooltip(duration));
        }
      }}
    >
      <Typography
        component="div"
        variant="subtitle2"
        whiteSpace="nowrap"
        data-testid="loadDuration"
        sx={{ fontSize: 12 }}
      >
        {loadDurationLabel}
      </Typography>
    </Tooltip>
  );
};

const getDurationTooltip = (milliSeconds?: number): string => {
  if (milliSeconds === undefined) {
    return '-';
  }

  return `${roundToDecimals(milliSeconds / MILLISECONDS_PER_SECOND, DECIMALS_IN_TOOLTIP)} s`;
};

const getImageLoadDuration = (layerId: string): number | undefined => {
  const layer = webmapUtils.getWMLayerById(layerId);

  const currentRequestedGetMapURL = layer?.getCurrentRequestedGetMapURL();

  if (currentRequestedGetMapURL === undefined) {
    return undefined;
  }

  const imageStore = layer.parentMap.getImageStore();
  const img = imageStore.getImageForSrc(currentRequestedGetMapURL);

  const loadDurationMs = img?.getLoadDuration();

  return loadDurationMs;
};

const roundToDecimals = (num: number, decimals: number): string => {
  return num.toLocaleString('en', {
    maximumFractionDigits: decimals,
    useGrouping: false,
  });
};

/**
 * Hook to subscribe to changes in the load duration for a map layer.
 * This will only trigger a render when the load duration rounded down to seconds changes for the given layer and map combination
 * @param mapId
 * @param layerId
 * @returns {number}
 */
const useLoadDurationLabel = (
  mapId: string,
  layerId: string,
): string | undefined => {
  const subscribe = React.useCallback(
    (onStoreChange: VoidFunction): VoidFunction => {
      const map = webmapUtils.getWMJSMapById(mapId);
      const layer = webmapUtils.getWMLayerById(layerId);

      const onImageLoad = (image: WMImage): void => {
        const currentRequestedGetMapURL = layer?.getCurrentRequestedGetMapURL();
        if (image.getSrc() === currentRequestedGetMapURL) {
          onStoreChange();
        }
      };

      map
        ?.getListener()
        .addEventListener(
          'onimagebufferimageload',
          onImageLoad as (param: unknown) => void,
        );
      return () => {
        map
          ?.getListener()
          ?.removeEventListener(
            'onimagebufferimageload',
            onImageLoad as (param: unknown) => void,
          );
      };
    },
    [mapId, layerId],
  );

  const getSnapshot = React.useCallback(() => {
    const loadDurationMs = getImageLoadDuration(layerId);

    if (loadDurationMs === undefined) {
      return '-';
    }

    const loadDurationInSeconds = loadDurationMs / MILLISECONDS_PER_SECOND;

    if (loadDurationInSeconds > MAX_DURATION_IN_SECONDS) {
      return `> ${MAX_DURATION_IN_SECONDS.toLocaleString('en')} s`;
    }

    if (loadDurationInSeconds < MIN_DURATION_IN_SECONDS) {
      return `< ${MIN_DURATION_IN_SECONDS.toLocaleString('en')} s`;
    }

    return `${roundToDecimals(loadDurationInSeconds, DECIMALS_IN_LABEL)} s`;
  }, [layerId]);

  return React.useSyncExternalStore(subscribe, getSnapshot);
};

export default LoadDuration;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { TooltipSelect } from '@opengeoweb/shared';
import { useCoreTranslation } from '../../../../../utils/i18n';

export const AcceptanceTime: FC<{
  isEnabled?: boolean;
  acceptanceTimeInMinutes: number | undefined;
  onChangeAcceptanceTime: (acceptanceTime: number | undefined) => void;
  icon?: React.ElementType;
  tooltipTitle?: string;
  skipLocalStyling?: boolean;
}> = ({
  onChangeAcceptanceTime,
  isEnabled = true,
  acceptanceTimeInMinutes,
  icon,
  tooltipTitle,
  skipLocalStyling,
}) => {
  const { t } = useCoreTranslation();
  const onChange = (acceptanceTimeRaw: string): void => {
    const acceptanceTime =
      acceptanceTimeRaw === 'off' ? undefined : Number(acceptanceTimeRaw);
    onChangeAcceptanceTime(acceptanceTime);
  };

  const layerAcceptanceTime =
    acceptanceTimeInMinutes === undefined
      ? 'off'
      : acceptanceTimeInMinutes.toString();

  const getAcceptenceTimes = (): {
    acceptanceTimes: { value: string; text: string }[];
    acceptanceTimesValues: { value: string }[];
  } => {
    const acceptanceTimes = [
      { value: 'off', text: t('layermanager-acceptance-time-off') },
      { value: '0', text: t('layermanager-acceptance-time-exact') },
      { value: '1', text: '00:01' },
      { value: '5', text: '00:05' },
      { value: '10', text: '00:10' },
      { value: '30', text: '00:30' },
      { value: '60', text: '01:00' },
      { value: '720', text: '12:00' },
      { value: '1440', text: '24:00' },
    ];
    const acceptanceTimesValues = acceptanceTimes.map((time) => ({
      value: time.value,
    }));

    return { acceptanceTimes, acceptanceTimesValues };
  };

  const { acceptanceTimes, acceptanceTimesValues } =
    React.useRef(getAcceptenceTimes()).current;

  const title = t('layermanager-acceptance-time');
  return (
    <TooltipSelect
      tooltip={tooltipTitle ?? title}
      isEnabled={isEnabled}
      value={layerAcceptanceTime}
      onChange={(event): void => {
        onChange(event.target.value as string);
      }}
      onChangeMouseWheel={(e): void => {
        onChange(e.value);
      }}
      requiresCtrlToChange={true}
      list={acceptanceTimesValues}
      currentIndex={acceptanceTimes.findIndex(
        (time) => layerAcceptanceTime === time.value,
      )}
      IconComponent={icon}
      data-testid="acceptanceTimeButton"
      skipLocalStyling={skipLocalStyling}
    >
      <MenuItem disabled>{title}</MenuItem>
      {acceptanceTimes.map((accTime) => (
        <MenuItem key={accTime.text} value={accTime.value}>
          {accTime.text}
        </MenuItem>
      ))}
    </TooltipSelect>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Cached } from '@opengeoweb/theme';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CustomIconButton } from '../CustomIconButton';
import { SHARED_NAMESPACE } from '../../utils/i18n';

export const getLastUpdateTitle = (time: string, t: TFunction): string => {
  return t('shared-last-updated', { time });
};

interface LastUpdateTimeProps {
  onPressRefresh: () => void;
  lastUpdateTime?: string;
  dataTestId?: string;
}

const LastUpdateTime: React.FC<LastUpdateTimeProps> = ({
  onPressRefresh = (): void => {},
  lastUpdateTime,
  dataTestId,
}: LastUpdateTimeProps) => {
  const { t } = useTranslation(SHARED_NAMESPACE);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'flex-end',
      }}
    >
      <Typography
        sx={{
          color: 'geowebColors.captions.captionStatus.rgba',
          fontSize: '10px',
          lineHeight: '1.6',
          letterSpacing: '0.33px',
        }}
        data-testid={dataTestId}
      >
        {getLastUpdateTitle(lastUpdateTime!, t)}
      </Typography>
      <CustomIconButton
        data-testid="refresh-button"
        onClick={onPressRefresh}
        sx={{ marginRight: { xs: 2, sm: 1 }, marginLeft: 1 }}
        tooltipTitle={t('shared-click-to-refresh')}
        variant="tool"
      >
        <Cached />
      </CustomIconButton>
    </Box>
  );
};

export default LastUpdateTime;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { selectorMemoizationOptions } from '@opengeoweb/store';
import { AppStore } from '../types';
import { viewPresetsListAdapter } from './reducer';
import { ViewPresetListItem, ViewPresetListState } from './types';
import {
  getViewPresetListFilterResultsForView,
  getViewPresetListFiltersForView,
  getViewPresetListSearchQueryForView,
} from '../viewPresets/selectors';
import { ViewPresetsListFilter } from '../viewPresets/types';

export const getViewPresetsListStore = (
  store: AppStore,
): ViewPresetListState => {
  if (store && store.viewPresetsList) {
    return store.viewPresetsList;
  }
  return null!;
};

// Used to retrieve the unfiltered, general view preset list.
// To ensure you retrieve the view preset list specific for your view, use getFilteredViewPresetListForView
export const { selectAll: getViewPresetsList, selectById } =
  viewPresetsListAdapter.getSelectors((state: AppStore) => {
    return state?.viewPresetsList ?? { entities: {}, ids: [] };
  });

// Retrieves the viewpreset list for your selected view
// If no filters apply it returns the general list, if you have filters it returns the filtered list
export const getFilteredViewPresetListForView = createSelector(
  getViewPresetsList,
  getViewPresetListFilterResultsForView,
  getViewPresetListFiltersForView,
  getViewPresetListSearchQueryForView,
  (
    fullViewPresetsList,
    filteredResults,
    appliedFilters,
    searchQuery,
  ): ViewPresetListItem[] => {
    // If we have a filtered results list, use this, otherwise fall back to the general unfiltered view presets list
    // If all filters are set to true we are not filtering
    const filtersApplied = !appliedFilters.every(
      (filter: ViewPresetsListFilter) => filter.isSelected,
    );
    if (filtersApplied || searchQuery !== '') {
      return filteredResults;
    }
    return fullViewPresetsList ?? { entities: {}, ids: [] };
  },
  selectorMemoizationOptions,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  CoreAppStore,
  drawingToolActions,
  drawingToolSelectors,
  layerActions,
  layerSelectors,
  uiSelectors,
} from '@opengeoweb/store';
import { useDispatch, useSelector } from 'react-redux';
import { DrawMode } from '@opengeoweb/webmap-react';
import { DrawingToolForm } from './DrawingToolForm';
import { selectDrawByInstanceId } from '../../store/warningsDrawings/selectors';
import { warningsDrawingsActions } from '../../store/warningsDrawings/reducer';
import { drawingDialogType } from '../../store/warningsDrawings/utils';
import { DrawingToolFormValues } from './DrawingToolFormContents';

export const DrawingToolFormConnect: React.FC = () => {
  const dispatch = useDispatch();
  const drawing = useSelector((store: CoreAppStore) =>
    selectDrawByInstanceId(store, drawingDialogType),
  );
  const drawToolId = drawingDialogType;
  const drawTool = useSelector((store: CoreAppStore) =>
    drawingToolSelectors.selectDrawToolById(store, drawToolId),
  );

  const layerFeatureGeoJSON = useSelector((store: CoreAppStore) =>
    layerSelectors.getFeatureLayerGeoJSON(store, drawTool?.geoJSONLayerId),
  );

  const isLayerInEditMode = useSelector((store: CoreAppStore) =>
    layerSelectors.getIsLayerInEditMode(store, drawTool?.geoJSONLayerId),
  );

  const featureLayerGeoJSONProperties = useSelector((store: CoreAppStore) =>
    layerSelectors.getFeatureLayerGeoJSONProperties(
      store,
      drawTool?.geoJSONLayerId,
    ),
  );

  const isDialogLoading = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogIsLoading(store, drawToolId),
  );

  const dialogError = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogError(store, drawToolId),
  );

  const onSubmitForm = React.useCallback(
    (formValues: DrawingToolFormValues): void => {
      dispatch(
        warningsDrawingsActions.submitDrawValues({
          geoJSON: formValues.geoJSON,
          objectName: formValues.objectName,
          scope: 'user',
          id: drawing?.id,
        }),
      );
    },
    [dispatch, drawing?.id],
  );
  const onChangeGeoJSONProperties = React.useCallback(
    (properties: GeoJSON.GeoJsonProperties): void => {
      dispatch(
        layerActions.updateFeatureProperties({
          layerId: drawTool?.geoJSONLayerId!,
          properties,
          origin: 'DrawingToolFormConnect',
        }),
      );
    },
    [dispatch, drawTool?.geoJSONLayerId],
  );

  const onChangeDrawMode = React.useCallback(
    (tool: DrawMode): void => {
      dispatch(
        drawingToolActions.changeDrawToolMode({
          drawToolId,
          drawModeId: tool.drawModeId,
        }),
      );
    },
    [dispatch, drawToolId],
  );

  const onDeactivateTool = React.useCallback((): void => {
    dispatch(
      layerActions.toggleFeatureMode({
        layerId: drawTool?.geoJSONLayerId!,
        isInEditMode: false,
        drawMode: '',
      }),
    );
    if (drawTool?.activeDrawModeId) {
      dispatch(
        drawingToolActions.changeDrawToolMode({
          drawToolId,
          drawModeId: drawTool?.activeDrawModeId,
        }),
      );
    }
  }, [
    dispatch,
    drawTool?.activeDrawModeId,
    drawTool?.geoJSONLayerId,
    drawToolId,
  ]);

  const onChangeName = React.useCallback(
    (newName: string): void => {
      dispatch(
        warningsDrawingsActions.setDrawValues({
          objectName: newName,
          id: drawing?.id || '',
          drawingInstanceId: drawingDialogType,
          isFormDirty: drawing?.isFormDirty,
        }),
      );
    },
    [dispatch, drawing?.id, drawing?.isFormDirty],
  );

  const onChangeFormDirty = React.useCallback(
    (isFormDirty: boolean): void => {
      dispatch(
        warningsDrawingsActions.setFormDirty({
          drawingInstanceId: drawingDialogType,
          isFormDirty,
        }),
      );
    },
    [dispatch],
  );

  return (
    <DrawingToolForm
      id={drawing?.id}
      onSubmitForm={onSubmitForm}
      objectName={drawing?.objectName}
      isLoading={isDialogLoading}
      dialogError={dialogError}
      // new
      drawModes={drawTool?.drawModes!}
      onChangeDrawMode={onChangeDrawMode}
      activeDrawModeId={drawTool?.activeDrawModeId || ''}
      onDeactivateTool={onDeactivateTool}
      isInEditMode={isLayerInEditMode}
      geoJSONProperties={featureLayerGeoJSONProperties}
      onChangeProperties={onChangeGeoJSONProperties}
      onChangeName={onChangeName}
      geoJSON={layerFeatureGeoJSON}
      onFormDirty={onChangeFormDirty}
    />
  );
};

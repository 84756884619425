/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormHiddenInput,
  isGeometryDirty,
} from '@opengeoweb/form-fields';
import { useApiContext } from '@opengeoweb/api';
import {
  DrawMode,
  DrawModeExitCallback,
  MapViewLayerProps,
  addFeatureProperties,
  rewindGeometry,
  useMapDrawTool,
} from '@opengeoweb/webmap-react';
import {
  CancelSigmet,
  Sigmet,
  FormMode,
  StartOrEndDrawing,
  ProductConfig,
  AviationProduct,
  AviationPhenomenon,
} from '../../../types';

import { formStyles, styles } from '../ProductForm.styles';
import {
  Phenomenon,
  VolcanicFields,
  ObservationForecast,
  Change,
  Progress,
  Type,
  SelectFIR,
  ObservationForecastTime,
  ValidFrom,
  ValidUntil,
  StartGeometry,
  Levels,
} from '../ProductFormFields';
import { useTAC } from '../ProductFormTac';
import {
  getDrawTools,
  getFir,
  getProductIssueDate,
  updateDrawToolsWithFir,
  getOmitChangeFromConfig,
  getDefaultPhenomena,
  useFormLayers,
} from '../utils';
import { SigmetAirmetApi } from '../../../utils/api';
import ProductFormFieldLayout from '../ProductFormFields/ProductFormFieldLayout';
import IssuedAt from '../ProductFormFields/IssuedAt';
import Tac from '../ProductFormFields/Tac';
import ProductFormLayout from '../ProductFormLayout';
import { sigmetConfig as defaultSigmetConfig } from '../../../utils/config';
import {
  featurePropsEnd,
  featurePropsFIR,
  featurePropsIntersectionEnd,
  featurePropsStart,
  featurePropsIntersectionStart,
} from '../../MapViewGeoJson/constants';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

const useConditionalFields = (
  productConfig: ProductConfig,
): {
  hasVolcanicAshes: boolean;
  omitChange: boolean;
} => {
  const { watch, unregister } = useFormContext();
  const hasVolcanicAshes = watch('phenomenon') === 'VA_CLD';
  const hasRadioactiveCloud = watch('phenomenon') === 'RDOACT_CLD';
  const changeFromConfig = getOmitChangeFromConfig(productConfig);
  const omitChange =
    changeFromConfig && (hasVolcanicAshes || hasRadioactiveCloud);
  React.useEffect(() => {
    if (!hasVolcanicAshes) {
      unregister([
        'vaSigmetVolcanoName',
        'vaSigmetVolcanoCoordinates.latitude',
        'vaSigmetVolcanoCoordinates.longitude',
      ]);
    }
    if (omitChange) {
      unregister(['change']);
    }
  }, [hasVolcanicAshes, omitChange, unregister]);
  return { hasVolcanicAshes, omitChange };
};

export interface SigmetFormProps {
  mode: FormMode;
  isCancelSigmet?: boolean;
  initialSigmet?: Sigmet;
  initialCancelSigmet?: CancelSigmet;
  showMap?: boolean;
  productConfig?: ProductConfig;
  setDialogActionsDisabled?: (disabled: boolean) => void;
  product?: AviationProduct;
  getTAC?: (product: AviationProduct) => Promise<{ data: string }>;
}

const SigmetForm: React.FC<SigmetFormProps> = ({
  mode,
  isCancelSigmet = false,
  initialSigmet = null!,
  initialCancelSigmet = null!,
  showMap = true,
  productConfig = defaultSigmetConfig,
  setDialogActionsDisabled,
  product,
  getTAC,
}: SigmetFormProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { api } = useApiContext<SigmetAirmetApi>();
  const firBorderAtStart = initialSigmet
    ? initialSigmet.locationIndicatorATSR
    : productConfig.active_firs[0];

  const [phenomenaList, setPhenomenaList] = React.useState<
    AviationPhenomenon[]
  >(getDefaultPhenomena(productConfig).phenomena);

  const {
    geoJSON: startGeoJSON,
    setGeoJSON: setStartGeoJSON,
    geoJSONIntersection: startGeoJSONIntersection,
    getLayer: getStartLayer,
    drawModes: startEditModes,
    activeTool: activeStartTool,
    changeActiveTool: changeActiveStartTool,
    deactivateTool: deactivateStartTool,
    setGeoJSONIntersectionBounds: setStartGeoJSONIntersectionBounds,
    setDrawModes: setStartEditModes,
  } = useMapDrawTool({
    defaultDrawModes: getDrawTools(
      StartOrEndDrawing.start,
      getFir(productConfig, firBorderAtStart),
    ),
    defaultGeoJSON:
      !isCancelSigmet && initialSigmet && initialSigmet.startGeometry
        ? addFeatureProperties(initialSigmet.startGeometry, featurePropsStart)
        : undefined,
    defaultGeoJSONIntersection:
      !isCancelSigmet && initialSigmet && initialSigmet.startGeometryIntersect
        ? addFeatureProperties(
            initialSigmet.startGeometryIntersect,
            featurePropsIntersectionStart,
          )
        : undefined,
    defaultGeoJSONIntersectionBounds: addFeatureProperties(
      getFir(productConfig, firBorderAtStart),
      featurePropsFIR,
    ),
  });
  const {
    geoJSON: endGeoJSON,
    setGeoJSON: setEndGeoJSON,
    geoJSONIntersection: endGeoJSONIntersection,
    getLayer: getEndLayer,
    drawModes: endEditModes,
    activeTool: activeEndTool,
    changeActiveTool: changeActiveEndTool,
    deactivateTool: deactivateEndTool,
    setGeoJSONIntersectionBounds: setEndGeoJSONIntersectionBounds,
    setDrawModes: setEndEditModes,
  } = useMapDrawTool({
    defaultDrawModes: getDrawTools(
      StartOrEndDrawing.end,
      getFir(productConfig),
    ),
    defaultGeoJSON:
      !isCancelSigmet && initialSigmet && initialSigmet.endGeometry
        ? addFeatureProperties(initialSigmet.endGeometry, featurePropsEnd)
        : undefined,
    defaultGeoJSONIntersection:
      !isCancelSigmet && initialSigmet && initialSigmet.endGeometryIntersect
        ? addFeatureProperties(
            initialSigmet.endGeometryIntersect,
            featurePropsIntersectionEnd,
          )
        : undefined,
    defaultGeoJSONIntersectionBounds: addFeatureProperties(
      getFir(productConfig),
      featurePropsFIR,
    ),
    defaultGeoJSONIntersectionProperties: featurePropsIntersectionEnd,
  });

  const onUpdateStartgeoJSON = (
    updatedStartGeoJSON: GeoJSON.FeatureCollection,
    updateStartGeoJSONIntersection: GeoJSON.FeatureCollection,
  ): void => {
    setValue('startGeometry', updatedStartGeoJSON, {
      shouldValidate: true,
      shouldDirty: isGeometryDirty(watch('startGeometry'), updatedStartGeoJSON),
    });
    setValue('startGeometryIntersect', updateStartGeoJSONIntersection, {
      shouldValidate: true,
      shouldDirty: isGeometryDirty(
        watch('startGeometryIntersect'),
        updateStartGeoJSONIntersection,
      ),
    });
    onChangeForm();
  };

  const onUpdateEndGeoJson = (
    updatedEndGeoJSON: GeoJSON.FeatureCollection,
    updateEndGeoJSONIntersection: GeoJSON.FeatureCollection,
  ): void => {
    setValue('endGeometry', updatedEndGeoJSON, {
      shouldValidate: true,
      shouldDirty: isGeometryDirty(watch('endGeometry'), updatedEndGeoJSON),
    });
    setValue('endGeometryIntersect', updateEndGeoJSONIntersection, {
      shouldValidate: true,
      shouldDirty: isGeometryDirty(
        watch('endGeometryIntersect'),
        updateEndGeoJSONIntersection,
      ),
    });
    onChangeForm();
  };

  const onChangeStartTool = (newTool: DrawMode): void => {
    deactivateEndTool();
    changeActiveStartTool(newTool);

    if (!newTool.isSelectable) {
      deactivateStartTool();
      onUpdateStartgeoJSON(
        newTool.shape as GeoJSON.FeatureCollection,
        newTool.shape as GeoJSON.FeatureCollection,
      );
    }
  };

  const onChangeEndTool = (newTool: DrawMode): void => {
    deactivateStartTool();
    changeActiveEndTool(newTool);

    if (!newTool.isSelectable) {
      deactivateEndTool();
      onUpdateEndGeoJson(
        newTool.shape as GeoJSON.FeatureCollection,
        newTool.shape as GeoJSON.FeatureCollection,
      );
    }
  };

  const onExitDrawMode = (
    reason: string,
    newGeoJSON?: GeoJSON.FeatureCollection,
  ): void => {
    deactivateStartTool();

    const [newStartGeoJson, newStartIntersection] = setStartGeoJSON(
      newGeoJSON!,
    );
    const newGeoStart = rewindGeometry(newStartGeoJson);
    const newGeoStartIntersect = rewindGeometry(newStartIntersection!);
    onUpdateStartgeoJSON(newGeoStart, newGeoStartIntersect!);
  };

  const onExitDrawModeEnd = (
    reason: DrawModeExitCallback,
    newGeoJSON?: GeoJSON.FeatureCollection,
  ): void => {
    deactivateEndTool();

    const [newEndGeoJson, newEndIntersection] = setEndGeoJSON(newGeoJSON!);
    const newGeoEnd = rewindGeometry(newEndGeoJson);
    const newGeoEndIntersect = rewindGeometry(newEndIntersection!);
    onUpdateEndGeoJson(newGeoEnd, newGeoEndIntersect!);
  };

  const layers: MapViewLayerProps[] = [
    getStartLayer('geoJSONIntersectionBounds', 'geojsonlayer-fir'),
    {
      ...getEndLayer('geoJSON', 'geojsonlayer-end'),
      exitDrawModeCallback: onExitDrawModeEnd,
    },
    {
      ...getStartLayer('geoJSON', 'geojsonlayer-start'),
      exitDrawModeCallback: onExitDrawMode,
    },
    getEndLayer('geoJSONIntersection', 'geojsonlayer-intersection-end'),
    getStartLayer('geoJSONIntersection', 'geojsonlayer-intersection-start'),
  ];

  useFormLayers(layers);

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const getHasFormErrors = (): boolean => Object.keys(errors).length > 0;

  const [isDisabled, setIsDisabled] = React.useState(mode === 'view');
  const isReadOnly = mode === 'view';
  const helperText = isReadOnly ? '' : 'Optional';
  const [tac, updateTac] = useTAC(
    initialSigmet !== null ? initialSigmet : initialCancelSigmet,
    (getTAC || api.getSigmetTAC) as (
      product: AviationProduct,
    ) => Promise<{ data: string }>,
    getHasFormErrors,
    getOmitChangeFromConfig(productConfig),
  );

  const onChangeForm = (): void => {
    updateTac(watch);
  };

  React.useEffect(() => {
    updateTac(watch);
  }, [initialSigmet, updateTac, watch]);

  React.useEffect(() => {
    const isInDrawMode = activeStartTool !== '' || activeEndTool !== '';
    setDialogActionsDisabled && setDialogActionsDisabled(isInDrawMode);
    if (mode === 'view') {
      setIsDisabled(true);
    } else {
      setIsDisabled(isInDrawMode);
    }
  }, [mode, activeStartTool, activeEndTool, setDialogActionsDisabled]);

  const { hasVolcanicAshes, omitChange } = useConditionalFields(productConfig);
  const { isShortTest } = useShortTestHelpers();

  const onChangeFir = (
    newPhenomenaList: AviationPhenomenon[],
    event?: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newFIRValue = event?.target.value;
    const newFirGeoJSON = addFeatureProperties(
      getFir(productConfig, newFIRValue),
      featurePropsFIR,
    );
    // delete shape
    onChangeStartTool(startEditModes.find((mode) => mode.value === 'DELETE')!);
    onChangeEndTool(endEditModes.find((mode) => mode.value === 'DELETE')!);
    // update instersection bounds
    setStartGeoJSONIntersectionBounds(newFirGeoJSON);
    setEndGeoJSONIntersectionBounds(newFirGeoJSON);
    // update tool buttons with new fir geoJSON
    setStartEditModes(updateDrawToolsWithFir(startEditModes, newFirGeoJSON));
    setEndEditModes(updateDrawToolsWithFir(endEditModes, newFirGeoJSON));
    // update available phenomena for the FIR
    setPhenomenaList(newPhenomenaList);
  };

  const renderForm = (): React.ReactElement => (
    <Grid container direction="column" spacing={2} sx={formStyles}>
      {/* Type */}
      <Type
        productType="sigmet"
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={onChangeForm}
      />
      {/* Phenomenon */}
      {!isCancelSigmet && (
        <Phenomenon
          productType="sigmet"
          productConfig={productConfig}
          phenomenaList={phenomenaList}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={onChangeForm}
        />
      )}
      {/* Observed/Forecast */}
      {!isCancelSigmet && (
        <ObservationForecast isDisabled={isDisabled} isReadOnly={isReadOnly} />
      )}
      {/* At */}
      {!isCancelSigmet && (
        <ObservationForecastTime
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          helperText={helperText}
          onChange={onChangeForm}
        />
      )}
      {/* Volcanic fields */}
      {!isCancelSigmet && hasVolcanicAshes && !isShortTest() && (
        <VolcanicFields
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          helperText={helperText}
        />
      )}
      {/* Valid from */}
      <ValidFrom
        productConfig={productConfig}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={onChangeForm}
      />
      {/* Valid until */}
      <ValidUntil
        productConfig={productConfig}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={onChangeForm}
      />
      {/* Where */}
      <SelectFIR
        productConfig={productConfig}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={(event, newPhenomenaList) => {
          onChangeFir(newPhenomenaList || [], event);
        }}
      />
      {/* Draw */}
      <StartGeometry
        isReadOnly={isReadOnly}
        geoJSON={startGeoJSON}
        geoJSONIntersection={startGeoJSONIntersection}
        tools={startEditModes}
        onChangeTool={onChangeStartTool}
        activeTool={activeStartTool}
        productConfig={productConfig}
      />
      {/* Levels */}
      {!isCancelSigmet && (
        <Levels
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={onChangeForm}
          productType="sigmet"
          productConfig={productConfig}
        />
      )}
      {/* Progress */}
      {!isCancelSigmet && (
        <Progress
          productType="sigmet"
          productConfig={productConfig}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onChange={onChangeForm}
          geoJSON={endGeoJSON}
          geoJSONIntersection={endGeoJSONIntersection!}
          tools={endEditModes}
          onChangeTool={onChangeEndTool}
          activeTool={activeEndTool}
        />
      )}
      {/* Change */}
      {!isCancelSigmet && !omitChange && (
        <Change isDisabled={isDisabled} isReadOnly={isReadOnly} />
      )}
      {/* Volcanic ash cloud moving to: */}
      {isCancelSigmet && watch('vaSigmetMoveToFIR') && (
        <ProductFormFieldLayout>
          <Typography
            variant="body2"
            sx={{ ...styles.body, marginBottom: '-4px' }}
            data-testid="vaSigmetMoveToFIR"
          >
            {t('vulcanic-cloud-moving-text')}:{' '}
            <b> {watch('vaSigmetMoveToFIR')}</b>
          </Typography>
        </ProductFormFieldLayout>
      )}
      {/* Issued at */}
      <IssuedAt
        date={getProductIssueDate(initialSigmet, initialCancelSigmet)}
        isReadOnly={isReadOnly}
      />
      {/* TAC */}
      <Tac tac={tac} product={product!}>
        {/* Hidden sequence number field needed for the TAC generation */}
        <ReactHookFormHiddenInput name="sequence" defaultValue="-1" />

        {
          /* Add hidden fields for TAC generation in case of cancel sigmet */
          isCancelSigmet && (
            <>
              <ReactHookFormHiddenInput name="cancelsSigmetSequenceId" />
              <ReactHookFormHiddenInput name="validDateEndOfSigmetToCancel" />
              <ReactHookFormHiddenInput name="validDateStartOfSigmetToCancel" />
            </>
          )
        }
      </Tac>
    </Grid>
  );
  if (!showMap) {
    return renderForm();
  }

  return (
    <form
      onChange={onChangeForm}
      onSubmit={handleSubmit(() => null)}
      style={{ width: '100%' }}
    >
      <ProductFormLayout productConfig={productConfig} geoJSONLayers={layers}>
        {renderForm()}
      </ProductFormLayout>
    </form>
  );
};

export default SigmetForm;

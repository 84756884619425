/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import Box from '@mui/material/Box';
import { MenuItem, MenuList } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { TafFromBackend, TimeSlot } from '../../../types';
import StatusIcon from '../../StatusIcon/StatusIcon';
import { TafAvatar } from '../../TafAvatar';

interface LocationTabsProps {
  tafList: TafFromBackend[];
  timeSlot: TimeSlot;
  activeIndex?: number;
  onChangeTab?: (newTabIndex: number, isFormDirty: boolean) => void;
}

const LocationTabs: React.FC<LocationTabsProps> = ({
  tafList,
  activeIndex = 0,
  onChangeTab = (): void => {},
  timeSlot,
}: LocationTabsProps) => {
  const {
    formState: { isDirty },
  } = useFormContext();

  return (
    <MenuList data-testid="location-tabs" sx={{ paddingTop: '32px' }}>
      {tafList.map(({ taf, editor }, index) => (
        <MenuItem
          key={taf.uuid}
          selected={index === activeIndex}
          sx={{
            backgroundColor: 'geowebColors.cards.cardContainer',
            borderColor: 'geowebColors.cards.cardContainerBorder',
            borderWidth: '1px',
            borderStyle: 'solid',
            margin: '0 0 4px',
            height: '56px',
            justifyContent: 'center',
            ':after': {
              background: 'none!important',
            },
            '&.Mui-selected': {
              backgroundColor: 'geowebColors.cards.cardContainer',
              marginRight: '-17px',
              borderRight: '0px',
              zIndex: 1,
            },
          }}
          onClick={(): void => onChangeTab(index, isDirty)}
        >
          {editor && (
            <Box sx={{ position: 'absolute', top: '4px', left: '8px' }}>
              <TafAvatar editor={editor} tooltipPlacement="right" />
            </Box>
          )}
          <Box
            component="span"
            data-testid="taf-location"
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0.09px',
            }}
          >
            {taf.location}
          </Box>
          <Box
            component="span"
            sx={{
              position: 'absolute',
              bottom: '-1px',
              width: '24px',
              height: '24px',
            }}
          >
            <StatusIcon timeSlot={timeSlot} status={taf.status} />
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default LocationTabs;

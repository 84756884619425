/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const consoleErrorMessages = {
  noCapabilityElementFound: 'No Capability element found in service',
  noServiceDefined: 'No service defined',
  noWmsCapabilityElementFound: 'No WMS Capability element found',
  noWmsLayerElementFound: 'No WMS Layer element found',
  notAvailableMessage: 'Not available',
  serviceHasError: '--- service has an error ---',
  serviceUrlEmpty: 'Service URL is empty',
  unableToConnectServer: 'Unable to connect to the service.',
  wmsServiceExceptionCode: 'WMS Service exception with code',
  layerNotFoundInService: 'WMS Layer was not found in service',
};

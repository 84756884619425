/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import axios from 'axios';
import { useIsMounted, ConfigType, useDebounce } from '@opengeoweb/shared';
/*
  Summary. Accepts a promise base apiCall with params, and returns an object with result
  
  @param {Promise} apiCall: api request Promise
  @param {object} params: params for api request   
  @return {isLoading: boolean, error: Error, results: any} 

  @example: 
   const { isLoading, error, result } = useApi(api.getList, {
    id: 'xxxxx',
  });
*/

export type ApiParams =
  | string
  | ConfigType
  | Record<string, string | undefined>
  | null;

interface BaseApiHookProps {
  fetchApiData?: (params: ApiParams) => Promise<void>;
  clearResults?: () => void;
}

type PendingApiHookProps = BaseApiHookProps & {
  isLoading: true;
  result: null;
  error: null;
};

type DoneApiHookProps<R> = BaseApiHookProps & {
  isLoading: false;
  result: R;
  error: null;
};

type ErrorApiHookProps = BaseApiHookProps & {
  isLoading: false;
  result: null;
  error: Error;
};

export type ApiHookProps<TData> =
  | ErrorApiHookProps
  | PendingApiHookProps
  | DoneApiHookProps<TData>;

interface Callbacks<TData> {
  onSuccess?: (data: TData) => void;
  onError?: (e: Error) => void;
}

const generateRandomId = (): string =>
  `-${Math.random().toString(36).substring(2, 11)}`;

export const useApi = <
  TResponse extends { data: unknown } | { data: unknown }[],
  TResult = TResponse extends { data: infer TData }[]
    ? TData[]
    : TResponse extends { data: infer TData }
      ? TData
      : never,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiCall?: (params?: any, id?: string) => Promise<TResponse>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any,
  callbacks?: Callbacks<TResult>,
): ApiHookProps<TResult> => {
  const { isMounted } = useIsMounted();
  const [id] = React.useState(generateRandomId());
  const [isLoading, setIsloading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [result, setResult] = React.useState<TResult | null>(null);
  const debouncedParams = useDebounce(JSON.stringify(params), 300);

  // trick so that we don't have to include this in the useEffect dependency array (as it is likely that callbacks changes on every render)
  const callbacksRef = React.useRef<Callbacks<TResult>>();
  callbacksRef.current = callbacks;

  const handleError = (newError: Error): void => {
    if (isMounted.current) {
      if (!axios.isCancel(newError)) {
        callbacksRef.current?.onError?.(newError);
        setError(newError);
      }
    }
  };

  const fetchApiData = async (_params?: ApiParams): Promise<void> => {
    setIsloading(true);
    setError(null);
    try {
      const response: TResponse = await apiCall!(_params, id);
      if (isMounted.current) {
        const data = Array.isArray(response)
          ? (response.map((d) => d.data) as TResult)
          : (response.data as TResult);
        callbacksRef.current?.onSuccess?.(data);
        setResult(data);
      }
    } catch (newError: unknown) {
      if (newError instanceof Error) {
        handleError(newError);
      }
    } finally {
      setIsloading(false);
    }
  };

  React.useEffect(
    () => {
      fetchApiData(params).catch((newError) => {
        handleError(newError);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedParams],
  );

  const clearResults = (): void => {
    setIsloading(true);
    setResult(null);
  };

  return {
    isLoading,
    error: error!,
    result,
    clearResults,
    fetchApiData,
  } as ApiHookProps<TResult>;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PROJECTION } from '@opengeoweb/shared';
import type IWMJSMap from './IWMJSMap';
import { isDefined, debugLogger, DebugType } from './WMJSTools';
import { WMEmptyLayerTitle, WMSVersion } from './WMConstants';
import WMJSDimension from './WMJSDimension';
import WMProjection from './WMProjection';
import WMBBOX from './WMBBOX';

import { Style, GeographicBoundingBox, GetCapabilitiesJson } from './types';
import { consoleErrorMessages } from '../utils/consoleErrorMessages';
import {
  privateWmsBuildLayerTreeFromNestedWMSLayer,
  privateWmsGetRootLayerFromGetCapabilities,
  privateWmsFlattenLayerTree,
  privateGetWMSServiceInfo,
} from '../utils/getCapabilities/utils';
import type { LayerType, LayerOptions } from './WMLayerTypes';
import {
  configureStyles,
  configureDimensions,
  configureGeographicBoundingBox,
} from './WMLayerUtils';
import {
  invalidateWMSGetCapabilities,
  queryWMSGetCapabilities,
} from '../query/queryWMS';

export default class WMLayer {
  id!: string;

  name?: string;

  ReactWMJSLayerId?: string;

  hasError?: boolean;

  public enabled?: boolean;

  lastError?: string;

  legendGraphic?: string;

  title?: string;

  public opacity?: number;

  service!: string;

  layerType!: LayerType;

  currentStyle?: string;

  linkedInfo!: { layer: string; message: string };

  headers!: Headers[];

  autoupdate!: boolean;

  timer!: NodeJS.Timeout | number;

  getmapURL!: string;

  getfeatureinfoURL!: string;

  getlegendgraphicURL!: string;

  keepOnTop!: boolean;

  transparent!: boolean;

  legendIsDimensionDependent!: boolean;

  wms130bboxcompatibilitymode!: boolean;

  version!: string;

  path!: string;

  type!: string;

  abstract!: string;

  dimensions!: WMJSDimension[];

  projectionProperties!: WMProjection[];

  queryable!: boolean;

  styles!: Style[];

  serviceTitle!: string;

  parentMap!: IWMJSMap;

  sldURL!: string;

  active!: boolean;

  getgraphinfoURL!: string;

  format!: string;

  _options!: LayerOptions;

  onReady!: (param: LayerOptions) => void;

  optimalFormat!: string;

  wmsextensions!: { url: string; colorscalerange: Record<string, unknown>[] };

  isConfigured!: boolean;

  geographicBoundingBox?: GeographicBoundingBox;

  geojson?: GeoJSON.FeatureCollection;

  currentRequestedGetMapURL?: string;

  init(): void {
    this.autoupdate = false;
    this.timer = undefined!;
    this.service = undefined!; // URL of the WMS Service
    this.getmapURL = undefined!;
    this.getfeatureinfoURL = undefined!;
    this.getlegendgraphicURL = undefined!;
    this.keepOnTop = false;
    this.transparent = true;
    this.hasError = false;
    this.legendIsDimensionDependent = true;
    this.wms130bboxcompatibilitymode = false;
    this.version = WMSVersion.version111;
    this.path = '';
    this.type = 'wms';
    this.wmsextensions = { url: '', colorscalerange: [] };
    this.name = undefined;
    this.title = WMEmptyLayerTitle;
    this.abstract = undefined!;
    this.dimensions = []; // Array of Dimension
    this.legendGraphic = '';
    this.projectionProperties = []; // Array of WMProjections
    this.queryable = false;
    this.enabled = true;
    this.styles = [];
    this.currentStyle = '';
    this.id = '-1';
    this.opacity = 1.0; // Ranges from 0.0-1.0
    this.serviceTitle = 'not defined';
    this.parentMap = null!;
    this.sldURL = null!;
    this.isConfigured = false;
    this.currentRequestedGetMapURL = undefined;
  }

  constructor(options?: LayerOptions) {
    this.init = this.init.bind(this);
    this.getLayerName = this.getLayerName.bind(this);
    this.toggleAutoUpdate = this.toggleAutoUpdate.bind(this);
    this.setAutoUpdate = this.setAutoUpdate.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.getOpacity = this.getOpacity.bind(this);
    this.remove = this.remove.bind(this);
    this.zoomToLayer = this.zoomToLayer.bind(this);
    this.draw = this.draw.bind(this);
    this.handleReferenceTime = this.handleReferenceTime.bind(this);
    this.setDimension = this.setDimension.bind(this);
    this._setWMSGetCapabilities = this._setWMSGetCapabilities.bind(this);
    this.parseLayer = this.parseLayer.bind(this);
    this.cloneLayer = this.cloneLayer.bind(this);
    this.setName = this.setName.bind(this);
    this.setStyle = this.setStyle.bind(this);
    this.getStyles = this.getStyles.bind(this);
    this.getStyleObject = this.getStyleObject.bind(this);
    this.getStyle = this.getStyle.bind(this);
    this.getDimension = this.getDimension.bind(this);
    this.getProjection = this.getProjection.bind(this);
    this.getCRS = this.getCRS.bind(this);
    this.getCRSByName = this.getCRSByName.bind(this);
    this.setSLDURL = this.setSLDURL.bind(this);
    this.display = this.display.bind(this);
    this.getDimensions = this.getDimensions.bind(this);
    this.getCurrentRequestedGetMapURL =
      this.getCurrentRequestedGetMapURL.bind(this);
    this.setCurrentRequestedGetMapURL =
      this.setCurrentRequestedGetMapURL.bind(this);
    this.init();
    this._options = options!;
    this.sldURL = null!;
    this.headers = [];
    if (options) {
      this.service = options.service;
      this.getmapURL = options.service;
      this.getfeatureinfoURL = options.service;
      this.getlegendgraphicURL = options.service;
      if (options.active === true) {
        this.active = true;
      } else {
        this.active = false;
      }
      this.name = options.name;
      if (options.getgraphinfoURL) {
        this.getgraphinfoURL = options.getgraphinfoURL;
      }
      if (options.style) {
        this.currentStyle = options.style;
      }
      if (options.getmapURL) {
        this.getmapURL = options.getmapURL || options.service;
      }
      if (options.currentStyle) {
        this.currentStyle = options.currentStyle;
      }
      if (options.sldURL) {
        this.sldURL = options.sldURL;
      }
      if (options.id) {
        this.id = options.id;
      }
      if (options.format) {
        this.format = options.format;
      } else {
        this.format = 'image/png';
      }
      if (isDefined(options.opacity)) {
        this.opacity = options.opacity;
      }
      if (options.title) {
        this.title = options.title;
      }
      this.abstract = consoleErrorMessages.notAvailableMessage;

      if (options.enabled === false) {
        this.enabled = false;
      }

      if (options.keepOnTop === true) {
        this.keepOnTop = true;
      }

      if (options.transparent === false) {
        this.transparent = false;
      }
      if (options.dimensions?.length) {
        options.dimensions.forEach((dimension) => {
          this.dimensions.push(new WMJSDimension(dimension));
        });
      }
      if (isDefined(options.onReady)) {
        this.onReady = options.onReady;
      }
      if (isDefined(options.type)) {
        this.type = options.type;
      }
      if (options.parentMap) {
        this.parentMap = options.parentMap;
      }
      if (options.headers) {
        this.headers = options.headers;
      }
      if (options.geojson) {
        this.geojson = options.geojson;
      }
      if (options.layerType) {
        this.layerType = options.layerType;
      }
    }
  }

  getLayerName(): string {
    return this.name!;
  }

  toggleAutoUpdate(): void {
    this.autoupdate = !this.autoupdate;
    if (this.autoupdate) {
      const numDeltaMS = 60000;
      this.timer = setInterval(() => {
        void this.parseLayer(true);
      }, numDeltaMS);
    } else {
      clearInterval(this.timer as NodeJS.Timeout);
    }
  }

  setAutoUpdate(val: boolean, interval?: number): void {
    if (val !== this.autoupdate) {
      this.autoupdate = val;
      if (!val) {
        clearInterval(this.timer as NodeJS.Timeout);
      } else {
        this.timer = setInterval(() => {
          void this.parseLayer(true);
        }, interval);
      }
    }
  }

  setOpacity(opacityValue: number): void {
    this.opacity = opacityValue;

    this.parentMap && this.parentMap.redrawBuffer();
  }

  getOpacity(): number {
    return this.opacity!;
  }

  remove(): void {
    if (this.parentMap) {
      this.parentMap.deleteLayer(this);
      this.parentMap.draw('WMLayer::remove');
    }
    clearInterval(this.timer as NodeJS.Timeout);
  }

  zoomToLayer(): void {
    if (this.parentMap) {
      this.parentMap.zoomToLayer(this);
    }
  }

  draw(e: string): void {
    if (this.parentMap) {
      this.parentMap.draw(`WMLayer::draw::${e}`);
    }
  }

  handleReferenceTime(
    name: string,
    value: string,
    updateMapDimensions = true,
  ): void {
    if (name !== 'reference_time') {
      return;
    }
    const timeDim = this.getDimension('time');
    const referenceTimeDim = this.getDimension(name);
    if (timeDim) {
      timeDim.setTimeValuesForReferenceTime(value, referenceTimeDim!);
      if (updateMapDimensions && this.parentMap && this.enabled !== false) {
        this.parentMap.getListener().triggerEvent('ondimchange', 'time');
      }
    }
  }

  getDimensions(): WMJSDimension[] {
    return this.dimensions;
  }

  setDimension(name: string, value: string, updateMapDimensions = true): void {
    if (!isDefined(value)) {
      return;
    }

    const dim = this.getDimension(name);
    if (!dim) {
      return;
    }

    dim.setValue(value);

    this.handleReferenceTime(name, value, updateMapDimensions);
    if (updateMapDimensions && dim.linked === true && this.parentMap) {
      this.parentMap.setDimension(name, dim.getValue());
    }
  }

  _setWMSGetCapabilities(getCapabilitiesJson: GetCapabilitiesJson): void {
    const layers = privateWmsFlattenLayerTree(
      privateWmsBuildLayerTreeFromNestedWMSLayer(
        privateWmsGetRootLayerFromGetCapabilities(getCapabilitiesJson),
      ),
    );
    const jsonlayer = layers.find((layer) => {
      return layer.name === this.name;
    });
    if (jsonlayer) {
      /** ***************** Go through styles **************** */
      configureStyles(jsonlayer, this);

      /** ***************** Go through Dimensions **************** */
      configureDimensions(jsonlayer, this);

      /** ***************** Go through geographicBoundingBox **************** */
      configureGeographicBoundingBox(jsonlayer, this);

      this.queryable = jsonlayer.queryable || false;

      const serviceInfo = privateGetWMSServiceInfo(
        getCapabilitiesJson,
        this.service,
      );

      this.getmapURL = serviceInfo.getmapURL;
      this.version = serviceInfo.version || WMSVersion.version130;

      this.title = jsonlayer.title;

      if (jsonlayer.crs) {
        jsonlayer.crs.forEach((p) => {
          const wmProjection = new WMProjection();
          wmProjection.srs = p.name || '';
          if (p.bbox) {
            const swapBBOX =
              this.version === WMSVersion.version130 &&
              wmProjection.srs === PROJECTION.EPSG_4326.value &&
              this.wms130bboxcompatibilitymode === false;

            if (swapBBOX) {
              wmProjection.bbox.setBBOX(
                p.bbox.bottom,
                p.bbox.left,
                p.bbox.top,
                p.bbox.right,
              );
            } else {
              wmProjection.bbox.setBBOX(
                p.bbox.left,
                p.bbox.bottom,
                p.bbox.right,
                p.bbox.top,
              );
            }
          }

          this.projectionProperties.push(wmProjection);
        });
      }

      this.isConfigured = true;
    } else {
      this.hasError = true;
      this.lastError = `${consoleErrorMessages.layerNotFoundInService} - ${this.name}`;
    }
  }

  /** A Promise to parse the layer, it will fetch the WMS GetCapabilities document, configure the layer and resolve to a WMLayer object
   * @param forceReload Do not use the cache of the WMS GetCapabilities document, but instead fetch new data from the server
   * @returns WMLayer object
   */
  async parseLayer(forceReload = false): Promise<WMLayer> {
    this.hasError = false;
    try {
      if (forceReload) {
        // eslint-disable-next-line no-console
        console.log('invalidateWMSGetCapabilities');
        await invalidateWMSGetCapabilities(this.service);
      }
      const getCapabilities = await queryWMSGetCapabilities(this.service);
      this._setWMSGetCapabilities(getCapabilities);
      return this;
    } catch (e) {
      this.hasError = true;
      const message = `${consoleErrorMessages.noCapabilityElementFound}[in ${this.service}] with message [${(e as unknown as Error).message}]`;
      this.lastError = message;
      this.title = consoleErrorMessages.serviceHasError;
      if (isDefined(this._options.failure)) {
        this._options.failure(this, message);
      }
      throw new Error(message);
    }
  }

  cloneLayer(): WMLayer {
    const newLayer = new WMLayer(this._options);
    newLayer.dimensions.length = 0;
    newLayer.name = this.name;
    newLayer.parentMap = this.parentMap;

    this.dimensions.forEach((dim) => {
      newLayer.dimensions.push(dim.clone());
    });
    return newLayer;
  }

  /**
   * Set or change the name of this layer. This involves re-parsing the WMS GetCapabilities document and updating the affected layer properties. When done, it resolves the WMLayer
   * @param name The name of the layer
   * @returns Promise resolving to a WMLayer object
   */
  setName(name: string): Promise<WMLayer> {
    this.name = name;
    return this.parseLayer();
  }

  /**
   * Sets the style by its name
   * @param style: The name of the style (not the object)
   */
  setStyle(styleName: string): void {
    debugLogger(DebugType.Log, `WMLayer::setStyle: ${styleName}`);

    if (!this.styles || this.styles.length === 0) {
      this.currentStyle = '';
      this.legendGraphic = '';
      debugLogger(DebugType.Log, 'Layer has no styles.');
      return;
    }

    let styleFound = false;

    for (const style of this.styles) {
      if (style.name === styleName) {
        this.legendGraphic = style.legendURL;
        this.currentStyle = style.name;
        styleFound = true;
      }
    }
    if (!styleFound) {
      debugLogger(
        DebugType.Log,
        `WMLayer::setStyle: Style ${styleName} not found, setting style ${this.styles[0].name}`,
      );
      this.currentStyle = this.styles[0].name;
      this.legendGraphic = this.styles[0].legendURL;
    }
  }

  getStyles(): Style[] {
    if (this.styles) {
      return this.styles;
    }
    return [];
  }

  /**
   * Get the styleobject by name
   * @param styleName The name of the style
   * @param nextPrev, can be -1 or +1 to get the next or previous style object in circular manner.
   */
  getStyleObject(styleName: string, nextPrev: number): Style {
    if (isDefined(this.styles) === false) {
      return undefined!;
    }
    for (let j = 0; j < this.styles.length; j += 1) {
      if (this.styles[j].name === styleName) {
        if (nextPrev === -1) {
          j -= 1;
        }
        if (nextPrev === 1) {
          j += 1;
        }
        if (j < 0) {
          j = this.styles.length - 1;
        }
        if (j > this.styles.length - 1) {
          j = 0;
        }
        return this.styles[j];
      }
    }
    return undefined!;
  }

  /*
   *Get the current stylename as used in the getmap request
   */
  getStyle(): string {
    return this.currentStyle!;
  }

  getDimension(name: string): WMJSDimension | undefined {
    return this.dimensions.find((dim) => dim.name === name);
  }

  /**
   * Returns the Coordinate Reference Systems for this layer
   * @returns List of WMProjection objects
   */
  getCRS(): WMProjection[] {
    const crs: WMProjection[] = [];
    for (const projectionProperty of this.projectionProperties) {
      if (projectionProperty) {
        const { bbox } = projectionProperty;
        if (bbox) {
          crs.push({
            srs: `${projectionProperty.srs}`,
            bbox: new WMBBOX(bbox.left, bbox.bottom, bbox.right, bbox.top),
          });
        }
      }
    }
    return crs;
  }

  /**
   * Get Coordinate reference system by name
   *
   * @param srsName Name of the coordinate reference system to get
   * @returns A WMProjection object if found, otherwise undefined.
   */
  getCRSByName(srsName: string): WMProjection {
    for (const projProps of this.projectionProperties) {
      if (projProps.srs === srsName) {
        const returnSRS = {
          srs: '',
          bbox: new WMBBOX(undefined, undefined, undefined, undefined),
        };
        returnSRS.srs = `${projProps.srs}`;
        returnSRS.bbox = new WMBBOX(
          projProps.bbox.left,
          projProps.bbox.bottom,
          projProps.bbox.right,
          projProps.bbox.top,
        );
        return returnSRS;
      }
    }
    return undefined!;
  }

  /**
   * Get Coordinate reference system by name. Note 2021-05-17: It is more inline with other function names to use the getCRSByName function instead.
   *
   * @param srsName Name of the coordinate reference system to get
   * @returns A WMProjection object if found, otherwise undefined.
   */
  getProjection(srsName: string): WMProjection {
    return this.getCRSByName(srsName);
  }

  setSLDURL(url: string): void {
    this.sldURL = url;
  }

  display(displayornot: boolean): void {
    this.enabled = displayornot;
    if (this.parentMap) {
      this.parentMap.displayLayer(this, this.enabled);
    }
  }

  setCurrentRequestedGetMapURL(url: string): void {
    this.currentRequestedGetMapURL = url;
  }

  getCurrentRequestedGetMapURL(): string | undefined {
    return this.currentRequestedGetMapURL;
  }
}

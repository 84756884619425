/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { CustomTooltip } from '@opengeoweb/shared';
import { Dimension } from '@opengeoweb/webmap';

interface LegendLayoutProps {
  title: string;
  name: string;
  dimensions: Dimension[];
  children: React.ReactNode;
  height?: number;
  width?: number;
  minWidth?: number;
}

export const LegendLayout: React.FC<LegendLayoutProps> = ({
  title,
  name,
  dimensions,
  children,
  height,
  width,
  minWidth,
}) => {
  return (
    <Paper
      data-testid="legend"
      sx={{
        padding: '10px',
        border: '1px solid grey',
        backgroundColor: '#FFF',
        overflow: 'hidden',
        marginBottom: 0.5,
      }}
    >
      <Box data-testid="legend-title">
        <Typography
          sx={{
            fontFamily: 'Roboto, Helvetica, Arial',
            fontSize: '10px',
            minWidth: '100%',
            wordWrap: 'break-word',
            color: 'common.black',
            whiteSpace: 'nowrap',
          }}
          variant="h3"
        >
          {title}
        </Typography>
      </Box>
      <CustomTooltip title={title || 'loading'} placement="top">
        <Box
          sx={{
            margin: '10px 0px 10px 0px',
            height: `${height}px`,
            width: `${width}px`,
          }}
        >
          {children}
        </Box>
      </CustomTooltip>
      <Box
        data-testid="legend-info"
        sx={{
          fontFamily: 'Roboto, Helvetica, Arial',
          fontSize: 10,
          color: 'common.black',
          overflowX: 'hidden',
          wordWrap: 'break-word',
          whiteSpace: 'nowrap',
          ...(minWidth && {
            minWidth: `${minWidth}px`,
          }),
        }}
      >
        {dimensions &&
          dimensions.map((dim) => (
            <Box key={name + dim.name}>
              {dim.name === 'time' ? (
                `${dim.currentValue}`
              ) : (
                <>
                  <Box style={{ fontWeight: 500 }}>{dim.name}:</Box>
                  {dim.currentValue} {dim.units}
                </>
              )}
            </Box>
          ))}
      </Box>
    </Paper>
  );
};

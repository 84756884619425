/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { snackbarListener, snackbarReducer } from '@opengeoweb/snackbar';
import { configureStore } from '@reduxjs/toolkit';
import {
  ConfigureStoreOptions,
  ToolkitStore,
} from '@reduxjs/toolkit/dist/configureStore';

export const getCurrentUrlLocation = (url: string, appUrl: string): string =>
  url ? url.replace(appUrl, '') : '/';

export const createAuthenticationTestStore = (
  preloadedState?: ConfigureStoreOptions['preloadedState'],
): ToolkitStore => {
  const store = configureStore({
    reducer: {
      snackbar: snackbarReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(snackbarListener.middleware),
    preloadedState,
  });
  return store;
};

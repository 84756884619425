/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { dateUtils } from '@opengeoweb/shared';
import { metronome } from '@opengeoweb/metronome';
import {
  handleDateUtilsISOString,
  isProjectionSupported,
  LayerType,
  webmapUtils,
} from '@opengeoweb/webmap';
import {
  defaultTimeStep,
  getSpeedDelay,
  roundWithTimeStep,
} from '@opengeoweb/timeslider';
import { mapActions } from './reducer';
import {
  defaultLayers,
  IS_LEGEND_OPEN_BY_DEFAULT,
  mapEnums,
  mapSelectors,
} from '.';
import { LayerActions, layerActions, layerSelectors } from '../layer';
import {
  Layer,
  LayerActionOrigin,
  ReduxLayer,
  ToggleAutoUpdatePayload,
  WebMapStateModuleState,
} from '../types';
import * as synchronizationGroupsSelector from '../../generic/syncGroups/selectors';
import { setTime } from '../../generic/actions';
import { dateFormat } from './utils';
import { setStep } from './mapListenerAnimationUtils';
import { filterLayers } from './filterLayers';
import {
  LayersAndAutoLayerIds,
  replaceLayerIdsToEnsureUniqueLayerIdsInStore,
} from './replaceLayerIdsToEnsureUniqueLayerIdsInStore';
import { genericActions } from '../../generic';
import { uiActions } from '../../ui';

export const isAnimationEndTimeValid = (animationEndTime: string): boolean => {
  const hasValidPrefix =
    animationEndTime.split(/[-+]/)[0] === 'NOW' ||
    animationEndTime.split(/[-+]/)[0] === 'TODAY';
  const durationString = animationEndTime.substring(
    animationEndTime.indexOf('PT') + 2,
  );

  const hasValidDate = /^(\d+H)?(\d+M)?$/.test(durationString);

  if (hasValidPrefix && hasValidDate) {
    return true;
  }
  const parsedDate = dateUtils.parseISO(animationEndTime);
  return dateUtils.isValid(parsedDate);
};

export const mapListener = createListenerMiddleware<WebMapStateModuleState>();

mapListener.startListening({
  actionCreator: layerActions.layerDelete,
  effect: ({ payload }, listenerApi) => {
    const { mapId } = payload;
    const layers = mapSelectors.getMapLayersWithoutDimensionCurrentValue(
      listenerApi.getState(),
      mapId,
    );
    if (!layers.length) {
      listenerApi.dispatch(mapActions.mapStopAnimation({ mapId }));
    }
  },
});

// update layer, synced maps and animation on latest max time if autoupdating
mapListener.startListening({
  actionCreator: layerActions.onUpdateLayerInformation,
  effect: ({ payload }, listenerApi) => {
    try {
      const { layerDimensions } = payload;
      if (!layerDimensions) {
        return;
      }
      const { dimensions, layerId } = layerDimensions;

      const layer = layerSelectors.getLayerById(
        listenerApi.getState(),
        layerId,
      );
      if (!layer) {
        return;
      }

      const newTimeDimension = dimensions.find(
        (dimension) => dimension.name === 'time',
      );
      const { mapId } = layer;

      if (!mapId) {
        return;
      }

      const autoUpdateLayerId = mapSelectors.getAutoUpdateLayerId(
        listenerApi.getState(),
        mapId,
      );
      const shouldAutoUpdate = mapSelectors.isAutoUpdating(
        listenerApi.getState(),
        mapId,
      );
      const prevTimeDimension = layerSelectors.getLayerTimeDimension(
        listenerApi.getState(),
        layerId,
      );

      const isAutoUpdateLayer = layerId === autoUpdateLayerId;

      const incomingMaxTime = newTimeDimension?.maxValue;
      const isIncomingMaxTimeLaterThanCurrentLayerTime =
        incomingMaxTime &&
        prevTimeDimension?.currentValue &&
        prevTimeDimension.currentValue !== incomingMaxTime;

      if (
        isAutoUpdateLayer && // only update the active layer
        shouldAutoUpdate &&
        isIncomingMaxTimeLaterThanCurrentLayerTime
      ) {
        const isMapAnimating = mapSelectors.isAnimating(
          listenerApi.getState(),
          mapId,
        );
        if (!isMapAnimating) {
          listenerApi.dispatch(
            layerActions.layerChangeDimension({
              layerId,
              origin: LayerActionOrigin.updateLayerInformationListener,
              dimension: {
                name: 'time',
                currentValue: incomingMaxTime,
              },
            }),
          );
          // Each time a layer updates, set the new time for all synced timesliders
          const syncedMapIds: string[] =
            synchronizationGroupsSelector.getSyncedMapIdsForTimeslider(
              listenerApi.getState(),
            );
          if (syncedMapIds) {
            // Change time value for all other timesliders
            syncedMapIds.map((syncedMapId) =>
              listenerApi.dispatch(
                setTime({
                  origin: LayerActionOrigin.updateLayerInformationListener,
                  sourceId: syncedMapId,
                  value: incomingMaxTime,
                }),
              ),
            );
          }
        }

        updateAnimation(mapId!, incomingMaxTime, listenerApi);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  },
});

const updateAnimation = (
  mapId: string,
  maxValue: string,
  listenerApi: ListenerEffectAPI<
    WebMapStateModuleState,
    ThunkDispatch<WebMapStateModuleState, unknown, AnyAction>,
    unknown
  >,
): void => {
  const shouldEndtimeOverride = mapSelectors.shouldEndtimeOverride(
    listenerApi.getState(),
    mapId,
  );

  if (shouldEndtimeOverride === true) {
    return;
  }

  const animationStart = mapSelectors.getAnimationStartTime(
    listenerApi.getState(),
    mapId,
  );

  // Calculate how much time the animation start need to move forwards
  const animationEnd = mapSelectors.getAnimationEndTime(
    listenerApi.getState(),
    mapId,
  );
  const animationEndUnix = dateUtils.unix(dateUtils.utc(animationEnd));
  const maxTimeAsUnix = dateUtils.unix(dateUtils.utc(maxValue));
  const timeInSecondToShiftAnimationForwards = maxTimeAsUnix - animationEndUnix;

  const animationStartUnix = dateUtils.unix(dateUtils.utc(animationStart));
  const newAnimationStartTime = dateUtils.dateToString(
    dateUtils.fromUnix(
      animationStartUnix + timeInSecondToShiftAnimationForwards,
    ),
    dateFormat,
  );

  const newAnimationEndTime = dateUtils.dateToString(
    dateUtils.fromUnix(animationEndUnix + timeInSecondToShiftAnimationForwards),
    dateFormat,
  );

  if (!newAnimationStartTime || !newAnimationEndTime) {
    return;
  }

  // Check if the mapId is part of a syncgroup
  const syncedMapIds: string[] =
    synchronizationGroupsSelector.getSyncedMapIdsForTimeslider(
      listenerApi.getState(),
    );
  const isSyncedMap = syncedMapIds.includes(mapId);
  const mapIdsToUpdate = isSyncedMap ? syncedMapIds : [mapId];
  // Update new animation times for the map or in case of syncgroup, all maps in the syncgroup
  mapIdsToUpdate.forEach((id) => {
    listenerApi.dispatch(
      mapActions.setAnimationEndTime({
        mapId: id,
        animationEndTime: newAnimationEndTime,
      }),
    );

    listenerApi.dispatch(
      mapActions.setAnimationStartTime({
        mapId: id,
        animationStartTime: newAnimationStartTime,
      }),
    );
  });
};

mapListener.startListening({
  actionCreator: mapActions.toggleAutoUpdate,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { shouldAutoUpdate, mapId } = payload;
    if (!shouldAutoUpdate) {
      return;
    }
    try {
      const autoUpdateLayerId = mapSelectors.getAutoUpdateLayerId(
        listenerApi.getState(),
        mapId,
      );
      const timeDimension = layerSelectors.getLayerTimeDimension(
        listenerApi.getState(),
        autoUpdateLayerId,
      );
      const syncedMapIds: string[] =
        synchronizationGroupsSelector.getSyncedMapIdsForTimeslider(
          listenerApi.getState(),
        );

      // go to end of active layer
      if (timeDimension?.maxValue && autoUpdateLayerId) {
        listenerApi.dispatch(
          layerActions.layerChangeDimension({
            layerId: autoUpdateLayerId,
            origin: LayerActionOrigin.toggleAutoUpdateListener,
            dimension: {
              name: 'time',
              currentValue: timeDimension.maxValue,
            },
          }),
        );

        // Change time value for all timesliders that are synced by syncgroups
        if (syncedMapIds.length > 0) {
          syncedMapIds.map((syncedMapId) =>
            listenerApi.dispatch(
              setTime({
                origin: LayerActionOrigin.toggleAutoUpdateListener,
                sourceId: syncedMapId,
                value: timeDimension.maxValue!,
              }),
            ),
          );
        }
        updateAnimation(mapId, timeDimension.maxValue, listenerApi);
      }

      // Toggle autoupdate off for synced timesliders
      const payloads: ToggleAutoUpdatePayload[] = syncedMapIds.reduce<
        ToggleAutoUpdatePayload[]
      >((syncedMapIdList, syncedMapId) => {
        if (syncedMapId !== mapId) {
          return syncedMapIdList.concat({
            mapId: syncedMapId,
            shouldAutoUpdate: false,
          });
        }
        return syncedMapIdList;
      }, []);

      if (payloads.length > 0) {
        payloads.map((payload) =>
          listenerApi.dispatch(mapActions.toggleAutoUpdate(payload)),
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  },
});

mapListener.startListening({
  actionCreator: mapActions.mapStartAnimation,
  effect: ({ payload }, listenerApi) => {
    const { mapId, initialTime } = payload;

    // Check if the mapId is part of a syncgroup
    const syncedMapIds: string[] =
      synchronizationGroupsSelector.getSyncedMapIdsForTimeslider(
        listenerApi.getState(),
      );
    const isSyncedMap = syncedMapIds.includes(mapId);
    const autoUpdateMapId = syncedMapIds.find((syncedMapId) =>
      mapSelectors.isAutoUpdating(listenerApi.getState(), syncedMapId),
    );

    // Start animation with the mapId that started the animation or in case of syncgroup, the map that is auto updating
    const useMapId = isSyncedMap ? autoUpdateMapId || mapId : mapId;

    const speedDelay = mapSelectors.getMapAnimationDelay(
      listenerApi.getState(),
      useMapId,
    );
    const speed = 1000 / (speedDelay || 1);
    metronome.register(null, speed, useMapId);

    const timeList = mapSelectors.getAnimationList(
      listenerApi.getState(),
      useMapId,
    );

    // In case of the timeList
    if (timeList && timeList.length > 0) {
      // Determine animation step based on initialTime and timeList
      const initalTimerStep = timeList?.findIndex((timeNameValue) => {
        return timeNameValue.value === initialTime;
      });
      if (initalTimerStep !== -1) {
        setStep(useMapId, initalTimerStep);
      }
    }
  },
});

mapListener.startListening({
  actionCreator: mapActions.mapStopAnimation,
  effect: ({ payload: { mapId } }, listenerApi) => {
    // Check if the mapId is part of a syncgroup
    const syncedMapIds: string[] =
      synchronizationGroupsSelector.getSyncedMapIdsForTimeslider(
        listenerApi.getState(),
      );
    const isSyncedMap = syncedMapIds.includes(mapId);

    const mapIdsToUnregister = isSyncedMap ? syncedMapIds : [mapId];
    mapIdsToUnregister.forEach((mapId) => metronome.unregister(mapId));
  },
});

const handleBaseLayers = (
  mapId: string,
  baseLayers: Layer[],
  listenerApi: ListenerEffectAPI<
    WebMapStateModuleState,
    ThunkDispatch<WebMapStateModuleState, unknown, LayerActions>
  >,
): void => {
  const baseLayer = baseLayers.find((layer) => layer.layerType === 'baseLayer');
  const currentAvailableBaseLayers =
    layerSelectors.getAvailableBaseLayersForMap(listenerApi.getState(), mapId);

  // find a availableBaseLayer with the same name, and use that id for the active baselayer
  const activeAvailableBaseLayer = currentAvailableBaseLayers.find(
    (availableBaseLayer: ReduxLayer) =>
      availableBaseLayer.name === baseLayer!.name,
  );

  const activeBaseLayerId = activeAvailableBaseLayer
    ? activeAvailableBaseLayer.id
    : webmapUtils.generateLayerId();

  // if the baseLayer can't be found in a visible available baseLayer list, add it
  // This happens when switching to a preset that has non-default baselayers
  if (!activeAvailableBaseLayer && currentAvailableBaseLayers.length) {
    listenerApi.dispatch(
      layerActions.addAvailableBaseLayers({
        layers: [{ ...baseLayer, mapId, id: activeBaseLayerId }],
      }),
    );
  }

  const baseLayersWithActiveId = baseLayers.map((layer, index) =>
    index === 0
      ? {
          ...layer,
          id: activeBaseLayerId || layer.id,
        }
      : layer,
  );

  listenerApi.dispatch(
    layerActions.setBaseLayers({
      mapId,
      layers: baseLayersWithActiveId,
    }),
  );
};

mapListener.startListening({
  actionCreator: mapActions.setMapPreset,
  effect: async ({ payload }, listenerApi) => {
    try {
      const { mapId, initialProps } = payload;
      const { mapPreset } = initialProps;

      if (mapPreset) {
        const {
          layers,
          activeLayerId,
          autoTimeStepLayerId,
          autoUpdateLayerId,
          proj,
          shouldAutoUpdate,
          shouldAnimate,
          animationPayload,
          toggleTimestepAuto,
          showTimeSlider,
          displayMapPin,
          shouldShowZoomControls,
          shouldShowLegend,
          dockedLayerManagerSize,
          timeSliderSpan,
          toggleTimeSpanAuto,
        } = mapPreset;

        const { mapLayers, baseLayers, overLayers } = filterLayers(layers);

        if (layers) {
          //  make sure all layers have a unique id before going forward
          let autoTimeStepLayerIdNew = autoTimeStepLayerId;
          let autoUpdateLayerIdNew = autoUpdateLayerId;
          const onlyActiveLayerIdIsSet =
            !autoTimeStepLayerId && !autoUpdateLayerId && activeLayerId;
          if (onlyActiveLayerIdIsSet) {
            autoTimeStepLayerIdNew = activeLayerId;
            autoUpdateLayerIdNew = activeLayerId;
          }
          const newLayerIds: LayersAndAutoLayerIds =
            replaceLayerIdsToEnsureUniqueLayerIdsInStore({
              layers: mapLayers,
              autoTimeStepLayerId: autoTimeStepLayerIdNew,
              autoUpdateLayerId: autoUpdateLayerIdNew,
            });

          //  set layers
          listenerApi.dispatch(
            layerActions.setLayers({
              mapId,
              layers: newLayerIds.layers,
            }),
          );

          // make sure layer with new id exist and if not, default to first layer
          const newAutoUpdateLayerId =
            newLayerIds.layers.find(
              (layer) => layer.id === newLayerIds.autoUpdateLayerId,
            )?.id || newLayerIds.layers[0]?.id;

          const newAutoTimeStepLayerId =
            newLayerIds.layers.find(
              (layer) => layer.id === newLayerIds.autoTimeStepLayerId,
            )?.id || newLayerIds.layers[0]?.id;

          listenerApi.dispatch(
            mapActions.setAutoLayerId({
              mapId,
              autoUpdateLayerId: newAutoUpdateLayerId,
              autoTimeStepLayerId: newAutoTimeStepLayerId,
            }),
          );
        }

        const customLayers = mapSelectors.getdefaultMapSettingsLayers(
          listenerApi.getState(),
        );
        const customBaseLayer = customLayers?.find(
          (layer) => layer.layerType === LayerType.baseLayer,
        );
        const customOverLayer = customLayers?.find(
          (layer) => layer.layerType === LayerType.overLayer,
        );

        // sets (default) baseLayers
        const baseLayersWithDefaultLayer = baseLayers.length
          ? baseLayers
          : [customBaseLayer || defaultLayers.baseLayerGrey];
        // sets (default) overLayers
        const overLayersWithDefaultLayer = overLayers!.length
          ? overLayers
          : [customOverLayer || defaultLayers.overLayer];

        const allBaseLayers = [
          ...baseLayersWithDefaultLayer,
          ...overLayersWithDefaultLayer!,
        ].map((layer) => ({
          ...layer,
          id: webmapUtils.generateLayerId(),
        })) as Layer[];

        handleBaseLayers(mapId, allBaseLayers, listenerApi);

        if (proj) {
          const checkIsprojectionSupported = isProjectionSupported(proj.srs);

          if (!checkIsprojectionSupported) {
            throw new Error(`Projection ${proj.srs} is not supported`);
          }

          //  set bbox
          listenerApi.dispatch(
            mapActions.setBbox({
              mapId,
              bbox: proj.bbox,
              srs: proj.srs,
            }),
          );
        }

        const animationLength = animationPayload && animationPayload.duration;
        const animationEndTime =
          animationPayload &&
          animationPayload.endTime &&
          isAnimationEndTimeValid(animationPayload.endTime) &&
          animationPayload.endTime;
        const shouldEndtimeOverride = animationPayload
          ? animationPayload.shouldEndtimeOverride
          : false;

        if (shouldEndtimeOverride) {
          //  auto update
          listenerApi.dispatch(
            mapActions.setEndTimeOverriding({
              mapId,
              shouldEndtimeOverride,
            }),
          );
        }

        if (shouldAutoUpdate !== undefined && !animationEndTime) {
          //  auto update
          listenerApi.dispatch(
            mapActions.toggleAutoUpdate({
              mapId,
              shouldAutoUpdate,
            }),
          );
        }

        if (showTimeSlider !== undefined) {
          // toggle timeslider
          listenerApi.dispatch(
            mapActions.toggleTimeSliderIsVisible({
              mapId,
              isTimeSliderVisible: showTimeSlider,
            }),
          );
        }
        if (timeSliderSpan !== undefined) {
          // set timeslider span
          listenerApi.dispatch(
            mapActions.setTimeSliderSpan({
              mapId,
              timeSliderSpan: timeSliderSpan!,
            }),
          );
        }
        if (toggleTimeSpanAuto !== undefined) {
          // toggle timeslider span auto
          listenerApi.dispatch(
            mapActions.toggleTimeSpanAuto({
              mapId,
              timeSpanAuto: toggleTimeSpanAuto,
            }),
          );
        }
        if (shouldShowZoomControls !== undefined) {
          // toggle zoom controls
          listenerApi.dispatch(
            mapActions.toggleZoomControls({ mapId, shouldShowZoomControls }),
          );
        }

        if (displayMapPin !== undefined) {
          //  display map pin
          listenerApi.dispatch(
            mapActions.toggleMapPinIsVisible({
              mapId,
              displayMapPin,
            }),
          );
        }

        // sets timestep by interval of animationPayload
        const interval = animationPayload && animationPayload.interval;
        if (interval) {
          listenerApi.dispatch(
            mapActions.setTimeStep({ mapId, timeStep: interval }),
          );
        }

        // sets animationEndTime by endTime of animationPayload

        if (animationEndTime) {
          const endTime =
            animationEndTime.includes('NOW') ||
            animationEndTime.includes('TODAY')
              ? dateUtils.convertNOWandTODAYFormatsToUTC(animationEndTime)
              : animationEndTime;

          listenerApi.dispatch(
            mapActions.setAnimationEndTime({
              mapId,
              animationEndTime: handleDateUtilsISOString(endTime),
            }),
          );

          const startTime = handleDateUtilsISOString(
            dateUtils
              .sub(dateUtils.utc(endTime), {
                minutes: 5 * 60, // set to default of 5 hours
              })
              .toISOString(),
          );

          listenerApi.dispatch(
            mapActions.setAnimationStartTime({
              mapId,
              animationStartTime: startTime,
            }),
          );

          listenerApi.dispatch(
            genericActions.setTime({
              origin: '',
              sourceId: mapId,
              value: startTime,
            }),
          );

          const centerTimeInSeconds = new Date(
            handleDateUtilsISOString(endTime),
          ).getTime();

          listenerApi.dispatch(
            mapActions.setTimeSliderCenterTime({
              mapId,
              timeSliderCenterTime: centerTimeInSeconds,
            }),
          );
        }

        // sets animationStartTime by duration of animationPayload
        if (animationLength) {
          const animationEnd = mapSelectors.getAnimationEndTime(
            listenerApi.getState(),
            mapId,
          );

          const startTime = handleDateUtilsISOString(
            dateUtils
              .sub(dateUtils.utc(animationEnd), {
                minutes: animationLength,
              })
              .toISOString(),
          );
          listenerApi.dispatch(
            mapActions.setAnimationStartTime({
              mapId,
              animationStartTime: startTime,
            }),
          );

          listenerApi.dispatch(
            genericActions.setTime({
              origin: '',
              sourceId: mapId,
              value: startTime,
            }),
          );
        }

        // sets animationDelay by speed of animationPayload
        if (animationPayload && animationPayload.speed) {
          listenerApi.dispatch(
            mapActions.setAnimationDelay({
              mapId,
              animationDelay: getSpeedDelay(animationPayload.speed),
            }),
          );
        }

        //  turn animation on
        if (shouldAnimate === true) {
          const duration =
            animationPayload && animationPayload.duration
              ? animationPayload.duration
              : 5 * 60; //  set to default of 5 hours
          const animationEnd =
            shouldEndtimeOverride && animationEndTime
              ? mapSelectors.getAnimationEndTime(listenerApi.getState(), mapId)
              : dateUtils.dateToString(dateUtils.utc(), dateFormat);

          const animationStart =
            shouldEndtimeOverride && animationLength
              ? mapSelectors.getAnimationStartTime(
                  listenerApi.getState(),
                  mapId,
                )
              : dateUtils.dateToString(
                  dateUtils.sub(dateUtils.utc(animationEnd), {
                    minutes: duration,
                  }),
                  dateFormat,
                );

          listenerApi.dispatch(
            mapActions.mapStartAnimation({
              mapId,
              start: animationStart,
              end: animationEnd,
              interval: interval || defaultTimeStep,
            }),
          );

          //  If animation interval set, set the timestep auto property to false
          if (interval) {
            listenerApi.dispatch(
              mapActions.toggleTimestepAuto({
                mapId,
                timestepAuto: false,
              }),
            );
          }
        } else if (toggleTimestepAuto !== undefined) {
          //  Set timestep auto based on preset if animation is off
          listenerApi.dispatch(
            mapActions.toggleTimestepAuto({
              mapId,
              timestepAuto: toggleTimestepAuto,
            }),
          );
        }

        // show legend
        const shouldOpenLegend =
          shouldShowLegend !== undefined
            ? shouldShowLegend
            : IS_LEGEND_OPEN_BY_DEFAULT;
        const legendId = mapSelectors.getLegendId(
          listenerApi.getState(),
          mapId,
        );
        if (legendId) {
          listenerApi.dispatch(
            uiActions.setToggleOpenDialog({
              type: legendId,
              setOpen: shouldOpenLegend,
            }),
          );
        }

        if (dockedLayerManagerSize) {
          listenerApi.dispatch(
            mapActions.setDockedLayerManagerSize({
              mapId,
              dockedLayerManagerSize,
            }),
          );
        }

        while (
          animationEndTime &&
          (shouldEndtimeOverride || !(shouldAutoUpdate || shouldAnimate))
        ) {
          const fiveMinuteDelayForAnimation = 1000 * 60 * 5;
          // eslint-disable-next-line no-await-in-loop
          await listenerApi.delay(fiveMinuteDelayForAnimation);
          const animationEnd = mapSelectors.getAnimationEndTime(
            listenerApi.getState(),
            mapId,
          );
          listenerApi.dispatch(
            mapActions.setAnimationEndTime({
              mapId,
              animationEndTime: dateUtils
                .add(dateUtils.utc(animationEnd), {
                  minutes: 5,
                })
                .toISOString(),
            }),
          );

          const animationStart = mapSelectors.getAnimationStartTime(
            listenerApi.getState(),
            mapId,
          );

          listenerApi.dispatch(
            mapActions.setAnimationStartTime({
              mapId,
              animationStartTime: dateUtils
                .add(dateUtils.utc(animationStart), {
                  minutes: 5,
                })
                .toISOString(),
            }),
          );
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        listenerApi.dispatch(
          mapActions.setMapPresetError({
            mapId: payload.mapId,
            error: error.message,
          }),
        );
      }
    }
  },
});

mapListener.startListening({
  actionCreator: mapActions.unregisterMap,
  effect: ({ payload }, listenerApi) => {
    const { mapId } = payload;
    const layerList = layerSelectors.getLayersByMapId(
      listenerApi.getState(),
      mapId,
    );
    metronome.unregister(mapId);
    (layerList as ReduxLayer[]).map((layer) =>
      listenerApi.dispatch(
        layerActions.layerDelete({
          mapId,
          layerId: layer.id as string,
          origin: LayerActionOrigin.unregisterMapListener,
        }),
      ),
    );
  },
});

mapListener.startListening({
  actionCreator: mapActions.setStepBackwardOrForward,
  effect: ({ payload }, listenerApi) => {
    const { mapId, isForwardStep } = payload;
    const timeStep = mapSelectors.getMapTimeStep(listenerApi.getState(), mapId);

    const currentTime = mapSelectors.getSelectedTime(
      listenerApi.getState(),
      mapId,
    );

    const [dataStartTime, dataEndTime] = mapSelectors.getDataLimitsFromLayers(
      listenerApi.getState(),
      mapId,
    );

    if (dateUtils.isValid(currentTime)) {
      const makeForwardStep = (): string => {
        const nextTime = currentTime + timeStep;
        const roundedTime = roundWithTimeStep(nextTime, timeStep, 'ceil');
        const newTime = Math.min(roundedTime, dataEndTime || roundedTime);
        return dateUtils.fromUnix(newTime).toISOString();
      };

      const makeBackwardStep = (): string => {
        const nextTime = currentTime - timeStep;
        const roundedTime = roundWithTimeStep(nextTime, timeStep, 'floor');
        const newTime = Math.max(roundedTime, dataStartTime || roundedTime);
        return dateUtils.fromUnix(newTime).toISOString();
      };

      const selectedTimeString = isForwardStep
        ? makeForwardStep()
        : makeBackwardStep();

      const isMapAnimating = mapSelectors.isAnimating(
        listenerApi.getState(),
        mapId,
      );
      if (isMapAnimating) {
        listenerApi.dispatch(
          mapActions.mapStopAnimation({
            mapId,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
      }
      listenerApi.dispatch(
        genericActions.setTime({
          origin: '',
          sourceId: mapId,
          value: handleDateUtilsISOString(selectedTimeString),
        }),
      );
    }
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { dateUtils } from '@opengeoweb/shared';
import { Change, NSC, TafFromBackend } from '../../types';
import { WeatherPhenomena } from '../weatherPhenomena';

// Used to mimic an authenticated user
export const MOCK_USERNAME = 'user.name';

const currentDate = dateUtils.dateToString(
  dateUtils.utc(),
  dateUtils.DATE_FORMAT_YEAR,
)!;
const tomorrowDate = dateUtils.dateToString(
  dateUtils.add(dateUtils.utc(), { days: 1 }),
  dateUtils.DATE_FORMAT_YEAR,
)!;
const airportPeriod = 6;
const airportValidity = 40;

const validDateRange = (refHour: Date, period: number): string[] => {
  const hours = dateUtils.dateToString(refHour, 'HH')!;
  const startHour = (period * Math.floor(parseInt(hours, 10) / period)) % 24;
  const start = dateUtils.set(
    refHour,
    {
      hours: startHour,
      minutes: 0,
      seconds: 0,
    },
    true,
  );

  const end = dateUtils.add(start, { hours: airportValidity });

  return [
    dateUtils.dateToString(start, dateUtils.DATE_FORMAT_UTC, false)!,
    dateUtils.dateToString(end, dateUtils.DATE_FORMAT_UTC, false)!,
  ];
};

const dateAdd = (refDateAsString: string, hours: number): string => {
  const newDate = dateUtils.add(dateUtils.utc(refDateAsString), { hours });
  const formattedDate = dateUtils.dateToString(
    newDate,
    dateUtils.DATE_FORMAT_UTC,
  )!;
  return formattedDate;
};

const [currentValidDateStart, currentValidDateEnd] = validDateRange(
  dateUtils.utc(),
  airportPeriod,
);

const [upcomingValidDateStart, upcomingValidDateEnd] = validDateRange(
  dateUtils.add(dateUtils.utc(), { hours: 6 }),
  airportPeriod,
);

const [expiredValidDateStart, expiredValidDateEnd] = validDateRange(
  dateUtils.sub(dateUtils.utc(), { hours: 6 }),
  airportPeriod,
);

export const fakeTafList: TafFromBackend[] = [
  {
    creationDate: upcomingValidDateStart,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: MOCK_USERNAME,
    taf: {
      uuid: '5f9583fjdf0f337925',
      baseTime: upcomingValidDateStart,
      validDateStart: upcomingValidDateStart,
      validDateEnd: upcomingValidDateEnd,
      location: 'EHAM',
      status: 'NEW',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: upcomingValidDateStart,
          end: upcomingValidDateEnd,
        },
      },
    },
  },
  {
    creationDate: upcomingValidDateStart,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: null!,
    taf: {
      uuid: '5f9583fjd111137925',
      baseTime: upcomingValidDateStart,
      validDateStart: upcomingValidDateStart,
      validDateEnd: upcomingValidDateEnd,
      location: 'EHRD',
      status: 'DRAFT',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: upcomingValidDateStart,
          end: upcomingValidDateEnd,
        },
        wind: { direction: 180, speed: 10, unit: 'KT' },
        cavOK: true,
      },
    },
  },
  {
    creationDate: upcomingValidDateStart,
    canbe: ['CANCELLED', 'AMENDED', 'DISCARDED'],
    editor: null!,
    taf: {
      uuid: '754905485',
      baseTime: upcomingValidDateStart,
      previousId: '5f9583fjdf45ff4330f337925',
      validDateStart: upcomingValidDateStart,
      validDateEnd: upcomingValidDateEnd,
      previousValidDateStart: upcomingValidDateStart,
      previousValidDateEnd: upcomingValidDateEnd,
      location: 'EHLE',
      status: 'DRAFT_AMENDED',
      messageType: 'AMD',
      baseForecast: {
        valid: {
          start: upcomingValidDateStart,
          end: upcomingValidDateEnd,
        },
        visibility: { range: 600, unit: 'M' },
        wind: { direction: 180, speed: 10, unit: 'KT' },
        weather: { weather1: 'SN' as WeatherPhenomena },
        cloud: { cloud1: { coverage: 'BKN', height: 35 } },
      },
      changeGroups: [
        {
          change: 'TEMPO',
          probability: 'PROB30',
          valid: {
            start: dateAdd(upcomingValidDateStart, 3),
            end: dateAdd(upcomingValidDateStart, 4),
          },
          visibility: { range: 100, unit: 'M' },
          cloud: {
            cloud1: { verticalVisibility: 5 },
          },
        },
      ],
    },
  },
  {
    creationDate: upcomingValidDateStart,
    canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
    editor: MOCK_USERNAME,
    taf: {
      uuid: '43545345345',
      issueDate: upcomingValidDateStart,
      baseTime: `${currentDate}T18:00:00Z`,
      validDateStart: `${currentDate}T18:00:00Z`,
      validDateEnd: upcomingValidDateEnd,
      previousId: 'jkdjkdfjkgdf',
      previousValidDateStart: upcomingValidDateStart,
      previousValidDateEnd: upcomingValidDateEnd,
      location: 'EHLE',
      status: 'CORRECTED',
      messageType: 'COR',
      baseForecast: {
        valid: {
          start: upcomingValidDateStart,
          end: upcomingValidDateEnd,
        },
        wind: { direction: 350, speed: 46, gust: 67, unit: 'KT' },
        cavOK: true,
      },
      changeGroups: [
        {
          change: 'BECMG',
          valid: {
            start: dateAdd(upcomingValidDateStart, 1),
            end: dateAdd(upcomingValidDateStart, 2),
          },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
          cloud: {
            cloud1: 'NSC' as NSC,
          },
        },
        {
          change: 'FM',
          valid: {
            start: dateAdd(upcomingValidDateStart, 2),
          },
          visibility: { range: 6000, unit: 'M' },
          weather: { weather1: '+SHRA' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
      ],
    },
  },
  {
    creationDate: upcomingValidDateStart,
    canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
    editor: null!,
    taf: {
      uuid: '5f9583fjdf45ff4330f337925',
      issueDate: upcomingValidDateStart,
      baseTime: upcomingValidDateStart,
      validDateStart: upcomingValidDateStart,
      validDateEnd: upcomingValidDateEnd,
      location: 'EHGG',
      status: 'PUBLISHED',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: upcomingValidDateStart,
          end: upcomingValidDateEnd,
        },
        visibility: { range: 600, unit: 'M' },
        wind: { direction: 180, speed: 10, unit: 'KT' },
        weather: { weather1: 'SN' as WeatherPhenomena },
        cloud: { cloud1: { coverage: 'BKN', height: 35 } },
      },
      changeGroups: [
        {
          change: 'TEMPO',
          probability: 'PROB30',
          valid: {
            start: dateAdd(upcomingValidDateStart, 1),
            end: dateAdd(upcomingValidDateStart, 2),
          },
          visibility: { range: 200, unit: 'M' },
          cloud: {
            cloud1: { verticalVisibility: 5 },
          },
        },
      ],
    },
  },
  {
    creationDate: upcomingValidDateStart,
    canbe: ['AMENDED'],
    editor: null!,
    taf: {
      uuid: '5464565464',
      previousId: '5445fghrfg3546456',
      issueDate: upcomingValidDateStart,
      baseTime: upcomingValidDateStart,
      validDateStart: upcomingValidDateStart,
      validDateEnd: upcomingValidDateEnd,
      previousValidDateStart: upcomingValidDateStart,
      previousValidDateEnd: upcomingValidDateEnd,
      location: 'EHBK',
      status: 'CANCELLED',
      messageType: 'CNL',
      baseForecast: {
        valid: {
          start: upcomingValidDateStart,
          end: upcomingValidDateEnd,
        },
        visibility: { range: 600, unit: 'M' },
        wind: { direction: 180, speed: 10, unit: 'KT' },
        weather: { weather1: 'SN' as WeatherPhenomena },
        cloud: { cloud1: { coverage: 'BKN', height: 35 } },
      },
    },
  },
  {
    creationDate: upcomingValidDateStart,
    canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
    editor: 'max.jansen',
    taf: {
      uuid: 'gh56734fghfgh',
      previousId: '8574jnf93475',
      issueDate: `${currentDate}T16:16:00Z`,
      baseTime: upcomingValidDateStart,
      validDateStart: upcomingValidDateStart,
      validDateEnd: upcomingValidDateEnd,
      previousValidDateStart: upcomingValidDateStart,
      previousValidDateEnd: upcomingValidDateEnd,
      location: 'TNCB',
      status: 'AMENDED',
      messageType: 'AMD',
      baseForecast: {
        valid: {
          start: `${currentDate}T16:12:00Z`,
          end: upcomingValidDateEnd,
        },
        visibility: { range: 600, unit: 'M' },
        wind: { direction: 180, speed: 10, unit: 'KT' },
        weather: { weather1: 'SN' as WeatherPhenomena },
        cloud: { cloud1: { coverage: 'BKN', height: 35 } },
      },
    },
  },
  {
    creationDate: currentValidDateStart,
    canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
    editor: null!,
    taf: {
      uuid: 'giskdj09845872039',
      issueDate: currentValidDateStart,
      baseTime: currentValidDateStart,
      validDateStart: currentValidDateStart,
      validDateEnd: currentValidDateEnd,
      location: 'EHAM',
      status: 'PUBLISHED',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: currentValidDateStart,
          end: currentValidDateEnd,
        },
        visibility: { range: 500, unit: 'M' },
        wind: { direction: 200, speed: 20, gust: 35, unit: 'KT' },
        weather: { weather1: 'SN' as WeatherPhenomena },
        cloud: { cloud1: { coverage: 'BKN', height: 40 } },
      },
    },
  },
  {
    creationDate: currentValidDateStart,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: null!,
    taf: {
      uuid: 'ghskdj09845872039',
      baseTime: currentValidDateStart,
      validDateStart: currentValidDateStart,
      validDateEnd: currentValidDateEnd,
      location: 'EHRD',
      status: 'NEW',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: currentValidDateStart,
          end: currentValidDateEnd,
        },
      },
    },
  },
  {
    creationDate: currentValidDateStart,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: null!,
    taf: {
      uuid: 'ghskdj09845872040',
      issueDate: currentValidDateStart,
      baseTime: currentValidDateStart,
      validDateStart: currentValidDateStart,
      validDateEnd: currentValidDateEnd,
      location: 'EHLE',
      status: 'NEW',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: currentValidDateStart,
          end: currentValidDateEnd,
        },
      },
    },
  },
  {
    creationDate: `${currentDate}T06:00:00Z`,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: null!,
    taf: {
      uuid: 'ghskdj09845872041',
      issueDate: currentValidDateStart,
      baseTime: currentValidDateStart,
      validDateStart: currentValidDateStart,
      validDateEnd: currentValidDateEnd,
      location: 'EHGG',
      status: 'NEW',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: currentValidDateStart,
          end: currentValidDateEnd,
        },
      },
    },
  },
  {
    creationDate: `${currentDate}T06:00:00Z`,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: null!,
    taf: {
      uuid: 'ghskdj09845872042',
      issueDate: currentValidDateStart,
      baseTime: currentValidDateStart,
      validDateStart: currentValidDateStart,
      validDateEnd: currentValidDateEnd,
      location: 'EHBK',
      status: 'NEW',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: currentValidDateStart,
          end: currentValidDateEnd,
        },
      },
    },
  },
  {
    creationDate: `${currentDate}T06:00:00Z`,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: null!,
    taf: {
      uuid: 'ghskdj09845872043',
      issueDate: currentValidDateStart,
      baseTime: currentValidDateStart,
      validDateStart: currentValidDateStart,
      validDateEnd: currentValidDateEnd,
      location: 'TNCB',
      status: 'NEW',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: currentValidDateStart,
          end: currentValidDateEnd,
        },
      },
    },
  },
  {
    creationDate: expiredValidDateStart,
    canbe: [],
    editor: null!,
    taf: {
      uuid: 'sdkjhsdf90845890',
      issueDate: expiredValidDateStart,
      baseTime: expiredValidDateStart,
      validDateStart: expiredValidDateStart,
      validDateEnd: expiredValidDateEnd,
      location: 'EHAM',
      status: 'EXPIRED',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: expiredValidDateStart,
          end: expiredValidDateEnd,
        },
        cavOK: true,
        wind: { direction: 50, speed: 5, unit: 'KT' },
      },
      changeGroups: [
        {
          change: 'BECMG',
          valid: {
            start: dateAdd(expiredValidDateStart, 1),
            end: dateAdd(expiredValidDateStart, 4),
          },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
        },
        {
          change: 'FM',
          valid: {
            start: dateAdd(expiredValidDateStart, 5),
          },
          visibility: { range: 6000, unit: 'M' },
          weather: { weather1: '+SHRA' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
      ],
    },
  },
];

export const fakeNewTaf: TafFromBackend = {
  creationDate: `${currentDate}T12:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: null!,
  taf: {
    messageType: 'ORG',
    status: 'NEW',
    uuid: '5f9583fjdf0445332f337925',
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T12:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHBK',
    baseForecast: {
      valid: {
        start: `${currentDate}T12:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
    },
  },
};

export const fakeNewFixedTaf: TafFromBackend = {
  creationDate: `2022-01-06T12:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: MOCK_USERNAME,
  taf: {
    ...fakeNewTaf.taf,
    baseTime: `2022-01-06T12:00:00Z`,
    validDateStart: `2022-01-06T12:00:00Z`,
    validDateEnd: `2022-01-07T18:00:00Z`,
    baseForecast: {
      valid: {
        start: `2022-01-06T12:00:00Z`,
        end: `2022-01-07T18:00:00Z`,
      },
    },
  },
};

export const fakeExpiredTaf: TafFromBackend = {
  creationDate: '2020-12-07T06:00:00Z',
  canbe: [],
  editor: null!,
  taf: {
    messageType: 'ORG',
    status: 'EXPIRED',
    uuid: 'fgskjdhf9845987349',
    issueDate: '2020-12-07T06:00:00Z',
    baseTime: '2020-12-07T06:00:00Z',
    validDateStart: '2020-12-07T06:00:00Z',
    validDateEnd: '2020-12-08T12:00:00Z',
    location: 'EHAM',
    baseForecast: {
      valid: {
        start: '2020-12-07T06:00:00Z',
        end: '2020-12-08T12:00:00Z',
      },
      wind: { direction: 50, speed: 5, unit: 'KT' },
      visibility: { range: 500, unit: 'M' },
      weather: {
        weather1: 'BCFG' as WeatherPhenomena,
        weather2: 'SNDZ' as WeatherPhenomena,
      },
      cloud: {
        cloud1: { coverage: 'FEW', height: 15 },
        cloud2: { coverage: 'SCT', height: 25 },
        cloud3: { coverage: 'BKN', height: 30 },
        cloud4: { coverage: 'OVC', height: 50, type: 'CB' },
      },
    },
    changeGroups: [
      {
        change: 'BECMG',
        valid: {
          start: '2020-12-07T09:00:00Z',
          end: '2020-12-07T12:00:00Z',
        },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'BR' as WeatherPhenomena },
      },
      {
        change: 'FM',
        valid: {
          start: '2020-12-07T15:00:00Z',
        },
        visibility: { range: 6000, unit: 'M' },
        weather: { weather1: 'DZ' as WeatherPhenomena },
        cloud: {
          cloud1: { coverage: 'FEW', height: 15 },
          cloud2: { coverage: 'BKN', height: 30 },
        },
      },
    ],
  },
};

export const fakePublishedTaf: TafFromBackend = {
  creationDate: `${currentDate}T12:00:00Z`,
  canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
  editor: null!,
  taf: {
    uuid: 'slsdkjg-023895',
    issueDate: `${currentDate}T12:00:00Z`,
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T12:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHLE',
    status: 'PUBLISHED',
    messageType: 'ORG',
    baseForecast: {
      valid: {
        start: `${currentDate}T12:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      wind: { direction: 50, speed: 5, unit: 'KT' },
      cavOK: true,
    },
    changeGroups: [
      {
        change: 'FM',
        valid: {
          start: `${currentDate}T12:00:00Z`,
        },
        visibility: { range: 6000, unit: 'M' },
        weather: { weather1: '+SHRA' as WeatherPhenomena },
        wind: { direction: 10, speed: 10, unit: 'KT', gust: 15 },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
      {
        change: 'BECMG',
        valid: {
          start: `${currentDate}T23:00:00Z`,
          end: `${tomorrowDate}T01:00:00Z`,
        },
        wind: { direction: 150, speed: 5, unit: 'KT' },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'MIFG' as WeatherPhenomena },
      },
    ],
  },
};

export const fakePublishedFixedTaf: TafFromBackend = {
  creationDate: `2022-01-06T12:00:00Z`,
  canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
  editor: MOCK_USERNAME,
  taf: {
    ...fakePublishedTaf.taf,
    uuid: 'sowi3u4o-lsdjflsk',
    location: 'EHGG',
    baseTime: `2022-01-06T12:00:00Z`,
    issueDate: `2022-01-06T12:00:00Z`,
    validDateStart: `2022-01-06T12:00:00Z`,
    validDateEnd: `2022-01-07T18:00:00Z`,
    baseForecast: {
      ...fakePublishedTaf.taf.baseForecast,
      valid: {
        start: `2022-01-06T12:00:00Z`,
        end: `2022-01-07T18:00:00Z`,
      },
    },
    changeGroups: [
      {
        change: 'FM',
        valid: {
          start: `2022-01-06T12:00:00Z`,
        },
        visibility: { range: 6000, unit: 'M' },
        weather: { weather1: '+SHRA' as WeatherPhenomena },
        wind: { direction: 10, speed: 10, unit: 'KT', gust: 35 },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
      {
        change: 'BECMG',
        valid: {
          start: `2022-01-06T23:00:00Z`,
          end: `2022-01-07T00:00:00Z`,
        },
        wind: { direction: 150, speed: 5, unit: 'KT' },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'MIFG' as WeatherPhenomena },
      },
    ],
  },
};

export const fakePublishedTafWithoutChangeGroups: TafFromBackend = {
  creationDate: `${currentDate}T12:00:00Z`,
  canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
  editor: null!,
  taf: {
    uuid: 'slsdkjg-023895',
    issueDate: `${currentDate}T12:00:00Z`,
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T12:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHLE',
    status: 'PUBLISHED',
    messageType: 'ORG',
    baseForecast: {
      valid: {
        start: `${currentDate}T12:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      wind: { direction: 50, speed: 5, unit: 'KT' },
      cavOK: true,
    },
  },
};

export const fakeCancelledTaf: TafFromBackend = {
  creationDate: `${currentDate}T12:00:00Z`,
  canbe: ['AMENDED'],
  editor: null!,
  taf: {
    uuid: 'slsdkjg-023895',
    issueDate: `${currentDate}T12:00:00Z`,
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T12:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHLE',
    status: 'CANCELLED',
    messageType: 'CNL',
    baseForecast: {
      valid: {
        start: `${currentDate}T12:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      wind: { direction: 50, speed: 5, unit: 'KT' },
      cavOK: true,
    },
    changeGroups: [],
  },
};

export const fakeDraftTaf: TafFromBackend = {
  creationDate: `${currentDate}T18:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: null!,
  taf: {
    uuid: '5f9583fjdf0f337925',
    baseTime: `${currentDate}T18:00:00Z`,
    validDateStart: `${currentDate}T18:00:00Z`,
    validDateEnd: `${tomorrowDate}T23:59:00Z`,
    location: 'EHLE',
    status: 'DRAFT',
    messageType: 'ORG',
    baseForecast: {
      valid: {
        start: `${currentDate}T18:00:00Z`,
        end: `${tomorrowDate}T23:59:00Z`,
      },
      wind: { direction: 150, speed: 5, unit: 'KT' },
      visibility: { range: 1000, unit: 'M' },
      weather: {
        weather1: 'BCFG' as WeatherPhenomena,
      },
      cloud: {
        cloud1: { coverage: 'FEW', height: 15 },
      },
    },
  },
};

export const fakeDraftFixedTaf: TafFromBackend = {
  creationDate: `2022-01-06T18:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: MOCK_USERNAME,
  taf: {
    ...fakeDraftTaf.taf,
    baseTime: `2022-01-06T18:00:00Z`,
    validDateStart: `2022-01-06T18:00:00Z`,
    validDateEnd: `2022-01-07T23:59:00Z`,
    baseForecast: {
      ...fakeDraftTaf.taf.baseForecast,
      valid: {
        start: `2022-01-06T18:00:00Z`,
        end: `2022-01-07T23:59:00Z`,
      },
    },
  },
};

export const fakeDraftAmendmentTaf: TafFromBackend = {
  creationDate: `${currentDate}T14:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: null!,
  taf: {
    uuid: '954905487',
    previousId: '5f9583fjdf45ff4330f337925',
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T14:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    previousValidDateStart: `${currentDate}T12:00:00Z`,
    previousValidDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHAM',
    status: 'DRAFT_AMENDED',
    messageType: 'AMD',
    baseForecast: {
      valid: {
        start: `${currentDate}T14:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      visibility: { range: 600, unit: 'M' },
      wind: { direction: 180, speed: 10, unit: 'KT' },
      weather: { weather1: 'SN' as WeatherPhenomena },
      cloud: { cloud1: { coverage: 'BKN', height: 35 } },
    },
    changeGroups: [
      {
        change: 'TEMPO',
        probability: 'PROB30',
        valid: {
          start: `${currentDate}T15:00:00Z`,
          end: `${currentDate}T16:00:00Z`,
        },
        visibility: { range: 100, unit: 'M' },
        cloud: {
          cloud1: { verticalVisibility: 5 },
        },
      },
    ],
  },
};

export const fakeDraftCorrectedTaf: TafFromBackend = {
  creationDate: `${currentDate}T14:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: null!,
  taf: {
    uuid: '754905487',
    previousId: '5f9583fjdf45ff4330f337927',
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T14:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    previousValidDateStart: `${currentDate}T12:00:00Z`,
    previousValidDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHBK',
    status: 'DRAFT_CORRECTED',
    messageType: 'COR',
    baseForecast: {
      valid: {
        start: `${currentDate}T14:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      visibility: { range: 600, unit: 'M' },
      wind: { direction: 200, speed: 10, unit: 'KT' },
      weather: { weather1: 'SN' as WeatherPhenomena },
      cloud: { cloud1: { coverage: 'BKN', height: 35 } },
    },
    changeGroups: [],
  },
};

// Filter out the changegroups for testing purposes as the valid times get adjusted
const { changeGroups, ...fakeDraftAmendmentWithoutChangeGroups } =
  fakeDraftAmendmentTaf.taf;
// Only use for display purposes in the list!
export const fakeDraftAmendmentFixedTaf: TafFromBackend = {
  creationDate: `2022-01-06T14:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: null!,
  taf: {
    ...fakeDraftAmendmentWithoutChangeGroups,
    location: 'EHRD',
    baseTime: `2022-01-06T12:00:00Z`,
    validDateStart: `2022-01-06T14:00:00Z`,
    validDateEnd: `2022-01-07T18:00:00Z`,
    previousId: `previousIdOfPreviousTAF`,
    previousValidDateStart: `2022-01-06T12:00:00Z`,
    previousValidDateEnd: `2022-01-07T18:00:00Z`,
    baseForecast: {
      ...fakeDraftAmendmentTaf.taf.baseForecast,
      valid: {
        start: `2022-01-06T14:00:00Z`,
        end: `2022-01-07T18:00:00Z`,
      },
    },
  },
};

// Use as the original previous TAF for TAF above
export const previousTafForfakeDraftAmendmentFixedTaf: TafFromBackend = {
  creationDate: `2022-01-06T14:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: null!,
  taf: {
    ...fakeDraftAmendmentWithoutChangeGroups,
    status: 'AMENDED',
    uuid: 'previousIdOfPreviousTAF',
    location: 'EHRD',
    baseTime: `2022-01-06T12:00:00Z`,
    validDateStart: `2022-01-06T14:00:00Z`,
    validDateEnd: `2022-01-07T18:00:00Z`,
    baseForecast: {
      ...fakeDraftAmendmentTaf.taf.baseForecast,
      valid: {
        start: `2022-01-06T14:00:00Z`,
        end: `2022-01-07T18:00:00Z`,
      },
      wind: { direction: 344, speed: 90, unit: 'KT' },
    },
  },
};

export const fakeAmendmentTaf: TafFromBackend = {
  creationDate: `${currentDate}T14:00:00Z`,
  canbe: ['CANCELLED', 'CORRECTED', 'AMENDED'],
  editor: null!,
  taf: {
    uuid: '754905486',
    previousId: '5f9583fjdf45ff4330f337925',
    issueDate: `${currentDate}T14:00:00Z`,
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T14:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    previousValidDateStart: `${currentDate}T12:00:00Z`,
    previousValidDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHAM',
    status: 'AMENDED',
    messageType: 'AMD',
    baseForecast: {
      valid: {
        start: `${currentDate}T14:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      visibility: { range: 600, unit: 'M' },
      wind: { direction: 180, speed: 10, unit: 'KT' },
      weather: { weather1: 'SN' as WeatherPhenomena },
      cloud: { cloud1: { coverage: 'BKN', height: 35 } },
    },
  },
};

export const fakeCorrectedTaf: TafFromBackend = {
  creationDate: `${currentDate}T14:00:00Z`,
  canbe: ['CANCELLED', 'CORRECTED', 'AMENDED'],
  editor: null!,
  taf: {
    uuid: '754905486',
    previousId: '5f9583fjdf45ff4330f337925',
    issueDate: `${currentDate}T14:00:00Z`,
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T14:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    previousValidDateStart: `${currentDate}T12:00:00Z`,
    previousValidDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHAM',
    status: 'CORRECTED',
    messageType: 'COR',
    baseForecast: {
      valid: {
        start: `${currentDate}T14:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      visibility: { range: 600, unit: 'M' },
      wind: { direction: 180, speed: 10, unit: 'KT' },
      weather: { weather1: 'SN' as WeatherPhenomena },
      cloud: { cloud1: { coverage: 'BKN', height: 35 } },
    },
  },
};

// Only use for display purposes in the list!
export const fakeAmendmentFixedTaf: TafFromBackend = {
  creationDate: `2022-01-06T14:00:00Z`,
  canbe: ['CANCELLED', 'CORRECTED', 'AMENDED'],
  editor: MOCK_USERNAME,
  taf: {
    ...fakeAmendmentTaf.taf,
    uuid: '7549058980',
    issueDate: `2022-01-06T14:00:00Z`,
    baseTime: `2022-01-06T12:00:00Z`,
    validDateStart: `2022-01-06T14:00:00Z`,
    validDateEnd: `2022-01-07T18:00:00Z`,
    previousValidDateStart: `2022-01-06T12:00:00Z`,
    previousValidDateEnd: `2022-01-07T18:00:00Z`,
    baseForecast: {
      ...fakeAmendmentTaf.taf.baseForecast,
      valid: {
        start: `2022-01-06T14:00:00Z`,
        end: `2022-01-07T18:00:00Z`,
      },
    },
  },
};

export const fakeCancelledFixedTaf: TafFromBackend = {
  creationDate: `2022-01-06T14:00:00Z`,
  canbe: ['AMENDED'],
  editor: null!,
  taf: {
    uuid: '1464565464',
    previousId: '1464565464',
    issueDate: `2022-01-06T14:00:00Z`,
    baseTime: `2022-01-06T12:00:00Z`,
    validDateStart: `2022-01-06T14:00:00Z`,
    validDateEnd: `2022-01-07T18:00:00Z`,
    previousValidDateStart: `2022-01-06T12:00:00Z`,
    previousValidDateEnd: `2022-01-07T18:00:00Z`,
    location: 'TNCB',
    status: 'CANCELLED',
    messageType: 'CNL',
    baseForecast: {
      valid: {
        start: `2022-01-06T14:00:00Z`,
        end: `2022-01-07T18:00:00Z`,
      },
      visibility: { range: 600, unit: 'M' },
      wind: { direction: 180, speed: 10, unit: 'KT' },
      weather: { weather1: 'SN' as WeatherPhenomena },
      cloud: { cloud1: { coverage: 'BKN', height: 35 } },
    },
  },
};

export const fakeFixedTafList = [
  fakeAmendmentFixedTaf,
  fakeDraftAmendmentFixedTaf,
  fakeDraftFixedTaf,
  fakePublishedFixedTaf,
  fakeNewFixedTaf,
  fakeCancelledFixedTaf,
];

export const fakePublishedTafWithChangeGroups: TafFromBackend = {
  creationDate: `${currentDate}T12:00:00Z`,
  canbe: ['CANCELLED', 'AMENDED', 'CORRECTED'],
  editor: null!,
  taf: {
    uuid: 'sl023895',
    issueDate: `${currentDate}T12:00:00Z`,
    baseTime: `${currentDate}T12:00:00Z`,
    validDateStart: `${currentDate}T12:00:00Z`,
    validDateEnd: `${tomorrowDate}T18:00:00Z`,
    location: 'EHLE',
    status: 'PUBLISHED',
    messageType: 'ORG',
    baseForecast: {
      valid: {
        start: `${currentDate}T12:00:00Z`,
        end: `${tomorrowDate}T18:00:00Z`,
      },
      wind: { direction: 50, speed: 5, unit: 'KT' },
      cavOK: true,
    },
    changeGroups: [
      {
        change: 'FM',
        valid: {
          start: `${currentDate}T12:00:00Z`,
        },
        visibility: { range: 6000, unit: 'M' },
        weather: { weather1: '+SHRA' as WeatherPhenomena },
        wind: { direction: 10, speed: 10, unit: 'KT' },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
      {
        change: 'BECMG',
        valid: {
          start: `${currentDate}T12:00:00Z`,
          end: `${tomorrowDate}T00:00:00Z`,
        },
        wind: { direction: 150, speed: 5, unit: 'KT' },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'MIFG' as WeatherPhenomena },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
      {
        change: 'TEMPO',
        valid: {
          start: `${currentDate}T23:00:00Z`,
          end: `${tomorrowDate}T00:00:00Z`,
        },
        wind: { direction: 150, speed: 5, unit: 'KT' },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'MIFG' as WeatherPhenomena },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
    ],
  },
};

const generateFakeDraftTafWithSameDates = (
  currentDate: string,
  tomorrowDate: string,
): TafFromBackend => {
  return {
    creationDate: `${currentDate}T12:00:00Z`,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    editor: MOCK_USERNAME,
    taf: {
      uuid: 'slsdkjg-023895',
      baseTime: `${currentDate}T12:00:00Z`,
      validDateStart: `${currentDate}T12:00:00Z`,
      validDateEnd: `${tomorrowDate}T18:00:00Z`,
      location: 'EHLE',
      status: 'DRAFT',
      messageType: 'ORG',
      baseForecast: {
        valid: {
          start: `${currentDate}T12:00:00Z`,
          end: `${tomorrowDate}T18:00:00Z`,
        },
        wind: { direction: 50, speed: 5, unit: 'KT' },
        cavOK: true,
      },
      changeGroups: [
        {
          change: 'FM',
          valid: {
            start: `${currentDate}T23:00:00Z`,
          },
          visibility: { range: 6000, unit: 'M' },
          weather: { weather1: '+SHRA' as WeatherPhenomena },
          wind: { direction: 10, speed: 10, unit: 'KT', gust: 35 },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
        {
          change: 'BECMG',
          valid: {
            start: `${currentDate}T23:00:00Z`,
            end: `${tomorrowDate}T00:00:00Z`,
          },
          wind: { direction: 150, speed: 5, unit: 'KT' },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
        {
          change: 'TEMPO',
          valid: {
            start: `${currentDate}T23:00:00Z`,
            end: `${tomorrowDate}T00:00:00Z`,
          },
          wind: { direction: 150, speed: 5, unit: 'KT' },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
        {
          probability: 'PROB40',
          change: '' as Change,
          valid: {
            start: `${currentDate}T23:00:00Z`,
            end: `${tomorrowDate}T00:00:00Z`,
          },
          wind: { direction: 150, speed: 5, unit: 'KT' },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
        {
          probability: 'PROB30',
          change: '' as Change,
          valid: {
            start: `${currentDate}T23:00:00Z`,
            end: `${tomorrowDate}T00:00:00Z`,
          },
          wind: { direction: 150, speed: 5, unit: 'KT' },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
        {
          probability: 'PROB40',
          change: 'TEMPO',
          valid: {
            start: `${currentDate}T23:00:00Z`,
            end: `${tomorrowDate}T00:00:00Z`,
          },
          wind: { direction: 150, speed: 5, unit: 'KT' },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
        {
          probability: 'PROB30',
          change: 'TEMPO',
          valid: {
            start: `${currentDate}T23:00:00Z`,
            end: `${tomorrowDate}T00:00:00Z`,
          },
          wind: { direction: 150, speed: 5, unit: 'KT' },
          visibility: { range: 1000, unit: 'M' },
          weather: { weather1: 'MIFG' as WeatherPhenomena },
          cloud: {
            cloud1: { coverage: 'BKN', height: 40 },
            cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
          },
        },
      ],
    },
  };
};

export const fakeDraftTafWithSameDates: TafFromBackend =
  generateFakeDraftTafWithSameDates(currentDate, tomorrowDate);

export const fakeDraftTafWithSameDatesFixed: TafFromBackend =
  generateFakeDraftTafWithSameDates('2022-01-06', '2022-01-07');

export const fakeDraftTafWithFM: TafFromBackend = {
  creationDate: `2022-01-06T12:00:00Z`,
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  editor: MOCK_USERNAME,
  taf: {
    uuid: 'fm023895',
    baseTime: `2022-01-06T12:00:00Z`,
    validDateStart: `2022-01-06T12:00:00Z`,
    validDateEnd: `2022-01-07T18:00:00Z`,
    location: 'EHLE',
    status: 'DRAFT',
    messageType: 'ORG',
    baseForecast: {
      valid: {
        start: `2022-01-06T12:00:00Z`,
        end: `2022-01-07T18:00:00Z`,
      },
      wind: { direction: 50, speed: 5, unit: 'KT' },
      cavOK: true,
    },
    changeGroups: [
      {
        change: 'BECMG',
        valid: {
          start: `2022-01-06T12:00:00Z`,
          end: `2022-01-06T16:00:00Z`,
        },
        wind: { direction: 150, speed: 5, unit: 'KT' },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'MIFG' as WeatherPhenomena },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
      {
        change: 'TEMPO',
        valid: {
          start: `2022-01-06T12:00:00Z`,
          end: `2022-01-06T16:00:00Z`,
        },
        wind: { direction: 150, speed: 5, unit: 'KT' },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'MIFG' as WeatherPhenomena },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
      {
        change: 'FM',
        valid: {
          start: `2022-01-06T14:00:00Z`,
        },
        visibility: { range: 6000, unit: 'M' },
        weather: { weather1: '+SHRA' as WeatherPhenomena },
        wind: { direction: 10, speed: 10, unit: 'KT' },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
      {
        change: 'TEMPO',
        valid: {
          start: `2022-01-06T14:00:00Z`,
          end: `2022-01-07T00:00:00Z`,
        },
        wind: { direction: 150, speed: 5, unit: 'KT' },
        visibility: { range: 1000, unit: 'M' },
        weather: { weather1: 'MIFG' as WeatherPhenomena },
        cloud: {
          cloud1: { coverage: 'BKN', height: 40 },
          cloud2: { coverage: 'OVC', height: 60, type: 'CB' },
        },
      },
    ],
  },
};

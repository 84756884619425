/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Polygon } from 'geojson';
import {
  hasIntersectionWithFIR,
  isMaximumOneDrawing,
  isValidGeoJsonCoordinates,
  ReactHookFormHiddenInput,
  hasMulitpleIntersections,
  useDraftFormHelpers,
  countIntersectionPoints,
} from '@opengeoweb/form-fields';

import { DrawMode } from '@opengeoweb/webmap-react';
import DrawTools from './DrawTools';
import { styles } from '../ProductForm.styles';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { isFir, hasMaxFeaturePoints, getMaxPolygonPoints } from '../utils';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import { ProductConfig } from '../../../types';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

interface StartGeometryProps {
  isReadOnly?: boolean;
  geoJSON: GeoJSON.FeatureCollection;
  geoJSONIntersection: GeoJSON.FeatureCollection | undefined;
  activeTool: string;
  tools: DrawMode[];
  onChangeTool: (newToolMode: DrawMode) => void;
  productConfig: ProductConfig;
}

const StartGeometry: React.FC<StartGeometryProps> = ({
  isReadOnly = false,
  activeTool,
  tools,
  onChangeTool,
  geoJSON,
  geoJSONIntersection,
  productConfig,
}: StartGeometryProps) => {
  const { t } = useSigmetAirmetTranslation();
  const {
    formState: { errors },
    watch,
    getValues,
  } = useFormContext();
  const { isDraft } = useDraftFormHelpers();
  const { isShortTest } = useShortTestHelpers();
  const getFIRValue = (): string => getValues('locationIndicatorATSR');

  // Max points the FIR allows for a polygon
  const maxPoints = getMaxPolygonPoints(getFIRValue(), productConfig);

  // Intersections with FIR
  const intersectionPointCount = countIntersectionPoints(
    geoJSON as GeoJSON.FeatureCollection<Polygon>,
    geoJSONIntersection! as GeoJSON.FeatureCollection<Polygon>,
  );
  const drawnGeometry = geoJSON?.features[0]?.geometry as GeoJSON.Polygon;
  const interceptionGeometry = geoJSONIntersection?.features[0]
    ?.geometry as GeoJSON.Polygon;

  // Total points in the user drawn polygon
  const totalDrawnPoints =
    drawnGeometry && drawnGeometry.coordinates[0]
      ? drawnGeometry.coordinates[0].length
      : 0;

  // Total points in the intersection polygon
  const intersectingPolygonPointsTotal =
    interceptionGeometry && interceptionGeometry.coordinates[0]
      ? interceptionGeometry.coordinates[0].length
      : 0;

  const showMaxFeaturePointsWarning =
    intersectionPointCount > 7 &&
    hasMaxFeaturePoints(geoJSONIntersection!, maxPoints) &&
    !activeTool &&
    !errors.startGeometry &&
    !isFir(geoJSON!);

  const showMoreThan6PointsWarning =
    totalDrawnPoints > 7 &&
    intersectionPointCount < 1 &&
    !activeTool &&
    !errors.startGeometry &&
    !isFir(geoJSON!);

  const showIntersectionAbove6PointsWarning =
    intersectingPolygonPointsTotal > 7 &&
    intersectionPointCount > 1 &&
    maxPoints > 7 &&
    !activeTool &&
    !errors.startGeometry &&
    !isFir(geoJSON!);

  return !isReadOnly ? (
    <ProductFormFieldLayout
      title={t('start-position')}
      sx={styles.drawSection}
      data-testid="startGeometry"
    >
      <DrawTools
        activeTool={activeTool}
        tools={tools}
        onChangeTool={onChangeTool}
        isDisabled={isShortTest()}
      />
      {activeTool && (
        <FormHelperText variant="filled" sx={styles.quitDrawModeMessage}>
          {t('geometry-exit-draw-mode-message')}
        </FormHelperText>
      )}
      {!!errors.startGeometry && (
        <FormHelperText error variant="filled">
          {errors.startGeometry.message as string}
        </FormHelperText>
      )}
      {
        /* non-blocking warnings */
        showMaxFeaturePointsWarning && (
          <FormHelperText variant="filled">
            {t('geometry-max-feature-points-message')}
          </FormHelperText>
        )
      }
      {
        /* non-blocking warnings */
        showMoreThan6PointsWarning && (
          <FormHelperText variant="filled">
            {t('geometry-more-than-6-points-message', { totalDrawnPoints })}
          </FormHelperText>
        )
      }
      {
        /* non-blocking warnings */
        showIntersectionAbove6PointsWarning && (
          <FormHelperText variant="filled">
            {t('geometry-intersection-above-6-points-message', {
              intersectingPolygonPointsTotal,
            })}
          </FormHelperText>
        )
      }
      {hasMulitpleIntersections(geoJSONIntersection!) &&
        !activeTool &&
        !errors.startGeometry &&
        !isFir(geoJSON!) && (
          <FormHelperText variant="filled">
            {t('geometry-multi-intersections-message')}
          </FormHelperText>
        )}
      <ReactHookFormHiddenInput
        name="startGeometry"
        rules={{
          validate: {
            maximumOneDrawing: (
              value: GeoJSON.FeatureCollection,
            ): boolean | string =>
              isMaximumOneDrawing(value) ||
              t('geometry-only-one-start-position-drawing'),
            intersectWithFIR: (
              value: GeoJSON.FeatureCollection,
            ): boolean | string =>
              hasIntersectionWithFIR(value, watch('startGeometryIntersect')) ||
              t('geometry-no-intersection-message'),
            coordinatesNotEmpty: (
              value: GeoJSON.FeatureCollection,
            ): boolean | string =>
              isDraft() || isShortTest()
                ? true
                : isValidGeoJsonCoordinates(value) ||
                  t('geometry-coodinates-empty-message'),
            hasMaxFeaturePoints: (
              value: GeoJSON.FeatureCollection<Polygon>,
            ): boolean | string =>
              isFir(value)
                ? true
                : !hasMaxFeaturePoints(value, maxPoints) ||
                  t('geometry-maximum-points-message', {
                    maxPoints: maxPoints - 1,
                  }),
          },
        }}
      />
      <ReactHookFormHiddenInput name="startGeometryIntersect" />
    </ProductFormFieldLayout>
  ) : null;
};

export default StartGeometry;

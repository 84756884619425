/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Draft,
} from '@reduxjs/toolkit';
import { compact } from 'lodash';
import { uiTypes, serviceActions } from '@opengeoweb/store';
import {
  LayerSelectRemoveServicePayload,
  LayerSelectStoreType,
  SetSearchFilterPayload,
  ToggleKeywordsPayload,
  Filter,
  ActiveServiceObject,
  ToggleServicePopupPayload,
  ServicePopupObject,
} from './types';
import { produceFilters, getFilterId } from './utils';

export const layerSelectFilterAdapter = createEntityAdapter<Filter>();
export const layerSelectActiveServicesAdapter =
  createEntityAdapter<ActiveServiceObject>({
    selectId: (service) => service.serviceId!,
  });

export const initialServicePopupState: ServicePopupObject = {
  isOpen: false,
  url: '',
  serviceId: '',
  variant: 'add',
};

export const initialState: LayerSelectStoreType = {
  filters: {
    searchFilter: '',
    activeServices: layerSelectActiveServicesAdapter.getInitialState(),
    filters: layerSelectFilterAdapter.getInitialState(),
  },
  allServicesEnabled: true,
  servicePopup: initialServicePopupState,
};

const slice = createSlice({
  initialState,
  name: uiTypes.DialogTypes.LayerSelect,
  reducers: {
    setSearchFilter: (
      draft: Draft<LayerSelectStoreType>,
      action: PayloadAction<SetSearchFilterPayload>,
    ) => {
      const { filterText } = action.payload;
      draft.filters.searchFilter = filterText;
    },

    closeServicePopupOpen: (draft: Draft<LayerSelectStoreType>) => {
      draft.servicePopup.isOpen = false;
    },
    toggleServicePopup: (
      draft: Draft<LayerSelectStoreType>,
      action: PayloadAction<ToggleServicePopupPayload>,
    ) => {
      const { variant, isOpen } = action.payload;
      draft.servicePopup.isOpen = isOpen;
      draft.servicePopup.url = action.payload.url ? action.payload.url : '';
      draft.servicePopup.variant = variant;
      draft.servicePopup.serviceId = action.payload.serviceId
        ? action.payload.serviceId
        : '';
    },
    layerSelectRemoveService: (
      draft: Draft<LayerSelectStoreType>,
      action: PayloadAction<LayerSelectRemoveServicePayload>,
    ) => {
      const { serviceId } = action.payload;

      layerSelectActiveServicesAdapter.removeOne(
        draft.filters.activeServices,
        serviceId,
      );
    },
    enableActiveService: (
      draft: Draft<LayerSelectStoreType>,
      action: PayloadAction<{ serviceId: string }>,
    ) => {
      const activeServicesById = draft.filters.activeServices.entities;
      const activeServices = compact(Object.values(activeServicesById));
      const countPressedServices = activeServices.filter(
        (service) => service.enabled,
      ).length;
      const isAllServicesGoingToBeEnabled =
        countPressedServices === activeServices.length - 1;
      if (isAllServicesGoingToBeEnabled) {
        slice.caseReducers.enableAllActiveServices(draft);
        return;
      }

      const service = activeServicesById[action.payload.serviceId]!;
      service.enabled = true;
    },
    enableAllActiveServices: (draft: Draft<LayerSelectStoreType>) => {
      const activeServices = draft.filters.activeServices.entities;
      const servicesToTurnOn = compact(Object.values(activeServices)).filter(
        (service) => !service.enabled,
      );

      const updates = servicesToTurnOn.map((service) => ({
        id: service.serviceId!,
        changes: { enabled: true },
      }));
      layerSelectActiveServicesAdapter.updateMany(
        draft.filters.activeServices,
        updates,
      );
      draft.allServicesEnabled = true;
    },
    onlyThisServiceEnabled: (
      draft: Draft<LayerSelectStoreType>,
      action: PayloadAction<{ serviceId: string }>,
    ) => {
      const activeServicesById = draft.filters.activeServices.entities;

      const services = compact(Object.values(activeServicesById));
      const servicesToTurnOff = services.filter(
        (service) => service.serviceId !== action.payload.serviceId,
      );

      const updates = servicesToTurnOff.map((service) => ({
        id: service.serviceId!,
        changes: { enabled: false },
      }));
      layerSelectActiveServicesAdapter.updateMany(
        draft.filters.activeServices,
        updates,
      );

      draft.allServicesEnabled = false;
    },
    disableActiveService: (
      draft: Draft<LayerSelectStoreType>,
      action: PayloadAction<{ serviceId: string }>,
    ) => {
      const activeServicesById = draft.filters.activeServices.entities;
      const countPressedServices = Object.values(activeServicesById).filter(
        (service) => service?.enabled,
      ).length;
      const isAllServicesGoingToBeDisabled = countPressedServices === 1;
      if (isAllServicesGoingToBeDisabled) {
        slice.caseReducers.enableAllActiveServices(draft);
        return;
      }

      const service = activeServicesById[action.payload.serviceId]!;
      service.enabled = false;
    },
    toggleFilter: (
      draft: Draft<LayerSelectStoreType>,
      action: PayloadAction<ToggleKeywordsPayload>,
    ) => {
      const { filterId, only } = action.payload;
      if (only) {
        Object.keys(draft.filters.filters.entities).forEach((filterId) => {
          draft.filters.filters.entities[filterId]!.checked = false;
        });
        draft.filters.filters.entities[filterId]!.checked = true;
      } else {
        draft.filters.filters.entities[filterId]!.checked =
          !draft.filters.filters.entities[filterId]!.checked;
      }
    },
    toggleAllFilters: (draft: Draft<LayerSelectStoreType>) => {
      const allFiltersChecked = Object.values(
        draft.filters.filters.entities,
      ).every((filter) => filter?.checked);
      draft.filters.filters.ids.forEach((filterId) => {
        draft.filters.filters.entities[filterId]!.checked = !allFiltersChecked;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(serviceActions.serviceSetLayers, (draft, action) => {
        const {
          id: serviceId,
          serviceUrl,
          name: serviceName,
          scope,
          abstract,
          layers,
          isUpdating,
        } = action.payload;
        if (isUpdating) {
          return;
        }
        const keywords = layers.reduce<string[]>((keywords, layer) => {
          if (layer.leaf) {
            return keywords.concat(layer.keywords ?? []);
          }
          return keywords;
        }, []);

        const groups = layers.reduce<string[]>((groups, layer) => {
          if (layer.leaf) {
            return groups.concat(layer.path ?? []);
          }
          return groups;
        }, []);

        if (
          !draft.filters.activeServices.entities[serviceId]?.filterIds?.length
        ) {
          // If the service has no filters yet, add them
          produceFilters(groups, 'groups', draft, layerSelectFilterAdapter);
          produceFilters(keywords, 'keywords', draft, layerSelectFilterAdapter);
        }

        const filterIds = groups
          .map((group) => getFilterId('groups', group))
          .concat(keywords.map((keyword) => getFilterId('keywords', keyword)));

        layerSelectActiveServicesAdapter.setOne(draft.filters.activeServices, {
          serviceId,
          enabled: draft.allServicesEnabled,
          keywords,
          groups,
          filterIds,
          scope,
          serviceName,
          serviceUrl,
          abstract,
          isLoading: false,
        });
      })
      .addCase(serviceActions.fetchInitialServices, (draft, action) => {
        const { services } = action.payload;

        services.forEach((service) => {
          const {
            id: serviceId,
            name: serviceName,
            url: serviceUrl,
            abstract,
            scope,
          } = service;

          layerSelectActiveServicesAdapter.setOne(
            draft.filters.activeServices,
            {
              serviceId,
              enabled: draft.allServicesEnabled,
              filterIds: [],
              scope,
              serviceName,
              serviceUrl,
              abstract,
              isLoading: true,
            },
          );
        });
      });
  },
});

export const { reducer: layerSelectReducer, actions: layerSelectActions } =
  slice;

export type LayerSelectActions =
  | ReturnType<typeof layerSelectActions.setSearchFilter>
  | ReturnType<typeof layerSelectActions.layerSelectRemoveService>
  | ReturnType<typeof layerSelectActions.enableActiveService>
  | ReturnType<typeof layerSelectActions.disableActiveService>
  | ReturnType<typeof layerSelectActions.toggleFilter>
  | ReturnType<typeof layerSelectActions.toggleAllFilters>;

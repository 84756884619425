/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box } from '@mui/material';
import { CustomTooltip } from '@opengeoweb/shared';
import React, { FC } from 'react';
import { useCoreTranslation } from '../../../../../utils/i18n';

export const GREEN = {
  backgroundColor: '#03930D',
  borderColor: '#00BF0D',
};
export const RED = {
  backgroundColor: '#FF0000',
  borderColor: '#FF6B6B',
};
export const ORANGE = {
  backgroundColor: '#FFA346',
  borderColor: '#FFCF9F',
};

export const MissingData: FC<{
  layerIsInsideAcceptanceTime: 'equal' | 'inside' | 'outside';
}> = ({ layerIsInsideAcceptanceTime }) => {
  const { t } = useCoreTranslation();
  const getTextAndColor = (
    layerIsInsideAcceptanceTime: string,
  ): {
    tooltipText: string;
    color: {
      backgroundColor: string;
      borderColor: string;
    };
  } => {
    if (layerIsInsideAcceptanceTime === 'inside') {
      return {
        tooltipText: t('layermanager-acceptance-time-within'),
        color: ORANGE,
      };
    }
    if (layerIsInsideAcceptanceTime === 'outside') {
      return {
        color: RED,
        tooltipText: t('layermanager-acceptance-time-outside'),
      };
    }
    return {
      color: GREEN,
      tooltipText: t('layermanager-acceptance-time-updated'),
    };
  };
  const { tooltipText, color } = getTextAndColor(layerIsInsideAcceptanceTime);
  return (
    <CustomTooltip title={tooltipText} placement="top">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          data-testid="MissingDataColor"
          sx={{
            width: '4px',
            height: '24px',
            backgroundColor: color.backgroundColor,
            border: `solid 1px ${color.borderColor}`,
            flexShrink: 0,
          }}
        />
      </Box>
    </CustomTooltip>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { List } from '@opengeoweb/theme';
import { MapControlButton } from '../MapControls';
import { useWebmapReactTranslation } from '../../utils/i18n';

export const LegendButton: React.FC<{
  openLegendDialog: () => void;
  isOpen: boolean;
}> = ({ openLegendDialog, isOpen }) => {
  const { t } = useWebmapReactTranslation();
  const onClickButton = (): void => openLegendDialog();

  return (
    <MapControlButton
      title={t('webmap-react-legend')}
      data-testid="open-Legend"
      onClick={onClickButton}
      isActive={isOpen}
    >
      <List />
    </MapControlButton>
  );
};

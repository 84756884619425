/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CoreAppStore,
  getUserAddedServices,
  serviceActions,
  serviceSelectors,
  serviceTypes,
  uiSelectors,
} from '@opengeoweb/store';
import { getWMSServiceId } from '@opengeoweb/webmap';
import { preloadedDefaultMapServices } from '../../utils/defaultConfigurations';

export const getServicesToLoad = async (
  services: serviceTypes.NoIdService[],
): Promise<serviceTypes.InitialService[]> =>
  Promise.all(
    services.map(async (service) => {
      const scope = service.scope ? service.scope : 'system';
      return {
        id: getWMSServiceId(service.url),
        name: service.name,
        url: service.url,
        scope,
        abstract: service.abstract,
      };
    }),
  );

export const useFetchServices = (
  dialogType: string,
  preloadedServices = preloadedDefaultMapServices,
  shouldDisableFetch = false,
): void => {
  const dispatch = useDispatch();

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, dialogType),
  );

  const services = useSelector((store: CoreAppStore) =>
    serviceSelectors.getServiceIds(store),
  );

  const fetchInitialServices = React.useCallback(
    (services: serviceTypes.InitialService[]): void => {
      dispatch(serviceActions.fetchInitialServices({ services }));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (
      !shouldDisableFetch &&
      isOpenInStore &&
      preloadedServices &&
      preloadedServices.length > 0 &&
      !services.length
    ) {
      const allServicesToLoad = mergePresetsAndUserAddedServices(
        preloadedServices,
        getUserAddedServices(),
      );
      void getServicesToLoad(allServicesToLoad).then(fetchInitialServices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenInStore, services]);
};

export const mergePresetsAndUserAddedServices = (
  presets: serviceTypes.InitialService[],
  userAddedServices: serviceTypes.UserAddedServices,
): serviceTypes.NoIdService[] =>
  Object.values({
    ...(presets as serviceTypes.NoIdService[]).reduce(
      (byUrl, preset) => ({ ...byUrl, [preset.url]: preset }),
      {} as Record<string, serviceTypes.NoIdService>,
    ),
    ...userAddedServices,
  });

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { DraggableEvent } from 'react-draggable';
import { Box, SxProps } from '@mui/material';
import { NumberSize, ResizeDirection } from 're-resizable';
import Draggable from '../DraggableResizable/Draggable';
import Resizable from '../DraggableResizable/Resizable';
import {
  DraggableSize,
  DraggablePosition,
  Position,
  ResizableComponentProps,
  Size,
} from '../DraggableResizable/types';
import ToolContainer, { BASE_ELEVATION } from './ToolContainer';
import { HeaderSize } from './types';
import { useHandleSizeFromOutsideComponent } from './useHandleSizeFromOutsideComponent';

// calculate dialog size and position to 85% of screensize
export const calculateDialogSizeAndPosition = (): {
  width: number;
  height: number;
  top: number;
  left: number;
} => {
  const width = window.innerWidth * 0.85;
  const height = window.innerHeight * 0.85;
  const top = (window.innerHeight * 0.15) / 2;
  const left = (window.innerWidth * 0.15) / 2;
  return { width, height, top, left };
};

export const calculateStartSize = (
  minSize: DraggableSize,
  prefSize: DraggableSize,
  startPosition: Position,
): DraggableSize => {
  const { width: calcWidth, height: calcHeight } =
    calculateDialogSizeAndPosition();
  return {
    width: Math.max(minSize.width, Math.min(prefSize.width, calcWidth)),
    height: Math.max(
      minSize.height,
      Math.min(prefSize.height, calcHeight - startPosition.top),
    ),
  };
};

interface DraggableComponentProps
  extends Omit<ResizableComponentProps, 'bounds'> {
  startPosition?: Position;
  onStartResize?: () => void;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  headerSize?: HeaderSize;
  initialMaxHeight?: string | number;
  onChangeSize?: (size: Size) => void;
  onDragEnd?: (position: DraggablePosition, size: Size) => void;
  onMouseDown?: (event: MouseEvent) => void;
  bounds?: 'parent' | string;
  cancel?: string;
  order?: number;
  source?: Source;
  leftHeaderComponent?: React.ReactNode;
  rightHeaderComponent?: React.ReactNode;
  dragHandleIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  sx?: SxProps;
}

const BASE_Z_INDEX = 100;

export const calculateNewPosition = (
  position: DraggablePosition,
  isRightAligned: boolean,
  handle: string,
  delta: NumberSize,
): DraggablePosition => {
  let { x, y } = position;
  // If dragging the same side as alignment, adjust position
  if (isRightAligned && handle === 'bottomRight') {
    x = position.x + delta.width;
  } else if (!isRightAligned && handle === 'bottomLeft') {
    x = position.x - delta.width;
  } else if (isRightAligned && handle === 'topRight') {
    x = position.x + delta.width;
    y = position.y - delta.height;
  } else if (isRightAligned && handle === 'topLeft') {
    y = position.y - delta.height;
  } else if (!isRightAligned && handle === 'topRight') {
    y = position.y - delta.height;
  } else if (!isRightAligned && handle === 'topLeft') {
    x = position.x - delta.width;
    y = position.y - delta.height;
  }
  return { x, y };
};

export type Source = 'app' | 'module'; // source of opening

const DraggableComponent: React.FC<DraggableComponentProps> = ({
  title,
  bounds = 'body',
  cancel = '.MuiButtonBase-root',
  headerSize = 'xs',
  startSize = { width: 'auto', height: 'auto' },
  startPosition = { left: undefined, right: undefined, top: undefined! },
  children,
  isOpen = true,
  onClose = (): void => {},
  initialMaxHeight: defaultInitialMaxHeight = 'initial',
  onChangeSize = (): void => {},
  onMouseDown = (): void => {},
  onResizeStop = (): void => {},
  onDragEnd = (): void => {},
  order = 1,
  source = 'app',
  leftHeaderComponent = null,
  rightHeaderComponent = null,
  dragHandleIcon,
  closeIcon,
  className,
  sx = {},
  ...props
}: DraggableComponentProps) => {
  const zIndex = (source === 'app' ? BASE_Z_INDEX : 1000) + order;
  const elevation = BASE_ELEVATION + order;
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = React.useState<DraggablePosition>({
    x: 0,
    y: 0,
  });

  const [size, setSize] = React.useState(startSize);
  const [isDragging, setIsDragging] = React.useState(false);

  const isRightAligned =
    startPosition &&
    startPosition.right !== null &&
    startPosition.right !== undefined;
  const onStopDrag = React.useCallback(
    (_event: DraggableEvent, position: DraggablePosition): void => {
      setPosition(position);
      onDragEnd(position, size);
    },
    [setPosition, onDragEnd, size],
  );

  const [initialMaxHeight, setInitialMaxHeight] = React.useState<
    string | number
  >(defaultInitialMaxHeight);

  const onStartResize = (): void => {
    setIsDragging(true);
    setInitialMaxHeight('initial');
  };

  const onResize = React.useCallback(
    (
      _event: MouseEvent | TouchEvent,
      handle: string,
      node: HTMLElement,
      delta: NumberSize,
    ) => {
      const { width, height } = node.getBoundingClientRect();
      onChangeSize({ width, height } as Size);
      const newPos = calculateNewPosition(
        position,
        isRightAligned,
        handle,
        delta,
      );
      // eslint-disable-next-line no-param-reassign
      const parent = node.parentNode! as HTMLElement;
      parent.style.transform = `translate(${newPos.x}px, ${newPos.y}px)`;
    },
    [onChangeSize, position, isRightAligned],
  );

  const onStopResize = (
    _event: MouseEvent | TouchEvent,
    direction: ResizeDirection,
    node: HTMLElement,
    delta: NumberSize,
  ): void => {
    setIsDragging(false);
    setPosition(
      calculateNewPosition(position, isRightAligned, direction, delta),
    );
    onResizeStop(_event, direction, node, delta);
  };

  const toolcontainerId = React.useRef(Date.now().toString()).current;
  const headerClassName = `header-${className || toolcontainerId}`;

  useHandleSizeFromOutsideComponent({
    nodeRef,
    bounds,
    position,
    isRightAligned,
    isOpen,
    startSize,
    onChangeSize: (size) => {
      setSize(size);
    },
  });

  return isOpen ? (
    <>
      {isDragging && (
        <Box
          data-testid="dragging-overlay"
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            zIndex: 999,
          }}
        />
      )}
      <Draggable
        nodeRef={nodeRef}
        bounds={bounds}
        cancel={cancel}
        position={position}
        onStop={onStopDrag}
        handle={`.${headerClassName}`}
        onMouseDown={onMouseDown}
        setIsDragging={setIsDragging}
      >
        <Box
          tabIndex={-1}
          sx={{
            position: 'absolute',
            pointerEvents: 'all',
            zIndex,
            ...startPosition,
            ...sx,
            '&:hover .resizableHandle': {
              display: 'inline',
            },
          }}
          ref={nodeRef}
          className="toolContainerDraggable"
          role="dialog"
          aria-label={title}
        >
          <Resizable
            {...props}
            size={size}
            setSize={setSize}
            onResizeStart={onStartResize}
            onResizeStop={onStopResize}
            onResize={onResize}
            nodeRef={nodeRef}
          >
            <ToolContainer
              maxHeight={initialMaxHeight}
              onClose={onClose}
              title={title}
              size={headerSize}
              isDraggable
              isResizable
              elevation={elevation}
              headerClassName={headerClassName}
              leftHeaderComponent={leftHeaderComponent}
              rightHeaderComponent={rightHeaderComponent}
              dragHandleIcon={dragHandleIcon}
              closeIcon={closeIcon}
              className={className}
            >
              {children}
            </ToolContainer>
          </Resizable>
        </Box>
      </Draggable>
    </>
  ) : null;
};

export default DraggableComponent;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CoreAppStore,
  uiActions,
  uiSelectors,
  uiTypes,
} from '@opengeoweb/store';
import { Layers } from '@opengeoweb/theme';
import { CustomTooltip } from '@opengeoweb/shared';
import { DIALOG_TYPE_MANAGER } from './TimeSeriesManagerConnect';
import { TimeSeriesTopRowButton } from '../TimeSeries/TimeSeriesTopRowButton';
import { useTimeseriesTranslation } from '../../utils/i18n';

export const TOOLTIP_TITLE = 'Timeseries Manager';

interface TimeSeriesManagerMapButtonConnectProps {
  viewId: string;
  source?: uiTypes.Source;
}

const TimeSeriesManagerMapButtonConnect: React.FC<
  TimeSeriesManagerMapButtonConnectProps
> = ({ viewId, source = 'app' }: TimeSeriesManagerMapButtonConnectProps) => {
  const dispatch = useDispatch();
  const { t } = useTimeseriesTranslation();

  const currentActiveMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, DIALOG_TYPE_MANAGER),
  );

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, DIALOG_TYPE_MANAGER),
  );

  const openTimeSeriesManagerDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: DIALOG_TYPE_MANAGER,
        mapId: viewId,
        setOpen: currentActiveMapId !== viewId ? true : !isOpenInStore,
        source,
      }),
    );
  }, [currentActiveMapId, dispatch, isOpenInStore, viewId, source]);

  const isOpen = currentActiveMapId === viewId && isOpenInStore;

  return (
    <CustomTooltip title={TOOLTIP_TITLE}>
      <TimeSeriesTopRowButton
        data-testid="timeSeriesManagerButton"
        onClick={openTimeSeriesManagerDialog}
        selected={isOpen}
        icon={<Layers />}
        longText={t('timeseries-ts-manager')}
        aria-label={t('timeseries-manager')}
      />
    </CustomTooltip>
  );
};

export default TimeSeriesManagerMapButtonConnect;

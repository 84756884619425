/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { ToggleMenu } from '@opengeoweb/shared';
import { Add, Delete, Close } from '@opengeoweb/theme';
import { useTafTranslation } from '../../../utils/i18n';

interface TafFormOptionsButtonProps {
  isChangeGroup: boolean;
  addChangeGroupBelow: () => void;
  addChangeGroupAbove: () => void;
  removeChangeGroup: () => void;
  clearRow: () => void;
  index: number;
}

const TafFormOptionsButton: React.FC<TafFormOptionsButtonProps> = ({
  isChangeGroup,
  addChangeGroupBelow,
  addChangeGroupAbove,
  removeChangeGroup,
  clearRow,
  index,
}: TafFormOptionsButtonProps) => {
  const { t } = useTafTranslation();
  const insertAbove = {
    text: t('options-insert-above'),
    icon: <Add />,
    action: addChangeGroupAbove,
  };
  const insertBelow = {
    text: t('options-insert-below'),
    icon: <Add />,
    action: addChangeGroupBelow,
  };
  const deleteRow = {
    text: t('options-delete'),
    icon: <Delete />,
    action: removeChangeGroup,
  };
  const clear = {
    text: t('options-clear'),
    icon: <Close />,
    action: clearRow,
  };

  const menuItemsBaseForecast = [insertBelow, clear];
  const menuItemsChangeGroup = [insertAbove, insertBelow, deleteRow, clear];

  return (
    <ToggleMenu
      buttonTestId={`tafFormOptions[${index}]`}
      buttonSx={{
        marginTop: '10px',
        marginLeft: '4px',
      }}
      menuItems={isChangeGroup ? menuItemsChangeGroup : menuItemsBaseForecast}
    />
  );
};

export default TafFormOptionsButton;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createApiInstance,
  CreateApiProps,
  createNonAuthApiInstance,
} from '@opengeoweb/api';
import { InitialAppPreset } from '@opengeoweb/core';
import { ConfigType } from '@opengeoweb/shared';
import { AxiosInstance } from 'axios';
import defaultInitialPresets from '../../assets/initialPresets.json';

export interface AppApi {
  getInitialPresets: (
    initialPresetsFilename: string,
  ) => Promise<{ data: InitialAppPreset }>;
  getBeVersion: (config: ConfigType) => Promise<{ data: { version: string } }>;
}
const getApiRoutes = (
  axiosInstance: AxiosInstance,
  nonAuthAxiosInstance: AxiosInstance,
): AppApi => ({
  getInitialPresets: (
    initialPresetsFilename: string,
  ): Promise<{ data: InitialAppPreset }> => {
    return nonAuthAxiosInstance.get(`./assets/${initialPresetsFilename}`);
  },
  getBeVersion: (
    config: ConfigType,
  ): Promise<{ data: { version: string } }> => {
    if (config.GW_FEATURE_MENU_VERSION) {
      return axiosInstance.get('/version');
    }
    return Promise.resolve({ data: { version: undefined! } });
  },
});

export const createApi = (props: CreateApiProps): AppApi => {
  const axiosInstance = createApiInstance(props);
  const nonAuthAxiosInstance = createNonAuthApiInstance({});
  return getApiRoutes(axiosInstance, nonAuthAxiosInstance);
};

// only used for testing
export const createFakeApi = (): AppApi => {
  const api: AppApi = {
    // dummy calls
    getInitialPresets: (): Promise<{ data: InitialAppPreset }> =>
      Promise.resolve({
        data: defaultInitialPresets as InitialAppPreset,
      }),
    getBeVersion: (): Promise<{ data: { version: string } }> =>
      Promise.resolve({ data: { version: 'version 1.0.1' } }),
  };

  return api;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  snackbarReducer,
  snackbarListener,
  SnackbarModuleStore,
} from '@opengeoweb/snackbar';
import {
  mapReducer,
  serviceReducer,
  layerReducer,
  syncGroupsReducer,
  uiReducer,
  mapListener,
  serviceListener,
  layersListener,
  syncGroupsListener,
  genericListener,
  WebMapStateModuleState,
  SynchronizationGroupModuleState,
  UIModuleState,
} from '@opengeoweb/store';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { timeseriesListener } from './listeners';
import { timeSeriesReducer } from './reducer';
import { TimeSeriesModuleState } from './types';

export const timeSeriesReducersMap = {
  webmap: mapReducer,
  services: serviceReducer,
  layers: layerReducer,
  syncGroups: syncGroupsReducer,
  ui: uiReducer,
  timeSeries: timeSeriesReducer,
  snackbar: snackbarReducer,
};

export const timeSeriesMiddlewares = [
  mapListener.middleware,
  serviceListener.middleware,
  layersListener.middleware,
  syncGroupsListener.middleware,
  genericListener.middleware,
  timeseriesListener.middleware,
  snackbarListener.middleware,
];

const timeSeriesRootReducer = combineReducers(timeSeriesReducersMap);

export const createMockStore = (
  mockState?:
    | WebMapStateModuleState
    | SynchronizationGroupModuleState
    | UIModuleState
    | TimeSeriesModuleState
    | SnackbarModuleStore,
): ToolkitStore =>
  configureStore({
    reducer: timeSeriesRootReducer,
    preloadedState: mockState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(timeSeriesMiddlewares),
  });

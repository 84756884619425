/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import i18n from 'i18next';
import { useSelector } from 'react-redux';
import * as selectors from '../../../store/selectors';
import { CapModule } from '../../../store/types';
import { findEventIndexInLanguage } from '../../../utils/findEventIndexInLanguage';
import { CapPresets } from '../../types';
import { WarningListRowCap } from './WarningListRowCap';

interface WarningListRowCapConnectProps {
  alertId: string;
  capWarningPresets: CapPresets;
}

export const WarningListRowCapConnect: React.FC<
  WarningListRowCapConnectProps
> = ({ alertId, capWarningPresets }) => {
  const [languageIndex, setLanguageIndex] = React.useState(0);

  const alert = useSelector((store: CapModule) =>
    selectors.getAlertById(store, alertId),
  );
  const alertHasLanguages = Boolean(alert?.languages?.length);
  const { language } = i18n;

  React.useEffect(() => {
    if (alert && alertHasLanguages) {
      const foundLanguageIndex = findEventIndexInLanguage(
        language,
        alert.languages,
        capWarningPresets,
        alert.feedAddress,
      );
      setLanguageIndex(foundLanguageIndex);
    }
  }, [alert, language, alertHasLanguages, capWarningPresets]);

  return (
    <div>
      {alert && alertHasLanguages && (
        <WarningListRowCap
          event={alert.languages[languageIndex].event}
          severity={alert.severity}
          expires={alert.expires}
        />
      )}
    </div>
  );
};

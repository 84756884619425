/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { DraggablePosition, ToolContainerDraggable } from '@opengeoweb/shared';
import ReactDOM from 'react-dom';
import { ThumbsUp } from '@opengeoweb/theme';
import { TFunction } from 'i18next';

import { IssuesPaneProps, IssuesPanePosition, CustomErrorField } from './types';
import { useTafTranslation } from '../../utils/i18n';

const getPaneTitle = (sumOfErrors: number, t: TFunction): string => {
  switch (sumOfErrors) {
    case 0:
      return t('issues-pane-none');
    case 1:
      return t('issues-pane-one');
    default:
      return t('issues-pane-multiple');
  }
};

export const humanizeErrorMessage = (
  errorTitle: string,
  fieldNumberedValue: number,
): string => {
  if (fieldNumberedValue !== undefined && fieldNumberedValue >= 0) {
    // when name contains a number we assume it's a formgroup and we add a index +1 for human readability
    const fieldNumber = fieldNumberedValue + 1;
    return `${errorTitle} ${fieldNumber}`;
  }

  return errorTitle;
};

export const getErrorMessage = (
  error: CustomErrorField,
  index: number,
): string => {
  const errorTitleCategory = error.isChangeGroup
    ? 'Changegroups'
    : 'Baseforecast';

  const fieldErrorTitle = humanizeErrorMessage(
    errorTitleCategory,
    error.rowIndex,
  );

  return `${index + 1}. ${fieldErrorTitle}: ${error.message}`;
};

export const MODAL_DIALOG_ELEMENT = 'modal-dialog';

export const useIssuesPane = (): {
  isIssuesPaneOpen: boolean;
  startPosition: IssuesPanePosition;
  onToggleIssuesPane: (
    isOpen: boolean,
    newPosition: IssuesPanePosition,
  ) => void;
  onCloseIssuesPane: () => void;
  onUserHasDragged: () => void;
} => {
  const [isIssuesPaneOpen, setIsIssuesPaneOpen] =
    React.useState<boolean>(false);
  const [startPosition, setStartPosition] = React.useState<IssuesPanePosition>(
    null!,
  );
  const [hasUserDragged, setHasUserDragged] = React.useState<boolean>(false);

  const onUserHasDragged = (): void => setHasUserDragged(true);

  const onToggleIssuesPane = (
    isOpen: boolean,
    newPosition: IssuesPanePosition,
  ): void => {
    if (!hasUserDragged) {
      setStartPosition(newPosition);
    }
    setIsIssuesPaneOpen(isOpen);
  };
  const onCloseIssuesPane = (): void => setIsIssuesPaneOpen(false);

  return {
    isIssuesPaneOpen,
    startPosition,
    onToggleIssuesPane,
    onCloseIssuesPane,
    onUserHasDragged,
  };
};

const IssuesPane: React.FC<IssuesPaneProps> = ({
  errorList = [],
  isOpen,
  handleClose,
  startPosition = {
    left: 20,
    top: 50,
  },
  onUserHasDragged = (): void => {},
}: IssuesPaneProps) => {
  const { t } = useTafTranslation();
  const rootElement =
    document.getElementById(MODAL_DIALOG_ELEMENT) || document.body;

  return ReactDOM.createPortal(
    <ToolContainerDraggable
      startSize={{ width: '577px', height: '200px' }}
      startPosition={startPosition}
      minWidth={300}
      onClose={handleClose}
      title={getPaneTitle(errorList.length, t)}
      isOpen={isOpen}
      data-testid="moveable-issues-pane"
      bounds="parent"
      cancel=""
      onDragEnd={(event: DraggablePosition): void => {
        if (event.x !== 0 && event.y !== 0) {
          onUserHasDragged();
        }
      }}
    >
      <Box
        component="div"
        sx={{
          marginTop: '16px',
          marginLeft: '24px',
        }}
      >
        {errorList.length === 0 && (
          <Box
            component="div"
            sx={{ fontSize: '4rem' }}
            data-testid="zeroIssuesImage"
          >
            <ThumbsUp sx={{ height: '69px', width: '58px' }} />
          </Box>
        )}
        {errorList.length > 0 &&
          errorList.map((error, index) => (
            <Typography
              // eslint-disable-next-line react/no-array-index-key
              key={`message-${index}`}
              variant="body1"
              data-testid="errorMessage"
            >
              {getErrorMessage(error, index)}
            </Typography>
          ))}
      </Box>
    </ToolContainerDraggable>,
    rootElement,
  );
};

export default IssuesPane;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Position,
  DraggableSize,
  HeaderSize,
  ToolContainerDraggable,
  calculateStartSize,
  SwitchButton,
} from '@opengeoweb/shared';
import { AviationProduct } from '@opengeoweb/sigmet-airmet';
import { uiTypes } from '@opengeoweb/store';
import { Box, LinearProgress, Typography } from '@mui/material';
import WarningAvatar from './WarningsAvatar';
import { useWarningsTranslation } from '../../utils/i18n';
import { WarningType } from '../../store/publicWarningForm/types';

const DEFAULT_WARNING_DIALOG_SIZE = { width: 470, height: 800 };
const DEFAULT_WARNING_DIALOG_POSITION = { top: 8, left: 60 };
const DEFAULT_WARNING_DIALOG_MIN_SIZE = { width: 264, height: 300 };

export const PublicWarningsFormDialog: React.FC<{
  bounds?: string;
  title?: string;
  onClose: () => void;
  onMouseDown?: () => void;
  isOpen: boolean;
  order?: number;
  source?: uiTypes.Source;
  size?: DraggableSize;
  startPosition?: Position;
  headerSize?: HeaderSize;
  children: React.ReactNode;
  isLoading?: boolean;
  isReadOnly?: boolean;
  onChangeFormMode?: (isReadOnly: boolean) => void;
  shouldHideFormMode?: boolean;
  publicWarningEditor?: string;
  selectedAviationProduct?: AviationProduct;
  warningType?: WarningType;
}> = ({
  bounds,
  onClose,
  title,
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'app',
  size = DEFAULT_WARNING_DIALOG_SIZE,
  startPosition = DEFAULT_WARNING_DIALOG_POSITION,
  headerSize = 'xs',
  children,
  isLoading = false,
  isReadOnly = false,
  onChangeFormMode = (): void => {},
  shouldHideFormMode = false,
  publicWarningEditor,
  selectedAviationProduct,
  warningType = 'public',
}) => {
  const { t } = useWarningsTranslation();

  const minSize = selectedAviationProduct
    ? { width: 470, height: 300 }
    : DEFAULT_WARNING_DIALOG_MIN_SIZE;
  const startSizeCalc = calculateStartSize(minSize, size, startPosition);

  const [sizeInState, setSizeInState] =
    React.useState<DraggableSize>(startSizeCalc);

  const fullTitle = selectedAviationProduct
    ? warningType?.toUpperCase()
    : title || t('public-warning-title');

  const onChangeMode = (): void => {
    onChangeFormMode(!isReadOnly);
  };

  return (
    <ToolContainerDraggable
      title={shouldHideFormMode ? fullTitle : undefined}
      {...(!shouldHideFormMode && {
        leftHeaderComponent: (
          <Box className="header-title">
            <Typography
              variant="h2"
              noWrap
              sx={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: '22px',
                marginTop: '-1px',
                paddingLeft: 1,
              }}
              role="heading"
            >
              {fullTitle}
            </Typography>
          </Box>
        ),
      })}
      startSize={sizeInState}
      minWidth={minSize.width}
      minHeight={minSize.height}
      startPosition={startPosition}
      isOpen={isOpen}
      onClose={onClose}
      headerSize={headerSize}
      bounds={bounds}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
      onResizeStop={(_event, _direction, node): void => {
        const { offsetWidth: width, offsetHeight: height } = node;
        setSizeInState({ width, height });
      }}
      onDragEnd={(_position, dragSize): void => {
        if (dragSize !== sizeInState) {
          setSizeInState(dragSize as DraggableSize);
        }
      }}
      dragHandleIcon={null}
      {...(!shouldHideFormMode && {
        rightHeaderComponent: (
          <Box
            sx={{
              flex: 1,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              right: 32,
              backgroundColor: 'geowebColors.background.surface',
              height: 24,
              paddingLeft: 1,
              paddingRight: 0.5,
            }}
          >
            <Box sx={{ position: 'relative', top: -7 }}>
              <SwitchButton
                checked={!isReadOnly}
                onChange={onChangeMode}
                activeLabel={t('warning-editor-user-editor')}
                inActiveLabel={t('warning-editor-user-viewer')}
              />
            </Box>
            <Box
              sx={{
                position: 'relative',
                top: 3,
                right: '-2px',
                width: '16px',
              }}
            >
              {publicWarningEditor && (
                <WarningAvatar editor={publicWarningEditor} />
              )}
            </Box>
          </Box>
        ),
      })}
      sx={{
        footer: {
          backgroundColor: 'inherit',
          boxShadow: 'none',
        },
      }}
    >
      {isLoading && (
        <LinearProgress
          data-testid="loading-bar"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 0, zIndex: 1 }}
        />
      )}

      {children}
    </ToolContainerDraggable>
  );
};

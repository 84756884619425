/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CustomIconProps } from '@opengeoweb/shared';
import {
  CoreAppStore,
  uiActions,
  uiSelectors,
  uiTypes,
} from '@opengeoweb/store';
import { Search } from '@opengeoweb/theme';
import { MapControlButton } from '@opengeoweb/webmap-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCoreTranslation } from '../../utils/i18n';

export interface SearchControlButtonConnectProps extends CustomIconProps {
  mapId: string;
  source?: uiTypes.Source;
}

export const SearchControlButtonConnect: React.FC<
  SearchControlButtonConnectProps
> = ({ mapId, source = 'app' }: SearchControlButtonConnectProps) => {
  const { t } = useCoreTranslation();
  const dispatch = useDispatch();

  const dialogType = `${uiTypes.DialogTypes.Search}-${mapId}`;

  const currentActiveMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, dialogType),
  );

  const openSearchDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: dialogType,
        mapId,
        setOpen: currentActiveMapId !== mapId ? true : !isOpenInStore,
        source,
      }),
    );
  }, [currentActiveMapId, dialogType, dispatch, isOpenInStore, mapId, source]);

  return (
    <MapControlButton
      onClick={openSearchDialog}
      title={t('search-title')}
      aria-label={t('search-title-aria')}
      isActive={isOpenInStore}
    >
      <Search />
    </MapControlButton>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  ProductCanbe,
  FormMode,
  SigmetFromBackend,
  ProductType,
  AirmetFromBackend,
  isInstanceOfCancelSigmetOrAirmet,
  AviationProduct,
  ProductConfig,
  ProductFromBackend,
} from '../../types';
import { ProductFormDialogContent } from './ProductFormDialogContent';
import { getProductFormMode } from './utils';
import { getDefaultValidFromValue } from './ProductFormFields/ValidFrom';
import { getDefaultValidUntilValue } from './ProductFormFields/ValidUntil';

export const prepareProductValues = (
  product: AviationProduct,
  productType: ProductType,
  productConfig: ProductConfig,
): AviationProduct => {
  // Ensure there is a valid start/end date
  if (
    !isInstanceOfCancelSigmetOrAirmet(product) &&
    (!product.validDateStart || !product.validDateEnd)
  ) {
    return {
      ...product,
      validDateStart: getDefaultValidFromValue(productConfig),
      validDateEnd: getDefaultValidUntilValue(productConfig),
    };
  }

  return product;
};

export interface ProductFormDialogProps {
  isOpen: boolean;
  productType: ProductType;
  productConfig: ProductConfig;
  toggleDialogStatus: (shouldRefreshList?: boolean) => void;
  handleRenewProductClick: (oldProduct: ProductFromBackend) => void;
  productListItem?: ProductFromBackend;
}

export const ProductFormDialog: React.FC<ProductFormDialogProps> = ({
  isOpen,
  productType,
  toggleDialogStatus,
  handleRenewProductClick,
  productListItem = null!,
  productConfig,
}: ProductFormDialogProps) => {
  // Show the right options for a new sigmet/airmet not coming from the BE
  const productCanBe =
    productListItem !== null
      ? productListItem.canbe
      : (['DRAFTED', 'DISCARDED', 'PUBLISHED'] as ProductCanbe[]);

  // Determine mode of form based on canbe properties
  const mode = getProductFormMode(productCanBe, productListItem) as FormMode;

  if (!isOpen) {
    return null;
  }
  const product =
    productListItem !== null
      ? prepareProductValues(
          productType === 'sigmet'
            ? (productListItem as SigmetFromBackend)[productType]
            : (productListItem as AirmetFromBackend)[productType],

          productType,
          productConfig,
        )
      : null!;
  return (
    <ProductFormDialogContent
      isOpen={isOpen}
      toggleDialogStatus={toggleDialogStatus}
      onRenewClick={() => handleRenewProductClick(productListItem)}
      mode={mode}
      productCanBe={productCanBe}
      product={product}
      productType={productType}
      productConfig={productConfig}
    />
  );
};

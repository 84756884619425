/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions, uiSelectors } from '@opengeoweb/store';

import { MosaicNode } from 'react-mosaic-component';
import { WorkspaceLookupFunctionType } from '../../store/workspace/types';
import * as workspaceSelectors from '../../store/workspace/selectors';
import { workspaceActions } from '../../store/workspace/reducer';
import WorkspaceView from './WorkspaceView';
import { AppStore } from '../../store/types';
import {
  closeTimeSeriesDialogsWhenChangingPreset,
  createBalancedMosaicNode,
} from './helpers';

export interface WorkspaceViewConnectProps {
  componentsLookUp: WorkspaceLookupFunctionType;
}

const WorkspaceViewConnect: React.FC<WorkspaceViewConnectProps> = ({
  componentsLookUp,
}: WorkspaceViewConnectProps) => {
  const dispatch = useDispatch();

  const isWorkspaceLoading = useSelector((store: AppStore) =>
    workspaceSelectors.isWorkspaceLoading(store),
  );

  const screenConfig = useSelector((store: AppStore) =>
    workspaceSelectors.getWorkspaceState(store),
  );

  const updateViews = useCallback(
    (currentNode: MosaicNode<string>): void => {
      dispatch(
        workspaceActions.updateWorkspaceViews({
          mosaicNode: currentNode,
        }),
      );
    },
    [dispatch],
  );

  const setActiveWindowId = useCallback(
    (activeWindowId: string): void => {
      dispatch(uiActions.setActiveWindowId({ activeWindowId }));
    },
    [dispatch],
  );

  const activeWindowId = useSelector(uiSelectors.getActiveWindowId);

  const mosaicNode = useSelector((store: AppStore) =>
    workspaceSelectors.getMosaicNode(store),
  );

  const autoArrange = useCallback((): void => {
    dispatch(
      workspaceActions.updateWorkspaceViews({
        mosaicNode: createBalancedMosaicNode(mosaicNode),
      }),
    );
  }, [dispatch, mosaicNode]);

  const handleOrientationChange = React.useCallback(() => {
    autoArrange();
  }, [autoArrange]);

  const componentTypes = useSelector((store: AppStore) =>
    workspaceSelectors.getComponentTypesFromActiveViews(store),
  );

  React.useEffect(() => {
    closeTimeSeriesDialogsWhenChangingPreset(componentTypes, dispatch);
  }, [componentTypes, dispatch]);

  React.useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange);
    return (): void =>
      window.removeEventListener('orientationchange', handleOrientationChange);
  }, [handleOrientationChange]);

  return (
    <WorkspaceView
      screenConfig={screenConfig}
      componentsLookUp={componentsLookUp}
      updateViews={updateViews}
      activeWindowId={activeWindowId}
      setActiveWindowId={setActiveWindowId}
      isWorkspaceLoading={isWorkspaceLoading}
    />
  );
};

export default WorkspaceViewConnect;

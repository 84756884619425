/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { DrawFIRLand } from '@opengeoweb/theme';

import { DRAWMODE } from '../MapDraw/MapDraw';
import { defaultGeoJSONStyleProperties } from '../MapDraw/geojsonShapes';
import {
  intersectionFeatureNL,
  intersectionFeatureBE,
} from '../MapDraw/storyComponents';
import { DrawMode } from './types';
import {
  getIcon,
  defaultPoint,
  defaultPolygon,
  defaultBox,
  defaultDelete,
  MapDrawToolOptions,
  defaultIntersectionStyleProperties,
} from './useMapDrawTool';

export const firSelectionType = 'fir';

// styles and shapes
const featurePropertiesStart = defaultGeoJSONStyleProperties;
const featurePropertiesEnd = {
  stroke: '#6e1e91',
  'stroke-width': 1.5,
  'stroke-opacity': 1,
  fill: '#6e1e91',
  'fill-opacity': 0.25,
};
const geoJSONIntersectionBoundsStyle = {
  stroke: '#000000',
  'stroke-width': 1.5,
  'stroke-opacity': 1,
  fill: '#0075a9',
  'fill-opacity': 0.0,
};

const intersectionShapeNL: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      ...intersectionFeatureNL,
      properties: { ...geoJSONIntersectionBoundsStyle },
    },
  ],
};

const intersectionShapeBE: GeoJSON.FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      ...intersectionFeatureBE,
      properties: { ...geoJSONIntersectionBoundsStyle },
    },
  ],
};

// custom buttons
const shapeButtonNL: DrawMode = {
  drawModeId: 'fir-tool-NL',
  value: DRAWMODE.POLYGON,
  title: 'Custom FIR NL polygon',
  shape: intersectionShapeNL,
  isSelectable: false,
  selectionType: firSelectionType,
};

const shapeButtonBE: DrawMode = {
  drawModeId: 'fir-tool-BE',
  value: DRAWMODE.POLYGON,
  title: 'Custom FIR BE polygon',
  shape: intersectionShapeBE,
  isSelectable: false,
  selectionType: firSelectionType,
};

export const exampleIntersectionsMultiDrawTool = [
  { title: 'NL', geojson: shapeButtonNL.shape as GeoJSON.FeatureCollection },
  { title: 'BE', geojson: shapeButtonBE.shape as GeoJSON.FeatureCollection },
];

export const getDoubleControlToolIcon = (
  drawMode: DrawMode,
): React.ReactElement => {
  const { selectionType } = drawMode;
  const defaultIcon = getIcon(selectionType);
  if (defaultIcon) {
    return defaultIcon;
  }
  if (selectionType === firSelectionType) {
    return (
      <DrawFIRLand
        sx={{
          ...(drawMode.title === shapeButtonBE.title && {
            transform: `scaleY(-1)`,
          }),
        }}
      />
    );
  }
  return <DrawFIRLand />;
};

const getEditModes = (
  geoJSONProperties: GeoJSON.GeoJsonProperties,
): DrawMode[] => [
  {
    ...defaultPoint,
    shape: {
      ...defaultPoint.shape,
      properties: {
        ...(defaultPoint.shape as GeoJSON.Feature).properties,
        ...geoJSONProperties,
      },
    } as GeoJSON.Feature,
  },
  {
    ...defaultPolygon,
    shape: {
      ...defaultPolygon.shape,
      properties: {
        ...(defaultPolygon.shape as GeoJSON.Feature).properties,
        ...geoJSONProperties,
      },
    } as GeoJSON.Feature,
  },
  {
    ...defaultBox,
    shape: {
      ...defaultBox.shape,
      properties: {
        ...(defaultBox.shape as GeoJSON.Feature).properties,
        ...geoJSONProperties,
      },
    } as GeoJSON.Feature,
  },
  {
    ...shapeButtonNL,
    shape: {
      ...shapeButtonNL.shape,
      features: [
        {
          ...intersectionFeatureNL,
          properties: {
            ...intersectionShapeNL.features[0].properties,
            ...geoJSONProperties,
          },
        },
      ],
    } as GeoJSON.FeatureCollection,
  },
  defaultDelete,
];

const getUpdatedFirShape = (isNL: boolean): GeoJSON.FeatureCollection =>
  isNL ? intersectionShapeNL : intersectionShapeBE;

export const getFirTitle = (isNL: boolean): string =>
  isNL ? shapeButtonNL.title : shapeButtonBE.title;

export const updateEditModeButtonsWithFir = (
  drawModes: DrawMode[],
  newFirGeoJSON: GeoJSON.FeatureCollection,
): DrawMode[] => {
  const isNL = newFirGeoJSON === intersectionShapeNL;

  return drawModes.map((mode) => {
    if (mode.selectionType === firSelectionType) {
      return {
        ...mode,
        shape: getUpdatedFirShape(isNL),
        title: getFirTitle(isNL),
      };
    }

    return mode;
  });
};

// example configs
export const startToolExampleConfig: MapDrawToolOptions = {
  defaultGeoJSONIntersectionBounds: intersectionShapeNL,
  defaultDrawModes: getEditModes(featurePropertiesStart),
  defaultGeoJSON: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          ...featurePropertiesStart,
          selectionType: 'box',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [9.451665078283622, 53.21804334226515],
              [4.734608638338736, 53.21804334226515],
              [4.734608638338736, 54.57650543915101],
              [9.451665078283622, 54.57650543915101],
              [9.451665078283622, 53.21804334226515],
            ],
          ],
        },
      },
    ],
  },
  defaultGeoJSONIntersection: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          ...defaultIntersectionStyleProperties,
          selectionType: 'box',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [4.734608638338736, 53.21804334226515],
              [7.181571844927457, 53.21804334226515],
              [7.191667, 53.3],
              [6.5, 53.666667],
              [6.500001364755953, 54.57650543915101],
              [4.734608638338736, 54.57650543915101],
              [4.734608638338736, 53.21804334226515],
            ],
          ],
        },
      },
    ],
  },
};

export const endToolExampleConfig: MapDrawToolOptions = {
  defaultGeoJSONIntersectionBounds: intersectionShapeNL,
  defaultDrawModes: getEditModes(featurePropertiesEnd),
  defaultGeoJSONIntersectionProperties: featurePropertiesEnd,
  defaultGeoJSON: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          ...featurePropertiesEnd,
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2.3408785046353606, 52.57033268070152],
              [8.870833501723242, 51.74975701659361],
              [-0.088053542798945, 50.72496422996941],
              [2.3408785046353606, 52.57033268070152],
            ],
          ],
        },
      },
    ],
  },
  defaultGeoJSONIntersection: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          ...featurePropertiesEnd,
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [2.000002, 51.500002],
              [3.370001, 51.369722],
              [3.362223, 51.320002],
              [3.36389, 51.313608],
              [3.373613, 51.309999],
              [3.952501, 51.214441],
              [4.397501, 51.452776],
              [5.078611, 51.391665],
              [5.249839243115953, 51.335557264081125],
              [6.173465572431757, 51.44120940537001],
              [5.94639, 51.811663],
              [6.405001, 51.830828],
              [6.708618091513499, 52.02146813052448],
              [2.8253551673020594, 52.50945174669971],
              [2.000002, 51.500002],
            ],
          ],
        },
      },
    ],
  },
};

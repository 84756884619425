/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash';
import {
  layerActions,
  layerSelectors,
  mapSelectors,
  layerTypes,
  CoreAppStore,
} from '@opengeoweb/store';
import { webmapUtils } from '@opengeoweb/webmap';
import BaseLayersSelect from './BaseLayers';

// Generate a layer id for an available baselayer to ensure same available baselayers have unique id's in case of multiple maps
// If already in selectedBaseLayers based on name or id, keep current id
export const constructLayerId = (
  baseLayer: layerTypes.Layer,
  selectedBaseLayers: layerTypes.Layer[],
): string => {
  const foundLayer = selectedBaseLayers.find(
    (selectedBaseLayer) =>
      baseLayer?.id === selectedBaseLayer?.id ||
      baseLayer.name === selectedBaseLayer.name,
  );
  return foundLayer ? foundLayer.id! : webmapUtils.generateLayerId();
};

export const constructListAvailableBaseLayers = (
  selectedBaseLayers: layerTypes.Layer[],
  preloadedAvailableBaseLayers: layerTypes.Layer[],
  mapId: string,
): layerTypes.Layer[] => {
  // Ensure we use unique id's for the base layers - excepting the ones that are currently selected, reuse the existing ids
  // Add mapId of map these available base layers are added for
  const baseLayersWithMapId = preloadedAvailableBaseLayers.map((baseLayer) => {
    const layerId = constructLayerId(baseLayer, selectedBaseLayers);
    return { ...baseLayer, mapId, id: layerId };
  });
  // Ensure that current selected baselayer(s) are in the baseLayersWithMapId array, otherwise add them
  return selectedBaseLayers.reduce((list, selectedBaseLayer) => {
    const foundLayer = baseLayersWithMapId.find(
      (layer) => layer.id === selectedBaseLayer?.id,
    );
    if (foundLayer) {
      return list;
    }
    return list.concat({ ...selectedBaseLayer, mapId });
  }, baseLayersWithMapId as layerTypes.Layer[]);
};

export const areAvailableBaseLayersSame = (
  availableBaseLayerList: layerTypes.Layer[],
  newBaseLayerList: layerTypes.Layer[],
): boolean => {
  const availableList = availableBaseLayerList.map((layer) => layer.name);
  const newList = newBaseLayerList.map((layer) => layer.name);

  return isEqual(availableList, newList);
};

interface BaseLayersConnectProps {
  mapId: string;
  preloadedAvailableBaseLayers: layerTypes.Layer[];
  tooltipPrefix?: string;
  icon?: React.ElementType;
}

const BaseLayersConnect: React.FC<BaseLayersConnectProps> = ({
  mapId,
  preloadedAvailableBaseLayers,
  tooltipPrefix,
  icon,
}: BaseLayersConnectProps) => {
  const dispatch = useDispatch();
  const selectedBaseLayers = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapBaseLayers(store, mapId),
  );

  const currentAvailableBaseLayers = useSelector((store: CoreAppStore) =>
    layerSelectors.getAvailableBaseLayersForMap(store, mapId),
  );

  React.useEffect(() => {
    const availableBaseLayersToBeAdded = constructListAvailableBaseLayers(
      selectedBaseLayers,
      preloadedAvailableBaseLayers,
      mapId,
    );

    const areBaseLayersSame = areAvailableBaseLayersSame(
      availableBaseLayersToBeAdded,
      currentAvailableBaseLayers,
    );

    if (!areBaseLayersSame) {
      dispatch(
        layerActions.setAvailableBaseLayers({
          layers: availableBaseLayersToBeAdded,
          mapId,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapId, preloadedAvailableBaseLayers]);

  const onChangeBaseLayers = (newSelectedLayerId: string): void => {
    const newBaseLayer = currentAvailableBaseLayers.filter(
      (layer) => layer.id === newSelectedLayerId,
    );
    dispatch(
      layerActions.setBaseLayers({
        mapId,
        layers: newBaseLayer,
        origin: layerTypes.LayerActionOrigin.layerManager,
      }),
    );
  };

  return (
    <BaseLayersSelect
      selectedBaseLayers={selectedBaseLayers}
      availableBaseLayers={currentAvailableBaseLayers}
      onChangeBaseLayers={onChangeBaseLayers}
      tooltipPrefix={tooltipPrefix}
      icon={icon}
    />
  );
};

/**
 * Allows you to select a baselayer
 *
 * Expects the following props:
 * @param {string} mapId mapId: string - Id of the map
 * @param {layerTypes.Layer[]} preloadedAvailableBaseLayers preloadedAvailableBaseLayers: array of layerTypes.Layer objects - contains an array of layerTypes.Layer objects (consisting solely of type baseLayer) that are available to be shown as basemaps
 * @example
 * ``` <BaseLayersConnect mapId="mapid_1" preloadedAvailableBaseLayers = {baseLayersList} /> ```
 */
export default BaseLayersConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';
import { CoreAppStore, layerSelectors } from '@opengeoweb/store';
import { MapDimensionSelect } from '@opengeoweb/webmap-react';
import { useLayerDimensions } from '../LayerManager/LayerContainerRow/LayerRow/DimensionSelect/dimensionSelectHooks';

export interface DimensionSelectSliderConnectProps {
  layerId: string;
  mapId: string;
  dimensionName: string;
  handleDimensionValueChanged: (
    layerId: string,
    dimensionName: string,
    dimensionValue: string,
  ) => void;
  handleSyncChanged: (
    layerId: string,
    dimensionName: string,
    dimensionValue: string,
    synced: boolean,
  ) => void;
}

const DimensionSelectSliderConnect: React.FC<
  DimensionSelectSliderConnectProps
> = ({
  layerId,
  mapId,
  dimensionName,
  handleDimensionValueChanged,
  handleSyncChanged,
}: DimensionSelectSliderConnectProps) => {
  const layerDimension = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerDimension(store, layerId, dimensionName),
  );

  const { isLayerEnabled } = useLayerDimensions(layerId, mapId);

  if (!layerDimension || !isLayerEnabled) {
    return null;
  }

  return (
    <MapDimensionSelect
      layerId={layerId}
      dimensionName={dimensionName}
      handleDimensionValueChanged={handleDimensionValueChanged}
      handleSyncChanged={handleSyncChanged}
      layerDimension={layerDimension}
    />
  );
};

export default DimensionSelectSliderConnect;

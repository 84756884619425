/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Visibility, ConfigKey } from '../../../../types';
import { getFieldValue, prependZeroes } from './utils';

const config = [
  { key: 'range', size: 4 },
  { key: 'unit', size: 1 },
];

const configAsObject = Object.assign(config).reduce(
  (list: ConfigKey[], item: ConfigKey) => ({ ...list, [item.key]: item }),
  {},
);

// formatters
// if passed true - visibility is CAVOK, if passed a number - prepend zeroes to size 4
export const formatVisibilityToString = (
  value: Visibility | boolean,
): string => {
  if (!value && value !== false) {
    return value as unknown as string;
  }
  // if passed true - we have cavOK
  if (typeof value === 'boolean') {
    return value ? 'CAVOK' : '';
  }
  // else, format visibility number
  const { size } = configAsObject.range;
  return prependZeroes(value.range, size);
};

// parsers
// returns object with validity if passed numbers - returns object with cavOK: true if passed CAVOK
export const parseVisibilityToObject = (
  value: string,
): { visibility?: Visibility; cavOK?: boolean } => {
  const trimmedValue = getFieldValue(value);

  if (trimmedValue === 'CAVOK') {
    return { cavOK: true };
  }

  return {
    visibility: {
      range: parseInt(value, 10),
      unit: 'M',
    },
  };
};

// messages
export const invalidVisibilityMessage =
  'Invalid visibility, expected either a 4 digit visibility range in meters or CAVOK';
export const invalidVisibilityRangeMessage =
  'Invalid visibility, expected a 4 digit range in meters';
export const invalidVisibilityRange50mStepMessage =
  'Invalid visibility, a range between 0000 and 0800 must be rounded to the nearest 50m';
export const invalidVisibilityRange100mStepMessage =
  'Invalid visibility, a range between 0800 and 5000 must be rounded to the nearest 100m';
export const invalidVisibilityRange1000mStepMessage =
  'Invalid visibility, a range between 5000 and 9000 must be rounded to the nearest 1000m';

// validations
const validateRange = (value: string): boolean | string => {
  // check if not too many digits
  if (value.length > 4) {
    return invalidVisibilityRangeMessage;
  }
  // Parse to integer to check for range steps
  const intRange = parseInt(value, 10);
  if (intRange < 800) {
    return intRange % 50 === 0 || invalidVisibilityRange50mStepMessage;
  }
  if (intRange < 5000) {
    return intRange % 100 === 0 || invalidVisibilityRange100mStepMessage;
  }
  if (intRange < 9999) {
    return intRange % 1000 === 0 || invalidVisibilityRange1000mStepMessage;
  }

  return true;
};

// Main validation function
export const validateVisibilityField = (value: string): boolean | string => {
  const trimmedValue = getFieldValue(value);
  if (!trimmedValue.length) {
    return true;
  }
  // Validate length and ensure it doesn't contain any other characters than numbers and CAVOK
  if (
    trimmedValue.length < 4 ||
    trimmedValue.length > 5 ||
    !trimmedValue.match(/^[0-9CAVOK]+$/)
  ) {
    return invalidVisibilityMessage;
  }

  // if not a number - only option is CAVOK
  if (!trimmedValue.match(/^[0-9]+$/)) {
    return trimmedValue === 'CAVOK' || invalidVisibilityMessage;
  }

  // validate range
  const rangeValidation = validateRange(trimmedValue);

  // return first error, if no error then no error!
  return rangeValidation === true || rangeValidation;
};

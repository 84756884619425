/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { layerActions, layerTypes } from '@opengeoweb/store';

import { webmapUtils } from '@opengeoweb/webmap';
import LayerManagerMenuButton from './MenuButton';

interface LayerManagerButtonProps {
  mapId: string;
  layerId?: string;
  tooltipTitle?: string;
  icon?: React.ReactNode;
}

interface AddLayerFromCopyParams {
  mapId: string;
  oldLayerId: string;
  newLayerId: string;
}

const LayerManagerMenuButtonConnect: React.FC<LayerManagerButtonProps> = ({
  layerId,
  mapId,
  tooltipTitle,
  icon,
}: LayerManagerButtonProps) => {
  const dispatch = useDispatch();

  const duplicateLayer = React.useCallback(
    ({ newLayerId, mapId }: AddLayerFromCopyParams) =>
      dispatch(
        layerActions.duplicateMapLayer({
          mapId,
          oldLayerId: layerId!,
          newLayerId,
          origin: layerTypes.LayerActionOrigin.layerManager,
        }),
      ),
    [dispatch, layerId],
  );

  return (
    <LayerManagerMenuButton
      layerId={layerId}
      mapId={mapId}
      onLayerDuplicate={(): void => {
        duplicateLayer({
          newLayerId: webmapUtils.generateLayerId(),
          mapId,
        } as AddLayerFromCopyParams);
      }}
      tooltipTitle={tooltipTitle}
      icon={icon}
    />
  );
};

export default LayerManagerMenuButtonConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { AxiosInstance } from 'axios';
import { CreateApiProps, createNonAuthApiInstance } from '@opengeoweb/api';
import {
  CapFeatures,
  CapPresets,
  CapWarnings,
  FeedLastUpdated,
} from '../components/types';

export interface CapApi {
  getCapConfiguration?: (
    productConfigKey: string,
  ) => Promise<{ data: CapPresets }>;
  getAllAlerts?: (thirdPartyApiUrl: string) => Promise<{ data: CapFeatures }>;
  getFeedLinks?: (thirdPartyApiUrl: string) => Promise<{ data: string[] }>;
  getSingleAlert?: (alertUrl: string) => Promise<{ data: CapWarnings }>;
  getLastUpdated?: (
    thirdPartyApiUrl: string,
  ) => Promise<{ data: FeedLastUpdated }>;
}
const getApiRoutes = (
  axiosInstance: AxiosInstance,
  nonAuthAxiosInstance: AxiosInstance,
): CapApi => ({
  getCapConfiguration: (
    productConfigKey: string,
  ): Promise<{ data: CapPresets }> => {
    return nonAuthAxiosInstance.get(`/assets/${productConfigKey}`);
  },
  getAllAlerts: (thirdPartyApiUrl: string): Promise<{ data: CapFeatures }> => {
    return axiosInstance.get(`/allalerts?url=${thirdPartyApiUrl}`);
  },
  getFeedLinks: (thirdPartyApiUrl: string): Promise<{ data: string[] }> => {
    return axiosInstance.get(`/feedlinks?url=${thirdPartyApiUrl}`);
  },
  getSingleAlert: (alertUrl: string): Promise<{ data: CapWarnings }> => {
    return axiosInstance.get(`/singlealert?url=${alertUrl}`);
  },
  getLastUpdated: (
    thirdPartyApiUrl: string,
  ): Promise<{ data: FeedLastUpdated }> => {
    return axiosInstance.get(`/poll_feed?url=${thirdPartyApiUrl}`);
  },
});

export const createApi = (props: CreateApiProps): CapApi => {
  const axiosInstance = createNonAuthApiInstance(props);
  const nonAuthAxiosInstance = createNonAuthApiInstance({});
  return getApiRoutes(axiosInstance, nonAuthAxiosInstance);
};

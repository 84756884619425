/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import {
  AlertBanner,
  useIsMounted,
  getErrorMessage,
  getWarningMessage,
} from '@opengeoweb/shared';

import { TFunction } from 'i18next';
import {
  TafFromBackend,
  Taf,
  TafActions,
  TafStatus,
  TafFromFrontEnd,
} from '../../../types';
import { TabPanel } from '../../TabPanel';
import TafHeader from '../../TafHeader/TafHeader';
import { getTafStatusLabel } from '../../TafForm/utils';
import Spinner from '../../Spinner/Spinner';
import TafForm from '../../TafForm/TafForm';
import { IssuesPanePosition } from '../../IssuesPane/types';
import { useTafTranslation } from '../../../utils/i18n';

export const fromActionToStatus = (tafAction: TafActions): TafStatus => {
  switch (tafAction) {
    case 'DRAFT':
      return 'DRAFT';
    case 'DRAFT_AMEND':
      return 'DRAFT_AMENDED';
    case 'DRAFT_CORRECT':
      return 'DRAFT_CORRECTED';
    case 'PUBLISH':
      return 'PUBLISHED';
    case 'AMEND':
      return 'AMENDED';
    case 'CORRECT':
      return 'CORRECTED';
    case 'CANCEL':
      return 'CANCELLED';
    default:
      return 'NEW';
  }
};

export interface TafError {
  type: 'CANCELLED' | 'SERVER' | 'WARNING';
  title: string;
  message?: string;
  testId?: string;
}
interface TafPanelProps {
  tafFromBackend: TafFromBackend;
  activeTafIndex: number;
  index: number;
  isLoading?: boolean;
  postTaf?: (taf: TafFromFrontEnd) => Promise<void>;
  patchTaf?: (taf: TafFromFrontEnd) => Promise<boolean>;
  isIssuesPaneOpen?: boolean;
  setIsIssuesPaneOpen?: (isOpen: boolean, position: IssuesPanePosition) => void;
  isFormDisabled?: boolean;
  setIsFormDisabled?: (isDisabled: boolean) => void;
  error?: TafError;
  fetchNewTafList?: () => Promise<TafFromBackend[]>;
  previousTaf: Taf;
  tafAction: TafActions;
}

export const isWarning = (error: TafError): boolean =>
  error?.type === 'WARNING';

const parseErrorMessage = (
  error: AxiosError | Error,
  params: TafFromFrontEnd,
  t: TFunction,
): TafError => {
  const message = getErrorMessage(error);
  const warningMessage = getWarningMessage(error);

  if (warningMessage) {
    return {
      type: 'WARNING',
      title: warningMessage,
    };
  }

  return params.changeStatusTo === 'CANCELLED'
    ? {
        type: 'CANCELLED',
        title: t('error-cancel'),
        message,
      }
    : {
        type: 'SERVER',
        title: t('error-save'),
        message,
      };
};

export const usePostTafRequest = (
  request: (params: TafFromFrontEnd) => Promise<void>,
): {
  isLoading: boolean;
  error: TafError | undefined;
  setError: (newError: TafError) => void;
  doRequest: (params: TafFromFrontEnd) => Promise<void>;
} => {
  const { t } = useTafTranslation();
  const { isMounted } = useIsMounted();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<TafError>();

  const doRequest = async (params: TafFromFrontEnd): Promise<void> => {
    setLoading(true);
    setError(null!);
    try {
      await request(params);
      if (!isMounted.current) {
        return;
      }
      setLoading(false);
      setError(null!);
    } catch (error) {
      if (!isMounted.current) {
        return;
      }
      const newError = parseErrorMessage(error as AxiosError, params, t);
      setLoading(false);
      setError(newError);
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw newError;
    }
  };
  return { isLoading, error, setError, doRequest };
};

const TafPanel: React.FC<TafPanelProps> = ({
  tafFromBackend,
  activeTafIndex,
  index,
  isLoading = false,
  postTaf = (): Promise<void> => null!,
  patchTaf = (): Promise<boolean> => null!,
  isIssuesPaneOpen = false,
  setIsIssuesPaneOpen = (): void => null!,
  isFormDisabled = false,
  setIsFormDisabled = (): void => null!,
  error,
  fetchNewTafList = (): Promise<TafFromBackend[]> => null!,
  previousTaf = null!,
  tafAction,
}: TafPanelProps) => {
  const { t } = useTafTranslation();
  const severity = isWarning(error!) ? 'warning' : 'error';

  const onTafAction = async (
    action: TafActions,
    taf?: Taf,
  ): Promise<boolean> => {
    try {
      // Add right actions and their follow up calls to the BE
      switch (action) {
        case 'DRAFT':
        case 'DRAFT_AMEND':
        case 'DRAFT_CORRECT':
        case 'PUBLISH':
        case 'AMEND':
        case 'CANCEL':
        case 'CORRECT':
          // post taf
          await postTaf({
            taf: taf!,
            changeStatusTo: fromActionToStatus(action),
          });
          return true;
        default:
          return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <TabPanel
      value={activeTafIndex}
      index={index}
      data-testid={`taf-panel${activeTafIndex === index ? '-active' : ''}`}
    >
      <TafHeader
        title={getTafStatusLabel(
          t,
          tafFromBackend.taf.status,
          tafAction,
          isFormDisabled,
        )}
        sx={{ marginBottom: '4px' }}
      />

      {isLoading && (
        <Spinner
          data-testid="taf-loading"
          sx={{
            position: 'absolute',
            zIndex: 1,
            color: 'geowebColors.backdrops.white.fill',
          }}
        />
      )}
      {error && (
        <AlertBanner
          severity={severity}
          title={error.title}
          info={error.message}
          dataTestId="taf-error"
          shouldClose
        />
      )}
      <Box sx={{ marginTop: { xs: '44px', sm: '10px' }, padding: 1 }}>
        <TafForm
          tafFromBackend={tafFromBackend}
          onFormAction={onTafAction}
          isIssuesPaneOpen={isIssuesPaneOpen}
          setIsIssuesPaneOpen={setIsIssuesPaneOpen}
          isDisabled={isFormDisabled}
          setIsDisabled={setIsFormDisabled}
          onSwitchEditor={patchTaf}
          fetchNewTafList={fetchNewTafList}
          previousTaf={previousTaf}
        />
      </Box>
    </TabPanel>
  );
};

export default TafPanel;

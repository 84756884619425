/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { SpeedButton } from '../SpeedButton/SpeedButton';
import { TimeStepButton } from '../TimeStepButton/TimeStepButton';
import { TimeSpanButton } from '../TimeSpanButton/TimeSpanButton';
import { AnimationLengthButton } from '../AnimationLengthButton/AnimationLengthButton';
import { defaultAnimationDelayAtStart } from '../../TimeSlider/timeSliderUtils';

export const OptionsMenu: FC<{
  timeSpanBtn?: React.ReactNode;
  timeStepBtn?: React.ReactNode;
  speedBtn?: React.ReactNode;
  animationLengthBtn?: React.ReactNode;
}> = ({ speedBtn, timeSpanBtn, timeStepBtn, animationLengthBtn }) => {
  return (
    <>
      <Grid size={{ xs: 'auto' }}>
        {timeSpanBtn || (
          <TimeSpanButton
            onChangeSliderValue={(): void => {}}
            onToggleTimeSpan={(): void => {}}
            isTimeSpanAuto={false}
            layerHasTimeStep={false}
            timeSliderSpan={0}
            disabled={false}
          />
        )}
      </Grid>
      <Grid size={{ xs: 'auto' }}>
        {animationLengthBtn || (
          <AnimationLengthButton
            toggleAnimationLengthAuto={() => {}}
            onChangeAnimationLength={(): void => {}}
          />
        )}
      </Grid>
      <Grid size={{ xs: 'auto' }}>
        {timeStepBtn || (
          <TimeStepButton
            onChangeTimeStep={(): void => {}}
            isTimestepAuto={false}
          />
        )}
      </Grid>
      <Grid size={{ xs: 'auto' }}>
        {speedBtn || (
          <SpeedButton
            animationDelay={defaultAnimationDelayAtStart}
            setMapAnimationDelay={(): void => {}}
          />
        )}
      </Grid>
    </>
  );
};

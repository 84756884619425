/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Button,
  Grid2 as Grid,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import {
  useConfirmationDialog,
  usePreventBrowserClose,
  getAxiosErrorMessage,
  isAxiosError,
  CustomTooltip,
} from '@opengeoweb/shared';
import { useApiContext } from '@opengeoweb/api';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormHiddenInput,
  ReactHookFormTextField,
} from '@opengeoweb/form-fields';

import { AxiosError } from 'axios';

import LifeCycleEditOptions from './LifeCycleEditOptions';
import { SWEvent, SWNotification } from '../../types';
import {
  constructOutgoingNotification,
  constructRepopulateNotification,
  ConstructRepopulateNotificationType,
} from './utils';
import FormWrapper from './FormWrapper';
import { SpaceWeatherApi } from '../../utils/api';
import { useSpaceweatherTranslation } from '../../utils/i18n';

// hidden input helpers, should not be part of send formdata
const HIDDEN_INPUT_HELPER_IS_POPULATE_REQUEST = 'IS_POPULATE_REQUEST';

const useIsPopulateFormHelpers = (): {
  toggleIsPopulate: (isPopulate: boolean) => void;
  isPopulate: () => boolean;
  PopulateFieldHelper: () => React.ReactElement;
} => {
  const { getValues, setValue, register } = useFormContext();

  const isPopulate = (): boolean =>
    getValues(HIDDEN_INPUT_HELPER_IS_POPULATE_REQUEST) === true;

  const toggleIsPopulate = (isPopulate: boolean): void =>
    setValue(HIDDEN_INPUT_HELPER_IS_POPULATE_REQUEST, isPopulate);

  const PopulateFieldHelper = (): React.ReactElement => (
    <input
      defaultValue="false"
      {...(register(
        HIDDEN_INPUT_HELPER_IS_POPULATE_REQUEST,
      ) as unknown as Record<string, unknown>)}
      type="hidden"
    />
  );

  return { toggleIsPopulate, isPopulate, PopulateFieldHelper };
};

const styles = {
  config: {
    width: '1000px',
  },
  optionButton: {
    marginRight: '10px',
  },
  repopulateButton: {
    marginTop: '10px',
  },
  backdrop: {
    zIndex: 1001,
    color: '#fff',
  },
  errorMessage: {
    color: 'red',
  },
  inputField: {
    marginTop: '0px',
    marginBottom: '10px',
  },
};

const isRequiredForSaveStore = (
  value: string,
  isPopulate: () => boolean,
): boolean | string =>
  !isPopulate() && value === '' ? 'This field is required' : true;

interface LifeCycleEditProps {
  toggleDialogOpen: (formSaved?: boolean) => void;
  statusTagContent?: string;
  eventTypeDisabled?: boolean;
  onFormChange?: (hasChanged: boolean) => void;
  actionMode?: string;
  baseNotificationType: string;
  setErrorStoreMessage?: (value: string) => void;
  setErrorRetrievePopulate?: (value: string) => void;
}

export const LifeCycleEditForm: React.FC<LifeCycleEditProps> = ({
  toggleDialogOpen,
  statusTagContent = 'Alert',
  eventTypeDisabled = false,
  onFormChange = (): void => {},
  actionMode = 'none',
  baseNotificationType = '',
  setErrorStoreMessage = (): void => {},
  setErrorRetrievePopulate = (): void => {},
}: LifeCycleEditProps) => {
  const { t } = useSpaceweatherTranslation();
  const confirmDialog = useConfirmationDialog();
  const { api } = useApiContext<SpaceWeatherApi>();
  const { handleSubmit, getValues, formState, setValue } = useFormContext();
  const { isPopulate, toggleIsPopulate, PopulateFieldHelper } =
    useIsPopulateFormHelpers();

  const { isDirty } = formState;

  const hiddenInputFields = [
    'changestateto',
    'draft',
    'originator',
    'notificationid',
    'eventid',
    'issuetime',
  ];

  const [showLoader, setLoader] = React.useState(false);

  usePreventBrowserClose(isDirty);

  React.useEffect(() => {
    onFormChange(isDirty);
  }, [isDirty, onFormChange]);

  const handleDiscard = (): void => {
    // If it is a draft that is discarded, show a confirmation message that it will be remove from database
    if (getValues('draft') && getValues('notificationid')) {
      void confirmDialog({
        title: t('discard-dialog-title'),
        description: t('discard-dialog-description'),
        confirmLabel: t('notification-discard'),
        cancelLabel: t('dialog-cancel'),
      }).then(() => {
        setLoader(true);
        setErrorStoreMessage('');
        api
          .discardDraftNotification({
            notificationid: getValues('notificationid'),
            eventid: getValues('eventid'),
          })
          .then(() => {
            toggleDialogOpen(true);
            setLoader(false);
          })
          .catch((error: AxiosError | Error) => {
            if (isAxiosError(error)) {
              setErrorStoreMessage(getAxiosErrorMessage(error as AxiosError));
            } else {
              setErrorStoreMessage(error.message ? error.message : 'error');
            }
            setLoader(false);
          });
      });
    } else {
      // Not a draft, close the dialog - in case of changes, show message that these will be lost if not saved
      toggleDialogOpen();
    }
  };

  const postNotification = (data: SWNotification): void => {
    setLoader(true);
    setErrorStoreMessage('');
    api
      .issueNotification(data)
      .then(() => {
        setLoader(false);
        toggleDialogOpen(true);
      })
      .catch((error: AxiosError | Error) => {
        if (isAxiosError(error)) {
          setErrorStoreMessage(getAxiosErrorMessage(error as AxiosError));
        } else {
          setErrorStoreMessage(error.message ? error.message : 'error');
        }
        setLoader(false);
      });
  };

  const handleStoreNotification = (isDraft = false): void => {
    toggleIsPopulate(false);
    void handleSubmit((data) => {
      postNotification(
        constructOutgoingNotification(
          data as SWNotification,
          statusTagContent,
          isDraft,
        ),
      );
    })();
  };

  const requestRepopulate = (data: SWNotification): void => {
    setLoader(true);
    setErrorRetrievePopulate('');
    api
      .getRePopulateTemplateContent(data)
      .then((response) => {
        setValue('title', response.data.title);
        setValue('message', response.data.message);
        setLoader(false);
        toggleIsPopulate(false);
      })
      .catch((error: AxiosError | Error) => {
        if (isAxiosError(error)) {
          setErrorRetrievePopulate(getAxiosErrorMessage(error as AxiosError));
        } else {
          setErrorRetrievePopulate(error.message ? error.message : 'error');
        }
        setLoader(false);
        toggleIsPopulate(false);
      });
  };

  const handleRequestRepopulate = (): void => {
    toggleIsPopulate(true);
    void handleSubmit((data) => {
      requestRepopulate(
        constructRepopulateNotification(
          data as ConstructRepopulateNotificationType,
          actionMode,
        ),
      );
    })();
  };

  // On first load, try (if there are no validation errors) to immediately retrieve the template
  React.useEffect(() => {
    if (baseNotificationType !== 'draft' && baseNotificationType !== 'new') {
      handleRequestRepopulate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1} data-testid="edit-lifecycle">
      <Grid size={12}>
        <LifeCycleEditOptions
          statusTagContent={statusTagContent}
          eventTypeDisabled={eventTypeDisabled}
          actionMode={actionMode}
        />
      </Grid>
      <Grid container spacing={1} size={12}>
        <Grid size={9}>
          <ReactHookFormTextField
            name="title"
            slotProps={{ htmlInput: { 'data-testid': 'notification-title' } }}
            label={t('notification-event-title')}
            sx={styles.inputField}
            rules={{
              validate: {
                isRequiredForSaveStore: (value): boolean | string =>
                  isRequiredForSaveStore(value, isPopulate),
              },
            }}
          />
        </Grid>
        <Grid size={3}>
          <CustomTooltip
            title={t('notification-populate-title')}
            placement="top"
            style={{ verticalAlign: 'baseLine' }}
          >
            <Button
              data-testid="notification-repopulate"
              variant="outlined"
              color="secondary"
              sx={styles.repopulateButton}
              onClick={(): void => {
                handleRequestRepopulate();
              }}
            >
              {t('notification-populate')}
            </Button>
          </CustomTooltip>
        </Grid>
      </Grid>
      <Grid size={12}>
        <ReactHookFormTextField
          name="message"
          slotProps={{ htmlInput: { 'data-testid': 'notification-text' } }}
          multiline
          rows={20}
          variant="outlined"
          placeholder={t('notification-event-description')}
          rules={{
            validate: {
              isRequiredForSaveStore: (value): boolean | string =>
                isRequiredForSaveStore(value, isPopulate),
            },
          }}
          sx={{
            backgroundColor: 'rgba(0, 117, 169, 0.05)',
          }}
        />
      </Grid>
      <PopulateFieldHelper />
      <Grid
        data-testid="new-notification-options"
        container
        alignItems="center"
        justifyContent="flex-end"
        size={12}
        spacing={0}
      >
        <Grid>
          <Button
            data-testid="discard"
            color="secondary"
            onClick={handleDiscard}
            sx={styles.optionButton}
          >
            {t('notification-discard')}
          </Button>
        </Grid>
        <Grid>
          <Button
            data-testid="draft"
            variant="outlined"
            color="secondary"
            sx={styles.optionButton}
            onClick={(): void => {
              handleStoreNotification(true);
            }}
          >
            {t('notification-save')}
          </Button>
        </Grid>
        <Grid>
          <Button
            data-testid="issue"
            variant="contained"
            color="secondary"
            sx={styles.optionButton}
            onClick={(): void => {
              handleStoreNotification();
            }}
          >
            {t('notification-issue')}
          </Button>
        </Grid>
      </Grid>
      <Backdrop data-testid="loader" open={showLoader} sx={styles.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {hiddenInputFields.map((field) => (
        <ReactHookFormHiddenInput name={field} key={field} />
      ))}
    </Grid>
  );
};

export interface WrapperProps extends LifeCycleEditProps {
  baseNotificationData?: SWEvent;
  baseNotificationType: string;
}

const LifeCycleEdit: React.FC<WrapperProps> = ({
  baseNotificationData,
  baseNotificationType,
  ...otherProps
}: WrapperProps) => {
  return (
    <FormWrapper
      baseNotificationData={baseNotificationData}
      baseNotificationType={baseNotificationType}
    >
      <LifeCycleEditForm
        {...otherProps}
        baseNotificationType={baseNotificationType}
      />
    </FormWrapper>
  );
};

export default LifeCycleEdit;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { useTheme } from '@emotion/react';
import {
  Grid2 as Grid,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { getDeepProperty } from '@opengeoweb/form-fields';
import React from 'react';
import {
  useFormContext,
  Controller,
  FieldErrors,
  RegisterOptions,
  FieldValues,
} from 'react-hook-form';
import { TAF_MODULE_ID } from '../../ResponsiveWrapper/ResponsiveWrapper';

const textFieldStyle = (
  theme: Theme,
  shouldPreventUppercase: boolean,
): SxProps<Theme> => ({
  // input
  marginBottom: '4px',
  width: '100%',
  height: 46,
  input: {
    textAlign: 'center',
    textTransform: shouldPreventUppercase ? 'none' : 'uppercase',
    fontWeight: 'normal',
    paddingLeft: 0,
    paddingRight: 0,
    '&.Mui-disabled': {
      color: 'geowebColors.typographyAndIcons.text',
      WebkitTextFillColor: theme.palette.geowebColors.typographyAndIcons.text,
    },
    // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/1995 disables the default autofill styling, should be a better fix
    '&:-webkit-autofill': {
      WebkitTransitionDelay: '86400s', // 1 day delay
    },
  },
  label: {
    transformOrigin: 'center top',
    right: 0,
    textAlign: 'center',
    fontSize: '10px',
    '&.Mui-disabled': {
      color: 'geowebColors.captions.captionStatus.rgba',
    },
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    transform: 'translate(0px, 16px) scale(1)',
  },
  // shrink
  ' .MuiInputLabel-shrink': {
    transform: 'translate(2px, 10px) scale(0.75) !important',
    letterSpacing: '0.33px',
    textAlign: 'left',
  },
  // root
  '&': {
    '.Mui-disabled:before': {
      borderColor: 'transparent',
    },
  },
  '& .MuiFilledInput-root': {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
  },
  // container queries
  [`@container ${TAF_MODULE_ID} (min-width: 1440px)`]: {
    '&.MuiFormControl-root.MuiTextField-root .inputText': {
      fontSize: 14,
    },
  },
  '.inputText': {
    fontSize: 12,
  },
});

const getTafErrors = (
  name: string,
  errors: FieldErrors<FieldValues>,
): FieldErrors => {
  const nameAsArray = name.split('.');
  if (nameAsArray[0].includes('changeGroups')) {
    if (errors.changeGroups) {
      const changeGroupNum = nameAsArray[0].slice(
        13,
        nameAsArray[0].length - 1,
      );
      const restArray = nameAsArray.slice(1);
      return getDeepProperty(
        restArray,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (errors.changeGroups as any)[changeGroupNum],
      );
    }
    // No changegroup errors
    return null!;
  }

  return getDeepProperty(nameAsArray, errors)!;
};

export const getDisplayValue = (value: string, disabled: boolean): string =>
  disabled && !value ? '-' : value;

type BaseTextFieldProps = TextFieldProps & {
  shouldPreventUppercase?: boolean;
};

export const BaseTextField: React.FC<BaseTextFieldProps> = React.memo(
  ({ shouldPreventUppercase = false, ...props }: BaseTextFieldProps) => {
    const theme = useTheme() as Theme;
    const styling = textFieldStyle(theme, shouldPreventUppercase);

    return <TextField type="text" variant="filled" sx={styling} {...props} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value === nextProps.value &&
      prevProps.error === nextProps.error &&
      prevProps.name === nextProps.name &&
      prevProps.disabled === nextProps.disabled
    );
  },
);

interface TafFormTextFieldProps {
  label: string;
  disabled?: boolean;
  name: string;
  onChange?: (e: string) => void;
  defaultValue?: string;
  formatter?: (value: string) => string;
  rules?: RegisterOptions;
  autoComplete?: { key: string; value: string }[];
  onBlur?: () => void;
  autoFocus?: boolean;
  shouldPreventUppercase?: boolean;
}

const TafFormTextField: React.FC<TafFormTextFieldProps> = ({
  label,
  disabled = false,
  name = '',
  onChange = (): void => {},
  onBlur = (): void => {},
  defaultValue = '',
  formatter = null!,
  rules,
  autoComplete = [],
  autoFocus = false,
  shouldPreventUppercase = false,
}: TafFormTextFieldProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const fieldErrors = getTafErrors(name, errors);

  const [maxLength, setMaxLength] = React.useState<number>(null!);

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const target = event.target as HTMLInputElement;
      const { value: currentValue, selectionStart, selectionEnd } = target;

      const textSelection = currentValue.substring(
        selectionStart!,
        selectionEnd!,
      );

      if (
        (autoComplete && !currentValue.length) ||
        (textSelection.length &&
          currentValue.length &&
          textSelection === currentValue)
      ) {
        const pressedKey = event.key.toString().toUpperCase();
        const foundValue =
          autoComplete.find((el) => el.key === pressedKey) || null;

        if (foundValue) {
          setValue(name, foundValue.value, {
            shouldDirty: true,
            shouldValidate: true,
          });
          setMaxLength(foundValue.value.length);

          event.preventDefault();
        }
      }
    },
    [name, autoComplete, setValue],
  );

  const onChangeField = React.useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      callback: (value: string) => void,
    ) => {
      const { value } = event.currentTarget;
      if (autoComplete) {
        const valueUppercase = value.toUpperCase();
        const exist = autoComplete.find((e) => e.value === valueUppercase);
        const newMaxLength = exist ? valueUppercase.length : null;
        setMaxLength(newMaxLength!);
      }
      callback(value);
      onChange(value);
    },
    [onChange, autoComplete],
  );

  const onBlurField = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>, callback: () => void) => {
      const { value } = event.target;
      const valueUppercase = value.toUpperCase();
      if (value !== valueUppercase && !shouldPreventUppercase) {
        setValue(name, valueUppercase, {
          shouldValidate: true,
        });
      }

      callback();
      onBlur();
    },
    [onBlur, name, setValue, shouldPreventUppercase],
  );

  const hasError = !!fieldErrors;

  return (
    <Grid
      sx={{
        width: `${100 / 13}%`,
        minWidth: 60,
      }}
    >
      <Controller
        render={({ field }): React.ReactElement => {
          const displayValue = formatter
            ? formatter(field.value)
            : getDisplayValue(field.value, disabled);

          return (
            <BaseTextField
              label={label}
              disabled={disabled}
              name={name}
              value={displayValue}
              inputRef={field.ref}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                onChangeField(event, field.onChange)
              }
              onKeyDown={onKeyDown}
              error={hasError}
              onBlur={(event: React.FocusEvent<HTMLInputElement>): void =>
                onBlurField(event, field.onBlur)
              }
              inputProps={{
                maxLength,
                className: 'inputText',
                'aria-label': name,
              }}
              autoFocus={autoFocus}
              shouldPreventUppercase={shouldPreventUppercase}
            />
          );
        }}
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
      />
    </Grid>
  );
};

export default TafFormTextField;

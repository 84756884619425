/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as turf from '@turf/turf';
import {
  WMJSMAP_LONLAT_EPSGCODE,
  getPixelCoordFromLatLong,
  webmapUtils,
} from '@opengeoweb/webmap';
import proj4, { InterfaceProjection } from 'proj4';

import { Position } from 'geojson';
import { Coordinate } from './geojsonShapes';

export type CheckHoverFeaturesResult = {
  coordinateIndexInFeature: number;
  featureIndex: number;
  feature: GeoJSON.Feature;
  distance: number;
  screenCoords: { x: number; y: number };
} | null;

export type ProjectorCache = Record<string, InterfaceProjection>;

export const Proj4js = proj4;
// Cache for for storing and reusing Proj4 instances
export const projectorCache = {} as ProjectorCache;

// Ensure that you have a Proj4 object, pulling from the cache if necessary
export const getProj4 = (
  projection: string | proj4.InterfaceProjection,
): InterfaceProjection => {
  if (projection instanceof Proj4js.Proj) {
    return projection as InterfaceProjection;
  }
  if (typeof projection === 'string' && projection in projectorCache) {
    return projectorCache[projection];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  projectorCache[projection] = new Proj4js.Proj(projection);
  return projectorCache[projection as keyof ProjectorCache];
};

/* Function which calculates the distance between two points */
export const distance = (a: Coordinate, b: Coordinate): number => {
  return Math.sqrt((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y));
};

/* Function which calculates if a point is between two other points */
export const isBetween = (
  a: Coordinate,
  c: Coordinate,
  b: Coordinate,
): boolean => {
  const da = distance(a, c) + distance(c, b);
  const db = distance(a, b);
  return Math.abs(da - db) < 1;
};

export const convertGeoCoordsToScreenCoords = (
  featureCoords: Position[],
  mapId: string,
): Coordinate[] => {
  const webmapjs = webmapUtils.getWMJSMapById(mapId);
  if (!webmapjs) {
    return [];
  }

  const XYCoords: Coordinate[] = [];
  for (const featureCoord of featureCoords) {
    if (featureCoord.length < 2) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const coord = getPixelCoordFromLatLong(webmapjs, {
      x: featureCoord[0],
      y: featureCoord[1],
    });
    XYCoords.push(coord);
  }
  return XYCoords;
};

export const getPixelCoordFromGeoCoord = (
  featureCoords: Position[],
  mapId: string,
): Coordinate[] => {
  const webmapjs = webmapUtils.getWMJSMapById(mapId);
  if (!webmapjs) {
    return [];
  }
  const { width, height } = webmapjs.getSize();
  const bbox = webmapjs.getDrawBBOX();
  const proj = webmapjs.getProj4();

  const XYCoords: Coordinate[] = [];

  const from = getProj4(WMJSMAP_LONLAT_EPSGCODE);
  const to = getProj4(proj.crs);
  for (const featureCoord of featureCoords) {
    if (featureCoord.length < 2) {
      // eslint-disable-next-line no-continue
      continue;
    }
    let coordinates = { x: featureCoord[0], y: featureCoord[1] };
    coordinates = proj.proj4.transform(from, to, coordinates);
    if (coordinates !== null) {
      const x =
        (width * (coordinates.x - bbox.left)) / (bbox.right - bbox.left);
      const y =
        (height * (coordinates.y - bbox.top)) / (bbox.bottom - bbox.top);
      XYCoords.push({ x, y });
    }
  }

  return XYCoords;
};

export const findClosestCoords = (
  positions: Coordinate[],
  mouseX: number,
  mouseY: number,
): number[] => {
  const thresholds = { max: 150, min: 0.5 };
  let closestIndexes: number[] = [];
  let closestDist = Infinity;

  positions.forEach((position, index) => {
    const currentDist = distance(position, { x: mouseX, y: mouseY });
    if (currentDist < thresholds.min || currentDist > thresholds.max) {
      return;
    }
    if (currentDist < closestDist) {
      closestDist = currentDist;
      closestIndexes = [index];
    } else if (currentDist === closestDist) {
      closestIndexes.push(index);
    }
  });

  return closestIndexes;
};

const checkHoverVertice = (
  feature: GeoJSON.Feature,
  mouseX: number,
  mouseY: number,
  convertGeoCoordsToScreenCoords: (featureCoords: Position[]) => Coordinate[],
  ignoreCoordinateIndexInFeature?: boolean,
  geometry: GeoJSON.Geometry = feature.geometry,
): {
  coordinateIndexInFeature: number;
  distance: number | null;
  screenCoords: { x: number; y: number };
} | null => {
  const maxDistance = 20;

  if (geometry.type === 'GeometryCollection') {
    for (const geom of geometry.geometries) {
      const result = checkHoverVertice(
        feature,
        mouseX,
        mouseY,
        convertGeoCoordsToScreenCoords,
        ignoreCoordinateIndexInFeature,
        geom,
      );
      if (result) {
        return result;
      }
    }
  }

  if (geometry.type === 'Point') {
    const featureCoords = geometry.coordinates;
    /* Get all vertexes */
    const XYCoords = convertGeoCoordsToScreenCoords([featureCoords]);
    const calculatedDistance: number | null =
      XYCoords.length > 0
        ? distance(XYCoords[0], { x: mouseX, y: mouseY })
        : null;
    if (calculatedDistance && calculatedDistance < maxDistance) {
      return {
        coordinateIndexInFeature: 0,
        distance: calculatedDistance,
        screenCoords: { ...XYCoords[0] },
      };
    }
  }
  if (geometry.type === 'MultiPoint') {
    for (
      let polygonIndex = geometry.coordinates.length - 1;
      polygonIndex >= 0;
      polygonIndex -= 1
    ) {
      const featureCoords = geometry.coordinates[polygonIndex];
      if (featureCoords === undefined) {
        // eslint-disable-next-line no-continue
        continue;
      }
      /* Get all vertexes */
      const XYCoords = convertGeoCoordsToScreenCoords([featureCoords]);
      const calculatedDistance =
        XYCoords.length > 0 && distance(XYCoords[0], { x: mouseX, y: mouseY });
      if (calculatedDistance && calculatedDistance < maxDistance) {
        return {
          coordinateIndexInFeature: polygonIndex,
          distance: calculatedDistance,
          screenCoords: { ...XYCoords[0] },
        };
      }
    }
  }

  if (geometry.type === 'Polygon') {
    const point = [mouseX, mouseY];
    for (
      let polygonIndex = geometry.coordinates.length - 1;
      polygonIndex >= 0;
      polygonIndex -= 1
    ) {
      const featureCoords = geometry.coordinates[polygonIndex];
      if (featureCoords === undefined) {
        // eslint-disable-next-line no-continue
        continue;
      }
      /* Get all vertexes */
      const poly = [
        convertGeoCoordsToScreenCoords(featureCoords).map((coord) => {
          return [coord.x, coord.y];
        }),
      ];
      try {
        const isPointInPoly = turf.booleanPointInPolygon(
          turf.point(point),
          turf.polygon(poly),
        );
        if (isPointInPoly) {
          if (ignoreCoordinateIndexInFeature) {
            return {
              coordinateIndexInFeature: 0,
              distance: 0,
              screenCoords: { x: mouseX, y: mouseY },
            };
          }

          return {
            coordinateIndexInFeature: polygonIndex,
            distance: 0,
            screenCoords: { x: mouseX, y: mouseY },
          };
        }
      } catch (e) {
        // console.warn(e);
      }
    }
  }

  if (geometry.type === 'MultiPolygon') {
    const point = [mouseX, mouseY];
    for (
      let polygonIndex = geometry.coordinates.length - 1;
      polygonIndex >= 0;
      polygonIndex -= 1
    ) {
      const featureCoords = geometry.coordinates[polygonIndex][0];
      if (featureCoords === undefined) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const poly = [
        convertGeoCoordsToScreenCoords(featureCoords).map((coord) => {
          return [coord.x, coord.y];
        }),
      ];
      try {
        const isPointInPoly = turf.booleanPointInPolygon(
          turf.point(point),
          turf.polygon(poly),
        );
        if (isPointInPoly) {
          return {
            coordinateIndexInFeature: polygonIndex,
            distance: 0,
            screenCoords: { x: mouseX, y: mouseY },
          };
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }

  if (geometry.type === 'LineString') {
    /* Get all vertexes */
    const XYCoords = convertGeoCoordsToScreenCoords(geometry.coordinates);
    /* Snap to the vertex closer than specified pixels */
    for (let j = 0; j < XYCoords.length; j += 1) {
      const coord = XYCoords[j];
      if (distance(coord, { x: mouseX, y: mouseY }) < maxDistance) {
        return {
          coordinateIndexInFeature: j,
          distance: 0,
          screenCoords: { ...coord },
        };
      }
    }
  }
  return null;
};

export const checkHoverFeatures = (
  geojson: GeoJSON.FeatureCollection,
  mouseX: number,
  mouseY: number,
  convertGeoCoordsToScreenCoords: (featureCoords: Position[]) => Coordinate[],
  ignoreCoordinateIndexInFeature?: boolean,
): CheckHoverFeaturesResult => {
  const result: CheckHoverFeaturesResult[] = [];
  for (let j = 0; j < geojson.features.length; j += 1) {
    const feature = geojson.features[j];
    const hoverResult = checkHoverVertice(
      feature,
      mouseX,
      mouseY,
      convertGeoCoordsToScreenCoords,
      ignoreCoordinateIndexInFeature,
    );
    if (hoverResult != null && hoverResult.distance != null) {
      result.push({
        coordinateIndexInFeature: hoverResult.coordinateIndexInFeature,
        featureIndex: j,
        feature,
        distance: hoverResult.distance,
        screenCoords: { ...hoverResult.screenCoords },
      });
    }
  }
  if (result.length === 0) {
    return null;
  }
  /* Sort the list, closest distance on top */
  result.sort((a: CheckHoverFeaturesResult, b: CheckHoverFeaturesResult) => {
    const distanceA = a ? a.distance : 0;
    const distanceB = b ? b.distance : 0;
    return distanceA - distanceB;
  }); // b - a for reverse sort
  return result[0];
};

export interface MapDrawDrawFunctionArgs {
  context: CanvasRenderingContext2D;
  featureIndex: number;
  coord: Coordinate;
  selected: boolean;
  isInEditMode: boolean;
  feature: GeoJSON.Feature;
  mouseX: number;
  mouseY: number;
  isHovered: boolean;
}

interface DrawFunction {
  id: string;
  drawMethod: (args: MapDrawDrawFunctionArgs) => void;
}

let generatedDrawFunctionIds = 0;
const generateDrawFunctionId = (): string => {
  generatedDrawFunctionIds += 1;
  return `drawFunctionId_${generatedDrawFunctionIds}`;
};

/**
 *  DrawFunction store for re-use of drawFunctions
 */
const drawFunctionStore: DrawFunction[] = [];

export const getDrawFunctionFromStore = (
  id: string,
): DrawFunction['drawMethod'] | undefined => {
  const drawFunction = drawFunctionStore.find(
    (drawFunction) => drawFunction.id === id,
  );

  return drawFunction?.drawMethod;
};

export const registerDrawFunction = (
  drawFunction: DrawFunction['drawMethod'] = (): void => {},
): string => {
  const id = generateDrawFunctionId();
  const newFunction = { id, drawMethod: drawFunction };
  drawFunctionStore.push(newFunction);
  return id;
};

export const NEW_LINESTRING_CREATED = 'new point in LineString created';
export const NEW_FEATURE_CREATED = 'new feature created';
export const NEW_POINT_CREATED = 'new point created';

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { metronome } from '@opengeoweb/metronome';
import {
  Dispatch,
  ListenerEffectAPI,
  createListenerMiddleware,
} from '@reduxjs/toolkit';
import { CoreAppStore } from '../../types';
import { mapActions } from './reducer';
import { mapSelectors } from '.';
import { setTimeSync } from '../../generic/synchronizationActions/actions';
import { SetTimeSyncPayload } from '../../generic/synchronizationActions/types';
import { SYNCGROUPS_TYPE_SETTIME } from '../../generic/syncGroups/constants';
import {
  getNextStep,
  getCurrentStep,
  setStep,
  prefetchAnimationTargetsForMetronome,
  handleTimerDwell,
} from './mapListenerAnimationUtils';
import * as synchronizationGroupsSelector from '../../generic/syncGroups/selectors';

/**
 * This handler is triggered by the metronome. An array of timerIds is given as argument.
 * It will update the animation loop of multiple maps and sliders
 * It will prefetch images for maps
 * @param timerIds string[] array of timerIds
 * @param listenerApi ListenerEffectAPI<CoreAppStore, Dispatch, unknown> listenerApi as received from listener
 */
const metronomeHandler = (
  timerIds: string[],
  listenerApi: ListenerEffectAPI<CoreAppStore, Dispatch, unknown>,
): void => {
  const targetsWithUpdateValue: SetTimeSyncPayload[] = [];

  for (const timerId of timerIds) {
    const animationListValuesNameAndValue = mapSelectors.getAnimationList(
      listenerApi.getState(),
      timerId,
    );

    const animationListValues = animationListValuesNameAndValue.map(
      (nameAndValue) => nameAndValue.value,
    );

    const targets = synchronizationGroupsSelector.getTargets(
      listenerApi.getState(),
      {
        sourceId: timerId,
        origin: timerId,
      },
      SYNCGROUPS_TYPE_SETTIME,
    ) as SetTimeSyncPayload[];

    if (targets.length === 0) {
      // When there are no targets, default to one
      targets.push({
        targetId: timerId,
        value: '',
      });
    }

    const timerIsInDwell = handleTimerDwell(
      timerId,
      animationListValues.length,
    );

    const timerShouldStepForward =
      prefetchAnimationTargetsForMetronome(
        timerId,
        animationListValues,
        targets,
      ) && !timerIsInDwell;

    // Determine the next step
    const timerStep = timerShouldStepForward
      ? getNextStep(timerId, animationListValues.length)
      : getCurrentStep(timerId);
    setStep(timerId, timerStep);

    const updatedValue: string = animationListValues[timerStep];

    targetsWithUpdateValue.push(
      ...targets.map((target: SetTimeSyncPayload): SetTimeSyncPayload => {
        return {
          ...target,
          value: updatedValue,
        } as SetTimeSyncPayload;
      }),
    );

    const speedDelay = mapSelectors.getMapAnimationDelay(
      listenerApi.getState(),
      timerId,
    );
    const speed = 1000 / (speedDelay || 1);
    metronome.setSpeed(timerId, speed);
  }

  // Update all targets of all sync groups in one action.
  if (targetsWithUpdateValue.length > 0) {
    listenerApi.dispatch(
      setTimeSync(null, targetsWithUpdateValue, ['metronomelistener']),
    );
  }
};

export const metronomeListener = createListenerMiddleware<CoreAppStore>();

metronomeListener.startListening({
  actionCreator: mapActions.mapStartAnimation,
  effect: async (_, listenerApi) => {
    // register handler with access to listenerApi
    metronome.handleTimerTicks = (timerIds: string[]): void => {
      metronomeHandler(timerIds, listenerApi);
    };
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createSlice,
  PayloadAction,
  Draft,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { mapUtils } from '@opengeoweb/store';
import { compact } from 'lodash';
import { TimeSeriesService } from '@opengeoweb/shared';
import { Parameter } from '../components/TimeSeries/types';
import {
  ServiceFilterChipsObject,
  SetCurrentParameterInfo,
  SetSearchFilterPayload,
  MovePlotPayload,
  SetTimeSeriesServicesPayload,
  TimeSeriesStoreType,
  ParameterManipulationPayload,
  UpdateTitlePayload,
  MoveParameterPayload,
  SetTimeSeriesServicePopupPayload,
  UpsertTimeSeriesServicePayload,
  RemoveServiceFilterChipFromStorePayload,
  RemoveTimeSeriesServicePayload,
  ParameterActionPayload,
  PlotActionPayload,
  AddPlotPayload,
  ParameterPatchPayload,
} from './types';
import { getServiceById } from '../utils/edrUtils';
// import { mockTimeSeriesServices as services } from './utils';

const patchableParameterProperties: Partial<Record<keyof Parameter, true>> = {
  color: true,
  plotType: true,
  opacity: true,
  instanceId: true,
  propertyName: true,
};
const removableParameterPropertiesOnPatch: Partial<
  Record<keyof Parameter, true>
> = {
  color: true,
  opacity: true,
  instanceId: true,
};

export const timeseriesSelectServiceFilterChipsAdapter =
  createEntityAdapter<ServiceFilterChipsObject>({
    selectId: (service) => service.serviceId!,
  });

export const initialState: TimeSeriesStoreType = {
  plotPreset: undefined,
  services: undefined,
  timeseriesSelect: {
    currentParameterInfo: undefined,
    filters: {
      searchFilter: '',
      serviceFilterChips:
        timeseriesSelectServiceFilterChipsAdapter.getInitialState(),
      allServiceFilterChipsEnabled: true,
    },
    servicePopup: {
      isOpen: false,
      variant: 'add',
    },
  },
};

const slice = createSlice({
  initialState,
  name: 'timeseries',
  reducers: {
    loadUserAddedTimeSeriesServices: (
      draft: Draft<TimeSeriesStoreType>,
      payloadAction: PayloadAction<TimeSeriesService[]>,
    ) => {
      // Make sure to initialize services to empty array (if not set)
      if (!draft.services || !draft.services.length) {
        draft.services = [];
      }

      const newServices: TimeSeriesService[] = [];

      payloadAction.payload.forEach((service) => {
        const existingService = draft.services?.find(
          (el) => el.url === service.url,
        );

        if (!existingService) {
          const newService: TimeSeriesService = {
            ...service,
            id: service.scope === 'system' ? service.id : generateServiceId(),
            scope: 'user',
          };
          newServices.push(newService);
        }
      });

      draft.services?.push(...newServices);

      // Add filterchips for any new services that were added
      timeSeriesReducer(draft, {
        payload: newServices.map(
          (service): ServiceFilterChipsObject => ({
            serviceId: service.id,
            serviceName: service.name,
            serviceUrl: service.url,
            type: service.type,
            scope: service.scope,
          }),
        ),
        type: timeSeriesActions.setServiceFilterChipsInStore,
      });
    },
    registerTimeSeriesPreset: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<TimeSeriesStoreType>,
    ) => {
      // Make sure to initialize services to empty array (if not set)
      if (!draft.services || !draft.services.length) {
        draft.services = [];
      }
      const newServicesAdded: ServiceFilterChipsObject[] = [];

      const timeSeriesPresetWithParameterIds = produce(
        action.payload,
        (draftPreset) => {
          if (!draftPreset.plotPreset) {
            console.warn('missing draft plotpresets', draftPreset);
            return;
          }
          // Make sure to initialize services to empty array (if not set)
          if (!draftPreset.services || !draftPreset.services.length) {
            draftPreset.services = [];
          }
          draftPreset.plotPreset.parameters.forEach((draftParameter) => {
            // Add parameter id to plotPreset.parameters
            draftParameter.id = generateParameterId();

            // Add opacity value to plotPreset.parameters
            draftParameter.opacity = draftParameter.opacity || 70;

            // Check if service is already in the services list
            const serviceFromStore = getServiceById(
              draft.services,
              draftParameter.serviceId,
            );
            if (!serviceFromStore) {
              // The service is not in the store. Add it based on the preset
              const serviceFromAction = getServiceById(
                draftPreset.services,
                draftParameter.serviceId,
              );
              if (!serviceFromAction) {
                console.error(
                  `A parameter from the preset has a service with id ${draftParameter.serviceId} which is not in the store or in the preset`,
                );
                return;
              }
              // Now check if the url is already in the store, it might be there with a different id
              const reUseAbleService = draft.services?.find((storeService) => {
                return storeService.url === serviceFromAction.url;
              });
              if (!reUseAbleService) {
                // The url and or serviceId were not yet in the services state
                draft.services?.push({ ...serviceFromAction, scope: 'system' });
                newServicesAdded.push({
                  serviceId: serviceFromAction.id,
                  serviceUrl: serviceFromAction.url,
                  serviceName: serviceFromAction.name,
                  type: serviceFromAction.type,
                  scope: 'system',
                });
              } else {
                // The same url is already in the store, but has the wrong id. Adjust the parameters serviceId.
                draftParameter.serviceId = reUseAbleService.id;
                // Make sure the service has correct scope as it might have been added by user previously
                reUseAbleService.scope = 'system';
              }
            }
          });
        },
      );

      // Check if all the services from the preset are now in the store. Some services might not be used in the parameter.
      timeSeriesPresetWithParameterIds?.services?.forEach(
        (serviceFromPreset) => {
          const serviceFromAction = draft.services?.find((serviceFromStore) => {
            return serviceFromPreset.url === serviceFromStore.url;
          });
          // The service from the preset was not added yet because it was not used in any parameter
          if (!serviceFromAction) {
            // Check if the id was already taken, if it was generate a new one.
            const uniqueServiceId = draft.services?.find((service) => {
              return serviceFromPreset.id === service.id;
            })
              ? generateServiceId()
              : serviceFromPreset.id;
            // Finally push the new (and unused) service to the store
            draft.services?.push({
              ...serviceFromPreset,
              id: uniqueServiceId,
              scope: 'system',
            });
            newServicesAdded.push({
              serviceId: uniqueServiceId,
              serviceUrl: serviceFromPreset.url,
              serviceName: serviceFromPreset.name,
              type: serviceFromPreset.type,
              scope: 'system',
            });
          } else {
            // Make sure the service has correct scope as it might have been added by user previously
            serviceFromAction.scope = 'system';
          }
        },
      );

      draft.plotPreset = timeSeriesPresetWithParameterIds.plotPreset;
      // Add filterchips for any new services that were added
      timeSeriesReducer(draft, {
        payload: newServicesAdded,
        type: timeSeriesActions.setServiceFilterChipsInStore,
      });
    },
    deletePlot: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<PlotActionPayload>,
    ) => {
      draft.plotPreset!.plots = draft.plotPreset!.plots.filter((plot) => {
        return plot.plotId !== action.payload.plotId;
      });
      draft.plotPreset!.parameters = draft.plotPreset!.parameters.filter(
        (parameter) => {
          return parameter.plotId !== action.payload.plotId;
        },
      );
    },
    togglePlot: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<PlotActionPayload>,
    ) => {
      draft.plotPreset!.plots = draft.plotPreset!.plots.map((plot) => {
        if (plot.plotId === action.payload.plotId) {
          const plotIsEnabled = plot.enabled !== false;
          return { ...plot, enabled: !plotIsEnabled };
        }
        return plot;
      });
    },
    addPlot: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<AddPlotPayload>,
    ) => {
      draft.plotPreset!.plots.push({
        title: action.payload.title,
        plotId: generateTimeSeriesId(),
      });
    },
    deleteParameter: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<ParameterActionPayload>,
    ) => {
      draft.plotPreset!.parameters = draft.plotPreset!.parameters.filter(
        (parameter) => {
          return parameter.id !== action.payload.parameterId;
        },
      );
    },
    addParameter: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<ParameterManipulationPayload>,
    ) => {
      draft.plotPreset!.parameters.push({
        ...action.payload.parameter,
        id: generateParameterId(),
      });
    },
    toggleParameter: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<ParameterActionPayload>,
    ) => {
      draft.plotPreset!.parameters = draft.plotPreset!.parameters.map(
        (parameter) => {
          if (parameter.id === action.payload.parameterId) {
            const parameterIsEnabled = parameter.enabled !== false;
            return { ...parameter, enabled: !parameterIsEnabled };
          }
          return parameter;
        },
      );
    },
    patchParameter: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<ParameterPatchPayload>,
    ) => {
      const payloadParameter = action.payload.parameter;
      draft.plotPreset!.parameters = draft.plotPreset!.parameters.map(
        (initialParameter): Parameter => {
          if (initialParameter.id === payloadParameter.id) {
            const patchedParameter: Parameter = { ...initialParameter };
            (Object.keys(payloadParameter) as (keyof Parameter)[]).forEach(
              (propertyName) => {
                if (
                  removableParameterPropertiesOnPatch[propertyName] &&
                  payloadParameter[propertyName] === undefined &&
                  propertyName in payloadParameter
                ) {
                  delete patchedParameter[propertyName];
                } else if (
                  patchableParameterProperties[propertyName] &&
                  payloadParameter[propertyName] !== undefined
                ) {
                  (patchedParameter as Record<keyof Parameter, unknown>)[
                    propertyName
                  ] = payloadParameter[propertyName];
                }
              },
            );
            return patchedParameter;
          }
          return initialParameter;
        },
      );
    },
    updateTitle: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<UpdateTitlePayload>,
    ) => {
      const plot = draft.plotPreset?.plots.find(
        (elem) => elem.plotId === action.payload.plotId,
      );
      if (plot) {
        plot.title = action.payload.title;
      }
    },
    setSearchFilter: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<SetSearchFilterPayload>,
    ) => {
      const { filterText } = action.payload;
      if (draft.timeseriesSelect) {
        draft.timeseriesSelect.filters.searchFilter = filterText;
      }
    },
    movePlot: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<MovePlotPayload>,
    ) => {
      const { oldIndex, newIndex } = action.payload;
      if (!draft.plotPreset?.plots) {
        return;
      }
      draft.plotPreset.plots = mapUtils.moveArrayElements(
        draft.plotPreset.plots,
        oldIndex,
        newIndex,
      );
    },

    moveParameter: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<MoveParameterPayload>,
    ) => {
      const { oldIndex, newIndex, toPlotId, plotId } = action.payload;
      if (!draft.plotPreset) {
        return;
      }
      const oldPos =
        draft.plotPreset.parameters.findIndex(
          (param) => param.plotId === plotId,
        ) + oldIndex;
      const newPos =
        draft.plotPreset.parameters.findIndex(
          (param) => param.plotId === toPlotId,
        ) + newIndex;
      if (toPlotId !== plotId) {
        draft.plotPreset.parameters[oldPos].plotId = toPlotId;
      }
      draft.plotPreset.parameters = mapUtils.moveArrayElements(
        draft.plotPreset.parameters,
        oldPos,
        newPos,
      );
    },

    // Select a filter chip - if this means all will be selected, ensure all gets set to selected
    setServiceFilterChipSelected: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<{ serviceId: string }>,
    ) => {
      const serviceFilterChipsById =
        draft.timeseriesSelect?.filters.serviceFilterChips.entities;
      if (serviceFilterChipsById) {
        const activeServices = compact(Object.values(serviceFilterChipsById));
        const countSelectedServices = activeServices.filter(
          (service) => service.enabled,
        ).length;
        const isAllServicesGoingToBeEnabled =
          countSelectedServices === activeServices.length - 1;
        if (isAllServicesGoingToBeEnabled) {
          slice.caseReducers.setAllServiceFilterChipSelected(draft);
          return;
        }

        const service = serviceFilterChipsById[action.payload.serviceId]!;
        service.enabled = true;
      }
    },
    // Select all filter chips
    setAllServiceFilterChipSelected: (draft: Draft<TimeSeriesStoreType>) => {
      if (draft.timeseriesSelect) {
        const serviceFilterChipsById =
          draft.timeseriesSelect.filters.serviceFilterChips.entities;
        const servicesToTurnOn = compact(
          Object.values(serviceFilterChipsById),
        ).filter((service) => !service.enabled);

        const updates = servicesToTurnOn.map((service) => ({
          id: service.serviceId!,
          changes: { enabled: true },
        }));
        timeseriesSelectServiceFilterChipsAdapter.updateMany(
          draft.timeseriesSelect.filters.serviceFilterChips,
          updates,
        );
        draft.timeseriesSelect.filters.allServiceFilterChipsEnabled = true;
      }
    },
    // Unselect all selected filter chips so this is the only one remaining selected
    setOnlyThisServiceFilterChipSelected: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<{ serviceId: string }>,
    ) => {
      if (draft.timeseriesSelect) {
        const serviceFilterChipsById =
          draft.timeseriesSelect.filters.serviceFilterChips.entities;

        const servicesToTurnOff = compact(
          Object.values(serviceFilterChipsById),
        ).filter((service) => service.serviceId !== action.payload.serviceId);

        const updates = servicesToTurnOff.map((service) => ({
          id: service.serviceId!,
          changes: { enabled: false },
        }));
        timeseriesSelectServiceFilterChipsAdapter.updateMany(
          draft.timeseriesSelect.filters.serviceFilterChips,
          updates,
        );

        draft.timeseriesSelect.filters.allServiceFilterChipsEnabled = false;
      }
    },
    // Unselect one filter chip - if this means all would be unselected, automatically reselect ALL
    setServiceFilterChipUnselected: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<{ serviceId: string }>,
    ) => {
      if (draft.timeseriesSelect) {
        const serviceFilterChipsById =
          draft.timeseriesSelect.filters.serviceFilterChips.entities;
        const countPressedServices = Object.values(
          serviceFilterChipsById,
        ).filter((service) => service?.enabled).length;
        const isAllServicesGoingToBeDisabled = countPressedServices === 1;
        if (isAllServicesGoingToBeDisabled) {
          slice.caseReducers.setAllServiceFilterChipSelected(draft);
          return;
        }

        const service = serviceFilterChipsById[action.payload.serviceId]!;
        service.enabled = false;
      }
    },
    setServiceFilterChipsInStore: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<TimeSeriesStoreType>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<ServiceFilterChipsObject[]>,
    ) => {
      action.payload.forEach((service) => {
        if (draft.timeseriesSelect) {
          draft.timeseriesSelect.filters.serviceFilterChips.entities[
            service.serviceId
          ] = {
            serviceId: service.serviceId,
            serviceName: service.serviceName || service.serviceId,
            enabled:
              draft.timeseriesSelect?.filters.allServiceFilterChipsEnabled,
            scope: service.scope || 'system',
            type: service.type,
            serviceUrl: service.serviceUrl,
            isLoading: false,
          };

          if (
            !draft.timeseriesSelect.filters.serviceFilterChips.ids.includes(
              service.serviceId,
            )
          ) {
            draft.timeseriesSelect.filters.serviceFilterChips.ids.push(
              service.serviceId,
            );
          }
        }
      });
    },

    removeServiceFilterChipFromStore: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<RemoveServiceFilterChipFromStorePayload>,
    ) => {
      if (draft.timeseriesSelect) {
        draft.timeseriesSelect.filters.serviceFilterChips.ids =
          draft.timeseriesSelect.filters.serviceFilterChips.ids.filter(
            (id) => id !== action.payload.id,
          );
        delete draft.timeseriesSelect.filters.serviceFilterChips.entities[
          action.payload.id
        ];
      }
    },

    setCurrentParameterInfoDisplayed: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<SetCurrentParameterInfo>,
    ) => {
      const { parameter } = action.payload;
      if (draft.timeseriesSelect) {
        draft.timeseriesSelect.currentParameterInfo = parameter;
      }
    },
    addServices: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<SetTimeSeriesServicesPayload>,
    ) => {
      if (!draft.services?.length) {
        draft.services = action.payload.timeSeriesServices;
      } else {
        const filteredServices = action.payload.timeSeriesServices.reduce(
          (acc, service) => {
            if (!draft.services!.some((s) => s.url === service.url)) {
              const hasDuplicateId = draft.services!.some(
                (s) => s.id === service.id,
              );
              if (hasDuplicateId) {
                acc.push({
                  ...service,
                  id: generateServiceId(),
                } as TimeSeriesService);
              } else {
                acc.push(service);
              }
            }
            return acc;
          },
          [] as TimeSeriesService[],
        );
        draft.services = [...draft.services, ...filteredServices];
      }
      const newServicesAdded = draft.services.map((service) => {
        return {
          serviceId: service.id,
          serviceUrl: service.url,
          serviceName: service.name,
          type: service.type,
          scope: 'system',
        };
      });
      // Add filterchips for any new services that were added
      timeSeriesReducer(draft, {
        payload: newServicesAdded,
        type: timeSeriesActions.setServiceFilterChipsInStore,
      });
    },
    upsertService: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<UpsertTimeSeriesServicePayload>,
    ) => {
      const newService: TimeSeriesService = {
        id: action.payload.id || generateServiceId(),
        description: action.payload.description,
        url: action.payload.url,
        name: action.payload.name,
        type: action.payload.type,
        scope: 'user',
      };
      if (!draft.services?.length) {
        draft.services = [newService];
      } else if (action.payload.isUpdate) {
        const index = draft.services.findIndex(
          (el) => el.id === action.payload.id,
        );
        if (index >= 0) {
          draft.services[index] = newService;
        }
      } else {
        draft.services = [...draft.services, newService];
      }

      // Add filterchips for any new services that were added
      timeSeriesReducer(draft, {
        payload: [
          {
            serviceId: newService.id,
            serviceUrl: newService.url,
            serviceName: newService.name,
            type: newService.type,
            scope: 'user',
          },
        ],
        type: timeSeriesActions.setServiceFilterChipsInStore,
      });
    },
    removeService: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<RemoveTimeSeriesServicePayload>,
    ) => {
      if (draft.services) {
        draft.services = draft.services.filter(
          (el) => el.id !== action.payload.id,
        );
        timeSeriesReducer(draft, {
          payload: action.payload,
          type: timeSeriesActions.removeServiceFilterChipFromStore,
        });
      }
    },
    closeServicePopup: (draft: Draft<TimeSeriesStoreType>) => {
      if (draft.timeseriesSelect) {
        draft.timeseriesSelect.servicePopup.isOpen = false;
      }
    },
    setServicePopup: (
      draft: Draft<TimeSeriesStoreType>,
      action: PayloadAction<SetTimeSeriesServicePopupPayload>,
    ) => {
      if (draft.timeseriesSelect) {
        draft.timeseriesSelect.servicePopup.isOpen = action.payload.isOpen;
        draft.timeseriesSelect.servicePopup.variant = action.payload.variant;
        draft.timeseriesSelect.servicePopup.url = action.payload.url || '';
        draft.timeseriesSelect.servicePopup.serviceId =
          action.payload.serviceId || '';
      }
    },
  },
});

export const { reducer: timeSeriesReducer, actions: timeSeriesActions } = slice;

const generateTimeSeriesId = (): string => {
  return `timeseriesid_${uuidv4()}`;
};

const generateParameterId = (): string => {
  return `timeseriesparamid_${uuidv4()}`;
};

export const generateServiceId = (): string => {
  return `timeseriesserviceid_${uuidv4()}`;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { MosaicBranch, MosaicNode, MosaicWindow } from 'react-mosaic-component';
import { useSelector, useDispatch } from 'react-redux';
import 'react-mosaic-component/react-mosaic-component.css';
import './WorkspaceView.css';
import { Box, LinearProgress } from '@mui/material';
import { AlertBanner, usePrevious } from '@opengeoweb/shared';
import { isEqual } from 'lodash';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import WorkspaceControls from './WorkspaceControls';
import * as workspaceSelectors from '../../store/workspace/selectors';
import { workspaceActions } from '../../store/workspace/reducer';
import { defaultComponentsLookUp } from '../DefaultComponentsLookUp';
import { AppStore } from '../../store/types';
import {
  WorkspaceLookupFunctionType,
  WorkspaceSetNewViewPayload,
} from '../../store/workspace/types';
import { getUniqueId } from './helpers';
import {
  viewPresetActions,
  viewPresetSelectors,
} from '../../store/viewPresets';
import { ViewPresetMenu } from '../ViewPresetMenu';
import {
  ToggleViewPresetDialogProps,
  WorkspaceViewTitleConnect,
} from '../WorkspaceViewTitle/WorkspaceViewTitleConnect';
import { UnregisterViewPresetPayload } from '../../store/viewPresets/types';
import { FetchViewPresetsListParams } from '../../store/viewPresetsList/types';
import { constructFilterParams } from '../../store/workspaceList/utils';

const FetchViewPresets: React.FC<{ panelId: string }> = ({ panelId }) => {
  const dispatch = useDispatch();
  const { currentRole } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;
  const scope = !isAdmin ? 'user' : 'system';
  const lastAdmin = usePrevious(isAdmin);

  const viewPresetListFilters = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetListFiltersForView(store, panelId, scope),
  );
  const viewPresetListSearchQuery = useSelector((store: AppStore) =>
    viewPresetSelectors.getViewPresetListSearchQueryForView(store, panelId),
  );

  const fetchViewPresets = React.useCallback(
    (filterParams: FetchViewPresetsListParams) => {
      dispatch(viewPresetActions.fetchViewPresets({ panelId, filterParams }));
    },
    [dispatch, panelId],
  );

  React.useEffect(() => {
    const filterParams = constructFilterParams(
      viewPresetListFilters,
      viewPresetListSearchQuery,
    );
    fetchViewPresets(filterParams);
  }, [fetchViewPresets, viewPresetListFilters, viewPresetListSearchQuery]);

  React.useEffect(() => {
    if (isAdmin !== lastAdmin && lastAdmin !== undefined) {
      // reset list filter when user changes role
      const filters = [
        {
          id: 'system',
          isSelected: true,
        },
        {
          id: 'user',
          isSelected: !isAdmin,
        },
      ];
      dispatch(viewPresetActions.setFilterChips({ panelId, filters }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  return null;
};

interface WorkspaceTileConnectProps {
  mosaicNodeId: string;
  path: MosaicBranch[];
  componentsLookUp: WorkspaceLookupFunctionType;
}

interface RegisterViewProps {
  panelId: string;
  mapPresetId: string;
}

const WorkspaceTileConnect: React.FC<WorkspaceTileConnectProps> = React.memo(
  ({
    mosaicNodeId = '',
    path,
    componentsLookUp,
  }: WorkspaceTileConnectProps) => {
    const dispatch = useDispatch();

    const view = useSelector((store: AppStore) =>
      workspaceSelectors.getViewById(store, mosaicNodeId),
    );

    const viewIds: string[] = useSelector((store: AppStore) =>
      workspaceSelectors.getViewIds(store),
    );

    const shouldPreventCloseView = useSelector((store: AppStore) =>
      workspaceSelectors.getShouldPreventClose(store, mosaicNodeId),
    );

    const isViewPresetDialogOpen = useSelector((store: AppStore) =>
      viewPresetSelectors.getIsViewPresetListDialogOpen(store, mosaicNodeId),
    );

    const isViewPresetsFetching = useSelector((store: AppStore) =>
      viewPresetSelectors.getViewPresetsIsFetching(store, mosaicNodeId),
    );

    const errorViewPreset = useSelector((store: AppStore) =>
      viewPresetSelectors.getViewPresetDetailError(store, mosaicNodeId),
    );

    const addView = React.useCallback(
      (view: WorkspaceSetNewViewPayload): void => {
        dispatch(workspaceActions.setNewWorkspaceView(view));
      },
      [dispatch],
    );

    const registerView = React.useCallback(
      ({ panelId, mapPresetId }: RegisterViewProps): void => {
        dispatch(
          viewPresetActions.registerViewPreset({
            panelId,
            viewPresetId: mapPresetId,
          }),
        );
      },
      [dispatch],
    );

    const unregisterView = React.useCallback(
      ({ panelId }: UnregisterViewPresetPayload): void => {
        dispatch(
          viewPresetActions.unregisterViewPreset({
            panelId,
          }),
        );
      },
      [dispatch],
    );

    const toggleViewPresetDialog = React.useCallback(
      ({
        panelId,
        isViewPresetDialogOpen,
      }: ToggleViewPresetDialogProps): void => {
        dispatch(
          viewPresetActions.toggleViewPresetListDialog({
            panelId,
            isViewPresetListDialogOpen: isViewPresetDialogOpen,
          }),
        );
      },
      [dispatch],
    );

    const createNode = React.useCallback((): MosaicNode<string> => {
      const newId = getUniqueId(viewIds);

      addView({
        componentType: 'Map',
        mosaicNodeId,
        newId,
      });

      return newId;
    }, [addView, mosaicNodeId, viewIds]);

    const title = view?.title || view?.id || mosaicNodeId;

    React.useEffect(() => {
      const viewPresetId = view?.id || '';
      registerView({ panelId: mosaicNodeId, mapPresetId: viewPresetId });
      return (): void => {
        unregisterView({ panelId: mosaicNodeId });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unregisterView, registerView]);

    const connectedMap = useSelector((store: AppStore) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const initialProps = view?.initialProps as any;
      const mapId = initialProps?.plotPreset?.mapId;
      if (mapId) {
        const viewPresetPanelId = viewPresetSelectors.getViewPresetPanelId(
          store,
          mapId,
        );
        return viewPresetPanelId && viewPresetPanelId.length > 0
          ? viewPresetPanelId
          : undefined;
      }
      return undefined;
    });

    // generic componentsLookUp
    const genericComponentsLookupResult = React.useMemo(
      () =>
        componentsLookUp({
          title,
          id: mosaicNodeId,
          componentType: view?.componentType!,
          initialProps: view?.initialProps,
          connectedMap,
        }),
      [
        componentsLookUp,
        connectedMap,
        mosaicNodeId,
        title,
        view?.componentType,
        view?.initialProps,
      ],
    );

    // default componentsLookUp
    const defaultComponentsLookupResult = React.useMemo(
      () =>
        defaultComponentsLookUp({
          viewPresetId: view?.id!,
          componentType: view?.componentType!,
          mosaicNodeId,
        }),
      [mosaicNodeId, view?.componentType, view?.id],
    );

    return (
      <MosaicWindow<string>
        path={path}
        createNode={createNode}
        title={
          (
            <WorkspaceViewTitleConnect
              panelId={mosaicNodeId}
              isViewPresetDialogOpen={isViewPresetDialogOpen || false}
            />
          ) as unknown as string
        }
        toolbarControls={
          <WorkspaceControls
            panelId={mosaicNodeId}
            createNode={createNode}
            path={path}
            shouldPreventCloseView={shouldPreventCloseView}
          />
        }
      >
        {isViewPresetsFetching && (
          <LinearProgress
            data-testid="loading-bar"
            color="secondary"
            sx={{ position: 'absolute', width: '100%', top: 0, zIndex: 1001 }}
          />
        )}
        {errorViewPreset && (
          <Box sx={{ position: 'absolute', left: 0, right: 0, zIndex: 1002 }}>
            <AlertBanner title={errorViewPreset} shouldClose />
          </Box>
        )}
        {/** default componentsLookUp */}
        {defaultComponentsLookupResult}
        {/** generic componentsLookUp */}
        {genericComponentsLookupResult}

        <FetchViewPresets panelId={mosaicNodeId} />
        {isViewPresetDialogOpen && (
          <ViewPresetMenu
            panelId={mosaicNodeId}
            closeMenu={(): void => {
              toggleViewPresetDialog({
                panelId: mosaicNodeId,
                isViewPresetDialogOpen: false,
              });
            }}
          />
        )}
      </MosaicWindow>
    );
  },
  isEqual,
);

export default WorkspaceTileConnect;

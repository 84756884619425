/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const COLOR_MAP = {
  air_pressure_at_sea_level: 'W',
  air_temperature_2m: 'A',
  air_temperature__at_2m: 'A',
  air_temperature__max_at_2m: 'X',
  air_temperature__min_at_2m: 'V',
  cloud_area_fraction: 'I',
  cloud_base_altitude: 'J',
  cloud_top_altitude: 'Z',
  dew_point_temperature__at_2m: 'N',
  precipitation_flux: 'H',
  precipitation_amount_acc: 'G',
  graupel_flux: 'E',
  snowfall_flux: 'D',
  high_type_cloud_area_fraction: 'W',
  medium_type_cloud_area_fraction: 'F',
  low_type_cloud_area_fraction: 'S',
  relative_humidity__at_2m: 'R',
  wind_at_10m: 'J',
  wind_speed_of_gust__at_10m: 'B',
  Pressure: 'P',
  GeopHeight: 'A',
  Temperature: 'X',
  DewPoint: 'V',
  Humidity: 'I',
  WindDirection: 'J',
  WindSpeedMS: 'Z',
  PrecipitationAmount: 'N',
  TotalCloudCover: 'H',
  MiddleAndLowCloudCover: 'E',
  LandSeaMask: 'D',
  WeatherSymbol3: 'W',
  Precipitation1h: 'F',
  WindGust: 'S',
  PrecipitationForm2: 'R',
  WindGust2: 'J',
} as const;

export const COLOR_NAME_TO_HEX_MAP = {
  A: '#C3213C',
  B: '#C726A9',
  C: '#5D3CBF',
  D: '#254BC3',
  E: '#046A9A',
  F: '#0480A3',
  G: '#0B8947',
  H: '#509A00',
  I: '#77800C',
  J: '#A36804',
  K: '#A33C04',
  L: '#A30426',
  M: '#A30445',
  N: '#F23C6C',
  O: '#F445EE',
  P: '#A86CF2',
  Q: '#4487F3',
  R: '#05BFEB',
  S: '#0AE6ED',
  T: '#12E880',
  U: '#8FEB00',
  V: '#D6E616',
  W: '#EDB605',
  X: '#ED6C06',
  Y: '#ED0845',
  Z: '#ED077C',
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { dateUtils } from '@opengeoweb/shared';
import {
  Taf,
  TafConfig,
  TafConfigLocation,
  TafConfigLocations,
  TafFormData,
  TafFromBackend,
  TafStatus,
  TimeSlot,
} from '../../types';
import tafConfig from '../../utils/tafConfig.json';
import { prepareTafValues } from '../TafForm/utils';

// TODO: should be validated
const getTafSettings = (): TafConfig => tafConfig;
const tafSettings = getTafSettings();

export const getLocationSetting = (
  location: string,
  locationSettings: TafConfigLocations = tafSettings.locations,
): TafConfigLocation => {
  return locationSettings[location];
};

export const sortTafsOnLocation = (
  tafList: TafFromBackend[] = [],
  locationOrder = tafSettings.locationOrder,
): TafFromBackend[] => {
  return [...tafList].sort(({ taf: tafA }, { taf: tafB }) =>
    locationOrder.indexOf(tafA.location) < locationOrder.indexOf(tafB.location)
      ? -1
      : 1,
  );
};

export const sortTafsOnIssueDate = (
  tafList: TafFromBackend[] = [],
): TafFromBackend[] => {
  return [...tafList].sort(({ taf: tafA }, { taf: tafB }) => {
    if (
      tafA.issueDate! < tafB.issueDate! ||
      (tafA.issueDate && !tafB.issueDate)
    ) {
      return 1;
    }
    return -1;
  });
};

export const getBaseTime = (
  baseTime: string,
  location: string,
): {
  currentBaseTime: Date;
  nextBaseTime: Date;
} => {
  const currentBaseTime = dateUtils.utc(baseTime);
  const activityPeriod = getLocationSetting(location).activity_period;
  const nextBaseTime = dateUtils.add(dateUtils.utc(baseTime), {
    hours: activityPeriod,
  });
  return { currentBaseTime, nextBaseTime };
};

export const getTafTimeSlot = (
  baseTime: string,
  location: string,
  status: TafStatus,
): TimeSlot => {
  const now = dateUtils.utc();
  const { currentBaseTime, nextBaseTime } = getBaseTime(baseTime, location);

  if (status === 'EXPIRED') {
    return 'EXPIRED';
  }
  if (
    currentBaseTime.getTime() < now.getTime() &&
    nextBaseTime.getTime() > now.getTime()
  ) {
    return 'ACTIVE';
  }
  return 'UPCOMING';
};

interface TafTimeSlots {
  current: TafFromBackend[];
  upcoming: TafFromBackend[];
  expired: TafFromBackend[];
}

export const sortTafTimeSlots = (tafList: TafFromBackend[]): TafTimeSlots => {
  const sortedListOnIssueDate = sortTafsOnIssueDate(tafList);
  const sortedListOnLocation = sortTafsOnLocation(sortedListOnIssueDate);

  return sortedListOnLocation.reduce<TafTimeSlots>(
    (sortedTafList, tafFromBackend) => {
      const { taf } = tafFromBackend;

      const timeSlot = getTafTimeSlot(taf.baseTime, taf.location, taf.status);
      if (timeSlot === 'EXPIRED') {
        sortedTafList.expired.push(tafFromBackend);
      } else if (timeSlot === 'ACTIVE') {
        sortedTafList.current.push(tafFromBackend);
      } else {
        sortedTafList.upcoming.push(tafFromBackend);
      }

      return sortedTafList;
    },
    { current: [], upcoming: [], expired: [] },
  );
};

export const muiTabA11yProps = (
  index: number,
): { id: string; 'aria-controls': string } => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const prepareTafExportValues = (
  tafToCopy: Taf,
  currentTaf: TafFormData,
): TafFormData => {
  const convertedCopy = prepareTafValues(tafToCopy, undefined!, false);
  const { baseForecast, changeGroups = [] } = convertedCopy;

  return {
    ...currentTaf,
    baseForecast: {
      ...baseForecast,
      valid: currentTaf?.baseForecast?.valid,
    },
    changeGroups,
  };
};

export const getNewTafLocationIndex = (
  tafLocations: TafFromBackend[],
  previousTafLocationUUID: string,
): number => {
  const newActiveIndexForDraft = tafLocations.findIndex(
    (location) => location?.taf?.previousId === previousTafLocationUUID,
  );
  if (newActiveIndexForDraft !== -1) {
    return newActiveIndexForDraft;
  }

  const newActiveIndexForPublished = tafLocations.findIndex(
    (location) => location?.taf?.uuid === previousTafLocationUUID,
  );
  if (newActiveIndexForPublished !== -1) {
    return newActiveIndexForPublished;
  }

  return 0;
};

export const getPreviousTaf = (
  tafList: TafFromBackend[],
  currentTaf: TafFromBackend,
): Taf => {
  const { previousId } = currentTaf.taf;
  if (!previousId) {
    return null!;
  }
  const previousTaf = tafList.find((taf) => taf.taf.uuid === previousId);
  return previousTaf ? previousTaf.taf : null!;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { webmapUtils } from '@opengeoweb/webmap';
import { TIME_SLIDER_LEGEND_HEIGHT } from '@opengeoweb/timeslider';

export const useTouchZoomPan = (mapIsActive: boolean, mapId: string): void => {
  const [firstTouchX, setFirstTouchX] = React.useState(0);
  const [firstTouchY, setFirstTouchY] = React.useState(0);
  const [secondTouchX, setSecondTouchX] = React.useState(0);
  const [secondTouchY, setSecondTouchY] = React.useState(0);
  const [prevDist, setPrevDist] = React.useState(0);

  const isPanning = (touches: TouchList): boolean => touches?.length === 1;
  const isZooming = (touches: TouchList): boolean => touches?.length === 2;
  const isCanvasElement = React.useCallback((event: TouchEvent): boolean => {
    const target = event.target as Element;
    return target.tagName === 'CANVAS';
  }, []);

  const distance = (x1: number, y1: number, x2: number, y2: number): number => {
    const dx = x1 - x2;
    const dy = y1 - y2;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const isMatchingWebMapNumbers = (
    firstStr: string,
    secondStr: string,
  ): boolean => {
    // Returns true if the same web map (id) number were encountered in both given strings, false otherwise.

    const webMapNumberBaseFormat = 'WebMapJSMapNo_';
    if (
      !firstStr.includes(webMapNumberBaseFormat) ||
      !secondStr.includes(webMapNumberBaseFormat)
    ) {
      return false;
    }

    const numberMatcher = /\d+/;
    const firstNumber = firstStr.match(numberMatcher)![0];
    const secondNumber = secondStr.match(numberMatcher)![0];
    return Boolean(firstNumber && secondNumber && firstNumber === secondNumber);
  };

  const isTouchInsideTargetElement = (
    elem: Element,
    touchX: number,
    touchY: number,
  ): boolean => {
    const { left, top, width, height } = elem.getBoundingClientRect();
    const leftMarginWidth = 0.02 * document.body.getBoundingClientRect().width;

    return (
      touchY > window.scrollY + top &&
      touchY < window.scrollY + top + height - TIME_SLIDER_LEGEND_HEIGHT &&
      touchX > window.scrollX + left + leftMarginWidth &&
      touchX < window.scrollX + left + width
    );
  };

  const handleStart = React.useCallback((event: TouchEvent) => {
    const { targetTouches } = event;
    const firstTouch = targetTouches?.item(0)!;

    setFirstTouchX(firstTouch?.clientX);
    setFirstTouchY(firstTouch?.clientY);

    if (isZooming(targetTouches)) {
      const secondTouch = targetTouches?.item(1)!;
      setSecondTouchX(secondTouch?.clientX);
      setSecondTouchY(secondTouch?.clientY);
    }
  }, []);

  const handleMove = React.useCallback(
    (event: TouchEvent) => {
      const map = webmapUtils.getWMJSMapById(mapId);
      if (!map) {
        return;
      }
      const targetElem = event.target as Element;

      if (!isCanvasElement(event)) {
        // Elements other than canvas elements are not allowed to be zoomed/panned
        return;
      }

      const targetMapId = map.getId();
      const targetElemId = targetElem.parentElement!.id;
      if (!isMatchingWebMapNumbers(targetMapId, targetElemId)) {
        // Current map (given by map id) was not coupled to the canvas element user touched.
        // This web map id number comparison is of importance when dealing with multi-window presentations.
        return;
      }

      if (!isTouchInsideTargetElement(targetElem, firstTouchX, firstTouchY)) {
        // A user touch outside the current canvas element will be rejected
        return;
      }

      // Start zooming/panning by touch

      event.preventDefault();

      const { targetTouches } = event;
      const firstTouch = targetTouches?.item(0)!;
      const firstX = firstTouch?.clientX;
      const firstY = firstTouch?.clientY;

      const elemWidth = (firstTouch.target as Element).clientWidth;
      const elemHeight = (firstTouch.target as Element).clientHeight;

      const distanceEps =
        TOUCH_PAN_STEP_PERCENTAGE * Math.min(elemWidth, elemHeight);

      if (isPanning(targetTouches)) {
        // Pan with a one-point touch

        const dist = distance(firstTouchX, firstTouchY, firstX, firstY);

        if (dist > distanceEps) {
          const distX = firstX - firstTouchX;
          const distY = firstY - firstTouchY;

          const unitDirX = distX / dist;
          const unitDirY = -distY / dist;

          setFirstTouchX(firstX);
          setFirstTouchY(firstY);

          map.mapPanPercentage(
            unitDirX * TOUCH_PAN_STEP_PERCENTAGE,
            unitDirY * TOUCH_PAN_STEP_PERCENTAGE,
          );
        }
      } else if (isZooming(targetTouches)) {
        // Zoom with a two-point "pinch" touch

        setFirstTouchX(firstX);
        setFirstTouchY(firstY);

        const secondTouch = targetTouches?.item(1)!;
        setSecondTouchX(secondTouch?.clientX);
        setSecondTouchY(secondTouch?.clientY);

        const dist = distance(
          firstTouchX,
          firstTouchY,
          secondTouchX,
          secondTouchY,
        );

        if (Math.abs(dist - prevDist) > distanceEps) {
          if (dist < prevDist) {
            map.zoomOut();
          } else if (dist > prevDist) {
            map.zoomIn(1);
          }
          setPrevDist(dist);
        }
      }
    },
    [
      mapId,
      isCanvasElement,
      firstTouchX,
      firstTouchY,
      secondTouchX,
      secondTouchY,
      prevDist,
    ],
  );

  const handleEnd = React.useCallback(() => {}, []);

  React.useEffect(() => {
    document.addEventListener('touchstart', handleStart, {
      passive: false,
    });
    document.addEventListener('touchmove', handleMove, { passive: false });
    document.addEventListener('touchend', handleEnd, { passive: false });

    return (): void => {
      document.removeEventListener('touchstart', handleStart);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [
    firstTouchX,
    firstTouchY,
    secondTouchX,
    secondTouchY,
    mapId,
    mapIsActive,
    prevDist,
    handleStart,
    handleMove,
    handleEnd,
  ]);
};

const TOUCH_PAN_STEP_PERCENTAGE = 0.02;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { LayerType, webmapUtils } from '@opengeoweb/webmap';

export const baseLayerGrey = {
  id: webmapUtils.generateLayerId(),
  name: 'WorldMap_Light_Grey_Canvas',
  type: 'twms',
  layerType: LayerType.baseLayer,
};
// Over Layers List
const KNMIgeoservicesBaselayers = {
  name: 'KNMIgeoservicesBaselayers',
  url: 'https://geoservices.knmi.nl/wms?DATASET=baselayers&',
  id: 'KNMIgeoservicesBaselayers',
};

export const overLayer = {
  service: KNMIgeoservicesBaselayers.url,
  name: 'countryborders',
  format: 'image/png',
  enabled: true,
  id: webmapUtils.generateLayerId(),
  layerType: LayerType.overLayer,
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormTextField } from '@opengeoweb/form-fields';
import { StatusTag } from '@opengeoweb/shared';

import { styles } from './EditFormFields.styles';
import { getNewInternalStatusTagContent } from '../utils';
import { getStatusTagColorFromContent } from '../../Notifications/utils';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

interface GScaleStatusTagProps {
  statusTagText: string;
  actionMode: string;
  eventTypeDisabled: boolean;
}

const GScaleStatusTag: React.FC<GScaleStatusTagProps> = ({
  statusTagText,
  actionMode,
  eventTypeDisabled,
}: GScaleStatusTagProps) => {
  const { t } = useSpaceweatherTranslation();
  const { watch } = useFormContext();

  const label = watch('label');
  const categorydetail = watch('categorydetail');

  return (
    <Grid container size={12}>
      <Grid size={10}>
        <ReactHookFormTextField
          name="initialgscale"
          sx={styles.inputField}
          inputProps={{ 'data-testid': 'initialgscale-input' }}
          label={t('notification-g-scale')}
          rules={{
            required: true,
          }}
        />
      </Grid>
      {eventTypeDisabled && (
        <Grid data-testid="notification-tag" size={2}>
          <StatusTag
            content={getNewInternalStatusTagContent(
              actionMode,
              label,
              categorydetail,
            )}
            color={getStatusTagColorFromContent(statusTagText)}
            sx={{ width: 'auto' }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default GScaleStatusTag;

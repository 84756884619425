/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { useMemo } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  mapActions,
  uiActions,
  uiTypes,
  mapTypes,
  layerTypes,
  defaultLayers,
  getSingularDrawtoolDrawLayerId,
  mapSelectors,
  CoreAppStore,
  layerActions,
} from '@opengeoweb/store';
import { LayerType, TileServerSettings, webmapUtils } from '@opengeoweb/webmap';
import { emptyGeoJSON } from '@opengeoweb/webmap-react';
import { PROJECTION, renderCounter } from '@opengeoweb/shared';
import { MapViewConnect } from '../MapViewConnect';
import { TimeSliderConnect } from '../TimeSliderConnect';
import { TimeSliderClockConnect } from '../TimeSliderClockConnect';
import { MapControls } from '../MapViewConnect/MapViewConnect';

const titleStyle = (theme: Theme): SxProps<Theme> => ({
  position: 'absolute',
  padding: '5px',
  zIndex: 50,
  color: theme.palette.common.black,
  whiteSpace: 'nowrap',
  userSelect: 'none',
  textShadow:
    '1px 1px 2px  #ffffffE6, -1px 1px 2px #ffffffE6, -1px -1px 2px  #ffffffE6, 1px -1px 2px #ffffffE6',
});

export const defaultBbox = {
  srs: PROJECTION.EPSG_3857.value,
  bbox: {
    left: 58703.6377,
    bottom: 6408480.4514,
    right: 3967387.5161,
    top: 11520588.9031,
  },
};

export interface ConfigurableMapConnectProps {
  id?: string;
  dockedLayerManagerSize?: mapTypes.DockedLayerManagerSize;
  shouldAutoUpdate?: boolean;
  shouldAnimate?: boolean;
  shouldAutoFetch?: boolean | number;
  title?: string;
  layers: layerTypes.Layer[];
  tileServerSettings?: TileServerSettings;
  autoUpdateLayerId?: string;
  autoTimeStepLayerId?: string;
  bbox?: mapTypes.Bbox;
  srs?: string;
  dimensions?: mapTypes.Dimension[];
  animationPayload?: mapTypes.AnimationPayloadType;
  shouldShowZoomControls?: boolean;
  displayMapPin?: boolean;
  showTimeSlider?: boolean; // used for map preset action
  disableTimeSlider?: boolean; // used by multimap to disable timeslider completely
  toggleTimestepAuto?: boolean;
  displayTimeInMap?: boolean;
  displayLayerManagerAndLegendButtonInMap?: boolean;
  displayDimensionSelectButtonInMap?: boolean;
  multiLegend?: boolean;
  shouldShowLayerManager?: boolean;
  shouldShowDockedLayerManager?: boolean;
  showClock?: boolean;
  displayGetFeatureInfoButtonInMap?: boolean;
  shouldDisplayDrawControls?: boolean;
  displaySearchButtonInMap?: boolean;
  children?: React.ReactNode;
  mapControls?: React.ReactNode;
  shouldDisablePrefetching?: boolean;
}

export const ConfigurableMapConnect: React.FC<ConfigurableMapConnectProps> = ({
  id,
  dockedLayerManagerSize,
  title,
  layers = [],
  dimensions = [],
  shouldAutoUpdate = false,
  shouldAnimate = false,
  shouldAutoFetch = true,
  bbox = defaultBbox.bbox,
  srs = defaultBbox.srs,
  shouldShowZoomControls = true,
  displayMapPin = false,
  showTimeSlider = true,
  disableTimeSlider = false,
  displayTimeInMap = false,
  displayLayerManagerAndLegendButtonInMap = true,
  displayDimensionSelectButtonInMap = true,
  multiLegend = true,
  shouldShowLayerManager,
  shouldShowDockedLayerManager,
  showClock = true,
  displayGetFeatureInfoButtonInMap = false,
  shouldDisplayDrawControls = false,
  displaySearchButtonInMap = false,
  mapControls,
  children,
  shouldDisablePrefetching,
  tileServerSettings,
  ...props
}: ConfigurableMapConnectProps) => {
  const dispatch = useDispatch();
  const mapId = React.useRef(id || webmapUtils.generateMapId()).current;

  const customLayers = useSelector((store: CoreAppStore) =>
    mapSelectors.getdefaultMapSettingsLayers(store),
  );

  const mapWindowRef = React.useRef(null);

  React.useEffect(() => {
    const addBaselayer = !layers?.find(
      (layer) => layer.layerType === LayerType.baseLayer,
    );
    const defaultBaseLayer =
      customLayers?.find((layer) => layer.layerType === LayerType.baseLayer) ||
      defaultLayers.baseLayerGrey;

    const addOverlayer = !layers?.find(
      (layer) => layer.layerType === LayerType.overLayer,
    );
    const defaultOverLayer =
      customLayers?.find((layer) => layer.layerType === LayerType.overLayer) ||
      defaultLayers.overLayer;

    const updatedLayers = [
      ...(layers || []),
      ...(addBaselayer ? [defaultBaseLayer] : []),
      ...(addOverlayer ? [defaultOverLayer] : []),
    ];

    const mapPreset = {
      layers: updatedLayers,
      proj: {
        bbox,
        srs,
      },
      dimensions,
      shouldAutoUpdate,
      shouldAnimate,
      shouldShowZoomControls,
      displayMapPin,
      showTimeSlider,
      dockedLayerManagerSize,
      ...props,
    };

    const initialProps: mapTypes.MapPresetInitialProps = { mapPreset };

    dispatch(mapActions.setMapPreset({ mapId, initialProps }));

    if (shouldShowLayerManager !== undefined) {
      dispatch(
        uiActions.setActiveMapIdForDialog({
          type: uiTypes.DialogTypes.LayerManager,
          mapId,
          setOpen: shouldShowLayerManager,
          source: 'app',
        }),
      );
    }
    if (shouldShowDockedLayerManager) {
      dispatch(
        uiActions.setToggleOpenDialog({
          type: uiTypes.DialogTypes.LayerManager,
          mapId,
          setOpen: false,
        }),
      );
      dispatch(
        uiActions.setToggleOpenDialog({
          type: `${uiTypes.DialogTypes.DockedLayerManager}-${mapId}`,
          mapId,
          setOpen: true,
        }),
      );
    }
    if (shouldDisplayDrawControls) {
      dispatch(
        layerActions.addLayer({
          mapId,
          layer: {
            geojson: emptyGeoJSON,
            layerType: LayerType.featureLayer,
          },
          layerId: getSingularDrawtoolDrawLayerId(mapId),
          origin: 'ConfigurableMapConnect',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapControlConfig = useMemo(
    (): MapControls => ({
      mapControlsPositionTop: title ? 24 : 8,
      search: displaySearchButtonInMap,
      zoomControls: shouldShowZoomControls,
      layerManagerAndLegend: displayLayerManagerAndLegendButtonInMap,
      multiLegend,
      dimensionSelect: displayDimensionSelectButtonInMap,
      getFeatureInfo: displayGetFeatureInfoButtonInMap,
      additionalMapControls: mapControls,
    }),
    [
      title,
      displaySearchButtonInMap,
      shouldShowZoomControls,
      displayLayerManagerAndLegendButtonInMap,
      multiLegend,
      displayDimensionSelectButtonInMap,
      displayGetFeatureInfoButtonInMap,
      mapControls,
    ],
  );

  return React.useMemo(() => {
    renderCounter.count(`${ConfigurableMapConnect.name}_${mapId}`);
    return (
      <Box
        ref={mapWindowRef}
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
        data-testid="ConfigurableMap"
      >
        {title && (
          <Typography data-testid="mapTitle" sx={titleStyle as SxProps<Theme>}>
            {title}
          </Typography>
        )}
        {!disableTimeSlider && (
          <Box
            sx={{
              position: 'absolute',
              left: '0px',
              bottom: '0px',
              zIndex: 1000,
              width: '100%',
            }}
          >
            <TimeSliderConnect
              mapId={id!}
              sourceId={id!}
              mapWindowRef={mapWindowRef}
            />
          </Box>
        )}

        <MapViewConnect
          controls={mapControlConfig}
          displayTimeInMap={displayTimeInMap}
          showScaleBar={false}
          shouldAutoFetch={shouldAutoFetch}
          mapId={mapId}
          shouldDisablePrefetching={shouldDisablePrefetching}
          tileServerSettings={tileServerSettings}
        >
          {children}
        </MapViewConnect>
        {showClock && <TimeSliderClockConnect mapId={mapId} />}
      </Box>
    );
  }, [
    mapId,
    title,
    id,
    mapControlConfig,
    tileServerSettings,
    disableTimeSlider,
    displayTimeInMap,
    shouldAutoFetch,
    shouldDisablePrefetching,
    children,
    showClock,
  ]);
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { FilterOption } from '@opengeoweb/shared';
import { layerSelectActions, layerSelectTypes } from '../../store';

export interface KeywordFilterResultsListItemConnectProps {
  filter: layerSelectTypes.Filter;
}

export const KeywordFilterResultsListItemConnect: React.FC<
  KeywordFilterResultsListItemConnectProps
> = ({ filter }: KeywordFilterResultsListItemConnectProps) => {
  const dispatch = useDispatch();

  const toggleFilter = React.useCallback(
    (filterId: string, only?: boolean) => {
      dispatch(layerSelectActions.toggleFilter({ filterId, only }));
    },
    [dispatch],
  );

  return (
    <FilterOption
      optionId={filter.id}
      checked={!!filter.checked}
      onClick={(checked: boolean, only?: boolean) => {
        toggleFilter(filter.id, only);
      }}
      label={
        filter.amountVisible
          ? `${filter.name} (${filter.amountVisible})`
          : filter.name
      }
    />
  );
};

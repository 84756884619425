/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { MapDrawDrawFunctionArgs } from '@opengeoweb/webmap-react';
import { TimeSeriesService } from '@opengeoweb/shared';
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  GeometryObject,
  Point,
} from 'geojson';
import { produce } from 'immer';
import { GeoJsonWithService, TimeSeriesPresetLocation } from './types';

export const createGeojson = (
  timeSeriesPresetLocations: TimeSeriesPresetLocation[],
  circleDrawFunctionId: string,
  hoverDrawFunctionId: string,
): FeatureCollection<Point> => {
  const features = timeSeriesPresetLocations.map(
    ({ name, lat, lon }): Feature<Point> => {
      return {
        type: 'Feature',
        properties: {
          name,
          drawFunctionId: circleDrawFunctionId,
          hoverDrawFunctionId,
        },
        geometry: {
          type: 'Point',
          coordinates: [lon, lat],
        },
      };
    },
  );

  return {
    type: 'FeatureCollection',
    features,
  };
};

export const addDrawFunctionToGeojson = (
  geojson: FeatureCollection,
  circleDrawFunctionId: string,
  selectedCircleDrawFunctionId: string,
  hoverDrawFunctionId: string,
  selectedFeatureIndex?: number,
): FeatureCollection => {
  const geojsonWithDraw = produce(geojson, (draft) => {
    /* Set default drawfunction for each feature */
    draft.features.forEach((feature) => {
      // eslint-disable-next-line no-param-reassign
      feature.properties!.drawFunctionId = circleDrawFunctionId;
      // eslint-disable-next-line no-param-reassign
      feature.properties!.hoverDrawFunctionId = hoverDrawFunctionId;
    });

    /* Change drawfunction for selected feature */
    if (selectedFeatureIndex !== undefined) {
      draft.features[selectedFeatureIndex].properties!.drawFunctionId =
        selectedCircleDrawFunctionId;
    }
  });
  return geojsonWithDraw;
};

export const fitText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  lineheight: number,
): void => {
  const lines = text.split('\n');

  for (let i = 0; i < lines.length; i += 1) {
    ctx.fillText(lines[i], x, y + i * lineheight);
  }
};

export const roundRect = (
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  x: number,
  y: number,
  radius = 2,
): void => {
  const r = x + w;
  const b = y + h;
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(r - radius, y);
  ctx.quadraticCurveTo(r, y, r, y + radius);
  ctx.lineTo(r, y + h - radius);
  ctx.quadraticCurveTo(r, b, r - radius, b);
  ctx.lineTo(x + radius, b);
  ctx.quadraticCurveTo(x, b, x, b - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.stroke();
  ctx.fill();
};
export const drawFunctionShowHoveredInfo = (
  args: MapDrawDrawFunctionArgs,
): void => {
  const { context: ctx, coord, feature, selected } = args;
  const fontSize = 16;
  const padding = 20;
  // eslint-disable-next-line no-param-reassign
  ctx.font = `${fontSize}px Roboto`;
  const { x, y } = coord;
  circleDrawFunction(args, selected ? '#F00' : '#AAF', 8, 4, true);
  roundRect(
    ctx,
    ctx.measureText(feature.properties!.name).width + padding,
    fontSize + padding,
    x - padding * 0.5 - ctx.measureText(feature.properties!.name).width * 0.5,
    y + padding,
  );
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = '#FFF';
  // eslint-disable-next-line no-param-reassign
  ctx.textAlign = 'left';
  if (feature && feature.properties) {
    fitText(
      ctx,
      feature.properties.name,
      x - ctx.measureText(feature.properties.name).width * 0.5,
      y + padding * 2,
      fontSize,
    );
  }
};

export const circleDrawFunction = (
  args: MapDrawDrawFunctionArgs,
  fillColor = '#8888FF66',
  radius = 8,
  innerCircleRadius = 2,
  isHovering = false,
): void => {
  const { context: ctx, coord } = args;
  ctx.strokeStyle = '#000';
  ctx.fillStyle = fillColor;
  // Draw outer circle
  ctx.beginPath();
  ctx.arc(coord.x, coord.y, radius || 10, 0, 2 * Math.PI);
  ctx.fill();
  if (isHovering) {
    ctx.stroke();
  }
  ctx.fillStyle = '#000';
  // Draw inner circle
  ctx.beginPath();
  ctx.arc(coord.x, coord.y, innerCircleRadius, 0, innerCircleRadius * Math.PI);
  ctx.fill();
};

export const createGeoJsonArrays = (
  locations: (
    | FeatureCollection<GeometryObject, GeoJsonProperties>
    | undefined
  )[],
  services: TimeSeriesService[] | undefined,
  isOgc?: string,
): {
  combinedGeoJson: FeatureCollection<GeometryObject, GeoJsonProperties>;
  splitGeoJsonByCollection: GeoJsonWithService[];
} => {
  const initialAccumulator: {
    combinedGeoJson: FeatureCollection<GeometryObject>;
    splitGeoJsonByCollection: GeoJsonWithService[];
    collectionMap: Record<string, GeoJsonWithService>;
  } = {
    combinedGeoJson: {
      type: 'FeatureCollection',
      features: [],
    },
    splitGeoJsonByCollection: [],
    collectionMap: {},
  };

  const { combinedGeoJson, splitGeoJsonByCollection } = locations.reduce(
    (acc, location) => {
      if (!location) {
        return acc;
      }

      location.features.forEach((feature) => {
        if (!feature.geometry) {
          return;
        }
        const serviceName = isOgc
          ? 'ogc'
          : services?.find(
              (serviceObject) =>
                serviceObject.id === feature?.properties?.serviceId,
            )?.name;
        const collectionName = isOgc ? 'ogc' : feature.properties?.collectionId;

        acc.combinedGeoJson.features.push(feature);

        if (serviceName && collectionName) {
          if (!acc.collectionMap[collectionName]) {
            const newGeoJsonWithService: GeoJsonWithService = {
              collectionName,
              serviceName,
              geoJson: {
                type: 'FeatureCollection',
                features: [],
              },
            };

            // eslint-disable-next-line no-param-reassign
            acc.collectionMap[collectionName] = newGeoJsonWithService;
            acc.splitGeoJsonByCollection.push(newGeoJsonWithService);
          }

          acc.collectionMap[collectionName].geoJson.features.push(feature);
        }
      });

      return acc;
    },
    initialAccumulator,
  );

  return { combinedGeoJson, splitGeoJsonByCollection };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Weather, WeatherStrings } from '../../../../types';
import { WeatherPhenomena } from '../../../../utils/weatherPhenomena';
import { getFieldValue } from './utils';

// messages
export const invalidWeatherMessage =
  'Invalid weather input, expected NSW or a valid weather code';

export const invalidWeatherCodeMessage =
  'Invalid weather input, invalid weather code';

export const invalidWeatherNSWMessage =
  'Invalid weather input, NSW is not allowed in the baseforecast and can only be used in the first weather group';

export const invalidWeatherWithPreviousEmptyWeather =
  'Invalid weather input, value is not allowed when previous weather group(s) are empty';

export const invalidWeatherNonUniqueValue =
  'Invalid weather input, weather groups must be unique';

export const invalidWeatherNonEmptyNSWGroups =
  'Invalid weather input, when NSW is used, other weather groups must be empty';

export const invalidWeatherCAVOKMessage =
  'Invalid weather input, when CAVOK is used, weather groups must be empty';

export const invalidWeatherVisibilityBelow5000 =
  'Invalid weather input, weather codes FU, DU, HZ, DRDU and BLDU need to be combined with a visibility of 5000m or less';

export const invalidWeatherBRVisibility =
  'Invalid weather input, weather code BR needs to be combined with a visibility between 1000 and 5000m';

export const invalidWeatherFGVisibility =
  'Invalid weather input, weather code FG needs to be combined with a visibility below 1000m';

// validations
const WeatherVisibilityBelow5000 = [
  WeatherPhenomena.FU,
  WeatherPhenomena.DU,
  WeatherPhenomena.HZ,
  WeatherPhenomena.DRDU,
  WeatherPhenomena.BLDU,
];

export const validateWeatherField = (
  value: string,
  visibilityFieldValue: string,
  NSWAllowed = true,
): boolean | string => {
  const trimmedValue = getFieldValue(value);
  const visibilityValue = getFieldValue(visibilityFieldValue);

  // if empty, validate as true
  if (trimmedValue === '') {
    return true;
  }

  // In case of NSW - validate if allowed
  if (trimmedValue === 'NSW' && !NSWAllowed) {
    return invalidWeatherNSWMessage;
  }

  // Check if cavOK - then weather group should be empty
  if (trimmedValue && visibilityValue === 'CAVOK') {
    return invalidWeatherCAVOKMessage;
  }

  // Validate if entered weather is one of the allowed weather phenomena
  if (!(trimmedValue in WeatherPhenomena) && trimmedValue !== 'NSW') {
    return NSWAllowed ? invalidWeatherMessage : invalidWeatherCodeMessage;
  }

  // Validate the weather code in combination with the visibility range
  const visibilityRange = parseInt(visibilityValue, 10);
  if (
    WeatherVisibilityBelow5000.includes(trimmedValue as WeatherPhenomena) &&
    visibilityRange > 5000
  ) {
    return invalidWeatherVisibilityBelow5000;
  }

  if (
    trimmedValue === WeatherPhenomena.BR &&
    (visibilityRange < 1000 || visibilityRange > 5000)
  ) {
    return invalidWeatherBRVisibility;
  }

  if (trimmedValue === WeatherPhenomena.FG && visibilityRange >= 1000) {
    return invalidWeatherFGVisibility;
  }

  // Everything validated ok - return true
  return true;
};

export const validateSecondWeatherField = (
  weather1Value: string,
  weather2Value: string,
  visibilityFieldValue: string,
): boolean | string => {
  const trimmedWeather2Value = getFieldValue(weather2Value);
  const visibilityValue = getFieldValue(visibilityFieldValue);

  // Field can be left empty
  if (!trimmedWeather2Value.length) {
    return true;
  }

  const trimmedWeather1Value = getFieldValue(weather1Value);

  // If weather1 is NSW weather2 should be empty
  if (trimmedWeather1Value === 'NSW' && trimmedWeather2Value !== '') {
    return invalidWeatherNonEmptyNSWGroups;
  }

  // If weather1 is empty weather2 should be empty
  if (trimmedWeather1Value === '') {
    return invalidWeatherWithPreviousEmptyWeather;
  }

  // If weather1 must be different from weather2
  if (trimmedWeather1Value === trimmedWeather2Value) {
    return invalidWeatherNonUniqueValue;
  }

  return validateWeatherField(weather2Value, visibilityValue, false);
};

export const validateThirdWeatherField = (
  weather1Value: string,
  weather2Value: string,
  weather3Value: string,
  visibilityFieldValue: string,
): boolean | string => {
  const trimmedWeather3Value = getFieldValue(weather3Value);
  const visibilityValue = getFieldValue(visibilityFieldValue);

  // Field can be left empty
  if (!trimmedWeather3Value.length) {
    return true;
  }

  const trimmedWeather1Value = getFieldValue(weather1Value);
  const trimmedWeather2Value = getFieldValue(weather2Value);

  // If weather1 is NSW weather3 should be empty
  if (trimmedWeather1Value === 'NSW' && trimmedWeather3Value !== '') {
    return invalidWeatherNonEmptyNSWGroups;
  }

  // If weather1 or weather2 is empty weather3 should be empty
  if (trimmedWeather1Value === '' || trimmedWeather2Value === '') {
    return invalidWeatherWithPreviousEmptyWeather;
  }

  // If weather3 must be different from weather1 and weather2
  if (
    trimmedWeather1Value === trimmedWeather3Value ||
    trimmedWeather2Value === trimmedWeather3Value
  ) {
    return invalidWeatherNonUniqueValue;
  }

  return validateWeatherField(weather3Value, visibilityValue, false);
};

export function parseWeatherToObject(value: WeatherStrings): Weather {
  const trimmedWeather = (Object.keys(value) as (keyof Weather)[]).reduce(
    (obj, key) => {
      const trimmedValue = value[key] && String(value[key]).trim();
      if (trimmedValue) {
        return { ...obj, [key]: trimmedValue };
      }
      return obj;
    },
    {} as Weather,
  );

  return trimmedWeather;
}

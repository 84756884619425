/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { isEqual } from 'lodash';
import { dateUtils } from '@opengeoweb/shared';
import { Polygon, Position } from 'geojson';

export const defaultProps = {
  shouldUnregister: false,
};

export const errorMessages = {
  required: 'form-fields-error-required',
  isValidDate: 'form-fields-error-date-valid',
  isAfter: 'form-fields-error-date-after',
  isXHoursBefore: 'form-fields-error-date-hours-before',
  isXHoursAfter: 'form-fields-error-date-hours-after',
  isBefore: 'form-fields-error-date-before',
  minLevel: 'form-fields-error-min-level',
  isLatitude: 'form-fields-error-latitude',
  isLongitude: 'form-fields-error-longitude',
  isValidMax: 'form-fields-error-valid-max',
  isLevelLower: 'form-fields-error-level-lower',
  isNonOrBothCoordinates: 'form-fields-error-non-or-both-cooordinate',
  isInteger: 'form-fields-error-integer',
  isNumeric: 'form-fields-error-numeric',
};

const regexMaxTwoDecimals = /^\s*-?\d+(\.\d{1,2})?\s*$/;

// validations
export const isEmpty = (value?: number | string | null): boolean => {
  if (
    (typeof value === 'string' && !value.trim().length) ||
    value === null ||
    value === undefined
  ) {
    return true;
  }
  return false;
};

export const isXHoursBefore = (
  ownDate: string,
  otherDate: string,
  hours = 4,
): boolean => {
  if (isEmpty(ownDate) || isEmpty(otherDate)) {
    return true;
  }
  const duration = dateUtils.differenceInHours(
    dateUtils.utc(otherDate),
    dateUtils.utc(ownDate),
    'ceil',
  );
  return duration <= hours;
};

export const isXHoursAfter = (
  ownDate: string,
  otherDate: string,
  hours = 4,
): boolean => {
  if (isEmpty(ownDate) || isEmpty(otherDate)) {
    return true;
  }
  const duration = dateUtils.differenceInHours(
    dateUtils.utc(ownDate),
    dateUtils.utc(otherDate),
    'ceil',
  );
  return duration <= hours;
};

export const isLatitude = (lat?: number | null): boolean => {
  if (!lat) {
    return true;
  }
  return (
    isFinite(lat) &&
    Math.abs(lat) <= 90 &&
    regexMaxTwoDecimals.test(lat.toString())
  );
};

export const isLongitude = (lng?: number | null): boolean => {
  if (!lng) {
    return true;
  }
  return (
    isFinite(lng) &&
    Math.abs(lng) <= 180 &&
    lng >= -180 &&
    regexMaxTwoDecimals.test(lng.toString())
  );
};

// Pass null if no maxValue
export const isValidMax = (value: number, maxValue: number | null): boolean => {
  if (isEmpty(value) || maxValue === null) {
    return true;
  }
  return !(value > maxValue);
};

// Pass null if no minValue
export const isValidMin = (value: number, minValue: number | null): boolean => {
  if (isEmpty(value) || minValue === null) {
    return true;
  }
  return !(value < minValue);
};

export const hasValidGeometry = (
  geojson?: GeoJSON.FeatureCollection | null,
): boolean => {
  if (
    !geojson ||
    !geojson.features ||
    !geojson.features.length ||
    !geojson.features[0].geometry ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !geojson.features[0].geometry.coordinates ||
    !geojson.features[0].geometry.type
  ) {
    return false;
  }
  return true;
};

export const isValidGeoJsonCoordinates = (
  geojson: GeoJSON.FeatureCollection,
): boolean => {
  const hasGeometry = hasValidGeometry(geojson);
  if (!hasGeometry) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { coordinates, type } = geojson.features[0].geometry;
  // For type POINT coordinates is an array of 2
  if (coordinates.length === 2 && type === 'Point') {
    return true;
  }
  // For type POLYGON coordinates is an array of arrays
  if (
    coordinates.length === 1 &&
    coordinates[0].length > 0 &&
    type === 'Polygon'
  ) {
    return true;
  }
  return false;
};

export const isMaximumOneDrawing = (
  geojson: GeoJSON.FeatureCollection,
): boolean => {
  const hasGeometry = hasValidGeometry(geojson);
  if (!hasGeometry) {
    return true; // no need to check further when there is no drawing yet
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { coordinates, type } = geojson.features[0].geometry;
  // For type POLYGON only one array of coordinates is allowed
  if (type === 'Polygon' && coordinates.length > 1) {
    return false;
  }

  return true;
};

export const hasIntersectionWithFIR = (
  geojson: GeoJSON.FeatureCollection,
  intersection: GeoJSON.FeatureCollection,
): boolean => {
  if (
    !hasValidGeometry(geojson) ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !geojson.features[0].geometry.coordinates.length ||
    (geojson.features[0].geometry.type === 'Polygon' &&
      !geojson.features[0].geometry.coordinates[0].length)
  ) {
    return true; // no need to check further when there is no drawing yet
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { coordinates, type } = intersection.features[0].geometry;

  if (type === 'Point' && coordinates.length === 0) {
    return false;
  }

  if (
    type !== 'Point' &&
    coordinates.length > 0 &&
    coordinates[0].length === 0
  ) {
    return false;
  }

  return true;
};

export const hasMulitpleIntersections = (
  geojson: GeoJSON.FeatureCollection,
): boolean => {
  const hasGeometry = hasValidGeometry(geojson);
  if (!hasGeometry) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { coordinates, type } = geojson.features[0].geometry;

  if (type === 'MultiPolygon' || (coordinates.length > 1 && type !== 'Point')) {
    return true;
  }

  return false;
};

export const countIntersectionPoints = (
  geojson: GeoJSON.FeatureCollection<Polygon>,
  intersection: GeoJSON.FeatureCollection<Polygon>,
): number => {
  if (
    !geojson?.features ||
    !geojson.features[0]?.geometry ||
    !intersection?.features ||
    !intersection.features[0]?.geometry
  ) {
    return 0;
  }

  const geojsonGeometry = geojson.features[0].geometry;
  const intersectionGeometry = intersection.features[0].geometry;

  if (
    geojsonGeometry.type !== 'Polygon' ||
    intersectionGeometry.type !== 'Polygon'
  ) {
    return 0;
  }

  const geojsonCoordinates = geojsonGeometry.coordinates[0];
  const intersectionCoordinates = intersectionGeometry.coordinates[0];

  // If both arrays are empty, return 0
  if (!geojsonCoordinates && !intersectionCoordinates) {
    return 0;
  }

  const intersectionPoints = intersectionCoordinates.map(
    (point: Position) => [point[0], point[1]] as [number, number],
  );

  const uniqueIntersectionPoints = intersectionPoints.filter(
    (point, index, self) =>
      index === self.findIndex((p) => p[0] === point[0] && p[1] === point[1]),
  );

  const collisionPoints = uniqueIntersectionPoints.filter(
    (point: [number, number]) => {
      const [x, y] = point;
      return !geojsonCoordinates.some(
        (originalPoint: Position) =>
          originalPoint[0] === x && originalPoint[1] === y,
      );
    },
  );

  return collisionPoints.length;
};

export const isGeometryDirty = (
  geoJSON?: GeoJSON.FeatureCollection | null,
  formGeoJSON?: GeoJSON.FeatureCollection | null,
): boolean => {
  if (
    !geoJSON ||
    !formGeoJSON ||
    !hasValidGeometry(geoJSON) ||
    !hasValidGeometry(formGeoJSON)
  ) {
    return false;
  }

  return !isEqual(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    geoJSON.features[0].geometry.coordinates,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formGeoJSON.features[0].geometry.coordinates,
  );
};

export const isInteger = (value: number): boolean => {
  if (isEmpty(value)) {
    return true;
  }
  return Number.isInteger(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isNumeric = (value: any): boolean =>
  !isNaN(parseFloat(value)) && isFinite(value);

export const isNonOrBothCoordinates = (
  coordinate?: number | null,
  otherCoordinate?: number | null,
): boolean => {
  if (
    (typeof coordinate === 'number' && typeof otherCoordinate === 'number') ||
    (coordinate === null && otherCoordinate === null) ||
    (coordinate === undefined && otherCoordinate === undefined)
  ) {
    return true;
  }
  if (typeof coordinate === 'number') {
    return true;
  }

  return false;
};

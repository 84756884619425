/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2025 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2025 - Finnish Meteorological Institute (FMI)
 * Copyright 2025 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { getConfig } from '@opengeoweb/authentication';
import { handleResponse } from '@opengeoweb/api';
import {
  LocationListResult,
  LocationDetail,
} from '../../components/Search/types';

const config = getConfig();

const locationBaseUrl = config.GW_LOCATION_BASE_URL || '';

export const getLocationList = (
  search: string,
): Promise<LocationListResult[]> =>
  fetch(`${locationBaseUrl}/locations?search=${search}`).then(handleResponse);

export const getLocationDetail = (
  id: string,
  source: string,
): Promise<LocationDetail> =>
  fetch(`${locationBaseUrl}/locationdetail?id=${id}&source=${source}`).then(
    handleResponse,
  );

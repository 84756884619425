/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { ConfigType } from '@opengeoweb/shared';
import { getSessionStorageProvider } from '@opengeoweb/authentication';

import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import i18n, { initGeowebI18n } from './i18n';
import App from './app/app';
import {
  initializeSentry,
  initializeSessionStorage,
} from './initiationFunctions';
import { fetchFile } from './app/utils/loadConfig';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      placeholderData: keepPreviousData,
      staleTime: 1000 * 60 * 5,
    },
  },
});

const initializeApp = async (): Promise<void> => {
  try {
    const config = await fetchFile<ConfigType>('config.json').catch(() => {
      return {} as ConfigType;
    });

    if (config.GW_FEATURE_INITIALIZE_SENTRY) {
      initializeSentry();
    }

    initGeowebI18n();

    await initializeSessionStorage();
    getSessionStorageProvider().setConfig(config);

    const container = document.getElementById('app');
    const root = createRoot(container!);
    root.render(
      <React.StrictMode>
        <QueryClientProvider client={client}>
          <I18nextProvider i18n={i18n}>
            <App configObject={config} />
          </I18nextProvider>
        </QueryClientProvider>
      </React.StrictMode>,
    );
  } catch (error) {
    console.error('Error loading config: ', error);
  }
};

await initializeApp();

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CSSProperties, Elevations, GeowebColorPalette } from '../../../types';

const focusButtonStyle: CSSProperties = {
  outline: 'solid 2px #419cfe',
};

// https://app.zeplin.io/project/5ecf84a3c6ae1047a368f393/screen/6093e5dc12278e35d433aa2d
export const colors: GeowebColorPalette = {
  background: {
    surface: '#303030',
    surfaceApp: '#2B2B2B',
    surfaceBrowser: '#181818',
  },
  brand: {
    brand: '#313131',
  },
  buttons: {
    primary: {
      default: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: '#71A6FF',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {},
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    secondary: {
      default: {
        fill: '#F8F9F9',
        color: '#374061',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: '#transparent',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {},
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    tertiary: {
      default: {
        fill: 'transparent',
        color: '#F8F9F9',
        borderColor: '#F8F9F9',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: '#F8F9F9',
      },
      active: {
        fill: 'transparent',
        color: '#BBD4FF',
        borderColor: '#BBD4FF',
      },
      activeMouseOver: {
        fill: '#45556F',
        color: '#BBD4FF',
        borderColor: '#BBD4FF',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    flat: {
      default: {
        fill: 'transparent',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      active: {
        fill: 'transparent',
        color: '#BBD4FF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#45556F',
        color: '#BBD4FF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    tool: {
      default: {
        fill: '#393939',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    boxed: {
      default: {
        fill: 'transparent',
        color: '#F8F9F9',
        borderColor: '#F8F9F9',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: '#F8F9F9',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    icon: {
      default: {
        fill: 'transparent',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      active: {
        fill: 'transparent',
        color: '#BBD4FF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#45556F',
        color: '#488BFD',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
  },
  iconButtons: {
    flat: {
      default: {
        fill: 'transparent',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      active: {
        fill: 'transparent',
        color: '#BBD4FF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#45556F',
        color: '#BBD4FF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    tool: {
      default: {
        fill: '#393939',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: 'transparent',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
    boxed: {
      default: {
        fill: 'transparent',
        color: '#F8F9F9',
        borderColor: '#F8F9F9',
      },
      mouseOver: {
        fill: '#45556F',
        color: '#F8F9F9',
        borderColor: '#F8F9F9',
      },
      active: {
        fill: '#186DFF',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      activeMouseOver: {
        fill: '#1047B0',
        color: '#FFFFFF',
        borderColor: 'transparent',
      },
      disabled: {
        fill: 'transparent',
        color: '#B4B4B4',
        borderColor: 'transparent',
      },
      focus: focusButtonStyle,
    },
  },
  typographyAndIcons: {
    text: '#FFFFFF',
    icon: '#FFFFFF',
    buttonIcon: '#FFFFFF',
    iconLinkActive: '#488BFD',
    iconLinkDisabled: '#5E5E5E',
    textLinkActive: '#488BFD',
    buttonIconTertiaryFlat: '#C5C5C5',
    inactiveText: '#b4b4b4',
    iconWarning: '#ffa800',
  },
  textInputField: {
    default: {
      fill: '#2b2f35',
      opacity: 1,
    },
    mouseOver: {
      fill: '#45556F',
      opacity: 1,
    },
    active: {
      fill: '#909090',
      opacity: 0.2,
    },
    disabled: {
      color: '#000000',
      opacity: 0,
    },
    label: {
      fill: '#FFFFFF',
      opacity: 0.7,
    },
    error: {
      fill: '#532323',
      opacity: 1,
    },
    errorMouseOver: {
      fill: '#633838',
      opacity: 1,
    },
  },
  menuItem: {
    mouseOver: '#45556F',
    selected: '#186DFF',
    selectedMouseOver: '#1047b0',
  },
  tab: {
    mouseOver: {
      fill: '#000000',
      opacity: 0.17,
    },
  },
  greys: {
    accessible: '#B4B4B4',
    accessibleLight: '#6F6F6F',
  },
  cards: {
    cardContainer: '#313131',
    cardContainerBorder: '#404040',
    cardContainerMouseOver: '#414751',
    cardContainerDisabled: '#252525',
    cardContainerDisabledBorder: '#363636',
    cardContainerDisabledMouseOver: '#414751',
  },
  backdrops: {
    black: {
      fill: '#000000',
      opacity: 0.5,
    },
    white: {
      fill: '#FFFFFF',
      opacity: 0.24,
    },
  },
  workspace: {
    activeWindow: '#242424',
    inactiveWindow: '#303030',
    tabButtonHover: '#45556F',
  },
  timeSlider: {
    // player
    playerNeedleTimeTop: {
      fill: '#E3004F',
      opacity: 100,
    },
    playerNeedleTime: {
      fill: '#FFD523',
      opacity: 0.75,
    },
    playerNeedlePlayerTop: {
      fill: '#051039',
      opacity: 100,
    },
    playerNeedlePlayer: {
      fill: '#67F0FF',
      opacity: 0.75,
    },
    playerTimeMarkers: {
      fill: '#051039',
    },
    playerTimeText: {
      color: '#FFFFFF',
      fontFamily: 'Roboto',
      fontSize: 14,
    },
    // timeline
    timelineIndicator: {
      fill: '#A2A2A2',
    },
    timelineText: {
      fontSize: 12,
      color: '#FFFFFF',
      fontFamily: 'Roboto',
    },
    timelineTimeScale: {
      fill: '#FFFFFF',
    },
    timelineNightTime: {
      fill: '#051038',
      opacity: 0.67,
    },
    timelineTimelineSurface: {
      fill: '#303030',
    },
    timelineMonthChangeDash: {
      fill: '#A2A2A2',
      opacity: 0.5,
    },
    timelineSelectionBackground: {
      fill: '#FFFFFF',
      opacity: 0.15,
    },
    timelineSelectionOutline: {
      fill: '#F0FF00',
      opacity: 0.4,
    },
    // time scale
    timeScalePointer: {
      fill: '#F8F9F9',
    },
    timeScaleHorizontalScale: {
      fill: '#000000',
      opacity: 0.8,
    },
    timeScaleTimeIndicators: {
      fill: '#A2A2A2',
    },
    timeScaleTimeIndicatorsActive: {
      fill: '#FFFFFF',
    },
    timeScaleShadowButtonScale: {
      filter:
        'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.28)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.40))',
    },
    // legend
    ledgendObservedBackground: {
      fill: '#00649F',
    },
    legendForecastBackground: {
      fill: '#9900B1',
    },
    legendNoDataBackground: {
      fill: '#575F7A',
      opacity: 0.95,
    },
  },
  timeSliderLite: {
    selected: {
      backgroundColor: '#EEF4FB',
    },
    needle: {
      stroke: '#303193',
    },
    needleDefault: {
      stroke: '#3BA587',
    },
    needleShadow: {
      stroke: '#E7F0FA',
    },
    needleLabel: {
      backgroundColor: '#212121',
      borderColor: '#9e9e9e',
      color: '#FFFFFF',
    },
    timeline: {
      fill: '#FFFFFF',
      stroke: '#FFFFFF',
    },
    timelineBackground: {
      fill: '#2959E0',
      color: '#FFFFFF',
    },
    timelineBackgroundAlternative: {
      fill: '#4871E5',
      color: '#FFFFFF',
    },
    timelineBackgroundDisabled: {
      fill: '#767E93',
      color: '#FFFFFF',
    },
    timelineBackgroundDisabledAlternative: {
      fill: '#8A91A3',
      color: '#FFFFFF',
    },
    timelineBackgroundObserved: {
      fill: '#8A91A3',
      color: '#FFFFFF',
    },
    timelineBackgroundObservedAlternative: {
      fill: '#767E93',
      color: '#FFFFFF',
    },
  },
  customSlider: {
    rail: '#488BFD',
    railDisabled: '#5E5E5E',
    track: '#186DFF',
    trackDisabled: '#5E5E5E',
    thumb: '#F8F9F9',
    thumbDisabled: '#5E5E5E',
    mark: '#FFFFFF',
    markDisabled: '#303030',
  },
  customSwitch: {
    thumb: '#F8F9F9',
    thumbDisabled: '#F5F5F5',
    track: '#BDBDBD',
    trackActive: '#488BFD',
  },
  syncGroups: {
    drawerOpen: {
      fill: '#232323',
    },
  },
  layerManager: {
    headerRowText: {
      color: '#FFFFFF',
      opacity: 0.67,
      fontSize: 12,
    },
    tableRowDefaultText: {
      color: '#FFFFFF',
      opacity: 0.87,
      fontSize: 12,
    },
    tableRowDefaultCardContainer: {
      fill: '#303030',
      border: '1px solid #404040',
      borderColor: '#404040',
    },
    tableRowDisabledText: {
      color: '#909090',
      opacity: 0.87,
      fontSize: 12,
    },
    tableRowDisabledCardContainer: {
      fill: '#252525',
      border: '1px solid #363636',
      borderColor: '#363636',
    },
    dragHandleHover: {
      backgroundColor: 'transparent',
    },
    root: {},
    opacitySelect: { root: {}, button: {}, popper: {} },
    baseLayerRow: { root: {} },
  },
  tooltips: {
    tooltipContainer: {
      fill: '#232323',
      opacity: 1,
    },
    tooltipText: {
      color: '#FFFFFF',
    },
    tooltipSelect: { select: {}, menu: {} },
  },
  captions: {
    captionStatus: { color: '#FFFFFF', opacity: 0.67 },
    captionInformation: { color: '#9EC2FF', opacity: 1 },
    captionError: { color: '#FF8F8F', opacity: 1 },
    captionErrorBackground: { color: '#C00000', opacity: 0.25 },
    captionDisabled: { color: '#B0B0B0', opacity: 1 },
  },
  functional: {
    success: '#72BB23',
    successOutline: {
      fill: 'rgba(114, 187, 35, 0.25)',
      border: '1px solid #72BB23',
      borderColor: '#72BB23',
    },
    error: '#C00000',
    errorOutline: {
      fill: 'rgba(192, 0, 0, 0.25)',
      border: '1px solid #C00000',
      borderColor: '#C00000',
    },
    warning: '#FFA800',
    warningOutline: {
      fill: 'rgba(255, 168, 0, 0.25)',
      border: '1px solid #FFA800',
      borderColor: '#FFA800',
    },
    warningHighlight: { fill: '#FFA800', opacity: 0.2 },
    notification: '#186DFF',
    notificationOutline: {
      fill: 'rgba(24, 109, 255, 0.25)',
      border: '1px solid #186DFF',
      borderColor: '#186DFF',
    },
    summary: '#9595FF',
    summaryOutline: {
      fill: 'rgba(131, 131, 242, 0.25)',
      border: '1px solid #8383F2',
      borderColor: '#8383F2',
    },
    disabled: '#9B9FB0',
    disabledOutline: {
      fill: 'rgba(155, 159, 176, 0.25)',
      border: '1px solid #9B9FB0',
      borderColor: '#9B9FB0',
    },
  },
  snackbar: {
    background: '#051039',
    text: '#FFFFFF',
    action: '#98e1f1',
    actionHover: { fill: '#7BACFF', opacity: 0.2 },
  },
  chip: {
    highlightDefault: {
      fill: '#FFA800',
      opacity: 0.15,
      borderColor: '#F8F9F9',
    },
    highlightDefaultMouseOver: {
      fill: '#DEDBD4',
      opacity: 1,
      borderColor: '#F8F9F9',
    },
    highlightActiveDefault: {
      fill: '#566F94',
      opacity: 1,
      borderColor: 'transparent',
    },
    highlightActiveMouseOver: {
      fill: '#345596',
      opacity: 1,
      borderColor: 'transparent',
    },
  },
};

// https://app.zeplin.io/project/5ecf84a3c6ae1047a368f393/screen/6093e694124ecf3886de76d4
export const elevations: Elevations = {
  elevation_01:
    '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)',
  elevation_02:
    '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
  elevation_03:
    '0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 3px 0 rgba(0, 0, 0, 0.14)',
  elevation_04:
    '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
  elevation_06:
    '0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)',
  elevation_08:
    '0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
  elevation_09:
    '0 5px 6px 0 rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14)',
  elevation_12:
    '0 7px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14)',
  elevation_16:
    '0 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14)',
  elevation_24:
    '0 11px 15px 0 rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
};

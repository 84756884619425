/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC } from 'react';
import { Box, Grid2 as Grid } from '@mui/material';
import { CustomIconButton } from '@opengeoweb/shared';
import { Copy, Delete, Visibility, VisibilityOff } from '@opengeoweb/theme';
import { ReactSortable, SortableEvent } from 'react-sortablejs';
import { TFunction } from 'i18next';
import { Parameter, ParameterListStyles, Plot } from '../TimeSeries/types';
import { BUTTON_WIDTH, LEFT_ACTION_WIDTH } from './TimeSeriesManagerUtils';
import TimeSeriesManagerRowAccordion from './TimeSeriesManagerRowAccordion';
import ParameterTypeSelect from './TimeSeriesManagerParameterComponents/ParameterTypeSelect';
import ParameterColorSelect from './TimeSeriesManagerParameterComponents/ParameterColorSelect';
import ParameterOpacitySelect from './TimeSeriesManagerParameterComponents/ParameterOpacitySelect';
import { ParameterInfoButtonConnect } from './ParameterInfo/ParameterInfoButtonConnect';
import ParameterNameSelect from './TimeSeriesManagerParameterComponents/ParameterNameSelect';
import { MoveParameterPayload } from '../../store/types';
import { ReferenceTimeSelect } from './TimeSeriesManagerParameterComponents/ParameterReferenceTimeSelect';

export const ParameterList: FC<{
  plot: Plot;
  plotIsEnabled: boolean;
  deleteParameter: (id: string) => void;
  toggleParameter: (id: string) => void;
  patchParameter: (parameter: Partial<Parameter>) => void;
  addParameter: (parameter: Parameter) => void;
  togglePlot: (id: string) => void;
  moveParameter: (event: MoveParameterPayload) => void;
  t: TFunction;
}> = ({
  plot,
  plotIsEnabled,
  deleteParameter,
  toggleParameter,
  patchParameter,
  addParameter,
  togglePlot,
  moveParameter,
  t,
}) => {
  const onSortEnd = React.useCallback(
    (sort: SortableEvent): void => {
      moveParameter({
        oldIndex: sort.oldIndex,
        newIndex: sort.newIndex,
        plotId: sort.from.id,
        toPlotId: sort.to.id,
      } as MoveParameterPayload);
    },
    [moveParameter],
  );

  return (
    <ReactSortable
      list={
        plot.parameters
          ? plot.parameters?.map((param) => ({ id: param.plotId }))
          : []
      }
      setList={(): void => {}}
      animation={200}
      swapThreshold={0.3} // Nested sortable gets jumpy without this. Cant be lower because cypress will fail.
      onEnd={onSortEnd}
      direction="vertical"
      id={plot.plotId}
      group={{ name: 'parameter', pull: true, put: ['parameter'] }}
    >
      {plot.parameters &&
        plot.parameters.map((parameter) => {
          const parameterIsEnabled = parameter.enabled !== false;
          const rowIsEnabled = plotIsEnabled && parameterIsEnabled;
          const styles = getStyles(rowIsEnabled);
          return (
            <Box sx={styles.plot} key={`${parameter.id}`}>
              <Box className="medium-timeseriesmanager">
                <TimeSeriesManagerRowAccordion
                  styles={styles}
                  parameter={parameter}
                  rowIsEnabled={rowIsEnabled}
                  plotIsEnabled={plotIsEnabled}
                  addParameter={addParameter}
                  patchParameter={patchParameter}
                  deleteParameter={deleteParameter}
                  togglePlot={togglePlot}
                  plot={plot}
                  t={t}
                />
              </Box>
              <Grid container sx={styles.row} className="plotRow">
                <Grid container sx={{ width: LEFT_ACTION_WIDTH }}>
                  <Grid sx={{ width: BUTTON_WIDTH }} />
                  <Grid sx={{ width: BUTTON_WIDTH }}>
                    <CustomIconButton
                      onClick={(): void => {
                        toggleParameter(parameter.id!);
                      }}
                      tooltipTitle={`${
                        parameterIsEnabled
                          ? t('timeseries-hide')
                          : t('timeseries-show')
                      } ${parameter.propertyName}`}
                      shouldShowAsDisabled={!rowIsEnabled}
                      data-testid="enableButton"
                    >
                      {rowIsEnabled ? <Visibility /> : <VisibilityOff />}
                    </CustomIconButton>
                  </Grid>
                </Grid>
                <Grid
                  sx={[styles.rowText]}
                  title={parameter.collectionId}
                  className="rowCollectionWidth"
                >
                  {parameter.collectionId}
                </Grid>
                <Grid className="rowParameterWidth">
                  <ParameterNameSelect
                    rowIsEnabled={rowIsEnabled}
                    parameter={parameter}
                    patchParameter={patchParameter}
                    t={t}
                  />
                </Grid>

                <Grid container className="rowStyleWidth">
                  <Grid>
                    <ParameterTypeSelect
                      rowIsEnabled={rowIsEnabled}
                      parameter={parameter}
                      patchParameter={patchParameter}
                      t={t}
                    />
                  </Grid>
                  <Grid>
                    <ParameterColorSelect
                      rowIsEnabled={rowIsEnabled}
                      parameter={parameter}
                      patchParameter={patchParameter}
                      t={t}
                    />
                  </Grid>
                  <Grid>
                    <ParameterOpacitySelect
                      rowIsEnabled={rowIsEnabled}
                      parameter={parameter}
                      patchParameter={patchParameter}
                      t={t}
                    />
                  </Grid>
                </Grid>

                <Grid container className="rowTimeWidth">
                  <Grid>
                    <ReferenceTimeSelect
                      rowIsEnabled={rowIsEnabled}
                      parameter={parameter}
                      patchParameter={patchParameter}
                      t={t}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '90px',
                  }}
                >
                  <Grid
                    sx={{
                      width: BUTTON_WIDTH,
                      marginLeft: 'auto',
                      marginRight: 0,
                    }}
                  >
                    <ParameterInfoButtonConnect parameter={parameter} />
                  </Grid>
                  <Grid
                    sx={{
                      width: BUTTON_WIDTH,
                      marginLeft: 'auto',
                      marginRight: 0,
                    }}
                  >
                    <CustomIconButton
                      tooltipTitle={`${t('timeseries-duplicate')} ${parameter.propertyName}`}
                      shouldShowAsDisabled={!plotIsEnabled}
                      data-testid="duplicateButton"
                      onClick={(): void => {
                        addParameter(parameter);
                      }}
                    >
                      <Copy />
                    </CustomIconButton>
                  </Grid>
                  <Grid
                    sx={{
                      width: BUTTON_WIDTH,
                      marginLeft: 'auto',
                      marginRight: 0,
                    }}
                  >
                    <CustomIconButton
                      tooltipTitle={`${t('timeseries-remove')} ${parameter.propertyName}`}
                      onClick={(): void => {
                        deleteParameter(parameter.id!);
                      }}
                      shouldShowAsDisabled={!plotIsEnabled}
                      data-testid="deleteButton"
                    >
                      <Delete />
                    </CustomIconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          );
        })}
    </ReactSortable>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getStyles = (isEnabled: boolean): ParameterListStyles => ({
  plot: {
    width: 'calc(100% - 8px)',
    marginBottom: '5px',
  },
  row: {
    backgroundColor: isEnabled
      ? 'geowebColors.layerManager.tableRowDefaultCardContainer.fill'
      : 'geowebColors.layerManager.tableRowDisabledCardContainer.fill',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'geowebColors.cards.cardContainerBorder',
    borderRadius: 1,
    marginBottom: 0.25,
    marginLeft: 0.5,
    marginRight: 0.5,
    height: 33,
    alignItems: 'center',
    width: '100%',
  },
  rowText: {
    fontSize: 12,
    fontWeight: 500,
    color: isEnabled
      ? 'geowebColors.layerManager.tableRowDefaultText.color'
      : 'geowebColors.layerManager.tableRowDisabledText.color',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

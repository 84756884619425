/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box } from '@mui/material';
import { TimeSeriesService, ToolContainerDraggable } from '@opengeoweb/shared';
import React, { FC } from 'react';
import { Parameter, Plot } from '../TimeSeries/types';
import { TimeSeriesSelectListConnect } from './TimeSeriesSelectListConnect';
import SearchFieldConnect from './TimeSeriesSearchConnect';
import TimeSeriesServiceListConnect from './TimeSeriesServiceListConnect';
import { DEFAULT_DIALOG_HEIGHT } from './TimeSeriesSelectList';
import { ServiceOptionsButtonConnect } from './ServiceOptionsConnect';
import { ServicePopupConnect } from './ServicePopup/ServicePopupConnect';
import { useTimeseriesTranslation } from '../../utils/i18n';

interface TimeSeriesSelectProps {
  onClose: () => void;
  onMouseDown: () => void;
  isOpen: boolean;
  order: number;
  selectPlot: Plot;
  handleAddOrRemoveClick: (
    parameter: Parameter,
    plotHasParameter: boolean,
  ) => void;
  services: TimeSeriesService[];
}

export const TimeSeriesSelect: FC<TimeSeriesSelectProps> = ({
  onClose,
  isOpen,
  onMouseDown,
  order,
  selectPlot,
  handleAddOrRemoveClick,
  services,
}) => {
  const { t } = useTimeseriesTranslation();

  return (
    <ToolContainerDraggable
      title={`${t('timeseries-select-for')} ${selectPlot.title}`}
      startSize={{ width: 750, height: DEFAULT_DIALOG_HEIGHT }}
      minWidth={320}
      minHeight={200}
      startPosition={{ top: 150, left: 100 }}
      isOpen={isOpen}
      onClose={onClose}
      onMouseDown={onMouseDown}
      order={order}
      data-testid="TimeSeriesSelect"
    >
      <Box
        sx={{
          padding: '6px 8px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SearchFieldConnect />
        <Box
          sx={{
            position: 'absolute',
            margin: '64px 0 0 calc(100% - 40px)',
            zIndex: 2,
            float: 'right',
          }}
        >
          <ServiceOptionsButtonConnect />
        </Box>
        <Box sx={{ paddingBottom: '6px', paddingRight: '32px' }}>
          <TimeSeriesServiceListConnect />
        </Box>

        <TimeSeriesSelectListConnect
          selectPlot={selectPlot}
          handleAddOrRemoveClick={handleAddOrRemoveClick}
          services={services}
        />
        <ServicePopupConnect />
      </Box>
    </ToolContainerDraggable>
  );
};

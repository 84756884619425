/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createAction } from '@reduxjs/toolkit';
import { SetTimePayload, SetBboxPayload } from './types';
/**
 * Generic action to set the time.
 *
 * @param payload: SetTimePayload;
 *   {
 *     sourceId: string; // Source where the new time value originates from
 *     value: string;    // ISO 8601 string time value like "2010-10-01T00:00:00Z"
 *   }
 */
export const setTime = createAction<SetTimePayload>('GENERIC_SETTIME');

/**
 * Generic action to set the bounding box.
 *
 * @param payload: SetBboxPayload;
 *
 * {
 *   sourceId: string;  // Source where the new time value originates from
 *   bbox: {            // Bbbox object
 *     left: number;
 *     bottom: number;
 *     right: number;
 *     top: number;
 *   };
 *   srs: string;       // Projection string according to EPSG, e.g. (EPSG:3857 ==  Mercator)
 * }
 */
export const setBbox = createAction<SetBboxPayload>('GENERIC_SETBBOX');

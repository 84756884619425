/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Draft,
  EntityState,
} from '@reduxjs/toolkit';

import { DrawMode, MapDrawToolOptions } from '@opengeoweb/webmap-react';
import { layerActions } from '../map';

export interface DrawToolItem {
  drawToolId: string; // instance id of drawtool
  geoJSONLayerId: string;
  activeDrawModeId: string;
  drawModes: DrawMode[];
  shouldAllowMultipleShapes: boolean;
  // intersection
  geoJSONIntersectionLayerId?: string;
  geoJSONIntersectionBoundsLayerId?: string;
}

export type DrawingToolState = EntityState<DrawToolItem>;

export interface DrawtoolModuleStore {
  drawingtools?: DrawingToolState;
}

// reducer utils
export const drawAdapter = createEntityAdapter<DrawToolItem>({
  selectId: (draw) => draw.drawToolId,
});

const getDrawToolByLayerId = (
  draft: Draft<DrawingToolState>,
  layerId: string,
): string | undefined => {
  const drawTool = draft.ids
    .map((id) => draft.entities[id])
    .find((tool) => tool?.geoJSONLayerId === layerId);

  return drawTool?.drawToolId || undefined;
};

export const initialState: DrawingToolState = drawAdapter.getInitialState();

const slice = createSlice({
  initialState,
  name: 'draw',
  reducers: {
    registerDrawTool: (
      draft: Draft<DrawingToolState>,
      action: PayloadAction<
        MapDrawToolOptions & {
          drawToolId: string;
          mapId?: string;
        }
      >,
    ) => {
      const {
        drawToolId,
        defaultDrawModes = [],
        geoJSONLayerId = '',
        geoJSONIntersectionLayerId,
        geoJSONIntersectionBoundsLayerId,
        shouldAllowMultipleShapes = false,
      } = action.payload;

      drawAdapter.addOne(draft, {
        drawToolId,
        geoJSONLayerId,
        geoJSONIntersectionLayerId,
        geoJSONIntersectionBoundsLayerId,
        activeDrawModeId: '',
        drawModes: defaultDrawModes,
        shouldAllowMultipleShapes,
      });
    },
    unregisterDrawTool: (
      draft: Draft<DrawingToolState>,
      action: PayloadAction<{
        drawToolId: string;
      }>,
    ) => {
      const { drawToolId } = action.payload;

      if (draft.entities[drawToolId]) {
        drawAdapter.removeOne(draft, drawToolId);
      }
    },
    changeDrawToolMode: (
      draft: Draft<DrawingToolState>,
      action: PayloadAction<{
        drawToolId: string;
        drawModeId: string;
        shouldUpdateShape?: boolean;
      }>,
    ) => {
      const {
        drawModeId,
        drawToolId,
        shouldUpdateShape = true,
      } = action.payload;
      const drawTool = draft.entities[drawToolId];

      if (!drawTool) {
        return;
      }
      const tool = drawTool.drawModes.find(
        (drawMode) => drawMode.drawModeId === drawModeId,
      );

      if (tool?.isSelectable === false) {
        drawTool.activeDrawModeId = '';
        return;
      }

      if (drawTool?.activeDrawModeId !== drawModeId || !shouldUpdateShape) {
        drawTool.activeDrawModeId = drawModeId;
      } else {
        drawTool.activeDrawModeId = '';
      }
    },
    updateGeoJSONLayerId: (
      draft: Draft<DrawingToolState>,
      action: PayloadAction<{
        drawToolId: string;
        geoJSONLayerId: string;
      }>,
    ) => {
      const { geoJSONLayerId, drawToolId } = action.payload;
      const drawTool = draft.entities[drawToolId];

      if (!drawTool) {
        return;
      }
      drawTool.geoJSONLayerId = geoJSONLayerId;
    },
    // updates single tool mode with new values
    updateDrawToolMode: (
      draft: Draft<DrawingToolState>,
      action: PayloadAction<{
        drawToolId: string;
        drawModeId: string;
        shape: GeoJSON.FeatureCollection;
        title?: string;
      }>,
    ) => {
      const { drawModeId, drawToolId, shape, title } = action.payload;
      const drawTool = draft.entities[drawToolId];

      if (!drawTool) {
        return;
      }

      const newModes = drawTool.drawModes.map((mode) => {
        if (mode.drawModeId === drawModeId) {
          return {
            ...mode,
            shape,
            ...(title && {
              title,
            }),
          };
        }
        return mode;
      });

      drawTool.drawModes = newModes;
    },
    changeIntersectionBounds: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<DrawingToolState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        drawToolId: string;
        geoJSON: GeoJSON.FeatureCollection;
      }>,
    ) => {
      // action caught in drawingTool/listener
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(layerActions.exitFeatureDrawMode, (draft, action) => {
        const { layerId, shouldAllowMultipleShapes, reason } = action.payload;

        const drawToolId = getDrawToolByLayerId(draft, layerId);
        if (!drawToolId || (drawToolId && !draft.entities[drawToolId])) {
          return;
        }

        if (
          !shouldAllowMultipleShapes ||
          (shouldAllowMultipleShapes && reason === 'escaped')
        ) {
          draft.entities[drawToolId!]!.activeDrawModeId = '';
        }
      })

      .addCase(layerActions.updateFeatureProperties, (draft, action) => {
        const { properties, layerId } = action.payload;

        const drawToolId = getDrawToolByLayerId(draft, layerId)!;
        const entity = draft.entities[drawToolId];

        if (drawToolId && entity !== undefined) {
          const newDrawModes = entity?.drawModes?.map((mode) => {
            if (mode.shape.type === 'Feature') {
              return {
                ...mode,
                shape: {
                  ...mode.shape,
                  properties: { ...mode.shape.properties, ...properties },
                },
              };
            }
            return mode;
          });
          entity.drawModes = newDrawModes;
          drawAdapter.setOne(draft, entity);
        }
      });
    // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/4065 enable auto registering again in
    // .addCase(mapActions.registerMap, (draft: Draft<DrawState>, action) => {
    //   // drawAdapter.addOne(draft, {
    //   //   mapId: action.payload.mapId,
    //   //   area: undefined!,
    //   //   areaName: '',
    //   //   id: '',
    //   // });
    // })
    // .addCase(mapActions.unregisterMap, (draft: Draft<DrawState>, action) => {
    //   // drawAdapter.removeOne(draft, action.payload.mapId);
    // });
  },
});

export const { reducer: drawingToolReducer, actions: drawingToolActions } =
  slice;

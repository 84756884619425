/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createEntityAdapter,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  CapFeed,
  CapFeedState,
  CapAlert,
  CapAlertState,
  AddManyAlertsToFeedPayload,
  FeedLastUpdatedPayload,
  CapStore,
} from './types';

export const feedAdapter = createEntityAdapter<CapFeed>();
export const feedInitialState: CapFeedState = feedAdapter.getInitialState();
export const alertAdapter = createEntityAdapter<CapAlert>({
  // we use identifier as id
  selectId: (capAlert) => capAlert.identifier,
});
export const alertInitialState: CapAlertState = alertAdapter.getInitialState();

export const initialState: CapStore = {
  feeds: feedInitialState,
  alerts: alertInitialState,
};

const slice = createSlice({
  initialState,
  name: 'capReducer',
  reducers: {
    addManyAlerts: (
      draft: Draft<CapStore>,
      action: PayloadAction<CapAlert[]>,
    ) => {
      alertAdapter.upsertMany(draft.alerts, action.payload);
    },
    addManyAlertsToFeed: (
      draft: Draft<CapStore>,
      action: PayloadAction<AddManyAlertsToFeedPayload>,
    ) => {
      feedAdapter.upsertOne(draft.feeds, {
        id: action.payload.feedId,
        lastUpdated: Date.now(),
        alertIds: action.payload.alertIds,
      });
    },
    setFeedLastUpdatedTimes: (
      draft: Draft<CapStore>,
      action: PayloadAction<FeedLastUpdatedPayload[]>,
    ) => {
      feedAdapter.updateMany(draft.feeds, action.payload);
    },
  },
});

export const { reducer: capReducer, actions: capActions } = slice;

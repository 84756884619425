/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2025 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2025 - Finnish Meteorological Institute (FMI)
 * Copyright 2025 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const WMS130GetCapabilitiesWithoutLegend = `<?xml version="1.0"?>
<WMS_Capabilities xmlns="http://www.opengis.net/wms" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.3.0" updateSequence="1737582762" xsi:schemaLocation="http://www.opengis.net/wms http://schemas.opengis.net/wms/1.3.0/capabilities_1_3_0.xsd">
  <Service>
    <Name>WMS</Name>
    <Title>WMS Example</Title>
    <Abstract>Example abstract.</Abstract>
    <KeywordList/>
    <OnlineResource xlink:href="http://localhost:3000/wms?" xlink:type="simple"/>
    <Fees>none</Fees>
    <AccessConstraints>none</AccessConstraints>
    <LayerLimit>1</LayerLimit>
    <MaxWidth>8192</MaxWidth>
    <MaxHeight>8192</MaxHeight>
  </Service>
  <Capability>
    <Request>
      <GetCapabilities>
        <Format>text/xml</Format>
        <DCPType>
          <HTTP>
            <Get>
              <OnlineResource xlink:href="http://localhost:3000/wms?" xlink:type="simple"/>
            </Get>
          </HTTP>
        </DCPType>
      </GetCapabilities>
      <GetMap>
        <Format>image/png</Format>
        <DCPType>
          <HTTP>
            <Get>
              <OnlineResource xlink:href="http://localhost:3000/wms?" xlink:type="simple"/>
            </Get>
          </HTTP>
        </DCPType>
      </GetMap>
    </Request>
    <Exception>
      <Format>XML</Format>
    </Exception>
    <Layer>
      <Title>example</Title>
      <CRS>EPSG:3857</CRS>
      <EX_GeographicBoundingBox>
        <westBoundLongitude>-180</westBoundLongitude>
        <eastBoundLongitude>180</eastBoundLongitude>
        <southBoundLatitude>-90</southBoundLatitude>
        <northBoundLatitude>90</northBoundLatitude>
      </EX_GeographicBoundingBox>
      <BoundingBox CRS="EPSG:3857" minx="-20037508.34" miny="-20048966.1" maxx="20037508.34" maxy="20048966.1"/>
      <Layer>
        <Name>my_name</Name>
        <Title>My Title</Title>
        <Abstract>My Abstract</Abstract>
        <Dimension name="time" units="ISO8601">2025-01-22T00:00:00Z</Dimension>
        <Style>
          <Name>standard</Name>
          <Title>Standard</Title>
          <!-- LegendURL>
            <Format>image/png</Format>
            <OnlineResource xlink:href="http://localhost:3000/jada" xlink:type="simple"/>
          </LegendURL -->
        </Style>
      </Layer>
    </Layer>
  </Capability>
</WMS_Capabilities>
`;

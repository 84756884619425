/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { connect, ConnectedProps, useSelector } from 'react-redux';
import React from 'react';

import {
  CoreAppStore,
  mapActions,
  mapEnums,
  mapSelectors,
} from '@opengeoweb/store';
import { defaultTimeStep, PlayButton } from '@opengeoweb/timeslider';

export interface PlayButtonConnectProps {
  mapId: string;
  isAnimating?: boolean;
  animationStartTime?: string;
  animationEndTime?: string;
  isDisabled?: boolean;
  mapStartAnimation?: typeof mapActions.mapStartAnimation;
  mapStopAnimation?: typeof mapActions.mapStopAnimation;
  timeStep?: number;
  linkedMapAnimationInfo?: { isAnimating: boolean; id: string };
}

const connectRedux = connect(
  (store: CoreAppStore, props: PlayButtonConnectProps) => ({
    timeStep: mapSelectors.getMapTimeStep(store, props.mapId),
    linkedMapAnimationInfo: mapSelectors.linkedMapAnimationInfo(
      store,
      props.mapId,
    ),
  }),
  {
    mapStartAnimation: mapActions.mapStartAnimation,
    mapStopAnimation: mapActions.mapStopAnimation,
  },
);

type Props = PlayButtonConnectProps & ConnectedProps<typeof connectRedux>;

export const PlayButtonConnect = connectRedux(
  ({
    mapId,
    isDisabled,
    timeStep,
    linkedMapAnimationInfo,
    mapStartAnimation,
    mapStopAnimation,
  }: Props) => {
    const { animationStartTime, animationEndTime } = useSelector(
      (store: CoreAppStore) => mapSelectors.getAnimationRange(store, mapId),
    );

    const animationInterval = timeStep || defaultTimeStep;
    const onTogglePlay = (): void => {
      if (linkedMapAnimationInfo.isAnimating) {
        mapStopAnimation({
          mapId: linkedMapAnimationInfo.id,
          origin: mapEnums.MapActionOrigin.map,
        });
      } else {
        mapStartAnimation({
          mapId,
          start: animationStartTime,
          end: animationEndTime,
          interval: animationInterval,
          origin: mapEnums.MapActionOrigin.map,
        });
      }
    };

    return (
      <PlayButton
        isAnimating={linkedMapAnimationInfo.isAnimating}
        isDisabled={isDisabled}
        onTogglePlayButton={(): void => {
          onTogglePlay();
        }}
      />
    );
  },
);

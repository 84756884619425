/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useDispatch } from 'react-redux';
import React from 'react';

import { mapActions } from '@opengeoweb/store';
import { BackwardForwardStepButton } from '@opengeoweb/timeslider';

export interface BackwardForwardStepButtonConnectProps {
  mapId: string;
  isForwardStep?: boolean;
}

export const BackwardForwardStepButtonConnect = ({
  isForwardStep,
  mapId,
}: BackwardForwardStepButtonConnectProps): React.ReactElement => {
  const dispatch = useDispatch();

  const setStepBackwardorForward = React.useCallback(() => {
    dispatch(
      mapActions.setStepBackwardOrForward({
        mapId,
        isForwardStep: !!isForwardStep,
      }),
    );
  }, [dispatch, isForwardStep, mapId]);

  return (
    <BackwardForwardStepButton
      isDisabled={false}
      isForwardStep={isForwardStep}
      onClickBFButton={setStepBackwardorForward}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import { PresetScope } from '@opengeoweb/shared';
import { WorkspaceOptions } from './WorkspaceOptions';
import { AppStore, WorkspaceModuleStore } from '../../store/types';
import * as workspaceSelectors from '../../store/workspace/selectors';
import { WorkspacePresetAction } from '../../store/workspace/types';
import { WorkspaceActionDialogType } from '../../store/workspaceList/types';
import { workspaceListActions } from '../../store/workspaceList/reducer';
import { useWorkspaceTranslation } from '../../utils/i18n';
import { getPresetTitle, setDocumentTitle } from '../../utils/utils';

export interface WorkspaceOptionsConnectProps {
  title: string;
  activeTab?: boolean;
  workspaceId: string;
  appTitle?: string;
}

export const WorkspaceOptionsConnect: React.FC<
  WorkspaceOptionsConnectProps
> = ({
  title,
  activeTab,
  workspaceId,
  appTitle,
}: WorkspaceOptionsConnectProps) => {
  const dispatch = useDispatch();
  const store = useStore<WorkspaceModuleStore>();

  const { t } = useWorkspaceTranslation();
  const { currentRole } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;

  const { isLoggedIn } = useAuthenticationContext();
  const hasChanges = useSelector((store: AppStore) =>
    workspaceSelectors.hasWorkspaceChanges(store),
  );

  const workspaceScope = useSelector((store: AppStore) =>
    workspaceSelectors.getSelectedWorkspaceScope(store),
  );

  const workspaceData = useSelector((store: AppStore) =>
    workspaceSelectors.getWorkspaceData(store),
  );

  const viewIds = useSelector((store: AppStore) =>
    workspaceSelectors.getViewIds(store),
  );

  const isNew = workspaceId === undefined || workspaceId === '';

  const fullTitle = getPresetTitle(title, hasChanges, isNew, t);

  const isUserPreset = workspaceScope === 'user';

  React.useEffect(() => {
    setDocumentTitle(fullTitle, appTitle);
  }, [fullTitle, appTitle]);

  const setWorkspaceActionDialogOptions = React.useCallback(
    (dialogOptions: WorkspaceActionDialogType) => {
      dispatch(
        workspaceListActions.openWorkspaceActionDialogOptions(dialogOptions),
      );
    },
    [dispatch],
  );

  const onDelete = (): void => {
    setWorkspaceActionDialogOptions({
      action: WorkspacePresetAction.DELETE,
      presetId: workspaceId,
      formValues: { title },
    });
  };

  const onSaveAction = (
    action: WorkspacePresetAction,
    scope: PresetScope,
    shouldIncludeViewPresetScope = false,
  ): void => {
    const views = workspaceData.views.map((view) => {
      const scope =
        workspaceSelectors.getViewById(store.getState(), view.mosaicNodeId)
          ?.scope || 'system';

      return {
        ...view,
        isSelected: false,
        ...(shouldIncludeViewPresetScope && {
          scope,
        }),
      };
    });

    setWorkspaceActionDialogOptions({
      action,
      presetId: workspaceId,
      formValues: {
        ...workspaceData,
        scope,
        views,
      },
      hasSaved: false,
    });
  };

  const onSaveAs = (): void =>
    onSaveAction(WorkspacePresetAction.SAVE_AS, 'user', true);

  const onSave = (): void =>
    onSaveAction(WorkspacePresetAction.SAVE, 'user', true);

  const onEdit = (): void => {
    setWorkspaceActionDialogOptions({
      action: WorkspacePresetAction.EDIT,
      presetId: workspaceId,
      formValues: {
        ...workspaceData,
      },
    });
  };

  const onSaveSystemPresetAs = (): void =>
    onSaveAction(WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS, 'system');

  const onSaveSystemPreset = (): void =>
    onSaveAction(WorkspacePresetAction.SAVE_SYSTEM_PRESET, 'system');

  const onReset = (): void => {
    setWorkspaceActionDialogOptions({
      action: WorkspacePresetAction.RESET,
      presetId: workspaceId,
      formValues: { title, scope: isAdmin ? 'system' : 'user' },
    });
  };

  const onDeleteSystemPreset = (): void => {
    setWorkspaceActionDialogOptions({
      action: WorkspacePresetAction.DELETE_SYSTEM_PRESET,
      presetId: workspaceId,
      formValues: { title },
    });
  };

  const getOnDelete = (): undefined | (() => void) => {
    if (isNew) {
      return undefined; // to disable delete option
    }
    if (isUserPreset) {
      return onDelete;
    }
    return isAdmin ? onDeleteSystemPreset : undefined;
  };

  const handleSave = (): undefined | (() => void) => {
    if (isNew) {
      return undefined;
    }
    if (isUserPreset) {
      return onSave;
    }

    return isAdmin ? onSaveSystemPreset : undefined;
  };

  const handleSaveAs = (): undefined | (() => void) => {
    return isAdmin ? onSaveSystemPresetAs : onSaveAs;
  };

  return (
    <WorkspaceOptions
      hasChanges={hasChanges}
      title={fullTitle}
      activeTab={activeTab}
      handleDelete={getOnDelete()}
      handleSaveAs={handleSaveAs()}
      handleSave={handleSave()}
      handleEdit={isUserPreset ? onEdit : undefined}
      handleReset={onReset}
      isDisabled={!viewIds.length || !isLoggedIn}
      isAdmin={isAdmin}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Speed } from '@opengeoweb/theme';
import * as React from 'react';
import { useState } from 'react';
import { Mark, TimeSliderMenu } from '../TimeSliderMenu/TimeSliderMenu';
import { getSpeedDelay, speedFactors } from '../../TimeSlider/timeSliderUtils';
import { useTimesliderTranslation } from '../../../utils/i18n';

export interface SpeedButtonProps {
  animationDelay: number;
  setMapAnimationDelay: (delay: number) => void;
}

export const SpeedButton: React.FC<SpeedButtonProps> = ({
  animationDelay,
  setMapAnimationDelay,
}: SpeedButtonProps) => {
  const sorted = [...speedFactors].sort((a, b) => {
    return b - a;
  });
  const marks: Mark[] = sorted.map(
    (speedFactor): Mark => ({
      value: getSpeedDelay(speedFactor),
      text: `x${speedFactor}`,
      label: `x${speedFactor}`,
    }),
  );
  const { t } = useTimesliderTranslation();
  const [open, setOpen] = useState(false);
  return (
    <TimeSliderMenu
      onChangeSliderValue={(markValue: number): void => {
        setMapAnimationDelay(markValue);
      }}
      icon={<Speed />}
      marks={marks}
      title={t('timeslider-speed')}
      value={animationDelay}
      open={open}
      setOpen={setOpen}
    />
  );
};

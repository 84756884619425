/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, MenuItem } from '@mui/material';
import {
  ReactHookFormSelect,
  ReactHookFormNumberField,
  isEmpty,
  useDraftFormHelpers,
  isValidMin,
  isValidMax,
} from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';
import {
  ConfigurableFormFieldProps,
  FIRConfigAirmet,
  ProductConfig,
  VisibilityCause,
} from '../../../types';
import { styles } from '../ProductForm.styles';
import {
  getActiveFIRArea,
  getFieldLabel,
  getMaxVisibilityValue,
  getMinVisibilityValue,
} from '../utils';
import {
  translateKeyOutsideComponents,
  useSigmetAirmetTranslation,
} from '../../../utils/i18n';

export const DEFAULT_ROUNDING_SURFACE_VISIBLILITY_BELOW = 50;
export const DEFAULT_ROUNDING_SURFACE_VISIBLILITY_ABOVE = 100;

export const getInvalidSurfaceVisibilityTill750StepsMessage = (
  value = DEFAULT_ROUNDING_SURFACE_VISIBLILITY_BELOW,
): string =>
  translateKeyOutsideComponents(
    'invalid-surface-visibility-till-750-steps-message',
    { value },
  );
export const getInvalidSurfaceVisibilityFrom750StepsMessage = (
  value = DEFAULT_ROUNDING_SURFACE_VISIBLILITY_ABOVE,
): string =>
  translateKeyOutsideComponents(
    'invalid-surface-visibility-from-750-steps-message',
    { value },
  );

export const validateSurfaceVisibility = (
  value: string,
  productConfig: ProductConfig,
  selectedFIR: string,
): boolean | string => {
  if (isEmpty(value)) {
    return true;
  }

  const {
    /* eslint-disable @typescript-eslint/naming-convention */
    visibility_rounding_below = DEFAULT_ROUNDING_SURFACE_VISIBLILITY_BELOW,
    visibility_rounding_above = DEFAULT_ROUNDING_SURFACE_VISIBLILITY_ABOVE,
    /* eslint-enable @typescript-eslint/naming-convention */
  } = getActiveFIRArea(selectedFIR, productConfig) as FIRConfigAirmet;

  // Parse to integer to check for steps
  const intVisibility = parseInt(value, 10);

  if (intVisibility > 750) {
    return (
      intVisibility % visibility_rounding_above === 0 ||
      getInvalidSurfaceVisibilityFrom750StepsMessage(visibility_rounding_above)
    );
  }
  return (
    intVisibility % visibility_rounding_below === 0 ||
    getInvalidSurfaceVisibilityTill750StepsMessage(visibility_rounding_below)
  );
};

const SurfaceVisibility: React.FC<ConfigurableFormFieldProps> = ({
  isDisabled,
  isReadOnly,
  productConfig,
  onChange = (): void => {},
}: ConfigurableFormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { isRequired } = useDraftFormHelpers();
  const { getValues } = useFormContext();

  const getFIRValue = (): string => getValues('locationIndicatorATSR');

  const visibilityLabel = getFieldLabel(t('surface-visibility'), isReadOnly!);
  const visibilityCauseLabel = getFieldLabel(
    t('surface-visibility-cause'),
    isReadOnly!,
  );

  return (
    <Grid container spacing={2}>
      <Grid container justifyContent="flex-end" size={4} />
      <Grid container justifyContent="flex-start" size={8}>
        <Grid sx={styles.surfaceVisibility} size={12}>
          <ReactHookFormNumberField
            name="visibilityValue"
            data-testid="surfaceVisibility-visibilityValue"
            label={visibilityLabel}
            helperText={t('surface-visibility-helper-text')}
            rules={{
              validate: {
                isRequired,
                min: (value): boolean | string =>
                  // The min level depends on the unit
                  isValidMin(
                    value,
                    getMinVisibilityValue(getFIRValue(), productConfig),
                  ) ||
                  t('surface-visibility-minimum-visibility', {
                    minimumVisibility: getMinVisibilityValue(
                      getFIRValue(),
                      productConfig,
                    ),
                  }),
                max: (value): boolean | string =>
                  // The max level depends on the unit
                  isValidMax(
                    value,
                    getMaxVisibilityValue(getFIRValue(), productConfig),
                  ) ||
                  t('surface-visibility-maximum-visibility', {
                    maximumVisibility: getMaxVisibilityValue(
                      getFIRValue(),
                      productConfig,
                    ),
                  }),
                validateSurfaceVisibility: (value): boolean | string =>
                  validateSurfaceVisibility(
                    value,
                    productConfig,
                    getFIRValue(),
                  ),
              },
            }}
            size="small"
            disabled={isDisabled}
            isReadOnly={isReadOnly}
            sx={{
              ' .MuiFormHelperText-root': {
                lineHeight: '1.33em',
              },
            }}
          />
        </Grid>
        <Grid size={12} sx={{ marginTop: -2 }}>
          <ReactHookFormSelect
            name="visibilityCause"
            label={visibilityCauseLabel}
            rules={{ validate: { isRequired } }}
            disabled={isDisabled}
            isReadOnly={isReadOnly}
            data-testid="surfaceVisibility-visibilityCause"
            onChange={(event): void => {
              event.stopPropagation();
              onChange();
            }}
            size="small"
          >
            {Object.keys(VisibilityCause).map((key) => (
              <MenuItem value={key} key={key}>
                {VisibilityCause[key as keyof typeof VisibilityCause]}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SurfaceVisibility;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormSelect,
  ReactHookFormHiddenInput,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';

import { getFir, getFirOptions, triggerValidations } from '../utils';
import {
  ConfigurableFormFieldProps,
  AviationPhenomenon,
  ProductConfig,
} from '../../../types';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

export const getFirLocationDefaultValues = (
  productConfig: ProductConfig,
  currentFir?: string,
): {
  locationIndicatorATSR: string;
  locationIndicatorATSU: string;
  locationIndicatorMWO: string;
  firName: string;
  firGeometry: GeoJSON.FeatureCollection;
  phenomena: AviationPhenomenon[];
} => {
  const defaultActiveFIR = currentFir || productConfig.active_firs[0]; // first FIR in active_firs is the default
  const currentLocationIndicatorATSR =
    productConfig.fir_areas[defaultActiveFIR].location_indicator_atsr;
  const currentFirArea = productConfig.fir_areas[currentLocationIndicatorATSR];
  const locationIndicatorATSU = currentFirArea.location_indicator_atsu;
  const locationIndicatorMWO = productConfig.location_indicator_mwo;
  const firName = currentFirArea.fir_name;
  const firPhenomena = currentFirArea.phenomenon || [];
  const firGeometry = getFir(productConfig, defaultActiveFIR);

  return {
    locationIndicatorATSR: currentLocationIndicatorATSR,
    locationIndicatorATSU,
    locationIndicatorMWO,
    firName,
    firGeometry,
    phenomena: firPhenomena,
  };
};

const SelectFIR: React.FC<ConfigurableFormFieldProps> = ({
  productConfig,
  isDisabled,
  isReadOnly,
  onChange = (): void => {},
}: ConfigurableFormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { setValue, trigger, getValues } = useFormContext();
  const { isRequired } = useDraftFormHelpers();
  const optionsFIR = getFirOptions(productConfig);

  const {
    locationIndicatorATSR,
    locationIndicatorATSU,
    locationIndicatorMWO,
    firName,
    firGeometry,
  } = getFirLocationDefaultValues(productConfig);

  const onChangeLocationATSR = (
    event: SelectChangeEvent<unknown>, // Update the event type here
  ): void => {
    const { value } = event.target; // MUI Select component's target has a `value` property
    const valuesFromConfig = getFirLocationDefaultValues(
      productConfig,
      value as string,
    ); // Ensure value is treated as a string

    // Updates values from config
    setValue('locationIndicatorATSU', valuesFromConfig.locationIndicatorATSU);
    setValue('firName', valuesFromConfig.firName);
    setValue('locationIndicatorMWO', valuesFromConfig.locationIndicatorMWO);
    setValue('firGeometry', valuesFromConfig.firGeometry);

    const currentPhenomenon = getValues('phenomenon');
    const isValidPhenomenon = valuesFromConfig.phenomena.some(
      (p) => p.code === currentPhenomenon,
    );

    if (!isValidPhenomenon) {
      if (valuesFromConfig.phenomena.length === 1) {
        setValue('phenomenon', valuesFromConfig.phenomena[0]?.code || '');
      } else {
        setValue('phenomenon', '');
      }
    }

    // Trigger validation for fields dependent on FIR config
    const fieldnames = [
      'phenomenon',
      'validDateStart',
      'validDateEnd',
      'movementSpeed',
      'level.value',
      'lowerLevel.value',
    ];
    triggerValidations(fieldnames, getValues, trigger);

    onChange(
      event as React.ChangeEvent<HTMLInputElement>,
      valuesFromConfig.phenomena,
    ); // Pass the event and updated phenomena as arguments
  };

  return (
    <ProductFormFieldLayout title={t('select-fir-title')}>
      <ReactHookFormSelect
        name="locationIndicatorATSR"
        label={isReadOnly ? 'FIR' : t('select-fir-label')}
        rules={{ validate: { isRequired } }}
        disabled={isDisabled}
        isReadOnly={isReadOnly}
        defaultValue={locationIndicatorATSR}
        onChange={onChangeLocationATSR}
        size="small"
      >
        {optionsFIR.map((fir) => (
          <MenuItem key={`${fir.ATSR}-${fir.FIR}`} value={fir.ATSR}>
            {fir.FIR}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
      <ReactHookFormHiddenInput
        name="locationIndicatorATSU"
        defaultValue={locationIndicatorATSU}
        data-testid="locationIndicatorATSU"
      />
      <ReactHookFormHiddenInput
        name="locationIndicatorMWO"
        defaultValue={locationIndicatorMWO}
        data-testid="locationIndicatorMWO"
      />
      <ReactHookFormHiddenInput
        name="firName"
        defaultValue={firName}
        data-testid="firName"
      />
      <ReactHookFormHiddenInput
        name="firGeometry"
        defaultValue={firGeometry}
        data-testid="firGeometry"
      />
    </ProductFormFieldLayout>
  );
};

export default SelectFIR;

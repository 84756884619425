/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  AnyAction,
  createListenerMiddleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import {
  genericActions,
  mapActions,
  mapSelectors,
  routerActions,
  syncGroupsActions,
} from '@opengeoweb/store';
import { InitialMapProps } from '@opengeoweb/core';
import { AxiosError } from 'axios';
import {
  getErrorMessage,
  getListInChunks,
  getLoadingWorkspace,
  getSyncGroups,
  getViewPresetState,
  getWorkspaceApi,
} from './utils';

import { AppStore, WorkspaceModuleStore } from '../types';
import { getViewType, getWorkspaceState } from './selectors';
import { viewPresetActions } from '../viewPresets';
import { workspaceActions } from './reducer';
import { workspaceListActions } from '../workspaceList/reducer';
import { getWorkspaceRouteUrl } from '../../utils/routes';
import { ERROR_NOT_FOUND } from '../../utils/fakeApi';
import {
  WorkspaceErrorType,
  WorkspacePresetAction,
  WorkspaceViewType,
} from './types';
import { createCustomEmptyMapWorkspace } from '../workspaceList/utils';

export const workspaceListener = createListenerMiddleware<AppStore>();

workspaceListener.startListening({
  actionCreator: mapActions.registerMap,
  effect: async ({ payload }, listenerApi) => {
    const { mapId } = payload;
    const viewType = getViewType(listenerApi.getState());

    if (viewType === 'tiledWindow') {
      const workspaceState = getWorkspaceState(listenerApi.getState());
      // if it can't find the id, it's a view with multiple maps
      const mapPresetId =
        workspaceState!.views!.byId![mapId]?.id ||
        workspaceState!.views!.byId![workspaceState.views.allIds[0]].id!;

      listenerApi.dispatch(
        viewPresetActions.registerViewPreset({
          panelId: mapId,
          viewPresetId: mapPresetId,
        }),
      );
    }
  },
});

workspaceListener.startListening({
  actionCreator: mapActions.unregisterMap,
  effect: async ({ payload }, listenerApi) => {
    const { mapId } = payload;
    const viewType = getViewType(listenerApi.getState());

    if (viewType === 'tiledWindow') {
      listenerApi.dispatch(
        viewPresetActions.unregisterViewPreset({ panelId: mapId }),
      );
    }
  },
});

workspaceListener.startListening({
  actionCreator: workspaceActions.changePreset,
  effect: async ({ payload }, listenerApi) => {
    // Cancel any in-progress instances of this listener
    listenerApi.cancelActiveListeners();

    // give react-mosaic-component time to clear the mosaicNode
    await listenerApi.condition(
      (_action, currentState: WorkspaceModuleStore) => {
        return currentState.workspace?.mosaicNode === '';
      },
      50,
    );

    listenerApi.dispatch(syncGroupsActions.syncGroupClear());
    listenerApi.dispatch(workspaceActions.setPreset(payload));
    const allSyncGroups = getSyncGroups(payload.workspacePreset.syncGroups);
    allSyncGroups.map((syncGroup) =>
      listenerApi.dispatch(syncGroupsActions.syncGroupAddGroup(syncGroup)),
    );

    listenerApi.dispatch(
      genericActions.setLinkedMap({
        newLinks: payload.workspacePreset.linking || {},
      }),
    );

    const isTimeScrollingEnabled =
      payload.workspacePreset.isTimeScrollingEnabled || false;
    listenerApi.dispatch(
      syncGroupsActions.syncGroupToggleIsTimeScrollingEnabled({
        isTimeScrollingEnabled,
      }),
    );
  },
});

export const fetchWorkspaceViewPreset = async (
  dispatch: ThunkDispatch<WorkspaceModuleStore, unknown, AnyAction>,
  viewPresetId: string,
  mosaicNodeId: string,
): Promise<void> => {
  try {
    // set the view as loading
    dispatch(
      workspaceActions.updateWorkspaceView({
        mosaicNodeId,
        viewPreset: getViewPresetState(viewPresetId, mosaicNodeId, 'loading'),
      }),
    );

    // fetch view
    const workspaceApi = getWorkspaceApi();
    const { data } = await workspaceApi.getViewPreset(viewPresetId);

    dispatch(
      viewPresetActions.fetchedViewPreset({
        viewPreset: data,
        viewPresetId: data.id!,
        panelId: mosaicNodeId,
      }),
    );

    // sync all connected syncgroups of viewPreset to the store
    const syncGroupIds =
      (data.initialProps as InitialMapProps)?.syncGroupsIds || [];
    if (syncGroupIds && syncGroupIds.length) {
      await Promise.all(
        syncGroupIds.map((syncGroupId: string) =>
          dispatch(
            syncGroupsActions.syncGroupAddTarget({
              groupId: syncGroupId,
              targetId: mosaicNodeId,
              linked: true,
            }),
          ),
        ),
      );
    }

    // update view with fetched data
    dispatch(
      workspaceActions.updateWorkspaceView({
        mosaicNodeId,
        viewPreset: data as WorkspaceViewType,
      }),
    );
  } catch (error) {
    dispatch(
      workspaceActions.updateWorkspaceView({
        mosaicNodeId,
        viewPreset: getViewPresetState(viewPresetId, mosaicNodeId, 'error'),
      }),
    );
  }
};

workspaceListener.startListening({
  actionCreator: workspaceActions.fetchWorkspaceViewPreset,
  effect: async ({ payload }, listenerApi) => {
    const { viewPresetId, mosaicNodeId } = payload;

    await fetchWorkspaceViewPreset(
      listenerApi.dispatch,
      viewPresetId,
      mosaicNodeId,
    );
  },
});

workspaceListener.startListening({
  actionCreator: workspaceActions.fetchWorkspace,
  effect: async ({ payload }, listenerApi) => {
    // Cancel any in-progress instances of this listener
    listenerApi.cancelActiveListeners();
    const { workspaceId } = payload;

    try {
      listenerApi.dispatch(
        routerActions.navigateToUrl({
          url: getWorkspaceRouteUrl(workspaceId),
        }),
      );
      listenerApi.dispatch(
        workspaceListActions.toggleWorkspaceDialog({
          isWorkspaceListDialogOpen: false,
        }),
      );

      // fetch workspace
      const workspaceApi = getWorkspaceApi();
      const { data: workspaceData } =
        await workspaceApi.getWorkspacePreset(workspaceId);

      if (!workspaceData) {
        throw new Error(ERROR_NOT_FOUND);
      }

      listenerApi.dispatch(
        workspaceActions.loadedWorkspace({
          workspaceId,
          workspace: workspaceData,
        }),
      );

      // show all views inside a workspace as loading
      const fullLoadingWorkspace = getLoadingWorkspace(workspaceData);
      listenerApi.dispatch(
        workspaceActions.changePreset({
          workspacePreset: fullLoadingWorkspace,
        }),
      );

      // fetch all individual view presets in batches
      const workspaceIdsInBatches = getListInChunks(workspaceData.views);
      await Promise.all(
        workspaceIdsInBatches.map(async (batchCall) => {
          await Promise.all(
            batchCall.map(async (view) =>
              fetchWorkspaceViewPreset(
                listenerApi.dispatch,
                view.viewPresetId,
                view.mosaicNodeId,
              ),
            ),
          );
        }),
      );
    } catch (error) {
      const errorType =
        (error as Error).message === ERROR_NOT_FOUND
          ? WorkspaceErrorType.NOT_FOUND
          : WorkspaceErrorType.GENERIC;

      listenerApi.dispatch(
        workspaceActions.errorWorkspace({
          workspaceId,
          error: {
            message: getErrorMessage(error as AxiosError),
            type: errorType,
          },
        }),
      );
    }
  },
});

workspaceListener.startListening({
  actionCreator: workspaceActions.saveWorkspacePreset,
  effect: async ({ payload }, listenerApi) => {
    // Cancel any in-progress instances of this listener
    listenerApi.cancelActiveListeners();
    const { workspace } = payload;

    try {
      const workspaceApi = getWorkspaceApi();
      await workspaceApi.saveWorkspacePreset(workspace.id!, workspace);

      listenerApi.dispatch(workspaceActions.loadedWorkspace(undefined!));

      listenerApi.dispatch(
        workspaceListActions.onSuccessWorkspacePresetAction({
          action: WorkspacePresetAction.SAVE,
          workspaceId: workspace.id!,
          title: workspace.title,
          scope: workspace.scope || 'user',
        }),
      );
    } catch (error) {
      listenerApi.dispatch(
        workspaceActions.errorWorkspace({
          workspaceId: workspace.id!,
          error: {
            message: (error as Error).message,
            type: WorkspaceErrorType.SAVE,
          },
        }),
      );
    }
  },
});

workspaceListener.startListening({
  actionCreator: workspaceActions.changeToEmptyMapWorkspace,
  effect: async (action, listenerApi) => {
    const { newMapPresetText, newWorkspaceText } = action.payload;
    // Cancel any in-progress instances of this listener
    listenerApi.cancelActiveListeners();
    const intialViewMapPreset = mapSelectors.getdefaultMapSettings(
      listenerApi.getState(),
    );
    listenerApi.dispatch(
      workspaceActions.changePreset({
        workspacePreset: createCustomEmptyMapWorkspace(
          newWorkspaceText,
          newMapPresetText,
          intialViewMapPreset,
        ),
      }),
    );
  },
});

workspaceListener.startListening({
  actionCreator: workspaceActions.setNewWorkspaceView,
  effect: async ({ payload }, listenerApi) => {
    const { mosaicNodeId, componentType, newId } = payload;

    const bbox = mapSelectors.getBbox(listenerApi.getState(), mosaicNodeId);
    const srs = mapSelectors.getSrs(listenerApi.getState(), mosaicNodeId);

    const mapPreset = bbox.bottom ? { proj: { bbox, srs } } : {};

    listenerApi.dispatch(
      workspaceActions.addWorkspaceView({
        componentType,
        id: newId,
        initialProps: {
          mapPreset,
          syncGroupsIds: [],
        },
      }),
    );
  },
});

workspaceListener.startListening({
  actionCreator: workspaceActions.updateWorkspaceViews,
  effect: async (_, listenerApi) => {
    // Cancel any in-progress instances of this listener
    listenerApi.cancelActiveListeners();
    // Delay before starting actual work
    await listenerApi.delay(100);

    /* This will trigger all maps and other components 
    to get the correct size after views are updated */
    window.dispatchEvent(new Event('resize'));
  },
});

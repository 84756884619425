/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import * as Sentry from '@sentry/react';
import { useAuthenticationContext } from '@opengeoweb/authentication';
import { AppHeader, ConfigType } from '@opengeoweb/shared';
import { SentryRecordButton } from '@opengeoweb/core';
import { ApiProvider } from '@opengeoweb/api';
import { routerActions } from '@opengeoweb/store';
import { useDispatch } from 'react-redux';
import {
  WORKSPACE_NAMESPACE,
  workspaceActions,
  workspaceRoutes,
} from '@opengeoweb/workspace';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { OverlayStyle, ContainerStyle } from '@opengeoweb/theme';
import { ConnectionIssueBanner } from '../../components/ConnectionIssueBanner';
import UserMenu from '../../components/UserMenu';
import { createApi } from '../../utils/api';
import Menu from './Menu';

interface HeaderProps {
  config?: ConfigType;
  workspaceTopBar?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  config,
  workspaceTopBar,
}: HeaderProps) => {
  const [recordButtonEnabled, setRecordButtonEnabled] = React.useState(false);
  const sentryInitialized = !!Sentry.getClient();

  const { auth, onSetAuth, authConfig } = useAuthenticationContext();
  const dispatch = useDispatch();
  const { t: workspaceT } = useTranslation(WORKSPACE_NAMESPACE);

  const fetchWorkspace = React.useCallback(
    (newWorkspaceId: string): void => {
      dispatch(
        workspaceActions.fetchWorkspace({ workspaceId: newWorkspaceId }),
      );
    },
    [dispatch],
  );

  const changeToEmptyMapWorkspace = React.useCallback((): void => {
    dispatch(
      workspaceActions.changeToEmptyMapWorkspace({
        newMapPresetText: workspaceT('workspace-mappreset-new'),
        newWorkspaceText: workspaceT('workspace-new'),
      }),
    );
  }, [dispatch, workspaceT]);

  const navigateToUrl = React.useCallback((): void => {
    dispatch(routerActions.navigateToUrl({ url: workspaceRoutes.root }));
  }, [dispatch]);

  const navigateToEmptyWorkspace = (): void => {
    changeToEmptyMapWorkspace();
    navigateToUrl();
  };

  const workspaceId = config?.GW_INITIAL_WORKSPACE_PRESET;

  const overlayStyle = OverlayStyle();
  const containerStyle = ContainerStyle();

  return (
    <>
      <Box sx={{ ...overlayStyle }} />
      <AppHeader
        title={config?.GW_FEATURE_APP_TITLE || 'GeoWeb'}
        menu={<Menu config={config} />}
        workspaceTopBar={workspaceTopBar}
        onClick={(): void => {
          workspaceId
            ? fetchWorkspace(workspaceId)
            : navigateToEmptyWorkspace();
        }}
        userMenu={
          authConfig ? (
            <ApiProvider
              config={{
                baseURL: authConfig.GW_BE_VERSION_BASE_URL,
                appURL: authConfig.GW_APP_URL,
                authTokenURL: authConfig.GW_AUTH_TOKEN_URL,
                authClientId: authConfig.GW_AUTH_CLIENT_ID,
              }}
              auth={auth!}
              onSetAuth={onSetAuth}
              createApi={createApi}
            >
              <ConnectionIssueBanner
                auth={auth!}
                onSetAuth={onSetAuth}
                authConfig={authConfig}
              />
              {sentryInitialized && recordButtonEnabled && (
                <SentryRecordButton />
              )}

              <UserMenu
                userName={auth && auth.username ? auth.username : 'Guest'}
                userRoles={auth?.roles}
                isAuthConfigured={true}
                config={config}
                recordButtonEnabled={recordButtonEnabled}
                setRecordButtonEnabled={setRecordButtonEnabled}
                sentryInitialized={sentryInitialized}
              />
            </ApiProvider>
          ) : (
            <UserMenu userName="Guest" />
          )
        }
      />
      <Box sx={{ ...containerStyle }} />
    </>
  );
};

export default Header;

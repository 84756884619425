/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { range } from 'lodash';
import styled from '@emotion/styled';
import React from 'react';
import { useTheme } from '@mui/material';
import { useThemeContext } from '../../components/Theme/ThemeContext';

import './xmas2024/snowflake.css';
import { CSSProperties, ThemeTypes } from '../../types';

const Snowflake = styled('div')(() => ({
  '--fall-duration': `${Math.random() * 10 + 10}s`,
  '--fall-delay': `-${Math.random() * 10}s`,
  '--sway-delay': `-${Math.random() * 5}s`,
  left: `${Math.random() * 100}%`,
}));

const getSnowflakes = (): React.JSX.Element[] => {
  return range(100).map((_, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Snowflake key={index} className="snowflake">
      ❄
    </Snowflake>
  ));
};

// Overlay style

const useOverlayStyle = (): CSSProperties | undefined => {
  const [overlayStyle, setOverlayStyle] = React.useState<
    CSSProperties | undefined
  >({} as CSSProperties);

  const theme = useTheme();
  const { namedTheme } = useThemeContext();

  React.useEffect(() => {
    const isXmasStyle =
      !!theme.palette.geowebColors.festoonOverlay &&
      namedTheme?.name === ThemeTypes.XMAS2024_THEME;
    setOverlayStyle(
      isXmasStyle ? theme.palette.geowebColors.festoonOverlay : {},
    );
  }, [theme, namedTheme]);

  return overlayStyle;
};

export const OverlayStyle = (): CSSProperties | undefined => {
  const defaultStyle = {};
  const style = useOverlayStyle();
  return style === ({} as CSSProperties) ? defaultStyle : style;
};

// Container  style

const useContainerStyle = (): CSSProperties | undefined => {
  const [containerStyle, setContainerStyle] = React.useState<
    CSSProperties | undefined
  >({} as CSSProperties);

  const theme = useTheme();
  const { namedTheme } = useThemeContext();

  React.useEffect(() => {
    const isXmasStyle =
      !!theme.palette.geowebColors.festoonContainer &&
      namedTheme?.name === ThemeTypes.XMAS2024_THEME;
    setContainerStyle(
      isXmasStyle ? theme.palette.geowebColors.festoonContainer : {},
    );
  }, [theme, namedTheme]);

  return containerStyle;
};

export const ContainerStyle = (): CSSProperties | undefined => {
  const defaultStyle = {};
  const style = useContainerStyle();
  return style === ({} as CSSProperties) ? defaultStyle : style;
};

// Backdrop style

const useBackdropStyle = (): CSSProperties | undefined => {
  const [backdropStyle, setBackdropStyle] = React.useState<
    CSSProperties | undefined
  >({} as CSSProperties);

  const theme = useTheme();
  const { namedTheme } = useThemeContext();

  React.useEffect(() => {
    const isXmasThemeStyle =
      !!theme.palette.geowebColors.festoonBackdrop &&
      namedTheme?.name === ThemeTypes.XMAS2024_THEME;
    setBackdropStyle(
      isXmasThemeStyle
        ? theme.palette.geowebColors.festoonBackdrop
        : ({} as CSSProperties),
    );
  }, [theme, namedTheme]);

  return backdropStyle;
};

export const BackdropStyle = (): CSSProperties | undefined => {
  const defaultStyle = {};
  const style = useBackdropStyle();
  return style === ({} as CSSProperties) ? defaultStyle : style;
};

// Backdrop element

const useBackdropElement = (): React.JSX.Element[] | null => {
  const [backdropElement, setBackdropElement] = React.useState<
    React.JSX.Element[] | null
  >(null);

  const theme = useTheme();
  const { namedTheme } = useThemeContext();

  React.useEffect(() => {
    const isXmasThemeElem =
      !!theme.palette.geowebColors.festoonBackdrop &&
      namedTheme?.name === ThemeTypes.XMAS2024_THEME;
    setBackdropElement(isXmasThemeElem ? getSnowflakes() : null);
  }, [theme, namedTheme]);

  return backdropElement;
};

export const BackdropElement = (): React.JSX.Element[] | null => {
  const defaultElem = null;
  const elem = useBackdropElement();
  return elem === null ? defaultElem : elem;
};

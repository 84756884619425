/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { SystemScope } from '@opengeoweb/shared';
import { serviceTypes } from '@opengeoweb/store';
import { LayerProps } from '@opengeoweb/webmap';
import { Draft, EntityAdapter } from '@reduxjs/toolkit';
import { Filter, FilterType, LayerSelectStoreType } from './types';

export const getFilterId = (
  filterType: FilterType,
  filterName: string,
): string => `${filterType}-${filterName}`;

export const produceFilters = (
  filterNames: string[],
  filterType: FilterType,
  draft: Draft<LayerSelectStoreType>,
  layerSelectFilterAdapter: EntityAdapter<Filter>,
): void => {
  filterNames.forEach((filterName) => {
    layerSelectFilterAdapter.addOne(draft.filters.filters, {
      id: getFilterId(filterType, filterName),
      name: filterName,
      checked: true,
      type: filterType,
    });
  });
};

export const filterLayersFromService = (
  serviceId: string,
  services: serviceTypes.Services,
  filterIds: string[],
  allFiltersActive: boolean,
  searchString: string,
): LayerProps[] => {
  const mapStoreService = services[serviceId];
  if (!mapStoreService) {
    return [];
  }
  if (searchString === '' && allFiltersActive) {
    return mapStoreService.layers!.filter((layer) => layer.leaf);
  }
  const searchStringArray = searchString.split(' ');
  return mapStoreService
    .layers!.filter((layer) => {
      return searchStringArray.every((search) => {
        const searchLowercase = search.toLowerCase();

        const layerFilterIds = layer.path
          .map((group) => getFilterId('groups', group))
          .concat(
            layer.keywords!.map((keyword) => getFilterId('keywords', keyword)),
          );

        const isFilterInLayer =
          layerFilterIds?.some((id) => {
            return filterIds.includes(id);
          }) || allFiltersActive;

        const isSearchTextInLayerFilter = layerFilterIds?.some((filterId) =>
          filterId.toLowerCase().includes(searchLowercase),
        );

        const isSearchTextInLayerName = layer.name
          ?.toLowerCase()
          .includes(searchLowercase);
        const isSearchTextInLayerText = layer.title
          ?.toLowerCase()
          .includes(searchLowercase);
        const isSearchTextInLayerAbstract = layer.abstract
          ?.toLowerCase()
          .includes(searchLowercase);
        const isSearchTextInServiceName = mapStoreService
          .name!.toLowerCase()
          .includes(searchLowercase);
        const isSearchTextInLayer =
          isSearchTextInLayerName ||
          isSearchTextInLayerText ||
          isSearchTextInLayerAbstract ||
          isSearchTextInLayerFilter ||
          isSearchTextInServiceName;

        return isFilterInLayer && isSearchTextInLayer;
      });
    })
    .filter((layer) => layer.leaf);
};

export const isUserAddedService = (scope: SystemScope): boolean => {
  return scope === 'user';
};

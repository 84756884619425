/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  mapActions,
  mapSelectors,
  CoreAppStore,
  mapEnums,
  syncGroupsSelectors,
} from '@opengeoweb/store';

import { dateUtils } from '@opengeoweb/shared';
import {
  TimeSliderLegend,
  secondsPerPxFromCanvasWidth,
} from '@opengeoweb/timeslider';

interface TimeSliderLegendConnectProps {
  mapId: string;
  onSetTimeSliderSpan?: (
    newSpan: number,
    newCenterTime: number,
    newSecondsPerPx: number,
  ) => void;
  unfilteredSelectedTime: number;
  setUnfilteredSelectedTime: (unfilteredSelectedTime: number) => void;
  mapWindowRef?: React.MutableRefObject<HTMLElement | null>;
  adjustSelectedTimeOnWheel?: ({
    event,
    deltaY,
  }: {
    event: WheelEvent | KeyboardEvent;
    deltaY: number;
  }) => void;
}

export const TimeSliderLegendConnect: React.FC<
  TimeSliderLegendConnectProps
> = ({
  mapId,
  onSetTimeSliderSpan,
  unfilteredSelectedTime,
  setUnfilteredSelectedTime,
  mapWindowRef,
  adjustSelectedTimeOnWheel,
}) => {
  const isTimeScrollingEnabled = useSelector(
    syncGroupsSelectors.isTimeScrollingEnabled,
  );

  const centerTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderCenterTime(store, mapId),
  );
  const timeSliderWidth = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderWidth(store, mapId),
  );
  const secondsPerPx = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderSecondsPerPx(store, mapId),
  );

  const timeStep = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeStep(store, mapId),
  );
  const isTimeSliderHoverOn = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimeSliderHoverOn(store, mapId),
  );

  const { animationStartTime, animationEndTime } = useSelector(
    (store: CoreAppStore) => mapSelectors.getAnimationRange(store, mapId),
  );

  const timeSliderSpan = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderSpan(store, mapId),
  );

  const currentTime = dateUtils.unix(Date.now());

  const [dataStartTime, dataEndTime] = useSelector((store: CoreAppStore) =>
    mapSelectors.getDataLimitsFromLayers(store, mapId),
  );
  const dispatch = useDispatch();
  const [isDraggingStartAnimation, setIsDraggingStartAnimation] =
    React.useState(false);
  const [isDraggingEndAnimation, setIsDraggingEndAnimation] =
    React.useState(false);

  function convertStringTimeToUnix(
    time: string | undefined,
  ): number | undefined {
    return time ? dateUtils.unix(new Date(time)) : undefined;
  }

  const handleStartTimeChangeInLegend = (startTime: number): void => {
    const newTime = convertStringTimeToUnix(animationStartTime);
    if (newTime !== startTime) {
      dispatch(
        mapActions.setAnimationStartTime({
          mapId,
          animationStartTime: dateUtils.fromUnix(startTime).toISOString(),
          origin: mapEnums.MapActionOrigin.map,
        }),
      );
    }

    setIsDraggingStartAnimation(true);
  };

  const handleEndTimeChangeInLegend = (endTime: number): void => {
    const newTime = convertStringTimeToUnix(animationEndTime);
    if (newTime !== endTime) {
      dispatch(
        mapActions.setAnimationEndTime({
          mapId,
          animationEndTime: dateUtils.fromUnix(endTime).toISOString(),
          origin: mapEnums.MapActionOrigin.map,
        }),
      );
    }

    setIsDraggingEndAnimation(true);
  };

  return (
    <TimeSliderLegend
      mapId={mapId}
      isTimeScrollingEnabled={isTimeScrollingEnabled}
      mapWindowRef={mapWindowRef}
      centerTime={centerTime!}
      timeSliderWidth={timeSliderWidth!}
      secondsPerPx={secondsPerPx!}
      currentTime={currentTime}
      isTimeSliderHoverOn={isTimeSliderHoverOn}
      dataStartTime={dataStartTime}
      dataEndTime={dataEndTime}
      reduxAnimationStartTime={animationStartTime}
      reduxAnimationEndTime={animationEndTime}
      timeStep={timeStep}
      unfilteredSelectedTime={unfilteredSelectedTime}
      isDraggingStartAnimation={isDraggingStartAnimation}
      timeSliderSpan={timeSliderSpan}
      setIsDraggingStartAnimation={setIsDraggingStartAnimation}
      isDraggingEndAnimation={isDraggingEndAnimation}
      setIsDraggingEndAnimation={setIsDraggingEndAnimation}
      onStartTimeChange={handleStartTimeChangeInLegend}
      onEndTimeChange={handleEndTimeChangeInLegend}
      onSetTimeSliderSpan={onSetTimeSliderSpan}
      setUnfilteredSelectedTime={setUnfilteredSelectedTime}
      onSetCenterTime={(newTime: number): void => {
        dispatch(
          mapActions.setTimeSliderCenterTime({
            mapId,
            timeSliderCenterTime: newTime,
          }),
        );
      }}
      onZoom={(newSecondsPerPx, newCenterTime): void => {
        dispatch(
          mapActions.setTimeSliderSecondsPerPx({
            mapId,
            timeSliderSecondsPerPx: newSecondsPerPx,
          }),
        );
        dispatch(
          mapActions.setTimeSliderCenterTime({
            mapId,
            timeSliderCenterTime: newCenterTime,
          }),
        );
      }}
      onSetAnimationStartTime={(animationStartTime: string): void => {
        dispatch(
          mapActions.setAnimationStartTime({
            mapId,
            animationStartTime,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
      }}
      onSetAnimationEndTime={(animationEndTime: string): void => {
        dispatch(
          mapActions.setAnimationEndTime({
            mapId,
            animationEndTime,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
      }}
      updateCanvasWidth={(storeWidth: number, newWidth: number): void => {
        if (storeWidth !== newWidth) {
          dispatch(
            mapActions.setTimeSliderWidth({
              mapId,
              timeSliderWidth: newWidth,
            }),
          );
          const spanInSeconds = secondsPerPx! * storeWidth;

          const newSecondsPerPx = secondsPerPxFromCanvasWidth(
            newWidth,
            spanInSeconds,
          );
          if (newSecondsPerPx !== undefined) {
            dispatch(
              mapActions.setTimeSliderSecondsPerPx({
                mapId,
                timeSliderSecondsPerPx: newSecondsPerPx,
              }),
            );
          }
        }
      }}
      adjustSelectedTimeOnWheel={adjustSelectedTimeOnWheel}
    />
  );
};

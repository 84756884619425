/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomIconButton } from '@opengeoweb/shared';
import { MapAdd } from '@opengeoweb/theme';
import { LayerType } from '@opengeoweb/webmap';
import { serviceTypes } from '@opengeoweb/store';
import AddLayersPopup from '../AddLayersPopup/AddLayersPopup';
import { preloadedDefaultMapServices } from '../../../utils/defaultConfigurations';

const styles = {
  buttonDiv: {
    position: 'relative',
  },
  loadingButton: {
    position: 'absolute',
    top: 7,
    left: 7,
    zIndex: 1,
  },
  button: {
    margin: 'auto 0px auto 32px',
  },
};

interface AddLayerButtonProps {
  onRenderTree?: (service: serviceTypes.InitialService) => React.ReactNode;
  preloadedServices?: serviceTypes.InitialService[];
  tooltip?: string;
  layerType?: LayerType;
  shouldFocus?: boolean;
}

const AddLayersButton: React.FC<AddLayerButtonProps> = ({
  onRenderTree,
  tooltip = '',
  preloadedServices = preloadedDefaultMapServices,
  layerType = LayerType.mapLayer,
  shouldFocus = false,
}: AddLayerButtonProps) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = (): void => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (shouldFocus && ref.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomIconButton
        onClick={handleClose}
        sx={styles.button}
        disableRipple
        data-testid="addLayersButton"
        ref={ref}
        tooltipTitle={tooltip}
      >
        <MapAdd />
      </CustomIconButton>
      <AddLayersPopup
        open={open}
        handleClose={handleClose}
        onRenderTree={onRenderTree}
        preloadedServices={preloadedServices}
        layerType={layerType}
      />
    </>
  );
};

export default AddLayersButton;

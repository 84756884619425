/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { getApi } from '@opengeoweb/api';
import { chunk } from 'lodash';
import { syncGroupsTypes } from '@opengeoweb/store';
import { MosaicNode, getLeaves } from 'react-mosaic-component';
import { AxiosError } from 'axios';
import { API_NAME, PresetsApi } from '../../utils/api';
import {
  WorkspacePreset,
  WorkspacePresetFromBE,
  WorkspaceViewsFromBE,
  ViewType,
  WorkspaceViewPresetComponentsLookupType,
  WorkspaceSyncGroup,
} from './types';

interface ErrorData {
  message: string;
}

export const getWorkspaceApi = (): PresetsApi => getApi<PresetsApi>(API_NAME);

export const getViewPresetState = (
  viewPresetId: string,
  mosaicNodeId: string,
  state: 'loading' | 'error',
): {
  title: string;
  id: string;
  mosaicNodeId: string;
  componentType: WorkspaceViewPresetComponentsLookupType;
} => {
  const titlePrefix = state === 'loading' ? 'Loading' : 'Error';
  const componentType = state === 'loading' ? 'ViewLoading' : 'ViewError';

  return {
    title: `${titlePrefix} ${viewPresetId}`,
    id: viewPresetId,
    mosaicNodeId,
    componentType,
  };
};

export const getLoadingWorkspace = (
  workspacePreset: WorkspacePresetFromBE,
): WorkspacePreset => {
  const views = workspacePreset.views.reduce(
    (object, viewDetails) => {
      return {
        allIds: [...object.allIds, viewDetails.mosaicNodeId],
        byId: {
          ...object.byId,
          [viewDetails.mosaicNodeId]: getViewPresetState(
            viewDetails.viewPresetId,
            viewDetails.mosaicNodeId,
            'loading',
          ),
        },
      };
    },
    { allIds: [] as string[], byId: {} },
  );

  const combinedWorkspacePreset = {
    ...workspacePreset,
    views,
  };
  return combinedWorkspacePreset;
};

const BATCH_SIZE = 3;
export const getListInChunks = (
  views: WorkspaceViewsFromBE[],
): WorkspaceViewsFromBE[][] => chunk(views, BATCH_SIZE);

export const defaultSyncGroups = syncGroupsTypes.SyncGroupTypeList.map(
  (syncgroupType) => ({
    groupId: syncgroupType,
    title: `Default group for ${syncgroupType}`,
    type: syncgroupType as syncGroupsTypes.SyncType,
  }),
);

export const getSyncGroups = (
  syncGroups: WorkspaceSyncGroup[] = [],
): syncGroupsTypes.SyncGroupAddGroupPayload[] => {
  const presetSyncGroups = syncGroups.map((syncGroup) => ({
    groupId: syncGroup.id,
    title: syncGroup.title || syncGroup.id,
    type: syncGroup.type,
  }));

  //  Only add a default sync group for a type that is not used yet
  defaultSyncGroups.forEach(
    (defaultSyncGroup) =>
      presetSyncGroups.findIndex(
        (presetSyncGroup) => presetSyncGroup.type === defaultSyncGroup.type,
      ) === -1 && presetSyncGroups.push(defaultSyncGroup),
  );

  return presetSyncGroups;
};

export const tiledWindowComponentTypes = [
  'MultiMap',
  'ModelRunInterval',
  'HarmonieTempAndPrecipPreset',
];

export const getViewType = (
  numViews: number,
  firstViewComponentType: string,
): ViewType => {
  // If more than one view set multiWindow
  if (numViews > 1) {
    return 'multiWindow';
  }
  if (numViews === 1) {
    // set tiledWindow if multimap
    return tiledWindowComponentTypes.includes(firstViewComponentType)
      ? 'tiledWindow'
      : 'singleWindow';
  }
  // no views
  return undefined!;
};

/**
 * Get all view ids from the mosaicnode state
 * @param mNode MosaicNode state
 * @returns List if string ids of all mosaic nodes.
 */
export const makeListOfViewIds = (mNode: MosaicNode<string>): string[] => {
  return getLeaves(mNode);
};

export const getErrorMessage = (error: AxiosError<unknown>): string => {
  const prefix = error.message;
  const suffix = (error.response?.data as ErrorData)?.message;

  return suffix ? `${prefix}: ${suffix}` : prefix;
};

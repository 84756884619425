/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { ReactElement } from 'react';
import { Box, Button, Card, Grid2 as Grid, Typography } from '@mui/material';

import { useApiContext } from '@opengeoweb/api';
import {
  StatusTag,
  useIsMounted,
  CustomTooltip,
  dateUtils,
} from '@opengeoweb/shared';
import { Edit, Success, Clock } from '@opengeoweb/theme';
import {
  CancelSigmet,
  isInstanceOfCancelSigmet,
  Sigmet,
  ProductType,
  CancelAirmet,
  Airmet,
  isInstanceOfSigmetOrAirmet,
  isInstanceOfCancelSigmetOrAirmet,
  BaseProduct,
  ProductConfig,
} from '../../types';
import { noTAC, shouldRetrieveTAC } from '../ProductForms/ProductFormTac';
import { SigmetAirmetApi } from '../../utils/api';
import { useSigmetAirmetTranslation } from '../../utils/i18n';
import { getPhenomenon } from '../ProductForms/utils';

const styles = {
  activeStatus: {
    color: '#72bb23',
    paddingLeft: '6px',
  },
  font: {
    fontWeight: 'normal',
  },
};

export const getActiveStatus = (
  product: Sigmet | CancelSigmet | Airmet | CancelAirmet,
): ReactElement => {
  // do not return an icon if product is expired
  if (dateUtils.utc(product.validDateEnd) < dateUtils.utc()) {
    return null!;
  }

  const active = dateUtils.utc(product.validDateStart) <= dateUtils.utc();
  return active ? (
    <Success data-testid="status-active" sx={styles.activeStatus} />
  ) : (
    <Clock data-testid="status-inactive" sx={styles.activeStatus} />
  );
};

const getStatusTagList = (
  product: CancelSigmet | Sigmet | Airmet | CancelAirmet,
  cancelsSeqId: string | null,
): ReactElement => {
  if (cancelsSeqId !== null) {
    return (
      <StatusTag
        content={`cancels ${cancelsSeqId}`}
        color="red"
        sx={{ width: '80px' }}
      />
    );
  }
  if (product.status === 'CANCELLED') {
    return (
      <StatusTag
        content={`cancelled ${product.sequence}`}
        color="red"
        sx={{ width: '80px' }}
      />
    );
  }

  if (dateUtils.utc(product.validDateEnd) < dateUtils.utc()) {
    return <StatusTag content="expired" color="grey" sx={{ width: '80px' }} />;
  }

  return (
    <StatusTag
      content={product.status.toLowerCase()}
      color={product.status === 'PUBLISHED' ? 'green' : 'grey'}
      sx={{ marginRight: '0!important', width: '80px' }}
    />
  );
};

const getCancelSeqId = (product: CancelSigmet | CancelAirmet): string => {
  if (isInstanceOfCancelSigmet(product)) {
    return product.cancelsSigmetSequenceId;
  }
  return product.cancelsAirmetSequenceId;
};

const formatValidTime = (
  startDate: string,
  endDate: string,
  format: string,
): string => {
  if (!startDate || !endDate) {
    return '-';
  }

  return `${dateUtils.dateToString(
    dateUtils.utc(startDate),
    dateUtils.DATE_FORMAT_NAME_OF_MONTH,
  )} - ${dateUtils.dateToString(dateUtils.utc(endDate), format)} UTC`;
};

interface ProductListRowHeaderProps {
  title: string;
}

const ProductListRowHeader: React.FC<ProductListRowHeaderProps> = ({
  title,
}: ProductListRowHeaderProps) => (
  <Typography
    sx={{
      color: 'geowebColors.textInputField.label.rgba',
      fontSize: '0.75rem',
    }}
  >
    {title}
  </Typography>
);

export interface ProductListRowProps {
  product: CancelSigmet | Sigmet | Airmet | CancelAirmet;
  productType: ProductType;
  productConfig: ProductConfig;
}

const listRowStyles = {
  width: '100%',
  '.productListContainer': {
    containerType: 'inline-size',
    containerName: 'productList',
  },
  '.rowContainer': {
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 1.5,
    paddingBottom: 1.5,
  },
  '.col-1': {
    width: '25%',
    flexBasis: '25%',
  },
  '.col-2': {
    width: '75%',
    flexBasis: '75%',
  },
  '.col-3': {
    width: '100%',
    flexBasis: '100%',
    paddingTop: 2,
  },
  '.col-4': {
    width: '100%',
    flexBasis: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 2,
  },
  '@container productList (width > 720px)': {
    '.productListContainer': {
      '.rowContainer': {
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        paddingBottom: 1.5,
      },
      '.col-1': {
        width: '10%',
        flexBasis: '10%',
      },
      '.col-2': {
        width: '25%',
        flexBasis: '25%',
      },
      '.col-3': {
        width: '40%',
        flexBasis: '40%',
        paddingTop: 0,
      },
      '.col-4': {
        justifyContent: 'right',
        alignItems: 'center',
        width: '25%',
        flexBasis: '25%',
        paddingTop: 0,
        button: {
          marginRight: 3,
        },
        ' .statusTag': {
          marginRight: 3,
        },
      },
    },
  },
};

const ProductListRow: React.FC<ProductListRowProps> = ({
  product,
  productType,
  productConfig,
}: ProductListRowProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { api } = useApiContext<SigmetAirmetApi>();
  const { isMounted } = useIsMounted();
  const [TAC, setTAC] = React.useState('');
  const apiCall =
    productType === 'sigmet' ? api.getSigmetTAC : api.getAirmetTAC;

  React.useEffect(() => {
    const retrieveTAC = (
      productToPost: CancelSigmet | Sigmet | Airmet | CancelAirmet,
    ): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      apiCall(productToPost as any)
        .then((result) => {
          if (isMounted.current) {
            setTAC(result.data);
          }
        })
        .catch(() => {});
    };
    if (shouldRetrieveTAC(product)) {
      retrieveTAC(product);
    } else if (isMounted.current) {
      setTAC(noTAC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, apiCall]);

  const cancelsSeqId = isInstanceOfCancelSigmetOrAirmet(product)
    ? getCancelSeqId(product)
    : null!;

  const fir = product.firName || '';

  const phenomenon =
    isInstanceOfSigmetOrAirmet(product) && product.phenomenon
      ? product.phenomenon
      : null!;

  const validTimeFormat =
    dateUtils.dateToString(
      dateUtils.utc(product.validDateStart),
      dateUtils.DATE_FORMAT_YEAR,
    ) ===
    dateUtils.dateToString(
      dateUtils.utc(product.validDateEnd),
      dateUtils.DATE_FORMAT_YEAR,
    )
      ? dateUtils.DATE_FORMAT_HOURS
      : dateUtils.DATE_FORMAT_NAME_OF_MONTH;

  const validTime = formatValidTime(
    product.validDateStart,
    product.validDateEnd,
    validTimeFormat,
  );

  const getLabel = (product: BaseProduct): string => {
    const isSigmetOrAirmet = (
      product: BaseProduct,
    ): product is Sigmet | Airmet => {
      return 'type' in product && 'phenomenon' in product;
    };

    if (isSigmetOrAirmet(product)) {
      const { phenomenon, type } = product;

      if (type === 'TEST' && (phenomenon as string) === 'VA_CLD') {
        return 'VA TEST';
      }
      if (type === 'TEST') {
        return 'TEST';
      }
      if (type === 'SHORT_TEST') {
        return 'TEST';
      }
      if (type === 'SHORT_VA_TEST') {
        return 'VA TEST';
      }
      return phenomenon;
    }
    return '';
  };
  return (
    <Card elevation={0} variant="outlined" sx={listRowStyles}>
      <Box className="productListContainer">
        <Grid
          container
          alignItems="center"
          spacing={0}
          className="rowContainer"
        >
          <Grid className="col-1">
            {phenomenon !== null && (
              <ProductListRowHeader title={productType.toUpperCase()} />
            )}

            <CustomTooltip
              title={
                phenomenon !== null
                  ? String(getPhenomenon(fir, phenomenon, productConfig))
                  : ''
              }
              placement="bottom-start"
            >
              <Typography variant="subtitle2" noWrap sx={styles.font}>
                {phenomenon !== null ? getLabel(product) : 'Cancel'}
              </Typography>
            </CustomTooltip>
          </Grid>

          <Grid className="col-2">
            <ProductListRowHeader title={t('issue-time')} />
            <CustomTooltip
              title={
                <>
                  <ProductListRowHeader title="TAC" />
                  <Typography
                    style={{ whiteSpace: 'pre-line' }}
                    variant="subtitle2"
                    sx={{
                      ...styles.font,
                      color: 'geowebColors.typographyAndIcons.text',
                      fontSize: '1.125rem',
                      fontWeight: 'normal',
                      fontStyle: 'normal',
                      lineHeight: '28px',
                      letterSpacing: '0.5px',
                    }}
                  >
                    {TAC}
                  </Typography>
                </>
              }
              placement="bottom-start"
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'geowebColors.background.surface',
                    boxShadow: 8,
                    '& .MuiTooltip-arrow': {
                      color: 'common.black',
                    },
                    maxWidth: 472,
                  },
                },
              }}
            >
              <Typography
                variant="subtitle2"
                sx={styles.font}
                data-testid="issueTime"
              >
                {product.issueDate
                  ? `${dateUtils.dateToString(
                      dateUtils.utc(product.issueDate),
                      dateUtils.DATE_FORMAT_NAME_OF_MONTH,
                    )} UTC`
                  : t('not-published')}
              </Typography>
            </CustomTooltip>
          </Grid>

          <Grid className="col-3">
            <ProductListRowHeader title={t('valid-time')} />
            <Typography
              variant="subtitle2"
              sx={styles.font}
              data-testid="validTime"
            >
              {validTime}
            </Typography>
          </Grid>

          <Grid className="col-4" container>
            {product.status === 'DRAFT' ? (
              <Button
                sx={{ marginTop: '6px' }}
                variant="flat"
                startIcon={<Edit />}
              >
                {t('draft')}
              </Button>
            ) : (
              getStatusTagList(product, cancelsSeqId)
            )}
            {product.status === 'PUBLISHED' &&
              cancelsSeqId === null &&
              getActiveStatus(product)}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default ProductListRow;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Airmet,
  AirmetFromBackend,
  AirmetMovementType,
  CancelAirmet,
  CancelSigmet,
  Change,
  LevelInfoMode,
  LevelType,
  ObservationOrForcast,
  ProductStatus,
  Sigmet,
  SigmetFromBackend,
} from '@opengeoweb/api';
import {
  AviationPhenomenaCode,
  AviationProduct,
  getDefaultValidUntilValue,
  isInstanceOfCancelAirmet,
  isInstanceOfCancelSigmet,
  ProductConfig,
} from '@opengeoweb/sigmet-airmet';
import { dateUtils } from '@opengeoweb/shared';
import {
  Area,
  Warning,
  PublicWarningStatus,
  AviationWarningDetails,
  AirmetWarning,
  SigmetWarning,
} from '../store/publicWarningForm/types';

export function isCancelAirmet(
  warningDetail: AviationWarningDetails,
): warningDetail is CancelAirmet {
  return (warningDetail as CancelAirmet).cancelsAirmetSequenceId !== undefined;
}

export function isCancelSigmet(
  warningDetail: AviationWarningDetails,
): warningDetail is CancelSigmet {
  return (warningDetail as CancelSigmet).cancelsSigmetSequenceId !== undefined;
}

export const flattenPublicWarning = (
  publicWarning: Warning,
): Partial<AviationWarningDetails> => {
  if (publicWarning.type === 'public') {
    return {};
  }

  const { sigmetAirmetDetails, ...rest } = publicWarning;

  return {
    ...rest,
    ...sigmetAirmetDetails,
  };
};

export const transformAirmetToWarningFormat = (
  apiAirmet: AirmetFromBackend,
): AirmetWarning => {
  const airmet = apiAirmet.airmet as Airmet;

  const areas: Area[] = [];

  if (airmet.startGeometry) {
    areas.push({
      geoJSON: {
        ...airmet.startGeometry,
        features: airmet.startGeometry.features.map((feature) => ({
          ...feature,
          properties: {
            ...feature.properties,
            isStartGeometry: true,
          },
        })),
      },
      objectName: airmet.locationIndicatorATSR,
    });
  }

  return {
    id: apiAirmet.uuid,
    lastUpdatedTime: apiAirmet.lastUpdateDate,
    productStatus: airmet.status,
    warningDetail: {
      id: airmet.uuid,
      phenomenon: airmet.phenomenon,
      areas,
      validFrom: airmet.validDateStart,
      validUntil: airmet.validDateEnd,
      incident: airmet.sequence,
      level: 'AIR',
      probability: 0,
      descriptionOriginal: '',
      descriptionTranslation: '',
    },
    sigmetAirmetDetails: {
      ...airmet,
    },
    type: 'airmet',
  };
};

export const transformSigmetToWarningFormat = (
  apiSigmet: SigmetFromBackend,
): SigmetWarning => {
  const sigmet = apiSigmet.sigmet as Sigmet;

  const areas: Area[] = [];

  if (sigmet.startGeometry) {
    areas.push({
      areaId: `${sigmet.uuid}-start`,
      geoJSON: {
        ...sigmet.startGeometry,
        features: sigmet.startGeometry.features.map((feature) => ({
          ...feature,
          properties: {
            ...feature.properties,
            isStartGeometry: true,
          },
        })),
      },
      objectName: sigmet.locationIndicatorATSR,
    });
  }

  if (sigmet.endGeometry) {
    areas.push({
      areaId: `${sigmet.uuid}-end`,
      geoJSON: {
        ...sigmet.endGeometry,
        features: sigmet.endGeometry.features.map((feature) => ({
          ...feature,
          properties: {
            ...feature.properties,
            isStartGeometry: false,
          },
        })),
      },
      objectName: sigmet.locationIndicatorATSR,
    });
  }

  return {
    id: apiSigmet.uuid,
    lastUpdatedTime: apiSigmet.lastUpdateDate,
    productStatus: sigmet.status,
    warningDetail: {
      id: sigmet.uuid,
      phenomenon: sigmet.phenomenon,
      areas,
      validFrom: sigmet.validDateStart,
      validUntil: sigmet.validDateEnd,
      incident: sigmet.sequence,
      level: 'SIG',
      probability: 0,
      descriptionOriginal: '',
      descriptionTranslation: '',
    },
    sigmetAirmetDetails: {
      ...sigmet,
    },
    type: 'sigmet',
  };
};

export const mapProductStatusToWarningStatus = (
  productStatus?: ProductStatus,
): PublicWarningStatus => {
  switch (productStatus) {
    case ProductStatus.PUBLISHED:
      return PublicWarningStatus.PUBLISHED;
    case ProductStatus.DRAFT:
      return PublicWarningStatus.DRAFT;
    case ProductStatus.CANCELLED:
    case ProductStatus.EXPIRED:
    case ProductStatus.DISCARDED:
    default:
      return PublicWarningStatus.EXPIRED;
  }
};

export const transformSigmetToProductFormat = (
  warning: SigmetFromBackend | undefined,
): AviationProduct => {
  const sigmet = warning?.sigmet as Sigmet;
  const cancelSigmet = warning?.sigmet as CancelSigmet;

  return {
    uuid: sigmet?.uuid || 'default', // Use a fallback UUID or handle undefined
    status: sigmet.status,
    issueDate: sigmet.issueDate,
    locationIndicatorATSR: sigmet.locationIndicatorATSR,
    locationIndicatorATSU: sigmet.locationIndicatorATSU,
    locationIndicatorMWO: sigmet.locationIndicatorMWO,
    phenomenon: sigmet.phenomenon as unknown as AviationPhenomenaCode,
    sequence: sigmet.sequence,
    validDateStart: sigmet.validDateStart,
    validDateEnd: sigmet.validDateEnd,
    firName: sigmet.firName,
    type: sigmet.type,
    isObservationOrForecast: sigmet.isObservationOrForecast,
    observationOrForecastTime: sigmet.observationOrForecastTime,
    change: sigmet.change,
    startGeometry: sigmet.startGeometry,
    startGeometryIntersect: sigmet.startGeometryIntersect,
    endGeometry: sigmet.endGeometry,
    endGeometryIntersect: sigmet.endGeometryIntersect,
    firGeometry: sigmet.firGeometry,
    levelInfoMode: sigmet.levelInfoMode,
    level: sigmet.level,
    lowerLevel: sigmet.lowerLevel,
    movementType: sigmet.movementType,
    movementDirection: sigmet.movementDirection,
    movementSpeed: sigmet.movementSpeed,
    movementUnit: sigmet.movementUnit,
    vaSigmetMoveToFIR: sigmet.vaSigmetMoveToFIR,
    vaSigmetVolcanoName: sigmet.vaSigmetVolcanoName,
    vaSigmetVolcanoCoordinates: sigmet.vaSigmetVolcanoCoordinates,
    ...(isInstanceOfCancelSigmet(sigmet as unknown as CancelSigmet) && {
      cancelsSigmetSequenceId: cancelSigmet.cancelsSigmetSequenceId,
    }),
    ...(isInstanceOfCancelSigmet(sigmet as unknown as CancelSigmet) && {
      validDateStartOfSigmetToCancel:
        cancelSigmet.validDateStartOfSigmetToCancel,
    }),
    ...(isInstanceOfCancelSigmet(sigmet as unknown as CancelSigmet) && {
      validDateEndOfSigmetToCancel: cancelSigmet.validDateEndOfSigmetToCancel,
    }),
  };
};

export const transformAirmetToProductFormat = (
  warning: AirmetFromBackend | undefined,
): AviationProduct => {
  const airmet = warning?.airmet as Airmet;
  const cancelAirmet = warning?.airmet as CancelAirmet;

  return {
    uuid: airmet?.uuid || 'default', // Use a fallback UUID or handle undefined
    status: airmet.status,
    issueDate: airmet.issueDate,
    locationIndicatorATSR: airmet.locationIndicatorATSR,
    locationIndicatorATSU: airmet.locationIndicatorATSU,
    locationIndicatorMWO: airmet.locationIndicatorMWO,
    phenomenon: airmet.phenomenon as unknown as AviationPhenomenaCode,
    sequence: airmet.sequence,
    validDateStart: airmet.validDateStart,
    validDateEnd: airmet.validDateEnd,
    firName: airmet.firName,
    type: airmet.type,
    isObservationOrForecast:
      airmet.isObservationOrForecast as ObservationOrForcast,
    change: airmet.change as Change,
    startGeometry: airmet.startGeometry,
    startGeometryIntersect: airmet.startGeometryIntersect,
    observationOrForecastTime: airmet.observationOrForecastTime,
    firGeometry: airmet.firGeometry,
    levelInfoMode: airmet.levelInfoMode,
    level: airmet.level,
    lowerLevel: airmet.lowerLevel,
    movementType: airmet.movementType,
    movementDirection: airmet.movementDirection,
    movementSpeed: airmet.movementSpeed,
    movementUnit: airmet.movementUnit,
    visibilityValue: airmet.visibilityValue,
    visibilityCause: airmet.visibilityCause,
    visibilityUnit: airmet.visibilityUnit,
    windSpeed: airmet.windSpeed,
    windUnit: airmet.windUnit,
    windDirection: airmet.windDirection,
    cloudLevelInfoMode: airmet.cloudLevelInfoMode,
    cloudLevel: airmet.cloudLevel,
    cloudLowerLevel: airmet.cloudLowerLevel,
    ...(isInstanceOfCancelAirmet(airmet as unknown as CancelAirmet) && {
      cancelsAirmetSequenceId: cancelAirmet.cancelsAirmetSequenceId,
    }),
    ...(isInstanceOfCancelAirmet(airmet as unknown as CancelAirmet) && {
      validDateStartOfAirmetToCancel:
        cancelAirmet.validDateStartOfAirmetToCancel,
    }),
    ...(isInstanceOfCancelAirmet(airmet as unknown as CancelAirmet) && {
      validDateEndOfAirmetToCancel: cancelAirmet.validDateEndOfAirmetToCancel,
    }),
  };
};
export const transformSigmetFromBackendToProductFormat = (
  warning: SigmetFromBackend,
): AviationProduct => transformSigmetToProductFormat(warning);

export const transformAirmetFromBackendToProductFormat = (
  warning: AirmetFromBackend,
): AviationProduct => transformAirmetToProductFormat(warning);

// For the Create and Clear buttons
// The fields initialised to unknown will be set to their default values by ReactHookForm
export const getEmptySigmetWarning = (): AviationProduct => {
  const currentTime = new Date();
  const validFromTime = dateUtils.add(currentTime, { minutes: 15 });
  const validUntilTime = dateUtils.add(currentTime, { hours: 4 });

  return {
    type: undefined as unknown as
      | 'NORMAL'
      | 'TEST'
      | 'EXERCISE'
      | 'SHORT_TEST'
      | 'SHORT_VA_TEST',
    phenomenon: '' as AviationPhenomenaCode,
    status: '' as ProductStatus,
    isObservationOrForecast: '' as ObservationOrForcast,
    issueDate: undefined,
    locationIndicatorATSR: undefined as unknown as string,
    locationIndicatorATSU: undefined as unknown as string,
    locationIndicatorMWO: undefined as unknown as string,
    change: '' as Change, // Default change
    sequence: undefined,
    validDateStart: dateUtils.dateToString(validFromTime)!,
    validDateEnd: dateUtils.dateToString(validUntilTime)!,
    level: '' as unknown as LevelType,
    levelInfoMode: '' as LevelInfoMode,
    movementType: '' as AirmetMovementType,
    firName: undefined as unknown as string,
  };
};

// For the Create and Clear buttons
// The fields initialised to unknown will be set to their default values by ReactHookForm
export const getEmptyAirmetWarning = (): AviationProduct => {
  const currentTime = new Date();
  const validFromTime = dateUtils.add(currentTime, { minutes: 15 });
  const validUntilTime = dateUtils.add(currentTime, { hours: 4 });

  return {
    type: undefined as unknown as 'NORMAL' | 'TEST' | 'EXERCISE',
    phenomenon: '' as AviationPhenomenaCode,
    status: '' as ProductStatus,
    isObservationOrForecast: '' as ObservationOrForcast,
    issueDate: undefined,
    locationIndicatorATSR: undefined as unknown as string,
    locationIndicatorATSU: undefined as unknown as string,
    locationIndicatorMWO: undefined as unknown as string,
    change: '' as Change, // Default change
    sequence: undefined as unknown as string,
    validDateStart: dateUtils.dateToString(validFromTime)!,
    validDateEnd: dateUtils.dateToString(validUntilTime)!,
    level: '' as unknown as LevelType,
    levelInfoMode: '' as LevelInfoMode,
    movementType: '' as AirmetMovementType,
    firName: undefined as unknown as string,
    startGeometry: {
      type: 'FeatureCollection',
      features: [],
    },
    startGeometryIntersect: {
      type: 'FeatureCollection',
      features: [],
    },
  };
};

export const renewAviationProduct = <T extends SigmetWarning | AirmetWarning>(
  aviationWarning: T,
  productConfig: ProductConfig,
): T => {
  const warningDetail = aviationWarning.sigmetAirmetDetails;

  const newValidUntil =
    'phenomenon' in warningDetail
      ? getDefaultValidUntilValue(
          productConfig,
          warningDetail.phenomenon,
          warningDetail.locationIndicatorATSR,
          warningDetail.validDateEnd,
        )
      : warningDetail.validDateEnd;

  const renewedWarningDetail = {
    ...warningDetail,
    validDateStart: warningDetail.validDateEnd,
    validDateEnd: newValidUntil,
    status: ProductStatus.DRAFT,
    uuid: undefined,
    sequence: '-1',
  };

  return {
    ...aviationWarning,
    sigmetAirmetDetails: renewedWarningDetail,
  };
};

export const duplicateAviationProduct = <
  T extends SigmetWarning | AirmetWarning,
>(
  aviationWarning: T,
): T => {
  const duplicatedWarningDetail = {
    ...aviationWarning.sigmetAirmetDetails,
    status: ProductStatus.DRAFT,
    uuid: undefined,
    sequence: '-1',
  };

  return {
    ...aviationWarning,
    sigmetAirmetDetails: duplicatedWarningDetail,
  };
};

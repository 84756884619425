/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  CoreAppStore,
  uiSelectors,
  uiTypes,
  useSetupDialog,
} from '@opengeoweb/store';

import DimensionSelectDialogConnect from './DimensionSelectDialogConnect';

export const getDimensionType = (dimensionName: string): string => {
  return `dimensionSelect-${dimensionName}`;
};
interface MultiMapSingleDimensionSelectConnectProps {
  dimensionName: string;
  index?: number;
}

const MultiMapSingleDimensionSelectConnect: React.FC<
  MultiMapSingleDimensionSelectConnectProps
> = ({ dimensionName, index }: MultiMapSingleDimensionSelectConnectProps) => {
  const uiDialogType = getDimensionType(dimensionName) as uiTypes.DialogType;

  const mapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getDialogMapId(store, uiDialogType),
  );

  const { dialogOrder, isDialogOpen, onCloseDialog, setDialogOrder, uiSource } =
    useSetupDialog(uiDialogType);

  return (
    <DimensionSelectDialogConnect
      mapId={mapId}
      dimensionName={dimensionName}
      onMouseDown={setDialogOrder}
      onCloseDialog={onCloseDialog}
      order={dialogOrder}
      source={uiSource}
      isOpen={isDialogOpen}
      index={index}
    />
  );
};

export default MultiMapSingleDimensionSelectConnect;

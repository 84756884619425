/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createFakeApiInstance } from '@opengeoweb/api';
import { PresetScope } from '@opengeoweb/shared';
import { AxiosInstance } from 'axios';
import { isEmpty } from 'lodash';
import { ViewPreset } from '../store/viewPresets/types';
import { FetchViewPresetsListParams } from '../store/viewPresetsList/types';
import {
  ViewPresetListItem,
  WorkspacePresetFromBE,
  WorkspacePresetListItem,
} from '../store/workspace/types';
import { FetchWorkspaceParams } from '../store/workspaceList/types';
import { PresetsApi } from './api';
import viewPresetsListFullContent from './fakeApi/viewPresets.json';
import viewPresetsList from './fakeApi/viewPresetsList.json';
import workspacePresetsListFullContent from './fakeApi/workspacePresets.json';
import workspacePresetsList from './fakeApi/workspacePresetsList.json';
import { getCleanSearchQuery } from './utils';

export const ERROR_NOT_FOUND = 'Request failed with status code 400';

export const extractWorkspacePresetFromJSON = (
  workspacePresetId: string,
): WorkspacePresetFromBE => {
  return (workspacePresetsListFullContent as WorkspacePresetFromBE[]).find(
    (element) => element.id === workspacePresetId,
  )!;
};

export const extractViewPresetFromJSON = (viewPresetId: string): ViewPreset => {
  return (viewPresetsListFullContent as ViewPreset[]).find(
    (element) => element.id === viewPresetId,
  )!;
};

export const filterWorkspacePresetList = (
  params?: FetchWorkspaceParams,
): WorkspacePresetListItem[] => {
  if (isEmpty(params)) {
    return workspacePresetsList as WorkspacePresetListItem[];
  }
  return workspacePresetsList.reduce<WorkspacePresetListItem[]>(
    (list, workspacePresetRaw) => {
      const workspacePreset = workspacePresetRaw as WorkspacePresetListItem;
      if (params?.scope?.includes(workspacePreset.scope!)) {
        if (params.search) {
          const searchQuery = getCleanSearchQuery(params.search);
          const hasResult = searchQuery.split(',').every((query) => {
            return (
              workspacePreset.title
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              workspacePreset.abstract
                .toLowerCase()
                .includes(query.toLowerCase())
            );
          });
          return hasResult ? list.concat(workspacePreset) : list;
        }

        return list.concat(workspacePreset);
      }
      return list;
    },
    [],
  );
};

export const filterViewPresetList = (
  params?: FetchViewPresetsListParams,
): ViewPresetListItem[] => {
  if (isEmpty(params)) {
    return viewPresetsList as ViewPresetListItem[];
  }
  return viewPresetsList.reduce<ViewPresetListItem[]>((list, viewPresetRaw) => {
    const workspacePreset = viewPresetRaw as ViewPresetListItem;
    if (params?.scope?.includes(workspacePreset.scope!)) {
      return list.concat(workspacePreset);
    }
    return list;
  }, []);
};

export const sortViewPresets = (
  viewPresets: ViewPresetListItem[],
): ViewPresetListItem[] => {
  const presetOrder: PresetScope[] = ['system', 'user'];
  return [...viewPresets].sort((left, right) => {
    if (left.scope === right.scope) {
      return left.title.localeCompare(right.title);
    }
    return presetOrder.indexOf(left.scope) - presetOrder.indexOf(right.scope);
  });
};

const getApiRoutes = (axiosInstance: AxiosInstance): PresetsApi => ({
  getWorkspacePresets: (
    params?: FetchWorkspaceParams,
  ): Promise<{ data: WorkspacePresetListItem[] }> => {
    return axiosInstance.get('/workspacepreset', { params }).then(() => {
      const data = filterWorkspacePresetList(params);
      return {
        data,
      };
    });
  },
  getWorkspacePreset: (
    workspacePresetId: string,
  ): Promise<{ data: WorkspacePresetFromBE }> => {
    return axiosInstance
      .get(`/workspacepreset/${workspacePresetId}`)
      .then(() => {
        if (!extractWorkspacePresetFromJSON(workspacePresetId)) {
          throw new Error(ERROR_NOT_FOUND);
        }
        return {
          data: extractWorkspacePresetFromJSON(workspacePresetId),
        };
      });
  },
  saveWorkspacePreset: (
    workspacePresetId: string,
    data: WorkspacePresetFromBE,
  ): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('api: save workspace preset', workspacePresetId, data);
    return axiosInstance.put(`/workspacepreset/${workspacePresetId}/`, data);
  },
  saveWorkspacePresetAs: (data: WorkspacePresetFromBE): Promise<string> => {
    // eslint-disable-next-line no-console
    console.log('api: save workspace preset as', data);
    return axiosInstance.post(`/workspacepreset/`, data).then(() => null!);
  },
  deleteWorkspacePreset: (workspacePresetId: string): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('api: delete workspace preset', workspacePresetId);
    return axiosInstance.delete(`/workspacepreset/${workspacePresetId}`);
  },

  // view presets
  getViewPresets: (
    params?: FetchViewPresetsListParams,
  ): Promise<{ data: ViewPresetListItem[] }> => {
    return axiosInstance.get('/viewpreset', { params }).then(() => {
      const data = filterViewPresetList(params);
      return {
        data: sortViewPresets(data),
      };
    });
  },
  getViewPreset: (presetId: string): Promise<{ data: ViewPreset }> => {
    return axiosInstance.get(`/viewpreset/${presetId}`).then(() => {
      return {
        data: extractViewPresetFromJSON(presetId),
      };
    });
  },
  saveViewPreset: (presetId: string, data: ViewPreset): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('api: save view preset', presetId, data);
    return axiosInstance.put(`/viewpreset/${presetId}/`, data);
  },
  saveViewPresetAs: (data: ViewPreset): Promise<string> => {
    // eslint-disable-next-line no-console
    console.log('api: save view preset as', data);
    return axiosInstance.post(`/viewpreset/`, data).then(() => null!);
  },
  deleteViewPreset: (presetId: string): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log('api: delete view preset', presetId);
    return axiosInstance.delete(`/viewpreset/${presetId}`);
  },
});

// used in storybook
export const createApi = (): PresetsApi => {
  const instance = createFakeApiInstance();
  return getApiRoutes(instance);
};

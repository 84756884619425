/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { TimeSeriesService } from '@opengeoweb/shared';
import {
  filterLayers,
  layerTypes,
  MapPreset,
  serviceTypes,
} from '@opengeoweb/store';

interface FilteredMapPresets extends filterLayers.FilteredLayerList {
  services?: serviceTypes.InitialService[];
  baseServices?: serviceTypes.InitialService[];
  timeSeriesServices?: TimeSeriesService[];
  defaultMapSettings?: MapPreset;
}

// this preset is only used with initialPresets.json files
export interface InitialAppPresetProps {
  presetType?: string;
  presetId?: string;
  presetName?: string;
  services?: serviceTypes.InitialService[];
  timeSeriesServices?: TimeSeriesService[];
  baseServices?: serviceTypes.InitialService[];
  layers?: layerTypes.Layer[];
  baseLayers?: layerTypes.Layer[];
  defaultMapSettings?: MapPreset;
}

export interface InitialAppPreset {
  preset: InitialAppPresetProps;
}

const isValidService = (service: serviceTypes.InitialService): boolean =>
  Object.prototype.hasOwnProperty.call(service, 'url');

export const filterServices = (
  presetArray: serviceTypes.InitialService[],
): serviceTypes.InitialService[] => {
  const hasServices =
    presetArray && Array.isArray(presetArray) && presetArray.length > 0;
  const validServices = hasServices
    ? presetArray.filter((preset) => isValidService(preset))
    : null;
  const hasValidServices = validServices && validServices.length > 0;
  const services = hasValidServices ? validServices : undefined;

  return services!;
};

export const filterTimeSeriesServices = (
  presetArray: TimeSeriesService[],
): TimeSeriesService[] => {
  const hasServices =
    presetArray && Array.isArray(presetArray) && presetArray.length > 0;
  const validServices = hasServices
    ? presetArray.filter((preset) => isValidService(preset))
    : null;
  const hasValidServices = validServices && validServices.length > 0;
  const services = hasValidServices ? validServices : undefined;

  return services!;
};

export const filterMapPresets = (
  initialAppPreset: InitialAppPreset | null,
): FilteredMapPresets => {
  const preset = (initialAppPreset && initialAppPreset.preset) || {};
  const { layers, services, baseServices, timeSeriesServices } = preset;

  const { baseLayers, mapLayers } = layers
    ? filterLayers.filterLayers(layers, filterLayers.parseLayer)
    : filterLayers.createEmtpyFilteredList(undefined!);

  const filterredServices = services ? filterServices(services) : undefined;
  const filteredBaseServices = baseServices
    ? filterServices(baseServices)
    : undefined;

  const filteredTimeSeriesServices = timeSeriesServices
    ? filterTimeSeriesServices(timeSeriesServices)
    : undefined;

  const filteredPreset = {
    services: filterredServices,
    baseServices: filteredBaseServices,
    timeSeriesServices: filteredTimeSeriesServices,
    baseLayers,
    mapLayers,
  };

  return filteredPreset;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const WMS111GetCapabilitiesGeoServicesRADAR = `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE WMT_MS_Capabilities SYSTEM "http://schemas.opengis.net/wms/1.1.1/WMS_MS_Capabilities.dtd" [
  <!ELEMENT VendorSpecificCapabilities EMPTY>
]>
<!-- end of DOCTYPE declaration -->
<WMT_MS_Capabilities version="1.1.1">
   <Service>
      <Name>OGC:WMS</Name>
      <Title>RADAR</Title>
      <Abstract>This service demonstrates how the ADAGUC server can be used to create OGC services.</Abstract>
      <ServerInfo>ADAGUCServer version 2.5.8, of Apr 13 2021 07:55:43</ServerInfo>
      <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
      <ContactInformation />
   </Service>
   <Capability>
      <Request>
         <GetCapabilities>
            <Format>application/vnd.ogc.wms_xml</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetCapabilities>
         <GetMap>
            <Format>image/png</Format>
            <Format>image/gif</Format>
            <Format>image/png32</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetMap>
         <GetFeatureInfo>
            <Format>text/plain</Format>
            <Format>text/html</Format>
            <Format>text/xml</Format>
            <Format>application/vnd.ogc.gml</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetFeatureInfo>
         <DescribeLayer>
            <Format>text/xml</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </DescribeLayer>
         <GetLegendGraphic>
            <Format>image/png</Format>
            <Format>image/gif</Format>
            <Format>image/png32</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetLegendGraphic>
         <GetStyles>
            <Format>text/xml</Format>
            <DCPType>
               <HTTP>
                  <Get>
                     <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;" />
                  </Get>
               </HTTP>
            </DCPType>
         </GetStyles>
      </Request>
      <Exception>
         <Format>application/vnd.ogc.se_xml</Format>
         <Format>application/vnd.ogc.se_inimage</Format>
         <Format>application/vnd.ogc.se_blank</Format>
      </Exception>
      <VendorSpecificCapabilities />
      <UserDefinedSymbolization SupportSLD="1" UserLayer="0" UserStyle="1" RemoteWFS="0" />
      <Layer>
         <Title>WMS of  RADAR</Title>
         <SRS>EPSG:3411</SRS>
         <SRS>EPSG:3412</SRS>
         <SRS>EPSG:3575</SRS>
         <SRS>EPSG:3857</SRS>
         <SRS>EPSG:4258</SRS>
         <SRS>EPSG:4326</SRS>
         <SRS>CRS:84</SRS>
         <SRS>EPSG:25831</SRS>
         <SRS>EPSG:25832</SRS>
         <SRS>EPSG:28992</SRS>
         <SRS>EPSG:7399</SRS>
         <SRS>EPSG:50001</SRS>
         <SRS>EPSG:54030</SRS>
         <SRS>EPSG:32661</SRS>
         <SRS>EPSG:40000</SRS>
         <SRS>EPSG:900913</SRS>
         <SRS>PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0</SRS>
         <Layer queryable="1" opaque="1" cascaded="0">
            <Name>RAD_NL25_PCP_CM</Name>
            <Title>Precipitation Radar NL</Title>
            <Abstract>Radar NL</Abstract>
            <KeywordList>
              <Keyword>Radar</Keyword>
            </KeywordList>
            <SRS>EPSG:3411</SRS>
            <BoundingBox SRS="EPSG:3411" minx="2682754.743620" miny="-3245034.014141" maxx="3759536.917562" maxy="-2168251.989038" />
            <SRS>EPSG:3412</SRS>
            <BoundingBox SRS="EPSG:3412" minx="0.000000" miny="32318824.826266" maxx="7413041.166015" maxy="40075258.815074" />
            <SRS>EPSG:3575</SRS>
            <BoundingBox SRS="EPSG:3575" minx="-770622.801471" miny="-4485814.811314" maxx="56845.766135" maxy="-3684039.443620" />
            <SRS>EPSG:3857</SRS>
            <BoundingBox SRS="EPSG:3857" minx="0.000000" miny="6257115.219364" maxx="1208534.698398" maxy="7553161.958695" />
            <SRS>EPSG:4258</SRS>
            <BoundingBox SRS="EPSG:4258" minx="0.000000" miny="48.895303" maxx="10.856452" maxy="55.973600" />
            <SRS>EPSG:4326</SRS>
            <BoundingBox SRS="EPSG:4326" minx="0.000000" miny="48.895303" maxx="10.856452" maxy="55.973600" />
            <SRS>CRS:84</SRS>
            <BoundingBox SRS="CRS:84" minx="0.000000" miny="48.895303" maxx="10.856452" maxy="55.973600" />
            <SRS>EPSG:25831</SRS>
            <BoundingBox SRS="EPSG:25831" minx="282182.345905" miny="5433247.394267" maxx="997135.658653" maxy="6207204.592736" />
            <SRS>EPSG:25832</SRS>
            <BoundingBox SRS="EPSG:25832" minx="-153083.019482" miny="5415817.312927" maxx="617595.626092" maxy="6239769.309937" />
            <SRS>EPSG:28992</SRS>
            <BoundingBox SRS="EPSG:28992" minx="-236275.338083" miny="106727.731651" maxx="501527.918656" maxy="900797.079725" />
            <SRS>EPSG:7399</SRS>
            <BoundingBox SRS="EPSG:7399" minx="0.000000" miny="5757301.056717" maxx="763611.971696" maxy="6483919.801602" />
            <SRS>EPSG:50001</SRS>
            <BoundingBox SRS="EPSG:50001" minx="-2000000.000000" miny="-2000000.000000" maxx="10000000.000000" maxy="8500000.000000" />
            <SRS>EPSG:54030</SRS>
            <BoundingBox SRS="EPSG:54030" minx="0.000000" miny="5211855.054125" maxx="853649.695106" maxy="5936394.291427" />
            <SRS>EPSG:32661</SRS>
            <BoundingBox SRS="EPSG:32661" minx="2000000.000000" miny="-2703305.597319" maxx="2745713.040381" maxy="-1888346.216710" />
            <SRS>EPSG:40000</SRS>
            <BoundingBox SRS="EPSG:40000" minx="0.000000" miny="-4731695.771951" maxx="750214.326339" maxy="-3911817.119426" />
            <SRS>EPSG:900913</SRS>
            <BoundingBox SRS="EPSG:900913" minx="0.000000" miny="6257115.219364" maxx="1208534.698398" maxy="7553161.958695" />
            <SRS>PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0</SRS>
            <BoundingBox SRS="PROJ4:%2Bproj%3Dstere%20%2Blat_0%3D90%20%2Blon_0%3D0%20%2Blat_ts%3D60%20%2Ba%3D6378%2E14%20%2Bb%3D6356%2E75%20%2Bx_0%3D0%20y_0%3D0" minx="0.000000" miny="-3649.999338" maxx="700.002420" maxy="-4415.002986" />
            <LatLonBoundingBox minx="0.000000" miny="48.895303" maxx="10.856452" maxy="55.973600" />
            <Dimension name="time" units="ISO8601" />
            <Extent name="time" default="2021-05-17T00:00:00Z" multipleValues="1" nearestValue="0">2021-03-31T09:25:00Z/2021-05-18T07:45:00Z/PT5M</Extent>
            <Style>
               <Name>radar/nearest</Name>
               <Title>radar/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=radar/nearest" />
               </LegendURL>
            </Style>
            <Style>
               <Name>precip-rainbow/nearest</Name>
               <Title>precip-rainbow/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-rainbow/nearest" />
               </LegendURL>
            </Style>
            <Style>
               <Name>precip-gray/nearest</Name>
               <Title>precip-gray/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-gray/nearest" />
               </LegendURL>
            </Style>
            <Style>
               <Name>precip-blue/nearest</Name>
               <Title>precip-blue/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-blue/nearest" />
               </LegendURL>
            </Style>
            <Style>
               <Name>precip-blue-transparent/nearest</Name>
               <Title>precip-blue-transparent/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-blue-transparent/nearest" />
               </LegendURL>
            </Style>
            <Style>
               <Name>precip-with-range/nearest</Name>
               <Title>precip-with-range/nearest</Title>
               <LegendURL width="300" height="400">
                  <Format>image/png</Format>
                  <OnlineResource xmlns:xlink="http://www.w3.org/1999/xlink" xlink:type="simple" xlink:href="https://geoservices.knmi.nl/wms?DATASET=RADAR&amp;SERVICE=WMS&amp;&amp;version=1.1.1&amp;service=WMS&amp;request=GetLegendGraphic&amp;layer=RAD_NL25_PCP_CM&amp;format=image/png&amp;STYLE=precip-with-range/nearest" />
               </LegendURL>
            </Style>
            <ScaleHint min="0" max="10000" />
         </Layer>
      </Layer>
   </Capability>
</WMT_MS_Capabilities>`;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { OptionsMenuButton } from '@opengeoweb/timeslider';
import { SpeedButtonConnect } from './SpeedButtonConnect';
import { TimeStepButtonConnect } from './TimeStepButtonConnect';
import { AnimationLengthButtonConnect } from './AnimationLengthButtonConnect';
import { TimeSpanButtonConnect } from './TimeSpanButtonConnect';

interface OptionsMenuButtonConnectProps {
  mapId: string;
}

/**
 * OptionsMenuButtonConnect component with components connected to the store displaying the options menu button
 *
 * Expects the following props:
 * @param {string} mapId mapId: string - Id of the map
 * @example
 * ``` <OptionsMenuButtonConnect mapId={mapId} />```
 */

export const OptionsMenuButtonConnect: React.FC<
  OptionsMenuButtonConnectProps
> = ({ mapId }: OptionsMenuButtonConnectProps) => {
  return (
    <OptionsMenuButton
      animationLengthBtn={<AnimationLengthButtonConnect mapId={mapId} />}
      speedBtn={<SpeedButtonConnect mapId={mapId} />}
      timeStepBtn={<TimeStepButtonConnect mapId={mapId} />}
      timeSpanBtn={<TimeSpanButtonConnect mapId={mapId} />}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, useTheme } from '@mui/material';
import { MapViewLayerProps } from '@opengeoweb/webmap-react';
import MapViewGeoJson from '../MapViewGeoJson/MapViewGeoJson';
import { ProductConfig } from '../../types';
import { getBaseLayers, getProjection } from './utils';

interface ProducFormLayoutProps {
  children: React.ReactNode;
  productConfig: ProductConfig;
  geoJSONLayers: MapViewLayerProps[];
}

const ProductFormLayout: React.FC<ProducFormLayoutProps> = ({
  children,
  productConfig,
  geoJSONLayers = [],
}: ProducFormLayoutProps) => {
  const theme = useTheme();
  const baseLayers = getBaseLayers(productConfig);
  const projection = getProjection(productConfig);

  return (
    <Grid
      container
      sx={{
        height: '100%',
        overflowY: 'auto',
        [theme.breakpoints.up('sm')]: {
          overflowY: 'hidden',
        },
      }}
    >
      <Grid
        sx={{
          height: 400,
          padding: 2,
          [theme.breakpoints.up('sm')]: {
            height: '100%',
            padding: 0,
          },
        }}
        size={{
          xs: 12,
          sm: 6,
          lg: 8,
        }}
      >
        <MapViewGeoJson
          baseLayers={baseLayers}
          projection={projection}
          geoJSONLayers={geoJSONLayers}
        />
      </Grid>
      <Grid
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingRight: 2,
          [theme.breakpoints.up('sm')]: {
            overflowY: 'auto',
            height: '100%',
          },
        }}
        size={{
          xs: 12,
          sm: 6,
          lg: 4,
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default ProductFormLayout;

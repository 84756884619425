/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { dateUtils } from '@opengeoweb/shared';

import {
  ReactHookFormDateTime,
  isXHoursAfter,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';

import {
  getFieldLabel,
  getMaxHoursOfValidity,
  triggerValidations,
} from '../utils';
import { ConfigurableFormFieldProps, ProductConfig } from '../../../types';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { styles } from '../ProductForm.styles';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

export const getDefaultValidUntilValue = (
  productConfig: ProductConfig,
  phenomenon?: string,
  fir?: string,
  validFrom?: string,
): string => {
  const maxHoursOfValidity = getMaxHoursOfValidity(
    phenomenon!,
    fir || '',
    productConfig,
  );

  // Set default validity to 2 hours for thunderstorms, otherwise use maxHoursOfValidity
  const defaultHoursOfValidity = [
    'EMBD_TS',
    'EMBD_TSGR',
    'FRQ_TS',
    'FRQ_TSGR',
    'OBSC_TS',
    'OBSC_TSGR',
    'SQL_TS',
    'SQL_TSGR',
    'ISOL_TS',
    'ISOL_TSGR',
    'OCNL_TS',
    'OCNL_TSGR',
  ].includes(phenomenon!)
    ? 2
    : maxHoursOfValidity;

  // Use validFrom if provided, otherwise use current UTC time
  const baseDate = validFrom ? new Date(validFrom) : dateUtils.utc();

  const dateWithValidity = dateUtils.add(baseDate, {
    hours: defaultHoursOfValidity,
  });

  return dateUtils.dateToString(dateWithValidity)!;
};

const ValidUntil: React.FC<ConfigurableFormFieldProps> = ({
  productConfig,
  isDisabled,
  isReadOnly,
  onChange = (): void => {},
}: ConfigurableFormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { getValues, trigger, watch, setValue } = useFormContext();
  const { isRequired } = useDraftFormHelpers();

  const dateAndTime = t('date-and-time');
  const label = getFieldLabel(dateAndTime, isReadOnly!);

  const [defaultValidUntilValue, setDefaultValidUntilValue] = React.useState(
    getDefaultValidUntilValue(
      productConfig,
      getValues('phenomenon'),
      getValues('locationIndicatorATSR'),
      getValues('validDateStart'),
    ),
  );

  const phenomenon = watch('phenomenon');
  const locationIndicatorATSR = watch('locationIndicatorATSR');
  const validFrom = watch('validDateStart');

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const updateValues = () => {
      const newDefaultValidUntilValue = getDefaultValidUntilValue(
        productConfig,
        phenomenon,
        locationIndicatorATSR,
        validFrom,
      );

      if (newDefaultValidUntilValue !== defaultValidUntilValue) {
        setDefaultValidUntilValue(newDefaultValidUntilValue);
        setValue('validDateEnd', newDefaultValidUntilValue, {
          shouldValidate: false,
        });
        void trigger('validDateEnd');
      }
    };

    updateValues();
  }, [
    phenomenon,
    locationIndicatorATSR,
    validFrom,
    setValue,
    productConfig,
    trigger,
    defaultValidUntilValue,
  ]);
  return (
    <ProductFormFieldLayout title={t('valid-until')}>
      <ReactHookFormDateTime
        key={defaultValidUntilValue}
        disablePast
        name="validDateEnd"
        label={label}
        defaultNullValue={defaultValidUntilValue}
        rules={{
          validate: {
            isRequired,
            isValidDate: dateUtils.isValidIsoDateString,
            isAfter: (value): boolean | string =>
              value
                ? dateUtils.isAfter(value, getValues('validDateStart')) ||
                  t('valid-until-time')
                : true,
            isXHoursAfter: (value): boolean | string =>
              isXHoursAfter(
                value,
                getValues('validDateStart'),
                getMaxHoursOfValidity(
                  getValues('phenomenon'),
                  getValues('locationIndicatorATSR'),
                  productConfig,
                ),
              ) ||
              t('valid-until-time-error', {
                hours: getMaxHoursOfValidity(
                  getValues('phenomenon'),
                  getValues('locationIndicatorATSR'),
                  productConfig,
                ),
              }),
          },
        }}
        disabled={isDisabled}
        isReadOnly={isReadOnly}
        onChange={(): void => {
          // Trigger validation after any change
          triggerValidations(
            ['validDateStart', 'observationOrForecastTime'],
            getValues,
            trigger,
          );
          onChange();
        }}
        data-testid="valid-until"
        sx={{
          ...styles.helperText,
          '.MuiFormHelperText-root.Mui-error': {
            marginBottom: '-16px',
          },
        }}
      />
    </ProductFormFieldLayout>
  );
};

export default ValidUntil;

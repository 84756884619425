/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export default `<?xml version="1.0" encoding="UTF-8"?>
<WMS_Capabilities version="1.3.0" xmlns="http://www.opengis.net/wms" xmlns:xsi="https://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/wms http://schemas.opengis.net/wms/1.3.0/capabilities_1_3_0.xsd" xmlns:xlink="http://www.w3.org/1999/xlink">
<Service>
  <Name>WMS</Name>
  <Title>SmartMet Web Map Service</Title>
  <Abstract>Web Map Service Powered by SmartMet Server</Abstract>
  <KeywordList>
      <Keyword>weather</Keyword>
      <Keyword>forecast</Keyword>
      <Keyword>hirlam</Keyword>
      <Keyword>meps</Keyword>
      <Keyword>smartmetserver</Keyword>
  </KeywordList>
  <OnlineResource xlink:href="https://github.com/fmidev/smartmet-server"/>
</Service>
<Capability>
  <Request>
    <GetCapabilities>
      <Format>text/xml</Format>
      <DCPType>
        <HTTP>
          <Get>
            <OnlineResource xlink:type="simple"
                            xlink:href="https://data.apps.meteo.fi/wms"/>
          </Get>

        </HTTP>
      </DCPType>
    </GetCapabilities>
    <GetMap>
      <Format>image/png</Format>
      <Format>image/svg+xml</Format>
      <DCPType>
        <HTTP>
          <Get>
            <OnlineResource xlink:type="simple"
                            xlink:href="https://data.apps.meteo.fi/wms"/>
          </Get>

        </HTTP>
      </DCPType>
    </GetMap>
  </Request>
  <Exception>
    <Format>XML</Format>
  </Exception>
  <Layer>
    <Title>SmartMet Web Map Service</Title>

        <Layer queryable="0">
      <Title>hbm</Title>
      <CRS>CRS:27</CRS>
      <CRS>CRS:83</CRS>
      <CRS>CRS:84</CRS>
      <CRS>EPSG:2393</CRS>
      <CRS>EPSG:3035</CRS>
      <CRS>EPSG:3047</CRS>
      <CRS>EPSG:3067</CRS>
      <CRS>EPSG:3857</CRS>
      <CRS>EPSG:4258</CRS>
      <CRS>EPSG:4326</CRS>
      <EX_GeographicBoundingBox>
        <westBoundLongitude>9.041</westBoundLongitude>
        <eastBoundLongitude>30.291</eastBoundLongitude>
        <southBoundLatitude>53.025</southBoundLatitude>
        <northBoundLatitude>65.875</northBoundLatitude>
      </EX_GeographicBoundingBox>
      <BoundingBox CRS="CRS:27" minx="9.041" miny="53.025" maxx="30.291" maxy="65.875"/>
      <BoundingBox CRS="CRS:83" minx="9.041" miny="53.025" maxx="30.291" maxy="65.875"/>
      <BoundingBox CRS="CRS:84" minx="9.041" miny="53.025" maxx="30.291" maxy="65.875"/>
      <BoundingBox CRS="EPSG:2393" minx="3064557.21476" miny="6651895.28724" maxx="3650275.30418" maxy="7312969.19512"/>
      <BoundingBox CRS="EPSG:3035" minx="4256650.93596" miny="3324481.13996" maxx="5238179.54401" maxy="4886764.90153"/>
      <BoundingBox CRS="EPSG:3047" minx="330890.119265" miny="6615141.39345" maxx="636784.191878" maxy="7309246.52372"/>
      <BoundingBox CRS="EPSG:3067" minx="43547.7892277" miny="6549298.62199" maxx="650042.964634" maxy="7309911.51299"/>
      <BoundingBox CRS="EPSG:3857" minx="1006439.51626" miny="6987623.58323" maxx="3371978.69562" maxy="9842718.24518"/>
      <BoundingBox CRS="EPSG:4258" minx="9.041" miny="53.025" maxx="30.291" maxy="65.875"/>
      <BoundingBox CRS="EPSG:4326" minx="9.041" miny="53.025" maxx="30.291" maxy="65.875"/>
      <Dimension name="time" units="ISO8601" multipleValues="0" nearestValue="0" current="1">2021-05-14T01:00:00Z/2021-05-16T04:00:00Z/PT1H</Dimension>

      <Dimension name="reference_time" units="ISO8601" default="2021-05-14T00:00:00Z" multipleValues="0" nearestValue="0" current="1">2021-05-13T12:00:00Z,2021-05-14T00:00:00Z</Dimension>

      <Dimension name="elevation" units="DepthBelowMeanSeaLevel" unitSymbol="m" default="0" multipleValues="0" nearestValue="0">0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,150,200,300,400</Dimension>



	  <Layer queryable="1">
      <Name>hbm:temperature</Name>
      <Title>Sea Surface Temperature</Title>
      <Abstract>Sea Surface Temperature from HBM</Abstract>
      <Style>
        <Name>temperaturestyleforhbm</Name>
        <Title>Default style for HBM Temperature</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=hbm%3Atemperature"/>
        </LegendURL>
      </Style>
      </Layer>

	  </Layer>
        <Layer queryable="0">
      <Title>hirlam</Title>
      <CRS>CRS:27</CRS>
      <CRS>CRS:83</CRS>
      <CRS>CRS:84</CRS>
      <CRS>EPSG:2393</CRS>
      <CRS>EPSG:3035</CRS>
      <CRS>EPSG:3047</CRS>
      <CRS>EPSG:3067</CRS>
      <CRS>EPSG:3857</CRS>
      <CRS>EPSG:4258</CRS>
      <CRS>EPSG:4326</CRS>
      <EX_GeographicBoundingBox>
        <westBoundLongitude>-101.480400722</westBoundLongitude>
        <eastBoundLongitude>102.056746641</eastBoundLongitude>
        <southBoundLatitude>25.6476611912</southBoundLatitude>
        <northBoundLatitude>61.2739846713</northBoundLatitude>
      </EX_GeographicBoundingBox>
      <BoundingBox CRS="CRS:27" minx="-101.480400722" miny="25.6476611912" maxx="102.056746641" maxy="61.2739846713"/>
      <BoundingBox CRS="CRS:83" minx="-101.480400722" miny="25.6476611912" maxx="102.056746641" maxy="61.2739846713"/>
      <BoundingBox CRS="CRS:84" minx="-101.480400722" miny="25.6476611912" maxx="102.056746641" maxy="61.2739846713"/>
      <BoundingBox CRS="EPSG:2393" minx="3064557.21476" miny="6651895.28724" maxx="3746240.35345" maxy="6804804.9047"/>
      <BoundingBox CRS="EPSG:3035" minx="-96211.1051714" miny="1631190.4604" maxx="6106555.77383" maxy="4695810.9123"/>
      <BoundingBox CRS="EPSG:3047" minx="330890.119265" miny="6615141.39345" maxx="660818.533807" maxy="6796998.92435"/>
      <BoundingBox CRS="EPSG:3067" minx="43547.7892277" miny="6549298.62199" maxx="745971.121814" maxy="6801952.28915"/>
      <BoundingBox CRS="EPSG:3857" minx="-11296746.5338" miny="2955507.31159" maxx="11360905.0681" maxy="8689007.32535"/>
      <BoundingBox CRS="EPSG:4258" minx="-101.480400722" miny="25.6476611912" maxx="102.056746641" maxy="61.2739846713"/>
      <BoundingBox CRS="EPSG:4326" minx="-101.480400722" miny="25.6476611912" maxx="102.056746641" maxy="61.2739846713"/>
      <Dimension name="time" units="ISO8601" multipleValues="0" nearestValue="0" current="1">2021-05-14T06:00:00Z/2021-05-16T12:00:00Z/PT1H</Dimension>

      <Dimension name="reference_time" units="ISO8601" default="2021-05-14T06:00:00Z" multipleValues="0" nearestValue="0" current="1">2021-05-13T12:00:00Z,2021-05-13T18:00:00Z,2021-05-14T00:00:00Z,2021-05-14T06:00:00Z</Dimension>



	  <Layer queryable="1">
      <Name>hirlam:precipitation</Name>
      <Title>HIRLAM Precipitation Rate</Title>
      <Abstract>Precipitation Rate from HIRLAM</Abstract>
      <Style>
        <Name>precipitationstyleforhirlam</Name>
        <Title>Default style for hirlam</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=hirlam%3Aprecipitation"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>hirlam:relativehumidity</Name>
      <Title>HIRLAM Relative Humidity 2m</Title>
      <Abstract>Relative Humidity 2m from HIRLAM</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=hirlam%3Arelativehumidity"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>hirlam:temperature</Name>
      <Title>HIRLAM Temperature 2m</Title>
      <Abstract>Temperature 2m from HIRLAM</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=hirlam%3Atemperature"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>hirlam:temperature-850hpa</Name>
      <Title>HIRLAM Temperature 850hPa</Title>
      <Abstract>Temperature 850hPa from HIRLAM</Abstract>
      <Dimension name="time" units="ISO8601" multipleValues="0" nearestValue="0" current="1">2021-05-14T06:00:00Z/2021-05-16T12:00:00Z/PT1H</Dimension>

      <Dimension name="reference_time" units="ISO8601" default="2021-05-14T06:00:00Z" multipleValues="0" nearestValue="0" current="1">2021-05-14T00:00:00Z,2021-05-14T06:00:00Z</Dimension>

      <Dimension name="elevation" units="IsobaricLevel" unitSymbol="hPa" default="1000" multipleValues="0" nearestValue="0">1000,925,850,700,600,500,400,300,250,200,100,50</Dimension>

      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=hirlam%3Atemperature-850hpa"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>hirlam:totalcloudcover</Name>
      <Title>HIRLAM Total Cloud Cover</Title>
      <Abstract>Total cloud cover from HIRLAM forecast model.</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=hirlam%3Atotalcloudcover"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>hirlam:winddirection</Name>
      <Title>HIRLAM Wind Direction 10m</Title>
      <Abstract>Wind direction from HIRLAM forecast model.</Abstract>
      </Layer>


	  <Layer queryable="1">
      <Name>hirlam:windspeed</Name>
      <Title>HIRLAM Wind Speed 10m</Title>
      <Abstract>Wind speed in m/s from HIRLAM forecast model.</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=hirlam%3Awindspeed"/>
        </LegendURL>
      </Style>
      </Layer>

	  </Layer>
        <Layer queryable="0">
      <Title>meps</Title>
      <CRS>CRS:27</CRS>
      <CRS>CRS:83</CRS>
      <CRS>CRS:84</CRS>
      <CRS>EPSG:2393</CRS>
      <CRS>EPSG:3035</CRS>
      <CRS>EPSG:3047</CRS>
      <CRS>EPSG:3067</CRS>
      <CRS>EPSG:3857</CRS>
      <CRS>EPSG:4258</CRS>
      <CRS>EPSG:4326</CRS>
      <EX_GeographicBoundingBox>
        <westBoundLongitude>-18.1181798512</westBoundLongitude>
        <eastBoundLongitude>54.237</eastBoundLongitude>
        <southBoundLatitude>49.7657866394</southBoundLatitude>
        <northBoundLatitude>72.7612723061</northBoundLatitude>
      </EX_GeographicBoundingBox>
      <BoundingBox CRS="CRS:27" minx="-18.1181798512" miny="49.7657866394" maxx="54.237" maxy="72.7612723061"/>
      <BoundingBox CRS="CRS:83" minx="-18.1181798512" miny="49.7657866394" maxx="54.237" maxy="72.7612723061"/>
      <BoundingBox CRS="CRS:84" minx="-18.1181798512" miny="49.7657866394" maxx="54.237" maxy="72.7612723061"/>
      <BoundingBox CRS="EPSG:2393" minx="3064557.21476" miny="6651895.28724" maxx="3674549.99025" maxy="7785726.69717"/>
      <BoundingBox CRS="EPSG:3035" minx="2351945.35175" miny="3346053.06304" maxx="5431836.28888" maxy="5804319.83173"/>
      <BoundingBox CRS="EPSG:3047" minx="330890.119265" miny="6615141.39345" maxx="599196.301358" maxy="8076330.30937"/>
      <BoundingBox CRS="EPSG:3067" minx="43547.7892277" miny="6549298.62199" maxx="674306.988933" maxy="7782478.68382"/>
      <BoundingBox CRS="EPSG:3857" minx="-2016906.55513" miny="6405812.58171" maxx="6037635.22215" maxy="12033196.7337"/>
      <BoundingBox CRS="EPSG:4258" minx="-18.1181798512" miny="49.7657866394" maxx="54.237" maxy="72.7612723061"/>
      <BoundingBox CRS="EPSG:4326" minx="-18.1181798512" miny="49.7657866394" maxx="54.237" maxy="72.7612723061"/>
      <Dimension name="time" units="ISO8601" multipleValues="0" nearestValue="0" current="1">2021-05-14T06:00:00Z/2021-05-17T00:00:00Z/PT1H</Dimension>

      <Dimension name="reference_time" units="ISO8601" default="2021-05-14T06:00:00Z" multipleValues="0" nearestValue="0" current="1">2021-05-13T12:00:00Z,2021-05-13T18:00:00Z,2021-05-14T00:00:00Z,2021-05-14T06:00:00Z</Dimension>



	  <Layer queryable="1">
      <Name>meps:precipitation</Name>
      <Title>MEPS Precipitation Rate</Title>
      <Abstract>Precipitation Rate from MEPS</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=meps%3Aprecipitation"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>meps:relativehumidity</Name>
      <Title>MEPS Relative Humidity 2m</Title>
      <Abstract>Relative Humidity 2m from MEPS</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=meps%3Arelativehumidity"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>meps:temperature</Name>
      <Title>MEPS Temperature 2m</Title>
      <Abstract>Temperature 2m from MEPS</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=meps%3Atemperature"/>
        </LegendURL>
      </Style>
      </Layer>


	  <Layer queryable="1">
      <Name>meps:windspeed</Name>
      <Title>MEPS Wind Speed 10m</Title>
      <Abstract>Wind speed in m/s from MEPS forecast model.</Abstract>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL>
          <Format>image/png</Format>
          <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=meps%3Awindspeed"/>
        </LegendURL>
      </Style>
      </Layer>
    <Layer queryable="1">
      <Name>meps:dailymeantemperature</Name>
      <Title>Mean Temperature</Title>
      <Abstract>Daily mean temperature</Abstract>
      <Dimension name="time" units="ISO8601" multipleValues="0" nearestValue="0" current="1">2024-11-26T00:00:00Z/2024-12-07T00:00:00Z/PT24H</Dimension>
      <Dimension name="reference_time" units="ISO8601" default="2024-12-02T10:30:00Z" multipleValues="0" nearestValue="0" current="1">2024-11-26T09:30:00Z,2024-11-26T10:30:00Z</Dimension>
      <Style>
        <Name>default</Name>
        <Title>Default style</Title>
        <LegendURL width="201" height="950">
        <Format>image/png</Format>
        <OnlineResource xlink:type="simple" xlink:href="https://data.apps.meteo.fi/wms?service=WMS&amp;request=GetLegendGraphic&amp;version=1.3.0&amp;sld_version=1.1.0&amp;style=default&amp;format=image%2Fpng&amp;layer=meps%3Adailymeantemperature"/>
        </LegendURL>
      </Style>
    </Layer>
	  </Layer>
  </Layer>
</Capability>
</WMS_Capabilities>`;

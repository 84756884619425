/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { useApiContext } from '@opengeoweb/api';
import { useIsMounted } from '@opengeoweb/shared';
import React from 'react';
import {
  Airmet,
  AirmetFromBackend,
  ProductConfig,
  ProductType,
  Sigmet,
  SigmetFromBackend,
} from '../../types';
import { SigmetAirmetApi } from '../../utils/api';
import { translateKeyOutsideComponents } from '../../utils/i18n';
import { getDefaultValidUntilValue } from '../ProductForms/ProductFormFields/ValidUntil';

interface State {
  isLoading: boolean;
  error: Error;
  result: SigmetFromBackend[] | AirmetFromBackend[];
}

interface UseApiProps extends State {
  fetchNewData: () => Promise<void>;
}

export const useProductApi = (productType: ProductType): UseApiProps => {
  const [state, setState] = React.useState<State>({
    isLoading: false,
    error: undefined!,
    result: [],
  });
  const { isMounted } = useIsMounted();
  const { api } = useApiContext<SigmetAirmetApi>();
  const requestList =
    productType === 'sigmet' ? api.getSigmetList : api.getAirmetList;

  const updateState = (newState: State): void => {
    if (isMounted.current) {
      setState((currentState) => ({ ...currentState, ...newState }));
    }
  };

  const fetchNewData = async (): Promise<void> => {
    // don't try to fetch when is loading already
    if (state.isLoading) {
      return;
    }

    updateState({ ...state, isLoading: true, error: undefined! });
    try {
      const { data } = await requestList();
      updateState({
        ...state,
        result: data,
        isLoading: false,
      });
    } catch (error) {
      const newError: Error = {
        ...(error as Error),
        name: translateKeyOutsideComponents('error-backend'),
      };
      updateState({ ...state, error: newError, isLoading: false });
    }
  };
  React.useEffect(() => {
    void fetchNewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...state, fetchNewData };
};

const renewProduct = <T extends Airmet | Sigmet>(
  oldProduct: T,
  productConfig: ProductConfig,
): T => ({
  ...oldProduct,
  validDateStart: oldProduct.validDateEnd,
  validDateEnd: getDefaultValidUntilValue(
    productConfig,
    oldProduct.phenomenon,
    oldProduct.locationIndicatorATSR,
    oldProduct.validDateEnd,
  ),
  status: 'DRAFT',
  uuid: undefined,
  sequence: '-1',
});
export const renewAirmet = (
  oldAirmet: AirmetFromBackend,
  productConfig: ProductConfig,
): AirmetFromBackend => {
  const airmet = oldAirmet.airmet as Airmet;
  return {
    ...oldAirmet,
    uuid: undefined,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    airmet: renewProduct(airmet, productConfig),
  };
};
export const renewSigmet = (
  oldSigmet: SigmetFromBackend,
  productConfig: ProductConfig,
): SigmetFromBackend => {
  const sigmet = oldSigmet.sigmet as Sigmet;
  return {
    ...oldSigmet,
    uuid: undefined,
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    sigmet: renewProduct(sigmet, productConfig),
  };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import type { CoreAppStore } from '../../types';
import { selectorMemoizationOptions } from '../../utils';
import type { GenericSyncActionPayload } from '../synchronizationActions/types';
import type { GenericActionPayload } from '../types';
import type {
  SyncGroupTarget,
  SynchronizationGroup,
  SynchronizationGroupState,
  SynchronizationSource,
  SyncType,
} from './types';

export const syncGroupStore = (
  store: CoreAppStore,
): SynchronizationGroupState => store.syncGroups || null!;

/**
 * Gets synchronization group state
 *
 * Example: synchronizationGroupState = getSynchronizationGroupState(store)
 * @param {object} store store: object - Store object
 * @returns {object} returnType: SynchronizationGroupState
 */
export const getSynchronizationGroupState = createSelector(
  syncGroupStore,
  (store) => store || null,
  selectorMemoizationOptions,
);

export const getSynchronizationGroup = createSelector(
  [syncGroupStore, (syncGroupStore, id): string => id],
  (syncGroupState, id): SynchronizationGroup => {
    return syncGroupState.groups.byId[id];
  },
  selectorMemoizationOptions,
);

export const getSynchronizationGroupSource = createSelector(
  [syncGroupStore, (syncGroupStore, id): string => id],
  (syncGroupState, id): SynchronizationSource => {
    return syncGroupState.sources.byId[id];
  },
  selectorMemoizationOptions,
);

export const getTargets = createSelector(
  [
    syncGroupStore,
    (syncGroupStore, payload): GenericActionPayload => payload,
    (syncGroupStore, payload, actionType): SyncType => actionType,
  ],
  (syncGroups, payload, actionType) => {
    const actionPayloads: GenericSyncActionPayload[] = [];
    const targetsInActionPayload = {} as SyncGroupTarget;
    const groups: string[] = []; /* All the groups the source is member of */
    if (syncGroups && payload) {
      /* Backwards compatibility, if there are no groups, connect everything */
      if (syncGroups.groups.allIds.length === 0) {
        syncGroups.sources.allIds.forEach((targetId) => {
          // Only add if should react to this action
          if (syncGroups.sources.byId[targetId].types.includes(actionType)) {
            /* Remember that we have already added this target in the action payloads, prevents adding it twice */
            targetsInActionPayload[targetId] = true;
            /* Compose the payload */
            const newPayload: GenericSyncActionPayload = {
              targetId,
              ...payload,
            };
            actionPayloads.push(newPayload);
          }
        });
      }

      syncGroups.groups.allIds.forEach((id) => {
        const syncronizationGroup = syncGroups.groups.byId[id];
        if (actionType === syncronizationGroup.type) {
          /* Check if the source is in the target list of the synchonizationGroup */
          const source = syncronizationGroup.targets.byId[payload.sourceId];
          /* If the source is part of the target list, and is linked, continue syncing the other targets */
          if (source && source.linked) {
            groups.push(id);
            syncronizationGroup.targets.allIds.forEach((targetId) => {
              const target = syncronizationGroup.targets.byId[targetId];
              if (target.linked && !targetsInActionPayload[targetId]) {
                /* Remember that we have already added this target in the action payloads, prevents adding it twice */
                targetsInActionPayload[targetId] = true;
                /* Compose the payload */
                const newPayload: GenericSyncActionPayload = {
                  targetId,
                  ...payload,
                };
                actionPayloads.push(newPayload);
              }
            });
          }
        }
      });
    }
    return actionPayloads;
  },
  selectorMemoizationOptions,
);

export const getTargetGroups = createSelector(
  [
    syncGroupStore,
    (syncGroupStore, payload): GenericActionPayload => payload,
    (syncGroupStore, payload, actionType): SyncType => actionType,
  ],
  (syncGroups, payload, actionType): string[] => {
    const groups = syncGroups.groups.allIds.reduce<string[]>(
      (list, groupId) => {
        const syncronizationGroup = syncGroups.groups.byId[groupId];
        if (actionType === syncronizationGroup.type) {
          /* Check if the source is in the target list of the synchronizationGroup */
          const source = syncronizationGroup.targets.byId[payload.sourceId];
          /* If the source is part of the target list, and is linked, continue syncin the other targets */
          if (source && source.linked) {
            return list.concat(groupId);
          }
        }
        return list;
      },
      [],
    );
    return groups;
  },
  selectorMemoizationOptions,
);

export const getSourceId = createSelector(
  [
    syncGroupStore,
    (syncGroupStore, sourceId): { sourceId: string } | string => sourceId,
  ],
  (syncGroupState, sourceId): string => {
    return typeof sourceId === 'string' ? sourceId : sourceId.sourceId;
  },
  selectorMemoizationOptions,
);

export const getAllTargetGroupsForSource = createSelector(
  [syncGroupStore, getSourceId],
  (syncGroupStore, sourceId) => {
    if (syncGroupStore?.groups) {
      return syncGroupStore.groups.allIds.reduce<string[]>(
        (linkedSyncGroupIds, groupId) => {
          const syncronizationGroup = syncGroupStore.groups.byId[groupId];
          const source = syncronizationGroup.targets.byId[sourceId];
          /* If the source is part of the target list, and is linked, continue syncin the other targets */
          if (source && source.linked) {
            return linkedSyncGroupIds.concat(groupId);
          }
          return linkedSyncGroupIds;
        },
        [],
      );
    }
    return [];
  },
  selectorMemoizationOptions,
);

export const syncGroupGetViewState = createSelector(
  syncGroupStore,
  (store) => store && store.viewState,
  selectorMemoizationOptions,
);

export const isTimeScrollingEnabled = createSelector(
  syncGroupStore,
  (store) => store && store.isTimeScrollingEnabled,
  selectorMemoizationOptions,
);

export const getSyncedMapIdsForTimeslider = createSelector(
  syncGroupStore,
  (store): string[] => {
    return store.viewState.timeslider.groups[0]?.selected ?? [];
  },
  selectorMemoizationOptions,
);

export const getSyncGroupTargets = createSelector(
  syncGroupStore,
  (store) => {
    if (!store || !store.groups) {
      return [];
    }
    return store.groups.allIds.reduce(
      (targets: SyncGroupTarget[], groupId: string): SyncGroupTarget[] => {
        const group: SynchronizationGroup = store.groups.byId[groupId];
        return targets.concat(
          group.targets.allIds.map(
            (targetId): SyncGroupTarget => ({
              groupId,
              targetId,
              linked: group.targets.byId[targetId].linked,
            }),
          ),
        );
      },
      [],
    );
  },
  selectorMemoizationOptions,
);

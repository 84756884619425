/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { FieldValues } from 'react-hook-form';

import { errorMessages, FORM_FIELDS_NAMESPACE } from '@opengeoweb/form-fields';
import { dateUtils } from '@opengeoweb/shared';
import { TFunction } from 'i18next';

export const isCategoryDetailRequired = (
  t: TFunction,
  value: string,
  category: string,
): boolean | string => {
  if (category !== 'XRAY_RADIO_BLACKOUT' && !value) {
    return t(errorMessages.required, { ns: FORM_FIELDS_NAMESPACE });
  }
  return true;
};

export const isRecentPast = (
  t: TFunction,
  ownDate: string,
): boolean | string => {
  const date = dateUtils.getUtcFromString(ownDate);
  if (!date) {
    return true;
  }
  const oneWeekAgo = dateUtils.sub(dateUtils.utc(), { weeks: 1 });
  return dateUtils.isAfter(date, oneWeekAgo) || t('notification-recent-date');
};

export const isInPastOrFutureAllowed = (
  t: TFunction,
  ownDate: string,
  isFutureAllowed?: boolean | FieldValues,
): boolean | string => {
  const isInFutureAllowed =
    isFutureAllowed !== undefined ? isFutureAllowed : false;
  const date = dateUtils.getUtcFromString(ownDate);
  if (!date || isInFutureAllowed) {
    return true;
  }
  return (
    dateUtils.isAfter(dateUtils.utc(), date) || t('notification-date-in-past')
  );
};

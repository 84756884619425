/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useSelector } from 'react-redux';
import * as selectors from '../../store/selectors';
import { CapModule } from '../../store/types';
import { CapPresets } from '../types';
import { WarningListCap } from './WarningListCap';

interface WarningListCapConnectProps {
  capWarningPresets: CapPresets;
}

export const WarningListCapConnect: React.FC<WarningListCapConnectProps> = ({
  capWarningPresets,
}) => {
  const alertIDs = useSelector(
    (store: CapModule) => selectors.getAlertIds(store) as string[],
  );

  return (
    <WarningListCap capWarningPresets={capWarningPresets} alertIds={alertIDs} />
  );
};

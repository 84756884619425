/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { rest } from 'msw';
import { AirmetFromBackend, SigmetFromBackend } from '@opengeoweb/api';
import { sigmetConfig, airmetConfig } from '@opengeoweb/sigmet-airmet';
import airmetListJSON from './mockAIRMETList.json';
import sigmetListJSON from './mockSIGMETList.json';

const airmetList = airmetListJSON as unknown as AirmetFromBackend[];
const sigmetList = sigmetListJSON as unknown as SigmetFromBackend[];

export const fakeSigmetTAC =
  'EHAA SIGMET 1 VALID 121249/121449 EHDB- EHAA AMSTERDAM FIR FRQ TS OBS ENTIRE FIR FL010 STNR WKN';

export const fakeAirmetTAC =
  'EHAA AIRMET -1 VALID 310700/311100 EHDB- EHAA AMSTERDAM FIR ISOL CB OBS ENTIRE FIR FL020 STNR NC';

export const aviationEndpoints = [
  rest.get(/^.*\/airmetlist$/, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(airmetList));
  }),
  rest.get(/^.*\/sigmetlist$/, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(sigmetList));
  }),
  rest.get(/^.*\/airmet-config$/, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(airmetConfig));
  }),
  rest.get(/^.*\/sigmet-config$/, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(sigmetConfig));
  }),
  rest.post(/^.*\/airmet2tac$/, (_, res, ctx) => {
    return res(ctx.status(200), ctx.text(fakeAirmetTAC));
  }),
  rest.post(/^.*\/sigmet2tac$/, (_, res, ctx) => {
    return res(ctx.status(200), ctx.text(fakeSigmetTAC));
  }),
  rest.get(/^(?!.*(sigmet|airmet)).*$/, (req) => {
    return req.passthrough();
  }),
];

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* eslint-disable no-param-reassign */
import { EPSGCode, PROJECTION } from '@opengeoweb/shared';
import type { TileServerMap, TileSettings } from '../utils';
import { WMPosition } from './types';
import WMBBOX from './WMBBOX';
import type WMImage from './WMImage';
import WMImageStore from './WMImageStore';
import { debugLogger, DebugType } from './WMJSTools';

export default class WMTileRenderer {
  render(
    currentBBOX: WMBBOX,
    newBBOX: WMBBOX,
    srs: EPSGCode,
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D,
    bgMapImageStore: WMImageStore,
    tileOptions: TileServerMap,
    layerName: string,
  ): TileSettings | undefined {
    const renderedURLs: Record<string, unknown> = {};
    const imagesToRender: {
      image: WMImage;
      i: HTMLImageElement;
      x: number;
      y: number;
      w: number;
      h: number;
      level: number;
    }[] = [];
    if (!layerName) {
      debugLogger(DebugType.Error, 'layerName not defined');
      return undefined!;
    }
    /* Temporal mappings from bgmaps.cgi service names to names defined here */
    if (layerName === 'streetmap') {
      layerName = 'OSM';
    }
    if (layerName === 'pdok') {
      layerName = 'OSM';
    }
    if (layerName === 'naturalearth2') {
      layerName = 'NaturalEarth2';
    }
    const tileLayer = tileOptions.get(layerName);
    if (!tileLayer) {
      debugLogger(
        DebugType.Error,
        `Tiled layer with name ${layerName} not found`,
      );
      return undefined!;
    }
    let tileSettings = tileLayer[srs];

    /* If current map projection is missing in the tilesets, try to find an alternative */
    if (!tileSettings) {
      tileOptions.forEach((tileServerDef) => {
        if (srs in tileServerDef) {
          tileSettings = tileServerDef[srs];
        }
      });
    }
    if (!tileSettings) {
      return undefined!;
    }
    const pi = Math.PI;
    /* Default settings for OSM Mercator */
    let tileSize = 256;
    let initialResolution = (2 * pi * 6378137) / tileSize;
    let originShiftX = (-2 * pi * 6378137) / 2.0;
    let originShiftY = (2 * pi * 6378137) / 2.0;

    if (tileSettings.tileSize) {
      tileSize = tileSettings.tileSize;
    }
    if (tileSettings.resolution) {
      initialResolution = tileSettings.resolution;
    }
    if (tileSettings.origX) {
      originShiftX = tileSettings.origX;
    }
    if (tileSettings.origY) {
      originShiftY = tileSettings.origY;
    }
    const screenWidth = width;
    const bboxw = currentBBOX.right - currentBBOX.left;
    const originShiftX2 = initialResolution * tileSize + originShiftX;
    const originShiftY2 = originShiftY - initialResolution * tileSize;
    const tileSetWidth = originShiftX2 - originShiftX;
    const tileSetHeight = originShiftY - originShiftY2;
    const levelF =
      Math.log(
        Math.abs(originShiftX2 - originShiftX) /
          ((bboxw / screenWidth) * tileSize),
      ) / Math.log(2);
    const level = Math.trunc(levelF + 0.5);

    const drawBGTiles = (level: number): void => {
      if (!tileSettings) {
        return;
      }
      const { home } = tileSettings;
      const { tileServerType } = tileSettings; // 'osm' or 'argisonline'
      const tileServerFormat = tileSettings.tileServerFormat || 'png';
      const tmsEnabled = tileSettings.tms || false;
      if (level < tileSettings.minLevel) {
        level = tileSettings.minLevel;
      }
      if (level > tileSettings.maxLevel) {
        level = tileSettings.maxLevel;
      }
      const numTilesAtLevel = 2 ** level;
      let numTilesAtLevelX =
        tileSetWidth / ((initialResolution / numTilesAtLevel) * tileSize);
      const numTilesAtLevelY =
        tileSetHeight / ((initialResolution / numTilesAtLevel) * tileSize);
      let tilenleft = Math.trunc(
        Math.round(
          (((currentBBOX.left - originShiftX) / tileSetWidth) *
            numTilesAtLevelX) /
            1 +
            0.5,
        ),
      );
      let tilenright = Math.trunc(
        Math.round(
          (((currentBBOX.right - originShiftX) / tileSetWidth) *
            numTilesAtLevelX) /
            1 +
            0.5,
        ),
      );
      let tilentop = Math.trunc(
        Math.round(
          numTilesAtLevelY -
            ((currentBBOX.bottom - originShiftY2) / tileSetHeight) *
              numTilesAtLevelY +
            0.5,
        ),
      );
      let tilenbottom = Math.trunc(
        Math.round(
          numTilesAtLevelY -
            ((currentBBOX.top - originShiftY2) / tileSetHeight) *
              numTilesAtLevelY +
            0.5,
        ),
      );

      const tileXYZToMercator = (
        level: number,
        x: number,
        y: number,
      ): WMPosition => {
        const tileRes = initialResolution / 2 ** level;
        const p = {
          x: x * tileRes + originShiftX,
          y: originShiftY - y * tileRes,
        };
        return p;
      };

      const getTileBounds = (
        level: number,
        x: number,
        y: number,
      ): {
        left: number;
        bottom: number;
        right: number;
        top: number;
      } => {
        const p1 = tileXYZToMercator(level, x * tileSize, y * tileSize);
        const p2 = tileXYZToMercator(
          level,
          (x + 1) * tileSize,
          (y + 1) * tileSize,
        );
        return { left: p1.x, bottom: p1.y, right: p2.x, top: p2.y };
      };

      const getPixelCoordFromGeoCoord = (
        coordinates: { x: number; y: number },
        b: { left: number; right: number; top: number; bottom: number },
        w: number,
        h: number,
      ): WMPosition => {
        const x = (w * (coordinates.x - b.left)) / (b.right - b.left);
        const y = (h * (coordinates.y - b.top)) / (b.bottom - b.top);
        return { x, y };
      };

      const drawTile = (
        ctx: CanvasRenderingContext2D,
        level: number,
        x: number,
        y: number,
        loadImage = true,
      ): void => {
        const bounds = getTileBounds(level, x, y);
        const bl = getPixelCoordFromGeoCoord(
          { x: bounds.left, y: bounds.bottom },
          newBBOX,
          width,
          height,
        );
        const tr = getPixelCoordFromGeoCoord(
          { x: bounds.right, y: bounds.top },
          newBBOX,
          width,
          height,
        );

        let imageURL = '';
        if (tileServerType === 'osm') {
          if (tmsEnabled) {
            imageURL = `${home + level}/${x}/${
              numTilesAtLevelY - 1 - y
            }.${tileServerFormat}`;
          } else {
            imageURL = `${home + level}/${x}/${y}.${tileServerFormat}`;
          }
        } else if (
          tileServerType === 'arcgisonline' ||
          tileServerType === 'wmst'
        ) {
          imageURL = `${home + level}/${y}/${x}`;
        } else if (tileServerType === 'skyvector') {
          imageURL = `${
            home + 2 * (11 - Math.round(level))
          }/${x}/${y}.${tileServerFormat}`;
        }

        if (renderedURLs[imageURL]) {
          return;
        }
        renderedURLs[imageURL] = true;
        const image = bgMapImageStore.getImage(imageURL);

        if (loadImage === false) {
          /* Here we display lower resolution images, if not available switch to an even higher resolution */
          if (image.isLoaded() && !image.hasError()) {
            try {
              ctx.drawImage(
                image.getElement(),
                Math.trunc(bl.x),
                Math.trunc(bl.y),
                Math.trunc(tr.x - bl.x) + 1,
                Math.trunc(tr.y - bl.y) + 1,
              );
            } catch (e) {
              // do nothing
            }
          } else if (level > 1) {
            /* If desired image is not yet loaded, try to load a higher resolution variant instead */
            drawTile(
              ctx,
              level - 1,
              Math.trunc(x / 2),
              Math.trunc(y / 2),
              false,
            );
          }
        } else {
          /* Not all images need to load, as we can switch to lower resolutions for the time being */
          if (loadImage) {
            if (
              image.isLoaded() === false &&
              image.hasError() === false &&
              image.isLoading() === false
            ) {
              image.load();
            }
          }
          /* Here we display the images we like to have at the desired resolution */
          if (image.isLoaded() && !image.hasError()) {
            imagesToRender.push({
              image,
              i: image.getElement(),
              x: Math.trunc(bl.x),
              y: Math.trunc(bl.y),
              w: Math.trunc(tr.x - bl.x) + 1,
              h: Math.trunc(tr.y - bl.y) + 1,
              level,
            });
          } else if (level > 1) {
            /* If desired image is not yet loaded, try to load a higher resolution variant instead */
            drawTile(
              ctx,
              level - 1,
              Math.trunc(x / 2),
              Math.trunc(y / 2),
              false,
            );
          }
        }
      };
      if (
        srs === PROJECTION.EPSG_4326.value ||
        srs === PROJECTION.EPSG_4258.value
      ) {
        numTilesAtLevelX *= 2;
      }
      if (tilenbottom < 1) {
        tilenbottom = 1;
      }
      if (tilenbottom > numTilesAtLevelY) {
        tilenbottom = numTilesAtLevelY;
      }
      if (tilenleft < 1) {
        tilenleft = 1;
      }
      if (tilenleft > numTilesAtLevelX) {
        tilenleft = numTilesAtLevelX;
      }
      if (tilentop < 1) {
        tilentop = 1;
      }
      if (tilentop > numTilesAtLevelY) {
        tilentop = numTilesAtLevelY;
      }
      if (tilenright < 1) {
        tilenright = 1;
      }
      if (tilenright > numTilesAtLevelX) {
        tilenright = numTilesAtLevelX;
      }
      if (tilentop - tilenbottom > 20) {
        debugLogger(
          DebugType.Error,
          `Too many tiles in vertical ${tilentop - tilenbottom}`,
        );
        return;
      }
      if (tilenright - tilenleft > 20) {
        debugLogger(
          DebugType.Error,
          `Too many tiles in horizontal ${tilentop - tilenbottom}`,
        );
        return;
      }
      for (let ty = tilenbottom - 1; ty < tilentop; ty += 1) {
        for (let tx = tilenleft - 1; tx < tilenright; tx += 1) {
          drawTile(ctx, level, tx, ty);
        }
      }
    };
    drawBGTiles(level);
    imagesToRender.sort((imageA, imageB) => {
      if (imageA.level < imageB.level) {
        return -1;
      }
      if (imageA.level > imageB.level) {
        return 1;
      }
      return 0;
    });
    for (const image of imagesToRender) {
      if (image.image.isLoaded() && !image.image.hasError()) {
        try {
          ctx.drawImage(image.i, image.x, image.y, image.w, image.h);
        } catch (e) {
          // do nothing
        }
      }
    }

    return tileSettings;
  }
}

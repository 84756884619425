/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  ListItem,
  ListItemButton,
  ListItemProps,
  Typography,
} from '@mui/material';
import React from 'react';
import { ChevronDown, ChevronUp } from '@opengeoweb/theme';
import { PublicWarningStatus } from '../../store/publicWarningForm/types';
import { useWarningsTranslation } from '../../utils/i18n';

const HEADER_SIZE = 32;

const WarningListItemSeperator: React.FC<
  {
    totalWarnings: number;
    status: PublicWarningStatus;
    isExpanded: boolean;
  } & ListItemProps
> = ({ totalWarnings, status, isExpanded, onClick, ...props }) => {
  const { t } = useWarningsTranslation();

  const getStatus = (status: string): string => {
    switch (status) {
      case 'DRAFT':
        return t('warning-list-status-draft');
      case 'PUBLISHED':
        return t('warning-list-status-published');
      case 'TODO':
        return t('warning-list-status-todo');
      default:
        return t('warning-list-status-expired');
    }
  };

  const headerText = `${totalWarnings} ${getStatus(status)}`;

  return (
    <ListItem
      {...props}
      sx={{
        height: HEADER_SIZE,
        padding: 0,
      }}
      aria-label={`warninglist ${status}`}
    >
      <ListItemButton
        sx={{
          height: HEADER_SIZE,
          padding: 0,
        }}
        onClick={onClick as unknown as React.MouseEventHandler<HTMLElement>}
        data-testid={`warninglist-${status}`}
      >
        {isExpanded ? <ChevronDown /> : <ChevronUp />}
        <Typography variant="caption" sx={{ opacity: 0.67, marginLeft: 1 }}>
          {headerText}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

export default WarningListItemSeperator;

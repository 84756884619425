/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  getAndAssertNodeAtPathExists,
  MosaicBranch,
  MosaicContext,
  MosaicNode,
} from 'react-mosaic-component';
import { CustomIconButton, useConfirmationDialog } from '@opengeoweb/shared';
import { isEqual, isString } from 'lodash';
import {
  SplitHorizontal,
  SplitVertical,
  Expand,
  UnExpand,
  Close,
} from '@opengeoweb/theme';
import { SxProps } from '@mui/material';
import { AppStore } from '../../store/types';
import * as workspaceSelectors from '../../store/workspace/selectors';
import { useWorkspaceTranslation } from '../../utils/i18n';
import LinkMenuConnect from './LinkMenu/LinkMenuConnect';

const buttonStyle: SxProps = { marginLeft: 0.5, marginRight: 0.5 };

interface WorkspaceControlsProps {
  panelId: string;
  path: MosaicBranch[];
  createNode?: () => MosaicNode<string>;
  shouldPreventCloseView?: boolean;
}

const WorkspaceControls: React.FC<WorkspaceControlsProps> = ({
  panelId,
  createNode,
  path,
  shouldPreventCloseView = false,
}: WorkspaceControlsProps) => {
  const confirmDialog = useConfirmationDialog();
  const { mosaicActions: actions } = React.useContext(MosaicContext);
  const { t } = useWorkspaceTranslation();

  const mosaicNode = useSelector((store: AppStore) =>
    workspaceSelectors.getMosaicNode(store),
  );
  const [stateAfterExpand, setStateAfterExpand] =
    React.useState<MosaicNode<string>>();

  const [stateBeforeExpand, setStateBeforeExpand] = React.useState(mosaicNode);
  const [enableUnexpand, setEnableUnexpand] = React.useState(false);

  React.useEffect(() => {
    if (enableUnexpand) {
      if (stateAfterExpand === undefined) {
        setStateAfterExpand(mosaicNode);
        return;
      }
      if (!isEqual(mosaicNode, stateAfterExpand)) {
        setEnableUnexpand(false);
        setStateAfterExpand(undefined);
      }
    }
  }, [enableUnexpand, mosaicNode, stateAfterExpand]);

  const onClose = async (): Promise<void> => {
    if (shouldPreventCloseView) {
      await confirmDialog({
        title: t('workspace-control-close-message-title'),
        description: t('workspace-control-close-message-description'),
        confirmLabel: t('workspace-control-close'),
      });
      actions.remove(path);
    } else {
      actions.remove(path);
    }
  };

  return (
    <>
      <LinkMenuConnect panelId={panelId} />
      <CustomIconButton
        data-testid="split-horizontal-btn"
        sx={buttonStyle}
        onClick={(): void => {
          const newNode = createNode!();
          const previousNode = getAndAssertNodeAtPathExists(
            actions.getRoot(),
            path,
          );
          actions.replaceWith(path, {
            direction: 'column',
            first: previousNode,
            second: newNode,
          });
        }}
        tooltipTitle={t('workspace-control-split-horizontal')}
      >
        <SplitVertical />
      </CustomIconButton>
      <CustomIconButton
        data-testid="split-vertical-btn"
        sx={buttonStyle}
        onClick={(): void => {
          const newNode = createNode!();
          const previousNode = getAndAssertNodeAtPathExists(
            actions.getRoot(),
            path,
          );
          actions.replaceWith(path, {
            direction: 'row',
            first: previousNode,
            second: newNode,
          });
        }}
        tooltipTitle={t('workspace-control-split-vertical')}
      >
        <SplitHorizontal />
      </CustomIconButton>
      {!enableUnexpand || isString(mosaicNode) ? (
        <CustomIconButton
          data-testid="expand-btn"
          sx={buttonStyle}
          onClick={(): void => {
            setStateBeforeExpand(mosaicNode);
            setEnableUnexpand(true);
            actions.expand(path, 70);
          }}
          tooltipTitle={t('workspace-control-expand')}
        >
          <Expand />
        </CustomIconButton>
      ) : (
        <CustomIconButton
          data-testid="unexpand-btn"
          sx={buttonStyle}
          onClick={(): void => {
            const stateBeforeExpandCopy = isString(stateBeforeExpand)
              ? stateBeforeExpand
              : { ...stateBeforeExpand };
            actions.replaceWith([], stateBeforeExpandCopy);
          }}
          tooltipTitle={t('workspace-control-unexpand')}
        >
          <UnExpand />
        </CustomIconButton>
      )}
      <CustomIconButton
        data-testid="close-btn"
        sx={buttonStyle}
        onClick={onClose}
        tooltipTitle={t('workspace-control-close')}
        id="close-window-button"
      >
        <Close />
      </CustomIconButton>
    </>
  );
};

export default WorkspaceControls;

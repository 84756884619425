/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';

import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

interface WrapperProps {
  children: React.ReactNode;
  options?: UseFormProps;
}

export const defaultFormOptions: UseFormProps = {
  mode: 'onChange',
  reValidateMode: 'onChange',
};

const ReactHookFormProvider: React.FC<WrapperProps> = ({
  children,
  options = defaultFormOptions,
}: WrapperProps) => {
  const formMethods = useForm(options);
  // added this ignore as the build is failing otherwise on: Type instantiation is excessively deep and possibly infinite.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

const ReactHookFormProviderWrapper: React.FC<WrapperProps> = ({
  children,
  options = defaultFormOptions,
}: WrapperProps) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ReactHookFormProvider options={options}>{children}</ReactHookFormProvider>
  </LocalizationProvider>
);

export default ReactHookFormProviderWrapper;

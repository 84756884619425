/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { dateUtils } from '@opengeoweb/shared';

import { ReactHookFormDateTime } from '@opengeoweb/form-fields';

import { TFunction } from 'i18next';
import { styles } from './EditFormFields.styles';
import { isRecentPast, isInPastOrFutureAllowed } from './validations';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

export const isInFuture = (
  t: TFunction,
  ownDate: string,
  isAllowed: boolean,
): boolean | string => {
  const own = dateUtils.getUtcFromString(ownDate);
  if (!own || isAllowed) {
    return true;
  }
  return (
    dateUtils.isBefore(dateUtils.utc(), own) ||
    t('notification-end-time-future')
  );
};

export const isAfterStart = (
  t: TFunction,
  ownDate: string,
  otherDate: string,
): boolean | string => {
  const own = dateUtils.getUtcFromString(ownDate);
  const other = dateUtils.getUtcFromString(otherDate);
  if (!own || !other) {
    return true;
  }
  return (
    dateUtils.isAfter(own, other) || t('notification-end-time-after-start')
  );
};

export const isEndDateRequired = (
  t: TFunction,
  ownValue: string,
  label: string,
  actionMode?: string,
): boolean | string => {
  if (ownValue) {
    return true;
  }
  if (label === 'WARNING' || actionMode === 'Summarise') {
    return t('notification-required');
  }
  return true;
};

interface StartTimeEndTimeProps {
  actionMode: string;
}

const StartTimeEndTime: React.FC<StartTimeEndTimeProps> = ({
  actionMode,
}: StartTimeEndTimeProps) => {
  const { t } = useSpaceweatherTranslation();
  const { watch, trigger, getValues } = useFormContext();

  const label = watch('label');
  const allowEndDateInPast =
    actionMode === 'Summarise' || actionMode === 'Cancel';

  return (
    <Grid container size={12} align-items="flex-start">
      <Grid size={5}>
        <ReactHookFormDateTime
          name="neweventstart"
          label={t('notification-start-time')}
          data-testid="start-date-picker"
          sx={styles.inputField}
          rules={{
            required: true,
            validate: {
              isValidDate: dateUtils.isValidIsoDateString,
              isInPastOrFutureAllowed: (value): boolean | string =>
                isInPastOrFutureAllowed(
                  t,
                  value,
                  getValues('label') === 'WARNING',
                ),
              isRecentPast: (value: string) => isRecentPast(t, value),
            },
          }}
          onChange={(): void => {
            void trigger('neweventend');
          }}
          shouldHideUTC
        />
      </Grid>
      <Grid size={5}>
        {(actionMode === 'Summarise' || label === 'WARNING') && (
          <ReactHookFormDateTime
            name="neweventend"
            label={t('notification-end-time')}
            disablePast={!allowEndDateInPast}
            data-testid="end-date-picker"
            sx={styles.inputField}
            rules={{
              validate: {
                isRequired: (value): boolean | string =>
                  isEndDateRequired(t, value, label, actionMode),
                isValidDate: dateUtils.isValidIsoDateString,
                isAfterStart: (value): boolean | string =>
                  isAfterStart(t, value, getValues('neweventstart')),
                isInFuture: (value): boolean | string =>
                  isInFuture(t, value, allowEndDateInPast),
                isRecentPast: (value: string) => isRecentPast(t, value),
              },
            }}
            shouldHideUTC
          />
        )}
      </Grid>
    </Grid>
  );
};

export default StartTimeEndTime;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { dateUtils } from '@opengeoweb/shared';
import { Typography, Box } from '@mui/material';
import { Dimension } from '@opengeoweb/webmap';

export const getTimeDimension = (dimensions: Dimension[]): Dimension =>
  dimensions.find(
    (dimension) =>
      dimension.name === 'time' && dimension.currentValue !== undefined,
  )!;

export const formatTime = (time: string, timeFormat: string): string =>
  dateUtils.dateToString(dateUtils.utc(time), timeFormat)!;
interface MapeTimeProps {
  dimensions: Dimension[];
  timeFormat?: string;
}

export const defaultTimeFormat = "EEE dd MMM yyyy HH:mm 'UTC'";

export const MapTime: React.FC<MapeTimeProps> = ({
  dimensions,
  timeFormat = defaultTimeFormat,
}: MapeTimeProps) => {
  const timeDimension = getTimeDimension(dimensions);

  if (!timeDimension) {
    return null;
  }
  const mapTime = formatTime(timeDimension.currentValue, timeFormat);

  return (
    <Box
      data-testid="map-time"
      sx={{
        zIndex: 10,
        justifySelf: 'center',
        bottom: '12px',
        position: 'absolute',
      }}
    >
      <Box>
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
          {mapTime}
        </Typography>
      </Box>
    </Box>
  );
};

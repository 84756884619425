/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware } from '@reduxjs/toolkit';
import { layerActions } from '.';
import { uiSelectors, uiTypes, uiActions } from '../../ui';
import { CoreAppStore } from '../../types';

export const layersListener = createListenerMiddleware<CoreAppStore>();

layersListener.startListening({
  actionCreator: layerActions.showLayerInfo,
  effect: async ({ payload }, listenerApi) => {
    const { mapId, source } = payload;

    const currentActiveMapId = uiSelectors.getDialogMapId(
      listenerApi.getState(),
      uiTypes.DialogTypes.LayerInfo,
    );

    const isOpenInStore = uiSelectors.getisDialogOpen(
      listenerApi.getState(),
      uiTypes.DialogTypes.LayerInfo,
    );
    if (currentActiveMapId !== mapId ? true : !isOpenInStore) {
      listenerApi.dispatch(
        uiActions.setActiveMapIdForDialog({
          type: uiTypes.DialogTypes.LayerInfo,
          mapId,
          setOpen: true,
          source,
        }),
      );
    }
  },
});

layersListener.startListening({
  actionCreator: uiActions.setToggleOpenDialog,
  effect: async ({ payload }, listenerApi) => {
    const { setOpen, type } = payload;

    // close LayerInfo if open and LayerManager is closed
    if (type === uiTypes.DialogTypes.LayerManager && !setOpen) {
      const isOpenInStore = uiSelectors.getisDialogOpen(
        listenerApi.getState(),
        uiTypes.DialogTypes.LayerInfo,
      );
      if (isOpenInStore) {
        listenerApi.dispatch(layerActions.hideLayerInfo());
      }
    }
  },
});

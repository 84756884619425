/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Stack } from '@mui/material';
import * as React from 'react';
import FilterChip from './FilterChip';

import { useSharedTranslation } from '../../utils/i18n';

export interface ListFilter {
  label: string;
  id: string;
  type?: 'scope' | 'keyword';
  isSelected?: boolean;
  isDisabled?: boolean;
}

interface FilterListProps {
  filters?: ListFilter[];
  onChipClick?: (id: string, selected: boolean) => void;
  isAllSelected?: boolean;
}

const FilterList: React.FC<FilterListProps> = ({
  filters = [],
  onChipClick = (): void => {},
  isAllSelected = false,
}: FilterListProps) => {
  const { t } = useSharedTranslation();
  return (
    <Stack
      direction="row"
      spacing={1}
      data-testid="filterList"
      className="filter-list"
    >
      <FilterChip
        id="all"
        label={t('shared-filter-all')}
        isSelected={isAllSelected}
        handleClick={(key): void => onChipClick(key, !isAllSelected)}
      />
      {filters.map((filter) => {
        return (
          <FilterChip
            key={filter.id}
            id={filter.id}
            label={t(filter.label)}
            isSelected={isAllSelected ? false : filter.isSelected}
            isDisabled={filter.isDisabled}
            handleClick={(key): void => {
              // If they are all isSelected ensure that if one is clicked that one will be the only one set to selected
              const selected = isAllSelected ? true : !filter.isSelected;
              onChipClick(key, selected);
            }}
          />
        );
      })}
    </Stack>
  );
};

export default FilterList;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { TimeSeriesService, dateUtils } from '@opengeoweb/shared';
import {
  Feature,
  FeatureCollection,
  GeometryObject,
  Point,
  Position,
} from 'geojson';
import { isString, sortBy, trim, trimStart } from 'lodash';
import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import * as turf from '@turf/turf';
import { WMListener } from '@opengeoweb/webmap';
import i18next from 'i18next';
import {
  isTimeSeriesLocation,
  type EDRCollection,
  type EDRInstance,
  type EDRParameter,
  type EDRPositionResponse,
  type EdrCollection,
  type Parameter,
  type ParameterWithData,
  type PointerLocation,
  type SelectParameter,
} from '../components/TimeSeries/types';
import { COLOR_MAP } from '../constants';
import { TimeseriesCollectionDetail } from '../components/TimeSeriesSelect/utils';
import { ogcParameters } from '../components/TimeSeriesSelect/ogcParameters';

export const createUrl = (
  baseUrl: string,
  pathToAdd?: string,
  queryParameters?: [string, string][],
): string => {
  const urlObject = new URL(baseUrl);
  if (pathToAdd) {
    if (urlObject.pathname) {
      urlObject.pathname = `${trim(urlObject.pathname, '/')}/${trimStart(pathToAdd, '/')}`;
    } else {
      urlObject.pathname = trimStart(pathToAdd, '/');
    }
  }
  if (queryParameters) {
    urlObject.search = [
      ...Array.from(urlObject.searchParams.entries()).map(
        ([key, val]) => `${key}=${val}`,
      ),
      ...queryParameters.map(([key, val]) => `${key}=${val}`),
    ].join('&');
  }
  return urlObject.toString();
};

const AXIOS_TIME_TO_LIVE_MS_FOR_EDR = 1000 * 60; // 1 minute

// Cached axios
const cachedAxios = setupCache(Axios, {
  cacheTakeover: false,
  ttl: AXIOS_TIME_TO_LIVE_MS_FOR_EDR,
});

// Event listener for certain connected components to listen to
export const edrListener = new WMListener();
let numEdrCallsLoading = 0;
const startOrStopLoading = (startOrStop: boolean): void => {
  edrListener.triggerEvent('edr', startOrStop);
};

// Interceptor which is being triggered once a request starts
cachedAxios.interceptors.request.use(
  (config) => {
    if (numEdrCallsLoading === 0) {
      startOrStopLoading(true);
    }
    numEdrCallsLoading += 1;
    // Do something before request is sent
    return config;
  },
  (error) => {
    if (numEdrCallsLoading === 0) {
      startOrStopLoading(true);
    }
    numEdrCallsLoading += 1;
    return Promise.reject(error);
  },
);

// Interceptor which is being triggered once a request responded
cachedAxios.interceptors.response.use(
  (response) => {
    numEdrCallsLoading -= 1;
    if (numEdrCallsLoading === 0) {
      startOrStopLoading(false);
    }
    return response;
  },
  (error) => {
    numEdrCallsLoading -= 1;
    if (numEdrCallsLoading === 0) {
      startOrStopLoading(false);
    }
    return Promise.reject(error);
  },
);

export const supportedOutputFormat = 'CoverageJSON';

export const getBaseQueryParamArray = (
  parameterName: string,
  instance: EDRInstance,
  clipInterval: [number, number] = [1 * 24, 10 * 24], // 1 day to past, 10 days to future
): [string, string][] => {
  const res: [string, string][] = [];
  res.push(['parameter-name', parameterName]);

  const interval = instance.extent?.temporal?.interval[0];
  // ----->  This will also set datetime parameter to the EDR query    <-----
  const [start, end] = clipEdrInterval(
    interval,
    clipInterval[0],
    clipInterval[1],
  );

  if (interval?.length) {
    res.push(['datetime', `${start}/${end}`]);
  }

  if (instance.extent?.spatial.crs) {
    res.push(['crs', instance.extent.spatial.crs]);
  }

  res.push(['f', supportedOutputFormat]);
  return res;
};

export const latestInstance = (instances: EDRInstance[]): EDRInstance => {
  return instances.reduce(
    (latest, current) => (latest.id < current.id ? current : latest),
    { id: '' },
  );
};

export const isSupportedInstance = (instances: EDRInstance): boolean => {
  const outputFormatsPosition =
    instances.data_queries?.position?.link.variables.output_formats ?? [];
  const outputFormatsLocation =
    instances.data_queries?.locations?.link.variables.output_formats ?? [];
  return [...outputFormatsLocation, ...outputFormatsPosition].includes(
    supportedOutputFormat,
  );
};

export const latestSupportedInstance = (
  instances: EDRInstance[],
): EDRInstance => {
  return latestInstance(
    instances.filter((instance) => isSupportedInstance(instance)),
  );
};

export const listSupportedInstance = (
  instances: EDRInstance[],
): EDRInstance[] => {
  return instances.filter((instance) => isSupportedInstance(instance));
};

export const fetchEdrParameterApiData = async (
  urlDomain: string,
  parameterName: string,
  collectionId: string,
  location: PointerLocation,
  instance: EDRInstance,
): Promise<EDRPositionResponse | null> => {
  let path = `/collections/${collectionId}`;
  if (instance.id !== '' && instance.id !== collectionId) {
    path = `/collections/${collectionId}/instances/${instance.id}`;
  }
  const queryParamArr = getBaseQueryParamArray(parameterName, instance);

  try {
    if (isTimeSeriesLocation(location) && instance.data_queries?.locations) {
      const { id } = location;
      if (id) {
        const locationUrl = createUrl(
          urlDomain,
          `${path}/locations/${id}`,
          queryParamArr,
        );
        const result = await cachedAxios.get<EDRPositionResponse>(locationUrl);
        return result.data;
      }
    } else if (instance.data_queries?.position) {
      const { lat, lon } = location;
      const positionUrl = createUrl(urlDomain, `${path}/position`, [
        ...queryParamArr,
        ['coords', `POINT(${lon} ${lat})`],
      ]);
      const result = await cachedAxios.get<EDRPositionResponse>(positionUrl);
      return result.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const fetchEdrLatestInstance = async (
  baseUrl: string,
  collectionId: string,
): Promise<EDRInstance> => {
  const url = createUrl(baseUrl, `/collections/${collectionId}/instances`);
  try {
    const result = await cachedAxios.get<{ instances: EDRInstance[] }>(url);
    return latestSupportedInstance(result.data.instances);
  } catch (error) {
    return { id: '' };
  }
};

export const fetchEdrAllInstances = async (
  baseUrl: string,
  collectionId: string,
): Promise<EDRInstance[]> => {
  try {
    const url = createUrl(baseUrl, `/collections/${collectionId}`);
    const result = await cachedAxios.get<EDRCollection>(url);
    if (result.data?.data_queries?.instances) {
      const url = createUrl(baseUrl, `/collections/${collectionId}/instances`);
      try {
        const result = await cachedAxios.get<{ instances: EDRInstance[] }>(url);
        return listSupportedInstance(result.data.instances);
      } catch (error) {
        return [];
      }
    } else {
      // Return the properties of the collection as instance data
      return [result.data as EDRInstance];
    }
  } catch (error) {
    return [];
  }
};

export const getUnit = (
  param: EDRParameter,
  lang: string,
  fallbackLang: string,
): string => {
  let unit: string | undefined;

  const symbol = param.unit?.symbol;
  if (isString(symbol)) {
    unit = symbol;
  } else {
    unit = symbol?.value;
  }
  if (unit === undefined) {
    const label = param.unit?.label;
    if (label !== undefined) {
      if (isString(label)) {
        unit = label;
      } else if (label?.[lang]) {
        unit = label?.[lang];
      } else if (label?.[fallbackLang]) {
        unit = label?.[fallbackLang];
      } else {
        const keys = Object.keys(label);
        if (keys.length > 0) {
          unit = label[keys[0]];
        }
      }
    }
  }
  if (unit === undefined) {
    unit = '-?-';
  }
  return unit;
};

export const getEdrParameter = async (
  presetParameter: Parameter,
  url: string,
  location: PointerLocation,
): Promise<ParameterWithData | null> => {
  const allEdrInstances = await fetchEdrAllInstances(
    url,
    presetParameter.collectionId,
  );

  const instanceInfo =
    (presetParameter.instanceId &&
      allEdrInstances.find((inst) => inst.id === presetParameter.instanceId)) ||
    allEdrInstances[0];

  const apiData: EDRPositionResponse | null = await fetchEdrParameterApiData(
    url,
    presetParameter.propertyName,
    presetParameter.collectionId,
    location,
    instanceInfo || latestSupportedInstance(allEdrInstances),
  );

  if (
    !apiData?.ranges ||
    !apiData.domain.axes.t ||
    apiData.domain.axes.t.values.length === 0
  ) {
    return null;
  }
  // assume only one parameter
  const paramKey = Object.keys(apiData.ranges)[0];

  const unit = getUnit(apiData.parameters[paramKey], i18next.language, 'en');

  return {
    ...presetParameter,
    unit,
    timestep: apiData.domain.axes.t.values.map((str) => dateUtils.utc(str)),
    value: apiData.ranges[paramKey].values,
  };
};

export const fetchEdrCollections = async (
  serviceUrl: string,
): Promise<EDRCollection[]> => {
  const url = createUrl(serviceUrl, '/collections');
  try {
    const result = await cachedAxios.get<{ collections: EDRCollection[] }>(url);
    return result.data.collections;
  } catch (error) {
    return [];
  }
};

export const validateServiceUrl = async (
  serviceUrl: string,
): Promise<{ title?: string; description?: string } | false> => {
  try {
    const [result, collections] = await Promise.all([
      cachedAxios.get<{ title: string; description: string }>(
        createUrl(serviceUrl),
      ),
      cachedAxios.get<{ collections: EDRCollection[] }>(
        createUrl(serviceUrl, '/collections'),
      ),
    ]);

    if (collections.data.collections.length > 0) {
      return { title: result.data.title, description: result.data.description };
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const constructParameterObject = (
  serviceId: string,
  collectionId: string,
  parametersById: EdrParameters,
): SelectParameter[] => {
  return Object.keys(parametersById).map((parameterId) => {
    const parameter = parametersById[parameterId];
    return {
      plotType: 'line',
      propertyName: parameter.id || parameterId,
      unit: parameter.unit?.label ?? ' ',
      serviceId,
      collectionId,
      color: COLOR_MAP[parameter.id as keyof typeof COLOR_MAP] ?? 'A',
      opacity: 70,
    };
  });
};

export const getEdrSelectCollectionsParameters = async (
  service: TimeSeriesService,
): Promise<EdrCollection[]> => {
  try {
    const collectionsParameters: EdrCollection[] = [];
    // Fetch all collections for this service that support /locations or /position
    const serviceCollections: EDRCollection[] = [];
    for await (const collection of await fetchEdrCollections(service.url)) {
      if (
        !!collection.data_queries?.locations ||
        !!collection.data_queries?.position
      ) {
        serviceCollections.push(collection);
      } else if (collection.data_queries?.instances) {
        // Check if the instances have locations or position calls
        const latestInstance = await fetchEdrLatestInstance(
          service.url,
          collection.id,
        );
        if (
          !!latestInstance.data_queries?.locations ||
          !!latestInstance.data_queries?.position
        ) {
          serviceCollections.push({ ...(collection as EDRCollection) });
        }
      }
    }

    for await (const collection of serviceCollections) {
      const collectionUrl = createUrl(
        service.url,
        `/collections/${collection.id}`,
      );
      if (collection.data_queries?.instances) {
        // Retrieve the latest instance
        const instance = await fetchEdrLatestInstance(
          service.url,
          collection.id,
        );

        // Retrieve all params of the latest instance
        const result = await cachedAxios.get<{
          parameters: object;
          parameter_names: EdrParameters;
        }>(createUrl(collectionUrl, `/instances/${instance.id}`));

        // Workaround for non-standard EDR servers, like interpol.met.no
        const paramNames: EdrParameters = {};
        if (result.data.parameters) {
          for (const p of result.data.parameters as [EdrParameter]) {
            paramNames[p.id] = p;
          }
        }

        collectionsParameters.push({
          id: collection.id,
          description: collection.description,
          parameters: constructParameterObject(
            service.id,
            collection.id,
            result.data.parameter_names || paramNames,
          ),
        });
      } else {
        collectionsParameters.push({
          id: collection.id,
          description: collection.description,
          parameters: constructParameterObject(
            service.id,
            collection.id,
            collection.parameter_names as EdrParameters,
          ),
        });
      }
    }

    return collectionsParameters;
  } catch (error) {
    console.error(error);
    return [];
  }
};

interface EdrParameter {
  id: string;
  description: string;
  type: string;
  collectionId: string;
  observedProperty: {
    label: string;
  };
  unit?: {
    label: string;
    symbol: {
      type: string;
      value: string;
    };
  };
}

type EdrParameters = Record<string, EdrParameter>;

export const getLocations = async (
  service: TimeSeriesService,
  collectionId: string,
  circleDrawFunctionId: string,
  hoverDrawFunctionId: string,
): Promise<FeatureCollection<GeometryObject> | undefined> => {
  const url = createUrl(service.url, `/collections/${collectionId}/locations`);
  try {
    const result: {
      data: {
        features: {
          id: string;
          type: 'Feature';
          geometry: GeometryObject;
          properties: {
            datetime: string;
            detail: string;
            name?: string;
            NAME?: string;
            Name?: string;
          };
        }[];
      };
    } = await cachedAxios.get(url);

    const features = result.data.features.map(
      (feature): Feature<GeometryObject> => {
        const geometry: GeometryObject = feature.geometry as GeometryObject;

        return {
          id: feature.id,
          type: 'Feature',
          properties: {
            name:
              feature.properties.name ||
              feature.properties.NAME ||
              feature.properties.Name,
            serviceId: service.id,
            collectionId,
            drawFunctionId: circleDrawFunctionId,
            hoverDrawFunctionId,
          },
          geometry,
        };
      },
    );
    return {
      type: 'FeatureCollection',
      features: sortBy(features, (feature) => feature.properties?.name),
    };
  } catch (error) {
    console.warn((error as Error).message);
  }
  return undefined;
};

export const getServiceById = (
  services: TimeSeriesService[] | undefined,
  serviceId: string | undefined,
): TimeSeriesService | undefined => {
  return serviceId && services && services.length > 0
    ? services.find((service) => service.id === serviceId)
    : undefined;
};

export const getCollectionListForTimeSeriesService = async (
  service: TimeSeriesService,
): Promise<TimeseriesCollectionDetail[]> => {
  if (service?.type === 'EDR') {
    return getEdrSelectCollectionsParameters(service).then(
      (edrCollectionParams: EdrCollection[]) => {
        return edrCollectionParams.map((edrCollectionParam) => {
          return {
            collectionId: edrCollectionParam.id,
            serviceId: service.id,
            serviceName: service.name || service.id,
            parameters: edrCollectionParam.parameters,
          };
        });
      },
    );
  }

  return [
    {
      collectionId: 'collectionOGC',
      serviceId: service.id,
      parameters: ogcParameters,
      serviceName: service.name || service.id,
    },
  ];
};

export const getParameterListForCollectionId = async (
  service: TimeSeriesService,
  collectionId: string,
): Promise<TimeseriesCollectionDetail> => {
  const collections = await getCollectionListForTimeSeriesService(service);
  const collection = collections.find(
    (collection: TimeseriesCollectionDetail): boolean => {
      return collection.collectionId === collectionId;
    },
  );
  if (collection) {
    return collection;
  }
  throw new Error(`Collection with id ${collectionId} not available`);
};

const HOUR_IN_MILLISECONDS = 3600 * 1000;

/**
 * This function clips the dates in the EDR interval string to a less wide  time range.
 *
 * @param interval EDR interval, e.g. ["startdate_as_isostring","enddate_as_isostring"]
 * @param maxHoursBefore Clips to maximum hours before the given date
 * @param maxHoursAfter Clip to maximum hours after the given date
 * @param date The date to use to clip arround, defaults to Date.now()
 * @returns Clipped EDR interval, e.g. ["startdate_as_isostring","enddate_as_isostring"]
 */
export const clipEdrInterval = (
  interval: string[] | undefined,
  maxHoursBefore = 1 * 24, // 1 day to past
  maxHoursAfter = 10 * 24, // 10 days to the future
  date = Date.now(),
): [string, string] => {
  const startTimeFromEdr = interval?.length && interval[0];
  const endTimeFromEdr = interval?.length && interval[1];

  const startDate = (
    dateUtils.isoStringToDate(String(startTimeFromEdr), false) ||
    dateUtils.getNowUtc()
  ).getTime();

  const endDate = (
    dateUtils.isoStringToDate(String(endTimeFromEdr), false) ||
    dateUtils.getNowUtc()
  ).getTime();

  const clippedStartDate = new Date(
    Math.max(startDate || date, date - maxHoursBefore * HOUR_IN_MILLISECONDS),
  );
  const clippedEndDate = new Date(
    Math.min(endDate || date, date + maxHoursAfter * HOUR_IN_MILLISECONDS),
  );

  // Round the dates to minutes to make caching more likely.
  clippedStartDate.setMilliseconds(0);
  clippedStartDate.setSeconds(0);
  clippedEndDate.setMilliseconds(0);
  clippedEndDate.setSeconds(0);

  const start = dateUtils.dateToString(clippedStartDate)!;

  const end = dateUtils.dateToString(clippedEndDate)!;

  return [start, end];
};

const EARTH_RADIUS = 6371;

/**
 * Find nearest point to the target location in timeseries.
 * Uses equirectangular distance approximation which is not accurate for long
 * distances, but works for finding nearby locations in timeseries.
 *
 * @param targetLocation target location the user has clicked
 * @param pointFeatures features to search through
 * @param maxDistanceKm maximum distance to consider
 * @returns feature nearest to the target location or null if none are within the maximum distance
 */
export const findNearestPoint = (
  targetLocation: PointerLocation,
  pointFeatures: Feature<GeometryObject>[],
  maxDistanceKm = 2,
): Feature<GeometryObject> | null => {
  if (!pointFeatures.length) {
    return null;
  }

  return (
    pointFeatures.reduce(
      (nearestPoint, currentFeature) => {
        const [featureLon, featureLat] = getFeatureCenterPoint(currentFeature);

        const lat1Rad = targetLocation.lat * (Math.PI / 180);
        const lat2Rad = featureLat * (Math.PI / 180);
        const lon1Rad = targetLocation.lon * (Math.PI / 180);
        const lon2Rad = featureLon * (Math.PI / 180);

        const x = (lon2Rad - lon1Rad) * Math.cos((lat1Rad + lat2Rad) / 2);
        const y = lat2Rad - lat1Rad;
        const distanceKm = EARTH_RADIUS * Math.sqrt(x * x + y * y);

        if (distanceKm > maxDistanceKm) {
          return nearestPoint;
        }
        if (!nearestPoint || distanceKm < nearestPoint.distance) {
          return { feature: currentFeature, distance: distanceKm };
        }
        return nearestPoint;
      },
      null as { feature: Feature<GeometryObject>; distance: number } | null,
    )?.feature || null
  );
};

export const getFeatureCenterPoint = (
  feature: Feature<GeometryObject>,
): Position => {
  return feature.geometry.type === 'Point'
    ? (feature.geometry as Point).coordinates
    : turf.center(feature).geometry.coordinates;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  FieldValues,
  UseFieldArrayReturn,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { BaseTaf, TafActions } from '../../types';

type ValidateFormPromise = () => Promise<void | BaseTaf>;

interface TafModuleMethods {
  onPreventCloseView?: (shouldPreventClose: boolean) => void;
}

interface TafModuleContextState {
  onValidateForm: ValidateFormPromise;
  registerValidateForm: (promiseToRegister: ValidateFormPromise) => void;
  tafAction: TafActions;
  setTafAction: (action: TafActions) => void;
  onPreventCloseView: (shouldPreventClose: boolean) => void;
  useFieldArrayMethods: UseFieldArrayReturn<FieldValues, string>;
}

const TafModuleContext = React.createContext<TafModuleContextState>({
  onValidateForm: null!,
  registerValidateForm: null!,
  tafAction: null!,
  setTafAction: null!,
  onPreventCloseView: null!,
  useFieldArrayMethods: {} as UseFieldArrayReturn<FieldValues, string>,
});

export const useTafModuleContext = (): Partial<TafModuleContextState> =>
  React.useContext(TafModuleContext);

export const useTafModuleState = ({
  onPreventCloseView,
}: TafModuleMethods): TafModuleContextState => {
  const [onValidateForm, registerValidateForm] =
    React.useState<ValidateFormPromise>(
      () =>
        new Promise((resolve) => {
          resolve();
        }),
    );
  const [tafAction, setTafAction] = React.useState<TafActions>(null!);

  const { control } = useFormContext();
  const useFieldArrayMethods = useFieldArray({
    control,
    name: 'changeGroups',
  });

  const onRegisterValidateForm = (
    promiseToRegister: ValidateFormPromise,
  ): void => {
    registerValidateForm(() => promiseToRegister);
  };

  return {
    onValidateForm,
    registerValidateForm: onRegisterValidateForm,
    tafAction,
    setTafAction,
    onPreventCloseView,
    useFieldArrayMethods,
  } as TafModuleContextState;
};

export interface TafModuleProviderProps extends TafModuleMethods {
  children?: React.ReactNode;
}

export const TafModuleProvider: React.FC<TafModuleProviderProps> = ({
  children,
  onPreventCloseView = (): void => {},
}: TafModuleProviderProps) => {
  const contextState = useTafModuleState({ onPreventCloseView });

  return (
    <TafModuleContext.Provider value={contextState}>
      {children}
    </TafModuleContext.Provider>
  );
};

export default TafModuleContext;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* eslint-disable no-param-reassign */
import { debounce } from 'lodash';
import { EPSGCode, PROJECTION } from '@opengeoweb/shared';
import { TFunction } from 'i18next';
import WMImageStore, { WMImageEventType } from './WMImageStore';
import {
  isDefined,
  preventdefaultEvent,
  getMouseXCoordinate,
  getMouseYCoordinate,
  DebugType,
  debugLogger,
} from './WMJSTools';
import WMBBOX, { Bbox } from './WMBBOX';
import { WMProjectionWH } from './WMProjection';
import WMListener from './WMListener';
import WMCanvasBuffer, { CanvasBBOX, CanvasGeoLayer } from './WMCanvasBuffer';
import WMAnimate from './WMAnimate';
import WMTileRenderer from './WMTileRenderer';
import { drawLegend, getLegendImageStore } from './WMLegend';
import { proj4 } from './WMJSExternalDependencies';
import WMJSDimension from './WMJSDimension';
import {
  WMDateOutSideRange,
  WMDateTooEarlyString,
  WMDateTooLateString,
  WMProj4Defs,
  WMSJSMAP_MINIMUM_MAP_HEIGHT,
  WMSJSMAP_MINIMUM_MAP_WIDTH,
  bgImageStoreLength,
  mapImageStoreLength,
} from './WMConstants';
import MapPin from './WMMapPin';
import WMLayer from './WMLayer';
import {
  AnimationStep,
  LinkedInfo,
  WMJSMapMouseClickEvent,
  IWMProj4,
  IWMJSMapMouseCoordinates,
} from './types';
import {
  buildMapLayerDims,
  buildWMSGetMapRequest,
  detectLeftButton,
  detectRightButton,
  getErrorsToDisplay,
  getGeoCoordFromPixelCoord,
  getLatLongFromPixelCoord,
  getLayerIndex,
  getPixelCoordFromGeoCoord,
  getPixelCoordFromLatLong,
  getWMSRequests,
} from './WMJSMapHelpers';
import {
  drawTextBG,
  drawScaleBar,
  getScaleBarProperties,
} from './WMCanvasDrawFunctions';

import WMFlyToBBox from './WMFlyToBBox';
import { prefetchImagesForNonAnimation } from './WMPrefetch';
import { WMMemo } from './WMMemo';
import {
  type TileServerDefinition,
  type TileServerMap,
  type TileServerSettings,
} from '../utils';
import type IWMJSMap from './IWMJSMap';
import { translateKeyOutsideComponents } from '../utils/i18n';

const version = '4.0.0';

/* Global vars */
let WebMapJSMapNo = 0;

export const bgMapImageStore = new WMImageStore(bgImageStoreLength, {
  id: 'bgMapImageStore',
});

interface DivBBox extends HTMLElement {
  bbox?: WMBBOX;
  displayed?: boolean;
}

interface IMapHeader {
  height: number;
  fill: {
    color: string;
    opacity: number;
  };
  hover: {
    color: string;
    opacity: number;
  };
  selected: {
    color: string;
    opacity: number;
  };
  hoverSelected: {
    color: string;
    opacity: number;
  };
  cursorSet: boolean;
  prevCursor: string;
  hovering: boolean;
}

/**
 * WMJSMap class
 */
export default class WMJSMap implements IWMJSMap {
  // Only used inside WMJSMap
  private _mouseX!: number;
  private _mouseDownX!: number;
  private _mouseY!: number;
  private _mouseDownY!: number;
  private _enableAutoPrefetching: boolean;
  private _WebMapJSMapVersion!: string;
  private _mainElement!: HTMLElement;
  private _srs!: string;
  private _width!: number;
  private _height!: number;
  private _layers!: WMLayer[];
  private _baseLayers!: WMLayer[];
  private _numBaseLayers!: number;
  private _divZoomBox!: HTMLElement;
  private _divBoundingBox!: DivBBox;
  private _divDimInfo!: HTMLElement;
  private _displayLegendInMap!: boolean;
  private _mapHeader: IMapHeader;
  private _currentCursor: string;
  private _mapIsActivated: boolean;
  private _isMapHeaderEnabled: boolean;
  private _showScaleBarInMap: boolean;
  private _showCursorCoordinates: boolean;
  private _wmEventListener: WMListener;
  private canvasLayerBuffer!: WMCanvasBuffer;
  private internalMapId = '';
  private activeLayer: WMLayer;
  private setTimeOffsetValue: string;
  private setMessageValue: string;
  private canvasErrors: LinkedInfo[];
  private resizeWidth: number;
  private resizeHeight: number;
  private wmAnimate: WMAnimate;
  private wmTileRenderer!: WMTileRenderer;
  private mouseWheelBusy: number;
  private wMFlyToBBox: WMFlyToBBox;
  private _mouseWheelEventBBOXCurrent: WMBBOX;
  private _mouseWheelEventBBOXNew: WMBBOX;
  private mouseUpX: number;
  private mouseUpY: number;
  private mouseDragging: number;
  private mouseDownPressed: number;
  private mapMode: string;
  private oldMapMode: string;
  private resizingBBOXCursor: string;
  private resizingBBOXEnabled: string;
  private mouseGeoCoordXY: { x: number; y: number };
  private mouseUpdateCoordinates: { x: number; y: number };
  private mapPanning: number;
  private mapPanStartGeoCoords: { x: number; y: number };
  private previousMouseButtonState: string;
  private tileRenderSettings: TileServerMap = new Map();
  private needsRedraw!: boolean;

  private _pixelCoordinatesToXY(coordinates: { x: number; y: number }): {
    x: number;
    y: number;
  } {
    return getGeoCoordFromPixelCoord(
      coordinates,
      this._bbox,
      this._width,
      this._height,
    );
  }

  private _makeComponentId(id: string): string {
    const availableId = `WebMapJSMapNo_${WebMapJSMapNo}`;
    if (this.internalMapId === '') {
      this.internalMapId = availableId;
      WebMapJSMapNo += 1;
    }
    return `${this.internalMapId}_${id}`;
  }

  private _adagucBeforeDraw(ctx: CanvasRenderingContext2D): void {
    if (this._baseLayers) {
      for (const baseLayer of this._baseLayers) {
        if (
          baseLayer.enabled &&
          baseLayer.keepOnTop === false &&
          baseLayer.type &&
          baseLayer.type === 'twms' &&
          this.tileRenderSettings
        ) {
          ctx.globalAlpha = baseLayer.opacity!;
          const tileSettings = this.wmTileRenderer.render(
            this._bbox,
            this._updateBBOX,
            this._srs as EPSGCode,
            this._width,
            this._height,
            ctx,
            bgMapImageStore,
            this.tileRenderSettings,
            baseLayer.name!,
          );

          const attributionText: string = tileSettings!.copyRight || '';
          ctx.globalAlpha = 1.0;

          const adagucAttribution = `ADAGUC webmapjs ${this._WebMapJSMapVersion}`;
          const txt = attributionText
            ? `${adagucAttribution} | ${attributionText}`
            : adagucAttribution;
          const x = this._width - 8;
          const y = this._height - 8;
          ctx.font = '10px Arial';
          ctx.textAlign = 'right';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#FFF';
          ctx.globalAlpha = 0.75;
          const { width } = ctx.measureText(txt);
          ctx.fillRect(x - width, y - 6, width + 8, 14);
          ctx.fillStyle = '#444';
          ctx.globalAlpha = 1.0;
          ctx.fillText(txt, x + 4, y + 2);
        }
      }
    }
  }

  private _adagucBeforeCanvasDisplay(ctx: CanvasRenderingContext2D): void {
    /* Map Pin */
    if (this.mapPin.displayMapPin) {
      this.mapPin.drawMarker(ctx);
    }

    // /* Draw legends */
    if (this._displayLegendInMap) {
      drawLegend(ctx, this._width, this._height, this._layers);
    }

    /* Map header */
    if (this._isMapHeaderEnabled) {
      ctx.beginPath();
      ctx.rect(0, 0, this._width, this._mapHeader.height);
      if (this._mapIsActivated === false) {
        ctx.globalAlpha = this._mapHeader.hovering
          ? this._mapHeader.hover.opacity
          : this._mapHeader.fill.opacity;
        ctx.fillStyle = this._mapHeader.hovering
          ? this._mapHeader.hover.color
          : this._mapHeader.fill.color;
      } else {
        ctx.globalAlpha = this._mapHeader.hovering
          ? this._mapHeader.hoverSelected.opacity
          : this._mapHeader.selected.opacity;
        ctx.fillStyle = this._mapHeader.hovering
          ? this._mapHeader.hoverSelected.color
          : this._mapHeader.selected.color;
      }
      ctx.fill();
      ctx.globalAlpha = 1;
    }

    /* Gather errors */
    for (const layer of this._layers) {
      for (const dimension of layer.dimensions) {
        if (dimension.currentValue === WMDateOutSideRange) {
          this.canvasErrors.push({
            linkedInfo: {
              layer,
              message: translateKeyOutsideComponents(
                this.t,
                'time-outside-range',
              ),
            },
          });
        } else if (dimension.currentValue === WMDateTooEarlyString) {
          this.canvasErrors.push({
            linkedInfo: {
              layer,
              message: translateKeyOutsideComponents(this.t, 'time-too-early'),
            },
          });
        } else if (dimension.currentValue === WMDateTooLateString) {
          this.canvasErrors.push({
            linkedInfo: {
              layer,
              message: translateKeyOutsideComponents(this.t, 'time-too-late'),
            },
          });
        }
      }
    }

    /* Display errors found during drawing canvas */
    if (this.canvasErrors && this.canvasErrors.length > 0) {
      const errorsToDisplay = getErrorsToDisplay(
        this.canvasErrors,
        this.getLayerByServiceAndName,
        this.t,
      );
      this.canvasErrors = [];

      if (errorsToDisplay.length > 0) {
        const mw = this._width / 2;
        const mh = 6 + errorsToDisplay.length * 15;
        const mx = this._width - mw;
        const my = this._isMapHeaderEnabled ? this._mapHeader.height : 0;
        ctx.beginPath();
        ctx.rect(mx, my, mx + mw, my + mh);
        ctx.fillStyle = 'white';
        ctx.globalAlpha = 0.9;
        ctx.fill();
        ctx.globalAlpha = 1;
        ctx.fillStyle = 'black';
        ctx.font = '10pt Helvetica';
        ctx.textAlign = 'left';

        errorsToDisplay.forEach((message, j) => {
          ctx.fillText(message, mx + 5, my + 11 + j * 15);
        });
      }
    }

    // Time offset message
    if (this.setTimeOffsetValue !== '') {
      ctx.font = '14px Helvetica';
      drawTextBG(
        ctx,
        this.setTimeOffsetValue,
        this._width / 2 - 70,
        this._height - 26,
        20,
      );
    }

    // Set message value
    if (this.setMessageValue !== '') {
      ctx.font = '15px Helvetica';
      drawTextBG(ctx, this.setMessageValue, this._width / 2 - 70, 2, 15);
    }

    // ScaleBar
    if (this._showScaleBarInMap === true) {
      drawScaleBar(getScaleBarProperties(this), ctx);
    }

    if (this._showCursorCoordinates === true) {
      // Mouse projected coords
      ctx.font = '10px Helvetica';
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'left';
      if (isDefined(this.mouseGeoCoordXY)) {
        let roundingFactor =
          1.0 /
          10 **
            (parseInt(
              (Math.log((this._bbox.right - this._bbox.left) / this._width) /
                Math.log(10)) as unknown as string,
              10,
            ) -
              2);
        if (roundingFactor < 1) {
          roundingFactor = 1;
        }
        ctx.fillStyle = '#000000';
        const xText =
          Math.round(this.mouseGeoCoordXY.x * roundingFactor) / roundingFactor;
        const yText =
          Math.round(this.mouseGeoCoordXY.y * roundingFactor) / roundingFactor;
        let units = '';
        if (this._srs === PROJECTION.EPSG_3857.value) {
          units = 'm';
        }
        ctx.fillText(
          `CoordYX: (${yText}, ${xText}) ${units}`,
          5,
          this._height - 50,
        );
      }
      // Mouse latlon coords
      if (isDefined(this.mouseUpdateCoordinates)) {
        const llCoord = getLatLongFromPixelCoord(
          this,
          this.mouseUpdateCoordinates,
        );

        if (isDefined(llCoord)) {
          const roundingFactor = 100;
          ctx.fillStyle = '#000000';
          const xText = Math.round(llCoord.x * roundingFactor) / roundingFactor;
          const yText = Math.round(llCoord.y * roundingFactor) / roundingFactor;
          ctx.fillText(
            `Lat/Lon: (${yText.toFixed(2)}, ${xText.toFixed(2)}) deg`,
            5,
            this._height - 38,
          );
        }
      }
      ctx.fillStyle = '#000000';
      ctx.fillText(
        `${translateKeyOutsideComponents(this.t, 'map-projection')}: ${this._srs}`,
        5,
        this._height - 26,
      );
    }

    /* Show layer metadata */
    if (this.showLayerInfo === true) {
      const metadataX = 100;
      const metadataY = 100;
      const dimStartX = 150;

      const rowHeight = 26;
      let rowNumber = 0;
      drawTextBG(
        ctx,
        'Layer name',
        metadataX,
        metadataY + rowNumber * rowHeight,
        10,
      );
      drawTextBG(
        ctx,
        'Dimensions',
        metadataX + dimStartX,
        metadataY + rowNumber * rowHeight,
        10,
      );
      rowNumber += 1;
      for (let j = 0; j < this._layers.length; j += 1) {
        const layer = this._layers[j];
        drawTextBG(
          ctx,
          `#${j}:${layer.name}`,
          metadataX,
          metadataY + rowNumber * rowHeight,
          10,
        );
        for (let d = 0; d < layer.dimensions.length; d += 1) {
          const dimension = layer.dimensions[d];
          drawTextBG(
            ctx,
            `${dimension.name} = ${dimension.currentValue}`,
            metadataX + d * 200 + dimStartX,
            metadataY + rowNumber * rowHeight,
            10,
          );
        }
        rowNumber += 1;
      }
    }
  }

  private _init(): void {
    if (!this._mainElement) {
      return;
    }
    if (this._mainElement.style) {
      if (!this._mainElement.style.height) {
        this._mainElement.style.height = '1px';
      }
      if (!this._mainElement.style.width) {
        this._mainElement.style.width = '1px';
      }
    }
    // baseDiv
    const baseDivId = this._makeComponentId('baseDiv');
    this._baseDiv = document.createElement('div');
    this._baseDiv.id = baseDivId;
    Object.assign(this._baseDiv.style, {
      position: 'relative',
      overflow: 'hidden',
      width: this._mainElement.clientWidth,
      height: this._mainElement.clientHeight,
      margin: 0,
      padding: 0,
      clear: 'both',
      left: '0px',
      top: '0px',
      cursor: 'default',
    });

    this._mainElement.appendChild(this._baseDiv);
    this._mainElement.style.margin = '0px';
    this._mainElement.style.padding = '0px';
    this._mainElement.style.border = 'none';
    this._mainElement.style.lineHeight = '0px';
    this._mainElement.style.display = 'inline-block';

    // Attach zoombox
    this._divZoomBox.className = 'wmjs-zoombox';
    this._divZoomBox.style.position = 'absolute';
    this._divZoomBox.style.display = 'none';
    this._divZoomBox.style.border = '2px dashed #000000';
    this._divZoomBox.style.margin = '0px';
    this._divZoomBox.style.padding = '0px';
    this._divZoomBox.style.lineHeight = '0';
    this._divZoomBox.style.background = '#AFFFFF';
    this._divZoomBox.style.opacity = '0.3'; // Gecko
    this._divZoomBox.style.filter = 'alpha(opacity=30)'; // Windows
    this._divZoomBox.style.left = '0px';
    this._divZoomBox.style.top = '0px';
    this._divZoomBox.style.width = '100px';
    this._divZoomBox.style.height = '100px';
    this._divZoomBox.style.zIndex = '1000';
    this._divZoomBox.oncontextmenu = (): boolean => {
      return false;
    };
    this._baseDiv.appendChild(this._divZoomBox);

    // Attach bbox box
    this._divBoundingBox.className = 'wmjs-boundingbox';
    this._divBoundingBox.style.position = 'absolute';
    this._divBoundingBox.style.display = 'none';
    this._divBoundingBox.style.border = '3px solid #6060FF';
    this._divBoundingBox.style.margin = '0px';
    this._divBoundingBox.style.padding = '0px';
    this._divBoundingBox.style.lineHeight = '0';
    this._divBoundingBox.style.left = '0px';
    this._divBoundingBox.style.top = '0px';
    this._divBoundingBox.style.width = '100px';
    this._divBoundingBox.style.height = '100px';
    this._divBoundingBox.style.zIndex = '1000';
    this._divBoundingBox.oncontextmenu = (): boolean => {
      return false;
    };
    this._baseDiv.appendChild(this._divBoundingBox);

    /* Attach divDimInfo */
    this._divDimInfo.style.position = 'absolute';
    this._divDimInfo.style.zIndex = '1000';
    this._divDimInfo.style.width = 'auto';
    this._divDimInfo.style.height = 'auto';
    this._divDimInfo.style.background = 'none';

    this._divDimInfo.oncontextmenu = (): boolean => {
      return false;
    };
    this._divDimInfo.innerHTML = '';
    this._baseDiv.appendChild(this._divDimInfo);

    /* Attach loading image */

    this._baseDiv.appendChild(this._loadingDiv);
    /* Attach events */
    this._attachEvents();

    this._bbox.left = -180;
    this._bbox.bottom = -90;
    this._bbox.right = 180;
    this._bbox.top = 90;
    this._srs = PROJECTION.EPSG_4326.value;

    // IMAGE buffer
    this.canvasLayerBuffer = new WMCanvasBuffer(
      this,
      this._width,
      this._height,
      {
        beforecanvasstartdraw: this._adagucBeforeDraw,
        beforecanvasdisplay: this._adagucBeforeCanvasDisplay,
        canvasonerror: (e): void => {
          this.canvasErrors = e;
        },
      },
    );

    this._baseDiv.appendChild(this.canvasLayerBuffer.getHTMLCanvasElement());

    getLegendImageStore().addImageEventCallback(
      (image, id, imageEventType: WMImageEventType) => {
        if (imageEventType === WMImageEventType.Loaded) {
          this.draw('legendImageStore loaded');
        }
      },
      this.internalMapId,
    );

    this._wmEventListener.addEventListener('display', this._display, true);
    this._wmEventListener.addEventListener(
      'draw',
      () => {
        debugLogger(DebugType.Log, 'draw event triggered externally, skipping');
      },
      true,
    );

    bgMapImageStore.addImageEventCallback(
      (image, id, imageEventType: WMImageEventType) => {
        if (imageEventType === WMImageEventType.Loaded) {
          this.draw('bgMapImageStore loaded');
        }
      },
      this.internalMapId,
    );
    this._updateBoundingBox(this._bbox);
    this.wmAnimate = new WMAnimate(this);
    this.wmTileRenderer = new WMTileRenderer();
    this._initialized = true;
  }

  private _rebuildMapDimensions(): void {
    this._layers.forEach((layer) => {
      layer.dimensions.forEach((dim) => {
        const mapdim = this.getDimension(dim.name);
        if (!isDefined(mapdim)) {
          const newdim = dim.clone();
          this.mapdimensions.push(newdim);
        }
      });
    });
    this._wmEventListener.triggerEvent('onmapdimupdate');
  }

  private _checkInvalidMouseAction(MX: number, MY: number): -1 | 0 {
    if (MY < 0 || MX < 0 || MX > this._width || MY > this._height) {
      return -1;
    }
    return 0;
  }

  private _updateMouseCursorCoordinates(coordinates: {
    x: number;
    y: number;
  }): void {
    if (
      !coordinates ||
      (this.mouseUpdateCoordinates.x === coordinates.x &&
        this.mouseUpdateCoordinates.y === coordinates.y)
    ) {
      return;
    }
    this.mouseUpdateCoordinates.x = coordinates.x;
    this.mouseUpdateCoordinates.y = coordinates.y;
    this.mouseGeoCoordXY = getGeoCoordFromPixelCoord(
      coordinates,
      this._bbox,
      this._width,
      this._height,
    );
    this._display();
  }

  /* Indicate weather this map component is active or not */
  private _setActive(active: boolean): void {
    this._mapIsActivated = active;
    this._isMapHeaderEnabled = true;
  }

  private _setActiveLayer(layer: WMLayer): void {
    for (const layer of this._layers) {
      layer.active = false;
    }
    this.activeLayer = layer;
    this.activeLayer.active = true;

    this._wmEventListener.triggerEvent('onchangeactivelayer');
  }

  /* 
    Calculates how many baselayers there are.
    Useful when changing properties for a divbuffer index (for example setLayerOpacity)
  */
  private _calculateNumBaseLayers(): void {
    this._numBaseLayers = 0;
    if (this._baseLayers) {
      for (const baseLayer of this._baseLayers) {
        if (baseLayer.enabled) {
          if (baseLayer.keepOnTop === false) {
            this._numBaseLayers += 1;
          }
        }
      }
    }
  }

  private _setSize(w: number, h: number): void {
    if (!w || !h) {
      return;
    }
    if (w < WMSJSMAP_MINIMUM_MAP_WIDTH || h < WMSJSMAP_MINIMUM_MAP_HEIGHT) {
      return;
    }

    debugLogger(DebugType.Log, `setSize(${w},${h})`);
    const projinfo = this.getProjection();
    this._width = w;
    this._height = h;
    if (this._width < 4 || isNaN(this._width)) {
      this._width = 4;
    }
    if (this._height < 4 || isNaN(this._height)) {
      this._height = 4;
    }
    if (!projinfo.srs || !projinfo.bbox) {
      debugLogger(
        DebugType.Error,
        'WebMapJS: this.setSize: Setting default projection (EPSG:4326 with (-180,-90,180,90)',
      );
      projinfo.srs = PROJECTION.EPSG_4326.value;
      projinfo.bbox.left = -180;
      projinfo.bbox.bottom = -90;
      projinfo.bbox.right = 180;
      projinfo.bbox.top = 90;
      this.setProjection(projinfo.srs, projinfo.bbox);
    }

    if (!this._baseDiv || !this._baseDiv.style) {
      return;
    }
    Object.assign(this._baseDiv.style, {
      width: this._width,
      height: this._height,
    });

    if (!this._mainElement || !this._mainElement.style) {
      return;
    }
    this._mainElement.style.width = `${this._width}px`;
    this._mainElement.style.height = `${this._height}px`;
    this.setBBOX(this._resizeBBOX);

    this._showBoundingBox();
    this.canvasLayerBuffer.resize(
      this._width.toString(),
      this._height.toString(),
    );

    this._repositionLegendGraphic();

    /* Fire the onresize event, to notify listeners that something happened. */
    this._wmEventListener.triggerEvent('onresize', [this._width, this._height]);
  }

  private _abort(): void {
    this._wmEventListener.triggerEvent('onmapready');
    this._wmEventListener.triggerEvent('onloadingcomplete');
  }

  private _makeInfoHTML(): void {
    try {
      // Create the layerinformation table
      let infoHTML = '<table class="myTable">';
      let infoHTMLHasValidContent = false;
      // Make first a header with 'Layer' and the dimensions
      infoHTML += '<tr><th>Layer</th>';
      if (this.mapdimensions) {
        for (const mapDim of this.mapdimensions) {
          infoHTML += `<th>${mapDim.name}</th>`;
        }
      }
      infoHTML += '</tr>';
      infoHTML += '<tr><td>Map</tdh>';
      if (this.mapdimensions) {
        for (const mapDim of this.mapdimensions) {
          infoHTML += `<td>${mapDim.currentValue}</td>`;
        }
      }
      infoHTML += '</tr>';
      let l = 0;
      for (l = 0; l < this.getNumLayers(); l += 1) {
        const j = this.getNumLayers() - 1 - l;
        if (this._layers[j].service && this._layers[j].enabled) {
          const layerDimensionsObject = this._layers[j].dimensions;
          if (layerDimensionsObject) {
            let layerTitle = '';
            layerTitle += this._layers[j].title;
            infoHTML += `<tr><td>${layerTitle}</td>`;
            for (const mapDim of this.mapdimensions) {
              let foundDim = false;
              for (const layerDim of layerDimensionsObject) {
                if (layerDim.name.toUpperCase() === mapDim.name.toUpperCase()) {
                  infoHTML += `<td>${layerDim.currentValue}</td>`;
                  foundDim = true;
                  infoHTMLHasValidContent = true;
                  break;
                }
              }
              if (foundDim === false) {
                infoHTML += '<td>-</td>';
              }
            }
            infoHTML += '</tr>';
          }
        }
      }
      infoHTML += '</table>';
      if (infoHTMLHasValidContent === true) {
        this._divDimInfo.style.display = '';
        this._divDimInfo.innerHTML = infoHTML;
        const cx = 8;
        const cy = 8;
        this._divDimInfo.style.width = `${Math.min(
          this._width - parseInt(this._divDimInfo.style.marginLeft, 10) - 210,
          350,
        )}px`;
        this._divDimInfo.style.left = `${cx}px`;
        this._divDimInfo.style.top = `${cy}px`;
      } else {
        this._divDimInfo.style.display = 'none';
      }
    } catch (e) {
      debugLogger(DebugType.Error, `WebMapJS: Exception${e}`);
    }
  }

  private _draw(animationList: AnimationStep[] | string | undefined): void {
    debugLogger(DebugType.Log, `draw:${animationList}`);
    this._drawnBBOX.setBBOX(this._bbox);
    this._drawAndLoad(animationList);
  }

  private _drawReady(): void {
    if (this.needsRedraw) {
      this.needsRedraw = false;
      this.draw(this._animationList);
    }
  }

  private _drawAndLoad(
    inputAnimationList: AnimationStep[] | string | undefined,
  ): void {
    if (this._width < 4 || this._height < 4) {
      this._drawReady();
      return;
    }

    this._wmEventListener.triggerEvent('beforedraw');

    if (
      this.isAnimating === false &&
      inputAnimationList !== undefined &&
      typeof inputAnimationList === 'object' &&
      inputAnimationList.length > 0
    ) {
      // Ensure the time dimension has been loaded for the map before starting animation
      const timeDim = this.getDimension('time');
      if (!timeDim) {
        return;
      }
      const selectedTime = timeDim.currentValue;
      this.isAnimating = true;
      this._wmEventListener.triggerEvent('onstartanimation', this);
      this.currentAnimationStep = this.initialAnimationStep;
      this.animationList = [];
      inputAnimationList.forEach((animationStepWithoutRequests, j) => {
        if (
          selectedTime &&
          animationStepWithoutRequests.value === selectedTime
        ) {
          this.currentAnimationStep = j;
        }
        const animationStep = {
          name: animationStepWithoutRequests.name,
          value: animationStepWithoutRequests.value,
          requests: [] as {
            url: string;
            headers: Headers[];
          }[],
        };
        this.setDimension(
          animationStepWithoutRequests.name,
          animationStepWithoutRequests.value,
          false,
        );
        animationStep.requests = getWMSRequests(this);
        (this.animationList as AnimationStep[]).push(animationStep);
      });
      this.setDimension(
        this.animationList[this.currentAnimationStep].name,
        this.animationList[this.currentAnimationStep].value,
        false,
      );

      this.wmAnimate.loopAnimation();
    }
    this.addLayersToCanvasAndDisplayThem();
  }

  /* Derived mouse methods */
  private _mouseDragStart(x: number, y: number): void {
    if (this.mapMode === 'pan') {
      this._mapPanStart(x, y);
    }
    if (this.mapMode === 'zoom') {
      this._mapZoomStart();
    }
  }

  private _mouseDrag(x: number, y: number): void {
    if (this.mouseDragging === 0) {
      this._mouseDragStart(x, y);
      this.mouseDragging = 1;
    }
    if (this.mapMode === 'pan') {
      this._mapPan(x, y);
    }
    if (this.mapMode === 'zoom') {
      this._mapZoom();
    }
  }

  private _mouseDragEnd(x: number, y: number): void {
    if (this.mouseDragging === 0) {
      return;
    }
    this.mouseDragging = 0;
    if (this.mapMode === 'pan') {
      this._mapPanEnd(x, y);
    }
    if (this.mapMode === 'zoom') {
      this._mapZoomEnd();
    }
    this._wmEventListener.triggerEvent('mapdragend', {
      map: this,
      x: this.mouseUpX,
      y: this.mouseUpY,
    });
  }

  private _mapPan(x: number, y: number): void {
    if (this.mapPanning === 0) {
      return;
    }

    if (
      this._mouseX < 0 ||
      this._mouseY < 0 ||
      this._mouseX > this._mainElement.clientWidth ||
      this._mouseY > this._mainElement.clientHeight
    ) {
      this._mapPanEnd(x, y);
      return;
    }
    const mapPanGeoCoords = getGeoCoordFromPixelCoord(
      { x, y },
      this._updateBBOX,
      this._width,
      this._height,
    );
    const diffX = mapPanGeoCoords.x - this.mapPanStartGeoCoords.x;
    const diffY = mapPanGeoCoords.y - this.mapPanStartGeoCoords.y;

    const newLeft = this._updateBBOX.left - diffX;
    const newBottom = this._updateBBOX.bottom - diffY;
    const newRight = this._updateBBOX.right - diffX;
    const newTop = this._updateBBOX.top - diffY;

    this._updateBBOX.left = newLeft;
    this._updateBBOX.bottom = newBottom;
    this._updateBBOX.right = newRight;
    this._updateBBOX.top = newTop;

    this._updateBoundingBox(this._updateBBOX);
  }

  private _mapPanPercentageEnd(): void {
    this.zoomTo(this._updateBBOX);
    this.draw('mapPanEnd');
  }

  private _mapZoomStart(): void {
    this._baseDiv.style.cursor = 'crosshair';
    this._mapZooming = 1;
  }

  private _showBoundingBox(_bbox?: WMBBOX, _mapbbox?: WMBBOX): void {
    if (isDefined(_bbox)) {
      this._divBoundingBox.bbox = _bbox;
      this._divBoundingBox.style.display = '';
      this._divBoundingBox.displayed = true;
    }
    if (this._divBoundingBox.displayed !== true) {
      return;
    }

    let b = this._bbox;
    if (isDefined(_mapbbox)) {
      b = _mapbbox;
    }
    const coord1 = getPixelCoordFromGeoCoord(
      this,
      { x: this._divBoundingBox.bbox!.left, y: this._divBoundingBox.bbox!.top },
      b,
    );
    const coord2 = getPixelCoordFromGeoCoord(
      this,
      {
        x: this._divBoundingBox.bbox!.right,
        y: this._divBoundingBox.bbox!.bottom,
      },
      b,
    );

    this._divBoundingBox.style.left = `${coord1.x - 1}px`;
    this._divBoundingBox.style.top = `${coord1.y - 2}px`;
    this._divBoundingBox.style.width = `${coord2.x - coord1.x}px`;
    this._divBoundingBox.style.height = `${coord2.y - coord1.y - 1}px`;
  }

  private _hideBoundingBox(): void {
    this._divBoundingBox.style.display = 'none';
    this._divBoundingBox.displayed = false;
  }

  private _animFrameRedraw(): void {
    this._draw(this._animationList);
  }

  constructor(_element: HTMLElement) {
    this._WebMapJSMapVersion = version;
    this._mainElement = _element;
    this._baseDiv = undefined!;
    this._srs = undefined!;

    this._width = 2;
    this._height = 2;
    this._layers = [];
    this.mapdimensions = []; // Array of Dimension;
    this.initialAnimationStep = 0;
    this._baseLayers = [];
    this._numBaseLayers = 0;
    this._divZoomBox = document.createElement('div');
    this._divBoundingBox = document.createElement('div');
    this._divDimInfo = document.createElement('div');
    this._displayLegendInMap = true;
    this._bbox = new WMBBOX(); // Boundingbox that will be used for map loading
    this._resizeBBOX = new WMBBOX();
    this._defaultBBOX = new WMBBOX();
    this._updateBBOX = new WMBBOX(); // Boundingbox to move map without loading anything
    this._drawnBBOX = new WMBBOX(); // Boundingbox that is used when map is drawn

    this.canvasLayerBuffer = undefined!;

    this.isDestroyed = false;

    this._mapHeader = {
      height: 0,
      fill: {
        color: '#EEE',
        opacity: 0.4,
      },
      hover: {
        color: '#017daf',
        opacity: 0.9,
      },
      selected: {
        color: '#017daf',
        opacity: 1.0,
      },
      hoverSelected: {
        color: '#017daf',
        opacity: 1.0,
      },
      cursorSet: false,
      prevCursor: 'default',
      hovering: false,
    };

    this._currentCursor = 'default';
    this._mapIsActivated = false;
    this._isMapHeaderEnabled = false;
    this._showScaleBarInMap = true;
    this._showCursorCoordinates = true;
    this._wmEventListener = new WMListener();

    this._initialized = false;
    this.activeLayer = undefined!;

    this.setTimeOffsetValue = '';
    this.setMessageValue = '';
    this.canvasErrors = [];

    this.resizeWidth = -1;
    this.resizeHeight = -1;
    this.wmAnimate = undefined!;

    this.mouseWheelBusy = 0;
    this.wMFlyToBBox = new WMFlyToBBox(this);

    this._mouseWheelEventBBOXCurrent = new WMBBOX();
    this._mouseWheelEventBBOXNew = new WMBBOX();
    this._mouseX = 0;
    this._mouseY = 0;
    this._mouseDownX = -10000;
    this._mouseDownY = -10000;
    this.mouseUpX = 10000;
    this.mouseUpY = 10000;
    this.mouseDragging = 0;
    this.mouseDownPressed = 0;
    this.mapMode = 'pan'; /* pan,zoom,zoomout,info */

    this.oldMapMode = undefined!;
    this.resizingBBOXCursor = '';
    this.resizingBBOXEnabled = '';
    this.mouseGeoCoordXY = undefined!;
    this.mouseUpdateCoordinates = { x: -1, y: -1 };
    this.mapPanning = 0;
    this.mapPanStartGeoCoords = undefined!;
    this._mapZooming = 0;
    this.holdShiftToScroll = false;

    if (proj4) {
      proj4.defs(WMProj4Defs);
    }
    this.previousMouseButtonState = 'up';
    this.shouldPrefetch = true;

    /* Binds */
    this.setWMTileRendererTileSettings =
      this.setWMTileRendererTileSettings.bind(this);
    this.addWMTileRendererTileSetting =
      this.addWMTileRendererTileSetting.bind(this);

    this._makeComponentId = this._makeComponentId.bind(this);

    this.setMessage = this.setMessage.bind(this);
    this.setTimeOffset = this.setTimeOffset.bind(this);
    this._init = this._init.bind(this);
    this._rebuildMapDimensions = this._rebuildMapDimensions.bind(this);
    this.getLayers = this.getLayers.bind(this);
    this.hasLayer = this.hasLayer.bind(this);
    this.setLayer = this.setLayer.bind(this);
    this._setActive = this._setActive.bind(this);
    this._setActiveLayer = this._setActiveLayer.bind(this);
    this._calculateNumBaseLayers = this._calculateNumBaseLayers.bind(this);
    this.displayLayer = this.displayLayer.bind(this);
    this.removeAllLayers = this.removeAllLayers.bind(this);
    this.deleteLayer = this.deleteLayer.bind(this);
    this.reorderLayers = this.reorderLayers.bind(this);
    this.addLayer = this.addLayer.bind(this);
    this.getActiveLayer = this.getActiveLayer.bind(this);
    this.setProjection = this.setProjection.bind(this);
    this.getBBOX = this.getBBOX.bind(this);
    this.getMapPin = this.getMapPin.bind(this);
    this.getProjection = this.getProjection.bind(this);
    this.getSize = this.getSize.bind(this);
    this._repositionLegendGraphic = this._repositionLegendGraphic.bind(this);
    this.setSize = this.setSize.bind(this);
    this._setSize = this._setSize.bind(this);
    this._abort = this._abort.bind(this);
    this._makeInfoHTML = this._makeInfoHTML.bind(this);
    this.hideMouseCursorProperties = this.hideMouseCursorProperties.bind(this);
    this._display = this._display.bind(this);
    this.draw = this.draw.bind(this);
    this._draw = this._draw.bind(this);
    this._drawAndLoad = this._drawAndLoad.bind(this);
    this._drawReady = this._drawReady.bind(this);
    this._animFrameRedraw = this._animFrameRedraw.bind(this);
    this.addLayersToCanvasAndDisplayThem =
      this.addLayersToCanvasAndDisplayThem.bind(this);
    this._updateBoundingBox = this._updateBoundingBox.bind(this);
    this.redrawBuffer = this.redrawBuffer.bind(this);
    this.setBaseLayers = this.setBaseLayers.bind(this);
    this.getBaseLayers = this.getBaseLayers.bind(this);
    this.getNumLayers = this.getNumLayers.bind(this);
    this.getBaseElement = this.getBaseElement.bind(this);
    this._mouseWheelEvent = this._mouseWheelEvent.bind(this);
    this.destroy = this.destroy.bind(this);
    this._detachEvents = this._detachEvents.bind(this);
    this._attachEvents = this._attachEvents.bind(this);

    this._getMouseCoordinatesForDocument =
      this._getMouseCoordinatesForDocument.bind(this);
    this._getMouseCoordsForElement = this._getMouseCoordsForElement.bind(this);
    this.mouseDown = this.mouseDown.bind(this);

    this._checkInvalidMouseAction = this._checkInvalidMouseAction.bind(this);
    this._updateMouseCursorCoordinates =
      this._updateMouseCursorCoordinates.bind(this);
    this._mouseDownEvent = this._mouseDownEvent.bind(this);
    this._mouseMoveEvent = this._mouseMoveEvent.bind(this);
    this._mouseUpEvent = this._mouseUpEvent.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this._mouseDragStart = this._mouseDragStart.bind(this);
    this._mouseDrag = this._mouseDrag.bind(this);
    this._mouseDragEnd = this._mouseDragEnd.bind(this);
    this._mapPanStart = this._mapPanStart.bind(this);
    this._mapPan = this._mapPan.bind(this);
    this._mapPanEnd = this._mapPanEnd.bind(this);
    this.mapPanPercentage = this.mapPanPercentage.bind(this);
    this._mapPanPercentageEnd = debounce(this._mapPanPercentageEnd, 500);
    this._mapZoomStart = this._mapZoomStart.bind(this);
    this._mapZoom = this._mapZoom.bind(this);
    this._mapZoomEnd = this._mapZoomEnd.bind(this);
    this.setCursor = this.setCursor.bind(this);
    this.getId = this.getId.bind(this);
    this.zoomTo = this.zoomTo.bind(this);
    this._pixelCoordinatesToXY = this._pixelCoordinatesToXY.bind(this);
    this.getProj4 = this.getProj4.bind(this);

    this.calculateBoundingBoxAndZoom =
      this.calculateBoundingBoxAndZoom.bind(this);
    this.addListener = this.addListener.bind(this);
    this.removeListener = this.removeListener.bind(this);
    this.getListener = this.getListener.bind(this);
    this.suspendEvent = this.suspendEvent.bind(this);
    this.resumeEvent = this.resumeEvent.bind(this);
    this.getDimensionList = this.getDimensionList.bind(this);
    this.getDimension = this.getDimension.bind(this);
    this.setDimension = this.setDimension.bind(this);
    this.zoomToLayer = this.zoomToLayer.bind(this);
    this.setBBOX = this.setBBOX.bind(this);
    this.setDefaultBBOX = this.setDefaultBBOX.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.displayLegendInMap = this.displayLegendInMap.bind(this);
    this._showBoundingBox = this._showBoundingBox.bind(this);
    this._hideBoundingBox = this._hideBoundingBox.bind(this);
    this._adagucBeforeDraw = this._adagucBeforeDraw.bind(this);
    this._adagucBeforeCanvasDisplay =
      this._adagucBeforeCanvasDisplay.bind(this);
    this.displayScaleBarInMap = this.displayScaleBarInMap.bind(this);
    this.configureMapDimensions = this.configureMapDimensions.bind(this);
    this.getImageStore = this.getImageStore.bind(this);
    this.clearImageCache = this.clearImageCache.bind(this);
    this.getAlternativeImage = this.getAlternativeImage.bind(this);
    this.getMapMouseCoordinates = this.getMapMouseCoordinates.bind(this);
    this.getLayerByServiceAndName = this.getLayerByServiceAndName.bind(this);
    this.detachWheelEvent = this.detachWheelEvent.bind(this);
    this.attachWheelEvent = this.attachWheelEvent.bind(this);

    this.mapPin = new MapPin(this);

    this._loadingDiv = document.createElement('div');
    this._loadingDiv.className = 'WMJSDivBuffer-loading';
    this._enableAutoPrefetching = true;

    this.getMapImageStore = new WMImageStore(mapImageStoreLength, {
      id: this.getId(),
    });
    this._init();
  }

  _resizeBBOX!: WMBBOX; // Boundingbox that will be used once the map is resized
  _defaultBBOX!: WMBBOX; // The default bbox, used when pressing the home button
  _bbox!: WMBBOX; // Boundingbox that will be used for map loading
  _updateBBOX!: WMBBOX; // Boundingbox to move map without loading anything
  _drawnBBOX!: WMBBOX; // Boundingbox that is used when map is drawn

  // Shared accross WMJSMap routines
  _loadingDiv!: HTMLElement;
  _baseDiv!: HTMLElement;
  _mapZooming!: 0 | 1;

  _initialized: boolean;
  // TODO: (Sander de Snaijer 2020-06-30) make this private after fixing public access of other classs to this property
  _animationList!: AnimationStep[] | string | undefined;

  getLayerByServiceAndName(layerService: string, layerName: string): WMLayer {
    for (let j = 0; j < this._layers.length; j += 1) {
      const layer = this._layers[this._layers.length - j - 1];
      if (layer.name === layerName) {
        if (layer.service === layerService) {
          return layer;
        }
      }
    }
    return undefined!;
  }

  redrawBuffer(): void {
    this.canvasLayerBuffer.display();
    debugLogger(DebugType.Log, 'loadedBBOX.setBBOX(bbox)');
    this._drawnBBOX.setBBOX(this._bbox);
    this.draw();
  }

  addLayersToCanvasAndDisplayThem(): void {
    debugLogger(DebugType.Log, 'loadLayers');

    const screenProjection: WMProjectionWH = {
      srs: this._srs,
      bbox: this._bbox,
      width: this._width,
      height: this._height,
    };

    this.canvasLayerBuffer.initCanvasLayers();
    let currentLayerIndex = 0;
    this._numBaseLayers = 0;

    // Loop through baselayers
    this._baseLayers &&
      this._baseLayers.forEach((baseLayer) => {
        if (
          baseLayer.enabled &&
          baseLayer.keepOnTop === false &&
          baseLayer.type &&
          baseLayer.type !== 'twms'
        ) {
          this._numBaseLayers += 1;
          const requestURL = buildWMSGetMapRequest(
            baseLayer,
            screenProjection,
          )?.url;

          if (requestURL) {
            this.canvasLayerBuffer.addLayerToCanvas(
              currentLayerIndex,
              requestURL,
              { layer: baseLayer },
              baseLayer.opacity!,
              this.getBBOX(),
            );
            currentLayerIndex += 1;
          }
        }
      });

    if (!this.isAnimating && this._enableAutoPrefetching) {
      prefetchImagesForNonAnimation(this);
    }
    // Loop through layers
    this._layers &&
      this._layers.forEach((layer) => {
        if (layer.service && layer.enabled && layer.isConfigured) {
          const requestURL = buildWMSGetMapRequest(
            layer,
            screenProjection,
          )?.url;
          if (requestURL) {
            layer.setCurrentRequestedGetMapURL(requestURL);
            this.canvasLayerBuffer.addLayerToCanvas(
              currentLayerIndex,
              requestURL,
              { layer },
              layer.opacity!,
              this.getBBOX(),
            );
            currentLayerIndex += 1;
          }
        }
      });

    // Loop through overalys
    this._baseLayers &&
      this._baseLayers.forEach((baseLayer) => {
        if (baseLayer.enabled && baseLayer.keepOnTop === true) {
          const requestURL = buildWMSGetMapRequest(
            baseLayer,
            screenProjection,
          )?.url;
          if (requestURL) {
            this.canvasLayerBuffer.addLayerToCanvas(
              currentLayerIndex,
              requestURL,
              { layer: baseLayer },
              baseLayer.opacity!,
              this.getBBOX(),
            );
            currentLayerIndex += 1;
          }
        }
      });

    this.canvasLayerBuffer.display();
    this._wmEventListener.triggerEvent('onmapready', this);
  }

  _mouseDownEvent(e: MouseEvent): void {
    this.previousMouseButtonState = 'down';
    const mouseCoords = this._getMouseCoordinatesForDocument(e);
    if (this._mapHeader.cursorSet && mouseCoords.y < this._mapHeader.height) {
      return;
    }

    this.mouseDown(mouseCoords.x, mouseCoords.y, e);
  }

  _mouseMoveEvent(e: MouseEvent): void {
    /* Small state machine to detect mouse changes outside of the base element */
    const currentMouseButtonState = e.buttons === 0 ? 'up' : 'down';
    if (
      this.previousMouseButtonState === 'down' &&
      currentMouseButtonState === 'up'
    ) {
      this._mouseUpEvent(e);
    }

    if (this.previousMouseButtonState !== currentMouseButtonState) {
      this.previousMouseButtonState = currentMouseButtonState;
    }

    const mouseCoords = this._getMouseCoordinatesForDocument(e);
    if (
      this.mouseDownPressed === 0 &&
      mouseCoords.y >= 0 &&
      mouseCoords.y < this._mapHeader.height &&
      mouseCoords.x >= 0 &&
      mouseCoords.x <= this._width
    ) {
      if (this._mapHeader.cursorSet === false) {
        this._mapHeader.cursorSet = true;
        this._mapHeader.prevCursor = this._currentCursor;
        this._mapHeader.hovering = true;
        this.setCursor('pointer');
        this.draw('mouseMoveEvent');
      }
    } else if (this._mapHeader.cursorSet === true) {
      this._mapHeader.cursorSet = false;
      this._mapHeader.hovering = false;
      this.setCursor(this._mapHeader.prevCursor);
      this.draw('mouseMoveEvent');
    }
    this.mouseMove(mouseCoords.x, mouseCoords.y, e);
  }

  _mouseUpEvent(e: MouseEvent): void {
    preventdefaultEvent(e);
    this.previousMouseButtonState = 'up';
    const mouseCoords = this._getMouseCoordinatesForDocument(e);
    this.mouseUp(mouseCoords.x, mouseCoords.y, e);
  }

  // The following methods are Used within WebMap but are not implemented by the IWMJSMap interface

  _display(): void {
    if (!this.canvasLayerBuffer) {
      return;
    }
    this.canvasLayerBuffer.display();
    this._drawnBBOX.setBBOX(this._bbox);
  }

  _updateBoundingBox(_mapbbox: WMBBOX, triggerEvent = true): void {
    if (!this.canvasLayerBuffer) {
      return;
    }
    let mapbbox = this._bbox;
    if (isDefined(_mapbbox)) {
      mapbbox = _mapbbox;
    }
    this._updateBBOX.setBBOX(mapbbox);
    this.canvasLayerBuffer.setBBOX(this._updateBBOX);
    this._drawnBBOX.setBBOX(mapbbox);

    this._showBoundingBox(this._divBoundingBox.bbox, this._updateBBOX);

    this.mapPin.repositionMapPin(_mapbbox);

    if (triggerEvent) {
      this._wmEventListener.triggerEvent('onupdatebbox', this._updateBBOX);
    }
  }

  _mouseWheelEvent(event: WheelEvent): void {
    if (this.holdShiftToScroll && !event.shiftKey) {
      this.setMessageValue = translateKeyOutsideComponents(
        this.t,
        'hold-shift-to-zoom',
      );
      this.draw();
      // Clear text after 500ms
      setTimeout(() => {
        this.setMessageValue = '';
        this.draw();
      }, 500);
      return;
    }
    preventdefaultEvent(event);
    if (this.mouseWheelBusy === 1) {
      return;
    }
    const { x, y } = this._getMouseCoordinatesForDocument(event);
    this._setMouseCoordinates(x, y);

    const delta = -event.deltaY;
    this.mouseWheelBusy = 1;
    const w = this._updateBBOX.right - this._updateBBOX.left;
    const h = this._updateBBOX.bottom - this._updateBBOX.top;
    const geoMouseXY = getGeoCoordFromPixelCoord(
      { x: this._mouseX, y: this._mouseY },
      this._drawnBBOX,
      this._width,
      this._height,
    );
    const nx = (geoMouseXY.x - this._updateBBOX.left) / w; // Normalized to 0-1
    const ny = (geoMouseXY.y - this._updateBBOX.top) / h;

    let zoomW;
    let zoomH;
    if (delta < 0) {
      zoomW = w * -0.25;
      zoomH = h * -0.25;
    } else {
      zoomW = w * 0.2;
      zoomH = h * 0.2;
    }
    let newLeft = this._updateBBOX.left + zoomW;
    let newTop = this._updateBBOX.top + zoomH;
    let newRight = this._updateBBOX.right - zoomW;
    let newBottom = this._updateBBOX.bottom - zoomH;

    const newW = newRight - newLeft;
    const newH = newBottom - newTop;

    const newX = nx * newW + newLeft;
    const newY = ny * newH + newTop;

    const panX = newX - geoMouseXY.x;

    const panY = newY - geoMouseXY.y;
    newLeft -= panX;
    newRight -= panX;
    newTop -= panY;
    newBottom -= panY;
    this._mouseWheelEventBBOXCurrent.copy(this._updateBBOX);
    this._mouseWheelEventBBOXNew.left = newLeft;
    this._mouseWheelEventBBOXNew.bottom = newBottom;
    this._mouseWheelEventBBOXNew.right = newRight;
    this._mouseWheelEventBBOXNew.top = newTop;
    this.mouseWheelBusy = 0;

    if (Math.abs(delta) < 4) {
      this.wMFlyToBBox.flyZoomToBBOXStartZoomThrottled(
        this._mouseWheelEventBBOXCurrent,
        this._mouseWheelEventBBOXNew,
      );
    } else {
      this.wMFlyToBBox.flyZoomToBBOXStartZoom(
        this._mouseWheelEventBBOXCurrent,
        this._mouseWheelEventBBOXNew,
      );
    }
  }

  public detachWheelEvent(): void {
    this._baseDiv.removeEventListener('wheel', this._mouseWheelEvent);
  }

  public attachWheelEvent(): void {
    this._baseDiv.addEventListener('wheel', this._mouseWheelEvent);
  }

  _detachEvents(): void {
    this._baseDiv.removeEventListener('mousedown', this._mouseDownEvent);
    this._baseDiv.removeEventListener('mouseup', this._mouseUpEvent);
    this._baseDiv.removeEventListener('mousemove', this._mouseMoveEvent);
    this._baseDiv.removeEventListener('contextmenu', this._onContextMenu);
    this._baseDiv.removeEventListener('wheel', this._mouseWheelEvent);
  }

  _attachEvents(): void {
    this._baseDiv.addEventListener('mousedown', this._mouseDownEvent);
    this._baseDiv.addEventListener('mouseup', this._mouseUpEvent);
    this._baseDiv.addEventListener('mousemove', this._mouseMoveEvent);
    this._baseDiv.addEventListener('contextmenu', this._onContextMenu);
    this._baseDiv.addEventListener('wheel', this._mouseWheelEvent);

    this.mapMode = 'pan';
    this._baseDiv.style.cursor = 'default';
  }

  // eslint-disable-next-line class-methods-use-this
  _onContextMenu = (): boolean => {
    return false;
  };

  /* Map zoom and pan methodss */
  _mapPanStart(x: number, y: number): void {
    this.zoomTo(this._updateBBOX);
    this.wMFlyToBBox.flyZoomToBBOXStop();

    this._baseDiv.style.cursor = 'move';

    this.mapPanning = 1;
    debugLogger(DebugType.Log, 'updateBBOX.setBBOX(drawnBBOX)');
    this._bbox.setBBOX(this._drawnBBOX);
    this._updateBBOX.setBBOX(this._drawnBBOX);
    this.mapPanStartGeoCoords = getGeoCoordFromPixelCoord(
      { x, y },
      this._bbox,
      this._width,
      this._height,
    );
  }

  _mapPanEnd(x: number, y: number): void {
    this._baseDiv.style.cursor = 'default';
    if (this.mapPanning === 0) {
      return;
    }
    this.mapPanning = 0;

    const mapPanGeoCoords = getGeoCoordFromPixelCoord(
      { x, y },
      this._drawnBBOX,
      this._width,
      this._height,
    );
    const diffX = mapPanGeoCoords.x - this.mapPanStartGeoCoords.x;
    const diffY = mapPanGeoCoords.y - this.mapPanStartGeoCoords.y;
    this._updateBBOX.left = this._drawnBBOX.left - diffX;
    this._updateBBOX.bottom = this._drawnBBOX.bottom - diffY;
    this._updateBBOX.right = this._drawnBBOX.right - diffX;
    this._updateBBOX.top = this._drawnBBOX.top - diffY;
    this._updateBoundingBox(this._updateBBOX);
    this.zoomTo(this._updateBBOX);
    this.draw('mapPanEnd');
  }

  _mapZoom(): void {
    if (this._mapZooming === 0) {
      return;
    }
    const x = this._mouseX - this._mouseDownX;
    const y = this._mouseY - this._mouseDownY;

    if (x < 0 && y < 0) {
      this._baseDiv.style.cursor = 'not-allowed';
    } else {
      this._baseDiv.style.cursor = 'crosshair';
    }
    let w = x;
    let h = y;
    this._divZoomBox.style.display = '';
    if (w < 0) {
      w = -w;
      this._divZoomBox.style.left = `${this._mouseX}px`;
    } else {
      this._divZoomBox.style.left = `${this._mouseDownX}px`;
    }
    if (h < 0) {
      h = -h;
      this._divZoomBox.style.top = `${this._mouseY}px`;
    } else {
      this._divZoomBox.style.top = `${this._mouseDownY}px`;
    }
    this._divZoomBox.style.width = `${w}px`;
    this._divZoomBox.style.height = `${h}px`;
  }

  _mapZoomEnd(): void {
    const x = this.mouseUpX - this._mouseDownX;
    const y = this.mouseUpY - this._mouseDownY;
    this._baseDiv.style.cursor = 'default';
    if (this._mapZooming === 0) {
      return;
    }
    this._mapZooming = 0;
    this._divZoomBox.style.display = 'none';
    if (x < 0 && y < 0) {
      return;
    }
    const zoomBBOXPixels = new WMBBOX();

    if (x < 0) {
      zoomBBOXPixels.left = this._mouseDownX + x;
      zoomBBOXPixels.right = this._mouseDownX;
    } else {
      zoomBBOXPixels.left = this._mouseDownX;
      zoomBBOXPixels.right = this._mouseDownX + x;
    }
    if (y < 0) {
      zoomBBOXPixels.top = this._mouseDownY + y;
      zoomBBOXPixels.bottom = this._mouseDownY;
    } else {
      zoomBBOXPixels.top = this._mouseDownY;
      zoomBBOXPixels.bottom = this._mouseDownY + y;
    }
    const p1 = this._pixelCoordinatesToXY({
      x: zoomBBOXPixels.left,
      y: zoomBBOXPixels.bottom,
    });
    const p2 = this._pixelCoordinatesToXY({
      x: zoomBBOXPixels.right,
      y: zoomBBOXPixels.top,
    });

    zoomBBOXPixels.left = p1.x;
    zoomBBOXPixels.bottom = p1.y;
    zoomBBOXPixels.right = p2.x;
    zoomBBOXPixels.top = p2.y;
    this.zoomTo(zoomBBOXPixels);
    this.draw('mapZoomEnd');
  }

  _getMouseCoordinatesForDocument(e: MouseEvent | TouchEvent): {
    x: number;
    y: number;
  } {
    if (isDefined((e as TouchEvent).changedTouches)) {
      return {
        x: (e as TouchEvent).changedTouches[0].screenX,
        y: (e as TouchEvent).changedTouches[0].screenY,
      };
    }

    const parentOffset =
      this._mainElement.parentElement!.getBoundingClientRect();

    let { pageX } = e as MouseEvent;
    let { pageY } = e as MouseEvent;
    if (pageX === undefined) {
      pageX = getMouseXCoordinate(e as MouseEvent);
    }
    if (pageY === undefined) {
      pageY = getMouseYCoordinate(e as MouseEvent);
    }

    const relX = pageX - parentOffset.left;
    const relY = pageY - parentOffset.top;
    return { x: relX, y: relY };
  }

  _setMouseCoordinates = (x: number, y: number): void => {
    this._mouseX = x;
    this._mouseY = y;
  };

  _getMouseCoordsForElement(e: MouseEvent | TouchEvent): {
    x: number;
    y: number;
  } {
    if (isDefined((e as TouchEvent).changedTouches)) {
      return {
        x: (e as TouchEvent).changedTouches[0].screenX,
        y: (e as TouchEvent).changedTouches[0].screenY,
      };
    }
    const parentOffset =
      this._mainElement.parentElement!.getBoundingClientRect();

    let { pageX } = e as MouseEvent;
    let { pageY } = e as MouseEvent;
    if (pageX === undefined) {
      pageX = getMouseXCoordinate(e as MouseEvent);
    }
    if (pageY === undefined) {
      pageY = getMouseYCoordinate(e as MouseEvent);
    }
    const relX = pageX - parentOffset.left;
    const relY = pageY - parentOffset.top;
    return { x: relX, y: relY };
  }

  // The following are implemented from the IWMJSMap interface:
  public prefetchLayerImagesForTimeMemo = new WMMemo();
  public shouldPrefetch: boolean;
  public mapPin!: MapPin;
  public mapdimensions!: WMJSDimension[];
  public timestepInMinutes: number | undefined;
  public showLayerInfo!: boolean;
  public stopAnimating!: () => void;
  public isAnimating!: boolean;
  public animationList!: AnimationStep[] | string | undefined; // TODO
  public animationDelay!: number;
  public currentAnimationStep!: number;
  public initialAnimationStep!: number;
  public setAnimationDelay!: (delay: number) => void;
  public isDestroyed: boolean;
  public getMapImageStore: WMImageStore;
  public holdShiftToScroll: boolean;
  public t: TFunction | undefined;
  public getMapMouseCoordinates(): IWMJSMapMouseCoordinates {
    return {
      mouseX: this._mouseX,
      mouseDownX: this._mouseDownX,
      mouseY: this._mouseY,
      mouseDownY: this._mouseDownY,
    };
  }
  public setWMTileRendererTileSettings(
    tileRenderSettings: TileServerSettings,
  ): void {
    Object.entries(tileRenderSettings).forEach((tileRenderSetting) => {
      if (tileRenderSetting.length === 2) {
        const key = tileRenderSetting[0];
        const value = tileRenderSetting[1];
        this.tileRenderSettings.set(key, value);
      }
    });
  }

  public addWMTileRendererTileSetting(
    tileRenderSetting: TileServerDefinition,
  ): void {
    const tileRenderSettingEntry = Object.entries(tileRenderSetting)[0];
    if (tileRenderSettingEntry.length === 2) {
      const key = tileRenderSettingEntry[0];
      const value = tileRenderSettingEntry[1];
      this.tileRenderSettings.set(key, value);
    }
  }

  public setMessage(message: string): void {
    if (!message || message === '') {
      this.setMessageValue = '';
    } else {
      this.setMessageValue = message;
    }
  }

  public setTimeOffset(message: string): void {
    if (!message || message === '') {
      this.setTimeOffsetValue = '';
    } else {
      this.setTimeOffsetValue = message;
    }
  }

  public getLayers(reverseOrder = true): WMLayer[] {
    /* Provide layers in reverse order */
    const returnlayers: WMLayer[] = [];
    for (let j = 0; j < this._layers.length; j += 1) {
      const layer = reverseOrder
        ? this._layers[this._layers.length - j - 1]
        : this._layers[j];
      returnlayers.push(layer);
    }
    return returnlayers;
  }

  public hasLayer(layer: WMLayer): boolean {
    return this._layers.includes(layer) || this._baseLayers.includes(layer);
  }

  public setLayer(layer: WMLayer): Promise<IWMJSMap> {
    return this.addLayer(layer);
  }

  public displayLayer(layer: WMLayer, enabled: boolean): void {
    layer.enabled = enabled;
    this._calculateNumBaseLayers();
    this._rebuildMapDimensions();
  }

  public removeAllLayers(): void {
    for (const layer of this._layers) {
      layer.setAutoUpdate(false);
    }
    this._layers.length = 0;
    this.mapdimensions.length = 0;
    this._wmEventListener.triggerEvent('onlayeradd');
  }

  public deleteLayer(layerToDelete: WMLayer): void {
    if (this._layers.length <= 0) {
      return;
    }
    layerToDelete.setAutoUpdate(false);
    const layerIndex = getLayerIndex(layerToDelete, this._layers);
    if (layerIndex >= 0) {
      // move everything up with id's higher than this layer
      for (let j = layerIndex; j < this._layers.length - 1; j += 1) {
        this._layers[j] = this._layers[j + 1];
      }
      this._layers.length -= 1;

      this.activeLayer = undefined!;
      if (layerIndex >= 0 && layerIndex < this._layers.length) {
        this._rebuildMapDimensions();
        this._setActiveLayer(this._layers[layerIndex]);
      } else if (this._layers.length > 0) {
        this._rebuildMapDimensions();
        this._setActiveLayer(this._layers[this._layers.length - 1]);
      }
    }
    this._wmEventListener.triggerEvent('onlayerchange');
    this._rebuildMapDimensions();
  }

  /**
   * Reorder layers according to an index array with indices on how the layers should be reordered.
   */
  public reorderLayers(order: number[]): boolean {
    // The length should be the same
    if (order.length !== this._layers.length) {
      return false;
    }
    // There should be no duplicate indices
    if (order.length !== new Set(order).size) {
      return false;
    }
    const origList = this._layers.slice();
    let wasReordered = false;
    order.forEach((value, index) => {
      if (value >= 0 || value < this._layers.length) {
        const indexA = this._layers.length - value - 1;
        const indexB = this._layers.length - index - 1;
        if (indexA !== indexB) {
          this._layers[indexA] = origList[indexB];
          wasReordered = true;
        }
      }
    });
    return wasReordered;
  }

  public configureMapDimensions(layer: WMLayer): void {
    if (this.isDestroyed) {
      return;
    }
    layer.dimensions.forEach((dimension): void => {
      const mapDim = this.getDimension(dimension.name);
      if (mapDim?.currentValue !== undefined && dimension.linked) {
        dimension.setClosestValue(mapDim.currentValue);
      }
    });
    this._rebuildMapDimensions();
  }

  public async addLayer(layer: WMLayer): Promise<IWMJSMap> {
    if (!layer) {
      throw new Error('undefined layer');
    }
    if (!layer.constructor) {
      throw new Error('layer has no constructor');
    }
    if (this.isDestroyed) {
      throw new Error('Map is destroyed');
    }

    // Assign a reference in the layer to this map
    layer.parentMap = this;

    // Add this layer
    this._layers.push(layer);
    // Parse info
    await layer.parseLayer();
    this.configureMapDimensions(layer);
    this._wmEventListener.triggerEvent('onlayeradd');
    return this;
  }

  public getActiveLayer(): WMLayer {
    return this.activeLayer;
  }

  /**
   * setProjection
   * Set the projection of the current webmap object
   *_srs also accepts a projectionProperty object
   */
  public setProjection(
    _srs: string | { srs: string; bbox: Bbox },
    _bbox?: Bbox,
  ): void {
    if (!_srs) {
      _srs = PROJECTION.EPSG_4326.value;
    }
    if (typeof _srs === 'object') {
      _bbox = new WMBBOX(_srs.bbox);
      _srs = _srs.srs;
    }
    if (!_srs) {
      _srs = PROJECTION.EPSG_4326.value;
    }

    if (this._srs !== _srs) {
      this._defaultBBOX.setBBOX(_bbox);
    }

    this._srs = _srs;

    this.setBBOX(_bbox!);
    this._updateMouseCursorCoordinates(undefined!);
    this._wmEventListener.triggerEvent('onsetprojection', [
      this._srs,
      this._bbox,
    ]);
  }

  public getDrawBBOX(): WMBBOX {
    return this._updateBBOX;
  }

  public getBBOX(): WMBBOX {
    return this._bbox;
  }

  public getProjection(): WMProjectionWH {
    return {
      srs: this._srs,
      bbox: this._resizeBBOX,
      width: this._width,
      height: this._height,
    };
  }

  public getSize(): { width: number; height: number } {
    return { width: this._width, height: this._height };
  }

  public getMapPin(): MapPin {
    return this.mapPin;
  }

  private _repositionLegendGraphic(): void {
    if (!this._displayLegendInMap) {
      this.draw();
    }
  }

  public setSize(w: number, h: number): void {
    debugLogger(DebugType.Log, 'setSize', w, h, this._initialized);
    if (
      w < WMSJSMAP_MINIMUM_MAP_WIDTH ||
      h < WMSJSMAP_MINIMUM_MAP_HEIGHT ||
      this._initialized === false
    ) {
      return;
    }
    this.resizeWidth = w;
    this.resizeHeight = h;

    this._setSize(this.resizeWidth, this.resizeHeight);
    this.draw('setSize');
  }

  public hideMouseCursorProperties(): void {
    this._showCursorCoordinates = false;
  }

  public displayScaleBarInMap(display: boolean): void {
    this._showScaleBarInMap = display;
  }

  public draw(animationList?: AnimationStep[] | string | undefined): void {
    if (this.isDestroyed) {
      return;
    }
    if (typeof animationList === 'object') {
      if (JSON.stringify(animationList) !== '{}') {
        this._animationList = animationList;
      }
    }
    if (this.isAnimating) {
      debugLogger(DebugType.Log, `ANIMATING: Skipping draw:${animationList}`);
      return;
    }
    this._draw(this._animationList);
  }

  public getAlternativeImage(
    imageUrl: string,
    bbox: CanvasBBOX,
    filterBad = false,
  ): CanvasGeoLayer[] {
    return this.canvasLayerBuffer.getAlternativeImage(
      imageUrl,
      bbox,
      filterBad,
    );
  }

  public setBaseLayers(layer: WMLayer[]): void {
    if (layer) {
      this._numBaseLayers = 0;
      this._baseLayers = layer;
      for (const baseLayer of this._baseLayers) {
        baseLayer.parentMap = this;

        if (baseLayer.keepOnTop !== true) {
          this._numBaseLayers += 1;
        }
      }
      this._wmEventListener.triggerEvent('onlayerchange');
    } else {
      this._baseLayers = undefined!;
    }
  }

  public getBaseLayers(): WMLayer[] {
    return this._baseLayers;
  }

  public getNumLayers(): number {
    return this._layers.length;
  }

  public getBaseElement(): HTMLElement {
    return this._baseDiv;
  }

  public destroy(): void {
    if (!this._initialized) {
      return;
    }
    this.stopAnimating();
    for (let i = this._layers.length - 1; i >= 0; i -= 1) {
      this._layers[i].setAutoUpdate(false);
    }
    this._detachEvents();

    this._wmEventListener.destroy();
    this._wmEventListener = null!;

    this.getMapImageStore.removeEventCallback(this.internalMapId);
    bgMapImageStore.removeEventCallback(this.internalMapId);
    getLegendImageStore().removeEventCallback(this.internalMapId);

    this.canvasLayerBuffer.destroy();
    this.canvasLayerBuffer = null!;

    this.internalMapId = '';

    this._mainElement.removeChild(this._baseDiv);
    this._baseDiv.innerHTML = '';
    this._divZoomBox.innerHTML = '';
    this._divBoundingBox.innerHTML = '';
    this._divDimInfo.innerHTML = '';
    this._loadingDiv.innerHTML = '';

    this._baseDiv = undefined!;
    this._divZoomBox = undefined!;
    this._divBoundingBox = undefined!;
    this._divDimInfo = undefined!;
    this._loadingDiv = undefined!;

    this.isDestroyed = true;
    this._initialized = false;
  }

  public mouseDown(
    mouseCoordX: number,
    mouseCoordY: number,
    event?: MouseEvent,
  ): void {
    let shiftKey = false;
    if (event) {
      if (event.shiftKey === true) {
        shiftKey = true;
      }
    }
    this._mouseDownX = mouseCoordX;
    this._mouseDownY = mouseCoordY;
    this.mouseDownPressed = 1;
    if (this.mouseDragging === 0 && event !== null) {
      if (
        this._checkInvalidMouseAction(this._mouseDownX, this._mouseDownY) === 0
      ) {
        const triggerResults = this._wmEventListener.triggerEvent(
          'beforemousedown',
          {
            mouseX: mouseCoordX,
            mouseY: mouseCoordY,
            mouseDown: true,
            event,
            leftButton: detectLeftButton(event!),
            rightButton: detectRightButton(event!),
            shiftKey,
          },
        );
        for (const triggerResult of triggerResults) {
          if (triggerResult === false) {
            return;
          }
        }
      }
    }
    if (!shiftKey) {
      if (this.oldMapMode !== undefined) {
        this.mapMode = this.oldMapMode;
        this.oldMapMode = undefined!;
      }
    } else {
      if (this.oldMapMode === undefined) {
        this.oldMapMode = this.mapMode;
      }
      this.mapMode = 'zoom';
    }
    this._wmEventListener.triggerEvent('mousedown', {
      map: this,
      x: this._mouseDownX,
      y: this._mouseDownY,
    });

    if (this.mapMode === 'info' || this.mapMode === 'point') {
      const mouseDownLatLon = getLatLongFromPixelCoord(this, {
        x: this._mouseDownX,
        y: this._mouseDownY,
      });
      const geoCoords = getGeoCoordFromPixelCoord(
        {
          x: this._mouseDownX,
          y: this._mouseDownY,
        },
        this._bbox,
        this._width,
        this._height,
      );
      this._wmEventListener.triggerEvent('onsetmappin', {
        map: this,
        lon: mouseDownLatLon.x,
        lat: mouseDownLatLon.y,
        screenOffsetX: this._mouseDownX,
        screenOffsetY: this._mouseDownY,
        projectionX: geoCoords.x,
        projectionY: geoCoords.y,
        srs: this._srs,
      });
      this.mapPin.setMapPin(this._mouseDownX, this._mouseDownY);
    }
  }

  public mouseMove(
    mouseCoordX: number,
    mouseCoordY: number,
    event?: MouseEvent,
  ): void {
    this._setMouseCoordinates(mouseCoordX, mouseCoordY);
    // TODO: add tests to ensure mouse cursor coordinates are still updated while in drawing mode. See: https://gitlab.com/opengeoweb/opengeoweb/-/issues/1319
    this._updateMouseCursorCoordinates({ x: this._mouseX, y: this._mouseY });

    if (this.mouseDragging === 0 && event !== null) {
      const triggerResults = this._wmEventListener.triggerEvent(
        'beforemousemove',
        {
          mouseX: this._mouseX,
          mouseY: this._mouseY,
          mouseDown: this.mouseDownPressed === 1,
          leftButton: detectLeftButton(event!),
          rightButton: detectRightButton(event!),
        },
      );
      for (const triggerResult of triggerResults) {
        if (triggerResult === false) {
          return;
        }
      }
    }

    if (this._divBoundingBox.displayed === true && this.mapPanning === 0) {
      let tlpx = getPixelCoordFromGeoCoord(this, {
        x: this._divBoundingBox.bbox!.left,
        y: this._divBoundingBox.bbox!.top,
      });
      let brpx = getPixelCoordFromGeoCoord(this, {
        x: this._divBoundingBox.bbox!.right,
        y: this._divBoundingBox.bbox!.bottom,
      });

      let foundBBOXRib = false;

      if (this.mouseDownPressed === 0) {
        if (this.resizingBBOXEnabled === '') {
          this.resizingBBOXCursor = getComputedStyle(this._baseDiv).cursor;
        }
        // Find left rib
        if (
          Math.abs(this._mouseX - tlpx.x) < 6 &&
          this._mouseY > tlpx.y &&
          this._mouseY < brpx.y
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'col-resize';
          this.resizingBBOXEnabled = 'left';
        }
        // Find top rib
        if (
          Math.abs(this._mouseY - tlpx.y) < 6 &&
          this._mouseX > tlpx.x &&
          this._mouseX < brpx.x
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'row-resize';
          this.resizingBBOXEnabled = 'top';
        }
        // Find right rib
        if (
          Math.abs(this._mouseX - brpx.x) < 6 &&
          this._mouseY > tlpx.y &&
          this._mouseY < brpx.y
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'col-resize';
          this.resizingBBOXEnabled = 'right';
        }
        // Find bottom rib
        if (
          Math.abs(this._mouseY - brpx.y) < 6 &&
          this._mouseX > tlpx.x &&
          this._mouseX < brpx.x
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'row-resize';
          this.resizingBBOXEnabled = 'bottom';
        }
        // Find topleft corner
        if (
          Math.abs(this._mouseX - tlpx.x) < 6 &&
          Math.abs(this._mouseY - tlpx.y) < 6
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'nw-resize';
          this.resizingBBOXEnabled = 'topleft';
        }
        // Find topright corner
        if (
          Math.abs(this._mouseX - brpx.x) < 6 &&
          Math.abs(this._mouseY - tlpx.y) < 6
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'ne-resize';
          this.resizingBBOXEnabled = 'topright';
        }
        // Find bottomleft corner
        if (
          Math.abs(this._mouseX - tlpx.x) < 6 &&
          Math.abs(this._mouseY - brpx.y) < 6
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'sw-resize';
          this.resizingBBOXEnabled = 'bottomleft';
        }
        // Find bottomright corner
        if (
          Math.abs(this._mouseX - brpx.x) < 6 &&
          Math.abs(this._mouseY - brpx.y) < 6
        ) {
          foundBBOXRib = true;
          this._baseDiv.style.cursor = 'se-resize';
          this.resizingBBOXEnabled = 'bottomright';
        }
      }

      if (
        foundBBOXRib === true ||
        (this.resizingBBOXEnabled !== '' && this.mouseDownPressed === 1)
      ) {
        if (this.mouseDownPressed === 1) {
          if (this.resizingBBOXEnabled === 'left') {
            tlpx.x = this._mouseX;
          }
          if (this.resizingBBOXEnabled === 'top') {
            tlpx.y = this._mouseY;
          }
          if (this.resizingBBOXEnabled === 'right') {
            brpx.x = this._mouseX;
          }
          if (this.resizingBBOXEnabled === 'bottom') {
            brpx.y = this._mouseY;
          }
          if (this.resizingBBOXEnabled === 'topleft') {
            tlpx.x = this._mouseX;
            tlpx.y = this._mouseY;
          }
          if (this.resizingBBOXEnabled === 'topright') {
            brpx.x = this._mouseX;
            tlpx.y = this._mouseY;
          }
          if (this.resizingBBOXEnabled === 'bottomleft') {
            tlpx.x = this._mouseX;
            brpx.y = this._mouseY;
          }
          if (this.resizingBBOXEnabled === 'bottomright') {
            brpx.x = this._mouseX;
            brpx.y = this._mouseY;
          }

          tlpx = getGeoCoordFromPixelCoord(
            tlpx,
            this._bbox,
            this._width,
            this._height,
          );
          brpx = getGeoCoordFromPixelCoord(
            brpx,
            this._bbox,
            this._width,
            this._height,
          );

          this._divBoundingBox.bbox!.left = tlpx.x;
          this._divBoundingBox.bbox!.top = tlpx.y;
          this._divBoundingBox.bbox!.right = brpx.x;
          this._divBoundingBox.bbox!.bottom = brpx.y;
          this._showBoundingBox(this._divBoundingBox.bbox);
          const data = { map: this, bbox: this._divBoundingBox.bbox };
          this._wmEventListener.triggerEvent('bboxchanged', data);
        }
        return;
      }
      this.resizingBBOXEnabled = '';
      this._baseDiv.style.cursor = this.resizingBBOXCursor;
    }

    if (this._checkInvalidMouseAction(this._mouseX, this._mouseY) === -1) {
      try {
        this._wmEventListener.triggerEvent('onmousemove', [
          undefined,
          undefined,
        ]);
        this._updateMouseCursorCoordinates(undefined!);
      } catch (e) {
        debugLogger(DebugType.Error, `WebMapJS: ${e}`);
      }
      this.mouseUpX = this._mouseX;
      this.mouseUpY = this._mouseY;
      if (this.mapPanning === 0) {
        return;
      }
      if (this.mouseDownPressed === 1) {
        if (this.mapMode === 'zoomout') {
          this.zoomOut();
        }
      }
      this.mouseDownPressed = 0;
      if (this.mouseDragging === 1) {
        this._mouseDragEnd(this.mouseUpX, this.mouseUpY);
      }
      return;
    }

    if (this.mouseDownPressed === 1) {
      if (
        !(
          Math.abs(this._mouseDownX - this._mouseX) < 3 &&
          Math.abs(this._mouseDownY - this._mouseY) < 3
        )
      ) {
        this._mouseDrag(this._mouseX, this._mouseY);
      }
    }
    this._wmEventListener.triggerEvent('onmousemove', [
      this._mouseX,
      this._mouseY,
    ]);
  }

  public mouseUp(
    mouseCoordX: number,
    mouseCoordY: number,
    e: MouseEvent,
  ): void {
    this.mouseUpX = mouseCoordX;
    this.mouseUpY = mouseCoordY;
    if (this.mouseDragging === 0) {
      if (this._checkInvalidMouseAction(this.mouseUpX, this.mouseUpY) === 0) {
        const triggerResults = this._wmEventListener.triggerEvent(
          'beforemouseup',
          {
            mouseX: mouseCoordX,
            mouseY: mouseCoordY,
            mouseDown: false,
            event: e,
          },
        );
        for (const triggerResult of triggerResults) {
          if (triggerResult === false) {
            this.mouseDownPressed = 0;
            return;
          }
        }
      }
    }
    if (this.mouseDownPressed === 1) {
      if (this.mapMode === 'zoomout') {
        this.zoomOut();
      }
      if (this.mouseDragging === 0) {
        if (
          Math.abs(this._mouseDownX - this.mouseUpX) < 3 &&
          Math.abs(this._mouseDownY - this.mouseUpY) < 3
        ) {
          if (isDefined(e)) {
            this._wmEventListener.triggerEvent('mouseclicked', {
              map: this,
              x: this.mouseUpX,
              y: this.mouseUpY,
              shiftKeyPressed: e.shiftKey === true,
            } as WMJSMapMouseClickEvent);
          }

          this.mapPin.setMapPin(this._mouseDownX, this._mouseDownY);
          const mouseDownLatLon = getLatLongFromPixelCoord(this, {
            x: this._mouseDownX,
            y: this._mouseDownY,
          });
          this._wmEventListener.triggerEvent('onsetmappin', {
            map: this,
            lon: mouseDownLatLon.x,
            lat: mouseDownLatLon.y,
          });
        }
      }
      this._wmEventListener.triggerEvent('mouseup', {
        map: this,
        x: this.mouseUpX,
        y: this.mouseUpY,
      });
    }
    this.mouseDownPressed = 0;
    if (this.mouseDragging === 1) {
      this._mouseDragEnd(this.mouseUpX, this.mouseUpY);
    }
  }

  public mapPanPercentage(
    percentageDiffX: number,
    percentageDiffY: number,
  ): void {
    const { left, bottom, right, top } = this._updateBBOX;

    if (percentageDiffX !== 0) {
      const diffX = percentageDiffX * Math.abs(left - right);

      this._updateBBOX.left = left - diffX;
      this._updateBBOX.right = right - diffX;
    }
    if (percentageDiffY !== 0) {
      const diffY = percentageDiffY * Math.abs(top - bottom);

      this._updateBBOX.bottom = bottom - diffY;
      this._updateBBOX.top = top - diffY;
    }

    this._updateBoundingBox(this._updateBBOX);
    this._mapPanPercentageEnd();
  }

  public setCursor(cursor?: string): void {
    if (cursor) {
      this._currentCursor = cursor;
    } else {
      this._currentCursor = 'default';
    }
    this._baseDiv.style.cursor = this._currentCursor;
  }

  public getId(): string {
    return this._makeComponentId('webmapjsinstance');
  }

  public zoomTo(_newbbox: Bbox): void {
    debugLogger(DebugType.Log, 'zoomTo');
    let setOrigBox = false;

    const newbbox = new WMBBOX(_newbbox);
    // Maintain aspect ratio
    let ratio = 1;
    try {
      ratio =
        (this._resizeBBOX.left - this._resizeBBOX.right) /
        (this._resizeBBOX.bottom - this._resizeBBOX.top);
    } catch (e) {
      setOrigBox = true;
    }
    // Check whether we have had valid bbox values
    if (isNaN(ratio)) {
      setOrigBox = true;
    }
    if (setOrigBox === true) {
      debugLogger(
        DebugType.Error,
        'WebMapJS warning: Invalid bbox: setting ratio to 1',
      );
      ratio = 1;
    }
    if (ratio < 0) {
      ratio = -ratio;
    }

    const screenRatio = this._width / this._height;

    // Is W > H?
    if (ratio > screenRatio) {
      // W is more than H, so calc H
      const centerH = (newbbox.top + newbbox.bottom) / 2;
      const extentH = (newbbox.left - newbbox.right) / 2 / ratio;
      newbbox.bottom = centerH + extentH;
      newbbox.top = centerH - extentH;
    } else {
      // H is more than W, so calc W
      const centerW = (newbbox.right + newbbox.left) / 2;
      const extentW = ((newbbox.bottom - newbbox.top) / 2) * ratio;
      newbbox.left = centerW + extentW;
      newbbox.right = centerW - extentW;
    }

    this.setBBOX(newbbox);
    this._updateBoundingBox(this._bbox);
    this._drawnBBOX.setBBOX(this._bbox);
  }

  public getProj4(): IWMProj4 {
    if (!this._srs || this._srs === 'GFI:TIME_ELEVATION') {
      return null!;
    }
    return { crs: this._srs, proj4 };
  }

  public calculateBoundingBoxAndZoom(lat: number, lng: number): void {
    const pixCoord = getPixelCoordFromLatLong(this, { x: lng, y: lat });
    const geolatlng = getGeoCoordFromPixelCoord(
      pixCoord,
      this._bbox,
      this._width,
      this._height,
    );

    const isOutsideBoundingBox =
      geolatlng.x < this.getBBOX().left ||
      geolatlng.x > this.getBBOX().right ||
      geolatlng.y > this.getBBOX().top ||
      geolatlng.y < this.getBBOX().bottom;

    if (isOutsideBoundingBox) {
      const expandScaler = 0.5;
      const horizontalExpand =
        expandScaler * (this._bbox.right - this._bbox.left);
      const verticalExpand =
        expandScaler * (this._bbox.top - this._bbox.bottom);

      const searchZoomBBOX = new WMBBOX();
      searchZoomBBOX.left = geolatlng.x - horizontalExpand;
      searchZoomBBOX.bottom = geolatlng.y - verticalExpand;
      searchZoomBBOX.right = geolatlng.x + horizontalExpand;
      searchZoomBBOX.top = geolatlng.y + verticalExpand;
      this.zoomTo(searchZoomBBOX);
    }
    this.mapPin.positionMapPinByLatLon({ x: lng, y: lat });
    this.draw('zoomIn');
  }

  public addListener(
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    f: (param?: any) => void,
    keep = false,
  ): boolean {
    return this._wmEventListener.addEventListener(name, f, keep);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeListener(name: string, f: (param?: any) => void): void {
    if (this.isDestroyed) {
      return;
    }
    this._wmEventListener.removeEventListener(name, f);
  }

  public getListener(): WMListener {
    return this._wmEventListener;
  }

  public suspendEvent(name: string): void {
    this._wmEventListener.suspendEvent(name);
  }

  public resumeEvent(name: string): void {
    this._wmEventListener.resumeEvent(name);
  }

  public getDimensionList(): WMJSDimension[] {
    return this.mapdimensions;
  }

  public getDimension(name: string): WMJSDimension {
    for (const mapDimension of this.mapdimensions) {
      if (mapDimension.name === name) {
        return mapDimension;
      }
    }
    return undefined!;
  }

  public setDimension(
    name: string,
    value: string,
    triggerEvent = true,
    adjustLayerDims = true,
  ): void {
    debugLogger(DebugType.Log, `WebMapJS::setDimension(${name},${value})`);
    if (!isDefined(name) || !isDefined(value)) {
      debugLogger(
        DebugType.Error,
        'WebMapJS: Unable to set dimension with undefined value or name',
      );
      return;
    }
    let dim = this.getDimension(name);

    if (isDefined(dim) === false) {
      dim = new WMJSDimension({ name, currentValue: value });
      this.mapdimensions.push(dim);
    }

    if (dim.currentValue !== value) {
      dim.currentValue = value;
      if (adjustLayerDims) {
        buildMapLayerDims(this);
      }
      if (triggerEvent === true) {
        this._wmEventListener.triggerEvent('ondimchange', name);
      }
    }
  }

  public zoomToLayer(_layer: WMLayer): void {
    // Tries to zoom to the layers boundingbox corresponding to the current map projection
    // If something fails, the defaultBBOX is used instead.
    let layer = _layer;
    if (!layer) {
      layer = this.activeLayer;
    }
    if (!layer) {
      this.zoomTo(this._defaultBBOX);
      this.draw('zoomTolayer');
      return;
    }
    for (const layerProjProp of layer.projectionProperties) {
      if (layerProjProp.srs === this._srs) {
        const w = layerProjProp.bbox.right - layerProjProp.bbox.left;
        const h = layerProjProp.bbox.top - layerProjProp.bbox.bottom;
        const newBBOX = new WMBBOX(layerProjProp.bbox);
        newBBOX.left -= w / 100;
        newBBOX.right += w / 100;
        newBBOX.bottom -= h / 100;
        newBBOX.top += h / 100;

        this.zoomTo(newBBOX);
        this.draw('zoomTolayer');
        return;
      }
    }
    debugLogger(
      DebugType.Error,
      `WebMapJS: Unable to find the correct bbox with current map projection ${this._srs} for layer ${layer.title}. Using default bbox instead.`,
    );
    this.zoomTo(this._defaultBBOX);
    this.draw('zoomTolayer');
  }

  public setDefaultBBOX(bbox: WMBBOX): void {
    this._defaultBBOX.setBBOX(bbox);
  }

  public setBBOX(
    left: number | Bbox,
    bottom?: number,
    right?: number,
    top?: number,
  ): boolean {
    debugLogger(DebugType.Log, 'setBBOX');
    this._bbox.setBBOX(left, bottom, right, top);
    this._resizeBBOX.setBBOX(this._bbox);
    this.wMFlyToBBox.flyZoomToBBOXFly.setBBOX(this._bbox);

    if (this._srs !== 'GFI:TIME_ELEVATION') {
      const divRatio = this._width / this._height;
      const bboxRatio =
        (this._bbox.right - this._bbox.left) /
        (this._bbox.top - this._bbox.bottom);
      if (bboxRatio > divRatio) {
        const centerH = (this._bbox.top + this._bbox.bottom) / 2;
        const extentH = (this._bbox.left - this._bbox.right) / 2 / divRatio;
        this._bbox.bottom = centerH + extentH;
        this._bbox.top = centerH - extentH;
      } else {
        /* H is more than W, so calc W */
        const centerW = (this._bbox.right + this._bbox.left) / 2;
        const extentW = ((this._bbox.bottom - this._bbox.top) / 2) * divRatio;
        this._bbox.left = centerW + extentW;

        this._bbox.right = centerW - extentW;
      }
    }
    this._updateBBOX.setBBOX(this._bbox);
    this._drawnBBOX.setBBOX(this._bbox);
    this.canvasLayerBuffer.setBBOX(this._updateBBOX);
    this.mapPin.repositionMapPin(this._bbox);
    this._wmEventListener.triggerEvent('aftersetbbox', this);
    if (this._bbox.equals(left, bottom!, right!, top!) === true) {
      return false;
    }
    return true;
  }

  public zoomOut(ratio = 1 / 6): void {
    const a = (this._resizeBBOX.right - this._resizeBBOX.left) * ratio;
    this.zoomTo(
      new WMBBOX(
        this._resizeBBOX.left - a,
        this._resizeBBOX.bottom - a,
        this._resizeBBOX.right + a,
        this._resizeBBOX.top + a,
      ),
    );
    this.draw('zoomOut');
  }

  public zoomIn(ratio: number): void {
    let a = (this._resizeBBOX.left - this._resizeBBOX.right) / 8;
    if (isDefined(ratio) === false) {
      ratio = 1;
    } else if (ratio === 0) {
      return;
    }
    a *= ratio;
    this.zoomTo(
      new WMBBOX(
        this._resizeBBOX.left - a,
        this._resizeBBOX.bottom - a,
        this._resizeBBOX.right + a,
        this._resizeBBOX.top + a,
      ),
    );
    this.draw('zoomIn');
  }

  public displayLegendInMap(_displayLegendInMap: boolean): void {
    this._displayLegendInMap = _displayLegendInMap;
    this._repositionLegendGraphic();
  }

  public getImageStore(): WMImageStore {
    return this.getMapImageStore;
  }

  public clearImageCache(): void {
    this.getMapImageStore.clear();
    getLegendImageStore().clear();
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Box, Grid2 as Grid, Grid2Props } from '@mui/material';
import { ArrowDownCompact, ArrowUpCompact } from '@opengeoweb/theme';

const sharedArrowIconStyles = {
  fontSize: '0.9rem',
};

const WarningListHeaderContent: React.FC<
  Grid2Props & {
    title: string;
    width?: number;
    onClick?: () => void;
    isSorted?: boolean;
    sortDirection?: 'asc' | 'desc';
    sortable?: boolean;
  }
> = ({
  title,
  width = 76,
  onClick,
  isSorted,
  sortDirection,
  sortable,
  ...props
}) => {
  return (
    <Grid
      sx={{ ...props.sx, width, cursor: onClick ? 'pointer' : 'default' }}
      onClick={(e) => {
        e.stopPropagation(); // Ensure click only affects this column
        onClick?.();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          fontWeight: 'normal',
          textOverflow: 'ellipsis',
          fontSize: 12,
          lineHeight: 1.33,
          height: 16,
          color: 'geowebColors.captions.captionStatus.rgba',
        }}
      >
        {title}
        {sortable && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '4px',
              color: isSorted ? '#186DFF' : 'inherit',
            }}
          >
            {isSorted && sortDirection === 'asc' ? (
              <ArrowUpCompact
                sx={{ ...sharedArrowIconStyles }}
                data-testid="ArrowUpIcon"
              />
            ) : (
              <ArrowDownCompact
                sx={{ ...sharedArrowIconStyles }}
                data-testid="ArrowDownIcon"
              />
            )}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default WarningListHeaderContent;

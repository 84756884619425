/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export const PROJECTION = {
  EPSG_3035: {
    name: 'ETRS89-extended / LAEA Europe',
    value: 'EPSG:3035',
  },
  EPSG_3067: {
    name: 'ETRS89 / TM35FIN(E,N) -- Finland',
    value: 'EPSG:3067',
  },
  EPSG_3411: {
    name: 'Sea Ice Polar Stereographic North',
    value: 'EPSG:3411',
  },
  EPSG_3412: {
    name: 'Sea Ice Polar Stereographic South',
    value: 'EPSG:3412',
  },
  EPSG_3575: {
    name: 'Northern Hemisphere North Pole',
    value: 'EPSG:3575',
  },
  EPSG_3785: {
    name: 'Pseudo-Mercator',
    value: 'EPSG:3785',
  },
  EPSG_3857: {
    name: 'Europe Mercator',
    value: 'EPSG:3857',
  },
  EPSG_4258: {
    name: 'ETRS89',
    value: 'EPSG:4258',
  },
  EPSG_4269: {
    name: 'NAD83 (long/lat)',
    value: 'EPSG:4269',
  },
  EPSG_4325: {
    name: 'Guam Map Grid',
    value: 'EPSG:4325',
  },
  EPSG_4326: {
    name: 'WGS 84 - WGS84 - World Geodetic System',
    value: 'EPSG:4326',
  },
  EPSG_7399: {
    name: 'WISCRS Vilas County (ftUS)',
    value: 'EPSG:7399',
  },
  EPSG_25832: {
    name: 'ETRS89 / UTM zone 32N',
    value: 'EPSG:25832',
  },
  EPSG_28992: {
    name: 'The Netherlands (28992)',
    value: 'EPSG:28992',
  },
  EPSG_32661: {
    name: 'Europe Polar Stereographic',
    value: 'EPSG:32661',
  },
  EPSG_54030: {
    name: 'Europe Robinson',
    value: 'EPSG:54030',
  },
  EPSG_900913: {
    name: 'Google Maps Global Mercator',
    value: 'EPSG:900913',
  },
  EPSG_102100: {
    name: 'WGS 84 / Pseudo-Mercator',
    value: 'EPSG:102100',
  },
} as const;

export type EPSGCode = (typeof PROJECTION)[keyof typeof PROJECTION]['value'];

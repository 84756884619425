/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { debounce } from 'lodash';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useIsMounted } from '@opengeoweb/shared';
import {
  CancelSigmet,
  Change,
  isInstanceOfCancelSigmetOrAirmet,
  isInstanceOfSigmetOrAirmet,
  SigmetMovementType,
  ObservationOrForcast,
  Sigmet,
  Airmet,
  CancelAirmet,
  AirmetMovementType,
  CloudLevelInfoMode,
  AviationPhenomenaCode,
  ProductStatus,
} from '../../types';
import { prepareFormValues } from './utils';
import {
  translateKeyOutsideComponents,
  useSigmetAirmetTranslation,
} from '../../utils/i18n';

type AviationProduct = Sigmet | CancelSigmet | Airmet | CancelAirmet;

// ToDo check if this can be transated here
export const noTAC = translateKeyOutsideComponents('tac-missing-data');

export const isLevelFieldsComplete = (product: Sigmet | Airmet): boolean => {
  const isAirmet = (product: Sigmet | Airmet): product is Airmet => {
    return (product as Airmet).cloudLevelInfoMode !== undefined;
  };

  switch (product.phenomenon) {
    // For certain AIRMETs no levels are provided
    case 'SFC_VIS' as AviationPhenomenaCode:
    case 'SFC_WIND' as AviationPhenomenaCode:
      return true;
    // For certain AIRMETs we have cloudlevels
    case 'OVC_CLD' as AviationPhenomenaCode:
    case 'BKN_CLD' as AviationPhenomenaCode: {
      if (isAirmet(product)) {
        const cloudLevelMode = product.cloudLevelInfoMode as CloudLevelInfoMode;
        if (!cloudLevelMode) {
          return false;
        }
        const levelNeeded =
          cloudLevelMode === 'BETW_SFC_ABV' || cloudLevelMode === 'BETW_SFC';
        const levelAndLowerLevelNeeded =
          cloudLevelMode === 'BETW' || cloudLevelMode === 'BETW_ABV';
        if (
          (levelAndLowerLevelNeeded &&
            (!product.cloudLevel?.value || !product.cloudLowerLevel?.value)) ||
          (levelNeeded && !product.cloudLevel?.value)
        ) {
          return false;
        }
        return true;
      }
      return false;
    }
    // Other AIRMETs and all SIGMETs
    default: {
      if (!product.levelInfoMode) {
        return false;
      }
      const levelMode = product.levelInfoMode;
      const levelNeeded =
        levelMode === 'AT' ||
        levelMode === 'ABV' ||
        levelMode === 'TOPS' ||
        levelMode === 'TOPS_ABV' ||
        levelMode === 'BETW_SFC';
      const levelAndLowerLevelNeeded = levelMode === 'BETW';
      if (
        (levelNeeded && !product.level?.value) ||
        (levelAndLowerLevelNeeded &&
          (!product.level?.value || !product.lowerLevel?.value))
      ) {
        return false;
      }
      return true;
    }
  }
};

/* Changes in intensity should always be included in sigmet except for
 * volcanic ash cloud and radioactive cloud where it should be omitted
 * if specified in the config
 */
export const isChangeComplete = (
  product: Sigmet | Airmet,
  omitChange?: boolean,
): boolean => {
  const omitChangeForVaAndRdoact =
    (omitChange &&
      product.phenomenon === ('VA_CLD' as AviationPhenomenaCode)) ||
    (omitChange &&
      product.phenomenon === ('RDOACT_CLD' as AviationPhenomenaCode));
  const isNotEmpty =
    !omitChangeForVaAndRdoact &&
    product.change !== undefined &&
    product.change !== null &&
    product.change !== ('' as Change);
  return omitChangeForVaAndRdoact || isNotEmpty;
};

export const isSurfaceVisComplete = (product: Sigmet | Airmet): boolean => {
  // For Surface Visibility the cause is required
  if (
    product.phenomenon === ('SFC_VIS' as AviationPhenomenaCode) &&
    'visibilityCause' in product &&
    !product.visibilityCause
  ) {
    return false;
  }
  return true;
};

// Ensure we have enough data to retrieve a TAC
export const shouldRetrieveTAC = (
  product: AviationProduct,
  omitChange?: boolean,
): boolean => {
  if (!product) {
    return false;
  }
  // always retrieve for a cancel product - this is always complete
  if (product && isInstanceOfCancelSigmetOrAirmet(product)) {
    return true;
  }
  // retrieve for short (va) test sigmets with less mandatory fields
  if (
    product &&
    isInstanceOfSigmetOrAirmet(product) &&
    (product.type === 'SHORT_TEST' || product.type === 'SHORT_VA_TEST') &&
    product.phenomenon &&
    product.phenomenon !== ('' as AviationPhenomenaCode)
  ) {
    return true;
  }
  if (
    product &&
    isInstanceOfSigmetOrAirmet(product) &&
    product.phenomenon &&
    product.phenomenon !== ('' as AviationPhenomenaCode) &&
    product.isObservationOrForecast &&
    product.isObservationOrForecast !== ('' as ObservationOrForcast) &&
    product.validDateStart &&
    product.validDateStart !== null &&
    product.validDateEnd &&
    product.validDateEnd !== null &&
    isLevelFieldsComplete(product) &&
    product.startGeometry &&
    product.startGeometry !== null &&
    product.startGeometryIntersect &&
    product.startGeometryIntersect !== null &&
    product.movementType &&
    product.movementType !== ('' as SigmetMovementType | AirmetMovementType) &&
    isChangeComplete(product, omitChange) &&
    isSurfaceVisComplete(product)
  ) {
    return true;
  }
  return false;
};

export const useTAC = (
  initialProduct: AviationProduct,
  getTAC: (product: AviationProduct) => Promise<{ data: string }>,
  hasErrors = (): boolean => false,
  omitChange?: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [string, any] => {
  const { t } = useSigmetAirmetTranslation();
  const noTAC = t('tac-missing-data');
  const [TAC, setTAC] = React.useState(noTAC);
  const { isMounted } = useIsMounted();

  const retrieveTAC = async (product: AviationProduct): Promise<void> => {
    try {
      const result = await getTAC(prepareFormValues(product));
      if (isMounted.current) {
        setTAC(result.data);
      }
    } catch (error) {
      if (isMounted.current) {
        setTAC(noTAC);
      }
    }
  };

  React.useEffect(() => {
    if (shouldRetrieveTAC(initialProduct, omitChange) && !hasErrors()) {
      void retrieveTAC(initialProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = (
    formValues: AviationProduct,
    hasFormErrors: () => boolean,
  ): void => {
    if (shouldRetrieveTAC(formValues, omitChange) && !hasFormErrors) {
      void retrieveTAC(formValues);
    } else if (isMounted.current) {
      setTAC(noTAC);
    }
  };

  const debouncedSearch = React.useRef(
    debounce(async (formFn, hasFormErrors) => {
      update(formFn(), hasFormErrors());
    }, 1000),
  ).current;

  const delayedQuery = (formFn: () => AviationProduct): void => {
    debouncedSearch.cancel();
    void debouncedSearch(formFn, hasErrors);
  };

  return [TAC, delayedQuery];
};

interface ProductFormTACProps {
  tac: string;
  product?: AviationProduct;
}

export const getSequenceStyling = (
  product?: ProductFormTACProps['product'],
  hasTac = false,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  if (
    (hasTac && !product) ||
    (product && product.status === 'DRAFT') ||
    (product && product.status === ('' as ProductStatus))
  ) {
    return {
      fontWeight: 'lighter',
      color: 'geowebColors.typographyAndIcons.inactiveText',
    };
  }
  return {};
};

const ProductFormTAC: React.FC<ProductFormTACProps> = ({ tac, product }) => {
  const { t } = useSigmetAirmetTranslation();
  const noTAC = t('tac-missing-data');
  const tacLines = tac.split('\n');
  const hasTac = tacLines.length > 0 && tac !== noTAC;
  const sequenceNumberInTac = hasTac ? tacLines[0].split(' ')[2] ?? null : null;
  const sequenceStyle = getSequenceStyling(product, hasTac);

  return (
    <Typography
      variant="body2"
      component="div"
      sx={{
        whiteSpace: 'break-spaces',
      }}
      data-testid="productform-tac-message"
    >
      <Box
        component="p"
        sx={{
          margin: 0,
          fontSize: '1.125rem',
          fontWeight: 'normal',
          lineHeight: 1.56,
          letterSpacing: '0.5px',
          marginTop: '2px',
        }}
        data-testid="productform-tac-message-line"
      >
        {tac.split(' ').map((word, index) => (
          <Box
            component="span"
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            sx={hasTac && word === sequenceNumberInTac ? sequenceStyle : {}}
          >
            {word + (index < tac.split(' ').length - 1 ? ' ' : '')}
          </Box>
        ))}
      </Box>
    </Typography>
  );
};

export default ProductFormTAC;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Grid2 as Grid, Typography, Box, SxProps } from '@mui/material';
import { Close } from '@opengeoweb/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../CustomTooltip';
import { HeaderSize, ToolHeaderProps } from './types';
import { CustomIconButton } from '../CustomIconButton';
import { SHARED_NAMESPACE } from '../../utils/i18n';

const headerStyle: SxProps = {
  containerType: 'inline-size',
  containerName: 'tool-header',
  '.header-title': {
    display: 'none',
  },
  '@container tool-header (min-width: 321px)': {
    '.header-title': {
      display: 'block',
    },
  },
};

const getStyle = (size: HeaderSize): React.CSSProperties => {
  switch (size) {
    case 'xs': {
      return {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '22px',
        marginTop: '-1px',
      };
    }
    case 'small': {
      return {
        fontSize: 16,
        fontWeight: 500,
        padding: '4px',
      };
    }
    case 'medium': {
      return {
        fontSize: 20,
        fontWeight: 400,
        padding: '4px 8px',
        height: 40,
      };
    }
    case 'large': {
      return {
        fontSize: 16,
        fontWeight: 500,
        padding: '12px',
      };
    }
    default: {
      return {};
    }
  }
};

const iconStyle: SxProps = {
  color: 'geowebColors.greys.accessible',
  padding: 0,
  '&:hover': {
    background: 'action.hover',
  },
};

const ToolHeader: React.FC<ToolHeaderProps> = ({
  size,
  title,
  onClose = null!,
  isDraggable = false,
  className = '',
  leftHeaderComponent = null,
  rightHeaderComponent = null,
  closeIcon = <Close data-testid="CloseIcon" />,
}: ToolHeaderProps) => {
  const styles = getStyle(size!);

  const columnSizeIcon =
    title && (leftHeaderComponent || rightHeaderComponent) ? 4 : 1;
  const columnSizeTitle = leftHeaderComponent || rightHeaderComponent ? 4 : 10;
  const columnSizeLeft = title ? columnSizeIcon : 12 - columnSizeIcon;
  const { t } = useTranslation(SHARED_NAMESPACE);

  return (
    <Box
      component="header"
      className={className}
      sx={{
        ...(rightHeaderComponent ? headerStyle : {}),
        boxShadow: 1, // elevation_01
        backgroundColor: 'geowebColors.background.surface',
        zIndex: 2,
        cursor: isDraggable ? 'move' : null,
      }}
      role="heading"
      aria-label="toolheader"
    >
      <Grid
        container
        alignItems="center"
        sx={{
          padding: styles.padding,
          height: styles.height,
        }}
        justifyContent="space-between"
      >
        <Grid
          className="header-left"
          sx={{ paddingRight: 1 }}
          size={columnSizeLeft}
        >
          {leftHeaderComponent && leftHeaderComponent}
        </Grid>
        <Grid
          className="header-title"
          size={title ? columnSizeTitle : 0}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: 12 }}
        >
          {title && (
            <Typography
              variant="h2"
              noWrap
              sx={{
                fontSize: styles.fontSize,
                fontWeight: styles.fontWeight,
                lineHeight: styles.lineHeight,
                marginTop: styles.marginTop,
                textAlign: 'center',
              }}
              role="heading"
            >
              {title}
            </Typography>
          )}
        </Grid>
        <Grid
          className="header-right"
          container
          justifyContent="flex-end"
          size={columnSizeIcon}
        >
          {rightHeaderComponent && rightHeaderComponent}
          {onClose && (
            <CustomTooltip
              title={t('shared-close')}
              placement="bottom"
              sx={{ pointerEvents: 'none' }}
            >
              <CustomIconButton
                data-testid="closeBtn"
                onClick={onClose}
                onTouchEnd={onClose}
                sx={{
                  '&.MuiButtonBase-root.MuiIconButton-root': iconStyle,
                }}
              >
                {closeIcon}
              </CustomIconButton>
            </CustomTooltip>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ToolHeader;

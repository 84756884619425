/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { dateUtils } from '@opengeoweb/shared';
import {
  ReactHookFormDateTime,
  isXHoursBefore,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';

import {
  getFieldLabel,
  getHoursBeforeValidity,
  getValidFromDelayTimeMinutesFromConfig,
  triggerValidations,
} from '../utils';
import { ConfigurableFormFieldProps, ProductConfig } from '../../../types';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { styles } from '../ProductForm.styles';
import {
  translateKeyOutsideComponents,
  useSigmetAirmetTranslation,
} from '../../../utils/i18n';

export const getValidFromXHoursBeforeMessage = (hours: number): string =>
  translateKeyOutsideComponents('valid-from-time-error', { hours });

export const getDefaultValidFromValue = (
  productConfig: ProductConfig,
): string => {
  const validFromDelayTimeMinutes =
    getValidFromDelayTimeMinutesFromConfig(productConfig);

  const dateWithDelay = dateUtils.add(dateUtils.utc(), {
    minutes: validFromDelayTimeMinutes,
  });

  const minutes = dateWithDelay.getUTCMinutes();

  // Calculate the minutes needed to round up to the next half hour
  const extraMinutes = minutes <= 30 ? 30 - minutes : 60 - minutes;

  const roundedDate = dateUtils.add(dateWithDelay, { minutes: extraMinutes });

  return dateUtils.dateToString(roundedDate)!;
};

const ValidFrom: React.FC<ConfigurableFormFieldProps> = ({
  productConfig,
  isDisabled,
  isReadOnly,
  onChange = (): void => {},
}: ConfigurableFormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { getValues, trigger } = useFormContext();
  const { isRequired } = useDraftFormHelpers();
  const label = getFieldLabel(t('date-and-time'), isReadOnly!);

  return (
    <ProductFormFieldLayout title={t('valid-from')}>
      <ReactHookFormDateTime
        disablePast
        name="validDateStart"
        label={label}
        defaultNullValue={getDefaultValidFromValue(productConfig)}
        rules={{
          validate: {
            isRequired,
            isValidDate: dateUtils.isValidIsoDateString,
            // The validity start can not be before the current time
            isValueBeforeCurrentTime: (value): boolean | string =>
              isXHoursBefore(value, dateUtils.getCurrentTimeAsString(), 0) ||
              t('valid-from-before-current-error'),
            // The current time can be no more than 4 hours before the validity start (12 for VA/TC SIGMET)
            isCurrentTimeXHoursBeforeValue: (value): boolean | string =>
              isXHoursBefore(
                dateUtils.getCurrentTimeAsString(),
                value,
                getHoursBeforeValidity(
                  getValues('phenomenon'),
                  getValues('locationIndicatorATSR'),
                  productConfig,
                ),
              ) ||
              getValidFromXHoursBeforeMessage(
                getHoursBeforeValidity(
                  getValues('phenomenon'),
                  getValues('locationIndicatorATSR'),
                  productConfig,
                ),
              ),
          },
        }}
        onChange={(): void => {
          triggerValidations(
            ['validDateEnd', 'observationOrForecastTime'],
            getValues,
            trigger,
          );
          onChange();
        }}
        disabled={isDisabled}
        isReadOnly={isReadOnly}
        data-testid="valid-from"
        sx={{
          ...styles.helperText,
          '.MuiFormHelperText-root.Mui-error': {
            marginBottom: '-16px',
          },
        }}
      />
    </ProductFormFieldLayout>
  );
};

export default ValidFrom;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Paper,
  List,
  ListItemButton,
  ListItemText,
  InputLabel,
  Grid2 as Grid,
} from '@mui/material';
import { serviceTypes } from '@opengeoweb/store';
import { useCoreTranslation } from '../../../utils/i18n';

interface WMSServerListProps {
  availableServices: serviceTypes.InitialService[];
  handleChangeService: (service: serviceTypes.InitialService) => void;
  service: serviceTypes.InitialService;
}

const WMSServerList: React.FC<WMSServerListProps> = ({
  availableServices,
  handleChangeService,
  service: activeService,
}: WMSServerListProps) => {
  const { t } = useCoreTranslation();
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    service: serviceTypes.InitialService,
  ): void => {
    handleChangeService(service);
  };

  const sortedServices = [...availableServices].sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <>
      <Grid container direction="row" style={{ height: '26px' }}>
        <InputLabel shrink htmlFor="select-multiple-native">
          {t('add-layer-popup-available-services')}
        </InputLabel>
      </Grid>
      <Paper style={{ height: 200, overflow: 'auto' }}>
        <List dense>
          {sortedServices.map((service) => (
            <ListItemButton
              data-testid="service-item"
              key={service.url}
              selected={service === activeService}
              onClick={(event): void => {
                handleListItemClick(event, service);
              }}
            >
              <ListItemText primary={service.name} />
            </ListItemButton>
          ))}
        </List>
      </Paper>
    </>
  );
};

export default WMSServerList;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Grid2 as Grid, Button } from '@mui/material';
import { useSpaceweatherTranslation } from '../../utils/i18n';

interface BulletinControlsProps {
  onOpenBulletinHistory?: () => void;
}

export const BulletinControls: React.FC<BulletinControlsProps> = ({
  onOpenBulletinHistory,
}: BulletinControlsProps) => {
  const { t } = useSpaceweatherTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <Grid>
        <Button
          data-testid="history"
          size="small"
          variant="outlined"
          color="secondary"
          onClick={(): void => onOpenBulletinHistory!()}
          sx={{
            marginTop: 1,
            marginRight: 1,
          }}
        >
          {t('bulletin-history-list')}
        </Button>
      </Grid>
    </Grid>
  );
};

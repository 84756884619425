/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Typography, Box, Drawer, useTheme } from '@mui/material';
import React from 'react';
import { GeoWebLogo, HamburgerMenu } from '@opengeoweb/theme';
import { CustomIconButton } from '../CustomIconButton';

interface AppHeaderProps {
  menu?: React.ReactNode;
  userMenu?: React.ReactNode;
  workspaceTopBar?: React.ReactNode;
  title?: string;
  onClick?: () => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({
  menu,
  userMenu,
  workspaceTopBar,
  title = 'GeoWeb',
  onClick,
}: AppHeaderProps) => {
  const theme = useTheme();
  const [isDrawerOpen, toggleDrawer] = React.useState<boolean>(false);
  const onToggleDrawer = (isOpen: boolean): void => toggleDrawer(isOpen);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Tab' || event.key === 'Shift') {
      return;
    }

    toggleDrawer(false);
  };

  return (
    <Box
      component="header"
      role="heading"
      sx={{
        height: 40,
        backgroundColor: theme.palette.geowebColors.brand.brand,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        zIndex: 1000,
        display: 'flex',
        padding: '0 16px',
        [theme.breakpoints.up('md')]: {
          padding: '0 24px',
        },
        [theme.breakpoints.up('sm')]: {
          '.workspace-topbar': {
            marginLeft: 3,
            maxWidth: 260,
          },
          '.hamburger-btn': {
            marginLeft: 4,
          },
          h1: { maxWidth: 'initial', display: 'block' },
        },
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          display: 'inherit',
          alignItems: 'center',
          cursor: onClick ? 'pointer' : 'auto',
        }}
      >
        {theme.palette.geowebColors.logo?.image ? (
          <img
            src={theme.palette.geowebColors.logo.image}
            alt="GeoWeb"
            style={{
              height: '29px',
              width: '47px',
            }}
          />
        ) : (
          <GeoWebLogo
            style={{
              position: 'relative',
              top: '-1px',
              right: '-1px',
            }}
          />
        )}
        <Typography
          variant="h1"
          sx={{
            display: 'none',
            fontSize: '20px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.15px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: 'geowebColors.typographyAndIcons.text',
            marginLeft: 1,
            maxWidth: 130,
          }}
        >
          {title}
        </Typography>
      </Box>

      {menu && (
        <Box
          className="hamburger-btn"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 1,
          }}
        >
          <CustomIconButton
            data-testid="menuButton"
            onClick={(): void => onToggleDrawer(true)}
            isSelected={isDrawerOpen}
          >
            <HamburgerMenu />
          </CustomIconButton>
          <Drawer
            data-testid="app-drawer"
            anchor="left"
            open={isDrawerOpen}
            onClose={(): void => onToggleDrawer(false)}
            onClick={(): void => onToggleDrawer(false)}
            onKeyDown={onKeyDown}
            sx={{
              '& .MuiDrawer-paper': {
                top: '40px',
              },
            }}
          >
            <Box
              role="presentation"
              sx={{
                width: '250px',
                overflowY: 'auto',
              }}
            >
              {menu}
            </Box>
          </Drawer>
        </Box>
      )}

      <Box
        className="workspace-topbar"
        sx={{
          flexGrow: 1,
          display: 'flex',
          marginLeft: 1,
          alignItems: 'center',
          maxWidth: 168,
        }}
      >
        {workspaceTopBar}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}
      >
        {userMenu}
      </Box>
    </Box>
  );
};

export default AppHeader;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useSpaceweatherTranslation } from '../../utils/i18n';

export enum BulletinType {
  technical = 'TECHNICAL',
  plain = 'PLAIN',
}

const styles = {
  tabsRoot: {
    borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
  },
  bulletinTab: {
    padding: '5px 16px 0px 16px',
  },
};

interface BulletinTabsProps {
  selected: BulletinType;
  onSetBulletinType: (bulletinType: BulletinType) => void;
}

export const BulletinTabs: React.FC<BulletinTabsProps> = ({
  selected,
  onSetBulletinType,
}: BulletinTabsProps) => {
  const { t } = useSpaceweatherTranslation();
  return (
    <Box sx={styles.bulletinTab} data-testid="bulletintabs">
      <Tabs
        value={selected}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={(event, value): void => {
          onSetBulletinType(value);
        }}
        sx={styles.tabsRoot}
      >
        <Tab
          label={t('bulletin-technical-forecast')}
          value={BulletinType.technical}
          data-testid="technical"
        />
        <Tab
          label={t('bulletin-plain-language')}
          value={BulletinType.plain}
          data-testid="plain"
        />
      </Tabs>
    </Box>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, Paper } from '@mui/material';
import React from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import ToolFooter from './ToolFooter';
import ToolHeader from './ToolHeader';
import { ToolContainerProps } from './types';

const styles = {
  container: {
    overflow: 'hidden',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
    position: 'relative',
  },
};

export const BASE_ELEVATION = 5;

const ToolContainer: React.FC<ToolContainerProps> = ({
  title = '',
  size = 'xs',
  children,
  onClose,
  isResizable = false,
  isDraggable = false,
  maxHeight = 'initial',
  elevation = BASE_ELEVATION, // elevation_06
  headerClassName = '',
  leftHeaderComponent = null,
  rightHeaderComponent = null,
  dragHandleIcon,
  closeIcon,
  ...props
}: ToolContainerProps) => {
  return (
    <Paper elevation={elevation} sx={styles.container} {...props}>
      <ErrorBoundary>
        <ToolHeader
          title={title}
          size={size}
          isDraggable={isDraggable}
          onClose={onClose}
          className={headerClassName}
          leftHeaderComponent={leftHeaderComponent}
          rightHeaderComponent={rightHeaderComponent}
          dragHandleIcon={dragHandleIcon}
          closeIcon={closeIcon}
        />
      </ErrorBoundary>
      <Box sx={styles.content} style={{ maxHeight }}>
        {children}
      </Box>
      {isResizable && <ToolFooter />}
    </Paper>
  );
};

export default ToolContainer;

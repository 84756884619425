/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { LayerType, webmapUtils } from '@opengeoweb/webmap';

import {
  layerActions,
  mapSelectors,
  layerTypes,
  serviceSelectors,
  layerSelectors,
  uiSelectors,
  uiTypes,
} from '@opengeoweb/store';
import { LayerList } from './LayerList';
import { useAppSelector, layerSelectSelectors } from '../../store';
import { LayerWithServiceName } from '../../store/types';
import { DeleteLayerParams } from './LayerAddRemoveButton';

interface LayerListConnectProps {
  mapId: string;
  layerSelectHeight: number;
  serviceListHeight: number;
  layerSelectWidth: number;
}

interface AddLayerParams {
  serviceUrl: string;
  layerName: string;
}

export const LayerListConnect: React.FC<LayerListConnectProps> = ({
  mapId,
  layerSelectHeight,
  serviceListHeight,
  layerSelectWidth,
}: LayerListConnectProps) => {
  const dispatch = useDispatch();

  const services = useAppSelector((store) =>
    serviceSelectors.getServices(store),
  );

  const mapLayers = useAppSelector((store): layerTypes.Layer[] =>
    mapSelectors.getMapLayersWithoutDimensionCurrentValue(store, mapId),
  );
  const addLayer = ({ serviceUrl, layerName }: AddLayerParams): void => {
    const layer = {
      service: serviceUrl,
      name: layerName,
      id: webmapUtils.generateLayerId(),
      layerType: LayerType.mapLayer,
    } as layerTypes.Layer;

    addMapLayer({
      layerId: layer.id,
      layer,
      origin: layerTypes.LayerActionOrigin.layerManager,
    } as layerTypes.AddLayerPayload);
  };

  const addMapLayer = React.useCallback(
    ({ layerId, layer, origin }: layerTypes.AddLayerPayload) =>
      dispatch(
        layerActions.addLayer({
          mapId,
          layerId,
          layer,
          origin,
        }),
      ),
    [dispatch, mapId],
  );

  const deleteLayer = React.useCallback(
    ({ layerId, layerIndex }: DeleteLayerParams) =>
      dispatch(
        layerActions.layerDelete({
          mapId,
          layerId,
          layerIndex,
          origin: layerTypes.LayerActionOrigin.layerManager,
        }),
      ),
    [dispatch, mapId],
  );

  const filteredLayers: LayerWithServiceName[] = useAppSelector((store) =>
    layerSelectSelectors.getFilteredLayers(store),
  );

  const searchFilter = useAppSelector((store) =>
    layerSelectSelectors.getSearchFilter(store),
  );

  const isLayerInfoVisibleInStore = useAppSelector((store) =>
    uiSelectors.getisDialogOpen(store, uiTypes.DialogTypes.LayerInfo),
  );

  const currentLayerInfo = useAppSelector((store) =>
    layerSelectors.getActiveLayerInfo(store),
  );

  const onToggleActiveLayer = React.useCallback(
    ({
      serviceUrl,
      layerName,
      isActiveLayer,
    }: AddLayerParams & { isActiveLayer: boolean }): void => {
      if (!isActiveLayer) {
        dispatch(
          layerActions.showLayerInfo({
            layerName,
            serviceUrl,
            mapId,
          }),
        );
      } else {
        dispatch(layerActions.hideLayerInfo());
      }
    },
    [mapId, dispatch],
  );

  return (
    <LayerList
      filteredLayers={filteredLayers}
      services={services}
      layerSelectHeight={layerSelectHeight}
      serviceListHeight={serviceListHeight}
      layerSelectWidth={layerSelectWidth}
      addLayer={addLayer}
      deleteLayer={deleteLayer}
      mapLayers={mapLayers}
      searchFilter={searchFilter}
      isLayerInfoVisibleInStore={isLayerInfoVisibleInStore}
      onToggleActiveLayer={onToggleActiveLayer}
      currentLayerInfo={currentLayerInfo!}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormSelect } from '@opengeoweb/form-fields';

import { styles } from './EditFormFields.styles';
import { ThresholdValues, EventLevels, XrayClasses } from '../../../types';
import { useSpaceweatherTranslation } from '../../../utils/i18n';

const EventLevel: React.FC = () => {
  const { t } = useSpaceweatherTranslation();
  const { watch, setValue } = useFormContext();

  const category = watch('category');
  const categorydetail = watch('categorydetail');

  return (
    <Grid container size={12}>
      <Grid size={5}>
        <ReactHookFormSelect
          name="neweventlevel"
          label={t('notification-event-level')}
          sx={styles.inputField}
          inputProps={{
            SelectDisplayProps: {
              'data-testid': 'eventlevel-select',
            },
          }}
          rules={{ required: true }}
          onChange={(event: SelectChangeEvent<unknown>): void => {
            if (
              category === 'XRAY_RADIO_BLACKOUT' ||
              categorydetail === 'PROTON_FLUX_10' ||
              categorydetail === 'KP_INDEX'
            ) {
              const index = EventLevels[
                category as keyof typeof EventLevels
              ].findIndex((level: string) => level === event.target.value);
              setValue(
                'threshold',
                ThresholdValues[category as keyof typeof ThresholdValues][
                  index
                ],
                {
                  shouldValidate: true,
                },
              );
              if (category === 'XRAY_RADIO_BLACKOUT') {
                setValue('xrayclass', XrayClasses[index], {
                  shouldValidate: true,
                });
              }
            }
          }}
        >
          {EventLevels[category as keyof typeof EventLevels] &&
            EventLevels[category as keyof typeof EventLevels].map(
              (eventlevel: string) => (
                <MenuItem value={eventlevel} key={eventlevel}>
                  {eventlevel}
                </MenuItem>
              ),
            )}
        </ReactHookFormSelect>
      </Grid>
    </Grid>
  );
};

export default EventLevel;

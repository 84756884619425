/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  List as MuiList,
  ListItemText,
  Grid2 as Grid,
  SxProps,
} from '@mui/material';
import { CustomToggleButton, SearchHighlight } from '@opengeoweb/shared';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, { FC } from 'react';
import { ChevronDown, ChevronUp } from '@opengeoweb/theme';
import { useTimeseriesTranslation } from '../../utils/i18n';
import { Parameter, Plot } from '../TimeSeries/types';
import {
  TimeseriesCollectionDetail,
  filterCollectionsParametersFromService,
} from './utils';
import { ServiceFilterChipsObjectEntities } from '../../store/types';
import { ParameterInfoButtonConnect } from '../TimeSeriesManager/ParameterInfo/ParameterInfoButtonConnect';

export const NO_RESULTS_FOUND_MESSAGE = 'No parameters found';
export const DEFAULT_DIALOG_HEIGHT = 500;
const DEFAULT_PADDING_TOP = 6;
const DEFAULT_RESULTS_HEIGHT = 22;

const DEFAULT_COLLECTION_ROW_HEIGHT = 48; // listItem height + 4px marginTop
const DEFAULT_PARAMETER_ROW_HEIGHT = 34; // listItem height
const DEFAULT_PARAMTER_LIST_PADDING_BOTTOM = 0;
const DEFAULT_PARAMTER_LIST_BORDER_BOTTOM = 1;

const fontStyling = (primary = true): SxProps => {
  return {
    fontSize: '12px',
    height: '14px',
    lineHeight: '14px',
    fontWeight: primary ? 500 : 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };
};

interface TimeSeriesSelectListProps {
  selectPlot: Plot;
  collectionListItemDetails: Record<string, boolean>;
  collectionList: TimeseriesCollectionDetail[];
  setCollectionListItemDetails: (newList: Record<string, boolean>) => void;
  handleAddOrRemoveClick: (
    parameter: Parameter,
    plotHasParameter: boolean,
  ) => void;
  searchFilter: string;
  filterChipsServices: ServiceFilterChipsObjectEntities;
  allFilterChipsEnabled: boolean;
}

export const TimeSeriesSelectList: FC<TimeSeriesSelectListProps> = ({
  selectPlot,
  collectionListItemDetails,
  collectionList,
  handleAddOrRemoveClick,
  setCollectionListItemDetails,
  searchFilter,
  filterChipsServices,
  allFilterChipsEnabled,
}) => {
  const listRef = React.useRef<List<TimeseriesCollectionDetail>>(null);
  const [filteredCollectionList, setFilteredCollectionList] = React.useState<
    TimeseriesCollectionDetail[]
  >([]);
  const { t } = useTimeseriesTranslation();

  React.useEffect(() => {
    const filterResults = filterCollectionsParametersFromService(
      collectionList,
      searchFilter,
      filterChipsServices,
      allFilterChipsEnabled,
    );
    setFilteredCollectionList(filterResults);
    // Needed to recalculate the height of the list elements now that some collections/parameters have been filtered out
    listRef?.current?.resetAfterIndex(0);
  }, [
    searchFilter,
    collectionList,
    filterChipsServices,
    allFilterChipsEnabled,
  ]);

  return (
    <Box
      data-testid="timeseriesSelectList"
      sx={{
        flex: 1,
      }}
    >
      <Box sx={{ marginBottom: '4px', fontSize: '12px' }}>
        {filteredCollectionList.length} {t('timeseries-results')}
      </Box>
      <AutoSizer>
        {({ height = 0, width = 0 }): React.ReactNode => (
          <List
            ref={listRef}
            height={height - DEFAULT_RESULTS_HEIGHT - DEFAULT_PADDING_TOP}
            width={width}
            itemCount={filteredCollectionList.length}
            itemSize={(index: number): number => {
              const { collectionId } = filteredCollectionList[index];

              if (!collectionListItemDetails[collectionId]) {
                return DEFAULT_COLLECTION_ROW_HEIGHT;
              }
              if (filteredCollectionList[index].parameters.length < 1) {
                return (
                  DEFAULT_PARAMETER_ROW_HEIGHT +
                  DEFAULT_COLLECTION_ROW_HEIGHT +
                  DEFAULT_PARAMTER_LIST_PADDING_BOTTOM +
                  DEFAULT_PARAMTER_LIST_BORDER_BOTTOM
                );
              }
              return (
                filteredCollectionList[index].parameters.length *
                  DEFAULT_PARAMETER_ROW_HEIGHT +
                DEFAULT_COLLECTION_ROW_HEIGHT +
                DEFAULT_PARAMTER_LIST_PADDING_BOTTOM +
                DEFAULT_PARAMTER_LIST_BORDER_BOTTOM
              );
            }}
          >
            {({ index, style }): React.ReactElement => {
              const collectionListItem = filteredCollectionList[index];
              const { collectionId } = collectionListItem;

              return (
                <Box style={style}>
                  <ListItem
                    sx={{
                      backgroundColor: 'geowebColors.cards.cardContainer',
                      height: '44px',
                      marginTop: 0.5,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      borderRadius: '3px',
                      padding: 0,
                      zIndex: 10,
                      overflow: 'hidden',
                      borderColor: 'geowebColors.cards.cardContainerBorder',
                      '.MuiListItemText-primary': {
                        fontSize: '12px',
                        fontWeight: 500,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                    }}
                  >
                    <ListItemButton
                      sx={{
                        height: '100%',
                        paddingLeft: '24px',
                        paddingRight: '8px',
                      }}
                      onClick={(): void => {
                        const currentState =
                          collectionListItemDetails[collectionId];
                        setCollectionListItemDetails({
                          ...collectionListItemDetails,
                          [collectionId]: !currentState,
                        });
                        listRef?.current?.resetAfterIndex(index);
                      }}
                    >
                      <Grid
                        container
                        size="grow"
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Grid>
                          <Box sx={fontStyling()}>
                            <SearchHighlight
                              text={collectionListItem.collectionId}
                              search={searchFilter}
                            />
                          </Box>

                          <Box sx={fontStyling(false)}>
                            <SearchHighlight
                              text={collectionListItem.serviceName}
                              search={searchFilter}
                            />
                          </Box>
                        </Grid>
                        <Grid>
                          <ListItemText
                            sx={{
                              paddingLeft: 1,
                              paddingRight: 5,
                              textAlign: 'right',
                            }}
                            primary={`${collectionListItem.parameters.length} ${t('timeseries-results')}`}
                          />
                        </Grid>
                      </Grid>
                      {collectionListItemDetails[collectionId] ? (
                        <ChevronUp />
                      ) : (
                        <ChevronDown />
                      )}
                    </ListItemButton>
                  </ListItem>
                  <Collapse
                    in={collectionListItemDetails[collectionId]}
                    unmountOnExit
                  >
                    <MuiList
                      sx={{
                        paddingBottom: `${DEFAULT_PARAMTER_LIST_PADDING_BOTTOM}px`,
                        paddingTop: 0,
                        backgroundColor: 'geowebColors.cards.cardContainer',
                        borderWidth: `${DEFAULT_PARAMTER_LIST_BORDER_BOTTOM}px`,
                        borderStyle: 'none solid solid solid',
                        borderColor: 'geowebColors.cards.cardContainerBorder',
                        borderBottomLeftRadius: '3px',
                        borderBottomRightRadius: '3px',
                        boxShadow: 1,
                        top: -5,
                      }}
                    >
                      {collectionListItem?.parameters.length < 1 && (
                        <ListItem
                          sx={{
                            height: `${DEFAULT_PARAMETER_ROW_HEIGHT + 4}px`,
                            minHeight: '36px',
                            paddingLeft: '40px',
                            paddingRight: '4px',
                            paddingTop: '13px',
                            ' .MuiListItemText-primary': {
                              fontSize: '12px',
                              fontWeight: 500,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            },
                          }}
                          role="listitem"
                        >
                          <ListItemText
                            sx={{
                              paddingLeft: 1,
                              paddingRight: 1,
                            }}
                            secondary={t('timeseries-no-params')}
                          />
                        </ListItem>
                      )}
                      {collectionListItem.parameters.map((parameter) => {
                        const plotParameter = selectPlot.parameters!.find(
                          (plotParameter) =>
                            collectionListItem.serviceId ===
                              plotParameter.serviceId &&
                            collectionListItem.collectionId ===
                              plotParameter.collectionId &&
                            parameter.propertyName ===
                              plotParameter.propertyName,
                        );
                        const plotHasParameter = Boolean(plotParameter);
                        return (
                          <ListItem
                            sx={{
                              paddingLeft: '40px',
                              paddingRight: '4px',
                              ':not(:first-of-type)': {
                                borderTopColor:
                                  'geowebColors.cards.cardContainerBorder',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px',

                                height: `${DEFAULT_PARAMETER_ROW_HEIGHT}px`,
                                minHeight: '32px',
                              },
                              ':first-of-type': {
                                height: `${DEFAULT_PARAMETER_ROW_HEIGHT + 4}px`,
                                minHeight: '36px',
                                paddingTop: '13px',
                              },
                              ':hover': {
                                backgroundColor:
                                  'geowebColors.cards.cardContainerMouseOver',
                              },
                            }}
                            key={parameter.propertyName}
                            role="listitem"
                            aria-label={parameter.propertyName}
                          >
                            <Grid
                              container
                              size="grow"
                              sx={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Grid>
                                <Box sx={fontStyling()}>
                                  <SearchHighlight
                                    text={parameter.propertyName}
                                    search={searchFilter}
                                  />
                                </Box>
                              </Grid>
                              <Grid>
                                <Box
                                  sx={{
                                    marginRight: '8px',
                                  }}
                                >
                                  <ParameterInfoButtonConnect
                                    parameter={
                                      plotParameter || {
                                        ...parameter,
                                        plotId: selectPlot.plotId,
                                      }
                                    }
                                  />
                                  <CustomToggleButton
                                    sx={{
                                      width: '60px',
                                      height: '24px !important',
                                      marginLeft: '4px',
                                    }}
                                    variant="tool"
                                    fullWidth={true}
                                    selected={plotHasParameter}
                                    onClick={(): void => {
                                      const parameterWithPlotId: Parameter = {
                                        ...parameter,
                                        plotId: selectPlot.plotId,
                                        id: plotParameter?.id,
                                      };
                                      handleAddOrRemoveClick(
                                        parameterWithPlotId,
                                        plotHasParameter,
                                      );
                                    }}
                                  >
                                    {plotHasParameter ? 'Remove' : 'Add'}
                                  </CustomToggleButton>
                                </Box>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      })}
                    </MuiList>
                  </Collapse>
                </Box>
              );
            }}
          </List>
        )}
      </AutoSizer>
    </Box>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { WarningModuleStore } from '../types';
import { WarningFormState } from './reducer';

export const getPublicWarningStore = (
  store: WarningModuleStore,
): WarningFormState => {
  if (store && store.publicWarningForm) {
    return store.publicWarningForm;
  }
  return {};
};

export const getPublicWarningObject = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore.object,
);

export const getPublicWarning = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.warning,
);

export const getPublicWarningStatus = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.warning?.status,
);

export const getPublicWarningId = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.warning?.warningDetail.id,
);

export const getPublicWarningAreas = createSelector(
  getPublicWarningStore,
  (publicWarningStore) =>
    publicWarningStore?.warning?.warningDetail.areas || [],
);

export const getPublicWarningFormState = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.formState || '',
);

export const getPublicWarningFormError = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.error,
);

export const getSelectedWarningId = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.selectedWarningId || '',
);

export const getSelectedPublicIsFormDirty = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.isFormDirty || false,
);

export const getWarningType = createSelector(
  getPublicWarningStore,
  (publicWarningStore) => publicWarningStore?.warningType,
);

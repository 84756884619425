/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { MenuItem, Theme } from '@mui/material';
import {
  ReactHookFormSelect,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';
import { useWarningsTranslation } from '../../../utils/i18n';
import { FilterTranslation } from '../../../store/publicWarnings/types';

export interface ColorDefinition {
  color: string;
  textColor: string;
  borderColor: string;
}

export interface LevelDetails extends FilterTranslation, ColorDefinition {}

export enum WarningLevel {
  moderate = 'moderate',
  severe = 'severe',
  extreme = 'extreme',
}

export const LevelColors: Record<WarningLevel, ColorDefinition> = {
  moderate: { color: '#FAE21E', textColor: '#051039', borderColor: '#FBFE02' },
  severe: { color: '#E27000', textColor: '#051039', borderColor: '#FEAE02' },
  extreme: { color: '#D62A20', textColor: '#ffffff', borderColor: '#FF0000' },
};

export const getLevelStyle = (
  levelColor?: ColorDefinition,
): React.CSSProperties => ({
  color: levelColor?.textColor,
  backgroundColor: levelColor?.color,
  paddingLeft: levelColor ? '0.5em' : 0,
  paddingRight: '0.5em',
  paddingBottom: '1px',
  marginLeft: -'0.5em',
  border: levelColor ? `solid 1px ${levelColor.borderColor}` : 'none',
});

export const levelOptions: LevelDetails[] = [
  {
    translationKey: 'warning-level-moderate',
    key: WarningLevel.moderate,
    ...LevelColors[WarningLevel.moderate],
  },
  {
    translationKey: 'warning-level-severe',
    key: WarningLevel.severe,
    ...LevelColors[WarningLevel.severe],
  },
  {
    translationKey: 'warning-level-extreme',
    key: WarningLevel.extreme,
    ...LevelColors[WarningLevel.extreme],
  },
];

export const levelOptionsDict: Record<string, LevelDetails> =
  levelOptions.reduce(
    (list, item) => ({
      ...list,
      [item.key]: item,
    }),
    {},
  );

export const LevelField: React.FC<{
  isDisabled?: boolean;
  isReadOnly?: boolean;
}> = ({ isDisabled = false, isReadOnly = false }) => {
  const { t } = useWarningsTranslation();
  const { isRequired } = useDraftFormHelpers();
  const { watch, trigger } = useFormContext();

  const selectedLevel = watch('level');
  const levelDetails = levelOptions.find(
    (level) => level.key === selectedLevel,
  );

  const onChangeLevel = (): void => {
    void trigger(['level', 'probability']);
  };

  return (
    <ReactHookFormSelect
      name="level"
      label={t('warning-level')}
      rules={{ validate: { isRequired } }}
      sx={{
        '& .MuiSelect-select.MuiSelect-filled': {
          color: levelDetails?.textColor || 'none',
          backgroundColor: levelDetails?.color || 'none',
          backgroundClip: 'content-box',
        },
      }}
      disabled={isDisabled || isReadOnly}
      isReadOnly={isReadOnly}
      onChange={onChangeLevel}
    >
      {levelOptions.map((level) => (
        <MenuItem
          value={level.key}
          key={level.key}
          sx={{
            '&.MuiMenuItem-root ': {
              color: level.textColor,
              background: level.color,
              backgroundClip: 'content-box',
              padding: '12px 8px',
              '&:hover': {
                backgroundColor: `${level.color}`,
                backgroundClip: 'content-box',
                boxShadow: (theme: Theme): string =>
                  `inset -8px -12px ${theme.palette.geowebColors.cards.cardContainerMouseOver}, inset 8px 12px ${theme.palette.geowebColors.cards.cardContainerMouseOver}`,
              },
              '&.Mui-selected': {
                backgroundColor: `${level.color}`,
                '&:hover': {
                  boxShadow: (theme: Theme): string =>
                    `inset 4px 0px 0px 0px ${theme.palette.geowebColors.typographyAndIcons.iconLinkActive}, inset -8px -12px ${theme.palette.geowebColors.cards.cardContainerMouseOver}, inset 8px 12px ${theme.palette.geowebColors.cards.cardContainerMouseOver}`,
                },
              },
            },
          }}
        >
          &nbsp;{t(level.translationKey)}
        </MenuItem>
      ))}
    </ReactHookFormSelect>
  );
};

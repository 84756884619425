/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { ApiProvider, ApiModule } from '@opengeoweb/api';
import { API_NAME, createApi } from '../../utils/api';
import { createApi as createFakeApi } from '../../utils/fakeApi';

interface WorkspaceModuleProviderProps extends ApiModule {
  children: React.ReactNode;
}

export const WorkspaceModuleProvider: React.FC<
  WorkspaceModuleProviderProps
> = ({ config, auth, onSetAuth, children }: WorkspaceModuleProviderProps) => {
  return (
    <ApiProvider
      auth={auth}
      onSetAuth={onSetAuth}
      createApi={config!.baseURL ? createApi : createFakeApi}
      config={config}
      name={API_NAME}
    >
      {children}
    </ApiProvider>
  );
};
export default WorkspaceModuleProvider;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box } from '@mui/material';
import {
  AllChipConnect,
  ServiceChipConnect,
} from './ServiceChips/ServiceChipConnect';
import { ServiceFilterChipsObjectEntities } from '../../store/types';

interface TimeSeriesServiceListProps {
  filterChipsServicesById: ServiceFilterChipsObjectEntities;
  isAllSelected: boolean;
}

const TimeSeriesServiceList: React.FC<TimeSeriesServiceListProps> = ({
  filterChipsServicesById,
  isAllSelected,
}: TimeSeriesServiceListProps) => {
  const activeServices = Object.values(filterChipsServicesById);

  return (
    <Box
      sx={{
        marginTop: '8px',
        width: '100%',
        overflow: 'hidden',
      }}
      data-testid="serviceList"
    >
      <Box
        sx={{
          width: '100%',
          display: 'block',
          alignItems: 'center',
          float: 'left',
          marginBottom: '8px',
          fontSize: '0.875rem',
        }}
      >
        {activeServices?.length && (
          <AllChipConnect isAllSelected={isAllSelected} />
        )}
        {activeServices.map((service) => {
          return (
            <ServiceChipConnect
              key={service.serviceUrl}
              service={service}
              isAllSelected={isAllSelected}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default TimeSeriesServiceList;

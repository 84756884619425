/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { dateUtils } from '@opengeoweb/shared';
import { TafFromBackend, TimeSlot } from '../../../types';
import { getBaseTime, muiTabA11yProps } from '../utils';
import { DATE_FORMAT_BASE_TIME } from '../../../utils/dateFormats';
import { useTafTranslation } from '../../../utils/i18n';

interface TimeSlotTab {
  title: string;
  timeSlot: TimeSlot;
}

interface TopTabsProps {
  timeSlot: TimeSlot;
  tafFromBackend: TafFromBackend;
  onChangeTimeSlot?: (timeSlot: TimeSlot, isFormDirty: boolean) => void;
}

export const formatBaseTime = (time: Date): string =>
  dateUtils.dateToString(time, DATE_FORMAT_BASE_TIME)!;

const TopTabs: React.FC<TopTabsProps> = ({
  timeSlot,
  onChangeTimeSlot = (): void => {},
  tafFromBackend = {
    taf: {
      baseTime: '2022-01-06T12:00:00Z',
      location: 'EHAM',
    },
  },
}) => {
  const { t } = useTafTranslation();
  const {
    formState: { isDirty },
  } = useFormContext();

  if (!tafFromBackend) {
    return null;
  }

  const activeTab = timeSlot === 'UPCOMING' ? 0 : 1;
  const { currentBaseTime, nextBaseTime } = getBaseTime(
    tafFromBackend.taf.baseTime,
    tafFromBackend.taf.location,
  );

  const tabs: TimeSlotTab[] = [
    {
      title: `${t('top-tabs-upcoming')} ${formatBaseTime(nextBaseTime)}`,
      timeSlot: 'UPCOMING',
    },
    {
      title: `${t('top-tabs-current')} ${formatBaseTime(currentBaseTime)}`,
      timeSlot: 'ACTIVE',
    },
  ];

  const onChangeTab = (
    _event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    onChangeTimeSlot(tabs[newValue].timeSlot, isDirty);
  };

  return (
    <Tabs
      value={activeTab}
      onChange={onChangeTab}
      aria-label="timeslot-tabs"
      data-testid="timeslot-tabs"
      variant="fullWidth"
    >
      {tabs.map((tab, index) => (
        <Tab
          key={tab.title}
          label={tab.title}
          data-testid={`toptab-${index}`}
          sx={{ fontSize: 16 }}
          {...muiTabA11yProps(index)}
        />
      ))}
    </Tabs>
  );
};

export default TopTabs;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { LayersAdd } from '@opengeoweb/theme';
import { CustomIconButton } from '@opengeoweb/shared';
import { uiActions, uiSelectors, uiTypes } from '@opengeoweb/store';
import { useAppSelector } from '../../store';
import { useLayerSelectTranslation } from '../../utils/i18n';

const style = {
  width: 'auto !important',
  height: 'auto !important',
  padding: 0,
};

interface LayerSelectButtonConnectProps {
  mapId: string;
  source?: uiTypes.Source;
  tooltipTitle?: string;
  icon?: React.ReactNode;
  isMultiMap?: boolean;
}

export const LayerSelectButtonConnect: React.FC<
  LayerSelectButtonConnectProps
> = ({
  mapId,
  source = 'app',
  tooltipTitle,
  icon = <LayersAdd data-testid="layerSelectButtonConnectIcon" />,
  isMultiMap = false,
}: LayerSelectButtonConnectProps) => {
  const { t } = useLayerSelectTranslation();
  const dispatch = useDispatch();
  const dialogType = isMultiMap
    ? `${uiTypes.DialogTypes.LayerSelect}-${mapId}`
    : uiTypes.DialogTypes.LayerSelect;

  const currentActiveMapId = useAppSelector((store) =>
    uiSelectors.getDialogMapId(store, dialogType),
  );

  const isOpenInStore = useAppSelector((store) =>
    uiSelectors.getisDialogOpen(store, dialogType),
  );

  const openLayerSelectDialog = React.useCallback((): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: dialogType,
        mapId,
        setOpen: currentActiveMapId !== mapId ? true : !isOpenInStore,
        source,
      }),
    );
  }, [currentActiveMapId, dispatch, isOpenInStore, mapId, source, dialogType]);

  return (
    <CustomIconButton
      onClick={openLayerSelectDialog}
      sx={style}
      id="layerSelectButton"
      data-testid="layerSelectButton"
      tooltipTitle={tooltipTitle || t('layer-select-button-tooltip')}
    >
      {icon}
    </CustomIconButton>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  createApiInstance,
  CreateApiProps,
  createNonAuthApiInstance,
} from '@opengeoweb/api';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
  WorkspacePresetFromBE,
  WorkspacePresetListItem,
  ViewPresetListItem,
} from '../store/workspace/types';
import { FetchWorkspaceParams } from '../store/workspaceList/types';
import { ViewPreset } from '../store/viewPresets/types';
import { FetchViewPresetsListParams } from '../store/viewPresetsList/types';

export const API_NAME = 'workspacepresets';

export const getIdFromUrl = (url: string): string =>
  url && url.match(/[^/]+$/)![0];

export interface PresetsApi {
  // workspace presets
  getWorkspacePresets: (
    params?: FetchWorkspaceParams,
  ) => Promise<{ data: WorkspacePresetListItem[] }>;
  getWorkspacePreset: (
    workspacePresetId: string,
  ) => Promise<{ data: WorkspacePresetFromBE }>;
  saveWorkspacePreset: (
    workspacePresetId: string,
    data: WorkspacePresetFromBE,
  ) => Promise<void>;
  saveWorkspacePresetAs: (data: WorkspacePresetFromBE) => Promise<string>;
  deleteWorkspacePreset: (workspacePresetId: string) => Promise<void>;

  // view presets
  getViewPresets: (
    params?: FetchViewPresetsListParams,
  ) => Promise<{ data: ViewPresetListItem[] }>;
  getViewPreset: (viewPresetId: string) => Promise<{ data: ViewPreset }>;
  saveViewPreset: (viewPresetId: string, data: ViewPreset) => Promise<void>;
  saveViewPresetAs: (data: ViewPreset) => Promise<string>;
  deleteViewPreset: (viewPresetId: string) => Promise<void>;
}

const getApiRoutes = (axiosInstance: AxiosInstance): PresetsApi => ({
  // workspace presets
  getWorkspacePresets: (
    params?: FetchWorkspaceParams,
  ): Promise<{ data: WorkspacePresetListItem[] }> => {
    return axiosInstance.get('/workspacepreset', { params });
  },
  getWorkspacePreset: (
    workspacePresetId: string,
  ): Promise<{ data: WorkspacePresetFromBE }> => {
    return axiosInstance.get(`/workspacepreset/${workspacePresetId}`);
  },
  saveWorkspacePreset: (
    workspacePresetId: string,
    data: WorkspacePresetFromBE,
  ): Promise<void> => {
    return axiosInstance.put(`/workspacepreset/${workspacePresetId}/`, data);
  },
  saveWorkspacePresetAs: (data: WorkspacePresetFromBE): Promise<string> => {
    return axiosInstance
      .post(`/workspacepreset/`, data)
      .then((response: AxiosResponse): string => {
        const headerLocation = response?.request.getResponseHeader('Location');
        return getIdFromUrl(headerLocation);
      });
  },
  deleteWorkspacePreset: (workspacePresetId: string): Promise<void> => {
    return axiosInstance.delete(`/workspacepreset/${workspacePresetId}`);
  },

  // view presets
  getViewPresets: (
    params?: FetchViewPresetsListParams,
  ): Promise<{ data: ViewPresetListItem[] }> => {
    return axiosInstance.get('/viewpreset', { params });
  },
  getViewPreset: (viewPresetId: string): Promise<{ data: ViewPreset }> => {
    return axiosInstance.get(`/viewpreset/${viewPresetId}`);
  },
  saveViewPreset: (viewPresetId: string, data: ViewPreset): Promise<void> => {
    return axiosInstance.put(
      `/viewpreset/${viewPresetId}/`,
      data.initialProps === null ? { ...data, initialProps: {} } : data,
    );
  },
  saveViewPresetAs: (data: ViewPreset): Promise<string> => {
    return axiosInstance
      .post(
        `/viewpreset/`,
        data.initialProps === null ? { ...data, initialProps: {} } : data,
      )
      .then((response: AxiosResponse): string => {
        const headerLocation = response?.request.getResponseHeader('Location');
        return getIdFromUrl(headerLocation);
      });
  },
  deleteViewPreset: (viewPresetId: string): Promise<void> => {
    return axiosInstance.delete(`/viewpreset/${viewPresetId}`);
  },
});

export const createApi = (props: CreateApiProps): PresetsApi => {
  const authInstance = createApiInstance(props);
  const nonAuthInstance = createNonAuthApiInstance(props);
  return getApiRoutes(props.auth ? authInstance : nonAuthInstance);
};

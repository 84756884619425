/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { getWMJSMapById, getWMSRequests } from '@opengeoweb/webmap';
import { SetTimeSyncPayload } from '../../generic/synchronizationActions/types';

/* A map with all the timerIds and their current step */
const stepMap = new Map<string, number>();
/* A map with a list of timers and their dwell */
const timerDwellMap = new Map<string, number>();

/**
 * Returns the next step for given timerId.
 * @param timerId The timer id
 * @param numberOfStepsInAnimation Animation length in steps
 * @param numStepsToGoForward Amount of steps to go forwards, defaults to 1. Can be positive and negative
 * @returns
 */
export const getNextStep = (
  timerId: string,
  numberOfStepsInAnimation: number,
  numStepsToGoForward = 1,
): number => {
  const currentStep = getCurrentStep(timerId);
  const nextStep = currentStep + numStepsToGoForward;
  const nextStepClipped =
    ((nextStep % numberOfStepsInAnimation) + numberOfStepsInAnimation) %
    numberOfStepsInAnimation;
  return nextStepClipped;
};

/**
 * Handles dwell at the end of the animation loop sequence. The number of steps to wait till proceed can be set by the dwell parameter.
 * @param timerId The timer id
 * @param numberOfStepsInAnimation Number of steps in the animation
 * @param dwell The number of steps to wait at the end of the animation sequence before to continue
 * @returns
 */
export const handleTimerDwell = (
  timerId: string,
  numberOfStepsInAnimation: number,
  dwell = 8,
): boolean => {
  if (dwell > 0) {
    const currentStep = getCurrentStep(timerId);
    // Reset the dwell if we are not at the last animation step
    if (currentStep < numberOfStepsInAnimation - 1) {
      timerDwellMap.set(timerId, dwell);
      return false;
    }

    // We are at the last animation step, check the dwell
    const timerDwell =
      (timerDwellMap.has(timerId) && timerDwellMap.get(timerId)) || 0;

    if (currentStep === numberOfStepsInAnimation - 1 && timerDwell > 0) {
      timerDwellMap.set(timerId, timerDwell - 1);
      return true;
    }
  }
  return false;
};

/**
 * Set step for the timerId
 * @param timerId
 * @param timerStep
 */
export const setStep = (timerId: string, timerStep: number): void => {
  stepMap.set(timerId, timerStep);
};

/**
 * Gets the current step for the timer
 * @param timerId
 * @returns
 */
export const getCurrentStep = (timerId: string): number => {
  return stepMap.get(timerId) || 0;
};

export const MAX_NUMBER_STEPS_FORWARD_TO_PREFETCH = 2;
const MAX_NUMBER_OF_PARALLEL_LOADING_IMAGES = 8;

/**
 * This prefetches all images connected to the same sync group as provided timerId
 * @param timerId The timerId
 * @param animationListValues List of animation steps in isostring to animate
 * @param targets List of targets to check
 * @returns True if all maps are ready to go forward, false if the map has no data to display yet.
 */
export const prefetchAnimationTargetsForMetronome = (
  timerId: string,
  animationListValues: string[],
  targets: SetTimeSyncPayload[],
): boolean => {
  let timerShouldStepForward = true;

  // The following code prefetches/buffers for all maps in the group
  for (
    let numPrefetch = 0;
    numPrefetch < MAX_NUMBER_STEPS_FORWARD_TO_PREFETCH;
    numPrefetch += 1
  ) {
    const nextStep = getNextStep(
      timerId,
      animationListValues.length,
      numPrefetch + 1,
    );
    const nextTimeValueStepToCheck: string = animationListValues[nextStep];
    for (const target of targets) {
      const targetMapId = target.targetId;
      const wmMap = getWMJSMapById(targetMapId);
      if (!wmMap) {
        return true; // Map was not registered so there is nothing to prefetch, do not block going forward
      }
      const layersImageUrls = getWMSRequests(wmMap, [
        {
          name: 'time',
          currentValue: nextTimeValueStepToCheck,
        },
      ]);
      for (const layersImageUrl of layersImageUrls) {
        const image = wmMap.getMapImageStore.getImage(layersImageUrl.url);

        if (!image.isLoaded()) {
          if (
            wmMap.getMapImageStore.getNumImagesLoading() <
            MAX_NUMBER_OF_PARALLEL_LOADING_IMAGES
          ) {
            image.load();
          }
          if (numPrefetch === 0) {
            const altImage = wmMap.getAlternativeImage(
              layersImageUrl.url,
              wmMap.getBBOX(),
              true,
            );
            // No alternative image available yet, so skipping animation.
            if (altImage.length === 0) {
              // This is useful to indicate that the map is loading and has nothing yet to display.
              // console.warn('No data available: Not stepping forward');
              timerShouldStepForward = false;
            }
          }
        } else if (image.isStale()) {
          if (
            wmMap.getMapImageStore.getNumImagesLoading() <
            MAX_NUMBER_OF_PARALLEL_LOADING_IMAGES
          ) {
            image.forceReload(true);
          }
        }
      }
    }
  }
  return timerShouldStepForward;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { CanvasComponent, dateUtils } from '@opengeoweb/shared';
import React from 'react';

export interface TimeBounds {
  startTime: number;
  endTime: number;
}

export enum Scale {
  Minutes5,
  Hour,
  Hours3,
  Hours6,
  Day,
  Week,
  Weeks2,
  Month,
  Months3,
  Year,
}

export enum AnimationLength {
  Minutes15 = 15,
  Minutes30 = 30,
  Hours1 = 60,
  Hours2 = 2 * 60,
  Hours3 = 3 * 60,
  Hours6 = 6 * 60,
  Hours12 = 12 * 60,
  Hours24 = 24 * 60,
}

export type SpeedFactorType = 0.1 | 0.2 | 0.5 | 1 | 2 | 4 | 8 | 16;

/**
 * In this Map collection all fundamental scales
 * (Scale.Minutes5 ... Scale.Year) are mapped with their corresponding
 * secondsPerPx numbers.
 * @returns a Map including information explained above
 */

export const secondsPerPxToScale = new Map<number, Scale>([
  [2.5, Scale.Minutes5],
  [30, Scale.Hour],
  [3 * 30, Scale.Hours3],
  [6 * 30, Scale.Hours6],
  [24 * 30, Scale.Day],
  [7 * 24 * 30, Scale.Week],
  [14 * 24 * 30, Scale.Weeks2],
  [30 * 24 * 30, Scale.Month],
  [90 * 24 * 30, Scale.Months3],
  [365 * 24 * 30, Scale.Year],
]);

export const spanToScale = new Map<number, Scale>([
  [5 * 60, Scale.Minutes5],
  [60 * 60, Scale.Hour],
  [3 * 60 * 60, Scale.Hours3],
  [6 * 60 * 60, Scale.Hours6],
  [24 * 60 * 60, Scale.Day],
  [7 * 24 * 60 * 60, Scale.Week],
  [14 * 24 * 60 * 60, Scale.Weeks2],
  [30 * 24 * 60 * 60, Scale.Month],
  [90 * 24 * 60 * 60, Scale.Months3],
  [365 * 24 * 60 * 60, Scale.Year],
]);

/**
 * Creates a reverse mapping of scales to their corresponding secondsPerPx
 * values to make it easier in some parts GeoWeb to fetch a secondsPerpx for
 * a certain fundamental scale (Scale.Minutes5 ... Scale.year)
 * @returns an Object, where names are fundamental scale values (0 - 7) and
 * values are their corresponding secondsPerPx numbers.
 */

export const scaleToSecondsPerPx: {
  [x in Scale]: number;
} = Object.fromEntries(
  Array.from(secondsPerPxToScale.entries()).map(([k, v]) => [v, k]),
) as { [x in Scale]: number };

export const scalingCoefficient = (
  end: number,
  start: number,
  width: number,
): number => width / (end - start);

export const timestampToPixelEdges = (
  timestamp: number,
  start: number,
  end: number,
  width: number,
): number => (timestamp - start) * scalingCoefficient(end, start, width);

export const timestampToPixel = (
  timestamp: number,
  centerTime: number,
  widthPx: number,
  secondsPerPx: number,
): number => widthPx / 2 - (centerTime - timestamp) / secondsPerPx;

export const pixelToTimestamp = (
  timePx: number,
  centerTime: number,
  widthPx: number,
  secondsPerPx: number,
): number => centerTime - (widthPx / 2 - timePx) * secondsPerPx;

/**
 * This function creates and returns an array where there is secondsPerPx
 * number of the data set added for a data scale (secondsPerPx)
 * to secondsPerPxToScale.
 * @param secondsPerPx
 * @returns an array containing secondsPerPx numbers.
 * The secondsPerPx number for a data scale is included
 * contrary to secondsPerPxToScale.
 */

export const secondsPerPxValues = (secondsPerPx: number): number[] => {
  const sortedSecondsPerPx = [...secondsPerPxToScale].map(
    (value: [number, Scale]) => Number(value[0]),
  );
  const full = [...sortedSecondsPerPx, secondsPerPx];
  return full;
};

export const secondsPerPxFromCanvasWidth = (
  canvasWidth: number,
  spanInSeconds: number,
): number | undefined => {
  if (canvasWidth <= 0 || spanInSeconds <= 0) {
    return undefined;
  }
  return spanInSeconds / canvasWidth;
};

export const getNewCenterOfFixedPointZoom = (
  fixedTimePoint: number,
  oldSecondsPerPx: number,
  newSecondsPerPx: number,
  oldCenterTime: number,
): number => {
  const centerToFixedPointPx =
    (fixedTimePoint - oldCenterTime) / oldSecondsPerPx;
  return fixedTimePoint - centerToFixedPointPx * newSecondsPerPx;
};

/**
 * Move the time slider such that a given time point (timePoint)
 * is at a given pixel width (timePointPx).
 * @returns a new center time for the resulting position
 */
export const moveRelativeToTimePoint = (
  timePoint: number,
  timePointPx: number,
  secondsPerPx: number,
  canvasWidth: number,
): number => timePoint - secondsPerPx * (timePointPx - canvasWidth / 2);

/** This reusable custom hook tells whether given pointer event targets current canvas node.
 * Example:
 *
 * const [isAllowedCanvasNodePointed, nodeRef] = useCanvasTarget('mousedown', false);
 *  ...
 * return isAllowedCanvasNodePointed ? (<CanvasComponent ref={nodeRef}>Component with ref</CanvasComponent>) : (<CanvasComponent>Component with NO ref</CanvasComponent>);
 *  ...
 */

export const useCanvasTarget = (
  eventType: string,
): [boolean, React.RefObject<CanvasComponent>] => {
  const nodeRef = React.useRef<CanvasComponent>(null);
  const [isTargetNode, setTargetNode] = React.useState(false);

  // Check if pointer event targets current node element
  const pointerEventListener = React.useCallback(
    (event: PointerEvent) => {
      setTargetNode(
        nodeRef.current!.canvas
          ? nodeRef.current!.canvas.isEqualNode(event.target as Node)
          : false,
      );
    },
    [nodeRef],
  ) as EventListener;

  React.useEffect(() => {
    document.addEventListener(
      eventType as keyof DocumentEventMap,
      pointerEventListener,
    );
    return (): void =>
      document.removeEventListener(
        eventType as keyof DocumentEventMap,
        pointerEventListener,
      );
  }, [eventType, pointerEventListener]); // Only add/remove event listener when listener really changes, that is, NOT necessarily between every re-render.

  return [isTargetNode, nodeRef];
};

export const timeBoxGeom = {
  smallWidth: 126,
  largeWidth: 167,
  height: 24,
  cornerRadius: 5,
  lineWidth: 1,
  iconWidth: 25,
  calendarWidth: 20,
};

export const AUTO_MOVE_AREA_PADDING = 1;
export const getAutoMoveAreaWidth = (scale: Scale): number =>
  (scale === Scale.Year
    ? timeBoxGeom.largeWidth + timeBoxGeom.iconWidth
    : timeBoxGeom.smallWidth + timeBoxGeom.iconWidth) /
    2 +
  AUTO_MOVE_AREA_PADDING;

export enum TimeInMinutes {
  YEAR = 525600,
  MONTH = 43200,
  WEEK = 10080,
  DAY = 1440,
  HOUR = 60,
  MINUTE = 1,
}

/**
 *
 * @param minutes
 * @returns the two highest values in the format yr(s) mo(s) d h m
 */
export const minutesToDescribedDuration = (minutes: number): string => {
  const units = [
    { label: 'yr', value: TimeInMinutes.YEAR, plural: 's' },
    { label: 'mo', value: TimeInMinutes.MONTH, plural: 's' },
    { label: 'd', value: TimeInMinutes.DAY },
    { label: 'h', value: TimeInMinutes.HOUR },
    { label: 'm', value: TimeInMinutes.MINUTE },
  ];
  const durations = units.reduce(
    (units, duration) => {
      const { min, time, count } = units;
      const { label, value, plural = '' } = duration;
      const unit = Math.floor(min / value);

      if (unit > 0 && count < 2) {
        return {
          time: `${time}${unit}${label}${unit > 1 ? plural : ''} `,
          min: min - unit * value,
          count: count + 1,
        };
      }

      return { min, time, count };
    },
    {
      min: minutes,
      time: '',
      count: 0,
    },
  );

  return durations.time.trim();
};

/**
 * Returns the largest timeunit value divisible by minuts
 * @param minutes
 * @returns
 */
export function getLargestTimeUnit(minutes: number): number {
  const values = Object.values(TimeInMinutes).filter(
    (value) => typeof value === 'number',
  ) as number[];

  for (const value of values) {
    if (minutes >= value) {
      return value;
    }
  }

  return TimeInMinutes.MINUTE;
}

// For a data scale an appropriate fundamental scale is chosen
// depending on secondsPerPx of data. When a time range of
// data is for example many months. the fundamental scale Scale.Year
// is chosen here and the legend of the time slider is drawn in the
// same as if otiginally a year scale would have been chose.
//
// If a  time range of data is a few weeks, Scale.Month is chosen. So, all
// the time range of data can be shown in the window.

export const getFundamentalScale = (secondsPerPx: number): Scale => {
  if (secondsPerPx >= scaleToSecondsPerPx[Scale.Month]) {
    return Scale.Year;
  }
  if (secondsPerPx >= scaleToSecondsPerPx[Scale.Week]) {
    return Scale.Month;
  }
  if (secondsPerPx >= scaleToSecondsPerPx[Scale.Day]) {
    return Scale.Week;
  }
  if (secondsPerPx >= scaleToSecondsPerPx[Scale.Hours6]) {
    return Scale.Day;
  }
  if (secondsPerPx >= scaleToSecondsPerPx[Scale.Hours3]) {
    return Scale.Hours6;
  }
  if (secondsPerPx >= scaleToSecondsPerPx[Scale.Hour]) {
    return Scale.Hours3;
  }
  if (secondsPerPx >= scaleToSecondsPerPx[Scale.Minutes5]) {
    return Scale.Hour;
  }
  return Scale.Minutes5;
};

export const getScaleFromSpan = (span: number): Scale => {
  const sortedScales = [...spanToScale.entries()].sort(([a], [b]) => b - a);
  for (const [scaleSpan, scale] of sortedScales) {
    if (span >= scaleSpan) {
      return scale;
    }
  }
  return sortedScales[0][1];
};

export const calculateMouseTimeAndCheckBounds = (
  x: number,
  startAnimationPosition: number,
  endAnimationPosition: number,
  DRAG_AREA_WIDTH: number,
  pixelsBetweenViewportAndTimesliderOnLeft: number | undefined,
  centerTime: number,
  canvasWidth: number,
  secondsPerPx: number,
  tooltipPosition: React.MutableRefObject<number | undefined>,
): number | undefined => {
  const mousePosition = startAnimationPosition + x;
  const startAnimationMaxPosition = endAnimationPosition - DRAG_AREA_WIDTH * 2;
  const isDraggingWithinBounds =
    mousePosition >= startAnimationMaxPosition || mousePosition <= 0;

  if (isDraggingWithinBounds) {
    return undefined;
  }

  // eslint-disable-next-line no-param-reassign
  tooltipPosition.current =
    pixelsBetweenViewportAndTimesliderOnLeft &&
    mousePosition + pixelsBetweenViewportAndTimesliderOnLeft;

  return pixelToTimestamp(mousePosition, centerTime, canvasWidth, secondsPerPx);
};
class MockDOMRect {
  x: number;
  y: number;
  width: number;
  height: number;
  constructor(x: number, y: number, width: number, height: number) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const setTooltipPosition = (tooltipX: number, tooltipY: number) => {
  return new MockDOMRect(tooltipX, tooltipY, 0, 0);
};

// default values
export const defaultAnimationDelayAtStart = 250;
export const defaultDelay = 1000; // [ms]
export const defaultTimeStep = 60;
export const defaultSecondsPerPx = 80;
export const defaultTimeSpan = 24 * 3600;
export const speedFactors = [
  0.1, 0.2, 0.5, 1, 2, 4, 8, 16,
] as SpeedFactorType[]; // Declares available animation speed multipliers for default delay

export const roundWithTimeStep = (
  unixTime: number,
  timeStep: number,
  type?: string,
): number => {
  const adjustedTimeStep = timeStep * 60;
  if (!type || type === 'round') {
    return Math.round(unixTime / adjustedTimeStep) * adjustedTimeStep;
  }
  if (type === 'floor') {
    return Math.floor(unixTime / adjustedTimeStep) * adjustedTimeStep;
  }
  if (type === 'ceil') {
    return Math.ceil(unixTime / adjustedTimeStep) * adjustedTimeStep;
  }
  return undefined!;
};

/**
 * Returns speed delay for given speedFactor. For options, see defined above in "speedFactors"
 */

export const getSpeedDelay = (speedFactor: SpeedFactorType): number => {
  return defaultDelay / speedFactor;
};

export const timeSliderLegendDefaultProps = {
  timeSliderWidth: 100,
  centerTime: dateUtils.unix(
    dateUtils.set(dateUtils.utc(), {
      hours: 12,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }),
  ),
  secondsPerPx: defaultSecondsPerPx,
  selectedTime: dateUtils.unix(
    dateUtils.set(dateUtils.utc(), {
      hours: 12,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    }),
  ),
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { getAxiosErrorMessage, isAxiosAuthError } from '@opengeoweb/shared';
import { AxiosError, isAxiosError } from 'axios';
import { snackbarActions } from '@opengeoweb/snackbar';
import {
  MapPresetInitialProps,
  mapSelectors,
  CoreAppStore,
  mapActions,
} from '@opengeoweb/store';
import { viewPresetActions } from './reducer';
import { WorkspaceModuleStore } from '../types';
import { getWorkspaceApi } from '../workspace/utils';
import { WorkspaceViewType } from '../workspace/types';
import { PresetsApi } from '../../utils/api';
import * as mapPresetSelectors from './selectors';

import {
  PresetAction,
  SaveViewPresetPayload,
  ViewPreset,
  ViewPresetError,
  ViewPresetErrorComponent,
  ViewPresetErrorType,
} from './types';
import { emptyViewPreset, getSnackbarMessage } from './utils';
import { workspaceActions } from '../workspace/reducer';
import { viewPresetSelectors } from '.';
import { constructFilterParams } from '../workspaceList/utils';

const processError = (
  error: AxiosError | Error,
  component: ViewPresetErrorComponent,
  errorMessageKey: string,
): ViewPresetError => {
  const message = isAxiosError(error)
    ? getAxiosErrorMessage(error)
    : errorMessageKey;
  const errorType = isAxiosAuthError(error)
    ? ViewPresetErrorType.AUTH
    : ViewPresetErrorType.GENERIC;
  return {
    message,
    component,
    errorType,
  };
};

export const viewPresetsListener =
  createListenerMiddleware<WorkspaceModuleStore>();

// fetch view presets
viewPresetsListener.startListening({
  actionCreator: viewPresetActions.fetchViewPresets,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { panelId, filterParams } = payload;

    try {
      const workspaceApi: PresetsApi = getWorkspaceApi();
      const { data: viewPresets } =
        await workspaceApi.getViewPresets(filterParams);

      listenerApi.dispatch(
        viewPresetActions.fetchedViewPresets({
          viewPresets,
          panelId,
          filterParams,
        }),
      );
    } catch (error) {
      listenerApi.dispatch(
        viewPresetActions.errorViewPreset({
          error: processError(
            error as Error,
            ViewPresetErrorComponent.PRESET_LIST,
            'workspace-mappreset-error-fetch',
          ),
          panelId,
        }),
      );
    }
  },
});

export const saveViewPresetAsEffect = async (
  { payload }: PayloadAction<SaveViewPresetPayload>,
  listenerApi: ListenerEffectAPI<
    WorkspaceModuleStore,
    ThunkDispatch<WorkspaceModuleStore, unknown, AnyAction>
  >,
): Promise<ViewPreset | Error | void> => {
  listenerApi.cancelActiveListeners();

  const { panelId, viewPreset } = payload;

  try {
    const workspaceApi: PresetsApi = getWorkspaceApi();
    const viewPresetId = await workspaceApi.saveViewPresetAs(viewPreset);
    listenerApi.dispatch(
      snackbarActions.openSnackbar(
        getSnackbarMessage(PresetAction.SAVE, viewPreset.title),
      ),
    );
    listenerApi.dispatch(
      viewPresetActions.savedViewPreset({ panelId, viewPresetId }),
    );

    listenerApi.dispatch(
      viewPresetActions.fetchedViewPreset({
        panelId,
        viewPresetId,
        viewPreset,
      }),
    );

    return {
      ...viewPreset,
      id: viewPresetId,
    };
  } catch (error) {
    listenerApi.dispatch(
      viewPresetActions.errorViewPreset({
        error: processError(
          error as Error,
          ViewPresetErrorComponent.PRESET_DETAIL,
          'workspace-mappreset-error-save',
        ),
        panelId,
      }),
    );
    return error as Error;
  }
};

export const saveViewPresetEffect = async (
  { payload }: PayloadAction<SaveViewPresetPayload>,
  listenerApi: ListenerEffectAPI<
    WorkspaceModuleStore,
    ThunkDispatch<WorkspaceModuleStore, unknown, AnyAction>
  >,
): Promise<ViewPreset | Error | void> => {
  listenerApi.cancelActiveListeners();

  const { panelId, viewPreset, viewPresetId } = payload;

  try {
    const workspaceApi: PresetsApi = getWorkspaceApi();
    await workspaceApi.saveViewPreset(viewPresetId, viewPreset);
    listenerApi.dispatch(
      snackbarActions.openSnackbar(
        getSnackbarMessage(PresetAction.SAVE, viewPreset.title),
      ),
    );
    listenerApi.dispatch(
      viewPresetActions.savedViewPreset({ panelId, viewPresetId }),
    );
    listenerApi.dispatch(
      viewPresetActions.fetchedViewPreset({
        panelId,
        viewPresetId,
        viewPreset,
      }),
    );

    return viewPreset;
  } catch (error) {
    listenerApi.dispatch(
      viewPresetActions.errorViewPreset({
        error: processError(
          error as Error,
          ViewPresetErrorComponent.PRESET_DETAIL,
          'workspace-mappreset-error-save',
        ),
        panelId,
        viewPresetId,
      }),
    );
    return error as Error;
  }
};

// save view preset
viewPresetsListener.startListening({
  actionCreator: viewPresetActions.saveViewPreset,
  effect: saveViewPresetEffect as () => Promise<void>,
});

// select view preset
viewPresetsListener.startListening({
  actionCreator: viewPresetActions.selectViewPreset,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { panelId, viewPresetId = '' } = payload;
    const isMapAnimating = mapSelectors.isAnimating(
      listenerApi.getState() as CoreAppStore,
      panelId,
    );

    if (isMapAnimating) {
      listenerApi.dispatch(mapActions.mapStopAnimation({ mapId: panelId }));
    }

    // fetch existing map preset selected
    if (viewPresetId !== '') {
      listenerApi.dispatch(
        workspaceActions.fetchWorkspaceViewPreset({
          mosaicNodeId: panelId,
          viewPresetId,
        }),
      );
    } else {
      // new map preset selected
      listenerApi.dispatch(
        mapActions.setMapPreset({
          mapId: panelId,
          initialProps: emptyViewPreset('Map')
            .initialProps as MapPresetInitialProps,
        }),
      );

      // update workspace with new empy view map
      listenerApi.dispatch(
        workspaceActions.updateWorkspaceView({
          viewPreset: emptyViewPreset('Map') as WorkspaceViewType,
          mosaicNodeId: panelId,
        }),
      );
      listenerApi.dispatch(
        viewPresetActions.fetchedViewPreset({
          panelId,
          viewPresetId,
          viewPreset: emptyViewPreset('Map'),
        }),
      );
    }
  },
});

// success view preset
viewPresetsListener.startListening({
  actionCreator: viewPresetActions.onSuccessViewPresetAction,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { action, viewPresetId, title, panelId } = payload;

    try {
      listenerApi.dispatch(
        snackbarActions.openSnackbar(getSnackbarMessage(action, title)),
      );

      const activeMapPresetId = mapPresetSelectors.getViewPresetActiveId(
        listenerApi.getState(),
        panelId,
      );

      let hasChanges = false;

      // if active preset has been deleted, select the empty new preset
      if (
        action === PresetAction.DELETE &&
        viewPresetId === activeMapPresetId
      ) {
        listenerApi.dispatch(
          viewPresetActions.setActiveViewPresetId({
            panelId,
            viewPresetId: '',
          }),
        );

        hasChanges = true;
      }

      // Retrieve new list with current filter and search query
      const viewPresetFilters =
        viewPresetSelectors.getViewPresetListFiltersForView(
          listenerApi.getState(),
          panelId,
        );
      // Retrieve new list with current filter
      const viewPresetSearchQuery =
        viewPresetSelectors.getViewPresetListSearchQueryForView(
          listenerApi.getState(),
          panelId,
        );

      listenerApi.dispatch(
        viewPresetActions.fetchViewPresets({
          panelId,
          filterParams: constructFilterParams(
            viewPresetFilters,
            viewPresetSearchQuery,
          ),
        }),
      );

      if (action !== PresetAction.EDIT) {
        listenerApi.dispatch(
          viewPresetActions.setViewPresetHasChanges({
            panelId,
            hasChanges,
          }),
        );
      }

      if (action === PresetAction.SAVE_AS && viewPresetId) {
        listenerApi.dispatch(
          viewPresetActions.setActiveViewPresetId({
            panelId,
            viewPresetId,
          }),
        );
      }
    } catch (error) {
      // TODO: use error handling of mapPreset https://gitlab.com/opengeoweb/opengeoweb/-/issues/3043
    }
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/system';
import { CustomTooltip } from '@opengeoweb/shared';
import {
  layerSelectors,
  mapActions,
  mapSelectors,
  layerTypes,
  CoreAppStore,
  mapEnums,
} from '@opengeoweb/store';
import { defaultTimeSpan } from '@opengeoweb/timeslider';
import { useCoreTranslation } from '../../../../../utils/i18n';
import ActivateLayer, { AutoOptions } from './ActivateLayer';

interface ActivateLayerProps {
  layerId: string;
  mapId: string;
}

const ActivateLayerConnect: React.FC<ActivateLayerProps> = ({
  layerId,
  mapId,
}: ActivateLayerProps) => {
  const { t } = useCoreTranslation();
  const dispatch = useDispatch();

  const autoUpdateLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getAutoUpdateLayerId(store, mapId),
  );
  const autoTimestepLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getAutoTimeStepLayerId(store, mapId),
  );

  const isLayerEnabled = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerEnabled(store, layerId),
  );

  const isAutoUpdating = useSelector((store: CoreAppStore) =>
    mapSelectors.isAutoUpdating(store, mapId),
  );

  const isTimestepAuto = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimestepAuto(store, mapId),
  );

  const isTimeSpanAuto = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimeSpanAuto(store, mapId),
  );

  const goToLastAvailableTimeForLayerIfAutoUpdateIsOn = (): void => {
    if (isAutoUpdating) {
      dispatch(
        mapActions.toggleAutoUpdate({
          mapId,
          shouldAutoUpdate: true,
        }),
      );
    }
  };

  const thisLayerHasAutoUpdate = layerId === autoUpdateLayerId;
  const thisLayerHasAutoTimestep = layerId === autoTimestepLayerId;
  const thisLayerHasBoth = thisLayerHasAutoTimestep && thisLayerHasAutoUpdate;

  function handleNoneClick(): void {
    if (thisLayerHasBoth) {
      dispatch(
        mapActions.setAutoLayerId({
          autoTimeStepLayerId: undefined,
          autoUpdateLayerId: undefined,
          mapId,
          origin: layerTypes.LayerActionOrigin.layerManager,
        }),
      );
      if (isTimestepAuto) {
        dispatch(
          mapActions.toggleTimestepAuto({
            mapId,
            timestepAuto: !isTimestepAuto,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
      }
      if (isTimeSpanAuto) {
        dispatch(
          mapActions.toggleTimeSpanAuto({
            mapId,
            timeSpanAuto: !isTimeSpanAuto,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
        dispatch(
          mapActions.setTimeSliderSpan({
            mapId,
            timeSliderSpan: defaultTimeSpan,
          }),
        );
      }
    } else if (thisLayerHasAutoTimestep) {
      dispatch(
        mapActions.setAutoTimestepLayerId({
          mapId,
          autoTimestepLayerId: undefined,
        }),
      );
      if (isTimestepAuto) {
        dispatch(
          mapActions.toggleTimestepAuto({
            mapId,
            timestepAuto: !isTimestepAuto,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
      }
      if (isTimeSpanAuto) {
        dispatch(
          mapActions.toggleTimeSpanAuto({
            mapId,
            timeSpanAuto: !isTimeSpanAuto,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
        dispatch(
          mapActions.setTimeSliderSpan({
            mapId,
            timeSliderSpan: defaultTimeSpan,
          }),
        );
      }
    } else if (thisLayerHasAutoUpdate) {
      dispatch(
        mapActions.setAutoUpdateLayerId({
          mapId,
          autoUpdateLayerId: undefined,
        }),
      );
    }
  }

  function handleBothClick(): void {
    dispatch(
      mapActions.setAutoLayerId({
        autoTimeStepLayerId: layerId,
        autoUpdateLayerId: layerId,
        mapId,
        origin: layerTypes.LayerActionOrigin.layerManager,
      }),
    );
    goToLastAvailableTimeForLayerIfAutoUpdateIsOn();
  }

  function handleAutoUpdateClick(): void {
    dispatch(
      mapActions.setAutoUpdateLayerId({
        mapId,
        autoUpdateLayerId: layerId,
      }),
    );
    if (thisLayerHasAutoTimestep) {
      dispatch(
        mapActions.setAutoTimestepLayerId({
          mapId,
          autoTimestepLayerId: undefined,
        }),
      );
      if (isTimestepAuto) {
        dispatch(
          mapActions.toggleTimestepAuto({
            mapId,
            timestepAuto: !isTimestepAuto,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
      }
      if (isTimeSpanAuto) {
        dispatch(
          mapActions.toggleTimeSpanAuto({
            mapId,
            timeSpanAuto: !isTimeSpanAuto,
            origin: mapEnums.MapActionOrigin.map,
          }),
        );
        dispatch(
          mapActions.setTimeSliderSpan({
            mapId,
            timeSliderSpan: defaultTimeSpan,
          }),
        );
      }
    }
    goToLastAvailableTimeForLayerIfAutoUpdateIsOn();
  }

  function handleAutoTimestepClick(): void {
    dispatch(
      mapActions.setAutoTimestepLayerId({
        mapId,
        autoTimestepLayerId: layerId,
      }),
    );
    if (thisLayerHasAutoUpdate) {
      dispatch(
        mapActions.setAutoUpdateLayerId({
          mapId,
          autoUpdateLayerId: undefined,
        }),
      );
    }
  }

  const current = getCurrentOptionForLayer(
    thisLayerHasBoth,
    thisLayerHasAutoTimestep,
    thisLayerHasAutoUpdate,
  );

  const layerHasTimeDimension = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerHasTimeDimension(store, layerId),
  );

  if (!layerHasTimeDimension) {
    return (
      <CustomTooltip title={t('layermanager-dimensions-none')} placement="top">
        <Box data-testid="noTimeDimension" sx={{ width: 24, height: 24 }} />
      </CustomTooltip>
    );
  }
  return (
    <ActivateLayer
      current={current}
      onChange={(autoOption: AutoOptions): void => {
        if (autoOption === AutoOptions.BOTH) {
          handleBothClick();
        } else if (autoOption === AutoOptions.NONE) {
          handleNoneClick();
        } else if (autoOption === AutoOptions.TIMESTEP) {
          handleAutoTimestepClick();
        } else if (autoOption === AutoOptions.UPDATE) {
          handleAutoUpdateClick();
        }
      }}
      isEnabled={isLayerEnabled}
    />
  );
};

/**
 * Activates a maplayer from the store
 *
 * Expects the following props:
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} layerId layerId: string - Id of the layer that is activated
 * @example
 * ``` <ActivateLayerConnect mapId={mapId} layerId={layerId} />```
 */
export default ActivateLayerConnect;

function getCurrentOptionForLayer(
  thisLayerHasBoth: boolean,
  thisLayerHasAutoTimestep: boolean,
  thisLayerHasAutoUpdate: boolean,
): AutoOptions {
  if (thisLayerHasBoth) {
    return AutoOptions.BOTH;
  }
  if (thisLayerHasAutoTimestep) {
    return AutoOptions.TIMESTEP;
  }
  if (thisLayerHasAutoUpdate) {
    return AutoOptions.UPDATE;
  }
  return AutoOptions.NONE;
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import {
  Backdrop,
  Grid2 as Grid,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';

import { ToolContainerDraggable } from '../ToolContainer';
import { ErrorBoundary } from '../ErrorBoundary';
import { SHARED_NAMESPACE } from '../../utils/i18n';

interface UserMenuSentryRecordingProps {
  recordButtonEnabled?: boolean;
  setRecordButtonEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserMenuItemSentryRecording: React.FC<UserMenuSentryRecordingProps> = ({
  recordButtonEnabled,
  setRecordButtonEnabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const [canvasIntegrationEnabled, setCanvasIntegrationEnabled] =
    React.useState(false);
  const [htmlRecordingEnabled, setHTMLRecordingEnabled] = React.useState(false);
  const { t } = useTranslation(SHARED_NAMESPACE);
  const onClose = (): void => {
    setOpen(false);
  };
  const onOpen = (): void => {
    setOpen(true);
  };

  const enableHTMLRecording = async (): Promise<void> => {
    if (recordButtonEnabled) {
      return;
    }
    if (canvasIntegrationEnabled) {
      return;
    }
    try {
      const { replayIntegration } = await import('@sentry/react');
      Sentry.addIntegration(
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      );
      setRecordButtonEnabled && setRecordButtonEnabled(!recordButtonEnabled);
      setHTMLRecordingEnabled(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const enableHTMLAndCanvasRecording = async (): Promise<void> => {
    if (recordButtonEnabled) {
      return;
    }
    try {
      const { replayIntegration, replayCanvasIntegration } = await import(
        '@sentry/react'
      );
      Sentry.addIntegration(
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      );
      Sentry.addIntegration(replayCanvasIntegration());
      setCanvasIntegrationEnabled(!canvasIntegrationEnabled);
      setRecordButtonEnabled && setRecordButtonEnabled(!recordButtonEnabled);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <ErrorBoundary>
      <MenuItem data-testid="sentryRecording-btn" onClick={onOpen} divider>
        <Grid container>
          <Grid>{t('shared-sentry-recording')}</Grid>
        </Grid>
      </MenuItem>
      <Backdrop
        data-testid="sentryRecording-content"
        open={open}
        sx={{
          zIndex: 1,
        }}
      >
        <ToolContainerDraggable
          startSize={{ width: 700 }}
          minHeight={300}
          minWidth={500}
          title={t('shared-sentry-recording')}
          isOpen={true}
          onClose={onClose}
          bounds="parent"
        >
          <Grid
            container
            spacing={4}
            sx={{ paddingLeft: '70px', paddingRight: '70px' }}
          >
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: 'center' }}
            >
              <Typography>{t('shared-sentry-information')}</Typography>
            </Grid>

            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: 'center' }}
            >
              <Typography>{t('shared-sentry-enable-message')}</Typography>
            </Grid>

            <Grid container alignItems="center" direction="column">
              <Switch
                checked={recordButtonEnabled && !canvasIntegrationEnabled}
                onChange={enableHTMLRecording}
                disabled={htmlRecordingEnabled}
              />
              {recordButtonEnabled && !canvasIntegrationEnabled ? (
                <Typography> {t('shared-sentry-disable')}</Typography>
              ) : (
                <Typography> {t('shared-sentry-enable')}</Typography>
              )}
            </Grid>

            <Grid container alignItems="center" direction="column">
              {/* TODO - We need to build a fetching mechanism that will handle both cases of fetching images with CORS headers of "Access-Control-Allow-Origin: *" . After this we can enable the Canvas recording */}
              <Switch
                disabled={true}
                checked={canvasIntegrationEnabled}
                onChange={enableHTMLAndCanvasRecording}
              />
              {canvasIntegrationEnabled ? (
                <Typography>{t('shared-sentry-disable-both')}</Typography>
              ) : (
                <>
                  <Typography sx={{ color: 'gray' }}>
                    {t('shared-sentry-enable-both')}
                  </Typography>
                  <Typography textAlign="center" sx={{ color: 'gray' }}>
                    ({t('shared-sentry-cors-wait')})
                  </Typography>
                </>
              )}
            </Grid>
            <Grid container justifyContent="center">
              <Typography sx={{ paddingTop: '20px', textAlign: 'center' }}>
                {t('shared-sentry-disclaimer')}
              </Typography>
            </Grid>
          </Grid>
        </ToolContainerDraggable>
      </Backdrop>
    </ErrorBoundary>
  );
};

export default UserMenuItemSentryRecording;

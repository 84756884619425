/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { rest, RestHandler } from 'msw';
import { concat } from 'lodash';
import type {
  DrawingFromBE,
  DrawingListItem,
} from '../../store/warningsDrawings/types';
import type {
  AirmetWarning,
  PublicWarning,
  SigmetWarning,
  Warning,
} from '../../store/publicWarningForm/types';
import drawingListJSON from './drawingsList.json';
import drawingsListFullContentJSON from './drawingsListFullContent.json';
import warningListJSON from './warningList.json';
import sigmetListJSON from './sigmetList.json';
import airmetListJSON from './airmetList.json';
import { aviationEndpoints } from '../../api/aviation-api/fakeApi';

const drawingList = drawingListJSON as DrawingListItem[];
const drawingsListFullContent = drawingsListFullContentJSON as DrawingFromBE[];
const warningList = warningListJSON.map((pw) => ({
  ...pw,
  type: 'public',
})) as PublicWarning[];
const lengthWarningListWithoutExpiredWithdrawn = warningList.filter(
  (warning) => warning.status !== 'EXPIRED' && warning.status !== 'WITHDRAWN',
).length;
const sigmetList = sigmetListJSON.map((s) => ({
  ...s,
  type: 'sigmet',
})) as unknown as SigmetWarning[];
const airmetList = airmetListJSON.map((a) => ({
  ...a,
  type: 'airmet',
})) as unknown as AirmetWarning[];
const combinedWarnings = concat<Warning>(warningList, sigmetList, airmetList);

const areaKeywordList = [
  'North Sea districts',
  'Coastal Districts',
  'KNMI Specific',
  'Objects',
  'Baltic areas',
  'NL',
  'FI',
  'METNorway Specific',
  'Provinces',
  'FIR',
  'Countries',
];

export const publicWarningEndpoints = [
  rest.get(/^.*\/warnings$/, (req, res, ctx) => {
    // respond using a mocked JSON body
    return res(ctx.status(200), ctx.json(warningList));
  }),
  rest.post(/^.*\/warnings\/(.*)$/, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
  }),
];

export const makeWarningsHandler = (result: Warning[]): RestHandler => {
  return rest.get(/^.*\/warnings$/, (req, res, ctx) => {
    // respond using a mocked JSON body
    return res(ctx.status(200), ctx.json(result));
  });
};

const requestHandlers = [...publicWarningEndpoints, ...aviationEndpoints];

export {
  drawingList,
  drawingsListFullContent,
  warningList,
  lengthWarningListWithoutExpiredWithdrawn,
  sigmetList,
  airmetList,
  combinedWarnings,
  areaKeywordList,
  requestHandlers,
};

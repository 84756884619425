/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Copy } from '@opengeoweb/theme';
import { ToggleMenu } from '@opengeoweb/shared';
import { useCoreTranslation } from '../../../../../utils/i18n';

interface LayerManagerButtonProps {
  mapId: string;
  layerId?: string;
  onLayerDuplicate?: (payload: { mapId: string; layerId: string }) => void;
  tooltipTitle?: string;
  icon?: React.ReactNode;
}

const LayerManagerMenuButton: React.FC<LayerManagerButtonProps> = ({
  mapId,
  layerId,
  onLayerDuplicate,
  tooltipTitle,
  icon,
}: LayerManagerButtonProps) => {
  const { t } = useCoreTranslation();
  const nonEmptyTooltipTitle = tooltipTitle ?? t('layermanager-options');

  const onClickDuplicate = (): void => {
    onLayerDuplicate!({ mapId, layerId: layerId! });
  };

  return (
    <ToggleMenu
      buttonTestId="openMenuButton"
      buttonSx={{
        margin: '4px 2px',
        '&.MuiButtonBase-root': {
          backgroundColor: 'transparent',
          color: 'geowebColors.greys.accessible',
        },
      }}
      menuPosition="bottom"
      menuItems={[
        {
          text: t('layermanager-layer-duplicate'),
          action: onClickDuplicate,
          icon: <Copy />,
        },
      ]}
      tooltipTitle={nonEmptyTooltipTitle}
      buttonIcon={icon}
    />
  );
};

export default LayerManagerMenuButton;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { createApiInstance, CreateApiProps } from '@opengeoweb/api';
import axios, { AxiosInstance, Canceler } from 'axios';

import {
  SWEvent,
  Bulletin,
  TimeseriesParams,
  TimeseriesResponseData,
  StreamResponse,
  StreamParams,
  SWErrors,
  EventCategoryParams,
  SWNotification,
  DiscardDraftNotification,
} from '../types';

// cancel tokens
const { CancelToken } = axios;
const storedCancels: Record<string, Canceler> = {};
export const cancelRequestById = (requestId: string): void => {
  if (requestId && storedCancels[requestId]) {
    storedCancels[requestId](SWErrors.USER_CANCELLED);
  }
};

export const createCancelRequestId = (param: TimeseriesParams): string =>
  `-${param.stream}-${param.parameter}`;

export interface SpaceWeatherApi {
  getTimeSeries: (
    params: TimeseriesParams,
    requestId: string,
  ) => Promise<{ data: TimeseriesResponseData }>;
  getTimeSeriesMultiple: (
    params: TimeseriesParams[],
  ) => Promise<{ data: TimeseriesResponseData; message?: string }[]>;
  getStreams: (
    params: StreamParams,
    requestId?: string,
  ) => Promise<{ data: StreamResponse[] }>;
  getBulletin: (bulletinId?: string) => Promise<{ data: Bulletin }>;
  getBulletinHistory: () => Promise<{ data: Bulletin[] }>;
  getEventList: (params: EventCategoryParams) => Promise<{ data: SWEvent[] }>;
  getEvent: (eventid: string) => Promise<{ data: SWEvent }>;
  getNewNotifications: () => Promise<{ data: SWEvent[] }>;
  issueNotification: (formData: SWNotification) => Promise<void>;
  discardDraftNotification: (params: DiscardDraftNotification) => Promise<void>;
  setAcknowledged: (eventid: string) => Promise<void>;
  getRePopulateTemplateContent: (
    formData: SWNotification,
  ) => Promise<{ data: { title: string; message: string } }>;
}

const getApiRoutes = (axiosInstance: AxiosInstance): SpaceWeatherApi => {
  const api = {
    getTimeSeries: (
      params: TimeseriesParams,
      requestId: string,
    ): Promise<{ data: TimeseriesResponseData }> => {
      cancelRequestById(requestId);
      return axiosInstance.get('/timeseries/data', {
        params,
        cancelToken: new CancelToken((cancelToken: Canceler) => {
          storedCancels[requestId] = cancelToken;
        }),
      });
    },

    getTimeSeriesMultiple: (
      params: TimeseriesParams[],
    ): Promise<{ data: TimeseriesResponseData }[]> => {
      return Promise.all(
        params.map((param) =>
          api
            .getTimeSeries(param, createCancelRequestId(param))
            .catch((error) => {
              return error;
            }),
        ),
      );
    },

    getStreams: (
      params: StreamParams,
      requestId?: string,
    ): Promise<{ data: StreamResponse[] }> => {
      cancelRequestById(requestId!);
      return axiosInstance.get('/timeseries/streams', {
        params,
      });
    },

    getBulletin: (bulletinId?: string): Promise<{ data: Bulletin }> => {
      const params = bulletinId !== undefined ? { bulletinId } : null;
      return axiosInstance.get('/bulletin/getBulletin', { params });
    },

    getBulletinHistory: (): Promise<{ data: Bulletin[] }> => {
      return axiosInstance.get('/bulletin/getBulletinHistory');
    },

    getEventList: (
      params: EventCategoryParams,
    ): Promise<{ data: SWEvent[] }> => {
      return axiosInstance.get('/notification/eventList', { params });
    },
    getEvent: (eventid: string): Promise<{ data: SWEvent }> => {
      return axiosInstance.get(`/notification/event/${eventid}`);
    },

    getNewNotifications: (): Promise<{ data: SWEvent[] }> => {
      return axiosInstance.get('/notification/newNotifications');
    },

    // POST
    issueNotification: (formData: SWNotification): Promise<void> => {
      return axiosInstance.post('/notification/store', { ...formData });
    },
    discardDraftNotification: (
      params: DiscardDraftNotification,
    ): Promise<void> => {
      return axiosInstance.post('/notification/discard', null, { params });
    },
    setAcknowledged: (eventid: string): Promise<void> => {
      return axiosInstance.post(`/notification/acknowledged/${eventid}`);
    },
    getRePopulateTemplateContent: (
      formData: SWNotification,
    ): Promise<{ data: { title: string; message: string } }> => {
      return axiosInstance.post('/notification/populate', { ...formData });
    },
  };
  return api;
};

export const createApi = (props: CreateApiProps): SpaceWeatherApi => {
  const axiosInstance = createApiInstance(props);
  return getApiRoutes(axiosInstance);
};

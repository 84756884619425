/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { LayerProps, Style } from '@opengeoweb/webmap';
import { createSelector } from '@reduxjs/toolkit';
import type { CoreAppStore } from '../../types';
import { selectorMemoizationOptions } from '../../utils';
import { ServiceState, ServiceLayer, ReduxService } from './types';

const getServicesStore = (store: CoreAppStore): ServiceState | undefined =>
  store?.services;

/**
 * Retrieves all serviceIds
 *
 * Example: serviceIds = getServiceIds(store)
 * @param {object} store store: object - object from which the service state will be extracted
 * @returns {array} returnType: array - an array of all serviceIds
 */
export const getServiceIds = createSelector(
  getServicesStore,
  (serviceState: ServiceState | undefined): string[] =>
    serviceState?.allIds ?? [],
  selectorMemoizationOptions,
);

/**
 * Gets map services
 *
 * Example: services = getServices(store)
 * @param {object} store store: object - Store object
 * @returns {object} returnType: ServiceState
 */
export const getServices = createSelector(
  getServicesStore,
  (store: ServiceState | undefined) => store?.byId ?? {},
  selectorMemoizationOptions,
);

/**
 * Gets the service object by its url
 *
 * Example: service = getServiceByName(store, 'serviceName')
 * @param {object} store store: object - Store object
 * @returns {ReduxService} returnType: Service
 */
export const getServiceByUrl = createSelector(
  getServicesStore,
  (state: CoreAppStore, serviceUrl: string): string => serviceUrl,
  (
    serviceState: ServiceState | undefined,
    serviceUrl: string,
  ): ReduxService | undefined => {
    if (!serviceState) {
      return undefined;
    }
    return Object.values(serviceState.byId).find(
      (service) => service.serviceUrl === serviceUrl,
    );
  },
  selectorMemoizationOptions,
);

/**
 * Gets the layer from the store using the serviceUrl and layerName
 *
 * Example: layers = getLayersFromService(store, 'https://geoservices.knmi.nl/...')
 * @param {object} store store: object - object from which the layers state will be extracted
 * @param {string}  serviceUrl serviceUrl: string - Url of the service where the layer belongs to
 * @returns {array} returnType: ServiceLayer[] - All layers within the service object.
 */
export const getLayersFromServiceSelector = createSelector(
  getServiceByUrl,
  (service: ReduxService | undefined): ServiceLayer[] => {
    return service?.layers ?? [];
  },
  selectorMemoizationOptions,
);

/**
 * Gets the layer from the store using the serviceUrl and layerName
 *
 * Example: layer = getLayerFromService(store, 'https://geoservices.knmi.nl/...', 'radar')
 * @param {object} store store: object - The application store
 * @param {string}  serviceUrl serviceUrl: string - Url of the service where the layer belongs to
 * @param {string}  layerName layerName: string - Name of the layer in the service
 * @returns {ServiceLayer} returnType: ServiceLayer - The layer from the service object.
 */
export const getLayerFromService = createSelector(
  getLayersFromServiceSelector,
  (_store: CoreAppStore, _serviceUrl: string, layerName: string) => {
    return layerName;
  },
  (layers: LayerProps[], layerName: string): ServiceLayer | undefined => {
    return layers.find((serviceLayer: ServiceLayer) => {
      return serviceLayer.name === layerName;
    });
  },
  selectorMemoizationOptions,
);

/**
 * Gets the layers stylelist from the servicestore using the serviceUrl and layerName
 *
 * Example: layerStyles = getLayerStyles(store, layerService, layerName);
 * @param {object} store store: object - store from which the layers state will be extracted
 * @param {string}  serviceUrl serviceUrl: string - Url of the service where the layer belongs to
 * @param {string}  layerName layerName: string - Name of the layer in the service
 * @returns {array} returnType: Style[] - array containing layer styles
 */
export const getLayerStyles = createSelector(
  getLayerFromService,
  (layer: ServiceLayer | undefined): Style[] => {
    return layer?.styles ?? [];
  },
  selectorMemoizationOptions,
);

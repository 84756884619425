/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { IWMJSMap, getWMJSMapById, Bbox } from '@opengeoweb/webmap';

export interface ProfileAxisProps {
  mapId: string;
}

const MS_IN_HOUR = 3600 * 1000;

interface AxisStyle {
  backgroundColor: string;
  color: string;
  fontStyleSmall: string;
  fontStyleBig: string;
}

// The following is necessary to allow to assign to the CanvasRenderingContext2D ctx property
/* eslint-disable no-param-reassign */

/**
 * This component registers to a webmap instance and draws axis for height and time in the map using the Map Canvas.
 */
export const ProfileAxis: React.FC<ProfileAxisProps> = ({
  mapId,
}: ProfileAxisProps) => {
  const drawAxisOnMapDisplay = (
    ctx: CanvasRenderingContext2D,
    webMap: IWMJSMap,
    axisStyle: AxisStyle,
  ): void => {
    const bbox = webMap.getDrawBBOX();
    const { width, height } = webMap.getSize();
    const offY = height - 50;
    const offX = 20;

    try {
      const startDate = new Date(bbox.left);
      const endDate = new Date(bbox.right);
      webMap.setMessage(
        `${startDate.toDateString()}, ${startDate.getUTCHours()} to ${endDate.getUTCHours()} hours UTC`,
      );
    } catch (e) {
      webMap.setMessage('');
    }

    // Draw the horizontal time axis
    drawTimeAxis(ctx, bbox, offX, offY, width, height, axisStyle);

    // Draw the vertical elevation axis
    drawHeightAxis(ctx, bbox, offX, offY, width, height, axisStyle);

    // Draw the mouse cursor
    drawCursor(ctx, bbox, offX, offY, width, height, webMap, axisStyle);

    // Draw the layer name
    drawLayerName(ctx, bbox, offX, offY, width, height, webMap, axisStyle);
  };

  React.useEffect(() => {
    const axisStyle: AxisStyle = {
      backgroundColor: 'white',
      color: 'black',
      fontStyleSmall: '10px Roboto, Helvetica, Arial',
      fontStyleBig: '14px Roboto, Helvetica, Arial',
    };

    // Draw on map canvas callback function
    const beforeCanvasDisplay = (ctx: CanvasRenderingContext2D): void => {
      const webmapjs = getWMJSMapById(mapId);
      webmapjs && drawAxisOnMapDisplay(ctx, webmapjs, axisStyle);
    };

    // unMount logic
    const unMount = (): void => {
      const webMap = getWMJSMapById(mapId);
      if (webMap) {
        webMap.removeListener('beforecanvasdisplay', beforeCanvasDisplay);
      }
    };

    // Mount logic
    const webMap = getWMJSMapById(mapId);
    if (webMap && webMap.getProjection().srs === 'GFI:TIME_ELEVATION') {
      webMap.displayScaleBarInMap(false);
      webMap.hideMouseCursorProperties();
      webMap.setTimeOffset('');
      webMap.addListener('beforecanvasdisplay', beforeCanvasDisplay, true);
      webMap.draw();
    }

    return unMount;
  }, [mapId]);
  return null;
};

/**
 * Calculates a time spacing. This is used to place labels on the X axis (time axis).
 * To make readable time labels, the time spacing is discretized to rounded times,
 * The time spacing changes according to zoom level.
 * @param bbox
 * @param width
 * @returns
 */
const getTimeSpacingInMs = (bbox: Bbox, width: number): number => {
  const hoursInView =
    Math.abs(bbox.right - bbox.left) / ((MS_IN_HOUR * width) / 500);
  return (
    2 **
      (Math.round((Math.log10(hoursInView / 10) + 0.5) / Math.log10(2)) - 1) *
    MS_IN_HOUR
  );
};

const drawTimeAxis = (
  ctx: CanvasRenderingContext2D,
  bbox: Bbox,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
  axisStyle: AxisStyle,
): void => {
  const { backgroundColor, color, fontStyleSmall, fontStyleBig } = axisStyle;
  // Draw rectangle with backgroundColor for axis
  ctx.textBaseline = 'middle';
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, offsetY, width, 50);
  ctx.font = fontStyleSmall;
  ctx.textAlign = 'left';
  ctx.fillStyle = color;
  ctx.strokeStyle = color;

  const ticksMs = getTimeSpacingInMs(bbox, width);
  const roundedTimeLeftMs = Math.round(bbox.left / ticksMs) * ticksMs;
  const roundedTimeRightMs = Math.round(bbox.right / ticksMs) * ticksMs;
  const numHoursInView = Math.min(
    (roundedTimeRightMs - roundedTimeLeftMs) / ticksMs + 1,
    100,
  );

  for (let hoursToPlot = 0; hoursToPlot < numHoursInView; hoursToPlot += 1) {
    const timeMs = roundedTimeLeftMs + hoursToPlot * ticksMs;
    const posX =
      Math.round(((timeMs - bbox.left) / (bbox.right - bbox.left)) * width) +
      0.5;
    ctx.beginPath();
    ctx.moveTo(posX, offsetY + 0);
    ctx.lineTo(posX, offsetY + 10);
    const date = new Date(timeMs);
    const hourString = `0${date.getUTCHours()}`.slice(-2);
    const minuteString = `:${`0${date.getUTCMinutes()}`.slice(-2)}`;
    const txt = `${hourString}${minuteString}`;
    ctx.stroke();
    ctx.fillText(`${txt}`, posX - 13, offsetY + 16);
  }

  // Draw units in map
  ctx.font = fontStyleBig;
  ctx.fillText('time UTC (h)', width / 2 - 10, offsetY + 38);
};

/**
 * Calculates a spacing for the height axis. This is used to place labels on the Y axis (height axis).
 * To make readable height labels, the height spacing is discretized to rounded elevations,
 * The spacing changes according to zoom level.
 * @param bbox
 * @param height
 * @returns
 */
const getHeightSpacingInMs = (bbox: Bbox, height: number): number => {
  const numMeterInView = Math.abs(bbox.bottom - bbox.top) / height;
  return 10 ** (Math.round(Math.log10(numMeterInView * 100) + 0.5) - 1);
};

const drawHeightAxis = (
  ctx: CanvasRenderingContext2D,
  bbox: Bbox,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
  axisStyle: AxisStyle,
): void => {
  const { backgroundColor, color, fontStyleSmall, fontStyleBig } = axisStyle;
  // Draw rectangle in backgroundColor for axis
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, 60 + offsetX, height);
  ctx.font = fontStyleSmall;
  ctx.textAlign = 'right';
  ctx.fillStyle = color;

  const ticksM = getHeightSpacingInMs(bbox, height);
  const roundedElevBottomMeter = Math.round(bbox.bottom / ticksM) * ticksM;
  const roundedElevTopMeter = Math.round(bbox.top / ticksM) * ticksM;

  const numKmInView = Math.min(
    (roundedElevTopMeter - roundedElevBottomMeter) / ticksM + 1,
    100,
  );

  for (let kmToPlot = 0; kmToPlot < numKmInView; kmToPlot += 1) {
    const valueInKm = roundedElevBottomMeter + kmToPlot * ticksM;
    const posY =
      Math.round(((bbox.top - valueInKm) / (bbox.top - bbox.bottom)) * height) +
      0.5;
    ctx.beginPath();
    ctx.moveTo(offsetX + 50, posY);
    ctx.lineTo(offsetX + 60, posY);
    ctx.stroke();
    const txt = valueInKm;
    ctx.fillText(`${txt}`, offsetX + 48, posY + 2);
  }

  // Draw height unit in axis
  ctx.font = axisStyle.fontStyleBig;
  ctx.save();
  ctx.translate(5, height / 2 - 50);
  ctx.rotate(-Math.PI / 2);
  ctx.font = fontStyleBig;
  ctx.fillText('height (m)', 5, 5);
  ctx.restore();
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, height - 60, offsetX + 60, 60);

  // Draw line border for map
  ctx.moveTo(offsetX + 60, offsetY + 0.5);
  ctx.lineTo(width, offsetY + 0.5);
  ctx.moveTo(offsetX + 60.5, 0);
  ctx.lineTo(offsetX + 60.5, height - 50);
  ctx.stroke();
};

const drawCursor = (
  ctx: CanvasRenderingContext2D,
  bbox: Bbox,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
  webMapJS: IWMJSMap,
  axisStyle: AxisStyle,
): void => {
  const { mouseX: mapMouseX, mouseY: mapMouseY } =
    webMapJS.getMapMouseCoordinates();

  // Do not draw if mouse is not in the map
  if (mapMouseX < 5 && mapMouseY < 5) {
    return;
  }
  /* Draw cursor line */
  const mouseX = Math.round(mapMouseX) + 0.5;
  const mouseY = Math.round(mapMouseY) + 0.5;
  ctx.strokeStyle = '#303030';
  ctx.setLineDash([15, 5]);
  ctx.beginPath();
  ctx.moveTo(0, mouseY);
  ctx.lineTo(width, mouseY);
  ctx.moveTo(mouseX, 0);
  ctx.lineTo(mouseX, height);
  ctx.stroke();

  ctx.strokeStyle = axisStyle.color;

  ctx.lineWidth = 1;
  ctx.setLineDash([]);
};

const drawLayerName = (
  ctx: CanvasRenderingContext2D,
  bbox: Bbox,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
  webMapJS: IWMJSMap,
  axisStyle: AxisStyle,
): void => {
  // Draw layername
  const layers = webMapJS.getLayers();
  if (layers.length > 0) {
    ctx.textBaseline = 'middle';
    ctx.fillStyle = axisStyle.backgroundColor;
    ctx.font = axisStyle.fontStyleBig;
    ctx.textAlign = 'left';
    ctx.fillStyle = axisStyle.color;
    ctx.strokeStyle = axisStyle.color;
    ctx.fillText(`Layer: ${layers[0].name!}`, 10, offsetY + 38);
  }
};

// Re-enable the previousely disabled eslint rule
/* eslint-enable no-param-reassign */

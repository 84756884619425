/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  optimisticUpdate,
  useAuthenticationContext,
  useAuthQuery,
} from '@opengeoweb/api';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { getPublicWarningList, updatePublicWarningEditor } from './api';
import { PublicWarning } from '../../store/publicWarningForm/types';
import { publicWarningFormActions } from '../../store/publicWarningForm/reducer';

/**
 * @param disabled - Disables fetching, but still returns cached values.
 * https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries
 */
export const usePublicWarnings = (
  disabled = false,
): UseQueryResult<PublicWarning[]> =>
  useAuthQuery(
    ['public-warning-list'],
    getPublicWarningList,
    disabled,
    1000 * 60 * 5,
  );

interface MutateProps {
  id: string;
  isEditor: boolean;
}
export const useMutatePublicWarningEditor = (): UseMutationResult<
  object,
  Error,
  MutateProps,
  { oldData: PublicWarning[] | undefined }
> => {
  const queryClient = useQueryClient();
  const { auth } = useAuthenticationContext();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ id, isEditor }) => {
      if (!auth) {
        throw new Error('Authentication context not found');
      }
      return updatePublicWarningEditor(id, isEditor, auth);
    },
    ...optimisticUpdate<PublicWarning, MutateProps>(
      ['public-warning-list'],
      queryClient,
      (w, { isEditor }) => ({
        ...w,
        editor: isEditor ? auth!.username : undefined,
      }),
      (error) => {
        dispatch(
          publicWarningFormActions.setFormError({ message: error.message }),
        );
      },
    ),
    onSettled: async (data, e, { isEditor }) => {
      dispatch(
        snackbarActions.openSnackbar({
          type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
          key: isEditor
            ? 'warning-editor-add-editor'
            : 'warning-editor-remove-editor',
        }),
      );
    },
  });
};

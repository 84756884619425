/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';

import {
  genericSelectors,
  mapSelectors,
  selectorMemoizationOptions,
  syncGroupsSelectors,
} from '@opengeoweb/store';
import { timeSeriesSelectors } from '@opengeoweb/timeseries';
import { InitialProps } from '@opengeoweb/core';
import { pick } from 'lodash';
import { initialState } from './reducer';
import { WorkspaceState } from './types';
import { AppStore } from '../types';
import { viewPresetSelectors } from '../viewPresets';
import { emptyViewPreset } from '../viewPresets/utils';

import {
  WorkspaceSupportedComponentTypes,
  ViewPreset,
} from '../viewPresets/types';

const workspaceStore = (store: AppStore): WorkspaceState =>
  store?.workspace || initialState;

/**
 * Gets workspace state
 *
 * @param {object} store store: object - Store object
 * @returns {object} returnType: WorkspaceState
 */
export const getWorkspaceState = createSelector(
  workspaceStore,
  (store) => store || initialState,
  selectorMemoizationOptions,
);

/**
 * Gets component types from the views used in the workspace
 *
 * @param {object} store store: object - Store object
 * @returns {WorkspaceViewType.componentType[]} returnType: WorkspaceViewType.componentType[]
 */
export const getComponentTypesFromActiveViews = createSelector(
  [(state: AppStore): WorkspaceState | undefined => state.workspace],
  (workspace) => {
    const viewIds = workspace?.views.allIds; // Assuming you have all view IDs here
    const componentTypes = viewIds?.map(
      (viewId) => workspace?.views?.byId?.[viewId]?.componentType || '',
    );
    return componentTypes;
  },
);

/**
 * Gets mosaicNode from workspace state
 *
 * @param {object} store store: object - Store object
 * @returns {MosaicNode<string>} returnType: MosaicNode<string>
 */
export const getMosaicNode = createSelector(
  getWorkspaceState,
  (state) => state && state.mosaicNode,
  selectorMemoizationOptions,
);

/**
 * Gets view from workspace state
 *
 * @param {object} store store: object - Store object
 * @param {string} viewId viewId: string - view id
 * @returns {object} returnType: WorkspaceViewType
 */
export const getViewById = createSelector(
  getWorkspaceState,
  (store: AppStore, viewId: string) => viewId,
  (store, viewId) => {
    return store?.views?.byId?.[viewId];
  },
  selectorMemoizationOptions,
);

export const getViewsById = createSelector(
  getWorkspaceState,
  (store) => store.views.byId,
);

/**
 * Gets all view ids
 *
 * @param {object} store store: object - Store object
 * @returns {object} returnType: string[]
 */
export const getViewIds = createSelector(
  workspaceStore,
  (store) => store.views.allIds || [],
  selectorMemoizationOptions,
);

/**
 * Gets getShouldPreventClose from workspace state
 *
 * @param {object} store store: object - Store object
 * @param {string} viewId viewId: string - view id
 * @returns {boolean} returnType: boolean
 */
export const getShouldPreventClose = createSelector(
  getViewById,
  (store) => store?.shouldPreventClose || false,
  selectorMemoizationOptions,
);

export const isWorkspaceLoading = createSelector(
  getWorkspaceState,
  (store) => store?.isLoading || false,
  selectorMemoizationOptions,
);

export const getWorkspaceError = createSelector(
  getWorkspaceState,
  (store) => store?.error,
  selectorMemoizationOptions,
);
/**
 * Gets selected workspace title
 *
 * @param {object} store store: object - Store object
 * @returns {string} returnType: string
 */
export const getSelectedWorkspaceTitle = createSelector(
  workspaceStore,
  (store) => (store && store.title) || '',
  selectorMemoizationOptions,
);

export const hasWorkspaceChanges = createSelector(
  getWorkspaceState,
  (store) => store?.hasChanges || false,
  selectorMemoizationOptions,
);

/**
 * Gets selected viewType
 *
 * @param {object} store store: object - Store object
 * @returns {string} returnType: string
 */
export const getViewType = createSelector(
  getWorkspaceState,
  (store) => store?.viewType || '',
  selectorMemoizationOptions,
);

/**
 * Gets selected workspace scope
 *
 * @param {object} store store: object - Store object
 * @returns {string} returnType: string
 */
export const getSelectedWorkspaceScope = createSelector(
  workspaceStore,
  (store) => (store && store.scope) || 'system',
  selectorMemoizationOptions,
);

/**
 * Gets workspace data to send to the backend
 *
 * @param {object} store store: object - Store object
 * @returns {object} returnType: object - WorkspacePresetFromBE
 */
export const getWorkspaceData = createSelector(
  getWorkspaceState,
  viewPresetSelectors.getViewPresetsStore,
  genericSelectors.getSynchronizationGroupStore,
  (store, mapPresetStore, syncGroupsStore) => {
    const syncGroups =
      syncGroupsStore?.groups.allIds.map((id) => {
        const { type } = syncGroupsStore.groups.byId[id];
        return { id, type };
      }) || [];
    const views = store.views.allIds.map((mosaicNodeId) => {
      const viewPreset = mapPresetStore.ids.length
        ? mapPresetStore.entities[mosaicNodeId]
        : undefined;

      const viewPresetId = viewPreset?.activeViewPresetId || 'emptyMap';
      return {
        mosaicNodeId,
        viewPresetId,
      };
    });

    const linkedState = pick(
      syncGroupsStore?.linkedState.links,
      store.views.allIds,
    );

    return {
      id: store.id,
      title: store.title,
      syncGroups,
      views,
      viewType: store.viewType,
      scope: store.scope,
      abstract: store.abstract,
      mosaicNode: store.mosaicNode,
      isTimeScrollingEnabled: syncGroupsStore?.isTimeScrollingEnabled || false,
      linking: linkedState,
    };
  },
  selectorMemoizationOptions,
);

export const getViewComponentType = createSelector(
  getViewById,
  (store) =>
    (store?.componentType as WorkspaceSupportedComponentTypes | undefined) ||
    'Map',
  selectorMemoizationOptions,
);

export const getViewPresetToSendToBackend = createSelector(
  getViewById,
  getViewComponentType,
  mapSelectors.getMapPreset,
  syncGroupsSelectors.getAllTargetGroupsForSource,
  timeSeriesSelectors.getTimeSeriesPreset,
  (
    workspaceView,
    viewComponentType,
    mapPreset,
    syncGroupsIds,
    timeSeriesPreset,
  ): Omit<ViewPreset, 'title'> & { title?: string } => {
    const title = workspaceView?.title;
    const scope = workspaceView?.scope || 'system';
    const defaultInitialProps = {
      mapPreset,
      syncGroupsIds,
    };
    const initialPropsByComponentType: Record<
      string,
      InitialProps | undefined
    > = {
      Map: defaultInitialProps,
      TimeSeries: timeSeriesPreset,
    };

    return {
      ...emptyViewPreset(viewComponentType),
      id: workspaceView?.id,
      scope,
      title,
      initialProps:
        initialPropsByComponentType[viewComponentType] || defaultInitialProps,
    };
  },
  selectorMemoizationOptions,
);

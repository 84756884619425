/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CoreAppStore,
  layerSelectors,
  mapActions,
  mapEnums,
  mapSelectors,
} from '@opengeoweb/store';
import { AnimationLength, AnimationLengthButton } from '@opengeoweb/timeslider';
import { dateUtils } from '@opengeoweb/shared';

export interface AnimationLengthButtonConnectProps {
  mapId: string;
}

export const AnimationLengthButtonConnect: React.FC<
  AnimationLengthButtonConnectProps
> = ({ mapId }: AnimationLengthButtonConnectProps) => {
  const dispatch = useDispatch();

  const isAnimating = useSelector((store: CoreAppStore) =>
    mapSelectors.isAnimating(store, mapId),
  );

  const isAnimationLengthAuto = useSelector((store: CoreAppStore) =>
    mapSelectors.isAnimationLengthAuto(store, mapId),
  );

  const selectedAnimationStartTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getAnimationStartTime(store, mapId),
  );

  const { animationStartTime, animationEndTime } = useSelector(
    (store: CoreAppStore) => mapSelectors.getAnimationRange(store, mapId),
  );

  const currentDiffInMinutes = dateUtils.differenceInMinutes(
    dateUtils.utc(animationEndTime),
    dateUtils.utc(animationStartTime),
  );

  const handlechangeAnimationLength = (length: number): void => {
    const animationEndTime = dateUtils.dateToIsoString(
      dateUtils.add(dateUtils.utc(selectedAnimationStartTime), {
        minutes: length,
      }),
    );
    dispatch(
      mapActions.setAnimationEndTime({
        mapId,
        animationEndTime:
          animationEndTime || dateUtils.dateToIsoString(dateUtils.utc())!,
        origin: mapEnums.MapActionOrigin.map,
      }),
    );
  };
  const toggleAnimationLengthAuto = React.useCallback((): void => {
    dispatch(
      mapActions.toggleAnimationLengthAuto({
        mapId,
        autoAnimationLength: true,
        origin: mapEnums.MapActionOrigin.map,
      }),
    );
  }, [dispatch, mapId]);

  const autoTimestepLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getAutoTimeStepLayerId(store, mapId),
  );

  const timeStepFromLayer = useSelector((store: CoreAppStore) =>
    layerSelectors.getTimeStepForLayerId(store, autoTimestepLayerId),
  );

  return (
    <AnimationLengthButton
      disabled={isAnimating}
      animationLength={currentDiffInMinutes || Number(AnimationLength.Hours24)}
      isAuto={isAnimationLengthAuto}
      onChangeAnimationLength={handlechangeAnimationLength}
      toggleAnimationLengthAuto={toggleAnimationLengthAuto}
      layerHasTimeStep={Boolean(timeStepFromLayer)!}
    />
  );
};

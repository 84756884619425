/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CustomFeature } from '../components/types';

export const addFeedAdressToCustomGeoJSonFeature = (
  feedAddress: string,
  features: CustomFeature[],
): CustomFeature[] => {
  return features.reduce(
    (list: CustomFeature[], feature: CustomFeature): CustomFeature[] => {
      return list.concat({
        ...feature,
        properties: {
          ...feature.properties,
          details: {
            ...feature.properties!.details,
            feedAddress,
          },
        },
      });
    },
    [],
  );
};

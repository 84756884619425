/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import {
  UseTranslationResponse,
  initReactI18next,
  useTranslation,
} from 'react-i18next';
import { SHARED_NAMESPACE, sharedTranslations } from '@opengeoweb/shared';
import {
  FORM_FIELDS_NAMESPACE,
  formFieldsTranslations,
} from '@opengeoweb/form-fields';
import swTranslations from '../../../locales/spaceweather.json';

export const SPACEWEATHER_NAMESPACE = 'spaceweather';

export const initSpaceweatherI18n = (): void => {
  void i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    ns: SPACEWEATHER_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [SPACEWEATHER_NAMESPACE]: swTranslations.en,
        [SHARED_NAMESPACE]: sharedTranslations.en,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.en,
      },
      fi: {
        [SPACEWEATHER_NAMESPACE]: swTranslations.fi,
        [SHARED_NAMESPACE]: sharedTranslations.fi,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.fi,
      },
      no: {
        [SPACEWEATHER_NAMESPACE]: swTranslations.no,
        [SHARED_NAMESPACE]: sharedTranslations.no,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.no,
      },
      nl: {
        [SPACEWEATHER_NAMESPACE]: swTranslations.nl,
        [SHARED_NAMESPACE]: sharedTranslations.nl,
        [FORM_FIELDS_NAMESPACE]: formFieldsTranslations.nl,
      },
    },
  });
};

const ns = [SPACEWEATHER_NAMESPACE, SHARED_NAMESPACE, FORM_FIELDS_NAMESPACE];

export const translateKeyOutsideComponents = (
  key: string,
  params: Record<string, string | number> | undefined = undefined,
): string => i18n.t(key, { ns, ...params });

export const useSpaceweatherTranslation = (): UseTranslationResponse<
  typeof SPACEWEATHER_NAMESPACE,
  typeof i18n
> => useTranslation(ns);

export { i18n };

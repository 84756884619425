/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';

import {
  Box,
  FormControlLabel,
  FormGroup,
  FormLabel,
  LinearProgress,
  Radio,
} from '@mui/material';
import { useAuthenticationTranslation } from '../../utils/i18n';
import { Role } from '../ApiContext/types';

export const UserMenuRoles: React.FC<{
  roles?: Role[];
  currentRole?: string;
  onChangeRole: (role: Role) => void;
  isLoading: boolean;
}> = ({ roles, onChangeRole, isLoading, currentRole = '' }) => {
  const { t } = useAuthenticationTranslation();

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <LinearProgress data-testid="loading-bar" color="secondary" />
        </Box>
      )}
      <FormGroup>
        <FormLabel
          component="legend"
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: 'geowebColors.typographyAndIcons.text',
            opacity: 1,
          }}
        >
          {t('auth-role-title')}
        </FormLabel>
        {roles &&
          roles.map((role) => (
            <FormControlLabel
              control={
                <Radio
                  disabled={isLoading}
                  checked={currentRole === role.name}
                />
              }
              label={role.getTitle(t)}
              key={role.name}
              onChange={() => onChangeRole(role)}
            />
          ))}
      </FormGroup>
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react';

import { CanvasComponent } from '@opengeoweb/shared';
import {
  defaultTimeSpan,
  getAutoMoveAreaWidth,
  getFundamentalScale,
  pixelToTimestamp,
  timeBoxGeom,
  timestampToPixel,
  useCanvasTarget,
} from '../TimeSlider/timeSliderUtils';
import { getFilteredTime } from '../TimeSlider/changeTimeFunctions';
import {
  onMouseDown,
  onMouseMove,
} from './TimeSliderCurrentTimeBoxMouseEvents';
import {
  renderTimeSliderCurrentTimeBox,
  secondsInYear,
} from './TimeSliderCurrentTimeBoxRenderFunctions';
import { CalendarButton } from './CalendarButton/CalendarButton';

export interface TimeSliderCurrentTimeBoxProps {
  centerTime: number;
  secondsPerPx: number;
  span?: number;
  dataStartTime?: number;
  dataEndTime?: number;
  timeStep?: number;
  unfilteredSelectedTime: number;
  isAutoUpdating?: boolean;
  setUnfilteredSelectedTime: (unfilteredSelectedTime: number) => void;
  onCalendarSelect: (newTime: number) => void;
  onSetCenterTime?: (newTime: number) => void;
}

export const getTimeBoxWidth = (span: number): number => {
  const { smallWidth, largeWidth, iconWidth, calendarWidth } = timeBoxGeom;
  const mainWidth = span >= secondsInYear ? largeWidth : smallWidth;
  return mainWidth + iconWidth + calendarWidth;
};

const CALENDAR_ICON_LEFT_SPACING = 1;

export const TimeSliderCurrentTimeBox: React.FC<
  TimeSliderCurrentTimeBoxProps
> = ({
  centerTime,
  secondsPerPx = 50,
  span = defaultTimeSpan,
  timeStep,
  dataStartTime,
  dataEndTime,
  unfilteredSelectedTime,
  isAutoUpdating,
  setUnfilteredSelectedTime,
  onCalendarSelect,
  onSetCenterTime,
}: TimeSliderCurrentTimeBoxProps) => {
  const timeBoxWidth = getTimeBoxWidth(span);

  const theme = useTheme();
  const [, node] = useCanvasTarget('mousedown');
  const [cursorStyle, setCursorStyle] = React.useState('auto');
  const [mouseDownInTimeBox, setMouseDownInTimeBox] = React.useState(false);
  const [canvasWidth, setCanvasWidth] = React.useState(0);

  // Move TimeBox back to view if it goes out of bounds
  useEffect(() => {
    if (!(onSetCenterTime && canvasWidth && !mouseDownInTimeBox)) {
      return;
    }
    const receivedTimePx = timestampToPixel(
      unfilteredSelectedTime,
      centerTime,
      canvasWidth,
      secondsPerPx,
    );
    const moveWidth = getAutoMoveAreaWidth(getFundamentalScale(secondsPerPx));
    if (receivedTimePx < moveWidth) {
      onSetCenterTime(
        pixelToTimestamp(
          canvasWidth / 2 - (moveWidth - receivedTimePx),
          centerTime,
          canvasWidth,
          secondsPerPx,
        ),
      );
    } else if (receivedTimePx > canvasWidth - moveWidth) {
      onSetCenterTime(
        pixelToTimestamp(
          canvasWidth / 2 + (receivedTimePx - (canvasWidth - moveWidth)),
          centerTime,
          canvasWidth,
          secondsPerPx,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfilteredSelectedTime]);

  const isTimeBoxArea = (x: number, selectedTimePx: number): boolean => {
    return (
      x > selectedTimePx - timeBoxWidth / 2 &&
      x < selectedTimePx + timeBoxWidth / 2
    );
  };

  const mouseDownX = useRef<number>(0);
  const unfilteredSelectedTimePxAtMouseDown = useRef<number>(0);

  React.useEffect(() => {
    const handleMouseUp = (): void => {
      mouseDownInTimeBox ? setCursorStyle('grab') : setCursorStyle('auto');
      setMouseDownInTimeBox(false);
    };
    const handleMouseDown = (event: MouseEvent): void => {
      mouseDownX.current = event.clientX;
      unfilteredSelectedTimePxAtMouseDown.current = timestampToPixel(
        unfilteredSelectedTime,
        centerTime,
        canvasWidth,
        secondsPerPx,
      );
    };
    const handleMouseMove = (event: MouseEvent): void => {
      if (mouseDownInTimeBox) {
        if (mouseDownX.current) {
          const pixelsMoved = event.clientX - mouseDownX.current;

          const clickedUnfilteredSelectedTimePx =
            unfilteredSelectedTimePxAtMouseDown.current + pixelsMoved;

          const newUnfilteredSelectedTime = pixelToTimestamp(
            clickedUnfilteredSelectedTimePx,
            centerTime,
            canvasWidth,
            secondsPerPx,
          );
          setUnfilteredSelectedTime(newUnfilteredSelectedTime);
        }
      }
    };
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mousedown', handleMouseDown);
    return (): void => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [
    canvasWidth,
    centerTime,
    mouseDownInTimeBox,
    secondsPerPx,
    setUnfilteredSelectedTime,
    unfilteredSelectedTime,
  ]);

  const filteredTime = getFilteredTime(
    unfilteredSelectedTime,
    timeStep,
    dataStartTime,
    dataEndTime,
  );
  const timePx = timestampToPixel(
    filteredTime,
    centerTime,
    canvasWidth,
    secondsPerPx,
  );
  const currentTimeBoxWidth = getTimeBoxWidth(span);
  const calendarIconPx =
    timePx - currentTimeBoxWidth / 2 + CALENDAR_ICON_LEFT_SPACING;

  return (
    <Box
      data-testid="timeSliderTimeBox"
      className="timeSliderTimeBox"
      sx={{
        height: '24px',
        cursor: cursorStyle,
      }}
    >
      <CanvasComponent
        ref={node}
        onMouseMove={(
          x: number,
          y: number,
          event: MouseEvent,
          width: number,
        ): void => {
          if (!unfilteredSelectedTime) {
            return;
          }
          onMouseMove(
            x,
            width,
            unfilteredSelectedTime,
            centerTime,
            secondsPerPx,
            mouseDownInTimeBox,
            isTimeBoxArea,
            setCursorStyle,
          );
        }}
        onMouseDown={(x: number, y: number, width: number): void => {
          if (!unfilteredSelectedTime) {
            return;
          }
          onMouseDown(
            x,
            width,
            unfilteredSelectedTime,
            centerTime,
            secondsPerPx,
            isTimeBoxArea,
            setCursorStyle,
            setMouseDownInTimeBox,
          );
        }}
        onRenderCanvas={(
          ctx: CanvasRenderingContext2D,
          width: number,
          height: number,
        ): void => {
          setCanvasWidth(width);
          const filteredTime = getFilteredTime(
            unfilteredSelectedTime,
            timeStep,
            dataStartTime,
            dataEndTime,
          );
          renderTimeSliderCurrentTimeBox(
            ctx,
            theme,
            width,
            height,
            centerTime,
            filteredTime,
            secondsPerPx,
            span,
            isAutoUpdating!,
          );
        }}
      />
      <CalendarButton
        calendarIconPx={calendarIconPx}
        dataStartTime={dataStartTime}
        dataEndTime={dataEndTime}
        selectedTime={filteredTime}
        onSelect={(newSelectedTime): void => {
          if (newSelectedTime) {
            onCalendarSelect(newSelectedTime);
            if (onSetCenterTime) {
              onSetCenterTime(newSelectedTime - filteredTime + centerTime);
            }
          }
        }}
      />
    </Box>
  );
};

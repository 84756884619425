/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { ApiModule, ApiProvider, ApiUrls } from '@opengeoweb/api';
import { ErrorBoundary } from '@opengeoweb/shared';
import { TafModule } from '../TafModule';
import { createApi } from '../../utils/api';

export const tafScreenPreset = {
  id: 'screenConfigTaf',
  title: 'TAF',
  views: {
    byId: {
      taf: {
        title: 'TAF',
        componentType: 'TafModule',
        initialProps: {},
      },
    },
    allIds: ['taf'],
  },
  syncGroups: [],
  mosaicNode: 'taf',
};

type TafComponentType = 'TafModule';

export interface ComponentsLookUpPayload {
  componentType: TafComponentType;
  id: string;
  title?: string;
  initialProps: Record<string, never>; // no initialProps needed for taf
}

// base type for connected modules
export interface ApiModuleProps extends ApiModule {
  config: ApiUrls;
  onPreventCloseView?: (shouldPreventClose: boolean) => void;
}

export const TafApiWrapper: React.FC<ApiModuleProps> = ({
  config,
  auth,
  onSetAuth,
  onPreventCloseView,
}: ApiModuleProps) => {
  return config ? (
    <ApiProvider
      config={{
        baseURL: config.baseURL,
        appURL: config.baseURL,
        authTokenURL: config.authTokenURL,
        authClientId: config.authClientId,
      }}
      auth={auth}
      onSetAuth={onSetAuth}
      createApi={createApi}
    >
      <ErrorBoundary>
        <TafModule onPreventCloseView={onPreventCloseView} />
      </ErrorBoundary>
    </ApiProvider>
  ) : null;
};

export const componentsLookUp = (
  payload: ComponentsLookUpPayload,
  { config, auth, onSetAuth }: ApiModuleProps,
  // TODO: find a good way to type this module without the apps complaining
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ConnectedModuleWrapper: any = null,
): React.ReactElement => {
  const { componentType } = payload;
  switch (componentType) {
    case 'TafModule':
      if (ConnectedModuleWrapper) {
        return (
          <ConnectedModuleWrapper
            component={TafApiWrapper}
            payload={payload}
            data-testid="tafmodule"
            config={config}
            auth={auth}
            onSetAuth={onSetAuth}
          />
        );
      }
      return (
        <TafApiWrapper
          data-testid="tafmodule"
          config={config}
          auth={auth}
          onSetAuth={onSetAuth}
        />
      );
    default:
      return null!;
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Autocomplete, Box, TextField, useTheme } from '@mui/material';
import { Close } from '@opengeoweb/theme';
import React, { useEffect, useState } from 'react';
import { CustomIconButton, useDebounce } from '@opengeoweb/shared';
import { MyMapLocation } from './MyMapLocation';
import { useCoreTranslation } from '../../utils/i18n';
import { LocationDetail, LocationListResult } from './types';
import {
  useLocationDetail,
  useLocationList,
} from '../../utils/location-api/hooks';

export interface SearchDialogProps {
  isOpen: boolean;
  mapId?: string;
  onSelectLocation?: (location?: LocationDetail) => void;
  onUpdateMyLocation?: (location?: LocationDetail) => void;
  isMyLocationSelected?: boolean;
}

export const SearchDialog: React.FC<SearchDialogProps> = ({
  isOpen,
  mapId = '',
  onSelectLocation = (): void => {},
  onUpdateMyLocation = (): void => {},
  isMyLocationSelected = false,
}: SearchDialogProps) => {
  const theme = useTheme();
  const { t } = useCoreTranslation();
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<LocationListResult | null>(
    null,
  );

  const debouncedInputValue = useDebounce(inputValue, 300);
  const { data: locationListData } = useLocationList(debouncedInputValue);
  const { data: locationDetail, isFetching: isFetchingDetail } =
    useLocationDetail(selectedValue?.id, selectedValue?.source);

  useEffect(() => {
    if (isFetchingDetail || !isOpen) {
      return;
    }
    onSelectLocation(selectedValue ? locationDetail : undefined);
  }, [
    locationDetail,
    onSelectLocation,
    selectedValue,
    isFetchingDetail,
    isOpen,
  ]);

  return isOpen && mapId ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        pointerEvents: 'all',
        zIndex: 99,
        left: 50,
        top: 8,
        backgroundColor: theme.palette.geowebColors.textInputField.default.fill,
        height: 40,
        boxShadow: theme.shadows[6],
        width: 'calc(100% - 50px)',
        maxWidth: 380,
      }}
    >
      <MyMapLocation
        mapId={mapId}
        onUpdateMyLocation={onUpdateMyLocation}
        isSelected={isMyLocationSelected}
      />
      <Autocomplete
        disablePortal
        selectOnFocus
        sx={{
          width: '100%',
          '&.MuiAutocomplete-root .MuiFilledInput-root': {
            height: 40,
            padding: '0px!important',
            '&:before, &:hover:before': {
              borderColor: 'transparent',
            },
          },
          '&.MuiAutocomplete-root .MuiInputLabel-root': {
            lineHeight: '16px',
            maxWidth: 'calc(100% - 40px)',
            ':not(.MuiInputLabel-shrink)': {
              transform: 'translate(12px, 12px) scale(1)',
            },
          },
          '&.MuiAutocomplete-root .MuiFilledInput-input': {
            lineHeight: '20px',
            paddingLeft: '12px',
            paddingTop: '20px',
          },
        }}
        getOptionLabel={(option) => option.name}
        getOptionKey={(option) => option.id + option.name}
        filterOptions={(x) => x}
        options={locationListData || []}
        noOptionsText={t('search-no-results')}
        popupIcon={null}
        clearIcon={null}
        onChange={(_event, newValue) => {
          if (newValue && typeof newValue !== 'string') {
            setSelectedValue(newValue);
            setInputValue(newValue.name);
          }
        }}
        value={selectedValue}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            size="medium"
            label={t('search-title-long')}
            fullWidth
            variant="filled"
            onChange={(event) => {
              setInputValue(event.target.value);
              if (selectedValue) {
                setSelectedValue(null);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <CustomIconButton
                  tooltipTitle={t('search-clear')}
                  onClick={() => {
                    setInputValue('');
                    setSelectedValue(null);
                  }}
                  sx={{ marginRight: 1 }}
                >
                  <Close />
                </CustomIconButton>
              ),
            }}
          />
        )}
      />
    </Box>
  ) : null;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { createFakeApiInstance } from '@opengeoweb/api';
import { AxiosInstance } from 'axios';
import {
  AdverseWeatherTAC,
  TAC,
  Taf,
  TafFromBackend,
  TafFromFrontEnd,
} from '../types';
import { TafApi } from './api';
import { fakeTafList } from './mockdata/fakeTafList';

export const fakeTafTac =
  'TAF EHAM 0812/0918 20020G35KT 0500 SN BKN040\nBECMG 0814/0816 0700 BKN001=';
const fakeTafTacJson: AdverseWeatherTAC = {
  tac: [
    [
      { text: 'TAF' },
      { text: 'EHAM' },
      { text: '0812/0918' },
      { text: '20020G35KT', type: 'adverse' },
      { text: '0500', type: 'adverse' },
      { text: 'SN', type: 'adverse' },
      { text: 'BKN040' },
    ],
    [
      { text: 'BECMG' },
      { text: '0814/0816' },
      { text: '0700', type: 'adverse' },
      { text: 'BKN001', type: 'adverse' },
      { text: '=' },
    ],
  ],
};

let fakeUpdatedTafList = [...fakeTafList];
const setFakeUpdatedList = (newList: TafFromBackend[]): void => {
  fakeUpdatedTafList = newList;
};
const getFakeUpdatedList = (): TafFromBackend[] => fakeUpdatedTafList;

const getApiRoutes = (axiosInstance: AxiosInstance): TafApi => ({
  getTafList: (): Promise<{ data: TafFromBackend[] }> => {
    // eslint-disable-next-line no-console
    console.log('Retrieving new TAF list');

    return axiosInstance.get('/taflist').then(() => {
      return {
        data: getFakeUpdatedList(),
      };
    });
  },
  postTaf: (postTaf: TafFromFrontEnd): Promise<void> => {
    // eslint-disable-next-line no-console
    console.log(`The new status of the taf will be ${postTaf.changeStatusTo}`);

    // eslint-disable-next-line no-console
    console.log('form has been posted with following values:', postTaf.taf);
    return axiosInstance.post('/taf', postTaf);
  },
  getTAC: (
    taf: Taf,
    getJSON?: boolean,
    signal?: AbortSignal,
  ): Promise<{ data: TAC }> => {
    return axiosInstance
      .post('/taf2tac', taf, {
        ...(signal ? { signal } : {}),
        headers: {
          Accept: getJSON ? 'application/json' : 'text/plain',
        },
      })
      .then(() => ({
        data: getJSON ? fakeTafTacJson : fakeTafTac,
      }));
  },
  patchTaf: (patchTaf: TafFromFrontEnd): Promise<void> => {
    const updatedList = getFakeUpdatedList().map((tafFromBackend) => {
      if (tafFromBackend.taf.uuid === patchTaf.taf.uuid) {
        return { ...tafFromBackend, editor: patchTaf.editor! };
      }
      return tafFromBackend;
    });

    setFakeUpdatedList(updatedList);

    // eslint-disable-next-line no-console
    console.log(
      `The new editor of the taf will be ${patchTaf.editor || 'empty'}`,
    );
    return axiosInstance.patch('/taf', patchTaf);
  },
});

export const createApi = (): TafApi => {
  const instance = createFakeApiInstance();
  return getApiRoutes(instance);
};

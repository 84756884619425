/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  Theme,
  PaletteMode,
  createTheme as createMuiTheme,
} from '@mui/material';

/* 
  The next import augments the standard Material UI Theme so that we can also style the elements used inside the DatePicker (MuiPickersDay and MuiPickersPoppers).
  Without the import you get a type error.
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import type * as pickerTypes from '@mui/x-date-pickers/themeAugmentation';

import {
  BORDER_RADIUS,
  buttonVariants,
  defaultButtonStyle,
  iconButtonStyle,
  variantsToClassName,
} from './buttonStyles';
import { createShadows, hex2rgba, parseColors } from './utils';
import { Elevations, GeowebColorPalette } from '../types';
import {
  colors as colorsLight,
  elevations as elevationsLight,
} from './themes/light/lightTheme';

export const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
  desktopHD: 1440,
  desktopHDWide: 1920,
};

interface CreateThemeProps {
  paletteType?: PaletteMode;
  geowebColors?: GeowebColorPalette;
  elevations?: Elevations;
}

export const createTheme = ({
  paletteType = 'light',
  geowebColors: defaultGeowebColors = colorsLight,
  elevations: defaultShadows = elevationsLight,
}: CreateThemeProps): Theme => {
  const geowebColors = parseColors(defaultGeowebColors);
  const shadows = createShadows(defaultShadows);

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: breakpoints.mobile,
        sm: breakpoints.tablet,
        md: breakpoints.desktop,
        lg: breakpoints.desktopHD,
        xl: breakpoints.desktopHDWide,
      },
    },
    palette: {
      mode: paletteType,
      secondary: {
        main: geowebColors.typographyAndIcons.iconLinkActive!,
      },
      background: {
        paper: geowebColors.background.surfaceApp,
        default: geowebColors.background.surfaceBrowser,
      },
      text: {
        primary: geowebColors.typographyAndIcons.text,
      },
      warning: {
        main: geowebColors.typographyAndIcons.iconWarning!,
      },
      // geoweb color palette
      geowebColors,
    },
    shape: { borderRadius: BORDER_RADIUS },
    typography: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    shadows,
    components: {
      MuiInputBase: {
        defaultProps: {
          disableInjectingGlobalStyles: true,
        },
      },
      MuiButton: {
        variants: buttonVariants(geowebColors.buttons),
        defaultProps: {
          focusRipple: false,
        },
      },
      MuiToggleButton: {
        defaultProps: {
          focusRipple: false,
        },
        styleOverrides: {
          root: variantsToClassName(buttonVariants(geowebColors.buttons)),
        },
      },
      MuiIconButton: {
        // TODO: https://gitlab.com/opengeoweb/opengeoweb/-/issues/1926 remove default styling and create CustomIconButton
        defaultProps: {
          focusRipple: false,
        },
        styleOverrides: {
          root: {
            ...defaultButtonStyle(geowebColors.iconButtons.flat),
            ...variantsToClassName(
              buttonVariants(geowebColors.iconButtons, iconButtonStyle),
            ),
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
              html {
                -webkit-font-smoothing: auto;
              }
            `,
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minHeight: '48px!important',
            fontSize: '0.875rem',
            letterSpacing: '0.25px',
            divider: {
              borderBottomColor: geowebColors.greys.accessibleLight,
            },
            li: {
              '&:hover': {
                backgroundColor: geowebColors.tab.mouseOver.rgba,
              },
            },
            '&.Mui-selected': {
              background: 'none',
              boxShadow: `inset 4px 0px 0px 0px ${geowebColors.typographyAndIcons.iconLinkActive}`,
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            '& .MuiMenuItem-root': {
              '&:hover, &:focus': {
                backgroundColor: geowebColors.menuItem.mouseOver,
              },
              '&.Mui-selected': {
                background: geowebColors.menuItem.selected,
                boxShadow: 'none',
                color: '#fff',

                '&:hover, &:focus': {
                  backgroundColor: geowebColors.menuItem.selectedMouseOver,
                },
              },
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: geowebColors.typographyAndIcons.iconLinkActive,
            '&.MuiRadio-root.Mui-disabled': {
              color: `${geowebColors.typographyAndIcons.iconLinkDisabled}`,
            },
            '&.Mui-checked': {
              color: geowebColors.typographyAndIcons.iconLinkActive,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: geowebColors.typographyAndIcons.iconLinkActive,
            '&.MuiCheckbox-root.Mui-disabled': {
              color: geowebColors.typographyAndIcons.iconLinkDisabled,
            },
            '&.Mui-checked': {
              color: geowebColors.typographyAndIcons.iconLinkActive,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: { backgroundImage: 'unset' },
          outlined: {
            borderColor: geowebColors.cards.cardContainerBorder,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: geowebColors.cards.cardContainer,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: geowebColors.typographyAndIcons.iconLinkActive,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderSpacing: '0 0.25rem',
            borderCollapse: 'separate',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            '&:hover': {
              boxShadow: shadows[0],
            },
          },
          root: {
            transition: 'box-shadow 100ms ease-out',
            boxShadow: shadows[0],
            borderRadius: BORDER_RADIUS,
            '&:hover': {
              boxShadow: shadows[1],
            },
            position: 'relative',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            borderBottom: 'none',
            fontSize: '0.875rem',
            fontWeight: 400,
          },
          body: {
            padding: 12,
            fontWeight: 500,
            backgroundColor: geowebColors.background.surface,
            borderStyle: 'solid',
            borderColor: 'transparent',
            borderWidth: 1,
            borderTopColor: geowebColors.cards.cardContainerBorder,
            borderBottomColor: geowebColors.cards.cardContainerBorder,
            '&:first-child': {
              borderLeftColor: geowebColors.cards.cardContainerBorder,
              borderRadius: `${BORDER_RADIUS}px 0px 0px ${BORDER_RADIUS}px`,
            },
            '&:last-child': {
              borderRightColor: geowebColors.cards.cardContainerBorder,
              borderRadius: `0px ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0px`,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            position: 'relative',
            '&:after': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              height: 1,
              width: '100%',
              zIndex: 0,
              backgroundColor: hex2rgba(geowebColors.greys.accessible!, 0.2),
            },
          },
          indicator: {
            zIndex: 1,
            backgroundColor: geowebColors.typographyAndIcons.iconLinkActive,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'initial',
            '&.Mui-selected': {
              color: geowebColors.typographyAndIcons.iconLinkActive,
            },
            '&:hover': {
              opacity: 1,
              color: geowebColors.typographyAndIcons.iconLinkActive,
              backgroundColor: geowebColors.tab.mouseOver.rgba,
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: geowebColors.typographyAndIcons.text,
            fontSize: '14px',
            minHeight: '36px',
          },
          divider: {
            borderBottomColor: hex2rgba(geowebColors.greys.accessible!, 0.2),
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: geowebColors.typographyAndIcons.icon,
            width: '24px',
            height: '24px',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: geowebColors.cards.cardContainerMouseOver,
            },
            '&.Mui-selected': {
              backgroundColor: geowebColors.cards.cardContainerMouseOver,
              boxShadow: `inset 4px 0px 0px 0px ${geowebColors.typographyAndIcons.iconLinkActive}`,
              '&:hover': {
                backgroundColor: geowebColors.cards.cardContainerMouseOver,
              },
            },
            '&.Mui-focusVisible': {
              backgroundColor: geowebColors.cards.cardContainerMouseOver,
              outline: geowebColors.buttons.primary.focus.outline,
            },
            ' .MuiListItemIcon-root': {
              minWidth: 40,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: geowebColors.backdrops.black.rgba,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: geowebColors.tooltips.tooltipContainer.rgba,
            color: geowebColors.tooltips.tooltipText.color,
            padding: '8px 12px',
            fontSize: 16,
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 0.44,
            lineHeight: 1.56,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: geowebColors.typographyAndIcons.textLinkActive,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              textDecorationColor:
                geowebColors.typographyAndIcons.textLinkActive,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardError: {
            border: geowebColors.functional.errorOutline.border,
            borderRadius: '0px',
            backgroundColor: geowebColors.functional.errorOutline.fill,
            '& .MuiAlert-icon': {
              color: geowebColors.functional.error,
              opacity: 1,
            },
          },
          standardWarning: {
            border: geowebColors.functional.warningOutline.border,
            borderRadius: '0px',
            backgroundColor: geowebColors.functional.warningOutline.fill,
            '& .MuiAlert-icon': {
              color: geowebColors.functional.warning,
              opacity: 1,
            },
          },
          standardInfo: {
            border: geowebColors.functional.notificationOutline.border,
            borderRadius: '0px',
            backgroundColor: geowebColors.functional.notificationOutline.fill,
            '& .MuiAlert-icon': {
              color: geowebColors.functional.notification,
              opacity: 1,
            },
          },
          standardSuccess: {
            border: geowebColors.functional.successOutline.border,
            borderRadius: '0px',
            backgroundColor: geowebColors.functional.successOutline.fill,
            '& .MuiAlert-icon': {
              color: geowebColors.functional.success,
              opacity: 1,
            },
          },
          action: { color: geowebColors.buttons.tertiary.default.color },
          message: {
            fontSize: '14px',
            lineHeight: 1.43,
            letterSpacing: '0.25px',
            color: geowebColors.typographyAndIcons.text,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'filled',
        },
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: geowebColors.textInputField.default.rgba,
            '&.Mui-focused': {
              backgroundColor: geowebColors.textInputField.default.rgba,
              '&:after': {
                borderColor: geowebColors.captions.captionInformation.rgba,
              },
            },
            '&:hover': {
              backgroundColor: geowebColors.textInputField.mouseOver.rgba,
            },
            '&.Mui-disabled': {
              backgroundColor: geowebColors.textInputField.disabled.rgba,
            },
            '&.Mui-error': {
              backgroundColor: geowebColors.textInputField.error.rgba,
              '&:hover': {
                backgroundColor:
                  geowebColors.textInputField.errorMouseOver.rgba,
              },
              '&:after': {
                borderColor: geowebColors.captions.captionError.color,
              },
            },
            // can be improved in MUI6 or after this PR is merged https://github.com/mui/material-ui/pull/32822#issuecomment-1275927288
            '&.MuiInputBase-readOnly': {
              background: 'transparent',
              color: geowebColors.typographyAndIcons.text,
              ' .MuiInputBase-readOnly': {
                WebkitTextFillColor: 'inherit',
              },
              '&:before': {
                border: 'transparent',
              },
            },
          },
          underline: {
            '&:before': {
              borderColor: geowebColors.greys.accessible,
            },
            '&:after': {
              borderWidth: '2px',
            },
            '&.Mui-disabled:before': {
              borderBottomStyle: 'solid',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: geowebColors.captions.captionStatus.color,
            opacity: geowebColors.captions.captionStatus.opacity,
            '&.Mui-focused': {
              color: geowebColors.captions.captionInformation.color,
              opacity: geowebColors.captions.captionInformation.opacity,
            },
            '&.Mui-error, :has(+ .Mui-error)': {
              color: geowebColors.captions.captionError.color,
              opacity: geowebColors.captions.captionError.opacity,
            },
            '&.Mui-disabled': {
              color: geowebColors.captions.captionDisabled.color,
              opacity: geowebColors.captions.captionDisabled.opacity,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: geowebColors.captions.captionStatus.color,
            opacity: geowebColors.captions.captionStatus.opacity,
            '&.Mui-error': {
              color: geowebColors.captions.captionError.color,
              opacity: geowebColors.captions.captionError.opacity,
            },
            '&.Mui-disabled': {
              color: geowebColors.captions.captionDisabled.color,
              opacity: geowebColors.captions.captionDisabled.opacity,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: geowebColors.customSwitch.thumb,
            '&.Mui-checked': {
              color: geowebColors.customSwitch.thumb,
            },
            '&.Mui-disabled': {
              color: geowebColors.customSwitch.thumbDisabled,
            },
            '&.Mui-checked.Mui-disabled': {
              color: geowebColors.customSwitch.thumbDisabled,
            },
            '&.Mui-checked+.MuiSwitch-track': {
              backgroundColor: geowebColors.customSwitch.trackActive,
            },
            '&.Mui-disabled+.MuiSwitch-track': {
              backgroundColor: geowebColors.customSwitch.track,
            },
            '&.Mui-checked.Mui-disabled+.MuiSwitch-track': {
              backgroundColor: geowebColors.customSwitch.trackActive,
            },
          },
          track: {
            backgroundColor: geowebColors.customSwitch.track,
            opacity: '1 !important',
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            backgroundColor: geowebColors.snackbar.background,
            color: geowebColors.snackbar.text,
            fontSize: '16px',
            letterSpacing: '0.44px',
            borderRadius: '2px',
            boxShadow: shadows[8],
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            ...defaultButtonStyle(geowebColors.buttons.primary),
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'geowebColors.buttons.primary.default.fill',
            },
            height: 32,
            width: 32,
            fontSize: 16,
            borderRadius: '50%',
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: geowebColors.cards.cardContainer,
            borderRadius: '3px',
            border: 'solid 1px',
            borderColor: geowebColors.cards.cardContainerBorder,
            color: geowebColors.buttons.flat.default.color,
            padding: '0px',
            margin: '0px 12px 4px 12px',
            fontSize: '12px',
            '&:before': {
              display: 'none',
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
              borderBottom: 'solid 1px',
              borderColor: geowebColors.cards.cardContainerBorder,
            },
            '& .MuiAccordionSummary-content': {
              margin: '2px',
              padding: '4px 4px 4px 10px',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: '32px',
            padding: '0px',
            paddingRight: '5px',
          },
        },
      },
      MuiPickersPopper: {
        styleOverrides: {
          root: {
            '& .MuiPickersPopper-paper': {
              backgroundColor: geowebColors.background.surface,
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            borderRadius: 2,
            '&.Mui-selected': {
              backgroundColor: geowebColors.buttons.primary.default.fill,
              color: geowebColors.buttons.primary.default.color,
            },
            '&:hover, &.Mui-selected:hover': {
              backgroundColor: geowebColors.buttons.primary.mouseOver.fill,
              color: geowebColors.buttons.primary.default.color,
            },
            '&.MuiPickersDay-today': {
              borderColor: geowebColors.buttons.primary.mouseOver.borderColor,
            },
          },
        },
      },
      MuiMultiSectionDigitalClockSection: {
        styleOverrides: {
          item: {
            borderRadius: 2,
            '&.Mui-selected': {
              backgroundColor: geowebColors.buttons.primary.default.fill,
              color: geowebColors.buttons.primary.default.color,
            },
            '&:hover, &.Mui-selected:hover': {
              backgroundColor: geowebColors.buttons.primary.mouseOver.fill,
              color: geowebColors.buttons.primary.default.color,
            },
          },
        },
      },
    },
  });
};

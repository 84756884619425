/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  CoreAppStore,
  layerSelectors,
  serviceSelectors,
} from '@opengeoweb/store';
import { MissingData } from './MissingData';

export const MissingDataConnect: FC<{ mapId: string; layerId?: string }> = ({
  layerId,
  mapId,
}) => {
  const layerIsInsideAcceptanceTime = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerIsInsideAcceptanceTime(store, layerId, mapId),
  );
  const layerName = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerName(store, layerId),
  );
  const layerService = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerService(store, layerId),
  );
  const serviceLayer = useSelector((store: CoreAppStore) =>
    serviceSelectors.getLayerFromService(store, layerService, layerName),
  );
  const layerIsMissingOnServer = !serviceLayer;
  return (
    <MissingData
      layerIsInsideAcceptanceTime={
        layerIsMissingOnServer ? 'outside' : layerIsInsideAcceptanceTime
      }
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

const WMDrawMarker = (
  context: CanvasRenderingContext2D,
  coordx: number,
  coordy: number,
  fillColor = '#FFF',
): void => {
  const ctx = context;
  const coord = {
    x: parseInt(coordx.toString(), 10),
    y: parseInt(coordy.toString(), 10),
  };
  ctx.fillStyle = fillColor;
  ctx.globalAlpha = 1.0;
  ctx.beginPath();
  const topRadius = 7;
  const topHeight = 2 * topRadius;
  ctx.arc(coord.x, coord.y - topHeight, topRadius, Math.PI, Math.PI * 2);
  ctx.bezierCurveTo(
    coord.x + topRadius,
    coord.y - topHeight,
    coord.x + topRadius / 1.6,
    coord.y - topRadius,
    coord.x,
    coord.y,
  );
  ctx.bezierCurveTo(
    coord.x,
    coord.y,
    coord.x - topRadius / 1.6,
    coord.y - topRadius,
    coord.x - topRadius,
    coord.y - topHeight,
  );
  ctx.stroke();
  ctx.fill();
  /* Fill center circle */
  ctx.fillStyle = '#FFF';
  ctx.beginPath();
  ctx.arc(coord.x, coord.y - topHeight, topRadius / 2.5, Math.PI * 2, 0);
  ctx.fill();
};
export default WMDrawMarker;

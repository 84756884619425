/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';

import { mapTypes } from '@opengeoweb/store';
import DimensionSelect from './DimensionSelect';
import {
  useReferenceTimeWithLatestOption,
  useLayerDimensions,
} from './dimensionSelectHooks';

export interface DimensionSelectProps {
  layerId: string;
  mapId: string;
  skipLocalStyling?: boolean;
}

const DimensionSelectConnect: React.FC<DimensionSelectProps> = ({
  layerId,
  mapId,
  skipLocalStyling,
}: DimensionSelectProps) => {
  const { layerDimensions, isLayerEnabled, layerChangeDimension } =
    useLayerDimensions(layerId, mapId);
  const { useLatestReferenceTime, setUseLatestReferenceTime } =
    useReferenceTimeWithLatestOption(layerId);

  if (!layerDimensions.length || !layerId) {
    return null;
  }

  return (
    <DimensionSelect
      layerDimensions={layerDimensions}
      onLayerChangeDimension={(
        dimensionName: string,
        dimensionValue: string,
        origin,
      ): void => {
        const dimension: mapTypes.Dimension = {
          name: dimensionName,
          currentValue: dimensionValue,
        };
        layerChangeDimension({
          dimension,
          origin,
        });
      }}
      isEnabled={isLayerEnabled}
      useLatestReferenceTime={useLatestReferenceTime}
      setUseLatestReferenceTime={setUseLatestReferenceTime}
      skipLocalStyling={skipLocalStyling}
    />
  );
};

export default DimensionSelectConnect;

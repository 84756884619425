/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid2 as Grid,
} from '@mui/material';

import {
  CustomIconButton,
  CustomTooltip,
  TimeSeriesService,
} from '@opengeoweb/shared';
import { Add, Delete, Edit, Visibility, Cached } from '@opengeoweb/theme';
import { TFunction } from 'i18next';
import { useTimeseriesTranslation } from '../../utils/i18n';
import { Parameter } from '../TimeSeries/types';

export const sortByService = (
  serviceObj: TimeSeriesService[],
): TimeSeriesService[] => {
  // Need to make a copy as we are not allowed to mutate the original serviceObj and .sort mutates
  const newServiceObj = serviceObj.slice();
  return newServiceObj.sort((a, b) =>
    (a.name ? a.name : a.id).localeCompare(b.name ? b.name : b.id),
  );
};

interface ServiceOptionsDialogProps {
  services: TimeSeriesService[] | undefined;
  parametersInUse: Parameter[];
  openNewServicePopup: () => void;
  openServicePopup: (variant: 'edit' | 'show', id: string, url: string) => void;
  removeService: (id: string, message?: string) => void;
}

const styles = {
  servicesContainer: {
    width: '360px',
    maxHeight: '412px',
    top: '140px',
    backgroundColor: 'geowebColors.background.surface',
    boxShadow: 6,
    overflow: 'auto',
  },
  header: {
    position: 'sticky',
    top: 0,
    fontSize: 'default',
    padding: '12px',
    backgroundColor: 'geowebColors.background.surface',
    zIndex: 100,
  },
  loading: {
    position: 'absolute',
    zIndex: 101,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'geowebColors.background.surface',
    zIndex: 100,
  },
  button: {
    margin: '16px',
    width: '80%',
    fontSize: '12px',
    textTransform: 'none',
  },
};

const stopTabPropagation = (event: React.KeyboardEvent): void => {
  if (event.key === 'Tab') {
    event.stopPropagation();
  }
};

export const ServiceOptionsDialog: React.FC<ServiceOptionsDialogProps> = ({
  services,
  parametersInUse,
  openNewServicePopup,
  openServicePopup,
  removeService,
}: ServiceOptionsDialogProps) => {
  const dialogRef = React.useRef<HTMLElement | null>(null);
  const { t } = useTimeseriesTranslation();

  React.useEffect(() => {
    dialogRef.current?.focus();
  }, []);

  if (!services || services.length === 0) {
    return (
      <Box
        sx={{
          backgroundColor: 'geowebColors.background.surface',
          boxShadow: 6,
          padding: 1,
        }}
        onKeyDown={(event): void => {
          if (event.key !== 'Escape') {
            event.stopPropagation();
          }
        }}
        tabIndex={-1}
        ref={dialogRef}
      >
        {t('timeseries-no-services')}
      </Box>
    );
  }

  return (
    <Box
      sx={styles.servicesContainer}
      onKeyDown={(event): void => {
        if (event.key !== 'Escape') {
          event.stopPropagation();
        }
      }}
      tabIndex={-1}
      ref={dialogRef}
      aria-label={t('timeseries-services-options')}
    >
      <Box sx={styles.header}>{t('timeseries-services')}</Box>
      <Rows
        services={services}
        parametersInUse={parametersInUse}
        openServicePopup={openServicePopup}
        removeService={removeService}
      />
      <Box sx={styles.footer}>
        <Button
          onClick={openNewServicePopup}
          data-testid="add-new-ts-service"
          onKeyDown={stopTabPropagation}
          sx={styles.button}
          variant="tertiary"
          startIcon={<Add />}
        >
          {t('timeseries-add-service')}
        </Button>
      </Box>
    </Box>
  );
};

const deleteServiceTitle = (hasActiveLayers: boolean, t: TFunction): string => {
  return hasActiveLayers
    ? t('timeseries-cannot-remove')
    : t('timeseries-delete-service');
};

const tooltipPopperProps = {
  disablePortal: true,
  sx: {
    '& .MuiTooltip-tooltip': {
      width: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};

const hasServiceParmetersInUse = (
  serviceId: string,
  parametersInUse: Parameter[],
): boolean => {
  return parametersInUse.some((parameter) => parameter.serviceId === serviceId);
};

const Rows: React.FC<{
  services: TimeSeriesService[];
  parametersInUse: Parameter[];
  openServicePopup: (variant: 'edit' | 'show', id: string, url: string) => void;
  removeService: (id: string, message?: string) => void;
}> = ({ services, parametersInUse, openServicePopup, removeService }) => {
  const sortedServices = sortByService(services);
  const { t } = useTimeseriesTranslation();

  return (
    <List disablePadding>
      {sortedServices.map((service) => {
        const { url, name, id, scope } = service;
        const serviceHasParametersInUse = hasServiceParmetersInUse(
          id,
          parametersInUse,
        );
        const userAddedService = scope === 'user';

        const openDialog = (): void =>
          openServicePopup(userAddedService ? 'edit' : 'show', id, url);

        return (
          <ListItem
            key={url}
            secondaryAction={
              <Grid
                sx={{
                  marginRight: '0px',
                  width: 96,
                  button: { marginLeft: 1 },
                }}
                container
                justifyContent="left"
              >
                <CustomTooltip
                  title={t('timeseries-update-service')}
                  placement="left"
                  PopperProps={tooltipPopperProps}
                >
                  <span>
                    <CustomIconButton
                      aria-label={t('timeseries-update-service')}
                      onKeyDown={stopTabPropagation}
                      onClick={(): void => {}}
                      disabled
                    >
                      <Cached />
                    </CustomIconButton>
                  </span>
                </CustomTooltip>
                {userAddedService ? (
                  <CustomTooltip
                    title={t('timeseries-edit-service')}
                    placement="left"
                    PopperProps={tooltipPopperProps}
                  >
                    <span>
                      <CustomIconButton
                        aria-label={t('timeseries-edit-service')}
                        data-testid={`edit-button-${id}`}
                        onKeyDown={stopTabPropagation}
                        onClick={openDialog}
                      >
                        <Edit />
                      </CustomIconButton>
                    </span>
                  </CustomTooltip>
                ) : (
                  <CustomTooltip
                    title={t('timeseries-show-service')}
                    placement="left"
                    PopperProps={tooltipPopperProps}
                  >
                    <span>
                      <CustomIconButton
                        aria-label={t('timeseries-show-service')}
                        data-testid={`show-button-${id}`}
                        onKeyDown={stopTabPropagation}
                        onClick={openDialog}
                      >
                        <Visibility />
                      </CustomIconButton>
                    </span>
                  </CustomTooltip>
                )}
                {userAddedService && (
                  <Box>
                    <CustomTooltip
                      title={deleteServiceTitle(serviceHasParametersInUse, t)}
                      PopperProps={tooltipPopperProps}
                      placement="left"
                    >
                      <span>
                        <CustomIconButton
                          aria-label={t('timeseries-delete-service')}
                          disabled={serviceHasParametersInUse}
                          onKeyDown={stopTabPropagation}
                          data-testid={`remove-button-${id}`}
                          onClick={() =>
                            removeService(
                              service.id,
                              t('timeseries-service-removed', {
                                service: service.name
                                  ? service.name
                                  : service.id,
                              }),
                            )
                          }
                        >
                          <Delete />
                        </CustomIconButton>
                      </span>
                    </CustomTooltip>
                  </Box>
                )}
              </Grid>
            }
          >
            <ListItemText
              style={{
                fontSize: '16px',
              }}
            >
              <Typography
                noWrap
                style={{
                  textOverflow: 'ellipsis',
                  paddingRight: '70px',
                }}
              >
                {name || id}
              </Typography>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { layerTypes } from '@opengeoweb/store';
import {
  getWMSGetFeatureInfoRequestURL,
  webmapUtils,
} from '@opengeoweb/webmap';

export interface GFIResult {
  data: string;
  isLoading: boolean;
}

export interface GFILayer {
  layerId: string;
  url: string;
  title: string;
}

/**
 * Get the title for this WMS layer based on its layerId. If not defined, layerId is used instead.
 * @param layerId
 * @returns Title for the layer
 */
const getTitle = (layerId: string): string => {
  const wmjsLayer = webmapUtils.getWMLayerById(layerId!);
  return (wmjsLayer ? wmjsLayer.title || wmjsLayer.name : layerId)!;
};

/**
 * Make a list of GFI Layers to display.
 * @param layers
 * @param mapId
 * @returns
 */
export const getLayersToUpdate = (
  layers: layerTypes.Layer[],
  mapId: string,
): GFILayer[] => {
  const wmjsMap = webmapUtils.getWMJSMapById(mapId);
  if (!wmjsMap || !layers || layers.length === 0) {
    return [];
  }

  return layers
    .filter(
      (layer: layerTypes.Layer) =>
        layer && layer.enabled && !!webmapUtils.getWMLayerById(layer.id!),
    )
    .map((layer: layerTypes.Layer): GFILayer => {
      /* 
        We need a wmjsLayer to build the getfeatureinfo url (getWMSGetFeatureInfoRequestURL function).
        But it seems that its dimension are lazely updated (by ReactMapView).
        Therefore make a clone of this layer and set its dimensions based on the layer object. 
        Otherwise we risk making a getfeatureinfo call with outdated dimension values.
      */
      const wmjsLayer = webmapUtils.getWMLayerById(layer.id!).cloneLayer();

      return {
        layerId: layer.id!,
        url: getWMSGetFeatureInfoRequestURL(
          wmjsLayer,
          wmjsMap.getMapPin().getXY().x,
          wmjsMap.getMapPin().getXY().y,
          'text/html',
        ),
        title: getTitle(layer.id!),
      };
    });
};

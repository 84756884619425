/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
export type Streams = 'xray' | 'rtsw_mag' | 'rtsw_wind' | 'kp' | 'kpforecast';
export type Parameters =
  | 'solar_x_ray_flux_long'
  | 'solar_x_ray_flux_short'
  | 'proton_density'
  | 'bx_gsm'
  | 'by_gsm'
  | 'bz_gsm'
  | 'bt'
  | 'kp'
  | 'kpforecast'
  | 'proton_pressure'
  | 'proton_speed'
  | 'proton_temperature';

export type Source = 'GOES-16' | 'GOES-17' | 'ACE' | 'DSCOVR' | 'GFZ';

export interface Threshold {
  title: string;
  value: number;
  color?: string;
}

export interface DataObject {
  source: string;
  timestamp: string;
  value: number;
}

export interface TimeseriesResponseData {
  data: DataObject[];
  parameter: string;
  stream: string;
  unit: string;
}

export interface TimeseriesParams {
  stream?: Streams;
  parameter?: Parameters;
  source?: Source;
  time_start?: string;
  time_stop?: string;
}

export interface StreamResponse {
  end: string;
  hasactive: boolean;
  params: { name: Parameters; unit: string }[];
  sources: Source[];
  start: string;
  stream: Streams;
}

export interface StreamParams {
  stream: Streams;
}

export enum SWErrors {
  USER_CANCELLED = 'User Cancelled',
}

export enum EventCategory {
  XRAY_RADIO_BLACKOUT = 'Radio Blackout',
  GEOMAGNETIC = 'Geomagnetic',
  PROTON_FLUX = 'Proton Flux',
  ELECTRON_FLUX = 'Electron Flux',
}

export enum EventCategoryDetail {
  GEOMAGNETIC_STORM = 'Geomagnetic Storm',
  GEOMAGNETIC_SUDDEN_IMPULSE = 'Geomagnetic Sudden Impulse',
  KP_INDEX = 'Kp Index',
  PROTON_FLUX_100 = 'Proton Flux 100MeV',
  PROTON_FLUX_10 = 'Proton Flux 10MeV',
  ELECTRON_FLUX_2 = 'Electron Flux 2MeV',
  ELECTRON_FLUENCE = 'Electron Fluence',
}

export const EventCategoryDetails = {
  XRAY_RADIO_BLACKOUT: [''], // XRAY_RADIO_BLACKOUT doesn't have a categorydetail
  GEOMAGNETIC: ['GEOMAGNETIC_STORM', 'GEOMAGNETIC_SUDDEN_IMPULSE', 'KP_INDEX'],
  PROTON_FLUX: ['PROTON_FLUX_100', 'PROTON_FLUX_10'],
  ELECTRON_FLUX: ['ELECTRON_FLUX_2', 'ELECTRON_FLUENCE'],
};

export type EventCategoryParams = { originator: 'KNMI' } | { category: string };

export enum NotificationLabel {
  ALERT = 'Alert',
  WARNING = 'Warning',
}

// Possible label options based on the categorydetail
export const EventLabels = {
  '': ['ALERT'], // XRAY_RADIO_BLACKOUT doesn't have a categorydetail
  GEOMAGNETIC_STORM: ['WARNING'], // will show as 'Watch'
  ELECTRON_FLUENCE: ['WARNING'],
  ELECTRON_FLUX_2: ['ALERT'],
  GEOMAGNETIC_SUDDEN_IMPULSE: ['ALERT', 'WARNING'],
  KP_INDEX: ['ALERT', 'WARNING'],
  PROTON_FLUX_100: ['ALERT', 'WARNING'],
  PROTON_FLUX_10: ['ALERT', 'WARNING'],
};

export const ThresholdUnits = {
  XRAY_RADIO_BLACKOUT: 'W.m^-2',
  PROTON_FLUX: 'particles cm^-2 s^-1 sr^-1',
  ELECTRON_FLUX_2: 'particles cm^-2 s^-1 sr^-1',
  ELECTRON_FLUENCE: 'particles cm^-2 day^-1 sr^-1',
};

export const ThresholdValues = {
  XRAY_RADIO_BLACKOUT: [1e-5, 5e-5, 1e-4, 1e-3, 2e-3],
  GEOMAGNETIC: [5, 6, 7, 8, 9],
  PROTON_FLUX: [10, 100, 1e3, 1e4, 1e5],
};

export const EventLevels = {
  XRAY_RADIO_BLACKOUT: ['R1', 'R2', 'R3', 'R4', 'R5'],
  GEOMAGNETIC: ['G1', 'G2', 'G3', 'G4', 'G5'],
  PROTON_FLUX: ['S1', 'S2', 'S3', 'S4', 'S5'],
};

export const XrayClasses = ['M1', 'M5', 'X1', 'X10', 'X20'];

export interface DiscardDraftNotification {
  notificationid: string;
  eventid: string;
}

// Notification info sent to backend
export interface SWNotification {
  label: string; // WATCH,WARNING,ALERT
  changestateto: string; // issued, ended
  category: string; // EventCategory
  categorydetail: string; // EventCategoryDetail
  neweventlevel?: string; // NOAA Scale: R1, R2,...M1,...,S4,S5
  neweventstart: string;
  neweventend?: string;
  threshold?: number;
  thresholdunit?: string;
  datasource?: string;
  impulsetime?: string;
  initialgscale?: string; // G2, G2-3, G2-4
  magnetometerdeflection?: number;
  observedpolaritybz?: number;
  observedsolarwind?: number; // Always positive number
  peakclass?: string; // M7.3 X10.3
  peakflux?: number;
  peakfluxtime?: string;
  xrayclass?: string;
  shocktime?: string;
  draft?: boolean; // Notification is in draft state
  // External notification: incoming Original UKMO notification text (JSON) potential impact
  // KNMI notification: Message as entered by user or retrieved from template
  message: string;
  title?: string; // Title for KNMI message as entered by user

  // the following are set in the BE in case of a new notification or event
  notificationid?: string; // UUID for this notification generated by the backend.
  eventid?: string; // Event id, can be null. When null (a new event issued), the id is generated by the backend.
  issuetime?: string; // Issuetime generated by the backend.

  // not in incoming notification
  originator?: string;
}
/**
 * SWEventLifeCycle is to aggregate an SWEvent. Its state is derived inside the backend from 1 or more Notifications.
 * The notifications property will list both notifications ->  the issue and the summary.
 */
export interface SWEventLifeCycle {
  eventid: string;
  firstissuetime?: string; // Time very first notification of this lifecycle was issued
  lastissuetime?: string; // Time of last incoming notification of this lifecycle (Same as firstissuetime in case of 1 notification)
  state: string; // The current state of the event: issued or ended or drafted
  label: string; // WATCH, WARNING or ALERT
  owner: string; // Thelifecycle owner METOffice or KNMI
  eventstart: string;
  eventend?: string;
  eventlevel?: string; // NOAA Scale: R1, R2,...M1,...,S4,S5
  canbe?: string[]; // The actions which can be done on this event. For example canbe 'summarised', or 'updated', 'extended', 'cancelled' or 'acknowledged'
  draft?: boolean;
  notifications?: SWNotification[]; // List of corresponding Notifications
}

/**
 * Event is a aggregation of lifecycles which belong together, e.g. a KNMI lifecycle which is based on a MetOffice lifecycle. Lifecycles itself are based on several Notifications.
 * Event is used in the Notification List to represent a single list item with both incoming (external provider) lifecycle and outgoing (internal provider) lifecycle.
 */
export interface SWEvent {
  eventid: string;
  category: string; // EventCategory
  categorydetail: string; // EventCategoryDetail
  originator: string; // The originator, e.g. KNMI or METOFFICE
  notacknowledged?: boolean; // If not acknowledged yet - set to true, once acknowledged, property not set or false
  lifecycles?: {
    externalprovider?: SWEventLifeCycle;
    internalprovider?: SWEventLifeCycle;
  }; // For KNMI events, only internalprovider lifecycle is set, for notifications originating from the MetOffice, both are set
}

/**
 * Bulletin types
 */
export interface BulletinProbability {
  day1: number;
  day2: number;
  day3: number;
  day4: number;
}

export interface BulletinCategory {
  previously_exceeded: string;
  probabilities: BulletinProbability[];
  simplified_summary: string;
  status: string;
  summary: string;
  timestamp: string;
  type: string;
}

export interface BulletinOverview {
  content: string;
  simplified_content: string;
  timestamp: string;
  type: string;
}

export interface BulletinMessage {
  categories: BulletinCategory[];
  overview: BulletinOverview;
  status: string;
}

export interface Bulletin {
  bulletin_id: string;
  id: string;
  time: number;
  message: BulletinMessage;
}

export enum BulletinType {
  technical = 'TECHNICAL',
  plain = 'PLAIN',
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';

import { drawAdapter } from './reducer';
import type { DrawingToolState, DrawtoolModuleStore } from './reducer';

export const getDrawingtoolStore = (
  store: DrawtoolModuleStore,
): DrawingToolState => {
  if (store && store.drawingtools) {
    return store.drawingtools;
  }
  return null!;
};

export const {
  selectById: selectDrawToolById,
  selectAll: selectAllDrawingTools,
} = drawAdapter.getSelectors((state: DrawtoolModuleStore) => {
  return state?.drawingtools ?? { entities: {}, ids: [] };
});

export const getActiveDrawToolId = createSelector(
  selectAllDrawingTools,
  (drawtools) => {
    const activeDrawTool = drawtools?.find(
      (drawtool) => drawtool.activeDrawModeId !== '',
    );
    return activeDrawTool?.drawToolId || '';
  },
);

export const getDrawModeById = createSelector(
  selectDrawToolById,
  (_store: DrawtoolModuleStore, _drawToolId: string, drawModeId: string) =>
    drawModeId,
  (draw, drawModeId) => {
    return draw?.drawModes.find((tool) => tool.drawModeId === drawModeId);
  },
);

export const getActiveDrawModeId = createSelector(
  selectDrawToolById,
  (drawingTool) => drawingTool?.activeDrawModeId || '',
);

export const getDrawToolGeoJSONLayerId = createSelector(
  selectDrawToolById,
  (drawingTool) => drawingTool?.geoJSONLayerId || '',
);

export const getGeoJSONIntersectionLayerId = createSelector(
  selectDrawToolById,
  (drawingTool) => drawingTool?.geoJSONIntersectionLayerId,
);

export const getGeoJSONIntersectionBoundsLayerId = createSelector(
  selectDrawToolById,
  (drawingTool) => drawingTool?.geoJSONIntersectionBoundsLayerId,
);

export const getShouldAllowMultipleShapes = createSelector(
  selectDrawToolById,
  (drawingTool) => drawingTool?.shouldAllowMultipleShapes || false,
);

export const getActiveDrawMode = createSelector(
  selectDrawToolById,
  (drawingTool) => {
    return drawingTool?.drawModes.find(
      (mode) => mode.drawModeId === drawingTool.activeDrawModeId,
    );
  },
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ReactHookFormRadioGroup,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';

import { RadioButtonAndLabel } from './RadioButtonAndLabel';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import { FormFieldProps } from '../../../types';
import { triggerValidations } from '../utils';
import { useShortTestHelpers } from '../../../hooks/useShortTestHelpers';
import { useSigmetAirmetTranslation } from '../../../utils/i18n';

const ObservationForecast: React.FC<FormFieldProps> = ({
  isDisabled,
  isReadOnly,
}: FormFieldProps) => {
  const { t } = useSigmetAirmetTranslation();
  const { watch, trigger, getValues } = useFormContext();
  const { isRequired: isRequiredField } = useDraftFormHelpers();
  const { isShortTest } = useShortTestHelpers();
  const isRequired = (value: string): string | boolean =>
    isShortTest() || isRequiredField(value);
  const fieldsDisabled = isDisabled || isShortTest();

  return (
    <ProductFormFieldLayout
      sx={{
        marginTop: -1,
        '.MuiButtonBase-root.MuiRadio-root': {
          padding: '6px',
          marginLeft: '3px',
        },
        '.MuiFormHelperText-root.Mui-error': {
          marginBottom: '-16px',
          marginTop: '3px',
        },
      }}
    >
      <ReactHookFormRadioGroup
        name="isObservationOrForecast"
        disabled={fieldsDisabled}
        rules={{
          validate: {
            isRequired,
          },
        }}
        onChange={(): void => {
          triggerValidations(['observationOrForecastTime'], getValues, trigger);
        }}
        isReadOnly={isReadOnly}
      >
        {(!isReadOnly || watch('isObservationOrForecast') === 'OBS') && (
          <RadioButtonAndLabel
            value="OBS"
            label={t('observation-label')}
            disabled={fieldsDisabled}
            data-testid="isObservationOrForecast-OBS"
          />
        )}
        {(!isReadOnly || watch('isObservationOrForecast') === 'FCST') && (
          <RadioButtonAndLabel
            value="FCST"
            label={t('forecast-label')}
            disabled={fieldsDisabled}
            data-testid="isObservationOrForecast-FCST"
          />
        )}
      </ReactHookFormRadioGroup>
    </ProductFormFieldLayout>
  );
};

export default ObservationForecast;

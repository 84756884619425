/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Grid2 as Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { CustomTooltip, CustomIconButton, dateUtils } from '@opengeoweb/shared';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from '@opengeoweb/theme';
import type { PopperPlacement } from './TimeSliderClock';
import { useTimesliderTranslation } from '../../utils/i18n';
import { dateToManualFormatString } from './clockContainerUtils';

/**
 * Format a date to a string
 *
 * Expects the following props:
 * @param {Date} inDate - date to be formatted
 *
 */

export const ClockContainer: FC<{
  hideButton: boolean;
  onButtonClick: () => void;
  isPopperOpen: boolean;
  fontSize: number;
  time: number;
  setButtonElement: (anchorElement: HTMLDivElement | null) => void;
  popperPlacement: PopperPlacement;
}> = ({
  fontSize,
  isPopperOpen,
  hideButton,
  onButtonClick,
  time,
  setButtonElement,
  popperPlacement,
}) => {
  const { t } = useTimesliderTranslation();
  const currentDateString = dateToManualFormatString(dateUtils.fromUnix(time));
  return (
    <Grid
      container
      wrap="nowrap"
      sx={{
        justifyContent: 'space-evenly',
        alignItems: 'center',
        cursor: 'move',
        height: '100%',
        width: '100%',
        backgroundColor: 'geowebColors.timeSlider.playerTimeMarkers.fill',
        borderRadius: '5px',
      }}
    >
      <Grid sx={{ width: '4px' }} />
      {!hideButton && (
        <CustomTooltip title={t('timeslider-animation-options')}>
          <Grid
            ref={setButtonElement}
            sx={{
              zIndex: 2,
            }}
          >
            <CustomIconButton
              variant="tool"
              onClick={onButtonClick}
              sx={{
                color: '#fff!important',
              }}
              aria-label="Animation options"
              isSelected={isPopperOpen}
            >
              <Icon
                isPopperOpen={isPopperOpen}
                popperPlacement={popperPlacement}
              />
            </CustomIconButton>
          </Grid>
        </CustomTooltip>
      )}
      <Grid>
        <Typography
          variant="subtitle2"
          sx={{
            whiteSpace: 'nowrap',
            fontSize,
            color: '#fff',
          }}
        >
          {currentDateString}
        </Typography>
      </Grid>
    </Grid>
  );
};

const Icon: FC<{ isPopperOpen: boolean; popperPlacement: PopperPlacement }> = ({
  isPopperOpen,
  popperPlacement,
}) => {
  if (isPopperOpen) {
    if (popperPlacement === 'left') {
      return <ChevronRight />;
    }
    if (popperPlacement === 'right') {
      return <ChevronLeft />;
    }
    if (popperPlacement === 'top') {
      return <ChevronDown />;
    }
    return <ChevronUp />;
  }
  const popperClosedStyle = {
    backgroundColor: 'geowebColors.timeSlider.playerTimeMarkers.fill',
    outline: '1px solid white',
    borderRadius: '5px',
  };
  if (popperPlacement === 'left') {
    return <ChevronLeft sx={popperClosedStyle} />;
  }
  if (popperPlacement === 'right') {
    return <ChevronRight sx={popperClosedStyle} />;
  }
  if (popperPlacement === 'top') {
    return <ChevronUp sx={popperClosedStyle} />;
  }
  return <ChevronDown sx={popperClosedStyle} />;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Switch } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  layerSelectSelectors,
  layerSelectActions,
  useAppSelector,
} from '../../store';

export const KeywordFilterSelectAllSwitchConnect: React.FC = () => {
  const dispatch = useDispatch();

  const allFiltersActive = useAppSelector((store) =>
    layerSelectSelectors.isAllFiltersChecked(store),
  );

  const toggleFilters = React.useCallback(() => {
    dispatch(layerSelectActions.toggleAllFilters());
  }, [dispatch]);

  return (
    <Switch
      inputProps={{
        'aria-label': 'All',
      }}
      checked={allFiltersActive}
      onChange={toggleFilters}
      data-testid="customSwitch"
    />
  );
};

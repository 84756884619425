/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  MutationOptions,
  QueryClient,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  Credentials,
  useAuthenticationContext,
} from '@opengeoweb/authentication';

export const getHeaders = (auth: Credentials): HeadersInit => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${auth!.token}`,
  Accept: 'application/json',
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const handleResponse = (response: Response) => {
  if (response.status === 200) {
    return response.json();
  }
  return response
    .json()
    .catch(() => {
      // Couldn't parse the JSON
      throw new Error(`${response.status}`);
    })
    .then(({ message }) => {
      // Got valid JSON with error response, use it
      throw new Error(message || response.status);
    });
};

export const useAuthQuery = <T>(
  queryKey: string[],
  queryFn: (auth: Credentials) => Promise<T>,
  disabled = false,
  refetchInterval: number | false = false,
): UseQueryResult<T> => {
  const { auth } = useAuthenticationContext();
  const enabled = !!auth && !disabled;
  return useQuery({
    queryKey,
    queryFn: (): Promise<T> => queryFn(auth!),
    enabled,
    refetchInterval,
  });
};

export const optimisticUpdate = <
  U extends { id?: string },
  T extends { id: string },
>(
  updatedQueryKey: string[],
  queryClient: QueryClient,
  optimisticUpdate: (oldValue: U, variables: T) => U,
  errorCallback?: (err: Error) => void,
): MutationOptions<object, Error, T, { oldData: U[] | undefined }> => ({
  onMutate: async (variables: T): Promise<{ oldData: U[] | undefined }> => {
    void queryClient.cancelQueries({ queryKey: updatedQueryKey });
    const oldData = queryClient.getQueryData<U[]>(updatedQueryKey);
    queryClient.setQueryData<U[]>(updatedQueryKey, (old) =>
      old?.map((w) =>
        w.id === variables.id ? optimisticUpdate(w, variables) : w,
      ),
    );
    return { oldData };
  },
  onError: (err: Error, _: T, context?: { oldData: U[] | undefined }): void => {
    if (context?.oldData) {
      queryClient.setQueryData(updatedQueryKey, context?.oldData);
    }
    if (errorCallback) {
      errorCallback(err);
    }
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  GetCapabilitiesJson,
  LayerProps,
  LayerTree,
  WMSServiceInfo,
} from '../components/types';

import {
  makeQueryKeyFroMServiceUrl,
  privateGetWMSServiceInfo,
  privateWmsFlattenLayerTree,
  privateWmsGetCapabilities,
  privateWmsGetLayerTree,
} from '../utils/getCapabilities/utils';
import { wmsQueryClient } from '../utils/getCapabilities/wmsQueryClient';
import {
  QUERYWMS_GETCAPABILITIES,
  QUERYWMS_LAYER,
  QUERYWMS_LAYERS,
  QUERYWMS_LAYERSTREE,
  QUERYWMS_SERVICEINFO,
  queryWMSKeys,
} from './queryConstants';

/**
 * Invalidates the tanstack store for this getcapabilities service. Used to force refetch.
 *
 * @async
 * @param {string} serviceUrl
 * @returns {Promise<void>}
 */
export const invalidateWMSGetCapabilities = async (
  serviceUrl: string,
): Promise<void> => {
  await Promise.all(
    queryWMSKeys.map((queryWMSKey) => {
      return wmsQueryClient.invalidateQueries({
        queryKey: [makeQueryKeyFroMServiceUrl(serviceUrl), queryWMSKey],
      });
    }),
  );
};

/**
 * Fetches the WMS GetCapabilities document and converts it to a json object. It is cached by TanStack.
 *
 * @async
 * @param {string} serviceUrl
 * @returns {Promise<GetCapabilitiesJson>}
 */
export const queryWMSGetCapabilities = async (
  serviceUrl: string,
): Promise<GetCapabilitiesJson> => {
  return wmsQueryClient.fetchQuery({
    queryKey: [
      makeQueryKeyFroMServiceUrl(serviceUrl),
      QUERYWMS_GETCAPABILITIES,
    ],
    queryFn: () => privateWmsGetCapabilities(serviceUrl),
  });
};

/**
 * Gets the layer tree in hierarchical order for a WMS service. Cached by TanStack. Uses the same store as in queryWMSGetCapabilities.
 *
 * @async
 * @param {string} serviceUrl
 * @returns {Promise<LayerTree>}
 */
export const queryWMSLayersTree = async (
  serviceUrl: string,
): Promise<LayerTree> => {
  const data = await queryWMSGetCapabilities(serviceUrl);
  return wmsQueryClient.fetchQuery({
    queryKey: [makeQueryKeyFroMServiceUrl(serviceUrl), QUERYWMS_LAYERSTREE],
    queryFn: () => privateWmsGetLayerTree(data),
  });
};

/**
 * Returns details about the WMS service
 *
 * @async
 * @param {string} serviceUrl
 * @returns {Promise<WMSServiceInfo>}
 */
export const queryWMSServiceInfo = async (
  serviceUrl: string,
): Promise<WMSServiceInfo> => {
  const data = await queryWMSGetCapabilities(serviceUrl);

  return wmsQueryClient.fetchQuery({
    queryKey: [makeQueryKeyFroMServiceUrl(serviceUrl), QUERYWMS_SERVICEINFO],
    queryFn: () => {
      return privateGetWMSServiceInfo(data, serviceUrl);
    },
  });
};

/**
 * Gets the flat layer list (without hierarchy) for a WMS service. Cached by TanStack. Uses the same store as in queryWMSGetCapabilities.
 *
 * @async
 * @param {string} serviceUrl
 * @param {boolean} [forceReload=false]
 * @returns {Promise<LayerProps[]>}
 */
export const queryWMSLayers = async (
  serviceUrl: string,
  forceReload = false,
): Promise<LayerProps[]> => {
  if (forceReload) {
    await invalidateWMSGetCapabilities(serviceUrl);
  }
  const data = await queryWMSLayersTree(serviceUrl);
  return wmsQueryClient.fetchQuery({
    queryKey: [makeQueryKeyFroMServiceUrl(serviceUrl), QUERYWMS_LAYERS],
    queryFn: () => privateWmsFlattenLayerTree(data),
  });
};

/**
 * Returns specific layer from the WMS service
 *
 * @async
 * @param {string} serviceUrl
 * @param {string} name
 * @returns {Promise<LayerProps>}
 */
export const queryWMSLayer = async (
  serviceUrl: string,
  name: string,
): Promise<LayerProps> => {
  const data = await queryWMSLayers(serviceUrl);
  return wmsQueryClient.fetchQuery({
    queryKey: [makeQueryKeyFroMServiceUrl(serviceUrl), QUERYWMS_LAYER],
    queryFn: () => {
      return data.find((layer) => layer.name === name);
    },
  });
};

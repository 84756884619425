/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  CoreAppStore,
  uiActions,
  uiTypes,
  useSetupDialog,
} from '@opengeoweb/store';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { ListFilter } from '@opengeoweb/shared';
import {
  DrawingListItem,
  FetchDrawingParams,
} from '../../store/warningsDrawings/types';
import { getWarningsApi, WarningsApi } from '../../utils/api';
import { areaObjectLoaderType } from '../../store/warningsDrawings/utils';
import { useWarningsTranslation } from '../../utils/i18n';
import { areaErrorType, AreaObjectLoader } from './AreaObjectLoader';
import { publicWarningFormActions } from '../../store/publicWarningForm/reducer';
import {
  getPublicWarningAreas,
  getPublicWarningId,
  getPublicWarningStatus,
} from '../../store/publicWarningForm/selectors';
import { PublicWarningStatus } from '../../store/publicWarningForm/types';

const getFilterParams = (
  areas: ListFilter[],
  searchQuery: string,
): FetchDrawingParams => {
  const keywords = areas.reduce<string[]>((areaList, area) => {
    if (area.isSelected) {
      return areaList.concat(area.id);
    }
    return areaList;
  }, []);

  return {
    ...(keywords.length && {
      keywords: keywords.join(','),
    }),
    ...(searchQuery.length && {
      search: searchQuery,
    }),
  };
};

const useAreaRequest = (
  warningsApi: WarningsApi,
): {
  areas: ListFilter[];
  fetchAreaError: uiTypes.UISetErrorPayload | undefined;
  isAreasLoading: boolean;
  fetchAreaKeywords: () => void;
  setAreas: (areas: ListFilter[]) => void;
} => {
  const [fetchAreaError, setFetchAreaError] = React.useState<
    uiTypes.UISetErrorPayload | undefined
  >(undefined);
  const [areas, setAreas] = useState<ListFilter[]>([]);
  const [isAreasLoading, setLoading] = useState(false);

  const fetchAreaKeywords = (): void => {
    setFetchAreaError(undefined);
    setLoading(true);

    warningsApi
      .getAreaKeywords()
      .then((response) => {
        const keyWords = response.data.map((keyword) => ({
          label: keyword,
          id: keyword,
          isSelected: false,
          isDisabled: false,
        }));

        setAreas(keyWords);
      })
      .catch((error) => {
        setFetchAreaError({ error: error.message, type: areaErrorType });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { areas, fetchAreaError, isAreasLoading, fetchAreaKeywords, setAreas };
};

export const AreaObjectLoaderConnect: React.FC<{
  bounds?: string;
  source?: uiTypes.Source;
}> = ({ bounds, source = 'app' }) => {
  const { t } = useWarningsTranslation();
  const { dialogOrder, setDialogOrder, isDialogOpen, uiSource, uiError } =
    useSetupDialog(areaObjectLoaderType, source);
  const warningsApi = getWarningsApi();

  const dispatch = useDispatch();

  const [items, setItems] = useState<DrawingListItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { areas, fetchAreaKeywords, fetchAreaError, setAreas, isAreasLoading } =
    useAreaRequest(warningsApi);

  const selectedAreas = useSelector((store: CoreAppStore) =>
    getPublicWarningAreas(store),
  );
  const warningId = useSelector((store: CoreAppStore) =>
    getPublicWarningId(store),
  );

  const warningStatus = useSelector((store: CoreAppStore) =>
    getPublicWarningStatus(store),
  );

  const fetchItems = (): void => {
    dispatch(
      uiActions.setErrorDialog({
        error: '',
        type: areaObjectLoaderType,
      }),
    );
    setLoading(true);
    const filterParams = getFilterParams(areas, searchQuery);

    warningsApi
      .getDrawings(filterParams)
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          uiActions.setErrorDialog({
            error: error.message,
            type: areaObjectLoaderType,
          }),
        );
      });
  };

  useEffect(() => {
    if (isDialogOpen) {
      fetchItems();
      fetchAreaKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const onClose = (): void => {
    dispatch(
      uiActions.setActiveMapIdForDialog({
        type: areaObjectLoaderType,
        mapId: '',
        setOpen: false,
        source,
      }),
    );
  };

  const onClickFilterChip = (id: string, isSelected: boolean): void => {
    const updatedAreas = areas.map((area) => {
      if (id === 'all') {
        return { ...area, isSelected: !isSelected };
      }
      return area.id === id
        ? {
            ...area,
            isSelected,
          }
        : area;
    });

    setAreas(updatedAreas);
  };

  const onSearch = (queryString: string): void => setSearchQuery(queryString);

  const onAddArea = ({ geoJSON, objectName }: DrawingListItem): void => {
    dispatch(
      publicWarningFormActions.addArea({
        area: { geoJSON, objectName },
      }),
    );
  };

  const onRemoveArea = ({ geoJSON, objectName }: DrawingListItem): void => {
    dispatch(
      publicWarningFormActions.removeArea({
        area: { geoJSON, objectName },
      }),
    );
  };

  const onRetryRequest = (errorType: string): void => {
    if (errorType === areaErrorType) {
      fetchAreaKeywords();
    }
  };

  React.useEffect(() => {
    if (areas.length) {
      fetchItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas, searchQuery]);

  const isChangeAreaDisabled =
    warningId !== undefined &&
    typeof warningId === 'string' &&
    warningId.length > 0 &&
    warningStatus !== PublicWarningStatus.DRAFT &&
    warningStatus !== PublicWarningStatus.DRAFT_PUBLISHED;

  const drawingError = uiError
    ? { type: areaObjectLoaderType, error: uiError }
    : undefined;

  return (
    <AreaObjectLoader
      title={t('area-object-loader-title')}
      bounds={bounds}
      isOpen={isDialogOpen}
      onClose={onClose}
      onMouseDown={setDialogOrder}
      order={dialogOrder}
      source={uiSource}
      headerSize="xs"
      startPosition={{ top: 104, left: 50 }}
      drawingListItems={items}
      error={drawingError || fetchAreaError}
      isLoading={loading || isAreasLoading}
      onClickFilterChip={onClickFilterChip}
      searchQuery={searchQuery}
      onSearch={onSearch}
      filters={areas}
      onAddArea={onAddArea}
      onRemoveArea={onRemoveArea}
      selectedAreas={selectedAreas}
      isChangeAreaDisabled={isChangeAreaDisabled}
      onRetryRequest={onRetryRequest}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2025 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2025 - Finnish Meteorological Institute (FMI)
 * Copyright 2025 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  CoreAppStore,
  layerActions,
  layerSelectors,
  mapSelectors,
} from '@opengeoweb/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  emptyGeoJSON,
  MapDrawDrawFunctionArgs,
  registerDrawFunction,
} from '@opengeoweb/webmap-react';
import { Feature, FeatureCollection, GeoJsonProperties } from 'geojson';
import { generateLayerId, getWMJSMapById, LayerType } from '@opengeoweb/webmap';
import { LocationDetail } from './types';

export const myLocationDrawFunction = (args: MapDrawDrawFunctionArgs): void => {
  const { context: ctx, coord } = args;
  // Draw outer circle
  ctx.strokeStyle = '#fff';
  ctx.fillStyle = '#fff';
  ctx.shadowColor = '#00000080';
  ctx.shadowBlur = 4;
  ctx.beginPath();
  ctx.arc(coord.x, coord.y, 14, 0, 2 * Math.PI);
  ctx.fill();
  ctx.shadowBlur = 0;
  // Draw inner circle
  ctx.fillStyle = '#186dff';
  ctx.beginPath();
  ctx.arc(coord.x, coord.y, 10, 0, 2 * Math.PI);
  ctx.fill();
};

export const selectedLocationDrawFunction = (
  args: MapDrawDrawFunctionArgs,
): void => {
  const { context: ctx, coord } = args;
  ctx.strokeStyle = '#051039';
  ctx.fillStyle = '#051039';
  ctx.beginPath();
  const topRadius = 7;
  const topHeight = 2 * topRadius;
  ctx.arc(coord.x, coord.y - topHeight, topRadius, Math.PI, Math.PI * 2);
  ctx.bezierCurveTo(
    coord.x + topRadius,
    coord.y - topHeight,
    coord.x + topRadius / 1.6,
    coord.y - topRadius,
    coord.x,
    coord.y,
  );
  ctx.bezierCurveTo(
    coord.x,
    coord.y,
    coord.x - topRadius / 1.6,
    coord.y - topRadius,
    coord.x - topRadius,
    coord.y - topHeight,
  );
  ctx.stroke();
  ctx.fill();
  /* Fill center circle */
  ctx.fillStyle = '#FFF';
  ctx.beginPath();
  ctx.arc(coord.x, coord.y - topHeight, topRadius / 2.5, Math.PI * 2, 0);
  ctx.fill();
};

export const geometryStyling: GeoJsonProperties = {
  fill: '#FF7800',
  'fill-opacity': 0.25,
  stroke: '#FF7800',
  'stroke-opacity': 1,
  'stroke-width': 4,
};

const makeGeoJSONFeatureFromLocation = (
  location: LocationDetail,
  drawFunctionId: string,
  hoverDrawFunctionId: string,
): FeatureCollection => {
  const collection: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  const geometry: Feature = {
    ...location.geometry!,
    properties: {
      ...geometryStyling,
      name: location.name,
      hoverDrawFunctionId,
    },
  };

  const marker: Feature = {
    type: 'Feature',
    properties: { name: location.name, drawFunctionId, hoverDrawFunctionId },
    geometry: {
      type: 'Point',
      coordinates: [location.lon, location.lat],
    },
  };

  switch (location.geometry?.geometry.type) {
    case 'Point':
    case 'MultiLineString':
    case undefined:
      collection.features.push(marker);
      break;
    default:
      collection.features.push(geometry);
  }

  return collection;
};

const fitText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  lineheight: number,
): void => {
  const lines = text.split('\n');

  for (let i = 0; i < lines.length; i += 1) {
    ctx.fillText(lines[i], x, y + i * lineheight);
  }
};

const roundRect = (
  ctx: CanvasRenderingContext2D,
  w: number,
  h: number,
  x: number,
  y: number,
  radius = 2,
): void => {
  const r = x + w;
  const b = y + h;
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(r - radius, y);
  ctx.quadraticCurveTo(r, y, r, y + radius);
  ctx.lineTo(r, y + h - radius);
  ctx.quadraticCurveTo(r, b, r - radius, b);
  ctx.lineTo(x + radius, b);
  ctx.quadraticCurveTo(x, b, x, b - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.stroke();
  ctx.fill();
};

export const hoverDrawFunction = (args: MapDrawDrawFunctionArgs): void => {
  const { context: ctx, coord, feature } = args;
  const fontSize = 16;
  const padding = 20;
  // eslint-disable-next-line no-param-reassign
  ctx.font = `${fontSize}px Roboto`;
  const { x, y } = coord;
  ctx.strokeStyle = '#000';
  ctx.fillStyle = '#000';
  roundRect(
    ctx,
    ctx.measureText(feature.properties!.name).width + padding,
    fontSize + padding,
    x - padding * 0.5 - ctx.measureText(feature.properties!.name).width * 0.5,
    y + padding,
  );
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = '#FFF';
  // eslint-disable-next-line no-param-reassign
  ctx.textAlign = 'left';
  if (feature && feature.properties) {
    fitText(
      ctx,
      feature.properties.name,
      x - ctx.measureText(feature.properties.name).width * 0.5,
      y + padding * 2,
      fontSize,
    );
  }
};

export const useDrawOnMap = (
  mapId: string,
  drawFunction: (args: MapDrawDrawFunctionArgs) => void,
): [(location?: LocationDetail) => void] => {
  const dispatch = useDispatch();
  const layerId = React.useRef(generateLayerId()).current;
  const isMapPresent = useSelector((store: CoreAppStore) =>
    mapSelectors.getIsMapPresent(store, mapId),
  );
  const isLayerPresent = useSelector((store: CoreAppStore) =>
    layerSelectors.getIsLayerPresent(store, layerId),
  );
  const shouldAddLayer = isMapPresent && !isLayerPresent;

  React.useEffect(() => {
    if (shouldAddLayer) {
      dispatch(
        layerActions.addLayer({
          mapId,
          layerId,
          layer: {
            geojson: emptyGeoJSON,
            layerType: LayerType.featureLayer,
          },
          origin: 'SearchDialogConnect',
        }),
      );
    }
  }, [dispatch, layerId, mapId, shouldAddLayer]);

  const drawFunctionId = React.useRef<string>(
    registerDrawFunction(drawFunction),
  ).current;

  const hoverDrawFunctionId = React.useRef<string>(
    registerDrawFunction(hoverDrawFunction),
  ).current;

  const updateGeoJSON = React.useCallback(
    (location?: LocationDetail): void => {
      const geojson = location
        ? makeGeoJSONFeatureFromLocation(
            location,
            drawFunctionId,
            hoverDrawFunctionId,
          )
        : emptyGeoJSON;
      dispatch(layerActions.updateFeature({ layerId, geojson }));
    },
    [dispatch, drawFunctionId, hoverDrawFunctionId, layerId],
  );

  const zoomToFeature = React.useCallback(
    (location: LocationDetail): void => {
      const webMap = getWMJSMapById(mapId);
      if (webMap) {
        webMap.calculateBoundingBoxAndZoom(location.lat, location.lon);
      }
    },
    [mapId],
  );

  const onUpdateLocation = React.useCallback(
    (location?: LocationDetail): void => {
      updateGeoJSON(location);
      if (location) {
        zoomToFeature(location);
      }
    },
    [updateGeoJSON, zoomToFeature],
  );
  return [onUpdateLocation];
};

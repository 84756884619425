/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomDialog } from '@opengeoweb/shared';
import {
  DialogContentText,
  Box,
  Typography,
  MenuItem,
  Link,
  ListItem,
  List,
} from '@mui/material';
import { TFunction } from 'i18next';
import { useGeowebTranslation } from '../../i18n';

interface DocLink {
  url: string;
  title: string;
  subTitle: string;
}

export const userDocLinks: DocLink[] = [
  {
    url: 'https://gitlab.com/groups/opengeoweb/-/wikis/Documentation/User-Manual',
    title: 'geoweb-user-doc-user-manual',
    subTitle: 'geoweb-user-doc-user-manual-insight',
  },
];

export const technicalDocLinks: DocLink[] = [
  {
    url: 'https://opengeoweb.gitlab.io/opengeoweb/docs/documentation',
    title: 'geoweb-tech-doc-modules',
    subTitle: 'geoweb-tech-doc-modules-insight',
  },
  {
    url: 'https://gitlab.com/opengeoweb/opengeoweb',
    title: 'geoweb-tech-doc-nx-workspace',
    subTitle: 'geoweb-tech-doc-nx-workspace-insight',
  },
  {
    url: 'https://www.npmjs.com/search?q=opengeoweb',
    title: 'geoweb-tech-doc-npm-packages',
    subTitle: 'geoweb-tech-doc-npm-packages-insight',
  },
];

const renderDocumentationLinks: React.FC<{
  documentationLinks: DocLink[];
  t: TFunction;
}> = ({ documentationLinks, t }) => {
  return (
    <List sx={{ listStyleType: 'disc', pl: 2 }}>
      {documentationLinks.map((link) => (
        <ListItem
          key={link.url}
          sx={{
            display: 'list-item',
            padding: 0,
            minHeight: 'initial',
          }}
        >
          <Link href={link.url} target="_blank">
            {t(link.title)}
          </Link>
          <Typography variant="caption" sx={{ marginLeft: 1 }}>
            {t(link.subTitle)}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export const ProjectDocInfo: React.FC = () => {
  const { t } = useGeowebTranslation();
  const [open, setOpen] = React.useState(false);

  const handleToggleDialog = (): void => {
    setOpen(!open);
  };

  return (
    <>
      <MenuItem onClick={handleToggleDialog} divider>
        {t('geoweb-project-documentation')}
      </MenuItem>
      <CustomDialog
        title={t('geoweb-project-doc-into-title')}
        open={open}
        onClose={handleToggleDialog}
      >
        <DialogContentText>{t('geoweb-user-doc-title')}</DialogContentText>
        <Box>
          {renderDocumentationLinks({ documentationLinks: userDocLinks, t })}
        </Box>
        <DialogContentText>{t('geoweb-tech-doc-title')}</DialogContentText>
        <Box>
          {renderDocumentationLinks({
            documentationLinks: technicalDocLinks,
            t,
          })}
        </Box>
      </CustomDialog>
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch } from 'react-redux';

import ServiceChip from './ServiceChip';
import { ServiceFilterChipsObject } from '../../../store/types';
import { timeSeriesActions } from '../../../store';

export interface ServiceChipConnectProps {
  service: ServiceFilterChipsObject;
  isAllSelected: boolean;
}

export const ServiceChipConnect: React.FC<ServiceChipConnectProps> = ({
  service,
  isAllSelected,
}: ServiceChipConnectProps) => {
  const dispatch = useDispatch();

  const { serviceId } = service;
  const toggleChip = (): void => {
    if (service.enabled) {
      if (isAllSelected) {
        dispatch(
          timeSeriesActions.setOnlyThisServiceFilterChipSelected({
            serviceId,
          }),
        );
        return;
      }
      dispatch(
        timeSeriesActions.setServiceFilterChipUnselected({
          serviceId,
        }),
      );
      return;
    }
    dispatch(
      timeSeriesActions.setServiceFilterChipSelected({
        serviceId,
      }),
    );
  };
  return (
    <ServiceChip
      service={service}
      toggleChip={toggleChip}
      isSelected={isAllSelected ? false : service.enabled}
      isDisabled={service.isLoading}
    />
  );
};

export const AllChipConnect: React.FC<{ isAllSelected: boolean }> = ({
  isAllSelected,
}) => {
  const dispatch = useDispatch();

  return (
    <ServiceChip
      toggleChip={(): void => {
        if (!isAllSelected) {
          dispatch(timeSeriesActions.setAllServiceFilterChipSelected());
        }
      }}
      isSelected={isAllSelected}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { SystemScope } from '@opengeoweb/shared';
import { assign, pick } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Warning } from '../../store/publicWarningForm/types';
import {
  AllPhenomenaKeys,
  defaultDomainFilter,
  defaultEmptyFilter,
  defaultLevelFilter,
  defaultPhenomenonFilter,
  Domain,
  domainLevels,
  domainPhenomena,
  emptyFilterId,
  getFiltersFromWarnings,
  getSelectedOptions,
  getValue,
  initialFilters,
  Level,
  makeFilterFromList,
  updateState,
} from './filter-utils';

export interface PickedFilterState {
  incident: Partial<typeof defaultEmptyFilter>;
  source: Partial<typeof defaultEmptyFilter>;
  domain: Partial<typeof defaultDomainFilter>;
  phenomenon: Partial<typeof defaultPhenomenonFilter>;
  level: Partial<typeof defaultLevelFilter>;
}
interface FilterState extends PickedFilterState {
  incident: typeof defaultEmptyFilter;
  source: typeof defaultEmptyFilter;
  domain: typeof defaultDomainFilter;
  phenomenon: typeof defaultPhenomenonFilter;
  level: typeof defaultLevelFilter;
}

export type FilterKeyType = keyof FilterState;
export type OptionKey<T extends FilterKeyType> = keyof FilterState[T];

export type UpdateFilter = <T extends FilterKeyType>(
  filterKey: T | 'ALL',
  optionKey: OptionKey<T> | 'ALL',
  value: boolean,
  only?: boolean,
) => void;

export interface FilterHookState {
  allSelected: boolean;
  updateFilter: UpdateFilter;
  byFilterState: (warning: Warning) => boolean;
  filterState: PickedFilterState;
}

const getLevelsForDomainFilter = (
  domainFilter: Partial<Record<Domain, boolean>>,
): Level[] => {
  return (Object.keys(domainFilter) as Domain[]).flatMap((domain) =>
    domainFilter[domain] ? domainLevels[domain] : [],
  );
};

const getPhenomenaForDomainFilter = (
  domainFilter: Partial<Record<Domain, boolean>>,
): (keyof typeof AllPhenomenaKeys)[] => {
  return (Object.keys(domainFilter) as Domain[]).flatMap((domain) =>
    domainFilter[domain] ? domainPhenomena[domain] : [],
  ) as (keyof typeof AllPhenomenaKeys)[];
};

const getFilterOptions = <T extends FilterKeyType>(
  defaultFilter: Partial<FilterState[T]>,
  allOptions: OptionKey<T>[],
  savedFilter?: Partial<FilterState[T]>,
): [Partial<FilterState[T]>, boolean] => {
  const pickedFilterOptions = pick(defaultFilter, [
    emptyFilterId,
    ...allOptions,
  ]);

  // keep options state from savedFilter if it still exists
  const updatedOptions = assign(
    pickedFilterOptions,
    pick(savedFilter || {}, Object.keys(pickedFilterOptions)),
  );

  const { allSelected } = getSelectedOptions(updatedOptions);
  return [updatedOptions, allSelected];
};

export const useWarningFilters = (
  warnings: Warning[],
  onUpdateFilters: (
    filterState: PickedFilterState,
    origin: SystemScope,
  ) => void,
  savedFilterState: PickedFilterState,
  presetFilterState?: PickedFilterState,
): FilterHookState => {
  const {
    domain: pickedDomainFilter,
    phenomenon: pickedPhenomenonFilter,
    level: pickedLevelFilter,
    source: pickedSourceFilter,
    incident: pickedIncidentFilter,
  } = savedFilterState;

  /// level
  const relevantLevels = useMemo(
    () => getLevelsForDomainFilter(pickedDomainFilter),
    [pickedDomainFilter],
  );

  const [updatedPickedLevels, allLevelsSelected] = getFilterOptions<'level'>(
    defaultLevelFilter,
    relevantLevels,
    pickedLevelFilter,
  );

  // phenomenon
  const domainPhenomena = useMemo(
    () =>
      getPhenomenaForDomainFilter(
        pickedDomainFilter as Record<Domain, boolean>,
      ),
    [pickedDomainFilter],
  );

  const warningsPhenomena = useMemo(
    () => getFiltersFromWarnings(warnings, 'phenomenon'),
    [warnings],
  );

  const relevantPhenomena = useMemo(
    () =>
      domainPhenomena.filter((phenomenon) =>
        warningsPhenomena.includes(phenomenon),
      ),
    [warningsPhenomena, domainPhenomena],
  );

  relevantPhenomena.push(emptyFilterId as keyof typeof AllPhenomenaKeys);

  const [updatedPickedPhenomena, allPhenomenaSelected] =
    getFilterOptions<'phenomenon'>(
      defaultPhenomenonFilter,
      relevantPhenomena,
      pickedPhenomenonFilter,
    );

  // source
  const relevantSources = useMemo(
    () => getFiltersFromWarnings(warnings, 'warningProposalSource'),
    [warnings],
  );
  const defaultSourceFilter = useMemo(
    () => Object.fromEntries(relevantSources.map((key) => [key, true])),
    [relevantSources],
  );
  const [updatedPickedSources, allSourcesSelected] = getFilterOptions<'source'>(
    defaultSourceFilter,
    relevantSources,
    pickedSourceFilter,
  );

  // incident
  const relevantIncidents = useMemo(
    () => getFiltersFromWarnings(warnings, 'incident'),
    [warnings],
  );

  const defaultIncidentFilter = useMemo(
    () => Object.fromEntries(relevantIncidents.map((key) => [key, true])),
    [relevantIncidents],
  );
  const [updatedPickedIncidents, allIncidentsSelected] =
    getFilterOptions<'incident'>(
      defaultIncidentFilter,
      relevantIncidents,
      pickedIncidentFilter,
    );

  useEffect(() => {
    // when the preset changes, update the filters
    if (presetFilterState) {
      const initialFiltersWithPresets = {
        incident: {
          ...makeFilterFromList(relevantIncidents),
          ...presetFilterState.incident,
        },
        source: {
          ...makeFilterFromList(relevantSources),
          ...presetFilterState.source,
        },
        domain: { ...initialFilters.domain, ...presetFilterState.domain },
        phenomenon: {
          ...initialFilters.phenomenon,
          ...presetFilterState.phenomenon,
        },
        level: { ...initialFilters.level, ...presetFilterState.level },
      };
      onUpdateFilters(initialFiltersWithPresets, 'system');
    } else {
      // for a preset without filters saved use the initial filters
      onUpdateFilters(
        {
          ...initialFilters,
          incident: makeFilterFromList(relevantIncidents),
          source: makeFilterFromList(relevantSources),
        },
        'system',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetFilterState, onUpdateFilters]);

  const { allSelected: allDomainSelected } =
    getSelectedOptions(pickedDomainFilter);

  const allSelected =
    allDomainSelected &&
    allLevelsSelected &&
    allPhenomenaSelected &&
    allIncidentsSelected &&
    allSourcesSelected;

  /**
   * @param filterKey - Name of the filter | "ALL" filters
   * @param optionKey - Name of the option | "ALL" options
   * @param value - The new value
   * @param only - Flag that decides if all other options should be disabled
   */
  const updateFilter: UpdateFilter = (filterKey, optionKey, value, only) => {
    const levelFilter = value ? defaultLevelFilter : defaultEmptyFilter;
    if (filterKey === 'ALL') {
      onUpdateFilters(
        {
          domain: updateState(pickedDomainFilter, 'ALL', value),
          level: updateState(levelFilter, 'ALL' as OptionKey<'level'>, value),
          phenomenon: updateState(
            pickedPhenomenonFilter,
            'ALL' as OptionKey<'phenomenon'>,
            value,
          ),
          incident: updateState(pickedIncidentFilter, 'ALL', value),
          source: updateState(pickedSourceFilter, 'ALL', value),
        },
        'user',
      );
    } else if (filterKey === 'domain') {
      const newDomain = updateState(
        pickedDomainFilter,
        optionKey as OptionKey<'domain'>,
        value,
        only,
      );
      const relevantLevels = getLevelsForDomainFilter(newDomain);
      const [newLevel] = getFilterOptions<'level'>(
        defaultLevelFilter,
        relevantLevels,
        pickedLevelFilter,
      );

      onUpdateFilters(
        {
          ...savedFilterState,
          domain: newDomain,
          level: newLevel, // levels depend on selected domains
        },
        'user',
      );
    } else if (filterKey === 'level') {
      onUpdateFilters(
        {
          ...savedFilterState,
          level: updateState(
            pickedLevelFilter,
            optionKey as OptionKey<'level'>,
            value,
            only,
          ),
        },
        'user',
      );
    } else if (filterKey === 'phenomenon') {
      onUpdateFilters(
        {
          ...savedFilterState,
          phenomenon: updateState(
            pickedPhenomenonFilter,
            optionKey as OptionKey<'phenomenon'>,
            value,
            only,
          ),
        },
        'user',
      );
    } else if (filterKey === 'source') {
      onUpdateFilters(
        {
          ...savedFilterState,
          source: updateState(
            pickedSourceFilter,
            optionKey as OptionKey<'source'>,
            value,
            only,
          ),
        },
        'user',
      );
    } else if (filterKey === 'incident') {
      onUpdateFilters(
        {
          ...savedFilterState,
          incident: updateState(
            pickedIncidentFilter,
            optionKey as OptionKey<'incident'>,
            value,
            only,
          ),
        },
        'user',
      );
    }
  };

  const byFilterState = useCallback(
    (warning: Warning): boolean => {
      const levelDomain = ['SIG', 'AIR'].find(
        (d) => d === warning.warningDetail.level,
      )
        ? Domain.aviation
        : Domain.public;
      if (!pickedDomainFilter[levelDomain]) {
        return false;
      }
      const level = getValue(Level, warning.warningDetail.level);
      if (!pickedLevelFilter[level]) {
        return false;
      }
      const phenomenon = getValue(
        AllPhenomenaKeys,
        warning.warningDetail.phenomenon,
      );
      if (
        !pickedPhenomenonFilter[
          phenomenon as keyof typeof pickedPhenomenonFilter
        ]
      ) {
        return false;
      }
      const source =
        relevantSources.find(
          (source) => source === warning.warningDetail.warningProposalSource,
        ) || emptyFilterId;
      if (!updatedPickedSources[source]) {
        return false;
      }
      const incident =
        relevantIncidents.find(
          (incident) => incident === warning.warningDetail.incident,
        ) || emptyFilterId;
      if (!updatedPickedIncidents[incident]) {
        return false;
      }
      return true;
    },
    [
      pickedDomainFilter,
      pickedLevelFilter,
      pickedPhenomenonFilter,
      relevantSources,
      updatedPickedSources,
      relevantIncidents,
      updatedPickedIncidents,
    ],
  );

  return {
    allSelected,
    updateFilter,
    byFilterState,
    filterState: {
      domain: pickedDomainFilter,
      level: updatedPickedLevels,
      phenomenon: updatedPickedPhenomena,
      incident: updatedPickedIncidents,
      source: updatedPickedSources,
    },
  };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useQueryClient, UseQueryResult } from '@tanstack/react-query';
import {
  AirmetConfig,
  AirmetFromBackend,
  SigmetConfig,
  SigmetFromBackend,
  useAuthenticationContext,
  useAuthQuery,
} from '@opengeoweb/api';
import { AviationProduct } from '@opengeoweb/sigmet-airmet';
import {
  getAirmetConfig,
  getAirmetList,
  getAirmetTAC,
  getSigmetConfig,
  getSigmetList,
  getSigmetTAC,
} from './api';
import { WarningType } from '../../store/publicWarningForm/types';

export const useAirmetList = (
  disabled = false,
): UseQueryResult<AirmetFromBackend[]> =>
  useAuthQuery(['airmet-list'], getAirmetList, disabled);

export const useSigmetList = (
  disabled = false,
): UseQueryResult<SigmetFromBackend[]> =>
  useAuthQuery(['sigmet-list'], getSigmetList, disabled);

export const useProductConfig = (
  warningType?: WarningType,
  disabled = false,
): UseQueryResult<SigmetConfig | AirmetConfig> => {
  const airmetQuery = useAuthQuery(
    ['airmet-config'],
    getAirmetConfig,
    disabled,
  );
  const sigmetQuery = useAuthQuery(
    ['sigmet-config'],
    getSigmetConfig,
    disabled,
  );

  return warningType === 'airmet' ? airmetQuery : sigmetQuery;
};

type FetchTACData = Promise<{ data: string }>;
export const useTACApi = (): {
  fetchSigmetTAC: (product: AviationProduct) => FetchTACData;
  fetchAirmetTAC: (product: AviationProduct) => FetchTACData;
} => {
  const { auth } = useAuthenticationContext();
  const queryClient = useQueryClient();

  const fetchSigmetTAC = (product: AviationProduct): FetchTACData =>
    queryClient.fetchQuery({
      queryKey: ['sigmet-tac', product],
      queryFn: (): FetchTACData => getSigmetTAC(auth!, product),
    });
  const fetchAirmetTAC = (product: AviationProduct): FetchTACData =>
    queryClient.fetchQuery({
      queryKey: ['airmet-tac', product],
      queryFn: (): FetchTACData => getAirmetTAC(auth!, product),
    });

  return { fetchSigmetTAC, fetchAirmetTAC };
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { uiActions, uiSelectors } from '@opengeoweb/store';
import { AxiosError, isAxiosError } from 'axios';
import { getAxiosErrorMessage } from '@opengeoweb/shared';
import { Dispatch, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { publicWarningFormActions } from './reducer';
import { WarningsApi, getWarningsApi } from '../../utils/api';
import { publicWarningDialogType } from './utils';
import { PublicWarning } from './types';

import { WarningModuleStore } from '../types';
import { areaObjectLoaderType } from '../warningsDrawings/utils';

const getErrorMessage = (error: AxiosError | Error): string =>
  isAxiosError(error) ? getAxiosErrorMessage(error) : error.message;

export const publicWarningFormListener =
  createListenerMiddleware<WarningModuleStore>();

publicWarningFormListener.startListening({
  actionCreator: publicWarningFormActions.publishWarning,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    try {
      const { publicWarning, refetchWarnings } = payload;
      const publishedWarning = await saveOrUpdateWarning(publicWarning);

      if (refetchWarnings) {
        refetchWarnings();
      }
      listenerApi.dispatch(
        publicWarningFormActions.publishWarningSuccess({
          publicWarning: publishedWarning,
          message: {
            type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
            key: 'warning-publish-succes',
          },
        }),
      );

      listenerApi.dispatch(
        uiActions.setToggleOpenDialog({
          type: publicWarningDialogType,
          setOpen: false,
        }),
      );
    } catch (error) {
      const isDialogOpen = uiSelectors.getisDialogOpen(
        listenerApi.getState(),
        publicWarningDialogType,
      );
      errorEffect(
        listenerApi.dispatch,
        getErrorMessage(error as Error),
        isDialogOpen,
      );
    }
  },
});

publicWarningFormListener.startListening({
  actionCreator: publicWarningFormActions.saveWarning,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();
    try {
      const { publicWarning, refetchWarnings } = payload;
      const savedWarning = await saveOrUpdateWarning(publicWarning);

      if (refetchWarnings) {
        refetchWarnings();
      }
      listenerApi.dispatch(
        publicWarningFormActions.saveWarningSuccess({
          publicWarning: savedWarning,
          message: {
            type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
            key: 'warning-save-succes',
          },
        }),
      );
    } catch (error) {
      const isDialogOpen = uiSelectors.getisDialogOpen(
        listenerApi.getState(),
        publicWarningDialogType,
      );
      errorEffect(
        listenerApi.dispatch,
        getErrorMessage(error as Error),
        isDialogOpen,
      );
    }
  },
});

// Side effects/snackbar listeners
publicWarningFormListener.startListening({
  matcher: isAnyOf(
    publicWarningFormActions.publishWarningSuccess,
    publicWarningFormActions.saveWarningSuccess,
  ),
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const { message } = payload;
    listenerApi.dispatch(snackbarActions.openSnackbar(message));

    const isDialogOpen = uiSelectors.getisDialogOpen(
      listenerApi.getState(),
      publicWarningDialogType,
    );

    toggleDialogLoadingEffect(listenerApi.dispatch, isDialogOpen, false);
  },
});

publicWarningFormListener.startListening({
  matcher: isAnyOf(
    publicWarningFormActions.publishWarning,
    publicWarningFormActions.saveWarning,
  ),
  effect: async (_, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const isDialogOpen = uiSelectors.getisDialogOpen(
      listenerApi.getState(),
      publicWarningDialogType,
    );

    toggleDialogLoadingEffect(listenerApi.dispatch, isDialogOpen, true);
  },
});

publicWarningFormListener.startListening({
  actionCreator: publicWarningFormActions.openPublicWarningFormDialog,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { panelId } = payload;

    listenerApi.dispatch(
      uiActions.setToggleOpenDialog({
        setOpen: true,
        type: publicWarningDialogType,
        sourcePanelId: panelId,
      }),
    );
  },
});

const saveOrUpdateWarning = async (
  publicWarning: PublicWarning,
): Promise<PublicWarning> => {
  const warningsApi: WarningsApi = getWarningsApi();
  const { warningDetail, status } = publicWarning;

  if (warningDetail.id) {
    await warningsApi.updateWarning(warningDetail.id, publicWarning);
    return publicWarning;
  }

  // In case of directly publishing an update of a published warning
  if (status === 'PUBLISHED' && warningDetail.linked_to_id) {
    const warning = {
      ...publicWarning,
      warningDetail: {
        ...warningDetail,
        id: warningDetail.linked_to_id,
      },
    };
    await warningsApi.updateWarning(warningDetail.linked_to_id, warning);
    return warning;
  }

  const newWarningId = await warningsApi.saveWarningAs(publicWarning);
  return {
    ...publicWarning,
    warningDetail: { ...warningDetail, id: newWarningId },
  };
};

const toggleDialogLoadingEffect = (
  dispatch: Dispatch,
  isDialogOpen: boolean,
  isLoading: boolean,
): void => {
  if (isDialogOpen) {
    dispatch(
      uiActions.toggleIsLoadingDialog({
        isLoading,
        type: publicWarningDialogType,
      }),
    );
  }
};

const errorEffect = (
  dispatch: Dispatch,
  message: string,
  isDialogOpen: boolean,
): void => {
  dispatch(publicWarningFormActions.setFormError({ message }));
  toggleDialogLoadingEffect(dispatch, isDialogOpen, false);
};

publicWarningFormListener.startListening({
  actionCreator: uiActions.setToggleOpenDialog,
  effect: async ({ payload }, listenerApi) => {
    if (!payload.setOpen && payload.type === publicWarningDialogType) {
      listenerApi.dispatch(
        uiActions.setToggleOpenDialog({
          setOpen: false,
          type: areaObjectLoaderType,
        }),
      );
    }
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { dateUtils } from '@opengeoweb/shared';
import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  useTheme,
} from '@mui/material';
import { Bulletin } from '../../types';
import { useSpaceweatherTranslation } from '../../utils/i18n';

export interface BulletinHistoryListProps {
  selectedBulletin: string;
  bulletinHistoryList: Bulletin[];
  onSelectBulletin: (bulletin: string) => void;
}

export const BulletinHistoryList: React.FC<BulletinHistoryListProps> = ({
  selectedBulletin,
  bulletinHistoryList,
  onSelectBulletin,
}: BulletinHistoryListProps) => {
  const theme = useTheme();
  const { t } = useSpaceweatherTranslation();
  return (
    <List
      sx={{
        height: 550,
        overflowY: 'scroll',
        paddingTop: '0px',
      }}
    >
      <ListSubheader
        sx={{
          fontSize: '12px',
          backgroundColor: theme.palette.geowebColors.background.surfaceApp,
        }}
      >
        {t('bulletin-publication-date')}
      </ListSubheader>
      {bulletinHistoryList.map((bulletin) => {
        return (
          <ListItemButton
            data-testid="historyItem"
            key={bulletin.bulletin_id}
            selected={selectedBulletin === bulletin.bulletin_id}
            onClick={(): void => {
              onSelectBulletin(bulletin.bulletin_id);
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
              }}
              primary={dateUtils.dateToString(
                dateUtils.utc(bulletin.message.overview.timestamp),
                dateUtils.DATE_FORMAT_DATEPICKER,
              )}
            />
          </ListItemButton>
        );
      })}
    </List>
  );
};

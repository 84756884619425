/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC, useEffect, useState } from 'react';
import { LinearProgress, MenuItem, SelectChangeEvent } from '@mui/material';
import { TooltipSelect, dateUtils } from '@opengeoweb/shared';

import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { EDRInstance, Parameter } from '../../TimeSeries/types';

import { TimeSeriesModuleState } from '../../../store/types';
import { getService } from '../../../store/selectors';

import { fetchEdrAllInstances } from '../../../utils/edrUtils';

export const ReferenceTimeSelect: FC<{
  rowIsEnabled: boolean;
  parameter: Parameter;
  patchParameter: (parameter: Partial<Parameter>) => void;
  t: TFunction;
}> = ({ rowIsEnabled, parameter, patchParameter, t }) => {
  const [refTimeList, setRefTimeList] = useState<EDRInstance[]>([]);

  const service = useSelector((state: TimeSeriesModuleState) =>
    getService(state, parameter.serviceId),
  );

  useEffect(() => {
    if (service && service.type === 'EDR') {
      fetchEdrAllInstances(service.url, parameter.collectionId)
        .then((refTimeList: EDRInstance[]) => {
          // Sort the reference times in descending order (newest first)
          const sortedRefTimeList = refTimeList.sort((a, b) => {
            return (
              dateUtils.parseCustomDateString(b.id).getTime() -
              dateUtils.parseCustomDateString(a.id).getTime()
            );
          });
          setRefTimeList(sortedRefTimeList);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameter.collectionId, service?.url]);

  const isFetching = refTimeList.length === 0;

  const paramInstanceId: string | undefined =
    parameter.instanceId ||
    (refTimeList.length > 0 ? refTimeList[0].id : undefined);

  if (!paramInstanceId) {
    return null;
  }

  return refTimeList &&
    refTimeList.length > 0 &&
    !refTimeList.some((value) => value.id === 'unvalidated') ? (
    <TooltipSelect
      SelectDisplayProps={{ 'aria-label': 'timeseriesManager-refTime' }}
      value={paramInstanceId}
      tooltip={t('timeseries-choose-ref-time')}
      isEnabled={rowIsEnabled}
      onChange={(event: SelectChangeEvent<unknown>): void => {
        const target = event.target as HTMLInputElement;
        const selectedRefTime = refTimeList.find((ref) => {
          return ref.id === target.value;
        });
        if (selectedRefTime) {
          const newParameter: Partial<Parameter> = {
            id: parameter.id,
            instanceId: target.value,
          };
          patchParameter(newParameter);
        } else {
          console.warn(`Unable to find reference time ${event.target.value}`);
        }
      }}
    >
      {isFetching && <LinearProgress data-testid="spinner" color="inherit" />}
      <MenuItem disabled>{t('timeseries-reference-time')}</MenuItem>
      {isFetching && (
        <MenuItem value={paramInstanceId}>
          {dateUtils.isValid(dateUtils.parseCustomDateString(paramInstanceId))
            ? dateUtils.dateToString(
                dateUtils.parseCustomDateString(paramInstanceId),
                "yyyy-MM-dd'T'HH:mm:ss 'Z'",
              )
            : paramInstanceId}
        </MenuItem>
      )}
      {refTimeList.map((value: EDRInstance) => (
        <MenuItem value={value.id} key={value.id}>
          {dateUtils.isValid(dateUtils.parseCustomDateString(value.id))
            ? dateUtils.dateToString(
                dateUtils.parseCustomDateString(value.id),
                "yyyy-MM-dd'T'HH:mm:ss 'Z'",
              )
            : value.id}
        </MenuItem>
      ))}
    </TooltipSelect>
  ) : null;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceTab } from './WorkspaceTab';
import { AppStore } from '../../store/types';
import * as workspaceSelectors from '../../store/workspace/selectors';
import WorkspacePresetsFormDialog from '../WorkspacePresetsFormDialog/WorkspacePresetsFormDialog';
import * as workspaceListSelectors from '../../store/workspaceList/selectors';
import { workspaceListActions } from '../../store/workspaceList/reducer';
import {
  WorkspacePresetAction,
  WorkspacePresetFromBE,
} from '../../store/workspace/types';
import { useWorkspaceTranslation } from '../../utils/i18n';
import { WorkspaceFormValues } from '../../store/workspaceList/types';

interface WorkspaceTabConnectProps {
  workspaceId: string;
  appTitle?: string;
}

export const WorkspaceTabConnect: React.FC<WorkspaceTabConnectProps> = ({
  workspaceId,
  appTitle,
}: WorkspaceTabConnectProps) => {
  const dispatch = useDispatch();
  const { t } = useWorkspaceTranslation();

  const title = useSelector((store: AppStore) =>
    workspaceSelectors.getSelectedWorkspaceTitle(store),
  );
  const dialogDetails = useSelector((store: AppStore) =>
    workspaceListSelectors.getWorkspaceActionDialogDetails(store),
  );

  const closeWorkspaceActionDialog = React.useCallback(() => {
    dispatch(workspaceListActions.closeWorkspaceActionDialogOptions());
  }, [dispatch]);

  const onSubmitForm = (
    presetAction: WorkspacePresetAction,
    presetId: string,
    data: WorkspacePresetFromBE,
  ): void => {
    dispatch(
      workspaceListActions.submitFormWorkspaceActionDialogOptions({
        presetAction,
        presetId,
        data,
        emptyMapPresetTitle: t('workspace-mappreset-new'),
        emptyWorkspacePresetTitle: t('workspace-new'),
      }),
    );
  };

  const presetId = typeof dialogDetails === 'object' && dialogDetails.presetId;
  const action = typeof dialogDetails === 'object' && dialogDetails.action;
  const formValues =
    typeof dialogDetails === 'object' && dialogDetails.formValues;
  const isFetching =
    (typeof dialogDetails === 'object' && dialogDetails.isFetching) || false;

  const error = typeof dialogDetails === 'object' && dialogDetails.error;

  const hasSavedWorkspace =
    typeof dialogDetails === 'object' && dialogDetails.hasSaved;

  return (
    <>
      <WorkspaceTab
        activeTab
        title={title}
        workspaceId={workspaceId}
        appTitle={appTitle}
      />
      {Boolean(dialogDetails) && (
        <WorkspacePresetsFormDialog
          isOpen={Boolean(dialogDetails)}
          onClose={closeWorkspaceActionDialog}
          presetId={presetId || undefined!}
          action={action || undefined!}
          formValues={(formValues as WorkspaceFormValues) || undefined!}
          onFormSubmit={onSubmitForm}
          isLoading={isFetching}
          error={error || undefined!}
          isWorkspaceSaved={hasSavedWorkspace}
        />
      )}
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  GetCapabilitiesJson,
  WMSServiceInfo,
  LayerTree,
  LayerProps,
  WMLayer,
  webmapUtils,
  LayerType,
  privateWebMapUtils,
  Style,
} from '@opengeoweb/webmap';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

import React, { useRef } from 'react';

const {
  privateWmsGetCapabilities,
  privateGetWMSServiceInfo,
  privateWmsGetLayerTree,
  privateWmsFlattenLayerTree,
  privateWmtsGetCapabilities,
  QUERYWMS_GETCAPABILITIES,
} = privateWebMapUtils;

/**
 * Queries the WMS GetCapabilities. This is the base TanStack hook for the other hooks in this file.
 *
 * @param {string} serviceUrl
 * @returns {UseQueryResult<GetCapabilitiesJson>}
 */
export const useQueryGetWMSGetCapabilities = (
  serviceUrl: string,
): UseQueryResult<GetCapabilitiesJson> => {
  const uriKey = new URLSearchParams(serviceUrl).toString();
  return useQuery({
    queryKey: [uriKey, QUERYWMS_GETCAPABILITIES],
    queryFn: () => privateWmsGetCapabilities(serviceUrl),
  });
};

/**
 * Returns details about the WMS service
 *
 * @param {string} serviceUrl
 * @returns {(WMSServiceInfo | null)}
 */
export const useQueryGetWMSServiceInfo = (
  serviceUrl: string,
): WMSServiceInfo | null => {
  const [serviceInfo, setServiceInfo] = React.useState<WMSServiceInfo | null>(
    null,
  );
  const { data: getCapabilitiesJSON } =
    useQueryGetWMSGetCapabilities(serviceUrl);
  React.useEffect((): void => {
    if (!getCapabilitiesJSON) {
      setServiceInfo(null);
    } else {
      setServiceInfo(privateGetWMSServiceInfo(getCapabilitiesJSON, serviceUrl));
    }
  }, [serviceUrl, getCapabilitiesJSON]);
  return serviceInfo;
};

/**
 * Gets the layer tree in hierarchical order for a WMS service. Cached by TanStack. Uses the same store as in queryWMSGetCapabilities.
 *
 * @param {string} serviceUrl
 * @returns {(LayerTree | null)}
 */
export const useQueryGetWMSLayersTree = (
  serviceUrl: string,
): LayerTree | null => {
  const [layerTree, setLayerTree] = React.useState<LayerTree | null>(null);
  const { data: getCapabilitiesJSON } =
    useQueryGetWMSGetCapabilities(serviceUrl);
  React.useEffect((): void => {
    if (!getCapabilitiesJSON) {
      setLayerTree(null);
    } else {
      setLayerTree(privateWmsGetLayerTree(getCapabilitiesJSON!));
    }
  }, [serviceUrl, getCapabilitiesJSON]);
  return layerTree;
};

/**
 * Gets the flat layer list (without hierarchy) for a WMS service. Cached by TanStack. Uses the same store as in queryWMSGetCapabilities.
 *
 * @param {string} serviceUrl
 * @returns {LayerProps[]}
 */
export const useQueryGetWMSLayers = (serviceUrl: string): LayerProps[] => {
  const [layers, setLayers] = React.useState<LayerProps[]>([]);
  const { data: getCapabilitiesJSON } =
    useQueryGetWMSGetCapabilities(serviceUrl);
  React.useEffect((): void => {
    if (!getCapabilitiesJSON) {
      setLayers([]);
    } else {
      setLayers(
        privateWmsFlattenLayerTree(
          privateWmsGetLayerTree(getCapabilitiesJSON!),
        ),
      );
    }
  }, [serviceUrl, getCapabilitiesJSON]);
  return layers;
};

/**
 * Returns specific layer from the WMS service
 *
 * @param {string} serviceUrl
 * @param {string} name
 * @returns {(LayerProps | null)}
 */
export const useQueryGetWMSLayer = (
  serviceUrl: string,
  name: string,
): LayerProps | null => {
  const [layer, setLayer] = React.useState<LayerProps | null>(null);
  const layers = useQueryGetWMSLayers(serviceUrl);
  React.useEffect((): void => {
    setLayer(layers.find((l) => l.name === name) || null);
  }, [layers, name]);

  return layer;
};

/**
 * Returns a stylelist for given layer in a service
 *
 * @param {string} serviceUrl
 * @param {string} name
 * @returns {(Style[] | null)}
 */
export const useGetWMSLayerStyleList = (
  serviceUrl: string,
  name: string,
): Style[] | null => {
  return useQueryGetWMSLayer(serviceUrl, name)?.styles || null;
};

/**
 * Returns WMLayer instance. The layer will contains parsed dimensions and styles and keeps a state for these properties.
 *
 * @param {string} serviceUrl
 * @param {string} name
 * @returns {(WMLayer | null)}
 */
export const useGetWMLayerInstance = (
  serviceUrl: string,
  name: string,
  id?: string,
): WMLayer | null => {
  const [layer, setLayer] = React.useState<WMLayer | null>(null);
  const refId = useRef(webmapUtils.generateLayerId()).current;
  const layerId = id || refId;
  React.useEffect((): void => {
    // Check if the layer is already registered
    const l = webmapUtils.getWMLayerById(layerId);
    if (!l || l.service !== serviceUrl) {
      // Make a new layer if the serviceUrl changes. (But not when the name changes)
      const wmLayer = new WMLayer({
        name,
        service: serviceUrl,
        layerType: LayerType.mapLayer,
        id: layerId,
      });
      webmapUtils.registerWMLayer(wmLayer, layerId);
    }

    // Set the name and parse the layer
    const wmLayer = webmapUtils.getWMLayerById(layerId);
    wmLayer
      .setName(name)
      .then(() => {
        webmapUtils.registerWMLayer(wmLayer, layerId);
        setLayer(wmLayer);
      })
      .catch((e) => {
        console.error(e);
        webmapUtils.registerWMLayer(wmLayer, layerId);
        setLayer(wmLayer);
      });
  }, [layerId, name, serviceUrl]);

  return layer;
};

/**
 * Used for querying WMTS GetCapabilities documents
 * @param serviceUrl
 * @returns
 */
export const useQueryWMTSGetCapabilities = (
  serviceUrl: string,
): UseQueryResult<GetCapabilitiesJson> => {
  const uriKey = new URLSearchParams(serviceUrl).toString();
  return useQuery({
    queryKey: [uriKey, 'queryGetCapabilities'],
    queryFn: () => privateWmtsGetCapabilities(serviceUrl, [], true),
  });
};

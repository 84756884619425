/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { LayerFoundation, IWMJSMap, WMLayer } from '@opengeoweb/webmap';
import { DrawModeExitCallback, FeatureEvent } from '../MapDraw';

export interface MapViewLayerProps extends LayerFoundation {
  id: string;
  // eslint-disable-next-line react/no-unused-prop-types
  onLayerReady?: (layer: WMLayer, webmap?: IWMJSMap) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onLayerError?: (layer: WMLayer, error?: Error) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  isInEditMode?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  drawMode?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  updateGeojson?: (geoJson: GeoJSON.FeatureCollection, reason: string) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  exitDrawModeCallback?: (
    reason: DrawModeExitCallback,
    newGeoJSON?: GeoJSON.FeatureCollection,
  ) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  selectedFeatureIndex?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  onClickFeature?: (event?: FeatureEvent) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onHoverFeature?: (event: FeatureEvent) => void;
}

export const MapViewLayer: React.FC<MapViewLayerProps> = (
  props: MapViewLayerProps,
) => {
  // TODO: This component should not do anything, but just return null: https://gitlab.com/opengeoweb/opengeoweb/-/issues/4579
  const { id } = props;
  return React.useMemo(() => {
    return (
      <div
        data-testid="mapViewLayer"
        style={{
          display: 'none',
        }}
      >
        {id}
      </div>
    );
  }, [id]);
};

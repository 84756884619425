/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Chip, useTheme } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';

export interface FilterChipProps {
  id: string;
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  handleClick?: (label: string, isSelected?: boolean) => void;
  actionIcon?: React.ReactElement;
  onClick?: (ref: HTMLElement | null) => void;
  size?: 'small' | 'medium';
}

const FilterChip: React.FC<FilterChipProps> = ({
  label,
  id,
  isSelected = false,
  isDisabled = false,
  handleClick = (): void => {},
  onClick,
  actionIcon,
  size = 'small',
}: FilterChipProps) => {
  const theme = useTheme();

  const chipRef = useRef<HTMLDivElement>(null);

  const clickHandler = (): void =>
    onClick ? onClick(chipRef.current) : handleClick(id, isSelected);

  return (
    <Chip
      ref={chipRef}
      size={size}
      id={id}
      label={label}
      disabled={isDisabled}
      variant={isSelected ? 'filled' : 'outlined'} // Chip does not have a selected state so theme class cannot be used for styling
      color={isSelected ? 'secondary' : 'default'}
      sx={{
        borderColor: `${theme.palette.geowebColors.buttons.tertiary.default.borderColor}`,
        padding: '0px 4px',
        '& .MuiSvgIcon-root, .MuiChip-deleteIcon': {
          fill: isSelected
            ? theme.palette.geowebColors.buttons.secondary.active.color!
            : theme.palette.geowebColors.buttons.flat.default.color!,
          color: isSelected
            ? theme.palette.geowebColors.buttons.secondary.active.color!
            : theme.palette.geowebColors.buttons.flat.default.color!,
        },
        '& .MuiChip-filled': {
          border: '1px solid transparent',
        },
        '&.MuiChip-sizeMedium > .MuiChip-label': {
          overflow: 'visible',
        },
      }}
      onClick={clickHandler}
      deleteIcon={actionIcon}
      onDelete={actionIcon ? clickHandler : undefined}
    />
  );
};

export default FilterChip;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';

import { ApiProvider, ApiUrls } from '@opengeoweb/api';
import { InitialMapProps } from '@opengeoweb/core';
import { WarningListCapConnectWrapper } from '../WarningListCap/WarningListCapConnectWrapper';
import { CapFeatures } from '../types';
import { createApi } from '../../utils/api';
import { MapViewCapConnectWrapper } from '../MapViewCap/MapViewCapConnectWrapper';

export type SupportedComponentTypes = 'CapWarningList' | 'CapWarningMap';

interface InitialProps {
  mapPreset: {
    layers?: InitialMapProps['mapPreset']['layers'];
    proj: {
      bbox: {
        left: number;
        bottom: number;
        right: number;
        top: number;
      };
      srs: string;
    };
  };
  geoJson: CapFeatures;
}

export interface ComponentsLookUpPayload {
  productConfigKey: string;
  componentType: SupportedComponentTypes;
  id: string;
  initialProps: InitialProps;
  config: ApiUrls;
}

/**
 * The lookup table is for registering your own components with the workspace.
 * @param payload
 * @returns
 */

export const ComponentsLookUp = ({
  componentType,
  initialProps,
  productConfigKey,
  config,
  id,
}: ComponentsLookUpPayload): React.ReactElement => {
  // TODO: implement polling when backend is ready
  // NOTE: Fetching data inside this component might not work, as using React Hooks causes Invalid Hook Call -errors in tests.

  switch (componentType) {
    case 'CapWarningMap': {
      return (
        <ApiProvider
          config={{
            baseURL: config.baseURL,
          }}
          createApi={createApi}
        >
          <MapViewCapConnectWrapper
            id={id}
            bbox={initialProps.mapPreset.proj?.bbox}
            srs={initialProps.mapPreset.proj?.srs}
            layers={initialProps.mapPreset.layers}
            productConfigKey={productConfigKey}
          />
        </ApiProvider>
      );
    }
    case 'CapWarningList': {
      return (
        <ApiProvider
          config={{
            baseURL: config.baseURL,
          }}
          createApi={createApi}
        >
          <WarningListCapConnectWrapper productConfigKey={productConfigKey} />
        </ApiProvider>
      );
    }
    default:
      return null!;
  }
};

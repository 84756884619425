/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { NumberSize, Resizable, ResizeDirection, Size } from 're-resizable';

import { Resize } from '@opengeoweb/theme';
import { useTheme } from '@mui/material';
import { ResizableComponentProps } from './types';

export const isDraggingClassName = 'is-dragging';

export interface BoundsProps {
  bounds: HTMLElement;
  boundsByDirection: boolean;
}

export const useBoundsProps = (
  nodeRef?: React.RefObject<HTMLElement>,
): BoundsProps | object => {
  const [boundsProps, setBoundsProps] = React.useState({});
  React.useEffect(() => {
    if (nodeRef) {
      setBoundsProps({
        bounds: nodeRef?.current?.parentNode as HTMLElement,
        boundsByDirection: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return boundsProps;
};

const ResizeComponent: React.FC<ResizableComponentProps> = ({
  children,
  onResizeStart = (): void => {},
  onResizeStop = (): void => {},
  size,
  setSize = (): void => {},
  minHeight = 50,
  minWidth = 50,
  enable = {
    top: false,
    right: false,
    bottom: false,
    left: false,
    topRight: true,
    bottomRight: true,
    bottomLeft: true,
    topLeft: true,
  },
  nodeRef,
  ...props
}: ResizableComponentProps) => {
  const theme = useTheme();

  const onStartResize = React.useCallback(
    (
      event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
      _direction: ResizeDirection,
      node: HTMLElement,
    ) => {
      event.stopPropagation();
      node.classList.add(isDraggingClassName);

      const { width, height } = node.getBoundingClientRect();
      setSize({ width, height });

      // fix for when initial maxHeight gets cleared
      // eslint-disable-next-line no-param-reassign
      node.style.height = `${height}px`;
      onResizeStart();
    },
    [onResizeStart, setSize],
  );

  const onStopResize = React.useCallback(
    (
      event: MouseEvent | TouchEvent,
      direction: ResizeDirection,
      node: HTMLElement,
      delta: NumberSize,
    ): void => {
      node.classList.remove(isDraggingClassName);

      if (!size) {
        return;
      }
      const width = parseInt(size.width as string, 10) + delta.width;
      const height = parseInt(size.height as string, 10) + delta.height;

      setSize({
        width,
        height,
      });
      onResizeStop(event, direction, node, delta);
    },
    [setSize, onResizeStop, size],
  );

  const boundsProps = useBoundsProps(nodeRef);

  return (
    <Resizable
      minHeight={minHeight}
      minWidth={minWidth}
      size={size as Size}
      onResizeStop={onStopResize}
      {...boundsProps}
      handleComponent={{
        bottomRight: (
          <Resize
            style={{
              fill: `${theme.palette.geowebColors.buttons.flat.default.color}`,
            }}
            sx={{ display: 'none' }}
            className="resizableHandle"
          />
        ),
        bottomLeft: (
          <Resize
            isRightAligned
            style={{
              fill: `${theme.palette.geowebColors.buttons.flat.default.color}`,
            }}
            sx={{ display: 'none' }}
            className="resizableHandle"
          />
        ),
      }}
      enable={enable}
      onResizeStart={onStartResize}
      {...props}
    >
      {children}
    </Resizable>
  );
};

export default ResizeComponent;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoreAppStore, layerActions, layerSelectors } from '@opengeoweb/store';
import { CustomTooltip } from '@opengeoweb/shared';
import { Box } from '@mui/material';
import { useCoreTranslation } from '../../../../../utils/i18n';
import { AcceptanceTime } from './AcceptanceTime';

export const AcceptanceTimeConnect: FC<{
  layerId: string | undefined;
  icon?: React.ElementType;
  tooltipTitle?: string;
  skipLocalStyling?: boolean;
}> = ({ layerId, icon, tooltipTitle, skipLocalStyling }) => {
  const { t } = useCoreTranslation();
  const isEnabled = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerEnabled(store, layerId),
  );
  const dispatch = useDispatch();
  const acceptanceTimeInMinutes = useSelector((store: CoreAppStore) => {
    return layerSelectors.getAcceptanceTimeInMinutes(store, layerId);
  });

  const onChangeAcceptanceTime = (acceptanceTime: number | undefined): void => {
    dispatch(
      layerActions.layerChangeAcceptanceTime({
        layerId: layerId!,
        acceptanceTime,
      }),
    );
  };

  const layerHasTimeDimension = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerHasTimeDimension(store, layerId),
  );
  if (!layerHasTimeDimension) {
    return (
      <CustomTooltip title={t('layermanager-dimensions-none')} placement="top">
        <Box sx={{ height: 32, width: '100%' }} data-testid="noTimeDimension" />
      </CustomTooltip>
    );
  }
  return (
    <AcceptanceTime
      isEnabled={isEnabled}
      acceptanceTimeInMinutes={acceptanceTimeInMinutes}
      onChangeAcceptanceTime={onChangeAcceptanceTime}
      icon={icon}
      tooltipTitle={tooltipTitle}
      skipLocalStyling={skipLocalStyling}
    />
  );
};

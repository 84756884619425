/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PROJECTION } from '@opengeoweb/shared';
import type IWMJSMap from './IWMJSMap';

interface ScaleBarProps {
  width: number;
  mapHeight: number;
  mapunits: number;
  mapSrs: string;
}

/* eslint-disable no-param-reassign */
export const drawTextBG = (
  ctx: CanvasRenderingContext2D,
  txt: string,
  x: number,
  y: number,
  fontSize: number,
): void => {
  ctx.textBaseline = 'top';
  ctx.textAlign = 'left';
  ctx.fillStyle = '#FFF';
  ctx.globalAlpha = 0.75;
  const { width } = ctx.measureText(txt);
  ctx.fillRect(x - 8, y - 8, width + 16, fontSize + 14);
  ctx.fillStyle = '#444';
  ctx.globalAlpha = 1;
  ctx.fillText(txt, x, y + 2);
};
/* eslint-enable no-param-reassign */

export const getScaleBarProperties = (map: IWMJSMap): ScaleBarProps => {
  const desiredWidth = 25;
  let realWidth = 0;
  let numMapUnits = 1 / 10000000;

  const { width: mapWidth, height: mapHeight } = map.getSize();
  const bbox = map.getBBOX();

  const boxWidth = bbox.right - bbox.left;
  const pixelsPerUnit = mapWidth / boxWidth;
  if (pixelsPerUnit <= 0) {
    return undefined!;
  }

  const a = desiredWidth / pixelsPerUnit;

  let divFactor = 0;
  do {
    numMapUnits *= 10;
    divFactor = a / numMapUnits;
    if (divFactor === 0) {
      return undefined!;
    }
    realWidth = desiredWidth / divFactor;
  } while (realWidth < desiredWidth);

  do {
    numMapUnits /= 2;
    divFactor = a / numMapUnits;
    if (divFactor === 0) {
      return undefined!;
    }
    realWidth = desiredWidth / divFactor;
  } while (realWidth > desiredWidth);

  do {
    numMapUnits *= 1.2;
    divFactor = a / numMapUnits;
    if (divFactor === 0) {
      return undefined!;
    }
    realWidth = desiredWidth / divFactor;
  } while (realWidth < desiredWidth);

  let roundedMapUnits = numMapUnits;

  const d = 10 ** (Math.round(Math.log10(numMapUnits) + 0.5) - 1);

  roundedMapUnits = Math.round(roundedMapUnits / d);
  if (roundedMapUnits < 2.5) {
    roundedMapUnits = 2.5;
  }
  if (roundedMapUnits > 2.5 && roundedMapUnits < 7.5) {
    roundedMapUnits = 5;
  }
  if (roundedMapUnits > 7.5) {
    roundedMapUnits = 10;
  }
  roundedMapUnits *= d;
  divFactor = desiredWidth / pixelsPerUnit / roundedMapUnits;
  realWidth = desiredWidth / divFactor;
  return {
    width: parseInt(realWidth as unknown as string, 10),
    mapHeight,
    mapunits: roundedMapUnits,
    mapSrs: map.getProjection().srs,
  };
};

/* eslint-disable no-param-reassign */
export const drawScaleBar = (
  scaleBarProps: ScaleBarProps,
  ctx: CanvasRenderingContext2D,
): void => {
  if (scaleBarProps) {
    const srs = scaleBarProps.mapSrs;
    const offsetX = 7.5;
    const offsetY = scaleBarProps.mapHeight - 25.5;
    const scaleBarHeight = 23;
    ctx.beginPath();
    ctx.lineWidth = 2.5;
    ctx.fillStyle = '#000';
    ctx.strokeStyle = '#000';
    ctx.font = '9px Helvetica';
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'left';
    for (let j = 0; j < 2; j += 1) {
      ctx.moveTo(offsetX, scaleBarHeight - 2 - j + offsetY);
      ctx.lineTo(
        scaleBarProps.width * 2 + offsetX + 1,
        scaleBarHeight - 2 - j + offsetY,
      );
    }

    const subDivXW = parseInt(
      Math.round(scaleBarProps.width / 5) as unknown as string,
      10,
    );
    ctx.lineWidth = 0.5;
    for (let j = 1; j < 5; j += 1) {
      ctx.moveTo(offsetX + subDivXW * j, scaleBarHeight - 2 + offsetY);
      ctx.lineTo(offsetX + subDivXW * j, scaleBarHeight - 2 - 5 + offsetY);
    }
    ctx.lineWidth = 1;
    ctx.moveTo(offsetX, scaleBarHeight - 2 + offsetY);
    ctx.lineTo(offsetX, scaleBarHeight - 2 - 7 + offsetY);
    ctx.moveTo(offsetX + scaleBarProps.width, scaleBarHeight - 2 + offsetY);
    ctx.lineTo(offsetX + scaleBarProps.width, scaleBarHeight - 2 - 7 + offsetY);
    ctx.moveTo(
      offsetX + scaleBarProps.width * 2 + 1,
      scaleBarHeight - 2 + offsetY,
    );
    ctx.lineTo(
      offsetX + scaleBarProps.width * 2 + 1,
      scaleBarHeight - 2 - 7 + offsetY,
    );

    let units = '';
    if (srs === PROJECTION.EPSG_3411.value) {
      units = 'm';
    }
    if (srs === PROJECTION.EPSG_3412.value) {
      units = 'm';
    }
    if (srs === PROJECTION.EPSG_3575.value) {
      units = 'm';
    }
    if (srs === PROJECTION.EPSG_4326.value) {
      units = 'deg';
    }
    if (srs === PROJECTION.EPSG_28992.value) {
      units = 'm';
    }
    if (srs === PROJECTION.EPSG_32661.value) {
      units = 'm';
    }
    if (srs === PROJECTION.EPSG_3857.value) {
      units = 'm';
    }
    if (srs === PROJECTION.EPSG_900913.value) {
      units = 'm';
    }
    if (srs === PROJECTION.EPSG_102100.value) {
      units = 'm';
    }
    if (units === 'm') {
      if (scaleBarProps.mapunits > 1000) {
        scaleBarProps.mapunits /= 1000;
        units = 'km';
      }
    }
    ctx.fillText('0', offsetX - 3, 12 + offsetY);

    let valueStr = `${scaleBarProps.mapunits.toPrecision()}`;
    ctx.fillText(
      valueStr,
      offsetX + scaleBarProps.width - valueStr.length * 2.5 + 0,
      12 + offsetY,
    );
    valueStr = `${(scaleBarProps.mapunits * 2).toPrecision()}`;
    ctx.fillText(
      valueStr,
      offsetX + scaleBarProps.width * 2 - valueStr.length * 2.5 + 0,
      12 + offsetY,
    );
    ctx.fillText(units, offsetX + scaleBarProps.width * 2 + 10, 18 + offsetY);
    ctx.stroke();
  }
};
/* eslint-enable no-param-reassign */

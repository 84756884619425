/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';

import {
  LoadingIndicatorType,
  SetIsEDRLoadingPayload,
  SetIsGetCapabilitiesLoadingPayload,
  SetIsGetMapLoadingPayload,
} from './types';
import { ANY_EDR_SERVICE } from './constants';

export const loadingIndicatorAdapter =
  createEntityAdapter<LoadingIndicatorType>();

const initLoadingIndicatorStateForId = (
  draft: EntityState<LoadingIndicatorType>,
  id: string,
): void => {
  if (!draft.entities[id]) {
    loadingIndicatorAdapter.addOne(draft, {
      id,
      isGetMapLoading: false,
      isGetCapabilitiesLoading: false,
      isEDRLoading: false,
    });
  }
};

export const slice = createSlice({
  initialState: loadingIndicatorAdapter.getInitialState(),
  name: 'loadingIndicatorReducer',
  reducers: {
    setGetMapIsLoading: (
      draft: EntityState<LoadingIndicatorType>,
      action: PayloadAction<SetIsGetMapLoadingPayload>,
    ) => {
      const { id, isGetMapLoading } = action.payload;
      initLoadingIndicatorStateForId(draft, id);
      draft.entities[id]!.isGetMapLoading = isGetMapLoading;
    },
    setGetCapabilitiesIsLoading: (
      draft: EntityState<LoadingIndicatorType>,
      action: PayloadAction<SetIsGetCapabilitiesLoadingPayload>,
    ) => {
      const { id, isGetCapabilitiesLoading } = action.payload;
      initLoadingIndicatorStateForId(draft, id);
      draft.entities[id]!.isGetCapabilitiesLoading = isGetCapabilitiesLoading;
    },
    setEDRIsLoading: (
      draft: EntityState<LoadingIndicatorType>,
      action: PayloadAction<SetIsEDRLoadingPayload>,
    ) => {
      const { id, isEDRLoading } = action.payload;
      initLoadingIndicatorStateForId(draft, id);
      // When one EDR is loading with a known id (panelId), do not set the any ANY_EDR_SERVICE as the panelId is known.
      if (
        isEDRLoading &&
        id === ANY_EDR_SERVICE &&
        draft.ids.some((id) => draft.entities[id]?.isEDRLoading)
      ) {
        draft.entities[id]!.isEDRLoading = false;
      } else {
        draft.entities[id]!.isEDRLoading = isEDRLoading;
      }
    },
  },
});

export const {
  actions: loadingIndicatorActions,
  reducer: loadingIndicatorReducer,
} = slice;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { mapTypes } from '@opengeoweb/store';
import type {
  DimensionTooltipGenerator,
  SingleValueComponentProps,
} from './LayerContainerRow/LayerRow/DimensionSelect/DimensionSelect';
import {
  Size,
  ToolbarButtonSettings,
  sizeLarge,
  sizeMedium,
  sizeSmall,
} from './LayerManagerHeaderOptions';

const iconButtonSize = 30;

export const columnClasses = {
  column1: 'column-1',
  column2: 'column-2',
  column3: 'column-3',
  column4: 'column-4',
  column5: 'column-5',
  column6: 'column-6',
  column7: 'column-7',
  acceptanceTime: 'column-acceptanceTime',
  loadDuration: 'column-loadDuration',
  columnHeader: 'columnHeader',
  baseLayer: 'baseLayer',
  columnTarget: 'columnTarget',
};

const DRAG_VISIBILITY_LEADING_BUTTONS = 3;
const DELETE_MORE_BUTTONS = 3;
const MISSING = 4;

const leftButtonsWidth =
  iconButtonSize * DRAG_VISIBILITY_LEADING_BUTTONS + MISSING;
const rightButtonsWidth = iconButtonSize * DELETE_MORE_BUTTONS;
const acceptanceTimeWidth = 65;
const loadDurationWidth = 50;
const nonFlexibleAreaWidth =
  leftButtonsWidth +
  rightButtonsWidth +
  acceptanceTimeWidth +
  loadDurationWidth;

const flexibleAreaWidth = `(100% - ${nonFlexibleAreaWidth}px)`;

export const leftButtonsStyle = {
  width: leftButtonsWidth,
  display: 'flex',
  background: 'transparent',
};

const columnCollapseStyle = {
  height: 'inherit',
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: 'geowebColors.buttons.icon.default.color',
  flexGrow: 1,
};

export const layerManagerStyle = {
  '.column-hover-icon': {
    display: 'none',
  },
  '.columnHeader:hover': {
    backgroundColor: 'geowebColors.buttons.icon.mouseOver.fill',
    cursor: 'default',
    '.column-hover-icon': {
      display: 'block',
    },
  },
  '.layerManagerContainer': {
    containerType: 'inline-size',
  },
  '.descriptionRow': {
    height: '32px',
    marginTop: '4px',
    marginBottom: '2px',
  },
  // disable overflow while dragging to prevent issues with scrollbar
  '.is-dragging header+.MuiBox-root': {
    overflow: 'hidden',
  },
  // baselayer & projection
  '.baseLayer': {
    width: `calc(${flexibleAreaWidth} * 0.25)`,
  },
  // layerName
  '.column-2': {
    width: `calc(${flexibleAreaWidth} * 0.25)`,
    ...columnCollapseStyle,
  },
  // style
  '.column-3': {
    width: `calc(${flexibleAreaWidth} * 0.25)`,
    ...columnCollapseStyle,
  },
  // opacity
  '.column-4': {
    width: `calc(${flexibleAreaWidth} * 0.15)`,
    ...columnCollapseStyle,
  },
  // dimensions
  '.column-5': {
    width: `calc(${flexibleAreaWidth} * 0.35)`,
    ...columnCollapseStyle,
  },
  '.column-acceptanceTime': {
    width: acceptanceTimeWidth,
    ...columnCollapseStyle,
    flexGrow: 0,
  },
  '.column-loadDuration': {
    width: loadDurationWidth,
    paddingLeft: 1,
    paddingRight: 1,
  },
  '.column-6': {
    width: rightButtonsWidth,
    display: 'inline-flex',
    marginLeft: 'auto',
  },
  // layer missing warning
  '.column-7': {
    width: `calc(${flexibleAreaWidth} * 0.75 + ${acceptanceTimeWidth}px + ${loadDurationWidth}px)`,
  },

  // sizes: mini
  '.enable-btn-mini': {
    display: 'none',
  },

  '.header-layermanager': {
    containerType: 'inline-size',
  },

  '.medium-layermanager': {
    display: 'none',
  },

  '.collapseLarge-btn': {
    display: 'none',
  },

  '@container (max-width: 320px)': {
    '.layermanager': {
      '.column-1': {
        width: '100%',
      },
      '.enable-btn-mini': {
        display: 'inline-flex',
      },
      '.activateLayer-btn, .enable-btn, .column-2, .column-3, .column-4, .column-5, .column-6, .column-7, .column-acceptanceTime, .column-loadDuration':
        {
          display: 'none',
        },

      '.addLayer-column, .setProjection-column': {
        display: 'none',
      },

      '.descriptionRow': {
        height: 0,
        '.MuiButtonBase-root': {
          display: 'none',
        },
      },
    },
    '.header-layermanager': {
      '.header-left': {
        display: 'none',
      },
      '&>div': {
        justifyContent: 'flex-end',
        padding: 0,
        height: 24,
      },
      '.header-right': {
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '.collapseSmall-btn': {
        display: 'none',
      },
    },
  },

  // sizes: medium
  '@container (max-width: 600px)': {
    '.layermanager': {
      '.column-2, .column-3, .column-4, .column-5, .column-6, .column-7, .column-acceptanceTime, .column-loadDuration':
        {
          display: 'none',
        },
      '.columnLayerName, .baseLayer': {
        width: `calc((100% - ${leftButtonsWidth}px) )`,
      },
    },
    '.header-layermanager': {
      '.collapseLarge-btn': {
        display: 'inline-flex',
      },
    },
  },

  // specific queries for medium layermanager
  '@container (max-width: 600px) and (min-width: 321px)': {
    '.layermanager': {
      '.medium-layermanager': {
        display: 'block',
      },
    },

    '.deleteBtnMedium': {
      display: 'inline-flex!important',
      '&+button': {
        display: 'none',
      },
    },

    '.layerRow': {
      '.column-1': {
        display: 'none',
      },
      '.column-2': {
        display: 'none',
      },
    },

    '.header-layermanager': {
      '.collapseMedium-btn': {
        display: 'none',
      },
    },
  },
};

export const returnCorrectSize = (
  sizeResult: mapTypes.DockedLayerManagerSize | undefined,
): Size => {
  if (sizeResult === 'sizeSmall') {
    return sizeSmall;
  }
  if (sizeResult === 'sizeMedium') {
    return sizeMedium;
  }
  if (sizeResult === 'sizeLarge') {
    return sizeLarge;
  }
  return sizeSmall;
};
export type DimensionNameMappings = Record<string, string>;
export type DimensionValueMappings = Record<
  string,
  (value: string, latest?: string) => string
>;
export interface LayerManagerCustomSettings {
  toolbar?: {
    dragHandle?: {
      icon?: React.ReactNode;
    };
    controlButtonSettings?: ToolbarButtonSettings;
    closeButton?: {
      icon?: React.ReactNode;
    };
  };
  header?: {
    addLayer?: {
      tooltipTitle?: string;
      icon?: React.ReactNode;
    };
    layerName?: {
      title: string;
    };
    layerStyle?: {
      title: string;
    };
    opacity?: {
      title: string;
    };
    dimensions?: {
      title: string;
    };
    acceptanceTime?: { title: string };
  };
  content?: {
    dragHandle?: {
      tooltipTitle?: string;
      icon?: React.ReactNode;
    };
    activateLayer?: {
      isDisabled?: boolean;
    };
    enableLayer?: {
      enabledIcon?: React.ReactNode;
      disabledIcon?: React.ReactNode;
      enabledTooltipTitle?: string;
      disabledTooltipTitle?: string;
    };
    renderLayer?: {
      Element?: React.FC<{ layerId: string; mapId: string }>;
      tooltipPrefix?: string;
    };
    layerStyle?: {
      tooltipPrefix?: string;
      icon?: React.ElementType;
    };
    opacity?: {
      tooltipPrefix?: string;
    };
    acceptanceTime?: { tooltipTitle?: string; icon?: React.ElementType };
    dimensions?: {
      dimensionsToShow?: string[];
      SingleValueComponent?: React.FC<SingleValueComponentProps>;
      icon?: React.ElementType;
      tooltipGenerator?: DimensionTooltipGenerator;
      nameMappings?: DimensionNameMappings;
      valueMappings?: DimensionValueMappings;
      // Hide latest reference_time value if Latest value is selectable
      hideLatestDuplicate?: boolean;
    };
    deleteLayer?: {
      tooltipTitle?: string;
      icon?: React.ReactNode;
    };
    menu?: {
      tooltipTitle?: string;
      icon?: React.ReactNode;
    };
  };
  footer?: {
    baseLayerRow?: {
      title?: string;
      addLayersButton?: {
        isDisabled?: boolean;
      };
    };
    baseLayerDropdown?: {
      tooltipPrefix?: string;
      icon?: React.ElementType;
    };
    projection?: { shouldHide?: boolean };
  };
}

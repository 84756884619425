/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
  Source,
  ToolContainerDraggable,
  useWheelStopPropagation,
} from '@opengeoweb/shared';
import { LayerFoundation } from '@opengeoweb/webmap';
import { Legend } from './Legend';
import { useWebmapReactTranslation } from '../../utils/i18n';

interface LegendDialogProps {
  layers: LayerFoundation[];
  isOpen: boolean;
  onClose: () => void;
  onMouseDown?: () => void;
  mapId: string;
  showMapId?: boolean;
  order?: number;
  source?: Source;
}

export const LegendDialog: React.FC<LegendDialogProps> = ({
  layers,
  isOpen,
  onClose,
  onMouseDown = (): void => {},
  mapId,
  showMapId = false,
  order = 0,
  source = 'app',
}: LegendDialogProps) => {
  const { t } = useWebmapReactTranslation();

  const stopWheelEventPropagationRef =
    useWheelStopPropagation<HTMLDivElement>();

  return (
    <ToolContainerDraggable
      startPosition={{ right: 20, top: 50 }}
      minWidth={100}
      onClose={onClose}
      title={
        showMapId
          ? `${t('webmap-react-legend')} ${mapId}`
          : t('webmap-react-legend')
      }
      initialMaxHeight={440}
      isOpen={isOpen}
      data-testid="moveable-legend"
      bounds="parent"
      onMouseDown={onMouseDown}
      order={order}
      source={source}
    >
      <Box
        sx={{
          padding: 0.5,
        }}
      >
        {layers && layers.length > 0 ? (
          <Box
            ref={stopWheelEventPropagationRef}
            data-testid="LegendList"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '&>div:last-child': {
                marginBottom: 0,
              },
            }}
          >
            {layers.map((layer) => (
              <Legend key={layer.id} layer={layer} />
            ))}
          </Box>
        ) : (
          <Typography
            data-testid="NoLayers"
            variant="body1"
            style={{ fontWeight: 500, fontSize: 14 }}
          >
            {t('webmap-react-no-layers')}
          </Typography>
        )}
      </Box>
    </ToolContainerDraggable>
  );
};

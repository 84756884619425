/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  DebugType,
  Dimension,
  LayerFoundation,
  LayerOptions,
  LayerType,
  IWMJSMap,
  WMLayer,
  debugLogger,
  getWMJSMapById,
  registerWMLayer,
} from '@opengeoweb/webmap';
import React from 'react';
import { dateUtils } from '@opengeoweb/shared';
import { FeatureLayer } from '../MapDraw';
import type { MapViewLayerProps, MapViewProps } from '../MapView';
import { getLayerUpdateInfo } from './ReactMapViewParseLayer';
import { ReactMapViewProps, UpdateLayerInfoPayload } from './types';

/** Returns filtered list of props with geoJson
 * @param children React.ReactNode, layers with geoJson
 */
export const getFeatureLayers = (children: React.ReactNode): FeatureLayer[] =>
  children && Array.isArray(children)
    ? children
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .reduce((acc, val) => acc.concat(val), [])
        .filter(
          (c: { props: { geojson: GeoJSON.FeatureCollection } }) =>
            c && c.props && c.props.geojson,
        )
        .map((c: { props: unknown }) => c.props)
        .reverse()
    : [];
/**
 * Returns true if this is a maplayer and not a baselayer or overlayer
 * @param layer The LayerFoundation object, or the props from the ReactWMJSLayer
 */
export const isAMapLayer = (layer: LayerFoundation): boolean =>
  layer.layerType === LayerType.mapLayer;

/**
 * Returns true if this is a geojsonlayer (layer containing geojson field)
 * @param layer The LayerFoundation object, or the props from the ReactWMJSLayer
 */
export const isAGeoJSONLayer = (layer: LayerFoundation): boolean =>
  layer.geojson !== undefined;

export function getIsInsideAcceptanceTime(
  acceptanceTimeInMinutes: number | undefined,
  mapDimensions: Dimension[] | undefined,
  layerDimensions: Dimension[] | undefined,
): boolean {
  const mapCurrentTime = mapDimensions?.find(
    (dimension) => dimension.name === 'time',
  )?.currentValue;

  const layerCurrentTime = layerDimensions?.find(
    (dimension) => dimension.name === 'time',
  )?.currentValue;

  if (
    acceptanceTimeInMinutes === undefined ||
    !mapCurrentTime ||
    !layerCurrentTime
  ) {
    return true;
  }

  const minutesBetween = dateUtils.differenceInMinutes(
    new Date(mapCurrentTime),
    new Date(layerCurrentTime),
  );
  if (Math.abs(minutesBetween) > acceptanceTimeInMinutes) {
    return false;
  }

  return true;
}

export const makeLayerPropListFromChildren = (
  children: React.ReactNode,
  mapId: string,
): MapViewLayerProps[] => {
  const myChildren: MapViewLayerProps[] = [];
  const takenIds = new Set();
  const wmjsMap = getWMJSMapById(mapId);
  React.Children.forEach(children as React.ReactElement, (child) => {
    if (child) {
      const { props: childProps } = child;
      if (childProps && childProps.id) {
        if (!takenIds.has(childProps.id)) {
          myChildren.push(childProps);
          takenIds.add(childProps.id);
        } else {
          childProps.onLayerError?.(
            childProps,
            new Error(
              `Duplicate layer id "${childProps.id}" encountered within this mapTypes.WebMap`,
            ),
            wmjsMap,
          );
          console.warn('ReactWMJSLayer has a duplicate id', child);
        }
      } else {
        debugLogger(
          DebugType.Warning,
          'ReactElement child ignored: has no props or id',
          child,
        );
      }
    }
  });
  myChildren.reverse();
  return myChildren;
};

export const parseWMJSLayer = (
  wmLayer: WMLayer,
  forceReload: boolean,
): Promise<WMLayer> => {
  return wmLayer.parseLayer(forceReload);
};

export const setWMLayerPropsBasedOnChildProps = (
  child: MapViewLayerProps,
  wmLayer: WMLayer,
  mapViewProps: ReactMapViewProps,
): boolean => {
  let needsRedraw = false;
  /* Set the name of the ADAGUC WMJSLayer */

  /* Set the Opacity of the ADAGUC WMJSLayer */
  if (child.opacity !== undefined && wmLayer.opacity !== child.opacity) {
    wmLayer.setOpacity(child.opacity);
    needsRedraw = false;
  }

  /* Set the Style of the ADAGUC WMJSLayer */
  if (child.style !== undefined && wmLayer.currentStyle !== child.style) {
    wmLayer.setStyle(child.style);
    needsRedraw = true;
  }

  const {
    enabled,
    acceptanceTimeInMinutes,
    dimensions: layerDimensions,
  } = child;

  const isInsideAcceptanceTime = getIsInsideAcceptanceTime(
    acceptanceTimeInMinutes,
    mapViewProps.dimensions,
    layerDimensions,
  );

  if (enabled !== undefined) {
    const wmLayerShouldBeShown = isInsideAcceptanceTime && enabled;
    if (wmLayerShouldBeShown !== wmLayer.enabled) {
      wmLayer.display(wmLayerShouldBeShown);
      needsRedraw = true;
    }
  }

  /* Set the dimensions of the ADAGUC WMJSLayer */
  if (child.dimensions !== undefined) {
    for (const childDimension of child.dimensions) {
      const dim = childDimension;
      const wmjsDim = wmLayer.getDimension(dim.name!);
      if (wmjsDim) {
        if (wmjsDim.currentValue !== dim.currentValue) {
          wmLayer.setDimension(dim.name!, dim.currentValue, false);
          needsRedraw = true;
        }
        if (wmjsDim.synced !== dim.synced) {
          wmjsDim.synced = dim.synced!;
          needsRedraw = true;
        }
      } else {
        debugLogger(
          DebugType.Warning,
          `MapView: Dimension does not exist, skipping ${child.name} :: ${dim.name} = ${dim.currentValue}`,
        );
      }
    }
  }
  return needsRedraw;
};

export const addWMLayerPropsBasedOnChildProps = (
  child: MapViewLayerProps,
  mapId: string,
  props: MapViewProps,
  onUpdateLayerInformation?: (payload: UpdateLayerInfoPayload) => void,
): void => {
  const wmjsMap = getWMJSMapById(mapId);
  if (!wmjsMap || wmjsMap.isDestroyed) {
    return;
  }
  const keepOnTop = child.layerType === LayerType.overLayer || false;
  const newWMLayer = new WMLayer({
    ...child,
    keepOnTop,
  } as LayerOptions);
  registerWMLayer(newWMLayer, child.id!);
  newWMLayer.ReactWMJSLayerId = child.id;
  const isBaselayer = !isAMapLayer(child) && !isAGeoJSONLayer(child);
  if (isBaselayer) {
    /* Add ADAGUC WebMapJS Baselayer */
    const baseLayers = wmjsMap.getBaseLayers();
    baseLayers.unshift(newWMLayer);
    wmjsMap.setBaseLayers(baseLayers);

    // If this layer has a tileserver configured, add it to the map.
    if (child.tileServer) {
      wmjsMap.addWMTileRendererTileSetting(child.tileServer);
    }
  } else {
    /* Add ADAGUC WebMapJS Layer */
    wmjsMap
      .addLayer(newWMLayer)
      .then(() => {
        if (newWMLayer.hasError === true) {
          child.onLayerError &&
            child.onLayerError(newWMLayer, new Error(newWMLayer.lastError));
        }

        // This ensures that MultiMapViewConnect works properly.
        setTimeout(async () => {
          const wmjsMap = getWMJSMapById(mapId);
          if (!wmjsMap || wmjsMap.isDestroyed) {
            return;
          }
          setWMLayerPropsBasedOnChildProps(child, newWMLayer, props);
          await newWMLayer.parseLayer();
          const layerInfo = getLayerUpdateInfo(newWMLayer, mapId);
          onUpdateLayerInformation && onUpdateLayerInformation(layerInfo);
          child.onLayerReady && child.onLayerReady(newWMLayer, wmjsMap);
          wmjsMap.draw();
        }, 1);
      })
      .catch((e) => {
        child.onLayerError && child.onLayerError(newWMLayer, e);
      });
  }
};

const ORIGIN_REACTMAPVIEW_UTILS_ONUPDATELAYERINFO =
  'ORIGIN_REACTMAPVIEW_UTILS_ONUPDATELAYERINFO';
export const removeWMLayerFromMap = (
  wmLayer: WMLayer,
  mapId: string,
  onUpdateLayerInformation?: (payload: UpdateLayerInfoPayload) => void,
): void => {
  // This will call the remove property of the WMJSLayer, which will adjust the layers array of WebMapJS */
  wmLayer.remove();
  /// Trigger update of all map dimensions
  const wmjsMap = getWMJSMapById(mapId);
  if (!wmjsMap) {
    return;
  }
  onUpdateLayerInformation &&
    onUpdateLayerInformation({
      origin: ORIGIN_REACTMAPVIEW_UTILS_ONUPDATELAYERINFO,
      layerStyle: null!,
      layerDimensions: null!,
      mapDimensions: {
        origin,
        mapId,
        dimensions: wmjsMap.mapdimensions.map(
          ({ name, units, currentValue, synced }) => {
            return {
              name,
              units,
              currentValue,
              synced,
            };
          },
        ),
      },
    });
};

// TODO: Improve performance in https://gitlab.com/opengeoweb/opengeoweb/-/issues/4566
export const orderLayers = (
  wmjsMap: IWMJSMap,
  _reactMapViewLayers: MapViewLayerProps[],
): boolean => {
  const mapLayers = wmjsMap.getLayers();
  const mapLayersIds = mapLayers.map((ml) => ml.id);
  const filtered = _reactMapViewLayers
    .filter((_l) => mapLayersIds.includes(_l.id))
    .reverse();

  const order = mapLayers.map((wmLayer: WMLayer) => {
    return filtered.findIndex(
      (reactMapViewLayer: MapViewLayerProps): boolean => {
        return reactMapViewLayer.id === wmLayer.id;
      },
    );
  });

  const wasReordered = wmjsMap.reorderLayers(order);
  return wasReordered;
};

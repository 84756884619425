/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  BaseTextFieldProps,
  FilledInputProps,
  SlotCommonProps,
  SlotProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import ReactHookFormFormControl from './ReactHookFormFormControl';
import { getErrors } from './formUtils';
import { ReactHookFormInput } from './types';
import { defaultProps } from './utils';

export const convertTextInputValue = (
  value: string,
  inCapitals: boolean,
): number | string => {
  return inCapitals ? value.toUpperCase() : value;
};

type ReactHookFormTextFieldProps = Partial<TextFieldProps> &
  ReactHookFormInput<{
    upperCase?: boolean;
    inputSlotProps?:
      | SlotProps<
          React.ElementType<
            FilledInputProps,
            keyof React.JSX.IntrinsicElements
          >,
          SlotCommonProps,
          BaseTextFieldProps
        >
      | undefined;
  }>;

const ReactHookFormTextField: React.FC<ReactHookFormTextFieldProps> = ({
  name,
  label,
  defaultValue = '',
  rules,
  disabled = false,
  upperCase = false,
  helperText = '',
  className,
  sx,
  onChange = (): void => {},
  isReadOnly,
  inputSlotProps,
  ...otherProps
}: ReactHookFormTextFieldProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange: onChangeField, value, ref },
    formState: { errors: formErrors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    ...defaultProps,
  });

  const errors = getErrors(name!, formErrors);

  return (
    <ReactHookFormFormControl
      className={className}
      sx={sx}
      disabled={disabled}
      errors={errors}
      isReadOnly={isReadOnly}
    >
      <TextField
        label={label}
        error={!!errors}
        helperText={helperText}
        value={value}
        variant="filled"
        type="text"
        name={name}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
          const { value } = evt.target;
          const convertedValue = convertTextInputValue(value, upperCase);

          onChangeField(convertedValue);
          onChange(null!);
        }}
        disabled={disabled}
        inputRef={ref}
        {...otherProps}
        slotProps={{
          ...otherProps.slotProps,
          input: {
            ...inputSlotProps,
            ...(isReadOnly && {
              readOnly: true,
            }),
          },
        }}
      />
    </ReactHookFormFormControl>
  );
};
export default ReactHookFormTextField;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import {
  getCodeChallenge,
  getRandomString,
  getSessionStorageProvider,
} from '@opengeoweb/authentication';

// ---> Sentry related code
export const initializeSentry = (): void => {
  const sentryEnvironments = [
    {
      windowLocationPattern: /.*acc.opengeoweb.com.*/,
      sentryEnvironment: 'open-acc',
      sentryEnabled: true,
    },
    {
      windowLocationPattern: /.*dev.opengeoweb.com.*/,
      sentryEnvironment: 'open-dev',
      sentryEnabled: true,
    },
    {
      windowLocationPattern: /.*acc-geoweb.fmi.fi.*/,
      sentryEnvironment: 'fmi-acc',
      sentryEnabled: true,
    },
    {
      windowLocationPattern: /.*localhost*/,
      sentryEnvironment: 'test',
      sentryEnabled: true,
    },
  ];

  const currentEnvironment = sentryEnvironments.find((environment) =>
    environment.windowLocationPattern.exec(window.location.hostname),
  );

  if (currentEnvironment && currentEnvironment.sentryEnabled) {
    Sentry.init({
      environment: currentEnvironment.sentryEnvironment, // Name Sentry environment after our environment
      dsn: 'https://c2954983afcb2973d1f05b3256cafde7@o4508720349839360.ingest.de.sentry.io/4508720354754640',
      integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      normalizeDepth: 10, // Redux related setting on how deep sentry will look into redux
      // ___ Performance Monitoring ____
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      tracePropagationTargets: [], // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled. Example ['localhost', /^https:\/\/yourserver\.io\/api/]
      // ____ Session Replay _____
      replaysSessionSampleRate: 0, // This will disable automatic Replay creation. We want to start them manually when needed
      replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      profilesSampleRate: 1.0,
    });
  }
};
// Sentry related code <--

export const initializeSessionStorage = async (): Promise<void> => {
  const sessionStorageProvider = getSessionStorageProvider();

  if (!sessionStorageProvider.getOauthState()) {
    const oauthState = getRandomString();
    sessionStorageProvider.setOauthState(oauthState);
  }

  if (
    !sessionStorageProvider.getOauthCodeVerifier() ||
    !sessionStorageProvider.getOauthCodeChallenge()
  ) {
    const codeVerifier = getRandomString();
    await getCodeChallenge(codeVerifier).then((codeChallenge) => {
      // setting both codeChallenge and codeVerifier at the same time since they are related
      sessionStorageProvider.setOauthCodeVerifier(codeVerifier);
      sessionStorageProvider.setOauthCodeChallenge(codeChallenge);
    });
  }
};

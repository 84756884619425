/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  FieldValues,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useApiContext } from '@opengeoweb/api';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { tacHasError, useTACGenerator } from '../../TafForm/utils';
import { Taf, TafFormData } from '../../../types';
import StatusIcon from '../../StatusIcon/StatusIcon';
import {
  getLocationSetting,
  getTafTimeSlot,
  prepareTafExportValues,
} from '../utils';
import ExportButton from './ExportButton';
import { TafApi } from '../../../utils/api';
import { AdverseWeatherTACMessage } from './AdverseWeatherTacMessage';
import { useTafModuleContext } from '../../TafModule/TafModuleProvider';
import { useTafTranslation } from '../../../utils/i18n';

interface TacContainerProps {
  taf: Taf;
  isActive?: boolean;
  isFormDisabled?: boolean;
}

const TacContainer: React.FC<TacContainerProps> = ({
  taf,
  isActive = false,
  isFormDisabled = false,
}: TacContainerProps) => {
  const { t } = useTafTranslation();
  const { api } = useApiContext<TafApi>();
  const dispatch = useDispatch();
  const [TAC, retrieveTAC] = useTACGenerator(taf, api.getTAC, true);
  const { status, location, baseTime } = taf;
  const locationLabel = getLocationSetting(location).label;
  const timeSlot = getTafTimeSlot(baseTime, location, status);
  const { getValues, trigger, reset, setValue } = useFormContext();
  const { useFieldArrayMethods } = useTafModuleContext();

  const { replace } = useFieldArrayMethods as UseFieldArrayReturn<
    FieldValues,
    string
  >;

  const showSnackbar = React.useCallback(
    (message: string) => {
      dispatch(
        snackbarActions.openSnackbar({
          type: snackbarTypes.SnackbarMessageType.VERBATIM_MESSAGE,
          message,
        }),
      );
    },
    [dispatch],
  );

  const handleExport = (): void => {
    const currentTaf = getValues() as TafFormData;
    const newTaf = prepareTafExportValues(taf, currentTaf);

    reset(newTaf, {
      keepDefaultValues: true,
      keepValues: false,
      keepDirty: false,
      keepErrors: false,
      keepIsValid: false,
      keepDirtyValues: false,
    });
    Object.keys(newTaf).forEach((key: string) => {
      setValue(key, newTaf[key as keyof TafFormData], { shouldValidate: true });
    });
    replace(newTaf.changeGroups);

    setTimeout(() => {
      void trigger(); // trigger all fields validation
    }, 0);

    showSnackbar(t('taf-import-message', { location, locationLabel }));
  };

  React.useEffect(() => {
    if (isActive) {
      retrieveTAC(taf);
    }
  }, [taf, isActive, retrieveTAC]);

  return (
    <Box
      sx={{
        marginBottom: '20px',
      }}
      data-location={location}
      className={isActive ? 'active' : undefined}
      role="listitem"
      aria-label={location}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 24,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 24,
              height: 24,
              display: 'flex',
              marginRight: '2px',
              paddingTop: '6px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StatusIcon timeSlot={timeSlot} status={status} />
          </Box>

          <Typography
            sx={{
              lineHeight: 1,
              ...(isActive && {
                backgroundColor:
                  'geowebColors.functional.warningHighlight.rgba',
                padding: '0 2px',
                marginLeft: '-2px',
              }),
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: 'geowebColors.captions.captionInformation.rgba',
              }}
              data-testid="tafLocation"
              component="span"
            >
              {location}
            </Typography>

            <Typography
              sx={{
                fontSize: '12px',
                marginLeft: '10px',
              }}
              data-testid="tafLocationLabel"
              component="span"
            >
              {locationLabel}
            </Typography>
          </Typography>
        </Box>
        <ExportButton
          onClick={handleExport}
          isHidden={isFormDisabled || tacHasError(TAC, t)}
        />
      </Box>
      <Box
        sx={{
          fontSize: '12px',
          letterSpacing: '0.4px',
          color: 'geowebColors.captions.captionStatus.rgba',
        }}
      >
        {typeof TAC === 'string' ? (
          <Box
            component="span"
            sx={{
              display: 'inline',
              boxDecorationBreak: 'clone',
              WebkitBoxDecorationBreak: 'clone',
              ...(isActive && {
                backgroundColor:
                  'geowebColors.functional.warningHighlight.rgba',
              }),
            }}
          >
            {TAC}
          </Box>
        ) : (
          <AdverseWeatherTACMessage TAC={TAC} isActive={isActive} />
        )}
      </Box>
    </Box>
  );
};

export default TacContainer;

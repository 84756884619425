/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ReactHookFormInput } from './types';
import { defaultProps } from './utils';

type ReactHookFormHiddenInputProps = ReactHookFormInput<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: unknown;
}>;

const ReactHookFormHiddenInput: React.FC<ReactHookFormHiddenInputProps> = ({
  name,
  defaultValue = null,
  rules,
  ...props
}: ReactHookFormHiddenInputProps) => {
  const { control } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue,
    ...defaultProps,
  });

  return field.value !== null ? (
    <input {...field} type="hidden" {...props} />
  ) : (
    null!
  );
};

export default ReactHookFormHiddenInput;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Draft,
} from '@reduxjs/toolkit';
import { uiActions } from '@opengeoweb/store';
import {
  DrawItem,
  DrawState,
  SetDrawValuesPayload,
  SubmitDrawValuesPayload,
} from './types';
import { drawingDialogType } from './utils';

export const drawAdapter = createEntityAdapter<DrawItem>({
  selectId: (draw) => draw.drawingInstanceId,
});

export const initialState: DrawState = drawAdapter.getInitialState();

const slice = createSlice({
  initialState,
  name: 'drawingForm',
  reducers: {
    setDrawValues: (
      draft: Draft<DrawState>,
      action: PayloadAction<SetDrawValuesPayload>,
    ) => {
      if (draft.entities[action.payload.drawingInstanceId]) {
        drawAdapter.setOne(draft, action.payload);
      }
    },
    // action caught in drawings/listener
    submitDrawValues: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<DrawState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<SubmitDrawValuesPayload>,
    ) => {},
    setFormDirty: (
      draft: Draft<DrawState>,
      action: PayloadAction<{
        drawingInstanceId: string;
        isFormDirty: boolean;
      }>,
    ) => {
      const { drawingInstanceId, isFormDirty } = action.payload;
      if (draft.entities[drawingInstanceId]) {
        drawAdapter.updateOne(draft, {
          id: drawingInstanceId,
          changes: { isFormDirty },
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // register draw dialog
      .addCase(uiActions.registerDialog, (draft: Draft<DrawState>, action) => {
        const { type } = action.payload;
        if (type === drawingDialogType) {
          drawAdapter.addOne(draft, {
            drawingInstanceId: type,
            objectName: '',
            id: '',
          });
        }
      })
      // unregister draw dialog
      .addCase(
        uiActions.unregisterDialog,
        (draft: Draft<DrawState>, action) => {
          const { type } = action.payload;
          if (type === drawingDialogType) {
            drawAdapter.removeOne(draft, type);
          }
        },
      )
      // reset draw dialog formDirty when dialog closes
      .addCase(
        uiActions.setActiveMapIdForDialog,
        (draft: Draft<DrawState>, action) => {
          const { type, setOpen } = action.payload;
          if (type === drawingDialogType && !setOpen) {
            drawAdapter.updateOne(draft, {
              id: type,
              changes: { isFormDirty: false },
            });
          }
        },
      );
  },
});

export const {
  reducer: warningsDrawingsReducer,
  actions: warningsDrawingsActions,
} = slice;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2025 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2025 - Finnish Meteorological Institute (FMI)
 * Copyright 2025 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getLocationList, getLocationDetail } from './api';
import {
  LocationDetail,
  LocationListResult,
} from '../../components/Search/types';

export const useLocationList = (
  search: string,
): UseQueryResult<LocationListResult[]> => {
  return useQuery({
    queryKey: ['location-list', search],
    queryFn: async () => {
      if (search.length > 0) {
        return getLocationList(search);
      }
      return [];
    },
  });
};

export const useLocationDetail = (
  id = '',
  source = '',
): UseQueryResult<LocationDetail> => {
  return useQuery({
    queryKey: ['location-detail', { id, source }],
    queryFn: () => getLocationDetail(id, source),
    enabled: !!id,
  });
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { dateUtils } from '@opengeoweb/shared';

import { getFir } from '../../components/ProductForms/utils';
import {
  Airmet,
  AirmetFromBackend,
  Direction,
  CancelAirmet,
  LevelUnits,
  CloudLevelUnits,
  MovementUnit,
  VisibilityCause,
  WindUnit,
  AviationPhenomenaCode,
} from '../../types';
import { airmetConfig } from '../config';

export const fakeAirmetList: AirmetFromBackend[] = [
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE',
      phenomenon: 'ISOL_TSGR' as AviationPhenomenaCode,
      sequence: 'A01',
      validDateStart: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,
      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 3 }),
      )!,
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(
        dateUtils.sub(dateUtils.utc(), { hours: 2 }),
      ),
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'DRAFT',
      levelInfoMode: 'ABV',
      level: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
    },
  },
  {
    creationDate: dateUtils.getCurrentTimeAsString(),
    canbe: ['CANCELLED'],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE2',
      phenomenon: 'SFC_VIS' as AviationPhenomenaCode,
      sequence: 'A01V',
      issueDate: '2020-09-17T12:00:00Z',
      validDateStart: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,
      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 2 }),
      )!,
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      ),
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      visibilityValue: 1000,
      visibilityCause: 'DZ' as VisibilityCause,
      visibilityUnit: 'm',
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: dateUtils.getCurrentTimeAsString(),
    canbe: ['CANCELLED'],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE3',
      phenomenon: 'ISOL_TS' as AviationPhenomenaCode,
      sequence: 'A02V30',
      issueDate: dateUtils.dateToString(dateUtils.utc()),
      validDateStart: dateUtils.getCurrentTimeAsString(),
      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(dateUtils.utc()),
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      levelInfoMode: 'BETW',
      level: {
        value: 1500,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    canbe: [],
    creationDate: '2020-09-17T14:30:00Z',
    airmet: {
      uuid: 'someuniqueidprescibedbyBE4',
      validDateStart: '2020-09-17T14:30:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      sequence: '4',
      issueDate: '2020-09-17T14:30:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      status: 'PUBLISHED',
      cancelsAirmetSequenceId: '113',
      validDateStartOfAirmetToCancel: '2020-09-17T13:00:00Z',
      validDateEndOfAirmetToCancel: '2020-09-17T17:00:00Z',
      type: 'NORMAL',
    },
  },
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: [],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE7',
      phenomenon: 'OVC_CLD' as AviationPhenomenaCode,
      sequence: 'A09',
      issueDate: '2020-09-17T12:00:00Z',
      validDateStart: '2020-09-17T13:00:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T12:00:00Z',
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      cloudLevelInfoMode: 'BETW_SFC_ABV',
      cloudLevel: {
        value: 1000,
        unit: 'FT' as CloudLevelUnits,
      },
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: [],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE12',
      phenomenon: 'SFC_WIND' as AviationPhenomenaCode,
      sequence: '113',
      issueDate: '2020-09-17T12:00:00Z',
      validDateStart: '2020-09-17T13:00:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T12:00:00Z',
      movementType: 'MOVEMENT',
      movementSpeed: 20.0,
      movementUnit: 'KT' as MovementUnit,
      movementDirection: Direction.NNE,
      change: 'WKN',
      type: 'NORMAL',
      status: 'CANCELLED',
      windSpeed: 120,
      windUnit: 'KT' as WindUnit,
      windDirection: 220,
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-18T12:00:00Z',
    canbe: [],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE8',
      phenomenon: 'ISOL_CB' as AviationPhenomenaCode,
      sequence: 'A02',
      issueDate: '2020-09-18T12:00:00Z',
      validDateStart: '2020-09-18T23:00:00Z',
      validDateEnd: '2020-09-19T02:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T12:00:00Z',
      levelInfoMode: 'AT',
      level: {
        value: 300,
        unit: 'FL' as LevelUnits,
      },
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.4324608936767396, 51.79162703117144],
                  [3.4324608936767396, 52.4211358618957],
                  [5.2318201855628175, 52.4211358618957],
                  [5.2318201855628175, 51.79162703117144],
                  [3.4324608936767396, 51.79162703117144],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.4324608936767396, 51.79162703117144],
                  [3.4324608936767396, 52.4211358618957],
                  [5.2318201855628175, 52.4211358618957],
                  [5.2318201855628175, 51.79162703117144],
                  [3.4324608936767396, 51.79162703117144],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: [],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE13',
      phenomenon: 'BKN_CLD' as AviationPhenomenaCode,
      sequence: 'A02V',
      issueDate: '2020-09-17T12:00:00Z',
      observationOrForecastTime: null!,
      validDateStart: '2020-09-17T13:00:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      movementType: 'MOVEMENT',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      movementSpeed: 20.0,
      movementUnit: 'KT' as MovementUnit,
      movementDirection: Direction.NNE,
      cloudLevelInfoMode: 'BETW_ABV',
      cloudLevel: {
        value: 900,
        unit: 'FT' as CloudLevelUnits,
      },
      cloudLowerLevel: {
        value: 100,
        unit: 'FT' as CloudLevelUnits,
      },
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'point',
            },
            geometry: {
              type: 'Point',
              coordinates: [5, 52],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'point',
            },
            geometry: {
              type: 'Point',
              coordinates: [5, 52],
            },
          },
        ],
      },
    },
  },
];

export const fakeDraftAirmet: Airmet = {
  uuid: 'someuniqueidprescibedbyBE',
  phenomenon: 'MOD_ICE' as AviationPhenomenaCode,
  sequence: 'A01',
  validDateStart: dateUtils.dateToString(
    dateUtils.add(dateUtils.utc(), { hours: 1 }),
  )!,
  validDateEnd: dateUtils.dateToString(
    dateUtils.add(dateUtils.utc(), { hours: 3 }),
  )!,
  firName: 'AMSTERDAM FIR',
  locationIndicatorATSU: 'EHAA',
  locationIndicatorATSR: 'EHAA',
  locationIndicatorMWO: 'EHDB',
  isObservationOrForecast: 'OBS',
  observationOrForecastTime: dateUtils.dateToString(
    dateUtils.sub(dateUtils.utc(), { hours: 2 }),
  ),
  movementType: 'STATIONARY',
  change: 'WKN',
  type: 'NORMAL',
  status: 'DRAFT',
  levelInfoMode: 'AT',
  level: {
    value: 1000,
    unit: 'FT' as LevelUnits,
  },
  firGeometry: getFir(airmetConfig),
  startGeometry: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [4.799062887514267, 54.04327723979929],
              [6.006227982070749, 53.64012115183787],
              [4.6168492883359304, 53.32840097044425],
              [4.799062887514267, 54.04327723979929],
            ],
          ],
        },
      },
    ],
  },
  startGeometryIntersect: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [4.799062887514267, 54.04327723979929],
              [6.006227982070749, 53.64012115183787],
              [4.6168492883359304, 53.32840097044425],
              [4.799062887514267, 54.04327723979929],
            ],
          ],
        },
      },
    ],
  },
};

export const fakePublishedAirmet: Airmet = {
  uuid: 'someuniqueidprescibedbyBE02',
  phenomenon: 'ISOL_TCU' as AviationPhenomenaCode,
  issueDate: dateUtils.dateToString(dateUtils.utc()),
  sequence: 'A02',
  validDateStart: dateUtils.dateToString(
    dateUtils.add(dateUtils.utc(), { hours: 1 }),
  )!,
  validDateEnd: dateUtils.dateToString(
    dateUtils.add(dateUtils.utc(), { hours: 3 }),
  )!,
  firName: 'AMSTERDAM FIR',
  locationIndicatorATSU: 'EHAA',
  locationIndicatorATSR: 'EHAA',
  locationIndicatorMWO: 'EHDB',
  isObservationOrForecast: 'OBS',
  observationOrForecastTime: dateUtils.dateToString(
    dateUtils.sub(dateUtils.utc(), { hours: 2 }),
  ),
  movementType: 'STATIONARY',
  change: 'WKN',
  type: 'NORMAL',
  status: 'PUBLISHED',
  levelInfoMode: 'AT',
  level: {
    value: 1000,
    unit: 'FT' as LevelUnits,
  },
  firGeometry: getFir(airmetConfig),
  startGeometry: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [4.799062887514267, 54.04327723979929],
              [6.006227982070749, 53.64012115183787],
              [4.6168492883359304, 53.32840097044425],
              [4.799062887514267, 54.04327723979929],
            ],
          ],
        },
      },
    ],
  },
  startGeometryIntersect: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [4.799062887514267, 54.04327723979929],
              [6.006227982070749, 53.64012115183787],
              [4.6168492883359304, 53.32840097044425],
              [4.799062887514267, 54.04327723979929],
            ],
          ],
        },
      },
    ],
  },
};

export const fakeCancelAirmet: CancelAirmet = {
  uuid: 'someuniqueid03',
  validDateStart: '2020-09-17T14:30:00Z',
  validDateEnd: '2020-09-17T17:00:00Z',
  sequence: '3',
  issueDate: '2020-09-17T14:30:00Z',
  firName: 'AMSTERDAM FIR',
  locationIndicatorATSU: 'EHAA',
  locationIndicatorATSR: 'EHAA',
  locationIndicatorMWO: 'EHDB',
  status: 'PUBLISHED',
  cancelsAirmetSequenceId: '113',
  validDateStartOfAirmetToCancel: '2020-09-17T13:00:00Z',
  validDateEndOfAirmetToCancel: '2020-09-17T17:00:00Z',
};

export const fakeNewAirmet: Airmet = {
  phenomenon: 'MOD_ICE' as AviationPhenomenaCode,
  sequence: 'A08',
  validDateStart: '2020-09-17T13:00:00Z',
  validDateEnd: '2020-09-17T17:00:00Z',
  firName: 'AMSTERDAM FIR',
  locationIndicatorATSU: 'EHAA',
  locationIndicatorATSR: 'EHAA',
  locationIndicatorMWO: 'EHDB',
  isObservationOrForecast: 'OBS',
  observationOrForecastTime: '2020-09-17T12:00:00Z',
  movementType: 'STATIONARY',
  change: 'WKN',
  type: 'NORMAL',
  status: 'DRAFT',
  levelInfoMode: 'AT',
  level: {
    value: 1000,
    unit: 'FT' as LevelUnits,
  },
  firGeometry: getFir(airmetConfig),
  startGeometry: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'box',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [1.4736647025096166, 52.00245103360221],
              [1.4736647025096166, 53.28757282590344],
              [9.058305768307893, 53.28757282590344],
              [9.058305768307893, 52.00245103360221],
              [1.4736647025096166, 52.00245103360221],
            ],
          ],
        },
      },
    ],
  },
  startGeometryIntersect: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.0552573, 53.28757283],
              [7.22786017, 53.28757283],
              [7.3, 53.25],
              [7.10288428, 52.21882119],
              [6.92570299, 52.00245103],
              [2.43067232, 52.00245103],
              [3.16909204, 52.86394071],
              [3.0552573, 53.28757283],
            ],
          ],
        },
      },
    ],
  },
};

export const fakeDraftAirmetCloud: AirmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  airmet: {
    uuid: 'someuniqueidprescibedbyBE',
    phenomenon: 'BKN_CLD' as AviationPhenomenaCode,
    sequence: 'A02V',
    issueDate: dateUtils.dateToString(dateUtils.utc()),
    observationOrForecastTime: null!,
    validDateStart: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 1 }),
    )!,
    validDateEnd: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 3 }),
    )!,
    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    isObservationOrForecast: 'OBS',
    movementType: 'MOVEMENT',
    change: 'WKN',
    type: 'NORMAL',
    status: 'DRAFT',
    movementSpeed: 10.0,
    movementUnit: 'KT' as MovementUnit,
    movementDirection: Direction.NNE,
    cloudLevelInfoMode: 'BETW_ABV',
    cloudLevel: {
      value: 900,
      unit: 'FT' as CloudLevelUnits,
    },
    cloudLowerLevel: {
      value: 100,
      unit: 'FT' as CloudLevelUnits,
    },
    firGeometry: getFir(airmetConfig),
    startGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'point',
          },
          geometry: {
            type: 'Point',
            coordinates: [5, 52],
          },
        },
      ],
    },
    startGeometryIntersect: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'point',
          },
          geometry: {
            type: 'Point',
            coordinates: [5, 52],
          },
        },
      ],
    },
  },
};

export const airmetNoValidStartEnd: AirmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  airmet: {
    uuid: 'someuniqueidprescribedbyBE',
    phenomenon: 'ISOL_TSGR' as AviationPhenomenaCode,
    sequence: 'A01',
    validDateStart: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 1 }),
    )!,
    validDateEnd: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 3 }),
    )!,
    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    isObservationOrForecast: 'OBS',
    observationOrForecastTime: dateUtils.dateToString(
      dateUtils.sub(dateUtils.utc(), { hours: 2 }),
    ),
    movementType: 'STATIONARY',
    change: 'WKN',
    type: 'NORMAL',
    status: 'DRAFT',
    levelInfoMode: 'ABV',
    level: {
      value: 1000,
      unit: 'FT' as LevelUnits,
    },
    firGeometry: getFir(airmetConfig),
    startGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [5.0, 55.0],
                [4.331914, 55.332644],
                [3.368817, 55.764314],
                [2.761908, 54.379261],
                [3.15576, 52.913554],
                [2.000002, 51.500002],
                [3.370001, 51.369722],
                [3.370527, 51.36867],
                [3.362223, 51.320002],
                [3.36389, 51.313608],
                [3.373613, 51.309999],
                [3.952501, 51.214441],
                [4.397501, 51.452776],
                [5.078611, 51.391665],
                [5.848333, 51.139444],
                [5.651667, 50.824717],
                [6.011797, 50.757273],
                [5.934168, 51.036386],
                [6.222223, 51.361666],
                [5.94639, 51.811663],
                [6.405001, 51.830828],
                [7.053095, 52.237764],
                [7.031389, 52.268885],
                [7.063612, 52.346109],
                [7.065557, 52.385828],
                [7.133055, 52.888887],
                [7.14218, 52.898244],
                [7.191667, 53.3],
                [6.5, 53.666667],
                [6.500002, 55.000002],
                [5.0, 55.0],
              ],
            ],
          },
          properties: {
            selectionType: 'fir',
          },
        },
      ],
    },
    startGeometryIntersect: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [5.0, 55.0],
                [4.331914, 55.332644],
                [3.368817, 55.764314],
                [2.761908, 54.379261],
                [3.15576, 52.913554],
                [2.000002, 51.500002],
                [3.370001, 51.369722],
                [3.370527, 51.36867],
                [3.362223, 51.320002],
                [3.36389, 51.313608],
                [3.373613, 51.309999],
                [3.952501, 51.214441],
                [4.397501, 51.452776],
                [5.078611, 51.391665],
                [5.848333, 51.139444],
                [5.651667, 50.824717],
                [6.011797, 50.757273],
                [5.934168, 51.036386],
                [6.222223, 51.361666],
                [5.94639, 51.811663],
                [6.405001, 51.830828],
                [7.053095, 52.237764],
                [7.031389, 52.268885],
                [7.063612, 52.346109],
                [7.065557, 52.385828],
                [7.133055, 52.888887],
                [7.14218, 52.898244],
                [7.191667, 53.3],
                [6.5, 53.666667],
                [6.500002, 55.000002],
                [5.0, 55.0],
              ],
            ],
          },
          properties: {
            selectionType: 'fir',
          },
        },
      ],
    },
  },
};

export const fakeStaticAirmetList: AirmetFromBackend[] = [
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE',
      phenomenon: 'ISOL_TSGR' as AviationPhenomenaCode,
      sequence: 'A01',
      validDateStart: '2020-09-17T12:05:00Z',
      validDateEnd: '2020-09-17T14:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T11:55:00Z',
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'DRAFT',
      levelInfoMode: 'ABV',
      level: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-17T08:29:00Z',
    canbe: ['CANCELLED'],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE2',
      phenomenon: 'SFC_VIS' as AviationPhenomenaCode,
      sequence: 'A01V',
      issueDate: '2020-09-17T08:29:00Z',
      validDateStart: '2020-09-17T08:49:00Z',
      validDateEnd: '2020-09-17T10:29:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T08:19:00Z',
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      visibilityValue: 1000,
      visibilityCause: 'DZ' as VisibilityCause,
      visibilityUnit: 'm',
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-16T23:19:00Z',
    canbe: ['CANCELLED'],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE3',
      phenomenon: 'ISOL_TS' as AviationPhenomenaCode,
      sequence: 'A02V30',
      issueDate: '2020-09-16T23:19:00Z',
      validDateStart: '2020-09-16T23:19:00Z',
      validDateEnd: '2020-09-17T02:19:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-16T23:00:00Z',
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      levelInfoMode: 'BETW',
      level: {
        value: 1500,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    canbe: [],
    creationDate: '2020-09-14T12:10:00Z',
    airmet: {
      uuid: 'someuniqueidprescibedbyBE4',
      validDateStart: '2020-09-14T14:30:00Z',
      validDateEnd: '2020-09-14T17:00:00Z',
      sequence: '4',
      issueDate: '2020-09-14T14:30:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      status: 'PUBLISHED',
      cancelsAirmetSequenceId: '113',
      validDateStartOfAirmetToCancel: '2020-09-14T13:00:00Z',
      validDateEndOfAirmetToCancel: '2020-09-14T17:00:00Z',
      type: 'NORMAL',
    },
  },
  {
    creationDate: '2020-09-14T13:00:00Z',
    canbe: [],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE7',
      phenomenon: 'OVC_CLD' as AviationPhenomenaCode,
      sequence: 'A09',
      issueDate: '2020-09-14T13:00:00Z',
      validDateStart: '2020-09-14T13:00:00Z',
      validDateEnd: '2020-09-14T16:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-14T12:00:00Z',
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      cloudLevelInfoMode: 'BETW_SFC_ABV',
      cloudLevel: {
        value: 1000,
        unit: 'FT' as CloudLevelUnits,
      },
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-13T16:00:00Z',
    canbe: [],
    airmet: {
      uuid: 'someuniqueidprescibedbyBE12',
      phenomenon: 'SFC_WIND' as AviationPhenomenaCode,
      sequence: '113',
      issueDate: '2020-09-13T16:00:00Z',
      validDateStart: '2020-09-13T15:00:00Z',
      validDateEnd: '2020-09-13T16:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-13T17:00:00Z',
      movementType: 'MOVEMENT',
      movementSpeed: 20.0,
      movementUnit: 'KT' as MovementUnit,
      movementDirection: Direction.NNE,
      change: 'WKN',
      type: 'NORMAL',
      status: 'CANCELLED',
      windSpeed: 120,
      windUnit: 'KT' as WindUnit,
      windDirection: 220,
      firGeometry: getFir(airmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
    },
  },
];

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { snackbarAdapter } from './reducer';
import { SnackbarModuleStore, SnackbarState } from './types';

export const getSnackbarStore = (store: SnackbarModuleStore): SnackbarState => {
  if (store && store.snackbar) {
    return store.snackbar;
  }
  return null!;
};

export const { selectAll: getCurrentSnackbarMessages } =
  snackbarAdapter.getSelectors((state: SnackbarModuleStore) => {
    return state?.snackbar ?? { entities: {}, ids: [] };
  });

export const getFirstSnackbarMessage = createSelector(
  getCurrentSnackbarMessages,
  (currentMessages) => {
    return currentMessages.length > 0 ? currentMessages[0] : undefined;
  },
);

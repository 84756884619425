/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  GetCapabilitiesJson,
  LayerProps,
  LayerTree,
  WMXMLStringToJson,
} from '../components';
import WMS130GetCapabilitiesHarmN25 from './getCapabilitySpecs/WMS130GetCapabilitiesHarmN25';
import WMS130GetCapabilitiesRadarTestWithInheritAndReplaceLayerprops from './getCapabilitySpecs/WMS130GetCapabilitiesRadarTestWithInheritAndReplaceLayerprops';
import WMS130GetCapabilitiesRadarTestWithInheritLayerprops from './getCapabilitySpecs/WMS130GetCapabilitiesRadarTestWithInheritLayerprops';
import WMS130GetCapabilitiesRadarTestWithoutInheritLayerprops from './getCapabilitySpecs/WMS130GetCapabilitiesRadarTestWithoutInheritLayerprops';
import WMSSmartMet from './getCapabilitySpecs/WMSSmartMet';
import { radarGetCapabilities } from './getCapabilitySpecs/radarGetCapabilities';
import { WMS111GetCapabilitiesGeoServicesRADAR } from './getCapabilitySpecs/WMS111GetCapabilitiesGeoServicesRADAR';
import { WMS130GetCapabilitiesWithoutLegend } from './getCapabilitySpecs/WMSGetCapabilitiesMissingLegend';

export const MOCK_URL_WITH_CHILDREN = 'https://mockUrlWithChildren.nl';
export const MOCK_URL_NO_CHILDREN = 'https://mockUrlNoChildren.nl';
export const MOCK_URL_WITH_NO_TITLE = 'https://mockUrlWithNoTitle.nl';
export const MOCK_URL_WITH_NO_TITLE_OR_NAME =
  'https://mockUrlWithNoTitleOrName.nl/wms?';
export const MOCK_URL_WITH_SUBCATEGORY = 'https://mockUrlWithSubcategory.nl';
export const MOCK_URL_INVALID = 'https://notawmsservice.nl';
export const MOCK_URL_SLOW = 'https://slowresolve.nl';
export const MOCK_URL_SLOW_FAILS = 'https://slowreject.nl';
export const MOCK_URL_DEFAULT = 'https://defaultservice.nl';
export const MOCK_URL_DEFAULT2 = 'https://defaultservice2.nl';
export const MOCK_URL_HTTP = 'http://wmsservice.nl';
export const MOCK_URL_HARMONIE = 'WMS130GetCapabilitiesHarmN25';
export const MOCK_URL_WMS130_NOLEGEND = 'MOCK_URL_WMS130_NOLEGEND';

export const mockLayersNoChildren = {
  leaf: false,
  name: null,
  title: 'RADNL_OPER_R___25PCPRR_L3_WMS',
  path: [],
  children: [],
};

export const mockLayersWithChildren = {
  leaf: false,
  name: null,
  title: 'RADNL_OPER_R___25PCPRR_L3_WMS',
  path: [],
  children: [
    {
      leaf: true,
      name: 'RAD_NL25_PCP_CM',
      title: 'RAD_NL25_PCP_CM',
      path: [],
      children: [],
    },
    {
      leaf: true,
      name: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
      title: 'RADNL_OPER_R___25PCPRR_L3_KNMI',
      path: [],
      children: [],
    },
  ],
};

export const mockLayersWithNoTitle: LayerTree = {
  leaf: false,
  name: 'UNTITLED_WMS',
  title: null!,
  path: [],
  children: [],
};

export const mockLayersWithNoTitleOrName: LayerTree = {
  leaf: false,
  name: null,
  title: null!,
  path: [],
  children: [],
};

export const mockLayersWithSubcategory = {
  leaf: false,
  name: null,
  title: 'Service with subcategory',
  path: [],
  children: [
    {
      leaf: true,
      name: 'cloud_area_fraction',
      title: 'Cloud cover (flag)',
      path: [],
      children: [],
    },
    {
      leaf: false,
      name: null,
      title: 'Subcategory',
      path: [],
      children: [
        {
          leaf: true,
          name: 'baselayer',
          title: 'Baselayer (-)',
          path: ['Subcategory'],
          children: [],
        },
        {
          leaf: false,
          name: null,
          title: 'Klima',
          path: ['Subcategory'],
          children: [
            {
              leaf: true,
              name: 'haic_airports',
              title: 'HAIC Airports',
              path: ['SubcategoryKlima'],
              children: [],
            },
          ],
        },
      ],
    },
  ],
};

export const mockLayersDefault = {
  leaf: false,
  name: null,
  title: 'Default title for default layer',
  path: [],
  children: [],
};
export const mockLayersDefault2 = {
  leaf: false,
  name: null,
  title: 'Default title for default 2 layer',
  path: [],
  children: [],
};

export const mockGetLayersFromService = (url: string): Promise<LayerTree> => {
  switch (url) {
    case MOCK_URL_WITH_CHILDREN:
      return new Promise((resolve) => {
        resolve(mockLayersWithChildren);
      });
    case MOCK_URL_NO_CHILDREN:
      return new Promise((resolve) => {
        resolve(mockLayersNoChildren);
      });
    case MOCK_URL_WITH_NO_TITLE:
      return new Promise((resolve) => {
        resolve(mockLayersWithNoTitle);
      });
    case MOCK_URL_WITH_NO_TITLE_OR_NAME:
      return new Promise((resolve) => {
        resolve(mockLayersWithNoTitleOrName);
      });
    case MOCK_URL_WITH_SUBCATEGORY:
      return new Promise((resolve) => {
        resolve(mockLayersWithSubcategory);
      });
    case MOCK_URL_SLOW:
      return new Promise((resolve) => {
        setTimeout(() => resolve(mockLayersWithChildren), 300);
      });
    case MOCK_URL_SLOW_FAILS:
      return new Promise((resolve, reject) => {
        setTimeout(
          () =>
            reject(new Error(`Mock url '${url}' service returned an error.`)),
          300,
        );
      });
    case MOCK_URL_INVALID:
      return Promise.reject(new Error(`Url '${url}' is not a wms service.`));
    case MOCK_URL_HTTP:
      return Promise.reject(new Error(`Url '${url}' is not https.`));
    case MOCK_URL_DEFAULT:
      return new Promise((resolve) => {
        resolve(mockLayersDefault);
      });
    case MOCK_URL_DEFAULT2:
      return new Promise((resolve) => {
        resolve(mockLayersDefault2);
      });
    default: {
      const message = `Url '${url}' is not a mock-url.`;
      return Promise.reject(new Error(message));
    }
  }
};

export const mockGetLayersFlattenedFromService = async (
  url: string,
): Promise<LayerProps[]> => {
  const layers = await mockGetLayersFromService(url);
  const serviceLayers = [] as LayerProps[];
  for (const object of layers.children) {
    serviceLayers.push({
      name: object.name!,
      title: object.title!,
      leaf: object.leaf,
      path: object.path as string[],
      keywords: object.keywords ? object.keywords : [],
      abstract: object.abstract ? object.abstract : '',
      styles: object.styles ? object.styles : [],
      dimensions: object.dimensions || [],
      geographicBoundingBox: object.geographicBoundingBox || undefined,
    });
  }

  return serviceLayers;
};

const mockServiceFromStore = {
  id: 'serviceid_1',
  title: 'Service test title',
  abstract: 'Service Abstract',
};

const mockGetCapNoChilds: GetCapabilitiesJson = {
  WMS_Capabilities: {
    Service: {
      Title: {
        value: 'ti',
      },
      Abstract: { value: 'Service Abstract' },
    },
    Capability: {
      Layer: {},
    },
  },
};

const mockGetCapWithSubcategory: GetCapabilitiesJson = {
  WMS_Capabilities: {
    Service: {
      Title: {
        value: mockServiceFromStore.title,
      },
      Abstract: { value: mockServiceFromStore.abstract },
    },
    Capability: {
      Layer: {
        Layer: [
          {
            Name: { value: 'cloud_area_fraction' },
            Title: { value: 'Cloud cover (flag)' },
          },
          {
            Title: { value: 'Subcategory' },
            Layer: [
              {
                Name: { value: 'baselayer' },
                Title: { value: 'Baselayer (-)' },
              },
              {
                Title: { value: 'Klima' },
                Layer: [
                  {
                    Name: { value: 'haic_airports' },
                    Title: { value: 'HAIC Airports' },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};

const mockGetCapLayersDefault: GetCapabilitiesJson = {
  WMS_Capabilities: {
    Service: {
      Title: {
        value: mockServiceFromStore.title,
      },
      Abstract: { value: mockServiceFromStore.abstract },
    },
    Capability: {
      Layer: {
        Title: { value: 'Default title for default layer' },
      },
    },
  },
};

const mockGetCapLayersDefault2: GetCapabilitiesJson = {
  WMS_Capabilities: {
    Service: {
      Title: {
        value: mockServiceFromStore.title,
      },
      Abstract: { value: mockServiceFromStore.abstract },
    },
    Capability: {
      Layer: {
        Title: { value: 'Default title for default 2 layer' },
      },
    },
  },
};

const mockGetCapLayersNoTitle: GetCapabilitiesJson = {
  WMS_Capabilities: {
    Service: {
      Title: {
        value: mockServiceFromStore.title,
      },
      Abstract: { value: mockServiceFromStore.abstract },
    },
    Capability: {
      Layer: {
        Name: { value: 'UNTITLED_WMS' },
      },
    },
  },
};

const mockGetCapLayersNoTitleOrName: GetCapabilitiesJson = {
  WMS_Capabilities: {
    Service: {
      Title: {
        value: mockServiceFromStore.title,
      },
      Abstract: { value: mockServiceFromStore.abstract },
    },
    Capability: {},
  },
};

const mockGetCap: GetCapabilitiesJson = {
  WMS_Capabilities: {
    Service: {
      Title: {
        value: mockServiceFromStore.title,
      },
      Abstract: { value: mockServiceFromStore.abstract },
    },
    Capability: {
      Layer: {
        Title: { value: 'RADNL_OPER_R___25PCPRR_L3_WMS' },
        Layer: [
          {
            Name: { value: 'RADNL_OPER_R___25PCPRR_L3_KNMI' },
            Title: { value: 'RADNL_OPER_R___25PCPRR_L3_KNMI' },
          },
          {
            Name: { value: 'RAD_NL25_PCP_CM' },
            Title: { value: 'RAD_NL25_PCP_CM' },
          },
        ],
      },
    },
  },
};

export const mockGetCapabilitiesFetcher = async (
  serviceUrl: string,
): Promise<GetCapabilitiesJson> => {
  switch (serviceUrl) {
    case MOCK_URL_NO_CHILDREN:
      return mockGetCapNoChilds;
    case MOCK_URL_WITH_CHILDREN:
    case 'https://geoservices.knmi.nl/wms?dataset=RADAR&':
      return mockGetCap;
    case MOCK_URL_WITH_SUBCATEGORY:
      return mockGetCapWithSubcategory;
    case MOCK_URL_DEFAULT:
      return mockGetCapLayersDefault;
    case MOCK_URL_DEFAULT2:
      return mockGetCapLayersDefault2;
    case MOCK_URL_WITH_NO_TITLE:
      return mockGetCapLayersNoTitle;
    case MOCK_URL_WITH_NO_TITLE_OR_NAME:
      return mockGetCapLayersNoTitleOrName;
    case 'https://testservice':
      return WMXMLStringToJson(WMS111GetCapabilitiesGeoServicesRADAR);
    case 'testservice':
      return radarGetCapabilities;
    case 'WMS130GetCapabilitiesRadarTestWithoutInheritLayerprops':
      return WMXMLStringToJson(
        WMS130GetCapabilitiesRadarTestWithoutInheritLayerprops,
      );
    case 'WMS130GetCapabilitiesRadarTestWithInheritLayerprops':
      return WMXMLStringToJson(
        WMS130GetCapabilitiesRadarTestWithInheritLayerprops,
      );
    case 'WMS130GetCapabilitiesRadarTestWithInheritAndReplaceLayerprops':
      return WMXMLStringToJson(
        WMS130GetCapabilitiesRadarTestWithInheritAndReplaceLayerprops,
      );
    case 'WMS111GetCapabilitiesGeoServicesRADAR':
      return WMXMLStringToJson(WMS111GetCapabilitiesGeoServicesRADAR);
    case 'WMSSmartMet':
      return WMXMLStringToJson(WMSSmartMet);
    case MOCK_URL_HARMONIE:
      return WMXMLStringToJson(WMS130GetCapabilitiesHarmN25);
    case MOCK_URL_WMS130_NOLEGEND:
      return WMXMLStringToJson(WMS130GetCapabilitiesWithoutLegend);
    case MOCK_URL_INVALID:
      throw new Error("Url 'https://notawmsservice.nl' is not a wms service.");
    default:
      return mockGetCap;
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  DialogProps,
} from '@mui/material';
import { Close } from '@opengeoweb/theme';
import { CustomIconButton } from '../CustomIconButton';

export type DialogAction = 'CLOSED';

export interface CustomDialogProps extends DialogProps {
  description?: string;
  actions?: React.ReactNode;
  onClose: (reason: DialogAction) => void;
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  onClose,
  description = '',
  actions = null,
  children,
  sx,
  ...other
}: CustomDialogProps) => {
  return (
    <Dialog
      data-testid="customDialog"
      PaperProps={{
        sx: { width: '560px', margin: 2.5, ...sx },
      }}
      // fix for briefly showing default options when closing dialog
      transitionDuration={0}
      onClose={(): void => {
        onClose('CLOSED');
      }}
      aria-labelledby="dialog-title"
      {...other}
    >
      <DialogTitle
        id="dialog-title"
        sx={{
          zIndex: 1,
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
          }}
          data-testid="customDialog-title"
          component="span"
        >
          {title}
        </Typography>
        <CustomIconButton
          data-testid="customDialog-close"
          aria-label="close"
          onClick={(): void => onClose('CLOSED')}
          sx={{
            '&.MuiIconButton-root': {
              color: 'geowebColors.greys.accessible',
            },
          }}
        >
          <Close data-testid="CloseIcon" />
        </CustomIconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '8px 16px',
        }}
      >
        {description && <Typography variant="body1">{description}</Typography>}
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          padding: 2,
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        {actions}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;

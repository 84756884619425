/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { ReactHookFormTextField } from '@opengeoweb/form-fields';
import { useFormContext } from 'react-hook-form';
import { AlertBanner, PresetScope } from '@opengeoweb/shared';
import { useWorkspaceTranslation } from '../../utils/i18n';
import { getPresetTitle } from '../../utils/utils';
import { WorkspaceDialogView } from '../../store/workspaceList/types';

const getExistingPresetWarningText = (
  isAdmin: boolean,
  scope: PresetScope,
): string => {
  if (isAdmin) {
    return 'workspace-admin-save-new-viewpreset-warning';
  }
  if (scope === 'user') {
    return 'workspace-save-new-viewpreset-warning-user';
  }
  return 'workspace-save-new-viewpreset-warning-system';
};

const getNewPresetWarningText = (isAdmin: boolean, isNew: boolean): string => {
  if (isAdmin) {
    return 'workspace-admin-save-new-viewpreset';
  }
  if (isNew) {
    return 'workspace-save-new-viewpreset';
  }
  return '';
};

const HelperText: React.FC<{ text: string }> = ({ text }) => (
  <Typography
    sx={{
      fontSize: 12,
      lineHeight: '16px',
      color: 'geowebColors.textInputField.label.rgba',
    }}
  >
    {text}
  </Typography>
);

const inputMargin = 2;

const ViewPresetItem: React.FC<{
  index: number;
  viewPreset: WorkspaceDialogView;
  isLoading: boolean;
  isAdmin: boolean;
}> = ({ index, viewPreset, isLoading = false, isAdmin = true }) => {
  const { t } = useWorkspaceTranslation();
  const { setValue, watch, setError } = useFormContext();

  const isNew =
    !viewPreset.viewPresetId || viewPreset.viewPresetId === 'emptyMap';
  const isChecked = watch(`views.${index}.isSelected`) === true;
  // useState to keep the original title after posting
  const [viewPresetTitle] = React.useState(
    viewPreset.title || t('workspace-mappreset-new'),
  );
  const label = getPresetTitle(
    viewPresetTitle,
    viewPreset.hasChanges,
    isNew,
    t,
  );

  const { hasChanges, error } = viewPreset;

  const fieldNameTitle = `views.${index}.title`;

  React.useEffect(() => {
    const suffix =
      isNew || (viewPreset.scope === 'system' && !isAdmin)
        ? ` ${viewPreset.mosaicNodeId}`
        : '';

    const initialTitle = `${viewPresetTitle}${suffix}`;

    setValue(`views.${index}.title`, initialTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setValue(`views.${index}.viewPresetId`, viewPreset.viewPresetId);
  }, [viewPreset.viewPresetId, index, setValue]);

  React.useEffect(() => {
    if (error) {
      setError(fieldNameTitle, { message: error.message });
    }
  }, [error, fieldNameTitle, setError]);

  const shouldShowInput =
    (isNew && isChecked) ||
    (isChecked && !isAdmin && viewPreset.scope === 'system');

  const isInputDisabled = isLoading || !hasChanges;

  return (
    <React.Fragment key={viewPreset.mosaicNodeId}>
      <FormControlLabel
        control={<Checkbox />}
        label={
          <Box
            component="span"
            style={{
              ...(hasChanges && {
                fontStyle: 'italic',
              }),
            }}
          >
            {label}
            <Box
              component="span"
              sx={{ color: 'geowebColors.captions.captionStatus.rgba' }}
            >
              {` ${viewPreset.mosaicNodeId}`}
            </Box>
          </Box>
        }
        onChange={(_, isChecked) => {
          setValue(`views.${index}.isSelected`, isChecked);
        }}
        checked={isChecked || !hasChanges}
        disabled={isLoading || !hasChanges}
      />
      {error && !isNew && <AlertBanner title={error.message} />}
      {shouldShowInput && (
        <>
          <ReactHookFormTextField
            name={fieldNameTitle}
            label={t('workspace-dialog-name')}
            rules={{
              required: true,
            }}
            disabled={isInputDisabled}
            sx={{ mb: inputMargin }}
            error={error !== undefined}
          />

          <ReactHookFormTextField
            name={`views.${index}.abstract`}
            label={t('workspace-dialog-abstract')}
            rules={{
              required: false,
            }}
            multiline
            rows={3}
            disabled={isInputDisabled}
            sx={{ mb: inputMargin }}
          />

          <HelperText text={t(getNewPresetWarningText(isAdmin, isNew))} />
        </>
      )}
      {!isNew && isChecked && hasChanges && (
        <HelperText
          text={t(getExistingPresetWarningText(isAdmin, viewPreset.scope))}
        />
      )}
    </React.Fragment>
  );
};

const WorkspaceViewPresetsFormFields: React.FC<{
  viewPresets: WorkspaceDialogView[];
  isLoading?: boolean;
  isAdmin?: boolean;
}> = ({ viewPresets = [], isLoading = false, isAdmin = true }) => {
  const { t } = useWorkspaceTranslation();

  return viewPresets.length ? (
    <FormGroup sx={{ marginBottom: 2, marginTop: 2 }}>
      <HelperText
        text={t(
          isAdmin
            ? 'workspace-admin-select-modified-viewpresets'
            : 'workspace-select-modified-viewpresets',
        )}
      />
      {viewPresets.map((viewPreset, index) => {
        return (
          <ViewPresetItem
            key={viewPreset.mosaicNodeId}
            viewPreset={viewPreset}
            index={index}
            isLoading={isLoading}
            isAdmin={isAdmin}
          />
        );
      })}
    </FormGroup>
  ) : null;
};

export default WorkspaceViewPresetsFormFields;

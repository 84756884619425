/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ClickAwayListener, Popper } from '@mui/material';
import { CustomToggleButton, CustomTooltip } from '@opengeoweb/shared';
import { CalendarToday } from '@opengeoweb/theme';
import React, { FC } from 'react';
import { useTimesliderTranslation } from '../../../utils/i18n';
import { MarkerTimeSelector } from '../MarkerTimeSelector/MarkerTimeSelector';

interface CalendarButtonProps {
  calendarIconPx: number;
  dataStartTime: number | undefined;
  dataEndTime: number | undefined;
  selectedTime?: number;
  onSelect?: (value: number | null) => void;
}

const style = {
  '&.MuiToggleButton-root': {
    color: '#B4B4B4',
    '&:hover': {
      color: '#F8F9F9 !important',
      backgroundColor: 'rgba(255,255,225, 0.3)',
    },
    '&.Mui-selected': {
      color: '#7BACFF !important',
      '&:hover': {
        backgroundColor: 'rgba(255,255,225, 0.3)',
      },
    },
  },
  width: '26px',
  height: '24px!important',
};

export const CalendarButton: FC<CalendarButtonProps> = ({
  calendarIconPx,
  dataStartTime,
  dataEndTime,
  selectedTime,
  onSelect = (): void => {},
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const { t } = useTimesliderTranslation();

  return (
    <ClickAwayListener
      onClickAway={() => setOpen(false)}
      mouseEvent="onMouseDown"
    >
      <div
        style={{
          position: 'absolute',
          top: '-2px',
          left: `${calendarIconPx}px`,
        }}
      >
        <CustomTooltip
          title={!open && t('timeslider-marker-placement')}
          TransitionProps={{ exit: false }}
        >
          <CustomToggleButton
            sx={style}
            selected={open}
            variant="flat"
            onClick={() => setOpen(!open)}
            ref={(ref): void => {
              setAnchorEl(ref);
            }}
          >
            <CalendarToday style={{ marginRight: '0px' }} />
          </CustomToggleButton>
        </CustomTooltip>

        <Popper
          sx={{ zIndex: 10 }}
          placement="top-start"
          open={open}
          data-testid="dateTimePickerPopOver"
          anchorEl={anchorEl}
        >
          <MarkerTimeSelector
            dataStartTime={dataStartTime}
            dataEndTime={dataEndTime}
            value={selectedTime}
            onSelect={(newTime) => {
              setOpen(false);
              onSelect(newTime);
            }}
            onCancel={() => setOpen(false)}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

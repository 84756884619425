/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { debounce } from 'lodash';
import { dateUtils } from '@opengeoweb/shared';
import {
  pixelToTimestamp,
  roundWithTimeStep,
  timestampToPixel,
} from './timeSliderUtils';

export const onsetNewDateDebounced = debounce(
  (dateToSet: string, onSetNewDate: (newDate: string) => void): void => {
    onSetNewDate(dateToSet);
  },
  4,
  { maxWait: 40 },
);

export const setPreviousTimeStep = (
  timeStep: number,
  currentTime: number,
  dataStartTime: number,
  onSetNewDate: (newDate: string) => void,
): void => {
  const nextTime = currentTime - timeStep;
  const roundedTime = roundWithTimeStep(nextTime, timeStep, 'floor');
  const newTime = Math.max(roundedTime, dataStartTime || roundedTime);
  const selectedTimeString = dateUtils.fromUnix(newTime).toISOString();
  onSetNewDate(selectedTimeString);
};

export const setNextTimeStep = (
  timeStep: number,
  currentTime: number,
  dataEndTime: number,
  onSetNewDate: (newDate: string) => void,
): void => {
  const nextTime = currentTime + timeStep;
  const roundedTime = roundWithTimeStep(nextTime, timeStep, 'ceil');
  const newTime = Math.min(roundedTime, dataEndTime || roundedTime);
  const selectedTimeString = dateUtils.fromUnix(newTime).toISOString();
  onSetNewDate(selectedTimeString);
};

/**
 * updates unfiltered selected time and moves selected time a given distance
 * in pixels. Unfiltered selected time can be outside data data limits, but
 * selected time cannot.
 */
export const moveSelectedTimePx = (
  pixelsMoved: number,
  canvasWidth: number,
  centerTime: number,
  dataStartTime: number,
  dataEndTime: number,
  secondsPerPx: number,
  timeStep: number,
  unfilteredSelectedTime: number,
  setUnfilteredSelectedTime: (unfilteredSelectedTime: number) => void,
): void => {
  const unfilteredSelectedTimePx = timestampToPixel(
    unfilteredSelectedTime,
    centerTime,
    canvasWidth,
    secondsPerPx,
  );

  const clickedUnfilteredSelectedTimePx =
    unfilteredSelectedTimePx + pixelsMoved;

  const newUnfilteredSelectedTime = pixelToTimestamp(
    clickedUnfilteredSelectedTimePx,
    centerTime,
    canvasWidth,
    secondsPerPx,
  );
  setUnfilteredSelectedTime(newUnfilteredSelectedTime);
};

export function getFilteredTime(
  unfilteredTime: number,
  timeStep?: number,
  dataStartTime?: number,
  dataEndTime?: number,
): number {
  if (!timeStep || !dataStartTime || !dataEndTime) {
    return unfilteredTime;
  }
  const roundedTime = roundWithTimeStep(unfilteredTime, timeStep);
  const maxTime = Math.max(roundedTime, dataStartTime);
  return Math.min(maxTime, dataEndTime);
}

/** Reusable business logic for how to handle events that set time to now (closest).
 * Used in NowButton and TimeSliderLegend.
 */
export const handleSetNowEvent = (
  timeStep: number,
  dataStartTime: number,
  dataEndTime: number,
  currentTime: number,
  onSetNewDate: (newDate: string) => void,
  onSetCenterTime: (newTime: number) => void,
): void => {
  if (dataStartTime < dataEndTime) {
    if (dataEndTime < currentTime) {
      onSetNewDate(dateUtils.fromUnix(dataEndTime).toISOString());
      onSetCenterTime(dataEndTime);
    } else if (dataStartTime > currentTime) {
      onSetNewDate(dateUtils.fromUnix(dataStartTime).toISOString());
      onSetCenterTime(dataStartTime);
    } else {
      const closestToCurrent = roundWithTimeStep(currentTime, timeStep);
      onSetNewDate(dateUtils.fromUnix(closestToCurrent).toISOString());
      onSetCenterTime(closestToCurrent);
    }
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';

import { UseFormProps } from 'react-hook-form';
import { ReactHookFormProvider } from '@opengeoweb/form-fields';
import { SWEvent } from '../../types';
import { constructBaseNotification } from './utils';

interface FormWrapperProps {
  baseNotificationData?: SWEvent;
  baseNotificationType: string;
  children: React.ReactNode;
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  baseNotificationData,
  baseNotificationType,
  children,
}: FormWrapperProps) => {
  const formOptions: UseFormProps = {
    defaultValues: constructBaseNotification(
      baseNotificationData!,
      baseNotificationType,
    ),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  };

  return (
    <ReactHookFormProvider options={formOptions}>
      {children}
    </ReactHookFormProvider>
  );
};

export default FormWrapper;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Credentials, getConfig } from '@opengeoweb/authentication';
import {
  AirmetConfig,
  AirmetFromBackend,
  getHeaders,
  handleResponse,
  SigmetConfig,
  SigmetFromBackend,
} from '@opengeoweb/api';
import { AviationProduct } from '@opengeoweb/sigmet-airmet';

const config = getConfig();

const airmetBaseUrl = config.GW_AIRMET_BASE_URL || '';
const sigmetBaseUrl = config.GW_SIGMET_BASE_URL || '';

// API
export const getAirmetList = (
  auth: Credentials,
): Promise<AirmetFromBackend[]> =>
  fetch(`${airmetBaseUrl}/airmetlist`, {
    headers: getHeaders(auth),
  }).then(handleResponse);

export const getSigmetList = (
  auth: Credentials,
): Promise<SigmetFromBackend[]> =>
  fetch(`${sigmetBaseUrl}/sigmetlist`, {
    headers: getHeaders(auth),
  }).then(handleResponse);

export const getAirmetConfig = (auth: Credentials): Promise<AirmetConfig> => {
  return fetch(`${airmetBaseUrl}/airmet-config`, {
    headers: getHeaders(auth),
  }).then((r) => r.json());
};
export const getSigmetConfig = (auth: Credentials): Promise<SigmetConfig> => {
  return fetch(`${sigmetBaseUrl}/sigmet-config`, {
    headers: getHeaders(auth),
  }).then((r) => r.json());
};

export const getAirmetTAC = (
  auth: Credentials,
  product: AviationProduct,
): Promise<{ data: string }> => {
  return fetch(`${airmetBaseUrl}/airmet2tac`, {
    body: JSON.stringify(product),
    method: 'POST',
    headers: getHeaders(auth),
  }).then(async (r) => {
    if (r.status === 200) {
      const textData = await r.text();
      return { data: textData };
    }
    throw new Error(`Failed to fetch TAC data: ${r.statusText}`);
  });
};

export const getSigmetTAC = (
  auth: Credentials,
  product: AviationProduct,
): Promise<{ data: string }> => {
  return fetch(`${sigmetBaseUrl}/sigmet2tac`, {
    body: JSON.stringify(product),
    method: 'POST',
    headers: getHeaders(auth),
  }).then(async (r) => {
    if (r.status === 200) {
      const textData = await r.text();
      return { data: textData };
    }
    throw new Error(`Failed to fetch TAC data: ${r.statusText}`);
  });
};

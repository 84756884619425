/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

export interface Bbox {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export default class WMBBOX implements Bbox {
  left: number;
  bottom: number;
  right: number;
  top: number;

  constructor(
    left?: number | string | Bbox,
    bottom?: number,
    right?: number,
    top?: number,
  ) {
    this.left = -180; // 0
    this.bottom = -90; // 1`
    this.right = 180; // 2
    this.top = 90; // 3
    this.clone = this.clone.bind(this);
    this.copy = this.copy.bind(this);
    this.setBBOX = this.setBBOX.bind(this);
    this.equals = this.equals.bind(this);
    this.toString = this.toString.bind(this);
    this.setBBOX(left, bottom, right, top);
  }

  /**
   * Clone an existing BBOX
   */
  clone(): WMBBOX {
    return new WMBBOX(this.left, this.bottom, this.right, this.top);
  }

  copy(bbox: WMBBOX): void {
    this.left = bbox.left;
    this.bottom = bbox.bottom;
    this.right = bbox.right;
    this.top = bbox.top;
  }

  /**
   * setBBOX method
   * Creates a WebMapJS bounding box based on 4 numeric values
   * Creates a WebMapJS bounding box based on 1 string with commaseparated numeric values
   * Creates a WebMapJS bounding box based on an exisiting WebMapJS bounding box.
   * When no parameters are given, a global latlon bbox is generated.
   */
  setBBOX(
    left?: number | string | Bbox,
    bottom?: number,
    right?: number,
    top?: number,
  ): void {
    /* Check if we recieve more than one parameter by checking the second bottom parameter. */
    if (bottom !== undefined) {
      this.left = left as number; // -180; // 0
      this.bottom = bottom; // -90; // 1`
      this.right = right!; // 180; // 2
      this.top = top!; // 90; // 3
    }
    /* Check if we recieve more than one parameter by checking the second bottom parameter. */
    if (bottom === undefined) {
      /* Otherwise it can be a bbox object or a string in the form "left,bottom,right,top" */

      if (typeof left === 'object') {
        /* A bbox object is given, so it is not a string */
        const a = left;
        this.left = a.left;
        this.bottom = a.bottom;
        this.right = a.right;
        this.top = a.top;
      } else {
        /* A string like "-180,-90,180,90" is given */
        try {
          const a = (left as string).split(',');
          if (a.length !== 4) {
            return;
          }
          this.left = parseFloat(a[0]);
          this.bottom = parseFloat(a[1]);
          this.right = parseFloat(a[2]);
          this.top = parseFloat(a[3]);
        } catch (e) {
          return;
        }
      }
    }
    /* Apply defaults to invalid values */
    if (this.left == null) {
      this.left = -180;
    }
    if (this.right == null) {
      this.right = 180;
    }
    if (this.bottom == null) {
      this.bottom = -90;
    }
    if (this.top == null) {
      this.top = 90;
    }
  }

  /*
   * Compares two boundingboxes and returns true if they are equal
   */
  equals(
    left: number | string | WMBBOX | Bbox,
    bottom: number,
    right: number,
    top: number,
  ): boolean {
    return (
      left === this.left &&
      bottom === this.bottom &&
      right === this.right &&
      top === this.top
    );
  }

  /**
   * Returns the current BBOX as a string, useful for generating WMS requests.
   */
  toString(): string {
    return `${this.left},${this.bottom},${this.right},${this.top}`;
  }
}

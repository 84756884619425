/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/**
 * Utility function to keep track of number of renders by a react component
 *
 * Usage:
 * 1) reset all counters to zero by:
 *      renderCounter.reset()
 * 2) in the render function of the functional component do:
 *      renderCounter.count(<your react functional component>.name);
 * 3) in the test do:
 *      expect(renderCounter.get(<your react functional component>.name)).toBe(1);
 *
 */

class RenderCounter {
  private numRenders: Map<string, number>;

  constructor() {
    this.numRenders = new Map<string, number>();
  }

  reset(): void {
    this.numRenders.forEach((_value: number, key: string): void => {
      this.numRenders.set(key, 0);
    });
  }

  count(id: string): void {
    this.numRenders.set(id, this.get(id) + 1);

    // Enable next line (for debugging) to track the number of renders per component in the developer console
    // console.log('numRenders', id, this.get(id));
  }

  get(id: string): number {
    return this.numRenders.get(id) || 0;
  }
}

export const renderCounter = new RenderCounter();

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { MenuItem, FormControl } from '@mui/material';

import { TooltipSelect } from '@opengeoweb/shared';
import { layerTypes } from '@opengeoweb/store';
import { useCoreTranslation } from '../../../../utils/i18n';

interface BaseLayersProps {
  selectedBaseLayers: layerTypes.Layer[];
  availableBaseLayers: layerTypes.Layer[];
  onChangeBaseLayers: (name: string) => void;
  icon?: React.ElementType;
  tooltipPrefix?: string;
}

const BaseLayers: React.FC<BaseLayersProps> = ({
  selectedBaseLayers,
  availableBaseLayers,
  onChangeBaseLayers,
  icon,
  tooltipPrefix,
}: BaseLayersProps) => {
  const { t } = useCoreTranslation();
  if (!availableBaseLayers || !availableBaseLayers.length) {
    return <div>{t('layermanager-baselayers-no-available')}</div>;
  }

  const nonEmptyTooltipPrefix = tooltipPrefix ?? `${t('layermanager-layer')}: `;

  const selectedBaseLayerId =
    typeof selectedBaseLayers[0] !== 'undefined'
      ? selectedBaseLayers[0].id
      : '';

  const list = availableBaseLayers.map((layer) => ({
    value: layer.id!,
  }));

  const currentIndex = availableBaseLayers.findIndex(
    (layer) => selectedBaseLayerId === layer.id,
  );

  return (
    <FormControl style={{ width: '100%' }}>
      <TooltipSelect
        disableUnderline
        tooltip={`${nonEmptyTooltipPrefix}${availableBaseLayers[currentIndex]?.name}`}
        inputProps={{
          SelectDisplayProps: {
            'data-testid': 'selectBaseLayer',
          },
        }}
        style={{ maxWidth: '100%' }}
        isEnabled={true}
        value={selectedBaseLayerId}
        list={list}
        currentIndex={currentIndex}
        onChange={(event): void => {
          onChangeBaseLayers(event.target.value as string);
        }}
        onChangeMouseWheel={(e): void => onChangeBaseLayers(e.value)}
        IconComponent={icon}
      >
        <MenuItem disabled>{t('layermanager-baselayers-title')}</MenuItem>
        {availableBaseLayers.map((baseLayer: layerTypes.Layer) => (
          <MenuItem key={baseLayer.id} value={baseLayer.id}>
            {baseLayer.name}
          </MenuItem>
        ))}
      </TooltipSelect>
    </FormControl>
  );
};

export default BaseLayers;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ToggleMenu, dateUtils } from '@opengeoweb/shared';
import { VariableSizeList as List } from 'react-window';
import {
  Stack,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
  Box,
  Grid2 as Grid,
  styled,
} from '@mui/material';
import React, { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Delete, Edit, ExitDomain, Share } from '@opengeoweb/theme';
import { DrawingListItem } from '../../store/warningsDrawings/types';
import { DATE_FORMAT_HEADER } from '../../utils/constants';
import { useWarningsTranslation } from '../../utils/i18n';

const DEFAULT_ADD_BUTTON_HEIGHT = 80;
const OBJECT_ITEM_SIZE = 50;
const OBJECT_ITEM_SIZE_SMALL_WIDTH = 72;
const HEADER_ITEM_SIZE = 28;
const BREAKPOINT = 320;

export const BUTTON_AIRMET = 'AIRMET';
export const BUTTON_SIGMET = 'SIGMET';

type ListInclHeadersType = { isObject: boolean; index: number }[];

export const getListInclHeaders = (
  objects: DrawingListItem[],
): { listInclHeaders: ListInclHeadersType; headerList: string[] } => {
  const listInclHeaders: ListInclHeadersType = [];
  const headerList: string[] = [];
  // Construct list incl day headers
  objects.forEach((object, index) => {
    const header = dateUtils.dateToString(
      dateUtils.utc(object.lastUpdatedTime),
      DATE_FORMAT_HEADER,
    )!;
    const isNewDay = !headerList.find((item) => item === header);
    if (isNewDay) {
      const newLength = headerList.push(header);
      listInclHeaders.push({ isObject: false, index: newLength - 1 });
    }
    listInclHeaders.push({ isObject: true, index });
  });

  return { listInclHeaders, headerList };
};

const ContainerDiv = styled('div')(() => ({
  '.objectActions': {
    width: '100%',
    minWidth: '100%',
  },
  '& .MuiListItemSecondaryAction-root': {
    width: '24px',
    right: '8px',
  },
  '& .MuiPaper-root': {
    height: '100%',
  },
  '& .MuiListItemButton-root': {
    height: '100%',
  },
  '& .MuiStack-root': {
    paddingRight: '32px',
    width: '100%',
    overflowWrap: 'anywhere',
    overflow: 'hidden',
  },
  [`@container objectList (min-width: ${BREAKPOINT}px)`]: {
    '.objectActions': {
      width: '50%',
      minWidth: '50%',
    },
    '& .MuiListItemSecondaryAction-root': {
      width: '56px',
    },
    '& .MuiStack-root': {
      paddingRight: '64px',
    },
  },
}));

export const Objects: FC<{
  objects: DrawingListItem[];
  onClickObject: (object: DrawingListItem) => void;
  onClickEdit: (objectId: string) => void;
  onClickDelete: (drawingId: string) => void;
  onClickShare: (object: DrawingListItem) => void;
  activeObject: string;
  isLoading?: boolean;
}> = ({
  objects,
  onClickObject,
  onClickEdit,
  onClickDelete,
  onClickShare,
  activeObject,
  isLoading = false,
}) => {
  const { t } = useWarningsTranslation();
  const listRef = React.useRef<List<DrawingListItem>>(null);
  const { listInclHeaders, headerList } = getListInclHeaders(objects || []);

  const getItemSize = (width: number, index: number): number => {
    const item = listInclHeaders[index];
    if (item.isObject) {
      return width < BREAKPOINT
        ? OBJECT_ITEM_SIZE_SMALL_WIDTH
        : OBJECT_ITEM_SIZE;
    }
    return HEADER_ITEM_SIZE;
  };

  React.useEffect(() => {
    if (listRef.current !== null) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [objects]);

  return (
    <AutoSizer
      onResize={(): void => {
        if (listRef.current !== null) {
          listRef.current.resetAfterIndex(0, true);
        }
      }}
    >
      {({ height = 0, width = 0 }): React.ReactNode => (
        <Box sx={{ width }}>
          {!isLoading && objects.length === 0 ? (
            <Typography
              sx={{ paddingLeft: '16px', paddingTop: '8px' }}
              variant="body1"
            >
              {t('object-manager-no-objects')}
            </Typography>
          ) : (
            <List
              height={height - DEFAULT_ADD_BUTTON_HEIGHT}
              width={width}
              itemCount={listInclHeaders.length}
              itemSize={(index): number => getItemSize(width, index)}
              style={{
                containerType: 'inline-size',
                containerName: 'objectList',
              }}
              ref={listRef}
            >
              {({ index, style }): React.ReactElement => {
                const listItem = listInclHeaders[index];
                if (!listItem.isObject) {
                  const header = headerList[listItem.index];
                  return (
                    <Typography
                      style={style}
                      key={listItem.index}
                      sx={{
                        paddingLeft: 2.5,
                        paddingBottom: 0.5,
                        paddingTop: 1,
                        opacity: 0.67,
                        lineHeight: '16px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      variant="caption"
                    >
                      {header}
                    </Typography>
                  );
                }

                const object = objects[listItem.index];

                return (
                  <ContainerDiv>
                    <ListItem
                      style={style}
                      disablePadding
                      key={object.id}
                      secondaryAction={
                        <Grid container rowSpacing={1}>
                          <Grid className="objectActions">
                            <ToggleMenu
                              buttonSx={{
                                // position the share button for scope global
                                marginBottom: object.scope === 'user' ? 0 : 4,
                                [`@container objectList (min-width: ${BREAKPOINT}px)`]:
                                  {
                                    marginBottom: 0,
                                    marginRight:
                                      object.scope === 'user' ? 1 : 4,
                                  },
                              }}
                              buttonIcon={<Share />}
                              menuTitle={t('object-manager-share-menu')}
                              tooltipTitle={t('object-manager-button-share')}
                              menuPosition="bottom"
                              aria-label={t('object-manager-button-share')}
                              menuItems={[
                                {
                                  text: BUTTON_AIRMET,
                                  action: (): void => {},
                                  icon: <ExitDomain />,
                                  isDisabled: true,
                                },
                                {
                                  text: BUTTON_SIGMET,
                                  action: (): void => {},
                                  icon: <ExitDomain />,
                                  isDisabled: true,
                                },
                                {
                                  text: t('public-warning-title').toUpperCase(),
                                  action: (): void => {
                                    onClickShare(object);
                                  },
                                  icon: <ExitDomain />,
                                },
                              ]}
                            />
                          </Grid>
                          {object.scope === 'user' && (
                            <Grid className="objectActions">
                              <ToggleMenu
                                menuTitle={t('object-manager-options-menu')}
                                tooltipTitle={t('object-manager-options-menu')}
                                menuPosition="bottom"
                                aria-label="Options"
                                menuItems={[
                                  {
                                    text: t('object-manager-button-edit'),
                                    action: (): void => {
                                      onClickEdit(object.id);
                                    },
                                    icon: <Edit />,
                                  },
                                  {
                                    text: t('object-manager-button-delete'),
                                    action: (): void => {
                                      onClickDelete(object.id);
                                    },
                                    icon: <Delete />,
                                  },
                                ]}
                              />
                            </Grid>
                          )}
                        </Grid>
                      }
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          width: '100%',
                          margin: 0.25,
                          backgroundColor: 'geowebColors.background.surface',
                          border: 'solid 1px',
                          borderColor: 'geowebColors.cards.cardContainerBorder',
                        }}
                      >
                        <ListItemButton
                          onClick={(): void => {
                            onClickObject(object);
                          }}
                          selected={activeObject === object.id}
                          sx={{ padding: 1, height: '44px' }}
                        >
                          <Stack>
                            <Typography
                              variant="caption"
                              sx={{
                                lineHeight: '16px',
                                maxHeight: '32px',
                                overflow: 'hidden',
                              }}
                            >
                              {object.objectName}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                opacity: 0.67,
                                lineHeight: '16px',
                                maxHeight: '32px',
                                overflow: 'hidden',
                              }}
                            >
                              {object.scope === 'global' && 'Global: '}
                              {`${dateUtils.dateToString(
                                dateUtils.utc(object.lastUpdatedTime),
                                dateUtils.DATE_FORMAT_DATEPICKER,
                              )} UTC`}
                            </Typography>
                          </Stack>
                        </ListItemButton>
                      </Paper>
                    </ListItem>
                  </ContainerDiv>
                );
              }}
            </List>
          )}
        </Box>
      )}
    </AutoSizer>
  );
};

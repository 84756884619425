/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

// TODO: fix these https://gitlab.com/opengeoweb/opengeoweb/-/issues/626
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import { cloneDeep } from 'lodash';
import {
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  GeometryObject,
  Position,
} from 'geojson';
import { getLatLongFromPixelCoord, webmapUtils } from '@opengeoweb/webmap';
import {
  checkHoverFeatures,
  CheckHoverFeaturesResult,
  convertGeoCoordsToScreenCoords,
  distance,
  findClosestCoords,
  getDrawFunctionFromStore,
  getPixelCoordFromGeoCoord,
  isBetween,
  NEW_FEATURE_CREATED,
  NEW_LINESTRING_CREATED,
  NEW_POINT_CREATED,
} from './mapDrawUtils';

import {
  emptyGeoJSON,
  featurePoint,
  featureMultiPoint,
  featurePolygon,
  featureBox,
  lineString,
  GeoFeatureStyle,
  Coordinate,
  defaultGeoJSONStyleProperties,
} from './geojsonShapes';

const colors = {
  main: '#ff7800',
  vertice: '#000',
};

interface DrawStyle {
  x: number;
  y: number;
  nr: number;
}

interface InputEvent {
  leftButton: boolean;
  mouseDown: boolean;
  mouseX: number;
  mouseY: number;
  rightButton: boolean;
}

export interface FeatureEvent {
  coordinateIndexInFeature: number;
  featureIndex: number;
  screenCoords: { x: number; y: number };
  mouseX: number;
  mouseY: number;
  isInEditMode: boolean;
  feature: GeoJSON.Feature;
}

export interface MapDrawProps {
  mapId: string;
  geojson: GeoJSON.FeatureCollection;
  linkedFeatures?: GeoJSON.FeatureCollection;
  drawMode: string;
  deletePolygonCallback?: string;
  exitDrawModeCallback: (
    reason: DrawModeExitCallback,
    newGeoJSON?: GeoJSON.FeatureCollection,
  ) => void;
  isInEditMode: boolean;
  isInDeleteMode: boolean;
  selectedFeatureIndex: number;
  onHoverFeature?: (event: FeatureEvent) => void;
  onClickFeature?: (event: FeatureEvent) => void;
  updateGeojson: (geoJson: GeometryObject, text: string) => void;
}

export enum EDITMODE {
  EMPTY = 'EMPTY',
  DELETE_FEATURES = 'DELETE_FEATURES',
  ADD_FEATURE = 'ADD_FEATURE',
}
export enum DRAWMODE {
  POLYGON = 'POLYGON',
  BOX = 'BOX',
  MULTIPOINT = 'MULTIPOINT',
  POINT = 'POINT',
  LINESTRING = 'LINESTRING',
}
enum VERTEX {
  NONE = 'NONE',
  MIDDLE_POINT_OF_FEATURE = 'MIDDLE_POINT_OF_FEATURE',
}
enum EDGE {
  NONE = 'NONE',
}
enum SNAPPEDFEATURE {
  NONE = 'NONE',
}
enum DRAGMODE {
  NONE = 'NONE',
  VERTEX = 'VERTEX',
  FEATURE = 'FEATURE',
}

/**
 * Initializes a new feature for given type
 * @param type
 * @returns
 */
const getNewFeature = (type: string): GeoJSON.Feature | undefined => {
  switch (type) {
    case DRAWMODE.POINT:
      return cloneDeep(featurePoint);
    case DRAWMODE.MULTIPOINT:
      return cloneDeep(featureMultiPoint);
    case DRAWMODE.POLYGON:
      return cloneDeep(featurePolygon);
    case DRAWMODE.BOX:
      return cloneDeep(featureBox);
    case DRAWMODE.LINESTRING:
      return cloneDeep(lineString);
    default:
  }
  return undefined;
};

export type DrawModeExitCallback = 'escaped' | 'doubleClicked';

export default class MapDraw extends React.PureComponent<MapDrawProps> {
  myEditMode: EDITMODE;
  myDrawMode: DRAWMODE;
  textPositions: { x: number; y: number; text: string }[];
  mouseOverPolygonCoordinates: Coordinate[] | number;
  defaultPolyProps: GeoFeatureStyle;
  defaultLineStringProps: GeoFeatureStyle;
  defaultIconProps: {
    imageWidth: number;
    imageHeight: number;
  };
  somethingWasDragged: string;
  mouseIsOverVertexNr: SNAPPEDFEATURE | VERTEX | number;
  selectedEdge: EDGE;
  geojson: FeatureCollection;
  listenersInitialized!: boolean;
  mouseGeoCoord!: Coordinate;
  snappedGeoCoords!: Coordinate;
  doubleClickTimer!: {
    isRunning?: boolean;
    timer?: number;
    mouseX?: number;
    mouseY?: number;
  };
  mouseStoppedTimer!: number;
  snappedPolygonIndex!: SNAPPEDFEATURE | number;
  mouseX!: number;
  mouseY!: number;
  mouseOverPolygonFeatureIndex!: number;
  drawMode!: string;
  disabled!: boolean;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    isInEditMode: false,
    isInDeleteMode: false,
    webmapjs: undefined,
    selectedFeatureIndex: 0,
  };
  featureEvent!: FeatureEvent;

  constructor(props: MapDrawProps) {
    super(props);
    this.myEditMode = EDITMODE.EMPTY;
    this.myDrawMode = DRAWMODE.POLYGON;
    this.beforeDraw = this.beforeDraw.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.deleteFeature = this.deleteFeature.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.checkDist = this.checkDist.bind(this);
    this.hoverEdge = this.hoverEdge.bind(this);
    this.drawPolygon = this.drawPolygon.bind(this);
    this.moveVertex = this.moveVertex.bind(this);
    this.featureHasChanged = this.featureHasChanged.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.hoverVertex = this.hoverVertex.bind(this);
    this.transposePolygon = this.transposePolygon.bind(this);
    this.transposeVertex = this.transposeVertex.bind(this);
    this.triggerMouseDownTimer = this.triggerMouseDownTimer.bind(this);
    this.mouseDoubleClick = this.mouseDoubleClick.bind(this);
    this.handleDrawMode = this.handleDrawMode.bind(this);
    this.componentCleanup = this.componentCleanup.bind(this);
    this.initializeFeatureCoordinates =
      this.initializeFeatureCoordinates.bind(this);
    this.validatePolys = this.validatePolys.bind(this);
    this.insertVertexInEdge = this.insertVertexInEdge.bind(this);
    this.createNewFeature = this.createNewFeature.bind(this);
    this.addPointToMultiPointFeature =
      this.addPointToMultiPointFeature.bind(this);
    this.addVerticesToPolygonFeature =
      this.addVerticesToPolygonFeature.bind(this);
    this.checkIfFeatureIsBox = this.checkIfFeatureIsBox.bind(this);
    this.handleGeoJSONUpdate = this.handleGeoJSONUpdate.bind(this);
    this.handleNewFeatureIndex = this.handleNewFeatureIndex.bind(this);
    this.getSelectedFeature = this.getSelectedFeature.bind(this);

    this.handleDrawMode(props.drawMode);

    this.textPositions = [];
    this.mouseOverPolygonCoordinates = [];

    this.defaultPolyProps = {
      stroke: '#000',
      'stroke-width': 0.4,
      'stroke-opacity': 1,
      fill: '#33cc00',
      'fill-opacity': 1,
    };

    this.defaultLineStringProps = {
      stroke: '#000',
      'stroke-width': 1.5,
      'stroke-opacity': 1,
      fill: '#33cc00',
      'fill-opacity': 1,
    };

    this.defaultIconProps = {
      imageWidth: 30,
      imageHeight: 30,
    };

    this.somethingWasDragged = DRAGMODE.NONE;
    this.mouseIsOverVertexNr = VERTEX.NONE;
    this.selectedEdge = EDGE.NONE;

    if (props.geojson) {
      this.geojson = cloneDeep(props.geojson);
    } else {
      this.geojson = cloneDeep(emptyGeoJSON);
      this.featureHasChanged('new');
    }
    this.validatePolys(true);
  }

  componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyDown);
    const { mapId, isInDeleteMode } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (this.disabled === undefined) {
      this.disabled = isInDeleteMode;
    }

    if (webmapjs !== undefined && this.listenersInitialized !== true) {
      this.listenersInitialized = true;
      webmapjs.addListener('beforecanvasdisplay', this.beforeDraw, true);
      webmapjs.addListener('beforemousemove', this.mouseMove, true);
      webmapjs.addListener('beforemousedown', this.mouseDown, true);
      webmapjs.addListener('beforemouseup', this.mouseUp, true);
      this.disabled = false;
    }

    if (webmapjs !== undefined) {
      webmapjs.draw();
    }
  }

  componentDidUpdate(prevProps: MapDrawProps): void {
    const {
      geojson,
      isInEditMode,
      isInDeleteMode,
      drawMode,
      mapId,
      selectedFeatureIndex,
    } = this.props;
    const prevGeojson = prevProps && prevProps.geojson;
    const prevIsInEditMode = prevProps && prevProps.isInEditMode;
    const prevIsInDeleteMode = prevProps && prevProps.isInDeleteMode;
    const prevDrawMode = prevProps && prevProps.drawMode;
    const prevSelectedFeatureIndex =
      prevProps && prevProps.selectedFeatureIndex;
    /* Handle geojson update */
    if (geojson !== prevGeojson) {
      this.handleGeoJSONUpdate(geojson);
    }
    /* Handle toggle edit */
    if (isInEditMode !== prevIsInEditMode) {
      if (isInEditMode === false && this.myEditMode !== EDITMODE.EMPTY) {
        this.cancelEdit(true); /* Throw away last vertice */
        if (this.myEditMode === EDITMODE.DELETE_FEATURES) {
          this.myEditMode = EDITMODE.EMPTY;
          return;
        }
      }

      webmapUtils
        .getWMJSMapById(mapId)
        ?.draw('MapDraw::componentDidUpdateIsInEditMode');
    }

    /* Handle toggle delete */
    if (isInDeleteMode !== prevIsInDeleteMode) {
      if (isInDeleteMode === true) {
        this.myEditMode = EDITMODE.DELETE_FEATURES;
      } else if (this.myEditMode === EDITMODE.DELETE_FEATURES) {
        this.myEditMode = EDITMODE.EMPTY;
      }
    }

    if (
      isInEditMode !== prevIsInEditMode ||
      isInDeleteMode !== prevIsInDeleteMode
    ) {
      if (isInEditMode === false && isInDeleteMode === false) {
        this.myEditMode = EDITMODE.EMPTY;
      }
    }

    if (selectedFeatureIndex !== prevSelectedFeatureIndex) {
      this.handleNewFeatureIndex();
    }

    /* Handle new drawmode */
    if (drawMode !== prevDrawMode) {
      /* Handle drawmode */
      this.handleDrawMode(drawMode);
    }
  }
  componentWillUnmount(): void {
    this.componentCleanup();
  }

  handleKeyDown(event: KeyboardEvent): void {
    const { isInEditMode } = this.props;

    if (event.key === 'Escape') {
      this.handleExitDrawMode('escaped');
    }
    if (isInEditMode && (event.key === 'Delete' || event.key === 'Backspace')) {
      this.deleteFeature();
    }
  }

  handleNewFeatureIndex(): void {
    // Another feature was selected. The mouseIsOverVertexNr should be updated
    const { mapId } = this.props;
    const feature = this.getSelectedFeature();
    if (feature && feature.geometry.type !== 'GeometryCollection') {
      const featureCoords = feature.geometry.coordinates;
      this.mouseIsOverVertexNr = featureCoords.length - 1;
    }

    webmapUtils
      .getWMJSMapById(mapId)
      ?.draw('MapDraw::componentDidUpdateSelectedFeatureIndex');
  }

  /* Converts string input into right drawmode */
  handleDrawMode(_drawMode: string): void {
    if (_drawMode) {
      const drawMode = _drawMode.toUpperCase();
      if (drawMode === 'POINT') {
        this.myDrawMode = DRAWMODE.POINT;
      }
      if (drawMode === 'MULTIPOINT') {
        this.myDrawMode = DRAWMODE.MULTIPOINT;
      }
      if (drawMode === 'BOX') {
        this.myDrawMode = DRAWMODE.BOX;
      }
      if (drawMode === 'POLYGON') {
        this.myDrawMode = DRAWMODE.POLYGON;
      }
      if (drawMode === 'LINESTRING') {
        this.myDrawMode = DRAWMODE.LINESTRING;
      }
    }
  }

  handleGeoJSONUpdate = (newGeoJSON: GeoJSON.FeatureCollection): void => {
    const { selectedFeatureIndex, mapId } = this.props;
    this.geojson = cloneDeep(newGeoJSON);
    /* Ensure that this.snappedPolygonIndex is still within the coordinates array */
    if (
      this.geojson?.features &&
      selectedFeatureIndex < this.geojson.features.length
    ) {
      const feature = this.getSelectedFeature();
      if (feature.geometry?.type === 'Polygon') {
        const { coordinates } = feature.geometry;
        if (Number(this.snappedPolygonIndex) > coordinates.length - 1) {
          this.snappedPolygonIndex = coordinates.length - 1;
        }
      } else {
        this.snappedPolygonIndex = SNAPPEDFEATURE.NONE;
      }
    }
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    webmapjs?.draw('MapDraw::handleGeoJSONUpdate');
  };

  handleExitDrawMode = (reason: DrawModeExitCallback = 'escaped'): void => {
    const { exitDrawModeCallback } = this.props;
    // remove last vertice when pressing ESC while drawing a polygon
    if (
      this.myDrawMode === DRAWMODE.POLYGON &&
      this.myEditMode === EDITMODE.ADD_FEATURE
    ) {
      const currentFeature = this.getSelectedFeature();
      const currentGeometry = currentFeature.geometry;
      if (currentGeometry.type === 'Polygon') {
        const coordinates = currentGeometry.coordinates[0];
        const firstElement = coordinates[0];
        coordinates[this.mouseIsOverVertexNr as number] =
          cloneDeep(firstElement);
      }
    }
    if (
      exitDrawModeCallback &&
      (this.myEditMode === EDITMODE.EMPTY ||
        this.myEditMode === EDITMODE.DELETE_FEATURES)
    ) {
      exitDrawModeCallback(reason, this.geojson);
    }
    this.cancelEdit(this.myDrawMode !== DRAWMODE.BOX);
    if (this.myDrawMode === DRAWMODE.POLYGON && exitDrawModeCallback) {
      exitDrawModeCallback(reason, this.geojson);
    }
    if (this.myDrawMode === DRAWMODE.LINESTRING && exitDrawModeCallback) {
      exitDrawModeCallback(reason, this.geojson);
    }
  };

  getSelectedFeature = (): GeoJSON.Feature => {
    const { selectedFeatureIndex } = this.props;
    return this.geojson.features[selectedFeatureIndex];
  };

  hoverVertex(feature: GeoJSON.Feature, mouseX: number, mouseY: number): void {
    const { mapId } = this.props;
    let foundVertex = VERTEX.NONE;
    this.mouseIsOverVertexNr = VERTEX.NONE;

    if (feature.geometry.type === 'Point') {
      this.snappedPolygonIndex = SNAPPEDFEATURE.NONE;
      const featureCoords = feature.geometry.coordinates;
      /* Get all vertexes */
      const XYCoords = convertGeoCoordsToScreenCoords([featureCoords], mapId);
      if (
        XYCoords.length > 0 &&
        this.checkDist(XYCoords[0], 0, mouseX, mouseY)
      ) {
        this.mouseIsOverVertexNr = 0;
        return;
      }
    }

    if (feature.geometry.type === 'MultiPoint') {
      this.snappedPolygonIndex = SNAPPEDFEATURE.NONE;
      for (
        let polygonIndex = feature.geometry.coordinates.length - 1;
        polygonIndex >= 0;
        polygonIndex -= 1
      ) {
        const featureCoords = feature.geometry.coordinates[polygonIndex];
        if (featureCoords === undefined) {
          // eslint-disable-next-line no-continue
          continue;
        }
        /* Get all vertexes */
        const XYCoords = convertGeoCoordsToScreenCoords([featureCoords], mapId);
        if (
          XYCoords.length > 0 &&
          this.checkDist(XYCoords[0], polygonIndex, mouseX, mouseY)
        ) {
          this.mouseIsOverVertexNr = polygonIndex;
          return;
        }
      }
    }

    if (feature.geometry.type === 'Polygon') {
      for (
        let polygonIndex = feature.geometry.coordinates.length - 1;
        polygonIndex >= 0;
        polygonIndex -= 1
      ) {
        const featureCoords = feature.geometry.coordinates[polygonIndex];
        if (featureCoords === undefined) {
          // eslint-disable-next-line no-continue
          continue;
        }
        /* Get all vertexes */
        const XYCoords = convertGeoCoordsToScreenCoords(featureCoords, mapId);
        const middle = { x: 0, y: 0 };
        /* Snap to the vertex closer than specified pixels */
        for (let j = 0; j < XYCoords.length - 1; j += 1) {
          const coord = XYCoords[j];
          middle.x += coord.x;
          middle.y += coord.y;

          if (this.checkDist(coord, polygonIndex, mouseX, mouseY)) {
            foundVertex = j as unknown as VERTEX;
            break;
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        middle.x = parseInt(middle.x / (XYCoords.length - 1), 10);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        middle.y = parseInt(middle.y / (XYCoords.length - 1), 10);
        /* Check if the mouse hovers the middle vertex */
        if (
          foundVertex === VERTEX.NONE &&
          this.checkDist(middle, polygonIndex, mouseX, mouseY)
        ) {
          foundVertex = VERTEX.MIDDLE_POINT_OF_FEATURE;
        }

        this.mouseIsOverVertexNr = foundVertex;
      }
    }

    if (feature.geometry.type === 'LineString') {
      const lineStringIndex = 0;
      const featureCoords = feature.geometry.coordinates;
      /* Get all vertexes */
      const XYCoords = convertGeoCoordsToScreenCoords(featureCoords, mapId);
      /* Snap to the vertex closer than specified pixels */
      for (let j = 0; j < XYCoords.length; j += 1) {
        const coord = XYCoords[j];
        // if (!coord || coord.length < 2) continue;
        if (this.checkDist(coord, lineStringIndex, mouseX, mouseY)) {
          foundVertex = j as unknown as VERTEX;
          break;
        }
      }
      this.mouseIsOverVertexNr = foundVertex;
    }
  }

  mouseMove(event: InputEvent): boolean {
    const { isInEditMode, onHoverFeature, mapId } = this.props;
    if (event?.rightButton === true) {
      return undefined!;
    }
    /* mouseMove is an event callback function which is triggered when the mouse moves over the map
      This event is only triggered if the map is in hover state.
      E.g. when the map is dragging/panning, this event is not triggerd
    */
    const { mouseX, mouseY, mouseDown } = event;

    this.mouseX = mouseX;
    this.mouseY = mouseY;

    if (onHoverFeature) {
      const handleMouseStoppedTimer = (): void => {
        const result: CheckHoverFeaturesResult = checkHoverFeatures(
          this.geojson,
          this.mouseX,
          this.mouseY,
          (coordinates) => {
            return convertGeoCoordsToScreenCoords(coordinates, mapId);
          },
        );
        if (result) {
          this.featureEvent = {
            coordinateIndexInFeature: result.coordinateIndexInFeature,
            featureIndex: result.featureIndex,
            mouseX,
            mouseY,
            isInEditMode,
            feature: result.feature,
            screenCoords: result.screenCoords,
          } as FeatureEvent;
          onHoverFeature(this.featureEvent);
        } else if (this.featureEvent) {
          this.featureEvent = undefined!;
          onHoverFeature(undefined!);
        }
        const webmapjs = webmapUtils.getWMJSMapById(mapId);
        webmapjs && webmapjs.draw('MapDraw::onHoverFeature');
      };
      window.clearTimeout(this.mouseStoppedTimer);
      this.mouseStoppedTimer = window.setTimeout(handleMouseStoppedTimer, 20);
    }

    if (isInEditMode === false) {
      return undefined!;
    }

    const feature = this.getSelectedFeature();
    if (!feature) {
      return undefined!;
    }

    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (!webmapjs) {
      return undefined!;
    }

    this.mouseGeoCoord = getLatLongFromPixelCoord(webmapjs, {
      x: mouseX,
      y: mouseY,
    });

    /* The mouse is hovering a vertice, and the mousedown is into effect, move vertice accordingly */
    const ret = this.moveVertex(feature, mouseDown);
    if (ret === false) {
      webmapjs.draw('MapDraw::mouseMove');
      // eslint-disable-next-line consistent-return
      return false;
    }

    /* Check if the mouse hovers any vertice of any polygon */
    this.hoverVertex(feature, mouseX, mouseY);
    if (this.mouseIsOverVertexNr !== VERTEX.NONE) {
      /* We found a vertex */
      this.selectedEdge =
        EDGE.NONE; /* We found a vertex, not an edge: reset it */
      webmapjs.setCursor('move');
      webmapjs.draw('MapDraw::hoverVertex');
      // eslint-disable-next-line consistent-return
      return false;
    }

    webmapjs.setCursor();

    /* Check if the mouse hovers an edge of a polygon */
    this.selectedEdge = EDGE.NONE;
    if (
      this.myEditMode !== EDITMODE.DELETE_FEATURES &&
      !this.checkIfFeatureIsBox(feature)
    ) {
      const retObj = this.hoverEdge(feature.geometry, mouseX, mouseY);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.selectedEdge = retObj.selectedEdge;
      this.snappedPolygonIndex = retObj.snappedPolygonIndex;
    }

    if (this.selectedEdge !== EDGE.NONE) {
      webmapjs.draw('MapDraw::mouseMove');
      // eslint-disable-next-line consistent-return
      return false;
    }

    if (this.myEditMode === EDITMODE.ADD_FEATURE) {
      // eslint-disable-next-line consistent-return
      return false;
    }

    /* We did not find anything under the mousecursor,
      return true means that the map will continue with its own mousehandling
    */
    if (
      this.mouseIsOverVertexNr === VERTEX.NONE &&
      this.selectedEdge === EDGE.NONE
    ) {
      webmapjs.draw('MapDraw::mouseMove');
      // eslint-disable-next-line consistent-return
      return true; /* False means that this component will take over entire controll.
                     True means that it is still possible to pan and drag the map while editing */
    }

    return undefined!;
  }

  /* Returns true if double click is detected */
  triggerMouseDownTimer(event: InputEvent): void | boolean {
    if (!this.doubleClickTimer) {
      this.doubleClickTimer = {};
    }
    const { mouseX, mouseY } = event;

    const checkTimeOut = (): void => {
      this.doubleClickTimer.isRunning = false;
    };

    /* Reset the timer */
    if (this.doubleClickTimer.timer) {
      clearTimeout(this.doubleClickTimer.timer);
      this.doubleClickTimer.timer = null!;
    }

    /* Check if double click on this location occured */
    if (this.doubleClickTimer.isRunning === true) {
      if (
        mouseX === this.doubleClickTimer.mouseX &&
        mouseY === this.doubleClickTimer.mouseY
      ) {
        this.doubleClickTimer.isRunning = false;
        return true;
      }
    }

    /* Create new timer */
    this.doubleClickTimer.isRunning = true;
    this.doubleClickTimer.mouseX = mouseX;
    this.doubleClickTimer.mouseY = mouseY;
    this.doubleClickTimer.timer = window.setTimeout(checkTimeOut, 300);

    /* No double click detected, return false */
    return false;
  }

  mouseDoubleClick(): void {
    this.handleExitDrawMode('doubleClicked');
  }

  /* Insert a new vertex into an edge, e.g a line is clicked and a point is added */
  insertVertexInEdge(event: InputEvent): boolean {
    const { mouseX, mouseY } = event;
    const { mapId } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (!webmapjs) {
      return false;
    }
    if (this.myDrawMode === DRAWMODE.POLYGON) {
      if (
        this.selectedEdge !== EDGE.NONE &&
        this.myEditMode !== EDITMODE.DELETE_FEATURES
      ) {
        this.mouseGeoCoord = getLatLongFromPixelCoord(webmapjs, {
          x: mouseX,
          y: mouseY,
        });
        const feature = this.getSelectedFeature();
        if (this.checkIfFeatureIsBox(feature)) {
          return false;
        }
        const featureCoords = (feature.geometry as GeoJSON.Polygon).coordinates[
          this.snappedPolygonIndex as number
        ];
        if (featureCoords === undefined) {
          return false;
        }
        featureCoords.splice(this.selectedEdge + 1, 0, [
          this.mouseGeoCoord.x,
          this.mouseGeoCoord.y,
        ]);
        this.featureHasChanged('insert vertex into line');
        this.mouseMove(event);
        return false;
      }
    }
    if (this.myDrawMode === DRAWMODE.LINESTRING) {
      if (
        this.selectedEdge !== EDGE.NONE &&
        this.myEditMode !== EDITMODE.DELETE_FEATURES
      ) {
        this.mouseGeoCoord = getLatLongFromPixelCoord(webmapjs, {
          x: mouseX,
          y: mouseY,
        });
        const feature = this.getSelectedFeature();
        if (this.checkIfFeatureIsBox(feature)) {
          return false;
        }
        const featureCoords = (feature.geometry as GeoJSON.LineString)
          .coordinates;
        if (featureCoords === undefined) {
          return false;
        }
        featureCoords.splice(this.selectedEdge + 1, 0, [
          this.mouseGeoCoord.x,
          this.mouseGeoCoord.y,
        ]);
        this.featureHasChanged('insert vertex into line');
        this.mouseMove(event);
        return false;
      }
    }
    return true;
  }

  /**
   * This creates a new feature in the geojson features array at the selectedFeatureIndex location
   * @param event
   * @returns if a new feature is made
   */
  createNewFeature(event: InputEvent): boolean {
    const { mouseX, mouseY } = event;
    const { mapId, selectedFeatureIndex } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (!webmapjs) {
      return false;
    }
    if (this.myEditMode === EDITMODE.EMPTY) {
      this.myEditMode = EDITMODE.ADD_FEATURE;

      if (!this.geojson.features[selectedFeatureIndex]) {
        this.geojson.features[selectedFeatureIndex] = getNewFeature(
          this.myDrawMode,
        )!;
      }
      const feature = this.getSelectedFeature();

      this.initializeFeatureCoordinates(feature, this.myDrawMode);

      this.mouseGeoCoord = getLatLongFromPixelCoord(webmapjs, {
        x: mouseX,
        y: mouseY,
      });

      if (
        this.myDrawMode === DRAWMODE.POINT ||
        this.myDrawMode === DRAWMODE.MULTIPOINT
      ) {
        /* Create points */
        const pointGeometry = feature.geometry as GeoJSON.Point;
        if (pointGeometry?.coordinates === undefined) {
          pointGeometry.coordinates = [];
        }
        const multiPointGeometry = feature.geometry as GeoJSON.MultiPoint;
        if (multiPointGeometry?.coordinates === undefined) {
          multiPointGeometry.coordinates = [[]];
        }
        if (this.myDrawMode === DRAWMODE.POINT) {
          const featureCoords = pointGeometry.coordinates;
          featureCoords[0] = this.mouseGeoCoord.x;
          featureCoords[1] = this.mouseGeoCoord.y;
          this.snappedPolygonIndex = pointGeometry.coordinates.length - 1;
          /* For type POINT it is not possible to add multiple points to the same feature */
          this.myEditMode = EDITMODE.EMPTY;
          this.featureHasChanged(NEW_POINT_CREATED);
        }
        if (this.myDrawMode === DRAWMODE.MULTIPOINT) {
          this.myEditMode = EDITMODE.EMPTY;
          let featureCoords = multiPointGeometry.coordinates;
          if (featureCoords === undefined) {
            featureCoords = [];
          }
          if (featureCoords[0] === undefined || featureCoords[0].length === 0) {
            featureCoords[0] = []; /* Used to create the first polygon */
          } else {
            featureCoords.push(
              [],
            ); /* Used to create extra polygons in the same feature */
          }
          // featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
          featureCoords[featureCoords.length - 1] = [
            this.mouseGeoCoord.x,
            this.mouseGeoCoord.y,
          ];
          this.snappedPolygonIndex = multiPointGeometry.coordinates.length - 1;
          this.featureHasChanged('new point in multipoint created');
        }
      }

      if (
        this.myDrawMode === DRAWMODE.POLYGON ||
        this.myDrawMode === DRAWMODE.BOX
      ) {
        /* Create poly's and boxes */
        this.myEditMode = EDITMODE.ADD_FEATURE;
        const polygonGeometry = feature.geometry as GeoJSON.Polygon;

        if (polygonGeometry?.coordinates === undefined) {
          polygonGeometry.coordinates = [[]];
        }
        if (
          polygonGeometry.coordinates[0] === undefined ||
          polygonGeometry.coordinates[0].length === 0
        ) {
          polygonGeometry.coordinates[0] =
            []; /* Used to create the first polygon */
        } else {
          this.myEditMode = EDITMODE.EMPTY;
          return false;
        }
        this.snappedPolygonIndex = polygonGeometry.coordinates.length - 1;
        const featureCoords =
          polygonGeometry.coordinates[this.snappedPolygonIndex];
        featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
        featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);

        /* This is triggered when a bounding box is created. Five points are added at once */
        if (this.myDrawMode === DRAWMODE.BOX) {
          featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
          featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
          featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
        }

        if (this.myDrawMode === DRAWMODE.BOX) {
          this.mouseIsOverVertexNr = 3;
        } else {
          this.mouseIsOverVertexNr = featureCoords.length - 1;
        }

        this.featureHasChanged(NEW_FEATURE_CREATED);
      }

      if (this.myDrawMode === DRAWMODE.LINESTRING) {
        this.myEditMode = EDITMODE.EMPTY;
        /* Create linestring */
        this.myEditMode = EDITMODE.ADD_FEATURE;
        let featureCoords = (feature.geometry as GeoJSON.LineString)
          .coordinates;
        if (featureCoords === undefined || featureCoords.length === 0) {
          featureCoords = [[this.mouseGeoCoord.x, this.mouseGeoCoord.y]];
        }
        if (featureCoords[0].length === 0) {
          featureCoords[0] = [this.mouseGeoCoord.x, this.mouseGeoCoord.y];
        }
        featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
        this.snappedPolygonIndex = 0;
        this.featureHasChanged(NEW_LINESTRING_CREATED);
        this.mouseIsOverVertexNr = featureCoords.length - 1;
      }
      webmapjs.draw('MapDraw::mouseDown');

      return true;
    }
    return false;
  }

  /* This is triggered when new points are added during the addmultipoint mode. One point is added per time */
  addPointToMultiPointFeature(event: InputEvent): boolean {
    const { mouseX, mouseY } = event;
    const { mapId } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (!webmapjs) {
      return false;
    }
    if (this.myDrawMode === DRAWMODE.MULTIPOINT) {
      if (
        this.myEditMode === EDITMODE.ADD_FEATURE &&
        this.snappedPolygonIndex !== SNAPPEDFEATURE.NONE
      ) {
        this.mouseGeoCoord = getLatLongFromPixelCoord(webmapjs, {
          x: mouseX,
          y: mouseY,
        });
        const feature = this.getSelectedFeature();
        if (feature.geometry.type === 'MultiPoint') {
          const featureCoords = feature.geometry.coordinates;
          featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
          this.featureHasChanged('point added to multipoint');
          this.mouseIsOverVertexNr = VERTEX.NONE;
          this.mouseMove(event);
        }

        return false;
      }
    }
    return true;
  }

  addVerticesToPolygonFeature(event: InputEvent): boolean {
    const { mouseX, mouseY } = event;
    const { mapId } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (!webmapjs) {
      return false;
    }

    if (this.myDrawMode === DRAWMODE.POLYGON) {
      if (
        this.myEditMode === EDITMODE.ADD_FEATURE &&
        this.snappedPolygonIndex !== SNAPPEDFEATURE.NONE
      ) {
        this.mouseGeoCoord = getLatLongFromPixelCoord(webmapjs, {
          x: mouseX,
          y: mouseY,
        });
        const feature = this.getSelectedFeature();
        if (feature.geometry.type === 'Polygon') {
          const featureCoords =
            feature.geometry.coordinates[this.snappedPolygonIndex];
          // "featureCoords" is our polygon represented as a list of coordinates. The last element in the list is the ghost node following our cursor.
          // We therefore extract the last two nodes, which will tell us the distance between the last placed node and the ghost node
          const [coordA, coordB] = featureCoords.slice(-2);
          const coordDistance = distance(
            { x: coordA[0], y: coordA[1] },
            { x: coordB[0], y: coordB[1] },
          );
          if (coordDistance > 0.005) {
            featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);

            this.featureHasChanged('vertex added to polygon');
            this.mouseIsOverVertexNr = featureCoords.length - 1;
            this.mouseMove(event);
          }
        }

        return false;
      }
    }

    return undefined!;
  }

  addPointToLineStringFeature(event: InputEvent): boolean {
    const { mouseX, mouseY } = event;
    const { mapId } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (!webmapjs) {
      return false;
    }

    if (this.myDrawMode === DRAWMODE.LINESTRING) {
      if (this.myEditMode === EDITMODE.ADD_FEATURE) {
        this.mouseGeoCoord = getLatLongFromPixelCoord(webmapjs, {
          x: mouseX,
          y: mouseY,
        });
        const feature = this.getSelectedFeature();
        const featureCoords = (feature.geometry as GeoJSON.LineString)
          .coordinates;
        featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
        this.featureHasChanged('vertex added to LineString');
        this.mouseIsOverVertexNr = featureCoords.length - 1;
        this.mouseMove(event);
        return false;
      }
    }
    return true;
  }

  mouseDown(event: InputEvent): void | boolean {
    const { isInEditMode } = this.props;

    if (event?.rightButton === true) {
      return;
    }
    if (isInEditMode === false) {
      return;
    }

    if (this.triggerMouseDownTimer(event)) {
      // eslint-disable-next-line consistent-return
      return this.mouseDoubleClick();
    }

    if (
      this.myEditMode === EDITMODE.ADD_FEATURE &&
      this.myDrawMode === DRAWMODE.BOX
    ) {
      // eslint-disable-next-line consistent-return
      return this.mouseDoubleClick();
    }

    this.somethingWasDragged = DRAGMODE.NONE;

    if (
      this.mouseIsOverVertexNr !== VERTEX.NONE &&
      this.myEditMode === EDITMODE.EMPTY
    ) {
      // eslint-disable-next-line consistent-return
      return false;
    }

    /* Insert a new vertex into an edge, e.g a line is clicked and a point is added */
    // eslint-disable-next-line consistent-return
    if (this.insertVertexInEdge(event) === false) {
      return false;
    }

    /* This checks if a new feature should be created.  */
    if (this.createNewFeature(event)) {
      /* Start with mouse move from this mouse down location,  enables sizing the box while moving the mouse */
      this.mouseMove(event);
      return false;
    }

    /* This is triggered when new points are added during the addmultipoint mode. One point is added per time */
    if (this.addPointToMultiPointFeature(event) === false) {
      return false;
    }

    /* This is triggered when new points are added during the addpolygon mode. One point is added per time */
    if (this.addVerticesToPolygonFeature(event) === false) {
      return false;
    }

    /* This is triggered when new points are added to a linestring */
    if (this.addPointToLineStringFeature(event) === false) {
      return false;
    }

    return false; /* False means that this component will take over entire controll.
                     True means that it is still possible to pan and drag the map while editing */
  }

  deletePolygon(index: number | SNAPPEDFEATURE): void {
    const { deletePolygonCallback } = this.props;
    const feature = this.getSelectedFeature();
    if (feature.geometry.type !== 'GeometryCollection') {
      feature.geometry.coordinates.splice(index as number, 1);
      if (deletePolygonCallback) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        deletePolygonCallback();
      }
    }
  }

  deleteFeature(): void {
    /* Deletes any features under the mousecursor */
    const { mapId, selectedFeatureIndex } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);

    const feature = this.geojson.features[
      selectedFeatureIndex
    ] as GeoJSON.Feature<GeoJSON.Polygon>; // Not actually necessarily a polygon

    const polygonIndex = // This allows us to delete nodes even while not hovering them
      feature?.geometry.coordinates.length === 1 ? 0 : this.snappedPolygonIndex;

    const featureCoords = feature?.geometry.coordinates[polygonIndex as number];
    if (featureCoords === undefined) {
      return;
    }

    if (
      this.mouseIsOverVertexNr !== VERTEX.MIDDLE_POINT_OF_FEATURE &&
      Array.isArray(featureCoords)
    ) {
      /* Remove edge of polygon */
      if (featureCoords.length <= 4) {
        /* Remove the polygon completely if it can not have an area */
        this.deletePolygon(polygonIndex);
      } else {
        const closestCoords = findClosestCoords(
          convertGeoCoordsToScreenCoords(featureCoords, mapId),
          this.mouseX,
          this.mouseY,
        );
        const newLength = featureCoords.length - closestCoords.length;
        /* Remove edge of polygon */
        closestCoords
          .sort()
          .reverse()
          .forEach((index) => {
            featureCoords.splice(index, 1);
          });
        if (closestCoords.length > 1) {
          featureCoords.push(cloneDeep(featureCoords[0]));
        }
        this.mouseIsOverVertexNr = newLength - 1;
      }
    } else {
      /* Remove the polygon completely */
      this.deletePolygon(polygonIndex);
    }
    this.featureHasChanged('deleteFeature');

    webmapjs?.draw('MapDraw::deletefeatures');
  }

  mouseUp(event: InputEvent): void | boolean {
    const { isInEditMode, onClickFeature, mapId } = this.props;
    if (event?.rightButton === true) {
      return;
    }
    if (onClickFeature) {
      const { mouseX, mouseY } = event;
      const ignoreCoordinateIndexInFeature = true;
      const result: CheckHoverFeaturesResult = checkHoverFeatures(
        this.geojson,
        mouseX,
        mouseY,
        (coordinates) => {
          return convertGeoCoordsToScreenCoords(coordinates, mapId);
        },
        ignoreCoordinateIndexInFeature,
      );
      if (result) {
        const featureEvent: FeatureEvent = {
          coordinateIndexInFeature: result.coordinateIndexInFeature,
          featureIndex: result.featureIndex,
          mouseX,
          mouseY,
          isInEditMode,
          feature: result.feature,
          screenCoords: { ...result.screenCoords },
        };
        onClickFeature(featureEvent);
      } else {
        onClickFeature(null!);
      }
    }
    if (isInEditMode === false) {
      return;
    }

    /* Keep the mouse down to drag a box */
    if (
      this.myDrawMode === DRAWMODE.BOX &&
      this.myEditMode === EDITMODE.ADD_FEATURE
    ) {
      this.cancelEdit(false);
      return false;
    }

    if (this.somethingWasDragged !== DRAGMODE.NONE) {
      this.featureHasChanged(
        `A ${this.somethingWasDragged.toString()} was dragged`,
      );
    }

    /* Delete a vertex or feature on mouseUp */
    if (this.myEditMode === EDITMODE.DELETE_FEATURES) {
      this.deleteFeature();
      return false;
    }

    if (
      this.mouseIsOverVertexNr !== VERTEX.NONE ||
      this.selectedEdge !== EDGE.NONE
    ) {
      return false;
    }

    if (this.myEditMode === EDITMODE.ADD_FEATURE) {
      return false;
    }
  }

  cancelEdit(cancelLastPoint: boolean): void {
    const { isInEditMode, mapId } = this.props;
    if (isInEditMode === false) {
      return;
    }
    const webmapjs = webmapUtils.getWMJSMapById(mapId);

    /* When in addpolygon mode, finish the polygon */
    if (this.myEditMode === EDITMODE.ADD_FEATURE) {
      this.myEditMode = EDITMODE.EMPTY;
      if (
        this.drawMode === DRAWMODE.POLYGON ||
        this.drawMode === DRAWMODE.BOX
      ) {
        if (this.snappedPolygonIndex === SNAPPEDFEATURE.NONE) {
          return;
        }

        const feature = this.getSelectedFeature();
        const { coordinates } = feature.geometry as GeoJSON.Polygon;
        const polygon = coordinates[this.snappedPolygonIndex];

        if (!polygon) {
          coordinates[this.snappedPolygonIndex] = [];
          return;
        }

        if (polygon.length === 0) {
          return;
        }

        if (!this.checkIfFeatureIsBox(feature)) {
          if (cancelLastPoint === true) {
            polygon.pop();
          }
          if (polygon.length < 3) {
            coordinates.pop();
          }
        }
      }
      if (this.myDrawMode === DRAWMODE.LINESTRING) {
        const feature = this.getSelectedFeature();
        const featureCoords = (feature.geometry as GeoJSON.LineString)
          .coordinates;
        featureCoords.pop();
      }
      this.featureHasChanged('cancelEdit');
      webmapjs?.draw('MapDraw::cancelEdit');
    } else {
      /* When in deletefeatures mode, remove any vertex under the mousecursor */
      // eslint-disable-next-line no-lonely-if
      if (this.myEditMode === EDITMODE.DELETE_FEATURES) {
        this.deleteFeature();
      }
    }
  }

  moveVertex(feature: GeoJSON.Feature, mouseDown: boolean): boolean {
    const geometryType = feature.geometry.type;
    if (geometryType === 'GeometryCollection') {
      return undefined!;
    }

    let featureCoords = feature.geometry.coordinates[
      this.snappedPolygonIndex as number
    ] as Position[];
    if (
      geometryType === 'Point' ||
      geometryType === 'MultiPoint' ||
      geometryType === 'LineString'
    ) {
      featureCoords = feature.geometry.coordinates as Position[];
    }

    if (!featureCoords) {
      return undefined!;
    }
    const vertexSelected =
      mouseDown === true && this.mouseIsOverVertexNr !== VERTEX.NONE;
    if (vertexSelected || this.myEditMode === EDITMODE.ADD_FEATURE) {
      if (geometryType === 'LineString') {
        this.transposeVertex(featureCoords);
        // eslint-disable-next-line consistent-return
        return false;
      }
      /* In case middle point is selected, transpose whole polygon */
      if (
        this.mouseIsOverVertexNr === VERTEX.MIDDLE_POINT_OF_FEATURE &&
        this.snappedGeoCoords
      ) {
        this.transposePolygon(featureCoords);
      } else if (this.mouseIsOverVertexNr !== VERTEX.NONE) {
        /* Transpose polygon vertex */
        this.transposeVertex(featureCoords);
      }
      // eslint-disable-next-line consistent-return
      return false;
    }

    return undefined!;
  }

  transposePolygon(featureCoords: Position[]): void {
    const incX = this.mouseGeoCoord.x - this.snappedGeoCoords.x;
    const incY = this.mouseGeoCoord.y - this.snappedGeoCoords.y;
    this.snappedGeoCoords.x = this.mouseGeoCoord.x;
    this.snappedGeoCoords.y = this.mouseGeoCoord.y;

    for (const featureCoord of featureCoords) {
      featureCoord[0] += incX;
      featureCoord[1] += incY;
    }
    if (this.myEditMode !== EDITMODE.ADD_FEATURE) {
      this.somethingWasDragged = DRAGMODE.FEATURE;
    }
  }

  transposeVertex(featureCoords: Position[]): void {
    if (this.myEditMode !== EDITMODE.ADD_FEATURE) {
      this.somethingWasDragged = DRAGMODE.VERTEX;
    }

    if (this.myDrawMode === DRAWMODE.POINT) {
      /* eslint-disable-next-line no-param-reassign */
      featureCoords[0] = this.mouseGeoCoord.x as unknown as Position;
      /* eslint-disable-next-line no-param-reassign */
      featureCoords[1] = this.mouseGeoCoord.y as unknown as Position;
      return;
    }

    if (this.myDrawMode === DRAWMODE.LINESTRING) {
      // eslint-disable-next-line no-param-reassign
      featureCoords[this.mouseIsOverVertexNr as number][0] =
        this.mouseGeoCoord.x;
      // eslint-disable-next-line no-param-reassign
      featureCoords[this.mouseIsOverVertexNr as number][1] =
        this.mouseGeoCoord.y;
      return;
    }

    if (this.myDrawMode === DRAWMODE.MULTIPOINT) {
      if (this.mouseIsOverVertexNr !== SNAPPEDFEATURE.NONE) {
        // eslint-disable-next-line no-param-reassign
        featureCoords[this.mouseIsOverVertexNr as number][0] =
          this.mouseGeoCoord.x;
        // eslint-disable-next-line no-param-reassign
        featureCoords[this.mouseIsOverVertexNr as number][1] =
          this.mouseGeoCoord.y;
      }
      return;
    }

    if (
      this.myDrawMode === DRAWMODE.BOX &&
      (featureCoords.length === 4 || featureCoords.length === 5)
    ) {
      while (featureCoords.length < 4) {
        featureCoords.push([this.mouseGeoCoord.x, this.mouseGeoCoord.y]);
      }
      if (this.mouseIsOverVertexNr === 0) {
        featureCoords[0][0] = this.mouseGeoCoord.x;
        featureCoords[0][1] = this.mouseGeoCoord.y;

        featureCoords[1][0] = this.mouseGeoCoord.x;
        featureCoords[3][1] = this.mouseGeoCoord.y;
      }
      if (this.mouseIsOverVertexNr === 1) {
        featureCoords[1][0] = this.mouseGeoCoord.x;
        featureCoords[1][1] = this.mouseGeoCoord.y;

        featureCoords[0][0] = this.mouseGeoCoord.x;
        featureCoords[4][0] = this.mouseGeoCoord.x;
        featureCoords[2][1] = this.mouseGeoCoord.y;
      }
      if (this.mouseIsOverVertexNr === 2) {
        featureCoords[2][0] = this.mouseGeoCoord.x;
        featureCoords[2][1] = this.mouseGeoCoord.y;

        featureCoords[1][1] = this.mouseGeoCoord.y;
        featureCoords[3][0] = this.mouseGeoCoord.x;
      }
      if (this.mouseIsOverVertexNr === 3) {
        featureCoords[3][0] = this.mouseGeoCoord.x;
        featureCoords[3][1] = this.mouseGeoCoord.y;

        featureCoords[0][1] = this.mouseGeoCoord.y;
        featureCoords[4][1] = this.mouseGeoCoord.y;
        featureCoords[2][0] = this.mouseGeoCoord.x;
      }

      /* Ensure the box square shape */
      /* A: Left edge should be vertical */
      const [
        [featureCoord0X, featureCoord0Y],
        [featureCoord1X],
        [featureCoord2X, featureCoord2Y],
        [, featureCoord3Y],
      ] = featureCoords;
      featureCoords[0][0] = featureCoord1X;

      /* B: Top edge should be horizontal */
      featureCoords[0][1] = featureCoord3Y;

      /* C: Right edge should be vertical */
      featureCoords[3][0] = featureCoord2X;

      /* D: Bottom Edge should be horizontal */
      featureCoords[1][1] = featureCoord2Y;

      /* Close the box: Last point (point 5 of the box) should have the same coordinates as the starting point */
      featureCoords[4][0] = featureCoord0X;
      featureCoords[4][1] = featureCoord0Y;
    }

    if (this.myDrawMode === DRAWMODE.POLYGON) {
      featureCoords[this.mouseIsOverVertexNr as number][0] =
        this.mouseGeoCoord.x;
      featureCoords[this.mouseIsOverVertexNr as number][1] =
        this.mouseGeoCoord.y;
      /* Transpose begin and end vertice */
      if (this.mouseIsOverVertexNr === 0) {
        featureCoords[featureCoords.length - 1][0] = this.mouseGeoCoord.x;
        featureCoords[featureCoords.length - 1][1] = this.mouseGeoCoord.y;
      }
    }
  }

  componentCleanup(): void {
    // this will hold the cleanup code
    document.removeEventListener('keydown', this.handleKeyDown);
    const { mapId } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);

    if (webmapjs !== undefined && this.listenersInitialized === true) {
      this.listenersInitialized = undefined!;
      webmapjs.removeListener('beforecanvasdisplay', this.beforeDraw);
      webmapjs.removeListener('beforemousemove', this.mouseMove);
      webmapjs.removeListener('beforemousedown', this.mouseDown);
      webmapjs.removeListener('beforemouseup', this.mouseUp);
      webmapjs.draw();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  initializeFeature(feature: GeoJSON.Feature): void {
    if (!feature.properties) {
      feature.properties = {};
    }
    if (!feature.geometry) {
      feature.geometry = {} as GeoJSON.Geometry;
    }
    if (!feature.type) {
      feature.type = 'Feature';
    }
    if (
      feature.geometry.type !== 'GeometryCollection' &&
      !feature.geometry.coordinates
    ) {
      feature.geometry.coordinates = [];
    }
  }

  validatePolys(fixPolys: boolean): void {
    if (!this.geojson?.features?.length) {
      return;
    }
    for (const geoJsonFeature of this.geojson.features) {
      const feature = geoJsonFeature;
      this.initializeFeature(feature);
      const featureType = feature.geometry.type;
      if (featureType === 'Polygon') {
        /* Loop through all polygons of the same feature */
        const polygonGeometry = feature.geometry;
        for (const polyCoordinates of polygonGeometry.coordinates) {
          let featureCoords = polyCoordinates;

          /* Sort clockwise */
          if (fixPolys) {
            if (this.checkIfFeatureIsBox(feature)) {
              // A box should have:
              // - topleft index 0 and 4
              // - bottomleft index 1
              // - bottomright index 2
              // - topright index 3
              // This represents counter-clockwise ordering and represents an area (https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.6)

              // Make sure the coordinate order in a box is always the same
              const boxCoords = feature.geometry.coordinates[0];
              if (boxCoords.length === 5) {
                // Copy the first four coordinates and sort them according Y and then X
                const sortedYthenX = boxCoords
                  .slice(0, 4)
                  .sort((a, b) => {
                    return b[1] - a[1]; // Sort on Y
                  })
                  .sort((a, b) => {
                    return a[0] - b[0]; // Sort on X
                  });
                // Assign the ordered coordinates back to the geometry of the feature
                feature.geometry.coordinates[0] = [
                  [...sortedYthenX[0]],
                  [...sortedYthenX[1]],
                  [...sortedYthenX[3]],
                  [...sortedYthenX[2]],
                  [...sortedYthenX[0]],
                ];
              }
            } else {
              const checkClockwiseOrder = (
                featureCoordinates: Position[],
              ): number => {
                let sum = 0;
                for (let j = 0; j < featureCoordinates.length - 1; j += 1) {
                  const currentPoint = featureCoordinates[j];
                  const nextPoint =
                    featureCoordinates[(j + 1) % featureCoordinates.length];
                  sum +=
                    (nextPoint[0] - currentPoint[0]) *
                    (nextPoint[1] + currentPoint[1]);
                }
                return sum;
              };
              const sum = checkClockwiseOrder(featureCoords);
              if (sum < 0) {
                featureCoords = featureCoords.reverse();
                /* The lastly selected vertex is now aways the second in the array */
                if (
                  this.mouseIsOverVertexNr !== VERTEX.NONE &&
                  this.mouseIsOverVertexNr !== VERTEX.MIDDLE_POINT_OF_FEATURE
                ) {
                  this.mouseIsOverVertexNr = 1;
                }
              }
            }
          }
        }
      }
    }
  }

  beforeDraw(ctx: CanvasRenderingContext2D): void {
    /* beforeDraw is an event callback function which is triggered
     just before viewer will flip the back canvas buffer to the front.
     You are free to draw anything you like on the canvas.
    */

    const { selectedFeatureIndex, isInEditMode, mapId, linkedFeatures } =
      this.props;

    if (!this.geojson?.features?.length) {
      return;
    }
    this.textPositions = [];
    this.mouseOverPolygonCoordinates = [];
    this.mouseOverPolygonFeatureIndex = -1;

    const drawFeatureGeometry = (
      feature: GeoJSON.Feature,
      featureIndex: number,
      geometry: GeoJSON.Geometry = feature.geometry,
    ): void => {
      if (geometry.type === 'GeometryCollection') {
        for (const geom of geometry.geometries) {
          drawFeatureGeometry(feature, featureIndex, geom);
        }
      }

      if (geometry.type === 'Point' || geometry.type === 'MultiPoint') {
        const coordinates =
          geometry.type === 'Point'
            ? [geometry.coordinates]
            : geometry.coordinates;
        const XYCoords = getPixelCoordFromGeoCoord(coordinates, mapId);
        if (XYCoords.length === 0) {
          return;
        }
        for (let j = 0; j < XYCoords.length; j += 1) {
          this.drawPoint(
            ctx,
            XYCoords[j],
            this.mouseIsOverVertexNr === j &&
              selectedFeatureIndex === featureIndex,
            false,
            isInEditMode && selectedFeatureIndex === featureIndex,
            feature,
            featureIndex,
          );
        }
      }

      if (geometry.type === 'MultiPolygon') {
        const totalMiddle = { x: 0, y: 0, nr: 0 };

        for (const multiPoly of geometry.coordinates) {
          for (
            let polygonIndex = 0;
            polygonIndex < multiPoly.length;
            polygonIndex += 1
          ) {
            const ringCoordinates = multiPoly[polygonIndex];
            const XYCoords = getPixelCoordFromGeoCoord(ringCoordinates, mapId);
            /* Only draw if there is stuff to show */
            if (XYCoords.length === 0) {
              // eslint-disable-next-line no-continue
              continue;
            }

            const middle = this.drawPolygon(
              ctx,
              XYCoords,
              featureIndex,
              polygonIndex,
            );
            if (middle) {
              totalMiddle.x += middle.x * middle.nr;
              totalMiddle.y += middle.y * middle.nr;
              totalMiddle.nr += middle.nr;
            }
            if (isInEditMode) {
              /* Draw all vertices on the edges of the polygons */
              for (let j = 0; j < XYCoords.length; j += 1) {
                this.drawVertice(
                  ctx,
                  XYCoords[j],
                  this.snappedPolygonIndex === polygonIndex &&
                    this.mouseIsOverVertexNr === j &&
                    selectedFeatureIndex === featureIndex,
                  false,
                  isInEditMode && selectedFeatureIndex === featureIndex,
                );
              }

              if (middle && isInEditMode === true && XYCoords.length >= 3) {
                /* Draw middle vertice for the poly if poly covers an area, e.g. when it contains more than three points */
                this.drawVertice(
                  ctx,
                  middle,
                  this.snappedPolygonIndex === polygonIndex &&
                    this.mouseIsOverVertexNr ===
                      VERTEX.MIDDLE_POINT_OF_FEATURE &&
                    selectedFeatureIndex === featureIndex,
                  true,
                  isInEditMode && selectedFeatureIndex === featureIndex,
                );
              }
            }
          }
        }
        if (totalMiddle.nr > 0) {
          const mx = totalMiddle.x / totalMiddle.nr;
          const my = totalMiddle.y / totalMiddle.nr;
          if (feature.properties?.text) {
            this.textPositions.push({
              x: mx,
              y: my,
              text: feature.properties.text,
            });
          }
        }
      }

      if (geometry.type === 'Polygon') {
        /* Loop through all rings of the same polygon */
        for (
          let polygonIndex = 0;
          polygonIndex < geometry.coordinates.length;
          polygonIndex += 1
        ) {
          const featureCoords = geometry.coordinates[polygonIndex];

          const XYCoords = getPixelCoordFromGeoCoord(featureCoords, mapId);
          /* Only draw if there is stuff to show */
          if (XYCoords.length === 0) {
            // eslint-disable-next-line no-continue
            continue;
          }

          const middle = this.drawPolygon(
            ctx,
            XYCoords,
            featureIndex,
            polygonIndex,
          );

          if (middle && feature.properties?.text) {
            this.textPositions.push({
              x: middle.x,
              y: middle.y,
              text: feature.properties.text,
            });
          }

          if (isInEditMode) {
            /* Draw all vertices on the edges of the polygons */
            for (let j = 0; j < XYCoords.length; j += 1) {
              this.drawVertice(
                ctx,
                XYCoords[j],
                this.snappedPolygonIndex === polygonIndex &&
                  this.mouseIsOverVertexNr === j &&
                  selectedFeatureIndex === featureIndex,
                false,
                isInEditMode && selectedFeatureIndex === featureIndex,
              );
              // Enable the following to debug vertice numbers in the screen
              // ctx.font = '20px Arial';
              // ctx.fillText(j, XYCoords[j].x - 10, XYCoords[j].y - 10);
            }

            if (middle && isInEditMode === true && XYCoords.length >= 3) {
              /* Draw middle vertice for the poly if poly covers an area, e.g. when it contains more than three points */
              this.drawVertice(
                ctx,
                middle,
                this.snappedPolygonIndex === polygonIndex &&
                  this.mouseIsOverVertexNr === VERTEX.MIDDLE_POINT_OF_FEATURE &&
                  selectedFeatureIndex === featureIndex,
                true,
                isInEditMode && selectedFeatureIndex === featureIndex,
              );
            }
          }
        }
      }

      if (geometry.type === 'LineString') {
        /* Loop through all line pints of the same feature */
        const featureCoords = geometry.coordinates;
        if (!featureCoords || !featureCoords.length) {
          return;
        }
        const XYCoords = getPixelCoordFromGeoCoord(featureCoords, mapId);
        /* Only draw if there is stuff to show */
        if (XYCoords.length === 0) {
          return;
        }

        const middle = this.drawLine(ctx, XYCoords, featureIndex, 0);

        if (middle && feature.properties?.text) {
          this.textPositions.push({
            x: middle.x,
            y: middle.y,
            text: feature.properties.text,
          });
        }

        if (isInEditMode) {
          /* Draw all vertices on the edges of the polygons */
          for (let j = 0; j < XYCoords.length; j += 1) {
            this.drawVertice(
              ctx,
              XYCoords[j],
              this.mouseIsOverVertexNr === j &&
                selectedFeatureIndex === featureIndex,
              false,
              isInEditMode && selectedFeatureIndex === featureIndex,
            );
          }
        }
      }
    };

    /* Draw all features */
    for (
      let featureIndex = 0;
      featureIndex < this.geojson.features.length;
      featureIndex += 1
    ) {
      const feature = this.geojson.features[featureIndex];
      /* Do not draw selected feature here, should be done last */
      if (featureIndex !== selectedFeatureIndex) {
        drawFeatureGeometry(feature, featureIndex);
      }
    }
    /* Draw selected feature to display it on top */
    drawFeatureGeometry(this.getSelectedFeature(), selectedFeatureIndex);

    /* Highlight polygon with mousehover */
    if (
      (isInEditMode === true &&
        this.mouseOverPolygonFeatureIndex === selectedFeatureIndex &&
        this.mouseIsOverVertexNr === VERTEX.NONE &&
        this.snappedPolygonIndex === SNAPPEDFEATURE.NONE &&
        this.selectedEdge === EDGE.NONE) ||
      this.mouseIsOverVertexNr === VERTEX.MIDDLE_POINT_OF_FEATURE ||
      (linkedFeatures?.features && linkedFeatures.features.length > 0)
    ) {
      const feature = this.getSelectedFeature();
      const isLinkedFeature = linkedFeatures?.features.some(
        (linkedFeature) => linkedFeature.id === feature.id,
      );
      const shouldHighlight =
        isLinkedFeature && feature.properties?.selectionType !== 'fir';

      if (shouldHighlight && this.mouseOverPolygonCoordinates.length >= 2) {
        const featureProperties = feature.properties;
        ctx.beginPath();
        ctx.moveTo(
          this.mouseOverPolygonCoordinates[0].x,
          this.mouseOverPolygonCoordinates[0].y,
        );
        for (let j = 1; j < this.mouseOverPolygonCoordinates.length; j += 1) {
          const coord = this.mouseOverPolygonCoordinates[j];
          ctx.lineTo(coord.x, coord.y);
        }
        const fillOpacity =
          (featureProperties && featureProperties['fill-opacity']) ||
          (defaultGeoJSONStyleProperties &&
            defaultGeoJSONStyleProperties['fill-opacity']);
        ctx.closePath();
        ctx.strokeStyle =
          featureProperties?.stroke || defaultGeoJSONStyleProperties?.stroke;
        ctx.lineWidth =
          defaultGeoJSONStyleProperties &&
          defaultGeoJSONStyleProperties['stroke-width'];
        ctx.fillStyle =
          featureProperties?.fill || defaultGeoJSONStyleProperties?.fill;
        ctx.globalAlpha = Number(fillOpacity) + 0.2; // Fill gets +0.2 opacity on hover
        ctx.fill();
        ctx.globalAlpha =
          defaultGeoJSONStyleProperties &&
          defaultGeoJSONStyleProperties['stroke-opacity'];
        ctx.stroke();
      }
    }

    /* Draw labels */

    for (const textPosition of this.textPositions) {
      const { x, y, text } = textPosition;
      ctx.fillStyle = 'black';
      ctx.font = '12px Arial';
      ctx.fillText(text, x, y);
    }

    /* Draw hovered feature */
    if (this.featureEvent) {
      const { feature, featureIndex, screenCoords } = this.featureEvent;
      const drawFunctionId = feature?.properties?.hoverDrawFunctionId;
      const drawFunction = getDrawFunctionFromStore(drawFunctionId);
      if (drawFunction) {
        drawFunction({
          context: ctx,
          featureIndex,
          isInEditMode,
          feature,
          isHovered: true,
          coord: screenCoords,
          selected: featureIndex === selectedFeatureIndex,
          mouseX: 0,
          mouseY: 0,
        });
      }
    }
  }

  /* Checks if mouse is in proximity of given coordinate */
  checkDist(
    coord: Coordinate,
    polygonIndex: SNAPPEDFEATURE | number,
    mouseX: number,
    mouseY: number,
  ): boolean {
    const VERTEX = distance(coord, { x: mouseX, y: mouseY });
    if (VERTEX < 10) {
      this.snappedGeoCoords = { ...this.mouseGeoCoord };
      this.snappedPolygonIndex = polygonIndex;
      return true;
    }
    return false;
  }

  hoverEdge(
    geometry: GeoJSON.Geometry,
    mouseX: number,
    mouseY: number,
  ): {
    selectedEdge: EDGE | number;
    snappedPolygonIndex: SNAPPEDFEATURE | number;
  } {
    const { mapId } = this.props;
    const { type: geometryType } = geometry;
    if (geometryType === 'GeometryCollection') {
      return {
        selectedEdge: EDGE.NONE,
        snappedPolygonIndex: SNAPPEDFEATURE.NONE,
      };
    }
    if (geometryType === 'Polygon') {
      for (
        let polygonIndex = geometry.coordinates.length - 1;
        polygonIndex >= 0;
        polygonIndex -= 1
      ) {
        const featureCoords = geometry.coordinates[polygonIndex];
        if (featureCoords === undefined) {
          // eslint-disable-next-line no-continue
          continue;
        }
        /* Get all vertexes */
        const XYCoords = convertGeoCoordsToScreenCoords(featureCoords, mapId);
        for (let j = 0; j < XYCoords.length; j += 1) {
          const startV = XYCoords[j];
          const stopV = XYCoords[(j + 1) % XYCoords.length];
          if (isBetween(startV, { x: mouseX, y: mouseY }, stopV)) {
            return { selectedEdge: j, snappedPolygonIndex: polygonIndex };
          }
        }
      }
      return {
        selectedEdge: EDGE.NONE,
        snappedPolygonIndex: SNAPPEDFEATURE.NONE,
      };
    }
    if (geometryType === 'LineString') {
      const featureCoords = geometry.coordinates;
      if (featureCoords === undefined) {
        return {
          selectedEdge: EDGE.NONE,
          snappedPolygonIndex: SNAPPEDFEATURE.NONE,
        };
      }
      /* Get all vertexes */
      const XYCoords = convertGeoCoordsToScreenCoords(featureCoords, mapId);
      for (let j = 0; j < XYCoords.length - 1; j += 1) {
        const startV = XYCoords[j];
        const stopV = XYCoords[(j + 1) % XYCoords.length];
        if (isBetween(startV, { x: mouseX, y: mouseY }, stopV)) {
          return { selectedEdge: j, snappedPolygonIndex: 0 };
        }
      }
      return {
        selectedEdge: EDGE.NONE,
        snappedPolygonIndex: SNAPPEDFEATURE.NONE,
      };
    }
    return {
      selectedEdge: EDGE.NONE,
      snappedPolygonIndex: SNAPPEDFEATURE.NONE,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  drawVertice(
    ctx: CanvasRenderingContext2D,
    _coord: Coordinate,
    selected: boolean,
    middle: boolean,
    isInEditMode: boolean,
  ): void {
    let w = 7;
    const coord = {
      x: parseInt(_coord.x as unknown as string, 10),
      y: parseInt(_coord.y as unknown as string, 10),
    };
    if (isInEditMode === false) {
      return;
    }
    if (selected === false) {
      if (middle === true) {
        /* Style for middle editable vertice */
        ctx.strokeStyle = '#000';
        ctx.fillStyle = '#D87502';
        ctx.lineWidth = 1.0;
      } else {
        /* Style for standard editable vertice */
        ctx.strokeStyle = '#000';
        ctx.fillStyle = colors.vertice;
        ctx.lineWidth = 1.0;
      }
    } else {
      /* Style for selected editable vertice */
      ctx.strokeStyle = '#000';
      ctx.fillStyle = '#FF0';
      ctx.lineWidth = 1.0;
      w = 11;
    }
    ctx.globalAlpha = 1.0;
    ctx.fillRect(coord.x - w / 2, coord.y - w / 2, w, w);
    ctx.strokeRect(coord.x - w / 2, coord.y - w / 2, w, w);
    if (isInEditMode) {
      ctx.strokeRect(coord.x - 0.5, coord.y - 0.5, 1, 1);
    }
  }

  drawPoint(
    ctx: CanvasRenderingContext2D,
    _coord: Coordinate,
    selected: boolean,
    middle: boolean,
    isInEditMode: boolean,
    feature: GeoJSON.Feature,
    featureIndex: number,
  ): void {
    const { properties: featureProperties } = feature;
    if (featureProperties?.imageURL) {
      this.drawIcon(ctx, _coord, featureProperties);
    }

    const drawStyledMarker = featureProperties
      ? featureProperties.stroke ||
        featureProperties['stroke-width'] ||
        featureProperties.fill
      : null;
    const drawMarkerByDefault =
      !featureProperties ||
      !featureProperties.imageURL ||
      !featureProperties.drawFunctionId;

    if (featureProperties!.drawFunctionId) {
      const drawFunction = getDrawFunctionFromStore(
        featureProperties!.drawFunctionId,
      );
      if (drawFunction) {
        const isHovered =
          this.featureEvent && this.featureEvent.feature === feature;
        drawFunction({
          context: ctx,
          featureIndex,
          coord: _coord,
          selected,
          isInEditMode,
          feature,
          mouseX: this.mouseX,
          mouseY: this.mouseY,
          isHovered,
        });
      }
    } else if (drawStyledMarker || drawMarkerByDefault) {
      this.drawMarker(
        ctx,
        _coord,
        selected,
        middle,
        isInEditMode,
        featureProperties!,
      );
    }
  }

  drawIcon(
    ctx: CanvasRenderingContext2D,
    _coord: Coordinate,
    featureProperties: GeoJsonProperties,
  ): DrawStyle {
    const { mapId } = this.props;
    const webmapjs = webmapUtils.getWMJSMapById(mapId);
    if (!webmapjs) {
      return undefined!;
    }
    const image = webmapjs?.getMapImageStore.getImage(
      featureProperties!.imageURL,
    );

    if (
      image.isLoaded() === false &&
      image.hasError() === false &&
      image.isLoading() === false
    ) {
      image.load();

      /* After the image is loaded the canvas will be redrawn and drawIcon will be triggered again
      via the beforecanvasdisplay listener. */
      return undefined!;
    }

    if (image.isLoaded()) {
      const imageWidth =
        (featureProperties && featureProperties.imageWidth) ||
        this.defaultIconProps.imageWidth;
      const imageHeight =
        (featureProperties && featureProperties.imageHeight) ||
        this.defaultIconProps.imageHeight;

      ctx.drawImage(
        image.getElement(),
        parseInt(_coord.x as unknown as string, 10) - imageWidth / 2,
        parseInt(_coord.y as unknown as string, 10) - imageHeight / 2,
        imageWidth,
        imageHeight,
      );
      if (
        featureProperties!.imageBorderWidth &&
        featureProperties!.imageBorderWidth > 0
      ) {
        ctx.lineWidth = featureProperties!.imageBorderWidth;
        ctx.strokeRect(
          parseInt(_coord.x as unknown as string, 10) - imageWidth / 2,
          parseInt(_coord.y as unknown as string, 10) - imageHeight / 2,
          imageWidth,
          imageHeight,
        );
      }
    }

    return undefined!;
  }

  drawMarker(
    ctx: CanvasRenderingContext2D,
    _coord: Coordinate,
    selected: boolean,
    middle: boolean,
    isInEditMode: boolean,
    featureProperties: GeoFeatureStyle,
  ): void | DrawStyle {
    const strokeStyle =
      (featureProperties && featureProperties.stroke) ||
      this.defaultPolyProps.stroke;
    const lineWidth =
      (featureProperties && featureProperties['stroke-width']) ||
      this.defaultPolyProps['stroke-width'];
    const fillStyle =
      (featureProperties && featureProperties.fill) ||
      this.defaultPolyProps.fill;

    if (isInEditMode === false) {
      /* Standard style, no editing, just display location of vertices */
      ctx.strokeStyle = strokeStyle!;
      ctx.fillStyle = fillStyle!;
      ctx.lineWidth = lineWidth!;
    } else if (selected === false) {
      /* Style for standard editable vertice */
      ctx.strokeStyle = '#000';
      ctx.fillStyle = colors.main;
      ctx.lineWidth = 1.0;
    } else {
      /* Style for selected editable vertice */
      ctx.strokeStyle = '#000';
      ctx.fillStyle = '#FF0';
      ctx.lineWidth = 1.0;
    }
    const coord = {
      x: parseInt(_coord.x as unknown as string, 10),
      y: parseInt(_coord.y as unknown as string, 10),
    };
    ctx.globalAlpha = 1.0;
    ctx.beginPath();
    const topRadius = 9;
    const topHeight = 2.5 * topRadius;
    ctx.arc(coord.x, coord.y - topHeight, topRadius, Math.PI, Math.PI * 2);
    ctx.bezierCurveTo(
      coord.x + topRadius,
      coord.y - topHeight,
      coord.x + topRadius / 1.6,
      coord.y - topRadius,
      coord.x,
      coord.y,
    );
    ctx.bezierCurveTo(
      coord.x,
      coord.y,
      coord.x - topRadius / 1.6,
      coord.y - topRadius,
      coord.x - topRadius,
      coord.y - topHeight,
    );
    ctx.stroke();
    ctx.fill();
    /* Fill center circle */
    ctx.fillStyle = '#FFF';
    ctx.beginPath();
    ctx.arc(coord.x, coord.y - topHeight, topRadius / 2, Math.PI * 2, 0);
    ctx.fill();

    /* Fill marker exact location */
    ctx.fillStyle = '#000';
    ctx.beginPath();
    ctx.arc(coord.x, coord.y, 2, Math.PI * 2, 0);
    ctx.fill();
  }

  drawLine(
    ctx: CanvasRenderingContext2D,
    XYCoords: Coordinate[],
    featureIndex: number,
    lineStringIndex: number,
  ): void | DrawStyle {
    const { isInEditMode, selectedFeatureIndex } = this.props;

    const feature = this.geojson.features[featureIndex];
    if (!feature?.geometry) {
      return;
    }
    if (feature.geometry.type !== 'LineString') {
      return;
    }
    let lineProps = feature.properties;
    if (!lineProps) {
      lineProps = this.defaultLineStringProps;
    }

    /* Draw polygons and calculate center of poly */
    const middle = { x: 0, y: 0, nr: 0 };
    ctx.beginPath();
    ctx.strokeStyle = lineProps.stroke || this.defaultLineStringProps.stroke;
    ctx.lineWidth =
      lineProps['stroke-width'] || this.defaultLineStringProps['stroke-width'];
    ctx.fillStyle = lineProps.fill || this.defaultLineStringProps.fill;
    const startCoord = XYCoords[0];
    ctx.moveTo(startCoord.x, startCoord.y);
    middle.x += startCoord.x;
    middle.y += startCoord.y;

    for (let j = 1; j < XYCoords.length; j += 1) {
      const coord = XYCoords[j];
      ctx.lineTo(coord.x, coord.y);
      if (j < XYCoords.length - 1) {
        middle.x += coord.x;
        middle.y += coord.y;
      }
    }

    ctx.globalAlpha =
      lineProps['fill-opacity'] || this.defaultLineStringProps['fill-opacity'];
    if (lineProps['fill-opacity'] === 0) {
      ctx.globalAlpha = 0;
    }
    // ctx.fill();
    ctx.globalAlpha =
      lineProps['stroke-opacity'] ||
      this.defaultLineStringProps['stroke-opacity'];
    ctx.stroke();
    // let test = ctx.isPointInPath(this.mouseX, this.mouseY);
    // if (test) {
    this.mouseOverPolygonCoordinates = 0;
    this.mouseOverPolygonFeatureIndex = 0;
    // }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middle.x = parseInt(middle.x / (XYCoords.length - 1), 10);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middle.y = parseInt(middle.y / (XYCoords.length - 1), 10);

    if (
      isInEditMode === true &&
      this.snappedPolygonIndex === lineStringIndex &&
      this.selectedEdge !== EDGE.NONE &&
      selectedFeatureIndex === featureIndex
    ) {
      /* Higlight selected edge of a LineString, previousely detected by mouseover event */
      ctx.beginPath();
      ctx.strokeStyle = '#FF0';
      ctx.lineWidth = 5;
      ctx.moveTo(XYCoords[this.selectedEdge].x, XYCoords[this.selectedEdge].y);
      ctx.lineTo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        XYCoords[(this.selectedEdge + 1) % XYCoords.length].x,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        XYCoords[(this.selectedEdge + 1) % XYCoords.length].y,
      );
      ctx.stroke();
    }

    middle.nr = XYCoords.length - 1;
    return middle;
  }

  drawPolygon(
    ctx: CanvasRenderingContext2D,
    XYCoords: Coordinate[],
    featureIndex: number,
    polygonIndex: number,
  ): void | DrawStyle {
    const { isInEditMode, selectedFeatureIndex } = this.props;

    const feature = this.geojson.features[featureIndex];
    if (!feature?.geometry || XYCoords.length === 0) {
      return;
    }
    let polyProps = feature.properties;
    if (!polyProps) {
      polyProps = this.defaultPolyProps;
    }

    /* Draw polygons and calculate center of poly */
    const middle = { x: 0, y: 0, nr: 0 };

    ctx.beginPath();
    ctx.strokeStyle = polyProps.stroke || this.defaultPolyProps.stroke;
    ctx.lineWidth =
      polyProps['stroke-width'] || this.defaultPolyProps['stroke-width'];
    ctx.fillStyle = polyProps.fill || this.defaultPolyProps.fill;
    const startCoord = XYCoords[0];
    ctx.moveTo(startCoord.x, startCoord.y);
    middle.x += startCoord.x;
    middle.y += startCoord.y;

    for (let j = 1; j < XYCoords.length; j += 1) {
      const coord = XYCoords[j];
      ctx.lineTo(coord.x, coord.y);
      if (j < XYCoords.length - 1) {
        middle.x += coord.x;
        middle.y += coord.y;
      }
    }
    ctx.closePath();

    ctx.globalAlpha =
      polyProps['fill-opacity'] || this.defaultPolyProps['fill-opacity'];
    if (polyProps['fill-opacity'] === 0) {
      ctx.globalAlpha = 0;
    }
    ctx.fill();
    ctx.globalAlpha =
      polyProps['stroke-opacity'] || this.defaultPolyProps['stroke-opacity'];
    ctx.stroke();

    const test = ctx.isPointInPath(this.mouseX, this.mouseY);
    if (test) {
      this.mouseOverPolygonCoordinates = XYCoords;
      this.mouseOverPolygonFeatureIndex = featureIndex;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middle.x = parseInt(middle.x / (XYCoords.length - 1), 10);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middle.y = parseInt(middle.y / (XYCoords.length - 1), 10);

    if (
      isInEditMode === true &&
      this.snappedPolygonIndex === polygonIndex &&
      this.selectedEdge !== EDGE.NONE &&
      selectedFeatureIndex === featureIndex
    ) {
      /* Higlight selected edge of a polygon, previousely detected by mouseover event */
      ctx.strokeStyle = '#FF0';
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.moveTo(XYCoords[this.selectedEdge].x, XYCoords[this.selectedEdge].y);
      ctx.lineTo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        XYCoords[(this.selectedEdge + 1) % XYCoords.length].x,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        XYCoords[(this.selectedEdge + 1) % XYCoords.length].y,
      );
      ctx.stroke();
    }

    middle.nr = XYCoords.length - 1;

    return middle;
  }

  initializeFeatureCoordinates(
    feature: GeoJSON.Feature,
    type: string,
  ): GeoJSON.Feature {
    this.initializeFeature(feature);
    // eslint-disable-next-line no-underscore-dangle
    if (feature.properties!._type) {
      delete feature.properties!._type;
    }
    // eslint-disable-next-line default-case
    switch (type) {
      case DRAWMODE.POINT:
        feature.geometry.type = 'Point';
        break;
      case DRAWMODE.MULTIPOINT:
        feature.geometry.type = 'MultiPoint';
        break;
      case DRAWMODE.BOX:
        feature.geometry.type = 'Polygon';
        feature.properties!._type = 'box';
        break;
      case DRAWMODE.POLYGON:
        feature.geometry.type = 'Polygon';
        break;
      case DRAWMODE.LINESTRING:
        feature.geometry.type = 'LineString';
        break;
    }
    // eslint-disable-next-line default-case
    switch (feature.geometry.type) {
      case 'MultiPoint':
        if (feature.geometry.coordinates.length === 0) {
          feature.geometry.coordinates.push([]);
        }
        break;
      case 'Polygon':
        if (
          feature.properties!._type !== 'box' &&
          feature.geometry.coordinates.length === 0
        ) {
          feature.geometry.coordinates.push([]);
        }
        break;
      case 'LineString':
        if (feature.geometry.coordinates.length === 0) {
          feature.geometry.coordinates.push([]);
        }
        break;
    }

    return feature;
  }

  // eslint-disable-next-line class-methods-use-this
  checkIfFeatureIsBox(feature: GeoJSON.Feature): boolean {
    if (!feature?.properties?._type) {
      return false;
    }
    return feature.properties._type === 'box';
  }

  featureHasChanged(text: string): void {
    const { updateGeojson } = this.props;
    if (text !== NEW_FEATURE_CREATED) {
      this.validatePolys(text === 'cancelEdit');
    }
    if (updateGeojson && this.geojson) {
      updateGeojson(cloneDeep(this.geojson as unknown as Geometry), text);
    }
  }

  render(): React.ReactElement {
    return <div />;
  }
}

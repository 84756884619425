/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { createFakeApiInstance } from '@opengeoweb/api';
import axios, { AxiosInstance, Canceler } from 'axios';
import {
  Bulletin,
  DiscardDraftNotification,
  EventCategoryParams,
  StreamParams,
  StreamResponse,
  SWEvent,
  SWNotification,
  TimeseriesParams,
  TimeseriesResponseData,
} from '../types';
import { cancelRequestById, SpaceWeatherApi } from './api';
import {
  kpIndexDummyData,
  kpIndexForecastDummyData,
  interplanetaryMagneticFieldBt,
  interplanetaryMagneticFieldBz,
  solarWindSpeedDummyData,
  solarWindPressureDummyData,
  xRayFluxDummyData,
  solarWindDensityDummyData,
} from './DummyData';
import {
  fakeBulletins,
  fakeEventList,
  fakeEventListFixedDates,
} from './fakedata';

export const fetchBulletin = (bulletinId?: string): Bulletin => {
  if (!bulletinId) {
    return fakeBulletins[0];
  }
  return fakeBulletins.filter(
    (bulletin) => bulletin.bulletin_id === bulletinId,
  )[0];
};

// get event list /eventlist - possible option to pass: category
export const fetchEventList = (params?: {
  category?: string;
  originator?: string;
}): SWEvent[] => {
  // api call
  // fake retrieval
  if (params && params.category) {
    return fakeEventList.filter((event) => event.category === params.category);
  }
  if (params && params.originator && params.originator === 'KNMI') {
    return fakeEventList.filter((event) => event.originator === 'KNMI');
  }
  return fakeEventList;
};

// get event /event - possible option to pass: category
export const fetchEvent = (eventid: string): SWEvent => {
  // api call
  // fake retrieval
  return fakeEventList.find((fakeEvent) => fakeEvent.eventid === eventid)!;
};

// get event /event - possible option to pass: category - this returns fixed dates to be used for snapshots
export const fetchEventFixedDate = (eventid: string): SWEvent => {
  // api call
  // fake retrieval
  return fakeEventListFixedDates.find(
    (fakeEvent) => fakeEvent.eventid === eventid,
  )!;
};

// get whether there are new notifications (unacknowledged) /isNewNotification
export const fetchFakeNewNotifications = (): SWEvent[] => {
  return fakeEventList.filter(
    (event) => event.originator === 'METOffice' && event.notacknowledged,
  );
};

export const getDummyTimeSerie = (
  stream: string,
  parameter: string,
): TimeseriesResponseData => {
  switch (stream) {
    case 'kpforecast':
      return kpIndexForecastDummyData;
    case 'rtsw_mag':
      return parameter === 'bt'
        ? interplanetaryMagneticFieldBt
        : interplanetaryMagneticFieldBz;
    case 'rtsw_wind': {
      if (parameter === 'proton_speed') {
        return solarWindSpeedDummyData;
      }
      if (parameter === 'proton_pressure') {
        return solarWindPressureDummyData;
      }
      if (parameter === 'proton_density') {
        return solarWindDensityDummyData;
      }
      return solarWindDensityDummyData;
    }
    case 'xray':
      return xRayFluxDummyData;
    default:
      return kpIndexDummyData;
  }
};

const { CancelToken } = axios;
const storedCancels: Record<string, Canceler> = {};

const getApiRoutes = (axiosInstance: AxiosInstance): SpaceWeatherApi => {
  const api = {
    getTimeSeries: (
      params: TimeseriesParams,
      requestId: string,
    ): Promise<{ data: TimeseriesResponseData }> => {
      cancelRequestById(requestId);
      return axiosInstance
        .get('/timeseries/data', {
          params,
          cancelToken: new CancelToken((cancelToken: Canceler) => {
            storedCancels[requestId] = cancelToken;
          }),
        })
        .then(() => ({
          data: getDummyTimeSerie(params.stream!, params.parameter!),
        }));
    },

    getTimeSeriesMultiple: (
      params: TimeseriesParams[],
    ): Promise<{ data: TimeseriesResponseData }[]> => {
      const bundeledSeries = params.map((param) => {
        return axiosInstance.get('/timeseries/data').then(() => ({
          data: getDummyTimeSerie(param.stream!, param.parameter!),
        }));
      });
      return Promise.all(bundeledSeries);
    },

    getStreams: (
      params: StreamParams,
      requestId?: string,
    ): Promise<{ data: StreamResponse[] }> => {
      cancelRequestById(requestId!);
      return axiosInstance.get('/timeseries/streams', {
        params,
      });
    },

    getBulletin: (bulletinId?: string): Promise<{ data: Bulletin }> =>
      axiosInstance.get('/fakeBulletin').then(() => {
        return {
          data: fetchBulletin(bulletinId),
        };
      }),

    getBulletinHistory: (): Promise<{ data: Bulletin[] }> =>
      axiosInstance.get('/fakeBulletinHistory').then(() => ({
        data: fakeBulletins,
      })),

    getEventList: (params: EventCategoryParams): Promise<{ data: SWEvent[] }> =>
      axiosInstance.get('/notification/eventList').then(() => ({
        data: fetchEventList(params),
      })),

    getEvent: (eventid: string): Promise<{ data: SWEvent }> =>
      axiosInstance.get(`/event/${eventid}`).then(() => ({
        data: fetchEvent(eventid),
      })),

    // POST
    issueNotification: (formData: SWNotification): Promise<void> => {
      // eslint-disable-next-line no-console
      console.log(formData);
      return axiosInstance.post('/store', { ...formData });
    },
    discardDraftNotification: (
      params: DiscardDraftNotification,
    ): Promise<void> => {
      return axiosInstance.post('/discard', { params });
    },
    setAcknowledged: (eventid: string): Promise<void> => {
      return axiosInstance.post(`/acknowledged/${eventid}`);
    },
    getNewNotifications: (): Promise<{ data: SWEvent[] }> =>
      axiosInstance.get('/notification/newNotifications').then(() => ({
        data: fetchFakeNewNotifications(),
      })),
    getRePopulateTemplateContent: (
      formData: SWNotification,
    ): Promise<{ data: { title: string; message: string } }> => {
      // eslint-disable-next-line no-console
      console.log(formData);
      return axiosInstance.post('/notification/populate').then(() => ({
        data: {
          title: 'Template title for you',
          message: 'Template message text for you',
        },
      }));
    },
  };
  return api;
};

export const createApi = (): SpaceWeatherApi => {
  return getApiRoutes(createFakeApiInstance());
};

export const createFakeApiFixedDates = (): SpaceWeatherApi => {
  const fakeApi = createApi();
  const fakeAxiosInstance = createFakeApiInstance();
  return {
    ...fakeApi,
    getEvent: (eventid): Promise<{ data: SWEvent }> =>
      fakeAxiosInstance.get(`/event/${eventid}`).then(() => ({
        data: fetchEventFixedDate(eventid),
      })),
  };
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CapModule, capReducersMap } from '@opengeoweb/cap';
import {
  layerSelectTypes,
  layerSelectReducersMap,
  layerSelectMiddlewares,
} from '@opengeoweb/layer-select';

import {
  CoreAppStore,
  storeReducerMap,
  storeMiddlewares,
} from '@opengeoweb/store';
import {
  TimeSeriesModuleState,
  timeSeriesReducersMap,
  timeSeriesMiddlewares,
} from '@opengeoweb/timeseries';
import { warningsReducersMap, warningsMiddlewares } from '@opengeoweb/warnings';
import {
  WorkspaceModuleStore,
  workspaceReducersMap,
  workspaceMiddlewares,
} from '@opengeoweb/workspace';
import {
  DevToolsEnhancerOptions,
  combineReducers,
  Middleware,
  configureStore,
} from '@reduxjs/toolkit';
import { produce } from 'immer';
import { compose } from 'msw';
import * as Sentry from '@sentry/react';
import { snackbarMiddlewares, snackbarReducersMap } from '@opengeoweb/snackbar';
import { unifyReducerMaps } from './utils/unifyReducerMaps';

export interface AppStore
  extends WorkspaceModuleStore,
    CoreAppStore,
    layerSelectTypes.LayerSelectModuleState,
    TimeSeriesModuleState,
    CapModule {}

const devToolsStateSanitizer: DevToolsEnhancerOptions = {
  // @ts-expect-error
  stateSanitizer: (state: AppStore) => {
    return produce(state, (draftState) => {
      if (state['services']) {
        Object.values(state['services'].byId).forEach((service) => {
          if (draftState && draftState['services'] && service.id) {
            // @ts-expect-error
            draftState['services'].byId[service.id].layers =
              service.layers?.length;
          }
        });
      }
    });
  },
};

// ---> Sentry related code
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: AppStore) => {
    return produce(state, (draftState) => {
      if (state['services']) {
        Object.values(state['services'].byId).forEach((service) => {
          if (draftState && draftState['services'] && service.id) {
            // @ts-expect-error
            draftState['services'].byId[service.id].layers =
              service.layers?.length;
          }
        });
      }
    });
  },
});
// Sentry related code <--

const libraryReducerMaps = [
  storeReducerMap,
  capReducersMap,
  layerSelectReducersMap,
  timeSeriesReducersMap,
  warningsReducersMap,
  workspaceReducersMap,
  snackbarReducersMap,
];

const allReducers = unifyReducerMaps(libraryReducerMaps);
export const geowebRootReducer = combineReducers(allReducers);

export const geowebMiddlewares: Middleware[] = Array.from(
  new Set([
    ...storeMiddlewares,
    ...layerSelectMiddlewares,
    ...timeSeriesMiddlewares,
    ...warningsMiddlewares,
    ...workspaceMiddlewares,
    ...snackbarMiddlewares,
  ]),
);

const store = configureStore({
  reducer: geowebRootReducer,
  enhancers: (defaultEnhancers) => [
    compose(...defaultEnhancers, sentryReduxEnhancer),
  ],
  middleware: (getDefaultMiddleware) =>
    [
      ...getDefaultMiddleware({
        serializableCheck: {
          warnAfter: 128,
        },
        immutableCheck: {
          warnAfter: 128,
        },
      }),
    ].concat(geowebMiddlewares),
  devTools:
    process.env.NODE_ENV === 'development' ? devToolsStateSanitizer : false,
});

export { store };

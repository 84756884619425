/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  uiTypes,
  uiSelectors,
  layerActions,
  layerSelectors,
  CoreAppStore,
} from '@opengeoweb/store';
import { LayerInfoButton } from '@opengeoweb/webmap-react';

interface LayerInfoButtonProps {
  isLayerMissing?: boolean;
  mapId: string;
  serviceUrl: string;
  layerName: string;
  source?: uiTypes.Source;
}

export const LayerInfoButtonConnect: React.FC<LayerInfoButtonProps> = ({
  isLayerMissing,
  mapId,
  serviceUrl,
  layerName,
  source = 'app',
}: LayerInfoButtonProps) => {
  const dispatch = useDispatch();

  const isOpenInStore = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, uiTypes.DialogTypes.LayerInfo),
  );

  const currentLayerInfo = useSelector((store: CoreAppStore) =>
    layerSelectors.getActiveLayerInfo(store),
  );

  const isActive =
    layerName === currentLayerInfo?.layerName &&
    serviceUrl === currentLayerInfo?.serviceUrl &&
    isOpenInStore;

  const onClick = (): void => {
    if (!isActive) {
      dispatch(
        layerActions.showLayerInfo({
          layerName,
          serviceUrl,
          source,
          mapId,
        }),
      );
    } else {
      dispatch(layerActions.hideLayerInfo());
    }
  };

  return (
    <LayerInfoButton
      disabled={isLayerMissing}
      isActive={isActive}
      onClick={onClick}
    />
  );
};

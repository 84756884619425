/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { ExitDomain } from '@opengeoweb/theme';
import {
  CustomIconButton,
  ToolContainerDraggable,
  useDebounce,
  useWheelStopPropagation,
} from '@opengeoweb/shared';
import { layerTypes, uiTypes } from '@opengeoweb/store';
import { MapLocation } from '@opengeoweb/webmap-react';
import { getLayersToUpdate, GFILayer, GFIResult } from './utils';
import { sanitizeHTML } from '../../utils/sanitizeHTML';
import { useCoreTranslation } from '../../utils/i18n';

interface GetFeatureInfoDialogProps {
  layers: layerTypes.Layer[];
  isOpen: boolean;
  onClose: () => void;
  onMouseDown?: () => void;
  mapId: string;
  showMapId?: boolean;
  order?: number;
  source?: uiTypes.Source;
  mapPinLocation?: MapLocation;
}

interface UpdateLayerResultsParams {
  layerId: string;
  data: string;
  isLoading: boolean;
}

const GetFeatureInfoDialog: React.FC<GetFeatureInfoDialogProps> = ({
  layers,
  isOpen,
  onClose,
  onMouseDown = (): void => {},
  mapId,
  showMapId = false,
  order = 0,
  source = 'app',
  mapPinLocation,
}: GetFeatureInfoDialogProps) => {
  const { t } = useCoreTranslation();
  /* Ref and state for maplocation */
  const mapLocationWasChanged = React.useRef<boolean>(false);
  const [isInitialMapLocationChanged, setInitialMapLocationIsChanged] =
    React.useState<boolean>(false);

  /* Ref, state and update function for results per layer map */
  const gfiResultMap = React.useRef(new Map<string, GFIResult>()).current;
  const [, triggerRerender] = React.useState<string>();
  const updateLayerResult = ({
    layerId,
    data,
    isLoading,
  }: UpdateLayerResultsParams): void => {
    gfiResultMap.set(layerId, { data, isLoading });
    triggerRerender(`${layerId}${isLoading}`);
  };

  /* Build a layerlist array with a set of arguments per layer to do the query for */
  const layerToUpdateList: GFILayer[] = isOpen
    ? getLayersToUpdate(layers, mapId)
    : [];

  /* 
    Build a string based on the GFI urls. If this changes for whatever reason we need to update. 
    These strings can change very often, therefore they need to be debounced and only use the most recent result  must be used.
  */
  const debouncedlayerListString = useDebounce(
    layerToUpdateList.map((layer) => layer.url).join(','),
    100,
  );

  /* Do a GFI call to the server and use the updateLayerResult to provide the results. */
  const fetchLayerInfo = async (
    url: string,
    layerId: string,
  ): Promise<void> => {
    try {
      updateLayerResult({ layerId, data: '', isLoading: true });
      const { data } = await axios.get(url);
      updateLayerResult({ layerId, data, isLoading: false });
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'fetchLayerInfo failed';
      updateLayerResult({ layerId, data: errorMessage, isLoading: false });
    }
  };

  /*  Wait till the user has clicked the map. */
  React.useEffect(() => {
    if (mapLocationWasChanged.current !== false) {
      setInitialMapLocationIsChanged(true);
    }
    mapLocationWasChanged.current = true;
  }, [mapPinLocation]);

  /* For each changed getfeature url string, load new data using WMS GetFeatureInfo */
  React.useEffect(() => {
    if (isInitialMapLocationChanged && isOpen) {
      layerToUpdateList.forEach(({ url, layerId }) => {
        void fetchLayerInfo(url, layerId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedlayerListString]);

  const hasLayers = layers && layers.length > 0;

  const stopWheelEventPropagationRef =
    useWheelStopPropagation<HTMLDivElement>();

  return (
    <ToolContainerDraggable
      startPosition={{ right: 10, top: 80 }}
      minWidth={100}
      onClose={onClose}
      title={
        showMapId
          ? `${t('feature_info_marker_info')} ${mapId}`
          : t('feature_info_marker_info')
      }
      initialMaxHeight={440}
      isOpen={isOpen}
      data-testid="moveable-getfeatureinfo"
      bounds="parent"
      onMouseDown={onMouseDown}
      order={order}
      source={source}
    >
      <Box sx={{ padding: 0 }}>
        {isOpen && hasLayers && isInitialMapLocationChanged && (
          <Box
            data-testid="GetFeatureInfoList"
            ref={stopWheelEventPropagationRef}
          >
            {layerToUpdateList.map((layer) => {
              const { layerId, title, url } = layer;
              const result = gfiResultMap.get(layerId);
              if (!result) {
                return null;
              }

              return (
                <Grid
                  key={layerId}
                  sx={{
                    margin: '4px 6px 4px 6px',
                    padding: '6px',
                    backgroundColor: 'geowebColors.cards.cardContainer',
                    border: 'solid 1px',
                    borderColor: 'geowebColors.cards.cardContainerBorder',
                  }}
                >
                  <Typography variant="subtitle1">
                    {title}&nbsp;
                    <CustomIconButton
                      sx={{ float: 'right' }}
                      aria-label="Open layer featureinfo in another page"
                      onClick={(): void => {
                        window.open(url);
                      }}
                    >
                      <ExitDomain />
                    </CustomIconButton>
                  </Typography>

                  {!result.isLoading ? (
                    <div
                      style={{
                        fontSize: '11px',
                        padding: '6px',
                        margin: '0',
                        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                        fontWeight: '400',
                        lineHeight: '1.75',
                        letterSpacing: '0.25px',
                      }}
                      data-testid={`layer-result-ready-${layerId}`}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={sanitizeHTML(result.data)}
                    />
                  ) : (
                    <div data-testid={`layer-result-loading-${layerId}`}>
                      <CircularProgress data-testid="spinner" color="inherit" />
                    </div>
                  )}
                </Grid>
              );
            })}
          </Box>
        )}
        {hasLayers && !isInitialMapLocationChanged && (
          <Typography
            data-testid="click-on-map-for-info"
            variant="subtitle1"
            sx={{ padding: '10px' }}
          >
            {t('feature_info_title')}
          </Typography>
        )}
        {!hasLayers && (
          <Typography
            data-testid="NoLayers"
            variant="subtitle1"
            sx={{ padding: '10px' }}
          >
            {t('feature_info_no_layers')}
          </Typography>
        )}
      </Box>
    </ToolContainerDraggable>
  );
};

export default GetFeatureInfoDialog;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  GridComponentOption,
  XAXisComponentOption,
  SeriesOption,
  YAXisComponentOption,
  DefaultLabelFormatterCallbackParams,
  TooltipComponentOption,
  TooltipComponentFormatterCallback,
} from 'echarts';
import { isArray } from 'lodash';
import { dateUtils } from '@opengeoweb/shared';
import { curveColors } from './styles';
import { GraphItem } from './types';
import { mirrorSeries } from './createChartSeriesData';

export const dateFormat = 'MM-dd HH:mm';

export const TITLE_KP_FORECAST = 'Kp Index Forecast';
export const TITLE_XRAY = 'X-ray Solar Flux (W/m\u00B2)';
export const TTTLE_SOLAR_WIND_DENSITY = 'Solar Wind Density (1/cm\u00B3)';
export const TITLE_SOLAR_WIND_SPEED = 'Solar Wind Speed (km/s)';
export const TITLE_SOLAR_WIND_PRESSURE = 'Solar Wind Pressure (nPa)';

export const CHART_LEFT_MARGIN = 32; // space for axis labels
export const CHART_RIGHT_MARGIN = 52; // space for axis labels
export const CHART_TOP_MARGIN = 20; // space for chart title
export const PLOT_TOP_MARGIN = 24; // space for graph title
export const CHART_BOTTOM_MARGIN = 48; // space for zoombar
export const DATA_ZOOM_LABEL_WIDTH = 76; // space for data zoom labels

// config
export const config: GraphItem[] = [
  // 1 Kp Index
  {
    id: '0-kp-barGraph',
    title: 'Kp Index',
    graphType: 'BAR',
    params: [
      {
        stream: 'kp',
        parameter: 'kp',
      },
      {
        stream: 'kpforecast',
        parameter: 'kpforecast',
      },
    ],
    columns: ['kpIndex', 'kpIndexForecast'],
    yMinValue: 0,
    yMaxValue: 9,
    threshold: [
      { title: 'Met Office', value: 5 },
      { title: 'KNMI', value: 7 },
    ],
  },
  // 2 Interplanetary Magnetic Field Bt and Bz (nT)
  {
    id: '1-rtsw-bandGraph',
    title: 'Interplanetary Magnetic Field Bt and Bz (nT)',
    graphType: 'BAND',
    params: [
      { stream: 'rtsw_mag', parameter: 'bt' },
      { stream: 'rtsw_mag', parameter: 'bz_gsm' },
    ],
    columns: ['magneticFieldBt', 'magneticFieldBz'],
    threshold: [{ title: '', value: -20 }],
    yMinValue: -25,
    yMaxValue: 25,
  },
  // 3 X-ray Solar Flux
  {
    id: '2-xray-logarithmicGraph',
    title: TITLE_XRAY,
    graphType: 'LOG',
    params: [
      {
        stream: 'xray',
        parameter: 'solar_x_ray_flux_long',
      },
    ],
    columns: ['lineSolarXray'],
    threshold: [{ title: '', value: '5e-5' }],
    yMinValue: 1e-9,
    yMaxValue: 1e-2,
    tickValues: (value: number): string => {
      switch (value) {
        case 1e-9:
          return '10^-9';
        case 1e-8:
          return '10^-8';
        case 1e-7:
          return '10^-7';
        case 1e-6:
          return '10^-6';
        case 1e-5:
          return '10^-5';
        case 1e-4:
          return '10^-4';
        case 1e-3:
          return '10^-3';
        case 1e-2:
          return '10^-2';
        default:
          return '';
      }
    },
  },
  // 4 Solar Wind Speed (km/s)
  {
    id: '3-wind-areaGraph',
    title: TITLE_SOLAR_WIND_SPEED,
    graphType: 'AREA',
    params: [{ stream: 'rtsw_wind', parameter: 'proton_speed' }],
    columns: [['lineSolarWindSpeed', 'areaSolarWindSpeed']],
    yMinValue: 200,
    yMaxValue: 800,
  },
  // 5 Solar Wind Density (nPa)
  {
    id: '4-wind-density-logarithmicGraph',
    title: TTTLE_SOLAR_WIND_DENSITY,
    graphType: 'LOG',
    params: [
      {
        stream: 'rtsw_wind',
        parameter: 'proton_density',
      },
    ],
    columns: ['lineSolarWindDensity'],
    yMinValue: 1,
    yMaxValue: 100,
  },
  // 6 Solar Wind Pressure (nPa)
  {
    id: '5-wind-pressure-areaGraph',
    title: TITLE_SOLAR_WIND_PRESSURE,
    graphType: 'AREA',
    params: [
      {
        stream: 'rtsw_wind',
        parameter: 'proton_pressure',
      },
    ],
    columns: [['lineSolarWindPressure', 'areaSolarWindPressure']],
    yMinValue: 0,
    yMaxValue: 15,
  },
];

export const getGrid = (
  data: GraphItem[],
  graphHeight: number,
): GridComponentOption[] => {
  const grid = data.map((_, index) => {
    const top =
      index === 0
        ? CHART_TOP_MARGIN
        : CHART_TOP_MARGIN + PLOT_TOP_MARGIN * index + graphHeight * index;

    return {
      left: `${CHART_LEFT_MARGIN}px`,
      right: `${CHART_RIGHT_MARGIN}px`,
      height: `${graphHeight}px`,
      top: `${top}px`,
    };
  });
  return grid;
};

export const getCurrentTimeMarkers = (data: GraphItem[]): SeriesOption[] => {
  const now = dateUtils.utc();
  return data.map((_, index) => {
    return {
      name: 'current_time',
      animation: false,
      type: 'line',
      markLine: {
        silent: true,
        label: {
          show: false,
        },
        data: [{ xAxis: now as unknown as string }],
        symbol: 'none',
        color: curveColors.currentTime,
      },
      xAxisIndex: index,
      yAxisIndex: index,
    };
  });
};

export const getXAxis = (
  data: GraphItem[],
  beginTime: Date,
  endTime: Date,
): XAXisComponentOption[] => {
  const xAxis: XAXisComponentOption = {
    name: 'UTC time',
    nameLocation: 'end',
    nameGap: 4,
    type: 'time',
    min: beginTime,
    max: endTime,
    axisLabel: {
      formatter: (params: number): string => {
        return dateUtils.dateToString(new Date(params), dateFormat)!;
      },
    },
    axisLine: {
      onZero: false,
    },
  };

  return data.map((_, index): XAXisComponentOption => {
    if (index === data.length - 1) {
      return {
        ...xAxis,
        gridIndex: index,
      };
    }
    return {
      ...xAxis,
      gridIndex: index,
      axisLabel: { show: false },
      axisTick: { show: false },
      name: '',
    };
  });
};

export const getYAxis = (data: GraphItem[]): YAXisComponentOption[] => {
  return data.map((graphItem, index) => {
    return {
      type: graphItem.graphType === 'LOG' ? 'log' : 'value',
      axisLine: { show: true },
      axisTick: { show: true },
      splitLine: { show: false },
      nameTextStyle: { align: 'left' },
      min: graphItem.yMinValue,
      max: graphItem.yMaxValue,
      name: graphItem.title,
      nameGap: 1,
      gridIndex: index,
      axisLabel: {
        formatter: (value: number): string | number => {
          if (graphItem.tickValues) {
            return graphItem.tickValues(value);
          }
          return value;
        },
      } as YAXisComponentOption['axisLabel'],
    };
  });
};

export const getSeries = (data: GraphItem[]): SeriesOption[] => {
  const kp = data.find((graphItem) => graphItem.id === config[0].id);
  const magnetic = data.find((graphItem) => graphItem.id === config[1].id);
  const xray = data.find((graphItem) => graphItem.id === config[2].id);
  const windspeed = data.find((graphItem) => graphItem.id === config[3].id);
  const winddensity = data.find((graphItem) => graphItem.id === config[4].id);
  const windpressure = data.find((graphItem) => graphItem.id === config[5].id);

  const kpSeries = kp
    ? [
        {
          name: kp.title,
          type: 'bar',
          barMaxWidth: 4,
          data: kp.series ? kp.series[0] : [],
          itemStyle: {
            color: curveColors.kpIndex,
            opacity: 0.8,
          },
          markLine: {
            silent: true,
            animation: false,
            label: {
              position: 'insideStartTop',
              formatter: kp.threshold![0].title,
              distance: [5, 1],
              color: curveColors.markLines,
              opacity: 0.67,
            },
            data: [{ yAxis: kp.threshold![0].value }],
            symbol: 'none',
            lineStyle: { color: curveColors.markLines, opacity: 0.67 },
          },
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
        {
          name: TITLE_KP_FORECAST,
          type: 'bar',
          barMaxWidth: 4,
          barGap: '-100%',
          barCategoryGap: '10%',
          data: kp.series ? kp.series[1] : [],
          itemStyle: {
            color: curveColors.kpIndexForecast,
            opacity: 0.75,
          },
          markLine: {
            silent: true,
            animation: false,
            label: {
              position: 'insideStartTop',
              formatter: kp.threshold![1].title,
              distance: [5, 1],
              color: curveColors.markLines,
              opacity: 0.67,
            },
            data: [{ yAxis: kp.threshold![1].value }],
            symbol: 'none',
            lineStyle: { color: curveColors.markLines, opacity: 0.67 },
          },
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
      ]
    : [];

  const magneticSeries = magnetic
    ? [
        {
          name: 'Magnetic Field Bt (nT)',
          data: magnetic.series ? magnetic.series[0] : [],
          type: 'line',
          smooth: true,
          areaStyle: {
            color: curveColors.magneticFieldBt,
            opacity: 0.5,
          },
          lineStyle: { opacity: 0 },
          itemStyle: { opacity: 0, color: curveColors.magneticFieldBt },
          tooltip: {
            valueFormatter: (value: number): string =>
              value ? `${value} nT` : '-',
          },
          markLine: {
            silent: true,
            animation: false,
            label: { show: false },
            data: [{ yAxis: magnetic.threshold![0].value }],
            symbol: 'none',
            lineStyle: { color: curveColors.markLines, opacity: 0.67 },
          },
          xAxisIndex: 1,
          yAxisIndex: 1,
        },
        {
          name: magnetic.columns[0],
          data: magnetic.series ? mirrorSeries(magnetic.series[0]) : [],
          type: 'line',
          smooth: true,
          areaStyle: {
            color: curveColors.magneticFieldBt,
            opacity: 0.5,
          },
          lineStyle: { opacity: 0 },
          itemStyle: { opacity: 0, color: curveColors.magneticFieldBt },
          tooltip: { show: false },
          xAxisIndex: 1,
          yAxisIndex: 1,
        },
        {
          name: 'Magnetic Field Bz (nT)',
          data: magnetic.series ? magnetic.series[1] : [],
          type: 'line',
          smooth: true,
          areaStyle: {
            color: curveColors.magneticFieldBz,
          },
          lineStyle: { opacity: 0 },
          itemStyle: { opacity: 0, color: curveColors.magneticFieldBz },
          tooltip: {
            valueFormatter: (value: number): string =>
              value ? `${value} nT` : '-',
          },
          xAxisIndex: 1,
          yAxisIndex: 1,
        },
      ]
    : [];

  const xraySeries = xray
    ? [
        {
          name: xray.title,
          data: xray.series ? xray.series[0] : [],
          type: 'line',
          lineStyle: { color: curveColors.lineSolarXray },
          itemStyle: { opacity: 0, color: curveColors.lineSolarXray },
          tooltip: {
            valueFormatter: (value: number): string =>
              value ? `${value} W/m2` : '-',
          },
          xAxisIndex: 2,
          yAxisIndex: 2,
          markLine: {
            silent: true,
            animation: false,
            label: { show: false },
            data: [{ yAxis: xray.threshold![0].value }],
            symbol: 'none',
            lineStyle: { color: curveColors.markLines, opacity: 0.67 },
          },
        },
      ]
    : [];

  const windspeedSeries = windspeed
    ? [
        {
          name: windspeed.title,
          data: windspeed.series ? windspeed.series[0] : [],
          type: 'line',
          areaStyle: {
            color: curveColors.areaSolarWindSpeed,
            opacity: 0.5,
          },
          lineStyle: { color: curveColors.lineSolarWindSpeed },
          itemStyle: { opacity: 0, color: curveColors.lineSolarWindSpeed },
          tooltip: {
            valueFormatter: (value: number): string =>
              value ? `${value} km/s` : '-',
          },
          xAxisIndex: 3,
          yAxisIndex: 3,
        },
      ]
    : [];

  const winddensitySeries = winddensity
    ? [
        {
          name: winddensity.title,
          data: winddensity.series ? winddensity.series[0] : [],
          type: 'line',
          lineStyle: { color: curveColors.lineSolarWindDensity },
          itemStyle: { opacity: 0, color: curveColors.lineSolarWindDensity },
          tooltip: {
            valueFormatter: (value: number): string =>
              value ? `${value} 1/cm3` : '-',
          },
          xAxisIndex: 4,
          yAxisIndex: 4,
        },
      ]
    : [];

  const windpressureSeries = windpressure
    ? [
        {
          name: windpressure.title,
          data: windpressure.series ? windpressure.series[0] : '',
          type: 'line',
          areaStyle: {
            color: curveColors.areaSolarWindPressure,
            opacity: 0.5,
          },
          lineStyle: { color: curveColors.lineSolarWindPressure },
          itemStyle: { opacity: 0, color: curveColors.lineSolarWindPressure },
          tooltip: {
            valueFormatter: (value: number): string =>
              value ? `${value} nPa` : '-',
          },
          xAxisIndex: 5,
          yAxisIndex: 5,
        },
      ]
    : [];

  return [
    ...kpSeries,
    ...magneticSeries,
    ...xraySeries,
    ...windspeedSeries,
    ...winddensitySeries,
    ...windpressureSeries,
  ] as SeriesOption[];
};

export const getTooltipValue = (
  date: string,
  param: DefaultLabelFormatterCallbackParams,
): string => {
  const value = isArray(param.data) && param.data[1];
  if (!value) {
    return '-';
  }
  if (
    param.seriesName !== TITLE_KP_FORECAST &&
    dateUtils.isAfter(dateUtils.utc(date), dateUtils.utc())
  ) {
    // when in future only show values for Kp Index Forecast
    return '-';
  }
  if (
    param.seriesName === TITLE_XRAY ||
    param.seriesName === TTTLE_SOLAR_WIND_DENSITY
  ) {
    return parseFloat(value.toString()).toPrecision(3);
  }
  if (
    param.seriesName === TITLE_SOLAR_WIND_SPEED ||
    param.seriesName === TITLE_SOLAR_WIND_PRESSURE
  ) {
    return parseFloat(value.toString()).toPrecision(4);
  }
  return value.toString();
};

export const getTooltip = (): TooltipComponentOption => {
  const formatter: TooltipComponentFormatterCallback<
    DefaultLabelFormatterCallbackParams | DefaultLabelFormatterCallbackParams[]
  > = (
    params:
      | DefaultLabelFormatterCallbackParams
      | DefaultLabelFormatterCallbackParams[],
  ): string => {
    if (Array.isArray(params)) {
      const date: string = Array.isArray(params[0].data)
        ? (params[0].data[0] as string)
        : '';
      const timeLabel = dateUtils.dateToString(
        dateUtils.utc(date),
        dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH,
      );

      const paramLabels = params
        .sort((a, b) => a.seriesIndex! - b.seriesIndex!)
        .map((param) => {
          const value = getTooltipValue(date, param);
          return `<div style="width:288px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color: ${param.color}"></span><span>${param.seriesName}:</span><span style="float:right;margin-left:12px;font-weight:600">${value}</span></div>`;
        })
        .join('');

      return `<b>${timeLabel}</b>${paramLabels}`;
    }

    const date: string = Array.isArray(params.data)
      ? (params.data[0] as string)
      : '';
    const timeLabel = dateUtils.dateToString(
      dateUtils.utc(date),
      dateUtils.DATE_FORMAT_NAME_OF_DAY_MONTH,
    );

    const value = getTooltipValue(date, params);
    const paramLabel = `<div style="width:288px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color: ${params.color}"></span><span>${params.seriesName}:</span><span style="float:right;margin-left:12px;font-weight:600">${value}</span></div>`;

    return `<b>${timeLabel}</b>${paramLabel}`;
  };
  return {
    trigger: 'axis',
    formatter,
  };
};

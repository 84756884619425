/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useWebmapReactTranslation } from '../../utils/i18n';
import { DimensionConfig, dimensionConfig, getDimensionIcon } from './utils';
import { MapControlButton } from '../MapControls';

interface DimensionSelectButtonProps {
  dimension: string;
  onClickDimensionButton: () => void;
  isActive?: boolean;
}

const DimensionSelectButton: React.FC<DimensionSelectButtonProps> = ({
  dimension,
  onClickDimensionButton,
  isActive = false,
}: DimensionSelectButtonProps) => {
  const { t } = useWebmapReactTranslation();
  const showPrefix = t('webmap-react-show');
  const dimConfig: DimensionConfig = dimensionConfig(t).find(
    (cnf) => cnf.name === dimension,
  )!;
  const Icon = getDimensionIcon(
    (dimConfig && dimConfig.iconType) || 'IconLevels',
  );

  const title = `${showPrefix} ${
    dimConfig ? dimConfig.label.toLowerCase() : dimension.toLowerCase()
  }`;

  return (
    <MapControlButton
      title={title}
      data-testid={`dimensionMapBtn-${dimension}`}
      onClick={onClickDimensionButton}
      isActive={isActive}
    >
      <Icon />
    </MapControlButton>
  );
};

export default DimensionSelectButton;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import type { WMJSDimension, IWMJSMap, WMLayer } from '../components';

let generatedLayerIds = 0;
export const generateLayerId = (): string => {
  generatedLayerIds += 1;
  return `layerid_${generatedLayerIds}`;
};
let generatedMapIds = 0;
export const generateMapId = (): string => {
  generatedMapIds += 1;
  return `mapid_${generatedMapIds}`;
};
let generatedTimesliderIds = 0;
export const generateTimesliderId = (): string => {
  generatedTimesliderIds += 1;
  return `timesliderid_${generatedTimesliderIds}`;
};
/**
 * Map for registering wmlayers with their id's
 */
const registeredWMLayersForReactLayerId: Record<string, WMLayer> = {};
/**
 * Registers a WMJSLayer in a lookuptable with a layerId
 * @param {WMLayer} wmLayer
 * @param {string} layerId
 */

export const registerWMLayer = (wmLayer: WMLayer, layerId: string): void => {
  registeredWMLayersForReactLayerId[layerId] = wmLayer;
};
/**
 * Get the WMLayer from the lookuptable with layerId
 * @param {string} layerId
 */

export const getWMLayerById = (layerId: string): WMLayer => {
  return registeredWMLayersForReactLayerId[layerId];
};

export const unRegisterWMJSLayer = (layerId: string): void => {
  const layer: WMLayer = registeredWMLayersForReactLayerId[layerId];
  if (layer) {
    delete registeredWMLayersForReactLayerId[layerId];
  }
};

export const unRegisterAllWMJSLayersAndMaps = (): void => {
  const allLayerIds = Object.keys(registeredWMLayersForReactLayerId);

  allLayerIds.forEach((layerId: string) => {
    unRegisterWMJSLayer(layerId);
  });

  const allMapIds = Object.keys(registeredWMMapForReactMapId);

  allMapIds.forEach((mapId: string) => {
    unRegisterWMJSMap(mapId);
  });
};
/**
 * Map for registering wmlayers with their id's
 */
const registeredWMMapForReactMapId: Record<string, IWMJSMap> = {};
/**
 * Registers a IWMJSMap in a lookuptable with a wmjsMapId
 * @param {IWMJSMap} wmjsMap
 * @param {string} wmjsMapId
 */

export const registerWMJSMap = (wmjsMap: IWMJSMap, wmjsMapId: string): void => {
  if (registeredWMMapForReactMapId[wmjsMapId]) {
    console.warn(`Map with id ${wmjsMapId} already made`);
  }
  registeredWMMapForReactMapId[wmjsMapId] = wmjsMap;
};

export const unRegisterWMJSMap = (wmjsMapId: string): void => {
  const wmjsMap: IWMJSMap = registeredWMMapForReactMapId[wmjsMapId];
  if (wmjsMap) {
    wmjsMap.getListener().suspendEvents();
    try {
      wmjsMap.stopAnimating && wmjsMap.stopAnimating();
    } catch (e) {
      console.warn(e);
    }
    Object.keys(registeredWMLayersForReactLayerId).forEach((layerId) => {
      const wmLayer = getWMLayerById(layerId);

      if (wmLayer.parentMap === wmjsMap) {
        unRegisterWMJSLayer(layerId);
      }
    });

    wmjsMap.destroy();
    delete registeredWMMapForReactMapId[wmjsMapId];
  }
};
/**
 * Get the wmjsMap from the lookuptable with wmjsMapId
 * @param {string} wmjsMapId
 */

export const getWMJSMapById = (wmjsMapId: string): IWMJSMap | undefined => {
  return registeredWMMapForReactMapId[wmjsMapId];
};

/**
 * Get all wmjsMap id's from the lookuptable with wmjsMapId
 * @param {string} wmjsMapId
 */

export const getWMJSMapIds = (): string[] => {
  return Object.keys(registeredWMMapForReactMapId);
};
/**
 * Returns the WMJSDimension object for given layerId and dimension name
 * @param layerId The layerId
 * @param dimensionName The dimension to lookup
 */

export const getWMJSDimensionForLayerAndDimension = (
  layerId: string,
  dimensionName: string | undefined,
): WMJSDimension | undefined => {
  const wmLayer = getWMLayerById(layerId);
  if (!wmLayer || !dimensionName) {
    return undefined;
  }
  const wmjsDimension = wmLayer.getDimension(dimensionName);
  if (!wmjsDimension) {
    return undefined;
  }
  return wmjsDimension;
};
/**
 * Gets the WMJSTimeDimension for given activeLayerId and dimensions list
 * @param layerId: The layer id to search the WMJSDimension for
 * @return: The WMJSDimension if found, otherwise null
 */

export const getWMJSTimeDimensionForLayerId = (
  layerId: string,
): WMJSDimension => {
  const wmLayer = getWMLayerById(layerId);
  if (!wmLayer) {
    return null!;
  }
  return wmLayer.getDimension('time')!;
};

/**
 * Clears the image store for all maps
 */
export const clearImageCacheForAllMaps = (): void => {
  getWMJSMapIds().forEach((id) => {
    const map = getWMJSMapById(id);
    if (map && !map.isDestroyed) {
      map.clearImageCache();
    }
  });
};

export const roundWithTimeStep = (
  unixTime: number,
  timeStep: number,
  type?: string,
): number => {
  const adjustedTimeStep = timeStep * 60;
  if (!type || type === 'round') {
    return Math.round(unixTime / adjustedTimeStep) * adjustedTimeStep;
  }
  if (type === 'floor') {
    return Math.floor(unixTime / adjustedTimeStep) * adjustedTimeStep;
  }
  if (type === 'ceil') {
    return Math.ceil(unixTime / adjustedTimeStep) * adjustedTimeStep;
  }
  return undefined!;
};

/* Adds DIM_ for certain dims */
export const getCorrectWMSDimName = (origDimName: string): string => {
  /* Adds DIM_ for dimensions other than height or time */
  const upperCaseDimName = origDimName.toUpperCase();
  if (upperCaseDimName === 'TIME') {
    return upperCaseDimName;
  }
  if (upperCaseDimName === 'ELEVATION') {
    return upperCaseDimName;
  }
  return `DIM_${upperCaseDimName}`;
};

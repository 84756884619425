/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/system';
import {
  CustomIconButton,
  CustomTooltip,
  ErrorBoundary,
} from '@opengeoweb/shared';
import { useCoreTranslation } from '../../utils/i18n';

interface CircleProps {
  isRecording: boolean;
}

const Circle = styled('div')<CircleProps>(({ isRecording }) => ({
  width: '70%',
  height: '70%',
  borderRadius: '50%',
  backgroundColor: isRecording ? 'red' : 'gray',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const SentryRecordButton: React.FC = () => {
  const { t } = useCoreTranslation();
  const [isRecording, setIsRecording] = React.useState(false);

  const replay = Sentry.getReplay();

  const toggleRecord = async (): Promise<void> => {
    if (!isRecording) {
      replay?.start();
      setIsRecording(true);
    } else {
      setIsRecording(false);
      await replay?.stop();
    }
  };

  const title = isRecording
    ? t('sentry-stop-recording')
    : t('sentry-start-recording');

  return (
    <ErrorBoundary handlePromiseRejections displayOnTop>
      <CustomTooltip title={title}>
        <CustomIconButton
          variant="flat"
          onClick={toggleRecord}
          data-testid="sentryRecordButton"
          sx={{ margin: '10px', border: 'solid 1px gray' }}
        >
          <Circle isRecording={isRecording} />
        </CustomIconButton>
      </CustomTooltip>
    </ErrorBoundary>
  );
};

export default SentryRecordButton;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import {
  Button,
  Grid2 as Grid,
  LinearProgress,
  List,
  ListItemButton,
  Box,
} from '@mui/material';
import { AlertBanner } from '@opengeoweb/shared';
import ProductListRow from './ProductListRow';
import {
  AirmetFromBackend,
  ProductConfig,
  ProductType,
  SigmetFromBackend,
} from '../../types';
import { useSigmetAirmetTranslation } from '../../utils/i18n';

interface ProductListProps {
  productType: ProductType;
  productList: SigmetFromBackend[] | AirmetFromBackend[];
  productConfig: ProductConfig;
  isLoading: boolean;
  error: Error;
  onClickProductRow: (product: SigmetFromBackend | AirmetFromBackend) => void;
  onClickNewProduct: () => void;
}

const ProductList: React.FC<ProductListProps> = ({
  productType,
  productList,
  productConfig,
  isLoading,
  error,
  onClickProductRow,
  onClickNewProduct,
}: ProductListProps) => {
  const { t } = useSigmetAirmetTranslation();

  return (
    <>
      {isLoading && (
        <LinearProgress
          data-testid="productlist-loadingbar"
          color="secondary"
          sx={{
            position: 'absolute',
            width: '100%',
            maxWidth: 'inherit',
            left: 0,
            top: 0,
          }}
        />
      )}
      {error && (
        <Box
          sx={{
            marginBottom: 2,
            ' .MuiAlert-message': { whiteSpace: 'pre-wrap' },
          }}
        >
          <AlertBanner
            severity="error"
            title={error.name}
            info={error.message ? error.message : ''}
            dataTestId="productList-alert"
          />
        </Box>
      )}
      <Grid container direction="column" alignItems="stretch">
        <Grid container justifyContent="flex-end">
          <Button
            variant="primary"
            data-testid="productListCreateButton"
            onClick={onClickNewProduct}
            sx={{ textTransform: 'initial' }}
          >
            {t('create-new-product', {
              product: productType.toUpperCase(),
            })}
          </Button>
        </Grid>
        <Grid
          sx={{
            width: '100%',
            minHeight: '600px',
          }}
        >
          {productList && (
            <List sx={{ paddingTop: 4, containerType: 'inline-size' }}>
              {(productList as (SigmetFromBackend | AirmetFromBackend)[]).map(
                (productListItem) => {
                  const product =
                    productType === 'sigmet'
                      ? (productListItem as SigmetFromBackend)[productType]
                      : (productListItem as AirmetFromBackend)[productType];
                  return (
                    <ListItemButton
                      data-testid="productListItem"
                      onClick={(): void => {
                        onClickProductRow(productListItem);
                      }}
                      key={product.uuid}
                      sx={{ padding: 0.25 }}
                    >
                      <ProductListRow
                        product={product}
                        productType={productType}
                        productConfig={productConfig}
                      />
                    </ListItemButton>
                  );
                },
              )}
            </List>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ProductList;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CoreAppStore,
  mapActions,
  mapEnums,
  mapSelectors,
} from '@opengeoweb/store';
import { SpeedButton } from '@opengeoweb/timeslider';

interface SpeedButtonConnectProps {
  mapId: string;
}

export const SpeedButtonConnect = ({
  mapId,
}: SpeedButtonConnectProps): React.ReactElement => {
  const animationDelay = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapAnimationDelay(store, mapId),
  );
  const dispatch = useDispatch();
  const onSetAnimationDelay = (animationDelay: number): void => {
    dispatch(
      mapActions.setAnimationDelay({
        mapId,
        animationDelay,
        origin: mapEnums.MapActionOrigin.map,
      }),
    );
  };

  return (
    <SpeedButton
      animationDelay={animationDelay!}
      setMapAnimationDelay={onSetAnimationDelay}
    />
  );
};

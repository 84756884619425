/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { GeometryObject } from 'geojson';
import MapDraw, { DrawModeExitCallback, FeatureEvent } from './MapDraw';

export interface FeatureLayer {
  id: string;
  type: string;
  features: [];
  isInEditMode?: boolean;
  isInDeleteMode?: boolean;
  geojson?: GeoJSON.FeatureCollection;
  drawMode?: string;
  onHoverFeature?: (feature: FeatureEvent) => void;
  onClickFeature?: (feature: FeatureEvent) => void;
  updateGeojson?: (feature: GeometryObject, reason: string) => void;
  exitDrawModeCallback?: (
    reason: DrawModeExitCallback,
    newGeoJSON?: GeoJSON.FeatureCollection,
  ) => void;
  selectedFeatureIndex?: string;
}

interface MapDrawContainerProps {
  featureLayers: FeatureLayer[];
  linkedFeatures?: GeoJSON.FeatureCollection;
  mapId: string;
}

export const MapDrawContainer: React.FC<MapDrawContainerProps> = ({
  featureLayers,
  linkedFeatures,
  mapId,
}: MapDrawContainerProps) => (
  <>
    {featureLayers.map((layer) => {
      return (
        <div
          data-testid={`featureLayer-${layer.id}`}
          key={layer.id}
          style={{ display: 'none' }}
        >
          <MapDraw
            geojson={layer.geojson!}
            linkedFeatures={linkedFeatures}
            isInEditMode={layer.isInEditMode}
            isInDeleteMode={layer.isInDeleteMode}
            drawMode={layer.drawMode!}
            mapId={mapId}
            onHoverFeature={(hoverInfo): void => {
              if (layer.onHoverFeature) {
                layer.onHoverFeature(hoverInfo);
              }
            }}
            onClickFeature={(hoverInfo): void => {
              if (layer.onClickFeature) {
                layer.onClickFeature(hoverInfo);
              }
            }}
            updateGeojson={(geojson, reason): void => {
              if (layer.updateGeojson) {
                layer.updateGeojson(geojson, reason);
              }
            }}
            exitDrawModeCallback={(reason, newGeoJSON): void => {
              if (layer.exitDrawModeCallback) {
                layer.exitDrawModeCallback(reason, newGeoJSON);
              }
            }}
            selectedFeatureIndex={parseInt(
              layer.selectedFeatureIndex || '0',
              10,
            )}
          />
        </div>
      );
    })}
  </>
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  Box,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { debounce } from 'lodash';
import { CustomIconButton } from '../CustomIconButton';
import { useSharedTranslation } from '../../utils/i18n';

interface Props {
  optionId: string;
  checked: boolean;
  onClick: (checked: boolean, only?: boolean) => void;
  labelStyle?: SxProps<Theme>;
  label: ReactNode;
}

export const FilterOption: React.FC<Props> = ({
  optionId,
  checked,
  onClick,
  labelStyle,
  label,
}: Props) => {
  const { t } = useSharedTranslation();
  const [showOnly, setShowOnly] = useState(false);
  const updateShowOnly = debounce((show) => setShowOnly(show), 100);

  return (
    <ListItem
      disablePadding
      dense
      sx={{
        width: '100%',
        minWidth: '300px',
        paddingRight: '0px',
        marginLeft: '-12px',
        fontSize: '12px',
      }}
      secondaryAction={
        showOnly && (
          <CustomIconButton
            aria-label={`Only ${label}`}
            style={{ backgroundColor: 'transparent', width: 'auto' }}
            edge="end"
            onClick={() => onClick(!checked, true)}
            onMouseOver={() => updateShowOnly(true)}
            onMouseOut={() => updateShowOnly(false)}
          >
            <Typography
              sx={{
                color: 'geowebColors.buttons.primary.default.fill',
                fontSize: '14px',
                fontWeight: 500,
              }}
            >
              {t('shared-only')}
            </Typography>
          </CustomIconButton>
        )
      }
    >
      <ListItemButton
        role={undefined}
        onClick={() => onClick(!checked)}
        onMouseOver={() => updateShowOnly(true)}
        onMouseOut={() => updateShowOnly(false)}
        dense
        disableGutters
        sx={{
          padding: 0,
        }}
      >
        <ListItemIcon sx={{ width: '42px' }}>
          <Checkbox
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{
              'aria-labelledby': optionId,
              'aria-label': optionId,
            }}
            name={optionId}
          />
        </ListItemIcon>
        <ListItemText
          id={optionId}
          primary={<Box sx={{ ...labelStyle }}>{label}</Box>}
        />
      </ListItemButton>
    </ListItem>
  );
};

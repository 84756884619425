/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { CustomIconButton } from '@opengeoweb/shared';
import { useDispatch, useSelector } from 'react-redux';

import { Delete } from '@opengeoweb/theme';
import {
  CoreAppStore,
  layerActions,
  layerSelectors,
  layerTypes,
  mapSelectors,
} from '@opengeoweb/store';
import { useCoreTranslation } from '../../../../../utils/i18n';

interface DeleteLayerProps {
  layerId: string;
  mapId: string;
  tooltipTitle?: string;
  icon?: React.ReactNode;
}

const DeleteLayerConnect: React.FC<DeleteLayerProps> = ({
  layerId,
  mapId,
  tooltipTitle,
  icon = <Delete />,
}: DeleteLayerProps) => {
  const { t } = useCoreTranslation();
  const dispatch = useDispatch();
  const nonEmptyTooltipTitle = tooltipTitle ?? t('layermanager-layer-delete');

  const layerIndex = useSelector((store: CoreAppStore) =>
    mapSelectors.getLayerIndexByLayerId(store, mapId, layerId),
  );

  const layerDelete = React.useCallback(
    ({ mapId, layerId }: DeleteLayerProps) =>
      dispatch(
        layerActions.layerDelete({
          mapId,
          layerId,
          layerIndex,
          origin: layerTypes.LayerActionOrigin.layerManager,
        }),
      ),
    [dispatch, layerIndex],
  );
  const isLayerEnabled = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerEnabled(store, layerId),
  );
  return (
    <CustomIconButton
      tooltipTitle={nonEmptyTooltipTitle}
      onClick={(): void => {
        layerDelete({ mapId, layerId });
      }}
      shouldShowAsDisabled={!isLayerEnabled}
      data-testid="deleteButton"
    >
      {icon}
    </CustomIconButton>
  );
};

/**
 * Deletes a maplayer from the store
 *
 * Expects the following props:
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} layerId layerId: string - Id of the layer that is deleted
 * @example
 * ``` <DeleteLayerConnect mapId={mapId} layerId={layerId} />```
 */
export default DeleteLayerConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { fakeMaxAgeResponsesByLayer } from '../utils/fakeMaxAgeResponses';
import { debugLogger, DebugType, isDefined } from './WMJSTools';

export interface WMImageOptions {
  randomizer?: boolean;
  headers: Headers[];
  fakeMaxAge?: number;
}

export interface WMGeoReference {
  extent: number[];
  resolution: number;
}

/**
 * Returns the current time in ms
 * @returns the current time in ms
 */
export const getCurrentImageTime = (): number => {
  return new Date().getTime();
};

/**
 * WMImage provides an API to the HTML image element. It is used for caching and easier access to images.
 */
export default class WMImage {
  imageLife!: number;

  randomize!: boolean;

  _srcLoaded!: string;

  _isLoaded!: boolean;

  _isLoading!: boolean;

  _hasError!: boolean;

  srcToLoad!: string;

  loadEventCallback?: (image: WMImage) => void;

  el!: HTMLImageElement;

  headers!: Headers[];

  _imageTimeStampAtError!: number;

  _numFailedAttempts!: number;

  _loadStartTime: number;
  _loadDuration?: number;

  // TODO: Rename when this is actually read from the HTTP response header in
  //   https://gitlab.com/opengeoweb/opengeoweb/-/issues/4926
  // Maximum age, in seconds
  _fakeMaxAge?: number | null;
  _resolveList: ((i: WMImage) => void)[] = [];
  geoReference: WMGeoReference | null = null;
  hashUrl: string | undefined;
  setGeoReference(geoReference: WMGeoReference): void {
    if (this.geoReference) {
      return;
    }
    this.geoReference = {
      extent: [...geoReference.extent],
      resolution: geoReference.resolution,
    };
  }
  /**
   *
   * @param src Optionally set the source URL to load
   * @param callback Optionally set the callback function to trigger once image has loaded
   * @param options Additional options to the image. You can set { randomizer: true } to force unique url's and prevent caching.
   */
  constructor(
    src: string,
    callback?: (image: WMImage) => void,
    options?: WMImageOptions,
  ) {
    this._loadDuration = undefined;
    this._loadStartTime = 0;
    this.randomize = false;
    this._srcLoaded = undefined!;
    this._isLoaded = undefined!;
    this._isLoading = undefined!;
    this._hasError = undefined!;
    this._imageTimeStampAtError = getCurrentImageTime();
    this._numFailedAttempts = 0;
    this.srcToLoad = src;
    this.loadEventCallback = callback;
    this.el = new Image();
    // TODO - We need to build a fetching mechanism that will handle both cases of fetching images with CORS headers of "Access-Control-Allow-Origin: *" and without - this.el.crossOrigin = 'anonymous';
    this.headers = [];
    if (isDefined(options) && isDefined(options!.randomizer)) {
      this.randomize = options!.randomizer!;
    }

    this.init = this.init.bind(this);
    this.isLoaded = this.isLoaded.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.setSource = this.setSource.bind(this);
    this.clear = this.clear.bind(this);
    this.getSrc = this.getSrc.bind(this);
    this.hasError = this.hasError.bind(this);
    this._load = this._load.bind(this);
    this.load = this.load.bind(this);
    this.forceReload = this.forceReload.bind(this);
    this.getLastErrorMSecondsAgo = this.getLastErrorMSecondsAgo.bind(this);
    this.getNumFailedAttempts = this.getNumFailedAttempts.bind(this);
    this.isStale = this.isStale.bind(this);

    this._loadEvent = this._loadEvent.bind(this);
    this.getWidth = this.getWidth.bind(this);
    this.getHeight = this.getHeight.bind(this);
    this.getElement = this.getElement.bind(this);
    this._getImageWithHeaders = this._getImageWithHeaders.bind(this);
    this.init();

    this.el.addEventListener('load', () => {
      this._loadEvent(false);
    });
    this.el.addEventListener('error', () => {
      this._imageTimeStampAtError = getCurrentImageTime();
      this._numFailedAttempts += 1;
      this._loadEvent(true);
    });
    this.el.onselectstart = (): boolean => {
      return false;
    };
    this.el.ondrag = (): boolean => {
      return false;
    };
  }

  private init(): void {
    this._srcLoaded = 'undefined image';
    this._isLoaded = false;
    this._isLoading = false;
    this._hasError = false;
    this._numFailedAttempts = 0;
    this._imageTimeStampAtError = getCurrentImageTime();
  }

  /**
   * Returns true if the image has been loaded
   */
  isLoaded(): boolean {
    if (this._isLoading) {
      return false;
    }
    return this._isLoaded;
  }

  isLoadedWithoutErrors(): boolean {
    return this.isLoaded() && !this.hasError();
  }

  hasNotStartedLoading(): boolean {
    return !this._isLoaded && !this._isLoading;
  }

  isStale(): boolean {
    if (this._fakeMaxAge === undefined) {
      try {
        const layerName = new URL(this.getSrc()).searchParams.get('LAYERS');
        if (layerName) {
          this._fakeMaxAge = fakeMaxAgeResponsesByLayer[layerName] ?? null;
        } else {
          this._fakeMaxAge = null;
        }
      } catch (e) {
        // failed to form URL
        this._fakeMaxAge = null;
      }
    }

    if (this._fakeMaxAge === null) {
      return false;
    }
    const imageAge = getCurrentImageTime() - this._loadStartTime;
    const maxAge = this._fakeMaxAge! * 1000;
    return imageAge > maxAge;
  }

  /**
   * Returns true if the image is still loading
   */
  public isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Get the amount of milliseconds since the image experienced an image load.
   * @returns milliseconds since the image had an error
   */
  public getLastErrorMSecondsAgo(): number {
    return getCurrentImageTime() - this._imageTimeStampAtError;
  }

  /**
   * @returns The number of failed attempts for this image
   */
  public getNumFailedAttempts(): number {
    return this._numFailedAttempts;
  }

  /**
   * Set source of image, but it will not load the image yet.
   * @param src URL of the image to load
   * @param options WMImageOptions to give to the image
   */
  public setSource(src: string, options: WMImageOptions): void {
    if (this._isLoading) {
      debugLogger(
        DebugType.Error,
        '-------------------------> Source set while still loading!!! ',
      );
      return;
    }
    this.srcToLoad = src;
    if (options && options.headers && options.headers.length > 0) {
      this.headers = options.headers;
    }
    if (this._srcLoaded === this.srcToLoad) {
      this._isLoaded = true;
      return;
    }
    this._numFailedAttempts = 0;
    this._imageTimeStampAtError = getCurrentImageTime();
    this._isLoaded = false;
  }

  /**
   * Re-intialize the image
   */
  public clear(): void {
    this.init();
  }

  /**
   * Get the URL of the image
   */
  public getSrc(): string {
    return this.srcToLoad;
  }

  /**
   * Check if the image has an error
   */
  public hasError(): boolean {
    return this._hasError;
  }

  private _resolveAll(): void {
    while (this._resolveList.length !== 0) {
      const resolve = this._resolveList.pop();
      resolve && resolve(this);
    }
  }

  /**
   * Start loading the image
   */
  public async loadAwait(): Promise<WMImage> {
    return new Promise((resolve: (image: WMImage) => void) => {
      this._resolveList.push(resolve);
      this.load();
    });
  }

  public load(): void {
    if (!this._isLoading) {
      this._load();
    }
  }

  public forceReload(randomize = false): void {
    this._isLoaded = false;
    this._srcLoaded = undefined!;
    if (randomize) {
      this.randomize = true;
    }
    this._load();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _getImageWithHeaders(url: string, headers: Headers[] | any[]): void {
    const fetchHeaders = new Headers();
    if (headers && headers.length > 0) {
      for (const header of headers) {
        fetchHeaders.append(header.name, header.value);
      }
    }
    const options: RequestInit = {
      method: 'GET',
      headers: fetchHeaders,
      mode: 'cors',
      cache: 'default',
    };

    const request = new Request(url);

    const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
      let binary = '';
      const bytes: number[] = [].slice.call(new Uint8Array(buffer));
      bytes.forEach((b) => {
        binary += String.fromCharCode(b);
      });
      return window.btoa(binary);
    };

    fetch(request, options)
      .then((response) => {
        response
          .arrayBuffer()
          .then((buffer) => {
            const base64Flag = 'data:image/png;base64,';
            const imageStr = arrayBufferToBase64(buffer);
            this.getElement().src = base64Flag + imageStr;
          })
          .catch(() => {
            debugLogger(
              DebugType.Error,
              `Unable to handle response ${response}`,
            );
          });
      })
      .catch(() => {
        debugLogger(
          DebugType.Error,
          `Unable to fetch image ${url} with headers [${JSON.stringify(
            headers,
          )}]`,
        );
        if (url.startsWith('http://')) {
          debugLogger(
            DebugType.Error,
            'Note that URL starts with http:// instead of https://',
          );
        }
        this._loadEvent(true);
      });
  }

  _load(): void {
    this._loadStartTime = getCurrentImageTime();

    this._hasError = false;
    if (this._isLoaded === true) {
      this._loadEvent(false);
      return;
    }
    this._isLoading = true;
    if (!this.srcToLoad) {
      this._loadEvent(true);
      return;
    }

    /* Allow relative URL's */
    if (this.srcToLoad.startsWith('/') && !this.srcToLoad.startsWith('//')) {
      const splittedHREF = window.location.href
        .split('/')
        .filter((e) => e.length > 0);
      const hostName = `${splittedHREF[0]}//${splittedHREF[1]}/`;
      this.srcToLoad = hostName + this.srcToLoad;
    }

    /*
     * If the image has already loaded this source succesfully (without error), simply trigger the loadevent.
     */
    if (this.srcToLoad === this._srcLoaded && !this._hasError) {
      this._loadEvent(false);
      return;
    }

    if (this.headers?.length) {
      /* Get an image which needs headers */
      this._getImageWithHeaders(this.srcToLoad, this.headers);
    } else if (this.randomize) {
      /* Do a standard img.src url request */
      let newSrc = this.srcToLoad;
      if (this.srcToLoad.indexOf('?') === -1) {
        newSrc += '?';
        newSrc += `random=${Math.random()}`;
      } else {
        try {
          const url = new URL(this.srcToLoad);
          url.searchParams.set('random', Math.random().toString());
          newSrc = url.toString();
        } catch {
          console.warn('failed to randomize url');
        }
      }

      this.getElement().src = newSrc;
    } else {
      // setting src loads the image and the 'load' callback is called when its finished loading
      this.getElement().src = this.srcToLoad;
    }
  }

  _loadEvent(hasError: boolean): void {
    this._loadDuration = hasError
      ? undefined
      : getCurrentImageTime() - this._loadStartTime;
    this._hasError = hasError;
    this._isLoading = false;
    this._isLoaded = true;
    this._srcLoaded = this.srcToLoad;
    this._resolveAll();

    this.loadEventCallback?.(this);
  }

  public getLoadDuration(): number | undefined {
    return this._loadDuration;
  }

  /**
   * Get the width of the current image
   */
  public getWidth(): number {
    return this.el.width;
  }

  /**
   * Get the height of the current image
   */
  public getHeight(): number {
    return this.el.height;
  }

  /**
   * Get the HTMLImageElement behind the WMImage
   */
  public getElement(): HTMLImageElement {
    return this.el;
  }
}

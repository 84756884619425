/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import ProductFormFieldLayout from './ProductFormFieldLayout';
import ProductFormTAC from '../ProductFormTac';
import { AviationProduct } from '../../../types';

interface TacProps {
  tac: string;
  children: React.ReactNode;
  product?: AviationProduct;
}

const Tac: React.FC<TacProps> = ({ tac, children, product }: TacProps) => {
  return (
    <ProductFormFieldLayout
      title="TAC"
      sx={{
        marginTop: -3,
        '.MuiTypography-root.MuiTypography-subtitle1': {
          marginTop: '2px',
        },
      }}
    >
      <ProductFormTAC tac={tac} product={product!} />
      {children}
    </ProductFormFieldLayout>
  );
};

export default Tac;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { Wind, ConfigKey } from '../../../../types';
import { getFieldValue, prependZeroes } from './utils';

const config = [
  { key: 'direction', size: 3 },
  { key: 'speed', size: 2, minSize: 2, maxSize: 3 },
  { key: 'gust', size: 2, minSize: 2, maxSize: 3 },
  { key: 'unit', size: 2 },
];

const configAsObject = Object.assign(config).reduce(
  (list: ConfigKey[], item: ConfigKey) => ({ ...list, [item.key]: item }),
  {},
);

// wind formatters:
export const formatWindValues = (
  key: string,
  value: number | string,
): string => {
  switch (key) {
    case 'direction': {
      // make sure the values get the right size
      const { size } = configAsObject[key];
      return prependZeroes(value, size);
    }
    case 'speed': {
      // if there is a P as first character - return P99
      const valueAsString = value.toString();
      if (valueAsString.length && valueAsString[0] === 'P') {
        return 'P99';
      }

      const { size } = configAsObject[key];
      return prependZeroes(value, size);
    }
    case 'gust': {
      // if there is a P as first character - return P99
      const valueAsString = value.toString();
      if (valueAsString.length && valueAsString[0] === 'P') {
        return 'GP99';
      }
      const { size } = configAsObject[key];
      const newValue = prependZeroes(value, size);
      return `G${newValue.toString()}`;
    }
    case 'unit': {
      // remove KT
      return '';
    }
    default:
      return value as unknown as string;
  }
};

// Format incoming Wind object
export const formatWindToString = (value: Wind): string => {
  if (!value) {
    return value as unknown as string;
  }

  return config.reduce((stringValue, item) => {
    const { key } = item;
    const propValue = value[key as keyof Wind];
    const hasValue =
      propValue !== undefined && propValue !== null && propValue !== '';

    return hasValue
      ? stringValue.concat(formatWindValues(key, propValue))
      : stringValue;
  }, '');
};

interface WindSection {
  remainder: string;
  direction: string;
  speed: string;
  gust: string;
  unit: string;
  validations: string[];
}

const extractValidateWindSections = (
  value: string,
  validation = false,
): WindSection => {
  return config.reduce<WindSection>(
    (obj, item) => {
      const { key, size } = item;
      const returnObj = obj;
      switch (key) {
        case 'direction': {
          // Get first 3 chars as they are ALWAYS the direction
          const direction = obj.remainder.slice(0, size);
          const remainder = obj.remainder.substring(size);

          // Validate direction
          if (validation) {
            const valDirection = validateDirection(direction);
            // If validated not ok - add validation feedback
            const validations =
              typeof valDirection === 'string'
                ? obj.validations.concat(valDirection)
                : obj.validations;

            return {
              ...obj,
              direction,
              remainder,
              validations,
            };
          }

          return {
            ...obj,
            direction,
            remainder,
          };
        }
        case 'speed': {
          const { maxSize, minSize } = item;
          // In case the first char of the speed is a P - it's 3 chars long, otherwise 2
          const dirLength =
            obj.remainder.length && obj.remainder[0] === 'P'
              ? maxSize
              : minSize;
          const speed = obj.remainder.slice(0, dirLength);
          const remainder = obj.remainder.substring(dirLength!);

          // Validate speed
          if (validation) {
            const valSpeed = validateSpeed(speed);
            // If validated not ok - add validation feedback
            const validations =
              typeof valSpeed === 'string'
                ? obj.validations.concat(valSpeed)
                : obj.validations;

            return {
              ...obj,
              speed,
              remainder,
              validations,
            };
          }

          return {
            ...obj,
            speed,
            remainder,
          };
        }
        case 'gust': {
          // Only parse gust if still chars left and these start with G
          if (
            obj.remainder.length &&
            obj.remainder !== 'KT' &&
            obj.remainder[0] === 'G'
          ) {
            const { maxSize, minSize } = item;
            // Remove G
            const rest = obj.remainder.substring(1);
            // In case the first char of the gust is a P - it's 3 chars long, otherwise 2
            const dirLength = rest[0] === 'P' ? maxSize : minSize;
            const gust = rest.slice(0, dirLength);
            const remainder = rest.substring(dirLength!);

            // Validate gusts
            if (validation) {
              const valGust = validateGust(gust);
              // If validated not ok - add validation feedback
              typeof valGust === 'string' &&
                returnObj.validations.push(valGust);

              const validations =
                typeof valGust === 'string'
                  ? obj.validations.concat(valGust)
                  : obj.validations;

              return {
                ...obj,
                gust,
                remainder,
                validations,
              };
            }

            return {
              ...obj,
              gust,
              remainder,
            };
          }
          return obj;
        }
        case 'unit': {
          if (obj.remainder.length) {
            // Validate units
            const valUnits = validateUnit(returnObj.remainder);
            const validations =
              typeof valUnits === 'string'
                ? obj.validations.concat(valUnits)
                : obj.validations;
            return {
              ...obj,
              validations,
            };
          }
          return obj;
        }
        default:
          return obj;
      }
    },
    {
      remainder: value,
      direction: '',
      speed: '',
      gust: '',
      unit: 'KT',
      validations: [],
    },
  );
};

// parsers
export const parseWindToObject = (value: string): Wind => {
  // Create wind object from passed string
  const trimmedValue = value.trim();
  // Extract wind sections - no validation
  const windSections = extractValidateWindSections(trimmedValue, false);

  const gust =
    windSections.gust === 'P99' || windSections.gust === ''
      ? windSections.gust
      : parseInt(windSections.gust, 10);

  const windObject = {
    direction:
      windSections.direction === 'VRB'
        ? windSections.direction
        : parseInt(windSections.direction, 10),
    speed:
      windSections.speed === 'P99'
        ? windSections.speed
        : parseInt(windSections.speed, 10),
    ...(gust !== '' ? { gust } : {}),
    unit: windSections.unit as 'KT',
  };

  return windObject;
};

// messages
export const invalidWindMessage =
  'Invalid wind, expected <direction><speed><gusts(optional)> with direction consisting of 3 digits or VRB and speed and gusts of 2 digits or P99';
export const invalidWindDirectionMessage =
  'Invalid wind direction, expected a direction equal to 3 digits or VRB';
export const invalidWindDirectionMinMaxMessage =
  'Invalid wind direction, the direction must lie between 000 and 360 degrees and must be rounded to the nearest step of 10 degrees';
export const invalidWindDirectionStepMessage =
  'Invalid wind direction, the direction must be rounded to the nearest step of 10 degrees';
export const invalidWindSpeedMessage =
  'Invalid wind speed, expected a speed equal to 2 digits or P99';
export const invalidWindGustsMessage =
  'Invalid wind gusts, expected gusts equal to 2 digits or P99';
export const invalidWindGustSpeedCombinationMessage =
  'Invalid wind gusts, gusts must at least be 10 knots greater than the wind speed';
export const invalidGustForWindP99Message =
  'Invalid wind speed and gust combination, if wind is P99, gusts can only be empty or P99';
export const invalidWindSpeedForDirectionZeroMessage =
  'Invalid wind speed, if direction is 000, speed can only be 00';
export const invalidWindSpeedForDirectionNonZeroMessage =
  'Invalid wind speed, if direction is not 000, speed can not be 00';
export const invalidWindVRBWindDirectionMessage =
  'Invalid wind direction, VRB can only be used in combination with windspeeds smaller than 3 or in combination with gusts and showers';

// validations
const validateDirection = (value: string): boolean | string => {
  // if not a number
  if (!value.match(/^[0-9]+$/)) {
    return value === 'VRB' || invalidWindDirectionMessage;
  }

  // Parse to integer to check for direction min and max
  const intDir = parseInt(value, 10);
  if (intDir > 360 || intDir < 0) {
    return invalidWindDirectionMinMaxMessage;
  }
  if (intDir % 10 !== 0) {
    return invalidWindDirectionStepMessage;
  }

  return true;
};

const validateSpeed = (value: string): boolean | string => {
  // if not a number
  if (!value.match(/^[0-9]+$/)) {
    return value === 'P99' || invalidWindSpeedMessage;
  }
  return true;
};

const validateGust = (value: string): boolean | string => {
  // if not a number
  if (!value.match(/^[0-9]+$/)) {
    return value === 'P99' || invalidWindGustsMessage;
  }
  if (value.length < 2) {
    return invalidWindGustsMessage;
  }
  return true;
};

const validateUnit = (value: string): boolean | string => {
  return value === 'KT' || value === '' || invalidWindMessage;
};

const validateWindInterSection = (
  speed: string,
  gust: string,
  direction: string,
  weather1Value: string,
  weather2Value: string,
  weather3Value: string,
): boolean | string => {
  if (direction === '000' && speed !== '00') {
    return invalidWindSpeedForDirectionZeroMessage;
  }
  if (direction !== '000' && speed === '00') {
    return invalidWindSpeedForDirectionNonZeroMessage;
  }

  if (direction === 'VRB' && (speed === 'P99' || parseInt(speed, 10) > 2)) {
    // Only allow VRB for speeds smaller than 3 or in combination with gusts and showers (SH, TS, SQ)
    const allowedWeatherPhenom = ['SH', 'TS', 'SQ'];
    if (
      gust === '' ||
      (!allowedWeatherPhenom.some((allowedPhenom) =>
        weather1Value.includes(allowedPhenom),
      ) &&
        !allowedWeatherPhenom.some((allowedPhenom) =>
          weather2Value.includes(allowedPhenom),
        ) &&
        !allowedWeatherPhenom.some((allowedPhenom) =>
          weather3Value.includes(allowedPhenom),
        ))
    ) {
      return invalidWindVRBWindDirectionMessage;
    }
  }

  // Validate gust
  if (gust === '') {
    return true;
  }
  if (speed === 'P99' && gust !== 'P99') {
    return invalidGustForWindP99Message;
  }
  if (gust !== 'P99' && parseInt(gust, 10) - parseInt(speed, 10) < 10) {
    return invalidWindGustSpeedCombinationMessage;
  }
  return true;
};

// Main validation function
export const validateWindField = (
  value: string,
  weatherfield1: string,
  weatherfield2: string,
  weatherfield3: string,
): boolean | string => {
  const trimmedWindValue = getFieldValue(value);
  const trimmedWeather1Value = getFieldValue(weatherfield1);
  const trimmedWeather2Value = getFieldValue(weatherfield2);
  const trimmedWeather3Value = getFieldValue(weatherfield3);

  if (!trimmedWindValue.length) {
    return true;
  }
  // Validate length and ensure it doesn't contain any other characters than numbers and VRB P KT and G
  if (
    trimmedWindValue.length < 5 ||
    trimmedWindValue.length > 12 ||
    !trimmedWindValue.match(/^[0-9VRBPGKT]+$/)
  ) {
    return invalidWindMessage;
  }

  // First validate individual sections
  const windValidationIndv = extractValidateWindSections(
    trimmedWindValue,
    true,
  );
  if (windValidationIndv.validations.length !== 0) {
    return windValidationIndv.validations.find(
      (error) => typeof error === 'string',
    )!;
  }

  // Then do any intersection validation
  return validateWindInterSection(
    windValidationIndv.speed,
    windValidationIndv.gust,
    windValidationIndv.direction,
    trimmedWeather1Value,
    trimmedWeather2Value,
    trimmedWeather3Value,
  );
};

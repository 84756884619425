/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Source } from '@opengeoweb/shared';

type LegendDialogType = string;
type GetFeatureInfoDialogType = string;

export enum DialogTypes {
  LayerInfo = 'layerInfo',
  SyncGroups = 'syncGroups',
  TimeSeriesSelect = 'timeSeriesSelect',
  TimeSeriesManager = 'timeSeriesManager',
  TimeseriesInfo = 'timeseriesInfo',
  KeywordFilter = 'keywordFilter',
  DimensionSelectEnsembleMember = 'dimensionSelect-ensemble_member',
  DimensionSelectElevation = 'dimensionSelect-elevation',
  LayerManager = 'layerManager',
  LayerSelect = 'layerSelect',
  DrawingTool = 'drawingTool',
  DockedLayerManager = 'dockedLayerManager',
  ObjectManager = 'objectManager',
  PublicWarnings = 'publicWarnings',
  Search = 'search',
  AreaObjectLoader = 'areaObjectLoader',
}

export type DialogType =
  | DialogTypes
  | LegendDialogType
  | GetFeatureInfoDialogType;

export type { Source };

export interface UIType {
  type: DialogType;
  activeMapId: string;
  isOpen: boolean;
  source?: Source;
  isLoading?: boolean;
  error?: string;
  sourcePanelId?: string;
}

export interface UIStoreType {
  order: DialogType[];
  dialogs: Partial<Record<DialogType, UIType>>;
  activeWindowId?: string;
}

export interface UIModuleState {
  ui?: UIStoreType;
}

export interface SetActiveMapIdForDialogPayload {
  type: DialogType;
  mapId: string;
  setOpen?: boolean;
  source?: Source;
  origin?: string;
}

export interface UIRegisterDialogPayload {
  type: DialogType;
  mapId?: string;
  setOpen?: boolean;
  source?: Source;
}

export interface UIUnRegisterDialogPayload {
  type: DialogType;
}

export interface UIToggleDialogPayload {
  type: DialogType;
  setOpen: boolean;
  origin?: string;
  mapId?: string;
  sourcePanelId?: string;
}

export interface UIOrderDialogPayload {
  type: DialogType;
}

export interface SetActiveWindowIdPayload {
  activeWindowId: string;
}

export interface UIToggleIsLoadingPayload {
  type: DialogType;
  isLoading: boolean;
}

export interface UISetErrorPayload {
  type: DialogType;
  error: string;
}

export interface UIToggleShowSentryRecordButton {
  type: DialogType;
}

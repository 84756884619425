/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/**
 * Set the length of the timer, in milli seconds, after time has elapsed function is called
 * @param secstime Time in milli seconds
 * @param functionhandler Function to call after time is elapsed
 */

class WMTimer {
  private timer: ReturnType<typeof setTimeout> = null!;

  public init(delayInMiliseconds: number, callback: () => void): void {
    this.stop();
    this.timer = setTimeout(callback, delayInMiliseconds);
  }

  public stop(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}

export default WMTimer;

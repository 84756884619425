/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { TimeSliderButtons } from '@opengeoweb/timeslider';
import { OptionsMenuButtonConnect } from './TimeSliderButtonsConnect/OptionsMenuButtonConnect';
import { PlayButtonConnect } from './TimeSliderButtonsConnect/PlayButtonConnect';
import { BackwardForwardStepButtonConnect } from './TimeSliderButtonsConnect/BackwardForwardStepButtonConnect';
import { AutoUpdateButtonConnect } from './TimeSliderButtonsConnect/AutoUpdateButtonConnect';
import { NowButtonConnect } from './TimeSliderButtonsConnect/NowButtonConnect';

interface TimeSliderButtonsConnectProps {
  sourceId: string;
  mapId: string;
}

/**
 * TimeSliderButtons component with components connected to the store displaying the time slider buttons
 *
 * Expects the following props:
 * @param {string} mapId mapId: string - Id of the map
 * @param {string} sourceId mapId: string - Id of the source
 * @example
 * ``` <TimeSliderButtonsConnect mapId={mapId} sourceId={sourceId} />```
 */

const TimeSliderButtonsConnectComponent: React.FC<
  TimeSliderButtonsConnectProps
> = ({ sourceId, mapId }: TimeSliderButtonsConnectProps) => (
  <TimeSliderButtons
    nowBtn={<NowButtonConnect mapId={mapId} sourceId={sourceId} />}
    autoUpdateBtn={<AutoUpdateButtonConnect mapId={mapId} />}
    optionsMenuBtn={<OptionsMenuButtonConnect mapId={mapId} />}
    playBtn={<PlayButtonConnect mapId={mapId} />}
    backwardBtn={
      <BackwardForwardStepButtonConnect mapId={mapId} isForwardStep={false} />
    }
    forwardBtn={
      <BackwardForwardStepButtonConnect mapId={mapId} isForwardStep={true} />
    }
  />
);

export const TimeSliderButtonsConnect = React.memo(
  TimeSliderButtonsConnectComponent,
);

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';

import { uiTypes, uiSelectors, CoreAppStore } from '@opengeoweb/store';
import HeaderOptions, {
  Size,
  ToolbarButtonSettings,
} from './LayerManagerHeaderOptions';

interface HeaderOptionsProps {
  isDockedLayerManager: boolean;
  mapId: string;
  onClickDockButton: () => void;
  onChangeSize: (size: Size) => void;
  buttonSettings?: ToolbarButtonSettings;
}

export const areShortcutsEnabled = (
  activeMapId: string,
  mapId: string,
  floatingLmOpen: boolean,
  isDockedLayerManager: boolean,
): boolean => {
  if (!isDockedLayerManager) {
    return true;
  }
  if (activeMapId === mapId && !floatingLmOpen) {
    return true;
  }
  return false;
};

const HeaderOptionsConnect: React.FC<HeaderOptionsProps> = ({
  isDockedLayerManager,
  mapId,
  onClickDockButton,
  onChangeSize,
  buttonSettings,
}: HeaderOptionsProps) => {
  const activeMapId = useSelector((store: CoreAppStore) =>
    uiSelectors.getActiveWindowId(store),
  );
  const floatingLmOpen = useSelector((store: CoreAppStore) =>
    uiSelectors.getisDialogOpen(store, uiTypes.DialogTypes.LayerManager),
  );

  const shortcutsEnabled = areShortcutsEnabled(
    activeMapId,
    mapId,
    floatingLmOpen,
    isDockedLayerManager,
  );

  return (
    <HeaderOptions
      isDockedLayerManager={isDockedLayerManager}
      shortcutsEnabled={shortcutsEnabled}
      onClickDockButton={onClickDockButton}
      onChangeSize={onChangeSize}
      buttonSettings={buttonSettings}
    />
  );
};

export default HeaderOptionsConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Tabs, Tab } from '@mui/material';
import { Notifications } from '@opengeoweb/theme';
import { EventCategory, SWEvent } from '../../types';
import { useSpaceweatherTranslation } from '../../utils/i18n';

interface NotificationTabsProps {
  activeTab: string;
  newNotifications: SWEvent[];
  onChange: (value: string) => void;
}

const NotificationTabs: React.FC<NotificationTabsProps> = ({
  activeTab,
  newNotifications,
  onChange,
}: NotificationTabsProps) => {
  const { t } = useSpaceweatherTranslation();
  const icon =
    newNotifications !== null && newNotifications.length > 0 ? (
      <Notifications
        data-testid="newNotificationTabIcon"
        fontSize="small"
        color="warning"
      />
    ) : null;

  return (
    <Tabs
      value={activeTab}
      indicatorColor="secondary"
      textColor="secondary"
      onChange={(event: React.SyntheticEvent, value): void => {
        onChange(value);
      }}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab
        label={t('notification-tab-all')}
        value="ALL"
        sx={{
          width: 'auto',
          padding: 0,
          flexDirection: 'row-reverse',
          minHeight: '48px',
          '&.MuiButtonBase-root.MuiTab-root > .MuiTab-iconWrapper': {
            marginBottom: '0',
          },
        }}
        data-testid="newNotificationTabAll"
        icon={icon!}
      />
      {Object.keys(EventCategory).map((key: string) => (
        <Tab
          key={key}
          label={EventCategory[key as keyof typeof EventCategory]}
          value={key}
        />
      ))}

      <Tab label="KNMI" value="KNMI" data-testid="newNotificationTabKNMI" />
    </Tabs>
  );
};

export default NotificationTabs;

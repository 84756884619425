/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, SxProps, Theme } from '@mui/material';
import {
  mapTypes,
  layerTypes,
  genericActions,
  syncGroupsTypes,
  syncGroupsSelectors,
  CoreAppStore,
} from '@opengeoweb/store';
import { ConfigurableMapConnect } from '../ConfigurableMapConnect';
import { LegendConnect } from '../LegendConnect';
import { TimeSliderClockConnect } from '../TimeSliderClockConnect';
import { TimeSliderConnect } from '../TimeSliderConnect';

const mapBoxStyle = (theme: Theme): SxProps<Theme> => ({
  boxSizing: 'border-box',
  float: 'left',
  '& .MapViewComponent': {
    border: `1px solid ${theme.palette.geowebColors.typographyAndIcons.iconLinkDisabled}`,
  },
});

export interface MultiMapPreset {
  id: string;
  title?: string;
  layers: layerTypes.Layer[];
  autoTimeStepLayerId?: string;
  autoUpdateLayerId?: string;
  bbox?: mapTypes.Bbox;
  srs?: string;
  dimensions?: mapTypes.Dimension[];
  displayTimeInMap?: boolean;
  displayLayerManagerAndLegendButtonInMap?: boolean;
  displayDimensionSelectButtonInMap?: boolean;
  syncGroupsIds?: string[];
  componentType?: string;
}
export interface MultiMapViewProps {
  rows: number;
  cols: number;
  maps: MultiMapPreset[];
  // eslint-disable-next-line react/no-unused-prop-types
  syncArea?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  syncTime?: boolean;
  showTimeSlider?: boolean;
  showZoomControls?: boolean;
  multiLegend?: boolean;
  showClock?: boolean;
}

export const MultiMapViewConnect: React.FC<MultiMapViewProps> = ({
  maps,
  rows,
  cols,
  showTimeSlider = true,
  showZoomControls = true,
  multiLegend = false,
  showClock = true,
}: MultiMapViewProps) => {
  const multiMapWindowRef = React.useRef(null);
  const dispatch = useDispatch();
  const syncGroupAddTarget = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupAddTargetPayload): void => {
      dispatch(genericActions.syncGroupAddTarget(payload));
    },
    [dispatch],
  );
  const syncTargets = useSelector((store: CoreAppStore) =>
    syncGroupsSelectors.getSyncGroupTargets(store),
  );

  React.useEffect(() => {
    maps.forEach((map) => {
      map.syncGroupsIds &&
        map.syncGroupsIds.forEach((syncGroupsId) => {
          const alreadySyncedTargetIndex = syncTargets.findIndex(
            (target) =>
              target.targetId === map.id &&
              target.groupId === syncGroupsId &&
              target.linked === true,
          );
          // Only trigger an action if the target was not yet added to the group or was not linked.
          if (alreadySyncedTargetIndex === -1) {
            syncGroupAddTarget({
              groupId: syncGroupsId,
              targetId: map.id,
              linked: true,
              origin: 'system',
            });
          }
        });
    });
  }, [maps, syncGroupAddTarget, syncTargets]);

  const mapStyle = {
    width: `${Math.round(100 / cols)}%`,
    height: `${Math.round(100 / rows)}%`,
  };
  if (maps.length === 0) {
    return null;
  }
  const firstMapId = maps[0].id;

  return (
    <div
      style={{ height: '100%', position: 'relative' }}
      data-testid="MultiMapViewSliderConnect"
      ref={multiMapWindowRef}
    >
      <div
        style={{
          position: 'absolute',
          left: '0px',
          bottom: '0px',
          zIndex: 1000,
          width: '100%',
        }}
      >
        {showTimeSlider && (
          <TimeSliderConnect
            sourceId={firstMapId}
            mapId={firstMapId}
            isAlwaysVisible
            mapWindowRef={multiMapWindowRef}
          />
        )}
      </div>
      <LegendConnect showMapId mapId={firstMapId} multiLegend={multiLegend} />
      {showClock && <TimeSliderClockConnect mapId={firstMapId} />}
      {maps.map((map: MultiMapPreset, mapIndex) => {
        const mapId = map.id;
        return (
          <Box key={mapId} style={mapStyle} sx={mapBoxStyle as SxProps<Theme>}>
            <ConfigurableMapConnect
              title={map.title || mapId}
              id={mapId}
              layers={map.layers || []}
              autoTimeStepLayerId={map.autoTimeStepLayerId}
              autoUpdateLayerId={map.autoUpdateLayerId}
              bbox={map.bbox}
              srs={map.srs}
              dimensions={map.dimensions || []}
              displayTimeInMap={map.displayTimeInMap || false}
              displayLayerManagerAndLegendButtonInMap={
                map.displayLayerManagerAndLegendButtonInMap
              }
              displayDimensionSelectButtonInMap={
                map.displayDimensionSelectButtonInMap
              }
              shouldShowZoomControls={showZoomControls && mapIndex === 0}
              showTimeSlider={false}
              disableTimeSlider
              showClock={false}
              multiLegend={multiLegend}
              shouldDisablePrefetching={map.componentType === 'MultiMap'}
            />
          </Box>
        );
      })}
    </div>
  );
};

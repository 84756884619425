/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { useApiContext } from '@opengeoweb/api';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { ConfigurableMapConnect, InitialMapProps } from '@opengeoweb/core';

import { dateUtils } from '@opengeoweb/shared';
import { mapSelectors } from '@opengeoweb/store';
import { webmapUtils } from '@opengeoweb/webmap';
import {
  FeatureEvent,
  MapViewLayer,
  MapWarningProperties,
} from '@opengeoweb/webmap-react';
import { CapApi } from '../../utils/api';
import { CapPresets, CapFeatures, CustomFeature } from '../types';
import { capActions, capSelectors } from '../../store';
import { AppStore, CapAlert, FeedLastUpdatedPayload } from '../../store/types';

import { findEventIndexInLanguage } from '../../utils/findEventIndexInLanguage';
import { highlightGeoJson } from '../../utils/highlightGeoJson';
import { addColors } from '../../utils/addColors';
import { addFeedAdressToCustomGeoJSonFeature } from '../../utils/addFeedAddressToCustomGeoJsonFeature';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const isPromiseFulfilled = <T extends unknown>(
  result: PromiseSettledResult<T>,
): result is PromiseFulfilledResult<T> => result.status === 'fulfilled';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const promiseSuccesses = <T extends unknown>(
  results: PromiseSettledResult<T>[],
): T[] =>
  results.reduce(
    (acc: T[], result) =>
      isPromiseFulfilled(result) ? [...acc, result.value] : acc,
    [],
  );

export interface MapViewCapConnectProps {
  id?: string;
  title?: string;
  bbox?: { left: number; bottom: number; right: number; top: number };
  srs?: string;
  capWarningPresets?: CapPresets;
  layers?: InitialMapProps['mapPreset']['layers'];
}

export const MapViewCapConnect: React.FC<MapViewCapConnectProps> = ({
  id,
  title,
  bbox,
  srs,
  capWarningPresets,
  layers,
}) => {
  const { api } = useApiContext<CapApi>();
  const dispatch = useDispatch();
  const previousFeatures = React.useRef<Record<string, CustomFeature[]> | null>(
    null,
  );

  const [featuresByAlert, setFeaturesByAlert] = React.useState<
    Record<string, CustomFeature[]>
  >({});

  const [selectedFeatureEvent, setSelectedFeatureEvent] =
    React.useState<FeatureEvent | null>(null);
  const mapIdRef = React.useRef(id || webmapUtils.generateMapId());
  const layerIdRef = React.useRef(webmapUtils.generateLayerId());

  const currentLastUpdatedTimes = useSelector((store: AppStore) =>
    capSelectors.getLastUpdatedTimes(store),
  );

  const fetchData = async (
    links: string[],
    feedAddress: string,
  ): Promise<void> => {
    const alertsAndFeaturesPromises = links.map(async (alertUrl) => {
      const { data } = await api.getSingleAlert!(alertUrl);
      const alert = { ...data.alert, feedAddress };
      const features = addFeedAdressToCustomGeoJSonFeature(
        feedAddress,
        data.features.features,
      );
      return { alert, features };
    });
    const alertsAndFeatures = await Promise.allSettled(
      alertsAndFeaturesPromises,
    );

    const realAlertsAndFeatures = alertsAndFeatures.reduce(
      (alerts: { alert: CapAlert; features: CustomFeature[] }[], result) =>
        // endpoints may throttle requests and return empty alerts
        isPromiseFulfilled(result) && result.value.alert
          ? [...alerts, result.value]
          : alerts,
      [],
    );

    const alerts = realAlertsAndFeatures.map(({ alert }) => ({
      ...alert,
    }));
    dispatch(capActions.addManyAlerts(alerts));
    dispatch(
      capActions.addManyAlertsToFeed({
        feedId: feedAddress,
        alertIds: alerts.map((alert) => alert.identifier),
      }),
    );

    const featuresByAlert = realAlertsAndFeatures.reduce(
      (byId, { alert, features }) => ({
        ...byId,
        [alert.identifier]: addColors(features),
      }),
      {} as Record<string, CustomFeature[]>,
    );
    const combinedFeatures = {
      ...previousFeatures.current,
      ...featuresByAlert,
    };
    setFeaturesByAlert(combinedFeatures);
    previousFeatures.current = combinedFeatures;
  };

  const fetchDataFromAllFeeds = async (): Promise<void> => {
    const feedPromises = capWarningPresets?.feeds.map(
      async ({ feedAddress }) => {
        const { data: links } = await api.getFeedLinks!(feedAddress);
        return { links, feedAddress };
      },
    );

    const feedLinks = promiseSuccesses(await Promise.allSettled(feedPromises!));
    feedLinks.forEach(({ links, feedAddress }) => {
      void fetchData(links, feedAddress);
    });
  };

  const pollAndUpdate = React.useCallback(async (): Promise<void> => {
    const lastUpdatedPromises = capWarningPresets?.feeds.map(
      async (feed): Promise<FeedLastUpdatedPayload> => {
        const { data: updated } = await api.getLastUpdated!(feed.feedAddress);
        return {
          id: feed.feedAddress,
          changes: {
            lastUpdated: updated.lastUpdated,
          },
        };
      },
    );
    if (lastUpdatedPromises) {
      const lastUpdateTimes = promiseSuccesses(
        await Promise.allSettled(lastUpdatedPromises),
      );
      lastUpdateTimes.forEach(async (feed, i) => {
        if (
          dateUtils.isAfter(
            feed.changes.lastUpdated,
            currentLastUpdatedTimes[i],
          )
        ) {
          const { data: links } = await api.getFeedLinks!(feed.id);
          void fetchData(links, feed.id);
        }
      });
      dispatch(capActions.setFeedLastUpdatedTimes(lastUpdateTimes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLastUpdatedTimes]);

  React.useEffect(() => {
    void fetchDataFromAllFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const pollInterval = capWarningPresets?.pollInterval ?? 300_000;

    const timer: ReturnType<typeof setInterval> = setInterval(() => {
      void pollAndUpdate();
    }, pollInterval);

    return (): void => {
      clearInterval(timer);
    };
  }, [capWarningPresets?.pollInterval, currentLastUpdatedTimes, pollAndUpdate]);

  const parentSizeRef = React.useRef<HTMLDivElement>(null);
  const [parentSize, setParentSize] = React.useState(window.innerWidth / 2);

  React.useEffect(() => {
    const handleWindowResize = (): void => {
      setParentSize(parentSizeRef.current!.parentElement!.offsetWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return (): void => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const highLightGeoJsonOnClick = (featureResults?: FeatureEvent): void => {
    const nextState = highlightGeoJson(featuresByAlert, featureResults!);
    setSelectedFeatureEvent(featureResults!);
    setFeaturesByAlert(nextState);
  };

  const selectedTime = useSelector((store: AppStore) =>
    mapSelectors.getSelectedTime(store, mapIdRef.current),
  );
  const alertIdsInEffect = useSelector((store: AppStore) =>
    capSelectors.getAlertsInEffect(
      store,
      dateUtils.fromUnix(selectedTime).toISOString(),
    ),
  ).map(({ identifier }) => identifier);

  const featuresInEffect = Object.keys(featuresByAlert)
    .filter((id) => alertIdsInEffect.includes(id))
    .flatMap((id) => featuresByAlert[id]);

  const geoJsonObject: CapFeatures = {
    type: 'FeatureCollection',
    features: featuresInEffect!,
  };

  return (
    <div
      data-testid="mapViewCap"
      style={{ height: '100%', width: '100%' }}
      ref={parentSizeRef}
    >
      <ConfigurableMapConnect
        id={mapIdRef.current}
        title={title}
        layers={layers || []}
        data-testid="capWarningMap"
        bbox={bbox}
        srs={srs}
      >
        <MapViewLayer
          id={layerIdRef.current}
          geojson={geoJsonObject}
          onClickFeature={highLightGeoJsonOnClick}
          data-testid="polygonLayer"
        />
        {selectedFeatureEvent && (
          <MapWarningProperties
            data-testid="mapWarnings"
            selectedFeatureProperties={
              selectedFeatureEvent.feature.properties!.details
            }
            languageIndex={findEventIndexInLanguage(
              i18n.language,
              selectedFeatureEvent.feature.properties!.details.languages,
              capWarningPresets!,
              selectedFeatureEvent.feature.properties!.details.feedAddress,
            )}
            parentSize={parentSize}
          />
        )}
      </ConfigurableMapConnect>
    </div>
  );
};

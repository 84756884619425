/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  PopoverOrigin,
  SxProps,
  Theme,
  ListSubheader,
  ListItemText,
  Grid2 as Grid,
} from '@mui/material';
import { Options } from '@opengeoweb/theme';
import { CustomIconButton, Variant } from '../CustomIconButton';

type MenuPosition = 'left' | 'right' | 'bottom';

export const getPosition = (
  menuPosition: MenuPosition,
): { anchorOrigin: PopoverOrigin; transformOrigin: PopoverOrigin } => {
  switch (menuPosition) {
    case 'bottom':
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      };
    // eslint-disable-next-line default-case-last
    default:
    case 'left':
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
      };

    case 'right':
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
      };
  }
};

export interface MenuItemType {
  text: string;
  action: (event: React.MouseEvent) => void;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  isSelected?: boolean;
}

interface ToggleMenuProps {
  buttonTestId?: string;
  menuPosition?: MenuPosition;
  menuItems?: MenuItemType[];
  menuTitle?: string;
  children?: React.ReactNode;
  buttonSx?: SxProps<Theme>;
  tooltipTitle?: string;
  variant?: Variant;
  isDefaultOpen?: boolean;
  buttonIcon?: React.ReactNode;
  isDisabled?: boolean;
}

export const ToggleMenu: React.FC<ToggleMenuProps> = ({
  buttonTestId = 'toggleMenuButton',
  menuPosition = 'left',
  menuItems,
  children,
  buttonSx,
  tooltipTitle = '',
  menuTitle = '',
  variant = 'tool',
  isDefaultOpen = false,
  buttonIcon = <Options />,
  isDisabled = false,
}: ToggleMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const isOpen = Boolean(anchorEl);
  const { anchorOrigin, transformOrigin } = getPosition(menuPosition);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (isDefaultOpen) {
      setAnchorEl(document.getElementById(buttonTestId) as HTMLButtonElement);
    }
  }, [isDefaultOpen, buttonTestId]);

  return (
    <>
      <CustomIconButton
        tooltipProps={{
          placement: 'top',
          title: tooltipTitle,
        }}
        isSelected={isOpen}
        sx={buttonSx}
        onClick={handleClick}
        data-testid={buttonTestId}
        variant={variant}
        id={buttonTestId}
        disabled={isDisabled}
      >
        {buttonIcon}
      </CustomIconButton>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={(
          event: React.MouseEvent,
          reason: 'backdropClick' | 'escapeKeyDown' | 'tabKeyDown',
        ): void => {
          if (reason === 'tabKeyDown') {
            return;
          }
          handleClose(event);
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        BackdropProps={{
          style: {
            opacity: 0,
            transition: 'none',
          },
        }}
        MenuListProps={{
          ...(children && {
            style: {
              padding: '0px',
            },
          }),
        }}
      >
        {menuTitle && (
          <ListSubheader data-testid="toggleMenuTitle">
            <Typography sx={{ fontSize: '12px' }}>{menuTitle}</Typography>
          </ListSubheader>
        )}
        {menuItems && menuItems.length ? (
          menuItems.map((menuItem) => {
            const { text, icon, action, isDisabled }: MenuItemType = menuItem;
            return (
              <MenuItem
                key={text}
                onClick={(event: React.MouseEvent): void => {
                  action(event);
                  handleClose(event);
                }}
                disabled={isDisabled}
                selected={menuItem.isSelected}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  size="grow"
                >
                  <Grid>
                    <ListItemText>{text}</ListItemText>
                  </Grid>
                  {icon && (
                    <Grid alignItems="flex-end">
                      <ListItemIcon
                        sx={{ '&.MuiListItemIcon-root': { minWidth: '24px' } }}
                      >
                        {icon}
                      </ListItemIcon>
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
            );
          })
        ) : (
          <div /> // Needed to make the menu work without any menuItems
        )}
        {children}
      </Menu>
    </>
  );
};

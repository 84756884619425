/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { LayerProps } from './types';
import WMJSDimension from './WMJSDimension';
import { isDefined } from './WMJSTools';
import type WMLayer from './WMLayer';
import { WMSLayerDimInfo } from './WMLayerTypes';

/**
 * Helper function to figure out the dimensions from the WMS layer object (From WMS GetCapabilities). Parses both WMS 1.1.1 and WMS 1.3.0.
 * @param layerObjectFromWMS The corresponding layer object from the WMS GetCapabilities.
 * @param layer THe WMLayer to configure
 * @param layerDimNamesToRemove A set of dimensions names which are flagged for removal, this function removes the dimension from the set if found in the layer.
 */
export const addDimsForLayer = (
  layerProps: LayerProps,
  layer: WMLayer,
  layerDimNamesToRemove: Set<string>,
): void => {
  /* Loop through all the dims from the WMS GetCapabilities document for this layer */
  layerProps.dimensions?.forEach((layerDimension) => {
    /* Obtain layerDim info from the GetCapabilities document and put into a WMSLayerDimInfo object  */
    const dimName = (layerDimension.name || '').toLowerCase();
    const layerDim: WMSLayerDimInfo = {
      name: dimName,
      units: layerDimension.units || '',
      unitSymbol: layerDimension.unitSymbol || '',
      value: layerDimension.values || '',
      default: layerDimension.currentValue,
      /* If presets with other dimensions, like elevation, cause
      problems, try to add the dimension here */
      linkDimensionToMap:
        dimName !== 'reference_time' &&
        dimName !== 'interval_start' &&
        dimName !== 'interval_end',
    };

    /* Flag that this dim should not be removed from the layer */
    layerDimNamesToRemove.delete(layerDim.name);

    /* Now configure the dimensions in the Layer based on the obtained info */
    const wmLayerDimensionIndex = layer.dimensions.findIndex(
      (dim): boolean => dim.name === layerDim.name,
    );

    /* Index === -1: Dimension was not found */
    if (wmLayerDimensionIndex === -1) {
      /* Add a new dimension for this layer */
      const dimension = new WMJSDimension({
        name: layerDim.name,
        units: layerDim.units,
        values: layerDim.value,
        currentValue: layerDim.default,
        defaultValue: layerDim.default,
        linked: layerDim.linkDimensionToMap,
        unitSymbol: layerDim.unitSymbol,
      });
      /* Check if the dimension should take the value from the map */
      if (layer.parentMap) {
        const mapDim = layer.parentMap.getDimension(layerDim.name);
        if (mapDim && mapDim.linked && isDefined(mapDim.currentValue)) {
          const dimensionCurrentValue = dimension.getClosestValue(
            mapDim.currentValue,
          );
          dimension.setValue(dimensionCurrentValue);
        }
      }
      layer.dimensions.push(dimension);
    } else {
      /* Dimension already exists, keep its current value but update the rest of its properties */
      const dimensionToUpdate = layer.dimensions[wmLayerDimensionIndex];
      const currentValue = dimensionToUpdate.getValue();
      dimensionToUpdate.values = layerDim.value;
      dimensionToUpdate.name = layerDim.name;
      dimensionToUpdate.units = layerDim.units;
      dimensionToUpdate.unitSymbol = layerDim.unitSymbol;
      dimensionToUpdate.reInitializeValues(dimensionToUpdate.values);
      dimensionToUpdate.setClosestValue(currentValue);
    }
  });
};

/**
 * Helper function to configure the WMS Styles from the WMS GetCapabilities document.
 * @param nestedLayerPath Array of WMS Layers from `[Root WMS Layer] => [Parent(s) WMS Layer] => [This WMS layer]`.
 * @param wmLayer The WMLayer object to configure
 */
export const configureStyles = (
  layerProps: LayerProps,
  wmLayer: WMLayer,
): void => {
  // eslint-disable-next-line no-param-reassign
  wmLayer.styles = layerProps.styles || [];

  /* Set the default style */
  if (wmLayer.styles.length > 0) {
    /* Check if the current style is in the list */
    const styleIndex = wmLayer.styles.findIndex(
      (style) => style.name === wmLayer.currentStyle,
    );

    /* If not found or not set, set the first one */
    if (styleIndex === -1) {
      wmLayer.setStyle(wmLayer.styles[0].name);
    } else {
      /* Otherwise set it */
      wmLayer.setStyle(wmLayer.styles[styleIndex].name);
    }
  }
};

/**
 * Helper function to configure the dimensions for this layer from the WMS GetCapabilities document.
 * @param nestedLayerPath Array of WMS Layers from `[Root WMS Layer] => [Parent(s) WMS Layer] => [This WMS layer]`.
 * @param wmLayer The WMLayer object to configure
 */
export const configureDimensions = (
  layerProps: LayerProps,
  wmLayer: WMLayer,
): void => {
  /*
  WMS 1.1.1:
    <Dimension name="time" units="ISO8601"/>
    <Extent name="time" default="2021-05-14T10:35:00Z" multipleValues="1" nearestValue="0">2021-03-31T09:25:00Z/2021-05-14T10:35:00Z/PT5M</Extent>

  WMS 1.3.0:
    <Dimension name="time" units="ISO8601" default="2021-05-14T07:35:00Z" multipleValues="1" nearestValue="0" current="1">2021-03-31T09:25:00Z/2021-05-14T07:35:00Z/PT5M</Dimension>
  */

  /* Flag dimensions which are currently in use for this layer, it is possible that the update will remove dimensions from this layer; keep track of the current ones */
  const layerDimNamesToRemove = new Set<string>();
  for (const layerDim of wmLayer.dimensions) {
    layerDimNamesToRemove.add(layerDim.name);
  }

  /* Now start adding all dimensions starting with the parent layer. Layers more deeply nested will inherit Dimension info */

  addDimsForLayer(layerProps, wmLayer, layerDimNamesToRemove);

  /* The list with dimensions to remove is now complete, remove the unneeded dimensions from the layer */
  layerDimNamesToRemove.forEach((dimName: string): void => {
    const dimIndex = wmLayer.dimensions.findIndex(
      (dim) => dim.name === dimName,
    );
    if (dimIndex !== -1) {
      wmLayer.dimensions.splice(dimIndex, 1);
    }
  });

  /* Handle reference time dim */
  const refTimeDimension = wmLayer.getDimension('reference_time');
  if (refTimeDimension) {
    wmLayer.handleReferenceTime('reference_time', refTimeDimension.getValue());
  }

  if (wmLayer.parentMap) {
    wmLayer.parentMap.configureMapDimensions(wmLayer);
  }
};

export const configureGeographicBoundingBox = (
  layerProps: LayerProps,
  wmLayer: WMLayer,
): void => {
  const bbox = layerProps.geographicBoundingBox;
  if (bbox) {
    // eslint-disable-next-line no-param-reassign
    wmLayer.geographicBoundingBox = bbox;
  }
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createListenerMiddleware } from '@reduxjs/toolkit';
import { LayerType } from '@opengeoweb/webmap';
import {
  emptyGeoJSON,
  defaultIntersectionStyleProperties,
  getLastEmptyFeatureIndex,
  addSelectionTypeToGeoJSON,
  getFeatureCollection,
  getGeoJson,
  moveFeature,
} from '@opengeoweb/webmap-react';
import { drawingToolActions, DrawtoolModuleStore } from './reducer';
import { layerSelectors, layerActions, WebMapStateModuleState } from '../map';
import * as drawingToolSelectors from './selectors';

export const drawingToolListener = createListenerMiddleware<
  DrawtoolModuleStore & WebMapStateModuleState
>();
const registerOrigin = 'drawings listener:registerDrawToolListener';

// register draw tool
drawingToolListener.startListening({
  actionCreator: drawingToolActions.registerDrawTool,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();
    const {
      mapId,
      geoJSONLayerId,
      geoJSONIntersectionLayerId,
      geoJSONIntersectionBoundsLayerId,
      defaultGeoJSON = emptyGeoJSON,
      defaultGeoJSONIntersection = emptyGeoJSON,
      defaultGeoJSONIntersectionBounds,
      defaultGeoJSONIntersectionProperties = defaultIntersectionStyleProperties,
    } = payload;

    // create for every drawTool a draw layer
    if (geoJSONLayerId && mapId) {
      listenerApi.dispatch(
        layerActions.addLayer({
          mapId,
          layer: {
            // empty geoJSON
            geojson: defaultGeoJSON,
            layerType: LayerType.featureLayer,
          },
          layerId: geoJSONLayerId,
          origin: registerOrigin,
        }),
      );
    }

    // create intersection layer
    if (geoJSONIntersectionLayerId && mapId) {
      listenerApi.dispatch(
        layerActions.addLayer({
          mapId,
          layer: {
            geojson: defaultGeoJSONIntersection,
            layerType: LayerType.featureLayer,
            defaultGeoJSONProperties: defaultGeoJSONIntersectionProperties,
          },
          layerId: geoJSONIntersectionLayerId,
          origin: registerOrigin,
        }),
      );
    }
    // create intersection bounds layer
    if (geoJSONIntersectionBoundsLayerId && mapId) {
      const existingBoundsLayer = layerSelectors.getLayerById(
        listenerApi.getState(),
        geoJSONIntersectionBoundsLayerId,
      );

      // don't add a new boundslayer when another drawtool using that same boundslayer
      if (!existingBoundsLayer) {
        listenerApi.dispatch(
          layerActions.addLayer({
            mapId,
            layer: {
              geojson: defaultGeoJSONIntersectionBounds,
              layerType: LayerType.featureLayer,
            },
            layerId: geoJSONIntersectionBoundsLayerId,
            origin: registerOrigin,
          }),
        );
      }

      listenerApi.dispatch(
        layerActions.orderLayerToFront({
          layerId: geoJSONIntersectionBoundsLayerId,
        }),
      );
    }
  },
});

// change draw tool
drawingToolListener.startListening({
  actionCreator: drawingToolActions.changeDrawToolMode,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    try {
      const { drawModeId, drawToolId, shouldUpdateShape = true } = payload;
      const drawingTool = drawingToolSelectors.selectDrawToolById(
        listenerApi.getState(),
        drawToolId,
      );

      if (!drawingTool) {
        return;
      }

      const {
        shouldAllowMultipleShapes = false,
        geoJSONIntersectionLayerId,
        geoJSONIntersectionBoundsLayerId,
      } = drawingTool;

      const newDrawMode = drawingToolSelectors.getDrawModeById(
        listenerApi.getState(),
        drawToolId,
        drawModeId,
      );

      // disable layer when no new drawmode or when selecting same tool and is selectable
      if (
        !newDrawMode ||
        (!drawingTool.activeDrawModeId && newDrawMode.isSelectable)
      ) {
        listenerApi.dispatch(
          layerActions.toggleFeatureMode({
            layerId: drawingTool.geoJSONLayerId,
            isInEditMode: false,
            drawMode: '',
          }),
        );

        const geoJSONLayer = layerSelectors.getLayerById(
          listenerApi.getState(),
          drawingTool.geoJSONLayerId,
        );

        const geoJSON = geoJSONLayer?.geojson;
        const lastFeatureIndex =
          geoJSON !== undefined ? getLastEmptyFeatureIndex(geoJSON) : undefined;

        if (lastFeatureIndex !== undefined) {
          const newGeoJSON = {
            ...geoJSON,
            features: (geoJSON as GeoJSON.FeatureCollection).features.filter(
              (_feature, index) => index !== lastFeatureIndex,
            ),
          } as GeoJSON.FeatureCollection;

          listenerApi.dispatch(
            layerActions.updateFeature({
              layerId: drawingTool.geoJSONLayerId,
              geojson: newGeoJSON,
            }),
          );

          listenerApi.dispatch(
            layerActions.setSelectedFeature({
              layerId: drawingTool.geoJSONLayerId,
              selectedFeatureIndex:
                newGeoJSON.features.length > 0
                  ? newGeoJSON.features.length - 1
                  : 0,
            }),
          );
        }

        return;
      }

      // delete shape
      if (newDrawMode.value === 'DELETE') {
        listenerApi.dispatch(
          layerActions.layerChangeGeojson({
            layerId: drawingTool.geoJSONLayerId,
            geojson: emptyGeoJSON,
          }),
        );

        // clear intersection shape
        if (geoJSONIntersectionLayerId) {
          listenerApi.dispatch(
            layerActions.layerChangeGeojson({
              layerId: geoJSONIntersectionLayerId,
              geojson: emptyGeoJSON,
            }),
          );
        }

        listenerApi.dispatch(
          layerActions.toggleFeatureMode({
            layerId: drawingTool.geoJSONLayerId,
            isInEditMode: false,
            drawMode: '',
          }),
        );

        return;
      }

      // check tool is selected of existing drawn shape
      const currentGeoJSONLayer = layerSelectors.getLayerById(
        listenerApi.getState(),
        drawingTool.geoJSONLayerId,
      );

      const { geojson: currentGeoJSON, selectedFeatureIndex = 0 } =
        currentGeoJSONLayer || {};

      const currentSelectionType =
        currentGeoJSON?.features[selectedFeatureIndex]?.properties
          ?.selectionType;
      const newSelectionType = newDrawMode.selectionType;
      const isNewToolSelected = currentSelectionType !== newSelectionType;

      const shouldUpdateNewShape =
        !currentGeoJSON?.features?.length ||
        isNewToolSelected ||
        (!newDrawMode.isSelectable && shouldAllowMultipleShapes);

      // don't change anything if same tool is selected again
      if (shouldUpdateNewShape && shouldUpdateShape) {
        const shapeWithSelectionType = addSelectionTypeToGeoJSON(
          newDrawMode.shape,
          newDrawMode.selectionType,
        );
        const geoJSONFeatureCollection = getFeatureCollection(
          shapeWithSelectionType,
          shouldAllowMultipleShapes,
          currentGeoJSON,
        );
        const newGeoJSON = getGeoJson(
          geoJSONFeatureCollection,
          shouldAllowMultipleShapes,
        );

        if (shouldAllowMultipleShapes) {
          moveFeature(currentGeoJSON!, newGeoJSON, selectedFeatureIndex, '');
        }

        listenerApi.dispatch(
          layerActions.setSelectedFeature({
            layerId: drawingTool.geoJSONLayerId,
            selectedFeatureIndex: newGeoJSON.features.length - 1,
          }),
        );

        listenerApi.dispatch(
          layerActions.updateFeature({
            layerId: drawingTool.geoJSONLayerId,
            geojson: newGeoJSON,
            ...(geoJSONIntersectionLayerId && {
              geoJSONIntersectionLayerId,
            }),
            ...(geoJSONIntersectionBoundsLayerId && {
              geoJSONIntersectionBoundsLayerId,
            }),
          }),
        );
      }

      listenerApi.dispatch(
        layerActions.toggleFeatureMode({
          layerId: drawingTool.geoJSONLayerId,
          isInEditMode: newDrawMode.isSelectable,
          drawMode: newDrawMode.value,
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error changeDrawToolListener', error);
    }
  },
});

// change intersection
drawingToolListener.startListening({
  actionCreator: drawingToolActions.changeIntersectionBounds,
  effect: async ({ payload }, listenerApi) => {
    listenerApi.cancelActiveListeners();

    const { drawToolId, geoJSON } = payload;

    const drawtool = drawingToolSelectors.selectDrawToolById(
      listenerApi.getState(),
      drawToolId,
    );

    if (!drawtool) {
      return;
    }

    listenerApi.dispatch(
      layerActions.layerChangeGeojson({
        layerId: drawtool.geoJSONIntersectionBoundsLayerId!,
        geojson: geoJSON,
      }),
    );
    listenerApi.dispatch(
      layerActions.layerChangeGeojson({
        layerId: drawtool.geoJSONLayerId,
        geojson: emptyGeoJSON,
      }),
    );
    listenerApi.dispatch(
      layerActions.layerChangeGeojson({
        layerId: drawtool.geoJSONIntersectionLayerId!,
        geojson: emptyGeoJSON,
      }),
    );
  },
});

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import {
  layerActions,
  mapActions,
  mapSelectors,
  genericActions,
  layerTypes,
  mapEnums,
  genericTypes,
  CoreAppStore,
  syncConstants,
  uiSelectors,
  syncGroupsTypes,
  drawingToolSelectors,
  loadingIndicatorActions,
  genericSelectors,
  syncGroupsSelectors,
} from '@opengeoweb/store';
import {
  EVENT_GETCAPABILITIES_READY,
  EVENT_GETCAPABILITIES_START,
  handleDateUtilsISOString,
  webmapUtils,
  wmServiceListener,
} from '@opengeoweb/webmap';

import {
  MapControls,
  MapPinLocationPayload,
  MapView,
  MapViewLayer,
  MapViewProps,
  SetMapDimensionPayload,
  UpdateLayerInfoPayload,
} from '@opengeoweb/webmap-react';
import { debounce } from 'lodash';
import { useTouchZoomPan } from './useTouchZoomPan';
import { useKeyboardZoomAndPan } from './useKeyboardZoomAndPan';
import { SearchControlButtonConnect, SearchDialogConnect } from '../Search';
import {
  LayerManagerConnect,
  LayerManagerMapButtonConnect,
} from '../LayerManager';
import { ZoomControlConnect } from '../MapControlsConnect';
import { LegendConnect, LegendMapButtonConnect } from '../LegendConnect';
import { MultiDimensionSelectMapButtonsConnect } from '../MultiMapDimensionSelectConnect';
import {
  GetFeatureInfoButtonConnect,
  GetFeatureInfoConnect,
} from '../FeatureInfo';

// eslint-disable-next-line no-redeclare
export interface MapControls {
  mapControlsPositionTop?: number;
  search?: boolean;
  zoomControls?: boolean;
  layerManagerAndLegend?: boolean;
  multiLegend?: boolean;
  dimensionSelect?: boolean;
  getFeatureInfo?: boolean;
  additionalMapControls?: React.ReactNode;
}

export interface MapViewConnectProps extends MapViewProps {
  mapId: string;
  displayTimeInMap?: boolean;
  controls?: MapControls;
  showScaleBar?: boolean;
  children?: React.ReactNode;
  showLayerInfo?: boolean;
  passiveMap?: boolean;
}

export const ORIGIN_REACTMAPVIEWCONNECT_ONMAPCHANGEDIMENSION =
  'ORIGIN_REACTMAPVIEWCONNECT_ONMAPCHANGEDIMENSION';

export const ORIGIN_REACTMAPVIEWCONNECT_ONUPDATELAYERINFO =
  'ORIGIN_REACTMAPVIEWCONNECT_ONUPDATELAYERINFO';

/**
 * Connected component used to display the map and selected layers.
 * Includes options to disable the map controls and legend.
 *
 * Expects the following props:
 * @param {string} mapId mapId: string - Id of the map
 * @param {object} [controls.zoomControls] **optional** controls: object - toggle the map controls, zoomControls defaults to true
 * @param {boolean} [displayTimeInMap] **optional** displayTimeInMap: boolean, toggles the mapTime, defaults to true
 * @param {boolean} [showScaleBar] **optional** showScaleBar: boolean, toggles the scaleBar, defaults to true
 * @example
 * ```<MapViewConnect mapId={mapId} controls={{ zoomControls: false }} displayTimeInMap={false} showScaleBar={false}/>```
 */
const MapViewConnect: React.FC<MapViewConnectProps> = ({
  mapId,
  children,
  controls = { mapControlsPositionTop: 0, zoomControls: true },
  ...props
}: MapViewConnectProps) => {
  const mapRef = React.useRef(null);
  const store = useStore<CoreAppStore>();
  const mapDimensions = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapDimensions(store, mapId),
  );
  const layers = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapLayers(store, mapId),
  );
  const baseLayers = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapBaseLayers(store, mapId),
  );
  const overLayers = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapOverLayers(store, mapId),
  );
  const featureLayers = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapFeatureLayers(store, mapId),
  );
  const linkedFeatures = useSelector((store: CoreAppStore) =>
    genericSelectors.selectLinkedFeatures(store.syncGroups?.linkedState, mapId),
  );
  const linkedFormFeatures = useSelector((store: CoreAppStore) =>
    genericSelectors.selectLinkedFormFeatures(
      store.syncGroups?.linkedState,
      mapId,
    ),
  );
  const bbox = useSelector((store: CoreAppStore) =>
    mapSelectors.getBbox(store, mapId),
  );
  const srs = useSelector((store: CoreAppStore) =>
    mapSelectors.getSrs(store, mapId),
  );
  const activeLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getActiveLayerId(store, mapId),
  );
  const animationDelay = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapAnimationDelay(store, mapId),
  );
  const mapPinLocation = useSelector((store: CoreAppStore) =>
    mapSelectors.getPinLocation(store, mapId),
  );

  const linkedPanelId = useSelector((store: CoreAppStore) =>
    genericSelectors.selectLinkedPanelId(store, mapId),
  );

  const selectedFeatureCoordinates = useSelector((store: CoreAppStore) =>
    genericSelectors.getSelectedFeature(store.syncGroups!, linkedPanelId!),
  );

  const disableMapPin = useSelector((store: CoreAppStore) =>
    mapSelectors.getDisableMapPin(store, mapId),
  );

  const displayMapPin = useSelector((store: CoreAppStore) =>
    mapSelectors.getDisplayMapPin(store, mapId),
  );
  const timestep = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeStep(store, mapId),
  );

  const dispatch = useDispatch();

  const mapChangeDimension = React.useCallback(
    (mapDimensionPayload: SetMapDimensionPayload): void => {
      if (mapDimensionPayload.dimension) {
        const wmjsMap = webmapUtils.getWMJSMapById(mapId);
        if (!wmjsMap) {
          return;
        }
        const dimension = wmjsMap.getDimension(
          mapDimensionPayload.dimension.name!,
        );
        if (
          dimension &&
          dimension.currentValue === mapDimensionPayload.dimension.currentValue
        ) {
          return;
        }
      }
      dispatch(mapActions.mapChangeDimension(mapDimensionPayload));
    },
    [dispatch, mapId],
  );

  const setTime = React.useCallback(
    (setTimePayload: genericTypes.SetTimePayload): void => {
      const wmjsMap = webmapUtils.getWMJSMapById(mapId);
      /* Check if the map not already has this value set, otherwise this component will re-render */
      if (
        wmjsMap &&
        wmjsMap.getDimension('time') &&
        wmjsMap.getDimension('time')?.currentValue === setTimePayload.value
      ) {
        return;
      }
      dispatch(genericActions.setTime(setTimePayload));
    },
    [dispatch, mapId],
  );

  const updateLayerInformation = React.useCallback(
    (payload: UpdateLayerInfoPayload): void => {
      dispatch(layerActions.onUpdateLayerInformation(payload));
    },
    [dispatch],
  );

  const registerMap = React.useCallback(
    (payload: { mapId: string }): void => {
      dispatch(mapActions.registerMap(payload));
    },
    [dispatch],
  );
  const unregisterMap = React.useCallback(
    (payload: { mapId: string }): void => {
      dispatch(mapActions.unregisterMap(payload));
    },
    [dispatch],
  );
  const genericSetBbox = React.useCallback(
    (payload: genericTypes.SetBboxPayload): void => {
      dispatch(genericActions.setBbox(payload));
    },
    [dispatch],
  );
  const syncGroupAddSource = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupsAddSourcePayload): void => {
      dispatch(genericActions.syncGroupAddSource(payload));
    },
    [dispatch],
  );
  const syncGroupRemoveSource = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupRemoveSourcePayload): void => {
      dispatch(genericActions.syncGroupRemoveSource(payload));
    },
    [dispatch],
  );
  const layerError = React.useCallback(
    (payload: layerTypes.ErrorLayerPayload): void => {
      dispatch(layerActions.layerError(payload));
    },
    [dispatch],
  );
  const mapPinChangeLocation = React.useCallback(
    (payload: MapPinLocationPayload): void => {
      dispatch(mapActions.setMapPinLocation(payload));
    },
    [dispatch],
  );
  const setSelectedFeature = React.useCallback(
    (payload: layerTypes.SetSelectedFeaturePayload): void => {
      dispatch(layerActions.setSelectedFeature(payload));
    },
    [dispatch],
  );

  const handleFeatureSelect = (mapId: string, featureId?: string): void => {
    dispatch(
      genericActions.addSharedData({
        panelId: mapId,
        data: { selectedFeatureId: featureId || '' },
      }),
    );
  };

  const updateFeature = React.useCallback(
    ({ geojson, reason, layerId }: layerTypes.UpdateFeaturePayload): void => {
      const activeDrawToolId = drawingToolSelectors.getActiveDrawToolId(
        store.getState(),
      );
      const activeDrawMode = drawingToolSelectors.getActiveDrawMode(
        store.getState(),
        activeDrawToolId,
      );
      dispatch(
        layerActions.updateFeature({
          geojson,
          reason,
          layerId,
          shouldAllowMultipleShapes:
            drawingToolSelectors.getShouldAllowMultipleShapes(
              store.getState(),
              activeDrawToolId,
            ),
          geoJSONIntersectionLayerId:
            drawingToolSelectors.getGeoJSONIntersectionLayerId(
              store.getState(),
              activeDrawToolId,
            ),
          geoJSONIntersectionBoundsLayerId:
            drawingToolSelectors.getGeoJSONIntersectionBoundsLayerId(
              store.getState(),
              activeDrawToolId,
            ),
          selectionType: activeDrawMode?.selectionType,
        }),
      );
    },
    [dispatch, store],
  );

  const exitFeatureDrawMode = React.useCallback(
    ({ reason, layerId }: layerTypes.ExitFeatureDrawModePayload): void => {
      const activeDrawToolId = drawingToolSelectors.getActiveDrawToolId(
        store.getState(),
      );

      dispatch(
        layerActions.exitFeatureDrawMode({
          reason,
          layerId,
          shouldAllowMultipleShapes:
            drawingToolSelectors.getShouldAllowMultipleShapes(
              store.getState(),
              activeDrawToolId,
            ),
        }),
      );
    },
    [dispatch, store],
  );

  const isActiveWindowId = useSelector((store: CoreAppStore) =>
    uiSelectors.getIsActiveWindowId(store, mapId),
  );
  useKeyboardZoomAndPan(isActiveWindowId, mapId, mapRef!.current);
  useTouchZoomPan(isActiveWindowId, mapId);

  const unRegister = React.useCallback(
    (mapId: string): void => {
      unregisterMap({ mapId });
      syncGroupRemoveSource({
        id: mapId,
      });
    },
    [syncGroupRemoveSource, unregisterMap],
  );

  React.useEffect(() => {
    registerMap({ mapId });
    syncGroupAddSource({
      id: mapId,
      type: [
        syncConstants.SYNCGROUPS_TYPE_SETTIME,
        syncConstants.SYNCGROUPS_TYPE_SETBBOX,
      ],
    });
    dispatch(
      loadingIndicatorActions.setGetMapIsLoading({
        id: mapId,
        isGetMapLoading: false,
      }),
    );
  }, [dispatch, mapId, registerMap, syncGroupAddSource]);

  const onGetMapLoadStart = React.useCallback((): void => {
    dispatch(
      loadingIndicatorActions.setGetMapIsLoading({
        id: mapId,
        isGetMapLoading: true,
      }),
    );
  }, [dispatch, mapId]);

  const onGetMapLoadReady = React.useCallback((): void => {
    dispatch(
      loadingIndicatorActions.setGetMapIsLoading({
        id: mapId,
        isGetMapLoading: false,
      }),
    );
  }, [dispatch, mapId]);

  const onGetCapabilitiesLoadStart = React.useCallback((): void => {
    dispatch(
      loadingIndicatorActions.setGetCapabilitiesIsLoading({
        id: mapId,
        isGetCapabilitiesLoading: true,
      }),
    );
  }, [dispatch, mapId]);

  const onGetCapabilitiesLoadReady = React.useCallback((): void => {
    dispatch(
      loadingIndicatorActions.setGetCapabilitiesIsLoading({
        id: mapId,
        isGetCapabilitiesLoading: false,
      }),
    );
  }, [dispatch, mapId]);

  const isTimeScrollingEnabled = useSelector(
    syncGroupsSelectors.isTimeScrollingEnabled,
  );

  const { hoverId } = useSelector((state: CoreAppStore) =>
    genericSelectors.selectSharedData(state, linkedPanelId!),
  );

  const debouncedSetHoverFeature = React.useMemo(
    () =>
      debounce(
        (featureId?: string) =>
          dispatch(
            genericActions.addSharedData({
              panelId: linkedPanelId!,
              data: { hoverId: featureId },
            }),
          ),
        200,
      ),
    [dispatch, linkedPanelId],
  );

  return (
    <div style={{ height: '100%' }} ref={mapRef}>
      <MapView
        {...props}
        mapId={mapId}
        controls={{}}
        linkedFeatures={{
          type: 'FeatureCollection',
          features: linkedFeatures.flatMap(
            (feature) => feature!.geoJSON.features,
          ),
        }}
        isTimeScrollingEnabled={isTimeScrollingEnabled}
        srs={srs}
        bbox={bbox}
        mapPinLocation={
          displayMapPin
            ? selectedFeatureCoordinates || mapPinLocation
            : undefined
        }
        dimensions={mapDimensions}
        activeLayerId={activeLayerId}
        animationDelay={animationDelay}
        timestep={timestep}
        displayMapPin={displayMapPin}
        disableMapPin={disableMapPin}
        onMapChangeDimension={(
          mapDimensionPayload: SetMapDimensionPayload,
        ): void => {
          if (
            mapDimensionPayload &&
            mapDimensionPayload.dimension &&
            mapDimensionPayload.dimension.name &&
            mapDimensionPayload.dimension.name === 'time'
          ) {
            setTime({
              sourceId: mapId,
              origin: `${mapDimensionPayload.origin}==> ${ORIGIN_REACTMAPVIEWCONNECT_ONMAPCHANGEDIMENSION}`,
              value: handleDateUtilsISOString(
                mapDimensionPayload.dimension.currentValue,
              ),
            } as genericTypes.SetTimePayload);
          } else {
            mapChangeDimension(mapDimensionPayload);
          }
        }}
        onUpdateLayerInformation={updateLayerInformation}
        onMapPinChangeLocation={mapPinChangeLocation}
        onMapZoomEnd={(a): void => {
          genericSetBbox({
            bbox: a.bbox,
            srs: a.srs!,
            sourceId: mapId,
            origin: mapEnums.MapActionOrigin.map,
            mapId,
          });
        }}
        onWMJSMount={(mapId): void => {
          const wmjsMap = webmapUtils.getWMJSMapById(mapId);
          if (!wmjsMap) {
            return;
          }
          wmjsMap
            .getListener()
            .addEventListener('onloadstart', onGetMapLoadStart);
          wmjsMap
            .getListener()
            .addEventListener('onloadready', onGetMapLoadReady);

          wmServiceListener.addEventListener(
            EVENT_GETCAPABILITIES_START,
            onGetCapabilitiesLoadStart,
            true,
          );
          wmServiceListener.addEventListener(
            EVENT_GETCAPABILITIES_READY,
            onGetCapabilitiesLoadReady,
            true,
          );
        }}
        onWMJSUnMount={(mapId): void => {
          unRegister(mapId);
          wmServiceListener.removeEventListener(
            EVENT_GETCAPABILITIES_START,
            onGetCapabilitiesLoadStart,
          );
          wmServiceListener.removeEventListener(
            EVENT_GETCAPABILITIES_READY,
            onGetCapabilitiesLoadReady,
          );
        }}
      >
        {baseLayers.map((layer) => (
          <MapViewLayer
            id={`baselayer-${layer.id}`}
            key={layer.id}
            onLayerError={(_, error): void => {
              layerError({
                layerId: layer.id!,
                error: error?.message || 'unknown error',
              });
            }}
            {...layer}
          />
        ))}
        {layers.map((layer: layerTypes.Layer) => (
          <MapViewLayer
            id={`layer-${layer.id}`}
            key={layer.id}
            onLayerError={(_, error): void => {
              layerError({
                layerId: layer.id!,
                error: error?.message || 'unknown error',
              });
            }}
            {...layer}
          />
        ))}

        {linkedFeatures.concat(linkedFormFeatures)?.map((feature) => (
          <MapViewLayer
            id={feature.id}
            key={feature.id}
            geojson={feature?.geoJSON}
            onHoverFeature={(hoverInfo) => {
              if (hoverInfo === undefined && hoverId !== undefined) {
                debouncedSetHoverFeature();
              } else if (hoverInfo?.feature && feature.originalId !== hoverId) {
                debouncedSetHoverFeature(feature.originalId);
              }
            }}
            onClickFeature={(event): void => {
              const isClickOutsideFeature = !event || !event.feature;
              if (isClickOutsideFeature) {
                if (mapPinLocation) {
                  handleFeatureSelect(linkedPanelId!, undefined);
                }
                return;
              }

              // Handle clicks on station
              const clickedFeatureName = event.feature.properties?.name;
              const { features } = linkedFeatures[0].geoJSON;
              const clickedFeatureIndex = features.findIndex(
                (feature) => feature.properties?.name === clickedFeatureName,
              );

              if (disableMapPin === false) {
                const selectedFeature = features[clickedFeatureIndex];
                handleFeatureSelect(
                  linkedPanelId!,
                  selectedFeature.id?.toString()!,
                );
              }
            }}
          />
        ))}

        {featureLayers.map((layer: layerTypes.Layer) => (
          <MapViewLayer
            id={`featurelayer-${layer.id}`}
            key={layer.id}
            onLayerError={(_, error): void => {
              layerError({
                layerId: layer.id!,
                error: error?.message || 'unknown error',
              });
            }}
            onClickFeature={(event): void => {
              const isClickOutsideFeature = !event;
              if (isClickOutsideFeature && !layer.isInEditMode) {
                setSelectedFeature({
                  layerId: layer.id!,
                  selectedFeatureIndex: undefined,
                });
                return;
              }
              if (event?.isInEditMode === false) {
                setSelectedFeature({
                  layerId: layer.id!,
                  selectedFeatureIndex: event.featureIndex,
                });
              }
            }}
            updateGeojson={(
              geojson: GeoJSON.FeatureCollection,
              reason: string,
            ): void => {
              updateFeature({
                geojson,
                layerId: layer.id!,
                reason,
              });
            }}
            exitDrawModeCallback={(reason: string): void =>
              exitFeatureDrawMode({
                reason,
                layerId: layer.id!,
              })
            }
            {...layer}
          />
        ))}
        {overLayers.map((layer) => (
          <MapViewLayer
            id={`baselayer-${layer.id}`}
            key={layer.id}
            onLayerError={(_, error): void => {
              layerError({
                layerId: layer.id!,
                error: error?.message || 'unknown error',
              });
            }}
            {...layer}
          />
        ))}
        {children}
      </MapView>
      <MapControls
        data-testid="mapControls"
        style={{ top: controls?.mapControlsPositionTop }}
      >
        {controls?.search && <SearchControlButtonConnect mapId={mapId} />}
        {controls?.zoomControls && <ZoomControlConnect mapId={mapId} />}
        {controls?.layerManagerAndLegend && (
          <>
            <LayerManagerMapButtonConnect mapId={mapId} />
            <LegendMapButtonConnect
              mapId={mapId}
              multiLegend={controls?.multiLegend}
            />
          </>
        )}
        {controls?.dimensionSelect && (
          <MultiDimensionSelectMapButtonsConnect mapId={mapId} />
        )}
        {controls?.getFeatureInfo && (
          <GetFeatureInfoButtonConnect mapId={mapId} />
        )}
        {controls?.additionalMapControls}
      </MapControls>
      {controls?.multiLegend && (
        <LegendConnect
          showMapId
          mapId={mapId}
          multiLegend={controls?.multiLegend}
        />
      )}
      {controls?.search && <SearchDialogConnect mapId={mapId} />}
      {controls?.getFeatureInfo && (
        <GetFeatureInfoConnect showMapId mapId={mapId} />
      )}
      <LayerManagerConnect mapId={mapId} bounds="parent" isDocked />
    </div>
  );
};

export default MapViewConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import ReactECharts from 'echarts-for-react';
import {
  EChartsDarkTheme,
  EChartsLightTheme,
  useThemeContext,
} from '@opengeoweb/theme';
import { PlotWithData } from './types';
import { getOption, PLOT_HEIGHT } from './chartUtils';

export const TimeSeriesChart: React.FC<{
  plotsWithData: PlotWithData[];
  animation?: boolean;
}> = ({ plotsWithData, animation = false }) => {
  const { namedTheme } = useThemeContext();
  const isDark = namedTheme?.theme.palette.mode === 'dark';
  const option = getOption(plotsWithData);
  const chartHeight = `${100 + PLOT_HEIGHT * plotsWithData.length}px`;

  return option ? (
    <div data-testid="TimeSeriesChart">
      <ReactECharts
        theme={isDark ? EChartsDarkTheme : EChartsLightTheme}
        option={{
          ...option,
          animation,
        }}
        style={{ background: 'white', height: chartHeight }}
        notMerge={true}
      />
    </div>
  ) : null;
};

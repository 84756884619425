/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { CSSProperties } from 'react';
import { escapeRegExp } from 'lodash';

export interface HighlightProps {
  text: string;
  search: string;
}

const highlightStyle: CSSProperties = {
  background: '#ffeecc',
};

const SearchHighlight: React.FC<HighlightProps> = ({ text = '', search }) => {
  if (search === '') {
    return <span>{text}</span>;
  }

  const searchWords = search
    .split(' ')
    .reduce(
      (words: string[], word: string) =>
        word ? [...words, word.toLocaleLowerCase()] : words,
      [],
    );
  const searchRegex = searchWords.map((word) => escapeRegExp(word)).join('|');
  const textParts = text.split(new RegExp(`(${searchRegex})`, 'gi'));

  return (
    <>
      {textParts.map((part, index) => {
        const key = `${part}-${index}`;
        if (
          searchWords.some((search) =>
            part.toLocaleLowerCase().includes(search),
          )
        ) {
          return (
            <mark key={key} style={highlightStyle} data-testid="mark">
              {part}
            </mark>
          );
        }
        return <span key={key}>{part}</span>;
      })}
    </>
  );
};

export default SearchHighlight;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { MenuItem, Typography, SelectChangeEvent, Box } from '@mui/material';

import { AlertIcon, TooltipSelect } from '@opengeoweb/shared';
import { LayerProps } from '@opengeoweb/webmap';
import { useCoreTranslation } from '../../../../../utils/i18n';

export interface RenderLayersProps {
  onChangeLayerName: (name: string) => void;
  layerName: string;
  layers: LayerProps[];
  isEnabled?: boolean;
  tooltipPrefix?: string;
  skipLocalStyling?: boolean;
}

const getRenderLayersValues = (
  layerName: string,
  layers: LayerProps[],
): {
  currentValue: string;
  currentIndex: number;
  extendedLayers: LayerProps[];
} => {
  const serviceLayer = layers.find((l) => l.name === layerName);
  if (!serviceLayer && layerName) {
    return {
      currentValue: layerName,
      currentIndex: layers.length,
      extendedLayers: [
        ...layers,
        {
          name: layerName,
          title: layerName,
          leaf: true,
          path: [],
        },
      ],
    };
  }
  return {
    currentValue: serviceLayer?.name || '',
    currentIndex: layers.findIndex((l) => l.name === serviceLayer?.name),
    extendedLayers: layers,
  };
};

const RenderLayers: React.FC<RenderLayersProps> = ({
  onChangeLayerName,
  layerName,
  layers = [],
  isEnabled = true,
  tooltipPrefix,
  skipLocalStyling,
}: RenderLayersProps) => {
  const { t } = useCoreTranslation();
  const nonEmptyTooltipPrefix = tooltipPrefix ?? `${t('layermanager-layer')}: `;

  if (!layers || !layers.length) {
    return (
      <Box
        sx={{
          paddingLeft: 1,
          height: '32px',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: (theme): number | string =>
              theme.palette.geowebColors.layerManager.tableRowDefaultText
                .fontSize!,
            color: 'geowebColors.layerManager.tableRowDefaultText.rgba',
            fontWeight: 500,
          }}
        >
          {t('layermanager-baselayers-no-available')}
        </Typography>
      </Box>
    );
  }

  const selectLayer = (event: SelectChangeEvent<unknown>): void => {
    event.stopPropagation();
    onChangeLayerName(event.target.value as string);
  };

  const { currentIndex, currentValue, extendedLayers } = getRenderLayersValues(
    layerName,
    layers,
  );

  const list = extendedLayers.map((layer) => ({
    value: layer.name!,
  }));

  return (
    <TooltipSelect
      disableUnderline
      tooltip={`${nonEmptyTooltipPrefix}${extendedLayers[currentIndex]?.title}`}
      inputProps={{
        SelectDisplayProps: {
          'data-testid': 'selectLayer',
        },
      }}
      isEnabled={isEnabled}
      value={currentValue}
      list={list}
      currentIndex={currentIndex}
      onChange={selectLayer}
      onChangeMouseWheel={(e): void => onChangeLayerName(e.value)}
      requiresCtrlToChange={true}
      skipLocalStyling={skipLocalStyling}
      renderValue={(value): string =>
        extendedLayers.find((layer): boolean => layer.name === value)!.title!
      } // Omit possible warning sign in the selected item due to layout effects
    >
      <MenuItem disabled>{t('layermanager-layer')}</MenuItem>
      {extendedLayers.map(
        (layerFromServiceLayers: LayerProps, currentIndex) => (
          <MenuItem
            key={layerFromServiceLayers.name}
            value={layerFromServiceLayers.name!}
            disabled={!layerFromServiceLayers.leaf!} // show categories as disabled
            sx={{ '& .MuiSvgIcon-root': { marginLeft: '28px' } }}
          >
            {layerFromServiceLayers.title}
            {currentIndex === layers.length && <AlertIcon severity="error" />}
          </MenuItem>
        ),
      )}
    </TooltipSelect>
  );
};

export default RenderLayers;

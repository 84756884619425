/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { TimeSeriesService, ToolContainerDraggable } from '@opengeoweb/shared';
import Box from '@mui/material/Box';
import { uiTypes } from '@opengeoweb/store';
import {
  Grid2 as Grid,
  LinearProgress,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { DimensionsTime, DrawRegion } from '@opengeoweb/theme';
import { EDRInstance, Parameter } from '../../TimeSeries/types';
import { useTimeseriesTranslation } from '../../../utils/i18n';

export type InstanceInformation =
  | {
      isLoading: true;
    }
  | {
      isLoading: false;
      instances: EDRInstance[];
    };

export interface ParameterInfoDialogProps {
  currentParameterInfo: Parameter;
  relatedService: TimeSeriesService | undefined;
  instanceInformation: InstanceInformation;
  onClose: () => void;
  isOpen: boolean;
  onMouseDown?: () => void;
  order?: number;
  source?: uiTypes.Source;
  dialogHeight?: number;
}

export const ParameterInfoDialog: React.FC<ParameterInfoDialogProps> = ({
  onClose,
  currentParameterInfo,
  instanceInformation,
  relatedService,
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'app',
  dialogHeight,
}: ParameterInfoDialogProps) => {
  const { t } = useTimeseriesTranslation();
  return (
    <ToolContainerDraggable
      onClose={onClose}
      startSize={{ width: 288, height: dialogHeight || 650 }}
      startPosition={{ top: 150, left: 790 }}
      title={t('timeseries-info-title')}
      isOpen={isOpen}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
    >
      <Box sx={{ padding: 2 }}>
        <ParameterInfoText
          label={t('timeseries-info-name')}
          value={currentParameterInfo.propertyName}
        />
        <ParameterInfoText
          label={t('timeseries-info-service')}
          value={relatedService?.name || currentParameterInfo.serviceId}
        />
        {relatedService && (
          <>
            <ParameterInfoText
              label={t('timeseries-info-service-url')}
              value={relatedService.url}
            />
            <ParameterInfoText
              label={t('timeseries-info-service-description')}
              value={relatedService.description}
            />
            <ParameterInfoText
              label={t('timeseries-info-service-type')}
              value={relatedService.type}
            />
          </>
        )}
        <ParameterInfoText
          label={t('timeseries-collection')}
          value={currentParameterInfo.collectionId}
        />
        <QueryInfoElement instanceInformation={instanceInformation} />
        <InstanceInfoElement instanceInformation={instanceInformation} />
      </Box>
    </ToolContainerDraggable>
  );
};

interface LoadingIndicatorProps {
  subtitle: string;
  textStyle: SxProps<Theme>;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  subtitle,
  textStyle,
}) => {
  return (
    <>
      <Typography variant="subtitle2" sx={textStyle}>
        {subtitle}
      </Typography>
      <Box sx={{ position: 'relative', marginBottom: 3 }}>
        <LinearProgress
          data-testid="loadingIndicator"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 8 }}
        />
      </Box>
    </>
  );
};

export const QueryInfoElement: React.FC<{
  instanceInformation: InstanceInformation;
}> = ({ instanceInformation }) => {
  const { t } = useTimeseriesTranslation();

  if (instanceInformation.isLoading) {
    return (
      <LoadingIndicator
        subtitle={t('timeseries-info-queries')}
        textStyle={textStyle}
      />
    );
  }

  const queryInfo =
    instanceInformation.instances.length === 0
      ? t('timeseries-info-no-data-available')
      : ((): string => {
          const instance = instanceInformation.instances[0];
          const queries = [
            instance.data_queries?.locations && 'locations',
            instance.data_queries?.position && 'position',
          ].filter(Boolean);
          return queries.length === 0
            ? t('timeseries-info-no-data-available')
            : queries.join(', ');
        })();

  return (
    <>
      <Typography variant="subtitle2" sx={textStyle}>
        {t('timeseries-info-queries')}
      </Typography>
      <Typography variant="body2" sx={textStyleWithMargin}>
        {queryInfo}
      </Typography>
    </>
  );
};

const InstanceInfoElement: React.FC<{
  instanceInformation: InstanceInformation;
}> = ({ instanceInformation }) => {
  const { t } = useTimeseriesTranslation();
  if (instanceInformation.isLoading) {
    return (
      <LoadingIndicator
        subtitle={t('timeseries-info-instance-dimensions')}
        textStyle={textStyle}
      />
    );
  }
  return (
    <>
      <Typography variant="subtitle2" sx={textStyle}>
        {t('timeseries-info-instance-dimensions')}
      </Typography>
      {instanceInformation.instances.length === 0 ? (
        <Typography variant="body2" sx={textStyleWithMargin}>
          {t('timeseries-info-no-data-available')}
        </Typography>
      ) : (
        [...instanceInformation.instances]
          .sort()
          .reverse()
          .map((instance) => {
            const infoList: ListItem[] = [];
            if (instance.extent?.temporal?.values) {
              infoList.push({
                label: t('timeseries-info-time'),
                icon: <DimensionsTime />,
                value: instance.extent.temporal.values.join(', '),
              });
            }
            if (instance.extent?.spatial.bbox) {
              const value = t('timeseries-info-bbox-rendering', {
                west: instance.extent.spatial.bbox[0],
                south: instance.extent.spatial.bbox[1],
                east: instance.extent.spatial.bbox[2],
                north: instance.extent.spatial.bbox[3],
              });
              infoList.push({
                label: t('timeseries-info-bbox'),
                icon: <DrawRegion />,
                value:
                  value === 'timeseries-info-bbox-rendering'
                    ? instance.extent.spatial.bbox.join(', ')
                    : value,
              });
            }
            return (
              <ParameterInfoList
                key={instance.id}
                label={instance.id}
                list={infoList}
              />
            );
          })
      )}
    </>
  );
};

export interface ParameterInfoTextProps {
  label: string;
  value: string;
}

const textStyle = {
  lineHeight: '20px',
  letterSpacing: '0.25px',
};

const textStyleWithMargin = {
  ...textStyle,
  marginBottom: 1,
};

const ParameterInfoText: React.FC<ParameterInfoTextProps> = ({
  label,
  value,
}: ParameterInfoTextProps) => {
  return (
    <>
      <Typography variant="subtitle2" sx={textStyle}>
        {label}
      </Typography>
      <Typography variant="body2" sx={textStyleWithMargin}>
        {value}
      </Typography>
    </>
  );
};

export interface ListItem {
  label: string;
  value: string;
  icon: React.ReactElement;
}

export interface ParameterInfoListProps {
  label: string;
  list: ListItem[];
}

const valueStyle = {
  ...textStyle,
  overflowWrap: 'break-word',
};

export const ParameterInfoList: React.FC<ParameterInfoListProps> = ({
  label,
  list,
}: ParameterInfoListProps) => {
  return (
    <>
      <Typography variant="subtitle2" sx={textStyle}>
        {label}
      </Typography>
      {list.length === 0 ? (
        <Typography variant="body2" sx={textStyleWithMargin}>
          -
        </Typography>
      ) : (
        <Grid container direction="column">
          {list.map((item) => (
            <div key={item.label}>
              <Grid container direction="row">
                {item.icon}
                <Grid sx={{ marginLeft: '10px' }}>
                  <Typography variant="body2" sx={textStyle}>
                    {item.label}:
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ marginBottom: 1, paddingLeft: '34px' }}
              >
                <Typography variant="body2" sx={valueStyle}>
                  {item.value}
                </Typography>
              </Grid>
            </div>
          ))}
        </Grid>
      )}
    </>
  );
};

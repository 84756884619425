/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { selectorMemoizationOptions } from '@opengeoweb/store';
import { PresetScope } from '@opengeoweb/shared';
import type { AppStore } from '../types';
import { ViewPresetListItem } from '../workspace/types';
import { initialState, viewPresetsAdapter } from './reducer';
import {
  ViewPresetEntity,
  ViewPresetError,
  ViewPresetsListFilter,
  ViewPresetState,
} from './types';

export const getViewPresetsStore = (store: AppStore): ViewPresetState => {
  if (store && store.viewPresets) {
    return store.viewPresets;
  }
  return initialState;
};

const selectors = viewPresetsAdapter.getSelectors((state: AppStore) => {
  return state?.viewPresets ?? { entities: {}, ids: [] };
});

export const { selectAll: getViewPresets, selectById: getViewPresetById } =
  selectors;

export const getViewPresetsIsFetching = createSelector(
  getViewPresetById,
  (_store: AppStore, panelId: string) => panelId,
  (viewPreset: ViewPresetEntity | undefined) => {
    return viewPreset?.isFetching || false;
  },
  selectorMemoizationOptions,
);

export const getViewPresetsError = createSelector(
  getViewPresetById,
  (_store: AppStore, panelId: string) => panelId,
  (viewPreset: ViewPresetEntity | undefined) => {
    return viewPreset?.error;
  },
  selectorMemoizationOptions,
);

export const getViewPresetDetailError = createSelector(
  getViewPresetsError,
  (_store: AppStore, panelId: string) => panelId,
  (error: ViewPresetError) => {
    if (error && error.component === 'PRESET_DETAIL') {
      return error.message;
    }
    return undefined;
  },
  selectorMemoizationOptions,
);

export const getViewPresetHasChanges = createSelector(
  getViewPresetById,
  (_store: AppStore, panelId: string) => panelId,
  (viewPreset: ViewPresetEntity | undefined) => {
    return viewPreset?.hasChanges;
  },
  selectorMemoizationOptions,
);

export const getViewPresetActiveId = createSelector(
  getViewPresetById,
  (_store: AppStore, panelId: string) => panelId,
  (viewPreset: ViewPresetEntity | undefined) => {
    return viewPreset?.activeViewPresetId;
  },
  selectorMemoizationOptions,
);

export const getViewPresetPanelId = createSelector(
  getViewPresets,
  (store: AppStore, mapId: string) => mapId,
  (viewPresets: ViewPresetEntity[], mapId) => {
    return (
      viewPresets.find(
        (preset: ViewPresetEntity) => preset.activeViewPresetId === mapId,
      )?.panelId ?? ''
    );
  },
  selectorMemoizationOptions,
);

export const getDialogOptions = createSelector(
  getViewPresetsStore,
  (store: AppStore) => store,
  (store) => {
    return store.viewPresetDialog || undefined;
  },
  selectorMemoizationOptions,
);

export const getIsViewPresetListDialogOpen = createSelector(
  getViewPresetById,
  (_store: AppStore, panelId: string) => panelId,
  (viewPreset: ViewPresetEntity | undefined) => {
    return viewPreset?.isViewPresetListDialogOpen || false;
  },
  selectorMemoizationOptions,
);

export const getViewPresetListFiltersForView = createSelector(
  getViewPresetById,
  (_store: AppStore, _panelId: string, scope?: PresetScope) => scope,
  (viewPreset: ViewPresetEntity | undefined, scope?: PresetScope) => {
    const filters = viewPreset?.filters || [];
    // no filters for system presets for now
    if (scope === 'system') {
      return filters.reduce<ViewPresetsListFilter[]>((list, filter) => {
        if (filter.id === 'system') {
          return list.concat({ ...filter, isDisabled: true });
        }
        return list;
      }, []);
    }
    return filters;
  },
  selectorMemoizationOptions,
);

export const getViewPresetListSearchQueryForView = createSelector(
  getViewPresetById,
  (_store: AppStore, panelId: string) => panelId,
  (viewPreset: ViewPresetEntity | undefined) => {
    return viewPreset?.searchQuery || '';
  },
  selectorMemoizationOptions,
);

// Used to retrieve the filtered view preset list for your view - if no filters/search applied it will be empty
// To ensure you retrieve the view preset list specific for your view (incl and excl filters), use getFilteredViewPresetListForView
export const getViewPresetListFilterResultsForView = createSelector(
  getViewPresetById,
  (viewPresetById): ViewPresetListItem[] => {
    const filteredResults = viewPresetById?.filterResults || {
      entities: {},
      ids: [],
    };
    return Object.values(filteredResults.entities) as ViewPresetListItem[];
  },
  selectorMemoizationOptions,
);

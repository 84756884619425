/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { DraggablePosition } from './types';

export interface DraggableComponentProps {
  nodeRef?: React.RefObject<HTMLElement>;
  children?: React.ReactNode;
  bounds?: 'parent' | string;
  cancel?: string;
  position?: DraggablePosition;
  onStop?: (event: DraggableEvent, pos: DraggablePosition) => void;
  onMouseDown?: (event: MouseEvent) => void;
  handle?: string;
  setIsDragging?: (isDragging: boolean) => void;
}

const DraggableComponent: React.FC<DraggableComponentProps> = ({
  bounds = 'body',
  setIsDragging,
  children,
  onStop,
  ...props
}: DraggableComponentProps) => {
  const onDragStart = (e: DraggableEvent): void => {
    // This makes sure that nothing is selected during drag
    setIsDragging && setIsDragging(true);
    e.preventDefault();
  };

  const onDragStop = (event: DraggableEvent, pos: DraggableData): void => {
    setIsDragging && setIsDragging(false);
    onStop && onStop(event, pos);
  };

  return (
    <Draggable
      bounds={bounds}
      {...props}
      onStop={(event, pos) => onDragStop(event, pos)}
      enableUserSelectHack={false}
      onStart={onDragStart}
    >
      {children}
    </Draggable>
  );
};

export default DraggableComponent;

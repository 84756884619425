/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import {
  Clouds,
  CloudCoverage,
  CloudType,
  Cloud,
  VerticalVisibility,
} from '../../../../types';
import { getFieldValue, prependZeroes } from './utils';

interface CloudStrings {
  cloud1: string;
  cloud2?: string;
  cloud3?: string;
  cloud4?: string;
}

// formatters
const formatSingleCloud = (value: Cloud): string => {
  if (!value) {
    return null!;
  }
  const height = !value.height ? '000' : prependZeroes(value.height, 3);

  return value.type
    ? `${value.coverage}${height}${value.type}`
    : `${value.coverage}${height}`;
};

export const formatCloudsToString = (clouds: Clouds): CloudStrings => {
  if (!clouds) {
    return null!;
  }

  return (Object.keys(clouds) as (keyof Clouds)[]).reduce((list, key) => {
    // only format it if it has a value
    if (clouds[key]) {
      switch (key) {
        case 'cloud1': {
          if (
            (clouds[key] as VerticalVisibility).verticalVisibility !== undefined
          ) {
            // Format Vertical Visibility
            return {
              ...list,
              [key]: `VV${prependZeroes(
                (clouds[key] as VerticalVisibility).verticalVisibility!,
                3,
              )}` as unknown as VerticalVisibility,
            };
          }
          if ((clouds[key] as Cloud).coverage) {
            // Format Cloud
            return {
              ...list,
              [key]: formatSingleCloud(
                clouds[key] as Cloud,
              ) as unknown as Cloud,
            };
          }

          // Format No Significant Clouds
          return {
            ...list,
            [key]: clouds[key],
          };
        }
        default: {
          // Other cloud keys can only contain a cloud
          return {
            ...list,
            [key]: formatSingleCloud(clouds[key] as Cloud),
          };
        }
      }
    }
    return list;
  }, clouds) as unknown as CloudStrings;
};

// parsers
const parseSingleCloud = (value: string): Cloud | null => {
  if (!value) {
    return null;
  }

  const trimmedCloud = value.trim();
  const cloudType = trimmedCloud.substring(6, 9);

  return {
    coverage: trimmedCloud.substring(0, 3) as CloudCoverage,
    height: parseInt(trimmedCloud.substring(3, 6), 10),
    ...(cloudType && { type: cloudType as CloudType }),
  };
};

export const parseCloudsToObject = (clouds: CloudStrings): Clouds => {
  if (!clouds) {
    return null!;
  }

  return (Object.keys(clouds) as (keyof Clouds)[]).reduce(
    (list, key): Clouds => {
      // only parse it if it has a value
      if (clouds[key] && String(clouds[key]).trim()) {
        switch (key) {
          case 'cloud1': {
            const trimmedValue = String(clouds[key]).trim();
            if (isVerticalVisibility(trimmedValue)) {
              // Parse Vertical Visibility
              return {
                ...list,
                [key]: {
                  verticalVisibility: parseInt(
                    trimmedValue.substring(2, 5),
                    10,
                  ),
                },
              };
            }
            if (trimmedValue === 'NSC') {
              // Parse No Significant Clouds
              return {
                ...list,
                [key]: trimmedValue,
              };
            }
            // Parse Cloud
            return {
              ...list,
              [key]: parseSingleCloud(trimmedValue) as Cloud,
            };
          }
          default: {
            // Other cloud keys can only contain a cloud
            return {
              ...list,
              [key]: parseSingleCloud(String(clouds[key])),
            };
          }
        }
      }
      // if it doesn't have a value, remove it from the list
      const { [key]: emptyKey, ...rest } = list;
      return rest as Clouds;
    },
    clouds as Clouds,
  );
};

// messages
export const invalidCloud1Message =
  'Invalid cloud input, expected either <coverage><height><type(optional)> with coverage FEW, SCT, BKN or OVC and height consisting of 3 digits or NSC';
export const invalidCloudMessage =
  'Invalid cloud input, expected <coverage><height><type(optional)> with coverage FEW, SCT, BKN or OVC and height consisting of 3 digits';
export const invalidCloudCoverageMessage =
  'Invalid cloud coverage, expected FEW, SCT, BKN or OVC';
export const invalidCloudHeightMessage =
  'Invalid cloud height, expected a height consisting of 3 digits';
export const invalidCloudHeightStepsMessage =
  'Invalid cloud height, a height above 050 must be rounded to the nearest 1000ft';
export const invalidCLoudTypeMessage = 'Invalid cloud type, expected CB or TCU';
export const invalidHeightTypeCombinationMessage =
  'Invalid cloud input, when cloud height is 050 or higher, CB or TCU is required';
export const invalidVerticalVisibilityMessage =
  'Invalid vertical visibility, expected VV<value> with value consisting of 3 digits';
export const invalidVerticalVisibilityHeightMessage =
  'Invalid vertical visibility, visibility should lie between 001 and 010';
export const invalidVerticalVisibilityHeightFGMessage =
  'Invalid vertical visibility, in case of fog visibility should lie between 001 and 005';
export const invalidCloudHeightComparedToPreviousMessage =
  'Invalid cloud height, height must be greater than previous cloud group height';
export const invalidCloud2CoverageTypeCombinationMessage =
  'Invalid cloud input, for the second cloud group (excluding any significant convective cloud groups with CB/TCU) a coverage of FEW is not allowed';
export const invalidCloud2WithVerticalVisibilityMessage =
  'Invalid cloud input, cloud type is required when Vertical Visibility is given';
export const invalidCloud3CoverageTypeCombinationMessage =
  'Invalid cloud input, for the third cloud group (excluding any significant convective cloud groups with CB/TCU) a coverage of FEW or SCT is not allowed';
export const invalidCloudWithVerticalVisibilityMessage =
  'Invalid cloud input, third or fourth cloud group is not allowed when Vertical Visibility is given';
export const invalidCloudWithPreviousEmptyCloud =
  'Invalid cloud input, value is not allowed when previous cloud group is empty';
export const invalidCloudWithNSCMessage =
  'Invalid cloud input, other cloud groups are not allowed when No Significant Clouds is given';
export const invalidCloudWithPreviousType =
  'Invalid cloud input, only one significant convective cloud group with CB/TCU is allowed';
export const invalidCloudCAVOKMessage =
  'Invalid cloud input, when CAVOK is used, cloud groups must be empty';
export const invalidCloud4TypeMissingMessage =
  'Invalid cloud input for fourth group, CB or TCU required if no other significant convective cloud group present';

// validations
const isLengthValid = (value: string): boolean => {
  const onlyDigitsOrSpecificLetters = /^[0-9FEWSCTBKNOVU]+$/;
  return (
    value.length >= 6 &&
    value.length <= 9 &&
    !!value.match(onlyDigitsOrSpecificLetters)
  );
};

const isCoverageValid = (coverage: string): boolean => {
  return !!(
    coverage === 'FEW' ||
    coverage === 'SCT' ||
    coverage === 'BKN' ||
    coverage === 'OVC'
  );
};

const isHeightValid = (height: string): boolean | string => {
  // validate if its a number
  if (!height.match(/^[0-9]+$/)) {
    return invalidCloudHeightMessage;
  }

  // check if its 3 digits
  if (height.length !== 3) {
    return invalidCloudHeightMessage;
  }

  // Parse to integer to check for steps
  const intHeight = parseInt(height, 10);
  if (intHeight > 49) {
    return intHeight % 10 === 0 || invalidCloudHeightStepsMessage;
  }

  return true;
};

const isTypeValid = (type: string): boolean => {
  return !!(type === '' || type === 'TCU' || type === 'CB');
};

const isHeightAllowedWithType = (height: string, type: string): boolean => {
  const intHeight = parseInt(height, 10);
  // From 5000ft or higher CB or TCU is required
  if (intHeight >= 50) {
    return !!(type === 'CB' || type === 'TCU');
  }
  return true;
};

const isVerticalVisibility = (value: string): boolean =>
  value.substring(0, 2) === 'VV';

const isVerticalVisibilityValid = (
  value: string,
  weather1: string,
  weather2: string,
  weather3: string,
): string | boolean => {
  // validate if its a number
  if (!value.match(/^[0-9]+$/)) {
    return invalidVerticalVisibilityMessage;
  }

  // check if its 3 digits
  if (value.length !== 3) {
    return invalidVerticalVisibilityMessage;
  }

  const intValue = parseInt(value, 10);
  if (weather1 !== 'FG' && weather2 !== 'FG' && weather3 !== 'FG') {
    // value should not be above 10
    if (intValue < 1 || intValue > 10) {
      return invalidVerticalVisibilityHeightMessage;
    }
  } else if (intValue < 1 || intValue > 5) {
    // Apply Dutch rule as national exception
    // In case of fog (FG) the vertical visibility can only be between VV001 up to and including VV005
    // HEIGHT_VV is: (001-(1)-005). Deze maxima zijn officiële Nederlandse afwijkingen van de WMO/ICAO regelgeving
    return invalidVerticalVisibilityHeightFGMessage;
  }

  return true;
};

interface CloudProps {
  coverage: string;
  height: string;
  type: string;
  errorMessages: string[];
}

interface SharedCloudValidationsProps {
  value: string;
  canBeNSC?: boolean;
}

const sharedCloudValidations = ({
  value,
  canBeNSC = false,
}: SharedCloudValidationsProps): CloudProps => {
  const errorMessages: string[] = [];
  // Validate length and ensure it doesn't contain any invalid characters
  if (!isLengthValid(value)) {
    errorMessages.push(canBeNSC ? invalidCloud1Message : invalidCloudMessage);
  }

  // Validate coverage
  const coverage = value.substring(0, 3);
  if (!isCoverageValid(coverage)) {
    errorMessages.push(invalidCloudCoverageMessage);
  }

  // Validate height
  const height = value.substring(3, 6);
  const isValidHeight = isHeightValid(height);
  if (isValidHeight !== true) {
    // Return height errors if any
    errorMessages.push(isValidHeight as string);
  }

  // Validate type
  const type = value.substring(6, 9);
  if (!isTypeValid(type)) {
    errorMessages.push(invalidCLoudTypeMessage);
  }

  // Validate combination of height and type
  if (!isHeightAllowedWithType(height, type)) {
    errorMessages.push(invalidHeightTypeCombinationMessage);
  }

  return { coverage, height, type, errorMessages };
};

export const validateFirstCloud = (
  value: string,
  visibilityValue: string,
  weatherfield1: string,
  weatherfield2: string,
  weatherfield3: string,
): boolean | string => {
  const trimmedValueFirstCloud = getFieldValue(value);
  const trimmedValueVisibility = getFieldValue(visibilityValue);
  const trimmedWeather1Value = getFieldValue(weatherfield1);
  const trimmedWeather2Value = getFieldValue(weatherfield2);
  const trimmedWeather3Value = getFieldValue(weatherfield3);

  // Field can be left empty
  if (!trimmedValueFirstCloud.length) {
    return true;
  }

  if (trimmedValueFirstCloud && trimmedValueVisibility === 'CAVOK') {
    return invalidCloudCAVOKMessage;
  }

  // Check for No Signicant Clouds
  if (trimmedValueFirstCloud === 'NSC') {
    return true;
  }

  // Validate Vertical Visibility
  if (isVerticalVisibility(trimmedValueFirstCloud)) {
    if (
      // Validate length of 5
      trimmedValueFirstCloud.length !== 5 ||
      // Ensure it only contains digits or VV
      !trimmedValueFirstCloud.match(/^[0-9V]+$/)
    ) {
      return invalidVerticalVisibilityMessage;
    }
    const verticalVisibilityValue = trimmedValueFirstCloud.substring(2, 5);
    return isVerticalVisibilityValid(
      verticalVisibilityValue,
      trimmedWeather1Value,
      trimmedWeather2Value,
      trimmedWeather3Value,
    );
  }

  const { errorMessages } = sharedCloudValidations({
    value: trimmedValueFirstCloud,
    canBeNSC: true,
  });

  // Return first error if any
  return errorMessages.length ? errorMessages[0] : true;
};

export const validateSecondCloud = (
  firstCloudValue: string,
  secondCloudValue: string,
  visibilityFieldValue: string,
): boolean | string => {
  const trimmedValueFirstCloud = getFieldValue(firstCloudValue);
  const trimmedValueSecondCloud = getFieldValue(secondCloudValue);
  const visibilityValue = getFieldValue(visibilityFieldValue);

  // Field can be left empty
  if (!trimmedValueSecondCloud.length) {
    return true;
  }

  // If Cloud1 is NSC, Cloud2 has to be empty
  if (trimmedValueFirstCloud === 'NSC' && trimmedValueSecondCloud !== '') {
    return invalidCloudWithNSCMessage;
  }

  // If Cloud1 is empty Cloud2 should be empty
  if (trimmedValueFirstCloud === '') {
    return invalidCloudWithPreviousEmptyCloud;
  }

  // Generic validations
  const { coverage, height, type, errorMessages } = sharedCloudValidations({
    value: trimmedValueSecondCloud,
  });
  // Return first error if any
  if (errorMessages.length) {
    return errorMessages[0];
  }

  // Cloud2 specific validations - only to be applied when cloud1 does not contain type CB/TCU
  const typeCloud1 = trimmedValueFirstCloud.substring(6, 9);
  if (typeCloud1 !== 'CB' && typeCloud1 !== 'TCU') {
    // Without a type specified in cloud group 1, this group counts as #2 and coverage can not be FEW
    if (type === '' && coverage === 'FEW') {
      return invalidCloud2CoverageTypeCombinationMessage;
    }
  }
  // If Cloud1 is not valid, skip further validation of Cloud2
  // No need to pass the weatherfields as their content does not influence what happens below
  if (
    validateFirstCloud(trimmedValueFirstCloud, visibilityValue, '', '', '') !==
    true
  ) {
    return true;
  }

  // If Cloud1 contains a type, Cloud2 cannot contain a type
  if (type !== '' && typeCloud1 !== '') {
    return invalidCloudWithPreviousType;
  }

  // Cloud2 height has to be greater than Cloud1 height
  if (!isVerticalVisibility(trimmedValueFirstCloud)) {
    const cloud1Height = trimmedValueFirstCloud.substring(3, 6);
    if (height <= cloud1Height) {
      return invalidCloudHeightComparedToPreviousMessage;
    }
  }

  // If Cloud1 contains Vertical Visibility then Cloud2 type is required
  if (isVerticalVisibility(trimmedValueFirstCloud) && type === '') {
    return invalidCloud2WithVerticalVisibilityMessage;
  }

  return true;
};

export const validateThirdCloud = (
  firstCloudValue: string,
  secondCloudValue: string,
  thirdCloudValue: string,
  visibilityFieldValue: string,
): boolean | string => {
  const trimmedValueFirstCloud = getFieldValue(firstCloudValue);
  const trimmedValueSecondCloud = getFieldValue(secondCloudValue);
  const trimmedValueThirdCloud = getFieldValue(thirdCloudValue);
  const visibilityValue = getFieldValue(visibilityFieldValue);

  // Field can be left empty
  if (!trimmedValueThirdCloud.length) {
    return true;
  }

  // If Cloud1 is NSC, Cloud3 has to be empty
  if (trimmedValueFirstCloud === 'NSC' && trimmedValueThirdCloud !== '') {
    return invalidCloudWithNSCMessage;
  }

  // If Cloud1 or Cloud2 is empty Cloud3 should be empty
  if (trimmedValueFirstCloud === '' || trimmedValueSecondCloud === '') {
    return invalidCloudWithPreviousEmptyCloud;
  }

  // Generic validations
  const { coverage, height, type, errorMessages } = sharedCloudValidations({
    value: trimmedValueThirdCloud,
  });
  // Return generic errors if any
  if (errorMessages.length) {
    return errorMessages[0];
  }

  // Cloud3 specific validations
  // Without a type specified in cloud group 1 or 2, this group counts as #3 and coverage can not be FEW or SCT
  const typeCloud1 = trimmedValueFirstCloud.substring(6, 9);
  const typeCloud2 = trimmedValueSecondCloud.substring(6, 9);
  if (
    typeCloud1 !== 'CB' &&
    typeCloud1 !== 'TCU' &&
    typeCloud2 !== 'CB' &&
    typeCloud2 !== 'TCU'
  ) {
    if (type === '' && (coverage === 'FEW' || coverage === 'SCT')) {
      return invalidCloud3CoverageTypeCombinationMessage;
    }
  } else if (type === '' && coverage === 'FEW') {
    // In case we do have a CB/TCU group in one of the previous clouf groups. This cloudgroup should be evaluated as if it was the second group
    return invalidCloud2CoverageTypeCombinationMessage;
  }

  // If Cloud1 is not valid, skip further validation of Cloud3
  // No need to pass the weatherfields as their content does not influence what happens below
  // Since in case of VV the third field should be empty anyways
  if (
    validateFirstCloud(trimmedValueFirstCloud, visibilityValue, '', '', '') !==
    true
  ) {
    return true;
  }

  // If Cloud1 contains Vertical Visibility then Cloud3 should be empty
  if (isVerticalVisibility(trimmedValueFirstCloud)) {
    return invalidCloudWithVerticalVisibilityMessage;
  }

  // If Cloud2 is not valid, skip further validation of Cloud3
  if (
    validateSecondCloud(
      trimmedValueFirstCloud,
      trimmedValueSecondCloud,
      visibilityValue,
    ) !== true
  ) {
    return true;
  }

  // If one of the previous groups contains a type, Cloud3 cannot contain a type
  if (type !== '' && (typeCloud1 !== '' || typeCloud2 !== '')) {
    return invalidCloudWithPreviousType;
  }

  // Cloud3 height has to be greater than Cloud2 height
  const cloud2Height = trimmedValueSecondCloud.substring(3, 6);
  if (height <= cloud2Height) {
    return invalidCloudHeightComparedToPreviousMessage;
  }

  return true;
};

export const validateFourthCloud = (
  firstCloudValue: string,
  secondCloudValue: string,
  thirdCloudValue: string,
  fourthCloudValue: string,
  visibilityFieldValue: string,
): boolean | string => {
  const trimmedValueFirstCloud = getFieldValue(firstCloudValue);
  const trimmedValueSecondCloud = getFieldValue(secondCloudValue);
  const trimmedValueThirdCloud = getFieldValue(thirdCloudValue);
  const trimmedValueFourthCloud = getFieldValue(fourthCloudValue);
  const visibilityValue = getFieldValue(visibilityFieldValue);

  // Field can be left empty
  if (!trimmedValueFourthCloud.length) {
    return true;
  }

  // If Cloud1 is NSC, Cloud4 has to be empty
  if (trimmedValueFirstCloud === 'NSC' && trimmedValueFourthCloud !== '') {
    return invalidCloudWithNSCMessage;
  }

  // If Cloud 1, 2 or 3 is empty Cloud4 should be empty
  if (
    trimmedValueFirstCloud === '' ||
    trimmedValueSecondCloud === '' ||
    trimmedValueThirdCloud === ''
  ) {
    return invalidCloudWithPreviousEmptyCloud;
  }

  // Generic validations
  const { height, coverage, type, errorMessages } = sharedCloudValidations({
    value: trimmedValueFourthCloud,
  });
  // Return generic errors if any
  if (errorMessages.length) {
    return errorMessages[0];
  }

  // Cloud4 specific validations
  // If none of the previous cloud groups contains a type, the fourth group must contain a type if not empty
  const typeCloud1 = trimmedValueFirstCloud.substring(6, 9);
  const typeCloud2 = trimmedValueSecondCloud.substring(6, 9);
  const typeCloud3 = trimmedValueThirdCloud.substring(6, 9);
  if (
    typeCloud1 !== 'CB' &&
    typeCloud1 !== 'TCU' &&
    typeCloud2 !== 'CB' &&
    typeCloud2 !== 'TCU' &&
    typeCloud3 !== 'CB' &&
    typeCloud3 !== 'TCU'
  ) {
    if (type === '') {
      return invalidCloud4TypeMissingMessage;
    }
  } else if (coverage === 'FEW' || coverage === 'SCT') {
    // We have a CB/TCU group in one of the previous cloud groups. This group should be evaluated as if it was the third group
    return invalidCloud3CoverageTypeCombinationMessage;
  }

  // If Cloud1 is not valid, skip further validation of Cloud4
  // No need to pass the weatherfields as their content does not influence what happens below
  // Since in case of VV the fourth field should be empty anyways
  if (
    validateFirstCloud(trimmedValueFirstCloud, visibilityValue, '', '', '') !==
    true
  ) {
    return true;
  }
  // If Cloud1 contains Vertical Visibility then Cloud4 should be empty
  if (isVerticalVisibility(trimmedValueFirstCloud)) {
    return invalidCloudWithVerticalVisibilityMessage;
  }

  // If Cloud3 is not valid, skip further validation of Cloud4
  if (
    validateThirdCloud(
      trimmedValueFirstCloud,
      trimmedValueSecondCloud,
      trimmedValueThirdCloud,
      visibilityValue,
    ) !== true
  ) {
    return true;
  }
  // If one of the previous groups contains a type, Cloud4 cannot contain a type
  if (
    type !== '' &&
    (typeCloud1 !== '' || typeCloud2 !== '' || typeCloud3 !== '')
  ) {
    return invalidCloudWithPreviousType;
  }

  // Cloud4 height has to be greater than Cloud3 height
  const cloud3Height = trimmedValueThirdCloud.substring(3, 6);
  if (height <= cloud3Height) {
    return invalidCloudHeightComparedToPreviousMessage;
  }

  return true;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import {
  UseTranslationResponse,
  initReactI18next,
  useTranslation,
} from 'react-i18next';

import timesliderTranslations from '../../../locales/timeslider.json';

export const TIMESLIDER_NAMESPACE = 'timeslider';
const defaultLanguage = 'en';

export const initTimesliderI18n = (): void => {
  void i18n.use(initReactI18next).init({
    lng: defaultLanguage,
    ns: TIMESLIDER_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.en,
      },
      nl: {
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.nl,
      },
      fi: {
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.fi,
      },
      no: {
        [TIMESLIDER_NAMESPACE]: timesliderTranslations.no,
      },
    },
  });
};

export const translateKeyOutsideComponents = (
  key: string,
  params: Record<string, string | number> | undefined = undefined,
): string => i18n.t(key, { ns: [TIMESLIDER_NAMESPACE], ...params });

export const useTimesliderTranslation = (): UseTranslationResponse<
  typeof TIMESLIDER_NAMESPACE,
  typeof i18n
> => useTranslation(TIMESLIDER_NAMESPACE);

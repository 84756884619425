/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { TextField, InputAdornment, debounce } from '@mui/material';
import { Clear, Search } from '@opengeoweb/theme';
import * as React from 'react';
import { useSharedTranslation } from '../../utils/i18n';
import { CustomIconButton } from '../CustomIconButton';

export const DEBOUNCE_TIMEOUT = 300;

interface SearchFieldProps {
  valueChanged: (value: string) => void;
  value?: string;
}

const SearchButton: React.FC<{ label: string }> = ({ label }) => (
  <CustomIconButton tooltipTitle={label} edge="end" size="large">
    <Search />
  </CustomIconButton>
);

const ClearButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <CustomIconButton
    tooltipTitle="Clear"
    edge="end"
    onClick={onClick}
    size="large"
  >
    <Clear />
  </CustomIconButton>
);

const SearchField: React.FC<SearchFieldProps> = ({
  valueChanged,
  value = '',
}: SearchFieldProps) => {
  const [localValue, setLocalValue] = React.useState(value);
  const { t } = useSharedTranslation();
  const label = t('shared-filter-search');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleValueChangeDebounced = React.useCallback(
    debounce(valueChanged, DEBOUNCE_TIMEOUT),
    [],
  );

  return (
    <TextField
      variant="filled"
      size="small"
      sx={{
        '& input': {
          paddingTop: '18px',
        },
      }}
      label={label}
      value={localValue}
      autoFocus
      fullWidth
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        handleValueChangeDebounced(e.target.value);
        setLocalValue(e.target.value);
      }}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              {localValue ? (
                <ClearButton
                  onClick={(): void => {
                    setLocalValue('');
                    valueChanged('');
                  }}
                />
              ) : (
                <SearchButton label={label} />
              )}
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default SearchField;

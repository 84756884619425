/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  InputLabel,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import ReactHookFormFormControl from './ReactHookFormFormControl';
import { getErrors } from './formUtils';
import { ReactHookFormInput } from './types';
import { defaultProps } from './utils';

type ReactHookFormSelectProps = Partial<SelectProps> &
  ReactHookFormInput<object>;

const ReactHookFormSelect: React.FC<ReactHookFormSelectProps> = ({
  name,
  label,
  defaultValue = '',
  children,
  rules,
  onChange = (): void => {},
  disabled,
  className,
  sx,
  isReadOnly,
  size = 'medium',
  ...otherProps
}: ReactHookFormSelectProps) => {
  const labelId = `${name}-label`;

  const { control } = useFormContext();
  const {
    field: { onChange: onChangeField, value, ref },
    formState: { errors: formErrors },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    ...defaultProps,
  });

  const errors = getErrors(name!, formErrors);

  return (
    <ReactHookFormFormControl
      className={className}
      sx={sx}
      disabled={disabled}
      errors={errors}
      isReadOnly={isReadOnly}
      size={size}
    >
      <InputLabel variant="filled" id={labelId}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        label={label}
        inputRef={ref}
        name={name}
        onChange={(
          changeEvent: SelectChangeEvent<unknown>,
          child: React.ReactNode,
        ): void => {
          onChangeField(changeEvent.target.value);
          // default props
          onChange(changeEvent, child);
        }}
        value={value || ''}
        variant="filled"
        {...otherProps}
      >
        {children}
      </Select>
    </ReactHookFormFormControl>
  );
};
export default ReactHookFormSelect;

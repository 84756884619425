/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';

import {
  SynchronizationGroupState,
  SyncGroupAddTargetPayload,
  SyncGroupAddGroupPayload,
  SyncGroupRemoveTargetPayload,
  SyncGroupRemoveGroupPayload,
  SyncGroupSetViewStatePayload,
  SyncGroupLinkTargetPayload,
  SyncGroupRemoveSourcePayload,
  SyncGroupsAddSourcePayload,
  SyncGroupToggleIsTimeScrollingEnabled,
  UpdateLinkedMap,
  AddSharedData,
  DeleteSharedData,
  SetLinkedMap,
} from './types';
import { SYNCGROUPS_TYPE_SETBBOX, SYNCGROUPS_TYPE_SETTIME } from './constants';

import type {
  SetBboxSyncActionPayload,
  SetTimeSyncActionPayload,
} from '../synchronizationActions/types';

import { removeInPlace } from '../utils';
import { setBboxSync, setTimeSync } from '../synchronizationActions/actions';

export const initialState: SynchronizationGroupState = {
  sources: {
    byId: {},
    allIds: [],
  },
  groups: {
    byId: {},
    allIds: [],
  },
  viewState: {
    timeslider: {
      groups: [],
      sourcesById: [],
    },
    zoompane: {
      groups: [],
      sourcesById: [],
    },
    level: {
      groups: [],
      sourcesById: [],
    },
  },
  linkedState: {
    links: {},
    sharedData: {},
  },
  isTimeScrollingEnabled: false,
};

export const slice = createSlice({
  initialState,
  name: 'synchronizationGroupsReducer',
  reducers: {
    /** Sets the links map.
     *  This is used to override the links when changing workspaces.
     * @param draft sync state
     * @param {Record<string, string[]>} action.newLinks the new links object
     */
    setLinkedMap: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SetLinkedMap>,
    ) => {
      const { newLinks } = action.payload;

      draft.linkedState.links = newLinks;
    },
    /** Updates the links map.
     *  This is used to update the links when operating the link menu.
     * @param draft sync state
     * @param {string} action.panelId id of the panel updating it's links
     * @param {string[]} action.mapIds the new linked maps
     */
    updateLinkedMap: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<UpdateLinkedMap>,
    ) => {
      const { panelId, mapIds } = action.payload;
      draft.linkedState.links[panelId] = mapIds;
    },
    addSharedData: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<AddSharedData>,
    ) => {
      const { panelId, data } = action.payload;
      draft.linkedState.sharedData[panelId] = {
        ...draft.linkedState.sharedData[panelId],
        ...data,
      };
    },
    deleteSharedData: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<DeleteSharedData>,
    ) => {
      const { panelId } = action.payload;

      delete draft.linkedState.sharedData[panelId];
    },
    syncGroupAddSource: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupsAddSourcePayload>,
    ) => {
      const { id, type: payloadType, defaultPayload } = action.payload;

      if (!draft.sources.byId[id]) {
        draft.sources.byId[id] = {
          types: payloadType,
          payloadByType: {
            SYNCGROUPS_TYPE_SETTIME: defaultPayload!,
            SYNCGROUPS_TYPE_SETBBOX: defaultPayload!,
            SYNCGROUPS_TYPE_SETLAYERACTIONS: defaultPayload!,
          },
        };

        draft.sources.allIds.push(id);
      }

      /* Now try to initialize with the correct values from the state */
      draft.groups.allIds.forEach((groupId) => {
        const syncronizationGroup = draft.groups.byId[groupId];
        /* Check if the source is in the target list of the synchonizationGroup */
        const source = syncronizationGroup.targets.byId[id];

        const { type } = syncronizationGroup;
        /* If the source is part of the target list, and is linked, continue syncing the other targets */
        if (source && source.linked) {
          draft.sources.byId[id].payloadByType[type] =
            (syncronizationGroup.payloadByType &&
              syncronizationGroup.payloadByType[type]) ||
            defaultPayload;
        }
      });
    },

    syncGroupRemoveSource: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupRemoveSourcePayload>,
    ) => {
      const { id } = action.payload;
      if (draft.sources.byId[id]) {
        delete draft.sources.byId[id];
        removeInPlace(draft.sources.allIds, (_id) => _id === id);
      }
    },
    syncGroupAddTarget: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupAddTargetPayload>,
    ) => {
      const { groupId, targetId, linked } = action.payload;
      const synchronizationGroup = draft.groups.byId[groupId];

      if (!synchronizationGroup) {
        console.warn(`SYNCGROUPS_ADD_TARGET: Group ${groupId} does not exist.`);
        return;
      }
      if (!targetId) {
        console.warn(`SYNCGROUPS_ADD_TARGET: targetId is not defined.`);
        return;
      }
      if (synchronizationGroup.targets.allIds.includes(targetId)) {
        return;
      }

      const isSourceLinked =
        synchronizationGroup.targets.byId[targetId]?.linked;

      synchronizationGroup.targets.allIds.push(targetId);
      synchronizationGroup.targets.byId[targetId] = {
        linked: linked !== false,
      };

      if (isSourceLinked) {
        const { type } = synchronizationGroup;
        draft.sources.byId[targetId].payloadByType[type] =
          synchronizationGroup.payloadByType[type];
      }
    },
    syncGroupRemoveTarget: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupRemoveTargetPayload>,
    ) => {
      const { groupId, targetId } = action.payload;
      if (!draft.groups.byId[groupId]) {
        return;
      }
      if (!draft.groups.byId[groupId].targets.allIds.includes(targetId)) {
        return;
      }
      delete draft.groups.byId[groupId].targets.byId[targetId];
      removeInPlace(
        draft.groups.byId[groupId].targets.allIds,
        (_id) => _id === targetId,
      );
    },
    syncGroupLinkTarget: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupLinkTargetPayload>,
    ) => {
      const { groupId, targetId, linked } = action.payload;
      if (
        !draft.groups.byId[groupId] ||
        !draft.groups.byId[groupId].targets.byId[targetId]
      ) {
        return;
      }
      draft.groups.byId[groupId].targets.byId[targetId].linked = linked;
    },
    syncGroupAddGroup: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupAddGroupPayload>,
    ) => {
      const { groupId, type, title } = action.payload;
      if (draft.groups.byId[groupId]) {
        return;
      }
      draft.groups.byId[groupId] = {
        title,
        type,
        payloadByType: {
          SYNCGROUPS_TYPE_SETTIME: null!,
          SYNCGROUPS_TYPE_SETBBOX: null!,
        },
        targets: {
          allIds: [],
          byId: {},
        },
      };
      draft.groups.allIds.push(groupId);
    },
    syncGroupRemoveGroup: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupRemoveGroupPayload>,
    ) => {
      const { groupId } = action.payload;
      if (!draft.groups.byId[groupId]) {
        return;
      }
      delete draft.groups.byId[groupId];
      removeInPlace(draft.groups.allIds, (_groupId) => _groupId === groupId);
    },
    syncGroupClear: () => {
      return initialState;
    },
    syncGroupSetViewState: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupSetViewStatePayload>,
    ) => {
      const { viewState } = action.payload;
      draft.viewState = viewState;
    },

    syncGroupToggleIsTimeScrollingEnabled: (
      draft: Draft<SynchronizationGroupState>,
      action: PayloadAction<SyncGroupToggleIsTimeScrollingEnabled>,
    ) => {
      draft.isTimeScrollingEnabled = action.payload.isTimeScrollingEnabled;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setTimeSync, (draft, action) => {
        return setBboxOrTimeSync(draft, action);
      })
      .addCase(setBboxSync, (draft, action) => {
        return setBboxOrTimeSync(draft, action);
      });
  },
});

const setBboxOrTimeSync = (
  draft: Draft<SynchronizationGroupState>,
  action: PayloadAction<SetBboxSyncActionPayload | SetTimeSyncActionPayload>,
): void => {
  const { targets, source, groups } = action.payload;

  const getPayLoadKey = (type: string): string | null => {
    switch (type) {
      case setTimeSync.type:
        return SYNCGROUPS_TYPE_SETTIME;
      case setBboxSync.type:
        return SYNCGROUPS_TYPE_SETBBOX;
      default:
        return null;
    }
  };

  if (source) {
    const payloadKey = getPayLoadKey(action.type);
    if (!source.payload || !payloadKey) {
      console.warn('Wrong sync action: ', action);
      return;
    }

    if (draft.sources.byId[source.payload.sourceId]) {
      if (!draft.sources.byId[source.payload.sourceId].payloadByType) {
        draft.sources.byId[source.payload.sourceId].payloadByType = {};
      }
      draft.sources.byId[source.payload.sourceId].payloadByType[payloadKey] =
        source.payload;
    }
    /* Update all targets */
    targets.forEach((target) => {
      if (draft.sources.byId[target.targetId]) {
        if (!draft.sources.byId[target.targetId].payloadByType) {
          draft.sources.byId[target.targetId].payloadByType = {};
        }
        draft.sources.byId[target.targetId].payloadByType[payloadKey] =
          source.payload;
      }
    });
    /* Set the value in the group */
    groups.forEach((group: string) => {
      if (draft.groups.byId[group]) {
        draft.groups.byId[group].payloadByType[payloadKey] = source.payload;
      }
    });
  }
};

export const {
  syncGroupAddGroup,
  syncGroupAddSource,
  syncGroupAddTarget,
  syncGroupClear,
  syncGroupLinkTarget,
  syncGroupRemoveGroup,
  syncGroupRemoveSource,
  syncGroupRemoveTarget,
  syncGroupSetViewState,
} = slice.actions;

export const { reducer: syncGroupsReducer, actions: syncGroupsActions } = slice;

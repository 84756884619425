/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  layerActions,
  mapEnums,
  syncGroupsActions,
  uiActions,
  genericActions,
  layerTypes,
  mapActions,
} from '@opengeoweb/store';
import {
  createEntityAdapter,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { timeSeriesActions, TimeSeriesTypes } from '@opengeoweb/timeseries';
import { publicWarningActions } from '@opengeoweb/warnings';

import {
  ErrorViewPresetPayload,
  FetchedViewPresetPayload,
  FetchedViewPresetsPayload,
  FetchViewPresetsPayload,
  ViewPresetEntity,
  ViewPresetState,
  OnSuccesViewPresetActionPayload,
  RegisterViewPresetPayload,
  SavedViewPresetPayload,
  SaveViewPresetPayload,
  SelectViewPresetPayload,
  SetActiveViewPresetIdPayload,
  SetViewPresetHasChangePayload,
  UnregisterViewPresetPayload,
  ToggleViewPresetListDialogPayload,
  OpenViewPresetDialogPayload,
  ToggleSelectFilterChip,
  SetSelectAllFilterChip,
  ViewPresetsListFilter,
  SearchWorkspaceFilter,
  ViewPresetErrorComponent,
  ViewPresetErrorType,
  SetFilterChipsPayload,
} from './types';
import { workspaceListActions } from '../workspaceList/reducer';

const viewPresetsListFilterOptions: ViewPresetsListFilter[] = [
  {
    label: 'shared-filter-my-presets',
    id: 'user',
    type: 'scope',
    isSelected: true,
    isDisabled: false,
  },
  {
    label: 'shared-filter-system-presets',
    id: 'system',
    type: 'scope',
    isSelected: true,
    isDisabled: false,
  },
];

export const viewPresetsAdapter = createEntityAdapter<ViewPresetEntity>({
  // we use panelId as id (panelId can be mapId or viewId)
  selectId: (mapPreset) => mapPreset.panelId,
});

export const initialState: ViewPresetState =
  viewPresetsAdapter.getInitialState();

const warningsPresetChangeActions = [publicWarningActions.setWarningFilters];

const syncGroupChangeActions = [
  syncGroupsActions.syncGroupRemoveTarget,
  syncGroupsActions.syncGroupAddTarget,
];
const mapPresetChangeActions = [
  layerActions.setBaseLayers,
  layerActions.layerDelete,
  layerActions.addLayer,
  layerActions.duplicateMapLayer,
  layerActions.layerChangeEnabled,
  layerActions.layerChangeName,
  layerActions.layerChangeStyle,
  layerActions.layerChangeOpacity,
  layerActions.layerChangeDimension,
  mapActions.layerMoveLayer,
  mapActions.setAutoLayerId,
  mapActions.toggleAutoUpdate,
  mapActions.mapStartAnimation,
  mapActions.mapStopAnimation,
  mapActions.toggleTimeSliderIsVisible,
  mapActions.setTimeStep,
  mapActions.setTimeSliderSpan,
  mapActions.toggleTimeSpanAuto,
  mapActions.setAnimationDelay,
  mapActions.toggleTimestepAuto,
  mapActions.setAnimationStartTime,
  mapActions.setAnimationEndTime,
  uiActions.setActiveMapIdForDialog,
  uiActions.setToggleOpenDialog,
  genericActions.setBbox,
  mapActions.setBbox,
];
const timeSeriesPresetChangeActions = [
  timeSeriesActions.addParameter,
  timeSeriesActions.addPlot,
  timeSeriesActions.deleteParameter,
  timeSeriesActions.deletePlot,
  timeSeriesActions.moveParameter,
  timeSeriesActions.movePlot,
  timeSeriesActions.toggleParameter,
  timeSeriesActions.togglePlot,
  timeSeriesActions.patchParameter,
  timeSeriesActions.updateTitle,
];

export type SupportedMapActionOrigins =
  | layerTypes.LayerActionOrigin
  | mapEnums.MapActionOrigin;

export const supportedMapActionOrigins: SupportedMapActionOrigins[] = [
  layerTypes.LayerActionOrigin.layerManager,
  layerTypes.LayerActionOrigin.wmsLoader,
  mapEnums.MapActionOrigin.map,
];

export type SupportedTimeSeriesActionOrigins =
  TimeSeriesTypes.TimeSeriesActionOrigin;

export const supportedTimeSeriesActionOrigins: SupportedTimeSeriesActionOrigins[] =
  [
    TimeSeriesTypes.TimeSeriesActionOrigin.timeSeriesManager,
    TimeSeriesTypes.TimeSeriesActionOrigin.timeSeriesSelect,
  ];

const slice = createSlice({
  initialState,
  name: 'viewPresets',
  reducers: {
    // fetch list actions
    fetchViewPresets: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<FetchViewPresetsPayload>,
    ) => {
      const { panelId } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.isFetching = true;
        entity.error = undefined;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },
    fetchedViewPresets: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<FetchedViewPresetsPayload>,
    ) => {
      const { panelId, viewPresets, filterParams } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.isFetching = false;
        // If there are filter params, set filter results
        if (
          Object.keys(filterParams).length !== 0 &&
          viewPresets.length !== 0
        ) {
          const { ids, entities } = viewPresets.reduce(
            (result, viewPreset) => {
              return {
                ids: [...result.ids, viewPreset.id],
                entities: { ...result.entities, [viewPreset.id]: viewPreset },
              };
            },
            {
              ids: [] as string[],
              entities: {},
            },
          );
          entity.filterResults.entities = entities;
          entity.filterResults.ids = ids;
        } else {
          // If no filtering applied or no results returned from BE, clear out filter results
          entity.filterResults = { entities: {}, ids: [] };
        }

        viewPresetsAdapter.setOne(draft, entity);
      }
    },
    fetchedViewPreset: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<FetchedViewPresetPayload>,
    ) => {
      const { panelId, viewPresetId: mapPresetId } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.isFetching = false;
        entity.activeViewPresetId = mapPresetId;
        entity.hasChanges = false;
        entity.error = undefined;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },

    saveViewPreset: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SaveViewPresetPayload>,
    ) => {
      const { panelId } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.isFetching = true;
        entity.error = undefined;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },
    savedViewPreset: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SavedViewPresetPayload>,
    ) => {
      const { panelId, viewPresetId } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.isFetching = false;
        entity.hasChanges = false;
        entity.activeViewPresetId = viewPresetId;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },

    errorViewPreset: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<ErrorViewPresetPayload>,
    ) => {
      const { panelId, error } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.error = error;
        entity.isFetching = false;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },

    // map actions
    setViewPresetHasChanges: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SetViewPresetHasChangePayload>,
    ) => {
      const { panelId, hasChanges } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.hasChanges = hasChanges;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },
    setActiveViewPresetId: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SetActiveViewPresetIdPayload>,
    ) => {
      const { panelId, viewPresetId: mapPresetId } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.activeViewPresetId = mapPresetId;
        entity.hasChanges = false;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },
    selectViewPreset: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SelectViewPresetPayload>,
    ) => {
      const { panelId, viewPresetId: mapPresetId } = action.payload;
      const entity = draft.entities[panelId];

      if (entity) {
        // only update activeMapPresetId after selecting a NEW preset
        // existing mapPresets are getting an activeMapPresetId after a succesfull fetch
        if (mapPresetId === '') {
          entity.activeViewPresetId = '';
          entity.error = undefined;
        }

        entity.isViewPresetListDialogOpen = false;

        viewPresetsAdapter.setOne(draft, entity);
      }
    },

    // action is intercepted in mapPresets/listener
    onSuccessViewPresetAction: (
      // eslint-disable-next-line no-unused-vars
      draft: Draft<ViewPresetState>,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<OnSuccesViewPresetActionPayload>,
    ) => {},

    registerViewPreset: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<RegisterViewPresetPayload>,
    ) => {
      const { panelId: mapId, viewPresetId: mapPresetId } = action.payload;
      viewPresetsAdapter.setOne(draft, {
        panelId: mapId,
        activeViewPresetId: mapPresetId,
        hasChanges: false,
        isFetching: false,
        error: undefined,
        isViewPresetListDialogOpen: false,
        filters: viewPresetsListFilterOptions,
        searchQuery: '',
        filterResults: { ids: [], entities: {} },
      });
    },
    unregisterViewPreset: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<UnregisterViewPresetPayload>,
    ) => {
      const { panelId } = action.payload;
      viewPresetsAdapter.removeOne(draft, panelId);
    },
    // dialog actions
    openViewPresetDialog: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<OpenViewPresetDialogPayload>,
    ) => {
      const { viewPresetDialog } = action.payload;
      draft.viewPresetDialog = viewPresetDialog;
    },
    closeViewPresetDialog: (draft: Draft<ViewPresetState>) => {
      delete draft.viewPresetDialog;
    },
    toggleViewPresetListDialog: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<ToggleViewPresetListDialogPayload>,
    ) => {
      const { isViewPresetListDialogOpen: isViewPresetDialogOpen, panelId } =
        action.payload;
      const entity = draft.entities[panelId];

      if (entity) {
        entity.isViewPresetListDialogOpen = isViewPresetDialogOpen;
        viewPresetsAdapter.setOne(draft, entity);
      }
    },
    toggleSelectFilterChip: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<ToggleSelectFilterChip>,
    ) => {
      const { id, isSelected, panelId } = action.payload;

      const entity = draft.entities[panelId];
      if (entity) {
        const isAllSelected = entity.filters!.every(
          (filter) => filter.isSelected,
        );
        const index = entity.filters!.findIndex((filter) => filter.id === id);

        if (index !== -1) {
          if (isAllSelected) {
            entity.filters!.forEach((filter, index) => {
              entity.filters![index].isSelected =
                filter.id === id ? isSelected : false;
            });
          } else {
            entity.filters![index] = {
              ...entity.filters![index],
              isSelected,
            };

            const isAllUnSelected = entity.filters!.every(
              (filter) => !filter.isSelected,
            );
            if (isAllUnSelected) {
              entity.filters!.forEach((_, index) => {
                entity.filters![index].isSelected = true;
              });
            }
          }
        }
      }
    },
    setSelectAllFilterChip: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SetSelectAllFilterChip>,
    ) => {
      const { panelId } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.filters = entity.filters!.map((filter) => {
          return { ...filter, isSelected: true };
        });
      }
    },
    setFilterChips: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SetFilterChipsPayload>,
    ) => {
      const { panelId, filters } = action.payload;

      const entity = draft.entities[panelId];

      if (!entity) {
        return;
      }
      entity.filters.forEach((filter, index) => {
        const existingFilter = filters.find((f) => f.id === filter.id);
        const updatedFilter = {
          ...filter,
          isSelected: existingFilter
            ? existingFilter.isSelected
            : filter.isSelected,
        };
        entity.filters[index] = updatedFilter;
      });
    },
    searchFilter: (
      draft: Draft<ViewPresetState>,
      action: PayloadAction<SearchWorkspaceFilter>,
    ) => {
      const { searchQuery, panelId } = action.payload;
      const entity = draft.entities[panelId];
      if (entity) {
        entity.searchQuery = searchQuery;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mapActions.setMapPresetError, (draft, action) => {
      const { mapId, error } = action.payload;
      const entity = draft.entities[mapId];
      if (entity) {
        entity.error = {
          message: error,
          component: ViewPresetErrorComponent.PRESET_DETAIL,
          errorType: ViewPresetErrorType.GENERIC,
        };
        entity.isFetching = false;
        viewPresetsAdapter.setOne(draft, entity);
      }
    });

    builder.addCase(
      workspaceListActions.submitFormWorkspaceActionDialogOptions,
      (draft, action) => {
        const { data } = action.payload;
        if (data.views) {
          data.views.forEach((view) => {
            if (draft.entities[view.mosaicNodeId]) {
              draft.entities[view.mosaicNodeId]!.error = undefined;
            }
          });
        }
      },
    );

    warningsPresetChangeActions.map((warningsAction) =>
      builder.addCase(
        warningsAction,
        (draft: Draft<ViewPresetState>, action) => {
          const { origin, panelId } = action.payload;
          if (origin === 'user' && panelId) {
            const entity = draft.entities[panelId];
            if (entity && !entity?.hasChanges) {
              entity.hasChanges = true;
              viewPresetsAdapter.setOne(draft, entity);
            }
          }
        },
      ),
    );

    syncGroupChangeActions.map((syncGroupAction) =>
      builder.addCase(
        syncGroupAction,
        (draft: Draft<ViewPresetState>, action) => {
          const { origin, targetId } = action.payload;
          if (origin === 'user') {
            const entity = draft.entities[targetId];
            if (entity && !entity?.hasChanges) {
              entity.hasChanges = true;
              viewPresetsAdapter.setOne(draft, entity);
            }
          }
        },
      ),
    );
    mapPresetChangeActions.map((mapPresetAction) =>
      builder.addCase(
        mapPresetAction,
        (draft: Draft<ViewPresetState>, action) => {
          const { mapId, origin } = action.payload;
          const isChangeOriginSupported =
            supportedMapActionOrigins.indexOf(
              origin as SupportedMapActionOrigins,
            ) >= 0;

          if (isChangeOriginSupported && mapId) {
            const entity = draft.entities[mapId];
            if (entity && !entity?.hasChanges) {
              entity.hasChanges = true;
              viewPresetsAdapter.setOne(draft, entity);
            }
          }
        },
      ),
    );
    timeSeriesPresetChangeActions.map((timeSeriesPresetAction) =>
      builder.addCase(
        timeSeriesPresetAction,
        (draft: Draft<ViewPresetState>, action) => {
          const { panelId, origin } = action.payload;
          const isChangeOriginSupported =
            supportedTimeSeriesActionOrigins.indexOf(
              origin as SupportedTimeSeriesActionOrigins,
            ) >= 0;

          const changesRelevantProperties =
            timeSeriesPresetAction.type ===
            timeSeriesActions.patchParameter.type
              ? Object.keys(
                  (
                    action.payload as TimeSeriesTypes.ParameterManipulationPayload
                  ).parameter,
                ).filter((key) => key !== 'id' && key !== 'instanceId').length >
                0
              : true;

          if (changesRelevantProperties && isChangeOriginSupported && panelId) {
            const entity = draft.entities[panelId];
            if (entity && !entity?.hasChanges) {
              entity.hasChanges = true;
              viewPresetsAdapter.setOne(draft, entity);
            }
          }
        },
      ),
    );
  },
});

export const { reducer: viewPresetsReducer, actions: viewPresetActions } =
  slice;

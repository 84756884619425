/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  ThemeProviderProps,
  ThemeWrapper,
  lightTheme,
} from '@opengeoweb/theme';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Store } from '@reduxjs/toolkit';
import { ConfirmationServiceProvider } from '@opengeoweb/shared';
import { ApiProvider } from '@opengeoweb/api';
import {
  AuthenticationDefaultStateProps,
  AuthenticationProvider,
} from '@opengeoweb/authentication';
import { SnackbarWrapperConnect } from '@opengeoweb/snackbar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createApi as createFakeApi } from '../../utils/fakeApi';
import { API_NAME } from '../../utils/api';
import { i18n, initWorkspaceTestI18n } from '../../utils/i18n';
import { createMockStore } from '../../store/store';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

const WorkspaceThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme = lightTheme,
}: ThemeProviderProps) => <ThemeWrapper theme={theme}>{children}</ThemeWrapper>;

export const DemoWrapper: React.FC<ThemeProviderProps> = ({ ...props }) => (
  <WorkspaceI18nProvider>
    <WorkspaceThemeProvider {...props} />
  </WorkspaceI18nProvider>
);

interface WorkspaceWrapperProviderWithStoreProps extends ThemeProviderProps {
  store?: Store;
  createApi?: () => void;
  auth?: AuthenticationDefaultStateProps;
}

export const defaultAuthConfig = {
  isLoggedIn: true,
  auth: {
    username: 'user.name',
    token: '1223344',
    refresh_token: '33455214',
  },
  onLogin: (): void => null!,
  onSetAuth: (): void => null!,
  sessionStorageProvider: null!,
};

export const WorkspaceWrapperProviderWithStore: React.FC<
  WorkspaceWrapperProviderWithStoreProps
> = ({
  children,
  store = createMockStore(),
  createApi = createFakeApi,
  theme,
  auth = defaultAuthConfig,
}: WorkspaceWrapperProviderWithStoreProps) => (
  <WorkspaceI18nProvider>
    <AuthenticationProvider value={auth}>
      <QueryClientProvider client={queryClient}>
        <ApiProvider createApi={createApi} name={API_NAME}>
          <Provider store={store}>
            <WorkspaceThemeProvider theme={theme}>
              <SnackbarWrapperConnect>
                <ConfirmationServiceProvider>
                  {children as React.ReactElement}
                </ConfirmationServiceProvider>
              </SnackbarWrapperConnect>
            </WorkspaceThemeProvider>
          </Provider>
        </ApiProvider>
      </QueryClientProvider>
    </AuthenticationProvider>
  </WorkspaceI18nProvider>
);
interface WorkspaceTranslationsWrapperProps {
  children?: React.ReactNode;
}

export const WorkspaceI18nProvider: React.FC<
  WorkspaceTranslationsWrapperProps
> = ({ children }) => {
  initWorkspaceTestI18n();
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

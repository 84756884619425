/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, LinearProgress } from '@mui/material';
import { routerActions } from '@opengeoweb/store';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertBanner } from '@opengeoweb/shared';
import {
  GEOWEB_ROLE_PRESETS_ADMIN,
  useAuthenticationContext,
} from '@opengeoweb/authentication';
import { workspaceActions } from '../../store/workspace/reducer';
import {
  WorkspaceLookupFunctionType,
  WorkspaceErrorType,
} from '../../store/workspace/types';
import * as workspaceSelectors from '../../store/workspace/selectors';
import { WorkspaceViewConnect } from '../WorkspaceView';
import { AppStore } from '../../store/types';
import { routes } from '../../utils/routes';
import ViewPresetsFormDialogConnect from '../ViewPresetsFormDialog/ViewPresetsFormDialogConnect';
import { useWorkspaceTranslation } from '../../utils/i18n';

export interface WorkspaceTopBarProps {
  initialWorkspaceId?: string;
  workspaceId?: string;
  componentsLookUp: WorkspaceLookupFunctionType;
  children?: React.ReactNode;
  // disable map for snapshots
  isSnapshot?: boolean;
}

export const WorkspaceDetail: React.FC<WorkspaceTopBarProps> = ({
  initialWorkspaceId,
  workspaceId,
  children,
  componentsLookUp,
  isSnapshot = false,
}: WorkspaceTopBarProps) => {
  const dispatch = useDispatch();
  const { t } = useWorkspaceTranslation();
  const { currentRole } = useAuthenticationContext();
  const isAdmin = currentRole?.name === GEOWEB_ROLE_PRESETS_ADMIN.name;

  const error = useSelector((store: AppStore) =>
    workspaceSelectors.getWorkspaceError(store),
  );

  const isLoading = useSelector(workspaceSelectors.isWorkspaceLoading);

  const workspaceData = useSelector(workspaceSelectors.getWorkspaceData);

  const fetchWorkspace = React.useCallback(
    (newWorkspaceId: string): void => {
      dispatch(
        workspaceActions.fetchWorkspace({ workspaceId: newWorkspaceId }),
      );
    },
    [dispatch],
  );

  const onSave = React.useCallback(() => {
    dispatch(
      workspaceActions.saveWorkspacePreset({
        workspace: workspaceData,
      }),
    );
  }, [dispatch, workspaceData]);

  const changeToEmptyMapWorkspace = React.useCallback((): void => {
    dispatch(
      workspaceActions.changeToEmptyMapWorkspace({
        newMapPresetText: t('workspace-mappreset-new'),
        newWorkspaceText: t('workspace-new'),
      }),
    );
  }, [dispatch, t]);

  const navigateToUrl = React.useCallback(
    (url: string): void => {
      dispatch(routerActions.navigateToUrl({ url }));
    },
    [dispatch],
  );

  const setEmptyWorkSpace = (): void => {
    changeToEmptyMapWorkspace();
    navigateToUrl(routes.root);
  };

  React.useEffect(() => {
    const idToFetch = workspaceId || initialWorkspaceId;
    if (!idToFetch) {
      setEmptyWorkSpace();
    } else {
      fetchWorkspace(idToFetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-testid="workspace" sx={{ width: '100%', height: '100%' }}>
      {isLoading && (
        <LinearProgress
          data-testid="loading-bar"
          color="secondary"
          sx={{ position: 'absolute', width: '100%', top: 0, zIndex: 100 }}
        />
      )}

      {error && error.type !== WorkspaceErrorType.SAVE && (
        <AlertBanner
          title={t('workspace-error-fetch-detail', {
            WORKSPACE_ID: workspaceId,
          })}
          info={error.message}
          shouldClose
          {...(error.type === WorkspaceErrorType.GENERIC && {
            actionButtonProps: {
              title: t('workspace-retry'),
              onClick: (): void => fetchWorkspace(workspaceId!),
            },
          })}
        />
      )}

      {children}

      {!isSnapshot && (
        <>
          {error && error.type === WorkspaceErrorType.SAVE && (
            <AlertBanner
              title={t('workspace-save-error', { TITLE: workspaceData.title })}
              info={error.message}
              shouldClose
              actionButtonProps={{
                title: t('workspace-retry'),
                onClick: (): void => onSave(),
              }}
            />
          )}
          {(!error || error.type === WorkspaceErrorType.SAVE) && (
            <Box
              component="div"
              sx={{
                gridArea: 'content',
                position: 'absolute',
                margin: 0,
                padding: 0,
                display: 'block',
                height: '100%',
                width: '100%',
                overflowY: 'auto',
                zIndex: 3,
              }}
              data-testid="WorkspacePage"
            >
              <WorkspaceViewConnect componentsLookUp={componentsLookUp} />
            </Box>
          )}
        </>
      )}
      <ViewPresetsFormDialogConnect scope={isAdmin ? 'system' : 'user'} />
    </Box>
  );
};

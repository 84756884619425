/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';

import { FieldValues, useFormContext } from 'react-hook-form';
import { Backdrop, CircularProgress } from '@mui/material';
import {
  useConfirmationDialog,
  usePreventBrowserClose,
  AlertBanner,
  isAxiosError,
  getAxiosErrorMessage,
  ConfirmationServiceProvider,
} from '@opengeoweb/shared';

import { useApiContext } from '@opengeoweb/api';

import { AxiosError } from 'axios';
import {
  defaultFormOptions,
  ReactHookFormProvider,
  useDraftFormHelpers,
} from '@opengeoweb/form-fields';
import { ContentDialog } from '../ContentDialog';
import {
  AviationProduct,
  isInstanceOfCancelSigmet,
  Sigmet,
  ProductAction,
  FormMode,
  Airmet,
  ProductType,
  isInstanceOfCancelSigmetOrAirmet,
  isInstanceOfCancelAirmet,
  SigmetFromFrontend,
  AirmetFromFrontend,
  ProductConfig,
  CancelSigmet,
  CancelAirmet,
  ProductStatus,
  AviationPhenomenaCode,
} from '../../types';
import { AirmetForm, SigmetForm } from '.';
import {
  createSigmetPostParameter,
  getDialogtitle,
  getProductFormLifecycleButtons,
  getConfirmationDialogButtonLabel,
  getConfirmationDialogContent,
  getConfirmationDialogTitle,
  getConfirmationDialogCancelLabel,
  createAirmetPostParameter,
} from './utils';

import { CancelSigmetVolcanicMovements } from './SigmetForm/SigmetCancelVolcanicMovements';
import { SigmetAirmetApi } from '../../utils/api';
import { useShortTestHelpers } from '../../hooks/useShortTestHelpers';

const isVAPhenomenon = (
  product: AviationProduct,
  action: ProductAction,
): boolean => {
  return (
    action === ProductAction.CANCEL &&
    product !== null &&
    !isInstanceOfCancelSigmetOrAirmet(product) &&
    product.phenomenon === ('VA_CLD' as AviationPhenomenaCode)
  );
};

const prepareCancelSigmet = (sigmet: Sigmet, movement: string): Sigmet => {
  // If movement UNKNOWN, don't send to BE
  if (movement === 'UNKNOWN') {
    return sigmet;
  }
  return {
    ...sigmet,
    vaSigmetMoveToFIR: movement,
  };
};

export interface ProductFormDialogContentProps {
  isOpen: boolean;
  toggleDialogStatus: (shouldRefreshList?: boolean) => void;
  onRenewClick: () => void;
  mode: FormMode;
  productType: ProductType;
  productConfig: ProductConfig;
  productCanBe: string[];
  product: AviationProduct;
}

const ProductFormDialogContent: React.FC<ProductFormDialogContentProps> = ({
  isOpen,
  toggleDialogStatus,
  onRenewClick,
  mode,
  productType,
  productConfig,
  productCanBe,
  product,
}: ProductFormDialogContentProps) => {
  const { api } = useApiContext<SigmetAirmetApi>();
  const apiCall = productType === 'sigmet' ? api.postSigmet : api.postAirmet;
  const { handleSubmit, formState, watch } = useFormContext();
  const { dirtyFields } = formState;
  const isDirty = Object.keys(dirtyFields).length > 0;
  const { toggleIsDraft, DraftFieldHelper } = useDraftFormHelpers();
  const { isShortTest } = useShortTestHelpers();
  usePreventBrowserClose(isDirty);

  const confirmationDialog = useConfirmationDialog();

  const [showLoader, setLoader] = React.useState(false);
  const [errorStoreMessage, setErrorStoreMessage] = React.useState(false);
  const [errorStoreMessageText, setErrorStoreMessageText] = React.useState('');
  const [dialogActionsDisabled, setDialogActionsDisabled] =
    React.useState(false);

  const postProduct = (
    productToPost: FieldValues,
    newStatus: ProductStatus,
  ): void => {
    const uuid = product !== null && product.uuid ? product.uuid : null;
    const postProductParam =
      productType === 'sigmet'
        ? createSigmetPostParameter(
            productToPost as Sigmet | CancelSigmet,
            newStatus,
            uuid!,
          )
        : createAirmetPostParameter(
            productToPost as Airmet | CancelAirmet,
            newStatus,
            uuid!,
          );

    setLoader(true);
    setErrorStoreMessage(false);
    setErrorStoreMessageText('');

    apiCall(postProductParam as SigmetFromFrontend & AirmetFromFrontend)
      .then(() => {
        onToggleDialog();
      })
      .catch((error: AxiosError | Error) => {
        setErrorStoreMessage(true);
        if (isAxiosError(error)) {
          setErrorStoreMessageText(getAxiosErrorMessage(error as AxiosError));
        }
        setLoader(false);
      });
  };

  const onToggleDialog = (shouldRefreshList = true): void => {
    setLoader(false);
    toggleDialogStatus(shouldRefreshList);
  };

  const showConfirmDialog = (action: ProductAction): Promise<void> =>
    confirmationDialog({
      title: getConfirmationDialogTitle(action, productType),
      description: getConfirmationDialogContent(action, productType),
      confirmLabel: getConfirmationDialogButtonLabel(action, productType),
      cancelLabel: getConfirmationDialogCancelLabel(action),
      catchOnCancel: action === ProductAction.CLOSE,
      content:
        isVAPhenomenon(product, action) && !isShortTest() ? (
          <CancelSigmetVolcanicMovements
            sigmet={product as Sigmet}
            productConfig={productConfig}
          />
        ) : null,
    });

  const onProductButtonPress = (action: ProductAction): void => {
    switch (action) {
      case ProductAction.CLOSE:
        if (mode === 'view' || !isDirty) {
          onToggleDialog(false);
        } else {
          toggleIsDraft(true);
          showConfirmDialog(action)
            .then(() => {
              void handleSubmit((formValues) => {
                postProduct(formValues, 'DRAFT');
              })();
            })
            .catch((reason) => {
              if (reason === 'CANCELLED') {
                // close without save
                onToggleDialog(false);
              }
            });
        }
        break;
      case ProductAction.CANCEL:
        void showConfirmDialog(action).then(() => {
          // if volcanic sigmet - add to which FIR it's moving
          if (isVAPhenomenon(product, action)) {
            postProduct(
              prepareCancelSigmet(
                product as Sigmet,
                watch('vaSigmetMoveToFIR'),
              ),
              'CANCELLED',
            );
          } else {
            postProduct(product, 'CANCELLED');
          }
        });
        break;

      case ProductAction.DISCARD:
        void showConfirmDialog(action).then(() => {
          // If we're discarding a new SIGMET/AIRMET - just close the dialog
          if (mode === 'new' || !product?.uuid) {
            onToggleDialog(false);
          } else {
            // If we're discarding an existing SIGMET/AIRMET - make call to BE
            postProduct(product, 'DISCARDED');
          }
        });
        break;
      case ProductAction.RENEW:
        onRenewClick();
        break;
      default:
        toggleIsDraft(action === ProductAction.SAVE);
        // validate form
        void handleSubmit((formValues) => {
          if (action === ProductAction.SAVE) {
            // NO MODAL, SAVE DIRECTLY
            postProduct(formValues, 'DRAFT');
          } else {
            // YES MODAL
            void showConfirmDialog(action).then(() => {
              postProduct(formValues, 'PUBLISHED');
            });
          }
        })();
        break;
    }
  };

  const title = getDialogtitle(product, productType, productConfig);

  return (
    <ContentDialog
      open={isOpen}
      toggleDialogStatus={(): void => onProductButtonPress(ProductAction.CLOSE)}
      data-testid="productform-dialog"
      title={title}
      fullWidth
      maxWidth="xl"
      disableEscapeKeyDown
      onClose={(): void => onProductButtonPress(ProductAction.CLOSE)}
      alertBanner={
        errorStoreMessage ? (
          <AlertBanner
            severity="error"
            title="An error has occurred while saving, please try again"
            info={errorStoreMessageText}
          />
        ) : null
      }
      options={getProductFormLifecycleButtons({
        canBe: productCanBe,
        onButtonPress: onProductButtonPress,
        productType,
        areActionsDisabled: dialogActionsDisabled,
      })}
      style={{ zIndex: 100 }}
      sx={{
        ' .MuiDialogContent-root': {
          overflowY: 'hidden',
        },
      }}
    >
      {productType === 'sigmet' ? (
        <SigmetForm
          mode={mode}
          isCancelSigmet={product !== null && isInstanceOfCancelSigmet(product)}
          initialSigmet={
            product !== null && !isInstanceOfCancelSigmet(product)
              ? (product as Sigmet)
              : null!
          }
          initialCancelSigmet={
            product !== null && isInstanceOfCancelSigmet(product)
              ? product
              : null!
          }
          productConfig={productConfig}
          setDialogActionsDisabled={setDialogActionsDisabled}
          product={product}
        />
      ) : (
        <AirmetForm
          mode={mode}
          isCancelAirmet={product !== null && isInstanceOfCancelAirmet(product)}
          initialAirmet={
            product !== null && !isInstanceOfCancelAirmet(product)
              ? (product as Airmet)
              : null!
          }
          initialCancelAirmet={
            product !== null && isInstanceOfCancelAirmet(product)
              ? product
              : null!
          }
          productConfig={productConfig}
          product={product}
        />
      )}
      <DraftFieldHelper />

      <Backdrop
        data-testid="loader"
        open={showLoader}
        sx={{
          zIndex: 1500,
          color: '#fff',
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ContentDialog>
  );
};

const Wrapper: React.FC<ProductFormDialogContentProps> = ({
  ...props
}: ProductFormDialogContentProps) => {
  return (
    <ReactHookFormProvider
      options={{
        ...defaultFormOptions,
        defaultValues: props.product,
        values: props.product,
      }}
    >
      <ConfirmationServiceProvider>
        <ProductFormDialogContent {...props} />
      </ConfirmationServiceProvider>
    </ReactHookFormProvider>
  );
};

export { Wrapper as ProductFormDialogContent };

export default Wrapper;

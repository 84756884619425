/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Box, Typography } from '@mui/material';
import {
  ReactHookFormHiddenInput,
  ReactHookFormTextField,
} from '@opengeoweb/form-fields';
import { AlertBanner, PresetScope } from '@opengeoweb/shared';
import { Exclamation } from '@opengeoweb/theme';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { WorkspacePresetAction } from '../../store/workspace/types';
import {
  WorkspaceDialogView,
  WorkspaceListError,
  WorkspaceListErrorType,
  WorkspacePresetFormValues,
} from '../../store/workspaceList/types';
import { useWorkspaceTranslation } from '../../utils/i18n';
import WorkspaceViewPresetsFormFields from './WorkspaceViewPresetsFormFields';

export const ERROR_TITLE_BE =
  'Preset title already in use, please choose a different title';

const validateTitle = (value: string): boolean | string => {
  if (!value) {
    return true;
  }
  return !value.trim().length ? 'This field is required' : true;
};

export const emptyWorkspacePreset = {
  scope: 'user' as PresetScope,
  title: '',
  abstract: '',
};

interface WorkspacePresetsFormProps {
  error?: WorkspaceListError;
  onSubmit?: () => void;
  action: WorkspacePresetAction;
  formValues: WorkspacePresetFormValues;
  isLoading?: boolean;
  hasSavedWorkspace?: boolean;
}

const WorkspacePresetsForm: React.FC<WorkspacePresetsFormProps> = ({
  error,
  onSubmit,
  action,
  formValues,
  isLoading = false,
  hasSavedWorkspace = false,
}: WorkspacePresetsFormProps) => {
  const { t } = useWorkspaceTranslation();
  const { setError } = useFormContext();

  const titleField = 'title';
  const { title, abstract, ...otherFormValues } = {
    ...emptyWorkspacePreset,
    ...formValues,
  };

  const hiddenFields = Object.keys(otherFormValues).map((name) => ({
    name,
    value: otherFormValues[name as keyof typeof otherFormValues],
  }));

  React.useEffect(() => {
    // autoFocus
    const field = document.querySelector(
      `[name="${titleField}"]`,
    ) as HTMLInputElement;
    field?.focus();
  }, []);

  React.useEffect(() => {
    // set title error on title field
    if (
      error?.type === WorkspaceListErrorType.VERBATIM_ERROR &&
      error.message === ERROR_TITLE_BE
    ) {
      setError(titleField, { message: error.message });
    }
  }, [error, setError]);

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit!();
  };

  const hasFormInput =
    action === WorkspacePresetAction.DUPLICATE ||
    action === WorkspacePresetAction.SAVE ||
    action === WorkspacePresetAction.SAVE_AS ||
    action === WorkspacePresetAction.EDIT ||
    action === WorkspacePresetAction.SAVE_SYSTEM_PRESET ||
    action === WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS;

  // disable form inputs
  const isDisabled = hasSavedWorkspace || isLoading;
  const shouldShowViewPresetForm =
    action === WorkspacePresetAction.SAVE_SYSTEM_PRESET ||
    action === WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS ||
    action === WorkspacePresetAction.SAVE_AS ||
    action === WorkspacePresetAction.SAVE;

  return (
    <>
      {error &&
        !(
          error.type === WorkspaceListErrorType.VERBATIM_ERROR &&
          error.message === ERROR_TITLE_BE
        ) && (
          <Box sx={{ marginBottom: 2 }}>
            <AlertBanner
              title={
                error.type === WorkspaceListErrorType.TRANSLATABLE_ERROR
                  ? t(error.key, error.params)
                  : error.message
              }
            />
          </Box>
        )}
      {hasFormInput && (
        <form data-testid="workspace-preset-form" onSubmit={onSubmitForm}>
          <Box sx={{ marginBottom: 2 }}>
            <ReactHookFormTextField
              name={titleField}
              label={t('workspace-dialog-name')}
              rules={{
                required: true,
                validate: {
                  validateTitle,
                },
              }}
              disabled={isDisabled}
              defaultValue={title}
              error={
                error?.type === WorkspaceListErrorType.VERBATIM_ERROR &&
                error.message === ERROR_TITLE_BE
              }
            />
          </Box>
          <ReactHookFormTextField
            name="abstract"
            label={t('workspace-dialog-abstract')}
            rules={{
              required: false,
            }}
            multiline
            rows={3}
            disabled={isDisabled}
            defaultValue={abstract}
          />
          {shouldShowViewPresetForm && (
            <WorkspaceViewPresetsFormFields
              viewPresets={otherFormValues.views as WorkspaceDialogView[]}
              isLoading={isLoading}
              isAdmin={
                action === WorkspacePresetAction.SAVE_SYSTEM_PRESET ||
                action === WorkspacePresetAction.SAVE_SYSTEM_PRESET_AS
              }
            />
          )}

          {hiddenFields.map(({ name, value }) => (
            <ReactHookFormHiddenInput
              key={name}
              name={name}
              defaultValue={value}
              data-testid={name}
            />
          ))}
        </form>
      )}
      {(action === WorkspacePresetAction.DELETE ||
        action === WorkspacePresetAction.DELETE_SYSTEM_PRESET) && (
        <>
          <Typography variant="body1">
            <Exclamation
              sx={{
                color: 'geowebColors.functional.warning',
                position: 'relative',
                top: '6px',
              }}
            />
            {action === WorkspacePresetAction.DELETE_SYSTEM_PRESET
              ? t('workspace-dialog-confirm-delete-system')
              : t('workspace-dialog-confirm-delete-user')}
          </Typography>
          <br />

          <Typography variant="body1">
            {t('workspace-dialog-confirm-delete-message', { TITLE: title })}
          </Typography>
          <ReactHookFormHiddenInput
            key="title"
            name="title"
            defaultValue={title}
          />
        </>
      )}
      {action === WorkspacePresetAction.RESET && (
        <Box>
          <Typography variant="body1">
            <Exclamation
              sx={{
                top: '6px',
                position: 'relative',
                color: 'geowebColors.functional.warning',
              }}
            />
            {formValues.scope === 'system'
              ? t('workspace-dialog-confirm-reset-message')
              : t('workspace-dialog-confirm-reset-personal-message')}
          </Typography>
          <ReactHookFormHiddenInput
            key="title"
            name="title"
            defaultValue={title}
          />
        </Box>
      )}
    </>
  );
};

export default WorkspacePresetsForm;

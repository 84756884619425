/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { InputAdornment, Paper, Stack } from '@mui/material';
import React, { FC } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { dateUtils } from '@opengeoweb/shared';
import { CalendarToday } from '@opengeoweb/theme';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { PickerValidDate } from '@mui/x-date-pickers/models/pickers';
import { useTimesliderTranslation } from '../../../utils/i18n';

export interface MarkerTimeSelectorProps {
  value?: number; // Unix timestamp in seconds
  dataStartTime?: number;
  dataEndTime?: number;
  onSelect?: (value: number | null) => void;
  onCancel?: () => void;
  format?: string;
}

const getTimezoneOffsetS = (value: Date): number =>
  value.getTimezoneOffset() * 60;

const toFakeUTC = (dateTime: number): Date =>
  dateUtils.fromUnix(
    dateTime + getTimezoneOffsetS(dateUtils.fromUnix(dateTime)),
  );

const toRealTime = (dateTime: Date): number =>
  dateUtils.unix(dateTime) - getTimezoneOffsetS(dateTime);

const OpenPicker = (): React.ReactElement => <CalendarToday />;

interface DayStyle {
  borderWidth?: string;
  borderBottomStyle?: string;
  borderRadius?: number;
  borderColor?: string;
  backgroundColor?: string;
}

const enabledAndSelectedButtonStyle = {
  borderWidth: '6px',
  borderBottomStyle: 'solid',
  borderRadius: 0,
  borderColor: 'rgb(170, 210, 240)',
  backgroundColor: 'rgba(170, 210, 240, 0.3)',
};

export const enabledButUnSelectedButtonStyle = {
  ...enabledAndSelectedButtonStyle,
  backgroundColor: 'transparent',
};

export const disabledButtonStyle = {
  ...enabledAndSelectedButtonStyle,
  borderColor: 'transparent',
  backgroundColor: 'transparent',
};

const AvailableDayByTimeRange = (
  props: PickersDayProps<Date>,
  isDisabledStyle: boolean,
): React.ReactElement => {
  const { day, today, outsideCurrentMonth, ...other } = props;
  const [calendarDayStyle, setCalendarDayStyle] = React.useState<DayStyle>(
    {} as DayStyle,
  );

  React.useEffect(() => {
    if (outsideCurrentMonth || isDisabledStyle) {
      setCalendarDayStyle(disabledButtonStyle);
    } else if (today) {
      setCalendarDayStyle(enabledAndSelectedButtonStyle);
    } else {
      setCalendarDayStyle(enabledButUnSelectedButtonStyle);
    }
  }, [day, today, outsideCurrentMonth, isDisabledStyle]);

  return (
    <PickersDay
      {...other}
      key={day.toString()}
      sx={{ ...calendarDayStyle }}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      disableMargin={true}
      disableHighlightToday={true}
    />
  );
};

export const MarkerTimeSelector: FC<MarkerTimeSelectorProps> = ({
  value,
  format = dateUtils.DATE_FORMAT_DATEPICKER,
  dataStartTime,
  dataEndTime,
  onSelect = (): void => {},
  onCancel = (): void => {},
}) => {
  const { t } = useTimesliderTranslation();

  const [fakeUtcValue, setFakeUtcValue] = React.useState<Date | null>(
    value ? toFakeUTC(value) : null,
  );

  const [isUndefinedDateRange, setIsUndefinedDateRange] =
    React.useState<boolean>(
      !dataStartTime || !dataEndTime || dataStartTime > dataEndTime,
    );

  const isDateRangeDisabled = React.useCallback(
    (date: Date): boolean => {
      if (
        dataStartTime === undefined ||
        dataEndTime === undefined ||
        dataStartTime > dataEndTime
      ) {
        return true;
      }

      const startOfFirstDay = dateUtils.fromUnix(dataStartTime);
      startOfFirstDay.setHours(0, 0, 0);
      const endOfLastDay = dateUtils.fromUnix(dataEndTime);

      const day = dateUtils.unix(date);
      return (
        day < dateUtils.unix(startOfFirstDay) ||
        day > dateUtils.unix(endOfLastDay)
      );
    },
    [dataStartTime, dataEndTime],
  );

  const [isDisabledRange, setIsDisabledRange] = React.useState<boolean>(
    isDateRangeDisabled(fakeUtcValue!),
  );

  const handleSelect = (): void => {
    if (fakeUtcValue) {
      onSelect(toRealTime(fakeUtcValue));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      handleSelect();
    } else if (event.key === 'Escape') {
      onCancel();
    }
  };

  const isDisabledDateWithSomeAvailableData = (): boolean => {
    return isUndefinedDateRange ? false : isDisabledRange;
  };

  const handleDateChange = (value: PickerValidDate | null): void => {
    if (
      value instanceof Date ||
      value === null ||
      isDisabledDateWithSomeAvailableData()
    ) {
      setFakeUtcValue(value);
    }
  };

  const AvailableDay = (props: PickersDayProps<Date>): React.ReactElement => {
    return AvailableDayByTimeRange(props, isDateRangeDisabled(props!.day));
  };

  React.useEffect(() => {
    setIsUndefinedDateRange(
      !dataStartTime || !dataEndTime || dataStartTime > dataEndTime,
    );
    setIsDisabledRange(isDateRangeDisabled(fakeUtcValue!));
  }, [dataStartTime, dataEndTime, fakeUtcValue, isDateRangeDisabled]);

  return (
    <Paper sx={{ borderRadius: '0px', width: '236px' }}>
      <Stack data-testid="markerTimeSelector">
        <Paper
          sx={{
            height: '24px',
            align: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px',
          }}
        >
          <Paper
            sx={{
              variant: 'subtitle',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              font: {
                fontWeight: 'medium',
                fontFamily: 'Roboto',
                fontSize: '12px',
              },

              borderRadius: '0px',
              boxShadow: 'none',
            }}
          >
            {t('timeslider-marker')}
          </Paper>
        </Paper>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            desktopModeMediaQuery="@media (min-width: 720px)"
            label={t('timeslider-marker-label')}
            ampm={false}
            value={fakeUtcValue}
            format={format}
            onChange={handleDateChange}
            onClose={handleSelect}
            autoFocus={true}
            disableIgnoringDatePartForTimeValidation={true}
            shouldDisableDate={isDisabledDateWithSomeAvailableData}
            minDateTime={
              isUndefinedDateRange ? undefined : toFakeUTC(dataStartTime!)
            }
            maxDateTime={
              isUndefinedDateRange ? undefined : toFakeUTC(dataEndTime!)
            }
            sx={{
              marginTop: '8px',
              marginBottom: '8px',
              marginLeft: '8px',
              marginRight: '8px',
              borderRadius: '0px',
            }}
            slotProps={{
              textField: {
                variant: 'filled',
                InputProps: {
                  onKeyDownCapture: handleKeyDown,
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        paddingTop: '16px',
                      }}
                      position="end"
                    >
                      UTC
                    </InputAdornment>
                  ),
                },
              },
              inputAdornment: {
                position: 'start',
              },
              openPickerButton: {
                size: 'small',
                sx: { padding: 0 },
              },
            }}
            slots={{
              openPickerIcon: OpenPicker,
              day: AvailableDay,
            }}
            timeSteps={{ minutes: 1 }}
          />
        </LocalizationProvider>
      </Stack>
    </Paper>
  );
};

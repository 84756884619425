/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import {
  DimensionsElevation,
  DimensionsOther,
  DimensionsRefTime,
  DimensionsTime,
} from '@opengeoweb/theme';
import { Dimension, LayerProps } from '@opengeoweb/webmap';
import { TFunction } from 'i18next';
import { ListItem } from './LayerInfoList';

export const getLayerStyles = (layer: LayerProps): string => {
  if (!layer.styles) {
    return '-';
  }
  const styles = layer.styles.map((style) => style.title);
  if (!styles.length) {
    return '-';
  }
  return styles.join(', ');
};

export const getDimensionValue = (
  name: string,
  dimensions: Dimension[],
): string => {
  const dimension = dimensions?.find(
    (dimension) => dimension.name!.toLowerCase() === name.toLowerCase(),
  );
  if (!dimension || !dimension.values) {
    return null!;
  }
  const dimName = dimension.name!.toLowerCase();
  const breakAt = dimName === 'time' ? '/' : ',';
  const includeUnit = dimName !== 'time' && dimName !== 'reference_time';
  const dimValue = dimension.values.split(breakAt).join(`${breakAt} `);

  if (includeUnit) {
    return `${dimValue} ${dimension.units}`;
  }
  return dimValue;
};

export const getLayerBbox = (t: TFunction, layer: LayerProps): string => {
  return layer.geographicBoundingBox
    ? `${t('webmap-react-west')} ${layer.geographicBoundingBox.west}, ${t('webmap-react-east')} ${layer.geographicBoundingBox.east}, ${t('webmap-react-north')} ${layer.geographicBoundingBox.north}, ${t('webmap-react-south')} ${layer.geographicBoundingBox.south}`
    : '-';
};

export const getDimensionIcon = (
  name: string,
  dimensions: Dimension[],
): React.ReactElement => {
  const dimension = dimensions?.find(
    (dimension) => dimension.name!.toLowerCase() === name.toLowerCase(),
  );
  if (!dimension) {
    return null!;
  }
  switch (dimension.name!.toLowerCase()) {
    case 'time':
      return <DimensionsTime />;
    case 'reference_time':
      return <DimensionsRefTime />;
    case 'elevation':
      return <DimensionsElevation />;
    default:
      return <DimensionsOther />;
  }
};

export const getDimensionLabel = (name: string): string => {
  const dimName = name.toLowerCase().split('_').join(' ');
  return dimName.charAt(0).toUpperCase() + dimName.slice(1);
};

export const getDimensionsList = (dimensions: Dimension[]): ListItem[] => {
  const result: ListItem[] = [];

  dimensions?.forEach(({ name }) => {
    const value = getDimensionValue(name!, dimensions);
    if (!value) {
      return;
    }
    const icon = getDimensionIcon(name!, dimensions);
    const label = getDimensionLabel(name!);
    result.push({ label, value, icon });
  });

  // sort alphabetically so other dimensions are sorted
  result.sort((a, b) => a.label.localeCompare(b.label));

  // sort by fixed order
  const preferredOrder = ['Time', 'Reference time', 'Elevation'];
  // using reverse ordering to make sure the other dimensions come last
  preferredOrder.reverse();
  result.sort(
    (a, b) => preferredOrder.indexOf(b.label) - preferredOrder.indexOf(a.label),
  );

  return result;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { CustomTooltip, dateUtils, CanvasComponent } from '@opengeoweb/shared';
import { debounce, throttle } from 'lodash';
import {
  roundWithTimeStep as roundWithTimeStepWebmap,
  useCanvasTarget,
  timestampToPixel,
  secondsPerPxFromCanvasWidth,
  defaultSecondsPerPx,
  pixelToTimestamp,
} from '../TimeSlider/timeSliderUtils';
import { drawTimeSliderLegend } from './drawFunctions';
import {
  getFilteredTime,
  moveSelectedTimePx,
} from '../TimeSlider/changeTimeFunctions';
import {
  DRAG_AREA_WIDTH,
  ShowTooltip,
  TIME_SLIDER_LEGEND_HEIGHT,
  convertStringTimeToUnix,
  getPixelsBetweenViewportAndTimesliderOnLeft,
  getPositions,
  getTimeSliderLegendElement,
  getTimeSliderLegendId,
  isDraggingEndWithinBounds,
  isDraggingStartWithinBounds,
  isInsideAnimationArea,
  isLeftAnimationIconArea,
  isRightAnimationIconArea,
  isSelectedTimeIconArea,
  timeValues,
} from './timeSliderLegendUtils';

export interface TimeSliderLegendProps {
  mapId: string;
  isTimeScrollingEnabled?: boolean;
  mapWindowRef?: React.MutableRefObject<HTMLElement | null>;
  centerTime: number;
  timeSliderWidth: number;
  secondsPerPx: number;
  currentTime?: number;
  dataStartTime?: number;
  dataEndTime?: number;
  reduxAnimationStartTime?: string;
  reduxAnimationEndTime?: string;
  isTimeSliderHoverOn?: boolean;
  timeStep?: number;
  unfilteredSelectedTime?: number;
  timeSliderSpan?: number;
  setUnfilteredSelectedTime?: (unfilteredSelectedTime: number) => void;
  onSetCenterTime?: (newTime: number) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  onZoom?: (newSecondsPerPx: number, newCenterTime: number) => void;
  onSetAnimationStartTime?: (time: string) => void;
  onSetAnimationEndTime?: (time: string) => void;
  updateCanvasWidth?: (storeWidth: number, newWidth: number) => void;
  isDraggingStartAnimation?: boolean;
  setIsDraggingStartAnimation?: (isDraggingStartAnimation: boolean) => void;
  isDraggingEndAnimation?: boolean;
  setIsDraggingEndAnimation?: (isDraggingEndAnimation: boolean) => void;
  onStartTimeChange?: (startTime: number) => void;
  onEndTimeChange?: (endTime: number) => void;
  onSetTimeSliderSpan?: (
    newSpan: number,
    newCenterTime: number,
    newSecondsPerPx: number,
  ) => void;
  adjustSelectedTimeOnWheel?: ({
    event,
    deltaY,
  }: {
    event: WheelEvent | KeyboardEvent;
    deltaY: number;
  }) => void;
}

// Explanation of props can be found here:
// https://drive.google.com/file/d/1jqqNcciCH0UJiZ04HO-1vknmPPpT8fK5/view?usp=sharing

export const TimeSliderLegend: React.FC<TimeSliderLegendProps> = ({
  mapId,
  isTimeScrollingEnabled,
  mapWindowRef,
  centerTime,
  timeSliderWidth: canvasWidth,
  secondsPerPx = defaultSecondsPerPx,
  currentTime,
  dataStartTime,
  dataEndTime,
  reduxAnimationStartTime,
  reduxAnimationEndTime,
  timeStep,
  unfilteredSelectedTime,
  isDraggingStartAnimation,
  isDraggingEndAnimation,
  isTimeSliderHoverOn,
  timeSliderSpan,
  setUnfilteredSelectedTime = (): void => {},
  onSetCenterTime = (): void => {},
  onSetAnimationStartTime = (): void => {},
  onSetAnimationEndTime = (): void => {},
  updateCanvasWidth = (): void => {},
  setIsDraggingStartAnimation,
  setIsDraggingEndAnimation,
  onStartTimeChange,
  onEndTimeChange,
  onSetTimeSliderSpan,
  adjustSelectedTimeOnWheel = (): void => {},
}) => {
  const [, node] = useCanvasTarget('mousedown');
  const theme = useTheme();

  const [isDraggingSelectedTime, setIsDraggingSelectedTime] = useState(false);
  const [isDraggingLegend, setIsDraggingLegend] = useState(false);
  const xOffset = node.current?.canvas?.getBoundingClientRect().x || 0;
  const [isDraggingAnimationArea, setIsDraggingAnimationArea] = useState(false);
  const [isMouseOverCanvas, setIsMouseOverCanvas] = useState(false);
  const [cursorStyle, setCursorStyle] = useState('auto');
  const [showTooltip, setShowTooltip] = useState<ShowTooltip>(undefined);
  const [lastHandledRoundedTime, setLastHandledRoundedTime] =
    useState<number>();

  // remove active drag. can happen outside canvas.
  const [localAnimationStartTime, setLocalAnimationStartTime] = useState<
    number | undefined
  >();
  const [localAnimationEndTime, setLocalAnimationEndTime] = useState<
    number | undefined
  >();
  const [pointerTime, setPointerTime] = useState<number | undefined>();
  const [showPointerTimeTooltip, setShowPointerTimeTooltip] = useState(false);
  const debouncedSetShowPointerTimeTooltip = useRef(
    debounce(setShowPointerTimeTooltip, 500),
  ).current;

  const timeBetweenMouseAndStartAndEnd = useRef<
    { timeFromMouseToStart: number; timeFromMouseToEnd: number } | undefined
  >();
  const pixelsMovedSinceStartDragging = useRef(0);
  const isAnimationTimeModifiedSetRef = useRef(false);
  const tooltipPosition = useRef<number>();
  const startDraggingPosition = useRef(0);
  const popperRef = useRef(null);
  const isClickOrDrag = useRef<'click' | 'drag' | undefined>();

  const filteredTime = getFilteredTime(
    unfilteredSelectedTime!,
    timeStep,
    dataStartTime,
    dataEndTime,
  )!;
  const start = localAnimationStartTime!;
  const end = localAnimationEndTime!;
  const pixelsBetweenViewportAndTimesliderOnTop =
    getTimeSliderLegendElement(mapId)?.getBoundingClientRect()['top'];

  const setTooltipPosition = (): DOMRect => {
    const tooltipX = tooltipPosition.current;
    const tooltipY =
      pixelsBetweenViewportAndTimesliderOnTop &&
      pixelsBetweenViewportAndTimesliderOnTop - TIME_SLIDER_LEGEND_HEIGHT * 2.5;
    return new DOMRect(tooltipX, tooltipY, 0, 0);
  };

  const getTooltipTime = (): number | undefined => {
    switch (showTooltip) {
      case 'animationStart':
        return localAnimationStartTime;
      case 'animationEnd':
        return localAnimationEndTime;
      case 'mousePointer':
        return pointerTime;
      default:
        return undefined;
    }
  };

  const getTooltipText = (): string | undefined => {
    const time = getTooltipTime();
    if (!time) {
      return undefined;
    }
    const roundedTooltipTime =
      showTooltip === 'mousePointer' ? time : roundWithTimeStep(time);
    const tooltipText = dateUtils.dateToString(
      dateUtils.fromUnix(roundedTooltipTime),
      dateUtils.DATE_FORMAT_HOURS,
    );
    return tooltipText;
  };

  const roundWithTimeStep = (time: number): number =>
    roundWithTimeStepWebmap(time, timeStep!);

  const currentIndex = timeValues.indexOf(timeSliderSpan!);

  const updateTimeSpan = (deltaY: number): void => {
    if (onSetTimeSliderSpan) {
      // Don't do anything if we are already at the edges of the timespan options
      if (
        (deltaY < 0 && currentIndex === 0) ||
        (deltaY > 0 && currentIndex === timeValues.length - 1)
      ) {
        return;
      }
      const newTimeSpan =
        deltaY < 0
          ? timeValues[currentIndex - 1]
          : timeValues[currentIndex + 1];

      const newSecondsPerPx = secondsPerPxFromCanvasWidth(
        canvasWidth,
        newTimeSpan,
      );
      onSetTimeSliderSpan(newTimeSpan, centerTime, newSecondsPerPx!);
    }
  };

  const throttledUpdateTimeSpan = throttle(updateTimeSpan, 200);
  const debouncedAndThrottledUpdateTimeSpan = debounce(
    throttledUpdateTimeSpan,
    20,
  );

  const handleAnimationDragging = React.useCallback(
    (x: number, clientX: number) => {
      if (!localAnimationStartTime || !localAnimationEndTime) {
        return;
      }

      const mousePosition = clientX - xOffset;

      const startPos = timestampToPixel(
        localAnimationStartTime!,
        centerTime,
        canvasWidth,
        secondsPerPx,
      );

      const endPos = timestampToPixel(
        localAnimationEndTime!,
        centerTime,
        canvasWidth,
        secondsPerPx,
      );

      const mouseTimeUnix = pixelToTimestamp(
        mousePosition,
        centerTime,
        canvasWidth,
        secondsPerPx,
      );

      const startTime = roundWithTimeStepWebmap(mouseTimeUnix, timeStep!);
      const endTime = roundWithTimeStepWebmap(mouseTimeUnix, timeStep!);

      const pixelsBetweenOnLeft =
        getPixelsBetweenViewportAndTimesliderOnLeft(mapId);

      if (
        isDraggingStartAnimation ||
        isDraggingEndAnimation ||
        isDraggingAnimationArea
      ) {
        if (!isAnimationTimeModifiedSetRef.current) {
          isAnimationTimeModifiedSetRef.current = true;
        }
      }

      if (isDraggingStartAnimation) {
        if (isDraggingStartWithinBounds(endPos, mousePosition)) {
          return;
        }

        setLocalAnimationStartTime(
          roundWithTimeStepWebmap(mouseTimeUnix, timeStep!),
        );

        if (lastHandledRoundedTime !== startTime) {
          setLastHandledRoundedTime(startTime);
          setShowTooltip('animationStart');
          onStartTimeChange && onStartTimeChange(startTime);
        }
      } else if (isDraggingEndAnimation && pixelsBetweenOnLeft) {
        if (isDraggingEndWithinBounds(startPos, mousePosition, canvasWidth)) {
          return;
        }

        setLocalAnimationEndTime(
          roundWithTimeStepWebmap(mouseTimeUnix, timeStep!),
        );

        if (lastHandledRoundedTime !== endTime) {
          setLastHandledRoundedTime(endTime);
          setShowTooltip('animationEnd');
          onEndTimeChange && onEndTimeChange(endTime);
        }
      }
      if (isDraggingAnimationArea) {
        if (timeBetweenMouseAndStartAndEnd.current === undefined) {
          // to move the whole animation area we need to calculate the distance
          // between the mouse position and the start and end position

          const timeFromMouseToStart = mouseTimeUnix - start;
          const timeFromMouseToEnd = end - mouseTimeUnix;
          timeBetweenMouseAndStartAndEnd.current = {
            timeFromMouseToEnd,
            timeFromMouseToStart,
          };
          return;
        }
        pixelsMovedSinceStartDragging.current += x;

        const { timeFromMouseToEnd, timeFromMouseToStart } =
          timeBetweenMouseAndStartAndEnd.current;

        const newStartTime = mouseTimeUnix - timeFromMouseToStart;
        const newEndTime = mouseTimeUnix + timeFromMouseToEnd;

        const roundedNewStartTime = roundWithTimeStepWebmap(
          newStartTime,
          timeStep!,
        );
        const roundedNewEndTime = roundWithTimeStepWebmap(
          newEndTime,
          timeStep!,
        );

        setLocalAnimationStartTime(roundedNewStartTime);
        setLocalAnimationEndTime(roundedNewEndTime);
      }
    },
    [
      canvasWidth,
      centerTime,
      end,
      isDraggingAnimationArea,
      isDraggingEndAnimation,
      isDraggingStartAnimation,
      lastHandledRoundedTime,
      localAnimationEndTime,
      localAnimationStartTime,
      mapId,
      onEndTimeChange,
      onStartTimeChange,
      secondsPerPx,
      start,
      timeStep,
      xOffset,
    ],
  );

  useEffect(() => {
    const handleMouseUp = (): void => {
      pixelsMovedSinceStartDragging.current = 0;
      timeBetweenMouseAndStartAndEnd.current = undefined;
    };
    document.addEventListener('mouseup', handleMouseUp);
    return (): void => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const startTimestamp = convertStringTimeToUnix(reduxAnimationStartTime);
    const endTimestamp = convertStringTimeToUnix(reduxAnimationEndTime);
    setLocalAnimationStartTime(startTimestamp);
    setLocalAnimationEndTime(endTimestamp);
  }, [
    reduxAnimationStartTime,
    reduxAnimationEndTime,
    setLocalAnimationStartTime,
    setLocalAnimationEndTime,
  ]);

  useEffect(() => {
    const stoppedDragging =
      !isDraggingStartAnimation &&
      !isDraggingEndAnimation &&
      !isDraggingAnimationArea;

    if (stoppedDragging && isAnimationTimeModifiedSetRef.current) {
      if (start && start !== localAnimationStartTime) {
        onSetAnimationStartTime(dateUtils.fromUnix(start).toISOString());
      }
      if (end && end !== localAnimationEndTime) {
        onSetAnimationEndTime(dateUtils.fromUnix(end).toISOString());
      }

      isAnimationTimeModifiedSetRef.current = false;
    }
  }, [
    isDraggingStartAnimation,
    isDraggingEndAnimation,
    isDraggingAnimationArea,
    isAnimationTimeModifiedSetRef,
    localAnimationStartTime,
    localAnimationEndTime,
    onSetAnimationStartTime,
    onSetAnimationEndTime,
    start,
    end,
  ]);

  useEffect(() => {
    const handleMouseUp = (): void => {
      if (isDraggingAnimationArea) {
        onStartTimeChange && onStartTimeChange(localAnimationStartTime!);
        onEndTimeChange && onEndTimeChange(localAnimationEndTime!);
      }
      setCursorStyle('auto');
      setIsDraggingSelectedTime(false);
      setIsDraggingStartAnimation && setIsDraggingStartAnimation(false);
      setIsDraggingEndAnimation && setIsDraggingEndAnimation(false);
      setIsDraggingLegend(false);
      setShowTooltip(undefined);
      setIsDraggingAnimationArea(false);
      startDraggingPosition.current = 0;
      if (isAnimationTimeModifiedSetRef.current) {
        isAnimationTimeModifiedSetRef.current = false;
      }
    };
    document.addEventListener('mouseup', handleMouseUp);
    return (): void => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [
    setIsDraggingStartAnimation,
    setIsDraggingEndAnimation,
    localAnimationStartTime,
    localAnimationEndTime,
    onStartTimeChange,
    onEndTimeChange,
    isDraggingAnimationArea,
  ]);

  useEffect(() => {
    const handleMouseActions = (event: MouseEvent): void => {
      if (event.movementX === 0) {
        return;
      }

      if (isDraggingLegend) {
        const dragDistanceTime = event.movementX * secondsPerPx;
        const newCenterTime = centerTime - dragDistanceTime;
        onSetCenterTime(newCenterTime);
      } else if (
        isDraggingStartAnimation ||
        isDraggingEndAnimation ||
        isDraggingAnimationArea
      ) {
        handleAnimationDragging(event.movementX, event.clientX);
      } else if (isDraggingSelectedTime) {
        moveSelectedTimePx(
          event.movementX,
          canvasWidth,
          centerTime,
          dataStartTime!,
          dataEndTime!,
          secondsPerPx,
          timeStep!,
          unfilteredSelectedTime!,
          setUnfilteredSelectedTime,
        );
      }
      if (isMouseOverCanvas && isTimeSliderHoverOn) {
        moveSelectedTimePx(
          event.movementX,
          canvasWidth,
          centerTime,
          dataStartTime!,
          dataEndTime!,
          secondsPerPx,
          timeStep!,
          unfilteredSelectedTime!,
          setUnfilteredSelectedTime,
        );
      }
    };

    const handleMouseMove = (event: MouseEvent): void => {
      handleMouseActions(event);
    };

    document.addEventListener('mousemove', handleMouseMove);
    return (): void => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [
    isDraggingLegend,
    isDraggingStartAnimation,
    isDraggingEndAnimation,
    isDraggingAnimationArea,
    isDraggingSelectedTime,
    canvasWidth,
    centerTime,
    dataStartTime,
    dataEndTime,
    secondsPerPx,
    timeStep,
    unfilteredSelectedTime,
    setUnfilteredSelectedTime,
    onSetCenterTime,
    isTimeSliderHoverOn,
    isMouseOverCanvas,
    handleAnimationDragging,
  ]);

  useEffect(() => {
    if (!showTooltip && pointerTime) {
      if (showPointerTimeTooltip) {
        setShowTooltip('mousePointer');
      } else {
        debouncedSetShowPointerTimeTooltip(true);
      }
    } else if (showTooltip === 'mousePointer' && !pointerTime) {
      setShowTooltip(undefined);
      debouncedSetShowPointerTimeTooltip(false);
      debouncedSetShowPointerTimeTooltip.flush();
    } else if (showTooltip !== 'mousePointer') {
      debouncedSetShowPointerTimeTooltip(false);
      debouncedSetShowPointerTimeTooltip.flush();
    }
  }, [
    debouncedSetShowPointerTimeTooltip,
    pointerTime,
    showPointerTimeTooltip,
    showTooltip,
  ]);

  const onMouseDown = (x: number, y: number, width: number): void => {
    isClickOrDrag.current = 'click';
    startDraggingPosition.current = x;

    const {
      startAnimationPosition,
      endAnimationPosition,
      selectedTimePosition,
    } = getPositions(
      width,
      unfilteredSelectedTime,
      centerTime,
      secondsPerPx,
      start,
      end,
    );

    // start dragging selected time.
    if (isSelectedTimeIconArea(x, selectedTimePosition, DRAG_AREA_WIDTH)) {
      setCursorStyle('grabbing');
      setIsDraggingSelectedTime(true);
      return;
    }
    if (reduxAnimationStartTime && reduxAnimationEndTime) {
      const pixelsBetweenOnLeft =
        getPixelsBetweenViewportAndTimesliderOnLeft(mapId);
      tooltipPosition.current = pixelsBetweenOnLeft
        ? x + pixelsBetweenOnLeft
        : x;

      // start dragging either marker
      if (isLeftAnimationIconArea(x, startAnimationPosition, DRAG_AREA_WIDTH)) {
        setCursorStyle('grabbing');
        setIsDraggingStartAnimation && setIsDraggingStartAnimation(true);
        setShowTooltip('animationStart');

        return;
      }
      if (isRightAnimationIconArea(x, endAnimationPosition, DRAG_AREA_WIDTH)) {
        setCursorStyle('grabbing');
        setIsDraggingEndAnimation && setIsDraggingEndAnimation(true);
        setShowTooltip('animationEnd');
        return;
      }
      if (
        isInsideAnimationArea(x, startAnimationPosition, endAnimationPosition)
      ) {
        setCursorStyle('grabbing');
        setIsDraggingAnimationArea(true);

        return;
      }
    }
    // clicking the timeslider
    setIsDraggingLegend(true);
  };

  const onMouseUp = (x: number): void => {
    if (isClickOrDrag.current === 'click') {
      const unfilteredSelectedTimePx = timestampToPixel(
        unfilteredSelectedTime as number,
        centerTime,
        canvasWidth,
        secondsPerPx,
      );
      moveSelectedTimePx(
        x - unfilteredSelectedTimePx,
        canvasWidth,
        centerTime,
        dataStartTime!,
        dataEndTime!,
        secondsPerPx,
        timeStep!,
        unfilteredSelectedTime!,
        setUnfilteredSelectedTime,
      );
    }
    isClickOrDrag.current = undefined;
  };

  const onMouseMove = (
    x: number,
    y: number,
    event: MouseEvent,
    width: number,
  ): void => {
    const mousePosition = x;
    setPointerTime(
      pixelToTimestamp(mousePosition, centerTime, canvasWidth, secondsPerPx),
    );
    const pixelsBetweenOnLeft =
      getPixelsBetweenViewportAndTimesliderOnLeft(mapId);
    tooltipPosition.current =
      pixelsBetweenOnLeft && mousePosition + pixelsBetweenOnLeft;

    if (isClickOrDrag.current === 'click') {
      isClickOrDrag.current = 'drag';
    }
    if (
      isDraggingSelectedTime ||
      isDraggingStartAnimation ||
      isDraggingEndAnimation ||
      isDraggingAnimationArea
    ) {
      return;
    }
    const {
      startAnimationPosition,
      endAnimationPosition,
      selectedTimePosition,
    } = getPositions(
      width,
      unfilteredSelectedTime,
      centerTime,
      secondsPerPx,
      start,
      end,
    );

    // Adjust mouse cursor while hovering draggable areas
    const leftAnimationIconArea = isLeftAnimationIconArea(
      x,
      startAnimationPosition,
      DRAG_AREA_WIDTH,
    );
    const rightAnimationIconArea = isRightAnimationIconArea(
      x,
      endAnimationPosition,
      DRAG_AREA_WIDTH,
    );
    const selectedTimeArea = isSelectedTimeIconArea(
      x,
      selectedTimePosition,
      DRAG_AREA_WIDTH,
    );
    const insideAnimationArea = isInsideAnimationArea(
      x,
      startAnimationPosition,
      endAnimationPosition,
    );
    const hoveringLegendHandles =
      leftAnimationIconArea ||
      rightAnimationIconArea ||
      selectedTimeArea ||
      isDraggingLegend;
    if (hoveringLegendHandles) {
      setCursorStyle('ew-resize');
    } else if (insideAnimationArea && !isDraggingAnimationArea) {
      setCursorStyle('grab');
    } else if (insideAnimationArea && isDraggingAnimationArea) {
      setCursorStyle('grabbing');
    } else {
      setCursorStyle('auto');
    }
  };

  const onWheel = React.useCallback(
    ({
      event,
      deltaY,
    }: {
      event: WheelEvent | KeyboardEvent;
      deltaY: number;
    }): void => {
      if (event.ctrlKey || event.metaKey) {
        debouncedAndThrottledUpdateTimeSpan(deltaY);
      }
    },
    [debouncedAndThrottledUpdateTimeSpan],
  );

  const onWheelOverCanvas = React.useCallback(
    (props: { event: WheelEvent | KeyboardEvent; deltaY: number }): void => {
      onWheel(props);
      adjustSelectedTimeOnWheel(props);
    },
    [adjustSelectedTimeOnWheel, onWheel],
  );

  React.useEffect(() => {
    if (isTimeScrollingEnabled && mapWindowRef?.current) {
      const handleWheel = (event: WheelEvent): void => {
        onWheel({ event, deltaY: event.deltaY });
      };
      const element = mapWindowRef.current;
      element.addEventListener('wheel', handleWheel);
      return (): void => {
        element.removeEventListener('wheel', handleWheel);
      };
    }
    return undefined;
  }, [isTimeScrollingEnabled, mapWindowRef, onWheel]);

  return (
    <Box
      sx={{ position: 'relative', height: `${TIME_SLIDER_LEGEND_HEIGHT}px` }}
    >
      <CustomTooltip
        title={getTooltipText()}
        open={Boolean(showTooltip)}
        PopperProps={{
          popperRef,
          anchorEl: { getBoundingClientRect: setTooltipPosition },
        }}
      >
        <Box
          data-testid="timeSliderLegend"
          className={getTimeSliderLegendId(mapId)}
          sx={{
            height: `${TIME_SLIDER_LEGEND_HEIGHT}px`,
            borderRadius: '4.5px',
            overflow: 'hidden',
            cursor: cursorStyle,
          }}
          onMouseLeave={() => {
            setPointerTime(undefined);
          }}
        >
          <CanvasComponent
            ref={node}
            onMouseMove={onMouseMove}
            onWheel={onWheelOverCanvas}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onRenderCanvas={(
              ctx: CanvasRenderingContext2D,
              width: number,
              height: number,
            ): void => {
              updateCanvasWidth(canvasWidth, width);
              drawTimeSliderLegend(
                ctx,
                theme,
                width,
                height,
                centerTime,
                secondsPerPx,
                timeSliderSpan!,
                filteredTime,
                currentTime!,
                start,
                end,
                dataStartTime as number,
                dataEndTime as number,
              );
            }}
          />
        </Box>
      </CustomTooltip>
      {isTimeSliderHoverOn && (
        <Box
          data-testid="customCanvasMouseOverContainer"
          sx={{
            position: 'absolute',
            top: '-40px',
            left: 0,
            right: 0,
            bottom: 0,
            height: `${TIME_SLIDER_LEGEND_HEIGHT} + 40px`,
            zIndex: isMouseOverCanvas ? 100 : 0,
          }}
          onMouseOver={(): void => setIsMouseOverCanvas(true)}
          onMouseLeave={(): void => setIsMouseOverCanvas(false)}
        />
      )}
    </Box>
  );
};

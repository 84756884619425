/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { mapTypes } from '@opengeoweb/store';

export type ProductType = 'sigmet' | 'airmet';

/**
 * The possible type for the airmet
 */
export enum AirmetType {
  'NORMAL' = 'Normal',
  'TEST' = 'Test',
  'EXERCISE' = 'Exercise',
}

/**
 * The possible type for the sigmet
 */
export enum SigmetType {
  'NORMAL' = 'Normal',
  'TEST' = 'Test',
  'EXERCISE' = 'Exercise',
  'SHORT_TEST' = 'Short test',
  'SHORT_VA_TEST' = 'Short VA test',
}

export enum VisibilityCause {
  'DZ' = 'Drizzle',
  'DU' = 'Dust',
  'PO' = 'Dust or sand devil',
  'DS' = 'Dust storm',
  'FG' = 'Fog',
  'FC' = 'Funnel clouds',
  'GR' = 'Hail',
  'HZ' = 'Haze',
  'PL' = 'Ice pellets',
  'BR' = 'Mist',
  'RA' = 'Rain',
  'SA' = 'Sand',
  'SS' = 'Sand storm',
  'FU' = 'Smoke',
  'SN' = 'Snow',
  'SG' = 'Snow grains',
  'GS' = 'Soft hail',
  'SQ' = 'Squalls',
  'VA' = 'Volcanic Ash',
}

/* International Civil Aviation Organization (ICAO) locations
 */
export type ICAOLocation = string; // For example 'AMSTERDAM FIR';

/* Meteorological Watch Office (MWO) locations */
export type MWOLocation = string; // For example 'EHDB'; Use getMWO() from utils to get the current MWO

/* Air Traffic Services Unit locations */
export type ATSULocation = string; // For example 'EHAA';

/*  Air Traffic Services Region locations */
export type ATSRLocation = string; // For example 'EHAA';

/* FIR type, for getting FIR list of backend */
export type FIR = Record<ICAOLocation, string>;

/* Speed unit, e.g. 'KT', 'KMH' */
export enum MovementUnit {
  'KT' = 'kt',
  'KMH' = 'kmh',
}

export enum WindUnit {
  'KT' = 'kt',
  'MPS' = 'mps',
}

export type VisibilityUnit = 'm';

/* Point coordinate in lat/lon system */
export interface Coordinate {
  latitude: number;
  longitude: number;
}

/* Date object, eg ISO8601 `2020-09-18T12:00:00Z` */
export type DateType = string;

/**
 * ProductStatus is returned by the backend. The backend fills in  this property.
 * For the backend, it should only need to store 'DRAFT' | 'PUBLISHED' | 'CANCELLED' in its DB.
 * The backend should derive and return the status 'EXPIRED' from the product properties.
 *
 * Note: ProductStatus from the backend should be refreshed regularly to display status updates.
 */
export type ProductStatus =
  | 'DRAFT'
  | 'PUBLISHED'
  | 'CANCELLED'
  | 'EXPIRED'
  | 'RENEWED'
  | 'DISCARDED';

export enum ProductAction {
  PUBLISH = 'PUBLISH',
  CANCEL = 'CANCEL',
  RENEW = 'RENEW',
  SAVE = 'SAVE',
  DISCARD = 'DISCARD',
  DUPLICATE = 'DUPLICATE',
  CLOSE = 'CLOSE',
}

export enum ProductStatusDescription {
  'DRAFT' = 'Draft',
  'PUBLISHED' = 'Published',
  'CANCELLED' = 'Cancelled',
  'EXPIRED' = 'Expired',
  'RENEWED' = 'Renewed',
  'DISCARDED' = 'Discarded',
}

/**
 * A Cancel Sigmet/Airmet (a sigmet/airmet which cancels another sigmet/airmet) can only have the status 'PUBLISHED',
 * e.g. cannot be edited, cancelled or stored as draft.
 */
export type CancelProductStatus = 'PUBLISHED';

/**
 *  ProductCanbe lifecycle is determined by the backend. E.g. the backend fills in these properties.
 *
 * 'DRAFTED': product can be saved as draft
 * 'DISCARDED': This product can be thrown away
 * 'PUBLISHED': product can be PUBLISHED
 * 'CANCELLED': product can be CANCELLED
 */
export type ProductCanbe =
  | 'DRAFTED'
  | 'DISCARDED'
  | 'PUBLISHED'
  | 'RENEWED'
  | 'CANCELLED';

/**
 * ProductSequence is a sequence label with a counter of the SIGMETs/AIRMETs issued on a day (starting at with 1 01:00),
 * max 3 characters long; first character should be a capital letter,
 * others are numbers: A01-A99, 01-99 or 1-99
 */
export type ProductSequenceId = string;

/**
 * This is the base sigmet/airmet structure, used for both the Sigmet, Airmet and CancelSigmet, CancelAirmet
 */
export interface BaseProduct {
  /* Start of validity time for SIGMET/AIRMET */
  validDateStart: DateType;

  /* End of validity time for SIGMET/AIRMET in ISO8601: "2020-09-17T12:03:00Z" */
  validDateEnd: DateType;

  /* ICAO name of FIR: for example "AMSTERDAM FIR" */
  firName: ICAOLocation;

  /* WMO name of Meteorological Watch Office (issuer): "EHDB", */
  locationIndicatorMWO: MWOLocation;

  locationIndicatorATSU: ATSULocation;

  locationIndicatorATSR: ATSRLocation;

  /* --- The following properties are controlled by the backend: --- */

  /* Unique id, controlled/generated by backend */
  uuid?: string;

  /* Sequence ID */
  sequence?: ProductSequenceId;

  /* Date of issuing (publishing) of SIGMET/AIRMET in ISO8601: "2020-09-17T12:03:00Z"
   * issueDate is needed in case of status 'PUBLISHED'
   */
  issueDate?: DateType;
}

/**
 * To cancel a published sigmet
 *
 * To cancel a published sigmet, we send a cancel sigmet with all the mandatory properties from the basesigmet.
 */
export interface CancelSigmet extends BaseProduct {
  /* The sigmet sequence id to cancel */
  cancelsSigmetSequenceId: ProductSequenceId;

  /* Start of validity time for the SIGMET to cancel */
  validDateStartOfSigmetToCancel: DateType;

  /* End of validity time for the SIGMET to cancel in ISO8601: "2020-09-17T12:03:00Z" */
  validDateEndOfSigmetToCancel: DateType;

  /* The status of the CancelSigmet, should only be set to PUBLISHED
   * You cannot save a draft a cancelsigmet, can only be 'PUBLISHED'
   */
  status: CancelProductStatus;

  /** Extra properties in case of cancel va SIGMET, if set to unknown, property not set */
  vaSigmetMoveToFIR?: ICAOLocation;
}

/**
 * To cancel a published airmet
 *
 * To cancel a published airmet, we send a cancel airmet with all the mandatory properties from the baseairmet.
 */
export interface CancelAirmet extends BaseProduct {
  /* The airmet sequence id to cancel */
  cancelsAirmetSequenceId: ProductSequenceId;

  /* Start of validity time for the Airmet to cancel */
  validDateStartOfAirmetToCancel: DateType;

  /* End of validity time for the Airmet to cancel in ISO8601: "2020-09-17T12:03:00Z" */
  validDateEndOfAirmetToCancel: DateType;

  /* The status of the CancelAirmet, should only be set to PUBLISHED
   * You cannot save a draft a cancelairmet, can only be 'PUBLISHED'
   */
  status: CancelProductStatus;
}

export enum CloudLevelUnits {
  'FT' = 'ft',
  'M' = 'm',
}

export enum LevelUnits {
  'FT' = 'ft',
  'M' = 'm',
  'FL' = 'FL',
}

export interface LevelType {
  value: number; //  height value
  unit: LevelUnits;
}

export interface CloudLevelType {
  value: number; //  height value
  unit: CloudLevelUnits;
}

export enum Direction {
  'N' = 'N',
  'NNE' = 'NNE',
  'NE' = 'NE',
  'ENE' = 'ENE',
  'E' = 'E',
  'ESE' = 'ESE',
  'SE' = 'SE',
  'SSE' = 'SSE',
  'S' = 'S',
  'SSW' = 'SSW',
  'SW' = 'SW',
  'WSW' = 'WSW',
  'W' = 'W',
  'WNW' = 'WNW',
  'NW' = 'NW',
  'NNW' = 'NNW',
}

export type ObservationOrForcast = 'OBS' | 'FCST';

export type Change = 'INTSF' | 'WKN' | 'NC';

export type LevelInfoMode =
  | 'AT'
  | 'BETW'
  | 'BETW_SFC'
  | 'TOPS'
  | 'TOPS_ABV'
  | 'TOPS_BLW'
  | 'ABV';

export type CloudLevelInfoMode =
  | 'BETW'
  | 'BETW_SFC'
  | 'BETW_SFC_ABV'
  | 'BETW_ABV';

export type SigmetMovementType =
  | 'STATIONARY'
  | 'MOVEMENT'
  | 'FORECAST_POSITION'
  | 'NO_VA_EXP';

export type AirmetMovementType = 'STATIONARY' | 'MOVEMENT';

export type AviationPhenomenaCode = Extract<keyof AviationPhenomenon, 'code'>;
export type AviationPhenomenaDescription = Extract<
  keyof AviationPhenomenon,
  'description'
>;

/**
 * To store all the other sigmet information
 */
export interface Sigmet extends BaseProduct {
  /* The sigmet phenomenon for example "OBSC_TS" */
  phenomenon: AviationPhenomenaCode;

  /* Current validity status of this sigmet */
  status: ProductStatus;

  /* Whether this Sigmet is based on an observation or on a forecast */
  isObservationOrForecast?: ObservationOrForcast;

  observationOrForecastTime?: DateType; // Datetime of observation or forecast in ISO8601:

  /* Type of Sigmet (e.g normal/test/excercise)
   * SHORT_TEST and SHORT_VA_TEST are only used in the frontend and converted to TEST for API calls */
  type: 'NORMAL' | 'TEST' | 'EXERCISE' | 'SHORT_TEST' | 'SHORT_VA_TEST';

  /** Change type of the Sigmet, can be:
   * INTSF:Intensifying
   * WKN: Weakening
   * NC: No change
   */
  change?: Change;

  /* Description of the vertical extent of the SIGMET's phenomenon, described by 1 or 2 levels and a level mode. */
  levelInfoMode?: LevelInfoMode;

  /* In case lowerlevel is specified, this acts as the upper level, otherwise it is the main level */
  level?: LevelType;

  /* Lowerlevel is only used for between and is not surface */
  lowerLevel?: LevelType;

  /* A GeoJSON Feature object describing the fir as used to calculate the intersection between drawn start/end and fir   */
  firGeometry?: GeoJSON.FeatureCollection;

  /* A GeoJSON Feature object describing the startposition of the SIGMET area as the forecaster specified it.
   * This has a property "selectionType" describing the type of the geometry ("point", box", "poly" or "fir")   */
  startGeometry?: GeoJSON.FeatureCollection;

  /* A GeoJSON Feature object describing the intersection of the start geometry and the FIR area.
   * This field will be generated either in the frontend or in the backend. */
  startGeometryIntersect?: GeoJSON.FeatureCollection;

  /* (optional) A GeoJSON Feature object describing the startposition of the SIGMET area as the forecaster specified it.
   * This has a property "selectionType" describing the type of the geometry ("point", box", "poly" or "fir")
   * Rule: When an endGeometry is specified, you should not specify a movement speed and direction
   */
  endGeometry?: GeoJSON.FeatureCollection;

  /* (optional) A GeoJSON Feature object describing the intersection of the start geometry and the FIR area.
   * This field will be generated either in the frontend or in the backend. */
  endGeometryIntersect?: GeoJSON.FeatureCollection;

  /* Movement type of the Sigmet, can be:
   * STATIONARY: not moving
   * MOVEMENT: moving, specified by speed/direction, (If not STATIONARY and no end geometry is given)
   * FORECAST_POSITION: moving, specified by end geometry
   * NO_VA_EXP: no volcanic ash expected, option only available for phenomenon  VA_CLD
   */
  movementType?: SigmetMovementType;

  /* If movementType is MOVEMENT, a movement speed and direction should be given */
  movementSpeed?: number;
  movementUnit?: MovementUnit;
  movementDirection?: Direction;

  /** Extra properties in case of va SIGMET */
  vaSigmetVolcanoName?: string;
  vaSigmetVolcanoCoordinates?: Coordinate;

  /** Extra properties in case of cancel va SIGMET, if set to unknown, property not set */
  vaSigmetMoveToFIR?: ICAOLocation;
}

/**
 * To store all the other sigmet information
 */
export interface Airmet extends BaseProduct {
  /* The airmet phenomenon */
  phenomenon: AviationPhenomenaCode;

  /* Current validity status of this airmet */
  status: ProductStatus;

  /* Whether this airmet is based on an observation or on a forecast */
  isObservationOrForecast: ObservationOrForcast;

  observationOrForecastTime?: DateType; // Datetime of observation or forecast in ISO8601:

  /* Type of airmet (e.g normal/test/excercise) */
  type: 'NORMAL' | 'TEST' | 'EXERCISE';

  /* Change type of the airmet */
  change: Change;

  /* Description of the vertical extent of the airmets phenomenon, described by 1 or 2 levels and a level mode. */
  levelInfoMode?: LevelInfoMode;

  /* In case lowerlevel is specified, this acts as the upper level, otherwise it is the main level */
  level?: LevelType;

  /* Lowerlevel is only used for between and is not surface */
  lowerLevel?: LevelType;

  /* A GeoJSON Feature object describing the fir as used to calculate the intersection between drawn start/end and fir   */
  firGeometry?: GeoJSON.FeatureCollection;

  /* A GeoJSON Feature object describing the startposition of the airmet area as the forecaster specified it.
   * This has a property "selectionType" describing the type of the geometry ("point", box", "poly" or "fir")   */
  startGeometry: GeoJSON.FeatureCollection;

  /* A GeoJSON Feature object describing the intersection of the start geometry and the FIR area.
   * This field will be generated either in the frontend or in the backend. */
  startGeometryIntersect: GeoJSON.FeatureCollection;

  /* Movement type of the airmet, can be:
   * STATIONARY: not moving
   * MOVEMENT: moving, specified by speed/direction
   */
  movementType: AirmetMovementType;

  /* If movementType is MOVEMENT, a movement speed and direction should be given */
  movementSpeed?: number;
  movementUnit?: MovementUnit;
  movementDirection?: Direction;

  /* Only for phenomenon surface wind */
  windSpeed?: number;
  windUnit?: WindUnit;
  windDirection?: number;

  /* Only for phenomenon surface visibility */
  visibilityValue?: number;
  visibilityCause?: VisibilityCause;
  visibilityUnit?: VisibilityUnit;

  /* Only for Cloud phenomena */
  cloudLevelInfoMode?: CloudLevelInfoMode;
  cloudLevel?: CloudLevelType;
  cloudLowerLevel?: CloudLevelType;
}

/**
 * Type which can be used to post a sigmet from the frontend to the backend
 */
export interface SigmetFromFrontend {
  sigmet: Sigmet | CancelSigmet;
  changeStatusTo: ProductStatus;
}

/**
 * Type with sigmet info from the backend
 */
export interface SigmetFromBackend {
  sigmet: Sigmet | CancelSigmet;
  creationDate: DateType;
  canbe: ProductCanbe[];
  uuid?: string;
}

/**
 * Type which can be used to post a airmet from the frontend to the backend
 */
export interface AirmetFromFrontend {
  airmet: Airmet | CancelAirmet;
  changeStatusTo: ProductStatus;
}

/**
 * Type with airmet info from the backend
 */
export interface AirmetFromBackend {
  airmet: Airmet | CancelAirmet;
  creationDate: DateType;
  canbe: ProductCanbe[];
  uuid?: string;
}

export type ProductFromBackend = AirmetFromBackend | SigmetFromBackend;

export type AviationProduct = Sigmet | CancelSigmet | Airmet | CancelAirmet;

export const isInstanceOfSigmet = (
  obj: Sigmet | CancelSigmet,
): obj is Sigmet => {
  return 'phenomenon' in obj;
};
export const isInstanceOfCancelSigmet = (
  obj: AviationProduct,
): obj is CancelSigmet => {
  return 'cancelsSigmetSequenceId' in obj;
};

export const isInstanceOfAirmet = (
  obj: Airmet | CancelAirmet,
): obj is Airmet => {
  return 'phenomenon' in obj;
};
export const isInstanceOfCancelAirmet = (
  obj: AviationProduct,
): obj is CancelAirmet => {
  return 'cancelsAirmetSequenceId' in obj;
};

export const isInstanceOfSigmetOrAirmet = (
  obj: AviationProduct,
): obj is Sigmet | Airmet => {
  return 'phenomenon' in obj;
};

export const isInstanceOfCancelSigmetOrAirmet = (
  obj: AviationProduct,
): obj is CancelSigmet | CancelAirmet => {
  return 'cancelsSigmetSequenceId' in obj || 'cancelsAirmetSequenceId' in obj;
};

export enum StartOrEndDrawing {
  'start' = 'start',
  'end' = 'end',
}

export type FormMode = 'new' | 'edit' | 'view';

export interface FormFieldProps {
  productType?: ProductType;
  onChange?: (
    event?: React.ChangeEvent<HTMLInputElement>,
    phenomenaList?: AviationPhenomenon[],
  ) => void;
  isDisabled: boolean;
  isReadOnly?: boolean;
}

export interface ConfigurableFormFieldProps extends FormFieldProps {
  productConfig: ProductConfig;
}

export interface AllowedUnits {
  unit_type: string;
  allowed_units: string[];
}

export interface AviationPhenomenon {
  code: string;
  description: string;
}

export type FirAllowedUnits = Record<string, AllowedUnits[]>;

export interface CloudLevelMinMaxType {
  FT?: number;
  M?: number;
}

export interface SurfaceWindMinMaxType {
  KT?: number;
  MPS?: number;
}

export interface LevelMinMaxType {
  FT?: number;
  FL?: number;
  M?: number;
}

export interface MovementMinMaxType {
  KT?: number;
  KMH?: number;
}

// config types
export type FIRLocationGeoJson = GeoJSON.FeatureCollection<
  GeoJSON.Geometry,
  { selectionType?: string }
>;

export interface FIRArea {
  fir_name: string;
  fir_location: FIRLocationGeoJson;
  location_indicator_atsr: string;
  location_indicator_atsu: string;
  max_hours_of_validity: number;
  hours_before_validity: number;
  level_min?: LevelMinMaxType;
  level_max?: LevelMinMaxType;
  level_rounding_FL?: number;
  level_rounding_FT?: number;
  level_rounding_M?: number;
  movement_min?: MovementMinMaxType;
  movement_max?: MovementMinMaxType;
  movement_rounding_kt?: number;
  movement_rounding_kmh?: number;
  max_polygon_points?: number;
  units: AllowedUnits[];
  phenomenon: AviationPhenomenon[];
}

export interface FIRConfigSigmet extends FIRArea {
  tc_max_hours_of_validity: number;
  tc_hours_before_validity: number;
  va_max_hours_of_validity: number;
  va_hours_before_validity: number;
  adjacent_firs: string[];
  area_preset: string;
}

export interface FIRConfigAirmet extends FIRArea {
  cloud_level_min?: CloudLevelMinMaxType;
  cloud_level_max?: CloudLevelMinMaxType;
  cloud_lower_level_min?: CloudLevelMinMaxType;
  cloud_lower_level_max?: CloudLevelMinMaxType;
  cloud_level_rounding_ft?: number;
  cloud_level_rounding_m_below?: number;
  cloud_level_rounding_m_above?: number;
  wind_direction_rounding?: number;
  wind_speed_max?: SurfaceWindMinMaxType;
  wind_speed_min?: SurfaceWindMinMaxType;
  visibility_max?: number;
  visibility_min?: number;
  visibility_rounding_below?: number;
  visibility_rounding_above?: number;
}

type SigmetFIRArea = Record<string, FIRConfigSigmet>;
type AirmetFIRArea = Record<string, FIRConfigAirmet>;
export type Firareas = SigmetFIRArea | AirmetFIRArea;

export interface ProductConfig {
  location_indicator_mwo: string;
  active_firs: string[];
  valid_from_delay_minutes: number;
  default_validity_minutes: number;
  fir_areas: Firareas;
  // uses mapPreset key (and no snake_case) to keep it in line with TS type
  mapPreset?: mapTypes.MapPreset;
  omit_change_va_and_rdoact_cloud?: boolean;
}

export interface SigmetConfig extends ProductConfig {
  fir_areas: SigmetFIRArea;
}

export interface AirmetConfig extends ProductConfig {
  fir_areas: AirmetFIRArea;
}

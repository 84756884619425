/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

export const defaultDelay = 1000;

export const useControlledTooltip = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [delayedTooltip, setDelayedTooltip] = React.useState(false);

  React.useEffect(() => {
    if (tooltipOpen === true) {
      const timer = setTimeout(() => setDelayedTooltip(true), defaultDelay);
      return (): void => {
        clearTimeout(timer);
      };
    }
    setDelayedTooltip(false);
    return (): void => {};
  }, [tooltipOpen]);

  return [delayedTooltip, setTooltipOpen];
};

export const CustomTooltip: React.FC<TooltipProps> = (props) => {
  return (
    <Tooltip
      {...props}
      enterDelay={defaultDelay}
      enterNextDelay={defaultDelay}
      disableInteractive
    />
  );
};

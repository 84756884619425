/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector } from 'react-redux';
import { CoreAppStore, mapSelectors, uiTypes } from '@opengeoweb/store';

import DimensionSelectButtonConnect from './DimensionSelectButtonConnect';

interface MultiDimensionSelectMapButtonsConnectProps {
  mapId: string;
  source?: uiTypes.Source;
}

const MultiDimensionSelectMapButtonsConnect: React.FC<
  MultiDimensionSelectMapButtonsConnectProps
> = ({ mapId, source = 'app' }: MultiDimensionSelectMapButtonsConnectProps) => {
  const dimensions = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapDimensions(store, mapId),
  );
  const availableDimensionsNoTime = dimensions!.filter(
    (dimension) => !dimension.name!.includes('time'),
  );

  if (!availableDimensionsNoTime || !availableDimensionsNoTime.length) {
    return null;
  }
  return (
    <>
      {availableDimensionsNoTime.map((dimension) => {
        return (
          <DimensionSelectButtonConnect
            mapId={mapId}
            dimension={dimension.name!}
            key={dimension.name}
            source={source}
          />
        );
      })}
    </>
  );
};

export default MultiDimensionSelectMapButtonsConnect;

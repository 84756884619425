/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { IWMJSMap, webmapUtils } from '@opengeoweb/webmap';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { CoreAppStore, mapSelectors } from '@opengeoweb/store';
import { ZoomControls } from '@opengeoweb/webmap-react';

interface ZoomControlConnectProps {
  mapId?: string;
}

const ZoomControlConnect: React.FC<ZoomControlConnectProps> = ({
  mapId,
}: ZoomControlConnectProps) => {
  const isVisible = useSelector((store: CoreAppStore) =>
    mapSelectors.isZoomControlsVisible(store, mapId!),
  );
  const adagucRef = React.useRef<IWMJSMap | null>(null);

  React.useEffect(() => {
    const webmapjs = webmapUtils.getWMJSMapById(mapId!);
    if (!webmapjs) {
      return;
    }
    adagucRef.current = webmapjs;
  }, [mapId]);

  const onZoomIn = (): void => adagucRef.current!.zoomIn(undefined!);
  const onZoomOut = (): void => adagucRef.current!.zoomOut();
  const onZoomReset = (): void =>
    adagucRef.current!.zoomToLayer(adagucRef.current!.getActiveLayer());

  if (!isVisible) {
    return null;
  }

  return (
    <ZoomControls
      onZoomIn={onZoomIn}
      onZoomOut={onZoomOut}
      onZoomReset={onZoomReset}
    />
  );
};

export default ZoomControlConnect;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { SystemScope } from '@opengeoweb/shared';
import {
  createEntityAdapter,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import { PickedFilterState } from '../../components/FilterList/filter-hooks';
import { Warning } from '../publicWarningForm/types';
import { PublicWarningsState } from './types';

export const publicWarningsAdapter = createEntityAdapter<Warning>({
  selectId: (warning) => warning.id!,
});

export const initialState: PublicWarningsState = {
  warnings: publicWarningsAdapter.getInitialState(),
  isLoading: true,
  lastUpdatedTime: '',
  filterState: {},
};

const slice = createSlice({
  initialState,
  name: 'publicWarnings',
  reducers: {
    setWarningFilters: (
      draft: Draft<PublicWarningsState>,
      action: PayloadAction<{
        filterState: PickedFilterState;
        panelId?: string;
        origin: SystemScope;
      }>,
    ) => {
      const { filterState, panelId } = action.payload;
      if (panelId) {
        draft.filterState[panelId] = filterState;
      }
    },
  },
});

export const { reducer: publicWarningsReducer, actions: publicWarningActions } =
  slice;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  uiTypes,
  syncGroupsSelectors,
  syncGroupsTypes,
  genericActions,
  useSetupDialog,
  syncGroupsActions,
} from '@opengeoweb/store';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { SyncGroupViewer } from './SyncGroupViewer';
import { useCoreTranslation } from '../../utils/i18n';

export const SyncGroupViewerConnect: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useCoreTranslation();

  const viewState = useSelector(syncGroupsSelectors.syncGroupGetViewState);

  const isTimeScrollingEnabled = useSelector(
    syncGroupsSelectors.isTimeScrollingEnabled,
  );

  const syncGroupAddGroup = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupAddGroupPayload): void => {
      dispatch(genericActions.syncGroupAddGroup(payload));
    },
    [dispatch],
  );

  const syncGroupRemoveGroup = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupRemoveGroupPayload): void => {
      dispatch(genericActions.syncGroupRemoveGroup(payload));
    },
    [dispatch],
  );

  const syncGroupAddTarget = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupAddTargetPayload): void => {
      dispatch(genericActions.syncGroupAddTarget(payload));
    },
    [dispatch],
  );

  const syncGroupRemoveTarget = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupRemoveTargetPayload): void => {
      dispatch(genericActions.syncGroupRemoveTarget(payload));
    },
    [dispatch],
  );

  const { dialogOrder, isDialogOpen, onCloseDialog, setDialogOrder } =
    useSetupDialog(uiTypes.DialogTypes.SyncGroups);

  const toggleTimeScroll = React.useCallback(
    (payload: syncGroupsTypes.SyncGroupToggleIsTimeScrollingEnabled) => {
      const message: snackbarTypes.TranslatableMessage = {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key:
          payload.isTimeScrollingEnabled === true
            ? t('workspace-time-scroll-toggle-on')
            : t('workspace-time-scroll-toggle-off'),
      };

      dispatch(snackbarActions.openSnackbar(message));
      dispatch(
        syncGroupsActions.syncGroupToggleIsTimeScrollingEnabled(
          payload as syncGroupsTypes.SyncGroupToggleIsTimeScrollingEnabled,
        ),
      );
    },
    [dispatch, t],
  );

  return (
    <SyncGroupViewer
      onClose={onCloseDialog}
      isOpen={isDialogOpen}
      syncGroupViewState={viewState}
      syncGroupAddTarget={syncGroupAddTarget}
      syncGroupRemoveTarget={syncGroupRemoveTarget}
      syncGroupAddGroup={syncGroupAddGroup}
      syncGroupRemoveGroup={syncGroupRemoveGroup}
      order={dialogOrder}
      onMouseDown={setDialogOrder}
      isTimeScrollingEnabled={isTimeScrollingEnabled}
      toggleTimeScroll={toggleTimeScroll}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CSSProperties } from 'react';
import { layerSelectTypes } from '../../store';

const buttonLarge = 80;
const buttonSmall = 60;

export const layerSelectColumnsLarge = {
  column1: {
    width: 112,
  },
  column2: {
    marginTop: '6px',
    width: `calc(100% - 112px)`,
    height: '36px',
    paddingLeft: '20px',
  },
  column3: {
    height: '12px',
  },
  column4: {
    height: '24px',
  },
  column5: {
    height: '24px',
    width: 140,
  },
  column6: {
    height: '24px',
    width: `calc(100% - 140px)`,
  },
  column7: {
    height: '48px',
    width: buttonLarge,
    marginRight: 1,
    marginLeft: 2,
  },
  column8: {
    width: `calc(100% - ${buttonLarge}px - 24px)`,
  },
  column9: {
    width: `calc(${buttonLarge}px + 24px)`,
  },
};

export const layerSelectColumnsSmall = {
  column1: {
    width: '100%',
  },
  column2: {
    marginTop: '4px',
    width: `calc(100% - 20px)`,
  },
  column3: {
    height: '12px',
  },
  column4: {
    height: '48px',
  },
  column5: {
    height: '24px',
    width: '100%',
  },
  column6: {
    height: '24px',
    width: '100%',
  },
  column7: {
    height: '48px',
    width: buttonSmall,
    marginRight: 0,
    marginLeft: 1,
  },
  column8: {
    width: `calc(100% - ${buttonSmall}px - 8px)`,
  },
  column9: {
    width: `calc(${buttonSmall}px + 8px)`,
  },
};

const breakpoint = 500;

export const widthToColumns = (
  width: number,
): Record<string, CSSProperties> => {
  if (width <= breakpoint) {
    return layerSelectColumnsSmall;
  }
  return layerSelectColumnsLarge;
};

const rowHeightLarge = 68;
const rowHeightSmall = 136;

export const widthToRowHeight = (width: number): number => {
  if (width <= breakpoint) {
    return rowHeightSmall;
  }
  return rowHeightLarge;
};

export const sortByService = (
  serviceObj: layerSelectTypes.ActiveServiceObjectEntities,
): layerSelectTypes.ActiveServiceObjectEntities =>
  Object.entries(serviceObj)
    .sort(([, a], [, b]) => a!.serviceName!.localeCompare(b?.serviceName!))
    .reduce((r, [key, value]) => ({ ...r, [key]: value }), {});

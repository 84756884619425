/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { AuthenticationConfig } from '@opengeoweb/authentication';
import { AlertBanner } from '@opengeoweb/shared';
import { Box } from '@mui/material';
import {
  Credentials,
  refreshAccessTokenAndSetAuthContext,
} from '@opengeoweb/api';
import { useDispatch } from 'react-redux';
import { snackbarActions, snackbarTypes } from '@opengeoweb/snackbar';
import { useGeowebTranslation } from '../../i18n';

interface ConnectionIssueBannerProps {
  auth?: Credentials;
  onSetAuth?: (cred: Credentials) => void;
  authConfig?: AuthenticationConfig;
}

export const ConnectionIssueBanner: React.FC<ConnectionIssueBannerProps> = ({
  auth,
  onSetAuth,
  authConfig,
}: ConnectionIssueBannerProps) => {
  const { t } = useGeowebTranslation();
  const dispatch = useDispatch();

  const showSnackbar = React.useCallback(
    (message: snackbarTypes.SnackbarMessage) => {
      dispatch(snackbarActions.openSnackbar(message));
    },
    [dispatch],
  );
  const hasError = auth && auth.has_connection_issue === true;
  if (!hasError) {
    // There are no connection issues, so nothing to show.
    return null;
  }
  const checkConnection = async (): Promise<void> => {
    onSetAuth!({ ...auth, has_connection_issue: false });
    await refreshAccessTokenAndSetAuthContext({
      auth,
      onSetAuth: (cred: Credentials): void => {
        if (cred.has_connection_issue !== true) {
          showSnackbar({
            type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
            key: 'geoweb-connection-restored',
          });
        }
        onSetAuth!(cred);
      },
      config: {
        baseURL: '',
        authTokenURL: authConfig!.GW_AUTH_TOKEN_URL,
        appURL: authConfig!.GW_APP_URL,
        authClientId: authConfig!.GW_AUTH_CLIENT_ID,
      },
    });
  };
  return (
    <div style={{ position: 'absolute', left: '20px', top: '50px' }}>
      <Box
        sx={{
          whiteSpace: 'pre-wrap',
          width: 'inherit',
          height: 'inherit',
          backgroundColor: 'geowebColors.background.surface',
        }}
      >
        <AlertBanner
          severity="error"
          title={t('geoweb-connection-lost')}
          actionButtonProps={{
            title: t('geoweb-try-again'),
            onClick: checkConnection,
          }}
        />
      </Box>
    </div>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import {
  getLegendGraphicURLForLayer,
  LayerFoundation,
  legendImageStore,
  webmapUtils,
  WMImage,
  WMImageEventType,
} from '@opengeoweb/webmap';
import { CanvasComponent } from '@opengeoweb/shared';
import { useWebmapReactTranslation } from '../../utils/i18n';
import { LegendLayout } from './LegendLayout';

interface LegendProps {
  layer: LayerFoundation;
}

const defaultCanvasWidth = 150;
const defaultCanvasHeight = 30;

/**
 * Renders a legendgraphic image based on a WMImage.
 *
 * @param wmImage The wmImage element to render (from WMImage.js)
 * @param ctx The canvas to render the image on
 * @param width The width you want the image to have
 * @param height The height you want the image to have
 */
const renderLegendGraphic = (
  wmImage: WMImage,
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
): void => {
  ctx.beginPath();
  ctx.fillStyle = 'white';
  ctx.rect(0, 0, width, height);
  ctx.fill();
  if (wmImage) {
    if (wmImage.isLoading()) {
      /* Legend image is loading */
      ctx.fillStyle = 'blue';
      ctx.fillText('... loading ...', 10, 10);
    } else if (wmImage.hasError()) {
      /* Legend image has an error */
      ctx.fillStyle = 'red';
      ctx.fillText('error :(', 10, 10);
    } else {
      /* All OK, just draw the legend image */

      ctx.drawImage(
        wmImage.getElement(),
        0,
        0,
        Math.min(wmImage.getElement().width, width),
        Math.min(wmImage.getElement().height, height),
      );
    }
  }
};

export const Legend: React.FC<LegendProps> = ({ layer }) => {
  /* Variable to remember the previous image */
  const previousLegendImage = useRef<WMImage | null>(null);

  /* Function to force rerender of this component when legend image loads */
  const [, setState] = useState();

  const layerId = layer && layer.id;

  /* The canvas listens to the size of its container. We will update it according to the image size. */
  const [canvasContainerWidth, setCanvasContainerWidth] =
    useState(defaultCanvasWidth);
  const [canvasContainerHeight, setCanvasContainerHeight] =
    useState(defaultCanvasHeight);
  let imageWidth: number;
  let imageHeight: number;
  const { t } = useWebmapReactTranslation();
  const legendPrefix = t('webmap-react-legend');

  /* Register callback to legendImagestore to trigger rerender when image is loaded. */
  useEffect(() => {
    legendImageStore.addImageEventCallback(
      (image, id, imageEventType: WMImageEventType) => {
        if (imageEventType === WMImageEventType.Loaded) {
          setState(null!);
        }
      },
      `${legendPrefix}_${layerId}`,
    );
    return (): void => {
      legendImageStore.removeEventCallback(`${legendPrefix}_${layerId}`);
    };
  }, [layerId, legendPrefix]);

  /* Should not do anything if no layer is given */
  if (!layer) {
    return null;
  }

  const isLayerEnabled = layer.enabled;
  if (!isLayerEnabled) {
    return null;
  }

  const layerOpacity = layer.opacity ? layer.opacity : 1;

  /* Get the wmLayer, it has more detailed info about the WMS service, 
  like a title and the WMS legendgraphic url */
  const wmLayer = webmapUtils.getWMLayerById(layer.id!);
  return (
    <LegendLayout
      title={wmLayer?.title!}
      name={wmLayer?.name!}
      dimensions={layer?.dimensions!}
      height={canvasContainerHeight}
      width={canvasContainerWidth}
      minWidth={defaultCanvasWidth}
    >
      <CanvasComponent
        redrawInterval={500}
        onRenderCanvas={(ctx, width, height): void => {
          /* 
                Check if the wmLayer is available, this happens when the WMS GetCapabilities is loaded and parsed.
                Note that we cannot use the wmLayer variable above, as this one could contain a non initialized WMJSLayer.
               */
          const legendWmLayer = webmapUtils.getWMLayerById(layer.id!);
          const legendUrl = getLegendGraphicURLForLayer(legendWmLayer);
          const image = legendImageStore.getImage(legendUrl);

          /* If there is no image for the given URL, we do not want to display a legend image (e.g. outside range) */
          if (!image) {
            previousLegendImage.current = null;
            setCanvasContainerWidth(defaultCanvasWidth);
            setCanvasContainerHeight(defaultCanvasHeight);
            ctx.beginPath();
            ctx.fillStyle = '#fff';
            ctx.rect(0, 0, width, height);
            ctx.fill();
            ctx.fillStyle = 'black';
            if (!legendUrl) {
              ctx.fillText('No legend graphic available', 5, 16);
            }
          } else {
            /* If there is an image for the given url, and has no error, but it is not yet loading or loaded, start loading it! */
            if (
              image &&
              image.hasError() === false &&
              image.isLoaded() === false &&
              image.isLoading() === false
            ) {
              image.load(); /* Will eventually trigger the callback used in the useEffect */
            }

            /* If the image is ready, we can show it in the component, store it in our ref, otherwise we keep the previous image to prevent flickering */
            if (image && image.isLoaded()) {
              previousLegendImage.current = image;

              /* Set the canvas container to the same size as the image */
              imageHeight = image.getHeight();
              setCanvasContainerHeight(imageHeight);

              imageWidth = image.getWidth();
              setCanvasContainerWidth(imageWidth);

              /* Give the canvas a white background before adding the image */
              ctx.fillStyle = '#fff';
              ctx.rect(0, 0, width, height);
              ctx.fill();
              /* Set the opacity used in the layer */
              ctx.globalAlpha = layerOpacity;
            }

            renderLegendGraphic(
              previousLegendImage.current!,
              ctx,
              imageWidth,
              imageHeight,
            );
          }
        }}
      />
    </LegendLayout>
  );
};

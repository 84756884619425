/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import themeTranslations from '../../../locales/theme.json';

export const THEME_NAMESPACE = 'theme';

export const initThemeI18n = (): void => {
  void i18n.use(initReactI18next).init({
    lng: 'en',
    ns: THEME_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        [THEME_NAMESPACE]: themeTranslations.en,
      },
      fi: {
        [THEME_NAMESPACE]: themeTranslations.fi,
      },
      no: {
        [THEME_NAMESPACE]: themeTranslations.no,
      },
      nl: {
        [THEME_NAMESPACE]: themeTranslations.nl,
      },
    },
  });
};

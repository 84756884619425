/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import {
  DialogProps,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
import { ChevronLeft } from '@opengeoweb/theme';
import { NotificationTrigger } from '../NotificationTrigger/NotificationTrigger';
import { useSpaceweatherTranslation } from '../../utils/i18n';

const styles = {
  dialogHeader: {
    minHeight: '1em',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)',
    zIndex: 1,
  },
  closeButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogChildren: {
    margin: 'auto',
    padding: '20px',
    paddingTop: '20px !important',
    minHeight: 200,
    minWidth: 400,
    width: 'inherit',
    boxSizing: 'content-box',
  },
};

interface ContentDialogProps extends DialogProps {
  open: boolean;
  toggleStatus: () => void;
  title: string;
  children?: React.ReactNode;
  options?: React.ReactNode;
  alertBanner?: React.ReactNode;
  onClose?: () => void;
}

const ContentDialog: React.FC<ContentDialogProps> = ({
  open,
  toggleStatus,
  title,
  children,
  options,
  onClose,
  alertBanner = null,
  ...other
}: ContentDialogProps) => {
  const { t } = useSpaceweatherTranslation();
  const handleClose = (): void => {
    if (onClose) {
      onClose();
    } else {
      toggleStatus();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={other.maxWidth ? other.maxWidth : 'lg'}
      {...other}
    >
      <DialogTitle sx={styles.dialogHeader}>
        <Button
          onClick={handleClose}
          sx={styles.closeButton}
          color="secondary"
          size="medium"
          startIcon={<ChevronLeft color="secondary" />}
          data-testid="contentdialog-close"
        >
          <Typography variant="subtitle1" sx={{ color: 'secondary' }}>
            {t('dialog-back')}
          </Typography>
        </Button>
        <Typography
          data-testid="dialogTitle"
          variant="h6"
          component="span"
          sx={styles.dialogTitle}
        >
          {title}
        </Typography>
      </DialogTitle>
      <NotificationTrigger />
      {alertBanner !== null && alertBanner}
      <DialogContent sx={styles.dialogChildren}>{children}</DialogContent>
      <DialogActions>{options}</DialogActions>
    </Dialog>
  );
};

export default ContentDialog;

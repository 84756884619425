/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { cloneDeep } from 'lodash';
import { MapPreset } from '@opengeoweb/store';
import { PROJECTION } from '@opengeoweb/shared';
import { snackbarTypes } from '@opengeoweb/snackbar';
import { TFunction } from 'i18next';
import { getCleanSearchQuery } from '../../utils/utils';
import {
  WorkspacePreset,
  WorkspacePresetAction,
  WorkspacePresetListItem,
} from '../workspace/types';
import { FetchWorkspaceParams, WorkspaceListFilter } from './types';
import { FetchViewPresetsListParams } from '../viewPresetsList/types';
import { ViewPresetsListFilter } from '../viewPresets/types';

export const getSnackbarMessage = (
  action: WorkspacePresetAction,
  title: string,
): snackbarTypes.TranslatableMessage => {
  // Trim and add quotes
  const trimmedTitle = `${title.trim()}`;
  switch (action) {
    case WorkspacePresetAction.DELETE:
      return {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key: 'workspace-list-success-delete',
        params: { TITLE: trimmedTitle },
      };
    case WorkspacePresetAction.DELETE_SYSTEM_PRESET:
      return {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key: 'workspace-list-success-delete-system',
        params: {
          TITLE: trimmedTitle,
        },
      };
    case WorkspacePresetAction.DUPLICATE:
      return {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key: 'workspace-list-success-duplicate',
        params: { TITLE: trimmedTitle },
      };
    case WorkspacePresetAction.EDIT:
      return {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key: 'workspace-list-success-edit',
        params: { TITLE: trimmedTitle },
      };
    case WorkspacePresetAction.RESET:
      return {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key: 'workspace-list-success-reset',
        params: {
          TITLE: trimmedTitle,
        },
      };
    default:
      return {
        type: snackbarTypes.SnackbarMessageType.TRANSLATABLE_MESSAGE,
        key: 'workspace-list-success-save',
        params: { TITLE: trimmedTitle },
      };
  }
};

export function getEmptyWorkspaceListItem(): Omit<
  WorkspacePresetListItem,
  'title'
>;
export function getEmptyWorkspaceListItem(
  t: TFunction,
): WorkspacePresetListItem;
export function getEmptyWorkspaceListItem(
  t?: TFunction,
): Omit<WorkspacePresetListItem, 'title'> | WorkspacePresetListItem;
export function getEmptyWorkspaceListItem(
  t?: TFunction,
): Omit<WorkspacePresetListItem, 'title'> | WorkspacePresetListItem {
  return {
    id: undefined!,
    title: t ? t('workspace-new') : undefined,
    scope: 'system',
    abstract: '',
    viewType: 'singleWindow',
  };
}

export function getEmptyMapWorkspace(): Omit<WorkspacePreset, 'title'>;
export function getEmptyMapWorkspace(t: TFunction): WorkspacePreset;
export function getEmptyMapWorkspace(
  t?: TFunction,
): WorkspacePreset | Omit<WorkspacePreset, 'title'> {
  return {
    ...getEmptyWorkspaceListItem(t),

    views: {
      allIds: ['1_screen'],
      byId: {
        '1_screen': {
          title: t ? t('workspace-mappreset-new') : undefined,
          componentType: 'Map',
          initialProps: {
            mapPreset: {
              layers: [],
              proj: {
                bbox: {
                  left: 58703.6377,
                  bottom: 6408480.4514,
                  right: 3967387.5161,
                  top: 11520588.9031,
                },
                srs: PROJECTION.EPSG_3857.value,
              },
              shouldShowLayerManager: true,
              dockedLayerManagerSize: 'sizeSmall',
            },
            syncGroupsIds: [],
          },
        },
      },
    },
    mosaicNode: '1_screen',
  };
}

export const getEmptyWorkspacePreset = (): Omit<WorkspacePreset, 'title'> => ({
  scope: 'system',
  abstract: '',
  views: {
    allIds: ['1_screen'],
    byId: {
      '1_screen': {
        componentType: 'Map',
        initialProps: {
          mapPreset: {
            layers: [],
            proj: {
              bbox: {
                left: 58703.6377,
                bottom: 6408480.4514,
                right: 3967387.5161,
                top: 11520588.9031,
              },
              srs: PROJECTION.EPSG_3857.value,
            },
            shouldShowLayerManager: true,
            dockedLayerManagerSize: 'sizeSmall',
          },
          syncGroupsIds: [],
        },
      },
    },
  },
  mosaicNode: '1_screen',
  viewType: 'singleWindow',
});

export const createCustomEmptyMapWorkspace = (
  emptyWorkspaceTitle: string,
  emptyMapWorkspaceTitle: string,
  customMapPreset?: MapPreset,
): WorkspacePreset => {
  const customEmptyMapWorkspace = cloneDeep(getEmptyWorkspacePreset());
  if (customMapPreset) {
    customEmptyMapWorkspace.views.byId!['1_screen'].initialProps!.mapPreset =
      customMapPreset;
  }

  customEmptyMapWorkspace.views.byId!['1_screen'].title =
    emptyMapWorkspaceTitle;
  const emptyWorkspacePreset: WorkspacePreset = {
    ...customEmptyMapWorkspace,
    title: emptyWorkspaceTitle,
  };
  return emptyWorkspacePreset;
};

export const constructFilterParams = (
  filters: WorkspaceListFilter[] | ViewPresetsListFilter[],
  searchQuery: string,
): FetchWorkspaceParams | FetchViewPresetsListParams => {
  // get filters
  const { scope } = filters.reduce<{ scope: string[] }>(
    (filterList, filter) => {
      if (filter.isSelected && filter.type === 'scope') {
        return {
          ...filter,
          scope: filterList.scope.concat(filter.id),
        };
      }
      return filterList;
    },
    { scope: [] },
  );
  // get search
  const search = getCleanSearchQuery(searchQuery);

  return {
    ...(scope.length && {
      scope: scope.toString(),
    }),
    ...(search.length && {
      search,
    }),
  };
};

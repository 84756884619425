/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React, { Component } from 'react';

interface CanvasComponentProps {
  onRenderCanvas: (
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number,
    canvas: HTMLCanvasElement,
  ) => void;
  onCanvasClick?: (x: number, y: number, width: number, height: number) => void;
  onMouseMove?: (
    x: number,
    y: number,
    event: MouseEvent,
    width: number,
  ) => void;
  onMouseUp?: (x: number) => void;
  onMouseDown?: (x: number, y: number, width: number) => void;
  onTouchStart?: (event: TouchEvent, width: number) => void;
  onTouchEnd?: () => void;
  onTouchMove?: (event: TouchEvent, width: number) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  isFocussed?: (isFocussed: boolean) => void;
  onWheel?: ({
    event,
    deltaY,
    deltaX,
    canvasWidth,
    mouseX,
  }: {
    event: WheelEvent;
    deltaY: number;
    deltaX: number;
    canvasWidth: number;
    mouseX: number;
  }) => void;
  loop?: boolean;
  redrawInterval?: number;
  resizeCallback?: (newWidth: number) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  children?: React.ReactNode;
}

export class CanvasComponent extends Component<CanvasComponentProps> {
  canvas: HTMLCanvasElement | null = null;

  ctx: CanvasRenderingContext2D | null = null;

  currentWidth = 1;

  currentHeight = 1;

  canvascontaineroutside: React.RefObject<HTMLDivElement> | null = null;

  canvascontainer: React.RefObject<HTMLDivElement> | null = null;

  timer: ReturnType<typeof setTimeout> | null = null;

  redrawTimer: ReturnType<typeof setTimeout> | null = null;

  loopHasStarted = false;

  mounted = false;

  static defaultProps = {
    onRenderCanvas: (): void => {
      /* intentionally left blank */
    },
    onCanvasClick: (): void => {
      /* intentionally left blank */
    },
    onMouseMove: (): void => {
      /* intentionally left blank */
    },
    onMouseUp: (): void => {
      /* intentionally left blank */
    },
    onMouseDown: (): void => {
      /* intentionally left blank */
    },
    onTouchStart: (): void => {
      /* intentionally left blank */
    },
    onTouchEnd: (): void => {
      /* intentionally left blank */
    },
    onTouchMove: (): void => {
      /* intentionally left blank */
    },
    onWheel: (): void => {
      /* intentionally left blank */
    },
  };

  private _isFocussed: boolean;

  constructor(props: CanvasComponentProps) {
    super(props);
    this.updateCanvas = this.updateCanvas.bind(this);
    this.handleMouseMoveEvent = this.handleMouseMoveEvent.bind(this);
    this.handleMouseUpEvent = this.handleMouseUpEvent.bind(this);
    this.handleMouseDownEvent = this.handleMouseDownEvent.bind(this);
    this.resize = this.resize.bind(this);
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this.handleClickEvent = this.handleClickEvent.bind(this);
    this.handleWheelEvent = this.handleWheelEvent.bind(this);
    this.handleTouchStartEvent = this.handleTouchStartEvent.bind(this);
    this.handleTouchEndEvent = this.handleTouchEndEvent.bind(this);
    this.handleTouchMoveEvent = this.handleTouchMoveEvent.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.startLoop = this.startLoop.bind(this);
    this._documentKeyDown = this._documentKeyDown.bind(this);
    this._isFocussed = false;
    this.canvas = null;
    this.canvascontaineroutside = React.createRef();
    this.canvascontainer = React.createRef();
  }

  componentDidMount(): void {
    this.mounted = true;
    if (this.canvas) {
      this.canvas.addEventListener('mousemove', this.handleMouseMoveEvent);
      this.canvas.addEventListener('mouseup', this.handleMouseUpEvent);
      this.canvas.addEventListener('mousedown', this.handleMouseDownEvent);
      this.canvas.addEventListener('touchstart', this.handleTouchStartEvent);
      this.canvas.addEventListener('touchend', this.handleTouchEndEvent);
      this.canvas.addEventListener('touchmove', this.handleTouchMoveEvent);
      this.canvas.addEventListener('wheel', this.handleWheelEvent);
      this.canvas.addEventListener('click', this.handleClickEvent);
      document.addEventListener('keydown', this._documentKeyDown, false);
      this.resize();
      this.updateCanvas();
    }
    const { onKeyUp, redrawInterval: loopInterval } = this.props;
    if (onKeyUp) {
      window.addEventListener('keyup', this.onKeyUp);
    }
    window.addEventListener('resize', this._handleWindowResize);
    if (
      this.canvascontaineroutside &&
      this.canvascontaineroutside.current &&
      onKeyUp
    ) {
      this.canvascontaineroutside.current.focus();
    }
    this.timer = setInterval(() => {
      this.resize();
    }, 1000);
    if (loopInterval) {
      this.redrawTimer = setInterval(() => {
        this.updateCanvas();
      }, loopInterval);
    }
  }

  shouldComponentUpdate(): boolean {
    window.requestAnimationFrame(() => {
      this.updateCanvas();
    });
    return false;
  }

  componentWillUnmount(): void {
    this.mounted = false;
    window.removeEventListener('resize', this._handleWindowResize);

    const { onKeyUp } = this.props;
    if (onKeyUp) {
      window.removeEventListener('keyup', this.onKeyUp);
    }
    if (this.canvas) {
      this.canvas.removeEventListener('mousedown', this.handleMouseDownEvent);
      this.canvas.removeEventListener('mouseup', this.handleMouseUpEvent);
      this.canvas.removeEventListener('mousemove', this.handleMouseMoveEvent);
      this.canvas.removeEventListener('touchstart', this.handleTouchStartEvent);
      this.canvas.removeEventListener('touchend', this.handleTouchEndEvent);
      this.canvas.removeEventListener('touchmove', this.handleTouchMoveEvent);
      this.canvas.removeEventListener('wheel', this.handleWheelEvent);
      this.canvas.removeEventListener('click', this.handleClickEvent);
      document.removeEventListener('keydown', this._documentKeyDown);
    }
    clearInterval(this.timer!);
    clearInterval(this.redrawTimer!);
  }

  handleMouseMoveEvent(event: MouseEvent): void {
    const { onMouseMove, onCanvasClick } = this.props;
    const x = event.offsetX;
    const y = event.offsetY;

    if (event.buttons === 1) {
      onCanvasClick!(x, y, this.currentWidth, this.currentHeight);
    }
    onMouseMove!(x, y, event, this.currentWidth);
  }

  handleMouseUpEvent(event: MouseEvent): void {
    const { onMouseUp } = this.props;
    const x = event.offsetX;
    onMouseUp!(x);
  }

  handleMouseDownEvent(event: MouseEvent): void {
    const { onMouseDown } = this.props;
    const x = event.offsetX;
    const y = event.offsetY;
    onMouseDown!(x, y, this.currentWidth);
  }

  handleTouchStartEvent(event: TouchEvent): void {
    const { onTouchStart } = this.props;
    onTouchStart!(event, this.currentWidth);
  }

  handleTouchEndEvent(): void {
    const { onTouchEnd } = this.props;
    onTouchEnd!();
  }

  handleTouchMoveEvent(event: TouchEvent): void {
    const { onTouchMove, onCanvasClick } = this.props;
    const touches = event && event.touches && event.touches[0];
    if (touches && touches.clientX && touches.clientY) {
      const x2 =
        event.touches[0].clientX -
        (event.touches[0].target as HTMLElement).offsetLeft;
      const y2 = event.touches[0].clientY;
      onCanvasClick!(x2, y2, this.currentWidth, this.currentHeight);
    }

    onTouchMove!(event, this.currentWidth);
  }

  handleClickEvent(event: MouseEvent): void {
    const { onCanvasClick } = this.props;
    const x = event.offsetX;
    const y = event.offsetY;
    onCanvasClick!(x, y, this.currentWidth, this.currentHeight);
  }

  handleWheelEvent(event: WheelEvent): void {
    const { onWheel } = this.props;

    onWheel!({
      event,
      deltaY: event.deltaY,
      deltaX: event.deltaX,
      canvasWidth: (event.target as HTMLCanvasElement).width,
      mouseX: event.offsetX,
    });
  }

  onFocus(): void {
    const { isFocussed } = this.props;
    this._isFocussed = true;
    if (isFocussed) {
      isFocussed(true);
    }
  }

  onBlur(): void {
    const { isFocussed } = this.props;
    this._isFocussed = false;
    if (isFocussed) {
      isFocussed(false);
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    const { onKeyUp } = this.props;
    if (onKeyUp) {
      onKeyUp(event);
    }
    if (
      this.canvascontaineroutside &&
      this.canvascontaineroutside.current &&
      onKeyUp
    ) {
      if (event.code === 'Escape') {
        this.canvascontaineroutside.current.focus();
      }
    }
  }

  _documentKeyDown(event: KeyboardEvent): void {
    if (this._isFocussed) {
      const { onKeyDown } = this.props;
      if (onKeyDown) {
        onKeyDown(event);
      }
    }
  }

  _handleWindowResize(): void {
    this.resize();
  }

  startLoop(): void {
    const { onRenderCanvas } = this.props;
    if (this.mounted === false || this.loopHasStarted === false) {
      return;
    }

    if (this.canvas) {
      onRenderCanvas(
        this.ctx!,
        this.currentWidth,
        this.currentHeight,
        this.canvas,
      );
    }
    requestAnimationFrame(this.startLoop);
  }

  updateCanvas(): void {
    if (!this.canvas) {
      return;
    }

    const { onRenderCanvas, loop } = this.props;
    this.ctx = this.canvas.getContext('2d');
    if (!this.ctx) {
      return;
    }
    if (this.ctx.canvas.height !== this.currentHeight) {
      this.ctx.canvas.height = this.currentHeight;
    }
    if (this.ctx.canvas.width !== this.currentWidth) {
      this.ctx.canvas.width = this.currentWidth;
    }

    if (loop !== true) {
      this.loopHasStarted = false;
      onRenderCanvas(
        this.ctx,
        this.currentWidth,
        this.currentHeight,
        this.canvas,
      );
    } else if (this.loopHasStarted === false) {
      this.loopHasStarted = true;
      this.startLoop();
    }
  }

  resize(): void {
    const {
      canvascontainer,
      props: { resizeCallback },
    } = this;
    if (canvascontainer && canvascontainer.current) {
      const newWidth = canvascontainer.current.clientWidth;
      const newHeight = canvascontainer.current.clientHeight;
      if (
        newWidth !== undefined &&
        newHeight !== undefined &&
        (this.currentWidth !== newWidth || this.currentHeight !== newHeight)
      ) {
        this.currentWidth = newWidth;
        this.currentHeight = newHeight;
        this.updateCanvas();
        resizeCallback && resizeCallback(newWidth);
      }
    }
  }

  render(): React.ReactElement {
    this.updateCanvas();
    this._handleWindowResize();
    return (
      <div
        role="button"
        ref={this.canvascontaineroutside}
        tabIndex={0}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
          display: 'block',
          overflow: 'hidden',
        }}
      >
        <div
          ref={this.canvascontainer}
          style={{
            minWidth: 'inherit',
            minHeight: 'inherit',
            width: 'inherit',
            height: 'inherit',
            overflow: 'hidden',
            display: 'block',
            border: 'none',
          }}
          data-testid="canvas-container"
        >
          <div
            style={{ overflow: 'visible', width: 0, height: 0 }}
            role="presentation"
            aria-label="canvas"
          >
            <canvas
              data-testid="canvas"
              ref={(canvas): void => {
                this.canvas = canvas;
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { ApiModule, CreateApiFn } from './types';

interface ApiContextState<ApiType> {
  api: ApiType;
}
const ApiContext = React.createContext({
  api: null!,
});

interface ApiProviderProps extends ApiModule {
  children: React.ReactNode;
  createApi: CreateApiFn;
}

const registeredApis = new Map();

function registerApi<Type>(name: string, api: Type): void {
  registeredApis.set(name, api);
}

// eslint-disable-next-line no-unused-vars
function unRegisterApi(name: string): void {
  // TODO https://gitlab.com/opengeoweb/opengeoweb/-/issues/3406: enable again after home is replaced
  // registeredApis.delete(name);
}

export function getApi<Type>(name: string): Type {
  return registeredApis.get(name);
}

export const ApiProvider: React.FC<ApiProviderProps> = ({
  children,
  createApi,
  name,
  ...apiModuleProps
}: ApiProviderProps) => {
  const handleNewApi = React.useCallback((): void => {
    const newApi = createApi(apiModuleProps);
    if (name) {
      registerApi(name, newApi);
    }
    return newApi;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createApi, name]);

  const [api, setApi] = React.useState(handleNewApi);

  React.useEffect(() => {
    setApi(handleNewApi);
    return (): void => {
      if (name) {
        unRegisterApi(name);
      }
    };
  }, [handleNewApi, name]);

  const contextValue = React.useMemo(() => ({ api }) as never, [api]);

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

export function useApiContext<ApiType>(): ApiContextState<ApiType> {
  return React.useContext(ApiContext);
}

export default ApiContext;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Grid2 as Grid, Typography, Grid2Props } from '@mui/material';

import { styles } from '../ProductForm.styles';

interface ProductFormFieldLayoutProps extends Grid2Props {
  title?: string;
  children: React.ReactNode;
}

const ProductFormFieldLayout: React.FC<ProductFormFieldLayoutProps> = ({
  title,
  children,
  ...props
}: ProductFormFieldLayoutProps) => {
  return (
    <Grid container spacing={3} {...props}>
      <Grid container justifyContent="flex-end" size={4}>
        {title && (
          <Typography variant="subtitle1" sx={styles.label}>
            {title}
          </Typography>
        )}
      </Grid>
      <Grid container justifyContent="flex-start" size={8}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ProductFormFieldLayout;

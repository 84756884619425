/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  IconButton,
  IconButtonProps,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { CustomTooltip } from '../CustomTooltip';

export type Variant = 'flat' | 'tool' | 'boxed';

type TooltipProps = Omit<MuiTooltipProps, 'children'>;

export interface CustomIconProps extends IconButtonProps {
  tooltipTitle?: string;
  variant?: Variant;
  isSelected?: boolean;
  shouldShowAsDisabled?: boolean;
  tooltipProps?: TooltipProps;
}

const CustomIconButtonNoMemo = React.forwardRef<
  HTMLButtonElement,
  CustomIconProps
>(
  (
    {
      tooltipTitle: defaultTooltipTitle = '',
      variant = 'flat',
      isSelected = false,
      shouldShowAsDisabled = false,
      size = 'small',
      children,
      className,
      tooltipProps: defaultTooltipProps,
      ...props
    }: CustomIconProps,
    ref,
  ) => {
    const classNames = [
      ...(className ? [className] : []),
      ...(isSelected ? ['Mui-selected'] : []),
      variant,
    ].join(' ');

    const tooltipTitle = defaultTooltipProps?.title || defaultTooltipTitle;
    const tooltipProps: TooltipProps = React.useMemo(() => {
      return {
        ...defaultTooltipProps,
        title: tooltipTitle,
        placement: defaultTooltipProps?.placement || 'top',
        sx: { pointerEvents: 'none' },
      };
    }, [defaultTooltipProps, tooltipTitle]);

    const renderIconButton = (): React.ReactElement => (
      <IconButton
        aria-label={tooltipTitle as string}
        {...props}
        size={size}
        className={classNames}
        ref={ref}
        aria-pressed={isSelected}
        sx={{
          ...props.sx,
          ...(shouldShowAsDisabled && {
            '&.MuiIconButton-root': {
              color: `geowebColors.iconButtons.${variant}.disabled.color`,
            },
            '&.Mui-focusVisible': {
              color: `geowebColors.iconButtons.${variant}.default.color`,
            },
          }),
        }}
      >
        {children}
      </IconButton>
    );

    return tooltipTitle && !props.disabled ? (
      <CustomTooltip {...tooltipProps}>{renderIconButton()}</CustomTooltip>
    ) : (
      renderIconButton()
    );
  },
);

export const CustomIconButton = React.memo(CustomIconButtonNoMemo);

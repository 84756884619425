/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { dateUtils } from '@opengeoweb/shared';
import { getFir } from '../../components/ProductForms/utils';
import {
  LevelUnits,
  Direction,
  MovementUnit,
  Sigmet,
  SigmetFromBackend,
  Change,
  AviationPhenomenaCode,
} from '../../types';
import { sigmetConfig } from '../config';

// TODO: [Loes Cornelis] Replace with correct strucutre and in JSON format - not js object
export const fakeSigmetList: SigmetFromBackend[] = [
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: 'A01',
      validDateStart: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,

      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 3 }),
      )!,

      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(
        dateUtils.sub(dateUtils.utc(), { hours: 2 }),
      ),

      movementType: 'FORECAST_POSITION',
      change: 'WKN',
      type: 'NORMAL',
      status: 'DRAFT',
      levelInfoMode: 'AT',

      level: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
      endGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626021876513, 52.98697212658787],
                  [7.1354804185898635, 53.68061155113826],
                  [7.955441614892378, 52.82212063293559],
                  [6.406626021876513, 52.98697212658787],
                ],
              ],
            },
          },
        ],
      },
      endGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626, 52.986972],
                  [6.898724319383813, 53.45529498136169],
                  [7.191667, 53.3],
                  [7.14344908001551, 52.90854691815449],
                  [6.406626, 52.986972],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: dateUtils.getCurrentTimeAsString(),

    canbe: ['CANCELLED'],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE2',
      phenomenon: 'VA_CLD' as AviationPhenomenaCode,
      sequence: 'A01V',
      issueDate: '2020-09-17T12:00:00Z',
      validDateStart: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,

      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 2 }),
      )!,

      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(
        dateUtils.sub(dateUtils.utc(), { hours: 1 }),
      ),

      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      levelInfoMode: 'BETW',
      vaSigmetVolcanoName: 'EYJAFJALLAJOKULL',
      vaSigmetVolcanoCoordinates: { latitude: 63.62, longitude: -19.61 },
      level: {
        value: 2000,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: dateUtils.getCurrentTimeAsString(),
    canbe: ['CANCELLED'],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE3',
      phenomenon: 'VA_CLD' as AviationPhenomenaCode,
      sequence: 'A02V',
      issueDate: dateUtils.dateToString(dateUtils.utc()),

      validDateStart: dateUtils.getCurrentTimeAsString(),

      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,

      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(dateUtils.utc()),
      movementType: 'NO_VA_EXP',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      levelInfoMode: 'BETW',
      vaSigmetVolcanoName: 'EYJAFJALLAJOKULL',
      vaSigmetVolcanoCoordinates: { latitude: 63.62, longitude: -19.61 },
      level: {
        value: 1500,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    canbe: [],
    creationDate: '2020-09-17T14:30:00Z',
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE4',
      validDateStart: '2020-09-17T14:30:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      sequence: '4',
      issueDate: '2020-09-17T14:30:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      status: 'PUBLISHED',
      cancelsSigmetSequenceId: '113',
      validDateStartOfSigmetToCancel: '2020-09-17T13:00:00Z',
      validDateEndOfSigmetToCancel: '2020-09-17T17:00:00Z',
      type: 'NORMAL',
    },
  },
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: [],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE7',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: 'A09',
      issueDate: '2020-09-17T12:00:00Z',
      validDateStart: '2020-09-17T13:00:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T12:00:00Z',
      movementType: 'FORECAST_POSITION',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      levelInfoMode: 'AT',
      level: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
      endGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
      endGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: [],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE12',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: '113',
      issueDate: '2020-09-17T12:00:00Z',
      validDateStart: '2020-09-17T13:00:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T12:00:00Z',
      levelInfoMode: 'AT',
      level: {
        value: 300,
        unit: 'FL' as LevelUnits,
      },
      movementType: 'FORECAST_POSITION',
      change: 'WKN',
      type: 'NORMAL',
      status: 'CANCELLED',
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
      endGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626021876513, 52.98697212658787],
                  [7.1354804185898635, 53.68061155113826],
                  [7.955441614892378, 52.82212063293559],
                  [6.406626021876513, 52.98697212658787],
                ],
              ],
            },
          },
        ],
      },
      endGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626, 52.986972],
                  [6.898724319383813, 53.45529498136169],
                  [7.191667, 53.3],
                  [7.14344908001551, 52.90854691815449],
                  [6.406626, 52.986972],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-18T12:00:00Z',
    canbe: [],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE8',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: 'A02',
      issueDate: '2020-09-18T12:00:00Z',
      validDateStart: '2020-09-18T23:00:00Z',
      validDateEnd: '2020-09-19T02:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T12:00:00Z',
      levelInfoMode: 'AT',
      level: {
        value: 300,
        unit: 'FL' as LevelUnits,
      },
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.4324608936767396, 51.79162703117144],
                  [3.4324608936767396, 52.4211358618957],
                  [5.2318201855628175, 52.4211358618957],
                  [5.2318201855628175, 51.79162703117144],
                  [3.4324608936767396, 51.79162703117144],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.4324608936767396, 51.79162703117144],
                  [3.4324608936767396, 52.4211358618957],
                  [5.2318201855628175, 52.4211358618957],
                  [5.2318201855628175, 51.79162703117144],
                  [3.4324608936767396, 51.79162703117144],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: [],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE13',
      phenomenon: 'VA_CLD' as AviationPhenomenaCode,
      sequence: 'A02V',
      issueDate: '2020-09-17T12:00:00Z',
      observationOrForecastTime: null!,
      vaSigmetVolcanoName: 'EYJAFJALLAJOKULL',
      vaSigmetVolcanoCoordinates: { latitude: 63.62, longitude: -19.61 },
      validDateStart: '2020-09-17T13:00:00Z',
      validDateEnd: '2020-09-17T17:00:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      movementType: 'MOVEMENT',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      levelInfoMode: 'AT',
      movementSpeed: 10.0,
      movementUnit: 'KT' as MovementUnit,
      movementDirection: Direction.NNE,
      level: {
        value: 2000,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'point',
            },
            geometry: {
              type: 'Point',
              coordinates: [5, 52],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'point',
            },
            geometry: {
              type: 'Point',
              coordinates: [5, 52],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    sigmet: {
      uuid: '41d4dd4f-82bb-4944-a8f5-f9635086156b',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: 'A05',
      validDateStart: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,

      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 2 }),
      )!,
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(
        dateUtils.sub(dateUtils.utc(), { hours: 1 }),
      ),
      movementType: 'FORECAST_POSITION',
      change: '' as Change,
      type: 'NORMAL',
      status: 'DRAFT',
      levelInfoMode: 'AT',

      level: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [0.853303944859795, 54.59563077557146],
                  [0.853303944859795, 56.299434402131965],
                  [8.364981676699648, 56.299434402131965],
                  [8.364981676699648, 54.59563077557146],
                  [0.853303944859795, 54.59563077557146],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.500001393444259, 54.595631],
                  [2.8567180183386474, 54.595631],
                  [3.368817, 55.764314],
                  [4.331914, 55.332644],
                  [4.999999, 54.999999],
                  [6.500002, 55.000002],
                  [6.500001393444259, 54.595631],
                ],
              ],
            },
          },
        ],
      },
      endGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [0.19374199767385722, 50.19557093912596],
                  [0.19374199767385722, 52.01288666298136],
                  [9.50089391907543, 52.01288666298136],
                  [9.50089391907543, 50.19557093912596],
                  [0.19374199767385722, 50.19557093912596],
                ],
              ],
            },
          },
        ],
      },
      endGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [2.4193505219008555, 52.012887],
                  [6.694951620112256, 52.012887],
                  [6.405001, 51.830828],
                  [5.94639, 51.811663],
                  [6.222223, 51.361666],
                  [5.934168, 51.036386],
                  [6.011797, 50.757273],
                  [5.651667, 50.824717],
                  [5.848333, 51.139444],
                  [5.078611, 51.391665],
                  [4.397501, 51.452776],
                  [3.952501, 51.214441],
                  [3.373613, 51.309999],
                  [3.36389, 51.313608],
                  [3.362223, 51.320002],
                  [3.370001, 51.369722],
                  [2.000002, 51.500002],
                  [2.4193505219008555, 52.012887],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: dateUtils.getCurrentTimeAsString(),
    canbe: ['CANCELLED'],
    sigmet: {
      uuid: 'someuniqueidprescibedbyPET',
      phenomenon: 'EMBD_TS' as AviationPhenomenaCode,
      sequence: '10',
      issueDate: '2020-09-17T12:00:00Z',
      validDateStart: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      )!,

      validDateEnd: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 2 }),
      )!,

      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: dateUtils.dateToString(
        dateUtils.add(dateUtils.utc(), { hours: 1 }),
      ),
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      levelInfoMode: 'BETW',
      vaSigmetVolcanoName: 'EYJAFJALLAJOKULL',
      vaSigmetVolcanoCoordinates: { latitude: 63.62, longitude: -19.61 },
      level: {
        value: 2000,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
    },
  },
];

export const fakeDataBox: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE15',
    phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
    sequence: 'A06',
    validDateStart: '2020-09-17T13:00:00Z',
    validDateEnd: '2020-09-17T17:00:00Z',
    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    isObservationOrForecast: 'OBS',
    observationOrForecastTime: '2020-09-17T12:00:00Z',
    movementType: 'FORECAST_POSITION',
    change: 'WKN',
    type: 'NORMAL',
    status: 'DRAFT',
    levelInfoMode: 'AT',
    level: {
      value: 1000,
      unit: 'FT' as LevelUnits,
    },
    firGeometry: getFir(sigmetConfig),
    startGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'box',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [8.451700286501511, 52.063884260285],
                [0.4518849127926449, 52.063884260285],
                [0.4518849127926449, 53.280640580981604],
                [8.451700286501511, 53.280640580981604],
                [8.451700286501511, 52.063884260285],
              ],
            ],
          },
        },
      ],
    },
    startGeometryIntersect: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'poly',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [6.77617041058054, 52.063884],
                [2.461047035878412, 52.063884],
                [3.15576, 52.913554],
                [3.0571195833792144, 53.280641],
                [7.189282421218352, 53.280641],
                [7.14218, 52.898244],
                [7.133055, 52.888887],
                [7.065557, 52.385828],
                [7.063612, 52.346109],
                [7.031389, 52.268885],
                [7.053095, 52.237764],
                [6.77617041058054, 52.063884],
              ],
            ],
          },
        },
      ],
    },
    endGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'point',
          },
          geometry: {
            type: 'Point',
            coordinates: [5, 51.5],
          },
        },
      ],
    },
    endGeometryIntersect: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'point',
          },
          geometry: {
            type: 'Point',
            coordinates: [5, 51.5],
          },
        },
      ],
    },
  },
};

export const fakeDataPoly: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE12',
    phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
    sequence: 'A07',
    validDateStart: '2020-09-17T13:00:00Z',
    validDateEnd: '2020-09-17T17:00:00Z',
    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    isObservationOrForecast: 'OBS',
    observationOrForecastTime: '2020-09-17T12:00:00Z',
    movementType: 'FORECAST_POSITION',
    change: 'WKN',
    type: 'NORMAL',
    status: 'DRAFT',
    levelInfoMode: 'AT',
    level: {
      value: 1000,
      unit: 'FT' as LevelUnits,
    },
    firGeometry: getFir(sigmetConfig),
    startGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'poly',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [4.799062887514267, 54.04327723979929],
                [6.006227982070749, 53.64012115183787],
                [4.6168492883359304, 53.32840097044425],
                [4.799062887514267, 54.04327723979929],
              ],
            ],
          },
        },
      ],
    },
    startGeometryIntersect: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'poly',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [4.799062887514267, 54.04327723979929],
                [6.006227982070749, 53.64012115183787],
                [4.6168492883359304, 53.32840097044425],
                [4.799062887514267, 54.04327723979929],
              ],
            ],
          },
        },
      ],
    },
    endGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'poly',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [6.406626021876513, 52.98697212658787],
                [7.1354804185898635, 53.68061155113826],
                [7.955441614892378, 52.82212063293559],
                [6.406626021876513, 52.98697212658787],
              ],
            ],
          },
        },
      ],
    },
    endGeometryIntersect: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            selectionType: 'poly',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [6.406626, 52.986972],
                [6.898724319383813, 53.45529498136169],
                [7.191667, 53.3],
                [7.14344908001551, 52.90854691815449],
                [6.406626, 52.986972],
              ],
            ],
          },
        },
      ],
    },
  },
};

export const fakeNewSigmet: Sigmet = {
  phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
  sequence: 'A08',
  validDateStart: '2020-09-17T13:00:00Z',
  validDateEnd: '2020-09-17T17:00:00Z',
  firName: 'AMSTERDAM FIR',
  locationIndicatorATSU: 'EHAA',
  locationIndicatorATSR: 'EHAA',
  locationIndicatorMWO: 'EHDB',
  isObservationOrForecast: 'OBS',
  observationOrForecastTime: '2020-09-17T12:00:00Z',
  movementType: 'FORECAST_POSITION',
  change: 'WKN',
  type: 'NORMAL',
  status: 'DRAFT',
  levelInfoMode: 'AT',
  level: {
    value: 1000,
    unit: 'FT' as LevelUnits,
  },
  firGeometry: getFir(sigmetConfig),
  startGeometry: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'box',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [1.4736647025096166, 52.00245103360221],
              [1.4736647025096166, 53.28757282590344],
              [9.058305768307893, 53.28757282590344],
              [9.058305768307893, 52.00245103360221],
              [1.4736647025096166, 52.00245103360221],
            ],
          ],
        },
      },
    ],
  },
  startGeometryIntersect: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'poly',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [3.0552573, 53.28757283],
              [7.22786017, 53.28757283],
              [7.3, 53.25],
              [7.10288428, 52.21882119],
              [6.92570299, 52.00245103],
              [2.43067232, 52.00245103],
              [3.16909204, 52.86394071],
              [3.0552573, 53.28757283],
            ],
          ],
        },
      },
    ],
  },
  endGeometry: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'point',
        },
        geometry: {
          type: 'Point',
          coordinates: [5, 51.5],
        },
      },
    ],
  },
  endGeometryIntersect: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          selectionType: 'point',
        },
        geometry: {
          type: 'Point',
          coordinates: [5, 51.5],
        },
      },
    ],
  },
};

export const fakeSigmetMultiPolygon: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  sigmet: {
    status: 'DRAFT',
    phenomenon: 'FRQ_TS' as AviationPhenomenaCode,
    isObservationOrForecast: 'OBS',
    observationOrForecastTime: null!,
    validDateStart: '2021-06-09T09:14:49Z',
    validDateEnd: '2021-06-09T12:14:49Z',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    firName: 'AMSTERDAM FIR',
    firGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [5, 55],
                [4.331914, 55.332644],
                [3.368817, 55.764314],
                [2.761908, 54.379261],
                [3.15576, 52.913554],
                [2.000002, 51.500002],
                [3.370001, 51.369722],
                [3.370527, 51.36867],
                [3.362223, 51.320002],
                [3.36389, 51.313608],
                [3.373613, 51.309999],
                [3.952501, 51.214441],
                [4.397501, 51.452776],
                [5.078611, 51.391665],
                [5.848333, 51.139444],
                [5.651667, 50.824717],
                [6.011797, 50.757273],
                [5.934168, 51.036386],
                [6.222223, 51.361666],
                [5.94639, 51.811663],
                [6.405001, 51.830828],
                [7.053095, 52.237764],
                [7.031389, 52.268885],
                [7.063612, 52.346109],
                [7.065557, 52.385828],
                [7.133055, 52.888887],
                [7.14218, 52.898244],
                [7.191667, 53.3],
                [6.5, 53.666667],
                [6.500002, 55.000002],
                [5, 55],
              ],
            ],
          },
          properties: {
            centlong: 4.98042633,
          },
        },
      ],
    },
    startGeometry: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            stroke: '#f24a00',
            'stroke-width': 1.5,
            'stroke-opacity': 1,
            fill: '#f24a00',
            'fill-opacity': 0.25,
            selectionType: 'poly',
          },
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [2.763574121746228, 55.7517694491503],
                [7.577660215570578, 54.441809771698],
                [4.554179315180896, 56.77870860348328],
                [2.763574121746228, 55.7517694491503],
              ],
            ],
          },
        },
      ],
    },
    startGeometryIntersect: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            stroke: '#f24a00',
            'stroke-width': 1.5,
            'stroke-opacity': 1,
            fill: '#f24a00',
            'fill-opacity': 0.5,
          },
          geometry: {
            type: 'MultiPolygon',
            coordinates: [
              [
                [
                  [4.365729269907419, 55.315807175634454],
                  [3.299427839634416, 55.605958027800156],
                  [3.368817, 55.764314],
                  [4.331914, 55.332644],
                  [4.365729269907419, 55.315807175634454],
                ],
              ],
              [
                [
                  [6.500001602574285, 54.735051191917506],
                  [5.526314849017847, 55.0000007017522],
                  [6.500002, 55.000002],
                  [6.500001602574285, 54.735051191917506],
                ],
              ],
            ],
          },
        },
      ],
    },
    level: { unit: 'FL' as LevelUnits, value: 50 },
    levelInfoMode: 'AT',
    movementType: 'STATIONARY',
    change: 'WKN',
    sequence: '-1',
    type: 'NORMAL',
  },
};

export const fakeVolcanicCancelSigmetNoMoveTo: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: [],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE4',
    validDateStart: '2020-09-17T14:30:00Z',
    validDateEnd: '2020-09-17T17:00:00Z',
    sequence: '4',
    issueDate: '2020-09-17T14:30:00Z',
    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    status: 'PUBLISHED',
    cancelsSigmetSequenceId: '113',
    validDateStartOfSigmetToCancel: '2020-09-17T13:00:00Z',
    validDateEndOfSigmetToCancel: '2020-09-17T17:00:00Z',
  },
};

export const fakeVolcanicCancelSigmetWithMoveTo: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: [],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE4',
    validDateStart: '2020-09-17T14:30:00Z',
    validDateEnd: '2020-09-17T17:00:00Z',
    sequence: '4',
    issueDate: '2020-09-17T14:30:00Z',
    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    status: 'PUBLISHED',
    cancelsSigmetSequenceId: '113',
    validDateStartOfSigmetToCancel: '2020-09-17T13:00:00Z',
    validDateEndOfSigmetToCancel: '2020-09-17T17:00:00Z',
    vaSigmetMoveToFIR: 'EKDK',
  },
};

export const fakeShortTestSigmet: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE',
    phenomenon: 'EMBD_TS' as AviationPhenomenaCode,
    sequence: 'Z01',
    validDateStart: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 1 }),
    )!,

    validDateEnd: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 3 }),
    )!,

    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    type: 'SHORT_TEST',
    status: 'DRAFT',
    firGeometry: getFir(sigmetConfig),
  },
};

export const fakeShortVaTestSigmet: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE',
    phenomenon: 'VA_CLD' as AviationPhenomenaCode,
    sequence: 'Y01',
    validDateStart: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 1 }),
    )!,

    validDateEnd: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 3 }),
    )!,

    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    type: 'SHORT_VA_TEST',
    status: 'DRAFT',
    firGeometry: getFir(sigmetConfig),
  },
};

export const fakeTestSigmetWithThunderStorm: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE',
    phenomenon: 'EMBD_TS' as AviationPhenomenaCode,
    sequence: 'Y01',
    validDateStart: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 1 }),
    )!,

    validDateEnd: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 3 }),
    )!,

    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    type: 'TEST',
    status: 'DRAFT',
    firGeometry: getFir(sigmetConfig),
  },
};

export const fakeTestSigmetWithVolcanicAshCloud: SigmetFromBackend = {
  creationDate: '2020-09-17T12:00:00Z',
  canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
  sigmet: {
    uuid: 'someuniqueidprescibedbyBE',
    phenomenon: 'VA_CLD' as AviationPhenomenaCode,
    sequence: 'Y01',
    validDateStart: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 1 }),
    )!,

    validDateEnd: dateUtils.dateToString(
      dateUtils.add(dateUtils.utc(), { hours: 3 }),
    )!,

    firName: 'AMSTERDAM FIR',
    locationIndicatorATSU: 'EHAA',
    locationIndicatorATSR: 'EHAA',
    locationIndicatorMWO: 'EHDB',
    type: 'TEST',
    status: 'DRAFT',
    firGeometry: getFir(sigmetConfig),
  },
};

export const fakeStaticSigmetList: SigmetFromBackend[] = [
  {
    creationDate: '2020-09-17T12:00:00Z',
    canbe: ['DRAFTED', 'DISCARDED', 'PUBLISHED'],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: 'A01',
      validDateStart: '2020-09-17T12:13:00Z',
      validDateEnd: '2020-09-17T14:13:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-17T11:47:00Z',
      movementType: 'FORECAST_POSITION',
      change: 'WKN',
      type: 'NORMAL',
      status: 'DRAFT',
      levelInfoMode: 'AT',

      level: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [5.0, 55.0],
                  [4.331914, 55.332644],
                  [3.368817, 55.764314],
                  [2.761908, 54.379261],
                  [3.15576, 52.913554],
                  [2.000002, 51.500002],
                  [3.370001, 51.369722],
                  [3.370527, 51.36867],
                  [3.362223, 51.320002],
                  [3.36389, 51.313608],
                  [3.373613, 51.309999],
                  [3.952501, 51.214441],
                  [4.397501, 51.452776],
                  [5.078611, 51.391665],
                  [5.848333, 51.139444],
                  [5.651667, 50.824717],
                  [6.011797, 50.757273],
                  [5.934168, 51.036386],
                  [6.222223, 51.361666],
                  [5.94639, 51.811663],
                  [6.405001, 51.830828],
                  [7.053095, 52.237764],
                  [7.031389, 52.268885],
                  [7.063612, 52.346109],
                  [7.065557, 52.385828],
                  [7.133055, 52.888887],
                  [7.14218, 52.898244],
                  [7.191667, 53.3],
                  [6.5, 53.666667],
                  [6.500002, 55.000002],
                  [5.0, 55.0],
                ],
              ],
            },
            properties: {
              selectionType: 'fir',
            },
          },
        ],
      },
      endGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626021876513, 52.98697212658787],
                  [7.1354804185898635, 53.68061155113826],
                  [7.955441614892378, 52.82212063293559],
                  [6.406626021876513, 52.98697212658787],
                ],
              ],
            },
          },
        ],
      },
      endGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626, 52.986972],
                  [6.898724319383813, 53.45529498136169],
                  [7.191667, 53.3],
                  [7.14344908001551, 52.90854691815449],
                  [6.406626, 52.986972],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-16T12:00:00Z',
    canbe: ['CANCELLED'],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE2',
      phenomenon: 'VA_CLD' as AviationPhenomenaCode,
      sequence: 'A01V',
      issueDate: '2020-09-16T12:00:00Z',
      validDateStart: '2020-09-16T13:00:00Z',
      validDateEnd: '2020-09-16T16:14:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-16T11:45:00Z',
      movementType: 'STATIONARY',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      levelInfoMode: 'BETW',
      vaSigmetVolcanoName: 'EYJAFJALLAJOKULL',
      vaSigmetVolcanoCoordinates: { latitude: 63.62, longitude: -19.61 },
      level: {
        value: 2000,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-16T09:00:00Z',
    canbe: ['CANCELLED'],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE3',
      phenomenon: 'VA_CLD' as AviationPhenomenaCode,
      sequence: 'A02V',
      issueDate: '2020-09-16T09:05:00Z',
      validDateStart: '2020-09-16T09:22:00Z',
      validDateEnd: '2020-09-16T10:26:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-16T08:39:00Z',
      movementType: 'NO_VA_EXP',
      change: 'WKN',
      type: 'NORMAL',
      status: 'PUBLISHED',
      levelInfoMode: 'BETW',
      vaSigmetVolcanoName: 'EYJAFJALLAJOKULL',
      vaSigmetVolcanoCoordinates: { latitude: 63.62, longitude: -19.61 },
      level: {
        value: 1500,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [3.7513346922388284, 52.93209131750574],
                  [4.890169687103436, 52.26807619123409],
                  [3.250247294498403, 51.960365357854286],
                  [3.7513346922388284, 52.93209131750574],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    canbe: [],
    creationDate: '2020-09-15T14:30:00Z',
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE4',
      validDateStart: '2020-09-15T14:30:00Z',
      validDateEnd: '2020-09-15T17:00:00Z',
      sequence: '4',
      issueDate: '2020-09-15T14:30:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      status: 'PUBLISHED',
      cancelsSigmetSequenceId: '113',
      validDateStartOfSigmetToCancel: '2020-09-15T13:00:00Z',
      validDateEndOfSigmetToCancel: '2020-09-15T17:00:00Z',
      type: 'NORMAL',
    },
  },
  {
    creationDate: '2020-09-15T10:30:00Z',
    canbe: [],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE7',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: 'A09',
      issueDate: '2020-09-15T10:30:00Z',
      validDateStart: '2020-09-15T10:35:00Z',
      validDateEnd: '2020-09-15T14:30:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-15T10:12:00Z',
      movementType: 'FORECAST_POSITION',
      change: 'WKN',
      type: 'NORMAL',
      status: 'EXPIRED',
      levelInfoMode: 'AT',
      level: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      lowerLevel: {
        value: 1000,
        unit: 'FT' as LevelUnits,
      },
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
      endGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
      endGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [4.799062887514267, 54.04327723979929],
                  [6.006227982070749, 53.64012115183787],
                  [4.6168492883359304, 53.32840097044425],
                  [4.799062887514267, 54.04327723979929],
                ],
              ],
            },
          },
        ],
      },
    },
  },
  {
    creationDate: '2020-09-13T22:30:00Z',
    canbe: [],
    sigmet: {
      uuid: 'someuniqueidprescibedbyBE12',
      phenomenon: 'OBSC_TS' as AviationPhenomenaCode,
      sequence: '113',
      issueDate: '2020-09-13T22:30:00Z',
      validDateStart: '2020-09-13T22:45:00Z',
      validDateEnd: '2020-09-13T23:55:00Z',
      firName: 'AMSTERDAM FIR',
      locationIndicatorATSU: 'EHAA',
      locationIndicatorATSR: 'EHAA',
      locationIndicatorMWO: 'EHDB',
      isObservationOrForecast: 'OBS',
      observationOrForecastTime: '2020-09-13T22:22:00Z',
      levelInfoMode: 'AT',
      level: {
        value: 300,
        unit: 'FL' as LevelUnits,
      },
      movementType: 'FORECAST_POSITION',
      change: 'WKN',
      type: 'NORMAL',
      status: 'CANCELLED',
      firGeometry: getFir(sigmetConfig),
      startGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'box',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [8.451700286501511, 52.063884260285],
                  [0.4518849127926449, 52.063884260285],
                  [0.4518849127926449, 53.280640580981604],
                  [8.451700286501511, 53.280640580981604],
                  [8.451700286501511, 52.063884260285],
                ],
              ],
            },
          },
        ],
      },
      startGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.77617041058054, 52.063884],
                  [2.461047035878412, 52.063884],
                  [3.15576, 52.913554],
                  [3.0571195833792144, 53.280641],
                  [7.189282421218352, 53.280641],
                  [7.14218, 52.898244],
                  [7.133055, 52.888887],
                  [7.065557, 52.385828],
                  [7.063612, 52.346109],
                  [7.031389, 52.268885],
                  [7.053095, 52.237764],
                  [6.77617041058054, 52.063884],
                ],
              ],
            },
          },
        ],
      },
      endGeometry: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626021876513, 52.98697212658787],
                  [7.1354804185898635, 53.68061155113826],
                  [7.955441614892378, 52.82212063293559],
                  [6.406626021876513, 52.98697212658787],
                ],
              ],
            },
          },
        ],
      },
      endGeometryIntersect: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              selectionType: 'poly',
            },
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [6.406626, 52.986972],
                  [6.898724319383813, 53.45529498136169],
                  [7.191667, 53.3],
                  [7.14344908001551, 52.90854691815449],
                  [6.406626, 52.986972],
                ],
              ],
            },
          },
        ],
      },
    },
  },
];

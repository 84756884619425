/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Grid2 as Grid, Typography, Button } from '@mui/material';

import { useApiContext, useApi } from '@opengeoweb/api';
import NotificationList from './NotificationList';
import LifeCycleDialog from '../LifeCycleDialog/LifeCycleDialog';
import { EventCategoryParams, SWEvent } from '../../types';
import { useNotificationTriggerContext } from '../NotificationTrigger';
import { SpaceWeatherApi } from '../../utils/api';
import { useSpaceweatherTranslation } from '../../utils/i18n';

/**
 * Notifications
 * View incoming notifications and issue new notifications following the notification lifecycle
 *
 * @example
 * ``` <Notifications /> ```
 */
const Notifications: React.FC = () => {
  const { t } = useSpaceweatherTranslation();
  const { api } = useApiContext<SpaceWeatherApi>();

  const {
    notificationTriggers: newNotifications,
    onFetchNewNotificationTriggerData,
  } = useNotificationTriggerContext();

  const [dialogEvent, setDialogEvent] = React.useState<SWEvent | null>(null);
  const [lifeCycleDialogMode, setLifeCycleDialogMode] = React.useState('new');
  const [lifeCycleDialogOpen, setLifeCycleDialogOpen] = React.useState(false);

  const [tabValue, setTabValue] = React.useState('ALL');

  // Retrieve eventList
  const params: EventCategoryParams =
    tabValue === 'KNMI' ? { originator: tabValue } : { category: tabValue };

  const {
    error: errorList,
    isLoading: isLoadingList,
    result: eventList,
    clearResults,
    fetchApiData: fetchNewEventList,
  } = useApi(api.getEventList, tabValue === 'ALL' ? {} : params);

  const getNewEventList = (): void => {
    const newparams =
      tabValue === 'KNMI' ? { originator: tabValue } : { category: tabValue };
    void fetchNewEventList!(tabValue === 'ALL' ? {} : newparams);
  };

  React.useEffect(() => {
    if (clearResults) {
      clearResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  React.useEffect(() => {
    getNewEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotifications]);

  const toggleDialogOpen = (): void => {
    // Refresh list on closing dialog
    if (lifeCycleDialogOpen === true) {
      getNewEventList();
    }
    setLifeCycleDialogOpen(!lifeCycleDialogOpen);
  };

  React.useEffect(() => {
    if (lifeCycleDialogMode !== 'new') {
      toggleDialogOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeCycleDialogMode]);

  const toggleCreateNotification = (): void => {
    setLifeCycleDialogMode('new');
    toggleDialogOpen();
  };

  const handleNotificatioNRowClick = (event: SWEvent): void => {
    setDialogEvent(event);
    if (lifeCycleDialogMode !== event.eventid) {
      setLifeCycleDialogMode(event.eventid);
      if (event.notacknowledged === true) {
        api
          .setAcknowledged(event.eventid)
          .then(() => {
            onFetchNewNotificationTriggerData!();
          })
          // eslint-disable-next-line no-console
          .catch((e) => console.warn(e));
      }
    } else {
      toggleDialogOpen();
    }
  };

  return (
    <Grid container spacing={1} style={{ height: '100%' }}>
      <Grid size={12}>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: 'center', fontWeight: 500 }}
        >
          {t('notification-title')}
        </Typography>
      </Grid>
      <Grid size={12}>
        <NotificationList
          tabValue={tabValue}
          handleNotificatioNRowClick={handleNotificatioNRowClick}
          onChangeTabValue={setTabValue}
          newNotifications={newNotifications!}
          eventList={eventList || null}
          isLoading={isLoadingList}
          error={errorList}
        />
      </Grid>
      <Grid size={12}>
        <Grid container justifyContent="flex-end">
          <Grid sx={{ marginTop: '8px', marginRight: 1 }}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={toggleCreateNotification}
              data-testid="notifications-newnotification"
            >
              {t('notification-issue-button')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {lifeCycleDialogOpen && (
        <LifeCycleDialog
          open={lifeCycleDialogOpen}
          toggleStatus={toggleDialogOpen}
          dialogMode={lifeCycleDialogMode}
          {...(lifeCycleDialogMode !== 'new' &&
            dialogEvent !== null && { event: dialogEvent })}
        />
      )}
    </Grid>
  );
};

export default Notifications;

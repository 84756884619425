/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';

import { FormControl, FormHelperText, FormControlProps } from '@mui/material';
import { FieldErrors } from 'react-hook-form';
import { TFunction } from 'i18next';
import { errorMessages } from './utils';
import { useFormFieldsTranslation } from '../utils/i18n';

interface ReactHookFormFormControlProps extends FormControlProps {
  children?: React.ReactNode;
  errors?: FieldErrors;
  isReadOnly?: boolean;
}

const radioCheckboxStyle = {
  // hides unchecked values
  display: 'none',
  '&+.MuiFormControlLabel-label': {
    display: 'none',
  },
  // shows checked value
  '&.Mui-checked': {
    display: 'inherit',
    '&+.MuiFormControlLabel-label': {
      display: 'inherit',
    },
  },
  '&.Mui-checked+.MuiFormControlLabel-label': {
    color: 'geowebColors.typographyAndIcons.text',
  },
  '&.Mui-disabled.Mui-checked': {
    color: 'geowebColors.typographyAndIcons.iconLinkActive',
  },
};

export const getErrorMessage = (t: TFunction, errors: FieldErrors): string => {
  if (errors && errors.message && typeof errors.message === 'string') {
    return t(errors.message);
  }

  if (errors && errors.type && typeof errors.type === 'string') {
    return t(errorMessages[errors.type]);
  }

  return '';
};

const ReactHookFormFormControl: React.FC<ReactHookFormFormControlProps> = ({
  children,
  errors,
  isReadOnly,
  sx = {},
  className = '',
  size = 'medium',
  ...props
}: ReactHookFormFormControlProps) => {
  const { t } = useFormFieldsTranslation();
  return (
    <FormControl
      fullWidth
      error={!!errors}
      className={`${isReadOnly ? 'is-read-only' : ''} ${className}`}
      size={size}
      sx={{
        ...(isReadOnly && {
          // Radio input
          '.MuiRadio-root': radioCheckboxStyle,
          // Checkbox input
          '.MuiCheckbox-root': radioCheckboxStyle,
          // Select input
          '.MuiSelect-select.Mui-disabled': {
            color: 'geowebColors.typographyAndIcons.text',
            WebkitTextFillColor: 'inherit',
            '&:before': {
              borderColor: 'transparent',
            },
          },
          '.MuiSelect-nativeInput+.MuiSvgIcon-root': {
            display: 'none',
          },
          '.Mui-disabled:before': {
            border: 'transparent',
          },
        }),
        ...sx,
      }}
      {...props}
    >
      {children}
      {errors && (
        <FormHelperText variant="filled" role="alert">
          {getErrorMessage(t, errors)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default ReactHookFormFormControl;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { ToolContainerDraggable } from '@opengeoweb/shared';
import * as React from 'react';
import { Box } from '@mui/material';
import { uiTypes } from '@opengeoweb/store';
import { KeywordFilterButtonConnect } from '../KeywordFilterButton/KeywordFilterButtonConnect';
import { SearchFieldConnect } from '../SearchField/SearchFieldConnect';
import { LayerListConnect } from '../LayerList/LayerListConnect';
import { ServiceListConnect } from '../ServiceList/ServiceListConnect';
import { ServiceOptionsButton } from '../ServiceOptionsButton/ServiceOptionsButton';

interface Size {
  width: number;
  height: number;
}
interface LayerSelectProps {
  mapId: string;
  bounds?: string;
  showTitle?: boolean;
  onClose?: () => void;
  onMouseDown?: (event: MouseEvent) => void;
  isOpen: boolean;
  order?: number;
  source?: uiTypes.Source;
  startSize?: Size;
  isMultiMap?: boolean;
}

export const LayerSelect: React.FC<LayerSelectProps> = ({
  mapId,
  bounds,
  onClose = (): void => {},
  showTitle,
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'module',
  startSize = { width: 750, height: 500 },
  isMultiMap = false,
}: LayerSelectProps) => {
  const initialServiceListHeight = 37;
  const [height, setHeight] = React.useState(startSize.height);
  const [width, setWidth] = React.useState(startSize.width);
  const [serviceListHeight, setServiceListHeight] = React.useState(
    initialServiceListHeight,
  );
  const onChangeSize = ({ height, width }: Size): void => {
    setHeight(height);
    setWidth(width);
  };

  const handleHeightChange = (height: number): void => {
    if (height > initialServiceListHeight) {
      setServiceListHeight(height);
    }
  };
  return (
    <ToolContainerDraggable
      title={showTitle ? `Layer Select ${mapId}` : 'Layer Select'}
      startSize={{ width, height }}
      minWidth={320}
      minHeight={126}
      startPosition={{ top: 150, left: 100 }}
      isOpen={isOpen}
      onResizeStop={(_event, _direction, node): void => {
        const { offsetWidth: width, offsetHeight: height } = node;
        onChangeSize({ width, height });
      }}
      onClose={onClose}
      bounds={bounds}
      data-testid="layerSelectWindow"
      onMouseDown={(event: MouseEvent): void => {
        const target = event.target as HTMLButtonElement;
        const isButtonInScrollableList =
          target.closest('.layerInfoButton') ||
          target.closest('.layerAddRemoveButton');

        //  don't propagate when clicking on layerInfoButton or addRemoveButton
        if (isButtonInScrollableList) {
          event.stopPropagation();

          return;
        }
        onMouseDown(event);
      }}
      order={order}
      source={source}
    >
      <Box sx={{ padding: '4px 16px 0 16px' }}>
        <SearchFieldConnect />
      </Box>
      <Box
        sx={{
          float: 'left',
          position: 'absolute',
          margin: '8px 8px 0 16px',
          zIndex: 2,
        }}
      >
        <KeywordFilterButtonConnect mapId={mapId} isMultiMap={isMultiMap} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          margin: '8px 0 0 calc(100% - 40px)',
          zIndex: 2,
        }}
      >
        <ServiceOptionsButton mapId={mapId} />
      </Box>
      <Box>
        <ServiceListConnect setHeight={handleHeightChange} />
      </Box>
      <Box
        sx={{
          padding: '0 16px',
        }}
      >
        <LayerListConnect
          mapId={mapId}
          layerSelectHeight={height}
          serviceListHeight={serviceListHeight}
          layerSelectWidth={width}
        />
      </Box>
    </ToolContainerDraggable>
  );
};

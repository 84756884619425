/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2020 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2020 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateUtils } from '@opengeoweb/shared';

import {
  mapSelectors,
  mapActions,
  uiSelectors,
  layerSelectors,
  genericActions,
  mapEnums,
  CoreAppStore,
  syncGroupsSelectors,
} from '@opengeoweb/store';
import { handleDateUtilsISOString } from '@opengeoweb/webmap';
import {
  TimeBounds,
  secondsPerPxFromCanvasWidth,
  getNewCenterOfFixedPointZoom,
  TimeSlider,
  defaultTimeSpan,
  onsetNewDateDebounced,
  getFilteredTime,
  moveSelectedTimePx,
  defaultSecondsPerPx,
} from '@opengeoweb/timeslider';

import {
  calculateNewAnimationTimeBounds,
  getTimeBounds,
} from './timesliderUtils';
import { TimeSliderButtonsConnect } from './TimeSliderButtonsConnect';
import { TimeSliderCurrentTimeBoxConnect } from './TimeSliderCurrentTimeBoxConnect';
import { TimeSliderLegendConnect } from './TimeSliderLegendConnect';

export const useUpdateTimestep = (mapId: string): void => {
  const autoTimeStepLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getAutoTimeStepLayerId(store, mapId),
  );
  const isTimeStepAuto = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimestepAuto(store, mapId),
  );
  const timeStep = useSelector((store: CoreAppStore) =>
    layerSelectors.getTimeStepForLayerId(store, autoTimeStepLayerId),
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isTimeStepAuto) {
      if (timeStep !== undefined) {
        dispatch(mapActions.setTimeStep({ mapId, timeStep }));
      }
    }
  }, [autoTimeStepLayerId, dispatch, isTimeStepAuto, mapId, timeStep]);
};

export const useUpdateTimeSpan = (
  mapId: string,
  myOnSetTimeSliderSpan: (
    newSpan: number,
    newCenterTime: number,
    newSecondsPerPx: number,
  ) => void,
): void => {
  const autoTimeStepLayerId = useSelector((store: CoreAppStore) =>
    mapSelectors.getAutoTimeStepLayerId(store, mapId),
  );
  const isTimeSpanAuto = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimeSpanAuto(store, mapId),
  );
  const activeLayerTimeDimension = useSelector((store: CoreAppStore) =>
    layerSelectors.getLayerTimeDimension(store, autoTimeStepLayerId),
  );
  const timeSliderWidth = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderWidth(store, mapId),
  );
  const centerTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderCenterTime(store, mapId),
  );
  const secondsPerPx = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderSecondsPerPx(store, mapId),
  );
  const currentTimeSpan = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderSpan(store, mapId),
  );

  const selectedTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getSelectedTime(store, mapId),
  );
  const updateTimeSliderSpan = (
    spanInSeconds: number,
    newCenterTime: number,
    newSecondsPerPx: number,
  ): void => {
    if (
      spanInSeconds !== undefined &&
      newCenterTime !== undefined &&
      newSecondsPerPx !== undefined
    ) {
      // Do not dispatch actions if values are the same
      if (
        currentTimeSpan !== spanInSeconds ||
        secondsPerPx !== newSecondsPerPx ||
        centerTime !== newCenterTime
      ) {
        myOnSetTimeSliderSpan(spanInSeconds, newCenterTime, newSecondsPerPx);
      }
    }
  };

  React.useEffect(() => {
    if (isTimeSpanAuto && autoTimeStepLayerId && activeLayerTimeDimension) {
      const { startTime, endTime }: TimeBounds = getTimeBounds([
        activeLayerTimeDimension!,
      ]);
      const spanInSeconds = endTime! - startTime!;

      const newSecondsPerPx = secondsPerPxFromCanvasWidth(
        timeSliderWidth!,
        spanInSeconds,
      );

      const newCenterTime = (startTime! + endTime!) / 2;

      updateTimeSliderSpan(spanInSeconds, newCenterTime, newSecondsPerPx!);
    } else {
      const spanInSeconds = currentTimeSpan || defaultTimeSpan;
      const newSecondsPerPx = secondsPerPxFromCanvasWidth(
        timeSliderWidth!,
        spanInSeconds,
      );

      const newCenterTime = getNewCenterOfFixedPointZoom(
        selectedTime,
        secondsPerPx!,
        newSecondsPerPx!,
        centerTime!,
      );

      updateTimeSliderSpan(spanInSeconds, newCenterTime, newSecondsPerPx!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoTimeStepLayerId, isTimeSpanAuto, mapId]);
};

const timeToIso = (selectedTime: number): string =>
  handleDateUtilsISOString(dateUtils.fromUnix(selectedTime).toISOString());

interface TimeSliderConnectProps {
  sourceId: string;
  mapId: string;
  isAlwaysVisible?: boolean;
  mapWindowRef?: React.MutableRefObject<HTMLElement | null>;
}

export const TimeSliderConnect: React.FC<TimeSliderConnectProps> = ({
  sourceId,
  mapId,
  isAlwaysVisible = false,
  mapWindowRef,
}: TimeSliderConnectProps) => {
  const isTimeSliderHoverOn = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimeSliderHoverOn(store, mapId),
  );

  const mapIsActive = useSelector((store: CoreAppStore) =>
    uiSelectors.getIsActiveWindowId(store, mapId),
  );
  const isTimeSliderVisible = useSelector((store: CoreAppStore) =>
    mapSelectors.isTimeSliderVisible(store, mapId),
  );

  // don't use redux state if isAlwaysVisible
  const isVisible = isAlwaysVisible || isTimeSliderVisible;

  const dispatch = useDispatch();

  // TODO: move keyboard logic to TimeSlider.tsx
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.ctrlKey && event.altKey && event.code === 'KeyH') {
        dispatch(
          mapActions.toggleTimeSliderHover({
            mapId,
            isTimeSliderHoverOn: !isTimeSliderHoverOn,
          }),
        );
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return (): void => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mapId, isTimeSliderHoverOn, dispatch]);

  const onToggleTimeSliderVisibility = React.useCallback(
    (isTimeSliderVisible: boolean): void => {
      dispatch(
        mapActions.toggleTimeSliderIsVisible({
          mapId,
          isTimeSliderVisible,
          origin: mapEnums.MapActionOrigin.map,
        }),
      );
    },
    [dispatch, mapId],
  );

  const onSetTimeSliderSpan = React.useCallback(
    (newSpan: number, newCenterTime: number, newSecondsPerPx: number): void => {
      dispatch(
        mapActions.setTimeSliderSecondsPerPx({
          mapId,
          timeSliderSecondsPerPx: newSecondsPerPx,
        }),
      );
      dispatch(
        mapActions.setTimeSliderSpan({
          mapId,
          timeSliderSpan: newSpan!,
        }),
      );
      dispatch(
        mapActions.setTimeSliderCenterTime({
          mapId,
          timeSliderCenterTime: newCenterTime,
        }),
      );
    },
    [dispatch, mapId],
  );

  const timeStep = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeStep(store, mapId),
  );

  const [dataStartTime, dataEndTime] = useSelector((store: CoreAppStore) =>
    mapSelectors.getDataLimitsFromLayers(store, mapId),
  );
  const isAnimating = useSelector((store: CoreAppStore) =>
    mapSelectors.isAnimating(store, mapId),
  );

  const selectedTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getSelectedTime(store, mapId),
  );
  const animationStartTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getAnimationStartTime(store, mapId),
  );
  const animationEndTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getAnimationEndTime(store, mapId),
  );

  useUpdateTimestep(mapId);
  useUpdateTimeSpan(mapId, onSetTimeSliderSpan);

  // Local state for unfiltered selected time
  const [unfilteredSelectedTime, setUnfilteredSelectedTimeViaState] =
    React.useState<number>(selectedTime);

  // Reference to the last time value set by this timeslider
  const lastTimeValueAsSetByThisTimeSlider = React.useRef<string>();

  // Set new time in the redux state
  const onSetNewDate = React.useCallback(
    (newDate: string): void => {
      // Remember that this timeslider has set this time
      lastTimeValueAsSetByThisTimeSlider.current = newDate;
      if (isAnimating) {
        dispatch(mapActions.mapStopAnimation({ mapId }));
      }

      dispatch(
        genericActions.setTime({
          sourceId,
          origin: 'TimeSliderConnect',
          value: handleDateUtilsISOString(newDate),
        }),
      );
    },
    [dispatch, isAnimating, mapId, sourceId],
  );

  // Update local state when time is updated outside of this component, e.g. during animation or syncgroups
  React.useEffect(() => {
    const selectedTimeAsIsoString = timeToIso(selectedTime);
    if (
      selectedTimeAsIsoString !== lastTimeValueAsSetByThisTimeSlider.current
    ) {
      setUnfilteredSelectedTimeViaState(selectedTime);
    }
  }, [selectedTime]);

  // Function used by timeslider components to update the unfiltered selected time
  const setUnfilteredSelectedTime = React.useCallback(
    (unfilteredSelectedTimeFromComponents: number): void => {
      const makeFilteredSelectedTime = (timeValue: number): number => {
        return getFilteredTime(timeValue, timeStep, dataStartTime, dataEndTime);
      };

      setUnfilteredSelectedTimeViaState(unfilteredSelectedTimeFromComponents);
      const filteredIsoTime = makeFilteredSelectedTime(
        unfilteredSelectedTimeFromComponents,
      );
      if (selectedTime !== filteredIsoTime) {
        onsetNewDateDebounced(timeToIso(filteredIsoTime), onSetNewDate);
      }
    },
    [dataEndTime, dataStartTime, onSetNewDate, selectedTime, timeStep],
  );

  // Move animation bar when user changes time using time picker
  // Animation bar however cannot be moved into a time where there is no data
  const moveAnimationUsingCalendar = (newTime: number): void => {
    if (
      !animationEndTime ||
      !animationStartTime ||
      !dataStartTime ||
      !dataEndTime
    ) {
      return;
    }

    const formatToIsoString = (date: number): string =>
      handleDateUtilsISOString(new Date(date).toISOString());

    const { newAnimationStartTime, newAnimationEndTime } =
      calculateNewAnimationTimeBounds(
        newTime,
        animationStartTime,
        animationEndTime,
        dataStartTime,
        dataEndTime,
      );

    dispatch(
      mapActions.setAnimationStartTime({
        mapId,
        animationStartTime: formatToIsoString(newAnimationStartTime),
      }),
    );
    dispatch(
      mapActions.setAnimationEndTime({
        mapId,
        animationEndTime: formatToIsoString(newAnimationEndTime),
      }),
    );
  };

  const onCalendarSelect = (newSelectedTime: number): void => {
    setUnfilteredSelectedTime(newSelectedTime);
    moveAnimationUsingCalendar(newSelectedTime);
  };

  const secondsPerPx =
    useSelector((store: CoreAppStore) =>
      mapSelectors.getMapTimeSliderSecondsPerPx(store, mapId),
    ) || defaultSecondsPerPx;
  const timeSliderWidth =
    useSelector((store: CoreAppStore) =>
      mapSelectors.getMapTimeSliderWidth(store, mapId),
    ) || 0;
  const centerTime = useSelector((store: CoreAppStore) =>
    mapSelectors.getMapTimeSliderCenterTime(store, mapId),
  );
  const isTimeScrollingEnabled = useSelector(
    syncGroupsSelectors.isTimeScrollingEnabled,
  );

  const adjustSelectedTimeOnWheel = React.useCallback(
    ({
      event,
      deltaY,
    }: {
      event: WheelEvent | KeyboardEvent;
      deltaY: number;
    }): void => {
      if (!(event.ctrlKey || event.metaKey || event.shiftKey || event.altKey)) {
        const pixelsPerScroll = (-deltaY * timeStep!) / (2 * secondsPerPx);
        moveSelectedTimePx(
          pixelsPerScroll,
          timeSliderWidth,
          centerTime!,
          dataStartTime!,
          dataEndTime!,
          secondsPerPx,
          timeStep!,
          unfilteredSelectedTime!,
          setUnfilteredSelectedTime,
        );
      }
    },
    [
      timeSliderWidth,
      centerTime,
      dataEndTime,
      dataStartTime,
      secondsPerPx,
      setUnfilteredSelectedTime,
      timeStep,
      unfilteredSelectedTime,
    ],
  );

  React.useEffect(() => {
    if (isTimeScrollingEnabled && mapWindowRef?.current) {
      const handleWheel = (event: WheelEvent): void => {
        adjustSelectedTimeOnWheel({ event, deltaY: event.deltaY });
      };
      const element = mapWindowRef.current;
      element.addEventListener('wheel', handleWheel);
      return (): void => {
        element.removeEventListener('wheel', handleWheel);
      };
    }
    return undefined;
  }, [isTimeScrollingEnabled, mapWindowRef, adjustSelectedTimeOnWheel]);

  return (
    <TimeSlider
      timeStep={timeStep}
      dataStartTime={dataStartTime}
      dataEndTime={dataEndTime}
      onSetNewDate={(newDate: string): void => {
        onsetNewDateDebounced(newDate, onSetNewDate);
      }}
      onSetCenterTime={(newTime: number): void => {
        dispatch(
          mapActions.setTimeSliderCenterTime({
            mapId,
            timeSliderCenterTime: newTime,
          }),
        );
      }}
      selectedTime={selectedTime}
      buttons={<TimeSliderButtonsConnect mapId={mapId} sourceId={sourceId} />}
      timeBox={
        <TimeSliderCurrentTimeBoxConnect
          mapId={mapId}
          unfilteredSelectedTime={unfilteredSelectedTime}
          setUnfilteredSelectedTime={setUnfilteredSelectedTime}
          onCalendarSelect={onCalendarSelect}
        />
      }
      legend={
        <TimeSliderLegendConnect
          mapId={mapId}
          onSetTimeSliderSpan={onSetTimeSliderSpan}
          unfilteredSelectedTime={unfilteredSelectedTime}
          setUnfilteredSelectedTime={setUnfilteredSelectedTime}
          mapWindowRef={mapWindowRef}
          adjustSelectedTimeOnWheel={adjustSelectedTimeOnWheel}
        />
      }
      mapIsActive={mapIsActive}
      onToggleTimeSlider={onToggleTimeSliderVisibility}
      isVisible={isVisible}
    />
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { Grid2 as Grid, Paper, Popper } from '@mui/material';
import React, { FC } from 'react';
import { OptionsMenu } from '../TimeSliderButtons/OptionsMenuButton/OptionsMenu';
import { ControlButtons } from '../TimeSliderButtons/ControlButtons/ControlButtons';
import type { PopperPlacement } from './TimeSliderClock';

export const PopperMenu: FC<{
  popperPlacement: PopperPlacement;
  isPopperOpen: boolean;
  buttonElement: HTMLDivElement | null;
  options: React.ReactElement | undefined;
  controls: React.ReactElement | undefined;
}> = ({ controls, isPopperOpen, options, buttonElement, popperPlacement }) => {
  if (!buttonElement) {
    return null;
  }
  const direction = ['bottom', 'top'].includes(popperPlacement)
    ? 'column'
    : 'row';
  const margin = getMargin(buttonElement, popperPlacement);

  return (
    <Popper
      sx={{ zIndex: 3 }}
      placement={popperPlacement}
      open={isPopperOpen}
      anchorEl={
        direction === 'row' ? buttonElement?.parentElement : buttonElement
      }
    >
      <Paper sx={{ ...margin }} elevation={3}>
        <Grid
          sx={{
            padding: '4px 8px 8px',
            ...(direction === 'column' && {
              '& button': { width: '100%!important' },
            }),

            ...(direction === 'row' && {
              marginTop: -0.5,
            }),
          }}
          container
          spacing={0.5}
          direction={direction}
        >
          {options ?? <OptionsMenu />}
          {controls ?? <ControlButtons />}
        </Grid>
      </Paper>
    </Popper>
  );
};
interface PopperMargin {
  marginTop?: string;
  marginBottom?: string;
}
const getMargin = (
  buttonElement: HTMLDivElement,
  popperPlacement: PopperPlacement,
): PopperMargin | undefined => {
  const clock = buttonElement.parentElement!.getBoundingClientRect();
  const button = buttonElement.getBoundingClientRect();
  const distanceFromButtonToContainer = clock.bottom - button.bottom;

  if (popperPlacement === 'top') {
    return { marginBottom: `${distanceFromButtonToContainer}px!important` };
  }
  if (popperPlacement === 'bottom') {
    return { marginTop: `${distanceFromButtonToContainer}px!important` };
  }
  return undefined;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { CapPresets } from '../components/types';

interface Language {
  language: string;
  event: string;
  senderName: string;
  headline: string;
  description: string;
}

export const findEventIndexInLanguage = (
  language: string,
  languages: Language[],
  capWarningPresets: CapPresets,
  feedAddressFromProps: string,
): number => {
  const correctFeedIndex = capWarningPresets.feeds.findIndex(
    ({ feedAddress }) => feedAddress === feedAddressFromProps,
  );
  if (correctFeedIndex === -1) {
    return 0;
  }

  const languageIndex = languages.findIndex((lang) =>
    lang.language.startsWith(language),
  );

  if (languageIndex === -1) {
    const fallbackLanguage = languages.findIndex((object) =>
      object.language.startsWith('en'),
    );

    if (fallbackLanguage === -1) {
      return 0;
    }
    return fallbackLanguage;
  }
  return languageIndex;
};

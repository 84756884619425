/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { PlayButton } from '../PlayButton/PlayButton';
import { BackwardForwardStepButton } from '../BackwardForwardStepButton/BackwardForwardStepButton';
import { NowButton } from '../NowButton/NowButton';
import { AutoUpdateButton } from '../AutoUpdateButton/AutoUpdateButton';

export const ControlButtons: React.FC<{
  backwardBtn?: React.ReactNode;
  forwardBtn?: React.ReactNode;
  playBtn?: React.ReactNode;
  nowBtn?: React.ReactNode;
  autoUpdateBtn?: React.ReactNode;
}> = ({ backwardBtn, forwardBtn, playBtn, nowBtn, autoUpdateBtn }) => {
  return (
    <>
      <Grid>
        {backwardBtn || <BackwardForwardStepButton isForwardStep={false} />}
      </Grid>
      <Grid>
        {forwardBtn || <BackwardForwardStepButton isForwardStep={true} />}
      </Grid>
      <Grid>{playBtn || <PlayButton />}</Grid>
      <Grid>{nowBtn || <NowButton />}</Grid>
      <Grid>{autoUpdateBtn || <AutoUpdateButton />}</Grid>
    </>
  );
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { dateUtils } from '@opengeoweb/shared';
import { ChangeGroupFormData, Valid } from '../../../../types';
import { getFieldValue } from './utils';

interface ValidSections {
  day: number;
  hour: number;
  min: number;
}

const FORMAT_DAYS_HOURS = 'ddHH';
export const FORMAT_DAYS_HOURS_MINUTES = 'ddHHmm';
const lengthFM = 6;
const lengthBECMGTEMPO = 9;

// formatters
export const formatValidToString = (valid: Valid): string => {
  // Ensure end of valid period if midnight is shown as 24 and not 00
  const endHours = dateUtils.dateToString(dateUtils.utc(valid.end), 'kk');
  const endDate =
    endHours === '24'
      ? dateUtils.dateToString(
          dateUtils.sub(dateUtils.utc(valid.end), { days: 1 }),
          'dd',
        )
      : dateUtils.dateToString(dateUtils.utc(valid.end), 'dd');

  return valid.end
    ? `${dateUtils.dateToString(
        dateUtils.utc(valid.start),
        FORMAT_DAYS_HOURS,
      )!}/${endDate}${endHours}`
    : dateUtils.dateToString(
        dateUtils.utc(valid.start),
        FORMAT_DAYS_HOURS_MINUTES,
      )!;
};

const getSections = (value: string, format: string): ValidSections => {
  return {
    day: parseInt(value.substring(0, 2), 10),
    hour: parseInt(value.substring(2, 4), 10),
    min:
      format === FORMAT_DAYS_HOURS_MINUTES
        ? parseInt(value.substring(4), 10)
        : 0,
  };
};

const getValidityTimeStamp = (
  sectionType: 'start' | 'end',
  sections: ValidSections,
  startTaf: Date,
  endTaf: Date,
): Date => {
  const clonedStartTafDate = dateUtils.set(startTaf, {
    date: sections.day,
    hours: sections.hour,
    minutes: sections.min,
    seconds: 0,
    milliseconds: 0,
  });

  // if start and hour is the same but minutes != 00 for the startTaf - set the minutes to account for AMENDED tafs which do not start on the hour
  const shouldUpdateMinutesSeconds =
    sectionType === 'start' &&
    startTaf.getDate() === clonedStartTafDate.getDate() &&
    startTaf.getUTCHours() === clonedStartTafDate.getUTCHours() &&
    startTaf.getMinutes() !== clonedStartTafDate.getMinutes();

  const updatedStartTafDate = shouldUpdateMinutesSeconds
    ? dateUtils.set(clonedStartTafDate, {
        minutes: startTaf.getMinutes(),
        seconds: startTaf.getSeconds(),
      })
    : clonedStartTafDate;

  // Only proceed if has not bubbled dates overflow to months and not bubbled hours overflow to days
  const isSameTime =
    updatedStartTafDate.getDate() === sections.day &&
    updatedStartTafDate.getUTCHours() === sections.hour &&
    updatedStartTafDate.getMinutes() === sections.min;
  const isOverflowing =
    updatedStartTafDate.getDate() === sections.day + 1 &&
    sections.hour === 24 &&
    sections.min === 0;

  if (isSameTime || isOverflowing) {
    const isHalfInMonth =
      endTaf.getMonth() !== startTaf.getMonth() && sections.day < 15;
    if (isHalfInMonth) {
      return dateUtils.add(updatedStartTafDate, { months: 1 });
    }
  }

  return updatedStartTafDate;
};

// parsers
export const parseValidToObject = (
  value: string,
  tafStart: string,
  tafEnd: string,
): Valid => {
  if (!value) {
    return null!;
  }
  // Create valid object from passed string
  const trimmedValue = value.trim();

  const tafStartDate = dateUtils.utc(tafStart);
  const tafEndDate = dateUtils.utc(tafEnd);

  const startEnd = trimmedValue.split('/');
  const format =
    trimmedValue.indexOf('/') === -1
      ? FORMAT_DAYS_HOURS_MINUTES
      : FORMAT_DAYS_HOURS;

  const startSections = getSections(startEnd[0], format);
  const startDate = getValidityTimeStamp(
    'start',
    startSections,
    tafStartDate,
    tafEndDate,
  );

  const startString = dateUtils.dateToString(startDate)!;

  // If we only have start value - return that otherwise, also get end time
  if (startEnd.length < 2) {
    return {
      start: startString,
    };
  }
  const endSections = getSections(startEnd[1], FORMAT_DAYS_HOURS);

  const endDate = getValidityTimeStamp(
    'end',
    endSections,
    tafStartDate,
    tafEndDate,
  );
  const endString = dateUtils.dateToString(endDate);
  return { start: startString, end: endString };
};

// messages
export const invalidValidityDaysHoursStartMessage =
  'Invalid validity start period, expected a date value between 00 and 31 and an hour value between 00 and 23';
export const invalidValidityDaysHoursEndMessage =
  'Invalid validity end period, expected a date value between 00 and 31 and an hour value between 01 and 24';
export const invalidValidityMinutesMessage =
  'Invalid validity period, specified minutes should be between 00 and 59';
export const invalidValidityStartTimeMessage =
  'Invalid validity start time, change group validity must lie within the TAF validity period';
export const invalidValidityPeriodMessage =
  'Invalid validity period, validity end time has to be after validity start time';
export const invalidValidityEndTimeMessage =
  'Invalid validity end time, change group validity must lie within the TAF validity period';
export const invalidChangeValidInterFieldMessage =
  'Invalid validity period, expected DDHH/DDHH for PROB, BECMG and TEMPO or DDHHmm for FM';
export const invalidBECMGDurationValidInterFieldMessage =
  'Invalid validity period, for BECMG only validity periods with a duration of 1, 2, 3 or 4 hours are allowed';
export const invalidTEMPODurationValidInterfieldMessage =
  'Invalid validity period, for TEMPO a validity period of at least 1 hour is required';
export const invalidChangeGroupOverlapsWithFM =
  'The valid time of a change group cannot overlap with the valid time of a following FM group. This change group should end before or at the hour of the start of the FM group';

// Validations

// Ensure all individual sections are correct (DD HH mm etc)
const validateValidSections = (
  sections: ValidSections,
  isStartEnd: 'start' | 'end',
): string[] => {
  const validateMsg: string[] = [];

  if (
    isStartEnd === 'start' &&
    (sections.day < 1 ||
      sections.day > 31 ||
      sections.hour < 0 ||
      sections.hour > 23)
  ) {
    validateMsg.push(invalidValidityDaysHoursStartMessage);
  } else if (
    isStartEnd === 'end' &&
    (sections.day < 1 ||
      sections.day > 31 ||
      sections.hour < 1 ||
      sections.hour > 24)
  ) {
    validateMsg.push(invalidValidityDaysHoursEndMessage);
  }
  if (sections.min < 0 || sections.min > 59) {
    validateMsg.push(invalidValidityMinutesMessage);
  }

  return validateMsg;
};

// Validate to ensure valid dates are passed
const validateIsValidDates = (
  value: string,
  tafStart: string,
  tafEnd: string,
): string[] => {
  const tafStartDate = dateUtils.utc(tafStart);
  const tafEndDate = dateUtils.utc(tafEnd);

  const startEnd = value.split('/');
  const format =
    value.indexOf('/') === -1 ? FORMAT_DAYS_HOURS_MINUTES : FORMAT_DAYS_HOURS;

  const validateMsg: string[] = [];

  // Validate start time
  const startSections = getSections(startEnd[0], format);

  // Validate individual sections for start
  const sectionValidationStart = validateValidSections(startSections, 'start');
  // If invalid start - return with errors
  if (sectionValidationStart.length) {
    return sectionValidationStart;
  }

  const startTimestamp = getValidityTimeStamp(
    'start',
    startSections,
    tafStartDate,
    tafEndDate,
  );

  // Validate start is within the taf validity bounds
  if (startTimestamp < tafStartDate || startTimestamp > tafEndDate) {
    validateMsg.push(invalidValidityStartTimeMessage);
  }

  // Validate end time
  if (startEnd.length > 1) {
    const endSections = getSections(startEnd[1], FORMAT_DAYS_HOURS);

    // Validate individual sections for end
    const sectionValidationEnd = validateValidSections(endSections, 'end');
    // If invalid end - return with errors
    if (sectionValidationEnd.length) {
      return sectionValidationEnd;
    }

    const endTimestamp = getValidityTimeStamp(
      'end',
      endSections,
      tafStartDate,
      tafEndDate,
    );

    // Validate end is not before start
    if (endTimestamp < startTimestamp) {
      validateMsg.push(invalidValidityPeriodMessage);
    }

    // Validate end is within the taf validity bounds
    if (endTimestamp < tafStartDate || endTimestamp > tafEndDate) {
      validateMsg.push(invalidValidityEndTimeMessage);
    }
  }

  return validateMsg;
};

// Main validation function
export const validateValidField = (
  value: string,
  tafStart: string,
  tafEnd: string,
): boolean | string => {
  const trimmedValue = getFieldValue(value);
  if (!trimmedValue.length) {
    return true;
  }

  // Validate length and ensure it doesn't contain any other characters than numbers and / and has either XXXX/XXXX or XXXXXX format
  if (
    trimmedValue.length < lengthFM ||
    trimmedValue.length > lengthBECMGTEMPO ||
    !(
      trimmedValue.match(/^[0-9]{4}\/[0-9]{4}$/) ||
      trimmedValue.match(/^[0-9]{6}$/)
    ) ||
    // only allow dates with / fully typed
    (tafStart?.indexOf('/') > -1 && trimmedValue.length !== lengthBECMGTEMPO) ||
    (tafEnd?.indexOf('/') > -1 && trimmedValue.length !== lengthBECMGTEMPO)
  ) {
    return invalidChangeValidInterFieldMessage;
  }

  // Ensure valid date(s) can be made
  const validDateErrors = validateIsValidDates(trimmedValue, tafStart, tafEnd);
  if (validDateErrors.length !== 0) {
    return validDateErrors.find((error) => typeof error === 'string')!;
  }

  return true;
};

export const validateValidChangeGroupInterField = (
  value: string,
  valueChange: string,
  valueProb: string,
  tafStart: string,
  tafEnd: string,
): boolean | string => {
  const trimmedValue = getFieldValue(value);
  const trimmedValueChange = getFieldValue(valueChange);
  const trimmedValueProb = getFieldValue(valueProb);

  if (!trimmedValue.length) {
    return true;
  }

  if (!trimmedValueChange.length && !trimmedValueProb.length) {
    return true;
  }

  if (!trimmedValueChange.length && trimmedValueProb.length) {
    if (
      // Ensure format for probability without change is correct DDHH/DDHH
      trimmedValue.length < 9
    ) {
      return invalidChangeValidInterFieldMessage;
    }
  }

  if (
    // Ensure format for FM changegroups is correct DDHHmm
    (trimmedValue.length > lengthFM && trimmedValueChange === 'FM') ||
    // Ensure format for BECMG and TEMPO changegroups is correct DDHH/DDHH
    (trimmedValue.length < lengthBECMGTEMPO && trimmedValueChange !== 'FM')
  ) {
    return invalidChangeValidInterFieldMessage;
  }

  // Ensure BECMG changegroup duration for BECMG and TEMPO
  if (trimmedValueChange === 'BECMG' || trimmedValueChange === 'TEMPO') {
    const tafStartDate = dateUtils.utc(tafStart);
    const tafEndDate = dateUtils.utc(tafEnd);

    const startEnd = trimmedValue.split('/');
    const format =
      trimmedValue.indexOf('/') === -1
        ? FORMAT_DAYS_HOURS_MINUTES
        : FORMAT_DAYS_HOURS;

    // Validate start time
    const startSections = getSections(startEnd[0], format);
    const startTimestamp = getValidityTimeStamp(
      'start',
      startSections,
      tafStartDate,
      tafEndDate,
    );
    const endSections = getSections(startEnd[1], FORMAT_DAYS_HOURS);
    const endTimestamp = getValidityTimeStamp(
      'end',
      endSections,
      tafStartDate,
      tafEndDate,
    );

    const timeDifference = dateUtils.differenceInHours(
      endTimestamp,
      startTimestamp,
      'ceil',
    );

    // Ensure BECMG changegroup has duration of 1,2,3,4 hours
    if (trimmedValueChange === 'BECMG') {
      if (
        // For non-amendments we only accept 1 to 4 hours
        (timeDifference >= 1 &&
          ![1, 2, 3, 4].includes(Math.ceil(timeDifference))) ||
        // For amendments the time difference can be below one hour, accept this unless the minute difference is zero
        (timeDifference < 1 &&
          dateUtils.differenceInMinutes(endTimestamp, startTimestamp) === 0)
      ) {
        return invalidBECMGDurationValidInterFieldMessage;
      }
    }
    // Ensure TEMPO changegroup has duration of at least 1 hour
    if (trimmedValueChange === 'TEMPO') {
      if (timeDifference < 1) {
        return invalidTEMPODurationValidInterfieldMessage;
      }
    }
  }

  return true;
};

// validate previous changeGroup
const validProbChangeOrder = [
  'FM',
  'BECMG',
  'TEMPO',
  'PROB40',
  'PROB30',
  'PROB40 TEMPO',
  'PROB30 TEMPO',
];
export const invalidPreviousChangeGroupIsBeforeMessage =
  'Valid time should be after previous valid time';
export const invalidPreviousChangeGroupOrderMessage = `When valid times are equal, the change/probability order should be: ${validProbChangeOrder.join(
  ', ',
)}`;
export const invalidPreviousChangeGroupHasSameValue =
  "When valid times are equal, probability/change can't have the same value as previous row value";

export const validatePreviousChangeGroup = (
  currentChangeGroup: ChangeGroupFormData,
  previousChangeGroup: ChangeGroupFormData,
  tafStart: string,
  tafEnd: string,
): boolean | string => {
  // extract the needed values from changeGroups
  const valid = getFieldValue(currentChangeGroup.valid);
  const change = getFieldValue(currentChangeGroup.change || '');
  const probability = getFieldValue(currentChangeGroup.probability || '');
  const previousValid = getFieldValue(previousChangeGroup.valid);
  const previousChange = getFieldValue(previousChangeGroup.change || '');
  const previousProbability = getFieldValue(
    previousChangeGroup.probability || '',
  );

  // get the parsed values
  const validValue = parseValidToObject(valid, tafStart, tafEnd);
  const previousValidValue = parseValidToObject(
    previousValid,
    tafStart,
    tafEnd,
  );
  if (
    !validValue ||
    !previousValidValue ||
    (!change.length && !probability.length)
  ) {
    return true;
  }

  // validate is valid time before
  const currentStartTime = dateUtils.utc(validValue.start);
  const previousRowStartTime = dateUtils.utc(previousValidValue.start);

  if (dateUtils.isBefore(currentStartTime, previousRowStartTime)) {
    return invalidPreviousChangeGroupIsBeforeMessage;
  }

  // validate same valid time
  if (dateUtils.isEqual(currentStartTime, previousRowStartTime)) {
    // validate change/probability order
    const currentProbChangeValue = `${probability} ${change}`.trim();
    const previousProbChangeValue =
      `${previousProbability} ${previousChange}`.trim();

    if (
      validProbChangeOrder.indexOf(currentProbChangeValue) <
      validProbChangeOrder.indexOf(previousProbChangeValue)
    ) {
      return invalidPreviousChangeGroupOrderMessage;
    }
    // validate same valid + change/probability value
    if (currentProbChangeValue === previousProbChangeValue) {
      return invalidPreviousChangeGroupHasSameValue;
    }
  }

  return true;
};

// validate overlapping validity when one of the following changegroups is FM
export const validateOverlapWithFM = (
  currentChangeGroup: ChangeGroupFormData,
  nextChangeGroups: ChangeGroupFormData[],
  tafStart: string,
  tafEnd: string,
): boolean | string => {
  const valid = getFieldValue(currentChangeGroup.valid);
  if (!valid || !nextChangeGroups.length) {
    return true;
  }

  if (
    valid.length < lengthFM ||
    valid.length > lengthBECMGTEMPO ||
    !(valid.match(/^[0-9]{4}\/[0-9]{4}$/) || valid.match(/^[0-9]{6}$/))
  ) {
    return false;
  }

  const validValue = parseValidToObject(valid, tafStart, tafEnd);

  const currentRowEndTime = dateUtils.utc(validValue.end);

  const firstFMChangeGroup = nextChangeGroups.find(
    (nextChangeGroup) => getFieldValue(nextChangeGroup.change) === 'FM',
  );
  if (firstFMChangeGroup && firstFMChangeGroup.valid) {
    const FMValidValue = parseValidToObject(
      firstFMChangeGroup.valid,
      tafStart,
      tafEnd,
    );

    const FMStartTime = dateUtils.utc(FMValidValue.start);

    if (dateUtils.isAfter(currentRowEndTime, FMStartTime)) {
      return invalidChangeGroupOverlapsWithFM;
    }
  }
  return true;
};

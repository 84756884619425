/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import {
  Capability,
  GetCapabilitiesJson,
  Header,
  LayerProps,
  LayerTree,
  WMSLayerFromGetCapabilities,
  WMSServiceInfo,
  XLINK,
} from '../../components/types';
import { WMSVersion } from '../../components/WMConstants';
import {
  getUriAddParam,
  isDefined,
  makeNodeLayerFromWMSGetCapabilityLayer,
  sortArrayOfObjectsByKey,
} from '../../components/WMJSTools';
import WMXMLParser from '../../components/WMXMLParser';
import { consoleErrorMessages } from '../consoleErrorMessages';
import { getWMSServiceId } from './getWMSServiceId';

/**
 * A LayerTree with deeply nested children will be flattened into a 1D array.
 * Children properties are removed and result is sorted alphabetically by the layers title.
 * @param layerTree a WMS LayerTree
 * @returns Flat array of layers as LayerProps[]
 */

export const privateWmsFlattenLayerTree = (
  layerTree: LayerTree,
): LayerProps[] => {
  const omit = (prop: string, { [prop]: _, ...rest }): LayerProps =>
    rest as unknown as LayerProps;

  const recurseLayerTree = (data: LayerTree[]): LayerProps[] => {
    return data && Array.isArray(data)
      ? data.reduce((acc: LayerProps[], item: LayerTree) => {
          item.name && acc.push(omit('children', item));
          item.children && acc.push(...recurseLayerTree(item.children));
          return acc;
        }, [])
      : [];
  };
  const flatLayerObject: LayerProps[] = recurseLayerTree(layerTree.children);

  return sortArrayOfObjectsByKey<LayerProps>(flatLayerObject, 'title');
};
export const privateGetOGCCapabilitiesUrl = (
  url = '',
  service = 'WMS',
  additionalParams = {},
): string =>
  getUriAddParam(url, {
    service,
    request: 'GetCapabilities',
    ...additionalParams,
  });

export const privateWmsGetCapabilities = async (
  serviceUrl: string,
  headers: Header[] = [],
  forceReload = false,
): Promise<GetCapabilitiesJson> => {
  /* Make the getCapabilitiesJSONrequest */
  if (!isDefined(serviceUrl)) {
    throw new Error(consoleErrorMessages.noServiceDefined);
  }
  if (wmsGetCapabilitiesTestFetcher != null) {
    return wmsGetCapabilitiesTestFetcher(serviceUrl)!;
  }
  if (serviceUrl.length === 0) {
    throw new Error(consoleErrorMessages.serviceUrlEmpty);
  }

  if (
    !serviceUrl.startsWith('http://') &&
    !serviceUrl.startsWith('https:') &&
    !serviceUrl.startsWith('//')
  ) {
    throw new Error(consoleErrorMessages.serviceUrlEmpty);
  }
  const addParams = forceReload ? { random: Math.random() } : {};
  const url = privateGetOGCCapabilitiesUrl(
    serviceUrl.indexOf('?') === -1 ? `${serviceUrl}?` : serviceUrl,
    'WMS',
    addParams,
  );
  return WMXMLParser(url, headers);
};

export const privateWmtsGetCapabilities = async (
  serviceUrl: string,
  headers: Header[] = [],
  forceReload = false,
): Promise<string> => {
  /* Make the getCapabilitiesJSONrequest */
  if (!isDefined(serviceUrl)) {
    throw new Error(consoleErrorMessages.noServiceDefined);
  }

  if (serviceUrl.length === 0) {
    throw new Error(consoleErrorMessages.serviceUrlEmpty);
  }

  if (
    !serviceUrl.startsWith('http://') &&
    !serviceUrl.startsWith('https:') &&
    !serviceUrl.startsWith('//')
  ) {
    throw new Error(consoleErrorMessages.serviceUrlEmpty);
  }
  const addParams = forceReload ? { random: Math.random() } : {};
  const url = privateGetOGCCapabilitiesUrl(
    serviceUrl.indexOf('?') === -1 ? `${serviceUrl}?` : serviceUrl,
    'WMTS',
    addParams,
  );
  const fetchHeaders = new Headers();
  if (headers && headers.length > 0) {
    for (const header of headers) {
      fetchHeaders.append(header.name, header.value);
    }
  }
  return fetch(url, {
    headers: fetchHeaders,
  }).then((response) => {
    return response.text();
  });
};

/**
 * privateWmsGetCapabilityElement
 * @param jsonData
 * @returns
 */
export const privateWmsGetCapabilityElement = (
  jsonData: GetCapabilitiesJson,
): Capability => {
  let capabilityObject;
  try {
    capabilityObject = jsonData.WMT_MS_Capabilities.Capability;
  } catch (e) {
    try {
      capabilityObject = jsonData.WMS_Capabilities.Capability;
    } catch {
      throw new Error(consoleErrorMessages.noCapabilityElementFound);
    }
  }
  return capabilityObject;
};

export const privateCheckException = (
  jsonData: GetCapabilitiesJson,
): string | undefined => {
  try {
    if (jsonData.ServiceExceptionReport) {
      let code: string;
      let value: string;
      const se = jsonData.ServiceExceptionReport.ServiceException;
      if (se) {
        try {
          if (se.attr.code) {
            code = se.attr.code;
          }
        } catch (e) {
          // do nothing
        }
        if (se.value) {
          value = se.value;
          return `Exception: ${code!}.\n${value}`;
        }
      }
      return `${consoleErrorMessages.wmsServiceExceptionCode} ${code!}`;
    }
  } catch (e) {
    // do nothing
  }
  return undefined;
};

export const privateWmsGetRootLayerFromGetCapabilities = (
  jsonData: GetCapabilitiesJson,
): WMSLayerFromGetCapabilities => {
  return privateWmsGetCapabilityElement(jsonData).Layer;
};

const privateCheckVersion111 = (jsonData: GetCapabilitiesJson): void => {
  try {
    const rootLayer = jsonData.WMT_MS_Capabilities.Capability.Layer;
    if (!rootLayer) {
      throw new Error('No 111 layer');
    }
  } catch (e) {
    const message = privateCheckException(jsonData);
    if (message !== undefined) {
      throw new Error(message);
    }
    if (!jsonData.WMT_MS_Capabilities.Capability) {
      throw new Error(consoleErrorMessages.noWmsCapabilityElementFound);
    }
    if (!jsonData.WMT_MS_Capabilities.Capability.Layer) {
      throw new Error(consoleErrorMessages.noWmsLayerElementFound);
    }
  }
};

export const privateCheckVersion130 = (jsonData: GetCapabilitiesJson): void => {
  try {
    const rootLayer = jsonData.WMS_Capabilities.Capability.Layer;
    if (!rootLayer) {
      throw new Error('No 130 layer');
    }
  } catch (e) {
    const message = privateCheckException(jsonData);
    if (message !== undefined) {
      throw new Error(message);
    }
    if (!jsonData.WMS_Capabilities.Capability) {
      throw new Error(consoleErrorMessages.noWmsCapabilityElementFound);
    }
    if (!jsonData.WMS_Capabilities.Capability.Layer) {
      throw new Error(consoleErrorMessages.noWmsLayerElementFound);
    }
  }
};

export const privateCheckVersion = (
  jsonData: GetCapabilitiesJson,
): string | null => {
  let version: string | null = null;
  try {
    if (WMSVersion.version100 === jsonData.WMT_MS_Capabilities.attr.version) {
      version = WMSVersion.version100;
    }
    if (WMSVersion.version111 === jsonData.WMT_MS_Capabilities.attr.version) {
      version = WMSVersion.version111;
    }
    if (WMSVersion.version130 === jsonData.WMT_MS_Capabilities.attr.version) {
      version = WMSVersion.version130;
    }
  } catch (e) {
    try {
      if (WMSVersion.version100 === jsonData.WMS_Capabilities.attr.version) {
        version = WMSVersion.version100;
      }
      if (WMSVersion.version111 === jsonData.WMS_Capabilities.attr.version) {
        version = WMSVersion.version111;
      }
      if (WMSVersion.version130 === jsonData.WMS_Capabilities.attr.version) {
        version = WMSVersion.version130;
      }
    } catch {
      return null;
    }
  }
  if (version === WMSVersion.version111) {
    privateCheckVersion111(jsonData);
    return version;
  }
  if (version === WMSVersion.version130) {
    privateCheckVersion130(jsonData);
    return version;
  }
  return WMSVersion.version111;
};

/**
 * Returns a LayerTree object representing hierarchical structure of Lahyers in the WMS GetCapabilities document
 * @param jsonData GetCapabilitiesJson
 * @returns LayerTree
 */
export const privateWmsGetLayerTree = (
  jsonData: GetCapabilitiesJson,
): LayerTree => {
  privateCheckVersion(jsonData);
  const rootLayer = privateWmsGetCapabilityElement(jsonData)
    .Layer as WMSLayerFromGetCapabilities;
  return privateWmsBuildLayerTreeFromNestedWMSLayer(rootLayer);
};

/**
 * Builds a standardized LayerTree object based on WMSLayerFromGetCapabilities object.
 * @param wmsLayer The WMSLayerFromGetCapabilities object
 * @param nestedPath - Optional, only to be used by privateWmsBuildLayerTreeFromNestedWMSLayer
 * @param nestedLayerPath - Optional, only to be used by privateWmsBuildLayerTreeFromNestedWMSLayer
 * @returns A LayerTree object.
 */
export const privateWmsBuildLayerTreeFromNestedWMSLayer = (
  wmsLayer: WMSLayerFromGetCapabilities,
  nestedPath: string[] = [],
  nestedLayerPath: LayerProps[] = [],
): LayerTree => {
  const isleaf = wmsLayer?.Name && !wmsLayer?.Layer;

  const layerProps = makeNodeLayerFromWMSGetCapabilityLayer(
    wmsLayer,
    nestedPath,
    isleaf,
    nestedLayerPath,
  );

  const pathName = wmsLayer?.Title?.value || '';

  const layerTree: LayerTree = { ...layerProps, children: [] };

  if (wmsLayer?.Layer) {
    const childLayers: WMSLayerFromGetCapabilities[] = (
      wmsLayer.Layer as WMSLayerFromGetCapabilities[]
    )['length']
      ? (wmsLayer.Layer as WMSLayerFromGetCapabilities[])
      : ([wmsLayer.Layer] as WMSLayerFromGetCapabilities[]);
    layerTree.children = sortArrayOfObjectsByKey<LayerTree>(
      childLayers.map((layerChild): LayerTree => {
        return privateWmsBuildLayerTreeFromNestedWMSLayer(
          layerChild,
          [...nestedPath, pathName],
          [...nestedLayerPath, layerProps],
        );
      }),
      'title',
    );
  }

  return layerTree;
};

export type GetCapabilitiesFunctionType = (
  serviceUrl: string,
) => Promise<GetCapabilitiesJson>;

let wmsGetCapabilitiesTestFetcher: GetCapabilitiesFunctionType | null = null;

export const setWMSGetCapabilitiesFetcher = (
  functionToSet: GetCapabilitiesFunctionType | null,
): void => {
  wmsGetCapabilitiesTestFetcher = functionToSet;
};

/**
 * Returns details about the WMS service. You are encouraged to use queryWMSServiceInfo instead.
 * @param getCapabilitiesJson
 * @param serviceUrl
 * @returns
 */

export const privateGetWMSServiceInfo = (
  getCapabilitiesJson: GetCapabilitiesJson,
  serviceUrl: string,
): WMSServiceInfo => {
  const wmsCapabilities =
    getCapabilitiesJson.WMS_Capabilities ||
    getCapabilitiesJson.WMT_MS_Capabilities;

  const wmsCapability = privateWmsGetCapabilityElement(getCapabilitiesJson);
  const wmsService = wmsCapabilities?.Service;
  const service: WMSServiceInfo = {
    id: getWMSServiceId(serviceUrl),
    version: privateCheckVersion(getCapabilitiesJson)!,
    abstract:
      wmsService?.Abstract?.value || consoleErrorMessages.notAvailableMessage,
    title: wmsService?.Title?.value || consoleErrorMessages.notAvailableMessage,
    onlineresource:
      wmsService?.OnlineResource?.value || wmsService?.OnlineResource?.attr
        ? (wmsService?.OnlineResource?.attr as XLINK)['xlink:href'] ||
          consoleErrorMessages.notAvailableMessage
        : consoleErrorMessages.notAvailableMessage,
    getmapURL:
      wmsCapability.Request?.GetMap?.DCPType?.HTTP?.Get?.OnlineResource?.attr[
        'xlink:href'
      ] || serviceUrl,
    getCapabilitiesJson: wmsCapabilities,
  };
  return service;
};

/**
 * Makes a uniform and harmonized URL used as caching key in TanStack
 * @param serviceUrl
 * @returns
 */
export const makeQueryKeyFroMServiceUrl = (serviceUrl: string): string => {
  return new URLSearchParams(serviceUrl).toString();
};

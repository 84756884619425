/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import * as React from 'react';
import { Box, Grid2 as Grid } from '@mui/material';

const spaceweatherLayoutStyles = {
  height: 'calc(100% - 56px)',
  overflowY: 'auto',
  '.spaceweatherLayoutContainer': {
    containerType: 'inline-size',
    containerName: 'spaceweatherLayout',
    flexWrap: 'wrap',
    '.gridContainer': { padding: '0px 16px' },
  },
  '.col-left': {
    flexGrow: 1,
    flexBasis: '320px',
    flexDirection: 'column',
  },
  '.col-right': {
    flexGrow: 1,
    flexBasis: '320px',
  },
  '@container spaceweatherLayout (width > 768px)': {
    '.spaceweatherLayoutContainer': {
      '.gridContainer': { padding: '0px 24px' },
      '.col-left': {
        flexGrow: 1,
      },
      '.col-right': {
        flexGrow: 1,
      },
    },
  },
};

interface LayoutProps {
  leftTop: React.ReactNode;
  leftBottom: React.ReactNode;
  right: React.ReactNode;
}

/**
 * Layout
 * Creates a grid with one component at the top, two on the left and one on the right.
 * IMPORTANT: WHEN USING THIS COMPONENT PLEASE WRAP INSIDE OF <NotificationTriggerProvider/>
 * @param {ReactNode} leftTop leftTop: ReactNode - React component to show on the left top
 * @param {ReactNode} leftBottom leftBottom: ReactNode - React component to show on the left bottom
 * @param {ReactNode} right right: ReactNode - React component to show on the right
 * @example
 * ``` <Layout leftTop={<Bulletin />} leftBottom={<Notifications />} right={<TimeSeries />} /> ```
 */
const Layout: React.FC<LayoutProps> = ({
  leftTop,
  leftBottom,
  right,
}: LayoutProps) => {
  return (
    <Box sx={spaceweatherLayoutStyles}>
      <Grid className="spaceweatherLayoutContainer" container>
        <Grid
          sx={{
            marginTop: '5px',
          }}
          container
          spacing={3}
          className="gridContainer"
        >
          <Grid container className="col-left">
            <Grid size={12}>{leftTop}</Grid>
            <Grid size={12}>{leftBottom}</Grid>
          </Grid>
          <Grid size={12} className="col-right">
            {right}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Layout;

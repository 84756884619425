/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Box, LinearProgress } from '@mui/material';
import {
  HeaderSize,
  ToolContainerDraggable,
  calculateStartSize,
  Position,
  DraggableSize,
} from '@opengeoweb/shared';
import { uiTypes } from '@opengeoweb/store';
import { useWarningsTranslation } from '../../utils/i18n';

interface DrawingToolDialogProps {
  bounds?: string;
  title?: string;
  onClose: () => void;
  onMouseDown?: () => void;
  isOpen: boolean;
  order?: number;
  source?: uiTypes.Source;
  isLoading?: boolean;
  size?: DraggableSize;
  startPosition?: Position;
  headerSize?: HeaderSize;
  children: React.ReactNode;
}

const DERFAULT_DRAW_TOOL_SIZE = { width: 320, height: 542 };
const DEFAULT_DRAW_TOOL_POSITION = { top: 120, left: 50 };
const DEFAULT_DRAW_TOOL_MIN_SIZE = { width: 160, height: 300 };

const DrawingTool: React.FC<DrawingToolDialogProps> = ({
  bounds,
  onClose,
  title,
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'app',
  isLoading = false,
  size = DERFAULT_DRAW_TOOL_SIZE,
  startPosition = DEFAULT_DRAW_TOOL_POSITION,
  headerSize = 'xs',
  children,
}: DrawingToolDialogProps) => {
  const { t } = useWarningsTranslation();
  const minSize = DEFAULT_DRAW_TOOL_MIN_SIZE;
  const startSizeCalc = calculateStartSize(minSize, size, startPosition);
  const [sizeInState, setSizeInState] =
    React.useState<DraggableSize>(startSizeCalc);

  return (
    <ToolContainerDraggable
      title={title || t('drawing-tool-title')}
      startSize={sizeInState}
      minWidth={minSize.width}
      minHeight={minSize.height}
      startPosition={startPosition}
      isOpen={isOpen}
      onClose={onClose}
      headerSize={headerSize}
      bounds={bounds}
      data-testid="drawingToolWindow"
      onMouseDown={onMouseDown}
      order={order}
      source={source}
      sx={{
        footer: {
          backgroundColor: 'inherit',
          boxShadow: 'none',
        },
      }}
      onResizeStop={(_event, _direction, node): void => {
        const { offsetWidth: width, offsetHeight: height } = node;
        setSizeInState({ width, height });
      }}
      onDragEnd={(_position, dragSize): void => {
        if (dragSize !== sizeInState) {
          setSizeInState(dragSize as DraggableSize);
        }
      }}
    >
      <Box sx={{ height: '100%' }}>
        {isLoading && (
          <LinearProgress
            data-testid="loading-bar-drawingTool"
            color="secondary"
            sx={{ position: 'absolute', width: '100%' }}
          />
        )}
        {children}
      </Box>
    </ToolContainerDraggable>
  );
};

export default DrawingTool;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import { SelectChangeEvent } from '@mui/material';
import {
  ReactHookFormProvider,
  defaultFormOptions,
} from '@opengeoweb/form-fields';
import { dateUtils } from '@opengeoweb/shared';
import * as React from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { clamp, cloneDeep } from 'lodash';
import {
  getGeoJSONPropertyValue,
  DrawMode,
  rewindGeometry,
} from '@opengeoweb/webmap-react';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { useFormDirty } from '../../utils/useFormDirty';
import {
  DrawingToolFormContents,
  DrawingToolFormValues,
} from './DrawingToolFormContents';

export const opacityOptions = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];
export const getObjectName = (objectName: string): string =>
  objectName ||
  `${dateUtils.dateToString(new Date(), dateUtils.DATE_FORMAT_DATEPICKER, true)} UTC`;

export interface DrawingToolFormComponentProps {
  onSubmitForm: (formValues: DrawingToolFormValues) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  objectName?: string;
  isLoading?: boolean;
  dialogError?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  id?: string;
  drawModes: DrawMode[];
  onChangeDrawMode: (newMode: DrawMode) => void;
  activeDrawModeId: string;
  onDeactivateTool: () => void;
  isInEditMode: boolean;
  geoJSONProperties: GeoJSON.GeoJsonProperties;
  onChangeProperties: (properties: GeoJSON.GeoJsonProperties) => void;
  onChangeName: (name: string) => void;
  onFormDirty?: (isFormDirty: boolean) => void;
  geoJSON: FeatureCollection<Geometry, GeoJsonProperties> | undefined;
}

const DrawingToolForm: React.FC<DrawingToolFormComponentProps> = ({
  onSubmitForm,
  isLoading = false,
  dialogError,
  id = '',
  drawModes = [],
  onChangeDrawMode = (): void => {},
  activeDrawModeId = '',
  onDeactivateTool = (): void => {},
  isInEditMode = false,
  geoJSONProperties = {},
  onChangeProperties = (): void => {},
  onChangeName = (): void => {},
  onFormDirty = (): void => {},
  geoJSON,
}: DrawingToolFormComponentProps) => {
  const {
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = useFormContext();

  // copy errors because react-hook-form mutates the object which breaks memoization
  const currentErrors = cloneDeep(errors);

  useFormDirty({
    isDirty,
    isLoading,
    error: dialogError,
    onFormDirty,
    onSuccess: () => {
      // reset form dirty and update defaultvalues and formvalues after successfull saving
      reset(getValues());
    },
  });

  React.useEffect(() => {
    if (activeDrawModeId || activeDrawModeId === '') {
      setValue('geoJSON', geoJSON, {
        shouldDirty: true,
      });
    }
  }, [activeDrawModeId, geoJSON, setValue]);

  React.useEffect(() => {
    // Only set form value if not in edit mode
    if (!isInEditMode && !isLoading && geoJSON) {
      setValue('geoJSON', geoJSON, {
        shouldValidate: true,
      });
    }
  }, [isLoading, isInEditMode, geoJSON, setValue]);

  const onSubmit = React.useCallback(
    (formValues: FieldValues) => {
      onDeactivateTool();
      // remove unneeded properties
      const { opacity, geoJSON, ...allFormValues } = formValues;
      // rewind geometry
      const rewindedGeometry = rewindGeometry(geoJSON);
      onSubmitForm({
        ...allFormValues,
        id,
        geoJSON: rewindedGeometry,
      } as DrawingToolFormValues);
    },
    [id, onDeactivateTool, onSubmitForm],
  );

  const opacity = React.useMemo(
    () =>
      getGeoJSONPropertyValue(
        'fill-opacity',
        geoJSONProperties,
        drawModes.find((mode) => mode.value === 'POLYGON'),
      ) as number,
    [geoJSONProperties, drawModes],
  );

  const onChangeOpacity = React.useCallback(
    (event: SelectChangeEvent<unknown>): void => {
      const target = event.target as HTMLInputElement;
      const { value } = target;
      const newOpacity = parseInt(value, 10) / 100;
      const additionalStrokeOpacity = 0.8;
      onChangeProperties({
        'fill-opacity': newOpacity,
        'stroke-opacity': clamp(newOpacity + additionalStrokeOpacity, 0.01, 1),
      });
    },
    [onChangeProperties],
  );

  const onBlurName = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      onChangeName(event.target.value);
    },
    [onChangeName],
  );

  return (
    <DrawingToolFormContents
      isLoading={isLoading}
      dialogError={dialogError}
      id={id}
      drawModes={drawModes}
      activeDrawModeId={activeDrawModeId}
      onChangeDrawMode={onChangeDrawMode}
      isInEditMode={isInEditMode}
      isDirty={isDirty}
      errors={currentErrors}
      onChangeOpacity={onChangeOpacity}
      onBlurName={onBlurName}
      opacity={opacity}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

const Wrapper: React.FC<DrawingToolFormComponentProps> = (
  props: DrawingToolFormComponentProps,
) => {
  const { id = '', objectName = '', geoJSON = undefined } = props;
  return (
    <ReactHookFormProvider
      options={{
        ...defaultFormOptions,
        defaultValues: {
          id,
          objectName: getObjectName(objectName),
          geoJSON,
          opacity: 20,
        },
      }}
    >
      <DrawingToolForm {...props} />
    </ReactHookFormProvider>
  );
};

export { Wrapper as DrawingToolForm };

export default Wrapper;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { createSelector } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import type { CoreAppStore } from '../types';
import type { DialogType, UIModuleState, UIStoreType, UIType } from './types';
import { selectorMemoizationOptions } from '../utils';

export const useAppSelector: TypedUseSelectorHook<CoreAppStore> = useSelector;

export const getUiStore = (store: UIModuleState): UIStoreType => {
  if (store && store.ui) {
    return store.ui;
  }
  return null!;
};

/**
 * Gets the active map Id and wether a dialog is open or closed
 *
 * Example: getDialogDetailsByType(store, 'legend')
 * @param {object} store store object from which the ui state wll be extracted
 * @param {string} dialogType type of the dialog
 * @returns {array} [string, boolean] first element gives the active map id, second element whether the dialog is open
 */
export const getDialogDetailsByType = (
  store: UIModuleState,
  dialogType: string,
): UIType => {
  if (store && store.ui && store.ui.dialogs && store.ui.dialogs[dialogType]) {
    return store.ui.dialogs[dialogType]!;
  }
  return null!;
};

/**
 * For a given ui component: gets wether the dialog is open or closed
 *
 * @param {object} store store object from which the ui state wll be extracted
 * @param {string} dialogType type of the dialog
 * @returns {boolean} returnType: boolean
 */
export const getisDialogOpen = createSelector(
  getDialogDetailsByType,
  (details): boolean => {
    if (details) {
      return details.isOpen;
    }
    return false;
  },
  selectorMemoizationOptions,
);

/**
 *For a given ui component: gets the active map Id
 *
 * @param {object} store store object from which the ui state wll be extracted
 * @param {string} dialogType type of the dialog
 * @returns {string} returnType: string - the active map id
 */
export const getDialogMapId = createSelector(
  getDialogDetailsByType,
  (details) => {
    if (details) {
      return details.activeMapId;
    }
    return '';
  },
  selectorMemoizationOptions,
);

/**
 *For a given ui component: gets the order of visible dialog
 *
 * @param {object} store store object from which the ui state wll be extracted
 * @param {DialogType} dialogType type of the dialog
 * @returns {number} the higher the order number the higher the zIndex
 */
export const getDialogOrder = createSelector(
  (store: UIModuleState, dialogType: DialogType): number => {
    if (store && store.ui && store.ui.order) {
      const { order } = store.ui;
      const visibleOrder = order.filter(
        (orderedDialogType) => store.ui!.dialogs[orderedDialogType]?.isOpen,
      );
      if (!visibleOrder.includes(dialogType)) {
        return 0;
      }
      return visibleOrder.length - visibleOrder.indexOf(dialogType);
    }
    return 0;
  },
  (order: number): number => order,
  selectorMemoizationOptions,
);

/**
 *For a given ui component: returns if ordered on top
 *
 * @param {object} store store object from which the ui state wll be extracted
 * @param {DialogType} dialogType type of the dialog
 * @returns {boolean}
 */
export const getDialogIsOrderedOnTop = createSelector(
  (store: UIModuleState, dialogType: DialogType): boolean => {
    if (store && store.ui && store.ui.order) {
      const { order } = store.ui;

      return order.includes(dialogType) && order.indexOf(dialogType) === 0;
    }
    return false;
  },
  (isOrderedOnTop: boolean): boolean => isOrderedOnTop,
  selectorMemoizationOptions,
);

export const getDialogSource = createSelector(
  getDialogDetailsByType,
  (details) => {
    if (details && details.source) {
      return details.source;
    }
    return 'app';
  },
  selectorMemoizationOptions,
);

/**
 * Get the active window that should receive keyboard shortcuts
 *
 * Example: getActiveWindowId(store)
 * @param {object} store store object from which the window state will be extracted
 * @returns {string} active window id
 */
export const getActiveWindowId = createSelector(
  getUiStore,

  (store) => {
    return store?.activeWindowId!;
  },
  selectorMemoizationOptions,
);

/**
 * Gets the panelId of which the dialog is opened from
 *
 * Example: getSourcePanelId(store, 'publicWarnings')
 * @param {object} store store object from which the panelId state will be extracted
 * @returns {string} the dialog type
 */

export const getSourcePanelId = createSelector(
  (store: UIModuleState, dialogType: DialogType) =>
    store.ui?.dialogs[dialogType],
  (dialog) => {
    return dialog?.sourcePanelId || '';
  },
  selectorMemoizationOptions,
);

/**
 * Gets if this is the active window
 *
 * Example: getIsActiveWindowId(store, viewId)
 * @param {object} store store object from which the window state will be extracted
 * @returns {string} active window id
 */
export const getIsActiveWindowId = createSelector(
  getUiStore,
  (_store: UIModuleState, viewId: string) => viewId,
  (store, viewId) => {
    return viewId === store?.activeWindowId!;
  },
  selectorMemoizationOptions,
);

export const getDialogIsLoading = createSelector(
  getDialogDetailsByType,
  (details) => {
    return (details && details.isLoading) || false;
  },
  selectorMemoizationOptions,
);

export const getDialogError = createSelector(
  getDialogDetailsByType,
  (details) => {
    return (details && details.error) || '';
  },
  selectorMemoizationOptions,
);

export const getUiDialogsByActiveMapId = (
  store: UIModuleState,
  mapId: string,
): UIType[] => {
  if (store && store.ui && store.ui.order) {
    const foundDialogsIds = store.ui.order.filter(
      (id) => store.ui?.dialogs[id]?.activeMapId === mapId,
    );
    const foundDialogs = foundDialogsIds.reduce<UIType[]>((list, id) => {
      const dialog = store.ui?.dialogs[id];
      if (dialog) {
        return list.concat(dialog);
      }
      return list;
    }, []);

    return foundDialogs.length ? foundDialogs : [];
  }
  return [];
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import React from 'react';
import { Box } from '@mui/material';

interface AppLayoutProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  header,
  children,
}: AppLayoutProps) => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid="appLayout"
    >
      {header}
      <Box
        component="main"
        sx={{
          flex: 1,
          position: 'relative',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppLayout;

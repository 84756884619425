/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2024 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { AirmetPhenomena, SigmetPhenomena } from '@opengeoweb/api';
import {
  Warning,
  PublicWarningDetail,
} from '../../store/publicWarningForm/types';

export enum Domain {
  aviation = 'aviation',
  maritime = 'maritime',
  public = 'public',
}
export enum Level {
  extreme = 'extreme',
  moderate = 'moderate',
  severe = 'severe',
  airmet = 'AIR',
  sigmet = 'SIG',
}
export enum Phenomenon {
  wind = 'wind',
  fog = 'fog',
  thunderstorm = 'thunderstorm',
  snowIce = 'snowIce',
  rain = 'rain',
  highTemp = 'highTemp',
  lowTemp = 'lowTemp',
  coastalEvent = 'coastalEvent',
}

export const emptyFilterId = 'unspecified';

export const defaultDomainFilter: Record<Domain, boolean> = {
  aviation: false,
  maritime: false,
  public: true,
};

export const domainLevels: Record<Domain, Level[]> = {
  aviation: [Level.airmet, Level.sigmet],
  maritime: [],
  public: [Level.extreme, Level.moderate, Level.severe],
};
export const defaultLevelFilter: Record<Level | 'unspecified', boolean> = {
  [emptyFilterId]: true,
  extreme: true,
  moderate: true,
  severe: true,
  AIR: true,
  SIG: true,
};

export const defaultEmptyFilter: Record<string, boolean> = {
  [emptyFilterId]: true,
};

export const makeFilterFromList = <T extends string>(
  options: T[],
): Record<T | 'unspecified', boolean> =>
  options.reduce(
    (acc, p) => {
      return p ? { ...acc, [p]: true } : acc;
    },
    { [emptyFilterId]: true } as Record<T | 'unspecified', boolean>,
  );

const AllPhenomena = {
  ...AirmetPhenomena,
  ...SigmetPhenomena,
  ...Phenomenon,
} as const;

export const AllPhenomenaKeys = Object.fromEntries(
  Object.keys(AllPhenomena).map((key) => [key, key]),
) as Record<keyof typeof AllPhenomena, keyof typeof AllPhenomena>;

export const domainPhenomena: Record<
  Domain,
  Partial<keyof typeof AllPhenomenaKeys>[]
> = {
  aviation: [
    ...Object.keys(SigmetPhenomena),
    ...Object.keys(AirmetPhenomena),
  ] as (keyof typeof AllPhenomenaKeys)[],
  maritime: [],
  public: [...Object.keys(Phenomenon)] as (keyof typeof AllPhenomenaKeys)[],
};

export const defaultPhenomenonFilter = makeFilterFromList(
  Object.keys(AllPhenomenaKeys),
);

export const initialFilters = {
  incident: defaultEmptyFilter,
  source: defaultEmptyFilter,
  domain: defaultDomainFilter,
  phenomenon: defaultPhenomenonFilter,
  level: defaultLevelFilter,
};

type OptionsType = Partial<Record<Domain | Level | Phenomenon, boolean>>;

interface OptionsMetadata {
  /** The number of selected options */
  noSelectedOptions: number;
  /** Whether or not all options are selected */
  allSelected: boolean;
}
export const getSelectedOptions = (options: OptionsType): OptionsMetadata => {
  const selectedOptions = Object.keys(options).filter(
    (k) => options[k as keyof typeof options],
  );

  const allSelected = selectedOptions.length === Object.keys(options).length;
  return { noSelectedOptions: selectedOptions.length, allSelected };
};

export const updateState = <T extends string>(
  state: Partial<Record<T, boolean>>,
  optionKey: T | 'ALL',
  value: boolean,
  only?: boolean,
): Record<T, boolean> => {
  if (only) {
    return Object.keys(state).reduce(
      (acc, k) => ({ ...acc, [k]: k === optionKey }),
      {} as Record<T, boolean>,
    );
  }
  if (optionKey === 'ALL') {
    if (Object.keys(state).length < 2) {
      return state as Record<T, boolean>;
    }
    return Object.keys(state).reduce(
      (acc, k) => ({ ...acc, [k]: value }),
      {} as Record<T, boolean>,
    );
  }

  return { ...state, [optionKey]: value };
};

/** Make sure our value is in the filter */
export const getValue = <T>(
  values: Record<string, T>,
  value: string,
): T | 'unspecified' =>
  Object.values(values).find((v) => v === value) || emptyFilterId;

export const getFiltersFromWarnings = (
  warnings: Warning[],
  filterKey: string,
): string[] => {
  const filterOptions: string[] = [emptyFilterId];
  if (!warnings || !warnings.length) {
    // return default list
    return filterOptions;
  }

  warnings.forEach((warning) => {
    const id = warning.warningDetail[
      filterKey as keyof PublicWarningDetail
    ] as string;
    if (id && filterOptions.findIndex((option) => option === id) === -1) {
      filterOptions.push(id);
    }
  });

  return filterOptions;
};

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import { Grid2 as Grid, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ItemInterface, ReactSortable } from 'react-sortablejs';
import Sortable, { SortableEvent } from 'sortablejs';
import {
  CoreAppStore,
  layerTypes,
  mapActions,
  mapSelectors,
} from '@opengeoweb/store';
import LayerRowConnect from './LayerRow/LayerRowConnect';
import DragHandle from './LayerRow/DragHandle/DragHandle';
import { LayerManagerCustomSettings } from '../LayerManagerUtils';

interface LayerContainerRowProps extends React.ComponentProps<typeof Grid> {
  mapId: string;
  settings?: LayerManagerCustomSettings['content'];
  collapsedColumns?: Record<string, boolean>;
}

interface CustomSortableEvent extends SortableEvent {
  mapId: string;
  oldIndex: number;
  newIndex: number;
}

const LayerContainerRow: React.FC<LayerContainerRowProps> = React.memo(
  ({
    mapId,
    settings,
    collapsedColumns,
    ...gridProps
  }: LayerContainerRowProps) => {
    const dispatch = useDispatch();
    const [keyPressed, setKeyPressed] = React.useState(false);
    const [isPending, startTransition] = React.useTransition();
    const [rowsToDisplay, setRowsToDisplay] = React.useState(0);
    const layerIds = useSelector((store: CoreAppStore) =>
      mapSelectors.getLayerIds(store, mapId),
    );
    const layerLength = layerIds.length;

    const layerMoveLayer = React.useCallback(
      ({ mapId, oldIndex, newIndex }: CustomSortableEvent) =>
        dispatch(
          mapActions.layerMoveLayer({
            mapId,
            oldIndex,
            newIndex,
            origin: layerTypes.LayerActionOrigin.layerManager,
          }),
        ),
      [dispatch],
    );

    const [activeDragIndex, setActiveDragIndex] = React.useState<number | null>(
      null,
    );

    const onSetList = (): void => {}; // since we use activeDragIndex, we don't need inner state handling of ReactSortable

    const onStart = React.useCallback(({ oldIndex }: CustomSortableEvent) => {
      setActiveDragIndex(oldIndex);
      if (Sortable.ghost) {
        Sortable.ghost.style.opacity = '1';
      }
    }, []);

    const onEnd = React.useCallback(() => {
      if (activeDragIndex !== null) {
        setActiveDragIndex(null);
      }
    }, [activeDragIndex]);

    const onSortEnd = ({ oldIndex, newIndex }: CustomSortableEvent): void => {
      setActiveDragIndex(null);
      layerMoveLayer({
        mapId,
        oldIndex,
        newIndex,
      } as CustomSortableEvent);
    };

    const isSorting = activeDragIndex !== null;
    const isDragDisabled = layerLength === 1;

    const keyPressedTrue = (event: KeyboardEvent): void => {
      if (event.ctrlKey || event.metaKey) {
        setKeyPressed(true);
      }
    };

    const keyPressedFalse = (): void => {
      setKeyPressed(false);
    };

    React.useEffect(() => {
      window.addEventListener('keydown', keyPressedTrue);
      window.addEventListener('keyup', keyPressedFalse);
      return (): void => {
        window.removeEventListener('keydown', keyPressedTrue);
        window.removeEventListener('keyup', keyPressedFalse);
      };
    }, []);

    const preventDefault = React.useCallback((event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();
    }, []);

    React.useEffect(() => {
      if (keyPressed === true) {
        window.addEventListener('wheel', preventDefault, { passive: false });
      } else {
        window.removeEventListener('wheel', preventDefault);
      }
      return (): void => window.removeEventListener('wheel', preventDefault);
    }, [keyPressed, preventDefault]);

    const contents = layerIds.map((layerId) => (
      <LayerRowConnect
        mapId={mapId}
        layerId={layerId}
        key={`layerRowConnect-${layerId}`}
        dragHandle={
          <DragHandle
            isDisabled={isDragDisabled}
            hideTooltip={isSorting || isDragDisabled}
            isSorting={isSorting}
            icon={settings?.dragHandle?.icon}
            tooltipTitle={settings?.dragHandle?.tooltipTitle}
          />
        }
        settings={settings}
        collapsedColumns={collapsedColumns}
      />
    ));

    React.useEffect(() => {
      if (rowsToDisplay < layerLength) {
        startTransition(() => {
          setRowsToDisplay(rowsToDisplay + layerLength);
        });
      }
    }, [layerLength, rowsToDisplay]);

    return (
      <Grid
        container
        data-testid="layerContainerRow"
        className="layerContainerRow"
        sx={{
          width: '100%',
          maxHeight: 'calc(100% - 10px)',
          overflow: 'auto',
          '& .sortable-chosen': {
            boxShadow: 1,
          },
          '& .sortable-ghost': {
            opacity: '0.5 !important',
          },
        }}
        {...gridProps}
      >
        <ReactSortable
          tag="div"
          list={layerIds.map(
            (layerId): ItemInterface => ({
              id: layerId,
            }),
          )}
          setList={onSetList}
          animation={200}
          onSort={onSortEnd as (event: SortableEvent) => void}
          handle=".handle"
          direction="vertical"
          // hover props
          forceFallback={false}
          onStart={onStart as (event: SortableEvent) => void}
          onEnd={onEnd}
          style={{
            width: '100%',
          }}
        >
          {isPending ? (
            <LinearProgress data-testid="loading-bar" />
          ) : (
            contents.slice(0, rowsToDisplay)
          )}
        </ReactSortable>
      </Grid>
    );
  },
);

/**
 * A Connected container component for rendering a sortable set of rows
 *
 * Expects the following props:
 * @param {string} mapId mapId: string - Id of the map
 * @param {LayerManagerWidth} layerManagerWidth layerManagerWidth: LayerManagerWidth - Width of the Layer Manager
 * @example
 * ``` <LayerContainerRow mapId={mapId} layerManagerWidth={LayerManagerWidth.lg} />```
 */
export default LayerContainerRow;

/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2021 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2021 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */
import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import ShortcutList from './ShortcutList';

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
}: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {value === index && children}
  </div>
);

const styles = {
  container: {
    margin: '0px 20px',
    maxHeight: '90vh',
  },
  tab: {
    fontSize: 12,
  },
};

const CheatSheet: React.FC = () => {
  const [activeTab, onChangeActiveTab] = React.useState(0);

  const onChangeTab = (
    _event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    onChangeActiveTab(newValue);
  };

  return (
    <Box sx={styles.container}>
      <Tabs value={activeTab} onChange={onChangeTab} aria-label="platform">
        <Tab sx={styles.tab} label="Windows" />
        <Tab sx={styles.tab} label="Mac" />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <ShortcutList />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <ShortcutList platform="mac" />
      </TabPanel>
    </Box>
  );
};

export default CheatSheet;

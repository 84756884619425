/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import { PresetScope } from '@opengeoweb/shared';
import { EntityState } from '@reduxjs/toolkit';
import { ViewPresetError } from '../viewPresets/types';
import {
  WorkspacePresetAction,
  WorkspacePresetFromBE,
  WorkspacePresetListItem,
  WorkspaceViewsFromBE,
} from '../workspace/types';

export enum WorkspaceListErrorCategory {
  AUTH = 'AUTH', // Errors related to authentication and authorization
  GENERIC = 'GENERIC',
}

export enum WorkspaceListErrorType {
  TRANSLATABLE_ERROR = 'TRANSLATABLE_ERROR',
  VERBATIM_ERROR = 'VERBATIM_ERROR',
}

export type WorkspaceListError =
  | TranslatableWorkspaceListError
  | VerbatimWorkspaceListError
  | undefined;

export interface TranslatableWorkspaceListError {
  type: WorkspaceListErrorType.TRANSLATABLE_ERROR;
  category: WorkspaceListErrorCategory;
  key: string;
  params?: Record<string, string | number> | undefined;
}

export interface VerbatimWorkspaceListError {
  type: WorkspaceListErrorType.VERBATIM_ERROR;
  category: WorkspaceListErrorCategory;
  message: string;
}

export interface WorkspaceListFilter {
  label: string;
  id: string;
  type: 'scope' | 'keyword';
  isSelected?: boolean;
  isDisabled?: boolean;
}

export interface FetchWorkspaceParams {
  scope?: string;
  search?: string;
}

export type WorkspaceListEntityState = EntityState<WorkspacePresetListItem>;

export type WorkspaceDialogView = {
  hasChanges: boolean;
  title: string;
  isSelected: boolean;
  error?: ViewPresetError;
  scope: PresetScope;
  abstract?: string;
} & WorkspaceViewsFromBE;

export interface WorkspaceFormValues {
  id?: string;
  title?: string;
  abstract?: string;
  scope?: PresetScope;
  views?: WorkspaceDialogView[];
}

export type WorkspacePresetFormValues =
  | WorkspaceFormValues
  | WorkspacePresetFromBE;

export interface WorkspaceActionDialogType {
  action: WorkspacePresetAction;
  presetId: string;
  formValues: WorkspacePresetFormValues;
  error?: WorkspaceListError;
  isFetching?: boolean;
  hasSaved?: boolean;
}

export interface WorkspaceListState extends WorkspaceListEntityState {
  isFetching: boolean;
  error: WorkspaceListError;
  isWorkspaceListDialogOpen: boolean;
  workspaceActionDialog?: WorkspaceActionDialogType;
  searchQuery?: string;
  workspaceListFilters?: WorkspaceListFilter[];
}

export type FetchWorkspaceList = FetchWorkspaceParams;

export interface FetchedWorkspaceList {
  workspaceList: WorkspacePresetListItem[];
}

export interface ErrorWorkspaceList {
  error: WorkspaceListError;
}

export interface ToggleListDialogPayload {
  isWorkspaceListDialogOpen: boolean;
}

export interface ToggleSelectFilterChip {
  id: string;
  isSelected: boolean;
}

export interface SearchWorkspaceFilter {
  searchQuery: string;
}

export type OpenWorkspaceActionDialogPayload = WorkspaceActionDialogType;

export interface SubmitFormWorkspaceActionDialogPayload {
  presetAction: WorkspacePresetAction;
  presetId: string;
  data: WorkspacePresetFromBE;
  emptyMapPresetTitle: string;
  emptyWorkspacePresetTitle: string;
}

export interface OnNonDeleteSuccesWorkspacePresetActionPayload {
  workspaceId: string;
  action: Exclude<
    WorkspacePresetAction,
    WorkspacePresetAction.DELETE | WorkspacePresetAction.DELETE_SYSTEM_PRESET
  >;
  title: string;
  abstract?: string;
  currentActiveWorkspaceId?: string;
  scope: PresetScope;
}

export interface OnDeleteSuccesWorkspacePresetActionPayload {
  workspaceId: string;
  action:
    | WorkspacePresetAction.DELETE
    | WorkspacePresetAction.DELETE_SYSTEM_PRESET;
  title: string;
  abstract?: string;
  currentActiveWorkspaceId?: string;
  scope: PresetScope;
  emptyWorkspacePresetTitle: string;
}

export type OnSuccesWorkspacePresetActionPayload =
  | OnNonDeleteSuccesWorkspacePresetActionPayload
  | OnDeleteSuccesWorkspacePresetActionPayload;

export interface OnErrorWorkspacePresetActionPayload {
  error: WorkspaceListError;
}

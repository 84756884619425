/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2023 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2023 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

import * as React from 'react';
import {
  Box,
  Grid2 as Grid,
  LinearProgress,
  SxProps,
  useTheme,
} from '@mui/material';
import {
  AlertBanner,
  calculateStartSize,
  Position,
  DraggableSize,
  HeaderSize,
  ToolContainerDraggable,
  renderCounter,
  Size,
  useWheelStopPropagation,
} from '@opengeoweb/shared';
import { layerTypes } from '@opengeoweb/store';
import type { serviceTypes, uiTypes } from '@opengeoweb/store';
import DescriptionRow from './DescriptionRow/DescriptionRow';
import LayerContainerRow from './LayerContainerRow/LayerContainerRow';
import BaseLayerRow from './BaseLayerRow/BaseLayerRow';
import {
  LayerManagerCustomSettings,
  layerManagerStyle,
} from './LayerManagerUtils';
import { sizeLarge, sizeMedium } from './LayerManagerHeaderOptions';
import HeaderOptionsConnect from './LayerManagerHeaderOptionsConnect';
import ProjectionSelectConnect from './ProjectionSelect/ProjectionSelectConnect';
import { useCoreTranslation } from '../../utils/i18n';

interface LayerManagerProps {
  mapId: string;
  preloadedAvailableBaseLayers?: layerTypes.Layer[];
  preloadedBaseServices?: serviceTypes.InitialService[];
  bounds?: string;
  title?: string;
  onClose: () => void;
  onMouseDown?: () => void;
  isOpen: boolean;
  order?: number;
  source?: uiTypes.Source;
  isLoading?: boolean;
  error?: string;
  isDockedLayerManager?: boolean;
  onToggleDock?: () => void;
  size?: DraggableSize;
  startPosition?: Position;
  settings?: LayerManagerCustomSettings;
  headerSize?: HeaderSize;
  addLayerComponent?: React.ReactElement;
  defaultCollapsedColumns?: Record<string, boolean>;
}
const styles = {
  layerRowContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 59px)',
    display: 'inline-block',
    padding: '0 6px',
  },
  layerManagerContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
};

const getLayerManagerSizeValue = (size: Size): string => {
  if (!size.width || typeof size.width !== 'number') {
    return 'sizeLarge';
  }

  if (size.width < sizeMedium.width) {
    return 'sizeSmall';
  }

  if (size.width < sizeLarge.width) {
    return 'sizeMedium';
  }

  return 'sizeLarge';
};

const LayerManager: React.FC<LayerManagerProps> = ({
  mapId,
  preloadedAvailableBaseLayers,
  preloadedBaseServices,
  bounds,
  onClose,
  title,
  isOpen,
  onMouseDown = (): void => {},
  order = 0,
  source = 'app',
  isLoading = false,
  error,
  isDockedLayerManager = false,
  onToggleDock = (): void => {},
  size = sizeLarge,
  startPosition = { top: 85, left: 50 },
  settings,
  headerSize = 'medium',
  addLayerComponent,
  defaultCollapsedColumns = {},
}: LayerManagerProps) => {
  const { t } = useCoreTranslation();
  const minSize = { width: 100, height: 126 };
  const theme = useTheme();
  renderCounter.count(LayerManager.name);

  const nonEmptyTitle = title ?? t('layermanager-title');

  const startSizeCalc = calculateStartSize(minSize, size, startPosition);
  const [sizeInState, setSizeInState] =
    React.useState<DraggableSize>(startSizeCalc);
  const dataSize = getLayerManagerSizeValue(sizeInState);

  const [collapsedColumns, setCollapsedColumns] = React.useState<
    Record<string, boolean>
  >(defaultCollapsedColumns);

  const stopWheelEventPropagationRef =
    useWheelStopPropagation<HTMLDivElement>();

  return (
    <ToolContainerDraggable
      title={nonEmptyTitle}
      startSize={sizeInState}
      minWidth={minSize.width}
      minHeight={minSize.height}
      startPosition={startPosition}
      isOpen={isOpen}
      onClose={onClose}
      headerSize={headerSize}
      bounds={bounds}
      data-testid="layerManagerWindow"
      data-size={dataSize}
      onMouseDown={onMouseDown}
      order={order}
      source={source}
      rightHeaderComponent={
        <HeaderOptionsConnect
          isDockedLayerManager={isDockedLayerManager}
          mapId={mapId}
          onClickDockButton={onToggleDock}
          onChangeSize={({ width }): void => {
            setSizeInState({ ...sizeInState, width });
          }}
          buttonSettings={settings?.toolbar?.controlButtonSettings}
        />
      }
      dragHandleIcon={settings?.toolbar?.dragHandle?.icon}
      closeIcon={settings?.toolbar?.closeButton?.icon}
      className="layermanager"
      sx={
        {
          ...layerManagerStyle,
          ...theme.palette.geowebColors.layerManager.root,
        } as SxProps
      }
      onResizeStop={(_event, _direction, node): void => {
        const { offsetWidth: width, offsetHeight: height } = node;
        setSizeInState({ width, height });
      }}
      onDragEnd={(_position, dragSize): void => {
        if (dragSize !== sizeInState) {
          setSizeInState(dragSize as DraggableSize);
        }
      }}
    >
      <Box
        ref={stopWheelEventPropagationRef}
        onKeyDown={(event): void => {
          event.stopPropagation();
        }}
      >
        {isLoading && (
          <LinearProgress
            data-testid="loading-bar"
            color="secondary"
            sx={{ position: 'absolute', width: '100%' }}
          />
        )}
        <Box className="layerManagerContainer">
          {error && (
            <Box sx={{ padding: '0 6px' }}>
              <AlertBanner title={error} shouldClose />
            </Box>
          )}
          <Grid
            container
            data-testid="layerManagerRowContainer"
            sx={styles.layerRowContainer}
          >
            <DescriptionRow
              source={source}
              settings={settings?.header}
              addLayerComponent={addLayerComponent}
              onToggleCollapsed={(name: string) =>
                setCollapsedColumns({
                  ...collapsedColumns,
                  [name]: !collapsedColumns[name],
                })
              }
              collapsedColumns={collapsedColumns}
            />
            <LayerContainerRow
              mapId={mapId}
              settings={settings?.content}
              collapsedColumns={collapsedColumns}
            />
            <BaseLayerRow
              mapId={mapId}
              tooltip={t('layermanager-baselayers-tooltip')}
              preloadedAvailableBaseLayers={preloadedAvailableBaseLayers}
              preloadedServices={preloadedBaseServices}
              settings={settings?.footer}
            />
            {!settings?.footer?.projection?.shouldHide && (
              <ProjectionSelectConnect mapId={mapId} />
            )}
          </Grid>
        </Box>
      </Box>
    </ToolContainerDraggable>
  );
};

export default LayerManager;
